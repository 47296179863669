import { toastr } from 'react-redux-toastr'
import { delay } from 'redux-saga'
// import { fetchMessages } from './messages-saga'
import {
  CRM_LOGGED_OUT,
  CRM_LOGIN,
  CRM_LOGIN_LOADING,
  CRM_LOGIN_SUCCESS,
  CRM_LOGIN_FAILURE,
  FETCH_AVAILABLE_INSTANCES,
  FETCH_AVAILABLE_INSTANCES_SUCCESS,
  CHANGE_SELECTED_INSTANCE,
  CHANGE_SELECTED_INSTANCE_SUCCESS,
  // FETCH_MY_PERMISSIONS,
  // FETCH_MY_PERMISSIONS_SUCCESS,
  FETCH_MY_PERMISSIONS_2019,
  FETCH_MY_PERMISSIONS_2019_SUCCESS,
  CRM_LOGIN_STATUS_CHANGED,
  TOUCH_OUTSIDE_NAVIGATION_AREA,
  MOBILE_NAVIGATION_HIDDEN,
  getUser,
  currentUserChanged,
  FETCH_USER_SETTINGS,
  FETCH_USER_SETTINGS_SUCCESS,
  CRM_INITIAL,
  CRM_INITIALIZATION_COMPLETED,
  CRM_INITIAL_STEP_CHANGE,
  FETCH_USER_INFO,
  FETCH_USER_INFO_SUCCESS,
  getAvailableInstances,
  getCrmInitialStep,
  getSelectedInstance,
  getCrmInitialStates,
  getMyPermissions2019Loaded,
  getUserInfoLoaded
} from 'crm-duxs/crm-reducer'

import { FETCH_LOCALE_DATA, FETCH_LOCALE_SUCCESS, getLocale } from 'crm-duxs/locale'

import { INIT_ACCOUNTS_MODULE } from 'crm-duxs/accounts-reducer'
import { INIT_ACTIVITIES_MODULE } from 'crm-duxs/activities-reducer'
import { LOGGED_IN, LOG_OUT } from 'crm-duxs/user'
import crmLoginApi from 'crm-api/crm-login-api'
import usersApi from 'crm-api/settings/users-api'
import { call, put, takeLatest, select, take } from 'redux-saga/effects'

function* crmLogin(action) {
  yield put({
    type: CRM_LOGIN_LOADING,
    payload: {
      loginLoading: true
    }
  })

  const result = yield call(
    crmLoginApi.doLogin,
    action.payload.username,
    action.payload.password,
    action.payload.company_code
  )

  const { user, access_token, error } = result

  yield put({
    type: CRM_LOGIN_LOADING,
    payload: {
      loginLoading: false
    }
  })

  if (error) {
    yield put({
      type: CRM_LOGIN_FAILURE,
      payload: {
        error: result.error
      }
    })
  }
  if (user) {
    yield put({
      type: CRM_LOGIN_SUCCESS,
      payload: {
        token: access_token,
        user
      }
    })
    // Fake Fleet user login
    yield put({
      type: LOGGED_IN,
      payload: {
        user
      }
    })

    yield put({
      type: FETCH_AVAILABLE_INSTANCES
    })

    // Notify user status changed
    yield put({
      type: CRM_LOGIN_STATUS_CHANGED
    })
  }
  yield call(delay, 5)
  console.log('Login, put currentUserChanged')
  yield put(currentUserChanged())
  yield call(initModules)
}

function* initModules() {
  console.log('initModules')
  yield put({
    type: INIT_ACTIVITIES_MODULE
  })
  yield put({
    type: INIT_ACCOUNTS_MODULE
  })
  yield call(fetchUserSettings)
}

function* crmLogOut(action) {
  window.localStorage.removeItem('crmUser')
  window.localStorage.removeItem('user')
  window.localStorage.removeItem('crmAccessToken')
  window.localStorage.removeItem('crm_instance')
  window.localStorage.removeItem('crmRefreshToken')
  window.localStorage.removeItem('lastNetworkActivityTime')
  if (action && action.reason) {
    window.localStorage.setItem('crmLogoutReason', action.reason)
  }

  yield put({
    type: CHANGE_SELECTED_INSTANCE_SUCCESS,
    payload: {
      instance: undefined
    }
  })
  yield put({
    type: CRM_LOGGED_OUT
  })
  yield call(delay, 5)
  yield put(currentUserChanged())
}

function* fetchAvailableInstances() {
  const user = yield select(getUser)
  const result = yield call(crmLoginApi.fetchAvailableInstancesQL, user.user_uid)
  yield put({
    type: FETCH_AVAILABLE_INSTANCES_SUCCESS,
    payload: {
      availableInstances: result
    }
  })
}

function* changeSelectedInstance(action) {
  // eslint-disable-next-line
  let details = action.payload.instance.details
  if (Array.isArray(details)) {
    details = details.reduce((result, item) => {
      // eslint-disable-next-line
      result[item.code] = item
      return result
    }, {})
  }

  const newInstance = {
    instance_uid: action.payload.instance.instance_uid,
    name: action.payload.instance.name,
    parameters: action.payload.instance.parameters,
    details,
    country_code: action.payload.instance.country_code,
    currency_code: action.payload.instance.currency_code
  }
  window.localStorage.setItem('crm_instance', JSON.stringify(newInstance))
  yield toastr.success('Instance changed', 'You should reload whole application')
  yield put({
    type: CHANGE_SELECTED_INSTANCE_SUCCESS,
    payload: {
      instance: newInstance
    }
  })
}

function* fetchLocalesData() {
  const locale = yield select(getLocale)
  const result = yield call(crmLoginApi.fetchLocales, locale)
  if (result.data && Array.isArray(result.data)) {
    const translationMsgs = {}
    result.data.forEach(obj => {
      translationMsgs[obj.code] = obj.value
    })

    yield put({
      type: FETCH_LOCALE_SUCCESS,
      payload: {
        translationMsgs
      }
    })
  }
}

// function* fetchMyPermissions() {
//   const result = yield call(crmLoginApi.fetchMyPermissions)
//   yield put({
//     type: FETCH_MY_PERMISSIONS_SUCCESS,
//     payload: {
//       myPermissions: result
//     }
//   })
// }

function* fetchMyPermissions2019() {
  const result = yield call(crmLoginApi.fetchMyPermissions2019)
  yield put({
    type: FETCH_MY_PERMISSIONS_2019_SUCCESS,
    payload: {
      myPermissions2019: result
    }
  })
}

function* fetchUserInfo() {
  const user = yield select(getUser)
  const result = yield call(usersApi.get, user.user_uid)
  yield put({
    type: FETCH_USER_INFO_SUCCESS,
    payload: {
      userInfo: result[0]
    }
  })
}

function* readCurrentUser() {
  const user = yield select(getUser)
  if (user) {
    yield put(currentUserChanged())
    yield call(initModules)
    yield call(fetchUserSettings)
  }
}

function* closeNavigationBar() {
  yield put({
    type: MOBILE_NAVIGATION_HIDDEN
  })
}

function* fetchUserSettings() {
  const user = yield select(getUser)
  const instance = yield select(getSelectedInstance)
  if (user && instance) {
    const result = yield call(crmLoginApi.fetchMyUserSettings)
    yield put({
      type: FETCH_USER_SETTINGS_SUCCESS,
      payload: {
        userSettings: result.user_settings
      }
    })
  }
}

function* processInitialNextStage() {
  const currentStep = yield select(getCrmInitialStep)
  const currentInstance = yield select(getSelectedInstance)
  const initialStages = yield select(getCrmInitialStates)
  const user = yield select(getUser)
  let nextStep
  // console.log('processInitialNextStage', currentStep)
  switch (currentStep) {
    case 'INIT_STAGE_INITIAL':
      nextStep = initialStages[currentStep].next
      break
    case 'INIT_STAGE_LOGIN':
      if (user) {
        nextStep = initialStages[currentStep].next
        break
      }

      yield take(LOGGED_IN)
      nextStep = initialStages[currentStep].next
      break

    case 'INIT_STAGE_INSTANCE': {
      const availableInstances = yield select(getAvailableInstances)
      if (!availableInstances?.available_instances || availableInstances.available_instances.length === 0) {
        yield put({
          type: FETCH_AVAILABLE_INSTANCES
        })
      }

      if (currentInstance) {
        nextStep = initialStages[currentStep].next
        break
      }

      yield take(CHANGE_SELECTED_INSTANCE_SUCCESS)
      nextStep = initialStages[currentStep].next
      break
    }
    case 'LOAD_CONFIGURATION':
      console.log('LOAD_CONFIGURATION')
      // yield put({ type: FETCH_MY_PERMISSIONS })
      yield put({ type: FETCH_MY_PERMISSIONS_2019 })
      yield put({ type: FETCH_LOCALE_DATA })
      yield put({ type: FETCH_USER_INFO })
      // yield take(FETCH_MY_PERMISSIONS_SUCCESS)
      // console.log(
      //   'LOAD_CONFIGURATION FETCH_MY_PERMISSIONS_SUCCESS',
      //   new Date().getTime() - start
      // )

      // yield take(FETCH_MY_PERMISSIONS_2019_SUCCESS)
      // console.log(
      //   'LOAD_CONFIGURATION FETCH_MY_PERMISSIONS_2019_SUCCESS',
      //   new Date().getTime() - start
      // )

      // // yield take(FETCH_LOCALE_SUCCESS)
      // // console.log(
      // //   'LOAD_CONFIGURATION FETCH_LOCALE_SUCCESS',
      // //   new Date().getTime() - start
      // // )

      // yield take(FETCH_USER_INFO_SUCCESS)
      // console.log(
      //   'LOAD_CONFIGURATION FETCH_USER_INFO_SUCCESS',
      //   new Date().getTime() - start
      // )
      // const end = new Date().getTime()
      // console.log('LOAD_CONFIGURATION FINISHED', end - start)
      // yield call(fetchMessages, {
      //   payload: {
      //     mine: true
      //   }
      // })

      // yield put({ type: FETCH_USER_INFO })
      // yield take(FETCH_USER_INFO_SUCCESS)

      // nextStep = initialStages[currentStep].next
      break

    case 'INIT_STAGE_READY':
      yield put({ type: CRM_INITIALIZATION_COMPLETED })
      break

    default:
      break
  }

  if (nextStep) {
    yield put({
      type: CRM_INITIAL_STEP_CHANGE,
      payload: {
        step: nextStep
      }
    })
  }
}
function* onInitializationStepCompleted(event) {
  const currentStep = yield select(getCrmInitialStep)
  const myPermissions2019Loaded = yield select(getMyPermissions2019Loaded)
  const userInfoLoaded = yield select(getUserInfoLoaded)
  if (currentStep === 'LOAD_CONFIGURATION' && myPermissions2019Loaded && userInfoLoaded) {
    yield put({
      type: CRM_INITIAL_STEP_CHANGE,
      payload: {
        step: 'INIT_STAGE_READY'
      }
    })
  }
}

export default function* accountsSaga() {
  yield takeLatest(CRM_LOGIN, crmLogin)
  yield takeLatest(LOG_OUT, crmLogOut)
  yield takeLatest(FETCH_AVAILABLE_INSTANCES, fetchAvailableInstances)
  yield takeLatest(CHANGE_SELECTED_INSTANCE, changeSelectedInstance)
  // yield takeLatest(FETCH_MY_PERMISSIONS, fetchMyPermissions)
  yield takeLatest(FETCH_MY_PERMISSIONS_2019, fetchMyPermissions2019)
  yield takeLatest(TOUCH_OUTSIDE_NAVIGATION_AREA, closeNavigationBar)
  yield takeLatest(FETCH_USER_SETTINGS, fetchUserSettings)
  yield takeLatest(CRM_INITIAL, processInitialNextStage)
  yield takeLatest(FETCH_LOCALE_DATA, fetchLocalesData)
  yield takeLatest(CHANGE_SELECTED_INSTANCE_SUCCESS, processInitialNextStage)
  yield takeLatest(CRM_INITIAL_STEP_CHANGE, processInitialNextStage)
  yield takeLatest(FETCH_USER_INFO, fetchUserInfo)
  // Initialzation elements observers
  // yield takeLatest(FETCH_MY_PERMISSIONS_SUCCESS, onInitializationStepCompleted)
  yield takeLatest(FETCH_MY_PERMISSIONS_2019_SUCCESS, onInitializationStepCompleted)
  yield takeLatest(FETCH_USER_INFO_SUCCESS, onInitializationStepCompleted)

  yield call(readCurrentUser)
}
