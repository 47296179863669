import resourceAPI from 'crm-api/admin/resources-api'
import {
  FETCH_RESOURCES,
  RECEIVE_RESOURCES,
  DELETE_RESOURCE_VALUE,
  SAVE_EDITING_RESOURCE,
  CANCEL_EDITING_RESOURCE,
  CANCEL_CREATING_RESOURCE,
  SAVE_CREATING_RESOURCE,
  CREATING_RESOURCE_ITEM,
  CREATED_RESOURCE_FOR_ACCOUNT
} from 'crm-duxs/admin/resources-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchResources() {
  const resources = yield call(resourceAPI.fetch)
  yield put({
    type: RECEIVE_RESOURCES,
    payload: {
      resources
    }
  })
}

function* createResourceforAccount(action) {
  try {
    yield call(resourceAPI.create, action.formValues)
    yield put({ type: CREATED_RESOURCE_FOR_ACCOUNT })
  } catch (err) {}
}

function* createResource(action) {
  try {
    yield call(resourceAPI.create, action.formValues)
    yield put({
      type: CANCEL_CREATING_RESOURCE
    })
    yield put({
      type: FETCH_RESOURCES
    })
  } catch (err) {}
}

function* updateResource(action) {
  try {
    yield call(resourceAPI.update, action.formValues)
    yield put({
      type: CANCEL_EDITING_RESOURCE
    })
    yield put({
      type: FETCH_RESOURCES
    })
  } catch (err) {}
}

function* deleteResource(action) {
  console.log('deleteResource', action)
  try {
    yield call(resourceAPI.delete, action)
    yield put({
      type: FETCH_RESOURCES
    })
  } catch (err) {}
}

function* settingResourceSaga() {
  yield takeLatest(FETCH_RESOURCES, fetchResources)
  yield takeLatest(SAVE_CREATING_RESOURCE, createResource)
  yield takeLatest(SAVE_EDITING_RESOURCE, updateResource)
  yield takeLatest(DELETE_RESOURCE_VALUE, deleteResource)
  yield takeLatest(CREATING_RESOURCE_ITEM, createResourceforAccount)
}

export default settingResourceSaga
