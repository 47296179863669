import React, { useState, useEffect } from 'react'
import {
  CrmMonthSlider,
  onchangeType
} from '@cartrack-crm/ui/src/month-slider/crm-month-slider'
import { ForecastProgressChart } from '../forecast-progresschart'
import { useFetchForecastThisMonth } from '../useFetchForecastManager'
import {
  AnalyticsContextProvider,
  useAnalyticsContext
} from 'crm-core/analytics/context'
import { FilterSectionLead, FilterSectionUser, FilterSectionActivityDate } from '@cartrack-crm/crm-filter-section'
import { CrmModal } from 'crm-components'

const MODAL_TITLE = 'Manager Forecast Monthly'

interface ForecastManagerModalGraphProps {
  isOpen: boolean
  onClose: () => any
  month: string
}

const globalFiltersDefinition = [
  {
    code: 'lead_in_date',
    data_type: 'date_range',
    label: 'Lead In Date',
    operator: 'eq',
    filterName: 'Lead in date'
  },
  {
    code: 'activity_date',
    data_type: 'date_range',
    label: 'Activity Date',
    operator: 'eq',
    filterName: 'Activtity Date'
  },
  {
    code: 'user_uid',
    data_type: 'user',
    label: 'User',
    operator: 'eq',
    filterName: 'User'
  },
  {
    code: 'account__transaction_type_code',
    data_type: 'account__transaction_type_code',
    label: 'Account Transaction Type',
    operator: 'eq',
    filterName: 'Account Transaction Type'
  },
  {
    code: 'account__lead_campaign__transaction_type_code',
    data_type: 'lead_source_transaction_type',
    label: 'Lead Source Transaction Type',
    operator: 'eq',
    filterName: 'Lead Source Transaction Type'
  },
  {
    code: 'account__lead_campaign__lead_source__kind',
    data_type: 'lead_source_channel',
    label: 'Lead Channel',
    operator: 'eq',
    filterName: 'Lead Channel'
  },
  {
    code: 'account__lead_campaign__lead_source_uid',
    data_type: 'lead_source_type',
    label: 'Lead Source ',
    operator: 'eq',
    filterName: 'Lead Source '
  },
  {
    code: 'account__lead_campaign_uid',
    data_type: 'lead_source_campaign',
    label: 'Lead Campaign',
    operator: 'eq',
    filterName: 'Lead Campaign'
  },
  {
    code: 'sales_campaign_uid',
    data_type: 'campaign',
    label: 'Sales Campaign',
    operator: 'eq',
    filterName: 'Sales Campaign'
  },
  {
    code: 'resource_pool_uid',
    data_type: 'resource_pool',
    label: 'Users Group',
    operator: 'eq',
    filterName: 'Users Group'
  },
  {
    code: 'department_uid',
    data_type: 'department',
    label: 'User Department',
    operator: 'eq',
    filterName: 'User Department'
  }
]

export const ForecastManagerModalGraph: React.FunctionComponent<ForecastManagerModalGraphProps> = (
  props
) => {
  useEffect(() => {
    setMonthSelected(props.month)
  }, [props.month])
  const analyticsContext: any = useAnalyticsContext()
  const [filterValue, setFilterValue] = useState({})
  const [monthSelected, setMonthSelected] = useState(props.month)
  const { forecastSize } = useFetchForecastThisMonth(null, monthSelected)
  const onChangeMonth = (res: onchangeType) => {
    setMonthSelected(res.moment.format('YYYY-MM'))
  }
  const onFilterChange = (value) => {
    const filters = {}
    Object.keys(value).forEach((key) => {
      if (['activity_date', 'lead_in_date'].includes(key)) {
        filters[key] = value[key].value
      } else {
        filters[key] = value[key].value[0]
      }
    })
    analyticsContext.setFilters(filters)
  }
  const _getFilterName = (code) => {
    const filter = globalFiltersDefinition.find((f) => f.code === code)
    return filter ? filter.filterName : undefined
  }
  const setFilter = (pnewFilterValues) => {
    const newFilterValues = {
      ...(filterValue || {}),
      ...pnewFilterValues
    }

    Object.keys(newFilterValues).forEach((key) => {
      if (newFilterValues[key]) {
        newFilterValues[key].filterName = _getFilterName(key)
      }
      if (
        newFilterValues[key] === undefined ||
        newFilterValues[key].value === '' ||
        newFilterValues[key].value === undefined
      ) {
        delete newFilterValues[key]
      }
    })
    setFilterValue(newFilterValues)
    onFilterChange(newFilterValues)
  }

  return (
    <AnalyticsContextProvider>
      <CrmModal
        isOpen={props.isOpen}
        title={MODAL_TITLE}
        onClose={props.onClose}
      >
        <div className="util-fullHeight util-flexColumn">
          <CrmMonthSlider
            onChange={onChangeMonth}
            currentMonth={monthSelected}
          />
          <div className="util-fullWidth util-alignCenter util-marginBottom">
            Total forecasts on this month : {forecastSize}
          </div>
          <div className="util-fullWidth util-util-marginBottomSm">
            <FilterSectionLead />
            <FilterSectionUser />
            <FilterSectionActivityDate />
          </div>
          <ForecastProgressChart
            isManager
            month={monthSelected}
            widgetClassName="util-fullHeight"
            filter={analyticsContext.filters}
          />
        </div>
      </CrmModal>
    </AnalyticsContextProvider>
  )
}
