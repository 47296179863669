/* eslint-disable camelcase */
import React from 'react'
import { shape, bool, func, string, arrayOf } from 'prop-types'
import { graphql, compose, withApollo } from 'react-apollo'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import {
  buildDeleteAccountCommand,
  mergeAccountCommand,
  findMainProcess,
  findLastSalesProcess,
  buildPrioritizeAccountCommand
} from 'crm-utils/accounts-utils'
import {
  qlqfetchAccount,
  mapAccountToServer,
  qlqFindDuplicates
} from 'crm-data/accounts'
import { qlqAccountTasks } from 'crm-data/tasks'
import gql from 'graphql-tag'

export const NEW_ACCOUNT = gql`
  {
    newAccount @client {
      name
      account_uid
      lead_in_date
      campaign_uid
      category
      cams_username
      website
      industry_uid
      is_assign_to_me
      remark
      registration_number
      contact_details @client {
        kind
        value
        contact_details_uid
      }
      addresses @client {
        city
        province
        suburb
        postcode
        address_kind
        town
        line1
        location @client {
          lat
          lng
        }
      }
      persons @client {
        name
        description
        department
        kind
        contact_details @client {
          kind
          value
          contact_details_uid
        }
      }
    }
  }
`

// eslint-disable-next-line
const accountPageHoc = WrappedComponent => {
  class AccountQLWrapper extends React.PureComponent {
    static propTypes = {
      accountChildDeleted: shape({}).isRequired,
      isEditing: bool,
      gotoPage: func,
      accountUid: string.isRequired,
      onRunCommand: func.isRequired,
      newAccountUid: string,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      isEditing: false,
      gotoPage: undefined,
      newAccountUid: undefined
    }
    constructor(props) {
      super(props)
      this.state = {
        isEditing: props.accountUid === 'new',
        didCheckForDuplicates: false
      }
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState({ ...this.state, ...newState }, () => {
          resolve()
        })
      })

    handleEditAccount = () => {
      this.setState({ isEditing: true })
    }
    handleCancelEditAccount = () => {
      this.setState({ isEditing: false })
    }

    handleSubmit = async values => {
      if (this.props.isSaving) {
        return
      }
      const accountData = { ...values }
      const newAccount = Object.assign(
        accountData,
        this.props.accountChildDeleted
      )
      const account = mapAccountToServer(accountData)
      let res
      const command = {
        type:
          !newAccount.account_uid || newAccount.account_uid === 'new'
            ? 'account.create'
            : 'account.update',
        aggregate_uid:
          !newAccount.account_uid || newAccount.account_uid === 'new'
            ? this.props.newObjectUid
            : account.account_uid,
        aggregate_type: 'account',
        version: 1,
        payload: {
          ...account
        }
      }
      const refetchQueries = []
      if (newAccount.account_uid && newAccount.account_uid !== 'new') {
        refetchQueries.push({
          query: qlqfetchAccount,
          variables: { account_uid: newAccount.account_uid }
        })
      }
      try {
        res = await this.props.onRunCommand(command, { refetchQueries })
      } catch (err) {
        throw err
      }
      await this.setStateAsync({ isSaving: false, isEditing: false })
      return res.data.cqCommand
    }

    handleFindDuplicates = async account => {
      if (!account) {
        return
      }
      await this.setStateAsync({
        didCheckForDuplicates: false,
        isCheckingDuplicates: true
      })
      const res = await this.props.client.query({
        query: qlqFindDuplicates,
        variables: {
          account: mapAccountToServer(account)
        },
        fetchPolicy: 'no-cache'
      })
      let duplicates = []
      if (res) {
        duplicates = res.data.find_duplicates
      }
      await this.setStateAsync({
        didCheckForDuplicates: true,
        isCheckingDuplicates: false
      })
      this.setState({ duplicates })
      return duplicates
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (nextProps.accountUid === 'new') {
        this.setState({ isEditing: true })
      }
    }

    handleUnDeleteAccount = async () => this.handleDeleteAccount(false)
    handleDeleteAccount = async (isDelete = true) => {
      let res
      const command = buildDeleteAccountCommand(
        this.props.account.account_uid,
        isDelete
      )
      const refetchQueries = []
      refetchQueries.push({
        query: qlqfetchAccount,
        variables: { account_uid: this.props.account.account_uid }
      })
      try {
        res = await this.props.onRunCommand(command, { refetchQueries })
      } catch (err) {
        throw err
      }
      await this.setStateAsync({ isSaving: false, isEditing: false })
      return res.data.cqCommand
    }

    handlePrioritizeAccount = async () => {
      let res
      const command = buildPrioritizeAccountCommand(
        this.props.account.account_uid,
        11
      )
      const refetchQueries = []
      refetchQueries.push({
        query: qlqAccountTasks,
        variables: { account_uid: this.props.account.account_uid }
      })
      try {
        res = await this.props.onRunCommand(command, { refetchQueries })
      } catch (err) {
        throw err
      }
      await this.setStateAsync({ isSaving: false, isEditing: false })
      return res.data.cqCommand
    }

    handleMigrationAccount = async () => {
      try {
        const accountUid = this.props.account.account_uid
        const { protocol, hostname } = window.location;
        let user = null;
        try {
          const userString = window.localStorage.getItem('crmUser');
          user = JSON.parse(userString);
        } catch (error) {
          console.log(error)
          //throw new Error('Failed to parse user data from localStorage', error);
        }
        if (!user.user_uid) {
          throw new Error("unable to get user uid for export data");
        }

        const generateRandomString = (length) => {
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let result = '';
          const charactersLength = characters.length;
          for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          return result;
        };
        const randomString = generateRandomString(16);
        const userUid = user.user_uid
        const apiUrl = `${protocol}//${hostname}/jsonrpc/scripts/exporttocrm2.php?user_id=${userUid}&lead_id=${accountUid}&random_string=${randomString}`;
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (response.status !== 200) {
          throw new Error(`Status code: ${response.status}`);
        }

        return data;
      } catch (error) {
        throw new Error(error);
      }
    }

    runMergeAccount = async (sourceAccountUid, destinationAccountUid) => {
      const command = mergeAccountCommand(
        sourceAccountUid,
        destinationAccountUid
      )
      const refetchQueries = []
      try {
        const res = await this.props.onRunCommand(command, { refetchQueries })
        return res.data.cqCommand
      } catch (err) {
        throw err
      }
    }

    render() {
      let process
      if (this.props.processUid) {
        if (this.props.account && this.props.account.processes) {
          process = this.props.account.processes.find(
            p => p.process_uid === this.props.processUid
          )
        }
      } else {
        process = findMainProcess(this.props.account)
      }

      const lastSalesProcess = findLastSalesProcess(this.props.account)
      const currentModuleCode =
        process && process.process_type ? process.process_type.module : 'sales'
      return (
        <WrappedComponent
          {...this.props}
          process={process}
          lastSalesProcess={lastSalesProcess}
          isNew={this.props.accountUid === 'new'}
          isEditing={this.state.isEditing}
          isSaving={this.props.isSaving}
          onEditAccount={this.handleEditAccount}
          onSubmit={this.handleSubmit}
          onCancelEditAccount={this.handleCancelEditAccount}
          onCheckForDuplicates={this.handleFindDuplicates}
          duplicates={this.state.duplicates}
          didCheckForDuplicates={this.state.didCheckForDuplicates}
          isCheckingDuplicates={this.state.isCheckingDuplicates}
          onDeleteAccount={this.handleDeleteAccount}
          onUnDeleteAccount={this.handleUnDeleteAccount}
          currentModuleCode={currentModuleCode}
          runMergeAccount={this.runMergeAccount}
          onPrioritizeAccount={this.handlePrioritizeAccount}
          onMigrationAccount={this.handleMigrationAccount}
        />
      )
    }
  }
  const withCommand = crmGenericCommandQlHoc(AccountQLWrapper)
  return withApollo(withCommand)
}

const AccountPageQLHOC = compose(
  graphql(qlqfetchAccount, {
    options: ownProps => ({
      variables: { account_uid: ownProps.accountUid }
    }),
    skip: ownProps => ownProps.accountUid === 'new' || !ownProps.accountUid,
    props: ({ accountQl, ownProps }) => ({
      ...ownProps,
      accountQl,
      account: accountQl ? accountQl.account : undefined,
      isLoading: accountQl ? accountQl.loading : false
    }),
    name: 'accountQl',
    fetchPolicy: 'no-cache'
  }),
  accountPageHoc
)

export const accountPageHocPropTypes = {
  onMigrationAccount: func.isRequired,
  onDeleteAccount: func.isRequired,
  onUnDeleteAccount: func.isRequired,
  isEditing: bool.isRequired,
  isSaving: bool.isRequired,
  onCheckForDuplicates: func.isRequired,
  isCheckingDuplicates: bool,
  didCheckForDuplicates: bool.isRequired,
  isNew: bool.isRequired,
  duplicates: arrayOf(shape({}))
}
export default AccountPageQLHOC
