/* eslint-disable camelcase */
import md5 from 'md5'

export const CHANGE_MY_PASSWORD = 'CHANGE_MY_PASSWORD'
export const CHANGE_MY_PASSWORD_SUCCESS = 'CHANGE_MY_PASSWORD_SUCCESS'
export const LOAD_SIP_USER = 'LOAD_SIP_USER'
export const RECEIVE_SIP_USER = 'RECEIVE_SIP_USER'
export const CHANGE_SIP_USER = 'CHANGE_SIP_USER'
export const DISCONNECT_FLEET_USER = 'DISCONNECT_FLEET_USER'
export const LOGIN_FLEET_USER = 'LOGIN_FLEET_USER'
export const LOGIN_FLEET_USER_SUCCESS = 'LOGIN_FLEET_USER_SUCCESS'

const initialState = {
  fetch: false,
  user: undefined,
  fleetToken: undefined
}

// Action
export function loadSipUser(user_uid) {
  return {
    type: LOAD_SIP_USER,
    user_uid
  }
}

export function changeSipUser(data, user_uid) {
  return {
    type: CHANGE_SIP_USER,
    data,
    user_uid
  }
}

export function fleetLogin(username, password) {
  return {
    type: LOGIN_FLEET_USER,
    username,
    password
  }
}

export function disconnectFleet() {
  return { type: DISCONNECT_FLEET_USER }
}

// Selector
export function getSipUser(state) {
  return state.crm.me.user
}

export function getFetch(state) {
  return state.crm.me.fetch
}

export function getFleetToken(state) {
  return state.crm.me.fleetToken
}

export default function meReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SIP_USER:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_SIP_USER:
      return {
        ...state,
        fetch: false,
        user: action.payload.user[0]
      }
    case LOGIN_FLEET_USER_SUCCESS:
      return {
        ...state,
        fleetToken: action.payload.user.authToken
      }
    case DISCONNECT_FLEET_USER:
      return {
        ...state,
        fleetToken: undefined
      }
    default:
      return state
  }
}
// Reducer
export function changeMyPassword(values) {
  return {
    type: CHANGE_MY_PASSWORD,
    payload: {
      user: values.user,
      newPassword: md5(values.new_password)
    }
  }
}
