import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape, func, arrayOf, node } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { submit } from 'redux-form'
import DocumentsList from '../documents/documents-list.jsx'
import documentsListQLHOC from '../documents/documents-list-ql-hoc'
import DocumentFormInner from '../../../documents/components/document-form-inner.jsx'
import { CrmFormSection, CrmModal, CrmButton } from 'crm-components'
import { actions, getDocument } from 'crm-duxs/document-reducer'
import { bindActions } from 'crm-utils/reducer-utils'
import { msToDateformat } from 'crm-utils/time-utils'

class SectionDocuments extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isModalVisible: false }
  }

  handleOnAddDocument = () => {
    this.props.actions.initNewDocument()
  }

  _getActions = () => {
    const haveDocuments =
      this.props.account &&
      this.props.account.documents &&
      this.props.account.documents.length > 0
    return haveDocuments
      ? [
          {
            label: '+ Document',
            onClick: this.handleOnAddDocument
          }
        ]
      : []
  }

  handleCloseModal = () => {
    // console.log('handleCloseModal')
    this.props.doSetEditedDocument(undefined)
  }

  handleSubmit = values => {
    // console.log('Document values : ', values)
    const documentValues = {
      account_uid: this.props.account.account_uid, // eslint-disable-line
      name: values.name,
      document_type_uid: values.document_type_uid, // eslint-disable-line
      items: values.items,
      opportunity_uid: values.opportunity_uid // eslint-disable-line
    }

    if (values.created_date)
      // eslint-disable-next-line
      documentValues.created_date = msToDateformat(
        values.created_date,
        'YYYY-MM-DD'
      )
    if (values.created_date)
      // eslint-disable-next-line
      documentValues.date_presented = msToDateformat(
        values.date_presented,
        'YYYY-MM-DD'
      )
    if (values.created_date)
      // eslint-disable-next-line
      documentValues.date_signed = msToDateformat(
        values.date_signed,
        'YYYY-MM-DD'
      )

    if (values.document_uid) {
      documentValues.document_uid = values.document_uid // eslint-disable-line
      documentValues.items_delete = values.items_delete // eslint-disable-line
        ? values.items_delete.split(',')
        : ''
    }

    if (documentValues.document_uid) {
      // console.log('update DocumentFormInner form values : ', documentValues)
      this.props.actions.updateDocument({ document: documentValues })
    } else {
      // console.log('create DocumentFormInner form values : ', documentValues)
      this.props.actions.createDocument({ document: documentValues })
    }
  }

  handleDocumentSubmit = () => {
    this.props.doSubmitForm()
  }

  render() {
    const { account, sectionName, document, documents } = this.props
    const isModal = this.state.isModalVisible || document
    console.log('render section documents')
    return (
      <CrmFormSection
        sectionName={sectionName}
        actions={this._getActions()}
        hasPadding
      >
        <DocumentsList documents={documents} />
        {account.documents && account.documents.length === 0 && (
          <div className="util-textLight util-textCenter">
            <FormattedMessage
              id="crm.ui.account.document.no_quotations_or_contracts"
              defaultMessage="There are no Quotations or Contracts for this account."
            />
            <br />
            <CrmButton
              label={
                <FormattedMessage
                  id="crm.ui.button.attach_document"
                  defaultMessage="Attach Document"
                />
              }
              onClick={this.handleOnAddDocument}
              type="primary"
            />
          </div>
        )}
        <CrmModal
          title={
            <FormattedMessage
              id="crm.ui.account.document.document"
              defaultMessage="Document"
            />
          }
          showFooter
          isOpen={isModal}
          size="lg"
          onClose={this.handleCloseModal}
          actions={[
            {
              label: (
                <FormattedMessage
                  id="crm.ui.button.save"
                  defaultMessage="Save"
                />
              ),
              type: 'primary',
              onClick: this.handleDocumentSubmit
            }
          ]}
        >
          <DocumentFormInner
            document={document}
            account={account}
            onSubmit={this.handleSubmit}
            initialValues={document}
          />
        </CrmModal>
      </CrmFormSection>
    )
  }
}

SectionDocuments.defaultProps = {
  sectionName: (
    <FormattedMessage
      id="crm.ui.account.document.documents"
      defaultMessage="Documents"
    />
  ),
  document: undefined,
  documents: undefined
}

SectionDocuments.propTypes = {
  account: shape({}).isRequired,
  document: shape({}),
  sectionName: node,
  doSetEditedDocument: func.isRequired,
  actions: shape({}).isRequired,
  documents: arrayOf(shape({})),
  doSubmitForm: func.isRequired // eslint-disable-line
}

function mapStateToProps(state) {
  return {
    document: getDocument(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActions(dispatch, actions),
    doSubmitForm: () => dispatch(submit('CrmDocumentFormInner')),
    doSetEditedDocument: document =>
      dispatch(actions.setEditedDocument.create({ document }))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(documentsListQLHOC(SectionDocuments))
