import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import React, { PureComponent } from 'react'
import { shape, string, arrayOf, func, bool, oneOfType } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { FormattedMessage, injectIntl } from 'react-intl'
import ActivitiesList from '../activities/activities-list.jsx'
import NewActivityForm from '../../../activities/component/new-activity-form.jsx'
import ActivityEditPopup from '../../../activities/activity-edit-popup.jsx'
import ActivityEmailLogPopup from '../../../activities/activity-email-log-popup'
import AccountContactDetailsBigDropdownQL from '../components/account-contact-details-modal.jsx'
import newLiveCallHoc from './new-live-call-hoc.jsx'
import {
  getActivity,
  actions as activityActions
} from 'crm-duxs/activity-reducer'
import SectionTasksHoc from '../tasks/section-tasks-hoc.jsx'
import { getActivityTypes } from 'crm-duxs/activities-reducer'
import { CrmButton, CrmDropdownButton, CrmLoadingIcon } from 'crm-components'
import { buildNewActivity } from 'crm-data/activities-utils'
import { getUser } from 'crm-duxs/crm-reducer'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import activitiesListQLHOC from '../activities/activities-list-ql-hoc'
import styles from './section-activities.scss'
import InfoNoAccountPermission from './info_no_account_permission.jsx'
import activitiesPermissionHOC from '../activities/hoc/activities-permissions-hoc'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class SectionActivities extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isAddingActivity: false,
      isWorkingOutsideProcess: false,
      isAddingTask: false,
      isLiveCallEditAction: false,
      isShowLog: false,
      shogActivity: undefined,
      sendEmailFromQuotation: props.sendEmailFromQuotation
        ? { ...props.sendEmailFromQuotation }
        : undefined
    }
  }

  handleOnAddActivity = type => {
    const newActivity = buildNewActivity(
      this.props.accountUid,
      type,
      this.props.currentUser
    )
    newActivity.process_uid = this.props.processUid
    this.setState({ isAddingActivity: true, newActivity })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activity && !nextProps.activity) {
      this.setState({ isAddingActivity: false })
      this.handleCancelAddingActivity()
    }
    if (
      this.props.sendEmailFromQuotation !== nextProps.sendEmailFromQuotation
    ) {
      this.setState(
        {
          sendEmailFromQuotation: nextProps.sendEmailFromQuotation
            ? { ...nextProps.sendEmailFromQuotation }
            : undefined
        },
        () => {
          if (this.state.sendEmailFromQuotation) {
            this._renderDefaultActivityPopup()
          }
        }
      )
    }
    if (nextProps.forceCloseActivityPopup && nextProps.sendEmailFromQuotation) {
      this.handleCancelAddingActivity()
    }
  }

  handleAddLiveCall = sales_live_call_activity_type => {
    // IF PENDNG CALL - restre
    const pendingLiveCall = this.getPendingLiveCall()
    if (this.pendingLiveCall) {
      toastr.info('Going back to pending Call')
      return this.handleBringBackLiveCall(pendingLiveCall)
    }
    this.setState({
      live_call_activity_type_uid:
        sales_live_call_activity_type.activity_type_uid,
      showLiveCallNumbers: true,
      scriptCode: undefined
    })
  }
  handleAddLiveFinanceCall = finance_live_call_activity_type => {
    this.setState({
      live_call_activity_type_uid:
        finance_live_call_activity_type.activity_type_uid,
      showLiveCallNumbers: true,
      scriptCode: undefined
    })
  }
  handleAddLiveCSCall = cs_live_call_activity_type => {
    this.setState({
      live_call_activity_type_uid: cs_live_call_activity_type.activity_type_uid,
      showLiveCallNumbers: true,
      scriptCode: undefined
    })
  }
  handleCancelAddingLiveCall = () => {
    this.setState({ showLiveCallNumbers: false })
  }

  handleSelectedPhoneChange = async cd => {
    if (this.props.isStartingActivity) {
      return
    }
    if (this.state.editedActivity) {
      return
    }
    if (cd) {
      try {
        const activityParams = {
          account_uid: this.props.accountUid,
          contact_details_uid: cd.contact_details_uid,
          process_uid: this.props.processUid,
          scriptCode: this.state.scriptCode
        }
        if (this.state.live_call_activity_type_uid) {
          activityParams.activity_type_uid = this.state.live_call_activity_type_uid
        }
        const activity = await this.props.onStartNewLiveActivity(activityParams)
        if (this.state.scriptCode) {
          this.setState({
            editedScriptActivity: activity,
            showLiveCallNumbers: false
          })
        } else {
          this.setState({
            editedActivity: activity,
            showLiveCallNumbers: false
          })
        }
        toastr.success('New Live Call started')
      } catch (err) {
        toastr.error('There was problem creating new Live Call')
      }
    }
  }
  handleCancelEditPopup = () => {
    this.setState({
      isAddingActivity: false,
      newActivity: undefined,
      editedActivity: undefined
    })
    this.props.dispatch(activityActions.endActivityEdit.create())
    if (this.props.handleEndSendingEmailFromQuotation) {
      this.props.handleEndSendingEmailFromQuotation()
    }
  }

  handleStartLiveCallScript = async event => {
    const scriptCode = event.currentTarget.id
    // Old style - inline call
    this.setState({ showLiveCallNumbers: true, scriptCode })
  }

  _getScriptCallOptions() {
    const types = this.props.activityTypes
      ? [...this.props.activityTypes].filter(v => v.form_code === 'live_call')
      : []
    const sales_type = types.filter(v => v.code === 'live_call')[0]
    const finance_type = types.filter(v => v.code === 'live_call_finance')[0]
    const cs_type = types.filter(v => v.code === 'live_call_cs')[0]
    const options = []
    if (this.props.hasSalesPermission() && sales_type) {
      options.push({
        id: 'live_call',
        label: (
          <FormattedMessage
            id="crm.activity.live_call"
            defaultMessage="Live Call"
          />
        ),
        onClick: () => {
          this.handleAddLiveCall(sales_type)
        }
      })
    }
    if (this.props.hasFinancePermission() && finance_type) {
      options.push({
        id: 'live_finance_call',
        label: (
          <FormattedMessage
            id="crm.activity.live_finance_call"
            defaultMessage="Live Finance Call"
          />
        ),
        onClick: () => {
          this.handleAddLiveFinanceCall(finance_type)
        }
      })
    }
    if (this.props.hasCustomerServicePermission() && cs_type) {
      options.push({
        id: 'live_call_cs',
        label: (
          <FormattedMessage
            id="crm.activity.cs_live_call"
            defaultMessage="CS Live Call"
          />
        ),
        onClick: () => {
          this.handleAddLiveCSCall(cs_type)
        }
      })
    }
    return options
  }

  setDefaultAction = mappedActivities => {
    mappedActivities.forEach(activity => {
      if (activity.medium_code === 'meeting') {
        activity.defaultOption = 'c99f586c-74c6-4245-b544-727f3c3c32fd'
        // Also can set with label   activity.defaultOption = 'Sales Meeting'
      }
    })

    return mappedActivities
  }

  getMetaForMedium(mediumCode) {
    if (mediumCode === 'meeting') {
      return { icon: faUsers, title: 'Schedule new Meeting' }
    }
    if (mediumCode === 'phone_call') {
      return { icon: faPhone, title: 'Register a Phone Call' }
    }
    if (mediumCode === 'note') {
      return { icon: faFile, title: 'Add new Note' }
    }
    if (mediumCode === 'email') {
      return { icon: faEnvelope, title: 'Send email' }
    }
  }

  _getActions() {
    // Build actions based on activity types
    let activityTypes =
      this.props.activityTypes && this.props.activityTypes.map
        ? [...this.props.activityTypes].filter(v => !!v.is_active)
        : []
    // Make default options first
    const isDefault = a =>
      a.activity_type_uid === 'c99f586c-74c6-4245-b544-727f3c3c32fd' ||
      a.activity_type_uid === '7093ad39-7534-496a-8519-b137e6c54306' ||
      a.activity_type_uid === '46fe497d-0f8d-4a25-b8af-3a1e69b71160'
    activityTypes = [
      ...activityTypes.filter(isDefault),
      ...activityTypes.filter(a => !isDefault(a))
    ]

    const mappedActivities = activityTypes
      ? Object.values(
          activityTypes
            // Temportary show also is_active - for compat with two client versions
            .filter(at => at.form_code !== 'live_call')
            .reduce((a, at) => {
              const ret = { ...a }
              if (!ret[at.medium_code]) {
                const meta = this.getMetaForMedium(at.medium_code)
                ret[at.medium_code] = {
                  medium_code: at.medium_code,
                  options: [],
                  label: at.name,
                  id: at.activity_type_uid,
                  ...meta,
                  onClick: () => this.handleOnAddActivity(at)
                }
              }
              ret[at.medium_code].options.push({
                label: at.name,
                icon: 'plus',
                id: at.activity_type_uid,
                onClick: () => this.handleOnAddActivity(at)
              })
              return ret
            }, {})
        )
      : []

    const scriptCallOptions = this._getScriptCallOptions()
    if (scriptCallOptions.length > 0) {
      mappedActivities.push({
        id: 'live_call',
        label: scriptCallOptions[0].label,
        onClick: scriptCallOptions[0].onClick,
        type: 'primary',
        options: scriptCallOptions
      })
    }
    // Script Call
    mappedActivities.push({
      id: 'add_task',
      label: '+ Task',
      onClick: this.handleAddTask,
      className: 'CrmButton--bordered '
    })

    this.setDefaultAction(mappedActivities)
    return mappedActivities
  }

  handleAddTask = event => {
    this.setState({ isAddingTask: true })
  }

  handleCancelAddingTask = () => {
    this.setState({ isAddingTask: false })
  }

  handleCancelAddingActivity = () => {
    this.setState({ isAddingActivity: false })
  }

  handleActivityCreated = () => {
    this.handleCancelAddingActivity()
  }
  handleActivityCompleted = () => {
    this.setState({ editedActivity: undefined })
  }
  clearIsLiveCallEditAction = () => {
    this.setState({ isLiveCallEditAction: false })
  }
  handleEditActivity = (activity, isLiveCallEditAction) => {
    this.setState({
      editedActivity: activity,
      isLiveCallEditAction:
        isLiveCallEditAction === true ? isLiveCallEditAction : false
    })
  }
  onShowEmailLog = activity => {
    this.setState({
      shogActivity: activity,
      isShowLog: true
    })
  }
  onLogClose = () => {
    this.setState({
      shogActivity: undefined,
      isShowLog: false
    })
  }
  handleBringBackLiveCall = activity => {
    this.setState({ editedActivity: activity })
  }
  handleActivityCompleted = activity => {
    this.setState({ editedActivity: undefined })
  }

  _renderNewActivityForm() {
    return (
      <NewActivityForm
        accountUid={this.props.accountUid}
        onActivityCreated={this.handleActivityCreated}
      />
    )
  }
  _renderNewActivityButtons() {
    const actions = this._getActions()
    return (
      <React.Fragment>
        {actions.map(action => {
          if (action.options) {
            return (
              <CrmDropdownButton
                key={action.key ? action.key : action.label}
                onClick={action.onClick}
                label={action.label}
                icon={action.icon}
                defaultOption={action.defaultOption}
                type={action.type ? action.type : 'dropdown'}
                className={`  ${action.className ? action.className : ''}
                ${
                  action.type && action.type === 'primary' ? 'Button--blue' : ''
                }`}
                options={action.options}
                style={{
                  whiteSpace: 'nowrap',
                  marginLeft: 5,
                  marginRight: 5,
                  width: action.icon
                    ? 60
                    : action.id === 'live_call'
                    ? 'auto'
                    : 110,
                  minWidth: action.icon ? 60 : 110
                }}
                iconButton={Boolean(action.icon)}
                title={action.title}
              />
            )
          } else {
            return (
              <CrmButton
                key={action.key ? action.key : action.label}
                onClick={action.onClick} // eslint-disable-line react/jsx-handler-names
                label={action.label}
                icon={action.icon}
                type={action.type ? action.type : 'secondary'}
                className={`CrmFormSection-action
                ${action.className ? action.className : ''}
                ${
                  action.type && action.type === 'primary' ? 'Button--blue' : ''
                }`}
                grouped
              />
            )
          }
        })}
      </React.Fragment>
    )
  }

  _renderDefaultActivityPopup = () => {
    const activityTypes = this.props.activityTypes
      ? [...this.props.activityTypes]
      : []
    if (this.state.sendEmailFromQuotation) {
      const activity = activityTypes.filter(v => v.medium_code === 'email')
      if (activity.length > 0) {
        this.handleOnAddActivity(activity[0])
      }
    }
  }

  getPendingLiveCall = () => {
    // Pending live call can be a new call (not saved yet) or one from saved activities list
    let pendingLiveCall
    if (this.state.editedActivity) {
      return this.state.editedActivity
    }
    if (this.props.activities) {
      pendingLiveCall = this.props.activities.find(
        a =>
          a.activity_type &&
          a.activity_type.form_code === 'live_call' &&
          !a.completed_time
      )

      return pendingLiveCall
    }
  }

  render() {
    if (this.props.hasAccountDataPermission === false) {
      return <InfoNoAccountPermission />
    }

    const { accountUid, isEditing, process, account } = this.props
    const activity =
      this.state.isAddingActivity && !this.props.activity
        ? this.state.newActivity
        : this.props.activity
    const pendingLiveCall = this.getPendingLiveCall()
    const processStepCode = this.props.process?.current_step?.process_step_type
      ?.code
    const accountKind = account && account.kind
    return (
      <div
        className={`CrmFormSection CrmFormSection--hasActions ${!isEditing &&
          ' util-flexGrow util-flexColumn '}`}
      >
        <div className="CrmFormSection-header">
          <div className="util-flexRow">
            <div className="util-flexGrow" />
            {this._renderNewActivityButtons()}
          </div>
        </div>
        <SectionTasksHoc
          process={process}
          processStepCode={processStepCode}
          accountKind={accountKind}
          accountUid={accountUid}
          isAddingTask={this.state.isAddingTask}
          onCancelAddingTask={this.handleCancelAddingTask}
        />
        {pendingLiveCall && (
          <div
            className={styles.PendingLiveCallWrapper}
            onClick={() => this.handleBringBackLiveCall(pendingLiveCall)}
          >
            PENDING LIVE CALL
          </div>
        )}

        <div
          className={`CrmFormSection-body util-flexGrow ${!isEditing &&
            ' util-flexColumn '} `}
          style={{ position: 'relative' }}
        >
          <div
            style={
              !isEditing && {
                overflowY: 'auto',
                paddingRight: 10,
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              }
            }
          >
            {this.state.showLiveCallNumbers && (
              <div className="CrmMdCard util-paddingMd util-marginBottom">
                <h5>
                  <FormattedMessage
                    id="crm.ui.account.activity.select_phone_number"
                    defaultMessage="Select phone number to start new Live Call"
                  />
                </h5>
                <AccountContactDetailsBigDropdownQL
                  accountUid={accountUid}
                  phoneOnly
                  selectable
                  onChange={this.handleSelectedPhoneChange}
                  autoSelect
                />
                {this.props.isStartingActivity && (
                  <div>
                    <div>Starting new call ... </div>
                    <CrmLoadingIcon />
                  </div>
                )}
                <CrmButton
                  label={
                    <FormattedMessage
                      id="crm.ui.button.cancel_starting_new_live_call"
                      defaultMessage="Cancel starting new Live Call"
                    />
                  }
                  onClick={this.handleCancelAddingLiveCall}
                />
              </div>
            )}

            {this.state.editedScriptActivity && (
              <Redirect
                to={`/crm/account/${this.props.accountUid}${
                  this.props.processUid
                    ? '/process/' + this.props.processUid
                    : ''
                }/liveCall/new?scriptCode=${
                  this.state.scriptCode
                }&activityTypeUid=${
                  this.state.editedScriptActivity.activity_type_uid
                }`}
              />
            )}

            <ActivitiesList
              accountUid={accountUid}
              account={this.props.account}
              onEditActivity={this.handleEditActivity}
              onShowEmailLog={this.onShowEmailLog}
              activities={this.props.activities}
              isLoading={this.props.isLoading}
            />

            <ActivityEditPopup
              accountUid={accountUid}
              account={this.props.account}
              isOpen={activity !== undefined}
              activity={activity}
              activityUid={
                this.state.isAddingActivity
                  ? 'new'
                  : activity
                  ? activity.activity_uid
                  : undefined
              }
              onClose={this.handleCancelEditPopup}
              isManager={this.props.accountPermissionsContext.hasPermissionByType(
                PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
              )}
              sendEmailFromQuotation={this.state.sendEmailFromQuotation}
            />
            <ActivityEditPopup
              accountUid={accountUid}
              account={this.props.account}
              isOpen={this.state.editedActivity !== undefined}
              activity={this.state.editedActivity}
              activityUid={
                this.state.editedActivity
                  ? this.state.editedActivity.activity_uid
                  : undefined
              }
              onClose={this.handleCancelEditPopup}
              onActivityCompleted={this.handleActivityCompleted}
              isManager={this.props.accountPermissionsContext.hasPermissionByType(
                PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
              )}
              isLiveCallEditAction={this.state.isLiveCallEditAction}
              clearIsLiveCallEditAction={this.clearIsLiveCallEditAction}
            />
            <ActivityEmailLogPopup
              activity={this.state.shogActivity}
              isOpen={this.state.isShowLog}
              onClose={this.onLogClose}
            />
          </div>
        </div>
      </div>
    )
  }
}

SectionActivities.defaultProps = {
  sectionName: 'Activities',
  activity: undefined,
  activityTypes: undefined,
  processUid: undefined,
  process: undefined,
  onLiveCallCompleted: undefined,
  isStartingActivity: undefined,
  hasAccountDataPermission: false,
  hasSalesPermission: () => {},
  hasFinancePermission: false,
  hasCustomerServicePermission: false,
  handleEndSendingEmailFromQuotation: undefined,
  sendEmailFromQuotation: undefined,
  forceCloseActivityPopup: false
}

SectionActivities.propTypes = {
  accountUid: string.isRequired,
  activity: shape({}),
  activityTypes: arrayOf(shape()),
  dispatch: func.isRequired,
  currentUser: shape().isRequired,
  onStartNewLiveActivity: func.isRequired,
  processUid: string,
  process: shape({}),
  onLiveCallCompleted: func,
  isStartingActivity: bool,
  hasAccountDataPermission: bool,
  hasSalesPermission: func,
  hasFinancePermission: oneOfType([func, bool]),
  hasCustomerServicePermission: oneOfType([func, bool]),
  handleEndSendingEmailFromQuotation: func,
  sendEmailFromQuotation: shape({}),
  forceCloseActivityPopup: bool,
  accountPermissionsContext: shape({}).isRequired
}

function mapStateToProps(state) {
  return {
    activity: getActivity(state),
    activityTypes: getActivityTypes(state),
    currentUser: getUser(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    doInitNewActivity: (accountUid, activityType) =>
      dispatch(
        activityActions.initNewActivity.create({ accountUid, activityType })
      )
  }
}

const SectionActivitiesQL = activitiesPermissionHOC(
  activitiesListQLHOC(newLiveCallHoc(SectionActivities))
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAccountPermissionsContextHOC(injectIntl(SectionActivitiesQL)))
