/* eslint-disable react/jsx-handler-names */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { push } from 'connected-react-router'
import ReactTable from 'react-table'
import { injectIntl, FormattedDate } from 'react-intl'
import { shape, func, bool, arrayOf } from 'prop-types'
import getPackageItemHoc from '../../admin/packages/package-item-ql-hoc.jsx'
import ReportQuickView from './components/report-quick-view.jsx'
import ReportCreateForm from './components/report-create-form.jsx'
import { toMSformat } from 'crm-utils/time-utils'
import { getCrmInstanceUid } from 'crm-api/api-utils'
import {
  fetchData,
  selectedItem,
  unselectedItem,
  startEditingItem,
  cancelEditingItem,
  startCreatingItem,
  cancelCreatingItem,
  saveCreatingItem,
  saveEditingItem
} from 'crm-duxs/settings/reports-reducer'

import {
  CrmFullpageContainer,
  CrmHbox,
  CrmVbox,
  CrmButton
} from 'crm-components'

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    width: 300
  },
  {
    accessor: 'query',
    Header: 'Query',
    id: 'query'
  }
]
class CrmReportsSettings extends PureComponent {
  getDateValue(datetime) {
    return (
      <FormattedDate
        year="numeric"
        month="long"
        day="2-digit"
        value={toMSformat(datetime)}
      />
    )
  }

  componentWillMount() {
    if (this.props.lists.length === 0) this.props.doFetch()
  }

  onRowClick(e, row) {
    if (
      this.props.selected &&
      row.original.report_uid === this.props.selected.report_uid
    ) {
      this.props.doUnselectedItem()
    } else {
      this.props.doSelectedItem(row.original)
    }
  }

  onRowClick2(e, row) {
    // console.log('onRowClick2 : ', row)

    if (row) {
      const url = `/crm/admin/advanced/packages/${row.original.package_uid}/${row.original.package_item_uid}`
      this.props.doPush(url)
    }
  }

  isSelectedRow = (state, rowInfo) => {
    const bg =
      this.props.selected &&
      rowInfo &&
      rowInfo.original.report_uid === this.props.selected.report_uid
        ? '#666'
        : ''
    return {
      style: {
        background: bg
      }
    }
  }

  _renderRightSide() {
    if (this.props.creating) {
      return (
        <div style={{ width: 700, height: '100%', overflowY: 'auto' }}>
          <ReportCreateForm
            onReset={this.props.doResetForm} // eslint-disable-line react/jsx-handler-names
            onCancel={this.props.doCancelCreating} // eslint-disable-line react/jsx-handler-names
            onSave={this.props.doSaveCreating} // eslint-disable-line react/jsx-handler-names
          />
        </div>
      )
    }

    if (this.props.selected) {
      return (
        <CrmVbox style={{ width: 700 }}>
          <ReportQuickView
            editing={this.props.editing}
            creating={this.props.creating}
            resource={this.props.selected}
            onClose={this.props.doUnselectedItem} // eslint-disable-line react/jsx-handler-names
            onEdit={this.props.doEditing} // eslint-disable-line react/jsx-handler-names
            onCancelEdit={this.props.doCancelEditing} // eslint-disable-line react/jsx-handler-names
            onReset={this.props.doResetForm} // eslint-disable-line react/jsx-handler-names
            onSaveEditing={this.props.doSaveEditing} // eslint-disable-line react/jsx-handler-names
          />
        </CrmVbox>
      )
    }
  }

  render() {
    const getTdProps = (state, rowInfo) => ({
      onClick: e => this.onRowClick(e, rowInfo)
    })
    const getTdProps2 = (state, rowInfo) => ({
      onClick: e => this.onRowClick2(e, rowInfo)
    })
    return (
      <CrmFullpageContainer hasPadding vbox>
        <div className="row">
          <CrmButton onClick={this.props.doCreateItem} label="Create" />
        </div>
        <CrmHbox fill>
          <ReactTable
            loading={this.props.fetch}
            data={this.props.lists}
            columns={columns}
            className="react-table -striped -highlight"
            style={{ height: '100%' }}
            getTdProps={getTdProps}
            getTrProps={this.isSelectedRow}
          />
          {this._renderRightSide()}
        </CrmHbox>
        <CrmHbox fill>
          <ReactTable
            data={this.props.packageItems}
            columns={columns}
            className="react-table -striped -highlight"
            getTdProps={getTdProps2}
          />
        </CrmHbox>
      </CrmFullpageContainer>
    )
  }
}

CrmReportsSettings.defaultProps = {
  selected: undefined,
  lists: [],
  packageItems: []
}

CrmReportsSettings.propTypes = {
  selected: shape({}),
  packageItems: shape({}),
  lists: arrayOf(shape({})),
  fetch: bool.isRequired,
  editing: bool.isRequired,
  creating: bool.isRequired,
  doUnselectedItem: func.isRequired,
  doEditing: func.isRequired,
  doSaveEditing: func.isRequired,
  doCancelEditing: func.isRequired,
  doSelectedItem: func.isRequired,
  doPush: func.isRequired,
  doFetch: func.isRequired,
  doResetForm: func.isRequired,
  doCreateItem: func.isRequired,
  doCancelCreating: func.isRequired,
  doSaveCreating: func.isRequired
}

function mapStateToProps(state) {
  const dictState = state.crm.settingReports
  return {
    fetch: dictState.fetch,
    lists: dictState.lists,
    selected: dictState.selected,
    editing: dictState.editing,
    creating: dictState.creating,
    type: 'report',
    // eslint-disable-next-line camelcase
    instnace_uid: getCrmInstanceUid()
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doFetch: () => dispatch(fetchData()),
    doSelectedItem: item => dispatch(selectedItem(item)),
    doUnselectedItem: () => dispatch(unselectedItem()),
    doEditing: () => dispatch(startEditingItem()),
    doCancelEditing: () => dispatch(cancelEditingItem()),
    doResetForm: () => dispatch(reset('crmSettingReport')),
    doCreateItem: () => dispatch(startCreatingItem()),
    doCancelCreating: () => dispatch(cancelCreatingItem()),
    doSaveCreating: formValues => dispatch(saveCreatingItem(formValues)),
    doSaveEditing: formValues => dispatch(saveEditingItem(formValues)),
    doPush: url => dispatch(push(url))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(getPackageItemHoc(CrmReportsSettings)))
