import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { FormattedRelativeTime, FormattedDate, FormattedTime } from 'react-intl'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import { AccountListPageQL } from 'crm-data/accounts'
import { getAccountStatus } from 'crm-utils/accounts-utils'

const qlOptions = {
  options: ownProps => ({
    variables: {
      filter: ownProps.filter,
      load_data: ownProps.loadData
    }
  }),
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.account_list_page : []
  })
}

export const columnAcountHealth = {
  Header: 'Health',
  accessor: 'kpi_health',
  Cell: row => {
    const opacity = Math.max(
      Math.min(Math.abs(row.original.kpi_health) / 50, 1),
      0.2
    )
    if (!row.original.kpi_health) {
      return <div />
    }
    return (
      <div>
        <div
          style={{ opacity }}
          className={`AccountHealthMarker ${
            row.original.kpi_health < 0
              ? 'AccountHealthMarker--sick'
              : 'AccountHealthMarker--healthy'
          }  `}
          title={`Account health: ${row.original.kpi_health}`}
        />
      </div>
    )
  },
  maxWidth: 50,
  show: false
}

const Table = genericTableFactory({
  query: AccountListPageQL,
  qlOptions
})

const numberCell = prop => (
  <span className="util-textRight util-fullWidth">{prop.value}</span>
)

const TableInner = props => {
  const getTrProps = (state, rowInfo) => {
    if (rowInfo === undefined) {
      return {}
    }
    const accountStatus = getAccountStatus(rowInfo.original)
    const rowClassName = `AccountsTable-row-${accountStatus}`
    return {
      className: rowClassName,
      onClick: () => {
        props.onAccountSelected(rowInfo.original)
      }
    }
  }

  const buildAccountName = row => {
    if (!row.original.account_name) {
      return 'NAME MISSING'
    }
    let result = row.original.account_name
    const phoneContacts = row.original.contact_details
      ? row.original.contact_details.filter(
          v => v.kind === 'phone' || v.kind === 'mobile'
        )
      : []
    const isPhoneExistInName =
      phoneContacts.filter(v => row.original.account_name.includes(v.value))
        .length > 0
    const isLongName = row.original.account_name.length > 25
    if (!isLongName && !isPhoneExistInName) {
      const mobile = phoneContacts.filter(v => v.kind === 'mobile')
      const phone = phoneContacts.filter(v => v.kind === 'phone')
      const displayPhone =
        mobile.length > 0
          ? mobile[0].value
          : phone.length > 0
          ? phone[0].value
          : ''
      result += displayPhone
        ? `  <span class='util-marginLeft'><i class="fa fa-phone-square"></i></span> <span>${displayPhone}</span>`
        : ''
    }
    const isGoogle = row.original.lead_source_type_name === 'AdWords'
    const isFacebook = row.original.lead_source_type_name === 'Facebook'

    result = isGoogle
      ? `<span style='color:#EC5426'><i class='fa fa-google'></i></span> ${result}`
      : result
    result = isFacebook
      ? `<span style='color:#4267b2'><i class='fa fa-facebook-square'></i></span> ${result}`
      : result

    return result
  }

  const availableColumns = [
    columnAcountHealth,
    {
      Header: 'Name',
      accessor: 'account_name',
      Cell: row => {
        // eslint-disable-next-line
        if (props.isModalMode) return row.original.account_name

        return (
          <Link
            to={'/crm/account/' + row.original.account_uid}
            title={
              row.original.duplicate_of_account_uid ? 'This is a duplicate' : ''
            }
          >
            {row.original.duplicate_of_account_uid && (
              <FontAwesomeIcon icon="clone" style={{ marginRight: 5 }} />
            )}
            <div dangerouslySetInnerHTML={{ __html: buildAccountName(row) }} />
          </Link>
        )
      }
    },
    {
      Header: 'City',
      accessor: 'city',
      maxWidth: 150
    },
    {
      Header: 'Kind',
      accessor: 'kind',
      maxWidth: 100
    },
    {
      Header: 'Status',
      accessor: 'sales_status_code',
      maxWidth: 100
    },
    {
      Header: 'Owner',
      accessor: 'owner_full_name',
      maxWidth: 180,
      Cell: row => row.original?.owner_group_name ?? row.original?.owner_full_name
    },
    {
      Header: 'Assigned At',
      accessor: 'assigned_time',
      Cell: row =>
        row.original.assigned_time ? (
          <React.Fragment>
            <FormattedDate value={row.original.assigned_time} />{' '}
            <FormattedTime value={row.original.assigned_time} />
          </React.Fragment>
        ) : (
          ''
        ),
      maxWidth: 140
    },

    {
      Header: 'Last Activity At',
      accessor: 'last_activity_time',
      Cell: row => {
        if (row.original && row.original.last_activity_time) {
          return (
            <span>
              <FormattedDate value={row.original.last_activity_time} />{' '}
              <FormattedTime value={row.original.last_activity_time} />
            </span>
          )
        }
        return <span />
      },
      maxWidth: 180
    },
    {
      Header: 'Last Activity Note',
      accessor: 'last_activity_note',
      maxWidth: 180
    },
    {
      id: 'last_activity_comment',
      accessor: 'last_activity_comment',
      Header: 'Activity Note',
      Cell: row => {
        if (row.original.last_activity) {
          return <small>{row.original.last_activity.note_text}</small>
        }
      },
      maxWidth: 250,
      show: false
    },
    {
      Header: 'Lead Campaign',
      accessor: 'lead_campaign_name',
      maxWidth: 180
    },
    {
      Header: 'Lead Source',
      accessor: 'lead_source_name',
      maxWidth: 180
    },
    {
      Header: 'Lead in',
      accessor: 'lead_in_time',
      maxWidth: 140,
      Cell: row => (
        <span className="util-textRight util-fullWidth">
          {row.value ? (
            <React.Fragment>
              <FormattedDate value={row.value} />{' '}
              <FormattedTime value={row.value} />
            </React.Fragment>
          ) : (
            ''
          )}
        </span>
      ),
    },
    {
      Header: 'Potential',
      accessor: 'market_potential',
      maxWidth: 100,
      Cell: props => {
        const value = get(props, 'value.size', 0)
        return numberCell({ value: value > 0 ? value : null })
      }
    }
  ]

  return (
    <Table
      {...props}
      getTrProps={getTrProps}
      columns={availableColumns}
      tableName="account"
      translation
    />
  )
}
export default TableInner
