import { useCrmToastr, CrmToastrType, CrmToastrPosition } from '@cartrack-crm/ui/src/toastrs'
import { useCrmGetErrorTrace } from '@cartrack-crm/ui/src/error-handler/useCrmErrorHandler'
import React from 'react'
import * as Sentry from '@sentry/browser'

export const useHandleErrorException = () => {
  const [showToast, hideToast] = useCrmToastr()
  const [errorTrace] = useCrmGetErrorTrace()
  const type = CrmToastrType.error
  const sendErrorToSentry = (err: any) => {
    Sentry.captureException(new Error(err))
    hideToast()
  }
  const getTraceAsList = (trace) => {
    return (<ul>{trace.map((item, index) => { return (<li className='text-detail-line' key={`${index}-${item}`}>{item}</li>) })}</ul>)
  }

  const toastrHandleErrorException = (err: any = null, title: string = 'An error has occurred from your last action.', timeToShowToast: number = null) => {
    const errorOptions = {
      btnPrimaryTitle: 'Report',
      btnSecondaryTitle: 'Ignore',
      message: err.message,
      onBtnPrimary: () => sendErrorToSentry(err),
      onBtnSecondary: () => hideToast(),
      textDetailContent: getTraceAsList(errorTrace),
      textDetailTitle: 'advance',
      timeToShow: timeToShowToast,
      position: CrmToastrPosition.middle
    }
    showToast(type, title, errorOptions)
  }


  return [toastrHandleErrorException]
}