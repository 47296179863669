import React from 'react'
import useCrmCurrentUser from 'crm-core/auth/hooks/use-crm-current-user'
import { QL_LIST_OPPORTUNITIES } from '../../../forecasts/useFetchForecastManager'
import { useGetNewCustomerWonByMonth } from '../wins/widget-customers-won-hoc'
import { useQuery } from '@apollo/react-hooks'
import { groupDataByMonth, calculatePercentInt } from 'crm-utils/data-utils'
import { createDashboardFormats, getWidgetAnalyticContext } from '../../dashboards/tools'
import { DashboardResponse, HeadCount, GQLQueryRes } from 'crm-types'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import moment from 'moment'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export interface widgetSaleToWinRatioHocProps {
  avgSaleToWinRatioThreeMonth: number
  bigContent: boolean
  isLoading?: boolean
  mainCaption?: string
  onClickDetail?: Function
  refetchData?: () => any
  threeMonthAgoSaleToWinRatio: any[any]
  totalSaleToWinRatio: number
}

export type useGetSaleToWinByMonth = (
  numberOfMonth?: number,
  headcount?: HeadCount[]
) => DashboardResponse & GQLQueryRes

type calculatePercentFromWonOpportunitiesAndNewCustomerWon = (
  opportunitiesWon: DashboardResponse & GQLQueryRes,
  newCustomerWon: DashboardResponse & GQLQueryRes
) => {
  isLoading: boolean
  totalCurrentMonth: number | string
  avgPassedMonths: string
  dataPassedMonths: any[any]
}

export const useGetSaleToWinByMonth: useGetSaleToWinByMonth = (numberOfMonth = 3, headcounts) => {
  const analyticsContext = getWidgetAnalyticContext()

  const { DATE_FORMAT, endDate, userUid: owner_user_uid } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const filter = {
    completed_time: {
      $lte: endDate,
      $gte: startDate
    },
    is_existing_customer: false,
    is_deleted: false,
    owner_user_uid: owner_user_uid || useCrmCurrentUser()?.user_uid,
    status_code: 'won'
  }

  const { data, loading, refetch } = useQuery(QL_LIST_OPPORTUNITIES, {
    variables: {
      filter,
      limit: 10000
    },
    skip: !filter.owner_user_uid,
    fetchPolicy: 'no-cache'
  })

  const listOpportunities = data?.opportunities?.edges?.map(e => e.edge) ?? []
  const grouped = groupDataByMonth(listOpportunities, 'completed_time', 'vehicle_count')

  return {
    refetch,
    loading,
    ...createDashboardFormats(grouped, numberOfMonth, moment(endDate).format('YYYY-MM'), headcounts, 'Agent')
  }
}

const widgetSaleToWinRatioHoc = (Component: any) => {
  const Wrapper = props => {
    const calculatePercentDataPassedMonth = (opportunitiesMonthPassed, newCustomersMonthPassed) => {
      let percentCalculated = []

      Object.keys(opportunitiesMonthPassed).forEach(key => {
        percentCalculated[key] = `${calculatePercentInt(newCustomersMonthPassed[key], opportunitiesMonthPassed[key])} %`
      })

      return percentCalculated
    }

    const calculatePercentFromWonOpportunitiesAndNewCustomerWon: calculatePercentFromWonOpportunitiesAndNewCustomerWon = (
      opportunitiesWon,
      newCustomerWon
    ) => {
      const isLoading = opportunitiesWon.loading && newCustomerWon.loading
      const currentMonthWonOpportunity = opportunitiesWon.totalLatestMonth
      const totalPassedMonthOpportunity = opportunitiesWon.totalPassedMonths
      const dataPassedMonthOpportunity = opportunitiesWon.dataPassedMonths
      const currentMonthNewCustomerWon = newCustomerWon.totalLatestMonth
      const totalPassedMonthNewCustomerWon = newCustomerWon.totalPassedMonths
      const dataPassedMonthNewCustomerWon = newCustomerWon.dataPassedMonths
      const dataPassedMonths = calculatePercentDataPassedMonth(
        dataPassedMonthOpportunity,
        dataPassedMonthNewCustomerWon
      )
      const totalCurrentMonth = `${calculatePercentInt(currentMonthNewCustomerWon, currentMonthWonOpportunity)} %`
      const avgPassedMonths = `${calculatePercentInt(totalPassedMonthNewCustomerWon, totalPassedMonthOpportunity)} % `

      return { isLoading, totalCurrentMonth, avgPassedMonths, dataPassedMonths }
    }

    const bigContent = props.headcounts
    const { analyticsContext, headcounts } = props
    const newCustomerWon = useGetNewCustomerWonByMonth(3, headcounts)
    const wonOpportunities = useGetSaleToWinByMonth(3, headcounts)

    const {
      avgPassedMonths,
      dataPassedMonths,
      totalCurrentMonth,
      isLoading
    } = calculatePercentFromWonOpportunitiesAndNewCustomerWon(wonOpportunities, newCustomerWon)

    const refetchData = async () => {
      await wonOpportunities.refetch()
      await newCustomerWon.refetch()
    }

    const onClickDetail = (monthSelected = null) => {
      const { endDate, userUid: owner_user_uid } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)

      const filters = {
        completed_time: convertMonthToDateRange(endDate),
        is_existing_customer: false,
        is_deleted: false,
        owner_user_uid: headcounts ? undefined : owner_user_uid || useCrmCurrentUser()?.user_uid,
        status_code: 'won'
      }

      if (monthSelected) {
        filters.completed_time = monthSelected
      }

      props.onShowLeadsPopup({ type: 'opportunity', filters })
    }

    return (
      <Component
        avgSaleToWinRatioThreeMonth={avgPassedMonths}
        bigContent={bigContent}
        isLoading={isLoading}
        refetchData={refetchData}
        threeMonthAgoSaleToWinRatio={dataPassedMonths}
        totalSaleToWinRatio={totalCurrentMonth}
        onClickDetail={onClickDetail}
        mainCaption={wonOpportunities.mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetSaleToWinRatioHoc
