/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import moment from 'moment'
import { shape, func, string } from 'prop-types'
import { mapContextToFilter } from '../../../dashboard-utils'

import CrmWidget from '../../../components/crm-widget.jsx'
import { qlqTasksTodoListPaged } from 'crm-data/tasks'
import { arraySum } from 'crm-utils/data-utils'
import { CrmHeatmapChart } from 'crm-components'

class WidgetAgentsCallbacks extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderSummary = () => (
    <div>
      {this.state.totalDispositions && (
        <span>Total Callbacks: {this.state.totalDispositions}</span>
      )}
    </div>
  )

  handleRefresh = async () => {
    console.log('widget WidgetAgentsCallbacks refresh', this.props)
    try {
      const filter = mapContextToFilter(this.props, false)
      console.log('this.props.analyticsContext', this.props.analyticsContext)
      if (
        !this.props.analyticsContext.filters ||
        !this.props.analyticsContext.filters.activity_date
      ) {
        return
      }
      filter.task_due_date = this.props.analyticsContext.filters.activity_date
      if (filter.user_uid) {
        filter.assignee_user_uid = filter.user_uid
      }

      filter.assignee_type = 'user'
      const variables = {
        filter,
        fields: ['task_owner_name', 'task_due_day'],
        aggregate: [{ id: 'task_uid', type: 'count' }]
      }

      const res = await this.props.client.query({
        query: qlqTasksTodoListPaged,
        variables,
        fetchPolicy: 'no-cache'
      })

      console.log('Got result', res)
      if (res.data.tasks_todo_list_qm_paged) {
        const ldata = res.data.tasks_todo_list_qm_paged.data.map(i => ({
          ...i,
          task_due_day: i.task_due_day
            ? moment(i.task_due_day).format('YYYY-MM-DD')
            : undefined
        }))
        this.setState({
          data: ldata,
          totalTasks: arraySum(ldata, 'task_uid')
        })
      }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }
  handleCellClicked = cell => {
    console.log('handleClickBar', cell)
    const filters = {
      ...this.props.analyticsContext.filters,
      task_owner_name: cell.task_owner_name,
      task_due_day: cell.task_due_day,
      assignee_type: 'user'
    }
    this.props.onAnalyticsRangeSelected({ type: 'task', filters })
  }
  handleGetRawData = () => this.state.data
  render() {
    console.log('Render widget new leads by current status', this.state)
    return (
      <CrmWidget
        title={this.props.title ? this.props.title : 'Agents Callbacks Stats'}
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-agents-dispositions"
      >
        {!this.state.data && (
          <span>No data - select filters and press refresh to load</span>
        )}
        {this.state.data && (
          <CrmHeatmapChart
            xAxisField="task_due_day"
            yAxisField="task_owner_name"
            valueField="task_uid"
            data={this.state.data}
            onCellClicked={this.handleCellClicked}
          />
        )}
      </CrmWidget>
    )
  }
}

WidgetAgentsCallbacks.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  title: string,
  onAnalyticsRangeSelected: func.isRequired
}
WidgetAgentsCallbacks.defaultProps = {
  title: undefined
}

export default withApollo(WidgetAgentsCallbacks)
