import React from 'react'
import { shape, func } from 'prop-types'
import {
  Bar,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
  ComposedChart,
  Line,
  Area
} from 'recharts'

class CombinedChartWidget extends React.PureComponent<any, any> {
  _mapData() {
    return []
  }
  handleClick = (data, index) => {
    console.log('handleClick', data, index, this.props)
    const d = { ...data.payload }
    if (this.props.onAnalyticsItemClicked) {
      this.props.onAnalyticsItemClicked(d)
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log('Combined Chart Widget will receive props', nextProps)
  }
  render() {
    const chartProps =
      this.props.item && this.props.item.props
        ? this.props.item.props.chartProps
        : undefined
    const data = this.props.value
      ? this.props.value.map((i) => ({ ...i, value: Number(i.value) }))
      : []
    console.log('render ChartColumns', this.props, data, chartProps)
    return (
      <div style={{ width: '100%', height: '250px' }}>
        <ResponsiveContainer width="90%" height="90%">
          <ComposedChart data={data} layout="horizontal">
            <XAxis
              type="category"
              label="Date"
              dataKey={chartProps.xaxis.fieldName}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {chartProps &&
              chartProps.series &&
              chartProps.series.map((series) => {
                if (series.type === 'bars') {
                  return (
                    <Bar
                      key={series.key}
                      dataKey={series.fieldName}
                      fill={series.color ? series.color : '#8884d8'}
                      isAnimationActive={false}
                      label={series.name}
                      onClick={this.handleClick}
                    />
                  )
                }
                if (series.type === 'line') {
                  return (
                    <Line
                      key={series.key}
                      type="monotone"
                      label={series.name}
                      dataKey={series.fieldName}
                      stroke={series.color ? series.color : '#ff7300'}
                      isAnimationActive={false}
                    />
                  )
                }
                if (series.type === 'area') {
                  return (
                    <Area
                      key={series.key}
                      type="monotone"
                      label={series.name}
                      dataKey={series.fieldName}
                      fill={series.color ? series.color : '#ff7300'}
                      isAnimationActive={false}
                    />
                  )
                }
                return null
              })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

// CombinedChartWidget.propTypes = {
//   value: shape().isRequired,
//   item: shape().isRequired,
//   onAnalyticsItemClicked: func
// }
// CombinedChartWidget.defaultProps = {
//   onAnalyticsItemClicked: undefined
// }
export default CombinedChartWidget
