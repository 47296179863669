/* eslint-disable camelcase */

// const fitmentsDone = {
//   columns_dims: [
//     {
//       dim_code: 'date_fitted',
//       kpi_code: 'fitment_details',
//       name: 'Date Fitted',
//       generate_values: true
//     }
//   ],
//   rows_dims: [
//     {
//       dim_code: 'owner_user',
//       kpi_code: 'fitment_details',
//       name: 'User'
//     }
//   ],
//   kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
//   title: 'Fitments by RM',
//   filters: [
//     {
//       kpi_code: 'fitment_details',
//       dim_code: 'date_fitted',
//       context: true,
//       context_filter_code: 'date_range',
//       operator: 'eq'
//     }
//   ]
// }

const totalFitmentsMtd = {
  kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
  title: 'Fitments MTD',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OrdersListQM',
          alias: 'OrdersListQM',
          filter: {},
          fields: [],
          aggregate: [{ id: 'vehicles_count', type: 'sum' }],
          contextMapping: {
            completed_date: 'activity_date'
          }
        }
      ],
      aggregates: {
        fittedMtd: {
          type: 'sum',
          dataSource: 'OrdersListQM',
          valueField: 'vehicles_count',
          aggregateBy: 'owner_user_uid'
        }
      }
    },
    layout: {
      items: [
        {
          type: 'container',
          direction: 'row',
          items: [
            {
              type: 'labeledField',
              props: {
                value: {
                  type: 'aggregates',
                  dataField: 'fittedMtd'
                },
                label: 'Fitted MTD'
              },
              link: {
                to: ['opportunities', 'list'],
                params: {
                  viewCode: 'overdue'
                }
              },
              style: {
                flexGrow: true
              }
            },
            {
              type: 'labeledField',
              props: {
                value: 30,
                label: 'Target MTD'
              },
              style: {
                flexGrow: true
              }
            }
          ]
        }
      ]
    }
  }
}

const fitmentsByDate = {
  kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
  title: 'Fitments by Date (orders)',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OrdersListQM',
          alias: 'OrdersListQM',
          filter: { status_code: 'completed' },
          fields: ['completed_date'],
          aggregate: [{ id: 'units_fitted', type: 'sum' }],
          sort: [{ id: 'completed_date', desc: true }],
          contextMapping: {
            completed_date: 'activity_date'
          }
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            columns: [
              {
                header: 'Fitment Date',
                accessor: 'completed_date'
              },
              {
                header: 'Units Fitted',
                accessor: 'units_fitted'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'OrdersListQM'
            }
          }
        }
      ]
    }
  }
}

const fitmentsByRmUser = {
  kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
  title: 'Fitments by RM',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OrdersListQM',
          alias: 'OrdersListQM',
          filter: {},
          fields: ['owner__full_name'],
          aggregate: [{ id: 'units_fitted', type: 'sum' }],
          order: [{ id: 'units_fitted', desc: true }],
          contextMapping: {
            completed_date: 'activity_date'
          }
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            columns: [
              {
                header: 'Account owner',
                accessor: 'account__owner__full_name'
              },
              {
                header: 'Units Fitted',
                accessor: 'units_fitted'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'OrdersListQM'
            }
          }
        }
      ]
    }
  }
}

// Fitments By Rm TOP 5
const fitmentsByRmUserTop = {
  kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
  title: 'Fitments by RM (Chart)',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OrdersListQM',
          alias: 'OrdersListQM',
          filter: {},
          fields: ['owner__full_name'],
          aggregate: [{ id: 'units_fitted', type: 'sum' }],
          order: [{ id: 'units_fitted', desc: true }],
          contextMapping: {
            completed_date: 'activity_date'
          }
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'chartBars',
          def_version: 5,
          props: {
            chart: {
              labelField: 'owner__full_name',
              valueField: 'units_fitted'
            },
            data: {
              type: 'dataSource',
              dataSourceCode: 'OrdersListQM'
            }
          }
        }
      ]
    }
  }
}
// By lead source type
const fitmentsByLeadSourceType = {
  kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
  title: 'Fitments by Lead Source',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OrdersListQM',
          alias: 'OrdersListQM',
          filter: {},
          fields: ['account__lead_campaign__lead_source__name'],
          aggregate: [{ id: 'units_fitted', type: 'sum' }],
          order: [{ id: 'units_fitted', desc: true }],
          contextMapping: {
            completed_date: 'activity_date'
          }
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            columns: [
              {
                header: 'Lead source type',
                accessor: 'account__lead_campaign__lead_source__name'
              },
              {
                header: 'Units Fitted',
                accessor: 'units_fitted'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'OrdersListQM'
            }
          }
        }
      ]
    }
  }
}

const fittedUnitsDetails = {
  title: 'Fitted Units Details',
  dataSource: {
    dataSources: [
      {
        code: 'OrdersListQM',
        alias: 'OrdersListQM',
        filter: {},
        fields: [
          'account__lead_campaign__lead_source__kind',
          'account__lead_campaign__lead_source__name',
          'account__lead_campaign__name',
          'account__name',
          'account_uid',
          'owner__full_name',
          'completed_date'
        ],
        order: [{ id: 'completed_date', desc: true }],
        contextMapping: {
          completed_date: 'activity_date'
        }
      }
    ],
    layout: 'pivot'
  },
  layout: 'flat_table',
  layoutParams: {
    columns: [
      'account__lead_campaign__lead_source__kind',
      'account__lead_campaign__lead_source__name',
      'account__lead_campaign__name',
      'account__name',
      'account__owner__full_name',
      'completed_date'
    ]
  },
  version: 3
}

const dashFitments = {
  dashboard_uid: 'dashFitmentsOverview',
  name: 'Fitments Overview',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'container',
        class: 'row util-marginBottom',
        items: [
          {
            type: 'container',
            class: 'col-md-3',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: totalFitmentsMtd
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-3',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: fitmentsByDate
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-3',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: fitmentsByRmUser
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-3',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: fitmentsByLeadSourceType
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-3',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: fitmentsByRmUserTop
              }
            ]
          }
        ]
      },
      {
        type: 'analitics_widget',
        widgetDefinition: fittedUnitsDetails
      }
    ]
  }
}

export default dashFitments
