import { CrmDropdown, CrmTextInput, CrmFormInputDropdown } from 'crm-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import generateInlineEditableField from 'crm-components/hoc/inline-editable-component-web-hoc'
import editContactDetailItemHoc, {
  saveHandler,
  deleteHandler
} from 'crm-modules/accounts/hoc/edit-contact-detail-item-hoc.js'
import React from 'react'
import { CrmEmailInput } from '@cartrack-crm/ui'

class ContactDetailItemInlineEditable extends React.PureComponent {
  renderInner() {
    const contactDetail = this.props.value
    const icon = this.props.icons[contactDetail.kind]
      ? this.props.icons[contactDetail.kind]
      : this.props.icons.unknown
    let validType = 'unknown'
    let validClass = ''
    if (
      contactDetail.validated_time &&
      (contactDetail.validated_result === 'valid' ||
        contactDetail.validated_result === undefined)
    ) {
      validType = 'valid'
      validClass = 'util-textOk'
    }
    if (contactDetail && contactDetail.validated_result === 'wrong_number') {
      validType = 'invalid'
      validClass = 'util-textRed'
    }

    return (
      <React.Fragment>
        <div className={`util-flexRow ${validClass}`}></div>
        <div className={`util-flexRow ${validClass}`}>
          <div className="AccountLeftSide-contactIcon">
            <i className={icon.classA} />
          </div>
          <div className={`LabeledField-value ${validClass}`}>
            {contactDetail.value}
          </div>
        </div>
      </React.Fragment>
    )
  }
  render() {
    return <div>{this.renderInner()}</div>
  }
}

class ContactDetailItemEditComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      contactDetail: { ...props.input.value },
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.input.value) !== JSON.stringify(this.props.index.value)) {
      this.setState({ contactDetail: nextProps.input.value })
    }
  }

  handleKindChange = async kind => {
    const contactDetail = { ...this.props.input.value, kind }
    const oldKind = this.props.input.value.kind
    if (oldKind !== kind) {
      contactDetail.value = ''
      contactDetail.country_code = ''
    }
    this.props.input.onChange(contactDetail)
  }

  handleEmailChange = event => {
    const contactDetail = {
      ...this.props.input.value,
      value: event.currentTarget.value
    }
    this.props.input.onChange(contactDetail)
  }

  handleValueChange = event => {
    const contactDetail = {
      ...this.props.input.value,
      value: event.currentTarget.value
    }
    this.props.input.onChange(contactDetail)
  }

  handleCountryCodeChange = country_code => {
    const contactDetail = { ...this.props.input.value, country_code }
    this.props.input.onChange(contactDetail)
  }

  render() {
    const contactDetail = this.state.contactDetail
    const disabled = true
    return (
      <div className="row">
        <div className="row col-md-12">
          <div className="col-md-6">
            <CrmDropdown
              title={this.props.intl.formatMessage({
                id: 'crm.account.contact_type',
                defaultMessage: 'Contact Type'
              })}
              placeholder={
                <FormattedMessage
                  id="crm.account.contact_type"
                  defaultMessage="Contact Types"
                />
              }
              addEmptyOption
              options={this.props.options}
              input={{
                value: contactDetail.kind,
                onChange: this.handleKindChange
              }}
            />
          </div>
          <div className="col-md-6">
            {!contactDetail.kind || contactDetail.kind !== 'email' && contactDetail.kind !== 'line' &&
              <CrmFormInputDropdown
                title={this.props.intl.formatMessage({
                  id: 'crm.account.country_code',
                  defaultMessage: 'Country Code'
                })}
                placeholder={
                  <FormattedMessage
                    id="crm.account.country_code"
                    defaultMessage="Country Code"
                  />
                }
                disabled={contactDetail.kind === 'email' ? disabled : !disabled}
                options={this.props.countries}
                input={{
                  value:
                    contactDetail.kind === 'email'
                      ? ''
                      : contactDetail.country_code,
                  onChange: this.handleCountryCodeChange
                }}
                addEmptyOption
                emptyOptionLabel="-"
              />
            }
          </div>
        </div>
        <div className="row col-md-12">
          <div className="col-md-11">
              {contactDetail.kind !== 'email' && (
                <CrmTextInput
                  placeholder={
                    <FormattedMessage
                      id="crm.account.contact"
                      defaultMessage="Contact"
                    />
                  }
                  input={{
                    value: contactDetail.value ?? '',
                    onChange: this.handleValueChange
                  }}
                  focus={true}
                />
              )}
              {contactDetail.kind === 'email' && (
                <CrmEmailInput
                  placeholder={
                    <FormattedMessage
                      id="crm.account.contact"
                      defaultMessage="Contact"
                    />
                  }
                  input={{
                    value: contactDetail.value ?? '',
                    onChange: this.handleEmailChange
                  }}
                  focus={true}
                />
              )}
          </div>
        </div>
      </div>
    )
  }
}

const inlineEditable = injectIntl(
  generateInlineEditableField(ContactDetailItemInlineEditable, {
    editComponent: editContactDetailItemHoc(ContactDetailItemEditComponent),
    saveHandler,
    deleteHandler
  })
)
export default inlineEditable
