import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './section-address.scss'

class MeetingAddressItem extends React.PureComponent {
  _addrFormatLine2 = addr => {
    let label = ''
    if (addr.line2) {
      label += addr.line2
    }
    if (addr.suburb) {
      label += (label.length > 0 ? ', ' : '') + addr.suburb
    }
    if (addr.town) {
      label += (label.length > 0 ? ', ' : '') + addr.town
    }
    if (addr.province) {
      label += (label.length > 0 ? ', ' : '') + addr.province
    }
    if (addr.postcode) {
      label += (label.length > 0 ? ', ' : '') + addr.postcode
    }
    return label
  }

  handleBodyClick = (e, address) => {
    if (this.props.handleAddMeetingAddress) {
      this.props.handleAddMeetingAddress(e, address.address_uid)
    }
  }

  render() {
    const { address } = this.props

    return (
      <React.Fragment>
        <div
          className={`ActivityListItem-headerItem-value ${styles.AddressItem} ${styles.MeetingAddressItem}`}
          style={
            this.props.caller === 'meetingForm'
              ? { border: 'solid 1px #eeeeee' }
              : {}
          }
          onClick={e => this.handleBodyClick(e, address)}
        >
          <div className={`${styles.MeetingAddressItem__ContentWrapper}`}>
            <div className={`${styles.MeetingAddressItem__Content}`}>
              <div className={`${styles.Headquater}`}>
                {address.address_kind === 'headquarter' ? (
                  <FormattedMessage
                    id="crm.ui.address.form.headquater"
                    defaultMessage="Headquater"
                  />
                ) : (
                  ''
                )}
              </div>
              <div className={`${styles.Addr1}`}>
                <div className="row">
                  <div className="col-md-12">
                    {address.is_primary && (
                      <div>
                        <h4 style={{ textAlign: 'left' }}> Primary </h4>
                      </div>
                    )}
                    <div>
                      {address.location &&
                        address.location.lat &&
                        address.location.lng && (
                          <span
                            title={`${address.location.lat} ${address.location.lng}`}
                          >
                            <FontAwesomeIcon
                              className="util-textGreen"
                              icon="map-marker-alt"
                            />
                            &nbsp;
                          </span>
                        )}
                      {address.line1}
                    </div>
                    <div>{this._addrFormatLine2(address)}</div>
                  </div>
                </div>
                <div className={`${styles.City}`}>{address.city}</div>
              </div>
            </div>
            <div className={`${styles.MeetingAddressItem__ContentAction}`}>
              {address.isMeetingAddress && (
                <React.Fragment>
                  <div className={styles.MeetingAddressItem__Top}>
                    {this.props.caller === 'meetingForm' && (
                      <div
                        className={styles.MeetingAddressItem__RemoveIcon}
                        onClick={e =>
                          this.props.handleRemoveMeetingAddress(
                            e,
                            address.address_uid
                          )
                        }
                        title="Click to remove meeting address"
                      >
                        <FontAwesomeIcon
                          className="util-marginLeft"
                          icon="times"
                        />
                      </div>
                    )}
                    {this.props.caller === 'accountPage' && (
                      <div
                        className={styles.MeetingAddressItem__RemoveIcon}
                        onClick={this.props.handleStartEdit}
                        title="Click to edit address"
                      >
                        <FontAwesomeIcon
                          className="util-marginLeft"
                          icon="pencil-alt"
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.MeetingAddressItem__SelectedIcon}>
                    <FontAwesomeIcon
                      className="util-marginLeft"
                      icon="address-card"
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MeetingAddressItem
