import { CrmDictionaryValueSelect, CrmCampaignSelect } from 'crm-components'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { shape, func, bool } from 'prop-types'
import AccountInlineEditable from './account-inline-editable.jsx'
import { getDateTimeFormat } from 'crm-utils/time-utils'

class AccountMiddleColumn extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { account, handleStartEdit, handleEndEdit } = this.props
    return (
      <div className="AccountMiddleColumn">
        <div className="row">
          <div className="col-md-12">
            <AccountInlineEditable
              account={account}
              fieldName="lead_in_time"
              label={
                <FormattedMessage
                  id="crm.ui.account.info.lead_received_at"
                  defaultMessage="Lead Received At"
                />
              }
              value={getDateTimeFormat(account.lead_in_time)}
              editableAsManager={this.props.isManager}
              readModeStyle="CrmLabeledField"
              handleStartEdit={
                this.props.isManager ? handleStartEdit : () => {}
              }
              handleEndEdit={this.props.isManager ? handleEndEdit : () => {}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AccountInlineEditable
              account={account}
              fieldName="lead_campaign_uid"
              label={
                <FormattedMessage
                  id="crm.ui.account.info.lead_source"
                  defaultMessage="Lead Campaign"
                />
              }
              value={account.lead_campaign ? account.lead_campaign.name : ''}
              editableAsManager={this.props.isManager}
              readModeStyle="CrmLabeledField"
              editComponent={CrmCampaignSelect}
              editComponentProps={{
                placeholder: (
                  <FormattedMessage
                    id="crm.ui.account.form.lead_source_select"
                    defaultMessage="Lead Campaign Select"
                  />
                )
              }}
              handleStartEdit={
                this.props.isManager ? handleStartEdit : () => {}
              }
              handleEndEdit={this.props.isManager ? handleEndEdit : () => {}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AccountInlineEditable
              account={account}
              fieldName="industry_uid"
              label={
                <FormattedMessage
                  id="crm.ui.account.info.industry"
                  defaultMessage="Industry"
                />
              }
              value={account.industry ? account.industry.name : ''}
              editable={true}
              readModeStyle="CrmLabeledField"
              editComponent={CrmDictionaryValueSelect}
              editComponentProps={{
                dictionaryCode: 'industries',
                placeholder: (
                  <FormattedMessage
                    id="crm.ui.account.form.industry"
                    defaultMessage="Industry"
                  />
                )
              }}
              handleStartEdit={handleStartEdit}
              handleEndEdit={handleEndEdit}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AccountInlineEditable
              account={account}
              fieldName="website"
              label={
                <FormattedMessage
                  id="crm.ui.account.info.website"
                  defaultMessage="Website"
                />
              }
              value={account.website ? account.website : ''}
              editable={true}
              readModeStyle="CrmLabeledField"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AccountInlineEditable
              account={account}
              fieldName="registration_number"
              label={
                <FormattedMessage
                  id="crm.ui.account.info.registration_number"
                  defaultMessage="Reg. Number"
                />
              }
              value={account.registration_number}
              editable={true}
              readModeStyle="CrmLabeledField"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AccountInlineEditable
              account={account}
              fieldName="cams_username"
              label={
                <FormattedMessage
                  id="crm.ui.account.info.cams_username"
                  defaultMessage="Cams Username"
                />
              }
              value={account.cams_username}
              editable={true}
              readModeStyle="CrmLabeledField"
            />
          </div>
        </div>
      </div>
    )
  }
}

AccountMiddleColumn.propTypes = {
  account: shape({}).isRequired,
  handleStartEdit: func,
  handleEndEdit: func,
  isManager: bool
}
AccountMiddleColumn.defaultProps = {
  handleStartEdit: undefined,
  handleEndEdit: undefined,
  isManager: false
}

export default injectIntl(AccountMiddleColumn)

// {/* <div className="row">
//     <div className="col-md-12">
//     <span>
//       <FormattedMessage
//         id="crm.ui.account.info.due_date"
//         defaultMessage="DUE DATE"
//       />
//     </span>
//     <div className="ActivityListItem-headerItem-value">
//       <FormattedDateTime
//         value={account.lead_in_date}
//         dateFormat="YYYY-MM-DD"
//       />
//     </div>
//   </div>
// </div> */}

// {/* <div className="row">
//   <div className="col-md-12">
//     <span>
//       <FormattedMessage
//         id="crm.ui.account.info.subsidiary_company"
//         defaultMessage="SUBSIDIARY COMPANY"
//       />
//     </span>
//     <div className="ActivityListItem-headerItem-value">
//       {account.category ? account.category : '-'}
//     </div>
//   </div>
// </div> */}
