import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const useQlSchema = () => {
  const QL_LOAD_SCHEMA = gql(`query schema {
    __schema {
      types {
        name
        fields {
          name
          description
          type {
            name
            kind
            ofType {
                name
            }
          }
          args {
            name
            description
            type {
              name
              description
              ofType {
                name
              }
            }
          }
        }
      }

      queryType {
        name
        description
        fields {
          name
          description
          __typename
          type {
            __typename
            name
          }
          args {
            name
            description
            type {
              name
              kind
              description
              fields {
                name
                description
                args {
                  name
                  description
                  type {
                    name
                    kind
                    ofType {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`)

  const { data } = useQuery(QL_LOAD_SCHEMA, {})

  var schema = {
    types: {},
    queries: {}
  }

  if (data) {
    if (data?.__schema?.types) {
      data.__schema.types.forEach(field => {
        if (field.name) {
          var typeDefinition = {
            name: field.name,
            type: field.type,
            is_connection: field.name.indexOf('Connection') >= 0,
            args: field.args
          }

          schema.types[field.name] = typeDefinition
        }
      })
    }

    if (data?.__schema?.queryType) {
      data.__schema.queryType.fields.forEach(field => {
        if (field.name) {
          var queryDefinition = {
            name: field.name,
            type: field.type,
            description: field.description,
            is_connection: field.name.indexOf('Connection') >= 0,
            args: field.args
          }
          schema.queries[field.name] = queryDefinition
        }
      })
    }
  }

  return schema
}
