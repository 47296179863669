import React from 'react'
import { array, bool, string, object, oneOfType } from 'prop-types'
import { CrmDropdown, CrmLabeledField } from 'crm-components'

const GenericDropdown = props => {
  function rebuildOptions() {
    let options = [...props.options]
    if (options && options.length > 0 && options[0].name) {
      options = options.sort((a, b) =>
        a.name ? a.name.localeCompare(b.name) : 0
      )
    }
    return options
  }

  const style = {
    minWidth: props.width ? `${props.width}px` : 'auto'
  };

  if (props.labeled) {
    console.log(props)
    const options = rebuildOptions()
    let selected = options.find(o => o.value === props.input.value)
    if (!selected) {
      selected = props.input.value
    } else {
      selected = selected.name
    }
    return (
      <CrmLabeledField label={props.placeholder}>{selected}</CrmLabeledField>
    )
  }

  return <CrmDropdown {...props} options={rebuildOptions()} addEmptyOption style={style} />
}

GenericDropdown.propTypes = {
  options: array.isRequired,
  labeled: bool,
  placeholder: oneOfType([string, object])
}

export default GenericDropdown
