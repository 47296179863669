import React, { useState } from 'react'
import ReactTable from 'react-table'
import { FormattedMessage } from 'react-intl'
import { getIdFormat } from '../utils/inital-component'
import { Spinner } from '../spinner/spinner'

function createColumnHeaders(props) {
  return props.columns
    ? props.columns.map(column => ({
        ...column,
        Header:
          typeof column.Header === 'string' && props.tableName !== undefined && props.translation ? (
            <FormattedMessage id={getTranslationKey(column.Header, props.tableName)} defaultMessage={column.Header} />
          ) : (
            column.Header
          )
      }))
    : []
}

function getTranslationKey(key, tableName) {
  return 'crm.ui.table.' + tableName + '.header.' + getIdFormat(key)
}

function getLoadingProps() {
  return { className: 'react-table-spinner' }
}

const Noop = () => null

const CrmTable = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const page = props.pages <= currentPage ? props.pages - 1 : currentPage
  const hasColumnGroup = props.columns && props.columns.length > 0 && props.columns[0].columns

  return (
    <ReactTable
      {...props}
      ref={props.tableRef}
      columns={
        hasColumnGroup
          ? createColumnHeaders(props)
          : [
              {
                Header: '',
                columns: createColumnHeaders(props)
              }
            ]
      }
      className={`${props.className} react-table -striped -highlight`}
      LoadingComponent={Spinner}
      getLoadingProps={getLoadingProps}
      NoDataComponent={Noop}
      onPageChange={page => {
        setCurrentPage(page)
      }}
      page={page}
      onSortedChange={props.onSortedChange ? props.onSortedChange : undefined}
    />
  )
}

export default CrmTable
