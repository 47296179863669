import React, { useState } from 'react'

export interface withLayoutActionProps {
  dataSource: any
  layout: any
  onSaveLayout: Function
  onSaveItem: Function
}

export const useLayoutItemAction = (props) => {
  const [layout, setLayout] = useState(props.layout)
  const onSaveLayout = (newLayout) => {
    setLayout(newLayout)
  }
  const onSaveItem = (value, field, index) => {
    const pLayout = { ...layout }
    pLayout.items[index][field] = value
    console.log(pLayout)
    onSaveLayout(pLayout)
    props.onSaveLayout ? props.onSaveLayout(pLayout) : null
  }
  return { layout, onSaveLayout, onSaveItem }
}

const withLayoutItemAction = (Component) => {
  return (props) => {
    const hocProps = useLayoutItemAction(props)
    return <Component {...props} {...hocProps} />
  }
}

export default withLayoutItemAction