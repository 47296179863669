/* eslint-disable camelcase */

import { getMasterUidFromToken } from 'crm-api/auth'

// Actions
export const CRM_TEST = 'CRM_TEST'
export const CRM_INITIAL_STEP_CHANGE = 'CRM_INITIAL_STEP_CHANGE'
export const CRM_INITIAL = 'CRM_INITIAL'
export const CRM_INITIALIZATION_COMPLETED = 'CRM_INITIALIZATION_COMPLETED'
export const CRM_LOGIN = 'CRM_LOGIN'
export const CRM_LOGIN_SUCCESS = 'CRM_LOGIN_SUCCESS'
export const CRM_LOGIN_LOADING = 'CRM_LOGIN_LOADING'
export const CRM_LOGIN_FAILURE = 'CRM_LOGIN_FAILURE'
export const CRM_LOGGED_OUT = 'CRM_LOGGED_OUT'
export const FETCH_AVAILABLE_INSTANCES = 'FETCH_AVAILABLE_INSTANCES'
export const FETCH_AVAILABLE_INSTANCES_SUCCESS =
  'FETCH_AVAILABLE_INSTANCES_SUCCESS'

export const CHANGE_SELECTED_INSTANCE = 'CHANGE_SELECTED_INSTANCE'
export const CHANGE_SELECTED_INSTANCE_SUCCESS =
  'CHANGE_SELECTED_INSTANCE_SUCCESS'
export const FETCH_MY_PERMISSIONS_2019 = 'FETCH_MY_PERMISSIONS_2019'
export const FETCH_MY_PERMISSIONS_2019_SUCCESS =
  'FETCH_MY_PERMISSIONS_2019_SUCCESS'
export const CRM_LOGIN_STATUS_CHANGED = 'CRM_LOGIN_STATUS_CHANGED'
export const TOGGLE_SIDE_WINDOW = 'TOGGLE_SIDE_WINDOW'
export const CRM_CURRENT_USER_CHANGED = 'CRM_CURRENT_USER_CHANGED'
export const WINDOW_SIZE_CHANGE = 'WINDOW_SIZE_CHANGE'
export const TOUCH_OUTSIDE_NAVIGATION_AREA = 'TOUCH_OUTSIDE_NAVIGATION_AREA'
export const MOBILE_NAVIGATION_VISIBLE = 'MOBILE_NAVIGATION_VISIBLE'
export const MOBILE_NAVIGATION_HIDDEN = 'MOBILE_NAVIGATION_HIDDEN'
export const TOGGLE_SELECT_TERRITORY_MODAL = 'TOGGLE_SELECT_TERRITORY_MODAL'
export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS'
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS'
export const FETCH_USER_INFO = 'FETCH_USER_INFO'
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS'

// Reducer

const token = window.localStorage.getItem('crmAccessToken')
const masterUid = token ? getMasterUidFromToken(token) : undefined

const initialState = {
  error: {
    code: '',
    message: ''
  },
  user: window.localStorage.getItem('crmUser')
    ? JSON.parse(window.localStorage.getItem('crmUser'))
    : undefined,
  availableInstances: [],
  selectedInstance: window.localStorage.getItem('crm_instance')
    ? JSON.parse(window.localStorage.getItem('crm_instance'))
    : undefined,
  masterCustomer: masterUid
    ? {
        master_uid: masterUid,
        name: 'Master'
      }
    : undefined,
  userInfo: undefined,
  myPermissions: [],
  hasSideWindow: false,
  isMobile: false,
  windowSize: {},
  mobileNavigationVisible: false,
  loginFailed: false,
  isSelectTerritoryModalVisible: false,
  userSettings: undefined,
  initializationStatus: false,
  initialCurrentStep: 'INIT_STAGE_INITIAL',
  myPermissionsLoaded: false,
  myPermissions2019Loaded: false,
  userInfoLoaded: false,
  initialStates: {
    INIT_STAGE_INITIAL: {
      next: 'INIT_STAGE_LOGIN'
    },
    INIT_STAGE_LOGIN: {
      next: 'INIT_STAGE_INSTANCE'
    },
    INIT_STAGE_INSTANCE: {
      next: 'LOAD_CONFIGURATION'
    },
    LOAD_CONFIGURATION: {
      next: 'INIT_STAGE_READY'
    },
    INIT_STAGE_READY: {}
  }
}

export default function reducer(state = initialState, action) {
  // eslint-disable-line
  switch (action.type) {
    case CRM_INITIAL:
      return { ...state }
    case CRM_INITIALIZATION_COMPLETED:
      return { ...state, initializationStatus: true }
    case CRM_INITIAL_STEP_CHANGE:
      return { ...state, initialCurrentStep: action.payload.step }
    case CRM_TEST:
      return {
        ...state,
        user: null
      }
    case FETCH_AVAILABLE_INSTANCES_SUCCESS:
      return {
        ...state,
        availableInstances: action.payload.availableInstances
      }
    case CHANGE_SELECTED_INSTANCE_SUCCESS:
      return {
        ...state,
        selectedInstance: action.payload.instance,
        isSelectTerritoryModalVisible: false
      }
    case FETCH_MY_PERMISSIONS_2019_SUCCESS: {
      return {
        ...state,
        myPermissions2019Loaded: true,
        myPermissions2019: action.payload.myPermissions2019
      }
    }
    case TOGGLE_SIDE_WINDOW: {
      return {
        ...state,
        hasSideWindow: action.payload.hasSideWindow
      }
    }
    case CRM_CURRENT_USER_CHANGED: {
      return {
        ...state
      }
    }
    case CRM_LOGIN: {
      return {
        ...state,
        loginFailed: false
      }
    }
    case CRM_LOGIN_FAILURE: {
      return {
        ...state,
        error: {
          message: action.payload.error.message,
          code: action.payload.error.code
        },
        loginFailed: true
      }
    }
    case CRM_LOGIN_SUCCESS: {
      return {
        ...state,
        error: {
          message: '',
          code: ''
        },
        user: action.payload.user,
        loginFailed: false
      }
    }
    case CRM_LOGIN_LOADING: {
      return {
        ...state,
        loginLoading: action.payload.loginLoading
      }
    }
    case CRM_LOGGED_OUT: {
      return {
        ...state,
        user: undefined,
        loginFailed: false
      }
    }
    case WINDOW_SIZE_CHANGE: {
      return {
        ...state,
        isMobile: action.isMobile,
        windowSize: Object.assign({}, action.size)
      }
    }
    case MOBILE_NAVIGATION_VISIBLE: {
      return {
        ...state,
        mobileNavigationVisible: true
      }
    }
    case MOBILE_NAVIGATION_HIDDEN: {
      return {
        ...state,
        mobileNavigationVisible: false
      }
    }
    case TOGGLE_SELECT_TERRITORY_MODAL: {
      return {
        ...state,
        isSelectTerritoryModalVisible:
          action.payload.isSelectTerritoryModalVisible
      }
    }
    case FETCH_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        userSettings: action.payload.userSettings
      }
    }

    case FETCH_USER_INFO_SUCCESS: {
      return {
        ...state,
        userInfo: action.payload.userInfo,
        userInfoLoaded: true
      }
    }

    default:
      return state
  }
}

// Action Creators
export function login(params) {
  return {
    type: CRM_LOGIN,
    payload: {
      username: params.username,
      password: params.password,
      company_code: params.company_code
    }
  }
}
export const initialCrm = () => ({
  type: CRM_INITIAL
})

export const currentUserChanged = () => ({
  type: CRM_CURRENT_USER_CHANGED
})
export function fetchAvailableInstances() {
  return {
    type: FETCH_AVAILABLE_INSTANCES
  }
}
export function fetchMyPermissions2019() {
  return {
    type: FETCH_MY_PERMISSIONS_2019
  }
}
export function changeSelectedInstance(instance) {
  return {
    type: CHANGE_SELECTED_INSTANCE,
    payload: {
      instance
    }
  }
}
export function toggleSideWindow(hasSideWindow) {
  return {
    type: TOGGLE_SIDE_WINDOW,
    payload: {
      hasSideWindow
    }
  }
}

export const toggleSelectTerritoryModal = isSelectTerritoryModalVisible => ({
  type: TOGGLE_SELECT_TERRITORY_MODAL,
  payload: {
    isSelectTerritoryModalVisible
  }
})
// T O D O: Finish
export function saveProfile() {
  return true
}

export function onSave(data) {
  return {
    type: CRM_TEST,
    payload: {
      data
    }
  }
}

export function toggleMabileNavigation(enable) {
  if (enable) {
    return {
      type: MOBILE_NAVIGATION_VISIBLE
    }
  }

  return {
    type: MOBILE_NAVIGATION_HIDDEN
  }
}

export function touchOutsideNavigationBar() {
  return {
    type: TOUCH_OUTSIDE_NAVIGATION_AREA
  }
}

export function windowSizeChange(size) {
  // eslint-disable-next-line
  const isMobile = size.width <= 600 ? true : false
  return {
    type: WINDOW_SIZE_CHANGE,
    size,
    isMobile
  }
}
export const fetchUserSettings = () => ({
  type: FETCH_USER_SETTINGS
})

// Selectors
export const getUser = state => state.user.user
export const getSelectedInstance = state => state.crm.crm.selectedInstance
export const getMasterCustomer = state => state.crm.crm.masterCustomer
export const getInstanceParameter = (state, key) => {
  if (
    state.crm &&
    state.crm.crm &&
    state.crm.crm.selectedInstance &&
    state.crm.crm.selectedInstance.details &&
    state.crm.crm.selectedInstance.details.params
  ) {
    const params = state.crm.crm.selectedInstance.details.params
    for (let i = 0; i < params.length; i++) {
      if (params[i].code === key) {
        if (params[i].type === 'boolean') {
          return params[i].value === 'true'
        }
        return params[i].value
      }
    }
  }
  return undefined
}
export const getAvailableInstances = state => state.crm.crm.availableInstances
export const getMyPermissions2019 = state =>
  state.crm.crm.myPermissions2019 ? state.crm.crm.myPermissions2019 : []
export const getMyPermissions2019Loaded = state =>
  state.crm.crm.myPermissions2019Loaded
export const getHasSideWindow = state => state.crm.crm.hasSideWindow
export const getIsMobile = state => state.crm.crm.isMobile
export const getMobileNavigationVisible = state =>
  state.crm.crm.mobileNavigationVisible
export const getLoginFailed = state => state.crm.crm.loginFailed
export const getLoginLoading = state => state.crm.crm.loginLoading
export const getError = state => state.crm.crm.error
export const getIsSelectTerritoryModalVisible = state =>
  state.crm.crm.isSelectTerritoryModalVisible
export const getUserSettings = state => state.crm.crm.userSettings
export const getUserInfoLoaded = state => state.crm.crm.userInfoLoaded
export const getUserInfo = state => state.crm.crm.userInfo
export const getCrmInitializationStatus = state =>
  state.crm.crm.initializationStatus
export const getCrmInitialStep = state => state.crm.crm.initialCurrentStep
export const getCrmInitialStates = state => state.crm.crm.initialStates
