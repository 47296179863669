import React from 'react'
import LeadsGroupedGenericWidget from './leads-grouped-generic-widget.jsx'

const LeadsStatsByOwner = props => (
  <LeadsGroupedGenericWidget
    {...props}
    groups={['owner_user_uid', 'owner_user_name']}
    labelField="owner_user_name"
  />
)

export const LeadsStatsByReasonLost = props => (
  <LeadsGroupedGenericWidget
    {...props}
    groups={['opportunity_reason_lost']}
    labelField="opportunity_reason_lost"
    title="By Reason Lost"
  />
)

export default LeadsStatsByOwner
