/* eslint-disable */
export const buildCrmReducer = (reducer, actions, initialState) => {
  const handlers = {}
  const updaters = {}
  for (const k in actions) {
    if (actions[k]) {
      const create = payload => {
        if (actions[k].creator) {
          //  T O D O - run payload creator
        }
        return {
          type: k,
          payload
        }
      }
      handlers[k] = actions[k].handler // T O D O - may have some handler generators
      if (actions[k].updater) {
        updaters[k] = actions[k].updater
      }
      actions[k] = {
        type: k,
        create
      }
    }
  }

  const newReducer = (state, action) => {
    let newState = state ? { ...state } : undefined
    if (newState) {
      if (handlers[action.type]) {
        newState = handlers[action.type](state, action)
      }
      if (updaters[action.type]) {
        const diff = updaters[action.type](action)
        if (diff) {
          newState = diff ? { ...state, ...diff } : { ...state }
        }
      }
    }
    return reducer(newState, action)
  }
  return newReducer
}

export const bindActions = (dispatch, actions) => {
  const ret = {}
  Object.keys(actions).forEach(k => {
    ret[k] = function(data) {
      dispatch(actions[k].create(data))
    }
  })
  return ret
}
