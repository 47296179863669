/* eslint-disable */
import obj2arg from 'graphql-obj2arg'
import cloneDeep from 'clone-deep'
import apiCaller, { callQLApi, callQLWithMetaApi, callGooglePlaceSerach } from './api-utils'
import { parseAccount, parseQualification } from './api-parsers'
import { qlActivityWithFields } from './activities-api'
import { leadSourceAllFields } from './settings/lead-sources-api'
import { qlResource } from './admin/resources-api'
import { qlDocument } from './documents-api'
import {
  qlProcessTypeFields,
  qlProcessStepFields,
  qlProcessOwnFields,
  qlProcessStepTypeDetails
} from '../data/processes-fragments'
import { qlPersonAllFields, qlqAccountsListQM, AccountListPageQL } from 'crm-data/accounts'

export const qlProcessAllFields = ` ${qlProcessOwnFields} 
process_type { ${qlProcessTypeFields} } 
current_step { ${qlProcessStepFields} process_step_type { name kind is_initial_step is_final_step is_active }   } 
owner_user { full_name } `

export { qlPersonAllFields } from 'crm-data/accounts'

export const qlAccountAllFields = ` 
  permissions  { 
    user_uid permission_code filters access_mode ownership_rule module profile_uid 
  } 
  account_uid cams_username name kind is_deleted merged_onto_account_uid
  territory_uid remark name_local lead_source_type_uid campaign_uid
  lead_in_date created_time registration_number meta category
  created_by_user { user_uid login full_name } 
  website phone {contact_details_uid account_uid value validated_time validated_by_user { full_name } } 
  mobile_phone {contact_details_uid account_uid value validated_time validated_by_user { full_name } }
  email {contact_details_uid  account_uid value validated_time validated_by_user { full_name } } 
  `

const qlAccountsListFields = ` account_uid name kind is_deleted name_local lead_in_date 
  created_time campaign{ name }  
  address: addresses{ city } 
  opportunities { opportunity_uid } 
  last_activity { ${qlActivityWithFields} } 
  market_potential { size other_size }`

export const qlAddressAllFields =
  ' address_uid account_uid city street province suburb is_valid street_number house_number postcode country_code address_kind town remark location line1 line2 is_primary '

export const qlContactDetailsAllFields =
  ' contact_details_uid account_uid instance_uid address_uid kind value country_code remarks is_valid validated_time validated_by_user { full_name } '

const qlActorAllFields = ' user { full_name } role_code is_valid valid_from valid_till '
export const qlCampaignAllFields = ' campaign_uid instance_uid name code description closed_at start_date end_date '

export const qlMarketPotentialWithFields = ` market_potential { size other_size } `

export const qlAccountFull = ` ${qlAccountAllFields}
addresses { ${qlAddressAllFields} }
activities { ${qlActivityWithFields} }
last_activity { ${qlActivityWithFields} }
contact_details {${qlContactDetailsAllFields} }
persons {${qlPersonAllFields} contact_details {${qlContactDetailsAllFields} } }
campaign { ${qlCampaignAllFields} }
territory { territory_uid name }
industry { name }
opportunities { 
  opportunity_uid name value value_currency due_date confidence_level vehicle_count
  opportunity_type { name }
  owner_user_uid owner_user { user_uid full_name }
  process { ${qlProcessAllFields} }
}
documents { ${qlDocument} }
processes { ${qlProcessAllFields} }
tasks { task_uid name assignments { due_date user { user_uid full_name } }  } 
 ${qlMarketPotentialWithFields} `

const qlAccountLight = `  ${qlAccountAllFields} `

export function countAccountsApi(lparams) {
  const params = { ...lparams }

  if (!params.pagination) {
    params.pagination = {}
  }

  params.pagination.pageSize = 0

  let query = `query account_list_page(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
  ) {
    account_list_page(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
    ) { count _dev_meta } } `

  return callQLWithMetaApi(query, {
    filter: lparams.filter,
    limit: 0,
    offset: 0
  }).then(res => {
    return res.data.account_list_page.count
  })
}

export default {
  getAccountLight: id => {
    const query = `{ accounts(account_uid:"${id}") { ${qlAccountLight} } } `
    return callQLApi(query).then(res => (res.accounts.length > 0 ? parseAccount(res.accounts[0]) : undefined))
  },
  getAccountApi: (id, params) => {
    const query = `{ accounts(account_uid:"${id}") { ${qlAccountFull}   }  files : resources(account_uid:"${id}"){ ${qlResource} } } `
    return callQLApi(query).then(res =>
      res.accounts.length > 0 ? Object.assign({ files: res.files }, parseAccount(res.accounts[0])) : undefined
    )
  },
  listAccountsApi: () => apiCaller('ac_api_account_list', {}).then(res => res.ac_api_account_list.map(parseAccount)),

  createAccountApi: ({ account }) => {
    const qlParams = {
      query: ` mutation  account_create($account:account_in) { account_create(account: $account) { ${qlAccountFull} }  } `,
      variables: {
        account: {
          name: account.name,
          name_local: account.name_local,
          territory_uid: account.territory_uid,
          industry_uid: account.industry_uid,
          addresses: account.addresses,
          contact_details: account.contact_details,
          persons: account.persons,
          lead_campaign_uid: account.lead_campaign_uid,
          lead_in_date: account.lead_in_date,
          registration_number: account.registration_number,
          cams_username: account.cams_username,
          is_assign_to_me: account.is_assign_to_me,
          phone: account.phone,
          mobile_phone: account.mobile_phone,
          email: account.email,
          website: account.website
        }
      }
    }

    return callQLApi(qlParams).then(res => {
      return res.account_create
    })
  },
  performAccountAction: params => {
    return apiCaller('ac_api_perform_action', params).then(res => parseAccount(res.ac_api_perform_action))
  },
  fetchCampaigns: () => {
    const qlQuery = `{ campaigns { campaign_uid, name lead_source_uid } } `
    return callQLApi(qlQuery).then(res => res.campaigns)
  },
  addContactDetailsAccount: (account_uid, contact_details) => {
    const qlParams = {
      query: ` mutation  account_update($account:account_in) { account_update(account: $account) { ${qlAccountFull} }  } `,
      variables: {
        account: {
          account_uid,
          contact_details
        }
      }
    }

    return callQLApi(qlParams).then(res => {
      return res.account_update
    })
  },

  searchPlaceApi: query => {
    return callGooglePlaceSerach(query).then(res => {
      return res
    })
  },

  updateAccount: account => {
    const contactDetails = account.contact_details ? cloneDeep(account.contact_details) : []

    contactDetails.forEach(cd => {
      delete cd.validated_by_user // eslint-disable-line
    })

    const persons = account.persons ? cloneDeep(account.persons) : []

    persons.forEach(p => {
      if (p.contact_details) {
        p.contact_details.forEach(cd => {
          delete cd.validated_by_user // eslint-disable-line
        })
      }
    })

    const qlParams = {
      query: ` mutation  account_update($account:account_in) { account_update(account: $account) { ${qlAccountFull} }  } `,
      variables: {
        account: {
          name: account.name,
          account_uid: account.account_uid,
          name_local: account.name_local,
          kind: account.kind,
          is_deleted: account.is_deleted,
          lead_in_date: account.lead_in_date,
          territory_uid: account.territory_uid,
          lead_campaign_uid: account.lead_campaign_uid,
          cams_username: account.cams_username,
          addresses: account.addresses,
          registration_number: account.registration_number,
          address_delete_ids: account.addresses_delete ? account.addresses_delete : '',
          contact_details: contactDetails,
          contact_delete_ids: account.contact_details_delete ? account.contact_details_delete : '',
          persons,
          person_delete_ids: account.persons_delete ? account.persons_delete : '',
          website: account.website,
          industry_uid: account.industry_uid,
          remark: account.remark
        }
      }
    }

    return callQLApi(qlParams).then(res => {
      return res.account_update
    })
  },
  verifyContact: (pContact, verified) => {
    const contact = {
      contact_details_uid: pContact.contact_details_uid,
      value: pContact.value
    }
    const qlParams = {
      query: ` mutation  verifyContact($contact:ContactDetailsIn!, $verified: Boolean!) 
        { verifyContact(contact: $contact, verified: $verified) { ${qlContactDetailsAllFields} }  } `,
      variables: {
        contact,
        verified
      }
    }

    return callQLApi(qlParams).then(res => {
      return res.verifyContact
    })
  }
}
