import React from 'react'
import { string } from 'prop-types'
import { getApiRootUrl } from 'crm-api/api-utils'

class AvatarPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.prefixLink = `${getApiRootUrl()}crm_resources.php?file=`
  }

  getImgClass = () => (this.props.class ? this.props.class : 'userAvatar')

  combineUrl = () =>
    this.props.src.indexOf('blob') >= 0 || this.props.src.indexOf('http') >= 0
      ? this.props.src
      : `${this.prefixLink}${
          this.props.src
        }&token=${window.localStorage.getItem('crmAccessToken')}`

  render() {
    return this.props.src ? (
      <img
        src={this.combineUrl()}
        className={this.getImgClass()}
        style={this.props.style}
      />
    ) : (
      <i className="fa fa-user util-textLarge"></i>
    )
  }
}

AvatarPreview.propTypes = {
  src: string.isRequired,
  class: string
}

AvatarPreview.defaultProps = {
  class: undefined
}

export default AvatarPreview
