export function hasPermission(userPermission: any, permissionCode: string, accessMode: string, module: string | any[]) {
  const permissions = [...userPermission]
  let hasPermission = false
  permissions.map((v) => {
    const isMatchPermissionCode = v.permission_code === permissionCode
    const isMatchAccessMode = v.access_mode[accessMode]
    const isMatchModule =
      v.module === '*' ? true : Array.isArray(module) ? module.includes(v.module) : module === v.module
    if (isMatchPermissionCode && isMatchAccessMode && isMatchModule) {
      hasPermission = true
    }
  })
  return hasPermission || hasSuperAdminPermission(userPermission)
}

export function hasSuperAdminPermission(userPermission: any) {
  let hasPermission = false
  userPermission.map((v: any) => {
    if (v.permission_code === 'crm.admin' && v.access_mode.admin && v.module === '*') {
      hasPermission = true
    }
  })
  return hasPermission
}

export function hasPermissionByPermissionType(
  user_permissions: any,
  permission_type: {
    permission_code: string
    access_level: string
    modules: string | any[]
  },
  modules: string | any[]
) {
  const permissions = [...user_permissions]
  let has_permission = false
  permissions.map((v) => {
    const isMatchPermissionCode = v.permission_code === permission_type.permission_code
    const isMatchAccessMode = v.access_mode[permission_type.access_level]
    const match_modules = modules ? modules : permission_type.modules
    const isMatchModule =
      v.module === '*'
        ? true
        : Array.isArray(match_modules)
        ? match_modules.includes(v.module)
        : match_modules === v.module
    if (isMatchPermissionCode && isMatchAccessMode && isMatchModule) {
      has_permission = true
    }
  })
  return has_permission || hasSuperAdminPermission(user_permissions)
}

export const PERMISSIONS_TYPES = {
  ADMIN_READ: {
    permission_code: 'crm.admin',
    access_level: 'read',
    modules: ['*']
  },

  ADMIN_MANAGE: {
    permission_code: 'crm.admin',
    access_level: 'manage',
    modules: ['*']
  },

  SUPER_ADMIN: {
    permission_code: 'crm.admin',
    access_level: 'admin',
    modules: ['*']
  },

  PRICE_TABLE_MODIFY: {
    permission_code: 'crm.priceTables',
    access_level: 'modify',
    modules: ['*']
  },

  PRICE_TABLE_MANAGE: {
    permission_code: 'crm.priceTables',
    access_level: 'manage',
    modules: ['*']
  },

  GENERAL_DATA_MANAGE: {
    permission_code: 'crm.generalData',
    access_level: 'manage',
    modules: ['*']
  },

  GROUP_CALENDAR_READ: {
    permission_code: 'crm.groupCalendar',
    access_level: 'read',
    modules: ['*', 'sales']
  },

  CONFIG_PRODUCTS_MODIFY: {
    permission_code: 'crm.config.products',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_USERS_MODIFY: {
    permission_code: 'crm.config.users',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_USERS_MANAGE: {
    permission_code: 'crm.config.users',
    access_level: 'manage',
    modules: ['*']
  },

  CONFIG_USERS_GROUP_MODIFY: {
    permission_code: 'crm.config.userGroups',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_PROFILES_MODIFY: {
    permission_code: 'crm.config.profiles',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_LEAD_CAMPAIGN_MODIFY: {
    permission_code: 'crm.config.leadCampaign',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_SALES_CAMPAIGN_MODIFY: {
    permission_code: 'crm.config.salesCampaign',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_CAMPAIGN_COST_MODIFY: {
    permission_code: 'crm.config.campaignCost',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_TERRITORIES_MODIFY: {
    permission_code: 'crm.config.territories',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_TEMPLATES_MODIFY: {
    permission_code: 'crm.config.templates',
    access_level: 'modify',
    modules: ['*']
  },

  CONFIG_TRANSLATIONS_MODIFY: {
    permission_code: 'crm.config.translations',
    access_level: 'modify',
    modules: ['*']
  },

  EXPORT_DATA: {
    permission_code: 'crm.export_data',
    access_level: 'read',
    modules: ['*']
  },

  BULK_OPERATIONS: {
    permission_code: 'crm.bulk_operations',
    access_level: 'modify',
    modules: ['*']
  },

  MENU: {
    permission_code: 'crm.menu',
    access_level: 'read',
    modules: ['*']
  },

  ACCOUNT_DATA_MODIFY: {
    permission_code: 'crm.accountData',
    access_level: 'modify',
    modules: ['*', 'sales']
  },

  ACCOUNT_DATA_MANAGE: {
    permission_code: 'crm.accountData',
    access_level: 'manage',
    modules: ['*', 'sales']
  },

  ACCOUNT_REASSIGN_MODIFY: {
    permission_code: 'crm.account.reassign',
    access_level: 'modify',
    modules: ['*', 'sales']
  },

  ACCOUNT_REASSIGN_MANAGE: {
    permission_code: 'crm.account.reassign',
    access_level: 'manage',
    modules: ['*', 'sales']
  },

  ADDITIONAL_OWNER_MODIFY: {
    permission_code: 'crm.account.reassign',
    access_level: 'modify',
    modules: ['*']
  },

  ADDITIONAL_OWNER_MANAGE: {
    permission_code: 'crm.account.reassign',
    access_level: 'manage',
    modules: ['*']
  },

  QUOTATION_MODIFY: {
    permission_code: 'crm.quotation',
    access_level: 'modify',
    modules: ['*']
  },

  QUOTATION_MANAGE: {
    permission_code: 'crm.quotation',
    access_level: 'manage',
    modules: ['*']
  },

  OPPORTUNITIES_READ: {
    permission_code: 'crm.opportunities',
    access_level: 'read',
    modules: ['*']
  },

  OPPORTUNITIES_MODIFY: {
    permission_code: 'crm.opportunities',
    access_level: 'modify',
    modules: ['*']
  },

  OPPORTUNITIES_MANAGE: {
    permission_code: 'crm.opportunities',
    access_level: 'manage',
    modules: ['*']
  },

  ACTIVITIES_READ: {
    permission_code: 'crm.activities',
    access_level: 'read',
    modules: ['*']
  },

  ACTIVITIES_RECORDING_READ: {
    permission_code: 'crm.activities.recording',
    access_level: 'read',
    modules: ['*']
  },

  EXCEL_EXPORT: {
    permission_code: 'crm.dashboard.excelExport',
    access_level: 'read',
    modules: ['*']
  },

  ORDERS_MANAGE: {
    permission_code: 'crm.orders',
    access_level: 'manage',
    modules: ['*']
  },

  QUOTATION_MANAGE_COMMISSION_AND_SPEC: {
    permission_code: 'crm.quotation.commission.and.special',
    access_level: 'manage',
    modules: ['*']
  }
}
