import React from 'react'
import { object } from 'prop-types'
import widgetDefinition from 'crm-modules/dashboard/hoc/ct-dashboards/rm-details/rm-lead-results-widget'
import { GenericWidgetWeb } from '@cartrack-crm/crm-analytics'

const RmLeadResults = props => {
  const handleAnalyticsItemClicked = () => {}
  const filters = {}

  return (
    <GenericWidgetWeb
      {...props}
      widgetDefinition={widgetDefinition}
      key={widgetDefinition.code}
      analyticsContext={props.analyticsContext}
      filters={filters}
      onAnalyticsItemClicked={handleAnalyticsItemClicked}
      noHeight
      noFilterBar
    />
  )
}

RmLeadResults.propTypes = {
  analyticsContext: object.isRequired
}

export default RmLeadResults
