import React from 'react'
import useCrmCurrentUser from 'crm-core/auth/hooks/use-crm-current-user'
import { groupDataByMonth } from 'crm-utils/data-utils'
import { DashboardResponse, HeadCount, GQLQueryRes } from 'crm-types'
import moment from 'moment'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import { createDashboardFormats, useQueryActivity, getWidgetAnalyticContext } from '../../dashboards/tools'
import { mapContextToFilter } from '../../dashboard-utils'
import { buildFilterForActivities } from './activities-dashboard-utils'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export interface WidgetFollowUpMeetingClientStatusHocProps {
  avgFollowUpMeetingCurrentMonthPerPerson: number | null
  avgFollowUpMeetingPassedMonthData: number | any[any]
  avgFollowUpMeetingPersonPassedMonth: number | null
  avgFollowUpMeetingThreeMonth: number
  footerName: string | null
  footerNamePerPerson: string | null
  isLoading?: boolean
  refetchData?: () => any
  threeMonthAgoFollowUpMeeting: any[any]
  totalFollowUpMeeting: number
  onClickDetail: Function
  mainCaption?: string
}

export type useGetFollowUpMeetingClientDashboardByMonth = (
  numberOfMonth?: number,
  headcounts?: HeadCount[]
) => DashboardResponse & GQLQueryRes

export const useGetFollowUpMeetingClientDashboardByMonth: useGetFollowUpMeetingClientDashboardByMonth = (
  numberOfMonth,
  headcounts
) => {
  const analyticsContext = getWidgetAnalyticContext()
  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const userID = !userUid ? undefined : { user_uid: { value: userUid } }
  const user = headcounts ? undefined : userID

  const filter = {
    user,
    organization_unit,
    role_code: 'performer',
    account_is_deleted: false,
    activity: {
      activity_type: {
        medium_code: 'meeting'
      },
      status: {
        is_success: true
      },
      is_customer: true,
      first_type: 'follow_up',
      start_date: {
        $gte: startDate,
        $lte: endDate
      }
    }
  }

  const { listActivity, refetch, loading } = useQueryActivity(
    filter,
    ['start_date_month'],
    [{ type: 'count', id: 'activity_uid' }]
  )

  const grouped = groupDataByMonth(listActivity, 'start_date_month', 'activity_uid')

  return {
    refetch,
    loading,
    ...createDashboardFormats(grouped, numberOfMonth, moment(endDate, 'YYYY-MM-DD').format('YYYY-MM'), headcounts)
  }
}

const widgetFollowUpMeetingClientStatusHoc = (Component: any) => {
  const Wrapper = props => {
    const { headcounts } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      avgPassedMonths,
      avgPerPersonPassedMonth,
      dataAvgPerPersonPassedMonth,
      dataPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      totalLatestMonth,
      mainCaption
    } = useGetFollowUpMeetingClientDashboardByMonth(3, headcounts)
    const onClickDetail = (monthSelected = null) => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)

      const filters = {
        organization_unit,
        performer_user_uid: headcounts ? undefined : userUid,
        activity_date: convertMonthToDateRange(endDate),
        first_type: 'follow_up',
        role_code: 'performer',
        is_customer: true,
        status: { is_success: true },
        type: { medium_code: 'meeting' },
        used_user_uid: true
      }

      if (monthSelected) {
        filters.activity_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'activities', filters })
    }

    return (
      <Component
        avgFollowUpMeetingCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgFollowUpMeetingPassedMonthData={dataAvgPerPersonPassedMonth}
        avgFollowUpMeetingPersonPassedMonth={avgPerPersonPassedMonth}
        avgFollowUpMeetingThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        refetchData={() => refetch()}
        threeMonthAgoFollowUpMeeting={dataPassedMonths}
        totalFollowUpMeeting={totalLatestMonth}
        onClickDetail={onClickDetail}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetFollowUpMeetingClientStatusHoc
