import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { injectIntl } from 'react-intl'
import { AddressStyled } from './section-address.styled'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard'

interface IProps {
  address: any
  is_selected: boolean
  handleSelectAddress: Function
  intl: any
}

class AddressItem extends React.PureComponent<IProps> {
  _addrFormatLine2 = (addr) => {
    let label = ''
    if (addr.line2) {
      label += addr.line2
    }
    if (addr.suburb) {
      label += (label.length > 0 ? ', ' : '') + addr.suburb
    }
    if (addr.town) {
      label += (label.length > 0 ? ', ' : '') + addr.town
    }
    if (addr.province) {
      label += (label.length > 0 ? ', ' : '') + addr.province
    }
    if (addr.postcode) {
      label += (label.length > 0 ? ', ' : '') + addr.postcode
    }
    return label
  }

  handleBodyClick = (e, address) => {
    if (this.props.handleSelectAddress) {
      this.props.handleSelectAddress(e, address.address_uid)
    }
  }

  render() {
    console.log('render selected-address-item', this.state, this.props)
    const { address, is_selected } = this.props
    const selectedItemClass = is_selected ? 'SelectedItem' : ''
    return (
      <AddressStyled>
        <div
          className={`${'AddressItem'}  ${selectedItemClass}`}
          onClick={(e) => this.handleBodyClick(e, address)}
        >
          <div className={`${'AddressItem__ContentWrapper'}`}>
            <div className={`${'AddressItem__Content'}`}>
              <div className={`${'Headquater'}`}>
                {address.address_kind === 'headquarter'
                  ? this.props.intl.formatMessage({
                      id: 'crm.ui.address.form.headquater',
                      defaultMessage: 'Headquater'
                    })
                  : ''}
              </div>
              <div className={`${'Addr1'}`}>
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      {address.location &&
                        address.location.lat &&
                        address.location.lng && (
                          <span
                            title={`${address.location.lat} ${address.location.lng}`}
                          >
                            <FontAwesomeIcon
                              className="util-textGreen"
                              icon={faMapMarkerAlt}
                            />
                            &nbsp;
                          </span>
                        )}
                      {address.line1}
                    </div>
                    <div>{this._addrFormatLine2(address)}</div>
                  </div>
                </div>
                <div className={`${'City'}`}>{address.city}</div>
              </div>
            </div>
            <div className={`${'AddressItem__ContentAction'}`}>
              {is_selected && (
                <React.Fragment>
                  <div className={'AddressItem__SelectedIcon'}>
                    <FontAwesomeIcon
                      className="util-marginLeft"
                      icon={faAddressCard}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </AddressStyled>
    )
  }
}

export default injectIntl(AddressItem)
