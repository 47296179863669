/* eslint-disable camelcase */
import React from 'react'
import { func, shape, bool } from 'prop-types'
import { qlqFetchAccountAddresses } from 'crm-data/accounts'
import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

const accountAddressCrudHoc = WrappedComponent => {
  class AccountAddressCrudHocInner extends React.PureComponent {
    static propTypes = {
      process: shape({}).isRequired,
      onCancel: func,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      isEditing: false,
      gotoPage: undefined,
      newAccountUid: undefined,
      onCancel: undefined
    }
    constructor(props) {
      super(props)
      this.state = { isSaving: false }
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })
    handleSaveAddress = async pAddress => {
      if (!pAddress) {
        throw new Error('No addres passed')
      }
      if (!pAddress.account_uid) {
        throw new Error('Address is missing account_uid')
      }
      if (!pAddress.hasPrimary) {
        throw new Error('Please select at least one primary address')
      }
      const command = {
        type: pAddress.address_uid ? 'address.update' : 'address.create',
        aggregate_uid: pAddress.address_uid
          ? pAddress.address_uid
          : this.props.newObjectUid,
        aggregate_type: 'address',
        payload: {
          ...pAddress
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqFetchAccountAddresses,
            variables: {
              account_uid: pAddress.account_uid
            }
          }
        ]
        // console.log('Command', command)
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })

        // console.log('Address crud hoc result', res)
        return res.data.cqCommand
      } catch (err) {
        console.log('Address crud error', err)
        throw err
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onSaveAddress={this.handleSaveAddress}
          isSavingAddress={this.props.isSavingAddress}
        />
      )
    }
  }
  return crmGenericCommandHoc(AccountAddressCrudHocInner)
}

export const accountReassignHocPropTypes = {
  onReassignAccount: func.isRequired,
  isSavingReassignAccount: bool.isRequired,
  reassignAccountUser: shape()
}
export default accountAddressCrudHoc
