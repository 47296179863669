import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import AccountMiddleColumn from './account-middle-column.jsx'
import SectionAddresses from './section-addresses.jsx'
import SectionContact from './section-contact.jsx'
import { bool, shape } from 'prop-types'
import InfoNoAccountPermission from '../../account/components/info_no_account_permission.jsx'

class AccountLeftPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      account: props.account ? { ...props.account } : undefined,
      isEditing: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account !== this.props.account) {
      this.setState({
        account: nextProps.account ? { ...nextProps.account } : undefined
      })
    }
  }

  handleStartEdit = () => {
    this.setState({ isEditing: true })
  }

  handleEndEdit = () => {
    this.setState({ isEditing: false })
  }

  hasSelectedPrimaryAddress = () => {
    const { account } = this.state
    const hasPrimary = account.addresses.filter(
      v => v.is_primary && v.is_primary === true
    )
    //if has primary address return object, else return null
    return hasPrimary.length > 0 ? hasPrimary[0] : null
  }

  render() {
    if (this.props.hasAccountDataPermission === false) {
      return <InfoNoAccountPermission />
    }

    const { account, isEditing } = this.state
    return (
      <React.Fragment>
        {account && (
          <div className="Form-fieldset CrmForm-form-fieldset">
            <div className="row">
              <div
                className={isEditing ? 'col-md-7' : 'col-md-9'}
                style={{ paddingRight: '20px' }}
              >
                <div>
                  <SectionContact account={account} />
                </div>
                <hr className="util-horizontalLine" />
                <div>
                  <SectionAddresses
                    hasPrimaryAddress={this.hasSelectedPrimaryAddress()}
                    account_uid={account.account_uid}
                    addresses={
                      account.addresses
                        ? account.addresses.filter(v => v.address_uid)
                        : []
                    }
                  />
                </div>
                <br />
              </div>
              <div className={isEditing ? 'col-md-5' : 'col-md-3'}>
                <AccountMiddleColumn
                  account={account}
                  handleStartEdit={this.handleStartEdit}
                  handleEndEdit={this.handleEndEdit}
                  isManager={this.props.isManager}
                />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

AccountLeftPage.propTypes = {
  hasAccountDataPermission: bool,
  account: shape({}).isRequired,
  isManager: bool
}
AccountLeftPage.defaultProps = {
  hasAccountDataPermission: false,
  isManager: false
}

export default injectIntl(AccountLeftPage)
