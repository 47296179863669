import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedTime, FormattedRelativeTime } from 'react-intl'
import moment from 'moment'

export const columnsAccount = {
  description: 'Account',
  name: 'Account',
  type: 'Account',
  fields: [
    {
      name: 'name',
      column_label: 'Name',
      column_label_skip_except_root: true,
      description: 'Name',
      Cell: row => {
        if (!row || !row.original) {
          return <span />
        }
        const accountUid = row.original.account_uid || row.original.account_uid
        return (
          <Link to={'/crm/account/' + accountUid} target="blank">
            {row.original.name ? row.original.name : 'Account'}
          </Link>
        )
      },
      selections: [{ name: 'name' }, { name: 'account_uid' }],
      type: 'string'
    },
    {
      name: 'lead_in_date',
      description: 'Lead In Day',
      column_label: 'Lead In Day',
      column_label_skip_all_nested: true,
      type: 'string',
      Cell: row =>
        row && row.original && row.original.lead_in_date ? (
          <FormattedDate value={row.original.lead_in_date} />
        ) : (
          <span />
        )
    },
    {
      name: 'lead_in_time',
      description: 'Lead In Time',
      selections: [{ name: 'lead_in_time ' }],
      type: 'date',
      Cell: row =>
        row.original && row.original.lead_in_time ? (
          <React.Fragment>
            <FormattedDate value={row.original.lead_in_time} /> <FormattedTime value={row.original.lead_in_time} />
          </React.Fragment>
        ) : (
          <span />
        )
    },
    {
      name: 'lead_in_hour',
      description: 'Lead In Hour',
      selections: [{ name: 'lead_in_time ' }],
      type: 'string',
      Cell: row =>
        row.original && row.original.lead_in_time ? (
          <React.Fragment>
            <FormattedTime value={row.original.lead_in_time} />
          </React.Fragment>
        ) : (
          <span />
        )
    },
    {
      name: 'lead_age',
      description: 'Lead Age',
      selections: [{ name: ' lead_in_date ' }],
      type: 'string',
      Cell: row =>
        row.original && row.original.lead_in_date ? (
          <React.Fragment>
            <FormattedRelativeTime value={moment(row.original.lead_in_date).diff(moment(), 'days')} unit="day" />
          </React.Fragment>
        ) : (
          <span />
        )
    },
    {
      name: 'tags',
      description: 'All Tags',
      selections: [{ name: 'tags', selections: [{ name: 'name' }] }],
      type: 'string',
      Cell: row => {
        const tags = row.original && row.original.tags ? row.original.tags.map(el => el['name']) : ['No Tags']
        return tags.toString()
      }
    },
    {
      name: 'last_result',
      description: 'Last Result',
      selections: [{ name: 'last_sales_process' }],
      type: 'string',
      Cell: row => {
        if (row.original.last_sales_process) {
          return (
            <div
              title={`by ${row.original.last_sales_process.ownerName} at ${row.original.last_sales_process.completedTime}`}
            >
              {row.original.last_sales_process.current_step_type_name}
            </div>
          )
        }
        return <div>-</div>
      }
    },
    {
      name: 'sales_status_code',
      description: 'Sales Status',
      type: 'string'
    },
    // Start additional Block
    {
      name: 'name_local',
      description: 'Local name',
      type: 'string'
    },
    {
      name: 'kind',
      description: 'Kind',
      type: 'string'
    },
    {
      name: 'registration_number',
      description: 'Registration number',
      type: 'string'
    },
    {
      name: 'remark',
      description: 'Remark',
      type: 'string'
    },
    {
      name: 'category',
      description: 'Account category',
      type: 'string'
    },
    {
      name: 'cams_username',
      description: 'Cams username',
      type: 'string'
    },
    {
      name: 'detailed_status_code',
      description: 'Detailed Status Code',
      type: 'string'
    },
    {
      name: 'validated_date',
      description: 'Validated Date',
      type: 'date',
      Cell: row =>
        row.original && row.original.validated_date ? (
          <React.Fragment>
            <FormattedDate value={row.original.validated_date} />
          </React.Fragment>
        ) : (
          <span />
        )
    },
    {
      name: 'contract_email',
      description: 'Emails',
      type: 'string'
    },
    {
      name: 'contract_phone_number',
      description: 'Phone Numbers',
      type: 'string'
    },
    {
      name: 'meta',
      description: 'Meta',
      type: 'string'
    },
    {
      name: 'industry',
      description: 'Industry',
      type: 'Industry',
      column_label_prefix: 'Industry'
    },
    {
      name: 'lead_campaign',
      description: 'Lead Campaign',
      type: 'Campaign',
      column_label_prefix: 'Lead Campaign',
      column_label_skip_previous_label_prefix: true
    },
    {
      name: 'last_activity',
      description: 'Last Activity',
      type: 'Activity',
      column_label_prefix: 'Last Activity'
    },
    {
      name: 'first_activity',
      description: 'First Activity',
      type: 'Activity',
      column_label_prefix: 'First Activity'
    },
    {
      name: 'market_potential',
      description: 'Market Potential',
      type: 'MarketPotential',
      column_label_prefix: 'Market Potential'
    },
    {
      name: 'latest_owner',
      description: 'Owner',
      type: 'User',
      column_label_prefix: 'Latest Owner'
    },
    {
      name: 'finances_owner',
      description: 'Finances Owner',
      type: 'User',
      column_label_prefix: 'Finance Owner',
      column_label_skip_previous_label_prefix: true
    },
    {
      name: 'telemarketer_owner',
      description: 'Telemarketer Owner',
      type: 'User',
      column_label_prefix: 'Tele Owner'
    },
    {
      name: 'cs_owner',
      description: 'CS Owner',
      type: 'User',
      column_label_prefix: 'CS Owner'
    }
  ]
}

export const columnsMarketPotential = {
  type: 'MarketPotential',
  name: 'MarketPotential',
  description: 'MarketPotential',
  fields: [
    {
      name: 'size',
      type: 'string',
      description: 'Size'
    }
  ]
}

export const columnsOwner = {
  type: 'Owner',
  name: 'Owner',
  description: 'Owner',
  fields: [
    {
      queryFragment: {
        selections: [{ name: 'name' }, { name: 'uid' }, { name: 'type' }]
      },
      description: 'Name',
      name: 'name',
      type: 'string'
    },
    {
      name: `department_type`,
      description: 'Department Type',
      type: 'DepartmentType'
    }
  ]
}
