import React from 'react'
import { useHandleErrorException } from '@cartrack-crm/ui/src/toastrs'

export const withHandleErrorExceptionHoc = (Component: any) => {
  return (props: any) => {
    const [toastrHandleErrorException] = useHandleErrorException()
    return (
      <Component
        toastrHandleErrorException={toastrHandleErrorException}
        {...props}
      />
    )
  }
}
