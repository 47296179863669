/* eslint-disable */

import { connect } from 'react-redux'
import React from 'react'
import { withApollo } from 'react-apollo'
import cloneDeep from 'clone-deep'
import moment from 'moment'
import { func, shape } from 'prop-types'
import { qlAnalyticsWidgetQuery } from 'crm-data/analytics'
import { getUser } from 'crm-duxs/crm-reducer'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { compareDateFn } from 'crm-utils/common-utils'
export const mock = 1
const TOTAL_PAGES = 3

const calculateForecast = val => {
  const today = moment()
  console.log('calculateForecast', today.date())
  const r = Number(moment().daysInMonth() / today.date())
  console.log('R', r)
  return Number((val * r).toFixed(0))
}

const widgetCallHoc = WrappedComponent => {
  class Widget extends React.PureComponent {
    static propTypes = {
      currentUser: shape({}).isRequired
    }
    constructor(props) {
      super(props)
      this.state = {
        currentPage: 1
      }
    }

    componentDidMount() {
      this.handleRefresh()
    }
    handleSwitchPage = order => {
      console.log('Hande Switch page')
      const nextPage = Math.max(
        1,
        Math.min(TOTAL_PAGES, this.state.currentPage + order)
      )
      this.setState({ currentPage: nextPage }, this.handleRefresh)
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    parseData = async data => {
      const newState = {}
      let firstCount = 0
      let followUpsCount = 0
      // console.log('parseDataMeetings', data)
      if (data.data) {
        data.data.forEach(row => {
          firstCount += !isNaN(Number(row.first)) ? Number(row.first) : 0
          followUpsCount += !isNaN(Number(row.follow_up))
            ? Number(row.follow_up)
            : 0
        })

        this.setState({ firstCount, followUpsCount })
      }
    }

    handleRefresh = async () => {
      console.log('handleRefresh', this.state.currentPage)
      if (this.state.currentPage == 1 || this.state.currentPage == undefined) {
        await this.handleRefresh1()
      } else if (this.state.currentPage == 2) {
        await this.handleRefresh2ByUser()
      } else if (this.state.currentPage === 3) {
        await this.handleRefresh3()
      }
    }
    handleRefresh1 = async () => {
      console.log('Refresh')
      const filter = {
        start_date: {
          $gte: moment()
            .startOf('day')
            .add(-6, 'day')
            .startOf('day')
            .format('YYYY-MM-DD'),
          $lte: moment()
            .startOf('day')
            .format('YYYY-MM-DD')
        },
        is_success: true,
        type__medium_code: 'phone_call',
        type__module: 'sales'
      }

      console.log('Month: ', filter)
      // Load fitments
      const variables = {
        fields: ['start_date'],
        aggregate: [{ id: 'activity_uid', type: 'count' }],
        rootType: 'ActivitiesListQM',
        filter
      }
      await this.setStateAsync({
        page1Data: {
          isLoading: true
        }
      })
      const res = await this.props.client.query({
        query: qlqGenericDataQuery,
        variables,
        fetchPolicy: 'no-cache'
      })
      console.log('RESULTCALLS', res)
      const calls = [...res.data.genericDataQuery.data]
      calls.sort(f => compareDateFn('start_date'))

      const callLast7Day = calls.reduce((a, i) => {
        // if (
        //   moment(i.start_date).format('YYYY-MM-DD') ==
        //   moment()
        //     .startOf('day')
        //     .format('YYYY-MM-DD')
        // ) {
        a += Number(i.activity_uid)
        // }
        return a
      }, 0)
      console.log('callLast7Day', callLast7Day)
      const callToday = calls.find(
        c => c.start_date === moment().format('YYYY-MM-DD')
      )
      console.log('callToday', callToday)

      const CallsHistory = calls.reduce(
        (a, i) => [
          ...a,
          {
            calls:
              moment(i.start_date).format('YYYY-MM-DD') ==
              moment()
                .startOf('month')
                .format('YYYY-MM-DD')
                ? calculateForecast(Number(i.order_uid))
                : Number(i.activity_uid),
            date: moment(i.start_date).format('YYYY-MM-DD')
          }
        ],
        []
      )

      // console.log('fitmentsHistory', fitmentsHistory)

      this.setState({
        page1Data: {
          callLast7Day: callLast7Day,
          callToday,
          CallsHistory,
          isLoading: false
        }
      })
    }

    handleRefresh2ByUser = async () => {
      console.log('handleRefresh2')
      const filter = {
        completed_date: {
          $gte: moment()
            .startOf('day')
            .add(-7, 'day')
            .startOf('day')
            .format('YYYY-MM-DD'),
          $lte: moment()
            .startOf('day')
            .format('YYYY-MM-DD')
        },
        status_code: 'completed'
      }

      // Load fitments
      const variables = {
        fields: ['completed_date'],
        aggregate: [{ id: 'order_uid', type: 'count' }],
        rootType: 'OrdersListQM',
        filter
      }
      await this.setStateAsync({
        page2Data: {
          isLoading: true
        }
      })
      const res = await this.props.client.query({
        query: qlqGenericDataQuery,
        variables,
        fetchPolicy: 'no-cache'
      })
      console.log('RESULT', res)
      const fitments = [...res.data.genericDataQuery.data]
      fitments.sort(f => compareDateFn('completed_date'))

      const fitmentsToday = fitments.reduce((a, i) => {
        if (
          moment(i.completed_date).format('YYYY-MM-DD') ==
          moment()
            .startOf('day')
            .format('YYYY-MM-DD')
        ) {
          a += Number(i.order_uid)
        }
        return a
      }, 0)
      const fitmentsTotal = fitments.reduce(
        (a, i) => (a += Number(i.order_uid)),
        0
      )
      console.log('fitmentsToday', fitmentsToday)
      // const calculateForecast = val => {
      //   const today = moment()
      //   console.log('calculateForecast', today.date())
      //   const r = Number(moment().daysInMonth() / today.date())
      //   console.log('R', r)
      //   return Number((val * r).toFixed(0))
      // }
      // const forecastThisMonth = calculateForecast(fitmentsThisMonth)
      const fitmentsHistory = fitments.reduce(
        (a, i) => [
          ...a,
          {
            fitments:
              moment(i.completed_date).format('YYYY-MM-DD') ==
              moment()
                .startOf('day')
                .format('YYYY-MM-DD')
                ? calculateForecast(Number(i.order_uid))
                : Number(i.order_uid),
            date: moment(i.completed_date).format('YYYY-MM-DD')
          }
        ],
        []
      )

      console.log('fitmentsHistory', fitmentsHistory)

      this.setState({
        page2Data: {
          fitmentsToday,
          fitmentsHistory,
          fitmentsTotal,
          isLoading: false
        }
      })
    }
    handleRefresh3 = async () => {
      console.log('handleRefresh2')
      await this.setStateAsync({
        page3Data: {
          isLoading: false
        }
      })
    }
    //   const filter = {
    //     ...this.props.filters,
    //     owner_user_uid: this.props.currentUser.user_uid,
    //     completed_date: { $ne: 'null' },
    //     is_success: true
    //   }
    //   const widgetDef = cloneDeep(myMeetingsStats)
    //   widgetDef.data.dataSources[0].filter = {
    //     is_success: true,
    //     performer_user_uid: this.props.currentUser.user_uid,
    //     start_date: this.props.analyticsContext.filters.activity_date
    //   }
    //   // console.log('WidgetDefinition', widgetDef)
    //   const query = {
    //     query: qlAnalyticsWidgetQuery,
    //     variables: {
    //       widgetDefinition: widgetDef,
    //       filter
    //     },
    //     fetchPolicy: 'no-cache'
    //   }
    //   const data = await this.props.client.query(query)
    //   // console.log('Got data', data)
    //   if (
    //     data &&
    //     data.data &&
    //     data.data.dataSourceQuery &&
    //     data.data.dataSourceQuery.data &&
    //     data.data.dataSourceQuery.data.dataSources &&
    //     data.data.dataSourceQuery.data.dataSources.ActivitiesAnalytics
    //   ) {
    //     await this.parseData(
    //       data.data.dataSourceQuery.data.dataSources.ActivitiesAnalytics.rawData
    //     )
    //   }
    // }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          currentPage={this.state.currentPage}
          onRefresh={this.handleRefresh}
          onSwitchPage={this.handleSwitchPage}
          fitmentsThisMonth={this.state.fitmentsThisMonth}
          fitmentsHistory={this.state.fitmentsHistory}
          isLoading={this.state.isLoading}
          page1Data={this.state.page1Data}
          page2Data={this.state.page2Data}
          page3Data={this.state.page3Data}
        />
      )
    }
  }

  function mapStateToProps(state) {
    return {
      currentUser: getUser(state)
    }
  }

  return withApollo(connect(mapStateToProps)(Widget))
}

export default widgetCallHoc
