import React from 'react'
import { DashBoardWidget } from '../dashboard-widget'
import { HeadCount } from 'crm-types'
import { numberFormat } from 'crm-utils/data-utils'
import { createDashboardFormats, getWidgetAnalyticContext } from '../../dashboards/tools'
import moment from 'moment'

type WidgetHeadCountProps = {
  headcounts?: HeadCount[]
  title?: string
}

export const WidgetHeadCount: React.SFC<WidgetHeadCountProps> = (props) => {
  const analyticsContext = getWidgetAnalyticContext()
  const headCount: HeadCount[] = props.headcounts ?? []
  const reFormated = (dataset: HeadCount[]) => {
    let reFormat = {}
    dataset.map((el) => {
      reFormat[el.month] = el.value
    })
    return reFormat
  }
  const dataset = reFormated(headCount)
  let floor = analyticsContext?.filters?.activity_date?.value?.$lte ? moment(analyticsContext.filters.activity_date.value.$lte).endOf('month').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD')
  floor = moment(floor).format('YYYY-MM')
  const { dataPassedMonths, totalLatestMonth, mainCaption } = createDashboardFormats(dataset, 3, floor)
  return (
    <DashBoardWidget
      title={props.title}
      totalNumber={numberFormat(totalLatestMonth)}
      footerRender={dataPassedMonths}
      subtitle={mainCaption}
      hideAverage
      bigContent
    />
  )
}
