import React, { createContext, useContext } from 'react'
import { connect } from 'react-redux'
import cloneDeep from 'clone-deep'

export const getAvailableInstances = state => state.crm.crm.availableInstances
export const getSelectedInstance = state => state.crm.crm.selectedInstance
export const getUser = state => state.user.user

export const CrmContext = createContext({
  instance: undefined,
  master: undefined,
  getAttributeValueByCode: undefined
})

type CrmContextProviderInnerProps = {
  children: any
  instance?: any
  master_attributes?: any
  instance_attributes?: any
  user?: any
}

const CrmContextProviderInner: React.SFC<CrmContextProviderInnerProps> = ({
  children,
  instance,
  master_attributes,
  instance_attributes,
  user
}) => {
  const findAttribute = (attribute_code, attributes) => {
    const pattributes = cloneDeep(attributes)
    const attribute = pattributes ? pattributes.find(v => v.code === attribute_code) : undefined
    if (attribute) {
      attribute.value = attribute.data_type === 'boolean' ? JSON.parse(attribute.value) : attribute.value
      return attribute.value
    }
    return null
  }

  const getAttributeValueByCode = attribute_code => {
    const value_by_instance = findAttribute(attribute_code, instance_attributes)
    const value_by_master = findAttribute(attribute_code, master_attributes)

    return value_by_instance ? value_by_instance : value_by_master
  }

  const crmContextValue = {
    instance,
    master: {
      master_uid: user?.master_uid ? user.master_uid : '9e84eae1-17b5-4ad1-88bb-ff9822b3220e',
      name: ''
    },
    getAttributeValueByCode
  }

  return <CrmContext.Provider value={crmContextValue}>{children}</CrmContext.Provider>
}

function mapStateToProps(state) {
  const selected_instance = getSelectedInstance(state)
  const selected_instance_uid = selected_instance?.instance_uid
  const instances_and_attributes = getAvailableInstances(state)
  const master_attributes = instances_and_attributes?.master_attributes
    ? instances_and_attributes.master_attributes
    : []
  const available_instances = instances_and_attributes?.available_instances
    ? instances_and_attributes.available_instances
    : []
  const instance = selected_instance_uid
    ? available_instances.find(v => v.instance_uid === selected_instance_uid)
    : undefined
  const instance_attributes = instance ? instance.instance_attributes : []

  return {
    instance: selected_instance,
    master_attributes: master_attributes,
    instance_attributes: instance_attributes,
    user: getUser(state)
  }
}
export const CrmContextProvider = connect(mapStateToProps)(CrmContextProviderInner)

export const useCrmContext = () => useContext(CrmContext)

export const useAttribute = attribute_code => {
  const context = useContext(CrmContext)
  return context.getAttributeValueByCode(attribute_code)
}

export const withCrmContext = WrappedComponent => {
  return props => {
    return (
      <CrmContext.Consumer>
        {value => (
          <WrappedComponent
            {...props}
            crmContext={value}
            crmInstance={value ? value.instance : undefined}
            crmMaster={value ? value.master : undefined}
          />
        )}
      </CrmContext.Consumer>
    )
  }
}
