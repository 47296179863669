/* eslint-disable camelcase */
import React from 'react'
import { func, shape } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import { qlqProcessType } from 'crm-data/processes'
import {
  qlGetOpportunity,
  qlqAccountOpportunities
} from 'crm-data/opportunities'
import { qlqFetchAccountProcesses } from 'crm-data/accounts'
import { mapOpportunity } from 'crm-utils/opportunities-utils'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

// eslint-disable-next-line
const opportunityWindowHoc = WrappedComponent => {
  class OpportunityWin extends React.PureComponent {
    static propTypes = {
      opportunity: shape({}),
      onCancel: func,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      opportunity: undefined,
      onCancel: undefined
    }
    constructor(props) {
      super(props)
      this.state = {
        isSaving: false,
        isActive: false
      }
    }

    _buildRefetchQueries = opportunity => {
      const refetchQueries = [
        {
          query: qlqAccountOpportunities,
          variables: { account_uid: opportunity.account_uid },
          partialRefetch: true
        },
        {
          query: qlqFetchAccountProcesses,
          variables: { account_uid: opportunity.account_uid }
        }
      ]
      if (opportunity.opportunity_uid && !opportunity.isDelete) {
        refetchQueries.push({
          query: qlGetOpportunity,
          variables: { opportunity_uid: opportunity.opportunity_uid }
        })
      }
      return refetchQueries
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    handleSaveWin = async payload => {
      const command = {
        type: 'opportunity.win',
        payload: {
          ...payload
        },
        aggregate_type: 'opportunity',
        aggregate_uid: this.props.opportunity.opportunity_uid
      }
      await this.setStateAsync({ isSaving: true })
      try {
        const res = await this.props.onRunCommand(command, {
          refetchQueries: this._buildRefetchQueries(this.props.opportunity)
        })
        await this.setStateAsync({ isSaving: false })
        return res
      } catch (err) {
        console.log('Error saving win')
        throw err
      }
    }

    handleSaveLost = async payload => {
      const command = {
        type: 'opportunity.lose',
        payload: {
          ...payload
        },
        aggregate_type: 'opportunity',
        aggregate_uid: this.props.opportunity.opportunity_uid
      }
      await this.setStateAsync({ isSaving: true })
      try {
        const refetchQueries = this._buildRefetchQueries(this.props.opportunity)
        const res = await this.props.onRunCommand(command, { refetchQueries })
        await this.setStateAsync({ isSaving: false })
        return res
      } catch (err) {
        console.error(err)
        throw err
      }
    }

    handleSaveOpportunity = async pOpportunity => {
      const opportunity = { ...pOpportunity }
      const isNew = !opportunity.opportunity_uid

      if (isNew) {
        opportunity.account_uid = this.props.account.account_uid
      }
      try {
        const command = {
          type: isNew ? 'opportunity.create' : 'opportunity.update',
          payload: {
            opportunity: mapOpportunity(opportunity)
          },
          aggregate_type: 'opportunity',
          aggregate_uid: isNew
            ? this.props.newObjectUid
            : opportunity.opportunity_uid
        }

        const result = await this.props.onRunCommand(command, {
          refetchQueries: this._buildRefetchQueries(command.payload.opportunity)
        })
        return result
      } catch (err) {
        console.log('Save Opportunity error', err)
        await this.setStateAsync({ isSaving: false })
        throw err
      }
    }

    handleSaveAdminCorrectData = async pOpportunity => {
      const opportunity = { ...pOpportunity }
      if (!opportunity.opportunity_uid) {
        console.log('Opportunity has no opportunity_uid')
        return
      }
      try {
        const command = {
          type: 'opportunity.correct',
          payload: {
            opportunity: {
              completed_time: opportunity.completed_time,
              owner_user_uid: opportunity.owner_user_uid,
              account_uid: opportunity.account_uid
            }
          },
          aggregate_type: 'opportunity',
          aggregate_uid: opportunity.opportunity_uid
        }
        const result = await this.props.onRunCommand(command, {
          refetchQueries: this._buildRefetchQueries(command.payload.opportunity)
        })
        return result
      } catch (err) {
        console.log('Save Opportunity error', err)
        await this.setStateAsync({ isSaving: false })
        throw err
      }
    }

    handleDeleteOpportunity = async pOpportunity => {
      const opportunity = { ...pOpportunity }
      opportunity.isDelete = true
      try {
        const command = {
          type: 'opportunity.delete',
          payload: {},
          aggregate_type: 'opportunity',
          aggregate_uid: opportunity.opportunity_uid
        }

        const result = await this.props.onRunCommand(command, {
          refetchQueries: this._buildRefetchQueries(opportunity)
        })
        return result
      } catch (err) {
        console.log('Delete Opportunity error', err)
        await this.setStateAsync({ isSaving: false })
        throw err
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onSaveWin={this.handleSaveWin}
          onSaveLost={this.handleSaveLost}
          onClickDo={this.handleClickDo}
          onCancel={this.props.onCancel}
          isSaving={this.state.isSaving}
          isActive={this.state.isActive}
          onSaveOpportunity={this.handleSaveOpportunity}
          onDeleteOpportunity={this.handleDeleteOpportunity}
          onSaveAdminCorrectData={this.handleSaveAdminCorrectData}
        />
      )
    }
  }

  const withCommand = crmGenericCommandQlHoc(OpportunityWin)
  return withCommand
}

const opportunityWinQl = compose(
  graphql(qlGetOpportunity, {
    options: ownProps => ({
      variables: { opportunity_uid: ownProps.opportunityUid } // eslint-disable-line
    }),
    skip: ownProps =>
      ownProps.opportunityUid === 'new' || !ownProps.opportunityUid
  }),
  graphql(qlqProcessType, {
    options: ownProps => ({
      variables: {
        process_type_uid: ownProps.process_type_uid,
        process_uid: ownProps.processUid
      }
    }),
    skip: ownProps => !ownProps.process_type_uid && !ownProps.processUid,
    props: ({ qlqProcessType, ownProps }) => ({
      ...ownProps,
      qlqProcessType,
      processType: qlqProcessType ? qlqProcessType.process_type : undefined
    }),
    name: 'qlqProcessType'
  }),
  opportunityWindowHoc
)
export default opportunityWinQl
