import React from 'react'
import { CrmFullpageContainer } from '../../../components/index'
import { WidgetFitmentSummary } from '../widgets/fitment-summary/widget-fitment-summary'
import { WidgetCustomersWon } from '../widgets/wins/widget-customers-won'
import { WidgetCustomersWinRatio } from '../widgets/wins/widget-customer-win-ratio-widget'
import { WidgetFirstMeetingSummary } from '../widgets/meetings/my-first-meeting-status-widget'
import { WidgetFollowUpMeetingProspectSummary } from '../widgets/meetings/my-follow-up-meetings-prospects-widget'
import { WidgetFollowUpMeetingClientSummary } from '../widgets/meetings/my-follow-up-meetings-client-widget'
import { UserInfoCard } from '../ct-dashboards/rm-dashboard/rm-summary-dashboard'
import { getWidgetAnalyticContext } from './tools'
import { ReportProvider } from 'crm-utils/reports-utils'
import withLeadPopUp from 'crm-modules/dashboard/components/_with-leads-popup-hoc'

export const DashBoardRM = props => {
  return (
    <CrmFullpageContainer>
      <div className="util-paddingXl">
        <DashBoardRMInner />
      </div>
    </CrmFullpageContainer>
  )
}

export const DashBoardRMInner = withLeadPopUp(() => {
  const analyticsContext = getWidgetAnalyticContext()
  const user = { user_uid: analyticsContext?.filters?.user?.user_uid?.value }
  return (
    <div className="row">
      <div className="col-md-2">
        <UserInfoCard user={user} />
      </div>
      <ReportProvider>
        <div className="col-md-3">
          <WidgetFitmentSummary />
        </div>
        <div className="col-md-3">
          <WidgetCustomersWon />
        </div>
        <div className="col-md-3">
          <WidgetCustomersWinRatio />
        </div>
        <div className="col-md-2" />
        <div className="col-md-3">
          <WidgetFirstMeetingSummary />
        </div>
        <div className="col-md-3">
          <WidgetFollowUpMeetingProspectSummary />
        </div>
        <div className="col-md-3">
          <WidgetFollowUpMeetingClientSummary />
        </div>
      </ReportProvider>
    </div>
  )
})
