// import { useSelector } from 'react-redux'
import { getDashboards } from 'crm-duxs/management/management-dashboard-reducer'
// import React, { useState, useEffect  } from 'react'
// import { func, shape, string, arrayOf } from 'prop-types'
// import { connect } from 'react-redux'
// import {
//   getDashboards,
//   fetchDashboards,
//   fetchAvailableKpis
// } from 'crm-duxs/management/management-dashboard-reducer'
// import { getSelectedInstance } from 'crm-duxs/crm-reducer'
// import { AnalyticsContextType, AnalyticsContext } from 'crm-core/analytics/context'

export const MODE_DASHBOARDS = 'DASHBOARDS'
export const MODE_MANAGEMENT_REPORTS = 'MANAGEMENT_REPORTS'

// TODO - need to update readux first
export const useDashboards = () => {
  // const dashboards = useSelector(getDashboards)
  // return dashboards
}
// /**
//   Nat - migration notes
//   components using analytics-dashboard-hoc()

//     1) component must take care to render AnalayticsContextProvider -> wrap render in ()
//     2) Filters change was handled by propagating onFilterValueChange function to the top - now components should import useAnalyticsContext and call context.setFilters function
// 3) Use hook useDashboards to get list of dashboars ( before this was injected to .props)
//  */

// const useAnalyticsDashboards = ( WrappedComponent : any ) => {

//   type InnerProps = {
//     analyticsContext: AnalyticsContextType,
//     doFetchDashboards: Function,
//   }

//   const Inner : React.SFC<InnerProps> = (props) => {
//     // static propTypes = {
//     //   dashboards: arrayOf(shape({})),
//     //   doFetchAvailableKpis: func.isRequired,
//     //   doFetchDashboards: func.isRequired,
//     //   mode: string,
//     //   instance: shape({}).isRequired,

//     // }
//     // static defaultProps = {
//     //   dashboards: [],
//     //   mode: MODE_DASHBOARDS
//     // }
//     // constructor(props) {
//     //   super(props)
//     //   this.state = {
//     //     globalFiltersDefinition: [
//     //       // Bring back to create aggregated reporting
//     //       // {
//     //       //   code: 'instance',
//     //       //   data_type: 'scope',
//     //       //   label: 'Scope',
//     //       //   default_value: 'development',
//     //       //   operator: 'eq'
//     //       // },
//     //       {
//     //         code: 'lead_in_date',
//     //         data_type: 'date_range',
//     //         label: 'Lead In Date',
//     //         operator: 'eq',
//     //         filterName: 'Lead in date'
//     //       },
//     //       {
//     //         code: 'activity_date',
//     //         data_type: 'date_range',
//     //         label: 'Activity Date',
//     //         operator: 'eq',
//     //         filterName: 'Activtity Date'
//     //       },
//     //       {
//     //         code: 'user_uid',
//     //         data_type: 'user',
//     //         label: 'User',
//     //         operator: 'eq',
//     //         filterName: 'User'
//     //       },
//     //       {
//     //         code: 'account__transaction_type_code',
//     //         data_type: 'account__transaction_type_code',
//     //         label: 'Account Transaction Type',
//     //         operator: 'eq',
//     //         filterName: 'Account Transaction Type'
//     //       },
//     //       {
//     //         code: 'account__lead_campaign__transaction_type_code',
//     //         data_type: 'lead_source_transaction_type',
//     //         label: 'Lead Source Transaction Type',
//     //         operator: 'eq',
//     //         filterName: 'Lead Source Transaction Type'
//     //       },
//     //       {
//     //         code: 'account__lead_campaign__lead_source__kind',
//     //         data_type: 'lead_source_channel',
//     //         label: 'Lead Channel',
//     //         operator: 'eq',
//     //         filterName: 'Lead Channel'
//     //       },
//     //       {
//     //         code: 'account__lead_campaign__lead_source_uid',
//     //         data_type: 'lead_source_type',
//     //         label: 'Lead Source ',
//     //         operator: 'eq',
//     //         filterName: 'Lead Source '
//     //       },
//     //       {
//     //         code: 'account__lead_campaign_uid',
//     //         data_type: 'lead_source_campaign',
//     //         label: 'Lead Campaign',
//     //         operator: 'eq',
//     //         filterName: 'Lead Campaign'
//     //       },
//     //       {
//     //         code: 'sales_campaign_uid',
//     //         data_type: 'campaign',
//     //         label: 'Sales Campaign',
//     //         operator: 'eq',
//     //         filterName: 'Sales Campaign'
//     //       },
//     //       {
//     //         code: 'resource_pool_uid',
//     //         data_type: 'resource_pool',
//     //         label: 'Users Group',
//     //         operator: 'eq',
//     //         filterName: 'Users Group'
//     //       },
//     //       {
//     //         code: 'department_uid',
//     //         data_type: 'department',
//     //         label: 'User Department',
//     //         operator: 'eq',
//     //         filterName: 'User Department'
//     //       }
//     //     ]
//     //   }
//     //   // console.log('genericWidgetHoc constructor', this.state)
//     // }
//     useEffect( () =>  {
//       props.doFetchDashboards()
//     })

//     //
//     const handleGlobalFiltersChange = globalFilters => {
//       console.log('handleGlobalFiltersChange', globalFilters)
//       // this.setState({ ...this.state, globalFilters })
//       if (
//         this.props.analyticsContext &&
//         this.props.analyticsContext.setFilters
//       ) {
//         this.props.analyticsContext.setFilters(globalFilters)
//       }
//     }
//     const _buildContext = () => {
//       // console.log('_rebuildContext, state:')
//       const ret = {
//         filters: {
//           // ...this.state.globalFilters,
//           ...this.props.analyticsContext.filters,
//           instance_uid: this.props.instance
//             ? this.props.instance.instance_uid
//             : undefined
//         }
//       }
//       return ret
//     }

//       console.log('Analytics dashboard hoc', props)
//       const analyticsContext = _buildContext()
//       console.log('new analyticsContext', analyticsContext)
//       return (
//         <WrappedComponent
//           {...this.props}
//           mode={this.props.mode}
//           dashboards={this.props.dashboards}
//           instance={this.props.instance}
//           analyticsContext={analyticsContext}
//           globalFiltersDefinition={this.state.globalFiltersDefinition}
//           filterValues={this.props.analyticsContext.filters}
//           onFilterValueChange={this.handleGlobalFiltersChange}
//         />
//       )
//   }

//   function mapStateToProps(state) {
//     return {
//       dashboards: getDashboards(state),
//       instance: getSelectedInstance(state)
//     }
//   }

//   function mapDispatchToProps(dispatch) {
//     return {
//       doFetchAvailableKpis: () => {
//         dispatch(fetchAvailableKpis())
//       },
//       doFetchDashboards: () => {
//         dispatch(fetchDashboards())
//       }
//     }
//   }

//   const WithContext = props => (
//     <AnalyticsContext.Consumer>
//       {analyticsContext => {
//         return <Inner {...props} analyticsContext={analyticsContext} />
//       }}
//     </AnalyticsContext.Consumer>
//   )
//   return connect(mapStateToProps, mapDispatchToProps)(WithContext)
// }

// export const genericWidgetProps = {
//   title: string.isRequired,
//   layout: shape({}).isRequired
// }
// export const rendererProps = {
//   item: shape({}).isRequired,
//   renderChildren: func.isRequired
// }

// export default genericWidgetHoc
