import React from 'react'
import { CrmFullpageContainer } from 'crm-components'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import { qlqListFeedItems } from 'crm-data/feed'

const qlOptions = {
  options: ownProps => ({
    variables: {
      filter: ownProps.filter
    }
  }),
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.feedItems : []
  })
}

const Table = genericTableFactory({
  query: qlqListFeedItems,
  qlOptions
})

const columns = [
  {
    Header: 'Title',
    accessor: 'title'
  },
  {
    Header: 'Message',
    accessor: 'message'
  },
  {
    Header: 'created_time',
    accessor: 'created_time'
  }
]
const FeedAuditView = () => (
  <CrmFullpageContainer hasPadding vbox>
    <Table columns={columns} exportFileName="Manager_feed" />
  </CrmFullpageContainer>
)

export default FeedAuditView
