/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { func, shape, bool, string } from 'prop-types'
import { qlFetchAccountOwners } from 'crm-data/accounts'
import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { dateTimeToDateString } from 'util-functions/time-utils'
import { getAPIDateFormat } from 'crm-utils/time-utils'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import moment from 'moment'

export const ownerTypeOptions = [
  { label: 'User', value: 'user' },
  { label: 'Group', value: 'group' }
]

export const ownerRoles = [
  { name: 'Finance', value: 'finances' },
  { name: 'Customer Support', value: 'cs' },
  { name: 'Telemarketer', value: 'telemarketer' }
]

export const editAdditionalOwnersHoc = WrappedComponent => {
  class EditAdditionalOwnersHoc extends React.PureComponent {
    static propTypes = {
      client: shape(),
      onRunCommand: func.isRequired,
      isSaving: bool.isRequired
    }

    handleRemoveAdditionalOwner = async params => {
      console.log()
      if (!params.accountUid) {
        throw new Error('accountUid must be provided')
      }
      if (!params.roleCode) {
        throw new Error('roleCode must be provided')
      }

      const command = {
        type: 'account.clearOwner',
        aggregate_uid: params.accountUid,
        aggregate_type: 'account',
        version: 2,
        payload: {
          roleCode: params.roleCode,
          validFrom: getAPIDateFormat(new Date())
        }
      }

      try {
        const refetchQueries = [
          {
            query: qlFetchAccountOwners,
            variables: {
              account_uid: params.accountUid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })
        return res.data.cqCommand
      } catch (err) {
        console.log(err)
        throw err
      }
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleRemoveAdditionalOwner={this.handleRemoveAdditionalOwner}
        />
      )
    }
  }

  const EditAdditionalOwnersHocWithCommand = crmGenericCommandHoc(
    EditAdditionalOwnersHoc
  )

  return EditAdditionalOwnersHocWithCommand
}

const accountReassignHoc = WrappedComponent => {
  class AccountReassignHOC extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        isSaving: false,
        roleCode: props.defaultRoleCode,
        ownerValidFrom: new Date(),
        optionOverride: 'override'
      }
    }

    static propTypes = {
      process: shape({}),
      onCancel: func,
      defaultRoleCode: string,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      isEditing: false,
      gotoPage: undefined,
      newAccountUid: undefined,
      onCancel: undefined,
      process: undefined,
      defaultRoleCode: undefined
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    handleReassignBulk = async pAccount => {
      return this.handleReassignAccount(
        {
          ownerUserUid: this.state.ownerUserUid,
          ownerType: 'user',
          roleCode: this.state.roleCode,
          validFrom: this.state.ownerValidFrom,
          isSkip: this.state.optionOverride
        },
        pAccount
      )
    }
    handleUserSelect = user => this.setState({ ownerUserUid: user })
    handleGroupChanged = ownerGroupUid => {
      this.setState({ ownerGroupUid })
    }
    handleValidFromChange = val => {
      this.setState({ ownerValidFrom: moment(val).format('YYYY-MM-DD') })
    }
    handleOwnerTypeChange = ownerType => {
      this.setState({ ownerType })
    }
    handleRoleCodeChange = roleCode => {
      this.setState({ roleCode })
    }
    handleSelectOverrideOption = optionOverride => {
      this.setState({ optionOverride })
    }
    handleReassignAccount = async (pNewOwnerParams, pAccount = undefined) => {
      const account = pAccount || this.props.account
      const newOwnerParams = pNewOwnerParams || {
        ownerType: this.state.ownerType,
        ownerGroupUid: this.state.ownerGroupUid,
        ownerUserUid: this.state.ownerUserUid,
        newOwnerValidFrom: this.state.ownerValidFrom,
        roleCode: this.state.roleCode,
        validFrom: dateTimeToDateString(this.state.ownerValidFrom),
        isSkip: this.state.optionOverride
      }
      // decide on ownerTypeUid
      if (!newOwnerParams.ownerType) {
        throw new Error('Owner type must be selected')
      }
      if (newOwnerParams.ownerType === 'user' && !newOwnerParams.ownerUserUid) {
        throw new Error('Please select user')
      }
      if (
        newOwnerParams.ownerType === 'group' &&
        !newOwnerParams.ownerGroupUid
      ) {
        throw new Error('Please select group')
      }

      const command = {
        type: 'account.reassignWithRole',
        aggregate_uid: pAccount.account_uid,
        aggregate_type: 'account',
        version: 2,
        payload: {
          ...newOwnerParams
        }
      }

      try {
        const refetchQueries = [
          {
            query: qlFetchAccountOwners,
            variables: {
              account_uid: account.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })
        return res.data.cqCommand
      } catch (err) {
        console.log(err)
        throw err
      }
    }

    render() {
      console.log('render account reass hoc', this.state)
      const isValid =
        (this.state.ownerType === 'user' && this.state.ownerUserUid) ||
        (this.state.ownerType === 'group' && this.state.ownerGroupUid)
      return (
        <WrappedComponent
          {...this.props}
          isValid={isValid}
          roleCode={this.state.roleCode}
          newOwnerType={this.state.ownerType}
          newOwnerUserUid={this.state.ownerUserUid}
          newOwnerGroupUid={this.state.ownerGroupUid}
          newOwnerValidFrom={this.state.ownerValidFrom}
          onReassignAccount={this.handleReassignAccount}
          onReassignAccountUserSelected={this.handleUserSelect}
          reassignAccountUser={this.state.ownerUserUid}
          optionOverride={this.state.optionOverride}
          onChangeOwnerType
          isSavingReassignAccount={this.props.isRunningCommand}
          onBulkOperation={this.handleReassignBulk}
          onOwnerTypeChange={this.handleOwnerTypeChange}
          onRoleCodeChange={this.handleRoleCodeChange}
          onGroupChanged={this.handleGroupChanged}
          onNewOwnerValidFromChange={this.handleValidFromChange}
          isManager={this.props.accountPermissionsContext.hasPermissionByType(
            PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
          )}
          onChangeSelectBulkOverrideOption={this.handleSelectOverrideOption}
        />
      )
    }
  }

  function mapStateToProps(state) {
    return {}
  }
  return crmGenericCommandHoc(
    connect(mapStateToProps)(
      withAccountPermissionsContextHOC(AccountReassignHOC)
    )
  )
}

export const accountReassignHocPropTypes = {
  onReassignAccount: func.isRequired,
  isSavingReassignAccount: bool.isRequired,
  newOwnerUserUid: string,
  newOwnerGroupUid: string,
  newOwnerType: string,
  accountPermissionsContext: shape({}).isRequired
}
export default accountReassignHoc
