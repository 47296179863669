import React, { PureComponent } from 'react'
import { string, array, oneOf, object, bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'

class CrmMessageBlock extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isClose: false,
      isShowMoreError: false,
      messageTimeout: null
    }
  }

  componentDidMount() {
    if (!this.props.disableAutoClose) {
      const timeout = setTimeout(() => {
        this.setState({ isClose: true })
      }, 5000)
      this.setState({ messageTimeout: timeout })
    }
  }

  handleClose = () => {
    this.setState({ isClose: true })
  }

  handleShowMoreError = () => {
    clearTimeout(this.state.messageTimeout)
    this.setState({ isShowMoreError: !this.state.isShowMoreError })
  }

  handleStopMessageTimeout = () => {
    clearTimeout(this.state.messageTimeout)
  }

  render() {
    const { message, messages, messageType, errorObject } = this.props
    const { isClose, isShowMoreError } = this.state
    const displayClass = isClose ? 'util-hide' : ''
    return (
      <div onMouseOver={this.handleStopMessageTimeout}>
        <div className={`${messageType} ${displayClass} util-flexRow`}>
          <div>
            {messageType === 'info' && <FontAwesomeIcon icon={faInfoCircle} />}
            {messageType === 'success' && (
              <FontAwesomeIcon icon={faCheckCircle} />
            )}
            {messageType === 'warning' && (
              <FontAwesomeIcon icon={faExclamationTriangle} />
            )}
            {messageType === 'error' && (
              <div className="util-pointer">
                <FontAwesomeIcon
                  onClick={this.handleShowMoreError}
                  icon={faExclamationCircle}
                />
              </div>
            )}
            {messageType === 'notification' && (
              <FontAwesomeIcon icon={faNewspaper} />
            )}
          </div>
          <div className="util-marginLeft util-marginRight util-flexGrow">
            <div className="util-capitalize util-textBold">{messageType}</div>
            {message && <div>{message}</div>}
            {messages &&
              messages.length > 0 &&
              messages.map((v, index) => <div key={index}>{v}</div>)}
            {isShowMoreError && errorObject && (
              <div>{JSON.stringify(errorObject)}</div>
            )}
          </div>
          <div className="util-pointer">
            <FontAwesomeIcon onClick={this.handleClose} icon={faTimesCircle} />
          </div>
        </div>
      </div>
    )
  }
}

CrmMessageBlock.propTypes = {
  message: string,
  messages: array,
  errorObject: object,
  messageType: oneOf(['info', 'success', 'warning', 'error', 'notification']),
  disableAutoClose: bool
}

CrmMessageBlock.defaultProps = {
  message: '',
  messages: [],
  errorObject: {},
  messageType: 'info',
  disableAutoClose: false
}

export default CrmMessageBlock
