/* eslint-disable camelcase */
import { loadDataMeetingsInPeriod } from './rms-dashboard-meetings'
import { loadDataCurrentOpportunities } from './rm-dashboard-opportunities'
import { loadCrmActivityInPeriod } from './rms-dashboard-crm-activity'
import { loadOpportunitiesPipeline } from './rm-dashboard-opportunities-pipeline'
import { loadOpportunitiesDueMonth } from './rm-dashboard-opportunities-due-month'
import { loadFunnel } from './rms-funnel'
import { qlqListUsers } from 'crm-data/users'

export const availableSections = [
  { name: 'Opportunities', code: 'opportunities' },
  { name: 'Pipeline By Status', code: 'pipeline' },
  { name: 'Pipeline By Months', code: 'pipelineByMonth' },
  { name: 'Funnel', code: 'funnel' },
  { name: 'Meetings', code: 'meetings' },
  { name: 'CRM Activity', code: 'crmActivityInPeriod' },
  { name: 'Fitments', code: 'fitments' }
]

export const merge = (users, add) => {
  if (add) {
    Object.keys(add).forEach(key => {
      const mainKpis = users[key] ? users[key].mainKpis : []
      // eslint-disable-next-line
      users[key] = {
        ...users[key],
        ...add[key],
        mainKpis: [...mainKpis, ...add[key].mainKpis]
      }
    })
  }

  return users
}

const fetchUserPhotos = async params => {
  const users = await params.client.query({ query: qlqListUsers })
  return users.data.users
}

export const loadData = async (params, selectedSections, currentUser) => {
  const res = {}
  const ret = {}
  const users = await fetchUserPhotos(params)

  if (selectedSections.opportunities) {
    const opportunitiesParsed = await loadDataCurrentOpportunities(params)
    merge(res, opportunitiesParsed)
  }

  if (selectedSections.meetings) {
    const meetingsResult = await loadDataMeetingsInPeriod(params)
    ret.meetingsFilter = meetingsResult.filter

    merge(res, meetingsResult.data)
  }

  if (selectedSections.crmActivityInPeriod) {
    merge(res, await loadCrmActivityInPeriod(params))
  }

  if (selectedSections.pipeline) {
    merge(res, await loadOpportunitiesPipeline(params))
  }

  if (selectedSections.pipelineByMonth) {
    merge(res, await loadOpportunitiesDueMonth(params))
  }

  if (selectedSections.funnel) {
    merge(res, await loadFunnel(params))
  }

  ret.data = Object.keys(res)
    .map(k => {
      const user = users.find(u => u && u.user_uid === res[k].user_uid)

      return {
        ...res[k],
        photo_url: user ? user.photo_url : undefined
      }
    })
    .sort((a, b) => (a.user_name ? a.user_name.localeCompare(b.user_name) : 0))

  return ret
}

export const mapContextToFilter = (
  props,
  mapDates = true,
  mapActivityDateAs = undefined
) => {
  const filter = {}

  if (props && props.analyticsContext && props.analyticsContext.filters) {
    if (props.analyticsContext.filters.resource_pool_uid) {
      filter.user_uid = {
        // eslint-disable-next-line
        $inResourcePool:
          props.analyticsContext.filters.resource_pool_uid.value[0]
      }
      filter.owner_user_uid = {
        // eslint-disable-next-line
        $inResourcePool:
          props.analyticsContext.filters.resource_pool_uid.value[0]
      }

      delete filter.resource_pool_uid
    }

    const supportedFields = [
      'instance_uid',
      'campaign_uid',
      'lead_source_type_uid',
      'lead_source_uid',
      'lead_source_name',
      'user_uid',
      'process_started_time',
      'process_started_date',
      'process_started_hour',
      'current_step_name',
      'process_step_kind',
      'process_completed_time',
      'activity_status_name',
      'call_disposition_name',
      'user_name',
      'start_time',
      'owner_user_name',
      'task_due_day',
      'task_owner_name',
      'assignee_type',
      'lead_source_kind',
      'lead_campaign_uid',
      'lead_source_transaction_type_code',
      'lead_source_channel_code'
    ]
    supportedFields.forEach(field => {
      if (props.analyticsContext.filters[field]) {
        if (props.analyticsContext.filters[field].value) {
          if (
            Array.isArray(props.analyticsContext.filters[field].value) &&
            props.analyticsContext.filters[field].value.length > 0
          ) {
            filter[field] = props.analyticsContext.filters[field].value[0]
          } else {
            filter[field] = props.analyticsContext.filters[field].value
          }
        } else {
          filter[field] = props.analyticsContext.filters[field]
        }
      }
    })

    if (mapDates) {
      if (
        props.analyticsContext.filters.lead_in_date &&
        props.analyticsContext.filters.lead_in_date.value
      ) {
        filter.lead_in_date = props.analyticsContext.filters.lead_in_date
      }
    }

    if (
      mapActivityDateAs &&
      props.analyticsContext.filters.activity_date &&
      props.analyticsContext.filters.activity_date.value
    ) {
      filter[mapActivityDateAs] = props.analyticsContext.filters.activity_date
    }
  }

  return filter
}
