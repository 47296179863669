import React from 'react'
import { arrayOf, func, string, shape } from 'prop-types'
import { resolvePathStringFromObject } from '../../../generic/_generic-analytics-widget'

const aggregateBy = (data, field, valueField) =>
  data && data.reduce
    ? data.reduce((a, i) => {
        const key = resolvePathStringFromObject(i, field, 'empty')
        console.log('aggregate by', field, i, key)
        if (key) {
          if (!a[key])
            a[key] = {
              ...i,
              value: 0,
              items: [],
              name: key
            }
          a[key].value += valueField ? Number(i[valueField]) : 1
        }

        return a
      }, {})
    : []

const maxValue = (data, fieldName) => data.reduce((a, i) => Math.max(a, i[fieldName]), 0)

const dataMedian = pnumbers => {
  let median = 0
  const numbers = pnumbers.map(n => Number(n)).sort()
  const numsLen = numbers.length

  if (numsLen % 2 === 0) {
    median = (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2
  } else {
    median = numbers[(numsLen - 1) / 2]
  }
  return median
}

const normalizeOpacity = opacity => Math.max(Math.min(opacity, 1), 0.05)

const _renderCell = (row, col, stats, index, onCellClicked) => {
  const cell = resolvePathStringFromObject(row.columns, col.name, undefined)
  const val = cell ? cell.value : undefined

  let opacity = val ? val / (2 * stats.median) : 0
  const style: any = {}
  if (val) {
    if (opacity > 0.95) {
      style.backgroundColor = 'rgba(208, 2, 27,' + normalizeOpacity(opacity) + ')'
      style.color = 'white'
    } else if (opacity < 0.05) {
      style.backgroundColor = 'rgba(45, 171, 51,' + normalizeOpacity(opacity) + ')'
    } else {
      style.backgroundColor = 'rgba(254, 192, 21,' + normalizeOpacity(opacity) + ')'
    }
  }

  const handleClick = () => {
    onCellClicked(cell)
  }
  return (
    <td key={index} className="CrmHeatmapChart-cell util-textRight" onClick={handleClick}>
      <div className="util-pointer util-paddingSm" style={style}>
        {val}
      </div>
    </td>
  )
}

export const CrmHeatmapChart = props => {
  const { xAxisField, yAxisField, data } = props

  const sortFn = (xSortField, sortType) => {
    return (a, b) => {
      if (sortType === 'number') {
        return a[xSortField] - b[xSortField]
      }
      return a[xSortField] ? a[xSortField].trim().localeCompare(b[xSortField] ? b[xSortField].trim() : '') : 0
    }
  }
  const ySortField = props.ySortField ? props.ySortField : 'name'
  const yAggregated = Object.values(aggregateBy(props.data, props.yAxisField, props.valueField)).sort(
    sortFn(ySortField, props.ySortType)
  )

  const xSortField = props.xSortField ? props.xSortField : 'name'

  const xAggregated = Object.values(aggregateBy(props.data, props.xAxisField, props.valueField)).sort(
    sortFn(xSortField, props.xSortType)
  )

  yAggregated.forEach((row: any) => {
    const filteredData = props.data.filter(d => {
      var key = resolvePathStringFromObject(d, props.yAxisField, undefined)
      return key == row.name
    })
    row.columns = aggregateBy(filteredData, props.xAxisField, props.valueField)
  })
  const stats = {
    max: maxValue(props.data, props.valueField),
    median: dataMedian(props.data.map(i => i[props.valueField])),
    sum: yAggregated.reduce((a, i: any) => a + i.value, 0)
  }

  if (xAggregated && xAggregated.length > 50) {
    return <div> There are more than 50 values in columns field - can't generate view </div>
  }
  return (
    <div>
      <table className="CrmHeatmapChart">
        <thead>
          <tr className="CrmHeatmapChart-thead-th">
            <th className="CrmHeatmapChart-header util-textLeft">{props.yLabel}</th>
            {xAggregated.map((row: any) => (
              <th
                key={row.name}
                className={
                  props.headerOrientation === 'vertical'
                    ? `CrmHeatmapChart-headerVertical`
                    : `CrmHeatmapChart-header util-textRight`
                }
              >
                <span>{row.name.replace(/_/g, ' ')}</span>
              </th>
            ))}
            <th className="CrmHeatmapChart-header util-textRight">Total</th>
          </tr>
        </thead>
        <tbody className="CrmHeatmapChart-tbody">
          {yAggregated.map((row: any) => {
            const mockCell = { ...row }
            delete mockCell[xAxisField]
            const handleTotalClicked = () => {
              console.log('handleTotalClicked', xAxisField, mockCell)
              props.onCellClicked(mockCell, true)
            }
            return (
              <tr key={row.name} className="CrmHeatmapChart-row">
                <td className="CrmHeatmapChart-rowLabel">
                  {props.rowHeaderRenderer ? props.rowHeaderRenderer({ row }) : row.name}
                </td>
                {xAggregated.map((col, index) => _renderCell(row, col, stats, index, props.onCellClicked))}
                <td
                  className="CrmHeatmapChart-summary util-paddingSm
                 CrmHeatmapChart-rowSummary util-pointer util-textRight"
                  onClick={handleTotalClicked}
                >
                  {row.value}
                </td>
              </tr>
            )
          })}

          <tr className="CrmHeatmapChart-row CrmHeatmapChart-rowSummary">
            <td className="CrmHeatmapChart-rowLabel">Total</td>
            {xAggregated.map((col: any, index) => {
              const mockCell = { ...col }
              delete mockCell[yAxisField]
              const handleCellClick = () => {
                console.log('handleCellClick ', xAxisField, mockCell)
                const mockFilter = {
                  [xAxisField]: mockCell[xAxisField],
                  disposition_type_uid: mockCell?.disposition_type_uid
                }
                props.onCellClicked(mockFilter)
              }
              return (
                <td
                  key={index}
                  className="CrmHeatmapChart-summary 
                  util-textRight
                  util-paddingSm 
                  util-pointer"
                  onClick={handleCellClick}
                >
                  {col.value}
                </td>
              )
            })}
            <td className="CrmHeatmapChart-summary util-textRight">{stats.sum}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
CrmHeatmapChart.propTypes = {
  data: arrayOf(shape({})).isRequired,
  xAxisField: string.isRequired,
  yAxisField: string.isRequired,
  valueField: string.isRequired,
  onCellClick: func.isRequired
}
CrmHeatmapChart.defaultProps = {
  onCellClick: () => {}
}

export default CrmHeatmapChart
