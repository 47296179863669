const dashFunnelStatusQuery = {
  type: 'analitics_widget',
  code: 'forecastQuery',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: {},
          fields: ['owner__user_uid', 'opportunity_due_date_month'],
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {
            owner_user_uid: 'user_uid'
          }
          // transposeColumns: ['opportunity_due_date_month'],
          // transposeKey: 'owner__user_uid',
          // transposeValueField: 'vehicle_count'
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {},
          fields: [
            'department_uid',
            'full_name',
            'user_uid',
            'contract_start_date',
            'contract_end_date',
            'department__name',
            'is_active'
          ]
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyField: 'user_uid',
        mapping: [
          {
            fromDataSourceName: 'OpportunitiesListQM',
            keyField: 'owner__user_uid',
            mapping: [
              {
                from: 'vehicle_count',
                to: 'size'
              },
              {
                from: 'opportunity_uid',
                to: 'count'
              }
            ]
          },
          {
            fromDataSourceName: 'CrmUser',
            keyField: 'user_uid',
            type: 'left',
            mapping: [
              {
                from: 'full_name'
              },
              {
                from: 'contract_start_date'
              },
              { from: 'contract_end_date' },
              { from: 'department__name' },
              { from: 'is_active' }
            ]
          }
        ]
      }
    }
  }
}

export default dashFunnelStatusQuery
