/* eslint-disable */
import React from 'react'
import { LIST_OF_ACCOUNT_TAGS } from 'crm-data/account-tags'

import {
  CrmUsersSelect,
  CrmUserGroupSelect,
  CrmTextInput,
  CrmCampaignSelect,
  LeadSourceSelect as LeadSourceSelectDropdown,
  LeadSourceTypeSelect as LeadSourceTypeDropdown,
  CrmDropdown,
  CrmDateRangeDropdown
} from 'crm-components'

const UserGroupSelect = props => {
  const handleChange = value => {
    props.onChange({
      rawValue: {
        resource_pool: {
          resource_pool_uid: {
            label: value.name,
            value: value.value
          }
        }
      }
    })
  }

  const fieldProps = {
    ...props.filterDef.componentProps,
    onChange: handleChange,
    style: { width: '100%' }
  }

  return <CrmUserGroupSelect {...fieldProps} />
}

const UserSelect = props => {
  const handleChange = value => {
    const res = {
      rawValue: value
    }

    props.onChange(res)
  }

  return (
    <CrmUsersSelect
      style={{ width: '100%' }}
      {...props.filterDef.componentProps}
      input={{
        onChange: handleChange,
        value: props.value ? props.value.rawValue : ''
      }}
      disabled={props.disabled}
    />
  )
}

const TextInput = props => {
  const handleChange = event => {
    const res = {
      rawValue: event.currentTarget.value
    }

    props.onChange(res)
  }

  return (
    <CrmTextInput
      {...props}
      placeholder="City"
      input={{
        onChange: handleChange
      }}
      value={props.value ? props.value.rawValue : ''}
      disabled={props.disabled}
    />
  )
}

const CampaignSelect = props => {
  const handleChange = value => {
    const res = {
      rawValue: value
    }

    props.onChange(res)
  }

  return (
    <CrmCampaignSelect
      style={{ width: '100%' }}
      {...props.filterDef.componentProps}
      input={{
        onChange: handleChange,
        value: props.value ? props.value.rawValue : ''
      }}
      disabled={props.disabled}
    />
  )
}

const LeadSourceSelect = props => {
  const handleChange = value => {
    const res = {
      rawValue: value
    }
    props.onChange(res)
  }

  return (
    <LeadSourceSelectDropdown
      style={{ width: '100%' }}
      {...props.filterDef.componentProps}
      input={{
        onChange: handleChange,
        value: props.value ? props.value.rawValue : ''
      }}
      disabled={props.disabled}
    />
  )
}

const LeadSourceTypeSelect = props => {
  const handleChange = value => {
    const res = {
      rawValue: value
    }
    props.onChange(res)
  }

  return (
    <LeadSourceTypeDropdown
      style={{ width: '100%' }}
      {...props.filterDef.componentProps}
      input={{
        onChange: handleChange,
        value: props.value ? props.value.rawValue : ''
      }}
      disabled={props.disabled}
    />
  )
}

const AccountType = props => {
  const handleChange = value => {
    const res = {
      rawValue: value
    }

    props.onChange(res)
  }

  const options = [
    { value: 'customer', name: 'Customer' },
    { value: 'prospect', name: 'Prospect' },
    { value: 'lead', name: 'Lead' },
    { value: 'bogus', name: 'Bogus' },
    { value: 'lost', name: 'Lost' }
  ]

  return (
    <CrmDropdown
      options={options}
      {...props.filterDef.componentProps}
      input={{
        onChange: handleChange,
        value: props.value ? props.value.rawValue : ''
      }}
      disabled={props.disabled}
      style={{ minWidth: 120 }}
    />
  )
}

const TagType = props => {
  const handleChange = value => {
    const res = {
      rawValue: [value],
      selected: value
    }
    props.onChange(res)
  }

  const options = LIST_OF_ACCOUNT_TAGS

  return (
    <CrmDropdown
      options={options}
      {...props.filterDef.componentProps}
      input={{
        onChange: handleChange,
        value: props.value ? props.value.selected : ''
      }}
      disabled={props.disabled}
      style={{ minWidth: 120 }}
    />
  )
}

class Checkbox extends React.PureComponent {
  componentDidMount() {
    this.handleChange()
  }

  handleChange = value => {
    const res = {
      rawValue: this.props.filterDef.componentProps.valueSelected,
      qfilter: this.props.filterDef.qfilter
    }

    this.props.onChange(res)
  }

  render() {
    return <div>{this.props.filterDef.componentProps.placeholder}</div>
  }
}

class Dropdown extends React.PureComponent {
  componentDidMount() {
    this.handleChange()
  }
  handleChange = value => {
    const option = this.props.filterDef.componentProps.options.find(
      o => o.value === value
    )

    const res = {
      rawValue: option ? option.value : undefined,
      qfilter: option ? option.qfilter : undefined
    }

    this.props.onChange(res)
  }

  render() {
    const options = this.props.filterDef.componentProps.options

    return (
      <CrmDropdown
        options={options}
        {...this.props.filterDef.componentProps}
        input={{
          onChange: this.handleChange,
          value: this.props.value ? this.props.value.rawValue : ''
        }}
        disabled={this.props.disabled}
      />
    )
  }
}

class DateRange extends React.Component {
  componentDidMount() {
    this.handleChange()
  }
  handleChange = value => {
    const res = {
      rawValue: value
        ? {
            $gte: value.startDate,
            $lte: value.endDate
          }
        : undefined
    }

    this.props.onChange(res)
  }

  render() {
    const options = this.props.filterDef.componentProps.options

    return (
      <CrmDateRangeDropdown
        style={{ minWidth: 220 }}
        {...this.props.filterDef.componentProps}
        input={{
          value:
            this.props.value && this.props.value.rawValue
              ? {
                  startDate: this.props.value.rawValue['$gte'],
                  endDate: this.props.value.rawValue['$lte']
                }
              : ''
        }}
        disabled={this.props.disabled}
        onChange={this.handleChange}
      />
    )
  }
}

const filtersRenderers = {
  UserSelect,
  UserGroupSelect,
  TextInput,
  CampaignSelect,
  LeadSourceSelect,
  LeadSourceTypeSelect,
  AccountType,
  Checkbox,
  Dropdown,
  DateRange,
  TagType
}

export default filtersRenderers
