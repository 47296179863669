import React, { useState } from 'react'
import {
  CrmDropdown,
  CrmJsonEditArea,
  CrmButton,
  CrmTextInput
} from 'crm-components'
import fetchVariableList from './variable-list'

const FilterCreator = (props) => {
  const [result, setResult] = useState(props.input.value)
  const { variables } = fetchVariableList()

  const onJsonChange = (json) => {
    setResult(json)
    props.input.onChange(json)
  }
  return (
    <React.Fragment>
      <div className="col-md-6">
        <CrmJsonEditArea
          input={{
            value: result,
            onChange: onJsonChange
          }}
        />
      </div>
      <div className="col-md-6">
        <NewFieldForm
          fields={props.fields}
          variables={variables}
          deleteFilter={(field) => {
            const newResult = Object.assign({}, result)
            delete newResult[field]
            setResult(newResult)
          }}
          addFilter={({ field, value }) => {
            const newResult = Object.assign({}, result)
            newResult[field] = value
            setResult(newResult)
            props.input.onChange(newResult)
          }}
        />
      </div>
    </React.Fragment>
  )
}

const NewFieldForm = (props) => {
  const [selected, setSelected] = useState()
  const [value, setValue] = useState() as any
  const [selectedOperation, setOperation] = useState()
  const [$gte, setGte] = useState('')
  const [$lte, setLte] = useState('')
  const options = props.fields.map((field) => ({
    name: field,
    value: field
  }))
  const operationOptions = operations.map((type) => ({
    name: type,
    value: type
  }))
  const variableOptions = props.variables.map((v) => ({
    name: v.description,
    value: v.variable
  }))
  return (
    <React.Fragment>
      <CrmDropdown
        placeholder="Select Field"
        options={options}
        input={{
          value: selected,
          onChange: (field) => {
            setSelected(field)
          }
        }}
      />
      <CrmDropdown
        style={{ marginTop: 10 }}
        placeholder="Select date range"
        options={operationOptions}
        input={{
          value: selectedOperation,
          onChange: (field) => {
            setOperation(field)
            if (field === 'equal') {
              setValue('')
            } else {
              setValue({ $gte: '', $lte: '' })
            }
          }
        }}
      />
      {selectedOperation === 'range' && (
        <div>
          <CrmDropdown
            style={{ marginTop: 10 }}
            placeholder="Greater than equal"
            options={variableOptions}
            input={{
              value: $gte,
              onChange: (value) => {
                setGte(value)
                setValue({
                  $gte: value,
                  $lte: $lte
                })
                props.addFilter({
                  field: selected,
                  value: {
                    $gte: value,
                    $lte: $lte
                  }
                })
              }
            }}
          />
          <CrmDropdown
            style={{ marginTop: 10 }}
            placeholder="Less than equal"
            options={variableOptions}
            input={{
              value: $lte,
              onChange: (value) => {
                setLte(value)
                setValue({
                  $gte,
                  $lte: value
                })
                props.addFilter({
                  field: selected,
                  value: {
                    $gte,
                    $lte: value
                  }
                })
              }
            }}
          />
        </div>
      )}
      {selectedOperation === 'equal' && (
        <CrmTextInput
          style={{ marginTop: 10 }}
          placeholder="Equal"
          input={{
            value: value,
            onChange: (e) => {
              const value = e.target.value
              setValue(value)
              props.addFilter({
                field: selected,
                value: value
              })
            }
          }}
        />
      )}
      <div style={{ marginTop: 15 }} className="util-flexCenter">
        <CrmButton
          small
          grouped
          label="Update Filter"
          onClick={() =>
            props.addFilter({
              field: selected,
              value
            })
          }
        />
        <CrmButton
          small
          grouped
          label="Delete Filter"
          onClick={() => props.deleteFilter(selected)}
        />
      </div>
    </React.Fragment>
  )
}

const operations = ['range', 'equal']

export default FilterCreator
