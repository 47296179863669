import React from 'react'
import { shape, func } from 'prop-types'
import withLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import ctMonthlyLeadsHoc from './ct-monthly-leads-dashboard-hoc'
import {
  CtMonthlyLeadDashboardTable,
  CtMonthlyLeadDashboardWrapper
} from './index'

const CtMonthlyLeadsDashboard = ctMonthlyLeadsHoc(props => {
  return (
    <CtMonthlyLeadDashboardWrapper {...props}>
      <CtMonthlyLeadDashboardTable analyticsContext={props.analyticsContext} />
    </CtMonthlyLeadDashboardWrapper>
  )
})

CtMonthlyLeadsDashboard.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

export default withLeadsPopupHoc(ctMonthlyLeadsHoc(CtMonthlyLeadsDashboard))
