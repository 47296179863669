/* eslint-disable react/no-unused-prop-types, react/require-default-props */
import React, { PureComponent } from 'react'
import { CrmButton } from '@cartrack-crm/ui'
interface ICrmSubmitButton {
  type?: string
  label?: string
  enable?: boolean
}
class CrmSubmitButton extends PureComponent<ICrmSubmitButton> {
  type
  enable
  label
  constructor(props) {
    super(props)
    this.checkSubmitState = this.checkSubmitState.bind(this)
  }
  componentWillMount() {
    this.type = this.props.type
    this.enable = this.props.enable
    this.label = this.props.label
  }

  checkSubmitState = () => {
    this.label = this.props.label
    if (this.enable !== this.props.enable) {
      if (this.enable) {
        this.label = 'Saving...'
        this.enable = this.props.enable
      } else {
        this.label = 'Saved'
        setTimeout(() => {
          this.setState({})
          this.enable = this.props.enable
        }, 2000)
      }
    }
  }

  render() {
    this.checkSubmitState()
    const type = this.enable ? this.type : 'button'
    return (
      <CrmButton
        {...this.props}
        type={type}
        label={this.label}
        enable={this.enable}
      />
    )
  }
}


export default CrmSubmitButton
