import React, { PureComponent } from 'react'
import uuidv4 from 'uuid/v4'
import { FormattedMessage } from 'react-intl'
import { shape, func, bool } from 'prop-types'
import { CrmButton } from 'crm-components'

class TableWidthEdit extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      editedRow: undefined,
      arrayData: this.buildArrayData(props),
      sectionData: props.sectionData ? { ...props.sectionData } : {}
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data !== this.props.data ||
      nextProps.sectionData !== this.props.sectionData
    ) {
      this.setState({
        ...this.state,
        editedRow: undefined,
        isEditing: false,
        arrayData: this.buildArrayData(nextProps),
        sectionData: { ...nextProps.sectionData }
      })
    }
  }

  buildArrayData(props) {
    let arrayData = props.data
    if (arrayData && typeof arrayData === 'object' && arrayData.value) {
      arrayData = arrayData.value
    }
    arrayData = arrayData && Array.isArray(arrayData) ? [...arrayData] : []
    const primaryKeyName = this.props.section.primaryKey
      ? this.props.section.primaryKey
      : 'id'
    // Add missing keys
    arrayData = arrayData
      ? arrayData.map(o => {
          const ret = { ...o }
          if (!ret[primaryKeyName]) {
            ret[primaryKeyName] = uuidv4()
          }
          return ret
        })
      : []
    return arrayData
  }

  handleFormSave = rowData => {
    const newSectionData = { ...this.state.sectionData }
    newSectionData.value = this.state.arrayData
    if (!newSectionData.value) {
      newSectionData.value = []
    }
    if (rowData.id) {
      console.dir(newSectionData.value)
      const idx = newSectionData.value.findIndex(r => r.id === rowData.id)
      console.log('Found idx')
      console.log(idx)
      if (idx !== undefined) {
        newSectionData.value[idx] = rowData
      }
    } else {
      const primaryKeyName = this.props.section.primaryKey
        ? this.props.section.primaryKey
        : 'id'
      rowData[primaryKeyName] = uuidv4() // eslint-disable-line
      newSectionData.value.push(rowData)
    }
    // Tthis.setState({
    //   ...this.state,
    //   isEditing: false,
    //   editedRow: undefined,
    //   sectionData: newData
    // })
    console.log('New Data:')
    console.dir(newSectionData)
    this.props.onSave(newSectionData)
  }
  handleFormCancel = () => {
    console.log('handleFormCancel')
    this.setState({ ...this.state, isEditing: false, editedRow: undefined })
  }
  handleFormSaved = () => {
    console.log('handleFormSaved')
    this.setState({ ...this.state, isEditing: false, editedRow: undefined })
  }

  handleClickEdit = event => {
    console.log('handleClickEdit')
    const rowId = event.currentTarget.id
    console.log(rowId)
    const data = this.state.arrayData
    console.dir(data)
    console.log('this.props.section.primaryKey')
    console.log(this.props.section.primaryKey)
    const key = this.props.section.primaryKey
    const row = data.find(r => String(r[key]) === String(rowId))
    console.dir(row)
    this.setState({ ...this.state, isEditing: true, editedRow: row })
  }

  handleClickNew = () => {
    const newRow = this.props.newElementFactory
      ? this.props.newElementFactory()
      : { entityUid: 'new' }
    newRow.$new = true
    this.setState({ ...this.state, isEditing: true, editedRow: newRow })
  }

  renderForm() {
    const form = this.props.definition.form
    console.log('render Form')
    console.dir(form)
    console.dir(this.props.section)
    if (form.component) {
      const formProps = {
        ...this.props,
        onSave: this.handleFormSave,
        onCancel: this.handleFormCancel,
        onSaved: this.handleFormSaved,
        data: this.state.editedRow,
        entity: this.state.editedRow,
        entityUid: this.state.editedRow
          ? this.state.editedRow.entityUid
          : undefined,
        isNew: this.state.editedRow ? this.state.editedRow.$new : false
      }
      return form.component(formProps)
    }
    return (
      <div />
      // <DynamicForm
      //   form={form}
      //   data={this.state.editedRow}
      //   onCancel={this.handleFormCancel}
      //   onSave={this.handleFormSave}
      // />
    )
  }
  render() {
    const { section, definition } = this.props
    const { isEditing } = this.state
    const columns =
      definition && definition.table && definition.table.columns
        ? definition.table.columns
        : []
    // C console.log('TABLE DETAILS DATA')
    // console.dir(this.props)
    // console.dir(this.state)
    if (isEditing) {
      return this.renderForm()
    }
    return (
      <div>
        <table className="CrmFlatTable">
          <thead className="CrmFlatTable-thead">
            <tr>
              {columns &&
                columns.map(col => (
                  <th key={col.Header} className="CrmFlatTable-thead-th">
                    {col.Header}
                  </th>
                ))}
              <th>
                <FormattedMessage
                  id="crm.ui.table.edit"
                  defaultMessage="Edit"
                />
              </th>
            </tr>
          </thead>
          <tbody className="CrmFlatTable-tbody">
            {this.state.arrayData &&
              this.state.arrayData.map(row => (
                <tr
                  key={row[section.primaryKey]}
                  className="CrmFlatTable-tbody-tr"
                >
                  {columns.map(col => (
                    <td key={col.Header} className="CrmFlatTable-tbody-td">
                      {col.Cell
                        ? col.Cell({ original: row }) // eslint-disable-line
                        : col.accessor
                        ? row[col.accessor]
                        : ''}
                    </td>
                  ))}
                  <td className="CrmFlatTable-tbody-td">
                    {this.props.isEditable && (
                      <CrmButton
                        id={row[section.primaryKey]}
                        label={
                          <FormattedMessage
                            id="crm.ui.button.edit"
                            defaultMessage="edit"
                          />
                        }
                        onClick={this.handleClickEdit}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {this.props.isEditable && (
          <div className="util-marginTop">
            <CrmButton
              onClick={this.handleClickNew}
              label={
                <FormattedMessage
                  id="crm.ui.button.+_Add"
                  defaultMessage="+ Add"
                />
              }
            />
          </div>
        )}
        {/* <div>
          State data:
          {JSON.stringify(this.state.data)}
          State section data:
          {JSON.stringify(this.state.sectionData)}
          Props data
          {JSON.stringify(this.props.data)}
        </div> */}
      </div>
    )
  }
}

TableWidthEdit.propTypes = {
  section: shape({}).isRequired,
  definition: shape({}).isRequired,
  onSave: func.isRequired,
  data: shape({}).isRequired,
  sectionData: shape({}).isRequired,
  isEditable: bool.isRequired,
  newElementFactory: func
}

TableWidthEdit.defaultProps = {
  editingId: undefined,
  minutesId: undefined,
  newElementFactory: undefined
}

export default TableWidthEdit
