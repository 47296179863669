import React, { useEffect, useState } from 'react'
import { mapContextToFilter, convertUnderlineFilterToObject } from '../dashboard-utils'
import { CrmModal } from '../../../components/index'
import { FilterSectionOpportunity } from '@cartrack-crm/crm-filter-section'
import { DynamicTableComponent, genericTableFactory } from '@cartrack-crm/crm-analytics'
import { mapDefaultToContextFilters } from 'crm-utils/opportunities-utils'
import { useAnalyticsContext } from 'crm-core/analytics/context'
import TableFilters from '../table-filters'
import { get, isEmpty } from 'lodash'

const initialColumns = [
  { field: 'account.name', name: 'Account' },
  { field: 'account.lead_campaign.name', name: 'Lead Campaign' },
  { field: 'quantity', name: 'Quantity' },
  { field: 'account.lead_in_date', name: 'Lead In Date' },
  { field: 'confidence_level', name: 'Confidence' },
  { field: 'due_date', name: 'Due Date' },
  { field: 'owner_user.full_name', name: 'Owner' },
  { field: 'created_time', name: 'Created', type: 'date' },
  { field: 'completed_date', name: 'Completed Date' }
]

const qlOptions = {
  options: ownProps => {
    const props = { analyticsContext: { filters: ownProps.filters } }

    let filter: any = {
      ...ownProps.filters,
      ...mapContextToFilter(props)
    }

    delete filter.ownerUserUid

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl
  }),
  fetchPolicy: 'no-cache'
}

const OpportunitiesTable: React.ComponentType<DynamicTableComponent> = genericTableFactory({
  qlOptions,
  rootQLType: 'Opportunity',
  useEdges: true,
  queryRoot: 'opportunities'
})

interface Props {
  isOpen: boolean
  onClose: Function
  filters: any
  analyticsContext: any
}

const OpportunitiesPopup: React.SFC<Props> = props =>  (
    <CrmModal isOpen={props.isOpen} title="Opportunities Details" onClose={props.onClose} size="xl">
      <OpportunitiesTable
        {...props}
        initialColumns={initialColumns}
        filtersComponent={
          <TableFilters
            {...props}
            additionalFilters={
              <FilterSectionOpportunity
                style={{
                  borderBottom: 0,
                  minWidth: 150,
                  margin: '10px 0',
                  padding: '0 20px'
                }}
              />
            }
          />
        }
      />
    </CrmModal>
  )


OpportunitiesPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

const WithAnalyticsContextPopup: React.SFC<Props> = props => {
  const analyticsContext: any = useAnalyticsContext()

  useEffect(() => {
    const mapped_filters: any = mapDefaultToContextFilters(props.filters)

    if (get(mapped_filters, 'user', false) && isEmpty(get(mapped_filters, 'user.user_uid.value', null))) {
      delete mapped_filters.user
    }

    analyticsContext.setFilters({ ...mapped_filters }, false)
  }, [props.filters])

  return <OpportunitiesPopup {...props} />
}

export default WithAnalyticsContextPopup
