/* eslint-disable */

import gql from 'graphql-tag'
import {
  qlProcessFields,
  qlProcessTypeFields,
  qlProcessWithCurrentStep
} from './processes-fragments'
import {
  qlDocument,
  qlDocumentOwnFields,
  qlDocumentItemOwnFields,
  qlDocumentTypeOwnFields
} from 'crm-api/documents-api'

import { fragmentDocumentFields } from './documents'

// export const qlTasksFields = ` task_uid name assignments { due_date user { user_uid full_name } }  `
// export const fragmentTaskFields = gql`
// fragment TasksFields on Task {
//  ${qlTasksFields}
// }
// `

export const fragmentOpportunityWithFields = `
fragment OpportunityFields on Opportunity {
  opportunity_uid
  opportunity_type_uid
  account_uid 
  name
  confidence_level
  contract_length
  value value_usd
  value_currency
  is_deleted
  opportunity_is_deleted
  quantity
  due_date
  marketing_campaign_uid
  commission_reduction
  special_project
  business_source
  forecast
  current_progress_update
  action_plan_for_next_steps
  format
  owner_user_uid
  owner_user {
    user_uid
    full_name
  }
  organization_unit_uid
  organization_unit {
    organization_unit_uid
    name
  }
  documents { 
    ${qlDocument} process { ${qlProcessWithCurrentStep} } 
  }
  opportunity_type { 
    name 
  }
  contract_signed_date
  is_existing_customer
  winning_quotation_uid
  status_code
  completed_time
  created_time
  winning_quotation { 
    ...DocumentFields
  }
}
${fragmentDocumentFields}`

export const fragmentContractWithFields = `
fragment ContractFields on Document {
  ${qlDocumentOwnFields} 
  document_type { ${qlDocumentTypeOwnFields} } 
  items { ${qlDocumentItemOwnFields} product { name product_uid name description code kind is_addon } } 
}`

export const opportunitiesFragment = `
fragment AccountOpportunities on Account {
  opportunities {
    ...OpportunityFields
  }
}
${fragmentOpportunityWithFields}
`

export const contractsFragment = `
fragment AccountContracts on Account {
  contracts {
    ...ContractFields
  }
}
${fragmentContractWithFields}
`

export const qlOpportunityTypeFields =
  ' opportunity_type_uid name is_recurring '

export const qlqAccountOpportunities = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      ...AccountOpportunities
    }
  }
  ${opportunitiesFragment}
`

export const qlGetOpportunity = gql(`
query opportunity($opportunity_uid: String!) {
  opportunity(opportunity_uid: $opportunity_uid)  {
    ...OpportunityFields
  }
}
${fragmentOpportunityWithFields}`)

export const qlqListOpportunities = gql`
  query opportunities_list_qm_paged(
    $sort: [JSON]
    $offset: Int
    $limit: Int
    $filter: JSON
    $fields: [String]
    $aggregate: [JSON]
  ) {
    opportunities_list_qm_paged(
      sort: $sort
      offset: $offset
      limit: $limit
      filter: $filter
      aggregate: $aggregate
      fields: $fields
    ) {
      data {
        lead_source_type_uid
        lead_source_type
        opportunity_uid
        account_uid
        opportunity_name
        confidence_level
        contract_length
        total_quotation
        quotation_waiting
        value
        value_usd
        value_currency
        is_deleted
        opportunity_is_deleted
        vehicle_count
        due_date
        campaign_uid
        process_uid
        is_overdue
        account_name
        account_uid
        opportunity_type_uid
        opportunity_type_name
        owner_user_name
        owner_user_uid
        process_last_update_time
        process_started_time
        owner_user_name
        current_step_name
        opportunities_size
        current_process_step_code
        current_process_step_kind
        opportunity_due_date_month
        win_date
        parent_process_uid
      }
      count
    }
  }
`

export const qlqListQuotationToApprove = gql`
  query quotation_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    quotation_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      data {
        account_name
        account_name_local
        account_uid
        instance_uid
        name
        opportunity_name
        document_uid
        opportunity_uid
        document_type_uid
        process_uid
        document_type
        sale_type
        quotation_date
        contract_length
        date_presented
        params
        created_date
        currency_code
        document_date
        updated_date
        process {
          process_uid
          account_uid
          process_type_uid
          current_step_uid
          result_type_uid
          owner_user_uid
          owner_pool_uid
          document_uid
          source_process_uid
          campaign_uid
          started_time
          completed_time
          completed_by_user_uid
        }
        document_items {
          document_item_uid
          price_table_item_value_uid
          document_uid
          product_uid
          opportunity_uid
          quantity
          value_net
          value_gross
          value_currency_code
          subscription_net
          subscription_tax
        }
      }
      count
    }
  }
`
