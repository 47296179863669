import React from 'react'
import {
  FilterSectionActivityDate,
  FilterSectionCampaign,
  FilterSectionLead,
  FilterSectionOrganizationUnit,
  FilterSectionUser
} from '@cartrack-crm/crm-filter-section'

export const SectionFilters = () => {
  return (
    <div
      style={{
        borderTop: '1px solid #97979780',
        borderLeft: '1px solid #97979780'
      }}
    >
      <div className="util-flexRow">
        <div className="util-flexGrow">
          <FilterSectionLead />
        </div>
        <div className="util-flexGrow">
          <FilterSectionOrganizationUnit />
        </div>
      </div>
      <div className="util-flexRow">
        <div className="util-flexGrow">
          <FilterSectionActivityDate />
        </div>
        <div className="util-flexGrow">
          <FilterSectionUser />
        </div>
        <div className="util-flexGrow">
          <FilterSectionCampaign />
        </div>
      </div>
    </div>
  )
}
