/* eslint-disable camelcase */
import { callQLApi } from '../api-utils'

export const qlAttribute =
  ' attribute_uid name description data_type type level code details master_uid territory_uid '

export default {
  create: params => {
    console.log('API Attribute ', params)

    const qlParams = {
      query: ` mutation  attribute_definition_create($attribute_definition_in: attribute_definition_in) { attribute_definition_create(attribute_definition: $attribute_definition_in) { attribute_uid } } `,
      variables: {
        attribute_definition_in: params
      }
    }
    return callQLApi(qlParams).then(res => res.attribute_definition_create)
  },
  update: params => {
    const qlParams = {
      query: ` mutation  attribute_definition_update($attribute_definition_in: attribute_definition_in) { attribute_definition_update(attribute_definition: $attribute_definition_in) { attribute_uid } }  `,
      variables: {
        attribute_definition_in: params
      }
    }
    console.log('resource_update ql update: ', qlParams)
    return callQLApi(qlParams).then(res => res.resource_update)
  },
  updateItems: params => {
    const qlParams = {
      query: ` mutation  attribute_definition_updates($attribute_definition_ins: attribute_definition_ins) { attribute_definition_updates(attribute_definitions: $attribute_definition_ins) { attribute_uid } }  `,
      variables: {
        attribute_definition_ins: { attributes: params }
      }
    }
    console.log('resource_update ql update: ', qlParams)
    return callQLApi(qlParams).then(res => res.resource_update)
  },
  fetch: params => {
    const filters = []
    if (params) {
      if (params.attribute_uid) {
        filters.push('attribute_uid:"' + params.attribute_uid + '"')
      }

      if (params.type) {
        filters.push('type:"' + params.type + '"')
      }

      if (params.master_uid) {
        filters.push('master_uid:"' + params.master_uid + '"')
      }
    }
    const qlParams = {
      query: ` { attribute_definitions ${
        filters.length > 0 ? '(' + filters.join(',') + ')' : ''
      } { ${qlAttribute} } } `
    }
    console.log('attributes ql : ', qlParams)
    return callQLApi(qlParams).then(res => res.attribute_definitions)
  }
}
