import React, { useState, useEffect } from 'react'
import {
  CrmLoadingIcon,
  CrmButton,
  CrmFullpageContainer,
  CrmModal,
  CrmTextInput,
  CrmDropdown,
  CrmLabeledField
} from '@cartrack-crm/ui'
import {
  useFetchEmailRecipient,
  useDeleteEmailRecipient,
  useFetchOneEmailRecipient,
  useInsertEmailRecipient,
  useUpdateEmailRecipient
} from './hook/use-email-recipient-action-hook'
import { FlatTable, CrmHbox } from 'crm-components'
import { useCrmToastr, CrmToastrType, CrmToastrPosition } from '@cartrack-crm/ui/src/toastrs'
import { FormattedDate, FormattedTime } from 'react-intl'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useGeneralPermissionsContext, PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

export const EmailRecipient = () => {
  const [mode, setMode] = useState('create')
  const [saving, setSaving] = useState(false)
  const [currentEmail, setEmail] = useState<any>({})
  const [show, setShowModal] = useState(false)
  const { data, loading, refetch } = useFetchEmailRecipient()
  const fetchOne = useFetchOneEmailRecipient()
  const [deleteEmail, mutatedDelete] = useDeleteEmailRecipient()
  const [createEmail, mutatedCreate] = useInsertEmailRecipient()
  const [updateEmail, mutatedUpdate] = useUpdateEmailRecipient()
  const listEmail = data?.email_recipient_page?.data ?? []
  const [showToastr, hideToastr] = useCrmToastr()
  const { hasPermissionByType } = useGeneralPermissionsContext()
  const alertMessageBox = async () => {
    const message = mutatedDelete?.data?.email_recipient_delete?.message ?? 'Email deleted successfully'
    let type = mutatedDelete?.data?.email_recipient_delete?.message ? CrmToastrType.error : CrmToastrType.success
    showToastr(type, message)
  }

  const onDeleteEmail = selectedUid => {
    const deleteData = async () => {
      await deleteEmail({ variables: { crm_system_recipient_uid: selectedUid } })
      refetchAll()
      hideToastr()
      await alertMessageBox()
    }
    showToastr(CrmToastrType.warning, 'Are you sure to remove this email ?', {
      onBtnPrimary: deleteData,
      onBtnSecondary: hideToastr,
      btnPrimaryTitle: 'Delete',
      btnSecondaryTitle: 'Cancel',
      timeToShow: null,
      position: CrmToastrPosition.middle
    })
  }

  const showModal = async (crm_system_recipient_uid = null) => {
    setShowModal(true)
    setSaving(true)
    if (crm_system_recipient_uid) {
      setMode('update')
      const res = await fetchOne.refetch({ crm_system_recipient_uid })
      const currentEmail = res?.data?.email_recipient
      setEmail(currentEmail)
    } else {
      setMode('create')
      setEmail({})
    }
    setSaving(false)
  }

  const handleFieldChange = (value, field) => {
    const newObject = { ...currentEmail }
    newObject[field] = value
    setEmail(newObject)
  }

  const doCreate = async createObject => {
    await createEmail({
      variables: {
        payload: {
          email: createObject?.email,
          email_type: createObject?.email_type ?? 'cc'
        }
      }
    })
  }

  const doUpdate = async updateObject => {
    await updateEmail({
      variables: {
        crm_system_recipient_uid: updateObject?.crm_system_recipient_uid,
        payload: { email: updateObject?.email, email_type: updateObject?.email_type }
      }
    })
  }

  const handleSubmit = async () => {
    const data = { ...currentEmail }
    if (mode === 'create') {
      await doCreate(data)
    } else {
      await doUpdate(data)
    }
  }
  const refetchAll = async () => {
    await refetch()
  }
  useEffect(() => {
    if (mutatedCreate.data?.email_recipient_create?.status === 'success') {
      showToastr(CrmToastrType.success, 'Email created successfully ')
      refetchAll()
      setShowModal(false)
    }
  }, [mutatedCreate.data?.email_recipient_create?.status])

  useEffect(() => {
    if (mutatedUpdate.data?.email_recipient_update?.status === 'success') {
      showToastr(CrmToastrType.success, 'Email updated successfully ')
      refetchAll()
      setShowModal(false)
    }
  }, [mutatedUpdate.data?.email_recipient_update?.status])

  const columns = [
    {
      Header: 'Email',
      Cell: row => (
        <span onClick={() => showModal(row?.original?.crm_system_recipient_uid)}>{row?.original?.email}</span>
      )
    },
    {
      Header: 'Type',
      accessor: 'email_type'
    },
    {
      Header: 'Created by',
      Cell: row => row?.original?.created_by_user?.name
    },
    {
      Header: 'Created at',
      Cell: row => {
        if (row.original && row.original.created_time) {
          return (
            <span>
              <FormattedDate value={row.original.created_time} /> <FormattedTime value={row.original.created_time} />
            </span>
          )
        }
        return <span />
      }
    },
    {
      Header: 'Updated by',
      Cell: row => row?.original?.updated_by_user?.name
    },
    {
      Header: 'Updated at',
      Cell: row => {
        if (row.original && row.original.updated_time) {
          return (
            <span>
              <FormattedDate value={row.original.updated_time} /> <FormattedTime value={row.original.updated_time} />
            </span>
          )
        }
        return <span />
      }
    },
    {
      Header: 'Action',
      Cell: row => (
        <CrmButton
          iconButton
          icon={faTrash}
          isSaving={mutatedDelete.loading}
          onClick={() => onDeleteEmail(row?.original?.crm_system_recipient_uid)}
        />
      )
    }
  ]

  return (
    <CrmFullpageContainer hasPadding>
      {!hasPermissionByType(PERMISSIONS_TYPES.SUPER_ADMIN) && <h1> Not have permission to view this page</h1>}
      {hasPermissionByType(PERMISSIONS_TYPES.SUPER_ADMIN) && (
        <React.Fragment>
          <div className="util-paddingMd">
            <CrmButton label="Add Email" type="primary" onClick={() => showModal(null)} />
          </div>
          <CrmHbox fill>
            {loading && <CrmLoadingIcon />}
            {!loading && <FlatTable data={listEmail} columns={columns} />}
          </CrmHbox>
          <CrmModal
            isOpen={show}
            isShowCloseBtn
            onClose={() => setShowModal(false)}
            onPrimaryButton={handleSubmit}
            onSecondaryButton={() => setShowModal(false)}
            primaryButtonLabel="Submit"
            secondaryButtonLabel="Cancel"
            showFooter
            isSaving={mutatedUpdate?.loading || mutatedCreate?.loading}
          >
            <div className="CrmForm">
              {saving && <CrmLoadingIcon />}
              {!saving && (
                <div className="row">
                  <div className="col-md-12">
                    <CrmTextInput
                      placeholder="Email"
                      iconName="email"
                      input={{
                        onChange: e => handleFieldChange(e?.target?.value, 'email'),
                        value: currentEmail?.email
                      }}
                      withBackground
                    />
                  </div>
                  <div className="col-md-12" style={{ marginTop: '15px' }}>
                    <CrmDropdown
                      title="Email type"
                      options={[
                        { name: 'CC', value: 'cc' },
                        { name: 'BCC', value: 'bcc' }
                      ]}
                      input={{
                        value: currentEmail?.email_type ?? 'cc',
                        onChange: e => handleFieldChange(e, 'email_type')
                      }}
                    />
                  </div>
                  <div className="col-md-12" style={{ marginTop: '15px' }}>
                    {mutatedCreate?.data?.email_recipient_create?.message && (
                      <label style={{ color: 'red' }}> {mutatedCreate?.data?.email_recipient_create?.message}</label>
                    )}
                    {mutatedUpdate?.data?.email_recipient_update?.message && (
                      <label style={{ color: 'red' }}> {mutatedUpdate?.data?.email_recipient_update?.message}</label>
                    )}
                  </div>
                  {mode === 'update' && (
                    <div className="col-md-12">
                      <CrmLabeledField label="Created at">{currentEmail.created_time}</CrmLabeledField>
                      <CrmLabeledField label="Created by">{currentEmail.created_by_user?.name}</CrmLabeledField>
                      <CrmLabeledField label="Updated at">{currentEmail.updated_tiem ?? '-'}</CrmLabeledField>
                      <CrmLabeledField label="Updated by">{currentEmail.updated_by_user?.name ?? '-'}</CrmLabeledField>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CrmModal>
        </React.Fragment>
      )}
    </CrmFullpageContainer>
  )
}
