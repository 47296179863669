import React, { PureComponent } from 'react'
import { string, shape } from 'prop-types'
import { connect } from 'react-redux'
import {
  CrmFullpageContainer,
  CrmButton,
  CrmDropdownButton
} from 'crm-components'
import { bindActions } from 'crm-utils/reducer-utils'
import {
  actions as phoneActions,
  getCurrentAgentStatusName,
  getCurrentAgentStatusCode
} from 'crm-duxs/phone/phone-reducer'

class AgentStatus extends PureComponent {
  constructor(props) {
    super(props)
    console.log('AgentStatus construtor')
  }

  handleAgentStatusChange = code => {
    this.props.phoneActions.agentStatusChange({ code })
  }

  render() {
    console.log(
      'AgentStatus : ',
      this.props.currentAgentStatusName,
      this.props.currentAgentStatusCode
    )

    const primaryButton = {
      type: 'primary',
      label: 'Test',
      onClick: () => {
        console.log('OnClick')
      }
    }

    const optionButtons = [
      { ...primaryButton },
      {
        ...primaryButton,
        onClick: () => {
          console.log('Onclick 222')
        }
      }
    ]

    /* eslint-disable */
    return (
      <CrmFullpageContainer hasPadding vbox>
        <div>
          <CrmDropdownButton {...primaryButton} options={optionButtons} />
        </div>
        <div fill>
          Current status : {this.props.currentAgentStatusName} -{' '}
          {this.props.currentAgentStatusCode}
          <div>
            <CrmButton
              label="Idle"
              onClick={() => {
                this.handleAgentStatusChange('idle')
              }}
            />
            <CrmButton
              label="Break"
              onClick={() => {
                this.handleAgentStatusChange('break')
              }}
            />
            <CrmButton
              label="Work"
              onClick={() => {
                this.handleAgentStatusChange('working')
              }}
            />
            <CrmButton
              label="Auto CRM"
              onClick={() => {
                this.handleAgentStatusChange('auto_crm')
              }}
            />
            <CrmButton
              label="Lunch"
              onClick={() => {
                this.handleAgentStatusChange('lunch')
              }}
            />
            <CrmButton
              label="Busy"
              onClick={() => {
                this.handleAgentStatusChange('unavailable')
              }}
            />
          </div>
        </div>
      </CrmFullpageContainer>
    )
    /* eslint-enable */
  }
}

function mapStateToProps(state) {
  return {
    currentAgentStatusName: getCurrentAgentStatusName(state),
    currentAgentStatusCode: getCurrentAgentStatusCode(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    phoneActions: bindActions(dispatch, phoneActions)
  }
}

AgentStatus.propTypes = {
  currentAgentStatusName: string.isRequired,
  currentAgentStatusCode: string.isRequired,
  phoneActions: shape({}).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentStatus)
