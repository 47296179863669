/* eslint-disable global-require */
import React, { Component } from 'react'
import { shape } from 'prop-types'
import { FormSection } from 'redux-form'
import TaskFormFields from '../../tasks/components/task-form-fields.jsx'

class ActivityFormNewTask extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps() {
    // // check if
    // if (nextProps.task) {
    //   if (!nextProps.task.assign_to_user_uid) {
    //   }
    // }
  }
  componentWillMount() {}

  render() {
    return (
      <div>
        <FormSection name="task">
          <TaskFormFields
            task={this.props.task}
            isSaving={false}
            isSimpleReminder="false"
          />
        </FormSection>
      </div>
    )
  }
}

ActivityFormNewTask.propTypes = {
  task: shape({})
}

ActivityFormNewTask.defaultProps = {
  task: {}
}

export default ActivityFormNewTask
