/* eslint-disable global-require, camelcase */
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { string, func, arrayOf, shape } from 'prop-types'

import ActivityForm from './activity-form.jsx'
import { CrmDropdown, CrmButton, CrmLabeledField } from 'crm-components'
import {
  actions as activityActions,
  getActivity
} from 'crm-duxs/activity-reducer'
import {
  getActivityTypes,
  fetchActivityTypes,
  createActivity
} from 'crm-duxs/activities-reducer'
import { getUser } from 'crm-duxs/crm-reducer'

class NewActivityForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.selectedActivityType && nextProps.activityTypes) {
      // Mock - to quickly create new meetings
      // this.props.doInitNewActivity(this.props.accountUid, nextProps.activityTypes[2])
      // const activity = this._buildNewActivity(nextProps.activityTypes[2])
      // this.setState({
      //   ...this.state,
      //   selectedActivityType: nextProps.activityTypes[2], // Temporary - some default value
      //   activity
      // })
    }
  }

  handleActivityTypeChange = value => {
    console.dir(value)
    console.log('handleActivityTypeChange')
    const selectedActivityType = this.props.activityTypes.find(
      a => a.activity_type_uid === value
    )
    // Const activity = this._buildNewActivity(activeOption)
    this.props.doInitNewActivity(this.props.accountUid, selectedActivityType)

    const newState = {
      ...this.state,
      selectedActivityType
    }
    this.setState(newState)
  }

  setIsSaving(isSaving) {
    this.setState({ ...this.state, isSaving })
  }

  handleResetActivityType = () => {
    this.setState({
      ...this.state,
      selectedActivityType: undefined,
      activity: undefined
    })
  }

  prepareActivity(activity) {
    return activity
  }

  handleSaveNewActivity = event => {
    console.log('handleSaveNewActivity')
    console.dir(event)
    this.setIsSaving(true)
    const activity = this.prepareActivity(event)
    this.props.doCreateActivity(activity, {
      onSuccess: event => {
        console.log('Form - on save success')
        this.setIsSaving(false)
        console.dir(event)
        this.props.onActivityCreated({ activity: event.activity })
      },
      onError: error => {
        console.log('Form = on save error')
        this.setIsSaving(false)
        console.dir(error)
      }
    })
  }

  render() {
    const { activityTypes } = this.props
    const handleActivityTypeChange = this.handleActivityTypeChange
    const { selectedActivityType } = this.state
    const activityTypeName = selectedActivityType
      ? selectedActivityType.name
      : ''
    const activityTypesOptions = activityTypes
      ? activityTypes
          .filter(type => type.is_active)
          .map(type => ({
            name: type.activity_type_uid,
            id: type.name,
            value: type.activity_type_uid,
            element: <div id={type.activity_type_uid}>{type.name}</div>
          }))
      : []
    console.log('new activity form ')
    console.dir(this.props)
    return (
      <div className="NewActivityForm">
        <div className="NewActivityForm-title">Create new Activity</div>
        {activityTypes && (
          <div className="row">
            <div className="col-md-6">
              {!selectedActivityType && (
                <CrmDropdown
                  title="Activity Type"
                  options={activityTypesOptions}
                  activeOption={activityTypeName}
                  input={{
                    onChange: handleActivityTypeChange
                  }}
                />
              )}
              {selectedActivityType && (
                <CrmLabeledField
                  label="Activity Type"
                  value={activityTypeName}
                />
              )}
            </div>
            <div className="col-md-3">
              {selectedActivityType && (
                <CrmButton
                  label="Change Type"
                  onClick={this.handleResetActivityType}
                />
              )}
            </div>
          </div>
        )}
        {this.props.activity && (
          <ActivityForm
            isSaving={this.state.isSaving}
            onCreateActivity={this.handleSaveNewActivity}
            currentUser={this.props.currentUser}
          />
        )}
      </div>
    )
  }
}

NewActivityForm.propTypes = {
  accountUid: string.isRequired,
  activityTypes: arrayOf(shape({})).isRequired,
  doCreateActivity: func.isRequired,
  onActivityCreated: func, // Call when new activity has been created
  doInitNewActivity: func.isRequired,
  activity: shape({}),
  currentUser: shape({}).isRequired
}

NewActivityForm.defaultProps = {
  onActivityCreated: () => {},
  activity: undefined
}

function mapStateToProps(state) {
  return {
    activityTypes: getActivityTypes(state),
    activity: getActivity(state),
    currentUser: getUser(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doTest: () => dispatch(),
    doFetchActivityTypes: () => dispatch(fetchActivityTypes()),
    doCreateActivity: (activity, params) =>
      dispatch(createActivity(activity, params)),
    doInitNewActivity: (accountUid, activityType) =>
      dispatch(
        activityActions.initNewActivity.create({ accountUid, activityType })
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewActivityForm)
