import React, { PureComponent } from 'react'
import { string, shape, bool, func, arrayOf } from 'prop-types'
import gql from 'graphql-tag'
import { graphql, compose } from 'react-apollo'
import MinuteFormName from '../../../minutes-form/minute-form-name.jsx'
import { CrmCheckbox } from 'crm-components'

class SurveyBlockChoices extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: this.buildArrayData(props)
    }
    this.options = []
  }

  buildArrayData(props) {
    let arrayData = props.sectionData
    console.log('buildArrayData', arrayData)
    if (arrayData && typeof arrayData === 'object' && arrayData.value) {
      arrayData = arrayData.value
    }
    arrayData = arrayData && Array.isArray(arrayData) ? [...arrayData] : []
    return arrayData
  }

  handleChangeRadio = event => {
    if (!this.props.isEditable) {
      return
    }
    const val = event.currentTarget.value
      ? event.currentTarget.value
      : undefined
    this.setState({ ...this.state, value: val ? [val] : [] }, () =>
      this.handleSave()
    )
  }

  handleChangeCheckbox = (value, name) => {
    if (!this.props.isEditable) {
      return
    }
    const newValues = [...this.state.value]
    if (value) {
      if (newValues.find(o => o.value === name) === undefined) {
        newValues.push(this.options.find(o => o.value === name))
      }
    } else {
      const idx = newValues.findIndex(o => o.value === name)
      if (idx !== undefined) {
        newValues.splice(idx, 1)
      }
    }
    console.log('newValues')
    console.dir(newValues)
    this.setState({ ...this.state, value: newValues }, () => this.handleSave())
  }
  _checkbox = option => {
    const value =
      this.state.value.find(v => v.value === option.value) !== undefined
    return (
      <CrmCheckbox
        id={option.value}
        name={option.value}
        type="checkbox"
        label={option.name}
        input={{
          name: option.value,
          value,
          onChange: this.handleChangeCheckbox
        }}
        disabled={this.props.isCompleted || !this.props.isEditable}
      />
    )
  }

  _radio = option => {
    return (
      <div>
        <input
          type="radio"
          name={this.props.section.code}
          value={option.value}
          onChange={this.handleChangeRadio}
          disabled={this.props.isCompleted}
        />
        <label>{option.name}</label>
      </div>
    )
  }

  _recalculateItems(props) {
    let options = []
    if (
      props &&
      props.items &&
      props.queryField &&
      props.items[props.queryField]
    ) {
      const rawOptions = props.items[props.queryField]
      if (rawOptions) {
        options = rawOptions.map(o => {
          const ret = {}
          ret.value = o[props.primaryKeyName]
          ret.name = o.name
          ret.label = o.name
          return ret
        })
        return options
      }
    }
    return undefined
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.propsItems) {
      //
      const options = this._recalculateItems(nextProps)
      this.setState({ ...this.state, options })
    }
  }

  getDerivedStateFromProps(nextProps) {
    console.log('getDerivedStateFromProps', nextProps)
  }

  handleSave = data => {
    console.log('Survey Block handleSave')
    console.dir(data)
    this.props.handleSave({
      section: this.props.section.code,
      data: { value: this.state.value }
    })
  }

  render() {
    this.options = this._recalculateItems(this.props)

    return (
      <div>
        <MinuteFormName formname={this.props.sectionName} />
        <div className="MinuteOfMeeting-choices row">
          {this.options &&
            this.options.map(o => (
              <div key={o.value} className={this.props.wrapperClassName}>
                {this.props.multiple ? this._checkbox(o) : this._radio(o)}
              </div>
            ))}
        </div>
      </div>
    )
  }
}

SurveyBlockChoices.propTypes = {
  isCompleted: bool.isRequired,
  handleSave: func.isRequired,
  sectionName: string,
  multiple: bool,
  items: arrayOf(shape({})).isRequired,
  section: shape({}).isRequired,
  wrapperClassName: string,
  isEditable: bool.isRequired
}

SurveyBlockChoices.defaultProps = {
  sectionName: undefined,
  multiple: true,
  wrapperClassName: undefined
}

const SurveyBlockChoicesFactory = sectionParams => {
  const query = sectionParams.params.options.query
  const listQuery = gql(query)
  return compose(graphql(listQuery, { name: 'items' }))(SurveyBlockChoices)
}

export default SurveyBlockChoicesFactory
