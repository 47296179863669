/* eslint-disable camelcase */

const fitmentsByDate = {
  kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
  title: 'Fitments by Date',
  dataSource: {
    dataSources: [
      {
        code: 'OrdersListQM',
        alias: 'OrdersListQM',
        filter: {},
        fields: ['completed_date'],
        aggregate: [{ id: 'units_fitted', type: 'sum' }],
        order: [{ id: 'completed_date', desc: true }],
        contextMapping: {
          completed_date: 'date_range'
        }
      }
    ]
  },
  layout: 'flat_table',
  layoutParams: {
    columns: ['completed_date', 'units_fitted']
  },
  version: 3
}

const dashFitments = {
  dashboard_uid: 'dashFitmentsDetails',
  name: 'Fitments Details',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'analitics_widget',
        widgetDefinition: fitmentsByDate
      }
    ]
  }
}

export default dashFitments
