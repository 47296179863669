/* eslint-disable  */
import React from 'react'
import uuidv4 from 'uuid'
import gql from 'graphql-tag'
import { graphql, compose, withApollo } from 'react-apollo'
import { qlAccountAllFields } from 'crm-data/accounts'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

const qlFindDuplicateAccount = gql`
  query find_duplicate_with_details(
    $account_uid: String
    $field: String
    $value: String
  ) {
    find_duplicate_with_details(
      account_uid: $account_uid
      field: $field
      value: $value
    )  {
      duplicate
      accounts_duplicated {
        ${qlAccountAllFields}
      }
  }
}
`

const gqlFindDuplicateAccount = graphql(qlFindDuplicateAccount, {
  name: 'qlFindDuplicateAccount',
  options: ownProps => ({})
})

const accountCheckDuplicateHoc = WrappedComponent => {
  class AccountCheckDuplicateQLWrapper extends React.PureComponent {
    static propTypes = {}
    static defaultProps = {}

    constructor(props) {
      super(props)
      this.state = {
        duplicate: {}
      }
    }

    getErrorMsg = field => {
      let msg
      switch (field) {
        case 'name':
          msg = 'Account name was taken already'
          break
        case 'phone':
          msg = 'Phone already exist'
          break
        case 'email':
          msg = 'Email already exist'
          break
        case 'mobile':
          msg = 'Mobile already exist'
          break
        default:
          msg = 'Data already exist'
          break
      }

      return msg
    }

    handleCheck = async (field, value, accountUid = undefined) => {
      const fieldAvailables = [
        'name',
        'phone',
        'email',
        'mobile',
        'registration_number'
      ]
      if (fieldAvailables.indexOf(field) < 0) {
        return
      }
      const duplicate = this.state.duplicate
      if (!duplicate.hasOwnProperty(field)) {
        duplicate[field] = {}
      }
      if (duplicate[field].hasOwnProperty('search')) {
        if (duplicate[field].search === value) {
          return
        }
      }

      duplicate[field].loading = true
      if (value.length === 0) {
        duplicate[field].loading = false
        duplicate[field].duplicate = false
        duplicate[field].search = value
        this.setState({ duplicate: { ...duplicate } })
        return
      }

      this.setState({ duplicate: { ...duplicate } })
      try {
        const duplicateResult = await this.props.client.query({
          query: qlFindDuplicateAccount,
          variables: {
            account_uid: accountUid,
            field,
            value,
            checkUid: uuidv4()
          },
          fetchPolicy: 'no-cache'
        })
        duplicate[field].search = value
        if (duplicateResult.data.find_duplicate_with_details.duplicate) {
          duplicate[field].duplicate = true
          duplicate[field].error = this.getErrorMsg(field)
          duplicate[field].duplicateData =
            duplicateResult.data.find_duplicate_with_details.accounts_duplicated
        } else {
          duplicate[field].duplicate = false
          duplicate[field].duplicateData = undefined
        }

        duplicate[field].loading = false
        this.setState({ duplicate: { ...duplicate } })
      } catch (err) {
        console.log('handleCheck error', err)
      }
    }

    handleCheckByUid = async (
      field,
      value,
      contactDetailsUid,
      accountUid = undefined
    ) => {
      const fieldAvailables = ['name', 'phone', 'email', 'mobile']
      if (fieldAvailables.indexOf(field) < 0) {
        return
      }
      const duplicate = this.state.duplicate
      if (!duplicate.hasOwnProperty(contactDetailsUid)) {
        duplicate[contactDetailsUid] = {}
      }
      if (duplicate[contactDetailsUid].hasOwnProperty('search')) {
        if (duplicate[contactDetailsUid].search === value) {
          return
        }
      }

      duplicate[contactDetailsUid].loading = true
      if (value.length === 0) {
        duplicate[contactDetailsUid].loading = false
        duplicate[contactDetailsUid].duplicate = false
        duplicate[contactDetailsUid].search = value
        this.setState({ duplicate: { ...duplicate } })
        return
      }

      this.setState({ duplicate: { ...duplicate } })
      try {
        const duplicateResult = await this.props.client.query({
          query: qlFindDuplicateAccount,
          variables: {
            account_uid: accountUid,
            field,
            value,
            checkUid: uuidv4()
          },
          fetchPolicy: 'no-cache'
        })
        duplicate[contactDetailsUid].search = value
        if (duplicateResult.data.find_duplicate_with_details.duplicate) {
          duplicate[contactDetailsUid].duplicate = true
          duplicate[contactDetailsUid].error = this.getErrorMsg(field)
          duplicate[contactDetailsUid].duplicateData =
            duplicateResult.data.find_duplicate_with_details.accounts_duplicated
        } else {
          duplicate[contactDetailsUid].duplicate = false
          duplicate[contactDetailsUid].duplicateData = undefined
        }

        duplicate[contactDetailsUid].loading = false
        this.setState({ duplicate: { ...duplicate } })
      } catch (err) {
        console.log('handleCheckByUid error', err)
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onHandleCheck={this.handleCheck}
          duplicate={this.state.duplicate}
          onHandleCheckByUid={this.handleCheckByUid}
        />
      )
    }
  }

  return withApollo(AccountCheckDuplicateQLWrapper)
}

export default accountCheckDuplicateHoc
