import { DataSchema } from './schema-types'
import { DataSourceField } from '../types/component-type'

export enum QueryRootLevel {
  ROOT = 'ROOT',
  MASTER = 'MASTER',
  INSTANCE = 'INSTANCE'
}

export type QLQueryAggregateParams = {
  id: string
  type: string
}

export type CrmQLQueryBuilderParams = {
  root: string
  root_type: string
  root_level: QueryRootLevel
  use_edges?: boolean
  fields: Array<DataSourceField | string>
  aggregates?: Array<QLQueryAggregateParams>
  grouped?: boolean
  group_by?: Array<string>
}

export class CrmQLQueryBuilder {
  buildQueryTree = (qlGeneratorParams: CrmQLQueryBuilderParams, schema: DataSchema) => {
    var root: any = {
      selections: []
    }

    function parseField(root, fieldDefinition) {
      var res = { ...root }

      if (!res.selections) {
        res.selections = []
      }

      if (typeof fieldDefinition == 'string') {
        var parts = fieldDefinition.split('.')

        if (parts.length > 1) {
          var subFieldIdx = res.selections.findIndex(s => s.field == parts[0])

          if (subFieldIdx == -1) {
            res.selections.push({ field: parts[0], selections: [] })
            subFieldIdx = res.selections.length - 1
          }

          var remainingParts = parts.slice(1, parts.length)
          var updatedSubField = parseField(res.selections[subFieldIdx], remainingParts.join('.'))
          res.selections[subFieldIdx] = updatedSubField
        } else {
          res.selections.push({
            field: fieldDefinition
          })
        }
      } else {
        throw new Error('Object format for query_field is not supported')
      }

      return res
    }

    for (var i = 0; i < qlGeneratorParams.fields.length; i++) {
      root = parseField(root, qlGeneratorParams.fields[i])
    }

    root.queryRoot = this.getQueryRootForType(qlGeneratorParams.root, schema)

    return root
  }

  buildVariables = (qlGeneratorParams: CrmQLQueryBuilderParams) => {
    var variables: any = {}

    if (qlGeneratorParams.aggregates) {
      variables.aggregate = qlGeneratorParams.aggregates
    }

    if (qlGeneratorParams.group_by) {
      variables.groupBy = qlGeneratorParams.group_by
    }

    return variables
  }

  getQueryRootForType = (type, schema) => {
    if (['Order', 'Fitment'].includes(type)) {
      return 'orders'
    }

    if (type === 'Opportunity') {
      return 'opportunities'
    }

    return type
  }

  buildGraphQLFromTree = (queryTree, qlGeneratorParams: CrmQLQueryBuilderParams, schema: DataSchema) => {
    const queryRoot = this.getQueryRootForType(qlGeneratorParams.root, schema)
    var queryArguments = []

    queryArguments.push({ name: 'sort', type: '[JSON]' })
    queryArguments.push({ name: 'offset', type: 'Int' })
    queryArguments.push({ name: 'limit', type: 'Int' })
    queryArguments.push({ name: 'filter', type: 'JSON' })
    queryArguments.push({ name: 'aggregate', type: '[JSON]' })
    queryArguments.push({ name: 'groupBy', type: '[String]' })

    const buildQueryForLevel = level => {
      if (level && level.selections) {
        const fields = level.selections.reduce(
          (a, field) => `${a} ${field.field} ${field.selections ? buildQueryForLevel(field) : ''}`,
          ''
        )
        return ` { ${fields} } `
      }

      return ''
    }

    const fieldsString = buildQueryForLevel(queryTree)

    let innerQuery = `  ${queryRoot} (limit: $limit, filter: $filter, sort: $sort, offset: $offset, aggregate: $aggregate, groupBy: $groupBy) {
    edges {
       edge ${fieldsString}
     }
     pageInfo {
       count
     }
     }`

    if (true) {
      innerQuery = `master(master_uid: $master_uid) { 
      instance(instance_uid: $instance_uid ) {
        ${innerQuery}
      }
    }`

      queryArguments.push({
        name: 'master_uid',
        type: 'String',
        required: true
      })

      queryArguments.push({
        name: 'instance_uid',
        type: 'String',
        required: true
      })
    }

    var queryArgumentsString = queryArguments.reduce(
      (a, i) => `${a.length > 0 ? `${a}, ` : a}$${i.name}:${i.type}${i.required ? '!' : ''}`,
      ''
    )

    let queryString = `query ${queryRoot}(${queryArgumentsString}) {
        ${innerQuery}
    } `

    return queryString
  }

  _addAccountUid = qlGeneratorParams => {
    if (
      qlGeneratorParams.root == 'orders' ||
      qlGeneratorParams.root == 'accounts' ||
      qlGeneratorParams.root == 'opportunities'
    ) {
      qlGeneratorParams.fields.push('account_uid')
    }
  }

  buildQlQuery = (qlGeneratorParams: CrmQLQueryBuilderParams, runtimeVariables: any, schema?: DataSchema) => {
    this._addAccountUid(qlGeneratorParams)

    const queryTree = this.buildQueryTree(qlGeneratorParams, schema)
    let gqlString = this.buildGraphQLFromTree(queryTree, qlGeneratorParams, schema)
    const queryRoot = this.getQueryRootForType(qlGeneratorParams.root, schema)
    var variables = {
      ...runtimeVariables,
      ...this.buildVariables(qlGeneratorParams)
    }

    return { queryTree, gqlString, variables, queryRoot }
  }
}
