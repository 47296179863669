import React, { SFC } from 'react'
import styled from 'styled-components'

const ButtonGroupContainer = styled.div`
${(p) =>
  p.small &&
  `
  button {
    font-size: 11px;
    min-width: 28px;
    height: 28px;
    padding: 0px;
  }
`}
button {
  display: inline-block;
}
  & > *:not(:first-child):not(:last-child) {
    ${(p) =>
      p.length > 1 &&
      `
       border-radius: 0px;
       border-left: none;
    `}
  }
  ${(p) =>
    p.length === 2 &&
    `
  & > *:first-child {
    button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  `}
  ${(p) =>
    p.length > 2 &&
    `
  & > *:first-child {
    button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  `}

  ${(p) =>
    p.length > 1 &&
    `
  & > *:last-child {
    button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: none;
    }
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }
  `}

`
interface ButtonGroupProps {
  small?: boolean
  children: React.ReactNode[] | React.ReactNode
}
const ButtonGroup: SFC<ButtonGroupProps> = (props) => {
  return (
    <ButtonGroupContainer
      small={props.small}
      length={React.Children.count(props.children)}
    >
      {props.children}
    </ButtonGroupContainer>
  )
}

export default ButtonGroup
