/* eslint-disable camelcase */
import cloneDeep from 'clone-deep'

export const COLORS = ['#65b7f3', '#b2d28c', '#f47735', '#f67960', '#82c6da']
export const mock = 1

// Maps local + global filters
export const mapFilters = (stateFilters, analyticsContext) => {
  console.log('mapFilters stateFilters')
  console.dir(stateFilters)
  const retFilters = []
  if (stateFilters && stateFilters.forEach) {
    stateFilters.forEach(f => {
      const filterItem = cloneDeep(f)
      if (filterItem.context) {
        if (!filterItem.context_filter_code) {
          throw new Error('context_filter_code missing for mapped filter')
        }

        if (analyticsContext.filters[filterItem.context_filter_code]) {
          console.log('Mapping value from ')
          console.dir(analyticsContext.filters[filterItem.context_filter_code])
          filterItem.value =
            analyticsContext.filters[filterItem.context_filter_code].value
        } else {
          // There is no context value - check if required .. TODO
        }
      }
      if (filterItem.value || filterItem.expression) {
        retFilters.push(filterItem)
      }
    })
  }
  // Map context filters
  if (analyticsContext && analyticsContext.filters) {
    Object.keys(analyticsContext.filters).forEach(k => {
      const f = analyticsContext.filters[k]
      if (f) {
        retFilters.push({
          dim_code: k,
          value: cloneDeep(f.value),
          operator: f.operator
        })
      }
    })
  }
  console.dir(retFilters)
  return retFilters
}
