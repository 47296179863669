import profilePermissionsAPI from 'crm-api/settings/profile-permissions-api'
import {
  RECEIVE_PROFILE_PERMISSION,
  FETCH_PROFILE_PERMISSION,
  SAVE_EDITING_PROFILE_PERMISSION,
  CANCEL_EDITING_PROFILE_PERMISSION,
  CANCEL_CREATING_PROFILE_PERMISSION,
  SAVE_CREATING_PROFILE_PERMISSION,
  UNSELECTED_PROFILE_PERMISSION
} from 'crm-duxs/settings/profile-permissions-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchProfilePermissions() {
  const profilePermissions = yield call(
    profilePermissionsAPI.fetchProfilePermissions
  )
  yield put({ type: RECEIVE_PROFILE_PERMISSION, payload: profilePermissions })
}

function* updateProfilePermission(action) {
  const ProfilePermission = action.formValues
  try {
    yield call(profilePermissionsAPI.updateProfilePermission, {
      item: ProfilePermission
    })
    yield put({ type: CANCEL_EDITING_PROFILE_PERMISSION })
    yield put({ type: UNSELECTED_PROFILE_PERMISSION })
    yield put({ type: FETCH_PROFILE_PERMISSION })
  } catch (err) {}
}

function* createProfilePermission(action) {
  const ProfilePermission = action.formValues
  try {
    yield call(profilePermissionsAPI.createProfilePermission, {
      item: ProfilePermission
    })
    yield put({ type: CANCEL_CREATING_PROFILE_PERMISSION })
    yield put({ type: FETCH_PROFILE_PERMISSION })
  } catch (err) {}
}

function* settingProfilePermissionsSaga() {
  yield takeLatest(FETCH_PROFILE_PERMISSION, fetchProfilePermissions)
  yield takeLatest(SAVE_EDITING_PROFILE_PERMISSION, updateProfilePermission)
  yield takeLatest(SAVE_CREATING_PROFILE_PERMISSION, createProfilePermission)
}

export default settingProfilePermissionsSaga
