import React from 'react'
import { connect } from 'react-redux'
import enhanceWithClickOutside from 'react-click-outside'
import { injectIntl } from 'react-intl'

interface ICrmDropdown {
  title: any
  options: any
  activeOption: any | string
  asNumber?: boolean
  input?: {
    onChange: Function
    value: string
  }
  onChange?: Function
  currentValue?: any
  disabled: boolean
  intl: any
  valueField: string
  small: boolean
  style?: any
  extraClassNames?: {
    containerClassNames?: string
    titleClassNames?: string
    selectClassNames?: string
  }
  hasErrors?: boolean
  addEmptyOption?: boolean
  emptyOptionLabel?: string
  disableOptionsIntl?: boolean
  placeholder?: any
  labelField?: string
  toolboxRenderer?: any
}
class CrmDropdown extends React.PureComponent<ICrmDropdown> {
  state: any
  dropdownInput: any

  public static defaultProps = {
    onChange() {},
    activeOption: null,
    extraClassNames: {
      containerClassNames: '',
      titleClassNames: '',
      selectClassNames: ''
    },
    input: {},
    currentValue: {},
    disabled: false,
    title: '',
    labelField: undefined,
    valueField: undefined,
    placeholder: undefined,
    disableOptionsIntl: false,
    small: false,
    style: undefined,
    addEmptyOption: false,
    emptyOptionLabel: 'Select...',
    hasErrors: false,
    label: undefined,
    asNumber: false,
    toolboxRenderer: undefined
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isObjectsMode: this.calculateIsObjectsMode(props) // Are options objects
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  handleClickOutside = () => {
    this.setState({ ...this.state, isOpen: false })
  }

  handleOpenClick = () => {
    this.setState({ ...this.state, isOpen: !this.state.isOpen })
  }

  handleOptionClick = event => {
    let value = event.currentTarget.id
    if (this.props.asNumber) {
      value = Number(value)
    }
    // Be sure to update state even if handler fails
    this.setState({ isOpen: false }, () => {
      const item = this.props.options && value ? this.props.options.find(i => i.value === value) : undefined
      if (this.props.input.onChange) {
        this.props.input.onChange(value, this.props.title, item)
      } else {
        this.props.onChange(value, this.props.title, item)
      }
    })
  }
  calculateIsObjectsMode = props => {
    let isOptionsMode = false
    if (props && props.options) {
      if (props.options.length > 0) {
        isOptionsMode = typeof props.options[0] !== 'string'
      }
    }
    return isOptionsMode
  }

  componentWillReceiveProps(nextProps) {
    const isOptionsMode = this.calculateIsObjectsMode(nextProps)
    if (isOptionsMode !== this.state.isOptionsMode) {
      this.setState({
        ...this.state,
        isOptionsMode
      })
    }
  }
  updateWindowDimensions = () => {
    this.setState({
      ...this.state,
      width: window.innerWidth,
      height: window.innerHeight
    })
    this.recalculateOpenToTop()
  }

  setRef = input => {
    this.dropdownInput = input
    this.recalculateOpenToTop()
  }

  recalculateOpenToTop = () => {
    if (this.dropdownInput) {
      const rect = this.dropdownInput.getBoundingClientRect()
      const openToTop = rect && rect.top * 2 > window.innerHeight
      if (this.state.openToTop !== openToTop) {
        this.setState({ ...this.state, openToTop })
      }
    }
  }

  render() {
    const { disabled, intl, input, small, style } = this.props
    const extraClassNames = this.props.extraClassNames ? { ...this.props.extraClassNames } : {}
    extraClassNames.containerClassNames += `  ${this.props.hasErrors ? 'CrmDropdown--hasErrors' : ''}`

    const options = this.props.options ? [...this.props.options] : []
    if (this.props.addEmptyOption) {
      options.unshift({ name: this.props.emptyOptionLabel, value: '' })
    }
    if (this.props.currentValue) {
      const isExistOption = options.find(option => option.value === this.props.currentValue.value)
      if (typeof isExistOption === 'undefined' && this.props.currentValue && this.props.currentValue.value) {
        options.unshift(this.props.currentValue)
      }
    }

    let disableOptionsIntl = this.props.disableOptionsIntl
    let title = this.props.title ? this.props.title : this.props.placeholder
    let activeOption = input.value || this.props.activeOption
    // If label field is passed then use it to convert id to item label
    const labelField = this.props.labelField || 'name'
    if (options && options.length > 0 && typeof options[0] !== 'string') {
      if (activeOption && (typeof activeOption === 'string' || typeof activeOption === 'number') && options) {
        activeOption =
          // eslint-disable-next-line
          options.find(o => o.value == activeOption) || activeOption // - Intentionaly match only value
      }
    }
    let activeOptionLabel
    if (activeOption) {
      if (typeof activeOption === 'string') {
        activeOptionLabel = activeOption
      } else {
        activeOptionLabel = activeOption[labelField]
      }
    }

    if (typeof title === 'undefined') {
      title = ''
      disableOptionsIntl = true
    }

    return (
      <div className="util-relative util-fullExpand">
        <div
          ref={input => {
            this.setRef(input)
          }}
          className={`${extraClassNames.containerClassNames} Dropdown ${small ? 'Dropdown--small' : ''}
          ${this.state.isOpen ? 'is-active' : ''} CrmDropdown ${this.state.openToTop ? 'CrmDropdown--openToTop' : ''}`}
          onClick={!disabled && this.handleOpenClick}
          style={style}
        >
          {title && <div className={`${extraClassNames.titleClassNames} Dropdown-title`}>{title}</div>}
          <div className="CrmDropdown-value">
            {activeOptionLabel && !disableOptionsIntl
              ? intl.formatMessage({
                  id: `crm.dropdown.option.${activeOptionLabel}`,
                  defaultMessage: activeOptionLabel
                })
              : activeOptionLabel}
          </div>
          <i className="fa fa-caret-down Dropdown-toggle CrmDropdown-toggle" aria-hidden="true" />
          {this.state.isOpen && (
            <div
              className={`${extraClassNames.selectClassNames} Dropdown-select ${
                title ? 'With-title' : 'Without-title'
              }`}
            >
              {options.map(opt =>
                typeof opt === 'string' ? (
                  <div key={opt} id={opt} className="Dropdown-option" onClick={this.handleOptionClick}>
                    {intl.formatMessage({
                      id: `dropdown.option.${opt}`,
                      defaultMessage: opt
                    })}
                  </div>
                ) : (
                  <div
                    key={opt.value}
                    id={opt.value}
                    className={`Dropdown-option ${
                      opt.is_deleted === true || opt.is_active === false || opt.isDeleted === true ? ' is-deleted' : ''
                    }
                    `}
                    onClick={this.handleOptionClick}
                  >
                    {opt.element || opt.name}
                  </div>
                )
              )}
            </div>
          )}
        </div>
        {this.props.toolboxRenderer && this.props.toolboxRenderer()}
      </div>
    )
  }
}

function mapStateToProps(state, { options }) {
  const isFunc = typeof options === 'function'

  return {
    options: isFunc ? options(state) : options
  }
}
const DropdownEnchanced = enhanceWithClickOutside(CrmDropdown)
const injectedDropdown: any = injectIntl(DropdownEnchanced)
export const ConnectedCrmDropdown = connect(mapStateToProps)(injectedDropdown)
export default injectedDropdown
