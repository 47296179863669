/* eslint-disable camelcase */
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { func, shape, bool, string } from 'prop-types'
import { initialize, getFormValues } from 'redux-form'
import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import { injectIntl } from 'react-intl'
import ActivityFormInner from './activity-form-inner.jsx'
import activityPageQlHoc from './activity-form-ql-hoc'
import { getUser } from 'crm-duxs/crm-reducer'

import { LoadingIcon } from 'util-components'

import {
  getActivity,
  getIsSaving,
  getActivityType,
  getAddressIsSaving,
  actions as activityActions
} from 'crm-duxs/activity-reducer'

class ActivityForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity ? this.props.activity : undefined
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState({ ...this.state, ...newState }, () => {
        resolve()
      })
    })

  handleFormSubmit = async activity => {
    console.log('ActivityForm handleFormSubmit')
    console.dir(activity)

    if (
      activity.disposition_type_uid === 'ee009f61-a010-41a1-afe3-ee6a2cfb1d84'
    ) {
      const saleItems = activity.disposition_details.saleItems
      const invalidSaleItems = saleItems.filter(
        v => !(!isNaN(Number(v.quantity)) && Number(v.quantity))
      )
      if (invalidSaleItems.length > 0) {
        toastr.error('Quantity is required')
        return
      }
    }

    try {
      const res = await this.props.onSubmit(activity)
      console.log('Activity saved', res)
      toastr.success('Activity saved')
      this.props.onCancel()
    } catch (err) {
      console.error('Problem saving activity: ' + err)
      toastr.error('Problem saving activity: ' + err.message)
    }
  }

  handleGotoMinutes = () => {
    // console.log('handleGotoMinutes')
    this.props.onCancel()
    this.props.doChangePage(
      '/crm/account/' +
        this.props.activity.account_uid +
        '/activity/' +
        this.props.activity.activity_uid +
        '/meeting-minutes'
    )
  }

  handleAddNewAddress = values => {
    // console.log('handleAddNewAddress', values)
    const addr = { ...values.address }
    addr.account_uid = this.props.account.account_uid
    this.props.doAddNewAddress(addr)
  }

  _getFormKey() {
    let id = ''
    if (this.props.activity && this.props.activity.activity_uid) {
      id = this.props.activity.activity_uid
    } else if (this.props.activity && this.props.activity.tmpId) {
      id = this.props.activity.tmpId
    }
    return 'activity-' + id
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.activityQl !== this.props.activityQl &&
      nextProps.activityQl.activity
    ) {
      this.setState({ ...this.state, activity: nextProps.activityQl.activity })
    }
  }

  handleFormChange = event => {
    // console.log('handleFormChange', event, this.state.activity)
    // Iif (
    //   event &&
    //   event.activity_type_uid !== this.state.activity.activity_type_uid
    // ) {
    //   // Update activity type uid
    //   console.log('Update activity type uid')
    //   this.setState({
    //     activity: {
    //       ...this.state.activity,
    //       activity_type_uid: event.activity_type_uid
    //     }
    //   })
    // }
    // Tthis.props.doActivityFormChange(event)
  }

  handleChanged = () => {
    // console.log('ActivityForm - handleChanged')
    // console.dir(this.props)
    if (this.props.activityQl) {
      this.props.activityQl.refetch()
    }
  }

  render() {
    const { isSaving } = this.props
    const formKey = this._getFormKey()
    console.log('render activity form')
    // console.dir(this.props)
    // console.log(this.state)
    const isLoading = this.props.activityQl
      ? this.props.activityQl.loading
      : false
    const activity = this.state.activity
    const activityType = activity ? activity.activity_type : undefined
    // console.log(activity, activityType)
    const availableStatuses = this.props.getAvailableStatuses(activity)

    return (
      <div className="util-marginTop util-marginBottom">
        {isLoading && <LoadingIcon />}
        {!isLoading && activity && activityType && (
          <ActivityFormInner
            account={this.props.account}
            onChange={this.handleFormChange}
            form={formKey}
            activity={activity}
            initialValues={activity}
            activityType={activityType}
            availableStatuses={availableStatuses}
            isSaving={isSaving}
            addressSaving={this.props.addressSaving}
            onSubmit={this.handleFormSubmit}
            dispatch={this.props.dispatch}
            formValues={this.props.formValues}
            onCancel={this.props.onCancel}
            currentUser={this.props.currentUser}
            onAddNewAddress={this.handleAddNewAddress}
            onGotoMinutes={this.handleGotoMinutes}
            onChanged={this.handleChanged}
            sendEmailFromQuotation={this.props.sendEmailFromQuotation}
          />
        )}
      </div>
    )
  }
}

ActivityForm.propTypes = {
  account: shape({}).isRequired,
  activity: shape({}).isRequired,
  isSaving: bool.isRequired,
  addressSaving: bool.isRequired,
  formValues: shape({}),
  onCancel: func,
  currentUser: shape({}).isRequired,
  doAddNewAddress: func.isRequired,
  doChangePage: func.isRequired,
  dispatch: func.isRequired,
  activityQl: shape(),
  onSubmit: func.isRequired,
  getAvailableStatuses: func.isRequired,
  sendEmailFromQuotation: shape({})
}

ActivityForm.defaultProps = {
  onCancel: () => {},
  keepOpen: false,
  activityQl: undefined,
  formValues: undefined,
  sendEmailFromQuotation: undefined
}

function mapStateToProps(state, ownProps) {
  const activity = ownProps.activity ? ownProps.activity : getActivity(state)
  const formKey = activity
    ? 'activity-' +
      (activity.activity_uid ? activity.activity_uid : activity.tmpId)
    : undefined
  return {
    activity,
    activityType: getActivityType(state),
    isSaving: getIsSaving(state),
    addressSaving: getAddressIsSaving(state),
    formValues: formKey ? getFormValues(formKey)(state) : undefined,
    currentUser: getUser(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doInitializeForm: (form, value) => dispatch(initialize(form, value)),
    doActivityFormChange: event =>
      dispatch(activityActions.activityFormChange.create(event)),
    doAddNewAddress: address =>
      dispatch(activityActions.addNewAddress.create(address)),
    doChangePage: path => dispatch(push(path)),
    dispatch
  }
}

/* Inject QL */

const ActivityFormIntl = injectIntl(activityPageQlHoc(ActivityForm))
export default connect(mapStateToProps, mapDispatchToProps)(ActivityFormIntl)
