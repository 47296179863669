import moment from 'moment'
import apiCaller from './api-utils'
import jwtDecode from 'jwt-decode'

const AUTOLOGOUT_TIMEOUT = 45 // as minutes
const RENEW_TOKEN_BEFORE_EXPIRED = 180 // as seconds
const TOKEN_MONITORING_INTERVAL = 1000 * 60

export const getMasterUidFromToken = token => {
  if (!token) {
    const data = jwtDecode(token)
    const masterUid = data ? data.master_uid : undefined
    return masterUid
  }
}
class Refresher {
  isRefreshing = false
  promises = []

  fetchToken() {
    const refreshToken = window.localStorage.getItem('crmRefreshToken')
    if (!refreshToken) return
    return apiCaller('crm_refresh_token', {
      refresh_token: refreshToken
    }).then(async response => {
      const { result, error } = response
      if (error) {
        if (error === 'refresh_token_revoke') {
          logout('Refresh Token Expired')
        }
      } else {
        window.localStorage.setItem('crmAccessToken', result.access_token)
        return {
          token: result.access_token
        }
      }
      return {}
    })
  }

  resolvePromses(result) {
    for (let i = 0; i < this.promises.length; i++) {
      this.promises[i].resolve(result)
    }
    this.promises = []
  }
  rejectPromises(result) {
    for (let i = 0; i < this.promises.length; i++) {
      this.promises[i].reject(result)
    }
    this.promises = []
  }

  async doRefresh() {
    return new Promise(async (resolve, reject) => {
      this.isRefreshing = true
      try {
        await this.fetchToken()
        await this.resolvePromses()
      } catch (err) {
        await this.rejectPromises()
      }

      this.isRefreshing = false
      resolve()
    })
  }

  requestRefresh() {
    const promise = new Promise(async (resolve, reject) => {
      this.promises.push({ promise, resolve, reject })
      if (!this.isRefreshing) {
        await this.doRefresh()
      }
    })

    return promise
  }

  async refreshIfExpired() {
    if (isExpired('crmAccessToken')) {
      await this.requestRefresh()
    }
  }

  startInterval() {
    if (this.refreshAccessTokenInterval) {
      clearInterval(this.refreshAccessTokenInterval)
    }
    this.refreshAccessTokenInterval = setInterval(async () => {
      await this.refreshIfExpired()
    }, TOKEN_MONITORING_INTERVAL)
  }
}

const tokenRefresher = new Refresher()
tokenRefresher.startInterval()

export const getDateInfoFromToken = token => {
  const data = jwtDecode(token)
  const iat = data.iat
  const exp = data.exp
  const dates = {
    iat,
    exp,
    isExpired: moment().unix() + RENEW_TOKEN_BEFORE_EXPIRED > exp
  }
  return dates
}

const isExpired = key => {
  const value = window.localStorage.getItem(key)
  if (!value) {
    return true
  }
  return getDateInfoFromToken(value).isExpired
}

const isRefreshTokenExpired = () => {
  if (!window.localStorage.getItem('crmAccessToken')) {
    logout('')
    return
  }
  if (isExpired('crmRefreshToken')) {
    logout('Refresh Token Expired')
  }
}

export const turnoffAutomaticSilentRefresh = () => {
  if (window.refreshAccessTokenInterval) {
    clearInterval(window.refreshAccessTokenInterval)
  }
}

export const rootSilentRefreshToken = async () => {
  const isTokenExpired = isExpired('crmAccessToken')
  isRefreshTokenExpired()
  autoLogoutInterval()
  if (isTokenExpired) {
    await renewAccessToken()
  }
}
export const checkAccessTokenExists = () => {
  if (!window.localStorage.getItem('crmAccessToken')) {
    const pathName = window.location.pathname
    const searchName = window.location.search
    logout('crmAccessToken not found')
    window.location.reload()
    window.location.pathname = pathName
    window.location.search = searchName
  }
}
export const silentRefreshToken = async () => {
  const isTokenExpired = isExpired('crmAccessToken')
  isRefreshTokenExpired()
  if (isTokenExpired) {
    await renewAccessToken()
  }
}

export const logout = reason => {
  window.crmLogout(reason)
}

export const renewAccessToken = async () => {
  const result = await tokenRefresher.requestRefresh()
  return result
}

const autoLogoutInterval = () => {
  if (!window.networkActivityInterval) {
    checkInactive()
    window.networkActivityInterval = setInterval(checkInactive, 60000)
  }
}

const checkInactive = () => {
  if (!window.localStorage.getItem('lastNetworkActivityTime')) return
  const now = moment()
  const lastActivity = moment(
    parseInt(window.localStorage.getItem('lastNetworkActivityTime'))
  )
  const duration = moment.duration(now.diff(lastActivity))
  const minutes = duration.asMinutes()

  if (minutes > AUTOLOGOUT_TIMEOUT) {
    logout('Inactivity Logout')
  }
}
