import React from 'react'
import { useCrmDynamicTableContext } from '../dynamic-table-context'
import { ChartTypes } from '../dynamic-table-consts'
import { CrmDropdown } from '@cartrack-crm/ui'

const chartTypeOptions = [
  { name: ChartTypes.LINEAR, value: ChartTypes.LINEAR },
  { name: ChartTypes.PIE, value: ChartTypes.PIE }
]
const ViewOptionsChart = () => {
  const dynamicTableContext: any = useCrmDynamicTableContext()

  return (
    <div className="util-flexRow util-flexCenterItems">
      <CrmDropdown
        style={{ minWidth: 150 }}
        options={chartTypeOptions}
        input={{
          value: dynamicTableContext.chartType,
          onChange: dynamicTableContext.setChartType
        }}
      />
    </div>
  )
}

export default ViewOptionsChart
