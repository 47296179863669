import React from 'react'
import { func, arrayOf, shape, string, number, bool } from 'prop-types'
import { compose } from 'react-apollo'
import { CrmFormInputDropdown } from 'crm-components'

class GenericSelectorInner extends React.Component {
  state = {
    filter: undefined
  }

  fetchMore = false

  observerSelectedChange = value => {
    if (typeof this.props.onChange === 'function') {
      const item = this.state.options
        ? this.state.options.find(o => o.value === value)
        : undefined
      this.props.onChange(value, item)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.filter) !== JSON.stringify(this.props.filter)
    ) {
      this.props.optionsQl.refetch({
        variables: {
          offset:
            this.props.options && this.props.options.length
              ? this.props.options.length
              : 0,
          limit: this.props.limit,
          filter: nextProps.filter
        },
        fetchPolicy: 'no-cache'
      })
    }
  }

  handleInputChange = value => {
    let textFilter = ''

    if (value === this.state.filter) return
    if (value) textFilter = value.toLowerCase()
    if (this.fetchMore) return
    if (
      this.props.optionsQl &&
      this.props.filterFieldOnApi &&
      !this.props.optionsQl.loading
    ) {
      const filter = {}
      filter[this.props.filterFieldOnApi] = { like: textFilter }
      this.setState({ filter: textFilter })
      this.props.optionsQl.refetch({
        filter: { ...this.props.filter, ...filter }
      })
    }
  }

  handleFetchMore = () => {
    if (!this.props.prefix || this.props.optionsQl.loading || this.fetchMore)
      return

    const filter = { ...this.props.filter }

    if (this.state.filter && this.state.filter !== '')
      filter[this.props.filterFieldOnApi] = { like: this.state.filter }

    this.fetchMore = true
    this.props.optionsQl.fetchMore({
      variables: {
        offset: this.props.options.length,
        limit: this.props.limit,
        filter
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        this.fetchMore = false

        if (!fetchMoreResult) return prev

        const newResult = {}

        if (typeof prev === 'object') {
          newResult[this.props.prefix] = {
            ...fetchMoreResult[this.props.prefix],
            data: [
              ...prev[this.props.prefix].data,
              ...fetchMoreResult[this.props.prefix].data
            ]
          }
        } else {
          newResult[this.props.prefix] = {
            ...fetchMoreResult[this.props.prefix],
            data: [...fetchMoreResult[this.props.prefix].data]
          }
        }

        return Object.assign({}, prev, { ...newResult })
      }
    })
  }

  render() {
    const showfetch =
      this.props.prefix &&
      this.props.count &&
      Array.isArray(this.props.options) &&
      this.props.options.length > 0
        ? this.props.count > this.props.options.length &&
          this.props.enableFetchMore
        : false

    return (
      <CrmFormInputDropdown
        {...this.props}
        options={this.props.options}
        observerSelectedChange={this.observerSelectedChange}
        onInputChange={this.handleInputChange}
        onFetchMore={this.handleFetchMore}
        showFetchMore={showfetch}
        addEmptyOption
      />
    )
  }
}

GenericSelectorInner.propTypes = {
  onChange: func,
  optionsQl: shape({}),
  options: arrayOf(shape({})),
  filterFieldOnApi: string,
  prefix: string,
  limit: number,
  count: number,
  enableFetchMore: bool,
  filter: shape()
}
GenericSelectorInner.defaultProps = {
  options: undefined,
  filterFieldOnApi: undefined,
  optionsQl: {},
  onChange: undefined,
  prefix: undefined,
  limit: 10,
  count: 0,
  enableFetchMore: false,
  filter: undefined
}

const crmGenericSelectQLHocFactory = (qlQuery, options) => {
  const Inner = compose(qlQuery)(GenericSelectorInner)
  const defaultProps = options ? options.defaultProps : undefined
  return props => <Inner {...defaultProps} {...props} />
}

export default crmGenericSelectQLHocFactory
