import styled, { css } from 'styled-components'

export const DashboardFiltersOptionsList = styled.div`
  height: 170px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-bo;
  padding-right: 10px; 
`

export const DashboardFilterOptionsItem = styled.div`
  color: #666;
  padding: 5px;
  cursor: pointer;
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out;

  &:hover {
    background-color: #fafafe;
    color: #666;
  }

  ${({ isSelected }) => isSelected && css`
    background-color: #f47735;
    color: white;
    &:hover {
      background-color: #f47735dd;
      color: white;
    }
  `}

  ${({ isInActive }) => isInActive && css`
      color: #b02323;
  `}
`