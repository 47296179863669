import cloneDeep from 'clone-deep'
import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import SectionContactDetails from './section-contact-details.jsx'
import SectionContactPersons from './section-contact-persons.jsx'

class SectionContact extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      persons: props.account.persons ? cloneDeep(props.account.persons) : [],
      accountContactDetails: this.getAccountContactDetails(props.account)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account !== this.props.account) {
      this.setState({
        persons: cloneDeep(nextProps.account.persons),
        accountContactDetails: this.getAccountContactDetails(nextProps.account)
      })
    }
  }

  getAccountContactDetails = account => {
    if (account.contact_details) {
      if (account.persons) {
        const personsContactDetails = account.persons.reduce((ret, v) => {
          if (v.contact_details) {
            ret.push(...v.contact_details)
          }
          return ret
        }, [])

        const accountContactDetails = account.contact_details.reduce(
          (ret, v) => {
            const foundInPersonContact = personsContactDetails.find(
              p => p.contact_details_uid === v.contact_details_uid
            )
            if (!foundInPersonContact) {
              ret.push(v)
            }
            return ret
          },
          []
        )

        // return accountContactDetails
        let newAccountDetails = []
        let uniqueNames = []
        accountContactDetails.map(c => {
          if (uniqueNames.indexOf(c.name) === -1) {
            uniqueNames.push(c.name)
          }
        })

        uniqueNames.map(n => {
          const detail = {
            name: n,
            contactDetails: accountContactDetails.filter(c => c.name === n)
          }

          newAccountDetails.push(detail)
        })

        return newAccountDetails
      } else {
        return account.contact_details
      }
    }
  }

  render() {
    const { account } = this.props
    const { persons, accountContactDetails } = this.state

    return (
      <React.Fragment>
        <div>
          <SectionContactPersons account={account} persons={persons} />
        </div>
        <hr className="util-horizontalLine" />
        <div>
          <SectionContactDetails
            key="account-contact-details"
            account={account}
            accountContactDetails={accountContactDetails}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(SectionContact)
