import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'
import { CrmButton } from 'crm-components'
import { get } from 'lodash'

const ResourcesSelect = props => {
  if (!props.accountUid)
    return <div>Error ResourcesSelect: accountUid is empty</div>

  const variables = {}
  const qlq = gql`
    { resources(account_uid:"${props.accountUid}", activity_uid: "${props.activityUid}") { resource_uid name description size mime_type is_deleted account_uid activity_uid created_time file_link } }
  `
  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options = get(data, 'resources', []).map(v => ({
          ...v,
          value: v.resource_uid
        }))

        return (
          props.isExpanded && (
            <div>
              <GenericDropdown
                {...props}
                options={options}
                placeholder={
                  props.placeholder ? (
                    props.placeholder
                  ) : (
                    <FormattedMessage
                      id="crm.ui.dropdown.select_resource"
                      defaultMessage="Select Resource"
                    />
                  )
                }
              />
              <div className="util-marginBottom util-marginTop">
                <div className="util-flexRowRight">
                  <CrmButton
                    grouped
                    label={
                      <FormattedMessage
                        id="meeting.section.close"
                        defaultMessage="Close"
                      />
                    }
                    id="section_guests_close"
                    small
                    onClick={props.onClose}
                  />
                </div>
              </div>
            </div>
          )
        )
      }}
    </Query>
  )
}

ResourcesSelect.propTypes = {
  accountUid: PropTypes.string.isRequired,
  activityUid: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

ResourcesSelect.defaultProps = {
  activityUid: ''
}

export default ResourcesSelect
