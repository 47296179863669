import React, { SFC } from 'react'
import styled from 'styled-components'
import moment from 'moment'

interface ActivityCategoryProps {
  label: string
  sequence?: number
  createdTime: string
  isCustomer?: boolean
}

const ActivityCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ActivityCategoryHeader = styled.div`
  position: relative;
  label {
    color: #777777;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    margin-right: 4px;
  }
  span {
    position: absolute;
    color: #ce5239;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    top: -2px;
  }
`
const ActivityCategoryFooter = styled.div`
  .date {
    color: #666666;
    font-size: 10px;
  }
  .time {
    color: #666666;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
  }
  .account__type {
    font-size: 10px;
    color: #999999;
  }
`

const ActivityCategory: SFC<ActivityCategoryProps> = props => {
  return (
    <ActivityCategoryContainer>
      <ActivityCategoryHeader>
        <label>{props.label}</label>
        {props.sequence ? <span>{props.sequence}</span> : ''}
      </ActivityCategoryHeader>
      <ActivityCategoryFooter>
        <div className="util-flexRow">
          <div className="util-marginTop">
            <div className="date">
              {moment(props.createdTime).format('DD-MM-YYYY')}
            </div>
            <div className="time">
              {moment(props.createdTime).format('HH:mm')}
            </div>
          </div>
          <div className="util-flexGrow"></div>
          {props.isCustomer && (
            <div className="account__type">
              <i className="fas fa-trophy"></i>
            </div>
          )}
        </div>
      </ActivityCategoryFooter>
    </ActivityCategoryContainer>
  )
}

export default ActivityCategory
