// Actions for products
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const SELECTED_PRODUCT = 'SELECTED_PRODUCT'
export const UNSELECTED_PRODUCT = 'UNSELECTED_PRODUCT'
export const SAVE_EDITING_PRODUCT = 'SAVE_EDITING_PRODUCT'
export const SAVED_EDITING_PRODUCT = 'SAVED_EDITING_PRODUCT'
export const EDITING_PRODUCT = 'EDITING_PRODUCT'
export const CANCEL_EDITING_PRODUCT = 'CANCEL_EDITING_PRODUCT'
export const CREATING_PRODUCT = 'CREATING_PRODUCT'
export const CANCEL_CREATING_PRODUCT = 'CANCEL_CREATING_PRODUCT'
export const SAVING_PRODUCT = 'SAVING_PRODUCT'
export const SAVE_CREATING_PRODUCT = 'SAVE_CREATING_PRODUCT'
export const SAVING_PRODUCT_ERROR = 'SAVING_PRODUCT_ERROR'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const REMOVED_PRODUCT = 'REMOVED_PRODUCT'

// Reducer
const initialState = {
  lists: [],
  fetch: false,
  editing: false,
  creating: false,
  productSelected: undefined
}

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        fetch: false,
        lists: action.payload.products
      }
    case SELECTED_PRODUCT:
      return {
        ...state,
        productSelected: action.product,
        creating: false,
        editing: false
      }
    case UNSELECTED_PRODUCT:
      return {
        ...state,
        productSelected: undefined,
        editing: false
      }
    case EDITING_PRODUCT:
      return {
        ...state,
        editing: true,
        creating: false
      }
    case CANCEL_EDITING_PRODUCT:
      return {
        ...state,
        editing: false
      }
    case CREATING_PRODUCT:
      return {
        ...state,
        creating: true,
        editing: false,
        productSelected: undefined
      }
    case CANCEL_CREATING_PRODUCT:
      return {
        ...state,
        creating: false
      }
    case SAVING_PRODUCT:
      return {
        ...state,
        creating: true
      }
    // End reducer for products
    default:
      return state
  }
}
// Action disptach for products
export function fetchProducts() {
  return {
    type: FETCH_PRODUCTS
  }
}

export function selectedProduct(product) {
  return {
    type: SELECTED_PRODUCT,
    product
  }
}

export function unselectedProduct() {
  return {
    type: UNSELECTED_PRODUCT
  }
}

export function startEditingProduct() {
  return {
    type: EDITING_PRODUCT
  }
}

export function cancelEditingProduct() {
  return {
    type: CANCEL_EDITING_PRODUCT
  }
}

export function startCreatingProduct() {
  return {
    type: CREATING_PRODUCT
  }
}

export function cancelCreatingProduct() {
  return {
    type: CANCEL_CREATING_PRODUCT
  }
}

export function saveCreatingProduct(formValues) {
  return {
    type: SAVE_CREATING_PRODUCT,
    formValues
  }
}

export function saveEditingProduct(formValues) {
  return {
    type: SAVE_EDITING_PRODUCT,
    formValues
  }
}

export function removeProduct(productUid) {
  // console.log('removeProduct : ', productUid)
  return {
    type: REMOVE_PRODUCT,
    productUid
  }
}
