import React from 'react'
import { shape } from 'prop-types'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    console.log('App error componentDidCatch : ', error, errorInfo)
  }

  render() {
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: shape({}).isRequired
}
export default ErrorBoundary
