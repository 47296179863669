import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import settingsAPI from 'crm-api/settings/products-api'
import {
  RECEIVE_PRODUCTS,
  FETCH_PRODUCTS,
  SAVE_EDITING_PRODUCT,
  REMOVE_PRODUCT
} from 'crm-duxs/settings/products-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchProducts() {
  const products = yield call(settingsAPI.fetch)
  yield call(console.log, 'RECEIVE_PRODUCTS', products)
  yield put({ type: RECEIVE_PRODUCTS, payload: { products } })
}

function* updateProduct(action) {
  const productUpdate = action.formValues
  try {
    yield call(settingsAPI.update, productUpdate)
    yield put(toastr.success('Update product success'))
    yield put({ type: FETCH_PRODUCTS })
    yield put(push('/crm/settings/products/'))
  } catch (err) {}
}

function* removeProduct(action) {
  const productUpdate = { product_uid: action.productUid, is_deleted: true }
  try {
    yield call(settingsAPI.update, productUpdate)
    yield put(toastr.success('Remove product success'))
    yield put({ type: FETCH_PRODUCTS })
  } catch (err) {}
}

function* settingProductSaga() {
  yield takeLatest(FETCH_PRODUCTS, fetchProducts)
  yield takeLatest(SAVE_EDITING_PRODUCT, updateProduct)
  yield takeLatest(REMOVE_PRODUCT, removeProduct)
}

export default settingProductSaga
