import React from 'react'
import { shape, func, bool } from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import ChangeProcessOwnerWidget from './_change-process-owner-widget.jsx'
import AvatarPreview from 'crm-components/utils/user-avatar.jsx'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangeAccountTransactionTypeWidget from './_change-account-transaction-type-widget.jsx'
import ChangeAccountTagWidget from './_change-account-tag-widget.tsx'
import PotentialWidget from './_potential-widget.jsx'
import AccountInlineEditable from '../../components/layout19/account-inline-editable.jsx'
import InfoNoAccountPermission from './info_no_account_permission.jsx'
import AccountAdditionalOwners from './account_additional_owners.jsx'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class AccountPageHeader19 extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isChangingTag: false
    }
  }

  handleEnableChangeOwner = () => {
    const canChangeOwner =
      this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.ACCOUNT_REASSIGN_MODIFY
      ) ||
      this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.ACCOUNT_REASSIGN_MANAGE
      )
    if (!canChangeOwner) {
      toastr.error("You don't have permission to change owner")
      return
    }
    if (
      this.props.account &&
      !this.props.account.currentSalesProcess &&
      this.props.account.sales_status_code !== 'customer'
    ) {
      return toastr.error(
        "Use 'Start Sales Pipeline to assign lead to user or group"
      )
    }

    if (this.props.process && this.props.process.completed_time) {
      return toastr.error(
        "This process is already completed - you can't change owner"
      )
    }
    if (
      this.props.process ||
      this.props.account.sales_status_code === 'customer'
    ) {
      this.setState({
        isChangingOwner: true,
        userOnly: this.props.account.sales_status_code === 'customer'
      })
    }
  }

  _renderChangeOwner = () => (
    <ChangeProcessOwnerWidget
      onCancel={this.handleCancelChangeOwner}
      process={this.props.process}
      account={this.props.account}
      accountUid={this.props.account.account_uid}
    />
  )
  _renderChangeTag = () => (
    <ChangeAccountTagWidget
      account={this.props.account}
      onCancel={this.handleChangeAccountTag}
    />
  )
  _renderAccountTagLabel = () => {
    const listCurrentTag = this.props.account.tags
      ? [...this.props.account.tags]
      : []
    const listCurrentTagName = listCurrentTag.map(el => el.name)
    const totalTags = listCurrentTag.length
    let tagLabel = 'No Tags'

    if (totalTags > 0) {
      tagLabel = listCurrentTagName.join(', ')
    }
    return tagLabel
  }
  _renderAccountTagBlock = () => {
    if (this.state.isChangingTag) {
      return this._renderChangeTag()
    } else {
      return (
        <div
          onClick={this.toggleShowAccountTag}
          className="col-md-12 util-flexRow util-hooverableContainer util-hooverOp util-marginBottom"
          style={{ alignItems: 'center', borderRadius: 6 }}
        >
          <div className="util-flexGrow">
            <div className="LabeledField" style={{ margin: 0 }}>
              <div className="LabeledField-value">
                {this._renderAccountTagLabel()}
              </div>
            </div>
          </div>
          <div className=" util-hooverableContent">
            <div>
              <FontAwesomeIcon icon="pencil-alt" />
            </div>
          </div>
        </div>
      )
    }
  }
  handleChangeAccountTag = () => {
    this.setState({
      isChangingTag: false
    })
  }
  toggleShowAccountTag = () => {
    this.setState({
      isChangingTag: !this.state.isChangingTag
    })
  }
  handleCancelChangeOwner = () => {
    this.setState({ isChangingOwner: false })
  }
  static getDerivedStateFromError(err) {
    console.log('getDerivedStateFromError', err)
    return <div> There was some problem creating this view </div>
  }

  _renderSalesOwner = () => {
    const { account } = this.props
    console.log('_renderSalesOwner', this.props)
    const currentOwner =
      account && account.owners && account.owners.sales
        ? account.owners.sales
        : undefined
    const currentOwnerType = currentOwner ? currentOwner.ownerType : undefined
    const currentOwnerName = currentOwner ? currentOwner.name : undefined
    if (this.state.isChangingOwner) {
      return this._renderChangeOwner()
    } else {
      return (
        <div
          title="Click to change owner"
          className="util-flexRow util-hooverableContainer util-hooverOp"
          style={{ alignItems: 'center', borderRadius: 6 }}
          onClick={
            this.props.hasAccountDataPermission
              ? this.handleEnableChangeOwner
              : null
          }
        >
          {this.props.process &&
            this.props.process.owner_user &&
            this.props.process.owner_user.photo_url && (
              <div style={{ paddingRight: 10 }}>
                <AvatarPreview
                  src={this.props.process.owner_user.photo_url}
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 42
                  }}
                />
              </div>
            )}
          <div className="util-flexGrow">
            <div className="LabeledField" style={{ margin: 0 }}>
              <div className="LabeledField-label">
                <FormattedMessage
                  id="crm.ui.widget.owner"
                  defaultMessage="Owner"
                />
              </div>
              <div className="LabeledField-value">
                {currentOwnerType ? (
                  <React.Fragment>
                    <FontAwesomeIcon
                      style={{ marginRight: 5 }}
                      icon={
                        this.props.account.owners.sales.ownerType === 'user'
                          ? 'user'
                          : 'users'
                      }
                    />
                    {currentOwnerName}
                  </React.Fragment>
                ) : (
                  <FormattedMessage
                    id="crm.account.empty_owner"
                    defaultMessage="empty"
                  />
                )}
              </div>
            </div>
          </div>
          <div className=" util-hooverableContent">
            <div>
              <FontAwesomeIcon icon="pencil-alt" />
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const { account } = this.props
    console.log('Render account header 19', account, this.props)
    const salesStatus = account.sales_status

    return (
      <div className="util-marginBottom">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-7">{this._renderSalesOwner()}</div>
              <div className="col-md-5">
                <AccountAdditionalOwners
                  account={account}
                  hasAccountDataPermission={this.props.hasAccountDataPermission}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {this.props.hasAccountDataPermission === false && (
              <InfoNoAccountPermission />
            )}
            {this.props.hasAccountDataPermission &&
              this._renderAccountTagBlock()}
          </div>
        </div>
        {this.props.hasAccountDataPermission === false && (
          <div>
            <div className="AccountPage-header-title">{account.name}</div>
          </div>
        )}
        {this.props.hasAccountDataPermission === false && (
          <InfoNoAccountPermission />
        )}
        {this.props.hasAccountDataPermission && (
          <div className="row">
            <div className="col-md-1" style={{ margin: 'auto' }}>
              <div
                className="AccountLeftSide-addButton"
                title={this.props.intl.formatMessage({
                  id: 'crm.account.click_to_see_account_history',
                  defaultMessage: 'Click to see account history'
                })}
              >
                <FontAwesomeIcon
                  className="util-hooverable util-pointer"
                  icon="history"
                  onClick={this.props.onShowHistory}
                />
              </div>
            </div>
            <div className="col-md-7">
              <div
                className="util-flexRow"
                style={{
                  textTransform: 'capitalize'
                }}
              >
                <div
                  onClick={this.props.onShowHistory}
                  title={this.props.intl.formatMessage({
                    id: 'crm.account.click_to_see_account_history',
                    defaultMessage: 'Click to see account history'
                  })}
                >
                  {account && account.validated_date && (
                    <span
                      title={'at ' + account.validated_date}
                      style={{ color: '#7b937c' }}
                    >
                      <FormattedMessage
                        id="crm.account.validated"
                        defaultMessage="Validated"
                      />{' '}
                    </span>
                  )}
                  {salesStatus && (
                    <span title="from salesStatus" style={{ color: '#4b91c3' }}>
                      {salesStatus.code}
                      {salesStatus.qualificationTypeName && (
                        <span style={{ color: '#d0021b' }}>
                          {' ' + salesStatus.qualificationTypeName}
                        </span>
                      )}
                    </span>
                  )}
                  {/* )} */}
                </div>
                <div style={{ paddingLeft: 3 }}>{'/'}</div>
                <div style={{ paddingLeft: 3 }}>
                  <ChangeAccountTransactionTypeWidget
                    account={this.props.account}
                  />
                </div>
              </div>
              <div
                className="AccountPage-header-title"
                style={{ paddingLeft: 0 }}
              >
                <Link
                  to={`/crm/account/${account.account_uid}`}
                  className="util-defaultLink"
                >
                  <AccountInlineEditable
                    account={account}
                    fieldName="name"
                    label={account.name ? '' : 'Name'}
                    value={account.name}
                    editable={true}
                    readModeStyle="CrmLabeledField"
                    style={{ margin: '0px 20px 0px 0px' }}
                  />
                  <div style={{ fontSize: '12px' }}>
                    <AccountInlineEditable
                      account={account}
                      fieldName="name_local"
                      label={account.name_local ? '' : 'Name Local'}
                      value={account.name_local ? account.name_local : ''}
                      editable={true}
                      readModeStyle="CrmLabeledField"
                    />
                  </div>
                  {account.is_deleted && (
                    <div style={{ fontSize: '12px', color: 'red' }}>
                      <FormattedMessage
                        id="crm.ui.account.header.account_is_deleted"
                        defaultMessage="This account is deleted"
                      />
                    </div>
                  )}
                </Link>
              </div>
            </div>
            <div className="col-md-4 util-textRight">
              {/* {this.props.process &&
              this.props.process.process_type &&
              this.props.process.process_type.name} */}
              <PotentialWidget
                account={account}
                accountUid={account.account_uid}
                onClick={
                  this.props.canEditAccount
                    ? this.props.onClickEditMarketPotential
                    : undefined
                }
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-5 " />
          <div className="col-md-7 util-flexRow">
            {/* <AccountStatusBar
              account={account}
              className="util-flexGrow"
              isManager={this.props.isManager}
              noOwner
            /> */}
            {/* {
              <AccountTimelineChart
                account={this.props.account}
                accountUid={this.props.account.account_uid}
              />
            } */}
            <div style={{ flex: 1 }} />
          </div>
        </div>
      </div>
    )
  }
}

AccountPageHeader19.propTypes = {
  account: shape({}).isRequired,
  process: shape({}),
  onShowHistory: func.isRequired,
  hasAccountDataPermission: bool,
  intl: shape({}).isRequired,
  accountPermissionsContext: shape({}).isRequired
}
AccountPageHeader19.defaultProps = {
  hasAccountDataPermission: false,
  process: undefined
}
export default withAccountPermissionsContextHOC(injectIntl(AccountPageHeader19))
