/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import moment from 'moment'
import UserItem from './rms-user-item.jsx'
import { graphql, compose } from 'react-apollo'
import { qlqListcqCommandAudit } from 'crm-data/cq-command-audit'
import { loadData, availableSections } from './rms-dashboard-utils'
import { arrayOf, shape, func } from 'prop-types'
import { FormattedDate, FormattedTime } from 'react-intl'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import {
  CrmFullpageContainer,
  CrmDropdown,
  CrmButton,
  CrmCheckbox
} from 'crm-components'

const columns = []

const qlOptions = {
  options: ownProps => {
    let filterDate = {}
    if (ownProps.analyticsContext.filters.activity_date) {
      const activityDate = ownProps.analyticsContext.filters.activity_date
      const startDate = activityDate.value[0]
      const endDate = activityDate.value[1]

      filterDate = {
        created_time: {
          $gte: startDate,
          $lte: endDate
        }
      }
    }

    return {
      variables: {
        filter: Object.assign(filterDate, ownProps.analyticsContext.filters)
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.cqcommand_audit_list_qm_paged : []
  })
}

const Table = genericTableFactory({ query: qlqListcqCommandAudit, qlOptions })

const TableInner = props => {
  const columns = [
    {
      Header: 'Command UID',
      Cell: row => <div>{row.original.command_uid}</div>
    },
    {
      Header: 'Account',
      accessor: 'account_name',
      Cell: row => (
        <div>{row.original.account_name ? row.original.account_name : ''}</div>
      )
    },
    {
      Header: 'Username',
      accessor: 'user_name',
      Cell: row => (
        <div>{row.original.user_name ? row.original.user_name : ''}</div>
      )
    },
    {
      Header: 'Result',
      accessor: 'result',
      Cell: row => <div>{row.original.result ? row.original.result : ''}</div>
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: row => <div>{row.original.type ? row.original.type : ''}</div>
    },
    {
      Header: 'Aggregate Type',
      accessor: 'aggregate_type',
      Cell: row => (
        <div>
          {row.original.aggregate_type ? row.original.aggregate_type : ''}
        </div>
      )
    },
    {
      Header: 'Created time',
      accessor: 'created_time',
      Cell: row =>
        row.original.created_time ? (
          <div>
            <FormattedDate value={row.original.created_time} />{' '}
            <FormattedTime value={row.original.created_time} />
          </div>
        ) : (
          <span />
        )
    }
  ]

  console.log('cqcommand_audit_list_qm_paged', props)
  return <Table {...props} columns={columns} />
}

TableInner.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

TableInner.defaultProps = {
  dashboards: []
}

// // Dialer type
// const processTypeUid = '273c0b3f-23dd-4d05-ba56-cd34c21d0fd2'

// const RmsActivitiesHistoryDashboardQL = compose(
//   graphql(qlqListcqCommandAudit, {
//     options: () => ({
//       variables: {
//         process_type_uid: processTypeUid
//       }
//     }),
//     props: ({ qlqListcqCommandAudit, ownProps }) => ({
//       ...ownProps,
//       qlqListcqCommandAudit,
//       cqcommands: qlqListcqCommandAudit
//         ? qlqListcqCommandAudit.cqcommand_audit_list_qm_paged
//         : undefined
//     }),
//     name: 'qlqListcqCommandAudit'
//   }),
//   withApollo
// )(RmsActivitiesHistory)

// export default RmsActivitiesHistoryDashboardQL

export default TableInner
