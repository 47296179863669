import React, { SFC } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const NotationContainer = styled.div`
  z-index: 1;
  svg {
    font-size: 16px;
    color: #a870bd;
  }
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
`

const LiveCallDirection = styled.div`
  position: absolute;
  top: 0px;
  right: -2px;
  svg {
    color: #2dab33;
    font-size: 12px;
    transform: rotate(180deg);
  }
`

interface ActivityNotationProps {
  activity_type: {
    medium_code: string
  }
  direction?: string
}

const getIcon = (type): IconProp => {
  switch (type) {
    case 'phone_call':
      return 'headset'
    case 'note':
      return 'sticky-note'
    case 'email':
      return 'envelope'
    case 'meeting':
      return 'users'
  }
}
const ActivityNotation: SFC<ActivityNotationProps> = (props) => {
  let showInbound = false
  if (props.activity_type.medium_code === 'phone_call') {
    showInbound = true
    if (props.direction === 'outbound') {
      showInbound = false
    }
  }
  return (
    <NotationContainer>
      {showInbound && (
        <LiveCallDirection>
          <FontAwesomeIcon icon="share" />
        </LiveCallDirection>
      )}
      <FontAwesomeIcon icon={getIcon(props.activity_type.medium_code)} />
    </NotationContainer>
  )
}

export default ActivityNotation
