/* eslint-disable camelcase */
import { callQLApi } from '../api-utils'

export const qlDictionary =
  ' dictionary_uid code name description is_user_dictionary values{ dictionary_value_uid value name name_en translation } '

export default {
  create: params => {
    const qlParams = {
      query: ` mutation  dictionary_create($dictionary:dictionary_in) { dictionary_create(dictionary: $dictionary)  { ${qlDictionary} }  } `,
      variables: {
        dictionary: {
          name: params.name,
          is_user_dictionary: false,
          code: params.code,
          description: params.description,
          values: params.values,
          translation: params.translation
        }
      }
    }
    return callQLApi(qlParams).then(res => res.dictionary_create)
  },
  update: params => {
    const qlParams = {
      query: ` mutation  dictionary_update($dictionary:dictionary_in) { dictionary_update(dictionary: $dictionary)  { ${qlDictionary} }  } `,
      variables: {
        dictionary: {
          dictionary_uid: params.dictionary_uid,
          name: params.name,
          code: params.code,
          description: params.description,
          values: params.values,
          translation: params.translation,
          values_delete_ids: params.deleteIds
        }
      }
    }
    return callQLApi(qlParams).then(res => res.dictionary_update)
  },
  fetch: () => {
    const query = ` { dictionaries {  ${qlDictionary} } } `
    return callQLApi(query).then(res => res.dictionaries)
  }
}
