import React from 'react'
import { string } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'

const qlqPermissions = gql`
  {
    permissions {
      permission_uid
      name
    }
  }
`

const CrmPermissionSelect = props => {
  const variables = {}

  return (
    <Query query={qlqPermissions} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.permissions
            ? data.permissions.map(v => ({
                ...v,
                value: v.permission_uid
              }))
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_permission"
                  defaultMessage="Select Permission"
                />
              )
            }
          />
        )
      }}
    </Query>
  )
}

CrmPermissionSelect.propTypes = {
  placeholder: string
}

export default CrmPermissionSelect
