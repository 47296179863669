/* eslint-disable camelcase */
import md5 from 'md5'
import apiCaller, { callQLApi, callQLApiFormDataNew } from '../api-utils'
import { parseUser } from '../api-parsers'
import { msToDateformat } from 'crm-utils/time-utils'
import { getCrmInstanceUid } from 'crm-api/api-utils'

export const qlDocument =
  ' is_blocked token_valid_after refresh_token task_queue_uid listExternalId { code instanceUid value key instanceName camsUserName } contract { contract_instance_uid contract_instance { instance_uid name } profile { user_profile_uid name } user_contracts_uid user_uid profile_uid valid_from valid_till } user_uid login full_name password_hash sip_server sip_username sip_password is_active email headshot_url mobile_phone local_phone department { name } department_uid position contract_start_date contract_end_date default_lead_campaign_uid photo_resource_uid cams_business_source_id next_task_options photo_url organization_unit_uid '

const convertActiveStatusToString = is_active => {
  if (is_active === '') return 'false'
  return 'true'
}

function mapNextTaskOptions(params) {
  let result = params.next_task_options
  if (result) {
    for (var k in result) {
      if (result.hasOwnProperty(k)) {
        const items = result[k].items
        if (!Array.isArray(items) || !items.length) {
          delete result[k]
        }
      }
    }
  }
  return result
}

function mapUser(params) {
  return {
    login: params.login,
    is_active: params.is_active,
    full_name: params.full_name, // eslint-disable-line
    sip_server: params.sip_server, // eslint-disable-line
    sip_username: params.sip_username, // eslint-disable-line
    sip_password: params.sip_password, // eslint-disable-line
    mobile_phone: params.mobile_phone,
    local_phone: params.local_phone,
    position: params.position,
    department: params.department,
    email: params.email,
    contract: {
      user_contracts_uid: params.user_contracts_uid,
      profile_uid: params.profile_uid,
      valid_from: params.valid_from
        ? msToDateformat(params.valid_from, 'YYYY-MM-DD')
        : null,
      valid_till: params.valid_till
        ? msToDateformat(params.valid_till, 'YYYY-MM-DD')
        : null,
      contract_instance_uid: params.contract_instance_uid
    },
    listContracts: params.listContracts.map(v => {
      return {
        user_contracts_uid: v.user_contracts_uid,
        profile_uid: v.profile_uid,
        contract_instance_uid: v.contract_instance_uid,
        profile_filter: v.profile_filter,
        valid_from: null,
        valid_till: v.isDeleted
          ? msToDateformat(new Date(), 'YYYY-MM-DD')
          : v.valid_till
          ? v.valid_till
          : null
      }
    }),
    contract_start_date: params.contract_start_date
      ? msToDateformat(params.contract_start_date, 'YYYY-MM-DD')
      : null,
    contract_end_date: params.contract_end_date
      ? msToDateformat(params.contract_end_date, 'YYYY-MM-DD')
      : null,
    default_lead_campaign_uid: params.default_lead_campaign_uid,
    department_uid: params.department_uid,
    cams_business_source_id: params.cams_business_source_id,
    external_ids: params.external_ids,
    next_task_options: mapNextTaskOptions(params),
    organization_unit_uid: params.organization_unit_uid,
    task_queue_uid: params.task_queue_uid
  }
}

export default {
  fetchUsers() {
    const qlParams = {
      query: `query users($filter: JSON, $limit: Int) { users(filter: $filter, limit: $limit) { user_uid, full_name, headshot_url, photo_url, is_active }}`,
      variables: {
        filter: {
          instance_uid: getCrmInstanceUid()
        }
      }
    }
    return callQLApi(qlParams).then(res => res.users)
  },
  saveUser(data) {
    console.log('saveUser api')
    const item = {
      ...data.payload.values,
      password_hash: md5(data.payload.values.password),
      is_active: convertActiveStatusToString(data.payload.values.is_active),
      kind: 'crm_user'
    }
    delete item.department
    return apiCaller('us_api_crm_user_create', {
      item
    }).then(res => res.us_api_crm_user_create)
  },
  updateUser(data) {
    const item = {
      ...data.payload.values,
      is_active: convertActiveStatusToString(data.payload.values.is_active),
      kind: 'crm_user'
    }
    return apiCaller('us_api_crm_user_update', {
      user_uid: data.payload.user.data.user_uid,
      item
    }).then(res => parseUser(res.us_api_crm_user_update))
  },
  list: () => {
    const query = ` { users { cams_business_source { userId firstName } ${qlDocument} } } `
    return callQLApi(query).then(res => res.users)
  },
  create: params => {
    const qlParams = {
      query: ` mutation  user_create($user:user_in) { user_create(user: $user)  { ${qlDocument} }  } `,
      variables: {
        user: Object.assign(
          { password_hash: md5(params.password) },
          mapUser(params)
        )
      }
    }

    return callQLApi(qlParams).then(res => res.user_create)
  },
  update: params => {
    const qlParams = {
      query: ` mutation user_update($user:user_in) { user_update(user: $user)  {
        listContracts { contract_instance_uid contract_instance { instance_uid name } profile { user_profile_uid name } user_contracts_uid user_uid profile_uid valid_from valid_till profile_filter }
        ${qlDocument} }  } `,
      variables: {
        user: Object.assign({ user_uid: params.user_uid }, mapUser(params))
      }
    }

    return callQLApi(qlParams).then(res => res.user_update)
  },
  uploadPhoto: params => {
    const formData = new FormData()
    formData.append(
      'query',
      ` mutation user_update_photo($user:user_in) { user_update_photo(user: $user)  { ${qlDocument} }  } `
    )
    formData.append(
      'variables',
      `{ "user": ${JSON.stringify({ user_uid: params.user_uid })}  }`
    )

    if (params.avatar) formData.append('avatar', params.avatar)
    return callQLApiFormDataNew(formData).then(res => res.user_update_photo)
  },
  changeMyPassword: params => {
    const qlParams = {
      query: ` mutation user_change_password($user:user_in) { user_change_password(user: $user)  { ${qlDocument} }  } `,
      variables: {
        user: {
          password_hash: params.payload.newPassword // eslint-disable-line
        }
      }
    }
    return callQLApi(qlParams).then(res => res.user_change_password)
  },
  get: user_uid => {
    // eslint-disable-line
    const query = ` { users(user_uid:"${user_uid}") {  ${qlDocument} }   } `
    console.log('get User with id', query)
    return callQLApi(query).then(res => res.users)
  },
  getWithContracts: user_uid => {
    // eslint-disable-line
    const query = ` { users(user_uid:"${user_uid}") {
      ${qlDocument}
      listPermissions {
        user_uid permission_code filters access_mode ownership_rule module profile_uid
      }
      listContracts { contract_instance_uid contract_instance { instance_uid name } profile { user_profile_uid name } user_contracts_uid user_uid profile_uid valid_from valid_till profile_filter } }
      user_contracts(user_uid:"${user_uid}") {  user_contracts_uid  profile_uid  valid_from valid_till  }
      campaigns { campaign_uid name } } `

    console.log('get User and contracts with id', query)
    return callQLApi(query).then(res => res)
  }
}
