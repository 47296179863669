/* eslint-disable camelcase */
import React from 'react'
import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

export const MODE_DASHBOARDS = 'DASHBOARDS'
export const MODE_MANAGEMENT_REPORTS = 'MANAGEMENT_REPORTS'

const editDashboardHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    static propTypes = {
      ...cqCommandPropTypes
    }
    static defaultProps = {
      dashboards: [],
      mode: MODE_DASHBOARDS
    }
    constructor(props) {
      super(props)
      this.state = {
        isEditingDashboard: props.dashboard
          ? props.dashboard.dashboard_uid === 'new'
          : false,
        dashboard: props.dashboard ? { ...props.dashboard } : undefined
      }
    }
    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (nextProps.dashboard !== this.props.dashboard) {
        this.setState({
          isEditingDashboard:
            nextProps.dashboard && nextProps.dashboard.dashboard_uid === 'new',
          dashboard: nextProps.dashboard
            ? { ...nextProps.dashboard }
            : undefined
        })
      }
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState({ ...this.state, ...newState }, () => {
          resolve()
        })
      })

    handleDashboardNameChange = event => {
      this.setState({
        dashboard: {
          ...this.state.dashboard,
          name: event.currentTarget.value
        }
      })
    }

    handleClickAddWidget = () => {
      // console.log('handleClickAddWidget')
      const newWidget = {
        type: 'analitics_widget',
        widgetDefinition: {
          isEditing: true,
          version: 3
        }
      }
      if (this.state.dashboard) {
        const newDashboard = { ...this.state.dashboard }
        newDashboard.content = newDashboard.content ? newDashboard.content : {}
        newDashboard.content.items =
          this.state.dashboard.content && this.state.dashboard.content.items
            ? this.state.dashboard.content.items.slice()
            : []
        newDashboard.content.items.push(newWidget)
        // console.log('new dashboard')
        this.setState({ dashboard: newDashboard })
      }
    }
    handleSaveDashboard = async pDashboard => {
      // console.log('handleSaveDashboard', pDashboard, this.state, this.props)
      const dashboard = pDashboard || this.state.dashboard
      const command = {
        aggregate_type: 'dashboard',
        type: 'dashboard.update',
        aggregate_uid: dashboard.dashboard_uid,
        payload: {
          dashboard: {
            ...dashboard
          }
        }
      }
      if (!dashboard.dashboard_uid) {
        command.type = 'dashboard.create'
        command.aggregate_uid = this.props.newObjectUid
      }
      try {
        const res = await this.props.onRunCommand(command)
        this.setState({ isEditingDashboard: false })
        // console.log('Got res,', this.props)
        return res.data.cqCommand.payload.dashboard
      } catch (err) {}
      // console.log('command', command)
    }

    handleStartEditDashboard = () => {
      // console.log('handleStartEditDashboard')
      if (
        this.state.dashboard &&
        this.state.dashboard.access_level === 'user'
      ) {
        this.setState({ isEditingDashboard: true })
      }
    }
    handleManualJsonChange = dashboard => {
      // console.log('handleManualJsonChange', dashboard)
      this.setState({ dashboard })
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          dashboard={this.state.dashboard}
          isEditingDashboard={this.state.isEditingDashboard}
          dashboardName={this.state.dashboardName}
          onDashboardNameChange={this.handleDashboardNameChange}
          onSaveDashboard={this.handleSaveDashboard}
          onClickAddWidget={this.handleClickAddWidget}
          onStartEditDashboard={this.handleStartEditDashboard}
          onManualJsonChange={this.handleManualJsonChange}
        />
      )
    }
  }

  return crmGenericCommandHoc(Inner)
}

export default editDashboardHoc
