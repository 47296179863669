/* eslint-disable */
import React, { PureComponent } from 'react'
import { func, shape, bool } from 'prop-types'
import widgetUtilsHoc from '../widget-utils-hoc'
import { listLeadsByStatusSummary } from 'crm-api/analytics-api'
import { CrmButton } from 'crm-components'

class AccountsByStatusSummaryWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState({ ...newState }, () => {
        resolve()
      })
    })

  componentDidMount() {
    this.handleRefresh()
  }

  _parseData = data => {
    // console.log('_parseData,', data)
    const stats = {}
    let total = 0

    const getRow = (stats, status) => {
      if (!stats[status]) {
        // eslint-disable-next-line
        stats[status] = { count: 0, status, details: {}, lostDetails: {} }
      }
      return stats[status]
    }

    if (data) {
      data.forEach(r => {
        const row = getRow(stats, r.status)
        row.count += r.count
        total += r.count
      })
    }

    let res = Object.keys(stats).map(k => {
      let order = 0
      let label = stats[k].status
      if (stats[k].status === 'bogus') {
        order = 5
        label = 'Bogus'
      }
      if (stats[k].status === 'lead') {
        order = 10
        label = 'Lead'
      }
      if (stats[k].status === 'prospect') {
        order = 20
        label = 'Prospect'
      }

      if (stats[k].status === 'lost') {
        order = 40
        label = 'Lost'
      }
      if (stats[k].status === 'customer') {
        order = 50
        label = 'Win'
      }

      return { ...stats[k], order, label }
    })

    res = res.sort((a, b) => a.order - b.order)

    res.push({ status: 'total', count: total, label: 'Total' })
    // console.log('resultsByStatus', res)
    this.setState({ resultsByStatus: res })
  }
  handleRefresh = async () => {
    const filter = {}
    if (this.props.userMode) {
      // eslint-disable-next-line
      filter.owner_user_uid = this.props.currentUser.user_uid
    }
    // console.log('doRefresh')
    // console.log(this.props)
    const loadingFn = async () => {
      // T O D  O - here can eventually add additional parameters
      const analyticsContext = this.props.analyticsContext
      const res = await listLeadsByStatusSummary(filter, analyticsContext)
      // console.log('results', res)
      this._parseData(res)
    }
    this.props.onStartLoading(loadingFn)
  }

  componentWillReceiveProps = nextProps => {
    if (
      JSON.stringify(nextProps.analyticsContext) !==
      JSON.stringify(this.props.analyticsContext)
    ) {
      // setTimeout(() => {
      //   this.handleRefresh()
      // })
    }
  }
  handleClickStatus = event => {
    // console.log('handleClickStatus', event)
    this.props.onDashboardEvent({
      type: 'accountStatusSelected',
      data: {
        status: event.currentTarget.id
      }
    })
  }
  render() {
    console.log('AccountsByStatusSummaryWidget')
    const resultsByStatus = this.state.resultsByStatus

    return (
      <div className="util-paddingMd">
        <CrmButton label="Refresh" onClick={this.handleRefresh} />
        {resultsByStatus && (
          <div className="row">
            {resultsByStatus.map(s => {
              return (
                <div
                  key={s.status}
                  id={s.status}
                  className="col-md-2"
                  onClick={this.handleClickStatus}
                >
                  <div>{s.label}</div>
                  <div
                    style={{
                      color: '#f47735',
                      fontSize: 32,
                      textAlign: 'center'
                    }}
                  >
                    {s.count}{' '}
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {JSON.stringify(this.state.resultsByStatus)}
      </div>
    )
  }
}

AccountsByStatusSummaryWidget.propTypes = {
  onDashboardEvent: func.isRequired,
  analyticsContext: shape().isRequired,
  onStartLoading: func.isRequired,
  userMode: bool
}
AccountsByStatusSummaryWidget.defaultProps = {
  userMode: false
}

export default widgetUtilsHoc(AccountsByStatusSummaryWidget)
