import React, { useState, useEffect } from 'react'
import ViewModeSelector from './view-mode-selector'
import styled from 'styled-components'
import { useCrmDynamicTableContext } from '../dynamic-table-context'
import { DynamicTableViewMode } from '../dynamic-table-consts'
import ViewOptionsPivot from '../views/view-options-pivot'
import { DataToolsBar } from './data-tools-bar'
import ViewOptionsTable from '../views/table/view-options-table'
import ViewOptionsChart from '../views/view-options-chart'
import ViewOptionsDashboard from '../views/view-options-dashboard'
import { useClickOutside } from 'react-click-outside-hook'
import { useWindowSize } from '@cartrack-crm/ui/src/utils/use-windows-size'
import { CrmButton } from '@cartrack-crm/ui'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV'
import classNames from 'classnames'
// import styles from 'crm-modules/dashboard/dashboards/filters/dashboard-filters.scss'

const ViewModeDiv = styled.div`
  position: relative;
  .backgroud {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    &:hover {
      background: #efefef;
      cursor: pointer;
    }
  }
  .popup {
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    z-index: 100;
    position: absolute;
    top: 40px;
    right: 0px;
    background: white;
    min-width: 400px;
    padding: 10px;

    .CrmButton--iconButton--withLabel {
      width: 100%;
    }
  }
  .viewSelector {
  }
`

const DynamicTableHeader = (props) => {
  const dynamicTableContext: any = useCrmDynamicTableContext()
  const [isExpanded, setExpanded] = useState(false)
  const [showDropDownMenu, setShowDropDownMenu] = useState(false)
  const [smallFilter, setSmallFilter] = useState(props.small_filter)
  const [isShowingColumnSelect, setShowColumnsSelect] = useState(false)
  const window_size: any = useWindowSize()
  const [ref, hasClickOutside] = useClickOutside()
  useEffect(() => {
    if (showDropDownMenu === true && isShowingColumnSelect === false) {
      setShowDropDownMenu(false)
    }
  }, [hasClickOutside])

  useEffect(() => {
    if (!props.small_filter) {
      if (window_size && window_size.width < 1600) {
        setSmallFilter(true)
      } else {
        setSmallFilter(false)
      }
    }
  }, window_size)

  const toggleDropdown = () => {
    setShowDropDownMenu(!showDropDownMenu)
  }

  const renderViewOptions = () => {
    if (dynamicTableContext.viewMode === DynamicTableViewMode.PIVOT) {
      return <ViewOptionsPivot {...props} />
    }
    if (dynamicTableContext.viewMode === DynamicTableViewMode.TABLE) {
      return <ViewOptionsTable {...props} setShowColumnsSelect={setShowColumnsSelect} />
    }
    if (dynamicTableContext.viewMode === DynamicTableViewMode.DASHBOARD) {
      return <ViewOptionsDashboard {...props} />
    }
    if (dynamicTableContext.viewMode === DynamicTableViewMode.CHART) {
      return <ViewOptionsChart {...props} />
    }
    return <span />
  }
  return (
    <div
      className="util-flexRow"
      style={{ background: 'white', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', zIndex: 1 }}
    >
      <div>{props.filtersComponent ? props.filtersComponent : <span>No filters bar</span>}</div>
      <div className="util-flexGrow" />
      <div className="util-flexRowCenter util-flexAlignItemsCenter">
        {/* {!smallFilter && (
          <div className="util-marginRight">{renderViewOptions()}</div>
        )} */}
        {!smallFilter && (
          <div>
            <ViewModeSelector />
          </div>
        )}

        {smallFilter && (
          <div className="util-relative util-marginRightSm util-marginLeftSm">
            <div className="util-flexCenterContent">
              <ViewModeDiv onClick={toggleDropdown}>
                <div className="background">
                  <CrmButton
                    icon={faEllipsisV}
                    className={classNames({
                      'CrmButton--selected': showDropDownMenu
                    })}
                    iconButton
                    grouped
                    noBorder
                  />
                </div>
                {showDropDownMenu && (
                  <div className="popup" ref={ref}>
                    <div>
                      <ViewModeSelector />
                    </div>
                    <div className="util-marginMd">{renderViewOptions()}</div>
                  </div>
                )}
              </ViewModeDiv>
            </div>
          </div>
        )}
        <div>
          <DataToolsBar {...props} />
        </div>
      </div>
    </div>
  )
}

export default DynamicTableHeader
