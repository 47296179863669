/* eslint-disable camelcase */
import uuidv4 from 'uuid/v4'
import { mapContactDetailsToServer } from './accounts'
import { msToUTCTimestamp } from 'util-functions/time-utils'

export const buildNewActivity = (accountUid, activityType, currentUser) => {
  console.log('buildNewActivity 2 ')
  const activity = {
    activity_type_uid: activityType.activity_type_uid,
    tmpId: uuidv4(),
    account_uid: accountUid,
    participants: [],
    activity_type: activityType
  }
  // Set default performer to current user
  // If that's a meeting - insert current user as participant
  if (
    activityType.medium_code === 'meeting' ||
    !activity.participants ||
    activity.participants.length === 0
  ) {
    if (!activity.participants) {
      activity.participants = []
    }
    activity.participants.push({
      role_code: 'performer',
      user_uid: currentUser.user_uid,
      user: currentUser,
      is_primary: true
    })
  }

  activity.primary_performer_user_uid = currentUser.user_uid
  activity.arranged_by_user_uid = currentUser.user_uid
  return activity
}
export const mock = 1

export const validateCreateActivity = activity => {
  if (activity) {
    if (!activity.start_time) {
      throw new Error('Activity must have start time')
    }
    if (activity.location_type === 'ON_SITE' && !activity.address_uid) {
      throw new Error('Meeting address is required for on site location')
    }
  }
}
export function validateActivity(activity) {
  console.log('  validateActivity', activity)
  if (!activity || !activity.activity_type) {
    throw new Error('Error - no activity')
  }
  if (!activity.participants || activity.participants.length === 0) {
    // Ttoastr.error("Can't find participants in activity")
    throw new Error('Error - no participant')
  }
  const performers = activity.participants.filter(
    p => p.role_code === 'performer'
  )
  if (!performers || performers.length === 0) {
    // Ttoastr.error("Can't find main performer in activity")
    throw new Error('Error - no performer')
  }
  if (performers.length > 1) {
    // Ttoastr.error("Activity can't have more than one performer - mark as guest")
    throw new Error('Error - too many performers')
  }

  if (
    activity.process_uid &&
    !activity.disposition_type_uid &&
    activity.activity_type.medium_code === 'phone_call'
  ) {
    throw new Error('Call Disposition is required for Process related call')
  }

  if (activity.activity_type.medium_code === "meeting" && typeof activity.activity_status == 'undefined') {
    throw new Error('Please select schedule status (Scheduled confirmed/not confirmed)')
  }
}

export const mapParticipantToServer = p => ({
  activity_participant_uid: p.activity_participant_uid,
  activity_uid: p.activity_uid,
  contact_details: p.contact_details
    ? mapContactDetailsToServer(p.contact_details)
    : undefined,
  contact_details_uid: p.contact_details_uid,
  participant_account_person_uid: p.participant_account_person_uid,
  participant_account_uid: p.participant_account_uid,
  role_code: p.role_code,
  user_uid: p.user_uid,
  is_primary: p.role_code === 'performer'
})

export function parseActivityToServer(activity) {
  return {
    start_time: msToUTCTimestamp(activity.start_time),
    account_uid: activity.account_uid,
    activity_status_uid: activity.activity_status_uid,
    activity_type_uid: activity.activity_type_uid,
    activity_uid: activity.activity_uid,
    address_uid: activity.address_uid,
    attachment_files: activity.attachment_files,
    details: activity.details,
    end_time: activity.end_time,
    note_text: activity.note_text,
    source_type_uid: activity.source_type_uid,
    subject: activity.subject,
    direction: activity.direction ? activity.direction : 'outbound',
    participants: activity.participants
      ? activity.participants.map(mapParticipantToServer)
      : undefined,
    disposition_type_uid: activity.disposition_type_uid,
    disposition_details: activity.disposition_details,
    process_uid: activity.process_uid,
    completed_time: activity.completed_time,
    contact_details_uid: activity.contact_details_uid,
    vehicle_id: activity.vehicle_id,
    location_type: activity.location_type
  }
}
