import React, { useState, useEffect } from 'react'
import { Field } from 'redux-form'
import {
  CrmDropdown,
  CrmButton,
  CrmTextArea,
  CrmJsonEditArea,
  CrmTextInput,
  CrmJsonPreview,
  CrmModal
} from 'crm-components'
import FilterCreator from './filter-creator'
import { SectionFilters } from './section-filters'
import DashboardSelector from './dashboard-selector'
import SchedulePartPreview from './schedule-part-preview'
import { Spacer } from 'util-components'
import { useAnalyticsContext } from 'crm-core/analytics/context'

const SchedulePart = (props) => {
  const { item, data, handleDelete, index, handleChangeFilterValues } = props
  const [fields, setFields] = useState([])
  const [isOpenPreview, setOpenPreview] = useState(false)
  const reportTypeOptions = [
    { name: 'Plain Text', value: 'text' },
    { name: 'Widget', value: 'widget' }
  ]
  const [showDropDownMenu, setShowDropDownMenu] = useState(false)
  const [openJsonDetailModal, setOpenJsonDetailModal] = useState(false)
  const handleOpenJsonDetail = () => setOpenJsonDetailModal(true)
  const handleCloseJsonDetail = () => setOpenJsonDetailModal(false)
  const toggleDropdown = () => setShowDropDownMenu(!showDropDownMenu)
  const analyticsContext = useAnalyticsContext()
  const filterValues = analyticsContext.filters
  useEffect(() => {
    handleChangeFilterValues(index, filterValues)
    return () => {}
  }, [filterValues])

  const handlePreview = () => setOpenPreview(true)
  const getAllFields = (fields) => setFields(fields)

  return (
    <div
      style={{
        marginTop: '25px',
        padding: '15px',
        marginBottom: '15px'
      }}
      className="CrmMdCard"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 15
        }}
      >
        <div style={{ flex: 1 }} className="row">
          <div className="col-md-3">
            <Field
              name={`${item}.report_type`}
              component={CrmDropdown}
              placeholder="Report type"
              options={reportTypeOptions}
            />
          </div>
          {renderReportFormat(item, data)}
          <div className="col-md-4">
            <Field
              name={`${item}.part_name`}
              component={CrmTextInput}
              placeholder="Part Name"
            />
          </div>
        </div>

        <div className="util-marginLeft">
          {data.report_type === 'widget' && (
            <CrmButton grouped label="Preview" onClick={handlePreview} />
          )}
        </div>

        <div className="util-marginLeft">
          <CrmButton grouped icon="trash" onClick={handleDelete} />
        </div>

        <div className="util-marginLeftSm">
          <div className="util-flexCenterContent">
            <div className="Ellipse-Dropdown" onClick={toggleDropdown}>
              <div className="icon-background">
                <i className="fa fa-ellipsis-v" />
              </div>
              {showDropDownMenu && (
                <ul>
                  <li onClick={handleOpenJsonDetail}>
                    <span>Show Json</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-6">{renderInput(item, data, getAllFields)}</div>
      </div>

      {/* {renderFilter(item, data, fields)} */}

      <Spacer height="20px" />
      <SectionFilters />

      {data.report_type === 'widget' && (
        <SchedulePartPreview
          isOpen={isOpenPreview}
          onClose={() => setOpenPreview(false)}
          dashboardUid={data.dashboard_uid}
          filter={data.filter}
        />
      )}

      <CrmModal
        isOpen={openJsonDetailModal}
        title="Details"
        size="xl"
        onClose={handleCloseJsonDetail}
      >
        <CrmJsonPreview json={data} />
      </CrmModal>
    </div>
  )
}

const renderInput = (item, data, getAllFields) => {
  switch (data['report_type']) {
    case 'text': {
      return (
        <Field
          name={`${item}.text`}
          component={CrmTextArea}
          size="100"
          type="text"
          placeholder="Text"
        />
      )
    }
    case 'custom': {
      return (
        <Field
          name={`${item}.params_json`}
          component={CrmJsonEditArea}
          size="100"
          type="text"
          placeholder="Json Params"
        />
      )
    }
    default: {
      return (
        <Field
          name={`${item}.dashboard_uid`}
          size="100"
          component={DashboardSelector}
          getAllFields={getAllFields}
          placeholder="Dashboard List"
        />
      )
    }
  }
}

const renderFilter = (item, data, fields) => {
  if (data['report_type'] === 'widget') {
    return (
      <div className="row" style={{ marginBottom: '15px' }}>
        <Field
          name={`${item}.filter`}
          component={FilterCreator}
          size="100"
          type="text"
          placeholder="Filter"
          fields={fields}
        />
      </div>
    )
  }
  return <React.Fragment />
}

const renderReportFormat = (item, data) => {
  const reportFormatOptions = [
    { name: 'Excel', value: 'xls' },
    { name: 'HTML', value: 'html' }
  ]
  if (!data['report_type'] || data['report_type'] === 'text')
    return <React.Fragment />
  return (
    <div className="col-md-3">
      <Field
        name={`${item}.report_format`}
        component={CrmDropdown}
        placeholder="Report Format"
        options={reportFormatOptions}
      />
    </div>
  )
}
export default SchedulePart
