/* eslint-disable camelcase */

import { actions } from 'crm-duxs/phone/phone-reducer'
import { takeLatest, select, takeEvery, call, put } from 'redux-saga/effects'
import { getUser } from 'crm-duxs/crm-reducer'
// Aimport activitiesApi from 'crm-api/activities-api'
import { getCrmCountryCode } from 'crm-api/api-utils'

let originateCallSuccessCallback = () => {}
let onCallEndCallback = () => {}

function* originateCall(action) {
  console.log('originateCall SAGA')
  console.dir(action)

  console.log('has client')
  const originateParams = { ...action.payload.originateParams }
  originateParams.number.value = originateParams.number.value.replace(
    /[^0-9]/g,
    ''
  )

  const countryCode = getCrmCountryCode() ? getCrmCountryCode() : 'ZA'
  console.log('Try to load awesome-phonenumber')
  let awesomePhonenumber = yield import('awesome-phonenumber')
  console.log('awesomePhonenumber module', awesomePhonenumber)
  awesomePhonenumber = awesomePhonenumber.default
  console.log('awesomePhonenumber', awesomePhonenumber)
  // Aconst countryCode = 'US'
  if (countryCode === 'US') {
    const phoneNumber = awesomePhonenumber(
      originateParams.number.value,
      countryCode
    )
    console.log('phoneNumber', phoneNumber)
    if (phoneNumber.getNumber('significant').length > 4) {
      if (phoneNumber.isValid()) {
        originateParams.number.value = `0${phoneNumber
          .getNumber()
          .replace(/\+/, '')
          .padStart(12, '0')}`
      } else {
        console.log('phoneNumber XXXX : ', phoneNumber.getNumber('significant'))
        originateParams.number.value = `0${originateParams.number.value}`.padStart(
          13,
          '0'
        )
      }
    }
  } else if (countryCode) {
    const phoneNumber = awesomePhonenumber(
      originateParams.number.value,
      countryCode
    )
    console.log('phoneNumber', phoneNumber)
    if (
      phoneNumber.getNumber('significant') &&
      phoneNumber.getNumber('significant').length > 4
    ) {
      originateParams.number.value = `0${phoneNumber.getNumber('significant')}`
    }
  }
  console.log('originateParams', originateParams)
  if (window.dialerClient) {
    const currentUser = yield select(getUser)
    originateParams.source_ext = currentUser.sip
    // A originateParams.variables = originateParams.variables
    console.log('Phone : ', originateParams.number.value)
    console.log('doRequestOriginateCall', originateParams)
    originateCallSuccessCallback = originateParams.onOriginateSuccess
    onCallEndCallback = originateParams.onCallEnd
    window.dialerClient.doRequestOriginateCall(originateParams)

    yield put({
      type: actions.callDetails.type,
      payload: { call_start_time: null, call_end_time: null }
    })
  } else {
    console.error('no dialer client')
  }
}

function* originateCallSuccess(action) {
  console.log('originateCallSuccess', action)

  yield call(originateCallSuccessCallback, action.payload)
  yield put({
    type: actions.callDetails.type,
    payload: { call_start_time: action.payload.start_time, call_end_time: null }
  })

  // Aif (
  //   typeof action.payload.activity_uid !== 'undefined' &&
  //   action.payload.activity_uid
  // ) {
  //   yield call(activitiesApi.updateLiveActivity, action.payload.activity_uid, {
  //     activity_uid: action.payload.activity_uid,
  //     call_external_uid: action.payload.call_uid,
  //     call_start_time: action.payload.start_time
  //   })
  // }
  originateCallSuccessCallback = () => {}
}

// A function callStatusChanged(action) {
//   console.log('callStatusChanged : ', action)

//   if (
//     typeof action.payload.activity_uid !== 'undefined' &&
//     action.payload.activity_uid
//   ) {
//     const updateData = {
//       activity_uid: action.payload.activity_uid
//     }

//     // A if (action.payload.channelstatedesc === 'Up') {
//     //   updateData.call_asterisk_id = action.payload.uniqueid

//     //   yield call(
//     //     activitiesApi.updateLiveActivity,
//     //     action.payload.activity_uid,
//     //     updateData
//     //   )
//     // }
//   }
// }

function* callHangUp(action) {
  console.log('callHangup : ', action)

  yield call(onCallEndCallback, action.payload)
  onCallEndCallback = () => {}

  yield put({
    type: actions.callDetails.type,
    payload: { call_end_time: action.payload.call_end_time }
  })

  // Aif (
  //   typeof action.payload.activity_uid !== 'undefined' &&
  //   action.payload.activity_uid
  // ) {
  //   const updateData = {
  //     activity_uid: action.payload.activity_uid,
  //     call_end_time: action.payload.call_end_time,
  //     call_asterisk_id: action.payload.uniqueid
  //   }

  //   yield call(
  //     activitiesApi.updateLiveActivity,
  //     action.payload.activity_uid,
  //     updateData
  //   )
  // }
}

// eslint-disable-next-line
function* dialerClientEventReceived(action) {
  console.log('dialerClientEventReceived SAGA')
  console.dir(action)
}

function* agentStatusChange(action) {
  yield window.dialerClient.doChangeAgentStatus(action.payload.code)
}

export default function* phoneSaga() {
  yield takeLatest(actions.agentStatusChange.type, agentStatusChange)
  yield takeLatest(actions.originateCall.type, originateCall)
  yield takeLatest(actions.originateCallSuccess.type, originateCallSuccess)
  // A yield takeLatest(actions.callStatusChanged.type, callStatusChanged)
  yield takeLatest(actions.callHangUp.type, callHangUp)
  yield takeEvery(
    actions.dialerClientEventReceived.type,
    dialerClientEventReceived
  )
}
