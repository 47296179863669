import React from 'react'
import { connect } from 'react-redux'
import { func, bool, arrayOf, object } from 'prop-types'
import { Link } from 'react-router-dom'

import ChangeProcessOwnerWidget from '../../accounts/account/components/_change-process-owner-widget.jsx'
import ReassignWidget from '../../accounts/account/components/_reassign-widget.jsx'
import StartNewProcessWidget from '../../accounts/qualification/start-new-process-widget.jsx'
import ChangeLeadCampaignWidget from '../../accounts/account/components/_change-lead-campaign-widget.jsx'
import ChangeLeadInDateWidget from '../../accounts/account/components/_change-lead-in-date-widget.jsx'
import {
  LostProspectForm,
  buildLostProspectCommand
} from '../account/components/lost/lost-prospect-form'

import {
  CrmModal,
  FlatTable,
  CrmDropdown,
  CrmButton,
  CrmCheckbox
} from 'crm-components'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

import {
  buildDeleteAccountCommand,
  buildPrioritizeAccountCommand
} from 'crm-utils/accounts-utils'
import {
  getAccounts,
  closeBulkOperations,
  getBulkOperationParams
} from 'crm-duxs/bulk-operations-reducer'
import { get } from 'lodash'

class BulkOperationsPopup extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      tab: 'messages',
      accounts: props.accounts,
      errors: [],
      selectAll: false
    }

    this.columns = [
      {
        header: 'Sel',
        accessor: 'is_selected',
        key: 'is_selected',
        headerRenderer: () => {
          const self = this
          const onChange = () => {
            const accounts = self.state.accounts.map(a => ({
              ...a,
              is_selected: !self.state.selectAll // eslint-disable-line
            }))
            this.setState({ accounts, selectAll: !self.state.selectAll })
          }

          return (
            <div>
              <CrmCheckbox
                input={{
                  value: get(self.state, 'selectAll', false),
                  onChange
                }}
              />
            </div>
          )
        },
        Cell: row => {
          const self = this
          let accountUid = null
          let accountUidKey = null
          const validAccountUidKeys = ['account.account_uid', 'account_uid']

          validAccountUidKeys.forEach(key => {
            const keyValue = get(row, `original.${key}`, false)
            if (!accountUid && keyValue) {
              accountUid = keyValue
              accountUidKey = key
            }
          })

          const onChange = () => {
            const idx = self.state.accounts.findIndex(
              a => get(a, accountUidKey, false) === accountUid
            )

            if (idx !== undefined) {
              const accounts = [...self.state.accounts]
              accounts[idx] = {
                ...accounts[idx],
                is_selected: !accounts[idx].is_selected // eslint-disable-line
              }
              this.setState({ accounts })
            }
          }
          return (
            <CrmCheckbox
              input={{
                value: get(row, 'original.is_selected', false),
                onChange
              }}
            />
          )
        }
      },
      {
        Header: 'Account Name',
        Cell: row => {
          let name = null
          let accountUid = null
          const validNameKeys = [
            'account.name',
            'account_name',
            'account__name',
            'name'
          ]
          const validAccountUidKeys = ['account.account_uid', 'account_uid']

          validNameKeys.forEach(key => {
            const keyValue = get(row, `original.${key}`, false)
            if (!name && keyValue) name = keyValue
          })

          validAccountUidKeys.forEach(key => {
            const keyValue = get(row, `original.${key}`, false)
            if (!accountUid && keyValue) accountUid = keyValue
          })

          if (name && accountUid) {
            return (
              <Link to={`/crm/account/${accountUid}`} target="blank">
                {name}
              </Link>
            )
          }
        },
        accessor: 'account_name',
        key: 'account_name'
      },
      { Header: 'Kind', accessor: 'kind', key: 'kind' },
      {
        Header: 'Result',
        accessor: 'bulkOperationsResult',
        key: 'bulkOperationsResult'
      }
    ]
    this.availableOperations = []
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.accounts !== this.props.accounts) {
      this.setState({ accounts: nextProps.accounts })
    }
  }

  handleClose = () => {
    this.props.doCloseBulkOperations()
  }

  handleSelectedOperationChange = selectedOperation => {
    this.setState({ selectedOperation })
  }

  getAvailableOperations = () => {
    const operations = []
    const elementType = this.props.params
      ? this.props.params.elementType
      : undefined

    if (elementType === 'prospect' || elementType === 'process') {
      operations.push({
        name: 'Change Process Owner',
        value: 'change_process_owner'
      })
    }

    operations.push({
      name: 'Delete Account',
      value: 'delete_account'
    })

    operations.push({
      name: 'Un-Delete Account',
      value: 'undelete_account'
    })

    operations.push({
      name: 'Prioritize Account Tasks',
      value: 'prioritize_account'
    })

    operations.push({
      name: 'Clear account owner',
      value: 'clear_owner'
    })

    operations.push({
      name: 'Test Operation (do nothing)',
      value: 'test'
    })

    operations.push({
      name: 'Start new process or reassign (Not for customers)',
      value: 'start_process'
    })

    operations.push({
      name: 'Change lead campaign',
      value: 'change_lead_campaign'
    })

    operations.push({
      name: 'Change lead in date',
      value: 'change_lead_in_date'
    })

    operations.push({
      name: 'Lose sales pipelines',
      value: 'lose_processes'
    })

    operations.push({
      name: 'Add additional owners',
      value: 'add_additional_owner'
    })

    operations.push({
      name: 'Rebuild Accounts',
      value: 'rebuild_account'
    })

    return operations
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, () => {
        resolve()
      })
    })

  timeoutAsync = async time =>
    new Promise(resolve => {
      setTimeout(resolve, time)
    })

  updateItemResult = async (idx, bulkOperationsResult) => {
    const accounts = [...this.state.accounts]
    accounts[idx] = { ...accounts[idx], bulkOperationsResult }
    await this.setStateAsync({ accounts })
  }

  _doFormBasedOperation = async () => {
    let account = this.state.accounts[this.state.currentIdx]
    if (account.account) {
      account = this.state.accounts[this.state.currentIdx].account
    }
    try {
      const res = await this.innerRef.props.onBulkOperation(
       account
      )
      await this.updateItemResult(this.state.currentIdx, 'success')
    } catch (err) {
      await this.updateItemResult(this.state.currentIdx, 'error')
      const errors = [
        ...this.state.errors,
        {
          message: `Error processing row: ${this.state.currentIdx} ${get(
            err,
            'message',
            ''
          )}`
        }
      ]

      await this.setStateAsync({ errors })
    }
  }

  _doPrioritizeAccount = async () => {
    const command = buildPrioritizeAccountCommand(
      this.state.accounts[this.state.currentIdx].account_uid,
      11
    )
    try {
      await this.props.onRunCommand(command, {})
    } catch (err) {
      throw err
    }
  }

  _doDeleteAccount = async (isDelete = true) => {
    let res
    const command = buildDeleteAccountCommand(
      this.state.accounts[this.state.currentIdx].account_uid,
      isDelete
    )

    try {
      res = await this.props.onRunCommand(command, {})
    } catch (err) {
      throw err
    }
  }

  _doLoseProcess = async () => {
    if (!this.state.reasonLost) {
      throw new Error('Select reason')
    }

    const command = buildLostProspectCommand(
      this.state.accounts[this.state.currentIdx].account_uid,
      '$current',
      this.state.reasonLost.code
    )

    try {
      await this.props.onRunCommand(command, {})
    } catch (err) {
      throw err
    }
  }

  _doClearOwner = async () => {
    let res
    const command = {
      type: 'account.adminClearOwner',
      aggregate_type: 'account', // eslint-disable-line
      aggregate_uid: this.state.accounts[this.state.currentIdx].account_uid, // eslint-disable-line
      payload: {}
    }

    try {
      res = await this.props.onRunCommand(command, {})
    } catch (err) {
      throw err
    }
  }

  _doAddAdditionalOwner = async () => {
    const currentAccount = this.state.accounts[this.state.currentIdx]
    this.innerRef.props.onBulkOperation(currentAccount)
  }

  _doTest = async () => {
    await this.timeoutAsync(1000)
  }

  _doRebuildAccount = async () => {
    const command = {
      aggregate_type: 'accountProjection',
      type: 'accountProjection.rebuild',
      aggregate_uid: this.state.accounts[this.state.currentIdx].account_uid
    }
    try {
      await this.props.onRunCommand(command, {})
    } catch (err) {
      throw err
    }
  }

  processCurrentIdx = async () => {
    if (!this.updateItemResult) {
      return
    }
    if (this.state.accounts[this.state.currentIdx].bulkOperationsResult) {
      const accountName = get(
        this.state,
        `accounts[${this.state.currentIdx}].account_name`,
        false
      )
      const errors = [
        ...this.state.errors,
        {
          message: `Skipping row ${this.state.currentIdx} ${
            accountName ? `(${accountName}) ` : ''
          }because it has already been processed. Use "Clear Results" to process again.`
        }
      ]

      await this.setStateAsync({ errors })

      return
    }

    if (!this.state.accounts[this.state.currentIdx].is_selected) {
      await this.updateItemResult(this.state.currentIdx, 'Not Selected - Skip')
      return
    }

    await this.updateItemResult(this.state.currentIdx, 'processing')

    try {
      if (
        this.innerRef &&
        this.innerRef.props &&
        this.innerRef.props.onBulkOperation
      ) {
        await this._doFormBasedOperation()
      }

      if (this.state.selectedOperation === 'delete_account') {
        await this._doDeleteAccount()
      }

      if (this.state.selectedOperation === 'undelete_account') {
        await this._doDeleteAccount(false)
      }

      if (this.state.selectedOperation === 'prioritize_account') {
        await this._doPrioritizeAccount()
      }

      if (this.state.selectedOperation === 'clear_owner') {
        await this._doClearOwner(false)
      }

      if (this.state.selectedOperation === 'test') {
        await this._doTest()
      }

      if (this.state.selectedOperation === 'lose_processes') {
        await this._doLoseProcess()
      }

      if (this.state.selectedOperation === 'add_additional_owner') {
        await this._doAddAdditionalOwner()
      }

      if (this.state.selectedOperation === 'rebuild_account') {
        await this._doRebuildAccount()
      }

      await this.updateItemResult(this.state.currentIdx, 'success')
    } catch (err) {
      await this.updateItemResult(this.state.currentIdx, err.message)
    }
  }

  handleStopOperation = async () => {
    this.setState({ forceStop: true })
  }

  handleResults = () => {
    this.setState({
      accounts: this.state.accounts.map(a => ({
        ...a,
        bulkOperationsResult: undefined
      }))
    })
  }

  handleSelectReasonLost = reasonLost => {
    this.setState({ reasonLost })
  }

  handleStartOperation = async () => {
    await this.setStateAsync({
      currentIdx: 0,
      isRunning: true,
      forceStop: false,
      errors: []
    })

    while (
      this.state.currentIdx < this.state.accounts.length &&
      !this.state.forceStop
    ) {
      await this.processCurrentIdx() // eslint-disable-line
      await this.setStateAsync({ currentIdx: this.state.currentIdx + 1 }) // eslint-disable-line
    }

    await this.setStateAsync({ isRunning: false })
  }

  handleBulkComponentInit = ref => {
    this.innerRef = ref
  }

  handleSetRef = ref => {
    this.componentRef = ref
  }

  render() {
    const accounts = this.state.accounts
      ? this.state.accounts.map(a => ({ ...a, id: a.account_uid }))
      : []
    const elementType = this.props.params
      ? this.props.params.elementType
      : undefined
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Bulk Operations"
        onClose={this.handleClose}
        showFooter
        primaryButtonLabel="Close"
        onPrimaryButton={this.handleClose}
        size="xl"
        zIndex={5001}
      >
        <div className="util-flexColumn util-fullHeight">
          <div className="util-bgRed util-textWhite util-paddingSm util-textCenter">
            <div>
              <strong>WARNING</strong>
            </div>
            <small>
              This tool should be used only by advanced users. Use only after
              being trained by IT team.
            </small>
            <small>
              <strong>Wrong use may cause in serious data loss</strong>
            </small>
          </div>

          <div className="row util-fullHeight util-marginMd">
            <div className="col-md-6 util-fullHeight util-flexColumn">
              <h4>Accounts</h4>
              <div className="util-flexGrow" style={{ overflow: 'auto' }}>
                {accounts && (
                  <FlatTable data={accounts} columns={this.columns} />
                )}
              </div>
            </div>

            <div className="col-md-6 util-fullHeight util-flexColumn">
              <h4>Select Operation</h4>
              <div>
                <CrmDropdown
                  options={this.getAvailableOperations()}
                  input={{
                    onChange: this.handleSelectedOperationChange,
                    value: this.state.selectedOperation
                  }}
                />
              </div>

              <div>
                {this.state.selectedOperation === 'change_process_owner' && (
                  <ChangeProcessOwnerWidget
                    ref={this.handleSetRef}
                    bulkMode
                    onSave={this.handleStartOperation}
                    onBulkComponentInit={this.handleBulkComponentInit}
                  />
                )}

                {this.state.selectedOperation === 'start_process' && (
                  <div style={{ marginTop: '20px' }}>
                    <div>
                      ** Make sure account already lost before Start new process
                    </div>
                    <div> If not Please select Lose sales pipelines</div>
                    <StartNewProcessWidget
                      ref={this.handleSetRef}
                      bulkMode
                      onSave={this.handleStartOperation}
                      onBulkComponentInit={this.handleBulkComponentInit}
                    />
                  </div>
                )}
                {(this.state.selectedOperation === 'delete_account' ||
                  this.state.selectedOperation === 'undelete_account') && (
                  <div className="util-marginTop">
                    DELETE / UNDELETE ACCOUNT - no params needed
                  </div>
                )}
                {this.state.selectedOperation === 'prioritize_account' && (
                  <div className="util-marginTop">
                    Prioritize Account Tasks - no params needed
                  </div>
                )}

                {this.state.selectedOperation === 'test' && (
                  <div className="util-marginTop">
                    Test operation - you can click run just to try
                  </div>
                )}
                {this.state.selectedOperation === 'clear_owner' && (
                  <div className="util-marginTop">
                    This operation will remove account owner - but only if there
                    is no active process for this account (telesales, prospect
                    etc)
                  </div>
                )}
                {this.state.selectedOperation === 'lose_processes' && (
                  <div className="util-marginTop util-marginBottom">
                    <LostProspectForm
                      bulkMode
                      onSelectedReasonLost={this.handleSelectReasonLost}
                      reasonLost={this.state.reasonLost}
                      processTypeUid="e30eb792-2832-4c10-9f74-2c6b1df1318e"
                    />
                  </div>
                )}
                {this.state.selectedOperation === 'change_lead_campaign' && (
                  <div className="util-marginTop util-marginBottom">
                    <div className="util-textBold">
                      Please select lead campaign
                    </div>
                    <ChangeLeadCampaignWidget
                      ref={this.handleSetRef}
                      bulkMode
                      onSave={this.handleStartOperation}
                      onBulkComponentInit={this.handleBulkComponentInit}
                      bulkType="campaign_uid"
                    />
                  </div>
                )}
                {this.state.selectedOperation === 'change_lead_in_date' && (
                  <div className="util-marginTop util-marginBottom">
                    <div className="util-textBold">
                      Please select lead in date
                    </div>
                    <ChangeLeadInDateWidget
                      ref={this.handleSetRef}
                      bulkMode
                      onSave={this.handleStartOperation}
                      onBulkComponentInit={this.handleBulkComponentInit}
                      bulkType="lead_in_date"
                    />
                  </div>
                )}

                {this.state.selectedOperation === 'add_additional_owner' && (
                  <div className="util-marginTop util-marginBottom">
                    <ReassignWidget
                      ref={this.handleSetRef}
                      onBulkComponentInit={this.handleBulkComponentInit}
                      bulkMode
                      isShowDateSelect={false}
                      isOpen
                      isNew
                    />
                  </div>
                )}
              </div>

              <div className="util-marginTop">
                {!this.state.selectedOperation && (
                  <div>Select operation from list above</div>
                )}
              </div>

              <div className="util-marginTop util-flexRowRight">
                {this.state.isRunning && (
                  <div className="util-marginRight">
                    <CrmButton
                      type="primary"
                      label="Stop Operation"
                      onClick={this.handleStopOperation}
                    />
                  </div>
                )}
                {!this.state.isRunning && (
                  <div className="util-marginRight">
                    <CrmButton
                      type="secondary"
                      label="Clear results"
                      onClick={this.handleResults}
                    />
                  </div>
                )}
                {!this.state.isRunning && this.state.selectedOperation && (
                  <div className="util-marginRight">
                    <CrmButton
                      type="primary"
                      label="Start Bulk Operation"
                      onClick={this.handleStartOperation}
                    />
                  </div>
                )}
              </div>

              {this.state.errors && this.state.errors.length > 0 && (
                <div className="util-flexGrow util-marginTop util-marginBottom util-fullHeight util-flexColumn">
                  <div className="util-marginTop">
                    <h4>Errors Log</h4>
                  </div>
                  <div
                    className="util-flexGrow"
                    style={{ overflow: 'auto', fontSize: '12px' }}
                  >
                    {this.state.errors.map((e, i) => (
                      <div key={i}>{e.message}</div> // eslint-disable-line
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </CrmModal>
    )
  }
}

BulkOperationsPopup.propTypes = {
  doCloseBulkOperations: func.isRequired,
  isOpen: bool.isRequired,
  accounts: arrayOf(object),
  params: object,
  ...cqCommandPropTypes
}

BulkOperationsPopup.defaultProps = {
  accounts: undefined,
  params: undefined
}

function mapStateToProps(state) {
  return {
    accounts: getAccounts(state),
    params: getBulkOperationParams(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doCloseBulkOperations: () => dispatch(closeBulkOperations())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(crmGenericCommandQlHoc(BulkOperationsPopup))
