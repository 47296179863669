import React from 'react'
import moment from 'moment'
import { groupDataByMonth } from 'crm-utils/data-utils'
import { createDashboardFormats, useQueryActivity, getWidgetAnalyticContext } from '../../dashboards/tools'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export interface widgetFollowUpCallMadeHocProps {
  avgFollowUpCallCurrentMonthPerPerson: number | null
  avgFollowUpCallPassedMonthData: number | any[any]
  avgFollowUpCallPersonPassedMonth: number | null
  avgFollowUpCallThreeMonth: number
  footerName: string | null
  footerNamePerPerson: string | null
  isLoading?: boolean
  refetchData?: () => any
  threeMonthAgoFollowUpCall: any[any]
  totalFollowUpCall: number
  onClickDetail?: Function
  mainCaption?: string
}

export const useGetFollowUpCallByMonth = (numberOfMonth = 3, headcounts) => {
  const analyticsContext = getWidgetAnalyticContext()
  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )

  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const filter = {
    account_is_deleted: false,
    activity: {
      activity_type: { medium_code: 'phone_call' },
      first_type: 'follow_up',
      status: { is_success: true },
      start_time: {
        $gte: startDate,
        $lte: endDate
      },
      user_uid: headcounts ? undefined : userUid
    },
    organization_unit
  }

  const { listActivity, refetch, loading } = useQueryActivity(
    filter,
    ['start_date_month'],
    [{ id: 'activity_uid', type: 'count' }]
  )

  const grouped = groupDataByMonth(listActivity, 'start_date_month', 'activity_uid')

  return {
    refetch,
    loading,
    ...createDashboardFormats(grouped, numberOfMonth, moment(endDate).format('YYYY-MM'), headcounts, 'Agent')
  }
}

const widgetFollowUpCallMadeHoc = (Component: any) => {
  const Wrapper = props => {
    const { headcounts } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      dataAvgPerPersonPassedMonth,
      avgPerPersonPassedMonth,
      avgPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      dataPassedMonths,
      totalLatestMonth,
      mainCaption
    } = useGetFollowUpCallByMonth(3, headcounts)

    const onClickDetail = (monthSelected = null) => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)
      const filters = {
        performer_user_uid: headcounts ? undefined : userUid,
        organization_unit,
        activity_date: convertMonthToDateRange(endDate),
        first_type: 'follow_up',
        type: { medium_code: 'phone_call' },
        status: { is_success: true }
      }

      if (monthSelected) {
        filters.activity_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'disposition', filters })
    }

    return (
      <Component
        avgFollowUpCallCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgFollowUpCallPassedMonthData={dataAvgPerPersonPassedMonth}
        avgFollowUpCallPersonPassedMonth={avgPerPersonPassedMonth}
        avgFollowUpCallThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        onClickDetail={onClickDetail}
        refetchData={() => refetch()}
        threeMonthAgoFollowUpCall={dataPassedMonths}
        totalFollowUpCall={totalLatestMonth}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetFollowUpCallMadeHoc
