/* eslint-disable  */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import moment from 'moment'

import { loadProcessedLeadsByResult } from '../telesales-dash-utils'
import { arraySum, aggregateBy } from 'crm-utils/data-utils'
import { shape, func } from 'prop-types'
import { CrmButton, CrmLabeledField } from 'crm-components'
import CrmBarChart from 'crm-components/charts/crm-bar-chart.jsx'
import CrmWidget from '../../../components/crm-widget.jsx'

const COLORS = ['#65b7f3', '#b2d28c', '#f47735', '#f67960', '#82c6da']

class WiedgetMovementsProcessedLeads extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ isLoading: val }, handler)
  }

  parseData(data) {
    const res = {}
    res.byResultName = aggregateBy(data, 'step__type__name', 'process_uid')
    res.data = data
    res.total = arraySum(data, 'process_uid')
    return res
  }
  handleRefresh = async () => {
    console.log('dash telesales general stats . handle refresh', this.props)
    try {
      // parse data
      const data = await loadProcessedLeadsByResult(this.props)
      if (data) {
        const parsed = this.parseData(data)
        this.setState(parsed)
      }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }

  handleCurrentStepClick = event => {
    console.log('handleCurrentStepClick', event)
    this.setState({ selectedStepTypeUid: event.currentTarget.id })
    this.props.onCurrentStepClick({
      process_step_type_uid: event.currentTarget.id
    })
  }

  renderBlock = (field, label, className) => {
    return this.state[field] ? (
      <CrmLabeledField label={label}>
        <div style={{ fontSize: 30, margin: 10 }} className={className}>
          {this.state[field]}
          <span style={{ fontSize: 10, margin: 3, opacity: 0.7 }}>
            {this.state[field + '_ratio']}%
          </span>
        </div>
      </CrmLabeledField>
    ) : (
      <span />
    )
  }
  _getColor = kind => {
    if (kind === 'won') {
      return '#b2d28c'
    }
    if (kind === 'lost') {
      return '#f47735'
    }
    return '#ccc'
  }
  handleClickBar = event => {
    console.log('handleClickBar', event, this.props)
    const filters = {
      step__type__name: event.name,
      ...this.props.analyticsContext.filters
    }

    if (this.props.analyticsContext.filters.activity_date) {
      filters.process_completed_time = this.props.analyticsContext.filters.activity_date
    }
    this.props.onAnalyticsRangeSelected({ type: 'lead', filters })
  }
  renderByResult = () => {
    const resultsParsed = Object.values(this.state.byResultName)
      .map(a => ({
        ...a,
        count: a.value,
        fill: this._getColor(a.process_step_kind)
      }))
      .sort((a, b) => b.value - a.value)
    console.log('CrmBarChar data', resultsParsed)
    return (
      <div style={{ height: 400 }}>
        <label>By Result</label>
        <CrmBarChart data={resultsParsed} onClickBar={this.handleClickBar} />
      </div>
    )
  }
  handleGetRawData = () => this.state.data

  renderSummary = () => (
    <div>
      {this.state ? (
        <span>Total {this.state.total} Leads Processed</span>
      ) : (
        <span />
      )}
    </div>
  )

  render() {
    console.log('Render widget movements new leads', this.state)
    return (
      <CrmWidget
        title={
          this.props.title
            ? this.props.title
            : 'Leads completed in selected period'
        }
        subtitle="Select filter Activity Date to show processes completed in this range "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-processed-leads"
      >
        <div className="row">
          <div className="col-md-6">
            {this.state.byResultName && this.renderByResult()}
          </div>
        </div>
      </CrmWidget>
    )
  }
}

WiedgetMovementsProcessedLeads.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  onCurrentStepClick: func.isRequired
}

WiedgetMovementsProcessedLeads.defaultProps = {
  dashboards: []
}

export default withApollo(WiedgetMovementsProcessedLeads)
