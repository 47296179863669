/* eslint-disable camelcase */
import React from 'react'
import { withApollo } from 'react-apollo'
import { shape, func } from 'prop-types'
import {
  allOpportunities,
  completeOpportunities,
  activeOpportunities,
  lostOpportunities,
  overdueOpportunities,
  wonOpportunities
} from './dash-opportunities-hoc'
import { GenericWidgetWeb } from '@cartrack-crm/crm-analytics'

class CtDashOpportunities extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'months'
    }
  }
  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, resolve)
    })

  render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <GenericWidgetWeb
            title="All Opportunities"
            widgetDefinition={allOpportunities}
            key={allOpportunities.code}
            analyticsContext={this.props.analyticsContext}
            layout={allOpportunities.layout}
            // onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
            noHeight
            noFilterBar
          />
        </div>
        <div className="col-md-4">
          <GenericWidgetWeb
            title="Overdue Opportunities"
            widgetDefinition={overdueOpportunities}
            key={overdueOpportunities.code}
            analyticsContext={this.props.analyticsContext}
            // onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
            noHeight
            noFilterBar
          />
        </div>
        <div className="col-md-4">
          <GenericWidgetWeb
            title="Active Opportunities"
            widgetDefinition={activeOpportunities}
            key={activeOpportunities.code}
            analyticsContext={this.props.analyticsContext}
            // onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
            noHeight
            noFilterBar
          />
        </div>
        <div className="col-md-4">
          <GenericWidgetWeb
            title="Complete Opportunities"
            widgetDefinition={completeOpportunities}
            key={completeOpportunities.code}
            analyticsContext={this.props.analyticsContext}
            // onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
            noHeight
            noFilterBar
          />
        </div>
        <div className="col-md-4">
          <GenericWidgetWeb
            title="Won Opportunities"
            widgetDefinition={wonOpportunities}
            key={wonOpportunities.code}
            analyticsContext={this.props.analyticsContext}
            // onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
            noHeight
            noFilterBar
          />
        </div>
        <div className="col-md-4">
          <GenericWidgetWeb
            title="Lost Opportunities"
            widgetDefinition={lostOpportunities}
            key={lostOpportunities.code}
            analyticsContext={this.props.analyticsContext}
            // onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
            noHeight
            noFilterBar
          />
        </div>
      </div>
    )
  }
}

CtDashOpportunities.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

export default withApollo(CtDashOpportunities)
