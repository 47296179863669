/* eslint-disable camelcase */
import React from 'react'
import moment from 'moment'
import { withApollo } from 'react-apollo'
import { shape, func } from 'prop-types'
import withLeadsPopupHoc from '../../../components/_with-leads-popup-hoc.jsx'
import { CrmButton } from 'crm-components'
import dashForecastQuery from './dash-forecast-query'
import { qlAnalyticsWidgetQuery } from 'crm-data/analytics'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip
} from 'recharts'

class CtDashForecast19 extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'months'
    }
  }
  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, resolve)
    })

  handleRefresh = async () => {
    // if (!this.props.analyticsContext.filters.activity_date) {
    //   toastr.warning('Select date range in Activity Date filter')
    //   return
    // }
    // // this.props.onRefresh()
    console.log('Refresh')
    try {
      const variables = {
        widgetDefinition: dashForecastQuery,
        filter: {}
      }
      await this.setStateAsync({ isLoading: true })
      const res = await this.props.client.query({
        query: qlAnalyticsWidgetQuery,
        variables,
        fetchPolicy: 'no-cache'
      })
      console.log('Data', res)
      if (
        res &&
        res.data &&
        res.data.dataSourceQuery &&
        res.data.dataSourceQuery.data.dataSources
      ) {
        const users = res.data.dataSourceQuery.data.dataSources.CrmUser.rawData.data.reduce(
          (a, i) => {
            return { ...a, [i.user_uid]: i }
          },
          {}
        )
        console.log('users', users)
        const data =
          res.data.dataSourceQuery.data.dataSources.OpportunitiesListQM
        if (data) {
          console.log('data', data)

          const parsedOpportunitiesData = res.data.dataSourceQuery.data.dataSources.OpportunitiesListQM.rawData.data.map(
            i => ({
              ...i,
              owner__full_name:
                users && users[i.owner__user_uid]
                  ? users[i.owner__user_uid].full_name
                  : 'no user'
            })
          )

          let summaryByMonths = parsedOpportunitiesData.reduce((a, i) => {
            const ret = { ...a }
            if (!ret[i.opportunity_due_date_month]) {
              ret[i.opportunity_due_date_month] = {
                value: 0,
                count: 1
              }
            }
            ret[i.opportunity_due_date_month].value += i.vehicle_count
            ret[i.opportunity_due_date_month].count += i.opportunity_uid
            return ret
          }, {})
          console.log('summaryByMonths', summaryByMonths)
          summaryByMonths = Object.keys(summaryByMonths).map(key => {
            return {
              value: summaryByMonths[key].value,
              count: summaryByMonths[key].count,
              month: moment(key).format('YYYY-MM-DD')
            }
          })

          summaryByMonths.sort((a, b) => {
            return moment(a.month) - moment(b.month)
          })
          this.setState({
            isLoading: false,
            opportunitiesData: parsedOpportunitiesData,
            summaryByMonths
          })
          // this.setState({
          //   currentAccountStateData: data.rawData.rows
          //     .filter(r => r.accounts_all > 0)
          //     .map(r => ({
          //       ...r,
          //       userUid: r.owner_user_uid,
          //       name: r.full_name,
          //       statuses: { ...r }
          //     }))
          // })
        }
      }
    } catch (err) {
      console.log('Error fetching data')
      await this.setStateAsync({ isLoading: false })
    }
  }
  handleStatusClick = params => {
    console.log('handleStatusClick', params)
    const detailsParams = {
      type: 'account_current_status',
      filters: {
        owner_user_uid: params.userUid,
        sales_process_step_code: params.statusCode
      }
    }
    this.props.onShowLeadsPopup(detailsParams)
  }

  handleClickMonthBar = ev => {
    console.log('handleClickMonthBar', ev)
    if (ev && ev.month) {
      this.setState({ selectedMonth: ev.month })
    }
  }
  handleClickOutsideMonth = ev => {
    this.setState({ selectedMonth: undefined })
  }
  handleClickDayUser = ev => {
    console.log('handleClickDayUser', ev)
    this.setState({ selectedMonth: undefined })
    const detailsParams = {
      type: 'opportunity',
      filters: {
        owner__user_uid: ev.owner__user_uid,
        opportunity_due_date_month: this.state.selectedMonth
      }
    }
    console.log('detailsParams', detailsParams)
    this.props.onShowLeadsPopup(detailsParams)
  }
  render() {
    console.log('CT Dash Forecast 2019', this.state)

    const selectedMonthData =
      this.state.selectedMonth && this.state.opportunitiesData
        ? this.state.opportunitiesData.filter(
            d => d.opportunity_due_date_month === this.state.selectedMonth
          )
        : []
    console.log('selectedMonthData, ', selectedMonthData)
    return (
      <div>
        <div
          style={{
            minWidth: '100px',
            width: '15%'
          }}
          className="CrmWidget-toolboxWrapper"
        >
          Forecast 2019
          <CrmButton
            onClick={this.handleRefresh}
            label="Refresh"
            isSaving={this.state.isLoading}
            type="primary"
          />
        </div>

        <div className="CrmWidget CrmWidget--fullHeight Widget CrmWidget--vMargin ">
          <div className="CrmWidget-header util-flexRow util-noMargin between-xs top-xs">
            <div className="util-flexColumn util-flexGrow">
              <div className="Widget-title CrmWidget-title util-flexRow">
                Dashboard Forecast 2019
              </div>
              {/* <WidgetFiltersBar
                analyticsContext={this.props.analyticsContext}
              /> */}
              {/* {this.state.sele<CrmButton label="Back to monthly view" /> */}
            </div>
          </div>
          <div className="Widget-content CrmWidget-content CrmWidget-content--padding">
            <div style={{ width: '100%', overflowY: 'auto' }}>
              {this.state.summaryByMonths && !this.state.selectedMonth && (
                <div
                  style={{
                    width: '100%',
                    height: 250
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={this.state.summaryByMonths}
                      // layout="horizontal"
                    >
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Bar
                        dataKey="value"
                        fill="#8884d8"
                        onClick={this.handleClickMonthBar}
                        // shape={
                        //   <IconBar vehicles label="name" value="count" onClick={onClickBar} />
                        // }
                        isAnimationActive={false}
                        barSize={50}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}
              {this.state.opportunitiesData && this.state.selectedMonth && (
                <div
                  style={{
                    width: '100%',
                    height: 500
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={selectedMonthData}
                      onClick={this.handleClickOutsideMonth}
                      // layout="horizontal"
                    >
                      <XAxis dataKey="owner__full_name" />
                      <YAxis />
                      <Tooltip />
                      <Bar
                        dataKey="vehicle_count"
                        fill="#8884d8"
                        onClick={this.handleClickDayUser}
                        // shape={
                        //   <IconBar vehicles label="name" value="count" onClick={onClickBar} />
                        // }
                        isAnimationActive={false}
                        barSize={50}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CtDashForecast19.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

export default withLeadsPopupHoc(withApollo(CtDashForecast19))
