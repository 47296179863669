import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import {
  CrmFullpageContainer,
  CrmButton,
  CrmHbox,
  FlatTable,
  CrmLoadingIcon,
  CrmCheckbox
} from 'crm-components'
import { useCrmContext } from 'crm-core/contexts/crm-context'
import { useQuery } from '@apollo/react-hooks'
import { CrmButtonGroup } from '@cartrack-crm/ui'

const scheduleListQuery = gql`
  query schedule_reports($master_uid: String!, $instance_uid: String!) {
    master(master_uid: $master_uid) {
      instance(instance_uid: $instance_uid) {
        schedule_reports {
          schedule_uid
          name
          details
          schedule_type_code
          is_disabled
          created_by_user {
            full_name
          }
        }
      }
    }
  }
`
const getDayFromNumber = (number) => {
  switch (parseInt(number)) {
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    case 7:
      return 'Sunday'
  }
  return ''
}
const convertToDisplayable = (schedule) => {
  let result = 'Every '
  switch (schedule.repeat) {
    case 'daily': {
      result += 'day'
      break
    }
    case 'monthly': {
      if (schedule.monthly) {
        if (schedule.monthly === '*') {
          result += 'everyday'
        } else {
          result +=
            ' ' +
            schedule.monthly
              .split(', ')
              .map((month) => {
                if (month % 10 == 1) return month + 'st'
                if (month % 10 == 2) return month + 'nd'
                return month + 'th'
              })
              .join(', ')
        }
      }
      break
    }
    case 'weekly': {
      if (schedule.weekly) {
        result += schedule.weekly
          .split(', ')
          .map(getDayFromNumber)
          .join(', ')
      }
      break
    }
  }
  if (schedule.hourly) {
    if (schedule.hourly === '*') {
      result += ' every hours'
    } else {
      result +=
        ' at ' +
        schedule.hourly
          .split(', ')
          .map((hour) => {
            return `${hour}:00`
          })
          .join(', ')
    }
  }
  return result
}
const prefixUri = '/crm/manager/schedule'
const columns = [
  {
    Header: 'Schedule Name',
    width: 400,
    Cell: (row) => (
      <Link to={`${prefixUri}/${row?.original?.schedule_uid}`}>
        {row?.original?.name}
      </Link>
    )
  },
  {
    Header: 'Repeat',
    Cell: (row) => {
      const schedules = row?.original?.details?.schedules
      if (schedules) {
        const display = schedules.map(convertToDisplayable).join(' AND ')
        return (
          <div>
            {schedules.map(convertToDisplayable).map((item) => (
              <p key={item}>{item}</p>
            ))}
          </div>
        )
      }
      return <div>EMPYT</div>
    }
  },
  {
    Header: 'Recipients',
    width: 80,
    Cell: (row) => row.original?.details?.recipients?.length
  },
  {
    Header: 'Enabled',
    width: 80,
    Cell: (row) => (
      <span
        style={{
          color: row.original.is_disabled ? '#ff2e00' : '#57d500',
          transition: 'all .3s ease'
        }}
      >
        &#x25cf;
      </span>
    )
  },
  {
    Header: 'Created By',
    width: 150,
    Cell: (row) => row.original.created_by_user?.full_name
  }
]
const ScheduleList = () => {
  const [isShowDisabled, setShowDisabled] = useState(true)
  const context = useCrmContext()
  const { loading, data, refetch } = useQuery(scheduleListQuery, {
    variables: {
      master_uid: context.master.master_uid,
      instance_uid: context.instance.instance_uid
    },
    fetchPolicy: 'network-only'
  })
  let scheduleReports = []
  if (!loading) {
    scheduleReports = data.master?.instance?.schedule_reports || []
  }

  const dataSources = scheduleReports.filter((item) => {
    if (isShowDisabled) return true
    return item.is_disabled !== true
  })
  return (
    <CrmFullpageContainer hasPadding vbox>
      <div className="util-paddingMd" style={{ display: 'flex' }}>
        <CrmButton
          grouped
          label="Create Schedule"
          type="primary"
          linkTo={`${prefixUri}/new`}
        />
        <CrmButton
          style={{ marginLeft: 10 }}
          grouped
          icon={`refresh ${loading ? 'fa-spin' : ''}`}
          onClick={() => refetch()}
        />
        <CrmCheckbox
          style={{ marginLeft: 10, marginTop: 6 }}
          input={{
            value: isShowDisabled,
            onChange: () => setShowDisabled(!isShowDisabled)
          }}
          label="Show Disabled"
        />
      </div>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 50
          }}
        >
          <CrmLoadingIcon />
        </div>
      )}
      <CrmHbox fill>
        {!loading && <FlatTable data={dataSources} columns={columns} />}
      </CrmHbox>
    </CrmFullpageContainer>
  )
}

export default ScheduleList
