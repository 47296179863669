import React from 'react'
import { CrmTextInput, CrmCheckbox } from '@cartrack-crm/ui'

interface Props {
  attributes: any
  onAttributeChange: Function
  attributeDefinitions: { code: string; name: string }[]
}

export const CustomAttributesEditFormInner: React.SFC<Props> = (props) => {
  const renderItem = (attributeDefinition: any, value: any) => {
    if (attributeDefinition.data_type === 'boolean') {
      return (
        <div className="col-md-4">
          <CrmCheckbox
            style={{ whiteSpace: 'no-wrap' }}
            label={attributeDefinition.name}
            input={{
              value: value,
              onChange: (e) => {
                props.onAttributeChange(attributeDefinition.code, !value)
              }
            }}
            noWrap
          />
        </div>
      )
    }
    return (
      <div className="col-md-4">
        <CrmTextInput
          input={{
            onChange: (e) => {
              props.onAttributeChange(
                attributeDefinition.code,
                e.currentTarget.value
              )
            },
            value: value
          }}
          placeholder={attributeDefinition.name}
        />
      </div>
    )
  }

  return (
    <div className="row">
      {props.attributeDefinitions &&
        props.attributeDefinitions.map((attributeDefinition) => {
          const value = props.attributes
            ? props.attributes[attributeDefinition.code]
            : undefined
          return renderItem(attributeDefinition, value)
        })}
    </div>
  )
}
