import gql from 'graphql-tag'

export const qlCampaignAllFields =
  ' campaign_uid instance_uid name code description closed_at start_date end_date transaction_type_code type marketing_campaign_uid organization_unit_uid lead_source_uid auto_launch_sales_process is_recycle details sales_channel_uid duplicate_detection_mode'

export const qlqListCampaigns = gql`
query campaigns($type: String, $limit: Int) {
  campaigns(type: $type, limit: $limit) {
   ${qlCampaignAllFields}
  }
}`

export const campaignQmQl = gql`
  query campaign_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    campaign_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        ${qlCampaignAllFields}
        user_group_uid
        process_type_uid
        is_active
        telesale_script_code
      }
    }
  }
`
