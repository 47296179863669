/* eslint-disable global-require, no-unused-vars */
import React, { PureComponent } from 'react'
import { func, shape, bool } from 'prop-types'
import { reduxForm, propTypes, change, Field } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import MeetingFormFields from './meeting/meeting-form-fields.jsx'
import PhoneCallFields from './phone-call-fields.jsx'
import NoteFields from './note-fields.jsx'
import ChatFields from './chat-fields.jsx'
import EmailFields from './email/email-fields.jsx'
import ActivityFormNewTask from './activity-form-new-task.jsx'
import {
  CrmButton,
  CrmSubmitButton,
  CrmDropdown,
  CrmValidatedField
} from 'crm-components'
import { Checkbox, RadioInput } from 'util-components'

class ActivityFormInner extends PureComponent {
  constructor(props) {
    super(props)
    console.log('ActivityFormInner create')
    console.dir(props)
    this.state = {
      hasTask: false,
      isEditing: !(props.activity && props.activity.activity_uid)
    }
  }
  handleClickEdit = () => {
    this.setState({ isEditing: true })
  }
  availableStatusesAsOptions = () =>
    this.props.availableStatuses && this.props.availableStatuses.map
      ? this.props.availableStatuses.map(st => ({
          name: st.description,
          value: st.activity_status_uid
        }))
      : []

  calculateIsTaskRequired(props) {
    console.dir(props)
    const activityStatus = props.formValues.activity_status_uid
      ? props.availableStatuses.find(
          st => st.activity_status_uid === props.formValues.activity_status_uid
        )
      : undefined
    console.log('status')
    console.dir(activityStatus)
    const isTaskRequired = true
    const newState = { ...this.state, isTaskRequired }
    if (newState.isTaskRequired && !newState.hasTask) {
      newState.hasTask = true
      this._initTask()
    }
    this.setState(newState)
  }

  _initTask() {
    console.log('initTask')
    console.dir(this.props)
    if (this.props.activityType.medium_code === 'phone_call') {
      if (
        this.props.formValues &&
        (!this.props.formValues.form || !this.props.formValues.form.task_name)
      ) {
        this.props.dispatch(change(this.props.form, 'task.name', 'Call Again'))
      }
    }
    console.log('new task - assign to me')
    this.props.dispatch(
      change(
        this.props.form,
        'task.assign_to_user_uid',
        this.props.currentUser.user_uid
      )
    )
  }
  handleAddQualification = event => {
    const hasQualification = !this.state.hasQualification
    this.setState({ ...this.state, hasQualification })
    this.props.dispatch(
      change(this.props.form, 'has_qualification', hasQualification)
    )
  }

  handleQualificationChange = value => {
    change(this.props.form, 'new_qualification_uid', value)
  }

  handleAddTask = event => {
    const newVal = !this.state.hasTask
    this.setState({ ...this.state, hasTask: newVal })
    this.props.dispatch(change(this.props.form, 'has_task', newVal))
    this._initTask(this.props)
  }

  _renderActivityForm() {
    const { activityType, activity, account } = this.props
    if (activityType.form_code) {
      if (activityType.form_code === 'live_call') {
        return <div>Not supported</div>
      }
    }
    if (activityType.medium_code === 'meeting') {
      return (
        <React.Fragment>
          <MeetingFormFields
            activity={activity}
            activityType={activityType}
            account={account}
            formValues={this.props.formValues}
            onAddNewAddress={this.props.onAddNewAddress}
            addressSaving={this.props.addressSaving}
            isEditing={this.state.isEditing}
            onGotoMinutes={this.props.onGotoMinutes}
            onChanged={this.props.onChanged}
          />
        </React.Fragment>
      )
    }
    if (activityType.medium_code === 'phone_call') {
      return (
        <PhoneCallFields
          account={this.props.account}
          activity={activity}
          activityType={activityType}
          isEditing={this.state.isEditing}
          callStatuses={this.props.availableStatuses}
          dispatch={this.props.dispatch}
          form={this.props.form}
          formValues={this.props.formValues}
        />
      )
    }
    if (activityType.medium_code === 'note') {
      return (
        <NoteFields
          activity={activity}
          activityType={activityType}
          isEditing={this.state.isEditing}
        />
      )
    }
    if (activityType.medium_code === 'communicator') {
      return (
        <ChatFields
          activity={activity}
          activityType={activityType}
          isEditing={this.state.isEditing}
        />
      )
    }
    if (activityType.medium_code === 'email') {
      return (
        <EmailFields
          form={this.props.form}
          account={this.props.account}
          activity={activity}
          activityType={activityType}
          isEditing={this.state.isEditing}
          sendEmailFromQuotation={this.props.sendEmailFromQuotation}
        />
      )
    }
  }
  render() {
    const { handleSubmit, activity, activityType, isSaving } = this.props
    console.log('render activity form inner', this.props)
    const statusesOptions = this.availableStatusesAsOptions()
    const isNew = !activity.activity_uid
    const isFinalStatus =
      activity.activity_status// && activity.activity_status.is_result <-- related to CRM-4284, pending status was remove, which having status will consider final status (not allow to edit)
    const newStatusProps = {
      placeholder: 'Update activity status',
      options: statusesOptions.slice()
    }
    const showNewStatusSelector =
      activityType.medium_code !== 'phone_call' &&
      activityType.medium_code !== 'note' &&
      activityType.medium_code !== 'communicator' &&
      activityType.medium_code !== 'email'

    const nextActionRenderer = props => (
      <RadioInput name="next_action" {...props} />
    )
    return (
      <div>
        <form onSubmit={handleSubmit} className="CrmForm">
          {activityType && <div>{this._renderActivityForm()}</div>}
          <fieldset
            className="CrmForm-form-fieldset"
            disabled={!this.state.isEditing}
          >
            {isNew && showNewStatusSelector && (
              <span>
                <CrmValidatedField
                  name="activity_status_uid"
                  component={CrmDropdown}
                  componentProps={{
                    labelField: 'name',
                    placeholder: (
                      <FormattedMessage
                        id="crm.ui.activity.form.new_status"
                        defaultMessage="New status"
                      />
                    ),
                    options: statusesOptions
                  }}
                  isRequired
                />
              </span>
            )}
          </fieldset>

          <fieldset
            className="CrmForm-form-fieldset"
            disabled={isSaving || !this.props.valid}
          >
            {!this.state.isEditing && (
              <div className="row util-marginTop">
                <div className="col-md-12 util-alignRight">
                  <CrmButton
                    icon="close"
                    label={
                      <FormattedMessage
                        id="crm.ui.button.close"
                        defaultMessage="Close"
                      />
                    }
                    grouped
                    onClick={this.props.onCancel}
                  />
                  {!isFinalStatus && (
                    <CrmButton
                      icon="edit"
                      label={
                        <FormattedMessage
                          id="crm.ui.button.edit"
                          defaultMessage="Edit"
                        />
                      }
                      grouped
                      onClick={this.handleClickEdit}
                    />
                  )}
                </div>
              </div>
            )}
          </fieldset>

          {!isFinalStatus && this.state.isEditing && (
            <div className="row">
              <div className="col-md-12 util-alignRight">
                {!this.props.valid && (
                  <span className="util-marginRight">
                    <FormattedMessage
                      id="crm.ui.activity.form.please_fill_required_field"
                      defaultMessage="Please fill all required fields"
                    />
                  </span>
                )}

                <CrmButton
                  label={
                    <FormattedMessage
                      id="crm.ui.button.cancel"
                      defaultMessage="Cancel"
                    />
                  }
                  type="secondary"
                  onClick={this.props.onCancel}
                  grouped
                />
                <CrmButton
                  label={
                    isSaving ? (
                      <FormattedMessage
                        id="crm.ui.button.saving"
                        defaultMessage="Saving.."
                      />
                    ) : (
                      <FormattedMessage
                        id="crm.ui.button.save"
                        defaultMessage="Save"
                      />
                    )
                  }
                  type="submit"
                  enable={!isSaving && this.props.valid}
                  grouped
                />
              </div>
            </div>
           )}
        </form>
      </div>
    )
  }
}

ActivityFormInner.propTypes = {
  ...propTypes,
  account: shape({}).isRequired,
  activity: shape({}).isRequired,
  activityType: shape({}).isRequired,
  handleSubmit: func.isRequired,
  onAddNewAddress: func.isRequired,
  isSaving: bool.isRequired,
  dispatch: func.isRequired,
  formValues: shape({}),
  currentUser: shape({}).isRequired,
  onCancel: func,
  addressSaving: bool.isRequired,
  onGotoMinutes: func.isRequired
}

ActivityFormInner.defaultProps = {
  onCancel: () => {},
  formValues: undefined
}

export default reduxForm({
  enableReinitialize: true
})(ActivityFormInner)
