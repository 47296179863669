/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { shape, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import gql from 'graphql-tag'
import { graphql, compose } from 'react-apollo'
import { toastr } from 'react-redux-toastr'

import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import AccountProcesses from './_account-processes.jsx'
import { msToUTCTimestamp } from 'util-functions/time-utils'
import FeedList from 'crm-modules/feed/feed-list.jsx'
import AdminForceUpdateSalesStatusWidget, {
  salesStatusOptions
} from './admin-force-update-sales-status-widget.jsx'

import {
  CrmButton,
  FlatTable,
  FormattedDateTime,
  CrmVbox,
  CrmDropdown,
  CrmDayPickerInput,
  CrmUserSelect,
  CrmInfoBlock
} from 'crm-components'
import { LoadingIcon } from 'util-components'
import AccountPageRightSidePanel from '../account-page-right-side-panel.jsx'
import AccountHistoryList from './_account-history-list.jsx'

const columns = [
  {
    Header: (
      <FormattedMessage
        id="crm.ui.account.history.from"
        defaultMessage="From"
      />
    ),
    Cell: row =>
      row.original.valid_from ? (
        <FormattedDateTime value={row.original.valid_from} />
      ) : (
        <div />
      ),
    accessor: 'valid_from'
  },
  {
    Header: (
      <FormattedMessage
        id="crm.ui.account.history.till"
        defaultMessage="Till"
      />
    ),
    Cell: row =>
      row.original.valid_till ? (
        <FormattedDateTime value={row.original.valid_till} />
      ) : (
        <div />
      ),
    accessor: 'valid_till'
  },
  {
    Header: (
      <FormattedMessage
        id="crm.ui.account.history.status"
        defaultMessage="Status"
      />
    ),
    accessor: 'account_status_code'
  },
  {
    Header: (
      <FormattedMessage
        id="crm.ui.account.history.owner"
        defaultMessage="Owner User"
      />
    ),
    Cell: row => (
      <div>
        {row.original.owner_user ? row.original.owner_user.full_name : ''}
      </div>
    ),
    accessor: 'owner_user'
  },
  {
    Header: (
      <FormattedMessage
        id="crm.history.owner_group"
        defaultMessage="Owner Group"
      />
    ),
    Cell: row => (
      <div>{row.original.owner_pool ? row.original.owner_pool.name : ''}</div>
    ),
    accessor: 'owner_pool'
  },
  {
    Header: (
      <FormattedMessage
        id="crm.ui.account.history.qualification"
        defaultMessage="Qualification"
      />
    ),
    Cell: row => (
      <div>
        {row.original.qualification_type
          ? row.original.qualification_type.name
          : ''}
      </div>
    ),
    accessor: 'qualification_type'
  },
  {
    Header: (
      <FormattedMessage
        id="crm.ui.account.history.created_by"
        defaultMessage="Created by"
      />
    ),
    Cell: row => (
      <div>
        {row.original.created_by_user
          ? row.original.created_by_user.full_name
          : ''}
      </div>
    )
  },
  {
    Header: (
      <FormattedMessage id="crm.history.process" defaultMessage="Process" />
    ),
    accessor: 'prospect_process_uid'
  }
]

const salesStatusHistoryColumns = [
  {
    accessor: 'code',
    Header: <FormattedMessage id="crm.history.code" defaultMessage="Code" />
  },
  {
    Header: (
      <FormattedMessage
        id="crm.ui.account.history.from"
        defaultMessage="From"
      />
    ),
    Cell: row =>
      row.original.validFrom ? (
        <FormattedDateTime value={row.original.validFrom} />
      ) : (
        <div />
      ),
    accessor: 'valid_from'
  },
  {
    Header: (
      <FormattedMessage
        id="crm.history.changed_by"
        defaultMessage="Changed by"
      />
    ),
    accessor: 'sourceEvent.createdByUser.name',
    accessorFn: row =>
      row.sourceEvent && row.sourceEvent.createdByUser
        ? row.sourceEvent.createdByUser.name
        : undefined,
    Cell: row => {
      return row.original.sourceEvent && row.original.sourceEvent.createdByUser
        ? row.original.sourceEvent.createdByUser.name
        : '-'
    }
  },
  {
    Header: (
      <FormattedMessage
        id="crm.history.changed_at"
        defaultMessage="Changed at"
      />
    ),
    accessor: 'sourceEvent.ts',
    accessorFn: row => (row.sourceEvent ? row.sourceEvent.ts : undefined),
    Cell: row => {
      return row.original.sourceEvent && row.original.sourceEvent.ts ? (
        <FormattedDateTime value={row.original.sourceEvent.ts} />
      ) : (
        '-'
      )
    }
  }
]
class AccountHistoryModalInner extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 'history',
      tabs: [
        {
          name: (
            <FormattedMessage
              id="crm.history.history"
              defaultMessage="History"
            />
          ),
          value: 'history'
        },
        {
          name: (
            <FormattedMessage
              id="crm.history.account_feed"
              defaultMessage="Account Feed"
            />
          ),
          value: 'feed'
        },
        {
          name: (
            <FormattedMessage
              id="crm.history.processes"
              defaultMessage="Processes"
            />
          ),
          value: 'processes'
        },
        {
          name: (
            <FormattedMessage
              id="crm.history.owners_history"
              defaultMessage="Owners history"
            />
          ),
          value: 'ownershipHistory'
        },
        {
          name: (
            <FormattedMessage id="crm.history.events" defaultMessage="Events" />
          ),
          value: 'events'
        }
      ]
    }
    this.options = salesStatusOptions
  }

  componentDidMount() {
    if (this.props.showOnlyOpportunityEvents === true) {
      this.setState({ selectedTab: 'events' })
    }
  }

  renderOwnershipHistory = () => {
    const data = this.props.account ? this.props.account.owners : undefined
    let flatten = []
    if (data) {
      Object.keys(data).forEach(role => {
        if (data[role] && data[role].history) {
          flatten = [...flatten, ...data[role].history]
        }
      })
    }
    console.log('Flatten data', flatten)
    const columns = [
      {
        Header: (
          <FormattedMessage id="crm.history.role" defaultMessage="Role" />
        ),
        accessor: 'roleCode'
      },
      {
        Header: (
          <FormattedMessage
            id="crm.history.Valid From"
            defaultMessage="Valid From"
          />
        ),
        accessor: 'validFrom'
      },
      {
        Header: (
          <FormattedMessage
            id="crm.global.account.type"
            defaultMessage="Type"
          />
        ),
        accessor: 'ownerType'
      },
      {
        Header: <FormattedMessage id="global.name" defaultMessage="Name" />,
        accessor: 'name'
      },
      {
        Header: (
          <FormattedMessage
            id="crm.history.changed_by"
            defaultMessage="Changed by"
          />
        ),
        accessor: 'sourceEvent.createdByUser.name',
        accessorFn: row =>
          row.sourceEvent && row.sourceEvent.createdByUser
            ? row.sourceEvent.createdByUser.name
            : undefined,
        Cell: row => {
          return row.original.sourceEvent &&
            row.original.sourceEvent.createdByUser
            ? row.original.sourceEvent.createdByUser.name
            : '-'
        }
      },
      {
        Header: (
          <FormattedMessage
            id="crm.history.changed_at"
            defaultMessage="Changed at"
          />
        ),
        accessor: 'sourceEvent.ts',
        accessorFn: row => (row.sourceEvent ? row.sourceEvent.ts : undefined),
        Cell: row => {
          return row.original.sourceEvent && row.original.sourceEvent.ts ? (
            <FormattedDateTime value={row.original.sourceEvent.ts} />
          ) : (
            '-'
          )
        }
      }
    ]
    return (
      <div className="util-paddingMd">
        {data ? (
          <FlatTable bordered columns={columns} data={flatten} />
        ) : (
          "Can't find ownership history data for this account"
        )}
      </div>
    )
  }

  renderFeed = () => (
    <div className="util-flexGrow util-paddingMd">
      <FeedList
        accountUid={this.props.account.account_uid}
        filter={{ account_uid: this.props.account.account_uid }}
      />
    </div>
  )
  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  handleNewOwnerSelect = value => {
    this.setState({ newOwner: value })
  }
  handleNewStatusChange = value => {
    // console.log('handleNewStatusChange')
    this.setState({ newStatus: value })
  }
  handleNewStatusFromChange = value => {
    this.setState({ newStatusValidFrom: value })
  }
  handleTabClick = event => {
    const selectedTab = event.currentTarget.id
    // console.log('handleTabClick', selectedTab)
    this.setState({ selectedTab })
  }

  handleRowClick = row => {
    // console.log('handleRowClick', row)
    this.setState({
      selectedRow: row,
      alignDateValidFrom: row.valid_from,
      alignDateValidTill: row.valid_till,
      alignOwner: row.owner_uid
    })
  }
  handleAlignDateFromChange = alignDateValidFrom => {
    this.setState({ alignDateValidFrom })
  }

  handleAlignDateTillChange = alignDateValidTill => {
    this.setState({ alignDateValidTill })
  }
  handleAlignOwnerSelect = alignOwner => {
    this.setState({ alignOwner })
  }
  handleAdminSaveNewValidFromDate = async () => {
    // console.log('handleAdminSaveNewValidFromDate', this.state, this.props)
    const command = {
      type: 'account.adminAlignStatusValidFromDate',
      aggregate_type: 'account',
      aggregate_uid: this.state.selectedRow.account_uid,
      payload: {
        account_status_uid: this.state.selectedRow.account_status_uid,
        valid_from: this.state.alignDateValidFrom
      }
    }
    try {
      this.setStateAsync({ isSavingAdminValidFrom: true })
      await this.props.onRunCommand(command, {})
      // console.log('Result', res)
      this.props.data.refetch()
      this.setStateAsync({ isSavingAdminValidFrom: false })
      toastr.success('Valid From date updated - refreshing')
    } catch (err) {
      this.setStateAsync({ isSavingAdminValidFrom: false })
      toastr.error('Problem saving Valid From date')
    }
    // console.log('COmmand', command)
  }

  handleAdminSaveNewValidTillDate = async () => {
    // console.log('handleAdminSaveNewValidTillDate', this.state, this.props)
    const command = {
      type: 'account.adminAlignStatusValidTillDate',
      aggregate_type: 'account',
      aggregate_uid: this.state.selectedRow.account_uid,
      payload: {
        account_status_uid: this.state.selectedRow.account_status_uid,
        valid_till: this.state.alignDateValidTill
      }
    }
    try {
      this.setStateAsync({ isSavingAdminValidTill: true })
      await this.props.onRunCommand(command, {})
      // console.log('Result', res)
      this.props.data.refetch()
      this.setStateAsync({ isSavingAdminValidTill: false })
      toastr.success('Valid Till date updated - refreshing')
    } catch (err) {
      this.setStateAsync({ isSavingAdminValidTill: false })
      toastr.error('Problem saving Valid Till date')
    }
    // console.log('COmmand', command)
  }

  handleAdminAlignOwner = async () => {
    // console.log('adminAlignStatusOwner', this.state, this.props)
    const command = {
      type: 'account.adminAlignStatusOwner',
      aggregate_type: 'account',
      aggregate_uid: this.state.selectedRow.account_uid,
      payload: {
        account_status_uid: this.state.selectedRow.account_status_uid,
        owner_user_uid: this.state.alignOwner
      }
    }
    try {
      this.setStateAsync({ isSavingAdminValidTill: true })
      await this.props.onRunCommand(command, {})
      // console.log('Result', res)
      this.props.data.refetch()
      this.setStateAsync({ isSavingAdminValidTill: false })
      toastr.success('Align owner updated - refreshing')
    } catch (err) {
      this.setStateAsync({ isSavingAdminValidTill: false })
      toastr.error('Problem saving owner date')
    }
    // console.log('COmmand', command)
  }

  renderAdminAlignSelectedRow = () => {
    return (
      <React.Fragment>
        <div>
          <FormattedMessage
            id="crm.ui.account.history.admin_align_status_and_owner_change_date"
            defaultMessage="Admin - align status and owner change date"
          />
        </div>

        <div className="row">
          {/* <div className="col-md-3">
            <CrmLabeledField label="Current date FROM">
              <FormattedDate value={this.state.selectedRow.valid_from} />{' '}
              <FormattedTime value={this.state.selectedRow.valid_from} />
            </CrmLabeledField>
          </div> */}
          <div className="col-md-3">
            <CrmDayPickerInput
              placeholder={
                <FormattedMessage
                  id="crm.ui.account.history.valid_from"
                  defaultMessage="Valid From"
                />
              }
              input={{
                value: this.state.alignDateValidFrom,
                onChange: this.handleAlignDateFromChange
              }}
            />
          </div>
          <div className="col-md-2">
            <CrmButton
              label={
                <FormattedMessage
                  id="crm.button.save_valid_till"
                  defaultMessage="Save Valid Till"
                />
              }
              small
              type="primary"
              disabled={!this.state.alignDateValidFrom}
              onClick={this.handleAdminSaveNewValidFromDate}
              isSaving={this.state.isSavingAdminValidFrom}
            />
          </div>

          <div className="col-md-3">
            <CrmDayPickerInput
              placeholder={
                <FormattedMessage
                  id="crm.ui.account.history.valid_from"
                  defaultMessage="Valid Till"
                />
              }
              input={{
                value: this.state.alignDateValidTill,
                onChange: this.handleAlignDateTillChange
              }}
            />
          </div>
          <div className="col-md-2">
            <CrmButton
              label={
                <FormattedMessage
                  id="crm.button.save_valid_from"
                  defaultMessage="Save Valid From"
                />
              }
              type="primary"
              small
              disabled={!this.state.alignDateValidTill}
              onClick={this.handleAdminSaveNewValidTillDate}
              isSaving={this.state.isSavingAdminValidTill}
            />
          </div>

          <div className="col-md-3">
            <CrmUserSelect
              input={{
                value: this.state.alignOwner,
                onChange: this.handleAlignOwnerSelect
              }}
              permissionCode="all"
            />
          </div>
          <div className="col-md-2">
            <CrmButton
              label={
                <FormattedMessage
                  id="crm.button.save_owner"
                  defaultMessage="Save Owner"
                />
              }
              type="primary"
              small
              disabled={!this.state.alignOwner}
              onClick={this.handleAdminAlignOwner}
              isSaving={this.state.isSavingAlignOwner}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
  render() {
    console.log('AccountHistoryModal', this.props)
    // console.dir(this.props)
    const tableData =
      this.props.data && this.props.data.account_status_history
        ? this.props.data.account_status_history.map(r => ({
            ...r,
            id: r.account_status_uid
          }))
        : undefined

    const mappedOwnersHistory =
      this.props.account && this.props.account.data
        ? Object.keys(this.props.account.data.owners).reduce((a, roleCode) => {
            console.log('map owner role ', roleCode)
            const ret = [
              ...a,
              ...(this.props.account.data.owners[roleCode].history
                ? this.props.account.data.owners[roleCode].history.map(h => ({
                    ...h
                  }))
                : [])
            ]
            return ret
          }, [])
        : []
    console.log('mappedOwnersHistory', mappedOwnersHistory)
    return (
      <CrmVbox className=" util-flexColumn util-fullHeight">
        <div>
          <div className="Navbar-innerNav Navbar--blue">
            {this.state.tabs.map(tab => (
              <div
                key={tab.value}
                className={`Navbar-innerNav-tab ${
                  this.state.selectedTab === tab.value ? 'is-active' : ''
                }`}
                id={tab.value}
                onClick={this.handleTabClick}
              >
                {tab.name}
              </div>
            ))}
          </div>
        </div>
        {this.state.selectedTab === 'history' && (
          <div className="util-flexGrow util-paddingMd">
            <div>
              <h4>
                <FormattedMessage
                  id="crm.ui.account.history.account_history"
                  defaultMessage="Account History (new)"
                />
              </h4>
              {mappedOwnersHistory ? (
                <FlatTable
                  bordered
                  data={mappedOwnersHistory}
                  columns={salesStatusHistoryColumns}
                  onRowClick={this.handleRowClick}
                  sortField="valid_from"
                  sortOrder="desc"
                />
              ) : (
                <div>
                  <FormattedMessage
                    id="crm.account.history.no_data_about_status_history"
                    defaultMessage="No data about status history"
                  />
                </div>
              )}
            </div>
            <AdminForceUpdateSalesStatusWidget account={this.props.account} />
            <div>
              <CrmInfoBlock warning>
                <FormattedMessage
                  id="crm.account.history.elements_below_are_deprecated_and_will_be_removed_in_future_versions"
                  defaultMessage="Elements below are deprecated and will be removed in future
                    versions"
                />
              </CrmInfoBlock>
              <h4>
                <FormattedMessage
                  id="crm.ui.account.history.account_history"
                  defaultMessage="Account History (old, will be removed):"
                />
              </h4>
              {this.props.data && this.props.data.loading && <LoadingIcon />}
              {tableData && (
                <FlatTable
                  bordered
                  data={tableData}
                  columns={columns}
                  onRowClick={this.handleRowClick}
                  sortField="valid_from"
                  sortOrder="desc"
                />
              )}
            </div>
          </div>
        )}
        {this.state.selectedTab === 'feed' && this.renderFeed()}
        {this.state.selectedTab === 'processes' && (
          <div>
            <AccountProcesses
              account={this.props.account}
              isManager={this.props.isManager}
            />
          </div>
        )}
        {this.state.selectedTab === 'ownershipHistory' &&
          this.renderOwnershipHistory()}

        {this.state.selectedTab === 'events' && (
          <div className="util-fullHeight">
            <AccountHistoryList
              accountUid={this.props.account.account_uid}
              account={this.props.account}
              showOnlyOpportunityEvents={this.props.showOnlyOpportunityEvents}
            />
          </div>
        )}
      </CrmVbox>
    )
  }
}

const accountStatusHistoryQl = gql`
  query account_status_history($account_uid: String!) {
    account_status_history(account_uid: $account_uid) {
      account_status_uid
      account_uid
      account_status_code
      owner_user {
        user_uid
        full_name
      }
      owner_pool {
        name
      }
      valid_from
      valid_till
      created_by_user_uid
      created_by_user {
        user_uid
        full_name
      }
      qualification_type {
        name
      }
      prospect_process_uid
    }
  }
`

const qlOptions = {
  options: ownProps => ({
    variables: { account_uid: ownProps.account.account_uid } // eslint-disable-line
  }),
  skip: ownProps =>
    !ownProps.account ||
    !ownProps.account.account_uid ||
    ownProps.account.account_uid === 'new',
  props: ({ data, ownProps }) => ({ data, ...ownProps })
}
const AccountHistoryModalInnerQL = compose(
  graphql(accountStatusHistoryQl, {
    ...qlOptions
  }),
  crmGenericCommandQlHoc
)(AccountHistoryModalInner)

AccountHistoryModalInner.defaultProps = {}

AccountHistoryModalInner.propTypes = {
  account: shape({}).isRequired, // eslint-disable-line
  data: shape({}).isRequired,
  mutate: func.isRequired,
  isManager: bool.isRequired,
  ...cqCommandPropTypes
}

const AccountHistoryModal = props => (
  // <CrmModal
  //   isOpen={props.isOpen}
  //   rightSideModal
  //   title={
  //     <FormattedMessage
  //       id="crm.ui.account.history.account_history"
  //       defaultMessage="Account History"
  //     />
  //   }
  //   onClose={props.onClose}
  // >
  <AccountPageRightSidePanel
    isOpen={props.isOpen}
    onClickOutside={props.onClose}
    preventClosingOnClickOutside={false}
  >
    <AccountHistoryModalInnerQL {...props} />
  </AccountPageRightSidePanel>
)
AccountHistoryModal.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  showOnlyOpportunityEvents: bool
}
AccountHistoryModal.defaultProps = {
  isOpen: false,
  showOnlyOpportunityEvents: false
}

export default AccountHistoryModal
