import React, { useContext, useState, useRef, useEffect } from 'react'
import {
  CrmToastrWrapper,
  CrmToastrTitle,
  CrmToastrMessage,
  CrmToastrGroupBtn,
  CrmToastrButton,
  CrmToastrDetailTitle,
  ExpandIcon,
  CrmToastrLineWrapper,
  CrmToastrLine,
  CrmToastrContent,
  CrmToastrProgressBar
} from './crm-toastr.style'
import { ToastrContext, CrmToastrPosition, CrmToastrType } from '@cartrack-crm/ui/src/toastrs'
import classNames from 'classnames'

export const CrmToastr = () => {
  const [isShowDetail, toggleDetail] = useState(true)
  const { state, setState } = useContext(ToastrContext)
  const { isShowToast, title, type, options } = state
  const hasOptions = options !== undefined
  const isShowMessageBlock = hasOptions && options.message
  const isShowGroupButton = hasOptions && options.btnPrimaryTitle
  const isShowDetailContent = hasOptions && options.textDetailTitle
  const [isShowLoadingIcon, setIsShowLoadingIcon] = useState(false)

  const position = options && options.position ? options.position : CrmToastrPosition.topRight

  const getClassName = (type: CrmToastrType) =>
    [CrmToastrType.info, CrmToastrType.error, CrmToastrType.success, CrmToastrType.warning].includes(type)
      ? type
      : CrmToastrType.success

  const getPositionClassName = (position: CrmToastrPosition) =>
    [
      CrmToastrPosition.topLeft,
      CrmToastrPosition.topMiddle,
      CrmToastrPosition.topRight,
      CrmToastrPosition.middleLeft,
      CrmToastrPosition.middle,
      CrmToastrPosition.middleRight,
      CrmToastrPosition.bottomLeft,
      CrmToastrPosition.bottomMiddle,
      CrmToastrPosition.bottomRight
    ].includes(position)
      ? position
      : CrmToastrPosition.topRight

  const handleSecondaryBtn = () => {
    const haveOnBtnSecondary = hasOptions && options.onBtnSecondary
    haveOnBtnSecondary ? options.onBtnSecondary() : setState({ ...state, isShowToast: false })
  }

  const handleCloseToarstr = () => {
    if (options && options.timeToShow !== null) {
      setState({ ...state, isShowToast: false })
    }
  }

  const toastrTypeClass = getClassName(type)
  const positionClassName = getPositionClassName(position)
  const isShowProgress = options && options.timeToShow !== null

  const handleClickPrimaryBtn = async () => {
    if (options?.onBtnPrimary) {
      setIsShowLoadingIcon(true)
      const res = await options.onBtnPrimary()
      setIsShowLoadingIcon(false)
      return res
    }
  }

  return (
    isShowToast && (
      <CrmToastrWrapper position={positionClassName} type={toastrTypeClass} onClick={handleCloseToarstr}>
        <CrmToastrTitle titleOnly={!isShowGroupButton && !isShowMessageBlock}>{title}</CrmToastrTitle>
        {isShowMessageBlock && <CrmToastrMessage>{options.message}</CrmToastrMessage>}
        {isShowGroupButton && (
          <CrmToastrGroupBtn isShowMessageBlock={isShowGroupButton}>
            <CrmToastrButton
              label={options.btnPrimaryTitle}
              type="submit"
              onClick={handleClickPrimaryBtn}
              isSaving={isShowLoadingIcon}
            />
            {options?.btnSecondaryTitle && (
              <CrmToastrButton label={options.btnSecondaryTitle} onClick={handleSecondaryBtn} />
            )}
          </CrmToastrGroupBtn>
        )}
        {isShowDetailContent && (
          <React.Fragment>
            <CrmToastrDetailTitle type={toastrTypeClass}>
              {options.textDetailTitle}
              <ExpandIcon
                className={classNames('fa', { 'fa-caret-up': isShowDetail, 'fa-caret-down': !isShowDetail })}
                onClick={() => toggleDetail(!isShowDetail)}
              />
              {!isShowDetail && (
                <CrmToastrLineWrapper className="util-relative">
                  <CrmToastrLine />
                </CrmToastrLineWrapper>
              )}
            </CrmToastrDetailTitle>
            <CrmToastrContent>{options.textDetailContent}</CrmToastrContent>
          </React.Fragment>
        )}
        {isShowProgress && <ProgressBar barType={toastrTypeClass} />}
      </CrmToastrWrapper>
    )
  )
}

export const useInterval = (callback, delay) => {
  const savedCallback: any = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export interface ProgressBarProps {
  barType: CrmToastrType
}

export const ProgressBar: React.SFC<ProgressBarProps> = props => {
  const { state } = useContext(ToastrContext)
  const [progress, setProgress] = useState(0)
  const [counter, setTimer] = useState(0)
  const { options } = state
  const timeToShow = options.timeToShow
  const timeLoop = 100
  const setProgressBar = () => {
    setTimer(counter + timeLoop)
    setProgress(((counter + timeLoop) / (timeToShow - timeLoop)) * 100)
  }

  useInterval(setProgressBar, timeLoop)

  return <CrmToastrProgressBar type={props.barType} progress={`${progress}%`} />
}
