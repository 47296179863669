/* eslint-disable import/no-extraneous-dependencies, no-unused-vars, import/no-unresolved, camelcase */
import { toastr } from 'react-redux-toastr'
import { actions } from 'crm-duxs/tasks-reducer'
import tasksApi from 'crm-api/tasks-api'
import { getUser } from 'crm-duxs/crm-reducer'
import { accountFieldsUpdated } from 'crm-duxs/account-reducer'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { msToUTCTimestamp } from 'util-functions/time-utils'

export function prepareTaskForServer(task) {
  const ltask = { ...task }
  ltask.assignments = [
    {
      assignee_type: 'user',
      user_uid: task.assign_to_user_uid,
      due_date: msToUTCTimestamp(task.due_date)
    }
  ]
  delete ltask.due_date
  delete ltask.assign_to_user_uid
  return ltask
}

function* createTask(action) {
  // Task is in nested 'task' attribute - due to using FormSection
  // Convert opportunity to server structre
  const task = prepareTaskForServer(action.payload.task.task)
  try {
    const createdTask = yield call(tasksApi.createTask, task)
    yield put({
      type: actions.createTaskSuccess.type,
      payload: { task: createdTask }
    })
    if (action.payload.onSuccess) {
      action.payload.onSuccess({ task: createdTask })
    }
    // Notify accounts that field has been updates
    yield put(accountFieldsUpdated(task.account_uid, ['tasks']))
    yield put(toastr.success('Task has been saved'))
  } catch (err) {
    toastr.error('Problem creating task')
  }
}

function* listMyTasks() {
  console.log('list My Tasks Saga')
  const tasks = yield call(tasksApi.listMyTasks)
  console.log('hot tasks')
  console.dir(tasks)
  yield put({
    type: actions.listMyTasksSuccess.type,
    payload: { tasks }
  })
}

function* listAllTasks() {
  console.log('list All Tasks Saga')
  const tasks = yield call(tasksApi.listAllTasks)
  yield put({
    type: actions.listAllTasksSuccess.type,
    payload: { tasks }
  })
}

export default function* tasksSaga() {
  yield takeLatest(actions.createTask.type, createTask)
  yield takeLatest(actions.listMyTasks.type, listMyTasks)
  yield takeLatest(actions.listAllTasks.type, listAllTasks)
}
