import React from 'react'
import { TableConfigSectionFields } from './components/table-section-fields'

export const LayoutConfigPivot = (props) => {
  console.log('LayoutConfigPivot', props)
  return (
    <div>
      <h6>Pivot table settings</h6>
      <TableConfigSectionFields
        {...props}
        fieldsProperty="rows"
        title="Rows"
        maxCount={1}
      />
      <TableConfigSectionFields
        {...props}
        fieldsProperty="columns"
        title="Columns"
        maxCount={1}
      />
      <TableConfigSectionFields
        {...props}
        fieldsProperty="values"
        title="Values"
        maxCount={1}
      />
    </div>
  )
}
