// Individual letter widths at font-size: 1px;
// Ridiculous calculations https://jsfiddle.net/zdoceyzn/

export const robotoRegular = {
  '0': 0.546875,
  '1': 0.546875,
  '2': 0.546875,
  '3': 0.546875,
  '4': 0.546875,
  '5': 0.546875,
  '6': 0.546875,
  '7': 0.546875,
  '8': 0.546875,
  '9': 0.546875,
  a: 0.53125,
  b: 0.546875,
  c: 0.515625,
  d: 0.5625,
  e: 0.515625,
  f: 0.34375,
  g: 0.546875,
  h: 0.546875,
  i: 0.234375,
  j: 0.234375,
  k: 0.5,
  l: 0.234375,
  m: 0.875,
  n: 0.546875,
  o: 0.5625,
  p: 0.546875,
  q: 0.5625,
  r: 0.328125,
  s: 0.515625,
  t: 0.3125,
  u: 0.546875,
  v: 0.484375,
  w: 0.75,
  x: 0.484375,
  y: 0.46875,
  z: 0.484375,
  ' ': 0,
  '!': 0.25,
  '@': 0.890625,
  '#': 0.609375,
  $: 0.546875,
  '%': 0.71875,
  '^': 0.40625,
  '&': 0.609375,
  '*': 0.421875,
  '(': 0.328125,
  ')': 0.34375,
  _: 0.4375,
  '-': 0.265625,
  '/': 0.40625,
  '\\': 0.40625,
  '{': 0.328125,
  '}': 0.328125,
  '[': 0.25,
  ']': 0.25,
  '<': 0.5,
  '>': 0.515625,
  ',': 0.1875,
  '.': 0.25,
  A: 0.640625,
  B: 0.609375,
  C: 0.640625,
  D: 0.640625,
  E: 0.5625,
  F: 0.546875,
  G: 0.671875,
  H: 0.703125,
  I: 0.265625,
  J: 0.546875,
  K: 0.625,
  L: 0.53125,
  M: 0.859375,
  N: 0.703125,
  O: 0.6875,
  P: 0.625,
  Q: 0.6875,
  R: 0.609375,
  S: 0.578125,
  T: 0.59375,
  U: 0.640625,
  V: 0.625,
  W: 0.875,
  X: 0.625,
  Y: 0.59375,
  Z: 0.59375
}

export const robotoCondensed = {
  '0': 0.446875,
  '1': 0.446875,
  '2': 0.446875,
  '3': 0.446875,
  '4': 0.446875,
  '5': 0.446875,
  '6': 0.446875,
  '7': 0.446875,
  '8': 0.446875,
  '9': 0.446875,
  a: 0.4375,
  b: 0.5,
  c: 0.4375,
  d: 0.5,
  e: 0.4375,
  f: 0.328125,
  g: 0.5,
  h: 0.5,
  i: 0.265625,
  j: 0.265625,
  k: 0.5,
  l: 0.265625,
  m: 0.765625,
  n: 0.5,
  o: 0.5,
  p: 0.5,
  q: 0.5,
  r: 0.328125,
  s: 0.375,
  t: 0.265625,
  u: 0.5,
  v: 0.5,
  w: 0.71875,
  x: 0.5,
  y: 0.5,
  z: 0.4375,
  ' ': 0.4,
  '!': 0.328125,
  '@': 0.90625,
  '#': 0.5,
  $: 0.5,
  '%': 0.828125,
  '^': 0.46875,
  '&': 0.765625,
  '*': 0.5,
  '(': 0.328125,
  ')': 0.328125,
  _: 0.5,
  '-': 0.328125,
  '/': 0.265625,
  '\\': 0.265625,
  '{': 0.46875,
  '}': 0.46875,
  '[': 0.328125,
  ']': 0.328125,
  '<': 0.5625,
  '>': 0.5625,
  ',': 0.25,
  '.': 0.25,
  A: 0.71875,
  B: 0.65625,
  C: 0.65625,
  D: 0.71875,
  E: 0.609375,
  F: 0.546875,
  G: 0.71875,
  H: 0.71875,
  I: 0.328125,
  J: 0.375,
  K: 0.71875,
  L: 0.609375,
  M: 0.875,
  N: 0.71875,
  O: 0.71875,
  P: 0.546875,
  Q: 0.71875,
  R: 0.65625,
  S: 0.546875,
  T: 0.609375,
  U: 0.71875,
  V: 0.71875,
  W: 0.9375,
  X: 0.71875,
  Y: 0.71875,
  Z: 0.609375
}
