/* eslint-disable */
import React, { Component } from 'react'
import filesize from 'filesize'
import { shape, arrayOf } from 'prop-types'
import ImportMappingForm from './import-mapping-form'
import { CrmTable, CrmModal, CrmHbox, CrmButton } from 'crm-components'
import ImportToolViewInner from './import-tool-view-inner.jsx'
// Import { jsonSyntaxHighlight } from 'crm-utils/common-utils'

class FileTableView extends Component {
  constructor(props) {
    super(props)
    this.columns = this.getColumn()

    this.state = {
      showModalView: false,
      viewSelectedRow: undefined,
      sheetSelected: undefined,
      showModalImport: false,
      importSelectedRow: undefined
    }
  }

  handleClickView = rowInfo => {
    if (rowInfo) console.log(rowInfo)
    this.setState({
      showModalView: true,
      viewSelectedRow: rowInfo,
      sheetSelected: 0
    })
  }

  handleClickImport = rowInfo => {
    this.setState({
      showModalImport: true,
      importSelectedRow: rowInfo
    })
  }

  handleCloseViewModal = () => {
    this.setState({
      showModalView: false,
      viewSelectedRow: undefined
    })
  }

  handleCloseImportModal = () => {
    this.setState({
      showModalImport: false,
      importSelectedRow: undefined
    })
  }

  handleClickSheet = event => {
    this.setState({ sheetSelected: event.target.id })
  }

  onHandleDelete = resourceUid => {
    this.props.handleDeleting(resourceUid)
  }

  getColumn() {
    return [
      {
        accessor: 'action',
        Header: 'Action',
        // eslint-disable-next-line
        Cell: row => (
          <div>
            <a href="#" onClick={() => this.handleClickImport(row)}>
              Import
            </a>{' '}
            |{' '}
            <a href="#" onClick={() => this.handleClickView(row)}>
              View
            </a>
          </div>
        )
      },
      {
        accessor: 'name',
        Header: 'Filename',
        width: 300
      },
      {
        accessor: 'description',
        Header: 'Description'
      },
      {
        accessor: 'size',
        Header: 'Size',
        Cell: row => filesize(row.original.size)
      },
      {
        accessor: 'mime_type',
        Header: 'Mimetype'
      },
      {
        accessor: 'created_time',
        Header: 'Createtime'
      },
      {
        Header: 'Action',
        width: 100,
        sortable: false,
        Cell: row => (
          <CrmButton
            className="remove-button"
            xsmall
            icon="trash"
            // eslint-disable-next-line
            onClick={() => this.onHandleDelete(row.original.resource_uid)}
          />
        )
      }
    ]
  }

  render() {
    const columns = this.columns
    console.log('Render Files Table View ', this.state)
    return (
      <CrmHbox fill>
        <CrmTable
          columns={columns}
          data={this.props.data ? this.props.data : []}
          className="react-table -striped -highlight"
          style={{ height: '100%' }}
        />

        <CrmModal
          isOpen={this.state.showModalView}
          title={`${
            this.state.viewSelectedRow
              ? this.state.viewSelectedRow.original.name
              : ''
          }`}
          onClose={this.handleCloseViewModal}
          size="xl"
        >
          {this.state.viewSelectedRow && (
            <div className="json-output">
              {/* <div>
                <h3>OUTPUT:</h3>
                <pre
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: jsonSyntaxHighlight(
                      this.state.selectedRow.original.parsed
                    )
                  }}
                />
              </div> */}
              <div>
                <ImportToolViewInner
                  resourceUid={this.state.viewSelectedRow.original.resource_uid}
                />
              </div>
            </div>
          )}
        </CrmModal>

        <CrmModal
          isOpen={this.state.showModalImport}
          title={`Map Import Fields ${
            this.state.importSelectedRow
              ? this.state.importSelectedRow.original.name
              : ''
          }`}
          onClose={this.handleCloseImportModal}
          size="xl"
        >
          {this.state.importSelectedRow && (
            <ImportMappingForm
              onClose={this.handleCloseImportModal}
              data={
                this.state.importSelectedRow.original.parsed
                  ? this.state.importSelectedRow.original.parsed.sheets
                  : undefined
              }
              resourceUid={this.state.importSelectedRow.original.resource_uid}
            />
          )}
        </CrmModal>
      </CrmHbox>
    )
  }
}

FileTableView.propTypes = {
  data: arrayOf(shape({})).isRequired
}

export default FileTableView
