/* eslint-disable camelcase */
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import { func, shape, arrayOf, bool } from 'prop-types'
import { actions, accountFieldsUpdated } from 'crm-duxs/account-reducer'
import {
  CrmModal,
  CrmDropdown,
  CrmButton,
  CrmUserSelect,
  CrmTextInput
} from 'crm-components'
import crmApi from 'crm-api/crm-api'

class AccountQualificationWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { hasQualifyWidget: false }
  }

  handleOnChange = value => {
    this.setState({ ...this.state, selectedQualificationUid: value })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }
  saveProspect() {
    throw new Error('Deprecated')
  }

  saveWin() {
    console.log('saveWin')
  }

  _afterSaved() {
    this.setState({ ...this.state, isSaving: false })
    this.props.doAccountFieldsUpdated(
      this.props.account.account_uid,
      [],
      () => {
        console.log('Account Refreshed')
        this.props.onCancel()
      }
    )
  }

  handleSave = () => {
    console.log('handleSave Qualification Widget')
    if (this.state.selectedQualificationUid) {
      // Special codes
      if (this.state.selectedQualificationUid === 'win') {
        return this.saveWin()
      }
      if (this.state.selectedQualificationUid === 'prospect') {
        return this.saveProspect()
      }
      const doSave = () => {
        const qualification = {
          account_uid: this.props.account.account_uid,
          qualification_type_uid: this.state.selectedQualificationUid
        }
        const onSuccess = () => {
          this.props.onCancel()
          this._afterSaved()
        }
        this.props.doSaveAccountQualification({ qualification, onSuccess })
      }
      this.setState({ ...this.state, isSaving: true }, doSave)
    }
  }
  handleWinUnitsCountChange = event => {
    const value = event.target.value
    this.setState({ ...this.state, winUnitsCount: value })
  }
  handlePotentialChange = event => {
    const value = event.target.value
    this.setState({ ...this.state, fleet_size: value })
  }
  handleWinSubscriptionPriceChange = event => {
    const value = event.target.value
    this.setState({ ...this.state, winSubscriptionPriceCount: value })
  }
  handleWinContractDatePicker = value => {
    this.setState({ ...this.state, winContractStartDate: value })
  }
  handleUserSelect = value => {
    console.log('handleUserSelect')
    console.dir(value)
    this.setState({ ...this.state, user: value })
  }

  handleSelectOption = event => {
    console.log('handleSelectOption')
    console.log(event.currentTarget.id)
    this.handleOnChange(event.currentTarget.id)
  }

  _renderOptionOpportunity() {
    const { selectedQualificationUid, user } = this.state
    return (
      <div className="col-md-6" id="prospect" onClick={this.handleSelectOption}>
        <div
          className={`QualificationWidget-option 
              ${
                selectedQualificationUid === 'prospect'
                  ? 'QualificationWidget-option--selected'
                  : ''
              }`}
        >
          <div className="QualificationWidget-option-title">
            Convert To Qualified Lead
          </div>
          {selectedQualificationUid === 'prospect' && (
            <div className="row">
              <div className="col-md-8">
                <CrmUserSelect
                  name="name"
                  input={{
                    value: user,
                    onChange: this.handleUserSelect
                  }}
                  title="Select new Owner"
                />
              </div>
              <div className="col-md-4">
                <CrmTextInput
                  placeholder="Fleet Size"
                  input={{
                    onChange: this.handlePotentialChange,
                    value: this.state.fleet_size
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  _renderOptionWin() {
    const { selectedQualificationUid } = this.state
    return (
      <div className="col-md-6" id="win" onClick={this.handleSelectOption}>
        <div
          className={`QualificationWidget-option 
              QualificationWidget-option-win 
              ${
                selectedQualificationUid === 'win'
                  ? 'QualificationWidget-option--selected'
                  : ''
              }`}
        >
          <div className="QualificationWidget-option-title">Win</div>
          {selectedQualificationUid === 'win' && (
            <div className="row">
              <div className="col-md-12">
                <small>
                  Congratulations! To qualify as new Customer register a new SA
                  in Documents section
                </small>
              </div>
              {/* <div className="col-md-4">
                <CrmTextInput
                  placeholder="Units"
                  input={{
                    onChange: this.handleWinUnitsCountChange,
                    value: this.state.winUnitsCount
                  }}
                />
              </div>
              <div className="col-md-4">
                <CrmTextInput
                  placeholder="Subscription"
                  input={{
                    onChange: this.handleWinSubscriptionPriceChange,
                    value: this.state.winSubscriptionPriceCount
                  }}
                />
              </div>
              <div className="col-md-4" style={{ color: 'black' }}>
                <DatePicker
                  input={{
                    onChange: this.handleWinContractDatePicker,
                    value: this.state.winContractStartDate
                  }}
                />
              </div> */}
            </div>
          )}
        </div>
      </div>
    )
  }
  renderNew(options) {
    const { selectedQualificationUid } = this.state
    console.log('renderNew')
    console.dir(options)
    console.dir(this.props)
    const optionsLost = options.filter(
      o => o.qualificationType.new_account_status === 'lost'
    )
    const optionsOther = options.filter(
      o =>
        o.qualificationType.new_account_status !== 'win' &&
        o.qualificationType.new_account_status !== 'lost' &&
        o.qualificationType.code !== 'opportunity'
    )
    const groups = [
      { name: 'Lost', options: optionsLost },
      { name: 'Other', options: optionsOther }
    ]
    const currentStatus = this.props.account.sales_status
      ? this.props.account.sales_status.code
      : undefined
    return (
      <div className="CrmForm">
        <div className="row">
          {this._renderOptionOpportunity()}
          {currentStatus === 'prospect' && this._renderOptionWin()}
        </div>

        {groups.map(g => (
          <div key={g.name} className="row">
            <div className="col-md-12 QualificationWidget-sectionTitle">
              {g.name}
            </div>
            {g.options.map(o => (
              <div
                key={o.value}
                id={o.value}
                className="col-md-3"
                onClick={this.handleSelectOption}
              >
                <div
                  className={`QualificationWidget-option 
              ${
                selectedQualificationUid === o.value
                  ? 'QualificationWidget-option--selected'
                  : ''
              }`}
                >
                  <div className="QualificationWidget-option-title">
                    {o.name}
                  </div>
                  {o.qualificationType && (
                    <div className="QualificationWidget-option-description">
                      {o.qualificationType.description}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className="row  QualificationWidget-sectionTitle">
          <div className="col-md-12 ButtonGroup" style={{ textAlign: 'right' }}>
            {this.state.isSaving && <div>Saving </div>}
            <CrmButton label="Cancel" onClick={this.props.onCancel} />
            <CrmButton
              type="primary"
              label="Save"
              onClick={this.handleSave}
              enable={
                selectedQualificationUid !== undefined && !this.state.isSaving
              }
            />
          </div>
        </div>
      </div>
    )
  }

  renderOld(options) {
    return (
      <div className="CrmForm">
        <div className="row">
          <div className="col-md-12  col-xs-12">
            <CrmDropdown
              options={options}
              input={{
                value: this.state.selectedQualificationUid,
                onChange: this.handleOnChange
              }}
            />
          </div>
        </div>
        <div className="row">
          {!this.props.widgetMode && (
            <div className="col-md-3  col-xs-6">
              <CrmButton
                small
                type="primary"
                label="Save"
                onClick={this.handleSave}
              />
            </div>
          )}
          {!this.props.widgetMode && (
            <div className="col-md-3  col-xs-6">
              <CrmButton
                small
                label="Cancel"
                disabled={!this.state.selectedQualificationUid}
                onClick={this.props.onCancel}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const options = this.props.qualificationTypes.map(qt => ({
      name: qt.name,
      value: qt.qualification_type_uid,
      qualificationType: qt
    }))
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Qualify account"
        onClose={this.props.onClose}
      >
        {this.props.isOpen ? this.renderNew(options) : <div />}
      </CrmModal>
    )
  }
}

AccountQualificationWidget.defaultProps = {
  onCancel: () => {},
  onClose: () => {},
  onChange: () => {},
  widgetMode: false
}

AccountQualificationWidget.propTypes = {
  onCancel: func.isRequired,
  onChange: func,
  onClose: func,
  doSaveAccountQualification: func.isRequired,
  account: shape().isRequired,
  qualificationTypes: arrayOf(shape()).isRequired,
  widgetMode: bool,
  doAccountFieldsUpdated: func.isRequired,
  isOpen: bool.isRequired
}

function mapStateToProps(state) {
  return {
    // qualificationTypes: getQualificationTypes(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doSaveAccountQualification: params =>
      dispatch(actions.saveAccountQualification.create(params)),
    doAccountFieldsUpdated: (accountUid, fields, onSuccess) =>
      dispatch(accountFieldsUpdated(accountUid, fields, onSuccess))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountQualificationWidget)
