import React from 'react'
import {
  FilterSectionActivityDate,
  FilterSectionCampaign,
  FilterSectionLead,
  FilterSectionOrganizationUnit,
  FilterSectionUser
} from '@cartrack-crm/crm-filter-section'

import { useGeneralPermissionsContext, PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

import { FilterSectionAccountName } from '../../modules/dashboard/dashboards/filters/filter-section-account-name'
import FilterSectionClearAll from '../../modules/dashboard/dashboards/filters/filter-section-clear-all'

export const TableFilters = props => {
  const filterStyle = {
    display: 'inline-block',
    minWidth: 150,
    borderBottom: 'none',
    margin: '10px 0',
    padding: '0 20px'
  }
  const { hasPermissionByType } = useGeneralPermissionsContext()
  const hasPermissionManagePermission = hasPermissionByType(PERMISSIONS_TYPES.OPPORTUNITIES_MANAGE)

  const locked_filters: any = props.locked_filters ? { ...props.locked_filters } : {}
  const nameSectionActivityDate = props.nameSectionActivityDate ? props.nameSectionActivityDate : null
  return (
    <div>
      <div style={{ padding: 0, backgroundColor: 'white', display: 'flex' }}>
        {props.additionalFilters && props.additionalFilters}
        <FilterSectionLead style={filterStyle} locked_filters={locked_filters} />

        <FilterSectionActivityDate nameSectionActivityDate={nameSectionActivityDate} style={filterStyle} locked_filters={locked_filters} />
        {hasPermissionManagePermission && <FilterSectionUser style={filterStyle} locked_filters={locked_filters} />}
        <FilterSectionCampaign style={filterStyle} locked_filters={locked_filters} />
        <FilterSectionAccountName style={filterStyle} />
        <FilterSectionClearAll style={filterStyle} locked_filters={locked_filters} />
      </div>
    </div>
  )
}

export default TableFilters
