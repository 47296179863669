import React from 'react'
import sectionContactPersonsHoc from 'crm-modules/accounts/hoc/section-contact-persons-hoc.js'
import ContactPersonForm from 'crm-modules/accounts/components/layout19/contact-person-item'
import SectionContactPersonInner from './section-contact-person-inner'

class SectionContactPerson extends React.PureComponent {
  render() {
    return (
      <SectionContactPersonInner {...this.props}>
        <ContactPersonForm />
      </SectionContactPersonInner>
    )
  }
}

export default sectionContactPersonsHoc(SectionContactPerson)
