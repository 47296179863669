import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { func, shape, bool, string, arrayOf } from 'prop-types'
import { isValid } from 'redux-form'
import { CrmModal } from 'crm-components'

import ConvertToProspectForm from './_convert-to-prospect-fields.jsx'

class ConvertToProspectModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false
    }
  }
  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, () => {
        resolve()
      })
    })
  handleSetClickHandler = handler => {
    console.log('handleSetClickHandler', handler)
    this.handlePrimaryAction = handler
  }
  handlePrimaryAction = () => {
    if (this.handlePrimaryAction) {
      this.handlePrimaryAction()
    }
  }
  handleIsSaving = async isSaving => {
    this.setStateAsync({ isSaving })
  }
  _renderTitle = () => {
    const convertMode = this.props.sourceProcess !== undefined
    if (this.props.newProcessKind === 'dialer') {
      return (
        <FormattedMessage
          id="crm.ui.account.lead.send_to_dialer"
          defaultMessage="Send Lead to Dialer"
        />
      )
    }
    if (convertMode) {
      return (
        <FormattedMessage
          id="crm.ui.account.lead.convert_lead_to_prospect"
          defaultMessage="Convert Lead To Prospect"
        />
      )
    }
    return (
      <FormattedMessage
        id="crm.ui.account.process.start_new_process"
        defaultMessage="Start new process"
      />
    )
  }
  render() {
    console.log('Convert to prospect modal')
    const convertMode = this.props.sourceProcess !== undefined
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        isSaving={this.state.isSaving}
        showFooter
        title={this._renderTitle()}
        onClose={this.props.onClose}
        primaryButtonLabel={
          convertMode ? (
            <FormattedMessage
              id="crm.ui.account.lead.convert_to_prospect"
              defaultMessage={
                this.state.isSaving ? 'Saving...' : 'Convert Lead To Prospect'
              }
            />
          ) : (
            <FormattedMessage
              id="crm.ui.account.process.start_process"
              defaultMessage={this.state.isSaving ? 'Saving...' : 'Start'}
            />
          )
        }
        secondaryButtonLabel={
          <FormattedMessage id="crm.ui.button.cancel" defaultMessage="Cancel" />
        }
        onSecondaryButton={this.props.onCancel}
        onPrimaryButton={this.handlePrimaryAction}
      >
        <ConvertToProspectForm
          {...this.props}
          key={this.props.key}
          onSetClickHandler={this.handleSetClickHandler}
          handleIsSaving={this.handleIsSaving}
        />
        isConvertToProspectValid:{' '}
        {this.props.isConvertToProspectValid ? 'Y' : 'F'}
      </CrmModal>
    )
  }
}

ConvertToProspectModal.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  onCancel: func.isRequired,
  sourceProcess: shape({}),
  processTypeOptions: arrayOf(shape()),
  newProcessMode: string,
  newProcessKind: string,
  key: string,
  isConvertToProspectValid: bool
}
ConvertToProspectModal.defaultProps = {
  sourceProcess: undefined,
  isOpen: false,
  processTypeOptions: undefined,
  newProcessMode: undefined,
  newProcessKind: undefined,
  key: undefined,
  isConvertToProspectValid: false
}

export default connect(state => ({
  isConvertToProspectValid: isValid('convertToProspect')(state)
}))(ConvertToProspectModal)
