export const calculateCurrentStepType = (process, processType) => {
  console.log('calculateCurrentStepType', process)
  const processTypeStages =
    processType && processType.data ? processType.data.stages : undefined
  const currentStepTypeUid =
    process && process.current_step
      ? process.current_step.process_step_type_uid
      : undefined
  if (currentStepTypeUid && processType) {
    const stepType = processType.steps.find(
      s => s.process_step_type_uid === currentStepTypeUid
    )
    if (stepType) {
      const currentStepType = { ...stepType }
      let currentStage
      if (
        currentStepType.data &&
        currentStepType.data.stageCode &&
        processTypeStages
      ) {
        currentStage = processTypeStages.find(
          s => s.code === currentStepType.data.stageCode
        )
      }
      return { currentStepType, currentStage }
    }
  } else if (
    process &&
    process.current_step &&
    process.current_step.process_step_type
  ) {
    return {
      currentStepType: process.current_step.process_step_type,
      currentStage: undefined
    }
  }
  return {}
}
export const mock = 1
