import { get } from 'lodash'

// Basic
export const required = name => val =>
  val !== undefined && val !== null && val !== ''
    ? undefined
    : `${name} is required.`

export const upperCase = name => str =>
  str.toUpperCase() === str ? undefined : `${name} needs to be upper case.`

export const minLen = min => name => val =>
  val && val.length >= min
    ? undefined
    : `${name} must be at least ${min} characters.`

export const maxLen = max => name => val =>
  val && val.length <= max
    ? undefined
    : `${name} must be less than or equal to ${max} characters.`

const joinWithSemicolon = (validator, type) => val =>
  val && val.includes(';')
    ? val.split(';').every(s => !validator(s))
      ? undefined
      : `Please make sure all ${type} are valid.`
    : validator(val)

// Specifics
export const email = val =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(val)
    ? undefined
    : 'Please provide a valid email.'
//  /.+@.+\..+/.test(val) ? undefined : 'Please provide a valid email.'

export const phone = val =>
  /^ ?\+?\d{10,}/.test(val)
    ? undefined
    : 'Phone numbers must be a minimum of 10 digits.'
export const multiEmail = joinWithSemicolon(email, 'emails')
export const multiPhone = joinWithSemicolon(phone, 'phone numbers')

const passwordMinLen = minLen(6)('Password')
const passwordMaxLen = maxLen(20)('Password')
export const password = val => {
  // Const hasCapAndLow = /[A-Z].*[a-z]|[a-z].*[A-Z]/.test(val)
  // Const hasNum = /\d/.test(val)
  const hasLen = !passwordMinLen(val) && !passwordMaxLen(val)

  return hasLen ? undefined : 'Password must be between 6-20 characters.'
}

// Options
export const oneOf = arr => val =>
  arr.includes(val) ? undefined : `Must be one of: ${arr.join(', ')}.`

export const notOneOf = arr => val =>
  arr.includes(val) ? `Must not be one of: ${arr.join(', ')}.` : undefined

// Date
export const isDateAfter = (key, message, gte) => (val, allVals) => {
  if (!val || !allVals) return undefined
  const date1 = new Date(val)
  const date2 = new Date(allVals[key])
  return (gte
  ? date1.getTime() >= date2.getTime()
  : date1.getTime() > date2.getTime())
    ? undefined
    : message
}

// Number
export const minValue = (min, format = n => n) => name => val =>
  Number(val) >= min
    ? undefined
    : `${name} must be at least ${format(min, val)}.`

// Type
export const isNumber = name => val =>
  Number.isNaN(Number(val)) ? `${name} must be a number.` : undefined

// Dynamic
export const equalTo = (to, msg) => (val, allVals) =>
  val === get(allVals, to) ? undefined : msg

// Validator Parsing
export const combineValidators = validators => (...args) => {
  // eslint-disable-next-line no-unused-vars
  for (const validator of validators) {
    const error = validator(...args)
    if (error) return error
  }
  return undefined
}

function validatorNamer(name) {
  return v => (typeof v('', {}) === 'function' ? v(name) : v)
}

export function resolveValidate(name, validate) {
  const namer = validatorNamer(name)
  if (Array.isArray(validate)) {
    return {
      validate: combineValidators(validate.map(namer)),
      required: Boolean(validate.find(v => v === required))
    }
  }
  return { validate: namer(validate), required: validate === required }
}

export function requiredByName() {
  return false // Mock
}
