import React from 'react'
import { CrmCarousel, CrmLoadingIcon } from '@cartrack-crm/ui'
import { DebugTools } from '../../debug-tools/debug-tools'
import { useGetComponentByUID, useGetDashboardOptionsByRootQLType } from '../data/component-utils'
import { useCrmDynamicTableContext, DynamicTableContextType } from '../dynamic-table-context'
import { GenericWidgetWeb } from '../../generic/web/generic-widget-web'

const DynamicTableViewDashboard = (props) => {
  const {
    currentDashboard,
    selectedDashboard,
    setSelectDashboard,
    setCurrentDashboard
  }: DynamicTableContextType = useCrmDynamicTableContext()
  const dashboardOptions = useGetDashboardOptionsByRootQLType(props.rootQLType)
  const { component, loading } = useGetComponentByUID(selectedDashboard)
  const isDebugMode: boolean = localStorage.getItem('crm_is_debug_mode') === 'true'
  const prepareDashboard = () => {
    if (!selectedDashboard) {
      setSelectDashboard(dashboardOptions?.[0]?.value)
    }
    if (!loading) {
      setCurrentDashboard(component)
    }
  }
  const onSelectedDashboard = (value) => {
    setSelectDashboard(value)
  }
  prepareDashboard()
  const handleShowLeadsPopup = (params) => {
    // Merge with context
    const lparams = {
      type: params.type,
      filters: {
        // ...mapContextToFilter(this.props),
        ...params.filters
      }
    }
    if (props.onShowLeadsPopup) {
      props.onShowLeadsPopup(lparams)
    }
  }
  const _renderItems = (parent) => {
    console.log('_renderITems', parent)
    return parent.items.map((item, index) => _renderItem(item, index, parent.items.length))
  }
  // !Work render here
  const _renderItem = (item, index, totalItems) => {
    console.log('_renderItem', totalItems)
    // console.dir(item)
    if (item.type === 'analitics_widget') {
      const widgetDefinition = item.widgetDefinition ? item.widgetDefinition : item
      const title = item.widgetDefinition ? item.widgetDefinition.title : ''
      return (
        <React.Fragment>
          <span />
          {/* IF we import this class then it doesnt compile without error //  */}
          {/* <GenericAnalyticsWidget
            key={item.code ? item.code : index}
            title={title}
            // style={{ maxHeight: 400, margin: 0 }}
            widgetDefinition={widgetDefinition}
            filters={item.filters}
            // analyticsContext={this.props.analyticsContext}
            onShowLeadsPopup={props.handleShowLeadsPopup}
            fullHeight={totalItems === 1}
          /> */}
        </React.Fragment>
      )
    }
    if (item.type === 'container') {
      const className = item.class
      return <div className={className}>{item.items && item.items.map(_renderItem)}</div>
    }
  }
  // TODO Have to change how to render
  return (
    <div className="util-flexColumn util-flexGrow" style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
      <CrmCarousel value={selectedDashboard} onChange={onSelectedDashboard} options={dashboardOptions} />
      {isDebugMode && <DebugTools />}
      {loading && <CrmLoadingIcon />}
      {!loading && currentDashboard?.content?.items && _renderItems(currentDashboard.content)}
      {!loading && !currentDashboard?.content?.items && currentDashboard?.component_uid && (
        <React.Fragment>
          <GenericWidgetWeb
            key={currentDashboard?.code ?? currentDashboard?.dashboard_uid}
            title={currentDashboard?.name}
            widgetDefinition={currentDashboard}
            onShowLeadsPopup={handleShowLeadsPopup}
          />
        </React.Fragment>
      )}
    </div>
  )
}

export default DynamicTableViewDashboard
