import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable, faRulerHorizontal, faChartPie, faBrush } from '@fortawesome/free-solid-svg-icons'

const IconSelectorWrapper = styled.div`
  align-items: center;
  margin: var(--crm-padding-12);
  padding: var(--crm-padding-24);
  background-color: var(--crm-color-gray25);
  font-size: var(--crm-font-size-36);
  border-radius: var(--crm-border-radius-lg);
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  display: flex;
  flex-direction: column;
  min-width: 80px;
  height: 80px;
  text-align: center;


  ${({ disabled }) => disabled && css`color: var(--crm-color-gray45);`}
  label {
    margin-top: var(--crm-padding-12);
    font-size: var(--crm-font-size-xxl);

    &.status {
      font-size: var(--crm-font-size-md);
    }
  }

  :hover {
    ${({ disabled }) => !disabled && css`
      box-shadow: var(--crm-box-shadow);
    `}
  }
`

interface IIconSelector {
  type: string
  label: string
  disabled?: boolean
  onClick?: Function
}

const getIcon = (type) => {
  switch (type) {
    case 'table':
      return faTable
    case 'horizontal_table':
      return faRulerHorizontal
    case 'pie_chart':
      return faChartPie
    case 'widget':
      return faBrush
  }
}
export const IconSelector: FC<IIconSelector> = ({ type, label, disabled, onClick }) => {
  const icon = getIcon(type)
  const onActionIcon = () => {
    if (!disabled && onClick) {
      onClick(type)
    }
  }
  return (
    <IconSelectorWrapper disabled={disabled} onClick={onActionIcon}>
      <FontAwesomeIcon icon={icon} />
      <label> {label}</label>
      {/* {disabled && <label className='status'> coming soon... (maybe)</label>} */}
    </IconSelectorWrapper>
  )
}