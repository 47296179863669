import React from 'react'
import { injectIntl } from 'react-intl'
import { Icon } from '../icon/icon'
const defaultFunction = () => null
export const TextInput = ({
  intl,
  input,
  type = 'text',
  placeholder,
  placeholderId,
  iconName,
  focus = false,
  disabled,
  meta = {
    valid: true,
    touched: false,
    error: ''
  },
  extraClassNames: {
    containerClassNames = '',
    inputClassNames = '',
    placeholderClassNames = ''
  },
  onBlur = defaultFunction,
  onChange = defaultFunction,
  onFocus = defaultFunction,
  onClick = defaultFunction,
  onKeyPress = defaultFunction,
  onKeyDown = defaultFunction,
  onIconClick = defaultFunction,
  readOnly,
  value,
  extraProps,
  required,
  id,
  autoComplete,
  hidden,
  style
}) => (
  <div
    className={`${containerClassNames} TextInput ${
      meta?.touched && !meta?.valid ? 'is-invalid' : ''
    }`}
  >
    <input
      autoFocus={focus}
      className={`${inputClassNames} TextInput-input ${
        input?.value || value ? 'is-withValue' : ''
      }`}
      value={input?.value || value}
      type={hidden ? 'hidden' : type}
      disabled={disabled}
      onChange={input?.onChange ?? onChange} // eslint-disable-line react/jsx-handler-names
      onBlur={input?.onBlur ?? onBlur} // eslint-disable-line react/jsx-handler-names
      onFocus={input?.onFocus ?? onFocus} // eslint-disable-line react/jsx-handler-names
      onClick={input?.onClick ?? onClick}
      onKeyPress={input?.onKeyPress ?? onKeyPress}
      onKeyDown={input?.onKeyDown ?? onKeyDown}
      readOnly={readOnly}
      maxLength={extraProps?.length}
      id={id}
      autoComplete={autoComplete}
      style={style}
    />
    <div
      className={`${placeholderClassNames} TextInput-placeholder ${
        input?.value || value ? 'is-withValue' : ''
      }`}
    >
      {placeholder && typeof placeholder === 'string'
        ? intl.formatMessage({
            id: placeholderId || `form.placeholder.${placeholder}`,
            defaultMessage: placeholder
          })
        : placeholder}
    </div>
    {required ? <div className="TextInput-required">*</div> : null}
    {iconName && (
      <Icon
        name={iconName}
        className="TextInput-faIcon"
        onClick={onIconClick}
      />
    )}
    <span className="TextInput-error">{meta?.error}</span>
  </div>
)
const injectIntlTextInput: any = injectIntl(TextInput)
export default injectIntlTextInput
