import React, { Component } from 'react'
import {
  shape,
  instanceOf,
  func,
  string,
  oneOfType,
  bool,
  number,
  arrayOf
} from 'prop-types'
import DayPicker, { DateUtils } from 'react-day-picker'
import enhanceClickOutside from 'react-click-outside'
import { injectIntl } from 'react-intl'
import { TextInput } from '@cartrack-crm/ui'

class DatePicker extends Component {
  constructor(props) {
    super(props)

    const value = this.props.input ? this.props.input.value : this.props.value
    let initialState = { selected: null }

    if (value) {
      if (this.props.isRange) {
        initialState = {
          selected: {
            from: value.from,
            to: value.to
          }
        }
      } else {
        initialState = {
          selected: value
        }
      }
    }

    this.state = {
      isFocused: false,
      sixMonthsAgo: new Date(Date.now() - 1.577e10),
      today: new Date(),
      lastMonth: new Date(Date.now() - 2.628e9),
      ...initialState
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleDayClick = this.handleDayClick.bind(this)
  }

  handleFocus() {
    this.setState({
      isFocused: !this.state.isFocused
    })

    if (this.state.isFocused && this.props.input && this.props.input.onBlur) {
      this.props.input.onBlur()
    }
  }

  handleDayClick(clickedDate) {
    const onChange = this.props.input
      ? this.props.input.onChange
      : this.props.onChange
    let selected
    let isFocused
    if (
      DateUtils.isDayBefore(clickedDate, this.state.today) ||
      DateUtils.isSameDay(clickedDate, this.state.today)
    )
      if (this.props.strictRange) {
        const from = new Date(
          clickedDate.getTime() - 8.64e7 * this.props.strictRange
        )
        const to = clickedDate
        selected = { from, to }
        isFocused = false
      } else if (this.props.isRange) {
        if (
          !(
            this.state.selected &&
            this.state.selected.from &&
            DateUtils.isSameDay(clickedDate, this.state.selected.from)
          ) ||
          !(
            this.state.selected &&
            this.state.selected.to &&
            DateUtils.isSameDay(clickedDate, this.state.selected.to)
          )
        ) {
          selected = DateUtils.addDayToRange(
            new Date(clickedDate),
            this.state.selected || {}
          )
        }
        isFocused = true
      } else {
        selected = clickedDate
        isFocused = false
      }
    else {
      if (this.props.disabledDays) {
        selected = clickedDate
      }
      isFocused = true
    }
    this.setState({
      isFocused,
      selected
    })

    if (selected) {
      const d = new Date(selected)
      console.log(
        'Selected date:',
        selected,
        d,
        new Date(selected.setHours(0, 0, 0, 0))
      )
      if (!this.props.isRange) onChange(new Date(selected.setHours(0, 0, 0, 0)))
      else if (selected.from && selected.to)
        onChange({
          from: new Date(selected.from.setHours(0, 0, 0, 0)),
          to: new Date(selected.to.setHours(23, 59, 59, 999))
        })
    }
  }

  handleClickOutside() {
    this.setState({ isFocused: false })
    if (this.props.input && this.props.input.onBlur) this.props.input.onBlur()
  }

  render() {
    const {
      meta,
      placeholder,

      disabled,
      required,

      extraClassNames: {
        containerClassNames = '',
        inputClassNames = '',
        placeholderClassNames = ''
      },

      openUpwards,
      isRange,
      intl: { formatDate }
    } = this.props

    let { isFocused, selected } = this.state
    const value = this.props.input ? this.props.input.value : this.props.value
    let formatted = ''

    if (!selected) {
      selected = value
    }

    if (isRange)
      if (selected && selected.from)
        if (selected.to) {
          formatted = {
            from: formatDate(selected.from),
            to: formatDate(selected.to)
          }
        } else {
          // Date Range but only start date
          formatted = { from: formatDate(selected.from), to: '' }
        }
      else formatted = { from: '', to: '' }
    else if (selected) formatted = formatDate(selected)

    return (
      <div
        className={`${containerClassNames} DatePicker ${
          isRange ? 'DatePicker--double' : ''
        }`}
      >
        <div style={{ position: 'relative' }}>
          <div className={`DatePicker-inner ${isFocused ? 'is-open' : ''}`}>
            {isRange ? (
              <div className="row between-xs" onClick={this.handleFocus}>
                <TextInput
                  type="text"
                  iconName="calendar-o"
                  onIconClick={this.handleFocus}
                  value={formatted.from}
                  placeholder={placeholder.from}
                  onClick={this.handleFocus}
                  extraClassNames={{
                    containerClassNames: 'col-xs-6',
                    inputClassNames,
                    placeholderClassNames
                  }}
                  readOnly
                  disabled={disabled}
                  required={required}
                  meta={meta}
                />
                <TextInput
                  type="text"
                  iconName="calendar-o"
                  onIconClick={this.handleFocus}
                  value={formatted.to}
                  placeholder={placeholder.to}
                  onClick={this.handleFocus}
                  extraClassNames={{
                    containerClassNames: 'col-xs-6',
                    inputClassNames,
                    placeholderClassNames
                  }}
                  readOnly
                  disabled={disabled}
                />
              </div>
            ) : (
              <TextInput
                type="text"
                iconName="calendar-o"
                onIconClick={this.handleFocus}
                value={formatted}
                placeholder={placeholder}
                onClick={this.handleFocus}
                extraClassNames={{ inputClassNames, placeholderClassNames }}
                readOnly
                disabled={disabled}
                required={required}
                meta={meta}
              />
            )}
            {isFocused ? (
              <div
                className={`
                DatePicker-holder 
                ${openUpwards ? 'DatePicker-holder--openUpwards' : ''}`}
              >
                <DayPicker
                  numberOfMonths={isRange ? 2 : 1}
                  onDayClick={this.handleDayClick}
                  selectedDays={selected}
                  disabledDays={
                    this.props.disabledDays || { after: this.state.today }
                  }
                  fromMonth={this.props.fromMonth || this.state.sixMonthsAgo}
                  toMonth={this.props.toMonth || this.state.today}
                  initialMonth={
                    this.props.initialMonth ||
                    this.state.selected ||
                    this.state.today
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

DatePicker.propTypes = {
  // Main Props
  input: shape({}),
  value: oneOfType([
    shape({
      from: oneOfType([instanceOf(Date), bool]),
      to: oneOfType([instanceOf(Date), bool])
    }),
    oneOfType([instanceOf(Date), bool])
  ]),
  onChange: func,
  placeholder: oneOfType([
    string.isRequired,
    shape({
      to: string.isRequired,
      from: string.isRequired
    })
  ]).isRequired,

  // Input State
  disabled: bool,
  required: bool,
  meta: shape({}),

  // Styling
  extraClassNames: shape({}),

  // Modifiers
  openUpwards: bool,
  isRange: bool,
  strictRange: number,

  // Pass down to DayPicker
  disabledDays: oneOfType([
    instanceOf(Date),
    shape({ from: instanceOf(Date), to: instanceOf(Date) }),
    shape({ before: instanceOf(Date), after: instanceOf(Date) }),
    arrayOf(instanceOf(Date))
  ]),
  fromMonth: instanceOf(Date),
  toMonth: instanceOf(Date),
  initialMonth: instanceOf(Date)
}

DatePicker.defaultProps = {
  // Main Props
  input: null,
  value: null,
  onChange: null,

  // Input State
  disabled: undefined,
  required: false,
  meta: {},

  // Styling
  extraClassNames: {
    containerClassNames: '',
    inputClassNames: '',
    placeholderClassNames: ''
  },

  // Modifiers
  openUpwards: false,
  isRange: false,
  strictRange: null,

  // Pass down to DayPicker
  disabledDays: undefined,
  fromMonth: undefined,
  toMonth: undefined,
  initialMonth: undefined
}
/** @type {any} */
const DatePickerWithIntl = injectIntl(enhanceClickOutside(DatePicker))
export default DatePickerWithIntl
