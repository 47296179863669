import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import crmRootSaga from './sagas/crm-root-saga'
import rootReducer from './duxs/root-reducer'

export default function configureStore(initialState = {}, options = {}) {
  const history = createBrowserHistory()
  const enhancers = []
  const sagaMiddleware = createSagaMiddleware()
  const middleware = []
  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

  if (process.env.NODE_ENV === 'development') {
    const reduxImmutableState = require('redux-immutable-state-invariant')
      .default
    const reduxUnhandledAction = require('redux-unhandled-action').default
    middleware.push(reduxImmutableState())
    middleware.push(
      reduxUnhandledAction(action =>
        // eslint-disable-next-line no-console
        console.error(`${action} didn't lead to creation of a new state object`)
      )
    )
  }

  middleware.push(routerMiddleware(history), sagaMiddleware)
  enhancers.unshift(applyMiddleware(...middleware))

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(...enhancers)
  )

  if (module.hot) {
    module.hot.accept('./duxs/root-reducer', () => {
      store.replaceReducer(rootReducer(history))
    })
  }
  sagaMiddleware.run(crmRootSaga(options))

  store.history = history

  // Store user preferences that might have changed
  window.onbeforeunload = () => {
    const state = store.getState()
    if (state.user.user) {
      window.localStorage.setItem(
        'userPreferences',
        JSON.stringify(state.user.preferences)
      )
      localStorage.setItem(
        'userPreferences' + state.user.user.id,
        JSON.stringify(state.user.preferences)
      )
    }
  }

  return store
}
