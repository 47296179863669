import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import { GenericDropDownContainer, GenericDropDownFilter, GenericClearFilter } from '../tools'
import { DashboardFiltersDropDown } from '../styled/dropdown-group-style'
import CampaignSelect from './campaign-select'

class SalesCampaignDropdown extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      filterValues: props.filterValues ? { ...props.filterValues } : {}
    }
  }
  componentWillReceiveProps = nextProps => {
    if (JSON.stringify(nextProps.filterValues) !== JSON.stringify(this.props.filterValues)) {
      this.setState({ filterValues: { ...nextProps.filterValues } })
    }
  }
  handleClickOutside = () => {
    if (this.props.isExpanded) {
      this.saveValue()
      setTimeout(this.props.onClickedOutside, 5)
    }
  }
  saveValue = () => {
    this.props.onSetFilters(this.state.filterValues)
  }
  handleFilterValueChanged = field => newValue => {
    const newFilterValues = { ...this.state.filterValues }
    newFilterValues[field] = newValue
      ? {
          campaign_uid: {
            value: newValue.value,
            label: newValue.label
          }
        }
      : undefined
    this.setState({
      filterValues: newFilterValues
    })
  }
  handleClearFilter = filterName => {
    const newFilterValues = { ...this.state.filterValues }
    newFilterValues[filterName] = undefined
    this.setState({
      filterValues: newFilterValues
    })
  }
  handleClearFilters = () => {
    const newFilterValues = { ...this.state.filterValues }
    newFilterValues.sales_campaign = undefined
    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClickApply = () => {
    this.handleClickOutside()
  }
  handleClickCancel = () => {
    this.setState({ filterValues: { ...this.props.filterValues } })
    setTimeout(this.props.onClickedOutside, 5)
  }

  render() {
    return (
      <GenericDropDownContainer>
        <GenericDropDownFilter
          size={250}
          label="Sales Campaign"
          value={
            this.state.filterValues.sales_campaign?.campaign_uid?.value
              ? this.state.filterValues.sales_campaign?.campaign_uid?.label
              : 'All'
          }
          onClearFilter={() => this.handleClearFilter('sales_campaign')}
        >
          <CampaignSelect
            type="sale"
            onFilterValueChanged={this.handleFilterValueChanged('sales_campaign')}
            filterValues={this.state.filterValues}
          />
        </GenericDropDownFilter>
        <GenericClearFilter
          onApply={this.handleClickApply}
          onCancel={this.handleClickCancel}
          onClearFilters={this.handleClearFilters}
        />
      </GenericDropDownContainer>
    )
  }
}

export default enhanceWithClickOutside(SalesCampaignDropdown)
