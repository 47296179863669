/* eslint-disable */
import React from 'react'
import { withApollo } from 'react-apollo'
import moment from 'moment'

import { graphql, compose } from 'react-apollo'
import { qlqListOpportunities } from 'crm-data/opportunities'

import { arrayOf, shape, func } from 'prop-types'
import { CrmFullpageContainer, CrmDropdown, CrmButton } from 'crm-components'
import WithLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import CrmScatteredChart from './crm-opportunities-scattered-chart.jsx'

class OpportunitiesFunnelDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      startDate: moment().add(-3, 'months'),
      endDate: moment().add(6, 'months')
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, () => {
        resolve()
      })
    })

  handleCurrentStepClick = selectedStepType => {
    // console.log('handleCurrentStepClick', selectedStepType)
    this.setState({ selectedStepType })
  }
  handleAnalyticsRangeSelected = params => {
    // console.log('handleAnalyticsRangeSelected', params)
    // this.props.onShowLeadsPopup(params)
  }
  _parseData = data => {
    return data
  }
  handleRefresh = async () => {
    // console.log('handleRefresh')

    try {
      const variables = {
        filter: {
          completed_time: 'null'
          // is_overdue: false
        },
        limit: 1000
      }
      await this.setStateAsync({ isLoading: true })
      const res = await this.props.client.query({
        query: qlqListOpportunities,
        variables,
        fetchPolicy: 'no-cache'
      })
      // console.log('got data', res)
      await this.setStateAsync({ isLoading: false })
      if (res.data && res.data.opportunities_list_qm_paged) {
        const parsed = this._parseData(
          res.data.opportunities_list_qm_paged.data
        )
        this.setState({ data: parsed })
      }
    } catch (err) {
      await this.setStateAsync({ isLoading: false })
    }
  }

  render() {
    // localContext.filters.activity_date = {
    //   value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    //   valueLabel: 'Today',
    //   filterName: 'Activity Date'
    // }
    // delete localContext.filters.lead_in_date
    console.log('Render dash opportunities funnel ')
    return (
      <div>
        <div className="row">
          <div className="col-md-10 util-textRed">
            <CrmButton
              label="Refresh"
              type="primary"
              onClick={this.handleRefresh}
              isSaving={this.state.isLoading}
            />
          </div>
        </div>
        {this.state.data && <CrmScatteredChart data={this.state.data} />}
      </div>
    )
  }
}

OpportunitiesFunnelDashboard.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

OpportunitiesFunnelDashboard.defaultProps = {
  dashboards: []
}

export default WithLeadsPopupHoc(withApollo(OpportunitiesFunnelDashboard))
