// Actions
export const FETCH_TERRITORIES = 'FETCH_TERRITORIES'
export const FETCH_TERRITORIES_SUCCESS = 'FETCH_TERRITORIESS_SUCCESS'
export const SELECT_TERRITORY = 'SELECT_TERRITORY'
export const SAVE_TERRITORY = 'SAVE_TERRITORY'
export const SAVE_TERRITORY_SUCCESS = 'SAVE_TERRITORY_SUCCESS'

// Reducer
const initialState = {
  selectedTerritory: undefined,
  territory: {},
  territories: []
}

export default function territoriesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TERRITORIES_SUCCESS:
      return { ...state, territories: action.payload.territories }
    case SELECT_TERRITORY:
      return { ...state, selectedTerritory: action.payload.selectedTerritory }
    case SAVE_TERRITORY_SUCCESS:
      return { ...state, territory: null, selectedTerritory: null }
    default:
      return state
  }
}

// Action Creators
export function fetchTerritories() {
  // console.log('fetchTerritories')
  return {
    type: FETCH_TERRITORIES,
    payload: {}
  }
}

export function selectTerritory(selectedTerritory) {
  // console.log('selectTerritory')
  return {
    type: SELECT_TERRITORY,
    payload: { selectedTerritory }
  }
}

export function doSaveTerritory(territory) {
  // console.log('doSaveTerritory')
  return {
    type: SAVE_TERRITORY,
    payload: { territory }
  }
}

// Selectors
export const listTerritories = state => state.crm.territories.territories
export const getSelectedTerritory = state =>
  state.crm.territories.selectedTerritory
export const getTerritory = state => state.crm.territories.territory
