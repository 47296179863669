import { bool, string } from 'prop-types'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import React from 'react'

const editOpportunityHoc = WrappedComponent => {
  class EditOpportunityHocInner extends React.PureComponent {
    handleOpportunityLost = async () => {}

    render() {
      return (
        <WrappedComponent
          {...this.props}
          isSaving={this.props.isSaving}
          fieldName={this.props.fieldName}
          label={this.props.label}
          editable={this.props.editable}
          onOpportunityLost={this.handleOpportunityLost}
        />
      )
    }
  }

  EditOpportunityHocInner.propTypes = {
    isSaving: bool,
    fieldName: string,
    label: string,
    editable: bool
  }
  return crmGenericCommandHoc(WrappedComponent)
}

export const saveHandler = async (value, props, isManagerMode = false) => {
  if (props.handleProductItemChange) {
    await props.handleProductItemChange(
      props.fieldName,
      props.index,
      value,
      props.isAdmitEdit
    )
  } else if (props.handleOpportunityTypeChange) {
    await props.handleOpportunityTypeChange(props.fieldName, value)
  } else if (props.handleFieldChange) {
    await props.handleFieldChange(props.fieldName, value, props.isAdmitEdit)
  }
}

export default editOpportunityHoc
