import React from 'react'
import { CrmLoadingIcon } from '@cartrack-crm/ui'
import classNames from 'classnames'
import changeAccountTagHoc from './_change_account-tag-hoc'
import enhanceWithClickOutside from 'react-click-outside'

interface ListAccountTag {
  name: string,
  code: string,
  icon?: string
}
interface Tag {
  code: string,
  name: string
}
interface ChangeAccountTagWidgetProps {
  onCancel: any,
  onAddAccountTag: any,
  onRemoveTagFromAccount: any,
  account: {
    account_uid: string,
    tags: Tag[]
  },
  listAccountTags: ListAccountTag[],
  tagOnSaving: string
}

class ChangeAccountTagWidget extends React.PureComponent<ChangeAccountTagWidgetProps> {
  onChangeTag = (value: string) => {
    const { onCancel, onAddAccountTag } = this.props
    const res = onAddAccountTag(this.props.account, value)
    if (res) {
      onCancel()
    }
  }
  handleClickOutside = () => {
    this.props.onCancel()
  }
  isAlreadyHasTag = (tag: string) => {
    const listAccountTag = this.props.account.tags || []
    const filteredAccountTag = listAccountTag.filter(el => el.code === tag)
    return filteredAccountTag.length > 0
  }
  isTagOnSaving = (tag: string) => {
    const { tagOnSaving } = this.props
    return tag === tagOnSaving
  }
  sendActionToAddTag = async (tag: string, tagName: string) => {
    const { onAddAccountTag, onRemoveTagFromAccount, onCancel } = this.props

    if (this.isAlreadyHasTag(tag)) {
      await onRemoveTagFromAccount(tag)
    } else {
      await onAddAccountTag(tag, tagName)
    }

  }
  renderPopUpForAddTags = () => {
    const { listAccountTags } = this.props
    return listAccountTags.map((tag) => {
      return (
        <div className={classNames('CrmAddTag-item', { 'active': this.isAlreadyHasTag(tag.code) })} onClick={() => this.sendActionToAddTag(tag.code, tag.name)} key={tag.code}>
          {this.isTagOnSaving(tag.code) ?
            <div className='CrmAddTag-Wrapper-loading'>
              <CrmLoadingIcon className='CrmAddTag-loading' />
            </div>
            : <div><i className={`fa fa-${tag.icon}`} /> {tag.name}</div>
          }

        </div>)
    })
  }
  render = () => {
    return (
      <div className='CrmAddTag-wrapper'>
        {this.renderPopUpForAddTags()}
      </div>
    )
  }
}

const changeAccountTagWidgetWithClickOutside = enhanceWithClickOutside(ChangeAccountTagWidget)
export default changeAccountTagHoc(changeAccountTagWidgetWithClickOutside)
