/* eslint-disable */
import { ApolloLink } from 'apollo-link'
import { print } from 'graphql/language/printer'
import errorReport from './crm-api-warning'
import { rootSilentRefreshToken, checkAccessTokenExists } from './auth'

/**
 * This is a port of Apollo Http Link - enchaced with crm_instance_uid and authorization support
 */

const crmAppVersion =
  process && process.env ? process.env.APP_VERSION : undefined
const crmAppName = process.env.APP_NAME ? process.env.APP_NAME : ''

const throwServerError = (response, result, message) => {
  const error = new Error(message)
  error.response = response
  error.statusCode = response.status
  error.result = result

  throw error
}

const defaultHttpOptions = {
  includeQuery: true,
  includeExtensions: false
}

const parseAndCheckResponse = request => response => {
  return response
    .json()
    .catch(e => {
      const parseError = e
      parseError.response = response
      parseError.statusCode = response.status

      throw parseError
    })
    .then(result => {
      if (response.status >= 300)
        throwServerError(
          response,
          result,
          `Response not successful: Received status code ${response.status}`
        )
      if (!result.hasOwnProperty('data') && !result.hasOwnProperty('errors'))
        throwServerError(
          response,
          result,
          `Server response was missing for query '${request.operationName}'.`
        )

      return result
    })
}

const checkFetcher = fetcher => {
  if (
    fetcher.use &&
    fetcher.useAfter &&
    fetcher.batchUse &&
    fetcher.batchUseAfter
  )
    throw new Error(`
  It looks like you're using apollo-fetch! Apollo Link now uses the native fetch
  implementation, so apollo-fetch is not needed. If you want to use your existing
  apollo-fetch middleware, please check this guide to upgrade:
    https://github.com/apollographql/apollo-link/blob/master/docs/implementation.md
`)
}

const warnIfNoFetch = fetcher => {
  if (!fetcher && typeof fetch === 'undefined') {
    let library = 'unfetch'

    if (typeof window === 'undefined') library = 'node-fetch'

    throw new Error(
      `fetch is not found globally and no fetcher passed, to fix pass a fetch for
      your environment like https://www.npmjs.com/package/${library}.
      For example:
        import fetch from '${library}';
        import { createHttpLink } from 'apollo-link-http';
        const link = createHttpLink({ uri: '/graphql', fetch: fetch });
      `
    )
  }
}

const createSignalIfSupported = () => {
  if (typeof AbortController === 'undefined')
    return {
      controller: false,
      signal: false
    }

  const controller = new AbortController()
  const signal = controller.signal

  return {
    controller,
    signal
  }
}

export function getApiRootUrl() {
  let apiUrl = process.env.CRM_API_URL
    ? process.env.CRM_API_URL
    : 'http://localhost/jsonrpc/'

  if (window.localStorage.getItem('crm_api_url')) {
    apiUrl = window.localStorage.getItem('crm_api_url')
  }

  return apiUrl
}

function getCrmInstanceUid() {
  if (window.localStorage.getItem('crm_instance')) {
    const crmInstance = JSON.parse(window.localStorage.getItem('crm_instance'))
    return crmInstance.instance_uid
  }

  return undefined
}

function getCrmLocale() {
  if (window.localStorage.getItem('locale')) {
    return window.localStorage.getItem('locale')
  }

  return 'en'
}

const abortRequestSettings = variables => {
  if (variables?.widgetDefinition)
    return { canAbort: true, controllerKey: variables.widgetDefinition.code }
  return { canAbort: false, controllerKey: null }
}

export const createCrmHttpLink = ({
  uri,
  pFetch,
  includeExtensions,
  ...requestOptions
} = {}) => {
  if (!uri) uri = `${getApiRootUrl()}crm_ql.php`

  const fetcher = pFetch || fetch
  let activeControllers = {}

  return new ApolloLink(
    operation =>
      new Observable(observer => {
        const {
          headers,
          credentials,
          fetchOptions = {},
          httpOptions
        } = operation.getContext()
        const { operationName, extensions, variables, query } = operation
        const { canAbort, controllerKey } = abortRequestSettings(variables)

        const http = {
          ...defaultHttpOptions,
          ...httpOptions
        }
        const body = {
          crm_instance_uid: getCrmInstanceUid(),
          crm_locale: getCrmLocale(),
          extensions:
            includeExtensions || http?.includeExtensions
              ? extensions
              : undefined,
          operationName,
          query: http?.includeQuery ? print(query) : undefined,
          variables
        }

        if (crmAppVersion) {
          body.crm_client_version = crmAppVersion
          body.crm_name = crmAppName
        }

        let serializedBody

        try {
          serializedBody = JSON.stringify(body)
        } catch (e) {
          const parseError = new Error(
            `Network request failed. Payload is not serializable: ${e.message}`
          )
          parseError.parseError = e
          throw parseError
        }

        const { controller, signal } = createSignalIfSupported()

        rootSilentRefreshToken().then(() => {
          checkAccessTokenExists()

          const options = requestOptions.fetchOptions
            ? {
                ...requestOptions.fetchOptions,
                ...fetchOptions
              }
            : fetchOptions

          const fetcherOptions = {
            method: 'POST',
            ...options,
            body: serializedBody,
            credentials: credentials ?? requestOptions?.credentials,
            headers: {
              accept: '*/*',
              'content-type': 'application/json',
              Authorization: `Bearer ${window.localStorage.getItem(
                'crmAccessToken'
              )}`,
              'Content-Language': `${window.localStorage.getItem('locale')}`,
              ...(requestOptions?.headers ?? {}),
              ...(headers ?? {})
            }
          }
          // TODO Will check this stuff later for better solution
          // if (canAbort && activeControllers[controllerKey])
          //   activeControllers[controllerKey].abort()

          if (controller) {
            if (canAbort) activeControllers[controllerKey] = controller
            fetcherOptions.signal = signal
          }

          const finalUri = `${uri}${
            isDebugMode
              ? '?XDEBUG_SESSION_START=DESKTOP-L4L561S$&CRM_DEBUG=true'
              : ''
          }`
          const isDebugMode =
            window.localStorage.getItem('crm_is_debug_mode') === 'true'

          window.localStorage.setItem(
            'lastNetworkActivityTime',
            new Date().getTime()
          )

          fetcher(finalUri, fetcherOptions)
            .then(response => {
              operation.setContext({
                response
              })

              return response
            })
            .then(parseAndCheckResponse(operation))
            .then(result => {
              if (canAbort && activeControllers[controllerKey])
                activeControllers[controllerKey] = undefined

              observer.next(result)
              observer.complete()
              return result
            })
            .catch(err => {
              if (canAbort && activeControllers[controllerKey])
                activeControllers[controllerKey] = undefined
              if (err.name === 'AbortError') return

              if (err?.result?.errors && err?.result?.data) {
                observer.next(err.result)
              } else {
                observer.error(err)
              }
            })
        })

        return () => {
          // https://developers.google.com/web/updates/2017/09/abortable-fetch
          if (controller) controller.abort()
          activeControllers = {}
        }
      })
  )
}

class CrmApolloHttpLink extends ApolloLink {
  constructor(opts) {
    super(createCrmHttpLink(opts).request)
  }
}

export default CrmApolloHttpLink
