import React, { useEffect, useState } from 'react'
import { useClickOutside } from 'react-click-outside-hook'
import { CrmOrganizationUnitTreeInner } from 'crm-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import styles from '../filters/dashboard-filters.scss'
import classNames from 'classnames'

interface DashboardOrganizationDropDownProps extends DashboardFilterBarProps {
  isExpanded: Boolean
  onCloseDropDown: () => void
}

export const DashboardOrganizationDropDown: React.SFC<DashboardOrganizationDropDownProps> = props => {
  const [ref, hasClickOutSide] = useClickOutside()
  const [organizationUnit, setOrganizationUnit] = useState(props.selectedOrganizationUnit)
  const [organizationName, setOrganizationName] = useState<string>(props.organizationName)

  useEffect(() => {
    let abort = false

    if (!abort) {
      setOrganizationUnit(props.selectedOrganizationUnit)
      setOrganizationName(props.organizationName)
    }

    return () => {
      abort = true
    }
  }, [props])

  useEffect(() => {
    if (hasClickOutSide) {
      props.onCloseDropDown()
      const organization = { organization_unit_uid: organizationUnit, name: organizationName }
      props.onChangeOrganization(organization)
    }
  }, [hasClickOutSide])

  const onChange = organization => {
    setOrganizationName(organization?.name)
    setOrganizationUnit(organization?.organization_unit_uid)
  }

  const clearOrganizationUnit = () => {
    onChange(undefined)
  }

  return (
    <div className={styles.DashboardFilters__Dropdown} ref={ref}>
      <div className={styles.DashboardFilters__Dropdown__Container}>
        <div className={styles.DashboardFilters__Dropdown__Body}>
          <div className={styles.DashboardFilters__Dropdown__Group} style={{ width: 250 }}>
            <div className={styles.DashboardFilters__Dropdown__GroupHeader}>
              <div className={`${styles.DashboardFilters__Dropdown__ClearFilter}`} onClick={clearOrganizationUnit}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div className={styles.DashboardFilters__Dropdown__GroupLabel}>Organization unit</div>
              <div>{organizationName ?? '-'}</div>
            </div>
            <div
              className={styles.DashboardFilters__Dropdown__OptionsWrapper}
              style={{ display: 'block', overflow: 'scroll' }}
            >
              <CrmOrganizationUnitTreeInner
                handleSelected={onChange}
                topOrganizationUnitUid={props.topOrganizationUnit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface DashboardFilterBarProps {
  onChangeOrganization?: (organization: { organization_unit_uid: string; name: string }) => void
  selectedOrganizationUnit?: string
  topOrganizationUnit?: string
  organizationName?: string
}

export const DashboardFilterBar: React.SFC<DashboardFilterBarProps> = props => {
  const [isExpanded, setExpand] = useState(false)
  const [ref, hasClickOutSide] = useClickOutside()

  useEffect(() => {
    let abort = true

    if (!abort && hasClickOutSide) {
      setExpand(false)
    }

    return () => {
      abort = true
    }
  }, [hasClickOutSide])

  return (
    <div className={styles.DashboardFilters__Wrapper}>
      <div className="util-flexRow">
        <div className="util-flexGrow">
          <div
            className={classNames(styles.DashboardFilters__Section, {
              [styles['DashboardFilters__Section--expanded']]: isExpanded
            })}
            style={{ minWidth: 400 }}
            ref={ref}
          >
            <div onClick={() => setExpand(!isExpanded)} className={styles.DashboardFilters__Section__Values}>
              <div className="util-flexRow">
                <div className={`${styles.DashboardFilters__FilterDropdownIconWrapper} util-flexCenterContent`}>
                  <i className="fa fa-caret-down" />
                </div>
                <div>
                  <div className={styles.DashboardFilters__Section__Label}>Organization unit</div>
                  <div className={styles.DashboardFilters__Section__Value}>{props.organizationName || '-'}</div>
                </div>
              </div>
            </div>
            <DashboardOrganizationDropDown
              isExpanded={isExpanded}
              onCloseDropDown={() => setExpand(false)}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
