import React from 'react'
import CrmDictionaryValueSelect from '../common/dictionary-value-select.jsx'

const DepartmentSelect = props => {
  return (
    <CrmDictionaryValueSelect
      {...props}
      dictionaryCode="crm.user_departments"
    />
  )
}

export default DepartmentSelect
