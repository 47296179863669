import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import SearchResultsQL from './quicksearch-results.jsx'
import { CrmTextInput } from 'crm-components'
import _ from 'lodash'

class QuicksearchWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleCloseSearchResults, false)
  }
  componentWillUnmount() {
    document.removeEventListener(
      'keydown',
      this.handleCloseSearchResults,
      false
    )
  }
  handleSearchChange = event => {
    this.setState({
      ...this.state,
      query: event.currentTarget.value,
    })
    _.debounce(() => {
      (this.state.query.length >= 3) && this.setState({ ...this.state, resultsVisible: true })
    }, 1000)()
  }
  handleCloseSearchResults = () => {
    this.setState({
      ...this.state,
      resultsVisible: false
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
      this.handleSearchProcess()
    }
  }

  handleSearchProcess = () => {
    this.setState({ resultsVisible: true })
  }

  render() {
    const style = { maxWidth: '100%', position: 'relative', height: '100%' }
    return (
      <div className="util-flexGrow util-flexCenterContent" style={style}>
        <CrmTextInput
          placeholder="Search in CRM"
          iconName="search"
          input={{
            onChange: this.handleSearchChange,
            value: this.state.query
          }}
          style={{
            border: 0,
            borderRadius: 10
          }}
          onKeyPress={this.handleKeyPress}
          onIconClick={this.handleSearchProcess}
          withBackground
          extraClassNames={{ inputClassNames: 'CrmNavbar-quickSearchInput' }}
        />
        {this.state.resultsVisible && (
          <SearchResultsQL
            query={this.state.query}
            onClickOutside={this.handleCloseSearchResults}
          />
        )}
      </div>
    )
  }
}
QuicksearchWidget.defaultProps = {}

QuicksearchWidget.propTypes = {}

const QuicksearchWidgetIntl = injectIntl(QuicksearchWidget)
export default QuicksearchWidgetIntl
