import React from 'react'
import { Formik, Form, Field } from 'formik'
import { CustomAttributesEditForm } from './custom-attributes-edit-form'
import { CrmButton } from '@cartrack-crm/ui'
import { SectionHeader } from './master-list'

interface MasterFormAttributesProps {
  masterUid: string
  initialValues: any
  master_customer: any
  isSaving: boolean
  handleUpdateAttributes: any
}

export const MasterFormAttributes: React.SFC<MasterFormAttributesProps> = (
  props
) => {
  console.log('MasterFormAttributes', props)
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ ...props.initialValues }}
        onSubmit={props.handleUpdateAttributes}
        validateOnChange
        validate={(values) => {
          const errors = {} as any
          if (!values.name) errors.name = '* required'
          return errors
        }}
      >
        {({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form>
              <fieldset
                className="Form-fieldset"
                disabled={props.initialValues?.step < 4}
              >
                <SectionHeader name="Custom Attributes" />
                <div>
                  <Field
                    render={({ field, form }) => (
                      <CustomAttributesEditForm
                        attributeDefinitions={
                          props.master_customer.attribute_definitions
                        }
                        attributes={values.attributes}
                        onChange={(field, value) => {
                          const new_values = { ...values.attributes }
                          new_values[field] = value
                          form.setFieldValue('attributes', new_values)
                        }}
                      />
                    )}
                  />
                </div>

                <br />
                <div className="row">
                  <div className="col-md-12">
                    <CrmButton
                      type="submit"
                      label="Save"
                      disabled={props.isSaving}
                      enable={!props.isSaving}
                      isSaving={props.isSaving}
                    />
                  </div>
                </div>
              </fieldset>
            </Form>
          )
        }}
      </Formik>
      <br /> <br /> <br />
    </div>
  )
}
