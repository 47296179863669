import React from 'react'
import { connect } from 'react-redux'
import { shape, func } from 'prop-types'
import AccountInfoFilter from '../list/components/filter/filters/account-info-filter.jsx'
import ProspectsListTable from './prospects-table-inner.jsx'
import { CrmFullpageContainer } from 'crm-components'

import { getQFilterGroup, initFiltersGroup } from 'crm-duxs/filters-reducer'

const accountsListCode = 'prospects'

class ProspectsListPage extends React.PureComponent {
  componentDidMount() {
    this.props.doInitFiltersGroup(accountsListCode)
  }

  render() {
    return (
      <CrmFullpageContainer vbox noInnerNav>
        <div className="util-paddingMd">
          <AccountInfoFilter accountsListCode="prospects" />
        </div>
        <ProspectsListTable
          filter={this.props.qfilter}
          exportFileName="Prospects"
          
        />
      </CrmFullpageContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    qfilter: getQFilterGroup(state, 'prospects')
  }
}
function mapDispatchToProps(dispatch) {
  return {
    doInitFiltersGroup: groupCode => dispatch(initFiltersGroup(groupCode))
  }
}

ProspectsListPage.propTypes = {
  qfilter: shape({}).isRequired,
  doInitFiltersGroup: func.isRequired
}
export default connect(mapStateToProps, mapDispatchToProps)(ProspectsListPage)
