const BULK_OPS_OPEN = 'BULK_OPS_OPEN'
const BULK_OPS_CLOSE = 'BULK_OPS_CLOSE'

const initialState = {
  isOpen: false,
  accounts: undefined
}

function bulkOperationsReducer(state = initialState, action) {
  switch (action.type) {
    case BULK_OPS_OPEN: {
      return {
        ...state,
        isOpen: true,
        accounts: [...action.payload.accounts],
        params: action.payload.params
      }
    }
    case BULK_OPS_CLOSE: {
      return { ...state, isOpen: false, accounts: undefined }
    }
    default:
      return state
  }
}

export const openBulkOperations = (accounts, params) => ({
  type: BULK_OPS_OPEN,
  payload: { accounts, params }
})

export const closeBulkOperations = () => ({
  type: BULK_OPS_CLOSE,
  payload: {}
})

export const getIsBulkOperationsOpen = state => state.crm.bulkOperations.isOpen
export const getAccounts = state => state.crm.bulkOperations.accounts
export const getBulkOperationParams = state => state.crm.bulkOperations.params

export default bulkOperationsReducer
