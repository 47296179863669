import React, { PureComponent } from 'react'
import {
  func,
  bool,
  string,
  arrayOf,
  array,
  object,
  oneOfType
} from 'prop-types'
import { connect } from 'react-redux'
import AccountsTableNew from '../accounts-table-inner.jsx'
import DefaultFilterContainer from './filter/default-filter-container.jsx'
import MoreFilterContainer from './filter/more-filter-container.jsx'
import { availableColumns } from './accounts-columns.jsx'
import AccountsViewsBar from './accounts-views-bar.jsx'
import { getIsShowMoreFilter, setFixFilter } from 'crm-duxs/accounts-reducer'
import { fetchAccountListCounts } from 'crm-duxs/accounts-list-reducer'
import { withGeneralPermissionsContextHOC } from '@cartrack-crm/crm-core'
import {
  setSortValue,
  getSortsGroup,
  getQFilterGroup,
  initFiltersGroup,
  getSelectedView
} from 'crm-duxs/filters-reducer'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class AccountListWithFilter extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isFilterExpanded: false,
      selectedAccount: undefined
    }
  }

  componentWillMount() {
    this.props.doSetFixFilter(this.props.fixFilter, this.props.accountsListCode)
    this.props.doFetchAccountsListCount()
    this.props.doInitFiltersGroup(this.props.accountsListCode)
  }

  // eslint-disable-next-line
  handleAccountSelected = account => {
    if (this.props.isModalMode === true) {
      this.props.handleRowClick(account)
    }
  }

  onHandleExportFileName = () => {
    let filename = this.props.accountsListCode

    if (
      this.props.selectedView && // eslint-disable-next-line
      this.props.selectedView.hasOwnProperty('name')
    ) {
      filename += '-' + this.props.selectedView.name
    }

    return filename.replace(' ', '_')
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.generalPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.GENERAL_DATA_MANAGE
      ) !==
      nextProps.generalPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.GENERAL_DATA_MANAGE
      )
    ) {
      this.props.doInitFiltersGroup(this.props.accountsListCode)
    }
  }

  onSortedChange = (newSorted, column, shiftKey) => {
    this.props.doSetSortValue(this.props.accountsListCode, newSorted)
  }

  render() {
    const { selectedAccount, isFilterExpanded } = this.state
    const visibleColumns = selectedAccount
      ? ['name']
      : this.props.visibleColumns
    const qfilter = { ...this.props.qfilter, ...this.props.fixQFilter }

    return (
      <div
        className={`AccountsListContainer ${
          isFilterExpanded ? 'AccountsListContainer--filterExpanded' : ''
        } 
          ${selectedAccount ? 'AccountsListContainer--withPreview' : ''}
          ${
            this.props.isShowFilter &&
            this.props.isShowMoreFilter &&
            typeof selectedAccount === 'undefined'
              ? 'AccountsListContainer--withFilter'
              : ''
          }        
        `}
      >
        {this.props.isShowFilter &&
        this.props.isShowMoreFilter &&
        typeof selectedAccount === 'undefined' ? (
          <div className="AccountsListContainer-filter">
            <MoreFilterContainer
              fixFilter={this.props.fixFilter}
              accountsListCode={this.props.accountsListCode}
              disableFilters={this.props.disableMoreFilters}
            />
          </div>
        ) : null}

        <div className="AccountsListContainer-left">
          {this.props.title && (
            <div style={{ padding: 10 }}>{this.props.title}</div>
          )}
          <AccountsViewsBar
            accountsListCode={this.props.accountsListCode}
            fixQFilter={this.props.fixQFilter}
          />
          <DefaultFilterContainer
            fixFilters={this.props.fixFilter}
            accountsListCode={this.props.accountsListCode}
            isShowFilter={this.props.isShowFilter}
            isManager={this.props.generalPermissionsContext.hasPermissionByType(
              PERMISSIONS_TYPES.GENERAL_DATA_MANAGE
            )}
            defaultColumnFilter={this.props.defaultColumnFilter}
            noOwnerFilter={this.props.noOwnerFilter}
          />

          <div className="AccountsListContainer-tableWrapper">
            <AccountsTableNew
              columns={this.props.columns}
              visibleColumns={visibleColumns}
              onAccountSelected={this.handleAccountSelected}
              accountsListCode={this.props.accountsListCode}
              selectedAccount={selectedAccount}
              style={{ width: '100%' }}
              filter={qfilter}
              exportFileName={this.onHandleExportFileName}
              isModalMode={this.props.isModalMode}
              bulkOperationElementType={this.props.bulkOperationElementType}
              onSortedChange={this.onSortedChange}
              defaultSorted={this.props.sorts[0] ? this.props.sorts : []}
              loadData={this.props.loadData ?? []}
            />
          </div>
        </div>
      </div>
    )
  }
}

AccountListWithFilter.propTypes = {
  doSetFixFilter: func.isRequired,
  handleRowClick: func,
  fixFilter: arrayOf(object),
  columns: arrayOf(object),
  disableMoreFilters: arrayOf(string),
  visibleColumns: arrayOf(string),
  isShowFilter: bool,
  isModalMode: bool, // eslint-disable-line
  title: string,
  accountsListCode: string.isRequired,
  defaultColumnFilter: string.isRequired,
  isShowMoreFilter: bool,
  generalPermissionsContext: object.isRequired,
  noOwnerFilter: bool,
  doFetchAccountsListCount: func.isRequired,
  qfilter: object.isRequired,
  fixQFilter: object.isRequired,
  doInitFiltersGroup: func.isRequired,
  selectedView: object,
  bulkOperationElementType: string,
  sorts: oneOfType([array, object]).isRequired,
  doSetSortValue: func.isRequired,
  loadData: arrayOf(string)
}

AccountListWithFilter.defaultProps = {
  getTdProps: () => ({ onClick: () => {} }),
  fixFilter: undefined,
  visibleColumn: [],
  columns: availableColumns,
  isShowTitle: true,
  isShowFilter: true,
  title: undefined,
  handleRowClick: undefined,
  isShowMoreFilter: false,
  isModalMode: false,
  disableMoreFilters: [],
  visibleColumns: [],
  noOwnerFilter: false,
  topBar: undefined,
  selectedView: {},
  bulkOperationElementType: undefined,
  loadData: []
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    doSetFixFilter: (fixFilter, accountsListCode) =>
      dispatch(setFixFilter(fixFilter, accountsListCode)),
    doFetchAccountsListCount: () =>
      dispatch(fetchAccountListCounts(ownProps.accountsListCode)),
    doInitFiltersGroup: groupCode => dispatch(initFiltersGroup(groupCode)),
    doSetSortValue: (groupCode, newSorted) =>
      dispatch(setSortValue(groupCode, newSorted))
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isShowMoreFilter: getIsShowMoreFilter(state, ownProps.accountsListCode),
    qfilter: getQFilterGroup(state, ownProps.accountsListCode),
    selectedView: getSelectedView(state, ownProps.accountsListCode),
    sorts: getSortsGroup(state, ownProps.accountsListCode || 'global')
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGeneralPermissionsContextHOC(AccountListWithFilter))
