import React from 'react'
import { arrayOf, func, shape } from 'prop-types'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip
} from 'recharts'
import IconBar from './icon-bar.jsx'

const CrmBarChart = ({ data, onClickBar }) => (
  <ResponsiveContainer width="90%" height="90%">
    <BarChart data={data} layout="vertical">
      <XAxis type="number" hide padding={{ right: 30 }} />
      <YAxis type="category" dataKey="name" hide />
      <Tooltip />
      <Bar
        dataKey="count"
        shape={
          <IconBar vehicles label="name" value="count" onClick={onClickBar} />
        }
        isAnimationActive={false}
        barSize={50}
      />
    </BarChart>
  </ResponsiveContainer>
)
CrmBarChart.propTypes = {
  data: arrayOf(shape({})).isRequired,
  onClickBar: func
}
CrmBarChart.defaultProps = {
  onClickBar: undefined
}
export default CrmBarChart
