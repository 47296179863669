import { graphql, compose } from 'react-apollo'
import { qlqAccountActivities } from 'crm-data/activities'
import { parseActivity } from 'crm-api/api-parsers'

const qlOptions = {
  // eslint-disable-next-line
  options: ownProps => ({ variables: { account_uid: ownProps.accountUid } }),
  props: ({ activitiesQl, ownProps }) => ({
    ...ownProps,
    activities:
      activitiesQl && activitiesQl.account && activitiesQl.account.activities
        ? activitiesQl.account.activities.map(parseActivity)
        : undefined,
    isLoading: activitiesQl ? activitiesQl.loading : false,
    partialRefetch: true
  }),
  partialRefetch: true
}
const ActivitiesListQLHOC = compose(
  graphql(qlqAccountActivities, {
    ...qlOptions,
    name: 'activitiesQl'
  })
)

export default ActivitiesListQLHOC
