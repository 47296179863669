import React from 'react'
import cloneDeep from 'clone-deep'
import styles from './section-participants.scss'
import { CrmLabeledField, CrmUserSelectorQL, CrmButton } from 'crm-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import AvatarPreview from 'crm-components/utils/user-avatar.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SectionParticipants extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      participants: props.participants ? cloneDeep(props.participants) : []
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.participants !== this.props.participants) {
      this.setState({
        participants: nextProps.participants
          ? cloneDeep(nextProps.participants)
          : []
      })
    }
  }

  handleClose = e => {
    e.stopPropagation()
    this.props.handleEditEnd()
  }

  handleAddParticipant = user => {
    const participants = [...this.state.participants, ...user].reduce(
      (ret, v) =>
        ret.some(e => e.user_uid === v.user_uid) ? ret : [...ret, v],
      []
    )
    this.setState({ participants })
    this.handleParticipantsChange(participants)
  }

  handleRemoveParticipant = (event, user_uid) => {
    event.stopPropagation()
    const participants = [...this.state.participants].filter(
      v => v.user_uid !== user_uid
    )
    this.setState({ participants })
    this.handleParticipantsChange(participants)
  }

  handleParticipantsChange = participants => {
    if (!this.props.activity.activity_uid) {
      this.props.handleParticipantsChange(participants)
    }
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(participants)
    }
  }

  handleHostChange = (e, user_uid, user) => {
    e.stopPropagation()
    this.props.handleHostChange(user_uid, user)
  }

  render() {
    console.log('render section-participants', this.props, this.state)
    const { participants } = this.state
    return (
      <div className={styles.SectionParticipants}>
        <div>
          <CrmLabeledField lg noMargin style={{ width: '100%' }}>
            {participants.length > 0 ? (
              <React.Fragment>
                <div className={styles.ParticipantItemsWrapper}>
                  {participants.map(v => (
                    <div key={v.user_uid} className={styles.ParticipantItem}>
                      <div className={styles.ParticipantItem__Top}>
                        <div
                          className={styles.ParticipantItem__RemoveIcon}
                          onClick={event =>
                            this.handleRemoveParticipant(event, v.user_uid)
                          }
                          title={this.props.intl.formatMessage({
                            id: 'crm.activity.click_to_remove_participant',
                            defaultMessage: 'Click to remove participant'
                          })}
                        >
                          <FontAwesomeIcon
                            className="util-marginLeft"
                            icon="times"
                          />
                        </div>
                        <div className={styles.ParticipantItem__AvatarWrapper}>
                          <AvatarPreview
                            src={v.photo_url ? v.photo_url : ''}
                            style={{
                              width: 42,
                              height: 42,
                              borderRadius: 42
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className={styles.ParticipantItem__Name}
                        title={
                          !this.props.activity.activity_uid
                            ? this.props.intl.formatMessage({
                                id: 'crm.activity.click_to_add_participant',
                                defaultMessage: 'Click to add participant'
                              })
                            : ''
                        }
                        onClick={
                          !this.props.activity.activity_uid
                            ? e => this.handleHostChange(e, v.user_uid, v)
                            : () => {}
                        }
                      >
                        <small>{v.full_name}</small>
                      </div>
                    </div>
                  ))}
                  {!this.props.activity.activity_uid && (
                    <div
                      onClick={
                        !this.props.activity.activity_uid
                          ? this.props.handleEditStart
                          : () => {}
                      }
                      className={`util-hooverable util-pointer ${styles.AddParticipantIcon} ${styles.ParticipantItem}`}
                    >
                      <div className={styles.ParticipantItem__Top}>
                        <div style={{ margin: '3px 0px' }}>
                          <FontAwesomeIcon
                            title={this.props.intl.formatMessage({
                              id: 'crm.activity.click_to_add_participant',
                              defaultMessage: 'Click to add participant'
                            })}
                            icon="user-plus"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            ) : (
              <div
                onClick={
                  !this.props.activity.activity_uid
                    ? this.props.handleEditStart
                    : () => {}
                }
                className={
                  !this.props.activity.activity_uid
                    ? 'util-hooverableContent util-hooverOp util-flexRow'
                    : ''
                }
              >
                <div style={{ padding: 3 }}>
                  <FormattedMessage
                    id="crm.activity.no_participants_click_here_to_select"
                    defaultMessage="No participants - Click here to select"
                  />
                </div>
              </div>
            )}
          </CrmLabeledField>
        </div>

        {this.props.isExpanded && (
          <React.Fragment>
            <div>
              <CrmUserSelectorQL
                enableSearch={true}
                handleUserSelect={this.handleAddParticipant}
              />
            </div>
            {!this.props.activity.activity_uid && (
              <div className="util-flexRowRight">
                <CrmButton
                  grouped
                  label={
                    <FormattedMessage
                      id="crm.ui.button.close"
                      defaultMessage="Close"
                    />
                  }
                  id="section_participants_close"
                  small
                  onClick={this.handleClose}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default injectIntl(SectionParticipants)
