import React, { PureComponent } from 'react'
import { shape, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ActionButtonForm from '../../components/action-button.jsx'
import { CrmTextInput } from 'crm-components'

class ParticipantForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { data: props.data ? props.data : {} }
    this.handleCompanyChanged = this.handleCompanyChanged.bind(this)
    this.handleFieldChanged = this.handleFieldChanged.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleInsertMyData = this.handleInsertMyData.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ ...this.state, data: nextProps.data })
    }
  }

  handleInsertMyData() {
    console.log('handleInsertMyData')
  }
  handleCompanyChanged(event) {
    console.log('handleCompanyChanged')
    console.dir(event)
    const newData = { ...this.state.data, company: event.currentTarget.value }
    this.setState({ ...this.state, data: newData })
  }
  handleSave() {
    this.props.onSave(this.state.data)
  }

  handleFieldChanged(event) {
    console.dir(event)
    const newData = { ...this.state.data }
    newData[event.currentTarget.id] = event.currentTarget.value
    this.setState({ ...this.state, data: newData })
  }

  render() {
    const { data } = this.state
    console.log('Render Participant Form')
    console.dir(this.props)
    return (
      <div>
        <div className="row util-marginBottom">
          <div className="col-md-4">
            <h5>
              <FormattedMessage
                id="crm.ui.minute.form.edit_remarks"
                defaultMessage="Edit Remarks"
              />
            </h5>
          </div>
          <div className="col-md-4" />
        </div>
        <div className="row util-marginBottom">
          <div className="col-md-4">
            <CrmTextInput
              placeholder="Author"
              placeholderId="crm.ui.textinput.author"
              id="author"
              input={{
                value: data.author,
                onChange: this.handleFieldChanged
              }}
            />
          </div>
          <div className="col-md-8">
            <CrmTextInput
              placeholder="Remarks"
              placeholderId="crm.ui.textinput.remarks"
              id="details"
              input={{
                value: data.details,
                onChange: this.handleFieldChanged
              }}
            />
          </div>
        </div>
        <div>
          <ActionButtonForm
            onSave={this.handleSave}
            onCancel={this.props.onCancel}
          />
        </div>
      </div>
    )
  }
}

ParticipantForm.propTypes = {
  onCancel: func.isRequired,
  onSave: func.isRequired,
  data: shape({}).isRequired
}

export default ParticipantForm
