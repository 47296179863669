import { DashBoardWidget } from '../dashboard-widget'
import React from 'react'
import widgetNewValidLeadsHoc, {
  widgetNewValidLeadsHocProps
} from './new-valid-lead-widget-hoc'
import { DashboardCardDescription, AnalyticDashboardWidget } from '../../../../../crm/types/index'

export const WidgetNewValidLeads: React.ComponentType<AnalyticDashboardWidget> = widgetNewValidLeadsHoc(
  (props: widgetNewValidLeadsHocProps) => (
    <DashBoardWidget
      avgDataPassedMonth={props.avgNewValidLeadsThreeMonth}
      avgDataPassedMonthPerPerson={props.avgNewValidLeadsPersonPassedMonth}
      avgPerPersonNumber={props.avgNewValidLeadsCurrentMonthPerPerson}
      footerName={props.footerName}
      footerRender={props.threeMonthAgoNewValidLeads}
      isLoading={props.isLoading}
      refetch={props.refetchData}
      dashboardDescription={DashboardCardDescription.NEW_VALID_LEAD}
      secondFooterRender={props.avgNewValidLeadsPassedMonthData}
      secondFooterName={props.footerNamePerPerson}
      subtitleSecondBlock={'Month date average'}
      title="New Valid Leads"
      totalNumber={props.totalNewValidLeads}
      onClickDetail={props.onClickDetail}
      subtitle={props.mainCaption}
    />
  )
)
