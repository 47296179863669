import React, { FC } from 'react'
import { getLeadInDateString } from '../tools/lead-utils'
interface ILeadFilterValueString {
  filterValues: any
}
export const LeadFilterValueString: FC<ILeadFilterValueString> = ({ filterValues }) => {
  if (!filterValues) {
    return <strong>All Leads </strong>
  }
  return (
    <React.Fragment>
      {!filterValues.account && <strong> All Leads </strong>}

      {filterValues.account &&
      filterValues.account.lead_campaign &&
      filterValues.account.lead_campaign.transaction_type_code ? (
        <span>
          <strong>
            {filterValues.account.lead_campaign.transaction_type_code.label}
            Lead Sources
          </strong>
        </span>
      ) : (
        <span />
      )}

      {filterValues.account &&
      filterValues.account.lead_campaign &&
      filterValues.account.lead_campaign.lead_source &&
      filterValues.account.lead_campaign.lead_source.kind ? (
        <span>
          <strong>{filterValues.account.lead_campaign.lead_source.kind.label}</strong>
        </span>
      ) : (
        <span />
      )}

      {filterValues?.account?.lead_campaign?.lead_source?.lead_source_uid ? (
        <div>
          {' Lead Source '}
          <strong>{filterValues && filterValues.account.lead_campaign.lead_source.lead_source_uid.label}</strong>
        </div>
      ) : filterValues?.account?.lead_campaign?.lead_source?.name ? (
        <div>
          {' Lead Source '}
          <strong>{filterValues && filterValues.account.lead_campaign.lead_source.name}</strong>
        </div>
      ) : (
        <span />
      )}
      {filterValues.account?.lead_campaign?.campaign_uid?.value ? (
        <span>
          {' Lead Campaign '}
          <strong>{filterValues && filterValues.account.lead_campaign.campaign_uid.label} </strong>
        </span>
      ) : (
        <span />
      )}
      {filterValues.lead_in_date && (
        <span>
          received <strong>{getLeadInDateString(filterValues)}</strong>
        </span>
      )}

      {filterValues.account && filterValues.account.transaction_type_code ? (
        <span>
          <strong>{filterValues.account.transaction_type_code.label} Leads</strong>
        </span>
      ) : (
        <span />
      )}
      {/* Page 2 */}
      {filterValues?.account?.account_type && (
        <div>
          <strong> {filterValues.account.account_type.label}</strong>
        </div>
      )}

      {filterValues?.account?.tags && (
        <div>
          <strong> {filterValues.account.tags.label}</strong>
        </div>
      )}

      {filterValues?.account?.industry && (
        <div>
          <strong> {filterValues.account.industry.label}</strong>
        </div>
      )}

      {filterValues?.account?.additional_owner_user_uid && (
        <div>
          <strong> {filterValues.account.additional_owner_user_uid.label}</strong>
        </div>
      )}

      {filterValues?.account?.city && (
        <div>
          <strong> {filterValues.account.city.label}</strong>
        </div>
      )}

      {filterValues?.account?.not_touched && (
        <div>
          <strong> {filterValues.account.not_touched.label}</strong>
        </div>
      )}

      {filterValues?.account?.touched && (
        <div>
          <strong> {filterValues.account.touched.label}</strong>
        </div>
      )}

      {filterValues?.account?.not_reached && (
        <div>
          <strong> {filterValues.account.not_reached.label}</strong>
        </div>
      )}

      {filterValues?.account?.reached && (
        <div>
          <strong> {filterValues.account.reached.label}</strong>
        </div>
      )}

      {filterValues?.account?.no_owner && (
        <div>
          <strong> {filterValues.account.no_owner.label}</strong>
        </div>
      )}

      {filterValues?.account?.include_deleted_account && (
        <div>
          <strong> {filterValues.account.include_deleted_account.label}</strong>
        </div>
      )}

      {filterValues?.account?.query?.value && (
        <span>
          Search <strong>"{filterValues?.account?.query?.value}"</strong>
        </span>
      )}
    </React.Fragment>
  )
}
