import React, { useState, useEffect } from 'react'
import { EmptyPage, CrmFullpageContainer } from '@cartrack-crm/ui'
import { ReportFilters, ReportRenderSection, WarningBox } from './report/components'
import { ReportProvider } from 'crm-utils/reports-utils'
import { useGetReportAction } from './report/hooks'

export const ReportDashboard = () => {
  const [current, setPage] = useState(undefined)
  const {
    autoDefaultFilter,
    clearFilter,
    filters,
    isCanRender,
    isReload,
    onApply,
    refreshReload,
    setCanRender
  } = useGetReportAction(current)
  useEffect(() => {
    setCanRender(false)
    clearFilter()
    autoDefaultFilter()
  }, [current?.value])

  useEffect(() => {
    autoDefaultFilter()
  }, [JSON.stringify(filters)])

  const handleSetDashboard = value => {
    setPage(value)
  }
  return (
    <CrmFullpageContainer>
      <div className="util-fullWidth util-fullHeight" style={{ display: 'flex', flexDirection: 'column' }}>
        <WarningBox />
        <ReportFilters dashboard={current} setDashboard={handleSetDashboard} onApply={onApply} />
        {!isCanRender && <EmptyPage />}
        <ReportProvider>
          {isCanRender && (
            <ReportRenderSection
              filters={filters}
              dashboard={current}
              isReload={isReload}
              refreshReload={refreshReload}
            />
          )}
        </ReportProvider>
      </div>
    </CrmFullpageContainer>
  )
}
