export const getProspectCurrentStepType = prospect =>
  prospect && prospect.process && prospect.process.current_step
    ? prospect.process.current_step.process_step_type
    : undefined

export const isProspectLost = prospect => {
  const currentStepType = getProspectCurrentStepType(prospect)
  return currentStepType ? currentStepType.kind === 'lost' : false
}
export const isProspectWin = prospect => {
  const currentStepType = getProspectCurrentStepType(prospect)
  return currentStepType ? currentStepType.kind === 'win' : false
}

export const isProspectCompleted = prospect => {
  const currentStepType = getProspectCurrentStepType(prospect)
  return currentStepType ? currentStepType.is_final_step : false
}

export const getProspectSizeFromOpportunities = () => {}
export const getProspectSize = process => {
  if (process && process.opportunities) {
    const sum = process.opportunities.reduce((a, i) => a + i.vehicle_count, 0)
    return sum > 0 ? sum + ' vehicles' : 'no details'
  }
  return ''
}
