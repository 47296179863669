import React, { SFC } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActivityTextContent,
  ActivitySubject,
  MetaItem,
  MetaRow
} from './shared.styled'
import { ActivityContentProps } from './activity-content'
import { CrmTranslateText } from '@cartrack-crm/ui'
import CrmActivityPerformer from './activity-performer'

interface EmailActivityContentProps extends ActivityContentProps {}

const EmailActivityContainer = styled.div``

const EmailTextContent = styled(ActivityTextContent)`
  margin-top: 6px;
  -webkit-line-clamp: 2;
`

const EmailActivity: SFC<EmailActivityContentProps> = (props) => {
  return (
    <EmailActivityContainer>
      <ActivitySubject oneline>{props.subject}</ActivitySubject>
      <EmailTextContent>
        <CrmTranslateText>{props.note_text}</CrmTranslateText>
      </EmailTextContent>
      <MetaRow>
        <MetaItem>
          <CrmActivityPerformer
            isOwner={props.isOwner}
            performer={props.primary_performer}
          />
        </MetaItem>
        {props.participants && props.participants.length > 0 && (
          <MetaItem>
            <FontAwesomeIcon icon="users" /> {props.participants.length}
          </MetaItem>
        )}
        {props.attachments && props.attachments.length > 0 && (
          <MetaItem>
            <FontAwesomeIcon icon="paperclip" /> {props.attachments.length}
          </MetaItem>
        )}
      </MetaRow>
    </EmailActivityContainer>
  )
}

export default EmailActivity
