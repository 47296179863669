import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { reduce } from 'lodash'
import { string, bool, shape, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Spinner from 'util-components/spinner.jsx'
import {} from 'util-components'
import ToggleDropdown from 'util-components/toggle-dropdown.jsx'
import Button from 'util-components/button.jsx'
import { getPreferences, savePreferences } from 'crm-duxs/user'

function getLoadingProps() {
  return { className: 'react-table-spinner' }
}

function getNoDataProps(tableState) {
  return { className: tableState.loading ? 'util-hide' : '' }
}

function intlizeColumnHeaders(columns) {
  return columns.map(column => ({
    ...column,
    Header:
      typeof column.Header === 'string' ? (
        <FormattedMessage
          id={`table.header.${column.Header}`}
          defaultMessage={column.Header}
        />
      ) : (
        column.Header
      )
  }))
}

function createColumnState(columns, preferences) {
  const columnVisibility =
    preferences ||
    reduce(
      columns,
      (acc, val) => {
        const name = typeof val.Header === 'function' ? 'Selected' : val.Header
        acc[name] = true
        return acc
      },
      {}
    )

  const columnOptions = { ...columnVisibility }

  return {
    columnOptions,
    columnVisibility,
    columns: preferences
      ? columns.filter(
          c => columnVisibility[c.Header] || typeof c.Header === 'function'
        )
      : columns
  }
}

const GearButton = props => <Button icon="cogs" square {...props} />

class Table extends Component {
  constructor(props) {
    super(props)

    this.state = createColumnState(this.props.columns, this.props.preferences)

    this.handleColumnChange = this.handleColumnChange.bind(this)
  }

  componentWillMount() {
    if (!this.props.preferences)
      this.props.doSavePreferences(
        this.props.preferencesId,
        this.state.columnVisibility
      )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.columns.length !== this.props.columns.length) {
      this.setState(
        createColumnState(nextProps.columns, nextProps.preferences),
        () => {
          if (!nextProps.preferences)
            nextProps.doSavePreferences(
              nextProps.preferencesId,
              this.state.columnVisibility
            )
        }
      )
    }
  }

  handleColumnChange(update) {
    console.log(update)
    const columnVisibility = {
      ...this.state.columnVisibility,
      ...update
    }

    const columns = this.props.columns.filter(
      c => columnVisibility[c.Header] || typeof c.Header === 'function'
    )

    this.props.doSavePreferences(this.props.preferencesId, columnVisibility)
    this.setState({
      columnVisibility,
      columns
    })
  }

  render() {
    return (
      <div className={`Table ${this.props.className}`}>
        <ReactTable
          {...this.props}
          minRows={this.props.minRows || 4}
          columns={
            this.props.data.length
              ? intlizeColumnHeaders(this.state.columns)
              : [{}]
          }
          className={`${this.props.tableClassName} react-table -striped -highlight`}
          LoadingComponent={Spinner}
          getLoadingProps={getLoadingProps}
          getNoDataProps={getNoDataProps}
          noDataText="No data available"
        />
        {this.props.hideOptions || !this.props.data.length ? null : (
          <ToggleDropdown
            className="Table-options"
            innerClassName="Table-options-inner"
            label="Select Columns"
            component={GearButton}
            options={this.state.columnOptions}
            values={this.state.columnVisibility}
            onChange={this.handleColumnChange}
          />
        )}
      </div>
    )
  }
}

Table.propTypes = {
  className: string,
  tableClassName: string,
  hideOptions: bool,
  preferences: shape({}),
  preferencesId: string.isRequired,
  doSavePreferences: func.isRequired,
  ...ReactTable.propTypes
}

Table.defaultProps = {
  className: '',
  tableClassName: '',
  hideOptions: false,
  preferences: null
}

function mapStateToProps(state, ownProps) {
  const preferencesId = ownProps.columns.reduce(
    (acc, c) => (typeof c.Header === 'string' ? acc + c.Header : acc),
    ''
  )

  return {
    preferences: getPreferences(state)[preferencesId],
    preferencesId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doSavePreferences: (id, preferences) =>
      dispatch(savePreferences(id, preferences))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
