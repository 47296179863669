import React from 'react'
import { _buildStyle } from './generic-widget-renderers'
// import { withSlFormRuntime } from 'crm-modules/platform/slforms/sl-forms-provider.jsx'
import { withSlFormRuntime } from '../../../slforms/sl-forms-provider'

const SlfContainerRuntime = ({ item, renderChildren }) => {
  console.log('render SlfContainer')
  const direction = item && item.direction ? item.direction : 'row'
  let style = _buildStyle(item)
  style = { ...style, marginLeft: '-10px', marginRight: '-10px' }
  style.paddingLeft = 24
  style.paddingRight = 24
  return (
    <div
      style={style}
      className={` util-paddingLg ${
        direction === 'row' ? 'util-flexRow' : 'util-flexColumn'
      }`}
    >
      {renderChildren()}
    </div>
  )
}

const SlfContainerEdit = ({ item, renderChildren }) => {
  const direction = item && item.direction ? item.direction : 'row'
  let style = _buildStyle(item)
  style = { ...style, marginLeft: '-10px', marginRight: '-10px' }
  style.paddingLeft = 24
  style.paddingRight = 24
  return (
    <div
      style={style}
      className={` util-paddingLg ${
        direction === 'row' ? 'util-flexRow' : 'util-flexColumn'
      }`}
    >
      Container Options:
      {renderChildren()}
    </div>
  )
}

const widget = {
  code: 'crm.container',
  name: 'General Container (HBox/VBox)',
  runtimeComponent: withSlFormRuntime(SlfContainerRuntime),
  editComponent: withSlFormRuntime(SlfContainerEdit)
}
export default widget
