const rmMeetingPerformance = {
  type: 'analitics_widget',
  code: 'ctDailyLeadsDashboard',
  def_version: 5,
  title: 'RM Meeting Performance',
  description: "AVG Meetings per day requires 'Activity Date' filter",
  content: {
    data: {
      dataSources: [
        {
          code: 'ActivitiesListQM',
          name: 'MeetingsAttendedAvg',
          type: 'QM',
          filter: {
            activity: {
              type: { medium_code: 'meeting' },
              status: { kind: 'completed' }
            }
          },
          groupBy: ['user_uid', 'lead_source_type_name'],
          aggregate: [{ id: 'activity_uid', type: 'count', distinct: true }],
          contextMapping: {
            start_date: 'activity_date'
          },
          qlQuery: `
          query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
            activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                edges { edge { activity_uid user_uid lead_source_type_name } }
                pageInfo { count }
              }
            }`,
          qlEdges: true,
          qlRoot: 'activity_participants'
        },
        {
          code: 'OpportunitiesMeetingsStats',
          name: 'OpportunitiesMeetingsStats',
          type: 'QM',
          filter: {},
          aggregate: [
            { id: 'meetings_till_win_count', type: 'avg' },
            { id: 'days_till_win', type: 'avg' }
          ],
          groupBy: ['owner_user_uid', 'lead_source_type_name'],
          contextMapping: {
            contract_signed_date: 'activity_date'
          },
          qlEdges: true,
          qlRoot: 'opportunity_meeting_status',
          qlQuery: `
          query opportunity_meeting_status($master_uid: String!, $instance_uid: String!, $filter: JSON, $aggregate:[JSON], $groupBy:[String]) {
            master(master_uid: $master_uid)  { 
                instance(instance_uid: $instance_uid) { 
                    opportunity_meeting_status(filter: $filter, aggregate: $aggregate, groupBy: $groupBy) 
                    {
                        edges {
                            edge {
                                  opportunity_uid
                                  account_uid
                                  vehicle_count
                                  lead_source_type_name
                                  owner_user_uid
                                  contract_signed_date
                                  meetings_till_win_count
                                  first_meeting_date
                                  days_till_win
                            }
                        }                     
                    }
                }   
            }
        }
          `
        },
        {
          code: 'OrdersListQM',
          name: 'OrdersListQM',
          type: 'QM',
          filter: {},
          fields: ['account__lead_campaign__lead_source__name'],
          aggregate: [
            { id: 'order_uid', type: 'count' },
            { id: 'subscription', type: 'sum' }
          ],
          contextMapping: {
            completed_date: 'activity_date',
            owner__user_uid: 'user_uid'
          },
          groupBy: ['owner_user_uid', 'lead_source_type_name'],
          qlOnly: true,
          qlRoot: 'orders',
          qlEdges: true,
          qlQuery: `query orders($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
            orders(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
            edges { edge { owner_user_uid order_uid subscription  lead_source_type_name } } } }`
        },
        {
          code: 'FirstFitmentsList',
          type: 'QM',
          alias: 'FirstFitments',
          filter: {
            sequence: 1,
            owner_user_uid: { $ne: 'null' },
            account_uid: { $ne: 'null' }
          },
          qlOnly: true,
          qlRoot: 'first_fitmentsc',
          qlEdges: true,
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
              master(master_uid: $master_uid)  { 
                  master_uid
                  name
                  instance(instance_uid: $instance_uid) { 
                      instance_uid
                      name
                     first_fitmentsc(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                         meta { sqls }
                         edges { 
                          edge 
                          { 
                              order_uid
                              lead_source_type_uid
                              lead_source_name
                          }
                         }
                     }
                  }
              }
          }`,
          contextMapping: { completed_date: 'activity_date', owner: 'user' },
          qlVariables: {
            groupBy: ['lead_source_type_uid', 'lead_source_name'],
            aggregate: [
              {
                id: 'order_uid',
                type: 'count'
              }
            ]
          }
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyField: 'account__lead_campaign__lead_source__name',
        actions: [
          {
            action: 'createNewField',
            field: 'meetings_count',
            operation: 'avgByActivityDate',
            filterFromDS: {
              ds: 'MeetingsAttendedAvg',
              field: 'start_date'
            },
            resultFieldName: 'avg_meetings_per_day'
          }
        ],
        mapping: [
          {
            fromDataSourceName: 'MeetingsAttendedAvg',
            keyField: 'lead_source_type_name',
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_count'
              }
            ]
          },
          {
            fromDataSourceName: 'OpportunitiesMeetingsStats',
            keyField: 'lead_source_type_name',
            mapping: [
              {
                from: 'meetings_till_win_count',
                to: 'meetings_till_win_count'
              },
              {
                from: 'days_till_win',
                to: 'days_till_win'
              }
            ]
          },
          {
            fromDataSourceName: 'FirstFitments',
            keyField: 'lead_source_name',
            mapping: [
              {
                from: 'order_uid',
                to: 'first_fitments'
              }
            ]
          },
          {
            fromDataSourceName: 'OrdersListQM',
            keyField: 'lead_source_type_name',
            mapping: [
              {
                from: 'order_uid',
                to: 'fitted_units'
              },
              {
                from: 'subscription',
                to: 'subscription'
              }
            ]
          }
        ]
      }
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: true,
            generate_average: true,
            columnGroups: [
              {
                Header: '',
                colSpan: 3
              },
              {
                Header: 'Statistics for period',
                colSpan: 8
              }
            ],
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 30
              },
              {
                Header: 'Lead Source',
                accessor: 'account__lead_campaign__lead_source__name'
              },

              {
                Header: 'AVG Meetings per day',
                accessor: 'avg_meetings_per_day',
                width: 100,
                type: 'number',
                maximumFractionDigits: 2,
                detailsPopup: 'activities',
                detailsFilter: {
                  activity_type: {
                    medium_code: 'meeting'
                  },
                  activity_status_kind: 'completed',
                  role_code: 'performer'
                },
                detailsFilterMapping: {
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                },
                contextMapping: {
                  primary_performer_user_uid: 'user'
                },
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'AVG Meetings to close deal',
                accessor: 'meetings_till_win_count',
                width: 100,
                type: 'number',
                maximumFractionDigits: 1,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunities_meetings_stats',
                detailsFilter: {},
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                }
              },
              {
                Header: 'AVG Days to Close Deal',
                accessor: 'days_till_win',
                width: 100,
                type: 'number',
                maximumFractionDigits: 0,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunities_meetings_stats',
                detailsFilter: {},
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                }
              },
              {
                Header: 'Fitments per Meeting',
                accessor: 'fitments_per_meeting',
                type: 'number',
                maximumFractionDigits: 1,
                width: 100,
                Cell: () => 'n/a'
              },
              {
                Header: 'First Fitments',
                accessor: 'first_fitments',
                width: 100,
                type: 'number',
                detailsPopup: 'first_fitments',
                detailsFilter: {
                  lead_source_name: { $ne: null }
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid',
                  lead_source_name: 'account__lead_campaign__lead_source__name'
                },
                contextMapping: {
                  completed_date: 'activity_date'
                },
                align: 'right'
              },
              {
                Header: 'Fitments',
                accessor: 'fitted_units',
                width: 100,
                type: 'number',
                detailsPopup: 'fitment',
                detailsFilter: {},
                detailsFilterMapping: {
                  owner__user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                },
                align: 'right'
              },
              {
                Header: 'ARPU',
                accessor: 'subscription',
                maximumFractionDigits: 1,
                width: 100,
                type: 'number',
                detailsPopup: 'fitment',
                detailsFilter: {},
                detailsFilterMapping: {
                  account__owner__user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                }
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}

export default rmMeetingPerformance
