import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import { FormattedMessage } from 'react-intl'
import { shape, string, func, bool, arrayOf } from 'prop-types'
import OpportunitiesList from '../opportunities/opportunities-list.jsx'
import opportunitiesListQLHOC from '../opportunities/opportunities-list-hoc'
import { CrmFormSection, CrmButton } from 'crm-components'

import OpportunityForm from '../opportunities/layout19/opportunity-form.jsx'
import cloneDeep from 'clone-deep'
import opportunityWinQl from '../opportunities/layout19/opportunity-win-ql-hoc'
import { QuotationBarWidget } from '@cartrack-crm/crm-opportunities'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { EditableContextProvider } from 'crm-core/permissions/editable-context.tsx'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class SectionOpportunities extends PureComponent {
  constructor(props) {
    super(props)

    const {
      opportunities,
      pendingOpportunities,
      lostOpportunities
    } = this.getSortOpportunities(props)

    this.state = {
      isShowingLostOpportunities: false,
      isShowingOpportunityPopup: false,
      selectedOpp: null,
      opportunities,
      pendingOpportunities,
      lostOpportunities,
      adminCorrectDataSelected: false,
      showDropDownMenu: false
    }
  }

  refMenu = React.createRef()

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.setState({ selectedOpp: this.getLastestOpportunity() })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = e => {
    if (
      this.refMenu &&
      this.refMenu.current &&
      !this.refMenu.current.contains(e.target)
    ) {
      this.setState({ showDropDownMenu: false })
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, resolve)
    })

  getSortOpportunities = props => {
    let opportunities = cloneDeep(props.opportunities)
    let pendingOpportunities = cloneDeep(props.pendingOpportunities)
    let lostOpportunities = cloneDeep(props.lostOpportunities)

    return { opportunities, pendingOpportunities, lostOpportunities }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.opportunities === this.props.opportunities) return

    const {
      opportunities,
      pendingOpportunities,
      lostOpportunities
    } = this.getSortOpportunities(nextProps)

    this.setState(
      {
        opportunities,
        pendingOpportunities,
        lostOpportunities,
        adminCorrectDataSelected: false
      },
      () => {
        let { selectedOpp } = this.state

        if (selectedOpp && selectedOpp.opportunity_uid) {
          selectedOpp = this.state.opportunities.filter(
            v => v.opportunity_uid === selectedOpp.opportunity_uid
          )[0]
        } else {
          selectedOpp = this.getLastestOpportunity()
        }

        if (!selectedOpp) {
          this.handleCloseOpportunity()
        } else {
          this.setState({ selectedOpp })
        }
      }
    )
  }

  getLastestOpportunity = () => {
    if (
      this.state.pendingOpportunities &&
      this.state.pendingOpportunities.length === 1
    ) {
      // eslint-disable-next-line standard/computed-property-even-spacing
      return this.state.pendingOpportunities[
        this.state.pendingOpportunities.length - 1
      ]
    } else {
      return null
    }
  }

  handleSelectOpportunity = opportunity_uid => {
    const opp = this.state.opportunities.filter(
      v => v.opportunity_uid === opportunity_uid
    )[0]

    this.setState({
      selectedOpp: opp,
      adminCorrectDataSelected: false
    })
  }

  handleAddNewItem = e => {
    if (e) e.stopPropagation()

    const selectedOpp = { ...this.state.selectedOpp, quantity: 1 }
    this.setState({ selectedOpp })
  }

  handleFieldChange = async (fieldName, newValue, isAdmitEdit) => {
    const selectedOpp = { ...this.state.selectedOpp }
    selectedOpp[fieldName] = newValue

    await this.setStateAsync({ selectedOpp })

    if (!this.state.selectedOpp.opportunity_uid) return

    if (isAdmitEdit) {
      await this.handleSaveAdminCorrectData()
    } else {
      await this.handleSaveOpportunity()
    }
  }

  handleDeleteOpportunity = async () => {
    var r = confirm('Are you sure you want to delete this opportunity ? ')

    if (r !== true) return

    try {
      const res = await this.props.onDeleteOpportunity(this.state.selectedOpp)
      toastr.success('Opportunity deleted')
    } catch (err) {
      toastr.error('Delete opportunity error')
    }
  }

  handleOpportunityTypeChange = async (fieldName, newValue) => {
    const selectedOpp = { ...this.state.selectedOpp }
    selectedOpp.opportunity_type_uid = newValue.opportunity_type_uid
    selectedOpp.opportunity_type = newValue

    await this.setStateAsync({ selectedOpp })

    if (!this.state.selectedOpp.opportunity_uid) return

    await this.handleSaveOpportunity()
  }

  validateQuantity = async () => {
    const opportunity = cloneDeep(this.state.selectedOpp)

    if (!opportunity.quantity || parseInt(Number(opportunity.quantity)) <= -1) {
      return 'Fleet size cannot be empty'
    }

    return ''
  }

  handleSaveOpportunity = async () => {
    try {
      const error = await this.validateQuantity()

      if (error) {
        toastr.error(error)
      } else {
        const opportunity = cloneDeep(this.state.selectedOpp)
        const res = await this.props.onSaveOpportunity(opportunity)
        toastr.success('opportunity saved')
      }
    } catch (err) {
      toastr.error('Problem saving opportunity - ' + err ? err.message : '')
    }
  }

  handleSaveAdminCorrectData = async () => {
    try {
      const opportunity = cloneDeep(this.state.selectedOpp)
      const res = await this.props.onSaveAdminCorrectData(opportunity)
      toastr.success('opportunity saved')
    } catch (err) {
      toastr.error('Problem saving opportunity - ' + err ? err.message : '')
    }
  }

  handleOnAddOpportunity = isWin => {
    let processUid

    if (this.state.pendingOpportunities.length > 0) {
      toastr.error("You can't have more than one pending opportunities")
      return
    }

    if (!processUid && this.props.process) {
      processUid = this.props.process.process_uid
    }

    if (this.props.processUid) {
      processUid = this.props.processUid
    }

    let countPendingOpportunities =
      this.props.account && this.props.account.opportunities
        ? this.props.account.opportunities.filter(op =>
            op.process ? op.process.completed_time === undefined : false
          ).length
        : 0

    if (countPendingOpportunities > 0) {
      return toastr.info(
        `You can't have more than 1 active opportunities. Win or Lost current one.`
      )
    }

    this.setState(
      {
        isWin,
        processUid,
        selectedOpportunity: {},
        selectedOpp: {}
      },
      () => {
        this.handleAddNewItem()
      }
    )
  }

  handleCloseOpportunity = () => {
    this.setState({ selectedOpp: null })
  }

  handleToggleShowLostOpportunities = isShow => {
    this.setState({ isShowingLostOpportunities: isShow })
  }

  handleOpportunitySaved = () => {
    this.props.opportunitiesQl.refetch()
  }

  _getActions() {
    const ret = []

    if (this._hasValueProposition()) {
      ret.push({
        key: 'add_opportunity',
        label: (
          <FormattedMessage
            id="crm.ui.account.prospect.add_opportunity"
            defaultMessage="+ Opportunity"
          />
        ),
        onClick: this.handleOnAddOpportunity
      })
    }

    return ret
  }

  _hasValueProposition = () => {
    const propositionLength =
      this.props.process?.attributes?.valueProposition?.value?.length ?? 0 //eslint-disable-line no-restricted-globals
    const analysisLength =
      this.props.process?.attributes?.needsAnalysis?.value?.length ?? 0 //eslint-disable-line no-restricted-globals
    return propositionLength >= 30 && analysisLength >= 30
  }

  handleOpportunityClick = selectedOpportunity => {
    this.setState({ isShowingOpportunityPopup: true, selectedOpportunity })
  }

  showDropDownMenu = () => {
    this.setState({ showDropDownMenu: !this.state.showDropDownMenu })
  }

  handleAdminCorrectDataSelected = () => {
    if (
      this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.ADMIN_MANAGE
      )
    ) {
      this.setState({ adminCorrectDataSelected: true })
    } else {
      toastr.error("You don't have permission to correct data")
    }
  }

  render() {
    const { account } = this.props
    const { selectedOpp, pendingOpportunities, lostOpportunities } = this.state
    const hasValueProposition = this._hasValueProposition()
    const isCustomer = account && account.sales_status_code === 'customer'

    return (
      <React.Fragment>
        {/* Opportunity Form */}
        {selectedOpp !== null && (
          <div className="opportynity">
            <div className="document19">
              <div className="util-flexRow">
                <div className="documentType util-flexGrow">
                  <FormattedMessage
                    id="crm.ui.account.Opportunity"
                    defaultMessage="Opportunity"
                  />{' '}
                  <small>
                    {selectedOpp.is_existing_customer ? (
                      <FormattedMessage
                        id="crm.ui.account.(existing_customer)"
                        defaultMessage="(existing customer)"
                      />
                    ) : (
                      <FormattedMessage
                        id="crm.ui.account.(new_customer)"
                        defaultMessage="(new customer)"
                      />
                    )}
                  </small>
                  <small> {selectedOpp.status_code}</small>
                </div>
                {selectedOpp && selectedOpp.opportunity_uid && (
                  <div className="util-flexCenterContent">
                    <div
                      ref={this.refMenu}
                      className="Ellipse-Dropdown"
                      onClick={this.showDropDownMenu}
                    >
                      <div className="icon-background">
                        <i className="fa fa-ellipsis-v" />
                      </div>
                      {this.state.showDropDownMenu && (
                        <ul>
                          <li onClick={this.handleAdminCorrectDataSelected}>
                            <span>
                              <FormattedMessage
                                id="crm.ui.account.admin_correct_data"
                                defaultMessage="Admin correct data"
                              />
                            </span>
                          </li>
                          <li onClick={this.props.handleShowOpportunityHistory}>
                            <span>
                              <FormattedMessage
                                id="crm.opportunity.opportunity_event_history"
                                defaultMessage="Event history"
                              />
                            </span>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
                <div className="util-flexColumnCenter">
                  <div
                    className="Ellipse-Dropdown"
                    onClick={this.handleCloseOpportunity}
                  >
                    <div className="icon-background">
                      <i className="fa fa-times" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="contentWrapper">
                <EditableContextProvider>
                  <OpportunityForm
                    account={this.props.account}
                    opportunity={selectedOpp}
                    handleOpportunityTypeChange={
                      this.handleOpportunityTypeChange
                    }
                    handleFieldChange={this.handleFieldChange}
                    handleQuotationClick={this.props.handleQuotationClick}
                    handleSaveOpportunity={this.handleSaveOpportunity}
                    handleCloseOpportunity={this.handleCloseOpportunity}
                    handleDeleteOpportunity={this.handleDeleteOpportunity}
                    isAdmin={this.props.accountPermissionsContext.hasPermissionByType(
                      PERMISSIONS_TYPES.ADMIN_MANAGE
                    )}
                    adminCorrectDataSelected={
                      this.state.adminCorrectDataSelected
                    }
                    handleQuickQuotationOpen={
                      this.props.handleQuickQuotationOpen
                    }
                  />
                </EditableContextProvider>
              </div>
            </div>
            {selectedOpp.documents && selectedOpp.documents.length > 0 && (
              <div>
                <br />
                <br />
                <div className="util-textCenter LabeledField-value">
                  <FormattedMessage
                    id="crm.ui.account.pending_quotations"
                    defaultMessage="Pending quotations"
                  />
                </div>
                <QuotationBarWidget
                  quotations={selectedOpp.documents}
                  handleQuotationClick={this.props.handleQuotationClick}
                  isShowAllQuotations={true}
                />
              </div>
            )}
          </div>
        )}

        {/* Add Opportunity */}
        {selectedOpp === null &&
          pendingOpportunities &&
          pendingOpportunities.length === 0 && (
            <div className="lostOpportunitiesWrapper">
              {!isCustomer && !hasValueProposition && (
                <div className="util-textGreen util-textCenter">
                  <FormattedMessage
                    id="crm.ui.account.prospect.fill_needs_analysis_notice"
                    defaultMessage="You have to fill 'Needs Analysis' and 'Value Proposition' before creating opportunity."
                  />
                </div>
              )}

              {(hasValueProposition || (isCustomer && process)) &&
                this.props.accountPermissionsContext.hasPermissionByType(
                  PERMISSIONS_TYPES.OPPORTUNITIES_MODIFY
                ) && (
                  <div className="util-textLight util-textCenter">
                    <FormattedMessage
                      id="crm.ui.account.opportunities.no_opportunities_for_this_account"
                      defaultMessage="There are no opportunities for this account."
                    />
                    <br />
                    <FormattedMessage
                      id="crm.ui.account.opportunities.click_add_opportunity_to_create_one"
                      defaultMessage="Click Add Opportunity to create one."
                    />
                    <br />
                    <br />
                    <CrmButton
                      label={
                        <FormattedMessage
                          id="crm.ui.button.add_opportunity"
                          defaultMessage={
                            isCustomer
                              ? 'Create Opportunity for existing Customer'
                              : 'Add Opportunity'
                          }
                        />
                      }
                      onClick={this.handleOnAddOpportunity}
                      type="primary"
                    />
                  </div>
                )}
            </div>
          )}

        {/* Pending Opportunities */}
        {selectedOpp === null &&
          pendingOpportunities &&
          pendingOpportunities.length > 0 && (
            <div className="pendingOpportunitiesWrapper">
              <div className="pendingLabel LabeledField-value">
                {pendingOpportunities.length}{' '}
                <FormattedMessage
                  id="crm.ui.account.pending_opportunities"
                  defaultMessage={
                    'Pending' + ' ' + pendingOpportunities.length > 1
                      ? 'opportunities'
                      : 'opportunity'
                  }
                />
              </div>
              <OpportunitiesList
                opportunities={pendingOpportunities}
                accountUid={account.account_uid}
                isManager={this.props.accountPermissionsContext.hasPermissionByType(
                  PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
                )}
                hasValueProposition={hasValueProposition}
                handleSelectOpportunity={this.handleSelectOpportunity}
              />
            </div>
          )}

        {/* Lost Opportunities */}
        {selectedOpp === null &&
          lostOpportunities &&
          lostOpportunities.length > 0 && (
            <div className="lostOpportunitiesWrapper">
              {!this.state.isShowingLostOpportunities && (
                <div
                  className="lostLabel LabeledField-value"
                  onClick={() => this.handleToggleShowLostOpportunities(true)}
                >
                  <FormattedMessage
                    id="crm.ui.account.show"
                    defaultMessage="Show"
                  />{' '}
                  {lostOpportunities.length}{' '}
                  <FormattedMessage
                    id="crm.ui.account.lost_opportunities"
                    defaultMessage={
                      'lost' + ' ' + lostOpportunities.length > 1
                        ? 'opportunities'
                        : 'opportunity'
                    }
                  />
                </div>
              )}
              {this.state.isShowingLostOpportunities && (
                <div
                  className="lostLabel LabeledField-value"
                  onClick={() => this.handleToggleShowLostOpportunities(false)}
                >
                  <FormattedMessage
                    id="crm.ui.account.hide_lost_opportunities"
                    defaultMessage="Hide lost opportunities"
                  />
                </div>
              )}
              {this.state.isShowingLostOpportunities && (
                <div>
                  <OpportunitiesList
                    opportunities={lostOpportunities}
                    accountUid={account.account_uid}
                    isManager={this.props.accountPermissionsContext.hasPermissionByType(
                      PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
                    )}
                    hasValueProposition={hasValueProposition}
                    handleSelectOpportunity={this.handleSelectOpportunity}
                  />
                </div>
              )}
            </div>
          )}

        <br />
        {this.props.wonOpportunities && (
          <CrmFormSection
            sectionName={
              <FormattedMessage
                id="crm.ui.account.prospect.signed_contracts"
                defaultMessage="Signed Contracts"
              />
            }
          >
            <OpportunitiesList
              opportunities={this.props.wonOpportunities}
              accountUid={account.account_uid}
              isManager={this.props.accountPermissionsContext.hasPermissionByType(
                PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
              )}
              handleSelectOpportunity={this.handleSelectOpportunity}
            />
          </CrmFormSection>
        )}
      </React.Fragment>
    )
  }
}

SectionOpportunities.defaultProps = {
  sectionName: 'Opportunities',
  opportunity: undefined,
  processUid: undefined,
  process: undefined,
  prospect: undefined,
  wonOpportunities: null
}

SectionOpportunities.propTypes = {
  account: shape({}).isRequired,
  opportunity: shape({}),
  opportunitiesQl: shape({}).isRequired,
  prospect: shape(),
  processUid: string,
  process: shape({}),
  onDeleteOpportunity: func.isRequired,
  handleQuotationClick: func.isRequired,
  opportunities: arrayOf(shape({})).isRequired,
  wonOpportunities: arrayOf(shape({})),
  onSaveOpportunity: func.isRequired,
  onSaveAdminCorrectData: func.isRequired,
  handleQuickQuotationOpen: func.isRequired,
  handleShowOpportunityHistory: func.isRequired,
  accountPermissionsContext: shape({}).isRequired
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAccountPermissionsContextHOC(
    opportunitiesListQLHOC(opportunityWinQl(SectionOpportunities))
  )
)
