import React from 'react'
import { CmpChartBars } from './renderers-charts'
import ChartColumns from './chart-columns'
import SlfContainer from './slf-container'
import SlfTable from './slf-table'
import { HorizontalTable } from './horizontal-table'
import DynamicTableViewPivot from '../../../dynamic-table2/views/dynamic-table-view-pivot'

const CrmLabeledField = (props) => {
  return <div>todo CrmLabeledField</div>
}

export const _buildStyle = (item) => {
  const ret: any = {}
  if (item && item.style) {
    if (item.style.flexGrow) {
      ret.flex = 1
    }
  }
  return ret
}

const CmpLabeledField = (props) => {
  console.log('render CmpLabeledField')
  return (
    <CrmLabeledField
      style={_buildStyle(props.item)}
      label={props.label ? props.label : undefined}
    >
      <span style={{ fontSize: 20 }}>{props.value}</span>
    </CrmLabeledField>
  )
}

const renderers = {
  container: SlfContainer,
  labeledField: CmpLabeledField,
  table: SlfTable,
  chartBars: CmpChartBars,
  chart: ChartColumns,
  tableHorizontal: HorizontalTable,
  pivot: DynamicTableViewPivot
}

export default renderers
