import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export type TASK_TYPE = {
  tasks_todo_paged?: {
    data?: {
      task_uid?: string,
      task_name?: string,
      task_type_name?: string,
      task_type_kind?: string,
      task_owner_name?: string,
      process_uid?: string,
      account_uid?: string,
      account_name?: string,
      lead_source_name?: string,
      lead_source_uid?: string,
      lead_in_date?: string,
      campaign_name?: string,
      campaign_uid?: string,
      process_name?: string,
      process_type_name?: string,
      process_type_kind?: string,
      task_due_date?: string,
      task_due_day?: string,
      assignee_type?: string,
      resource_pool_name?: string,
      current_step_name?: string,
      locked_by_user_name?: string,
      locked_till?: string,
      overdue_range?: string,
      is_overdue?: boolean,
      is_today?: boolean,
      is_future_day?: boolean,
      __typename?: boolean
    }[],
    count?: number,
    page?: {
      current_page?: number,
      item_per_page?: number,
      total_page?: number
    },
    __typename?: string
  }
}

export type TASK_VARIABLE_TYPE = {
  filter?: any,
  limit?: number,
  offset?: number,
  sort?: any[] | null,
  aggregate?: any[] | null,
  page?: number
}

export const TASK_QL = gql`
  query tasks_todo_paged($filter: JSON, $limit: Int, $offset: Int, $sort: [JSON], $aggregate: [JSON], $page: Int) {
    tasks_todo_paged(filter: $filter, limit: $limit, offset: $offset, sort: $sort, aggregate: $aggregate, page: $page) {
      data {
        task_uid
        task_name
        task_type_name
        task_type_kind
        task_owner_name
        process_uid
        account_uid
        account_name
        lead_source_name
        lead_source_uid
        lead_in_date
        campaign_name
        campaign_uid
        process_name
        process_type_name
        process_type_kind
        task_due_date
        task_due_day
        assignee_type
        resource_pool_name
        current_step_name
        locked_by_user_name
        locked_till
        overdue_range
        is_overdue
        is_today
        is_future_day
        __typename
      }
      count
      page
      __typename
    }
  }
`
type useLoadTaskType = (
  variables: TASK_VARIABLE_TYPE
) => { data: TASK_TYPE; loading: boolean; error: any; fetchMoreTask: (variables: TASK_VARIABLE_TYPE) => void, refetch: () => any }

export const useLoadTask: useLoadTaskType = variables => {
  const { data, loading, error, fetchMore, refetch } = useQuery(TASK_QL, {
    variables,
    fetchPolicy: 'cache-and-network'
  })
  const fetchMoreTask = variables => {
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          tasks_todo_paged: {
            ...fetchMoreResult.tasks_todo_paged,
            data: [...prev.tasks_todo_paged?.data, ...fetchMoreResult.tasks_todo_paged?.data],
            page: { ...fetchMoreResult.tasks_todo_paged?.page }
          }
        })
      }
    })
  }
  return { data, loading, error, fetchMoreTask, refetch }
}
