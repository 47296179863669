/* eslint-disable camelcase */
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import { FormattedMessage } from 'react-intl'
import { func, shape, bool } from 'prop-types'
import { getFormValues } from 'redux-form'
import DocumentFormInner from '../../documents/components/document-form-inner.jsx'
import { actions, accountFieldsUpdated } from 'crm-duxs/account-reducer'
import { CrmModal } from 'crm-components'
import { bindActions } from 'crm-utils/reducer-utils'
import { msToDateformat } from 'crm-utils/time-utils'

import {
  actions as documentActions,
  getDocument
} from 'crm-duxs/document-reducer'

class RegisterWinModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleOnChange = value => {
    this.setState({ ...this.state, selectedQualificationUid: value })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }
  _afterSaved() {
    this.setState({ ...this.state, isSaving: false })
    this.props.doAccountFieldsUpdated(
      this.props.account.account_uid,
      [],
      () => {
        console.log('Account Refreshed')
        this.props.onClose()
      }
    )
  }

  handlePrimaryButton = () => {
    console.log('handlePrimaryButton')
    this.handleSubmit(this.props.formValues)
  }
  handleSubmit = values => {
    console.log('Regiser win modal - submit')
    console.dir(this.props)
    console.dir(values)
    const documentValues = {
      account_uid: this.props.account.account_uid, // eslint-disable-line
      name: values.name,
      document_type_uid: values.document_type_uid, // eslint-disable-line
      items: values.items,
      opportunity_uid: values.opportunity_uid // eslint-disable-line
    }

    if (values.created_date)
      // eslint-disable-next-line
      documentValues.created_date = msToDateformat(
        values.created_date,
        'YYYY-MM-DD'
      )
    if (values.created_date)
      // eslint-disable-next-line
      documentValues.date_presented = msToDateformat(
        values.date_presented,
        'YYYY-MM-DD'
      )
    if (values.created_date)
      // eslint-disable-next-line
      documentValues.date_signed = msToDateformat(
        values.date_signed,
        'YYYY-MM-DD'
      )

    if (values.document_uid) {
      documentValues.document_uid = values.document_uid // eslint-disable-line
      documentValues.items_delete = values.items_delete // eslint-disable-line
        ? values.items_delete.split(',')
        : ''
    }

    const onSuccess = () => {
      console.log('Register win modal on Success')
      this._afterSaved()
      toastr.info('Document has been saved')
    }

    if (documentValues.document_uid) {
      console.log('update DocumentFormInner form values : ', documentValues)
      this.props.documentActions.updateDocument({
        document: documentValues,
        onSuccess
      })
    } else {
      console.log('create DocumentFormInner form values : ', documentValues)
      this.props.documentActions.createDocument({
        document: documentValues,
        onSuccess
      })
    }
  }

  handleUserSelect = value => {
    console.log('handleUserSelect')
    console.dir(value)
    this.setState({ ...this.state, user: value })
  }

  render() {
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        showFooter
        title={
          <FormattedMessage
            id="crm.ui.account.register.register_new_contract"
            defaultMessage="Register new Contract"
          />
        }
        onClose={this.props.onClose}
        primaryButtonLabel={
          <FormattedMessage
            id="crm.ui.account.register.register_new_contract"
            defaultMessage="Register new Contract"
          />
        }
        secondaryButtonLabel={
          <FormattedMessage id="crm.ui.button.cancel" defaultMessage="Cancel" />
        }
        onSecondaryButton={this.props.onCancel}
        onPrimaryButton={this.handlePrimaryButton}
      >
        {this.props.document && (
          <DocumentFormInner
            document={this.props.document}
            account={this.props.account}
            onSubmit={this.handleSubmit}
            initialValues={this.props.document}
            documentType={
              <FormattedMessage
                id="crm.ui.account.document.contract"
                defaultMessage="contract"
              />
            }
          />
        )}
      </CrmModal>
    )
  }
}

RegisterWinModal.defaultProps = {
  onCancel: () => {},
  onClose: () => {},
  onChange: () => {},
  widgetMode: false,
  document: undefined,
  formValues: undefined
}

RegisterWinModal.propTypes = {
  onCancel: func.isRequired,
  onChange: func,
  onClose: func,
  account: shape().isRequired,
  doAccountFieldsUpdated: func.isRequired,
  isOpen: bool.isRequired,
  documentActions: shape({}).isRequired,
  document: shape({}),
  formValues: shape({})
}

function mapStateToProps(state) {
  return {
    document: getDocument(state),
    formValues: getFormValues('CrmDocumentFormInner')(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    documentActions: bindActions(dispatch, documentActions),
    doSaveAccountQualification: params =>
      dispatch(actions.saveAccountQualification.create(params)),
    doAccountFieldsUpdated: (accountUid, fields, onSuccess) =>
      dispatch(accountFieldsUpdated(accountUid, fields, onSuccess))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterWinModal)
