import React from 'react'
import { string } from 'prop-types'
import gql from 'graphql-tag'
import { crmGenericCommandHoc } from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { mapAccountToServer } from 'crm-data/accounts'
import moment from 'moment'
import { getAPIDateFormat } from 'crm-utils/time-utils'
import { toastr } from 'react-redux-toastr'

const editAccountHoc = WrappedComponent => {
  class EditAccountHOC extends React.PureComponent {
    static propTypes = {
      bulkType: string
    }
    constructor(props) {
      super(props)
      this.state = {
        campaignUid: null,
        leadInDate: null
      }
    }

    handleLeadCampaignChange = (value, item) => {
      this.setState({ campaignUid: value })
    }
    handleChangeLeadCampaignBulk = async pAccount => {
      if (!this.state.campaignUid) {
        throw new Error('Please select lead campaign')
      }
      return saveLeadSourceInfoHandler(this.state.campaignUid, {
        ...this.props,
        account: pAccount,
        fieldName: 'lead_campaign_uid',
        isBulkOperation: true
      })
    }

    handleLeadInDateChange = value => {
      this.setState({ leadInDate: value })
    }
    handleChangeLeadInDateBulk = async pAccount => {
      if (!this.state.leadInDate) {
        throw new Error('Please select lead in date')
      }
      return saveLeadSourceInfoHandler(this.state.leadInDate, {
        ...this.props,
        account: pAccount,
        fieldName: 'lead_in_date',
        isBulkOperation: true
      })
    }

    render() {
      const bulkOperation =
        this.props.bulkType === 'campaign_uid'
          ? this.handleChangeLeadCampaignBulk
          : this.handleChangeLeadInDateBulk
      return (
        <WrappedComponent
          {...this.props}
          onBulkOperation={bulkOperation}
          onLeadCampaignChange={this.handleLeadCampaignChange}
          onLeadInDateChange={this.handleLeadInDateChange}
          leadInDate={this.state.leadInDate}
        />
      )
    }
  }

  const EditAccountHOCWithCommand = crmGenericCommandHoc(EditAccountHOC)

  const WithQuery = props => <EditAccountHOCWithCommand {...props} />
  return WithQuery
}

export const saveLeadSourceInfoHandler = async (
  value,
  props,
  isManagerMode = false
) => {
  if (!value && value !== '') {
    throw new Error('No value passed')
  }
  if (!props.account) {
    throw new Error('No account passed')
  }
  if (!props.account.account_uid) {
    throw new Error('Account is missing account_uid')
  }
  if (!props.fieldName) {
    throw new Error('No fieldName passed')
  }

  const accountData = { account_uid: props.account.account_uid }
  accountData[props.fieldName] = value
  const account = mapAccountToServer(accountData)
  console.log('accountData', accountData, account)
  const command = {
    type: 'account.updateLeadSourceInfo',
    aggregate_uid: account.account_uid,
    aggregate_type: 'account',
    payload: {
      account
    }
  }
  const qlRefetchField = gql`
    query account($account_uid: String!) 
    {
      account(account_uid: $account_uid) {
        account_uid
        ${props.fieldName}
      }
    }
  `
  try {
    const refetchQueries = props.isBulkOperation
      ? []
      : [
          {
            query: qlRefetchField,
            variables: {
              account_uid: account.account_uid
            }
          }
        ]
    await props.onRunCommand(command, {
      refetchQueries
    })
  } catch (err) {
    console.log('Error running command')
    throw err
  }
}

export const saveHandler = async (value, props, isManagerMode = false) => {
  if (!value && value !== '') {
    throw new Error('No value passed')
  }
  if (!props.account) {
    throw new Error('No account passed')
  }
  if (!props.account.account_uid) {
    throw new Error('Account is missing account_uid')
  }
  if (!props.fieldName) {
    throw new Error('No fieldName passed')
  }

  if (props.fieldName === 'lead_in_time') {
    const parsed = moment(value, 'DD/MM/YYYY HH:mm')
    if (!parsed.isValid()) {
      throw new Error('Lead in date has invalid format')
    } else {
      value = getAPIDateFormat(parsed) + ' ' + moment(parsed).format('HH:mm')
    }
  }

  const commandType =
    props.fieldName === 'lead_in_time' || props.fieldName === 'campaign_uid'
      ? 'account.adminUpdate'
      : 'account.update'
  const accountData = { account_uid: props.account.account_uid }
  accountData[props.fieldName] = value
  const account = mapAccountToServer(accountData)
  const command = {
    type: commandType,
    aggregate_uid: account.account_uid,
    aggregate_type: 'account',
    payload: {
      account
    }
  }

  let refetchFileds = props.fieldName
  if (props.fieldName === 'industry_uid') {
    refetchFileds = 'industry {  name, dictionary_uid }'
  }
  if (props.fieldName === 'lead_campaign_uid') {
    refetchFileds = 'lead_campaign {  name, campaign_uid }'
  }

  const qlRefetchField = gql`
    query account($account_uid: String!) 
    {
      account(account_uid: $account_uid) {
        account_uid
        ${refetchFileds}
      }
    }
  `
  try {
    const refetchQueries = [
      {
        query: qlRefetchField,
        variables: {
          account_uid: account.account_uid
        }
      }
    ]
    const result = await props.onRunCommand(command, {
      refetchQueries
    })
    if (result.data.cqCommand.payload && result.data.cqCommand.payload.error === 'duplicate_company_registration') {
      toastr.info("System does not allow duplicate company registration numbers.")
    }
  } catch (err) {
    console.log('Error running command', err)
    throw err
  }
}

export default editAccountHoc
