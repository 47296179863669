import React, { PureComponent } from 'react'
import {
  shape,
  string,
  func,
  element,
  oneOfType,
  number,
  bool
} from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { CrmDropdown, CrmDayPickerInput } from '../index'

class CrmDayHourPickerInput extends PureComponent {
  constructor(props) {
    super(props)
    // Athis.handleDayChange = this.handleDayChange.bind(this)
    // this.handleHourChange = this.handleHourChange.bind(this)
    // Build hours
    const hours = this._buildAvailableHours()
    this.state = {
      dateValue: undefined,
      hourValue: undefined,
      hours,
      time: undefined
    }
  }

  _buildAvailableHours() {
    const ret = []
    const addLeadig0 = val => (val <= 9 ? '0' + val : val)
    const _pushHour = (h, m) => {
      const label = addLeadig0(h) + ':' + addLeadig0(m)
      ret.push({
        value: label,
        name: label,
        label,
        hour: h,
        minutes: m,
        element: <div>{label}</div>
      })
    }
    for (let i = 7; i < 22; i += 1) {
      _pushHour(i, 0)
      i !== 21 && _pushHour(i, 30)
    }
    return ret
  }

  handleDayChange = value => {
    const time = this._recalculateValue(value, this.state.hourValue)
    this.setState({ dateValue: value, time })
  }

  handleHourChange = value => {
    const time = this._recalculateValue(this.state.dateValue, value)
    this.setState({ hourValue: value, time })
  }

  _recalculateValue(dateValue, hourValue) {
    if (dateValue && hourValue) {
      const hours = hourValue.split(':')
      const newStart = new Date(dateValue)
      newStart.setHours(hours[0], hours[1])
      const newStartTime = newStart.getTime()
      return newStartTime
    }
    return undefined
  }
  componentWillMount() {
    this._parseValue(this.props)
  }
  _parseValue(props) {
    if (props.input.value === undefined || props.input.value === '') {
      // eslint-disable-next-line
      this.setState({
        dateValue: undefined,
        hourValue: undefined
      })
    } else {
      const date = new Date(props.input.value)
      // eslint-disable-next-line
      this.setState({
        dateValue: date,
        hourValue:
          date
            .getHours()
            .toString()
            .padStart(2, '0') +
          ':' +
          date
            .getMinutes()
            .toString()
            .padStart(2, '0')
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.time !== prevState.time) {
      if (this.props.input && this.props.input.onChange) {
        this.props.input.onChange(this.state.time)
      }
    }
    // Observe for external value change
    if (prevProps.input !== this.props.input) {
      if (this.props.input.value !== this.state.time) {
        // Refresh time based on external value
        console.log('day hour picker set value')
        console.dir(this.props.input)
        this._parseValue(this.props)
      }
    }
  }

  render() {
    console.log('CrmDayHourPickerInput')
    console.dir(this.props)
    const { placeholder, extraClassNames } = this.props
    const { containerClassNames } = extraClassNames
    const { dateValue, hourValue, hours } = this.state
    return (
      <div className={`CrmDayHourPickerInput ${containerClassNames}`}>
        <CrmDayPickerInput
          extraClassNames={{
            containerClassNames: 'CrmDayHourPickerInput-day'
          }}
          name="start_time_date"
          component={CrmDayPickerInput}
          placeholder={
            placeholder || (
              <FormattedMessage id="crm.ui.picker.date" defaultMessage="Date" />
            )
          }
          isRequired
          input={{
            value: dateValue,
            onChange: this.handleDayChange
          }}
          disabled={this.props.disabled}
        />
        <CrmDropdown
          extraClassNames={{
            containerClassNames: 'CrmDayHourPickerInput-hour'
          }}
          name="start_time_hour"
          title={
            <FormattedMessage id="crm.ui.dropdown.hour" defaultMessage="Hour" />
          }
          component={CrmDropdown}
          options={hours}
          input={{
            value: hourValue,
            onChange: this.handleHourChange
          }}
          disabled={this.props.disabled}
        />
      </div>
    )
  }
}

CrmDayHourPickerInput.propTypes = {
  input: shape({
    value: number,
    onChange: func.isRequired
  }),
  placeholder: oneOfType([element.isRequired, string.isRequired]),
  extraClassNames: shape({
    containerClassNames: string,
    inputClassNames: string,
    placeholderClassNames: string
  }),
  disabled: bool
}

CrmDayHourPickerInput.defaultProps = {
  type: 'text',
  iconName: '',
  extraClassNames: {
    containerClassNames: '',
    inputClassNames: '',
    placeholderClassNames: ''
  },
  input: {
    value: undefined,
    onChange: () => {}
  },
  placeholder: undefined,
  disabled: false
}

export default injectIntl(CrmDayHourPickerInput)
