import React, { useState } from 'react'
import { LayoutField } from '../layout-field'
import { CrmButton } from '@cartrack-crm/ui'
import styled from 'styled-components'
import { useComponentConfigurationContext } from '../../configuration-tools/component-configuration-context'
// TODO TON - this can be dynamic and work for different key under which we store fields
// for table this can be 'rows'
// buy for pivot - rows / columns / values
// maybe some other keys for charts

type TableConfigSectionFieldsProps = {
  maxCount?: number
  fieldsProperty: string
  title: string
  item: any
}

const SectionTitle = styled.div`
  font-size: var(--crm-font-size-sm);
`
export const TableConfigSectionFields: React.SFC<TableConfigSectionFieldsProps> = (
  props
) => {
  const [newField, setNewField] = useState(undefined)
  const componentConfigurationContext = useComponentConfigurationContext()

  const { fieldsProperty } = props
  const _generateIdForNewRow = (newRow) => {
    if (!newRow.name) {
      throw new Error('No name in new row')
    }
    return newRow.name
      .toLowerCase()
      .split(' ')
      .join('_')
  }
  const onSaveRowField = (newField, index) => {
    if (!newField.id) {
      const updatedItem = { ...props.item }
      updatedItem.props[fieldsProperty] =
        updatedItem.props[fieldsProperty] || []
      var newRow = { ...newField, id: _generateIdForNewRow(newField) }
      console.log('Add new row', newRow)
      updatedItem.props[fieldsProperty] = [
        ...updatedItem.props[fieldsProperty],
        newRow
      ]

      componentConfigurationContext.updateLayoutItem(updatedItem, newField)
      //  var res = componentConfigurationContext.updateLayoutItem()
    } else {
      // Update
      const updatedItem = { ...props.item }
      var newRow = { ...newField }
      console.log('Add new row', newRow)
      const idx = updatedItem.props[fieldsProperty].findIndex(
        (r) => r.id === newRow.id
      )
      if (idx >= 0) {
        updatedItem.props[fieldsProperty][idx] = newRow
        componentConfigurationContext.updateLayoutItem(updatedItem)
      } else {
        componentConfigurationContext.updateLayoutItem(updatedItem)
      }
    }

    setNewField(undefined)
  }

  const onDeleteField = (field, index) => {
    const updatedItem = { ...props.item }
    const idx = updatedItem.props[fieldsProperty].findIndex(
      (r) => r.id === field.id
    )
    if (idx >= 0) {
      updatedItem.props[fieldsProperty]?.splice(idx, 1)
      componentConfigurationContext.updateLayoutItem(updatedItem)
    } else {
      console.log('onDelete', updatedItem)
    }
  }

  console.log(
    'Table Section fields: ',
    componentConfigurationContext.component,
    fieldsProperty
  )
  return (
    <div>
      <SectionTitle>{props.title}</SectionTitle>
      {props.item?.props[fieldsProperty]?.map((row, index) => (
        <LayoutField
          name={row.label}
          index={index}
          from={row.field}
          field={row}
          layoutItem={props.item}
          onSave={onSaveRowField}
          onDelete={onDeleteField}
        />
      ))}
      {!newField && (
        <div style={{ textAlign: 'center' }}>
          <CrmButton
            label="+ Add Field"
            small
            type="primary"
            onClick={() => {
              setNewField({})
            }}
          />
        </div>
      )}
      {newField && (
        <div>
          <LayoutField
            name={newField.label}
            isEdited={true}
            // index={index}
            from={newField.field}
            field={newField}
            layoutItem={props.item}
            onSave={onSaveRowField}
          />
        </div>
      )}
    </div>
  )
}
