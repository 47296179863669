import React, { useState } from 'react'
import { CrmButton } from '@cartrack-crm/ui'
import {
  useCrmToastr,
  CrmToastrType,
  CrmToastrPosition,
  optionParams,
  useRunApiWithToastr
} from '@cartrack-crm/ui/src/toastrs'
import {
  useForecastSendCommand,
  confirmForecastParams,
  initForecastParams,
  opportunitiesParams
} from './useForecastSendCommand'
import uuidv4 from 'uuid'
import { LoadingIcon } from 'util-components'
import { ForecastOpportunitiesListView } from './forecast-opportunities-list-view'
import { CrmInfoBlock } from 'crm-components'
import { get, sumBy } from 'lodash'

interface ForecastProps {
  forecastData: any
  month: string
  userUid?: string
  refetchParams: any
  refetch: (param: any) => any
  loading: boolean
  isFromManagePage?: boolean
}

const ForecastBody: React.FunctionComponent<ForecastProps> = props => {
  const [runApiWithToastr] = useRunApiWithToastr()
  const [showToastr, hideToastr] = useCrmToastr()
  const [totalActive, setTotalActive] = useState(0)
  const [totalLost, setTotalLost] = useState(0)
  const [totalWon, setTotalWon] = useState(0)
  const [totalPush, setTotalPush] = useState(0)
  const [totalVehicle, setTotalVehicle] = useState(0)
  const [sendInitCommand, sendConfirmCommand, sendUnlockForecastCommand] = useForecastSendCommand(props)
  const [isUnlocking, setUnlocking] = useState(false)
  const [isShowWarning, setShowWarning] = useState(false)
  let listOpportunities: opportunitiesParams[] = []

  const forecastUid = get(props, 'forecastData.forecast_uid', undefined)
  const forecastStatus = get(props, 'forecastData.status', undefined)
  const forecastData = { ...get(props, 'forecastData', {}) }
  const totalVehicleFromForecast = sumBy(get(forecastData, 'items', []), i => i.quantity * 1) || 0

  const unLockForecast = async () => {
    hideToastr()
    setUnlocking(true)

    await runApiWithToastr(() => sendUnlockForecastCommand({ forecastUid: forecastUid }), {
      successMessage: 'Unlock Forecast Success',
      errorMessage: 'Unlock Forecast Fail',
      timeToShow: 3000
    })

    await props.refetch(props.refetchParams)
    setUnlocking(false)
  }

  const callToastrConfirmUnlock = () => {
    showToastr(CrmToastrType.info, 'Are you sure to unlock this forecast ?', {
      btnPrimaryTitle: 'Unlock',
      btnSecondaryTitle: 'Cancel',
      onBtnPrimary: () => unLockForecast(),
      onBtnSecondary: () => hideToastr(),
      position: CrmToastrPosition.middle,
      timeToShow: null
    })
  }

  const initForecast = async () => {
    const params: initForecastParams = {
      forecastUid: uuidv4(),
      userUid: props.userUid,
      month: props.month
    }

    await sendInitCommand(params)

    hideToastr()

    await props.refetch(props.refetchParams)
  }

  const callToarstrToInit = () => {
    showToastr(CrmToastrType.info, 'Are you sure to create forecast ?', {
      btnPrimaryTitle: 'Yes',
      btnSecondaryTitle: 'No',
      onBtnPrimary: () => initForecast(),
      onBtnSecondary: () => hideToastr(),
      position: CrmToastrPosition.middle,
      timeToShow: null
    })
  }

  const confirmForecastCommand = async () => {
    const params: confirmForecastParams = {
      items: listOpportunities,
      forecastUid: forecastUid
    }

    hideToastr()

    await runApiWithToastr(() => sendConfirmCommand(params), {
      errorMessage: 'There was some problem to save forecast',
      successMessage: 'Forecast Saved'
    })

    await props.refetch(props.refetchParams)
  }

  const callToarstrToConfirmForecast = () => {
    const title = `Confirm forecast for ${props.month} ?`
    const options: optionParams = {
      position: CrmToastrPosition.middle,
      btnPrimaryTitle: 'Yes',
      btnSecondaryTitle: 'No',
      message: `Total forecast size: ${totalVehicle}`,
      timeToShow: null,
      onBtnPrimary: () => confirmForecastCommand(),
      onBtnSecondary: () => hideToastr()
    }

    showToastr(CrmToastrType.info, title, options)
  }

  const renderEmptyState = () => {
    return (
      <div style={{ width: '100%' }}>
        <div className="CrmForecast-Empty-Label">No Forecast / empty Forecast</div>
        <CrmButton label="Start working on forecast" onClick={callToarstrToInit} />
      </div>
    )
  }

  const onLoadedOpportunities = (summary: any, opportunities: opportunitiesParams[], hasWarning: boolean) => {
    setTotalActive(summary['active'])
    setTotalLost(summary['lost'])
    setTotalVehicle(summary['summary'])
    setTotalWon(summary['won'])
    setTotalPush(summary['push'])
    listOpportunities = opportunities
    setShowWarning(hasWarning)
  }

  const renderBodyInner = () => {
    const forecastUserUid = forecastData && forecastData.user_uid ? forecastData.user_uid : null
    const userUid = props.isFromManagePage ? forecastUserUid : props.userUid

    if (props.loading) {
      return (
        <div style={{ width: '100%' }}>
          <LoadingIcon />
        </div>
      )
    }

    if (!props.forecastData) {
      return renderEmptyState()
    }

    return (
      <ForecastOpportunitiesListView
        refetchForecast={async () => await props.refetch(props.refetchParams)}
        month={props.month}
        onLoadedOpportunities={onLoadedOpportunities}
        forecastData={forecastData}
        userUid={userUid}
      />
    )
  }

  const showForecastConfirm = forecastStatus === 'forecast.hadInit'
  const forecastHasConfirmed = forecastStatus === 'forecast.confirmed'

  return (
    <React.Fragment>
      {forecastHasConfirmed && (
        <div className="util-alignCenter util-flex util-paddingMd ">
          <CrmInfoBlock hint className="col-md-7">
            This forecast has been confirmed
            {props.isFromManagePage && (
              <CrmButton
                small
                label="Unlock forecast"
                icon="unlock"
                className="util-bgGray util-marginLeft util-alignItemCenter"
                onClick={callToastrConfirmUnlock}
              />
            )}
          </CrmInfoBlock>
        </div>
      )}
      <div style={{ marginBottom: '5%' }} className="util-overflowAuto util-textCenter util-flexColumn util-fullHeight">
        {renderBodyInner()}
      </div>
      {props.forecastData && (
        <div className={`CrmForecast-Footer${props.isFromManagePage ? '-Short' : ''} util-flexGrow`}>
          <div className="CrmForecast-ForecastSummary">
            {forecastHasConfirmed && (
              <div className="CrmForecast-ForecastSummary Items">
                <label className="util-fontWeightMedium util-textMedium col-md-2 Item">
                  Forecast: {totalVehicleFromForecast}
                </label>
                <label className="util-fontWeightMedium util-textMedium col-md-2 status push Item">
                  Pushed: {totalPush}
                </label>
                <label className="util-fontWeightMedium util-textMedium col-md-2 status active Item">
                  Active: {totalActive}
                </label>
                <label className="util-fontWeightMedium util-textMedium col-md-2 status lost Item">
                  Lost: {totalLost}
                </label>
                <label className="util-fontWeightMedium util-textMedium col-md-2 status won Item">
                  Won: {totalWon}
                </label>
              </div>
            )}
            {!isShowWarning && showForecastConfirm && (
              <div className="util-alignCenter util-flex Confirm">
                <CrmButton label="Confirm Forecast" onClick={callToarstrToConfirmForecast} type="primary" />
              </div>
            )}
            {props.forecastData && isShowWarning && showForecastConfirm && (
              <div className="util-alignCenter util-flex Warning">
                <CrmInfoBlock error>
                  Some opportunities are overdue or are missing some data. Correct to continue.
                </CrmInfoBlock>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default ForecastBody
