import { createSelector } from 'reselect'
import { getFixFilters } from './accounts-list-reducer'
import { findValueFromFilter } from 'crm-utils/filter-utils'

// Actions
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS'
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS'

export const SELECT_ACCOUNT = 'SELECT_ACCOUNT'

export const SET_NEAR_BY_RESULT = 'SET_NEAR_BY_RESULT'
export const SET_OPTIONS_ACCOUNT_TABLE = 'SET_OPTIONS_ACCOUNT_TABLE'
export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const TOGGLE_FILTER_SUCCESS = 'TOGGLE_FILTER_SUCCESS'
export const SET_CURRENT_FILTER_VALUE = 'SET_CURRENT_FILTER_VALUE'
export const UPDATE_FILTER = 'UPDATE_FILTER'

export const SEARCH_PLACE = 'SEARCH_PLACE'

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_SORT_CONDITIONS = 'SET_SORT_CONDITIONS'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
export const TOGGLE_SHOW_MORE_FILTER = 'TOGGLE_SHOW_MORE_FILTER'

export const SET_FIX_FILTER = 'SET_FIX_FILTER'

export const INIT_ACCOUNTS_MODULE = 'INIT_ACCOUNTS_MODULE'
export const GOTO_NEW_ACCOUNT = 'GOTO_NEW_ACCOUNT'

// Reducer
const initialState = {
  qualificationTypes: [],
  campaigns: [],
  leadSources: []
}

export const nameOfFilters = {
  isEnableAddressFilter: 'address',
  isEnableContactDetailFilter: 'contact_detail',
  isEnableSaleFilter: 'owner',
  isEnableTMFilter: 'tm',
  isEnableQualificationFilter: 'qualification',
  isEnableAccountAttibuteFilter: 'account_more',
  isEnableDuringProcessFilter: 'during_process',
  isEnableCampaignFilter: 'campaign',
  isEnableLeadSourceFilter: 'lead_source',
  isEnableNearByFilter: 'nearby',
  isEnableLeadInDateFilter: 'lead_in_date',
  isEnableCreatedTimeFilter: 'created_time',
  isEnableCreatedByUserFilter: 'created_by_user'
}

export default function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload.account
      }

    default:
      return state
  }
}

// Note - this must be duplicated as causes problem with recursive imports
export const getCurrentFilters = (state, listCode) =>
  state.crm['accountsList_' + listCode].currentFilters
// Action Creators
export function fetchAccounts(query, params) {
  return {
    type: FETCH_ACCOUNTS,
    payload: {
      query,
      pagination_options: {
        page: params.page,
        pageSize: params.pageSize,
        sortConditions: params.sort
      },
      accountsListCode: params.accountsListCode
    }
  }
}

export function selectAccount(account) {
  return {
    type: SELECT_ACCOUNT,
    payload: {
      account
    }
  }
}
export function setSortConditions(sortConditions, accountsListCode) {
  return {
    type: SET_SORT_CONDITIONS,
    payload: {
      sortConditions,
      accountsListCode
    }
  }
}
export function setCurrentPage(currentPage, accountsListCode) {
  return {
    type: SET_CURRENT_PAGE,
    payload: {
      currentPage,
      accountsListCode
    }
  }
}

export function setPageSize(pageSize, accountsListCode) {
  return {
    type: SET_PAGE_SIZE,
    payload: {
      pageSize,
      accountsListCode
    }
  }
}

export const toggleShowMoreFilter = accountsListCode => ({
  type: TOGGLE_SHOW_MORE_FILTER,
  payload: {
    accountsListCode
  }
})

export const toggleFilter = (
  filterStatusName,
  disableFilters,
  accountsListCode
) => ({
  type: TOGGLE_FILTER,
  payload: {
    filterStatusName,
    disableFilters,
    accountsListCode
  }
})

export const gotoNewAccount = () => ({ type: GOTO_NEW_ACCOUNT })
export const updateFilter = (filterName, conditions, accountsListCode) => ({
  type: UPDATE_FILTER,
  payload: {
    filterName,
    conditions,
    accountsListCode
  }
})

export const searchPlace = (accountsListCode, query) => ({
  type: SEARCH_PLACE,
  payload: {
    query,
    accountsListCode
  }
})

export const setFixFilter = (fixFilters, accountsListCode) => ({
  type: SET_FIX_FILTER,
  payload: {
    fixFilters,
    accountsListCode
  }
})

// Selectors
export const getAccounts = state => state.crm.accounts.accounts
export const getSelectedAccount = state => state.crm.accounts.selectedAccount
export const getCountRows = (state, listCode) =>
  state.crm['accountsList_' + listCode].countRows

export const getQueries = (state, listCode) =>
  listCode ? state.crm['accountsList_' + listCode].queries : undefined
export const getCurrentPage = (state, listCode) =>
  listCode ? state.crm['accountsList_' + listCode].currentPage : undefined
export const getPageSize = (state, listCode) =>
  state.crm['accountsList_' + listCode].pageSize

export const getPageSorts = (state, listCode) =>
  state.crm['accountsList_' + listCode].sorts

export const getIsLoading = (state, listCode) =>
  state.crm['accountsList_' + listCode].isLoading

export const getAccountsListByCode = (state, listCode) =>
  state.crm['accountsList_' + listCode].accounts

export const getIsShowMoreFilter = (state, listCode) =>
  state.crm['accountsList_' + listCode].isShowMoreFilter

export const getNearByItems = (state, listCode) =>
  state.crm['accountsList_' + listCode].nearByItems
export const nearbyKeyword = (state, listCode) =>
  state.crm['accountsList_' + listCode].nearbyKeyword

export const getIsEnableFilterStatus = (
  state,
  varName,
  disableFilters,
  listCode
) => {
  const isDefineDisable = disableFilters.find(
    filterName => filterName === nameOfFilters[varName]
  )
  let isDisable = false
  if (isDefineDisable) isDisable = true
  return { value: state.crm['accountsList_' + listCode][varName], isDisable }
}

const isShowFilterIcon = currentFilters => {
  const filters = currentFilters.filter(
    filter =>
      filter.code !== 'account_info' &&
      filter.params &&
      filter.params.length > 0
  )
  if (filters.length > 0) return true
  return false
}
export const getIsShowFilterIcon = createSelector(
  getCurrentFilters,
  isShowFilterIcon
)

export const getValueFromFilterByKey = createSelector(
  (state, filterName, key, listCode) => getFixFilters(state, listCode),
  (state, filterName, key, listCode) => getCurrentFilters(state, listCode),
  (state, filterName) => filterName,
  (state, filterName, key) => key,
  (state, filterName, key, listCode) => listCode,
  // eslint-disable-next-line
  (fixFilter, currentFilter, filterName, key, listCode) =>
    findValueFromFilter(fixFilter, currentFilter, filterName, key, listCode)
)

// eslint-disable-next-line
const mapSelectableToAtts = (choies, state, listCode, filterCode, UIDKey) => {
  const atts = {}
  choies.forEach(type => {
    atts[`${type[UIDKey]}`] = getValueFromFilterByKey(
      state,
      filterCode,
      type[UIDKey],
      listCode
    )
  })
  return atts
}
export const getMapSelectableToAtts = createSelector(
  (state, choies) => choies,
  state => state,
  (state, choies, listCode) => listCode,
  (state, choies, listCode, filterCode) => filterCode,
  // eslint-disable-next-line
  (state, choies, listCode, filterCode, UIDKey) => UIDKey,
  mapSelectableToAtts
)
