import React, { useState } from 'react'
import moment from 'moment'
import { CrmFullpageContainer } from 'crm-components'
import { DashboardFilterBar } from '../../dashboards/tools'
import useCrmCurrentUser from '../../../../core/auth/hooks/use-crm-current-user'
import { useGetUserInformation } from 'crm-duxs/user-session'
import { useCrmComponent } from 'crm-utils/crm-component-util'
import { GenericAnalyticsWidget } from '@cartrack-crm/crm-analytics'
import { last } from 'lodash'
import { getAPIDateFormat, getDateRangeByLabel } from 'crm-utils/time-utils'

interface IDashboardRoiInner {
  organizationUnit?: string
  analyticsContext?: any
}

const DashboardRoiInner: React.ComponentType<IDashboardRoiInner> = (props: IDashboardRoiInner) => {
  const { getComponentByFilter } = useCrmComponent(props)
  const { components, loading } = getComponentByFilter({
    code: 'roiMonthlyLeadResult'
  })
  const ctMonthlyLeadsDefinition = last(components)
  const filterCurrentYear = props.analyticsContext.filters

  return (
    <div className="util-fullHeight util-paddingLg">
      <React.Fragment>
        {!loading && ctMonthlyLeadsDefinition && (
          <GenericAnalyticsWidget
            key={ctMonthlyLeadsDefinition?.component_uid}
            title={ctMonthlyLeadsDefinition?.name}
            style={{ maxHeight: 400, margin: 0 }}
            widgetDefinition={ctMonthlyLeadsDefinition}
            filters={filterCurrentYear}
          />
        )}
      </React.Fragment>
    </div>
  )
}

export const DashboardROI = (props: any) => {
  const [selectedOrganizationUnit, setOrganizationUnit] = useState<string>(undefined)
  const [organizationName, setOrganizationName] = useState<string>(undefined)
  const currentUser = useCrmCurrentUser()
  const userUid = currentUser?.user_uid
  const { getOrganizationUnit } = useGetUserInformation(userUid)

  const onChangeOrganization = (organization): void => {
    setOrganizationName(organization?.name)
    setOrganizationUnit(organization?.organization_unit_uid)
  }

  const buildCurrentActivityDateRange = () => {
    const dateRange = getDateRangeByLabel('lastyear', true)

    return {
      $gte: getAPIDateFormat(dateRange.from),
      $lte: getAPIDateFormat(dateRange.to)
    }
  }

  const organizationUnit = getOrganizationUnit()

  const currentOrganizationUnit = selectedOrganizationUnit || organizationUnit?.organization_unit_uid
  const currentOrganizationName = organizationName || organizationUnit?.name

  const analyticsContext = {
    filters: {
      activity_date: {
        value: buildCurrentActivityDateRange()
      },
      organization_unit: {
        organization_unit_uid: {
          value: currentOrganizationUnit
        }
      }
    }
  }

  return (
    <CrmFullpageContainer>
      <DashboardFilterBar
        selectedOrganizationUnit={currentOrganizationUnit}
        onChangeOrganization={onChangeOrganization}
        organizationName={currentOrganizationName}
        topOrganizationUnit={organizationUnit?.organization_unit_uid}
      />
      <DashboardRoiInner organizationUnit={currentOrganizationUnit} analyticsContext={analyticsContext} />
    </CrmFullpageContainer>
  )
}
