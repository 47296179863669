import React from 'react'
import { useRunApiWithToastr } from '@cartrack-crm/ui/src/toastrs/'

export const withToastrWithRunApiHoc = (Component: any) => {
  return (props: any) => {
    const [runApiWithToastr] = useRunApiWithToastr()
    return <Component runApiWithToastr={runApiWithToastr} {...props} />
  }
}

