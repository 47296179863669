import React, { useEffect, useState } from 'react'
import { func, shape } from 'prop-types'
import { withApollo } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import gql from 'graphql-tag'
import AccountPageRightSidePanel from '../accounts/account/account-page-right-side-panel.jsx'
import { CrmLabeledField, CrmJsonPreview } from 'crm-components'
import moment from 'moment'
import { CrmButton } from '@cartrack-crm/ui'
import { LoadingIcon } from 'util-components'

const ActivityEmailLogPopup = props => {
  const [logs, setLogs] = useState([])
  const [isLoading, setLoading] = useState(true)
  const { activity } = props
  useEffect(() => {
    setLoading(true)
    if (props.activity) {
      const { activity_uid } = props.activity
      props.client
        .query({
          query: query,
          variables: {
            activity_uid
          }
        })
        .then(({ data }) => {
          const result = data.activity_email_logs
          setLogs(result)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }, activity)
  return (
    <AccountPageRightSidePanel
      preventClosingOnClickOutside={true}
      isOpen={activity !== undefined}
      title={
        <FormattedMessage
          id="crm.ui.activity.edit_activity"
          defaultMessage="Edit Activity"
        />
      }
      onClose={props.onClose}
      onClickOutside={props.onClose}
    >
      <div style={{ margin: 0, marginBottom: 20, padding: 20 }}>
        <h4>Email Logs</h4>
        {isLoading && <LoadingIcon />}
        {!isLoading &&
          logs.map(log => <LogInner log={log} key={log.email_queue_uid} />)}
        <div className="col-md-12 util-alignRight">
          <CrmButton
            grouped
            icon="close"
            label="Close"
            onClick={props.onClose}
          />
        </div>
      </div>
    </AccountPageRightSidePanel>
  )
}

const LogInner = ({ log }) => {
  const [showParams, setShowParams] = useState(false)
  return (
    <div className="util-flexRowSpaceBetween util-flexGrow CrmMdCard util-paddingMd util-marginBottom">
      <div className="util-fullWidth util-pointer util-hooverable">
        <div>
          <CrmButton
            small
            onClick={() => setShowParams(!showParams)}
            label={!showParams ? 'Show Params' : 'Hide'}
            style={{ marginBottom: 20 }}
          />
          {showParams && <CrmJsonPreview json={log.params} />}
          <div className="util-textInfo">Subject: {log.title}</div>
          <hr />
          <div
            className="util-textInfo"
            style={{ marginBottom: 30 }}
            dangerouslySetInnerHTML={{ __html: log.message }}
          />
          <div className="row">
            <div className="col-md-4">
              <CrmLabeledField label="Status">{log.status}</CrmLabeledField>
            </div>
            <div className="col-md-8">
              <CrmLabeledField label="Sent Time">
                {dateFormat(log.sent_time)}
              </CrmLabeledField>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <CrmLabeledField label="Remark">{log.remark}</CrmLabeledField>
            </div>
            <div className="col-md-8">
              <CrmLabeledField label="Created Time">
                {dateFormat(log.created_time)}
              </CrmLabeledField>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const dateFormat = date => {
  if (date) return moment(date).format('YYYY/MM/DD HH:mm')
  return ''
}

ActivityEmailLogPopup.propTypes = {
  activity: shape({}),
  onClose: func.isRequired
}

ActivityEmailLogPopup.defaultProps = {
  activity: undefined
}

const query = gql`
  query activity_email_logs($activity_uid: String) {
    activity_email_logs(activity_uid: $activity_uid) {
      email_queue_uid
      params
      status
      title
      message
      remark
      created_time
      sent_time
    }
  }
`

export default withApollo(ActivityEmailLogPopup)
