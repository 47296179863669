import React from 'react'
import { bool } from 'prop-types'
import { connect } from 'react-redux'

const visibleBySelector = (Wrapped, selector) => {
  const VisibleBySelector = props =>
    props.__visible ? <Wrapped {...props} /> : null

  VisibleBySelector.propTypes = {
    __visible: bool
  }

  VisibleBySelector.defaultProps = {
    __visible: false
  }

  function mapStateToProps(state) {
    return {
      __visible: selector(state)
    }
  }

  return connect(mapStateToProps)(VisibleBySelector)
}

export default visibleBySelector
