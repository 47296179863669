import React, { FC, useState } from 'react'
import { LayoutItem } from './layout-item'
import withLayoutAction, { withLayoutActionProps } from './layout-action-hoc'
import {
  CrmCard,
  CrmButton,
  CrmModal,
  CrmJsonPreview,
  CrmDropdown
} from '@cartrack-crm/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCode } from '@fortawesome/free-solid-svg-icons'
import { useComponentConfigurationContext } from '../configuration-tools/component-configuration-context'
import styled from 'styled-components'

export const LayoutCrmCardWrapper = styled(CrmCard)`
  padding: var(--crm-padding-08);
  box-shadow: unset;
`
interface ILayoutPanel extends withLayoutActionProps {
  layout: any
  onSaveLayout: Function
  isDebug?: boolean
}

const LayoutPanel: FC<ILayoutPanel> = (props) => {
  const [newLayoutItem, setNewLayoutItem] = useState<any>(undefined)
  const [isShowwingJsonPopup, setIsShowingJsonPopup] = useState(false)
  const componentConfigurationContext = useComponentConfigurationContext()
  const layout = componentConfigurationContext.component?.content?.layout || {}
  console.log('LayoutPanel.newLayoutField', newLayoutItem)
  const handleSaveNewLayoutItem = () => {
    console.log('handleSaveNewLayoutItem', newLayoutItem)
    // TODO TON
    componentConfigurationContext.addLayoutItem(newLayoutItem)
    setNewLayoutItem(undefined)
  }

  const layoutTypeOptions = [
    { name: 'Table', value: 'table' },
    { name: 'Horizontal Table', value: 'table_horizontal' },
    { name: 'Pivot', value: 'pivot' },
    { name: 'Chart', value: 'chart' },
    { name: 'Text Field', value: 'labeled_field' }
  ]

  return (
    <React.Fragment>
      <LayoutCrmCardWrapper>
        <div className="row">
          <div className="col-md-6">
            <strong>Layout</strong>
          </div>
          <div className="col-md-3">
            {props.layout?.items?.length === 0 && (
              <CrmButton label={<FontAwesomeIcon icon={faPlus} />} />
            )}
          </div>
          <div className="col-md-3">
            <CrmButton
              small
              style={{ minWidth: '0 !important', margin: 0 }}
              className="util-marginLeft"
              icon={faCode}
              iconButton
              onClick={() => setIsShowingJsonPopup(true)}
            />
          </div>
        </div>
        {/* TODO TON: Here in future you can add general layout props - like main direction row/column , maybe background color or so */}
        {layout?.items?.map((item, index) => (
          <LayoutItem
            item={item}
            index={index}
            key={item}
            onSaveItem={props.onSaveItem}
          />
        ))}

        <CrmModal
          isOpen={isShowwingJsonPopup}
          onClose={() => setIsShowingJsonPopup(false)}
        >
          <div style={{ height: '100%' }} className="util-flexColumn">
            <h5>Layout:</h5>
            <CrmJsonPreview json={layout ?? {}} />
          </div>
        </CrmModal>

        {!newLayoutItem && (
          <div>
            <CrmButton
              label="Add Element"
              onClick={() => {
                setNewLayoutItem({})
              }}
            />
          </div>
        )}

        {newLayoutItem && (
          <div>
            <CrmDropdown
              options={layoutTypeOptions}
              input={{
                onChange: (type) => {
                  console.log(type)
                  setNewLayoutItem({ ...newLayoutItem, type })
                },
                value: newLayoutItem.type
              }}
            />
            <div>
              <CrmButton
                label="Cancel"
                small
                type="secondary"
                onClick={() => {
                  setNewLayoutItem(undefined)
                }}
              />

              <CrmButton
                label="Save"
                type="primary"
                small
                onClick={handleSaveNewLayoutItem}
              />
            </div>
          </div>
        )}
      </LayoutCrmCardWrapper>
    </React.Fragment>
  )
}

export default withLayoutAction(LayoutPanel)
