import { callQLApi } from 'crm-api/api-utils'

export const leadAnalyticsFields = `account_uid name campaign_uid campaign_name
lead_source_type status lead_in_date  last_activity_ago
opportunity_due_date opportunity_size opportunity_confidence_level 
opportunity_reason_lost opportunity_current_step_type
opportunity_confidence_level last_activity_date
qualification_name qualification_code 
last_activity_note owner_user_uid owner_user_name units_fitted `

const qlqLeadsStatsByStatusSummary = `
query leadsStatsByStatusSummary($analyticsContext: JSON, $filter:JSON) {
  leadsStatsByStatusSummary(analyticsContext: $analyticsContext, filter: $filter) {
    ${leadAnalyticsFields} count
  }
}
`

export const listLeadsByStatusSummary = (filter, analyticsContext) => {
  const variables = {
    filter,
    analyticsContext
  }

  return callQLApi(qlqLeadsStatsByStatusSummary, variables).then(
    res => res.leadsStatsByStatusSummary
  )
}

export const listLeadsDetails = ({ filters }) => {
  const query = `{ leadsAnalytics${
    filters && filters.length > 0 ? '(' + filters.join(',') + ')' : ''
  } { ${leadAnalyticsFields} } }`

  return callQLApi(query).then(res => res.leadsAnalytics)
}

export const uncompletedMeetingsStats = filters => {
  const query = `{ uncompletedMeetingsStats${
    filters && filters.length > 0 ? '(' + filters.join(',') + ')' : ''
  } { ${leadAnalyticsFields} count } }`

  return callQLApi(query).then(res => res.uncompletedMeetingsStats)
}

export const leadsStatsGeneric = (group, filter) => {
  console.log('leadsStatsGeneric', group, filter)
  // Cconst groupString = group ? group.map(g => `"${g}"`).join(',') : ''
  const query = `query leadsStatsGeneric($group:[String], $filter:JSON) 
    { leadsStatsGeneric(group:$group, filter: $filter) 
      { ${leadAnalyticsFields} count } } `

  const variables = {
    group,
    filter
  }
  return callQLApi(query, variables).then(res => res.leadsStatsGeneric)
}

export const mock = 1
