import React, { Component } from 'react'
import { func, bool } from 'prop-types'
import CrmAddressInlineForm from './crm-address-inline-form.jsx'
import { CrmDropdown, CrmButton } from 'crm-components'

class CrmAddressSelect extends Component {
  constructor(props) {
    super(props)
    this.state = { add: false }
  }

  handleAdd = () => {
    this.setState({ add: true })
  }

  handleSubmit = values => {
    console.log('Address activity form : ', values)
    this.handleCancel()
    this.props.onAddNewAddress(values)
  }

  handleCancel = () => {
    this.setState({ add: false })
  }
  handleSaved = () => {
    console.log('handleSaved')
    this.setState({ add: false })
  }
  addressForm() {
    return (
      <div className="row">
        <CrmAddressInlineForm
          accountUid={this.props.accountUid}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          onSaved={this.handleSaved}
        />
      </div>
    )
  }

  render() {
    console.log('Render CrmAddressSelect', this.props)
    const newOptions = this.props.options.slice()
    return (
      <div className="CrmAddressSelect-Container">
        <div className="row">
          <div className="col-md-10">
            <CrmDropdown {...this.props} options={newOptions} />
          </div>
          {!this.props.saving && (
            <div className="col-md-2">
              <CrmButton xsmall icon="plus" onClick={this.handleAdd} />
            </div>
          )}
          {this.state.add && !this.props.saving && this.addressForm()}
        </div>
        {this.props.saving && (
          <div className="row">
            <p>Saving..</p>
          </div>
        )}
      </div>
    )
  }
}

CrmAddressSelect.propTypes = {
  ...CrmDropdown.propTypes,
  onAddNewAddress: func.isRequired,
  saving: bool.isRequired
}

CrmAddressSelect.defaultProps = {
  ...CrmDropdown.defaultProps
}

export default CrmAddressSelect
