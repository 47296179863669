import React from 'react'
import { string, shape, bool } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'

const qlqInstance = gql`
  {
    instances {
      instance_uid
      name
    }
  }
`

const CrmInstanceSelect = props => {
  const variables = {}
  return (
    <Query query={qlqInstance} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.instances
            ? data.instances.map(v => ({
                ...v,
                value: v.instance_uid
              }))
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_instance"
                  defaultMessage="Select Instance"
                />
              )
            }
          />
        )
      }}
    </Query>
  )
}

CrmInstanceSelect.propTypes = {
  placeholder: string,
  input: shape({}),
  disabled: bool,
  labeled: bool
}

CrmInstanceSelect.defafultProps = {
  disabled: false
}

export default CrmInstanceSelect
