import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router'
import { withRouter, Link } from 'react-router-dom'
import { initialize, submit } from 'redux-form'
import { shape, func, bool, arrayOf, string } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl'
import ConvertToProspectModal from '../../qualification/convert-to-prospect-modal.jsx'
import AccountQualificationWidget from '../../qualification/account-qualification-widget2.jsx'
import LeadActionsWidget from '../lead/lead-actions-widget.jsx'
import AccountHistoryModal from './account-history-modal.jsx'
import accountPageQLHOC, {
  accountPageHocPropTypes
} from './account-page-ql-hoc'
import AccountForm from './account-form.jsx'
import TabAdditionalInfo from './tabs/tab_additional_info.jsx'
import TabDocuments from './tabs/tab_documents.jsx'
import TabFitments from './tabs/tab_fitments.jsx'
import TabOpportunities from './tabs/tab-opportunities.jsx'
import ConvertLeadToAccountModal from './_convert-lead-to-account.jsx'
import AccountMergingSelector from './_account-merging-selector.jsx'
import QualifyModal from './_qualify-modal.jsx'
import MarketPotentialModal from './_market-potential-modal.jsx'
import SaleProgressWidget from './dialer/sales-progress-widget.jsx'
import ScriptCallWindow from './script-call/script-call-window.jsx'
import LostProspectModal from './lost/lost-prospect-modal'
import LastSalesResult from './sales/last-sales-result.jsx'
// eslint-disable-next-line
import { getAccountStatus, findMainProcess } from 'crm-utils/accounts-utils'
import AccountActionsButton from './account-actions-button.jsx'
import {
  CrmFullpageContainer,
  CrmButton,
  CrmInfoBlock,
  CrmTabs,
  CrmModal
} from 'crm-components'
import { bindActions } from 'crm-utils/reducer-utils'
import {
  startAccountEdit,
  getChildAccountDeleted,
  actions as accountActions,
  markAsCustomer,
  accountFieldsUpdated
} from 'crm-duxs/account-reducer'
import uuidv4 from 'uuid'
import { LoadingIcon } from 'util-components'
import AccountPageHeader19 from './account-page-header-19.jsx'
import Confetti from 'react-confetti'
import AccountPageRightPanel from '../account-page-right-side-panel.jsx'
import {
  QuotationForm19,
  QuickQuotationGenerator
} from '@cartrack-crm/crm-opportunities'
import OpportunityBarWidget from '../opportunities/layout19/opportunity-bar-widget.jsx'
import { calculateCurrentStepType } from 'crm-utils/process-utils'
import InfoNoAccountPermission from './info_no_account_permission.jsx'
import { AccountPermissionsContextProvider } from '../../../../core/permissions/account-permissions-context'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { EditableContextProvider } from 'crm-core/permissions/editable-context.tsx'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import { ManualWonButton } from './manual-won-btn.tsx'

class AccountPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      accountId: '',
      isConvertLeadToAccountModal: false,
      isMergeAccountModal: false,
      isAccountQualificationModal: false,
      isQualifyModalVisible: false,
      isAssignToMeAuto: true,
      isCoverHidden: false,
      isCoverAsManager: false,
      isMarketPotentialModal: false,
      isStartNewProspectModal: false,
      marketPotentialEditMode: false, // Should we by default open in edit mode
      selectedTab: '',
      isShowingQuotationsPopup: false,
      selectedQuotation: undefined,
      isShowingQuickQuotation: false,
      quickQuotationOpportunity: null,
      isShowingHistory: false,
      sendEmailFromQuotation: undefined,
      forceCloseActivityPopup: false,
      selectedOrder: undefined
    }
  }

  hasAccountDataPermission = () => {
    return this.props.account && this.props.account.permissions
      ? this.props.accountPermissionsContext.hasPermissionByType(
          PERMISSIONS_TYPES.ACCOUNT_DATA_MODIFY
        )
      : false
  }
  handleShowConfetti = () => {
    this.setState({ isConfetti: true })
    setTimeout(() => {
      this.setState({ isConfetti: false })
    }, 10000)
  }

  onAssignToMeUpdate = () => {
    this.setState({
      isAssignToMeAuto: !this.state.isAssignToMeAuto
    })
  }

  handleMergeAccountAction = async (
    sourceAccountUid,
    destinationAccountUid
  ) => {
    try {
      const res = await this.props.runMergeAccount(
        sourceAccountUid,
        destinationAccountUid
      )
      if (this.props.account.account_uid !== destinationAccountUid) {
        this.props.doPush('/crm/account/' + destinationAccountUid)
      }
      return res
    } catch (err) {
      throw err
    }
  }

  handleClickMarketPotential = () => {
    // console.log('handleClickMarketPotential')
    this.setState({
      isMarketPotentialModal: !this.state.isMarketPotentialModal,
      marketPotentialEditMode: true
    })
  }

  handleMarketPotentialChanged = () => {
    this.setState({
      isMarketPotentialModal: false,
      isReassignModal: false
    })
  }

  handleHideCoverAsManager = () => {
    this.setState({ isCoverAsManager: true })
  }
  handleHideCover = () => {
    this.setState({ isCoverHidden: true })
  }
  handleMergeAccount = () => {
    // console.log('handleMergeAccount')
    this.setState({
      isMergeAccountModal: !this.state.isMergeAccountModal
    })
  }

  handleAccoutDataChanged = () => {
    this.props.doAccountFieldsUpdated(this.props.account.account_uid)
  }
  handleDeleteAccount = async () => {
    // console.log('handleDeleteAccount')
    try {
      await this.props.onDeleteAccount()
      toastr.success('Account has been deleted')
    } catch (err) {
      toastr.error(err.message)
    }
  }
  handleMigrationAccount = async () => {
    try {
      toastr.info('Migrating account, estimate 30 seconds...');
      const status = await this.props.onMigrationAccount();
      if (status.success) {
        toastr.success('Account has been migrated');
      } else {
        toastr.error(status.message);
      }
    } catch (err) {
      toastr.error('Migration failed: ' + err.message);
    }
  }

  handleUnDeleteAccount = async () => {
    try {
      await this.props.onUnDeleteAccount()
      toastr.success('Account has been undeleted')
    } catch (err) {}
  }

  handleMarkAsCustomer = () => {
    this.props.doMarkAsCustomer(this.props.account.account_uid)
  }

  handleConvertToAccount = () => {
    this.setState({
      isConvertLeadToAccountModal: !this.state.isConvertLeadToAccountModal
    })
  }

  handleStartOpportunity = () => {
    this.setState({
      isQualifyModalVisible: true
    })
  }

  handleCancelQualifyModal = () => {
    this.setState({
      isQualifyModalVisible: false,
      isAccountQualificationModal: false
    })
  }

  handleStartQualificationByMe = () => {
    this.props.accountActions.startQualification({
      userUid: 'me',
      account: this.props.account,
      onSuccess: () => {
        toastr.info('Account assigned to you')
        // console.log('try to refetch', this.props)
        if (this.props.accountQl) {
          this.props.accountQl.refetch()
        }
      }
    })
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.account &&
      this.props.account.opportunities &&
      nextProps.account &&
      nextProps.account.opportunities &&
      nextProps.account.opportunities !== this.props.account.opportunities
    ) {
      // update state selectedQuotation for right side
      if (this.state.isShowingQuotationsPopup && this.state.selectedQuotation) {
        const opportunities = [...nextProps.account.opportunities]
        const opportunity = opportunities.filter(
          v =>
            v.opportunity_uid === this.state.selectedQuotation.opportunity_uid
        )[0]
        const selectedQuotation = opportunity.documents.filter(
          v => v.document_uid === this.state.selectedQuotation.document_uid
        )[0]
        if (!selectedQuotation) {
          // quotation deleted
          this.handleCloseQuotationsPopup()
        } else {
          this.handleQuotationClick(selectedQuotation)
        }
      }
    }
  }
  handleQualify = () => {
    this.setState({
      isAccountQualificationModal: !this.state.isAccountQualificationModal
    })
  }

  _renderNoPermissionLeadCover = () => (
    <div className={`AccountPage-newLeadCover `}>&nbsp;</div>
  )

  _renderNewLeadCover = isLost => {
    const status = this.props.account.kind
    return (
      <div
        className={`AccountPage-newLeadCover ${
          isLost ? 'AccountPage-newLeadCover--lost' : ''
        }`}
      >
        {!this.state.isCoverHidden && (
          <div className="AccountPage-newLeadCover-inner">
            {status === 'raw' && (
              <h2>
                <FormattedMessage
                  id="crm.ui.account.this_new_lead"
                  defaultMessage="This is NEW Lead"
                />
              </h2>
            )}
            {status !== 'raw' && (
              <h2>
                <FormattedMessage
                  id="crm.ui.account.lead_is_already"
                  defaultMessage="This Lead is already processed"
                />
              </h2>
            )}
            <h4>
              <FormattedMessage
                id="crm.ui.account.click"
                defaultMessage="Click"
              />{' '}
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.button.assign_to_me"
                    defaultMessage="Assign to me"
                  />
                }
                type="primary"
                onClick={this.handleStartQualificationByMe}
              />{' '}
              <FormattedMessage
                id="crm.ui.account.to_start_working_lead"
                defaultMessage="to start working with this lead"
              />
            </h4>
            <h4>
              <FormattedMessage
                id="crm.ui.account.or_click"
                defaultMessage="Or click"
              />{' '}
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.button.assign_to_another"
                    defaultMessage="Assign to another user"
                  />
                }
                onClick={this.handleStartQualification}
              />{' '}
              <FormattedMessage
                id="crm.ui.account.user_you_have_permission"
                defaultMessage="user (is you have permission)"
              />
            </h4>
            <CrmButton small label="Hide" onClick={this.handleHideCover} />
            {this.props.accountPermissionsContext.hasPermissionByType(
              PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
            ) && (
              <CrmButton
                onClick={this.handleHideCoverAsManager}
                type="primary"
                label={
                  <FormattedMessage
                    id="crm.ui.button.work_as_manager"
                    defaultMessage="Work as a manager"
                  />
                }
              />
            )}
          </div>
        )}
      </div>
    )
  }
  handleShowHistory = () => {
    this.setState({ isShowingHistoryModal: true })
  }
  handleCloseHistory = () => {
    this.setState({
      isShowingHistoryModal: false,
      showOnlyOpportunityEvents: false
    })
  }
  handleShowOpportunityHistory = () => {
    this.setState({
      isShowingHistoryModal: true,
      showOnlyOpportunityEvents: true
    })
  }

  handleClickedSave = () => {
    // console.log('handleClickedSave', this)
    this.props.doSubmit('crmAccount')
  }
  _renderNoPermission = () => {}
  checkPermissionToEditAccount = () => {
    if (
      this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
      )
    ) {
      return true
    }
    if (!this.props.account) {
      return false
    }
    // T O D O - tempory bypass due to groups
    return true
    // Temp removed const accountOwnerUser = getAccountOwnerUser(this.props.account)
    // if (!accountOwnerUser) {
    //   return true // Can edit unassigned accounts
    // }
    // return this.props.currentUser.user_uid === accountOwnerUser.user_uid
  }

  _renderCurrentProcess = () => {
    // console.log('_renderCurrentProcess', this.props)
    const canEditAccount = this.checkPermissionToEditAccount()
    const currentProcess = this.props.process ? this.props.process : undefined
    const hasAccountDataPermission = this.hasAccountDataPermission()
    const isMergedToAccount =
      this.props.account && this.props.account.merged_to_account !== null
    console.log(
      '_renderCurrentProcess',
      currentProcess,
      this.props.lastSalesProcess
    )

    return (
      <React.Fragment>
        {currentProcess && (
          <SaleProgressWidget
            process={currentProcess}
            processTypeUid={currentProcess.process_type_uid}
            account={this.props.account}
            onConvertToB2BProspect={this.handleConvertToB2BProspect}
            onClickEditMarketPotential={this.handleClickMarketPotential}
            canEditAccount={canEditAccount}
            onEditAccount={this.props.onEditAccount}
            onCancelEdit={this.props.onCancelEditAccount}
            onSaveEdit={this.handleClickedSave}
            onShowHistory={this.handleShowHistory}
            onCloseHistory={this.handleCloseHistory}
            onStartNewProspect={this.handleStartNewProspect}
            onMergeAccount={this.handleMergeAccount}
            onDeleteAccount={this.handleDeleteAccount}
            onMigrationAccount={this.handleMigrationAccount}
            onUnDeleteAccount={this.handleUnDeleteAccount}
            onShowConfetti={this.handleShowConfetti}
            historyMode={this.props.historyMode}
            handleShowQuotationTab={this.handleShowQuotationTab}
            handleQuotationClick={this.handleQuotationClick}
            hasAccountDataPermission={hasAccountDataPermission}
          />
        )}
        {!currentProcess && (
          <div>
            <AccountPageHeader19
              account={this.props.account}
              onClickEditMarketPotential={this.handleClickMarketPotential}
              canEditAccount={canEditAccount}
              onShowHistory={this.handleShowHistory}
              onCloseHistory={this.handleCloseHistory}
              hasAccountDataPermission={hasAccountDataPermission}
            />
          </div>
        )}
        {isMergedToAccount && (
          <div className="col-xs-12 ">
            <div className="util-flexColumn util-paddingSm util-textMedium">
              {this.props.account.merged_to_account.name} received at{' '}
              {this.props.account.merged_to_account.lead_in_time}
            </div>
          </div>
        )}
        {currentProcess === undefined && this.props.lastSalesProcess && (
          <LastSalesResult
            process={this.props.lastSalesProcess}
            processTypeUid={this.props.lastSalesProcess.process_type_uid}
            account={this.props.account}
            onConvertToB2BProspect={this.handleConvertToB2BProspect}
            onClickEditMarketPotential={this.handleClickMarketPotential}
            canEditAccount={canEditAccount}
            onEditAccount={this.props.onEditAccount}
            onCancelEdit={this.props.onCancelEditAccount}
            onSaveEdit={this.handleClickedSave}
            onShowHistory={this.handleShowHistory}
            onCloseHistory={this.handleCloseHistory}
            onStartNewProspect={this.handleStartNewProspect}
            onMergeAccount={this.handleMergeAccount}
            onDeleteAccount={this.handleDeleteAccount}
            onMigrationAccount={this.handleMigrationAccount}
            onUnDeleteAccount={this.handleUnDeleteAccount}
            historyMode={this.props.historyMode}
            hasAccountDataPermission={hasAccountDataPermission}
          />
        )}
      </React.Fragment>
    )
  }

  handleSubmit = async account => {
    // console.log('handleSubmit ', account)
    try {
      const res = await this.props.onSubmit(account)
      // console.log('Submit result ', res)
      const savedAccount = res.payload.account
      // console.log('savedAccount', savedAccount, account)
      if (
        savedAccount &&
        (account.account_uid === 'new' || !account.account_uid)
      ) {
        toastr.success('New account saved')
        this.props.doPush('/crm/account/' + savedAccount.account_uid)
      } else if (savedAccount && account.account_uid !== 'new') {
        toastr.success('Account updated')
      }
    } catch (err) {
      // console.log('Error saving account')
      toastr.error('Error Saving account')
    }
  }

  handleStartNewProspect = ({ kind, mode }) => {
    this.setState({
      isStartNewProspectModal: true,
      newProcessKind: kind,
      newProcessMode: mode,
      newProspectModalKey: uuidv4()
    })
  }
  handleConvertToB2BProspect = () => {
    this.setState({ isStartNewProspectModal: true })
  }
  handleCloseStartNewProspect = () => {
    this.setState({ isStartNewProspectModal: false })
  }

  renderThisIsArchivedInformation = mainProcess => (
    <CrmInfoBlock warning className="util-marginBottom">
      <strong>
        <FormattedMessage
          id="crm.ui.account.looking_archived_info"
          defaultMessage="You are looking at history about this account"
        />
      </strong>
      <div>
        {mainProcess && (
          <Link to={`/crm/account/${mainProcess.account_uid}/sales`}>
            <FormattedMessage
              id="crm.ui.account.click_to_open_current_info"
              defaultMessage="Click here to open current information"
            />
          </Link>
        )}
      </div>
    </CrmInfoBlock>
  )
  _renderLiveCallMode = () => (
    <ScriptCallWindow
      account={this.props.account}
      process={this.props.process}
    />
  )

  _renderWrapperContent(content) {
    return (
      <CrmFullpageContainer
        scrollBody
        noInnerNav
        className="AccountPage--centered "
      >
        <div className="AccountPage">
          <div style={{ textAlign: 'center' }}>{content}</div>
        </div>
      </CrmFullpageContainer>
    )
  }

  _renderLoading = () => this._renderWrapperContent(<LoadingIcon />)

  _renderAccountNotFound = () => {
    const content = (
      <div style={{ padding: 20 }}>
        <i className="fa fa-warning" />
        <span style={{ fontWeight: '600', paddingLeft: '10px' }}>
          <FormattedMessage
            id="crm.ui.account.account_or_process_not_found"
            defaultMessage="Account or process was not found in system"
          />
        </span>
        <div style={{ paddingLeft: '10px', paddingTop: '10px', fontSize: 12 }}>
          <FormattedMessage
            id="crm.ui.account.if_you_want_to_open_link_beloging_to_other_country_you_must_change_country_first"
            defaultMessage="If you want to open link belonging to other country - you must change
            country first."
          />
        </div>
      </div>
    )
    return this._renderWrapperContent(content)
  }

  renderLeft = () =>
    this.props.isEditing ? (
      <span />
    ) : (
      <div className="LEFT">{this._renderCurrentProcess()}</div>
    )

  handleCompleteProcess = () => {
    this.setState({ showLostProspectModal: true })
  }
  handleLostProspectModalCancel = () => {
    this.setState({ showLostProspectModal: false })
  }

  renderCurrentProcessHint() {
    const { currentStepType, currentStage } = calculateCurrentStepType(
      this.props.process,
      this.props.processType
    )
    if (
      currentStepType &&
      currentStepType.data &&
      currentStepType.data.userHint &&
      currentStepType.process_step_type_uid !== '1f0c30c8-b8d7-47e0-82db-35ed44d4f898'
    ) {
      return (
        <div className="util-marginBottom">
          <CrmInfoBlock hint>
            <div
              dangerouslySetInnerHTML={{
                __html: currentStepType.data.userHint
              }}
            />
          </CrmInfoBlock>
        </div>
      )
    }
    return <div />
  }
  renderRight = () => {
    const accountStatus = getAccountStatus(this.props.account)
    if (this.hasAccountDataPermission() === false) {
      return <InfoNoAccountPermission />
    }
    return this.props.isEditing ? (
      <span />
    ) : (
      <React.Fragment>
        <div className="util-flexRow util-marginBottom">
          <div className="util-flexGrow" />
          <div style={{ margin: '0 5px' }}>
            <CrmButton
              icon="edit"
              // onClick={this.props.onEditAccount}
              onClick={() => {
                toastr.info('Please edit data for each field independently.')
                toastr.info('กรุณาแก้ไขข้อมูลทีละฟิลด์ที่หน้าหลัก')
              }}
              iconButton
            />
          </div>
          <AccountActionsButton
            process={this.props.process}
            account={this.props.account}
            onStartNewProspect={this.handleStartNewProspect}
            onMergeAccount={this.handleMergeAccount}
            onDeleteAccount={this.handleDeleteAccount}
            onMigrationAccount={this.handleMigrationAccount}
            onUnDeleteAccount={this.handleUnDeleteAccount}
            onCompleteProcess={this.handleCompleteProcess}
            onPrioritizeAccount={this.props.onPrioritizeAccount}
            isManager={this.props.accountPermissionsContext.hasPermissionByType(
              PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
            )}
          />
          <ManualWonButton process={this.props.process} />
        </div>
        {(accountStatus === 'lead' ||
          accountStatus === 'bogus' ||
          accountStatus === 'lost') &&
          !this.props.process &&
          this.props.account && (
            <div>
              <LeadActionsWidget
                account={this.props.account}
                onShowPotentialPopup={this.handleClickMarketPotential}
              />
            </div>
          )}
        {this.props.process && this.renderCurrentProcessHint()}
      </React.Fragment>
    )
  }

  getTabOptions = () => {
    return [
      {
        name: (
          <FormattedMessage
            id="global.opportunities_and_quotations"
            defaultMessage="Opportunities & Quotations"
          />
        ),
        value: 'oportunities'
      },
      {
        name: (
          <FormattedMessage
            id="crm.ui.account.fitments"
            defaultMessage="Fitments"
          />
        ),
        value: 'fitments'
      },
      {
        name: (
          <FormattedMessage
            id="crm.ui.account.document.documents"
            defaultMessage="Documents"
          />
        ),
        value: 'documents'
      },
      {
        name: (
          <FormattedMessage
            id="crm.ui.account.additional.additional_information"
            defaultMessage="Additional Info"
          />
        ),
        value: 'additional_info'
      }
    ]
  }

  handleTabChange = tabOption => {
    this.setState({ selectedTab: tabOption })
  }

  handleShowQuotationTab = () => {
    const tabOption = this.getTabOptions().filter(
      v => v.value === 'oportunities'
    )[0]
    this.handleTabChange(tabOption)
  }

  getTabContent = () => {
    const { account } = this.props
    const hasAccountDataPermission = this.hasAccountDataPermission()
    if (this.props.isLoading) {
      return this._renderLoading()
    }
    if (
      account === null ||
      account === undefined ||
      (this.props.accountQl && this.props.accountQl.error)
    ) {
      return this._renderAccountNotFound()
    }
    if (this.state.selectedTab.value === 'oportunities') {
      return (
        <TabOpportunities
          account={account}
          processUid={this.props.processUid}
          process={this.props.process}
          handleQuotationClick={this.handleQuotationClick}
          hasPermissionToReadOpportunities={this.props.accountPermissionsContext.hasPermissionByType(
            PERMISSIONS_TYPES.OPPORTUNITIES_READ
          )}
          handleQuickQuotationOpen={this.handleQuickQuotationOpen}
          handleShowOpportunityHistory={this.handleShowOpportunityHistory}
        />
      )
    }
    if (this.state.selectedTab.value === 'fitments') {
      return (
        <TabFitments
          account={account}
          hasAccountDataPermission={hasAccountDataPermission}
        />
      )
    }
    if (this.state.selectedTab.value === 'documents') {
      return (
        <TabDocuments
          account={account}
          hasAccountDataPermission={hasAccountDataPermission}
        />
      )
    }
    if (this.state.selectedTab.value === 'additional_info') {
      return (
        <TabAdditionalInfo
          account={account}
          currentModuleCode={this.props.currentModuleCode}
          hasAccountDataPermission={hasAccountDataPermission}
        />
      )
    }
  }
  handleQuotationClick = pQuotation => {
    const selectedQuotation = { ...pQuotation }
    selectedQuotation.opportunity =
      this.props.account && this.props.account.opportunities
        ? this.props.account.opportunities.filter(
            v => v.opportunity_uid === pQuotation.opportunity_uid
          )[0]
        : null
    this.setState({
      isShowingQuotationsPopup: true,
      selectedQuotation
    })
    this.handleEndSendingEmailFromQuotation()
  }
  handleCloseQuotationsPopup = () => {
    this.setState({
      isShowingQuotationsPopup: false,
      selectedQuotation: undefined
    })
  }

  handleQuickQuotationOpen = async opportunity => {
    this.setState({
      isShowingQuickQuotation: true,
      quickQuotationOpportunity: opportunity
    })
  }
  handleQuickQuotationClose = async () => {
    this.setState({ isShowingQuickQuotation: false })
  }

  handleClickMarketPotentialHistory = async () => {
    this.setState({
      isShowingMarketPotentialHistory: !this.state
        .isShowingMarketPotentialHistory
    })
  }

  onSetHandleFromChild = async handlerFromChild => {
    this.setState({ handlerFromChild })
  }

  handleClickSaveMarketPotential = () => {
    this.state.handlerFromChild()
  }

  handleSendEmailFromQuotation = (resource, quotation) => {
    this.setState({
      sendEmailFromQuotation: {
        resource: resource,
        documentUid: quotation.document_uid
      }
    })
    this.handleCloseQuotationsPopup()
  }

  handleEndSendingEmailFromQuotation = () => {
    this.setState({
      sendEmailFromQuotation: undefined,
      forceCloseActivityPopup: true
    })
  }

  render() {
    const { account, isSaving, isEditing, accountActions } = this.props
    const { selectedQuotation, isShowingMarketPotentialHistory } = this.state
    const accountKind = account ? account.kind : undefined
    const accountStatus = getAccountStatus(account)
    const hasAccountDataPermission = this.hasAccountDataPermission()

    if (this.props.isLoading) {
      return this._renderLoading()
    }

    if (
      account === null ||
      account === undefined ||
      (this.props.accountQl && this.props.accountQl.error)
    ) {
      return this._renderAccountNotFound()
    }
    const canEditAccount = this.checkPermissionToEditAccount()
    const pageContainerClass = isEditing
      ? { scrollBody: true, noInnerNav: true }
      : { scrollBody: false, noInnerNav: false }
    const pageContainerStyle = isEditing ? {} : { overflowY: 'hidden' }

    return (
      <React.Fragment>
        {!isEditing && (
          <CrmTabs
            tabOptions={this.getTabOptions()}
            handleTabChange={this.handleTabChange}
            getTabContent={this.getTabContent}
            selectedTab={this.state.selectedTab}
          />
        )}
        <AccountPageRightPanel
          isOpen={this.state.isShowingQuotationsPopup}
          quotation={selectedQuotation}
          onClose={this.handleCloseQuotationsPopup}
          onClickOutside={this.handleCloseQuotationsPopup}
        >
          <div className="document19">
            {selectedQuotation && (
              <OpportunityBarWidget
                opportunity={selectedQuotation.opportunity}
              />
            )}
            <EditableContextProvider>
              <QuotationForm19
                documentUid={
                  selectedQuotation ? selectedQuotation.document_uid : undefined
                }
                quotation={selectedQuotation}
                account={account}
                persons={account.persons}
                addresses={
                  account.addresses
                    ? account.addresses.filter(v => v.address_uid)
                    : []
                }
                onClose={this.handleCloseQuotationsPopup}
                onShowConfetti={this.handleShowConfetti}
                handleSendEmailFromQuotation={this.handleSendEmailFromQuotation}
              />
            </EditableContextProvider>
          </div>
        </AccountPageRightPanel>

        <CrmFullpageContainer
          {...pageContainerClass}
          noInnerNav
          className="AccountPage--centered"
          style={pageContainerStyle}
        >
          <Switch>
            <Route
              path={`${this.props.match.url}/liveCall`}
              render={this._renderLiveCallMode}
            />
            <Route
              path={`${this.props.match.url}`}
              // eslint-disable-next-line
              render={props => (
                <div className="AccountPage">
                  {/* Remember to keep proper order of covers! */}
                  {!canEditAccount && this._renderNoPermissionLeadCover()}
                  {accountKind === 'raw' &&
                    !this.state.isCoverAsManager &&
                    this._renderNewLeadCover(accountKind !== 'lead')}
                  {/* {!this.props.process && (
                    <div className="AccountPage-header-wrapper">
                      {account &&
                      !this.props.isNew && (
                        <AccountPageHeader
                          account={account}
                          process={this.props.process}
                          isSaving={isSaving}
                          isEditing={isEditing}
                          isAssignToMeAuto={this.state.isAssignToMeAuto}
                          onEditAccount={this.props.onEditAccount}
                          onCancelEdit={this.props.onCancelEditAccount}
                          onSaveEdit={this.handleClickedSave}
                          onConverToAccount={this.handleConvertToAccount}
                          onDeleteAccount={this.handleDeleteAccount}
                          onUnDeleteAccount={this.handleUnDeleteAccount}
                          onStartQualification={this.handleStartQualification}
                          onAssignTm={this.handleAssignTm}
                          onMarkAsCustomer={this.handleMarkAsCustomer}
                          onMergeAccount={this.handleMergeAccount}
                          onQualify={this.handleQualify}
                          onStartOpportunity={this.handleStartOpportunity}
                          handleAssignToMeUpdate={this.onAssignToMeUpdate}
                          isManager={this.props.isManager}
                          onAccountChanged={this.handleAccoutDataChanged}
                          onClickEditMarketPotential={
                            this.handleClickMarketPotential
                          }
                          canEditAccount={canEditAccount}
                          onStartNewProspect={this.handleStartNewProspect}
                          onShowHistory={this.handleShowHistory}
                      
                        />
                      )}
                    </div>
                  )} */}
                  <MarketPotentialModal
                    isOpen={this.state.isMarketPotentialModal}
                    account={account}
                    onClickSaveMarketPotential={
                      this.handleClickSaveMarketPotential
                    }
                    onSetHandleFromChild={this.onSetHandleFromChild}
                    onClickMarketPotentialHistory={
                      this.handleClickMarketPotentialHistory
                    }
                    onClose={this.handleClickMarketPotential}
                    onSaved={this.handleMarketPotentialChanged}
                    edit={this.state.marketPotentialEditMode}
                    isShowingMarketPotentialHistory={
                      isShowingMarketPotentialHistory
                    }
                  />
                  <AccountHistoryModal
                    isOpen={this.state.isShowingHistoryModal}
                    onClose={this.handleCloseHistory}
                    account={this.props.account}
                    isManager={this.props.accountPermissionsContext.hasPermissionByType(
                      PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
                    )}
                    showOnlyOpportunityEvents={
                      this.state.showOnlyOpportunityEvents
                    }
                  />

                  {account &&
                    this.props.historyMode &&
                    this.renderThisIsArchivedInformation(this.props.process)}
                  <AccountForm
                    onSubmit={this.handleSubmit}
                    isEditing={isEditing}
                    isSaving={isSaving}
                    account={account}
                    processUid={this.props.processUid}
                    process={this.props.process}
                    onStartEdit={this.handleEditAccount}
                    onCancelEdit={this.props.onCancelEditAccount}
                    initialValues={this.props.account}
                    onLiveCallCompleted={this.props.onLiveCallCompleted}
                    duplicates={this.props.duplicates}
                    isCheckingDuplicates={this.props.isCheckingDuplicates}
                    didCheckForDuplicates={this.props.didCheckForDuplicates}
                    isNew={this.props.isNew}
                    renderLeft={this.renderLeft}
                    renderRight={this.renderRight}
                    currentModuleCode={this.props.currentModuleCode}
                    historyMode={this.props.historyMode}
                    onShowConfetti={this.handleShowConfetti}
                    hasAccountDataPermission={hasAccountDataPermission}
                    isManager={this.props.accountPermissionsContext.hasPermissionByType(
                      PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
                    )}
                    sendEmailFromQuotation={this.state.sendEmailFromQuotation}
                    handleEndSendingEmailFromQuotation={
                      this.handleEndSendingEmailFromQuotation
                    }
                    forceCloseActivityPopup={this.state.forceCloseActivityPopup}
                  />
                </div>
              )}
            />
          </Switch>
          <LostProspectModal
            account={account}
            isOpen={this.state.showLostProspectModal}
            process={this.props.process}
            accountUid={this.props.account.account_uid}
            onCancel={this.handleLostProspectModalCancel}
            onClose={this.handleLostProspectModalCancel}
          />

          {this.state.isConvertLeadToAccountModal && (
            <ConvertLeadToAccountModal
              account={account}
              accountActions={accountActions}
              onClose={this.handleConvertToAccount}
            />
          )}
          {/*
          <AccountQualificationWidget
            account={account}
            onCancel={this.handleCancelQualifyModal}
            onClose={this.handleCancelQualifyModal}
            isOpen={this.state.isAccountQualificationModal}
          /> */}
          {account && (
            <AccountMergingSelector
              onMergeAccountAction={this.handleMergeAccountAction}
              account={account}
              isOpen={this.state.isMergeAccountModal}
              onClose={this.handleMergeAccount}
            />
          )}

          <ConvertToProspectModal
            isOpen={this.state.isStartNewProspectModal}
            account={this.props.account}
            accountUid={this.props.account.account_uid}
            onCancel={this.handleCloseStartNewProspect}
            onClose={this.handleCloseStartNewProspect}
            sourceProcess={this.props.process}
            newProcessKind={this.state.newProcessKind}
            newProcessMode={this.state.newProcessMode}
            key={this.state.newProspectModalKey}
          />

          {account && this.state.isQualifyModalVisible && (
            <QualifyModal
              account={account}
              isVisible={this.state.isQualifyModalVisible}
              onCancel={this.handleCancelQualifyModal}
            />
          )}
          {this.state.isConfetti && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 5000
              }}
            >
              <Confetti width={2320} height={2000} />
            </div>
          )}
        </CrmFullpageContainer>

        <CrmModal
          isOpen={this.state.isShowingQuickQuotation}
          title={
            <FormattedMessage
              id="crm.quotation.quick_quotation_generation"
              defaultMessage="Quick quotation generation"
            />
          }
          showFooter={false}
          size="md"
          isShowCloseBtn={false}
          extraClassNames={{ bodyClassNames: 'util-overflowInitial' }}
        >
          <div className="util-fullHeight util-fullWidth">
            <QuickQuotationGenerator
              opportunity={this.state.quickQuotationOpportunity}
              handleQuickQuotationClose={this.handleQuickQuotationClose}
              account={account}
              handleQuotationClick={this.handleQuotationClick}
              persons={account.persons}
              addresses={
                account.addresses
                  ? account.addresses.filter(v => v.address_uid)
                  : []
              }
            />
          </div>
        </CrmModal>
      </React.Fragment>
    )
  }
}

AccountPage.defaultProps = {
  account: undefined,
  process: undefined,
  onLiveCallCompleted: undefined,
  isLoading: false
}

AccountPage.propTypes = {
  doMarkAsCustomer: func.isRequired,
  account: shape({}),
  isLoading: bool,
  accountActions: shape({}).isRequired,
  doAccountFieldsUpdated: func.isRequired,
  // eslint-disable-next-line
  onSubmit: func.isRequired,
  onEditAccount: func.isRequired,
  onCancelEditAccount: func.isRequired,
  doSubmit: func.isRequired,
  accountQl: shape({}),
  processUid: string,
  process: shape({}),
  onLiveCallCompleted: func,
  doPush: func.isRequired,
  match: shape().isRequired,
  ...accountPageHocPropTypes
}

AccountPage.defaultProps = {
  processUid: undefined,
  accountQl: undefined,
  duplicates: undefined,
  isCheckingDuplicates: false
}

function mapStateToProps(state) {
  return {
    accountChildDeleted: getChildAccountDeleted(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doSubmit: () => dispatch(submit('crmAccount')),
    doStartAccountEdit: () => dispatch(startAccountEdit()),
    doInitializeForm: (form, value) => dispatch(initialize(form, value)),
    accountActions: bindActions(dispatch, accountActions),
    doMarkAsCustomer: accountUid => {
      dispatch(markAsCustomer(accountUid))
    },
    doStartQualification: params =>
      dispatch(accountActions.startQualification(params)),
    doAccountFieldsUpdated: accountUid =>
      dispatch(accountFieldsUpdated(accountUid)),
    doPush: url => dispatch(push(url))
  }
}

const PermissionsContextAccountPage = withAccountPermissionsContextHOC(
  AccountPage
)
const WithPermissionsContextAccountPage = props => {
  return (
    <AccountPermissionsContextProvider
      account_permissions={
        props.account && props.account.permissions
          ? props.account.permissions
          : []
      }
    >
      <PermissionsContextAccountPage {...props} />
    </AccountPermissionsContextProvider>
  )
}
WithPermissionsContextAccountPage.propTypes = {
  account: shape({})
}
WithPermissionsContextAccountPage.defaultProps = {
  account: undefined
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(accountPageQLHOC(WithPermissionsContextAccountPage))
)
