import { buildCrmReducer } from 'crm-utils/reducer-utils'

export const FETCH_QL_SCHEMA = 'FETCH_QL_SCHEMA'
export const FETCH_QL_SCHEMA_SUCCESS = 'FETCH_QL_SCHEMA_SUCCESS'

// Reducer
const initialState = {
  providers: {},
  datatypes: [],
  qlSchema: undefined
}

const actions = {
  registerNewDataProvider: {}
}

// Reducer Fn
function dataReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_QL_SCHEMA_SUCCESS:
      return { ...state, qlSchema: action.payload.qlSchema }
    default:
      return state
  }
}

export function fetchQlSchema() {
  return { type: FETCH_QL_SCHEMA }
}

const reducer = buildCrmReducer(dataReducer, actions, initialState)

export default reducer
