import React from 'react'
import { bool, func, shape, string, any } from 'prop-types'
import enhanceWithClickOutside from 'react-click-outside'
import { crmGenericCommandHoc } from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CrmLoadingIcon } from 'crm-components'
import { withEditableContextHOC } from 'crm-core/permissions/editable-context'

const generateInlineEditableField = (
  WrappedComponent,
  {
    showComponent,
    editComponent,
    editable,
    editableAsManager,
    saveHandler,
    deleteHandler,
    managerSaveHandler,
    errorHandler
  }
) => {
  class InlineEditableComponent extends React.PureComponent {
    constructor(props) {
      super(props)
      const initialState = {}
      // Calculate if we should automatically start in edit mode
      if (this.props.startEdit) {
        if (
          this.props.editable ||
          this.props.editableAsManager ||
          this.getIsEditableFromContext()
        ) {
          initialState.isEditing = true
        }
      }
      this.state = initialState
    }

    getIsEditableFromContext = () => {
      if (this.props.editableContext && this.props.editableContext.isEditable) {
        return true
      } else {
        return false
      }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.props.value) {
        this.setState({ newValue: undefined })
      }
    }

    getDefaultEditAsManager = () =>
      this.props.editableAsManager && !this.props.editable

    handleStartEdit = e => {
      if (
        this.props.editable ||
        this.props.editableAsManager ||
        this.getIsEditableFromContext()
      ) {
        const editAsManager = this.getDefaultEditAsManager()
        this.setState({ isEditing: true, editingAsManager: editAsManager })
      }
      if (this.props.handleStartEdit) {
        this.props.handleStartEdit(e)
      }
    }

    handleValueChange = newValue => {
      this.setState({ newValue })
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    doSave = async () => {
      if (this.props.handleSave) {
        this.props.handleSave()
      }

      if (
        (!this.state.newValue && this.state.newValue !== '') ||
        this.state.newValue === this.props.value
      ) {
        this.setState({
          isEditing: false,
          isSaving: false
        })
        if (this.props.handleEndEdit) {
          this.props.handleEndEdit()
        }
        return
      }
      if (saveHandler) {
        try {
          await this.setStateAsync({ isSaving: true })
          const saveHandlerBind = saveHandler.bind(this)
          await saveHandlerBind(
            this.state.newValue || this.state.newValue === ''
              ? this.state.newValue
              : this.props.value,
            this.props,
            this.state.editingAsManager
          )

          await this.setStateAsync({ isSaving: false })
        } catch (err) {
          if (errorHandler) {
            errorHandler({ errorMessage: err.message })
          }
          console.error('Error calling save handler', err)
        }
      } else {
        console.log('No save handler')
      }
      this.setState({
        isEditing: false,
        isSaving: false
      })
      if (this.props.handleEndEdit) {
        this.props.handleEndEdit()
      }
    }
    handleCancel = event => {
      this.setState({ isEditing: false, newValue: this.props.value })
      if (this.props.handleEndEdit) {
        this.props.handleEndEdit()
      }
      if (this.props.onCancelInlineEdit) {
        if (this.props.item) {
          this.props.onCancelInlineEdit(this.props.item)
        } else {
          this.props.onCancelInlineEdit()
        }
      }
      event.preventDefault()
    }
    handleSave = event => {
      event.preventDefault()
      this.doSave()
    }

    doDelete = async () => {
      if (deleteHandler) {
        try {
          await this.setStateAsync({ isSaving: true })
          const deleteHandlerBind = deleteHandler.bind(this)
          await deleteHandlerBind(
            this.state.newValue || this.state.newValue === ''
              ? this.state.newValue
              : this.props.value,
            this.props,
            this.state.editingAsManager
          )
          await this.setStateAsync({ isSaving: false })
        } catch (err) {
          if (errorHandler) {
            errorHandler({ errorMessage: err.message })
          }
          console.error('Error calling delete handler', err)
        }
      } else {
        console.log('No delete handler')
      }

      this.setState({
        isEditing: false,
        isSaving: false
      })

      if (this.props.handleEndEdit) {
        this.props.handleEndEdit()
      }
    }

    handleDelete = event => {
      event.preventDefault()
      this.doDelete()
      this.props.onRemoveArchivedContactUI()
    }
    handleClickOutside = async event => {
      if (this.state.isEditing) {
        this.doSave()
      }
    }
    renderEditComponent = () => {
      const EditComponent = editComponent
      return (
        <div className="CrmInlineEditable-editWrapper">
          <EditComponent
            {...this.props}
            input={{
              value:
                this.state.newValue || this.state.newValue === ''
                  ? this.state.newValue
                  : this.props.value,
              onChange: this.handleValueChange
            }}
            isSavingInlineEdit={this.state.isSaving}
          />
          <div className="CrmInlineEditable-editButtons">
            {this.state.isSaving ? (
              <CrmLoadingIcon />
            ) : (
              <React.Fragment>
                <div className="CrmInlineEditable-editButtonGroup">
                  <div
                    className="CrmInlineEditable-editButton"
                    title="Cancel"
                    onClick={this.handleCancel}
                  >
                    <FontAwesomeIcon icon="times" />
                  </div>
                  <div className="CrmInlineEditable-editButton" title="Save">
                    <FontAwesomeIcon onClick={this.handleSave} icon="check" />
                  </div>
                </div>
                {this.props.value?.contact_details_uid && (
                  <div className="CrmInlineEditable-editButtonGroup">
                    <div
                      className="CrmInlineEditable-editButton"
                      title="Delete"
                      onClick={this.handleDelete}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      )
    }
    render() {
      const defaultEditAsManager = this.getDefaultEditAsManager()
      return (
        <div
          className="CrmInlineEditable util-hooverableContainer util-hooverOp"
          style={{
            position: 'relative',
            paddingRight: this.state.isEditing ? 0 : 15
          }}
          title={
            this.props.editable || this.getIsEditableFromContext() ? 'Edit' : ''
          }
        >
          {this.state.isEditingAsManager || this.state.isEditing ? (
            this.renderEditComponent()
          ) : (
            <div onClick={this.handleStartEdit}>
              <WrappedComponent {...this.props} />
            </div>
          )}
          {(this.props.editableAsManager ||
            this.props.editable ||
            this.getIsEditableFromContext()) && (
            <div
              className=" util-hooverableContent"
              style={{
                position: 'absolute',
                right: 2,
                top: 2,
                color: defaultEditAsManager ? 'red' : undefined
              }}
              title={defaultEditAsManager ? 'Edit as Manager' : 'Edit'}
              onClick={this.handleStartEdit}
            >
              {!this.state.isEditing && <FontAwesomeIcon icon="pencil-alt" />}
            </div>
          )}
          {this.props.lockEditingMessage && (
            <div
              className=" util-hooverableContent"
              style={{
                position: 'absolute',
                right: 2,
                top: 2
              }}
              title={this.props.lockEditingMessage}
            >
              <FontAwesomeIcon icon="lock" />
            </div>
          )}
        </div>
      )
    }
  }
  InlineEditableComponent.propTypes = {
    startEdit: bool,
    editable: bool,
    editableAsManager: bool,
    handleStartEdit: func,
    value: any,
    handleSave: func,
    handleDelete: func,
    onCancelInlineEdit: func,
    onRemoveArchivedContactUI: func,
    item: shape(),
    lockEditingMessage: string,
    handleEndEdit: func,
    editableContext: shape({}).isRequired
  }
  InlineEditableComponent.defaultProps = {
    startEdit: false,
    editable: false,
    editableAsManager: false,
    handleStartEdit: undefined,
    value: undefined,
    handleSave: undefined,
    handleDelete: undefined,
    onCancelInlineEdit: undefined,
    onRemoveArchivedContactUI: undefined,
    item: undefined,
    lockEditingMessage: '',
    handleEndEdit: undefined
  }
  return crmGenericCommandHoc(
    withEditableContextHOC(enhanceWithClickOutside(InlineEditableComponent))
  )
}

export default generateInlineEditableField
