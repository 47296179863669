import React from 'react'
import moment from 'moment'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { CrmLabeledField } from 'crm-components'
import styles from './opportunity-bar-widget.scss'
import cloneDeep from 'clone-deep'
import { func, shape, bool } from 'prop-types'

class OpportunityBarWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    console.log('render opportunity-bar-widget', this.props, styles)
    const { opportunity } = this.props
    const started =
      opportunity.propcess && opportunity.propcess.started_time
        ? opportunity.propcess.started_time
        : null
    return (
      <div className={`${styles.opportunityBarWrapper}`}>
        <div className={`LabeledField-value ${styles.type} ${styles.colItem}`}>
          {opportunity.opportunity_type &&
            `Opportunity ${opportunity.opportunity_type.name}`}
        </div>
        <div className={`${styles.details}`}>
          <div className={`${styles.colItem}`}>
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.opportunity.opportunity_size"
                  defaultMessage="OPPORTUNITY SIZE"
                />
              }
              valueClassName="LabeledField-value"
              noMargin
            >
              {opportunity.quantity}
            </CrmLabeledField>
          </div>
          <div className={`${styles.colItem}`}>
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.opportunity.started"
                  defaultMessage="STARTED"
                />
              }
              valueClassName="LabeledField-value"
              noMargin
            >
              {started && <FormattedDate value={started} />}
              {!started && <div>-</div>}
            </CrmLabeledField>
          </div>
          <div className={`${styles.colItem}`}>
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.opportunity.due_date"
                  defaultMessage="DUE DATE"
                />
              }
              valueClassName="LabeledField-value"
              noMargin
            >
              {opportunity.due_date && (
                <FormattedDate value={opportunity.due_date} />
              )}
              {!opportunity.due_date && '-'}
            </CrmLabeledField>
          </div>
          <div className={`${styles.colItem}`}>
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.opportunity.confidence"
                  defaultMessage="CONFIDENCE"
                />
              }
              valueClassName="LabeledField-value util-capitalize"
              noMargin
            >
              {`${opportunity.confidence_level}%`}
            </CrmLabeledField>
          </div>
        </div>
      </div>
    )
  }
}

OpportunityBarWidget.propTypes = {
  opportunity: shape({})
}

OpportunityBarWidget.defaultProps = {
  opportunity: {}
}

export default OpportunityBarWidget
