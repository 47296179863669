/* eslint-disable camelcase */
import React, { useState } from 'react'
import { shape, bool } from 'prop-types'
import FitmentEventHistoryModal from '../fitment/fitment-event-history-modal'
import SectionOrders from '../_section-orders.jsx'
import InfoNoAccountPermission from '../info_no_account_permission.jsx'

const TabFitments = props => {
  const [isOpenHistory, setIsOpenHistory] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState()
  if (props.hasAccountDataPermission === false) {
    return <InfoNoAccountPermission />
  }

  const { account } = props
  const handleShowFitmentHistory = order => {
    setIsOpenHistory(true)
    setSelectedOrder(order)
  }
  if (account) {
    return (
      <React.Fragment>
        <SectionOrders
          account={account}
          handleShowFitmentHistory={handleShowFitmentHistory}
        />
        <FitmentEventHistoryModal
          isOpen={isOpenHistory}
          onClose={() => setIsOpenHistory(false)}
          order={selectedOrder}
        />
      </React.Fragment>
    )
  }
  return <span />
}

TabFitments.defaultProps = {
  account: undefined
}

TabFitments.propTypes = {
  account: shape({}).isRequired,
  hasAccountDataPermission: bool.isRequired
}
export default TabFitments
