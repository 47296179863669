import styled from 'styled-components'

export const QuotationBarWidgetStyled = styled.div`
  .quotationBarWrapper {
    margin: 10px;
    background-color: white;
    box-shadow: 0 10px 20px 0 #eee;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;

    .colItem {
      padding: 5px;
    }

    .colName {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #d7d7dd;
      border-radius: 8px 0px 0px 8px;
      width: 90px;
    }
  }

  .showMoreQuotations {
    padding: 5px 0px;
    text-align: center;
  }
`
