import React, { PureComponent } from 'react'
import { shape, bool, arrayOf, string } from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import styles from '@cartrack-crm/ui/style/index.scss'
import styles from '@cartrack-crm/ui/style/crm-tab-navigator/crm-tab-navigator.scss'

class CrmTabNavigator extends PureComponent<any, any> {
  static propTypes = {
    tabs: arrayOf(shape({})).isRequired,
    selectedIndex: string,
    contentWrapperNoPadding: bool,
    pageSize: string,
    centered: bool,
    style: shape({})
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: props.selectedIndex,
      tabs: props.tabs
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      tabs: nextProps.tabs
    })
  }

  handleChange = (event, newValue) => {
    this.setState({ selectedIndex: newValue })
  }

  tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    }
  }

  render() {
    const { selectedIndex, tabs } = this.state
    return (
      <React.Fragment>
        <div
          className={`${styles.TabNavLabelWrapper}`}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Tabs
            value={selectedIndex}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            //     variant="fullWidth"
            centered={this.props.centered}
          >
            {tabs.map((v, i) => {
              return <Tab key={i} label={v.label} {...this.tabProps(0)} />
            })}
          </Tabs>
        </div>
        <div
          className={
            this.props.contentWrapperNoPadding
              ? `${styles.TabNavContentWrapper__noPadding}`
              : `${styles.TabNavContentWrapper}`
          }
          style={{ flex: 1, minHeight: 0, overflowY: 'auto' }}
        >
          {tabs
            .filter((v, i) => i === selectedIndex)
            .map((v, i) => {
              const childProps = { ...v.childComponent.props }
              if (this.props.pageSize) {
                childProps.pageSize = this.props.pageSize
              }
              const child = { ...v.childComponent, props: { ...childProps } }
              return (
                <div
                  className="util-fullHeight"
                  key={i}
                  style={this.props.style}
                >
                  {child}
                </div>
              )
            })}
        </div>
      </React.Fragment>
    )
  }
}

export default CrmTabNavigator
