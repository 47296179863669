import { combineReducers } from 'redux'
import {
  getEndDate,
  parseToCalendarFormat,
  getStartDateOfWeek,
  getStartDateOfDay
} from '../modules/calendars/calendar-utils'

export const SWITCH_CALENDAR_VIEW = 'SWITCH_CALENDAR_VIEW'
export const CHANGE_DATE_IN_CALENDAR_WEEK = 'CHANGE_DATE_IN_CALENDAR_WEEK'
export const CHANGE_DATE_IN_CALENDAR_DAY = 'CHANGE_DATE_IN_CALENDAR_DAY'
export const JUMP_TO_DATE_IN_CALENDAR_DAY = 'JUMP_TO_DATE_IN_CALENDAR_DAY'
export const RECEIVED_CALENDAR_ACTIVITIES = 'RECEIVED_CALENDAR_ACTIVITIES'
export const TOGGLE_WEEK_PANEL = 'TOGGLE_WEEK_PANEL'
export const FETCH_CALENDAR_ACTIVITIES = 'FETCH_CALENDAR_ACTIVITIES'
export const VIEW_TODAY_CALENDAR = 'VIEW_TODAY_CALENDAR'
export const CALENDAR_SELECTED_DATE = 'CALENDAR_SELECTED_DATE'
export const CALENDAR_TOGGLE_MODAL = 'CALENDAR_TOGGLE_MODAL'
export const TOGGLE_FILTER_CONFIRM_MEETING = 'TOGGLE_FILTER_CONFIRM_MEETING'
export const TOGGLE_FILTER_CANCEL_MEETING = 'TOGGLE_FILTER_CANCEL_MEETING'
export const RECEIVE_USERS = 'RECEIVE_USERS'
export const USER_FILTER_CHANGE = 'USER_FILTER_CHANGE'
export const USER_GROUP_FILTER_CHANGE = 'USER_GROUP_FILTER_CHANGE'
export const ACTIVITY_TYPE_CHANGE = 'ACTIVITY_TYPE_CHANGE'

// Reducer
const initialState = {
  fetch: false,
  views: ['Week', 'Day'],
  currentView: 'Week',
  date: {
    Week: {
      start: getStartDateOfWeek(),
      end: getEndDate(getStartDateOfWeek())
    },
    Day: { start: getStartDateOfDay(), end: getStartDateOfDay() }
  },
  activities: [],
  filterConfirm: false,
  filterCancel: false,
  modalVisible: false,
  modalSize: 'md',
  weekPanelVisible: window.innerWidth >= 1020 // Activate by default on bigger screens
}

function calendarState(state = initialState, action) {
  let startDate
  let endDate
  let activities
  let date
  switch (action.type) {
    case SWITCH_CALENDAR_VIEW:
      return { ...state, currentView: action.view }
    case FETCH_CALENDAR_ACTIVITIES:
      return { ...state, fetch: true }
    case VIEW_TODAY_CALENDAR:
      date = Object.assign({}, state.date)
      if (state.currentView === 'Week') {
        date.Week = {
          start: getStartDateOfWeek(),
          end: getEndDate(getStartDateOfWeek())
        }
      } else {
        date.Day = { start: getStartDateOfDay(), end: getStartDateOfDay() }
      }
      return {
        ...state,
        date
      }
    case RECEIVED_CALENDAR_ACTIVITIES: {
      activities = parseToCalendarFormat(action.payload.calendarData.data)
      return {
        ...state,
        fetch: false,
        activities
      }
    }
    case CALENDAR_SELECTED_DATE:
      startDate = action.date.clone()
      endDate = getEndDate(startDate.clone())
      return {
        ...state,
        date: {
          ...state.date,
          Week: {
            start: startDate,
            end: endDate
          }
        }
      }
    case CHANGE_DATE_IN_CALENDAR_WEEK:
      startDate = state.date.Week.start.clone()
      startDate = action.increase
        ? startDate.add(1, 'w')
        : startDate.subtract(1, 'w')
      endDate = getEndDate(startDate.clone())
      return {
        ...state,
        date: {
          ...state.date,
          Week: {
            start: startDate,
            end: endDate
          }
        }
      }

    case CHANGE_DATE_IN_CALENDAR_DAY:
      startDate = state.date.Day.start.clone()
      startDate = action.increase
        ? startDate.add(1, 'd')
        : startDate.subtract(1, 'd')
      return {
        ...state,
        date: {
          ...state.date,
          Day: {
            start: startDate,
            end: startDate
          }
        }
      }
    case JUMP_TO_DATE_IN_CALENDAR_DAY:
      startDate = action.date
      return {
        ...state,
        date: {
          ...state.date,
          Day: {
            start: startDate,
            end: startDate
          }
        }
      }
    case TOGGLE_FILTER_CONFIRM_MEETING:
      return {
        ...state,
        filterConfirm: !state.filterConfirm
      }
    case TOGGLE_FILTER_CANCEL_MEETING:
      return {
        ...state,
        filterCancel: !state.filterCancel
      }
    case CALENDAR_TOGGLE_MODAL:
      return { ...state, modalVisible: !state.modalVisible }
    case TOGGLE_WEEK_PANEL:
      return { ...state, weekPanelVisible: !state.weekPanelVisible }
    case RECEIVE_USERS:
      return { ...state, users: action.payload.users }
    case USER_FILTER_CHANGE:
      return {
        ...state,
        userSelected: action.userSelected,
        userGroupSelected: undefined
      }
    case USER_GROUP_FILTER_CHANGE:
      return {
        ...state,
        userGroupSelected: action.userGroupSelected,
        userSelected: undefined
      }
    case ACTIVITY_TYPE_CHANGE:
      return {
        ...state,
        activityType: action.activityTypeUid
      }
    default:
      return state
  }
}

export const getStartAndEndDate = calendarId => state =>
  // eslint-disable-next-line
  state.crm.calendars[calendarId].date[
    state.crm.calendars[calendarId].currentView
  ]
export const getIsMyCalendar = calendarId => state =>
  state.crm.calendars[calendarId].isMyCalendar

export const getUsersCalendar = calendarId => state =>
  state.crm.calendars[calendarId].users

export const getUserFilterSelected = calendarId => state =>
  state.crm.calendars[calendarId].userSelected

export const getUserGroupFilterSelected = calendarId => state =>
  state.crm.calendars[calendarId].userGroupSelected

export const getActivityTypeFilterSelected = calendarId => state =>
  state.crm.calendars[calendarId].activityType

export const getMeetingCanceled = calendarId => state =>
  state.crm.calendars[calendarId].filterCancel

export function switchView(calendarId, view) {
  return {
    type: SWITCH_CALENDAR_VIEW,
    name: calendarId,
    view
  }
}

export function chageDateCalendarWeek(calendarId, increase) {
  return {
    type: CHANGE_DATE_IN_CALENDAR_WEEK,
    name: calendarId,
    increase
  }
}

export function chageDateCalendarDay(calendarId, increase) {
  return {
    type: CHANGE_DATE_IN_CALENDAR_DAY,
    name: calendarId,
    increase
  }
}

export function jumpToDateCalendarDay(calendarId, date) {
  return {
    type: JUMP_TO_DATE_IN_CALENDAR_DAY,
    name: calendarId,
    date
  }
}

export function viewTodayCalendar(calendarId) {
  return {
    type: VIEW_TODAY_CALENDAR,
    name: calendarId
  }
}

export function selectedDate(calendarId, date) {
  const dateOfWeek = getStartDateOfWeek(date)
  return {
    type: CALENDAR_SELECTED_DATE,
    name: calendarId,
    date: dateOfWeek
  }
}

export function fetchCalendars(calendarId) {
  return {
    type: FETCH_CALENDAR_ACTIVITIES,
    name: calendarId
  }
}

export function toggleModal(calendarId) {
  return {
    type: CALENDAR_TOGGLE_MODAL,
    name: calendarId
  }
}

export function toggleFilterConfirm(calendarId) {
  return {
    type: TOGGLE_FILTER_CONFIRM_MEETING,
    name: calendarId
  }
}

export function toggleFilterCanceled(calendarId) {
  return {
    type: TOGGLE_FILTER_CANCEL_MEETING,
    name: calendarId
  }
}

export function toggleWeekPanel(calendarId) {
  return {
    type: TOGGLE_WEEK_PANEL,
    name: calendarId
  }
}

export function userFilterChange(calendarId, userSelected) {
  return {
    type: USER_FILTER_CHANGE,
    name: calendarId,
    userSelected
  }
}

export function activityTypeFilterChange(calendarId, activityTypeUid) {
  return {
    type: ACTIVITY_TYPE_CHANGE,
    name: calendarId,
    activityTypeUid
  }
}

export function userGroupFilterChange(calendarId, userGroupSelected) {
  return {
    type: USER_GROUP_FILTER_CHANGE,
    name: calendarId,
    userGroupSelected
  }
}

function createNamedWrapperReducer(reducerFunction, reducerName) {
  return (state, action) => {
    const { name } = action
    const isInitializationCall = state === undefined
    if (name !== reducerName && !isInitializationCall) return state

    return reducerFunction(state, action)
  }
}

const myCalendarInitialState = { ...initialState, isMyCalendar: true }
const groupCalendarInitialState = {
  ...initialState,
  isMyCalendar: false,
  users: [],
  userSelected: undefined,
  userGroupSelected: undefined,
  activityType: undefined
}

export const calendarsReducer = combineReducers({
  my: createNamedWrapperReducer(
    (state = myCalendarInitialState, action) => calendarState(state, action),
    'my'
  ),
  group: createNamedWrapperReducer(
    (state = groupCalendarInitialState, action) => calendarState(state, action),
    'group'
  )
})
