import React, { useState, useEffect } from 'react'
import TextInput from './text-input'
import validate from '../../../../utils/validates'

interface ICrmInput {
  input?: {
    value: number | string
    onChange?: Function
  }
  value?: string
  type?: string
  placeholder?: React.ElementType | string
  iconName?: string
  extraClassNames?: {
    containerClassNames?: string
    inputClassNames?: string
    placeholderClassNames?: string
  }
  onChange?: Function
  hasErrors?: boolean
  id?: string
  withBackground?: boolean
  onKeyPress?: Function
  autocomplete?: string
  onClick?: any
  style?: React.CSSProperties
}

export const CrmEmailInput: React.SFC<ICrmInput> = (props) => {
  const [error, setError] = useState(undefined)
  const [extraClassNames, setExtraClassNames] = useState({})
  const [meta, setMeta] = useState({})

  useEffect(() => {
    const class_names = { ...props.extraClassNames }
    const error_class = error ? 'CrmTextInput--hasErrors' : ''
    class_names.containerClassNames = ` CrmTextInput ${error_class}`
    class_names.inputClassNames += ' CrmTextInput-input'
    class_names.placeholderClassNames += ' CrmTextInput-placeholder'

    setExtraClassNames(class_names)
  }, [props, error])

  const validateEmail = async (e) => {
    const value = e.target.value
    const error = validate.email(value)
    setError(error)
    setMeta(
      error
        ? {
            valid: false,
            touched: true,
            error: error
          }
        : {}
    )
  }

  const handleKeyDown = async (e) => {
    await validateEmail(e)
  }

  const handleOnBlur = async (e) => {
    await validateEmail(e)
  }

  return (
    <div className="util-flexColumn">
      <div>
        <TextInput
          {...props}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          extraClassNames={extraClassNames}
          meta={meta}
        />
      </div>
    </div>
  )
}
