import React from 'react'
import { shape } from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'

const ActivityStatusWidget = props => {
  let iconName // eslint-disable-line

  console.log('props.activityStatus', props.activityStatus)

  if (props.activityStatus) {
    if (props.activityStatus.kind === 'completed') {
      iconName = 'fa-check'
    } else if (props.activityStatus.kind === 'failure') {
      iconName = 'fa-frown-o'
    } else if (props.activityStatus.kind === 'canceled') {
      iconName = 'fa-circle'
    } else if (props.activityStatus.kind === 'sheduled') {
      iconName = 'fa-calendar'
    }

    return (
      <span
        className={`ActivityStatusWidget ActivityStatusWidget-${props.activityStatus.kind}`}
        title={props.activityStatus.description}
      >
        <FormattedMessage
          id={'crm.activities.status.' + props.activityStatus.code}
          defaultMessage={props.activityStatus.description}
        />
        {/* {iconName && (
          <i
            className={`fa ${iconName} ActivityStatusWidget-icon ActivityStatusWidget-icon-kind-${props
              .activityStatus.kind}`}
          />
        )} */}
      </span>
    )
  }
  return <span>Status</span>
}
export const EmailStatusWidget = ({ status }) => {
  return (
    <span
      className={`ActivityStatusWidget EmailStatusWidget-${status}`}
      title={status}
    >
      <FormattedMessage
        id={'crm.activities.email_status.' + status}
        defaultMessage={status}
      />
    </span>
  )
}

ActivityStatusWidget.defaultProps = {
  activityStatus: undefined
}
ActivityStatusWidget.propTypes = {
  activityStatus: shape({})
}

export default injectIntl(ActivityStatusWidget)
