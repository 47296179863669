import React from 'react'
import { shape } from 'prop-types'
import { qlqGetUser } from 'crm-data/users'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

const userDashboardPopupHoc = WrappedComponent => {
  const UserDashboardPopupWithQuery = props => {
    const userUid = get(props, 'filters.user.user_uid.value', get(props, 'filters.user_uid.value', null))

    const { loading, data, refetch } = useQuery(qlqGetUser, {
      variables: {
        user_uid: userUid
      },
      fetchPolicy: 'no-cache',
      skip: !userUid
    })

    const user = data?.user
    const dashboardType =
      user &&
      get(user, 'department.name', '')
        .toLowerCase()
        .indexOf('telesales') >= 0
        ? 'telesales'
        : 'rm'

    return <WrappedComponent {...props} loading={loading} dashboardType={dashboardType} user={user} />
  }

  UserDashboardPopupWithQuery.propTypes = {
    filters: shape({})
  }

  UserDashboardPopupWithQuery.defaultProps = {
    filters: undefined
  }

  return UserDashboardPopupWithQuery
}

export default userDashboardPopupHoc
