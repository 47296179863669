import moment from 'moment'
export const groupActivitiesByDate = activities => {
  return groupItemsBy('created_time', true)(activities)
}

export const groupItemsBy = (key, isDate = false) => items => {
  return items.reduce((p, c) => {
    let value = c[key]
    if (isDate) {
      value = moment(c[key]).format('YYYYMMDD')
    }
    if (p[value]) {
      p[value].push(c)
    } else {
      p[value] = [c]
    }
    return p
  }, {})
}

export const groupItemsByRelativeTime = (key, items) => {
  moment.updateLocale('en', {
    relativeTime: {
      d: 'Yesterday',
      dd: '%d Days ago',
      M: 'Last Month',
      MM: '%d Months ago',
      y: 'Last Year',
      yy: '%d Years ago'
    }
  })
  const sortedItem = [...items].sort((a, b) => moment(a[key]).diff(moment(b[key]))).reverse()
  return sortedItem.reduce((p, c) => {
    let value = c[key]
    const isSameDay = moment(value).isSame(moment(), 'day')
    const groupKey = isSameDay ? 'Today' : moment(value).fromNow(true)
    if (p[groupKey]) {
      p[groupKey].push(c)
    } else {
      p[groupKey] = [c]
    }
    return p
  }, {})
}
