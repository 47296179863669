import React from 'react'
import { mapContextToFilter, convertUnderlineFilterToObject } from 'crm-modules/dashboard/dashboard-utils'
import { CrmFullpageContainer } from 'crm-components'
import { DynamicTableComponent, genericTableFactory } from '@cartrack-crm/crm-analytics'
import { AnalyticsContextProvider } from 'crm-core/analytics/context'
import TableFilters from 'crm-modules/dashboard/table-filters'
import { FilterSectionOpportunity, FilterSectionSaleDate } from '@cartrack-crm/crm-filter-section'
import withLeadPopUp from '../../../modules/dashboard/components/_with-leads-popup-hoc'
import { useGeneralPermissionsContext, PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import { useSelector } from 'react-redux'
import { getUser } from 'crm-duxs/crm-reducer'

const initialColumns = [
  { field: 'account.name', name: 'Account' },
  { field: 'account.lead_campaign.name', name: 'Lead Campaign' },
  { field: 'vehicle_count', name: 'Quantity' },
  { field: 'account.lead_in_date', name: 'Lead In Date' },
  { field: 'confidence_level', name: 'Confidence' },
  { field: 'due_date', name: 'Due Date' },
  { field: 'owner_user.full_name', name: 'Owner' },
  { field: 'created_time', name: 'Created', type: 'date' },
  { field: 'completed_date', name: 'Completed Date' }
]

const initialPivotComponent = {
  props: {
    rows: [
      {
        data: {
          data_source: 'ds',
          field: 'owner_user.full_name'
        },
        name: 'Owner',
        id: 'owner_user.full_name'
      }
    ],
    columns: [
      {
        data: {
          data_source: 'ds',
          field: 'due_date_month'
        },
        name: 'Due Month',
        id: 'due_date_month'
      }
    ],
    values: [
      {
        data: {
          data_source: 'quantity',
          field: 'quantity'
        },
        name: 'Quantity',
        id: 'quantity'
      }
    ]
  }
}

const qlOptions = {
  options: ownProps => {
    const props = { analyticsContext: { filters: ownProps.filters } }
    let filter: any = {
      ...ownProps.filters,
      ...mapContextToFilter(props)
    }
    filter = convertUnderlineFilterToObject(filter)

    delete filter.ownerUserUid

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl
  }),
  fetchPolicy: 'no-cache'
}

const OpportunitiesTable: React.ComponentType<DynamicTableComponent> = genericTableFactory({
  qlOptions,
  rootQLType: 'Opportunity',
  useEdges: true,
  queryRoot: 'opportunities'
})

const OpportunitiesListView = withLeadPopUp(props => (
  <CrmFullpageContainer>
    <div className="util-fullHeight util-flexGrow util-flexColumn">
      <OpportunitiesTable
        {...props}
        initialColumns={initialColumns}
        initialPivotComponent={initialPivotComponent}
        onShowLeadsPopup={props.onShowLeadsPopup}
        filtersComponent={
          <TableFilters
            {...props}
            nameSectionActivityDate="Due Date"
            additionalFilters={
              <React.Fragment>
                <FilterSectionOpportunity
                  style={{
                    borderBottom: 0,
                    minWidth: 150,
                    margin: '10px 0',
                    padding: '0 20px'
                  }}
                />
                <FilterSectionSaleDate
                  style={{
                    borderBottom: 0,
                    minWidth: 200,
                    margin: '10px 0',
                    padding: '0 20px'
                  }}
                />
              </React.Fragment>
            }
          />
        }
      />
    </div>
  </CrmFullpageContainer>
))

const initialFilters = {
  opportunity: {
    status: {
      value: 'pending',
      label: 'Active'
    }
  }
}

const WithAnalyticsContextTable = props => {
  const { hasPermissionByType } = useGeneralPermissionsContext()
  const { user_uid } = useSelector(getUser)

  if (!hasPermissionByType(PERMISSIONS_TYPES.OPPORTUNITIES_MANAGE)) {
    initialFilters['opportunity']['owner_user_uid'] = user_uid
  }

  return (
    <AnalyticsContextProvider initialFilters={initialFilters}>
      <OpportunitiesListView {...props} />
    </AnalyticsContextProvider>
  )
}

export default WithAnalyticsContextTable
