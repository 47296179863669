import React, { useState, SFC } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'

library.add({ faAngleLeft, faAngleRight })

const DrawerPanelContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  // border-radius: 10px;
  // background-color: #ffffff;
  // box-shadow: 0 0 6px 0 rgba(84, 84, 84, 0.25);
`

const BodyContainer = styled.div`
  flex: 1;
  // padding: 8px;
  margin-right: 2em;
  // overflow: scroll;
`
const ActionContainer = styled.div`
  z-index: 2;
  position: absolute;
  right: 0px;
  padding: 8px;
  background: #fff;
  transition: width 0.3s;
  transition-timing-function: cubic-bezier(0.21, 1.37, 0, 0.98);
  top: 0px;
  bottom: 0px;
  // border-top-right-radius: 10px;
  // border-bottom-right-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(84, 84, 84, 0.25);
  ${(p) =>
    p.isOpen
      ? `
    width: ${p.expandedWidth}px;
  `
      : `width: auto;

  `};
`
const ActionPlaceholder = styled.div`
  ${(p) => `width ${p.collapseWidth}px;`}
  min-height: 150px;
`

const ActionOpenButton = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  left: -13px;
  background: #fff;
  font-size: 16px;
  padding: 8px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  color: #888;
  border: 1px solid rgba(84, 84, 84, 0.25);

  &:hover {
    // color: #666;
    background: #888;
    color: #fff;
  }
`

const ActionBody = styled.div`
  overflow-y: auto;
  height: 100%;
`

const DrawerPanel = (props) => {
  return <DrawerPanelContainer>{props.children}</DrawerPanelContainer>
}

const Body = (props) => {
  return <BodyContainer>{props.children}</BodyContainer>
}

interface ActionProps {
  collapseWidth?: number
  expandedWidth?: number
}

const Action: SFC<ActionProps> = (props) => {
  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen(!isOpen)

  return (
    <React.Fragment>
      <ActionContainer
        collapseWidth={props.collapseWidth || 100}
        expandedWidth={props.expandedWidth || 300}
        isOpen={isOpen}
      >
        <ActionBody>{typeof props.children === 'function' ? props.children(isOpen) : props.children}</ActionBody>
        <ActionOpenButton onClick={toggle}>
          <FontAwesomeIcon icon={isOpen ? 'angle-right' : 'angle-left'} />
        </ActionOpenButton>
      </ActionContainer>

      <ActionPlaceholder collapseWidth={props.collapseWidth || 100} />
    </React.Fragment>
  )
}

DrawerPanel.Body = Body
DrawerPanel.Action = Action

export default DrawerPanel
