import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { arrayPush } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddressItemJSX from '../../../../../modules/accounts/components/layout19/address-item.jsx'
import { initNewAddress } from '../../../../../utils/accounts-utils'

const AddressItem: any = AddressItemJSX

interface IProps {
  account: any
  sectionName: any
  addFields: Function
  values: any
  arrayHelpers: any
  errors: any
  intl: any
}

interface IState {}

class SectionAddresses extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
  }

  handleAddAddress = () => {
    const newAddress = initNewAddress('new')
    this.props.arrayHelpers.push({ ...newAddress })
  }

  removeAddressUI = index => {
    this.props.arrayHelpers.remove(index)
  }

  handleSaveUI = async (index, pAddress) => {
    this.props.arrayHelpers.form.setFieldValue('addresses[' + index + ']', {
      ...pAddress
    })
  }

  render() {
    const { values } = this.props
    const addresses = values.addresses.map(v => ({
      ...v
    }))
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <span
              className="AccountLeftSide-addButton"
              title={this.props.intl.formatMessage({
                id: 'crm.ui.address.form.add_address',
                defaultMessage: 'Add Address'
              })}
              onClick={this.handleAddAddress}
            >
              <FontAwesomeIcon
                className="util-hooverable util-pointer"
                icon="plus"
              />
            </span>
            <span className="AddAccountForm-Header util-marginLeft">
              <FormattedMessage
                id="crm.ui.address.form.addresses"
                defaultMessage="Addresses"
              />
            </span>
          </div>
        </div>
        <div>
          {addresses &&
            addresses.map((v, index) => {
              return (
                <AddressItem
                  key={index}
                  index={index}
                  account_uid={this.props.account.account_uid}
                  address={v}
                  removeAddressUI={this.removeAddressUI}
                  mapAlign="left"
                  mapWidth={0.6}
                  mapHeight={'200px'}
                  updateOnAddressFieldChanged={this.handleSaveUI}
                  showOnlyRemoveAddress={true}
                  hiddenFields={{ line2: true, headquarter: true }}
                  keepFormOpen={true}
                />
              )
            })}
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addFields: field => dispatch(arrayPush('crmAccount', 'addresses', field))
  }
}

export default connect(null, mapDispatchToProps)(injectIntl(SectionAddresses))
