import React, { PureComponent } from 'react'
import { shape, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import MinuteFormName from '../../minutes-form/minute-form-name.jsx'
import { CrmTextArea, CrmButton } from 'crm-components'

class SurveyInternalNotes extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: this.buildData(props),
      isEdited: false,
      isSaving: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data !== this.props.data ||
      nextProps.sectionData !== this.props.sectionData
    ) {
      this.setState({
        ...this.state,
        data: this.buildData(nextProps),
        isEdited: false,
        isChanged: false
      })
    }
  }

  handleFieldChanged = event => {
    const newData = { ...this.state.data }
    newData[event.currentTarget.id] = event.currentTarget.value
    const isChanged = newData !== this.props.sectionData
    this.setState({ ...this.state, data: newData, isChanged })
    this.props.onChange(newData)
  }

  buildData(props) {
    console.log('SurveBlock CurrentSystem buildData')
    console.dir(props)
    let sectionData = props.sectionData
    if (sectionData && typeof sectionData === 'object' && sectionData.value) {
      sectionData = sectionData.value
    }

    return { ...sectionData }
  }

  handleSave = data => {
    console.log('SurveyBlockCurrentSystem handleSave')
    console.dir(data)
    this.setState({ ...this.state, isSaving: true }, () => {
      // Do save
      const sectionData = { ...this.state.data }
      this.props.handleSave({
        section: this.props.section.sourceField,
        data: { value: sectionData }
      })
    })
  }
  handleCurrentSystemEdit = () => {
    this.setState({ ...this.state, isEdited: true })
  }

  render() {
    const { isChanged } = this.state
    const { isEditable } = this.props
    return (
      <div>
        <MinuteFormName
          formname={
            <FormattedMessage
              id="crm.ui.minute.form.internal_notes"
              defaultMessage="Internal Notes"
            />
          }
        />
        {isEditable && (
          <div>
            <CrmTextArea
              placeholder="Internal Notes"
              placeholderId="crm.ui.textarea.internal_notes"
              name="adnotations"
              id="adnotations"
              rows="10"
              input={{
                value: this.state.data.adnotations,
                onChange: this.handleFieldChanged
              }}
            />
            <CrmButton
              onClick={this.handleSave}
              label={
                <FormattedMessage
                  id="crm.ui.button.save"
                  defaultMessage="Save"
                />
              }
              enable={isChanged}
              type={isChanged ? 'primary' : 'button'}
            />
          </div>
        )}
        {!isEditable && (
          <div className="row ActivityListItem-header">
            <div className="col-xs-12 col-md-12 col-lg-12 ActivityListItem-headerItem">
              <span>
                <FormattedMessage
                  id="crm.ui.minute.form.internal_note"
                  defaultMessage="Internal Note"
                />
              </span>
              <div className="ActivityListItem-headerItem-value">
                {this.state.data.adnotations}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

SurveyInternalNotes.propTypes = {
  data: shape({}).isRequired,
  sectionData: shape({}).isRequired,
  handleSave: func.isRequired,
  section: shape({}).isRequired,
  isEditable: bool.isRequired,
  onChange: func.isRequired
}

export default SurveyInternalNotes
