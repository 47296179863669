/* eslint-disable */
import React from 'react'
import { withApollo } from 'react-apollo'
import moment from 'moment'

import { graphql, compose } from 'react-apollo'
import { qlqListOpportunities } from 'crm-data/opportunities'

import { arrayOf, shape, func } from 'prop-types'
import {
  CrmFullpageContainer,
  CrmDropdown,
  CrmButton,
  FlatTable
} from 'crm-components'
import { transpose } from 'crm-utils/data-utils'
import WithLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import CrmScatteredChart from './crm-opportunities-scattered-chart.jsx'

class OpportunitiesStatsDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      startDate: moment().add(-3, 'months'),
      endDate: moment().add(6, 'months')
    }

    this.detailsColumns = [
      {
        accessor: 'account_name',
        Header: 'Account'
      },
      {
        accessor: 'due_date',
        Header: 'Due',
        Cell: row => {
          const d = moment(row.original.due_date)
          // console.log('')
          return (
            <div
              className={`${
                d.diff(moment()) < 0 ? 'util-textRed' : 'util-textGreen'
              }`}
            >
              {row.original.due_date}
            </div>
          )
        }
      },
      {
        accessor: 'confidence_level',
        Header: 'Confidence'
      },
      {
        accessor: 'opportunities_size',
        Header: 'Size'
      }
    ]
    this.columns = [
      {
        accessor: 'owner_user_name',
        Header: 'RM Name'
      },
      {
        Header: 'Opportunities',
        Cell: row => {
          const total = row.original.overdue_count + row.original.current_count
          let overduePercent = (100 * row.original.overdue_count) / total
          overduePercent =
            row.original.current_count > 0
              ? Math.min(90, overduePercent)
              : overduePercent
          // console.log(
          //   'overduePercent',
          //   overduePercent,
          //   row.original,
          //   'total',
          //   total
          // )

          return (
            <div
              id={row.original.owner_user_uid}
              onClick={this.handleExpandRow}
            >
              <div
                className="util-flexRow"
                style={{
                  width: 200,
                  padding: 10,
                  color: 'white',
                  fontSize: '1.3em',
                  background:
                    overduePercent > 0
                      ? `linear-gradient( to right, #ce5239 ${overduePercent}%, #5cae60 `
                      : '#5cae60'
                }}
              >
                <div className="util-flexGrow  util-textLeft" title="Overdue">
                  {row.original.overdue_count}
                  {row.original.overdue_sum > 0 && (
                    <small className="util-paddingSm">
                      ({row.original.overdue_sum})
                    </small>
                  )}
                </div>
                <div
                  className="util-flexGrow util-textRight"
                  title="Current Opportunities"
                >
                  {row.original.current_count}
                  {row.original.current_sum > 0 && (
                    <small className="util-paddingSm">
                      ({row.original.current_sum})
                    </small>
                  )}
                </div>
              </div>
              {row.original.is_expanded && (
                <div>
                  Details
                  {row.original.details && (
                    <div>{this._renderDetailsTable(row)}</div>
                  )}
                </div>
              )}
            </div>
          )
        }
      }
    ]
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, () => {
        resolve()
      })
    })

  _renderDetailsTable = row => {
    return (
      <FlatTable data={row.original.details} columns={this.detailsColumns} />
    )
  }
  handleExpandRow = event => {
    const id = event.currentTarget.id
    // console.log('handleExpandRow', id)
    if (id) {
      const data = this.state.data.map(r => ({
        ...r,
        is_expanded: r.owner_user_uid === id
      }))
      this.setState({ data })
      this._handleLoadDetails(id)
    }
  }
  handleCurrentStepClick = selectedStepType => {
    // console.log('handleCurrentStepClick', selectedStepType)
    this.setState({ selectedStepType })
  }
  handleAnalyticsRangeSelected = params => {
    // console.log('handleAnalyticsRangeSelected', params)
    // this.props.onShowLeadsPopup(params)
  }

  _handleLoadDetails = async ownerUid => {
    // console.log('_handleLoadDetails')
    const variables = {
      filter: {
        completed_time: 'null',
        owner_user_uid: ownerUid
        // is_overdue: false
      },
      fields: ['owner_user_name', 'owner_user_uid', 'is_overdue'],
      aggregate: [],
      limit: 1000
    }
    const res = await this.props.client.query({
      query: qlqListOpportunities,
      variables,
      fetchPolicy: 'no-cache'
    })
    if (res.data && res.data.opportunities_list_qm_paged) {
      const details = [...res.data.opportunities_list_qm_paged.data]
      details.sort((a, b) => moment(a.due_date).diff(moment(b.due_date)))
      const data = this.state.data.map(r => ({
        ...r,
        details: r.owner_user_uid === ownerUid ? details : r.details
      }))
      this.setState({ data })
    }
  }
  _parseData = data => {
    const ret = data.reduce((a, i) => {
      const key = i.owner_user_uid
      if (!a[key]) {
        a[key] = {
          ...i,
          overdue_count: 0,
          overdue_sum: 0,
          current_count: 0,
          current_sum: 0,
          items: []
        }
      }
      if (i.is_overdue) {
        a[key].overdue_count += i.opportunity_uid
          ? Number(i.opportunity_uid)
          : 0
        a[key].overdue_sum += Number(i.opportunities_size)
      } else {
        a[key].current_count += Number(i.opportunity_uid)
        a[key].current_sum += Number(i.opportunities_size)
      }
      return a
    }, {})

    return Object.values(ret)
  }
  handleRefresh = async () => {
    // console.log('handleRefresh')

    try {
      const variables = {
        filter: {
          completed_time: 'null'
          // is_overdue: false
        },
        fields: ['owner_user_name', 'owner_user_uid', 'is_overdue'],
        aggregate: [
          { id: 'opportunity_uid', type: 'count' },
          { id: 'opportunities_size', type: 'sum' }
        ],
        limit: 1000
      }
      await this.setState({ isLoading: true })
      const res = await this.props.client.query({
        query: qlqListOpportunities,
        variables,
        fetchPolicy: 'no-cache'
      })
      // console.log('got data', res)
      if (res.data && res.data.opportunities_list_qm_paged) {
        const parsed = this._parseData(
          res.data.opportunities_list_qm_paged.data
        )
        parsed.sort((a, b) => a.overdue_sum - b.overdue_sum)
        // console.log('parsed ', parsed)
        this.setState({ data: parsed, isLoading: false })
      }
    } catch (err) {
      console.log('Error', err)
      await this.setState({ isLoading: false })
    }
  }

  render() {
    // localContext.filters.activity_date = {
    //   value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    //   valueLabel: 'Today',
    //   filterName: 'Activity Date'
    // }
    // delete localContext.filters.lead_in_date
    console.log('Render dash opportunities stats ')
    return (
      <div>
        <div className="row">
          <div className="col-md-10 util-textRed">
            <CrmButton
              label="Refresh"
              onClick={this.handleRefresh}
              type="primary"
              isSaving={this.state.isLoading}
            />
          </div>
        </div>
        {this.state.data && (
          <FlatTable data={this.state.data} columns={this.columns} />
        )}
      </div>
    )
  }
}

OpportunitiesStatsDashboard.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

OpportunitiesStatsDashboard.defaultProps = {
  dashboards: []
}

export default WithLeadsPopupHoc(withApollo(OpportunitiesStatsDashboard))
