import React, { SFC } from 'react'
import EventsHistoryList from 'crm-modules/events/events-history-list'
import { CrmVbox } from 'crm-components'

interface Props {
  order: any
}

const FitmentEventHistoryInner: SFC<Props> = props => {
  return (
    <CrmVbox className=" util-flexColumn util-fullHeight">
      <div className="util-fullHeight">
        <div>
          <div className="Navbar-innerNav Navbar--blue">
            <div className={`Navbar-innerNav-tab is-active`}>
              Fitment Events
            </div>
          </div>
        </div>
        <EventsHistoryList
          aggregates={[
            {
              type: 'order',
              aggregate_uid: props.order.order_uid
            }
          ]}
        />
      </div>
    </CrmVbox>
  )
}

export default FitmentEventHistoryInner
