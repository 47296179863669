const agentLeadsResults = {
  type: 'agentLeadsResults',
  code: 'rmLeadsResults',
  def_version: 5,
  title: 'Agent Leads Results',
  content: {
    data: {
      dataSources: [
        {
          code: 'AccountsListQM',
          name: 'AccountsListQM',
          type: 'QM',
          filter: {},
          fields: [
            'latest_owner_user_uid',
            'sales_result_code',
            'lead_campaign__lead_source__name'
          ],
          aggregate: [{ id: 'account_uid', type: 'count' }],
          contextMapping: {
            latest_owner_user_uid: 'user_uid',
            latest_owner__department_uid: 'department_uid'
          },
          transposeColumns: ['sales_result_code'],
          transposeKey: 'lead_campaign__lead_source__name',
          transposeValueField: 'account_uid'
        },
        {
          code: 'OrdersListQM',
          name: 'OrdersListQM',
          type: 'QM',
          filter: {
            completed_date: { $ne: null }
          },
          fields: ['owner__user_uid'],
          aggregate: [{ id: 'vehicles_count', type: 'sum' }],
          contextMapping: {}
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {},
          fields: [
            'department_uid',
            'full_name',
            'user_uid',
            'contract_start_date',
            'contract_end_date',
            'department__name',
            'is_active'
          ]
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyFields: [
          'user_uid',
          'account__lead_campaign__lead_source__name'
        ],
        mapping: [
          {
            fromDataSourceName: 'AccountsListQM',
            keyFields: {
              user_uid: 'latest_owner_user_uid',
              account__lead_campaign__lead_source__name:
                'lead_campaign__lead_source__name'
            },
            mapping: [
              {
                from: 'account_uid',
                to: 'accounts_all'
              },
              {
                from: 'lead',
                to: 'accounts_new'
              },
              {
                from: 'customer',
                to: 'accounts_won'
              },
              {
                from: 'pending',
                to: 'accounts_pending'
              },
              {
                from: 'lost',
                to: 'accounts_lost'
              },
              {
                from: 'invalid',
                to: 'accounts_invalid'
              },
              {
                from: 'quoted',
                to: 'accounts_quoted'
              }
            ]
          },
          {
            fromDataSourceName: 'OrdersListQM',
            keyFields: {
              user_uid: 'owner__user_uid'
            },
            mapping: [
              {
                from: 'vehicles_count',
                to: 'fitted_units'
              }
            ]
          },
          {
            fromDataSourceName: 'CrmUser',
            keyField: 'user_uid',
            type: 'left',
            mapping: [
              {
                from: 'full_name'
              },
              {
                from: 'contract_start_date'
              },
              { from: 'contract_end_date' },
              { from: 'department__name' },
              { from: 'is_active' }
            ]
          }
        ]
      }
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: true,
            generate_average: true,
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 50
              },
              {
                Header: 'Lead Source',
                accessor: 'account__lead_campaign__lead_source__name'
              },
              {
                Header: 'Leads',
                accessor: 'accounts_all',
                detailsPopup: 'accounts_results',
                detailsFilter: {},
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number'
              },
              {
                Header: 'Won',
                accessor: 'accounts_won',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'accounts_results',
                detailsFilter: {
                  sales_result_code: 'won'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Invalid',
                accessor: 'accounts_invalid',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'accounts_results',
                detailsFilter: {
                  sales_result_code: 'invalid'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Lost',
                accessor: 'accounts_lost',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'accounts_results',
                detailsFilter: {
                  sales_result_code: 'lost'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Leads',
                accessor: 'accounts_new',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'accounts_results',
                detailsFilter: {
                  sales_result_code: 'lead'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Quoted',
                accessor: 'accounts_quoted',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'accounts_results',
                detailsFilter: {
                  detailed_status_code: 'quoted'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid'
                }
              },
              // {
              //   Header: 'Unquoted',
              //   accessor: 'accounts_pending',
              //   className: 'util-textRight util-pointer',
              //   width: 60,
              //   type: 'number',
              //   detailsPopup: 'accounts_results',
              //   detailsFilter: {
              //     sales_step_code: 'pending'
              //   },
              //   detailsFilterMapping: {
              //     owner_user_uid: 'user_uid'
              //   }
              // },

              {
                Header: 'Wins to Leads',
                accessorFn: row => {
                  const all = row ? Number(row.accounts_all) : undefined
                  const won = row ? Number(row.accounts_won) : undefined
                  if (
                    all &&
                    won &&
                    !isNaN(all) &&
                    !isNaN(won) &&
                    all > 0 &&
                    won > 0
                  ) {
                    const res = Number((100 * won) / all)
                    return res && !isNaN(res) ? res : undefined
                  }
                },
                Cell: row => {
                  const all =
                    row && row.original
                      ? Number(row.original.accounts_all)
                      : undefined
                  const won =
                    row && row.original
                      ? Number(row.original.accounts_won)
                      : undefined
                  if (
                    all &&
                    won &&
                    !isNaN(all) &&
                    !isNaN(won) &&
                    all > 0 &&
                    won > 0
                  ) {
                    const res = Number((100 * won) / all)
                    return res && !isNaN(res)
                      ? res.toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        }) + '%'
                      : undefined
                  }
                },
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'accounts_results',
                detailsFilter: {
                  sales_step_code: 'won'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Fitments To Leads',
                width: 60,
                headerClassName: 'util-textCenter',
                accessorFn: row => {
                  if (
                    row &&
                    row.fitted_units &&
                    !isNaN(Number(row.fitted_units)) &&
                    row.accounts_all &&
                    !isNaN(Number(row.accounts_all))
                  ) {
                    if (Number(row.accounts_all) > 0) {
                      return Number(row.fitted_units) / Number(row.accounts_all)
                    }
                    return 0
                  }
                },
                type: 'number',
                align: 'right',
                maximumFractionDigits: 1
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}

export default agentLeadsResults
