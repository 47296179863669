import { DataSchema } from './data-utils/schema-types'

const typeActivity = {
  name: 'Activity',
  description: 'Activities - meetings, calls',
  fields: [
    {
      name: 'activity_uid',
      type: 'string',
      description: 'Activity ID'
    },
    {
      name: 'master_uid',
      type: 'string',
      description: 'Master UID'
    },
    {
      name: 'instance_uid',
      type: 'string',
      description: 'instance uid'
    },
    { name: 'account', type: 'Account' },
    { name: 'address', type: 'Address' },
    { name: 'call_external_uid', type: 'string' },
    { name: 'call_asterisk_id', type: 'string' },
    { name: 'call_start_time', type: 'string' },
    { name: 'call_end_time', type: 'string' },
    { name: 'activity_type_uid', type: 'string' },
    { name: 'activity_status_uid', type: 'string' },
    { name: 'activity_status_code', type: 'string' },
    { name: 'start_time', type: 'string' },
    { name: 'end_time', type: 'string' },
    { name: 'call_length', type: 'string' },
    { name: 'note_text', type: 'string' },
    { name: 'location_type', type: 'string' },
    { name: 'source_type_uid', type: 'string' },
    { name: 'result_data', type: 'string' },
    { name: 'kpis', type: 'string' },
    { name: 'details', type: 'string' },
    { name: 'qualification_type_uid', type: 'string' },
    { name: 'direction', type: 'string' },
    { name: 'meeting_minutes_location_validation', type: 'string' },
    { name: 'meeting_minutes_location_distance', type: 'string' },
    { name: 'is_customer', type: 'boolean' },
    { name: 'start_date_month', type: 'string' },
    { name: 'created_date_month', type: 'string' },
    { name: 'completed_date_month', type: 'string' },
    { name: 'start_date', type: 'string' },
    { name: 'created_date', type: 'string' },
    { name: 'completed_date', type: 'string' },
    { name: 'account', type: 'json' },
    { name: 'activity_type', type: 'json' },
    {
      name: 'call_log',
      type: 'json',
      fields: [{ name: 'name', type: 'string' }]
    },
    { name: 'participants', type: 'json' },
    { name: 'primary_performer', type: 'json' },
    { name: 'activity_status', type: 'json' },
    { name: 'status', type: 'json' },
    { name: 'meeting_minutes', type: 'json' },
    { name: 'address', type: 'json' },
    { name: 'email_queues', type: 'json' },
    { name: 'first_type', type: 'number' },
    { name: 'seq_same_type_success', type: 'number' },
    { name: 'seq_same_type_success_user', type: 'number' },
    { name: 'call_time', type: 'number' }
  ]
}

const typeAccount = {
  name: 'Account',
  description: 'Accounts',
  fields: [
    { name: 'account_uid', type: 'string' },
    { name: 'name', type: 'string' },
    { name: 'territory_uid', type: 'string' },
    { name: 'name_local', type: 'string' },
    { name: 'kind', type: 'string' },
    { name: 'lead_in_date', type: 'string' },
    { name: 'lead_in_date_month', type: 'string' },
    { name: 'validated_date_month', type: 'string' },
    { name: 'is_deleted', type: 'string' },
    { name: 'lead_source_type_uid', type: 'string' },
    { name: 'campaign_uid', type: 'string' },
    { name: 'cams_username', type: 'string' },
    { name: 'is_assign_to_me', type: 'boolean' },

    { name: 'industry_uid', type: 'string' },
    { name: 'remark', type: 'string' },
    { name: 'remarks', type: 'string' },
    { name: 'registration_number', type: 'string' },
    { name: 'validated_date', type: 'string' },
    { name: 'category', type: 'json' },
    { name: 'merged_onto_account_uid', type: 'string' },
    { name: 'website', type: 'string' },
    { name: 'owners', type: 'json' },
    { name: 'sales_status', type: 'string' },
    { name: 'sales_status_code', type: 'string' },
    { name: 'current_sales_process', type: 'string' },
    { name: 'last_sales_process', type: 'json' },
    { name: 'data', type: 'json' },
    { name: 'version', type: 'number' },
    { name: 'emails', type: 'sting' },
    { name: 'phone_numbers', type: 'string' },
    { name: 'last_activity_date_ageing_category', type: 'string' }
  ]
}

const types = [
  typeActivity,
  typeAccount,
  {
    name: 'Fitment',
    description: 'Fitments',
    fields: [
      {
        name: 'order_uid',
        type: 'string',
        description: 'Fitment ID'
      },
      {
        name: 'completed_date',
        type: 'date',
        description: 'Completed Date'
      },
      {
        name: 'status_code',
        type: 'string',
        description: 'Status Code'
      },
      {
        name: 'account',
        type: 'Account',
        description: 'Link to Account'
      }
    ],
    preselections: [
      {
        name: 'Completed Fitments',
        filters: {
          status_code: 'completed'
        }
      },
      {
        name: 'Pending Fitments',
        filters: {
          status_code: 'pending'
        }
      }
    ]
  },
  {
    name: 'Fitments - pending',
    description: 'Fitments',
    fields: [
      {
        name: 'activity_uid',
        type: 'string',
        description: 'Activity UID'
      },
      {
        name: 'activity_uid',
        type: 'string',
        description: 'Activity UID'
      }
    ],
    data_source: {
      type: 'QL',
      endpoint: '',
      filters: {
        status_code: 'completed'
      }
    }
  }
]

export const mockSchema: DataSchema = {
  types
}

export default types
