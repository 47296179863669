/* eslint-disable */
import fetch from 'isomorphic-fetch'
import { withEld } from 'util-components/with-eld.jsx'

export const apiErrorTypes = {
  INVALID_API_CALL: 'INVALID_API_CALL',
  INVALID_JSON: 'INVALID_JSON'
}

const url = withEld
  ? 'https://fleetwebtest-th.cartrack.com/jsonrpc/index.php?PHPSESSID='
  : '/jsonrpc/index.php?PHPSESSID='

export default function apiCaller(method, params = {}, { authToken } = {}) {
  let storedAuthToken
  if (!authToken) {
    const user = JSON.parse(window.localStorage.getItem('user'))
    storedAuthToken = (user && user.authToken) || ''
  }

  return fetch(`${url}${storedAuthToken || authToken}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    timeout: 5000,
    body: JSON.stringify({
      version: '2.0',
      method,
      id: 10,
      params: { x: 'x', ...params }
    })
  }).then(
    res =>
      res.json().then(
        json => {
          if (res.ok && !json.error) return json.result
          const error = new Error(json.error)
          error.name = apiErrorTypes.INVALID_API_CALL
          throw error
        },
        err => {
          const error = new Error(err.message)
          error.name = apiErrorTypes.INVALID_JSON
          throw error
        }
      ),
    err => {
      const error = new Error(
        err.message === 'Failed to fetch'
          ? 'Check your network connection.'
          : err.message
      )
      error.name = err.name
      throw error
    }
  )
}
