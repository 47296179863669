import { delay } from 'redux-saga'
import accountsSaga from './accounts-saga'
import accountsListSaga from './accounts-list-saga'
import settingDepaertmentSaga from './settings/departments-saga'
import activitiesSaga from './activities-saga'
import activitySaga from './activity-saga'
import settingTerritorySaga from './settings/territories-saga'
import crmSaga from './crm-saga'
import crmSagaSentry from './crm-saga-sentry'
import liveCallSaga from './live-call-saga'
import settingProductSaga from './settings/products-saga'
import dataSaga from './data-saga'
import dialerSaga from './dialer-saga'
import calendarsSaga from './calendars-saga'
import settingProfilePermissionsSaga from './settings/profile-permissions-saga'
import settingMeSaga from './settings/me-saga'
import settingDictionarySaga from './settings/dictionaries-saga'
import settingReportSaga from './settings/reports-saga'
import settingTemplateSaga from './settings/templates-saga'
import settingInstanceSaga from './settings/instance-saga'
import settingCampaignSaga from './settings/campaigns-saga'
import settingLeadSourceSaga from './settings/lead-sources-saga'
import adminResourceSaga from './admin/resources-saga'
import adminImportToolSaga from './admin/import-tool-saga'
import opportunitySaga from './opportunity-saga'
import documentsSaga from './documents-saga'
import messagesSaga from './messages-saga'
import phoneSage from './phone/phone-saga'
import meetingMinutesSaga from './meeting-minutes-saga'
import reportSaga from './report-saga'
import tasksSaga from './tasks-saga'
import filtersSaga from './filters-saga'
import userSession from './user-session-saga'
import attributeSaga from './admin/attributes-saga'
import { silentRefreshToken } from '../api/auth'
import { spawn, call, all } from 'redux-saga/effects'
import store from '../../index'

function makeRestartable(saga) {
  return function*() {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        yield call(saga)
        console.error(
          'unexpected root saga termination. The root sagas are supposed to be sagas that live during the whole app lifetime!',
          saga
        )
      } catch (err) {
        if (err && err.message === 'access_token_expired') {
          yield call(silentRefreshToken)
        }
        const reloadErrors = [
          'refresh_token_revoke',
          'refresh_token_expired',
          "Can't call API query - not authorized"
        ]
        if (reloadErrors.includes(err.message)) {
          window.crmLogout(err.message)
        }
        if (err.error && reloadErrors.includes(err.error)) {
          window.crmLogout(err.error)
        }
        console.error('Crm Saga error, the saga will be restarted', err)
      }
      yield delay(1000) // Avoid infinite failures blocking app TODO use backoff retry policy...
    }
  }
}

export const rootSagas = [
  accountsSaga,
  accountsListSaga,
  settingDepaertmentSaga,
  activitiesSaga,
  activitySaga,
  crmSaga,
  dialerSaga,
  filtersSaga,
  settingProductSaga,
  settingTerritorySaga,
  dataSaga,
  liveCallSaga,
  calendarsSaga,
  messagesSaga,
  settingProfilePermissionsSaga,
  settingDictionarySaga,
  settingReportSaga,
  settingTemplateSaga,
  settingCampaignSaga,
  settingLeadSourceSaga,
  adminResourceSaga,
  tasksSaga,
  opportunitySaga,
  documentsSaga,
  phoneSage,
  reportSaga,
  adminImportToolSaga,
  settingInstanceSaga,
  settingMeSaga,
  meetingMinutesSaga,
  userSession,
  attributeSaga
]

export default (options = {}) => {
  var sagas = [...rootSagas]

  if (options && options.Sentry) {
    sagas = [crmSagaSentry(options), ...sagas]
  }
  sagas = sagas.map(makeRestartable)
  return function* crmRootSaga() {
    yield all(sagas.map(saga => spawn(saga)))
  }
}
window.crmLogout = reason => {
  store.dispatch({ type: 'LOG_OUT', reason: reason })
}
