/* eslint-disable */
import React from 'react'
import moment from 'moment'
import {
  ComposedChart,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Cell,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ZAxis,
  BarChart,
  Bar
} from 'recharts'

class CrmScatterChart extends React.PureComponent {
  handleGotoAccount = event => {
    console.log('handleGotoAccount')
  }
  renderShape = props => {
    console.log('renderShape', props)
    const maxSize = 10
    let size = props.opportunities_size / 10
    size = Math.max(size, 5)
    size = Math.min(size, 25)

    return (
      <g>
        <circle cx={props.cx} cy={props.cy} r={size} fill="#5099ce" />
      </g>
    )
  }
  renderTooltip = props => {
    console.log('Tooltop ', props)
    const item =
      props && props.payload && props.payload.length > 0
        ? props.payload[0].payload
        : undefined
    return (
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid #999',
          margin: 0,
          padding: 10
        }}
      >
        {item && (
          <div id={item.account_uid} onClick={this.handleGotoAccount}>
            <p> {item.account_name} </p>
            <p>
              Vehicles: {item.opportunities_size} , {item.confidence_level}%
            </p>
            <p>
              {item.owner_user_name} due to {item.due_date}{' '}
            </p>
          </div>
        )}
      </div>
    )
  }
  render() {
    const startDate = moment().add(-3, 'months')
    const endDate = moment().add(6, 'months')

    const parsed = this.props.data
      ? this.props.data.map(row => {
          const ret = { ...row }

          let dueDateInDays = moment(row.due_date).diff(moment(), 'days')
          // console.log('due data', row.due_date, dueDateInDays)
          dueDateInDays = Math.max(dueDateInDays, -6 * 30)
          dueDateInDays = Math.min(dueDateInDays, 6 * 30)
          if (isNaN(dueDateInDays)) {
            dueDateInDays = 180
          }
          ret.due = dueDateInDays

          return ret
        })
      : []
    // const summaries = Object.values(
    //   parsed.reduce((a, i) => {
    //     if (!a[i.due]) {
    //       a[i.x] = { x: i.due, size: 0, count: 0, opportunities: [] }
    //     }
    //     a[i.x].size += i.opportunities_size
    //     return a
    //   }, {})
    // )
    // console.log('summaries', summaries)
    console.log('parsed', parsed)
    return (
      <div style={{ width: '100%' }}>
        <ResponsiveContainer width={'100%'} height={500}>
          {/* <ComposedChart width={800} height={400} data={parsed}> */}
          <ScatterChart
            width={800}
            height={400}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <XAxis
              type="number"
              dataKey={'due'}
              name="Due Date"
              unit="Days"
              // range={[-180, 180]}
            />
            {/* <ZAxis
              dataKey={'opportunities_size'}
              range={[10, 50]}
              name="Size"
              unit="vehicles"
            /> */}
            {/* <Area
              type="monotone"
              dataKey="amt"
              fill="#8884d8"
              stroke="#8884d8"
            /> */}
            {/* <CartesianGrid /> */}
            {/* <YAxis
            yAxisId="left"
            type="number"
            dataKey="x"
            name="Confidence"
            unit="%"
            stroke="#8884d8"
          /> */}
            <YAxis
              yAxisId="right"
              type="number"
              dataKey="confidence_level"
              name="Confidence"
              unit="kg"
              orientation="right"
              stroke="#82ca9d"
            />
            <Tooltip
              wrapperStyle={{ zIndex: 100 }}
              cursor={{ strokeDasharray: '3 3' }}
              content={this.renderTooltip}
            />
            <Scatter
              yAxisId="right"
              name="A school"
              data={parsed}
              fill="#8884d8"
              shape={this.renderShape}
            />

            {/* <Scatter yAxisId="right" name='A school' data={data02} fill='#82ca9d'/> */}
          </ScatterChart>
        </ResponsiveContainer>
        {/* <ResponsiveContainer width={'100%'} height={500}>
          <BarChart data={summaries}>
            <XAxis dataKey="x" type="number" domain={[-90, 180]} />
            <YAxis />
            <Bar dataKey="size" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer> */}
      </div>
    )
  }
}

export default CrmScatterChart
