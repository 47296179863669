import { form } from 'util-components'

export const resetPasswordFormKey = 'crmResetPasswordForm'
export default form(
  resetPasswordFormKey,
  {
    login: {
      type: 'text',
      placeholder: 'login',
      extraClassNames: { containerClassNames: 'Login-textInput util-marginTop' }
    },
    email: {
      type: 'text',
      placeholder: 'email',
      extraClassNames: { containerClassNames: 'Login-textInput util-marginTop' }
    }
  },
  { submitOnEnter: true }
)
