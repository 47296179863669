import React, { PureComponent } from 'react'
import { shape, bool, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import TableWithEdit from '../../components/_table-width-edit.jsx'
import MinuteFormName from '../../../minutes-form/minute-form-name.jsx'
import RemarksForm from './remark-form.jsx'

const remarksTableDefinition = {
  columns: [
    {
      accessor: 'author',
      Header: (
        <FormattedMessage
          id="crm.ui.table.minute.author"
          defaultMessage="author"
        />
      ),
      width: '30%'
    },
    {
      accessor: 'details',
      Header: (
        <FormattedMessage
          id="crm.ui.table.minute.details"
          defaultMessage="details"
        />
      ),
      width: '70%'
    }
  ]
}

const remarksFormDefinition = {
  fields: [
    {
      name: 'author',
      label: (
        <FormattedMessage
          id="crm.ui.table.minute.author"
          defaultMessage="author"
        />
      ),
      type: 'String'
    },
    {
      name: 'mobile',
      label: (
        <FormattedMessage
          id="crm.ui.table.minute.mobile_phone"
          defaultMessage="Mobile Phone"
        />
      ),
      type: 'String'
    }
  ],
  component: props => <RemarksForm {...props} />
}
const definition = {
  table: remarksTableDefinition,
  form: remarksFormDefinition
}

class SurveyBlockRemarks extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSave = data => {
    console.log('Survey Block handleSave')
    console.dir(data)
    this.props.handleSave({ section: this.props.section.sourceField, data })
  }

  render() {
    console.log('SurveyBlockRemarks render')
    console.dir(this.props)
    return (
      <div>
        <MinuteFormName
          formname={
            <FormattedMessage
              id="crm.ui.minute.form.discussion_notes"
              defaultMessage="Discussion Notes"
            />
          }
        />
        <TableWithEdit
          section={this.props.section}
          definition={definition}
          onSave={this.handleSave}
          data={this.props.sectionData}
          isEditable={this.props.isEditable}
        />
      </div>
    )
  }
}

SurveyBlockRemarks.propTypes = {
  handleSave: func.isRequired,
  section: shape({}).isRequired,
  isEditable: bool.isRequired,
  sectionData: shape({}).isRequired
}

export default SurveyBlockRemarks
