import React from 'react'
import { shape, func, string } from 'prop-types'
import withLeadsPopupHoc from '../../../components/_with-leads-popup-hoc.jsx'
import WidgetAgentsCallbacks from './widget-agents-callbacks.jsx'
import WidgetAgentsCallbacksOverdue from './widget-agents-callbacks-overdue.jsx'

class DashAgentsCallbacks extends React.PureComponent {
  handleAnalyticsRangeSelected = params => {
    // console.log('handleAnalyticsRangeSelected', params)
    this.props.onShowLeadsPopup(params)
  }

  render() {
    console.log('render dash DashAgentsCallbacks')
    return (
      <div>
        <WidgetAgentsCallbacksOverdue
          onClick={this.handleClickAgent}
          analyticsContext={this.props.analyticsContext}
          onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
          processType={this.props.processType}
        />
        <WidgetAgentsCallbacks
          onClick={this.handleClickAgent}
          analyticsContext={this.props.analyticsContext}
          onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
          processType={this.props.processType}
        />
      </div>
    )
  }
}

DashAgentsCallbacks.propTypes = {
  analyticsContext: shape({}).isRequired,
  onShowLeadsPopup: func.isRequired,
  processType: string
}
DashAgentsCallbacks.defaultProps = {
  processType: undefined
}

export default withLeadsPopupHoc(DashAgentsCallbacks)
