import React from 'react'
import { shape } from 'prop-types'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { CrmLabeledField } from 'crm-components'
import { getDateFormat } from 'crm-utils/time-utils'

import { getWonSize } from '../../../forecasts/useFetchForecastManager'
class OpportunityListItem extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleEditAction = () => {
    // this.props.onOpportunityClick(this.props.opportunity)
    this.props.handleSelectOpportunity(this.props.opportunity.opportunity_uid)
  }

  onQuotationSelect = document => {
    this.props.onOpportunityClick(document)
  }

  render() {
    console.log('Render opportunity list item', this.props)
    const { opportunity } = this.props
    const { documents } = opportunity
    const { handleEditAction } = this
    const currentStatusKind = opportunity && opportunity.status_code
    console.log('currentStatusKind', currentStatusKind)
    const isWon = currentStatusKind === 'won'
    let isCurrentQuotationStepWon = false
    if (
      documents &&
      documents[0] &&
      documents[0].process &&
      documents[0].process.current_step &&
      documents[0].process.current_step.process_step_type &&
      documents[0].process.current_step.process_step_type.name &&
      documents[0].process.current_step.process_step_type.name === 'Won'
    ) {
      isCurrentQuotationStepWon = true
    }
    const quantitySize =
      isWon && isCurrentQuotationStepWon
        ? getWonSize(opportunity)
        : opportunity.quantity
    return (
      <div
        className={`OpportunityListItem CrmMdCard 
        ${'OpportunityListItem--kind--' + currentStatusKind} '`}
        style={this.props.style}
        onClick={handleEditAction}
      >
        <div className="row">
          <div className="col-md-3">
            <CrmLabeledField
              noMargin
              label={
                <FormattedMessage
                  id="crm.ui.account.status"
                  defaultMessage="Status"
                />
              }
            >
              {currentStatusKind || '-'}
            </CrmLabeledField>
          </div>
          <div className="col-md-3">
            <CrmLabeledField
              noMargin
              label={
                <FormattedMessage
                  id="crm.ui.account.vehicles"
                  defaultMessage={
                    opportunity.quantity > 1 ? 'Vehicles' : 'Vehicle'
                  }
                />
              }
            >
              {quantitySize}
            </CrmLabeledField>
          </div>
          {!isWon && (
            <div className="col-md-3">
              <CrmLabeledField
                noMargin
                label={
                  <FormattedMessage
                    id="crm.ui.account.started"
                    defaultMessage="Started"
                  />
                }
              >
                {opportunity.created_time
                  ? getDateFormat(opportunity.created_time)
                  : ''}
              </CrmLabeledField>
            </div>
          )}
          {!isWon && (
            <div className="col-md-3">
              <CrmLabeledField
                noMargin
                label={
                  <FormattedMessage
                    id="crm.ui.account.due_date"
                    defaultMessage="Due Date"
                  />
                }
              >
                {opportunity.due_date
                  ? getDateFormat(opportunity.due_date)
                  : ''}
              </CrmLabeledField>
            </div>
          )}
          {!isWon && (
            <div className="col-md-3">
              <CrmLabeledField
                noMargin
                label={
                  <FormattedMessage
                    id="crm.ui.account.confidence"
                    defaultMessage="Confidence"
                  />
                }
              >
                {opportunity.confidence_level
                  ? opportunity.confidence_level + '%'
                  : ''}
              </CrmLabeledField>
            </div>
          )}
          <div className="col-md-3">
            <CrmLabeledField
              noMargin
              label={
                <FormattedMessage
                  id="crm.ui.account.owner"
                  defaultMessage="Owner"
                />
              }
            >
              {opportunity.owner_user && opportunity.owner_user.full_name
                ? opportunity.owner_user.full_name
                : ''}
            </CrmLabeledField>
          </div>

          {isWon && (
            <React.Fragment>
              <div className="col-md-3">
                <div className="LabeledField-label">
                  <FormattedMessage
                    id="crm.ui.account.signed_date"
                    defaultMessage="Signed Date"
                  />
                </div>
                <div className="LabeledField-value">
                  {opportunity.contract_signed_date ? (
                    <FormattedDate value={opportunity.contract_signed_date} />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <div
                className=" col-md-3"
                title={
                  <FormattedMessage
                    id="crm.ui.account.date_of_first_fitment"
                    defaultMessage="Date of first fitment"
                  />
                }
              >
                <div className="LabeledField-label">
                  <FormattedMessage
                    id="crm.ui.account.first_fitment"
                    defaultMessage="First Fitment"
                  />
                </div>
                <div className="LabeledField-value">n/a</div>
              </div>
              <div
                className=" col-md-3"
                title="Number of pending fitments from CAMS (not available yet - work in progress)"
              >
                <div className="LabeledField-label">
                  <FormattedMessage
                    id="crm.ui.account.pending"
                    defaultMessage="Pending"
                  />
                </div>
                <div className="LabeledField-value">n/a</div>
              </div>
              <div className=" col-md-3">
                <div className="LabeledField-label">
                  <FormattedMessage
                    id="crm.ui.account.fitted"
                    defaultMessage="Fitted"
                  />
                </div>
                <div className="LabeledField-value">n/a</div>
              </div>

              <div className="col-md-3">
                <div className="LabeledField-label">
                  <FormattedMessage
                    id="crm.ui.account.type"
                    defaultMessage="Type"
                  />
                </div>
                <div className="LabeledField-value">
                  {opportunity.is_existing_customer ? (
                    <FormattedMessage
                      id="crm.ui.account.existing_customer"
                      defaultMessage="Existing Customer"
                    />
                  ) : (
                    <FormattedMessage
                      id="crm.ui.account.new_customer"
                      defaultMessage="New Customer"
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        {opportunity.is_deleted && (
          <div
            className="util-textCenter util-textRed"
            style={{
              height: '15px',
              backgroundColor: '#A61C1C',
              color: '#fff'
            }}
          >
            <FormattedMessage
              id="crm.ui.account.deleted"
              defaultMessage="Deleted"
            />
          </div>
        )}
      </div>
    )
  }
}

OpportunityListItem.defaultProps = {
  style: {}
}

OpportunityListItem.propTypes = {
  opportunity: shape({}).isRequired,
  style: shape({})
}

export default OpportunityListItem
