import { hhmmss } from 'crm-utils/common-utils'

const agentHistoricalActuals = {
  type: 'item',
  label: 'Agent Historical Actuals B2B',
  code: 'agentHistoricalActualsB2B',
  dashboard_uid: 'agentHistoricalActualsB2B',
  defaultFilters: {
    organization_unit: {
      department_type_uid: {
        value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
        label: 'Telesales'
      }
    },
    account__lead_campaign__transaction_type_code: {
      value: 'B2B',
      label: 'B2B'
    }
  },
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'agentHistoricalActualsB2B',
        def_version: 5,
        title: 'Agent Historical Actuals B2B',
        description_: "Predefined filter: 'Telesales'",
        content: {
          data: {
            dataSources: [
              {
                code: 'AgentHistoricalB2B',
                name: 'AgentHistoricalB2B',
                type: 'QM',
                qlOnly: true,
                qlRoot: 'agent_historical_actual_b2b',
                qlQuery: `
                    query agent_historical_actual_b2b($filter: JSON) {
                      agent_historical_actual_b2b(filter: $filter) {
                        answered_calls
                        answered_calls_time
                        calls_unique_leads
                        meetings_created
                        not_answered_calls
                        total_calls
                        total_calls_time
                        won_opportunities
                        fitted_units
                        conversion_lead_dialed
                        call_per_lead
                        fit_to_sales
                        user_uid
                        user {
                            full_name
                            is_active
                            contract_start_date
                          }
                      }
                    }
                  `,
                filter: {},
                contextMapping: {}
              }
            ]
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                mob_fix_columns: 2,
                props: {
                  generate_summary: true,
                  generate_average: true,
                  initialSort: {
                    id: 'user.contract_start_date',
                    order: 'asc'
                  },
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 30
                    },
                    {
                      Header: 'RM',
                      accessor: 'user.full_name',
                      classNameHandler: row => (row.original?.user?.is_active ? '' : 'util-opacity50'),
                      Cell: row => row?.original?.user?.full_name,
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user_uid: 'user_uid'
                      }
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'user.contract_start_date',
                      Cell: row => row?.original?.user?.contract_start_date,
                      width: 70
                    },
                    {
                      Header: 'Leads Dialed',
                      accessor: 'calls_unique_leads',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        type: { medium_code: 'phone_call' },
                        array_agg: {
                          group_by: ['account_uid', 'created_by_user_uid'],
                          key_condition: 'activity_uid'
                        }
                      },
                      detailsFilterMapping: {
                        created_by_user_uid: 'user_uid'
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Answered Calls',
                      accessor: 'answered_calls',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        status: { is_success: true },
                        type: { medium_code: 'phone_call' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Not Answered Calls',
                      accessor: 'not_answered_calls',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        status: { is_success: false },
                        type: { medium_code: 'phone_call' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Total Calls',
                      accessor: 'total_calls',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        type: { medium_code: 'phone_call' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Calls per Lead',
                      accessor: 'call_per_lead',
                      width: 50,
                      type: 'number',
                      align: 'right'
                    },
                    {
                      Header: 'Total Talk Time',
                      accessor: 'answered_calls_time',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        status: { is_success: true },
                        type: { medium_code: 'phone_call' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer',
                      Cell: row => hhmmss(row.original.answered_calls_time)
                    },
                    {
                      Header: 'Total Time on Phone',
                      accessor: 'total_calls_time',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        type: { medium_code: 'phone_call' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer',
                      Cell: row => hhmmss(row.original.total_calls_time)
                    },
                    {
                      Header: 'Direct Sales - Wins',
                      accessor: 'won_opportunities',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        opportunity: {
                          status: {
                            value: 'won'
                          }
                        }
                      },
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        completed_time: 'activity_date'
                      }
                    },
                    {
                      Header: 'Close rate',
                      Cell: () => 'n/a',
                      accessor: '',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        process: { step__type__kind: 'won' }
                      },
                      detailsFilterMapping: {
                        owner: { user_uid: 'user_uid' }
                      },
                      contextMapping: {
                        process: { completed_time: 'activity_date' }
                      }
                    },
                    {
                      Header: 'Meetings Made',
                      accessor: 'meetings_created',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: { medium_code: 'meeting' },
                        created_by: { department: { name: 'Telesales' } },
                        activity_date: { $ne: 'null' },
                        primary_performer_user_uid: { $ne: 'null' },
                        is_primary: true
                      },
                      detailsFilterMapping: {
                        primary_performer_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        created_time: 'activity_date',
                        primary_performer_user_uid: 'user'
                      },
                      className: ' util-pointer',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'Conversion (Leads Dialled) ',
                      accessor: 'conversion_lead_dialed',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2,
                      unitSymbol: '%'
                    },
                    {
                      Header: 'Fits',
                      accessor: 'fitted_units',
                      detailsPopup: 'fitment',
                      detailsFilter: {
                        status_code: 'completed',
                        owner_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid'
                      },
                      className: 'util-textRight util-pointer',
                      width: 50,
                      type: 'number'
                    },
                    {
                      Header: 'Fits to sales',
                      accessor: 'fit_to_sales',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'AgentHistoricalB2B'
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}

export default agentHistoricalActuals
