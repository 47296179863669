import React from 'react'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { CrmLabeledField } from '@cartrack-crm/ui'
import { QuotationBarWidgetStyled } from './quotation-bar-widget.styled'
import cloneDeep from 'clone-deep'
import { getDateFormat } from 'crm-utils/time-utils'

interface IProps {
  quotations: any
  handleQuotationClick: Function
  handleShowQuotationTab: Function
  isShowAllQuotations: boolean
}

class QuotationBarWidget extends React.PureComponent<IProps> {
  render() {
    console.log('render quotation-bar-widget', this.props)
    const { quotations, isShowAllQuotations } = this.props
    let sortQuotations = cloneDeep(quotations)
    sortQuotations = sortQuotations
      ? sortQuotations.sort((a, b) => {
          if (a.process && b.process && a.process.started_time && b.process.started_time) {
            const date_a: any = new Date(a.process.started_time)
            const date_b: any = new Date(b.process.started_time)
            return date_a - date_b
          } else {
            return a
          }
        })
      : []
    const showQuotations = isShowAllQuotations ? sortQuotations : sortQuotations.slice(-1)
    const hideQuotations = sortQuotations.slice(0, -1)

    return (
      <QuotationBarWidgetStyled>
        <div>
          {showQuotations.map((v, index) => {
            const sumValueNet = v.items
              ? v.items.reduce((ret, item) => {
                  ret += item.value_net || 0
                  return ret
                }, 0)
              : 0
            const sumSubNet = v.items
              ? v.items.reduce((ret, item) => {
                  ret += item.subscription_net || 0
                  return ret
                }, 0)
              : 0

            const status = v.process && v.process.current_step ? v.process.current_step.process_step_type.name : ''
            const statusCode = v.process && v.process.current_step ? v.process.current_step.process_step_type.code : ''
            let statusDate =
              v.process && v.process.current_step && v.process.current_step.created_time
                ? v.process.current_step.created_time
                : ''
            if (!statusDate) {
              const attributes = v?.process?.attributes
              if (statusCode === 'new') {
                statusDate = v?.process?.started_time
              }
              if (attributes && statusCode === 'awaitingApproval') {
                statusDate = attributes?.sentToApprovalTime?.value
              }
              if (attributes && statusCode === 'approved') {
                statusDate = statusDate = attributes?.approvalTime?.value
              }
              if (attributes && statusCode === 'presented') {
                statusDate = statusDate = attributes?.presentedToCustomerTime?.value
              }
            }

            const statusClassName =
              v.process && v.process.current_step
                ? `ProcessWidget-step ProcessWidget-step--kind-${v.process.current_step.process_step_type.kind}`
                : ''

            return (
              <div
                key={index}
                className={`util-pointer util-hooverable ${'quotationBarWrapper'}`}
                onClick={() => this.props.handleQuotationClick(v)}
                title={statusDate ? `${status} at ${getDateFormat(statusDate)}` : null}
              >
                <div className={`${'colItem'} ${'colName'}`}>
                  <div
                    className={`LabeledField-value util-capitalize ${statusClassName}`}
                    style={{ textAlign: 'center' }}
                  >
                    {status}
                  </div>
                </div>

                <div className={`${'colItem'}`}>
                  <CrmLabeledField
                    label={<FormattedMessage id="crm.ui.account.date" defaultMessage="DATE" />}
                    valueClassName="LabeledField-value"
                    noMargin
                  >
                    {v.process && v.process.started_time && <FormattedDate value={v.process.started_time} />}
                  </CrmLabeledField>
                </div>

                <div className={`${'colItem'}`}>
                  <CrmLabeledField
                    label={<FormattedMessage id="crm.ui.account.type" defaultMessage="TYPE" />}
                    valueClassName="LabeledField-value util-capitalize"
                    noMargin
                  >
                    {v.sale_type}
                  </CrmLabeledField>
                </div>

                <div className={`${'colItem'}`}>
                  <CrmLabeledField
                    label={<FormattedMessage id="crm.ui.account.length" defaultMessage="LENGTH" />}
                    valueClassName="LabeledField-value"
                    noMargin
                  >
                    {v.contract_length}
                  </CrmLabeledField>
                </div>

                <div className={`${'colItem'}`}>
                  <CrmLabeledField
                    label={<FormattedMessage id="crm.ui.account.hardware" defaultMessage="HARDWARE" />}
                    valueClassName="LabeledField-value"
                    noMargin
                  >
                    {sumValueNet} {v.currency_code}
                  </CrmLabeledField>
                </div>

                <div className={`${'colItem'}`}>
                  <CrmLabeledField
                    label={<FormattedMessage id="crm.ui.account.subscription" defaultMessage="SUBSCRIPTION" />}
                    valueClassName="LabeledField-value"
                    noMargin
                  >
                    {sumSubNet} {v.currency_code}
                  </CrmLabeledField>
                </div>
              </div>
            )
          })}

          {!isShowAllQuotations && hideQuotations.length > 0 && (
            <div className={'showMoreQuotations'}>
              <div
                className="LabeledField-label util-hooverable util-pointer"
                onClick={() => {
                  this.props.handleShowQuotationTab()
                }}
              >
                <FormattedMessage
                  id="crm.ui.quotation.show_more_quotations"
                  defaultMessage={`Show ${hideQuotations.length} more quotations`}
                />
              </div>
            </div>
          )}
        </div>
      </QuotationBarWidgetStyled>
    )
  }
}

export default QuotationBarWidget

/* {sortQuotations.map(v => {
  return (
    <div>{v.document_uid} <FormattedDate value={v.created_time} /></div>
  )
})} */

/* {hideQuotations.map(v => {
  return (
    <div>{v.document_uid} {v.created_time}</div>
  )
})} */
