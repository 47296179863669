import React, { Component } from 'react'
import { connect } from 'react-redux'
import { shape, arrayOf } from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Table } from 'util-components'

class TasksTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.columns = this.buildColumns()
  }

  buildColumns() {
    return [
      {
        Header: 'Account',
        accessor: 'account',
        Cell: row => (
          <div>
            {row.original.account && (
              <Link to={`/crm/account/${row.original.account_uid}`}>
                {row.original.account
                  ? row.original.account.name
                  : row.original.account_uid}
              </Link>
            )}
          </div>
        )
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Asssigned To',
        accessor: 'assigned to',
        Cell: row => {
          const assignment =
            row.original.assignments && row.original.assignments.length > 0
              ? row.original.assignments[0]
              : undefined
          return (
            <div>
              {assignment && assignment.user && (
                <div>{assignment.user.full_name}</div>
              )}
            </div>
          )
        }
      },
      {
        Header: 'Due Date',
        accessor: 'assignments',
        Cell: row => {
          const assignment =
            row.original.assignments && row.original.assignments.length > 0
              ? row.original.assignments[0]
              : undefined
          return assignment ? (
            <div>
              <FormattedDate value={assignment.due_date} />
              &nbsp;
              <FormattedTime value={assignment.due_date} />
            </div>
          ) : null
        }
      }
    ]
  }

  componentWillMount() {}

  render() {
    return (
      <Table
        data={this.props.tasks}
        columns={this.columns}
        style={this.props.style}
      />
    )
  }
}

TasksTable.defaultProps = {
  style: undefined
}
TasksTable.propTypes = {
  tasks: arrayOf(shape()).isRequired,
  style: shape({})
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksTable)
