/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import UserItem from './rms-user-item.jsx'
import { graphql, compose } from 'react-apollo'
import { qlqProcessType } from 'crm-data/processes'
import { loadData, availableSections } from './rms-dashboard-utils'
import { arrayOf, shape, func } from 'prop-types'
import {
  CrmFullpageContainer,
  CrmDropdown,
  CrmButton,
  CrmCheckbox
} from 'crm-components'

import DashAgentsCallbacks from '../telesales/agents/dash-agents-callbacks.jsx'
class RmDashboardTasksStats extends React.PureComponent {
  render() {
    return (
      <DashAgentsCallbacks
        title="Overdue Tasks Statistics - all process types"
        {...this.props}
        processType="all"
      />
    )
  }
}

RmDashboardTasksStats.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

RmDashboardTasksStats.defaultProps = {
  dashboards: []
}

export default RmDashboardTasksStats
