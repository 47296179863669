import React from 'react'
import { shape, func } from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import AvatarPreview from 'crm-components/utils/user-avatar.jsx'
import { CrmLabeledField } from 'crm-components'

const Funnel = props => {
  const maxVal = props.data.reduce((a, i) => {
    return i.value ? Math.max(a, i.value) : a
  }, 0)
  return (
    <div
      style={{ height: 100, width: '100%', alignItems: 'center' }}
      className="util-flexColumn"
    >
      {props.data.map(r => {
        const w = (100 * r.value) / maxVal
        const width = Math.max(w, 10) + '%'
        return (
          <div
            style={{ flex: 1, width: width, backgroundColor: r.color }}
            title={r.name}
          >
            {r.value ? r.value : '0'}
          </div>
        )
      })}
    </div>
  )
}
class DashTelesalesAgentItem extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderFunnel() {
    const { user } = this.props
    if (!user.funnel) {
      return <span />
    }
    const data = [
      { name: 'Leads', value: user.funnel.lead, color: '#65b7f3' },
      { name: 'Prospects', value: user.funnel.prospect, color: '#b2d28c' },
      { name: 'Customers', value: user.funnel.customer, color: '#f47735' }
    ]
    return (
      <div style={{ width: 80 }}>
        <Funnel data={data} />
      </div>
    )
  }
  renderLeft() {
    const { user } = this.props
    const imageUrl = user.headshot_url
      ? `https://crm.cartrack.sg/resources/users/${user.headshot_url}`
      : undefined
    console.log('imageUrl', imageUrl)
    return (
      <div className="util-flexGrow util-flexColumn">
        <div
          className="util-flexGrow util-flexCenterContent"
          style={{ alignItems: 'center' }}
        >
          {user.photo_url && (
            <AvatarPreview
              src={user.photo_url}
              style={{ width: 50, height: 50, borderRadius: 50 }}
            />
          )}
        </div>
        <div className="util-marginBottom util-textCenter">
          <strong>{user.user_name}</strong>
        </div>
      </div>
    )
  }

  _renderStatLine(name, value) {
    return (
      <div
        className="util-paddingSm util-flexRow"
        style={{ borderBottom: '1px solid #f5f5f5', alignItems: 'center' }}
      >
        <div className="util-flexGrow">{name}</div>
        <div style={{ fontSize: '1.2em' }}>{value}</div>
      </div>
    )
  }
  renderKpis() {
    const { user } = this.props

    return (
      <div className="util-paddingMd">
        {user.mainKpis.map(kpi => this._renderStatLine(kpi.name, kpi.value))}
      </div>
    )
  }
  renderCrmActivithInPeriod = () => {
    const crmActivityInPeriod = this.props.user.crmActivityInPeriod
    if (!crmActivityInPeriod) {
      return <span />
    }
    return (
      <div>
        <div className="util-textCenter util-textLight">
          CRM Activity in selected period
        </div>
        <div className="util-flexRow">
          <div className="util-flexGrow">
            <CrmLabeledField label="MEETINGS CREATED">
              <span style={{ fontSize: 20 }}>
                {crmActivityInPeriod.meetingsCreated}
                {/* <span style={{ fontSize: 10, margin: 5 }}>
                  ({meetingsInPeriod.overdueCount})
                </span> */}
              </span>
            </CrmLabeledField>
          </div>

          <div className="util-flexGrow">
            <CrmLabeledField label="PHONE CALLS CREATED">
              <span style={{ fontSize: 20 }}>
                {crmActivityInPeriod.callsCreated}
                {/* <span style={{ fontSize: 10, margin: 5 }}>
                  ({meetingsInPeriod.overdueCount})
                </span> */}
              </span>
            </CrmLabeledField>
          </div>

          <div className="util-flexGrow">
            <CrmLabeledField label="NEW ACCOUNTS">
              <span style={{ fontSize: 20 }}>
                n/a
                {/* <span style={{ fontSize: 10, margin: 5 }}>
                  ({meetingsInPeriod.overdueCount})
                </span> */}
              </span>
            </CrmLabeledField>
          </div>
        </div>
      </div>
    )
  }

  handleClickMeetingsDetails = filters => {
    console.log('handleClickMeetingsDetails', filters)
    const params = {
      type: 'activities',
      filters: {
        medium_code: 'meeting',
        type__medium_code: 'meeting',
        performed_by_user_uid: this.props.user.user_uid,
        performer_user_uid: this.props.user.user_uid,
        activity__performer__user_uid: this.props.user.user_uid,
        ...filters
      }
    }
    if (this.props.analyticsContext && this.props.analyticsContext.filter) {
    }
    this.props.onShowLeadsPopup(params)
  }
  handleClickNotCompletedMeetings = () =>
    this.handleClickMeetingsDetails({
      status__kind: 'sheduled'
    })

  handleClickCanceledMeetings = () =>
    this.handleClickMeetingsDetails({
      status__kind: 'canceled'
    })
  handleClickCompletedMeetings = () =>
    this.handleClickMeetingsDetails({
      status__kind: 'completed'
    })

  renderMeetingsInPeriod = () => {
    const meetingsInPeriod = this.props.user.meetingsInPeriod

    return (
      <div>
        <div className="util-textCenter util-textLight">
          Meetings in selected period
        </div>
        <div className="util-flexRow">
          <div
            className="util-flexGrow"
            onClick={this.handleClickNotCompletedMeetings}
          >
            <CrmLabeledField label="SCHEDULED">
              <span className="util-textRed" style={{ fontSize: 20 }}>
                {meetingsInPeriod ? meetingsInPeriod.uncompletedCount : 0}
              </span>
            </CrmLabeledField>
          </div>

          <div
            className="util-flexGrow"
            onClick={this.handleClickCanceledMeetings}
          >
            <CrmLabeledField label="CANCELED">
              <span className="util-textRed" style={{ fontSize: 20 }}>
                {meetingsInPeriod ? meetingsInPeriod.canceledCount : 0}
              </span>
            </CrmLabeledField>
          </div>

          <div
            className="util-flexGrow"
            onClick={this.handleClickCompletedMeetings}
          >
            <CrmLabeledField label="COMPLETED">
              <span style={{ fontSize: 20 }}>
                {meetingsInPeriod ? meetingsInPeriod.completedCount : 0}
              </span>
            </CrmLabeledField>
          </div>
        </div>
      </div>
    )
  }

  renderPipelineByMonth = () => {
    const opportunitiesDueMonth = this.props.user.opportunitiesDueMonth
    if (!opportunitiesDueMonth) {
      return <span />
    }
    const mapped = Object.keys(this.props.user.opportunitiesDueMonth)
      .sort((a, b) => moment(a).diff(b))
      .map(key => this.props.user.opportunitiesDueMonth[key])
    console.log('mapped', mapped)

    return (
      <div>
        <div className="util-textCenter util-textLight">
          Opportunities By Due Date (Month)
        </div>
        <div className="util-paddingMd">
          {mapped.map(row => (
            <div
              key={row.date}
              className="util-paddingSm util-flexRow"
              style={{
                borderBottom: '1px solid #f5f5f5',
                alignItems: 'center'
              }}
            >
              <div className="util-flexGrow">{row.date}</div>
              <div>
                <span style={{ fontSize: '1.2em' }}>{row.size}</span>
                <small className="util-marginSm">({row.count})</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderPipeline = () => {
    const opportunitiesPipeline = this.props.user.opportunitiesPipeline
    if (!opportunitiesPipeline) {
      return <span />
    }
    const mapped = Object.values(this.props.user.opportunitiesPipeline)
    console.log('mapped', mapped)
    return (
      <div>
        <div className="util-textCenter util-textLight">
          Opportunities By Status
        </div>
        <div className="util-paddingMd">
          {mapped.map(row => (
            <div
              key={row.date}
              className="util-paddingSm util-flexRow"
              style={{
                borderBottom: '1px solid #f5f5f5',
                alignItems: 'center'
              }}
            >
              <div className="util-flexGrow">{row.kind}</div>
              <div>
                <span style={{ fontSize: '1.2em' }}>{row.size}</span>
                <small className="util-marginSm">({row.count})</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  renderOpportunities = () => {
    const opportunities = this.props.user.opportunities
    if (!opportunities) {
      return <span />
    }
    return (
      <div>
        <div className="util-textCenter util-textLight">
          Current status of opportunities
        </div>
        <div className="util-flexRow">
          <div className="util-flexGrow">
            <Link to="/crm/opportunities/list?viewCode=overdue">
              <CrmLabeledField label="OVERDUE">
                <span className="util-textRed" style={{ fontSize: 20 }}>
                  {opportunities.overdueSize}
                  <span style={{ fontSize: 10, margin: 5 }}>
                    ({opportunities.overdueCount})
                  </span>
                </span>
              </CrmLabeledField>
            </Link>
          </div>
          <div className="util-flexGrow">
            <Link to="/crm/opportunities/list?viewCode=current">
              <CrmLabeledField label="CURRENT">
                <span style={{ fontSize: 20 }}>
                  {opportunities.currentSize}
                  <span style={{ fontSize: 10, margin: 5 }}>
                    ({opportunities.currentCount})
                  </span>
                </span>
              </CrmLabeledField>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { user } = this.props
    console.log('render user', user)
    return (
      <div
        className="CrmMdCard util-pointer util-hooverable"
        onClick={this.props.onClick}
        style={{ paddingTop: 10 }}
      >
        <div className="util-flexRow">
          {this.renderLeft()}
          {this.renderFunnel()}
          <div className="util-flexGrow ">
            {this.renderKpis()}
            {/* {this.renderChart()}
            {this.renderStats()} */}
          </div>
        </div>
        {this.renderOpportunities()}
        {this.renderPipeline()}
        {this.renderPipelineByMonth()}
        {this.renderMeetingsInPeriod()}
        {this.renderCrmActivithInPeriod()}
      </div>
    )
  }
}

DashTelesalesAgentItem.propTypes = {
  user: shape({}).isRequired,
  onClick: func
}

DashTelesalesAgentItem.defaultProps = {
  onClick: () => {}
}
export default DashTelesalesAgentItem
