import { DashBoardWidget } from '../dashboard-widget'
import React from 'react'
import widgetTimeOnPhoneMadeHoc, { widgetTimeOnPhoneHocProps } from './time-on-phone-widget-hoc'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetTimeOnPhone: React.ComponentType<AnalyticDashboardWidget> = widgetTimeOnPhoneMadeHoc(
  (props: widgetTimeOnPhoneHocProps) => (
    <DashBoardWidget
      avgDataPassedMonth={props.avgTimeOnPhoneThreeMonth}
      avgDataPassedMonthPerPerson={props.avgTimeOnPhonePersonPassedMonth}
      avgPerPersonNumber={props.avgTimeOnPhoneCurrentMonthPerPerson}
      footerName={props.footerName}
      footerRender={props.threeMonthAgoTimeOnPhone}
      isLoading={props.isLoading}
      onClickDetail={props.onClickDetail}
      refetch={props.refetchData}
      secondFooterName={props.footerNamePerPerson}
      secondFooterRender={props.avgTimeOnPhonePassedMonthData}
      subtitle={props.mainCaption}
      subtitleSecondBlock={'Month date average'}
      title='Time on Phone'
      totalNumber={props.totalTimeOnPhone}
    />
  )
)
