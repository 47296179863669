/* eslint-disable camelcase */
import { graphql, compose } from 'react-apollo'
import qlCreateImport, {
  qlCreateImportConfig,
  qlUpdateImportConfig,
  qlImportConfig,
  qlImportHistoryLog
} from 'crm-data/import'

export const gqlCreateImport = graphql(qlCreateImport, {
  name: 'qlCreateImport'
})

export const gqlFetchImportConfig = graphql(qlImportConfig, {
  name: 'qlImportConfig'
})

export const gqlCreateImportConfig = graphql(qlCreateImportConfig, {
  name: 'qlCreateImportConfig'
})

export const gqlUpdateImportConfig = graphql(qlUpdateImportConfig, {
  name: 'qlUpdateImportConfig'
})

export const gqlqlfetchImportHistoryLog = graphql(qlImportHistoryLog, {
  options: ownProps => ({
    variables: { filter: { resource_uid: ownProps.resourceUid } }
  }),
  name: 'qlImportHistoryLog'
})

const importQLHOC = compose(
  gqlCreateImport,
  gqlFetchImportConfig,
  gqlUpdateImportConfig,
  gqlqlfetchImportHistoryLog
)

export default importQLHOC
