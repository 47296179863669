/* eslint-disable  */
import React from 'react'
import { shape, bool, func, string } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { CrmButton } from 'crm-components'
import { LoadingIcon } from 'util-components'

// eslint-disable-next-line
const WidgetUtilsHoc = WrappedComponent => {
  return class extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        isLoading: false
      }
    }

    handleSetHeaderComponent = comp => {
      // console.log('handleSetHeaderComponent', comp)
      this.setState({ header: comp })
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState({ ...this.state, ...newState }, () => {
          resolve()
        })
      })

    handleRefresh = () => {
      // console.log('handleRefresh', this.childRef)
      if (this.childRef) {
        this.childRef.handleRefresh()
      }
    }

    handleStartLoading = async refreshFn => {
      // console.log('handleStartLoading')
      await this.setStateAsync({ isLoading: true })
      try {
        await refreshFn()
      } catch (err) {
        console.log('Load Analytics error')
        // console.error(err)
        await this.setStateAsync({ isLoading: false, hasErrors: true })
        return
      }
      // console.log('Loading completed')
      await this.setStateAsync({ isLoading: false, hasErrors: false })
    }

    setRef = ref => {
      this.childRef = ref
    }

    render() {
      const { handleClick, height, title, description } = this.props
      console.log('render WidgetUtilsHoc')
      return (
        <div
          className="CrmWidget CrmWidget--noHeight Widget util-marginBottom util-flexColumn"
          onClick={handleClick}
          onRefresh={this.handleRefresh}
        >
          {this.state.isLoading && (
            <div className="CrmWidget--loading">
              <LoadingIcon />
              <div>Loading...</div>
            </div>
          )}
          {this.state.hasErrors && (
            <div className="CrmWidget--errors">
              <div>Ups - there was a problem loading analytics data...</div>
              <CrmButton
                style={{ float: 'right' }}
                icon="refresh"
                iconButton
                onClick={this.handleRefresh}
                style={{ zIndex: 10 }}
                label="Refresh"
              />
            </div>
          )}
          <div className="Widget-header row util-noMargin between-xs top-xs">
            <div className="Widget-title CrmWidget-title">
              <div className="util-flexRow">
                <div>{title}</div>
                <div className="util-flexGrow util-textRight">
                  {this.state.header ? this.state.header : ''}
                </div>
              </div>
              {description && (
                <div className="Widget-description">{description}</div>
              )}
            </div>
            <div className="CrmWidget-tools">
              <CrmButton
                style={{ float: 'right' }}
                icon="refresh"
                iconButton
                onClick={this.handleRefresh}
                style={{ zIndex: 10 }}
              />
            </div>
          </div>

          <div
            className="Widget-content CrmWidget-content CrmWidget-content--padding"
            style={{ height: height ? height : 360 }}
          >
            <WrappedComponent
              ref={this.setRef}
              {...this.props}
              isLoading={this.state.isLoading}
              onStartLoading={this.handleStartLoading}
              setHeaderComponent={this.handleSetHeaderComponent}
            />
          </div>
        </div>
      )
    }
  }
}

export default WidgetUtilsHoc
