/* eslint-disable */

import cloneDeep from 'clone-deep'
import moment from 'moment'

export const isFloat = number => {
  const preNumber = Math.round(Number(number))
  return Number(number) - preNumber !== 0
}

export const numberFormat = (number, separate = ' ') => {
  let res = number
  if (typeof number == 'number') {
    res = isFloat(number)
      ? number
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separate}`)
      : number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separate}`)
  }
  return res
}

export const resolvePathStringFromObject = (object, path, defaultValue) =>
  path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object)

export const calculatePercentInt = (firstNumber, secondNumber) => {
  let calculated = 0
  if (secondNumber === 0 && firstNumber !== 0) {
    calculated = 0
  } else if (firstNumber !== 0 && secondNumber !== 0) {
    calculated = Math.round((firstNumber / secondNumber) * 100)
      if (calculated < 1) {
          calculated = ((firstNumber / secondNumber) * 100)
          calculated = calculated.toFixed(2);
      }
  }
  return calculated
}

export const groupDataByMonth = (data, groupField, dataField) => {
  return data.reduce((a, i) => {
    const ret = { ...a }
    const month = moment(i[groupField])
      .startOf('month')
      .format('YYYY-MM')
    if (!ret[month]) {
      ret[month] = 0
    }
    ret[month] += i[dataField] ? Number(i[dataField]) : 0
    return ret
  }, {})
}

export const countDataByMonth = (data, groupField, dataField) => {
  return data.reduce((a, i) => {
    const ret = { ...a }
    const month = moment(i[groupField])
      .startOf('month')
      .format('YYYY-MM')
    if (!ret[month]) {
      ret[month] = 0
    }
    ret[month] += i[dataField] ? 1 : 0
    return ret
  }, {})
}

export const aggregateBy = (data, field, valueField) =>
  data && data.reduce
    ? data.reduce((a, i) => {
        const key = i[field] ? i[field] : 'empty'
        if (key) {
          if (!a[key])
            // eslint-disable-next-line
            a[key] = {
              ...i,
              value: 0,
              items: [],
              name: key
            }
          // eslint-disable-next-line
          a[key].value += valueField ? Number(i[valueField]) : 1
        }

        return a
      }, {})
    : []

export const transpose = (data, aggregateField, transposeField, valueField) =>
  data && data.reduce
    ? data.reduce((a, i) => {
        const key = i[aggregateField] ? i[aggregateField] : 'empty'
        if (key) {
          if (!a[key])
            // eslint-disable-next-line
            a[key] = {
              ...i,
              value: 0,
              items: [],
              name: key
            }
          // eslint-disable-next-line
          const trKey = i[transposeField]
          if (!a[key][trKey]) {
            // eslint-disable-next-line
            a[key][trKey] = 0
          }
          // eslint-disable-next-line
          a[key][trKey] += valueField ? Number(i[valueField]) : 1
        }

        return a
      }, {})
    : []

export const arraySum = (array, field) =>
  array && array.reduce
    ? array.reduce((a, i) => a + (i && i[field] ? Number(i[field]) : 0), 0)
    : 0
export const arrayAvg = (array, field) =>
  array && array.length > 0 ? arraySum(array, field) / array.length : 0

export const convertToMatrix = (
  data,
  xFieldName,
  yFieldName,
  valueFieldName,
  params
) => {
  // console.log(
  //   'convertToMatrix',
  //   data,
  //   xFieldName,
  //   yFieldName,
  //   valueFieldName,
  //   params
  // )
  let colSums = {}
  let ret = data.reduce
    ? data.reduce((a, i) => {
        const rowKey = i[yFieldName] ? i[yFieldName] : 'empty'
        const colKey = i[xFieldName] ? i[xFieldName] : 'empty'
        if (!a[rowKey]) {
          // eslint-disable-next-line
          a[rowKey] = {
            [yFieldName]: rowKey
          }
        }
        const row = a[rowKey]
        if (!row[colKey]) {
          row[colKey] = 0
        }
        if (!colSums[colKey]) {
          colSums[colKey] = {
            colKey,
            value: 0
          }
          if (params && params.xSortField) {
            colSums[colKey][params.xSortField] = i[params.xSortField]
          }
        }
        if (i[valueFieldName]) {
          row[colKey] += Number(i[valueFieldName])
          colSums[colKey].value += Number(i[valueFieldName])
        }

        return a
      }, {})
    : {}
  ret = Object.values(ret)
  // Iif (params && params.ySortField) {
  //   ret.sort(
  //     (a, b) =>
  //       a[params.ySortField]
  //         ? a[params.ySortField].localeCompare(b[params.ySortField])
  //         : 0
  //   )
  // }
  // console.log('colSums', colSums)
  colSums = Object.values(colSums)
  if (params && params.xSortField) {
    colSums.sort((a, b) =>
      a[params.xSortField]
        ? a[params.xSortField].localeCompare
          ? a[params.xSortField].localeCompare(b[params.xSortField])
          : a - b
        : 0
    )
  }
  const columnNames = colSums.reduce(
    (a, cn) => ({ ...a, [cn.colKey]: cn.colKey }),
    {}
  )
  ret = [
    columnNames,
    ...ret,
    Object.keys(colSums).map(c => ({ [colSums[c]]: colSums[c].value }))
  ]
  return ret
}

export const mock = 1

// Removes query fields based on fields from 'variables'
export function buildQlQueryByFields(query, variables) {
  // console.log('buildQlQueryByFields', query, variables)
  if (!variables || !variables.fields) {
    return query
  }
  const ret = cloneDeep(query)
  if (ret.definitions && ret.definitions.length > 0) {
    const def0 = ret.definitions[0]
    if (
      def0.selectionSet &&
      def0.selectionSet.selections &&
      def0.selectionSet.selections.length === 1
    ) {
      const sel0 = def0.selectionSet.selections[0]
      if (sel0 && sel0.selectionSet && sel0.selectionSet.selections) {
        const dataSel = sel0.selectionSet.selections.find(
          s => s.name && s.name.value === 'data'
        )
        if (dataSel) {
          dataSel.selectionSet.selections = dataSel.selectionSet.selections.filter(
            selField => {
              const isField = variables.fields.find(
                field => field === selField.name.value
              )
              if (isField !== undefined) {
                return true
              }
              // Check if is in filter
              if (variables.filter) {
                const isFilter = Object.keys(variables.filter).find(
                  field => field === selField.name.value
                )
                if (isFilter !== undefined) {
                  return true
                }
              }
              // Check if is aggregate
              if (variables.aggregate) {
                const isAggregate = variables.aggregate.find(
                  a => a.id === selField.name.value
                )
                if (isAggregate !== undefined) {
                  return true
                }
              }
              //
              if (selField.name.value === 'count') {
                return true
              }
              return false
            }
          )
        }
      }
    }
  }
  return ret
}
