/* eslint-disable camelcase */
import { createSelector } from 'reselect'
// Actions
export const FETCH_SETTING_LEAD_SOURCES = 'FETCH_SETTING_LEAD_SOURCES'
export const FETCH_SETTING_LEAD_SOURCES_SUCCESS =
  'FETCH_SETTING_LEAD_SOURCES_SUCCESS'
export const SELECT_LEAD_SOURCE = 'SELECT_LEAD_SOURCE'
export const CLEAR_SELECT_LEAD_SOURCE = 'CLEAR_SELECTLEAD_SOURCE'
export const SHOW_CREATE_FORM_LEAD_SOURCE = 'SHOW_CREATE_FORM_LEAD_SOURCE'
export const CREATE_LEAD_SOURCE = 'CREATE_LEAD_SOURCE'
export const CREATE_LEAD_SOURCE_SUCCESS = 'CREATE_LEAD_SOURCE_SUCCESS'
export const UPDATE_LEAD_SOURCE = 'UPDATE_LEAD_SOURCE'
export const UPDATE_LEAD_SOURCE_SUCCESS = 'UPDATE_LEAD_SOURCE_SUCCESS'

// Reducer
const initialState = {
  leadSources: [],
  leadSourceSelected: {
    uid: undefined,
    form_type: undefined
  }
}

export const findIndexOfLeadSourcesByUID = (leadSources, uid) =>
  leadSources.findIndex(leadSource => leadSource.lead_source_type_uid === uid)

const mergeStateLeadSourceData = (leadSources, uid, user) => {
  const indexOfLeadSources = findIndexOfLeadSourcesByUID(leadSources, uid)
  return Object.assign([], leadSources, { [indexOfLeadSources]: user })
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTING_LEAD_SOURCES:
    case CREATE_LEAD_SOURCE:
    case UPDATE_LEAD_SOURCE:
      return { ...state }

    case FETCH_SETTING_LEAD_SOURCES_SUCCESS: {
      return {
        ...state,
        leadSources: action.payload.leadSources
      }
    }
    case SELECT_LEAD_SOURCE:
      return {
        ...state,
        leadSourceSelected: { uid: action.payload.uid, formType: 'update' }
      }

    case CLEAR_SELECT_LEAD_SOURCE:
      return {
        ...state,
        leadSourceSelected: { uid: undefined, formType: undefined }
      }

    case SHOW_CREATE_FORM_LEAD_SOURCE:
      return {
        ...state,
        leadSourceSelected: { uid: undefined, formType: 'create' }
      }

    case CREATE_LEAD_SOURCE_SUCCESS: {
      return {
        ...state,
        leadSources: [...state.leadSources, action.payload.leadSource]
      }
    }
    case UPDATE_LEAD_SOURCE_SUCCESS: {
      return {
        ...state,
        leadSources: mergeStateLeadSourceData(
          state.leadSources,
          action.payload.leadSource.lead_source_type_uid,
          action.payload.leadSource
        )
      }
    }

    default:
      return state
  }
}

export function fetchLeadSource(master_uid) {
  return {
    type: FETCH_SETTING_LEAD_SOURCES,
    payload: {
      master_uid
    }
  }
}

export function setSelectedLeadSource(leadSourceUid) {
  return {
    type: SELECT_LEAD_SOURCE,
    payload: {
      uid: leadSourceUid
    }
  }
}

export function clearSelectedLeadSource() {
  return {
    type: CLEAR_SELECT_LEAD_SOURCE
  }
}

export function showCreateForm() {
  return {
    type: SHOW_CREATE_FORM_LEAD_SOURCE
  }
}

export function saveLeadSource(leadSource, values) {
  let type = CREATE_LEAD_SOURCE
  if (leadSource.formType === 'update') {
    type = UPDATE_LEAD_SOURCE
  }

  return {
    type,
    payload: {
      leadSource,
      values
    }
  }
}

/*eslint-disable */
// Selector
export const leadSourceSelector = state => state.crm.leadSource.leadSources
const selectedLeadSource = state => state.crm.leadSource.leadSourceSelected
const getSelected = (leadSources, selectedLeadSourceData) => {
  return {
    data:
      leadSources[
        findIndexOfLeadSourcesByUID(leadSources, selectedLeadSourceData.uid)
      ],
    formType: selectedLeadSourceData.formType
  }
}
/*eslint-enable */
export const getSelectedLeadSource = createSelector(
  leadSourceSelector,
  selectedLeadSource,
  getSelected
)
