/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import uuidv4 from 'uuid'
import { shape, func, string } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import TaskFormInner from './task-form-inner.jsx'
import { msToUTCTimestamp } from 'util-functions/time-utils'
import taskCreateHoc, {
  taskCreateHocPropTypes
} from 'crm-modules/processes/hoc/task-create-hoc'

class NewTaskForm extends PureComponent {
  constructor(props) {
    super(props)
    this.formId = uuidv4()
  }
  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, () => {
        resolve()
      })
    })

  handleSubmit = async task => {
    const newTask = {
      account_uid: task.task.account_uid,
      name: task.task.name,
      due_date: msToUTCTimestamp(task.task.due_date),
      assignee_type: 'user',
      assignee_user_uid: task.task.assign_to_user_uid
    }

    try {
      const savedTask = await this.props.onCreateTask(newTask)
      toastr.success('Task created')
      if (this.props.onTaskCreated) {
        this.props.onTaskCreated(savedTask)
      }
    } catch (err) {
      const message = err
        ? err.message
        : 'There was a problem completing this task'
      toastr.error(message)
    }
  }

  render() {
    return (
      <div>
        <TaskFormInner
          onSubmit={this.handleSubmit}
          initialValues={this.props.task}
          form={this.formId}
          task={this.props.task}
          onCancel={this.props.onCancel}
          isSaving={this.props.isSavingCreateTask}
        />
      </div>
    )
  }
}

NewTaskForm.defaultProps = {
  task: undefined,
  onCancel: () => {},
  onTaskCreated: () => {}
}

NewTaskForm.propTypes = {
  task: shape({}),
  onCancel: func,
  onTaskCreated: func,
  qlmCreateTask: func.isRequired,
  accountUid: string.isRequired,
  ...taskCreateHocPropTypes
}

export default taskCreateHoc(NewTaskForm)
