/* eslint-disable */
import React, { PureComponent } from 'react'
import { string, func, shape } from 'prop-types'
import uuidv4 from 'uuid'
import { reduxForm } from 'redux-form'
import gql from 'graphql-tag'
import { toastr } from 'react-redux-toastr'
import { graphql, compose } from 'react-apollo'
import { CrmButton } from 'crm-components'

// Inner element - QL enriched
class CrmQLEditableInner extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSub = event => {
    console.log('submit')
    console.dir(event)
    event.preventDefault()
    this.props.handleSubmit(event)
  }

  render() {
    console.log('CrmQLEditableFormInner')
    const { handleSubmit } = this.props
    console.dir(this.props)
    const rendererProps = {
      isEditing: true
    }

    return (
      <form
        onSubmit={this.handleSub}
        className={`CrmForm ${
          this.props.className ? this.props.className : ''
        }`}
      >
        {this.props.formFieldsComponent &&
          this.props.formFieldsComponent(rendererProps)}
        <div className="row">
          <div className="col-md-12 util-textRight">
            <CrmButton
              type="secondary"
              grouped
              label="Cancel"
              onClick={this.props.onCancel}
            />
            <CrmButton
              type="primary"
              grouped
              label="Save"
              onClick={this.handleSub}
            />
          </div>
        </div>
      </form>
    )
  }
}

const CrmQLEditableInnerForm = reduxForm({ enableReinitialize: true })(
  CrmQLEditableInner
)

// QL enabled elemtn

class QLInterimElement extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  setStateAsync = state =>
    new Promise(resolve => {
      this.setState(state, resolve)
    })

  handleSubmit = async event => {
    console.log('handleSubmit')
    console.dir(event)
    console.dir(this.props)

    // event.preventDefault();
    await this.setState({ isSaving: true })
    // try to save
    try {
      let qlVariables = {}
      let res
      let entityUid = this.props.entityKeyField
        ? event[this.props.entityKeyField]
        : event.entityUid
      let resultVariableName
      // if (this.props.entityKeyField) {
      //   qlVariables[this.props.entityKeyField] = event
      //   // TODO: Normalize
      // }
      if (this.props.qlEntityVariableName) {
        qlVariables[this.props.qlEntityVariableName] = event
      }
      // Handler to generate variables
      if (this.props.qlVariableMapper) {
        console.log('use qlVariableMapper')
        qlVariables = this.props.qlVariableMapper(event)
      }
      console.log('try to save, ', qlVariables, entityUid)
      if (entityUid === undefined || entityUid === 'new') {
        console.log('call create')
        qlVariables = { ...qlVariables, ...this.props.qlCreateVariables }
        console.dir(qlVariables)
        console.dir(this.props)
        resultVariableName = 'account_person_create'
        res = await this.props.qlCreate({ variables: qlVariables })
      } else {
        qlVariables = { ...qlVariables, ...this.props.qlUpdateVariables }
        res = await this.props.qlUpdate({ variables: qlVariables })
        resultVariableName = 'account_person_update'
      }
      console.log('Got Results')
      console.dir(res)
      if (this.props.onSaved) {
        toastr.success('Saved!')
        // TODO Extract result properly ..
        this.props.onSaved(res.data[resultVariableName])
      }
    } catch (err) {
      console.error(err)
    }
    await this.setState({ isSaving: false })
  }

  render() {
    console.log('render CrmQLEditableInnerForm')
    console.dir(this.props)
    return (
      <CrmQLEditableInnerForm
        className={this.props.className}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        form={this.props.form}
        initialValues={this.props.initialValues}
        formFieldsComponent={this.props.formFieldsComponent}
      />
    )
  }
}
// const CrmQLEditableFormInnerQL = compose(
//   graphql(qlqAccountPersonGet, {
//     options: ownProps => ({
//       skip:
//         ownProps.account_person_uid === 'new' || !ownProps.account_person_uid,
//       variables: { account_person_uid: ownProps.account_person_uid } // eslint-disable-line
//     }),
//     name: 'account_person_uid'
//   })
// )(PersonEditFormQl)

//
class CrmQLEditableForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      instanceId: uuidv4()
    }
  }

  render() {
    console.log('render CrmQLEditableForm')
    console.dir(this.props)
    // build dynamic class
    const InnerElementQL = compose(
      graphql(this.props.qlGet, {
        options: ownProps => ({
          variables: { entityUid: ownProps.entityUid } // eslint-disable-line
        }),
        skip: ownProps => ownProps.entityUid === 'new' || !ownProps.entityUid,
        name: 'entity'
      }),
      graphql(this.props.qlCreate, {
        name: 'qlCreate',
        options: this.props.qlCreateOptions
      }),
      graphql(this.props.qlUpdate, {
        name: 'qlUpdate',
        options: this.props.qlUpdateOptions
      })
    )(QLInterimElement)

    return (
      <InnerElementQL
        form={this.state.instanceId}
        qlGet={this.props.qlGet}
        initialValues={this.props.entity}
        {...this.props}
      />
    )
  }
}

CrmQLEditableForm.propTypes = {
  entityUid: string,
  entity: shape(),
  formFieldsComponent: func.isRequired,
  qlGet: shape().isRequired,
  qlCreate: shape().isRequired,
  qlUpdate: shape().isRequired,
  qlEntityVariableName: string.isRequired, // In which variable pass entity to QL function
  entityKeyField: string.isRequired, // Entity key
  onCancel: func,
  onSaved: func,
  className: string,
  qlUpdateOptions: func,
  qlCreateOptions: func
}

CrmQLEditableForm.defaultProps = {
  onCancel: () => {},
  onSaved: () => {},
  className: undefined,
  qlUpdateOptions: undefined,
  qlCreateOptions: undefined
}

export default CrmQLEditableForm
