import React from 'react'
import { func, object } from 'prop-types'
import { getFirstValue } from '../tools'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { FiltersOptionList } from '../tools'
import { useCrmContext } from '@cartrack-crm/crm-core'

const leadSourceTypeQl = gql(
  `query lead_source_types($master_uid: String!) { 
     master(master_uid: $master_uid) { 
        lead_source_types {  lead_source_type_uid instance_uid name code kind description }
    }
  }
     `
)

export const LeadSourceSelect = props => {
  const crmContext = useCrmContext()
  const selectedLeadSourceKind = getFirstValue(props.filterValues.account?.lead_campaign?.lead_source?.kind?.value)

  return (
    <Query
      query={leadSourceTypeQl}
      skip={!crmContext.master}
      variables={{
        master_uid: crmContext.master ? crmContext.master.master_uid : undefined
      }}
    >
      {({ loading, data }) => {
        const leadSourceTypes =
          data && data.master && data.master.lead_source_types ? [...data.master.lead_source_types] : []

        if (leadSourceTypes) {
          leadSourceTypes.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
        }

        const leadSourceOptions = leadSourceTypes
          ? leadSourceTypes
              .filter(v => (selectedLeadSourceKind ? v.kind === selectedLeadSourceKind : v))
              .map(t => ({
                value: t.lead_source_type_uid,
                name: t.name
              }))
          : []

        return (
          <FiltersOptionList
            enableSearch
            isLoading={loading}
            options={leadSourceOptions}
            onFilterValueChanged={props.onFilterValueChanged}
            selectedValue={getFirstValue(
              props.filterValues.account?.lead_campaign?.lead_source?.lead_source_uid?.value
            )}
          />
        )
      }}
    </Query>
  )
}

LeadSourceSelect.propTypes = {
  filterValues: object.isRequired,
  onFilterValueChanged: func.isRequired
}
