import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { shape, arrayOf, func, string } from 'prop-types'
import { propTypes, change, Field } from 'redux-form'
import CallDisposition from '../live-call/call-disposition.jsx'
import { bindActions } from 'crm-utils/reducer-utils'
import moment from 'moment'

import {
  CrmValidatedField,
  CrmTextArea,
  CrmButton,
  CrmDropdown
} from 'crm-components'

import { actions as phoneActions } from 'crm-duxs/phone/phone-reducer'

class PhoneCallFields extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleDispositionChange = disposition => {
    if (disposition) {
      this.props.dispatch(
        change(
          this.props.form,
          'disposition_type_uid',
          disposition.disposition_type_uid
        )
      )
      this.props.dispatch(
        change(
          this.props.form,
          'disposition_details',
          disposition.dispositionDetails
        )
      )
    }
  }

  handleClickStatus = event => {
    this.props.dispatch(
      change(this.props.form, 'activity_status_uid', event.currentTarget.id)
    )
  }
  handleClickResetStatus = () => {
    this.props.dispatch(change(this.props.form, 'activity_status_uid', null))
  }

  _renderOptionButton(option) {
    return option.name !== "Pending" && (
      <CrmButton
        key={option.activity_status_uid}
        small
        type="button"
        id={option.activity_status_uid}
        className={`PhoneCallForm-statusButton ${
          option.is_success ? 'PhoneCallForm-success' : ''
        }`}
        onClick={this.handleClickStatus}
        label={option.name}
      />
    )
  }

  renderPhoneCallStatusSelector = () => {
    if (this.props.callStatuses) {
      const callStatuses =
        this.props.callStatuses && this.props.callStatuses.slice
          ? this.props.callStatuses.slice()
          : []
      return (
        <div>
          {callStatuses
            .sort(a => (a.is_success ? 0 : 1))
            .map(this._renderOptionButton.bind(this))}
        </div>
      )
    }
  }
  _getSelectedStatus = () =>
    this.props.formValues &&
    this.props.formValues.activity_status_uid &&
    this.props.callStatuses &&
    this.props.callStatuses.find
      ? this.props.callStatuses.find(
          status =>
            status.activity_status_uid ===
            this.props.formValues.activity_status_uid
        )
      : undefined

  handlePhoneNumberChanged = phoneNumber => {
    if (phoneNumber) {
      const cd = this.props.account.contact_details.find(
        cd => cd.value === phoneNumber
      )
      if (cd) {
        this.props.dispatch(
          change(this.props.form, 'contact_details_uid', cd.contact_details_uid)
        )
      }
    }
    this.setState({ phoneNumber })
  }

  handleDial = () => {
    if (this.state.phoneNumber) {
      const originateParams = {
        number: { value: this.state.phoneNumber, name: this.state.phoneNumber }
      }
      this.props.phoneActions.originateCall({ originateParams })
    }
  }

  _renderPhoneNumbers = () => {
    console.dir(this.props.account)
    const phoneNumbersOptions =
      this.props.account && this.props.account.contact_details
        ? this.props.account.contact_details
            .filter(cd => cd.kind === 'phone' || cd.kind === 'mobile')
            .map(cd => ({
              name: cd.value,
              value: cd.value
            }))
        : []
    if (phoneNumbersOptions.length > 0 && !this.state.phoneNumber) {
      this.handlePhoneNumberChanged(phoneNumbersOptions[0].value)
    }
    return (
      <div className="row" style={{ marginTop: 5, marginBottom: 5 }}>
        <div className="col-md-3">
          <FormattedMessage
            id="crm.ui.activity.phone_number:"
            defaultMessage="Phone Number:"
          />
        </div>
        <div className="col-md-6">
          <CrmDropdown
            placeholder={
              <FormattedMessage
                id="crm.ui.activity.phone_number"
                defaultMessage="Phone Number"
              />
            }
            options={phoneNumbersOptions}
            input={{
              onChange: this.handlePhoneNumberChanged,
              value: this.state.phoneNumber
            }}
            disabled={!this.props.isEditing}
          />
        </div>
        <div className="col-md-3">
          {this.state.phoneNumber && (
            <CrmButton
              label={
                <FormattedMessage
                  id="crm.ui.button.dial"
                  defaultMessage="Dial"
                />
              }
              onClick={this.handleDial}
              disabled={!this.props.isEditing}
            />
          )}
        </div>
      </div>
    )
  }

  render() {
    const selectedStatus = this._getSelectedStatus()
    const processOptions =
      this.props.account && this.props.account.processes
        ? this.props.account.processes.map(p => ({
            value: p.process_uid,
            name: `${p.process_type ? p.process_type.name : 'Process'} (${moment(p?.started_time).format('YYYY-MM-DD')})`
          }))
        : []
    return (
      <div>
        {this._renderPhoneNumbers()}
        <div className="row">
          <div className="col-md-2">
            <FormattedMessage
              id="crm.ui.activity.status"
              defaultMessage="Status"
            />
          </div>
          <div className="col-md-10">
            {selectedStatus ? (
              <span>
                <strong>{selectedStatus.name}</strong>
                <CrmButton
                  small
                  style={{ marginLeft: 10 }}
                  label={
                    <FormattedMessage
                      id="crm.ui.button.change"
                      defaultMessage="change"
                    />
                  }
                  disabled={!this.props.isEditing}
                  onClick={this.handleClickResetStatus}
                />
              </span>
            ) : (
              this.renderPhoneCallStatusSelector()
            )}
          </div>
        </div>
        <div className="row" style={{ marginTop: 5, marginBottom: 5 }}>
          <div className="col-md-12">
            <CrmValidatedField
              component={CrmTextArea}
              componentProps={{
                placeholder: 'Phone Call Note',
                placeholderId: 'crm.ui.activity.phone_call_note',
                rows: 10
              }}
              name="note_text"
            />
          </div>
        </div>
        {this.props.account && this.props.account.processes && (
          <div className="row util-marginTop">
            <div className="col-md-3">
              <FormattedMessage
                id="crm.ui.activity.related_process"
                defaultMessage="Related Process"
              />
            </div>
            <div className="col-md-8">
              <Field
                component={CrmDropdown}
                componentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.ui.activity.related_process"
                      defaultMessage="Related Process"
                    />
                  ),
                  options: processOptions
                }}
                options={processOptions}
                name="process_uid"
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <CallDisposition
              accountUid={this.props.account.account_uid}
              account={this.props.account}
              activity={this.props.activity}
              onChange={this.handleDispositionChange}
              activityStatus={selectedStatus}
              form={this.props.form}
            />
          </div>
        </div>
      </div>
    )
  }
}

PhoneCallFields.propTypes = {
  ...propTypes,
  activity: shape({}).isRequired,
  account: shape({}).isRequired,
  callStatuses: arrayOf(shape()).isRequired,
  dispatch: func.isRequired,
  form: string.isRequired,
  formValues: shape({}).isRequired,
  phoneActions: shape({}).isRequired
}

PhoneCallFields.defaultProps = {}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    phoneActions: bindActions(dispatch, phoneActions)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PhoneCallFields)
