import React from 'react'
import { shape } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { CrmFormSection, FormattedDateTime } from 'crm-components'

const SectionFooters = props => (
  <CrmFormSection
    sectionName={
      <FormattedMessage
        id="crm.ui.account.footer.account_history"
        defaultMessage="Account history"
      />
    }
  >
    <div className="row ActivityListItem-header">
      <div className="col-xs-12 col-md-12 col-lg-6 ActivityListItem-headerItem">
        <span>
          <FormattedMessage
            id="crm.ui.account.footer.created_by"
            defaultMessage="CREATED BY"
          />
        </span>
        <div className="ActivityListItem-headerItem-value">
          {props.account.created_by_user
            ? props.account.created_by_user.full_name
            : ''}
        </div>
      </div>

      <div className="col-xs-12 col-md-12 col-lg-6 ActivityListItem-headerItem">
        <span>
          <FormattedMessage
            id="crm.ui.account.footer.created_at"
            defaultMessage="CREATED AT"
          />
        </span>
        <div className="ActivityListItem-headerItem-value">
          <FormattedDateTime value={props.account.created_time} />
        </div>
      </div>
    </div>
  </CrmFormSection>
)

SectionFooters.propTypes = {
  account: shape({}).isRequired
}

export default SectionFooters
