import { DashBoardWidget } from '../dashboard-widget'
import React from 'react'
import {
  widgetInvalidLeadsHoc,
  WidgetInvalidLeadsHocProps
} from './invalid-leads-widget-hoc'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetInvalidValidLeads: React.ComponentType<AnalyticDashboardWidget> = widgetInvalidLeadsHoc(
  (props: WidgetInvalidLeadsHocProps) => (
    <DashBoardWidget
      avgDataPassedMonth={props.avgInValidLeadsThreeMonth}
      avgDataPassedMonthPerPerson={props.avgInValidLeadsPersonPassedMonth}
      avgPerPersonNumber={props.avgInValidLeadsCurrentMonthPerPerson}
      footerName={props.footerName}
      footerRender={props.threeMonthAgoInValidLeads}
      isLoading={props.isLoading}
      onClickDetail={props.onClickDetail}
      refetch={props.refetchData}
      secondFooterName={props.footerNamePerPerson}
      secondFooterRender={props.avgInValidLeadsPassedMonthData}
      subtitle={props.mainCaption}
      subtitleSecondBlock={'Month date average'}
      title="Invalid Leads"
      totalNumber={props.totalInValidLeads}
    />
  )
)
