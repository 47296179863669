import React, { Component } from 'react'
import { connect } from 'react-redux'
import { shape, func } from 'prop-types'
import { Link } from 'react-router-dom'
// import { FlatTable } from 'crm-components'

const FlatTable = (props) => {
  return <div>TODO move FlatTable to crm-ui</div>
}

class GenericAnalyticsTableWidget extends Component<any, any> {
  constructor(props) {
    super(props)
    this.state = { isLoading: false }
  }

  _renderCellActivity(row, dim, rawVal) {
    console.log('_renderCellActivity')
    console.log(rawVal)
    return <div>Act</div>
  }

  _renderCellAccount(row, dim, value) {
    console.log('_renderCellAccount')
    console.log(value)
    if (value && typeof value === 'object') {
      return (
        <div>
          <Link to={`/crm/account/${value.value}`}>{value.label}</Link>
        </div>
      )
    }
    return <div />
  }
  _renderCell(row, dim) {
    console.log('_renderCell')
    console.dir(dim)
    {
      const rawVal =
        row.original && row.original.dims && row.original.dims[dim.code]
          ? row.original.dims[dim.code].value
          : undefined
      const value = this.props.getDimValue(
        this.props.dimensions,
        dim.code,
        rawVal
      )
      if (dim.data_type_code === 'activity') {
        return this._renderCellActivity(row, dim, value)
      } else if (dim.data_type_code === 'account') {
        return this._renderCellAccount(row, dim, value)
      }

      let valueLabel
      if (value) {
        if (typeof value === 'object') {
          if (value.label) {
            valueLabel = value.label
          } else if (value.value) {
            valueLabel = value.value
          }
        } else {
          valueLabel = value
        }
      }
      return <div>{valueLabel}</div>
    }
  }

  render() {
    console.log('_renderDetailsTable')
    console.dir(this.props)

    const rowsData =
      this.props.dataSourceData &&
      this.props.dataSourceData.data &&
      this.props.dataSourceData.data.rawData
        ? this.props.dataSourceData.data.rawData.rows
        : []

    const layoutColumns =
      this.props.widgetDefinition &&
      this.props.widgetDefinition.layoutParams &&
      this.props.widgetDefinition.layoutParams.columns
        ? this.props.widgetDefinition.layoutParams.columns
        : []

    const columns = layoutColumns
      ? layoutColumns.map((code) => {
          // const dim = this.props.dimensions[code]
          return {
            Header: code,
            accessor: code
            // Cell: row => this._renderCell(row, dim)
          }
        })
      : []
    // columns.push({
    //   Header: 'Value',
    //   Cell: row => (
    //     <div>
    //       {row.original && row.original.value && row.original.value.value ? (
    //         row.original.value.value
    //       ) : (
    //         ''
    //       )}
    //     </div>
    //   )
    // })
    console.log('rowsData', rowsData)
    console.log('columns', columns)
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
      >
        {rowsData && rowsData.length === 0 && <div>No results found</div>}
        {rowsData && (
          <FlatTable columns={columns} data={rowsData} minRows={0} />
        )}
      </div>
    )
  }
}

// GenericAnalyticsTableWidget.propTypes = {
//   dimensions: shape().isRequired,
//   getDimValue: func.isRequired,
//   data: shape({}).isRequired,
//   dataSourceData: shape({}).isRequired,
//   widgetDefinition: shape({}).isRequired
// }

// GenericAnalyticsTableWidget.defaultProps = {}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericAnalyticsTableWidget)
