import { crmGenericCommandHoc } from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { qlqfetchAccountContactDetails } from 'crm-data/accounts'
import React from 'react'
import uuidv4 from 'uuid'
import validate from 'crm-utils/validates'

const editContactDetailItemHoc = WrappedComponent => {
  class EditContactDetailItemHOC extends React.PureComponent {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const EditContactDetailItemHOCWithCommand = crmGenericCommandHoc(EditContactDetailItemHOC)

  const WithQuery = props => <EditContactDetailItemHOCWithCommand {...props} />
  return WithQuery
}

export const saveHandler = async (value, props, isManagerMode = false) => {
  if (!value || !props || !props.value) {
    return
  }
  if (value.kind === 'email') {
    const error_email = validate.email(value.value)
    if (error_email) return
  }

  const accountUid = value && value.account_uid ? value.account_uid : value.newContactDetails.account_uid

  const command = {
    type: props.value.contact_details_uid ? 'contact_details.update' : 'contact_details.create',
    aggregate_uid: props.value.contact_details_uid ? value.contact_details_uid : uuidv4(),
    aggregate_type: 'contact_details',
    payload: {
      value: value.value,
      kind: value.kind,
      country_code: value.country_code,
      account_uid: accountUid,
      account_person_uid: value.account_person_uid
    }
  }
  if (props.value.contact_details_uid) {
    command.payload.contact_details_uid = value.contact_details_uid
  }

  try {
    const refetchQueries = [
      {
        query: qlqfetchAccountContactDetails,
        variables: {
          account_uid: accountUid
        }
      }
    ]
    await props.onRunCommand(command, {
      refetchQueries
    })
  } catch (err) {
    console.log('Error running command')
    throw err
  }
}

export const deleteHandler = async (value, props, isManagerMode = false) => {
  if (!value || !props || !props.value) {
    return
  }

  const command = {
    type: 'contact_details.delete',
    aggregate_uid: value.contact_details_uid,
    aggregate_type: 'contact_details',
    payload: {
      contact_details_uid: value.contact_details_uid
    }
  }

  try {
    await props.onRunCommand(command)
  } catch (err) {
    console.log('Delete person error')
    console.log(err)
    throw err
  }
}

export default editContactDetailItemHoc
