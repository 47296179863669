import React from 'react'

interface OrganizationUnitProps {
  organizationUnitName: string
}

export const OrganizationUnitWidget = ({ organizationUnitName }) => (
  <div className="CrmMdCard CrmMdCard--paddingMd util-flexGrow">
    <div className="util-paddingMd">
      <div className="util-textCenter">
        <i className='fa fa-users util-textXL' />
      </div>
      <div className="util-textCenter">
        <h4>{organizationUnitName}</h4>
      </div>
    </div>
  </div>
)
