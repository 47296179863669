import React, { useState } from 'react'
import { useComponentConfigurationContext } from '../configuration-tools/component-configuration-context'
import { CrmDropdown, CrmButton } from '@cartrack-crm/ui'
import { getTypeFields } from '../configuration-tools/component-configuration-utils'

export const DataSourceFields = (props) => {
  const [isAdding, setIsAdding] = useState(false)
  const [selectedField, setSelectedField] = useState()
  const widgetConfigurationContext = useComponentConfigurationContext()
  const schema = widgetConfigurationContext.schema

  const dataSource = props.dataSource
  console.log('DataSourceFields', dataSource)
  console.log('DataSourceFields, schema', schema)

  const handleSaveNewField = () => {
    console.log('handleSaveNewField', selectedField)
    if (selectedField) {
      const newField = {
        name: selectedField
      }
      // Todo - that may be more abstract method -addFIeldToDataSource
      widgetConfigurationContext.addFieldToDataSource(dataSource.id, newField)
      setIsAdding(false)
    }
  }
  const typeFields = dataSource.data_type
    ? getTypeFields(schema, dataSource.data_type)
    : []
  console.log('typeFields', typeFields)
  const newFieldOptions = typeFields.map((field) => ({
    name: field.description || field.name,
    value: field.name
  }))

  const isGrouped = dataSource.grouped
  return (
    <div>
      <strong>Grouped: {isGrouped ? 'YES' : 'NO'}</strong>
      <strong>Fields</strong>
      {dataSource.fields ? (
        dataSource.fields.map((field) => {
          return (
            <div
              style={{
                backgroundColor: '#f0f0f0',
                padding: 5,
                cursor: 'pointer'
              }}
            >
              * <strong>{field.name}</strong>
              {isGrouped && (
                <span>
                  {field.aggregate_type ? field.aggregate_type : 'No Aggr Type'}
                </span>
              )}
              {/* TODO TON - make this draggable from here */}
            </div>
          )
        })
      ) : (
        <div>no fields</div>
      )}
      {!isAdding && (
        <CrmButton
          small
          type="primary"
          onClick={() => {
            setIsAdding(true)
          }}
          label="+Add Field"
        ></CrmButton>
      )}
      {isAdding && (
        <div>
          New Field:
          <CrmDropdown
            options={newFieldOptions}
            input={{
              value: selectedField,
              onChange: setSelectedField
            }}
          />
          <CrmButton
            label="Cancel"
            onClick={() => {
              setIsAdding(false)
            }}
            small
          />
          <CrmButton
            label="Save"
            onClick={handleSaveNewField}
            small
            type="primary"
          />
        </div>
      )}
    </div>
  )
}
