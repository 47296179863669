const ManagerSubTabs = crm_context => {
  const use_cams_integration = crm_context.getAttributeValueByCode('use_cams_integration')
  const sub_tabs = [
    {
      path: '/crm/manager/dashboards',
      text: 'Dashboards',
      long: true
    },
    {
      path: '/crm/manager/reports',
      text: 'Customized',
      long: true
    },
    {
      path: '/crm/manager/fitments',
      text: 'Fitments'
    },
    {
      path: '/crm/admin/campaign-cost',
      text: 'Campaign cost'
    },
    {
      path: '/crm/manager/tasks-manager',
      text: 'Tasks Manager'
    }
  ]

  if (use_cams_integration) {
    sub_tabs.push({
      path: '/crm/cams-integration',
      text: 'CAMS'
    })
  }

  sub_tabs.push(
    {
      path: '/crm/manager/forecasts',
      text: 'Forecasts',
      long: true
    },
    {
      path: '/crm/manager/activity-audit',
      text: 'Activity Audit'
    },
    {
      path: '/crm/manager/check',
      text: 'Check'
    },
    {
      path: '/crm/manager/schedule',
      text: 'Schedule reports'
    }
  )

  return sub_tabs
}

const AdminSubTabs = crm_context => {
  const use_cams_integration = crm_context.getAttributeValueByCode('use_cams_integration')
  const sub_tabs = [
    {
      path: '/crm/admin/advanced',
      text: 'Advanced'
    },
    {
      path: '/crm/admin/leadsources',
      text: 'Lead sources'
    },

    {
      path: '/crm/admin/reports',
      text: 'Reports'
    },
    {
      path: '/crm/admin/resources',
      text: 'Resources'
    }
  ]

  if (use_cams_integration) {
    sub_tabs.push({
      path: '/crm/admin/import-tool',
      text: 'Import tool'
    })
  }

  sub_tabs.push(
    {
      path: '/crm/admin/sales-scripts',
      text: 'Sales script'
    },
    {
      path: '/crm/admin/attribute',
      text: 'Attributes'
    },
    {
      path: '/crm/admin/authentication',
      text: 'Auth History'
    },
    {
      path: '/crm/admin/components',
      text: 'Widgets'
    },
    {
      path: '/crm/admin/system_recipient',
      text: 'System recipient email'
    }
  )

  return sub_tabs
}

const crmNavbarAllItems = crm_context => {
  return [
    {
      text: 'Home',
      icon: 'home',
      path: '/crm/dashboard/me',
      subTabs: [
        {
          path: '/crm/dashboard/details',
          text: 'My Dashboard'
        },
        {
          path: '/crm/dashboard/work',
          text: 'Work'
        },
        // {
        //   path: '/crm/dashboard/rm',
        //   text: 'Dashboard RM'
        // },
        // {
        //   path: '/crm/dashboard/agent',
        //   text: 'Dashboard Agent'
        // },
        {
          path: '/crm/dashboard/roi',
          text: 'ROI'
        }
      ],
      mobile: true
    },

    {
      text: 'Leads',
      icon: 'vcard-o',
      crmPermissions: [
        {
          permissionCode: 'crm.accountData',
          module: ['*', 'sales'],
          accessMode: 'read'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ],
      path: '/crm/accounts/lead'
    },
    {
      text: 'Prospects',
      icon: 'handshake-o',
      crmPermissions: [
        {
          permissionCode: 'crm.accountData',
          module: ['*', 'sales'],
          accessMode: 'read'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ],
      path: '/crm/prospects'
    },
    {
      text: 'Opportunities',
      icon: 'dollar',
      subTabs: [
        {
          path: '/crm/opportunities/list',
          text: 'Opportunities'
        },
        {
          path: '/crm/opportunities/quotation',
          text: 'Approve Quotes',
          crmPermissions: [
            {
              permissionCode: 'crm.quotation',
              module: ['*'],
              accessMode: 'manage'
            }
          ]
        },
        // {
        //   path: '/crm/opportunities/contracts',
        //   text: 'Contracts'
        // },
        {
          path: '/crm/opportunities/forecast',
          text: 'Forecasts'
        }
      ],
      mobile: true,
      crmPermissions: [
        {
          permissionCode: 'crm.accountData',
          module: ['*', 'sales'],
          accessMode: 'read'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ]
    },
    {
      text: 'Customers',
      icon: 'trophy',
      crmPermissions: [
        {
          permissionCode: 'crm.accountData',
          module: ['*', 'sales'],
          accessMode: 'read'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ],
      path: '/crm/accounts/customers'
    },

    {
      text: 'Tasks',
      icon: 'check-square-o',
      crmPermissions: [
        {
          permissionCode: 'crm.tasks',
          module: ['*'],
          accessMode: 'read'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ],
      subTabs: [
        {
          path: '/crm/tasks/my-tasks',
          text: 'My Tasks'
        },
        {
          path: '/crm/tasks/my-list',
          text: 'My Tasks List'
        },
        {
          path: '/crm/tasks/my-group',
          text: 'Group Tasks'
        }
      ]
    },
    {
      text: 'Database',
      icon: 'list',
      crmPermissions: [
        {
          permissionCode: 'crm.accountData',
          module: ['*', 'sales'],
          accessMode: 'read'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ],
      subTabs: [
        {
          path: '/crm/accounts/all',
          text: 'All Accounts'
        }
      ],
      mobile: true
    },
    {
      text: 'Manager',
      icon: 'legal',
      crmPermissions: [
        {
          permissionCode: 'crm.generalData',
          module: '*',
          accessMode: 'manage'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ],
      subTabs: ManagerSubTabs(crm_context),
      mobile: true
    },
    {
      text: 'Calendar',
      icon: 'calendar',
      subTabs: [
        {
          path: '/crm/calendar',
          text: 'My Calendar'
        },
        {
          path: '/crm/group_calendar',
          text: 'Group Calendar'
        }
      ],
      mobile: true,
      crmPermissions: [
        {
          permissionCode: 'crm.accountData',
          module: ['*', 'sales'],
          accessMode: 'read'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ]
    },
    {
      text: 'Settings',
      icon: 'flash',
      crmPermissions: [
        {
          permissionCode: 'crm.admin',
          module: '*',
          accessMode: 'read'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ],
      hide: true,
      subTabs: [
        {
          path: '/crm/settings/instances',
          text: 'Territories'
        },
        {
          path: '/crm/settings/sales_channels',
          text: 'Sales Channels'
        },
        {
          path: '/crm/settings/products',
          text: 'Products'
        },
        {
          path: '/crm/settings/profiles',
          text: 'Profiles'
        },
        {
          path: '/crm/settings/users',
          text: 'Users'
        },
        {
          path: '/crm/settings/user-groups',
          text: 'User Groups'
        },
        {
          path: '/crm/settings/dictionaries',
          text: 'Configuration'
        },
        {
          path: '/crm/settings/templates',
          text: 'Templates'
        },
        {
          path: '/crm/settings/lead-campaigns',
          text: 'Lead Campaigns'
        },
        {
          path: '/crm/settings/campaigns',
          text: 'Sales Campaigns'
        },
        {
          path: '/crm/settings/translations',
          text: 'Translations'
        },
        {
          path: '/crm/settings/price-table',
          text: 'Price table'
        },
        {
          path: '/crm/settings/task-setting',
          text: 'Next task sequence',
          style: { width: '200px' }
        },
        {
          path: '/crm/settings/opportunity_types',
          text: 'Opportunity types',
          style: { width: '200px' }
        }
      ]
    },
    {
      text: 'Admin',
      icon: 'music',
      crmPermissions: [
        {
          permissionCode: 'crm.admin',
          module: '*',
          accessMode: 'manage'
        },
        {
          permissionCode: 'crm.menu',
          module: ['*'],
          accessMode: 'read'
        }
      ],
      hide: true,
      subTabs: AdminSubTabs(crm_context)
    },
    {
      text: '',
      icon: '',
      meta: true,
      subTabs: [
        {
          path: '/crm/profile-settings',
          text: 'Profile Settings'
        }
      ]
    }
  ]
}
export default crmNavbarAllItems
