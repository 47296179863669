import React from 'react'
import { toastr } from 'react-redux-toastr'
import { reduxForm } from 'redux-form'
import { CrmButton } from 'crm-components'
import AddressesItem from 'crm-modules/accounts/account/addresses/address-item.jsx'
import accountAddressCrudHoc from 'crm-modules/accounts/hoc/account-address-crud-hoc'
import cloneDeep from 'clone-deep'

class CrmAddressInner extends React.PureComponent {
  render() {
    const { handleSubmit } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <AddressesItem
          member={'address'}
          isEditing={true}
          enableDeleteButton={false}
        />
        <div className="CrmAddressSelect-Buttons">
          <CrmButton xsmall label="Save" type="submit" />
          <CrmButton xsmall label="Cancel" onClick={this.props.onCancel} />
        </div>
      </form>
    )
  }
}

const CrmAddressInnerForm = reduxForm({
  form: 'CrmActivityAddressSelectForm'
})(CrmAddressInner)

class CrmAddressSelectForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleSubmit = async data => {
    // console.log('handleSubmit', data, this.props)
    if (data && data.address) {
      try {
        const address = cloneDeep(data.address) // we do data.address as address is rendered in nested form element
        address.account_uid = this.props.accountUid
        const ret = await this.props.onSaveAddress(address)
        toastr.info('Address was saved')
        this.props.onSaved()
      } catch (err) {
        toastr.error('Problem saving address: ' + err)
      }
    }
  }
  render() {
    return <CrmAddressInnerForm onSubmit={this.handleSubmit} />
  }
}

export default accountAddressCrudHoc(CrmAddressSelectForm)
