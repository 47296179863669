import React, { PureComponent } from 'react'
import { object, bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { propTypes, change, formValueSelector } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import EmailForm from './email-form.jsx'
import EmailLabel from './email-label.jsx'
import AttachmentLabel from './attachment-label.jsx'
import EmailRecipientsFields from './email-recipients-fields.jsx'
import {
  CrmValidatedField,
  CrmTextArea,
  CrmTextInput,
  CrmQuotationSelectorQL,
  CrmButton,
  CrmResourcesSelect
} from 'crm-components'
import {
  addEmail,
  setRecipientEmails,
  getRecipientEmails,
  getAttachmentFiles,
  setAttachmentFiles
} from 'crm-duxs/account-reducer'
import { getUserInfo } from 'crm-duxs/crm-reducer'
import cloneDeep from 'clone-deep'

class EmailFields extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShowAddForm: false,
      expandQuotationAttachments: false,
      expandOtherAttachments: false,
      sendEmailFromQuotation: cloneDeep(props.sendEmailFromQuotation)
    }
  }

  componentDidMount() {
    this.setDefaultEmailMessage()
  }

  setDefaultEmailMessage = () => {
    if (!this.state.sendEmailFromQuotation) return

    const subject = this.props.intl.formatMessage({
      id: 'crm.ui.quotation.email.cartrack_quotation',
      defaultMessage: 'Cartrack Quotation'
    })
    const message = this.props.intl.formatMessage({
      id: 'crm.ui.quotation.email.please_find_attached_quotation',
      defaultMessage: 'Please find attached quotation'
    })

    this.props.doUpdateSubject(this.props.form, subject)
    this.props.doUpdateMessage(this.props.form, message)
  }

  componentWillMount() {
    this.props.doSetRecipientEmails({})
    this.props.doUpdateEmail(this.props.form, {})
    this.props.doSetAttachmentFiles({})
    this.props.doUpdateAttachmentFile(this.props.form, {})
  }

  handleCreateEmailSubmit = (accountUid, email) => {
    this.props.doAddEmail(accountUid, email)
  }

  handleSelectEmail = email => {
    const toEmails = Object.assign({}, this.props.emails, {
      [email.contact_details_uid]: email
    })

    this.props.doSetRecipientEmails(toEmails)
    this.props.doUpdateEmail(this.props.form, toEmails)
  }

  handleShowAddFrom = () => {
    this.setState({ ...this.state, isShowAddForm: true })
  }

  handleCloseForm = () => {
    this.setState({ ...this.state, isShowAddForm: false })
  }

  onRemoveEmail = data => {
    const toEmails = Object.assign({}, this.props.emails)
    delete toEmails[data.contact_details_uid]

    this.props.doSetRecipientEmails(toEmails)
    this.props.doUpdateEmail(this.props.form, toEmails)
  }

  handleSelectResource = document => {
    const attachmentFiles = []

    Object.entries(this.props.attachmentFiles).forEach(entry => {
      const [key, data] = entry
      attachmentFiles.push(data)
    })

    attachmentFiles.push({
      source_type: 'resource',
      resource_uid: document.resource.resource_uid,
      name: document.resource.name
    })

    this.props.doSetAttachmentFiles(attachmentFiles)
    this.props.doUpdateAttachmentFile(this.props.form, attachmentFiles)
  }

  handleSetDefaultAttachFile = document => {
    const attachmentFiles = []

    attachmentFiles.push({
      source_type: 'resource',
      resource_uid: document.resource.resource_uid,
      name: document.resource.name
    })

    this.props.doSetAttachmentFiles(attachmentFiles)
    this.props.doUpdateAttachmentFile(this.props.form, attachmentFiles)
  }

  handleCloseAttachmentSection = () => {
    this.setState({
      expandQuotationAttachments: false,
      expandOtherAttachments: false
    })
  }

  onRemoveAttachmentFile = data => {
    const attachmentFiles = this.props.attachmentFiles
      ? [...this.props.attachmentFiles]
      : []
    const newAttachment =
      attachmentFiles.length > 0 &&
      attachmentFiles.reduce((ret, v) => {
        if (v.resource_uid !== data.resource_uid) ret.push(v)
        return ret
      }, [])

    this.props.doSetAttachmentFiles(newAttachment)
    this.props.doUpdateAttachmentFile(this.props.form, newAttachment)
  }

  renderPreviewSignature = () => {
    if (!this.props.isEditing) return null

    const { userInfo } = this.props

    return (
      <div>
        <div>
          <div style={{ fontSize: 14 }}>
            (Your signature will be automatically added at the end of this
            email)
          </div>
        </div>
        <div>
          <b>{userInfo.full_name}</b>
        </div>
        <div>
          <b>{userInfo.position}</b>
        </div>
        {userInfo.mobile_phone && <div>Mobile: {userInfo.mobile_phone}</div>}
        {userInfo.local_phone && <div>Tel: {userInfo.local_phone}</div>}
        <div>Email: {userInfo.email}</div>
      </div>
    )
  }

  render() {
    const emails =
      this.props.account && this.props.account.contact_details
        ? this.props.account.contact_details.filter(
            contact => contact.kind === 'email'
          )
        : []
    const { isEditing } = this.props
    const { sendEmailFromQuotation } = this.state

    return (
      <div>
        {sendEmailFromQuotation && (
          <div className="util-textCenter util-marginBottom">
            <FormattedMessage
              id="crm.ui.button.send_quotation"
              defaultMessage="Send Quotation"
            />
          </div>
        )}
        <EmailRecipientsFields
          form={this.props.form}
          isEditing={isEditing}
          emails={emails}
          account={this.props.account}
          activity={this.props.activity}
          sendEmailFromQuotation={sendEmailFromQuotation}
        />
        <fieldset disabled={!isEditing} className="CrmForm-form-fieldset">
          <div className="row">
            <div className="col-md-8">
              <EmailLabel handleRemoveEmail={this.onRemoveEmail} />
              <div className="util-hide">
                <CrmValidatedField
                  component={CrmTextInput}
                  componentProps={{
                    placeholder: 'Email',
                    placeholderId: 'crm.ui.activity.email'
                  }}
                  name="to_emails"
                />
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.isShowAddForm && (
              <EmailForm
                account={this.props.account}
                onClose={this.handleCloseForm}
                onCreateEmail={this.handleCreateEmailSubmit}
              />
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <CrmValidatedField
                isRequired={true}
                component={CrmTextInput}
                componentProps={{
                  placeholder: 'Subject',
                  placeholderId: 'crm.ui.activity.subject'
                }}
                name="subject"
              />
            </div>

            <div className="col-md-12" style={{ marginTop: '20px' }}>
              <CrmValidatedField
                component={CrmTextArea}
                componentProps={{
                  placeholder: 'Message',
                  placeholderId: 'crm.ui.activity.message',
                  height: 50
                }}
                name="note_text"
              />
            </div>
          </div>
          {this.renderPreviewSignature()}
          <div
            className="row"
            style={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 20
            }}
          >
            <div>
              <b>Attachments</b>
            </div>
            {isEditing && (
              <div>
                <CrmButton
                  type="secondary"
                  label="+ Quotation"
                  onClick={() =>
                    this.setState({ expandQuotationAttachments: true })
                  }
                />
                <CrmButton
                  type="secondary"
                  label="+ Other"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      expandOtherAttachments: true
                    })
                  }
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-13">
              <AttachmentLabel
                handleRemoveAttachmentFile={this.onRemoveAttachmentFile}
              />
            </div>
          </div>
          <div>
            <CrmQuotationSelectorQL
              activity={this.props.activity}
              accountUid={this.props.account.account_uid}
              onChange={this.handleSelectResource}
              isExpanded={this.state.expandQuotationAttachments}
              attachmentFiles={this.props.attachmentFiles}
              onCloseSection={this.handleCloseAttachmentSection}
              sendEmailFromQuotation={sendEmailFromQuotation}
              handleSetDefaultAttachFile={this.handleSetDefaultAttachFile}
            />
            <CrmResourcesSelect
              accountUid={this.props.account.account_uid}
              isExpanded={this.state.expandOtherAttachments}
              onChange={(value, title, item) =>
                this.handleSelectResource({ resource: { ...item } })
              }
              onClose={this.handleCloseAttachmentSection}
            />
          </div>
        </fieldset>
      </div>
    )
  }
}

EmailFields.propTypes = {
  ...propTypes,
  activity: object.isRequired,
  isEditing: bool.isRequired
}

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(ownProps.form)
  return {
    emails: getRecipientEmails(state),
    toEmails: selector(state, 'to_emails'),
    attachmentFiles: getAttachmentFiles(state),
    userInfo: getUserInfo(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doUpdateEmail: (form, value) => dispatch(change(form, 'to_emails', value)),
    doUpdateSubject: (form, value) => dispatch(change(form, 'subject', value)),
    doUpdateMessage: (form, value) =>
      dispatch(change(form, 'note_text', value)),
    doUpdateAttachmentFile: (form, value) =>
      dispatch(change(form, 'attachment_files', value)),
    doAddEmail: (accountUid, email) => dispatch(addEmail(accountUid, email)),
    doSetRecipientEmails: toEmails => dispatch(setRecipientEmails(toEmails)),
    doSetAttachmentFiles: attachmentFiles =>
      dispatch(setAttachmentFiles(attachmentFiles))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EmailFields))
