import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { Field as FieldFormik, FieldArray } from 'formik'
import { CrmTextInput, CrmButton } from '../../../../../components/index'
import Spacer from '../../../../../../util-components/spacer.jsx'
import { initNewPersonContactDetail } from '../../../../../utils/accounts-utils'
import ContactDetailItem from './person-contact-detail-item'

interface IRenderMembersProps {
  contactDetails: any
  personIndex: Number
  arrayHelpers: any
  handleRemovePerson: Function
  onHandleCheckByUid: Function
  duplicate: any
  intl: any
}

const RenderMembers: React.SFC<IRenderMembersProps> = ({
  contactDetails,
  personIndex,
  arrayHelpers,
  handleRemovePerson,
  onHandleCheckByUid,
  duplicate,
  intl
}) => {
  const getMessagesByKind = (field) => {
    if (field === 'phone') {
      return { id: 'crm.ui.contact.form.add_phone', label: 'Add Phone' }
    } else if (field === 'email') {
      return { id: 'crm.ui.contact.form.add_email', label: 'Add Email' }
    } else if (field === 'mobile') {
      return { id: 'crm.ui.contact.form.add_mobile', label: 'Add Mobile' }
    }
  }

  const handleAddContactDetails = (event) => {
    const newContactDetail = initNewPersonContactDetail(event.currentTarget.id)
    arrayHelpers.push({ ...newContactDetail, isAdditionalItem: true })
  }

  return (
    <React.Fragment>
      <div className="row">
        {contactDetails &&
          contactDetails.map &&
          contactDetails.map((item, index) => {
            const f = contactDetails[index]
            const kind = f.kind
              ? f.kind.charAt(0).toUpperCase() + f.kind.slice(1)
              : 'Value'
            const messages = getMessagesByKind(f.kind)
            const isLastElement = contactDetails.length === index + 1
            const emptyColumnCount = contactDetails.length % 3
            return (
              <React.Fragment key={index}>
                <div
                  className={`${
                    isLastElement && emptyColumnCount === 0
                      ? 'col-md-3'
                      : 'col-md-4'
                  }
                    ${index >= 6 ? ' util-marginTop' : ''}
                  `}
                >
                  <ContactDetailItem
                    item={item}
                    personIndex={personIndex}
                    onHandleCheckByUid={onHandleCheckByUid}
                    duplicate={duplicate}
                    index={index}
                    handleAddContactDetails={handleAddContactDetails}
                    messages={messages}
                    kind={kind}
                    id={f.kind}
                  />
                </div>
                {isLastElement && (
                  <div
                    className={`util-flexRowRight ${
                      emptyColumnCount === 0
                        ? 'col-md-1'
                        : emptyColumnCount === 2
                        ? 'col-md-4'
                        : 'col-md-8'
                    }
                      ${index >= 6 ? ' util-marginTop' : ''}
                    `}
                  >
                    <CrmButton
                      type="secondary"
                      className="util-textApply"
                      onClick={() => handleRemovePerson(personIndex)}
                      icon={'trash'}
                      iconButton
                    />
                  </div>
                )}
              </React.Fragment>
            )
          })}
      </div>

      <Spacer height="10px" />
    </React.Fragment>
  )
}

interface IProps {
  handleRemovePerson: Function
  person: any
  index: Number
  intl: any
  onHandleCheckByUid: Function
  duplicate: any
}

const PersonItem: React.SFC<IProps> = (props) => {
  console.log('PersonItem', props)
  const { person, index } = props

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4">
          <FieldFormik
            render={({ field, form }) => (
              <CrmTextInput
                value={person.name}
                placeholder={props.intl.formatMessage({
                  id: 'crm.ui.contact.form.name',
                  defaultMessage: 'Name'
                })}
                onChange={(e) => {
                  form.setFieldValue(
                    `persons.${index}.name`,
                    e.currentTarget.value
                  )
                }}
              />
            )}
          />
        </div>
        <div className="col-md-4">
          <FieldFormik
            render={({ field, form }) => (
              <CrmTextInput
                value={person.description}
                placeholder={props.intl.formatMessage({
                  id: 'crm.ui.contact.form.title',
                  defaultMessage: 'Title'
                })}
                onChange={(e) => {
                  form.setFieldValue(
                    `persons.${index}.description`,
                    e.currentTarget.value
                  )
                }}
              />
            )}
          />
        </div>
        <div className="col-md-4">
          <FieldFormik
            render={({ field, form }) => (
              <CrmTextInput
                value={person.department}
                placeholder={props.intl.formatMessage({
                  id: 'crm.ui.contact.form.department',
                  defaultMessage: 'Department'
                })}
                onChange={(e) => {
                  form.setFieldValue(
                    `persons.${index}.department`,
                    e.currentTarget.value
                  )
                }}
              />
            )}
          />
        </div>
      </div>

      <FieldArray
        name={`persons.${index}.contact_details`}
        render={(arrayHelpers) => {
          return (
            <RenderMembers
              contactDetails={person.contact_details}
              personIndex={index}
              arrayHelpers={arrayHelpers}
              handleRemovePerson={props.handleRemovePerson}
              onHandleCheckByUid={props.onHandleCheckByUid}
              duplicate={props.duplicate}
              intl={props.intl}
            />
          )
        }}
      />
    </React.Fragment>
  )
}

export default injectIntl(PersonItem)
