import React from 'react'
import { connect } from 'react-redux'
import { arrayOf, shape, func, string } from 'prop-types'
import QuotationToApproveListInner from './quotation-to-approve-list-inner.jsx'
import { CrmFullpageContainer } from 'crm-components'
import { getIsShowMoreFilter } from 'crm-duxs/accounts-reducer'
import { getQFilterGroup, getSelectedView } from 'crm-duxs/filters-reducer'
import { getOwnerUserUid } from 'crm-duxs/opportunities-reducer'

const QuotationToApproveView = props => (
  <CrmFullpageContainer style={{ display: 'flex', flexDirection: 'column' }}>
    <div className="AccountsListContainer-tableWrapper">
      <QuotationToApproveListInner />
    </div>
  </CrmFullpageContainer>
)

QuotationToApproveView.defaultProps = {
  accountsListCode: 'opportunities',
  ownerUserUid: undefined
}

QuotationToApproveView.propTypes = {
  doSetOwnerUserUid: func,
  accountsListCode: string.isRequired,
  doFetchAccountsListCount: func,
  qfilter: shape().isRequired,
  selectedView: shape({}),
  doSetFilterGroupView: func,
  views: arrayOf(shape())
}

function mapStateToProps(state) {
  return {
    isShowMoreFilter: getIsShowMoreFilter(state, 'opportunities'),
    ownerUserUid: getOwnerUserUid(state),
    qfilter: getQFilterGroup(state, 'opportunities'),
    selectedView: getSelectedView(state, 'opportunities')
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationToApproveView)
