/* eslint-disable camelcase */
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { reducer as form } from 'redux-form'
import user from './user'
import crm from './crm-reducer'
import accounts from './accounts-reducer'
import account from './account-reducer'
import department from './settings/department-reducer'
import instance from './settings/instance-reducer'
import campaign from './settings/campaign-reducer'
import campaignCost from './settings/campaign-cost-reducer'
import leadSource from './settings/lead-source-reducer'
import saleScript from './settings/sales-script-reducer'
import managementDashboard from './management/management-dashboard-reducer'
import { activitiesReducer as activities } from './activities-reducer'
import activity from './activity-reducer'
import liveCall from './activities/live-call-reducer'
import territories from './settings/territory-reducer'
import data from './data-reducer'
import products from './settings/products-reducer'
import permissions from './settings/permissions-reducer'
import profilePermissions from './settings/profile-permissions-reducer'
import me from './settings/me-reducer'
import dialer from './dialer-reducer'
import dictionaries from './settings/dictionaries-reducer'
import settingReports from './settings/reports-reducer'
import settingsTemplate from './settings/templates-reducer'
import adminResources from './admin/resources-reducer'
import adminImportTool from './admin/import-tool-reducer'
import { calendarsReducer as calendars } from './calendars-reducer'
import opportunities from './opportunities-reducer'
import opportunity from './opportunity-reducer'
import document from './document-reducer'
import tasks from './tasks-reducer'
import meetingMinutes from './meeting-minutes-reducer'
import phone from './phone/phone-reducer'
import report from './report-reducer'
import { accountsListReducerFactory } from './accounts-list-reducer'
import analytics from './analytics/analytics-reducer'
import messages from './messages-reducer'
import accountsLeadsReducer from './accounts/accounts-leads-reducer'
import accountsProspectsReducer from './accounts/accounts-prospects-reducer'
import accountsCustomersReducer from './accounts/accounts-customers-reducer'
import accountsOpportunitiesReducer from './accounts/accounts-opportunities-reducer'
import filters from './filters-reducer'
import attributeReducer from './admin/attributes-reducer'
import userSession from './user-session'
import errorReporting from './error-reporting'
import bulkOperations from './bulk-operations-reducer'
import drivers from './drivers'
import vehicles from './vehicles'
import map from './map'
import landmarks from './landmarks'
import geofences from './geofences'
import locale from './locale'
import toast from './toast'
import loadingBar from './loading-bar'

const crmRootReducer = combineReducers({
  account,
  accounts,
  accountsList_leads: accountsLeadsReducer, // eslint-disable-line
  accountsList_prospects: accountsProspectsReducer, // eslint-disable-line
  accountsList_customers: accountsCustomersReducer, // eslint-disable-line
  accountsList_accounts: accountsListReducerFactory('accounts', {
    onlyOwnAccounts: false
  }),
  accountsList_opportunities: accountsOpportunitiesReducer,
  activity,
  analytics,
  bulkOperations,
  campaign,
  activities,
  calendars,
  dialer,
  crm,
  filters,
  leadSource,
  department,
  data,
  document,
  instance,
  liveCall,
  managementDashboard,
  messages,
  opportunity,
  opportunities,
  report,
  campaignCost,
  settingReports,
  settingsTemplate,
  adminResources,
  permissions,
  phone,
  profilePermissions,
  products,
  dictionaries,
  tasks,
  territories,
  me,
  adminImportTool,
  meetingMinutes,
  userSession,
  errorReporting,
  saleScript,
  attribute: attributeReducer
})

const rootReducer = history =>
  combineReducers({
    crm: crmRootReducer,
    form,
    drivers,
    vehicles,
    map,
    landmarks,
    geofences,
    locale,
    toast,
    loadingBar,
    router: connectRouter(history),
    user,
    toastr: toastrReducer
  })

export default rootReducer
