import { CrmDropdown } from 'crm-components'
import gql from 'graphql-tag'
import React, { PureComponent } from 'react'
import { Query } from 'react-apollo'
import { shape, func, string } from 'prop-types'

const qlqListCamsBusinessSources = gql`
  {
    listCamsBusinessSources {
      userId
      firstName
      surname
    }
  }
`

const CrmCamsBusinessSourceSelect = props => {
  console.log('render CrmCamsBusinessSourceSelect', props)
  return (
    <Query query={qlqListCamsBusinessSources}>
      {({ loading, error, data }) => {
        const options =
          data && data.listCamsBusinessSources
            ? data.listCamsBusinessSources.map(v => ({
                name: v.firstName + ' ' + v.surname,
                value: v.userId
              }))
            : []
        options.sort((a, b) => {
          const va = a && a.name ? a.name : ''
          const vb = b && b.name ? b.name : ''
          return va.localeCompare(vb)
        })
        return (
          <CrmDropdown
            {...props}
            options={options}
            placeholder={props.placeholder}
            addEmptyOption
          />
        )
      }}
    </Query>
  )
}

CrmCamsBusinessSourceSelect.propTypes = {
  placeholder: string.isRequired
}

export default CrmCamsBusinessSourceSelect
