import React from 'react'
import '@cartrack-crm/ui/style/crm-forecast-page/forecast-page.scss'
import { useFetchForecast, useGetGraphDataMonthly } from '../useFetchForecastManager'
import { ForecastListInner } from './forecast-list-inner'
import { ForecastProgressChart } from '../forecast-progresschart'
import ForecastBody from '../forecast-body'
import moment from 'moment'
import { AdminHeader } from 'util-components'

export const ForecastManagerView = (props) => {
  const forecastUid = props.match.params.forecastUid || undefined
  const { data, loading, error, refetch } = useFetchForecast(forecastUid)
  const hasForecast = data && data.forecast
  const forecastData = hasForecast ? data.forecast : undefined
  const forecastOwnerUsername = hasForecast ? data.forecast.user.full_name : ''
  const userUid = hasForecast ? data.forecast.user_uid : ''
  const refetchParams = {
    forecastUid: forecastUid
  }

  const breadcrumbPaths = [
    { label: 'Back to Forecasts', path: '/crm/manager/forecasts' },
    { label: 'Manage Forecast' }
  ]
  let month = moment(new Date())
  if (hasForecast) {
    month = moment(data.forecast.month_date, 'YYYY-MM')
  }

  return (
    <div className='CrmFullpageContainer CrmFullpageContainer--scrollInner'>
      <div className='col-xs-10 col-xs-offset-1 util-flexGrow util-fullHeight util-flexColumn'>
        <AdminHeader breadcrumbs={breadcrumbPaths} children={''} />
        <div className='CrmForecast-ViewWrapper util-flexGrow util-fullHeight' style={{ display: 'flex', height: 'calc(100% - 48px)' }} >
          <section style={{ width: '40%' }} className='util-flexColumn'>
            <ForecastProgressChart widgetClassName='util-marginBottom' month={month.format('YYYY-MM')} userUid={userUid} />
            <ForecastListInner isFromViewPageList />
          </section>
          <section style={{ width: '60%' }} className='util-flexColumn util-fullHeight'>
            <div className='util-fullHeight util-flexGrow util-flexColumn util-relative'>
              <div className='util-flexRowCenter' style={{ alignItems: 'center' }}>
                <h2 className='CrmForecast-Title'>Forecast</h2>
                <TextFormWithVale title='Forecast Month' value={month.format('MMMM YYYY')} margin={20} />
                <TextFormWithVale title='Owner' value={forecastOwnerUsername} icon='user' margin={20} />
              </div>
              <ForecastBody month={month.format('YYYY-MM')} forecastData={forecastData} refetch={refetch} loading={loading} refetchParams={refetchParams} isFromManagePage />
            </div>
          </section>
        </div>
      </div>
    </div>)
}
interface TextFormWithValeProps {
  title: string,
  value: string | number,
  margin?: number
  icon?: any,
  valueClassName?: string,
  onClick?: () => any
}
export const TextFormWithVale: React.FunctionComponent<TextFormWithValeProps> = (props) => {
  return (
    <div className="LabeledField" style={{ margin: props.margin || 10 }} onClick={props.onClick}>
      <div className="LabeledField-label">
        {props.title}
      </div>
      <div className="LabeledField-value">
        <div className={`${props.valueClassName ? props.valueClassName : ''}`}>
          {
            props.icon &&
            <i className={`fa fa-${props.icon}`}
              style={{ marginRight: 5 }}
            />
          }
          {props.value}
        </div>
      </div>
    </div>
  )
}