import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Downshift from 'downshift'
import {
  arrayOf,
  shape,
  string,
  func,
  bool,
  number,
  oneOfType,
  element,
  any
} from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { TextInput } from '@cartrack-crm/ui'
import Icon from './icon.jsx'
import { fuzzySearch } from 'cartrack-utils'

class InputDropdown extends PureComponent {
  state = {
    inputValue: ''
  }

  static itemToString = item => (item && item.name) || ''

  componentWillMount() {
    this.setState({
      options: this.resolveOptions(this.props),
      selectedItem: this.resolveSelected(this.props.activeOption)
    })
  }

  componentWillReceiveProps(nextProps) {
    let newState
    if (nextProps.options !== this.props.options) {
      newState = {}
      newState.options = this.resolveOptions(nextProps)
    }
    if (nextProps.activeOption !== this.props.activeOption) {
      newState = newState || {}
      newState.selectedItem = this.resolveSelected(nextProps.activeOption)
    }
    if (newState) this.setState(newState)
  }

  resolveSelected(item) {
    return item === Object(item)
      ? item
      : this.props.options.find(o => o.value === item)
  }

  resolveOptions(
    {
      options,
      disableInput,
      disableFilter,
      itemSearchTransform,
      filterPredicate
    },
    inputValue
  ) {
    // Necessary to allow use within handleInputChange
    const filterStr =
      inputValue === undefined ? this.state.inputValue : inputValue

    if (
      disableFilter ||
      !filterStr ||
      disableInput ||
      this.state.forceAllOptions
    )
      return options

    let filtered = options.filter((o, i, arr) => {
      // Allow custom transform to search single/compound values
      const item = itemSearchTransform ? itemSearchTransform(o) : o
      // Default to fuzzy string search
      return filterPredicate
        ? filterPredicate(item, filterStr, i, arr)
        : fuzzySearch(item, filterStr)
    })

    if (this.props.maxItems) filtered = filtered.slice(0, this.props.maxItems)
    return filtered
  }

  // Event Handlers
  handleInputFocus = () => {
    if (this.openMenu) this.openMenu()
    this.setState({
      forceAllOptions: true
    })
  }

  handleInputChange = e => {
    if (this.props.onInputChange) this.props.onInputChange(e)
    this.setState({
      inputValue: e.currentTarget.value,
      options: this.resolveOptions(this.props, e.currentTarget.value),
      forceAllOptions: false
    })
  }

  handleDownshiftOnChange = item => {
    if (this.props.returnItem) {
      this.props.onChange(item)
    } else {
      this.props.onChange(item.value)
    }
    this.setState({
      forceAllOptions: false
    })
  }

  renderDropdown = ({
    getInputProps,
    getButtonProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    selectedItem,
    openMenu,
    inputValue
  }) => {
    const {
      extraClassNames: { containerClassNames },
      placeholder,
      icon,
      iconName,
      disableInput,
      disabled,
      disableOptionIntl,
      required
    } = this.props
    const { options } = this.state
    const isWithValue = selectedItem ? 'is-withValue' : ''
    this.openMenu = openMenu

    return (
      <div
        className={`InputDropdown ${containerClassNames} ${
          placeholder === ' ' ? 'InputDropdown--noPlaceholder' : ''
        }
          ${this.props.small ? 'InputDropdown--small' : ''}`}
      >
        <div className="InputDropdown-container">
          {disableInput ? (
            <button
              className={`InputDropdown-button ${isWithValue}`}
              {...getButtonProps()}
            >
              <div
                className={`InputDropdown-button-placeholder ${isWithValue}`}
              >
                {placeholder}
              </div>
              {inputValue}
            </button>
          ) : (
            <TextInput
              {...getInputProps({
                placeholder,
                icon,
                iconName,
                disabled,
                disableAutofill: true,
                onFocus: this.handleInputFocus,
                onChange: this.handleInputChange
              })}
            />
          )}
          {!icon && !iconName && !disabled && (
            <Icon
              name="caret-down"
              className={`InputDropdown-arrow ${isOpen ? 'is-open' : ''}`}
            />
          )}
          {required && <span className="InputDropdown-required">*</span>}
          {isOpen && options.length > 0 && (
            <div className="InputDropdown-options">
              {options.map((item, index) => (
                <div
                  key={item.key || item.name}
                  {...getItemProps({
                    item,
                    index,
                    className: `InputDropdown-options-option ${
                      highlightedIndex === index ? 'is-focused' : ''
                    } ${item === selectedItem ? 'is-selected' : ''}`
                  })}
                >
                  {item.Component ? (
                    <item.Component item={item} />
                  ) : disableOptionIntl ? (
                    item.name
                  ) : (
                    <FormattedMessage
                      id={`dropdown.option.${item.name}`}
                      defaultMessage={item.name}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    return (
      <Downshift
        selectedItem={this.state.selectedItem}
        itemToString={this.props.itemToString || InputDropdown.itemToString}
        onChange={this.handleDownshiftOnChange}
        onStateChange={this.props.onStateChange}
        defaultInputValue={this.props.defaultInputValue}
        initialSelectedItem={this.props.initialSelectedItem}
        defaultHighlightedIndex={0}
      >
        {this.renderDropdown}
      </Downshift>
    )
  }
}

InputDropdown.propTypes = {
  // Configuration
  disableFilter: bool,
  filterPredicate: func,
  itemSearchTransform: func,
  disableOptionIntl: bool,
  maxItems: number,
  disableInput: bool,
  disabled: bool,
  returnItem: bool,
  initialSelectedItem: arrayOf(shape({})),
  // Downshift
  activeOption: oneOfType([
    shape({
      name: string.isRequired,
      Component: func
    }),
    any
  ]),
  defaultInputValue: string,
  onStateChange: func,
  itemToString: func,

  // Text Input
  onInputChange: func,
  placeholder: string,
  icon: element,
  iconName: string,

  // Dropdown
  options: arrayOf(
    shape({
      name: string.isRequired,
      Component: func
    })
  ),
  onChange: func.isRequired,

  // Configuration
  extraClassNames: shape({
    containerClassName: string
  }),
  small: bool
}

InputDropdown.defaultProps = {
  disableFilter: false,
  filterPredicate: null,
  itemSearchTransform: null,
  disableOptionIntl: false,
  maxItems: null,
  disableInput: false,
  disabled: false,
  itemToString: null,
  returnItem: false,
  activeOption: undefined,
  defaultInputValue: '',
  onStateChange: undefined,
  onInputChange: null,
  placeholder: ' ',
  icon: null,
  iconName: '',
  options: [],
  extraClassNames: {
    containerClassNames: ''
  },
  small: false,
  initialSelectedItem: []
}

export default InputDropdown

function mapStateToProps(state, { options, input: { value: activeOption } }) {
  const isFunc = typeof options === 'function'
  return {
    options: isFunc ? options(state) : options,
    activeOption
  }
}

const FormInputDropdown = props => <InputDropdown {...props} {...props.input} />

FormInputDropdown.propTypes = {
  ...InputDropdown.propTypes,
  onChange: func,
  input: shape({}).isRequired
}

FormInputDropdown.defaultProps = {
  ...InputDropdown.defaultProps
}

export const ConnectedDropdown = connect(mapStateToProps)(FormInputDropdown)
