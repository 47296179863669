import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { arrayPush } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PersonItem from './person-item'
import { initNewPerson } from '../../../../../utils/accounts-utils'
import accountCheckDuplicateHoc from '../account-name-check-duplicate-hoc'

interface IProps {
  sectionName: any
  addFields: Function
  values: any
  arrayHelpers: any
  errors: any
  intl: any
  onDuplicateResultChanged: Function
  duplicate: any
  onHandleCheckByUid: Function
}

interface IState {}

class SectionPersons extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      duplicate: { ...props.duplicate }
    }
  }

  static defaultProps = {
    duplicate: {}
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.duplicate !== nextProps.duplicate) {
      this.setState({ duplicate: { ...nextProps.duplicate } })
      this.props.onDuplicateResultChanged(
        'personContactDetails',
        nextProps.duplicate
      )
    }
  }

  handleAddPerson = () => {
    const newPerson = initNewPerson()
    this.props.arrayHelpers.push(newPerson)
  }

  handleRemovePerson = (index) => {
    this.props.arrayHelpers.remove(index)
  }

  render() {
    const { values } = this.props
    const persons = values.persons
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <span
              className="AccountLeftSide-addButton"
              title={this.props.intl.formatMessage({
                id: 'crm.ui.contact.form.add_person',
                defaultMessage: 'Add Person'
              })}
              onClick={this.handleAddPerson}
            >
              <FontAwesomeIcon
                className="util-hooverable util-pointer"
                icon="plus"
              />
            </span>
            <span className="AddAccountForm-Header util-marginLeft">
              <FormattedMessage
                id="crm.ui.contact.form.contact_persons"
                defaultMessage="Contact Persons"
              />
            </span>
          </div>
        </div>
        <div>
          {persons &&
            persons.map((v, index) => {
              return (
                <PersonItem
                  {...this.props}
                  key={index}
                  person={v}
                  index={index}
                  handleRemovePerson={this.handleRemovePerson}
                  onHandleCheckByUid={this.props.onHandleCheckByUid}
                />
              )
            })}
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addFields: (field) => dispatch(arrayPush('crmAccount', 'persons', field))
  }
}

export default connect(
  null,
  mapDispatchToProps
)(accountCheckDuplicateHoc(injectIntl(SectionPersons)))
