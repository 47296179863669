import React, { SFC } from 'react'
import { FiltersOptionList } from '../tools'

export const getOpportunityViews = () => {
  const opportunity_views = [
    {
      name: 'All',
      value: 'all'
    },
    {
      name: 'Overdue',
      value: 'overdue',
      filter: {
        is_overdue: true,
        status_code: 'active'
      }
    },
    {
      name: 'Active',
      value: 'pending',
      filter: {
        status_code: 'active'
      }
    },
    {
      name: 'Completed',
      value: 'completed',
      filter: {
        status_code: { $ne: 'active' }
      }
    },
    {
      name: 'Won',
      value: 'won',
      filter: {
        status_code: 'won'
      }
    },
    {
      name: 'Lost',
      value: 'lost',
      filter: {
        status_code: 'lost'
      }
    }
  ]
  return opportunity_views
}

interface IOpportunitiesViewSelect {
  filterValues: any
  onFilterValueChanged: Function
}

export const OpportunitiesViewSelect: SFC<IOpportunitiesViewSelect> = (props) => {
  return (
    <FiltersOptionList
      options={getOpportunityViews()}
      onFilterValueChanged={props.onFilterValueChanged}
      selectedValue={
        props.filterValues && props.filterValues?.opportunity?.status
          ? props.filterValues?.opportunity?.status?.value
          : undefined
      }
    />
  )
}