import React, { useEffect, useState } from 'react'
import { AnalyticsContextProvider, AnalyticsContextFilters, useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import DashboardContent from 'crm-modules/dashboard/dashboards/dashboard-content'
import { useGetDashboard } from '../hooks/get-dashboard-hook'

type ReportRenderSectionProps = {
  filters?: AnalyticsContextFilters,
  dashboard?: any,
  isReload?: boolean,
  refreshReload?: any
}

export const ReportRenderSection = (props: ReportRenderSectionProps) => {
  const { filters, dashboard, isReload, refreshReload } = props
  const [json] = useGetDashboard(dashboard)
  return (
    <AnalyticsContextProvider>
      <div className="AnalyticsCenter-mainView">
        <InnerDashboard dashboard={json} isReload={isReload} filters={filters} refreshReload={refreshReload} />
      </div>
    </AnalyticsContextProvider>
  )
}

const InnerDashboard = ({ isReload, filters, dashboard, refreshReload }: ReportRenderSectionProps) => {
  const analyticsContext = useAnalyticsContext()
  const analyticSize = Object.keys(analyticsContext.filters).length

  // CRM-4376 - allow empty filters for Monthly Lead Results B2B/B2C
  const dashboardCode: string = dashboard?.code ?? ''
  const allowEmptyFilter: boolean = ['ctMonthlyLeadsResultsDashboardB2B', 'ctMonthlyLeadsResultsDashboardB2C'].includes(dashboardCode)

  const showContent: boolean = (analyticSize > 0) || allowEmptyFilter;

  useEffect(() => {
    if (isReload) {
      analyticsContext?.setFilters(filters, false)
      refreshReload?.()
    }
  }, [isReload])
  return showContent && <DashboardContent dashboard={dashboard} />
}
