/* eslint-disable camelcase */
import { callQLApi } from '../api-utils'

export const qlTemplate =
  ' template_uid code document_type_code is_default description html json_data style '

export default {
  preview: params => {
    const qlParams = {
      query: ` mutation  template_preview($template:template_in) { template_preview(template: $template)  { output }  } `,
      variables: {
        template: {
          html: params.html,
          json_data: params.json,
          style: params.style,
          output: params.output,
          lang: params.lang
        }
      }
    }
    return callQLApi(qlParams).then(res => res.template_preview)
  },
  save: params => {
    const template = Object.assign({}, params)
    const templateId = template.templateId
    const func = templateId ? 'template_update' : 'template_create'
    delete template.templateId

    const templateParam = {
      code: template.code,
      html: template.html,
      json_data: template.json,
      style: template.style,
      document_type_code: template.document_type_code,
      is_default: template.is_default
    }

    if (templateId) {
      templateParam.template_uid = templateId
    }

    const qlParams = {
      query: ` mutation  ${func}($template:template_in) { ${func}(template: $template)  { ${qlTemplate} }  } `,
      variables: {
        template: templateParam
      }
    }

    console.log('reports ql saving: ', qlParams)
    return callQLApi(qlParams).then(res => res[func])
  },
  fetch: () => {
    const query = ` { templates {  ${qlTemplate} } } `
    console.log('templates ql : ', query)
    return callQLApi(query).then(res => res.templates)
  },
  get: templateId => {
    const query = ` { templates(template_uid:"${templateId}") {  ${qlTemplate} } } `
    return callQLApi(query).then(res => res.templates)
  }
}
