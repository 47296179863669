import React from 'react'
import { shape, func } from 'prop-types'
import {
  CrmDropdown,
  CrmDayPickerInput,
  CrmButton,
  CrmUserSelect
} from 'crm-components'
import { FormattedMessage } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'
import { qlqFetchAccountSalesStatus } from 'crm-data/accounts'
import { crmGenericCommandHoc } from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { DatePicker } from 'util-components'

export const salesStatusOptions = [
  { value: 'lead', name: 'Lead' },
  { value: 'prospect', name: 'Prospect' },
  { value: 'customer', name: 'Customer' },
  { value: 'lostCustomer', name: 'Lost Customer' }
]

class AdminForceUpdateSalesStatusWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    var n = moment()
    this.state = {
      validFrom: n.format('YYYY-MM-DD')
    }
  }
  static propTypes = {
    account: shape().isRequired,
    onRunCommand: func.isRequired
  }
  handleStatusSelected = selectedStatus => this.setState({ selectedStatus })
  handleValidFromSelected = validFrom =>
    this.setState({ validFrom: moment(validFrom).format('YYYY-MM-DD') })

  handleNewOwnerSelect = value => {
    this.setState({ newOwner: value })
  }
  handleSave = async () => {
    console.log('Save', this.state, this.props)
    if (!this.state.selectedStatus) {
      return toastr.warning('Select status first')
    }
    const cmd = {
      type: 'salesAccount.adminCorrectSalesStatus',
      aggregate_uid: this.props.account.account_uid,
      aggregate_type: 'salesAccount',
      payload: {
        salesStatusCode: this.state.selectedStatus,
        validFrom: this.state.validFrom,
        ownerUserUid: this.state.newOwner,
        ownerType: 'user'
      }
    }
    console.log('commnd', cmd)
    try {
      const refetchQueries = [
        {
          query: qlqFetchAccountSalesStatus,
          variables: {
            account_uid: this.props.account.account_uid
          }
        }
      ]
      await this.props.onRunCommand(cmd, { refetchQueries })
      toastr.success('New status was saved')
      this.setState({})
    } catch (err) {
      toastr.error('There were problems updating sales status')
    }
  }

  render(props) {
    return (
      <div>
        <label>
          <FormattedMessage
            id="crm.ui.account.history.admin_overwrite_customer_status"
            defaultMessage="Admin - Overwrite Customer status"
          />
        </label>

        <div className="row">
          <div className="col-md-4">
            <CrmDropdown
              placeholder={
                <FormattedMessage
                  id="crm.ui.account.history.new_status"
                  defaultMessage="New Status"
                />
              }
              options={salesStatusOptions}
              input={{
                value: this.state.selectedStatus,
                onChange: this.handleStatusSelected
              }}
            />
          </div>
          <div className="col-md-4">
            <DatePicker
              placeholder={
                <FormattedMessage
                  id="crm.ui.account.history.valid_from"
                  defaultMessage="Valid From"
                />
              }
              input={{
                value: new Date(this.state.validFrom),
                onChange: this.handleValidFromSelected
              }}
            />
          </div>
          <div className="col-md-4">
            <CrmUserSelect
              input={{
                value: this.state.newOwner,
                onChange: this.handleNewOwnerSelect
              }}
              permissionCode="all"
            />
          </div>
          <div className="col-md-2">
            <CrmButton
              onClick={this.handleSave}
              label={
                <FormattedMessage
                  id="crm.ui.account.history.valid_from"
                  defaultMessage="Save"
                />
              }
            />
          </div>
        </div>
      </div>
    )
  }
}
export default crmGenericCommandHoc(AdminForceUpdateSalesStatusWidget)
