import React from 'react'
import widgetCustomersWinRatioHoc, {
  widgetWinRatioHocProps
} from './widget-customer-win-ratio-hoc'
import { DashBoardWidget } from '../dashboard-widget'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetCustomersWinRatio: React.ComponentType<AnalyticDashboardWidget> = widgetCustomersWinRatioHoc(
  (props: widgetWinRatioHocProps) => (
    <DashBoardWidget
      title='Customers Win Ratio'
      subtitle='Last 6 Months'
      totalNumber={props.totalPercentWinRatio}
      footerRender={props.footerRender}
      isLoading={props.isLoading}
      refetch={props.refetchData}
      hideAverage
      bigContent={props.bigContent}
      onClickDetail={props.onClickDetail}
    />)
)
