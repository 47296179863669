/* eslint-disable camelcase, no-unused-vars */
import obj2arg from 'graphql-obj2arg'
import cloneDeep from 'clone-deep'
import apiCaller, { callQLWithMetaApi, callQLApi } from './api-utils'
import { parseActivity, parseActivityStatus } from './api-parsers'
import { msToUTCTimestamp } from 'util-functions/time-utils'
import { qlPersonAllFields } from 'crm-data/accounts'
import {
  qlActivityOwnFields,
  qlActivityWithFields,
  qlActivityTypeOwnFields,
  qlActivityAccountFields,
  qlActivityStatusOwnFields,
  qlActivityParticipantOwnFields
} from 'crm-data/activities'
import { parseActivityToServer } from 'crm-data/activities-utils'

/* QL Partials */

export {
  qlActivityOwnFields,
  qlActivityWithFields,
  qlActivityTypeOwnFields,
  qlActivityAccountFields,
  qlActivityStatusOwnFields,
  qlActivityParticipantOwnFields
} from 'crm-data/activities'

const parseActivityType = type => ({
  ...type,
  is_active: type.is_active === 't' || type.is_active === true
})

export default {
  listActivityTypesApi: () => {
    const qlListActivityTypes = `{ activity_types { ${qlActivityTypeOwnFields} activity_type_statuses { ${qlActivityStatusOwnFields} } } }`
    return callQLApi(qlListActivityTypes).then(res =>
      res.activity_types ? res.activity_types.map(parseActivityType) : []
    )
  },
  createActivityApi: activity => {
    const activityCopy = parseActivityToServer(activity)
    activityCopy.start_time = msToUTCTimestamp(activityCopy.start_time)

    const query = {
      query: `mutation activity_create($activity: activity_in) { activity_create(activity: $activity) {  ${qlActivityWithFields} } }`,
      variables: { activity: activityCopy }
    }
    console.dir(query)
    return callQLApi(query).then(res => parseActivity(res.activity_create))
  },
  updateActivity: activity => {
    const activityCopy = parseActivityToServer(activity)

    const query = {
      query: `mutation activity_update($activity_uid: String,$activity: activity_in) { activity_update(activity_uid: $activity_uid, activity: $activity) {  ${qlActivityWithFields} } }`,
      variables: { activity: activityCopy, activity_uid: activity.activity_uid }
    }
    return callQLApi(query).then(res => {
      console.log('got activity_update result')
      console.dir(res)
      return parseActivity(res.activity_update)
    })
  },
  listActivity: params => {
    let filterParam = ''
    if (Object.keys(params).length > 0) {
      filterParam = '(' + obj2arg(params, { noOuterBraces: true }) + ')'
    }

    const query = `{ activities${filterParam} {  ${qlActivityWithFields} } }`
    console.log('graph ql activity : ', query)
    return callQLWithMetaApi(query).then(res => ({
      activities: res.data.activities,
      count: res.meta ? res.meta.count : 0
    }))
  },
  createLiveActivity: activity => {
    console.log('API createLiveActivity')
    const activityCopy = { ...activity }
    delete activityCopy.arranged_by_user_uid
    delete activityCopy.tmpId
    delete activityCopy.primary_performer_user_uid
    const query = {
      query: `mutation activity_live_create($activity: activity_in) { activity_live_create(activity: $activity) {  ${qlActivityWithFields} } }`,
      variables: { activity: activityCopy }
    }
    console.dir(query)
    return callQLApi(query).then(res => parseActivity(res.activity_live_create))
  },
  updateLiveActivity: (activityUid, activity) => {
    console.log('API updateLiveActivity')
    const activityCopy = { ...activity }
    delete activityCopy.arranged_by_user_uid
    delete activityCopy.tmpId
    delete activityCopy.primary_performer_user_uid
    delete activityCopy.meeting_minutes

    const query = {
      query: `mutation activity_live_update($activity_uid: String, $activity: activity_in) { 
        activity_live_update(activity_uid: $activity_uid, activity: $activity) {
          ${qlActivityOwnFields}
          activity_status { ${qlActivityStatusOwnFields} }
          contact_details { contact_details_uid value  }
        } }`,
      variables: { activity: activityCopy, activity_uid: activityUid }
    }
    console.dir(query)
    return callQLApi(query).then(res => parseActivity(res.activity_live_update))
  },
  completeLiveActivity: (activityUid, activity) => {
    console.log('API completeLiveActivity')
    const activityCopy = { ...activity }
    delete activityCopy.arranged_by_user_uid
    delete activityCopy.tmpId
    delete activityCopy.primary_performer_user_uid
    delete activityCopy.meeting_minutes

    const query = {
      query: `mutation activity_live_complete($activity_uid: String, $activity: activity_in) { activity_live_complete(activity_uid: $activity_uid,activity: $activity) {  ${qlActivityOwnFields} } }`,
      variables: { activity: activityCopy, activity_uid: activityUid }
    }
    console.dir(query)
    return callQLApi(query).then(res =>
      parseActivity(res.activity_live_complete)
    )
  },
  getActivity: activityUid => {
    const query = `{ activity(activity_uid:"${activityUid}") {
       ${qlActivityWithFields} } }`

    return callQLApi(query).then(res => res.activity)
  }
}
