/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'

import { graphql, compose } from 'react-apollo'
import { qlqProcessType } from 'crm-data/processes'

import { arrayOf, shape, func } from 'prop-types'
import { CrmFullpageContainer, CrmDropdown, CrmButton } from 'crm-components'

const columns = []

class DashLeadsToday extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleCurrentStepClick = selectedStepType => {
    console.log('handleCurrentStepClick', selectedStepType)
    this.setState({ selectedStepType })
  }

  _mergeWithLocalContext = () => {
    let res = { ...this.props.analyticsContext }
    if (this.state.selectedStepType) {
      res.filters.process_step_type_uid = this.state.selectedStepType.process_step_type_uid
    }
    console.log('_mergeWithLocalContext', res)
    return res
  }

  render() {
    return (
      <div>
        <div className="row">
          <label>LEads by agents</label>
        </div>

        {/* <WiedgetMovementsProcessedLeads
          analyticsContext={this.props.analyticsContext}
          onCurrentStepClick={this.handleCurrentStepClick}
          title="Leads processed TODAY"
        />

        <WidgetMovementNewLeadsByStatus
          analyticsContext={this.props.analyticsContext}
          onCurrentStepClick={this.handleCurrentStepClick}
          title="Leads processed TODAY"
        />

        <WidgetAgentsDispositionsHm
          analyticsContext={this.props.analyticsContext}
          onCurrentStepClick={this.handleCurrentStepClick}
          title="Dispositions by agent TODAY"
        /> */}

        <div>selectedStepTypeUid : {this.state.selectedStepTypeUid}</div>
        <small>{JSON.stringify(this.props.analyticsContext)}</small>
      </div>
    )
  }
}

DashLeadsToday.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

DashLeadsToday.defaultProps = {
  dashboards: []
}

export default withApollo(DashLeadsToday)
