/* eslint-disable camelcase */
import React from 'react'
import AccountsList from './accounts-list.jsx'

const visibleColumns = [
  'name',
  'address',
  'owner_full_name',
  'assigned_time',
  'last_activity_date',
  'last_activity_comment',
  'qualification',
  'campaign_name'
]

const AccountsLeadsView = () => (
  <AccountsList
    fixQFilter={{
      kind: 'lead'
    }}
    accountsListCode="leads"
    noOwnerFilter
    visibleColumns={visibleColumns}
    noInnerNav
    loadData={["market_potential"]}
  />
)

export default AccountsLeadsView
