import React from 'react'
import DynamicTableHeader from './header/dynamic-table-header'
import { CrmDynamicTableContextProvider, useCrmDynamicTableContext } from './dynamic-table-context'
import DynamicTableViewTable from './views/table/dynamic-table-view-table'
import { DynamicTableViewMode } from './dynamic-table-consts'
import DynamicTableViewPivot from './views/dynamic-table-view-pivot'
import DynamicTableViewChart from './views/dynamic-table-view-chart'
import DynamicTableViewDashboard from './views/dynamic-table-view-dashboard'
import { withCrmContext } from '@cartrack-crm/crm-core'
import { useQlSchema } from '../component-section-data-sources/ql-schema-provider'
import { crmSchemaProd } from '../crm-schema-prod'
import { CrmDrawerPanel } from '@cartrack-crm/ui'
import { ViewOptionsPanel } from './header/view-options-panel'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

const types = crmSchemaProd.types
export interface DynamicTableComponent {
  columns?: Array<any>
  style?: any
  filters?: any
  initialColumns?: any
  initialPivotComponent?: any
  analyticsContext?: any
  small_filter?: boolean
  additional_filter_sections?: Array<string>
  filtersComponent?: any
}

export const genericTableFactory = ({
  qlOptions,
  useEdges,
  rootQLType,
  columnOptions = types,
  queryRoot,
  defaultQueryFields = undefined
}) => {
  const DynamicTableInnerComponent: any = props => {
    const dynamicTableContext: any = useCrmDynamicTableContext()
    const schema = useQlSchema()
    const viewMode = dynamicTableContext.viewMode || 'TABLE'
    const passProps = {
      ...props,
      qlOptions,
      useEdges,
      rootQLType,
      columnOptions,
      queryRoot,
      defaultQueryFields
    }

    return (
      <React.Fragment>
        {viewMode === DynamicTableViewMode.TABLE && <DynamicTableViewTable {...passProps} />}
        {viewMode === DynamicTableViewMode.PIVOT && <DynamicTableViewPivot {...passProps} />}
        {viewMode === DynamicTableViewMode.CHART && <DynamicTableViewChart {...passProps} />}
        {viewMode === DynamicTableViewMode.DASHBOARD && <DynamicTableViewDashboard {...passProps} />}
      </React.Fragment>
    )
  }

  const CrmGenericDynamicTable = props => {
    const passProps = {
      ...props,
      rootQLType
    }

    return (
      <div className="util-flexColumn util-flexGrow util-fullHeight" style={{ backgroundColor: 'white' }}>
        <CrmDynamicTableContextProvider
          rootQLType={rootQLType}
          initialColumns={props.initialColumns}
          initialPivotComponent={props.initialPivotComponent}
          schema={crmSchemaProd}
        >
          <DndProvider backend={Backend}>
            <DynamicTableHeader {...passProps} />
            <div className="util-flexGrow util-flexColumn" id="body-wrapper">
              <CrmDrawerPanel style={{ height: '100%' }} collapseWidth={10}>
                <CrmDrawerPanel.Body style={{ height: '100%' }}>
                  <DynamicTableInnerComponent {...props} style={{ height: '100%' }} />
                </CrmDrawerPanel.Body>
                <CrmDrawerPanel.Action collapseWidth={20}>
                  {isOpenDrawer => {
                    return isOpenDrawer ? (
                      <ViewOptionsPanel />
                    ) : (
                      <span>
                        <FontAwesomeIcon icon={faCog} />
                      </span>
                    )
                  }}
                </CrmDrawerPanel.Action>
              </CrmDrawerPanel>
            </div>
          </DndProvider>
        </CrmDynamicTableContextProvider>
      </div>
    )
  }

  const TableOuterWithRedux = withCrmContext(CrmGenericDynamicTable)

  return TableOuterWithRedux
}

export default genericTableFactory
