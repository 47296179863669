/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { shape, bool, func, string } from 'prop-types'

import PersonItem from '../../account/persons/person-item.jsx'
import { CrmGenericFormQL } from 'crm-components'
import {
  qlqAccountPersonGet,
  mutationAccountPersonCreate,
  mutationAccountPersonUpdate,
  mapContactPersonToServer,
  qlqfetchAccountContactDetails
} from 'crm-data/accounts'

export class PersonEditFormQl extends PureComponent {
  constructor(props) {
    super(props)

    this.state = { person: this.props.person }

    if (props.initNew && !this.props.person) {
      this.state.person = {
        contact_details: [{ kind: 'email' }, { kind: 'mobile' }]
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.person !== this.state.person) {
      this.setState({ ...this.state, person: nextProps.person })
    }
  }

  mapVariables = values => ({
    account_person: mapContactPersonToServer(values)
  })
  render() {
    console.log('PersonEditFormQl')
    console.dir(this.props)
    const mutationsOptions = ownProps => {
      console.log('Generate mutation options', ownProps)
      return {
        refetchQueries: [
          {
            query: qlqfetchAccountContactDetails,
            variables: { account_uid: ownProps.accountUid }
          }
        ]
      }
    }
    return (
      <CrmGenericFormQL
        accountUid={this.props.accountUid}
        className="CrmMdCard CrmMdCard--rounded util-bgGray"
        entityUid={this.props.account_person_uid}
        entityKeyField="account_person_uid"
        qlEntityVariableName="account_person"
        entity={this.state.person}
        formFieldsComponent={PersonItem}
        qlGet={qlqAccountPersonGet}
        qlCreate={mutationAccountPersonCreate}
        qlCreateVariables={{ account_uid: this.props.accountUid }}
        qlCreateOptions={mutationsOptions}
        qlUpdate={mutationAccountPersonUpdate}
        qlUpdateVariables={{ account_uid: this.props.accountUid }}
        qlUpdateOptions={mutationsOptions}
        qlVariableMapper={this.mapVariables}
        onCancel={this.props.onCancel}
        onSaved={this.props.onSaved}
      />
    )
  }
}

PersonEditFormQl.defaultProps = {
  style: {},
  initNew: false,
  account_person_uid: undefined,
  onSaved: undefined,
  onCancel: undefined,
  person: undefined
}

PersonEditFormQl.propTypes = {
  accountUid: string.isRequired,
  account_person_uid: string,
  person: shape({}),
  initNew: bool,
  onCancel: func,
  onSaved: func
}

export default PersonEditFormQl
