import React, { FC, forwardRef } from 'react'
import {
  DashboardFiltersDropDown,
  DashboardFiltersDropDownContainer,
  DashboardFiltersDropDownBody
} from '../styled/dropdown-group-style'

interface IGenericDropDownContainer {
  ref?: any
}

export const GenericDropDownContainer: FC<IGenericDropDownContainer> = forwardRef(({ children }, ref) => (
  <DashboardFiltersDropDown ref={ref ?? null}>
    <DashboardFiltersDropDownContainer>
      <DashboardFiltersDropDownBody>{children}</DashboardFiltersDropDownBody>
    </DashboardFiltersDropDownContainer>
  </DashboardFiltersDropDown>
))
