// Actions
export const LOG_IN = 'LOG_IN'
export const LOGGED_IN = 'LOGGED_IN'
export const LOG_OUT = 'LOG_OUT'
export const SAVE_PROFILE = 'SAVE_PROFILE'
export const ON_SAVE_PROFILE = 'ON_SAVE_PROFILE'
export const FETCH_TIMEZONES = 'FETCH_TIMEZONES'
export const RECEIVE_TIMEZONES = 'RECEIVE_TIMEZONES'
export const SAVE_PREFERENCE = 'SAVE_PREFERENCE'

// Reducer
const initialState = {
  user: JSON.parse(window.localStorage.getItem('user')) || null,
  settings: JSON.parse(window.localStorage.getItem('userSettings')) || null,
  timezones: JSON.parse(window.localStorage.getItem('userTimezones')) || null,
  preferences: {}, // JSON.parse(window.localStorage.getItem('userPreferences')) || {},
  rememberedUsername: localStorage.getItem('rememberedUsername') || ''
}

const emptyState = {
  user: null,
  settings: null,
  timezones: null,
  preferences: {},
  rememberedUsername: ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOG_IN:
      return emptyState
    case LOGGED_IN:
      return {
        ...state,
        user: action.payload.user,
        settings: action.payload.settings,
        timezones: action.payload.timezones,
        preferences: action.payload.preferences,
        rememberedUsername: action.payload.rememberedUsername
      }
    case LOG_OUT:
      console.log({
        ...emptyState,
        rememberedUsername: state.rememberedUsername
      })
      return { ...emptyState, rememberedUsername: state.rememberedUsername }

    case ON_SAVE_PROFILE:
      return { ...state, settings: action.payload.settings }
    case SAVE_PREFERENCE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.payload.key]: action.payload.value
        }
      }
    default:
      return state
  }
}

// Action Creators
export function logout(reason) {
  return {
    type: LOG_OUT,
    reason: reason
  }
}

export function login(formData) {
  return {
    type: LOG_IN,
    payload: {
      username: formData.username,
      password: formData.password,
      rememberMe: formData.rememberMe
    }
  }
}

export function saveProfile(formData, timezones) {
  return {
    type: SAVE_PROFILE,
    payload: {
      formData,
      timezones
    }
  }
}

export function savePreferences(key, value) {
  return {
    type: SAVE_PREFERENCE,
    payload: { key, value }
  }
}

// Selectors
export const getUser = state => state.user.user
export const getSettings = state => state.user.settings
export const getTimezones = state => state.user.timezones
export const getPreferences = state => state.user.preferences
export const getRememberedUsername = state => state.user.rememberedUsername
