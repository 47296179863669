/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { func, arrayOf, shape } from 'prop-types'
import { Query, withApollo } from 'react-apollo'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { CrmFormInputDropdown } from 'crm-components'
import activitiesApi from 'crm-api/activities-api'
import { buildNewActivity } from 'crm-utils/activities-utils'
import { qlmActivitiesCommand } from 'crm-data/activities'
import { getUser } from 'crm-duxs/crm-reducer'
import uuidv4 from 'uuid'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

const NewLiveCallHoc = WrappedComponent => {
  const LiveCallHOC = class extends React.PureComponent {
    constructor(props) {
      // console.log('LiveCallHOC constructor')
      super(props)
      this.state = {
        newActivityUid: uuidv4()
      }
    }
    static propTypes = {
      onRunCommand: func.isRequired
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState({ ...this.state, ...newState }, () => {
          resolve()
        })
      })

    handleStartNewLiveActivity = async pactivity => {
      console.log('handleStartNewLiveActivity', pactivity, this.props)
      if (!pactivity.activity_type_uid) {
        throw new Error('activity_type_uid not provided for new activity')
      }
      if (!this.props.activityTypes) {
        throw new Error("Can't create new activity - no activity types loaded")
      }
      const activityType = this.props.activityTypes.find(
        t => t.activity_type_uid === pactivity.activity_type_uid
      )
      if (!activityType) {
        throw new Error(
          "Can't create activity - can't find activity type: " +
            pactivity.activity_type_uid
        )
      }
      // console.log('call buildNewActivity')
      const activity = buildNewActivity(
        pactivity.account_uid,
        activityType,
        this.props.currentUser,
        this.props.activityTypes
      )

      // console.log('new activity 2 ', activity)
      const command = {
        type: 'activity.startLive',
        aggregate_uid: this.state.newActivityUid,
        aggregate_type: 'Activity',
        payload: {
          activity_uid: this.state.newActivityUid,
          ...activity,
          ...pactivity
        }
      }
      // Copy activity as attr in payload - new format
      command.payload.activity = { ...command.payload }
      try {
        // console.log('Try create new activity', command, this.props)
        const res = await this.props.onRunCommand(
          command,
          {},
          qlmActivitiesCommand
        )
        // console.log('Live Activity Created', res)
        let activity = res.data.cqCommand.activity
        if (activity && activity.hasOwnProperty('activity')) {
          activity = activity.activity
        }
        // console.log('activity', activity)
        await this.setStateAsync({ newActivityUid: uuidv4(), activity })
        return activity
      } catch (err) {
        // console.log('Create live activity error', err)
        throw new Error("Can't create new activity")
      }
      // activity.contact_details_uid  = action.payload.activity
      // yield put({ type: actions.createLiveActivity.type, payload: { activity } })

      // await this.setStateAsync({ isSaving: true })
      // const savedActivity = await activitiesApi.updateLiveActivity(
      //   activityChange.activity_uid,
      //   activityChange
      // )
      // await this.setStateAsync({ isSaving: false })
      // console.log('saved', savedActivity)
      // this.setState({ activity: savedActivity })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          isStartingActivity={this.props.isRunningCommand}
          onStartNewLiveActivity={this.handleStartNewLiveActivity}
        />
      )
    }
  }

  function mapStateToProps(state) {
    return {
      currentUser: getUser(state)
    }
  }

  return connect(mapStateToProps)(crmGenericCommandHoc(LiveCallHOC))
}

NewLiveCallHoc.propTypes = {
  onChange: func.isRequired,
  options: arrayOf({}).isRequired,
  currentUser: shape({}).isRequired
}

export default NewLiveCallHoc
