/* eslint-disable camelcase */
import { callQLApi } from '../api-utils'
import obj2arg from 'graphql-obj2arg'
import { leadSourceAllFields } from './lead-sources-api'
import { qlDepartment } from './departments-api'

export const campaignAllFields = ` 
  campaign_uid name code is_active description lead_source_uid auto_launch_sales_process lead_source { ${leadSourceAllFields} } type marketing_campaign_uid sales_channel_uid transaction_type_code 
  sales_channel { ${qlDepartment} } 
  marketing_campaign { name } details 
  medium_uid medium { name }
  `

const _mapCampaignToSave = campaign => {
  const ret = { ...campaign }
  delete ret.marketing_campaign
  delete ret.lead_source
  delete ret.medium
  return ret
}
export default {
  fetchCampaignsApi: params => {
    console.log('fetchCampaignsApi params : ', params)
    let filterParam = ''
    if (Object.keys(params).length > 0) {
      filterParam = '(' + obj2arg(params, { noOuterBraces: true }) + ')'
    }

    //const query = `{ activities${filterParam} {  ${qlActivityWithFields} } }`
    const query = `{ campaigns${filterParam} { ${campaignAllFields} } }`
    return callQLApi(query).then(res => res)
  },
  createCampaignApi: campaign => {
    const campaign_in = _mapCampaignToSave(campaign)
    const qlParams = {
      query: ` mutation  campaign_create($campaign_in:campaign_in) { campaign_create(campaign_in: $campaign_in) { ${campaignAllFields} }  } `,
      variables: {
        campaign_in
      }
    }
    return callQLApi(qlParams).then(res => res.campaign_create)
  },
  updateCampaignApi: campaign => {
    // eslint-disable-next-line
    const campaign_in = _mapCampaignToSave(campaign)
    const qlParams = {
      query: ` mutation  campaign_update($campaign_in:campaign_in) { campaign_update(campaign_in: $campaign_in) { ${campaignAllFields} }  } `,
      variables: {
        campaign_in
      }
    }
    return callQLApi(qlParams).then(res => res.campaign_update)
  }
}
