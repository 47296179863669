import React from 'react'
import { FormattedMessage } from 'react-intl'
type initComponentStringType = (
  label: string,
  prefix?: string
) => any

export const getIdFormat = (label: string) => {
  return label.replace(/ /g, '_').toLowerCase()
}

export const formatMessage: initComponentStringType = (label, prefix = '') => {
  return typeof label === 'string' ? (
    <FormattedMessage
      id={`crm.${prefix}.${getIdFormat(label)}`}
      defaultMessage={label}
    />
  ) : (
      label
    )
}