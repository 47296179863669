import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon } from 'util-components'

const Breadcrumbs = props => {
  const items = props.data.map((breadcrumb, idx) => (
    <span key={breadcrumb.label}>
      {breadcrumb.path ? (
        <Link to={breadcrumb.path}>
          <span className="Breadcrumbs-crumbLink">
            <Icon name="angle-left" /> {breadcrumb.label}
          </span>
        </Link>
      ) : (
        <span className="Breadcrumbs-crumbNoLink">{breadcrumb.label}</span>
      )}
      {idx !== props.data.length - 1 && (
        <span className="Breadcrumbs-separator">/</span>
      )}
    </span>
  ))

  return <div className={`Breadcrumbs ${props.className}`}>{items}</div>
}

Breadcrumbs.propTypes = {
  data: arrayOf(
    shape({
      label: string.isRequired,
      path: string
    })
  ).isRequired,
  className: string
}

Breadcrumbs.defaultProps = {
  className: ''
}

export default Breadcrumbs
