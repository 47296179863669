import React from 'react'
import { bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { getActive, getCount } from 'crm-duxs/loading-bar'

const LoadingBar = props => {
  if (!props.active) return null
  return (
    <div
      className="LoadingBar"
      style={{ background: `hsl(20.7, 89.7%, ${58.2 - props.count * 5}%)` }}
    />
  )
}

LoadingBar.propTypes = {
  active: bool.isRequired,
  count: number.isRequired
}

function mapStateToProps(state) {
  return {
    active: getActive(state),
    count: getCount(state)
  }
}

export default connect(mapStateToProps)(LoadingBar)
