import React, { createContext, useContext, useState, useEffect } from 'react'
import uuid from 'uuid'
import { node, shape } from 'prop-types'
import cloneDeep from 'clone-deep'
import { DataSchema } from '../data-utils/schema-types'
import { DataSource } from '../types/component-type'
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

export type WidgetConfigurationContextType = {
  schema: DataSchema
  component: any // current component
  addDataSource: Function
  updateDataSource: Function
  addLayoutItem: Function
  updateLayoutItem: Function
  setComponentDefinition: Function
  addFieldToDataSource: Function
  isDirty: boolean
}

const initalContextValue = {}

export const WidgetConfigurationContext: any = createContext(initalContextValue)

export const WidgetConfigurationContextProvider = ({
  children,
  schema = {},
  component = {}

  // rootQLType,
  // initialColumns
}) => {
  const [componentState, setComponentState] = useState<any>(component)
  const [isDirty, setIsDirty] = useState(false)

  // useEffect(() => {
  //   console.log('Component changed from outside', component)
  //   setComponentState(component)
  // }, [component])

  const _generateIdForDataSource = (dataSourceDefinition: DataSource) => {
    // TODO TON - if this one exists already - add 1,2.. ad the end
    return dataSourceDefinition.data_type ? dataSourceDefinition.data_type.toLowerCase() : uuid()
  }

  const _rebuildCombinedDataSource = (componentDefinition) => {
    const ret = { ...componentDefinition }
    console.log('_rebuildCombinedDataSource')

    return ret
  }
  const _updateComponentState = (newComponentState) => {
    const withCombinedDataSources = _rebuildCombinedDataSource(newComponentState)
    setComponentState(withCombinedDataSources)
    setIsDirty(true)
  }

  const _cloneAndInitComponent = () => {
    const newComponentState = cloneDeep(componentState)
    if (!newComponentState.def_version) {
      newComponentState.def_version = 6
    }
    if (!newComponentState.type) {
      newComponentState.type = 'widget'
    }
    newComponentState.content = newComponentState.content || {}
    newComponentState.content.data = newComponentState.content.data || {}
    newComponentState.content.data = newComponentState.content.data || {
      dataSources: []
    }
    newComponentState.content.layout = newComponentState.content.layout || {
      items: []
    }

    return newComponentState
  }
  const addDataSource = (dataSourceDefinition) => {
    console.log('addDataSource', dataSourceDefinition)

    // do some more calculations here
    var newDataSourceDefinition = {
      fields: [],
      ...dataSourceDefinition,
      id: _generateIdForDataSource(dataSourceDefinition)
    }
    if (!newDataSourceDefinition.name) {
      newDataSourceDefinition.name = newDataSourceDefinition.data_type
    }
    console.log('newDataSourceDefinition', newDataSourceDefinition)
    const newComponentState = _cloneAndInitComponent()
    newComponentState.content.data.dataSources = [
      ...(newComponentState.content.data.dataSources || []),
      newDataSourceDefinition
    ]
    _updateComponentState(newComponentState)
  }
  const _findDataSourceById = (dataSourceId) =>
    componentState.content?.data?.dataSources.find((ds) => ds.id === dataSourceId)

  // We use addFieldToDataSource instead of generic 'update' method -becacse at the same time we could update laoyuts
  const addFieldToDataSource = (dataSourceId, newField) => {
    console.log('addFieldToDataSource', dataSourceId, newField)
    const ds = _findDataSourceById(dataSourceId)
    if (ds) {
      const updatedDS = { ...ds, fields: [...(ds.fields || []), newField] }
      updateDataSource(updatedDS)
    }
  }

  const updateDataSource = (dataSourceDefinition) => {
    console.log('updateDataSource', dataSourceDefinition)
    var newDataSources = [...(componentState.content.data.dataSources || [])]
    var idx = newDataSources.findIndex((ds) => ds.id === dataSourceDefinition.id)
    console.log('idx', idx)
    if (idx >= 0) {
      newDataSources[idx] = { ...dataSourceDefinition }
      const newComponentState = cloneDeep(componentState)
      newComponentState.content.data.dataSources[idx] = dataSourceDefinition
      _updateComponentState(newComponentState)
    }
  }

  const setComponentDefinition = (componentDefinition) => {
    console.log("setComponentDefinition CALLED - this shouldn't be called to overwrite whole config")
    _updateComponentState(componentDefinition)
  }

  const _addNewFieldToDataSources = (componentState, newField) => {
    if (newField?.data?.data_source) {
      console.log('_addNewFieldToDataSources', newField)
      var ds = componentState.content.data.dataSources.find((ds) => ds.id == newField.data.data_source)
      console.log('Found ds', ds)
      if (ds) {
        const duplicateField = ds.fields ? ds.fields.find((field) => field.name === newField.data.field) : false
        ds.fields = ds.fields || []

        if (!duplicateField) {
          console.log('Adding new field to ds')
          ds.fields.push({
            name: newField.data.field
          })
        }
      }
    }
    return componentState
  }

  const updateLayoutItem = (layoutItemDefinition, newField = null) => {
    console.log('updateLayoutItem', layoutItemDefinition, componentState, newField)
    var newLayoutItems =
      componentState.content.layout && componentState.content.layout.items
        ? [...componentState.content.layout.items]
        : []
    console.log('find in ', newLayoutItems, layoutItemDefinition.id)
    var idx = newLayoutItems.findIndex((ds) => ds.id === layoutItemDefinition.id)
    console.log('idx', idx)
    if (idx >= 0) {
      newLayoutItems[idx] = { ...layoutItemDefinition }
    }
    console.log('newLayoutItems', newLayoutItems)
    let newComponentState = _cloneAndInitComponent()
    newComponentState.content.layout.items = newLayoutItems
    // TON - this is trick now - we check what is data reference from this field and eventually add this as 'field' to data source
    if (newField) {
      newComponentState = _addNewFieldToDataSources(newComponentState, newField)
    }
    console.log('after _addNew ', newComponentState)
    _updateComponentState(newComponentState)
  }

  const addLayoutItem = (layoutItemDefinition) => {
    console.log('addLayoutItem', layoutItemDefinition)
    // TODO: here must be logic that will eventually put
    const newComponentState = _cloneAndInitComponent()
    newComponentState.content.layout.items = newComponentState.content.layout.items || []
    const newItem = { ...layoutItemDefinition }
    newItem.props = newItem.props || {}
    if (!newItem.id) {
      newItem.id = newItem.type
      // TODO TON: add number here
    }
    newComponentState.content.layout.items.push(newItem)
    _updateComponentState(newComponentState)
  }

  var widgetConfigurationContextValue = {
    schema,
    component: componentState,
    addLayoutItem,
    updateLayoutItem,
    updateDataSource,
    addDataSource,
    setComponentDefinition,
    addFieldToDataSource,
    isDirty
  }

  return (
    <WidgetConfigurationContext.Provider value={widgetConfigurationContextValue}>
      <DndProvider backend={Backend}>{children}</DndProvider>
    </WidgetConfigurationContext.Provider>
  )
}

WidgetConfigurationContextProvider.propTypes = {
  children: node.isRequired,
  filter: shape({})
}

export const useComponentConfigurationContext = (): WidgetConfigurationContextType =>
  useContext(WidgetConfigurationContext)
