/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { shape, func, string, arrayOf } from 'prop-types'
import CrmWidget from '../../../components/crm-widget.jsx'
import widgetFitmentsArpuByRmHoc from './widget-fitments-arpu-by-rm-hoc'
import { FlatTable } from 'crm-components'

class WidgetFitmentsArpuByRM extends React.PureComponent {
  constructor(props) {
    super(props)
    this.columns = [
      {
        accessor: 'owner.full_name',
        Header: 'RM Name',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <span onClick={handleClick}>
              {row.original.owner ? row.original.owner.full_name : ''}
            </span>
          )
        }
      },
      {
        accessor: 'quantity',
        Header: 'Units Fitted',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.quantity}
            </div>
          )
        }
      },
      {
        accessor: 'hardware_price',
        Header: 'HW Sum',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.hardware_price
                ? row.original.hardware_price.toLocaleString(undefined, {
                    maximumFractionDigits: 0
                  })
                : ''}
            </div>
          )
        }
      },
      {
        accessor: 'subscription',
        Header: 'MRR Sum',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.subscription
                ? row.original.subscription.toLocaleString(undefined, {
                    maximumFractionDigits: 0
                  })
                : ''}
            </div>
          )
        }
      },
      {
        accessor: 'avg_subscription',
        Header: 'MRR Avg',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.avg_subscription
                ? row.original.avg_subscription.toLocaleString(undefined, {
                    maximumFractionDigits: 0
                  })
                : ''}
            </div>
          )
        }
      }
    ]
  }

  handleRefresh = async () => {
    try {
      return await this.props.onRefresh()
    } catch (err) {
      toastr.error('Problem getting stats')
      // console.error(err)
    }
  }

  handleCellClicked = cell => {
    const filters = {
      ...this.props.analyticsContext.filters
    }
    if (cell.owner?.full_name !== "Total") {
      filters.user = {
        user_uid: {
          value: cell.owner_user_uid,
          label: cell.owner ? cell.owner.full_name : undefined
        }
      }
    }
    this.props.onAnalyticsRangeSelected({ type: 'fitment', filters })
  }
  handleGetRawData = () => this.props.data

  render() {
    console.log('Render widget new leads by current status')
    return (
      <CrmWidget
        title={this.props.title ? this.props.title : 'Revenue by RM'}
        subtitle=" "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-new-leads-by-status"
      >
        {!this.props.analyticsContext.filters.activity_date && (
          <span>You have to select activity date range filter </span>
        )}

        {this.props.data && (
          <FlatTable
            columns={this.columns}
            data={this.props.data}
            onCellClicked={this.handleCellClicked}
          />
        )}
      </CrmWidget>
    )
  }
}

WidgetFitmentsArpuByRM.propTypes = {
  analyticsContext: shape({}).isRequired,
  onRefresh: func.isRequired,
  onAnalyticsRangeSelected: func.isRequired,
  title: string,
  data: arrayOf()
}
WidgetFitmentsArpuByRM.defaultProps = {
  title: undefined,
  data: undefined
}

export default widgetFitmentsArpuByRmHoc(WidgetFitmentsArpuByRM)
