/* eslint-disable camelcase */
import React from 'react'
import { Route, Switch } from 'react-router'
import Loadable from 'react-loadable'
import ProtectedRoute from 'crm-modules/app/protected-route'

import AccountsList from './modules/accounts/list/accounts-list.jsx'
import AccountsRawView from './modules/accounts/list/accounts-raw-view.jsx'
import AccountsLeadsView from './modules/accounts/list/accounts-leads-view.jsx'
import PendingAccountsView from './modules/accounts/list/accounts-pending-view.jsx'
import CustomersAccountsView from './modules/accounts/list/accounts-customers-view.jsx'
import AccountFitmentsView from './modules/settings/fitments/crm-settings-fitments-view.jsx'
import MyClientsView from './modules/accounts/list/my-clients-view.jsx'

import CrmHookRequestLogs from './modules/logs/hook-requests/crm-hook-request-logs-view.jsx'

import CrmAgentStatus from './modules/phone/agent-status.jsx'

import CrmAdminResources from './modules/admin/resources/crm-settings-resources.jsx'

import CrmReportsSettings from './modules/settings/reports/crm-settings-reports.jsx'

import OpportunitiesDashboardView from './modules/opportunities/dashboard/opportunities-dashboard-view.jsx'
// import MyActivitiesDashboardView from './modules/dashboard/myactivities-dashboard.jsx'
import OpportunitiesListview from './modules/opportunities/list/opportunities-list-view'
import ContractsListView from './modules/opportunities/contracts/contracts-list-view.jsx'
import QuotationToApproveView from './modules/opportunities/quotation-approve/quotation-to-approve-view.jsx'
import ManagerProcessesList from './modules/manager/processes/manager-processes-list.jsx'
import CrmMeSettings from './modules/settings/me/crm-profile-settings.jsx'
import MyTasksView from './modules/tasks/my-tasks-view.jsx'
import ForecastPage from './modules/forecasts/forecast-page.tsx'

import MyDialerTasksView from './modules/tasks/my-dialer-tasks-view.jsx'
import ManagerTasksView from './modules/tasks/manager-tasks-view.jsx'
import DialerView from './modules/dialer/dialer/cc-dialer-view.jsx'
import crmReport from './modules/reports/reports.jsx'

import CrmScheduleReport from './modules/manager/schedule/schedule-list.tsx'
import CrmScheduleReportForm from './modules/manager/schedule/schedule-form-view.tsx'

// Accounts
import ViewAccountPage from './modules/accounts/account/components/view-account-page.jsx'
import ProspectPage from './modules/accounts/prospect/prospect-page.jsx'
import ProspectsListPage from './modules/accounts/prospect/prospects-list-page.jsx'
import AfterMeetingSurveyPage from './modules/activities/component/survey/after-meeting-survey-page.jsx'
// Admin

import AttributeListView from './modules/admin/attributes/attributes-list.jsx'
import AttributeFormView from './modules/admin/attributes/attribute-form-view.jsx'

import AccountProcessPage from './modules/accounts/process/process-page.jsx'
import FeedAuditView from './modules/admin/feed/feed-audit-view.jsx'

import CrmCampaignCostSettings from './modules/settings/campaigns-cost/crm-settings-campaigns-cost-views.jsx'
import { ForecastManager } from './modules/forecasts/manager/forecast-manager.tsx'
import { ForecastManagerView } from './modules/forecasts/manager/forecast-manager-view'
import CrmImportTool from './modules/admin/import-tool/crm-import-tool.jsx'
import UserWorkDashboard from './modules/dashboard/dashboards/user-work-dashboard.tsx'
import UserDashboard from './modules/dashboard/dashboards/user-dashboard18.tsx'
import { DashBoardRM } from './modules/dashboard/dashboards/new-dashboard-rm'
import { DashBoardAgent } from './modules/dashboard/dashboards/new-dashboard-agent'
import { DashboardROI } from './modules/dashboard/dashboards/roi/dashboard-roi'
import { ManagerCheckScreen } from 'crm-modules/manager/check/manager-check-screen'
import AdminListWidgetTable from './modules/dashboard/widgets/admin-tools/admin-list-widget-table'
import AdminWidgetDetail from './modules/dashboard/widgets/admin-tools/admin-widget-detail'
import { MasterCustomerFormView } from '@cartrack-crm/crm-manager'
import { MainPage } from 'crm-modules/dashboard/dashboards/main-page'
import { ReportDashboard } from 'crm-modules/dashboard/dashboards/report-dashboard'
import { EmailRecipient } from 'crm-modules/admin/email-recipient'

const CrmDemo = Loadable({
  loader: () => import('./modules/demo/crm-demo-views.jsx'),
  loading: () => <div> Loading ... </div>
})

const LeadAnalytics = Loadable({
  loader: () => import('./modules/manager/leads/leads-analytics.jsx'),
  loading: () => <div> Loading ... </div>
})
const CrmCamsIntegrationPanel = Loadable({
  loader: () => import('./modules/integration/cams/cams-integration-panel.jsx'),
  loading: () => <div> Loading ... </div>
})
const CrmDashboard = Loadable({
  loader: () => import('./modules/management/dashboard/crm-dashboard'),
  loading: () => <div> Loading ... </div>
})

const CrmAdminSalesScript = Loadable({
  loader: () => import('./modules/admin/sales-scripts/sales-scripts-view.jsx'),
  loading: () => <div> Loading ... </div>
})

const GroupCrmCalendarMeetings = Loadable({
  loader: () =>
    import('./modules/calendars/meetings/group-calendars-meeting.jsx'),
  loading: () => <div> Loading ... </div>
})

const CrmLeadsManager = Loadable({
  loader: () =>
    import('./modules/accounts/leads-manager/leads-manager-page.tsx'),
  loading: () => <div> Loading ... </div>
})
const TasksManagerPanel = Loadable({
  loader: () => import('./modules/manager/tasks/tasks-manager-panel'),
  loading: () => <div> Loading ... </div>
})
const TaskQueue = Loadable({
  loader: () => import('./modules/manager/tasks/task-queue-item.tsx'),
  loading: () => <div> Loading ... </div>
})

const ActivityAudit = Loadable({
  loader: () => import('./modules/manager/activity-audit/activity-audit.tsx'),
  loading: () => <div> Loading ... </div>
})

// const CrmImportTool = Loadable({
//   loader: () => import('./modules/admin/import-tool/crm-import-tool.jsx'),
//   loading: () => <div> Loading ... </div>
// })
const CrmAdvancedItems = Loadable({
  loader: () => import('./modules/admin/advanced/advanced-menu.jsx'),
  loading: () => <div> Loading ... </div>
})
const AuthenticationHistoryPanel = Loadable({
  loader: () =>
    import('./modules/admin/authentication/authentication-history-panel.jsx'),
  loading: () => <div> Loading ... </div>
})

const CrmSettingsRoutes = Loadable({
  loader: () => import('./modules/settings/settings-routes.jsx'),
  loading: () => <div> Loading ... </div>
})

// const GenericDashboards = Loadable({
//   loader: () => import('./modules/dashboard/dashboards/generic-dashboards'),
//   loading: () => <div> Loading ... </div>
// })
import GenericDashboards from './modules/dashboard/dashboards/generic-dashboards'


// const UserDashboard = Loadable({
//   loader: () => import('./modules/dashboard/dashboards/user-dashboard18.tsx'),
//   loading: () => <div> Loading ... </div>
// })
const CrmLeadSourcesSettings = Loadable({
  loader: () =>
    import(
      './modules/settings/lead-source-categories/lead-source-categories-view.jsx'
    ),
  loading: () => <div> Loading ... </div>
})

const MyCrmCalendarMeetings = Loadable({
  loader: () => import('./modules/calendars/meetings/my-calendars-meeting.jsx'),
  loading: () => <div> Loading ... </div>
})
const MyTasksDashboard = Loadable({
  loader: () => import('./modules/tasks/views/my-tasks-dashboard.jsx'),
  loading: () => <div> Loading ... </div>
})

const MyDashboards = () => <GenericDashboards mode="MY_DASHBOARDS" />
const GroupTasksList = props => <MyTasksView {...props} type="group" />

const HistoryPage = props => (
  <AccountProcessPage {...props} historyMode={true} />
)

const AccountsListAll = () => <AccountsList noOwnerFilter loadData={['market_potential']} />
const routes = (
  <React.Fragment>
    <ProtectedRoute
      exact
      path="/master/:master_uid"
      component={MasterCustomerFormView}
    />

    <ProtectedRoute exact path="/crm/demo" component={CrmDemo} />

    <ProtectedRoute
      exact
      path="/crm/admin/leadsources"
      component={CrmLeadSourcesSettings}
    />

    <ProtectedRoute
      exact
      path="/crm/profile-settings"
      component={CrmMeSettings}
    />
    <Route path="/crm/settings" component={CrmSettingsRoutes} />
    <ProtectedRoute
      path="/crm/callcenter/agent-status"
      component={CrmAgentStatus}
    />
    <ProtectedRoute
      path="/crm/admin/hook-request-log"
      component={CrmHookRequestLogs}
    />

    <ProtectedRoute path="/crm/admin/import-tool" component={CrmImportTool} />
    <ProtectedRoute path="/crm/admin/attribute" component={AttributeListView} />

    <Route path="/crm/admin/advanced" component={CrmAdvancedItems} />

    <ProtectedRoute
      path="/crm/admin/attribute/:attributeUid"
      component={AttributeFormView}
    />
    {/* Lazy */}
    <Route path="/crm/leads-manager" component={CrmLeadsManager} />
    <ProtectedRoute path="/crm/manager/dashboards" component={ReportDashboard} />
    <ProtectedRoute
      path="/crm/manager/tasks-manager"
      component={TasksManagerPanel}
    />
    <ProtectedRoute
      path="/crm/manager/activity-audit"
      component={ActivityAudit}
    />
    <ProtectedRoute path="/crm/manager/check" component={ManagerCheckScreen} />
    <ProtectedRoute path="/crm/manager/forecasts" component={ForecastManager} />
    <ProtectedRoute
      path="/crm/manager/forecasts/:forecastUid"
      component={ForecastManagerView}
    />
    <ProtectedRoute
      path="/crm/manager/tasks-manager/:tabName"
      component={TasksManagerPanel}
    />
    <ProtectedRoute
      path="/crm/manager/tasks-manager/queue/:task_queue_uid"
      component={TaskQueue}
    />
    <ProtectedRoute
      path="/crm/cams-integration"
      component={CrmCamsIntegrationPanel}
    />
    <ProtectedRoute
      path="/crm/admin/campaign-cost"
      component={CrmCampaignCostSettings}
    />

    <ProtectedRoute path="/crm/admin/resources" component={CrmAdminResources} />
    <Route path="/crm/admin/sales-scripts" component={CrmAdminSalesScript} />

    <ProtectedRoute
      path="/crm/manager/fitments"
      component={AccountFitmentsView}
    />

    <ProtectedRoute path="/crm/admin/reports" component={CrmReportsSettings} />
    <ProtectedRoute
      path="/crm/admin/authentication"
      component={AuthenticationHistoryPanel}
    />

    <ProtectedRoute path="/crm/accounts/all" component={AccountsListAll} />
    <ProtectedRoute path="/crm/accounts/raw" component={AccountsRawView} />
    <ProtectedRoute path="/crm/accounts/lead" component={AccountsLeadsView} />
    <ProtectedRoute
      path="/crm/accounts/pending"
      component={PendingAccountsView}
    />
    <ProtectedRoute path="/crm/prospects" component={ProspectsListPage} />
    <ProtectedRoute
      path="/crm/accounts/customers"
      component={CustomersAccountsView}
    />
    <ProtectedRoute path="/crm/accounts/my_clients" component={MyClientsView} />
    <Switch>
      <ProtectedRoute
        exact
        path="/crm/account/:accountId/activity/:activityUid/meeting-minutes"
        component={AfterMeetingSurveyPage}
      />
      <Route
        path="/crm/account/:accountId/history/:processUid"
        component={HistoryPage}
      />
      <Route
        path="/crm/account/:accountId/:currentModuleCode"
        component={ViewAccountPage}
      />
      <Route path="/crm/account/:accountId" component={ViewAccountPage} />
    </Switch>
    <ProtectedRoute
      path="/crm/prospect/:prospectUid"
      component={ProspectPage}
    />
    <ProtectedRoute path="/crm/management/dashboard" component={CrmDashboard} />
    <ProtectedRoute exact path="/crm/dashboard/details" component={UserDashboard} />
    <ProtectedRoute exact path="/crm/dashboard/me" component={MainPage} />
    <ProtectedRoute exact path="/crm/dashboard/rm" component={DashBoardRM} />
    <ProtectedRoute
      exact
      path="/crm/dashboard/agent"
      component={DashBoardAgent}
    />
    <ProtectedRoute exact path="/crm/dashboard/roi" component={DashboardROI} />
    <ProtectedRoute
      exact
      path="/crm/dashboard/work"
      component={UserWorkDashboard}
    />

    {/* <ProtectedRoute
      exact
      path="/crm/dashboard/activities"
      component={MyActivitiesDashboardView}
    /> */}

    <ProtectedRoute path="/crm/calendar" component={MyCrmCalendarMeetings} />
    <ProtectedRoute
      path="/crm/group_calendar"
      component={GroupCrmCalendarMeetings}
    />
    {/* <ProtectedRoute
      path="/crm/calendar/dealers"
      component={CrmDashboard}
    /> */}
    {/* <ProtectedRoute path="/crm/mob/calendar" component={MobCalendarView} /> */}
    <ProtectedRoute
      exact
      path="/crm/opportunities/dashboard"
      component={OpportunitiesDashboardView}
    />
    <ProtectedRoute
      exact
      path="/crm/opportunities/list"
      component={OpportunitiesListview}
    />
    <ProtectedRoute
      exact
      path="/crm/opportunities/quotation"
      component={QuotationToApproveView}
    />
    <ProtectedRoute
      exact
      path="/crm/opportunities/contracts"
      component={ContractsListView}
    />
    <ProtectedRoute
      exact
      path="/crm/opportunities/forecast"
      component={ForecastPage}
    />
    <ProtectedRoute
      path="/crm/manager/processes"
      component={ManagerProcessesList}
    />
    <ProtectedRoute path="/crm/manager/feed" component={FeedAuditView} />
    <ProtectedRoute path="/crm/manager/reports" component={crmReport} />
    <ProtectedRoute
      path="/crm/manager/schedule"
      component={CrmScheduleReport}
    />
    <ProtectedRoute
      path="/crm/manager/schedule/:scheduleUid"
      component={CrmScheduleReportForm}
    />
    <ProtectedRoute
      path="/crm/manager/leads_analytics"
      component={LeadAnalytics}
    />

    <ProtectedRoute
      path="/crm/manager/my_dashboards"
      component={MyDashboards}
    />

    <ProtectedRoute
      path="/crm/admin/components"
      component={AdminListWidgetTable}
    />

    <ProtectedRoute
      path="/crm/admin/system_recipient"
      component={EmailRecipient}
    />
    <ProtectedRoute
      path="/crm/admin/components/:dashboardUid"
      component={AdminWidgetDetail}
    />
    <ProtectedRoute path="/crm/tasks/dialer" component={MyDialerTasksView} />
    <ProtectedRoute path="/crm/tasks/my-tasks" component={MyTasksDashboard} />
    <ProtectedRoute path="/crm/tasks/my-list" component={MyTasksView} />
    <ProtectedRoute path="/crm/tasks/my-group" component={GroupTasksList} />
    <ProtectedRoute
      path="/crm/tasks/manager-group"
      component={GroupTasksList}
    />
    <ProtectedRoute path="/crm/manager/tasks" component={ManagerTasksView} />
    <ProtectedRoute path="/crm/callcenter/dialer" component={DialerView} />
  </React.Fragment>
)

export default routes
