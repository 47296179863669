import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import { CrmButton } from 'crm-components'

class CrmTranslate extends PureComponent {
  handleClickTranslate = () => {
    // eslint-disable-next-line
    parent.window.open(
      `https://translate.google.com/?hl=th#th/en/${encodeURIComponent(
        this.props.text
      )}`,
      'Translate',
      'menubar=1,resizable=1,width=850,height=550'
    )
  }

  render() {
    return (
      <CrmButton
        iconButton
        icon="file-text-o"
        title="Translate"
        onClick={this.handleClickTranslate}
      />
    )
  }
}

CrmTranslate.defaultProps = { text: '' }

CrmTranslate.propTypes = {
  text: string
}

export default CrmTranslate
