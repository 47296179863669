import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { injectIntl, FormattedMessage } from 'react-intl'
import sectionContactPersonsHoc from 'crm-modules/accounts/hoc/section-contact-persons-hoc.js'
import React, { PureComponent } from 'react'
import ContactPersonItem from './contact-person-item.jsx'

class SectionContactPersons extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleStartAddPerson = () => {
    if (this.props.isAddingNewPerson()) {
      const elmnt = document.getElementById('div-contact-person-item-0')
      elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return
    }
    this.props.onStartAddPerson()
  }

  render() {
    const { persons } = this.props
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-1">
            <span
              className="AccountLeftSide-addButton"
              title={this.props.intl.formatMessage({
                id: 'crm.account.click_to_add_new_person',
                defaultMessage: 'Click to add new person'
              })}
            >
              <FontAwesomeIcon
                className="util-hooverable util-pointer"
                icon="user-plus"
                onClick={this.handleStartAddPerson}
              />
            </span>
          </div>
          <div className="col-md-11">
            {persons.length === 0 && (
              <div className="util-textLight util-textCenter">
                <small>
                  <FormattedMessage
                    id="crm.account.no_contact_person_for_this_account_click_icon_on_the_left_side_to_add_new_person"
                    defaultMessage="No contact person for this account. Click icon on the left
                    side to add new person."
                  />
                </small>
              </div>
            )}
            {persons.map((person, index) => {
              const key = person.account_person_uid || person.newObjectUid
              return (
                <div key={key} id={'div-contact-person-item-' + index}>
                  <hr className="util-horizontalLine" />
                  <ContactPersonItem
                    key={key}
                    index={index}
                    person={person}
                    onCancelAddPerson={this.props.onCancelAddPerson}
                    removePersonUI={this.props.removePersonUI}
                    updatePersonsUI={this.props.updatePersonsUI}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(sectionContactPersonsHoc(SectionContactPersons))
