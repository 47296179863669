import React from 'react'
import styled from 'styled-components'

const asset = {
  empty_icon: require('./asset/empty-page-icon.svg')
}

const PageLogo = styled.img`
  width: 150px;
  margin: auto;
`

const PageText = styled.label`
  padding: 25px;
  color: #b6b6b6;
  font-size: 18px;
`

const SpanText = styled.span`
  font-weight: 500;
  color: #444;
`

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 57px 20px;
  width: 100%;
  height: 100%;
`

const Row = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
`

export const EmptyPage = () => (
  <InnerContainer>
    <Row>
      <PageLogo src={asset.empty_icon} />
      <PageText>
        Please select <SpanText>Report Type</SpanText> and <SpanText>Apply Filter</SpanText> for view relevant data
      </PageText>
    </Row>
  </InnerContainer>
)
