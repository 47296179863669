import { hhmmss } from 'crm-utils/common-utils'

const agentHistoricalActuals = {
  type: 'item',
  label: 'Agent Historical Actuals B2C',
  code: 'agentHistoricalActualsB2C',
  dashboard_uid: 'agentHistoricalActualsB2C',
  defaultFilters: {
    organization_unit: {
      department_type_uid: {
        value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
        label: 'Telesales'
      }
    }
  },
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'agentHistoricalActualsB2C',
        def_version: 5,
        rev: '2019-07-05',
        title: 'Agent Historical Actuals B2C',
        description_: 'Default filter: Department Telesales',
        content: {
          data: {
            dataSources: [
              {
                code: 'AgentHistoricalActualB2C',
                name: 'AgentHistoricalActualB2C',
                type: 'QM',
                filter: {},
                qlRoot: 'agent_historical_actual_b2c',
                qlEdges: false,
                qlOnly: true,
                qlQuery: `
                query agent_historical_actual_b2c($filter: JSON) {
                  agent_historical_actual_b2c(filter: $filter) {
                      answered_calls
                      answered_calls_time
                      call_per_lead
                      calls_unique_leads
                      fitted_units
                      fits_to_sales
                      leads_assigned
                      not_answered_calls
                      total_calls
                      total_calls_time
                      won_opportunities_all
                      won_opportunities_all_size
                      won_opportunities_existing
                      won_opportunities_new
                      conversion_leads_assign
                      conversion_leads_dialed
                      conversion_size_leads_assign
                      conversion_new_leads
                      conversion_old_leads
                      user_uid
                      user {
                          full_name
                          is_active
                          contract_start_date
                      }
                  }
              }
                `
              }
            ]
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                mob_fix_columns: 2,

                props: {
                  generate_summary: true,
                  generate_average: true,
                  initialSort: {
                    id: 'user.contract_start_date',
                    order: 'asc'
                  },
                  rowIdField: 'user_uid',
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 30
                    },
                    {
                      Header: 'RM',
                      accessor: 'user.full_name',
                      classNameHandler: row => (row.original?.user?.is_active ? '' : 'util-opacity50'),
                      detailsPopup: 'user_dashboard',
                      Cell: row => row.original?.user?.full_name,
                      detailsFilterMapping: {
                        user_uid: 'user_uid'
                      }
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'user.contract_start_date',
                      Cell: row => row.original?.user?.contract_start_date,
                      width: 70
                    },
                    {
                      Header: 'Leads Dialed',
                      accessor: 'calls_unique_leads',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        type: { medium_code: 'phone_call' },
                        array_agg: {
                          group_by: ['account_uid', 'created_by_user_uid'],
                          key_condition: 'activity_uid'
                        }
                      },
                      detailsFilterMapping: {
                        created_by_user_uid: 'user_uid'
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Leads Assigned',
                      accessor: 'leads_assigned',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'sales_process',
                      detailsFilter: {
                        is_first: true,
                        owner_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        owner: {
                          user_uid: 'user_uid'
                        }
                      },
                      contextMapping: {
                        assigned_to_user_date: 'activity_date'
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Answered Calls',
                      accessor: 'answered_calls',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        status: { is_success: true },
                        type: { medium_code: 'phone_call' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Not Answered Calls',
                      accessor: 'not_answered_calls',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        status: { is_success: false },
                        type: { medium_code: 'phone_call' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Total Calls',
                      accessor: 'total_calls',
                      width: 50,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        type: { medium_code: 'phone_call' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Calls per Lead',
                      accessor: 'call_per_lead',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2
                    },
                    {
                      Header: 'Total Talk Time',
                      accessor: 'answered_calls_time',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        status__is_success: true,
                        type__medium_code: 'phone_call'
                      },
                      detailsFilterMapping: {
                        performer__user_uid: 'user_uid'
                      },
                      className: 'util-textRight util-pointer',
                      Cell: row => hhmmss(row.original.answered_calls_time)
                    },
                    {
                      Header: 'Total Time on Phone',
                      accessor: 'total_calls_time',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        type__medium_code: 'phone_call'
                      },
                      detailsFilterMapping: {
                        performer__user_uid: 'user_uid'
                      },
                      className: 'util-textRight util-pointer',
                      Cell: row => hhmmss(row.original.total_calls_time)
                    },
                    {
                      Header: 'Sales (New Leads)',
                      accessor: 'won_opportunities_new',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        status_code: 'won',
                        is_existing_customer: false
                      },
                      detailsFilterMapping: {
                        owner__user_uid: 'user_uid'
                      },
                      contextMapping: {
                        completed_time: 'activity_date',
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      }
                    },
                    {
                      Header: 'Sales (All Lead Date)',
                      accessor: 'won_opportunities_all',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        status_code: 'won'
                      },
                      detailsFilterMapping: {
                        owner__user_uid: 'user_uid'
                      },
                      contextMapping: {
                        completed_time: 'activity_date',
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      }
                    },
                    {
                      Header: 'Sales Size (All Lead Date)',
                      accessor: 'won_opportunities_all_size',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        status_code: 'won'
                      },
                      detailsFilterMapping: {
                        owner__user_uid: 'user_uid'
                      },
                      contextMapping: {
                        completed_time: 'activity_date',
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      }
                    },
                    {
                      Header: 'Sales (Old Leads)',
                      accessor: 'won_opportunities_existing',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        status_code: 'won',
                        is_existing_customer: true
                      },
                      detailsFilterMapping: {
                        owner__user_uid: 'user_uid'
                      },
                      contextMapping: {
                        completed_time: 'activity_date',
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      }
                    },
                    {
                      Header: 'Conversion % (Leads Assigned)',
                      accessor: 'conversion_leads_assign',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2,
                      unitSymbol: '%'
                    },
                    {
                      Header: 'Conversion % Size (Leads Assigned)',
                      accessor: 'conversion_size_leads_assign',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2,
                      unitSymbol: '%'
                    },
                    {
                      Header: 'Conversion % (New Leads)',
                      accessor: 'conversion_new_leads',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2,
                      unitSymbol: '%'
                    },
                    {
                      Header: 'Conversion % (Old Leads) ',
                      accessor: 'conversion_old_leads',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2,
                      unitSymbol: '%'
                    },
                    {
                      Header: 'Conversion % (Leads Dialled) ',
                      accessor: 'conversion_leads_dialed',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2,
                      unitSymbol: '%'
                    },
                    {
                      Header: 'Fits',
                      accessor: 'fitted_units',
                      detailsPopup: 'fitment',
                      detailsFilter: {
                        status_code: 'completed',
                        owner_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        completed_date: 'activity_date',
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      },
                      className: 'util-textRight util-pointer',
                      width: 50,
                      type: 'number'
                    },
                    {
                      Header: 'Fits to sales',
                      accessor: 'fits_to_sales',
                      width: 50,
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 2
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'AgentHistoricalActualB2C'
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}

export default agentHistoricalActuals
