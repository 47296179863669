import React, { SFC } from 'react'
import styled from 'styled-components'
import { CrmButton, CrmButtonGroup } from '@cartrack-crm/ui'
import { ActivityContentProps } from './activity-content'
import { CrmTranslateText } from '@cartrack-crm/ui'
import CrmActivityPerformer from './activity-performer'

const NoteContent = styled.div`
font-size: 10px;
font-style: italic;
font-weight: 500;
letter-spacing: 0;
line-height: 24px;
`

const NoteAction = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  visibility: hidden;
`
const NoteActivityContainer = styled.div`
  display: flex;
  &:hover ${NoteAction} {
    visibility: visible;
  }
  flex-direction: column;
  justify-content: space-between;
`

const RowItem = styled.div`
  svg {
    margin-right: 6px;
    color: #999999;
  }
  margin-right: 16px;
`

const Performer = styled(RowItem)``

const TopRow = styled.div`
  display: flex;
  color: #3a3a3a;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 16px;
  align-items: center;
  svg {
    font-size: 11px;
  }
`

interface NoteActionProps extends ActivityContentProps {
  onClick: () => void
}

const NoteActivity: SFC<NoteActionProps> = (props) => {

  return (
    <NoteActivityContainer>
      <TopRow>
        <Performer>
          <CrmActivityPerformer
            isOwner={props.isOwner}
            performer={props.primary_performer}
          />
        </Performer>
      </TopRow>
      <NoteContent>
        <CrmTranslateText>{props.note_text}</CrmTranslateText>
      </NoteContent>
      <NoteAction>
        <CrmButtonGroup small>
          <CrmButton icon="eye" onClick={props.onClick} />
        </CrmButtonGroup>
      </NoteAction>
    </NoteActivityContainer>
  )
}

export default NoteActivity
