/* eslint-disable */
const meetingsCreated = {
  title: 'Meetings Created by Telesales rev 24-01-2019',
  description:
    "User creating meeting must have department name exactly 'Telesales'",
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'ActivitiesListQM',
          alias: 'ActivitiesListQM',
          type: 'QM',
          filter: {
            type__medium_code: 'meeting',
            created_by__department__name: 'Telesales'
          },
          fields: [
            'performer_user_uid',
            'created_date',
            'created_by__full_name',
            'performer__full_name',
            'status__name',
            'start_time',
            'account_uid',
            'account__name'
          ],
          aggregate: [{ id: 'value', type: 'sum' }],
          contextMapping: {
            created_date: 'activity_date'
          }
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          keyField: 'account_uid',
          props: {
            columns: [
              {
                header: 'Created At',
                accessor: 'created_date',
                type: 'date'
              },
              {
                header: 'Created by',
                accessor: 'created_by__full_name'
              },
              {
                header: 'Account Name',
                accessor: 'account__name'
              },
              {
                header: 'Status',
                accessor: 'status__name'
              },
              {
                header: 'Performer',
                accessor: 'performer__full_name'
              },
              {
                header: 'Scheduled Time',
                accessor: 'start_time',
                type: 'datetime'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'ActivitiesListQM'
            }
          }
        }
      ]
    }
  }
}

const meetingsCreatedResults = {
  rows_dims: [
    {
      dim_code: 'user_creator',
      kpi_code: 'meetings_details',
      name: 'Created by'
    }
  ],
  columns_dims: [
    {
      dim_code: 'activity_status',
      kpi_code: 'meetings_details',
      name: 'Status'
    }
  ],
  kpis: [{ code: 'meetings_details', as: 'meetings_details' }],
  title: 'Results of Meetings Created by Telesales',
  filters: [
    {
      kpi_code: 'meetings_details',
      dim_code: 'activity_created_date',
      operator: 'eq',
      context: true,
      context_filter_code: 'date_range'
    },
    {
      kpi_code: 'meetings_details',
      dim_code: 'activity_source_type',
      operator: 'eq',
      expression: {
        fn: 'dictionary_values',
        params: {
          dictionary_code: 'crm.meeting.source_type',
          value: 'callcenter'
        }
      }
    }
  ]
}

const dashTelesalesOverview = {
  dashboard_uid: 'dashTelesalesOverview',
  name: 'Telesales Meetings',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'analitics_widget',
        widgetDefinition: meetingsCreated
      },
      {
        type: 'analitics_widget',
        widgetDefinition: meetingsCreatedResults
      }
    ]
  }
}

const dashTelesalesCampaign = {
  dashboard_uid: 'dashTelesalesCampaign',
  name: 'Telesales Campaign',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashTelesalesCampaign'
      }
    ]
  }
}

const dashTelesalesCampaignMovements = {
  dashboard_uid: 'dashTelesalesCampaignMovements',
  name: 'Telesales Campaign Movements',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashTelesalesCampaignMovements'
      }
    ]
  }
}

const dashTelesalesToday = {
  dashboard_uid: 'dashTelesalesToday',
  name: 'Telesales Today',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashTelesalesToday'
      }
    ]
  }
}

const dashTelesalesAgentsPerformance = {
  dashboard_uid: 'dashTelesalesAgentsPerformance',
  name: 'Telesales Agents Performance',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashTelesalesAgentsPerformance'
      }
    ]
  }
}

const dashTelesalesAgentsCallbacks = {
  dashboard_uid: 'dashTelesalesAgentsCallbacks',
  name: 'Telesales Agents Callbacks',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashTelesalesAgentsCallbacks'
      }
    ]
  }
}

const dashTelesalesAgentsDispositions = {
  dashboard_uid: 'dashTelesalesAgentsDispositions',
  name: 'Telesales Agents Dispositions',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashTelesalesAgentsDispositions'
      }
    ]
  }
}
const dashTelesalesSales = {
  dashboard_uid: 'dashTelesalesSales',
  name: 'Telesales Sales',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashTelesalesSales'
      }
    ]
  }
}

const dashLeadsByAgents = {
  dashboard_uid: 'dashLeadsByAgents',
  name: 'Leads By Agents',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashLeadsByAgents'
      }
    ]
  }
}

const dashDailyLeadsMonitoring = {
  dashboard_uid: 'dashDailyLeadsMonitoring',
  name: 'Daily Leads Monitoring',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashDailyLeadsMonitoring'
      }
    ]
  }
}

const dashMonthlyLeadsMonitoring = {
  dashboard_uid: 'dashMonthlyLeadsMonitoring',
  name: 'Monthly Leads Monitoring',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashMonthlyLeadsMonitoring'
      }
    ]
  }
}

export const dashboards = [
  dashTelesalesOverview,
  dashTelesalesCampaign,
  dashTelesalesAgentsPerformance,
  dashTelesalesCampaignMovements,
  dashTelesalesToday,
  dashLeadsByAgents,
  dashDailyLeadsMonitoring,
  dashTelesalesSales,
  dashTelesalesAgentsDispositions,
  dashTelesalesAgentsCallbacks,
  dashMonthlyLeadsMonitoring
]
