/* eslint-disable */
const dashLeadsDetails = {
  dashboard_uid: 'dashLeadsDetails',
  name: 'Leads Details - details on accounts with Lead In date in given period',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'leadsDetails'
      }
    ]
  }
}

const dashLeadsToday = {
  dashboard_uid: 'dashLeadsToday',
  name: 'Leads Generated Today',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashLeadsToday'
      }
    ]
  }
}

const dashCampaignLeadsResults = {
  dashboard_uid: 'dashCampaignLeadsResults',
  name: 'Campaign results',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'campaignLeadsResults'
      }
    ]
  }
}

const dashRmPerformance = {
  dashboard_uid: 'dashRmPerformance',
  name: 'RM performance',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'ReportRMPerformance'
      }
    ]
  }
}

const dashLeadsDailyStats = {
  dashboard_uid: 'dashLeadsDailyStats',
  name: 'Leads Daily Stats',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'ReportLeadsDailyStats'
      }
    ]
  }
}
const dashLeadsAnalytics18 = {
  dashboard_uid: 'dashLeadsAnalytics18',
  name: '*Leads Analytics Dashboard',
  description:
    'Date range will be interpreted as Lead In Date, RM will be interpreted as current account owner',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'ReportLeadsAnalytics18'
      }
    ]
  }
}

const dashDigitalMonitoring18 = {
  dashboard_uid: 'dashDigitalMonitoring18',
  name: 'Digital Live Monitoring Dashboard',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'DigitalMonitoringDashboard'
      }
    ]
  }
}

const dashCurrentLeadStatus = {
  dashboard_uid: 'dashCurrentLeadStatus',
  label: 'Leads - Current Status',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashCurrentLeadStatus'
      }
    ]
  }
}

const joeDashboard = {
  dashboard_uid: 'joeDashboard',
  name: 'Dashboard Joe',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'joeDashboard'
      }
    ]
  }
}

const agentWb = {
  dashboard_uid: 'agentWb',
  name: 'Agents Live Monitoring ',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'agentWb'
      }
    ]
  }
}

const dashMeetingsAnalysis18 = {
  dashboard_uid: 'dashMeetingsAnalysis18',
  name: 'Meetings Analysis',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashMeetingsAnalysis18'
      }
    ]
  }
}

const dashRmsTasksStats = {
  dashboard_uid: 'dashRmsTasksStats',
  name: 'RMs Tasks Statistics',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'widgetRmsTasksStats'
      }
    ]
  }
}
const dashWeeklySalesActivities18 = {
  dashboard_uid: 'dashWeeklySalesActivities18',
  name: 'Weekly Sales Activities (Evert)',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'RmWeeklySalesActivitiesDashboard'
      }
    ]
  }
}

const dashNoneMovement18 = {
  dashboard_uid: 'dashNoneMovement18',
  name: 'Stale Opportunities',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'NoneMovementDashboard'
      }
    ]
  }
}

const dashboards = [
  dashLeadsDetails,
  dashRmPerformance,
  dashLeadsDailyStats,
  dashLeadsAnalytics18,
  dashDigitalMonitoring18,
  dashWeeklySalesActivities18,
  dashNoneMovement18,
  dashLeadsToday,
  joeDashboard,
  dashMeetingsAnalysis18,
  dashCurrentLeadStatus,
  agentWb,
  dashRmsTasksStats
]
export default dashboards
