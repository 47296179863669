import { call, takeLatest, select } from 'redux-saga/effects'
import { CRM_CURRENT_USER_CHANGED, getUser } from 'crm-duxs/crm-reducer'

function* initDataReducer() {
  // Fetch datatypes
  const user = yield select(getUser)
  if (!user) return
  try {
    // Not required yet yield call(fetchQlSchema)
  } catch (err) {
    console.error("Can't init Data Reducer")
  }
}

function* handleCurrentUserChanged() {
  yield call(initDataReducer)
}

export default function* accountsSaga() {
  yield takeLatest(CRM_CURRENT_USER_CHANGED, handleCurrentUserChanged)
  // yield takeLatest(FETCH_QL_SCHEMA, fetchQlSchema)
  yield call(initDataReducer)
}
