import React from 'react'
import { shape } from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { FormattedDate, FormattedTime } from 'react-intl'

function _getIconClass(task) {
  const taskType = task && task ? task.task_type_kind : ''
  switch (taskType) {
    case 'meeting':
      return 'fa-users'
    case 'phone_call':
      return 'fa-phone'
    case 'note':
      return 'fa-sticky-note'
    default:
      return 'fa-futbol-o'
  }
}

const TasksListItem = ({ task }) => {
  let title = ''
  title += task.process_type_name
    ? 'Process: ' + task.process_type_name + ' '
    : ''

  title += task.campaign_name ? 'Campaign: ' + task.campaign_name + ' ' : ''
  const isLocked =
    task.locked_till && moment().isBefore(task.locked_till, 'minutes') > 0
  return (
    <div
      key={task.task_uid}
      id={task.task_uid}
      className="CrmMdListItem "
      title={title}
    >
      <Link
        to={`/crm/account/${task.account_uid}${
          task.process_uid ? '/process/' + task.process_uid : ''
        }`}
      >
        <div className="util-flexRow">
          <div className="TasksListItem-icon">
            <i className={`fa ${_getIconClass(task)}`} />
            {isLocked && (
              <i
                className="fa fa-lock util-marginLeft"
                title={`This task is locked by ${task.locked_by_user_name} till ${task.locked_till}`}
              />
            )}
          </div>
          <div className="util-flexGrow util-marginRight">
            <div className="util-flexRow">
              <div className="util-flexGrow">{task.account_name}</div>
              <div className="">
                {task.task_due_date && (
                  <React.Fragment>
                    <FormattedDate value={task.task_due_date} />{' '}
                    <FormattedTime value={task.task_due_date} />
                  </React.Fragment>
                )}
              </div>
            </div>
            <div>
              <small>{task.task_name}</small>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
TasksListItem.propTypes = {
  task: shape({}).isRequired
}
export default TasksListItem
