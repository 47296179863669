import React from 'react'
import { string, bool, node, element, oneOfType } from 'prop-types'

const SectionHeader = ({
  label,
  sub,
  subLabel,
  id,
  requiredMessage,
  action,
  children = undefined
}) => {
  if (action) {
    label = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        <div>{label}</div>
        <div>{action}</div>
      </div>
    )
  }
  return (
    <p
      className={`SectionHeader ${
        sub ? 'SectionHeader--sub' : 'SectionHeader--section'
      }`}
      id={id}
    >
      {label}
      {subLabel ? (
        <span className="SectionHeader-subLabel">{subLabel}</span>
      ) : null}
      {requiredMessage ? (
        <span className="SectionHeader-requiredMessage">
          <span className="SectionHeader-asterisk">*</span>
          Required Fields
        </span>
      ) : null}

      {children && <div>{children}</div>}
    </p>
  )
}

SectionHeader.propTypes = {
  label: oneOfType([element, string]),
  action: element,
  subLabel: string,
  sub: bool,
  id: string,
  requiredMessage: bool,
  children: node
}

SectionHeader.defaultProps = {
  label: '',
  subLabel: '',
  sub: false,
  id: '',
  requiredMessage: false
}

export default SectionHeader
