import React from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CrmTable, CrmButton } from '../../index'
import LoadingIcon from '../../../../util-components/loading-icon'
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns'
import {
  withGeneralPermissionsContextHOC,
  PERMISSIONS_TYPES,
  withBulkOperationsContext,
  BulkOperationsContextType
} from '@cartrack-crm/crm-core'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import { DynamicTableColumnsSelector } from '@cartrack-crm/crm-analytics/src/dynamic-table2/dynamic-table-columns'
import {
  getColumnByName,
  getColumnOptionsForType
} from '@cartrack-crm/crm-analytics/src/dynamic-table2/dynamic-columns-utils'
import { exportDataToExcel } from '@cartrack-crm/crm-analytics'

/**
 * CHECK docs / DYNAMIC-TABLES.md
 */
interface TableInnerProps {
  pageInfo?: any
  data?: any
  isLoading: boolean
  pageSize: number
  columns?: Array<any>
  tableName?: string
  onFetchData?: Function
  defaultSorted?: any
  getTdProps?: Function
  onRowClick?: Function
  getTrProps?: Function
  listState?: any
  onDataLoaded: Function
  translation?: any
  filter?: any
  crmContext?: any
}

export interface DynamicTableComponent {
  columns?: Array<any>
  style: any
  filters: any
  initialColumns?: any
}

const genericTableFactory = ({
  qlOptions,
  qlQuery = undefined,
  useEdges,
  rootQLType,
  columnOptions,
  queryRoot,
  extractResult = undefined,
  useInstanceContext = false,
  defaultQueryFields = undefined
}): React.ComponentType<DynamicTableComponent> => {
  class TableInner extends React.PureComponent<TableInnerProps> {
    constructor(props) {
      super(props)
      const state = {
        isConfigModalOpen: false,
        optionalColumns: []
      }
      // map initialColumns
      this.state = state
    }

    componentWillReceiveProps (nextProps) {
      if (nextProps !== this.props) {
      }
      if (this.props.filter !== nextProps.filter || this.props.crmContext !== nextProps.crmContext) {
        this.props.onFetchData(this.props.listState)
      }
    }

    render () {
      const { pageInfo, data } = this.props
      const isLoading = this.props.isLoading
      let totalPages = pageInfo && this.props.pageInfo ? Math.ceil(pageInfo.count / this.props.pageSize) : 1
      const getTrProps = (state, rowInfo) => {
        let ret: any = {}
        if (this.props.getTrProps) {
          const outer = this.props.getTrProps(state, rowInfo)
          ret = { ...ret, ...outer }
        }
        if (this.props.onRowClick) {
          ret.onClick = () => this.props.onRowClick(rowInfo)
        }
        return ret
      }
      const getTdProps = (state, rowInfo, column, instance) => {
        let ret = {}
        if (this.props.getTdProps) {
          const outer = this.props.getTdProps(state, rowInfo, column, instance)
          ret = { ...ret, ...outer }
        }
        return ret
      }
      return (
        <div style={{ height: '100%', position: 'relative' }}>
          {isLoading && (
            <div className="CrmWidget--loading">
              <LoadingIcon />
              <div>Loading...</div>
            </div>
          )}
          {data && (
            <CrmTable
              style={{ height: '100%' }}
              columns={this.props.columns}
              tableName={this.props.tableName}
              translation={this.props.translation}
              className="react-table -striped -highlight"
              data={data}
              getTrProps={getTrProps}
              getTdProps={getTdProps}
              manual
              pages={totalPages}
              onFetchData={this.props.onFetchData}
              defaultSorted={this.props.defaultSorted}
            />
          )}
        </div>
      )
    }
  }

  interface TableOuterState {
    listState?: any
    isLoading: boolean
    currentPageData: any
    pageInfo: any
    data?: any
    pageSize?: number
    optionalColumns?: any
    isConfigModalOpen?: boolean
    onlyDynamicColumns: boolean
  }

  interface TableOuterProps {
    bulkOperationElementType?: string
    onShowBulkOperations?: Function
    getRefreshFunc?: Function
    onDataLoaded?: Function
    exportFileName?: any
    client: any
    columns: Array<any>
    style?: any
    generalPermissionsContext: any
    crmContext?: any
    initialColumns?: any
    bulkOperationsContext: BulkOperationsContextType
  }
  class TableOuter extends React.PureComponent<TableOuterProps, TableOuterState> {
    exportDataPermission: string
    innerRef: any

    constructor(props) {
      super(props)
      const state: any = {
        isLoading: false,
        currentPageData: [],
        pageInfo: undefined,
        listState: {
          page: 0,
          pageSize: 20
        },
        onlyDynamicColumns: false
      }

      if (props.initialColumns) {
        const initialColumnsMapped = []
        props.initialColumns.forEach(col => {
          const foundColumn = this.findColumnByPath(col)
          if (foundColumn !== undefined && foundColumn !== null) {
            initialColumnsMapped.push(foundColumn)
          }
        })
        state.optionalColumns = initialColumnsMapped
        state.onlyDynamicColumns = true
      }
      this.exportDataPermission = 'crm.export_data'
      this.state = state
    }

    findColumnByPath = path => {
      const splited = path.split('.')
      const resultPath = []
      let currentLevel
      let currentType = rootQLType
      let currentLevelOptions = columnOptions
      for (let i = 0; i < splited.length; i++) {
        currentLevel = getColumnOptionsForType(columnOptions, currentType)
        if (i === splited.length - 1) {
          const columnDef = currentLevel ? getColumnByName(currentLevel.fields, splited[i]) : undefined
          return columnDef != undefined
            ? {
              column: columnDef,
              path: resultPath
            }
            : undefined
        } else {
          resultPath.push(splited[i])
          const columnDef: any = currentLevel ? getColumnByName(currentLevel.fields, splited[i]) : undefined
          if (!columnDef) {
            return undefined
          }
          currentType = columnDef.type
        }
      }
    }

    handleRefresh = () => {
      this.handleFetchData(this.state.listState)
    }
    doRefetch = () => {
      this.handleRefresh()
    }

    handleBulkOperation = () => {
      const accounts = this.state.currentPageData ? this.state.currentPageData : []
      const params = {
        elementType: this.props.bulkOperationElementType
      }
      this.props.bulkOperationsContext.onShowBulkOperations(accounts, params)
    }
    handleSetRef = ref => {
      this.innerRef = ref
    }
    handleFetchData = async listState => {
      this.setState({ isLoading: true })
      const result = await this.runQuery({}, listState)
      this.setState({
        isLoading: false,
        currentPageData: result.edges,
        pageInfo: result.pageInfo,
        listState,
        pageSize: listState.pageSize
      })
      return result
    }

    componentWillMount () {
      if (this.props.getRefreshFunc) this.props.getRefreshFunc(this.doRefetch)
    }

    handleDataLoaded = ({ data }) => {
      this.setState({ data })
      if (typeof this.props.onDataLoaded === 'function') {
        this.props.onDataLoaded(data)
      }
    }

    getExportFileName = () => {
      let exportFileName = 'Crm-export'
      if (typeof this.props.exportFileName === 'string') {
        exportFileName = this.props.exportFileName
      } else if (typeof this.props.exportFileName === 'function') {
        exportFileName = this.props.exportFileName()
      }
      return exportFileName
    }

    addInstanceContextVariables = () => {
      const ret: any = {}
      if (useInstanceContext) {
        if (this.props.crmContext && this.props.crmContext.instance) {
          ret.instance_uid = this.props.crmContext.instance.instance_uid
        }
        if (this.props.crmContext && this.props.crmContext.master) {
          ret.master_uid = this.props.crmContext.master.master_uid
        }
      }
      return ret
    }

    runQuery = async (variables, listState, isExport = false) => {
      variables.offset = listState.page ? listState.page * listState.pageSize : 0
      variables.limit = listState.pageSize || 20
      if (listState.sorted && listState.sorted.length > 0) {
        variables.sort = listState.sorted
      }

      const options = qlOptions.options ? qlOptions.options(this.props) : { variables: {} }

      const dynamicQuery = qlQuery ? qlQuery : this.buildGQLQuery()
      const finalVariables = {
        ...this.addInstanceContextVariables(),
        ...variables,
        ...options.variables
      }
      const result = await this.props.client.query({
        query: dynamicQuery,
        variables: finalVariables,
        context: {
          headers: isExport
            ? {
              'x-crm-log-request': true
            }
            : {}
        },
        fetchPolicy: 'no-cache'
      })

      const paginatedResult = extractResult ? extractResult(result.data) : result.data[queryRoot]
      let data = paginatedResult.edges
      const pageInfo = paginatedResult.pageInfo
      if (useEdges) {
        data = data.map(row => row.edge)
      }
      return {
        edges: data,
        pageInfo
      }
    }
    handleExportExcel = async () => {
      const parsed: any = await this.runQuery({}, { pageSize: 10000 }, true)
      if (parsed && parsed.edges) {
        const fileName = this.getExportFileName()
        exportDataToExcel({
          data: parsed.edges,
          fileName
        })
      }
    }

    buildQueryTree () {
      const mergeLevels = (level1, level2) => {
        const resultSelections = [
          ...(level1 && level1.selections ? level1.selections : []),
          ...(level2 && level2.selections ? level2.selections : [])
        ]
        return {
          selections: resultSelections
        }
      }
      const mappedOptionalColumns = this.state.optionalColumns
        ? this.state.optionalColumns.reduce((a, col) => {
          const mapPathsToSelection = paths => {
            let res = []

            if (paths.length >= 1) {
              const selection: any = { name: paths[0] }
              const lpaths = [...paths]
              lpaths.splice(0, 1)
              selection.selections = mapPathsToSelection(lpaths)
              res = [selection]
            }
            if (paths.length === 0 && col.column) {
              // last level
              if (col.column.queryFragment) {
                res = col.column.queryFragment.selections
              } else if (col.column.selections) {
                res = col.column.selections
              } else {
                res = [{ name: col.column.name }]
              }
            }
            return res
          }

          const mappedPath: Array<any> = col.path ? mapPathsToSelection([...col.path]) : []
          return [...a, ...mappedPath]
        }, [])
        : []
      const mergedTree = mergeLevels(defaultQueryFields, {
        selections: mappedOptionalColumns
      })
      return mergedTree
    }

    generatePagedQLQueryString () {
      const queryTree = this.buildQueryTree()

      const buildQueryForLevel = level => {
        if (level && level.selections) {
          let levelString = ` { `
          const fields = level.selections.reduce((a, field) => {
            let res = a + ' ' + field.name + ' '
            if (field.selections) {
              res += buildQueryForLevel(field)
            }
            return res
          }, '')
          levelString += fields + ' } '
          return levelString
        }
        return ''
      }
      const fieldsString = buildQueryForLevel(queryTree)
      let queryString = `query ${queryRoot}($sort: [JSON], $offset: Int, $limit: Int, $filter: JSON,  $groupBy: [String], $aggregate: [JSON]) {
        ${queryRoot} (limit: $limit, filter: $filter, sort: $sort, offset: $offset, aggregate: $aggregate, groupBy: $groupBy) {
           edges {
              edge ${fieldsString}
            }
            pageInfo {
              count
            }
            }
        } `
      return queryString
    }

    buildGQLQuery = () => {
      // dynamically add columns
      const queryString = this.generatePagedQLQueryString()
      try {
        const parsed = gql(queryString)
        return parsed
      } catch (err) {
        console.error('Problem parse ql', err)
      }
    }
    saveOptionalColumns = optionalColumns => {
      this.setState(
        {
          optionalColumns,
          isConfigModalOpen: false
        },
        () => {
          this.doRefetch()
        }
      )
    }

    mapOptionalColumnRenderers = () => {
      return this.state.optionalColumns
        ? this.state.optionalColumns.map(col => {
          let header = ''
          const generatedColumn = this.generateColumn(col, col.path)
          header += col.column.header ? col.column.header : col.column.description
          return {
            ...generatedColumn,
            Header: header
          }
        })
        : []
    }

    generateColumn = (col, path) => {
      const renderer = row => {
        let val = row.original ? row.original : row
        if (path && val) {
          for (var i = 0; i < path.length; i++) {
            val = val ? val[path[i]] : undefined
          }
        }
        if (val === undefined) {
          return <span />
        }
        if (col.column && col.column.Cell) {
          const cellRendered = col.column.Cell({ ...row, original: val })
          return cellRendered || <span />
        }
        if (col.column && col.column.name && val) {
          return val[col.column.name] || <span />
        }
        if (col.name && val) {
          return val[col.name] || <span />
        }
        return val || <span />
      }
      const columnId =
        col && col.column
          ? (path && path.length > 0 ? path.join('.') + '.' : '') + (col.column.id ? col.column.id : col.column.name)
          : ''

      const column: any = {
        Header: col && col.column ? col.column.description : 'column',
        Cell: renderer,
        accessor: columnId,
        id: columnId,
        name: columnId
      }
      if (col.column && col.column.width) {
        column.width = col.column.width
        column.minWidth = col.column.width
      }
      return column
    }
    findColumnDefinitionByCode = fullColumnPath => {
      const findColumnForType = (code, type) => {
        const parts = code.split('.')
        const typeColumns = columnOptions.find(o => o.type === type)
        if (!typeColumns) {
          return undefined
        }
        const nestedColumn = typeColumns.fields.find(f => f.name === parts[0])
        if (!nestedColumn) {
          return console.error("Can't find nested column: " + parts[0])
        }
        if (parts.length === 1) {
          let finalColumnPath = fullColumnPath.split('.')
          finalColumnPath.pop()
          return nestedColumn.Cell
            ? {
              Cell: nestedColumn.Cell,
              header: nestedColumn.description
            }
            : this.generateColumn(nestedColumn, finalColumnPath)
        } else {
          // Nested

          if (!nestedColumn.type) {
            return console.error("Nested column doesn't have type")
          }
          parts.splice(0, 1)
          return findColumnForType(parts.join('.'), nestedColumn.type)
        }
      }
      return findColumnForType(fullColumnPath, rootQLType)
    }
    parseColumDefinitions = () => {
      return this.props.columns
        ? this.props.columns.map(col => (col.code ? this.findColumnDefinitionByCode(col.code) : col))
        : []
    }
    render () {
      const optionalColumnRenderers = this.mapOptionalColumnRenderers()
      const mergedColumns = this.state.onlyDynamicColumns
        ? optionalColumnRenderers
        : [...this.parseColumDefinitions(), ...optionalColumnRenderers]
      return (
        <div className="util-flexRow" style={{ height: '100%', ...this.props.style }}>
          <DynamicTableColumnsSelector
            isOpen={this.state.isConfigModalOpen}
            onClose={() => this.setState({ isConfigModalOpen: false })}
            columnOptions={columnOptions}
            optionalColumns={this.state.optionalColumns}
            onSave={this.saveOptionalColumns}
            rootQLType={rootQLType}
          />
          <div className="util-flexGrow util-flexColumn util-fullHeight">
            <TableInner
              {...this.props}
              data={this.state.currentPageData}
              pageInfo={this.state.pageInfo}
              ref={this.handleSetRef}
              onFetchData={this.handleFetchData}
              listState={this.state.listState}
              pageSize={this.state.listState ? this.state.listState.pageSize : 20}
              columns={mergedColumns}
              onDataLoaded={this.handleDataLoaded}
              isLoading={this.state.isLoading}
            />
          </div>
          <div className="util-flexColumn">
            <CrmButton noBorder onClick={this.handleRefresh} icon="refresh" iconButton />
            {this.props.generalPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.EXPORT_DATA) && (
              <CrmButton noBorder onClick={this.handleExportExcel} icon="file-excel-o" iconButton />
            )}
            {this.props.generalPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.BULK_OPERATIONS) && (
              <CrmButton noBorder onClick={this.handleBulkOperation} icon="list" title="Bulk Operations" iconButton />
            )}
            <CrmButton
              noBorder
              onClick={() => this.setState({ isConfigModalOpen: true })}
              icon={faColumns}
              title="Columns"
              iconButton
            />
            <div
              title={this.state.pageInfo ? `Total records = ${this.state.pageInfo.count}` : ''}
              className="util-pointer"
              style={{
                textAlign: 'center'
              }}
            >
              <FontAwesomeIcon icon="info" />
            </div>
          </div>
        </div>
      )
    }
  }

  function mapStateToProps (state) {
    return {}
  }

  const TableOuterWithRedux = connect(mapStateToProps)(
    withBulkOperationsContext(withGeneralPermissionsContextHOC(withCrmContext(TableOuter)))
  )
  return withApollo(TableOuterWithRedux, { withRef: true })
}

export default genericTableFactory
