/* eslint-disable camelcase */
const dashRmsOverview = {
  dashboard_uid: 'dashRmsOverview',
  name: 'RMs Overview',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashRmsOverview'
      }
    ]
  }
}

const dashRmsFunnel = {
  dashboard_uid: 'dashRmsFunnel',
  name: 'RMs Funnel',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashRmsFunnel'
      }
    ]
  }
}

const opportunitiesFunnel = {
  dashboard_uid: 'opportunitiesFunnel',
  name: 'Opportunities Funnel',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'opportunitiesFunnel'
      }
    ]
  }
}

const rmLeadsStats = {
  dashboard_uid: 'rmLeadsStats',
  name: 'RMs Leads Statistics',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'dashRmsLeadsStats'
      }
    ]
  }
}

const opportunitiesStats = {
  dashboard_uid: 'opportunitiesStats',
  name: 'Opportunities Stats',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'opportunitiesStats'
      }
    ]
  }
}

const rmActivitiesHistory = {
  dashboard_uid: 'rmActivitiesHistory',
  name: 'Activities History',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'special_widget',
        code: 'rmActivitiesHistory'
      }
    ]
  }
}

export const dashboards = [
  dashRmsOverview,
  dashRmsFunnel,
  opportunitiesFunnel,
  opportunitiesStats,
  rmLeadsStats,
  rmActivitiesHistory
]
export const mock = 1
