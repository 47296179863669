export const FETCH_REPORTS_SETTING = 'FETCH_REPORTS_SETTING'
export const RECEIVE_REPORTS_SETTING = 'RECEIVE_REPORTS_SETTING'
export const SELECTED_REPORT = 'SELECTED_REPORT'
export const UNSELECTED_REPORT = 'UNSELECTED_REPORT'
export const SAVE_EDITING_REPORT = 'SAVE_EDITING_REPORT'
export const SAVED_EDITING_REPORT = 'SAVED_EDITING_REPORT'
export const EDITING_REPORT = 'EDITING_REPORT'
export const CANCEL_EDITING_REPORT = 'CANCEL_EDITING_REPORT'
export const CREATING_REPORT = 'CREATING_REPORT'
export const CANCEL_CREATING_REPORT = 'CANCEL_CREATING_REPORT'
export const SAVING_REPORT = 'SAVING_REPORT'
export const SAVE_CREATING_REPORT = 'SAVE_CREATING_REPORT'
export const SAVING_REPORT_ERROR = 'SAVING_REPORT_ERROR'

// Reducer
const initialState = {
  lists: [],
  fetch: false,
  editing: false,
  creating: false,
  selected: undefined
}

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTS_SETTING:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_REPORTS_SETTING:
      return {
        ...state,
        fetch: false,
        lists: action.payload.reports
      }
    case SELECTED_REPORT:
      return {
        ...state,
        selected: action.item,
        creating: false,
        editing: false
      }
    case UNSELECTED_REPORT:
      return {
        ...state,
        selected: undefined,
        editing: false
      }
    case EDITING_REPORT:
      return {
        ...state,
        editing: true,
        creating: false
      }
    case CANCEL_EDITING_REPORT:
      return {
        ...state,
        editing: false
      }
    case CREATING_REPORT:
      return {
        ...state,
        creating: true,
        editing: false,
        selected: undefined
      }
    case CANCEL_CREATING_REPORT:
      return {
        ...state,
        creating: false
      }
    case SAVING_REPORT:
      return {
        ...state,
        creating: true
      }
    case SAVE_EDITING_REPORT:
      return { ...state }
    default:
      return state
  }
}

function formatDefinitions(itemSelected) {
  const item = Object.assign({}, itemSelected)
  // console.log('formatDefinitions : ', item.definitions)
  item.definitions = JSON.stringify(JSON.parse(item.definitions), null, 2)
  return item
}

function definitionToJson(values) {
  const report = Object.assign({}, values)
  report.definitions = JSON.parse(report.definitions)
  return report
}

export function fetchData() {
  return {
    type: FETCH_REPORTS_SETTING
  }
}

export function selectedItem(item) {
  return {
    type: SELECTED_REPORT,
    item: formatDefinitions(item)
  }
}

export function unselectedItem() {
  return {
    type: UNSELECTED_REPORT
  }
}

export function startEditingItem() {
  return {
    type: EDITING_REPORT
  }
}

export function cancelEditingItem() {
  return {
    type: CANCEL_EDITING_REPORT
  }
}

export function startCreatingItem() {
  return {
    type: CREATING_REPORT
  }
}

export function cancelCreatingItem() {
  return {
    type: CANCEL_CREATING_REPORT
  }
}

export function saveCreatingItem(formValues) {
  return {
    type: SAVE_CREATING_REPORT,
    formValues: definitionToJson(formValues)
  }
}

export function saveEditingItem(formValues) {
  return {
    type: SAVE_EDITING_REPORT,
    formValues: definitionToJson(formValues)
  }
}
