/* eslint-disable */
import React, { Component } from 'react'
import { shape, func } from 'prop-types'
import { CrmButton } from 'crm-components'
import { getAccountOwnerUser } from 'crm-utils/accounts-utils'
import { CrmModal } from 'crm-components'

class QualifyModal extends Component {
  constructor(props) {
    super(props)
    this.state = { actions: [], isShowMoreActions: false }
  }

  render() {
    const { isVisible, onCancel } = this.props
    return (
      <CrmModal isOpen={isVisible} title="Qualify Account" onClose={onCancel}>
        <div className="row" />
      </CrmModal>
    )
  }
}

QualifyModal.defaultProps = {}
QualifyModal.propTypes = {
  account: shape({}).isRequired,
  onCancel: func.isRequired
}

export default QualifyModal
