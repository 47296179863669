import React from 'react'
import { Route, Redirect } from 'react-router'
import { shape, bool, elementType } from 'prop-types'
import WithEld from 'util-components/with-eld.jsx'
import { PERMISSIONS_TYPES, useGeneralPermissionsContext } from '@cartrack-crm/crm-core'

const ProtectedRoute = ({ component: Component, withEld, ...rest }) => {
  const { hasPermissionByType } = useGeneralPermissionsContext()
  const isSuperAdmin = hasPermissionByType(PERMISSIONS_TYPES.SUPER_ADMIN)
  const isAdmin = hasPermissionByType(PERMISSIONS_TYPES.ADMIN_MANAGE) || hasPermissionByType(PERMISSIONS_TYPES.ADMIN_READ)
  const isManager = hasPermissionByType(PERMISSIONS_TYPES.GENERAL_DATA_MANAGE)
  const isCanAccess = isSuperAdmin || isAdmin || isManager
  const renderWidget = props => {
    const pathName = props.location?.pathname
    if (pathName.includes('/crm/manager') && !isCanAccess) {
      return <Redirect to={{ pathname: '/crm/dashboard/me' }} />
    }
    return window.localStorage.getItem('user') ? (
        withEld ? (
          <WithEld>
            <Component {...props} />
          </WithEld>
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
  }

  return (<Route
    exact
    {...rest}
    // eslint-disable-next-line react/jsx-no-bind
    render={renderWidget}
  />)
  }

ProtectedRoute.propTypes = {
  component: elementType.isRequired,
  location: shape({}),
  withEld: bool
}

ProtectedRoute.defaultProps = {
  location: null,
  withEld: false
}

export default ProtectedRoute
