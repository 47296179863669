import { useCrmToastr, CrmToastrType } from '@cartrack-crm/ui/src/toastrs'

interface optionsConfig {
  successMessage?: string
  errorMessage?: string
  timeToShow?: number
}

export const useRunApiWithToastr = () => {
  const [showToastr] = useCrmToastr()

  const runApiWithToastr = async (
    onRunCommand: Function = null,
    options: optionsConfig = null
  ) => {
    const hasOptions = options !== null
    const haveTimeToShowParam = hasOptions && options.timeToShow
    const successMessage = options.successMessage
    const errorMessage =
      options.errorMessage || 'An error has occurred from your last action.'
    const timeToShowToastr =
      hasOptions && haveTimeToShowParam ? options.timeToShow : 4000
    try {
      const response = await onRunCommand()
      
      if (options.successMessage) {
        showToastr(CrmToastrType.success, successMessage, {
          ...options,
          timeToShow: timeToShowToastr
        })
      }
      return response
    } catch (err) {
      showToastr(CrmToastrType.error, errorMessage, {
        timeToShow: 4000,
        message: err.message
      })
    }
  }

  return [runApiWithToastr]
}
