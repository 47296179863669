/* eslint-disable camelcase */
import React from 'react'
import AccountsList from './accounts-list.jsx'

const PendingAccountsView = () => (
  <AccountsList
    title="Pending Qualification - learn more about this customer"
    fixFilter={[
      {
        code: 'account_info',
        code_option: 'equal',
        params: [{ condition_key: 'kind', condition_value: 'pending' }]
      }
    ]}
    accountsListCode="my_leads"
    disableMoreFilters={['owner']}
    visibleColumns={[
      'name',
      'address',
      'sale',
      'tm',
      'owner',
      'assigned_time',
      'last_activity_date',
      'last_activity_comment',
      'campaign_name'
    ]}
  />
)

export default PendingAccountsView
