const agentDetailsLiveCallActivityWidget = {
  type: 'analitics_widget',
  code: 'agentDetailsCallActivity',
  def_version: 5,
  title: 'Agent Call Activity ',
  content: {
    data: {
      dataSources: [
        {
          code: 'ActivitiesListQM',
          name: 'ActivitiesListQM',
          alias: 'AnsweredCalls',
          type: 'QM',
          filter: {
            status: { is_success: true },
            type: { medium_code: 'phone_call' }
          },
          aggregate: [
            { id: 'activity_uid', type: 'count' },
            { id: 'call_length', type: 'sum' }
          ],
          contextMapping: {
            start_date: 'activity_date',
            performer__department_uid: 'department_uid',
            performer__organization_unit_uid: 'organization_unit_uid'
          },
          groupBy: ['created_by_user_uid'],
          qlEdges: true,
          qlRoot: 'activities',
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid)  {
                master_uid
                name
                instance(instance_uid: $instance_uid) {
                    instance_uid
                    name
                    activities(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                       edges {
                        edge
                        {
                            activity_uid
                            created_by_user_uid
                            call_length
                        }
                       }
                   }
                }
            }
        }`
        },
        {
          code: 'ActivitiesListQM',
          name: 'ActivitiesListQM',
          alias: 'NotAnsweredCalls',
          type: 'QM',
          filter: {
            status: { is_success: false },
            type: { medium_code: 'phone_call' }
          },
          aggregate: [{ id: 'activity_uid', type: 'count' }],
          contextMapping: {
            start_date: 'activity_date',
            performe: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            }
          },
          groupBy: ['created_by_user_uid'],
          qlEdges: true,
          qlRoot: 'activities',
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid)  {
                master_uid
                name
                instance(instance_uid: $instance_uid) {
                    instance_uid
                    name
                    activities(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                       edges {
                        edge
                        {
                            activity_uid
                            created_by_user_uid
                        }
                       }
                   }
                }
            }
        }`
        },
        {
          code: 'ActivitiesListQM',
          name: 'ActivitiesListQM',
          alias: 'TotalCalls',
          type: 'QM',
          fields: ['created_by_user_uid'],
          filter: {
            type: { medium_code: 'phone_call' }
          },
          aggregate: [
            { id: 'activity_uid', type: 'count' },
            {
              id: 'account_uid',
              type: 'count',
              distinct: true,
              alias: 'calls_unique_leads'
            },
            { id: 'call_length', type: 'sum' }
          ],
          contextMapping: {
            start_date: 'activity_date',
            performer: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            },
            created_by_user_uid: 'user'
          },
          qlEdges: true,
          qlRoot: 'activities',
          groupBy: ['created_by_user_uid'],
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid)  {
                master_uid
                name
                instance(instance_uid: $instance_uid) {
                    instance_uid
                    name
                    activities(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                      edges {
                        edge
                        {
                            activity_uid
                            account_uid
                            created_by_user_uid
                            call_length
                            calls_unique_leads
                        }
                      }
                  }
                }
            }
        }`
        },
        {
          code: 'CallsTodayStats',
          name: 'CallsTodayStats',
          type: 'QM',
          qlQuery: `
          query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid)  { 
                master_uid
                name
                instance(instance_uid: $instance_uid) { 
                    instance_uid
                    name
                    activities(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                      edges{ edge {
                        count
                        primary_performer_user_uid
                        first_call_start_time
                        last_call_start_time
                        call_length
                        current_idle_time
                        total_work_time
                      }
                      }
                      pageInfo { count }
                  }
                }
            }
          }
          `,
          qlEdges: true,
          qlVariables: {
            groupBy: ['primary_performer_user_uid'],
            aggregate: [
              { type: 'count', id: 'activity_uid', alias: 'count' },
              {
                type: 'max',
                id: 'start_time',
                alias: 'last_call_start_time'
              },
              {
                type: 'min',
                id: 'start_time',
                alias: 'first_call_start_time'
              },
              { type: 'sum', id: 'call_length' },
              {
                type: 'extract',
                first_field: ' max(start_time)',
                second_field: 'min(start_time)',
                result_type: 'INT',
                alias: 'total_work_time'
              },
              {
                type: 'extract',
                first_field: ' now() ',
                second_field: 'max(start_time)',
                result_type: 'INT',
                alias: 'current_idle_time'
              }
            ]
          },
          qlRoot: 'activities',
          filter: {
            type: { medium_code: 'phone_call' },
            start_date: '$today'
          }
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {},
          qlVariables: {
            limit: 1000
          },
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $limit: Int) {
            master(master_uid: $master_uid)  { 
                master_uid
                name
                instance(instance_uid: $instance_uid) { 
                    instance_uid
                    name
                    users (filter: $filter, limit: $limit) { 
                      user_uid
                      is_active
                      full_name
                      contract_start_date
                      contract_end_date
                      department { 
                        name 
                      }
                    }
                }
            }
          }`,
          qlRoot: 'users'
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyField: 'user_uid',
        mapping: [
          {
            fromDataSourceName: 'AnsweredCalls',
            keyField: 'created_by_user_uid',
            mapping: [
              {
                from: 'activity_uid',
                to: 'answered_calls'
              },
              {
                from: 'call_length',
                to: 'answered_calls_time'
              }
            ]
          },
          {
            fromDataSourceName: 'NotAnsweredCalls',
            keyField: 'created_by_user_uid',
            mapping: [
              {
                from: 'activity_uid',
                to: 'not_answered_calls'
              }
            ]
          },
          {
            fromDataSourceName: 'TotalCalls',
            keyField: 'created_by_user_uid',
            mapping: [
              {
                from: 'activity_uid',
                to: 'total_calls'
              },
              {
                from: 'call_length',
                to: 'total_calls_time'
              },
              {
                from: 'calls_unique_leads',
                to: 'calls_unique_leads'
              }
            ]
          },
          {
            fromDataSourceName: 'CallsTodayStats',
            keyField: 'primary_performer_user_uid',
            mapping: [
              {
                from: 'first_call_start_time',
                to: 'first_call_start_time'
              },
              {
                from: 'last_call_start_time',
                to: 'last_call_start_time'
              },
              {
                from: 'total_work_time',
                to: 'total_work_time'
              },
              {
                from: 'total_call_time',
                to: 'total_call_time'
              },
              {
                from: 'current_idle_time',
                to: 'current_idle_time'
              }
            ]
          },
          {
            fromDataSourceName: 'CrmUser',
            keyField: 'user_uid',
            type: 'left',
            mapping: [
              {
                from: 'full_name'
              },
              {
                from: 'contract_start_date'
              },
              { from: 'contract_end_date' },
              { from: 'department__name' },
              { from: 'is_active' }
            ]
          }
        ]
      }
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          mob_fix_columns: 2,
          props: {
            generate_summary: true,
            generate_average: true,
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 50
              },
              {
                Header: 'RM',
                accessor: 'full_name',
                classNameHandler: row => (row.original.is_active ? '' : 'util-opacity50')
              },
              {
                Header: 'Start Date',
                accessor: 'contract_start_date',
                width: 80
              },
              {
                Header: 'Success Calls',
                accessor: 'answered_calls',
                width: 60,
                type: 'number',
                align: 'right',
                detailsPopup: 'disposition',
                detailsFilter: {
                  status__is_success: true,
                  type__medium_code: 'phone_call',
                  start_date: '$today'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Failed Calls',
                accessor: 'not_answered_calls',
                width: 60,
                type: 'number',
                align: 'right',
                detailsPopup: 'disposition',
                detailsFilter: {
                  status__is_success: false,
                  type__medium_code: 'phone_call',
                  start_date: '$today'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Total Calls',
                accessor: 'total_calls',
                width: 60,
                type: 'number',
                detailsPopup: 'disposition',
                detailsFilter: {
                  type__medium_code: 'phone_call',
                  start_date: '$today'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Dialing Time',
                accessor: 'total_calls_time',
                width: 60,
                type: 'interval',
                align: 'right',
                detailsPopup: 'disposition',
                detailsFilter: {
                  type__medium_code: 'phone_call',
                  start_date: '$today'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Talk Time',
                accessor: 'answered_calls_time',
                width: 60,
                type: 'interval',
                detailsPopup: 'disposition',
                detailsFilter: {
                  status__is_success: true,
                  type__medium_code: 'phone_call',
                  start_date: '$today'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Idle Time',
                accessorFn: row => {
                  const totalWorkTime = !isNaN(Number(row.total_work_time)) ? Number(row.total_work_time) : 0
                  const totalCallTime = !isNaN(Number(row.total_call_time)) ? Number(row.total_call_time) : 0

                  return totalWorkTime - totalCallTime
                },
                type: 'interval',
                align: 'right',
                width: 60
              },
              {
                Header: 'Tasks',
                Cell: () => 'n/a',
                align: 'right',
                type: 'interval',
                width: 60
              },
              {
                Header: 'Current Idle',
                accessor: 'current_idle_time',
                width: 60,
                type: 'interval',
                align: 'right'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}
export default agentDetailsLiveCallActivityWidget
