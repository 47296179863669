import React from 'react'
import { shape, func } from 'prop-types'
import moment from 'moment'
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts'

const Arc = props => {
  // eslint-disable-next-line
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        className="booga"
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 3}
        outerRadius={outerRadius + 12}
        fill={fill}
        opacity={0.4}
      />
    </g>
  )
}

class DashTelesalesAgentItem extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  renderLeft() {
    const { agent } = this.props
    return (
      <div className="util-flexGrow util-flexColumn">
        <div className="util-flexGrow" />
        <div className="util-marginBottom util-textCenter">
          <strong>{agent.user_name}</strong>
        </div>
      </div>
    )
  }

  renderChart() {
    const { agent } = this.props
    const data = [
      { label: 'Success', value: agent.success_calls, fill: '#5cae60' },
      {
        label: 'Failed',
        value: agent.activity_count - agent.success_calls,
        fill: '#ce5239'
      }
    ]
    const renderLabel = props => props.payload.label + ' ' + props.payload.value
    return (
      <div style={{ height: 110, width: '100%' }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart margin={{ top: 30, right: 30, left: 30, bottom: 30 }}>
            <Pie
              data={data}
              activeIndex={this.state.activeIndex}
              activeShape={Arc}
              isAnimationActive
              innerRadius={'40%'}
              outerRadius={'100%'}
              label={renderLabel}
              onMouseEnter={this.handleSectionEnter}
            >
              {data.map(entry => (
                <Cell key={entry.label} fill={entry.fill} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  }

  _renderDisposition = disposition =>
    this._renderStatLine(disposition.disposition_name, disposition.count)

  _renderStatLine(name, value) {
    return (
      <div
        className="util-paddingSm util-flexRow"
        style={{ borderBottom: '1px solid #f5f5f5', alignItems: 'center' }}
      >
        <div className="util-flexGrow">{name}</div>
        <div style={{ fontSize: '1.2em' }}>{value}</div>
      </div>
    )
  }

  renderDispositions() {
    const { agent } = this.props
    console.log('remder dispositions', this.props)
    const dispositions = agent.dispositions
      ? Object.values(agent.dispositions).sort((a, b) =>
          a.disposition_name
            ? a.disposition_name.localeCompare(b.disposition_name)
            : 0
        )
      : []

    return (
      <div>
        <div className="util-textCenter util-textLight">
          Opportunities By Status
        </div>
        <div className="util-textGreen util-textCenter">Success Calls</div>
        {dispositions
          .filter(d => d.call_disposition_is_success)
          .map(this._renderDisposition)}
        <div className="util-textGrey util-textCenter">Failed Calls</div>
        {dispositions
          .filter(d => !d.call_disposition_is_success)
          .map(this._renderDisposition)}
      </div>
    )
  }

  renderStats() {
    const { agent } = this.props

    const callLength = agent.call_length
      ? moment('2015-01-01')
          .startOf('day')
          .seconds(agent.call_length)
          .format('H:mm:ss')
      : 0
    return (
      <div className="util-paddingMd">
        {this._renderStatLine('Success Calls', agent.success_calls)}
        {this._renderStatLine('Call Time', callLength)}
        {this._renderStatLine('Tasks', agent.completed_tasks)}
      </div>
    )
  }
  render() {
    const { agent } = this.props
    console.log('render Agent', agent)

    return (
      <div
        className="CrmMdCard util-pointer util-hooverable"
        onClick={this.props.onClick}
        style={{ paddingTop: 10 }}
      >
        <div className="util-flexRow">
          {this.renderLeft()}
          <div className="util-flexGrow ">
            {this.renderChart()}
            {this.renderStats()}
          </div>
        </div>
        <div className="util-paddingMd">
          <h4 className="util-textCenter">Dispositions</h4>
          {this.renderDispositions()}
        </div>
      </div>
    )
  }
}

DashTelesalesAgentItem.propTypes = {
  agent: shape({}).isRequired,
  onClick: func
}

DashTelesalesAgentItem.defaultProps = {
  onClick: () => {}
}
export default DashTelesalesAgentItem
