import React, { useState } from 'react'
import { shape } from 'prop-types'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { withApollo } from 'react-apollo'
import CrmWidget from 'crm-modules/dashboard/components/crm-widget.jsx'
import moment from 'moment'
import { ResponsiveAreaBump } from '@nivo/bump'
import { withCrmUserContext } from 'crm-core/auth/auth-context'

const ShowUserName = withCrmUserContext(props => {
  console.log('ShowUserName', props)
  return <div> User name : {props.crmUser ? props.crmUser.username : ''}</div>
})
const WidgetRmFitments = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(undefined)

  const handleRefresh = async () => {
    console.log('Refresh')
    const filter = {
      completed_month: {
        $gte: moment()
          .startOf('month')
          .add(-5, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        $lte: moment()
          .startOf('month')
          .format('YYYY-MM-DD')
      },
      status_code: 'completed'
    }

    console.log('Month: ', filter)
    // Load fitments
    const variables = {
      fields: ['completed_month', 'owner_user_uid', 'owner__full_name'],
      aggregate: [{ id: 'order_uid', type: 'count' }],
      rootType: 'OrdersListQM',
      filter
    }
    await setIsLoading(true)
    console.log('Call')
    const res = await props.client.query({
      query: qlqGenericDataQuery,
      variables,
      fetchPolicy: 'no-cache'
    })
    console.log('RESULT', res)
    let data = [...res.data.genericDataQuery.data]
    data.sort((a, b) =>
      moment(b.completed_month).diff(moment(a.completed_month))
    )

    let fitments = res.data.genericDataQuery.data.reduce((a, row) => {
      const res = { ...a }
      if (!res[row.owner__full_name]) {
        res[row.owner__full_name] = {
          id: row.owner__full_name,
          data: []
        }
      }
      res[row.owner__full_name].data.push({
        x: moment(row.completed_month).format('YYYYMM'),
        y: Number(row.count)
      })

      res[row.owner__full_name].data.sort((a, b) => a.x - b.x)

      return res
    }, {})

    fitments = Object.values(fitments)
    console.log('fitments', fitments)
    await setIsLoading(false)
    await setData(fitments)
  }

  return (
    <CrmWidget onRefresh={handleRefresh} title="RM Sales">
      {data && (
        <div style={{ height: 1000, width: 1400 }}>
          <ResponsiveAreaBump
            animate={true}
            data={data}
            margin={{ top: 40, right: 120, bottom: 40, left: 120 }}
            spacing={8}
            colors={{ scheme: 'nivo' }}
            blendMode="multiply"
            startLabel="id"
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -36
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
            }}
          />
        </div>
      )}
      <ShowUserName />
    </CrmWidget>
  )
}
WidgetRmFitments.propTypes = {
  client: shape().isRequied
}

export default withApollo(WidgetRmFitments)
