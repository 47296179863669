import React, { useState } from 'react'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import { FilterSection } from '../tools'
import { formatDateFilterValue } from 'crm-modules/dashboard/dashboard-utils'
import SaleDateFilterDropDown from './sale-date-filter-dropdown'

export const getSaleDateString = filterValues => {
  let saleDateLabel = 'Select Value'
  if (filterValues && filterValues.contract_signed_date && filterValues.contract_signed_date.value) {
    const contract_signed_date = filterValues.contract_signed_date
    if (contract_signed_date.label) {
      saleDateLabel = contract_signed_date.label
    } else {
      saleDateLabel = formatDateFilterValue(contract_signed_date)
    }
  }
  return saleDateLabel
}

export const FilterSectionSaleDate = props => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleDropDown = () => setIsExpanded(!isExpanded)
  const closeDropDown = () => setIsExpanded(false)
  const { filters, setFilters } = useAnalyticsContext()
  return (
    <FilterSection
      name="SaleDate"
      selectedValue={getSaleDateString(filters)}
      onToggle={toggleDropDown}
      isExpanded={isExpanded}
      {...props}
    >
      {isExpanded && (
        <SaleDateFilterDropDown
          filterValues={filters}
          onSetFilters={setFilters}
          isExpanded={isExpanded}
          onClickedOutside={closeDropDown}
        />
      )}
    </FilterSection>
  )
}
