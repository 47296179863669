import gql from 'graphql-tag'

export const qlMinutesFields =
  '  activity_uid meeting_minutes_uid details created_time updated_time completed_at'

export const qlqFetchMeetingMinutes = gql`query
   meeting_minutes($activity_uid :String!) { 
     meeting_minutes: meeting_minutes(activity_uid:$activity_uid) {
      ${qlMinutesFields} 
    }
}`

export const qlmSetMinutesCompleted = gql`
  mutation meeting_minutes_complete($meeting_minutes:meeting_minutes_in, $completed: Boolean) { meeting_minutes_complete(meeting_minutes: $meeting_minutes, completed: $completed)  { ${qlMinutesFields} }  }`

export const qlmMeetingMinutesUpdate = gql`
  mutation  meeting_minutes_save($meeting_minutes:meeting_minutes_in) { meeting_minutes_save(meeting_minutes: $meeting_minutes)  { ${qlMinutesFields} }  } `
// D} ${dictQuery} products : products { name, product_uid }  account : accounts(account_uid:"${account_uid}") { account_uid , name , kind  }  }`
