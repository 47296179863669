import React, { useState } from 'react'
import { CrmModal, CrmJsonPreview } from '@cartrack-crm/ui'
import { useCrmToastr, CrmToastrType, CrmToastrPosition } from '@cartrack-crm/ui/src/toastrs'

interface IModalEditContentSection {
  data?: any
  onSave?: (data: any) => any,
  isOpen?: boolean
  onClose?: () => any
  sectionName?: 'filter'
}
export const ModalEditContentSection: React.FC<IModalEditContentSection> = (props) => {
  const [content, SetContent] = useState(props.data?.content)
  const [showToastr] = useCrmToastr()
  const onSave = () => {
    let pData = { ...props.data }
    try {
      pData.content = { ...content }
      if (props.onSave) props.onSave(pData)
      props?.onClose()
    } catch (err) {
      showToastr(CrmToastrType.error, 'Validate Error', {
        message: err.message,
        timeToShow: 4000,
        position: CrmToastrPosition.topRight
      })
    }
  }
  return (
    <CrmModal
      showFooter
      primaryButtonLabel='Save'
      secondaryButtonLabel='Cancel'
      onPrimaryButton={onSave}
      onSecondaryButton={props?.onClose}
      isOpen={props?.isOpen}
      onClose={props?.onClose}
      size='lg'
    >
      <div className='util-paddingMd' >
        <CrmJsonPreview json={content} onChange={(value) => SetContent(value)} editable rows={25} />
      </div>
    </CrmModal>)
} 