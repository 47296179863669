import React, { PureComponent } from 'react'
import { shape, string, arrayOf, bool, func, node } from 'prop-types'
import { CrmButton, CrmDropdownButton } from 'crm-components'

// eslint-disable-next-line
class CrmFormSection extends PureComponent {
  render() {
    const { actions, hasPadding, className } = this.props
    const hasActions = actions !== undefined && actions.length > 0
    return (
      <div
        className={`CrmFormSection ${hasActions &&
          'CrmFormSection--hasActions'} ${className || ''}
          ${
            this.props.mdCard
              ? 'CrmMdCard util-paddingMd util-marginBottom'
              : ''
          }
          `}
      >
        {!this.props.noHeader && (
          <div className="CrmFormSection-header">
            {this.props.formatMessage(
              this.props.sectionName,
              'ui.section.name'
            )}
            {actions && (
              <div
                className={`${
                  actions.length <= 2
                    ? 'CrmFormSection-actions-wrapper'
                    : 'util-flexRow util-textRight'
                }`}
              >
                {actions.map(action =>
                  action.options ? (
                    <CrmDropdownButton
                      key={action.key ? action.key : action.label}
                      onClick={action.onClick} // eslint-disable-line react/jsx-handler-names
                      label={action.label}
                      icon={action.icon}
                      defaultOption={action.defaultOption}
                      type={action.type ? action.type : 'secondary'}
                      className={`CrmFormSection-action Button--small CrmDropdownButton--grey 
                  ${action.className ? action.className : ''} 
                ${
                  action.type && action.type === 'primary' ? 'Button--blue' : ''
                }`}
                      options={action.options}
                      style={{
                        whiteSpace: 'nowrap',
                        marginLeft: 5,
                        marginRight: 5
                      }}
                    />
                  ) : (
                    <CrmButton
                      key={action.key ? action.key : action.label}
                      onClick={action.onClick} // eslint-disable-line react/jsx-handler-names
                      label={action.label}
                      icon={action.icon}
                      type={action.type ? action.type : 'secondary'}
                      className={`CrmFormSection-action Button--small 
                  ${action.className ? action.className : ''} 
                ${
                  action.type && action.type === 'primary' ? 'Button--blue' : ''
                }`}
                      grouped
                    />
                  )
                )}
              </div>
            )}
          </div>
        )}
        <div
          className={`CrmFormSection-body ${hasPadding &&
            'CrmFormSection-body--hasPadding'}`}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

CrmFormSection.propTypes = {
  sectionName: node,
  children: node,
  actions: arrayOf(shape({ label: node.isRequired, onClick: func })),
  hasPadding: bool,
  formatMessage: func.isRequired,
  className: string,
  mdCard: bool,
  noHeader: bool
}

CrmFormSection.defaultProps = {
  sectionName: '',
  actions: undefined,
  hasPadding: false,
  children: undefined,
  className: undefined,
  mdCard: false,
  noHeader: false
}

export default CrmFormSection
