import React from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import { CrmDropdownButton } from 'crm-components'
import { toastr } from 'react-redux-toastr'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const qlmGenerateReport = gql`
  mutation quotation_print($quotation: quotation_in, $language: String) {
    quotation_print(quotation: $quotation, language: $language) {
      output
      resource {
        resource_uid
        name
        description
        file_link
      }
    }
  }
`
interface IProps {
  qlVariables: any
  client: any
  successMessage: string
  label: string
  crmContext: any
  style: any
}

interface IState {
  isSaving: boolean
}

class PrintQuotationButtonQL extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false
    }
  }
  setStateAsync = (newState) =>
    new Promise((resolve) => {
      this.setState(newState, resolve)
    })

  calculateQuotationTemplateUid = async () => {
    console.log('PrintQuotationButtonQL')
    const crmInstance = JSON.parse(window.localStorage.getItem('crm_instance'))
    let templateUid = ''
    if (
      crmInstance &&
      crmInstance.parameters &&
      crmInstance.parameters.quotation_template_uid
    ) {
      templateUid = await crmInstance.parameters.quotation_template_uid
      console.log('PrintQuotationButtonQL', 'templateUid', templateUid)
      return templateUid
    }

    try {
      const res = await this.props.client.query({
        query: gql(
          `query templates { templates(document_type_code:"quotation") {template_uid code document_type_code is_default  } }`
        )
      })
      console.log('PrintQuotationButtonQL', 'res', res)
      if (
        res &&
        res.data &&
        res.data.templates &&
        res.data.templates.length > 0
      ) {
        const template = await res.data.templates.find(
          (tem) => tem.is_default === true
        )
        templateUid =
          (await template) && template.template_uid ? template.template_uid : ''
        console.log('PrintQuotationButtonQL', 'templateUid', templateUid)
        return templateUid
      }
    } catch (err) {
      toastr.error('Error can not find template')
      console.error(err)
    }
  }

  handleClick = async (event, lang = undefined) => {
    console.log('handle click', lang)
    console.dir(this.props)

    await this.setStateAsync({ isSaving: true })
    const variables = {
      ...this.props.qlVariables
    }

    if (lang) {
      variables.language = lang
    } else {
      variables.language = this.props.crmContext?.instance?.country_code
    }
    if (variables && variables.quotation && !variables.quotation.template_uid) {
      const findTemplateUid = await this.calculateQuotationTemplateUid()
      if (findTemplateUid) {
        variables.quotation.template_uid = findTemplateUid
      }
    }
    console.log('PrintQuotationButtonQL', 'variables', variables)
    try {
      const res = await this.props.client.mutate({
        mutation: qlmGenerateReport,
        variables
      })
      console.log('Completed')
      console.dir(res)
      const message =
        this.props.successMessage &&
        typeof this.props.successMessage === 'string'
          ? this.props.successMessage
          : 'Action completed!'
      toastr.success(message)
      await this.onSuccess(res, { qlVariables: variables })
    } catch (err) {
      toastr.error('Error while performing operation')
      console.error(err)
    }
    await this.setStateAsync({ isSaving: false })
  }

  downloadFileFromLink = (sUrl, fileName) => {
    const link = document.createElement('a')
    link.href = sUrl
    link.setAttribute('target', '_blank')

    if (link.download !== undefined) {
      // Set HTML5 download attribute. This will prevent file from opening if supported.
      link.download = fileName
    }

    // Dispatching click event.
    if (document.createEvent) {
      const e = document.createEvent('MouseEvents')
      e.initEvent('click', true, true)
      link.dispatchEvent(e)
      return true
    }
  }
  onSuccess = (quotation, { qlVariables }) => {
    console.log('Print quotation on Success', quotation, qlVariables)
    console.dir(this)
    const data = quotation.data.quotation_print.output
    const sUrl = 'data:application/pdf;base64,' + data
    const fileName =
      quotation.data.quotation_print.resource &&
      quotation.data.quotation_print.resource.name
        ? quotation.data.quotation_print.resource.name
        : 'Quotation'
    this.downloadFileFromLink(sUrl, fileName)
  }
  render() {
    const label = this.state.isSaving ? 'Saving' : this.props.label
    const options = [
      {
        label: 'Print - English',
        onClick: (e) => this.handleClick(e, 'en')
      },
      {
        label: 'Print - Thai',
        onClick: (e) => this.handleClick(e, 'th')
      },
      {
        label: 'Print - Polish',
        onClick: (e) => this.handleClick(e, 'pl')
      },
      {
        label: 'Print - Portuguese',
        onClick: (e) => this.handleClick(e, 'pt')
      }
    ]
    return (
      <CrmDropdownButton
        small
        options={options}
        label={label}
        onClick={this.handleClick}
        enable={!this.state.isSaving}
        style={this.props.style ? this.props.style : {}}
        isSaving={this.state.isSaving}
      />
    )
  }
}

export default withApollo(withCrmContext(PrintQuotationButtonQL))
