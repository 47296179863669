/* eslint-disable */
import gql from 'graphql-tag'
import { getAPIDateFormat } from 'crm-utils/time-utils'
import uuidv4 from 'uuid'

export const priceTableQL = gql`
  query price_table_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    price_table_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        price_table_uid
        instance_uid
        name
        is_approve
        is_default
        is_deleted
        currency_code
        data
        valid_till
        valid_from
      }
    }
  }
`

export const priceTableItemsQL = gql`
  query find_price_options($query: JSON!) {
    find_price_options(query: $query) {
      price_table_uid
      price_table_item_uid
      data
    }
  }
`

export const PriceTableCycleOptions = [
  { name: 'Monthly', value: '1' },
  { name: 'Quarterly', value: '3' },
  { name: 'Half yearly', value: '6' },
  { name: 'Yearly', value: '12' },
  { name: '3 Yearly', value: '36' },
  { name: '5 Yearly', value: '60' }
]

export const SalesTypeOptions = [
  { name: 'Rental', value: 'rental' },
  { name: 'Standard', value: 'cash' },
  { name: '*', value: 'all' }
]

export const contractLengthOptions = [
  { name: 'Trial', value: 0 },
  { name: 'Month to Month', value: 1 },
  { name: '6 Months', value: 6 },
  { name: '12 Months', value: 12 },
  { name: '24 Months', value: 24 },
  { name: '36 Months', value: 36 },
  { name: '48 Months', value: 48 },
  { name: '60 Months', value: 60 }
]

export const productTypeOptions = [
  { label: 'Product', value: 'product' },
  { label: 'Add-on', value: 'addon' }
]

export const getSalesTypeName = value => {
  return value ? SalesTypeOptions.find(v => v.value === String(value)).name : ''
}

export const getPriceTableCycleName = value => {
  return value
    ? PriceTableCycleOptions.find(v => v.value === String(value)).name
    : ''
}

export const getContractLengthOptionsName = value => {
  return value
    ? contractLengthOptions.find(v => v.value === parseInt(value)).name
    : ''
}

export const formatTotal = value => {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })
}

export function priceTableInitialValues() {
  return {
    is_approve: 'no',
    is_default: 'no',
    items: []
  }
}

export function parsePriceTableDataToForm(data) {
  const items = data?.data?.items ? [...data.data.items] : []
  const itemsTmp = []
  items.map(v => {
    const prices = { ...v.prices[0] }
    prices.first_year_price_per_cycle = String(
      prices.first_year_price_per_cycle
    )
    prices.next_years_price_per_cycle = String(
      prices.next_years_price_per_cycle
    )
    const itemTmp = {
      ...v,
      prices: [prices]
    }
    itemsTmp.push(itemTmp)
  })
  const formatData = { ...data }
  formatData.is_approve = data.is_approve === true ? 'yes' : 'no'
  formatData.is_default = data.is_default === true ? 'yes' : 'no'
  formatData.valid_from = getAPIDateFormat(data.valid_from)
  formatData.valid_till = getAPIDateFormat(data.valid_till)
  delete formatData.data
  return { ...formatData, items: itemsTmp }
}

export function parsePriceTableDataToApi(data) {
  const itemsTmp = []
  data.items.map(v => {
    const prices = { ...v.prices[0] }
    prices.cycle = parseInt(prices.cycle)
    prices.first_year_price_per_cycle = parseFloat(
      prices.first_year_price_per_cycle
    )
    prices.next_years_price_per_cycle = parseFloat(
      prices.next_years_price_per_cycle
    )
    const itemTmp = {
      ...v,
      prices: [prices],
      price_table_item_uid: v.price_table_item_uid
        ? v.price_table_item_uid
        : uuidv4()
    }
    itemsTmp.push(itemTmp)
  })
  return { ...data, items: itemsTmp }
}
