import React from 'react'
import withClickOutSide from 'react-click-outside'
import { DateRangePicker } from 'util-components'
import { func, object, bool } from 'prop-types'
import { AnalyticsContextFilters } from '@cartrack-crm/crm-analytics'
import { TransactionTypeSelect, LeadSourceSelect, LeadSourceTypeSelect } from '.'
import { CampaignSelect } from '../campaign'
import { getDateRangeOptionsWithCustom, getDateRangeByLabel, getAPIDateFormat } from 'crm-utils/time-utils'
import { mergeDeep } from 'crm-modules/dashboard/dashboard-utils'
import {
  GenericDropDownFilter,
  GenericDropDownContainer,
  getLeadInDateString,
  getFirstValue,
  GenericClearFilter,
  FiltersOptionList
} from '../tools'
import { DashboardFiltersDropDownGroup } from '../styled/dropdown-group-style'

type StateType = {
  filterValues: AnalyticsContextFilters
}

class LeadFilterDropdown extends React.PureComponent<any, StateType> {
  myRef: any
  currentMonth: any
  static propTypes = {
    onClickedOutside: func.isRequired,
    onFilterChanged: func,
    isExpanded: bool.isRequired,
    onSetFilters: func.isRequired,
    filterValues: object.isRequired
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.currentMonth = new Date()
    this.state = {
      filterValues: props.filterValues ? { ...props.filterValues } : {}
    }
  }

  componentDidUpdate() {
    this.recalculatePopupMaxWidth()
  }

  componentDidMount() {
    window.addEventListener('resize', this.recalculatePopupMaxWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recalculatePopupMaxWidth)
  }

  componentWillReceiveProps = nextProps => {
    if (JSON.stringify(nextProps.filterValues) !== JSON.stringify(this.props.filterValues)) {
      this.setState({ filterValues: { ...nextProps.filterValues } })
    }
  }

  handleClickOutside = () => {
    if (this.props.isExpanded) {
      this.saveValue()
      setTimeout(this.props.onClickedOutside, 5)
    }
  }

  handleClickApply = () => {
    this.handleClickOutside()
  }

  handleClickCancel = () => {
    setTimeout(this.props.onClickedOutside, 5)
  }

  saveValue = () => {
    this.props.onSetFilters(this.state.filterValues)
  }

  updateFilter = newFilter => {
    const newFilterValues = mergeDeep({}, this.state.filterValues, newFilter)
    this.setState({
      filterValues: newFilterValues
    })
  }

  handleDateRangeChanged = (dateRange, selectedDateRangeOption) => {
    const newFilterValues = { ...this.state.filterValues }

    if (dateRange && dateRange.from && dateRange.to) {
      newFilterValues.lead_in_date = {
        value: {
          $gte: getAPIDateFormat(dateRange.from),
          $lte: getAPIDateFormat(dateRange.to)
        },
        calendarValue: {
          from: getAPIDateFormat(dateRange.from),
          to: getAPIDateFormat(dateRange.to)
        },
        label: selectedDateRangeOption ? selectedDateRangeOption.label : null,
        valueCode: selectedDateRangeOption ? selectedDateRangeOption.value : undefined
      }
    } else {
      newFilterValues.lead_in_date = undefined
    }

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleDateRangeLabelSelect = selectedDateRangeOption => {
    const dateRange = selectedDateRangeOption ? getDateRangeByLabel(selectedDateRangeOption.value, true) : undefined
    this.handleDateRangeChanged(dateRange, selectedDateRangeOption)
  }

  handleClearFilters = () => {
    const newFilterValues = { ...this.state.filterValues }
    newFilterValues.account = undefined
    newFilterValues.lead_in_date = undefined
    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilter = filterName => {
    const newFilterValues = { ...this.state.filterValues }
    const leadSourceConditions = ['lead_source_uid', 'kind']
    const leadCampaignConditions = ['campaign_uid', 'transaction_type_code']

    if (filterName === 'account_type_code') {
      newFilterValues.account.transaction_type_code = undefined
    } else if (leadSourceConditions.includes(filterName)) {
      newFilterValues.account.lead_campaign.lead_source[filterName] = undefined
    } else if (leadCampaignConditions.includes(filterName)) {
      newFilterValues.account.lead_campaign[filterName] = undefined
    } else {
      newFilterValues[filterName] = undefined
    }

    this.setState({
      filterValues: newFilterValues
    })
  }

  recalculatePopupMaxWidth = () => {
    if (this.myRef && this.myRef.current) {
      const left = this.myRef.current.getBoundingClientRect().left
      this.myRef.current.style.maxWidth = `calc(100vw - ${left + 20}px)`
    }
  }

  render() {
    return (
      <GenericDropDownContainer ref={this.myRef}>
        <GenericDropDownFilter
          label="Transaction Type"
          value={this.state.filterValues?.account?.lead_campaign?.transaction_type_code?.label}
          onClearFilter={() => this.handleClearFilter('transaction_type_code')}
        >
          <TransactionTypeSelect
            onFilterValueChanged={newValue =>
              this.updateFilter({
                account: {
                  lead_campaign: {
                    transaction_type_code: newValue
                      ? {
                          value: newValue?.value,
                          label: newValue?.label
                        }
                      : null
                  }
                }
              })
            }
            selectedValue={this.state.filterValues?.account?.lead_campaign?.transaction_type_code?.value}
          />
        </GenericDropDownFilter>
        <GenericDropDownFilter
          label="Lead Source Type"
          value={this.state.filterValues?.account?.lead_campaign?.lead_source?.kind?.label}
          onClearFilter={() => this.handleClearFilter('kind')}
        >
          <LeadSourceTypeSelect
            onFilterValueChanged={newValue =>
              this.updateFilter({
                account: {
                  lead_campaign: {
                    lead_campaign_uid: null,
                    lead_source: { lead_source_uid: null, kind: newValue }
                  }
                }
              })
            }
            filterValues={this.state.filterValues}
          />
        </GenericDropDownFilter>
        <GenericDropDownFilter
          label="Lead Source"
          value={
            this.state.filterValues && this.state.filterValues.account?.lead_campaign?.lead_source?.lead_source_uid
              ? this.state.filterValues.account?.lead_campaign?.lead_source?.lead_source_uid?.label
              : 'All'
          }
          onClearFilter={() => this.handleClearFilter('lead_source_uid')}
        >
          <LeadSourceSelect
            onFilterValueChanged={newValue =>
              this.updateFilter({
                account: {
                  lead_campaign: {
                    campaign_uid: null,
                    lead_source: newValue ? { lead_source_uid: newValue } : undefined
                  }
                }
              })
            }
            filterValues={this.state.filterValues}
          />
        </GenericDropDownFilter>
        <GenericDropDownFilter
          label="Lead Campaign"
          value={this.state.filterValues?.account?.lead_campaign?.campaign_uid?.label}
          onClearFilter={() => this.handleClearFilter('campaign_uid')}
        >
          <CampaignSelect
            onFilterValueChanged={newValue =>
              this.updateFilter({
                account: {
                  lead_campaign: {
                    campaign_uid: newValue ? getFirstValue(newValue) : null
                  }
                }
              })
            }
            type="lead_source"
            filterValues={this.state.filterValues}
          />
        </GenericDropDownFilter>
        <GenericDropDownFilter
          label="Account Type"
          value={this.state.filterValues?.account?.transaction_type_code?.label}
          onClearFilter={() => this.handleClearFilter('account_type_code')}
        >
          <TransactionTypeSelect
            onFilterValueChanged={newValue =>
              this.updateFilter({
                account: {
                  transaction_type_code: newValue ? getFirstValue(newValue) : null
                }
              })
            }
            selectedValue={getFirstValue(this.state.filterValues?.account?.transaction_type_code?.value)}
          />
        </GenericDropDownFilter>
        <GenericDropDownFilter
          label="Lead in Date"
          value={getLeadInDateString(this.state.filterValues)}
          onClearFilter={() => this.handleClearFilter('lead_in_date')}
        >
          <FiltersOptionList
            options={getDateRangeOptionsWithCustom(['this_year'])}
            onFilterValueChanged={this.handleDateRangeLabelSelect}
            selectedValue={
              this.state.filterValues?.lead_in_date ? this.state.filterValues.lead_in_date.valueCode : undefined
            }
          />
        </GenericDropDownFilter>
        <DashboardFiltersDropDownGroup className="util-marginLeft" style={{ width: 480 }}>
          <DateRangePicker
            value={
              this.state.filterValues &&
              this.state.filterValues.lead_in_date &&
              this.state.filterValues.lead_in_date.calendarValue
                ? this.state.filterValues.lead_in_date.calendarValue
                : undefined
            }
            onChange={this.handleDateRangeChanged}
            initialMonth={this.currentMonth}
            showDateTextInput={false}
          />
        </DashboardFiltersDropDownGroup>
        <GenericClearFilter
          onApply={this.handleClickApply}
          onCancel={this.handleClickCancel}
          onClearFilters={this.handleClearFilters}
        />
      </GenericDropDownContainer>
    )
  }
}

export default withClickOutSide(LeadFilterDropdown)
