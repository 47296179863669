/* eslint-disable camelcase */
import obj2arg from 'graphql-obj2arg'
import { callQLApi, callQLApiFormDataNew } from '../api-utils'

export const qlResource =
  ' resource_uid name description size mime_type is_deleted account_uid activity_uid created_time file_link parsed meta'

export default {
  create: params => {
    console.log('API', params)
    const fileName = params.fileName ? params.fileName : null
    const meta = params.meta ? params.meta : {}

    // eslint-disable-next-line
    const formData = new FormData()
    formData.append(
      'query',
      ` mutation  resource_create($resource:resource_in) { resource_create(resource: $resource)  { ${qlResource} }  } `
    )

    const resourceData = {
      description: params.description,
      file_field_name: 'files',
      meta: JSON.stringify(meta),
      name: fileName,
      account_uid: params.account_uid
    }

    if (params.access_mode) {
      resourceData.access_mode = params.access_mode
    }

    formData.append(
      'variables',
      `{ "resource":  ${JSON.stringify(resourceData)} }`
    )
    formData.append('files', params.file)

    console.log('resource_create ql create: ', formData)
    return callQLApiFormDataNew(formData).then(res => res)
  },
  update: params => {
    const qlParams = {
      query: ` mutation  resource_update($resource:resource_in) { resource_update(resource: $resource)  { ${qlResource} }  } `,
      variables: {
        resource: {
          resource_uid: params.resource_uid,
          description: params.description,
          name: params.name,
          is_deleted: params.is_deleted
        }
      }
    }
    console.log('resource_update ql update: ', qlParams)
    return callQLApi(qlParams).then(res => res.resource_update)
  },
  delete: params => {
    const qlParams = {
      query: ` mutation resource_delete($resource:resource_in) { resource_delete(resource: $resource)  { ${qlResource} }  } `,
      variables: {
        resource: {
          resource_uid: params.resourceUid
        }
      }
    }
    console.log('resource_delete ql delete: ', qlParams)
    return callQLApi(qlParams).then(res => res)
  },
  fetch: params => {
    let query = ` { resources {  ${qlResource} } } `
    if (params) {
      query = ` { resources(tag: ${obj2arg(params)} ) {  ${qlResource} } } `
    }
    console.log('resources ql : ', query)
    return callQLApi(query).then(res => res.resources)
  }
}
