import React, { PureComponent } from 'react'
import { Formik, Form, FieldArray } from 'formik'
import { FormattedMessage } from 'react-intl'
import SectionOverview from './_section-overview'
import SectionAddresses from './_section-addresses'
import SectionPersons from './_section-persons'
import { CrmButton, CrmCheckbox } from '../../../../../components/index'
import Spacer from '../../../../../../util-components/spacer.jsx'
import { withApollo } from 'react-apollo'
import { getAPIDateFormat } from '../../../../../utils/time-utils'
import { CrmLoadingIcon } from 'crm-components'

interface IProps {
  account?: any
  isSaving: boolean
  onCancelAdding?: Function
  duplicates?: any
  didCheckForDuplicates: boolean
  isCheckingDuplicates?: boolean
  initialValues: any
  accountCategory: string
  pageSize?: string
  onSubmit: Function
  client: any
}

interface IState {
  accountId: String
  isOpenMetaModal: Boolean
  isKpisVisible: Boolean
  errorDuplicates: any
  confirmDuplicate: Boolean
}

class AddAccountForm extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      accountId: '',
      isOpenMetaModal: false,
      isKpisVisible: false,
      errorDuplicates: {
        basicInfo: null,
        contactPerson: null
      },
      confirmDuplicate: false
    }
  }

  static defaultProps = {
    account: undefined,
    isSaving: false,
    onCancelAdding: undefined,
    duplicates: undefined,
    isCheckingDuplicates: false,
    pageSize: 'large'
  }

  handleCancelEdit = event => {
    event.preventDefault()
    this.props.onCancelAdding(event)
  }

  saveIntoLocal = values => {
    const data = {
      newAccount: {
        ...values,
        lead_in_date: getAPIDateFormat(values.lead_in_date),
        category: this.props.accountCategory,
        transaction_type: this.props.accountCategory,
        __typename: 'NewAccount'
      }
    }
    this.props.client.writeData({ data })
  }

  onFormChange = values => {
    this.saveIntoLocal(values)
  }

  onDuplicateResultChanged = (section, duplicate) => {
    const errorDuplicates = { ...this.state.errorDuplicates }
    errorDuplicates[section] = duplicate
    this.setState({ errorDuplicates }, () => {})
  }

  hasOneOrMoreDuplicate = () => {
    let hasDuplicate = false
    const basicInfo = this.state.errorDuplicates.basicInfo
    if (basicInfo) {
      if (basicInfo.name && basicInfo.name.duplicate === true)
        hasDuplicate = true
      if (
        basicInfo.regitration_number &&
        basicInfo.regitration_number.duplicate === true
      )
        hasDuplicate = true
      for (var key in basicInfo) {
        if (basicInfo.hasOwnProperty(key)) {
          if (basicInfo[key].duplicate === true) hasDuplicate = true
        }
      }
    }

    const personCT = this.state.errorDuplicates.personContactDetails
    if (personCT) {
      for (var key in personCT) {
        if (personCT.hasOwnProperty(key)) {
          if (personCT[key].duplicate === true) hasDuplicate = true
        }
      }
    }

    return hasDuplicate
  }

  isValidForm = (values, formErrors) => {
    const hasFormError =
      Object.keys(formErrors).length > 0 && !formErrors.apiError
    if (!values.name || !values.lead_campaign_uid || hasFormError) {
      return false
    }
    return true
  }

  handleConfirmDuplicate = () => {
    this.setState({ confirmDuplicate: !this.state.confirmDuplicate })
  }

  render() {
    const { account, initialValues, accountCategory, pageSize } = this.props
    const isB2C = accountCategory === 'B2C'

    if (account) {
      return (
        <Formik
          enableReinitialize
          initialValues={{ ...initialValues }}
          validate={values => {
            let errors: any = {}
            if (!values.name) errors.name = '* required'
            if (!values.lead_campaign_uid)
              errors.lead_campaign_uid = '* required'
            return errors
          }}
          onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
            const res = await this.props.onSubmit(values)
            setSubmitting(false)
            if (res.isError) {
              setErrors({ apiError: res.error })
            } else {
              setStatus({ success: true })
            }
          }}
        >
          {({
            values,
            errors,
            status,
            handleSubmit,
            submitForm,
            isSubmitting,
            setFieldValue,
            handleChange
          }) => {
            this.onFormChange(values)

            const isValidForm = this.isValidForm(values, errors)
            const hasDuplicate = this.hasOneOrMoreDuplicate()
            const hasDuplicateAndConfirm =
              hasDuplicate && this.state.confirmDuplicate

            return (
              <Form className="CrmForm">
                <div className="util-fullHeight util-flexColumn">
                  <div className="row">
                    <div
                      className={`${
                        pageSize === 'small' ? 'col-md-12' : 'col-md-5'
                      }`}
                    >
                      <SectionOverview
                        account={account}
                        accountCategory={accountCategory}
                        values={values}
                        errors={errors}
                        onDuplicateResultChanged={this.onDuplicateResultChanged}
                      />
                    </div>
                    <div
                      className={`${
                        pageSize === 'small' ? 'col-md-12' : 'col-md-7'
                      }`}
                    >
                      <div
                        className={`${
                          pageSize === 'small'
                            ? 'AddAccountForm--small'
                            : 'AddAccountForm--large'
                        }`}
                      >
                        <FieldArray
                          name="addresses"
                          render={arrayHelpers => {
                            return (
                              <SectionAddresses
                                arrayHelpers={arrayHelpers}
                                values={values}
                                errors={errors}
                                account={account}
                                accountCategory={accountCategory}
                                hasPrimaryAddress={false}
                              />
                            )
                          }}
                        />
                        <Spacer height="20px" />
                        {!isB2C && (
                          <FieldArray
                            name="persons"
                            render={arrayHelpers => {
                              return (
                                <SectionPersons
                                  arrayHelpers={arrayHelpers}
                                  values={values}
                                  errors={errors}
                                  accountCategory={accountCategory}
                                  onDuplicateResultChanged={
                                    this.onDuplicateResultChanged
                                  }
                                />
                              )
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="util-flexGrow" />

                  {/* Buttons */}
                  <div className="AddAccountForm-Buttons">
                    <div
                      className={`${
                        pageSize === 'small'
                          ? 'util-flexRowCenter'
                          : 'util-flexRowRight'
                      }`}
                    >
                      <div className={``}>
                        {hasDuplicate && (
                          <div className="util-flexColumn">
                            <div className="util-textWarning util-textBold">
                              <FormattedMessage
                                id="crm.ui.account.form.one_or_more_duplicate_were_found"
                                defaultMessage="One or more duplicate were found. Please check and confirm."
                              />
                            </div>
                            <div className="util-textWarning">
                              <CrmCheckbox
                                input={{
                                  value: this.state.confirmDuplicate,
                                  onChange: this.handleConfirmDuplicate
                                }}
                                label={
                                  <FormattedMessage
                                    id="crm.ui.account.form.i_confirm_create_new_account"
                                    defaultMessage="I confirm create new account."
                                  />
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className={`${
                            pageSize === 'small'
                              ? 'util-flexRowRight'
                              : 'util-flexRowRight'
                          }`}
                        >
                          <div>
                            <CrmButton
                              type="secondary"
                              label={
                                <FormattedMessage
                                  id="crm.ui.button.cancel"
                                  defaultMessage="Cancel"
                                />
                              }
                              onClick={this.handleCancelEdit}
                            />
                          </div>
                          <div className="util-marginLeft">
                            <CrmButton
                              type="primary"
                              className="AddAccountForm-Submit"
                              label={
                                <FormattedMessage
                                  id="crm.ui.button.create_new_account"
                                  defaultMessage="Create Account"
                                />
                              }
                              isSaving={this.props.isSaving}
                              enable={
                                isValidForm &&
                                (!hasDuplicate || hasDuplicateAndConfirm) &&
                                !this.props.isSaving
                              }
                              onClick={handleSubmit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      )
    }
    return (
      <div className="util-fullHeight util-flexCenterContent util-textCenter">
        <div>
          <CrmLoadingIcon />
        </div>
      </div>
    )
  }
}

export default withApollo(AddAccountForm)
