import { formatDateFilterValue } from 'crm-modules/dashboard/dashboard-utils'
export const getActivityDateString = (filterValues) => {
  let activityDateLabel = 'Select Value'
  if (
    filterValues &&
    filterValues.activity_date &&
    filterValues.activity_date.value
  ) {
    const activity_date = filterValues.activity_date
    if (activity_date.label) {
      activityDateLabel = activity_date.label
    } else {
      activityDateLabel = formatDateFilterValue(activity_date)
    }
  }
  return activityDateLabel
}