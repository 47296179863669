import React, { Component } from 'react'
import { func } from 'prop-types'
import ImportToolReduxForm from './import-tool-form.jsx'

class ImportToolCreateForm extends Component {
  constructor(props) {
    super(props)
    this.file = undefined
    this.handleSave = this.handleSave.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
  }

  handleSave = async values => {
    if (this.file) {
      const resourceValue = {}
      resourceValue.description = values.description
      resourceValue.fileName = values.file_name
      resourceValue.name = values.name
      resourceValue.flag = 'import_tool'
      resourceValue.file = this.file
      resourceValue.meta = {
        tags: ['import']
      }
      await this.props.onSave(resourceValue)
    }
  }

  handleUpload(file) {
    console.log('handleUpload values : ', file)
    this.file = file
  }

  render() {
    return (
      <div>
        <ImportToolReduxForm
          initialValues={{ fileupload: {} }}
          editing={false}
          creating="true"
          onSubmit={this.handleSave}
          onUpload={this.handleUpload}
        />
      </div>
    )
  }
}

ImportToolCreateForm.propTypes = {
  onSave: func.isRequired
}

export default ImportToolCreateForm
