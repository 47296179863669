import React, { PureComponent } from 'react'
import cloneDeep from 'clone-deep'
import moment from 'moment'
import GenericAnalyticsTableWidget from './_generic-analytics-details-table'
import { getDimensionsForKpis } from './_generic-widget-utils'
import TopValuesChartWidget from './top-values-chart-widget'
import SingleNumberWidget from './single-number-widget'
import AnalyticsPivotTableWidget from './_analytics-pivot-table-widget'
import genericWidgetQLHoc from './generic-widget-hoc'
import CrmGenericWidget from './web/generic-widget-web'
import { withAnalyticsContextHoc } from '../context/context'
import { CrmCheckbox, CrmButton } from '@cartrack-crm/ui'
import { get } from 'lodash'

const CrmTable = props => <div>TODO move CrmTable to crm-ui rom crm-components</div>
const LoadingIcon = props => <div> LoadingIcon </div>
const Checkbox = CrmCheckbox

export const resolvePathStringFromObject = (object, path, defaultValue) =>
  path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object)

export class GenericAnalyticsWidget extends PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = this._buildStateFromProps(props)
  }

  _buildStateFromProps(props) {
    const availableDimensions = get(props, 'widgetDefinition.kpis', false)
      ? getDimensionsForKpis(props.widgetDefinition.kpis, props.availableKpis, props.availableKpis)
      : []
    const state = {
      availableDimensions,
      columnsDims: get(props, 'widgetDefinition.columns_dims', undefined),
      rowsDims: get(props, 'widgetDefinition.rows_dims', undefined),
      isDefinitionVisible: false,
      widgetDefinition: cloneDeep(props.widgetDefinition),
      isLoading: false,
      showAnalyticsTable: get(props, 'widgetDefinition.layout', null) !== 'pivot' ? false : true,
      showDetailsTable: get(props, 'widgetDefinition.layout', null) === 'details' ? true : false,
      filters: get(props, 'filters', get(props, 'widgetDefinition.filters', undefined)),
      title: get(props, 'title', get(props, 'widgetDefinition.title', undefined)),
      fetchDetails: false
    }

    return state
  }

  componentDidMount() {
    this._parseWidgetDefinition(this.props.widgetDefinition, undefined)
  }

  componentWillMount() {
    this.refreshData()
  }

  componentWillReceiveProps(nextProps, nextState) {
    const nextPropsWd = get(nextProps, 'widgetDefinition', null),
      nextStateFilters = get(nextState, 'filters', null)

    if (nextStateFilters !== this.state.filters || nextProps.analyticsContext !== this.props.analyticsContext) {
      this.refreshData()
    }

    if (nextPropsWd !== this.props.widgetDefinition) {
      this._parseWidgetDefinition(nextPropsWd, true)
    }
  }

  _parseWidgetDefinition(widgetDefinition, resetData) {
    const availableDimensions = getDimensionsForKpis(
      this.props.widgetDefinition.kpis,
      this.props.availableKpis,
      this.props.availableKpis
    )
    const newState = {
      availableDimensions,
      widgetDefinition: cloneDeep(widgetDefinition)
    }

    if (resetData) {
      this.setState({ ...newState, tableData: undefined }, () => {
        this.refreshData()
      })
    } else {
      this.setState(newState)
    }
  }

  toggleFetchDetails = () => {
    this.setState({ fetchDetails: !this.state.fetchDetails })
  }

  handleToggleDefinitionVisible = () => {
    this.setState({
      isDefinitionVisible: !this.state.isDefinitionVisible
    })
  }

  toggleIsLoading = isLoading => {
    this.setState({ ...this.state, isLoading })
  }

  refreshData = () => {
    if (!this.state.widgetDefinition || (this.state.widgetDefinition && this.state.widgetDefinition.def_version >= 5))
      return

    const { widgetDefinition } = this.state

    if (widgetDefinition.version === 3) {
      this.props.onFetchData(widgetDefinition)
      return
    }

    this.toggleIsLoading(true)
  }

  handleRefreshClick = () => {
    this.refreshData()
  }

  handleFiltersChange = filters => {
    this.setState({ ...this.state, filters })
  }

  _buildDateColumnsForMonth(startDate, endDate) {
    const columns = []
    let m = moment(startDate).startOf('day')
    const mEnd = moment(endDate).startOf('day')

    while (m.isSameOrBefore(mEnd)) {
      columns.push({
        label: m.format('DD'),
        value: m.format('YYYY-MM-DD')
      })
      m = m.add(1, 'days')
    }

    return columns
  }

  _getDimDefByCode(code) {
    return this.props.dimensionsDefinitions.find(d => d.code === code)
  }

  _findFilterByDimCode(dim_code) {
    return this.state.filters ? this.state.filters.find(f => f.dim_code === dim_code) : undefined
  }

  _buildColumnsForDim(colDimDef, data) {
    let columns = []

    if (colDimDef.dim_code) {
      const dimDef = data.dimensions[colDimDef.dim_code]

      if (!dimDef) return

      columns = Object.keys(dimDef.values).map(k => {
        const val = dimDef.values[k]
        return { ...val }
      })
    }

    return columns
  }

  _buildColumns(data) {
    const columnsByLevels = []

    if (!this.state.widgetDefinition || !this.state.widgetDefinition.columns_dims) {
      return []
    }

    for (let i = this.state.widgetDefinition.columns_dims.length - 1; i >= 0; i--) {
      const levelColumns = this._buildColumnsForDim(this.state.widgetDefinition.columns_dims[i], data)
      columnsByLevels.push(levelColumns)
    }

    return columnsByLevels[0]
  }

  _parseData(data) {
    const columns = this._buildColumns(data)
    const tableData = this._mapValuesToTable(
      data,
      this.state.widgetDefinition.columns_dims,
      this.state.widgetDefinition.rows_dims,
      columns
    )

    this.setState({
      ...this.state,
      tableData,
      data,
      dimensions: data.dimensions
    })
  }

  getDimValue(dimensions, dim_code, value) {
    return get(dimensions, `${dim_code}.values.${value}`, { value, label: value })
  }

  _mapValuesToTable(data, colDim, rowDim, pColumns) {
    if (!data) return

    const { dimensions, values } = data
    let columns

    if (pColumns) {
      columns = pColumns
    }

    const tableData = { rows: [], rowsByKey: {}, columns }
    const summaryRow = { code: 'sum', values: {}, sum: 0 }

    const increaseSummary = (key, value) => {
      if (!Object.prototype.hasOwnProperty.call(summaryRow.values, key)) {
        summaryRow.values[key] = { value: 0 }
      }

      summaryRow.values[key].value = Number(summaryRow.values[key].value) + Number(value)
      summaryRow.sum = Number(summaryRow.sum) + Number(value)
    }

    const _buildNewRowForKey = (parent, keyValue, dim_code) => {
      const rowDimDef = this._getDimDefByCode(dim_code)
      const keyVal = this.getDimValue(dimensions, dim_code, keyValue)
      const row = {
        values: {},
        rows: [],
        rowsByKey: {},
        rowKey: {
          dim_code,
          value: keyVal,
          dim_def: rowDimDef,
          label: keyVal ? keyVal.label : 'empty'
        },
        sum: 0
      }

      parent.rowsByKey[keyValue] = row // eslint-disable-line
      tableData.rows.push(row)

      return row
    }

    const getRowForKey = (parent, keyValue, rowKey) => {
      if (!parent.rowsByKey[keyValue]) {
        _buildNewRowForKey(parent, keyValue, rowKey)
      }

      return parent.rowsByKey[keyValue]
    }

    const getTableRowForData = data => {
      let row = {}

      for (let i = 0; i < this.state.widgetDefinition.rows_dims.length; i++) {
        const rowDim = this.state.widgetDefinition.rows_dims[i]
        const rowDimValue = get(data, `dims.${rowDim.dim_code}.value`, undefined)

        if (i === 0) {
          row = getRowForKey(tableData, rowDimValue, rowDim.dim_code)
        } else {
          const nrow = getRowForKey(row, rowDimValue, rowDim.dim_code)
          row = nrow
        }
      }

      return row
    }

    values.forEach(sourceRow => {
      const row: any = getTableRowForData(sourceRow)
      let rowValues = row.values

      for (let colLevel = 0; colLevel < this.state.columnsDims.length; colLevel++) {
        const colDimCode = this.state.columnsDims[colLevel].dim_code
        const colDimValue = get(sourceRow, `dims.${colDimCode}.value`, undefined)

        if (colDimValue) {
          if (colLevel === this.state.columnsDims.length - 1) {
            rowValues[colDimValue] = { value: sourceRow.value.value }
          } else {
            const rowValue = { values: {} }
            rowValues[colDimValue] = rowValue
            rowValues = rowValue
          }
        }

        row.sum = Number(row.sum) + Number(sourceRow.value.value)

        if (colLevel === 0) {
          increaseSummary(colDimValue, sourceRow.value.value)
        }
      }
    })

    tableData.rows.push(summaryRow)

    return tableData
  }

  _renderTable(tableData) {
    if (!tableData.columns) {
      return <div>Data parse error</div>
    }

    return <CrmTable data={tableData.rows} minRows={0} />
  }

  renderFilters() {
    return <div />
  }

  handleSaveDefinition = async newDef => {
    if (this.props.onSaveWidgetDefinition) {
      const savedDef = await this.props.onSaveWidgetDefinition(newDef)
      this.setState({ widgetDefinition: savedDef })
    }
  }

  handleToggleShowTable = () => {
    this.setState({
      ...this.state,
      showDetailsTable: !this.state.showDetailsTable
    })
  }

  _renderWidgetHeader() {
    return (
      <div className="Widget-header CrmWidget-header">
        <div className="CrmWidget-title">{this.props.title}</div>
        <div className="CrmWidget-options CrmWidget-tools">
          <div className=" util-flexRow ">
            {this.renderFilters()}
            <Checkbox
              extraClassNames={{ containerClassNames: 'util-marginRight' }}
              input={{
                onChange: this.handleToggleShowTable,
                value: this.state.showDetailsTable
              }}
              label="Show Table"
            />
            <Checkbox
              extraClassNames={{ containerClassNames: 'util-marginRight' }}
              className="util-marginRight"
              input={{
                onChange: this.toggleFetchDetails,
                value: this.state.fetchDetails
              }}
              label="Details"
            />

            <CrmButton style={{ marginLeft: 5, marginRight: 5 }} icon="refresh" onClick={this.handleRefreshClick} />
            <CrmButton
              style={{ marginLeft: 5, marginRight: 5 }}
              icon="cogs"
              onClick={this.handleToggleDefinitionVisible}
            />
          </div>
        </div>
        {this.state.isLoading && <LoadingIcon />}
      </div>
    )
  }

  handleShowWidgetDefinition = () => {
    this.setState({ isDefinitionVisible: true })
  }

  renderContent = () => {
    const contentProps = {
      dataSourceData: this.props.dataSourceData,
      widgetDefinition: this.state.widgetDefinition
    }

    if (this.props.widgetDefinition.layout === 'single_number') {
      return (
        <SingleNumberWidget
          {...contentProps}
          tableData={this.state.tableData}
          style={this.props.style}
          onShowWidgetConfig={this.handleShowWidgetDefinition}
          widgetDefinition={this.state.widgetDefinition}
          onRefresh={this.refreshData} // eslint-disable-line
        />
      )
    }

    if (this.props.widgetDefinition.layout === 'top_values') {
      return (
        <TopValuesChartWidget
          {...contentProps}
          tableData={this.state.tableData}
          style={this.props.style}
          onRefresh={this.refreshData} // eslint-disable-line
        />
      )
    }

    if (this.props.widgetDefinition.layout === 'flat_table') {
      return (
        <GenericAnalyticsTableWidget
          {...contentProps}
          getDimValue={this.getDimValue}
          data={this.state.data}
          dimensions={this.state.dimensions}
          analyticsContext={this.props.analyticsContext}
        />
      )
    }

    if (this.props.widgetDefinition.layout === 'pivot_table') {
      return (
        <AnalyticsPivotTableWidget
          {...contentProps}
          widgetDefinition={this.state.widgetDefinition}
          onRefresh={this.refreshData}
        />
      )
    }

    return <div> Unknown widget format </div>
  }
  _renderVer5() {
    let widgetDefinition = cloneDeep(
      this.state.widgetDefinition ? this.state.widgetDefinition : this.props.widgetDefinition
    )

    if (widgetDefinition.content) {
      widgetDefinition = { ...widgetDefinition, ...widgetDefinition.content }
    }

    return (
      <CrmGenericWidget
        {...this.props}
        title={widgetDefinition.name}
        description={widgetDefinition.description}
        widgetDefinition={widgetDefinition}
        analyticsContext={this.props.analyticsContext}
        onEditWidget={this.handleToggleDefinitionVisible}
        onSaveDefinition={this.handleSaveDefinition}
        fullHeight={this.props.fullHeight}
      >
        {this._renderDefinitionWidget()}
      </CrmGenericWidget>
    )
  }

  _renderDefinitionWidget = () => <span />

  _getFilterSelected = () => {
    const staticFilters = this.props.filters
    const analyticFilters = this.props.analyticsContext?.filters

    return { ...staticFilters, ...analyticFilters }
  }

  _checkFilterSelected = requiredFilters => {
    const errorMessages = []
    const filters = this._getFilterSelected()

    requiredFilters.map(filter => {
      const result = resolvePathStringFromObject(filters, filter.filter, undefined)

      if (!result) {
        errorMessages.push(filter.message)
      }
    })

    return errorMessages.length > 0 ? errorMessages : null
  }

  render() {
    const requiredFilters = this.props.widgetDefinition?.content?.requiredFilters

    if (requiredFilters) {
      const checker = this._checkFilterSelected(requiredFilters)

      if (checker) {
        return checker.map(errorMessage => <div>{errorMessage}</div>)
      }
    }

    if (this.props.widgetDefinition && this.props.widgetDefinition.def_version >= 5) {
      return this._renderVer5()
    }

    return (
      <div
        className={`Widget CrmWidget util-marginBottom  ${this.props.fullHeight ? 'CrmWidget--fullHeight' : ''}`}
        style={this.props.style}
      >
        {this._renderWidgetHeader()}
        {this._renderDefinitionWidget()}
        <div className="Widget-content CrmWidget-content util-paddingSm">{this.renderContent()}</div>
      </div>
    )
  }
}

export default genericWidgetQLHoc(withAnalyticsContextHoc(GenericAnalyticsWidget))
