/* eslint-disable react/prop-types */
import React from 'react'
import { withApollo } from 'react-apollo'
import { shape } from 'prop-types'
import { mapContextToFilter } from '../../../dashboard-utils'
import gql from 'graphql-tag'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import { arraySum } from 'crm-utils/data-utils'

const widgetFitmentsArpuByDepartmentHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {}
    }

    handleRefresh = async () => {
      const filter = mapContextToFilter(this.props, false)
      filter.status_code = 'completed'
      if (this.props.analyticsContext.filters.activity_date) {
        filter.completed_date = this.props.analyticsContext.filters.activity_date
      }
      const variables = {
        filter,
        aggregate: [
          { id: 'quantity', type: 'count' },
          { id: 'subscription', type: 'sum' },
          { id: 'hardware_price', type: 'sum' }
        ],
        groupBy: ['department_type_uid'],
        master_uid: this.props.crmContext.master.master_uid,
        instance_uid: this.props.crmContext.instance.instance_uid
      }
      const QL_FITMENTS = gql(`
      query master(
        $master_uid: String!
        $instance_uid: String!
        $filter: JSON
        $groupBy: [String]
        $aggregate: [JSON]
      ) {
        master(master_uid: $master_uid) {
          master_uid
          name
          instance(instance_uid: $instance_uid) {
            instance_uid
            name
            orders(
              filter: $filter
              groupBy: $groupBy
              aggregate: $aggregate
            ) {
              edges {
                edge {
                  order_uid
                  quantity
                  department_type_uid
                  department_type { 
                    name
                  }
                  subscription
                  hardware_price
                }
              }
            }
          }
        }
      }
    `)
      const res = await this.props.client.query({
        query: QL_FITMENTS,
        variables,
        fetchPolicy: 'no-cache'
      })

      if (res.data) {
        var mapped = res?.data?.master?.instance?.orders
          ? res.data.master.instance.orders.edges.map(({ edge }) => ({
              ...edge,
              avg_subscription: edge.quantity ? edge.subscription / edge.quantity : 0
            }))
          : []

        const sumUnitsFitted = arraySum(mapped, 'quantity')
        this.setState({
          data: [
            ...mapped,
            {
              department_type: { name: 'Total' },
              quantity: sumUnitsFitted,
              hardware_price: arraySum(mapped, 'hardware_price'),
              subscription: arraySum(mapped, 'subscription'),
              avg_subscription: sumUnitsFitted ? arraySum(mapped, 'subscription') / sumUnitsFitted : undefined
            }
          ]
        })
      }
    }

    render() {
      return <WrappedComponent {...this.props} data={this.state.data} onRefresh={this.handleRefresh} />
    }
  }

  Inner.propTypes = {
    analyticsContext: shape({}).isRequired,
    client: shape({}).isRequired
  }
  return withApollo(withCrmContext(Inner))
}

export default widgetFitmentsArpuByDepartmentHoc
