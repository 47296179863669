/* eslint-disable camelcase */
import gql from 'graphql-tag'

export const qlqListFeedItems = gql`
  query feedItems($account_uid: String) {
    feedItems(account_uid: $account_uid) {
      feed_item_uid
      title
      type
      category
      message
      created_time
      user {
        full_name
      }
      source_command_uid
    }
  }
`
export const mock = 1
