import React from 'react'
import { shape, func } from 'prop-types'
import withLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import WidgetCurrentLeadStatusByOwner from './widget-current-lead-status-by-users.jsx'
import WidgetCurrentLeadStatusByOwner19 from './widget-current-lead-status-by-users-19.jsx'

class BoomCurrentLeadStatusDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleAnalyticsRangeSelected = params => {
    // console.log('handleAnalyticsRangeSelected', params)
    this.props.onShowLeadsPopup(params)
  }

  handleDashboardEvent = event => {
    // console.log('handleDashboardEvent', event)
    if (event === 'accountStatusSelected') {
      this.setState({ selectedAccountStatus })
    }
  }
  render() {
    return (
      <div>
        <WidgetCurrentLeadStatusByOwner
          analyticsContext={this.props.analyticsContext}
          onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
        />
        <WidgetCurrentLeadStatusByOwner19
          analyticsContext={this.props.analyticsContext}
          onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
        />
      </div>
    )
  }
}

BoomCurrentLeadStatusDashboard.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

BoomCurrentLeadStatusDashboard.defaultProps = {
  dashboards: []
}

export default withLeadsPopupHoc(BoomCurrentLeadStatusDashboard)
