import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import { qlqProcessDetails } from 'crm-data/processes'
import { func, shape, bool, string } from 'prop-types'
import { CrmModal } from '../../../../../components/index'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { qlqAccountStatusAndOwners } from 'crm-data/accounts'
import { qlqAccountTasks } from 'crm-data/tasks'
import {
  LostProspectForm,
  buildLostProspectCommand
} from './lost-prospect-form'

const LostProspectModal = props => {
  const [reasonLost, setReasonLost] = useState<any>()

  const handlePrimaryAction = async () => {
    if (!reasonLost) {
      return toastr.error('Select reason activity is lost')
    }

    const command = buildLostProspectCommand(
      props.accountUid,
      props.process.process_uid,
      reasonLost.code
    )
    try {
      const refetchQueries = [
        {
          query: qlqProcessDetails,
          variables: {
            process_uid: props.process.process_uid
          }
        },
        {
          query: qlqAccountStatusAndOwners,
          variables: {
            account_uid: props.accountUid
          }
        },
        {
          query: qlqAccountTasks,
          variables: {
            account_uid: props.accountUid
          }
        }
      ]
      console.log('Run lostProspect Modal', props, command)
      await props.onRunCommand(command, { refetchQueries })
      toastr.success('Prospect marked as Lost')
      props.onCancel()
    } catch (err) {
      toastr.error('Error marking prospect as lost: ' + err.message)
    }
  }

  console.log('Lost Prospect Modal ', reasonLost)
  return (
    <CrmModal
      isOpen={props.isOpen}
      showFooter
      title={
        <FormattedMessage
          id="crm.ui.account.prospect.lost_prospect"
          defaultMessage="Lost Lead"
        />
      }
      onClose={props.onClose}
      primaryButtonLabel={
        <FormattedMessage
          id="crm.ui.button.save_lost"
          defaultMessage="Save Lost"
        />
      }
      secondaryButtonLabel={
        <FormattedMessage id="crm.ui.button.cancel" defaultMessage="Cancel" />
      }
      onSecondaryButton={props.onCancel}
      onPrimaryButton={handlePrimaryAction}
    >
      <LostProspectForm
        {...props}
        processTypeUid={props.process ? props.process.process_type_uid : null}
        reasonLost={reasonLost}
        onSelectedReasonLost={setReasonLost}
      />
    </CrmModal>
  )
}

LostProspectModal.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  onCancel: func.isRequired,
  onRunCommand: func.isRequired,
  isSaving: bool.isRequired,
  process: shape({}),
  accountUid: string.isRequired,
  bulkMode: bool,
  onBulkComponentInit: func
}
LostProspectModal.defaultProps = {
  prospect: undefined,
  isOpen: false,
  process: undefined,
  bulkMode: false,
  onBulkComponentInit: undefined
}

export default crmGenericCommandHoc(LostProspectModal)
