import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape, func, bool, arrayOf } from 'prop-types'
import { CrmFormSection, CrmDropdown } from 'crm-components'
import {
  getAvailableInstances,
  changeSelectedInstance,
  fetchAvailableInstances,
  getSelectedInstance
} from 'crm-duxs/crm-reducer'

class MeInstanceForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { defaultInstanceUid: undefined }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.availableInstances !== nextProps.availableInstances) {
      const options = this.getOptions(nextProps.availableInstances)
      const defaultInstanceUid =
        options && options.length === 1 && options[0]
          ? options[0].value
          : undefined

      if (defaultInstanceUid) {
        this.setState({ defaultInstanceUid: defaultInstanceUid })
        this.handleSelectInstance(defaultInstanceUid)
      }
    }
  }

  handleSelectInstance = value => {
    if (value) {
      const instance = this.props.availableInstances.find(
        inst => inst.instance_uid === value
      )
      if (instance) {
        this.props.doChangeSelectedInstance(instance)
      }
    }
  }

  getOptions = availableInstances => {
    const options = availableInstances
      ? availableInstances.map(inst => ({
          name: inst.name,
          value: inst.instance_uid
        }))
      : []

    return options
  }

  render() {
    const options = this.getOptions(this.props.availableInstances)

    const selectedInstanceUid = this.props.selectedInstance
      ? this.props.selectedInstance.instance_uid
      : undefined

    const dropdown = (
      <div className="row">
        <div className="col-xs-12 col-md-12">
          <CrmDropdown
            title="Select Branch"
            options={options}
            input={{
              value: selectedInstanceUid || this.state.defaultInstanceUid,
              onChange: this.handleSelectInstance
            }}
            extraClassNames={{
              containerClassNames: ` `,
              selectClassNames: ''
            }}
          />
        </div>
      </div>
    )

    if (!this.props.showWithTitle) return dropdown

    return (
      <CrmFormSection sectionName="Change Current Branch">
        <fieldset className="Form-fieldset CrmForm-form-fieldset">
          {dropdown}
        </fieldset>
      </CrmFormSection>
    )
  }
}

MeInstanceForm.propTypes = {
  selectedInstance: shape({}),
  availableInstances: arrayOf(shape({})),
  doChangeSelectedInstance: func.isRequired,
  showWithTitle: bool
}

MeInstanceForm.defaultProps = {
  showWithTitle: true,
  selectedInstance: undefined,
  availableInstances: undefined
}

function mapStateToProps(state) {
  const availableInstances = getAvailableInstances(state)
  return {
    availableInstances: availableInstances?.available_instances
      ? availableInstances.available_instances
      : [],
    selectedInstance: getSelectedInstance(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangeSelectedInstance: instance => {
      dispatch(changeSelectedInstance(instance))
    },
    doFetchAvailableInstances: () => dispatch(fetchAvailableInstances())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeInstanceForm)
