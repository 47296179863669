// Actions for dictionaries
export const FETCH_RESOURCES = 'FETCH_RESOURCES'
export const RECEIVE_RESOURCES = 'RECEIVE_RESOURCES'
export const SELECTED_RESOURCE = 'SELECTED_RESOURCE'
export const UNSELECTED_RESOURCE = 'UNSELECTED_RESOURCE'
export const SAVE_EDITING_RESOURCE = 'SAVE_EDITING_RESOURCE'
export const SAVED_EDITING_RESOURCE = 'SAVED_EDITING_RESOURCE'
export const EDITING_RESOURCE = 'EDITING_RESOURCE'
export const CANCEL_EDITING_RESOURCE = 'CANCEL_EDITING_RESOURCE'
export const CREATING_RESOURCE = 'CREATING_RESOURCE'
export const CANCEL_CREATING_RESOURCE = 'CANCEL_CREATING_RESOURCE'
export const SAVING_RESOURCE = 'SAVING_RESOURCE'
export const SAVE_CREATING_RESOURCE = 'SAVE_CREATING_RESOURCE'
export const SAVING_RESOURCE_ERROR = 'SAVING_RESOURCE_ERROR'
export const DELETE_RESOURCE_VALUE = 'DELETE_RESOURCE_VALUE'
export const CREATING_RESOURCE_ITEM = 'CREATING_RESOURCE_ITEM'
export const CREATED_RESOURCE_FOR_ACCOUNT = 'CREATED_RESOURCE_FOR_ACCOUNT'

// Reducer
const initialState = {
  lists: [],
  fetch: false,
  editing: false,
  creating: false,
  selected: undefined,
  deleteValues: [],
  uploadedAttachmentForAccount: 0
}

export default function resourceReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESOURCES:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_RESOURCES:
      return {
        ...state,
        fetch: false,
        lists: action.payload.resources
      }
    case SELECTED_RESOURCE:
      return {
        ...state,
        selected: action.item,
        creating: false,
        editing: true
      }
    case UNSELECTED_RESOURCE:
      return {
        ...state,
        selected: undefined,
        editing: false
      }
    case EDITING_RESOURCE:
      return {
        ...state,
        editing: true,
        creating: false,
        deleteValues: []
      }
    case CANCEL_EDITING_RESOURCE:
      return {
        ...state,
        editing: false,
        selected: undefined
      }
    case CREATING_RESOURCE:
      return {
        ...state,
        creating: true,
        editing: false,
        selected: undefined,
        deleteValues: []
      }
    case CANCEL_CREATING_RESOURCE:
      return {
        ...state,
        creating: false
      }
    case SAVING_RESOURCE:
      return {
        ...state,
        creating: true
      }
    case CREATED_RESOURCE_FOR_ACCOUNT: {
      let uploaded = state.uploadedAttachmentForAccount
      return {
        ...state,
        uploadedAttachmentForAccount: ++uploaded
      }
    }
    default:
      return state
  }
}

export function fetchData() {
  return {
    type: FETCH_RESOURCES
  }
}

export function selectedItem(item) {
  return {
    type: SELECTED_RESOURCE,
    item
  }
}

export function deleteItem(resourceUid) {
  return {
    type: DELETE_RESOURCE_VALUE,
    resourceUid
  }
}

export function unselectedItem() {
  return {
    type: UNSELECTED_RESOURCE
  }
}

export function startEditingItem() {
  return {
    type: EDITING_RESOURCE
  }
}

export function cancelEditingItem() {
  return {
    type: CANCEL_EDITING_RESOURCE
  }
}

export function startCreatingItem() {
  return {
    type: CREATING_RESOURCE
  }
}

export function cancelCreatingItem() {
  return {
    type: CANCEL_CREATING_RESOURCE
  }
}

export function saveCreatingItem(formValues) {
  return {
    type: SAVE_CREATING_RESOURCE,
    formValues
  }
}

export function createResourceItem(formValues) {
  return {
    type: CREATING_RESOURCE_ITEM,
    formValues
  }
}

export function saveEditingItem(formValues) {
  return {
    type: SAVE_EDITING_RESOURCE,
    formValues
  }
}
