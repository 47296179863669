/* eslint-disable camelcase, no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import cloneDeep from 'clone-deep'
import { arrayOf, shape, func, string } from 'prop-types'
import { Query } from 'react-apollo'
import { getFormValues, FormSection } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { qlqListCallDispositionTypes } from 'crm-data/activities'
import { ConvertToProspectFieldsHOC } from 'crm-modules/accounts/qualification/_convert-to-prospect-fields.jsx'
import {
  CrmDropdown,
  CrmDayHourPickerInput,
  CrmCheckbox,
  CrmTextInput,
  CrmProductsSelect
} from 'crm-components'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

const prioritiesOptions = [
  { name: 'Normal', value: '2' },
  { name: 'Higher', value: '4' },
  { name: 'Highest', value: '9' }
]
class CallDisposition extends React.PureComponent {
  constructor(props) {
    super(props)
    const state = {
      dispositionDetails: {
        callback_time: undefined,
        callback_priority: undefined,
        saleItems: [{}]
      },
      isExactTime: true,
      isShowManualCallback: false
    }

    if (props.value && props.dispositionTypes) {
      state.selectedDispositionUid = props.dispositionTypes.find(
        dt => dt.disposition_type_uid === props.value
      )
    }
    if (props.activity) {
      state.selectedDispositionUid = props.activity.disposition_type_uid
      if (
        props.activity.disposition_details &&
        !Array.isArray(props.activity.disposition_details)
      ) {
        state.dispositionDetails = props.activity.disposition_details
      }
      if (state.selectedDispositionUid && props.dispositionTypes) {
        state.selectedDisposition = props.dispositionTypes.find(
          d => d.disposition_type_uid === state.selectedDispositionUid
        )
      }
    }
    this.state = state
  }

  handleSetManualCallback() {
    // console.log('handleSetManualCallback')
  }

  _availableOptions = () => {
    const activityStatus = this.props.activityStatus
    let filtered = this.props.dispositionTypes
      ? this.props.dispositionTypes.filter(dt => {
          if (activityStatus) {
            return (
              activityStatus.is_success === dt.is_success &&
              dt.is_active === true
            )
          }
          return true
        })
      : []

    filtered = this.filterAvailableDispositions(filtered)

    // filter by call type
    const activityTypeModule =
      this.props.activity && this.props.activity.activity_type
        ? this.props.activity.activity_type.module
        : null
    if (activityTypeModule) {
      filtered = filtered.filter(
        v => v.module === activityTypeModule || !v.module
      )
    }

    return filtered
      .map(o => ({
        value: o.disposition_type_uid,
        name: o.name
      }))
      .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
  }

  filterAvailableDispositions = disposition_types => {
    return disposition_types.filter(v => {
      const require_manage_permission = v.require_manage_permission
        ? this.props.accountPermissionsContext.hasPermissionByType(
            PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
          )
        : true

      // const account_validation_match = this.props.account?.validated_date
      //   ? v.for_validated_account
      //   : v.for_not_validated_account
      // console.log(
      //   'filterAvailableDispositions',
      //   account_validation_match,
      //   v.for_validated_account,
      //   v.for_not_validated_account
      // )
      const account_validation_match = true // Don't launch this yet - wait for new form
      return require_manage_permission && account_validation_match
    })
  }

  componentWillReceiveProps = nextProps => {
    const newState = {}
    // console.log(
    //   'Call Disposition: componentWillReceiveProps',
    //   nextProps,
    //   this.props
    // )
    if (
      nextProps.dispositionTypes &&
      !this.props.dispositionTypes &&
      nextProps.value
    ) {
      // Loaded disposition types
      // console.log('Set new disposition types')
      newState.selectedDispositionUid = nextProps.dispositionTypes.find(
        dt => dt.disposition_type_uid === nextProps.value
      )
    }
    if (
      nextProps.activity &&
      nextProps.activity.disposition_type_uid &&
      nextProps.activity.disposition_type_uid !==
        this.state.selectedDispositionUid
    ) {
      newState.selectedDispositionUid = nextProps.activity.disposition_type_uid
      newState.selectedDisposition = nextProps.dispositionTypes
        ? nextProps.dispositionTypes.find(
            d =>
              d.disposition_type_uid === nextProps.activity.disposition_type_uid
          )
        : undefined
    }
    if (
      nextProps.activity.disposition_details &&
      !this.state.dispositionDetails &&
      !Array.isArray(nextProps.activity.disposition_details)
    ) {
      newState.dispositionDetails = nextProps.activity.disposition_details
    }
    if (newState.dispositionDetails && !newState.dispositionDetails.saleItems) {
      newState.dispositionDetails.saleItems = [{}]
    }
    
    if (
      nextProps.activityStatus
    ) {
      const rulesCode = ['busy', 'answer', 'no_answer']
      if (rulesCode.includes(nextProps.activityStatus?.code)) {
        newState.isShowManualCallback = true
      } else {
        newState.isShowManualCallback = false
        if (nextProps.activityStatus.is_success === false) {
          newState.dispositionDetails = {
            callback_time: undefined,
            callback_priority: undefined,
            saleItems: [{}]
          }
        }
      }
    }

    // FormValues changed
    if (
      nextProps.dispositionAdditionalFormValues !==
      this.props.dispositionAdditionalFormValues
    ) {
      if (nextProps.dispositionAdditionalFormValues.dispositionDetailsForm) {
        newState.dispositionDetails =
          nextProps.dispositionAdditionalFormValues.dispositionDetailsForm
      }
    }
    this.setState(newState)
  }
  getDispositionErrors = () => {
    const errors = []
    errors.push({
      message: 'Sample error'
    })
    return errors
  }
  handleDispositionChange = async (selectedDispositionUid, item) => {
    if (selectedDispositionUid !== this.state.selectedDispositionUid) {
      const selectedDisposition = await this.props.dispositionTypes.find(
        d => d.disposition_type_uid === selectedDispositionUid
      )
      // Evaluate if is valid
      const dispositionErrors = await this.getDispositionErrors()
      await this.setState({
        selectedDispositionUid,
        selectedDisposition,
        dispositionErrors
      })
      this._updateDisposition({
        ...this.state,
        selectedDispositionUid,
        selectedDisposition,
        dispositionErrors
      })
    }
  }
  handleCallbackDateChange = async callback_time => {
    const newState = await {
      ...this.state,
      dispositionDetails: {
        ...this.state.dispositionDetails,
        callback_time: new Date(callback_time)
      }
    }
    this._updateDisposition(newState)
  }

  handleCamsUsernameChange = event => {
    const camsUsername = event.currentTarget.value
    // console.log('handleCamsUsernameChange', camsUsername)
    const newState = {
      dispositionDetails: {
        camsUsername
      }
    }
    this._updateDisposition(newState)
  }

  handleProductChange = productUid => {
    const newState = cloneDeep(this.state)
    newState.dispositionDetails.saleItems[0].product_uid = productUid
    this._updateDisposition(newState)
  }

  handleQuantityChange = event => {
    const newState = cloneDeep(this.state)
    newState.dispositionDetails.saleItems[0].quantity =
      event.currentTarget.value
    this._updateDisposition(newState)
  }
 
  handleUpdate = (event, productUid, quantity) => {
    event.preventDefault()
    const newState = cloneDeep(this.state);
    newState.dispositionDetails.saleItems[0].product_uid = productUid;
    newState.dispositionDetails.saleItems[0].quantity = quantity;
    this._updateDisposition(newState);
  };  

  handlePriorityChanged = async value => {
    const newState = await {
      ...this.state,
      dispositionDetails: {
        callback_priority: String(value)
      }
    }
    this._updateDisposition(newState)
  }
  _updateDisposition = async params => {
    const nextState = await {
      ...params,
      ...this.state,
      dispositionDetails: {
        ...this.state.dispositionDetails,
        ...params.dispositionDetails
      }
    }
    await this.setState(nextState)
    this.notifyDispositionChanged(nextState)
  }
  notifyDispositionChanged = nextState => {
    const disposition = {
      disposition_type_uid: nextState.selectedDispositionUid,
      dispositionDetails: nextState.dispositionDetails,
      dispositionErrors: nextState.dispositionErrors
    }
    if (this.props.onChange) {
      this.props.onChange(disposition)
    }
  }

  handleSetManualCall = () => {
    this.setState({ hasManuallCallback: true })
  }

  renderAdditionalForm = () => {
    let FormClass
    if (this.state.selectedDisposition.code) {
      FormClass = ConvertToProspectFieldsHOC
    }
    if (!FormClass) {
      return <div>{`Can't find additional form`}</div>
    }

    return (
      <FormSection name="dispositionDetailsForm">
        <FormClass
          account={this.props.account}
          dispositionDetails={this.state.dispositionDetails}
          onChange={this.handleDispositionDetailsChange}
          form={this.props.form}
          isNested
          newProcessKind="prospect"
        />
      </FormSection>
    )
  }

  render() {
    const options = this._availableOptions()
    const { hasManuallCallback, isShowManualCallback } = this.state
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <FormattedMessage
              id="crm.ui.activity.form.call_disposition"
              defaultMessage="Call Disposition"
            />
          </div>
          <div className="col-md-7">
            <CrmDropdown
              options={options}
              input={{
                value: this.state.selectedDispositionUid,
                onChange: this.handleDispositionChange
              }}
            />
          </div>
        </div>

        {this.state.selectedDisposition &&
          this.state.selectedDisposition.code === 'OSC' &&
          this.renderAdditionalForm()}
        {this.state.selectedDisposition &&
          this.state.selectedDisposition.code === 'SALE' && (
            <div className="util-bgGreen util-textWhite util-paddingSm">
              <label>
                <FormattedMessage
                  id="crm.ui.activity.form.provide_sale_details"
                  defaultMessage="Provide Sale Details"
                />
              </label>
              <div className="row">
                <div className="col-md-4">
                  <CrmTextInput
                    placeholder="CAMS Username"
                    placeholderId="crm.ui.activity.form.cams_username"
                    input={{
                      value: this.state.dispositionDetails.camsUsername,
                      onChange: this.handleCamsUsernameChange
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <CrmProductsSelect
                    input={{
                      value:
                        this.state.dispositionDetails &&
                        this.state.dispositionDetails.saleItems
                          ? this.state.dispositionDetails.saleItems[0]
                              .product_uid
                          : undefined,
                      onChange: this.handleProductChange
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <CrmTextInput
                    placeholder="Quantity"
                    placeholderId="crm.ui.activity.form.quantity"
                    input={{
                      value:
                        this.state.dispositionDetails &&
                        this.state.dispositionDetails.saleItems
                          ? this.state.dispositionDetails.saleItems[0].quantity
                          : undefined,
                      onChange: this.handleQuantityChange
                    }}
                  />
                </div>
              </div>

		<div>
                <button
                    onClick={(event) =>
                        this.handleUpdate(
                            event,
                            this.state.dispositionDetails.saleItems[0].product_uid,
                            this.state.dispositionDetails.saleItems[0].quantity
                        )
                    }
                >
                  Update Sale Details
                </button>
              </div>

            </div>
          )}
        {isShowManualCallback && (
          <div>
            {!hasManuallCallback && (
              <small className="util-textGreen">
                <CrmCheckbox
                  labelMessageId="crm.ui.activity.form.set_manual_callback"
                  label="Set Manual Callback"
                  input={{
                    onChange: this.handleSetManualCall
                  }}
                />
              </small>
            )}
            {hasManuallCallback && (
              <div className="row util-marginTop">
                <div className="col-md-4">
                  <label>
                    <FormattedMessage
                      id="crm.ui.activity.form.next_call_date"
                      defaultMessage="Next Call Date"
                    />
                  </label>
                </div>
                <div className="col-md-5">
                  <CrmDayHourPickerInput
                    placeholder={
                      <FormattedMessage
                        id="crm.ui.activity.form.next_call_date"
                        defaultMessage="Next Call Date"
                      />
                    }
                    input={{
                      onChange: this.handleCallbackDateChange,
                      value: this.state.dispositionDetails.callback_time
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <CrmDropdown
                    placeholder={
                      <FormattedMessage
                        id="crm.ui.activity.form.priority"
                        defaultMessage="Priority"
                      />
                    }
                    options={prioritiesOptions}
                    input={{
                      onChange: this.handlePriorityChanged,
                      value: this.state.dispositionDetails.callback_priority
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

CallDisposition.propTypes = {
  dispositionTypes: arrayOf(shape({})).isRequired,
  activity: shape({}).isRequired, // eslint-disable-line
  input: shape({}).isRequired, // eslint-disable-line
  onChange: func,
  activityStatus: shape({}),
  value: string,
  account: shape({}),
  dispositionAdditionalFormValues: shape({}),
  form: string.isRequired,
  accountPermissionsContext: shape({}).isRequired
}

CallDisposition.defaultProps = {
  input: undefined,
  onChange: () => {},
  activityStatus: undefined,
  value: undefined,
  dispositionAdditionalFormValues: undefined,
  account: undefined
}
const CallDispositionConnected = connect((state, ownProps) => ({
  dispositionAdditionalFormValues: getFormValues(ownProps.form)(state)
}))(CallDisposition)

const CallDispositionQL = props => (
  <Query query={qlqListCallDispositionTypes}>
    {({ loading, error, data, refetch }) => (
      <CallDispositionConnected
        {...props}
        loading={loading}
        error={error}
        refetch={refetch}
        dispositionTypes={
          data && data.call_disposition_type_list_qm_paged
            ? data.call_disposition_type_list_qm_paged.data
            : undefined
        }
      />
    )}
  </Query>
)

export default withAccountPermissionsContextHOC(CallDispositionQL)
