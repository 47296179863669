import React from 'react'
import { bool, any } from 'prop-types'

// eslint-disable-next-line arrow-body-style
export const CrmFullpageContainer = props => {
  return (
    <div
      className={`CrmFullpageContainer 
      ${props.className} ${props.hasPadding
          ? 'CrmFullpageContainer--hasPadding'
          : ''} 
        ${props.hbox ? ' CrmBox--hbox' : ''}
        ${props.vbox ? ' CrmBox--vbox' : ''}
        ${props.scrollBody
          ? 'CrmFullpageContainer--scrollBody'
          : 'CrmFullpageContainer--scrollInner'}
          ${props.noInnerNav ? 'CrmFullpageContainer--noInnerNav' : ''}
        ${props.fullPage ? 'CrmFullpageContainer--fullPage' : ''}`}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

CrmFullpageContainer.propTypes = {
  className: any.isRequired, // eslint-disable-line
  children: any.isRequired, // eslint-disable-line
  hasPadding: bool,
  style: any, // eslint-disable-line
  hbox: bool,
  vbox: bool,
  scrollBody: bool,
  fullPage: bool,
  noInnerNav: bool
}

CrmFullpageContainer.defaultProps = {
  className: '',
  hasPadding: false,
  style: undefined,
  vbox: undefined,
  hbox: undefined,
  scrollBody: false,
  fullPage: false,
  noInnerNav: false
}

export default CrmFullpageContainer
