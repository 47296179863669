import React, { Component } from 'react'
import { func, bool, shape } from 'prop-types'
import filesize from 'filesize'
import { reduxForm, Field } from 'redux-form'
import Dropzone from 'react-dropzone'
import { getApiRootUrl } from 'crm-api/api-utils'
import {
  CrmFormGroup,
  CrmFormSection,
  CrmTextInput,
  CrmButton,
  CrmCheckbox
} from 'crm-components'
import validate from 'crm-utils/validates'

class ResourceForm extends Component {
  constructor(props) {
    super(props)
    this.state = { files: undefined }
    this.handleEdit = this.handleEdit.bind(this)
    this.handleCancelEdit = this.handleCancelEdit.bind(this)
    this.renderFormFiled = this.renderFormFiled.bind(this)
    this.showFileDropped = this.showFileDropped.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
    this.getButton = this.getButton.bind(this)
  }

  handleEdit() {
    this.props.onEdit()
  }

  handleCancelEdit() {
    this.props.onReset()
    this.props.onCancel()
  }

  renderFormFiled() {
    const fields = []
    fields.push(
      <div className="row">
        <div className="col-xs-12 col-md-12 col-lg-8">
          <p>
            <label>Description:</label>
          </p>
          <Field
            name="description"
            component={CrmTextInput}
            type="text"
            placeholder="description"
            validate={[validate.required]}
          />
        </div>
      </div>
    )

    if (this.props.editing) {
      console.log('Resource from props ', this.props)
      fields.push(
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-8">
            <Field
              name="name"
              component={CrmTextInput}
              type="text"
              placeholder="Filename"
            />
          </div>
        </div>
      )

      fields.push(
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-8">
            <p>
              <label>Deleted:</label>
            </p>
            <Field
              name="is_deleted"
              component={CrmCheckbox}
              label="checked for delete"
              placeholder="Is delete"
            />
          </div>
        </div>
      )

      const link =
        getApiRootUrl() +
        'crm_resources.php?file=' +
        this.props.resource.file_link
      fields.push(
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-8">
            <p>
              <a href={link}> Download link</a>
            </p>
          </div>
        </div>
      )

      return fields
    }

    fields.push(
      <div className="row">
        <div className="col-xs-12 col-md-12 col-lg-8">
          <p>
            <label>File upload:</label>
          </p>
          <Dropzone onDrop={this.handleDrop} multiple={false}>
            <p>{this.showFileDropped()}</p>
          </Dropzone>
        </div>
      </div>
    )

    return fields
  }

  showFileDropped() {
    if (this.state.files) {
      return (
        'Filename : ' +
        this.state.files[0].name +
        ', Filesize :' +
        filesize(this.state.files[0].size)
      )
    }

    return 'Try dropping some files here, or click to select files to upload.'
  }

  handleDrop(files) {
    this.setState({
      files
    })

    this.props.onUpload(files[0])
  }

  getButton() {
    if (this.props.editing) {
      return (
        <div>
          <CrmButton label="Save" type="submit" />
          <CrmButton
            label="Cancel"
            type="secondary"
            onClick={this.handleCancelEdit}
          />
        </div>
      )
    }

    return (
      <div>
        <CrmButton label="Save" type="submit" />
        <CrmButton
          label="Cancel"
          type="secondary"
          onClick={this.handleCancelEdit}
        />
      </div>
    )
  }

  render() {
    const { handleSubmit } = this.props
    const isEditing = this.props.editing || this.props.creating
    return (
      <div>
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <div className="row">{this.getButton()}</div>
          <CrmFormGroup groupName="General">
            <CrmFormSection sectionName="Resource information">
              <fieldset
                disabled={!isEditing}
                className="Form-fieldset CrmForm-form-fieldset"
              >
                {this.renderFormFiled().map(field => field)}
              </fieldset>
            </CrmFormSection>
          </CrmFormGroup>
        </form>
      </div>
    )
  }
}

ResourceForm.propTypes = {
  creating: bool.isRequired,
  editing: bool.isRequired,
  onCancel: func.isRequired,
  onEdit: func.isRequired,
  handleSubmit: func.isRequired,
  onReset: func.isRequired,
  onUpload: func.isRequired,
  resource: shape({}).isRequired
}

const ResourceReduxForm = reduxForm({ form: 'crmSettingResource' })(
  ResourceForm
)
export default ResourceReduxForm
