import React from 'react'
import { func, shape } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import { qlqProcessType } from 'crm-data/processes'
import {
  qlqAccountStatusAndOwners,
  qlqFetchAccountProcesses
} from 'crm-data/accounts'
import {
  qlGetOpportunity,
  qlqAccountOpportunities
} from 'crm-data/opportunities'
import { qlGetDocument } from 'crm-data/documents'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

// eslint-disable-next-line
const quotationWindowHoc = WrappedComponent => {
  class QuotationWin extends React.PureComponent {
    static propTypes = {
      opportunity: shape({}),
      onCancel: func,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      opportunity: undefined,
      onCancel: undefined
    }
    constructor(props) {
      super(props)
      this.state = {
        isSaving: false,
        isActive: false
      }
    }

    _buildRefetchQueries = () => {
      const refetchQueries = [
        {
          query: qlqAccountOpportunities,
          variables: { account_uid: this.props.quotation.account_uid },
          partialRefetch: true
        },
        {
          query: qlGetOpportunity,
          variables: { opportunity_uid: this.props.quotation.opportunity_uid }
        },
        {
          query: qlqAccountStatusAndOwners,
          variables: { account_uid: this.props.quotation.account_uid }
        },
        {
          query: qlqFetchAccountProcesses,
          variables: { account_uid: this.props.quotation.account_uid }
        }
      ]
      return refetchQueries
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    handleDeleteQuotation = async data => {
      console.log('handleDeleteQuotation')
      if (!this.props.quotation) {
        throw new Error('No props.quotation')
      }
      const command = {
        type: 'quotation.delete',
        aggregate_uid: this.props.quotation.document_uid,
        aggregate_type: 'quotation',
        payload: {}
      }
      try {
        this.setStateAsync({ isSavingQuotation: true })
        const res = await this.props.onRunCommand(command, {
          refetchQueries: [
            {
              query: qlGetOpportunity,
              variables: { opportunity_uid: data.opportunity_uid }
            }
          ]
        })
        this.setStateAsync({ isSavingQuotation: false })
        return res
      } catch (err) {
        this.setStateAsync({ isSavingQuotation: false })
        console.error('Error running command')
        throw err
      }
    }
    handleSaveQuotation = async data => {
      console.log('handleSaveQuotation', data, this.props)
      if (!this.props.quotation) {
        throw new Error('No props.quotation')
      }
      const command = {
        type: 'quotation.update',
        aggregate_uid: this.props.quotation.document_uid,
        aggregate_type: 'quotation',
        payload: { ...data }
      }
      try {
        this.setStateAsync({ isSavingQuotation: true })
        const res = await this.props.onRunCommand(command, {
          refetchQueries: [
            {
              query: qlGetOpportunity,
              variables: { opportunity_uid: data.opportunity_uid }
            }
          ]
        })
        this.setStateAsync({ isSavingQuotation: false })
        return res
      } catch (err) {
        this.setStateAsync({ isSavingQuotation: false })
        console.error('Error running command')
        throw err
      }
    }
    handleSaveWin = async payload => {
      // console.log('handleSaveWin', this.props, payload)
      const command = {
        type: 'process.complete',
        payload: {
          ...payload,
          resultStepTypeUid: '45af09af-f319-4f7c-96e3-a2c25ae8ee65',
          accountUid: this.props.accountUid
        },
        aggregate_type: 'process',
        aggregate_uid: this.props.quotation.process.process_uid
      }
      await this.setStateAsync({ isSaving: true })
      try {
        const res = await this.props.onRunCommand(command, {
          refetchQueries: this._buildRefetchQueries()
        })
        // console.log('got CQ Result', res)
        await this.setStateAsync({ isSaving: false })
        return res
      } catch (err) {
        console.log('Error saving win')
        console.error(err)
        throw err
      }
    }

    handleSaveLost = async payload => {
      console.log('handleSaveLost', this.props)
      const command = {
        type: 'process.complete',
        payload: {
          ...payload
        },
        aggregate_type: 'process',
        aggregate_uid: this.props.quotation.process.process_uid
      }
      await this.setStateAsync({ isSaving: true })
      try {
        const refetchQueries = this._buildRefetchQueries()
        // console.log('refetchQueries', refetchQueries)
        const res = await this.props.onRunCommand(command, { refetchQueries })
        // console.log('got CQ Result', res)
        await this.setStateAsync({ isSaving: false })
        return res
      } catch (err) {
        console.error(err)
        throw err
      }
    }

    render() {
      console.log('Render Quotation Windoth HOC', this.props)
      return (
        <WrappedComponent
          {...this.props}
          onSaveWin={this.handleSaveWin}
          onSaveLost={this.handleSaveLost}
          onClickDo={this.handleClickDo}
          onCancel={this.props.onCancel}
          isSaving={this.state.isSaving}
          isActive={this.state.isActive}
          onSaveQuotation={this.handleSaveQuotation}
          onDeleteQuotation={this.handleDeleteQuotation}
          isSavingQuotation={this.state.isSavingQuotation}
        />
      )
    }
  }

  const quotationWinQl = compose(
    graphql(qlGetDocument, {
      options: ownProps => ({
        variables: { document_uid: ownProps.documentUid } // eslint-disable-line
      }),
      props: allProps => {
        const { qlGetDocument, ownProps } = allProps
        // console.log('qlGetDocument result', allProps, qlGetDocument, ownProps)
        return {
          ...ownProps,
          qlGetDocument,
          quotation:
            qlGetDocument && qlGetDocument.document
              ? qlGetDocument.document
              : undefined
        }
      },
      name: 'qlGetDocument',
      skip: ownProps => ownProps.quotation || !ownProps.documentUid
    }),
    graphql(qlGetOpportunity, {
      options: ownProps => ({
        variables: {
          opportunity_uid: ownProps.quotation
            ? ownProps.quotation.opportunity_uid
            : undefined
        } // eslint-disable-line
      }),
      props: ({ qlGetOpportunity, ownProps }) => {
        return {
          ...ownProps,
          qlGetOpportunity,
          opportunity:
            qlGetOpportunity && qlGetOpportunity.opportunity
              ? qlGetOpportunity.opportunity
              : undefined
        }
      },
      skip: ownProps => ownProps.opportunity || !ownProps.quotation,
      name: 'qlGetOpportunity'
    }),
    graphql(qlqProcessType, {
      options: ownProps => {
        return {
          variables: {
            process_uid: ownProps.quotation.process.process_uid
          }
        }
      },
      skip: ownProps => !ownProps.opportunity,
      props: ({ qlqProcessType, ownProps }) => ({
        ...ownProps,
        qlqProcessType,
        processType: qlqProcessType ? qlqProcessType.process_type : undefined
      }),
      name: 'qlqProcessType'
    }),
    crmGenericCommandQlHoc
  )(QuotationWin)

  return quotationWinQl
}

export default quotationWindowHoc
