import settingsAPI from 'crm-api/settings/lead-sources-api'
import {
  FETCH_SETTING_LEAD_SOURCES_SUCCESS,
  FETCH_SETTING_LEAD_SOURCES,
  CREATE_LEAD_SOURCE,
  CREATE_LEAD_SOURCE_SUCCESS,
  CLEAR_SELECT_LEAD_SOURCE,
  UPDATE_LEAD_SOURCE,
  UPDATE_LEAD_SOURCE_SUCCESS
} from 'crm-duxs/settings/lead-source-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchLeadSources(master_uid) {
  const responses = yield call(settingsAPI.fetchLeadSourcesApi, master_uid)
  yield put({
    type: FETCH_SETTING_LEAD_SOURCES_SUCCESS,
    payload: {
      leadSources: responses
    }
  })
}

function* createLeadSource(data) {
  const leadSource = yield call(
    settingsAPI.createLeadSourceApi,
    data.payload.values
  )
  yield put({ type: CREATE_LEAD_SOURCE_SUCCESS, payload: { leadSource } })
  yield put({ type: CLEAR_SELECT_LEAD_SOURCE })
}

function* updateLeadSource(data) {
  const leadSource = yield call(
    settingsAPI.updateLeadSourceApi,
    data.payload.values
  )
  yield put({ type: UPDATE_LEAD_SOURCE_SUCCESS, payload: { leadSource } })
  yield put({ type: CLEAR_SELECT_LEAD_SOURCE })
}

function* settingLeadSourceSaga() {
  yield takeLatest(FETCH_SETTING_LEAD_SOURCES, fetchLeadSources)
  yield takeLatest(CREATE_LEAD_SOURCE, createLeadSource)
  yield takeLatest(UPDATE_LEAD_SOURCE, updateLeadSource)
}

export default settingLeadSourceSaga
