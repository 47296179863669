import usersApi from 'crm-api/settings/users-api'
import messagesApi from 'crm-api/messages-api'
import { actions } from 'crm-duxs/messages-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchUsers(action) {
  let users = yield call(usersApi.fetchUsers)
  users = users.sort(
    (a, b) => (a.full_name > b.full_name) - (a.full_name < b.full_name)
  )
  yield put({
    type: actions.fetchUsersSuccess.type,
    payload: { users }
  })
}

function* sendNotificationToMobile(action) {
  const messageResult = yield call(
    messagesApi.sendNotificationToMobile,
    action.payload.message
  )
  if (action.payload.onSuccess) {
    action.payload.onSuccess(messageResult)
  }
}

export function* fetchMessages(action) {
  console.log('fetchMessages SAGA')
  let messages = yield call(messagesApi.listMessages, action.payload)
  messages.countUnreadMessage = 0
  console.log('got messages')
  if (messages && messages.data) {
    messages = {
      data: messages.data.sort((a, b) => a.created_time - b.created_time)
    }
    messages.count = messages.data.length
    messages.countUnreadMessage = messages.data.filter(
      item => item.read_time === null
    ).length
  }
  yield put(actions.fetchMessagesSuccess.create({ messages }))
}

function* selectMessage(action) {
  const selectedMessage = yield call(
    messagesApi.getMessage,
    action.payload.messageUid
  )
  console.log('got message')
  yield put(actions.setSelectedMessage.create({ selectedMessage }))
}

export default function* messagesSaga() {
  yield takeLatest(actions.fetchUsers.type, fetchUsers)
  yield takeLatest(
    actions.sendNotificationToMobile.type,
    sendNotificationToMobile
  )
  yield takeLatest(actions.fetchMessages.type, fetchMessages)
  yield takeLatest(actions.selectMessage.type, selectMessage)
}
