import React, { Component } from 'react'
import { shape, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import { CrmModal, CrmButton, CrmUserSelect } from 'crm-components'
import { getAccountOwnerUser } from 'crm-utils/accounts-utils'

class ConvertLeadToAccountModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleClickConvert = this.handleClickConvert.bind(this)
    this.handleUserSelect = this.handleUserSelect.bind(this)
  }

  handleClickConvert() {
    console.log('handleClickConvert')
    if (!this.state.user) {
      window.alert('Please select user') // eslint-disable-line
      return
    }
    const onSuccess = () => {
      toastr.info('Account has been assigned')
      this.props.onClose()
    }
    this.props.accountActions.convertLeadToAccount({
      userUid: this.state.user,
      account: this.props.account,
      onSuccess
    })
  }
  handleUserSelect(value) {
    console.log('handleUserSelect')
    console.dir(value)
    this.setState({ ...this.state, user: value })
  }

  render() {
    const { account } = this.props
    const isValid = this.state.user
    const accountOwner = getAccountOwnerUser(account)
    const hasPermissionsToReassign = true
    return (
      <CrmModal
        isOpen
        title="Assign account to new owner"
        onClose={this.props.onClose}
      >
        <div className="CrmForm">
          {accountOwner && !hasPermissionsToReassign ? (
            <div>
              <FormattedMessage
                id="crm.ui.account.convert.account_already_has_owner"
                defaultMessage="This account already has an owner and you don`t have a permission
                    to reassign"
              />
            </div>
          ) : (
            <div className="row CrmForm-row">
              <div className="col-xs-12 col-md-12 col-lg-12">
                {accountOwner ? (
                  <div>
                    <FormattedMessage
                      id="crm.ui.account.convert.company_already_assigned_to:"
                      defaultMessage="This company is already assigned to:"
                    />
                    {accountOwner ? accountOwner.full_name : undefined}
                  </div>
                ) : (
                  <label>
                    <FormattedMessage
                      id="crm.ui.account.convert.select_new_owner_for_account:"
                      defaultMessage="Select new owner for this account:"
                    />
                  </label>
                )}
                <div>{account.name}</div>
                <CrmUserSelect
                  name="name"
                  input={{
                    value: this.state.user,
                    onChange: this.handleUserSelect
                  }}
                  title={
                    <FormattedMessage
                      id="crm.ui.account.convert.select_new_account_owner"
                      defaultMessage="Select new account owner"
                    />
                  }
                />
              </div>
            </div>
          )}
          <div className="row CrmForm-row">
            <div className="col-md-12">
              {!this.state.user && (
                <span>
                  <FormattedMessage
                    id="crm.ui.account.convert.please_select_user"
                    defaultMessage="Please select user"
                  />
                </span>
              )}
              <fieldset disabled={!isValid} className="CrmForm-form-fieldset">
                <CrmButton
                  label={
                    <FormattedMessage
                      id="crm.ui.button.assign_account"
                      defaultMessage="Assign Account"
                    />
                  }
                  type="button"
                  onClick={this.handleClickConvert}
                />
              </fieldset>
            </div>
          </div>
        </div>
      </CrmModal>
    )
  }
}

ConvertLeadToAccountModal.defaultProps = {
  sectionName: 'Overview'
}

ConvertLeadToAccountModal.propTypes = {
  account: shape({}).isRequired,
  accountActions: shape({}).isRequired,
  onClose: func.isRequired
}

export default ConvertLeadToAccountModal
