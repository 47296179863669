/* eslint-disable camelcase */
import { qlqListOpportunities } from 'crm-data/opportunities'
import { mapContextToFilter } from './rms-dashboard-utils'

const parseOpportunitiesPipeline = data => {
  const users = {}
  data.forEach(row => {
    if (!users[row.owner_user_uid]) {
      users[row.owner_user_uid] = {
        user_uid: row.owner_user_uid,
        user_name: row.owner_user_name,
        opportunitiesPipeline: {},
        mainKpis: []
      }
    }
    const user = users[row.owner_user_uid]
    const stepCode = row.current_step_name ? row.current_step_name : 'empty'
    if (stepCode) {
      user.opportunitiesPipeline[stepCode] = {
        count: 0,
        size: 0,
        kind: stepCode
      }

      user.opportunitiesPipeline[stepCode].size += Number(
        row.opportunities_size
      )
      user.opportunitiesPipeline[stepCode].count += Number(row.opportunity_uid)
    }
  })

  return users
}
export const mock = 1
export const loadOpportunitiesPipeline = async params => {
  console.log('loadOpportunitiesPipeline')

  const filter = mapContextToFilter(params, true)
  filter.completed_date = 'null'
  filter.is_deleted = false
  const variables = {
    filter,
    fields: [
      'owner_user_name',
      'owner_user_uid',
      'current_step_name',
      'current_process_step_kind',
      'current_process_step_code'
    ],
    aggregate: [
      { id: 'opportunity_uid', type: 'count' },
      { id: 'opportunities_size', type: 'sum' }
    ]
  }

  const res = await params.client.query({
    query: qlqListOpportunities,
    variables,
    fetchPolicy: 'no-cache'
  })
  console.log('Got OpportunitiesPipeline result', res)
  if (res && res.data && res.data.opportunities_list_qm_paged) {
    return parseOpportunitiesPipeline(res.data.opportunities_list_qm_paged.data)
  }
  return undefined
}
