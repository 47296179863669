/* eslint camelcase: ["error", {properties: "never"}] */
import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import moment from 'moment'
import { object, string, arrayOf, func, bool, oneOfType } from 'prop-types'
import validate from 'crm-utils/validates'
import {
  CrmTextInput,
  CrmFormSection,
  CrmButton,
  CrmDropdown,
  CrmValidatedField,
  CrmDayPickerInput,
  CrmUserSelect,
  CrmUsersSelect,
  CrmLoadingIcon,
  CrmPersonSelect,
  CrmTerritorySelect,
  CapDatatypeSelect,
  CapDatatypeFieldSelect,
  CapFunctionSelect,
  CrmOpportunityTypeSelect,
  CrmProfileSelect,
  CrmInstanceSelect,
  CrmPermissionSelect,
  CrmDocumentTypeSelect,
  CrmDictionaryValueSelect,
  CrmProductsSelect,
  CrmPackageItemSelect,
  CrmDateRangeDropdown
} from 'crm-components'

class ReportForm extends Component {
  constructor(props, context) {
    super(props, context)
    this._getReportOption = this._getReportOption.bind(this)
    this.getSelectorDropdown = this.getSelectorDropdown.bind(this)
    this.dropdown = {
      CrmUserSelect,
      CrmUsersSelect,
      CrmLoadingIcon,
      CrmPersonSelect,
      CrmTerritorySelect,
      CapDatatypeSelect,
      CapDatatypeFieldSelect,
      CapFunctionSelect,
      CrmOpportunityTypeSelect,
      CrmProfileSelect,
      CrmInstanceSelect,
      CrmPermissionSelect,
      CrmDocumentTypeSelect,
      CrmDictionaryValueSelect,
      CrmProductsSelect
    }

    this.dateRangeName = undefined
    this.handleDateRange = this.handleDateRange.bind(this)
    this.state = {
      ranges: {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Previous Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ],
        'Next Month': [
          moment()
            .add(1, 'month')
            .startOf('month'),
          moment()
            .add(1, 'month')
            .endOf('month')
        ]
      },
      startDate: moment().subtract(29, 'days'),
      endDate: moment()
    }
  }

  componentWillMount() {
    this.dateRangeName = undefined
  }

  handleDateRange(value) {
    this.setState({
      startDate: value.startDate,
      endDate: value.endDate
    })
    this.props.onSetField(this.dateRangeName, {
      start: value.startDate,
      end: value.endDate
    })
  }

  _getReportOption() {
    let reports = [{ name: '0 - Please select report', value: '0' }]
    this.props.reports.forEach(obj => {
      reports.push({ name: obj.name, value: obj.report_uid })
    })
    reports = reports.sort((a, b) =>
      a.name ? a.name.localeCompare(b.name) : 0
    )
    return reports
  }

  getField(field) {
    let component = undefined // eslint-disable-line
    if (field.type === 'text') {
      component = CrmTextInput
    } else if (field.type === 'date') {
      component = CrmDayPickerInput
    } else if (field.type === 'dropdown') {
      component = this.getSelectorDropdown(field.component)
    }

    if (component) {
      return (
        <Field
          name={field.name}
          component={component}
          placeholder={field.label}
          validate={[validate.required]}
        />
      )
    }

    // For custom component
    if (field.type === 'date_range') {
      this.dateRangeName = field.name
      this.props.onSetField(this.dateRangeName, {
        start: this.state.startDate,
        end: this.state.endDate
      })
      return (
        <div>
          <CrmDateRangeDropdown
            placeholder={'Select date range'}
            onChange={this.handleDateRange}
            input={{
              value: {
                startDate: this.state.startDate,
                endDate: this.state.endDate
              }
            }}
          />
        </div>
      )
    }

    if (!component) return ''
  }

  getSelectorDropdown(type) {
    if (type in this.dropdown) {
      return this.dropdown[type]
    }

    return null
  }

  renderFields() {
    let inputParams = []
    const fields = []
    this.props.reports.forEach(obj => {
      if (obj.report_uid === this.props.reportSelected) {
        inputParams = JSON.parse(obj.input_parameters)
      }
    })

    if (Array.isArray(inputParams)) {
      inputParams.forEach(field => {
        const fieldInput = this.getField(field)
        fields.push(
          <div className="row report-form-row">
            <div className="col-xs-12 col-md-12 col-lg-8">{fieldInput}</div>
          </div>
        )
      })
    }

    return fields
  }

  render() {
    const { handleSubmit } = this.props
    const layouts = [
      { value: 'new', name: 'Flat - no pages' },
      { value: 'old', name: 'With pages' }
    ]
    return (
      <form onSubmit={handleSubmit}>
        <CrmFormSection sectionName="Report type">
          <fieldset className="Form-fieldset CrmForm-form-fieldset">
            <div className="row report-form-row">
              <div className="col-xs-12 col-md-12 col-lg-8">
                <CrmValidatedField
                  name={`report`}
                  component={CrmDropdown}
                  componentProps={{
                    placeholder: 'Report',
                    options: this._getReportOption()
                  }}
                  isRequired
                />
              </div>
            </div>
            <div className="row report-form-row">
              <div className="col-xs-12 col-md-12 col-lg-8">
                <CrmPackageItemSelect type="report" />
              </div>
            </div>
            <div>{this.renderFields()}</div>
            <div className="row report-form-row">
              <div className="col-xs-12 col-md-12 col-lg-8">
                <CrmButton
                  label={this.props.isGenerating ? 'Running...' : 'Run'}
                  type="submit"
                  isSaving={this.props.isGenerating}
                />
              </div>
            </div>
            <div className="row report-form-row">
              <div className="col-xs-12 col-md-12 col-lg-8">
                <CrmDropdown
                  options={layouts}
                  input={{
                    value: this.props.layout,
                    onChange: this.props.onLayoutChanged
                  }}
                />
              </div>
            </div>
          </fieldset>
        </CrmFormSection>
      </form>
    )
  }
}

ReportForm.propTypes = {
  handleSubmit: oneOfType([object, func]).isRequired,
  reportSelected: string,
  reports: arrayOf(object).isRequired,
  onSetField: func.isRequired,
  onLayoutChanged: func.isRequired,
  layout: string.isRequired,
  isGenerating: bool.isRequired
}

ReportForm.defaultProps = {
  reportSelected: undefined
}

const ReportReduxForm = reduxForm({
  form: 'crmReport'
})(ReportForm)

export default ReportReduxForm
