/* eslint-disable camelcase */
import React from 'react'
import { shape, func } from 'prop-types'
import withLeadsPopupHoc from '../../../components/_with-leads-popup-hoc.jsx'
import WidgetFitmentsByUserAndSource from './widget-fitments-by-user-and-source.jsx'
import WidgetFitmentsByDate from './widget-fitments-by-date.jsx'
import WidgetFitmentsByLeadSourceType from './widget-fitments-by-lead-source-type.jsx'
import WidgetFitmentsArpuByRM from './widget-fitments-arpu-by-rm.jsx'
import WidgetFitmentsArpuByDepartment from './widget-fitments-arpu-by-department.jsx'

class WhiteBoardWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  parseData(data) {
    // console.log('parseData', data)
    const parsed = data.rows.sort((a, b) =>
      a.user_name ? a.user_name.localeCompare(b.user_name) : 0
    )
    this.setState({ rows: parsed })
  }

  handleAnalyticsRangeSelected = params => {
    // console.log('handleAnalyticsRangeSelected', params)
    this.props.onShowLeadsPopup(params)
  }

  handleBack = () => {
    this.setState({ expandData: undefined })
  }

  render() {
    console.log('Joe Dashboard Render')
    return (
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <WidgetFitmentsByUserAndSource
              analyticsContext={this.props.analyticsContext}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <WidgetFitmentsByDate
              analyticsContext={this.props.analyticsContext}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>

          <div className="col-md-4">
            <WidgetFitmentsByDate
              title="Outbound - by Date"
              analyticsContext={{
                ...this.props.analyticsContext,
                filters: {
                  ...this.props.analyticsContext.filters,
                  account: {
                    lead_campaign: {
                      lead_source: {
                        kind: {
                          value: 'outbound',
                          label: 'Outboubd',
                          filterName: 'Lead Source'
                        }
                      }
                    }
                  }
                }
              }}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>

          <div className="col-md-4">
            <WidgetFitmentsByDate
              title="Digital - by date"
              analyticsContext={{
                ...this.props.analyticsContext,
                filters: {
                  ...this.props.analyticsContext.filters,
                  account: {
                    lead_campaign: {
                      lead_source: {
                        kind: {
                          value: 'digital',
                          label: 'Digital'
                        }
                      }
                    }
                  }
                }
              }}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <WidgetFitmentsArpuByRM
              analyticsContext={this.props.analyticsContext}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>
          <div className="col-md-4">
            <WidgetFitmentsByLeadSourceType
              analyticsContext={this.props.analyticsContext}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>
          <div className="col-md-4">
            <WidgetFitmentsArpuByDepartment
              analyticsContext={this.props.analyticsContext}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>
        </div>
      </div>
    )
  }
}

WhiteBoardWidget.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

export default withLeadsPopupHoc(WhiteBoardWidget)
