export const useGetDashboard = (dashboard: any) => {
  let widget = dashboard?.item?.json
  if (!widget) {
    widget = {
      label: dashboard?.item?.name,
      dashboard_uid: dashboard?.value,
      code: dashboard?.value,
      content: {
        items: [
          {
            type: 'special_widget',
            code: dashboard?.value
          }
        ]
      }
    }
    if (['ctDailyLeadsResultsB2B', 'ctDailyLeadsResultsB2C'].includes(dashboard?.value)) {
      widget.defaultFilters = {
        department_uid: {
          value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
          label: 'Telesales'
        }
      }
      widget.content = {
        items: [
          {
            type: 'special_widget',
            code: 'ctDailyLeadsDashboard'
          }
        ]
      }
    }

    if (['ctMonthlyLeadsResultsDashboardB2B', 'ctMonthlyLeadsResultsDashboardB2C'].includes(dashboard?.value)) {
      widget.defaultFilters = {
        department_uid: {
          value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
          label: 'Telesales'
        }
      }
      widget.content = {
        items: [
          {
            type: 'special_widget',
            code: 'ctMonthlyLeadsDashboard'
          }
        ]
      }
    }
  }

  return [widget]
}
