import gql from 'graphql-tag'

export const qlqListcqCommandAudit = gql`
  query cqcommand_audit_list_qm_paged(
    $sort: [JSON]
    $offset: Int
    $limit: Int
    $filter: JSON
    $fields: [String]
    $aggregate: [JSON]
  ) {
    cqcommand_audit_list_qm_paged(
      sort: $sort
      offset: $offset
      limit: $limit
      filter: $filter
      aggregate: $aggregate
      fields: $fields
    ) {
      data {
        command_uid
        account_name
        account_uid
        activity_uid
        process_uid
        type
        result
        user_uid
        user_name
        aggregate_type
        created_time
        meta
        payload
      }
      count
    }
  }
`
export const mock = 1
