import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BaseContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 8px;
`
const MeContainer = styled(BaseContainer)`
  justify-content: center;
  height: 18px;
  width: 18px;
  background-color: #5099ce;
  color: #ffffff;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  border-radius: 18px;
`

const OtherContainer = styled(BaseContainer)`
  color: #333333; !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  svg {
    color: #999999 !important;
    font-size: 13px;
  }
`
interface Props {
  isOwner?: boolean
  performer?: {
    user: {
      user_uid: string
      full_name: string
      email: string
    }
  }
}
const ActivityPerformer = (props: Props) => {
  if (props.isOwner) {
    return <MeContainer>ME</MeContainer>
  }
  return (
    <OtherContainer>
      <FontAwesomeIcon icon="user" />
      {props?.performer?.user?.full_name}
    </OtherContainer>
  )
}

export default ActivityPerformer
