import React from 'react'
import moment from 'moment'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
type HorizontalTableProps = {
  getColumnFromCombineDataSource?: {
    field?: string
    isDate?: boolean
    groupBy?: 'day' | 'date' | 'month' | 'year'
  }
  data?: any
  item?: {
    props?: any
    columns: Array<Column>
    rows: Array<Row>
  }
  analyticsContext: any
  hasTotalField?: boolean
  exceptTotalField?: string[]
  rawData?: any
  onShowLeadsPopup?: any
}
type Row = {
  label: string
  field: string
  summaryOnly?: boolean
  isCurrency?: boolean
  options?: any
  detailsPopup?: string
  detailsFilters?: string
  detailsFilterMapping?: any
}

type GetColumn = () => Array<Column>
type Column = { header?: string; subHeader?: string }

export const HorizontalTable: React.SFC<HorizontalTableProps> = props => {
  const context = useAnalyticsContext()
  const getColumns: GetColumn = () => {
    let columns = []
    if (props?.getColumnFromCombineDataSource) {
      const key = props?.getColumnFromCombineDataSource?.field
      const isDate = props?.getColumnFromCombineDataSource?.isDate
      const groupBy = props?.getColumnFromCombineDataSource?.groupBy ?? 'month'
      const getFormat = groupBy => {
        let format = 'YYYY-MMM'
        if (groupBy === 'day' || groupBy === 'date') {
          format = 'YYYY-MMM-DD'
        }
        return format
      }
      const format = getFormat(groupBy)
      columns = props?.data?.map(el => {
        if (isDate) {
          const header = moment(el?.[key]).format(format)
          const subHeader = moment(el?.[key]).format('YYYY')
          return { header, subHeader }
        } else {
          return { header: el[key] }
        }
      })
    } else if (props?.item?.columns) {
      columns = props?.item?.columns
    }
    return columns
  }
  let data = props?.data ?? []
  if (props.hasTotalField) {
    data.total = props.rawData?.dataSources?.[props.item?.props?.data?.dataSourceCode]?.rawData?.total
  }
  const getFilterByHeader = (columnParam, field) => {
    if (props.getColumnFromCombineDataSource && props.getColumnFromCombineDataSource.isDate) {
      const { groupBy } = props.getColumnFromCombineDataSource
      if (groupBy === 'month') {
        return {
          value: {
            $gte: moment(columnParam[field], 'YYYY-MMM')
              .startOf('month')
              .format('YYYY-MM-DD'),
            $lte: moment(columnParam[field], 'YYYY-MMM')
              .endOf('month')
              .format('YYYY-MM-DD')
          }
        }
      }

      if (groupBy === 'day' || groupBy === 'date') {
        return {
          value: {
            $gte: moment(columnParam[field], 'YYYY-MMM-DD').format('YYYY-MM-DD'),
            $lte: moment(columnParam[field], 'YYYY-MMM-DD').format('YYYY-MM-DD')
          }
        }
      }
    }
    return columnParam[field]
  }
  const getColumnsFilters = (columnParam, detailFilters) => {
    let filters = {}
    Object.keys(detailFilters).forEach(key => {
      filters[key] = getFilterByHeader(columnParam, detailFilters[key])
    })
    return filters
  }
  const onPopUpDetail = (column, detailsPopup, detailFilters = {}, detailsFilterMapping = {}) => {
    const columnFilters = getColumnsFilters(column, detailsFilterMapping)
    const type = detailsPopup ?? null
    const filters = {
      ...detailFilters,
      ...(context?.filters ?? {}),
      ...columnFilters
    }
    if (props?.onShowLeadsPopup) {
      props.onShowLeadsPopup({ type, filters })
    }
  }
  const columns = getColumns()
  const renderRow = (row: any, data) => {
    var label = row?.label ? row.label : row.name
    var field = row.data ? row.data.field : row.field
    var isCurrency = row.isCurrency
    var summaryOnly = row.summaryOnly
    var options = row.options ?? {}
    var detailsPopup = row.detailsPopup
    var detailFilters = row.detailsFilter
    var detailsFilterMapping = row.detailsFilterMapping

    return (
      <tr>
        <td style={{ width: 150, minWidth: 150 }} className="CrmFlatTable-tbody-td">
          {label}
        </td>
        {props.hasTotalField && (
          <td className="util-textRight CrmFlatTable-tbody-td" style={{ width: '7%' }} onClick={() => null}>
            <strong>
              {data?.total?.[field] &&
                (isCurrency
                  ? Number(data.total[field]).toLocaleString(undefined, {
                      maximumFractionDigits: 0
                    })
                  : data?.total?.[field])}
              {options?.isPercent && '%'}
            </strong>
          </td>
        )}
        {!summaryOnly &&
          columns.map((column, index) => {
            return (
              <td
                key={index}
                className="CrmFlatTable-tbody-td util-textRight util-pointer"
                onClick={() => onPopUpDetail(column, detailsPopup, detailFilters, detailsFilterMapping)}
                data-date={data?.[index]?.date}
              >
                {data?.[index]?.[field] !== undefined &&
                  (isCurrency
                    ? Number(data[index][field]).toLocaleString(undefined, {
                        maximumFractionDigits: 0
                      })
                    : data?.[index]?.[field])}
                {data?.[index]?.[field] === undefined && '-'}
                {data?.[index]?.[field] !== undefined && options?.isPercent && '%'}
              </td>
            )
          })}
      </tr>
    )
  }

  let horizontalRows: any = props?.item?.props?.rows ? props?.item?.props?.rows : props?.item?.props?.columns
  if (!horizontalRows) {
    horizontalRows = props.item.rows
  }
  return (
    <React.Fragment>
      <table className="CrmFlatTable">
        <thead
          className="CrmFlatTable-thead--no-border CrmFlatTable-thead--small util-backgroundGray35"
          style={{ border: 'unset' }}
        >
          <tr>
            <th />
            {props.hasTotalField && (
              <th>
                <strong>Total</strong>
              </th>
            )}
            {columns.map((column, index) => {
              return (
                <th key={index}>
                  <div className="util-textRight">
                    {column?.header} <br />
                    <label className="util-textSmall">{column?.subHeader}</label>
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className="CrmFlatTable-tbody ct-monthly-leads-dashboard">
          {horizontalRows ? horizontalRows.map(row => renderRow(row, data)) : <span> No fields defined </span>}
        </tbody>
      </table>
    </React.Fragment>
  )
}
