import React from 'react'
import { string } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'

const CrmProcessTypeSelect = props => {
  const variables = {}
  const qlq = gql`
    {
      process_types {
        process_type_uid
        name
        kind
        code
        is_default
      }
    }
  `
  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.process_types
            ? data.process_types.map(v => ({
                ...v,
                value: v.process_type_uid
              }))
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_process_type"
                  defaultMessage="Select Process Type"
                />
              )
            }
          />
        )
      }}
    </Query>
  )
}

CrmProcessTypeSelect.propTypes = {
  placeholder: string
}

export default CrmProcessTypeSelect
