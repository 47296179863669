import React, { PureComponent } from 'react'
import { func, shape } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { CrmTextInput, CrmButton } from 'crm-components'

class EmailForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShowAddForm: false,
      email: undefined
    }
  }

  handleSubmit = () => {
    this.props.onClose()
    this.props.onCreateEmail(this.props.account.account_uid, this.state.email)
  }

  handleEmailChange = event => {
    this.setState({ ...this.state, email: event.target.value })
  }

  render() {
    return (
      <div className="col-md-12" style={{ marginBottom: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: '1' }}>
            <CrmTextInput
              name="email"
              placeholder="Create email contact"
              placeholderId="crm.ui.activity.create_email_contact"
              id="email"
              input={{
                onChange: this.handleEmailChange,
                value:
                  typeof this.state.email === 'undefined'
                    ? ''
                    : this.state.email
              }}
            />
          </div>

          <div style={{ width: '40px' }}>
            <CrmButton
              xsmall
              label={
                <FormattedMessage
                  id="crm.ui.button.save"
                  defaultMessage="Save"
                />
              }
              onClick={this.handleSubmit}
            />
          </div>
          <div style={{ width: '40px' }}>
            <CrmButton
              xsmall
              label={
                <FormattedMessage
                  id="crm.ui.button.cancel"
                  defaultMessage="Cancel"
                />
              }
              onClick={this.props.onClose}
            />
          </div>
        </div>
      </div>
    )
  }
}

EmailForm.propTypes = {
  onCreateEmail: func.isRequired,
  onClose: func.isRequired,
  account: shape({}).isRequired
}

EmailForm.defaultProps = {}

export default EmailForm
