import React from 'react';
import { func, arrayOf, shape, string, number, bool } from 'prop-types';
import { CrmFormInputDropdown } from 'crm-components';
import { callQLApi } from './../../../api/api-utils'

class BusinessSourceSelect extends React.Component {
  state = {
    options: [],
    selectedOption: '',
    loading: true,
  };

  componentDidMount () {
    const qlBusinessSource = `{ business_sources { name } }`;
    callQLApi(qlBusinessSource).then(res => {
      const options = res.business_sources ? res.business_sources.map(source => ({
        value: source.name,
        name: source.name,
      })) : [];
      this.setState({ options, loading: false });
    });
  }

  observerSelectedChange = value => {
    this.setState({ selectedOption: value });
    if (typeof this.props.onChange === 'function') {
      const item = this.state.options.find(o => o.value === value);
      this.props.onChange(value, item);
    }
  };

  render () {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <CrmFormInputDropdown
        {...this.props}
        options={this.state.options}
        value={this.state.selectedOption}
        onChange={this.observerSelectedChange}
        addEmptyOption
      />
    );
  }
}

BusinessSourceSelect.propTypes = {
  onChange: func,
  options: arrayOf(shape({})),
};

BusinessSourceSelect.defaultProps = {
  options: [],
};

export default BusinessSourceSelect;
