export default function cleanProps(props, delProps) {
  const newProps = { ...props }

  // eslint-disable-next-line no-unused-vars
  for (const prop of delProps) {
    delete newProps[prop]
  }

  return newProps
}
