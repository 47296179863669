/* eslint-disable react/prop-types */
/* eslint-disable global-require, camelcase */
import React, { PureComponent } from 'react'
import moment from 'moment'
import { hhmmss } from 'crm-utils/common-utils'
import {
  arrayOf,
  array,
  object,
  bool,
  string,
  oneOfType,
  func
} from 'prop-types'
import { FormattedMessage } from 'react-intl'
import getIdFormat from 'crm-utils/translation-utils'

class FlatTable extends PureComponent {
  constructor(props) {
    super(props)
    const state = {
      ...this._buildState(props)
    }

    if (props.sortField) {
      state.sortField = props.sortField
    }

    if (props.sortOrder) {
      state.sortOrder = props.sortOrder
    }

    this.state = state
  }

  _buildState(props) {
    const columns = props.generateColumns
      ? this._generateColumns(props)
      : props.columns
    const summaryRow = this.calculateSummaryRow({ columns })

    return {
      columns,
      summaryRows: [summaryRow]
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = nextProps => {
    if (this.props.data !== nextProps.data) {
      this.setState(this._buildState(nextProps))
    }
  }

  handleHeaderClick = event => {
    event.preventDefault()
    const key = event.currentTarget.id

    if (!key) return

    if (this.state.sortField === key) {
      const newSortOrder = this.state.sortOrder === 'asc' ? 'desc' : undefined
      this.setState({
        sortOrder: newSortOrder,
        sortField: newSortOrder ? key : undefined
      })
    } else {
      this.setState({ sortOrder: 'asc', sortField: key })
    }
  }

  getColumnHeaderValue = ({ Header, labelMessageId }) =>
    typeof Header === 'string' &&
    this.props.tableName !== undefined &&
    this.props.translation ? (
      <FormattedMessage
        id={
          labelMessageId || this.getTranslationKey(Header, this.props.tableName)
        }
        defaultMessage={Header}
      />
    ) : (
      Header
    )

  getTranslationKey(key, tableName) {
    return `crm.ui.table.${tableName}.header.${getIdFormat(key)}`
  }

  _renderHead({ columns }) {
    return (
      <thead className="CrmFlatTable-thead">
        {this.props.columnGroups && (
          <tr>
            {this.props.columnGroups.map((cg, index) => (
              <th
                key={index}
                colSpan={cg.colSpan}
                className={`${cg.className || ''}`}
              >
                <div className="util-textCenter">{cg.Header}</div>
              </th>
            ))}
          </tr>
        )}
        <tr>
          {columns.map(col => {
            const style = {}

            if (col.width) {
              style.width = col.width
            }

            if (col.maxWidth) {
              style.maxWidth = col.maxWidth
            }

            return (
              <th
                key={col.key ? col.key : col.Header}
                style={style}
                className={`CrmFlatTable-thead-th ${
                  this.state.sortField === col.accessor
                    ? 'CrmFlatTable-thead-sort'
                    : ''
                } ${col.headerAlign === 'right' ? 'th-right' : ''} ${
                  col.headerAlign === 'center' ? 'th-center' : ''
                } ${col.headerClassName ? col.headerClassName : ''}`}
                onClick={this.handleHeaderClick}
                id={col.accessor}
              >
                {col.headerRenderer ? (
                  col.headerRenderer()
                ) : (
                  <React.Fragment>
                    {this.getColumnHeaderValue(col)}
                    {this.state.sortField === col.accessor && col.accessor && (
                      <i
                        className={`fa ${
                          this.state.sortOrder === 'asc'
                            ? 'fa-sort-asc'
                            : 'fa-sort-desc'
                        }`}
                      />
                    )}
                  </React.Fragment>
                )}
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }

  handleClickAccount = () => {}

  getCellValue = (row, colDef) => {
    let value

    if (colDef.type === 'number') {
      let numValue

      if (colDef.accessorFn) {
        numValue = Number(colDef.accessorFn(row))
      } else {
        numValue = Number(row[colDef.accessor])
      }

      if (!isNaN(numValue)) {
        numValue = numValue.toLocaleString(undefined, {
          maximumFractionDigits: colDef.maximumFractionDigits || 0
        })

        value = numValue
      }
    } else {
      value = colDef.accessorFn ? colDef.accessorFn(row) : row[colDef.accessor]
    }

    if (colDef.type === 'time') {
      const timeFormat = colDef.format || 'HH:MM'
      const t = moment(value)

      value = t.format(timeFormat)
    } else if (colDef.type === 'interval') {
      value = hhmmss(value)
    }

    return value
  }

  _renderCell = (col, row) => {
    const handleClick = () => {
      if (col.onClick) {
        col.onClick(row, col)
      }
    }

    const additionalClassNames = col.classNameHandler
      ? col.classNameHandler({ original: row })
      : ''

    if (col.Cell && typeof col.Cell === 'function') {
      const renderedCell = col.Cell({ original: row, onClick: handleClick }) // eslint-disable-line

      if (typeof renderedCell === 'string') {
        return (
          <div className={` ${additionalClassNames}`} onClick={handleClick}>
            {renderedCell}
          </div>
        )
      }

      return renderedCell
    }

    if (col.accessor === 'account_name') {
      return (
        <a
          id={row ? row.account_uid : 'empty'}
          href={`/crm/account/${row.account_uid}`}
          onClick={this.handleClickAccount}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          {row[col.accessor]}
        </a>
      )
    }

    const cellValue = this.getCellValue(row, col)

    return (
      <div className={`${additionalClassNames}`} onClick={handleClick}>
        {cellValue}
      </div>
    )
  }

  handleRowClick = event => {
    if (this.props.onRowClick) {
      let item = this.props.data.find(
        item => item.id === event.currentTarget.id
      )

      if (!item) {
        item = this.state.summaryRows.find(r => r.id === event.currentTarget.id)
      }

      this.props.onRowClick(item)
    }
  }

  _renderData(params) {
    let sortedData =
      this.props.data && Array.isArray(this.props.data)
        ? this.props.data.slice()
        : []
    const { columns } = params

    if (this.state.sortField) {
      const sortCol = columns.find(c => c.accessor === this.state.sortField)

      if (sortCol) {
        const getCellValue = row =>
          sortCol.accessorFn ? sortCol.accessorFn(row) : row[sortCol.accessor]

        const compareFn =
          sortCol.type === 'number' || sortCol.type === 'interval'
            ? (a, b) => {
                return (
                  (Number(getCellValue(a) || 0) -
                    Number(getCellValue(b) || 0)) *
                  (this.state.sortOrder === 'desc' ? -1 : 1)
                )
              }
            : (a, b) => {
                const va = getCellValue(a) || ''
                const vb = getCellValue(b) || ''

                if (!va.localeCompare || !vb.localeCompare) {
                  return 0
                }

                return (
                  va.localeCompare(vb) *
                  (this.state.sortOrder === 'desc' ? -1 : 1)
                )
              }

        sortedData = sortedData.sort(compareFn)
      }
    }

    sortedData = sortedData.map((i, index) => ({ index: index + 1, ...i }))

    return (
      <tbody className="CrmFlatTable-tbody">
        {sortedData.map((row, index) => {
          return this.renderRow(row, params, index)
        })}
        {this.renderSummaries(params)}
      </tbody>
    )
  }

  renderRow(row, { columns }, rowIndex = undefined) {
    return (
      <tr
        key={rowIndex}
        id={row.id}
        className={`CrmFlatTable-tbody-tr ${
          row.className ? row.className : ''
        }`}
        onClick={this.handleRowClick}
      >
        {columns.map(col => (
          <td
            key={`row ${row.id} ${col.key ? col.key : col.Header}`}
            className={`CrmFlatTable-tbody-td
            ${col.align === 'right' ? 'util-textRight' : ''}
            ${col.align === 'center' ? 'util-textCenter' : ''}
            ${col.className ? col.className : ''}`}
          >
            {this._renderCell(col, row)}
          </td>
        ))}
      </tr>
    )
  }

  calculateSummaryRow = params => {
    const summableColumns = params.columns.filter(
      c => c.type === 'number' || c.type === 'float' || c.type === 'integer'
    )

    const total =
      this.props.data && this.props.data.reduce
        ? this.props.data.reduce(
            (a, i) => {
              const t = { ...a }

              summableColumns.forEach(col => {
                if (col.nestedField && !t[col.nestedField]) {
                  t[col.nestedField] = {}
                }

                const num = col
                  ? Number(
                      col.nestedField
                        ? i[col.nestedField]
                          ? i[col.nestedField][col.accessor]
                          : undefined
                        : i[col.accessor]
                    )
                  : undefined

                if (num && !isNaN(num)) {
                  if (col.nestedField) {
                    t[col.nestedField][col.accessor] = t[col.nestedField][
                      col.accessor
                    ]
                      ? t[col.nestedField][col.accessor] + num
                      : num
                  } else {
                    t[col.accessor] = t[col.accessor]
                      ? t[col.accessor] + num
                      : num
                  }
                }
              })

              return t
            },
            {
              isSummary: true,
              className: 'util-textBold CrmFlatTable-summary',
              id: 'summary'
            }
          )
        : 0

    return total
  }

  renderSummaries(params) {
    const ret = []

    if (this.props.generateSummary) {
      const summaryRow = this.calculateSummaryRow(params)
      ret.push(this.renderRow(summaryRow, params))
    }

    return ret
  }

  getColumnKey = key => {
    if (typeof key === 'string') {
      return key
    }

    return Math.random()
      .toString(36)
      .substring(8)
  }

  _renderTable(params) {
    return (
      <table
        className={`CrmFlatTable ${
          this.props.hScrollable ? ' CrmFlatTable-hScrollable' : ''
        } ${this.props.scrollBody ? ' CrmFlatTable--scrollBody' : ''} ${
          this.props.bordered ? ' CrmFlatTable--bordered' : ''
        }`}
      >
        {this._renderHead(params)}
        {this.props.data &&
          this.props.data.length > 0 &&
          this._renderData(params)}
        {!this.props.data ||
          (this.props.data.length === 0 && (
            <tbody>
              <tr>
                <td
                  colSpan={params.columns ? params.columns.length : 1}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    color: '#ccc',
                    fontSize: 40
                  }}
                >
                  No records loaded
                </td>
              </tr>
            </tbody>
          ))}
      </table>
    )
  }

  _generateColumns(props) {
    const ret = props.data
      ? Object.values(
          props.data.reduce((a, i) => {
            Object.keys(i).forEach(k => (a[k] = k))
            return a
          }, {})
        )
      : []

    const columns = ret.map(name => ({
      accessor: name,
      Header: name
    }))

    return columns.slice(0, 5)
  }

  render() {
    const { columns } = this.state

    if (this.props.hScrollable) {
      return (
        <div className="CrmFlatTable-hScrollable-wrapper">
          {this._renderTable({ columns })}
        </div>
      )
    }

    return this._renderTable({ columns })
  }
}

FlatTable.propTypes = {
  data: oneOfType([array, func]).isRequired,
  columns: arrayOf(object).isRequired,
  hScrollable: bool,
  translation: bool,
  generateColumns: bool,
  tableName: string,
  bordered: bool,
  generateSummary: bool,
  generateAverage: bool
}

FlatTable.defaultProps = {
  data: [],
  hScrollable: false,
  translation: false,
  tableName: undefined,
  generateColumns: false,
  bordered: false,
  generateSummary: false,
  generateAverage: false
}

export default FlatTable
