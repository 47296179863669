import styled from 'styled-components'

export const ContactPersonStyled = styled.div`
  .SectionContactPerson {
    color: #333;
    font-size: 12px;
  }

  .PersonItemsWrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .PersonFormHeader {
    margin: 10px 0px;
    text-align: center;
    font-weight: bold;
  }

  .AddNewPersonIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 55px;
    font-size: 2em;
  }

  .PersonItem {
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: default;
    justify-content: center;
    line-height: 20px;
    opacity: 0.5;
    text-align: center;

    &__ContentWrapper {
      display: flex;
      justify-content: space-between;
    }

    &__ContentAction {
      margin: 5px 8px 0px 0px;
      font-size: 18px;
      display: flex;
      flex-direction: row;
    }

    &__Content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      text-align: left;
      margin: 10px 10px;

      .PersonName {
        font-size: 18px;
        margin: 5px 0px;
      }

      .Department {
      }
    }

    &:hover {
      opacity: 1;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

      .PersonItem__NoSelectedIcon {
        display: initial;
      }

      .PersonItem__SelectedIcon {
        display: initial;
      }

      .PersonItem__EditIcon {
        display: initial;
      }
    }

    &__NoSelectedIcon {
      cursor: pointer;
      color: grey;
      display: none;
    }

    &__SelectedIcon {
      cursor: pointer;
      color: #5bae5f;
    }

    &__EditIcon {
      cursor: pointer;
      color: grey;
      display: none;
    }
  }

  .SelectedItem {
    opacity: 1;
  }
`
