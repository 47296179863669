import {
  FETCH_ACTIVITY_TYPES,
  FETCH_ACTIVITY_TYPES_SUCCESS,
  FETCH_ACTIVITIES_LIST,
  RECEIVE_ACTIVITIES_LIST,
  INIT_ACTIVITIES_MODULE
} from 'crm-duxs/activities-reducer'
import activitiesApi from 'crm-api/activities-api'

import { call, put, takeLatest, select } from 'redux-saga/effects'
import { getUser, CHANGE_SELECTED_INSTANCE_SUCCESS } from 'crm-duxs/crm-reducer'

function* listActivityTypes() {
  const currentUser = yield select(getUser)
  console.log('listActivityTypes', currentUser)
  if (currentUser) {
    const activityTypes = yield call(activitiesApi.listActivityTypesApi)
    // console.log('listActivityTypes result', activityTypes)
    yield put({
      type: FETCH_ACTIVITY_TYPES_SUCCESS,
      payload: { activityTypes }
    })
  }
}

function* listActivities(action) {
  const activities = yield call(activitiesApi.listActivity, action.params)
  yield put({
    type: RECEIVE_ACTIVITIES_LIST,
    payload: activities,
    name: action.name
  })
  if (action.payload.onSuccess) {
    action.payload.onSuccess(activities)
  }
}

function* initActivitiesModule() {
  yield call(listActivityTypes)
}

function* processInitialNextStage() {
  yield call(listActivityTypes)
}

export default function* activitiesSaga() {
  yield takeLatest(FETCH_ACTIVITY_TYPES, listActivityTypes)
  yield takeLatest(FETCH_ACTIVITIES_LIST, listActivities)
  // Observe login change
  yield takeLatest(INIT_ACTIVITIES_MODULE, initActivitiesModule)
  yield takeLatest(CHANGE_SELECTED_INSTANCE_SUCCESS, processInitialNextStage)
  yield call(listActivityTypes)
}
