/* eslint-disable  */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import moment from 'moment'

import { leadsGenerationsQl } from './dash-leads-utils'
import { aggregateBy } from 'crm-utils/data-utils'
import { shape, func } from 'prop-types'
import { mapContextToFilter } from '../../dashboard-utils'

import { CrmButton, CrmLabeledField } from 'crm-components'
import CrmBarChart from 'crm-components/charts/crm-bar-chart.jsx'

const COLORS = ['#65b7f3', '#b2d28c', '#f47735', '#f67960', '#82c6da']

class WidgetMovementsNewLeads extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ ...this.state, isLoading: val }, handler)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== undefined &&
      nextProps.analyticsContext !== this.props.analyticsContext
    ) {
      // this.setState({ ...this.state }, () => {
      // this.handleRefresh()
      // })
    }
  }

  parseData(data) {
    const res = { data }
    const ldata = data.map(i => ({
      ...i,
      value: i.count,
      hour: moment.parseZone(i.lead_in_hour).format('HH:mm')
    }))
    console.log('data with hour ', ldata)
    res.byHour = aggregateBy(ldata, 'hour', 'count')
    res.byLeadSource = aggregateBy(ldata, 'lead_source_name', 'count')
    res.totalLeads = data.reduce((a, i) => a + i.count, 0)
    return res
  }

  handleRefresh = async () => {
    console.log('Widget new leads by source refresh', this.props)
    try {
      const filter = mapContextToFilter(this.props, true)

      const variables = {
        filter,
        fields: ['lead_in_hour', 'lead_source_uid', 'lead_source_name'],
        aggregate: [{ id: 'account_uid', type: 'count' }]
      }

      const res = await this.props.client.query({
        query: leadsGenerationsQl,
        variables,
        fetchPolicy: 'no-cache'
      })

      console.log('Got result', res)

      // parse data
      // if (res.data.leads_generation_analysis_qm_paged) {
      //   const parsed = this.parseData(
      //     res.data.leads_generation_analysis_qm_paged.data
      //   )

      //   this.setState(parsed)
      // }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }

  handleCurrentStepClick = event => {
    console.log('handleCurrentStepClick', event)
    this.setState({ selectedStepTypeUid: event.currentTarget.id })
    this.props.onCurrentStepClick({
      process_step_type_uid: event.currentTarget.id
    })
  }

  renderBlock = (field, label, className) => {
    return this.state[field] ? (
      <CrmLabeledField label={label}>
        <div style={{ fontSize: 30, margin: 10 }} className={className}>
          {this.state[field]}
          <span style={{ fontSize: 10, margin: 3, opacity: 0.7 }}>
            {this.state[field + '_ratio']}%
          </span>
        </div>
      </CrmLabeledField>
    ) : (
      <span />
    )
  }
  renderByHour = () => {
    const resultsParsed = Object.values(this.state.byHour)
      .map((a, i) => ({ ...a, count: a.value, fill: COLORS[i % 5] }))
      .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
    return (
      <div style={{ height: 600 }}>
        <label>By Hour </label>
        <CrmBarChart data={resultsParsed} onClickBar={this.handleClickBar} />
      </div>
    )
  }

  renderByLeadSource = () => {
    const resultsParsed = Object.values(this.state.byLeadSource)
      .map((a, i) => ({ ...a, count: a.value, fill: COLORS[i % 5] }))
      .sort((a, b) => b.value - a.value)
    console.log('renderByLeadSource', resultsParsed)
    return (
      <div style={{ height: 600 }}>
        <label>By Lead Source</label>
        <CrmBarChart data={resultsParsed} onClickBar={this.handleClickBar} />
      </div>
    )
  }

  render() {
    console.log('Render widget new leads by source', this.state)
    const dateRange = this.props.analyticsContext.filters.lead_in_date
    return (
      <div className="Widget CrmWidget util-marginBottom CrmWidget--noHeight">
        <div className="Widget-header row util-noMargin between-xs top-xs">
          <div className="Widget-title CrmWidget-title util-flexRow">
            <span>
              {this.props.title ? this.props.title : 'New Leads in Campaign'}
            </span>

            <span title="Click to refresh">
              <CrmButton label="Refresh Stats" onClick={this.handleRefresh} />
            </span>
            <span>
              Total Leads received: <strong>{this.state.totalLeads}</strong>
            </span>
            {dateRange && dateRange.value && dateRange.value.length > 0 && (
              <div>
                Started between {dateRange.value[0]} and {dateRange.value[1]}
              </div>
            )}
          </div>
        </div>
        <div className="Widget-content CrmWidget-content util-paddingMd">
          <div className="row">
            <div className="col-md-6">
              {this.state.byLeadSource && this.renderByLeadSource()}
            </div>
            <div className="col-md-6">
              {this.state.byHour && this.renderByHour()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

WidgetMovementsNewLeads.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  onCurrentStepClick: func.isRequired
}

export default withApollo(WidgetMovementsNewLeads)
