import React, { PureComponent } from 'react'
import { number, func } from 'prop-types'

const CrmMesssageWithCounterHoc = WrappedComponent => {
  class CrmMesssageWithCounter extends PureComponent {
    static propTypes = {
      count: number,
      onClick: func.isRequired
    }

    static defaultProps = {
      count: 0
    }

    render() {
      return (
        <div className="WithCounterContainer" onClick={this.props.onClick}>
          <WrappedComponent {...this.props} />
          {this.props.count > 0 && (
            <div className="WithCounter-Counter"> {this.props.count} </div>
          )}
        </div>
      )
    }
  }

  return CrmMesssageWithCounter
}

export default CrmMesssageWithCounterHoc
