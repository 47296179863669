import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sectionContactDetailsHoc from 'crm-modules/accounts/hoc/section-contact-details-hoc.js'
import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import ContactDetailItem from './contact-detail-item.jsx'

class SectionContactDetails extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      account,
      person,
      personContactDetails,
      accountContactDetails,
      isSavingPerson
    } = this.props
    const classRow =
      person && (person.isNew || person.isEditing) && !isSavingPerson
        ? ''
        : 'row'
    return (
      <React.Fragment>
        {personContactDetails && (
          <div className="row">
            <div className="col-md-1">
              <span
                className="AccountLeftSide-addButton"
                title={this.props.intl.formatMessage({
                  id: 'crm.account.click_to_add_new_person_contact_details',
                  defaultMessage: 'Click to add new person contact details'
                })}
              >
                <FontAwesomeIcon
                  className="util-hooverable util-pointer"
                  icon="plus"
                  onClick={this.props.onStartAddPersonContactDetail}
                />
              </span>
            </div>
            <div className="col-md-11">
              {personContactDetails.length === 0 && (
                <div className="util-textLight util-textCenter">
                  <small>
                    <FormattedMessage
                      id="crm.account.no_contact_details_for_this_account_click_icon_on_the_left_side_to_add_new_contact_details"
                      defaultMessage="No contact details for this account. Click icon on the left
                side to add new contact details."
                    />
                  </small>
                </div>
              )}
              {personContactDetails.map((contactDetail, index) => {
                if (!contactDetail.isDeleted) {
                  return (
                    <div className={classRow} key={index}>
                      <ContactDetailItem
                        contactGroup="personContactDetails"
                        key={
                          contactDetail.contact_details_uid ||
                          contactDetail.newObjectUid
                        }
                        getCountriesOptions={this.props.getCountriesOptions}
                        getCountriesRegex={this.props.getCountriesRegex}
                        getKindOptions={this.props.getKindOptions}
                        getKindIcons={this.props.getKindIcons}
                        index={index}
                        contactDetail={contactDetail}
                        onRemovePersonContactDetail={
                          this.props.onRemovePersonContactDetail
                        }
                        onPersonContactDetailsFieldChange={
                          this.props.onPersonContactDetailsFieldChange
                        }
                        onRemoveArchivedContactDetail={
                          this.props.onRemoveArchivedContactDetail
                        }
                      />
                    </div>
                  )
                }
              })}
            </div>
          </div>
        )}

        {accountContactDetails && (
          <div className="row">
            <div className="col-md-1">
              <span
                className="AccountLeftSide-addButton"
                title={this.props.intl.formatMessage({
                  id: 'crm.account.click_to_add_new_account_contact_details',
                  defaultMessage: 'Click to add new account contact details'
                })}
              >
                <FontAwesomeIcon
                  className="util-hooverable util-pointer"
                  icon="plus"
                  onClick={this.props.onStartAddAccountContactDetail}
                />
              </span>
            </div>
            <div className="col-md-11">
              {accountContactDetails.length === 0 && (
                <div className="util-textLight util-textCenter">
                  <small>
                    <FormattedMessage
                      id="crm.account.no_contact_details_for_this_account_click_icon_on_the_left_side_to_add_new_contact_details"
                      defaultMessage="No contact details for this account. Click icon on the left
                side to add new contact details."
                    />
                  </small>
                </div>
              )}
              {accountContactDetails.map(contact => {
                return (
                  <React.Fragment key={contact.name}>
                    <div className={`util-flexRow`}>
                      <div className={`LabeledField-value`}>{contact.name}</div>
                    </div>
                    {contact.contactDetails.map((contactDetail, index) => (
                      <div
                        key={
                          contactDetail.contact_details_uid ||
                          contactDetail.newObjectUid
                        }
                        className="row"
                      >
                        <ContactDetailItem
                          contactGroup="accountContactDetails"
                          key={
                            contactDetail.contact_details_uid ||
                            contactDetail.newObjectUid
                          }
                          getCountriesOptions={this.props.getCountriesOptions}
                          getCountriesRegex={this.props.getCountriesRegex}
                          getKindOptions={this.props.getKindOptions}
                          getKindIcons={this.props.getKindIcons}
                          index={index}
                          contactDetail={contactDetail}
                          contactArchivedList={accountContactDetails}
                          onRemoveAccountContactDetail={
                            this.props.onRemoveAccountContactDetail
                          }
                          onRemoveArchivedContactDetail={
                            this.props.onRemoveArchivedContactDetail
                          }
                        />
                      </div>
                    ))}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default injectIntl(sectionContactDetailsHoc(SectionContactDetails))
