/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { shape, string } from 'prop-types'
import AccountInfoFilter from '../accounts/list/components/filter/filters/account-info-filter.jsx'
import TasksTodoTable from './tasks-todo-table.jsx'
import { CrmFullpageContainer } from 'crm-components'
import {
  getFilterGroup,
  getQFilterGroup,
  initFiltersGroup
} from 'crm-duxs/filters-reducer'
import { getUser } from 'crm-duxs/crm-reducer'

const MyTasksView = props => {
  const mergedFilter = {
    ...props.qfilter,
    is_completed: false
  }

  if (props.type === 'user') {
    mergedFilter.assignee_user_uid = props.currentUser.user_uid
    if (props?.qfilter?.owner_user_uid) {
      mergedFilter.assignee_user_uid = props.qfilter.owner_user_uid
    }
    mergedFilter.assignee_type = 'user'
  } else {
    mergedFilter.assignee_type = 'group'
    mergedFilter.assignee_group_uid = { $currentUserGroups: true }
  }

  return (
    <CrmFullpageContainer vbox>
      <div className="util-paddingMd">
        <AccountInfoFilter accountsListCode="my_tasks" />
      </div>
      <TasksTodoTable filter={mergedFilter} exportFileName="My_tasks" />
    </CrmFullpageContainer>
  )
}

MyTasksView.propTypes = {
  qfilter: shape({}).isRequired,
  currentUser: shape({}).isRequired,
  type: string
}

MyTasksView.defaultProps = {
  type: 'user'
}

function mapStateToProps(state) {
  return {
    filters: getFilterGroup(state, 'my_tasks'),
    qfilter: getQFilterGroup(state, 'my_tasks'),
    currentUser: getUser(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doInitFiltersGroup: groupCode => dispatch(initFiltersGroup(groupCode))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTasksView)
