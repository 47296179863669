import moment from 'moment'
const rmsHistoricalPerformance = {
  type: 'item',
  label: 'RM Historical Actuals',
  code: 'rmsHistoricalPerformance',
  dashboard_uid: 'rmsHistoricalPerformance',
  defaultFilters: {},
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'ctDailyLeadsDashboard',
        def_version: 5,
        title: 'RM Historical Performance',
        description_: "Predefined filter 'Corporate RMs'",
        content: {
          data: {
            dataSources: [
              {
                code: 'OrdersListQM',
                name: 'OrdersListQM',
                type: 'QM',
                alias: 'FitmentsCompleted',
                filter: {
                  status_code: 'completed',
                  owner_user_uid: { $ne: 'null' },
                  account_uid: { $ne: 'null' },
                  completed_month: { $ne: 'null' }
                },
                fields: ['owner_user_uid'],
                aggregate: [
                  { id: 'quantity', type: 'sum' },
                  { id: 'subscription', type: 'sum' }
                ],
                contextMapping: {
                  owner: 'user',
                  completed_date: 'activity_date'
                },
                qlQuery: `query orders($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                  orders(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                  edges { edge { owner_user_uid order_uid subscription quantity } } } }`,
                qlVariables: {
                  groupBy: ['owner_user_uid'],
                  limit: 10
                },
                qlEdges: true,
                qlRoot: 'orders'
              },
              {
                code: 'OrdersListQM',
                name: 'OrdersListQM',
                alias: 'FitmentsHistory',
                type: 'QM',
                filter: {
                  owner_user_uid: { $ne: 'null' }
                },
                aggregate: [{ id: 'order_uid', type: 'count' }],
                contextMapping: {
                  account: 'account',
                  owner: 'user'
                },
                qlQuery: `query orders($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                    orders(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                    edges {
                      edge {
                        owner_user_uid order_uid completed_month
                      } } } }`,
                qlVariables: {
                  groupBy: ['owner_user_uid', 'completed_month'],
                  limit: 10
                },
                qlEdges: true,
                qlRoot: 'orders',
                transposeColumns: ['completed_month'],
                transposeKey: 'owner_user_uid',
                transposeValueField: 'order_uid',
                transposeNestedField: 'fitments_history',
                transposeFn: 'date'
              },
              {
                code: 'OrdersListQM',
                name: 'OrdersListQM',
                type: 'QM',
                alias: 'Pending',
                filter: {
                  status_code: 'pending',
                  owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                },
                fields: ['owner_user_uid'],
                aggregate: [{ id: 'order_uid', type: 'count' }],
                contextMapping: {},
                qlQuery: `query orders($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                  orders(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                  edges { edge { owner_user_uid order_uid } } } }`,
                qlVariables: {
                  groupBy: ['owner_user_uid'],
                  limit: 10
                },
                qlEdges: true,
                qlRoot: 'orders'
              },
              {
                code: 'CrmUser',
                name: 'CrmUser',
                type: 'QM',
                filter: {},
                qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON) {
                  master(master_uid: $master_uid)  {
                      master_uid
                      name
                      instance(instance_uid: $instance_uid) {
                          instance_uid
                          name
                          users (filter: $filter) {
                            user_uid
                            is_active
                            full_name
                            contract_start_date
                            contract_end_date
                            department {
                              name
                            }
                          }
                      }
                  }
                }`,
                qlRoot: 'users'
              }
            ],
            combineDataSources: {
              name: 'CombinedDataSource',
              targetKeyField: 'user_uid',
              mapping: [
                {
                  fromDataSourceName: 'FitmentsCompleted',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'quantity',
                      to: 'fitted_units'
                    },
                    {
                      from: 'subscription',
                      to: 'subscription'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'FirstFitments',
                  keyField: 'account__owner__user_uid',
                  mapping: [
                    {
                      from: 'quantity',
                      to: 'first_fitted_customers'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'FitmentsHistory',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'fitments_history',
                      to: 'fitments_history'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'Pending',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'order_uid',
                      to: 'pending'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'CrmUser',
                  keyField: 'user_uid',
                  type: 'left',
                  mapping: [
                    {
                      from: 'full_name'
                    },
                    {
                      from: 'contract_start_date'
                    },
                    { from: 'contract_end_date' },
                    { from: 'department__name' },
                    { from: 'is_active' }
                  ]
                }
              ]
            }
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                mob_fix_columns: 2,

                props: {
                  generate_summary: true,
                  generate_average: true,
                  initialSort: { id: 'contract_start_date', order: 'asc' },
                  columnGroups: [
                    {
                      Header: '',
                      colSpan: 3
                    },
                    {
                      Header: 'Statistics for period',
                      colSpan: 4,
                      className: 'CrmFlatTable-summary',
                      styleHighlighted: true
                    },
                    {
                      Header: '12 Month Historical Actuals',
                      colSpan: 12,
                      className: 'util-textCenter'
                    },
                    {
                      Header: '',
                      colSpan: 1
                    }
                  ],
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 30
                    },
                    {
                      Header: 'RM',
                      accessor: 'full_name',
                      features: [
                        {
                          code: 'conditional_formatting',
                          params: {
                            condition_field: 'is_active',
                            class_names: 'util-textRed'
                          }
                        }
                      ],
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user: { user_uid: 'user_uid' }
                      },
                      classNameHandler: row => (row.original.is_active ? '' : 'util-opacity50')
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'contract_start_date',
                      width: 80,
                      align: 'center',
                      type: 'text'
                    },
                    {
                      Header: 'Confirmed',
                      headerClassName: 'CrmFlatTable-summary util-textCenter',
                      accessor: 'captured_sales',
                      width: 80,
                      align: 'right',
                      type: 'number',
                      accessorFn: row => {
                        return (
                          (row && row.pending && !isNaN(Number(row.pending)) ? Number(row.pending) : 0) +
                          (row && row.fitted_units && !isNaN(Number(row.fitted_units)) ? Number(row.fitted_units) : 0)
                        )
                      },
                      maximumFractionDigits: 0,
                      styleHighlighted: true
                    },
                    {
                      Header: 'Pending',
                      accessor: 'pending',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      headerClassName: 'CrmFlatTable-summary util-textCenter',
                      detailsPopup: 'order',
                      detailsFilter: {
                        status_code: 'pending',
                        owner_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        owner: {
                          department_uid: 'department_uid',
                          organization_unit_uid: 'organization_unit_uid'
                        }
                      },
                      styleHighlighted: true
                    },
                    {
                      Header: 'Installed',
                      accessor: 'fitted_units',
                      detailsPopup: 'order',
                      detailsFilter: {
                        status_code: 'completed',
                        owner_user_uid: { $ne: 'null' },
                        account_uid: { $ne: 'null' },
                        quantity: { $ne: 'null' },
                        completed_month: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        owner: {
                          department_uid: 'department_uid',
                          organization_unit_uid: 'organization_unit_uid'
                        },
                        owner_user_uid: 'user'
                      },
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      headerClassName: 'CrmFlatTable-summary util-textCenter',
                      styleHighlighted: true
                    },
                    {
                      Header: 'Avg ARPU',
                      accessorFn: row => {
                        if (!row) return undefined

                        const fitted =
                          row.fitted_units && !isNaN(Number(row.fitted_units)) ? Number(row.fitted_units) : 0
                        const subscription =
                          row.subscription && !isNaN(Number(row.subscription)) ? Number(row.subscription) : 0

                        if (fitted <= 0 || subscription <= 0) return undefined

                        return subscription / fitted
                      },
                      detailsPopup: 'fitment',
                      detailsFilter: {},
                      detailsFilterMapping: {
                        owner__user_uid: 'user_uid'
                      },
                      contextMapping: {
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      },
                      align: 'right',
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      headerClassName: 'CrmFlatTable-summary util-textCenter',
                      styleHighlighted: true
                    },
                    {
                      Header: 'Avg 3 mo',
                      accessor: 'avg_3_mo',
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      headerClassName: ' util-textCenter',
                      accessorFn: row => {
                        const data = []

                        if (row.fitments_history) {
                          let date = moment()
                          date.add(-1, 'month')

                          for (let i = 0; i < 3; i++) {
                            const key = date.format('YYYY-MM-01')

                            if (row.fitments_history[key]) {
                              data.push(row.fitments_history[key])
                            }

                            date.add(-1, 'month')
                          }
                        }

                        return data.length > 0
                          ? Number(data.reduce((a, i) => a + Number(i), 0) / 3).toFixed(0)
                          : undefined
                      }
                    },
                    {
                      generator: {
                        type: 'months',
                        startMonth: -1,
                        endMonth: -13,
                        order: 'desc',
                        accessor: 'fitments_history',
                        columnProps: {
                          width: 50,
                          className: 'util-pointer',
                          detailsPopup: 'fitment',
                          detailsFilter: {
                            owner_user_uid: { $ne: 'null' },
                            completed_month: { $ne: 'null' }
                          },
                          detailsFilterMapping: {
                            owner_user_uid: 'user_uid'
                          },
                          fieldIgnoreContextMapping: ['activity_date'],
                          type: 'number',
                          align: 'right'
                        }
                      }
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'CombinedDataSource'
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}

export default rmsHistoricalPerformance
