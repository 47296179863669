import React from 'react'
import { toastr } from 'react-redux-toastr'
import { object, bool, func } from 'prop-types'
import editDashboardHoc from '../hoc/edit-dashboard-hoc'
import LeadsAnalyticsDashboard from './leads-analytics/leads-analytics-dashboard.jsx'
import DigitalMonitoringDashboard from './digital/digital-monitoring-dashboard.jsx'
import joeDashboard from './widgets18/joe/joe-dashboard.jsx'
import FitmentGeneralStaticDashboard from './widgets18/fitment/fitment-general-static-dashboard'
import agentWallboard from './widgets18/dialer/wallboard.jsx'
import DashTelesalesCampaign from './telesales/dash-telesales-campaign.jsx'
import DashTelesalesCampaignMovements from './telesales/dash-telesales-campaign-movements.jsx'
import DashTelesalesToday from './telesales/dash-telesales-today.jsx'
import DashLeadsToday from './leads/dash-leads-today.jsx'
import dashCurrentLeadStatus from './marketing/boom-current-lead-status.jsx'
import DashTelesalesAgents from './telesales/dash-telesales-agents.jsx'
import DashTelesalesDispositions from './telesales/dash-telesales-dispositions.jsx'
import DashTelesalesSales from './telesales/dash-telesales-sales.jsx'
import DashTelesalesCallbacks from './telesales/agents/dash-agents-callbacks.jsx'
import ctCampaignStatistics from '../ct-dashboards/campaign-statistics/ct-campaign-statistics-dashboard.jsx'
import ctLeadsCurrentStatus from '../ct-dashboards/ct-leads-current-status.jsx'
import ctLeadsStatusByLeadDate from '../ct-dashboards/ct-leads-current-status-by-date.jsx'
import RmSummaryWithDetails from '../ct-dashboards/rm-dashboard/rm-summary-dashboard.jsx'
import AgentSummaryWithDetails from '../ct-dashboards/rm-dashboard/agent-summary-dashboard.jsx'
import DashRms from './rms/rms-dashboard.jsx'
import opportunitiesFunnel from './opportunities/dash-opportunities-funnel.jsx'
import opportunitiesStats from './opportunities/dash-opportunities-stats.jsx'
import dashRmsLeadsStats from './leads/dash-rms-leads-stats.jsx'
import dashRmsFunnel from './rms/rms-funnel-dashboard.jsx'
import RmDashboardTasksStats from './rms/rm-dashboard-tasks-stats.jsx'
import rmActivitiesHistory from './rms/rms-activities-history.jsx'
import DashLeadsByAgents from './telesales/dash-leads-by-agents.jsx'
import ctDailyLeadsDashboard from './cartrack/ct-daily-leads-dashboard.jsx'
import testFunnelDashboard19 from '../dashboards/tests19/funnel/dash-funnel-19.tsx'
import testForecast2019 from '../dashboards/tests19/forecast/dash-forecast-19.jsx'
import testOpportunities2019 from './tests19/opportunities/dash-opportunities-19.jsx'
import testCharts2019 from '../dashboards/tests19/charts/dash-test-charts.jsx'
import ctMonthlyLeadsDashboard from './cartrack/ct-monthly-leads-dashboard.jsx'
import withDetailsPopup from '../components/_with-leads-popup-hoc.jsx'
import { mapContextToFilter2 } from '../dashboard-utils'
import {
  CrmTextInput,
  CrmButton,
  CrmTextArea,
  CrmJsonPreview
} from 'crm-components'
import { DashBoardRMInner } from '../dashboards/new-dashboard-rm'
import { DashBoardAgentInner } from '../dashboards/new-dashboard-agent'
import { SaleManagerDashboardInner } from '../dashboards/dashboard-sale-manager'
import { TeleSaleManagerDashboardInner } from './dashboard-tele-sale-manager'
import { withAnalyticsContextHoc } from 'crm-core/analytics/context'
import withLeadsPopupHoc from '../components/_with-leads-popup-hoc'
import { DynamicComponentDashboard } from './dynamic-component-dashboard'
import { GenericAnalyticsWidget } from '@cartrack-crm/crm-analytics'
import { get } from 'lodash'

class DashboardContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dashboardJson: JSON.stringify(props.dashboard, null, 2)
    }
  }

  handleShowLeadsPopup = params => {
    const lparams = {
      ...params,
      type: params.type,
      filters: {
        ...mapContextToFilter2(this.props, params),
        ...params.filters
      }
    }

    this.props.onShowLeadsPopup(lparams)
  }
  _renderItem = (item, index, totalItems) => {
    if (item.type === 'analitics_widget') {
      const widgetDefinition = item.widgetDefinition
        ? item.widgetDefinition
        : item
      const title = get(item, 'widgetDefinition.title', '')

      return (
        <GenericAnalyticsWidget
          key={item.code ? item.code : index}
          title={title}
          widgetDefinition={widgetDefinition}
          filters={item.filters}
          analyticsContext={this.props.analyticsContext}
          onShowLeadsPopup={this.handleShowLeadsPopup}
          fullHeight={totalItems === 1}
        />
      )
    }

    if (item.type === 'container') {
      const className = item.class

      return (
        <div className={className}>
          {get(item, 'items', []).map(this._renderItem)}
        </div>
      )
    }

    const showContextFilter = props => (
      <div>
        showContextFilter :
        {get(props, 'analyticsContext.filters', false) ? (
          <CrmJsonPreview json={props.analyticsContext.filters} />
        ) : (
          'no filters'
        )}
      </div>
    )

    const specialWidgets = {
      leadsToday: DashLeadsToday,
      ReportLeadsAnalytics18: LeadsAnalyticsDashboard,
      dashCurrentLeadStatus,
      joeDashboard,
      agentWb: agentWallboard,
      DigitalMonitoringDashboard,
      dashTelesalesCampaign: DashTelesalesCampaign,
      dashTelesalesCampaignMovements: DashTelesalesCampaignMovements,
      dashTelesalesAgentsPerformance: DashTelesalesAgents,
      dashTelesalesAgentsCallbacks: DashTelesalesCallbacks,
      widgetRmsTasksStats: RmDashboardTasksStats,
      dashTelesalesAgentsDispositions: DashTelesalesDispositions,
      dashTelesalesSales: DashTelesalesSales,
      dashTelesalesToday: DashTelesalesToday,
      dashRmsOverview: DashRms,
      dashRmsFunnel,
      opportunitiesFunnel,
      opportunitiesStats,
      dashLeadsByAgents: DashLeadsByAgents,
      dashLeadsToday: DashLeadsToday,
      dashRmsLeadsStats,
      rmActivitiesHistory,
      ctDailyLeadsDashboard,
      ctMonthlyLeadsDashboard,
      ctCampaignStatistics,
      ctLeadsCurrentStatus,
      ctAgentDashboard: withDetailsPopup(AgentSummaryWithDetails),
      ctRmDashboard: withDetailsPopup(RmSummaryWithDetails),
      testFunnelDashboard19,
      testForecast2019,
      testCharts2019,
      ctLeadsStatusByLeadDate,
      testOpportunities2019,
      showContextFilter,
      dynamicComponentDashboard: DynamicComponentDashboard,
      ctNewRmDashboard: DashBoardRMInner,
      ctNewAgentDashboard: DashBoardAgentInner,
      ctNewSaleManagerDashboard: SaleManagerDashboardInner,
      ctNewTeleSaleManagerDashboard: TeleSaleManagerDashboardInner,
      fitmentGeneralStatic: FitmentGeneralStaticDashboard
    }

    if (item.type === 'special_widget') {
      const Component = specialWidgets[item.code]
      
      return Component ? (
        <Component
          key={item.code}
          analyticsContext={this.props.analyticsContext}
          dashboard={item}
        />
      ) : (
        <div>{`Can't find component for code: ${item.code}`}</div>
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard !== this.props.dashboard || JSON.stringify(nextProps.analyticsContext?.filters) !== JSON.stringify(this.props.analyticsContext?.filters)) {
      this.setState({
        dashboardJson: JSON.stringify(nextProps.dashboard, null, 2)
      })
    }
  }

  _renderItems = parent =>
    parent.items.map((item, index) =>
      this._renderItem(item, index, parent.items.length)
    )

  handleSaveDashboard = async () => {
    try {
      await this.props.onSaveDashboard()
      toastr.success('Dashboard saved')
    } catch (err) {
      toastr.error('Problem saving dashboard')
    }
  }

  handleManualJsonChange = event => {
    this.setState({ dashboardJson: event.currentTarget.value })
  }

  handleUpdateFromJson = () => {
    try {
      const dashboard = JSON.parse(this.state.dashboardJson)
      this.props.onManualJsonChange(dashboard)
    } catch (err) {}
  }
  _renderContent = () => {
    const { dashboard } = this.props
    const isRenderWholeJSON = get(dashboard, 'content.layout', undefined)
    const isRenderContentItem = get(dashboard, 'content.items', undefined)

    if (isRenderWholeJSON) {
      return this._renderFromWholeDefinition(dashboard)
    } else if (isRenderContentItem) {
      return this._renderItems(dashboard.content)
    }
  }

  _renderFromWholeDefinition = dashboard => (
    <GenericAnalyticsWidget
      key={dashboard.dashboard_uid}
      title={dashboard.name}
      widgetDefinition={dashboard}
      filters={{}}
      fullHeight
      analyticsContext={this.props.analyticsContext}
      onShowLeadsPopup={this.props.onShowLeadsPopup}
    />
  )

  render() {
    const { dashboard, isEditingDashboard } = this.props

    return (
      <div className="AnalyticsDashboard util-fullHeight">
        <div className="AnalyticsCenter-dashboard-name util-flexRow"></div>
        {dashboard.description && (
          <div className="AnalyticsCenter-dashboard-description">
            {dashboard.description}
          </div>
        )}
        {isEditingDashboard && (
          <div className="row">
            <div className="col-md-3">
              <CrmTextInput
                placeholder="Dashboard name"
                input={{
                  onChange: this.props.onDashboardNameChange,
                  value: this.props.dashboard.name
                }}
              />
            </div>
            <div className="col-md-1">
              <CrmButton
                label="+ Widget"
                onClick={this.props.onClickAddWidget}
              />
            </div>
            <div className="col-md-3">
              <CrmButton
                label="Save"
                primary
                onClick={this.handleSaveDashboard}
              />
            </div>
          </div>
        )}

        {dashboard.content === undefined && <div> This dashboard is empty</div>}
        {this._renderContent()}

        {isEditingDashboard && (
          <div className="util-marginTop row">
            <div className="col-md-10">
              <CrmTextArea
                rows="20"
                style={{ width: '100%' }}
                input={{
                  value: this.state.dashboardJson,
                  onChange: this.handleManualJsonChange
                }}
              />
            </div>
            <div className="col-md-2">
              <CrmButton
                label="Update from JSON"
                onClick={this.handleUpdateFromJson}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

DashboardContent.propTypes = {
  dashboard: object.isRequired,
  analyticsContext: object.isRequired,
  onSaveDashboard: func.isRequired,
  onDashboardNameChange: func.isRequired,
  onStartEditDashboard: func.isRequired,
  isEditingDashboard: bool.isRequired,
  onClickAddWidget: func.isRequired,
  onManualJsonChange: func.isRequired,
  onShowLeadsPopup: func.isRequired
}

DashboardContent.defaultProps = {}

export default withLeadsPopupHoc(
  withDetailsPopup(editDashboardHoc(withAnalyticsContextHoc(DashboardContent)))
)
