/* eslint-disable camelcase */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { shape, func, string, bool } from 'prop-types'
import { Query } from 'react-apollo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bindActions } from 'crm-utils/reducer-utils'
import { CrmButton } from 'crm-components'
import { qlqGetContactDetails } from 'crm-data/contact-details'
import { FormattedMessage } from 'react-intl'
import { actions as phoneActions } from 'crm-duxs/phone/phone-reducer'

const statuses = [
  {
    is_success: true,
    is_active: true,
    name: (
      <FormattedMessage
        id="crm.activity.status.answer"
        defaultMessage="Answer"
      />
    ),
    code: 'answer'
  },
  {
    is_success: false,
    is_active: true,
    name: (
      <FormattedMessage id="crm.activity.status.busy" defaultMessage="Busy" />
    ),
    code: 'busy'
  },
  {
    is_success: false,
    is_active: true,
    name: (
      <FormattedMessage
        id="crm.activity.status.no_answer"
        defaultMessage="No answer"
      />
    ),
    code: 'no_answer'
  },
  {
    is_success: false,
    is_active: true,
    name: (
      <FormattedMessage
        id="crm.activity.status.bad_number"
        defaultMessage="Bad number"
      />
    ),
    code: 'wrong_number'
  }
]

class CallerWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      status: 'select_number',
      callMode: 'manual',
      callStartTime: undefined,
      callEndTime: undefined
    }
  }

  componentDidMount() {
    console.log('CallerWidget componentDidMount', this.props)
    if (
      this.props.autoDial &&
      this.props.isNewCall &&
      this.props.isLiveCallEditAction !== true
    ) {
      this.handleDial()
    }
    if (this.props.activityStatus) {
      this.setState({ selectedStatusCode: this.props.activityStatus.code })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.contactDetails &&
      !this.props.contactDetails &&
      nextProps.autoDial &&
      nextProps.isNewCall &&
      this.props.isLiveCallEditAction !== true
    ) {
      this.handleDial(nextProps)
    }
    console.log('callerWidget.willreceiveProps', nextProps)
  }

  handleClickStatus = event => {
    console.log('handleClickStatus', event)
    const code = event ? event.currentTarget.id : undefined
    this.setState({ selectedStatusCode: code })
    if (this.props.onCallStatusChanged) {
      const status = statuses.find(s => s.code === code)
      this.props.onCallStatusChanged(status)
    }
  }

  handleSelectedStatusClick = () => {
    this.handleClickStatus()
  }
  renderPhoneCallStatusSelector = () => {
    console.log('renderPhoneCallStatusSelector', this.state)
    const selectedStatus = this.state.selectedStatusCode
      ? statuses.find(s => s.code === this.state.selectedStatusCode)
      : undefined
    return selectedStatus ? (
      <div className="util-paddingMd">
        <div
          className={`CrmButton ${
            selectedStatus.is_success ? 'CrmButton--green' : 'CrmButton--red'
          } `}
          onClick={this.handleSelectedStatusClick}
        >
          {selectedStatus.name}
        </div>
      </div>
    ) : (
      <div>
        {statuses.map(option => (
          <CrmButton
            key={option.code}
            small
            type="button"
            id={option.code}
            className={`PhoneCallForm-statusButton ${
              option.is_success
                ? 'PhoneCallForm-statusButton-success'
                : 'PhoneCallForm-statusButton-failure'
            }
              ${
                option.code === this.state.selectedStatusCode
                  ? 'PhoneCallForm-statusButton--selected'
                  : ''
              }
              `}
            onClick={this.handleClickStatus}
            label={option.name}
          />
        ))}
      </div>
    )
  }

  handleOriginateSuccess = paylaod => {
    console.log('handleOriginateSuccess : ', paylaod)
    this.setState({
      callStartTime: moment(paylaod.start_time).format('h:mm:ss a')
    })
  }

  handleCallEnd = paylaod => {
    this.setState({
      callEndTime: moment(paylaod.call_end_time).format('h:mm:ss a')
    })
  }

  handleDial = props => {
    const contactDetails =
      props && props.contactDetails
        ? props.contactDetails
        : this.props.contactDetails
    if (contactDetails) {
      const originateParams = {
        number: {
          value: `${contactDetails.country_code}${contactDetails.value}`,
          name: `${contactDetails.country_code}${contactDetails.value}`
        },
        variables: {
          activity_uid: this.props.activityUid,
          account_uid: this.props.accountUid
        },
        onOriginateSuccess: this.handleOriginateSuccess,
        onCallEnd: this.handleCallEnd
      }
      console.log(originateParams, contactDetails)
      this.props.phoneActions.originateCall({ originateParams })
    } else {
      console.log('No Phone number')
    }
  }

  handleSelectedPhoneChange = event => {
    console.log('handleSelectedPhoneChange', event)
  }
  handleClickedPhone = () => {}

  render() {
    const { contactDetails } = this.props
    const { call } = this.state // eslint-disable-line
    console.log('Render CallerWidget', this.props, this.state)
    // A TODO - work in no-company mode
    return (
      <div
        className="CallerWidget"
        style={{
          backgroundColor: '#f5f5f5',
          width: '100%',
          color: 'black',
          borderRadius: 5
        }}
      >
        <div className="util-flexRow">
          {this.props.isPending && (
            <div
              style={{
                width: 70,
                borderRight: '1px solid #ccc',
                alignItems: 'center',
                fontSize: 12
              }}
              onClick={this.handleDial}
              title="Click to Dial"
              className="util-flexCenterContent CallerWidget-phoneIcon"
            >
              <FontAwesomeIcon icon="phone" onClick={this.handleClickedPhone} />
              <div>Dial</div>
            </div>
          )}
          <div
            style={{
              width: 100,
              borderRight: '1px solid #ccc',
              backgroundColor: 'white',
              padding: 10
            }}
          >
            {contactDetails && (
              <div>
                <div style={{ fontSize: 10 }}>phone</div>
                <div style={{ fontSize: 12 }}>
                  <strong>
                    {contactDetails.country_code}
                    {contactDetails.value}
                  </strong>
                </div>
                {this.state.callStartTime && (
                  <div>
                    <div style={{ fontSize: 10 }}>Call start time</div>
                    <div style={{ fontSize: 12 }}>
                      <strong>{this.state.callStartTime}</strong>
                    </div>
                  </div>
                )}
                {this.state.callEndTime && (
                  <div>
                    <div style={{ fontSize: 10 }}>Call end time</div>
                    <div style={{ fontSize: 12 }}>
                      <strong>{this.state.callEndTime}</strong>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div
            style={{
              width: 50,
              borderRight: '1px solid #b5b5b5',
              alignItems: 'center'
            }}
            className="util-flexCenterContent"
          >
            <CrmButton
              label="Dial"
              onClick={this.handleDial}
              type="primary"
              style={{
                width: 60,
                minWidth: 60,
                padding: 10
              }}
            />
          </div> */}
          <div className="util-flexGrow util-textCenter">
            <small style={{ fontSize: 10 }}>
              Click <strong>phone icon</strong> to dial or select status
              manually
            </small>
            {this.renderPhoneCallStatusSelector()}
          </div>
        </div>
      </div>
    )
  }
}

CallerWidget.defaultProps = {
  accountUid: undefined,
  onCallStatusChanged: undefined,
  autoDial: false,
  isNewCall: false,
  isPending: true,
  activityUid: undefined,
  isLiveCallEditAction: bool
}
CallerWidget.propTypes = {
  onCallStatusChanged: func,
  contactDetails: shape({}).isRequired,
  phoneActions: shape({}).isRequired,
  autoDial: bool,
  isNewCall: bool,
  isPending: bool,
  activityUid: string,
  accountUid: string,
  activityStatus: shape({}).isRequired,
  isLiveCallEditAction: false
}

function mapStateToProps() {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    phoneActions: bindActions(dispatch, phoneActions)
  }
}
const CallerWidgetLinked = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallerWidget)

const CallerWidgetQL = props => (
  <Query
    query={qlqGetContactDetails}
    variables={{
      contact_details_uid: props.contact_details_uid
    }}
  >
    {({ loading, data, refetch }) => (
      <CallerWidgetLinked
        {...props}
        loading={loading}
        refetch={refetch}
        data={data}
        contactDetails={data ? data.contact_detail : 'undefined'}
      />
    )}
  </Query>
)

CallerWidgetQL.propTypes = {
  contact_details_uid: string.isRequired,
  activityUid: string
}
CallerWidgetQL.defaultProps = {
  activityUid: undefined
}
export default CallerWidgetQL
