/* eslint-disable camelcase */
import React, { Component } from 'react'
import { func, shape, arrayOf } from 'prop-types'
import { connect } from 'react-redux'
import uuidv4 from 'uuid'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import { CrmFullpageContainer, CrmButton } from 'crm-components'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { fetchData } from 'crm-duxs/admin/attributes-reducer'

class AttributesListView extends Component {
  constructor(props) {
    super(props)
    this.prefixUri = '/crm/admin/attribute/'
    this.state = {}
  }

  componentDidMount() {
    this.props.doFetch()
  }

  handleEdit = event => {
    const url = this.prefixUri + event.currentTarget.id
    this.props.doPush(url)
  }

  handleRemove = event => {
    const attributeUid = event.currentTarget.id
    const toastrConfirmOptions = {
      onOk: () => this.processRemove(attributeUid)
    }

    toastr.confirm(
      'Are you sure you want to delete this attribute ? ',
      toastrConfirmOptions
    )
  }

  processRemove = async masterUid => {
    const formValues = { master_uid: masterUid, is_deleted: true }
    const commandType = 'CustomerMaster.update'
    const command = {
      type: commandType,
      aggregate_uid: uuidv4(),
      aggregate_type: 'CustomerMaster',
      payload: {
        ...formValues
      }
    }

    try {
      const masterData = await this.props.onRunCommand(command, {})
      // console.log('masterData', masterData)
      toastr.success('Saved')
      this.props.masterConfigQl.refetch()
    } catch (err) {
      console.error('Error running command')
      toastr.error('There was problem saving data')
    }
  }

  handleOnCreate = () => {
    const url = this.prefixUri + 'new'
    this.props.doPush(url)
  }

  renderItem = obj => (
    <div className="col-md-2" style={{ padding: '5px' }}>
      <div className="CrmMdCard util-paddingSm">
        <div
          className="CrmFormSection-header util-flexRow"
          style={{ justifyContent: ' space-between' }}
        >
          <div>Name : </div>
          <div
            className="util-textRight"
            style={{ display: 'flex', flexDirection: 'row', maxWidth: '70px' }}
          >
            <CrmButton
              className="remove-button"
              xsmall
              icon="pencil"
              id={obj.attribute_uid}
              onClick={this.handleEdit}
            />
            {/* <CrmButton
              className="remove-button"
              xsmall
              icon="trash"
              id={obj.master_uid}
              onClick={this.handleRemove}
            /> */}
          </div>
        </div>
        {obj.name}
      </div>
    </div>
  )

  render() {
    console.log('render attributes : ')
    const contents =
      Array.isArray(this.props.attributes) && this.props.attributes.length > 0
        ? this.props.attributes.map(obj => this.renderItem(obj))
        : []

    return (
      <CrmFullpageContainer className="CrmDashboard">
        <div className="row">
          <div
            className="col-md-12"
            style={{ padding: '20px', paddingBottom: 0 }}
          >
            <CrmButton
              label="Create"
              icon="plus"
              type="primary"
              onClick={this.handleOnCreate}
            />
          </div>
        </div>
        <div className="row" style={{ padding: '20px' }}>
          {contents}
        </div>
      </CrmFullpageContainer>
    )
  }
}

AttributesListView.propTypes = {
  doPush: func.isRequired,
  attributes: arrayOf(shape({})).isRequired,
  masterConfigs: shape({}).isRequired,
  ...cqCommandPropTypes
}

function mapStateToProps(state) {
  // console.log('mapStateToProps  attributeSaga ', state.crm.attribute)
  const attributeState = state.crm.attribute
  // // return {
  // //   attributes: attributeState.list
  // // }

  return {
    attributes: attributeState.lists
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doFetch: () => dispatch(fetchData()),
    doPush: url => dispatch(push(url))
  }
}

const AttributesListViewWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributesListView)

export default AttributesListViewWithState
