import { withEld } from 'util-components/with-eld.jsx'

// Actions
export const FETCH_LOCALE_DATA = 'FETCH_LOCALE_DATA'
export const FETCH_LOCALE_SUCCESS = 'FETCH_LOCALE_SUCCESS'
const CHANGE_LOCALE = 'CHANGE_LOCALE'
const UPDATE_INTL_MESSAGES = 'UPDATE_INTL_MESSAGES'

// Reducer
const initialState = {
  locale: window.localStorage.getItem('locale') || (withEld ? 'en-US' : 'en'),
  messages: {},
  translationMessages: undefined
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.payload.locale
      }
    case UPDATE_INTL_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages
      }
    case FETCH_LOCALE_SUCCESS:
      return {
        ...state,
        translationMessages: Object.assign({}, action.payload.translationMsgs)
      }
    default:
      return state
  }
}

// Action Creators
export function changeLocale(locale) {
  return {
    type: CHANGE_LOCALE,
    payload: {
      locale
    }
  }
}

export function updateMessages(messages) {
  return {
    type: UPDATE_INTL_MESSAGES,
    payload: {
      messages
    }
  }
}

// Selectors
export const getLocale = state => state.locale.locale
export const getMessages = state => state.locale.messages
export const getTranslationMessages = state => state.locale.translationMessages
