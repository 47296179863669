import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useCrmContext } from '@cartrack-crm/crm-core/src/contexts/crm-context'
import gql from 'graphql-tag'
import { CrmLoadingIcon } from '@cartrack-crm/ui'

const CRM_COMPONENT_FIELDS = `
  component_uid
  instance_uid
  code
  name
  owner_user_uid
  access_level
  enable_excel_export
  content
  params
  test_filters
  type
  def_version
  is_published
  meta
  is_archived
`

const QUERY_FIND_COMPONENT_BY_UID = gql`
  query master($master_uid: String!, $instance_uid: String!, $component_uid: String) 
  {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        component(component_uid: $component_uid) {
          ${CRM_COMPONENT_FIELDS}
        }
      }
    }
  }
`

const QUERY_COMPONENTS = gql`
  query master($master_uid: String!, $instance_uid: String!, $filter: JSON)
    {
      master(master_uid: $master_uid) {
        master_uid
        name
        instance(instance_uid: $instance_uid) {
          instance_uid
          name
          components (filter: $filter){
              ${CRM_COMPONENT_FIELDS}
          } 
        }
      }
    }
`

export const useGetDashboardOptionsByRootQLType = (rootQLType: string) => {
  const { instance, master } = useCrmContext()
  const { data, loading } = useQuery(QUERY_COMPONENTS, {
    variables: {
      master_uid: master?.master_uid,
      instance_uid: instance?.instance_uid,
      filter: {
        is_published: true
      }
    },
    fetchPolicy: 'no-cache'
  })
  let options = [{ name: (<CrmLoadingIcon />), value: null }]
  if (!loading) {
    options = data?.master?.instance?.components?.filter((component) => (rootQLType === component?.meta?.rootQLType))?.map((component) => ({ name: component.name, value: component.component_uid })) ?? []
  }
  return options
}

export const useGetPublishedComponent = () => {
  const { instance, master } = useCrmContext()
  const { data, loading } = useQuery(QUERY_COMPONENTS, {
    variables: {
      master_uid: master?.master_uid,
      instance_uid: instance?.instance_uid,
      filter: {
        is_published: true
      }
    },
    fetchPolicy: 'no-cache'
  })
  const components = data?.master?.instance?.components ?? []
  return { loading, components }
}

export const useGetComponentByUID = (uid: string) => {
  const { instance, master } = useCrmContext()
  const { data, loading } = useQuery(QUERY_FIND_COMPONENT_BY_UID, {
    variables: {
      master_uid: master?.master_uid,
      instance_uid: instance?.instance_uid,
      component_uid: uid
    },
    skip: !uid,
    fetchPolicy: 'no-cache'
  })

  const component = data?.master?.instance?.component ?? null
  return { loading, component }
}