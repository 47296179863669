import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import styles from './crm-tabs.scss'
import enhanceWithClickOutside from 'react-click-outside'
import { string, func, arrayOf, shape } from 'prop-types'

class CrmTabs extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: props.selectedTab
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedTab !== this.props.selectedTab) {
      this.setState({
        selectedTab: nextProps.selectedTab
      })
    }
  }

  handleClickOutside() {
    this.handleCloseTabs()
  }

  handleCloseTabs = () => {
    this.setState({ selectedTab: {} })
  }

  render() {
    // console.log('render crm-tabs', this.props, this.state, styles)
    const { selectedTab } = this.state
    const tabOptions = [...this.props.tabOptions]
    const activeClass =
      selectedTab && selectedTab.value ? styles.TabsContainer__Active : ''

    return (
      <div className={`${styles.TabsContainer} ${activeClass}`}>
        <div className={styles.TabsInner}>
          {selectedTab.value && (
            <div
              className={`${styles.ContentWrapper} ${
                selectedTab.value ? styles['ContentWrapper--active'] : ''
              }`}
            >
              {this.props.getTabContent()}
            </div>
          )}

          <div className={styles.TabsWrapper}>
            {tabOptions.map(v => {
              const selectedClass =
                v.value === selectedTab.value ? styles.Tab__Selected : ''
              const whiteBlockClass =
                v.value === selectedTab.value ? styles.whiteBlock : ''
              return (
                <div
                  key={v.value}
                  className={`${styles.Tab} ${selectedClass}`}
                  onClick={() => this.props.handleTabChange(v)}
                >
                  <div className={`${whiteBlockClass}`} />
                  <div>
                    <label className={`${styles.TabLabel}`}>{v.name}</label>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
CrmTabs.propTypes = {
  selectedTab: string.isRequired,
  getTabContent: func.isRequired,
  tabOptions: arrayOf(shape()).isRequired,
  handleTabChange: func.isRequired
}
export default injectIntl(enhanceWithClickOutside(CrmTabs))
