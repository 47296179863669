import React, { useState, FC } from 'react'
import schemaMock from '../mock-schema-type-data'
export const useComponentType = (props) => {
  const schema = [...schemaMock]
  return { schema }
}

const withComponentTypeActionHoc = (Component) => {
  const Wrapper = (props) => {
    const hocProps = useComponentType(props)
    return <Component {...props} {...hocProps} />
  }
  return Wrapper
}

export default withComponentTypeActionHoc