import React, { PureComponent } from 'react'
import { func, shape } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { CrmDropdown } from 'crm-components'

class EmailSelect extends PureComponent {
  handleOnChange = value => {
    if (value && value !== 'add') {
      this.props.onChange(
        this.props.emails.find(email => email.contact_details_uid === value)
      )
    } else if (value === 'add') {
      this.props.onShowAddForm(true)
    }
  }

  render() {
    // Map as options
    const mailsOptions = this.props.emails
      ? this.props.emails.map(mail => ({
          name: mail.value,
          value: mail.contact_details_uid
        }))
      : []
    mailsOptions.unshift({ name: 'Add new email', value: 'add' })
    mailsOptions.unshift({ name: 'Select email', value: '' })

    return (
      <div>
        <CrmDropdown
          options={mailsOptions}
          title={
            <FormattedMessage
              id="crm.ui.dropdown.select_email"
              defaultMessage="Select email"
            />
          }
          activeOption={
            <FormattedMessage
              id="crm.ui.dropdown.select_email"
              defaultMessage="Select email"
            />
          }
          valueField="contact_detail_uid"
          onChange={this.handleOnChange}
        />
      </div>
    )
  }
}

EmailSelect.propTypes = {
  onShowAddForm: func.isRequired,
  onChange: func.isRequired,
  emails: shape({})
}

EmailSelect.defaultProps = {
  emails: []
}

export default EmailSelect
