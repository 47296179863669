/* eslint-disable camelcase */

const contractsSigned = {
  id: 'contractsSigned',
  columns_dims: [
    {
      dim_code: 'date_created',
      kpi_code: 'contract_details',
      name: 'Date Signed',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_code: 'owner_user',
      kpi_code: 'contract_details',
      name: 'User'
    }
  ],
  kpis: [{ code: 'contract_details', as: 'contract_details' }],
  title: 'Subscriber Agreements by RM and Date Signed',
  filters: [
    {
      kpi_code: 'contract_details',
      dim_code: 'date_created',
      default_value: 'october_2017',
      operator: 'eq',
      class: 'date'
    }
  ]
}

// By lead source type

const contractsSignedByLeadSource = {
  id: 'contractsSignedByLeadSource',
  columns_dims: [
    {
      dim_code: 'date_created',
      kpi_code: 'contract_details',
      name: 'Date Signed',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_code: 'lead_source_type',
      kpi_code: 'contract_details',
      name: 'Lead Source type'
    }
  ],
  kpis: [{ code: 'contract_details', as: 'contract_details' }],
  title: 'Subscriber Agreements by Date Signed Lead Source',
  filters: [
    {
      kpi_code: 'contract_details',
      dim_code: 'date_created',
      default_value: 'october_2017',
      operator: 'eq',
      class: 'date'
    }
  ]
}

//

const contractsSignedByUserLeadSource = {
  id: 'contractsSignedByUserLeadSource',
  columns_dims: [
    {
      dim_code: 'owner_user',
      kpi_code: 'contract_details',
      name: 'User'
    }
  ],
  rows_dims: [
    {
      dim_code: 'lead_source_type',
      kpi_code: 'contract_details',
      name: 'Lead Source type'
    }
  ],
  kpis: [{ code: 'contract_details', as: 'contract_details' }],
  title: 'Subscriber Agreements by RM and Lead Source',
  filters: [
    {
      kpi_code: 'contract_details',
      dim_code: 'date_created',
      default_value: 'october_2017',
      operator: 'eq',
      class: 'date'
    }
  ]
}

const dashContracts = {
  id: 'contractsSignedByUserLeadSource',
  dashboard_uid: 'dashContracts',
  name: 'Subscriber Agreements Analysis',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'analitics_widget',
        widgetDefinition: contractsSigned
      },
      {
        type: 'analitics_widget',
        widgetDefinition: contractsSignedByLeadSource
      },
      {
        type: 'analitics_widget',
        widgetDefinition: contractsSignedByUserLeadSource
      }
    ]
  }
}

export default dashContracts
