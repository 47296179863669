import { qlqAccountActivities } from 'crm-data/activities'
export const updateActivitiesStoreAfterCreated = ({ variables }) => (
  store,
  { data }
) => {
  console.log('Store update', store, data)
  // Read the data from our cache for this query.
  const activity =
    data.cqCommand && data.cqCommand.payload
      ? data.cqCommand.payload.activity
      : undefined

  const storeData = store.readQuery({ query: qlqAccountActivities, variables })
  console.log('Store data', storeData)
  console.log('activity', activity)
  //
  if (
    activity &&
    storeData &&
    storeData.account &&
    storeData.account.activities
  ) {
    const activityIdx = storeData.account.activities.findIndex(
      a => a.activity_uid === activity.activity_uid
    )
    console.log('activityIdx', activityIdx)
    let newActivities = [...storeData.account.activities]
    const newActivity = {
      ...activity,
      details: activity.details || {}
    }
    if (newActivity.activity_status) {
      newActivity.activity_status.__typename = 'Activity_status'
    }
    if (newActivity.activity_type) {
      newActivity.activity_type.__typename = 'Activity_Type'
    }
    if (newActivity.contact_details) {
      newActivity.contact_details.__typename = 'ContactDetails'
    }
    if (newActivity.created_by_user) {
      newActivity.created_by_user.__typename = 'User'
    }
    if (newActivity.disposition_type) {
      newActivity.created_by_user.__typename = 'CallDispositionType'
    }
    if (newActivity.meeting_minutes) {
      newActivity.meeting_minutes.__typename = 'MinutesOfMeeting'
    }
    if (newActivity.participants) {
      newActivity.participants = newActivity.participants.map(p => ({
        ...p,
        __typename: 'Activity_Participant_Type',
        user: p.user ? { ...p.user, __typename: 'User' } : undefined
      }))
      newActivity.participants.__typename = 'MinutesOfMeeting'
    }
    if (newActivity.primary_performer) {
      newActivity.primary_performer.__typename = 'Activity_Participant_Type'
      if (newActivity.primary_performer.user) {
        newActivity.primary_performer.user.__typename = 'User'
      }
    }

    if (activityIdx >= 0) {
      newActivities[activityIdx] = { ...newActivity, __typename: 'Activity' }
    } else {
      newActivities = [
        { ...newActivity, __typename: 'Activity' },
        ...storeData.account.activities
      ]
    }

    const newData = {
      account: {
        ...storeData.account,
        activities: newActivities
      }
    }
    console.log('WriteQuery', newData)
    store.writeQuery({
      query: qlqAccountActivities,
      variables: variables,
      data: newData
    })
  }
  // Add our comment from the mutation to the end.
  // data.comments.push(submitComment);
  // // Write our data back to the cache.
  // store.writeQuery({ query: CommentAppQuery, data });
}
