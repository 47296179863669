/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import { graphql, compose } from 'react-apollo'
import {
  qlqFetchMeetingMinutes,
  qlmSetMinutesCompleted,
  qlmMeetingMinutesUpdate
} from 'crm-data/meeting-minutes'
import { qlqActivityWithFields } from 'crm-data/activities'

const getGuestEmails = guest => {
  const result = []
  guest.contact_details &&
    guest.contact_details.map(v => {
      if (v.kind === 'email') {
        result.push(v)
      }
    })

  return result
}

const getIsValidGuestEmail = guest => {
  let hasEmail = false
  let hasEmailValue = false
  const emails = getGuestEmails(guest.person)
  emails.map(v => {
    hasEmail = true
    hasEmailValue = false
    if (v.value) {
      hasEmailValue = true
    }
  })
  const isValid = hasEmail && hasEmailValue
  return isValid
}

export const validateAfterMeetingForm = activity => {
  console.log('validateAfterMeetingForm', activity)

  const errors = []
  const allParticipants = activity && activity.participants

  // validate guests and email
  const guests = allParticipants.filter(
    v => v.role_code === 'participant' && v.person !== null
  )
  const hasGuest = guests.length > 0
  if (!hasGuest) {
    errors.push('Guest is required')
  } else {
    guests.map(guest => {
      if (!getIsValidGuestEmail(guest)) {
        const name = guest.person.name ? guest.person.name : ''
        errors.push('Guest ' + name + ' has no email')
      }
    })
  }

  return errors
}

const qlOptions = {
  options: ownProps => ({
    variables: { activity_uid: ownProps.activityUid }
  })
}

const mutationsOptions = ownProps => ({
  refetchQueries: [
    {
      query: qlqFetchMeetingMinutes,
      variables: { activity_uid: ownProps.activityUid }
    }
  ]
})

const afterMeetingSurveyQLHOC = compose(
  graphql(qlqFetchMeetingMinutes, {
    ...qlOptions,
    name: 'meetingMinutesQl',
    props: ({ meetingMinutesQl, ownProps }) => ({
      minutesData: meetingMinutesQl
        ? meetingMinutesQl.meeting_minutes
        : undefined,
      isLoading: meetingMinutesQl ? meetingMinutesQl.loading : false,
      ...ownProps
    })
  }),
  graphql(qlqActivityWithFields, {
    ...qlOptions,
    name: 'activityQl',
    props: ({ activityQl, ownProps }) => ({
      activity:
        activityQl && activityQl.activity ? activityQl.activity : undefined,
      isLoadingActivity: activityQl ? activityQl.loading : false,
      ...ownProps
    })
  }),
  graphql(qlmSetMinutesCompleted, {
    name: 'qlmSetMinutesCompleted',
    options: mutationsOptions
  }),
  graphql(qlmMeetingMinutesUpdate, {
    name: 'qlmMeetingMinutesUpdate',
    options: mutationsOptions
  })
)

export default afterMeetingSurveyQLHOC
