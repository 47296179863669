export class WidgetsManager {
  widgetDefinitions
  widgetDataProviders
  widgetRenderers
  constructor() {
    this.widgetDefinitions = {}
    this.widgetDataProviders = {}
    this.widgetRenderers = {}
  }

  registerWidgetDefinition(widgetDefinition) {
    if (widgetDefinition && widgetDefinition.code) {
      this.widgetDefinitions[widgetDefinition.code] = widgetDefinition
    }
  }

  registerWidgetDataProvider(code, hoc) {
    // console.log('registerWidgetDataProvider', code)
    this.widgetDataProviders[code] = hoc
  }

  getWidgetDefinition(code) {
    return this.widgetDefinitions[code]
  }
  registerComponentRenderer(code, component) {
    // console.log('registerComponentRenderer', code)
    this.widgetRenderers[code] = component
  }
}

const widgetsManager = new WidgetsManager()

// widgetsManager.registerWidgetDefinition(
//   require('./widgets-definitions/fitments/widget-fitments-with-target.json')
// )
// widgetsManager.registerWidgetDefinition(
//   require('./widgets-definitions/meetings/widget-my-meetings-mtd.json')
// )

export default widgetsManager
