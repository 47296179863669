/* eslint-disable camelcase */
import React from 'react'
import { shape, func, string, number } from 'prop-types'
import { CrmTextInputNumber } from 'crm-components'

class MarketPotientialSizeInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      marketSize: undefined
    }
  }

  componentWillMount() {
    // Iif (this.props.marketPotential) {
    //   const details = this.props.marketPotential.details
    //   if (details && details.items) {
    //     const marketSize = details.items.find(
    //       item =>
    //         item.market_potential_type_uid ===
    //         this.props.row.dictionary_value_uid
    //     )
    //     if (marketSize) {
    //       this.setState({ marketSize: marketSize.size })
    //     }
    //   }
    // }
  }

  handleNewSizeChange = event => {
    this.props.handleSizeChange(this.props.row, event.currentTarget.value)
    this.setState({ ...this.state, marketSize: event.currentTarget.value })
  }

  render() {
    let disabled = false
    if (this.props.mode !== 'edit') {
      disabled = true
    }

    return (
      <CrmTextInputNumber
        placeholder="size"
        placeholderId="crm.ui.account.market.size"
        input={{
          onChange: this.handleNewSizeChange,
          value: this.props.row.size
        }}
        disabled={disabled}
      />
    )
  }
}

MarketPotientialSizeInput.propTypes = {
  row: shape({}).isRequired, // eslint-disable-line
  handleSizeChange: func.isRequired,
  mode: string.isRequired,
  size: number
}

MarketPotientialSizeInput.defaultProps = {
  size: undefined
}

export default MarketPotientialSizeInput
