import React, { FC } from 'react'
import { FilterSectionLead, FilterSectionUser, FilterSectionCampaign, FilterSectionActivityDate, FilterSectionOrganizationUnit } from '@cartrack-crm/crm-filter-section'
import { DashboardFiltersWrapper } from './styled/dashboard-filters-section'

interface IDashboardFilters {
  currentDashboard?: any
}

export const DashboardFilters: FC<IDashboardFilters> = () => {
  return (
    <DashboardFiltersWrapper>
      <div className="util-flexRow">
        <div className="util-flexGrow">
          <FilterSectionLead />
        </div>
        <div>
          <FilterSectionOrganizationUnit />
        </div>
        <div className="util-flexGrow">
          <FilterSectionUser />
        </div>
      </div>
      <div className="util-flexRow">
        <div style={{ flex: 8 }}>
          <FilterSectionActivityDate />
        </div>
        <div style={{ flex: 6 }}>
          <FilterSectionCampaign />
        </div>
      </div>
    </DashboardFiltersWrapper>
  )
}
