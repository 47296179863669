import React, { useState } from 'react'
import { CrmMonthSlider, onchangeType } from '@cartrack-crm/ui/src/month-slider/crm-month-slider'
import moment from 'moment'
import { first, get } from 'lodash'
import { useFetchListForecasts } from './useFetchForecastManager'
import ForecastBody from './forecast-body'
import '@cartrack-crm/ui/style/crm-forecast-page/forecast-page.scss'
import '@cartrack-crm/ui/style/crm-button/crm-button.scss'
import { connect } from 'react-redux'
import { getUser } from '../../duxs/crm-reducer'

const ForecastPage = props => {
  const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM'))
  const onChange = (res: onchangeType) => {
    const month = res.moment.format('YYYY-MM')
    setCurrentMonth(month)
  }

  let forecastData = undefined
  const { loading, error, data, refetch } = useFetchListForecasts({
    monthDate: currentMonth,
    userUid: props.currentUser.user_uid
  })

  if (data && data.forecasts.length != 0) {
    if (data.forecasts) {
      forecastData = data.forecasts[0]
    }
  }

  const refetchParams = {
    month_date: currentMonth,
    user_uid: props.currentUser.user_uid
  }

  return (
    <div className="CrmFullpageContainer CrmFullpageContainer--scrollInner">
      <div className="util-fullHeight util-flexGrow util-flexColumn util-relative">
        <div className="util-flexRowCenter util-paddingSm">
          <CrmMonthSlider onChange={onChange} width={250} />
        </div>
        <ForecastBody
          month={currentMonth}
          forecastData={first(get(data, 'forecasts.data', []))}
          refetch={refetch}
          loading={loading}
          userUid={props.currentUser.user_uid}
          refetchParams={refetchParams}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  currentUser: getUser(state)
})

export default connect(mapStateToProps, null)(ForecastPage)
