import apiCaller, { callQLApi } from '../api-utils'

export const qlProduct =
  ' product_uid name name_en description code kind is_active is_taxable annuity_term full_description is_addon is_package revenue_type opportunity_unit_type localization tax_rate'

export default {
  fetchProducts() {
    return apiCaller('ic_api_product_list', {}).then(
      res => res.ic_api_product_list
    )
  },
  updateProduct(product) {
    return apiCaller('ic_api_product_update', product).then(
      res => res.ic_api_product_update
    )
  },
  createProduct(product) {
    return apiCaller('ic_api_product_create', product).then(
      res => res.ic_api_product_create
    )
  },
  update: params => {
    const qlParams = {
      query: ` mutation product_update($product:product_in) { product_update(product: $product)  { ${qlProduct} }  } `,
      variables: {
        product: params
      }
    }

    return callQLApi(qlParams).then(res => res.product_update)
  },
  fetch: () => {
    const qlParams = {
      query: ` query products($includeInActive: Boolean) { products(includeInActive: $includeInActive)  { ${qlProduct} } } `,
      variables: {
        includeInActive: true
      }
    }
    return callQLApi(qlParams).then(res => res.products)
  }
}
