import { useState, useEffect } from 'react'
import emitter from '../../../../../util-functions/emitter'


export const useCrmGetErrorTrace = () => {
  const [errorTrace, setErrorTrace] = useState([])
  useEffect(() => {
    emitter.on('api/error', (error) => {
      const errorObject = error.error[0].trace
      setErrorTrace(errorObject)
    })
  }, [])

  return [errorTrace]
}


