/* eslint-disable */
import React, { PureComponent } from 'react'
import io from 'socket.io-client'
import { connect } from 'react-redux'
import { any, shape, string } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bindActions } from 'crm-utils/reducer-utils'
import { getUser } from 'crm-duxs/crm-reducer'
import {
  actions as phoneActions,
  getSipStatusCode,
  getIsLoadScript
} from 'crm-duxs/phone/phone-reducer'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { withCrmContext } from 'crm-core/contexts/crm-context'

class PhoneWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      deviceStatus: 'Offline',
      phoneNumber: '',
      dialer_server_url: props.crmContext.getAttributeValueByCode(
        'dialer_server_url'
      )
    }
    this.loginParams = {}
    this.io = io // Make reference to get socket.io imported
    window.io = io
  }

  componentWillMount() {
    console.log('PhoneWidget : componentWillMount')
    console.log('dialer_server_url', this.state.dialer_server_url)
    if (this.props.currentUser.sip && this.state.dialer_server_url) {
      this.scriptUrl = `${this.state.dialer_server_url}/scripts/dialer-client.js`
      this.loadScript()
    }
  }

  handleChangeStatusAck = ({ payload }) => {
    const data = payload
    console.log('handleChangeStatusAck : ', data)
  }

  initDialerClient = () => {
    this.props.phoneActions.loadScriptSuccess({
      isLoad: true
    })

    if(window.DialerClient()) {
      this.dialerClient = window.DialerClient()
      window.dialerClient = this.dialerClient
      this.dialerClient.init({
        url: this.state.dialer_server_url,
        url2: 'ws://localhost:8082/ws'
      })

      this.dialerClient.on(undefined, this.handleDialerClientEvent)
      if (this.props.currentUser.sip) {
        console.log('Dialer Client - try to login')
        const crmInstance = JSON.parse(
            window.localStorage.getItem('crm_instance')
        )
        this.setState({...this.state, sip: this.props.currentUser.sip})
        this.loginParams = {
          username: this.props.currentUser.username,
          user_uid: this.props.currentUser.user_uid, // eslint-disable-line
          sip: this.props.currentUser.sip,
          params: {},
          instance_uid: crmInstance.instance_uid
        }
        try {
          this.dialerClient.login(this.loginParams)
          console.log('dialerClient.login called')
        } catch (err) {
          console.log('dialerClient login error')
        }
      }
    }
  }

  handleDialerClientEvent = event => {
    if (event.code && event.device_status_uid) {
      this.handleDeviceStatusChanged(event)
    }
    if (event && event.type) {
      switch (event.type) {
        case 'cmd.dialer.authorize.ack':
          this.handleAuthAck(event)
          break

        case 'cmd.dialer.call_originate_req.ack':
          this.handleCallOriginateAck(event)
          break
        case 'users_status':
          // TONG FIX HERE
          this.handleUsersStatusChanged(event)
          break
        case 'crm.dialer.device_status_change.ack':
          this.handleDeviceStatusChanged(event)
          break
        case 'crm.dialer.call_state_change.event':
          this.handleCallStatusChanged(event)
          break

        case 'crm.dialer.call_hang_up.event':
          this.handleCallHangUp(event)
          break

        case 'crm.dialer.device_status_change.event':
          this.handleDeviceStateChange(event)
          break
        case 'cmd.dialer.sync_agent_status.event':
          this.handleAgentStatusSync(event)

        case 'cmd.dialer.change_status_req.ack':
          this.handleAgentStatusSync(event)
        default:
      }
    }
  }

  handleAgentStatusSync = params => {
    console.log('handleAgentStatusSync')

    if (
      Object.prototype.hasOwnProperty.call(
        params.payload.agentStatus,
        'code'
      ) &&
      Object.prototype.hasOwnProperty.call(params.payload.agentStatus, 'name')
    ) {
      this.props.phoneActions.agentStatusUpdated({
        agentStatusCode: params.payload.agentStatus.code,
        agentStatusName: params.payload.agentStatus.name
      })
    }
  }

  handleAuthAck = params => {
    if (params.payload.deviceStatus) {
      this.props.phoneActions.sipStatusUpdated({
        sipStatusCode: params.payload.deviceStatus.code
      })

      if (
        params &&
        params.payload &&
        params.payload.agentStatus &&
        params.payload.agentStatus.code &&
        params.payload.agentStatus.name
      ) {
        this.props.phoneActions.agentStatusUpdated({
          agentStatusCode: params.payload.agentStatus.code,
          agentStatusName: params.payload.agentStatus.name
        })
      }
    }
  }

  handleDeviceStateChange = params => {
    console.log('handleDeviceStatusChanged', params)
    this.props.phoneActions.sipStatusUpdated({
      sipStatusCode: params.payload.code
    })
  }

  handleCallHangUp(params) {
    console.log('handleCallHangUp', params)
    this.props.phoneActions.callHangUp(params.payload)
  }

  handleCallStatusChanged(params) {
    console.log('handleCallStatusChanged', params)
    this.props.phoneActions.callStatusChanged(params.payload)
  }

  handleCallOriginateAck(params) {
    console.log('handleCallOriginateAck', params)

    if (
      Object.prototype.hasOwnProperty.call(params.payload, 'destination_number')
    ) {
      toastr.success(`Calling :  ${params.payload.destination_number}`)
    }
    this.props.phoneActions.originateCallSuccess(params.payload)
  }

  handleUsersStatusChanged(event) {
    const usersStatus = event.usersStatus
    const userStatus = usersStatus.find(
      user => user.user_uid === this.props.currentUser.user_uid
    )

    if (userStatus) {
      if (this.state.deviceStatus !== userStatus.device_status) {
        this.setState({
          ...this.state,
          deviceStatus: userStatus.device_status
        })
      }
    }
  }

  handleDeviceStatusChanged(event) {
    console.log('handleDeviceStatusChanged', event)
    this.props.phoneActions.sipStatusUpdated({
      sipStatusCode: event.code
    })
  }

  handleNumberChange = event => {
    this.setState({ ...this.state, phoneNumber: event.currentTarget.value })
  }

  handleDial = () => {
    if (!this.state.phoneNumber) {
      toastr.error('Please Type in phone number')
      return
    }
    const originateParams = {
      number: { value: this.state.phoneNumber },
      variables: { activity_uid: null, account_uid: null },
      source_ext: this.props.currentUser.sip
    }
    this.props.phoneActions.originateCall({ originateParams })
  }
  loadScript() {
    console.log('PhoneWidget.loadScript', this.scriptUrl)
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = this.scriptUrl
    s.async = true
    s.addEventListener('load', this.initDialerClient)
    document.head.appendChild(s)
  }

  handleTogglePhoneWidget = () => {
    this.setState({ ...this.state, expanded: !this.state.expanded })
  }

  showErrorMessagePopup = () => {
    toastr.error('Dialer script is not loaded')
  }

  render() {
    if (this.state.dialer_server_url) {
      return this.props.currentUser.sip && !this.props.isLoadScript ? (
        <div className={`PhoneWidget PhoneWidget--collapsed`}>
          <div
            href="#"
            onClick={this.showErrorMessagePopup}
            className={`PhoneWidget-status unavailable`}
            style={{ color: 'red' }}
          >
            <FontAwesomeIcon icon="phone" />
          </div>
        </div>
      ) : (
        this.renderPhoneWidget()
      )
    }

    return <div />
  }

  renderMiniWidget(sipDeviceStatusClassName) {
    return (
      <div className={`PhoneWidget PhoneWidget--collapsed`}>
        <div
          href="#"
          onClick={this.handleTogglePhoneWidget}
          className={`PhoneWidget-status ${sipDeviceStatusClassName}`}
        >
          <FontAwesomeIcon icon="phone" />
        </div>
      </div>
    )
  }

  renderFullWidget(sipDeviceStatusClassName) {
    return (
      <div
        className={`PhoneWidget  PhoneWidget--expanded
      ${sipDeviceStatusClassName}`}
      >
        <div
          href="#"
          onClick={this.handleTogglePhoneWidget}
          className={`PhoneWidget-status`}
        >
          {this.state.sip}
        </div>
        <div className="PhoneWidget-body">
          <input
            className="PhoneWidget-phoneInput"
            placeholder="Number"
            onChange={this.handleNumberChange}
            value={this.state.phoneNumber}
          />
          <FontAwesomeIcon
            icon={faPhone}
            onClick={this.handleDial}
            className="PhoneWidget-dialButton util-hooverable"
            style={{ marginLeft: 5, marginRight: 5 }}
            title="Click to Dial"
          />
        </div>
      </div>
    )
  }

  renderPhoneWidget() {
    const sipDeviceStatusClassName = `PhoneWidget--status-${this.props.sipStatusCode}`

    return this.state.expanded
      ? this.renderFullWidget(sipDeviceStatusClassName)
      : this.renderMiniWidget(sipDeviceStatusClassName)
  }
}

PhoneWidget.propTypes = {
  currentUser: any.isRequired,
  phoneActions: shape({}).isRequired,
  sipStatusCode: string.isRequired
}

function mapStateToProps(state) {
  return {
    currentUser: getUser(state),
    isLoadScript: getIsLoadScript(state),
    sipStatusCode: getSipStatusCode(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    phoneActions: bindActions(dispatch, phoneActions)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCrmContext(PhoneWidget))
