export const convertContextToQFilters = context => {
  const filters = {}
  if (context && context.filters) {
    Object.keys(context.filters).forEach(key => {
      const f = context.filters[key]
      if (f) {
        if (f.value) {
          filters[key] = f.value
        } else {
          filters[key] = f
        }
      }
    })
  }

  return filters
}
export const mock = 1
