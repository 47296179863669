import gql from 'graphql-tag'
import { crmQLActionButtonFactory } from 'crm-components/crm-ql-action-button.jsx'

const qlmGenerateReport = gql`
  mutation meeting_minutes_report($meeting_minutes: meeting_minutes_in) {
    meeting_minutes_report(meeting_minutes: $meeting_minutes) {
      output
      resource {
        resource_uid
        name
        description
        file_link
      }
    }
  }
`

const downloadFileFromLink = (sUrl, fileName) => {
  const link = document.createElement('a')
  link.href = sUrl
  link.setAttribute('target', '_blank')

  if (link.download !== undefined) {
    // Set HTML5 download attribute. This will prevent file from opening if supported.
    link.download = fileName
  }

  // Dispatching click event.
  if (document.createEvent) {
    const e = document.createEvent('MouseEvents')
    e.initEvent('click', true, true)
    link.dispatchEvent(e)
    return true
  }
}
const onSuccess = (minutes, { qlVariables }) => {
  console.log('Print Minutes on Success', minutes, qlVariables)
  console.dir(this)
  const data = minutes.data.meeting_minutes_report.output
  const sUrl = 'data:application/pdf;base64,' + data
  const fileName =
    minutes.data.meeting_minutes_report.resource &&
    minutes.data.meeting_minutes_report.resource.name
      ? minutes.data.meeting_minutes_report.resource.name
      : 'Meeting Minutes'
  downloadFileFromLink(sUrl, fileName)
}

const PrintMinutesButtonQL = crmQLActionButtonFactory({
  mutation: qlmGenerateReport,
  onSuccess
})

export default PrintMinutesButtonQL
