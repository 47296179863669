/* eslint-disable camelcase */
import React from 'react'
import { shape, func, bool, string } from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import { graphql, compose } from 'react-apollo'
import MarketPotentialDetails from './_market-potential-details.jsx'
import { CrmModal, FlatTable, FormattedDateTime } from 'crm-components'
import { LoadingIcon } from 'util-components'
import { qlListMartketPotential } from 'crm-data/account-market-potential'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const columns = [
  {
    Header: 'From',
    key: 'From',
    labelMessageId: 'crm.ui.account.history.from',
    Cell: row =>
      row.original.valid_from ? (
        <FormattedDateTime value={row.original.valid_from} />
      ) : (
        <div />
      )
  },
  {
    Header: 'Till',
    key: 'Till',
    labelMessageId: 'crm.ui.account.history.till',
    Cell: row =>
      row.original.valid_till ? (
        <FormattedDateTime value={row.original.valid_till} />
      ) : (
        <div />
      )
  },
  {
    Header: 'Size',
    key: 'Size',
    labelMessageId: 'crm.ui.account.history.size',
    accessor: 'size'
  },
  {
    Header: 'Type',
    key: 'Type',
    labelMessageId: 'crm.ui.account.history.type',
    accessor: 'type'
  },
  {
    Header: 'Created by',
    key: 'Created by',
    labelMessageId: 'crm.ui.account.history.created_by',
    Cell: row => (
      <div>
        {row.original.created_by_user
          ? row.original.created_by_user.full_name
          : ''}
      </div>
    )
  }
]
class MarketPotentialModalInner extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mode: props.edit ? 'edit' : 'readonly',
      isSaving: false
    }
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.edit && nextProps.edit !== this.props.edit) {
      this.setState({
        ...this.state,
        mode: nextProps.edit ? 'edit' : 'readonly'
      })
    }
  }

  handleNewSizeChange = event => {
    this.setState({ ...this.state, newSize: event.currentTarget.value })
  }

  renderSummary(marketPotentialDetail) {
    return (
      <span className="CrmChip CrmChip-blue" style={{ cursor: 'default' }}>
        {marketPotentialDetail.size}{' '}
        {marketPotentialDetail.size > 1 ? (
          <FormattedMessage
            id="crm.ui.account.market.vehicles"
            defaultMessage="Created by"
          />
        ) : (
          <FormattedMessage
            id="crm.ui.account.market.vehicle"
            defaultMessage="Vehicle"
          />
        )}
      </span>
    )
  }
  handleClickCancel = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
  render() {
    let marketPotentialDetail = null
    const { data, isShowingMarketPotentialHistory } = this.props
    const isShowingForm = !isShowingMarketPotentialHistory
    const market_potential_history = data
      ? data.market_potential_history
      : undefined
    const loading = data ? data.loading : false
    const tableData = market_potential_history
      ? market_potential_history.map(r => ({
          ...r,
          id: r.market_potential_uid
        }))
      : undefined
    marketPotentialDetail = market_potential_history
      ? market_potential_history[0]
      : undefined
    const use_advanced = this.props.crmContext.getAttributeValueByCode(
      'use_advanced_market_potential'
    )
    return (
      <div className="util-paddingMd">
        {isShowingForm && (
          <div>
            <h4>
              <FormattedMessage
                id="crm.ui.account.market.market_potential_details"
                defaultMessage="Market potential details"
              />

              {use_advanced &&
                marketPotentialDetail &&
                this.renderSummary(marketPotentialDetail)}
            </h4>
            <div className="row">
              <div className="col-md-12">
                <MarketPotentialDetails
                  isSaving={this.state.isSaving}
                  mode={this.state.mode}
                  accountUid={
                    this.props.account
                      ? this.props.account.account_uid
                      : undefined
                  }
                  onSetHandleFromChild={this.props.onSetHandleFromChild}
                  handleClickEdit={this.onClickEdit}
                  onCancel={this.handleClickCancel}
                  onSaved={this.props.onSaved}
                  slFormOnSave={this.props.slFormOnSave}
                  showEditButtons={false}
                />
              </div>
            </div>
          </div>
        )}
        {isShowingMarketPotentialHistory && (
          <div>
            <h4>
              <FormattedMessage
                id="crm.ui.account.market.market_potential_history"
                defaultMessage="Market Potential History:"
              />
            </h4>
            {loading && <LoadingIcon />}
            {tableData && (
              <FlatTable
                data={tableData}
                columns={columns}
                translation
                tableName="market_potential_history"
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

const listQuery = gql(qlListMartketPotential)

export const marketPotentialHoc = compose(
  graphql(listQuery, {
    options: ownProps => ({
      variables: {
        account_uid: ownProps.account
          ? ownProps.account.account_uid
          : ownProps.account_uid
      }
    }),
    skip: ownProps =>
      !ownProps.account ||
      !ownProps.account.account_uid ||
      ownProps.account.account_uid === 'new',
    props: ({ data, ownProps }) => ({ data, ...ownProps })
  })
)

export const MarketPotentialModalInnerQL = marketPotentialHoc(
  MarketPotentialModalInner
)

MarketPotentialModalInner.propTypes = {
  account: shape({}).isRequired, // eslint-disable-line
  data: shape({}).isRequired,
  onSaved: func,
  onClose: func,
  edit: bool,
  slFormOnSave: func,
  isShowingMarketPotentialHistory: bool,
  onSetHandleFromChild: func,
  crmContext: shape()
}

MarketPotentialModalInner.defaultProps = {
  onSaved: () => {},
  onClose: () => {},
  edit: false,
  slFormOnSave: undefined
}

const MarketPotentialModal = props => (
  <CrmModal
    isOpen={props.isOpen}
    title={
      <FormattedMessage
        id="crm.ui.account.market.market_potential"
        defaultMessage="Market Potential"
      />
    }
    onClose={props.onClose}
    showFooter
    primaryButtonLabel="Save"
    onPrimaryButton={props.onClickSaveMarketPotential}
    onSecondaryButton={props.onClose}
    secondaryButtonLabel="Cancel"
    specialContentLabel={`${
      props.isShowingMarketPotentialHistory ? 'Show Form' : 'Show History'
    }`}
    onSpecialContent={props.onClickMarketPotentialHistory}
  >
    <MarketPotentialModalInnerQL {...props} />
  </CrmModal>
)

MarketPotentialModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  mode: string,
  slFormOnSave: func,
  isShowingMarketPotentialHistory: bool,
  onClickMarketPotentialHistory: func,
  onClickSaveMarketPotential: func
}
MarketPotentialModal.defaultProps = {
  mode: undefined,
  slFormOnSave: undefined,
  isShowingMarketPotentialHistory: false
}
export default withCrmContext(MarketPotentialModal)
