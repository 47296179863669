import React from 'react'
import { func, object, string } from 'prop-types'
import cloneDeep from 'clone-deep'
import { withApollo } from 'react-apollo'
import { withCrmContext } from '@cartrack-crm/crm-core'
import { mapContextFiltersIntoWidget } from '../dashboard/dashboard-utils'
import gql from 'graphql-tag'
import widgetsManager from './widgets/widgets-manager'
import { get } from 'lodash'

const qlAnalyticsWidgetQuery = gql(`
query dataSourceQuery($widgetDefinition: JSON, $filter: JSON, $master_uid: String!, $instance_uid: String!) {
master(master_uid: $master_uid) {
  instance(instance_uid: $instance_uid) { 
    dataSourceQuery(
      widgetDefinition: $widgetDefinition,
      filter: $filter
    )
  {
    data
    meta
  }
}
}
}
`)

const genericWidgetHoc = WrappedComponent => {
  class Inner extends React.PureComponent<any, any> {
    constructor(props) {
      super(props)
      const widgetDefinition = this._prepareWidgetDefinition(
        props.widgetDefinition
          ? cloneDeep(props.widgetDefinition)
          : widgetsManager.getWidgetDefinition(props.widgetDefCode)
      )

      this.state = {
        isSaving: false,
        isActive: false,
        widgetDefinition,
        isEditingWidget: false,
        layoutWithProps: this._buildLayoutWithProps(widgetDefinition)
      }
    }
    componentDidMount() {
      if (this.props.autoRefresh) {
        this.handleFetchData()
      }
    }

    _prepareWidgetDefinition(pWidgetDefinition) {
      const widgetDefinition = pWidgetDefinition ? cloneDeep(pWidgetDefinition) : {}
      widgetDefinition.content = widgetDefinition.content || {}
      widgetDefinition.content.layout = widgetDefinition.content.layout || {}
      widgetDefinition.content.layout.items = widgetDefinition.content.layout.items || []

      if (widgetDefinition.content.layout.items.length === 0) {
        widgetDefinition.content.layout.items.push({
          type: 'container',
          items: [],
          params: { direction: 'column' }
        })
      }

      return widgetDefinition
    }
    componentWillReceiveProps(nextProps) {
      if (
        nextProps.widgetDefinition !== this.props.widgetDefinition ||
        nextProps.analyticsContext !== this.props.analyticsContext
      ) {
        if (this.props.autoRefresh) {
          this.setState({}, () => {
            this.handleFetchData()
          })
        } else {
          const widgetDefinition = this._prepareWidgetDefinition(nextProps.widgetDefinition)
          this.setState({
            widgetDefinition,
            layoutWithProps: this._buildLayoutWithProps(widgetDefinition)
          })
        }
      }
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    evaluateProps = itemDef => {
      const ret = {}

      if (!this.state) {
        return {}
      }

      const data = this.state.data

      if (itemDef && itemDef.props) {
        Object.keys(itemDef.props).forEach(key => {
          let value = itemDef.props[key]

          if (typeof itemDef.props[key] === 'object') {
            if (itemDef.props[key].type === 'aggregates') {
              const dataField = itemDef.props[key].dataField

              value = data && data.aggregates && data.aggregates[dataField] ? data.aggregates[dataField] : undefined

              // eslint-disable-next-line
              if (value && value.hasOwnProperty('total')) {
                value = value.total
              }
            } else if (itemDef.props[key].type === 'dataSource') {
              const dataSourceCode = itemDef.props[key].dataSourceCode

              value = []
              if (
                dataSourceCode &&
                data &&
                data.dataSources &&
                data.dataSources[dataSourceCode] &&
                data.dataSources[dataSourceCode].rawData
              ) {
                value = data.dataSources[dataSourceCode].rawData.rows
                  ? data.dataSources[dataSourceCode].rawData.rows
                  : data.dataSources[dataSourceCode].rawData.data
              }
            }
          }
          ret[key] = value
        })
      }

      return ret
    }

    _combineAnalyticContext = ds => {
      let ret = cloneDeep(ds.filter)

      if (this.props.analyticsContext) {
        ret = { ...ret, ...this.props.analyticsContext.filters }
      }

      if (ds.contextMapping && this.props.analyticsContext) {
        Object.keys(ds.contextMapping).forEach(skey => {
          if (this.props.analyticsContext && this.props.analyticsContext.filters) {
            ret[skey] = {
              ...ret[skey],
              ...this.props.analyticsContext.filters[ds.contextMapping[skey]]
            }
          }
        })
      }

      return ret
    }

    get mergedContextToWidgetDefinition() {
      const { widgetDefinition } = this.state
      let _widgetDefinition = cloneDeep(widgetDefinition)

      if (_widgetDefinition.content) {
        _widgetDefinition = {
          ..._widgetDefinition,
          ..._widgetDefinition.content
        }
      }

      if (_widgetDefinition.dataSources) {
        _widgetDefinition.data = _widgetDefinition.dataSource
      }

      _widgetDefinition = mapContextFiltersIntoWidget(_widgetDefinition, this.props)

      return _widgetDefinition
    }

    get dataSourcesVariables() {
      const { widgetDefinition } = this.state
      let filter = {}

      if (this.props.filters) {
        filter = { ...this.props.filters }
      }

      let _widgetDefinition = cloneDeep(widgetDefinition)

      if (_widgetDefinition.content) {
        _widgetDefinition = {
          ..._widgetDefinition,
          ..._widgetDefinition.content
        }
      }

      if (_widgetDefinition.dataSources) {
        _widgetDefinition.data = _widgetDefinition.dataSource
      }
      if (!_widgetDefinition.data) {
        return {}
      }

      let pAnalyticContext = {
        filters: Object.assign(this.props.analyticsContext?.filters ?? {}, filter)
      }

      _widgetDefinition = mapContextFiltersIntoWidget(_widgetDefinition, {
        analyticsContext: pAnalyticContext
      })

      const variables = {
        widgetDefinition: _widgetDefinition,
        filter,
        masterUid: this.props.crmMaster ? this.props.crmMaster.master_uid : undefined,
        master_uid: this.props.crmMaster ? this.props.crmMaster.master_uid : undefined,
        instanceUid: this.props.crmInstance ? this.props.crmInstance.instance_uid : undefined,
        instance_uid: this.props.crmInstance ? this.props.crmInstance.instance_uid : undefined
      }

      return variables
    }

    _fetchDataSingleDataSource = async () => {}

    handleFetchData = async () => {
      const { widgetDefinition } = this.state
      let filter = {}

      if (this.props.filters) {
        filter = { ...this.props.filters }
      }

      let _widgetDefinition = cloneDeep(widgetDefinition)

      if (_widgetDefinition.content) {
        _widgetDefinition = {
          ..._widgetDefinition,
          ..._widgetDefinition.content
        }
      }

      if (_widgetDefinition.dataSources) {
        _widgetDefinition.data = _widgetDefinition.dataSource
      }
      if (!_widgetDefinition.data) {
        return
      }

      let pAnalyticContext = {
        filters: Object.assign(this.props.analyticsContext?.filters ?? {}, filter)
      }

      _widgetDefinition = mapContextFiltersIntoWidget(_widgetDefinition, {
        analyticsContext: pAnalyticContext
      })

      const variables = {
        widgetDefinition: _widgetDefinition,
        filter,
        masterUid: this.props.crmMaster ? this.props.crmMaster.master_uid : undefined,
        master_uid: this.props.crmMaster ? this.props.crmMaster.master_uid : undefined,
        instanceUid: this.props.crmInstance ? this.props.crmInstance.instance_uid : undefined,
        instance_uid: this.props.crmInstance ? this.props.crmInstance.instance_uid : undefined
      }

      await this.setStateAsync({ isLoading: true })

      const res = await this.props.client.query({
        query: qlAnalyticsWidgetQuery,
        variables,
        fetchPolicy: 'no-cache'
      })

      const dataSourceData =
        res && res.data && res.data.master.instance.dataSourceQuery
          ? res.data.master.instance.dataSourceQuery
          : undefined

      this.setState(
        {
          data: this._processData(dataSourceData),
          isLoading: false
        },
        async () => {
          await this.rebuildLayout()
        }
      )

      return dataSourceData
    }

    rebuildLayout = async () => {
      const layoutWithProps = this._buildLayoutWithProps(undefined)
      await this.setStateAsync({ layoutWithProps })
    }

    _buildLayoutWithProps = pWidgetDefinition => {
      const widgetDefinition = pWidgetDefinition || this.state.widgetDefinition
      const items = []

      const mergeWithProps = (parent, target) => {
        if (parent && parent.items) {
          parent.items.forEach(item => {
            const n = { ...item }
            n.itemProps = this.evaluateProps(item)

            if (n.items) {
              n.items = mergeWithProps(n, [])
            }

            target.push(n)
          })
        }

        return target
      }

      if (widgetDefinition && widgetDefinition.content && widgetDefinition.content.layout) {
        mergeWithProps(widgetDefinition.content.layout, items)
      } else if (widgetDefinition && widgetDefinition.layout) {
        mergeWithProps(widgetDefinition.layout, items)
      }

      return { items }
    }

    _processData = dataSourceData => {
      const res = dataSourceData
        ? {
            ...dataSourceData.data
          }
        : {}
      return res
    }

    _evaluateTitle = () => (this.props.title ? this.props.title : this.state.widgetDefinition.title)

    handleEnableEditMode = () => {
      this.setState({ isEditingWidget: true })
    }

    handleCancelEdit = () => {
      this.setState({
        isEditingWidget: false,
        widgetDefinition: cloneDeep(this.props.widgetDefinition),
        widgetDefinitionJson: JSON.stringify(this.props.widgetDefinition, null, 2),
        layoutWithProps: this._buildLayoutWithProps(this.props.widgetDefinition)
      })
    }

    handleSaveWidgetDefinition = async () => {
      try {
        const data = {
          ...this.state.widgetDefinition
        }

        delete data.data
        delete data.layout
        delete data.__typename

        if (!data.def_version) {
          data.def_version = 6
        }

        const saved = await this.props.onSaveDashboard(data)

        this.setState({
          widgetDefinition: cloneDeep(saved),
          widgetDefinitionJson: JSON.stringify(saved, null, 2),
          layoutWithProps: this._buildLayoutWithProps(saved)
        })

        return saved
      } catch (err) {}
    }

    _listAllDataSources = () => {
      let allDataSources = this.props.availableKpis ? [...this.props.availableKpis] : []

      if (this.props.analyticsViews) {
        allDataSources = [...allDataSources, ...this.props.analyticsViews]
      }

      return allDataSources
    }

    handleAddNewDS = async newDS => {
      const widgetDefinition = cloneDeep(this.state.widgetDefinition)
      widgetDefinition.content = widgetDefinition.content || {}

      if (!widgetDefinition.content.data) {
        widgetDefinition.content.data = {}
      }

      if (!widgetDefinition.content.data.dataSources) {
        widgetDefinition.content.data.dataSources = []
      }

      widgetDefinition.content.data.dataSources.push(newDS)
      this._updateWidgetDefinition(widgetDefinition)
    }

    _updateWidgetDefinition(widgetDefinition) {
      this.setState({
        widgetDefinition,
        widgetDefinitionJson: JSON.stringify(widgetDefinition, null, 2),
        layoutWithProps: this._buildLayoutWithProps(widgetDefinition)
      })
    }

    handleAddLayoutElement = event => {
      if (event && event.path) {
        const widgetDefinition = cloneDeep(this.state.widgetDefinition)
        let targetItem = widgetDefinition.content.layout

        event.path.forEach(i => {
          targetItem = targetItem.items[i]
        })

        if (!targetItem.items) {
          targetItem.items = []
        }

        targetItem.items.push({
          type: event.elementType,
          props: {}
        })

        this._updateWidgetDefinition(widgetDefinition)
      }
    }

    handleDndDrop = event => {
      if (!get(event, 'destination.droppableId', false)) {
        const pWidgetDefinition = cloneDeep(this.state.widgetDefinition)

        if (event && event.source && event.source.droppableId) {
          const [, source, index] = event.source.droppableId.split('|')

          if (source === 'dataSource') {
            const dataSourceContent =
              pWidgetDefinition &&
              pWidgetDefinition.content &&
              pWidgetDefinition.content.data &&
              pWidgetDefinition.content.data.dataSources &&
              pWidgetDefinition.content.data.dataSources[index] &&
              pWidgetDefinition.content.data.dataSources[index].fields
                ? pWidgetDefinition.content.data.dataSources[index].fields
                : []

            if (dataSourceContent.length > 0) {
              const fields = dataSourceContent.filter(field => field.fieldPath !== event.draggableId)

              pWidgetDefinition.content.data.dataSources[index].fields = fields
              this._updateWidgetDefinition(pWidgetDefinition)
            }
          }
        }
      }
    }

    handleChangeWidgetName = pWidgetDefinition => {
      this._updateWidgetDefinition(pWidgetDefinition)
    }

    _listAllDataFields() {
      const allDataSources = this._listAllDataSources()

      const allDF = allDataSources.reduce((a, i) => {
        let r = [...a]

        if (i.details && i.details.attributes) {
          r = [...r, ...i.details.attributes]
        }

        return r
      }, [])

      return allDF
    }

    render() {
      if (!this.state.widgetDefinition) {
        return <div>No widget definition for widget Code: {this.props.widgetDefCode}</div>
      }

      const title = this._evaluateTitle()
      const analyticsContext = { ...this.props.analyticsContext }

      if (this.props.filters) {
        analyticsContext.filters = {
          ...analyticsContext.filters,
          ...this.props.filters
        }
      }

      const allDataSources = this._listAllDataSources()
      const allDataFields = this._listAllDataFields()

      return (
        <WrappedComponent
          {...this.props}
          title={title}
          isEditingWidget={this.state.isEditingWidget}
          analyticsContext={analyticsContext}
          data={this.state.data}
          widgetDefinition={this.state.widgetDefinition}
          layout={this.state.layoutWithProps}
          onFetchData={this.handleFetchData}
          dataSourceData={this.state.dataSourceData}
          evaluateProps={this.evaluateProps}
          isLoading={this.state.isLoading}
          onChangeWidgetName={this.handleChangeWidgetName}
          onEnableEditMode={this.handleEnableEditMode}
          onCancelEditWidget={this.handleCancelEdit}
          onSaveWidgetDefinition={this.handleSaveWidgetDefinition}
          allDataSources={allDataSources}
          allDataFields={allDataFields}
          onAddNewDS={this.handleAddNewDS}
          widgetDefinitionJson={this.state.widgetDefinitionJson}
          mergedContextToWidgetDefinition={this.mergedContextToWidgetDefinition}
          onAddLayoutElement={this.handleAddLayoutElement}
          onDndDrop={this.handleDndDrop}
          dataSourcesVariables={this.dataSourcesVariables}
        />
      )
    }
  }

  return withCrmContext(withApollo(Inner))
}

export const genericWidgetProps = {
  title: string.isRequired,
  layout: object.isRequired
}

export const rendererProps = {
  item: object.isRequired,
  renderChildren: func.isRequired
}

export default genericWidgetHoc
