import { reconciliateGroupItemIds } from 'util-functions/functional-utils'

// Actions
export const FETCH_LANDMARKS = 'FETCH_LANDMARKS'
export const RECEIVE_LANDMARKS = 'RECEIVE_LANDMARKS'
export const UPDATE_LANDMARK_GROUP = 'UPDATE_LANDMARK_GROUP'
export const DELETE_LANDMARK_GROUP = 'DELETE_LANDMARK_GROUP'
export const CREATE_LANDMARK_GROUP = 'CREATE_LANDMARK_GROUP'
export const SAVE_LANDMARK = 'SAVE_LANDMARK'
export const DELETE_LANDMARK = 'DELETE_LANDMARK'

// Reducer
const initialState = {
  landmarks: [],
  groups: [],
  loading: true
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LANDMARKS:
      return { ...state, loading: true }
    case RECEIVE_LANDMARKS:
      return {
        ...state,
        landmarks: action.payload.landmarks,
        groups: action.payload.groups,
        loading: false
      }
    default:
      return state
  }
}

// Action Creators
export function fetchLandmarks() {
  return {
    type: FETCH_LANDMARKS
  }
}

export function updateLandmarkGroup(group, selectedIds) {
  return {
    type: UPDATE_LANDMARK_GROUP,
    payload: { group, selectedIds }
  }
}

export function deleteLandmarkGroup(groupId, groupName) {
  return {
    type: DELETE_LANDMARK_GROUP,
    payload: { groupId, groupName }
  }
}

export function createLandmarkGroup(name) {
  return {
    type: CREATE_LANDMARK_GROUP,
    payload: {
      name
    }
  }
}

export function saveLandmark(landmark) {
  return {
    type: SAVE_LANDMARK,
    payload: {
      landmark
    }
  }
}

export function deleteLandmark(id, name) {
  return {
    type: DELETE_LANDMARK,
    payload: {
      id,
      name
    }
  }
}

// Selectors
export const getLandmarks = state => state.landmarks.landmarks
export const getLandmarkGroups = state => state.landmarks.groups
export const getLoading = state => state.landmarks.loading

// T O D O: Use create selector
export const getLandmarkGroupItemIds = (state, groupId) =>
  state.landmarks.groups.find(g => g.id === groupId).itemIds

// T O D O: Use create selector
export const getLandmarkGroup = (state, groupId) =>
  reconciliateGroupItemIds(
    getLandmarkGroups(state).find(group => group.id === groupId),
    getLandmarks(state)
  )

// T O D O: Use create selector
export const getLandmark = (state, landmarkId) =>
  state.landmarks.landmarks.find(landmark => landmark.id === landmarkId) || null
