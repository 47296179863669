/* eslint-disable new-cap, react/no-unused-prop-types */
import React from 'react'
import { connect } from 'react-redux'

import { shape, string, arrayOf, func, bool } from 'prop-types'
import { injectIntl } from 'react-intl'
import AccountListWithFilter from './components/account-list-with-filter.jsx'
import { availableColumns } from './components/accounts-columns.jsx'
import { selectAccount } from 'crm-duxs/accounts-reducer'
import { CrmFullpageContainer } from 'crm-components'

const defaultVisibleColumns = [
  'kpi_health',
  'name',
  'kind',
  'city',
  'owner_full_name',
  'last_result',
  'assigned_time',
  'last_activity_date',
  'last_activity_comment',
  'campaign_name',
  'potential',
  'qualification'
]

const AccountsListInner = (props, columns) => (
  <AccountListWithFilter
    title={props.title}
    isShowTitle
    isShowCreateBtn
    isShowFilter={props.isShowFilter}
    columns={columns}
    visibleColumns={
      props.visibleColumns ? props.visibleColumns : defaultVisibleColumns
    }
    handleRowClick={props.onRowClick} // eslint-disable-line
    fixFilter={props.fixFilter}
    accountsListCode={props.accountsListCode}
    disableMoreFilters={props.disableMoreFilters}
    isModalMode={props.isModalMode}
    defaultColumnFilter={props.defaultColumnFilter}
    noOwnerFilter={props.noOwnerFilter}
    fixQFilter={props.fixQFilter}
    bulkOperationElementType={props.bulkOperationElementType}
    loadData={props.loadData}
  />
)
const AccountsList = props => {
  const columns = props.columns.length > 0 ? props.columns : availableColumns
  if (props.isModalMode) {
    return AccountsListInner(props, columns) // eslint-disable-line
  }
  return (
    <CrmFullpageContainer noInnerNav={props.noInnerNav}>
      {AccountsListInner(props, columns)}
    </CrmFullpageContainer>
  )
}

AccountsList.propTypes = {
  fixFilter: arrayOf(shape({})),
  disableMoreFilters: arrayOf(string),
  columns: arrayOf(shape({})),
  visibleColumns: arrayOf(string),
  title: string,
  onRowClick: func,
  accountsListCode: string,
  defaultColumnFilter: string,
  isModalMode: bool,
  isShowFilter: bool,
  noOwnerFilter: bool,
  noInnerNav: bool
}
AccountsListInner.propTypes = AccountsList.propTypes

AccountsList.defaultProps = {
  fixFilter: [],
  columns: [],
  visibleColumns: undefined,
  disableMoreFilters: [],
  title: undefined,
  onRowClick: undefined,
  isModalMode: false,
  isShowFilter: true,
  accountsListCode: 'accounts',
  defaultColumnFilter: 'name',
  noOwnerFilter: false,
  noInnerNav: false
}
AccountsListInner.defaultProps = AccountsList.defaultProps
const AccountsListIntl = injectIntl(AccountsList)

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    doSelectAccount: account => dispatch(selectAccount(account))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsListIntl)
