import { DataSchema } from './data-utils/schema-types'
import {
  columnsAccount,
  columnsMarketPotential,
  columnsOwner
} from './types/prod-schema/account'
import {
  columnsCampaign,
  columnsLeadSource,
  columnsSalesCampaign
} from './types/prod-schema/campaign'

import { columnsOpportunity } from './types/prod-schema/opportunity'
import { columnsOrder } from './types/prod-schema/order'
import { columnsUser, columnsDepartmentType } from './types/prod-schema/user'

const types = [
  columnsAccount,
  columnsMarketPotential,
  columnsOwner,
  columnsOpportunity,
  columnsCampaign,
  columnsLeadSource,
  columnsSalesCampaign,
  columnsOrder,
  columnsUser,
  columnsDepartmentType
]

export const crmSchemaProd: DataSchema = {
  types
}

export default types
