import React, { PureComponent } from 'react'
import { string, any, func, node } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CrmLabeledFieldEditable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleStartEdit = () => {
    this.setState({ ...this.state, isEditing: true })
  }

  render() {
    const { className, value, label, children } = this.props
    return (
      <div className={`LabeledFieldEditable ${className}`}>
        <div className="LabeledFieldEditable-label">
          {this.props.formatMessage(label, 'form.label')}
        </div>
        <div className="LabeledFieldEditable-value">
          {value}
          {children}
        </div>
        <div className="LabeledFieldEditable-editBtn util-flexCenterContent util-textCenter">
          {false && (
            <FontAwesomeIcon icon="pencil-alt" onClick={this.handleStartEdit} />
          )}
        </div>
      </div>
    )
  }
}

CrmLabeledFieldEditable.propTypes = {
  children: any, // eslint-disable-line
  label: node.isRequired,
  value: string,
  formatMessage: func.isRequired,
  className: string
}

CrmLabeledFieldEditable.defaultProps = {
  children: undefined,
  value: undefined,
  className: undefined
}

export default CrmLabeledFieldEditable
