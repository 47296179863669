import React from 'react'
import { TableConfigSectionFields } from './components/table-section-fields'

export const LayoutConfigHorizontalTable = (props) => {
  return (
    <div>
      Layout Config LayoutConfigHorizontalTable
      <TableConfigSectionFields {...props} />
    </div>
  )
}
