import React, { useState } from 'react'
import { CrmGoogleMap, CrmModal } from '@cartrack-crm/ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ActivityMeetingLocationMap = (props) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false)

  const googleMapOptions = {
    isShow: true,
    width: '100%',
    height: '500px',
    zoom: 13
  }

  const declare_location = props.declare_location?.location
    ? {
        lat: parseFloat(props.declare_location.location.lat),
        lng: parseFloat(props.declare_location.location.lng),
        markerText: 'Declared location',
        markerClass: 'util-textBlue',
        markerStyles: { fontSize: '20px' }
      }
    : undefined

  const actual_location = props.actual_location?.location
    ? {
        lat: parseFloat(props.actual_location.location.lat),
        lng: parseFloat(props.actual_location.location.lng),
        markerText: 'Actual location',
        markerClass: 'util-textGreen',
        markerStyles: { fontSize: '25px' }
      }
    : undefined

  const markers = []
  if (declare_location) {
    markers.push(declare_location)
  }
  if (actual_location) {
    markers.push(actual_location)
  }

  console.log('ActivityMeetingLocationMap', props, markers)

  const center = actual_location
    ? { ...actual_location }
    : { ...declare_location }
  return (
    <React.Fragment>
      <div
        className="util-cursorPointer "
        onClick={() => {
          setIsOpenPopup(true)
        }}
      >
        <FontAwesomeIcon icon="map-marked-alt" />
        {props.location_type}
      </div>
      <CrmModal
        isOpen={isOpenPopup}
        onClose={() => {
          setIsOpenPopup(false)
        }}
        title="Meeting Location"
        size="lg"
      >
        <div className="util-flexColumn">
          <div className="util-marginMd">
            <div className="util-flexRow">
              <div className="col-md-6">
                {declare_location && (
                  <div className="util-flexRow">
                    <div
                      className={declare_location.markerClass}
                      style={{ fontSize: '20px' }}
                    >
                      <FontAwesomeIcon icon="map-marker-alt" />
                    </div>
                    <div className="util-marginLeft">
                      <div>{declare_location.markerText}</div>
                      <strong>
                        {props._addrFormat(props.declare_location)}
                      </strong>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-6">
                {actual_location && (
                  <div className="util-flexRow">
                    <div
                      className={actual_location.markerClass}
                      style={{ fontSize: '20px' }}
                    >
                      <FontAwesomeIcon icon="map-marker-alt" />
                    </div>
                    <div className="util-marginLeft">
                      <div>{actual_location.markerText}</div>
                      <strong>
                        {`lat: ${actual_location.lat}, lng: ${actual_location.lng}`}
                      </strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="util-flexGrow">
            <CrmGoogleMap
              height={googleMapOptions.height}
              width={googleMapOptions.width}
              zoom={googleMapOptions.zoom}
              center={center}
              markers={markers}
            />
          </div>
        </div>
      </CrmModal>
    </React.Fragment>
  )
}

export default ActivityMeetingLocationMap
