import gql from 'graphql-tag'

export const qlAnalyticsWidgetQuery = gql(`
query dataSourceQuery($widgetDefinition: JSON, $filter: JSON ){
  dataSourceQuery(
    widgetDefinition: $widgetDefinition,
    filter: $filter
  )
  {
    data
    meta
  }
}
`)

export const mock = 1
