import React from 'react'
import { number, object, string } from 'prop-types'
import { injectIntl } from 'react-intl'

import cleanProps from 'util-functions/clean-props'
import { formatDistance, formatTemperature } from 'util-components'

const propsToClean = [
  'rotation',
  'payload',
  'units',
  'verticalAnchor',
  'index',
  'intl'
]

export function switchUnits(value, units, intl) {
  switch (units) {
    case 'speed':
      return formatDistance(intl, value, 'ph', true)
    case 'time':
      return intl.formatTime(value)
    case 'temperature':
      return formatTemperature(intl, value)
    default:
      return value
  }
}

const SwitchUnitsNoIntl = props => (
  <span>{switchUnits(props.value, props.units, props.intl)}</span>
)

SwitchUnitsNoIntl.propTypes = {
  units: string.isRequired,
  intl: string.isRequired
}

const CustomChartTick = props => (
  <g transform={`translate(${props.x}, ${props.y})`}>
    <text
      transform={`rotate(${props.rotation})`}
      {...cleanProps(props, propsToClean)}
      x={0}
      y={0}
      fontSize="12px"
    >
      {switchUnits(props.payload.value, props.units, props.intl)}
    </text>
  </g>
)

CustomChartTick.propTypes = {
  x: number,
  y: number,
  rotation: string,
  payload: object, // eslint-disable-line react/forbid-prop-types
  units: string
}

CustomChartTick.defaultProps = {
  x: 0,
  y: 0,
  rotation: '0',
  payload: { value: '' },
  units: ''
}

export const SwitchUnits = injectIntl(SwitchUnitsNoIntl)
export default injectIntl(CustomChartTick)
