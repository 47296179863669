import React from 'react'
// import { CrmMessageBlock } from 'crm-components'

const InfoNoAccountPermission = props => (
  // <CrmMessageBlock
  //   message="You don't have permission for this account"
  //   messageType="info"
  //   disableAutoClose={true}
  // />
  <div className="util-textCenter">
    {"You don't have permission for this account"}
  </div>
)

export default InfoNoAccountPermission
