import React, { Component } from 'react'
import { connect } from 'react-redux'
import { shape, arrayOf } from 'prop-types'
import TasksTable from './components/tasks-table.jsx'
import { actions, getAllTasks } from 'crm-duxs/tasks-reducer'
import { bindActions } from 'crm-utils/reducer-utils'

class ManagerTasksView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    console.log('ManagerTasksView will mount')
    this.props.actions.listAllTasks()
  }

  render() {
    return (
      <div>
        <h3>Manager Tasks</h3>
        <TasksTable tasks={this.props.tasks} />
      </div>
    )
  }
}

ManagerTasksView.propTypes = {
  tasks: arrayOf(shape()).isRequired,
  actions: shape().isRequired
}

function mapStateToProps(state) {
  return {
    tasks: getAllTasks(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActions(dispatch, actions)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerTasksView)
