import React from 'react'
import styled from 'styled-components'
import { asset } from '../asset'
import { MessageBoxProps } from '../type'

const Wrapper = styled.div`
  padding: 0 5px;
  display: flex;
  width: 100%;
  margin: auto;
`
const Text = styled.label`
  color: ${({ type }) => (type === 'danger' ? '#E45D5E' : '')};
  font-weight: 600;
  margin: auto 8px;
  font-size: 15px;
`
const Icon = styled.img`
  margin: auto 0px;
  width: 25px;
`

export const MessageBox = ({ type, message }: MessageBoxProps) => (
  <Wrapper>
    {type === 'danger' && <Icon src={asset.danger_icon} alt={message} />}
    <Text type={type}>{message}</Text>
  </Wrapper>
)
