/* eslint-disable camelcase */
import dashSalesLiveScreen from './dash-sales-live-screen'
import dashContracts from './dash-contracts'

import dashHome from './dash-home'
import dashSalesOverview from './dashboards/dash-sales-overview'
import dashFitmentsDetails from './dashboards/dash-fitments-details'
import dashFitmentsOverview from './dashboards/dash-fitments-overview'
import dashPendingFitments from './dashboards/dash-pending-fitments'
import { dashboards as dashboardsTelesales } from './dashboards/dash-telesales-overview'
import { dashboards as dashboardsRms } from './dashboards/dash-rms'
import dashLiveView from './dashboards/dash-live-view'
import dashLeadsDetails from './dashboards/dash-leads-details'
const dimAccount = { code: 'account', name: 'Account', class: 'account' }

const dimActivityStartDate = {
  code: 'date_performed',
  name: 'Activity Start Date',
  class: 'date'
}

const dimDateCreated = {
  code: 'date_created',
  name: 'Activity Created Date',
  class: 'date'
}
const dimDate = {
  code: 'date',
  name: 'Date',
  class: 'date'
}

const dimActivityResult = {
  code: 'activity_result',
  name: 'Meeting Start Date',
  class: 'date'
}

const dimActivityUserCreated = {
  code: 'activity_user_created',
  name: 'Activity User Created',
  class: 'user'
}

const dimRmUser = {
  code: 'owner_user',
  name: 'RM',
  class: 'user'
}

const dimActivityUserPerformed = {
  code: 'activity_user_performed',
  name: 'Activity User Performed',
  class: 'user'
}

const dimLeadSourceType = {
  code: 'lead_source_type',
  name: 'Lead Source',
  class: 'lead_source_type'
}

const dimActivityStatus = {
  code: 'activity_status',
  name: 'Activity Status',
  class: 'activity_status'
}

const dimUserPerformer = {
  code: 'user_performer',
  name: 'Performed by',
  class: 'user'
}

const dimUserCreator = {
  code: 'user_creator',
  name: 'Created by',
  class: 'user'
}

const dimActivitySourceType = {
  code: 'activity_source_type',
  name: 'Activity Source',
  class: 'activity_source_type'
}

export const dimensionsDefinitions = [
  dimAccount,
  dimActivityResult,
  dimActivityStartDate,
  dimActivityUserCreated,
  dimActivityUserPerformed,
  dimActivityStatus,
  dimLeadSourceType,
  dimUserPerformer,
  dimUserCreator,
  dimDateCreated,
  dimDate,
  dimActivitySourceType,
  dimRmUser
]

export const dsMeetingsAnalytics = {
  description: 'Analysis of meetings ',
  dimensions: [
    dimAccount,
    dimActivityResult,
    dimActivityStartDate,
    dimActivityUserCreated,
    dimActivityUserPerformed,
    dimLeadSourceType,
    dimUserPerformer,
    dimUserCreator,
    dimDateCreated,
    dimDate,
    dimActivitySourceType,
    dimRmUser
  ]
}

// Widget definitions
const tmCreatedMeetings = {
  columns_dims: [
    {
      dim_code: 'date_created',
      kpi_code: 'telesales_new_created_meetings',
      name: 'Date Created',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_code: 'user_creator',
      kpi_code: 'telesales_new_created_meetings',
      name: 'User Creator',
      field_code: 'full_name'
    }
  ],
  kpis: [
    {
      code: 'telesales_new_created_meetings',
      as: 'telesales_new_created_meetings'
    }
  ],
  title: 'Telesales new created meetings',
  filters: [
    {
      kpi_code: 'meetings_details',
      dim_code: 'date_created',
      operator: 'eq',
      default_value: 'october_2017',
      precision: 'month',
      class: 'date'
    }
  ]
}

// General phone calls analysis
const tmPhoneCallsDetails = {
  columns_dims: [
    {
      dim_code: 'date_created',
      kpi_code: 'calls_details',
      name: 'Date Created',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_code: 'user_creator',
      kpi_code: 'calls_details',
      name: 'User Creator',
      field_code: 'full_name'
    }
  ],
  values_dims: [
    {
      dim_code: 'value',
      kpi_code: 'calls_details'
    }
  ],
  kpis: [{ code: 'calls_details', as: 'calls_details' }],
  title: 'Phone calls by created date',
  layout: 'pivot',
  filters: [
    {
      kpi_code: 'calls_details',
      dim_code: 'date_created',
      operator: 'eq',
      default_value: 'october_2017',
      precision: 'month',
      class: 'date'
    }
  ]
}

//
const tmPhoneCallsList = {
  columns_dims: [
    {
      dim_code: 'date_created',
      kpi_code: 'calls_details',
      name: 'Date Created',
      generate_values: true
    },
    {
      dim_code: 'user_creator',
      kpi_code: 'calls_details',
      name: 'user_creator'
    }
  ],
  rows_dims: [
    {
      dim_code: 'kpi',
      kpi_code: 'calls_details',
      name: 'Kpi',
      field_code: 'Kpi'
    }
  ],
  values_dims: [
    {
      dim_code: 'date_created',
      kpi_code: 'calls_details'
    }
  ],
  kpis: [{ code: 'calls_details', as: 'calls_details' }],
  title: 'Phone calls List',
  layout: 'details',
  filters: [
    {
      kpi_code: 'calls_details',
      dim_code: 'date_created',
      operator: 'eq',
      default_value: 'october_2017',
      precision: 'month',
      class: 'date'
    }
  ]
}
// Opportunities details
const widgetOpportunities = {
  columns_dims: [
    {
      dim_code: 'date_created',
      kpi_name: 'opportunities_details',
      name: 'Date Created',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_dode: 'user_owner',
      kpi_name: 'opportunities_details',
      name: 'Owner User'
    }
  ],
  kpis: [{ code: 'opportunities_details', as: 'opportunities_details' }],
  title: 'Opportunities details',
  filters: [
    {
      kpiCode: 'calls.details',
      dimCode: 'date_created',
      operator: 'eq',
      default_value: 'october_2017',
      precision: 'month',
      class: 'date'
    }
  ]
}

// Dashboards

export const dashboards = [
  {
    dashboard_uid: 'telesalesLiveMonitoring',
    name: 'Telesales Live Monitoring Dashboard',
    fullscreen: true,
    content: {
      items: [
        {
          type: 'analitics_widget',
          widgetDefinition: tmCreatedMeetings
        },
        {
          type: 'analitics_widget',
          widgetDefinition: tmPhoneCallsDetails
        }
      ]
    }
  },
  dashSalesLiveScreen,
  dashContracts,
  dashFitmentsDetails,
  dashFitmentsOverview,
  dashPendingFitments,
  dashHome,
  dashSalesOverview,
  ...dashboardsTelesales,
  dashLiveView,
  ...dashLeadsDetails,
  ...dashboardsRms
]

// Meetings created today analysis
const meetingsCreatedToday = {
  columns_dims: [
    {
      dim_code: 'date_created',
      kpi_code: 'meetings_details',
      name: 'Date Created',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_code: 'user_creator',
      kpi_code: 'meetings_details',
      name: 'User Created'
    }
  ],
  kpis: [{ code: 'meetings_details', as: 'meetings_details' }],
  title: 'Telesales new created meetings',
  filters: [
    {
      kpi_code: 'meetings.details',
      dim_code: 'date_created',
      default_value: 'october_2017',
      operator: 'eq',
      class: 'date'
    }
  ]
}

const dashMeetingsAnalysis = {
  dashboard_uid: 'dashMeetingsAnalysis',
  name: 'Meetings analysis Dashboard',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'analitics_widget',
        widgetDefinition: meetingsCreatedToday
      }
    ]
  },
  showDetailsTable: true
}
dashboards.push(dashMeetingsAnalysis)

const dashPhoneCalls = {
  dashboard_uid: 'dashPhoneCalls',
  name: 'NEW Phone Calls analysis Dashboard',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'analitics_widget',
        widgetDefinition: tmPhoneCallsDetails
      },
      {
        type: 'analitics_widget',
        widgetDefinition: tmPhoneCallsList
      }
    ]
  },
  showDetailsTable: true
}
dashboards.push(dashPhoneCalls)

// Opportunities
dashboards.push({
  dashboard_uid: 'dashOpportunities',
  name: 'Opportunities Dashboard',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'analitics_widget',
        widgetDefinition: widgetOpportunities
      }
    ]
  },
  showDetailsTable: true
})

export const kpisDefinitions = [dsMeetingsAnalytics]
export const mock = 1
