import React, { PureComponent } from 'react'
import { func } from 'prop-types'
import ReportReduxForm from './report-form.jsx'

class ReportCreateForm extends PureComponent {
  handleSave = values => {
    this.props.onSave(values)
  }

  render() {
    const initialReport = { definitions: '{}' }
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div>
          <span>Create report</span>
        </div>
        <div style={{ flex: 1, position: 'relative' }}>
          <ReportReduxForm
            initialValues={initialReport}
            editing="false"
            onSubmit={this.handleSave}
            onCancel={this.props.onCancel}
            onReset={this.props.onReset}
          />
        </div>
      </div>
    )
  }
}

ReportCreateForm.propTypes = {
  onCancel: func.isRequired,
  onReset: func.isRequired,
  onSave: func.isRequired
}

export default ReportCreateForm
