/* eslint-disable camelcase */
import React from 'react'
import { shape, string, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import newLiveCallHoc from '../new-live-call-hoc.jsx'
import AccountContactDetailsBigDropdownQL from '../../components/account-contact-details-modal.jsx'
import liveCallHoc from 'crm-modules/activities/hoc/live-call-hoc.jsx'
import { SLFormsProvider, SlFormRuntime } from '@cartrack-crm/crm-analytics'

import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

class ScriptPhoneCallInner extends React.PureComponent {
  constructor(props) {
    super(props)
    // console.log('ScriptPhoneCallInner constructor', props)
    this.state = {
      slFormsRuntimeParams: this._generateSlFormRuntime(props.activity),
      scriptCode: props.scriptCode,
      activityTypeUid: props.activityTypeUid
    }
  }

  _doSave = async resultData => {
    // console.log('_doSave', resultData)
    const res = await this.props.onResultDataChange(resultData)
    return res
  }

  handleSave = async params => {
    const { slFormRuntime } = params
    // console.log('Save handler', params)
    const activityMinutes = slFormRuntime.rootContext.activityMinutes
    // console.log('activityMinutes ', activityMinutes)
    await this._doSave(activityMinutes)
    const saveResult = { result: 'OK' }
    return saveResult
  }
  _generateSlFormRuntime(activity) {
    // console.log('Generate sl Form Runtime', this.props)
    const slFormsRuntimeParams = {
      contexts: {
        activityMinutes: {}
      },
      defaultContextName: 'activityMinutes'
    }
    if (activity) {
      slFormsRuntimeParams.contexts.activity = {
        activity
      }
    }
    return slFormsRuntimeParams
  }

  render() {
    console.log('render Secript Phone Call Runtime')
    return (
      <div>
        Inner
        {this.state.slFormsRuntimeParams ? (
          <SLFormsProvider
            onSave={this.handleSave}
            slFormsRuntimeParams={this.state.slFormsRuntimeParams}
          >
            {this.props.scriptCode ? (
              <SlFormRuntime scriptCode={this.props.scriptCode} />
            ) : (
              <div>No script code</div>
            )}
          </SLFormsProvider>
        ) : (
          <div> no this.state.slFormsRuntimeParams </div>
        )}
      </div>
    )
  }
}

const ScriptPhoneCallInnerHoc = crmGenericCommandHoc(
  liveCallHoc(ScriptPhoneCallInner)
)
ScriptPhoneCallInner.propTypes = {
  activity: shape().isRequired,
  scriptCode: string.isRequired,
  ...cqCommandPropTypes
}

class ScriptPhoneCallRuntime extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      scriptCode: this.props.scriptCode,
      activityTypeUid: this.props.activityTypeUid
    }
  }

  handleSelectedPhoneChange = async cd => {
    // console.log('handleSelectedPhoneChange', cd)
    this.setState({ contactDetailsUid: cd.contact_details_uid }, () => {
      this.initNewLiveCall()
    })
  }

  initNewLiveCall = async () => {
    // console.log('initNewLiveCall', this.props, this.state)
    if (this.state && this.state.contactDetailsUid && this.state.scriptCode) {
      const newActivity = await this.props.onStartNewLiveActivity({
        account_uid: this.props.account.account_uid,
        contact_details_uid: this.state.contactDetailsUid,
        process_uid: this.props.process
          ? this.props.process.process_uid
          : undefined,
        scriptCode: this.state.scriptCode
      })
      // console.log('newActivity', newActivity)
      this.setState({
        activity: newActivity
      })
    }
  }
  componentDidMount = async () => {
    // console.log('ScriptPhoneCallRuntime did mount - init activity')
    this.initNewLiveCall()
  }

  _renderSelectContactDetails() {
    return (
      <div>
        <h5>
          <FormattedMessage
            id="crm.ui.account.activity.select_phone_number"
            defaultMessage="Select phone number to start new Live Call"
          />
        </h5>
        <AccountContactDetailsBigDropdownQL
          accountUid={this.props.account.account_uid}
          phoneOnly
          selectable
          onChange={this.handleSelectedPhoneChange}
          autoSelect
        />
      </div>
    )
  }

  render() {
    console.log('render ScriptPhoneCallRuntime')
    return (
      <div style={{ border: '1px solid red' }}>
        {!this.state.activity && this._renderSelectContactDetails()}
        {this.state.activity && (
          <ScriptPhoneCallInnerHoc
            scriptCode={this.props.scriptCode}
            activity={this.state.activity}
          />
        )}
        {!this.state.activity && <div> Creating new activity .. </div>}
      </div>
    )
  }
}
ScriptPhoneCallRuntime.propTypes = {
  activity: shape(),
  scriptCode: string.isRequired,
  onStartNewLiveActivity: func.isRequired,
  ...cqCommandPropTypes
}
ScriptPhoneCallRuntime.defaultProps = {
  activity: undefined
}
export default crmGenericCommandHoc(newLiveCallHoc(ScriptPhoneCallRuntime))
