/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import { FormattedMessage } from 'react-intl'
import quotationWinQl from 'crm-modules/quotations/hoc/quotation-window-hoc'
import {
  CrmButton,
  CrmCheckbox,
  CrmDropdown,
  CrmDayPickerInput
} from 'crm-components'
import QuotationInlineEditable from './quotation-inline-editable'
import { getDateFormat } from 'crm-utils/time-utils'

interface IProps {
  opportunity: any
  onSaveWin: Function
  onCancel: Function
  isSaving: boolean
  quotation: any
  onShowConfetti: Function
  handleErrors: Function
}

interface IState {
  contractWinDate: Date
  isDataVerified: boolean
  isVerified: boolean
}

class QuotationWinForm extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      contractWinDate: new Date(),
      isDataVerified: false,
      isVerified: false
    }
  }

  handleSave = async (event) => {
    event.preventDefault()
    if (!this.state.isVerified) {
      return
    }
    try {
      const res = await this.props.onSaveWin({
        contractWinDate: this.state.contractWinDate,
        document_uid: this.props.quotation.document_uid
      })
      if (res) {
        toastr.success('Quotation Won')
        this.props.onShowConfetti()
        this.props.onCancel()
      }
    } catch (err) {
      console.log('Problem saving opp status', err)
      let message = 'Problem saving opportunity status'
      if (err) {
        message += ': ' + err
      }
      toastr.error(message)
    }
  }

  handleChangeVerified = () => {
    this.setState({ isVerified: !this.state.isVerified })
  }

  handleSignDateChange = (fieldName, newValue) => {
    this.setState({
      contractWinDate: newValue
    })
  }

  render() {
    const signDate = getDateFormat(this.state.contractWinDate)
    return (
      <div className="winDialog slider" id="winDialog">
        <div className="winMessage">
          <FormattedMessage
            id="crm.quotation.congratulations_you_won_a_contract"
            defaultMessage="Congratulations! You Won a contract!"
          />
        </div>
        <br />
        <br />
        <div className="row">
          <div style={{ marginLeft: '8px' }}>
            <CrmCheckbox
              style={{ width: '100%' }}
              input={{
                value: this.state.isVerified,
                onChange: this.handleChangeVerified
              }}
              label=""
            />
          </div>
          <div style={{ paddingLeft: '10px' }}>
            <div className="LabeledField-value">
              {
                <FormattedMessage
                  id="crm.quotation.confirmed"
                  defaultMessage="Confirmed"
                />
              }
            </div>
            <div>
              <FormattedMessage
                id="crm.quotation.signed_sa_matches_quotation_presented_above"
                defaultMessage="Signed SA matches Quotation presented above"
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-md-6 winSignedDate">
            <QuotationInlineEditable
              fieldName="contractWinDate"
              id="quotation-contractWinDate"
              label={
                <FormattedMessage
                  id="crm.quotation.date_signed"
                  defaultMessage="Date Signed"
                />
              }
              value={signDate}
              displayValue={''}
              editable={true}
              readModeStyle="CrmLabeledField"
              handleErrors={this.props.handleErrors}
              editComponent={CrmDayPickerInput}
              handleFieldChange={this.handleSignDateChange}
            />
          </div>
          <div className="col-md-3">
            <CrmButton
              label={
                <FormattedMessage
                  id="crm.ui.button.cancel"
                  defaultMessage="Cancel"
                />
              }
              type="cancel"
              grouped
              onClick={() => this.props.onCancel()}
            />
          </div>
          <div className="col-md-3">
            {this.state.isVerified && (
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.button.save"
                    defaultMessage="Save"
                  />
                }
                grouped
                onClick={this.handleSave}
                isSaving={this.props.isSaving}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const QuotationWinFormHoc = quotationWinQl(QuotationWinForm)

interface QuotationLostFormProps {
  opportunity: any
  onSaveLost: Function
  processType: any
  onCancel: Function
  isSaving: boolean
  quotation: any
}

interface QuotationLostFormState {
  reasonLostUid: string
}

class QuotationLostForm extends PureComponent<
  QuotationLostFormProps,
  QuotationLostFormState
> {
  constructor(props) {
    super(props)
    this.state = {
      reasonLostUid: undefined
    }
  }

  handleReasonLostChange = (value) => {
    this.setState({
      reasonLostUid: value
    })
  }

  handleSaveLost = async (event) => {
    event.preventDefault()
    if (!this.state.reasonLostUid) {
      toastr.error('Select reason')
      return
    }
    const res = await this.props.onSaveLost({
      resultStepTypeUid: this.state.reasonLostUid,
      document_uid: this.props.quotation.document_uid
    })
    if (res) {
      toastr.success('Quotation marked as Lost')
      this.props.onCancel()
    }
  }

  _getReasonsLost = () =>
    this.props.processType && this.props.processType.steps
      ? this.props.processType.steps
          .filter((s) => s.kind === 'lost' && s.is_final_step)
          .sort((a, b) => a.step > b.step)
          .map((s) => ({ name: s.name, value: s.process_step_type_uid }))
      : []

  render() {
    console.log('QuotationWinLoseWidget.render', this.props)
    const reasonsLost = this._getReasonsLost()
    return (
      <div className="winDialog">
        <div className="winMessage">Your quotation has been rejected!</div>
        <br />
        <br />
        <div className="row">
          <div className="col-md-4">
            <CrmDropdown
              options={reasonsLost}
              placeholder="Reason"
              input={{
                onChange: this.handleReasonLostChange,
                value: this.state.reasonLostUid
              }}
            />
          </div>
          <div className="col-md-2" />
          <div className="col-md-3">
            <CrmButton
              label="Cancel"
              grouped
              onClick={() => this.props.onCancel()}
            />
          </div>
          <div className="col-md-3">
            <CrmButton
              disabled={!this.state.reasonLostUid}
              label="Save"
              grouped
              onClick={this.handleSaveLost}
              isSaving={this.props.isSaving}
            />
          </div>
        </div>
      </div>
    )
  }
}

const QuotationLostFormHoc = quotationWinQl(QuotationLostForm)

interface QuotationWinLoseWidgetProps {
  selectedOption: any
  accountUid: string
  opportunityUid: string
  quotation: any
  handleCancelWinLost: Function
  onShowConfetti: Function
  processType: any
}

interface QuotationWinLoseWidgetState {
  selectedOption: any
}

class QuotationWinLoseWidget extends PureComponent<
  QuotationWinLoseWidgetProps,
  QuotationWinLoseWidgetState
> {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: undefined
    }
  }

  render() {
    const sliderWin =
      this.props.selectedOption === 'win' ? 'slider' : 'slider closed'
    const sliderLost =
      this.props.selectedOption === 'lost' ? 'slider' : 'slider closed'
    console.log('QuotationWinLoseWidget.render ', this.props)
    return (
      <React.Fragment>
        {/* {this.props.selectedOption === 'win' && ( */}
        <div className={sliderWin}>
          <QuotationWinFormHoc
            accountUid={this.props.accountUid}
            opportunityUid={this.props.opportunityUid}
            quotation={this.props.quotation}
            onCancel={this.props.handleCancelWinLost}
            onShowConfetti={this.props.onShowConfetti}
          />
        </div>
        {/* )} */}
        {/* {this.props.selectedOption === 'lost' && ( */}
        <div className={sliderLost}>
          <QuotationLostFormHoc
            quotation={this.props.quotation}
            onCancel={this.props.handleCancelWinLost}
            processUid={this.props.quotation.process_uid}
          />
        </div>
        {/* )} */}
      </React.Fragment>
    )
  }
}

export default QuotationWinLoseWidget
