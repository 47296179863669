/* eslint-disable camelcase */
import React, { Component } from 'react'
import { CrmDropdownButton } from 'crm-components'
import { SectionHeader } from 'util-components'
import SchedulePart from './schedule-part'
import { AnalyticsContextProvider } from 'crm-core/analytics/context'

interface ReportFormItemProps {
  fields: any
  handleChangeFilterValues: Function
}
class ReportFormItem extends Component<ReportFormItemProps> {
  state = {}

  handleCreate = (initialPart = {}) => {
    this.props.fields.push(initialPart)
  }

  handleDelete = (index) => {
    this.props.fields.remove(index)
  }

  get partOptions() {
    return [
      { key: 'text', label: 'Plain Text' },
      { key: 'widget', label: 'Widget' }
    ].map((item) => ({
      ...item,
      onClick: () => this.handleCreate(item.key)
    }))
  }

  render() {
    const datas = this.props.fields.getAll()
    console.log('report-form44', this.props.fields)
    return (
      <React.Fragment>
        <SectionHeader
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>Content Parts</div>
              <CrmDropdownButton
                onClick={() => this.handleCreate()}
                label="Add Part"
                options={this.partOptions}
                style={{ marginRight: 20 }}
              />
            </div>
          }
          sub
          id="content-info-section"
        />
        <div style={{ marginTop: '10px' }}>
          {this.props.fields.map((item, index) => {
            const initialFilters = datas[index].filter
            return (
              <React.Fragment>
                <AnalyticsContextProvider initialFilters={initialFilters}>
                  <SchedulePart
                    key={item}
                    item={item}
                    data={datas[index]}
                    handleDelete={() => this.handleDelete(index)}
                    index={index}
                    handleChangeFilterValues={
                      this.props.handleChangeFilterValues
                    }
                  />
                </AnalyticsContextProvider>
              </React.Fragment>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default ReportFormItem
