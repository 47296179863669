import React, { PureComponent } from 'react'
import { shape, bool } from 'prop-types'
import gql from 'graphql-tag' // eslint-disable-line
import { FormattedMessage } from 'react-intl'
import { graphql, compose } from 'react-apollo' // eslint-disable-line
import { toastr } from 'react-redux-toastr'
import AccountContactDetailsBigDropdownQL from '../components/account-contact-details-modal.jsx'
import { CrmTextInput } from 'crm-components'

class ValidationMarketPotential extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      newPhone: undefined,
      isEdited: false,
      isSaving: false
    }
  }
  setStateAsync = state =>
    new Promise(resolve => {
      this.setState(state, resolve)
    })

  componentWillReceiveProps(nextProps) {
    if (nextProps.account !== this.props.account) {
      this.setState({
        ...this.state
      })
    }
  }

  handleChangePhone = event => {
    this.setState({ ...this.state, newPhone: event.currentTarget.value })
  }

  handleClickSave = async () => {
    console.log('handleClickSave')
    if (!this.state.size) {
      return toastr.error(
        <FormattedMessage
          id="crm.ui.account.lead.please_provide_new_market_size"
          defaultMessage="Please provide new market size"
        />
      )
    }
  }

  _findValidatedContactDetail() {
    console.log('_findValidatedContactDetail')
    console.dir(this.props.account)
    const contactDetails =
      this.props.account && this.props.account.contact_details
        ? this.props.account.contact_details.find(cd => cd.validated_time)
        : undefined

    return contactDetails
  }

  render() {
    const validatedContactDetails = this._findValidatedContactDetail()
    console.log('validatedContactDetails', this.props)
    const isOk = validatedContactDetails !== undefined
    const dropdownContent = () =>
      validatedContactDetails ? (
        <span>{validatedContactDetails.value}</span>
      ) : (
        <span>
          <FormattedMessage
            id="crm.ui.account.lead.not_found"
            defaultMessage="Not Found!"
          />
        </span>
      )

    return (
      <div
        className={`LeadActionsWidget-validationItem ${
          isOk
            ? 'LeadActionsWidget-validationItem--ok'
            : 'LeadActionsWidget-validationItem--bad'
        }`}
      >
        <div className="row">
          <div className="col-md-6">
            <div>
              <FormattedMessage
                id="crm.ui.account.lead.validated_phone_number"
                defaultMessage="Validated phone number"
              />
            </div>
            {this.props.isEdited ? (
              <CrmTextInput
                placeholder="Phone Number"
                placeholderId="crm.ui.account.lead.phone_number"
                input={{
                  value: this.state.size,
                  onChange: this.handleChangePhone
                }}
              />
            ) : (
              <div className="LeadActionsWidget-validationItem-value">
                <AccountContactDetailsBigDropdownQL
                  dropdownContent={dropdownContent}
                  accountUid={this.props.account.account_uid}
                />
              </div>
            )}
          </div>
          <div className="col-md-3 util-flexCenterContent">
            {/* <CrmButton
              title="Edit"
              grouped
              iconButton
              icon="pencil"
              onClick={this.props.onStartEdit}
            /> */}
          </div>
          <div
            className="col-md-3 util-flexCenterContent
          LeadActionsWidget-validationItem-status "
          >
            {isOk ? (
              <div className="LeadActionsWidget-validationItem--ok">
                <i className="fa fa-check" />
              </div>
            ) : (
              <div className="LeadActionsWidget-validationItem--bad">
                <i className="fa fa-warning" />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

ValidationMarketPotential.defaultProps = {}

ValidationMarketPotential.propTypes = {
  account: shape({}).isRequired,
  isEdited: bool.isRequired
}

export default ValidationMarketPotential
