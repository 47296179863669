import { msToUTCTimestamp } from 'util-functions/time-utils'
import { getOpportunityViews } from '@cartrack-crm/crm-filter-section/src/opportunity/opportunities-view-select'
import { convertUnderlineFilterToObject } from 'crm-modules/dashboard/dashboard-utils'

export const mapOpportunity = opportunity => {
  const result = {
    account_uid: opportunity.account_uid,
    confidence_level: opportunity.confidence_level,
    contract_length: opportunity.contract_length,
    due_date: opportunity.due_date
      ? msToUTCTimestamp(opportunity.due_date)
      : undefined,
    is_deleted: opportunity.is_deleted,
    opportunity_item_delete_ids: opportunity.opportunity_item_delete_ids,
    name: opportunity.name,
    opportunity_uid: opportunity.opportunity_uid,
    value: opportunity.value,
    owner_user_uid: opportunity.owner_user_uid,
    value_currency: opportunity.value_currency,
    value_usd: opportunity.value_usd,
    opportunity_type_uid: opportunity.opportunity_type_uid,
    marketing_campaign_uid: opportunity.marketing_campaign_uid,
    commission_reduction: opportunity.commission_reduction,
    special_project: opportunity.special_project,
    business_source: opportunity.business_source,
    forecast: opportunity.forecast,
    current_progress_update: opportunity.current_progress_update,
    action_plan_for_next_steps: opportunity.action_plan_for_next_steps,
    prospect_uid: opportunity.prospect_uid,
    format: opportunity.format,
    parent_process_uid: opportunity.parent_process_uid,
    contract_signed_date: opportunity.contract_signed_date,
    is_existing_customer: opportunity.is_existing_customer,
    winning_quotation_uid: opportunity.winning_quotation_uid,
    quantity: opportunity.quantity
  }
  return result
}

export const mapDefaultToContextFilters = filters => {
  const filter_objects = convertUnderlineFilterToObject(filters)
  const result = { ...filter_objects }

  if (filter_objects.opp_owner_user_uid) {
    result.user_uid = {
      value: filter_objects.opp_owner_user_uid.value,
      label: filter_objects.opp_owner_user_uid.label
    }
    delete result.opp_owner_user_uid
    delete result.department_uid
  }

  if (filter_objects.status_code) {
    const status_code =
      filter_objects.status_code === 'active'
        ? 'pending'
        : filter_objects.status_code
    const views = getOpportunityViews()
    const selected_view = views.filter(v => v.value === status_code)[0]
    result.status = {
      value: selected_view.value,
      label: selected_view.name
    }
  }

  if (filter_objects.is_overdue) {
    const views = getOpportunityViews()
    const selected_view = views.filter(v => v.value === 'overdue')[0]
    result.opportunities_status = {
      value: selected_view.value,
      label: selected_view.name
    }
    delete result.is_overdue
  }

  return result
}
