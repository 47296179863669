import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { shape, func, bool, arrayOf, string } from 'prop-types'
import { reduxForm, submit, getFormValues, change } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import SectionActivities from './_section-activities.jsx'
import SectionNeedsAnalysis from './prospect/_prospect-needs-analysis.jsx'
import { getInstanceParameter } from 'crm-duxs/crm-reducer'
import FeedList from 'crm-modules/feed/feed-list.jsx'
import { useCrmToastr, CrmToastrType } from '@cartrack-crm/ui/src/toastrs'
import { bindActions } from 'crm-utils/reducer-utils'
import {
  CrmForm,
  CrmFormGroup,
  CrmButton,
  CrmList,
  CrmModal,
  CrmLabeledField
} from 'crm-components'
import { actions as accountActions } from 'crm-duxs/account-reducer'
import AccountListItem from 'crm-modules/accounts/components/account-list-item.jsx'
import AccountLeftPage from 'crm-modules/accounts/components/layout19/account-left-page.jsx'
import moment from 'moment'

class AccountForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      accountId: '',
      isOpenMetaModal: false,
      isKpisVisible: false,
      isOpenHistoryModal: false,
      sendEmailFromQuotation: props.sendEmailFromQuotation
        ? { ...props.sendEmailFromQuotation }
        : undefined
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.sendEmailFromQuotation !== nextProps.sendEmailFromQuotation
    ) {
      this.setState({
        sendEmailFromQuotation: nextProps.sendEmailFromQuotation
          ? { ...nextProps.sendEmailFromQuotation }
          : undefined
      })
    }
  }

  handleSave = () => {
    this.props.doSave()
  }
  handleEditAccount = () => {
    this.props.onStartEdit()
  }

  handleCancelEdit = () => {
    console.log('handleCancelEdit', this.props)
    this.props.onCancelEdit()
  }

  handleShowMetaModal = () => {
    this.setState({ isOpenMetaModal: true })
  }
  onClickMetaModalClose = () => {
    this.setState({ isOpenMetaModal: false })
  }
  _renderShowHistoryOfAnalysisAndValueProPosition = () => {
    const { account } = this.props
    const processes =
      account && account.processes
        ? account.processes.filter(
            pr => pr.process_type && pr.process_type.kind !== 'opportunity'
          )
        : []
    const list_user_uid = new Set([
      ...processes.map(item => {
        if (item && item.owner_user) return item.owner_user.user_uid
      })
    ])
    if (list_user_uid.size > 1) {
      return (
        <React.Fragment>
          <ModalHistory
            isOpen={this.state.isOpenHistoryModal}
            onClose={() => this.setState({ isOpenHistoryModal: false })}
            processes={processes}
          />
          <CrmButton
            className="CrmButton--secondary CrmButton--small"
            label="show history"
            onClick={() => this.setState({ isOpenHistoryModal: true })}
            style={{ width: '100px' }}
          />
        </React.Fragment>
      )
    }
  }
  _renderBasic() {}
  _renderContacts() {}

  render() {
    const {
      account,
      handleSubmit,
      isEditing,
      accountActions,
      hasAccountDataPermission
    } = this.props
    const isNew = account && account.account_uid === 'new'
    const paddingStyle = { paddingLeft: 10, paddingRight: 10, height: '100%' }
    const isProcessEditable =
      this.props.process && !this.props.process.completed_time
    console.log('Render Account Form', this.props, isProcessEditable, isNew)
    const isFeedListVisible = false

    if (account) {
      return (
        <CrmForm
          onSubmit={handleSubmit}
          style={{ padding: 0 }}
          fullHeight={!isEditing}
        >
          {/* Have to keep in separate group and inside <form> element */}
          <CrmFormGroup groupName="General" fullHeight={!isEditing}>
            <div className="row util-fullHeight">
              <div
                className={`col-xs-12 
                  ${!isEditing && ' util-fullHeight util-flexColumn '}
                  ${isFeedListVisible ? 'col-md-5 ' : 'col-md-5 '}`}
              >
                {this.props.renderLeft && this.props.renderLeft()}
                {!isEditing && (
                  <div
                    className="CrmFormSection-body util-flexGrow util-overflowYAuto"
                    style={{
                      overflowX: 'hidden'
                    }}
                  >
                    <AccountLeftPage
                      account={account}
                      hasAccountDataPermission={hasAccountDataPermission}
                      isManager={this.props.isManager}
                    />
                  </div>
                )}
                <div style={{ height: '35px' }}></div>
              </div>
              <div
                className={`col-xs-12
                  ${!isEditing && ' util-fullHeight '}
                  ${isFeedListVisible ? 'col-md-5 ' : 'col-md-7 '}`}
              >
                <div
                  style={paddingStyle}
                  className={`${!isEditing && ' util-flexColumn '}`}
                >
                  {!isNew &&
                    !isEditing &&
                    this.props.renderRight &&
                    this.props.renderRight()}
                  {!isNew && !isEditing && (
                    <React.Fragment>
                      <SectionNeedsAnalysis
                        accountUid={account.account_uid}
                        process={this.props.process}
                        isEditable={isProcessEditable}
                        hasAccountDataPermission={hasAccountDataPermission}
                      />
                      {this._renderShowHistoryOfAnalysisAndValueProPosition()}
                    </React.Fragment>
                  )}

                  {!isNew && !isEditing && (
                    <SectionActivities
                      processUid={
                        this.props.process
                          ? this.props.process.process_uid
                          : undefined
                      }
                      process={this.props.process}
                      accountUid={account.account_uid}
                      account={account}
                      onLiveCallCompleted={this.props.onLiveCallCompleted}
                      isEditing={isEditing}
                      hasAccountDataPermission={hasAccountDataPermission}
                      sendEmailFromQuotation={this.state.sendEmailFromQuotation}
                      handleEndSendingEmailFromQuotation={
                        this.props.handleEndSendingEmailFromQuotation
                      }
                      forceCloseActivityPopup={
                        this.props.forceCloseActivityPopup
                      }
                    />
                  )}
                </div>
              </div>
              {!isNew && isFeedListVisible && (
                <div className="col-md-2 col-xs-12">
                  <FeedList
                    accountUid={account.account_uid}
                    filter={{ account_uid: account.account_uid }}
                  />
                </div>
              )}
            </div>
            {this.props.duplicates && this.props.duplicates.length > 0 && (
              <div className="util-paddingMd">
                <strong>
                  <FormattedMessage
                    id="crm.ui.account.form.similar_accounts_found"
                    defaultMessage="Similar accounts found (showing first 5)"
                  />
                </strong>
                <CrmList>
                  {this.props.duplicates.map(d => (
                    <AccountListItem key={d.account_uid} account={d} />
                  ))}
                </CrmList>
              </div>
            )}
            {this.props.didCheckForDuplicates &&
              this.props.duplicates &&
              this.props.duplicates.length === 0 && (
                <div className="util-paddingMd util-textGrey">
                  <FormattedMessage
                    id="crm.ui.account.form.no_duplicates_found"
                    defaultMessage="No duplicates found"
                  />
                </div>
              )}
            {this.props.isNew && !this.props.didCheckForDuplicates && (
              <div>
                <label className="util-textWarning">
                  <FormattedMessage
                    id="crm.ui.account.form.before_saving_you_must_check_duplicates"
                    defaultMessage="Before saving new account you must check for duplicates"
                  />
                </label>
              </div>
            )}
          </CrmFormGroup>
        </CrmForm>
      )
    }
    return (
      <div>
        <FormattedMessage
          id="crm.ui.account.form.no_account"
          defaultMessage="No account"
        />
      </div>
    )
  }
}

AccountForm.defaultProps = {
  account: undefined,
  hasNavbar: true,
  isEditing: false,
  isSaving: false,
  accountFormValues: undefined,
  onCancelEdit: undefined,
  onStartEdit: undefined,
  processUid: undefined,
  process: undefined,
  onLiveCallCompleted: undefined,
  duplicates: undefined,
  isCheckingDuplicates: false,
  formValues: undefined,
  showAccountLocalName: false,
  currentModuleCode: undefined,
  historyMode: false,
  hasAccountDataPermission: false,
  renderLeft: undefined,
  renderRight: undefined,
  isManager: false,
  sendEmailFromQuotation: undefined,
  handleEndSendingEmailFromQuotation: undefined,
  forceCloseActivityPopup: false
}

AccountForm.propTypes = {
  account: shape({}).isRequired,
  accountFormValues: shape({}),
  handleSubmit: func.isRequired,
  change: func.isRequired,
  hasNavbar: bool,
  isEditing: bool,
  isSaving: bool,
  onStartEdit: func,
  onCancelEdit: func,
  changeFieldValue: func.isRequired,
  accountActions: shape({}).isRequired,
  doSave: func.isRequired,
  processUid: string,
  process: shape({}),
  onLiveCallCompleted: func,
  formValues: shape({}),
  duplicates: arrayOf(shape({})),
  isNew: bool.isRequired,
  didCheckForDuplicates: bool.isRequired,
  isCheckingDuplicates: bool,
  showAccountLocalName: bool,
  currentModuleCode: string,
  historyMode: bool,
  hasAccountDataPermission: bool,
  renderLeft: func,
  renderRight: func,
  isManager: bool,
  sendEmailFromQuotation: shape({}),
  handleEndSendingEmailFromQuotation: func,
  forceCloseActivityPopup: bool
}

function mapStateToProps(state) {
  return {
    formValues: getFormValues('crmAccount')(state),
    showAccountLocalName: getInstanceParameter(
      state,
      'crm.account_field_local_name'
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doSave: () => dispatch(submit('crmAccount')),
    accountActions: bindActions(dispatch, accountActions),
    changeFieldValue: (field, value) =>
      dispatch(change('crmAccount', field, value))
  }
}
const AccountFormForm = reduxForm({
  form: 'crmAccount',
  enableReinitialize: true
})(AccountForm)
export default connect(mapStateToProps, mapDispatchToProps)(AccountFormForm)

const ModalHistory = props => {
  const [showToastr, hideToastr] = useCrmToastr()
  const copyAnalysisToClipBoard = analysis => {
    navigator.clipboard.writeText(analysis)
    showToastr(CrmToastrType.success, 'Copy Analysis Success')
  }

  const renderAnalysis = item => {
    const analysis =
      item.attributes && item.attributes.needsAnalysis
        ? item.attributes.needsAnalysis.value
        : ''
    return (
      <div className="col-md-4 util-marginSm util-flexColumn">
        <CrmLabeledField
          label="Analysis"
          noMargin
          style={{ marginTop: 5, marginRight: 5 }}
        >
          {analysis}
        </CrmLabeledField>
        {analysis && analysis !== '' && (
          <CrmButton
            className="CrmButton--secondary CrmButton--small"
            label="Copy Analysis"
            onClick={() => {
              copyAnalysisToClipBoard(analysis)
            }}
            style={{ width: '100px' }}
          />
        )}
      </div>
    )
  }

  const renderProposition = item => {
    const proposition =
      item.attributes && item.attributes.valueProposition
        ? item.attributes.valueProposition.value
        : ''
    return (
      <CrmLabeledField
        label="Proposition"
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
        className="col-md-4 util-marginSm"
      >
        {proposition}
      </CrmLabeledField>
    )
  }

  return (
    <CrmModal
      onClose={props.onClose}
      isOpen={props.isOpen}
      title={'History of analytic ans proposition'}
    >
      <div className="util-paddingSm">
        {props.processes.map(el => (
          <div key={el.process_uid} className="row CrmMdCard util-marginSm">
            {el.owner_user && (
              <CrmLabeledField
                value={el.owner_user.full_name}
                label="User owner"
                className="col-md-4 util-marginSm"
              />
            )}
            <CrmLabeledField
              value={moment(el.started_time).format('DD/MM/YYYY')}
              label="Started"
              className="col-md-4 util-marginSm"
            />
            {renderAnalysis(el)}
            {renderProposition(el)}
          </div>
        ))}
      </div>
    </CrmModal>
  )
}
