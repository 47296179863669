import React from 'react'
import { ActivityItemContiner } from './shared.styled'
import { CrmActivityItem, CrmActivityNotation, CrmActivityCategory } from '.'
import CrmActivityContent from './activity-content-note'

const ActivityNote = (props) => {
  const { activity } = props
  return (
    <ActivityItemContiner>
      <CrmActivityNotation
        activity_type={activity.activity_type}
        direction={activity.direction}
      />
      <CrmActivityItem isSuccess={activity.activity_status?.is_success}>
        <CrmActivityCategory
          label={activity.activity_type.name}
          sequence={activity.seq_same_type_success_user}
          createdTime={activity.created_time}
          isCustomer={props.isCustomer}
        />
        <CrmActivityContent {...activity} onClick={props.onEditActivity} />
      </CrmActivityItem>
    </ActivityItemContiner>
  )
}

export default ActivityNote
