import global from './crm-data'
import {
  kpisDefinitions,
  dimensionsDefinitions,
  dashboards as localDashboards
} from './management-kpis-definitions'
import { createSelector } from 'reselect'

export const SELECT_REGION = 'SELECT_REGION'
export const SET_REGION_SELECTOR_VISIBLE = 'SET_REGION_SELECTOR_VISIBLE'
export const FETCH_DASHBOARDS = 'FETCH_DASHBOARDS'
export const FETCH_DASHBOARDS_SUCCESS = 'FETCH_DASHBOARDS_SUCCESS'

const initialState = {
  globalRegion: global,
  selectedRegion: global,
  isRegionSelectorVisible: false,
  dimensionsDefinitions,
  kpisDefinitions,
  mockData: {},
  localDashboards,
  dashboards: [],
  availableKpis: [] // Kpis from server
}

const crmDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REGION:
      return { ...state, selectedRegion: action.payload.selectedRegion }
    case SET_REGION_SELECTOR_VISIBLE:
      return {
        ...state,
        isRegionSelectorVisible: action.payload.isRegionSelectorVisible
      }
    case FETCH_DASHBOARDS_SUCCESS:
      return {
        ...state,
        dashboards: [...action.payload.dashboards]
      }
    default:
      return state
  }
}

export function setSelectedRegion(selectedRegion) {
  return {
    type: SELECT_REGION,
    payload: { selectedRegion }
  }
}

export const setRegionSelectorVisible = isRegionSelectorVisible => ({
  type: SET_REGION_SELECTOR_VISIBLE,
  payload: { isRegionSelectorVisible }
})

export const fetchDashboards = () => ({
  type: FETCH_DASHBOARDS
})

export const _getDashboards = state => state.crm.managementDashboard.dashboards
export const _getLocalDashboards = state =>
  state.crm.managementDashboard.localDashboards
export const getDashboards = createSelector(
  _getDashboards,
  _getLocalDashboards,
  (dashboards, localDashboards) => [...dashboards, ...localDashboards]
)
export const getWidgets = state =>
  state.crm.managementDashboard.dashboards
    ? state.crm.managementDashboard.dashboards.filter(d => d.type === 'widget')
    : []

export const getDataTypes = state => state.crm.managementDashboard.dataTypes

export default crmDashboardReducer
