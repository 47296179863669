import React from 'react'
import { groupDataByMonth, numberFormat } from 'crm-utils/data-utils'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import {
  createDashboardFormats,
  getWidgetAnalyticContext,
  useQueryTimeOnPhone
} from '../../dashboards/tools'
import { GQLQueryRes, HeadCount, DashboardResponse, Aggregate, GroupBy } from 'crm-types'
import moment from 'moment'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export interface widgetTimeOnPhoneHocProps {
  avgTimeOnPhoneCurrentMonthPerPerson: number | null
  avgTimeOnPhonePassedMonthData: number | any[any]
  avgTimeOnPhonePersonPassedMonth: number | null
  avgTimeOnPhoneThreeMonth: number
  footerName: string | null
  footerNamePerPerson: string | null
  isLoading?: boolean
  refetchData?: () => any
  threeMonthAgoTimeOnPhone: any[any]
  totalTimeOnPhone: number
  onClickDetail?: Function
  mainCaption?: string
}

export type useGetTimeOnPhoneByMonth = (
  numberOfMonth?: number,
  headcount?: HeadCount[]
) => DashboardResponse & GQLQueryRes

export const useGetTimeOnPhoneByMonth: useGetTimeOnPhoneByMonth = (numberOfMonth = 3, headcounts) => {
  const analyticsContext = getWidgetAnalyticContext()

  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const filter = {
    user_uid: headcounts ? undefined : userUid,
    organization_unit,
    account_is_deleted: false,
    activity: {
      activity_type: {
        medium_code: 'phone_call'
      }
    },
    call_end_time: { $ne: null },
    start_time: {
      $gte: startDate,
      $lte: endDate
    }
  }

  const { listTimeOnPhone, loading, refetch } = useQueryTimeOnPhone(filter)
  const statistics = groupDataByMonth(listTimeOnPhone, 'start_date_month', 'call_duration')
  return {
    refetch,
    loading,
    ...createDashboardFormats(statistics, numberOfMonth, moment(endDate).format('YYYY-MM'), headcounts, 'Agent')
  }
}

const widgetTimeOnPhoneHoc = (Component: any) => {
  const Wrapper = props => {
    const formatSecToMinutes = data => {
      let newObject = {}

      Object.keys(data).forEach(key => {
        newObject[key] = Math.round(data[key] / 60)
      })

      return newObject
    }

    const { headcounts } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      dataAvgPerPersonPassedMonth,
      avgPerPersonPassedMonth,
      avgPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      dataPassedMonths,
      totalLatestMonth,
      mainCaption
    } = useGetTimeOnPhoneByMonth(3, headcounts)

    const totalTimeOnPhone = `${numberFormat(Math.round(totalLatestMonth / 60))} min`
    const totalAvgPassedMonths = `${numberFormat(Math.round(avgPassedMonths / 60))} min`
    const totalAvgThisMonthPerson = `${numberFormat(Math.round(avgCurrentMonthPerPerson / 60))} min`
    const newDataPassedMonths = formatSecToMinutes(dataPassedMonths)
    const newDataPassedAvgPerPerson = dataAvgPerPersonPassedMonth
      ? formatSecToMinutes(dataAvgPerPersonPassedMonth)
      : undefined

    const onClickDetail = (monthSelected = null) => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)
      const filters = {
        activity_date: convertMonthToDateRange(endDate),
        type: { medium_code: 'phone_call' },
        call_end_time: { $ne: 'null' },
        performer_user_uid: headcounts ? undefined : userUid,
        organization_unit
      }

      if (monthSelected) {
        filters.activity_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'disposition', filters })
    }

    return (
      <Component
        avgTimeOnPhoneCurrentMonthPerPerson={totalAvgThisMonthPerson}
        avgTimeOnPhonePassedMonthData={newDataPassedAvgPerPerson}
        avgTimeOnPhonePersonPassedMonth={avgPerPersonPassedMonth}
        avgTimeOnPhoneThreeMonth={totalAvgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        onClickDetail={onClickDetail}
        refetchData={() => refetch()}
        threeMonthAgoTimeOnPhone={newDataPassedMonths}
        totalTimeOnPhone={totalTimeOnPhone}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetTimeOnPhoneHoc
