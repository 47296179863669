import React, { createContext, useContext, useState } from 'react'
import { node, shape } from 'prop-types'

export const CrmAuthContext = createContext({ crmCurrentUser: undefined })

export const CrmAuthContextProvider = ({ children }) => {
  const [crmCurrentUser, setCrmCurrentUser] = useState(undefined)
  console.log('CrmAuthContextProvider render')

  const crmAuthContext = { crmCurrentUser, setCrmCurrentUser }
  console.log('render CrmAuthContextProvider pass value ', crmAuthContext)
  return (
    <CrmAuthContext.Provider value={crmAuthContext}>
      {children}
    </CrmAuthContext.Provider>
  )
}

CrmAuthContextProvider.propTypes = {
  children: node.isRequired,
  crmCurrentUser: shape({})
}

export const useCrmAuthContext = () => useContext(CrmAuthContext)

export const withCrmUserContext = WrappedComponent => {
  return props => {
    return (
      <CrmAuthContext.Consumer>
        {value => (
          <WrappedComponent
            {...props}
            crmAuthContext={value}
            crmUser={value ? value.crmCurrentUser : undefined}
          />
        )}
      </CrmAuthContext.Consumer>
    )
  }
}
