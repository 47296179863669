import React from 'react'
import { CrmFullpageContainer } from '../../../components/index'
import { WidgetFitmentSummary } from '../widgets/fitment-summary/widget-fitment-summary'
import { WidgetCustomersWon } from '../widgets/wins/widget-customers-won'
import { WidgetCustomersWinRatioAgent } from '../widgets/wins/widget-customer-win-ratio-agent-widget'
import { WidgetNewValidLeads } from '../widgets/lead/new-valid-lead-widget'
import { WidgetInvalidValidLeads } from '../widgets/lead/invalid-lead-widget'
import { WidgetFirstCall } from '../widgets/call/first-call-widget'
import { WidgetFollowUpCall } from '../widgets/call/follow-up-call-widget'
import { WidgetAmountOfCall } from '../widgets/call/amount-of-call-widget'
import { WidgetTimeOnPhone } from '../widgets/call/time-on-phone-widget'
import { WidgetSaleToWinRatio } from '../widgets/sales/sale-to-win-ratio-widget'
import { UserInfoCard } from '../ct-dashboards/rm-dashboard/rm-summary-dashboard'
import { getWidgetAnalyticContext } from './tools'
import { ReportProvider } from 'crm-utils/reports-utils'
import withPopup from 'crm-modules/dashboard/components/_with-leads-popup-hoc'

export const DashBoardAgent = () => (
  <CrmFullpageContainer>
    <div className="util-paddingXl">
      <DashBoardAgentInner />
    </div>
  </CrmFullpageContainer>
)

export const DashBoardAgentInner = withPopup(() => {
  const analyticsContext = getWidgetAnalyticContext()
  const user = { user_uid: analyticsContext?.filters?.user?.user_uid?.value }

  return (
    <div className="row">
      <div className="col-md-2">
        <UserInfoCard user={user} />
      </div>
      <ReportProvider>
        <div className="col-md-3">
          <WidgetFitmentSummary />
        </div>
        <div className="col-md-3">
          <WidgetCustomersWon />
        </div>
        <div className="col-md-3">
          <WidgetCustomersWinRatioAgent />
        </div>
        <div className="col-md-2" />
        <div className="col-md-3">
          <WidgetNewValidLeads />
        </div>
        <div className="col-md-3">
          <WidgetInvalidValidLeads />
        </div>
        <div className="col-md-3">
          <WidgetFirstCall />
        </div>
        <div className="col-md-2" />
        <div className="col-md-3">
          <WidgetFollowUpCall />
        </div>
        <div className="col-md-3">
          <WidgetAmountOfCall />
        </div>
        <div className="col-md-3">
          <WidgetTimeOnPhone />
        </div>
        <div className="col-md-2" />
        <div className="col-md-3">
          <WidgetSaleToWinRatio />
        </div>
      </ReportProvider>
    </div>
  )
})
