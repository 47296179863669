import React, { FC, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { CrmCard, CrmButton, CrmTextInput } from '@cartrack-crm/ui'
import { useComponentConfigurationContext } from '../configuration-tools/component-configuration-context'
import {
  getOneSchemaByTypeName,
  getSelectedFieldFromLayoutInDataSource
} from '../configuration-tools/component-configuration-utils'
import { ComponentListType } from '../component-list-type'

export const LayoutFieldCard = styled(CrmCard)`
  margin: var(--crm-padding-04) 0;
  .card-title {
    margin-right: var(--crm-padding-12);
  }

  box-shadow: unset;
  position: relative;
  padding: var(--crm-padding-04) var(--crm-padding-02);

  .header {
    cursor: pointer;
    padding: var(--crm-padding-08);
    display: flex;
    flex-direction: row;
    border-radius: var(--crm-padding-04);

    strong,
    i {
      font-size: 0.67em;
    }
  }
  cursor: pointer;
  border-radius: var(--crm-padding-08);
  &:hover {
    .header {
      background-color: var(--crm-color-gray10);
    }
  }

  ${({ editMode }) =>
    editMode &&
    ` 
      box-shadow: 0 2px 5px rgba(0,5,10,0.10);
      margin: 10px;
      padding: 10px;
    `}
`
export const LayoutExpandBtn = styled.i`
  position: absolute;
  right: var(--crm-padding-20);
  margin: 0 var(--crm-padding-04);
  cursor: pointer;

  &:hover {
    color: #f0f0f0;
  }
`
interface ILayoutField {
  index?: number
  onSave?: Function
  onDelete?: Function
  isDebug?: boolean
  name?: string
  from?: string
  field?: any
  isEdited?: boolean
  layoutItem?: any // this is parent for rows - we need to use this id
}

export const LayoutField: FC<ILayoutField> = (props) => {
  const [editMode, setEditMode] = useState(props.isEdited)
  const componentConfigurationContext = useComponentConfigurationContext()
  const [tmpField, setTmpField] = useState<any>()
  useEffect(() => {
    setTmpField({ ...props.field })
  }, [props])
  const toggleEditMode = () => setEditMode(!editMode)
  const getLayoutItemObject = (fieldName: string) => {
    const res = {
      data: { data_source: null, field: null },
      name: null,
      id: tmpField?.id
    }
    const nestedField = fieldName.split('.')
    const dsName = nestedField.shift()
    const stringField = [...nestedField]
      .pop()
      ?.replace(/_/g, ' ')
      .trim()
    const dsID = componentConfigurationContext.component.content?.data?.dataSources?.find((ds) => ds.name === dsName)
      ?.id
    const dsField = nestedField.join('.')
    res.data.data_source = dsID
    res.data.field = dsField
    res.name = tmpField.name ?? stringField
    if (!tmpField?.id) {
      res.name = stringField
    }
    return res
  }
  const onFieldSelected = (fieldName, field, p3) => {
    const newTmpField = getLayoutItemObject(fieldName)
    setTmpField(newTmpField)
    if (!newTmpField?.id) props.onSave(newTmpField)
  }

  const handleSaveField = () => {
    try {
      props.onSave(tmpField)
      setEditMode(false)
    } catch (err) {}
  }

  const handleCancel = () => {
    try {
      props.onSave({ ...props.field })
      setEditMode(false)
    } catch (err) {
      console.log('handleCancel', err)
    }
  }

  const handleDelete = () => {
    if (props.onDelete) props.onDelete(tmpField)
    setEditMode(false)
  }

  const buildTreeOptions = () => {
    const listForTree = []
    console.log(
      'buildTreeOptions',
      componentConfigurationContext?.component?.content,
      componentConfigurationContext.schema
    )
    componentConfigurationContext?.component?.content?.data?.dataSources?.map((ds) => {
      const schema = getOneSchemaByTypeName(componentConfigurationContext.schema, ds.data_type)
      console.log('type schema schema', schema)
      const layoutItems = componentConfigurationContext.component?.content?.layout?.items ?? []
      const selected = getSelectedFieldFromLayoutInDataSource(ds.id, layoutItems) ?? []
      const finalField = schema?.fields?.map((field) => ({
        ...field,
        disabled: selected.includes(field.name)
      }))
      schema.fields = finalField
      listForTree.push(schema)
    })
    return listForTree
  }
  const treeFieldOptions = buildTreeOptions()
  console.log('Layout field treeFieldOptions', treeFieldOptions)
  const title = tmpField?.data?.data_source ? `${tmpField?.data?.field}` : 'No select field now'
  return (
    <LayoutFieldCard style={{ padding: '10px' }} editMode={editMode}>
      <div className="header" onClick={toggleEditMode}>
        <strong title={title}>{tmpField?.name !== '' ? tmpField?.name : tmpField?.data?.field} </strong>
        {!tmpField?.data && <small>No data</small>}
        <LayoutExpandBtn>
          <FontAwesomeIcon icon={editMode ? faCaretUp : faCaretDown} />
        </LayoutExpandBtn>
      </div>
      {editMode && (
        <div className="row">
          <div className="col-md-12">
            {tmpField?.data && tmpField?.data?.data_source && tmpField?.data?.field && tmpField?.id && (
              <CrmTextInput
                input={{
                  value: tmpField.name ? tmpField.name : undefined,
                  onChange: (event) => {
                    setTmpField({
                      ...tmpField,
                      name: event.currentTarget.value
                    })
                  }
                }}
                placeholder="Name"
              />
            )}
          </div>
          <div className="col-md-12" style={{ maxHeight: '400px', paddingBottom: '15px' }}>
            <ComponentListType schema={treeFieldOptions} onClickField={onFieldSelected} />
          </div>
          <div className="row">
            {tmpField?.id && editMode && (
              <React.Fragment>
                <div className="col-md-5">
                  <CrmButton label="cancel" grouped small type="secondary" onClick={handleCancel} />
                </div>
                <div className="col-md-5">
                  <CrmButton label="Save" grouped small type="primary" onClick={handleSaveField} />
                </div>
                <div className="col-md-2">
                  <LayoutExpandBtn onClick={handleDelete}>
                    <FontAwesomeIcon icon={faTrash} />
                  </LayoutExpandBtn>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </LayoutFieldCard>
  )
}
