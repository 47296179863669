import React, { useEffect, useState } from 'react'
import { useCrmDynamicTableContext, DynamicTableContextType } from '../dynamic-table-context'
import { CrmButton, CrmJsonPreview, CrmLoadingIcon } from '@cartrack-crm/ui'
import { CrmToastrType, useCrmToastr } from '@cartrack-crm/ui/src/toastrs'
import { buildGQLQuery } from '../data/dynamic-ql-query-utils'
import { CrmHeatmapChart } from '@cartrack-crm/crm-analytics'
import { withApollo } from 'react-apollo'
import { CrmQLQueryBuilder, CrmQLQueryBuilderParams } from '@cartrack-crm/crm-analytics'
import { QueryRootLevel } from '@cartrack-crm/crm-analytics/src/data-utils/crm-ql-query-builder'
import gql from 'graphql-tag'
import { useAnalyticsContext } from '../../context/context'
import { useCrmContext } from '@cartrack-crm/crm-core'
import { DataSourceAggregateType } from '../../types/component-type'
import { useComponentConfigurationContext } from '../../configuration-tools/component-configuration-context'

const DynamicTableViewPivotInner = (props) => {
  // const dynamicTableContext: DynamicTableContextType = useCrmDynamicTableContext()
  const analyticsContext: any = useAnalyticsContext()
  const componentConfigurationContext = useComponentConfigurationContext()
  const crmContext = useCrmContext()

  const [showToastr, hideToastr] = useCrmToastr()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState()

  const component = componentConfigurationContext.component
  console.log('component', component)
  const layoutItem =
    component?.content?.layout?.items && component?.content?.layout?.items.length == 1
      ? component?.content?.layout?.items[0]
      : undefined

  let pivotDefinition = layoutItem

  console.log('DynamicTableViewPivot', props, data, pivotDefinition)
  if (!pivotDefinition && props.item) {
    pivotDefinition = props.item
  }
  console.log('pivotDefinition', pivotDefinition)
  const pivotRowField =
    pivotDefinition?.props?.rows && pivotDefinition?.props?.rows.length > 0
      ? pivotDefinition?.props?.rows[0]
      : undefined
  const pivotColumnField =
    pivotDefinition?.props?.columns && pivotDefinition?.props?.columns.length > 0
      ? pivotDefinition?.props?.columns[0]
      : undefined
  const pivotValueField =
    pivotDefinition?.props?.values && pivotDefinition?.props?.values.length > 0
      ? pivotDefinition?.props?.values[0]
      : undefined
  console.log('pivotRow', pivotRowField)
  const validate = () => {
    if (!pivotValueField) {
      showToastr(CrmToastrType.error, ' pivot Cell is not selected')
      return false
    }
    if (!pivotColumnField) {
      showToastr(CrmToastrType.error, ' pivot Column is not selected')
      return false
    }
    if (!pivotRowField) {
      showToastr(CrmToastrType.error, ' pivot Row is not selected')
      return false
    }
    return true
  }

  const fetchData = async () => {
    // map pivot def to columns
    if (!validate()) {
      return
    }

    var queryBuilder = new CrmQLQueryBuilder()
    // we have data in pivotDevinition

    const fields: any = [pivotRowField.data.field, pivotColumnField.data.field, pivotValueField.data.field]

    const group_by = [pivotRowField.data.field, pivotColumnField.data.field]
    const aggregates = [{ id: pivotValueField.data.field, type: 'sum' }]
    // const fields: any = [
    //   {
    //     name: pivotRowField.data.field,
    //     aggregate_type: DataSourceAggregateType.GROUP_BY
    //   },
    //   {
    //     name: pivotRowField.data.field,
    //     aggregate_type: DataSourceAggregateType.GROUP_BY
    //   },
    //   {
    //     name: pivotRowField.data.field,
    //     aggregate_type: DataSourceAggregateType.GROUP_BY
    //   }
    // ]
    console.log('fields', fields, props)

    // find original lead sources
    const dataSourceCode = pivotDefinition.props.data.dataSourceCode
    console.log('dataSourceCode', dataSourceCode)
    const componentDefinition = component.content
    console.log('componentDefinition', componentDefinition)
    const dataSource = componentDefinition.data.dataSources.find((ds) => ds.id === dataSourceCode)
    console.log('orignal ds', dataSource)
    if (!dataSource) {
      console.error('Orinal DS missing')
      return
    }

    var queryDefinition: CrmQLQueryBuilderParams = {
      root: dataSource.data_type,
      root_type: dataSource.data_type,
      root_level: QueryRootLevel.INSTANCE,
      grouped: true,
      aggregates,
      group_by,
      fields
    }
    console.log('queryDefinition', queryDefinition)
    var queryBuilder = new CrmQLQueryBuilder()
    const queryBuilderResult = queryBuilder.buildQlQuery(queryDefinition, {
      filter: { ...dataSource.filters, ...analyticsContext.filters },
      master_uid: crmContext.master.master_uid,
      instance_uid: crmContext.instance.instance_uid
    })
    console.log('queryBuilderResult result', queryBuilderResult)

    setIsLoading(true)
    const result = await props.client.query({
      query: gql(queryBuilderResult.gqlString),
      variables: queryBuilderResult.variables,
      fetchPolicy: 'no-cache'
    })
    setIsLoading(false)

    console.log('result', result)
    var data = result.data
    if (data.master) {
      data = data.master
    }
    if (data.instance) {
      data = data.instance
    }
    return data[queryBuilderResult.queryRoot]
  }
  const handleRefreshPivotData = async () => {
    // build query from pivot def
    console.log('handleRefreshPivotData')
    var result = await fetchData()
    console.log('fetch Data', result)
    var data = result.edges ? result.edges.map((row) => row.edge) : result
    const pageInfo = result.pageInfo

    setData(data)
  }

  useEffect(() => {
    console.log('View Pivot init')
  })

  const xAxisField = pivotColumnField?.data?.field
  const yAxisField = pivotRowField?.data?.field
  const valueField = pivotValueField?.data?.field
  console.log('xAxisField', xAxisField)
  console.log('yAxisField', yAxisField)
  console.log('valueField', valueField)
  console.log('data', data)
  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-3">
          {isLoading && (
            <div className="CrmWidget--loading">
              <CrmLoadingIcon />
              <div>Loading...</div>
            </div>
          )}
        </div>
        <div className="col-md-3">
          <CrmButton iconButton icon="refresh" grouped onClick={handleRefreshPivotData} />
        </div>
      </div>

      {!xAxisField && <div>Select Row field</div>}
      {!yAxisField && <div>Select Column field</div>}
      {data && xAxisField && yAxisField && (
        <div style={{ width: '100%', height: '100%' }}>
          <CrmHeatmapChart
            xAxisField={xAxisField}
            yAxisField={yAxisField}
            valueField={valueField}
            data={data as any}
            // onCellClicked={this.handleCellClicked}
          />
        </div>
      )}
      {/* <CrmJsonPreview json={pivotDefinition} /> */}
    </div>
  )
}
export const DynamicTableViewPivot = withApollo(DynamicTableViewPivotInner)
export default DynamicTableViewPivot
