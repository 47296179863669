import dictionaryAPI from 'crm-api/settings/dictionaries-api'
import {
  FETCH_DICTIONARIES,
  RECEIVE_DICTIONARIES,
  SAVE_EDITING_DICTIONARY,
  CANCEL_EDITING_DICTIONARY,
  CANCEL_CREATING_DICTIONARY,
  SAVE_CREATING_DICTIONARY
} from 'crm-duxs/settings/dictionaries-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchDictionaries() {
  const dictionaries = yield call(dictionaryAPI.fetch)
  yield put({ type: RECEIVE_DICTIONARIES, payload: { dictionaries } })
}

function* createDictionary(action) {
  try {
    yield call(dictionaryAPI.create, action.formValues)
    yield put({ type: CANCEL_CREATING_DICTIONARY })
    yield put({ type: FETCH_DICTIONARIES })
  } catch (err) {}
}

function* updateDictionary(action) {
  try {
    yield call(dictionaryAPI.update, action.formValues)
    yield put({ type: CANCEL_EDITING_DICTIONARY })
    yield put({ type: FETCH_DICTIONARIES })
  } catch (err) {}
}

function* settingDictionarySaga() {
  yield takeLatest(FETCH_DICTIONARIES, fetchDictionaries)
  yield takeLatest(SAVE_CREATING_DICTIONARY, createDictionary)
  yield takeLatest(SAVE_EDITING_DICTIONARY, updateDictionary)
}

export default settingDictionarySaga
