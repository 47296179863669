import { delay } from 'redux-saga'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { AGENT_STATUS_CHANGED } from 'crm-duxs/user-session'
import { qGetNextTask } from 'crm-data/tasks'
import { callQLApi } from 'crm-api/api-utils'
import accountsApi from 'crm-api/accounts-api'
import {
  DIALER_NEXT_TASK_RECEIVED,
  DIALER_TASK_STATUS_CHANGED,
  SET_NEXT_TASK_COUNTDOWN,
  getCurrentTask
} from 'crm-duxs/dialer-reducer'
import { ACTIVITY_COMPLETED } from 'crm-duxs/activities-reducer'

function* setNextTask(task) {
  console.log('setNextTask SAGA')
  yield put({
    type: DIALER_NEXT_TASK_RECEIVED,
    payload: {
      task,
      taskStatus: {
        startedAt: new Date(),
        statusStartedAt: new Date(),
        taskStatusCode: 'precall',
        name: 'Before Call'
      }
    }
  })
}

function* doCountdown(val) {
  console.log('doCountdown saga', val)
  if (val === 0) {
    yield call(gotoNextTask)
  } else {
    yield put({
      type: SET_NEXT_TASK_COUNTDOWN,
      payload: {
        nextTaskCountdown: val
      }
    })
    yield call(delay, 1000)
    yield call(doCountdown, val - 1)
  }
}

function* startNextTaskCountdown() {
  console.log('startNextTaskCountdown')
  yield call(doCountdown, 20)
}
function* changeCurrentTaskStatus(params) {
  console.log('changeCurrentTaskStatus SAGA')
  yield put({
    type: DIALER_TASK_STATUS_CHANGED,
    payload: {
      taskStatus: {
        ...params,
        statusStartedAt: new Date()
      }
    }
  })
}
function* gotoNextTask() {
  console.log('gotoNextTask SAGA')

  const res = yield call(callQLApi, qGetNextTask)
  console.log('Got next task res', res)
  if (res && res.task_get_next) {
    yield call(setNextTask, res.task_get_next.task)
  }
}

function* onAgentStatusChanged(action) {
  console.log('dialer.onAgentStatusChanged SAGA', action)
  // eslint-disable-next-line
  const currentTask = undefined // TODO - get task
  if (
    action.payload.userSession &&
    action.payload.userSession.sessionStatusCode === 'auto_dialer'
  ) {
    if (!currentTask) {
      yield call(gotoNextTask)
    }
  }
}

function* onActivityCompleted(action) {
  console.log('dialerSaga on Activity Completed', action)
  const currentTask = yield select(getCurrentTask)
  console.log('currentTask', currentTask)

  if (!currentTask) {
    if (action.payload.activity) {
      const activity = action.payload.activity
      if (
          activity &&
          activity.activity_type &&
          activity.activity_type.code === 'live_call'
      ) {
        let account = yield call(
            accountsApi.getAccountLight,
            activity.account_uid
        )
        if (account.kind && account.kind === 'invalid') {
          window.location.reload();
        }
      }
    }
  }

  const activity = action.payload.activity
  if (
    currentTask &&
    activity &&
    activity.activity_type &&
    activity.activity_type.medium_code === 'phone_call' &&
    currentTask.account_uid === activity.account_uid
  ) {
    console.log('Task completed')
    yield call(changeCurrentTaskStatus, {
      taskStatusCode: 'postcall',
      name: 'After Call'
    })
    yield call(startNextTaskCountdown)
  }
}

function* dialerSaga() {
  yield takeLatest(AGENT_STATUS_CHANGED, onAgentStatusChanged)
  yield takeLatest(ACTIVITY_COMPLETED, onActivityCompleted)
}

export default dialerSaga
