import gql from 'graphql-tag'

export const buildQlQuery = (dataSource, filter, additionalFilters = {}) => {
  console.log('buildQLQuery', dataSource, filter)
  if (!dataSource.qlQuery) {
    throw new Error('No qlQuery in datasource')
  }

  // TODO - smarter merge
  var variables = { filter: { ...dataSource.filter, ...additionalFilters } }
  if (dataSource.qlVariables) {
    variables = { ...variables, ...dataSource.qlVariables }
  }

  if (filter) {
    // TODO - we may smarter merge here
    variables.filter = { ...variables.filter, ...filter }
  }
  const res = {
    query: gql(dataSource.qlQuery),
    qlQueryString: dataSource.qlQuery,
    variables
  }

  return res
}
