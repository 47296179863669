import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { Field as FieldFormik } from 'formik'
import { CrmTextInput, CrmList } from '../../../../../components/index'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import AccountListItem from '../../../../../modules/accounts/components/account-list-item.jsx'
import { useDebounce } from 'util-components'

interface IProps {
  item: any
  onHandleCheckByUid: Function
  intl: any
  index: any
  kind: any
  duplicate: any
}

const ContactDetailItem: React.SFC<IProps> = ({
  item,
  onHandleCheckByUid,
  intl,
  index,
  kind,
  duplicate
}) => {
  const [showPhoneDuplicate, setShowPhoneDuplicate] = useState(false)
  const [showEmailDuplicate, setShowEmailDuplicate] = useState(false)
  const [showMobileDuplicate, setShowMobileDuplicate] = useState(false)
  const [searchText, setSearchText] = useState({ id: '', value: '' })
  const debouncedSearchInput = useDebounce(searchText, 1000)

  useEffect(() => {
    if (debouncedSearchInput) {
      if (searchText) {
        checkDuplicateContact(
          searchText.id,
          searchText.value,
          item.contact_details_uid
        )
      }
    }
  }, [debouncedSearchInput])

  useEffect(() => {
    // check only when restore from local, not from key press
    if (!item.isFromKeyPress) {
      checkDuplicateContact(
        kind.toLowerCase(),
        item.value,
        item.contact_details_uid
      )
    }
    return () => {}
  }, [item.value])

  const checkDuplicateContact = async (field, value, contact_details_uid) => {
    if (onHandleCheckByUid) {
      const accountUid = 'new'
      await onHandleCheckByUid(field, value, contact_details_uid, accountUid)
    }
  }

  const handleShowDuplicate = (field) => {
    if (field === 'phone') {
      setShowPhoneDuplicate(!showPhoneDuplicate)
    } else if (field === 'email') {
      setShowEmailDuplicate(!showEmailDuplicate)
    } else if (field === 'mobile') {
      setShowMobileDuplicate(!showMobileDuplicate)
    }
  }

  const getTextShowMoreForField = (field, show) => {
    if (field === 'name') {
      return show ? '<< show less' : 'show more >>'
    }

    return show ? 'Hide' : 'Show'
  }

  const handleRenderDuplicateStatus = (field) => {
    if (!duplicate || !duplicate.hasOwnProperty(item.contact_details_uid)) {
      return
    }

    const duplicateData = duplicate[item.contact_details_uid]
    if (duplicateData.loading) {
      return <span>Checking duplicates ...</span>
    }
    if (duplicateData.duplicate === false && duplicateData.search) {
      return <span className="util-textGreen">No duplicates found</span>
    }

    if (!duplicateData.duplicate) {
      return ''
    }

    const showMoreDuplicate = {
      phone: showPhoneDuplicate,
      email: showEmailDuplicate,
      mobile: showMobileDuplicate
    }

    const showText = getTextShowMoreForField(field, showMoreDuplicate[field])

    return (
      <span>
        <strong className="util-textRed">
          {duplicateData.duplicateData.length} duplicates
        </strong>
        &nbsp; | &nbsp;{' '}
        <strong
          onClick={() => handleShowDuplicate(field)}
          style={{ cursor: 'pointer' }}
        >
          {showText}
        </strong>
      </span>
    )
  }

  const hasDuplicateData = (duplicate, item) => {
    const duplicateData = duplicate[item.contact_details_uid]
    if (
      duplicateData &&
      duplicateData.duplicate &&
      Array.isArray(duplicateData.duplicateData) &&
      duplicateData.duplicateData.length > 0
    ) {
      return true
    } else {
      return false
    }
  }

  const handleRenderDuplicateAccount = (field) => {
    const duplicateData = duplicate[item.contact_details_uid]
    if (hasDuplicateData(duplicate, item)) {
      const size = field === 'name' ? 'normal' : 'small'
      return (
        <div className="row">
          <div className="col-md-10">
            <CrmList>
              {duplicateData.duplicateData.map((d) => (
                <AccountListItem
                  key={d.account_uid}
                  account={d}
                  target="_blank"
                  size={size}
                />
              ))}
            </CrmList>
          </div>
        </div>
      )
    }
  }

  const stateShowDuplicate = {
    phone: showPhoneDuplicate,
    email: showEmailDuplicate,
    mobile: showMobileDuplicate
  }

  return (
    <React.Fragment>
      <FieldFormik
        render={({ field, form }) => (
          <CrmTextInput
            id={kind.toLowerCase()}
            value={item.value}
            placeholder={kind}
            onChange={(e) => {
              form.setFieldValue(
                `contact_details.${index}.value`,
                e.currentTarget.value
              )
              form.setFieldValue(
                `contact_details.${index}.isFromKeyPress`,
                true
              )
              setSearchText({
                id: e.currentTarget.id,
                value: e.currentTarget.value
              })
            }}
          />
        )}
      />
      <div className="AddAccountForm-DuplicateAccountWrapper">
        <div className="duplicate-checking-status-for-detail">
          {handleRenderDuplicateStatus(kind.toLowerCase())}
        </div>
        <ReactCSSTransitionGroup
          transitionName="duplicate-account"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          <div className="AddAccountForm-DuplicateContactDetailList">
            {stateShowDuplicate[kind.toLowerCase()] &&
              handleRenderDuplicateAccount(kind.toLowerCase())}
          </div>
        </ReactCSSTransitionGroup>
      </div>
    </React.Fragment>
  )
}

export default injectIntl(ContactDetailItem)
