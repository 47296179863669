export const FETCH_REPORTS = 'FETCH_REPORTS'
export const RECEIVE_REPORTS = 'RECEIVE_REPORTS'
export const GENERATE_REPORT = 'GENERATE_REPORT'
export const RECEIVE_GENERATE_REPORT = 'RECEIVE_GENERATE_REPORT'

// Initial
const initialState = {
  reports: [],
  reportResults: [],
  fetch: false,
  generating: false,
  params: {}
}

export function fetchReports() {
  return {
    type: FETCH_REPORTS
  }
}

export function generateReport(values) {
  return {
    type: GENERATE_REPORT,
    params: values
  }
}

function reportReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTS:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_REPORTS:
      return {
        ...state,
        fetch: false,
        reports: action.payload.reports
      }
    case GENERATE_REPORT:
      return {
        ...state,
        generating: true
      }
    case RECEIVE_GENERATE_REPORT:
      return {
        ...state,
        generating: false,
        reportResults: action.payload.reportData.result
      }
    default:
      return state
  }
}

// Selectors
export const getIsGenerating = state => state.crm.report.generating
export const getIsFetching = state => state.crm.report.fetch
export const getReports = state => state.crm.report.reports
export const getReportResults = state => state.crm.report.reportResults

export default reportReducer
