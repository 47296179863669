import resourceAPI from 'crm-api/admin/resources-api'
import {
  FETCH_IMPORT_FILES,
  RECEIVE_IMPORT_FILES,
  SAVE_CREATING_IMPORT_FILE,
  DELETE_IMPORT_FILE_VALUE
} from 'crm-duxs/admin/import-tool-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchImportToolFiles(action) {
  console.log('fetchImportToolFiles')
  console.log(action)

  const importFiles = yield call(resourceAPI.fetch, action.payload)
  yield put({
    type: RECEIVE_IMPORT_FILES,
    payload: {
      importFiles
    }
  })
}

function* createImportToolFile(action) {
  try {
    yield call(resourceAPI.create, action.formValues)
    yield put({
      type: FETCH_IMPORT_FILES,
      payload: {
        tag: 'import'
      }
    })
  } catch (err) {}
}

function* deleteImportToolFile(action) {
  try {
    yield call(resourceAPI.delete, action)
    yield put({
      type: FETCH_IMPORT_FILES,
      payload: {
        tag: 'import'
      }
    })
  } catch (err) {}
}

function* settingImportToolSaga() {
  yield takeLatest(FETCH_IMPORT_FILES, fetchImportToolFiles)
  yield takeLatest(SAVE_CREATING_IMPORT_FILE, createImportToolFile)
  yield takeLatest(DELETE_IMPORT_FILE_VALUE, deleteImportToolFile)
}

export default settingImportToolSaga
