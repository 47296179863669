import React, { useState } from 'react'
import { withApollo } from 'react-apollo'
import { shape, func } from 'prop-types'
import withLeadsPopupHoc from '../../../components/_with-leads-popup-hoc.jsx'
// import { CrmButton, FlatTable } from 'crm-components/index.js'
import { CrmButton } from '@cartrack-crm/ui'
import FunnelItem from './funnel-item.jsx'
import { qlAnalyticsWidgetQuery } from 'crm-data/analytics'
import dashFunnelWidget from './dash-funnel-query'
import ApolloClient from 'apollo-client'

const generateMock = () => {
  const i = (Math.random() * 100) % 100
  return {
    name: 'RM ' + i,
    statuses: {
      new: (Math.random() * 100) % 100,
      validated: (Math.random() * 100) % 100,
      needs_analysis: (Math.random() * 100) % 100,
      opportunity: (Math.random() * 100) % 100,
      quoted: (Math.random() * 100) % 100,
      closing: (Math.random() * 100) % 100,
      won: (Math.random() * 100) % 100
    }
  }
}

const mockData = [
  generateMock(),
  generateMock(),
  generateMock(),
  generateMock(),
  generateMock(),
  generateMock()
]

interface CtDashFunnel19 {
  client: ApolloClient<any>
  analyticsContext: object
  onShowLeadsPopup: Function
}
const CtDashFunnel19: React.SFC<CtDashFunnel19> = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [funnelData, setFunnelData] = useState(undefined)

  const handleRefresh = async () => {
    // if (!this.props.analyticsContext.filters.activity_date) {
    //   toastr.warning('Select date range in Activity Date filter')
    //   return
    // }
    // // this.props.onRefresh()
    console.log('Refresh')
    try {
      const variables = {
        widgetDefinition: dashFunnelWidget,
        filter: {}
      }
      setIsLoading(true)
      const res: any = await props.client.query({
        query: qlAnalyticsWidgetQuery,
        variables,
        fetchPolicy: 'no-cache'
      })
      console.log('Data', res)
      if (
        res &&
        res.data &&
        res.data.dataSourceQuery &&
        res.data.dataSourceQuery.data.dataSources
      ) {
        const data =
          res.data.dataSourceQuery.data.dataSources.LeadsCurrentStatus.rawData
            .data
        console.log('data', data)
        if (data) {
          console.log('before reduce', data)
          // group to users
          let funnelDataByUsers = data.reduce((a: any, i: any) => {
            const r = { ...a }
            if (!r[i.owner_user_uid]) {
              r[i.owner_user_uid] = {
                statuses: {},
                userUid: i.owner_user_uid,
                name: i.owner__full_name,
                department: i.owner__department__name,
                total: 0
              }
            }
            console.log('User: ', i, r)

            if (!r[i.owner_user_uid].statuses[i.detailed_status_code]) {
              r[i.owner_user_uid].statuses[i.detailed_status_code] = 0
            }
            r[i.owner_user_uid].statuses[i.detailed_status_code] += Number(
              i.account_uid
            )

            // if (
            //   !r[i.owner_user_uid].statuses[i.sales_result_code][
            //     i.detailed_status_code
            //   ]
            // ) {
            //   r[i.owner_user_uid].statuses[i.sales_result_code][
            //     i.detailed_status_code
            //   ] = 0
            // }
            // r[i.owner_user_uid].statuses[i.sales_result_code][
            //   i.detailed_status_code
            // ] += Number(i.account_uid)
            r[i.owner_user_uid].total += Number(i.account_uid)

            return r
          }, {})

          console.log('FunnelData By Users', funnelDataByUsers)

          console.log('data', data)

          funnelDataByUsers = Object.values(funnelDataByUsers).filter(
            (r: any) => r.total > 0
          )
          console.log('funnelDataByUsers', funnelDataByUsers)
          setFunnelData(funnelDataByUsers)
        }
        setIsLoading(false)
      }
    } catch (err) {
      console.log('Error fetching data')
      console.error(err)
    }
  }
  const handleStatusClick = params => {
    console.log('handleStatusClick', params)
    const detailsParams = {
      type: 'account',
      filters: {
        owner_user_uid: params.userUid,
        detailed_status_code: params.statusCode
      }
    }
    props.onShowLeadsPopup(detailsParams)
  }

  console.log('CT Funnel', props, funnelData)
  const columns = [
    { accessor: 'owner_user_uid', Header: 'user' },
    { accessor: 'name', Header: 'Name' },
    { accessor: 'department__name', Header: 'department__name' },
    { accessor: 'new', Header: 'New', type: 'number' },
    { accessor: 'validated', Header: 'validated', type: 'number' },
    { accessor: 'needs_analysis', Header: 'needs_analysis', type: 'number' },
    { accessor: 'opportunity', Header: 'opportunity', type: 'number' },
    { accessor: 'quoted', Header: 'quoted', type: 'number' },
    { accessor: 'closing', Header: 'closing', type: 'number' }
  ]
  return (
    <div>
      <div
        style={{
          minWidth: '100px',
          width: '15%'
        }}
        className="CrmWidget-toolboxWrapper"
      >
        <CrmButton
          onClick={handleRefresh}
          label="Refresh"
          isSaving={isLoading}
          type="primary"
        />
      </div>

      <div className="CrmWidget CrmWidget--fullHeight Widget CrmWidget--vMargin ">
        <div className="CrmWidget-header util-flexRow util-noMargin between-xs top-xs">
          <div className="util-flexColumn util-flexGrow">
            <div className="Widget-title CrmWidget-title util-flexRow">
              Dashboard Funnel 2019
            </div>
            {/* <WidgetFiltersBar
                analyticsContext={this.props.analyticsContext}
              /> */}
          </div>
        </div>
        <div className="Widget-content CrmWidget-content CrmWidget-content--padding">
          <div style={{ width: '100%', overflowY: 'auto' }}>
            <div className="row">
              {funnelData &&
                funnelData.map(mock => (
                  <div
                    key={mock.owner_user_uid}
                    className="col-md-2 util-marginTop"
                  >
                    <FunnelItem data={mock} onStatusClick={handleStatusClick} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="CrmWidget CrmWidget--fullHeight Widget CrmWidget--vMargin ">
        <div className="CrmWidget-header util-flexRow util-noMargin between-xs top-xs">
          <div className="Widget-content CrmWidget-content CrmWidget-content--padding">
            {/* {funnelData && (
              <FlatTable columns={columns} data={funnelData} generateSummary />
            )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withLeadsPopupHoc(withApollo(CtDashFunnel19))
