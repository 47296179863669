import React, { CSSProperties } from 'react'
import { CrmTextInput, CrmLoadingIcon } from '@cartrack-crm/ui'
import { DashboardFiltersOptionsList, DashboardFilterOptionsItem } from '../styled/filter-option-style'

interface IFiltersOptionList {
  options: any[]
  selectedValue: any
  onFilterValueChanged: Function
  isLoading?: boolean
  enableSearch?: boolean
  toolboxRenderer?: Function
  showInactive?: boolean
  style?: CSSProperties
}

interface IFiltersOptionListState {
  options: any[]
  searchValue: string
  startSearch: boolean
}

export class FiltersOptionList extends React.PureComponent<IFiltersOptionList, IFiltersOptionListState> {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
      options: props.options ? props.options : [],
      startSearch: false
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.options !== nextProps.options) {
      this.setState({
        options: nextProps.options ? nextProps.options : [],
        startSearch: false,
        searchValue: ''
      })
    }
  }

  handleOptionClicked = event => {
    const { options, selectedValue } = this.props

    if (event.currentTarget.id) {
      const filterValue: any = {
        value: event.currentTarget.id
      }

      if ((selectedValue && selectedValue.includes(event.currentTarget.id)) || selectedValue === filterValue.value) {
        filterValue.value = undefined
      } else {
        const item = options.find(c => c.value === event.currentTarget.id)
        if (item) {
          filterValue.label = item.name
          filterValue.item = item
        }
      }

      this.props.onFilterValueChanged(filterValue && filterValue.value ? filterValue : undefined)
      this.handleSearchValueChange(event)
      this.setState({
        startSearch: false
      })
    }
  }

  handleSearchValueChange = event => {
    const searchValue = event ? event.currentTarget.value : ''
    const options = [...this.props.options]

    this.setState({
      searchValue,
      options: !searchValue ? options : options.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()))
    })
  }

  handleSearchValueBlur = () => {
    if (!this.state.searchValue) {
      this.setState({
        startSearch: false
      })
    }
  }

  handleSearchLabelClick = () => {
    this.setState({
      startSearch: true
    })
  }

  handleClearFilter = () => {
    const filterValue = {
      value: undefined
    }

    this.props.onFilterValueChanged(filterValue)
    this.handleSearchValueChange(event)
    this.setState({
      startSearch: false
    })
  }

  render() {
    const { selectedValue, isLoading, enableSearch } = this.props
    const { options, searchValue, startSearch } = this.state

    return (
      <React.Fragment>
        <div className="util-fullWidth">
          {enableSearch && options && (
            <div className="FilterSearchWrapper">
              {!startSearch && (
                <div className="SearchLabel" onClick={this.handleSearchLabelClick}>
                  <CrmTextInput
                    iconName="search"
                    input={{
                      onChange: null,
                      value: ''
                    }}
                  />
                </div>
              )}
              {startSearch && (
                <div className="SearchInput">
                  <CrmTextInput
                    iconName="search"
                    focus
                    input={{
                      onChange: this.handleSearchValueChange,
                      value: searchValue,
                      onBlur: this.handleSearchValueBlur
                    }}
                  />
                </div>
              )}
              <div
                title="Clear filter"
                className="ClearFilterLabel"
                style={{ opacity: startSearch ? '0' : '1.0' }}
                onClick={this.handleClearFilter}
              >
                All
              </div>
              {this.props.toolboxRenderer && this.props.toolboxRenderer !== undefined && this.props.toolboxRenderer()}
            </div>
          )}
        </div>
        <DashboardFiltersOptionsList>
          {isLoading && <CrmLoadingIcon />}
          {options &&
            options.map(c => {
              const isInactive = c.is_deleted === true || c.is_active === false || (c.item && c.item.isDeleted === true)
              if (!this.props.showInactive && isInactive) {
                return
              }
              return (
                <DashboardFilterOptionsItem
                  key={c.value}
                  id={c.value}
                  isSelected={
                    (selectedValue && selectedValue.includes && selectedValue.includes(c.value)) ||
                    selectedValue === c.value
                  }
                  isInActive={isInactive}
                  onClick={this.handleOptionClicked}
                >
                  {c.name}
                </DashboardFilterOptionsItem>
              )
            })}
        </DashboardFiltersOptionsList>
      </React.Fragment>
    )
  }
}

export default FiltersOptionList
