/* eslint-disable camelcase */
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { func, shape, bool } from 'prop-types'
import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import { injectIntl, FormattedMessage } from 'react-intl'
import MeetingFormInner from 'crm-modules/meeting/components/meeting-form-inner.jsx'
import activityPageQlHoc from 'crm-modules/activities/component/activity-form-ql-hoc'
import { LoadingIcon } from 'util-components'
import {
  getActivity,
  getIsSaving,
  getActivityType
} from 'crm-duxs/activity-reducer'

class AfterMeetingForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity ? this.props.activity : undefined,
      errorMessages: []
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState({ ...this.state, ...newState }, () => {
        resolve()
      })
    })

  handleFormSubmit = async activity => {
    console.log('AfterMeetingForm handleFormSubmit', activity)
    this.setState({ errorMessages: [] })
    try {
      const res = await this.props.onSubmit(activity)
      console.log('Saved', res)
      toastr.success('Activity saved')
      this.props.onCancel()
    } catch (err) {
      console.error('Problem saving activity: ' + err)
      toastr.error('Problem saving activity: ' + err.message)
      this.setState({
        errorMessages: ['Problem saving activity: ' + err.message]
      })
    }
  }

  handleErrors = errors => {
    this.setState({ errorMessages: errors })
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.activityQl !== this.props.activityQl &&
      nextProps.activityQl.activity
    ) {
      this.setState({ ...this.state, activity: nextProps.activityQl.activity })
    }
  }

  render() {
    console.log('render after-meeting-form', this.props, this.state)
    const { isSaving } = this.props
    const isLoading = this.props.activityQl
      ? this.props.activityQl.loading
      : false
    const activity = this.state.activity
    const activityType = activity ? activity.activity_type : undefined
    const availableStatuses = this.props.getAvailableStatuses(activity)

    return (
      <React.Fragment>
        <h5>
          <FormattedMessage
            id="crm.ui.minute.form.confirm_meeting_details_and_participants"
            defaultMessage="Confirm Meeting Details and Participants"
          />
        </h5>
        {isLoading && <LoadingIcon />}
        {!isLoading && activity && activityType && (
          <div className="CrmForm">
            <MeetingFormInner
              account={this.props.account}
              persons={this.props.account.persons}
              addresses={this.props.account.addresses}
              activity={activity}
              availableStatuses={availableStatuses}
              onSubmit={this.handleFormSubmit}
              onCancel={this.props.onCancel}
              isSavingNewMeeting={isSaving}
              errorMessages={this.state.errorMessages}
              handleErrors={this.handleErrors}
              hiddenFields={{
                delete_meeting_button: true,
                create_meeting_minutes_button: true,
                confirm_meeting_button: true,
                cancel_meeting_button: true,
                save_new_meeting_button: true,
                meeting_form_buttons_section: true
              }}
              disabledFields={{ arranged_by: true, host: true }}
              validationRules={this.props.validationRules}
              isMeetingCompleted={this.props.isMeetingCompleted}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}

AfterMeetingForm.propTypes = {
  account: shape({}).isRequired,
  activity: shape({}).isRequired,
  isSaving: bool.isRequired,
  onCancel: func,
  doChangePage: func.isRequired,
  dispatch: func.isRequired,
  activityQl: shape({}),
  onSubmit: func.isRequired,
  getAvailableStatuses: func.isRequired,
  validationRules: shape({}),
  isMeetingCompleted: bool
}

AfterMeetingForm.defaultProps = {
  validationRules: {},
  isMeetingCompleted: false
}

function mapStateToProps(state, ownProps) {
  const activity = ownProps.activity ? ownProps.activity : getActivity(state)
  return {
    activity,
    activityType: getActivityType(state),
    isSaving: getIsSaving(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangePage: path => dispatch(push(path)),
    dispatch
  }
}

const AfterMeetingFormIntl = injectIntl(activityPageQlHoc(AfterMeetingForm))
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AfterMeetingFormIntl)
