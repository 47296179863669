/* eslint-disable camelcase */

import React from 'react'
import { withApollo } from 'react-apollo'
import { shape } from 'prop-types'
import { qlAnalyticsWidgetQuery } from 'crm-data/analytics'
import {
  mapContextFiltersIntoWidget,
  mapContextToFilter
} from '../../dashboard-utils'

export const widgetByPeriod = {
  code: 'ct_campaign_statistics_2019',
  type: 'analitics_widget',
  def_version: 5,
  title: 'Lead status',
  content: {
    data: {
      dataSources: [
        {
          code: 'LatestCampaignProcessQM',
          alias: 'CurrentProcessByStatusKind',
          type: 'QM',
          filter: {},
          aggregate: [{ id: 'process_uid', type: 'count' }],
          contextMapping: {
            sales_campaign_uid: 'sales_campaign_uid',
            owner_user_uid: 'user_uid',
            lead_in_date: 'activity_date'
          },
          qlQuery: `query sales_processes($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            sales_processes(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
              edges { edge { process_uid sale_current_step_type_kind  } } } }`,
          qlVariables: {
            groupBy: ['sale_current_step_type_kind'],
            limit: 1000
          },
          qlEdges: true,
          qlRoot: 'sales_processes'
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: true,
            columns: [
              {
                Header: 'Status',
                accessor: 'sale_current_step_type_kind'
              },
              {
                Header: 'Count',
                accessor: 'process_uid',
                width: 100,
                type: 'number',
                className: 'util-textRight',
                detailsPopup: 'sales_process',
                detailsFilter: {},
                detailsFilterMapping: {
                  sale_current_step_type_kind: 'sale_current_step_type_kind'
                }
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CurrentProcessByStatusKind'
            }
          }
        }
      ]
    }
  }
}

export const widgetLostSummary = {
  code: 'ct_campaign_statistics_2019',
  type: 'analitics_widget',
  def_version: 5,
  title: 'Lost summary',
  content: {
    data: {
      dataSources: [
        {
          code: 'LatestCampaignProcessQM',
          alias: 'CurrentProcessByLostType',
          type: 'QM',
          filter: {
            sale_current_step_type_kind: 'lost'
          },
          aggregate: [{ id: 'process_uid', type: 'count' }],
          contextMapping: {
            lead_in_date: 'activity_date'
          },
          qlQuery: `query sales_processes($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            sales_processes(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
              edges { edge { process_uid current_step_type_name  } } } }`,
          qlVariables: {
            groupBy: ['current_step_type_name'],
            limit: 1000
          },
          qlEdges: true,
          qlRoot: 'sales_processes'
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: true,
            initialSort: {
              id: 'process_uid',
              order: 'desc'
            },
            columns: [
              {
                Header: 'Reason Lost',
                accessor: 'current_step_type_name'
              },
              {
                Header: 'Count',
                accessor: 'process_uid',
                width: 100,
                type: 'number',
                className: 'util-textRight',
                detailsPopup: 'sales_process',
                detailsFilter: {
                  sale_current_step_type_kind: 'lost'
                },
                detailsFilterMapping: {
                  current_step_type_name: 'current_step_type_name'
                }
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CurrentProcessByLostType'
            }
          }
        }
      ]
    }
  }
}

export const widgetPendingSummary = {
  code: 'ct_campaign_statistics_2019',
  type: 'analitics_widget',
  def_version: 5,
  title: 'Pending Summary',
  content: {
    data: {
      dataSources: [
        {
          code: 'LatestCampaignProcessQM',
          alias: 'CurrentPendingProcessByLostType',
          type: 'QM',
          filter: {
            completed_time: 'null'
          },
          aggregate: [{ id: 'process_uid', type: 'count' }],
          contextMapping: {
            sales_campaign_uid: 'sales_campaign_uid',
            owner_user_uid: 'user_uid',
            lead_in_date: 'activity_date'
          },
          qlQuery: `query sales_processes($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            sales_processes(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
              edges { edge { process_uid current_step_type_name  } } } }`,
          qlVariables: {
            groupBy: ['current_step_type_name'],
            limit: 1000
          },
          qlEdges: true,
          qlRoot: 'sales_processes'
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: true,
            columns: [
              {
                Header: 'Current status',
                accessor: 'current_step_type_name'
              },
              {
                Header: 'Count',
                accessor: 'process_uid',
                width: 100,
                type: 'number',
                className: 'util-textRight',
                detailsPopup: 'sales_process',
                detailsFilter: {
                  completed_time: 'null'
                },
                detailsFilterMapping: {
                  current_step_type_name: 'current_step_type_name'
                }
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CurrentPendingProcessByLostType'
            }
          }
        }
      ]
    }
  }
}

export const widgetNewLeadAgeing = {
  code: 'new_lead_ageing',
  type: 'analitics_widget',
  def_version: 5,
  title: 'New Lead Ageing',
  description: 'How old are leads that are "New - untouched"',
  enable_excel_export: true,
  content: {
    data: {
      dataSources: [
        {
          code: 'LatestCampaignProcessQM',
          alias: 'UntouchedLeadsAgeing',
          type: 'QM',
          filter: {
            last_activity_date_ageing_category: 'ageing_untouched',
            sales_result_code: 'lead',
            detailed_status_code: 'new'
          },
          aggregate: [{ id: 'account_uid', type: 'count' }],
          contextMapping: {
            sales_campaign_uid: 'sales_campaign_uid',
            owner_user_uid: 'user_uid',
            lead_in_date: 'activity_date'
          },
          transposeColumns: ['lead_in_date_ageing_category'],
          transposeKey: 'instance_uid',
          transposeValueField: 'account_uid',
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid)  {
              master_uid name instance(instance_uid: $instance_uid) {
                instance_uid name accountsc(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                  edges {
                    edge {
                      instance_uid
                      account_uid
                      lead_in_date_ageing_category
                    }
                  }
                }
              }
            }
          }`,
          groupBy: ['lead_in_date_ageing_category'],
          qlEdges: true,
          qlRoot: 'accountsc'
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          mob_fix_columns: 2,
          props: {
            columnGroups: [
              {
                Header: '',
                colSpan: 3
              },
              {
                Header: 'Current Status - Pendings',
                colSpan: 6
              }
            ],
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 30
              },
              {
                header: 'Total',
                accessor: 'account_uid',
                width: 70,
                align: 'center',
                headerAlign: 'center',
                detailsPopup: 'account',
                detailsFilter: {
                  last_activity_date_ageing_category: 'ageing_untouched',
                  sales_result_code: 'lead',
                  detailed_status_code: 'new'
                }
              },
              {
                header: 'Current',
                accessor: 'ageing_current',
                detailsPopup: 'account',
                detailsFilter: {
                  last_activity_date_ageing_category: 'ageing_untouched',
                  sales_result_code: 'lead',
                  detailed_status_code: 'new',
                  lead_in_date_ageing_category: 'ageing_current'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: '1 Day Old',
                accessor: 'ageing_1',
                detailsPopup: 'account',
                detailsFilter: {
                  last_activity_date_ageing_category: 'ageing_untouched',
                  sales_result_code: 'lead',
                  detailed_status_code: 'new',
                  lead_in_date_ageing_category: 'ageing_1'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: '2 Days Old',
                accessor: 'ageing_2',
                detailsPopup: 'account',
                detailsFilter: {
                  last_activity_date_ageing_category: 'ageing_untouched',
                  sales_result_code: 'lead',
                  detailed_status_code: 'new',
                  lead_in_date_ageing_category: 'ageing_2'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: '3-4 Days Old',
                accessor: 'ageing_3_4',
                detailsPopup: 'account',
                detailsFilter: {
                  last_activity_date_ageing_category: 'ageing_untouched',
                  sales_result_code: 'lead',
                  detailed_status_code: 'new',
                  lead_in_date_ageing_category: 'ageing_3_4'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: '5+ Days Old',
                accessor: 'ageing_5_plus',
                detailsPopup: 'account',
                detailsFilter: {
                  last_activity_date_ageing_category: 'ageing_untouched',
                  sales_result_code: 'lead',
                  detailed_status_code: 'new',
                  lead_in_date_ageing_category: 'ageing_5_plus'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'UntouchedLeadsAgeing'
            }
          }
        }
      ]
    }
  }
}

export const widgetPendingAgeing = {
  code: 'new_lead_ageing',
  type: 'analitics_widget',
  def_version: 5,
  title: 'Active Lead Ageing',
  description: 'Ageing of pending leads (based on Lead in Date)',
  enable_excel_export: true,
  content: {
    data: {
      dataSources: [
        {
          code: 'LatestCampaignProcessQM',
          alias: 'UntouchedLeadsAgeing',
          type: 'QM',
          filter: {
            latest_step_type_kind: 'active'
          },
          aggregate: [{ id: 'account_uid', type: 'count' }],
          contextMapping: {
            sales_campaign_uid: 'sales_campaign_uid',
            owner_user_uid: 'user_uid',
            lead_in_date: 'activity_date'
          },
          transposeColumns: ['lead_in_date_ageing_category'],
          transposeKey: 'latest_step_type_name',
          transposeValueField: 'account_uid',
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid)  {
              master_uid name instance(instance_uid: $instance_uid) {
                instance_uid name accountsc(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                  edges {
                    edge {
                      account_uid
                      lead_in_date_ageing_category
                      latest_step_type_name
                    }
                  }
                }
              }
            }
          }`,
          groupBy: ['lead_in_date_ageing_category', 'latest_step_type_name'],
          qlEdges: true,
          qlRoot: 'accountsc'
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          mob_fix_columns: 2,
          props: {
            generate_summary: true,
            columnGroups: [
              {
                Header: '',
                colSpan: 3
              },
              {
                Header: 'Current Status - Pendings',
                colSpan: 6
              }
            ],
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 30
              },
              {
                Header: 'Step type',
                accessor: 'latest_step_type_name',
                width: 30
              },
              {
                header: 'Total',
                accessor: 'account_uid',
                width: 70,
                align: 'center',
                headerAlign: 'center'
              },
              {
                header: 'Current',
                accessor: 'ageing_current',
                detailsPopup: 'account',
                detailsFilter: {
                  latest_step_type_kind: 'active',
                  lead_in_date_ageing_category: 'ageing_current'
                },
                detailsFilterMapping: {
                  latest_step_type_name: 'latest_step_type_name'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: '1 Day Old',
                accessor: 'ageing_1',
                detailsPopup: 'account',
                detailsFilter: {
                  latest_step_type_kind: 'active',
                  lead_in_date_ageing_category: 'ageing_1'
                },
                detailsFilterMapping: {
                  latest_step_type_name: 'latest_step_type_name'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: '2 Days Old',
                accessor: 'ageing_2',
                detailsPopup: 'account',
                detailsFilter: {
                  latest_step_type_kind: 'active',
                  lead_in_date_ageing_category: 'ageing_2'
                },
                detailsFilterMapping: {
                  latest_step_type_name: 'latest_step_type_name'
                },

                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: '3-4 Days Old',
                accessor: 'ageing_3_4',
                detailsPopup: 'account',
                detailsFilter: {
                  latest_step_type_kind: 'active',
                  lead_in_date_ageing_category: 'ageing_3_4'
                },
                detailsFilterMapping: {
                  latest_step_type_name: 'latest_step_type_name'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: '5+ Days Old',
                accessor: 'ageing_5_plus',
                detailsPopup: 'account',
                detailsFilter: {
                  latest_step_type_kind: 'active',
                  lead_in_date_ageing_category: 'ageing_5_plus'
                },
                detailsFilterMapping: {
                  latest_step_type_name: 'latest_step_type_name'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'UntouchedLeadsAgeing'
            }
          }
        }
      ]
    }
  }
}

const CtCampaignStatisticsDashboardHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = { selectedSections: {} }
    }
    static propTypes = {
      dashboard: shape().isRequired
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (
        nextProps !== undefined &&
        JSON.stringify(nextProps.analyticsContext) !==
          JSON.stringify(this.props.analyticsContext)
      ) {
        this.setState({}, () => {
          this.handleRefresh()
        })
      }
    }

    handleRefresh = async () => {
      const isCampaignFilterMissing = this.getIsCampaignFilterMissing()

      if (isCampaignFilterMissing) {
        return
      }
      let widgetDefinitionWithFilters = widgetByPeriod
      if (this.props.dashboard && this.props.dashboard.filters) {
        widgetDefinitionWithFilters = mapContextFiltersIntoWidget(
          widgetDefinitionWithFilters,
          {
            analyticsContext: { filters: this.props.dashboard.filters }
          }
        )
      }

      widgetDefinitionWithFilters = mapContextFiltersIntoWidget(
        widgetDefinitionWithFilters,
        this.props
      )

      const contextFilter = mapContextToFilter(this.props, true)
      const variables = {
        widgetDefinition: widgetDefinitionWithFilters,
        filter: contextFilter
      }
      await this.setStateAsync({ isLoading: true })
      try {
        const res = await this.props.client.query({
          query: qlAnalyticsWidgetQuery,
          variables,
          fetchPolicy: 'no-cache'
        })

        if (
          res.data &&
          res.data.dataSourceQuery &&
          res.data.dataSourceQuery.data
        ) {
          let data = {
            ...res.data.dataSourceQuery.data.dataSources
          }

          this.setState({
            data,
            contextFilter,
            isLoading: false
          })
        }
        return
      } catch (err) {
        console.log('Error', err)
      }
      await this.setStateAsync({ isLoading: false })
    }

    getIsCampaignFilterMissing = () =>
      !this.props.analyticsContext ||
      !this.props.analyticsContext.filters ||
      !this.props.analyticsContext.filters.sales_campaign_uid ||
      !this.props.analyticsContext.filters.sales_campaign_uid.value

    render() {
      console.log('render ctCampaignStatisticsDashboard HOC')
      const isCampaignFilterMissing = false

      return (
        <WrappedComponent
          {...this.props}
          isCampaignFilterMissing={isCampaignFilterMissing}
          onRefresh={this.handleRefresh}
          isLoading={this.state.isLoading}
          data={this.state.data}
          contextFilter={this.state.contextFilter}
        />
      )
    }
  }

  Inner.propTypes = {
    analyticsContext: shape({}).isRequired,
    client: shape({}).isRequired
  }

  return withApollo(Inner)
}
export default CtCampaignStatisticsDashboardHoc
