/* eslint-disable camelcase, no-param-reassign */
import React from 'react'
import { withApollo } from 'react-apollo'
import { shape, func } from 'prop-types'
import moment from 'moment'
import withLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { CrmButton, FlatTable, CrmCheckbox } from 'crm-components'

class RmsLeadsStatsDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      startDate: moment().add(-3, 'months'),
      endDate: moment().add(6, 'months'),
      is_current_status_over_3_months: true
    }

    this.columns = [
      {
        Header: 'Owner',
        accessor: 'owner__full_name'
      },
      {
        Header: 'Lead',
        accessor: 'lead_active',
        kind: 'lead',
        stale: false,
        className: 'util-textRight util-pointer',
        onClick: this.handleCellClick
      },
      {
        Header: 'Lead Stale',
        accessor: 'lead_stale',
        kind: 'lead',
        stale: true,
        className: 'util-textRed util-textRight  util-pointer',
        onClick: this.handleCellClick
      },
      {
        Header: 'Prospect Active',
        accessor: 'prospect_active',
        kind: 'prospect',
        stale: false,
        className: ' util-textRight  util-pointer',
        onClick: this.handleCellClick
      },
      {
        Header: 'Prospect Stale',
        accessor: 'prospect_stale',
        kind: 'prospect',
        stale: true,
        className: 'util-textRed util-textRight  util-pointer',
        onClick: this.handleCellClick
      },
      {
        Header: 'Customer Active',
        accessor: 'customer_active',
        kind: 'customer',
        stale: false,
        className: ' util-textRight  util-pointer',
        onClick: this.handleCellClick
      },
      {
        Header: 'Customer Stale',
        accessor: 'customer_stale',
        kind: 'customer',
        stale: true,
        className: 'util-textRed util-textRight  util-pointer',
        onClick: this.handleCellClick
      },
      {
        Header: 'Total',
        accessor: 'total',
        onClick: this.handleCellClick
      }
    ]
  }

  handleCellClick = (cell, col) => {
    // console.log('onClick', cell, col)

    const params = {
      type: 'account',
      filters: {
        kind: col.kind,
        is_stale_over_3_months: col.stale,
        owner__full_name: cell.owner__full_name,
        is_current_status_over_3_months: this.state
          .is_current_status_over_3_months
      }
    }
    // console.log('Params', params)
    this.props.onShowLeadsPopup(params)
  }

  handleChangeCurrentStatus3 = () => {
    this.setState({
      is_current_status_over_3_months: !this.state
        .is_current_status_over_3_months
    })
  }
  _parseData = data => {
    // console.log('parse data', data)
    const summary = { owner__full_name: 'Total' }

    const parsed =
      data && data.reduce
        ? data.reduce((a, i) => {
            const key = i.owner__full_name ? i.owner__full_name : 'empty'
            if (key) {
              if (!a[key])
                // eslint-disable-next-line
                a[key] = {
                  ...i,
                  total: 0
                }
            }
            // console.log('item i', i)
            const valueKey =
              i.kind + (i.is_stale_over_3_months ? '_stale' : '_active')
            // console.log('valueKey', valueKey)
            // eslint-disable-next-line
            if (!a[key].hasOwnProperty(valueKey)) {
              a[key][valueKey] = 0
            }
            // eslint-disable-next-line
            if (!summary.hasOwnProperty(valueKey)) {
              summary[valueKey] = 0
            }

            a[key].total += Number(i.account_uid)
            a[key][valueKey] += Number(i.account_uid)
            summary[valueKey] += Number(i.account_uid)
            return a
          }, {})
        : []

    // console.log('parsed', parsed)
    const values = Object.values(parsed)
    values.sort((a, b) =>
      a.owner__full_name
        ? a.owner__full_name.localeCompare(b.owner__full_name)
        : 0
    )
    values.push(summary)
    return values
  }
  handleRefresh = async () => {
    // console.log('handleRefresh')

    const filter = mapContextToFilter(this.props)
    const variables = {
      filter: {
        ...filter,
        is_deleted: false,
        is_current_status_over_3_months: this.state
          .is_current_status_over_3_months
      },
      fields: [
        'is_stale_over_3_months',
        'owner_user_uid',
        'owner__full_name',
        'kind',
        'is_current_status_over_3_months'
      ],
      aggregate: [
        {
          id: 'account_uid',
          type: 'count'
        }
      ],
      limit: 1000,
      rootType: 'AccountsListQM'
    }
    const res = await this.props.client.query({
      query: qlqGenericDataQuery,
      variables,
      fetchPolicy: 'no-cache'
    })
    // console.log('got data', res)
    if (res.data && res.data.genericDataQuery) {
      const parsed = this._parseData(res.data.genericDataQuery.data)
      this.setState({ data: parsed })
    }
  }

  render() {
    console.log('Render dash Rms Leads Stats ')
    return (
      <div>
        <div className="row">
          <div className="col-md-10 util-textRed">
            <CrmButton
              label="Refresh"
              type="primary"
              onClick={this.handleRefresh}
            />
          </div>
          <div className="col-md-5">
            <CrmCheckbox
              input={{
                value: this.state.is_current_status_over_3_months,
                onChange: this.handleChangeCurrentStatus3
              }}
              label={
                this.state.is_current_status_over_3_months
                  ? 'Only reassigned more than 3 months ago'
                  : 'Reassigned less then 3 months ago'
              }
            />
          </div>
        </div>
        {this.state.data && (
          <FlatTable columns={this.columns} data={this.state.data} />
        )}
      </div>
    )
  }
}

RmsLeadsStatsDashboard.propTypes = {
  analyticsContext: shape({}).isRequired, // eslint-disable-line
  client: shape({}).isRequired,
  onShowLeadsPopup: func.isRequired
}

RmsLeadsStatsDashboard.defaultProps = {
  dashboards: []
}

export default withLeadsPopupHoc(withApollo(RmsLeadsStatsDashboard))
