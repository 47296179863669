import React from 'react'
import {
  useCrmDynamicTableContext,
  DynamicTableContextType
} from '../dynamic-table-context'
import { useGetDashboardOptionsByRootQLType } from '../data/component-utils'
import { CrmDropdown } from '@cartrack-crm/ui'

const ViewOptionsDashboard = (props: any) => {
  const dynamicTableContext: DynamicTableContextType = useCrmDynamicTableContext()

  // const dashboardOptions = getDashboardsForType(props.rootQLType)
  const dashboardOptions = useGetDashboardOptionsByRootQLType(dynamicTableContext.rootQLType)
  console.log(
    'ViewOptionsDashboard',
    props,
    dashboardOptions,
    dynamicTableContext
  )

  return (
    <div className="util-flexRow util-flexCenterItems">
      <CrmDropdown
        style={{ minWidth: 200 }}
        placeholder="Dashboard"
        options={dashboardOptions}
        extraClassNames={{
          containerClassNames: 'util-marginLeftSm util-marginRightSm'
        }}
        input={{
          onChange: dynamicTableContext.setSelectDashboard,
          value: dynamicTableContext.selectedDashboard
        }}
      />
    </div>
  )
}

export default ViewOptionsDashboard
