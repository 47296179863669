import styled, { css } from 'styled-components'

export const DashboardFiltersWrapper = styled.div`
  width: 100%;
`

export const DashboardFiltersSection = styled.div`
  border-bottom: 1px solid #97979780;
  border-right: 1px solid #97979780;
  box-sizing: border-box;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  font-size: 10px;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.3' : '0.7')};
  padding: 10px 20px;
  position: relative;
  transition: background-color 150ms ease-in-out;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      background-color: #e6e6e6;
      opacity: 1;
    `},
`

export const DashboardFiltersSectionActive = styled.div`
  opacity: 1;
`

export const DashboardFiltersSectionValues = styled.div`
  display: flex;
  flex-direction: column;
`

export const DashboardFiltersSectionValue = styled.div`
  color: #334;
`

export const DashboardFiltersSectionLabel = styled.div`
  color: #667;
`
