/* eslint-disable */
import React, { PureComponent } from 'react'
import moment from 'moment' // eslint-disable-line
import ReactDayPickerInput from 'react-day-picker/DayPickerInput'
import {
  shape,
  string,
  func,
  element,
  oneOfType,
  number,
  bool,
  node
} from 'prop-types'
import { injectIntl } from 'react-intl'

/*
  CrmDayPickerInput is a wrapper over react-day-picker component
  Changes:
    - add supports for Fleet style placeholder (same as for TextInput)
    - expects value in miliseconds instead of Date/moment object
    - value in onChange event is sent in miliseconds
    - date is formatted via react-intl
*/

class CrmDayPickerComponent extends PureComponent {
  setInputRef = ref => {
    this.input = ref
  }

  handleBlur = () => {
    if (this.input) {
      this.props.onBlur.bind(this)()
    }
  }

  focus() {}

  handleClick = event => {
    console.log('handleClick', this.props)
    if (!this.props.disabled) {
      this.props.onClick(event)
    }
  }
  render() {
    const { placeholder, iconName } = this.props
    // Cconsole.log('try to format: ', this.props.value, new Date(this.props.value))
    // const formattedValue = this.props.value
    //   ? this.props.intl.formatDate(new Date(this.props.value))
    //   : this.props.value

    if (this.props.value) {
      moment.locale('en-GB')
      console.log('Valud as date ', moment(this.props.value), moment.locale())
      const d = new Date(this.props.value)
      console.log('d', d)
      console.log(new Date(this.props.value))
    }
    const formattedValue = this.props.value
    // ? this.props.intl.formatDate(new Date(this.props.value))
    // : undefined

    console.log('Render CrmDayPickerComponent', this.props, formattedValue)
    const { placeholderClassNames } = this.props.extraClassNames

    return (
      <div className={`CrmDayPickerInput`}>
        <input
          ref={this.setInputRef}
          className={` CrmDayPickerInput-input ${
            this.props.className ? this.props.className : ''
          }`}
          onBlur={this.handleBlur}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          onKeyUp={this.props.onKeyUp}
          onClick={this.handleClick}
          value={formattedValue}
        />
        {placeholder && (
          <div
            className={`${
              placeholderClassNames ? placeholderClassNames : ''
            } CrmDayPickerInput-placeholder ${
              this.props.value ? 'is-withValue' : ''
            }`}
          >
            {placeholder}
          </div>
        )}
        {iconName && (
          <i
            className={`fa fa-${iconName} CrmDayPickerInput-faIcon`}
            aria-hidden="true"
          />
        )}
      </div>
    )
  }
}
CrmDayPickerComponent.defaultProps = {
  iconName: '',
  extraClassNames: {
    containerClassNames: '',
    inputClassNames: '',
    placeholderClassNames: ''
  },
  disabled: false,
  className: undefined,
  placeholder: undefined
}

CrmDayPickerComponent.propTypes = {
  placeholder: node,
  iconName: string,
  extraClassNames: shape({
    containerClassNames: string,
    inputClassNames: string,
    placeholderClassNames: string
  }),
  className: string,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  onClick: func.isRequired,
  onKeyUp: func.isRequired,
  value: string.isRequired,
  disabled: bool
}

class CrmDayPickerInput extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openToTop: false
    }
  }

  handleDayChange = value => {
    console.log('handleDayChange', value)
    // const valueMs = value ? value.toDate().getTime() : value
    // this.props.input.onChange(valueMs, this.props.name)
    // const iso = value.toDate().toISOString()
    // console.log('iso ', iso)
    const f = value ? moment(value).format('YYYY-MM-DD') : undefined // this.props.input.onChange(iso, this.props.name)
    this.props.input.onChange(f, this.props.name)
  }

  formatDate = value => {
    console.log('formatDate', value)
    return value
  }

  parseDate = value => {
    console.log('parseDate', value)
    return value
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({
      ...this.state,
      width: window.innerWidth,
      height: window.innerHeight
    })
    this.recalculateOpenToTop()
  }

  setRef = input => {
    this.dropdownInput = input
    this.recalculateOpenToTop()
  }

  handleClearDate = () => {
    this.props.input.onChange('', this.props.name);
  }

  recalculateOpenToTop = () => {
    if (this.dropdownInput) {
      const rect = this.dropdownInput.getBoundingClientRect()
      const openToTop = rect && rect.top * 2 > window.innerHeight
      if (this.state.openToTop !== openToTop) {
        this.setState({ ...this.state, openToTop })
      }
    }
  }

  render() {
    const { input, type, placeholder, iconName, extraClassNames } = this.props

    const { containerClassNames, inputClassNames } = extraClassNames

    const formattedValue = input.value

    const { valid, touched, error } = this.props.meta

    // const formattedValue = input.value
    //   ? this.props.intl.formatDate(new Date(input.value)
    //   : undefined
    // const formattedValue = new Date(input.value).toISOString()
    console.log('Render picker input', input.value, this.props)
    // Cconsole.log('render crm day picker', input, this.props)
    // const formattedValue = input.value
    //   ? this.props.intl.formatDate(input.value)
    //   : input.value
    return (
      <div
        className={`${containerClassNames} CrmDayPickerValidateInput ${
          touched && !valid ? 'is-invalid' : ''
        }`}
        style={this.props.style}
        ref={input => {
          this.setRef(input)
        }}
      >
        <ReactDayPickerInput
          component={CrmDayPickerComponent}
          className={`${inputClassNames} CrmDayPickerInput-input`}
          classNames={{
            container: 'DayPickerInput',
            overlayWrapper:
              'DayPickerInput-OverlayWrapper CrmDayPickerInput-overlayWrapper',
            overlay: `${
              this.state.openToTop ? 'CrmDayPickerInput--openToTop' : ''
            } DayPickerInput-Overlay `
          }}
          value={formattedValue}
          type={type}
          intl={this.props.intl}
          placeholder={placeholder}
          onDayChange={this.handleDayChange}
          extraClassNames={extraClassNames}
          iconName={iconName}
          disabled={this.props.disabled}
          inputProps={{ disabled: this.props.disabled }}
          // formatDate={this.formatDate}
          // +       parseDate={this.parseDate}
        />
        <button onClick={this.handleClearDate} type="button">Clear</button>
        <div className="CrmDayPickerValidateInput-error">{error}</div>
      </div>
    )
  }
}

CrmDayPickerInput.propTypes = {
  input: shape({
    value: oneOfType([number.isRequired, string.isRequired]),
    onChange: func.isRequired
  }).isRequired,
  type: string,
  placeholder: node.isRequired,
  iconName: string,
  extraClassNames: shape({
    containerClassNames: string,
    inputClassNames: string,
    placeholderClassNames: string
  }),
  name: string,
  disabled: bool,
  meta: shape({
    valid: bool,
    touched: bool,
    error: string
  })
}

CrmDayPickerInput.defaultProps = {
  type: 'text',
  iconName: '',
  extraClassNames: {
    containerClassNames: '',
    inputClassNames: '',
    placeholderClassNames: ''
  },
  name: undefined,
  disabled: false,
  meta: {
    valid: true,
    touched: false,
    error: ''
  }
}

export default injectIntl(CrmDayPickerInput)
