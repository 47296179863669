import React from 'react'
import moment from 'moment'

import { shape, func, bool, arrayOf, object } from 'prop-types'
import { toastr } from 'react-redux-toastr'

import withLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import ctDailyLeadsHoc from './ct-daily-leads-dashboard-hoc'
import { CrmButton, CrmInfoBlock } from 'crm-components'

class CtDailyLeadsDashboard extends React.PureComponent {
  componentDidMount() {
    if (
      this.props.analyticsContext.filters &&
      this.props.analyticsContext.filters.activity_date
    ) {
      this.handleRefresh()
    }
  }
  handleRefresh = () => {
    if (!this.props.analyticsContext.filters.activity_date) {
      toastr.warning('Select date range in Activity Date filter')
      return
    }
    this.props.onRefresh()
  }

  renderRow = (
    label,
    data,
    field,
    summaryOnly = false,
    decimalNumbers = null
  ) => {
    const { days } = this.props
    const clickHandler = event => {
      const dataset = event.currentTarget.dataset
      const date = dataset.date
      const filter = {}
      if (data.account__lead_campaign__lead_source__name) {
        filter.account__lead_campaign__lead_source__name =
          data.account__lead_campaign__lead_source__name
      }
      if (data.account__lead_campaign__lead_source_uid) {
        filter.account__lead_campaign__lead_source_uid =
          data.account__lead_campaign__lead_source_uid
      }
      if (data.account__lead_campaign__lead_source__kind) {
        filter.account__lead_campaign__lead_source__kind =
          data.account__lead_campaign__lead_source__kind
      }

      this.handleClickDetails(
        {
          field,
          date
        },
        filter
      )
    }
    return (
      <tr>
        <td
          style={{ width: 150, minWidth: 150 }}
          className="CrmFlatTable-tbody-td"
        >
          {label}
        </td>
        <td
          className="util-textRight CrmFlatTable-tbody-td"
          onClick={clickHandler}
        >
          <strong>
            {data.summary &&
              data.summary[field] &&
              (decimalNumbers != null
                ? Number(data.summary[field]).toLocaleString(undefined, {
                    maximumFractionDigits: decimalNumbers
                  })
                : data.summary[field])}
          </strong>
        </td>
        {!summaryOnly &&
          days.map(i => {
            return (
              <td
                key={i}
                className="CrmFlatTable-tbody-td util-textRight util-pointer"
                onClick={clickHandler}
                data-date={moment(i).format('YYYY-MM-DD')}
              >
                {data[i] &&
                  data[i][field] &&
                  (decimalNumbers != null
                    ? Number(data[i][field]).toLocaleString(undefined, {
                        maximumFractionDigits: decimalNumbers
                      })
                    : data[i][field])}
              </td>
            )
          })}
      </tr>
    )
  }
  handleClickDetails = (cell, filters) => {
    console.log('handleClickDetails', cell, filters, this.props)

    const lparams = {
      filters: {
        ...this.props.contextFilter,
        ...filters
      }
    }

    const dateFilter = cell.date
      ? cell.date
      : this.props.contextFilter.activity_date
    // This filter must be deleted as can break filter based on cell clicked
    delete lparams.filters.activity_date
    if (cell.field === 'fitments') {
      lparams.type = 'fitment'
      lparams.filters.completed_date = dateFilter
      lparams.filters.status_code = 'completed'
    }
    if (cell.field === 'authenticated_leads') {
      lparams.type = 'account'
      lparams.filters.validated_date = dateFilter
    }
    if (cell.field === 'first_fitments') {
      lparams.type = 'first_fitments'
      lparams.filters.completed_date = dateFilter
    }
    if (cell.field === 'meetings_completed') {
      lparams.type = 'activities'
      lparams.filters.start_date = cell.date
      lparams.filters.type__medium_code = 'meeting'
      lparams.filters.status__kind = 'completed'
      lparams.filters.used_user_uid = true
    }
    if (cell.field === 'meetings_scheduled') {
      lparams.type = 'activities'
      lparams.filters.start_time = dateFilter
      lparams.filters.type__medium_code = 'meeting'
      lparams.filters.role_code = 'performer'
      lparams.filters.used_user_uid = true
    }
    if (cell.field === 'new_leads') {
      lparams.type = 'account'
      lparams.filters.lead_in_date = dateFilter
    }
    if (cell.field === 'won_opportunities') {
      lparams.type = 'opportunity'
      lparams.filters.status_code = 'won'
      lparams.filters.completed_date = dateFilter
      lparams.filters.is_existing_customer = false
    }
    if (cell.field === 'leads_assigned') {
      lparams.type = 'sales_process'
      lparams.filters.is_first = true
      lparams.filters.assigned_to_user_date = dateFilter
    }
    if (this.props.dashboard && this.props.dashboard.filters) {
      lparams.filters = { ...this.props.dashboard.filters, ...lparams.filters }
    }
    if (lparams.type) {
      this.props.onShowLeadsPopup(lparams)
    }
  }

  renderSection = (data, summary) => {
    return <React.Fragment />
  }
  renderTable(data) {
    const { days } = this.props

    const getStyle = date => {
      const dow = Number(moment(date).format('e'))
      const style = { width: 36, minWidth: 36 }
      if (dow === 0 || dow === 6) {
        style.backgroundColor = '#f1f1ef'
      }
      return style
    }
    return (
      <table style={{ width: 'initial' }} className="CrmFlatTable ">
        <thead className="CrmFlatTable-thead CrmFlatTable-thead--small">
          <tr>
            <th colSpan={2}> </th>
            {days.map(i => {
              return (
                <th key={i} style={getStyle(i)}>
                  <div className="util-textRight">
                    {moment(i).format('ddd')}
                  </div>
                </th>
              )
            })}
          </tr>
          <tr>
            <th />
            <th>
              <strong>Total</strong>
            </th>
            {days.map(i => {
              return (
                <th key={i} style={getStyle(i)}>
                  <div className="util-textRight">
                    {moment(i).format('DD-MMM')}
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className="CrmFlatTable-tbody">
          {this.renderRow('Cost', data, 'lead_campaign_cost', false, 0)}
          {this.renderRow('Leads Received', data, 'new_leads')}
          {this.renderRow('Authenticated Leads', data, 'authenticated_leads')}
          {this.renderRow('Leads Assigned', data, 'leads_assigned')}
          {this.renderRow('Meetings Scheduled', data, 'meetings_scheduled')}
          {this.renderRow(
            'SAs Signed (Won Opportunities new Cust)',
            data,
            'won_opportunities'
          )}
          {this.renderRow(
            'Customers Acquired (First Fitted)',
            data,
            'first_fitments'
          )}
          {this.renderRow('Fitments', data, 'fitments')}
          {this.renderRow(
            'Conversions (Sales to leads)',
            data,
            'sales_to_leads',
            false,
            1
          )}
          {this.renderRow('Cost/Lead', data, 'cost_to_lead', false, 0)}
          {this.renderRow('Cost/Win', data, 'cost_to_win', false, 0)}
          {this.renderRow('Cost/Fit', data, 'cost_to_fitment', false, 0)}
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div className="util-fullWidth util-fullHeight">
        <div
          className="CrmWidget CrmWidget--fullHeight Widget CrmWidget--vMargin "
          style={{ maxWidth: 'calc(100vw - 220px)' }}
        >
          <div className="CrmWidget-header util-flexRow util-noMargin between-xs top-xs">
            <div className="util-flexColumn util-flexGrow">
              <div className="Widget-title CrmWidget-title util-flexRow">
                Daily Lead Results
              </div>
            </div>
            <div
              style={{
                minWidth: '100px',
                width: '15%'
              }}
              className="CrmWidget-toolboxWrapper"
            >
              <CrmButton
                onClick={this.handleRefresh}
                label="Refresh"
                isSaving={this.props.isLoading}
                type="primary"
              />
            </div>
          </div>
          <div className="Widget-content CrmWidget-content CrmWidget-content--padding">
            <div style={{ width: '100%', overflowY: 'auto' }}>
              {this.props.isActivityDateFilterMissing && (
                <CrmInfoBlock warning>
                  You must select activity date filter for this dashboard.
                </CrmInfoBlock>
              )}
              {this.props.data && this.renderTable(this.props.data)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CtDailyLeadsDashboard.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired,
  onRefresh: func.isRequired,
  isLoading: bool,
  isActivityDateFilterMissing: bool.isRequired,
  days: arrayOf(shape()),
  data: arrayOf(shape()),
  contextFilter: object,
  dashboard: shape().isRequired
}
CtDailyLeadsDashboard.defaultProps = {
  days: undefined,
  data: undefined
}

export default withLeadsPopupHoc(ctDailyLeadsHoc(CtDailyLeadsDashboard))
