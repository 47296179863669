/* eslint-disable camelcase */
import { graphql, compose } from 'react-apollo'
import {
  qlFetchMessages,
  qlFetchSelectedMessage,
  qlMarkasRead,
  qlCreateMessage
} from 'crm-data/messages'

export const gqlFetchMessages = graphql(qlFetchMessages, {
  name: 'messagesQl',
  fetchPolicy: 'no-cache',
  options: () => ({
    variables: { mine: true, unread: true }
  })
})

export const gqlFetchSelectedMessage = graphql(qlFetchSelectedMessage, {
  name: 'qlFetchSelectedMessage',
  options: ownProps => ({
    variables: {
      message_uid: ownProps.selectedMessage,
      skip: !ownProps.selectedMessage
    }
  })
})

export const gqlMarkasRead = graphql(qlMarkasRead, {
  name: 'markAsReadQl'
})

export const gqlCreateMessage = graphql(qlCreateMessage, {
  name: 'createMessageQl'
})

const messagesQLHOC = compose(
  gqlFetchMessages,
  gqlFetchSelectedMessage,
  gqlMarkasRead,
  gqlCreateMessage
)

export default messagesQLHOC
