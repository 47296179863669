import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate } from 'react-intl'
import { columnAcountHealth } from '../../accounts/list/accounts-table-inner.jsx'

const numberCell = prop => (
  <span className="util-textRight util-fullWidth">{prop.value}</span>
)

const columns = [
  columnAcountHealth,
  {
    Header: 'Account',
    accessor: 'account__name',
    Cell: row => (
      <div>
        <Link to={`/crm/account/${row.original.account_uid}/sales`}>
          {row.original.account__name}
        </Link>
      </div>
    )
  },
  {
    Header: 'Owner',
    accessor: 'account__owner__full_name'
  },
  {
    Header: 'Quotation Name',
    accessor: 'name'
  },
  {
    Header: 'Contract Length',
    accessor: 'contract_length',
    maxWidth: 100,
    Cell: numberCell
  },
  {
    Header: 'Current Step',
    accessor: 'process__current_step_type_code'
  }
]

export default columns
