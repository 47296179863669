import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useCrmContext } from 'crm-core/contexts/crm-context'
import { Aggregate, GroupBy, SaleProcess } from 'crm-types'

export const SALE_PROCESS_QL = gql`
  query master(
    $master_uid: String!
    $instance_uid: String!
    $filter: JSON
    $groupBy: [String]
    $aggregate: [JSON]
  ) {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        sales_processes(
          filter: $filter
          aggregate: $aggregate
          groupBy: $groupBy
        ) {
          edges {
            edge {
              process_uid
              owner_user_uid
              completed_date_month
              assigned_to_user_date
            }
          }
        }
      }
    }
  }
`
type SaleProcessResponse = {
  loading: boolean
  listSaleProcesses: SaleProcess[]
  refetch: Function
}
type UseGetSaleProcesses = (
  filter: any,
  groupBy: GroupBy,
  aggregate: Aggregate[]
) => SaleProcessResponse

export const useGetSaleProCesses: UseGetSaleProcesses = (
  filter,
  groupBy,
  aggregate
) => {
  const context = useCrmContext()
  const { data, loading, refetch } = useQuery(SALE_PROCESS_QL, {
    variables: {
      master_uid: context?.master?.master_uid,
      instance_uid: context?.instance?.instance_uid,
      groupBy: groupBy ?? undefined,
      aggregate: aggregate ?? undefined,
      filter: { ...filter }
    },
    fetchPolicy: 'no-cache'
  })
  const listSaleProcesses =
    data?.master?.instance?.sales_processes?.edges?.map((e) => e.edge) ?? []
  return { listSaleProcesses, loading, refetch }
}
