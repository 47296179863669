import React, { useState } from 'react'
import { useCrmToastr, CrmToastrType } from '@cartrack-crm/ui/src/toastrs'
import uuidv4 from 'uuid'
import MasterCustomerFormInner from './master-form-inner'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const cqCommandResult = ' status errors payload command_uid '
export const qlmExecuteCommand = gql`
mutation cqCommand($command: CqCommand!) { 
  cqCommand(command: $command) { 
  ${cqCommandResult} 
}  
}`

const MasterCustomerForm = (props) => {
  const history = props.history
  const master_uid = props.match.params.master_uid
  const [masterUid, setMasterUid] = useState(master_uid)
  const [isSaving, setSaving] = useState(false)
  const [createMasterApi] = useMutation(qlmExecuteCommand)
  const [updateMasterAttributesApi] = useMutation(qlmExecuteCommand)
  const [createFirstInstanceApi] = useMutation(qlmExecuteCommand)
  const [createAdminUserApi] = useMutation(qlmExecuteCommand)
  const [createProfilesTemplateApi] = useMutation(qlmExecuteCommand)
  const [createProfilesFromTemplateApi] = useMutation(qlmExecuteCommand)
  const [completeConfigurationApi] = useMutation(qlmExecuteCommand)
  const is_new = master_uid === 'new'
  const [showToastr, hideToastr] = useCrmToastr()

  const handleCreateMaster = async (payload) => {
    setSaving(true)
    const command = {
      type: 'master_customer.create',
      aggregate_uid: payload.master_uid,
      aggregate_type: 'CustomerMaster',
      payload: {
        ...payload,
        step: 1
      }
    }
    try {
      const masterData = await createMasterApi({
        variables: {
          command: {
            command_uid: uuidv4(),
            ...command
          }
        }
      })

      showToastr(CrmToastrType.success, 'Master created')
      const new_master_uid =
        masterData.data.cqCommand.payload.master_customer.master_uid
      setMasterUid(new_master_uid)
      history.push(`/master/${new_master_uid}`)
    } catch (err) {
      console.error(err)
      showToastr(CrmToastrType.error, err)
    }
    setSaving(false)
  }

  const handleUpdateAttributes = async (payload) => {
    setSaving(true)
    const command = {
      type: 'master_customer.update_attributes',
      aggregate_uid: masterUid,
      aggregate_type: 'CustomerMaster',
      payload: {
        ...payload
      }
    }
    try {
      const masterData = await updateMasterAttributesApi({
        variables: {
          command: {
            command_uid: uuidv4(),
            ...command
          }
        }
      })

      showToastr(CrmToastrType.success, 'Attributes updated')
    } catch (err) {
      console.error(err)
      showToastr(CrmToastrType.error, err)
    }
    setSaving(false)
  }

  const handleCreateFirstInstance = async (payload) => {
    setSaving(true)
    const command = {
      type: 'master_customer.create_first_instance',
      aggregate_uid: masterUid,
      aggregate_type: 'CustomerMaster',
      payload: {
        ...payload
      }
    }

    try {
      const masterData = await createFirstInstanceApi({
        variables: {
          command: {
            command_uid: uuidv4(),
            ...command
          }
        }
      })

      showToastr(CrmToastrType.success, 'First instance created')
    } catch (err) {
      console.error(err)
      showToastr(CrmToastrType.error, err)
    }
    setSaving(false)
  }

  const handleCreateAdminUser = async (payload) => {
    setSaving(true)
    const command = {
      type: 'master_customer.create_admin_user',
      aggregate_uid: masterUid,
      aggregate_type: 'CustomerMaster',
      payload: {
        ...payload
      }
    }

    try {
      const masterData = await createAdminUserApi({
        variables: {
          command: {
            command_uid: uuidv4(),
            ...command
          }
        }
      })

      showToastr(CrmToastrType.success, 'Admin user created')
    } catch (err) {
      console.error(err)
      showToastr(CrmToastrType.error, err)
    }

    setSaving(false)
  }

  const handleCreateProfilesTemplate = async () => {
    setSaving(true)
    const command = {
      type: 'master_customer.create_profiles_template',
      aggregate_uid: masterUid,
      aggregate_type: 'CustomerMaster',
      payload: {
        master_uid: masterUid
      }
    }

    try {
      const masterData = await createProfilesTemplateApi({
        variables: {
          command: {
            command_uid: uuidv4(),
            ...command
          }
        }
      })

      showToastr(CrmToastrType.success, 'Default profile template created')
    } catch (err) {
      console.error(err)
      showToastr(CrmToastrType.error, err)
    }

    setSaving(false)
  }

  const handleCreateProfilesFromTemplate = async () => {
    setSaving(true)
    const command = {
      type: 'master_customer.create_profiles_from_template',
      aggregate_uid: masterUid,
      aggregate_type: 'CustomerMaster',
      payload: {
        master_uid: masterUid
      }
    }

    try {
      const masterData = await createProfilesFromTemplateApi({
        variables: {
          command: {
            command_uid: uuidv4(),
            ...command
          }
        }
      })

      showToastr(CrmToastrType.success, 'Default profile created')
    } catch (err) {
      console.error(err)
      showToastr(CrmToastrType.error, err)
    }

    setSaving(false)
  }

  const handleCompleteConfiguration = async () => {
    setSaving(true)
    const command = {
      type: 'master_customer.complete_configuration',
      aggregate_uid: masterUid,
      aggregate_type: 'CustomerMaster',
      payload: {
        master_uid: masterUid
      }
    }

    try {
      const masterData = await completeConfigurationApi({
        variables: {
          command: {
            command_uid: uuidv4(),
            ...command
          }
        }
      })

      showToastr(CrmToastrType.success, 'Configuration completed')
    } catch (err) {
      console.error(err)
      showToastr(CrmToastrType.error, err)
    }

    setSaving(false)
  }

  return (
    <MasterCustomerFormInner
      masterUid={masterUid}
      handleCreateMaster={handleCreateMaster}
      handleCreateFirstInstance={handleCreateFirstInstance}
      handleCreateAdminUser={handleCreateAdminUser}
      handleCreateProfilesTemplate={handleCreateProfilesTemplate}
      handleCreateProfilesFromTemplate={handleCreateProfilesFromTemplate}
      handleUpdateAttributes={handleUpdateAttributes}
      handleCompleteConfiguration={handleCompleteConfiguration}
      isSaving={isSaving}
      is_new={is_new}
    />
  )
}

export default MasterCustomerForm
