/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import uuidv4 from 'uuid'
import columns from './contracts-columns.jsx'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import { CrmButton } from 'crm-components'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

const qlOptions = {
  options: ownProps => ({
    variables: {
      filter: {
        ...ownProps.filter,
        process__step__type__kind: 'won'
      },
      fields: [
        'opportunity_uid',
        'account_uid',
        'account__name',
        'name',
        'contract_signed_date',
        'owner__full_name',
        'account__owner__full_name',
        'is_existing_customer',
        'vehicle_count',
        'cams_pending',
        'cams_completed',
        'cams_canceled',
        'remaining'
      ],
      rootType: 'OpportunitiesStats'
    }
  }),
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  })
}

const Table = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions
})

class OpportunitiesListInner extends React.Component {
  tableDataRefresh = refreshFunction => {
    this.refreshData = refreshFunction
  }

  render() {
    const getTdProps = (state, rowInfo, column) => ({
      onClick: (e, handleOriginal) => {
        if (column.Header !== 'Action') {
          if (this.onRowClick) {
            this.onRowClick(e, rowInfo)
          }
        }

        handleOriginal()
      }
    })

    console.log('render Contracts List Inner')

    return (
      <Table
        {...this.props}
        style={{ width: '100%' }}
        getTdProps={getTdProps}
        columns={columns}
        className="react-table -striped -highlight"
        tableName="opportunities"
        getRefreshFunc={this.tableDataRefresh}
        translation
      />
    )
  }
}

OpportunitiesListInner.propTypes = {
  ...cqCommandPropTypes
}
export default crmGenericCommandQlHoc(OpportunitiesListInner)
