import React, { useState, useEffect } from 'react'
import styles from './dashboard-filters.scss'
import classNames from 'classnames'
import { CrmTextInput, CrmButton } from 'crm-components'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import styled from 'styled-components'
import { get, set } from 'lodash'

const SearchBtn = styled(CrmButton)`
  display: flow-root;
  border-radius: 0 8px 8px 0;
`

type FilterSectionAccountNameProps = {
  style: any
}

export const FilterSectionAccountName: React.SFC<FilterSectionAccountNameProps> = (
  props: FilterSectionAccountNameProps
) => {
  const analyticsContext = useAnalyticsContext()
  const contextFilterValues = analyticsContext.filters
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    const accountQueryString = get(contextFilterValues, 'account.query.value', '')
    if (accountQueryString !== searchInput) {
      setSearchInput(accountQueryString)
    }
  }, [contextFilterValues])
  const _handelKeyPress = event => event.key === 'Enter' && doSendRequest()
  const doSendRequest = () => {
    const filter: any = { ...analyticsContext.filters }
    set(filter, 'account.query', searchInput ? { value: searchInput, label: 'Account Search' } : undefined)
    analyticsContext.setFilters(filter)
  }
  return (
    <div className={classNames(styles.DashboardFilters__Section)} style={{ ...props.style, minWidth: '300px' }}>
      <div className={styles.DashboardFilters__Section__Values}>
        <div className="util-flexGrow util-marginRight" style={{ display: 'flex' }}>
          <CrmTextInput
            placeholder={'Account name'}
            onChange={e => setSearchInput(e.target.value)}
            value={searchInput}
            onKeyPress={_handelKeyPress}
          />
          <SearchBtn icon="search" iconButton type="primary" onClick={doSendRequest} />
        </div>
      </div>
    </div>
  )
}
