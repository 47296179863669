/* eslint-disable global-require, camelcase */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import gql from 'graphql-tag'
import { graphql, compose } from 'react-apollo'
import { func, object, arrayOf, oneOfType } from 'prop-types'
import CampaignPage from './campaign-cost-page.jsx'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import {
  setSelectedCampaignCost,
  showCreateForm,
  clearSelectedCampaign,
  getSelectedCampaignCost
} from 'crm-duxs/settings/campaign-cost-reducer'
import { CrmFullpageContainer, CrmHbox, CrmButton } from 'crm-components'

const campaignCostFields = ` cost_uid
campaign_uid
date
value
currency_code
created_time
instance_uid
campaign{ campaign_uid name }
`

const listQuery = gql`
  query campaign_cost_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    campaign_cost_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        ${campaignCostFields}
      }
    }
  }
`

const createCampaignCostMutation = gql`
mutation campaign_cost_create($campaign_cost: campaign_cost_in) {
  campaign_cost_create(campaign_cost_in: $campaign_cost) {
   ${campaignCostFields}
  }
}`

const updateCampaignCostMutation = gql`
mutation campaign_cost_update($campaign_cost: campaign_cost_in) {
  campaign_cost_update(campaign_cost_in: $campaign_cost) {
   ${campaignCostFields}
  }
}`

const qlOptions = {
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.campaign_cost_qm_paged : []
  })
}

const Table = genericTableFactory({ query: listQuery, qlOptions })
class CrmCampaignSettings extends Component {
  constructor(props) {
    super(props)

    this.columns = [
      {
        Header: 'Campaign Name',
        accessor: 'campaign_uid',
        Cell: row => {
          if (row.original.campaign) return row.original.campaign.name
          return null
        }
      },
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Cost',
        accessor: 'value',
        sortMethod: (a, b) => (Number(a) > Number(b) ? 1 : -1)
      },
      {
        Header: 'Currency',
        accessor: 'currency_code'
      }
    ]

    this.handleClickAddBtn = this.handleClickAddBtn.bind(this)
    this.handleClickRowTable = this.handleClickRowTable.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  handleClickRowTable(rowInfo) {
    if (rowInfo) {
      this.props.doSelectedCampaignCost(rowInfo.original)
    }
  }

  handleClickAddBtn() {
    this.props.doShowCreateForm()
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  async onFormSubmit(data) {
    const cost =
      typeof data.value === 'string'
        ? data.value.replace(/,/g, '.')
        : data.value
    data = { ...data, value: parseFloat(cost) }
    await this.setStateAsync({ isSaving: true })

    if (this.props.campaignCost.formType === 'create')
      await this.props.create({
        variables: {
          campaign_cost: { ...data, date: data.date }
        }
      })
    else if (this.props.campaignCost.formType === 'update') {
      const dataUpdate = Object.assign({}, data)
      delete dataUpdate.__typename
      await this.props.update({
        variables: {
          campaign_cost: { ...dataUpdate, date: data.date }
        }
      })
    }

    toastr.info('Campaign cost saved')
    await this.setStateAsync({ isSaving: false })
    this.props.doClearSelectedCampaign()
    this.refreshData()
  }

  tableDataRefresh = refreshFunction => {
    this.refreshData = refreshFunction
  }

  render() {
    const getTdProps = (state, rowInfo) => ({
      onClick: () => this.handleClickRowTable(rowInfo)
    })

    return (
      <CrmFullpageContainer hasPadding vbox>
        <div className="row">
          <CrmButton label="Create" onClick={this.handleClickAddBtn} />
        </div>
        <CrmHbox fill style={{ flex: 4 }}>
          <div style={{ flex: 3 }}>
            <Table
              {...this.props}
              columns={this.columns}
              getTdProps={getTdProps}
              getRefreshFunc={this.tableDataRefresh}
            />
          </div>
          {this.props.campaignCost &&
            (this.props.campaignCost.uid ||
              this.props.campaignCost.formType) && (
              <div
                style={{
                  flex: 1,
                  height: '100%',
                  overflowY: 'auto',
                  padding: '20px'
                }}
              >
                <CampaignPage
                  campaignCost={this.props.campaignCost}
                  handleFormSubmit={this.onFormSubmit}
                />
              </div>
            )}
        </CrmHbox>
      </CrmFullpageContainer>
    )
  }
}

CrmCampaignSettings.propTypes = {
  doSelectedCampaignCost: func.isRequired,
  doClearSelectedCampaign: func.isRequired,
  doShowCreateForm: func.isRequired,
  campaignCost: oneOfType([arrayOf(object), object]).isRequired,
  update: func.isRequired,
  create: func.isRequired,
  data: object
}

CrmCampaignSettings.defaultProps = {}

const CrmCampaignSettingsQL = compose(
  graphql(createCampaignCostMutation, { name: 'create' }),
  graphql(updateCampaignCostMutation, { name: 'update' })
)(CrmCampaignSettings)

function mapStateToProps(state) {
  return {
    campaignCost: getSelectedCampaignCost(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doSelectedCampaignCost: originalData =>
      dispatch(setSelectedCampaignCost(originalData)),
    doShowCreateForm: () => dispatch(showCreateForm()),
    doClearSelectedCampaign: () => dispatch(clearSelectedCampaign())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CrmCampaignSettingsQL)
