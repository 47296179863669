function p(x, y) {
  return x + ' ' + y + ' '
}

// eslint-disable-next-line import/prefer-default-export, max-params
export function rectanglePath(x, y, w, h, r1 = 0, r2 = 0, r3 = 0, r4 = 0) {
  let strPath = 'M' + p(x + r1, y)
  strPath += 'L' + p(x + w - r2, y) + 'Q' + p(x + w, y) + p(x + w, y + r2)
  strPath +=
    'L' + p(x + w, y + h - r3) + 'Q' + p(x + w, y + h) + p(x + w - r3, y + h)
  strPath += 'L' + p(x + r4, y + h) + 'Q' + p(x, y + h) + p(x, y + h - r4)
  strPath += 'L' + p(x, y + r1) + 'Q' + p(x, y) + p(x + r1, y)
  strPath += 'Z'

  return strPath
}
