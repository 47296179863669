/* eslint-disable camelcase */
import apiCaller from 'crm-api/fleet-integration/api-caller'

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

function heliosGUID() {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

const datetimeFormats = ['ISO [Y-m-d H:i:sO]', 'US [m/d/Y H:i:sO T]', 'EU and others [d-m-Y H:i:sO]']

function parseSettings({ ct_fleet_get_user_settings: settings, ct_fleet_get_app_settings: appSettings }) {
  const altDataSource = appSettings.altdatasource.settings_value
  return {
    customerName: settings.customer_name,
    datetimeFormat: datetimeFormats[settings.datetime_format - 1],
    dotNumber: settings.carriers_dot_number,
    gpsFormat: settings.gps_format,
    imgSrc: settings.logo_image_base64,
    timezone: settings.timezone_of_user,
    altDataSource: altDataSource === 'DB_FLEET_US' ? 'FLEET_USA' : altDataSource
  }
}

export function normalizeSettings({ companyName, datetimeFormat, dotNumber, gpsFormat, imgSrc, timezone }, timezones) {
  const updates = {
    customer_name: companyName,
    datetime_format: datetimeFormats.indexOf(datetimeFormat) + 1,
    dot_setting: {
      dotName: companyName,
      dotNumber
    },
    gps_format: gpsFormat,
    logo_image_base64: imgSrc,
    timezone_of_user: timezones.indexOf(timezone) + 1
  }

  return {
    users: [updates]
  }
}

function parseTimezones(timezones) {
  return timezones.map(timezone => timezone.timezone_description)
}

export default {
  login(accountUsername, subUserUsername, password) {
    const authToken = heliosGUID()
    return apiCaller(
      'ct_login',
      {
        account: accountUsername,
        username: subUserUsername,
        password,
        otp: '',
        browserName: '',
        tabid: ''
      },
      { authToken }
    ).then(res => ({
      user: {
        id: res.user_id,
        username: res.username,
        companyName: res.ct_fleet_get_user_settings.customer_name,
        authToken
      },
      timezones: parseTimezones(res.ct_fleet_get_timezones_list),
      settings: parseSettings(res)
    }))
  },

  updateProfile(formData, timezones) {
    return apiCaller('ct_fleet_update_user_settings', normalizeSettings(formData, timezones)).then(res =>
      parseSettings(res.ct_fleet_get_user_settings[0])
    )
  },

  fetchTimezones() {
    return apiCaller('ct_fleet_get_timezones_list').then(res => parseTimezones(res.ct_fleet_get_timezones_list))
  }
}
