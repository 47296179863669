import React, { PureComponent } from 'react'
import { hasPermission } from '@cartrack-crm/crm-core'
import { shape } from 'prop-types'

const ActivitiesPermissionHOC = WrappedComponent => {
  class ActivitiesPermissionHOCInner extends PureComponent {
    constructor(props) {
      super(props)
      this.state = {}
    }

    hasAllPermissions = () => {
      const has = this.props.account.permissions
        ? hasPermission(
            this.props.account.permissions,
            'crm.activities',
            'modify',
            '*'
          )
        : false

      return has
    }

    hasSalesPermission = () => {
      const has = this.props.account.permissions
        ? hasPermission(
            this.props.account.permissions,
            'crm.activities',
            'modify',
            'sales'
          )
        : false

      return has || this.hasAllPermissions()
    }

    hasFinancePermission = () => {
      const has = this.props.account.permissions
        ? hasPermission(
            this.props.account.permissions,
            'crm.activities',
            'modify',
            'finances'
          )
        : false

      return has || this.hasAllPermissions()
    }

    hasCustomerServicePermission = () => {
      const has = this.props.account.permissions
        ? hasPermission(
            this.props.account.permissions,
            'crm.activities',
            'modify',
            'cs'
          )
        : false

      return has || this.hasAllPermissions()
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          hasSalesPermission={this.hasSalesPermission}
          hasFinancePermission={this.hasFinancePermission}
          hasCustomerServicePermission={this.hasCustomerServicePermission}
        />
      )
    }
  }

  ActivitiesPermissionHOCInner.propTypes = {
    account: shape({}).isRequired
  }

  return ActivitiesPermissionHOCInner
}

export default ActivitiesPermissionHOC
