import React from 'react'
import { useCrmDynamicTableContext, DynamicTableContextType } from '../dynamic-table-context'
import { useComponentConfigurationContext } from '../../configuration-tools/component-configuration-context'
import { useAnalyticsContext } from '../../context/context'
import { useCrmContext } from '@cartrack-crm/crm-core'

const DynamicTableViewChart = () => {
  const analyticsContext: any = useAnalyticsContext()
  const componentConfigurationContext = useComponentConfigurationContext()
  const crmContext = useCrmContext()
  const component = componentConfigurationContext.component
  return (
    <div className="util-flexGrow util-flexColumn">
      {component ? <div>Work in progress..</div> : <div> No component</div>}
      {/* {dynamicTableContext.chartType} */}
      {/* <CrmDropdown
        options={chartTypeOptions}
        input={{
          value: dynamicTableContext.chartType,
          onChange: dynamicTableContext.setChartType
        }}
      /> */}
    </div>
  )
}

export default DynamicTableViewChart
