import React, { useState } from 'react'
import moment from 'moment'
import { CrmLabeledField, CrmLoadingIcon } from 'crm-components'
import { numberFormat } from 'crm-utils/data-utils'
import classNames from 'classnames'

export interface DashBoardWidgetProps {
  avgDataPassedMonth?: number | string
  avgDataPassedMonthPerPerson?: number | string
  avgPerPersonNumber?: number | string
  bigContent?: boolean
  dashboardDescription?: string
  footerName?: string
  footerRender?: any[any]
  hideAverage?: boolean
  isLoading?: boolean
  note?: any
  onClickDetail?: Function
  refetch?: () => any
  secondFooterName?: string
  secondFooterRender?: any[any]
  subtitle?: string
  subtitleSecondBlock?: string
  title: string
  totalNumber?: number | string
}

export type DashboardBlockTitle = {
  caption?: string
  onClickDetail?: any
  showNumber: string | number
}

export type DashboardBlockContentInnerProps = {
  avgDataPassedMonth?: number | string
  bigContent?: boolean
  footerName?: string
  footerRender: any[any]
  hideAverage?: boolean
  hideHeader?: boolean
  isLoading?: boolean
  onClickDetail?: Function
}

export const DashboardBlockContentInner: React.SFC<DashboardBlockContentInnerProps> = (
  props
) => {
  const renderFooter = () => {
    const renderer = []
    const handleClickLabel = (month) => {
      const date = moment(month, 'YYYY-MM')
      if (props.onClickDetail) {
        if (date.isValid()) {
          const startDate = date.startOf('month').format('YYYY-MM-DD')
          const endDate = date.endOf('month').format('YYYY-MM-DD')
          props.onClickDetail({
            value: {
              $gte: startDate,
              $lte: endDate
            },
            label: startDate + ' - ' + endDate
          })
        } else {
          props.onClickDetail({ value: month, label: month })
        }
      }
    }
    if (props.footerName) {
      renderer.push(
        <CrmLabeledField
          style={{ width: '55px', marginLeft: 'auto', marginRight: 'auto' }}
          value={props.footerName}
          className={classNames({ 'util-marginAuto': !props.hideHeader })}
          valueClassName={classNames({
            'util-fontWeightLight': props.hideHeader
          })}
        />
      )
    }

    Object.keys(props.footerRender).forEach((key) => {
      let header = key
      if (moment(key, 'YYYY-MM').isValid()) {
        header = moment(key, 'YYYY-MM').format('MMM')
      }
      renderer.push(
        <CrmLabeledField
          key={key}
          style={{ minWidth: '40px' }}
          label={props.hideHeader ? null : header.toUpperCase()}
          value={numberFormat(props.footerRender[key])}
          lg
          valueClassName={classNames({
            'util-fontWeightLight': props.hideHeader
          })}
          onClickLabel={() => handleClickLabel(key)}
        />
      )
    })
    return renderer
  }
  return (
    <div
      className={classNames(
        'util-flexRow util-paddingSm util-marginRight util-marginLeft',
        { 'util-alignItemCenter': props.bigContent }
      )}
      style={props.bigContent ? { height: '126px' } : { height: '63px' }}
    >
      {!props.isLoading && (
        <React.Fragment>
          {props.footerRender && renderFooter()}
          {!props.hideAverage && (
            <div className="util-alignRight util-flexGrow">
              <CrmLabeledField
                label={props.hideHeader ? null : 'AVERAGE'}
                value={
                  typeof props.avgDataPassedMonth === 'number'
                    ? numberFormat(props.avgDataPassedMonth)
                    : props.avgDataPassedMonth
                }
                lg
                valueClassName={classNames('util-alignRight', {
                  'util-fontWeightLight': props.hideHeader
                })}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
interface IDashboardHoverCard {
  onHover: () => any
  title?: string
  textContent?: string
}
export const DashboardHoveredCard: React.SFC<IDashboardHoverCard> = (props) => {
  return (
    <div
      style={{ zIndex: 1, background: 'white', right: 0, top: 0 }}
      className="util-absolute util-borderRadiusMd util-dropShadow util-paddingMd util-fullHeight util-flexColumn util-fullExpand"
      onMouseLeave={props.onHover}
    >
      <div className="util-paddingSm util-textBold">
        <i className="fa fa-info-circle util-marginRight" /> {props.title}
      </div>
      <div style={{ overflow: 'auto' }}>{props.textContent}</div>
    </div>
  )
}

export const DashboardBlockTitle: React.SFC<DashboardBlockTitle> = (props) => (
  <div className="util-marginLeft">
    <div
      className="CrmWidget-title util-alignRight util-textXL"
      style={{
        color: '#4a4a4a',
        cursor: `${props.onClickDetail ? 'pointer' : 'default'}`
      }}
      onClick={props.onClickDetail}
    >
      {props.showNumber}
    </div>
    <div className="CrmWidget-subtitle util-alignRight util-marginBottom">
      {props.caption
        ? props.caption
        : `Month to date of ${moment().format('MMM')}`}
    </div>
  </div>
)

export const DashBoardWidget: React.FunctionComponent<DashBoardWidgetProps> = (
  props
) => {
  const [hoverCard, setHoverCard] = useState(false)
  const [hoverInfo, setHoverInfo] = useState(false)
  const isShowManagerMode = props?.secondFooterRender
  const onClickDetail = () => {
    if (props.onClickDetail) {
      props.onClickDetail()
    }
  }
  return (
    <div
      className="CrmWidget--white CrmWidget CrmWidget--noHeight util-marginBottom util-overflowHidden"
      onMouseEnter={() => setHoverCard(true)}
      onMouseLeave={() => setHoverCard(false)}
    >
      {hoverInfo && (
        <DashboardHoveredCard
          title={props.title}
          textContent={props.dashboardDescription}
          onHover={() => setHoverInfo(false)}
        />
      )}
      <React.Fragment>
        <div
          className="Widget-header row util-noMargin between-xs top-xs"
          style={{ height: 'auto', fontFamily: 'Roboto' }}
        >
          <div
            className="util-flexColumn util-flexGrow"
            style={{ height: '90px', position: 'relative' }}
          >
            <div
              className="Widget-title CrmWidget-title util-flexRow"
              style={{ maxWidth: '100%' }}
              onClick={() => (props.refetch ? props.refetch() : undefined)}
            >
              <span title="Click to refresh">{props.title}</span>
              {hoverCard && props.dashboardDescription && (
                <i
                  className="fa fa-question-circle util-textGray30"
                  style={{ marginLeft: 'auto' }}
                  onMouseEnter={() => setHoverInfo(true)}
                />
              )}
            </div>
            {props.isLoading ? (
              <CrmLoadingIcon className="util-marginAuto" />
            ) : (
              <React.Fragment>
                {props.note && (
                  <div
                    className="util-absolute"
                    style={{
                      top: '50%',
                      transform: 'translate(0, -50%)',
                      width: '200px',
                      lineHeight: '1'
                    }}
                  >
                    {props.note}
                  </div>
                )}
                <div
                  className="util-flexRow"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <DashboardBlockTitle
                    showNumber={props.totalNumber}
                    caption={props.subtitle}
                    onClickDetail={onClickDetail}
                  />
                  {isShowManagerMode && (
                    <DashboardBlockTitle
                      showNumber={props.avgPerPersonNumber}
                      caption={props.subtitleSecondBlock}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div
          className="Widget-content CrmWidget-content"
          style={{ background: '#F9F9F9' }}
        >
          <DashboardBlockContentInner
            avgDataPassedMonth={props.avgDataPassedMonth}
            bigContent={props.bigContent}
            footerName={props.footerName}
            footerRender={props.footerRender}
            hideAverage={props.hideAverage}
            isLoading={props.isLoading}
            onClickDetail={props.onClickDetail}
          />
          {isShowManagerMode && (
            <DashboardBlockContentInner
              avgDataPassedMonth={props.avgDataPassedMonthPerPerson}
              footerName={props.secondFooterName}
              footerRender={props.secondFooterRender}
              hideHeader
              isLoading={props.isLoading}
            />
          )}
        </div>
      </React.Fragment>
    </div>
  )
}
