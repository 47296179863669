import React, { createContext, useContext, useState } from 'react'

const initState = {
  isShowToast: false,
  title: undefined,
  type: 'success',
  options: {
    btnPrimaryTitle: undefined,
    btnSecondaryTitle: undefined,
    onBtnPrimary: undefined,
    onBtnSecondary: undefined,
    textDetailContent: undefined,
    textDetailTitle: undefined,
    position: 'top-right',
    message: undefined,
    timeToShow: undefined
  }

}

export const ToastrContext: any = createContext({ ...initState })

export const CrmToastrContextProvider = ({ children }) => {
  const [state, setState] = useState({ ...initState })
  const updateToastr = (newState: any) => {
    setState(newState)
  }

  const resetToast = () => {

    setState({ ...initState })
  }

  const toastContext = { state, setState: updateToastr, resetState: resetToast }
  return (
    <ToastrContext.Provider value={toastContext}>{children}</ToastrContext.Provider>
  )
}

export const useToastrContext = () => useContext(ToastrContext)



