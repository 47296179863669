import React from 'react'
import { withApollo } from 'react-apollo'
import { shape } from 'prop-types'
import { mapContextToFilter } from '../../../dashboard-utils'
import gql from 'graphql-tag'
import { arraySum } from 'crm-utils/data-utils'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const widgetFitmentsArpuByRmHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {}
    }

    handleRefresh = async () => {
      if (!this.props.analyticsContext.filters.activity_date) {
        return
      }
      const filter = mapContextToFilter(this.props, false)
      filter.completed_date = { status_code: 'completed' }
      if (this.props.analyticsContext.filters.activity_date) {
        filter.completed_date = this.props.analyticsContext.filters.activity_date
      }

      const variables = {
        filter,
        aggregate: [{ id: 'quantity', type: 'count' }],
        rootType: 'OrdersListQM',
        groupBy: ['completed_date'],
        sort: [{ id: 'completed_date', desc: false }],
        master_uid: this.props.crmContext.master.master_uid,
        instance_uid: this.props.crmContext.instance.instance_uid
      }
      const QL_FITMENTS = gql(`
      query master(
        $master_uid: String!
        $instance_uid: String!
        $filter: JSON
        $sort: [JSON]
        $groupBy: [String]
        $aggregate: [JSON]
      ) {
        master(master_uid: $master_uid) {
          master_uid
          name
          instance(instance_uid: $instance_uid) {
            instance_uid
            name
            orders(
              filter: $filter
              groupBy: $groupBy
              aggregate: $aggregate
              sort: $sort
            ) {
              edges {
                edge {
                  order_uid
                  quantity
                  owner_user_uid
                  owner {
                    full_name
                  }
                  completed_date
                }
              }
            }
          }
        }
      }
    `)

      const res = await this.props.client.query({
        query: QL_FITMENTS,
        variables,
        fetchPolicy: 'no-cache'
      })

      if (res.data) {
        var mapped = res?.data?.master?.instance?.orders
          ? res.data.master.instance.orders.edges.map(({ edge }) => ({
              ...edge
            }))
          : []
        this.setState({
          data: [
            ...mapped,
            {
              completed_date: 'Total',
              quantity: arraySum(mapped, 'quantity')
            }
          ]
        })
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          data={this.state.data}
          onRefresh={this.handleRefresh}
        />
      )
    }
  }

  Inner.propTypes = {
    analyticsContext: shape({}).isRequired,
    client: shape({}).isRequired,
    crmContext: shape({})
  }
  return withApollo(withCrmContext(Inner))
}

export default widgetFitmentsArpuByRmHoc
