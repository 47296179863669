/* eslint-disable */
import React from 'react'
import { bool, arrayOf, shape, func } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { exportDataToExcel } from '@cartrack-crm/crm-analytics'

import { CrmButton, CrmDropdownButton } from 'crm-components'
import { Button, LoadingIcon } from 'util-components'

class CrmWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState({ ...this.state, ...newState }, () => {
        resolve()
      })
    })

  componentDidMount() {
    if (this.props.autoRefresh) {
      this.handleRefresh()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== undefined &&
      nextProps.analyticsContext &&
      JSON.stringify(nextProps.analyticsContext.filters) !==
        JSON.stringify(this.props.analyticsContext.filters)
    ) {
      console.log(
        'CrmWidget will receive  props - context  changed',
        this.props,
        nextProps
      )
      if (this.props.autoRefresh) {
        setTimeout(() => {
          this.handleRefresh()
        })
      }
    }
  }

  handleExportExcelLayout = () => {
    console.log('handleExportExcel')
    if (!this.props.onGetRawData) {
      return toastr.error("This widget doesn't support excel export yet")
    }
    const data = this.props.onGetLayoutData()
    exportDataToExcel({ data, fileName: this.props.exportFileName })
  }

  handleExportExcel = () => {
    console.log('handleExportExcel')
    if (!this.props.onGetRawData) {
      return toastr.error("This widget doesn't support excel export yet")
    }
    const data = this.props.onGetRawData()
    exportDataToExcel({ data, fileName: this.props.exportFileName })
  }

  handleRefresh = async () => {
    console.log('handleRefresh')
    await this.setStateAsync({ isLoading: true })
    try {
      await this.props.onRefresh()
    } catch (err) {
      console.log('Error , ', err)
      throw err
    }
    await this.setStateAsync({ isLoading: false })
  }

  render() {
    const options = []
    if (this.props.onGetRawData) {
      options.push({
        onClick: this.handleExportExcel,
        label: 'Export raw data to Excel',
        icon: 'file-excel-o'
      })
    }

    if (this.props.onGetLayoutData) {
      options.push({
        onClick: this.handleExportExcelLayout,
        label: 'Export this layout to Excel',
        icon: 'file-excel-o'
      })
    }
    return (
      <div
        className={`
      Widget CrmWidget CrmWidget--vMargin 
      ${!this.props.fixHeight ? 'CrmWidget--noHeight' : ''}`}
      >
        <div className="CrmWidget-header util-flexRow util-noMargin between-xs top-xs">
          <div className="util-flexColumn util-flexGrow">
            <div className="Widget-title CrmWidget-title util-flexRow">
              {this.props.title}
              {(this.state.isLoading || this.props.isLoading) && (
                <LoadingIcon />
              )}
            </div>

            <div className="util-flexRow">
              <div className="CrmWidget-subtitle ">{this.props.subtitle}</div>
            </div>
          </div>

          <div
            style={{
              minWidth: '100px',
              width: '15%'
            }}
            className="CrmWidget-toolboxWrapper"
          >
            <div className="CrmWidget-toolboxWrapper-sumary">
              <div>
                {this.props.renderSummary && this.props.renderSummary()}
              </div>
            </div>
            <div className="CrmWidget-toolboxWrapper-tools util-textRight util-marginRight">
              <Button
                icon="file-excel-o"
                style={{ color: 'green' }}
                onClick={this.handleExportExcelLayout}
                square
                title="Export to Excel (this layou)"
                style={{ width: 38, height: 38 }}
                grouped
              />
              <Button
                icon="file-excel-o"
                style={{ color: 'green' }}
                onClick={this.handleExportExcel}
                square
                title="Export to Excel (raw data)"
                style={{ width: 38, height: 38 }}
                grouped
              />
            </div>
          </div>
          <div>
            <CrmButton
              icon="refresh"
              onClick={this.handleRefresh}
              options={options}
              square
              style={{ width: 38, height: 38 }}
              grouped
            />
          </div>
        </div>

        <div
          className={`Widget-content CrmWidget-content ${
            this.props.padding ? 'CrmWidget-content--padding ' : ''
          } `}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

CrmWidget.propTypes = {
  autoRefresh: bool
}
CrmWidget.defaultProps = {
  autoRefresh: false
}
export default CrmWidget
