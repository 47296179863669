import React from 'react'
import { groupDataByMonth } from 'crm-utils/data-utils'
import { DashboardResponse, HeadCount, GQLQueryRes } from 'crm-types'
import { createDashboardFormats, useQueryActivity, getWidgetAnalyticContext } from '../../dashboards/tools'
import moment from 'moment'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export interface WidgetFirstMeetingStatusHocProps {
  avgFirstMeetingCurrentMonthPerPerson: number | null
  avgFirstMeetingPassedMonthData: number | any[any]
  avgFirstMeetingPersonPassedMonth: number | null
  avgFirstMeetingThreeMonth: number
  footerName: string | null
  footerNamePerPerson: string | null
  isLoading?: boolean
  refetchData?: () => any
  threeMonthAgoFirstMeeting: any[any]
  totalFirstMeeting: number
  onClickDetail?: Function
  mainCaption?: string
}

export type UseGetFirstMeetingPropectDashboardByMonth = (
  numberOfMonth?: number,
  headcount?: HeadCount[]
) => DashboardResponse & GQLQueryRes

export const useGetFirstMeetingPropectDashboardByMonth: UseGetFirstMeetingPropectDashboardByMonth = (
  numberOfMonth = 3,
  headcounts
) => {
  const analyticsContext = getWidgetAnalyticContext()

  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const filter = {
    organization_unit,
    user_uid: headcounts ? undefined : userUid,
    role_code: 'performer',
    account_is_deleted: false,
    activity: {
      activity_type: {
        medium_code: 'meeting'
      },
      first_type: 'first',
      start_date: {
        $gte: startDate,
        $lte: endDate
      }
    }
  }

  const { listActivity, refetch, loading } = useQueryActivity(
    filter,
    ['start_date_month'],
    [{ type: 'count', id: 'activity_uid' }]
  )
  const grouped = groupDataByMonth(listActivity, 'start_date_month', 'activity_uid')
  return {
    refetch,
    loading,
    ...createDashboardFormats(grouped, numberOfMonth, moment(endDate, 'YYYY-MM-DD').format('YYYY-MM'), headcounts)
  }
}

const widgetFirstMeetingStatusHoc = (Component: any) => {
  const Wrapper = props => {
    const { headcounts } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      dataAvgPerPersonPassedMonth,
      avgPerPersonPassedMonth,
      avgPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      dataPassedMonths,
      totalLatestMonth,
      mainCaption
    } = useGetFirstMeetingPropectDashboardByMonth(3, headcounts)

    const onClickDetail = monthSelected => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)

      const filters = {
        performer_user_uid: headcounts ? undefined : userUid,
        organization_unit,
        activity_date: convertMonthToDateRange(endDate),
        first_type: 'first',
        role_code: "performer",
        type: { medium_code: 'meeting' },
        used_user_uid: true,
      }

      if (monthSelected) {
        filters.activity_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'activities', filters })
    }

    return (
      <Component
        avgFirstMeetingCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgFirstMeetingPassedMonthData={dataAvgPerPersonPassedMonth}
        avgFirstMeetingPersonPassedMonth={avgPerPersonPassedMonth}
        avgFirstMeetingThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        onClickDetail={onClickDetail}
        refetchData={() => refetch()}
        threeMonthAgoFirstMeeting={dataPassedMonths}
        totalFirstMeeting={totalLatestMonth}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetFirstMeetingStatusHoc
