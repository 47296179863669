import React from 'react'
import { shape, func } from 'prop-types'
import { withApollo } from 'react-apollo'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { CrmHeatmapChart } from 'crm-components'
import withLeadsPopupHoc from '../components/_with-leads-popup-hoc.jsx'
import { GenericWidgetWeb } from '@cartrack-crm/crm-analytics'
import { get } from 'lodash'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import CrmWidget from 'crm-modules/dashboard/components/crm-widget.jsx'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import gql from 'graphql-tag'

export const widgetLeadsByOwner = {
  code: 'new_lead_ageing',
  type: 'analitics_widget',
  def_version: 5,
  title: 'Leads by owner',
  description: 'Not support "Activity Date" filter',
  content: {
    data: {
      dataSources: [
        {
          code: 'AccountsListQM',
          alias: 'OwnedLeads',
          type: 'QM',
          contextMapping: {
            latest_owner_user_uid: 'user',
            latest_owner: {
              organization_unit: 'organization_unit'
            }
          },
          qlQuery: "query lead_by_owner($filter: JSON) { lead_by_owner(filter:$filter) { latest_owner_user_uid latest_owner { full_name department { name } } total lead invalid valid lost customer }}",
          qlVariables: {
            filter: {
              latest_owner_user_uid: { $ne: 'null' }
            }
          },
          qlEdges: false,
          qlOnly: true,
          qlRoot: 'lead_by_owner'
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          mob_fix_columns: 2,
          props: {
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 30
              },
              {
                header: 'Owner name',
                accessor: 'latest_owner.full_name',
                Cell: row => get(row, 'original.latest_owner.full_name', ''),
                width: 70,
                align: 'left',
                headerAlign: 'center'
              },
              {
                header: 'Department Type',
                accessor: 'latest_owner.department.name',
                Cell: row =>
                  get(row, 'original.latest_owner.department.name', ''),
                width: 70,
                align: 'left',
                headerAlign: 'center'
              },
              {
                header: 'Total',
                accessor: 'total',
                detailsPopup: 'account',
                detailsFilter: {
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: 'Lead',
                accessor: 'lead',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'lead',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Invalid',
                accessor: 'invalid',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'invalid',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Valid',
                accessor: 'valid',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'valid',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Lost',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                accessor: 'lost',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'lost',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Customer',
                accessor: 'customer',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'customer',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'OwnedLeads'
            }
          }
        }
      ]
    }
  }
}

export const widgetActiveLeadsByOwner = {
  code: 'new_lead_ageing',
  type: 'analitics_widget',
  def_version: 5,
  title: 'Active Leads details',
  description: 'Not support "Activity Date" filter',
  enable_excel_export: true,
  content: {
    data: {
      dataSources: [
        {
          code: 'AccountsListQM',
          alias: 'ActiveLead',
          type: 'QM',
          contextMapping: {
            latest_owner_user_uid: 'user',
            latest_owner: {
              organization_unit: 'organization_unit'
            }
          },
          qlVariables: {
            filter: {
              sales_result_code: { $in: ['valid', 'lead'] },
              latest_owner_user_uid: { $ne: 'null' }
            }
          },
          qlQuery: "query active_lead_status($filter: JSON) { active_lead_status(filter:$filter) { latest_owner_user_uid latest_owner { full_name department { name } }  total new touched contacted validated need_analysis opportunity quoted closing }}",
          qlEdges: false,
          qlRoot: "active_lead_status"
        }
      ],
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          mob_fix_columns: 2,
          props: {
            columnGroups: [
              {
                Header: '',
                colSpan: 4
              },
              {
                Header: 'Leads',
                colSpan: 3,
                styleHighlighted: true,
                className: 'CrmFlatTable-summary'
              },
              {
                Header: 'Valid',
                colSpan: 5,
                styleHighlighted: true,
                className: 'CrmFlatTable-summary'
              }
            ],
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 30
              },
              {
                header: 'Owner name',
                key: 'latest_owner.full_name',
                width: 70,
                align: 'left',
                headerAlign: 'center',
                Cell: row => get(row, 'original.latest_owner.full_name', '')
              },
              {
                header: 'Department',
                Cell: row => get(row, 'latest_owner.department.name', ''),
                width: 70,
                align: 'left',
                headerAlign: 'center'
              },
              {
                header: 'Total',
                accessor: 'total',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                },
                className: 'util-textRight util-pointer',
                width: 100,
                type: 'number'
              },
              {
                header: 'New',
                accessor: 'new',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  detailed_status_code: 'new',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Touched',
                accessor: 'touched',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  detailed_status_code: 'touched',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Contacted',
                accessor: 'contacted',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  detailed_status_code: 'contacted',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Validated',
                accessor: 'validated',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  detailed_status_code: 'validated',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Needs Analysis',
                accessor: 'need_analysis',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  detailed_status_code: 'needs_analysis',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Opportunity',
                accessor: 'opportunity',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  detailed_status_code: 'opportunity',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Quoted',
                accessor: 'quoted',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  detailed_status_code: 'quoted',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              },
              {
                header: 'Closing',
                accessor: 'closing',
                width: 70,
                headerAlign: 'center',
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                  detailed_status_code: 'closing',
                  latest_owner_user_uid: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'latest_owner_user_uid'
                }
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'ActiveLead'
            }
          }
        }
      ]
    }
  }
}
const lostLeadGQL = gql`
  query lost_lead_by_result($filter: JSON) { 
    lost_lead_by_result(filter:$filter) { 
      latest_owner_user_uid 
      latest_owner { 
        full_name
      }  
      count 
      latest_step_type_name
    }
  }
`
class WidgetByResult extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderSummary = () => (
    <div>
      {this.state.totalDispositions && (
        <span>Total Callbacks: {this.state.totalDispositions}</span>
      )}
    </div>
  )

  handleRefresh = async () => {
    try {
      const filter = mapContextToFilter(this.props, false)

      const variables = {
        crm_instance_uid: this.props.crmInstance?.instance_uid,
        groupBy: ['latest_owner_user_uid', 'latest_step_type_name'],
        filter: {
          sales_result_code: 'lost',
          ...filter
        }
      }

      const res = await this.props.client.query({
        query: lostLeadGQL,
        variables,
        fetchPolicy: 'no-cache'
      })
      const data = res?.data?.lost_lead_by_result?.map?.((e) => ({
        account_uid: e?.count,
        latest_step_type_name: e?.latest_step_type_name,
        latest_owner__full_name: e?.latest_owner?.full_name,
        latest_owner_user_uid: e?.latest_owner_user_uid,
        count: e?.count
      }))
      this.setState({ data: data })

      if (res.loading === false) {
        const ldata = data
        this.setState({
          data: ldata
        })
      }
    } catch (err) {
      console.error(err)
    }
  }
  handleCellClicked = (cell, isTotal = false) => {
    const filters = {
      ...this.props.analyticsContext.filters,
      latest_owner_user_uid: {
        value: this.state.data?.find((e) => e.latest_owner__full_name === cell.latest_owner__full_name)?.latest_owner_user_uid,
        $ne: 'null',
      },
      detailed_status_code: cell.name,
      latest_step_type_name: cell.latest_step_type_name,
      sales_result_code: 'lost'
    }
    if (isTotal) delete filters.detailed_status_code
    this.props.onShowLeadsPopup({ type: 'account', filters })
  }

  handleGetRawData = () => this.state.data

  render() {
    return (
      <CrmWidget
        title="Lost Leads by result"
        subtitle={`Not support "Activity Date" filter`}
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-agents-dispositions"
      >
        {!this.state.data && (
          <span>No data - select filters and press refresh to load</span>
        )}
        {this.state.data && (
          <CrmHeatmapChart
            xAxisField="latest_step_type_name"
            yAxisField="latest_owner__full_name"
            valueField="account_uid"
            data={this.state.data}
            onCellClicked={this.handleCellClicked}
          />
        )}
      </CrmWidget>
    )
  }
}

WidgetByResult.propTypes = {
  analyticsContext: shape().isRequired,
  client: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

const WidgetByResultWithHoc = withCrmContext(WidgetByResult)
class CtLeadsCurrentStatus extends React.PureComponent {
  handleShowLeadsPopup = params => {
    const lparams = {
      type: params.type,
      filters: {
        ...mapContextToFilter(this.props),
        ...params.filters
      }
    }

    this.props.onShowLeadsPopup(lparams)
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <GenericWidgetWeb
              widgetDefinition={widgetLeadsByOwner}
              key={widgetLeadsByOwner.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              onShowLeadsPopup={this.handleShowLeadsPopup}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <GenericWidgetWeb
              widgetDefinition={widgetActiveLeadsByOwner}
              key={widgetLeadsByOwner.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              onShowLeadsPopup={this.handleShowLeadsPopup}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <WidgetByResultWithHoc
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              onShowLeadsPopup={this.handleShowLeadsPopup}
              client={this.props.client}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" />
        </div>
      </div>
    )
  }
}

CtLeadsCurrentStatus.propTypes = {
  analyticsContext: shape().isRequired,
  client: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

export default withApollo(withLeadsPopupHoc(CtLeadsCurrentStatus))
