/* eslint-disable */
import React, { Component } from 'react'
import { shape, func, bool } from 'prop-types'
import ResourceReduxForm from './resource-form.jsx'

class ResourceQuickView extends Component {
  constructor(props) {
    super(props)
    this.handleClosePreview = this.handleClosePreview.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  handleSave(values) {
    console.log('handleSave', values)
    this.props.onSaveEditing(values)
  }

  handleClosePreview() {
    console.log('handleClosePreview')
    this.props.onClose()
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{ flex: 1, position: 'relative' }}>
          <div style={{ height: '100%', position: 'absolute', width: '100%' }}>
            <ResourceReduxForm
              editing={this.props.editing}
              creating={false}
              enableReinitialize="true"
              initialValues={this.props.dictionary}
              onSubmit={this.handleSave}
              onEdit={this.props.onEdit}
              onCancel={this.props.onCancelEdit}
              onReset={this.props.onReset}
              resource={this.props.dictionary}
            />
          </div>
        </div>
      </div>
    )
  }
}

ResourceQuickView.defaultProps = {
  report: undefined
}

ResourceQuickView.propTypes = {
  creating: bool.isRequired,
  editing: bool.isRequired,
  report: shape({}),
  onCancelEdit: func.isRequired,
  onSaveEditing: func.isRequired,
  onEdit: func.isRequired,
  onClose: func.isRequired,
  onReset: func.isRequired
}

export default ResourceQuickView
