import styled, { css } from 'styled-components'

export const DashboardFiltersDropDown = styled.div`
  height: 250px;
  z-index: 102;
  background-color: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  left: 0;
  padding: 15px;
  position: absolute;
  top: 100%;
  transform: translateY(-30%);
  transition: transform 120ms ease-in-out;
  flex-direction: column;
  transform: translateY(0);
`

export const DashboardFilterDropDownTitle = styled.div`
  font-size: 14px;
  margin: 5px 0;
`

export const DashboardFiltersDropDownBody = styled.div`
  overflow: auto hidden;
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const DashboardFiltersDropDownFooter = styled.div`
  font-size: 0.8em;
`

export const DashboardFiltersDropDownGroup = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const DashboardFiltersDropDownGroupCurrent = styled.div`
  flex: 3;
`

export const DashboardFiltersDropDownGroupHeader = styled.div`
  border-bottom: 2px solid #97979750;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 36px;
  padding: 0 10px;
  position: relative;
  &:hover {
    opacity: 1;
    visibility: visible;
  }
`

export const DashboardFiltersDropDownClearFilter = styled.div`
  position: absolute;
  right: 3px;
  top: 0;
  color: gray;
  transition: visibility 150ms, opacity 150ms;
  opacity: ${({ isHover }) => isHover ? 1 : 0}
`

export const DashboardFiltersDropDownIconWrapper = styled.div`
  margin-right: 15px;
  font-size: 16px;
`

export const DashboardFiltersDropDownGroupLabel = styled.div`
  font-size: 10px;
  letter-spacing: 0.2px;
`

export const DashboardFiltersDropDownValue = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const DashboardFiltersDropDownOptionsWrapper = styled.div`
  height: 100%;
  border-right: 1px solid #f0f0f0;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 10px 5px;
  padding: 0 5px;
  align-items: center;
`

export const DashboardFiltersDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`