import React from 'react'
import { getDateRangeByLabel, getAPIDateFormat, getDateRangeOptions } from 'crm-utils/time-utils'
import enhanceWithClickOutside from 'react-click-outside'
import { GenericDropDownContainer, GenericClearFilter, GenericDropDownFilter, FiltersOptionList } from '../tools'

import { getSaleDateString } from './filter-section-sale-date'
import { DashboardFiltersDropDownOptionsWrapper, DashboardFiltersDropDownGroup } from '../styled/dropdown-group-style'
import { DateRangePicker } from 'util-components'

interface SaleDateFilterDropdownProps {
  onClickedOutside: Function
  onFilterChanged: Function
  isExpanded: boolean
  onSetFilters: Function
}

class SaleDateFilterDropdown extends React.PureComponent<SaleDateFilterDropdownProps, any> {
  currentMonth

  constructor(props) {
    super(props)
    this.currentMonth = new Date()
    this.state = { filterValues: props.filterValues }
  }

  handleClickOutside = () => {
    if (this.props.isExpanded) {
      this.saveValue()
      setTimeout(this.props.onClickedOutside, 5)
    }
  }

  handleClickApply = () => {
    this.handleClickOutside()
  }

  handleClickCancel = () => {
    setTimeout(this.props.onClickedOutside, 5)
  }

  saveValue = () => {
    this.props.onSetFilters(this.state.filterValues)
  }

  handleDateRangeChanged = (dateRange, selectedDateRangeOption) => {
    const newFilterValues = { ...this.state.filterValues }

    if (dateRange && dateRange.from && dateRange.to) {
      newFilterValues.contract_signed_date = {
        value: {
          $gte: getAPIDateFormat(dateRange.from),
          $lte: getAPIDateFormat(dateRange.to)
        },
        calendarValue: {
          from: getAPIDateFormat(dateRange.from),
          to: getAPIDateFormat(dateRange.to)
        },
        label: selectedDateRangeOption ? selectedDateRangeOption.label : null,
        valueCode: selectedDateRangeOption ? selectedDateRangeOption.value : undefined
      }
    } else {
      newFilterValues.contract_signed_date = {}
    }

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleDateRangeLabelSelect = selectedDateRangeOption => {
    const dateRange = getDateRangeByLabel(selectedDateRangeOption.value, true)
    this.handleDateRangeChanged(dateRange, selectedDateRangeOption)
  }

  handleClearFilters = () => {
    const newFilterValues = { ...this.state.filterValues }
    newFilterValues.contract_signed_date = undefined

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilter = filterName => {
    const newFilterValues = { ...this.state.filterValues }
    newFilterValues[filterName] = undefined

    this.setState({
      filterValues: newFilterValues
    })
  }

  render() {
    return (
      <GenericDropDownContainer>
        <GenericDropDownFilter
          size={170}
          label="SaleDate"
          onClearFilter={() => this.handleClearFilter('contract_signed_date')}
          value={getSaleDateString(this.state.filterValues)}
        >
          <DashboardFiltersDropDownOptionsWrapper style={{ borderRight: 'none' }}>
            <FiltersOptionList
              options={getDateRangeOptions()}
              onFilterValueChanged={this.handleDateRangeLabelSelect}
              selectedValue={
                this.state.filterValues && this.state.filterValues.contract_signed_date
                  ? this.state.filterValues.contract_signed_date.valueCode
                  : undefined
              }
            />
          </DashboardFiltersDropDownOptionsWrapper>
        </GenericDropDownFilter>
        <DashboardFiltersDropDownGroup style={{ width: 480 }}>
          <DateRangePicker
            value={
              this.state.filterValues &&
              this.state.filterValues.contract_signed_date &&
              this.state.filterValues.contract_signed_date.calendarValue
                ? this.state.filterValues.contract_signed_date.calendarValue
                : undefined
            }
            onChange={this.handleDateRangeChanged}
            initialMonth={this.currentMonth}
            showDateTextInput={false}
          />
        </DashboardFiltersDropDownGroup>
        <GenericClearFilter
          onApply={this.handleClickApply}
          onCancel={this.handleClickCancel}
          onClearFilters={this.handleClearFilters}
        />
      </GenericDropDownContainer>
    )
  }
}

export default enhanceWithClickOutside(SaleDateFilterDropdown)
