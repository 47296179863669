// Simple binary search, except it returns the closest
// value if an exact match is not found
const binaryFindNearest = (predicate, lastBefore = false) => arr => {
  let min = 0
  let max = arr.length - 1
  let curr
  let result

  while (min <= max) {
    curr = Math.floor((min + max) / 2)
    result = predicate(arr[curr])

    if (result < 0) {
      max = curr - 1
    } else if (result > 0) {
      min = curr + 1
    } else {
      return curr
    }
  }

  const prev = arr[curr - 1]
  const next = arr[curr + 1]

  if (lastBefore) {
    return prev
      ? Math.abs(predicate(prev)) < Math.abs(result)
        ? curr - 1
        : curr
      : curr
  }

  const prevDiff = prev ? Math.abs(predicate(prev)) : Infinity
  const nextDiff = next ? Math.abs(predicate(next)) : Infinity

  if (nextDiff < prevDiff) {
    if (nextDiff < result) return curr + 1
  } else if (prevDiff < result) return curr - 1

  return curr
}

export default function findEventAtTime(time, events, lastBefore) {
  // Compares events' timestamps with our target time
  return binaryFindNearest(evt => time - evt.time, lastBefore)(events)
}
