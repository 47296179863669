/* eslint-disable camelcase */
import React from 'react'
import AccountsList from './accounts-list.jsx'

const MyClientsView = () => (
  <AccountsList
    fixFilter={[
      {
        code: 'account_info',
        code_option: 'equal',
        params: [{ condition_key: 'kind', condition_value: 'account' }]
      },
      {
        code: 'has_actor',
        params: { user_uid: 'me', role_code: 'owner' }
      }
    ]}
    accountsListCode="my_clients"
    disableMoreFilters={['owner']}
  />
)

export default MyClientsView
