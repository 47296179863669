// Actions
export const FETCH_PROFILE_PERMISSION = 'FETCH_PROFILE_PERMISSION'
export const RECEIVE_PROFILE_PERMISSION = 'RECEIVE_PROFILE_PERMISSION'
export const SELECTED_PROFILE_PERMISSION = 'SELECTED_PROFILE_PERMISSION'
export const UNSELECTED_PROFILE_PERMISSION = 'UNSELECTED_PROFILE_PERMISSION'
export const SAVE_EDITING_PROFILE_PERMISSION = 'SAVE_EDITING_PROFILE_PERMISSION'
export const SAVED_EDITING_PROFILE_PERMISSION =
  'SAVED_EDITING_PROFILE_PERMISSION'
export const EDITING_PROFILE_PERMISSION = 'EDITING_PROFILE_PERMISSION'
export const CANCEL_EDITING_PROFILE_PERMISSION =
  'CANCEL_EDITING_PROFILE_PERMISSION'
export const CREATING_PROFILE_PERMISSION = 'CREATING_PROFILE_PERMISSION'
export const CANCEL_CREATING_PROFILE_PERMISSION =
  'CANCEL_CREATING_PROFILE_PERMISSION'
export const SAVING_PROFILE_PERMISSION = 'SAVING_PROFILE_PERMISSION'
export const SAVE_CREATING_PROFILE_PERMISSION =
  'SAVE_CREATING_PROFILE_PERMISSION'
export const SAVING_PROFILE_PERMISSION_ERROR = 'SAVING_PROFILE_PERMISSION_ERROR'

// Reducer
const initialState = {
  lists: [],
  fetch: false,
  editing: false,
  creating: false,
  selected: undefined
}

export default function profilePermissionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE_PERMISSION:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_PROFILE_PERMISSION:
      return {
        ...state,
        fetch: false,
        lists: action.payload
      }
    case SELECTED_PROFILE_PERMISSION:
      return {
        ...state,
        selected: action.item,
        creating: false,
        editing: false
      }
    case UNSELECTED_PROFILE_PERMISSION:
      return {
        ...state,
        selected: undefined,
        editing: false
      }
    case EDITING_PROFILE_PERMISSION:
      return {
        ...state,
        editing: true,
        creating: false
      }
    case CANCEL_EDITING_PROFILE_PERMISSION:
      return {
        ...state,
        editing: false
      }
    case CREATING_PROFILE_PERMISSION:
      return {
        ...state,
        creating: true,
        editing: false,
        selected: undefined
      }
    case CANCEL_CREATING_PROFILE_PERMISSION:
      return {
        ...state,
        creating: false
      }
    case SAVING_PROFILE_PERMISSION:
      return {
        ...state,
        creating: true
      }
    default:
      return state
  }
}

export function fetchData() {
  return {
    type: FETCH_PROFILE_PERMISSION
  }
}

export function selectedItem(item) {
  return {
    type: SELECTED_PROFILE_PERMISSION,
    item
  }
}

export function unselectedItem() {
  return {
    type: UNSELECTED_PROFILE_PERMISSION
  }
}

export function startEditingItem() {
  return {
    type: EDITING_PROFILE_PERMISSION
  }
}

export function cancelEditingItem() {
  return {
    type: CANCEL_EDITING_PROFILE_PERMISSION
  }
}

export function startCreatingItem() {
  return {
    type: CREATING_PROFILE_PERMISSION
  }
}

export function cancelCreatingItem() {
  return {
    type: CANCEL_CREATING_PROFILE_PERMISSION
  }
}

export function saveCreatingItem(formValues) {
  return {
    type: SAVE_CREATING_PROFILE_PERMISSION,
    formValues
  }
}

export function saveEditingItem(formValues) {
  return {
    type: SAVE_EDITING_PROFILE_PERMISSION,
    formValues
  }
}
