import 'babel-polyfill'
import { hot } from 'react-hot-loader/root'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import ErrorBoundary from './error-boundary'

import { silentRefreshToken } from './crm/api/auth'
import configureStore from './crm/store'
import AppRoot from './crm/crm-app-root.jsx'

import '@formatjs/intl-relativetimeformat/polyfill-locales'
import '@formatjs/intl-relativetimeformat/polyfill'

Sentry.init({
  dsn: 'https://712accc7ebb04d8dba6107217958a2aa@sentry.io/206644',
  ignoreErrors: ['Authorization error', 'Network error: Failed to fetch'],
  enabled: process.env.NODE_ENV !== 'development',
  debug: process.env.NODE_ENV === 'development',
  environment: process.env.NODE_ENV,
  integrations: [
    new Integrations.Dedupe(),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],
  beforeSend(event, hint, x, y) {
    let isSentError = true
    const error = hint.originalException
    if (error.graphQLErrors && error.graphQLErrors[0]) {
      const category = error.graphQLErrors[0].category
      if (category === 'businessLogic') {
        isSentError = false
      }
    }
    if (isSentError) {
      return event
    }
  }
})

const store = configureStore(
  {},
  {
    Sentry
  }
)
export default store

function runApp() {
  function render(Component, hotReload = false) {
    ReactDOM.render(
      <ErrorBoundary>
        <Component
          store={store}
          history={store.history}
          hotReload={hotReload}
        />
      </ErrorBoundary>,
      document.getElementById('app')
    )
  }

  const hotApp = hot(AppRoot)
  render(hotApp)
  if (module.hot) {
    module.hot.accept('./crm/crm-app-root.jsx', () => {
      render(AppRoot, true)
    })
  }
}

// \\if (window.Intl) runApp()
// else
import('intl')
  .then(silentRefreshToken)
  .then(
    import('@formatjs/intl-relativetimeformat/polyfill-locales').then(runApp)
  )
  .catch(error => {
    console.error('Error loading intl', error)
  })
