import apiCaller from '../api-utils'

export default {
  listTerritoriesApi() {
    console.log('ic_api_territory_list')
    return apiCaller('ic_api_territory_list', {}).then(
      res => res.ic_api_territory_list
    )
  },
  updateTerritoryApi(id, params) {
    console.log('ic_api_territory_update')
    return apiCaller('ic_api_territory_update', {
      item: params
    }).then(res => res.ic_api_territory_update)
  },
  createTerritoryApi(params) {
    console.log('ic_api_territory_create')
    return apiCaller('ic_api_territory_create', {
      item: params
    }).then(res => res.ic_api_territory_create)
  }
}
