import React from 'react'
import { shape, func, bool } from 'prop-types'
import ctCampaignStaticicsDashboardHoc, {
  widgetByPeriod,
  widgetLostSummary,
  widgetPendingSummary,
  widgetNewLeadAgeing,
  widgetPendingAgeing
} from '../../hoc/ct-dashboards/ct-campaign-statistics-dashboard-hoc'
import { CrmInfoBlock } from 'crm-components'
import withLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import { GenericWidgetWeb } from '@cartrack-crm/crm-analytics'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'

class CtCampaignStatisticsDashbaord extends React.PureComponent {
  handleShowLeadsPopup = params => {
    // Merge with context
    const lparams = {
      type: params.type,
      filters: {
        ...mapContextToFilter(this.props),
        ...params.filters
      }
    }
    this.props.onShowLeadsPopup(lparams)
  }
  render() {
    // if (this.props.isCampaignFilterMissing) {
    //   return (
    //     <CrmInfoBlock warning>
    //       You must select Sales Campaign for this dashboard
    //     </CrmInfoBlock>
    //   )
    // }
    return (
      <div className="util-fullWidth">
        <div className="row">
          <div className="col-md-4">
            <GenericWidgetWeb
              widgetDefinition={widgetByPeriod}
              key={widgetByPeriod.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              onShowLeadsPopup={this.handleShowLeadsPopup}
            />
          </div>
          <div className="col-md-4">
            <GenericWidgetWeb
              widgetDefinition={widgetLostSummary}
              key={widgetByPeriod.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              onShowLeadsPopup={this.handleShowLeadsPopup}
            />
          </div>
          <div className="col-md-4">
            <GenericWidgetWeb
              widgetDefinition={widgetPendingSummary}
              key={widgetByPeriod.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              onShowLeadsPopup={this.handleShowLeadsPopup}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <GenericWidgetWeb
              widgetDefinition={widgetNewLeadAgeing}
              key={widgetByPeriod.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              onShowLeadsPopup={this.handleShowLeadsPopup}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <GenericWidgetWeb
              widgetDefinition={widgetPendingAgeing}
              key={widgetByPeriod.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              onShowLeadsPopup={this.handleShowLeadsPopup}
            />
          </div>
        </div>
      </div>
    )
  }
}

CtCampaignStatisticsDashbaord.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired,
  isCampaignFilterMissing: bool.isRequired
}
export default ctCampaignStaticicsDashboardHoc(
  withLeadsPopupHoc(CtCampaignStatisticsDashbaord)
)
