import React, { useState, useEffect } from 'react'
import {
  CrmDropdown,
  CrmTextInputNumber,
  CrmProductsSelect,
  CrmMessageBlock
} from 'crm-components'
import { CrmButton } from '@cartrack-crm/ui'
import { RadioInput, Spacer } from 'util-components'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
  contractLengthOptions,
  productTypeOptions,
  SalesTypeOptions,
  priceTableItemsQL
} from 'crm-data/prices-table'
import { toastr } from 'react-redux-toastr'
import generateQuotationForOpportunityHoc from 'crm-modules/quotations/hoc/generate-quotation-for-opportunity-hoc'
import QuickQuotationSummary from './quick-quotation-summary'
import PriceOptionItem from './price-options-item'
import { getAccountHqAddress } from 'crm-utils/accounts-utils'

interface IProps {
  opportunity: any
  intl: any
  handleQuickQuotationClose: Function
  account: any
  onGenerateQuotationForOpportunity: Function
  handleQuotationClick: Function
  client: any
  isGeneratingQuotation: boolean
  addresses: any
  persons: any
}

const QuickQuotationGenerator: React.SFC<IProps> = (props) => {
  const { opportunity, account, persons, addresses } = props
  const defaultQuantity = opportunity.quantity ? opportunity.quantity : 1
  const [quotation, setQuotation]: any = useState({
    items: [],
    account_uid: account.account_uid
  })
  const [newProductItem, setNewProductItem]: any = useState({
    quantity: defaultQuantity
  })
  const [selectedProductType, setSelectedProductType] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPrice, setSelectedPrice] = useState(null)
  const [errorPrices, setErrorPrices] = useState(null)
  const [autoOpenQuotationId, setAutoOpenQuotationId] = useState(false)
  const [creatingQuotaion, setCreatingQuotaion] = useState(false)
  const [priceOptions, setPriceOptions] = useState([])
  const [loadingPriceOptions, setLoadingPriceOptions] = useState(false)
  const [quotationAddress, setQuotationAddress] = useState(undefined)
  const [quotationContactPerson, setQuotationContactPerson] = useState(
    undefined
  )

  useEffect(() => {
    if (quotation.items.length === 0 && !newProductItem.product) {
      setCurrentPage(1)
    }
    return () => {}
  }, [newProductItem.product, quotation.items.length])

  useEffect(() => {
    if (autoOpenQuotationId) {
      console.log('QuickQuotationGenerator props changed', props)
      let openQuotation = null
      account.opportunities &&
        account.opportunities.map((opp) => {
          opp.documents &&
            opp.documents.map((quot) => {
              if (quot.document_uid === autoOpenQuotationId) {
                openQuotation = quot
                setCreatingQuotaion(false)
                setAutoOpenQuotationId(false)
              }
            })
        })
      if (openQuotation) {
        props.handleQuotationClick(openQuotation)
        props.handleQuickQuotationClose()
      }
    }
  }, [account, autoOpenQuotationId, props])

  useEffect(() => {
    const quotation_person = persons ? persons[0] : undefined
    const quotation_address = getAccountHqAddress({ addresses: addresses })
    setQuotationContactPerson(quotation_person)
    setQuotationAddress(quotation_address)
  }, [persons, addresses])

  const setAutoOpenQuotation = (documentUid) => {
    setAutoOpenQuotationId(documentUid)
  }

  const getCreateQuotationData = () => {
    return {
      ...opportunity,
      address_uid: quotationAddress?.address_uid,
      account_person_uid: quotationContactPerson?.account_person_uid
    }
  }
  const handleCreateQuickQuotation = async () => {
    const recalculateItems = quotation.items.reduce((ret, v) => {
      ret.push({
        ...v,
        subscription_net: getPriceByCycle(v.cycle, v.subscription_net),
        subscription_net_next_years: getPriceByCycle(
          v.cycle,
          v.subscription_net_next_years
        )
      })
      return ret
    }, [])
    try {
      const data = getCreateQuotationData()
      const res = await props.onGenerateQuotationForOpportunity(data, false, {
        ...quotation,
        items: recalculateItems
      })
      setAutoOpenQuotation(res.cqCommand.payload.quotation.document_uid)
      toastr.success('Quick quotation generated')
    } catch (err) {
      console.log('Error creating quick quotation', err)
      toastr.error('Error creating quick quotation ' + err.message)
    }
  }

  const handleCreateCustomQuotation = async () => {
    try {
      const data = getCreateQuotationData()
      const res = await props.onGenerateQuotationForOpportunity(data)
      setAutoOpenQuotation(res.cqCommand.payload.quotation.document_uid)
      toastr.success('Quotation generated')
    } catch (err) {
      console.log('Error creating quotation', err)
      toastr.error('Error creating quotation ' + err.message)
    }
  }

  const getPriceByCycle = (itemCycle, itemPrice) => {
    const quotationCycle = getFirstItemCycle()
    if (!quotationCycle) {
      toastr.error('Something wrong - Quotation cycle is not defined.')
      return
    }
    if (itemCycle > quotationCycle) {
      return itemPrice
    } else {
      return parseFloat(
        Number((quotationCycle / itemCycle) * itemPrice).toString()
      )
    }
  }

  const getFirstItemCycle = () => {
    if (quotation.items && quotation.items[0]) {
      return quotation.items[0].cycle
    } else {
      return ''
    }
  }

  const mapPriceOptionsToClient = (options) => {
    const result = options.reduce((ret, v) => {
      ret.push({
        price_table_uid: v.price_table_uid,
        price_table_item_uid: v.price_table_item_uid,
        pay_option: v.data.pay_option,
        value_net: v.data.hardware_price,
        subscription_net: v.data.prices[0].first_year_price_per_cycle,
        subscription_net_next_years:
          v.data.prices[0].next_years_price_per_cycle,
        cycle: v.data.prices[0].cycle
      })
      return ret
    }, [])
    return result
  }

  const findPriceOptions = async () => {
    try {
      const res = await props.client.query({
        query: priceTableItemsQL,
        variables: {
          query: {
            product_uid: newProductItem.product.product_uid,
            pay_option: quotation.pay_option
          }
        },
        fetchPolicy: 'no-cache'
      })
      return mapPriceOptionsToClient(res.data.find_price_options)
    } catch (err) {
      console.log('Error find price options', err)
      toastr.error('Error find price options' + err.message)
    }
  }

  const handleNext = async () => {
    setPriceOptions(null)
    const error = validateNewProductItem()
    if (error) {
      toastr.error(error)
    } else {
      setSelectedPrice(null)
      setLoadingPriceOptions(true)
      const options = await findPriceOptions()
      setPriceOptions(options)
      setLoadingPriceOptions(false)
      setCurrentPage(2)
    }
  }

  const handleFinish = async () => {
    const error = validateQuotationItems()
    if (error) {
      toastr.error(error)
      setErrorPrices(error)
    } else {
      setCreatingQuotaion(true)
      await handleCreateQuickQuotation()
    }
  }

  const setDefaultNewProductItem = () => {
    setNewProductItem({ quantity: defaultQuantity })
  }

  const handleSalesTypeChange = (newValue) => {
    setQuotation({ ...quotation, pay_option: newValue })
  }

  const handleContractLengthChange = (newValue) => {
    setQuotation({ ...quotation, contract_length: newValue })
  }

  const handleProductTypeChange = async (newValue) => {
    setSelectedProductType(newValue)
  }

  const handleProductItemChange = async (fieldName, newValue) => {
    const item = { ...newProductItem }
    if (fieldName === 'product_uid') {
      item[fieldName] = newValue.product_uid
      item.product = newValue
    } else {
      item[fieldName] = newValue
    }
    setNewProductItem(item)
  }

  const handleAddMoreProduct = () => {
    const error = validateNewItemPrice()
    if (error) {
      toastr.error(error)
    } else {
      setDefaultNewProductItem()
      setCurrentPage(1)
      setErrorPrices(null)
    }
  }

  const handleDeleteProduct = async (productUid) => {
    const updatedItems = [...quotation.items].filter(
      (v) => v.product_uid !== productUid
    )
    setQuotation({ ...quotation, items: updatedItems })
    if (newProductItem?.product?.product_uid === productUid) {
      setDefaultNewProductItem()
      setSelectedPrice(null)
    }
  }

  const handlePriceSelected = (priceTableItem) => {
    setErrorPrices(null)
    setSelectedPrice(priceTableItem.price_table_item_uid)
    const updatedItem = { ...newProductItem, ...priceTableItem }
    setQuotation({
      ...quotation,
      items: [
        ...quotation.items.filter(
          (v) => v.product.product_uid !== newProductItem.product.product_uid
        ),
        updatedItem
      ]
    })
    setNewProductItem({ ...newProductItem, ...priceTableItem })
  }

  const validateNewProductItem = () => {
    if (
      quotation.items.filter(
        (v) => v.product.product_uid === newProductItem.product_uid
      ).length > 0
    ) {
      return props.intl.formatMessage({
        id: 'crm.quotation.this_product_already_added',
        defaultMessage: 'This product already added'
      })
    }
    if (!quotation.pay_option) {
      return props.intl.formatMessage({
        id: 'crm.quotation.sales_type_cannot_be_empty',
        defaultMessage: 'Sales type cannot be empty'
      })
    }
    // if (!quotation.contract_length) {
    //   return props.intl.formatMessage({
    //     id: 'crm.quotation.contract_length_cannot_be_empty',
    //     defaultMessage: 'Contract length cannot be empty'
    //   })
    // }
    if (!newProductItem.product_uid) {
      return props.intl.formatMessage({
        id: 'crm.quotation.product_cannot_be_empty',
        defaultMessage: 'Product cannot be empty'
      })
    }
    if (!newProductItem.quantity) {
      return props.intl.formatMessage({
        id: 'crm.quotation.quantity_cannot_be_empty',
        defaultMessage: 'Quantity cannot be empty'
      })
    }
    return ''
  }

  const validateNewItemPrice = () => {
    if (
      newProductItem.product &&
      !newProductItem.price_table_item_uid &&
      priceOptions &&
      priceOptions.length > 0
    ) {
      return props.intl.formatMessage({
        id: 'crm.quotation.please_select_a_price_option',
        defaultMessage: 'Please select a price option'
      })
    }
    return ''
  }

  const validateQuotationItems = () => {
    console.log('validateQuotationItems', quotation, newProductItem)
    const invalidPrices = quotation.items.filter((v) => !v.price_table_item_uid)
    if (
      invalidPrices.length > 0 ||
      (newProductItem.product &&
        priceOptions &&
        priceOptions.length > 0 &&
        !newProductItem.price_table_item_uid)
    ) {
      return props.intl.formatMessage({
        id: 'crm.quotation.please_select_a_price_option',
        defaultMessage: 'Please select a price option'
      })
    }
    if (quotation.items.length === 0) {
      return props.intl.formatMessage({
        id: 'crm.quotation.quotation_items_cannot_be_empty',
        defaultMessage: 'Quotation items cannot be empty'
      })
    }
    return ''
  }

  const getSelectedSalesType = () => {
    return quotation.pay_option || ''
  }

  return (
    <div className="util-fullHeight util-flexColumn util-paddingMd">
      <div className="util-flexGrow util-paddingMd util-overflowAuto">
        <div className="util-flexRow util-fullHeight">
          <div className="util-flexGrow util-paddingMd util-marginRight">
            {currentPage === 1 && (
              <React.Fragment>
                <div className="LabeledField-value">
                  <FormattedMessage
                    id="crm.quotation.select_product_to_add_to_quotation"
                    defaultMessage="Select product to add to quotation"
                  />
                </div>
                <Spacer height="20px" />
                <div className="util-marginBottom">
                  <CrmDropdown
                    placeholder={
                      <FormattedMessage
                        id="crm.quotation.sales_type"
                        defaultMessage="Sales Type"
                      />
                    }
                    options={SalesTypeOptions.filter((v) => v.value !== 'all')}
                    input={{
                      value: quotation.pay_option,
                      onChange: handleSalesTypeChange
                    }}
                    disabled={quotation.items.length > 0}
                  />
                </div>
                <div className="util-marginBottom">
                  <CrmDropdown
                    placeholder={
                      <FormattedMessage
                        id="crm.ui.oppournity.form.contract_length"
                        defaultMessage="Contract Length"
                      />
                    }
                    options={contractLengthOptions}
                    input={{
                      value: quotation.contract_length,
                      onChange: handleContractLengthChange
                    }}
                    disabled={quotation.items.length > 0}
                  />
                </div>
                <div className="util-marginBottom">
                  <CrmTextInputNumber
                    placeholder={
                      <FormattedMessage
                        id="crm.quotation.number_of_vehicles"
                        defaultMessage="Number of vehicles"
                      />
                    }
                    input={{
                      value: newProductItem.quantity,
                      onChange: (e) => {
                        handleProductItemChange(
                          'quantity',
                          e.currentTarget.value
                        )
                      }
                    }}
                  />
                </div>
                <div className="">
                  <RadioInput
                    name="productType"
                    options={productTypeOptions}
                    input={{
                      value: selectedProductType,
                      onChange: handleProductTypeChange
                    }}
                  />
                </div>
                {selectedProductType && (
                  <div className="util-marginBottom">
                    <CrmProductsSelect
                      input={{
                        value: newProductItem.product
                          ? newProductItem.product.name
                          : '',
                        onChange: (value, item, data) => {
                          handleProductItemChange('product_uid', data)
                        }
                      }}
                      showProduct={
                        selectedProductType
                          ? selectedProductType === 'product'
                          : true
                      }
                      showAddOn={
                        selectedProductType
                          ? selectedProductType === 'addon'
                          : true
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            {currentPage === 2 && (
              <React.Fragment>
                {newProductItem.product && (
                  <div className="util-flexGrow">
                    <div className="LabeledField-value">
                      <FormattedMessage
                        id="crm.quotation.price_options"
                        defaultMessage="Price Options"
                      />
                      {' - '}
                      {newProductItem.product.name}
                    </div>
                    <Spacer height="20px" />
                    {priceOptions && priceOptions.length > 0 && (
                      <div className="row">
                        {priceOptions.map((v) => {
                          const selectedSalesType = getSelectedSalesType()
                          const quotationCycle = getFirstItemCycle()
                          const isMatchQuotationCycle = quotationCycle
                            ? v.cycle <= quotationCycle
                            : true
                          if (
                            (isMatchQuotationCycle &&
                              selectedSalesType === v.pay_option) ||
                            v.pay_option === 'all'
                          ) {
                            return (
                              <div
                                key={v.price_table_item_uid}
                                className="col-md-6 util-marginBottom"
                              >
                                <PriceOptionItem
                                  priceTableItem={v}
                                  handlePriceSelected={handlePriceSelected}
                                  selectedPrice={selectedPrice}
                                />
                              </div>
                            )
                          }
                        })}
                      </div>
                    )}
                    {priceOptions.length === 0 && (
                      <div className="util-flexColumn util-textLight">
                        <div>
                          <small>
                            <FormattedMessage
                              id="crm.quotation.no_matching_price_options_for"
                              defaultMessage="No matching price options for"
                            />
                            <span>{` ${newProductItem.product.name}`}</span>
                            <span>{` - ${quotation.pay_option} `}</span>
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {errorPrices && (
                  <div className="row util-marginTop">
                    <div className="col-md-12">
                      <CrmMessageBlock
                        message={errorPrices}
                        messageType="error"
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>

          <div style={{ width: '300px' }} className="util-marginLeft">
            {quotation.items.length > 0 && (
              <QuickQuotationSummary
                quotation={quotation}
                handleDeleteProduct={handleDeleteProduct}
                getPriceByCycle={getPriceByCycle}
                getFirstItemCycle={getFirstItemCycle}
              />
            )}
          </div>
        </div>
      </div>

      <div className="util-flexRowSpaceBetween util-paddingMd">
        <div>
          <div className="util-flexRow">
            <div>
              <CrmButton
                label={
                  <FormattedMessage
                    id="global.cancel"
                    defaultMessage="Cancel"
                  />
                }
                onClick={() => {
                  props.handleQuickQuotationClose()
                }}
              />
            </div>
            {currentPage === 1 && (
              <div className="util-marginLeft">
                <CrmButton
                  label={
                    <FormattedMessage
                      id="crm.ui.button.custom_quotation"
                      defaultMessage="Custom Quotation"
                    />
                  }
                  onClick={handleCreateCustomQuotation}
                  isSaving={props.isGeneratingQuotation}
                />
              </div>
            )}
          </div>
        </div>
        <div className="util-flexRow">
          {currentPage === 1 && (
            <React.Fragment>
              <div>
                <CrmButton
                  type="primary"
                  label={
                    <FormattedMessage
                      id="crm.quotation.select_price_option"
                      defaultMessage="Select Pricing Option"
                    />
                  }
                  onClick={handleNext}
                  enable={!loadingPriceOptions}
                  isSaving={loadingPriceOptions}
                />
              </div>
            </React.Fragment>
          )}
          {currentPage === 2 && (
            <React.Fragment>
              <div>
                <CrmButton
                  label={
                    <FormattedMessage
                      id="crm.quotation.add_another_product_or_addons"
                      defaultMessage="Add another product or add-ons"
                    />
                  }
                  onClick={handleAddMoreProduct}
                />
              </div>
              <div className="util-marginLeft">
                <CrmButton
                  type="primary"
                  label={
                    <FormattedMessage
                      id="crm.quotation.complete_quotation"
                      defaultMessage="Complete Quotation"
                    />
                  }
                  onClick={handleFinish}
                  enable={!creatingQuotaion}
                  isSaving={creatingQuotaion}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

const injectedQuickQuotationGenerator = injectIntl(QuickQuotationGenerator)

export default generateQuotationForOpportunityHoc(
  injectedQuickQuotationGenerator
)
