import { accountsListReducerFactory } from '../accounts-list-reducer'

const views = [
  {
    name: 'All My Prospects',
    code: 'all'
  },
  {
    name: 'Not Contacted By Me',
    code: 'not_contacted_by_me',
    filters: [{ code: 'never_contacted_by_me' }]
  },
  {
    name: 'No activity 1 month',
    code: 'over_1_month',
    filters: [
      {
        code: 'last_activity_before',
        filterParams: {
          date: {
            expression: {
              fn: 'date_add',
              params: { startDate: 'now', unit: 'month', interval: '-1' }
            }
          }
        }
      }
    ]
  }
  // {
  //   name: 'With lost opportunity',
  //   code: 'with_lost_opportunity'
  // }
]
const accountsProspectsReducer = accountsListReducerFactory('prospects', {
  views
})

export default accountsProspectsReducer
