import React from 'react'
import { toastr } from 'react-redux-toastr'
import { CrmButton, CrmInfoBlock } from '../../../../components/index'

export const CtMonthlyLeadDashboardWrapper = (props) => {
  const handleRefresh = () => {
    if (!props.analyticsContext.filters.activity_date) {
      toastr.warning('Select date range in Activity Date filter')
      return
    }
    props.onRefresh()
  }
  return (
    <div className="util-fullWidth">
      <div className="CrmWidget CrmWidget--fullHeight Widget CrmWidget--vMargin ">
        <div className="CrmWidget-header util-flexRow util-noMargin between-xs top-xs">
          <div className="util-flexColumn util-flexGrow">
            <div className="Widget-title CrmWidget-title util-flexRow" onDoubleClick={handleRefresh} style={{ cursor: 'pointer' }}>
              Monthly Lead Results (showing last 12 months{/* from selected date */})
            </div>
          </div>
          <div
            style={{
              minWidth: '100px',
              width: '15%'
            }}
            className="CrmWidget-toolboxWrapper"
          >
            {props.btnName &&
              <CrmButton
                className="CrmButton--secondary CrmButton--small"
                label={props.btnName}
                onClick={props.btnAction}
                isSaving={props.isLoading}
              />
            }
          </div>
        </div>
        <div className="Widget-content CrmWidget-content CrmWidget-content--padding">
          <div style={{ width: '100%', overflowY: 'auto' }}>
            {props.isActivityDateFilterMissing && (
              <CrmInfoBlock warning>
                You must select activity date filter for this dashboard.
              </CrmInfoBlock>
            )}
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

