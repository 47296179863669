import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { isEmptyData } from '../utils/common-utils.js'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { MasterFormStep1 } from './master-form-step1'
import { MasterFormStep2 } from './master-form-step2'
import { MasterFormStep3 } from './master-form-step3'
import { MasterFormStep4 } from './master-form-step4'
import { MasterFormAttributes } from './master-form-attributes'
import md5 from 'md5'
import uuidv4 from 'uuid'
import { MasterListNav } from './master-list'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tab_panel: {
    width: '100%'
  }
}))

const StepTabs = (props) => {
  const classes = useStyles()
  const [step, setStep] = React.useState(0)

  useEffect(() => {
    const st = props.master_customer?.step
      ? parseInt(props.master_customer.step)
      : 0
    setStep(st)
    return () => {}
  }, [props])

  const handleChange = (event, newValue) => {
    setStep(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={step}
        onChange={handleChange}
        className={classes.tabs}
      >
        <Tab label="Step 1" />
        <Tab label="Step 2" />
        <Tab label="Step 3" />
        <Tab label="Step 4" />
        <Tab label="Attributes" />
      </Tabs>
      <TabPanel className={classes.tab_panel} value={step} index={0}>
        <MasterFormStep1 {...props} />
      </TabPanel>
      <TabPanel className={classes.tab_panel} value={step} index={1}>
        <MasterFormStep2 {...props} />
      </TabPanel>
      <TabPanel className={classes.tab_panel} value={step} index={2}>
        <MasterFormStep3 {...props} />
      </TabPanel>
      <TabPanel className={classes.tab_panel} value={step} index={3}>
        <MasterFormStep4 {...props} />
      </TabPanel>
      <TabPanel className={classes.tab_panel} value={step} index={4}>
        <MasterFormAttributes {...props} />
      </TabPanel>
    </div>
  )
}

const GET_MASTER_CUSTOMER = gql`
  query master($master_uid: String!) {
    master(master_uid: $master_uid) {
      master_uid
      name
      cams_username
      details
      company_code
      step
      main_email
      config_completed
      template_code
      attribute_definitions {
        attribute_uid
        name
        details
        type
        level
        code
        data_type
        description
      }
      attributes {
        attribute_uid
        code
        name
        value
        data_type
      }
      instances {
        instance_uid
        name
        country_code
        currency_code
        timezone
      }
      profiles {
        name
      }
      users {
        full_name
      }
    }
  }
`

interface MasterCustomerFormInnerProps {
  masterUid: string
  initialValues: any
  master_customer: any
  isSaving: boolean
  handleCreateMaster: Function
  handleCreateFirstInstance: Function
  handleCreateAdminUser: Function
  handleCreateProfilesFromTemplate: Function
  handleUpdateAttributes: Function
  handleCompleteConfiguration: Function
  first_instance: any
  created_instances: any
  created_users: any
  created_profiles: any
  is_template: boolean
}

const MasterCustomerFormInner: React.SFC<MasterCustomerFormInnerProps> = (
  props
) => {
  console.log('MasterCustomerFormInner', props)

  return <div>{StepTabs(props)}</div>
}

interface MasterCustomerItemProps {
  masterUid: string
  isSaving: boolean
  is_new: boolean
  handleCreateMaster: Function
  handleCreateFirstInstance: Function
  handleCreateAdminUser: Function
  handleCreateProfilesTemplate: Function
  handleCreateProfilesFromTemplate: Function
  handleUpdateAttributes: Function
  handleCompleteConfiguration: Function
}

const MasterCustomerItem: React.SFC<MasterCustomerItemProps> = (props) => {
  const { data, error, loading, refetch } = useQuery(GET_MASTER_CUSTOMER, {
    variables: {
      master_uid: props.masterUid
    },
    skip: !props.masterUid || props.masterUid === 'new',
    fetchPolicy: 'no-cache'
  })

  const master_customer = data?.master ? { ...data.master } : {}
  const attributes = master_customer.attributes
  const format_data = !isEmptyData(attributes)
    ? attributes.reduce((ret, v) => {
        ret[v.code] = v.data_type === 'boolean' ? JSON.parse(v.value) : v.value
        return ret
      }, {})
    : null
  master_customer.attributes = format_data

  const first_instance = master_customer?.instances
    ? master_customer.instances[0]
    : undefined

  const is_template = master_customer?.template_code === 'template'

  const handleCreateMaster = async (form_values) => {
    const payload = { ...form_values }
    payload.master_uid = uuidv4()
    await props.handleCreateMaster(payload)
    refetch()
  }

  const handleCreateFirstInstance = async (form_values) => {
    const payload = { ...form_values }
    payload.master_uid = props.masterUid
    await props.handleCreateFirstInstance(payload)
    refetch()
  }

  const handleCreateAdminUser = async (form_values) => {
    const payload = { ...form_values }
    payload.master_uid = props.masterUid
    payload.instance_uid = first_instance?.instance_uid
    payload.password_hash = md5(form_values.password)
    delete payload.password
    await props.handleCreateAdminUser(payload)
    if (is_template) {
      await props.handleCreateProfilesTemplate()
    }
    refetch()
  }

  const handleUpdateAttributes = async (form_values) => {
    const payload = { ...form_values }
    payload.master_uid = props.masterUid

    const new_attributes = form_values.attributes
    if (new_attributes) {
      const custom_attributes = []
      Object.keys(new_attributes).map((key) => {
        custom_attributes.push({ code: key, value: new_attributes[key] })
      })
      payload.details = { custom_attributes: custom_attributes }
    }
    await props.handleUpdateAttributes(payload)
    refetch()
  }

  const handleCreateProfilesFromTemplate = async () => {
    await props.handleCreateProfilesFromTemplate()
    refetch()
  }

  const handleCompleteConfiguration = async () => {
    await props.handleCompleteConfiguration()
    refetch()
  }

  return (
    <React.Fragment>
      <div className="util-flexRow util-marginTop">
        <div>{MasterListNav()}</div>
        <div className="util-marginLeft">
          {props.is_new
            ? 'New Master'
            : master_customer?.name
            ? `Master : ${master_customer.name}`
            : '...'}
        </div>
        {master_customer?.config_completed && (
          <div className="util-marginLeft util-textGreen util-textBold">
            {' (Configuration Completed)'}
          </div>
        )}
        {is_template && (
          <div className="util-textBlue util-textBold">
            &nbsp; ({master_customer?.template_code})
          </div>
        )}
      </div>
      <br />
      <div className="util-marginTop">
        <MasterCustomerFormInner
          masterUid={props.masterUid}
          initialValues={master_customer}
          master_customer={master_customer}
          handleCreateMaster={handleCreateMaster}
          handleCreateFirstInstance={handleCreateFirstInstance}
          handleCreateAdminUser={handleCreateAdminUser}
          handleCreateProfilesFromTemplate={handleCreateProfilesFromTemplate}
          handleUpdateAttributes={handleUpdateAttributes}
          handleCompleteConfiguration={handleCompleteConfiguration}
          first_instance={first_instance}
          created_instances={master_customer.instances}
          created_users={master_customer.users}
          created_profiles={master_customer.profiles}
          isSaving={props.isSaving}
          is_template={is_template}
        />
      </div>
    </React.Fragment>
  )
}

export default MasterCustomerItem

// export const listUsersQuery = gql`
//     query users(
//       $master_uid: String!
//       $instance_uid: String!
//       $filter: JSON
//       $limit: Int
//     ) {
//       master(master_uid: $master_uid) {
//         master_uid
//         name
//         instance(instance_uid: $instance_uid) {
//           instance_uid
//           name
//           users(filter: $filter, limit: $limit) {
//             user_uid
//             full_name
//             is_active
//           }
//         }
//       }
//     }
//   `
//   const { data: dataUser, error: errorUser, loading: loadingUser } = useQuery(
//     listUsersQuery,
//     {
//       variables: {
//         master_uid: props.masterUid,
//         instance_uid: first_instance?.instance_uid,
//         limit: 100,
//         offset: 0,
//         filter: {}
//       },
//       skip:
//         (!props.masterUid || props.masterUid === 'new') &&
//         !first_instance?.instance_uid,
//       fetchPolicy: 'no-cache'
//     }
//   )

// const first_user = dataUser?.master?.instance?.users
// ? dataUser.master.instance.users[0]
// : undefined
