import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedTime, FormattedRelativeTime } from 'react-intl'
import moment from 'moment'

export const columnsTask = {
  type: 'Task',
  fields: [
    {
      name: 'name',
      description: 'Name'
    },
    {
      name: 'due_date',
      description: 'Due date'
    },
    {
      name: 'assignee_type',
      description: 'Assignee Type'
    },
    {
      name: 'account',
      description: 'Account',
      type: 'Account'
    },
    {
      name: 'assignee_user',
      description: 'Assigned User',
      type: 'User'
    },
    {
      name: 'assignee_group',
      description: 'Assigned Group',
      type: 'Group'
    },
    {
      name: 'assignee',
      description: 'Assignee',
      type: 'Owner'
    }
  ]
}
