import React from 'react'
import { toastr } from 'react-redux-toastr'
import { array, shape, func, string, object, oneOfType } from 'prop-types'
import CrmWidget from '../../../components/crm-widget.jsx'
import widgetFitmentsByUserAndSourceHoc from './widget-fitments-by-user-and-source-hoc'
import { CrmHeatmapChart } from 'crm-components'

class WidgetFitmentsByUserAndSource extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleCellClicked = cell => {
    console.log('handleCellClicked', cell)
    const filters = {
      ...this.props.analyticsContext.filters
    }
    filters.account__lead_campaign__lead_source__name =
      cell.account__lead_campaign__lead_source__name
    filters.owner_user_uid = cell.owner_user_uid
    //make the popup request same with report Fitments by Date and lead source
    filters.status_code = "completed"
    filters.lead_campaign = this.props.analyticsContext?.filters?.account?.lead_campaign
    this.props.onAnalyticsRangeSelected({ type: 'fitment', filters })
  }
  handleGetRawData = () => this.props.data

  handleRefresh = async () => {
    try {
      return await this.props.onRefresh()
    } catch (err) {
      toastr.error('Problem getting stats')
    }
  }

  render() {
    return (
      <CrmWidget
        title={
          this.props.title
            ? this.props.title
            : 'Fitments by Date and lead source'
        }
        subtitle=" "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-new-leads-by-status"
      >
        {!this.props.analyticsContext.filters.activity_date && (
          <span>You have to select activity date range filter </span>
        )}

        {this.props.data && (
          <CrmHeatmapChart
            xAxisField="account__lead_campaign__lead_source__name"
            yAxisField="owner__full_name"
            valueField="quantity"
            xSortType="string"
            xSortField="account__lead_campaign__lead_source__name"
            ySortField="owner__full_name"
            ySortType="owner__full_name"
            yLabel="Fitment Owner"
            data={this.props.data}
            onCellClicked={this.handleCellClicked}
          />
        )}
      </CrmWidget>
    )
  }
}

WidgetFitmentsByUserAndSource.propTypes = {
  analyticsContext: shape({}).isRequired,
  data: oneOfType([array, object]),
  onAnalyticsRangeSelected: func.isRequired,
  title: string,
  onRefresh: func.isRequired
}

WidgetFitmentsByUserAndSource.defaultProps = {
  title: undefined,
  data: undefined
}

export default widgetFitmentsByUserAndSourceHoc(WidgetFitmentsByUserAndSource)
