import React, { Component } from 'react'
import { func, bool } from 'prop-types'
import filesize from 'filesize'
import { reduxForm, Field } from 'redux-form'
import Dropzone from 'react-dropzone'
import {
  CrmFormGroup,
  CrmFormSection,
  CrmTextInput,
  CrmButton
} from 'crm-components'
import validate from 'crm-utils/validates'
import { LoadingIcon } from 'util-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'

class ImportToolForm extends Component {
  constructor(props) {
    super(props)
    this.state = { files: undefined }
    this.renderFormFiled = this.renderFormFiled.bind(this)
    this.showFileDropped = this.showFileDropped.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
    this.getButton = this.getButton.bind(this)
    this.state = {
      uploading: false
    }
  }

  renderFormFiled() {
    const fields = []

    fields.push(
      <div className="row">
        <div className="col-xs-12 col-md-12 col-lg-8">
          <p>
            <label>Filename:</label>
          </p>
          <Field
            name="file_name"
            component={CrmTextInput}
            type="text"
            placeholder="File name"
            validate={[validate.required]}
          />
        </div>
      </div>
    )

    fields.push(
      <div className="row">
        <div className="col-xs-12 col-md-12 col-lg-8">
          <p>
            <label>Description:</label>
          </p>
          <Field
            name="description"
            component={CrmTextInput}
            type="text"
            placeholder="description"
          />
        </div>
      </div>
    )

    fields.push(
      <div className="row">
        <div className="col-xs-12 col-md-12 col-lg-8">
          <p>
            <label>File upload:</label>
          </p>
          <Dropzone onDrop={this.handleDrop} multiple={false}>
            <p>{this.showFileDropped()}</p>
          </Dropzone>
        </div>
      </div>
    )

    return fields
  }

  showFileDropped() {
    if (this.state.files) {
      return (
        'Filename : ' +
        this.state.files[0].name +
        ', Filesize :' +
        filesize(this.state.files[0].size)
      )
    }

    return 'Try dropping some files here, or click to select files to upload.'
  }

  handleDrop(files) {
    this.setState({
      files
    })

    this.props.onUpload(files[0])
  }

  getButton() {
    return (
      <div>
        <CrmButton label="Save" type="submit" />
      </div>
    )
  }

  handleSubmit = async values => {
    this.setState({ ...this.state, files: undefined, uploading: true })
    await this.props.onSubmit(values)
    this.setState({ uploading: false })
  }

  render() {
    const { handleSubmit } = this.props
    const isEditing = this.props.editing || this.props.creating
    return (
      <div>
        <form
          onSubmit={handleSubmit(this.handleSubmit)}
          style={{ height: '100%' }}
        >
          <CrmFormGroup groupName="General">
            <CrmFormSection sectionName="File information">
              <fieldset
                disabled={!isEditing}
                className="Form-fieldset CrmForm-form-fieldset"
              >
                {this.renderFormFiled().map(field => field)}
              </fieldset>
            </CrmFormSection>
          </CrmFormGroup>
          <br />
          <div className="util-flexColumn util-bgRed util-textWhite util-paddingMd">
            <div className="">
              <FontAwesomeIcon icon={faExclamationCircle} />
              &nbsp; ACCEPTED DATE FORMAT ARE
            </div>
            <div className="util-marginSm">
              <div>DD/MM/YYYY (e.g. 01/02/2019 = 01 Feb 2019)</div>
              <div>DD-MM-YYYY (e.g. 01-02-2019 = 01 Feb 2019)</div>
              <div>YYYY-MM-DD (e.g. 2019-02-01 = 01 Feb 2019)</div>
              <div>YYYY/MM/DD (e.g. 2019/02/01 = 01 Feb 2019)</div>
            </div>
          </div>
          <br />
          <div className="row">{!this.state.uploading && this.getButton()}</div>
        </form>
        {this.state.uploading && (
          <div className="CrmWidget--loading">
            <LoadingIcon />
            <div>
              <strong>Your file is uploading, please wait...</strong>
            </div>
          </div>
        )}
      </div>
    )
  }
}

ImportToolForm.propTypes = {
  creating: bool.isRequired,
  editing: bool.isRequired,
  onSubmit: func.isRequired,
  onUpload: func.isRequired,
  handleSubmit: func.isRequired
}

const ImportToolReduxForm = reduxForm({ form: 'crmSettingImportTool' })(
  ImportToolForm
)
export default ImportToolReduxForm
