/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { func, bool, shape } from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import { DatePicker, RadioInput, Spacer } from 'util-components'
import accountReassignHoc, {
  accountReassignHocPropTypes,
  ownerRoles
} from 'crm-modules/accounts/hoc/account-reassign-hoc'
import {
  CrmButton,
  CrmUserSelect,
  CrmDayPickerInput,
  CrmDropdown,
  CrmInfoBlock
} from 'crm-components'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

const OPTIONS = [
  { label: 'Skip', value: 'skip' },
  { label: 'Override', value: 'override' }
]
class ReassignWidget extends React.PureComponent {
  handleClickReassign = async () => {
    if (!this.props.reassignAccountUser) {
      toastr.error(
        this.props.intl.formatMessage({
          id: 'crm.ui.account.please_select_new_user',
          defaultMessage: 'Please select new user'
        })
      )
      return
    }
    if (!this.props.roleCode) {
      toastr.error(
        this.props.intl.formatMessage({
          id: 'crm.ui.account.please_select_role',
          defaultMessage: 'Please select role'
        })
      )
      return
    }
    try {
      await this.props.onReassignAccount(null, this.props.account)
      toastr.success(
        this.props.intl.formatMessage({
          id: 'crm.ui.account.additional_owner_has_been_added',
          defaultMessage: 'Additional owner has been added'
        })
      )
    } catch (err) {
      toastr.error(
        this.props.intl.formatMessage({
          id: 'crm.ui.account.there_was_a_problem_saving_additional_owner',
          defaultMessage: 'There was a problem saving new acccount owner'
        })
      )
      console.error(err)
    }
    if (this.props.onSaved) {
      this.props.onSaved()
    }
    this.props.onClose()
  }

  componentDidMount() {
    if (this.props.onBulkComponentInit) {
      this.props.onBulkComponentInit(this)
    }
    if (this.props.showOnlyOwnerType) {
      this.props.onOwnerTypeChange(this.props.showOnlyOwnerType)
    }
    if (this.props.selectedRoleCode) {
      this.props.onRoleCodeChange(this.props.selectedRoleCode)
    }
    if (this.props.selectedOwner) {
      if (this.props.selectedOwner.validFrom) {
        this.props.onNewOwnerValidFromChange(this.props.selectedOwner.validFrom)
      }
      if (this.props.selectedOwner.userUid) {
        this.props.onReassignAccountUserSelected(
          this.props.selectedOwner.userUid
        )
      }
    }
  }

  getOwnerRoleOptions = () => {
    return ownerRoles
  }

  render() {
    const isNew = this.props.isNew
    const isEditing = !isNew
    const ownerRoleOptions = this.getOwnerRoleOptions()
    const defaultOwnerRoleOption =
      ownerRoleOptions.filter(v => v.value === this.props.roleCode).length > 0
        ? this.props.roleCode
        : null
    return (
      <div className="util-paddingSm">
        <div className="CrmForm">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <CrmUserSelect
                name="name"
                input={{
                  value: this.props.newOwnerUserUid,
                  onChange: this.props.onReassignAccountUserSelected
                }}
                permissionCode="all"
                placeholder={
                  <FormattedMessage
                    id="crm.ui.account.select_additional_owner"
                    defaultMessage="Select additional owner"
                  />
                }
              />
            </div>

            <div className="col-xs-12 col-md-6">
              <CrmDropdown
                disabled={isEditing}
                options={ownerRoleOptions}
                placeholder={
                  <FormattedMessage
                    id="crm.reassign.role"
                    defaultMessage="Role"
                  />
                }
                input={{
                  value: defaultOwnerRoleOption,
                  onChange: this.props.onRoleCodeChange
                }}
              />
            </div>
          </div>
          <div className="util-marginTop util-flexRowRight">
            {!this.props.bulkMode && (
              <CrmButton
                enable={!this.props.isSaving}
                label={
                  <FormattedMessage
                    id="crm.ui.account.reassign.reassign"
                    defaultMessage="Reassign"
                  />
                }
                isSaving={this.props.isSaving}
                type="primary"
                onClick={this.handleClickReassign}
              />
            )}
          </div>

          <Spacer height="10px" />

          {this.props.isManager && this.props.isShowDateSelect && (
            <CrmInfoBlock info>
              <div className="row">
                <div className="col-md-6">
                  <small>
                    <FormattedMessage
                      id="crm.reassign.with_your_manager_permission_you_can_modify_date_when_ownership_change_took_place"
                      defaultMessage="With your Manager permission you can modify date when
                        ownership change took place"
                    />
                    {':'}
                  </small>
                </div>
                <div className="col-md-6">
                  <DatePicker
                    placeholder={
                      <FormattedMessage
                        id="crm.reassign.valid_from"
                        defaultMessage="Valid From"
                      />
                    }
                    input={{
                      value: this.props.newOwnerValidFrom,
                      onChange: this.props.onNewOwnerValidFromChange
                    }}
                  />
                </div>
              </div>
            </CrmInfoBlock>
          )}

          {this.props.bulkMode && (
            <React.Fragment>
              <label className="util-paddingSm">
                If additional owner exists
              </label>
              <RadioInput
                options={OPTIONS}
                input={{
                  value: this.props.optionOverride,
                  onChange: this.props.onChangeSelectBulkOverrideOption
                }}
                name="bulkOptions"
              />
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

ReassignWidget.propTypes = {
  onClose: func.isRequired,
  isOpen: bool.isRequired,
  onBulkComponentInit: func,
  ...accountReassignHocPropTypes,
  bulkMode: bool,
  accountPermissionsContext: shape({}).isRequired,
  isShowDateSelect: bool
}

ReassignWidget.defaultProps = {
  onBulkComponentInit: undefined,
  bulkMode: false,
  isShowDateSelect: true
}

export default injectIntl(
  accountReassignHoc(
    connect(null, dispatch => ({
      doPush: url => dispatch(push(url))
    }))(withAccountPermissionsContextHOC(ReassignWidget))
  )
)
