/* eslint-disable camelcase */
import { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const CHANGE_AGENT_STATUS = 'CHANGE_AGENT_STATUS'
export const AGENT_STATUS_CHANGED = 'AGENT_STATUS_CHANGED'

const initialState = {
  currentStatus: {
    agentStatusCode: undefined,
    startedAt: undefined,
    currentSession: undefined
  }
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case AGENT_STATUS_CHANGED: {
      return { ...state, currentSession: action.payload.userSession }
    }
    default:
      return state
  }
}

export const getCurrentUserSession = state => state.crm.userSession.currentSession

export const changeUserStatus = newStatusCode => ({
  type: CHANGE_AGENT_STATUS,
  payload: {
    newStatusCode
  }
})

export function getInstanceNameAndCode() {
  const crmInstance = JSON.parse(window.localStorage.getItem('crm_instance'))
  const InstanceName = crmInstance && crmInstance.name ? crmInstance.name : 'Unknown'
  const InstanceCode = crmInstance && crmInstance.country_code ? crmInstance.country_code : 'UNK'
  return { InstanceName, InstanceCode }
}

type InstanceObject = {
  instance_uid: string
  name: string
  parameters: any
}

export function getInstance(): InstanceObject {
  return JSON.parse(window.localStorage.getItem('crm_instance'))
}

const USER_QUERY_INFORMATION_QL = gql`
  query user($userUid: String!) {
    user(user_uid: $userUid) {
      contract {
        organization_unit {
          organization_unit_uid
          name
        }
        profile {
          dashboard_code
        }
      }
    }
  }
`
export const useGetUserInformation = (userUid: String) => {
  useEffect(() => {
    refetch()
  }, [userUid])

  const { data, loading, refetch } = useQuery(USER_QUERY_INFORMATION_QL, {
    variables: {
      userUid
    },
    skip: !userUid,
    fetchPolicy: 'no-cache'
  })

  const getOrganizationUnit = () => ({
    organization_unit_uid: data?.user?.contract?.organization_unit?.organization_unit_uid,
    name: data?.user?.contract?.organization_unit?.name
  })

  const getDashboardCode = () => data?.user?.contract?.profile?.dashboard_code

  return { loading, getOrganizationUnit, getDashboardCode }
}

export function getCurrencyCode() {
  const crmInstance = JSON.parse(window.localStorage.getItem('crm_instance'))

  if (crmInstance?.currency_code) {
    return crmInstance?.currency_code
  }

  return null
}
