import React from 'react'
import { string, func, bool, oneOfType, shape } from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'util-components'

const Button = props => {
  let color = ''
  if (props.red) color = 'Button--red'
  if (props.green) color = 'Button--green'
  if (props.blue) color = 'Button--blue'
  if (props.white) color = 'Button--white'
  if (props.orange) color = 'Button--orange'

  const buttonProps = {
    disabled: props.disabled,
    className: `Button ${props.small ? 'Button--small' : ''} ${
      props.className
    } ${color} ${props.square ? 'Button--square' : ''} ${
      props.to ? 'Button--link' : ''
    } ${props.progress ? 'Button--progress' : ''}`,
    onClick: props.onClick,
    style: props.style
  }

  const buttonLabel =
    typeof props.label === 'string' ? (
      <FormattedMessage
        id={`button.label.${props.label}`}
        defaultMessage={props.label}
      />
    ) : (
      props.label
    )

  return props.to ? (
    <Link {...buttonProps} to={props.to} target={props.target}>
      <Icon name={props.icon} /> {buttonLabel}
    </Link>
  ) : (
    <button id={props.id} {...buttonProps}>
      <Icon name={props.icon} /> {buttonLabel}
    </button>
  )
}

Button.propTypes = {
  id: string,
  icon: string,
  label: string,
  className: string,
  onClick: func,
  blue: bool,
  red: bool,
  green: bool,
  white: bool,
  orange: bool,
  square: bool,
  disabled: bool,
  small: bool,
  to: oneOfType([string, shape({})]),
  target: string,
  progress: bool,
  style: shape({})
}

Button.defaultProps = {
  id: '',
  className: '',
  icon: '',
  label: null,
  transparent: false,
  orange: false,
  blue: false,
  green: false,
  red: false,
  white: false,
  disabled: false,
  square: false,
  small: false,
  to: null,
  onClick: () => null,
  target: '',
  progress: false,
  style: undefined
}

export default Button
