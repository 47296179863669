import React from 'react'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import columns from './forecast-columns'
import gql from 'graphql-tag'

const FORECASTS = gql`
  query forecasts(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
  ) {
    forecasts(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count
      data {
        forecast_uid
        user_uid
        status
        month_date
        items {
          quantity
        }
        user {
          full_name
        }
      }
    }
  }
`


const qlOptions = {
  options: ownProps => {
    const { filter } = ownProps
    return {
      variables: {
        filter: filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.forecasts : []
  })
}

const Table: any = genericTableFactory({
  query: FORECASTS,
  qlOptions
})

export const ForecastListInner = (props) => {
  const getColumn = () => {
    let resColumns = [...columns]
    if (props.isFromViewPageList) {
      delete resColumns[2]
    }
    return resColumns
  }
  return (
    <Table
      filter={props.filter}
      style={{ width: '100%' }}
      columns={getColumn()}
      className="react-table -striped -highlight"
      tableName="forecasts"
      translation
    />
  )
}
