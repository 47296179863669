import React, { useState } from 'react'
import { qlqListUsersQuery } from 'crm-data/users'
import { FiltersOptionList } from '../tools'
import { Query } from 'react-apollo'
import { useCrmContext } from '@cartrack-crm/crm-core'
import Icon from '../../../../../util-components/icon'
export const UsersSelect = props => {
  const crmContext = useCrmContext()
  const [showInactive, setShowInactive] = useState(false)
  const handleShowInactive = () => {
    setShowInactive(!showInactive)
  }
  const toolboxRenderer = () => (
    <div>
      <div>
        <Icon
          name="archive"
          title="Show inactive users"
          style={{
            position: 'absolute',
            top: '3px',
            right: '22px',
            opacity: showInactive ? '1.0' : '0.3',
            fontSize: '14px'
          }}
          onClick={handleShowInactive}
        />
      </div>
    </div>
  )
  const selectedDepartmentTypeUid =
    props.filterValues && props.filterValues.department_uid && props.filterValues.department_uid.value
      ? Array.isArray(props.filterValues.department_uid.value)
        ? props.filterValues.department_uid.value[0]
        : props.filterValues.department_uid.value
      : null
  const variables = {
    master_uid: crmContext.master.master_uid,
    instance_uid: crmContext.instance.instance_uid,
    limit: 2500
  }
  return (
    <Query query={qlqListUsersQuery} variables={variables}>
      {({ loading, data }) => {
        const users = data?.master?.instance?.user_list_qm_paged?.data
          ? [...data.master.instance.user_list_qm_paged.data]
          : []
        if (users) {
          users.sort((a, b) => (a.full_name && b.full_name ? a.full_name.localeCompare(b.full_name) : 0))
        }
        const usersOptions = users
          ? users
              .filter(v => {
                return selectedDepartmentTypeUid ? v.department_uid === selectedDepartmentTypeUid : v
              })
              .map(t => ({
                value: t.user_uid,
                name: t.full_name,
                is_active: t.is_active
              }))
          : []
        return (
          <FiltersOptionList
            enableSearch={true}
            isLoading={loading}
            options={usersOptions}
            onFilterValueChanged={props.onFilterValueChanged}
            selectedValue={
              props.filterValues && props.filterValues.user && props.filterValues.user.user_uid
                ? props.filterValues.user.user_uid.value
                : undefined
            }
            toolboxRenderer={toolboxRenderer}
            showInactive={showInactive}
          />
        )
      }}
    </Query>
  )
}
