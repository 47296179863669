import React from 'react'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import cloneDeep from 'clone-deep'
import { getActivityTypes } from 'crm-duxs/activities-reducer'
import { qlqAccountTasks } from 'crm-data/tasks'

import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { updateActivitiesStoreAfterCreated } from 'crm-data/activities-store-utils'

import {
  qlqActivityWithFields,
  qlmUpdateActivity,
  qlqAccountActivities,
  qlmActivitiesCommand
} from 'crm-data/activities'
import {
  parseActivityToServer,
  validateActivity,
  validateCreateActivity
} from 'crm-data/activities-utils'
import { qlqProcessWithFields } from 'crm-data/processes-fragments'
import {
  qlqfetchAccount,
  qlqFetchAccountProcesses,
  qlqFetchAccountOpportunities,
  qlqAccountStatusAndOwners
} from 'crm-data/accounts'

const qlOptions = {
  options: ownProps => ({
    variables: { activity_uid: ownProps.activityUid }
  }),
  skip: ownProps => ownProps.activityUid === 'new' || !ownProps.activityUid
}
const activityPageHoc = WrappedComponent => {
  class ActivityPageWrapper extends React.PureComponent {
    static propTypes = {
      qlmUpdateActivity: func.isRequired,
      activityUid: string.isRequired,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      isEditing: false
    }
    constructor(props) {
      super(props)
      this.state = {
        isEditing: props.activityUid === 'new',
        isSaving: false
      }
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    getAvailableStatuses = activity => {
      let availableStatuses

      const activityType =
        activity && activity.activity_type_uid && this.props.activityTypes
          ? this.props.activityTypes.find(
              at => at.activity_type_uid === activity.activity_type_uid
            )
          : undefined
      if (activityType && activityType.activity_type_statuses) {
        availableStatuses = activityType.activity_type_statuses
      }

      if (
        availableStatuses &&
        this.state.activity &&
        (!this.state.activity.activity_uid ||
          this.state.activity.activity_uid === 'new')
      ) {
        availableStatuses = availableStatuses.filter(s => s.is_initial)
      }
      return availableStatuses
    }
    doUpdateActivity = async (activity, params, refetchQueries) => {
      if (this.state.isSaving) {
        return
      }
      try {
        validateActivity(activity)
        await this.setState({ isSaving: true })
        const variables = {
          activity_uid: activity.activity_uid,
          activity: parseActivityToServer(activity)
        }
        await this.props.qlmUpdateActivity({
          variables,
          refetchQueries
        })
      } catch (err) {
        let message = 'Problem updating activity: '
        if (err && err instanceof Object && err.message) {
          message += err.message
        }
        console.log('Error:', message)
        throw err
      }
      this.setState({ isSaving: false })
    }

    doCreateActivity = async pactivity => {
      const activity = { ...pactivity }
      console.log('ActivityFormHOC.doCreateActivity', pactivity, activity)
      if (this.state.isSaving) {
        return
      }
      if (
        activity &&
        activity.activity_type &&
        activity.activity_type.medium_code !== 'meeting' &&
        !activity.start_time
      ) {
        activity.start_time = new Date()
      }
      try {
        if (!activity.participants) {
          activity.participants = []
        }
        if (
          activity.participants.find(p => p.role_code === 'performer') ===
            undefined &&
          activity.primary_performer_user_uid
        ) {
          activity.participants.push({
            user_uid: activity.primary_performer_user_uid,
            role_code: 'performer',
            is_primary: true
          })
        }
        activity.completed_time = new Date()
        validateActivity(activity)
        validateCreateActivity(activity)

        const command = {
          type: 'activity.create',
          aggregate_type: 'activity',
          aggregate_uid: this.props.newObjectUid,
          payload: {
            activity: parseActivityToServer(activity)
          }
        }
        const refetchQueries = [
          {
            query: qlqAccountTasks,
            variables: { account_uid: activity.account_uid }
          },
          {
            query: qlqFetchAccountOpportunities,
            variables: { account_uid: activity.account_uid }
          },
          {
            query: qlqFetchAccountProcesses,
            variables: { account_uid: activity.account_uid }
          },
          {
            query: qlqAccountStatusAndOwners,
            variables: { account_uid: activity.account_uid }
          }
        ]

        if (activity.process_uid) {
          refetchQueries.push({
            query: qlqProcessWithFields,
            variables: { process_uid: activity.process_uid },
            partialRefetch: true
          })
        } else {
          refetchQueries.push({
            query: qlqFetchAccountProcesses,
            variables: { account_uid: activity.account_uid },
            partialRefetch: true
          })
        }

        const update = updateActivitiesStoreAfterCreated({
          variables: { account_uid: activity.account_uid }
        })

        const res = await this.props.onRunCommand(
          command,
          {
            refetchQueries,
            update
          },
          qlmActivitiesCommand
        )

        console.log('res activity.create Command', res)
        this.setState({ isSaving: false, errorMessage: undefined })
        return res
      } catch (err) {
        console.log('Create activity catch', err)
        let message = 'Problem updating activity: '
        if (err && err instanceof Object && err.message) {
          message += err.message
        }
        console.log('Message', message)
        this.setState({ isSaving: false, errorMessage: message })
        throw err
      }
    }

    handleSubmit = async activity => {
      console.log('Activity Form QL handleSubmit', activity)
      const lactivity = cloneDeep(activity)
      console.log('lactivity', lactivity)
      if (lactivity.dispositionDetailsForm) {
        lactivity.disposition_details = lactivity.dispositionDetailsForm
      }
      const refetchQueries = [
        {
          query: qlqAccountActivities,
          variables: { account_uid: activity.account_uid }
        }
      ]

      if (lactivity.activity_uid) {
        this.doUpdateActivity(lactivity, {}, refetchQueries)
      } else {
        // Check if should init stauts
        if (!lactivity.activity_status_uid) {
          const available = this.getAvailableStatuses(activity)
          if (available) {
            const initial = available.filter(s => s.is_initial)
            if (initial && initial.length > 0) {
              lactivity.activity_status_uid = initial[0].activity_status_uid
            }
          }
        }
        return this.doCreateActivity(lactivity, {}, refetchQueries)
      }
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          isEditing={this.state.isEditing}
          isSaving={this.props.isSaving}
          onEditAccount={this.handleEditAccount}
          onSubmit={this.handleSubmit}
          getAvailableStatuses={this.getAvailableStatuses}
        />
      )
    }
  }

  function mapStateToProps(state) {
    return {
      activityTypes: getActivityTypes(state)
    }
  }
  return crmGenericCommandHoc(connect(mapStateToProps)(ActivityPageWrapper))
}

const activityPageQlHoc = compose(
  graphql(qlqActivityWithFields, {
    ...qlOptions,
    name: 'activityQl'
  }),
  graphql(qlqfetchAccount, {
    options: ownProps => ({
      variables: {
        account_uid: ownProps.accountUid
      }
    }),
    props: ({ accountQl, ownProps }) => ({
      ...ownProps,
      account: accountQl ? accountQl.account : undefined
    }),
    name: 'accountQl'
  }),
  graphql(qlmUpdateActivity, {
    name: 'qlmUpdateActivity',
    options: ownProps => ({
      refetchQueries: [
        {
          query: qlqAccountActivities,
          variables: { account_uid: ownProps.account_uid }
        }
      ]
    })
  }),
  activityPageHoc
)
export default activityPageQlHoc
