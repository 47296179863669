import moment from 'moment'
import React, { useState } from 'react'
import { ActivityItemContiner } from './shared.styled'
import { CrmActivityItem, CrmActivityAction, CrmActivityNotation, CrmActivityCategory } from '.'
import CrmActivityContent from './activity-content-live-call'
import { CrmButtonGroup, CrmButton } from '@cartrack-crm/ui'

const ActivityLiveCall = props => {
  const [isShowRecord, setShowRecord] = useState(false)
  const { activity } = props
  const isPendingLiveCall = !activity.completed_time
  const buttons = []
  if (activity?.call_log?.voice_record_uid) {
    buttons.push(
      <CrmButton
        key={buttons.length}
        icon={isShowRecord ? 'pause' : 'play'}
        onClick={() => setShowRecord(!isShowRecord)}
      />
    )
  }
  buttons.push(<CrmButton key={buttons.length} icon="pencil-alt" onClick={props.onEditActivity} />)
  return (
    <ActivityItemContiner>
      <CrmActivityNotation activity_type={activity.activity_type} direction={activity.direction} />
      <CrmActivityItem isSuccess={activity.activity_status?.is_success}>
        <CrmActivityCategory
          label={activity.activity_type.name}
          sequence={activity.seq_same_type_success_user}
          createdTime={activity.created_time}
          isCustomer={props.isCustomer}
        />
        <CrmActivityContent
          {...activity}
          isShowRecord={isShowRecord}
          dialerUrl={props.dialerUrl}
          setShowRecord={setShowRecord}
        />
        <CrmActivityAction
          showActionsOnHover
          isPendingLiveCall={isPendingLiveCall}
          activityStatus={activity.activity_status}
          activityType={activity.activity_type?.medium_code}
          calling={{ started_time: activity.start_time }}
        >
          <CrmButtonGroup small>{buttons}</CrmButtonGroup>
        </CrmActivityAction>
      </CrmActivityItem>
    </ActivityItemContiner>
  )
}

export default ActivityLiveCall
