/* eslint-disable camelcase */
import gql from 'graphql-tag'

import { accountProcessesFragment } from './processes-fragments'
import { opportunitiesFragment, contractsFragment } from './opportunities'

export const qlAddressAllFields =
  ' address_uid account_uid city street province suburb is_valid street_number house_number postcode country_code address_kind town remark location line1 line2 is_primary'

export const fragmentAddressAllFields = `
fragment AddressAllFields on Address { 
  ${qlAddressAllFields}
}`

export const fragmentAccountAddresses = `
fragment AccountAddresses on Account {
    account_uid
    addresses { 
       ...AddressAllFields
      }
}
${fragmentAddressAllFields}`

export const qlContactDetailsAllFields =
  ' contact_details_uid account_uid instance_uid address_uid country_code kind name value remarks is_valid validated_time validated_by_user { full_name } validated_result '

export const qlCampaignAllFields =
  ' campaign_uid instance_uid name code description closed_at start_date end_date marketing_campaign_uid transaction_type_code'

export const qlMarketPotentialWithFields = ` market_potential { size other_size } `

export const qlAccountAllFields = ` 
  permissions { user_uid permission_code filters access_mode ownership_rule module profile_uid } 
  account_uid cams_username name 
  kind 
  is_deleted 
  merged_onto_account_uid
territory_uid remark name_local lead_source_type_uid lead_campaign_uid meta
lead_in_date lead_in_time created_time registration_number validated_date
created_by_user { user_uid login full_name } 
website phone {contact_details_uid account_uid country_code value validated_time validated_by_user { full_name } } 
mobile_phone {contact_details_uid account_uid country_code value validated_time validated_by_user { full_name } }
email {contact_details_uid  account_uid value validated_time validated_by_user { full_name } } 
owners additional_owners currentSalesProcess lastSalesProcess version category validated_date 
`

export const accountStatusFields = ` account_status_code valid_from owner_user { user_uid full_name }  owner_pool { name } marketing_campaign_uid 
  marketing_campaign { 
    name
  }`

export const qlPersonAllFields = ' account_person_uid account_uid name description department kind is_valid '

export const qlqAccountPersonGet = gql`
query account_person_get($account_person_id: String!) {
  account_person_get(account_person_id: $account_person_id) {
   ${qlPersonAllFields}
  }
}`

export const mutationAccountPersonCreate = gql`
mutation account_person_create($account_uid: String!, $account_person: AccountPersonIn!) {
  account_person_create(account_uid: $account_uid, account_person: $account_person) {
    ${qlPersonAllFields}
  }
}`
export const mutationAccountPersonUpdate = gql`
mutation account_person_update( $account_person: AccountPersonIn!) {
  account_person_update(account_person: $account_person) {
    ${qlPersonAllFields}
  }
}`

export const qlqAccountPersonList = gql`
query account_person_list($account_uid: String!) {
  account_person_list(account_uid: $account_uid) {
   ${qlPersonAllFields}
  }
}
`

const fragmentAccountSalesStatus = `
fragment AccountSalesStatus on Account {
  account_uid
  sales_status
  sales_status_code
  currentSalesProcess
  lastSalesProcess  
}`

const fragmentAccountOwners = `
fragment AccountOwners on Account {
  owners
}`

export const accountStatusAndOwnersFragment = `
fragment AccountStatus on Account {
  ...AccountSalesStatus
  ...AccountOwners
}
${fragmentAccountSalesStatus}
${fragmentAccountOwners}
`

export const qlqAccountStatusAndOwners = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      ...AccountSalesStatus
      ...AccountOwners
    }
  }
  ${fragmentAccountSalesStatus}
  ${fragmentAccountOwners}
`

const qlAccountAllData = `  
${qlAccountAllFields}
...AccountAddresses

contact_details {${qlContactDetailsAllFields} }
persons {${qlPersonAllFields} contact_details {${qlContactDetailsAllFields} } }
lead_campaign { ${qlCampaignAllFields} }
territory { territory_uid name }
industry { name dictionary_uid }
merged_to_account {
  account_uid
  name
  lead_in_time
}
${qlMarketPotentialWithFields}
...AccountProcesses
...AccountOpportunities
duplicate_leads {
  account_uid
  name
  lead_in_time
  lead_campaign { name }
}
tags {
  code name
}
`

export const buildQLGetAccountWithFields = (fields, fragments = undefined) => {
  return `query account($account_uid: String!) 
  {
    account(account_uid: $account_uid) {
      account_uid
      ${fields}
     }
}
${fragments || ''}
`
}

export const qlRefetchAccountStatus = buildQLGetAccountWithFields(
  ` validated_date 
  ...AccountSalesStatus `,
  ` ${fragmentAccountSalesStatus} `
)

export const qFetchAccount = `query account($account_uid: String!) 
  {
    account(account_uid: $account_uid) {
      ${qlAccountAllData}
      ...AccountContracts
      ...AccountSalesStatus
    }
  }
  ${opportunitiesFragment}
  ${contractsFragment}
  ${accountProcessesFragment}
  ${fragmentAccountAddresses}
  ${fragmentAccountSalesStatus}
`

export const qlqfetchAccount = gql(qFetchAccount)

export const qlFetchAccountOwners = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      owners
      additional_owners
    }
  }
`
export const qlqFetchAccountAddresses = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      ...AccountAddresses
    }
  }
  ${fragmentAccountAddresses}
`

export const qlqFetchAccountSalesStatus = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      ...AccountSalesStatus
    }
  }
  ${fragmentAccountSalesStatus}
`

export const qlqFetchAccountOpportunities = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      ...AccountOpportunities
    }
  }
  ${opportunitiesFragment}
`

export const qlqFetchAccountProcesses = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      ...AccountProcesses
    }
  }
  ${accountProcessesFragment}
`

export const qlqfetchAccountContactDetails = gql`
query account($account_uid: String!) {
  account(account_uid: $account_uid) {
    account_uid
    contact_details {${qlContactDetailsAllFields} }
    persons {${qlPersonAllFields} contact_details {${qlContactDetailsAllFields} } }
  }
}
`

export const qlqfetchAccountStatus = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
    }
  }
`

export const qlqfetchAccountTags = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      tags {
        code
        name
      }
    }
  }
`
export const qlqfetchAccountPermissions = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      permissions {
        user_uid
        permission_code
        filters
        access_mode
        ownership_rule
        module
        profile_uid
      }
    }
  }
`

export const qlqfetchAccountStatusAndProcesses = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      ...AccountProcesses
      ...AccountSalesStatus
      owners
    }
  }
  ${accountProcessesFragment}
  ${fragmentAccountSalesStatus}
`

export const qlmVerifyContact = gql`
mutation  verifyContact($contact:ContactDetailsIn!, $verified: Boolean!) 
{ verifyContact(contact: $contact, verified: $verified) { ${qlContactDetailsAllFields} }  } `

export const qlqAccountsListQM = gql`
  query accounts_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
    $groupBy: [String]
  ) {
    accounts_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
      groupBy: $groupBy
    ) {
      data {
        account_name
        account_uid
        contract_email
        contract_phone_number
        assigned_time
        city
        current_step_type_code
        current_step_type_name
        duplicate_of_account_uid
        first_account_touch_time
        first_activity_date
        is_deleted
        kind
        last_activity_date
        last_activity_note
        last_sales_process
        lead_in_date
        lead_in_date_month
        lead_in_time
        lead_source_name
        lead_source_type_name
        object_permissions
        opportunities {
          quantity
          status_code
        }
        owner_group_uid
        owner_name
        owner_pool_name
        owner_user_name
        owner_user_uid
        sale_campaign_name
        sales_status_code
        campaign {
          name
        }
        market_potential {
          size
        }
      }
      count
    }
  }
`
export const AccountListPageQL = gql`
  query account_list_page(
    $aggregate: [JSON]
    $filter: JSON
    $groupBy: [String]
    $limit: Int
    $load_data: [String]
    $offset: Int
    $sort: [JSON]
  ) {
    account_list_page(
      aggregate: $aggregate
      filter: $filter
      groupBy: $groupBy
      limit: $limit
      load_data: $load_data
      offset: $offset
      sort: $sort
    ) {
      data {
        account_name
        account_uid
        assigned_time
        city
        first_account_touch_time
        instance_uid
        is_deleted
        kind
        last_activity_note
        last_activity_time
        lead_campaign_name
        lead_campaign_uid
        lead_in_date
        lead_in_time
        lead_source_name
        lead_source_uid
        name_local
        owner_full_name
        owner_group_name
        owner_group_uid
        owner_user_uid
        sales_campaign_name
        sales_campaign_uid
        sales_status_code
        opportunities {
          quantity
          status_code
        }
        market_potential {
          size
        }
      }
      count
    }
  }
`

export const mapContactDetailsToServer = cd => ({
  contact_details_uid: cd.contact_details_uid,
  kind: cd.kind,
  value: cd.value,
  account_uid: cd.account_uid,
  is_valid: cd.is_valid,
  remarks: cd.remarks
})

export const mapContactPersonToServer = p => ({
  account_person_uid: p.account_person_uid,
  account_uid: p.account_uid,
  name: p.name,
  description: p.description,
  department: p.department,
  kind: p.kind,
  is_valid: p.is_valid,
  contact_details: p.contact_details ? p.contact_details.map(mapContactDetailsToServer) : []
})

export const mapAccountToServer = account => {
  const persons = account.persons ? account.persons.map(mapContactPersonToServer) : []

  const addresses = account.addresses
    ? account.addresses.map(a => ({
      address_uid: a.address_uid,
      city: a.city,
      street: a.street,
      province: a.province,
      suburb: a.suburb,
      is_valid: a.is_valid,
      is_primary: a.is_primary,
      street_number: a.street_number,
      house_number: a.house_number,
      postcode: a.postcode,
      country_code: a.country_code,
      address_kind: a.address_kind,
      town: a.town,
      remark: a.remark,
      location: a.location,
      line1: a.line1,
      line2: a.line2
    }))
    : []

  const ret = {
    name: account.name,
    account_uid: account.account_uid,
    name_local: account.name_local,
    kind: account.kind,
    is_deleted: account.is_deleted,
    lead_in_date: account.lead_in_date,
    lead_in_time: account.lead_in_time,
    territory_uid: account.territory_uid,
    lead_campaign_uid: account.lead_campaign_uid,
    category: account.category,
    transaction_type: account.transaction_type,
    cams_username: account.cams_username,
    addresses,
    registration_number: account.registration_number,
    address_delete_ids: account.addresses_delete ? account.addresses_delete : '',
    contact_details: account.contact_details ? account.contact_details.map(mapContactDetailsToServer) : undefined,
    contact_delete_ids: account.contact_details_delete ? account.contact_details_delete : '',
    persons,
    person_delete_ids: account.persons_delete ? account.persons_delete : '',
    website: account.website,
    industry_uid: account.industry_uid,
    is_assign_to_me: account.is_assign_to_me,
    remark: account.remark
  }

  return ret
}

export const qlqFindDuplicates = gql`
query find_duplicates($account: account_in) {
  find_duplicates(account: $account) {
    ${qlAccountAllFields}
    contact_details {
      ${qlContactDetailsAllFields} 
    }
  }
}
`

export const qlqQuickserachSearch = gql`
  query search($query: String!) {
    search(query: $query) {
      results {
        id
        type
        name
        account_uid
        account_name
        match
        contact_details {
          value
          kind
        }
        has_account_data_permission
        meta
        owner_name
      }
    }
  }
`
