import React, { FC } from 'react'

interface IUserFilterValueString {
  filterValues?: any
}

export const UserFilterValueString: FC<IUserFilterValueString> = ({ filterValues }) => {
  const resolveDisplay = () => {
    const result = { label: '', value: 'All users' }

    if (filterValues?.user?.user_uid) result.value = filterValues.user.user_uid?.label ?? ''
    if (filterValues?.owner_user_uid) result.value = filterValues.owner_user_uid?.label ?? ''

    if (filterValues?.user?.resource_pool?.resource_pool_uid && !filterValues.user.user_uid) {
      result.label = 'Users Group'
      result.value = filterValues.user.resource_pool.resource_pool_uid?.label ?? ''
    }

    return result
  }

  const { label, value } = resolveDisplay()

  return (
    <React.Fragment>
      {
        <span>
          {label}
          <strong>{`${label ? ` ${value}` : value}`}</strong>
        </span>
      }
    </React.Fragment>
  )
}
