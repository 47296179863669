const dashFunnelStatusQuery = {
  type: 'analitics_widget',
  code: 'rmLeadsCurrentStatus',
  def_version: 5,
  title: 'All Accounts Current Status',
  description: 'Showing current status of leads owner by RM',
  content: {
    data: {
      dataSources: [
        {
          code: 'AccountsListQM',
          name: 'AccountsListQM',
          alias: 'LeadsCurrentStatus',
          type: 'QM',
          filter: {
            sales_result_code: { $in: ['valid', 'lead'] },
            owner_user_uid: { $ne: null }
          },
          fields: [
            'sales_result_code',
            'owner_user_uid',
            'detailed_status_code',
            'owner__full_name',
            'owner__department__name'
          ],
          aggregate: [{ id: 'account_uid', type: 'count' }],
          contextMapping: {
            owner_user_uid: 'user_uid'
          }
          // transposeColumns: ['sales_result_code'],
          // transposeKey: 'owner_user_uid',
          // transposeValueField: 'account_uid'
        }
      ]
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: true,
            generate_average: true,
            columnGroups: [
              {
                Header: '',
                colSpan: 3
              },
              {
                Header: 'Leads',
                colSpan: 5,
                className: 'CrmFlatTable-summary'
              },
              {
                Header: 'Prospects',
                colSpan: 7,
                className: 'CrmFlatTable-summary'
              },
              {
                Header: 'Customers',
                colSpan: 2,
                className: 'CrmFlatTable-summary'
              }
            ],
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 50
              },
              {
                Header: 'RM',
                accessor: 'full_name',
                width: 150,
                classNameHandler: row =>
                  row.original.is_active ? '' : 'util-opacity50'
              },
              {
                Header: 'Start Date',
                accessor: 'contract_start_date',
                width: 80
              },
              {
                Header: 'All',
                accessor: 'leads_all',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'lead'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number'
              },
              {
                Header: 'Untouched',
                accessor: 'leads_new',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'lead',
                  sales_step_code: 'new'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Pending',
                accessor: 'leads_pending',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'lead',
                  sales_step_code: 'pending'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Lost',
                accessor: 'leads_lost',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'lead',
                  sales_step_code: 'lost'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Bogus',
                accessor: 'leads_bogus',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'lead',
                  sales_step_code: 'bogus'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'All',
                accessor: 'prospects_all',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'prospect'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Untouched',
                accessor: 'prospects_new',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'prospect',
                  sales_step_code: 'new'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Pending',
                accessor: 'prospects_pending',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'prospect',
                  sales_step_code: 'pending'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Opportunity',
                accessor: 'prospects_opportunity',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'prospect',
                  sales_step_code: 'opportunity'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Quoted',
                accessor: 'prospects_quoted',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'prospect',
                  sales_step_code: 'quoted'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                },
                Cell: () => 'n/a'
              },
              {
                Header: 'Lost',
                accessor: 'prospects_lost',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'prospect',
                  sales_step_code: 'lost'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Bogus',
                accessor: 'prospects_bogus',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'prospect',
                  sales_step_code: 'bogus'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Active',
                accessor: 'customers_all',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account_current_status',
                detailsFilter: {
                  kind: 'customer'
                },
                detailsFilterMapping: {
                  owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Lost',
                accessor: 'customers_lost',
                className: 'util-textRight util-pointer',
                width: 60,
                Cell: () => 'n/a'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}

export default dashFunnelStatusQuery
