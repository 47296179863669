import settingsAPI from 'crm-api/settings/campaigns-api'
import {
  FETCH_SETTING_CAMPAIGNS_SUCCESS,
  FETCH_SETTING_CAMPAIGNS,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CLEAR_SELECT_CAMPAIGN,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS
} from 'crm-duxs/settings/campaign-reducer'
import { FETCH_CAMPAIGNS } from 'crm-duxs/account-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchCampaigns(action) {
  console.log('fetchCampaigns : ', action.payload)
  const res = yield call(settingsAPI.fetchCampaignsApi, action.payload.params)

  yield put({
    type: FETCH_SETTING_CAMPAIGNS_SUCCESS,
    payload: {
      campaigns: res.campaigns.map(c => ({
        ...c,
        details: c.details === null ? undefined : c.details
      }))
    }
  })
}

function* createCampaign(data) {
  const campaign = yield call(
    settingsAPI.createCampaignApi,
    data.payload.values
  )
  yield put({ type: CREATE_CAMPAIGN_SUCCESS, payload: { campaign } })
  yield put({ type: CLEAR_SELECT_CAMPAIGN })
  yield put({ type: FETCH_CAMPAIGNS })
}

function* updateCampaign(data) {
  const campaign = yield call(
    settingsAPI.updateCampaignApi,
    data.payload.values
  )
  yield put({ type: UPDATE_CAMPAIGN_SUCCESS, payload: { campaign } })
  yield put({ type: CLEAR_SELECT_CAMPAIGN })
  yield put({ type: FETCH_CAMPAIGNS })
}

function* settingCampaignSaga() {
  yield takeLatest(FETCH_SETTING_CAMPAIGNS, fetchCampaigns)
  yield takeLatest(CREATE_CAMPAIGN, createCampaign)
  yield takeLatest(UPDATE_CAMPAIGN, updateCampaign)
}

export default settingCampaignSaga
