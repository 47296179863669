import gql from 'graphql-tag'

export const qlmExecuteFunction = gql`
  mutation execute_function($function: String, $params: JSON) {
    execute_function(function: $function, params: $params) {
      result
      data
    }
  }
`
export const mock = 1
