/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import AccountPage from '../../accounts/account/components/account-page.jsx'
import { getCurrentTask } from 'crm-duxs/dialer-reducer'

import { qlqProcessDetails } from 'crm-data/processes'

class DialerInnerTaskView extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { currentTask, process } = this.props
    console.log('render DialerTask View ')
    return (
      <div>
        {process && (
          <AccountPage
            accountUid={process.account_uid}
            processUid={process.process_uid}
            process={process}
          />
        )}
        Dialer Inner Task
        {JSON.stringify(currentTask)}
      </div>
    )
  }
}

DialerInnerTaskView.propTypes = {
  currentTask: shape({}),
  process: shape({})
}
DialerInnerTaskView.defaultProps = {
  currentTask: undefined,
  process: undefined
}

const dialerTaskPageHoc = compose(
  graphql(qlqProcessDetails, {
    options: ownProps => {
      // console.log('ownProps, ', ownProps)
      return {
        variables: {
          process_uid: ownProps.currentTask
            ? ownProps.currentTask.process_uid
            : undefined
        }
      }
    },
    props: ({ processQl, ownProps }) => ({
      ...ownProps,
      processQl,
      process: processQl && processQl.process ? processQl.process : undefined,
      isLoading: processQl ? processQl.loading : false
    }),
    name: 'processQl',
    fetchPolicy: 'no-cache'
  })
)

function mapStateToProps(state) {
  return {
    currentTask: getCurrentTask(state)
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(dialerTaskPageHoc(DialerInnerTaskView))
