/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import { shape, func, string } from 'prop-types'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { transpose } from 'crm-utils/data-utils'
import CrmWidget from 'crm-modules/dashboard/components/crm-widget.jsx'
import { CrmHeatmapChart } from 'crm-components'

class WidgetCurrentLeadStatusByOwner extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps !== undefined &&
  //     nextProps.analyticsContext !== this.props.analyticsContext
  //   ) {
  //     console.log(
  //       'Widget Agnets Dispositiosn will receive props',
  //       this.props,
  //       this.nextProps
  //     )
  //     setTimeout(() => {
  //       this.handleRefresh()
  //     })
  //   }
  // }
  renderSummary = () => (
    <div>
      {this.state.totalDispositions && (
        <span>Total Dispositions: {this.state.totalDispositions}</span>
      )}
    </div>
  )

  handleRefresh = async () => {
    console.log('dash telesales general stats . handle refresh', this.props)
    try {
      const filter = mapContextToFilter(this.props, false)
      filter.is_deleted = false
      if (this.props.analyticsContext.filters.lead_in_date) {
        filter.account__lead_in_date = {
          $gte: this.props.analyticsContext.filters.lead_in_date.value[0],
          $lte: this.props.analyticsContext.filters.lead_in_date.value[1]
        }
      }
      const variables = {
        filter,
        fields: [
          'owner__full_name',
          'owner_user_uid',
          'owner__is_active',
          'owner_pool_uid'
        ],
        aggregate: [{ id: 'account_uid', type: 'count' }],
        rootType: 'AccountsListQM'
      }

      const res = await this.props.client.query({
        query: qlqGenericDataQuery,
        variables,
        fetchPolicy: 'no-cache'
      })

      console.log('Got result', res)
      if (res.data.genericDataQuery) {
        console.log('Transpose ')
        const transposed = transpose(
          res.data.genericDataQuery.data,
          'owner__full_name',
          'owner__is_active',
          'kpi_sales_status_code',
          'account_uid'
        )
        console.log('Transposed', transposed)
        this.setState({
          data: res.data.genericDataQuery.data,
          transposed
        })
      }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }
  handleCellClicked = cell => {
    console.log('handleClickBar', cell, this.props)

    const filters = {
      ...mapContextToFilter(this.props, false)
    }
    if (cell) {
      filters.kpi_sales_status_code = cell.kpi_sales_status_code
      filters.owner__full_name = cell.owner__full_name
    }
    if (this.props.analyticsContext.filters.lead_in_date) {
      filters.lead_in_date = this.props.analyticsContext.filters.lead_in_date
    }
    console.log('result filters', filters)
    this.props.onAnalyticsRangeSelected({ type: 'account', filters })
  }
  rowHeaderRenderer = ({ row }) => <div>{row.name}</div>
  handleGetRawData = () => this.state.data
  render() {
    console.log(
      'Render Widget Current Lead Status by Owner rev 31-01-2019',
      this.state
    )
    return (
      <CrmWidget
        title={
          this.props.title
            ? this.props.title
            : 'Current Lead Status By Owner rev 31-01-2019'
        }
        subtitle="Current status and owner of leads"
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-agents-dispositions"
      >
        {!this.state.data && (
          <span>No data - select filters and press refresh to load</span>
        )}
        {this.state.data && (
          <CrmHeatmapChart
            xAxisField="kpi_sales_status_code"
            yAxisField="owner__full_name"
            valueField="account_uid"
            data={this.state.data}
            onCellClicked={this.handleCellClicked}
            rowHeaderRenderer={this.rowHeaderRenderer}
          />
        )}
      </CrmWidget>
    )
  }
}

WidgetCurrentLeadStatusByOwner.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  title: string,
  onAnalyticsRangeSelected: func.isRequired
}
WidgetCurrentLeadStatusByOwner.defaultProps = {
  title: undefined
}

export default withApollo(WidgetCurrentLeadStatusByOwner)
