import gql from 'graphql-tag'
import { genericActionResultTypeFields } from './common'

export const qlPersonAllFields = ' account_person_uid account_uid name description department kind is_valid '

export const qlActivityOwnFields = ` 
activity_uid activity_type_uid instance_uid account_uid direction
call_external_uid call_start_time call_end_time call_asterisk_id
address_uid start_time end_time note_text source_type_uid location_type
details subject created_time 
completed_time
completed_at
created_by_user {full_name}
disposition_type_uid
disposition_type { disposition_type_uid code name name_en }
disposition_details
contact_details_uid 
meta
result_data
seq_same_type_success_user
seq_same_type_success
script_uid
module
is_deleted
vehicle_id
meeting_minutes_location_validation
meeting_minutes_location_distance
`

export const qlActivityTypeOwnFields =
  ' activity_type_uid instance_uid name name_en description medium_code is_active code form_code module require_address meeting_minutes_code '
export const qlActivityAccountFields = `account_uid name kind contact_details { 
    contact_details_uid account_uid instance_uid address_uid kind value remarks is_valid } `
export const qlActivityStatusOwnFields =
  ' activity_status_uid code instance_uid kind is_success name description pbx_code is_active is_result is_initial '
export const qlActivityParticipantOwnFields = ` activity_participant_uid activity_uid participant_account_person_uid participant_account_uid role_code is_primary user_uid 
  user { user_uid full_name headshot_url photo_url email } 
  contact_details { contact_details_uid account_uid value kind }
  person { ${qlPersonAllFields} contact_details { contact_details_uid account_uid value kind } } `

export const qlActivityWithFields = ` ${qlActivityOwnFields} 
call_log { call_uid account_uid ast_id user_uid activity_uid channel source_number destination_number start_time end_time current_status path voice_record_uid }
account { ${qlActivityAccountFields} }
activity_type { ${qlActivityTypeOwnFields} }
participants { ${qlActivityParticipantOwnFields} } 
primary_performer { ${qlActivityParticipantOwnFields} } 
activity_status_uid 
activity_status {${qlActivityStatusOwnFields}}
meeting_minutes { meeting_minutes_uid completed_at details }
contact_details { value }
script { code name version }
email_status
attachments { resource_uid resource { name } } 
`

export const fragmentActivityWithFields = `
fragment ActivityFields on Activity {
  ${qlActivityWithFields}
}`

export const qlqActivityGet = gql`
query activity($activity_uid: String!) {
  activity(activity_uid: $activity_uid) {
   ${qlActivityOwnFields}
  }
}`

export const qlqActivityWithFields = gql`
query activity($activity_uid: String!) {
  activity(activity_uid: $activity_uid) {
   ${qlActivityWithFields}
  }
}`

export const qlqGetLiveActivity = gql`
query activity($activity_uid: String!) {
  activity(activity_uid: $activity_uid) {
   ${qlActivityOwnFields}
   activity_status { ${qlActivityStatusOwnFields} }
   contact_details { contact_details_uid value  }
   activity_type { ${qlActivityTypeOwnFields} }
  }
}`

// MUTATIONS

export const qlmUpdateActivityStatus = gql`
mutation activity_update_status($activity_uid: String!
    ,$activity_status_uid: String
    ,$activity_status_code: String
    ,$reason: String)
    {
      activity_update_status(activity_uid: $activity_uid
      ,activity_status_uid: $activity_status_uid
      ,activity_status_code: $activity_status_code
      ,reason: $reason) 
      {
    ${genericActionResultTypeFields}
  }
}`

export const qlmUpdateActivity = gql`
mutation activity_update($activity_uid: String!
    ,$activity: activity_in)
    {
      activity_update(activity_uid: $activity_uid
      ,activity: $activity)
      {
    ${qlActivityWithFields}
  }
}`

// export const qlqAccountActivities = gql`
// query activities($account_uid: String!) {
//   account(account_uid: $account_uid) { activities {${qlActivityWithFields} } }
// }
// `

export const fragmentAccountActivities = `
fragment AccountActivities on Account {
    activities { 
       ...ActivityFields
     }
}
${fragmentActivityWithFields}`

export const qlqAccountActivity = gql`
  query activities($account_uid: String!, $activity_uid: String) {
    account(account_uid: $account_uid) {
      account_uid
      activities(activity_uid: $activity_uid) {
        ...ActivityFields
      }
    }
  }
  ${fragmentActivityWithFields}
`

export const qlqAccountActivities = gql`
  query activities($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      ...AccountActivities
    }
  }
  ${fragmentAccountActivities}
`

export const qlqListCallDispositionTypes = gql`
  {
    call_disposition_type_list_qm_paged(limit: 400) {
      count
      data {
        disposition_type_uid
        code
        name
        name_en
        pbx_status_code
        is_default
        is_deleted
        is_success
        data
        locale_data
        is_active
        module
        assign_to_caller
        next_process_step_code
        require_manage_permission
        for_validated_account
        for_not_validated_account
      }
    }
  }
`

export const qlqListActivities = gql`
  query activities_list_qm_paged(
    $sort: [JSON]
    $offset: Int
    $limit: Int
    $filter: JSON
    $fields: [String]
    $aggregate: [JSON]
    $useCache: Boolean
  ) {
    activities_list_qm_paged(
      sort: $sort
      offset: $offset
      limit: $limit
      filter: $filter
      aggregate: $aggregate
      fields: $fields
      useCache: $useCache
    ) {
      data {
        activity_uid
        start_time
        start_date
        created_by_user_uid
        created_by_user_name
        call_length
      }
      count
    }
  }
`
export const qlmActivitiesCommand = gql`
  mutation cqCommand($command: CqCommand!) {
    cqCommand(command: $command) {
      status
      errors
      payload
      command_uid
      activity {
        ...ActivityFields
      }
    }
  }
  ${fragmentActivityWithFields}
`
