import React, { useState } from 'react'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import { FilterSection } from '../tools'
import { OpportunityFilterDropDown, OpportunityValueString } from '.'
export const FilterSectionOpportunity = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleDropDown = () => setIsExpanded(!isExpanded)
  const closeDropDown = () => setIsExpanded(false)
  const { filters, setFilters } = useAnalyticsContext()
  return (
    <FilterSection
      name="Opportunity"
      selectedValue={<OpportunityValueString filterValues={filters} />}
      onToggle={toggleDropDown}
      isExpanded={isExpanded}
      {...props}
    >
      {isExpanded && <OpportunityFilterDropDown
        filterValues={filters}
        onSetFilters={setFilters}
        isExpanded={isExpanded}
        onClickedOutside={closeDropDown}
      />}
    </FilterSection>
  )
}
