import cloneDeep from 'clone-deep'
import { delay } from 'redux-saga'
import { put, takeLatest, select } from 'redux-saga/effects'
import { getMyPermissions2019, getUser } from 'crm-duxs/crm-reducer'
import { hasPermission } from '@cartrack-crm/crm-core'
import {
  SET_FILTER_VALUE,
  SET_SORT_VALUE,
  CLEAR_FILTERS_GROUP,
  STORE_FILTER_GROUP,
  STORE_SORT_GROUP,
  getFilterGroup,
  getFiltersDefinitions,
  GROUP_QFILTER_UPDATED,
  INIT_FILTERS_GROUP,
  setFilterValue
} from 'crm-duxs/filters-reducer'
import { fetchAccountListCounts } from 'crm-duxs/accounts-list-reducer'
import { get } from 'lodash'

// eslint-disable-next-line
const mapFilterValue = (filter, filterDefinitions) =>
  get(filter, 'value.rawValue', null)

const getFilterDefinition = (filterDefinitions, code) =>
  filterDefinitions.find(f => (f.code ? f.code : f.field) === code)

const convertToQfilter = (filterGroup, filterDefinitions) => {
  let ret = {}

  Object.keys(filterGroup).forEach(key => {
    if (filterGroup[key].enabled) {
      if (filterGroup[key].value && filterGroup[key].value.qfilter) {
        ret = { ...ret, ...filterGroup[key].value.qfilter }
      } else {
        const value = mapFilterValue(filterGroup[key], filterDefinitions)
        let field = key
        const filterDef = getFilterDefinition(filterDefinitions, key)

        if (filterDef && filterDef.field) {
          field = filterDef.field
        }

        if (value) {
          ret[field] = value
        }
      }
    }
  })

  return ret
}

function* onSetFilterValue(action) {
  const currentFilter = yield select(getFilterGroup, action.payload.groupCode)
  const filterDefinitions = yield select(getFiltersDefinitions)
  const newFilterGroup = cloneDeep(currentFilter)
  const oldFilter = newFilterGroup[action.payload.filterCode]
    ? {
        ...newFilterGroup[action.payload.filterCode]
      }
    : {}

  newFilterGroup[action.payload.filterCode] = {
    ...oldFilter,
    ...action.payload.filterValue
  }

  yield put({
    type: STORE_FILTER_GROUP,
    payload: {
      groupCode: action.payload.groupCode,
      groupFilter: newFilterGroup
    }
  })

  yield delay(500)

  yield put({
    type: GROUP_QFILTER_UPDATED,
    payload: {
      groupCode: action.payload.groupCode,
      qfilter: convertToQfilter(newFilterGroup, filterDefinitions)
    }
  })

  yield put(fetchAccountListCounts(action.payload.groupCode))
}

function* onSetSortValue(action) {
  yield put({
    type: STORE_SORT_GROUP,
    payload: {
      groupCode: action.payload.groupCode,
      newSorted: action.payload.newSorted
    }
  })
}

function* initFiltersGroup(action) {
  const myPermissions2019 = yield select(getMyPermissions2019)
  const isManager = hasPermission(
    myPermissions2019,
    'crm.generalData',
    'manage',
    ['*']
  )

  if (!isManager) {
    const user = yield select(getUser)

    yield put(
      setFilterValue(action.payload.groupCode, 'owner_user_uid', {
        enabled: true,
        fixed: true,
        value: {
          rawValue: user.user_uid
        }
      })
    )
  }
}

function* clearFiltersGroup(action) {
  const filterDefinitions = yield select(getFiltersDefinitions)
  const newFilterGroup = {}

  yield put({
    type: STORE_FILTER_GROUP,
    payload: {
      groupCode: action.payload.groupCode,
      groupFilter: newFilterGroup
    }
  })

  yield delay(500)

  yield put({
    type: GROUP_QFILTER_UPDATED,
    payload: {
      groupCode: action.payload.groupCode,
      qfilter: convertToQfilter(newFilterGroup, filterDefinitions)
    }
  })

  yield put(fetchAccountListCounts(action.payload.groupCode))
}

export default function* accountsSaga() {
  yield takeLatest(SET_FILTER_VALUE, onSetFilterValue)
  yield takeLatest(SET_SORT_VALUE, onSetSortValue)
  yield takeLatest(INIT_FILTERS_GROUP, initFiltersGroup)
  yield takeLatest(CLEAR_FILTERS_GROUP, clearFiltersGroup)
}
