import attributeAPI from 'crm-api/admin/attributes-api'
import { toastr } from 'react-redux-toastr'
import {
  FETCH_ATTRIBUTES,
  RECEIVE_ATTRIBUTES,
  SAVE_ATTRIBUTE,
  SAVE_ATTRIBUTES,
  FETCH_ATTRIBUTE,
  RECEIVE_ATTRIBUTE
} from 'crm-duxs/admin/attributes-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchAttributes(action) {
  console.log('fetchAttributes', action)
  const attributes = yield call(attributeAPI.fetch, action.params)
  yield put({
    type: RECEIVE_ATTRIBUTES,
    payload: {
      attributes
    }
  })
}

function* fetchAttribute(action) {
  console.log('fetchAttribute', action)
  const attributes = yield call(attributeAPI.fetch, action)
  const attribute = attributes[0]
  yield put({
    type: RECEIVE_ATTRIBUTE,
    payload: {
      attribute
    }
  })
}

function* saveAttributes(action) {
  try {
    yield call(attributeAPI.updateItems, action.formValues)
    toastr.success('Attributes Saved !')
  } catch (err) {}
}

function* saveAttribute(action) {
  console.log('saveAttribute : ', action)
  try {
    if (action.formValues.attribute_uid)
      yield call(attributeAPI.update, action.formValues)
    else yield call(attributeAPI.create, action.formValues)

    yield put({
      type: FETCH_ATTRIBUTES
    })
  } catch (err) {}
}

// function* updateResource(action) {
//   try {
//     yield call(resourceAPI.update, action.formValues)
//     yield put({
//       type: CANCEL_EDITING_RESOURCE
//     })
//     yield put({
//       type: FETCH_RESOURCES
//     })
//   } catch (err) {}
// }

// function* deleteResource(action) {
//   console.log('deleteResource', action)
//   try {
//     yield call(resourceAPI.delete, action)
//     yield put({
//       type: FETCH_RESOURCES
//     })
//   } catch (err) {}
// }

function* AttributeSaga() {
  yield takeLatest(FETCH_ATTRIBUTES, fetchAttributes)
  yield takeLatest(SAVE_ATTRIBUTE, saveAttribute)
  yield takeLatest(SAVE_ATTRIBUTES, saveAttributes)
  yield takeLatest(FETCH_ATTRIBUTE, fetchAttribute)
  // yield takeLatest(SAVE_EDITING_RESOURCE, updateResource)
  // yield takeLatest(DELETE_RESOURCE_VALUE, deleteResource)
  // yield takeLatest(CREATING_RESOURCE_ITEM, createResourceforAccount)
}

export default AttributeSaga
