import React, { PureComponent } from 'react'
import { graphql, compose } from 'react-apollo'
import { shape, func, string } from 'prop-types'
import { CrmInputDropdown } from 'crm-components'

class CrmGenericSelector extends PureComponent {
  _parseData() {
    const rawData = this.props.data[this.props.queryName]
    return rawData
      ? rawData.map(item => {
          const ret = {
            value: item[this.props.itemValueField],
            name: item[this.props.itemLabelField],
            data: item
          }
          return ret
        })
      : []
  }
  dispatchChange = (value, data) => {
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(value, data)
    }
    if (this.props.onChange) {
      this.props.onChange(value, data)
    }
  }
  handleChange = value => {
    if (value) {
      const options = this._parseData()
      const item = options.find(i => i.value === value)
      if (item) {
        this.dispatchChange(value, item.data)
      } else {
        this.dispatchChange(value)
      }
    } else this.dispatchChange()
  }
  render() {
    const options = this._parseData()
    return (
      <CrmInputDropdown
        {...this.props}
        options={options}
        onChange={this.handleChange}
      />
    )
  }
}

CrmGenericSelector.propTypes = {
  onChange: func.isRequired,
  input: shape({}).isRequired,
  data: shape().isRequired,
  itemValueField: string.isRequired,
  itemLabelField: string.isRequired,
  queryName: string.isRequired
}

const CrmGenericSelectorFactory = props => {
  const qlOptions = ownProps => {
    const ret = {}
    if (props.variablesMapHandler) {
      ret.variables = props.variablesMapHandler(ownProps)
    }
    return ret
  }

  const InnerQl = compose(
    graphql(props.qlQuery, {
      options: qlOptions
    })
  )(CrmGenericSelector)

  return innerProps => {
    return (
      <InnerQl
        {...innerProps}
        itemValueField={props.itemValueField}
        itemLabelField={props.itemLabelField}
        queryName={props.qlQuery.definitions[0].name.value}
      />
    )
  }
}

CrmGenericSelectorFactory.propTypes = {
  itemValueField: string.isRequired,
  itemLabelField: string.isRequired,
  queryName: string.isRequired
}
export default CrmGenericSelectorFactory
