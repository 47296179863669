import { reduce } from 'lodash'

function subStringSearch(string, search) {
  const str = string.toLowerCase()
  const srch = search.toLowerCase()
  return str.indexOf(srch) > -1
}

function needleInAHaystack(string, search) {
  const str = string.toLowerCase()
  const srch = search.toLowerCase()
  let i = -1

  // eslint-disable-next-line no-unused-vars
  for (const char of srch) {
    if (!~(i = str.indexOf(char, i + 1))) return false
  }
  return true
}

function createSearch(predicate) {
  // prettier-ignore
  return function (strOrObj, search) {
    if (search === '') return true
    return typeof strOrObj === 'string'
      ? predicate(strOrObj, search)
      : reduce(strOrObj, (acc, value) => acc || (typeof value === 'string' ? predicate(value, search) : false), false)
  }
}

export const stringSearch = createSearch(subStringSearch)
export default createSearch(needleInAHaystack)

export const dateSearch = (d, search) => {
  const date = new Date(d)
  const dateStr = (
    date.toLocaleString() + date.toLocaleString(undefined, { month: 'long' })
  ).toLowerCase()
  const srch = search.toLowerCase()
  return dateStr.indexOf(srch) > -1
}
