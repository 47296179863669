/* eslint-disable camelcase */
import React, { SFC, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useCrmContext } from 'crm-core/contexts/crm-context'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { CrmFullpageContainer } from 'crm-components'
import uuidv4 from 'uuid'
import ScheduleConfigFormInner from './schedule-form-inner'
import crmGenericCommandQlHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import ScheduleLog from './schedule-logs'
import { LoadingIcon, Spacer } from 'util-components'
import { Link } from 'react-router-dom'
import { set } from 'lodash'

interface ScheduleConfigFormProps {
  match?: any
  history?: any
  onRunCommand: (command?: {}, props?: {}, mutation?: {}) => any
}

type FormValues = {
  schedule_uid?: string
  name?: string
  details?: {
    recipients?: []
    schedules?: []
    parts?: []
  }
}

const FetchScheduleQuery = gql`
  query schedule_report($master_uid: String!, $instance_uid: String!, $schedule_uid: String!) {
    master(master_uid: $master_uid) {
      instance(instance_uid: $instance_uid) {
        schedule_report(schedule_uid: $schedule_uid) {
          schedule_uid
          name
          details
          schedule_type_code
          is_disabled
          created_by_user {
            user_uid
            full_name
          }
        }
      }
    }
  }
`

const formatScheduleFormValues = values => {
  const formValues: FormValues = {}
  formValues.name = values.name
  formValues.details = {}
  formValues.details.recipients = Array.isArray(values.recipients) ? values.recipients.slice() : []
  formValues.details.schedules = Array.isArray(values.schedules) ? values.schedules.slice() : []

  formValues.details.parts = (values.parts || []).map(part => {
    const tempPart = {
      filter: part.filter || {},
      part_name: part.part_name,
      report_type: part.report_type
    }

    switch (part.report_type) {
      case 'text':
        set(tempPart, 'text', part.text)
        break
      case 'widget':
        set(tempPart, 'dashboard_uid', part.dashboard_uid)
        set(tempPart, 'report_format', part.report_format)
        break
      default:
        return part
    }

    return tempPart
  })

  return formValues
}

const loadData = props => {
  const scheduleUid = props.match.params.scheduleUid
  const { master, instance } = useCrmContext()
  const initialForm = {
    schedule_uid: scheduleUid,
    name: '',
    recipients: [],
    parts: [],
    schedules: [],
    is_disabled: false
  }
  if (scheduleUid === 'new') {
    return {
      schedule: {},
      loading: false,
      initialForm
    }
  }
  const { data, loading, refetch } = useQuery(FetchScheduleQuery, {
    variables: {
      master_uid: master.master_uid,
      instance_uid: instance.instance_uid,
      schedule_uid: scheduleUid
    },
    fetchPolicy: 'no-cache'
  })

  if (loading) {
    return {
      schedule: {},
      loading: true,
      initialForm
    }
  }
  const schedule = data?.master?.instance?.schedule_report
  if (!schedule) {
    return {
      schedule: undefined,
      loading: false,
      initialForm
    }
  } else {
    return {
      schedule: schedule,
      loading,
      refetch,
      initialForm: {
        schedule_uid: scheduleUid,
        name: schedule.name,
        recipients: schedule.details.recipients,
        parts: schedule.details.parts,
        schedules: schedule.details.schedules,
        is_disabled: schedule.is_disabled,
        created_by_user: schedule.created_by_user
      }
    }
  }
}

const ScheduleConfigForm: SFC<ScheduleConfigFormProps> = props => {
  const [isSaving, setSaving] = useState(false)
  const { schedule, loading, initialForm, refetch } = loadData(props)

  if (!schedule && !loading) {
    return (
      <CrmFullpageContainer vbox>
        <div className="util-flexRowCenter util-paddingLg">
          <div>Data was not found in system</div>
        </div>
      </CrmFullpageContainer>
    )
  }
  if (loading) {
    return (
      <CrmFullpageContainer vbox>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingIcon />
        </div>
      </CrmFullpageContainer>
    )
  }

  const handleFormSubmit = async values => {
    const formValues = formatScheduleFormValues(values)
    let commandType = 'ScheduleReport.create'
    if (schedule.schedule_uid) {
      commandType = 'ScheduleReport.update'
      formValues.schedule_uid = schedule.schedule_uid
    }

    const command = {
      type: commandType,
      aggregate_uid: uuidv4(),
      aggregate_type: 'ScheduleReport',
      payload: {
        ...formValues
      }
    }

    try {
      setSaving(true)
      await props.onRunCommand(command, {})
      toastr.success('Saved')
      setSaving(false)
      if (!schedule.schedule_uid) {
        props.history.replace('/crm/manager/schedule/')
      }
    } catch (err) {
      toastr.error('There was problem saving data')
      setSaving(false)
    }
  }

  return (
    <CrmFullpageContainer vbox>
      <div className="util-fullHeight util-marginLg">
        <div className="util-flexRow">
          <Link to="/crm/manager/schedule">
            <span className="Breadcrumbs-crumbLink">
              <i className={`fa fa-angle-left`} aria-hidden="true" /> Back to Schedule
            </span>
          </Link>
          <div className="util-marginLeft"> / {schedule ? 'Edit Schedule' : 'Create Schedule'}</div>
        </div>
        <Spacer height="40px" />

        <ScheduleConfigFormInner
          refetch={refetch}
          scheduleUid={initialForm.schedule_uid}
          onSubmit={handleFormSubmit}
          initialValues={initialForm}
          isSaving={isSaving}
          history={props.history}
          onRunCommand={props.onRunCommand}
        />
        <ScheduleLog schedule_uid={schedule.schedule_uid} />

        <Spacer height="200px" />
      </div>
    </CrmFullpageContainer>
  )
}

export default crmGenericCommandQlHoc(ScheduleConfigForm)
