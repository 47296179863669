// Actions
export const RECEIVE_PERMISSION = 'RECEIVE_PERMISSION'
export const SELECTED_PERMISSION = 'SELECTED_PERMISSION'
export const UNSELECTED_PERMISSION = 'UNSELECTED_PERMISSION'
export const SAVED_EDITING_PERMISSION = 'SAVED_EDITING_PERMISSION'
export const EDITING_PERMISSION = 'EDITING_PERMISSION'
export const CANCEL_EDITING_PERMISSION = 'CANCEL_EDITING_PERMISSION'
export const CREATING_PERMISSION = 'CREATING_PERMISSION'
export const CANCEL_CREATING_PERMISSION = 'CANCEL_CREATING_PERMISSION'
export const SAVING_PERMISSION = 'SAVING_PERMISSION'
export const SAVING_PERMISSION_ERROR = 'SAVING_PERMISSION_ERROR'

// Reducer
const initialState = {
  lists: [],
  fetch: false,
  editing: false,
  creating: false,
  selected: undefined
}

export default function permissionReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PERMISSION:
      return {
        ...state,
        fetch: false,
        lists: action.payload
      }
    case SELECTED_PERMISSION:
      return {
        ...state,
        selected: action.item,
        creating: false,
        editing: false
      }
    case UNSELECTED_PERMISSION:
      return {
        ...state,
        selected: undefined,
        editing: false
      }
    case EDITING_PERMISSION:
      return {
        ...state,
        editing: true,
        creating: false
      }
    case CANCEL_EDITING_PERMISSION:
      return {
        ...state,
        editing: false
      }
    case CREATING_PERMISSION:
      return {
        ...state,
        creating: true,
        editing: false,
        selected: undefined
      }
    case CANCEL_CREATING_PERMISSION:
      return {
        ...state,
        creating: false
      }
    case SAVING_PERMISSION:
      return {
        ...state,
        creating: true
      }
    default:
      return state
  }
}

export function selectedItem(item) {
  return {
    type: SELECTED_PERMISSION,
    item
  }
}

export function unselectedItem() {
  return {
    type: UNSELECTED_PERMISSION
  }
}

export function startEditingItem() {
  return {
    type: EDITING_PERMISSION
  }
}

export function cancelEditingItem() {
  return {
    type: CANCEL_EDITING_PERMISSION
  }
}

export function startCreatingItem() {
  return {
    type: CREATING_PERMISSION
  }
}

export function cancelCreatingItem() {
  return {
    type: CANCEL_CREATING_PERMISSION
  }
}
