import { useGenericCommandQL } from 'crm-components/ql/useGenericCommandQL'
import uuidv4 from 'uuid'

export type initForecastParams = {
  forecastUid?: string,
  month?: string,
  userUid?: string
}
export type opportunitiesParams = {
  account_uid: string,
  confidence_level: string,
  quantity: string,
  due_date: string
}
export type confirmForecastParams = {
  forecastUid?: string,
  items?: opportunitiesParams[]
}
export type unlockForecastParams = {
  forecastUid?: string
}

export type initForecasts = {
  userUids?: [] | [any]
  month?: string
  forecastUid?: string
}

export type paramForecastByTarget = {
  resource_pool_uid?: string
  targets?: number
  month?: string
}

export const useForecastSendCommand = (props) => {
  const { onRunCommand } = useGenericCommandQL(props)
  const sendInitCommand = async (params: initForecastParams) => {
    const uuid = params.forecastUid ? params.forecastUid : uuidv4()
    const command = {
      type: 'forecast.init',
      aggregate_uid: uuid,
      aggregate_type: 'forecast',
      payload: {
        month: params.month,
        user_uid: params.userUid
      }
    }
    const res = await onRunCommand(command, props)
    return res.data.cqCommand
  }
  const sendConfirmForecastCommand = async (params: confirmForecastParams) => {
    const command = {
      type: 'forecast.confirm',
      aggregate_uid: params.forecastUid,
      aggregate_type: 'forecast',
      payload: {
        items: params.items
      }
    }
    const res = await onRunCommand(command, props)
    return res.data.cqCommand
  }
  const sendInitManyForecasts = async (initForecasts: initForecasts) => {
    const command = {
      type: 'forecast.initMany',
      aggregate_uid: initForecasts && initForecasts.forecastUid ? initForecasts.forecastUid : uuidv4(),
      aggregate_type: 'forecast',
      payload: {
        month: initForecasts.month,
        user_uids: initForecasts.userUids
      }
    }
    const res = await onRunCommand(command, props)
    return res.data.cqCommand
  }
  const sendUnlockForecastCommand = async (params: unlockForecastParams) => {
    const command = {
      type: 'forecast.unlock',
      aggregate_uid: params.forecastUid,
      aggregate_type: 'forecast',
      payload: {}
    }
    const res = await onRunCommand(command, props)
    return res.data.cqCommand
  }
  const sendCommandCreateForecastByTarget = async (paramForecastByTarget: paramForecastByTarget) => {
    const command = {
      type: 'forecast.create_with_group_and_targets',
      aggregate_uid: uuidv4(),
      aggregate_type: 'forecast',
      payload: {
        resource_pool_uid: paramForecastByTarget.resource_pool_uid,
        targets: paramForecastByTarget.targets,
        month: paramForecastByTarget.month
      }
    }
    const res = await onRunCommand(command, props)
    return res.data.cqCommand
  }

  return [sendInitCommand, sendConfirmForecastCommand, sendUnlockForecastCommand, sendInitManyForecasts, sendCommandCreateForecastByTarget]
}
