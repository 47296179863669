import React, { PureComponent } from 'react'
import { CrmTextInputNumber, CrmProductsSelect } from 'crm-components'

interface IProps {
  item: any
  input: any
  renderColAction: Function
  index: number
  isLastRow: boolean
  productType: string
}

interface IState {
  item: any
  selectProduct: any
}

class QuotationItem extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      item: { ...props.item },
      selectProduct: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.item !== this.props.item) {
      this.setState({
        item: { ...nextProps.item }
      })
    }
  }

  handleProductItemChange = (fieldName, index, newValue) => {
    const item = { ...this.state.item }

    if (fieldName === 'product_uid') {
      item[fieldName] = newValue.product_uid
      item.product = newValue
    } else {
      item[fieldName] = newValue
    }
    this.setState({ item }, () => {
      if (this.props.input && this.props.input.onChange) {
        this.props.input.onChange(item)
      }
    })
  }

  render() {
    console.log('render quotation-item', this.props, this.state)
    const { item } = this.state
    const { index, isLastRow, productType } = this.props
    return (
      <React.Fragment>
        <table>
          <tr>
            <td className="colProduct">
              <CrmProductsSelect
                input={{
                  value: item.product ? item.product.name : '',
                  onChange: (value, item, data) => {
                    this.handleProductItemChange('product_uid', index, data)
                  }
                }}
              />
            </td>
            <td className="LabeledField-value colProductType">{productType}</td>
            <td className="colDiscount">
              <CrmTextInputNumber
                input={{
                  value: item.discount ? item.discount : '',
                  onChange: (e) => {
                    this.handleProductItemChange(
                      'discount',
                      index,
                      e.currentTarget.value
                    )
                  }
                }}
              />
            </td>
            <td className="colQuantity">
              <CrmTextInputNumber
                input={{
                  value: String(item.quantity),
                  onChange: (e) => {
                    this.handleProductItemChange(
                      'quantity',
                      index,
                      e.currentTarget.value
                    )
                  }
                }}
                focus={true}
              />
            </td>
            <td className="colHardwarePrice">
              <CrmTextInputNumber
                input={{
                  value: String(item.value_net),
                  onChange: (e) => {
                    this.handleProductItemChange(
                      'value_net',
                      index,
                      e.currentTarget.value
                    )
                  }
                }}
                focus={true}
              />
            </td>
            <td className="colSubscriptionPrice">
              <CrmTextInputNumber
                input={{
                  value: item.subscription_net
                    ? String(item.subscription_net)
                    : 0,
                  onChange: (e) => {
                    this.handleProductItemChange(
                      'subscription_net',
                      index,
                      e.currentTarget.value
                    )
                  }
                }}
                focus={true}
              />
            </td>
            <td className="colSubscriptionPriceNextYears">
              <CrmTextInputNumber
                input={{
                  value: item.subscription_net_next_years
                    ? String(item.subscription_net_next_years)
                    : 0,
                  onChange: (e) => {
                    this.handleProductItemChange(
                      'subscription_net_next_years',
                      index,
                      e.currentTarget.value
                    )
                  }
                }}
                focus={true}
              />
            </td>
            <td className="calTaxRate" />
            <td className="colAction">
              {this.props.renderColAction(index, isLastRow)}
            </td>
          </tr>
        </table>
      </React.Fragment>
    )
  }
}

export default QuotationItem
