export const LOAD_INITIAL_FORM_VALUE = 'LOAD_INITIAL_FORM_VALUE'
export const LOAD_INITIAL_VIEW_VALUE = 'LOAD_INITIAL_VIEW_VALUE'
export const RECEIVE_INITIAL_VIEW_VALUE = 'RECEIVE_INITIAL_VIEW_VALUE'
export const RECEIVE_INITIAL_FORM_VALUE = 'RECEIVE_INITIAL_FORM_VALUE'
export const SAVE_MINUTES_FORM = 'SAVE_MINUTES_FORM'
export const SUBMIT_MINUTES_FORM = 'SUBMIT_MINUTES_FORM'
export const SUBMITED_MINUTES_FORM = 'SUBMITED_MINUTES_FORM'
export const GET_MINUTES_DATA = 'GET_MINUTES_DATA'
export const START_MINUTES_EDITING = 'START_MINUTES_EDITING'
export const CANCEL_MINUTES_EDITING = 'CANCEL_MINUTES_EDITING'
export const UPDATE_MINUTES_VALUE = 'UPDATE_MINUTES_VALUE'
export const SET_MINUTES_COMPLETED = 'SET_MINUTES_COMPLETED'
export const UPDATE_MINUTES_ERROR = 'UPDATE_MINUTES_ERROR'
export const TOGGLE_IS_SAVING = 'TOGGLE_IS_SAVING'

export const MARK_MINUTES_DIRTY = 'MARK_MINUTES_DIRTY'

const initialState = {
  fetch: false,
  submitting: false,
  completed: false,
  formData: undefined,
  formDefinitions: [],
  formDefinitionValues: undefined,
  editingId: undefined,
  viewValues: {},
  minutesUid: undefined,
  reportGenerating: false,
  reportData: undefined,
  isDirty: false,
  activity: undefined
}

export function saveData(minutes) {
  return {
    type: SAVE_MINUTES_FORM,
    payload: { minutes }
  }
}

export function submitFormData(formValues) {
  return {
    type: SUBMIT_MINUTES_FORM,
    formValues
  }
}

export function markDirty() {
  return { type: MARK_MINUTES_DIRTY }
}

export function setEditingId(id) {
  return {
    type: START_MINUTES_EDITING,
    id
  }
}

export function clearEditingId() {
  return {
    type: CANCEL_MINUTES_EDITING
  }
}

export function loadViewValues() {
  return {
    type: LOAD_INITIAL_VIEW_VALUE
  }
}

export function setMinutesCompleted(activityId, value) {
  // console.log('setMinutesCompleted')
  // console.log(activityId)
  // console.log(value)
  return {
    type: SET_MINUTES_COMPLETED,
    payload: {
      activityId,
      value
    }
  }
}

// eslint-disable-next-line
export function loadFormValue(activity_uid, account_uid) {
  return {
    type: LOAD_INITIAL_FORM_VALUE,
    activity_uid, // eslint-disable-line
    account_uid // eslint-disable-line
  }
}

// eslint-disable-next-line
function getMinutesChoices(dictionaries) {
  return [
    {
      id: 'attendees',
      formname: 'Participants',
      view: 'MinuteParticipants',
      multiple: true
    },
    {
      id: 'meeting_source',
      formname: 'Meeting source',
      view: 'MinuteChoices',
      option: {
        multiple: false,
        choices: dictionaries.meeting_source.values
      }
    },
    {
      id: 'products_interest',
      formname: 'Interest in Products ',
      view: 'MinuteChoices',
      option: {
        multiple: true,
        choices: dictionaries.meeting_product.values
      }
    },
    {
      id: 'fleet_size',
      formname: 'Fleet',
      view: 'MinuteGroupChoices',
      option: {
        multiple: false,
        choices: dictionaries.fleet_size_key.values,
        keys: dictionaries.fleet_size_value.values
      }
    },
    {
      id: 'customer_priorities',
      formname: 'Customer Priority',
      view: 'MinuteChoices',
      option: {
        multiple: true,
        choices: dictionaries.customer_priorities.values
      }
    },
    {
      id: 'remarks',
      formname: 'Remark',
      view: 'MinuteRemarks',
      multiple: true
    },
    {
      id: 'actions',
      formname: 'Next actions',
      view: 'MinuteNextAction',
      multiple: true
    },
    {
      id: 'potential',
      formname: 'Potential',
      view: 'MinutePotential'
    },
    {
      id: 'current_system',
      formname: 'Current system in use',
      view: 'MinuteCurrentSystem'
    },
    {
      id: 'special_requirement',
      formname: 'Special requirement',
      view: 'MinuteSpecial'
    },
    {
      id: 'adnotations',
      formname: 'Additional internal notes',
      view: 'MinuteInternalNotes'
    },
    {
      id: 'qualification',
      formname: 'Qualification',
      view: 'MinutesQualification'
    },
    {
      id: 'target_prices',
      formname: 'Opportunity Size',
      view: 'MinuteTargetPrice',
      multiple: true
    }
    // {
    //   id: 'opportunity',
    //   formname: 'Opportunity (Internal)',
    //   view: 'MinutesOpportnity'
    // }
  ]
}

export default function meetingMinutesReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_MINUTES_FORM:
      return {
        ...state,
        formData: action.formStateValues
      }
    case SUBMIT_MINUTES_FORM:
      return {
        ...state,
        submitting: true
      }
    case START_MINUTES_EDITING:
      return {
        ...state,
        editingId: action.id
      }
    case CANCEL_MINUTES_EDITING:
      return {
        ...state,
        editingId: undefined
      }
    case LOAD_INITIAL_FORM_VALUE:
      return {
        ...state,
        fetch: true,
        submitting: false,
        formData: undefined,
        completed: false,
        editingId: undefined,
        minutesUid: undefined,
        reportGenerating: false,
        reportData: undefined
      }
    case UPDATE_MINUTES_VALUE:
      return {
        ...state,
        submitting: false,
        // eslint-disable-next-line
        // completed: action.payload.minutes.details.completed_at ? true : false, // eslint-disable-line
        minutesData: action.payload.minutes,
        isDirty: false
        // eslint-disable-next-line
        // formDefinitionValues: action.payload.minutes.details,
        // minutesUid: action.payload.minutes.meeting_minutes_uid
      }
    case UPDATE_MINUTES_ERROR:
      return {
        ...state,
        submitting: false,
        errorMessage: action.payload.errorMessage
      }
    case RECEIVE_INITIAL_FORM_VALUE:
      return {
        ...state,
        fetch: false,
        completed: action.payload.minutesData.completed_at
          ? true // eslint-disable-line
          : false,
        formDefinitionValues: action.payload.minutesData.details,
        minutesUid: action.payload.minutesData.meeting_minutes_uid,
        activity: action.payload.activity,
        // eslint-disable-next-line
        // viewValues: {
        //   products: action.payload.minutesData.products,
        //   dictionaries: action.payload.dictionaries,
        //   account: action.payload.minutesData.account
        // },
        // formDefinitions: getMinutesChoices(action.payload.minutesData),
        minutesData: action.payload.minutesData
      }
    case RECEIVE_INITIAL_VIEW_VALUE:
      return {
        ...state,
        viewValues: action.payload
      }
    case TOGGLE_IS_SAVING:
      return { ...state, isSaving: action.payload.value }
    case MARK_MINUTES_DIRTY:
      return { ...state, isDirty: true }
    default:
      return state
  }
}

export const getMinutesUid = state => state.crm.meetingMinutes.minutesUid
export const getFormViewValues = state => state.crm.meetingMinutes.viewValues
export const getFormLoading = state => state.crm.meetingMinutes.fetch
export const getFormSubmitting = state => state.crm.meetingMinutes.submitting
export const getFormData = state => state.crm.meetingMinutes.formData
export const getFormDataInitial = state =>
  state.crm.meetingMinutes.formDefinitionValues
export const getFormDefinitions = state =>
  state.crm.meetingMinutes.formDefinitions
export const getEditingId = state => state.crm.meetingMinutes.editingId
export const getErrorMessage = state => state.crm.meetingMinutes.errorMessage
export const getMinutesData = state => state.crm.meetingMinutes.minutesData
export const getIsSaving = state => state.crm.meetingMinutes.isSaving
export const getIsDirty = state => state.crm.meetingMinutes.isDirty
export const getMeetingMinutesActivity = state =>
  state.crm.meetingMinutes.activity
