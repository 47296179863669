export const SET_LOADING_BAR = 'SET_LOADING_BAR'

// Reducer
const initialState = {
  count: 0
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_BAR:
      return {
        ...state,
        count: state.count + action.payload.countAdd
      }
    default:
      return state
  }
}

// Action Creators
export function setLoadingBar(isActive) {
  return {
    type: SET_LOADING_BAR,
    payload: {
      countAdd: isActive ? 1 : -1
    }
  }
}

// Selectors
export const getActive = state => state.loadingBar.count > 0
export const getCount = state => state.loadingBar.count
