import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import { OpportunitiesViewSelect } from './opportunities-view-select'
import { GenericDropDownContainer, GenericDropDownFilter, GenericClearFilter } from '../tools'
import cloneDeep from 'clone-deep'

interface IOpportunityFilterDropdown {
  filterValues?: any
  onSetFilters: Function
  isExpanded: boolean
  onClickedOutside: Function
}

class OpportunityFilterDropdown extends React.PureComponent<IOpportunityFilterDropdown, any> {

  myRef: any

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      filterValues: props.filterValues ? cloneDeep(props.filterValues) : {}
    }
  }
  componentDidUpdate () {
    this.recalculatePopupMaxWidth()
  }
  componentDidMount () {
    window.addEventListener('resize', this.recalculatePopupMaxWidth)
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.recalculatePopupMaxWidth)
  }
  componentWillReceiveProps = (nextProps) => {
    if (JSON.stringify(nextProps.filterValues) !== JSON.stringify(this.props.filterValues)) {
      this.setState({ filterValues: { ...nextProps.filterValues } })
    }
  }
  handleClickOutside = () => {
    if (this.props.isExpanded) {
      this.saveValue()
      setTimeout(this.props.onClickedOutside, 5)
    }
  }
  handleClickApply = () => {
    this.handleClickOutside()
  }
  handleClickCancel = () => {
    setTimeout(this.props.onClickedOutside, 5)
  }
  saveValue = () => {
    this.props.onSetFilters(this.state.filterValues)
  }
  handleFilterValueChanged = (field) => (newValue) => {
    const newFilterValues = { ...this.state.filterValues }
    if (!newFilterValues.opportunity) {
      newFilterValues.opportunity = {}
    }
    newFilterValues.opportunity[field] = newValue
      ? {
        value: newValue.value,
        label: newValue.label
      }
      : undefined
    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilters = () => {
    const newFilterValues = { ...this.state.filterValues, opportunity: null }
    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilter = (filterName) => {
    const newFilterValues = { ...this.state.filterValues }

    if (newFilterValues.opportunity) {
      newFilterValues.opportunity[filterName] = undefined
    }

    this.setState({
      filterValues: newFilterValues
    })
  }

  recalculatePopupMaxWidth = () => {
    if (this.myRef && this.myRef.current) {
      const left = this.myRef.current.getBoundingClientRect().left
      this.myRef.current.style.maxWidth = `calc(100vw - ${left + 20}px)`
    }
  }

  hasValueByFilterName = (name) => {
    return (
      this.state.filterValues &&
      this.state.filterValues[name] &&
      this.state.filterValues[name].value &&
      this.state.filterValues[name].value.length > 0
    )
  }

  getValueByFilterName = (name) => {
    return this.state.filterValues &&
      this.state.filterValues[name] &&
      this.state.filterValues[name].value &&
      this.state.filterValues[name].value[0]
      ? this.state.filterValues[name].label
      : ''
  }

  handleOthersSelected = (value, item) => {
    const filterValue = {
      value: [item.value],
      label: item.name,
      item: item
    }
    this.handleFilterValueChanged(item.value)(filterValue)
  }
  render () {
    return (
      <GenericDropDownContainer ref={this.myRef}>
        <GenericDropDownFilter
          size={130}
          label='Opportunity Status'
          value={this.state.filterValues && this.state?.filterValues?.opportunity?.status?.value
            ? this.state.filterValues?.opportunity?.status?.label
            : 'All'}
          onClearFilter={() => this.handleClearFilter('status')}
        >
          <OpportunitiesViewSelect
            onFilterValueChanged={this.handleFilterValueChanged('status')}
            filterValues={this.state.filterValues}
          />
        </GenericDropDownFilter>
        <GenericClearFilter
          onApply={this.handleClickApply}
          onClearFilters={this.handleClearFilters}
          onCancel={this.handleClickCancel}
        />
      </GenericDropDownContainer>
    )
  }
}

export default enhanceWithClickOutside(OpportunityFilterDropdown)