import React from 'react'
import { FormattedMessage } from 'react-intl'

export const afterFirstMeetingSurvey = {
  definitions: [],
  sections: [
    {
      code: 'participants',
      widgetClass: 'WidgetMeetingParticipants',
      sourceField: 'participants',
      primaryKey: 'id',
      params: {
        required: true
      }
    },
    {
      code: 'market_potential',
      widgetClass: 'WidgetMarketPotential',
      sourceField: 'market_potential',
      primaryKey: 'id',
      params: {
        required: true
      }
    },
    {
      code: 'products_interest',
      sourceField: 'products_interest',
      widgetClass: 'MinuteChoices',
      params: {
        options: {
          query:
            ' { products { product_uid name description code kind is_active full_description is_addon } } '
        },
        props: {
          primaryKeyName: 'product_uid',
          queryField: 'products',
          multiple: true,
          sectionName: (
            <FormattedMessage
              id="crm.ui.minute.form.product_interest"
              defaultMessage="Product Interest"
            />
          ),
          wrapperClassName: 'col-md-4'
        }
      }
    },
    // {
    //   code: 'customer_priorities',
    //   sourceField: 'customer_priorities',
    //   widgetClass: 'MinuteChoices',
    //   params: {
    //     options: {
    //       query:
    //         '{ dictionary_values(code:"crm.minutes.customer_priorities") { dictionary_value_uid name }  }  '
    //     },
    //     props: {
    //       primaryKeyName: 'dictionary_value_uid',
    //       queryField: 'dictionary_values',
    //       multiple: true,
    //       sectionName: 'Customer Priorities',
    //       wrapperClassName: 'col-md-4'
    //     }
    //   }
    // }
    {
      code: 'customer_priorities',
      sourceField: 'customer_priorities',
      widgetClass: 'MinuteChoices',
      params: {
        options: {
          query:
            '{ dictionary_values(code:"crm.minutes.customer_priorities") { dictionary_value_uid name }  }  '
        },
        props: {
          primaryKeyName: 'dictionary_value_uid',
          queryField: 'dictionary_values',
          multiple: true,
          sectionName: (
            <FormattedMessage
              id="crm.ui.minute.form.customer_priorities"
              defaultMessage="Customer Priorities"
            />
          ),
          wrapperClassName: 'col-md-4'
        }
      }
    },
    {
      code: 'remarks',
      widgetClass: 'WidgetMeetingRemarks',
      sourceField: 'remarks',
      primaryKey: 'id',
      params: {
        required: true
      }
    },
    {
      code: 'actions',
      widgetClass: 'WidgetMeetingActions',
      sourceField: 'actions',
      primaryKey: 'id',
      params: {
        required: true
      }
    },
    {
      code: 'current_system',
      widgetClass: 'WidgetCurrentSystem',
      sourceField: 'current_system',
      primaryKey: 'id',
      params: {
        required: false
      }
    },
    {
      code: 'adnotations',
      widgetClass: 'WidgetInternalNotes',
      sourceField: 'adnotations',
      params: {
        required: false
      }
    }
  ]
}

export const mock = 1
