/* eslint-disable camelcase */
import React, { useState } from 'react'
import { shape, func, bool, string, any } from 'prop-types'
import { toastr } from 'react-redux-toastr'
// import opportunityWinQl from './opportunity-win-ql-hoc'
import useOpportunityCommand from '../hooks/with-opportunity-command'
import { CrmButton, CrmDropdown } from 'crm-components'
import { qlqProcessType } from 'crm-data/processes'
import { qlqAccountOpportunities } from 'crm-data/opportunities'
import { Query } from 'react-apollo'
// class OpportunityWinForm extends PureComponent {
//   constructor(props) {
//     super(props)
//     this.state = {
//       contractWinDate: new Date(),
//       isDataVerified: false,
//       isVerified: false
//     }
//   }

//   handleDateChange = date => {
//     // console.log('handleDateChange', date)
//     this.setState({
//       contractWinDate: this.state.contractWinDate
//     })
//   }

//   handleSave = async event => {
//     event.preventDefault()
//     // console.log('Save Win')
//     if (!this.state.isVerified) {
//       return
//     }
//     try {
//       const res = await this.props.onSaveWin({
//         contractWinDate: this.state.contractWinDate,
//         opportunity_uid: this.props.opportunity.opportunity_uid
//       })
//       if (res) {
//         toastr.success('Opportunity updated as Win')
//       }
//     } catch (err) {
//       // console.log('Problem saving opp status', err)
//       // console.log(typeof err)
//       let message = 'Problem saving opportunity status'
//       if (err) {
//         message += ': ' + err
//       }
//       toastr.error(message)
//     }
//   }

//   handleChangeVerified = () => {
//     this.setState({ isVerified: !this.state.isVerified })
//   }

//   render() {
//     return (
//       <div style={{ backgroundColor: '#f5f5f5' }} className="util-paddingMd">
//         <span className="util-textGreen">
//           Congratulations! You Won a contract!
//         </span>
//         <div>
//           <div className="">
//             Contract Details:
//             {this.props.opportunity &&
//               this.props.opportunity.items &&
//               this.props.opportunity.items.map(item => (
//                 <div key={item.opportunity_item_uid}>
//                   {item.product ? item.product.name : 'Missing product!'},
//                   hardware: {item.price} {item.currency}, subscription:{' '}
//                   {item.subscription_price} {item.currency}
//                 </div>
//               ))}
//             <div>
//               <CrmCheckbox
//                 style={{ width: '100%' }}
//                 input={{
//                   value: this.state.isVerified,
//                   onChange: this.handleChangeVerified
//                 }}
//                 label="I have Verified contract details"
//               />
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-3">
//             <div>
//               <DatePicker
//                 placeholder="Date SA Signed"
//                 onChange={this.handleDateChange}
//                 value={this.state.contractWinDate}
//               />
//             </div>
//           </div>

//           <div className="col-md-6">
//             <CrmButton
//               disabled={!this.state.isVerified}
//               label="Save WIN"
//               type="primary"
//               grouped
//               onClick={this.handleSave}
//               isSaving={this.props.isSaving}
//             />
//             <CrmButton
//               label="Cancel"
//               type="cancel"
//               grouped
//               onClick={this.props.onCancel}
//             />
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// OpportunityWinForm.propTypes = {
//   opportunity: shape({}).isRequired,
//   onSaveWin: func.isRequired,
//   onCancel: func.isRequired,
//   isSaving: bool.isRequired
// }
// const OpportunityWinFormHoc = opportunityWinQl(OpportunityWinForm)

const OpportunityLostForm = props => {
  const [reasonLostUid, setReasonLostUid] = useState()
  const { isSaving, runOpportunityCommand } = useOpportunityCommand()

  const handleSaveLost = async event => {
    event.preventDefault()
    console.log('handleSaveLost', reasonLostUid)
    if (!reasonLostUid) {
      toastr.error('Select reason for Opportunity Lost')
      return
    }
    const command = {
      type: 'opportunity.lose',
      aggregate_uid: props.opportunity.opportunity_uid,
      aggregate_type: 'opportunity',
      payload: {
        reasonLostUid
      }
    }
    const refetchQueries = [
      {
        query: qlqAccountOpportunities,
        variables: { account_uid: props.opportunity.account_uid },
        partialRefetch: true
      }
    ]
    const res = await runOpportunityCommand(command, {
      refetchQueries
    })
    console.log('Result', res)
    if (res) {
      props.onSaved(res)
      return res
    }

    // const res = await this.props.onSaveLost({
    //   resultStepTypeUid: this.state.reasonLostUid,
    //   opportunity_uid: this.props.opportunity.opportunity_uid
    // })
    // if (res) {
    //   toastr.success('Opportunity marked as Lost')
    // }
  }

  const _getReasonsLost = () =>
    props.processType && props.processType.steps
      ? props.processType.steps
          .filter(s => s.kind === 'lost' && s.is_final_step)
          .sort((a, b) => a.step > b.step)
          .map(s => ({ name: s.name, value: s.process_step_type_uid }))
      : []

  console.log('render LostOpportunity', props)
  const reasonsLost = _getReasonsLost()
  return (
    <div
      style={{ backgroundColor: '#f5f5f5' }}
      className="util-paddingMd util-marginTop"
    >
      <span className="util-textWorning">
        {'Select reason for Opportunity Lost'}
      </span>
      <div className="row">
        <div className="col-md-6">
          <CrmDropdown
            options={reasonsLost}
            input={{
              onChange: setReasonLostUid,
              value: reasonLostUid
            }}
          />
        </div>

        <div className="col-md-6 util-textRight">
          <CrmButton
            label="Cancel"
            type="cancel"
            secondary
            grouped
            onClick={props.onCancel}
          />
          <CrmButton
            disabled={!reasonLostUid}
            label="Save"
            grouped
            type="primary"
            onClick={handleSaveLost}
            isSaving={isSaving}
          />
        </div>
      </div>
    </div>
  )
}

// OpportunityLostForm.propTypes = {
//   opportunity: shape().isRequired,
//   onSaveLost: func.isRequired,
//   processType: shape({}),
//   onCancel: func.isRequired,
//   isSaving: bool.isRequired,
//   onSaved: func.isRequired
// }
// OpportunityLostForm.defaultProps = {
//   processType: undefined
// }
// const OpportunityLostFormHoc = opportunityWinQl(OpportunityLostForm)
const WithQuery = props => {
  const variables = {
    process_type_uid: props.processTypeUid
      ? props.processTypeUid
      : props.lastProcessTypeUid
  }
  return (
    <Query
      skip={!props.processTypeUid && !props.lastProcessTypeUid}
      variables={variables}
      query={qlqProcessType}
    >
      {({ data }) => {
        return (
          <OpportunityLostForm
            {...props}
            processType={data ? data.process_type : undefined}
          />
        )
      }}
    </Query>
  )
}
// WithQuery.propTypes = {
//   processTypeUid: string.isRequired
// }
export default WithQuery
