import React, { SFC, useState } from 'react'
import { CrmButton, CrmModal } from '.'

interface Props {
  message: string
  buttonText?: string
}
const MessagePreview: SFC<Props> = ({ message, buttonText }) => {
  const [show, setShow] = useState(false)
  if (!show) {
    return (
      <CrmButton
        onClick={() => setShow(true)}
        label={buttonText || 'Preview'}
        small
      />
    )
  }
  return (
    <CrmModal
      isOpen={show}
      title="Preview"
      onClose={() => setShow(!show)}
      size="lg"
    >
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </CrmModal>
  )
}

export default MessagePreview
