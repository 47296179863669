import React from 'react'
import { bool, func, shape } from 'prop-types'
import { Link } from 'react-router-dom'

import { FormattedDate, FormattedTime } from 'react-intl'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { CrmModal } from 'crm-components'
import { qlqTasksTodoListPaged } from 'crm-data/tasks'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'

const columns = [
  {
    Header: 'Account Name',
    Cell: row => (
      <Link to={'/crm/account/' + row.original.account_uid} target="blank">
        {row.original.account_name}
      </Link>
    ),
    accessor: 'account_name'
  },
  {
    Header: 'Due Date',
    accessor: 'task_due_date',
    Cell: row =>
      row.original.task_due_date ? (
        <span>
          <FormattedDate value={row.original.task_due_date} />{' '}
          <FormattedTime value={row.original.task_due_date} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Task type',
    accessor: 'task_type_name'
  },
  {
    Header: 'Current Step',
    accessor: 'current_step_name'
  },
  {
    Header: 'Owner',
    accessor: 'task_owner_name'
  },
  {
    Header: 'Lead Source',
    accessor: 'lead_source_name'
  },
  {
    Header: 'Lead In Date',
    accessor: 'lead_in_date',
    Cell: row => (
      <span>
        <FormattedDate value={row.original.lead_in_date} />{' '}
        <FormattedTime value={row.original.lead_in_date} />
      </span>
    )
  }
]
const qlOptions = {
  options: ownProps => {
    console.log('qlOtions, ', ownProps)
    const props = { analyticsContext: { filters: ownProps.filters } }
    const filter = { ...ownProps.filters, ...mapContextToFilter(props) }

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.tasks_todo_list_qm_paged : []
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  query: qlqTasksTodoListPaged,
  qlOptions
})

class TasksDetailsPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Tasks Details"
        onClose={this.props.onClose}
        size="xl"
      >
        <Table
          columns={columns}
          style={{ width: '100%' }}
          filters={this.props.filters}
        />
      </CrmModal>
    )
  }
}

TasksDetailsPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: shape({})
}
TasksDetailsPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

export default TasksDetailsPopup
