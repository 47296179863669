import gql from 'graphql-tag'
import React from 'react'
import { bool, func, object, array, arrayOf } from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedTime } from 'react-intl'
import { CrmModal } from 'crm-components'
import { get } from 'lodash'

import { mapContextToFilter } from '../dashboard-utils'
import genericTableFactory from 'crm-components/ql/table/crm-generic-dynamic-table.tsx'
import { allColumns } from './dynamic-tables/columns'

const defaultQueryFields = {
  selections: [
    { name: 'account_uid' },
    { name: 'activity_uid' },
    { name: 'created_time' },
    { name: 'start_time' },
    { name: 'meta' },
    { name: 'first_type' },
    {
      name: 'account',
      selections: [
        { name: 'name' },
        { name: 'account_uid' },
        {
          name: 'lead_campaign',
          selections: [{ name: 'name' }, { name: 'campaign_uid' }]
        },
        { name: 'lead_in_date' }
      ]
    },
    {
      name: 'status',
      selections: [{ name: 'name' }, { name: 'is_success' }]
    },
    {
      name: 'primary_performer',
      selections: [
        {
          name: 'user',
          selections: [{ name: 'full_name' }, { name: 'user_uid' }]
        }
      ]
    },
    {
      name: 'meeting_minutes',
      selections: [{ name: 'completed_at' }, { name: 'details' }]
    },
    { name: 'first_type' },
    { name: 'activity_type', selections: [{ name: 'name' }] }
  ]
}

const columns = [
  {
    Header: 'Account Name',
    Cell: row => (
      <Link to={`/crm/account/${row.original.account_uid}`} target="blank">
        {get(row, 'original.account.name', 'Account')}
      </Link>
    ),
    accessor: 'account.name'
  },
  {
    Header: 'Lead In',
    accessor: 'account.lead_in_date',
    width: 100,
    Cell: row =>
      get(row, 'original.account.lead_in_date', false) ? (
        <FormattedDate value={row.original.account.lead_in_date} />
      ) : (
        <span />
      )
  },
  {
    Header: 'Lead Campaign',
    accessor: 'account.lead_campaign.name',
    width: 100,
    Cell: row => get(row, 'original.account.lead_campaign.name', <span />)
  },
  {
    Header: 'Type',
    accessor: 'activity_type.name',
    width: 120,
    Cell: row => get(row, 'original.activity_type.name', <span />)
  },
  {
    Header: 'Created',
    accessor: 'created_time',
    width: 120,
    Cell: row =>
      get(row, 'original.created_time', false) ? (
        <span>
          <FormattedDate value={row.original.created_time} />{' '}
          <FormattedTime value={row.original.created_time} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Activity date',
    accessor: 'start_time',
    width: 120,
    Cell: row =>
      get(row, 'original.start_time', false) ? (
        <span>
          <FormattedDate value={row.original.start_time} />{' '}
          {row.original.start_time && (
            <FormattedTime value={row.original.start_time} />
          )}
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Performer Name',
    accessor: 'primary_performer.user.full_name',
    Cell: row =>
      get(row, 'original.primary_performer.user', false) ? (
        <span>{row.original.primary_performer.user.full_name}</span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Status',
    accessor: 'status.name',
    Cell: row => get(row, 'original.status.name', '')
  },
  {
    Header: 'Minutes Completed At',
    accessor: 'meeting_minutes.completed_at',
    Cell: row =>
      get(row, 'original.meeting_minutes.completed_at', false) ? (
        <Link
          to={`/crm/account/${row.original.account_uid}/activity/${row.original.activity_uid}/meeting-minutes`}
          target="blank"
        >
          <FormattedDate value={row.original.meeting_minutes.completed_at} />{' '}
          {row.original.meeting_minutes.completed_at && (
            <FormattedTime value={row.original.meeting_minutes.completed_at} />
          )}
        </Link>
      ) : (
        <span />
      )
  },
  {
    Header: 'Minutes Location',
    accessor: 'meta',
    Cell: row =>
      get(row, 'original.meta.meeting_minutes_completed_location', false) ? (
        <span className="util-marginAuto">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`http://www.google.com/maps/place/${row.original.meta.meeting_minutes_completed_location.latitude},${row.original.meta.meeting_minutes_completed_location.longitude}`}
          >
            <i className="fa fa-map-marked-alt" />
          </a>
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'First?',
    accessor: 'first_type',
    width: 70
  }
]

const qlOptions = {
  options: ownProps => {
    const props = { analyticsContext: { filters: ownProps.filters } }
    const filter = { ...ownProps.filters, ...mapContextToFilter(props) }

    if (ownProps.filters.activity_date && !filter.start_date) {
      filter.start_date = ownProps.filters.activity_date
    }

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  qlOptions,
  rootQLType: 'Activity',
  useEdges: true,
  columnOptions: allColumns,
  defaultQueryFields,
  queryRoot: 'activities'
})

const getTrProps = (state, rowInfo) => {
  const ret = {}

  if (get(rowInfo, 'original', false)) {
    if (!get(rowInfo, 'original.status.is_success', false)) {
      ret.style = {
        opacity: 0.5
      }
    }
  }

  return ret
}
const ActivitiesPopup = props => {
  const { initialColumns, isOpen, onClose, filters } = props

  return (
    <CrmModal
      isOpen={isOpen}
      title="Activities Details"
      onClose={onClose}
      size="xl"
    >
      <Table
        columns={columns}
        style={{ width: '100%' }}
        filters={filters}
        getTrProps={getTrProps}
        defaultSorted={[
          {
            id: 'start_time',
            desc: false
          }
        ]}
        initialColumns={initialColumns}
      />
    </CrmModal>
  )
}

ActivitiesPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: object,
  initialColumns: arrayOf(object)
}

ActivitiesPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined,
  initialColumns: undefined
}

export default ActivitiesPopup
