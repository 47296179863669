import React, { useState, useEffect } from 'react'
import { OrganizationUnitWidget } from '../widgets/organization-unit-widget'
import { CrmFullpageContainer } from '../../../components/index'
import useCrmCurrentUser from '../../../core/auth/hooks/use-crm-current-user'
import { useGetUserInformation } from 'crm-duxs/user-session'
import { DashboardFilterBar, getWidgetAnalyticContext } from '../dashboards/tools'
import { UserInfoCard } from '../ct-dashboards/rm-dashboard/rm-summary-dashboard'
import { useGetAnalyticContextHeadCount } from './tools'
import { WidgetHeadCount } from '../widgets/headcount/headcount-wiget'
import { WidgetFitmentSummary } from '../widgets/fitment-summary/widget-fitment-summary'
import { WidgetCustomersWinRatioAgent } from '../widgets/wins/widget-customer-win-ratio-agent-widget'
import { WidgetNewValidLeads } from '../widgets/lead/new-valid-lead-widget'
import { WidgetInvalidValidLeads } from '../widgets/lead/invalid-lead-widget'
import { WidgetFirstCall } from '../widgets/call/first-call-widget'
import { WidgetFollowUpCall } from '../widgets/call/follow-up-call-widget'
import { WidgetAmountOfCall } from '../widgets/call/amount-of-call-widget'
import { WidgetTimeOnPhone } from '../widgets/call/time-on-phone-widget'
import { WidgetSaleToWinRatio } from '../widgets/sales/sale-to-win-ratio-widget'
import { WidgetCustomersWon } from '../widgets/wins/widget-customers-won'
import { CrmLoadingIcon } from 'crm-components'
import { useCrmFilterContext } from '../../dashboard/dashboards/dashboard-context/crm-filter-context'
import { ReportProvider } from 'crm-utils/reports-utils'

export const TeleSaleManagerDashboard: React.SFC = props => {
  const currentUser = useCrmCurrentUser()
  const analyticsContext = useCrmFilterContext()
  const userUid = currentUser?.user_uid
  const { loading, getOrganizationUnit } = useGetUserInformation(userUid)
  const organizationUnit = getOrganizationUnit()
  const topOrganizationUnit = organizationUnit?.organization_unit_uid
  const [selectedOrganizationUnit, setOrganizationUnit] = useState<string>(organizationUnit?.organization_unit_uid)
  const [organizationName, setOrganizationName] = useState<string>(organizationUnit.name)
  useEffect(() => {
    if (!loading) {
      onChangeOrganization(organizationUnit)
    }
  }, [organizationUnit.organization_unit_uid])
  const onChangeOrganization = (organization): void => {
    const nameValue = organization?.name ?? organizationUnit?.name
    const uidValue = organization?.organization_unit_uid ?? organizationUnit?.organization_unit_uid
    setOrganizationName(nameValue)
    setOrganizationUnit(uidValue)
    const newFilter = {
      organization_unit: { organization_unit_uid: { value: uidValue, label: nameValue } },
      user_uid: undefined
    }
    analyticsContext.setFilters(newFilter)
  }

  return (
    <CrmFullpageContainer>
      {!loading && (
        <React.Fragment>
          <DashboardFilterBar
            topOrganizationUnit={topOrganizationUnit}
            selectedOrganizationUnit={selectedOrganizationUnit}
            onChangeOrganization={onChangeOrganization}
            organizationName={organizationName}
          />
          <TeleSaleManagerDashboardInner />
        </React.Fragment>
      )}
    </CrmFullpageContainer>
  )
}
type TeleSaleManagerDashboardInnerProps = {
  analyticsContext?: any
}
export const TeleSaleManagerDashboardInner: React.SFC<TeleSaleManagerDashboardInnerProps> = () => {
  const analyticsContext = getWidgetAnalyticContext()
  const userInfo = useGetUserInformation(useCrmCurrentUser()?.user_uid)
  if (!analyticsContext?.filters?.organization_unit && !userInfo.loading) {
    const organizationUnit = userInfo.getOrganizationUnit()
    console.log('organizationUnit', organizationUnit)
    analyticsContext.filters.organization_unit = {
      organization_unit_uid: {
        value: organizationUnit.organization_unit_uid,
        label: organizationUnit.name
      }
    }
  }
  const [headcounts, loading] = useGetAnalyticContextHeadCount(analyticsContext)
  const user = { user_uid: useCrmCurrentUser()?.user_uid }
  const organizationUnitName = analyticsContext?.filters?.organization_unit?.organization_unit_uid?.label
  return (
    <React.Fragment>
      {loading && userInfo.loading && (
        <div className="util-relative util-fullExpand">
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)'
            }}
          >
            <CrmLoadingIcon />
          </div>
        </div>
      )}
      {!loading && !userInfo.loading && (
        <div className="util-paddingXl">
          <div className="row">
            <div className="col-md-2">
              {organizationUnitName && <OrganizationUnitWidget organizationUnitName={organizationUnitName} />}
              {!organizationUnitName && <UserInfoCard user={user} />}
            </div>
            <div className="col-md-3">
              <WidgetHeadCount headcounts={headcounts} title="Total Number Agents" />
            </div>
            <ReportProvider>
              <div className="col-md-3">
                <WidgetFitmentSummary headcounts={headcounts} mode="Agent" />
              </div>
              <div className="col-md-3">
                <WidgetCustomersWon headcounts={headcounts} mode="Agent" />
              </div>
              <div className="col-md-2" />
              <div className="col-md-3">
                <WidgetCustomersWinRatioAgent headcounts={headcounts} />
              </div>
              <div className="col-md-3">
                <WidgetNewValidLeads headcounts={headcounts} />
              </div>
              <div className="col-md-3">
                <WidgetInvalidValidLeads headcounts={headcounts} />
              </div>
              <div className="col-md-2" />
              <div className="col-md-3">
                <WidgetFirstCall headcounts={headcounts} />
              </div>
              <div className="col-md-3">
                <WidgetFollowUpCall headcounts={headcounts} />
              </div>
              <div className="col-md-3">
                <WidgetAmountOfCall headcounts={headcounts} />
              </div>
              <div className="col-md-2" />
              <div className="col-md-3">
                <WidgetTimeOnPhone headcounts={headcounts} />
              </div>
            </ReportProvider>
            {/* We keep it disabled for now
              <div className="col-md-3">
              <WidgetSaleToWinRatio
                analyticsContext={analyticsContext}
                headcounts={headcounts}
              />
            </div> */}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
