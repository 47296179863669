import styled, { css } from 'styled-components'
import { CrmToastrType, CrmToastrPosition } from '../toastrs/index'
import { CrmButton } from '@cartrack-crm/ui'

const getBarColor = (type: CrmToastrType) => {
  switch (type) {
    case CrmToastrType.info:
      return css`
        background-color: var(--crm-color-blue);
      `
    case CrmToastrType.success:
      return css`
        background-color: var(--crm-color-green);
      `
    case CrmToastrType.warning:
      return css`
        background-color: var(--crm-color-yellow50);
      `
    case CrmToastrType.error:
      return css`
        background-color: var(--crm-color-orange);
      `
  }
}

const getColorType = (type: CrmToastrType) => {
  switch (type) {
    case CrmToastrType.info:
      return css`
        color: var(--crm-color-blue);
      `
    case CrmToastrType.success:
      return css`
        color: var(--crm-color-green);
      `
    case CrmToastrType.warning:
      return css`
        color: var(--crm-color-yellow50);
      `
    case CrmToastrType.error:
      return css`
        color: var(--crm-color-orange);
      `
  }
}

const getTypeCss = (type: CrmToastrType) => {
  switch (type) {
    case CrmToastrType.info:
      return css`
        border: var(--crm-border-solid-md) var(--crm-color-blue);
        color: unset;
      `
    case CrmToastrType.success:
      return css`
        border: var(--crm-border-solid-md) var(--crm-color-green);
        color: unset;
      `
    case CrmToastrType.warning:
      return css`
        border: var(--crm-border-solid-md) var(--crm-color-yellow50);
        color: unset;
      `
    case CrmToastrType.error:
      return css`
        border: var(--crm-border-solid-md) var(--crm-color-orange);
        color: unset;
      `
  }
}

const getPositionCss = (position: CrmToastrPosition) => {
  switch (position) {
    case CrmToastrPosition.topRight:
      return css`
        right: 10px;
        top: 100px;
      `
    case CrmToastrPosition.topLeft:
      return css`
        left: 10px;
        top: 100px;
      `
    case CrmToastrPosition.topMiddle:
      return css`
        left: 50%;
        top: 100px;
        transform: translate(-50%, 0);
      `
    case CrmToastrPosition.middleLeft:
      return css`
        left: 10px;
        top: 50%;
        transform: translate(0, -50%);
      `
    case CrmToastrPosition.middle:
      return css`
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      `
    case CrmToastrPosition.middleRight:
      return css`
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
      `
    case CrmToastrPosition.bottomLeft:
      return css`
        bottom: 0;
        left: 10px;
      `
    case CrmToastrPosition.bottomMiddle:
      return css`
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      `
    case CrmToastrPosition.bottomRight:
      return css`
        bottom: 0;
        right: 10px;
      `
  }
}

interface ICrmWrapper {
  position: CrmToastrPosition
  type: CrmToastrType
}

export const CrmToastrWrapper = styled.div<ICrmWrapper>`
  background-color: var(--crm-color-white) !important;
  border-radius: var(--crm-border-radius-lg);
  box-shadow: var(--crm-box-shadow);
  color: var(--crm-color-gray80);
  max-width: 500px;
  min-width: 477px;
  padding: var(--crm-padding-30) !important;
  position: fixed;
  z-index: 99999999;
  ${({ type }) => getTypeCss(type)}
  ${({ position }) => getPositionCss(position)}
}
`

interface ICrmToastrTitle {
  titleOnly?: boolean
}

export const CrmToastrTitle = styled.div<ICrmToastrTitle>`
  display: block;
  font-size: var(--crm-font-size-lg);
  font-weight: var(--crm-font-weight-light);
  margin-bottom: var(--crm-padding-20);
  ${({ titleOnly }) =>
    titleOnly &&
    css`
      text-align: center;
      margin-button: unset;
    `}
`

export const CrmToastrMessage = styled.div`
  font-size: var(--crm-font-size-md);
  min-height: var(--crm-padding-36);
`
interface ICrmToastrGroupBtn {
  isShowMessageBlock: boolean
}
export const CrmToastrGroupBtn = styled.div<ICrmToastrGroupBtn>`
  display: block;
  float: right;
  height: ${({ isShowMessageBlock }) => (isShowMessageBlock ? '44px' : '88px')}
  margin-top: ${({ isShowMessageBlock }) => (isShowMessageBlock ? '0px' : '44px')}
`

export const CrmToastrButton = styled(CrmButton)`
  border-radius: var(--crm-border-radius-md);
  box-shadow: unset;
  font-size: var(--crm-font-size-sm) !important;
  height: 28px;
  min-width: 70px;
  padding: unset !important;
  margin-right: var(--crm-padding-12);
`
export const ExpandIcon = styled.i`
  color: var(--crm-color-gray60);
  cursor: pointer;
  font-size: var(--crm-font-size-sm);
  margin-left: var(--crm-padding-08);
`
interface ICrmToastrDetailTitle {
  type: CrmToastrType
}
export const CrmToastrDetailTitle = styled.div<ICrmToastrDetailTitle>`
  display: flex;
  font-size: var(--crm-font-size-xs);
  font-weight: var(--crm-font-weight-strong);
  margin-bottom: var(--crm-padding-14);
  margin-top: 25px;
  ${({ type }) => getColorType(type)}
`

export const CrmToastrContent = styled.div`
  background-color: var(--crm-color-white);
  border: var(--crm-border-solid-sm) var(--crm-color-gray60);
  border-radius: var(--crm-border-radius-md);
  font-size: var(--crm-font-size-sm);
  margin-bottom: var(--crm-padding-48);
  max-height: 400px;
  max-width: 500px;
  min-height: 250px;
  overflow: scroll;
  padding: var(--crm-padding-08);

  .text-detail-line {
    margin-bottom: var(--crm-padding-04);
  }
`

export const CrmToastrLineWrapper = styled.div`
  height: var(--crm-padding-12);
  width: 100%;
`

export const CrmToastrLine = styled.div`
  border: 0.5px solid var(--crm-color-medium-gray);
  margin-left: var(--crm-padding-12);
  position: absolute;
  top: 50%;
  width: calc(100% - 12px);
`
interface ICrmToastrProgressBar {
  type: CrmToastrType
  progress: string
}
export const CrmToastrProgressBar = styled.div<ICrmToastrProgressBar>`
  border-radius: var(--crm-border-radius-lg);
  bottom: 0;
  height: 5px;
  left: 0;
  position: absolute;
  width: ${({ progress }) => progress};
  transition: 'width .1s ease-in';
  ${({ type }) => getBarColor(type)}
`
