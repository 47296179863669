/* eslint-disable no-unused-vars */
import React, { PureComponent, createElement } from 'react'
import { any, bool, shape, func, string, arrayOf, node } from 'prop-types'
import { Field } from 'redux-form'
import { injectIntl } from 'react-intl'

class CrmValidatedField extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
    this.requiredValidate = value =>
      value !== undefined
        ? undefined
        : this.props.intl.formatMessage({
            id: 'crm.ui.activity.form.field_is_required',
            defaultMessage: 'This field is required'
          })
  }

  renderField = ({
    input,
    label,
    type,
    component,
    meta: { touched, error, warning }
  }) => {
    const hasErrors = error !== undefined
    const props = this.props
    // Problem console.log('touched ' + touched)
    // There is some problem and touched is always false..
    let propsToComponent = { ...input }
    if (props.componentProps) {
      propsToComponent = { ...propsToComponent, ...props.componentProps }
    }
    return (
      <div
        className={`CrmValidatedField ${
          error === undefined
            ? 'CrmValidatedField-isOk --isOk'
            : 'CrmValidatedField-hasErrors --hasErrors'
        }`}
      >
        <div className="CrmValidatedField-fieldWrapper">
          {createElement(props.component, {
            ...propsToComponent,
            className: `${hasErrors ? '--hasErrors' : ''} ${props.className}`,
            type: this.props.type,
            input,
            hasErrors,
            disabled: this.props.disabled,
            ...this.props.props
          })}
        </div>
        {error && <div className="CrmValidatedField-errors">{error}</div>}
      </div>
    )
  }

  render() {
    let validate
    const props = this.props
    if (this.props.isRequired) {
      validate = this.requiredValidate
    }
    return (
      <Field
        {...this.props}
        placeholder={this.props.placeholder}
        component={this.renderField}
        name={this.props.name}
        options={this.props.options}
        componentProps={this.props.componentProps}
        validate={validate}
        onChange={this.props.onChange}
        props={this.props.props}
      />
    )
  }
}

CrmValidatedField.propTypes = {
  children: any, // eslint-disable-line
  isRequired: bool.isRequired,
  onChange: func,
  componentProps: shape({}),
  type: string,
  placeholder: node,
  name: string.isRequired,
  options: arrayOf(shape()),
  disabled: bool,
  props: shape()
}

CrmValidatedField.defaultProps = {
  children: undefined,
  isRequired: false,
  componentProps: undefined,
  onChange: () => {},
  type: undefined,
  placeholder: undefined,
  options: undefined,
  disabled: false,
  props: undefined
}

export default injectIntl(CrmValidatedField)
