import React, { useEffect, useState } from 'react'
import {
  JsonEditorRenderCardProps,
  JsonEditorRenderCard
} from '../json-editable/index'
import {
  CrmLabeledField,
  CrmCard,
  CrmButton,
  CrmJsonPreview,
  CrmModal,
  CrmJsonPreviewInline
} from '@cartrack-crm/ui'
import {
  ModalGeneralSection,
  ModalEditContentSection,
  ModalEditFiltersSection
} from '.'
import { useComponentConfigurationContext } from '../configuration-tools/component-configuration-context'
import { faPen, faCode } from '@fortawesome/free-solid-svg-icons'

export interface IComponentSectionAllJson {
  onSaveDefinition?: (data: any, type: string) => any
}
export const ComponentSectionAllJson: React.FC<IComponentSectionAllJson> = (
  props
) => {
  const [showGeneralForm, setShowGeneralForm] = useState(false)
  const [showContentForm, setShowContentForm] = useState(false)
  const [showFiltersForm, setShowFiltersForm] = useState(false)
  const [isShowwingJsonPopup, setIsShowingJsonPopup] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const componentConfigurationContext = useComponentConfigurationContext()
  const component = componentConfigurationContext.component

  const onSaveComponent = (pData) => {
    if (props.onSaveDefinition) {
      props.onSaveDefinition(pData, 'all')
    }
  }
  return (
    <CrmCard className="util-paddingMd">
      <div className="CrmCard-content">
        <div className="row">
          <div className="col-md-6">
            <strong>
              <small>{component.name}</small>
            </strong>
            {/* TODO TON - this word "General" can be replaced by widget name and make it inline editable like account name */}
          </div>
          <div className="col-md-3">
            <CrmButton
              small
              style={{ minWidth: '0px ', margin: '0px ' }}
              className="util-marginLeft"
              icon={faCode}
              iconButton
              onClick={() => setIsShowingJsonPopup(true)}
            />
          </div>
          <div className="col-md-3">
            <CrmButton
              small
              style={{ minWidth: '0px !important', margin: '0px !important' }}
              className="util-marginLeft"
              icon={faPen}
              iconButton
              onClick={() => setShowGeneralForm(true)}
            />
          </div>
          {/* TODO TON: on edit we could swith to isEditing: true and then show this general form inline here - there is not so much there */}
        </div>
        <div className="row">
          {/* <div className="col-md-3">
            <CrmLabeledField
              label="Dashboard Uid"
              value={component?.dashboard_uid ?? '-'}
            />
          </div> */}
          <div className="col-md-12">
            <CrmLabeledField label="Name" value={component?.name ?? '-'} />
          </div>
          {isExpanded && (
            <React.Fragment>
              <div className="col-md-12">
                <CrmLabeledField
                  label="Dashboard Code"
                  value={component?.code ?? '-'}
                />
              </div>

              <div className="col-md-4">
                <CrmLabeledField
                  label="Access level"
                  value={component?.access_level ?? '-'}
                />
              </div>
              <div className="col-md-4">
                <CrmLabeledField label="Type" value={component?.type ?? '-'} />
              </div>
              <div className="col-md-4">
                <CrmLabeledField
                  label="is Published"
                  value={component?.is_published ? 'True' : 'False'}
                />
              </div>
              <div className="col-md-3">
                <CrmLabeledField
                  label="Enable Export Excel"
                  value={component?.enable_excel_export ? 'True' : 'False'}
                />
              </div>
              <div className="col-md-3">
                <CrmLabeledField
                  label="Definition Version"
                  value={component?.def_version ?? '-'}
                />
              </div>
            </React.Fragment>
          )}

          {
            <div
              onClick={() => {
                setIsExpanded(!isExpanded)
              }}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </div>
          }
        </div>
        {/* <label>
          Content Section{' '}
          <CrmButton
            label="Edit"
            small
            className="util-marginLeft"
            onClick={() => setShowContentForm(true)}
          />
        // </lbel> */}
        {/* <CrmJsonPreview json={component?.content ?? {}} /> */}
        {/* <label>
          Test Filters Section
          <CrmButton
            label="Edit"
            small
            className="util-marginLeft"
            onClick={() => setShowFiltersForm(true)}
          />
        </label> */}
        <CrmModal
          isOpen={isShowwingJsonPopup}
          onClose={() => setIsShowingJsonPopup(false)}
        >
          <div style={{ height: '100%' }} className="util-flexColumn">
            <h5>Component:</h5>
            <JsonEditorRenderCard
              data={component ?? {}}
              onSave={(newData) => {
                console.log('Update definition')
                componentConfigurationContext.setComponentDefinition(newData)
              }}
            />
            <h5>Filters:</h5>
            <CrmJsonPreview json={component?.test_filters ?? {}} />
          </div>
        </CrmModal>
      </div>
      <ModalGeneralSection
        isOpen={showGeneralForm}
        onClose={() => setShowGeneralForm(false)}
        onSave={onSaveComponent}
        data={component}
      />
      <ModalEditContentSection
        isOpen={showContentForm}
        onClose={() => setShowContentForm(false)}
        onSave={onSaveComponent}
        data={component}
      />
      <ModalEditFiltersSection
        isOpen={showFiltersForm}
        onClose={() => setShowFiltersForm(false)}
        onSave={onSaveComponent}
        data={component}
      />
    </CrmCard>
  )
}
