/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { shape, string, func, bool } from 'prop-types'
import SectionOpportunities from '../_section-opportunities.jsx'
import InfoNoAccountPermission from '../info_no_account_permission.jsx'

class TabOportunities extends PureComponent {
  render() {
    if (this.props.hasPermissionToReadOpportunities === false) {
      return <InfoNoAccountPermission />
    }

    const { account } = this.props
    if (account) {
      return (
        <div className="row">
          <div className="col-md-12">
            <SectionOpportunities
              account={account}
              accountUid={account.account_uid}
              processUid={this.props.processUid}
              process={this.props.process}
              handleQuotationClick={this.props.handleQuotationClick}
              handleQuickQuotationOpen={this.props.handleQuickQuotationOpen}
              handleShowOpportunityHistory={
                this.props.handleShowOpportunityHistory
              }
            />
            {/* )} */}
          </div>
        </div>
      )
    } else {
      return <div> No account </div>
    }
  }
}

TabOportunities.defaultProps = {
  account: undefined,
  prospect: undefined,
  processUid: undefined
}

TabOportunities.propTypes = {
  account: shape({}).isRequired,
  processUid: string,
  process: shape({}).isRequired,
  handleQuotationClick: func.isRequired,
  hasPermissionToReadOpportunities: bool.isRequired,
  handleQuickQuotationOpen: func.isRequired,
  handleShowOpportunityHistory: func.isRequired
}

export default TabOportunities
