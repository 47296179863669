import React, { Component } from 'react'
import enhanceWithClickOutside from 'react-click-outside'

class AccountPageRightSidePanelInner extends React.PureComponent {
  handleClickOutside = () => {
    // console.log('handleClickOutside')
    if (this.props.onClickOutside && !this.props.preventClosingOnClickOutside) {
      this.props.onClickOutside()
    }
  }
  render() {
    return this.props.children
  }
}

const WithClickOut = enhanceWithClickOutside(AccountPageRightSidePanelInner)
class AccountPageRightSidePanel extends Component {
  render() {
    // if (!this.props.isOpen) {
    //   return <span />
    // }
    return (
      <div
        className={`AccountPageRightSidePanel-wrapper
       ${this.props.isOpen ? 'AccountPageRightSidePanel--open' : ''}`}
      >
        <div className="AccountPageRightSidePanel-inner">
          {this.props.isOpen && (
            <WithClickOut
              onClickOutside={this.props.onClickOutside}
              preventClosingOnClickOutside={
                this.props.preventClosingOnClickOutside
              }
            >
              <div className="AccountPageRightSidePanel-content">
                {this.props.children}
              </div>
            </WithClickOut>
          )}
        </div>
      </div>
    )
  }
}

export default AccountPageRightSidePanel
