import React from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
interface ICrmTextArea {
  input: {
    value?: string,
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  }
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any
  rows?: number
  disabled?: boolean
  focus?: boolean
  placeholder?: string | React.ElementType
  meta?: {
    valid: boolean
    touched: boolean
    error: string
  }
  extraClassNames?: {
    containerClassNames?: string
    inputClassNames?: string
    placeholderClassNames?: string
  },
  type?: string
  id?: string
  iconName?: string
  inputRef?: React.LegacyRef<HTMLTextAreaElement>
  intl: any,
  placeholderId?: string
}

export const CrmTextArea: React.SFC<ICrmTextArea> = (props) => {
  const {
    intl,
    input,
    onClick,
    focus,
    disabled,
    extraClassNames,
    rows,
    id,
    meta,
    placeholder,
    iconName,
    inputRef,
    placeholderId
  } = props

  const isInValid = meta?.touched && !meta?.valid
  return (<div
    className={classNames('CrmTextArea', { [`${extraClassNames?.containerClassNames}`]: extraClassNames?.containerClassNames, 'is-invalid': isInValid })}
    onClick={onClick}
  >
    <textarea
      ref={inputRef}
      autoFocus={focus}
      className={`${extraClassNames?.inputClassNames} CrmTextArea-input`}
      value={input && input.value ? input.value : ''}
      disabled={disabled}
      onChange={input?.onChange}
      onBlur={input?.onBlur}
      id={id}
      rows={rows}
    />
    {placeholder && (
      <div
        className={classNames('CrmTextArea-placeholder', { [`${extraClassNames?.placeholderClassNames}`]: extraClassNames?.placeholderClassNames, 'is-withValue': input?.value })}
      >
        {typeof placeholder === 'string'
          ? intl.formatMessage({
            id: placeholderId || `form.placeholder.${placeholder}`,
            defaultMessage: placeholder
          })
          : placeholder}
      </div>
    )}
    {iconName && (
      <i
        className={`fa fa-${iconName} CrmTextArea-faIcon`}
        aria-hidden="true"
      />
    )}
    <span className="CrmTextArea-error">{meta?.error}</span>
  </div>)
}

export const CrmTextAreaIntl = injectIntl(CrmTextArea)
export default CrmTextAreaIntl
