import gql from 'graphql-tag'

export const qlResourceAllFields =
  ' resource_uid name description size mime_type is_deleted account_uid activity_uid created_time  parsed meta resource { file_link } '

export const qlqListResources = gql`
  query resource_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    resource_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        ${qlResourceAllFields}
      }
    }
  }
`

export const qlqAccountResources = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      resources {
        resource_uid
        name
        description
        file_link
        size
      }
    }
  }
`

export const qlGetResource = gql`
  query resources($resource_uid: String) {
    resources(resource_uid: $resource_uid) {
      resource_uid
      name
      description
      size
      mime_type
      is_deleted
      account_uid
      activity_uid
      created_time
      parsed
      meta
    }
  }
`
