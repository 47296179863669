import { callQLApi } from './api-utils'

const crmApi = {
  executeFunction: (functionName, params) => {
    const query = {
      query: `mutation execute_function($function: String, $params: JSON) { execute_function(function: $function, params: $params) { result data } }`,
      variables: { function: functionName, params }
    }
    return callQLApi(query).then(res => res.execute_function.data)
  }
}
export default crmApi
