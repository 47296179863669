import React, { createContext, useContext } from 'react'

export const BulkOperationsContext: any = createContext({
  onShowBulkOperations: undefined
})

export type BulkOperationsContextType = {
  onShowBulkOperations?: Function
}

export const BulkOperationsContextProvider = ({ children, onShowBulkOperations }) => {
  return <BulkOperationsContext.Provider value={{ onShowBulkOperations }}>{children}</BulkOperationsContext.Provider>
}

export const useBulkOperationsContext = (): BulkOperationsContextType => useContext(BulkOperationsContext)

export const withBulkOperationsContext = WrappedComponent => props => {
  const bulkOperationsContext = useBulkOperationsContext()
  return <WrappedComponent {...props} bulkOperationsContext={bulkOperationsContext} />
}
