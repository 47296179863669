/* eslint-disable global-require, react/prefer-stateless-function */
import React, { PureComponent } from 'react'
import { shape, any, arrayOf, bool } from 'prop-types'
import { CrmButton } from 'crm-components'

class CrmFormHeader extends PureComponent {
  handleClick = () => {}
  render() {
    const { actions } = this.props
    const hasActions = actions !== undefined

    return (
      <div
        className={`CrmFormHeader ${hasActions && 'CrmFormHeader--hasActions'}`}
      >
        <div>{this.props.children}</div>
        {actions && (
          <div className="CrmFormHeader-actions-wrapper">
            {actions.map(action => (
              <CrmButton
                key={action.label}
                disabled={this.props.disabled}
                label={action.label}
                onClick={action.onClick ? action.onClick : undefined} // eslint-disable-line react/jsx-handler-names
                className={`CrmFormHeader-action 
                ${
                  action.type &&
                  (action.type === 'primary' || action.type === 'submit')
                    ? 'is-blue'
                    : ''
                }`}
                type={action.type}
                icon={action.type}
                small
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}

CrmFormHeader.defaultProps = {
  actions: [],
  disabled: false
}

CrmFormHeader.propTypes = {
  children: any.isRequired, // eslint-disable-line react/forbid-prop-types
  actions: arrayOf(shape({})),
  disabled: bool
}

export default CrmFormHeader
