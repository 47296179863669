/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'

import { dialerCampaignQl } from './telesales-dash-utils'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { graphql, compose } from 'react-apollo'
import { arrayOf, shape, func } from 'prop-types'
import { CrmFullpageContainer, CrmDropdown, CrmButton } from 'crm-components'
import { buildQlQueryByFields } from 'crm-utils/data-utils'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  Label
} from 'recharts'

import CrmWidget from '../../components/crm-widget.jsx'
class DashTelesalesCampaignStats extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ ...this.state, isLoading: val }, handler)
  }

  parseData(result) {
    console.log('DashLeadsDetails parseData')
    console.dir(result)
    this.setState({ ...this.state, data: result.data }, () => {
      this.toggleIsLoading(false)
    })
  }

  refreshStats = async () => {
    console.log('DasyTelesalesStatsByStatus.refreshStats by status', this.props)
    try {
      const filter = mapContextToFilter(this.props)
      console.log('filter', filter)
      const variables = {
        filter,
        fields: ['current_step_name', 'current_step_type_uid'],
        aggregate: [{ id: 'process_uid', type: 'count' }]
      }
      if (this.props.leadInDateRange) {
        variables.filter.lead_in_date = {
          $gte: this.props.leadInDateRange.startDate,
          $lte: this.props.leadInDateRange.endDate
        }
      }

      const res = await this.props.client.query({
        query: buildQlQueryByFields(dialerCampaignQl, variables),
        variables,
        fetchPolicy: 'no-cache'
      })

      console.log('Got result', res)
      this.setState({
        statsByCurrentStepResults: res.data
          .dialer_campaign_accounts_list_qm_paged
          ? res.data.dialer_campaign_accounts_list_qm_paged.data
          : undefined
      })
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }

  handleCurrentStepClick = event => {
    console.log('handleCurrentStepClick', event)
    this.setState({ selectedStepTypeUid: event.currentTarget.id })
    this.props.onCurrentStepClick({
      process_step_type_uid: event.currentTarget.id
    })
  }
  handleRefresh = async (event, props) => {
    console.log('handleRefresh', this.props)
    await this.refreshStats()
    // Build filters
  }
  handleClickBar = event => {
    console.log('handleClickBar', event, this.props)
    const filters = {
      ...this.props.analyticsContext.filters,
      current_step_name: event.name
    }

    this.props.onAnalyticsRangeSelected({ type: 'lead', filters })
  }
  renderChart(stats) {
    const data = stats
      .map(st => ({
        name: st.current_step_name,
        value: st.count
      }))
      .sort((a, b) => {
        return b ? b.value - a.value : 0
      })
    console.log('render chart data', data)
    const renderLabel = props => {
      console.log('render Label by status', props)
      return <div sty>Label</div>
    }
    // width={730}
    // height={250}
    return (
      <ResponsiveContainer width="90%" height="90%">
        <BarChart data={data} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis dataKey="name" type="category" tick={{ fontSize: 15 }} />
          <XAxis type="number" />
          <Tooltip />
          {/* <Legend /> */}
          <Bar
            dataKey="value"
            fill="#8884d8"
            label={{ fill: 'red', fontSize: 20 }}
            onClick={this.handleClickBar}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  }
  handleGetRawData = () => this.state.statsByCurrentStepResults

  render() {
    console
    const allAccountsCount = this.state.statsByCurrentStepResults
      ? this.state.statsByCurrentStepResults.reduce((a, i) => a + i.count, 0)
      : 0
    return (
      <CrmWidget
        title={' Campaign Leads by current status'}
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        fixHeight
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-campaign-leads-by-current-status"
      >
        {this.state.statsByCurrentStepResults &&
          this.renderChart(this.state.statsByCurrentStepResults)}
        {/* <div>
            <CrmButton label="Refresh Stats" onClick={this.handleRefresh} />
            {this.state.statsByCurrentStepResults && (
              <div className="row">
                <div className="col-md-2">
                  <div>Statistics by process step</div>
                  <div>All accounts: {allAccountsCount}</div>
                </div>
                <div className="col-md-10 util-flexRow">
                  {this.renderChart(this.state.statsByCurrentStepResults)}
                  {/* {this.state.statsByCurrentStepResults.map(r => (
                <div
                  key={r.current_step_name}
                  id={r.current_step_type_uid}
                  className="util-paddingMd util-pointer util-hooverGray"
                  style={{ border: '1px solid grey', margin: 10 }}
                  onClick={this.handleCurrentStepClick}
                >
                  {r.current_step_name}
                  <strong className="util-marginMd">{r.count}</strong>
                </div>
              ))} 
                </div>
              </div>
            )}
            <div>selectedStepTypeUid : {this.state.selectedStepTypeUid}</div>
            <small>{JSON.stringify(this.props.analyticsContext)}</small>
          </div> */}
      </CrmWidget>
    )
  }
}

DashTelesalesCampaignStats.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  onCurrentStepClick: func.isRequired
}

DashTelesalesCampaignStats.defaultProps = {
  dashboards: []
}

const DashTelesalesCampaignStatsQL = withApollo(DashTelesalesCampaignStats)

export default DashTelesalesCampaignStatsQL
