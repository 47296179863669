const agentOwnedLeads = {
  type: 'item',
  label: 'Agent Owned Leads Ageing',
  code: 'ctAgentOwnedLeads',
  dashboard_uid: 'ctAgentOwnedLeads',
  defaultFilters: {
    organization_unit: {
      department_type_uid: {
        value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
        label: 'Telesales'
      }
    }
  },
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'ctDailyLeadsDashboard',
        def_version: 5,
        rev: '2019-08-13',
        title: 'Agent Owned Leads Ageing (Not support activity date)',
        description: 'Ageing based on date when lead was assigned to latest owner',
        enable_excel_export: true,
        content: {
          data: {
            dataSources: [
              {
                code: 'AgentOwned',
                name: 'AgentOwned',
                type: 'QM',
                filter: {
                  sales_result_code: { $in: ['valid', 'lead'] },
                },
                contextMapping: {
                  owner_user_uid: 'user'
                },
                qlQuery: `
                query agent_owned_lead_ageing($filter: JSON, $limit: Int, $sort: [JSON]) {  
                      agent_owned_lead_ageing(
                          filter: $filter
                          ,limit:$limit
                          ,sort:$sort
                      ) {
                          data{
                              owner_user_uid
                              owner_user_full_name
                              owner_contract_start_date
                              owner_is_active
                              ageing_current
                              ageing_1
                              ageing_2
                              ageing_3_4
                              ageing_5_plus
                              total_leads
                          }
                      }
                  }`,
                qlEdges: false,
                qlRoot: 'agent_owned_lead_ageing'
              }
            ]
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                initialSort: {
                  id: 'owner__contract_start_date',
                  order: 'desc'
                },
                mob_fix_columns: 2,
                props: {
                  generate_summary: true,
                  generate_average: true,
                  columnGroups: [
                    {
                      Header: '',
                      colSpan: 3
                    },
                    {
                      Header: 'Current Status - Pendings',
                      colSpan: 6
                    }
                  ],
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 30
                    },
                    {
                      header: 'Owner Name',
                      width: 150,
                      accessor: 'owner_user_full_name',
                      classNameHandler: row => (row.original.owner_is_active ? '' : 'util-opacity50'),
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user_uid: 'owner_user_uid'
                      }
                    },
                    {
                      header: 'Start Date',
                      accessor: 'owner_contract_start_date',
                      width: 70,
                      align: 'center',
                      headerAlign: 'center'
                    },
                    {
                      header: 'Current',
                      accessor: 'ageing_current',
                      detailsPopup: 'account',
                      detailsFilter: {
                        latest_owner_user_uid: { $ne: 'null' },
                        current_owner_ageing_category: 'ageing_current',
                        sales_result_code: { $in: ['valid', 'lead'] }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'owner_user_uid'
                      },
                      contextMapping: {
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      },
                      className: 'util-textRight util-pointer',
                      width: 100,
                      type: 'number'
                    },
                    {
                      header: '1 Day Old',
                      accessor: 'ageing_1',
                      detailsPopup: 'account',
                      detailsFilter: {
                        latest_owner_user_uid: { $ne: 'null' },
                        current_owner_ageing_category: 'ageing_1',
                        sales_result_code: { $in: ['valid', 'lead'] }
                      },
                      contextMapping: {
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'owner_user_uid'
                      },
                      className: 'util-textRight util-pointer',
                      width: 100,
                      type: 'number'
                    },
                    {
                      header: '2 Days Old',
                      accessor: 'ageing_2',
                      detailsPopup: 'account',
                      detailsFilter: {
                        latest_owner_user_uid: { $ne: 'null' },
                        current_owner_ageing_category: 'ageing_2',
                        sales_result_code: { $in: ['valid', 'lead'] }
                      },
                      contextMapping: {
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'owner_user_uid'
                      },
                      className: 'util-textRight util-pointer',
                      width: 100,
                      type: 'number'
                    },
                    {
                      header: '3-4 Days Old',
                      accessor: 'ageing_3_4',
                      detailsPopup: 'account',
                      detailsFilter: {
                        latest_owner_user_uid: { $ne: 'null' },
                        current_owner_ageing_category: 'ageing_3_4',
                        sales_result_code: { $in: ['valid', 'lead'] }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'owner_user_uid'
                      },
                      contextMapping: {
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      },
                      detailsPopupParams: {
                        showColumns: []
                      },
                      className: 'util-textRight util-pointer',
                      width: 100,
                      type: 'number'
                    },
                    {
                      header: '5+ Days Old',
                      accessor: 'ageing_5_plus',
                      detailsPopup: 'account',
                      detailsFilter: {
                        latest_owner_user_uid: { $ne: 'null' },
                        current_owner_ageing_category: 'ageing_5_plus',
                        sales_result_code: { $in: ['valid', 'lead'] }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'owner_user_uid'
                      },
                      contextMapping: {
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      },
                      className: 'util-textRight util-pointer',
                      width: 100,
                      type: 'number'
                    },
                    {
                      header: 'Total',
                      accessor: 'total_leads',
                      detailsPopup: 'account',
                      detailsFilter: {
                        latest_owner_user_uid: { $ne: 'null' },
                        sales_result_code: { $in: ['valid', 'lead'] }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'owner_user_uid'
                      },
                      contextMapping: {
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      },
                      className: 'util-pointer CrmFlatTable-summary',
                      headerClassName: 'CrmFlatTable-summary',
                      width: 100,
                      type: 'number',
                      align: 'right',
                      headerAlign: 'right'
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'AgentOwned'
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}

export default agentOwnedLeads
