/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'

import { withApollo } from 'react-apollo'
import WidgetTelesalesSalesBySource from './sales/sales-by-lead-source-hm.jsx'
import WidgetTelesalesSalesDetails from './sales/widget-telesales-sales-details.jsx'
import WidgetTelesalesSalesConvertedToProspects from './sales/widget-telesales-converted-to-prospects.jsx'
import WidgetLeadAssignments from './sales/leads-assignments.jsx'

class DashTelesalesSales extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== undefined &&
      nextProps.analyticsContext !== this.props.analyticsContext
    ) {
      console.log(
        'DashTelesalesAgents propsChanged',
        this.props.analyticsContext,
        nextProps.analyticsContext
      )
      // Tthis.setState({ ...this.state }, () => {
      //   this.handleRefresh()
      // })
    }
  }

  handleDateRangeFilterChange = value => {
    console.log('handleDateRangeFilterChange')
    console.dir(value)
    this.setState({
      activityDate:
        value && value.startDate ? [value.startDate, value.endDate] : undefined
    })
  }

  render() {
    console.log('render dash telesales agents', this.props)
    return (
      <div>
        <WidgetLeadAssignments analyticsContext={this.props.analyticsContext} />
        <WidgetTelesalesSalesBySource
          analyticsContext={this.props.analyticsContext}
        />
        <WidgetTelesalesSalesDetails
          analyticsContext={this.props.analyticsContext}
        />
        <WidgetTelesalesSalesConvertedToProspects
          analyticsContext={this.props.analyticsContext}
        />
      </div>
    )
  }
}

DashTelesalesSales.propTypes = {
  analyticsContext: shape({}).isRequired
}

DashTelesalesSales.defaultProps = {
  dashboards: []
}

export default withApollo(DashTelesalesSales)
