import React, { PureComponent } from 'react'
import { shape, string, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import { CrmTextInput, CrmButton, CrmValidatedField } from 'crm-components'

class AddressesItem extends PureComponent {
  isHeadQuaterAddress = () => this.props.address.address_kind === 'headquarter'

  removeItem = () => {
    const deleteid = this.props.address.address_uid
    if (deleteid) this.props.accountActions.removeAddress({ deleteid }) // eslint-disable-line
    this.props.fields.remove(this.props.index) // eslint-disable-line
  }

  render() {
    const { member } = this.props
    return (
      <div className="AddressesItem" style={this.props.style}>
        {this.isHeadQuaterAddress() && (
          <span className="AddressesItem-headquater-title">
            <FormattedMessage
              id="crm.ui.address.form.headquater"
              defaultMessage="Headquater"
            />
          </span>
        )}
        <fieldset
          disabled={!this.props.isEditing}
          className="CrmForm-form-fieldset"
        >
          <div className="row CrmForm-row">
            <div className="col-md-12">
              <CrmValidatedField
                name={`${member}.line1`}
                component={CrmTextInput}
                componentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.ui.address.form.address_line_1"
                      defaultMessage="Address Line 1"
                    />
                  ),
                  type: 'text'
                }}
              />
            </div>
          </div>

          <div className="row CrmForm-row">
            <div className="col-md-12">
              <CrmValidatedField
                name={`${member}.line2`}
                component={CrmTextInput}
                componentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.ui.address.form.address_line_2"
                      defaultMessage="Address Line 2"
                    />
                  ),
                  type: 'text'
                }}
              />
            </div>
          </div>
          <div className="row CrmForm-row">
            <div className="col-md-4">
              <CrmValidatedField
                name={`${member}.city`}
                component={CrmTextInput}
                componentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.ui.address.form.city"
                      defaultMessage="City"
                    />
                  ),
                  type: 'text'
                }}
              />
            </div>
            <div className="col-md-3">
              <Field
                name={`${member}.postcode`}
                component={CrmTextInput}
                type="text"
                placeholder={
                  <FormattedMessage
                    id="crm.ui.address.form.postcode"
                    defaultMessage="Postcode"
                  />
                }
              />
            </div>
          </div>
          <div className="row CrmForm-row">
            <div className="col-md-4">
              <Field
                name={`${member}.suburb`}
                component={CrmTextInput}
                type="text"
                placeholder={
                  <FormattedMessage
                    id="crm.ui.address.form.suburb"
                    defaultMessage="Suburb"
                  />
                }
              />
            </div>
            <div className="col-md-4">
              <Field
                name={`${member}.town`}
                component={CrmTextInput}
                type="text"
                placeholder={
                  <FormattedMessage
                    id="crm.ui.address.form.town"
                    defaultMessage="Town"
                  />
                }
              />
            </div>

            <div className="col-md-4">
              <Field
                name={`${member}.province`}
                component={CrmTextInput}
                type="text"
                placeholder={
                  <FormattedMessage
                    id="crm.ui.address.form.province"
                    defaultMessage="Province"
                  />
                }
              />
            </div>
          </div>

          <div className="row CrmForm-row">
            {/* <div className="col-md-4">
                  <Field
                    name={`${member}.street`}
                    component={CrmTextInput}
                    type="text"
                    placeholder="Street"
                  />
                </div>
                <div className="col-md-2">
                  <Field
                    name={`${member}.street_number`}
                    component={CrmTextInput}
                    type="text"
                    placeholder="No"
                  />
                </div> */}
            {/* <div className="col-md-3">
                  <Field
                    name={`${member}.location.lat`}
                    component={CrmTextInput}
                    type="text"
                    placeholder="Latitude"
                  />
                </div>
                <div className="col-md-3">
                  <Field
                    name={`${member}.location.lng`}
                    component={CrmTextInput}
                    type="text"
                    placeholder="longitude"
                  />
                </div> */}
          </div>
          {/* <div className="row CrmForm-row">
                <div className="col-md-12">
                  <Field
                    name={`${member}.remark`}
                    component={CrmTextInput}
                    type="text"
                    placeholder="Remark"
                  />
                </div>
              </div> */}
          <div className="col-md-1">
            {this.props.isEditing &&
              !this.isHeadQuaterAddress() &&
              this.props.enableDeleteButton && (
                <CrmButton
                  className="remove-button"
                  xsmall
                  icon="trash"
                  // eslint-disable-next-line
                  onClick={this.removeItem}
                />
              )}
          </div>
        </fieldset>
      </div>
    )
  }
}

AddressesItem.defaultProps = {
  style: {},
  address: {},
  isEditing: false,
  enableDeleteButton: true
}

AddressesItem.propTypes = {
  style: shape({}),
  address: shape({}),
  member: string.isRequired,
  isEditing: bool,
  enableDeleteButton: bool
}

export default AddressesItem
