import React from 'react'
import widgetFollowUpMeetingClientStatusHoc, { WidgetFollowUpMeetingClientStatusHocProps } from './my-follow-up-meetings-client-widget-hoc'
import { DashBoardWidget } from '../dashboard-widget'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetFollowUpMeetingClientSummary: React.ComponentType<AnalyticDashboardWidget> = widgetFollowUpMeetingClientStatusHoc(
  (props: WidgetFollowUpMeetingClientStatusHocProps) => (
    <DashBoardWidget
      avgDataPassedMonth={props.avgFollowUpMeetingThreeMonth}
      avgDataPassedMonthPerPerson={props.avgFollowUpMeetingPersonPassedMonth}
      avgPerPersonNumber={props.avgFollowUpMeetingCurrentMonthPerPerson}
      footerName={props.footerName}
      footerRender={props.threeMonthAgoFollowUpMeeting}
      isLoading={props.isLoading}
      refetch={props.refetchData}
      secondFooterName={props.footerNamePerPerson}
      secondFooterRender={props.avgFollowUpMeetingPassedMonthData}
      subtitleSecondBlock='Month to date average'
      title='Follow Up Meetings - Customer'
      totalNumber={props.totalFollowUpMeeting}
      onClickDetail={props.onClickDetail}
      subtitle={props.mainCaption}
    />
  )
)
