import React, { useState, useEffect } from 'react'
import { useCrmComponent } from 'crm-utils/crm-component-util'
import { CrmDropdown } from 'crm-components'

const DashboardSelector = (props) => {
  const [dashboardUid, setDashboardUid] = useState(props?.input?.value)
  const { getComponentByFilter } = useCrmComponent(props)
  const { components, refetch, loading } = getComponentByFilter()

  useEffect(() => {
    if (props.getAllFields) {
      const fields = getFieldsOfDashboard(dashboardUid)
      props.getAllFields(fields)
    }
  }, [loading])

  const getFieldsOfDashboard = (dashboardUid) => {
    let fields = []
    const dashboard = components.find(
      (d) => d.component_uid === dashboardUid
    ) as any
    if (dashboard?.content?.data?.dataSources) {
      fields = dashboard?.content?.data?.dataSources
        .reduce((p, c) => {
          const contextFields = c.contextMapping
            ? Object.values(c.contextMapping)
            : []
          return p.concat(c.fields || []).concat(contextFields)
        }, [])
        .reduce((p, c) => {
          if (typeof c !== 'string') {
            return p
          }
          if (p.includes(c)) {
            return p
          }
          return p.concat(c)
        }, [])
    }
    return fields
  }

  const handleSelect = (dashboardUid) => {
    setDashboardUid(dashboardUid)
    if (props?.input) {
      props.input.onChange(dashboardUid)
      if (props.getAllFields) {
        const fields = getFieldsOfDashboard(dashboardUid)
        props.getAllFields(fields)
      }
    }
  }

  const dashboardOptions =
    components
      ?.filter((w) => w.is_published)
      .map((w) => ({ value: w.component_uid, name: w.name })) ?? []
  return (
    <div>
      <CrmDropdown
        placeholder="Widget List"
        options={dashboardOptions}
        input={{
          onChange: handleSelect,
          value: dashboardUid
        }}
      />
    </div>
  )
}

export default DashboardSelector
