import React from 'react'
import { CrmTabNavigator } from 'crm-components/index'
import { FitmentsTable } from '../../dashboard/components/fitments-popup'

const TabSoldNotFitted = props => {
  return <div>TabSoldNotFitted</div>
}

const TabFittedNotCustomer = props => {
  const filters = {
    account: {
      kind: {
        $ne: 'customer'
      }
    },
    status_code: 'completed'
  }
  const initialColumns = ['account.name', 'status_code', 'completed_date', 'sale_date']
  return (
    <div className="util-fullHeight">
      <div>Completed fitments for accounts that are not 'customer'</div>
      <FitmentsTable filters={filters} style={{ width: '100%' }} initialColumns={initialColumns} />
    </div>
  )
}

export const ManagerCheckScreen = props => {
  const tabs = [
    { label: 'Sold not fitted', childComponent: <TabSoldNotFitted /> },
    { label: 'Fitted not customer', childComponent: <TabFittedNotCustomer /> }
  ]
  console.log('Render ManagerCheckScreen')
  return (
    <div
      className="CrmFullpageContainer
      CrmFullpageContainer--hasPadding  CrmBox--vbox
 CrmFullpageContainer--scrollInner "
    >
      <CrmTabNavigator tabs={tabs} selectedIndex={6} />
    </div>
  )
}
