/* eslint-disable */

const meetingsPerformedToday = {
  columns_dims: [
    {
      dim_code: 'date_performed',
      kpi_code: 'meetings_details',
      name: 'Date Performed',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_code: 'user_performer',
      kpi_code: 'meetings_details',
      name: 'User Performed'
    }
  ],
  kpis: [{ code: 'meetings_details', as: 'meetings_details' }],
  title: 'Meetings Held by date',
  filters: [
    {
      kpi_code: 'meetings.details',
      dim_code: 'date_performed',
      context: true,
      context_filter_code: 'date_range'
    },
    {
      kpi_code: 'meetings.details',
      dim_code: 'activity_success',
      values: ['true'],
      operator: 'eq',
      class: 'bool'
    }
  ]
}

const meetingsPerformedTodayBySource = {
  columns_dims: [
    {
      dim_code: 'date_performed',
      kpi_code: 'meetings_details',
      name: 'Date Performed',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_code: 'user_performer',
      kpi_code: 'meetings_details',
      name: 'User Performed'
    },
    {
      dim_code: 'activity_source_type',
      kpi_code: 'meetings_details',
      name: 'Meeting source'
    }
  ],
  kpis: [{ code: 'meetings_details', as: 'meetings_details' }],
  title: 'Meetings Held by date - by performer / meeting source',
  filters: [
    {
      kpi_code: 'meetings.details',
      dim_code: 'date_performed',
      context: true,
      context_filter_code: 'date_range'
    },
    {
      kpi_code: 'meetings.details',
      dim_code: 'activity_success',
      values: ['true'],
      operator: 'eq',
      class: 'bool'
    }
  ]
}

// Change order

const meetingsPerformedTodayBySource2 = {
  columns_dims: [
    {
      dim_code: 'date_performed',
      kpi_code: 'meetings_details',
      name: 'Date Performed',
      generate_values: true
    }
  ],
  rows_dims: [
    {
      dim_code: 'activity_source_type',
      kpi_code: 'meetings_details',
      name: 'Meeting source'
    },
    {
      dim_code: 'user_performer',
      kpi_code: 'meetings_details',
      name: 'User Performed'
    }
  ],
  kpis: [{ code: 'meetings_details', as: 'meetings_details' }],
  title: 'Meetings Held - by Meeting source / Perfomer',
  filters: [
    {
      kpi_code: 'meetings.details',
      dim_code: 'date_performed',
      context: true,
      context_filter_code: 'date_range'
    },
    {
      kpi_code: 'meetings.details',
      dim_code: 'activity_success',
      values: ['true'],
      operator: 'eq',
      class: 'bool'
    }
  ]
}

//
const meetingsPerformedTodayBySource3 = {
  columns_dims: [
    {
      dim_code: 'activity_source_type',
      kpi_code: 'meetings_details',
      name: 'Meeting source'
    }
  ],
  rows_dims: [
    {
      dim_code: 'user_performer',
      kpi_code: 'meetings_details',
      name: 'User Performed'
    }
  ],
  kpis: [{ code: 'meetings_details', as: 'meetings_details' }],
  title: 'Meetings Held - compare Meeting source and Perfomer',
  filters: [
    {
      kpi_code: 'meetings.details',
      dim_code: 'date_performed',
      default_value: 'october_2017',
      operator: 'eq',
      class: 'date'
    },
    {
      kpi_code: 'meetings.details',
      dim_code: 'activity_success',
      values: ['true'],
      operator: 'eq',
      class: 'bool'
    }
  ]
}

const dashSalesLiveScreen = {
  dashboard_uid: 'salesLiveScreen',
  name: 'Sales Overview',
  fullscreen: true,
  filters: [
    {
      code: 'scope',
      data_type: 'scope',
      label: 'Scope',
      default_value: 'development'
    },
    {
      code: 'date_range',
      data_type: 'date_range',
      label: 'Date Range',
      default_value: 'october_2017'
    }
  ],
  content: {
    items: [
      {
        type: 'analitics_widget',
        widgetDefinition: meetingsPerformedToday
      }
      // {
      //   type: 'analitics_widget',
      //   widgetDefinition: meetingsPerformedTodayBySource
      // },
      // {
      //   type: 'analitics_widget',
      //   widgetDefinition: meetingsPerformedTodayBySource2
      // },
      // {
      //   type: 'analitics_widget',
      //   widgetDefinition: meetingsPerformedTodayBySource3
      // }
    ]
  }
}

export default dashSalesLiveScreen
