export const getTypeDefinition = (schema, dataType) => {
  console.log('getTypeDefinition', schema, dataType)
}

export const getType = (schema, dataType) =>
  schema && schema.types
    ? schema.types.find((t) => t.name === dataType)
    : undefined

export const getTypeFields = (schema, dataType) => {
  const type = getType(schema, dataType)
  return type ? type.fields : []
}

export const getOneSchemaByTypeName = (schema, dataType) => {
  return schema.types?.find((data) => data.type === dataType) ?? {}
}

export const mapAvailableColumns = (schema, dataSource) => {}

const _getRootTypeFromOldDSCode = (oldDS) => {
  return oldDS.code
}
export const upgrateToVersion6 = (component) => {
  if (component.dev_version == 6) {
    return component
  }

  var dataSources = []
  if (component?.content?.data?.dataSources) {
    component?.content?.data?.dataSources.forEach((oldDS) => {
      var id = oldDS.id || oldDS.code
      if (oldDS.alias) {
        id = oldDS.alias
      }
      const name = oldDS.name

      const root_type = _getRootTypeFromOldDSCode(oldDS)
      // Notes
      // alias removed - replaced by id
      // code not used (use id)
      // root_type required - use from old code

      // parse old aggregate and mark as field props
      if (oldDS.aggregate) {
        oldDS.aggregate.forEach((a) => {})
      }

      if (oldDS.groupBy) {
        // mark field
      }
      const newDS = {
        type: 'QL_GENERATOR',
        id,
        name,
        fields: oldDS.fields,
        root_type,
        filter: oldDS.filter
      }

      dataSources.push(newDS)
    })
  }
  var layout = []

  return {
    ...component,
    def_version: 6,
    content: {
      data: {
        data_sources: dataSources
      },
      layout
    }
  }
}

export const getSelectedFieldFromLayoutInDataSource = (
  dataSourceId,
  layoutItems
) => {
  let selected = []
  layoutItems.forEach((item) => {
    selected =
      item.props?.columns
        ?.filter((column) => column?.data?.data_source === dataSourceId)
        ?.map((column) => column?.data?.field) ?? []
  })
  return selected
}

export const downgradeToVersion5 = (component) => {}
