import { isValidPhoneNumber } from 'react-phone-number-input'

const required = value => (value ? undefined : 'Required')
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,10}$/i.test(value)
    ? 'Invalid email address'
    : undefined
const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined
const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined
const password = value =>
  value && /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value)
    ? undefined
    : 'least 8 characters, least one number, one lowercase and one uppercase letter '

const confirmPassword = (value, allValues) =>
  value === allValues.new_password ? undefined : 'Must equal new password '

const jsonValue = jsonVal => {
  try {
    JSON.parse(jsonVal)
    return undefined
    // eslint-disable-next-line
  } catch (e) {
    return 'Invalid json format'
  }
}

const booleanData = value =>
  value !== 'true' || value !== 'false'
    ? 'Invalid boolean value , You can put true or false.'
    : undefined

const numberWithCountryCode = (value, country_code) => {
  const number = `+${country_code + value}`
  const isValid = isValidPhoneNumber(number)
  if (isValid) {
    return undefined
  }
  return 'Invalid number format'
}

const validationRules = {
  required,
  maxLength,
  minLength,
  minValue,
  number,
  email,
  alphaNumeric,
  numberWithCountryCode,
  phoneNumber,
  jsonValue,
  password,
  confirmPassword,
  booleanData
}

export default validationRules
