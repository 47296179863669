import { shape, string } from 'prop-types'
import { createSelector } from 'reselect'
import { mapValues } from 'lodash'
// import { processDriverEvents } from '../process-data/drivers'
import { getSelectedTripSummaryIndex } from 'crm-duxs/map' // eslint-disable-line
import { reconciliateGroupItemIds } from 'util-functions/functional-utils'

// Actions
export const CREATE_DRIVER = 'CREATE_DRIVER'
export const ON_CREATE_DRIVER = 'ON_CREATE_DRIVER'
export const RECEIVE_DRIVERS = 'RECEIVE_DRIVERS'
export const RECEIVE_DRIVER_EVENTS = 'RECEIVE_DRIVER_EVENTS'
export const UPDATE_DRIVER_GROUP = 'UPDATE_DRIVER_GROUP'
export const DELETE_DRIVER_GROUP = 'DELETE_DRIVER_GROUP'
export const CREATE_DRIVER_GROUP = 'CREATE_DRIVER_GROUP'
export const FETCH_DRIVER_LOGS = 'FETCH_DRIVER_LOGS'
export const RECEIVE_DRIVER_LOGS = 'RECEIVE_DRIVER_LOGS'
export const FETCH_DRIVER_DETAILS = 'FETCH_DRIVER_DETAILS'
export const UPDATE_DRIVER_DETAILS = 'UPDATE_DRIVER_DETAILS'
export const RECEIVE_DRIVER_DETAILS = 'RECEIVE_DRIVER_DETAILS'
export const CLEAR_CURRENT_DRIVER = 'CLEAR_CURRENT_DRIVER'
export const TOGGLE_DRIVER_STATUS = 'TOGGLE_DRIVER_STATUS'
export const ON_TOGGLE_DRIVER_STATUS = 'ON_TOGGLE_DRIVER_STATUS'
export const FETCH_HISTORICAL_DRIVER_EVENTS = 'FETCH_HISTORICAL_DRIVER_EVENTS'
export const RECEIVE_HISTORICAL_DRIVER_EVENTS = 'RECEIVE_HISTORICAL_DRIVER_EVENTS'
export const FETCH_HOS_LOGS = 'FETCH_HOS_LOGS'
export const RECEIVE_HOS_LOGS = 'RECEIVE_HOS_LOGS'

const defaultDriverEvents = {
  events: [],
  eventsStartInMs: null,
  eventsNDays: 7
}

// Reducer
const initialState = {
  hasLoaded: false,
  drivers: [],
  groups: [],
  events: {}, // Map of id => events for left panel timelines
  historicalEvents: defaultDriverEvents,
  logs: null,
  hosLogs: [],
  loadingHosLogs: false,
  detailedDriver: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_DRIVERS:
      return {
        ...state,
        drivers: action.payload.drivers,
        groups: action.payload.groups,
        hasLoaded: true
      }
    case RECEIVE_DRIVER_EVENTS:
      return {
        ...state,
        events: action.payload.events
      }
    case RECEIVE_DRIVER_LOGS:
      return {
        ...state,
        logs: action.payload.logs
      }
    case RECEIVE_DRIVER_DETAILS:
      return {
        ...state,
        detailedDriver: action.payload.details,
        availableStates: action.payload.states
      }
    case ON_TOGGLE_DRIVER_STATUS:
      return {
        ...state,
        detailedDriver: {
          ...state.detailedDriver,
          active: action.payload.active
        }
      }
    case CLEAR_CURRENT_DRIVER:
      return {
        ...state,
        logs: null,
        detailedDriver: null
      }
    case FETCH_HISTORICAL_DRIVER_EVENTS:
      return {
        ...state,
        historicalEvents: defaultDriverEvents
      }
    case RECEIVE_HISTORICAL_DRIVER_EVENTS:
      return {
        ...state,
        historicalEvents: action.payload.historicalEvents
      }
    case ON_CREATE_DRIVER:
      return { ...state, hasLoaded: false }
    case FETCH_HOS_LOGS:
      return { ...state, loadingHosLogs: true }
    case RECEIVE_HOS_LOGS:
      return {
        ...state,
        hosLogs: action.payload.hosLogs,
        loadingHosLogs: false
      }
    default:
      return state
  }
}

// Action Creators
export function createDriver(driver) {
  return {
    type: CREATE_DRIVER,
    payload: { driver }
  }
}

export function updateDriverGroup(group, selectedIds) {
  return {
    type: UPDATE_DRIVER_GROUP,
    payload: { group, selectedIds }
  }
}

export function deleteDriverGroup(groupId, groupName) {
  return {
    type: DELETE_DRIVER_GROUP,
    payload: { groupId, groupName }
  }
}

export function createDriverGroup(name) {
  return {
    type: CREATE_DRIVER_GROUP,
    payload: {
      name
    }
  }
}

export function fetchDriverLogsForPastDays(driverId, startDate, days) {
  return {
    type: FETCH_DRIVER_LOGS,
    payload: {
      driverId,
      startDate,
      days
    }
  }
}

export function fetchDriverDetails(driverId) {
  return {
    type: FETCH_DRIVER_DETAILS,
    payload: {
      driverId
    }
  }
}

export function fetchHistoricalDriverEvents(driverId, startDate) {
  return {
    type: FETCH_HISTORICAL_DRIVER_EVENTS,
    payload: {
      driverId,
      startDate
    }
  }
}

export function fetchHosLogs(driverIds, startTime, endTime) {
  return {
    type: FETCH_HOS_LOGS,
    payload: {
      driverIds,
      startTime,
      endTime
    }
  }
}

export function updateDriverDetails(update) {
  return {
    type: UPDATE_DRIVER_DETAILS,
    payload: {
      update
    }
  }
}

export function toggleDriverStatus(driver) {
  return {
    type: TOGGLE_DRIVER_STATUS,
    payload: {
      driver
    }
  }
}

export function clearCurrentDriver() {
  return {
    type: CLEAR_CURRENT_DRIVER
  }
}

// Selectors
export const getHasLoaded = state => state.drivers.hasLoaded
export const getDrivers = state => state.drivers.drivers
export const getDriverGroups = state => state.drivers.groups
export const getHosLogs = state => state.drivers.hosLogs
export const getLoadingHosLogs = state => state.drivers.loadingHosLogs

export const getActiveDriversWithVehicles = createSelector(
  getDrivers,
  state => state.vehicles.vehicles, // Circular dependency guard
  (drivers, vehicles) =>
    drivers
      .filter(d => d.active)
      .map(d => ({
        ...d,
        vehicle: vehicles.find(v => v.id === d.vehicleId)
      }))
)

// T O D O: Use create selector
export const getDriverFromId = (state, id) => state.drivers.drivers.find(driver => driver.id === id)

export const getDetailedDriver = state => state.drivers.detailedDriver
export const getDriverEvents = state => state.drivers.events
export const getDriverLogs = state => state.drivers.logs
const getFocusedDriverId = state => state.map.focusedItemId

// T O D O: Use create selector
export const getDriverGroupItemIds = (state, groupId) => state.drivers.groups.find(g => g.id === groupId).itemIds

// T O D O: Use createSelector
export const getDriverGroup = (state, groupId) =>
  reconciliateGroupItemIds(
    getDriverGroups(state).find(group => group.id === groupId),
    getDrivers(state)
  )

export const getFocusedDriver = createSelector(
  getDrivers,
  getFocusedDriverId,
  (drivers, id) => (id && drivers.find(v => v.id === id)) || null
)

// Const getDriverEventsFromId = (state, id) => {
//   const driver = getDriverEvents(state)
//   return driver[id] ? driver[id].events : []
// }

export const getFocusedDriverEvents = state => {
  const focusedDriver = getFocusedDriver(state)
  if (focusedDriver) {
    const eventData = getDriverEvents(state)[focusedDriver.id]
    return eventData ? eventData.events : []
  }
  return []
}

function filterEventsNotInDay(eventsOrEventsData, idx = 0) {
  const isObject = !Array.isArray(eventsOrEventsData)
  const tmpDate = isObject
    ? new Date((eventsOrEventsData.eventsNDays - 1) * 86400000 + eventsOrEventsData.eventsStartInMs)
    : new Date()
  const day = tmpDate.getDate() - idx
  return (isObject ? eventsOrEventsData.events : eventsOrEventsData).filter(evt => {
    tmpDate.setTime(evt.startTime)
    return tmpDate.getDate() === day
  })
}

const getHistoricalDriverEventsData = state => state.drivers.historicalEvents

export const getHistoricalDriverEventsForDay = createSelector(getHistoricalDriverEventsData, eventsData =>
  filterEventsNotInDay(eventsData).filter(e => e.statusClassName.indexOf('violation') === -1)
)

export const getHistoricalDriverTimelinesData = createSelector(getHistoricalDriverEventsData)

export const getFocusedDriverEventsData = state => {
  const focusedDriver = getFocusedDriver(state)
  if (focusedDriver) {
    return getDriverEvents(state)[focusedDriver.id] || defaultDriverEvents
  }
  return defaultDriverEvents
}

export const getFocusedDriverEventsForDay = createSelector(
  getFocusedDriverEvents,
  getSelectedTripSummaryIndex,
  filterEventsNotInDay
)

export const getFocusedDriverTimelinesData = createSelector(getFocusedDriverEventsData)

export const getDriversTimelinesData = createSelector(getDriverEvents, events => mapValues(events))

export const getCurrentDriverTimelinesData = createSelector(getDetailedDriver, getDriverEvents)

export const getAvailableStates = state => state.drivers.availableStates
export const getHasAvailableStates = state => (state.drivers.availableStates || []).length > 0

// PropTypes
export const driverShape = shape({
  id: string.isRequired,
  name: string.isRequired
})

export const driverEventShape = shape({
  id: string.isRequired
})
