/* eslint-disable camelcase */
import React from 'react'
import { shape, arrayOf } from 'prop-types'
import { graphql, compose, withApollo } from 'react-apollo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedDate, injectIntl, FormattedMessage } from 'react-intl'
import { qlqListFeedItems } from 'crm-data/feed'
import { qlqListcqCommandAudit } from 'crm-data/cq-command-audit'

class FeedListItem extends React.PureComponent {
  static propTypes = {
    client: shape().isRequired,
    item: shape().isRequired
  }
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleLoadCommandDetails = async event => {
    console.log('onLoadCommandDetails', this.props)

    const filter = {
      command_uid: event.currentTarget.id
    }

    const res = await this.props.client.query({
      query: qlqListcqCommandAudit,
      variables: { filter }
    })

    if (
      res &&
      res.data &&
      res.data.cqcommand_audit_list_qm_paged &&
      res.data.cqcommand_audit_list_qm_paged.data.length === 1
    ) {
      this.setState({ command: res.data.cqcommand_audit_list_qm_paged.data[0] })
    } else {
      this.setState({ command: undefined })
    }
  }
  render() {
    const { item } = this.props
    let title = this.props.intl.formatDate(item.created_time) + ' '
    title += this.props.intl.formatTime(item.created_time) + ' '

    if (item.user) {
      title += 'by ' + item.user.full_name
    }
    return (
      <div className="FeedItem util-hooverable">
        <div className="FeedItem-title">{item.title}</div>
        <div className="FeedItem-message">{item.message}</div>
        <div className="FeedItem-ts util-textRight">
          {item.source_command_uid && (
            <span className="util-marginRight">
              <FontAwesomeIcon
                id={item.source_command_uid}
                title="Click to show technical details"
                icon="info-circle"
                onClick={this.handleLoadCommandDetails}
              />
            </span>
          )}

          <span title={title}>
            {item.user && <span>{item.user.full_name}@</span>}
            <FormattedDate value={item.created_time} />
          </span>
        </div>
        {this.state.command && (
          <span>
            <FormattedMessage
              id="crm.ui.account.history.command_details"
              defaultMessage="Command details"
            />
            {':'}

            <span>{JSON.stringify(this.state.command)}</span>
          </span>
        )}
      </div>
    )
  }
}
class FeedList extends React.PureComponent {
  handleRefresh = () => {
    console.log('handleRefresh')
    this.props.qlqListFeedItems.refetch()
  }
  render() {
    const feedItems = this.props.feedItems
    console.log('Render Feed List', this.props)
    return (
      <div className="Feed util-fullHeight util-flexColumn">
        <div className="CrmFormSection util-flexColumn util-fullHeight">
          <div className="CrmFormSection-header" onClick={this.handleRefresh}>
            <FormattedMessage
              id="crm.history.account_feed"
              defaultMessage="Account Feed"
            />
          </div>
          <div
            className="Feed-list util-flexGrow"
            style={{ overflowY: 'auto' }}
          >
            {feedItems &&
              feedItems.map(item => (
                <FeedListItem
                  key={item.feed_item_uid}
                  item={item}
                  client={this.props.client}
                  intl={this.props.intl}
                />
              ))}
          </div>
        </div>
      </div>
    )
  }
}

FeedList.propTypes = {
  feedItems: arrayOf(shape()),
  client: shape().isRequired
}

FeedList.defaultProps = {
  feedItems: undefined
}
const qlOptions = {
  options: ownProps => ({
    variables: { account_uid: ownProps.accountUid },
    fetchPolicy: 'network-only'
  }),
  props: ({ qlqListFeedItems, ownProps }) => {
    console.log('Map props ', ownProps)
    return {
      ...ownProps,
      qlqListFeedItems,
      feedItems: qlqListFeedItems ? qlqListFeedItems.feedItems : undefined,
      isLoading: qlqListFeedItems ? qlqListFeedItems.loading : false
    }
  }
}

const FeedListQL = compose(
  graphql(qlqListFeedItems, {
    ...qlOptions,
    name: 'qlqListFeedItems',
    fetchPolicy: 'no-cache',
    forceFetch: true
  }),
  injectIntl,
  withApollo
)(FeedList)

FeedList.propTypes = {
  qlqListFeedItems: shape().isRequired
}

export default FeedListQL
