/* eslint-disable  */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape, bool, func, string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { FieldArray, Field, propTypes, change } from 'redux-form'
import { CrmUsersSelect, CrmButton, CrmDropdown } from 'crm-components'
import SectionSelectRecipients from './section-select-recipients.jsx'
import EmailSelect from './email-select.jsx'
import {
  AccountPersonSelect,
  ContactPersonInlineForm
} from 'crm-modules/accounts/components/'
import { getContactDetailByType } from 'crm-utils/accounts-utils'
import SectionGuests from 'crm-modules/meeting/components/section-guests/section-guests.jsx'
import cloneDeep from 'clone-deep'

class RenderItems extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isAddingNew: false,
      persons: cloneDeep(props.persons)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.persons !== this.props.persons) {
      this.setState({
        persons: cloneDeep(nextProps.persons)
      })
    }
  }

  handleNewPersonSaved = person => {
    this.props.fields.push({
      kind: 'person',
      participant_account_person_uid: person.account_person_uid
    })
    this.setState({ ...this.state, isAddingNew: false })
  }

  handleNewPersonCancel = () => {
    this.setState({ ...this.state, isAddingNew: false })
  }

  handleSelectEmail = email => {
    if (email) {
      const item = {
        role_code: email.role_code,
        contact_details_uid: email.contact_details.contact_details_uid,
        participant_contact_person_uid: email.account_person_uid,
        contact_details: email.contact_details
      }
      this.props.fields.push(item)
      this.setState({ guests: email })
    }
  }

  handleRemoveEmail = email => {
    for (let i = 0; i < this.props.fields.length; i++) {
      const item = this.props.fields.get(i)
      if (item.role_code === 'performer') {
      } else if (
        item.contact_details.contact_details_uid ===
        email.contact_details.contact_details_uid
      ) {
        this.props.fields.remove(i)
      }
    }
  }
  toggleRole = async recipient => {
    let role_code = recipient.role_code
    if (role_code === 'to') {
      role_code = 'cc'
    } else {
      role_code = 'to'
    }
    const newRecipient = {
      ...recipient,
      role_code
    }
    const index = this.props.fields
      .getAll()
      .findIndex(
        r =>
          r.contact_details &&
          r.contact_details.contact_details_uid ===
            recipient.contact_details.contact_details_uid
      )
    await this.props.fields.remove(index)
    await this.props.fields.insert(index, newRecipient)
  }

  render() {
    const { fields, emails } = this.props
    const { isAddingNew } = this.state

    return (
      <div>
        <div className="row ">
          <div className="col-md-6">
            <b>
              <FormattedMessage
                id="crm.ui.activity.form.recipients"
                defaultMessage="Recipients"
              />
            </b>
          </div>
        </div>
        <div>
          <SectionSelectRecipients
            isEditing={this.props.isEditing}
            account={this.props.account}
            persons={this.props.account.persons}
            activity={this.props.activity}
            handleGuestsChange={this.handleSelectEmail}
            handleRemoveEmail={this.handleRemoveEmail}
            fields={fields}
            sendEmailFromQuotation={this.props.sendEmailFromQuotation}
            toggleRole={this.toggleRole}
            recipients={this.props.recipients}
          />
        </div>

        {isAddingNew && (
          <ContactPersonInlineForm
            accountUid={this.props.accountUid}
            onSaved={this.handleNewPersonSaved}
            onCancel={this.handleNewPersonCancel}
          />
        )}
      </div>
    )
  }
}
class EmailRecipientsFields extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { activity, isEditing } = this.props
    return (
      <div>
        <fieldset
          disabled={!this.props.isEditing}
          className="CrmForm-form-fieldset"
        >
          <FieldArray
            name="participants"
            form={this.props.form}
            doChange={this.props.doChange}
            component={RenderItems}
            emails={this.props.emails}
            isEditing={this.props.isEditing}
            accountUid={this.props.accountUid}
            account={this.props.account}
            activity={this.props.activity}
            persons={this.props.account.persons}
            sendEmailFromQuotation={this.props.sendEmailFromQuotation}
          />
        </fieldset>
      </div>
    )
  }
}

EmailRecipientsFields.propTypes = {
  ...propTypes,
  activity: shape({}).isRequired,
  addressSaving: bool.isRequired,
  isEditing: bool.isRequired,
  onGotoMinutes: func.isRequired,
  accountUid: string.isRequired
}

EmailRecipientsFields.defaultProps = {}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    doChange: (form, field, value) => dispatch(change(form, field, value))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailRecipientsFields)
