import React, { useState } from 'react'
import { TASK_TYPE, TASK_VARIABLE_TYPE } from 'crm-modules/tasks/hook/use-fetch-task'
import { useFetchMyTaskWidget } from 'crm-modules/tasks/hook/use-fetch-my-widget'
import { Button, LoadingIcon } from 'util-components'
import TaskListItem from 'crm-modules/tasks/elements/task-list-item'
import styled from 'styled-components'

type MyTaskWidgetProps = {
  filter?: any,
  style?: React.CSSProperties,
  title?: string,
  type?: 'user' | 'group'
}
export const MyTaskWidget = ({ filter, style, title, type }: MyTaskWidgetProps) => {
  const [currenTab, setCurrentTab] = useState('overdue')
  const { overDueTask, todayTask, futureTask } = useFetchMyTaskWidget(filter, type)
  const isLoading = () => !overDueTask.loading && !todayTask.loading && !futureTask.loading
  const renderTab = () => {
    if (currenTab === 'today') {
      return <TaskListItemScroll data={todayTask.data} loadMore={todayTask.fetchMoreTask} currentTab='today' />
    } else if (currenTab === 'overdue') {
      return <TaskListItemScroll data={overDueTask.data} loadMore={overDueTask.fetchMoreTask} currentTab='overdue'/>
    } else {
      return <TaskListItemScroll data={futureTask.data} loadMore={futureTask.fetchMoreTask} currentTab='future'/>
    }
  }
  const refetchAll = () => {
    overDueTask.refetch()
    todayTask.refetch()
    futureTask.refetch()
  }
  return (
    <div className="CrmWidget Widget CrmWidget--noHeight" style={style}>
      <div className="CrmWidget-header row util-noMargin between-xs top-xs">
        <div className="CrmWidget-title util-flexRow">
          <div className="util-flexGrow">{title}</div>
          <div
            style={{ margin: 0, padding: '0 10px' }}
            className={`util-pointer LabeledField  util-textRed util-marginLeft util-marginRight ${currenTab ===
              'overdue' && 'util-bgGray'}`}
            title="Overdue tasks"
            onClick={() => setCurrentTab('overdue')}
          >
            <div className="LabeledField-label">Overdue</div>
            <div className="LabeledField-value ">{overDueTask?.data?.tasks_todo_paged?.count ?? 0}</div>
          </div>

          <div
            style={{ margin: 0, padding: '0 10px' }}
            className={`util-pointer LabeledField util-marginLeft util-marginRight ${currenTab === 'today' &&
              'util-bgGray'}`}
            title="Tasks today"
            onClick={() => setCurrentTab('today')}
          >
            <div className="LabeledField-label">Today</div>
            <div className="LabeledField-value ">{todayTask?.data?.tasks_todo_paged?.count ?? 0}</div>
          </div>
          <div
            style={{ margin: 0, padding: '0 10px' }}
            className={`util-pointer LabeledField util-marginLeft util-marginRight ${currenTab === 'future' &&
              'util-bgGray'}`}
            title="Tasks Future"
            onClick={() => setCurrentTab('future')}
          >
            <div className="LabeledField-label">Future</div>
            <div className="LabeledField-value ">{futureTask?.data?.tasks_todo_paged?.count ?? 0}</div>
          </div>
        </div>
        <div className="CrmWidget-tools">
          <Button style={{ marginLeft: 5, marginRight: 5 }} icon="refresh" square onClick={refetchAll} />
        </div>
      </div>
      <div className="CrmWidget-content CrmWidget-content--padding" style={{ overflowY: 'auto', position: 'relative' }}>
        {!isLoading() && <LoadingPage />}
        {renderTab()}
      </div>
    </div>
  )
}
const LoadingBg = styled.div`
  background-color: #faebd7;
  height: 100%;
  margin: -24px;
  position: absolute;
  width: 100%;
  z-index: 1;
  opacity: 0.5;
`
const CrmLoading = styled(LoadingIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
`

const LoadingPage = () => (
  <React.Fragment>
    <CrmLoading />
    <LoadingBg />
  </React.Fragment>
)
type TaskListItemScrollProps = {
  data: TASK_TYPE,
  loadMore: (variables: TASK_VARIABLE_TYPE) => void,
  currentTab?: 'overdue' | 'future' | 'today'
}
export const TaskListItemScroll = ({ data, loadMore, currentTab }: TaskListItemScrollProps) => {
  const handleScroll = ({ currentTarget }) => {
    if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) {
      loadMore({
        offset: data.tasks_todo_paged?.data?.length
      })
    }
  }
  return (
    <div className="CrmMdList" onScroll={handleScroll} style={{ height: '100%', overflow: 'scroll' }}>
      {data && data.tasks_todo_paged?.data?.map?.(task => <TaskListItem key={`${currentTab}-${task.task_uid}`} task={task} />)}
    </div>
  )
}
