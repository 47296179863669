import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, shape } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAttachmentFiles } from 'crm-duxs/account-reducer'

class AttachmentLabel extends Component {
  renderAttachmentFiles = () => {
    const attachmentFiles = this.props.attachmentFiles
    const renderHtml = []

    Object.entries(attachmentFiles).forEach(entry => {
      const [key, data] = entry

      const removeEmail = () => this.props.handleRemoveAttachmentFile(data)
      console.log('renderAttachmentFiles', key, data, this.props)

      renderHtml.push(
        <div key={key} className="CrmChip CrmChip-green util-marginBottom">
          {data.name}
          <span style={{ color: 'red' }}>
            <FontAwesomeIcon
              title="Remove"
              className="util-marginLeft"
              icon="times"
              onClick={removeEmail}
            />
          </span>
        </div>
      )
    })

    return renderHtml
  }

  render() {
    return <div>{this.renderAttachmentFiles()}</div>
  }
}

AttachmentLabel.propTypes = {
  handleRemoveAttachmentFile: func.isRequired,
  attachmentFiles: shape({}).isRequired
}

AttachmentLabel.defaultProps = {}

function mapStateToProps(state) {
  return {
    attachmentFiles: getAttachmentFiles(state)
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentLabel)
