import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import { shape, func, arrayOf, bool } from 'prop-types'
import ConvertToProspectModal from '../../qualification/convert-to-prospect-modal.jsx'

import RegisterWinModal from '../../win/register-win-modal.jsx'
import ValidationMarketPotential from './validation-market-potential.jsx'
import ValidationContactDetails from './validation-contact-details.jsx'
import QualifyButton from './qualify-button.jsx'
import { CrmButton } from 'crm-components'
import {
  actions as documentActions,
  getDocument
} from 'crm-duxs/document-reducer'
import { bindActions } from 'crm-utils/reducer-utils'

class LeadActionsWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isEdited: false,
      isConvertToProspectModal: false,
      isRegisterWinModal: false
    }
  }
  handleStartEdit = () => {
    this.setState({ isEdited: true })
  }

  handleSave = () => {
    this.setState({ isEdited: false })
  }

  handleWon = () => {
    this.setState({ isRegisterWinModal: true })
    this.props.documentActions.initNewDocument({
      document: { document_type_uid: '856cff59-cbb5-4a8f-88ad-3d8800b8c0d2' } // eslint-disable-line
    })
  }

  handleConvertToProspect = () => {
    if (!this._hasMarketSize() || !this._hasValidatedPhone()) {
      toastr.error(
        this.props.intl.formatMessage({
          id: 'crm.ui.account.lead.need_to_validate',
          defaultMessage:
            'You need to validate market size and contact details first'
        })
      )
      return
    }
    this.setState({ isConvertToProspectModal: true })
  }
  handleCloseConvertToProspect = () => {
    console.log('handleCloseConverToProspect')
    this.setState({
      isConvertToProspectModal: false,
      isRegisterWinModal: false
    })
  }

  _hasMarketSize = () => {
    console.log('_hasMarketSize', this.props.account)
    return (
      this.props.account &&
      this.props.account.market_potential &&
      (Number(this.props.account.market_potential.size) > 0 ||
        Number(this.props.account.market_potential.other_size) > 0)
    )
  }

  _hasValidatedPhone = () =>
    this.props.account &&
    this.props.account.contact_details &&
    this.props.account.contact_details.find(cd => cd.validated_time) !==
      undefined

  render() {
    const { account } = this.props
    console.log('render lead-actions-widget', this.props)
    const titlesStyle = { fontSize: '1.1em', opacity: 0.85 }
    const hasMarketSize = this._hasMarketSize()
    const hasValidatedPhone = this._hasValidatedPhone()

    return (
      <div
        className={`
        ${
          this.props.inline ? '' : '        CrmMdCard  '
        } LeadActionsWidget util-paddingMd`}
      >
        {account && (
          <div className="row">
            <div className="col-md-6">
              <div>
                <div className="util-noMargin" style={titlesStyle}>
                  <FormattedMessage
                    id="crm.ui.account.lead.lead_validation"
                    defaultMessage="Lead Validation"
                  />
                </div>
              </div>
              <div>
                <ValidationMarketPotential
                  account={this.props.account}
                  isEdited={this.state.isEdited}
                  onStartEdit={this.props.onShowPotentialPopup}
                />
                <ValidationContactDetails
                  account={account}
                  isEdited={this.state.isEdited}
                  onStartEdit={this.handleStartEdit}
                />

                {this.state.isEdited && (
                  <CrmButton
                    label="Save"
                    type="primary"
                    onClick={this.handleSave}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 util-flexCenterContent">
                  <QualifyButton
                    className="LeadActionsWidget-butRed"
                    title={
                      <FormattedMessage
                        id="crm.ui.account.lead.bogus"
                        defaultMessage="Bogus"
                      />
                    }
                    description={
                      <FormattedMessage
                        id="crm.ui.account.lead.record_mistake_doesnt_have"
                        defaultMessage="This record is a mistake or doesn't have any fleet"
                      />
                    }
                    qualificationTypeCode="bogus"
                    account={this.props.account}
                    accountUid={this.props.account.account_uid}
                  />

                  <QualifyButton
                    className="LeadActionsWidget-butRed"
                    title={
                      <FormattedMessage
                        id="crm.ui.account.lead.unreachable"
                        defaultMessage="Unreachable"
                      />
                    }
                    description={
                      <FormattedMessage
                        id="crm.ui.account.lead.mark_as_unreachable"
                        defaultMessage="To mark as Unreachable try to call 5 times."
                      />
                    }
                    qualificationTypeCode="uncontactable"
                    account={this.props.account}
                    accountUid={this.props.account.account_uid}
                  />
                  <QualifyButton
                    className="LeadActionsWidget-butRed"
                    title={
                      <FormattedMessage
                        id="crm.ui.account.lead.duplicate"
                        defaultMessage="Duplicate"
                      />
                    }
                    description={
                      <FormattedMessage
                        id="crm.ui.account.lead.mark_as_duplicate"
                        defaultMessage="Mark as duplicated lead"
                      />
                    }
                    qualificationTypeCode="duplicate"
                    account={this.props.account}
                    accountUid={this.props.account.account_uid}
                  />
                  {/* <QualifyButton
                    className="LeadActionsWidget-butRed"
                    title="Lost"
                    description="Select reason"
                    selectReason
                    qualificationTypes={this.props.qualificationTypes}
                    account={this.props.account}
                    accountUid={this.props.account.account_uid}
                  /> */}
                </div>
                <div className="col-md-6 util-flexCenterContent ">
                  <div
                    className="LeadActionsWidget-but
                    LeadActionsWidget-butYellow util-hooverable"
                    onClick={this.handleConvertToProspect}
                    style={{ height: '100%' }}
                  >
                    <strong>
                      <FormattedMessage
                        id="crm.ui.account.lead.convert_to_prospect"
                        defaultMessage="Convert to Prospect"
                      />
                    </strong>
                    <ul>
                      {!hasMarketSize && (
                        <li>
                          <FormattedMessage
                            id="crm.ui.account.lead.missing_market_potential"
                            defaultMessage="Missing Market Potential"
                          />
                        </li>
                      )}
                      {!hasValidatedPhone && (
                        <li>
                          <FormattedMessage
                            id="crm.ui.account.lead.missing_validated_phone_number"
                            defaultMessage="Missing validated phone number"
                          />
                        </li>
                      )}
                      {hasMarketSize && hasValidatedPhone && (
                        <li>
                          <FormattedMessage
                            id="crm.ui.account.lead.ok_can_converttoprospect"
                            defaultMessage="OK - Can convert to prospect!"
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                  <div
                    className="LeadActionsWidget-but
                  LeadActionsWidget-butGreen util-hooverable"
                    onClick={this.handleWon}
                    style={{ height: '100%' }}
                  >
                    <strong>
                      <FormattedMessage
                        id="crm.ui.account.lead.won"
                        defaultMessage="Won!"
                      />
                    </strong>
                    <br />
                    <small>
                      <FormattedMessage
                        id="crm.ui.account.lead.register_new_deal"
                        defaultMessage="Register new Deal"
                      />
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ConvertToProspectModal
          isOpen={this.state.isConvertToProspectModal}
          account={this.props.account}
          accountUid={this.props.account.account_uid}
          onCancel={this.handleCloseConvertToProspect}
          onClose={this.handleCloseConvertToProspect}
        />
        <RegisterWinModal
          isOpen={this.state.isRegisterWinModal}
          account={this.props.account}
          onClose={this.handleCloseConvertToProspect}
        />
      </div>
    )
  }
}

LeadActionsWidget.propTypes = {
  account: shape({}).isRequired,
  documentActions: shape({}).isRequired,
  onShowPotentialPopup: func.isRequired,
  inline: bool
}

LeadActionsWidget.defaultProps = {
  document: undefined,
  inline: false
}

function mapStateToProps(state) {
  return {
    document: getDocument(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    documentActions: bindActions(dispatch, documentActions)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LeadActionsWidget))
