/* eslint-disable camelcase */
import { buildCrmReducer } from 'crm-utils/reducer-utils'

// Initial
const initialState = {
  task: undefined,
  myTasks: [],
  allTasks: [],
  isEditing: false,
  isSaving: false,
  myDialerTasks: [],
  isMyNextTasksModalVisible: false
}

// Actions
export const actions = {
  listMyTasks: {},
  listMyTasksSuccess: {
    updater: action => ({
      myTasks: action.payload.tasks
    })
  },
  listAllTasks: {},
  listAllTasksSuccess: {
    updater: action => ({
      allTasks: action.payload.tasks
    })
  },
  createTask: {},
  createTaskSuccess: {},
  toggleMyNextTasksModal: {
    updater: action => ({ isMyNextTasksModalVisible: action.payload.value })
  }
}

function tasksReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
export const toggleMyNextTasksModal = value => ({
  type: actions.toggleMyNextTasksModal.type,
  payload: { value }
})

// Selectors
export const getMyTasks = state => state.crm.tasks.myTasks
export const getAllTasks = state => state.crm.tasks.allTasks
export const getIsMyNextTasksModalVisible = state =>
  state.crm.tasks.isMyNextTasksModalVisible
const reducer = buildCrmReducer(tasksReducer, actions)
export default reducer
