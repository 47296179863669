import React, { useEffect } from 'react'
import { mapContextToFilter, convertUnderlineFilterToObject } from '../dashboard-utils'
import { CrmModal } from '../../../components/index'
import { useAnalyticsContext } from 'crm-core/analytics/context'
import { genericTableFactory } from '@cartrack-crm/crm-analytics'
import TableFilters from 'crm-modules/dashboard/table-filters'
import { OrdersFilterSection } from 'crm-modules/orders/orders-filter-section'

const qlOptions = {
  options: (ownProps: any) => {
    const props = { analyticsContext: { filters: ownProps.filters } }
    const filter: any = convertUnderlineFilterToObject({
      ...ownProps.filters,
      ...mapContextToFilter(props)
    })

    if (ownProps.filters.activity_date && !filter.completed_date) {
      if (ownProps.filters.activity_date.value) {
        filter.completed_date = { ...ownProps.filters.activity_date.value }
      } else {
        filter.completed_date = ownProps.filters.activity_date
      }
    }

    if (ownProps.filters.owner && ownProps.filters.owner.user_uid && ownProps.filters.owner.user_uid.value) {
      filter.owner.user_uid = ownProps.filters.owner.user_uid.value
    }

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl
  }),
  fetchPolicy: 'no-cache'
}

export const FitmentsTable: React.ComponentType<any> = genericTableFactory({
  qlOptions,
  rootQLType: 'Order',
  useEdges: true,
  queryRoot: 'orders'
})

interface Props {
  isOpen: boolean
  onClose: Function
  filters: any
  initialColumns: any
  title?: string
}

const defaultInitialColumns = [
  { field: 'account.name', name: 'Account' },
  { field: 'account.lead_campaign.name', name: 'Lead Campaign' },
  { field: 'completed_date', name: 'Fitment Date', type: 'date', width: 100 },
  { field: 'subscription', name: 'Subscription', type: 'number', width: 100 },
  { field: 'hardware_price', name: 'Hardware', type: 'number', width: 100 },
  { field: 'sale_date', name: 'Sale Date', type: 'date', width: 100 },
  { field: 'status_code', name: 'Status', width: 120 },
  { field: 'owner.full_name', name: 'Owner', width: 150 }
]

const FitmentsPopup: React.SFC<Props> = props => {
  const analyticsContext: any = useAnalyticsContext()

  useEffect(() => {
    const mapped_filters: any = { ...props.filters }
    analyticsContext.setFilters(
      {
        ...mapped_filters,
        completed_date: mapped_filters?.activity_date ? { ...mapped_filters?.activity_date } : mapped_filters?.completed_date
      },
      false
    )
  }, [props.filters])

  const initialColumns = defaultInitialColumns

  return (
    <CrmModal isOpen={props.isOpen} title={props.title || 'Fitments Details'} onClose={props.onClose} size="xl">
      <FitmentsTable
        columns={[]}
        style={{ width: '100%' }}
        filters={props.filters}
        initialColumns={initialColumns}
        analyticsContext={analyticsContext}
        filtersComponent={
          <TableFilters
            {...props}
            additionalFilters={
              <OrdersFilterSection
                style={{
                  borderBottom: 0,
                  minWidth: 150,
                  margin: '10px 0',
                  padding: '0 20px'
                }}
              />
            }
          />
        }
      />
    </CrmModal>
  )
}

FitmentsPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined,
  initialColumns: undefined
}

export default FitmentsPopup
