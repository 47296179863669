import React from 'react'
import moment from 'moment'
import { push } from 'connected-react-router'
import { shape, arrayOf, bool } from 'prop-types'
import { CrmActivityList } from '@cartrack-crm/crm-activities'
import ActivityListItem from './activity-list-item.jsx'
import { connect } from 'react-redux'
import { LoadingIcon } from 'util-components'
import { CrmInfoBlock } from 'crm-components'
import { FormattedMessage } from 'react-intl'
import { useAccountPermissionsContext } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import { getUser } from 'crm-duxs/crm-reducer'
import { _addrFormat } from 'crm-modules/meeting/hoc/edit-meeting-hoc.js'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const ActivitiesList = props => {
  let activities = props.activities ? [...props.activities] : []
  if (activities.length > 0) {
    activities = activities.sort((a, b) =>
      b.start_time && a.start_time
        ? moment(b.start_time).diff(moment(a.start_time))
        : 0
    )
  }

  const accountPermissionsContext = useAccountPermissionsContext()
  const userUid = props.user.user_uid

  return (
    <div className="ActivitiesList" style={props.style}>
      {props.isLoading && (
        <div className="util-fullWidth util-textCenter">
          <LoadingIcon />
        </div>
      )}
      {activities.length > 0 ? (
        <CrmActivityList
        account={props.account}
        activities={activities.map(activity => {
          const p_module = activity.activity_type
            ? activity.activity_type.module
            : null
          const has_permission = p_module
            ? accountPermissionsContext.hasPermissionByType(
                PERMISSIONS_TYPES.ACTIVITIES_READ,
                p_module
              )
            : true
          const has_recording_permission = p_module
            ? accountPermissionsContext.hasPermissionByType(
                PERMISSIONS_TYPES.ACTIVITIES_RECORDING_READ,
                p_module
              )
            : true
          return {
            ...activity,
            isOwner: activity.primary_performer_user_uid === userUid,
            has_permission,
            has_recording_permission
          }
        })}
        onEditActivity={props.onEditActivity}
        onShowEmailLog={props.onShowEmailLog}
        dialerUrl={props.crmContext.getAttributeValueByCode(
          'dialer_server_url'
        )}
        onMinutesOfMeeting={activity => {
          props.doChangePage(
            '/crm/account/' +
              activity.account_uid +
              '/activity/' +
              activity.activity_uid +
              '/meeting-minutes'
          )
        }}
        _addrFormat={_addrFormat}
      />
      ) : (
        <div className="util-marginTop">
          <div className="util-marginTop">
            <div className="util-textLight util-textCenter">
              <FormattedMessage
                id={'no.activity.id'}
                defaultMessage="There are no activities for this account yet. Use buttons above to register phone calls, emails or meetings."
              />
              <br />
            </div>
          </div>
        </div>
      )}
      
    </div>
  )
}

ActivitiesList.propTypes = {
  account: shape().isRequired,
  activities: arrayOf(shape({})),
  style: shape({}),
  isLoading: bool.isRequired
}
ActivitiesList.defaultProps = {
  style: {},
  activities: undefined
}

function mapDispatchToProps(dispatch) {
  return {
    doChangePage: path => dispatch(push(path))
  }
}
function mapStateToProps(state) {
  return {
    user: getUser(state)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCrmContext(ActivitiesList))
