import { takeLatest, select } from 'redux-saga/effects'
import { CRM_CURRENT_USER_CHANGED, getUser } from 'crm-duxs/crm-reducer'

const onCurrentUserChanged = options => {
  return function*(event) {
    const user = yield select(getUser)
    setSentryUser(options ? options.Sentry : undefined, user)
  }
}
function setSentryUser(Sentry, user) {
  console.log('setSentry user', Sentry, user)
  if (Sentry !== 'undefined') {
    // prettier-ignore
    Sentry.configureScope(function (scope) {
      scope.setUser(user ? { username: user.username, id: user.user_uid } : {})
      scope.setExtra('crm_version', process.env.APP_VERSION)
    })
  }
}

export default options => {
  return function* crmSagaSentry() {
    console.log('Create crmSagaSentry', options)
    yield takeLatest(CRM_CURRENT_USER_CHANGED, onCurrentUserChanged(options))
  }
}
