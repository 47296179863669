/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import cloneDeep from 'clone-deep'

export const SET_FILTER_VALUE = 'SET_FILTER_VALUE'
export const SET_SORT_VALUE = 'SET_SORT_VALUE'
export const CLEAR_FILTERS_GROUP = 'CLEAR_FILTERS_GROUP'
export const STORE_FILTER_VALUE = 'STORE_FILTER_VALUE'
export const STORE_FILTER_GROUP = 'STORE_FILTER_GROUP'
export const STORE_SORT_GROUP = 'STORE_SORT_GROUP'
export const FILTER_VALUE_UPDATED = 'FILTER_VALUE_UPDATED'
export const GROUP_QFILTER_UPDATED = 'GROUP_QFILTER_UPDATED'
export const INIT_FILTERS_GROUP = 'INIT_FILTERS_GROUP'
export const SET_FILTERS_GROUP_VIEW = 'SET_FILTERS_GROUP_VIEW'

const initialState = {
  filters: {},
  sorts: {},
  qfilters: {},
  selectedViews: {},
  filtersDefinitions: [
    {
      field: 'owner_user_uid',
      component: 'UserSelect',
      componentProps: {
        placeholder: 'Owner',
        permissionCode: 'all',
        emptyOptionLabel: 'All',
        addEmptyOption: true,
        disabled: false
      },
      name: 'Owner',
      excludeFromGroupCode: ['my_tasks']
    },
    {
      field: 'owner_group_uid',
      component: 'UserGroupSelect',
      componentProps: {
        disabled: false,
        permissionCode: 'all',
        placeholder: 'Owner Group',
        returnItem: true
      },
      name: 'Owner Group',
      excludeFromGroupCode: []
    },
    {
      field: 'additional_owner_uid',
      component: 'UserSelect',
      componentProps: {
        placeholder: 'Additional Owner',
        permissionCode: 'all',
        emptyOptionLabel: 'All',
        addEmptyOption: true,
        disabled: false
      },
      name: 'Additional Owner',
      excludeFromGroupCode: ['my_tasks']
    },
    {
      field: 'city',
      component: 'TextInput',
      componentProps: {
        placeholder: 'Owner'
      },
      name: 'City'
    },
    {
      field: 'lead_campaign_uid',
      component: 'CampaignSelect',
      componentProps: {
        placeholder: 'Lead Campaign',
        type: 'lead_source'
      },
      name: 'Lead Campaign'
    },
    {
      field: 'lead_campaign__lead_source_uid',
      component: 'LeadSourceSelect',
      componentProps: {
        placeholder: 'Lead Source',
        type: 'lead_source'
      },
      name: 'Lead Source'
    },
    {
      field: 'lead_campaign__lead_source__kind',
      component: 'LeadSourceTypeSelect',
      componentProps: {
        placeholder: 'Lead Source Type',
        type: 'lead_source_type'
      },
      name: 'Lead Source Type'
    },
    {
      field: 'sales_campaign_uid',
      component: 'CampaignSelect',
      componentProps: {
        placeholder: 'Sales Campaign',
        type: 'sale'
      },
      name: 'Sales Campaign'
    },
    {
      field: 'kind',
      component: 'AccountType',
      componentProps: {
        placeholder: 'Account type'
      },
      name: 'Account type',
      excludeFromGroupCode: ['customers', 'leads', 'prospects']
    },
    {
      field: 'lead_in_time',
      component: 'DateRange',
      componentProps: {
        placeholder: 'Leads received between'
      },
      name: 'Lead in date'
    },
    {
      field: 'tags',
      component: 'TagType',
      componentProps: {
        placeholder: 'Tag type'
      },
      name: 'Tag type',
      excludeFromGroupCode: ['my_tasks']
    },
    {
      code: 'processing_status',
      field: 'processing_status',
      component: 'Dropdown',
      componentProps: {
        placeholder: 'Processing Status',
        options: [
          {
            name: 'Never Processed',
            value: 'not_processed',
            qfilter: {
              processing_status: 'not_processed'
            }
          },
          {
            name: 'Lost',
            value: 'lost',
            qfilter: {
              processing_status: 'lost'
            }
          },
          {
            name: 'Customer',
            value: 'customer',
            qfilter: {
              processing_status: 'customer'
            }
          },
          {
            name: 'In process',
            value: 'in_process',
            qfilter: {
              processing_status: 'in_process'
            }
          }
        ]
      },
      name: 'Processing Status'
    },
    {
      code: 'no_owner',
      component: 'Checkbox',
      componentProps: {
        placeholder: 'No owner'
      },
      qfilter: {
        no_owner: true
      },
      name: 'No owner',
      excludeFromGroupCode: ['my_tasks']
    },
    {
      code: 'not_touched',
      component: 'Checkbox',
      componentProps: {
        placeholder: 'Not Touched'
      },
      qfilter: {
        first_account_touch_time: null
      },
      name: 'Not Touched'
    },
    {
      code: 'touched',
      component: 'Checkbox',
      componentProps: {
        placeholder: 'Touched'
      },
      qfilter: {
        first_account_touch_time: { $ne: null }
      },
      name: 'Touched'
    },
    {
      code: 'not_reached',
      component: 'Checkbox',
      componentProps: {
        placeholder: 'Not Reached'
      },
      qfilter: {
        first_account_reach_time: null
      },
      name: 'Not Reached'
    },
    {
      code: 'reached',
      component: 'Checkbox',
      componentProps: {
        placeholder: 'Reached'
      },
      qfilter: {
        first_account_reach_time: { $ne: null }
      },
      name: 'Reached'
    },
    {
      code: 'include_opportunity_deleted',
      component: 'Checkbox',
      componentProps: {
        placeholder: 'Include deleted opportunities'
      },
      qfilter: {
        opportunity_is_deleted: { $gte: 0 }
      },
      name: 'Include deleted opportunities',
      showInGroupCode: ['opportunities']
    }
  ]
}

function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_FILTER_VALUE: {
      const newState = {
        ...state,
        filters: cloneDeep(state.filters)
      }

      if (action.payload.groupCode && action.payload.filterCode) {
        if (!newState.filters[action.payload.groupCode]) {
          newState.filters[action.payload.groupCode] = {}
        }

        newState.filters[action.payload.groupCode][action.payload.filterCode] = action.payload.filterValue
      }

      return newState
    }
    case STORE_FILTER_GROUP: {
      const newState = {
        ...state,
        filters: cloneDeep(state.filters)
      }

      newState.filters[action.payload.groupCode] = action.payload.groupFilter

      return newState
    }
    case STORE_SORT_GROUP: {
      const newState = {
        ...state,
        sorts: cloneDeep(state.sorts)
      }

      newState.sorts[action.payload.groupCode] = action.payload.newSorted

      return newState
    }
    case GROUP_QFILTER_UPDATED: {
      const newState = {
        ...state
      }

      newState.qfilters = cloneDeep(state.qfilters)
      newState.qfilters[action.payload.groupCode] = action.payload.qfilter

      return newState
    }
    case SET_FILTERS_GROUP_VIEW: {
      const newSelectedViews = cloneDeep(state.selectedViews)

      if (action.payload.view) {
        newSelectedViews[action.payload.groupCode] = action.payload.view
      } else {
        delete newSelectedViews[action.payload.groupCode]
      }

      return {
        ...state,
        selectedViews: newSelectedViews
      }
    }
    default:
      return state
  }
}

export const initFiltersGroup = groupCode => ({
  type: INIT_FILTERS_GROUP,
  payload: {
    groupCode
  }
})

export const setFiltersGroupView = (groupCode, view) => ({
  type: SET_FILTERS_GROUP_VIEW,
  payload: {
    groupCode,
    view
  }
})

export const setFilterValue = (groupCode, filterCode, filterValue) => ({
  type: SET_FILTER_VALUE,
  payload: {
    groupCode,
    filterCode,
    filterValue
  }
})

export const setSortValue = (groupCode, newSorted) => {
  return {
    type: SET_SORT_VALUE,
    payload: {
      groupCode,
      newSorted
    }
  }
}

export const clearFiltersGroup = groupCode => ({
  type: CLEAR_FILTERS_GROUP,
  payload: {
    groupCode
  }
})

export const getFilterGroup = (state, groupCode) =>
  state.crm.filters.filters[groupCode] ? state.crm.filters.filters[groupCode] : {}

export const getSortsGroup = (state, groupCode) => {
  return state.crm.filters.sorts[groupCode] ? state.crm.filters.sorts[groupCode] : {}
}

export const getSelectedView = (state, groupCode) => state.crm.filters.selectedViews[groupCode]

export const getQFilterGroupRaw = (state, groupCode) =>
  state.crm.filters.qfilters[groupCode] ? state.crm.filters.qfilters[groupCode] : {}

export const getIsIncludeDeleted = (state, groupCode) =>
  state.crm.filters.filters[groupCode] && state.crm.filters.filters[groupCode].include_deleted
    ? state.crm.filters.filters[groupCode].include_deleted.enabled
    : undefined

export const getIsIncludeDuplicates = (state, groupCode) =>
  state.crm.filters.filters[groupCode] && state.crm.filters.filters[groupCode].include_duplicates
    ? state.crm.filters.filters[groupCode].include_duplicates.enabled
    : undefined

export const getQFilterGroup = createSelector(
  getQFilterGroupRaw,
  getSelectedView,
  getIsIncludeDeleted,
  getIsIncludeDuplicates,
  (qfilter, view, includeDeleted, includeDuplicates) => {
    let ret = qfilter
      ? {
          ...qfilter
        }
      : {}

    if (view && view.filter) {
      ret = { ...ret, ...view.filter }
    }

    if (includeDeleted) {
      // eslint-disable-next-line
      ret.include_deleted = true
    }
    if (includeDuplicates) {
      ret.include_duplicates = true
    }

    return ret
  }
)

export const getFiltersDefinitions = state => state.crm.filters.filtersDefinitions

export default filtersReducer
