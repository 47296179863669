import { DashBoardWidget } from '../dashboard-widget'
import React from 'react'
import widgetSaleToWinRatioHoc, { widgetSaleToWinRatioHocProps } from './sale-to-win-ratio-widget-hoc'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetSaleToWinRatio: React.ComponentType<AnalyticDashboardWidget> = widgetSaleToWinRatioHoc(
  (props: widgetSaleToWinRatioHocProps) => (
    <DashBoardWidget
      avgDataPassedMonth={props.avgSaleToWinRatioThreeMonth}
      bigContent={props.bigContent}
      footerRender={props.threeMonthAgoSaleToWinRatio}
      isLoading={props.isLoading}
      onClickDetail={props.onClickDetail}
      refetch={props.refetchData}
      subtitle={props.mainCaption}
      title='Sale to Win Ratio'
      totalNumber={props.totalSaleToWinRatio}
      note={(
        <div className='util-textMedium'>
          Customer first fitted (<span className='util-textBold'>Win</span>) / <span className='util-textBold'>Sales</span> made (voicelog)
        </div>)}
    />
  )
)
