import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useCrmContext } from 'crm-core/contexts/crm-context'
import { Aggregate, GroupBy } from 'crm-types'

export const ACTIVITIES_QL = gql`
  query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        activity_participants(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
          edges {
            edge {
              activity_uid
              start_date_month
              call_duration
            }
          }
        }
      }
    }
  }
`
type AggregateActivity = {
  activity_uid?: string
  start_date_month?: string
  call_duration?: string
  __typename?: string
}

type useQueryActivity = (
  filter?: any,
  groupBy?: GroupBy,
  aggregate?: Aggregate[]
) => { loading: boolean; listActivity: AggregateActivity[]; refetch: Function }

export const useQueryActivity: useQueryActivity = (filter, groupBy, aggregate) => {
  const context = useCrmContext()
  const { data, loading, refetch } = useQuery(ACTIVITIES_QL, {
    variables: {
      master_uid: context?.master?.master_uid,
      groupBy: groupBy ?? undefined,
      aggregate: aggregate ?? undefined,
      instance_uid: context.instance?.instance_uid,
      filter: { ...filter }
    },
    fetchPolicy: 'no-cache'
  })
  const listActivity = data?.master?.instance?.activity_participants?.edges?.map(e => e.edge) ?? []
  return { loading, listActivity, refetch }
}

export const TIME_ON_PHONE_QL = gql`
  query dashboard_time_on_phone($filter: JSON) {
    dashboard_time_on_phone(filter: $filter) {
      start_date_month
      call_duration
      count
    }
  }
`

export const useQueryTimeOnPhone = filter => {
  const { data, loading, refetch } = useQuery(TIME_ON_PHONE_QL, {
    variables: {
      filter
    },
    fetchPolicy: 'no-cache'
  })
  const listTimeOnPhone = data?.dashboard_time_on_phone ?? []
  return { loading, listTimeOnPhone, refetch }
}
