import { CrmLabeledField } from 'crm-components'
import generateInlineEditableField from 'crm-components/hoc/inline-editable-component-web-hoc'
import editMeetingHoc, {
  saveHandler
} from 'crm-modules/meeting/hoc/edit-meeting-hoc.js'
import React from 'react'
import AvatarPreview from 'crm-components/utils/user-avatar.jsx'
import styles from './section-participants/section-participants.scss'
import { shape, bool, string, any, func } from 'prop-types'

class MeetingInlineEditable extends React.PureComponent {
  renderInner_CrmLabeledField() {
    const { label, value, displayValue, className, emptyValue } = this.props
    return (
      <CrmLabeledField label={label} noMargin lg>
        {(!Array.isArray(value) && value) ||
        (Array.isArray(value) && value.length > 0) ? (
          <div className={className}>{displayValue || value}</div>
        ) : (
          <div>{emptyValue}</div>
        )}
      </CrmLabeledField>
    )
  }

  renderInner_Participants() {
    const { value, emptyValue } = this.props
    return (
      <div className={styles.SectionParticipants}>
        <CrmLabeledField lg noMargin>
          {value.length > 0 && (
            <div className={styles.ParticipantItemsWrapper}>
              {value.map(v => (
                <div
                  key={v.user_uid}
                  className={`${styles.ParticipantItem} ${styles.ReadMode}`}
                >
                  <div className={styles.ParticipantItem__Top}>
                    <div className={styles.ParticipantItem__AvatarWrapper}>
                      <AvatarPreview
                        src={v.photo_url ? v.photo_url : ''}
                        style={{
                          width: 42,
                          height: 42,
                          borderRadius: 42
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.ParticipantItem__Name}>
                    <small>{v.full_name}</small>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!(value.length > 0) && <div>{emptyValue}</div>}
        </CrmLabeledField>
      </div>
    )
  }

  renderInner_NoLabel() {
    const { value, displayValue, className, emptyValue } = this.props
    return (
      <CrmLabeledField lg noMargin>
        {(!Array.isArray(value) && value) ||
        (Array.isArray(value) && value.length > 0) ? (
          <div className={className}>
            {displayValue && <span>{displayValue}</span>}
            {!displayValue && value && typeof value === 'string' && (
              <span>{value}</span>
            )}
          </div>
        ) : (
          <div>{emptyValue}</div>
        )}
      </CrmLabeledField>
    )
  }

  render() {
    const { readModeStyle } = this.props
    if (readModeStyle === 'CrmLabeledField') {
      return <div>{this.renderInner_CrmLabeledField()}</div>
    } else if (readModeStyle === 'Participants') {
      return <div>{this.renderInner_Participants()}</div>
    } else {
      return <div>{this.renderInner_NoLabel()}</div>
    }
  }
}

MeetingInlineEditable.propTypes = {
  label: string,
  value: any,
  displayValue: string,
  className: string,
  emptyValue: string,
  readModeStyle: string
}

MeetingInlineEditable.defaultProps = {
  label: '',
  value: undefined,
  displayValue: '',
  className: '',
  emptyValue: '',
  readModeStyle: undefined
}

class MeetingEditComponent extends React.PureComponent {
  handleValueChange = object => {
    if (
      this.props.fieldName === 'subject' ||
      this.props.fieldName === 'note_text'
    ) {
      this.props.input.onChange(object.currentTarget.value)
    } else {
      this.props.input.onChange(object)
    }
  }

  render() {
    console.log('Render MeetingEditComponent', this.props)
    return (
      <div
        style={
          this.props.isSavingInlineEdit
            ? {}
            : this.props.editComponentWrapperStyles
        }
      >
        <this.props.editComponent
          {...this.props.editComponentProps}
          input={{
            value: this.props.input.value,
            onChange: this.handleValueChange
          }}
        />
      </div>
    )
  }
}

MeetingEditComponent.propTypes = {
  fieldName: string,
  input: shape({}),
  isSavingInlineEdit: bool,
  editComponentWrapperStyles: shape({}),
  editComponentProps: shape({})
}

MeetingEditComponent.defaultProps = {
  fieldName: '',
  input: undefined,
  isSavingInlineEdit: undefined,
  editComponentWrapperStyles: {},
  editComponentProps: {}
}

const inlineEditable = generateInlineEditableField(MeetingInlineEditable, {
  editComponent: editMeetingHoc(MeetingEditComponent),
  saveHandler
})
export default inlineEditable
