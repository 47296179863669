import { CrmDropdown } from 'crm-components'
import gql from 'graphql-tag'
import React from 'react'
import { Query } from 'react-apollo'
import { string, shape } from 'prop-types'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import { LoadingIcon } from 'util-components'

const qlqListCamsSalesAgents = gql`
  query listCamsSalesAgents($master_uid: String!, $instance_uid: String!) {
    master(master_uid: $master_uid) {
      instance(instance_uid: $instance_uid) {
        listCamsSalesAgents {
          userId
          firstName
          surname
        }
      }
    }
  }
`

const CrmCamsSalesAgentsSelect = props => {
  const variables = {
    instance_uid: props.instance_uid
      ? props.instance_uid
      : props.crmContext.instance.instance_uid,
    master_uid: props.crmContext.master.master_uid
  }
  return (
    <Query query={qlqListCamsSalesAgents} variables={variables}>
      {({ loading, error, data }) => {
        const options =
          data.master &&
          data.master.instance &&
          data.master.instance.listCamsSalesAgents
            ? data.master.instance.listCamsSalesAgents.map(v => ({
                name: v.firstName + ' ' + v.surname,
                value: v.userId
              }))
            : []
        options.sort((a, b) => {
          const va = a && a.name ? a.name : ''
          const vb = b && b.name ? b.name : ''
          return va.localeCompare(vb)
        })
        return (
          <div className="util-flexRow">
            <div className="util-flexGrow">
              <CrmDropdown
                {...props}
                options={options}
                placeholder={props.placeholder}
                addEmptyOption
                disabled={loading}
              />
            </div>
            <div className="util-marginLeftSm">
              {loading && (
                <span>
                  <LoadingIcon />
                </span>
              )}
            </div>
          </div>
        )
      }}
    </Query>
  )
}

CrmCamsSalesAgentsSelect.propTypes = {
  placeholder: string.isRequired,
  crmContext: shape({}).isRequired,
  instance_uid: string.isRequired
}

export default withCrmContext(CrmCamsSalesAgentsSelect)
