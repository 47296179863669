import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape, func, bool, arrayOf } from 'prop-types'
import Dropdown from 'util-components/dropdown.jsx'
import { CrmModal, CrmButton } from 'crm-components'
import {
  getAvailableInstances,
  changeSelectedInstance,
  fetchAvailableInstances
} from 'crm-duxs/crm-reducer'

class SelectBranchModal extends PureComponent {
  handleSelectInstance = (event) => {
    if (event.currentTarget.id) {
      const instance = this.props.availableInstances.find(
        (inst) => inst.instance_uid === event.currentTarget.id
      )
      if (instance) {
        console.log(instance)
        this.props.doChangeSelectedInstance(instance)
      }
    }
  }

  render() {
    console.log('render SelectBranchModal', this.props)
    const options = this.props.availableInstances
      ? this.props.availableInstances
          .filter((i) => i.is_active === true)
          .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
          .map((inst) => ({
            name: inst.name,
            value: inst.instance_uid,
            element: (
              <div key={inst.instance_uid}>
                <div id={inst.instance_uid} onClick={this.handleSelectInstance}>
                  {inst.name}
                </div>
              </div>
            )
          }))
      : []
    console.log('select branch modal')
    console.dir(this.props)
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Select Territory"
        showFooter={false}
        size="xs"
        isShowCloseBtn={false}
        extraClassNames={{ bodyClassNames: 'util-overflowInitial' }}
      >
        <div className="util-paddingMd">
          <div style={{ height: '100%', width: '100%', paddingBottom: '0px' }}>
            <fieldset className="Form-fieldset CrmForm-form-fieldset">
              <div className="row">
                <div className="col-xs-12 ">
                  <Dropdown
                    title="Select Branch"
                    options={options}
                    extraClassNames={{
                      containerClassNames: ` `
                    }}
                    input={{
                      value: this.props.selectedInstance
                        ? this.props.selectedInstance.instance_uid
                        : undefined
                    }}
                  />
                </div>
              </div>
              <div className="row util-marginTop util-textRight">
                <div className="col-md-12">
                  <CrmButton
                    type="primary"
                    label="Close"
                    onClick={this.props.onClose}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </CrmModal>
    )
  }
}
SelectBranchModal.propTypes = {
  availableInstances: arrayOf(shape({})),
  doChangeSelectedInstance: func.isRequired,
  isOpen: bool.isRequired,
  selectedInstance: shape({}),
  onClose: func.isRequired
}

SelectBranchModal.defaultProps = {
  selectedInstance: undefined,
  availableInstances: undefined
}

function mapStateToProps(state) {
  const availableInstances = getAvailableInstances(state)
  return {
    availableInstances: availableInstances?.available_instances
      ? availableInstances.available_instances
      : []
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangeSelectedInstance: (instance) => {
      dispatch(changeSelectedInstance(instance))
    },
    doFetchAvailableInstances: () => dispatch(fetchAvailableInstances())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectBranchModal)
