/* eslint-disable */
import React from 'react'
const widgetDef = {}

class WidgetProcessInstancesByStep extends React.PureComponent {
  render() {
    return <div> WidgetProcessInstancesByStep </div>
  }
}

export default WidgetProcessInstancesByStep
