import React from 'react'
import styled from 'styled-components'
import { ActivityActionButtons } from './activity-action'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1.3px solid rgba(84, 84, 84, 0.25);
  box-shadow: 0 0 6px 0 rgba(84, 84, 84, 0.25);

  & > :nth-child(1) {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 8px;
    border-right: 1px solid #dddddd;
    width: 120px;
  }

  & > :nth-child(2) {
    padding-top: 16px;
    padding-bottom: ${(p) => (p.childrenLength === 2 ? '8px' : '16px')};
    padding-left: ${(p) => (p.childrenLength === 2 ? '16px' : '16px')};
    padding-right: ${(p) => (p.childrenLength === 2 ? '8px' : '4px')};
    flex: 3.5;
  }
  & > :nth-child(3) {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-right: 8px;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
  }
  &:hover {
    border: 1.3px solid rgba(75, 145, 195, 0.5);
  }
  &:hover ${ActivityActionButtons} {
    visibility: visible;
  }

  ${(p) => !p.isSuccess && 'background: #ECECEC;'}
`

const ActivityItem = (props) => {
  return (
    <Container
      childrenLength={props.children.length}
      isSuccess={props.isSuccess}
    >
      {props.children}
    </Container>
  )
}

export default ActivityItem
