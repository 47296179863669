import React from 'react'
import { hhmmss } from 'crm-utils/common-utils'
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl'
import { useAttribute } from 'crm-core/contexts/crm-context'

const RecordingRenderer = (row: any) => {
  const dialer_server_url = useAttribute('dialer_server_url')
  if (!dialer_server_url) {
    return <span />
  } else {
    return row && row.original && row.original?.call_log?.voice_record_uid ? (
      <audio className="player" controls preload="none" style={{ height: 20 }}>
        <source src={`${dialer_server_url}/play/${row.original?.call_log?.call_uid}.mp3`} type=" audio/mp3" />
        <FormattedMessage
          id="crm.ui.activity.browser_does_not_support"
          defaultMessage="Your browser does not support the audio element."
        />
      </audio>
    ) : (
      <span />
    )
  }
}

export const columnsActivity = {
  type: 'Activity',
  fields: [
    {
      name: 'activity.all_participant_names',
      queryFragment: {
        selections: [
          {
            name: 'participants',
            selections: [
              { name: 'role_code' },
              {
                name: 'user',
                selections: [{ name: 'full_name' }, { name: 'user_uid' }]
              }
            ]
          }
        ]
      },
      Cell: row =>
        row?.original?.participants ? (
          <div>
            {row.original.participants.map((participant, idx) => {
              if (
                participant.user &&
                (participant.role_code === 'participant' || participant.role_code === 'performer')
              ) {
                return (
                  <span key={idx}>
                    {idx > 0 ? ', ' : ''}
                    {participant.user.full_name}
                  </span>
                )
              }
              return <span key={idx} />
            })}
          </div>
        ) : (
          <span />
        ),
      description: 'All Participants Names'
    },
    {
      description: 'Activity Start date',
      name: 'start_time',
      width: 120,
      Cell: row =>
        row && row.original && row.original.start_time ? (
          <span>
            <FormattedDate value={row.original.start_time} />{' '}
            {row.original.start_time && <FormattedTime value={row.original.start_time} />}
          </span>
        ) : (
          <span />
        )
    },
    {
      description: 'Call Time',
      name: 'call_time',
      width: 120,
      Cell: row => (row?.original?.call_time ? hhmmss(row.original.call_time) : <span />)
    },
    {
      description: 'Phone Call Start Time',
      name: 'call_start_time',
      width: 120,
      Cell: row =>
        row && row.original && row.original.call_start_time ? (
          <span>
            <FormattedDate value={row.original.call_start_time} />{' '}
            {row.original.call_start_time && <FormattedTime value={row.original.call_start_time} />}
          </span>
        ) : (
          <span />
        )
    },
    {
      description: 'Phone Call End Time',
      name: 'call_end_time',
      width: 120,
      Cell: row =>
        row && row.original && row.original.call_end_time ? (
          <span>
            <FormattedDate value={row.original.call_end_time} />{' '}
            {row.original.call_end_time && <FormattedTime value={row.original.call_end_time} />}
          </span>
        ) : (
          <span />
        )
    },
    {
      description: 'Recording',
      name: 'call_external_uid',
      Cell: RecordingRenderer,
      width: 200
    },
    {
      description: 'Direction',
      name: 'direction'
    },
    {
      description: 'Subject (meeting)',
      name: 'subject'
    },
    {
      description: 'Is First?',
      name: 'first_type'
    },
    {
      description: 'Minutes Location',
      name: 'meta',
      Cell: row =>
        row?.original?.meta && row?.original?.meta?.meeting_minutes_completed_location ? (
          <span className="util-marginAuto">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`http://www.google.com/maps/place/${row.original.meta.meeting_minutes_completed_location.latitude},${row.original.meta.meeting_minutes_completed_location.longitude}`}
            >
              <i className="fa fa-map-marked-alt" />
            </a>
          </span>
        ) : (
          <span />
        )
    },
    {
      description: 'Activity UID',
      name: 'activity_uid'
    },
    {
      description: 'Note text',
      name: 'note_text'
    },
    {
      description: 'Location Validation',
      name: 'meeting_minutes_location_validation',
      Cell: row => {
        const validate: string = row?.original?.meeting_minutes_location_validation
        if (validate == 'true') {
          return <i className="fa fa-check-circle util-marginAuto util-textGreen" />
        }
        if (validate == 'false') {
          return <i className="fa fa-times util-marginAuto util-textRed" />
        }
      }
    },
    {
      description: 'Location Distance',
      name: 'meeting_minutes_location_distance',
      Cell: row => {
        let distance: string = row?.original?.meeting_minutes_location_distance
        if (distance) {
          distance = `${parseFloat(distance).toFixed(2)} km`
        }
        return distance
      }
    },
    {
      name: `is_customer`,
      description: 'Is Customer',
      Cell: row => (row?.original?.is_customer ? 'Yes' : 'No')
    },
    {
      description: 'Account',
      type: 'Account',
      name: 'account'
    },
    {
      description: 'Main Participant',
      type: 'ActivityParticipant',
      name: 'primary_performer'
    },
    {
      description: 'Status',
      type: 'ActivityStatus',
      name: 'status'
    },
    {
      description: 'Call Disposition',
      type: 'CallDispositionType',
      name: 'disposition_type'
    },
    {
      description: 'Type',
      type: 'ActivityType',
      name: 'activity_type'
    }
  ]
}

export const columnsActivityStatus = {
  type: 'ActivityStatus',
  fields: [
    {
      name: `name`,
      description: 'Name'
    },
    {
      name: `is_success`,
      description: 'Is Success',
      Cell: row => (row.original.status && row.original.status.is_success ? 'Yes' : 'No')
    }
  ]
}

export const columnsActivityType = {
  type: 'ActivityType',
  fields: [
    {
      name: `name`,
      description: 'Activity Type Name'
    },
    {
      name: `medium_code`,
      description: 'Medium',
      Cell: row => row.original.medium_code
    },
    {
      name: `module`,
      description: 'Module'
    }
  ]
}

export const columnsDispositionType = {
  type: 'CallDispositionType',
  fields: [
    {
      name: `name`,
      description: 'Disposition Type Name'
    }
  ]
}

export const columnsActivityParticipant = {
  type: 'ActivityParticipant',
  fields: [
    {
      description: 'User',
      type: 'User',
      name: 'user'
    },
    {
      name: 'role_code',
      description: 'Role'
    }
  ]
}
