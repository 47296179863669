/* eslint-disable camelcase */
import React, { Component, useState } from 'react'
import { Field } from 'redux-form'
import validate from 'crm-utils/validates'
import { CrmDropdown, CrmButton } from 'crm-components'
import { SectionHeader } from 'util-components'
interface TimeSchedulerFormItemProps {
  fields: any
}
class TimeSchedulerFormItem extends Component<TimeSchedulerFormItemProps> {
  repeatOptions: { name: string; value: string }[]
  constructor(props: TimeSchedulerFormItemProps) {
    super(props)
    this.state = {}
    this.repeatOptions = [
      { name: 'Day', value: 'daily' },
      { name: 'Week', value: 'weekly' },
      { name: 'Month', value: 'monthly' }
    ]
  }

  handleCreate = () => {
    this.props.fields.push({})
  }

  handleDelete = e => {
    this.props.fields.remove(e.currentTarget.id)
  }

  render() {
    return (
      <React.Fragment>
        <SectionHeader
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>Schedule Info</div>
              <CrmButton onClick={this.handleCreate} label="Add Schedule" style={{ marginRight: 20 }} />
            </div>
          }
          sub
          id="schedule-info-section"
        />
        <div style={{ marginTop: '10px' }}>
          {this.props.fields.map((item, index) => {
            const schedule = this.props.fields.get(index)
            return (
              <div
                className="CrmMdCard util-paddingSm"
                key={item}
                style={{
                  margin: '15px 0px',
                  padding: 15,
                  position: 'relative'
                }}
              >
                <div>
                  <b>Repeat Every: </b>
                </div>
                <Field name={`${item}.repeat`} component={CrmDropdown} options={this.repeatOptions} />
                <Field
                  name={`${item}.monthly`}
                  component={DateInput}
                  disabled={['daily', 'weekly'].includes(schedule.repeat)}
                />
                <Field
                  name={`${item}.weekly`}
                  component={WeeklyInput}
                  disabled={['daily', 'monthly'].includes(schedule.repeat)}
                />
                <Field name={`${item}.hourly`} component={HourInput} type="text" validate={[validate.required]} />
                <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
                  <CrmButton icon="trash" onClick={this.handleDelete} />
                </div>
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

const days = [
  {
    name: 'Monday',
    value: '1'
  },
  {
    name: 'Tuesday',
    value: '2'
  },
  {
    name: 'Wednesday',
    value: '3'
  },
  {
    name: 'Thursday',
    value: '4'
  },
  {
    name: 'Friday',
    value: '5'
  },
  {
    name: 'Saturday',
    value: '6'
  },
  {
    name: 'Sunday',
    value: '7'
  }
]

const WeeklyInput = props => {
  return <OptionSelector {...props} label="Days" addButtonLabel="Add Day" options={days} />
}

const DateInput = props => {
  return (
    <OptionSelector
      {...props}
      label="Dates"
      addButtonLabel="Add Date"
      options={[...Array(31).keys()]
        .map(x => String(x + 1))
        .concat('End Of Month')
        .map(v => ({
          value: v,
          name: v
        }))}
    />
  )
}

const HourInput = props => {
  const options = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
    .map(h => {
      if (h < 10) return `0${h}`
      return `${h}`
    })
    .map(v => ({
      value: v,
      name: v + ':00'
    }))
    .concat({
      value: '*',
      name: 'ALL'
    })

  return <OptionSelector {...props} label="Hours" addButtonLabel="Add Hour" options={options} />
}

const OptionSelector = props => {
  const [values, setValues] = useState(
    props.input.value
      .split(',')
      .filter(v => v)
      .map(v => v.trim())
  )
  const [showDropDown, setShowDropdown] = useState(false)
  const toggleDropdown = () => setShowDropdown(!showDropDown)

  const onChange = value => {
    let nextValues = values
      .concat(value)
      .sort((a, b) => a - b)
      .reduce((p, c) => {
        return p.includes(c) ? p : p.concat(c)
      }, [])
    if (value === '*') {
      nextValues = ['*']
    } else {
      nextValues = nextValues.filter(v => v !== '*')
    }
    setValues(nextValues)
    setShowDropdown(false)
    props.input.onChange(nextValues.join(', '))
  }

  const removeValue = value => {
    const nextValues = values.filter(v => v !== value)
    setValues(nextValues)
    props.input.onChange(nextValues.join(', '))
  }

  const menus = props.options.filter(v => !values.includes(v.value))
  if (props.disabled) return null

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ paddingBottom: 5, paddingTop: 10 }}>
        <b>{props.label}:</b>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {values.map(v => {
          let display = v
          const item = props.options.find(option => v === option.value)
          if (item) {
            display = item.name
            if (item.render) {
              return item.render(() => removeValue(v))
            }
          }
          return (
            <span onClick={() => removeValue(v)} key={v} className="CrmChip CrmChip-blue CrmChip-small">
              {display}
            </span>
          )
        })}
        {values.length !== props.options.length && (
          <div className="util-flexCenterContent">
            <div className="Ellipse-Dropdown">
              <span className="CrmChip CrmChip-default CrmChip-small" onClick={toggleDropdown}>
                {showDropDown ? 'Close X' : props.addButtonLabel}
              </span>
              {showDropDown && (
                <ul className="left" style={{ zIndex: 2 }}>
                  {menus.map(menu => {
                    return (
                      <li key={menu.value} onClick={() => onChange(menu.value)}>
                        <span>{menu.name}</span>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TimeSchedulerFormItem
