import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'
import { string } from 'prop-types'

const qlqTerritory = gql`
  {
    territories {
      territory_uid
      name
      kind
    }
  }
`

const TerritorySelect = props => {
  const variables = {}
  return (
    <Query query={qlqTerritory} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.territories
            ? data.territories.map(v => ({
                ...v,
                value: v.territory_uid
              }))
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_territory"
                  defaultMessage="Select Territory"
                />
              )
            }
          />
        )
      }}
    </Query>
  )
}

TerritorySelect.propTypes = {
  placeholder: string
}

export default TerritorySelect
