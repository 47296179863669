const salesSpain = [
  {
    name: 'Guillermo Alvaro',
    sales: 101,
    arpu: 13.93,
    payback: 13.16,
    month: 2
  },
  { name: 'Angel Lopez', sales: 48, arpu: 12.94, payback: 14.81, month: 2 },
  { name: 'Jesus Ariza', sales: 32, arpu: 14.33, payback: 16.5, month: 2 },
  {
    name: 'Juan Vicente Fernandes',
    sales: 26,
    arpu: 16.42,
    payback: 19.59,
    month: 2
  },
  {
    name: 'Francesc Bargallo',
    sales: 24,
    arpu: 10.96,
    payback: 20.53,
    month: 2
  },
  { name: 'Ramon Perez', sales: 23, arpu: 13, payback: 20.47, month: 2 },
  { name: 'Jose Maria', sales: 1, arpu: 14, payback: 138.78, month: 2 }
]

export default salesSpain
