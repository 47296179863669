import React from 'react'
import { any } from 'prop-types'
import { FormattedTime, FormattedDate } from 'react-intl'

const FormattedDateTime = props =>
  props && props.value ? (
    <div>
      <FormattedDate value={props.value} />{' '}
      <FormattedTime value={props.value} />
    </div>
  ) : (
    <div />
  )
FormattedDateTime.propTypes = {
  value: any // eslint-disable-line
}
FormattedDateTime.defaultProps = {
  value: undefined
}
export default FormattedDateTime
