/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import AccountPage from '../account/components/account-page.jsx'

const ProspectPageInner = props => (
  <div>
    {props.prospect && (
      // <Redirect
      //   to={`/crm/accounts/${props.prospect.account_uid}/process/${props
      //     .prospect.prospect_process_uid}`}
      // />
      <AccountPage
        accountUid={props.prospect.account_uid}
        prospect={props.prospect}
        process={props.prospect.process}
      />
    )}
  </div>
)

ProspectPageInner.propTypes = {
  prospect: shape().isRequired
}

const ProspectPageInnerQl = ProspectPageInner

const ProspectPage = props => (
  <ProspectPageInnerQl prospectUid={props.match.params.prospectUid} />
)

ProspectPage.propTypes = {
  match: shape().isRequired
}

export default ProspectPage
