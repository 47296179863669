export function mapKeysAndValues(collection, keyTransform, valTransform) {
  const isArray = Array.isArray(collection)
  const array = isArray ? collection : Object.keys(collection)

  return array.reduce((acc, itemOrKey) => {
    const item = isArray ? itemOrKey : collection[itemOrKey]

    const accKey =
      typeof keyTransform === 'function'
        ? keyTransform(item, isArray ? undefined : itemOrKey)
        : item[keyTransform]

    acc[accKey] =
      typeof valTransform === 'function'
        ? valTransform(item, isArray ? undefined : itemOrKey)
        : valTransform
    return acc
  }, {})
}

export function addReduce(array, propToAdd, predicate, propToCheck) {
  return array.reduce((acc, item) => {
    let n = item
    if (propToAdd) {
      // eslint-disable-next-line no-unused-vars
      for (const key of propToAdd.split('.')) {
        n = n[key]
      }
    }

    if (typeof predicate === 'function')
      // Only add if predicate returns truthy
      return predicate(item) ? acc + n : acc
    else if (predicate)
      // Only add if predicate equals predicateProp
      return predicate === item[propToCheck] ? acc + n : acc

    return acc + n
  }, 0)
}

export function pickKeys(obj, predicate) {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof predicate === 'function' && predicate(obj[key])) acc.push(key)
    else if (predicate && predicate === obj[key]) acc.push(key)

    return acc
  }, [])
}

export function groupByAndMapValues(array, propToGroupBy, valTransform) {
  return array.reduce((acc, item) => {
    const transformed =
      typeof valTransform === 'function'
        ? valTransform(item)
        : item[valTransform]

    if (acc[item[propToGroupBy]]) acc[item[propToGroupBy]].push(transformed)
    else acc[item[propToGroupBy]] = [transformed]

    return acc
  }, {})
}

export function filterMap(array, predicate, iteratee) {
  const l = array.length
  const r = []
  for (let i = 0; i < l; i++) {
    if (!predicate || predicate(array[i], i, array, r))
      r.push(iteratee(array[i], i, array, r))
  }
  return r
}

export function reconciliateGroupItemIds(group, items) {
  if (!group || !items) return null
  return {
    ...group,
    items: items.filter(item => group.itemIds.find(id => item.id === id))
  }
}

export function stopPropagation(event) {
  event.stopPropagation()
}

export function transformAt(arr, idx, transform) {
  return [...arr.slice(0, idx), transform(arr[idx]), ...arr.slice(idx + 1)]
}

export function removeAt(arr, idxOrArray) {
  const isArray = Array.isArray(idxOrArray)
  if (isArray) return arr.filter((e, i) => !idxOrArray.includes(i))
  return [...arr.slice(0, idxOrArray), ...arr.slice(idxOrArray + 1)]
}

export function shallowEqual(a, b, ignores = []) {
  // eslint-disable-next-line no-unused-vars
  for (const k in a)
    if (
      Object.prototype.hasOwnProperty.call(a, k) &&
      (!Object.prototype.hasOwnProperty.call(b, k) || a[k] !== b[k]) &&
      !ignores.includes(k)
    )
      return false

  // eslint-disable-next-line no-unused-vars
  for (const k in b)
    if (
      Object.prototype.hasOwnProperty.call(b, k) &&
      (!Object.prototype.hasOwnProperty.call(a, k) || b[k] !== a[k]) &&
      !ignores.includes(k)
    )
      return false

  return true
}

export function firstDefined(...args) {
  return args.find(a => a !== undefined)
}

export function makeHash(arr, prop, val) {
  const getKey = prop ? x => x[prop] : x => x
  const getVal = val ? x => x[val] : x => x
  return arr.reduce((acc, cur) => {
    acc[getKey(cur)] = getVal(cur)
    return acc
  }, {})
}
