import { graphql, compose } from 'react-apollo'
import { qlqAccountTasks } from 'crm-data/tasks'

const qlOptions = {
  // eslint-disable-next-line
  options: ownProps => ({ variables: { account_uid: ownProps.accountUid } }),
  props: ({ tasksQl, ownProps }) => ({
    ...ownProps,
    tasks: tasksQl && tasksQl.account ? tasksQl.account.tasks : undefined,
    isLoading: tasksQl ? tasksQl.loading : false,
    tasksQl
  }),
  fetchPolicy: 'no-cache'
}
const TasksListQLHOC = compose(
  graphql(qlqAccountTasks, {
    ...qlOptions,
    name: 'tasksQl'
  })
)

export default TasksListQLHOC
