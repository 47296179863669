import React, { useState } from 'react'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import { UserFilterValueString, UserFilterDropDown } from '.'
import { FilterSection } from '../tools'

export const FilterSectionUser = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleDropDown = () => setIsExpanded(!isExpanded)
  const closeDropDown = () => setIsExpanded(false)
  const { filters, setFilters } = useAnalyticsContext()
  return (
    <FilterSection
      name="Users"
      selectedValue={<UserFilterValueString filterValues={filters} />}
      onToggle={toggleDropDown}
      isExpanded={isExpanded}
      {...props}
    >
      {isExpanded && <UserFilterDropDown
        filterValues={filters}
        onSetFilters={setFilters}
        isExpanded={isExpanded}
        onClickedOutside={closeDropDown}
      />}
    </FilterSection>
  )
}
