import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FlatTable, CrmHbox } from 'crm-components'
import { CrmLoadingIcon, CrmButton, CrmFullpageContainer } from '@cartrack-crm/ui'
import { useCrmToastr, CrmToastrType, CrmToastrPosition } from '@cartrack-crm/ui/src/toastrs'
import { useCrmComponent } from 'crm-utils/crm-component-util'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Columns } from 'crm-types'
const URL_TO_DETAIL = '/crm/admin/components'

const AdminListWidgetTable = props => {
  const [isLoading, setLoading] = useState(false)
  const { getComponentByFilter, onArchive } = useCrmComponent(props)
  const { components, loading, refetch } = getComponentByFilter()
  const [showToastr, hideToastr] = useCrmToastr()
  const popUpComfirmToastr = async (uuid: string) => {
    showToastr(CrmToastrType.info, 'Are you sure to archive this component', {
      onBtnPrimary: () => onConfirmArchive(uuid),
      onBtnSecondary: hideToastr,
      btnPrimaryTitle: 'Confirm',
      btnSecondaryTitle: 'Cancel',
      timeToShow: null,
      position: CrmToastrPosition.middle
    })
  }
  const onConfirmArchive = async (uuid: string) => {
    if (onArchive) {
      await onArchive(uuid)
      await refetch()
      hideToastr()
    }
  }

  const renderBooleanField = (isTrue: boolean = false) => (
    <span
      style={{
        color: isTrue ? '#57d500' : '#ff2e00',
        transition: 'all .3s ease'
      }}
    >
      &#x25cf;
    </span>
  )
  const columns: Columns = [
    {
      Header: 'Name',
      Cell: row => <Link to={`${URL_TO_DETAIL}/${row?.original?.component_uid}`}>{row?.original?.name}</Link>
    },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Is published', Cell: row => renderBooleanField(row?.original?.is_published) },
    { Header: 'Is visible in dashboards', Cell: row => renderBooleanField(row?.original?.is_visible_in_dashboards) },
    { Header: 'Code', accessor: 'code' },
    { Header: 'Scope', accessor: 'scope' },
    {
      Header: 'Action',
      Cell: row => (
        <CrmButton
          iconButton
          icon={faTrash}
          isSaving={isLoading}
          onClick={() => popUpComfirmToastr(row?.original?.component_uid)}
        />
      )
    }
  ]
  return (
    <CrmFullpageContainer hasPadding vbox>
      <div className="util-paddingMd">
        <CrmButton label="Create Widget" type="primary" linkTo={`${URL_TO_DETAIL}/new`} />
      </div>
      <CrmHbox fill>
        {loading && <CrmLoadingIcon />}
        {!loading && <FlatTable data={components} columns={columns} />}
      </CrmHbox>
    </CrmFullpageContainer>
  )
}

export default AdminListWidgetTable
