/* eslint-disable camelcase */
import React from 'react'
import { func, shape } from 'prop-types'
import { qlGetOpportunity } from 'crm-data/opportunities'
import { qlqFetchAccountOpportunities } from 'crm-data/accounts'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

// eslint-disable-next-line
const generateQuotationForOpportunityHoc = WrappedComponent => {
  class GenerateQuotationForOppInner extends React.PureComponent {
    static propTypes = {
      opportunity: shape({}),
      onCancel: func,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      opportunity: undefined,
      onCancel: undefined
    }
    constructor(props) {
      super(props)
      this.state = {
        isSaving: false
      }
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    handleGenerateQuotationForOpportunity = async (
      data,
      refetchOpportunity = false,
      quickQuotationData
    ) => {
      console.log('handleGenerateQuotationForOpportunity')
      if (!data.opportunity_uid) {
        throw new Error('No props.opportunity_uid')
      }
      const command = {
        type: 'quotation.createQuotationForOpportunity',
        aggregate_uid: data.opportunity_uid,
        aggregate_type: 'Opportunity'
      }
      command.payload = {
        address_uid: data.address_uid,
        account_person_uid: data.account_person_uid
      }
      if (quickQuotationData) {
        command.payload.quickQuotation = { ...quickQuotationData }
      }
      try {
        this.setStateAsync({ isSaving: true })
        const refetchQueries = [
          {
            query: qlqFetchAccountOpportunities,
            variables: { account_uid: this.props.opportunity.account_uid },
            partialRefetch: true
          }
        ]
        if (refetchOpportunity) {
          refetchQueries.push({
            query: qlGetOpportunity,
            variables: { opportunity_uid: data.opportunity_uid },
            partialRefetch: true
          })
        }
        // console.log('refetchQueries', refetchQueries)
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })
        this.setStateAsync({ isSaving: false })
        return res.data
      } catch (err) {
        this.setStateAsync({ isSaving: false })
        console.error('Error running command')
        throw err
      }
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          onGenerateQuotationForOpportunity={
            this.handleGenerateQuotationForOpportunity
          }
          isGeneratingQuotation={this.state.isSaving}
        />
      )
    }
  }

  const quotationWinQl = crmGenericCommandQlHoc(GenerateQuotationForOppInner)
  return quotationWinQl
}

export default generateQuotationForOpportunityHoc
