import apiCaller from '../api-utils'

export default {
  fetchProfilePermissions() {
    return apiCaller('prper_api_crm_profile_permission_list').then(
      res => res.prper_api_crm_profile_permission_list
    )
  },
  updateProfilePermission(profilePermission) {
    return apiCaller(
      'prper_api_crm_profile_permission_update',
      profilePermission
    ).then(res => res.prper_api_crm_profile_permission_update)
  },
  createProfilePermission(profilePermission) {
    return apiCaller(
      'prper_api_crm_profile_permission_create',
      profilePermission
    ).then(res => res.prper_api_crm_profile_permission_create)
  }
}
