import React from 'react'
import withClickOutSide from 'react-click-outside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { GenericDropDownFilter, GenericDropDownContainer } from '../tools'
import {
  DashboardFiltersDropDownGroup,
  DashboardFiltersDropDownGroupHeader,
  DashboardFiltersDropDownValue
} from '../styled/dropdown-group-style'
import { UsersSelect } from './user-select'
import { UserGroupSelect } from './user-group-select'

interface IUserFilterDropDown {
  filterValues?: any
  onSetFilters: Function
  isExpanded: boolean
  onClickedOutside: Function
  onFilterValueChanged: Function
}

interface IUserFilterDropDownState {
  filterValues: any
}

class UsersFilterDropdown extends React.PureComponent<IUserFilterDropDown, IUserFilterDropDownState> {
  myRef: any

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      filterValues: props.filterValues ? { ...props.filterValues } : {}
    }
  }

  componentDidUpdate() {
    this.recalculatePopupMaxWidth()
  }

  componentDidMount() {
    window.addEventListener('resize', this.recalculatePopupMaxWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recalculatePopupMaxWidth)
  }

  componentWillReceiveProps = nextProps => {
    if (JSON.stringify(nextProps.filterValues) !== JSON.stringify(this.props.filterValues)) {
      this.setState({ filterValues: { ...nextProps.filterValues } })
    }
  }

  handleClickOutside = () => {
    if (this.props.isExpanded) {
      this.saveValue()
      setTimeout(this.props.onClickedOutside, 5)
    }
  }

  handleClickApply = () => {
    this.handleClickOutside()
  }

  handleClickCancel = () => {
    setTimeout(this.props.onClickedOutside, 5)
  }

  saveValue = () => {
    this.props.onSetFilters(this.state.filterValues)
  }

  handleFilterValueChanged = field => newValue => {
    const newUserFilter = this.state.filterValues.user ? { ...this.state.filterValues.user } : {}

    if (field === 'resource_pool_uid') {
      newUserFilter.resource_pool = newValue
        ? {
            resource_pool_uid: {
              value: newValue.value,
              label: newValue.label
            }
          }
        : undefined
    } else {
      newUserFilter[field] = newValue
        ? {
            value: newValue.value,
            label: newValue.label
          }
        : undefined
    }

    const newFilterValues = { ...this.state.filterValues, user: newUserFilter }

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleFilterOrganizationUnitChanged = field => newValue => {
    const newFilterValues = { ...this.state.filterValues }
    const orgUnitFilter = {
      organization_unit_uid: newValue
        ? {
            value: newValue.value,
            label: newValue.label,
            item: newValue.item
          }
        : undefined
    }
    newFilterValues[field] = {
      ...orgUnitFilter,
      value: orgUnitFilter
    }

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilters = () => {
    const newFilterValues = { ...this.state.filterValues }
    newFilterValues.department_uid = undefined
    newFilterValues.resource_pool_uid = undefined
    newFilterValues.user_uid = undefined
    newFilterValues.organization_unit_uid = undefined
    newFilterValues.organization_unit = undefined
    newFilterValues.user = undefined

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilter = filterName => {
    const newFilterValues = { ...this.state.filterValues }

    if (filterName === 'organization_unit_uid') {
      delete newFilterValues.user.organization_unit_uid
    } else if (filterName === 'user_uid') {
      newFilterValues.user.user_uid = undefined
    } else if (filterName === 'resource_pool_uid') {
      newFilterValues.user.resource_pool = undefined
    }

    this.setState({
      filterValues: newFilterValues
    })
  }

  recalculatePopupMaxWidth = () => {
    if (this.myRef && this.myRef.current) {
      const left = this.myRef.current.getBoundingClientRect().left
      this.myRef.current.style.maxWidth = `calc(100vw - ${left + 20}px)`
    }
  }

  render() {
    return (
      <GenericDropDownContainer ref={this.myRef}>
        <GenericDropDownFilter
          size={160}
          label="Users Group"
          value={this.state.filterValues?.user?.resource_pool?.resource_pool_uid?.label ?? 'All'}
          onClearFilter={() => this.handleClearFilter('resource_pool_uid')}
        >
          <UserGroupSelect
            onFilterValueChanged={this.handleFilterValueChanged('resource_pool_uid')}
            filterValues={this.state.filterValues}
          />
        </GenericDropDownFilter>
        <GenericDropDownFilter
          size={190}
          label="User"
          value={this.state.filterValues?.user?.user_uid?.label ?? 'All'}
          onClearFilter={() => this.handleClearFilter('user_uid')}
        >
          <UsersSelect
            onFilterValueChanged={this.handleFilterValueChanged('user_uid')}
            filterValues={this.state.filterValues}
          />
        </GenericDropDownFilter>
        <DashboardFiltersDropDownGroup>
          <DashboardFiltersDropDownGroupHeader
            className="util-textCenter"
            style={{ width: 120 }}
            onClick={this.handleClearFilters}
          >
            <div className="util-flexRowRight">
              <div>
                <DashboardFiltersDropDownValue>
                  <FontAwesomeIcon icon={faFilter} />
                </DashboardFiltersDropDownValue>
                <div>Clear Filter</div>
              </div>
            </div>
          </DashboardFiltersDropDownGroupHeader>

          <div className="util-flexGrow" />
          <div className="util-flexRowRight">
            <DashboardFiltersDropDownValue
              className="util-marginBottom util-marginRight util-textBold util-textApply"
              onClick={this.handleClickApply}
            >
              Apply
            </DashboardFiltersDropDownValue>
            <DashboardFiltersDropDownValue
              className="util-marginBottom util-marginLeft util-marginRight util-textBold util-textApply"
              onClick={this.handleClickCancel}
            >
              Cancel
            </DashboardFiltersDropDownValue>
          </div>
        </DashboardFiltersDropDownGroup>
      </GenericDropDownContainer>
    )
  }
}

export default withClickOutSide(UsersFilterDropdown)
