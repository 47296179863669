import gql from 'graphql-tag'
import { qlContactDetailsAllFields } from './accounts'

export const qlqGetContactDetails = gql`
query contact_detail($contact_details_uid: String!) {
  contact_detail(contact_details_uid: $contact_details_uid) {
    ${qlContactDetailsAllFields}  
  }
  }
`
export const mock = 1
