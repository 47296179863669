import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedTime } from 'react-intl'

export const columnsCampaign = {
  type: 'Campaign',
  name: 'Campaign',
  description: 'Campaign',
  fields: [
    {
      name: 'name',
      description: 'Name',
      column_label: 'Name',
      column_label_skip_except_root: true,
      type: 'string',
      Cell: (row) => {
        console.log('Campaign render', row)
        return row.original ? row.original.name : undefined
      }
    },
    {
      name: 'code',
      type: 'string',
      description: 'Code'
    },
    {
      name: 'description',
      type: 'string',
      description: 'Description'
    },
    {
      name: 'lead_source',
      description: 'Lead Source',
      type: 'LeadSource'
    },
    {
      name: 'marketing_campaign',
      description: 'Sales Campaign',
      type: 'SalesCampaign'
    },
    {
      name: 'transaction_type_code',
      type: 'string',
      description: 'Transaction Type'
    },
    {
      name: 'auto_launch_sales_process',
      description: 'Auto Launch Sales'
    }
  ]
}

export const columnsSalesCampaign = {
  type: 'SalesCampaign',
  name: 'SalesCampaign',
  description: 'SalesCampaign',
  fields: [
    {
      name: 'name',
      description: 'Name',
      Cell: (row) => {
        console.log('SalesCampaign render', row)
        return row.original ? row.original.name : undefined
      }
    },
    {
      name: 'code',
      type: 'string',
      description: 'Code'
    },
    {
      name: 'description',
      type: 'string',
      description: 'Description'
    },
    {
      name: 'lead_source',
      description: 'Lead Source',
      type: 'LeadSource'
    },
    {
      name: 'transaction_type_code',
      type: 'string',
      description: 'Transaction Type'
    },
    {
      name: 'auto_launch_sales_process',
      type: 'string',
      description: 'Auto Launch Sales'
    }
  ]
}

export const columnsLeadSource = {
  type: 'LeadSource',
  name: 'LeadSource',
  description: 'LeadSource',
  fields: [
    {
      name: 'name',
      type: 'string',
      description: 'Name'
    },
    {
      name: 'code',
      type: 'string',
      description: 'Code'
    },
    {
      name: 'kind',
      type: 'string',
      description: 'Kind'
    },
    {
      name: 'description',
      type: 'string',
      description: 'Description'
    }
  ]
}
