import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import crmGenericSelectQLHocFactory from 'crm-components/ql/crm-generic-select-ql-hoc.jsx'

const listProductTerritoryQuery = gql`
  query product_territory_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    product_territory_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        product_uid
        name
      }
    }
  }
`

function getOptions(listProductTerritoryQuery) {
  // console.log('listProductTerritoryQuery', listProductTerritoryQuery)
  if (
    listProductTerritoryQuery &&
    listProductTerritoryQuery.product_territory_list_qm_paged
  ) {
    // console.log('listProductTerritoryQuery', listProductTerritoryQuery)
    return listProductTerritoryQuery.product_territory_list_qm_paged.data
      .map(item => ({
        name: item.product_uid,
        key: item.product_uid,
        value: item.product_uid
      }))
      .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
  }

  return []
}

const query = graphql(listProductTerritoryQuery, {
  options: ownProps => {
    const filterQuery = { limit: 200, offset: 0 }
    if (ownProps.limit) {
      filterQuery.limit = ownProps.limit
    }

    if (ownProps.instanceUid) {
      filterQuery.filter = {}
      filterQuery.filter.instance_uid = ownProps.instanceUid
    }
    return {
      variables: filterQuery
    }
  },
  props: ({ listProductTerritoryQuery, ownProps }) => ({
    ...ownProps,
    listProductTerritoryQuery,
    prefix: 'product_territory_list_qm_paged',
    optionsQl: listProductTerritoryQuery,
    options:
      listProductTerritoryQuery &&
      listProductTerritoryQuery.product_territory_list_qm_paged
        ? listProductTerritoryQuery.product_territory_list_qm_paged.data
            .map(item => ({
              ...item,
              name: item.name,
              key: item.product_uid,
              value: item.product_uid
            }))
            .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
        : undefined,
    count:
      listProductTerritoryQuery &&
      listProductTerritoryQuery.product_territory_list_qm_paged
        ? listProductTerritoryQuery.product_territory_list_qm_paged.count
        : undefined,
    isLoading: listProductTerritoryQuery
      ? listProductTerritoryQuery.loading
      : false
  }),
  name: 'listProductTerritoryQuery'
})

const CrmProductsTerritorySelect = crmGenericSelectQLHocFactory(compose(query))

CrmProductsTerritorySelect.propTypes = {}

export default CrmProductsTerritorySelect
