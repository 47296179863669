import React, { useState } from 'react'
import { CrmModal, CrmTextArea } from '@cartrack-crm/ui'
import { useCrmToastr, CrmToastrType, CrmToastrPosition } from '@cartrack-crm/ui/src/toastrs'

interface IModalEditFiltersSection {
  data?: any
  onSave?: (data: any) => any,
  isOpen?: boolean
  onClose?: () => any
  sectionName?: 'filter'
}
export const ModalEditFiltersSection: React.FC<IModalEditFiltersSection> = (props) => {
  const [filters, SetFilters] = useState(JSON.stringify(props.data?.test_filters ?? {}))
  const [showToastr] = useCrmToastr()
  const onSave = () => {
    let pData = { ...props.data }
    try {
      const pFilters = JSON.parse(filters)
      pData.test_filters = { ...pFilters }
      console.log('ModalEditFiltersSection.PreSave', pData)
      if (props.onSave) props.onSave(pData)
      props?.onClose()
    } catch (err) {
      showToastr(CrmToastrType.error, 'Validate Error', {
        message: err.message,
        timeToShow: 4000,
        position: CrmToastrPosition.topRight
      })
    }
  }
  return (
    <CrmModal
      showFooter
      primaryButtonLabel='Save'
      secondaryButtonLabel='Cancel'
      onPrimaryButton={onSave}
      onSecondaryButton={props?.onClose}
      isOpen={props?.isOpen}
      onClose={props?.onClose}
      size='lg'
    >
      <div className='util-paddingMd' >
        <CrmTextArea
          rows={25}
          placeholder='filters'
          input={{
            value: filters,
            onChange: (e) => SetFilters(e?.target?.value)
          }} />
      </div>
    </CrmModal>)
} 