/* eslint-disable */
import React from 'react'
import { arrayOf, shape, func } from 'prop-types'
import { CrmFullpageContainer, CrmDropdown } from 'crm-components'
import AccountsStatsAnalyticsWidgetGroup from '../widgets18/accounts/accounts-stats-analytics-widget-group.jsx'
import DigitalLeadsWaitingForReactionTable from '../widgets18/digital/digital-leads-waiting-for-reaction-widget.jsx'

class DigitalMonitoringDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleDashboardEvent = event => {
    console.log('handleDashboardEvent', event)
    if (event === 'accountStatusSelected') {
      this.setState({ ...this.state, selectedAccountStatus })
    }
  }

  render() {
    const filters = {
      accountStatus: this.state.selectedAccountStatus
    }
    return (
      <div>
        <div className="util-marginTop">
          <div className="row">
            <div className="col-md-8">
              <DigitalLeadsWaitingForReactionTable
                title="Digital Leads Received Today"
                height={300}
              />
            </div>
            <div className="col-md-8">Stats here</div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {/* <DigitalLeadsShortListWidget
                title="Validated prospects Today"
                height={250}
              /> */}
            </div>
            <div className="col-md-6">
              {/* <DigitalLeadsShortListWidget title="Bogus Today" height={250} /> */}
            </div>
          </div>
          <code>{JSON.stringify(this.props.analyticsContext)}</code>
        </div>
      </div>
    )
  }
}

DigitalMonitoringDashboard.propTypes = {
  analyticsContext: shape().isRequired
}

DigitalMonitoringDashboard.defaultProps = {
  dashboards: []
}

export default DigitalMonitoringDashboard
