import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useCrmDynamicTableContext, DynamicTableContextType } from '../../dynamic-table-context'
import { CrmTable, CrmLoadingIcon } from '@cartrack-crm/ui'
import { buildGQLQuery } from '../../data/dynamic-ql-query-utils'
import { withApollo } from 'react-apollo'
import { useAnalyticsContext } from '../../../context/context'
import gql from 'graphql-tag'
import { useCrmContext, withCrmContext, useBulkOperationsContext } from '@cartrack-crm/crm-core'
import { useComponentConfigurationContext } from '../../../configuration-tools/component-configuration-context'
import { generateTableColumns } from '../../../shared/table-utils/table-utils'
import { exportDataToExcel } from '@cartrack-crm/crm-analytics'

interface TableInnerProps {
  pageInfo?: any
  data?: any
  isLoading?: boolean
  pageSize?: number
  columns?: Array<any>
  tableName?: string
  onFetchData?: Function
  defaultSorted?: any
  getTdProps?: Function
  onRowClick?: Function
  getTrProps?: Function
  listState?: any
  onDataLoaded?: Function
  translation?: any
  tableRef?: any
}

const TableInner = (props: TableInnerProps) => {
  const { pageInfo, data } = props
  const isLoading = props.isLoading
  let totalPages = pageInfo && props.pageInfo ? Math.ceil(pageInfo.count / props.pageSize) : 1

  const getTrProps = (state, rowInfo) => {
    let ret: any = {}

    if (props.getTrProps) {
      const outer = props.getTrProps(state, rowInfo)
      ret = { ...ret, ...outer }
    }

    if (props.onRowClick) {
      ret.onClick = () => props.onRowClick(rowInfo)
    }

    return ret
  }

  const getTdProps = (state, rowInfo, column, instance) => {
    let ret = {}

    if (props.getTdProps) {
      const outer = props.getTdProps(state, rowInfo, column, instance)
      ret = { ...ret, ...outer }
    }

    return ret
  }

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      {isLoading && (
        <div className="CrmWidget--loading">
          <CrmLoadingIcon />
          <div>Loading...</div>
        </div>
      )}
      {data && (
        <CrmTable
          tableRef={props.tableRef}
          style={{ height: '100%' }}
          columns={props.columns}
          tableName={props.tableName}
          translation={props.translation}
          className="react-table -striped -highlight"
          data={data}
          getTrProps={getTrProps}
          getTdProps={getTdProps}
          manual
          pages={totalPages}
          onFetchData={props.onFetchData}
          defaultSorted={props.defaultSorted}
        />
      )}
    </div>
  )
}

interface TableOuterProps {
  bulkOperationElementType?: string
  onShowBulkOperations?: Function
  getRefreshFunc?: Function
  onDataLoaded?: Function
  exportFileName?: any
  client: any
  columns: Array<any>
  style?: any
  hasBulkOperationsPermission: boolean
  hasExcelExportPermission: boolean
  columnOptions?: any
  rootQLType?: any
  qlOptions?: any
  queryRoot?: any
  useEdges?: boolean
  defaultQueryFields?: any
}

const TableOuter: React.SFC<TableOuterProps> = props => {
  const [listState, setListState] = useState({ page: 0, pageSize: 20 })
  const [pageInfo, setPageInfo] = useState({})
  const [currentPageData, setCurrentPageData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const tableRef = useRef<any>(React.createRef())
  const bulkOperationsContext = useBulkOperationsContext()
  const dynamicTableContext: DynamicTableContextType = useCrmDynamicTableContext()
  const componentConfigurationContext = useComponentConfigurationContext()
  const analyticsContext: any = useAnalyticsContext()
  const component = componentConfigurationContext.component
  const layoutItem =
    component?.content?.layout?.items && component?.content?.layout?.items.length == 1
      ? component?.content?.layout?.items[0]
      : undefined

  if (!layoutItem) {
    return <div> Can't find layout </div>
  }

  const crmContext = useCrmContext()

  const handleRefresh = useCallback(() => {
    handleFetchData(listState)
  }, [analyticsContext.filters, tableRef.current])

  const manualRefresh = () => {
    if (tableRef && tableRef.current && tableRef.current.fireFetchData) {
      tableRef.current.fireFetchData()
    }
  }

  const registerCallbacks = () => {
    dynamicTableContext.setRefreshHandler(manualRefresh)
    dynamicTableContext.setExcelExportHandler(handleExportExcel)
    dynamicTableContext.setActionHandlers({
      exportExcel: handleExportExcel,
      refresh: manualRefresh,
      bulkOperations: handleBulkOperation
    })
  }

  useEffect(() => {
    registerCallbacks()
    handleRefresh()
  }, [])

  useEffect(registerCallbacks, [currentPageData])

  useEffect(() => {
    manualRefresh()
  }, [componentConfigurationContext.component])

  useEffect(() => {
    dynamicTableContext.setRefreshHandler(manualRefresh)
    handleRefresh()
  }, [analyticsContext.filters])

  useEffect(() => {
    dynamicTableContext.setRefreshHandler(manualRefresh)
    handleRefresh()
  }, [dynamicTableContext.customColumns])

  const handleBulkOperation = () => {
    const accounts = currentPageData
      ? currentPageData.map(v => {
          return {
            account_uid: v.account_uid,
            account_name: v.account ? v.account.name : v.name,
            kind: ''
          }
        })
      : []
    const params = {
      elementType: props.bulkOperationElementType
    }
    bulkOperationsContext.onShowBulkOperations(accounts, params)
  }

  const handleFetchData = async listState => {
    setIsLoading(true)

    const result = await runQuery({}, listState)

    setIsLoading(false)
    setListState({ ...listState })
    setCurrentPageData(result.edges)
    setPageInfo(result.pageInfo)

    if (result.pageInfo) {
      dynamicTableContext.setResultCount(result.pageInfo.count)
    }

    return result
  }

  const getExportFileName = () => {
    let exportFileName = 'Crm-export'

    if (typeof props.exportFileName === 'string') {
      exportFileName = props.exportFileName
    } else if (typeof props.exportFileName === 'function') {
      exportFileName = props.exportFileName()
    }

    return exportFileName
  }

  const runQuery = async (variables, listState, isExport = false) => {
    variables.offset = listState.page ? listState.page * listState.pageSize : 0
    variables.limit = listState.pageSize || 20

    if (listState.sorted && listState.sorted.length > 0) {
      variables.sort = listState.sorted
    }

    const options = props.qlOptions.options ? props.qlOptions.options(props) : { variables: {} }
    const dynamicQuery = buildGQLQuery(layoutItem.props.columns, props.defaultQueryFields, props.queryRoot)
    const finalVariables = {
      ...variables,
      ...options.variables,
      master_uid: crmContext.master.master_uid,
      instance_uid: crmContext.instance.instance_uid
    }
    const variableFilter = options.variables?.filter ?? {}
    var apiFilters = { ...analyticsContext.filters }
    finalVariables.filter = apiFilters ? { ...apiFilters, ...variableFilter } : {}
    const result = await props.client.query({
      query: gql(dynamicQuery.gqlString),
      variables: finalVariables,
      context: {
        headers: isExport
          ? {
              'x-crm-log-request': true
            }
          : {}
      },
      fetchPolicy: 'no-cache'
    })

    let data = result.data.master.instance[props.queryRoot].edges
    const pageInfo = result.data.master.instance[props.queryRoot].pageInfo

    if (props.useEdges) {
      data = data.map(row => row.edge)
    }

    return {
      edges: data,
      pageInfo
    }
  }

  const handleExportExcel = async () => {
    const parsed: any = await runQuery({}, { pageSize: 10000 }, true)

    if (parsed && parsed.edges) {
      const fileName = getExportFileName()
      exportDataToExcel({
        data: parsed.edges,
        fileName
      })
    }
  }

  const mergedColumns = generateTableColumns(layoutItem.props.columns, {})

  return (
    <TableInner
      {...props}
      tableRef={tableRef}
      data={currentPageData}
      pageInfo={pageInfo}
      listState={listState}
      onFetchData={handleFetchData}
      pageSize={listState ? listState.pageSize : 20}
      columns={mergedColumns}
      isLoading={isLoading}
    />
  )
}

export default withApollo(withCrmContext(TableOuter))
