const countries = {
  AF: {
    code: '93',
    country: 'Afghanistan',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  AX: {
    code: '358',
    country: 'Åland Islands',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  AL: {
    code: '355',
    country: 'Albania',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  DZ: {
    code: '213',
    country: 'Algeria',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  AS: {
    code: '1684',
    country: 'American Samoa',
    Format: 'NNNNN (optionally NNNNN-NNNN or NNNNN-NNNNNN)',
    Regex: '^\\d{5}(-{1}\\d{4,6})$'
  },
  AD: {
    code: '376',
    country: 'Andorra',
    Format: 'CCNNN',
    Regex: '^[Aa][Dd]\\d{3}$'
  },
  AO: {
    code: '244',
    country: 'Angola',
    Format: '- no codes -',
    Regex: ''
  },
  AI: {
    code: '1264',
    country: 'Anguilla',
    Format: 'AI-2640',
    Regex: '^[Aa][I][-][2][6][4][0]$'
  },
  AQ: {
    code: '672',
    country: 'Antarctica',
    Format: '-no codes-',
    Regex: ''
  },
  AG: {
    code: '1268',
    country: 'Antigua and Barbuda',
    Format: '- no codes -',
    Regex: ''
  },
  AR: {
    code: '54',
    country: 'Argentina',
    Format: '1974-1998 NNNN; From 1999 ANNNNAAA',
    Regex: '^\\d{4}|[A-Za-z]\\d{4}[a-zA-Z]{3}$'
  },
  AM: {
    code: '374',
    country: 'Armenia',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  AW: {
    code: '297',
    country: 'Aruba',
    Format: '- no codes -',
    Regex: ''
  },
  AU: {
    code: '61',
    country: 'Australia',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  AT: {
    code: '43',
    country: 'Austria',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  AZ: {
    code: '94',
    country: 'Azerbaijan',
    Format: 'CCNNNN',
    Regex: '^[Aa][Zz]\\d{4}$'
  },
  BS: {
    code: '1242',
    country: 'Bahamas',
    Format: '- no codes -',
    Regex: ''
  },
  BH: {
    code: '973',
    country: 'Bahrain',
    Format: 'NNN or NNNN',
    Regex: '^\\d{3,4}$'
  },
  BD: {
    code: '880',
    country: 'Bangladesh',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  BB: {
    code: '1246',
    country: 'Barbados',
    Format: 'CCNNNNN',
    Regex: '^[Aa][Zz]\\d{5}$'
  },
  BY: {
    code: '375',
    country: 'Belarus',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  BE: {
    code: '32',
    country: 'Belgium',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  BZ: {
    code: '501',
    country: 'Belize',
    Format: '- no codes -',
    Regex: ''
  },
  BJ: {
    code: '229',
    country: 'Benin',
    Format: '- no codes -',
    Regex: ''
  },
  BM: {
    code: '1-441',
    country: 'Bermuda',
    Format: 'AA NN or AA AA',
    Regex: '^[A-Za-z]{2}\\s([A-Za-z]{2}|\\d{2})$'
  },
  BT: {
    code: '975',
    country: 'Bhutan',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  BO: {
    code: '591',
    country: 'Bolivia',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  BA: {
    code: '387',
    country: 'Bosnia and Herzegovina',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  BW: {
    code: '267',
    country: 'Botswana',
    Format: '- no codes -',
    Regex: ''
  },
  BV: {
    code: '47',
    country: 'Bouvet Island',
    Format: '-no codes-',
    Regex: ''
  },
  BR: {
    code: '55',
    country: 'Brazil',
    Format: 'NNNNN-NNN (NNNNN from 1971 to 1992)',
    Regex: '^\\d{5}-\\d{3}$'
  },
  IO: {
    code: '246',
    country: 'British Indian Ocean Territory',
    Format: 'AAAANAA one code: BBND 1ZZ',
    Regex: '^[Bb]{2}[Nn][Dd]\\s{0,1}[1][Zz]{2}$'
  },
  BN: {
    code: '673',
    country: 'Brunei Darussalam',
    Format: 'AANNNN',
    Regex: '^[A-Za-z]{2}\\d{4}$'
  },
  BG: {
    code: '359',
    country: 'Bulgaria',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  BF: {
    code: '226',
    country: 'Burkina Faso',
    Format: '- no codes -',
    Regex: ''
  },
  BI: {
    code: '257',
    country: 'Burundi',
    Format: '- no codes -',
    Regex: ''
  },
  KH: {
    code: '855',
    country: 'Cambodia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  CM: {
    code: '237',
    country: 'Cameroon',
    Format: '- no codes -',
    Regex: ''
  },
  CA: {
    code: '1',
    country: 'Canada',
    Format: 'ANA NAN',
    Regex: '^[^d,f,i,o,q,u,w,z]d[^d,f,i,o,q,u,w,z]s?d[^d,f,i,o,q,u,w,z]d$/i'
  },
  CV: {
    code: '238',
    country: 'Cape Verde',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  KY: {
    code: '1345',
    country: 'Cayman Islands',
    Format: 'CCN-NNNN',
    Regex: '^[Kk][Yy]\\d[-\\s]{0,1}\\d{4}$'
  },
  CF: {
    code: '236',
    country: 'Central African Republic',
    Format: '- no codes -',
    Regex: ''
  },
  TD: {
    code: '235',
    country: 'Chad',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  CL: {
    code: '56',
    country: 'Chile',
    Format: 'NNNNNNN (NNN-NNNN)',
    Regex: '^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$'
  },
  CN: {
    code: '86',
    country: 'China',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  CX: {
    code: '61',
    country: 'Christmas Island',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  CC: {
    code: '61',
    country: 'Cocos (Keeling) Islands',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  CO: {
    code: '57',
    country: 'Colombia',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  KM: {
    code: '269',
    country: 'Comoros',
    Format: '- no codes -',
    Regex: ''
  },
  CG: {
    code: '242',
    country: 'Congo',
    Format: '- no codes -',
    Regex: ''
  },
  CD: {
    code: '243',
    country: 'Congo, Democratic Republic',
    Format: '- no codes -',
    Regex: '^[Cc][Dd]$'
  },
  CK: {
    code: '682',
    country: 'Cook Islands',
    Format: '- no codes -',
    Regex: ''
  },
  CR: {
    code: '506',
    country: 'Costa Rica',
    Format: 'NNNNN (NNNN until 2007)',
    Regex: '^\\d{4,5}$'
  },
  CI: {
    code: '225',
    country: 'Cote D"Ivoire',
    Format: '- no codes -',
    Regex: ''
  },
  HR: {
    code: '385',
    country: 'Croatia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  CU: {
    code: '53',
    country: 'Cuba',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  CY: {
    code: '357',
    country: 'Cyprus',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  CZ: {
    code: '420',
    country: 'Czech Republic',
    Format: 'NNNNN (NNN NN)',
    Regex: '^\\d{3}\\s?\\d{2}$'
  },
  DK: {
    code: '45',
    country: 'Denmark',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  DJ: {
    code: '253',
    country: 'Djibouti',
    Format: '- no codes -',
    Regex: ''
  },
  DM: {
    code: '1767',
    country: 'Dominica',
    Format: '- no codes -',
    Regex: ''
  },
  DO: {
    code: '1809',
    country: 'Dominican Republic',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  EC: {
    code: '593',
    country: 'Ecuador',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  EG: {
    code: '20',
    country: 'Egypt',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  SV: {
    code: '503',
    country: 'El Salvador',
    Format: '1101',
    Regex: '^1101$'
  },
  GQ: {
    code: '240',
    country: 'Equatorial Guinea',
    Format: '- no codes -',
    Regex: ''
  },
  ER: {
    code: '291',
    country: 'Eritrea',
    Format: '- no codes -',
    Regex: ''
  },
  EE: {
    code: '372',
    country: 'Estonia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  ET: {
    code: '251',
    country: 'Ethiopia',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  FK: {
    code: '500',
    country: 'Falkland Islands (Malvinas)',
    Format: 'AAAANAA one code: FIQQ 1ZZ',
    Regex: '^[Ff][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$'
  },
  FO: {
    code: '298',
    country: 'Faroe Islands',
    Format: 'NNN',
    Regex: '^\\d{3}$'
  },
  FJ: {
    code: '679',
    country: 'Fiji',
    Format: '- no codes -',
    Regex: ''
  },
  FI: {
    code: '358',
    country: 'Finland',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  FR: {
    code: '33',
    country: 'France',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  GF: {
    code: '594',
    country: 'French Guiana',
    Format: '973NN',
    Regex: '^973\\d{2}$'
  },
  PF: {
    code: '689',
    country: 'French Polynesia',
    Format: '987NN',
    Regex: '^987\\d{2}$'
  },
  TF: {
    code: '262',
    country: 'French Southern Territories',
    Format: '- no codes -',
    Regex: ''
  },
  GA: {
    code: '241',
    country: 'Gabon',
    Format: 'NN [city name] NN',
    Regex: '^\\d{2}\\s[a-zA-Z-_ ]\\s\\d{2}$'
  },
  GM: {
    code: '220',
    country: 'Gambia',
    Format: '- no codes -',
    Regex: ''
  },
  GE: {
    code: '995',
    country: 'Georgia',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  DE: {
    code: '49',
    country: 'Germany',
    Format: 'NN',
    Regex: '^\\d{2}$'
  },
  GH: {
    code: '233',
    country: 'Ghana',
    Format: '- no codes -',
    Regex: ''
  },
  GI: {
    code: '350',
    country: 'Gibraltar',
    Format: 'GX11 1AA',
    Regex: '^[Gg][Xx][1]{2}\\s{0,1}[1][Aa]{2}$'
  },
  GR: {
    code: '30',
    country: 'Greece',
    Format: 'NNN NN',
    Regex: '^\\d{3}\\s{0,1}\\d{2}$'
  },
  GL: {
    code: '299',
    country: 'Greenland',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  GD: {
    code: '1-473',
    country: 'Grenada',
    Format: '- no codes -',
    Regex: ''
  },
  GP: {
    code: '590',
    country: 'Guadeloupe',
    Format: '971NN',
    Regex: '^971\\d{2}$'
  },
  GU: {
    code: '1671',
    country: 'Guam',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  GT: {
    code: '502',
    country: 'Guatemala',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  GG: {
    code: '441481',
    country: 'Guernsey',
    Format: 'AAN NAA, AANN NAA',
    Regex: '^[A-Za-z]{2}\\d\\s{0,1}\\d[A-Za-z]{2}$'
  },
  GN: {
    code: '224',
    country: 'Guinea',
    Format: '- no codes -',
    Regex: ''
  },
  GW: {
    code: '245',
    country: 'Guinea-Bissau',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  GY: {
    code: '592',
    country: 'Guyana',
    Format: '- no codes -',
    Regex: ''
  },
  HT: {
    code: '509',
    country: 'Haiti',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  HM: {
    code: '672',
    country: 'Heard Island and Mcdonald Islands',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  VA: {
    code: '379',
    country: 'Holy See (Vatican City State)',
    Format: '120',
    Regex: '^120$'
  },
  HN: {
    code: '504',
    country: 'Honduras',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  HK: {
    code: '852',
    country: 'Hong Kong',
    Format: '- no codes -',
    Regex: ''
  },
  HU: {
    code: '36',
    country: 'Hungary',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  IS: {
    code: '354',
    country: 'Iceland',
    Format: 'NNN',
    Regex: '^\\d{3}$'
  },
  IN: {
    code: '91',
    country: 'India',
    Format: 'NNNNNN,&#10;NNN NNN',
    Regex: '^\\d{6}$'
  },
  ID: {
    code: '62',
    country: 'Indonesia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  IR: {
    code: '98',
    country: 'Iran',
    Format: 'NNNNN-NNNNN',
    Regex: '^\\d{5}-\\d{5}$'
  },
  IQ: {
    code: '964',
    country: 'Iraq',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  IE: {
    code: '353',
    country: 'Ireland',
    Format: '- no codes -',
    Regex: ''
  },
  IM: {
    code: '441624',
    country: 'Isle of Man',
    Format: 'CCN NAA, CCNN NAA',
    Regex: '^[Ii[Mm]\\d{1,2}\\s\\d\\[A-Z]{2}$'
  },
  IL: {
    code: '972',
    country: 'Israel',
    Format: 'NNNNNNN, NNNNN',
    Regex: '^\\b\\d{5}(\\d{2})?$'
  },
  IT: {
    code: '39',
    country: 'Italy',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  JM: {
    code: '1876',
    country: 'Jamaica',
    Format: 'Before suspension: CCAAANN &#10;After suspension: NN',
    Regex: '^\\d{2}$'
  },
  JP: {
    code: '81',
    country: 'Japan',
    Format: 'NNNNNNN (NNN-NNNN)',
    Regex: '^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$'
  },
  JE: {
    code: '441534',
    country: 'Jersey',
    Format: 'CCN NAA',
    Regex: '^[Jj][Ee]\\d\\s{0,1}\\d[A-Za-z]{2}$'
  },
  JO: {
    code: '962',
    country: 'Jordan',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  KZ: {
    code: '7',
    country: 'Kazakhstan',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  KE: {
    code: '254',
    country: 'Kenya',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  KI: {
    code: '686',
    country: 'Kiribati',
    Format: '- no codes -',
    Regex: ''
  },
  KP: {
    code: '850',
    country: 'Korea (North)',
    Format: '- no codes -',
    Regex: ''
  },
  KR: {
    code: '82',
    country: 'Korea (South)',
    Format: 'NNNNNN (NNN-NNN)(1988~2015)',
    Regex: '^\\d{6}\\s\\(\\d{3}-\\d{3}\\)$'
  },
  XK: {
    code: '383',
    country: 'Kosovo',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  KW: {
    code: '965',
    country: 'Kuwait',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  KG: {
    code: '996',
    country: 'Kyrgyzstan',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  LA: {
    code: '856',
    country: 'Laos',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  LV: {
    code: '371',
    country: 'Latvia',
    Format: 'LV-NNNN',
    Regex: '^[Ll][Vv][- ]{0,1}\\d{4}$'
  },
  LB: {
    code: '961',
    country: 'Lebanon',
    Format: 'NNNN NNNN',
    Regex: '^\\d{4}\\s{0,1}\\d{4}$'
  },
  LS: {
    code: '266',
    country: 'Lesotho',
    Format: 'NNN',
    Regex: '^\\d{3}$'
  },
  LR: {
    code: '231',
    country: 'Liberia',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  LY: {
    code: '218',
    country: 'Libyan Arab Jamahiriya',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  LI: {
    code: '423',
    country: 'Liechtenstein',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  LT: {
    code: '270',
    country: 'Lithuania',
    Format: 'LT-NNNNN',
    Regex: '^[Ll][Tt][- ]{0,1}\\d{5}$'
  },
  LU: {
    code: '352',
    country: 'Luxembourg',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  MO: {
    code: '853',
    country: 'Macao',
    Format: '- no codes -',
    Regex: ''
  },
  MK: {
    code: '389',
    country: 'Macedonia',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  MG: {
    code: '261',
    country: 'Madagascar',
    Format: 'NNN',
    Regex: '^\\d{3}$'
  },
  MW: {
    code: '265',
    country: 'Malawi',
    Format: '- no codes -',
    Regex: ''
  },
  MY: {
    code: '60',
    country: 'Malaysia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  MV: {
    code: '960',
    country: 'Maldives',
    Format: 'NNNN, NNNNN',
    Regex: '^\\d{4,5}$'
  },
  ML: {
    code: '223',
    country: 'Mali',
    Format: '- no codes -',
    Regex: ''
  },
  MT: {
    code: '356',
    country: 'Malta',
    Format: 'AAANNNN (AAA NNNN)',
    Regex: '^[A-Za-z]{3}\\s{0,1}\\d{4}$'
  },
  MH: {
    code: '692',
    country: 'Marshall Islands',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  MQ: {
    code: '596',
    country: 'Martinique',
    Format: '972NN',
    Regex: '^972\\d{2}$'
  },
  MR: {
    code: '222',
    country: 'Mauritania',
    Format: '- no codes -',
    Regex: ''
  },
  MU: {
    code: '230',
    country: 'Mauritius',
    Format: '- no codes -',
    Regex: ''
  },
  YT: {
    code: '262',
    country: 'Mayotte',
    Format: '976NN',
    Regex: '^976\\d{2}$'
  },
  MX: {
    code: '52',
    country: 'Mexico',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  FM: {
    code: '691',
    country: 'Micronesia',
    Format: 'NNNNN or NNNNN-NNNN',
    Regex: '^\\d{5}(-{1}\\d{4})$'
  },
  MD: {
    code: '373',
    country: 'Moldova',
    Format: 'CCNNNN (CC-NNNN)',
    Regex: '^[Mm][Dd][- ]{0,1}\\d{4}$'
  },
  MC: {
    code: '377',
    country: 'Monaco',
    Format: '980NN',
    Regex: '^980\\d{2}$'
  },
  MN: {
    code: '976',
    country: 'Mongolia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  MS: {
    code: '1664',
    country: 'Montserrat',
    Format: 'MSR 1110-1350',
    Regex: '^[Mm][Ss][Rr]\\s{0,1}\\d{4}$'
  },
  MA: {
    code: '212',
    country: 'Morocco',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  MZ: {
    code: '258',
    country: 'Mozambique',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  MM: {
    code: '95',
    country: 'Myanmar',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  NA: {
    code: '264',
    country: 'Namibia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  NR: {
    code: '674',
    country: 'Nauru',
    Format: '- no codes -',
    Regex: ''
  },
  NP: {
    code: '977',
    country: 'Nepal',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  NL: {
    code: '31',
    country: 'Netherlands',
    Format: 'NNNN AA',
    Regex: '^\\d{4}\\s{0,1}[A-Za-z]{2}$'
  },
  AN: {
    code: '599',
    country: 'Netherlands Antilles',
    Format: '-no codes-',
    Regex: ''
  },
  NC: {
    code: '687',
    country: 'New Caledonia',
    Format: '988NN',
    Regex: '^988\\d{2}$'
  },
  NZ: {
    code: '64',
    country: 'New Zealand',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  NI: {
    code: '505',
    country: 'Nicaragua',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  NE: {
    code: '227',
    country: 'Niger',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  NG: {
    code: '234',
    country: 'Nigeria',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  NU: {
    code: '683',
    country: 'Niue',
    Format: '- no codes -',
    Regex: ''
  },
  NF: {
    code: '850',
    country: 'Norfolk Island',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  MP: {
    code: '1670',
    country: 'Northern Mariana Islands',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  NO: {
    code: '47',
    country: 'Norway',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  OM: {
    code: '968',
    country: 'Oman',
    Format: 'NNN',
    Regex: '^\\d{3}$'
  },
  PK: {
    code: '92',
    country: 'Pakistan',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  PW: {
    code: '680',
    country: 'Palau',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  PS: {
    code: '970',
    country: 'Palestinian Territory, Occupied',
    Format: '-no codes-',
    Regex: ''
  },
  PA: {
    code: '507',
    country: 'Panama',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  PG: {
    code: '675',
    country: 'Papua New Guinea',
    Format: 'NNN',
    Regex: '^\\d{3}$'
  },
  PY: {
    code: '595',
    country: 'Paraguay',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  PE: {
    code: '51',
    country: 'Peru',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  PH: {
    code: '63',
    country: 'Philippines',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  PN: {
    code: '64',
    country: 'Pitcairn',
    Format: 'AAAANAA one code: PCRN 1ZZ',
    Regex: '^[Pp][Cc][Rr][Nn]\\s{0,1}[1][Zz]{2}$'
  },
  PL: {
    code: '48',
    country: 'Poland',
    Format: 'NNNNN (NN-NNN)',
    Regex: '^\\d{2}[- ]{0,1}\\d{3}$'
  },
  PT: {
    code: '351',
    country: 'Portugal',
    Format: 'NNNN',
    Regex: '^\\d{4}-\\d{3}$'
  },
  PR: {
    code: ['1787', '1-939'],
    country: 'Puerto Rico',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  QA: {
    code: '974',
    country: 'Qatar',
    Format: '- no codes -',
    Regex: ''
  },
  RE: {
    code: '262',
    country: 'Reunion',
    Format: '974NN',
    Regex: '^974\\d{2}$'
  },
  RO: {
    code: '40',
    country: 'Romania',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  RU: {
    code: '7',
    country: 'Russian Federation',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  RW: {
    code: '250',
    country: 'Rwanda',
    Format: '-no codes-',
    Regex: ''
  },
  SH: {
    code: '290',
    country: 'Saint Helena',
    Format: 'STHL 1ZZ',
    Regex: '^[Ss][Tt][Hh][Ll]\\s{0,1}[1][Zz]{2}$'
  },
  KN: {
    code: '1869',
    country: 'Saint Kitts and Nevis',
    Format: '- no codes -',
    Regex: ''
  },
  LC: {
    code: '1758',
    country: 'Saint Lucia',
    Format: '- no codes -',
    Regex: ''
  },
  PM: {
    code: '508',
    country: 'Saint Pierre and Miquelon',
    Format: '97500',
    Regex: '^97500$'
  },
  VC: {
    code: '1784',
    country: 'Saint Vincent and the Grenadines',
    Format: 'CCNNNN',
    Regex: '^[Vv][Cc]\\d{4}$'
  },
  WS: {
    code: '685',
    country: 'Samoa',
    Format: '-no codes-',
    Regex: ''
  },
  SM: {
    code: '378',
    country: 'San Marino',
    Format: '4789N',
    Regex: '^4789\\d$'
  },
  ST: {
    code: '239',
    country: 'Sao Tome and Principe',
    Format: '- no codes -',
    Regex: ''
  },
  SA: {
    code: '966',
    country: 'Saudi Arabia',
    Format: 'NNNNN for PO Boxes. NNNNN-NNNN for home delivery.',
    Regex: '^\\d{5}(-{1}\\d{4})?$'
  },
  SN: {
    code: '221',
    country: 'Senegal',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  RS: {
    code: '381',
    country: 'Serbia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  ME: {
    code: '382',
    country: 'Montenegro',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  SC: {
    code: '248',
    country: 'Seychelles',
    Format: '- no codes -',
    Regex: ''
  },
  SL: {
    code: '232',
    country: 'Sierra Leone',
    Format: '- no codes -',
    Regex: ''
  },
  SG: {
    code: '65',
    country: 'Singapore',
    Format: 'NN',
    Regex: '^\\d{6}$'
  },
  SK: {
    code: '421',
    country: 'Slovakia',
    Format: 'NNNNN (NNN NN)',
    Regex: '^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$'
  },
  SI: {
    code: '386',
    country: 'Slovenia',
    Format: 'NNNN (CC-NNNN)',
    Regex: '^([Ss][Ii][- ]{0,1}){0,1}\\d{4}$'
  },
  SB: {
    code: '677',
    country: 'Solomon Islands',
    Format: '- no codes -',
    Regex: ''
  },
  SO: {
    code: '252',
    country: 'Somalia',
    Format: '- no codes -',
    Regex: ''
  },
  ZA: {
    code: '27',
    country: 'South Africa',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  GS: {
    code: '500',
    country: 'South Georgia and the South Sandwich Islands',
    Format: 'SIQQ 1ZZ',
    Regex: '^[Ss][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$'
  },
  ES: {
    code: '34',
    country: 'Spain',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  LK: {
    code: '94',
    country: 'Sri Lanka',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  SD: {
    code: '249',
    country: 'Sudan',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  SR: {
    code: '597',
    country: 'Suriname',
    Format: '- no codes -',
    Regex: ''
  },
  SJ: {
    code: '47',
    country: 'Svalbard and Jan Mayen',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  SZ: {
    code: '268',
    country: 'Swaziland',
    Format: 'ANNN',
    Regex: '^[A-Za-z]\\d{3}$'
  },
  SE: {
    code: '46',
    country: 'Sweden',
    Format: 'NNNNN (NNN NN)',
    Regex: '^\\d{3}\\s*\\d{2}$'
  },
  CH: {
    code: '41',
    country: 'Switzerland',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  SY: {
    code: '963',
    country: 'Syrian Arab Republic',
    Format: '- no codes -',
    Regex: ''
  },
  TW: {
    code: '886',
    country: 'Taiwan, Province of China',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  TJ: {
    code: '992',
    country: 'Tajikistan',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  TZ: {
    code: '255',
    country: 'Tanzania',
    Format: '- no codes -',
    Regex: ''
  },
  TH: {
    code: '66',
    country: 'Thailand',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  TL: {
    code: '670',
    country: 'Timor-Leste',
    Format: '- no codes -',
    Regex: ''
  },
  TG: {
    code: '228',
    country: 'Togo',
    Format: '- no codes -',
    Regex: ''
  },
  TK: {
    code: '690',
    country: 'Tokelau',
    Format: '- no codes -',
    Regex: ''
  },
  TO: {
    code: '676',
    country: 'Tonga',
    Format: '- no codes -',
    Regex: ''
  },
  TT: {
    code: '1868',
    country: 'Trinidad and Tobago',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  TN: {
    code: '216',
    country: 'Tunisia',
    Format: 'NNNN',
    Regex: '^\\d{4}$'
  },
  TR: {
    code: '90',
    country: 'Turkey',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  TM: {
    code: '993',
    country: 'Turkmenistan',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  TC: {
    code: '1649',
    country: 'Turks and Caicos Islands',
    Format: 'TKCA 1ZZ',
    Regex: '^[Tt][Kk][Cc][Aa]\\s{0,1}[1][Zz]{2}$'
  },
  TV: {
    code: '688',
    country: 'Tuvalu',
    Format: '- no codes -',
    Regex: ''
  },
  UG: {
    code: '256',
    country: 'Uganda',
    Format: '- no codes -',
    Regex: ''
  },
  UA: {
    code: '380',
    country: 'Ukraine',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  AE: {
    code: '971',
    country: 'United Arab Emirates',
    Format: '- no codes -',
    Regex: ''
  },
  GB: {
    code: '44',
    country: 'United Kingdom',
    Format: 'A(A)N(A/N)NAA (A[A]N[A/N] NAA)',
    Regex: '^[A-Z]{1,2}[0-9R][0-9A-Z]?\\s*[0-9][A-Z-[CIKMOV]]{2}'
  },
  US: {
    code: '1',
    country: 'United States',
    Format: 'NNNNN (optionally NNNNN-NNNN)',
    Regex: '^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$'
  },
  UM: {
    code: '246',
    country: 'United States Minor Outlying Islands',
    Format: '-no codes-',
    Regex: ''
  },
  UY: {
    code: '598',
    country: 'Uruguay',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  UZ: {
    code: '998',
    country: 'Uzbekistan',
    Format: 'NNN NNN',
    Regex: '^\\d{3} \\d{3}$'
  },
  VU: {
    code: '678',
    country: 'Vanuatu',
    Format: '- no codes -',
    Regex: ''
  },
  VE: {
    code: '58',
    country: 'Venezuela',
    Format: 'NNNN or NNNN A',
    Regex: '^\\d{4}(\\s[a-zA-Z]{1})?$'
  },
  VN: {
    code: '84',
    country: 'Viet Nam',
    Format: 'NNNNNN',
    Regex: '^\\d{6}$'
  },
  VG: {
    code: '1844',
    country: 'Virgin Islands, British',
    Format: 'CCNNNN',
    Regex: '^[Vv][Gg]\\d{4}$'
  },
  VI: {
    code: '1340',
    country: 'Virgin Islands, U.S.',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  WF: {
    code: '681',
    country: 'Wallis and Futuna',
    Format: '986NN',
    Regex: '^986\\d{2}$'
  },
  EH: {
    code: '212',
    country: 'Western Sahara',
    Format: '-no codes-',
    Regex: ''
  },
  YE: {
    code: '967',
    country: 'Yemen',
    Format: '- no codes -',
    Regex: ''
  },
  ZM: {
    code: '260',
    country: 'Zambia',
    Format: 'NNNNN',
    Regex: '^\\d{5}$'
  },
  ZW: {
    code: '263',
    country: 'Zimbabwe',
    Format: '- no codes -',
    Regex: ''
  }
}

export default countries
