import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useCrmContext } from 'crm-core/contexts/crm-context'

const VariableList = () => {
  const context = useCrmContext()
  const { data, loading } = useQuery(variableQL, {
    variables: {
      master_uid: context.master.master_uid,
      instance_uid: context.instance.instance_uid
    }
  })

  if (loading) {
    return {
      loading,
      variables: []
    }
  }
  return {
    loading,
    variables: data.master.instance.context_variables
  }
}

const variableQL = gql`
  query master($master_uid: String!, $instance_uid: String!) {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        context_variables {
          variable
          value
          description
        }
      }
    }
  }
`
export default VariableList
