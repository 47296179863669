import React from 'react'

import classNames from 'classnames'
import { useCrmDynamicTableContext } from '../dynamic-table-context'
import { DynamicTableViewMode } from '../dynamic-table-consts'
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable'
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons/faSolarPanel'
import { CrmButton } from '@cartrack-crm/ui'

const ViewModeSelector = props => {
  const dynamicTableContext: any = useCrmDynamicTableContext()

  const renderViewButton = props => {
    return (
      <CrmButton
        icon={props.icon}
        title={props.label}
        iconButton
        className={classNames({
          'CrmButton--selected': dynamicTableContext.viewMode === props.code
        })}
        grouped
        noBorder
        onClick={() => {
          dynamicTableContext.setViewMode(props.code)
        }}
      />
    )
  }

  return (
    <div className={`util-flexRowRight ${props.styles}`}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'center'
        }}
      >
        {renderViewButton({
          label: 'Table View',
          icon: faTable,
          code: DynamicTableViewMode.TABLE
        })}
        {renderViewButton({
          label: 'Dashboards View',
          icon: faSolarPanel,
          code: DynamicTableViewMode.DASHBOARD
        })}
      </div>
    </div>
  )
}

export default ViewModeSelector
