import React from 'react'
import moment from 'moment'
import { string, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { hhmmss } from 'crm-utils/common-utils'

class LiveDurationCallTime extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      durationSecTime: 0
    }
  }

  updateDurationTime = () => {
    let endTime = moment()
    if (this.props.endTime) {
      endTime = moment(this.props.endTime)
    }

    const startTime = moment(this.props.startTime)
    const duration = endTime.diff(startTime, 'seconds')
    this.setState({ durationSecTime: duration })
  }

  componentWillMount() {
    this.setState({ durationSecTime: 0 })
  }

  componentDidMount() {
    setInterval(this.updateDurationTime, 1000)
  }

  render() {
    if (!this.props.startTime) return false

    return (
      <div>
        {this.props.showTitle && (
          <span>
            <FormattedMessage
              id="crm.ui.activity.form.duration"
              defaultMessage="Duration  "
            />
          </span>
        )}

        <div>
          <strong>
            {this.state.durationSecTime
              ? hhmmss(this.state.durationSecTime)
              : null}
          </strong>
        </div>
      </div>
    )
  }
}

LiveDurationCallTime.propTypes = {
  startTime: string,
  endTime: string,
  showTitle: bool
}

LiveDurationCallTime.defaultProps = {
  startTime: moment(),
  endTime: undefined,
  showTitle: true
}

export default LiveDurationCallTime
