/* eslint-disable camelcase */
import React from 'react'
import { bool, func, shape } from 'prop-types'
import { Link } from 'react-router-dom'

import { FormattedDate, FormattedTime } from 'react-intl'
import { mapContextToFilter } from '../dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { CrmModal } from 'crm-components'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'

const columns = [
  {
    Header: 'Account Name',
    Cell: row => (
      <Link to={'/crm/account/' + row.original.account_uid} target="blank">
        {row.original.account__name}
      </Link>
    ),
    accessor: 'account__name'
  },
  // {
  //   Header: 'Account Type',
  //   accessor: 'account__sales_step_code'
  // },
  {
    Header: 'Current Step Name',
    accessor: 'process_step_type_name'
  },
  {
    Header: 'Current Step Type',
    accessor: 'process_step_type_kind'
  },
  {
    Header: 'Owner User',
    accessor: 'owner_user__full_name'
  },
  {
    Header: 'Lead Campaign',
    accessor: 'account__lead_campaign__name'
  },
  {
    Header: 'Sales Campaign',
    accessor: 'sales_campaign__name'
  },
  {
    Header: 'Lead In Date',
    accessor: 'lead_in_date',
    Cell: row =>
      row.original.account__lead_in_date ? (
        <span>
          <FormattedDate value={row.original.account__lead_in_date} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Last Activity Date',
    accessor: 'account__last_activity_date',
    Cell: row =>
      row.original.account__last_activity_date ? (
        <span>
          <FormattedDate value={row.original.account__last_activity_date} />{' '}
          <FormattedTime value={row.original.account__last_activity_date} />
        </span>
      ) : (
        <span />
      )
  }
]
const qlOptions = {
  options: ownProps => {
    console.log('qlOtions, ', ownProps)
    const props = { analyticsContext: { filters: ownProps.filters } }
    const filter = { ...ownProps.filters, ...mapContextToFilter(props) }

    return {
      variables: {
        filter,
        fields: [
          'account_uid',
          'account__lead_in_date',
          'account__lead_campaign__name',
          'account__last_activity_date',
          'account__name',
          'process_uid',
          'account__sales_step_code',
          'owner_user__full_name',
          'campaign__name',
          'lead_in_date',
          'process_step_type_name',
          'process_step_type_code',
          'sales_campaign__name'
        ],
        rootType: 'LatestCampaignProcessQM'
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions
})

class AccountsDetailsPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    console.log('render AccountsDetailsPopup', this.props)
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Account Latest process result"
        onClose={this.props.onClose}
        size="xl"
      >
        <Table
          columns={columns}
          style={{ width: '100%' }}
          filters={this.props.filters}
        />
      </CrmModal>
    )
  }
}

AccountsDetailsPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: shape({})
}
AccountsDetailsPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

export default AccountsDetailsPopup
