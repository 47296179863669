export const LIST_OF_ACCOUNT_TAGS = [
  {
    value: 'HIGHT_POTENTIAL',
    code: 'HIGHT_POTENTIAL',
    name: 'High Potential',
    icon: 'star'
  },
  {
    value: 'TELE_TO_RM',
    code: 'TELE_TO_RM',
    name: 'Tele to RM',
    icon: 'headset'
  },
  { value: 'HOT_LEAD', code: 'HOT_LEAD', name: 'Hot lead', icon: 'fire' },
  { value: 'SALE_SUPPORT', code: 'SALE_SUPPORT', name: 'Sales Support', icon: 'phone' },
  { value: 'WATCH', code: 'WATCH', name: 'Watch', icon: 'eye' },
  { value: 'DEBTOR', code: 'DEBTOR', name: 'Debtor', icon: 'money-bill-alt' },
  {
    value: 'HIGH_RISK',
    code: 'HIGH_RISK',
    name: 'High risk',
    icon: 'exclamation'
  },
  { value: 'HAPPY', code: 'HAPPY', name: 'Happy', icon: 'smile-beam' },
  { value: 'DLT', code: 'DLT', name: 'DLT', icon: 'truck-moving' },
  { value: 'BATTERY_NO_PROBLEM', code: 'BATTERY_NO_PROBLEM', name: 'Battery-No Problem', icon: 'battery-full' },
  { value: 'LOW_POWER', code: 'LOW_POWER', name: 'Low Power', icon: 'battery-quarter' }
]
