import React from 'react'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip
} from 'recharts'
import { arrayOf, shape } from 'prop-types'
// import IconBar from '../../../../../modules/dashboard/components/icon-bar.jsx'
const IconBar = <div>ICON BAR</div>

const WidgetTopValues = ({ data }) => {
  console.log('WidgetTopValues')
  console.dir(data)
  return data ? (
    <ResponsiveContainer width="90%" height="90%">
      <BarChart data={data} layout="vertical">
        <XAxis type="number" hide padding={{ right: 30 }} />
        <YAxis type="category" dataKey="label" hide />
        <Tooltip labelStyle={{ textTransform: 'capitalize' }} />
        <Bar
          dataKey="value"
          // shape={<IconBar label="label" />}
          isAnimationActive={false}
          barSize={50}
        />
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <div>no values</div>
  )
}

// WidgetTopValues.propTypes = {
//   data: arrayOf(shape({})).isRequired
// }
export default WidgetTopValues
