import React, { SFC } from 'react'
import styled from 'styled-components'
import NoteActivity from './activity-content-note'
import LiveCallActivity from './activity-content-live-call'
import EmailActivity from './activity-content-email'
import MeetingActivity from './activity-content-meeting'

const ActivityContentContainer = styled.div``

export interface ActivityContentProps {
  activity_status: {
    code: string
    kind: string
    name: string
    description: string
    is_success: boolean
  }
  activity_type: {
    medium_code?: string
    code?: string
    name?: string
    description?: string
    module?: string
  }
  attachments?: [any]
  call_log?: {
    destination_number?: string
    path?: string
    call_uid?: string
    current_status?: string
  }
  call_start_time?: string
  call_end_time?: string
  created_by_user: {
    full_name: string
  }
  created_time: string
  contact_details?: any
  completed_time: string
  direction?: string
  disposition_type: {
    name: string
  }
  disposition_details: {
    callback_time: string
  }
  has_permission?: boolean
  location_type?: string
  meta: any
  note_text?: string
  participants?: [any]
  primary_performer: {
    user_uid: string
    user: {
      user_uid: string
      full_name: string
      email: string
    }
  }
  seq_same_type_success_user: number
  start_time: string
  subject?: string
  isOwner?: boolean
  account?: any
  address_uid?: string
  _addrFormat?: any
}

const ActivityContent: SFC<ActivityContentProps> = (props) => {
  console.log('ActivityContentActivityContent', props)
  switch (props.activity_type.medium_code) {
    case 'note': {
      return (
        <NoteActivity
          {...props}
          onClick={() => console.log('OPEN FULL NOTE')}
        />
      )
    }
    case 'phone_call':
      return <LiveCallActivity {...props} />
    case 'email':
      return <EmailActivity {...props} />
    case 'meeting':
      return <MeetingActivity {...props} />
  }
  return (
    <ActivityContentContainer>
      {props.disposition_type.name}
    </ActivityContentContainer>
  )
}

export default ActivityContent
