/* eslint-disable camelcase */
export const mapDocumentToServer = document =>
  document
    ? {
        account_uid: document.account_uid,
        created_date: document.created_date,
        document_type_uid: document.document_type_uid,
        date_signed: document.date_signed,
        date_presented: document.date_presented,
        opportunity_uid: document.opportunity_uid,
        name: document.name,
        items: document.items
          ? document.items.map(item => ({
              document_uid: item.document_uid,
              document_item_uid: item.document_item_uid,
              product_uid: item.product_uid,
              quantity: Number(item.quantity),
              subscription_net: Number(item.subscription_net),
              value_gross: Number(item.value_gross),
              value_net: Number(item.value_net),
              value_currency_code: item.value_currency_code,
              is_deleted: item.is_deleted
            }))
          : undefined
      }
    : undefined

export const mock = 1
