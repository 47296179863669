import gql from 'graphql-tag'

export const qlqResourcePoolsList = gql`
  query users_groups($filter: JSON, $limit: Int, $offset: Int, $sort: [JSON]) {
    users_groups(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      resource_pool_uid
      name
      pool_type
      is_deleted
      organization_unit_uid
      department_type_uid
      department_type {
        name
      }
      organization_unit {
        organization_unit_uid
        name
      }
    }
  }
`

export const qlqUserGroupsPages = gql`
  query users_groups_page($filter: JSON, $limit: Int, $offset: Int, $sort: [JSON]) {
    users_groups_page(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      data {
        resource_pool_uid
        name
        pool_type
        is_deleted
        organization_unit_uid
        department_type_uid
        department_type {
          name
        }
        organization_unit {
          organization_unit_uid
          name
        }
      }
      count
    }
  }
`

export const qlqResourcePoolItemsList = gql`
  query resource_pool_items_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $resource_pool_uid: String
  ) {
    resource_pool_items_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      resource_pool_uid: $resource_pool_uid
    ) {
      data {
        resource_pool_uid
        resource_pool_item_uid
        user_uid
        user {
          login
          full_name
        }
        details
      }
      count
    }
  }
`

export const mock = 1
