import React, { FC, useState } from 'react'
import {
  DashboardFiltersDropDownGroup,
  DashboardFiltersDropDownGroupHeader,
  DashboardFiltersDropDownClearFilter,
  DashboardFiltersDropDownGroupLabel,
  DashboardFiltersDropDownValue,
  DashboardFiltersDropDownOptionsWrapper
} from '../styled/dropdown-group-style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface IGenericDropDownFilter {
  onClearFilter?: Function
  label?: string
  value?: string
  size?: number
}
export const GenericDropDownFilter: FC<IGenericDropDownFilter> = (props) => {
  const [isHover, setIsHover] = useState(false)
  const showClose = () => setIsHover(true)
  const hideClose = () => setIsHover(false)
  const handleClickClearFilter = () => {
    if (props.onClearFilter) props.onClearFilter()
  }
  return (
    <DashboardFiltersDropDownGroup>
      <DashboardFiltersDropDownGroupHeader style={{ width: props.size ?? 140 }} onMouseEnter={showClose} onMouseLeave={hideClose}>
        <DashboardFiltersDropDownClearFilter onClick={handleClickClearFilter} isHover={isHover}>
          <FontAwesomeIcon icon={faTimes} />
        </DashboardFiltersDropDownClearFilter>
        <DashboardFiltersDropDownGroupLabel>
          {props.label ?? 'undefined label'}
        </DashboardFiltersDropDownGroupLabel>
        <DashboardFiltersDropDownValue>
          {props.value ?? 'All'}
        </DashboardFiltersDropDownValue>
      </DashboardFiltersDropDownGroupHeader>
      <DashboardFiltersDropDownOptionsWrapper>
        {props.children}
      </DashboardFiltersDropDownOptionsWrapper>
    </DashboardFiltersDropDownGroup>
  )
}