const rmLeadsResults = {
  type: 'item',
  label: 'Agent Leads Results B2C',
  code: 'agentLeadsResultsB2C',
  dashboard_uid: 'agentLeadsResultsB2C',
  defaultFilters: {
    account__lead_campaign__transaction_type_code: {
      value: 'B2C',
      label: 'B2C'
    },
    organization_unit: {
      department_type_uid: {
        value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
        label: 'Telesales'
      }
    }
  },
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'agentLeadsResults B2C',
        def_version: 5,
        title: 'Agent Leads Results B2C',
        enable_excel_export: true,
        description_: 'Only users in departments Telesales and transaction type B2C',
        content: {
          data: {
            dataSources: [
              {
                code: 'AccountsListQM',
                name: 'AccountsListQM',
                type: 'QM',
                filter: {},
                aggregate: [{ id: 'account_uid', type: 'count' }],
                contextMapping: {
                  latest_owner_user_uid: 'user_uid',
                  latest_owner__department_uid: 'department_uid',
                  last_activity_date: 'activity_date'
                },
                transposeColumns: ['sales_result_code'],
                transposeKey: 'latest_owner_user_uid',
                transposeValueField: 'account_uid',
                qlQuery: `query accountsc($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                    accountsc(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                      edges { edge {  latest_owner_user_uid sales_result_code account_uid latest_owner { uid name type } } } } }`,
                qlVariables: {
                  groupBy: ['latest_owner_user_uid', 'sales_result_code'],
                  limit: 10
                },
                qlEdges: true,
                qlRoot: 'accountsc'
              },
              {
                code: 'AccountsListQM',
                alias: 'AccountsDetailed',
                type: 'QM',
                filter: {
                  latest_owner_user_uid: { $ne: 'null' }
                },
                aggregate: [{ id: 'account_uid', type: 'count' }],
                contextMapping: {
                  latest_owner_user_uid: 'user_uid',
                  latest_owner: { department_uid: 'department_uid' },
                  last_activity_date: 'activity_date'
                },
                transposeColumns: ['detailed_status_code'],
                transposeKey: 'latest_owner_user_uid',
                transposeValueField: 'account_uid',
                qlQuery: `query accountsc($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                accountsc(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                  edges { edge {  latest_owner_user_uid detailed_status_code account_uid } } } }`,
                qlVariables: {
                  groupBy: ['detailed_status_code', 'latest_owner_user_uid'],
                  limit: 10
                },
                qlEdges: true,
                qlRoot: 'accountsc'
              },
              {
                code: 'AccountsListQM',
                alias: 'OwnedLeads',
                type: 'QM',
                fields: ['owner_user_uid'],
                aggregate: [{ id: 'account_uid', type: 'count' }],
                contextMapping: {
                  last_activity_date: 'activity_date',
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  }
                },
                filter: {
                  owner_type: 'user',
                  kind: { $ne: 'customer' }
                },
                qlQuery: `query accountsc($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                accountsc(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                  edges { edge {  owner_user_uid account_uid } } } }`,
                qlVariables: {
                  groupBy: ['owner_user_uid'],
                  limit: 10
                },
                qlEdges: true,
                qlRoot: 'accountsc'
              },
              {
                code: 'AccountsListQM',
                alias: 'AccountsWon',
                type: 'QM',
                fields: ['won_by_user_uid'],
                aggregate: [{ id: 'account_uid', type: 'count' }],
                contextMapping: {
                  won_by_user_uid: 'user',
                  won_by_user: {
                    department_uid: 'department_uid'
                  },
                  last_activity_date: 'activity_date'
                },
                filter: {
                  won_by_user_uid: { $ne: 'null' },
                  filter_won_by_user_uid: true
                },
                qlQuery: `query accountsc($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                accountsc(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                  edges { edge {  won_by_user_uid account_uid } } } }`,
                qlVariables: {
                  groupBy: ['won_by_user_uid'],
                  limit: 10
                },
                qlEdges: true,
                qlRoot: 'accountsc'
              },
              {
                code: 'CrmUser',
                name: 'CrmUser',
                type: 'QM',
                qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $limit: Int) {
                  master(master_uid: $master_uid)  { 
                      master_uid
                      name
                      instance(instance_uid: $instance_uid) { 
                          instance_uid
                          name
                          users (filter: $filter, limit: $limit) { 
                            user_uid
                            is_active
                            full_name
                            contract_start_date
                            contract_end_date
                            department { 
                              name 
                            }
                          }
                      }
                  }
                }`,
                filter: {
                  get_all_instance_uid: true
                },
                qlVariables: {
                  limit: 3000
                },
                qlRoot: 'users'
              }
            ],
            combineDataSources: {
              name: 'CombinedDataSource',
              targetKeyField: 'user_uid',
              mapping: [
                {
                  fromDataSourceName: 'AccountsListQM',
                  keyField: 'latest_owner_user_uid',
                  mapping: [
                    {
                      from: 'account_uid',
                      to: 'accounts_all'
                    },
                    {
                      from: 'new',
                      to: 'accounts_new'
                    },
                    {
                      from: 'pending',
                      to: 'accounts_pending'
                    },
                    {
                      from: 'lost',
                      to: 'accounts_lost'
                    },
                    {
                      from: 'invalid',
                      to: 'accounts_invalid'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'AccountsDetailed',
                  keyField: 'latest_owner_user_uid',
                  mapping: [
                    {
                      from: 'new',
                      to: 'accounts_new',
                      type: 'number'
                    },
                    {
                      from: 'quoted',
                      to: 'accounts_quoted',
                      type: 'number'
                    },
                    {
                      from: 'contacted',
                      to: 'accounts_contacted',
                      type: 'number'
                    },
                    {
                      from: 'touched',
                      to: 'accounts_touched',
                      type: 'number'
                    },
                    {
                      from: 'validated',
                      to: 'accounts_pending',
                      type: 'number'
                    },
                    {
                      from: 'needs_analysis',
                      to: 'accounts_pending',
                      type: 'number'
                    },
                    {
                      from: 'opportunity',
                      to: 'accounts_pending',
                      type: 'number'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'AccountsWon',
                  keyField: 'won_by_user_uid',
                  mapping: [
                    {
                      from: 'account_uid',
                      to: 'accounts_won'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'CrmUser',
                  keyField: 'user_uid',
                  type: 'left',
                  mapping: [
                    {
                      from: 'full_name'
                    },
                    {
                      from: 'contract_start_date'
                    },
                    { from: 'contract_end_date' },
                    { from: 'department__name' },
                    { from: 'is_active' }
                  ]
                }
              ]
            }
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                props: {
                  generate_summary: true,
                  generate_average: true,
                  initialSort: {
                    id: 'contract_start_date',
                    order: 'asc'
                  },
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 50
                    },
                    {
                      Header: 'RM',
                      accessor: 'full_name',
                      classNameHandler: row => (row.original.is_active ? '' : 'util-opacity50'),
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user_uid: 'user_uid'
                      }
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'contract_start_date',
                      width: 70
                    },
                    {
                      Header: 'Unanswered',
                      accessor: 'accounts_touched',
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'account',
                      detailsFilter: {
                        detailed_status_code: 'touched',
                        latest_owner_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        last_activity_date: 'activity_date'
                      }
                    },
                    {
                      Header: 'Answered',
                      accessor: 'accounts_contacted',
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'account',
                      detailsFilter: {
                        detailed_status_code: 'contacted',
                        latest_owner_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        last_activity_date: 'activity_date'
                      }
                    },

                    {
                      Header: 'Quoted',
                      accessor: 'accounts_quoted',
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'account',
                      detailsFilter: {
                        detailed_status_code: 'quoted'
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        last_activity_date: 'activity_date'
                      }
                    },
                    {
                      Header: 'Bogus',
                      accessor: 'accounts_invalid',
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'account',
                      detailsFilter: {
                        sales_result_code: 'invalid',
                        latest_owner_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        last_activity_date: 'activity_date'
                      }
                    },
                    {
                      Header: 'Lost',
                      accessor: 'accounts_lost',
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'account',
                      detailsFilter: {
                        sales_result_code: 'lost',
                        latest_owner_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'user_uid'
                      },
                      contextMapping: {
                        last_activity_date: 'activity_date'
                      }
                    },
                    {
                      Header: 'Won',
                      accessor: 'accounts_won',
                      className: 'util-textRight util-pointer',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'account',
                      detailsFilter: {
                        won_by_user_uid: { $ne: 'null' }
                      },
                      detailsFilterMapping: {
                        won_by_user_uid: 'user_uid',
                        account__owner__department_uid: 'department_uid'
                      },
                      contextMapping: {
                        last_activity_date: 'activity_date'
                      }
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'CombinedDataSource'
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}

export default rmLeadsResults
