export const columnsIndustry = {
  type: 'Industry',
  fields: [
    {
      name: 'name',
      description: 'Industry Name',
      Cell: row => (row.original && row.original.name ? row.original.name : '-')
    }
  ]
}
