import React, { Component } from 'react'
import { string, arrayOf, number, shape } from 'prop-types'

import { ConnectedDropdown } from 'util-components/dropdown.jsx'
import { utcTimestampDateRange } from 'util-functions/time-utils'

class DateRangeInput extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount() {
    this.handleChange(this.props[this.props.valueFieldName].input.value)
  }

  handleChange(value) {
    const { start, end } = utcTimestampDateRange(new Date(), -Number(value))

    this.props[this.props.startFieldName].input.onChange(start)
    this.props[this.props.endFieldName].input.onChange(end)
    this.props[this.props.valueFieldName].input.onChange(value)
  }

  render() {
    const options = this.props.options.map(dayCount => ({
      name: `Last ${dayCount} Days`,
      value: String(dayCount)
    }))

    return (
      <ConnectedDropdown
        extraClassNames={this.props.extraClassNames}
        options={options}
        title={this.props.title}
        input={{
          value: this.props[this.props.valueFieldName].input.value,
          onChange: this.handleChange
        }}
      />
    )
  }
}

DateRangeInput.propTypes = {
  title: string.isRequired,
  startFieldName: string.isRequired,
  endFieldName: string.isRequired,
  extraClassNames: shape({
    containerClassNames: string,
    titleClassNames: string,
    selectClassNames: string
  }),
  options: arrayOf(number).isRequired,
  valueFieldName: string.isRequired
}

DateRangeInput.defaultProps = {
  extraClassNames: {
    containerClassNames: '',
    titleClassNames: '',
    selectClassNames: ''
  }
}

export default DateRangeInput
