/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { func, bool, shape } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl'
import changeAccountTransactionTypeHoc from 'crm-modules/accounts/hoc/change-account-transaction-type-hoc.jsx'

class ChangeAccountTransactionTypeWidget extends React.PureComponent {
  handleSave = async event => {
    const val = event.target.id
    // console.log('Save val', val, this.props)
    if (this.props.bulkMode) {
      // console.log('handleSaveOwnerChange bulkNode')
      return
    }
    try {
      const res = await this.props.onSaveNewTransactionType(val)
      // console.log('Type saved', res, this.props)
      toastr.success('Transaction type changed')
      this.props.onCancel()
    } catch (err) {
      // console.log('Error saving')
      toastr.error('Error saving transaction type')
    }
  }
  componentDidMount() {
    // console.log('Change proces owner widget did mount', this.props)
    if (this.props.onBulkComponentInit) {
      this.props.onBulkComponentInit(this)
    }
  }

  render() {
    console.log('render ChangeAccountTransactionTypeWidget')
    if (!this.props.isEdited) {
      return (
        <div onClick={this.props.onStartEdit}>
          {this.props.account ? this.props.account.category : ''}
        </div>
      )
    }
    return (
      <div
        style={{
          backgroundColor: '#ce5239',
          color: 'white',
          padding: '0 5px',
          borderRadius: 3
        }}
      >
        {this.props.isSaving && <div>Saving... </div>}
        {!this.props.isSaving && (
          <div
            style={{ fontSize: 12, textAlign: 'left', paddingRight: 5 }}
            className="util-flexRow"
          >
            <div style={{ paddingRight: 5 }}>
              <FormattedMessage
                id="crm.ui.label.click_transaction_type"
                defaultMessage="Transaction Type"
              />
            </div>
            <div className="util-flexRow">
              {' '}
              <div
                className="util-flexCenterContent
              util-hooverable util-pointer"
              >
                <div
                  style={{
                    backgroundColor: '#5cae60',
                    color: 'white',
                    padding: '2px 4px'
                  }}
                  id="B2B"
                  onClick={this.handleSave}
                >
                  B2B
                </div>
              </div>
              <div
                style={{ padding: '2px 4px' }}
                className="util-flexCenterContent  util-hooverable  util-pointer"
              >
                <div
                  style={{ backgroundColor: '#063358', color: 'white' }}
                  id="B2C"
                  onClick={this.handleSave}
                >
                  B2C
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

ChangeAccountTransactionTypeWidget.propTypes = {
  onCancel: func,
  isSaving: bool.isRequired,
  bulkMode: bool,
  onBulkComponentInit: func,
  account: shape().isRequired,
  onSaveNewTransactionType: func.isRequired,
  isEdited: bool.isRequired,
  onStartEdit: func.isRequired
}

ChangeAccountTransactionTypeWidget.defaultProps = {
  userUid: undefined,
  usersGroup: undefined,
  bulkMode: false,
  onBulkComponentInit: undefined,
  onCancel: () => {}
}

export default changeAccountTransactionTypeHoc(
  connect(null, dispatch => ({
    doPush: url => dispatch(push(url))
  }))(ChangeAccountTransactionTypeWidget)
)
