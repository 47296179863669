import React from 'react'

import widgetDefinition from 'crm-modules/dashboard/hoc/ct-dashboards/rm-details/rm-meeting-activity-widget'
import { GenericWidgetWeb } from '@cartrack-crm/crm-analytics'

class RmMeetingActivity extends React.PureComponent {
  render() {
    const filters = {}

    return (
      <GenericWidgetWeb
        {...this.props}
        widgetDefinition={widgetDefinition}
        key={widgetDefinition.code}
        analyticsContext={this.props.analyticsContext}
        filters={filters}
        onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
        noHeight
        noFilterBar
      />
    )
  }
}

export default RmMeetingActivity
