const salesPortugal = [
  { name: 'MARCO LIMA', sales: 218, arpu: 9.83, payback: 13.21, month: 2 },
  { name: 'JANETE MARQUES', sales: 152, arpu: 10.47, payback: 15.09, month: 2 },
  { name: 'NUNO REIS', sales: 102, arpu: 11.67, payback: 13.13, month: 2 },
  { name: 'Bruno Martinho', sales: 68, arpu: 12.16, payback: 13.81, month: 2 },
  { name: 'JOÃO MÁRIO DIAS', sales: 35, arpu: 11.06, payback: 16.09, month: 2 },
  { name: 'Hugo Barbosa', sales: 30, arpu: 10.53, payback: 18.95, month: 2 },
  { name: 'ELISABETE MACIEL', sales: 29, arpu: 9.35, payback: 15.6, month: 2 },
  { name: 'RAQUEL MONTES', sales: 20, arpu: 12.97, payback: 15.6, month: 2 },
  { name: 'RICARDO PEREIRA', sales: 18, arpu: 7.73, payback: 17.68, month: 2 },
  { name: 'ADRIANO NUNES', sales: 13, arpu: 9.77, payback: 19.9, month: 2 },
  { name: 'Nuno Lousada', sales: 12, arpu: 7.14, payback: 24.44, month: 2 },
  { name: 'MIGUEL SOUSA', sales: 7, arpu: 13.43, payback: 30.02, month: 2 },
  { name: 'VASCO RIJO', sales: 3, arpu: 10.61, payback: 73.1, month: 2 },
  { name: 'Maria Ladiera', sales: 2, arpu: 12, payback: 88.06, month: 2 },
  { name: 'Olena', sales: 2, arpu: 9.98, payback: 165.37, month: 2 }
]

export default salesPortugal
