import { form } from 'util-components'
import React from 'react'
import { func, bool, string } from 'prop-types'

export const loginFormKey = 'crmLoginForm'

const LoginForm = props => {
  const fields = {
    username: {
      type: 'text',
      placeholder: 'username',
      extraClassNames: {
        containerClassNames: 'Login-textInput util-marginTop'
      },
      default_value: props.defaultValues.username
    },
    password: {
      type: 'password',
      placeholder: 'password',
      extraClassNames: {
        containerClassNames: 'Login-textInput util-marginTop'
      },
      default_value: props.defaultValues.password
    },
    company_code: {
      type: 'text',
      placeholder: 'Company',
      extraClassNames: {
        containerClassNames: props.enableChangeCompany
          ? 'Login-textInput util-marginTop'
          : 'hide'
      },
      default_value: props.defaultValues.company_code
    }
  }
  const LoginFormInner = form(
    loginFormKey,
    {
      ...fields
    },
    { submitOnEnter: true }
  )

  return <LoginFormInner onSubmit={props.onSubmit} />
}

LoginForm.propTypes = {
  onSubmit: func.isRequired,
  enableChangeCompany: bool.isRequired,
  defaultValues: string.isRequired
}

LoginForm.defaultProps = {}

export default LoginForm
