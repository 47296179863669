import React from 'react'
import { number, oneOfType, bool, string } from 'prop-types'
import { injectIntl } from 'react-intl'

export function formatDistance(intl, value, units, round) {
  const unit = intl.messages['units.distance']
  const unitShort = intl.messages['units.distance.short']
  return (
    intl.formatNumber(
      (value * intl.messages.kmConvert).toFixed(round ? 0 : 1)
    ) +
    (typeof units === 'string' ? ' ' + unit[0] + units : units ? unitShort : '')
  )
}

const FormattedDistance = props => (
  <span>
    {formatDistance(props.intl, props.value, props.units, props.round)}
  </span>
)

FormattedDistance.propTypes = {
  value: number.isRequired,
  units: oneOfType([bool.isRequired, string.isRequired]),
  round: bool
}

FormattedDistance.defaultProps = {
  units: false,
  round: false
}

export default injectIntl(FormattedDistance)
