import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { NEW_ACCOUNT } from '../../accounts/account/components/account-page-ql-hoc'
import { AlertBadge } from 'util-components'
import { CrmButton } from '../../../components/index'

export const AddAccountButton = props => {
  const { loading, error, data } = useQuery(NEW_ACCOUNT, {
    fetchPolicy: 'cache-only'
  })
  const hasPendingNewLead = data && data.newAccount && data.newAccount.name
  return (
    <React.Fragment>
      {hasPendingNewLead && <AlertBadge count={1} />}
      <CrmButton
        className="Button--white CrmButton-iconOnly"
        onClick={props.handleAddAccount}
        style={{
          minWidth: 'initial',
          padding: 5,
          width: '40px'
        }}
        icon="plus"
      />
    </React.Fragment>
  )
}
