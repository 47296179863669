import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import moment from 'moment'
import { convertUnderlineFilterToObject } from '../dashboard/dashboard-utils'

export type OpportunityType = {
  vehicle_count?: number
  confidence_level?: number
  due_date?: string
  opportunity_uid?: string
  account?: any
  quantity?: number
  account_uid?: string
  status_code?: string
  process_uid?: string
  additional_sales?: boolean
  document?: any
}

export type forecastType = {
  status?: string
  forecast_uid?: string
  user_uid?: string
  month_date?: string
  items?: {
    account_uid?: string
    opportunity_uid?: string
    due_date?: string
    confidence_level?: string
    quantity?: number
    additional_sales?: boolean
    opportunity?: OpportunityType
  }[]
}

const FORECASTS = gql`
  query forecasts($filter: JSON, $limit: Int, $offset: Int, $sort: [JSON]) {
    forecasts(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      count
      data {
        forecast_uid
        user_uid
        instance_uid
        status
        month_date
        items {
          opportunity_uid
          confidence_level
          account_uid
          due_date
          quantity
          additional_sales
          opportunity {
            opportunity_uid
            account_uid
            confidence_level
            vehicle_count
            due_date
            status_code
            completed_date
            account {
              account_uid
              name
              lead_campaign {
                name
              }
              lead_in_time
            }
          }
        }
        user {
          full_name
        }
      }
    }
  }
`

export const FORECAST_GRAPHS = gql`
  query forecasts($filter: JSON, $limit: Int, $offset: Int, $sort: [JSON]) {
    forecasts(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      count
      data {
        forecast_uid
        user_uid
        status
        month_date
        items {
          quantity
        }
        user {
          full_name
        }
      }
    }
  }
`

export const FORECAST = gql`
  query forecast($forecastUid: String) {
    forecast(forecast_uid: $forecastUid) {
      forecast_uid
      user_uid
      instance_uid
      month_date
      status
      items {
        opportunity_uid
        confidence_level
        account_uid
        due_date
        quantity
        additional_sales
        opportunity {
          opportunity_uid
          account_uid
          confidence_level
          vehicle_count
          due_date
          status_code
          completed_date
          account {
            account_uid
            name
            lead_campaign {
              name
            }
            lead_in_time
          }
        }
      }
      user {
        full_name
      }
    }
  }
`

export const getWonSize = (opportunity): number => {
  const documents = opportunity.documents ? opportunity.documents : []
  let won = 0

  documents.map(({ process, items }) => {
    const lastStep =
      process &&
      process.current_step &&
      process.current_step.process_step_type &&
      process.current_step.process_step_type.name
        ? process.current_step.process_step_type.name
        : null

    if (lastStep === 'Won') {
      won += items.reduce((acc, { quantity, product }) => (product && !product.is_addon ? acc + quantity : acc + 0), 0)
    }
  })

  return won
}

type params = {
  monthDate?: string
  userUid?: string
}

export const useFetchListForecasts = (params: params) => {
  return useQuery(FORECASTS, {
    variables: {
      filter: {
        month_date: params.monthDate,
        user_uid: params.userUid
      }
    },
    skip: !params.monthDate,
    fetchPolicy: 'no-cache'
  })
}

export const useFetchForecast = (forecastUid: string) => {
  return useQuery(FORECAST, {
    variables: {
      forecastUid: forecastUid
    }
  })
}

export const useFetchForecastThisMonth = (userUid: string = null, monthDate: string = moment().format('YYYY-MM')) => {
  let forecastSize = 0
  let forecast: forecastType | forecastType[]
  let forecasts: forecastType[]
  const isGetAllUser = userUid === null
  const month = monthDate

  let variables = {
    filter: {
      month_date: month,
      user_uid: userUid
    }
  }

  if (userUid === null) {
    delete variables['filter']['user_uid']
  }

  const { data } = useQuery(FORECAST_GRAPHS, {
    variables,
    fetchPolicy: 'no-cache'
  })

  if (data && data.forecasts.data) {
    forecasts = [...data.forecasts.data]
    forecast = !isGetAllUser && data.forecasts.data.length === 1 ? forecasts[0] : forecasts
    const items = data.forecasts.data.map(el => el.items)

    items.map(item => {
      item.map((el, index) => {
        forecastSize += el.quantity * 1
      })
    })
  }

  return { forecast, forecastSize }
}

export const QL_LIST_OPPORTUNITIES = gql`
  query opportunities($filter: JSON) {
    opportunities(filter: $filter, limit: 500) {
      edges {
        edge {
          documents {
            process {
              current_step {
                process_step_type {
                  name
                }
              }
            }
            items {
              product {
                is_addon
              }
              quantity
            }
          }
          vehicle_count
          due_date
          status_code
          completed_date
          completed_time
          owner {
            user_uid
          }
        }
      }
    }
  }
`

const useGetWonOpportunities = (userUid: string = null, month: string = moment().format('YYYY-MM'), filter = null) => {
  const convertedFilter = filter ? convertUnderlineFilterToObject(filter) : {}
  const dueDateMoment = moment(month, 'YYYY-MM')
  let opportunities = []

  let variables = {
    filter: {
      due_date: {
        $lte: dueDateMoment.endOf('month').format('YYYY-MM-DD'),
        $gte: dueDateMoment.startOf('month').format('YYYY-MM-DD')
      },
      is_deleted: false,
      owner_user_uid: userUid,
      status_code: 'won',
      ...convertedFilter
    }
  }

  const setNewKeyForFilter = (root, key, isDate = false) => {
    if (convertedFilter.hasOwnProperty(key)) {
      variables['filter'][root] ? null : (variables['filter'][root] = {})

      if (isDate) {
        variables['filter'][root][key] = { $gte: convertedFilter[key][0], $lte: convertedFilter[key][1] }
      } else {
        variables['filter'][root][key] = convertedFilter[key]
      }

      delete variables['filter'][key]
    }
  }

  const clearFilterObjects = () => {
    setNewKeyForFilter('account', 'lead_in_date', true)

    if (convertedFilter.hasOwnProperty('user_uid')) {
      variables['filter']['owner_user_uid'] = convertedFilter['user_uid']
    }

    if (userUid === null && !convertedFilter.hasOwnProperty('user_uid')) {
      delete variables['filter']['owner_user_uid']
    }
  }

  if (userUid === null && filter === null) {
    delete variables['filter']['owner_user_uid']
    delete variables['filter']['account']
    delete variables['filter']['owner']
  } else if (filter) {
    clearFilterObjects()
  }

  const { data, refetch } = useQuery(QL_LIST_OPPORTUNITIES, {
    variables,
    fetchPolicy: 'no-cache'
  })

  if (data && data.opportunities && data.opportunities.edges) {
    opportunities = data.opportunities.edges.map(e => e.edge)
  }

  return { opportunities, refetch }
}

export const useGetOrderResults = (
  userUid: string = null,
  month: string = moment().format('YYYY-MM'),
  filter = null
) => {
  const dateObject = moment(month, 'YYYY-MM')
  const startDate = dateObject.startOf('month').format('YYYY-MM-DD')
  const endDate = dateObject.endOf('month').format('YYYY-MM-DD')

  let variables = {
    filter: {
      owner__user_uid: userUid,
      completed_date: {
        $ne: null,
        $gte: startDate,
        $lte: endDate
      },
      ...filter
    },
    fields: ['owner__user_uid', 'completed_date'],
    aggregate: [
      {
        id: 'units_fitted',
        type: 'count'
      }
    ],
    rootType: 'OrdersListQM'
  }

  if (userUid === null) {
    delete variables['filter']['owner__user_uid']
  }

  if (variables['filter']['resource_pool_uid']) {
    variables['filter']['owner__user_uid'] = { $inResourcePool: filter.resource_pool_uid }
    delete variables['filter']['resource_pool_uid']
  }

  if (variables['filter']['lead_in_date']) {
    variables['filter']['account__lead_in_date'] = { $gte: filter.lead_in_date[0], $lte: filter.lead_in_date[1] }
    delete variables['filter']['resource_pool_uid']
  }

  if (variables['filter']['activity_date']) {
    variables['filter']['completed_date']['$gte'] = filter.activity_date[0]
    variables['filter']['completed_date']['lte'] = filter.activity_date[1]
    delete variables['filter']['resource_pool_uid']
  }

  const { data, loading, refetch } = useQuery(qlqGenericDataQuery, {
    variables,
    fetchPolicy: 'no-cache'
  })

  let listFitment = []
  let totalFitment = 0

  if (data?.genericDataQuery?.data) {
    listFitment = data.genericDataQuery.data
  }

  listFitment.map(el => {
    totalFitment += el.units_fitted
  })

  return { listFitment, refetch, totalFitment }
}

export const useGetGraphDataMonthly = (
  userUid: string = null,
  month: string = moment().format('YYYY-MM'),
  filter = null
) => {
  const { forecastSize } = useFetchForecastThisMonth(userUid, month)
  const dateObject = moment(month, 'YYYY-MM')
  const daysInMonth = dateObject.daysInMonth()
  const { listFitment } = useGetOrderResults(userUid, month, filter)
  const { opportunities } = useGetWonOpportunities(userUid, month, filter)

  const setGraphDataFormat = () => {
    let results = []
    let fitments = 0
    let sales = 0
    for (let i = 1; i <= daysInMonth; i++) {
      const momentObject = moment(`${month}-${i}`, 'YYYY-MM-D')
      listFitment.map(el => {
        if (el.completed_date == momentObject.format('YYYY-MM-DD')) {
          fitments += el.units_fitted
        }
      })
      opportunities.map(el => {
        const completedDate = moment(el.completed_time).format('YYYY-MM-DD')
        if (completedDate == momentObject.format('YYYY-MM-DD')) {
          sales += getWonSize(el)
        }
      })

      if (momentObject.isAfter(moment())) {
        sales = undefined
        fitments = undefined
      }
      results.push({
        name: momentObject.format('DD MMM'),
        forecast: forecastSize,
        fitments: fitments,
        sales: sales
      })
    }

    return results
  }

  const data = setGraphDataFormat()

  return [data]
}
