/* eslint-disable camelcase */
import React, { Component } from 'react'
import { Field } from 'redux-form'
import validate from 'crm-utils/validates'
import { CrmTextInput, CrmDropdown, CrmButton } from 'crm-components'
import { SectionHeader } from 'util-components'
interface RecipientFormItemProps {
  fields: any
}
class RecipientFormItem extends Component<RecipientFormItemProps> {
  deliveryMethods: {
    name?: string
    value?: string
  }[]
  constructor(props) {
    super(props)
    this.state = {}
    this.deliveryMethods = [
      // { name: 'Line', value: 'line' },
      // { name: 'SMS', value: 'sms' },
      { name: 'Email', value: 'email' }
    ]
  }

  handleCreate = () => {
    this.props.fields.push({ delivery_method: 'email' })
  }

  handleDelete = (index) => {
    this.props.fields.remove(index)
  }

  renderTextbox = (item, data) => {
    console.log('renderTextbox', item)
    const textComponents = []
    if (data.delivery_method === 'email') {
      textComponents.push(
        <div className="col-md-3">
          <Field
            name={`${item}.name`}
            component={CrmTextInput}
            type="text"
            placeholder="Name"
            validate={[validate.required]}
          />
        </div>
      )

      textComponents.push(
        <div className="col-md-3">
          <Field
            name={`${item}.email`}
            component={CrmTextInput}
            type="text"
            placeholder="Email"
            validate={[validate.required]}
          />
        </div>
      )
    } else if (data.delivery_method === 'sms') {
      textComponents.push(
        <div className="col-md-3">
          <Field
            name={`${item}.mobile`}
            component={CrmTextInput}
            type="text"
            placeholder="Mobile"
            validate={[validate.required]}
          />
        </div>
      )
    } else if (data.delivery_method === 'line') {
      textComponents.push(
        <div className="col-md-3">
          <Field
            name={`${item}.line`}
            component={CrmTextInput}
            type="text"
            placeholder="Line ID"
            validate={[validate.required]}
          />
        </div>
      )
    }

    return textComponents
  }

  render() {
    const datas = this.props.fields.getAll()
    return (
      <React.Fragment>
        <SectionHeader
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>Recipients Info</div>
              <CrmButton
                onClick={this.handleCreate}
                label="Add Recipient"
                style={{ marginRight: 20 }}
              />
            </div>
          }
          sub
          id="recipient-info-section"
        />
        <div style={{ marginTop: '10px' }}>
          {this.props.fields.map((item, index) => (
            <div
              key={item}
              className="row CrmMdCard util-paddingSm"
              style={{
                margin: '15px 0px',
                padding: 15,
                position: 'relative'
              }}
            >
              <div className="col-md-3">
                <Field
                  name={`${item}.delivery_method`}
                  component={CrmDropdown}
                  placeholder="Delivery method"
                  options={this.deliveryMethods}
                />
              </div>
              {this.renderTextbox(item, datas[index])}
              <div style={{ position: 'absolute', right: 15 }}>
                <CrmButton
                  icon="trash"
                  onClick={() => this.handleDelete(index)}
                />
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

export default RecipientFormItem
