import React from 'react'
import { bool } from 'prop-types'
import { CrmValidatedField, CrmTextArea } from 'crm-components'

class NoteFields extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <fieldset
          disabled={!this.props.isEditing}
          className="CrmForm-form-fieldset"
        >
          <div className="row">
            <div className="col-md-12">
              <CrmValidatedField
                component={CrmTextArea}
                componentProps={{
                  placeholder: 'Note Text',
                  placeholderId: 'crm.ui.activity.note_text',
                  height: 150,
                  extraClassNames: {
                    inputClassNames: 'note-form',
                    placeholderClassNames: 'note-placeholder'
                  }
                }}
                name="note_text"
              />
            </div>
          </div>
        </fieldset>
      </div>
    )
  }
}
NoteFields.propTypes = {
  isEditing: bool.isRequired
}
export default NoteFields
