import React, { FC, useState } from 'react'
import { Schema, SchemaField } from '../data-utils/schema-types'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { useDrag } from 'react-dnd'
import { useComponentConfigurationContext } from '../configuration-tools/component-configuration-context'

export const ListItemHeader = styled.div`
  font-size: var(--crm-font-size-md);
  padding: var(--crm-padding-08);
  border-bottom: 1px solid var(--crm-color-gray20);
  label {
    cursor: pointer;
    font-weight: var(--crm-font-weight-medium);
    margin-right: var(--crm-padding-08);
    ${({ isChild }) => isChild && 'margin-left: var(--crm-padding-20);'}
  }

  i {
    float: right;
  }
  
`

export const ListItemSubItem = styled.div`
  cursor: pointer;
  display: flex;
  padding: var(--crm-padding-08) 0;
  label {
    cursor: pointer;
    font-size: var(--crm-font-size-md);
  }
  i {
    color: ${({ disabled }) => disabled ? 'var(--crm-color-green)' : 'var(--crm-color-gray30)'};
    cursor: pointer;
    font-size: var(--crm-font-size-lg);
    margin: auto var(--crm-padding-08) auto 0;
    width: 20px;
    display: inherit;
    ${({ isChild }) => isChild && 'margin-left: var(--crm-padding-20);'}
    ${({ disabled }) => !disabled && css`
    &:hover {
      color: var(--crm-color-green);
    }`}
  }

  &:hover {
    background-color: var(--crm-color-gray20);
  }

  cursor: ${({ isDragging, disabled }) => {
    if (disabled) {
      return 'default'
    } else if (isDragging) {
      return 'grab'
    } else {
      return 'pointer'
    }
  }};
`

interface IComponentListItemType {
  schema: Schema
  isChild?: boolean
  onClickField?: Function
  sub_parent?: string
}

export const ItemType = 'gqlType'

export const ComponentListItemType: FC<IComponentListItemType> = ({ schema, isChild, onClickField, sub_parent }) => {
  const [isShowSubItem, setStatusSubItem] = useState(!isChild)
  const toggleShow = () => setStatusSubItem(!isShowSubItem)
  const handleAfterClicked = (value) => {
    if (onClickField) onClickField(value)
    setStatusSubItem(false)
  }
  console.log('ComponentListItemType', schema.fields, schema)
  return (
    <React.Fragment>
      <ListItemHeader onClick={toggleShow} isChild={isChild}>
        <label title={schema?.description}>{schema?.description ?? schema?.name}</label>
        <i>
          <FontAwesomeIcon icon={isShowSubItem ? faCaretUp : faCaretDown} />
        </i>
      </ListItemHeader>
      {isShowSubItem && schema?.fields?.map((item) => (<ComponentTypeItem field={item} key={item.name} parent={sub_parent ?? schema.name} disabled={item.disabled} onClickField={handleAfterClicked} isChild={isChild} />) ?? [])}
    </React.Fragment>
  )
}
interface IComponentTypeItem {
  parent?: string
  field: SchemaField
  disabled?: boolean
  onClickField?: Function
  isChild?: boolean
}

const loadChildrenType = (field) => {
  const { schema, component } = useComponentConfigurationContext()
  const selected = new Set([])
  component?.content?.layout?.items?.map((item) => {
    item?.props?.columns?.map((column) => { selected.add(column?.data?.field) })
  })
  if (field.type !== 'json') {
    const preSchema = schema?.types?.find((item) => item.name === field.type) ?? undefined
    if (preSchema) {
      preSchema.fields = preSchema.fields?.map((item) => ({ ...item, disabled: selected.has(`${field.name}.${item.name}`) })) ?? []
    }
    return preSchema
  } else if (field.type === 'json') {
    return field
  } else {
    console.log('what the case', field)
  }
}
export const ComponentTypeItem: FC<IComponentTypeItem> = ({ field, parent, onClickField, isChild }) => {
  const allowRenderType = ['string', 'int', 'date', 'number', 'boolean']
  console.log('ComponentTypeItem.field', field)
  const [{ isDragging }, drag] = useDrag({ item: { type: ItemType, field, parent }, collect: (monitor) => ({ isDragging: !!monitor.isDragging() }) })
  const isHasChildType = !allowRenderType.includes(field?.type?.toLocaleLowerCase())
  let children: Schema
  if (isHasChildType) {
    children = loadChildrenType(field)
  }
  const title = field.disabled ? 'this field is already selected' : `add field ${field.name} to template`
  const parentKey = `${parent}.${field?.name}`
  const handleClickField = () => {
    if (onClickField) onClickField(parentKey)
  }
  return (
    <React.Fragment >
      {!isHasChildType &&
        <ListItemSubItem ref={!field?.disabled ? drag : null} isDragging={isDragging} disabled={field.disabled} isChild={isChild} onClick={handleClickField}>
          <i title={title} >
            <FontAwesomeIcon icon={faCheckCircle} />
          </i>
          <label>{field?.description ?? field?.name} </label>
        </ListItemSubItem>}
      {isHasChildType && children && <ComponentListItemType schema={children} isChild onClickField={onClickField} sub_parent={parentKey} />}
    </React.Fragment >)
}

