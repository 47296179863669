import { toastr } from 'react-redux-toastr'
import documentsApi from 'crm-api/documents-api'
import { call, put, takeLatest } from 'redux-saga/effects'
import { accountFieldsUpdated } from 'crm-duxs/account-reducer'
import { actions } from 'crm-duxs/document-reducer'

function* startDocumentEdit(action) {
  yield put({
    type: actions.setEditedDocument.type,
    payload: { document: action.payload.document }
  })
}

function* createDocument(action) {
  const document = action.payload.document
  yield call(console.log, 'createdDocument before api ', document)
  try {
    const createdDocument = yield call(documentsApi.createDocument, document)
    yield call(console.log, 'createdDocument after api ', createdDocument)
    yield put({
      type: actions.createDocumentSuccess.type,
      payload: { document: createdDocument }
    })

    yield put(accountFieldsUpdated(document.account_uid, ['documents']))
    yield put(toastr.success('Document has been saved'))
    if (action.payload.onSuccess) {
      action.payload.onSuccess()
    }
  } catch (err) {
    toastr.error('Problem creating Document')
    console.dir(err)
  }
}

function* updateDocument(action) {
  const document = { ...action.payload.document }
  try {
    const updatedDocument = yield call(documentsApi.updateDocument, document)
    yield put({
      type: actions.updateDocumentSuccess.type,
      payload: { document: updatedDocument }
    })

    yield put(accountFieldsUpdated(document.account_uid, ['documents']))
    toastr.success('Document has been updates')
    if (action.payload.onSuccess) {
      action.payload.onSuccess()
    }
  } catch (err) {
    toastr.error('Problem updating Document')
  }
}

export default function* activitiesSaga() {
  yield takeLatest(actions.startDocumentEdit.type, startDocumentEdit)
  yield takeLatest(actions.createDocument.type, createDocument)
  yield takeLatest(actions.updateDocument.type, updateDocument)
}
