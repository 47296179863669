import React from 'react'
import { shape, func } from 'prop-types'
import { COLORS } from './widget-utils'
import WidgetTopValuesInner from './_widget-top-values-inner'
import { CrmButton } from '@cartrack-crm/ui'

const TopValuesChartWidget = (props) => {
  console.log('TopValuesChartWidget render')
  console.dir(props.tableData)

  const data = props.tableData
    ? props.tableData.rows
        .slice(0, 5)
        .sort((a, b) => b.sum - a.sum)
        .map((r, i) => ({
          index: i,
          label: 'name',
          value: r.sum,
          name: r.rowKey ? r.rowKey.label : '',
          fill: COLORS[i]
        }))
    : []
  return (
    <div className="Widget CrmWidget util-marginBottom" style={props.style}>
      <div className="Widget-header row util-noMargin between-xs top-xs">
        <div className="Widget-title CrmWidget-title">
          {props.widgetDefinition.title}
        </div>
        <div className="CrmWidget-tools">
          <CrmButton
            style={{ float: 'right' }}
            icon="refresh"
            iconButton
            onClick={props.onRefresh}
          />
        </div>
      </div>
      <div
        className="Widget-content CrmWidget-content 
        CrmWidget-content200
        CrmWidget-content--padding"
      >
        <WidgetTopValuesInner data={data} />
      </div>
    </div>
  )
}

TopValuesChartWidget.propTypes = {
  tableData: shape({}).isRequired,
  widgetDefinition: shape({}).isRequired,
  style: shape({}).isRequired,
  onRefresh: func.isRequired
}

export default TopValuesChartWidget
