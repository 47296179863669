import React, { FC } from 'react'
import { DashboardFiltersDropDownGroup, DashboardFiltersDropDownGroupHeader, DashboardFiltersDropDownValue } from '../styled/dropdown-group-style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

interface IGenericClearFilter {
  onCancel?: Function,
  onApply?: Function,
  onClearFilters?: Function
}
export const GenericClearFilter: FC<IGenericClearFilter> = (props) => {
  const handleClickApply = () => {
    if (props.onApply) props.onApply()
  }
  const handleClickCancel = () => {
    if (props.onCancel) props.onCancel()
  }
  const handleClearFilters = () => {
    if (props.onClearFilters) props.onClearFilters()
  }
  return (
    <DashboardFiltersDropDownGroup>
      <DashboardFiltersDropDownGroupHeader
        className='util-textCenter'
        style={{ width: 120 }}
        onClick={handleClearFilters}
      >
        <div className="util-flexRowRight">
          <div>
            <DashboardFiltersDropDownValue>
              <FontAwesomeIcon icon={faFilter} />
            </DashboardFiltersDropDownValue>
            <div>Clear Filter</div>
          </div>
        </div>
      </DashboardFiltersDropDownGroupHeader>

      <div className="util-flexGrow" />
      <div className="util-flexRowRight">
        <DashboardFiltersDropDownValue
          className='util-marginBottom util-marginRight util-textBold util-textApply'
          onClick={handleClickApply}
        >
          Apply
          </DashboardFiltersDropDownValue>
        <DashboardFiltersDropDownValue
          className='util-marginBottom util-marginLeft util-marginRight util-textBold util-textApply'
          onClick={handleClickCancel}
        >
          Cancel
          </DashboardFiltersDropDownValue>
      </div>
    </DashboardFiltersDropDownGroup>
  )
}