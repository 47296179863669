import React, { useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import ApolloClient from 'apollo-client'
import uuidv4 from 'uuid'
import { qlmExecuteCommand } from 'crm-data/commands'

function useOpportunityCommand() {
  const [commandUid, setCommandUid] = useState < String > (uuidv4())
  const [isSaving, setIsSaving] = useState < Boolean > (false)
  const client: ApolloClient<any> = useApolloClient()

  const _afterCommandSuccess = async () => {
    setCommandUid(uuidv4())
    setIsSaving(false)
  }

  const _afterCommandFail = async () => {
    setCommandUid(uuidv4())
    setIsSaving(false)
  }

  const runOpportunityCommand = async (
    command: any,
    options: any = null
  ): Promise<any> => {
    setIsSaving(true)
    try {
      const result = await client.mutate({
        mutation: qlmExecuteCommand,
        variables: {
          command: {
            command_uid: commandUid,
            ...command
          }
        },
        refetchQueries: options ? options.refetchQueries : undefined
      })
      await _afterCommandSuccess()
      return result.data.cqCommand
    } catch (err) {
      await _afterCommandFail()
      console.log('runOpportunityCommand error', err)
    }
  }

  return { isSaving, runOpportunityCommand }
}

export const withOpportunityCommandHoc: any = WrappedComponent => {
  const WithOpportunityCommandHocInner: any = props => {
    const { isSaving, runOpportunityCommand } = useOpportunityCommand()
    return (
      <WrappedComponent
        {...props}
        isSavingOpportunityCommand={isSaving}
        onRunOpportunityCommand={runOpportunityCommand}
      />
    )
  }
  return WithOpportunityCommandHocInner
}

export default useOpportunityCommand
