import { toastr } from 'react-redux-toastr'
import userFleetAPI from 'crm-api/fleet-integration/user'
import usersAPI from 'crm-api/settings/users-api'
import {
  CHANGE_MY_PASSWORD,
  CHANGE_MY_PASSWORD_SUCCESS,
  LOAD_SIP_USER,
  RECEIVE_SIP_USER,
  CHANGE_SIP_USER,
  LOGIN_FLEET_USER,
  LOGIN_FLEET_USER_SUCCESS
} from 'crm-duxs/settings/me-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fleetLogin(action) {
  const { username, password } = action
  const [accountUsername, subUserUsername = ''] = username.split('-')
  try {
    const { user, timezones, settings } = yield call(userFleetAPI.login, accountUsername, subUserUsername, password)

    console.log('after fleet login : ', user, timezones, settings)
    yield put({
      type: LOGIN_FLEET_USER_SUCCESS,
      payload: { user }
    })

    yield put(toastr.success('Login success'))
  } catch (err) {
    toastr.error('Username or password invalid')
  }
}

function* changeMyPassword(data) {
  try {
    const user = yield call(usersAPI.changeMyPassword, data)
    yield put({
      type: CHANGE_MY_PASSWORD_SUCCESS,
      payload: { user }
    })
    window.localStorage.setItem('crmRefreshToken', user.refresh_token)

    yield put(toastr.success('New password has been changed'))
  } catch (err) {
    toastr.error('Problem change password')
  }
}

function* fetchUser(data) {
  try {
    const user = yield call(usersAPI.get, data.user_uid)
    yield put({
      type: RECEIVE_SIP_USER,
      payload: { user }
    })
  } catch (err) {
    toastr.error('Cannot get user data')
  }
}

function* updateSipUser(data) {
  try {
    yield call(usersAPI.update, data.data)
    yield put(toastr.success('Sip user has been changed'))
  } catch (err) {
    toastr.error('Cannot change sip user ')
  }
}

function* settingMeSaga() {
  yield takeLatest(CHANGE_MY_PASSWORD, changeMyPassword)
  yield takeLatest(LOAD_SIP_USER, fetchUser)
  yield takeLatest(CHANGE_SIP_USER, updateSipUser)
  yield takeLatest(LOGIN_FLEET_USER, fleetLogin)
}

export default settingMeSaga
