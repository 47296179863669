import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, bool } from 'prop-types'

import MessagesTabSendNofitication from './_messages-tab-send-notification.jsx'
import MessagesTabMessages from './_messages-tab-messages.jsx'
import CreateMessageForm from './create-message-form.jsx'
import { CrmModal, CrmHbox } from 'crm-components'
import { getUsers, actions } from 'crm-duxs/messages-reducer'
import { bindActions } from 'crm-utils/reducer-utils'

class CrmMessagesPopup extends Component {
  constructor(props) {
    super(props)
    this.state = { tab: 'messages' }
  }

  handleClickTab = event => {
    console.log('handleClickTab')
    console.log(event.currentTarget.id)
    this.setState({ ...this.state, tab: event.currentTarget.id })
  }

  render() {
    console.log('Render CrmMessages Popup')
    console.dir(this.props)
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Messages"
        onClose={this.props.onClose}
        showFooter
        primaryButtonLabel="Close"
        onPrimaryButton={this.props.onClose}
      >
        <div className="CrmMessagesPopup">
          <div className="CrmMessagesPopup-tabs">
            <div className="Navbar-innerNav">
              <div
                className={`Navbar-innerNav-tab ${
                  this.state.tab === 'messages' ? 'is-active' : ''
                }`}
                id="messages"
                onClick={this.handleClickTab}
              >
                Messages
              </div>
              <div
                className={`Navbar-innerNav-tab ${
                  this.state.tab === 'create_message' ? 'is-active' : ''
                }`}
                id="create_message"
                onClick={this.handleClickTab}
              >
                New message
              </div>
              <div
                className={`Navbar-innerNav-tab ${
                  this.state.tab === 'notifications' ? 'is-active' : ''
                }`}
                id="notifications"
                onClick={this.handleClickTab}
              >
                Notifications
              </div>
            </div>
          </div>
          <div className="CrmMessagesPopup-body util-fullHeight">
            <CrmHbox
              fullHeight
              className={`util-${
                this.state.tab === 'messages' ? 'block' : 'hide'
              }`}
            >
              <MessagesTabMessages />
            </CrmHbox>
            <CrmHbox
              fullHeight
              className={`util-${
                this.state.tab === 'notifications' ? 'block' : 'hide'
              }`}
            >
              <MessagesTabSendNofitication />
            </CrmHbox>
            <CrmHbox
              fullHeight
              className={`util-${
                this.state.tab === 'create_message' ? 'block' : 'hide'
              }`}
            >
              <CreateMessageForm />
            </CrmHbox>
          </div>
        </div>
      </CrmModal>
    )
  }
}

CrmMessagesPopup.propTypes = {
  onClose: func.isRequired,
  isOpen: bool.isRequired
}

function mapStateToProps(state) {
  return {
    users: getUsers(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActions(dispatch, actions)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmMessagesPopup)
