import React from 'react'
import { func, object, string, oneOfType } from 'prop-types'
import { FiltersOptionList } from '../tools'

const options = [
  { name: 'B2B', value: 'B2B' },
  { name: 'B2C', value: 'B2C' }
]

const TransactionTypeSelect = props => {
  return (
    <FiltersOptionList
      options={options}
      onFilterValueChanged={props.onFilterValueChanged}
      selectedValue={props.selectedValue}
    />
  )
}

TransactionTypeSelect.propTypes = {
  filterValues: object,
  onFilterValueChanged: func.isRequired,
  type: string,
  field: string,
  selectedValue: oneOfType([object, string]),
  fieldName: string
}

TransactionTypeSelect.defaultProps = {
  selectedValue: undefined
}

export default TransactionTypeSelect
