import React from 'react'
import { connect } from 'react-redux'
import { func, string, bool } from 'prop-types'
import { CrmModal, CrmButton } from 'crm-components'
import { getLocale, changeLocale } from 'crm-duxs/locale'
import LanguageSelect from 'crm-components/modules/settings/language-select.jsx'

const SelectLanguageModal = props => {
  const handleLocaleChange = nextLocale => {
    window.localStorage.setItem('locale', nextLocale)
    props.doChangeLocale(nextLocale)
    window.location.reload()
  }

  return (
    <CrmModal
      isOpen={props.isOpen}
      title="Select Language"
      showFooter={false}
      size="xs"
      isShowCloseBtn={false}
      extraClassNames={{ bodyClassNames: 'util-overflowInitial' }}
    >
      <div className="util-paddingMd">
        <div style={{ height: '100%', width: '100%', paddingBottom: '0px' }}>
          <fieldset className="Form-fieldset CrmForm-form-fieldset">
            <div className="row">
              <div className="col-xs-12 ">
                <LanguageSelect
                  onChange={handleLocaleChange}
                  activeOption={props.locale}
                />
              </div>
            </div>
            <div className="row util-marginTop util-textRight">
              <div className="col-md-12">
                <CrmButton
                  type="primary"
                  label="Close"
                  onClick={props.onClose}
                />
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </CrmModal>
  )
}

SelectLanguageModal.propTypes = {
  locale: string.isRequired,
  doChangeLocale: func.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired
}
SelectLanguageModal.defaultProps = {}

function mapStateToProps(state) {
  return {
    locale: getLocale(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangeLocale: locale => dispatch(changeLocale(locale))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLanguageModal)
