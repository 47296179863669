/* eslint-disable */

// Widget with number of fitted unit
export const widgetUnitsFitted = {
  title: 'Fitments summary',
  def_version: 5,
  data: {
    dataSources: [
      {
        code: 'OrdersListQM',
        alias: 'OrdersListQM',
        fields: [],
        aggregate: [{ id: 'units_fitted', type: 'sum' }],
        contextMapping: {
          completed_date: 'activity_date'
        }
      }
    ],
    aggregates: {
      fitments: {
        type: 'sum',
        dataSource: 'OrdersListQM',
        valueField: 'units_fitted',
        aggregateBy: 'completed_date'
      }
    }
  },
  layout: {
    items: [
      {
        type: 'labeledField',
        props: {
          value: {
            type: 'aggregates',
            dataField: 'fitments'
          }
        }
      }
    ]
  }
}

// Fitments by RM
export const topRms = {
  title: 'Top 5 RMs',
  def_version: 5,
  description: 'This month',
  data: {
    dataSources: [
      {
        code: 'OrdersListQM',
        alias: 'OrdersListQM',
        filter: {},
        fields: ['owner_user_name'],
        aggregate: [{ id: 'units_fitted', type: 'sum' }],
        order: [{ id: 'units_fitted', desc: true }],
        contextMapping: {
          completed_date: 'activity_date'
        }
      }
    ]
  },
  layout: {
    items: [
      {
        type: 'chartBars',
        def_version: 5,
        props: {
          limit: 5,
          chart: {
            labelField: 'owner_user_name',
            valueField: 'units_fitted'
          },
          data: {
            type: 'dataSource',
            dataSourceCode: 'OrdersListQM'
          }
        }
      }
    ]
  }
}

// Fitments by lead source
export const fitmentsBySource = {
  title: 'Fitments by Source',
  def_version: 5,
  description: 'Selected date range',
  data: {
    dataSources: [
      {
        code: 'OrdersListQM',
        alias: 'OrdersListQM',
        filter: {},
        fields: ['lead_source_type'],
        aggregate: [{ id: 'units_fitted', type: 'sum' }],
        order: [{ id: 'units_fitted', desc: true }],
        contextMapping: {
          completed_date: 'activity_date'
        }
      }
    ]
  },
  layout: {
    items: [
      {
        type: 'chartBars',
        def_version: 5,
        props: {
          limit: 5,
          chart: {
            labelField: 'lead_source_type',
            valueField: 'units_fitted'
          },
          data: {
            type: 'dataSource',
            dataSourceCode: 'OrdersListQM'
          }
        }
      }
    ]
  }
}

const fitmentsByRm = {
  title: 'Fitments by RM - all',
  def_version: 5,
  description: 'This month',
  data: {
    dataSources: [
      {
        code: 'OrdersListQM',
        alias: 'OrdersListQM',
        filter: {},
        fields: ['owner_user_name'],
        aggregate: [{ id: 'units_fitted', type: 'sum' }],
        order: [{ id: 'units_fitted', desc: true }],
        contextMapping: {
          completed_date: 'activity_date'
        }
      }
    ]
  },
  layout: {
    items: [
      {
        type: 'table',
        def_version: 5,
        props: {
          limit: 5,
          columns: [
            {
              header: 'RM',
              accessor: 'owner_user_name'
            },
            {
              header: 'Units',
              accessor: 'units_fitted'
            }
          ],
          data: {
            type: 'dataSource',
            dataSourceCode: 'OrdersListQM'
          }
        }
      }
    ]
  }
}

// // Cros - User / Lead Source
// const fitmentsUserSource = {
//   columns_dims: [
//     {
//       dim_code: 'lead_source_type',
//       kpi_code: 'fitment_details',
//       name: 'Lead Source'
//     }
//   ],
//   rows_dims: [
//     {
//       dim_code: 'owner_user',
//       kpi_code: 'fitment_details',
//       name: 'RM'
//     }
//   ],
//   kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
//   title: 'Fitments by RM and Lead Source',
//   filters: [
//     {
//       kpi_code: 'fitment_details',
//       dim_code: 'date_fitted',
//       default_value: 'october_2017',
//       operator: 'eq',
//       class: 'date'
//     }
//   ]
// }

const dashHome = {
  dashboard_uid: 'dashHome',
  name: 'Home',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'container',
        class: 'row util-marginBottom',
        items: [
          {
            type: 'container',
            class: 'col-md-3',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: widgetUnitsFitted
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-8',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: topRms
              }
            ]
          }
        ]
      },
      // Row 2
      {
        type: 'container',
        class: 'row util-marginBottom',
        items: [
          {
            type: 'container',
            class: 'col-md-6',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: fitmentsBySource
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-6',
            items: [{}]
          }
        ]
      },
      {
        type: 'analitics_widget',
        widgetDefinition: fitmentsByRm
      }
    ]
  }
}

export default dashHome
