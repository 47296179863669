import React from 'react'
import styled from 'styled-components'

const CrmCardWrapper = styled.div`
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 5, 10, 0.07);
    padding: 15px;

    .CrmCard-title {
      font-size: 1.1em;
      opacity: 0.85;
      text-align: left;
    }

    .CrmCard-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
    }
`

interface ICrmCard {
  title?: string,
  className?: string
}
export const CrmCard: React.SFC<ICrmCard> = (props) => {
  const renderTitle = () => {
    if (props.title)
      return <label className='CrmCard-title'> {props.title}</label>
  }
  return (
    <CrmCardWrapper className={props.className ?? ''}>
      {renderTitle()}
      {props.children}
    </CrmCardWrapper>
  )
}
