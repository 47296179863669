import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  string,
  arrayOf,
  shape,
  func,
  element,
  oneOfType,
  bool,
  any,
  number
} from 'prop-types'
import { injectIntl } from 'react-intl'
import enhanceClickOutside from 'react-click-outside'
import { Icon } from 'util-components'

class Dropdown extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  handleOpenClick = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleOptionClick = event => {
    this.setState({ isOpen: false })
    const value = event.currentTarget.id

    if (this.props.input.onChange) {
      this.props.input.onChange(value, this.props.title)
    } else {
      this.props.onChange(value, this.props.title)
    }
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }

  formatOption = opt => {
    let option
    let id
    if (opt.element) {
      option = opt.element // Option is the custom element
      id = opt.name // Id/value is the name prop
    } else {
      // Id is the value if specified, otherwise it's the name or the option itself (string)
      id = opt.value === undefined ? opt.name || opt : opt.value
      // Intl or not?
      option = this.props.disableOptionsIntl
        ? opt.name || opt
        : this.props.intl.formatMessage({
            id: `dropdown.option.${opt.name || opt}`,
            defaultMessage: opt.name || opt
          })
    }

    return (
      <div
        key={this.props.useValueAsKey ? opt.value || opt.name : opt.name || opt}
        id={id.toString()}
        className="Dropdown-option"
        onClick={this.handleOptionClick}
      >
        {option}
      </div>
    )
  }

  render() {
    const {
      disabled,
      intl,
      input,
      title,
      options,
      disableOptionsIntl,
      small,
      icon,
      required,
      openUpwards,
      extraClassNames: {
        containerClassNames = '',
        titleClassNames = '',
        selectClassNames = ''
      }
    } = this.props

    const isTitleString = typeof this.props.title === 'string'
    const activeOption = input.value || this.props.activeOption
    let label = options.find(
      x =>
        x === activeOption ||
        x.value === activeOption ||
        x.name === activeOption
    )
    if (label) {
      label = label.name || label
    }

    return (
      <div
        className={`${containerClassNames} Dropdown ${
          small ? 'Dropdown--small' : ''
        } ${this.state.isOpen ? 'is-open' : ''}${
          disabled ? ' is-disabled' : ''
        }`}
        onClick={disabled ? undefined : this.handleOpenClick}
        style={this.props.style}
      >
        {required && <div className="Dropdown-required">*</div>}
        <div className={`${titleClassNames} Dropdown-title`}>
          {isTitleString
            ? intl.formatMessage({
                id: `dropdown.${title}`,
                defaultMessage: title
              })
            : title}
        </div>
        <span className="Dropdown-activeOption">
          {activeOption !== undefined &&
          activeOption !== null &&
          !disableOptionsIntl
            ? intl.formatMessage({
                id: `dropdown.option.${label}`,
                defaultMessage: label
              })
            : label}
        </span>
        <Icon
          name={icon || (openUpwards ? 'caret-up' : 'caret-down')}
          className="Dropdown-toggle"
        />
        {this.state.isOpen && (
          <div
            className={`${selectClassNames} Dropdown-select ${
              openUpwards ? 'Dropdown-select--openUpwards' : ''
            }`}
          >
            {options.map(this.formatOption)}
          </div>
        )}
      </div>
    )
  }
}

Dropdown.propTypes = {
  title: oneOfType([string.isRequired, element.isRequired]).isRequired,
  options: oneOfType([
    arrayOf(
      oneOfType([
        string.isRequired,
        shape({
          name: string.isRequired,
          element,
          value: any
        }).isRequired
      ])
    ),
    func
  ]).isRequired,
  activeOption: oneOfType([string, number, bool]),
  input: shape({
    onChange: func,
    value: oneOfType([string, shape()])
  }),
  extraClassNames: shape({
    containerClassNames: string,
    titleClassNames: string,
    selectClassNames: string
  }),
  style: shape({}),
  onChange: func,
  disabled: bool,
  disableOptionsIntl: bool,
  small: bool,
  useValueAsKey: bool,
  icon: string,
  required: bool,
  openUpwards: bool
}

Dropdown.defaultProps = {
  onChange: () => null,
  activeOption: null,
  extraClassNames: {
    containerClassNames: '',
    titleClassNames: '',
    selectClassNames: ''
  },
  style: null,
  input: {},
  disabled: false,
  disableOptionsIntl: false,
  small: false,
  useValueAsKey: false,
  icon: null,
  required: false,
  openUpwards: false
}

function mapStateToProps(state, { options }) {
  const isFunc = typeof options === 'function'

  return {
    options: isFunc ? options(state) : options
  }
}

const injectedDropdown = injectIntl(enhanceClickOutside(Dropdown))
export default injectedDropdown
export const ConnectedDropdown = connect(mapStateToProps)(injectedDropdown)
