import gql from 'graphql-tag'

export const qlMessageFields =
  ' message_uid title body created_time from_user { full_name } to_user { full_name } type read_time '

export const qlFetchMessages = gql`
  query messages($mine: Boolean, $unread: Boolean) { 
    messages(mine: $mine, unread: $unread) {
        ${qlMessageFields} 
      }
  }
`

export const qlFetchSelectedMessage = gql`
  query message($message_uid: String, $skip: Boolean!) { 
    message(message_uid: $message_uid) @skip(if: $skip) {
        ${qlMessageFields} params
      }
  }
`

export const qlMarkasRead = gql`
  mutation message_mark_as_read($message_uid: String) {
    message_mark_as_read(message_uid: $message_uid) {
      message_uid
    }
  }
`
export const qlCreateMessage = gql`
  mutation message_create($message: message_in) {
    message_create(message: $message) {
      message_uid
    }
  }
`
