import React from 'react'
import { useGetFirstMeetingPropectDashboardByMonth } from '../meetings/my-first-meeting-status-hoc'
import { useGetNewCustomerWonByMonth } from './widget-customers-won-hoc'
import { useGetNewValidLeadsByMonth } from '../lead/new-valid-lead-widget-hoc'
import { calculatePercentInt } from 'crm-utils/data-utils'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import moment from 'moment'
import { resolveDashboardWidgetFiltersFromContextFilters } from 'crm-modules/dashboard/dashboard-utils'
import { getWidgetAnalyticContext } from 'crm-modules/dashboard/dashboards/tools'

export type widgetWinRatioHocProps = {
  totalNewCustomerWon?: number
  totalPercentWinRatio?: string
  totalPercentWinRatioAgent?: string
  totalNewCustomerVisited?: number
  footerRender?: any[any]
  footerRenderAgent?: any[any]
  isLoading?: boolean
  refetchData?: () => any
  bigContent?: boolean
  onClickDetail?: Function
}

const NEW_CUSTOMERS_WON = 'NEW CUSTOMERS WON'
const NEW_CUSTOMERS_VISITED = 'NEW CUSTOMERS VISITED'
const NEW_VALID_LEADS = 'NEW VALID LEADS'

const widgetCustomersWinRatioHoc = (Component: any) => {
  const Wrapper = (props: any) => {
    const { headcounts } = props
    const analyticsContext = getWidgetAnalyticContext()
    const newCustomerWon = useGetNewCustomerWonByMonth(6, headcounts)
    const newCustomersVisit = useGetFirstMeetingPropectDashboardByMonth(6, headcounts)
    const newValidLead = useGetNewValidLeadsByMonth(6, headcounts)
    const totalNewCustomerVisited = newCustomersVisit.totalLatestMonth + newCustomersVisit.totalPassedMonths
    const totalNewCustomerWon = newCustomerWon.totalLatestMonth + newCustomerWon.totalPassedMonths
    const totalNewValidLeads = newValidLead.totalLatestMonth + newValidLead.totalPassedMonths
    const ratioOfWon = `${calculatePercentInt(totalNewCustomerWon, totalNewCustomerVisited)} %`
    const ratioOfWonAgent = `${calculatePercentInt(totalNewCustomerWon, totalNewValidLeads)} %`
    const bigContent = props.headcounts

    let renderData = []
    renderData[NEW_CUSTOMERS_WON] = totalNewCustomerWon
    renderData[NEW_CUSTOMERS_VISITED] = totalNewCustomerVisited

    let renderDataAgent = []
    renderDataAgent[NEW_CUSTOMERS_WON] = totalNewCustomerWon
    renderDataAgent[NEW_VALID_LEADS] = totalNewValidLeads

    const refetchData = async () => {
      await newCustomerWon.refetch()
      await newCustomersVisit.refetch()
      await newValidLead.refetch()
    }

    const onClickDetail = (labelSelected = null) => {
      const {
        DATE_FORMAT,
        endDate,
        userUid: initialUserUid,
        organization_unit
      } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)
      const startDate = moment(endDate, DATE_FORMAT)
        .subtract(6, 'month')
        .startOf('month')
        .format(DATE_FORMAT)

      const userUid = headcounts ? undefined : initialUserUid

      const filters = {
        won_by_user_uid: userUid,
        customer_since_date: {
          $gte: startDate,
          $lte: endDate
        },
        organization_unit: undefined,
        assigned_to_user_date: undefined,
        account: undefined
      }

      switch (labelSelected?.value ?? '') {
        case NEW_CUSTOMERS_VISITED:
          props.onShowLeadsPopup({
            type: 'activities',
            filters: {
              organization_unit,
              performer_user_uid: userUid,
              first_type: 'first',
              type: {
                medium_code: 'meeting'
              },
              role_code: "performer",
              used_user_uid: true,
              activity_date: { $gte: startDate, $lte: endDate }
            }
          })
          break
        case NEW_VALID_LEADS:
          props.onShowLeadsPopup({
            type: 'sales_process',
            filters: {
              ...filters,
              owner_user_uid: userUid,
              organization_unit,
              assigned_to_user_date: {
                $gte: startDate,
                $lte: endDate
              },
              customer_since_date: undefined,
              account: undefined
            }
          })
          break
        case NEW_CUSTOMERS_WON:
        default:
          props.onShowLeadsPopup({ type: 'account', filters: { ...filters, organization_unit } })
      }
    }

    return (
      <Component
        totalNewCustomerWon={totalNewCustomerWon}
        totalPercentWinRatioAgent={ratioOfWonAgent}
        totalPercentWinRatio={ratioOfWon}
        totalNewCustomerVisited={totalNewCustomerVisited}
        footerRender={renderData}
        footerRenderAgent={renderDataAgent}
        isLoading={newCustomerWon.loading && newCustomersVisit.loading}
        refetchData={refetchData}
        bigContent={bigContent}
        onClickDetail={onClickDetail}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetCustomersWinRatioHoc
