import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { func, shape, string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  reduxForm,
  propTypes,
  FieldArray,
  Field,
  formValueSelector
} from 'redux-form'
import DocumentFormItems from './document-form-items.jsx'
import {
  CrmValidatedField,
  CrmDocumentTypeSelect,
  CrmLabeledField,
  CrmDayPickerInput,
  CrmDropdown
} from 'crm-components'

class DocumentFormInner extends PureComponent {
  constructor(props) {
    super(props)
    this.deleteItems = []
  }

  handleDeleteDocumentItem = documentItem => {
    // eslint-disable-next-line
    if (typeof documentItem.document_item_uid !== undefined) {
      this.deleteItems.push(documentItem.document_item_uid)
      this.props.change('items_delete', this.deleteItems.join(','))
    }
  }

  _loadDocumentType() {
    if (this.props.document.document_uid) {
      return (
        <CrmLabeledField
          label={
            <FormattedMessage
              id="crm.ui.account.document.document_type"
              defaultMessage="Document Type"
            />
          }
          value={this.props.document.document_type.name}
        />
      )
    }

    return (
      <CrmValidatedField
        name="document_type_uid"
        component={CrmDocumentTypeSelect}
        componentProps={{
          labelField: 'name',
          placeholder: (
            <FormattedMessage
              id="crm.ui.account.document.document_type"
              defaultMessage="Document Type"
            />
          )
        }}
        isRequired
      />
    )
  }

  render() {
    const { handleSubmit } = this.props
    const opportunitiesOptions = this.props.account.opportunities
      ? this.props.account.opportunities.map(o => ({
          name: (o.vehicle_count ? o.vehicle_count : '') + ' Vehicles',
          value: o.opportunity_uid
        }))
      : []
    return (
      <div className="Document-container-form">
        <form onSubmit={handleSubmit}>
          <div className="row CrmForm-row">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.document.document_type"
                  defaultMessage="Document Type"
                />
              }
            >
              {this.props.documentType}
            </CrmLabeledField>
            {/* <div className="col-xs-12 col-md-12 col-lg-3">
              {this._loadDocumentType()}
            </div>
            <div className="col-xs-12 col-md-12 col-lg-3">
              <CrmValidatedField
                component={CrmTextInput}
                componentProps={{ placeholder: 'Document name' }}
                name="name"
              />
            </div> */}
            <div className="col-xs-12 col-md-4 col-lg-2">
              <CrmValidatedField
                name="created_date"
                component={CrmDayPickerInput}
                componentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.ui.account.document.create_date"
                      defaultMessage="Create date"
                    />
                  ),
                  name: 'created_date'
                }}
                isRequired
              />
            </div>
            {/* <div className="col-xs-12 col-md-4 col-lg-2">
              <CrmValidatedField
                name="date_signed"
                component={CrmDayPickerInput}
                componentProps={{
                  placeholder: 'Sign date',
                  name: 'date_signed'
                }}
              />
            </div>
            <div className="col-xs-12 col-md-4 col-lg-2">
              <CrmValidatedField
                name="date_presented"
                component={CrmDayPickerInput}
                componentProps={{
                  placeholder: 'Present date',
                  name: 'date_presented'
                }}
              />
            </div> */}

            <Field name="document_uid" component="hidden" type="text" />
            <Field name="items_delete" component="hidden" type="text" />
          </div>
          <div className="row CrmForm-row">
            <div className="col-md-4">
              <CrmValidatedField
                component={CrmDropdown}
                componentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.ui.account.document.select_opportunity"
                      defaultMessage="Select Opportunity"
                    />
                  ),
                  options: opportunitiesOptions
                }}
                name="opportunity_uid"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <FieldArray
                name="items"
                component={DocumentFormItems}
                onDeleteItem={this.handleDeleteDocumentItem}
                opportunityUid={this.props.opportunityUid}
                account={this.props.account}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

DocumentFormInner.propTypes = {
  ...propTypes,
  handleSubmit: func.isRequired,
  document: shape({}).isRequired,
  opportunityUid: string
}

DocumentFormInner.defaultProps = {
  opportunityUid: undefined
}

const selector = formValueSelector('CrmDocumentFormInner')

const mapStateToProps = state => ({
  opportunityUid: selector(state, 'opportunity_uid')
})

const DocumentFormInnerForm = reduxForm({
  enableReinitialize: true,
  form: 'CrmDocumentFormInner'
})(DocumentFormInner)
export default connect(mapStateToProps)(DocumentFormInnerForm)
