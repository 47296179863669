import React from 'react'
import { node, bool, string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CrmInfoBlock = props => (
  <div
    className={`CrmInfoBlock
     ${props.info ? 'CrmInfoBlock--info' : ''} 
     ${props.className || ''} 
     ${props.warning ? ' CrmInfoBlock--warning ' : ''}
     ${props.hint ? ' CrmInfoBlock--hint ' : ''}
     ${props.error ? ' CrmInfoBlock--error ' : ''}`}
  >
    {props.hint && (
      <span style={{ padding: '0 10px' }}>
        <FontAwesomeIcon icon="lightbulb" />
      </span>
    )}
    {props.children}
  </div>
)

CrmInfoBlock.propTypes = {
  children: node.isRequired,
  info: bool,
  warning: bool,
  error: bool,
  hint: bool,
  className: string
}
CrmInfoBlock.defaultProps = {
  info: false,
  warning: false,
  error: false,
  hint: false,
  className: undefined
}
export default CrmInfoBlock
