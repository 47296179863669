import React from 'react'
import { CrmButton } from '@cartrack-crm/ui'
import { SectionHeader } from './master-list'

interface MasterFormStep4Props {
  masterUid: string
  initialValues: any
  master_customer: any
  created_profiles: any
  isSaving: boolean
  handleCreateProfilesFromTemplate: any
  handleCompleteConfiguration: any
  is_template: boolean
}

export const MasterFormStep4: React.SFC<MasterFormStep4Props> = (props) => {
  console.log('MasterFormStep4', props)
  const created_profiles = props.created_profiles ? props.created_profiles : []
  return (
    <div>
      <SectionHeader name="Profiles" />

      {created_profiles && (
        <React.Fragment>
          <div
            className="util-marginLg"
            style={{ maxHeight: '300px', overflowY: 'auto' }}
          >
            <div className="">Created Profiles</div>
            <div className="util-marginLeft">
              {created_profiles.map((v) => (
                <li className="">{`${v.name}`}</li>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}

      <fieldset
        className="Form-fieldset"
        disabled={props.initialValues?.step >= 4}
      >
        {!props.is_template && (
          <div className="util-marginTop">
            Do you want to create default profiles?
            <div className="row  util-marginTop">
              <div className="col-md-12">
                <CrmButton
                  type="primary"
                  label="Create Default Profiles"
                  disabled={props.isSaving}
                  enable={!props.isSaving}
                  isSaving={props.isSaving}
                  onClick={props.handleCreateProfilesFromTemplate}
                />
              </div>
            </div>
          </div>
        )}
      </fieldset>
      <br />

      <fieldset
        className="Form-fieldset"
        disabled={props.initialValues?.step >= 4}
      >
        <SectionHeader name="Complete Configuration" />
        <div className="row">
          <div className="col-md-12">
            <CrmButton
              type="primary"
              label="Complete Configuration"
              disabled={props.isSaving}
              enable={!props.isSaving}
              isSaving={props.isSaving}
              onClick={props.handleCompleteConfiguration}
            />
          </div>
        </div>
      </fieldset>
    </div>
  )
}
