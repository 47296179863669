/* eslint-disable  */
import gql from 'graphql-tag'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { buildQlQueryByFields } from 'crm-utils/data-utils'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'

export const dialerCampaignQl = gql(`
  query dialer_campaign_accounts_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    dialer_campaign_accounts_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      data {
        database_uid
        instance_uid
        account_uid
        account_name
        lead_source_uid
        lead_source_name
        lead_in_date
        process_type_name
        current_step_name
        current_step_type_uid
        process_started_time
        current_step_started_time
        process_started_date
        process_completed_time
        process_completed_date
        process_step_step
        owner_user_name
        owner_user_uid
        owner_pool_name
        owner_pool_uid
        owner_name
        process_uid
        task_uid
        task_name
        task_owner_name
        assignee_type
        task_due_date
        task_type_name
        resource_pool_name
        count
        process_step_kind
        process_started_hour
        campaign_uid
        campaign_name
        account_meta
      }
      count
    }
  }
`)

export const qlAgentsAnalytics = gql(`
  query agents_analytics_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    agents_analytics_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        user_uid
        user_name
        success_calls
        activity_count
        call_length
        call_disposition_name
        activity_status_name
        headshot_url
        call_disposition_is_success
        account_uid
        account_name
        start_time
        process_uid
      }
    }
  }
`)

export const qlCompletedTask = gql(`
  query tasks_complete_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ){
    tasks_complete_list_qm_paged(filter: $filter, limit: $limit, offset: $offset, sort: $sort, fields: $fields, aggregate: $aggregate){
      count,
      data{
        task_uid account_uid  instance_uid  name  completed_time user_uid full_name
      }
    }
  }
`)

// export mapContextToFilter
// export const mapContextToFilter = (
//   props,
//   mapDates = true,
//   mapActivityDateAs = undefined
// ) => {
//   const filter = {}
//   console.log('map context to filter', props)
//   if (props && props.analyticsContext && props.analyticsContext.filters) {
//     const supportedFields = [
//       'instance_uid',
//       'campaign_uid',
//       'lead_source_type_uid',
//       'lead_source_uid',
//       'lead_source_name',
//       'user_uid',
//       'process_started_time',
//       'process_started_date',
//       'process_started_hour',
//       'current_step_name',
//       'process_step_kind',
//       'process_completed_time',
//       'activity_status_name',
//       'call_disposition_name',
//       'user_name',
//       'start_time',
//       'owner_user_name',
//       'task_due_day',
//       'task_owner_name',
//       'assignee_type',
//       'kpi_sales_status_code',
//       'kpi_owner_user_uid',
//       'kpi_owner_user_name'
//     ]
//     supportedFields.forEach(field => {
//       if (props.analyticsContext.filters[field]) {
//         if (props.analyticsContext.filters[field].value) {
//           console.log(
//             'is Array, ',
//             Array.isArray(props.analyticsContext.filters[field].value)
//           )
//           if (
//             Array.isArray(props.analyticsContext.filters[field].value) &&
//             props.analyticsContext.filters[field].value.length > 0
//           ) {
//             filter[field] = props.analyticsContext.filters[field].value[0]
//           } else {
//             filter[field] = props.analyticsContext.filters[field].value
//           }
//         } else {
//           filter[field] = props.analyticsContext.filters[field]
//         }
//       }
//     })

//     if (mapDates) {
//       if (
//         props.analyticsContext.filters.lead_in_date &&
//         props.analyticsContext.filters.lead_in_date.value
//       ) {
//         filter.lead_in_date = {
//           $gte: props.analyticsContext.filters.lead_in_date.value[0],
//           $lte: props.analyticsContext.filters.lead_in_date.value[1]
//         }
//       }
//     }
//     if (
//       mapActivityDateAs &&
//       props.analyticsContext.filters.activity_date &&
//       props.analyticsContext.filters.activity_date.value
//     ) {
//       filter[mapActivityDateAs] = props.analyticsContext.filters.activity_date
//     }
//     if (
//       props.analyticsContext.filters.resource_pool_uid &&
//       props.analyticsContext.filters.resource_pool_uid.value
//     ) {
//       if (!filter.user_uid) {
//         filter.user_uid = {
//           // eslint-disable-next-line
//           $inResourcePool:
//             props.analyticsContext.filters.resource_pool_uid.value[0]
//         }
//       }
//       if (!filter.owner_user_uid) {
//         filter.owner_user_uid = {
//           // eslint-disable-next-line
//           $inResourcePool:
//             props.analyticsContext.filters.resource_pool_uid.value[0]
//         }
//       }
//       delete filter.resource_pool_uid
//     }
//   }
//   console.log('result filter', filter)
//   return filter
// }
// export const mock = 1

// eslint-disable-next-line
export const aggregateBy = (data, field, valueField) => {
  if (!data || !data.reduce) {
    console.log('Wrong data passed to aggregateBy', data)
    return []
  }
  return data.reduce((a, i) => {
    if (i[field]) {
      if (!a[i[field]])
        // eslint-disable-next-line
        a[i[field]] = {
          value: 0,
          items: [],
          name: i[field]
        }
    }
    // eslint-disable-next-line
    a[i[field]].value += valueField ? Number(i[valueField]) : 1

    return a
  }, {})
}

export const loadNewLeadsInCampaign = async (
  props,
  pfilter,
  hourPrecision = true
) => {
  let filter = mapContextToFilter(props, false)
  if (pfilter) {
    filter = {
      ...filter,
      ...pfilter
    }
  }
  if (props.analyticsContext.filters.activity_date) {
    filter.process_started_time = props.analyticsContext.filters.activity_date
  }

  const variables = {
    filter,
    fields: [
      'process_started_date',
      'account__lead_campaign__name',
      'account__lead_campaign__campaign_uid'
    ],
    aggregate: [
      {
        id: 'process_uid',
        type: 'count'
      }
    ],
    rootType: 'DialerCampaignAccountsQM',
    limit: 1000
  }

  if (hourPrecision) {
    variables.fields.push('process_started_hour')
  }
  const res = await props.client.query({
    query: qlqGenericDataQuery,
    variables,
    fetchPolicy: 'no-cache'
  })

  console.log('Got result', res)
  return res.data.genericDataQuery.data
}

export const loadProcessedLeads = async (props, hourPrecission = true) => {
  const filter = mapContextToFilter(props, false)
  if (props.analyticsContext.filters.activity_date) {
    filter.process_completed_time = props.analyticsContext.filters.activity_date
  }
  filter.is_final_step = 'true'

  const variables = {
    filter,
    fields: [
      'process_completed_date',
      'step__type__name',
      'step__type__kind',
      'account__lead_campaign_uid',
      'account__lead_campaign__name'
    ],
    aggregate: [
      {
        id: 'process_uid',
        type: 'count'
      }
    ],
    rootType: 'DialerCampaignAccountsQM',
    limit: 1000
  }
  if (hourPrecission) {
    variables.fields.push('process_completed_time')
  }
  const res = await props.client.query({
    query: qlqGenericDataQuery,
    variables,
    fetchPolicy: 'no-cache'
  })

  console.log('Got result', res)
  return res.data.genericDataQuery.data
}

export const loadProcessedLeadsByResult = async (
  props,
  hourPrecission = true
) => {
  const filter = mapContextToFilter(props, false)
  if (props.analyticsContext.filters.activity_date) {
    filter.process_completed_time = props.analyticsContext.filters.activity_date
  }
  filter.is_final_step = 'true'

  const variables = {
    filter,
    fields: ['process_completed_date', 'step__type__name', 'step__type__kind'],
    aggregate: [
      {
        id: 'process_uid',
        type: 'count'
      }
    ],
    rootType: 'DialerCampaignAccountsQM',
    limit: 1000
  }
  if (hourPrecission) {
    variables.fields.push('process_completed_time')
  }
  const res = await props.client.query({
    query: qlqGenericDataQuery,
    variables,
    fetchPolicy: 'no-cache'
  })

  console.log('Got result', res)
  return res.data.genericDataQuery.data
}
