import React, { SFC, useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { CrmButton } from '@cartrack-crm/ui'

const DownloadButton = ({
  widgetDefinition,
  onlyOnClick = false,
  dataSourcesVariables = {}
}) => {
  const [isLoading, setLoading] = useState(false)
  const [generateExcel] = useMutation(downloadResourceQL)
  const onClick = async () => {
    setLoading(true)
    const variables = dataSourcesVariables
    try {
      const result = await generateExcel({
        variables
      })
      const {
        resource,
        output
      } = result.data.master.instance.dashboard_generate_excel
      const data = output
      const sUrl =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        data
      const fileName = resource.name
      downloadPopup(sUrl, fileName)
    } catch (error) {
      console.log('Download excel buttonError: ', error)
    }

    setLoading(false)
  }
  const icon = isLoading ? 'circle-notch fa-spin' : 'download'
  const isShowButon = widgetDefinition?.enable_excel_export
  if (onlyOnClick) {
    if (!isShowButon) return null
    return onClick
  }
  if (!isShowButon) {
    return <React.Fragment />
  }
  return (
    <CrmButton disabled={isLoading} icon={icon} onClick={onClick} grouped />
  )
}
export default DownloadButton

const downloadPopup = (sUrl, fileName) => {
  const link = document.createElement('a')
  link.href = sUrl
  link.setAttribute('target', '_blank')

  if (link.download !== undefined) {
    link.download = fileName
  }
  if (document.createEvent) {
    const e = document.createEvent('MouseEvents')
    e.initEvent('click', true, true)
    link.dispatchEvent(e)
    return true
  }
}

const downloadResourceQL = gql`
  mutation dashboard_generate_excel(
    $master_uid: String!
    $instance_uid: String!
    $dashboard_uid: String
    $widgetDefinition: JSON
    $filter: JSON
  ) {
    master(master_uid: $master_uid) {
      instance(instance_uid: $instance_uid) {
        name
        dashboard_generate_excel(
          dashboard_uid: $dashboard_uid
          widgetDefinition: $widgetDefinition
          filter: $filter
        ) {
          output
          resource {
            resource_uid
            name
            description
          }
        }
      }
    }
  }
`
