import React, { FC } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faCircle } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

const CrmCarouselWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`
const CarouselIconBtn = styled.i`
  cursor: pointer;
  font-size: var(--crm-font-size-30);
  margin: var(--crm-margin-auto-vertical);
  padding: var(--crm-padding-10);
  color: var(--crm-color-gray70);

  &:hover {
    color: var(--crm-color-gray60);
  }
`
const CarouselGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--crm-padding-10);
  min-width: 250px;
`

const CarouselContentWrapper = styled.div`
  padding: var(--crm-padding-10);
  text-align: center;
  font-weight: var(--crm-font-weight-medium);
`

const CarouselPagingWrapper = styled.div`
  width: 100%;
  text-align: center;
`

const CarouselDotPaging = styled.i`
  padding: var(--crm-padding-02);
  font-size:var(--crm-font-size-xxs);
  cursor: pointer;
  color: var(--crm-color-gray50);

  &.active {
    color: var(--crm-color-gray70);
  }

  &:hover{
    font-size: var(--crm-font-size-sm);
    color: var(--crm-color-gray70);
  }
`

interface ICrmCarousel {
  options: { name: string | Element | JSX.Element, value: string }[],
  onChange: (value: string) => any
  value: string
}

export const CrmCarousel: FC<ICrmCarousel> = (props) => {
  const currentValue = props.options?.find((option) => (props.value === option.value))?.name ?? props.options?.[0]?.name
  let currentIndex = props.options?.findIndex((item) => (item.value === props.value)) ?? 0
  currentIndex = currentIndex === -1 ? 0 : currentIndex
  const lengthOptions = props.options?.length ?? 0;
  const onChangeNext = () => {
    if (lengthOptions === currentIndex + 1) {
      const value = props?.options?.[0]?.value
      sendValueOutside(value)
    } else {
      const value = props?.options?.[currentIndex + 1]?.value
      sendValueOutside(value)
    }
  }
  const onChangePrev = () => {
    if (currentIndex === 0) {
      const value = props?.options?.[lengthOptions - 1]?.value
      sendValueOutside(value)
    } else {
      const value = props?.options?.[currentIndex - 1]?.value
      sendValueOutside(value)
    }
  }

  const sendValueOutside = (value) => {
    if (props.onChange) props.onChange(value)
  }
  return (
    <CrmCarouselWrapper>
      <CarouselIconBtn onClick={onChangePrev}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </CarouselIconBtn>
      <CarouselGroupWrapper>
        <CarouselContentWrapper>
          {currentValue}
        </CarouselContentWrapper>
        <CarouselPagingWrapper>
          {props.options?.map((option) => {
            const isActive = option.value === props.value
            return (
              <CarouselDotPaging
                title={option.name}
                key={option?.value}
                className={classNames({ 'active': isActive })}
                onClick={() => sendValueOutside(option.value)}>
                <FontAwesomeIcon icon={faCircle} />
              </CarouselDotPaging>)
          })}
          {!props.options && 'No items'}
        </CarouselPagingWrapper>
      </CarouselGroupWrapper>
      <CarouselIconBtn onClick={onChangeNext}>
        <FontAwesomeIcon icon={faAngleRight} />
      </CarouselIconBtn>
    </CrmCarouselWrapper>)
}