import React from 'react'
import { string, func, bool, shape, number, oneOfType, node, any } from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export function getButtonClassProperties(props) {
  let buttonClass = 'Button CrmButton '
  if (props.className) {
    buttonClass += ' ' + props.className + ' '
  }
  if (props.type) {
    buttonClass += ' ' + 'CrmButton--' + props.type + ' '
  }
  buttonClass += props.small ? ' CrmButton--small ' : ''
  buttonClass += props.small && props.icon ? 'CrmButton--smallIcon' : ''
  buttonClass += props.icon ? ' CrmButton--iconButton' : ''
  buttonClass += props.noBorder ? ' CrmButton--noBorder' : ''
  buttonClass += props.white ? ' CrmButton--white' : ''
  buttonClass += props.blue ? ' CrmButton--blue' : ''
  buttonClass += props.green ? ' CrmButton--green' : ''
  buttonClass += props.red ? ' CrmButton--red' : ''
  buttonClass += props.disabled ? ' CrmButton--disabled' : ''
  buttonClass += props.type === 'primary' ? ' CrmButton--primary' : ''
  buttonClass += props.xsmall ? ' CrmButton--xsmall' : ''
  buttonClass += props.margin || props.vMargin ? ' CrmButton--vMargin' : ''
  buttonClass += props.iconButton ? ' CrmButton--iconButton' : ''
  buttonClass += props.icon && props.label ? ' CrmButton--iconButton--withLabel' : ''
  buttonClass += props.margin || props.hMargin ? ' CrmButton--hMargin' : ''
  buttonClass += props.grouped ? ' CrmButton--grouped' : ''
  return buttonClass
}

function _renderInner(props) {
  const spinnerMargin = props.label ? 'util-marginLeft' : ''
  return (
    <button
      className={getButtonClassProperties(props)}
      onClick={props.disabled ? undefined : props.onClick}
      type={props.type && props.type === 'submit' ? 'submit' : 'button'}
      id={props.id}
      style={props.style}
      disabled={props.disabled}
      title={props.title}
    >
      {props.icon && (
        <div className="CrmButton-iconWrapper">
          {typeof props.icon === 'string' && <span className={`${props.icon ? 'fa fa-' + props.icon : ''}`} />}

          {typeof props.icon === 'object' && <FontAwesomeIcon icon={props.icon} />}
        </div>
      )}
      {props.label && (
        <span className="CrmButton-label">
          {typeof props.label === 'string' && props.formatMessage
            ? props.formatMessage(props.label, 'ui.button')
            : props.label}
        </span>
      )}
      {props.isSaving && (
        <span className={spinnerMargin ?? ''}>
          <FontAwesomeIcon spin icon={faSpinner} />
        </span>
      )}
    </button>
  )
}

export const CrmButton = props => {
  if (props.linkTo) {
    return <Link to={props.linkTo}>{_renderInner(props)}</Link>
  }
  return _renderInner(props)
}

CrmButton.propTypes = {
  id: oneOfType([string, number]),
  icon: any,
  label: node,
  className: string,
  onClick: func,
  type: string,
  linkTo: string,
  formatMessage: func,
  xsmall: bool,
  small: bool,
  style: shape({}),
  margin: bool,
  hMargin: bool,
  vMargin: bool,
  iconButton: bool,
  enable: bool,
  grouped: bool,
  enableTranslate: bool,
  isSaving: bool,
  noBorder: bool,
  disabled: bool,
  title: string,
  isShowProgress: bool
}

_renderInner.propTypes = CrmButton.propTypes

CrmButton.defaultProps = {
  className: '',
  icon: '',
  type: undefined,
  onClick: () => {},
  linkTo: undefined,
  small: false,
  xsmall: false,
  shape: undefined,
  label: undefined,
  margin: false,
  hMargin: false,
  vMargin: false,
  iconButton: false,
  enable: true,
  grouped: false,
  enableTranslate: true,
  formatMessage: undefined,
  isSaving: false,
  noBorder: false,
  disabled: false,
  title: undefined,
  isShowProgress: undefined
}

export default CrmButton
