/* eslint-disable */
import templateAPI from 'crm-api/settings/templates-api'
import {
  FETCH_TEMPLATE_SETTING,
  RECEIVE_TEMPLATE_SETTING,
  SAVING_TEMPLATE,
  SAVING_TEMPLATE_SUCCESS,
  GET_TEMPLATE_SETTING_BY_ID,
  RECEIVE_TEMPLATE_SETTING_BY_ID,
  SUBMIT_TEMPLATE_PREVIEW,
  RECEIVE_TEMPLATE_PREVIEW
} from 'crm-duxs/settings/templates-reducer'
import { toastr } from 'react-redux-toastr'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchTemplates() {
  const templates = yield call(templateAPI.fetch)
  yield put({ type: RECEIVE_TEMPLATE_SETTING, payload: { templates } })
}

function* getTemplate(action) {
  const template = yield call(templateAPI.get, action.templateId)
  console.log('getTemplate : ', template)
  yield put({ type: RECEIVE_TEMPLATE_SETTING_BY_ID, payload: { template } })
}

function* saveTemplates(action) {
  try {
    yield call(templateAPI.save, action.formValues)
    //yield put({ type: SAVING_TEMPLATE_SUCCESS })
    yield put(toastr.success('Template has been saved'))
  } catch (err) {
    yield put(toastr.error('Something webt wrong'))
  }
}

function* getPreview(action) {
  try {
    const preview = yield call(templateAPI.preview, action.formValues)
    yield put({ type: RECEIVE_TEMPLATE_PREVIEW, payload: preview })
  } catch (err) {
    yield put(toastr.error('Something webt wrong'))
  }
}

// function* createReport(action) {
//   try {
//     yield call(reportAPI.create, action.formValues)
//     yield put({ type: CANCEL_CREATING_REPORT })
//     yield put({ type: FETCH_REPORTS_SETTING })
//   } catch (err) {}
// }

// function* updateReport(action) {
//   try {
//     yield call(reportAPI.update, action.formValues)
//     yield put({ type: CANCEL_EDITING_REPORT })
//     yield put({ type: FETCH_REPORTS_SETTING })
//   } catch (err) {}
// }

function* settingTemplateSaga() {
  yield takeLatest(FETCH_TEMPLATE_SETTING, fetchTemplates)
  yield takeLatest(SAVING_TEMPLATE, saveTemplates)
  yield takeLatest(GET_TEMPLATE_SETTING_BY_ID, getTemplate)
  yield takeLatest(SUBMIT_TEMPLATE_PREVIEW, getPreview)

  // yield takeLatest(SAVE_CREATING_REPORT, createReport)
  // yield takeLatest(SAVE_EDITING_REPORT, updateReport)
}

export default settingTemplateSaga
