import React, { PureComponent } from 'react'
import { shape, func, bool } from 'prop-types'
import { availableColumns } from '../../list/components/accounts-columns.jsx'
import AccountsList from '../../list/accounts-list.jsx'
import { CrmModal, CrmButton } from 'crm-components'
import { toastr } from 'react-redux-toastr'

class AccountMergingSelector extends PureComponent {
  constructor(props) {
    super(props)
    const columns = Object.assign([], availableColumns)

    this.state = {
      columns,
      selectedAccount: null,
      selectedDirection: null
    }
  }

  handleRunMergeClick = async => {
    const selectedAccount = { ...this.state.selectedAccount }
    if (this.props.account.account_uid === selectedAccount.account_uid) {
      toastr.error('Merge direction from and to are the same account')
      return
    }
    if (!this.state.selectedAccount) {
      toastr.error('Please select an account to merge')
      return
    }
    if (!this.state.selectedDirection) {
      toastr.error('Please select merge direction')
      return
    }

    const sourceAccountUid =
      this.state.selectedDirection === 'fromSelectedAccount'
        ? selectedAccount.account_uid
        : this.props.account.account_uid
    const destinationAccountUid =
      this.state.selectedDirection === 'fromSelectedAccount'
        ? this.props.account.account_uid
        : selectedAccount.account_uid
    const toastrConfirmOptions = {
      onOk: async () => {
        try {
          const res = await this.props.onMergeAccountAction(
            sourceAccountUid,
            destinationAccountUid
          )
          toastr.success('Merge account successfully. Please refresh the page.')
        } catch (err) {
          toastr.error(err.message)
        }
        this.props.onClose()
      }
    }
    toastr.confirm(
      `Are you sure you want to merge account? `,
      toastrConfirmOptions
    )
  }

  handleRowClick = account => {
    const selectedAccount = { ...account }
    this.setState({ selectedAccount })
  }

  handleSelectMergeFromSelectedAccount = () => {
    this.setState({ selectedDirection: 'fromSelectedAccount' })
  }

  handleSelectMergeFromCurrentPageAccount = () => {
    this.setState({ selectedDirection: 'fromCurrentPageAccount' })
  }

  handleCloseModal = () => {
    this.props.onClose()
  }

  render() {
    const { selectedAccount, selectedDirection } = this.state
    const isModalMode = true
    const accountName = this.props.account.name
    const selectedAccountName = selectedAccount
      ? selectedAccount.account_name
      : 'selected account'
    const mergeButtonClass = `AccountMergeButton`
    const accountNameClass = 'AccountMerge-accountName'
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Merge account"
        size="xl"
        onClose={this.handleCloseModal}
      >
        <div className="util-fullHeight util-flexColumn">
          <div className="util-fullHeight util-flexColumn">
            <div className="util-marginLg util-textError util-textBold">
              Please consult IT before using this feature /
              การใช้งานระบบนี้ต้องปรึกษากับแผนก IT ก่อนเริ่มใช้งาน
            </div>

            <div className="util-marginLg">
              <div className="util-textBlue util-textBold">
                Please select merge direction
              </div>
              <div className="util-marginLg" />
              <div className="row">
                <div className="col-md-5">
                  <div
                    className={`${mergeButtonClass} ${
                      selectedDirection === 'fromSelectedAccount'
                        ? 'AccountMergeButton--selected'
                        : ''
                    }`}
                    onClick={this.handleSelectMergeFromSelectedAccount}
                  >
                    <div className="">
                      {` Merge from `}
                      <span className={`${accountNameClass}`}>
                        ({selectedAccountName})
                      </span>
                      <br />
                      {` to `}
                      <span className={`${accountNameClass}`}>
                        ({accountName})
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div
                    className={`${mergeButtonClass} ${
                      selectedDirection === 'fromCurrentPageAccount'
                        ? 'AccountMergeButton--selected'
                        : ''
                    }`}
                    onClick={this.handleSelectMergeFromCurrentPageAccount}
                  >
                    <div className="">
                      {` Merge from `}
                      <span className={`${accountNameClass}`}>
                        ({accountName})
                      </span>
                      <br />
                      {` to `}
                      <span className={`${accountNameClass}`}>
                        ({selectedAccountName})
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 util-marginAuto">
                  <CrmButton
                    label={'Merge'}
                    onClick={this.handleRunMergeClick}
                    enable={selectedAccount && selectedDirection}
                    type="primary"
                  />
                </div>
              </div>
            </div>

            <div className="util-flexColumn">
              <AccountsList
                visibleColumns={['name', 'kind', 'control']}
                columns={this.state.columns}
                isModalMode={isModalMode}
                accountsListCode="leads"
                onRowClick={this.handleRowClick}
              />
            </div>
          </div>
        </div>
      </CrmModal>
    )
  }
}

AccountMergingSelector.propTypes = {
  account: shape({}).isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onMergeAccountAction: func.isRequired
}

AccountMergingSelector.defaultProps = {}

export default AccountMergingSelector
