/* eslint-disable  */
import React from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl'
import uuidv4 from 'uuid'
import {
  propTypes,
  change,
  Field,
  reduxForm,
  FieldArray,
  isDirty,
  isValid
} from 'redux-form'
import { func, shape, bool, string, arrayOf } from 'prop-types'
import convertToProspectHOC from './convert-to-prospect-ql-hoc'
import {
  CrmUserSelect,
  CrmCampaignSelect,
  CrmResourcePoolSelect,
  CrmDropdown
} from 'crm-components'

import { RadioInput } from 'util-components'
export const formName = 'convertToProspect'

export class ConvertToProspectFields extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      thisUid: uuidv4()
    }
    console.log('ConvertToProspectFields constructor')
  }
  componentDidMount = () => {
    console.log('Conver to prospect fields, did mount')
    if (this.props.onSetClickHandler) {
      this.props.onSetClickHandler(this.handlePrimaryAction)
    }
    if (this.props.onBulkComponentInit) {
      this.props.onBulkComponentInit(this)
    }
  }

  handleOnChange = value => {
    this.setState({ selectedQualificationUid: value })
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }
  handleIsSaving = async isSaving => {
    if (this.props.handleIsSaving) {
      this.props.handleIsSaving(isSaving)
    }
  }
  handlePrimaryAction = async props => {
    // console.log(
    //   'Convert To Prospect Modal, handlePrimaryAction',
    //   props,
    //   this.state
    // )
    const formValues = this.props.formValues
    // console.log('formValues', formValues)
    try {
      console.log('Call on SAve')
      this.handleIsSaving(true)
      const res = await this.props.onSave()
      this.handleIsSaving(false)
      // console.log('saved result', res)
      if (res && res.prospect) {
        const savedProspect = res.prospect
        if (savedProspect) {
          this.props.doPush(`/crm/account/${savedProspect.account_uid}/sales`)
        }
      } else if (res && res.process) {
        this.props.doPush(`/crm/account/${res.process.account_uid}/sales`)
      }
      if (res) {
        toastr.success('Successfully converted to prospect')
        this.props.onClose()
      }
    } catch (err) {
      console.log('Convert to prospect error', err)
      let msg = 'Can`t start new prospect'
      if (err && err.message) {
        msg += ': ' + err.message
      }
      toastr.error(msg)
      this.handleIsSaving(false)
    }
  }

  render() {
    console.log('render conver to prospect fields')
    const { owner_type } = this.props.formValues ? this.props.formValues : {}
    const showUserTypeSelect = true // this.props.newProcessKind !== 'prospect'
    if (this.props.noDefaultSaleCampaignError) {
      return (
        <div>
          Can't find Sale Campaign for this account's lead campaign (
          {this.props.account &&
            this.props.account.lead_campaign &&
            this.props.account.lead_campaign.name}
          ). Please contact administrator
        </div>
      )
    }
    return (
      <div className="util-paddingMd">
        <div>
          <h4>
            {this.props.newProcessKind === 'dialer' && (
              <FormattedMessage
                id="crm.ui.account.convert.assign_to"
                defaultMessage="Assign to"
              />
            )}
            {this.props.newProcessKind === 'prospect' && (
              <FormattedMessage
                id="crm.ui.account.convert.prospect_owner"
                defaultMessage="Select new account owner"
              />
            )}
            {!this.props.newProcessKind && <span>Start new process</span>}
          </h4>
          {showUserTypeSelect && (
            <Field
              name="owner_type"
              component={props => {
                return (
                  <React.Fragment>
                    <RadioInput
                      options={this.props.newOwnerOptions}
                      {...props}
                      // placeholder={
                      //   <FormattedMessage
                      //     id="crm.ui.account.convert.prospect_owner_type"
                      //     defaultMessage="Prospect Owner Type"
                      //   />
                      // }
                    />
                  </React.Fragment>
                )
              }}
            />
          )}
          {owner_type === 'user' && (
            <Field
              name="owner_user_uid"
              component={props => {
                return (
                  <React.Fragment>
                    <CrmUserSelect
                      {...props}
                      name="name"
                      title="Select new Owner"
                      placeholder={
                        <FormattedMessage
                          id="crm.ui.account.convert.select_new_owner"
                          defaultMessage="Select new Owner"
                        />
                      }
                    />
                  </React.Fragment>
                )
              }}
            />
          )}
          {owner_type === 'group' && (
            <Field
              name="owner_pool_uid"
              component={props => {
                // console.log('Render UsersGroupSelect component', props)
                return (
                  <React.Fragment>
                    <CrmResourcePoolSelect
                      {...props}
                      placeholder={
                        <FormattedMessage
                          id="crm.ui.account.convert.select_users_group_as_prospect_owner"
                          defaultMessage="Select Users Group as Prospect Owner"
                        />
                      }
                    />
                  </React.Fragment>
                )
              }}
            />
          )}
        </div>
        {!this.props.bulkMode && (
          <div>
            <div
              onClick={this.props.onToggleShowMoreOptions}
              className="util-textBlue util-pointer util-textRight util-paddingSm"
              style={{ textDecoration: 'underline' }}
            >
              <small>
                {this.props.isShowingMoreOptions ? 'Hide ' : 'Show '}Advanced
                Options
              </small>
            </div>
          </div>
        )}
        {(this.props.isShowingMoreOptions || this.props.bulkMode) && (
          <div className="util-marginTop">
            <Field
              name="campaign_uid"
              component={props => {
                // console.log('Render marketing_campaign_uid component', props)
                return (
                  <React.Fragment>
                    <CrmCampaignSelect
                      {...props}
                      name="marketing_campaign_uid"
                      title="Sales campaign"
                      type="sale"
                      placeholder={
                        <FormattedMessage
                          id="crm.ui.account.convert.sale_campaign"
                          defaultMessage="Sales Campaign"
                        />
                      }
                    />
                  </React.Fragment>
                )
              }}
            />
          </div>
        )}
        {(this.props.isShowingMoreOptions || this.props.bulkMode) && (
          <div className="util-marginTop">
            <Field
              name="process_type_uid"
              component={props => {
                // console.log('Render CrmDropdown component', props)
                return (
                  <React.Fragment>
                    <CrmDropdown
                      {...props}
                      options={this.props.processTypeOptions}
                      placeholder={
                        <FormattedMessage
                          id="crm.ui.account.convert.process_type"
                          defaultMessage="Process Type"
                        />
                      }
                    />
                    {props.meta && props.meta.error && (
                      <div className="util-textError">{props.meta.error}</div>
                    )}
                  </React.Fragment>
                )
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

ConvertToProspectFields.defaultProps = {
  onCancel: () => {},
  onClose: () => {},
  onChange: () => {},
  widgetMode: false,
  sourceProcess: undefined,
  user: undefined,
  usersGroup: undefined,
  owner_type: undefined,
  campaign_uid: undefined,
  processTypeOptions: undefined,
  process_type_uid: undefined,
  newProcessMode: string,
  bulkMode: false
}

ConvertToProspectFields.propTypes = {
  ...propTypes,
  onChange: func,
  onClose: func,
  onSetClickHandler: func.isRequired,
  onSave: func.isRequired,
  doPush: func.isRequired,
  sourceProcess: shape({}),
  user: string,
  usersGroup: string,
  owner_type: string,
  campaign_uid: string,
  newOwnerOptions: arrayOf(shape({})).isRequired,
  onowner_typeChanged: func.isRequired,
  onUserSelect: func.isRequired,
  onUserGroupSelect: func.isRequired,
  processTypeOptions: arrayOf(shape({})),
  process_type_uid: string,
  onProcessTypeChange: func.isRequired,
  onCampaignChanged: func.isRequired,
  newProcessMode: string,
  newProcessKind: string.isRequired,
  noDefaultSaleCampaignError: bool,
  requireSelectTransactionType: bool.isRequired,
  bulkMode: bool
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    doPush: url => dispatch(push(url))
  }
}

export const ConvertToProspectFieldsHOC = convertToProspectHOC(
  connect(mapStateToProps, mapDispatchToProps)(ConvertToProspectFields)
)

class ConvertToProspectFormIn extends React.Component {
  handleSubmit(values) {
    // console.log('handleSubmit', values)
  }
  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`CrmForm ${
          this.props.className ? this.props.className : ''
        }`}
        style={{ padding: 0 }}
      >
        <ConvertToProspectFields {...this.props} />
      </form>
    )
  }
}

const validateConvertToProspect = values => {
  // console.log('validateConvertToProspect', values)
  const errors = {}
  if (!values.process_type_uid) {
    errors.process_type_uid = 'Select prospect type'
  }
  return errors
}

const ConvertToProspectForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: validateConvertToProspect
})(ConvertToProspectFormIn)

export default convertToProspectHOC(
  connect(mapStateToProps, mapDispatchToProps)(ConvertToProspectForm)
)
