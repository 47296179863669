/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import { getActivityTypes } from '../activities-reducer'
import { buildCrmReducer } from 'crm-utils/reducer-utils'

export const STATUS_INIT = 'INIT'
export const STATUS_PENDING = 'PENDING'
// Reducer used by Create Activity forms
// Initial
const initialState = {
  status: undefined,
  activity: undefined,
  liveCallStatus: undefined, // Which step of call are we in: pre-call, dialing, call, post-call
  callPbxStatus: undefined, // Status from PBX or manual dialing
  isChanged: false, // Has data changed - have to save
  isSaving: false
  // // Here we hold list of available statuses - either for new or completed activity
}

// Actions
export const actions = {
  setStatus: {
    updater: action => ({ status: action.payload.status })
  },
  initNewLiveCall: {},
  fetchAvailableStatusesSuccess: {
    updater: action => ({ availableStatuses: action.payload.availableStatuses })
  },
  createLiveActivity: {},
  createLiveActivitySuccess: {
    updater: action => ({
      activity: action.payload.activity,
      isSaving: false,
      isChanged: false
    })
  },
  setCurrentLiveActivity: {
    updater: action => ({
      activity: action.payload.activity,
      isSaving: false,
      isChanged: false,
      status: STATUS_PENDING
    })
  },
  changeLiveCallStatus: {},
  setLiveCallStatus: {
    updater: action => ({ liveCallStatus: action.payload.liveCallStatus })
  },
  setLiveCallActivityType: {
    updater: action => ({
      liveCallActivityType: action.payload.liveCallActivityType,
      liveCallActivityStatuses: action.payload.liveCallActivityStatuses
    })
  },
  activityDetailsChanged: {},
  setIsSaving: {
    updater: action => ({ isSaving: action.payload.isSaving })
  },
  setIsChanged: {
    updater: action => ({ isChanged: action.payload.isChanged })
  },
  completeLiveCall: {},
  completeLiveCallSuccess: {
    updater: () => ({ activity: undefined, status: undefined })
  }
}

/**
 * This reducer is used to manage status of currently edited activity
 * Especially it is used to
 *  - create new activity
 *  - edit activity
 *  - perform other activity actions
 *
 * As we keep all the status inside this reducer (instead of some view) - there can be only ONE currently edited activity
 */

function liveCallReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
// Generators
export const initNewLiveCall = activity => ({
  type: actions.initNewLiveCall.type,
  payload: { activity }
})
export const setStatus = status => ({
  type: actions.setStatus.type,
  payload: { status }
})

// Selectors
export const getActivity = state => state.crm.liveCall.activity
export const getStatus = state => state.crm.liveCall.status
export const getLiveCallActivityStatuses = state =>
  state.crm.liveCall.liveCallActivityStatuses

export const getIsChanged = state => state.crm.liveCall.isChanged
export const getIsSaving = state => state.crm.liveCall.isSaving

const _getActivityType = (activity, types) => {
  if (!activity || !types) {
    return undefined
  }
  return types.find(
    type => type.activity_type_uid === activity.activity_type_uid
  )
}

export const getActivityType = createSelector(
  getActivity,
  getActivityTypes,
  _getActivityType
)

const reducer = buildCrmReducer(liveCallReducer, actions)
export const getCallPbxStatus = state => state.crm.activity.callPbxStatus
export default reducer
