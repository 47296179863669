import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ButtonWrapper = styled.span`
  right: 0px;
  margin-left: 5px;
  cursor: pointer;
  visibility: hidden;
  color: #5099ce;
`

const TextWrapper = styled.span``
const TranslateWrapper = styled.div`
${(p) =>
  p.oneline &&
  `
  ${TextWrapper} {
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 13px;
  }
  `}
  &:hover ${ButtonWrapper} {
    visibility: visible;
  }
`

const TranslateText = (props) => {
  const onClick = () => {
    window.open(
      `https://translate.google.com/?hl=th#th/en/${encodeURIComponent(
        props.children
      )}`,
      'Translate',
      'menubar=1,resizable=1,width=850,height=550'
    )
  }
  const childrenType = typeof props.children
  if (childrenType === 'string') {
    return (
      <TranslateWrapper oneline={props.oneline}>
        <TextWrapper dangerouslySetInnerHTML={{ __html: props.children }} />
        <ButtonWrapper onClick={onClick}>
          <FontAwesomeIcon icon="language" />
        </ButtonWrapper>
      </TranslateWrapper>
    )
  }
  return (
    <TranslateWrapper oneline={props.oneline}>
      {props.children}
    </TranslateWrapper>
  )
}

export default TranslateText
