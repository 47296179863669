import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import crmGenericSelectQLHocFactory from 'crm-components/ql/crm-generic-select-ql-hoc.jsx'
import { withCrmContext } from '@cartrack-crm/crm-core'

const leadSourceTypeQl = gql(
  `query lead_source_types($master_uid: String!) { 
    master(master_uid: $master_uid) { 
       lead_source_types {  lead_source_type_uid instance_uid name code kind description }
   }
 }
    `
)

const mapCampaignToOptions = c => ({
  value: c.lead_source_type_uid,
  key: c.lead_source_type_uid,
  name: c.name
})

const query = graphql(leadSourceTypeQl, {
  options: ownProps => {
    // console.log('List Source Type Options', ownProps)
    return {
      variables: {
        limit: ownProps.limit ? ownProps.limit : 1000,
        offset: ownProps.offset ? ownProps.offset : 0,
        master_uid:
          ownProps.crmContext && ownProps.crmContext.master
            ? ownProps.crmContext.master.master_uid
            : undefined
      }
    }
  },
  props: ({ leadSourceTypeQl, ownProps }) => {
    // console.log('LeadSourceTypeSelect', leadSourceTypeQl, ownProps)
    return {
      ...ownProps,
      leadSourceTypeQl,
      prefix: 'lead_source_types',
      optionsQl: leadSourceTypeQl,
      options:
        leadSourceTypeQl &&
        leadSourceTypeQl.master &&
        leadSourceTypeQl.master.lead_source_types
          ? leadSourceTypeQl.master.lead_source_types
              .map(mapCampaignToOptions)
              .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
          : undefined,
      count:
        leadSourceTypeQl &&
        leadSourceTypeQl.master &&
        leadSourceTypeQl.master.lead_source_types
          ? leadSourceTypeQl.master.lead_source_types.count
          : undefined,
      isLoading: leadSourceTypeQl ? leadSourceTypeQl.loading : false
    }
  },
  name: 'leadSourceTypeQl',
  fetchPolicy: 'no-cache'
})

const LeadSourceSelect = withCrmContext(
  crmGenericSelectQLHocFactory(query, {
    defaultProps: {
      limit: 1000
    },
    queryName: 'leadSourceTypeQl'
  })
)

LeadSourceSelect.propTypes = {}
LeadSourceSelect.defaultProps = {}

export default LeadSourceSelect
