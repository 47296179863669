import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import React from 'react'
import { Query } from 'react-apollo'
import resourceAPI from 'crm-api/admin/resources-api'
import { qlGetResources } from 'crm-data/import'
import { func, shape } from 'prop-types'

const importToolHoc = WrappedComponent => {
  class ImportToolHocInner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {}
    }

    handleUploadFile = async values => {
      try {
        const res = await resourceAPI.create(values)
        if (res.err) {
          console.log('Upload file error: ', res.err)
          throw new Error(res.err)
        } else {
          this.props.refetch()
          return res
        }
      } catch (err) {
        console.log('Upload file error: ', err)
        throw new Error(err)
      }
    }

    handleDeleteFile = async resourceUid => {
      try {
        const res = await resourceAPI.delete({ resourceUid: resourceUid })
        if (!res.resource_delete) {
          console.log('Delete file error: ', res)
          throw new Error('Delete file error')
        } else {
          this.props.refetch()
          return res
        }
      } catch (err) {
        console.log('Delete file error: ', err)
        throw new Error('Delete file error')
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleUploadFile={this.handleUploadFile}
          handleDeleteFile={this.handleDeleteFile}
        />
      )
    }
  }
  ImportToolHocInner.propTypes = {
    refetch: func.isRequired
  }
  const WithCommand = crmGenericCommandHoc(ImportToolHocInner)

  const ImportToolWithQuery = props => {
    return (
      <Query query={qlGetResources} variables={{ tag: props.tag }}>
        {({ loading, error, data, refetch }) => {
          return (
            <WithCommand
              {...props}
              loading={loading}
              error={error}
              refetch={refetch}
              lists={data.resources}
            />
          )
        }}
      </Query>
    )
  }
  ImportToolWithQuery.propTypes = {
    tag: shape().isRequired
  }
  return ImportToolWithQuery
}

export default importToolHoc
