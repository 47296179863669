import React, { useState } from 'react'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import { FilterSection, getActivityDateString } from '../tools'
import { ActivityDateFilterDropDown } from '.'
export const FilterSectionActivityDate = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleDropDown = () => setIsExpanded(!isExpanded)
  const closeDropDown = () => setIsExpanded(false)
  const { filters, setFilters } = useAnalyticsContext()
  const nameSectionActivityDate = props.nameSectionActivityDate ? props.nameSectionActivityDate : 'Activity Date'
  return (
    <FilterSection
      name={nameSectionActivityDate}
      selectedValue={<strong>{getActivityDateString(filters)}</strong>}
      isExpanded={isExpanded}
      onToggle={toggleDropDown}
      {...props}
    >
      {isExpanded && <ActivityDateFilterDropDown
        filterValues={filters}
        onSetFilters={setFilters}
        isExpanded={isExpanded}
        onClickedOutside={closeDropDown}
      />}
    </FilterSection>
  )
}
