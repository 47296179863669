import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Link } from 'react-router-dom'
import { getTrProps } from './tasks-table-utils'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import { qlqTasksListPaged } from 'crm-data/tasks'

const qlOptions = {
  options: ownProps => {
    console.log('qlOtions, ', ownProps)
    return {
      variables: {
        filter: ownProps.filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.tasks_list_qm_paged : []
  })
}

const Table = genericTableFactory({
  query: qlqTasksListPaged,
  qlOptions
})

export const availableColumns = [
  {
    Header: 'Account Name',
    accessor: 'account_name',
    Cell: row => (
      <Link
        to={`/crm/account/${row.original.account_uid}/process/${row.original.process_uid}`}
      >
        {row.original.account_name}
      </Link>
    )
  },
  {
    Header: 'Process Type',
    accessor: 'process_type_name'
  },
  {
    Header: 'Task Name',
    accessor: 'task_name'
  },
  {
    Header: 'Task Type',
    accessor: 'task_type_name'
  },
  {
    Header: 'Assigned To',
    accessor: 'task_owner_name'
  },
  {
    Header: 'Due Date',
    accessor: 'task_due_date',
    Cell: row =>
      row.original.task_due_date ? (
        <span>
          <FormattedDate value={row.original.task_due_date} />{' '}
          <FormattedTime value={row.original.task_due_date} />
        </span>
      ) : (
        <span />
      )
  }
]

const TableInner = props => (
  <Table {...props} columns={availableColumns} getTrProps={getTrProps} />
)

export default TableInner
