import React from 'react'
import moment from 'moment'
import crmAnalyticsTableHoc, { AnalyticsTableHocProps, AnalyticsTableHocInnerProps } from './analytics-table-hoc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp'
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown'
import { get } from 'lodash'

export function pad(num) {
  return ('0' + num).slice(-2)
}

export function hhmmss(sec) {
  if (!sec || isNaN(Number(sec))) return undefined

  let secs = sec
  let minutes = Math.floor(secs / 60)
  secs %= 60
  const hours = Math.floor(minutes / 60)
  minutes %= 60

  return `${hours > 0 ? `${hours}:` : ``}${pad(minutes)}:${pad(secs)}`
}

export type AnalyticsTableProps = AnalyticsTableHocProps & AnalyticsTableHocInnerProps

class AnalyticsTableInner extends React.PureComponent<AnalyticsTableProps, any> {
  static defaultProps = {
    generateSummary: true
  }

  buildClassesFromColumn = col => {
    const ret = []

    switch (col.headerAlign) {
      case 'left':
        ret.push('CrmAnalyticsTable__Cell--alignLeft')
        break
      case 'center':
        ret.push('CrmAnalyticsTable__Cell--alignCenter')
        break
      case 'right':
        ret.push('CrmAnalyticsTable__Cell--alignRight')
        break
      default:
    }

    if (col.detailsType) {
      ret.push('CrmAnalyticsTable__Cell--expandable')
    }

    if (col.type) {
      ret.push(`'CrmAnalyticsTable__Cell--'${col.type}`)
    }

    if (col.styleHighlighted) {
      ret.push('CrmAnalyticsTable__Cell--highlighted')
    }

    return ret.join(' ')
  }

  handleHeaderClick = event => {
    const key = event.currentTarget.id
    if (key) {
      this.props.onHeaderClick(key)
    }
  }

  renderHeader() {
    const { columns } = this.props

    return (
      <thead
        className={`${'CrmAnalyticsTable__Header__Wrapper'} ${
          this.props.columnGroups ? 'CrmAnalyticsTable__Header--withColumnGroups' : ''
        }
        `}
      >
        {this.props.columnGroups && (
          <tr>
            {this.props.columnGroups.map(cg => (
              <th key={cg.id} colSpan={cg.colSpan} className={`${cg.className || ''}`}>
                <div className="util-textCenter">{cg.Header}</div>
              </th>
            ))}
          </tr>
        )}
        <tr className={'CrmAnalyticsTable__Header__HeadersRow'}>
          {columns.map((col, index) => {
            const props = {}
            const style: any = {}

            if (col.width) {
              style.width = col.width
              style.maxWidth = col.width
            }

            style.textAlign = col.align

            let classNames = this.buildClassesFromColumn(col)

            return (
              <th
                key={index}
                style={style}
                className={`${classNames} ${col.sticky ? 'CrmAnalyticsTable__Cell--sticky' : ''}
                  ${col.styleHighlighted ? 'CrmFlatTable-summary' : ''}
                  ${col.headerClassName ? col.headerClassName : ''}
                  `}
                {...props}
                id={col.id}
                onClick={this.handleHeaderClick}
              >
                {col.headerRenderer ? (
                  col.headerRenderer()
                ) : (
                  <React.Fragment>
                    {col.Header}
                    {this.props.sortColumnId === col.id && (
                      <span style={{ marginLeft: 5 }}>
                        <FontAwesomeIcon icon={this.props.sortOrder === 'asc' ? faSortUp : faSortDown} />
                      </span>
                    )}
                  </React.Fragment>
                )}
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }

  getCellValue = (row, colDef) => {
    let value

    if (colDef.type === 'number') {
      let numValue

      if (colDef.accessorFn) {
        numValue = Number(colDef.accessorFn(row))
      } else {
        numValue = Number(row[colDef.accessor])
      }

      if (!isNaN(numValue)) {
        numValue = numValue.toLocaleString(undefined, {
          maximumFractionDigits: colDef.maximumFractionDigits || 0
        })
        value = numValue
      }
    } else if (colDef.type === 'date') {
      const dateValue = colDef.accessorFn ? colDef.accessorFn(row) : row[colDef.accessor]
      value = moment(dateValue).format('YYYY-MM-DD')
    } else {
      value = colDef.accessorFn ? colDef.accessorFn(row) : row[colDef.accessor]
    }

    if (colDef.type === 'time') {
      if (typeof value === 'undefined') return

      const timeFormat = colDef.format || 'HH:mm'
      const t = moment(value)
      value = t.format(timeFormat)
    } else if (colDef.type === 'interval') {
      value = hhmmss(value)
    }

    return value
  }

  handleCellClick = event => {
    const data = event.currentTarget.dataset
    const colSpec = this.props.columns.find(c => c.id === data.columnId)
    let row = this.props.data.find(r => r.id == data.rowId)

    if (!row) {
      row = this.props.summaryRows.find(r => r.id == data.rowId)
    }

    if (!colSpec?.detailsType) return

    this.props.onExpandCell({
      rowId: data.rowId,
      columnId: data.columnId
    })
    if (!colSpec?.onClick) return
    colSpec.onClick(row, colSpec)
  }

  renderExpandedDetails = (row, isExpanded) => {
    const DetailsRenderer = get(this.props.detailsRenderers, this.props.expandedCell.column.detailsType, undefined)
    const detailsType = this.props.expandedCell.column.detailsType
    const cell = {
      row: this.props.expandedCell.row,
      column: this.props.expandedCell.column
    }

    return (
      <tr
        style={{ border: 0, padding: 0 }}
        className={`${'CrmAnalyticsTable__ExpandedDetails'}
        ${isExpanded ? 'CrmAnalyticsTable__ExpandedDetails--expanded' : ''}`}
      >
        <td style={{ border: 0, padding: 0 }} colSpan={this.props.columns.length}>
          <div className={'CrmAnalyticsTable__ExpandedDetails__Inner'}>
            {isExpanded && (
              <div style={{ height: 300, minHeight: 300 }}>
                {DetailsRenderer ? (
                  <DetailsRenderer cell={cell} />
                ) : (
                  <div>Don't know how to render details for type ({detailsType})</div>
                )}
              </div>
            )}
          </div>
        </td>
      </tr>
    )
  }

  _renderThousandsSeparate = number => {
    return Number(number).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      useGrouping: true
    })
  }

  _renderIntFormat = number => {
    return Number(number).toLocaleString()
  }

  _renderCell = (col, row) => {
    const handleClick = () => {
      if (col.onClick) {
        col.onClick(row, col)
      }
    }

    const additionalClassNames = col.classNameHandler ? col.classNameHandler({ original: row }) : ''
    const isInt = number => number === +number && number === (number | 0)

    if (col.Cell && typeof col.Cell === 'function') {
      let renderedCell = col.Cell({ original: row, onClick: handleClick }) // eslint-disable-line

      const isFloat = !isNaN(renderedCell) && renderedCell?.toString()?.indexOf('.') !== -1
      const isInteger = isInt(renderedCell)
      const isNumeral = isFloat || isInteger

      if (isInteger) {
        renderedCell = this._renderIntFormat(Number(renderedCell))
      } else if (isFloat) {
        renderedCell = this._renderThousandsSeparate(Number(renderedCell))
      }

      if (typeof renderedCell === 'string' || typeof renderedCell === 'number') {
        return (
          <div className={` ${additionalClassNames} ${isNumeral && 'util-textRight'}`} onClick={handleClick}>
            {renderedCell}
          </div>
        )
      }
    }

    if (col.accessor === 'account_name') {
      return (
        <a id={row ? row.account_uid : 'empty'} href={'/crm/account/' + row.account_uid} target="_blank">
          {row[col.accessor]}
        </a>
      )
    }

    const cellValue = this.getCellValue(row, col)

    return (
      <div className={`${additionalClassNames}`} onClick={handleClick}>
        {cellValue}
      </div>
    )
  }

  renderRow = (row, useTh = false, rowIndex = 0) => {
    const { columns } = this.props
    const isRowExpanded = get(this.props, 'expandedCell.rowId', null) === row.id
    let rowClassNames = ''

    if (isRowExpanded) {
      rowClassNames += 'CrmAnalyticsTable__Row--expanded'
    }

    return (
      <React.Fragment key={rowIndex}>
        <tr className={rowClassNames}>
          {columns.map((col, index) => {
            let classNames = this.buildClassesFromColumn(col)
            const cellProps = {
              onClick: row[col?.accessor] && this.handleCellClick,
              'data-column-id': col.id,
              'data-row-id': row.id
            }

            if (
              get(this.props, 'expandedCell.rowId', null) === row.id &&
              get(this.props, 'expandedCell.columnId', null) === col.id
            ) {
              classNames += 'CrmAnalyticsTable__Cell--expanded'
            }

            if (col.sticky) {
              return (
                <th
                  key={index}
                  className={`${classNames} ${col.sticky ? 'CrmAnalyticsTable__Cell--sticky' : ''}`}
                  {...cellProps}
                >
                  {row[col.accessor]}
                </th>
              )
            }

            return useTh ? (
              <th key={index} className={classNames} {...cellProps}>
                {this._renderCell(col, row)}
              </th>
            ) : (
              <td key={index} className={classNames} {...cellProps}>
                {this._renderCell(col, row)}
              </td>
            )
          })}
        </tr>
        {get(this.props, 'expandedCell.rowId', null) === row.id && this.renderExpandedDetails(row, isRowExpanded)}
      </React.Fragment>
    )
  }

  renderBody() {
    return (
      <React.Fragment>
        <tbody className="CrmAnalyticsTable__Body">
          {this.props.data && this.props.data.map((row, index) => this.renderRow(row, false, index))}
        </tbody>
        {this.props.generateSummary && this.props.summaryRows && (
          <tfoot>{this.props.summaryRows.map(row => this.renderRow(row, true))}</tfoot>
        )}
      </React.Fragment>
    )
  }
  render() {
    return (
      <div className="CrmAnalyticsTable__Wrapper CrmAnalyticsTable--themeCartrack">
        <table
          className={`CrmAnalyticsTable__Table ${this.props.expandedRow ? 'CrmAnalyticsTable__Table--expanded' : ''}`}
        >
          {this.renderHeader()}
          {this.renderBody()}
        </table>
      </div>
    )
  }
}

export const AnalyticsTable: any = crmAnalyticsTableHoc(AnalyticsTableInner)
export default AnalyticsTable
