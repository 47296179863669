/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import { shape, func, string } from 'prop-types'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import CrmWidget from '../../../components/crm-widget.jsx'

import { arraySum } from 'crm-utils/data-utils'
import { CrmHeatmapChart } from 'crm-components'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import gql from 'graphql-tag'

const heatMapQuery = gql`
  query master(
    $master_uid: String!
    $instance_uid: String!
    $filter: JSON
    $groupBy: [String]
    $aggregate: [JSON]
  ) {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        activities(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
          edges {
            edge {
              activity_uid
              created_by_user_uid
              creator {
                full_name
              }
              call_length
              disposition_type_uid
              disposition_type {
                name
              }
              status {
                name
              }
              activity_status_uid
            }
          }
        }
      }
    }
  }
`

class WidgetAgentsDispositionsHM extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderSummary = () => (
    <div>
      {this.state.totalDispositions && (
        <span>Total Dispositions: {this.state.totalDispositions}</span>
      )}
    </div>
  )

  handleRefresh = async () => {
    try {
      const filter = mapContextToFilter(this.props, true)
      const heatMapVariables = {
        master_uid: this.props.crmMaster?.master_uid,
        instance_uid: this.props.crmInstance?.instance_uid,
        groupBy: [
          'created_by_user_uid',
          'disposition_type_uid'
        ],
        aggregate: [
          { id: 'activity_uid', type: 'count' },
          { id: 'call_length', type: 'sum' }
        ],
        filter: {
          activity_type: {
            medium_code: 'phone_call'
          },
          ...filter
        }
      }
      const { loading, data } = await this.props.client.query({
        query: heatMapQuery,
        variables: heatMapVariables,
        fetchPolicy: 'no-cache'
      })

      if (!loading) {
        const ldata =
          data?.master?.instance?.activities?.edges
            ?.map(e => e.edge)
            ?.map(e => ({
              created_by__full_name: e.creator?.full_name,
              created_by_user_uid: e.created_by_user_uid,
              activity_uid: e.activity_uid * 1,
              count: e.activity_uid * 1,
              disposition_type_uid: e.disposition_type_uid,
              disposition__name: e.disposition_type?.name,
              status__name: e.status?.name
            })) ?? []
        this.setState({
          data: ldata,
          totalDispositions: arraySum(ldata, 'activity_uid')
        })
      }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }
  handleCellClicked = cell => {
    const filters = {
      ...this.props.analyticsContext.filters,
      disposition_type_uid: cell.disposition_type_uid,
      created_by_user_uid: cell.created_by_user_uid,
      ...cell
    }
    if (!cell.created_by__full_name) {
      delete filters.created_by_user_uid
    }
    if (!cell.disposition__name) {
      delete filters.disposition_type_uid
    }
    if (this.props.analyticsContext.filters.activity_date) {
      filters.start_time = this.props.analyticsContext.filters.activity_date
    }
    this.props.onAnalyticsRangeSelected({ type: 'disposition', filters })
  }
  handleGetRawData = () => this.state.data
  render() {
    const isCanRender = this.props.analyticsContext?.filters?.activity_date
    return (
      isCanRender ? <CrmWidget
        title={
          this.props.title ? this.props.title : 'Call dispositions by Agent'
        }
        subtitle="Matrix of dispositions made by telesales agents in selected period "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-agents-dispositions"
      >
        {!this.state.data && (
          <span>No data - select filters and press refresh to load</span>
        )}
        {this.state.data && isCanRender && (
          <CrmHeatmapChart
            xAxisField="disposition__name"
            yAxisField="created_by__full_name"
            valueField="activity_uid"
            data={this.state.data}
            onCellClicked={this.handleCellClicked}
            headerOrientation={'vertical'}
          />
        )}
      </CrmWidget> : (<span> Activity date is Required</span>)
    )
  }
}

WidgetAgentsDispositionsHM.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  title: string,
  onAnalyticsRangeSelected: func.isRequired
}
WidgetAgentsDispositionsHM.defaultProps = {
  title: undefined
}

export default withApollo(withCrmContext(WidgetAgentsDispositionsHM))
