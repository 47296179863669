import React from 'react'

class AnalyticsPivotTableWidget extends React.PureComponent<any, any> {
  _buildPivoTableData = () => {
    console.log('_buildPivoTableData')

    const dataSourceData = this.props.dataSourceData

    // const dimsColumns = tableData.columns.map(col => ({
    //   Header: () => {
    //     console.log('Log dynamic column header')
    //     console.dir(col)
    //     const title = col.value
    //     return (
    //       <div key={title} title={title}>
    //         {col.label}
    //       </div>
    //     )
    //   },
    //   accessor: 'dyamic_col',
    //   Cell: row => {
    //     // Advanced render here
    //     const innerVal =
    //       row.original.values && row.original.values[col.value]
    //         ? row.original.values[col.value].value
    //         : ''
    //     return row.original.code === 'sum' ? (
    //       <strong>{innerVal}</strong>
    //     ) : (
    //       <div>{innerVal}</div>
    //     )
    //   },
    //   minWidth: 20
    // }))
    // // Build row header columns
    // if (!this.state.rowsDims) {
    //   console.error('No state.rows_dims')
    //   return <div>No rows_dims</div>
    // }
    // console.dir(this.state)

    // const rowGroupColumns = this.state.rowsDims.map(rowDim => {
    //   const dimDef = this._getDimDefByCode(rowDim.dim_code)
    //   return {
    //     Header: dimDef ? dimDef.name : rowDim.dim_code,
    //     Cell: row => {
    //       console.log('Row Dim cell render')
    //       console.dir(row)
    //       console.dir(rowDim)
    //       return (
    //         <div>
    //           {row.original.rowKey &&
    //           row.original.rowKey.dim_code === rowDim.dim_code ? (
    //             row.original.rowKey.label
    //           ) : (
    //             ''
    //           )}
    //         </div>
    //       )
    //     },
    //     minWidth: 150
    //   }
    // })
    // // Build summary column
    // const summaryColumn = {
    //   Header: 'Sum',
    //   Cell: row => (
    //     <div>
    //       <strong>{row.original.sum}</strong>
    //     </div>
    //   ),
    //   maxWidth: 50
    // }
    // const finalColumns = [...rowGroupColumns, ...dimsColumns, summaryColumn]
  }

  render() {
    console.log('PivotTable ', this.props)
    return <div>Pivot Table</div>
  }
}

export default AnalyticsPivotTableWidget
