/* eslint-disable */
import React, { PureComponent } from 'react'
import { shape, func, bool } from 'prop-types'
import ReportReduxForm from './report-form.jsx'
import { CrmVbox } from 'crm-components'

class ReportQuickView extends PureComponent {
  handleSave = values => {
    console.log('handleSave', values)
    this.props.onSaveEditing(values)
  }

  handleClosePreview = () => {
    console.log('handleClosePreview')
    this.props.onClose()
  }

  render() {
    return (
      <CrmVbox
        className="util-padding-md util-flexGrow"
        style={{ overflowY: 'auto' }}
      >
        <div>
          <span>Report preview </span>
          <button onClick={this.handleClosePreview}>Close preview</button>
        </div>
        <div style={{ flex: 1, position: 'relative' }}>
          <ReportReduxForm
            editing={this.props.editing}
            creating={this.props.creating}
            enableReinitialize="true"
            initialValues={this.props.resource}
            onSubmit={this.handleSave}
            onEdit={this.props.onEdit}
            onCancel={this.props.onCancelEdit}
            onReset={this.props.onReset}
            resource={this.props.resource}
          />
        </div>
      </CrmVbox>
    )
  }
}

ReportQuickView.defaultProps = {
  report: undefined
}

ReportQuickView.propTypes = {
  creating: bool.isRequired,
  editing: bool.isRequired,
  resource: shape({}),
  onCancelEdit: func.isRequired,
  onSaveEditing: func.isRequired,
  onEdit: func.isRequired,
  onClose: func.isRequired,
  onReset: func.isRequired
}

export default ReportQuickView
