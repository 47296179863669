/* eslint-disable */
export const FETCH_TEMPLATE_SETTING = 'FETCH_TEMPLATE_SETTING'
export const RECEIVE_TEMPLATE_SETTING = 'RECEIVE_TEMPLATE_SETTING'
export const GET_TEMPLATE_SETTING_BY_ID = 'GET_TEMPLATE_SETTING_BY_ID'
export const RECEIVE_TEMPLATE_SETTING_BY_ID = 'RECEIVE_TEMPLATE_SETTING_BY_ID'
export const START_EDITING_TEMPLATE = 'START_EDITING_TEMPLATE'
export const START_CREATING_TEMPLATE = 'START_CREATING_TEMPLATE'
export const SAVING_TEMPLATE = 'SAVING_TEMPLATE'
export const SAVING_TEMPLATE_SUCCESS = 'SAVING_TEMPLATE_SUCCESS'
export const SUBMIT_TEMPLATE_PREVIEW = 'SUBMIT_TEMPLATE_PREVIEW'
export const RECEIVE_TEMPLATE_PREVIEW = 'RECEIVE_TEMPLATE_PREVIEW'
// Reducer
const initialState = {
  lists: [],
  fetch: false,
  fetchDetail: false,
  template: undefined,
  saving: false,
  preview: undefined,
  fetchPreview: false,
  output: undefined
}

function findTemplate(id, list) {
  let templateEdit = undefined
  list.forEach(template => {
    console.log('findTemplate', template.template_uid, id)
    if (template.template_uid === id) {
      templateEdit = template
    }
  })

  return templateEdit
}

export default function templateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEMPLATE_SETTING:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_TEMPLATE_SETTING:
      return {
        ...state,
        fetch: false,
        lists: action.payload.templates
      }
    case START_CREATING_TEMPLATE:
      return {
        ...state,
        template: {}
      }
    case GET_TEMPLATE_SETTING_BY_ID:
      let fetchDetail = true
      if (action.templateId === 'new') {
        fetchDetail = false
      }

      return {
        ...state,
        preview: undefined,
        template: undefined,
        output: undefined,
        fetchDetail
      }

    case RECEIVE_TEMPLATE_SETTING_BY_ID:
      return {
        ...state,
        fetchDetail: false,
        template: formatJson(action.payload.template)
      }
    case SAVING_TEMPLATE:
      return {
        ...state,
        saving: true
      }
    case SUBMIT_TEMPLATE_PREVIEW:
      return {
        ...state,
        fetchPreview: true,
        preview: undefined,
        output: action.outputType
      }
    case RECEIVE_TEMPLATE_PREVIEW:
      return {
        ...state,
        fetchPreview: false,
        preview: action.payload.output
      }
    default:
      return state
  }
}

function formatJson(values) {
  const template = Object.assign({}, values[0])
  template.json = JSON.stringify(JSON.parse(template.json_data), null, 2)
  return template
}

function jsonDataToJson(values) {
  const template = Object.assign({}, values)
  template.json = JSON.parse(template.json)
  return template
}

export function fetchData() {
  return {
    type: FETCH_TEMPLATE_SETTING
  }
}

export function getTemplateItem(templateId) {
  return {
    type: GET_TEMPLATE_SETTING_BY_ID,
    templateId
  }
}

export function startEditingItem(templateId) {
  return {
    type: START_EDITING_TEMPLATE,
    templateId
  }
}

export function startCreatingItem() {
  return {
    type: START_CREATING_TEMPLATE
  }
}

export function savingTemplate(formValues) {
  return {
    type: SAVING_TEMPLATE,
    formValues: jsonDataToJson(formValues)
  }
}

export function previewTemplate(formValues, outputType) {
  formValues.output = outputType
  return {
    type: SUBMIT_TEMPLATE_PREVIEW,
    outputType,
    formValues: jsonDataToJson(formValues)
  }
}

// export function cancelEditingItem() {
//   return {
//     type: CANCEL_EDITING_REPORT
//   }
// }

// export function cancelCreatingItem() {
//   return {
//     type: CANCEL_CREATING_REPORT
//   }
// }

// export function saveCreatingItem(formValues) {
//   return {
//     type: SAVE_CREATING_REPORT,
//     formValues: definitionToJson(formValues)
//   }
// }

// export function saveEditingItem(formValues) {
//   return {
//     type: SAVE_EDITING_REPORT,
//     formValues: definitionToJson(formValues)
//   }
// }
