import React from 'react'

import styles from './sales-call-inner.scss'
import { FormattedMessage } from 'react-intl'
import {
  CrmOptionButtons,
  CrmDayHourPickerInput,
  CrmDropdown
} from 'crm-components'

const prioritiesOptions = [
  { name: 'Normal', value: '2' },
  { name: 'Higher', value: '4' },
  { name: 'Highest', value: '9' }
]

class OptionScheduleCallback extends React.PureComponent {
  isManualCallback = () =>
    this.props.resultData.callback && this.props.resultData.callback.manual
  toggleManualCallback = () => {
    console.log('toggleManualCallback')
    const newResultData = {
      callback: {
        manual: !this.isManualCallback()
      }
    }
    this.props.onResultDataChange(newResultData)
  }
  handleUpdateCallbackData = data => {
    const newResultData = {
      callback: {
        ...(this.props.resultData && this.props.resultData.callback
          ? this.props.resultData.callback
          : {}),
        ...data
      }
    }
    this.props.onResultDataChange(newResultData)
  }
  handleCallbackDateChange = val => {
    this.handleUpdateCallbackData({ callbackTime: new Date(val) })
  }
  handlePriorityChanged = value => {
    this.handleUpdateCallbackData({ priority: new String(value) })
  }
  render() {
    const { resultData } = this.props
    const callback =
      resultData && resultData.callback
        ? resultData.callback
        : { manual: false, __version: 1 }
    console.log('Render callback', callback)
    return (
      <div
        className={`util-marginMd SalesCallInnerOptionsBox ${
          this.isManualCallback() ? 'SalesCallInnerOptionsBox--enabled' : ''
        }`}
      >
        <div className="row">
          <div className="SalesCallInnerOptionsBox--label">Callback</div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={this.toggleManualCallback}
          >
            {this.isManualCallback() ? 'Manual' : ' Auto'}
          </div>
        </div>
        {this.isManualCallback() ? (
          <React.Fragment>
            <div>Setup Calback</div>
            <CrmDayHourPickerInput
              placeholder={
                <FormattedMessage
                  id="crm.ui.activity.form.next_call_date"
                  defaultMessage="Next Call Date"
                />
              }
              input={{
                onChange: this.handleCallbackDateChange,
                value: callback.callbackTime
              }}
            />
            <CrmDropdown
              placeholder={
                <FormattedMessage
                  id="crm.ui.activity.form.priority"
                  defaultMessage="Priority"
                />
              }
              options={prioritiesOptions}
              input={{
                onChange: this.handlePriorityChanged,
                value: callback.priority
              }}
            />
          </React.Fragment>
        ) : (
          'Auto Callback'
        )}
      </div>
    )
  }
}

class OptionReassign extends React.PureComponent {
  isManualCallback = () =>
    this.props.resultData.callback && this.props.resultData.callback.manual
  toggleManualCallback = () => {
    console.log('toggleManualCallback')
    const newResultData = {
      reassign: {
        value: true
      }
    }
    this.props.onResultDataChange(newResultData)
  }
  render() {
    return (
      <div
        className="util-marginMd SalesCallInnerOptionsBox"
        onClick={this.toggleManualCallback}
      >
        <div className="SalesCallInnerOptionsBox--label">Reassign</div>
        {this.isManualCallback() ? 'Y' : 'N'}
      </div>
    )
  }
}

class Step3NextAction extends React.PureComponent {
  handleTypeChanged = val => {
    console.log('handleTypeChanged', val)
    const newResultData = {
      firstValidationResult: {
        value: val
      }
    }
    this.props.onResultDataChange(newResultData)
  }
  render() {
    const { activity, resultData } = this.props
    const options = [
      { value: 'valid', label: 'Valid' },
      { value: 'callback', label: 'Callback' },
      { value: 'bogus', label: 'Bogus' }
    ]
    return (
      <div className={styles.SalesCallInnerStep}>
        <div className={styles.StepLabel}>Next Action</div>
        <div className="row">
          <div className="col-md-4">
            <OptionScheduleCallback {...this.props} />
          </div>
          <div className="col-md-4">
            <OptionReassign {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

export default Step3NextAction
