/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { shape, func, string, arrayOf, object, oneOfType } from 'prop-types'
import CrmWidget from '../../../components/crm-widget.jsx'
import widgetFitmentsByDateHoc from './widget-fitments-by-date-hoc'
import { FlatTable } from 'crm-components'

class WidgetFitmentsByDate extends React.PureComponent {
  constructor(props) {
    super(props)
    this.columns = [
      {
        accessor: 'completed_date',
        Header: 'Fitment Date'
      },
      {
        accessor: 'quantity',
        Header: 'Units Fitted',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <span className="util-textRight" onClick={handleClick}>
              {row.original.quantity}
            </span>
          )
        }
      }
    ]
  }

  handleRefresh = async () => {
    try {
      return await this.props.onRefresh()
    } catch (err) {
      toastr.error('Problem getting stats')
      // console.error(err)
    }
  }

  handleCellClicked = cell => {
    // console.log('handleCellClicked', cell)
    const filters = {
      ...this.props.analyticsContext.filters
    }

    if (cell.completed_date === 'Total') {
      //call with Total get activity_date from main filter
      filters.activity_date = this.props.analyticsContext.filters.activity_date;
    } else {
      filters.activity_date = {
        value: { $gte: cell.completed_date, $lte: cell.completed_date }
      }
    }
    this.props.onAnalyticsRangeSelected({ type: 'fitment', filters })
  }
  handleGetRawData = () => this.props.data

  render() {
    console.log('Render widget new leads by current status', this.props)
    return (
      <CrmWidget
        title={this.props.title ? this.props.title : ' Fitments by Date'}
        subtitle=" "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-new-leads-by-status"
      >
        {!this.props.analyticsContext.filters.activity_date && (
          <span>You have to select activity date range filter </span>
        )}

        {this.props.data && (
          <FlatTable
            columns={this.columns}
            data={this.props.data}
            onCellClicked={this.handleCellClicked}
            sortField="completed_date"
            sortOrder="asc"
          />
        )}
      </CrmWidget>
    )
  }
}

WidgetFitmentsByDate.propTypes = {
  analyticsContext: shape({}).isRequired,
  data: oneOfType([func, object]),
  onAnalyticsRangeSelected: func.isRequired,
  title: string,
  onRefresh: func.isRequired
}
WidgetFitmentsByDate.defaultProps = {
  title: undefined,
  data: arrayOf().isRequired
}

export default widgetFitmentsByDateHoc(WidgetFitmentsByDate)
