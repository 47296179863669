import { graphql, compose } from 'react-apollo'
import { qlqAccountDocuments } from 'crm-data/documents'

const qlOptions = {
  // eslint-disable-next-line
  options: ownProps => ({ variables: { account_uid: ownProps.accountUid } }),
  props: ({ documentsQl, ownProps }) => ({
    ...ownProps,
    documents:
      documentsQl && documentsQl.account
        ? documentsQl.account.documents
        : undefined,
    isLoading: documentsQl ? documentsQl.loading : false
  })
}
const documentsListQLHOC = compose(
  graphql(qlqAccountDocuments, {
    ...qlOptions,
    name: 'documentsQl'
  })
)

export default documentsListQLHOC
