import React, { useState, useEffect } from 'react'
import { CrmModal } from '@cartrack-crm/ui'
import { CrmJsonEditArea } from 'crm-components'

import {
  FilterSectionActivityDate,
  FilterSectionCampaign,
  FilterSectionLead,
  FilterSectionOrganizationUnit,
  FilterSectionUser
} from '@cartrack-crm/crm-filter-section'

interface IFilterManager {
  onClose?: () => any
  filters?: any
  onSave?: Function
  isOpen?: boolean
}

export const FilterManager: React.SFC<IFilterManager> = (props) => {
  const { onClose, filters, onSave, isOpen } = props
  const [currentFilter, setCurrentFilter] = useState(filters)
  useEffect(() => {
    setCurrentFilter(props.filters)
    console.log('FilterManager.useEffect', currentFilter)
  }, [props])

  const handleFilterValueChange = (pfilter) => {
    console.log('FilterManager.handleFilterValueChange', pfilter)
    setCurrentFilter({ ...currentFilter, ...pfilter })
  }
  const handleChangeFilterFromEditor = (event) => {
    console.log('FilterManager.handleChangeFilterFromEditor', event)
    setCurrentFilter(event)
  }

  const handleSaveFilter = () => {
    const pFilter = { ...currentFilter }
    console.log('FilterManager.handleSaveFilter', pFilter)
    if (onSave) {
      onSave(pFilter)
      onClose()
    }
  }
  console.log('FilterManager.PRender', currentFilter)
  return (
    <CrmModal
      title="Filter Manager"
      isOpen={isOpen}
      isShowCloseBtn
      onClose={onClose}
      size="xl"
      showFooter
      primaryButtonLabel="Save"
      secondaryButtonLabel="Cancel"
      onPrimaryButton={handleSaveFilter}
      onSecondaryButton={onClose}
    >
      <div className="util-fullHeight util-flexColumn">
        <div className="util-flexRow">
          <div className="util-flexGrow">
            <FilterSectionLead />
          </div>
          <div>
            <FilterSectionOrganizationUnit />
          </div>
        </div>
        <div className="util-flexRow">
          <div className="util-flexGrow">
            <FilterSectionUser />
          </div>
        </div>
        <div className="util-flexRow">
          <div style={{ flex: 8 }}>
            <FilterSectionActivityDate />
          </div>
          <div style={{ flex: 6 }}>
            <FilterSectionCampaign />
          </div>
        </div>
        <CrmJsonEditArea
          input={{
            value: currentFilter,
            onChange: handleChangeFilterFromEditor
          }}
        />
      </div>
    </CrmModal>
  )
}
