import React from 'react'
import { string, shape, bool } from 'prop-types'
import EventHistoryList from 'crm-modules/events/events-history-list.jsx'

const AccountHistoryList = props => {
  const { accountUid, account, showOnlyOpportunityEvents } = props
  const aggregates = []
  if (showOnlyOpportunityEvents !== true) {
    aggregates.push({
      type: 'account',
      aggregate_uid: accountUid
    })
    aggregates.push({
      type: 'salesAccount',
      aggregate_uid: accountUid
    })
  }
  if (account && account.opportunities) {
    account.opportunities.forEach(opportunity => {
      aggregates.push({
        type: 'opportunity',
        aggregate_uid: opportunity.opportunity_uid
      })
    })
  }
  aggregates.push({ type: 'task', account_uid: accountUid });
  return <EventHistoryList aggregates={aggregates} />
}

AccountHistoryList.propTypes = {
  accountUid: string.isRequired,
  account: shape,
  showOnlyOpportunityEvents: bool
}
AccountHistoryList.defaultProps = {
  account: undefined,
  showOnlyOpportunityEvents: false
}

export default AccountHistoryList
