import { CrmLabeledField } from 'crm-components'
import generateInlineEditableField from 'crm-components/hoc/inline-editable-component-web-hoc'
import editQuotationHoc, {
  saveHandler
} from 'crm-modules/quotations/hoc/edit-quotation-hoc.js'
import React from 'react'

interface IProps {
  fieldName: string
  label: string
  id: string
  value: any
  displayValue: any
  editable: boolean
  emptyValue: string
  readModeStyle: string
  renderColAction: Function
  item: any
  index: string
  isLastRow: string
  productType: string
}

class QuotationInlineEditable extends React.PureComponent<IProps> {
  renderInner_CrmLabeledField() {
    const { label, value, displayValue, emptyValue } = this.props
    return (
      <CrmLabeledField label={label} noMargin>
        {(!Array.isArray(value) && value) ||
        (Array.isArray(value) && value.length > 0) ? (
          <div className="">{displayValue || value}</div>
        ) : (
          <div className="LabeledField-label">{emptyValue}</div>
        )}
      </CrmLabeledField>
    )
  }

  renderInner_NoStyle() {
    const { value, displayValue, emptyValue } = this.props
    if (displayValue) {
      return <div className="LabeledField-value">{displayValue || value}</div>
    } else {
      return <div className="LabeledField-label">{emptyValue}</div>
    }
  }

  renderInner_ProductItem() {
    const { item, index, isLastRow, productType, editable } = this.props
    return (
      <table style={{ width: '100%' }}>
        <tr>
          <td className="LabeledField-value colFirst colProduct">
            {item.product ? item.product.name : ''}
          </td>
          <td className="LabeledField-value colProductType">{productType}</td>
          <td className="LabeledField-value colDiscount">{item.discount}</td>
          <td className="LabeledField-value colQuantity">{item.quantity}</td>
          <td className="LabeledField-value colHardwarePrice util-textRight">
            {item.value_net}
          </td>
          <td className="LabeledField-value colSubscriptionPrice util-textRight">
            {item.subscription_net}
          </td>
          <td className="LabeledField-value colSubscriptionPriceNextYears util-textRight">
            {item.subscription_net_next_years}
          </td>
          <td className="LabeledField-value colTaxRate">
            {item.product ? item.product.tax_rate : ''}
          </td>
          <td className="colAction">
            {this.props.renderColAction &&
              editable &&
              this.props.renderColAction(index, isLastRow)}
          </td>
        </tr>
      </table>
    )
  }

  render() {
    const { readModeStyle } = this.props
    if (readModeStyle === 'CrmLabeledField') {
      return <div>{this.renderInner_CrmLabeledField()}</div>
    } else if (readModeStyle === 'productItem') {
      return this.renderInner_ProductItem()
    } else {
      return <div>{this.renderInner_NoStyle()}</div>
    }
  }
}

interface QuotationEditComponentProps {
  fieldName: string
  input: any
  isSavingInlineEdit: boolean
  editComponentProps: any
  editComponentWrapperStyles: any
  editComponent: any
}

class QuotationEditComponent extends React.PureComponent<
  QuotationEditComponentProps
> {
  handleValueChange = (object, data1, data2) => {
    if (this.props.fieldName === 'product_uid') {
      this.props.input.onChange(data2)
      return
    }
    if (this.props.fieldName === 'productItem') {
      this.props.input.onChange(object)
      return
    }
    const textFields = [
      'name',
      'internal_note',
      'note',
      'discount',
      'quantity',
      'value_net',
      'subscription_net',
      'subscription_net_next_years'
    ]
    const dropDownFields = [
      'contract_length',
      'sale_type',
      'currency_code',
      'is_show_term_and_condition'
    ]
    const dateFields = ['document_date', 'contractWinDate']
    if (textFields.includes(this.props.fieldName)) {
      this.props.input.onChange(object.currentTarget.value)
    } else if (dropDownFields.includes(this.props.fieldName)) {
      this.props.input.onChange(object)
    } else if (dateFields.includes(this.props.fieldName)) {
      this.props.input.onChange(object)
    } else {
      this.props.input.onChange(object)
    }
  }
  render() {
    console.log('Render QuotationEditComponent', this.props)
    return (
      <div
        style={
          this.props.isSavingInlineEdit
            ? {}
            : this.props.editComponentWrapperStyles
        }
      >
        {/* <CrmValidatedField
          name={this.props.name}
          component={this.props.editComponent}
          componentProps={{ ...this.props.editComponentProps }}
          isRequired={this.props.isRequired}
          input={{
            value: this.props.input.value,
            onChange: this.handleValueChange
          }}
        /> */}
        <this.props.editComponent
          {...this.props.editComponentProps}
          input={{
            value: this.props.input.value,
            onChange: this.handleValueChange
          }}
        />
      </div>
    )
  }
}

const generateInlineEditableParams: any = {
  editComponent: editQuotationHoc(QuotationEditComponent),
  saveHandler
}

const inlineEditable = generateInlineEditableField(
  QuotationInlineEditable,
  generateInlineEditableParams
)
export default inlineEditable
