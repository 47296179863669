import React, { useState } from 'react'
import { CrmButton } from '@cartrack-crm/ui'
import { useRunApiWithToastr } from '@cartrack-crm/ui/src/toastrs/useRunApiWithToastr'
import {
  useCrmToastr,
  CrmToastrType,
  CrmToastrPosition
} from '@cartrack-crm/ui/src/toastrs'
import { ComponentSectionDataSources } from '../component-section-data-sources'
import cloneDeep from 'clone-deep'
import { ComponentSectionAllJson } from '../component-section-all-json'
import { JsonEditorRenderCard } from '../json-editable'
import LayoutPanel from '../component-section-layout/layout-panel'
import { mockSchema } from '../mock-schema-type-data'
import {
  useComponentConfigurationContext
} from './component-configuration-context'
import uuid from 'uuid'

type WidgetJsonConfigurationProps = {
  onSaveDashboard: Function
  widget: any
  onSaved: Function
  saveDashboard: Function
  runQuery: () => any
  renderPreview: any
  onOpenFilter?: () => any
  onCloseFilter?: () => any
  renderFilter?: (data, onSave) => any
}
export const WidgetJsonConfiguration: React.SFC<WidgetJsonConfigurationProps> = (
  props
) => {
  const widgetConfigurationContext = useComponentConfigurationContext()
  const component = widgetConfigurationContext.component
  const [toastrWithCommand] = useRunApiWithToastr()
  const [showToarst, hideToarst] = useCrmToastr()
  const isShowPublishBtn = !component?.is_published && component?.dashboard_uid
  const isShowUnPublish = component?.is_published && component?.dashboard_uid

  const handleSaveJson = (
    pJson,
    type: 'all' | 'data_source' | 'filter' | 'layout' = 'all',
    index = null
  ) => {
    let cWidget = cloneDeep(component)
    if (type === 'all') {
      cWidget = pJson
    } else if (type === 'data_source') {
      cWidget.content.data.dataSources[index] = pJson
    } else if (type === 'layout') {
      cWidget.content.layout.items[index] = pJson
    }
    toastrWithCommand(
      () => {
        widgetConfigurationContext.setComponentDefinition(cWidget)
      },
      {
        successMessage: 'Save Definition Success',
        errorMessage: 'Save Definition Fail'
      }
    )
  }

  const saveWidget = async () => {
    const currentJson = cloneDeep(component)
    let type: 'create' | 'update' = currentJson?.component_uid ? 'update' : 'create'
    if (type === 'create') {
      currentJson.component_uid = uuid()
    }
    const { loading, data, error } = await props.saveDashboard(currentJson, type)
    if (!loading && !error) {
      showToarst(CrmToastrType.success, 'Save Success')
      props.onSaved(currentJson)
    } else {
      showToarst(CrmToastrType.error, 'Save Failed', {
        position: CrmToastrPosition.middle,
        btnPrimaryTitle: 'ignore',
        textDetailTitle: 'advance',
        message: 'Failed to send Command to server',
        textDetailContent: error.message,
        onBtnPrimary: hideToarst,
        timeToShow: null
      })
    }
  }

  const handleSaveFilter = (newFilter) => {
    let cWidget = cloneDeep(component)
    cWidget.test_filters = { ...newFilter }
    widgetConfigurationContext.setComponentDefinition(cWidget)
  }

  const preSaveConfirm = () => {
    saveWidget()
    // TODO TON - show progress on save button
    // showToarst(CrmToastrType.info, 'Are you sure to save this data', {
    //   btnPrimaryTitle: 'Save',
    //   btnSecondaryTitle: 'Cancel',
    //   onBtnPrimary: saveWidget,
    //   onBtnSecondary: hideToarst,
    //   position: CrmToastrPosition.middle,
    //   timeToShow: null
    // })
  }

  const handlePublish = async () => {
    let currentJson = cloneDeep(component)
    currentJson.is_published = true
    const { loading, error } = await props.saveDashboard(currentJson, 'update')
    if (!loading && !error) {
      showToarst(CrmToastrType.success, 'Publish Success')
      props.onSaved(currentJson)
    } else {
      showToarst(CrmToastrType.error, 'Publish Failed', {
        position: CrmToastrPosition.middle,
        btnPrimaryTitle: 'ignore',
        textDetailTitle: 'advance',
        message: 'Failed to send Command to server',
        textDetailContent: error.message,
        onBtnPrimary: hideToarst,
        timeToShow: null
      })
    }
  }

  const handleSaveDataSource = async (dataSource, idx) => {
    console.log('handleSaveDataSource', dataSource, idx)
    // update
    let cWidget = cloneDeep(component)
    // ugly update by idx - will not work for add
    // TODO TON - more checks here
    cWidget.content.data.dataSources[idx] = dataSource
    widgetConfigurationContext.setComponentDefinition(cWidget)
  }

  const handleUnPublish = async () => {
    let currentJson = cloneDeep(component)
    currentJson.is_published = false
    const { loading, error } = await props.saveDashboard(currentJson, 'update')
    if (!loading && !error) {
      showToarst(CrmToastrType.success, 'Un Publish Success')
      props.onSaved(currentJson)
    } else {
      showToarst(CrmToastrType.error, 'Un Publish Failed', {
        position: CrmToastrPosition.middle,
        btnPrimaryTitle: 'ignore',
        textDetailTitle: 'advance',
        message: 'Failed to send Command to server',
        textDetailContent: error.message,
        onBtnPrimary: hideToarst,
        timeToShow: null
      })
    }
  }

  const tabs = [
    {
      label: 'All Definition',
      childComponent: (
        <ComponentSectionAllJson onSaveDefinition={handleSaveJson} />
      )
    },
    {
      label: 'Data Sources',
      childComponent: (
        <ComponentSectionDataSources
          component={component}
          runQuery={props.runQuery}
          onSaveDataSource={handleSaveDataSource}
        />
      )
    },
    {
      label: 'Layouts',
      childComponent: <LayoutPanel />
      // childComponent: (
      //   <JsonEditorRenderCard
      //     title="Layouts"
      //     data={widget?.content?.layout?.items ?? []}
      //     type="layout"
      //     textRow={35}
      //     onSave={handleSaveJson}
      //   />
      // )
    },
    {
      label: 'Test Filter Input',
      childComponent: (
        <JsonEditorRenderCard
          title="Filters"
          data={component?.filters ?? {}}
          type="filter"
          textRow={35}
          onSave={handleSaveFilter}
          onOpenFilter={props.onOpenFilter}
          onCloseFilter={props.onCloseFilter}
          renderFilter={props.renderFilter}
        />
      )
    }
  ]

  const analyticsContext = {
    filters: { ...component?.filters }
  }

  const renderTopToos = () => (
    <div className="util-flexRow">
      Is Changed ? {widgetConfigurationContext.isDirty ? 'YES' : 'NO'}
      <CrmButton
        label="Save Widget"
        className="util-marginMd"
        onClick={preSaveConfirm}
        type="primary"
        small
      />
      {isShowPublishBtn && (
        <CrmButton
          label="Publish"
          type="primary"
          onClick={handlePublish}
          className="util-marginMd"
          small
        />
      )}
      {isShowUnPublish && (
        <CrmButton
          label="Unpublish"
          type="primary"
          onClick={handleUnPublish}
          className="util-marginMd"
          small
        />
      )}
    </div>
  )

  return (
    <div
      className="util-paddingLg util-flexColumn util-fullHeight WidgetJsonConfiguration"
      style={{ backgroundColor: '#eee', overflow: 'hidden', height: '100%' }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <div style={{ flex: 1, overflow: 'auto' }} className="util-flexColumn">
          <div className="util-paddingMd">
            <strong>Preview</strong>
            {props.renderPreview && props.renderPreview(component)}
          </div>
        </div>
        <div
          style={{ width: '400px', overflowY: 'auto', padding: 10 }}
          className="util-flexColumn"
        >
          {renderTopToos()}
          {tabs.map((tab) => (
            <div style={{ marginBottom: 10 }}>{tab.childComponent}</div>
          ))}
          {/* <CrmTabNavigator
            tabs={tabs}
            selectedIndex={0}
            contentWrapperNoPadding
            style={{ overflowY: 'auto', height: '90%' }}
            widget={component}
          /> */}
        </div>
      </div>
    </div>
  )
}
