import React, { useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { CrmCheckbox, CrmModal, CrmButton } from '@cartrack-crm/ui'

export const DynamicTableColumnsSelector = props => {
  const [columnOptions, setColumnOptions] = useState(props.columnOptions)
  const [selectedFields, setSelectedFields] = useState(props.optionalColumns || [])
  const findIndex = (path: any, columnDefinition: any) => {
    const idx = selectedFields.findIndex(f => {
      return JSON.stringify(f.path) === JSON.stringify(path) && f.column.description === columnDefinition.description
    })
    return idx
  }
  const toggleField = (path, column) => {
    let newFields = [...selectedFields]
    const idx = findIndex(path, column)
    if (idx === -1) {
      newFields.push({
        path,
        column
      })
    } else {
      newFields = newFields.filter((el, index) => index !== idx)
    }
    setSelectedFields(newFields)
  }
  const toggleExpanded = (typeName, fieldName) => {
    const newOptions = [...columnOptions]
    const type = newOptions.find(t => t.type === typeName)

    if (type) {
      const field = type.fields ? type.fields.find(f => f.name === fieldName) : undefined
      if (field) {
        field.expanded = !field.expanded
      }
    }

    setColumnOptions(newOptions)
  }
  const renderOptions = (objectType, path) => {
    const optionsForType = columnOptions ? columnOptions.find(t => t.type === objectType) : undefined

    if (!optionsForType) {
      return <div>No options for type: {objectType}</div>
    }

    return (
      <div>
        {optionsForType.fields.map(fieldDefinition => {
          const isNested = fieldDefinition.type
          return (
            !path.includes(fieldDefinition.name) && (
              <div
                className={classNames('row', 'DynamicTableOptions__Field', {
                  DynamicTableOptions__FieldNested: isNested
                })}
              >
                {fieldDefinition.type ? (
                  <React.Fragment>
                    <div
                      className="DynamicTableOptions__FieldNested__Tools"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        toggleExpanded(objectType, fieldDefinition.name)
                      }}
                    >
                      <FontAwesomeIcon icon={fieldDefinition.expanded ? faChevronUp : faChevronDown} />
                    </div>
                    <div className="util-flexGrow util-marginLeft">
                      <strong>{fieldDefinition.description}</strong>
                      <div style={{ paddingLeft: 10 }}>
                        {fieldDefinition.expanded && (
                          <div>{renderOptions(fieldDefinition.type, [...path, fieldDefinition.name])}</div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="util-paddingSm">
                      <CrmCheckbox
                        input={{
                          onChange: () => {
                            toggleField(path, fieldDefinition)
                          },
                          value: findIndex(path, fieldDefinition) !== -1
                        }}
                        style={{ height: 'initial' }}
                      />
                    </div>
                    <div className="DynamicTableOptions__Field__Name util-flexGrow util-marginAuto">
                      <span>{fieldDefinition.description}</span>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )
          )
        })}
      </div>
    )
  }
  return (
    <CrmModal isOpen={props.isOpen} title="Table Columns" size="md" onClose={props.onClose}>
      <div className="util-flexColumn util-fullHeight">
        <div className="util-flexGrow util-paddingMd util-overflowYAuto">
          <div className="util-marginTop">
            <label>Available columns</label>
            {renderOptions(props.rootQLType, [])}
          </div>
        </div>
        <div className="CrmModal-footer util-flexRowCenter">
          <CrmButton label="Save" onClick={() => props.onSave(selectedFields)} />
        </div>
      </div>
    </CrmModal>
  )
}
