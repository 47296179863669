import React from 'react'
import { string } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'

const CrmCategoryProductsSelect = props => {
  const variables = {}
  const qlq = gql`
    query product_list_qm_paged(
      $limit: Int
      $offset: Int
      $sort: [JSON]
      $fields: [String]
      $aggregate: [JSON]
    ) {
      product_list_qm_paged(
        filter: { kind: "product_category" }
        limit: $limit
        offset: $offset
        sort: $sort
        fields: $fields
        aggregate: $aggregate
      ) {
        count
        data {
          product_uid
          instance_uid
          revenue_category_uid
          product_line_uid
          parent_product_uid
          code
          opportunity_unit_type
          revenue_type
          name
          description
          annuity_term
          billing_period_type_code
          is_taxable
          is_active
          kind
          __typename
        }
        __typename
      }
    }
  `
  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.product_list_qm_paged && data.product_list_qm_paged.data
            ? data.product_list_qm_paged.data.map(v => ({
                ...v,
                value: v.product_uid
              }))
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_product_category"
                  defaultMessage="Select Product Category"
                />
              )
            }
          />
        )
      }}
    </Query>
  )
}

CrmCategoryProductsSelect.propTypes = {
  placeholder: string
}

export default CrmCategoryProductsSelect
