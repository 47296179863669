import React, { useState } from 'react'
import ReactTable from 'react-table'
import { CrmModal, CrmJsonPreview } from 'crm-components'
import moment from 'moment'
import requestJobsQL from './jobs-reports-gql'

interface IProps {
  client?: any
}
class JobsRelationView extends React.Component<IProps> {
  state = {
    columnType: '',
    showModal: false,
    showLog: false,
    selected: null,
    correlation_uid: '',
    stack: [],
    rows: [],
    modalTitle: ''
  }

  reload = (correlation_uid: string, modalTitle: string) => {
    console.log(modalTitle)
    this.setState({ rows: [], stack: [], columnType: '', modalTitle }, () =>
      this.load(correlation_uid, modalTitle)
    )
  }
  load = async (correlation_uid, modalTitle) => {
    const filter = { correlation_uid }
    const variables = { filter }
    const jobs = await this.props.client
      .query({
        query: requestJobsQL,
        variables
      })
      .then(response => {
        return response.data.list_jobs.nodes
      })
    this.pushToStack(() => {
      this.setState({
        rows: jobs,
        columnType: '',
        showModal: true,
        modalTitle
      })
    })
  }
  getModalTitle(selected) {
    let jobName = ''
    if (selected.job_name) {
      jobName = `: ${selected.job_name}`
    }
    return selected ? `${selected.type} ${jobName}` : ''
  }
  get columns() {
    const columns = [
      {
        Header: 'Name',
        accessor: 'job_name',
        Cell: row => <div>{row.original.job_name}</div>
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: row => <div>{row.original.type ? row.original.type : ''}</div>
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        Cell: row => <div>{row.original.status}</div>
      },
      {
        Header: 'Input',
        accessor: 'input',
        width: 100,
        Cell: row => <PreviewJson json={row.original.input} />
      },
      {
        Header: 'Last Log',
        Cell: row => {
          if (row.original.logs) {
            return (
              <div>
                {row.original.logs[row.original.logs.length - 1].message}
              </div>
            )
          }
          return <div></div>
        }
      },
      {
        Header: 'Created At',
        accessor: 'created_time',
        width: 160,
        Cell: row => (
          <div>
            {moment(row.original.created_time).format('YYYY/MM/DD HH:mm:ss')}
          </div>
        )
      },
      {
        Header: 'Actions',
        width: 130,
        Cell: row => (
          <div>
            {row.original.logs && (
              <button
                onClick={() => {
                  let rows = []
                  if (row.original.logs) {
                    rows = row.original.logs
                  }
                  this.pushToStack()
                  this.setState({
                    showModal: true,
                    selected: row.original,
                    rows: rows,
                    columnType: 'detail',
                    modalTitle: this.getModalTitle(row.original)
                  })
                }}
              >
                Details
              </button>
            )}
            {row.original.has_children && (
              <button
                onClick={() =>
                  this.load(
                    row.original.job_uid,
                    this.getModalTitle(row.original)
                  )
                }
              >
                Children
              </button>
            )}
          </div>
        )
      }
    ]
    const detailColumns = [
      {
        Header: 'At',
        accessor: 'created_time',
        width: 200,
        Cell: row => moment(row.value).format('YYYY/MM/DD HH:mm:ss')
      },
      {
        Header: 'Message',
        accessor: 'message'
      },
      {
        Header: 'Json',
        accessor: 'json',
        width: 100,
        Cell: row => (
          <div style={{ whiteSpace: 'normal' }}>
            <PreviewJson json={row.value} />
          </div>
        )
      }
    ]
    if (this.state.columnType === 'detail') return detailColumns
    return columns
  }
  toggle = () => this.setState({ showModal: !this.state.showModal })
  toggleLog = () => this.setState({ showLog: !this.state.showLog })
  pushToStack = (callback = () => {}) => {
    const { columnType, rows, modalTitle } = this.state
    const item = { columnType, rows, modalTitle }
    return this.setState({ stack: this.state.stack.concat(item) }, callback)
  }
  popFromStack = () => {
    const stack = [...this.state.stack]
    if (stack.length > 0) {
      const item = stack.pop()
      this.setState({
        columnType: item.columnType,
        rows: item.rows,
        stack,
        modalTitle: item.modalTitle
      })
    }
  }
  render() {
    return (
      <div>
        <CrmModal
          isOpen={this.state.showModal}
          title={
            <div style={{ position: 'relative' }}>
              {this.state.stack.length > 1 && (
                <button
                  style={{ position: 'absolute', left: 10 }}
                  onClick={this.popFromStack}
                >
                  Back
                </button>
              )}
              <div>{this.state.modalTitle}</div>
            </div>
          }
          onClose={this.toggle}
          size="xl"
        >
          <ReactTable data={this.state.rows} columns={this.columns} />
        </CrmModal>
      </div>
    )
  }
}

export const PreviewJson = ({ json }) => {
  if (!json) return <div></div>
  const [expanded, setExpand] = useState(false)
  return (
    <React.Fragment>
      <button onClick={() => setExpand(!expanded)}>
        {expanded ? 'Hide' : 'Show'}
      </button>
      <CrmModal
        isOpen={expanded}
        title="Preview JSON"
        onClose={() => setExpand(!expanded)}
        size="lg"
      >
        <CrmJsonPreview json={json} />
      </CrmModal>
    </React.Fragment>
  )
}

export default JobsRelationView
