/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import UserItem from './rms-user-item.jsx'
import { graphql, compose } from 'react-apollo'
import { qlqProcessType } from 'crm-data/processes'
import { loadData, availableSections } from './rms-dashboard-utils'
import { arrayOf, shape, func } from 'prop-types'
import {
  CrmFullpageContainer,
  CrmDropdown,
  CrmButton,
  CrmCheckbox
} from 'crm-components'

const columns = []
class RmsDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedSections: {}
    }
  }

  toggleIsLoading(val, handler) {
    return this.setState({ ...this.state, isLoading: val }, handler)
  }

  parseData(result) {
    console.log('DashLeadsDetails parseData')
    console.dir(result)
    this.setState({ ...this.state, data: result.data }, () => {
      this.toggleIsLoading(false)
    })
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== undefined &&
      nextProps.analyticsContext !== this.props.analyticsContext
    ) {
      this.setState({ ...this.state }, () => {
        this.handleRefresh()
      })
    }
  }

  handleRefresh = async () => {
    console.log('handleRefresh RMs dashboard')
    try {
      const users = await loadData(this.props, this.state.selectedSections)
      if (users) {
        this.setState({ users })
      }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }
  handleCurrentStepClick = selectedStepType => {
    console.log('handleCurrentStepClick', selectedStepType)
    this.setState({ selectedStepType })
  }

  _mergeWithLocalContext = () => {
    let res = { ...this.props.analyticsContext }
    if (this.state.selectedStepType) {
      res.filters.process_step_type_uid = this.state.selectedStepType.process_step_type_uid
    }
    console.log('_mergeWithLocalContext', res)
    return res
  }
  handleTogleSection = (event, code) => {
    console.log('handleTogleSection', event, code)
    if (code) {
      const selectedSections = { ...this.state.selectedSections }
      selectedSections[code] = !selectedSections[code]
      this.setState({ selectedSections })
    }
  }
  renderAvailableSections = () => {
    console.log('renderAvailableSections', availableSections)
    return (
      <div className="util-flexRow">
        {availableSections.map(s => {
          const isSelected = false
          return (
            <div className="util-marginMd">
              <CrmCheckbox
                label={s.name}
                name={s.code}
                input={{
                  value: this.state.selectedSections[s.code],
                  onChange: this.handleTogleSection
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  renderUser = user => {
    return (
      <div className="col-md-4 util-marginBottom">
        <UserItem user={user} />
      </div>
    )
  }

  render() {
    const allAccountsCount = this.state.statsByCurrentStepResults
      ? this.state.statsByCurrentStepResults.reduce((a, i) => a + i.count, 0)
      : 0
    const detailsFilter = {}
    const localContext = this._mergeWithLocalContext()
    const { processType } = this.props
    if (!processType) {
      return <div>Loading Process Type</div>
    }
    return <div>RMs Funnel Dashboard</div>
  }
}

RmsDashboard.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

RmsDashboard.defaultProps = {
  dashboards: []
}

// Dialer type
const processTypeUid = '273c0b3f-23dd-4d05-ba56-cd34c21d0fd2'

const RmsDashboardQL = compose(
  graphql(qlqProcessType, {
    options: () => ({
      variables: {
        process_type_uid: processTypeUid
      }
    }),
    props: ({ qlqProcessType, ownProps }) => ({
      ...ownProps,
      qlqProcessType,
      processType: qlqProcessType ? qlqProcessType.process_type : undefined
    }),
    name: 'qlqProcessType'
  }),
  withApollo
)(RmsDashboard)

export default RmsDashboardQL
