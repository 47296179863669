import { CrmDropdown } from 'crm-components'
import gql from 'graphql-tag'
import React, { PureComponent } from 'react'
import { Query } from 'react-apollo'

const qlqListVehicle = gql`
  {
    listVehicles {
      contract_state_id
      registration
      contract_state
      type
      owner
      manufacturer
      model
      year_model
    }
  }
`

const CrmVehicleSelect = props => {
  console.log('render CrmVehicleSelect', props)
  const variables = {
    contract_state_id: '2'
  }
  return (
    <Query query={qlqListVehicle} variables={variables}>
      {({ loading, error, data }) => {
        const options =
          data && data.listVehicles
            ? data.listVehicles.map(v => ({
                name: `${v.registration}, ${v.manufacturer} ${v.model} ${v.year_model}`,
                value: v.registration
              }))
            : []
        options.sort((a, b) => {
          const va = a && a.registration ? a.registration : ''
          const vb = b && b.registration ? b.registration : ''
          return va.localeCompare(vb)
        })
        return (
          <CrmDropdown
            {...props}
            options={options}
            placeholder="Vehicles"
            addEmptyOption
          />
        )
      }}
    </Query>
  )
}

export default CrmVehicleSelect
