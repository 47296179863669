/* eslint-disable camelcase */
import {
  setFiltersGroupView,
  getQFilterGroupRaw
} from '../duxs/filters-reducer'
import {
  FETCH_ACCOUNTS_LIST_COUNT,
  FETCH_ACCOUNTS_LIST_COUNT_SUCCESS,
  SELECT_ACCOUNTS_VIEW,
  SET_ACCOUNTS_VIEW,
  getViews,
  getFixFilters,
  getOnlyOwnAccounts,
  getCurrentFilters
} from 'crm-duxs/accounts-list-reducer'

import { countAccountsApi } from 'crm-api/accounts-api'
import { countOpportunitiesApi } from 'crm-api/opportunities-api'

import { call, put, takeLatest, select } from 'redux-saga/effects'
import { hasPermission } from '@cartrack-crm/crm-core'
import { getMyPermissions2019 } from 'crm-duxs/crm-reducer'

export function* _buildAccountsRequestFilters(accountsListCode) {
  let filters = []
  const fixFilters = yield select(getFixFilters, accountsListCode)

  if (fixFilters) {
    filters = [...filters, ...fixFilters]
  }

  const currentFilters = yield select(getCurrentFilters, accountsListCode)

  if (currentFilters) {
    filters = [...filters, ...currentFilters]
  }

  const isOnlyOwnAccounts = yield select(getOnlyOwnAccounts, accountsListCode)
  const myPermissions2019 = yield select(getMyPermissions2019)
  const isManager = hasPermission(
    myPermissions2019,
    'crm.generalData',
    'manage',
    ['*']
  )

  if (isOnlyOwnAccounts && !isManager) {
    filters.push({
      code: 'owner_new',
      params: [
        {
          condition_key: 'owner_user_uid',
          condition_value: 'me'
        }
      ]
    })
  }

  return filters
}

function* handleSelectAccountsView(action) {
  const accountsView = { ...action.payload.accountsView }

  yield put({
    type: SET_ACCOUNTS_VIEW,
    payload: { accountsListCode: action.payload.accountsListCode, accountsView }
  })

  yield put(setFiltersGroupView(action.payload.accountsListCode, accountsView))
}

function* fetchAccountsCount(action) {
  const views = yield select(getViews, action.payload.accountsListCode)
  const accountRequestFilters = yield _buildAccountsRequestFilters(
    action.payload.accountsListCode
  )
  const qfilter = yield select(
    getQFilterGroupRaw,
    action.payload.accountsListCode
  )

  if (views !== undefined) {
    const summaries = []

    // eslint-disable-next-line no-unused-vars
    for (const view of views) {
      let query = view.filters ? view.filters : []

      if (accountRequestFilters) {
        query = [...query, ...accountRequestFilters]
      }

      const filter = { ...qfilter, ...view.filter }
      const params = { query, filter }
      const count = yield call(
        action.payload.accountsListCode === 'opportunities'
          ? countOpportunitiesApi
          : countAccountsApi,
        params
      )

      summaries.push({ code: view.code, count })
    }
    yield put({
      type: FETCH_ACCOUNTS_LIST_COUNT_SUCCESS,
      payload: { accountsListCode: action.payload.accountsListCode, summaries }
    })
  }
}

export default function* accountsListSaga() {
  yield takeLatest(SELECT_ACCOUNTS_VIEW, handleSelectAccountsView)
  yield takeLatest(FETCH_ACCOUNTS_LIST_COUNT, fetchAccountsCount)
}
