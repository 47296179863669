/* eslint-disable camelcase */
import React from 'react'
import { func, shape, bool } from 'prop-types'
import { qlqAccountTasks } from 'crm-data/tasks'
import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

const taskCreateHoc = WrappedComponent => {
  class TaskCreateHocInner extends React.PureComponent {
    static propTypes = {
      process: shape({}).isRequired,
      onCancel: func.isRequired,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      isEditing: false,
      gotoPage: undefined,
      newAccountUid: undefined
    }
    constructor(props) {
      super(props)
      this.state = { isSaving: false }
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })

    handleCreateTask = async pTask => {
      // console.log('handleCreateTask', pTask)
      const task = {
        task_uid: this.props.newObjectUid,
        ...pTask,
        creation_mode: 'manual'
      }
      const command = {
        type: 'task.manualCreate',
        aggregate_type: 'task',
        aggregate_uid: task.task_uid,
        payload: {
          task
        }
      }

      // console.log('command', command)
      try {
        const refetchQueries = [
          {
            query: qlqAccountTasks,
            variables: {
              account_uid: pTask.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })

        // console.log('Task Create HOC reslt', res)
        return res.data.cqCommand
      } catch (err) {
        console.log('Create task error', err)
        throw err
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onCreateTask={this.handleCreateTask}
          isSavingCreateTask={this.props.isRunningCommand}
        />
      )
    }
  }
  return crmGenericCommandHoc(TaskCreateHocInner)
}

export const taskCreateHocPropTypes = {
  onCompleteTask: func.isRequired,
  isSavingCompleteTask: bool.isRequired
}
export default taskCreateHoc
