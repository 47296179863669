const rmLeadsResults = {
  type: 'analitics_widget',
  code: 'rmLeadsResults',
  def_version: 5,
  title: 'RM Leads Results',
  description: 'Showing results of leads processes by RMs (Quoted shows all accounts ever quoted)',
  content: {
    data: {
      dataSources: [
        {
          code: 'AccountsListQM',
          name: 'AccountsListQM',
          type: 'QM',
          filter: {},
          fields: ['latest_owner_user_uid', 'sales_result_code', 'lead_campaign__lead_source__name'],
          aggregate: [
            { id: 'account_uid', type: 'count' },
            { id: 'lead_source_type_name', type: 'count' }
          ],
          contextMapping: {
            latest_owner_user_uid: 'user_uid'
          },
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid)  {
              master_uid name instance(instance_uid: $instance_uid) {
                instance_uid name accountsc(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                  edges {
                    edge {
                      account_uid
                      latest_owner_user_uid
                      sales_result_code
                      lead_campaign__lead_source__name
                    }
                  }
                }
              }
            }
          }`,

          transposeColumns: ['sales_result_code'],
          transposeKey: 'lead_campaign__lead_source__name',
          transposeValueField: 'account_uid',
          qlVariables: {
            groupBy: ['latest_owner_user_uid', 'sales_result_code', 'lead_campaign__lead_source__name']
          },
          qlEdges: true,
          qlRoot: 'accountsc'
        },
        {
          code: 'AccountsListQM',
          name: 'AccountsListQM',
          alias: 'AccountsQuotedQM',
          type: 'QM',
          filter: {
            detailed_status_code: 'quoted'
          },
          fields: ['latest_owner_user_uid', 'lead_campaign__lead_source__name'],
          aggregate: [{ id: 'account_uid', type: 'count' }],
          contextMapping: {
            latest_owner_user_uid: 'user_uid'
          }
        },
        {
          code: 'OrdersListQM',
          name: 'OrdersListQM',
          type: 'QM',
          filter: {
            completed_date: { $ne: null }
          },
          fields: ['owner_user_uid', 'lead_source_type_name', 'lead_source_type_uid'],
          aggregate: [{ id: 'count', type: 'count', alias: 'count' }],
          contextMapping: {},
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid) {
              master_uid
              name
              instance(instance_uid: $instance_uid) {
                instance_uid
                name
                orders(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                  edges {
                    edge {
                      count
                      owner_user_uid
                      lead_source_type_uid
                      lead_source_type_name
                    }
                  }
                }
              }
            }
          }`,
          qlVariables: {
            groupBy: ['owner_user_uid', 'count', 'lead_source_type_name', 'lead_source_type_uid']
          },
          qlEdges: true,
          qlRoot: 'orders'
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {},
          // $noContextFilter: true,
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON) {
            master(master_uid: $master_uid)  {
                master_uid
                name
                instance(instance_uid: $instance_uid) {
                    instance_uid
                    name
                    users (filter: $filter){
                      user_uid
                      is_active
                      full_name
                      contract_start_date
                      contract_end_date
                      department {
                        name
                      }
                    }
                }
            }
          }`,
          qlRoot: 'users'
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyFields: ['user_uid', 'account__lead_campaign__lead_source__name'],
        mapping: [
          {
            fromDataSourceName: 'AccountsListQM',
            keyFields: {
              user_uid: 'latest_owner_user_uid',
              account__lead_campaign__lead_source__name: 'lead_campaign__lead_source__name'
            },
            mapping: [
              {
                from: 'account_uid',
                to: 'accounts_all'
              },
              {
                from: 'lead',
                to: 'accounts_new'
              },
              {
                from: 'customer',
                to: 'accounts_won'
              },
              {
                from: 'pending',
                to: 'accounts_pending'
              },
              {
                from: 'lost',
                to: 'accounts_lost'
              },
              {
                from: 'invalid',
                to: 'accounts_invalid'
              },
              {
                from: 'quoted',
                to: 'accounts_quoted'
              }
            ]
          },
          {
            fromDataSourceName: 'OrdersListQM',
            keyFields: {
              user_uid: 'owner_user_uid',
              account__lead_campaign__lead_source__name: 'lead_source_type_name'
            },
            mapping: [
              {
                from: 'count',
                to: 'fitted_units'
              }
            ]
          },
          {
            fromDataSourceName: 'CrmUser',
            keyField: 'user_uid',
            type: 'left',
            mapping: [
              {
                from: 'full_name'
              },
              {
                from: 'contract_start_date'
              },
              { from: 'contract_end_date' },
              { from: 'department__name' },
              { from: 'is_active' }
            ]
          }
        ]
      }
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: true,
            generate_average: true,
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 50
              },
              {
                Header: 'Lead Source',
                accessor: 'account__lead_campaign__lead_source__name'
              },
              {
                Header: 'Total',
                accessor: 'accounts_all',
                detailsPopup: 'account',
                detailsFilter: {},
                detailsFilterMapping: {
                  latest_owner: { user_uid: 'user_uid' },
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                },
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number'
              },
              {
                Header: 'Won',
                accessor: 'accounts_won',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'customer'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                }
              },
              {
                Header: 'Invalid',
                accessor: 'accounts_invalid',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'invalid'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                }
              },
              {
                Header: 'Lost',
                accessor: 'accounts_lost',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'lost'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                }
              },
              {
                Header: 'Leads',
                accessor: 'accounts_new',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  sales_result_code: 'lead'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                }
              },
              {
                Header: 'Quoted',
                accessor: 'accounts_quoted',
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'account',
                detailsFilter: {
                  detailed_status_code: 'quoted'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                },
                Cell: 'n/a'
              },
              {
                Header: 'Wins to Leads',
                accessorFn: row => {
                  const all = row ? Number(row.accounts_all) : undefined
                  const won = row ? Number(row.accounts_won) : undefined
                  if (all && won && !isNaN(all) && !isNaN(won) && all > 0 && won > 0) {
                    const res = Number((100 * won) / all)
                    return res && !isNaN(res) ? res : undefined
                  }
                },
                Cell: row => {
                  const all = row && row.original ? Number(row.original.accounts_all) : undefined
                  const won = row && row.original ? Number(row.original.accounts_won) : undefined
                  if (all && won && !isNaN(all) && !isNaN(won) && all > 0 && won > 0) {
                    const res = Number((100 * won) / all)
                    return res && !isNaN(res)
                      ? res.toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        }) + '%'
                      : undefined
                  }
                },
                className: 'util-textRight util-pointer',
                width: 60,
                type: 'number',
                detailsPopup: 'accounts_results',
                detailsFilter: {
                  sales_step_code: 'won'
                },
                detailsFilterMapping: {
                  latest_owner_user_uid: 'user_uid',
                  lead_source_type_name: 'account__lead_campaign__lead_source__name'
                }
              },
              {
                Header: 'Fitments To Leads',
                width: 60,
                headerClassName: 'util-textCenter',
                accessorFn: row => {
                  if (
                    !row ||
                    !row.fitted_units ||
                    !row.accounts_all ||
                    isNaN(row.fitted_units) ||
                    isNaN(row.accounts_all)
                  )
                    return undefined

                  const accounts = Number(row.accounts_all)

                  if (accounts <= 0) return 0

                  return Number(row.fitted_units) / accounts
                },
                type: 'number',
                align: 'right',
                maximumFractionDigits: 1
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}

export default rmLeadsResults
