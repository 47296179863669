import React, { Component } from 'react'
import { func } from 'prop-types'
import ResourceReduxForm from './resource-form.jsx'

class ResourceCreateForm extends Component {
  constructor(props) {
    super(props)
    this.file = undefined
    this.handleSave = this.handleSave.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
  }

  handleSave(values) {
    if (this.file) {
      const resourceValue = {}
      resourceValue.description = values.description
      resourceValue.file = this.file
      this.props.onSave(resourceValue)
    }
  }

  handleUpload(file) {
    console.log('handleUpload values : ', file)
    this.file = file
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div>
          <span>Create resource</span>
        </div>
        <div style={{ flex: 1, position: 'relative' }}>
          <div style={{ height: '100%', position: 'absolute', width: '100%' }}>
            <ResourceReduxForm
              initialValues={{ fileupload: {} }}
              editing={false}
              creating="true"
              onSubmit={this.handleSave}
              onCancel={this.props.onCancel}
              onReset={this.props.onReset}
              onUpload={this.handleUpload}
            />
          </div>
        </div>
      </div>
    )
  }
}

ResourceCreateForm.propTypes = {
  onCancel: func.isRequired,
  onReset: func.isRequired,
  onSave: func.isRequired
}

export default ResourceCreateForm
