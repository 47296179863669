import React from 'react'
import { shape, arrayOf } from 'prop-types'
import DocumentListItem from './document-list-item.jsx'

const DocumentsList = ({ documents, style }) => (
  <div className="ActivitiesList" style={style}>
    {documents &&
      documents.map(item => (
        <DocumentListItem key={item.document_uid} document={item} />
      ))}
  </div>
)

DocumentsList.defaultProps = {
  style: {},
  documents: undefined
}

DocumentsList.propTypes = {
  documents: arrayOf(shape({})),
  style: shape({})
}

export default DocumentsList
