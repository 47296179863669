import React from 'react'
import { useCrmDynamicTableContext } from '../dynamic-table-context'
import { DynamicTableViewMode } from '../dynamic-table-consts'
import ViewOptionsPivot from '../views/view-options-pivot'
import ViewOptionsTable from '../views/table/view-options-table'
import ViewOptionsDashboard from '../views/view-options-dashboard'
import ViewOptionsChart from '../views/view-options-chart'
import { LayoutConfigTable } from '../../component-section-layout/layout-config-table'
import { useComponentConfigurationContext } from '../../configuration-tools/component-configuration-context'

export const ViewOptionsPanel = (props) => {
  const dynamicTableContext: any = useCrmDynamicTableContext()
  const componentConfigurationContext = useComponentConfigurationContext()
  // dynamicTableContext.
  // const [isShowingColumnSelect, setShowColumnsSelect] = useState(false)

  if (dynamicTableContext.viewMode === DynamicTableViewMode.PIVOT) {
    if (componentConfigurationContext.component) {
      const item = componentConfigurationContext.component.content.layout.items[0]
      return <ViewOptionsPivot {...props} item={item} />
    } else {
      return <div>No component</div>
    }
  }
  if (dynamicTableContext.viewMode === DynamicTableViewMode.TABLE) {
    console.log('View Options panel', props, dynamicTableContext)
    console.log('component', componentConfigurationContext.component)
    // find table in component
    if (componentConfigurationContext.component) {
      // fieldsPropery
      const item = componentConfigurationContext.component.content.layout.items[0]
      console.log('item', item)
      return item ? <LayoutConfigTable fieldsProperty="columns" item={item} /> : <div> No Item </div>
    }

    // return (
    //   <ViewOptionsTable
    //     {...props}
    //     // setShowColumnsSelect={setShowColumnsSelect}
    //   />
    // )
  }
  if (dynamicTableContext.viewMode === DynamicTableViewMode.DASHBOARD) {
    return <ViewOptionsDashboard {...props} />
  }
  if (dynamicTableContext.viewMode === DynamicTableViewMode.CHART) {
    return <ViewOptionsChart {...props} />
  }
  return <span />
}
