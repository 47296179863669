/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'

import DialerHeaderMenu from './dialer-header-menu.jsx'
import DialerInnerTaskView from './dialer-task-view.jsx'

class DialerView extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div noInnerNav fullPage className="CrmCallcenter">
        <DialerHeaderMenu />
        <DialerInnerTaskView />
      </div>
    )
  }
}

DialerView.propTypes = {}

DialerView.defaultProps = {}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DialerView)
