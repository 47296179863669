import React from 'react'
import { shape, string, arrayOf, bool } from 'prop-types'
import AccountInfoFilter from './filters/account-info-filter.jsx'
import { CrmHbox } from 'crm-components'

const DefaultFilterContainer = props => (
  <CrmHbox>
    <div className="AccountsListFilters">
      <div className="AccountsListFilters-block util-paddingMd">
        <div className="AccountsListFilters-element-block">
          <div style={{ flex: 1 }}>
            <AccountInfoFilter
              accountsListCode={props.accountsListCode}
              fixFilters={props.fixFilters}
              defaultColumnFilter={props.defaultColumnFilter}
              isManager={props.isManager}
              noOwnerFilter={props.noOwnerFilter}
              queryField={props.queryField}
            />
          </div>
        </div>
      </div>
    </div>
  </CrmHbox>
)
DefaultFilterContainer.propTypes = {
  fixFilters: arrayOf(shape({})),
  accountsListCode: string.isRequired,
  defaultColumnFilter: string.isRequired,
  isShowFilter: bool.isRequired,
  isManager: bool.isRequired,
  noOwnerFilter: bool,
  queryFiel: string
}

DefaultFilterContainer.defaultProps = {
  fixFilters: undefined,
  noOwnerFilter: false,
  queryField: undefined
}

export default DefaultFilterContainer
