import React, { FC } from 'react'
import styled from 'styled-components'
import { ComponentListItemType } from '.'
import { ListSchema } from '../data-utils/schema-types'
export const ComponentListTypeWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`
interface IComponentListType {
  schema: ListSchema
  onClickField?: Function
}

export const ComponentListType: FC<IComponentListType> = ({ schema, onClickField }) => {
  return (
    <ComponentListTypeWrapper>
      {schema.map((item) => <ComponentListItemType schema={item} onClickField={onClickField} />)}
    </ ComponentListTypeWrapper>
  )
}