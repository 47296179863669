import React, { SFC } from 'react'
import styled from 'styled-components'

const BadgeContainer = styled.span`
  display: flex;
  min-height: 24px;
  min-width: 86px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  background-color: ${(p) => {
    switch (p.type) {
      case 'primary':
        return '#73ADD7'
      case 'success':
        return '#2DAB33'
      case 'danger':
      case 'failure':
        return '#CE5239'
      default:
        return '#666666'
    }
  }};

  font-size: ${(p) => (p.textLength > 20 ? '9px' : '11px')};
  font-family: roboto;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  animation: ${(p) => {
    return p.type === 'pending' ? 'blinkingActivity 1s infinite alternate' : ''
  }}};
`

export interface CrmBadgeProps {
  type: 'primary' | 'success' | 'danger' | 'default' | 'pending'
  children: string
}

const CrmBadge: SFC<CrmBadgeProps> = (props) => {
  return (
    <BadgeContainer
      textLength={props.children ? props.children.length : 0}
      type={props.type}
    >
      {props.children}
    </BadgeContainer>
  )
}

export default CrmBadge
