import React, { PureComponent } from 'react'
import { shape, string, func, arrayOf } from 'prop-types'
import widgetUtilsHoc from '../widget-utils-hoc'
import CrmBarChart from 'crm-components/charts/crm-bar-chart.jsx'
import { leadsStatsGeneric } from 'crm-api/analytics-api'

class LeadsGroupedGenericWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentWillMount() {
    this.handleRefresh()
  }

  handleRefresh = async () => {
    if (!this.props.analyticsContext) {
      return
    }
    const loadingFn = async () => {
      const filters = { ...this.props.analyticsContext.filters }
      // console.log('doRefresh', this.props.analyticsContext)
      // console.log(filters)
      const res = await leadsStatsGeneric(this.props.groups, filters)
      // console.log('results', res)
      const resultsParsed = res
        .map(r => ({
          ...r,
          name: r[this.props.labelField]
        }))
        .sort((a, b) => b.count - a.count)

      this.setState({ ...this.state, resultsParsed })
    }
    this.props.onStartLoading(loadingFn)
  }

  handleClickStatus = event => {
    // console.log('handleClickStatus', event)
    this.props.onDashboardEvent({
      type: 'accountStatusSelected',
      data: {
        status: event
      }
    })
  }
  handleClickBar = event => {
    // console.log('handleClickBar', event)
  }

  render() {
    console.log('AccountsByStatusSummaryWidget')
    const resultsParsed = this.state.resultsParsed
    // console.log('resultsParsed', resultsParsed)
    return (
      <div
        className="util-paddingMd util-flexColumn"
        style={{ height: '100%' }}
      >
        <CrmBarChart data={resultsParsed} onClickBar={this.handleClickBar} />
      </div>
    )
  }
}

LeadsGroupedGenericWidget.propTypes = {
  analyticsContext: shape({}).isRequired,
  labelField: string.isRequired,
  groups: arrayOf(shape({})).isRequired,
  onDashboardEvent: func.isRequired,
  onStartLoading: func.isRequired
}

export default widgetUtilsHoc(LeadsGroupedGenericWidget)
