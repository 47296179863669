const rmMeetingPerformance = {
  type: 'item',
  label: 'RM Meeting Performance',
  code: 'rmMeetingPerformance',
  dashboard_uid: 'rmMeetingPerformance',
  defaultFilters: {},
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'ctDailyLeadsDashboard',
        def_version: 5,
        title: 'RM Meeting Performance',
        description: 'Columns calculating Average requires selection of Activity Date Range',
        content: {
          data: {
            dataSources: [
              {
                code: 'OrdersListQM',
                name: 'OrdersListQM',
                type: 'QM',
                filter: {
                  status_code: 'completed'
                },
                aggregate: [
                  { id: 'quantity', type: 'sum' },
                  { id: 'subscription', type: 'sum' }
                ],
                contextMapping: {
                  completed_date: 'activity_date',
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  }
                },
                groupBy: ['owner_user_uid'],
                qlQuery: `query orders($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
                  orders(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                  edges { edge { owner_user_uid order_uid subscription quantity } } } }`,
                qlEdges: true,
                qlRoot: 'orders'
              },
              {
                code: 'OpportunitiesMeetingsStats',
                name: 'OpportunitiesMeetingsStats',
                type: 'QM',
                filter: {},
                aggregate: [
                  { id: 'meetings_till_win_count', type: 'avg' },
                  { id: 'days_till_win', type: 'avg' }
                ],
                groupBy: ['owner_user_uid'],
                contextMapping: {
                  contract_signed_date: 'activity_date'
                },
                qlEdges: true,
                qlRoot: 'opportunity_meeting_status',
                qlQuery: `
                query opportunity_meeting_status($master_uid: String!, $instance_uid: String!, $filter: JSON, $aggregate:[JSON], $groupBy:[String]) {
                  master(master_uid: $master_uid)  { 
                      instance(instance_uid: $instance_uid) { 
                          opportunity_meeting_status(filter: $filter, aggregate: $aggregate, groupBy: $groupBy) 
                          {
                              meta {  sqls }
                              edges {
                                  edge {
                                        opportunity_uid
                                        account_uid
                                        vehicle_count
                                        owner_user_uid
                                        contract_signed_date
                                        meetings_till_win_count
                                        first_meeting_date
                                        days_till_win
                                  }
                              }
                              pageInfo { count }
                           
                          }
                      }   
                  }
              }
                `
              },
              {
                code: 'ActivitiesListQM',
                name: 'MeetingsAttendedAvg',
                type: 'QM',
                filter: {
                  type: { medium_code: 'meeting' },
                  status: { kind: 'completed' }
                },
                groupBy: ['created_by_user_uid'],
                aggregate: [{ id: 'activity_uid', type: 'count' }],
                contextMapping: {
                  start_date: 'activity_date'
                },
                qlQuery: `
                query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
                  master(master_uid: $master_uid)  { 
                      master_uid
                      name
                      instance(instance_uid: $instance_uid) { 
                          instance_uid
                          name
                          activities(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                             edges{ edge { 
                                 activity_uid
                                 created_by_user_uid
                             }
                             }
                         }
                      }
                  }
              }`,
                qlEdges: true,
                qlRoot: 'activities'
              },
              {
                code: 'FirstFitmentsList',
                type: 'QM',
                alias: 'FirstFitments',
                filter: {
                  sequence: 1,
                  owner_user_uid: { $ne: 'null' },
                  account_uid: { $ne: 'null' }
                },
                qlOnly: true,
                qlRoot: 'first_fitmentsc',
                qlEdges: true,
                qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
                    master(master_uid: $master_uid)  { 
                        master_uid
                        name
                        instance(instance_uid: $instance_uid) { 
                            instance_uid
                            name
                           first_fitmentsc(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                               meta { sqls }
                               edges { 
                                edge 
                                { 
                                    order_uid
                                    completed_date
                                    cams_username
                                    owner_user_uid
                                    account_uid
                                }
                               }
                           }
                        }
                    }
                }`,
                contextMapping: { completed_date: 'activity_date' },
                qlVariables: {
                  groupBy: ['owner_user_uid'],
                  aggregate: [
                    {
                      id: 'account_uid',
                      type: 'count'
                    }
                  ]
                }
              },
              {
                code: 'CrmUser',
                name: 'CrmUser',
                type: 'QM',
                filter: {},
                qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON) {
                  master(master_uid: $master_uid)  { 
                      master_uid
                      name
                      instance(instance_uid: $instance_uid) { 
                          instance_uid
                          name
                          users(filter: $filter) {
                              user_uid
                              is_active
                              full_name
                              contract_start_date
                              contract_end_date
                              department { 
                                name 
                              }
                          } 
                      }
                  }
              }`,
                qlRoot: 'users'
              }
            ],
            combineDataSources: {
              name: 'CombinedDataSource',
              targetKeyField: 'user_uid',
              actions: [
                {
                  action: 'createNewField',
                  field: 'meetings_count',
                  operation: 'avgByActivityDate',
                  filterFromDS: {
                    ds: 'MeetingsAttendedAvg',
                    field: 'activity_date'
                  },
                  resultFieldName: 'avg_meetings_per_day'
                }
              ],
              mapping: [
                {
                  fromDataSourceName: 'MeetingsAttendedAvg',
                  keyField: 'created_by_user_uid',
                  mapping: [
                    {
                      from: 'activity_uid',
                      to: 'meetings_count'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'OpportunitiesMeetingsStats',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'meetings_till_win_count',
                      to: 'meetings_till_win_count'
                    },
                    {
                      from: 'days_till_win',
                      to: 'days_till_win'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'FirstFitments',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'account_uid',
                      to: 'first_fitments'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'OrdersListQM',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'quantity',
                      to: 'fitted_units'
                    },
                    {
                      from: 'subscription',
                      to: 'subscription'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'CrmUser',
                  keyField: 'user_uid',
                  type: 'left',
                  mapping: [
                    {
                      from: 'full_name'
                    },
                    {
                      from: 'contract_start_date'
                    },
                    { from: 'contract_end_date' },
                    { from: 'department__name' },
                    { from: 'is_active' }
                  ]
                }
              ]
            }
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                props: {
                  generate_summary: true,
                  generate_average: true,
                  initialSort: {
                    id: 'owner__contract_start_date',
                    order: 'desc'
                  },
                  columnGroups: [
                    {
                      Header: '',
                      colSpan: 3
                    },
                    {
                      Header: 'Statistics for period',
                      colSpan: 8
                    }
                  ],
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 30
                    },
                    {
                      Header: 'RM',
                      accessor: 'full_name',
                      classNameHandler: row => {
                        return row.original.is_active ? '' : 'util-opacity50'
                      },
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user_uid: 'user_uid'
                      }
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'contract_start_date',
                      width: 80,
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'AVG Meetings per day',
                      width: 100,
                      type: 'number',
                      maximumFractionDigits: 2,
                      detailsPopup: 'activities',
                      accessor: 'avg_meetings_per_day',
                      detailsFilter: {
                        type: { medium_code: 'meeting' },
                        status: { kind: 'completed' }
                      },
                      detailsFilterMapping: {
                        created_by_user_uid: 'user_uid'
                      },
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'AVG Meetings to close deal',
                      accessor: 'meetings_till_win_count',
                      width: 100,
                      type: 'number',
                      maximumFractionDigits: 1,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'opportunities_meetings_stats',
                      detailsFilter: {},
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid',
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      }
                    },
                    {
                      Header: 'AVG Days to Close Deal',
                      accessor: 'days_till_win',
                      width: 100,
                      type: 'number',
                      maximumFractionDigits: 0,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'opportunities_meetings_stats',
                      detailsFilter: {},
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid',
                        owner__department_uid: 'department_uid',
                        owner__organization_unit_uid: 'organization_unit_uid'
                      }
                    },
                    {
                      Header: 'Fitments per Meeting',
                      accessor: 'fitments_per_meeting',
                      width: 80,
                      accessorFn: row => {
                        if (
                          row &&
                          row.fitted_units &&
                          !isNaN(Number(row.fitted_units)) &&
                          row.meetings_count &&
                          !isNaN(Number(row.meetings_count))
                        ) {
                          if (Number(row.meetings_count) > 0) {
                            return Number(row.fitted_units) / Number(row.meetings_count)
                          }
                          return 0
                        }
                      },
                      type: 'number',
                      align: 'right',
                      maximumFractionDigits: 1,
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type__medium_code: 'meeting'
                      },
                      detailsFilterMapping: {
                        performer_user_uid: 'user_uid',
                        performer_department_uid: 'department_uid'
                      }
                    },
                    {
                      Header: 'First Fitments',
                      accessor: 'first_fitments',
                      width: 100,
                      type: 'number',
                      detailsPopup: 'first_fitments',
                      detailsFilter: {
                        account_uid: { $ne: 'null' },
                        owner_user_uid: { $ne: 'null' }
                      },
                      contextMapping: {
                        completed_date: 'activity_date'
                      },
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid'
                      },
                      align: 'right'
                    },
                    {
                      Header: 'Fitments',
                      accessor: 'fitted_units',
                      width: 100,
                      type: 'number',
                      detailsPopup: 'fitment',
                      detailsFilter: {
                        owner_user_uid: { "$ne": "null" }
                      },
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid'
                      },
                      align: 'right'
                    },
                    {
                      Header: 'ARPU',
                      accessor: 'subscription',
                      maximumFractionDigits: 0,
                      width: 100,
                      type: 'number',
                      detailsPopup: 'fitment',
                      detailsFilter: {},
                      detailsFilterMapping: {
                        owner_user_uid: 'user_uid'
                      },
                      align: 'right'
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'CombinedDataSource'
                  }
                }
              }
            ]
          },
          requiredFilters: [{ filter: 'activity_date', message: 'Activity Date is Required' }]
        }
      }
    ]
  }
}

export default rmMeetingPerformance
