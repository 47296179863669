import gql from 'graphql-tag'

export const fragmentOrdersFields = `
fragment OrderFields on Order {
  order_uid
  completed_time
  status_code
  date_signed
  quantity
  scheduled_time
  description
  details
  owner_user_uid
  external_id
  is_deleted
  contract_status_code
  repairs_count
  terminated_time
  canceled_time
  owner { full_name }
  organization_unit_uid
  organization_unit {
    organization_unit_uid
    name
  }
}`

export const fragmentAccountOrders = `
fragment AccountOrders on Account {
  orders {
    ...OrderFields
  }
}
${fragmentOrdersFields}
`

export const qlAccountOrders = gql`
  query account($account_uid: String!) {
    account(account_uid: $account_uid) {
      account_uid
      ...AccountOrders
    }
  }
  ${fragmentAccountOrders}
`

export const qlOrdersConnection = gql`
  query orders(
    $master_uid: String!
    $instance_uid: String!
    $filter: JSON
    $groupBy: [String]
    $aggregate: [JSON]
    $limit: Int
  ) {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        orders(filter: $filter, groupBy: $groupBy, aggregate: $aggregate, limit: $limit) {
          edges {
            edge {
              order_uid
              account_uid
              completed_time
              completed_date
              completed_month
              status_code
              quantity
              description
              details
              owner_user_uid
              external_id
              external_account_id
              contract_status_code
              repairs_count
              terminated_time
              canceled_time
              account {
                name
                account_uid
                cams_username
              }
              owner {
                full_name
              }
            }
          }
        }
      }
    }
  }
`
