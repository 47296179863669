/* eslint-disable camelcase */
import { accountsListReducerFactory } from '../accounts-list-reducer'

const views = [
  // {
  //   name: 'Total Leads',
  //   code: 'all',
  //   filter: {
  //     kind: 'lead'
  //   }
  // },
  // {
  //   name: 'Not Contacted',
  //   code: 'not_bogus',
  //   filter: {
  //     $qualification_no_bugus: true,
  //     kind: 'lead'
  //   },
  //   filters: [{ code: 'not_bogus' }]
  // },
  // {
  //   name: 'Qualified Bogus',
  //   code: 'qualified_bogus',
  //   filter: {
  //     $qualification_bugus: true,
  //     kind: 'lead'
  //   },
  //   filters: [
  //     {
  //       code: 'qualification_bugus',
  //       code_option: 'ilike'
  //     }
  //   ]
  // },
  // {
  //   name: 'No activities in Outbound in 30 days',
  //   code: 'no_activities_outbound_30_days',
  //   className: 'AccountsViewButton--blue',
  //   filter: {
  //     $no_activities_outbound_30_days: true
  //   },
  //   filters: [
  //     {
  //       code: 'no_activities_outbound_30_days',
  //       filterParams: {
  //         // Ddate: {
  //         //   expression: {
  //         //     fn: 'date_add',
  //         //     params: { startDate: 'now', unit: 'month', interval: '-1' }
  //         //   }
  //         // }
  //       }
  //     }
  //   ]
  // },
  // {
  //   name: 'No activities in Inbound in 24 hours',
  //   code: 'no_activities_inbound_24h',
  //   className: 'AccountsViewButton--blue',
  //   filter: {
  //     $no_activities_inbound_24h: true
  //   },
  //   filters: [
  //     {
  //       code: 'no_activities_inbound_24h'
  //     }
  //   ]
  // }
  // {
  //   name: 'Pending Qualification',
  //   code: 'pending_qualification',
  //   filters: [
  //     {
  //       code: 'qualification',
  //       code_option: 'ilike',
  //       params: [
  //         { condition_key: 'not_qualify', condition_value: 'not_qualify' }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   name: 'From Digital',
  //   code: 'digital',
  //   filters: [{ code: 'campaign_source_type_code', filterParams: { value: 'digital' }]
  // }
]
const accountsLeadsReducer = accountsListReducerFactory('leads', {
  views,
  selectedView: views[1]
})

export default accountsLeadsReducer
