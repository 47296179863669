import React from 'react'
import GoogleMapReact from 'google-map-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type MapMarkerLocation = {
  lat: number
  lng: number
}

interface CrmGoogleMapProps {
  zoom: number
  height: string
  width: string
  markers: any
  center: MapMarkerLocation
}

const CustomMarker = ({ text, markerClass, markerStyles, lat, lng }) => (
  <div className={markerClass} style={markerStyles} title={text}>
    <FontAwesomeIcon icon="map-marker-alt" />
  </div>
)

const CrmGoogleMap: React.SFC<CrmGoogleMapProps> = (props) => {
  const google_api_key = process.env.GOOGLE_API_KEY
  return (
    <div style={{ height: props.height, width: props.width }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: google_api_key }}
        defaultCenter={props.center}
        center={props.center}
        defaultZoom={props.zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        {props.markers &&
          props.markers.map((v) => {
            return (
              <CustomMarker
                lat={v.lat}
                lng={v.lng}
                text={v.markerText}
                markerClass={v.markerClass}
                markerStyles={v.markerStyles}
              />
            )
          })}
        {/* <CustomMarker
          lat={'13.9127437'}
          lng={'100.5779305'}
          text={'test'}
          markerClass={'util-textRed'}
          markerStyles={{ fontSize: '50px' }}
        /> */}
      </GoogleMapReact>
    </div>
  )
}

export default CrmGoogleMap
