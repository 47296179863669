/* eslint-disable camelcase */
import md5 from 'md5'
import jwtDecode from 'jwt-decode'
import apiCaller, { callQLApi } from './api-utils'
import { turnoffAutomaticSilentRefresh } from './auth'

export default {
  doLogin(username, password, company_code) {
    return apiCaller('crm_do_login', {
      login: username,
      password: md5(password),
      company_code: company_code
    })
      .then(res => {
        const response = {}
        const { error, result } = res
        if (error) {
          response.error = error
          return {
            result: error,
            error: error
          }
        }

        if (res.result) {
          const decoded = jwtDecode(result.access_token)
          const user = {
            user_uid: decoded.uid,
            sip: decoded.sip,
            login: username,
            username: username,
            master_uid: decoded.master_uid,
            name: decoded.name
          }
          response.user = user
          response.access_token = result.access_token
          window.localStorage.setItem('crmUser', JSON.stringify(user))
          window.localStorage.setItem('user', JSON.stringify(user))
          window.localStorage.removeItem('crmLogoutReason')
          if (result.group) {
            window.localStorage.setItem('userGroup', result.group)
          } else {
            window.localStorage.setItem('userGroup', '')
          }
          window.localStorage.setItem('crmAccessToken', result.access_token)
          window.localStorage.setItem('crmRefreshToken', result.refresh_token)
          window.localStorage.removeItem('message')
        }

        console.log('login_result_response', response)
        return response
      })
      .catch(err => {
        window.localStorage.clear()
        console.log('doLogin error', err)
      })
  },
  doLogout() {
    return apiCaller('crm_do_logout', {
      access_token: window.localStorage.getItem('crmAccessToken')
    })
      .then(res => {
        if (res.error) {
          throw Error(res.error)
        }
        turnoffAutomaticSilentRefresh()
        return { result: res.result }
      })
      .catch(err => {
        console.log(err)
      })
  },
  doResetPassword(login, email) {
    return apiCaller('crm_do_reset_password', {
      login,
      email
    })
      .then(res => {
        return res
      })
      .catch(err => {
        console.log(err)
      })
  },

  fetchAvailableInstancesQL: user_uid => {
    // eslint-disable-line
    const query = ` { availableInstancesAndAttributes : users(user_uid:"${user_uid}") { 
      available_instances { instance_uid name country_code is_active parameters details currency_code 
        instance_attributes {  attribute_uid code name value data_type } 
      } 
      master_attributes {attribute_uid code name value data_type } } } `
    return callQLApi(query, undefined, { noInstance: true }).then(res => {
      console.log('fetchAvailableInstancesQL result', res)
      return res?.availableInstancesAndAttributes && res.availableInstancesAndAttributes.length > 0
        ? res.availableInstancesAndAttributes[0]
        : {}
    })
  },

  fetchMyPermissions2019() {
    const query = ` { list_my_permissions_2019  { user_uid permission_code filters access_mode profile_uid ownership_rule module } } `
    return callQLApi(query, undefined, { noInstance: true }).then(res => res.list_my_permissions_2019)
  },
  fetchMyUserSettings() {
    const query = `{ get_my_user_settings { user_uid user_settings }}`
    return callQLApi(query, undefined, { noInstance: true }).then(res => res.get_my_user_settings)
  },
  fetchLocales(locale, limit = 5000) {
    const localeUpper = locale.toUpperCase()
    const query = ` { configuration_translation_list_qm_paged(filter: {lang:{value:"${localeUpper}"}} , limit: ${limit}, offset: 0) {    data {translation_uid lang region  code value created_time }    count    __typename  }  }`
    return callQLApi(query, undefined, { noInstance: true }).then(res => res.configuration_translation_list_qm_paged)
  }
}
