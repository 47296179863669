// Actions for dictionaries
export const FETCH_DICTIONARIES = 'FETCH_DICTIONARIES'
export const RECEIVE_DICTIONARIES = 'RECEIVE_DICTIONARIES'
export const SELECTED_DICTIONARY = 'SELECTED_DICTIONARY'
export const UNSELECTED_DICTIONARY = 'UNSELECTED_DICTIONARY'
export const SAVE_EDITING_DICTIONARY = 'SAVE_EDITING_DICTIONARY'
export const SAVED_EDITING_DICTIONARY = 'SAVED_EDITING_DICTIONARY'
export const EDITING_DICTIONARY = 'EDITING_DICTIONARY'
export const CANCEL_EDITING_DICTIONARY = 'CANCEL_EDITING_DICTIONARY'
export const CREATING_DICTIONARY = 'CREATING_DICTIONARY'
export const CANCEL_CREATING_DICTIONARY = 'CANCEL_CREATING_DICTIONARY'
export const SAVING_DICTIONARY = 'SAVING_DICTIONARY'
export const SAVE_CREATING_DICTIONARY = 'SAVE_CREATING_DICTIONARY'
export const SAVING_DICTIONARY_ERROR = 'SAVING_DICTIONARY_ERROR'
export const DELETE_DICTIONARY_VALUE = 'DELETE_DICTIONARY_VALUE'

// Reducer
const initialState = {
  lists: [],
  fetch: false,
  editing: false,
  creating: false,
  selected: undefined,
  deleteValues: []
}

function parseToValueItemJson(dicts) {
  dicts.forEach((item, indexItem) => {
    if (item.values) {
      item.values.forEach((itemValue, indexValues) => {
        // eslint-disable-next-line
        dicts[indexItem].values[indexValues].translation = JSON.parse(
          itemValue.translation
        )
      })
    }
  })

  return dicts
}

export default function dictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DICTIONARIES:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_DICTIONARIES:
      return {
        ...state,
        fetch: false,
        lists: parseToValueItemJson(action.payload.dictionaries)
      }
    case SELECTED_DICTIONARY:
      return {
        ...state,
        selected: action.item,
        creating: false,
        editing: false
      }
    case UNSELECTED_DICTIONARY:
      return {
        ...state,
        selected: undefined,
        editing: false
      }
    case EDITING_DICTIONARY:
      return {
        ...state,
        editing: true,
        creating: false,
        deleteValues: []
      }
    case CANCEL_EDITING_DICTIONARY:
      return {
        ...state,
        editing: false
      }
    case CREATING_DICTIONARY:
      return {
        ...state,
        creating: true,
        editing: false,
        selected: undefined,
        deleteValues: []
      }
    case CANCEL_CREATING_DICTIONARY:
      return {
        ...state,
        creating: false
      }
    case SAVING_DICTIONARY:
      return {
        ...state,
        creating: true
      }

    case DELETE_DICTIONARY_VALUE: {
      const deleteIds = state.deleteValues.slice()
      deleteIds.push(action.valueId)
      return {
        ...state,
        deleteValues: deleteIds
      }
    }
    default:
      return state
  }
}

export function fetchData() {
  return {
    type: FETCH_DICTIONARIES
  }
}

export function selectedItem(item) {
  return {
    type: SELECTED_DICTIONARY,
    item
  }
}

export function deleteItem(valueId) {
  return {
    type: DELETE_DICTIONARY_VALUE,
    valueId
  }
}

export function unselectedItem() {
  return {
    type: UNSELECTED_DICTIONARY
  }
}

export function startEditingItem() {
  return {
    type: EDITING_DICTIONARY
  }
}

export function cancelEditingItem() {
  return {
    type: CANCEL_EDITING_DICTIONARY
  }
}

export function startCreatingItem() {
  return {
    type: CREATING_DICTIONARY
  }
}

export function cancelCreatingItem() {
  return {
    type: CANCEL_CREATING_DICTIONARY
  }
}

export function saveCreatingItem(formValues) {
  return {
    type: SAVE_CREATING_DICTIONARY,
    formValues
  }
}

export function saveEditingItem(formValues) {
  return {
    type: SAVE_EDITING_DICTIONARY,
    formValues
  }
}
