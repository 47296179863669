import React from 'react'
import { string, object, oneOfType } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'

const CrmDictionaryValueSelect = props => {
  const variables = {}
  const qlq = gql`
    { dictionary(code:"${props.dictionaryCode}") { values { dictionary_value_uid name } } }
  `
  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.dictionary && data.dictionary.values
            ? data.dictionary.values.map(v => ({
                ...v,
                value: v.dictionary_value_uid
              }))
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_dictionary"
                  defaultMessage="Select Dictionary"
                />
              )
            }
          />
        )
      }}
    </Query>
  )
}

CrmDictionaryValueSelect.propTypes = {
  dictionaryCode: string.isRequired,
  placeholder: oneOfType([string, object])
}

export default CrmDictionaryValueSelect
