import styled from 'styled-components'

export const ActivityDateToday = styled.div`
  margin-left: 50px;
  margin-bottom: 8px;
  color: #5099ce;
  font-size: 11px;
  text-align: center;
`

export const ActivityDateGroup = styled.div`
  margin-left: 50px;
  text-align: center;
  color: #60a4d7;
  font-size: 11px;
  padding-bottom: 8px;
  position: relative;
  :before,
  :after {
    content: '';
    width: calc(50% - 42px);
    top: 6px;
    position: absolute;
    border-bottom: 1px solid #dddddd;
  }

  :before {
    left: 0px;
  }
  :after {
    right: 0px;
  }
`

export const ActivityListWrapper = styled.div`
  position: relative;
  width: 100%;
  // overflow: scroll;

  &:before {
    content: '';
    position: absolute;
    left: 20px;
    display: inline-block;
    height: 100%;
    min-height: 80px;
    border-left: 1px solid #cccccc;
  }
`
