import { crmGenericCommandHoc } from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { shape, func, bool } from 'prop-types'
import {
  qlmUpdateActivity,
  qlqAccountActivities,
  qlmUpdateActivityStatus
} from 'crm-data/activities'
import { parseActivityToServer } from 'crm-data/activities-utils'
import React from 'react'
import gql from 'graphql-tag'

export const qlmSetMeetingCompleted = gql`
  mutation activity_meeting_complete(
    $activity_uid: String!
    $completed: Boolean!
  ) {
    activity_meeting_complete(
      activity_uid: $activity_uid
      completed: $completed
    ) {
      activity_uid
      completed_at
    }
  }
`

export const _addrFormat = addr => {
  let label = ''
  if (addr.line1) {
    label += addr.line1
  }
  if (addr.line2) {
    label += (label.length > 0 ? ', ' : '') + addr.line2
  }
  if (addr.city) {
    label += (label.length > 0 ? ', ' : '') + addr.city
  }
  if (addr.street) {
    label += (label.length > 0 ? ', ' : '') + addr.street
    if (addr.street_number) {
      label += ' ' + addr.street_number
    }
  }
  if (addr.suburb) {
    label += (label.length > 0 ? ', ' : '') + addr.suburb
  }
  if (addr.town) {
    label += (label.length > 0 ? ', ' : '') + addr.town
  }
  if (addr.address_kind === 'headquarter') {
    label += ' (HQ) '
  }

  return label
}

export const getIsValidMeetingAddress = activity => {
  let require_address = false

  if (activity?.activity_type?.require_address) {
    require_address = activity.activity_type.require_address
  } else if (activity?.location_type) {
    require_address = true
  }

  const isValidAddress =
    (activity.location_type === 'ON_SITE' &&
      activity?.address_uid &&
      activity?.address_uid !== '') ||
    activity.location_type !== 'ON_SITE' ||
    !require_address

  return isValidAddress
}

const editMeetingHoc = WrappedComponent => {
  class EditMeetingHOC extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        isUpdatingStatus: false,
        isValidParticipants: undefined,
        participantsErrors: []
      }
    }
    static propTypes = {
      client: shape(),
      onRunCommand: func.isRequired,
      isSaving: bool.isRequired
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })

    handleSaveStatus = async (activity, newValue) => {
      await this.setStateAsync({ isUpdatingStatus: true })
      const refetchQueries = [
        {
          query: qlqAccountActivities,
          variables: {
            account_uid: activity.account_uid
          }
        }
      ]
      const variables = {
        activity_uid: activity.activity_uid,
        activity_status_uid: newValue
      }
      try {
        const res = await this.props.client.mutate({
          mutation: qlmUpdateActivityStatus,
          variables,
          refetchQueries
        })
        await this.setStateAsync({ isUpdatingStatus: false })
        return res
      } catch (err) {
        console.log('Error running command')
        throw err
      }
    }

    handleDeleteMeeting = async (activity, is_deleted) => {
      const command = {
        type: 'activity.admin_delete',
        aggregate_uid: activity.activity_uid,
        aggregate_type: 'activity',
        payload: {
          activity: {
            activity_uid: activity.activity_uid,
            is_deleted: is_deleted
          }
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqAccountActivities,
            variables: {
              account_uid: activity.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })
        return res.data.cqCommand
      } catch (err) {
        console.log('Error running command')
        throw err
      }
    }

    mapApiToMeetingPersons = activity => {
      const arrangedBy = this.mapApiToMeetingPersonsByKind(
        activity,
        activity.participants,
        'arrangedBy'
      )
      const host = this.mapApiToMeetingPersonsByKind(
        activity,
        activity.participants,
        'host'
      )
      const guests = this.mapApiToMeetingPersonsByKind(
        activity,
        activity.participants,
        'guests'
      )
      const participants = this.mapApiToMeetingPersonsByKind(
        activity,
        activity.participants,
        'participants'
      )
      return { arrangedBy, host, guests, participants }
    }

    mapApiToMeetingPersonsByKind = (activity, participants, kind) => {
      let meetingPersons
      if (kind === 'host') {
        meetingPersons = participants
          ? participants.filter(v => v.role_code === 'performer')[0]
          : undefined
      } else if (kind === 'arrangedBy') {
        meetingPersons = participants
          ? participants.filter(v => v.role_code === 'arranged_by')[0]
          : undefined
        if (!meetingPersons) {
          if (!activity.activity_uid) {
            // new meeting
            meetingPersons = {
              user: {
                user_uid: this.props.currentUser.user_uid,
                full_name: this.props.currentUser.username
              }
            }
            activity.participants = [
              ...activity.participants,
              {
                role_code: 'arranged_by',
                is_primary: false,
                kind: 'user',
                user_uid: this.props.currentUser.user_uid
              }
            ]
          } else {
            // exist meeting
            meetingPersons = {
              user: {}
            }
          }
        }
      } else if (kind === 'guests') {
        meetingPersons = participants
          ? participants.filter(
              v => v.role_code === 'participant' && v.person !== null
            )
          : undefined
        meetingPersons = meetingPersons
          ? meetingPersons.map(v => ({
              ...v,
              name: v.person.name,
              account_person_uid: v.person.account_person_uid
            }))
          : undefined
      } else if (kind === 'participants') {
        meetingPersons = participants
          ? participants.filter(
              v => v.role_code === 'participant' && v.user !== null
            )
          : undefined
        meetingPersons = meetingPersons
          ? meetingPersons.map(v => ({
              ...v,
              full_name: v.user.full_name,
              photo_url: v.user.photo_url
            }))
          : undefined
      }
      return meetingPersons
    }

    validateParticipants = participants => {
      let hasParticipant = false
      let allParticipantHasEmail = false
      let errors = []
      if (participants)
        // eslint-disable-next-line array-callback-return
        participants.map(v => {
          if (v.role_code === 'participant' && v.kind === 'user') {
            hasParticipant = true
            allParticipantHasEmail = true
            const user = v.user
            if (!user || !user.email) {
              allParticipantHasEmail = false
              user && errors.push(user.full_name + ' has no email')
            }
          }
        })

      // participants can be empty. if not empty, all must have email.
      const isValidParticipants =
        !hasParticipant || (hasParticipant && allParticipantHasEmail)
      this.setState({ participantsErrors: [...errors], isValidParticipants })
      if (isValidParticipants) {
        return true
      } else {
        return false
      }
    }

    setDefaultLocationType = activity => {
      const result = { ...activity }
      if (!activity.activity_uid && !activity.location_type) {
        // new activity set default onsite
        result.location_type = 'ON_SITE'
      }
      return result
    }

    validateForm = (activity, host) => {
      const isValidAddress = getIsValidMeetingAddress(activity)
      let isValidForm = false
      const isValidParticipants = this.validateParticipants(
        activity.participants
      )
      if (
        activity.activity_status_uid &&
        activity.subject &&
        host &&
        host.user.user_uid &&
        activity.start_time &&
        activity.location_type &&
        isValidParticipants &&
        isValidAddress
      ) {
        isValidForm = true
      }
      return isValidForm
    }

    handleSaveMeetingCompleteState = async (activity, completed) => {
      await this.setStateAsync({ isSavingCompleteState: true })
      const command = {
        type: completed
          ? 'activity.complete_meeting'
          : 'activity.unlock_completed_meeting',
        aggregate_uid: activity.activity_uid,
        aggregate_type: 'activity',
        payload: {
          activity: {
            activity_uid: activity.activity_uid
          },
          completed: completed
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqAccountActivities,
            variables: {
              account_uid: activity.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })
        await this.setStateAsync({ isSavingCompleteState: false })
        return res.data.cqCommand
      } catch (err) {
        console.log('Error running command')
        await this.setStateAsync({ isSavingCompleteState: false })
        throw err
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleSaveStatus={this.handleSaveStatus}
          handleDeleteMeeting={this.handleDeleteMeeting}
          isUpdatingStatus={this.state.isUpdatingStatus}
          isDeletetingMeeting={this.props.isSaving}
          mapApiToMeetingPersons={this.mapApiToMeetingPersons}
          validateParticipants={this.validateParticipants}
          isValidParticipants={this.state.isValidParticipants}
          participantsErrors={this.state.participantsErrors}
          setDefaultLocationType={this.setDefaultLocationType}
          validateForm={this.validateForm}
          handleSaveMeetingCompleteState={this.handleSaveMeetingCompleteState}
          isSavingCompleteState={this.state.isSavingCompleteState}
          // handleCompleteMinutes={this.handleCompleteMinutes}
        />
      )
    }
  }

  EditMeetingHOC.propTypes = {
    currentUser: shape({}).isRequired
  }

  const EditMeetingHOCWithCommand = crmGenericCommandHoc(EditMeetingHOC)

  const WithQuery = props => <EditMeetingHOCWithCommand {...props} />
  return WithQuery
}

export const saveHandler = async (value, props, isManagerMode = false) => {
  props.handleErrors([])
  if (props.isSaving) {
    throw new Error('Command is running')
  }
  if (!value && value !== '') {
    props.handleErrors(['No value passed'])
    throw new Error('No value passed')
  }
  if (!props.activity) {
    props.handleErrors(['No activity passed'])
    throw new Error('No activity passed')
  }
  if (!props.activity.activity_uid) {
    props.handleErrors(['Activity is missing activity_uid'])
    throw new Error('Activity is missing activity_uid')
  }
  if (!props.fieldName) {
    props.handleErrors(['No fieldName passed'])
    throw new Error('No fieldName passed')
  }
  if (props.fieldName === 'subject' && value === '') {
    props.handleErrors(['Subject is required'])
    throw new Error('Subject is required')
  }
  if (!getIsValidMeetingAddress(value)) {
    props.handleErrors(['Meeting address is required for on site location'])
    throw new Error('Meeting address is required for on site location')
  }

  const activityData = {
    activity_uid: props.activity.activity_uid
  }
  const refetchQueries = [
    {
      query: qlqAccountActivities,
      variables: {
        account_uid: props.activity.account_uid
      }
    }
  ]

  if (
    props.fieldName === 'guests' ||
    props.fieldName === 'participants' ||
    props.fieldName === 'arrangedBy' ||
    props.fieldName === 'host'
  ) {
    const parse = props.getParseUpdateParticipantsToServerByKind(
      props.fieldName,
      props.activity,
      value
    )
    const commandCreate = {
      type: 'activity.addParticipants',
      aggregate_uid: props.activity.activity_uid,
      aggregate_type: 'activity',
      payload: {
        activity: {
          activity_uid: props.activity.activity_uid,
          participants: parse.createParticipants
        }
      }
    }
    const commandRemove = {
      type: 'activity.removeParticipants',
      aggregate_uid: props.activity.activity_uid,
      aggregate_type: 'activity',
      payload: {
        activity: {
          activity_uid: props.activity.activity_uid,
          participants: parse.removeParticipants
        }
      }
    }
    try {
      if (parse.removeParticipants.length > 0) {
        await props.onRunCommand(commandRemove, {
          refetchQueries
        })
      }
      if (parse.createParticipants.length > 0) {
        await props.onRunCommand(commandCreate, {
          refetchQueries
        })
      }
    } catch (err) {
      console.log('Error running command')
      throw err
    }
  } else {
    if (props.fieldName === 'location_type') {
      activityData.location_type = value.location_type
      // clear address if not on site
      activityData.address_uid =
        value.location_type === 'ON_SITE' ? value.address_uid : ''
    } else if (props.fieldName === 'start_time') {
      const activity = {
        ...props.activity,
        start_time: value
      }
      const updatedActivity = parseActivityToServer(activity)
      activityData.start_time = updatedActivity.start_time
    } else {
      activityData[props.fieldName] = value
    }
    const variables = {
      activity_uid: props.activity.activity_uid,
      activity: activityData
    }
    try {
      await props.client.mutate({
        mutation: qlmUpdateActivity,
        variables,
        refetchQueries
      })
    } catch (err) {
      props.handleErrors(err)
      console.log('Error running command')
      throw err
    }
  }
}

export default editMeetingHoc
