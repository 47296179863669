import React from 'react'
import { graphql } from 'react-apollo'
import crmGenericSelectQLHocFactory from 'crm-components/ql/crm-generic-select-ql-hoc.jsx'
import { campaignQmQl } from 'crm-data/campaigns'
import { Icon } from 'util-components'
import { string } from 'prop-types'

const mapCampaignToOptions = c => ({
  value: c.campaign_uid,
  key: c.campaign_uid,
  name: c.name,
  is_active: c.is_active
})

const query = graphql(campaignQmQl, {
  options: ownProps => {
    // console.log('Campaign select options', ownProps)

    const filterParams = {}
    if (ownProps.campaignType) {
      filterParams.type = ownProps.campaignType
    } else {
      if (ownProps.type) {
        filterParams.type = ownProps.type
      }
    }

    if (ownProps.filter && ownProps.filter.is_active) {
      filterParams.is_active = ownProps.filter.is_active
    }

    return {
      variables: {
        // Support different names due to some backwards compat
        filter: filterParams,
        limit: ownProps.limit ? ownProps.limit : 100,
        offset: ownProps.offset ? ownProps.offset : 0
      }
    }
  },
  props: ({ campaignQmQl, ownProps }) => {
    // console.log('CampaignSelect', campaignQmQl, ownProps)
    return {
      ...ownProps,
      campaignQmQl,
      prefix: 'campaign_list_qm_paged',
      optionsQl: campaignQmQl,
      options:
        campaignQmQl && campaignQmQl.campaign_list_qm_paged
          ? campaignQmQl.campaign_list_qm_paged.data
              .map(mapCampaignToOptions)
              .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
          : undefined,
      count:
        campaignQmQl && campaignQmQl.campaign_list_qm_paged
          ? campaignQmQl.campaign_list_qm_paged.count
          : undefined,
      isLoading: campaignQmQl ? campaignQmQl.loading : false
    }
  },
  name: 'campaignQmQl',
  fetchPolicy: 'no-cache'
})

const CampaignSelect = crmGenericSelectQLHocFactory(query, {
  defaultProps: {
    // FfilterWithApi: true, // eslint-disable-line
    // FfilterFieldOnApi: 'name', // eslint-disable-line
    limit: 1000
    // EenableFetchMore: true // eslint-disable-line
  },
  queryName: 'campaignQmQl'
})

CampaignSelect.propTypes = {}
CampaignSelect.defaultProps = {}

// export default CampaignSelect

class CrmCampaignSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showInactive: false
    }
  }

  handleShowInactive = () => {
    this.setState({ showInactive: !this.state.showInactive })
  }

  toolboxRenderer = () => (
    <div>
      <div>
        <Icon
          name="archive"
          title="Show archived campaigns"
          style={{
            position: 'absolute',
            top: '10px',
            right: '32px',
            opacity: this.state.showInactive ? '1.0' : '0.4',
            width: '12px'
          }}
          onClick={this.handleShowInactive}
        />
      </div>
    </div>
  )

  render() {
    const activeFilter = this.state.showInactive ? {} : { is_active: true }
    return (
      <CampaignSelect
        {...this.props}
        filter={{
          type: this.props.type,
          ...activeFilter
        }}
        toolboxRenderer={this.toolboxRenderer}
        type={this.props.type}
      />
    )
  }
}

CrmCampaignSelect.propTypes = {
  type: string
}

CrmCampaignSelect.defaultProps = {
  type: 'lead_source'
}

export default CrmCampaignSelect
