/* eslint-disable global-require, react/forbid-prop-types */
import React, { PureComponent } from 'react'
import { any } from 'prop-types'

class CrmFormGroup extends PureComponent {
  // Aconstructor(props) {
  //   super(props)
  //   this.handleNavbarItemClick = this.handleNavbarItemClick.bind(this)
  // }

  handleNavbarItemClick = () => {
    // Aconsole.log('handleNavbarItemClick')
  }

  render() {
    return (
      <div
        className={`CrmFormGroup ${this.props.fullHeight && 'util-fullHeight'}`}
      >
        <div
          className={`CrmFormGroup-body ${this.props.fullHeight &&
            'util-fullHeight'}`}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

CrmFormGroup.defaultProps = {}

CrmFormGroup.propTypes = {
  children: any.isRequired
}

export default CrmFormGroup
