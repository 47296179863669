const widgetDefinition = {
  type: 'analitics_widget',
  code: 'ctDailyLeadsDashboard',
  def_version: 5,
  title: 'RM Meeting Activity',
  description: 'RM Meeting Activity',
  content: {
    data: {
      dataSources: [
        {
          code: 'ActivitiesListQM',
          name: 'MeetingsCreatedFirst',
          type: 'QM',
          filter: {
            activity: {
              type: {
                medium_code: 'meeting'
              },
              first_type: 'first'
            },
            role_code: 'performer'
          },
          contextMapping: {
            activity: {
              start_date: 'activity_date'
            },
            user: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            }
          },
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
            master(master_uid: $master_uid)  { 
                master_uid
                name
                instance(instance_uid: $instance_uid) { 
                    instance_uid
                    name
                   activity_participants(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                       edges { 
                        edge 
                        { 
                           activity_uid
                           user_uid
                           lead_source_type_name
                       }
                       }
                   }
                }
            }
        }`,
          qlVariables: {
            aggregate: [{ id: 'activity_uid', type: 'count' }],
            groupBy: ['user_uid', 'lead_source_type_name']
          },
          qlEdges: true,
          qlOnly: true,
          qlRoot: 'activity_participants'
        },
        {
          code: 'ActivitiesListQM',
          name: 'MeetingsCreatedFollowup',
          type: 'QM',
          filter: {
            activity: {
              first_type: 'follow_up',
              activity_type: {
                medium_code: 'meeting'
              }
            },
            role_code: 'performer'
          },
          fields: ['performer_user_uid'],
          contextMapping: {
            activity: {
              start_date: 'activity_date'
            },
            user: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            }
          },
          qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
          activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
              edges { edge { activity_uid user_uid lead_source_type_name } }
              pageInfo { count }
            }
          }`,
          qlVariables: {
            aggregate: [{ id: 'activity_uid', type: 'count' }],
            groupBy: ['user_uid', 'lead_source_type_name']
          },
          qlEdges: true,
          qlOnly: true,
          qlRoot: 'activity_participants'
        },
        {
          code: 'ActivitiesListQM',
          name: 'MeetingsAll',
          type: 'QM',
          filter: {
            activity: {
              activity_type: {
                medium_code: 'meeting'
              }
            },
            role_code: 'performer'
          },
          contextMapping: {
            user: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            },
            activity: {
              start_date: 'activity_date'
            }
          },
          qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
            activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                edges { edge { activity_uid user_uid lead_source_type_name} }
                pageInfo { count }
              }
            }`,
          qlVariables: {
            aggregate: [{ id: 'activity_uid', type: 'count' }],
            groupBy: ['user_uid', 'lead_source_type_name']
          },
          qlEdges: true,
          qlOnly: true,
          qlRoot: 'activity_participants'
        },
        {
          code: 'ActivitiesListQM',
          alias: 'MeetingsCompleted',
          type: 'QM',
          filter: {
            activity: {
              activity_type: {
                medium_code: 'meeting'
              },
              status: {
                is_success: true
              }
            },
            role_code: 'performer'
          },
          contextMapping: {
            user: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            },
            activity: {
              start_date: 'activity_date'
            }
          },
          qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
          activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
              edges { edge { activity_uid user_uid lead_source_type_name } }
              pageInfo { count }
            }
          }`,
          qlVariables: {
            aggregate: [{ id: 'activity_uid', type: 'count' }],
            groupBy: ['user_uid', 'lead_source_type_name']
          },
          qlEdges: true,
          qlOnly: true,
          qlRoot: 'activity_participants'
        },
        {
          code: 'ActivitiesListQM',
          alias: 'MeetingsCanceled',
          type: 'QM',
          filter: {
            activity: {
              type: { medium_code: 'meeting' },
              status: { kind: 'canceled' }
            },
            role_code: 'performer'
          },
          fields: ['performer_user_uid'],
          contextMapping: {
            user: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            },
            activity: {
              start_date: 'activity_date'
            }
          },
          qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
            activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                edges { edge { activity_uid user_uid lead_source_type_name } }
                pageInfo { count }
              }
            }`,
          qlVariables: {
            aggregate: [{ id: 'activity_uid', type: 'count' }],
            groupBy: ['user_uid', 'lead_source_type_name']
          },
          qlEdges: true,
          qlOnly: true,
          qlRoot: 'activity_participants'
        },
        {
          code: 'ActivitiesListQM',
          alias: 'MeetingsOverdue',
          type: 'QM',
          filter: {
            activity: {
              type: { medium_code: 'meeting' },
              status: { kind: 'sheduled' },
              start_date: { $lte: 'now()' }
            },
            role_code: 'performer'
          },
          contextMapping: {
            user: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            }
          },
          qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
            activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                edges { edge { activity_uid user_uid lead_source_type_name} }
                pageInfo { count }
              }
            }`,
          qlVariables: {
            aggregate: [{ id: 'activity_uid', type: 'count' }],
            groupBy: ['user_uid', 'lead_source_type_name']
          },
          qlEdges: true,
          qlOnly: true,
          qlRoot: 'activity_participants'
        },
        {
          code: 'ActivitiesListQM',
          alias: 'MeetingsPending',
          type: 'QM',
          filter: {
            activity: {
              activity_type: {
                medium_code: 'meeting'
              },
              status: {
                kind: 'sheduled'
              },
              start_date: { $gte: 'now()' }
            },
            role_code: 'performer'
          },
          fields: ['performer_user_uid'],
          contextMapping: {
            user: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            }
          },
          qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
            activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                edges { edge { activity_uid user_uid lead_source_type_name} }
                pageInfo { count }
              }
            }`,
          qlVariables: {
            aggregate: [{ id: 'activity_uid', type: 'count' }],
            groupBy: ['user_uid', 'lead_source_type_name']
          },
          qlEdges: true,
          qlOnly: true,
          qlRoot: 'activity_participants'
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {},
          $noContextFilter: true,
          qlQuery: `query master($master_uid: String!, $instance_uid: String!) {
            master(master_uid: $master_uid)  { 
                master_uid
                name
                instance(instance_uid: $instance_uid) { 
                    instance_uid
                    name
                    users { 
                      user_uid
                      is_active
                      full_name
                      contract_start_date
                      contract_end_date
                      department { 
                        name 
                      }
                    }
                }
            }
        }`,
          qlRoot: 'users'
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyFields: [
          'user_uid',
          'account__lead_campaign__lead_source__name'
        ],
        mapping: [
          {
            fromDataSourceName: 'MeetingsCreatedFirst',
            keyFields: {
              user_uid: 'user_uid',
              account__lead_campaign__lead_source__name: 'lead_source_type_name'
            },
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_created_first'
              }
            ]
          },
          {
            fromDataSourceName: 'MeetingsCreatedFollowup',
            keyFields: {
              user_uid: 'user_uid',
              account__lead_campaign__lead_source__name: 'lead_source_type_name'
            },
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_created_followup'
              }
            ]
          },
          {
            fromDataSourceName: 'MeetingsAll',
            keyFields: {
              user_uid: 'user_uid',
              account__lead_campaign__lead_source__name: 'lead_source_type_name'
            },
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_all'
              }
            ]
          },
          {
            fromDataSourceName: 'MeetingsCompleted',
            keyFields: {
              user_uid: 'user_uid',
              account__lead_campaign__lead_source__name: 'lead_source_type_name'
            },
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_completed'
              }
            ]
          },

          {
            fromDataSourceName: 'MeetingsCanceled',
            keyFields: {
              user_uid: 'user_uid',
              account__lead_campaign__lead_source__name: 'lead_source_type_name'
            },
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_canceled'
              }
            ]
          },
          {
            fromDataSourceName: 'MeetingsOverdue',
            keyFields: {
              user_uid: 'user_uid',
              account__lead_campaign__lead_source__name: 'lead_source_type_name'
            },
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_overdue'
              }
            ]
          },
          {
            fromDataSourceName: 'MeetingsPending',
            keyFields: {
              user_uid: 'user_uid',
              account__lead_campaign__lead_source__name: 'lead_source_type_name'
            },
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_pending'
              }
            ]
          }
        ]
      }
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          mob_fix_columns: 2,
          props: {
            generate_summary: true,
            generate_average: true,
            columnGroups: [
              {
                Header: '',
                colSpan: 3
              },
              {
                Header: 'Statistics for period',
                colSpan: 5
              },
              {
                Header: 'Current meeting status',
                colSpan: 2
              }
            ],
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 30,
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Lead Source',
                width: 150,
                accessor: 'account__lead_campaign__lead_source__name'
              },
              {
                Header: 'First Meetings Created ',
                accessor: 'meetings_created_first',
                width: 100,
                type: 'number',
                detailsPopup: 'activities',
                detailsFilter: {
                  type__medium_code: 'meeting',
                  first_type: 'first'
                },
                detailsFilterMapping: {
                  performer_user_uid: 'user_uid'
                },
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Followup Meetings Created ',
                accessor: 'meetings_created_followup',
                width: 100,
                type: 'number',
                detailsPopup: 'activities',
                detailsFilter: {
                  type__medium_code: 'meeting',
                  first_type: 'follow_up'
                },
                detailsFilterMapping: {
                  performer_user_uid: 'user_uid'
                },
                className: ' util-pointer',
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Total Meetings Created',
                accessor: 'meetings_all',
                width: 100,
                type: 'number',
                detailsPopup: 'activities',
                detailsFilter: {
                  type__medium_code: 'meeting'
                },
                detailsFilterMapping: {
                  performer_user_uid: 'user_uid'
                },
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: ' Meetings Completed',
                accessor: 'meetings_completed',
                width: 100,
                type: 'number',
                className: 'util-pointer',
                detailsPopup: 'activities',
                detailsFilter: {
                  type__medium_code: 'meeting',
                  status__is_success: 'true'
                },
                detailsFilterMapping: {
                  performer_user_uid: 'user_uid'
                },
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Meetings Canceled',
                accessor: 'meetings_canceled',
                width: 100,
                type: 'number',
                className: 'util-pointer',
                detailsPopup: 'activities',
                detailsFilter: {
                  type__medium_code: 'meeting',
                  status__kind: 'canceled'
                },
                detailsFilterMapping: {
                  performer_user_uid: 'user_uid'
                },
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Overdue Meetings',
                accessor: 'meetings_overdue',
                width: 100,
                type: 'number',
                className: 'util-pointer',
                detailsPopup: 'activities',
                detailsFilter: {
                  status__kind: 'sheduled',
                  start_date: { $lte: 'now()' }
                },
                detailsFilterMapping: {
                  performer_user_uid: 'user_uid'
                },
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Pending Meetings',
                accessor: 'meetings_pending',
                width: 100,
                type: 'number',
                className: 'util-pointer',
                detailsPopup: 'activities',
                detailsFilter: {
                  status__kind: 'sheduled',
                  start_date: { $gte: 'now()' }
                },
                detailsFilterMapping: {
                  performer_user_uid: 'user_uid'
                },
                align: 'right',
                headerAlign: 'center'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}
export default widgetDefinition
