import React from 'react'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider as ApolloHook } from '@apollo/react-hooks'
import { ApolloProvider } from 'react-apollo'
import { getApiRootUrl } from 'crm-api/crm-apollo-http-link'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'

const uri = `${getApiRootUrl()}crm_ql.php?reports=true`
const httpLink = createHttpLink({
  uri
})

const ErrorLink = onError(data => {
  const { graphQLErrors, networkError } = data
  graphQLErrors?.map(({ message, locations, path }) => {
    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
  })
  networkError && console.log(`[Network error]: ${networkError}`)
})

const middlewareLink = new ApolloLink((operation, forward) => {
  const crmInstance = window.localStorage.getItem('crm_instance')
  const user = window.localStorage.getItem('crmUser')
  const instance_uid = JSON.parse(crmInstance)?.instance_uid || ''
  const master_uid = JSON.parse(user)?.master_uid || ''
  operation.setContext({
    headers: {
      authorization: `Bearer ${window.localStorage.getItem('crmAccessToken')}`,
      crm_instance_uid: instance_uid ?? '',
      instanceUid: instance_uid ?? '',
      instance_uid: instance_uid ?? '',
      masterUid: master_uid ?? '',
      master_uid: master_uid ?? ''
    }
  })
  return forward(operation)
})

const link = ErrorLink.concat(middlewareLink).concat(httpLink)
const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export const ReportProvider = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <ApolloHook client={client}>{children}</ApolloHook>
    </ApolloProvider>
  )
}
