/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import gql from 'graphql-tag'
import { graphql, compose } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { CrmButton } from 'crm-components'
import { actions as activityActions } from 'crm-duxs/activity-reducer'
import { qlmUpdateActivityStatus } from 'crm-data/activities'
import { LoadingIcon } from 'util-components'

const MODE_CONFIRM_CONFIRM = 'CONFIRM_CONFIRM'
const MODE_CONFIRM_DELETE = 'MODE_CONFIRM_DELETE'
const MODE_NORMAL = 'STATE_NORMAL'
const MODE_CONFIRM = 'MODE_CONFIRM'

class MeetingStatusWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mode: MODE_NORMAL
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState({ ...newState }, () => {
        resolve()
      })
    })

  findActivityStatusByCode(code) {
    return this.props.data.activity_statuses
      ? this.props.data.activity_statuses.find(s => s.code === code)
      : undefined
  }

  handleNewStatusClick = event => {
    const newStatus = event.currentTarget.id
    this.setState({ mode: MODE_CONFIRM, newStatus })
  }

  async _changeStatusTo(code) {
    const newStatus = this.findActivityStatusByCode(code)
    console.dir(newStatus)
    const activityUpdate = {
      activity_uid: this.props.activity.activity_uid,
      update_activity_status_uid: newStatus.activity_status_uid
    }
    const onSuccess = () => {
      this.setState({ mode: MODE_NORMAL, newStatus: undefined })
      this.props.onChanged()
    }
    this.props.doUpdateActivity({ activity: activityUpdate, onSuccess })
  }

  handleConfirm = () => {
    this.setState({ mode: MODE_CONFIRM_CONFIRM })
  }
  handleDelete = () => {
    this.setState({ mode: MODE_CONFIRM_DELETE })
  }

  handleDeleteYes = () => {
    this._changeStatusTo('canceled_us')
  }

  handleNo = () => {
    this.setState({ mode: MODE_NORMAL })
  }
  handleYes = async () => {
    console.dir(this.state.newStatus)
    if (!this.state.newStatus) {
      return toastr.error('No status')
    }
    const newStatus = this.findActivityStatusByCode(this.state.newStatus)
    if (!newStatus) {
      toastr.error("Can't find new status by code: ", this.state.newState)
    }
    const variables = {
      activity_uid: this.props.activity.activity_uid,
      activity_status_uid: newStatus.activity_status_uid
    }
    await this.setStateAsync({ isSaving: true })
    const res = await this.props.qlmUpdateActivityStatus({ variables })
    if (
      res.data &&
      res.data.activity_update_status &&
      res.data.activity_update_status.result === 'OK'
    ) {
      toastr.info('Status updated!')
      this.setState({ mode: MODE_NORMAL, newStatus: undefined })
      this.props.onChanged()
    } else {
      toastr.error('Problem updating meeting status')
    }
    await this.setStateAsync({ isSaving: false })
  }
  handleConfirmYes = () => {
    console.dir(this.state)
    if (this.state.newStatus) {
      this._changeStatusTo(this.state.newStatus)
    }
  }

  handleCancel = () => {}
  handleCreateMinutes = () => {
    this.props.doEndActivityEdit()
    setTimeout(() => {
      this.props.onGotoMinutes()
    })
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data.activity_statuses !== this.props.data.activity_statuses
    ) {
      // eslint-disable-line
    }
  }

  render() {
    const { activity, isFinalStatus } = this.props
    const { mode } = this.state
    console.dir(this.props)
    const currentStatusCode =
      activity && activity.activity_status
        ? activity.activity_status.code
        : undefined
    return (
      <div>
        <fieldset className="CrmForm-form-fieldset">
          <div className="row util-marginBottom">
            {activity.activity_status && (
              <div className="ActivityListItem-headerItem col-md-4">
                <span>
                  <FormattedMessage
                    id="crm.ui.activity.status"
                    defaultMessage="STATUS"
                  />
                </span>
                <div className="ActivityListItem-headerItem-value">
                  <span>{activity.activity_status.description}</span>
                </div>
              </div>
            )}
            {mode === MODE_NORMAL &&
              !this.props.isEditing &&
              currentStatusCode !== 'success' && (
                <div className="col-md-8">
                  <CrmButton
                    grouped
                    label={
                      <FormattedMessage
                        id="crm.ui.activity.canceled_us"
                        defaultMessage="Canceled by us"
                      />
                    }
                    id="canceled_us"
                    small
                    onClick={this.handleNewStatusClick}
                  />
                  <CrmButton
                    grouped
                    label={
                      <FormattedMessage
                        id="crm.ui.activity.canceled_client"
                        defaultMessage="Canceled by client"
                      />
                    }
                    id="canceled_client"
                    small
                    onClick={this.handleNewStatusClick}
                  />
                  {currentStatusCode !== 'sheduled_confirmed' && (
                    <CrmButton
                      grouped
                      label={
                        <FormattedMessage
                          id="crm.ui.activity.confirm"
                          defaultMessage="Confirm"
                        />
                      }
                      id="sheduled_confirmed"
                      small
                      onClick={this.handleNewStatusClick}
                    />
                  )}
                  {currentStatusCode &&
                    currentStatusCode === 'sheduled_confirmed' && (
                      <CrmButton
                        grouped
                        label={
                          <FormattedMessage
                            id="crm.ui.activity.un_confirm"
                            defaultMessage="Un-Confirm"
                          />
                        }
                        id="sheduled_not_confirmed"
                        small
                        onClick={this.handleNewStatusClick}
                      />
                    )}
                  {/* <CrmButton
                grouped
                label="Delete Meeting"
                onClick={this.handleDelete}
              /> */}
                </div>
              )}
            {mode === MODE_CONFIRM_CONFIRM && (
              <div className="col-md-12 ">
                <div className="util-paddingMd">
                  <span>
                    <FormattedMessage
                      id="crm.ui.activity.want_to_make_meeting"
                      defaultMessage={
                        'Are you sure you wan\'t to make meeting "Confirmed" ?'
                      }
                    />
                  </span>
                  <CrmButton
                    grouped
                    small
                    label={
                      <FormattedMessage
                        id="crm.ui.button.no"
                        defaultMessage="No"
                      />
                    }
                    onClick={this.handleNo}
                  />
                  <CrmButton
                    grouped
                    small
                    label={
                      <FormattedMessage
                        id="crm.ui.button.yes"
                        defaultMessage="Yes"
                      />
                    }
                    onClick={this.handleConfirmYes}
                  />
                </div>
              </div>
            )}
            {mode === MODE_CONFIRM && (
              <div className="col-md-12">
                <div className="util-paddingMd">
                  <span className="util-util-marginRight">
                    <FormattedMessage
                      id="crm.ui.activity.want_to_change_meeting_status"
                      defaultMessage={
                        "Are you sure you wan't to change meeting status ?"
                      }
                    />
                  </span>
                  <CrmButton
                    grouped
                    small
                    label={
                      <FormattedMessage
                        id="crm.ui.button.no"
                        defaultMessage="No"
                      />
                    }
                    onClick={this.handleNo}
                  />
                  <CrmButton
                    grouped
                    small
                    label={
                      <FormattedMessage
                        id="crm.ui.button.yes"
                        defaultMessage="Yes"
                      />
                    }
                    type="primary"
                    onClick={this.handleYes}
                  />
                </div>
                {this.state.isSaving && (
                  <div>
                    <LoadingIcon />
                    <FormattedMessage
                      id="crm.ui.activity.saving"
                      defaultMessage="Saving ..."
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {activity && activity.activity_uid && (
            <div
              className="row"
              style={{ backgroundColor: 'green', color: 'white' }}
            >
              <div className="col-md-8 util-paddingSm">
                {isFinalStatus ? (
                  <FormattedMessage
                    id="crm.ui.activity.meeting_is_already_completed"
                    defaultMessage="This meeting is already completed"
                  />
                ) : (
                  <FormattedMessage
                    id="crm.ui.activity.to_complete_meeting"
                    defaultMessage="To Complete meeting - use 'Create Minutes' button"
                  />
                )}
              </div>
              <div className="col-md-4 util-paddingSm util-textRight">
                <CrmButton
                  type="primary"
                  grouped
                  label={
                    isFinalStatus ? (
                      <FormattedMessage
                        id="crm.ui.button.show_meeting_minutes"
                        defaultMessage="Show Meeting Minutes"
                      />
                    ) : (
                      <FormattedMessage
                        id="crm.ui.button.create_meeting_minutes"
                        defaultMessage="Create Meeting Minutes"
                      />
                    )
                  }
                  onClick={this.handleCreateMinutes}
                />
              </div>
            </div>
          )}
        </fieldset>
      </div>
    )
  }
}

MeetingStatusWidget.propTypes = {
  activity: shape({}).isRequired,
  data: shape().isRequired,
  isEditing: bool.isRequired,
  doUpdateActivity: func.isRequired,
  onGotoMinutes: func.isRequired,
  doEndActivityEdit: func.isRequired,
  isFinalStatus: bool.isRequired,
  qlmUpdateActivityStatus: func.isRequired,
  onChanged: func.isRequired
}

MeetingStatusWidget.defaultProps = {}

const listActivityStatusesQuery = gql`
  query activity_statuses($activity_type_uid: String, $medium_code: String) {
    activity_statuses(
      activity_type_uid: $activity_type_uid
      medium_code: $medium_code
    ) {
      activity_status_uid
      kind
      is_success
      name
      description
      code
      is_active
      is_initial
      is_result
    }
  }
`

const MeetingStatusWidgetQL = compose(
  graphql(listActivityStatusesQuery, {
    options: ownProps => ({
      variables: { activity_type_uid: ownProps.activity.activity_type_uid } // eslint-disable-line
    }),
    props: ({ data, ownProps }) => ({ data, ...ownProps })
  }),
  graphql(qlmUpdateActivityStatus, { name: 'qlmUpdateActivityStatus' })
)(MeetingStatusWidget)

function mapDispatchToProps(dispatch) {
  return {
    doUpdateActivity: (activity, params) =>
      dispatch(activityActions.updateActivity.create(activity, params)),
    doEndActivityEdit: () => dispatch(activityActions.endActivityEdit.create())
  }
}
export default connect(null, mapDispatchToProps)(MeetingStatusWidgetQL)
