import React, { useState } from 'react'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import { FilterSection } from '../tools'
import { LeadFilterValueString, LeadFilterDropDown } from '.'

export const FilterSectionLead = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleDropDown = () => setIsExpanded(!isExpanded)
  const closeDropDown = () => setIsExpanded(false)
  const { filters, setFilters } = useAnalyticsContext()
  return (
    <FilterSection
      name="Leads"
      selectedValue={<LeadFilterValueString filterValues={filters} />}
      onToggle={toggleDropDown}
      isExpanded={isExpanded}
      {...props}
    >
      {isExpanded && <LeadFilterDropDown
        filterValues={filters}
        onSetFilters={setFilters}
        isExpanded={isExpanded}
        onClickedOutside={closeDropDown}
      />}
    </FilterSection>
  )
}
