import React from 'react'
import { string } from 'prop-types'

const UserInfo = props => (
  <figure className="AdminUserInfo">
    {props.avatar ? <img src={props.avatar} className="AdminUserInfo" /> : null}
    <span>{props.username}</span>
  </figure>
)

UserInfo.propTypes = {
  avatar: string,
  username: string.isRequired
}

UserInfo.defaultProps = {
  avatar: ''
}

export default UserInfo
