import React, { PureComponent } from 'react'
import CrmHookRequestLogTable from './crm-hook-request-logs-table.jsx'
import {
  CrmFullpageContainer,
  CrmDateRangeDropdown,
  CrmTextInput
} from 'crm-components'

class CrmHookRequestLog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      startDateLog: new Date(),
      endDateLog: new Date(),
      filterText: undefined
    }
  }

  handleDateChange = date => {
    const nextState = {
      ...this.state,
      startDateLog: date.startDate,
      endDateLog: date.endDate
    }
    this.setState(nextState)
  }

  handleUpdateFilter = event => {
    this.setState({ filterText: event.currentTarget.value })
  }

  render() {
    console.log('Render Hook Request log')
    const value = {
      startDate: this.state.startDateLog,
      endDate: this.state.endDateLog
    }
    return (
      <CrmFullpageContainer hasPadding vbox>
        <div fill>
          <div className="row">
            <div className="col-md-6">
              <CrmDateRangeDropdown
                name="log_date_range"
                onChange={this.handleDateChange}
                dateFormat="DD-MM-YYYY"
                input={{
                  value: value
                }}
              />
            </div>
            <div className="col-md-6">
              <CrmTextInput
                placeholder="Search text. (Support all columns)"
                input={{
                  value: this.state.filterText,
                  onChange: this.handleUpdateFilter
                }}
              />
            </div>
          </div>
        </div>
        <CrmHookRequestLogTable
          startDate={this.state.startDateLog}
          endDate={this.state.endDateLog}
          filterText={this.state.filterText}
        />
      </CrmFullpageContainer>
    )
  }
}

export default CrmHookRequestLog
