import React from 'react'
import { groupDataByMonth } from 'crm-utils/data-utils'
import { createDashboardFormats, useQueryActivity, getWidgetAnalyticContext } from '../../dashboards/tools'
import { DashboardResponse, GQLQueryRes, HeadCount } from 'crm-types'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import moment from 'moment'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export interface widgetAmountOfCallHocProps {
  avgAmountOfCallCurrentMonthPerPerson: number | null
  avgAmountOfCallPassedMonthData: number | any[any]
  avgAmountOfCallPersonPassedMonth: number | null
  avgAmountOfCallThreeMonth: number
  footerName: string | null
  footerNamePerPerson: string | null
  isLoading?: boolean
  refetchData?: () => any
  threeMonthAgoAmountOfCall: any[any]
  totalAmountOfCall: number
  onClickDetail?: Function
  mainCaption?: string
}

export type useGetAmountOfCallByMonth = (
  numberOfMonth?: number,
  headcounts?: HeadCount[]
) => DashboardResponse & GQLQueryRes

export const useGetAmountOfCallByMonth: useGetAmountOfCallByMonth = (numberOfMonth = 3, headcounts) => {
  const analyticsContext = getWidgetAnalyticContext()
  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const filter = {
    user_uid: headcounts ? undefined : userUid,
    organization_unit,
    account_is_deleted: false,
    activity: {
      activity_type: {
        medium_code: 'phone_call'
      },
      status: {
        is_success: true
      },
      start_time: {
        $gte: startDate,
        $lte: endDate
      }
    }
  }

  const { listActivity, loading, refetch } = useQueryActivity(
    filter,
    ['start_date_month'],
    [{ id: 'activity_uid', type: 'count' }]
  )
  const statistics = groupDataByMonth(listActivity, 'start_date_month', 'activity_uid')

  return {
    refetch,
    loading,
    ...createDashboardFormats(statistics, numberOfMonth, moment(endDate).format('YYYY-MM'), headcounts, 'Agent')
  }
}

const widgetAmountOfCallHoc = (Component: any) => {
  const Wrapper = props => {
    const { headcounts } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      dataAvgPerPersonPassedMonth,
      avgPerPersonPassedMonth,
      avgPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      dataPassedMonths,
      totalLatestMonth,
      mainCaption
    } = useGetAmountOfCallByMonth(3, headcounts)
    const onClickDetail = (monthSelected = null) => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)
      const filters = {
        activity_date: convertMonthToDateRange(endDate),
        type: {
          medium_code: 'phone_call'
        },
        status: { is_success: true },
        performer_user_uid: headcounts ? undefined : userUid,
        organization_unit
      }

      if (monthSelected) {
        filters.activity_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'disposition', filters })
    }

    return (
      <Component
        avgAmountOfCallCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgAmountOfCallPassedMonthData={dataAvgPerPersonPassedMonth}
        avgAmountOfCallPersonPassedMonth={avgPerPersonPassedMonth}
        avgAmountOfCallThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        onClickDetail={onClickDetail}
        refetchData={() => refetch()}
        threeMonthAgoAmountOfCall={dataPassedMonths}
        totalAmountOfCall={totalLatestMonth}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetAmountOfCallHoc
