import React from 'react'
import { func, string, arrayOf, shape, object } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import filtersRenderers from './filters-renderers.jsx'
import { CrmCheckbox, CrmButton } from 'crm-components'

import { toggleFilter } from 'crm-duxs/accounts-reducer'
import {
  setFilterValue,
  getFilterGroup,
  getFiltersDefinitions,
  clearFiltersGroup
} from 'crm-duxs/filters-reducer'
import { get } from 'lodash'

const isDeletedFilter = { code: 'include_deleted', field: 'include_deleted' }
const includeDuplicatesFilter = {
  code: 'include_duplicates',
  field: 'include_duplicates'
}
class MoreFilterContainer extends React.PureComponent {
  getFilterKey = filter => (filter.code ? filter.code : filter.field)

  getCurrentFilterValue = filter => {
    const key = this.getFilterKey(filter)
    return this.props.filters && this.props.filters[key]
      ? this.props.filters[key]
      : {}
  }

  updateFilter = (filter, update) => {
    const currentValues = this.getCurrentFilterValue(filter)
    const newFilterParams = {
      ...currentValues,
      ...update
    }

    this.props.doSetFilterValue(
      this.props.groupCode,
      this.getFilterKey(filter),
      newFilterParams
    )
  }

  handleToggleFilter = (filter, value) => {
    const currentValues = this.getCurrentFilterValue(filter)

    this.updateFilter(filter, {
      enabled: currentValues.enabled ? !currentValues.enabled : value
    })
  }

  handleFilterParamsChange = (filter, value) => {
    this.updateFilter(filter, {
      value
    })
  }

  renderFilter = filter => {
    const currentValue = this.getCurrentFilterValue(filter)

    const toggleHandler = value => {
      if (currentValue.fixed) {
        toastr.info("You can't disable this filter")
        return
      }

      this.handleToggleFilter(filter, value)
    }

    const props = {
      style: { width: '100%' },
      onChange: event => {
        this.handleFilterParamsChange(filter, event)
      },
      value: currentValue.value,
      filterDef: filter,
      disabled: currentValue.fixed
    }

    const Renderer = filtersRenderers[filter.component]

    return (
      <div key={filter.field || filter.code} className="util-marginRight">
        <div
          className={`util-paddingSm util-flexRow
        ${currentValue.enabled ? 'util-bgOrange util-borderRadiusMd' : ''}`}
        >
          <div className="util-marginRight">
            <CrmCheckbox
              style={{ whiteSpace: 'no-wrap' }}
              label={currentValue.enabled ? '' : filter.name}
              input={{
                value: get(currentValue, 'enabled', false),
                onChange: toggleHandler
              }}
              disabled={currentValue.fixed}
              noWrap
            />
          </div>
          {Renderer && currentValue.enabled && <Renderer {...props} />}
        </div>
      </div>
    )
  }

  handleClearFilters = () => {
    this.props.doClearFiltersGroup(this.props.groupCode)
  }

  handleToggleIncludeDeleted = value => {
    const currentValues = this.getCurrentFilterValue(isDeletedFilter)

    this.updateFilter(isDeletedFilter, {
      enabled: currentValues.enabled ? !currentValues.enabled : value
    })
  }

  handleToggleIncludeDuplicates = value => {
    const currentValues = this.getCurrentFilterValue(includeDuplicatesFilter)

    this.updateFilter(includeDuplicatesFilter, {
      enabled: currentValues.enabled ? !currentValues.enabled : value
    })
  }

  renderFilters = () => {
    const { filtersDefinitions } = this.props
    const availableFilters = []

    filtersDefinitions.forEach(obj => {
      if (get(obj, 'excludeFromGroupCode', []).includes(this.props.groupCode))
        return

      if (obj.hasOwnProperty('showInGroupCode')) {
        if (obj.showInGroupCode.indexOf(this.props.groupCode) >= 0)
          availableFilters.push(this.renderFilter(obj))
      } else {
        availableFilters.push(this.renderFilter(obj))
      }
    })

    return <div className="row">{availableFilters}</div>
  }

  render() {
    const { filtersDefinitions } = this.props
    const currentValuesIsDeleted = this.getCurrentFilterValue(isDeletedFilter)
    const isIncludeDeleted = get(currentValuesIsDeleted, 'enabled', false)
    const currentValuesIncludeDuplicates = this.getCurrentFilterValue(
      includeDuplicatesFilter
    )
    const isIncludeDuplicates = get(
      currentValuesIncludeDuplicates,
      'enabled',
      false
    )

    return (
      <div className="CrmMdCard CrmMdCard--rounded util-paddingMd util-bo">
        <div className="row">
          <div className="col-md-3" style={{ paddingBottom: 10 }}>
            Filters{' '}
            <CrmButton label="Clear" xsmall onClick={this.handleClearFilters} />{' '}
          </div>
          <div className="col-md-3">
            <CrmCheckbox
              label="Include deleted accounts"
              input={{
                value: isIncludeDeleted,
                onChange: this.handleToggleIncludeDeleted
              }}
            />
          </div>
          <div className="col-md-3">
            <CrmCheckbox
              label="Show duplicates"
              input={{
                value: isIncludeDuplicates,
                onChange: this.handleToggleIncludeDuplicates
              }}
            />
          </div>
          <div className="col-md-3" />
          <div className="col-md-3" style={{ alignContent: 'right' }} />
        </div>
        <div className="row">{this.renderFilters()}</div>
      </div>
    )
  }
}

MoreFilterContainer.propTypes = {
  disableFilters: arrayOf(string),
  groupCode: string,
  filters: shape().isRequired,
  filtersDefinitions: arrayOf(object).isRequired,
  doSetFilterValue: func.isRequired,
  doClearFiltersGroup: func.isRequired
}

MoreFilterContainer.defaultProps = {
  groupCode: 'global'
}

function mapStateToProps(state, ownProps) {
  return {
    filters: getFilterGroup(state, ownProps.groupCode || 'global'),
    filtersDefinitions: getFiltersDefinitions(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doToggleFilter: (filterName, disableFilters, accountsListCode) =>
      dispatch(toggleFilter(filterName, disableFilters, accountsListCode)),
    doSetFilterValue: (groupCode, filterCode, params) =>
      dispatch(setFilterValue(groupCode, filterCode, params)),
    doClearFiltersGroup: groupCode => dispatch(clearFiltersGroup(groupCode))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreFilterContainer)
