import React, { PureComponent } from 'react'
import { shape, string, func, arrayOf } from 'prop-types'
import widgetUtilsHoc from '../widget-utils-hoc'
import ShortAnalyticsAccountsList from './short-accounts-list-ql-hoc.jsx'

class DigitalLeadsShortListWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentWillMount() {
    this.handleRefresh()
  }

  handleRefresh = async () => {
    // console.log('DigitalLeadsShortListWidget refresh')
    // console.log(this.listRef)
    if (this.listRef) {
      this.listRef.doRefetch()
    }
  }

  handleRef = ref => {
    this.listRef = ref
  }
  handleClickStatus = event => {
    // console.log('handleClickStatus', event)
    this.props.onDashboardEvent({
      type: 'accountStatusSelected',
      data: {
        status: event
      }
    })
  }
  handleClickBar = event => {
    // console.log('handleClickBar', event)
  }

  render() {
    return <ShortAnalyticsAccountsList ref={this.handleRef} {...this.props} />
  }
}

DigitalLeadsShortListWidget.propTypes = {
  analyticsContext: shape({}).isRequired,
  labelField: string.isRequired,
  groups: arrayOf(shape({})).isRequired,
  onDashboardEvent: func.isRequired
}

export default widgetUtilsHoc(DigitalLeadsShortListWidget)
