import React, { SFC } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withCrmContext } from 'crm-core/contexts/crm-context'

export const qlqOpportunityTypes = gql`
  query opportunity_types($master_uid: String!) {
    master(master_uid: $master_uid) {
      master_uid
      name
      opportunity_types {
        opportunity_type_uid
        name
        instance {
          name
        }
        is_active
        master_uid
      }
    }
  }
`

interface CrmOpportunityTypeProps {
  accountUid?: string
  placeholder?: string
  crmContext: any
}

const CrmOpportunityTypeSelect: SFC<CrmOpportunityTypeProps> = props => {
  const variables = {
    master_uid: props.crmContext.master.master_uid
  }
  return (
    <Query query={qlqOpportunityTypes} variables={variables}>
      {({ data }) => {
        const options = data?.master?.opportunity_types
          ? data.master.opportunity_types.map(v => ({
              ...v,
              value: v.opportunity_type_uid
            }))
          : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder
                ? props.placeholder
                : this.props.intl.formatMessage({
                    id: 'crm.ui.dropdown.select_opportunity_type',
                    defaultMessage: 'Select Opportunity Type'
                  })
            }
          />
        )
      }}
    </Query>
  )
}

export default injectIntl(withCrmContext(CrmOpportunityTypeSelect))
