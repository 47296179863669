/* eslint-disable camelcase */
import { buildCrmReducer } from 'crm-utils/reducer-utils'
import { initNewAccount } from 'crm-utils/accounts-utils'

// Actions
export const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS'
export const CREATE_NEW_ACCOUNT = 'CREATE_NEW_ACCOUNT'

export const SAVE_NEW_ACCOUNT = 'SAVE_NEW_ACCOUNT'
export const SAVE_NEW_ACCOUNT_SUCCESS = 'SAVE_NEW_ACCOUNT_SUCCESS'
export const SAVE_ACCOUNT_ERROR = 'SAVE_ACCOUNT_ERROR'

export const SAVE_UPDATE_ACCOUNT = 'SAVE_UPDATE_ACCOUNT'
export const SAVE_UPDATE_ACCOUNT_SUCCESS = 'SAVE_UPDATE_ACCOUNT_SUCCESS'

export const START_ACCOUNT_EDIT = 'START_ACCOUNT_EDIT'
export const END_ACCOUNT_EDIT = 'END_ACCOUNT_EDIT'

export const ADD_CONTACT_EMAIL = 'ADD_CONTACT_EMAIL'
export const MARK_AS_CUSTOMER = 'MARK_AS_CUSTOMER'

export const ACCOUNT_FIELDS_UPDATED = 'ACCOUNT_FIELDS_UPDATED'
export const SET_ATTACHMENT_FILES_EMAIL = 'SET_ATTACHMENT_FILES_EMAIL'
export const TOGGLE_ACCOUNT_IS_LOADING = 'TOGGLE_ACCOUNT_IS_LOADING'

export const FETCH_CAMPAIGNS = 'FETCH_ACCOUNT_CAMPAIGNS'
export const MARK_CONTACT_VERIFIED = 'MARK_CONTACT_VERIFIED'
export const SET_RECIPIENT_EMAIL = 'SET_RECIPIENT_EMAIL'

const changeStateinChild = (state, action, key) => {
  const deletedId = action.payload.deleteid
  if (!deletedId) return state

  const childDeleted = Object.assign({}, state.childDeleted)
  const deletedIds = childDeleted[key] ? childDeleted[key].slice() : []
  deletedIds.push(deletedId)

  return {
    ...state,
    childDeleted: { ...state.childDeleted, [key]: deletedIds }
  }
}

export const actions = {
  removeAddress: {
    handler: (state, action) =>
      changeStateinChild(state, action, 'addresses_delete')
  },
  removeContactDetails: {
    handler: (state, action) =>
      changeStateinChild(state, action, 'contact_details_delete')
  }
}

// Reducer
const initialState = {
  account: undefined,
  childDeleted: {},
  isEditing: false,
  isSaving: false,
  isLoading: false,
  hasToolsWindow: false,
  recipientEmails: {},
  attachmentFiles: {}
}

function accountReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_SUCCESS:
      return { ...state, account: action.payload.account, childDeleted: {} }
    case SAVE_NEW_ACCOUNT:
      return { ...state, isSaving: true }
    case SAVE_UPDATE_ACCOUNT:
      return { ...state, isSaving: true }
    case CREATE_NEW_ACCOUNT:
      return {
        ...state,
        account: initNewAccount(),
        isEditing: true,
        isSaving: false
      }
    case SAVE_NEW_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload.account,
        childDeleted: {},
        isEditing: false,
        isSaving: false
      }
    case SAVE_ACCOUNT_ERROR: {
      return {
        ...state,
        isEditing: true,
        isSaving: false,
        saveError: action.payload.saveError
      }
    }
    case SAVE_UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload.account,
        childDeleted: {},
        isEditing: false,
        isSaving: false
      }
    case START_ACCOUNT_EDIT:
      return { ...state, isEditing: true }
    case END_ACCOUNT_EDIT:
      return { ...state, isEditing: false }
    case TOGGLE_ACCOUNT_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading }
    case SET_RECIPIENT_EMAIL: {
      return { ...state, recipientEmails: action.payload.toEmails }
    }
    case SET_ATTACHMENT_FILES_EMAIL: {
      return { ...state, attachmentFiles: action.payload.attachmentFiles }
    }
    default:
      return state
  }
}

export function fetchCampaigns() {
  return {
    type: FETCH_CAMPAIGNS
  }
}

// Action Creators
export function fetchAccount(id, params) {
  return {
    type: FETCH_ACCOUNT,
    payload: {
      accountId: id,
      params
    }
  }
}
export const toggleAccountIsLoading = isLoading => ({
  type: TOGGLE_ACCOUNT_IS_LOADING,
  payload: { isLoading }
})
export function fetchAccountSuccess(account) {
  return {
    type: FETCH_ACCOUNT_SUCCESS,
    payload: {
      account
    }
  }
}

export function createNewAccount(account) {
  return {
    type: CREATE_NEW_ACCOUNT,
    payload: {
      account
    }
  }
}

export function saveNewAccount(account) {
  return {
    type: SAVE_NEW_ACCOUNT,
    payload: {
      account
    }
  }
}

export function saveUpdateAccount(account, params) {
  return {
    type: SAVE_UPDATE_ACCOUNT,
    payload: {
      account,
      params
    }
  }
}

export function setRecipientEmails(toEmails) {
  return { type: SET_RECIPIENT_EMAIL, payload: { toEmails } }
}

export function setAttachmentFiles(attachmentFiles) {
  return { type: SET_ATTACHMENT_FILES_EMAIL, payload: { attachmentFiles } }
}

export const markAsCustomer = accountUid => ({
  type: MARK_AS_CUSTOMER,
  payload: {
    accountUid
  }
})

export function startAccountEdit() {
  return {
    type: START_ACCOUNT_EDIT
  }
}
export function cancelAccountEdit() {
  return {
    type: END_ACCOUNT_EDIT
  }
}

export function accountFieldsUpdated(accountUid, fields, onSuccess) {
  return {
    type: ACCOUNT_FIELDS_UPDATED,
    payload: { fields, accountUid, onSuccess }
  }
}

export function addEmail(accountUid, email) {
  return {
    type: ADD_CONTACT_EMAIL,
    payload: { email, accountUid }
  }
}

export const markContactVerified = (contact, verified, onSuccess) => ({
  type: MARK_CONTACT_VERIFIED,
  payload: { contact, verified, onSuccess }
})

// Selectors
export const getAccount = state => state.crm.account.account
export const getChildAccountDeleted = state => state.crm.account.childDeleted
export const getIsEditing = state => state.crm.account.isEditing
export const getIsSaving = state => state.crm.account.isSaving
export const getIsLoadingAccount = state => state.crm.account.isLoading
export const getRecipientEmails = state => state.crm.account.recipientEmails
export const getAttachmentFiles = state => state.crm.account.attachmentFiles
const reducer = buildCrmReducer(accountReducer, actions)
export default reducer
