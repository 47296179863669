/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { array, shape, func, string } from 'prop-types'
import CrmWidget from '../../../components/crm-widget.jsx'
import widgetFitmentsArpuByDepartmentHoc from './widget-fitments-arpu-by-department-hoc'
import { FlatTable } from 'crm-components'

class WidgetFitmentsArpuByDepartment extends React.PureComponent {
  constructor(props) {
    super(props)
    this.columns = [
      {
        accessor: 'department_type.name',
        Header: 'Department',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <span onClick={handleClick}>
              {row.original.department_type
                ? row.original.department_type.name
                : 'empty'}
            </span>
          )
        }
      },
      {
        accessor: 'quantity',
        Header: 'Units Fitted',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.quantity
                ? Number(row.original.quantity).toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                    useGrouping: true
                  })
                : ''}
            </div>
          )
        }
      },
      {
        accessor: 'hardware_price',
        Header: 'HW Sum',
        align: 'right',
        type: 'number',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.hardware_price
                ? Number(row.original.hardware_price).toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 0
                    }
                  )
                : ''}
            </div>
          )
        }
      },
      {
        accessor: 'subscription',
        Header: 'MRR Sum',
        align: 'right',
        type: 'number',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.subscription
                ? Number(row.original.subscription).toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                    useGrouping: true
                  })
                : ''}
            </div>
          )
        }
      },
      {
        accessor: 'avg_subscription',
        Header: 'MRR Avg',
        align: 'right',
        type: 'number',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.avg_subscription
                ? row.original.avg_subscription.toLocaleString(undefined, {
                    maximumFractionDigits: 0
                  })
                : ''}
            </div>
          )
        }
      }
    ]
  }

  handleRefresh = async () => {
    try {
      return await this.props.onRefresh()
    } catch (err) {
      toastr.error('Problem getting stats')
    }
  }

  handleCellClicked = cell => {
    const filters = {
      ...this.props.analyticsContext.filters,
             status_code: 'completed'
    }
    if (cell.department_type) {
      filters.department_type_uid = {
        value: cell.department_type_uid,
        label: cell.department_type.name
      }
      if (cell.department_type && cell.department_type.name === "Total") {
        delete filters.department_type_uid
       }
    } else {
        filters.department_type_uid = null
    }
    this.props.onAnalyticsRangeSelected({ type: 'fitment', filters })
  }
  handleGetRawData = () => this.props.data

  render() {
    return (
      <CrmWidget
        title={
          this.props.title ? this.props.title : ' Fitments ARPU by Department '
        }
        subtitle=" "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-new-leads-by-status"
      >
        {!this.props.analyticsContext.filters.activity_date && (
          <span>You have to select activity date range filter </span>
        )}

        {this.props.data && (
          <FlatTable
            columns={this.columns}
            data={this.props.data}
            onCellClicked={this.handleCellClicked}
          />
        )}
      </CrmWidget>
    )
  }
}

WidgetFitmentsArpuByDepartment.propTypes = {
  analyticsContext: shape({}).isRequired,
  onRefresh: func.isRequired,
  onAnalyticsRangeSelected: func.isRequired,
  title: string,
  data: array
}

WidgetFitmentsArpuByDepartment.defaultProps = {
  title: undefined,
  data: undefined
}

export default widgetFitmentsArpuByDepartmentHoc(WidgetFitmentsArpuByDepartment)
