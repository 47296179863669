import React from 'react'
import { string } from 'prop-types'

const VerticalYLabel = props => (
  <g>
    <text textAnchor="middle" x="-100px" y="25px" transform="rotate(270)">
      {props.text}
    </text>
  </g>
)

VerticalYLabel.propTypes = {
  text: string.isRequired
}

export default VerticalYLabel
