/* eslint-disable camelcase */
import React from 'react'
import { shape, func } from 'prop-types'
import agentDetailsB2BDashboardHoc, {
  propTypes
} from '../../hoc/ct-dashboards/agent-details/agent-details-b2b-dashboard-hoc'
import { GenericWidgetWeb } from '@cartrack-crm/crm-analytics'
import { CrmButton, CrmInfoBlock } from 'crm-components'
import agentB2BHistoricalActualsWidget from 'crm-modules/dashboard/hoc/ct-dashboards/agent-details/agent-details-b2b-historical-actuals-widget'
import agentLeadResultsWidget from 'crm-modules/dashboard/hoc/ct-dashboards/agent-details/agent-lead-results-widget'
import agentDetailsLiveCallActivityWidget from 'crm-modules/dashboard/hoc/ct-dashboards/agent-details/agent-details-live-call-activity'
import userOpportunitiesWidget from 'crm-modules/dashboard/hoc/ct-dashboards/rm-details/user-opportunities-widget'
import { UserInfoCard } from './rm-summary-dashboard.jsx'

class AgentSummaryB2BDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentWillMount() {}

  renderTable1() {
    return (
      <div className="CrmMdCard CrmMdCard--md">
        <div className="CrmMdCard-title">Title for this table</div>
        <div className="CrmMdCard-content" />
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.isUserRequiredError && (
          <CrmInfoBlock warning>
            Select user from filters on top to run this dashboard
          </CrmInfoBlock>
        )}
        {!this.props.isUserRequiredError && (
          <div style={{ marginBottom: 10 }}>
            <CrmButton
              label="Refresh"
              onClick={this.props.onRefresh}
              primary
              isLoading={this.props.isLoading}
            />
          </div>
        )}

        {!this.props.isUserRequiredError && (
          <React.Fragment>
            <div className="util-flexRow  util-marginBottom">
              <div
                style={{ width: '20%', paddingRight: 10 }}
                className="util-flexColumn"
              >
                {this.props.user && <UserInfoCard {...this.props} />}
              </div>
            </div>

            <GenericWidgetWeb
              widgetDefinition={agentB2BHistoricalActualsWidget}
              key={agentB2BHistoricalActualsWidget.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              noHeight
            />

            <GenericWidgetWeb
              widgetDefinition={agentLeadResultsWidget}
              key={agentLeadResultsWidget.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              noHeight
            />
            <GenericWidgetWeb
              widgetDefinition={agentDetailsLiveCallActivityWidget}
              key={agentDetailsLiveCallActivityWidget.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              noHeight
            />

            <GenericWidgetWeb
              title={'Agent Opportunities'}
              widgetDefinition={userOpportunitiesWidget}
              key={userOpportunitiesWidget.code}
              analyticsContext={this.props.analyticsContext}
              onAnalyticsItemClicked={this.handleAnalyticsItemClicked}
              noHeight
            />
          </React.Fragment>
        )}
      </div>
    )
  }
}

AgentSummaryB2BDashboard.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired,
  ...propTypes
}

export default agentDetailsB2BDashboardHoc(AgentSummaryB2BDashboard)
