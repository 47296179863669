export const OPEN_CONFIRMATION = 'OPEN_CONFIRMATION'
export const CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION'

// Reducer
const initialState = {
  message: '',
  verbs: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CONFIRMATION:
      return { message: action.payload.message, verbs: action.payload.verbs }
    case CLOSE_CONFIRMATION:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function openConfirmation(message, verbs) {
  return {
    type: OPEN_CONFIRMATION,
    payload: { message, verbs }
  }
}

export function closeConfirmation(confirmed) {
  return {
    type: CLOSE_CONFIRMATION,
    payload: { confirmed }
  }
}

// Selectors
export const getMessage = state => state.confirmationModal.message
export const getVerbs = state => state.confirmationModal.verbs
