/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'

import { dialerCampaignQl } from './telesales-dash-utils'
import { graphql, compose } from 'react-apollo'
import { arrayOf, shape, func } from 'prop-types'

import CrmWidget from '../../components/crm-widget.jsx'
import { CrmFullpageContainer, CrmDropdown, CrmButton } from 'crm-components'
import { mapContextToFilter } from '../../dashboard-utils'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  ComposedChart,
  YAxis,
  XAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  Label
} from 'recharts'

class DashTelesalesCampaignStats extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ ...this.state, isLoading: val }, handler)
  }

  parseData(result) {
    console.log('DashLeadsDetails parseData')
    console.dir(result)
    this.setState({ ...this.state, data: result.data }, () => {
      this.toggleIsLoading(false)
    })
  }

  handleRefresh = async () => {
    console.log('DashTelesalesStatsByOwner.refreshStats by status', this.props)
    try {
      const filter = mapContextToFilter(this.props)
      if (this.props.analyticsContext) {
        if (this.props.analyticsContext.campaign_uid) {
          filter.campaign_uid = this.props.analyticsContext.campaign_uid
        }
      }
      filter.process_completed_date = 'null'
      const variables = {
        filter,
        fields: ['owner_user_name', 'is_overdue'],
        aggregate: [{ id: 'process_uid', type: 'count' }]
      }
      if (this.props.leadInDateRange) {
        variables.filter.lead_in_date = {
          $gte: this.props.leadInDateRange.startDate,
          $lte: this.props.leadInDateRange.endDate
        }
      }

      const res = await this.props.client.query({
        query: dialerCampaignQl,
        variables,
        fetchPolicy: 'no-cache'
      })

      console.log('Got result', res)
      this.setState({
        statsByCurrentStepResults: res.data
          .dialer_campaign_accounts_list_qm_paged
          ? res.data.dialer_campaign_accounts_list_qm_paged.data
          : undefined
      })
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }
  handleClickBar = event => {
    console.log('handleClickBar', event, this.props)
    const filters = {
      ...this.props.analyticsContext.filters,
      owner_user_name: event.name
    }

    this.props.onAnalyticsRangeSelected({ type: 'lead', filters })
  }
  renderChart(stats) {
    // Aggregate by users
    let data = stats.reduce((users, row) => {
      if (!users[row.owner_user_name]) {
        users[row.owner_user_name] = {
          overdue_count: 0,
          current_count: 0,
          name: row.owner_user_name
        }
      }
      if (row.is_overdue) {
        a
        users[row.owner_user_name].overdue_count += row.count
      }
      if (!row.is_overdue) {
        users[row.owner_user_name].current_count += row.count
      }
      users[row.owner_user_name].total_count =
        users[row.owner_user_name].current_count +
        users[row.owner_user_name].overdue_count
      return users
    }, {})

    data = Object.keys(data).map(k => data[k])
    data = data.sort((a, b) => b.total_count - a.total_count)
    const renderLabel = props => {
      console.log('render Label', props)
      return <div>Label</div>
    }
    console.log('render chart data', data)
    // layout="vertical"
    return (
      <ResponsiveContainer width="90%" height="90%">
        <BarChart width={730} height={250} data={data} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis dataKey="name" type="category" label={renderLabel} />
          <XAxis type="number" />
          <Tooltip />
          {/* <Legend /> */}
          <Bar
            dataKey="total_count"
            fill="#8884d8"
            onClick={this.handleClickBar}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  }
  render() {
    console
    const allAccountsCount = this.state.statsByCurrentStepResults
      ? this.state.statsByCurrentStepResults.reduce((a, i) => a + i.count, 0)
      : 0
    return (
      <CrmWidget
        title={'Pending Leads by owner'}
        subtitle="Only not completed leads - by current owner"
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        fixHeight
        autoRefresh
      >
        {this.state.statsByCurrentStepResults &&
          this.renderChart(this.state.statsByCurrentStepResults)}
      </CrmWidget>
    )
  }
}

DashTelesalesCampaignStats.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  onCurrentStepClick: func.isRequired
}

DashTelesalesCampaignStats.defaultProps = {
  dashboards: []
}

const DashTelesalesCampaignStatsQL = withApollo(DashTelesalesCampaignStats)

export default DashTelesalesCampaignStatsQL
