/* eslint-disable */
import apiCaller, { callQLApi } from './api-utils'
import { utcTimestampToMs } from 'util-functions/time-utils'

export const qlTaskOwnFields = ` task_uid account_uid task_type_uid name status_code priority completed_time completed_by_user_uid result_status_code `
export const qlTaskAssignmentFields = ` task_assignment_uid task_uid assignee_type user_uid person_uid due_date_type due_date_precision due_date completed_time completed_by_user_uid  user { user_uid full_name } `
export const qlTaskTypeFields = ` name code  `
export const qlTaskFieldsWithAssignments = ` ${qlTaskOwnFields} assignments { ${qlTaskAssignmentFields} } account { account_uid name } task_type { ${qlTaskTypeFields}} `

function parseTask(task) {
  const assignments = task.assignments
    ? task.assignments.map(ass => ({
        ...ass,
        due_date: utcTimestampToMs(ass.due_date)
      }))
    : undefined
  return {
    ...task,
    assignments
  }
}

export default {
  listAllTasks: params => {
    const qlListTasks = `{ tasks { ${qlTaskFieldsWithAssignments} } }`
    return callQLApi(qlListTasks).then(res => {
      console.log('GOTqlListTasksRES')
      console.dir(res)
      return res.tasks.map(parseTask)
    })
  },
  listMyTasks: params => {
    // T O D O - show onyl my tasks
    const qlListTasks = `{ my_tasks { ${qlTaskFieldsWithAssignments} } }`
    return callQLApi(qlListTasks).then(res => {
      console.log('GOTqlListTasksRES')
      console.dir(res)
      return res.my_tasks.map(parseTask)
    })
  }
}
