import React from 'react'
import sectionAddressesHoc from 'crm-modules/accounts/hoc/section-addresses-hoc.js'
import AddressForm from 'crm-modules/accounts/components/layout19/address-item'
import SectionAddressInner from './section-address-inner'

class SectionAddress extends React.PureComponent {
  render() {
    return (
      <SectionAddressInner {...this.props}>
        <AddressForm />
      </SectionAddressInner>
    )
  }
}

export default sectionAddressesHoc(SectionAddress)
