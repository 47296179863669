const agentB2BHistoricalActuals = {
  type: 'analitics_widget',
  code: 'ctAgentHistoricalActuals',
  def_version: 5,
  title: 'Historical Actuals',
  defaultFilters: {
    // department_uid: {
    //   value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
    //   label: 'Telesales'
    // },
    organization_unit: {
      department_type_uid: {
        value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
        label: 'Telesales'
      }
    }
  },
  content: {
    data: {
      dataSources: [
        {
          code: 'ActivitiesListQM',
          name: 'ActivitiesListQM',
          alias: 'AnsweredCalls',
          type: 'QM',
          filter: {
            status__is_success: true,
            type__medium_code: 'phone_call'
          },
          fields: ['performer__user_uid', 'performer__full_name'],
          aggregate: [
            { id: 'activity_uid', type: 'count' },
            { id: 'call_length', type: 'sum' }
          ],
          contextMapping: {
            start_date: 'activity_date',
            performer__user_uid: 'user_uid',
            performer__department_uid: 'department_uid',
            performer__organization_unit_uid: 'organization_unit_uid'
          }
        },
        {
          code: 'ActivitiesListQM',
          name: 'ActivitiesListQM',
          alias: 'NotAnsweredCalls',
          type: 'QM',
          filter: {
            status__is_success: false,
            type__medium_code: 'phone_call'
          },
          fields: ['performer__user_uid', 'performer__full_name'],
          aggregate: [{ id: 'activity_uid', type: 'count' }],
          contextMapping: {
            start_date: 'activity_date',
            performer__user_uid: 'user_uid',
            performer__department_uid: 'department_uid',
            performer__organization_unit_uid: 'organization_unit_uid'
          }
        },
        {
          code: 'ActivitiesListQM',
          name: 'ActivitiesListQM',
          alias: 'TotalCalls',
          type: 'QM',
          filter: {
            type__medium_code: 'phone_call'
          },
          fields: ['performer__user_uid', 'performer__full_name'],
          aggregate: [
            { id: 'activity_uid', type: 'count' },
            {
              id: 'account_uid',
              type: 'count',
              distinct: true,
              alias: 'calls_unique_leads'
            },
            { id: 'call_length', type: 'sum' }
          ],
          contextMapping: {
            start_date: 'activity_date',
            performer__user_uid: 'user_uid',
            performer__department_uid: 'department_uid',
            performer__organization_unit_uid: 'organization_unit_uid'
          }
        },

        {
          code: 'OrdersListQM',
          name: 'OrdersListQM',
          type: 'QM',
          filter: {
            status_code: 'completed'
          },
          fields: ['owner_user_uid'],
          aggregate: [{ id: 'vehicles_count', type: 'sum' }],
          contextMapping: {
            completed_date: 'activity_date',
            owner__user_uid: 'user_uid',
            owner__department_uid: 'department_uid',
            owner__organization_unit_uid: 'organization_unit_uid'
          }
        },
        {
          code: 'OpportunitiesListQM',
          alias: 'WonOpportunities',
          type: 'QM',
          filter: {
            status_code: 'won'
          },
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {
            completed_time: 'activity_date',
            owner: {
              department_uid: 'department_uid',
              organization_unit_uid: 'organization_unit_uid'
            },
            owner_user_uid: 'user_uid'
          },
          qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
              opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                  edges { edge { opportunity_uid owner_user_uid owner_user_uid vehicle_count } }
                  pageInfo { count }
                }
              }`,
          qlVariables: {
            aggregate: [
              { id: 'vehicle_count', type: 'sum' },
              { id: 'opportunity_uid', type: 'count' }
            ],
            groupBy: ['owner_user_uid'],
            limit: 10,
            filter: {}
          },
          qlEdges: true,
          qlRoot: 'opportunities'
        },
        {
          code: 'ActivitiesListQM',
          alias: 'MeetingsCreated',
          type: 'QM',
          filter: {
            type__medium_code: 'meeting'
          },
          fields: ['created_by__user_uid'],
          aggregate: [{ id: 'activity_uid', type: 'count' }],
          contextMapping: {
            created_date: 'activity_date',
            created_by__user_uid: 'user_uid',
            created_by__department_uid: 'department_uid'
          }
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {},
          fields: [
            'department_uid',
            'full_name',
            'user_uid',
            'contract_start_date',
            'contract_end_date',
            'department__name',
            'is_active'
          ]
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyField: 'user_uid',
        mapping: [
          {
            fromDataSourceName: 'AnsweredCalls',
            keyField: 'performer__user_uid',
            mapping: [
              {
                from: 'activity_uid',
                to: 'answered_calls'
              },
              {
                from: 'call_length',
                to: 'answered_calls_time'
              }
            ]
          },
          {
            fromDataSourceName: 'NotAnsweredCalls',
            keyField: 'performer__user_uid',
            mapping: [
              {
                from: 'activity_uid',
                to: 'not_answered_calls'
              }
            ]
          },
          {
            fromDataSourceName: 'TotalCalls',
            keyField: 'performer__user_uid',
            mapping: [
              {
                from: 'activity_uid',
                to: 'total_calls'
              },
              {
                from: 'call_length',
                to: 'total_calls_time'
              },
              {
                from: 'calls_unique_leads',
                to: 'calls_unique_leads'
              }
            ]
          },
          {
            fromDataSourceName: 'OrdersListQM',
            keyField: 'owner__user_uid',
            mapping: [
              {
                from: 'vehicles_count',
                to: 'fitted_units'
              }
            ]
          },
          {
            fromDataSourceName: 'WonOpportunities',
            keyField: 'owner__user_uid',
            mapping: [
              {
                from: 'opportunity_uid',
                to: 'won_opportunities'
              }
            ]
          },
          {
            fromDataSourceName: 'MeetingsCreated',
            keyField: 'created_by__user_uid',
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_created'
              }
            ]
          },
          {
            fromDataSourceName: 'CrmUser',
            keyField: 'user_uid',
            type: 'left',
            mapping: [
              {
                from: 'full_name'
              },
              {
                from: 'contract_start_date'
              },
              { from: 'contract_end_date' },
              { from: 'department__name' },
              { from: 'is_active' }
            ]
          }
        ]
      }
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: false,
            generate_average: false,
            columnGroups: [
              {
                Header: 'Statistics for period',
                colSpan: 8
              }
            ],
            columns: [
              {
                Header: 'Answered Calls',
                accessor: 'answered_calls',
                width: 50,
                type: 'number',
                detailsPopup: 'disposition',
                detailsFilter: {
                  status__is_success: true,
                  type__medium_code: 'phone_call'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Not Answered Calls',
                accessor: 'not_answered_calls',
                width: 50,
                type: 'number',
                detailsPopup: 'disposition',
                detailsFilter: {
                  status__is_success: false,
                  type__medium_code: 'phone_call'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Total Calls',
                accessor: 'total_calls',
                width: 50,
                type: 'number',
                detailsPopup: 'disposition',
                detailsFilter: {
                  type__medium_code: 'phone_call'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Meetings Won',
                accessor: 'meetings_created',
                width: 50,
                type: 'number',
                align: 'right',
                detailsPopup: 'activities',
                detailsFilter: {
                  type__medium_code: 'meeting',
                  created_by__department__name: 'Telesales',
                  activity_date: { $ne: 'null' }
                },
                detailsFilterMapping: {
                  created_by__user_uid: 'user_uid'
                },
                contextMapping: {
                  created_date: 'activity_date'
                },
                className: ' util-pointer',
                headerAlign: 'center'
              },

              {
                Header: 'Conversion (total leads)',
                accessorFn: row => {
                  const totalWon = !isNaN(Number(row.won_opportunities))
                    ? Number(row.won_opportunities)
                    : 0
                  const meetingsCreated = !isNaN(Number(row.meetings_created))
                    ? Number(row.meetings_created)
                    : 0

                  const uniqueLeadsDialled = !isNaN(
                    Number(row.calls_unique_leads)
                  )
                    ? Number(row.calls_unique_leads)
                    : 0
                  if (uniqueLeadsDialled && uniqueLeadsDialled > 0) {
                    return (
                      (100 * (totalWon + meetingsCreated)) / uniqueLeadsDialled
                    )
                  }
                },
                maximumFractionDigits: 2,
                width: 60,
                Cell: 'n/a',
                type: 'number',
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Tasks Completed',
                accessor: '',
                Cell: () => 'n/a',
                width: 60,
                type: 'number',
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Talk Time',
                accessor: 'answered_calls_time',
                width: 60,
                type: 'interval',
                detailsPopup: 'disposition',
                detailsFilter: {
                  status__is_success: true,
                  type__medium_code: 'phone_call'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              },
              {
                Header: 'Dialing Time',
                accessor: 'total_calls_time',
                width: 60,
                type: 'interval',
                detailsPopup: 'disposition',
                detailsFilter: {
                  type__medium_code: 'phone_call'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}

export default agentB2BHistoricalActuals
