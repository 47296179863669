/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'

import { graphql, compose } from 'react-apollo'
import { dialerCampaignQl } from './telesales-dash-utils'
import { qlqProcessType } from 'crm-data/processes'

import { arrayOf, shape, func } from 'prop-types'
import { CrmFullpageContainer, CrmDropdown, CrmButton } from 'crm-components'
import DashTelesalesCampaignStatsByStatus from './dash-telesales-stats-by-status.jsx'
import DashTelesalesCampaignStatsByOwner from './dash-telesales-stats-by-owner.jsx'
import DashTelesalesCampaignLeads from './dash-telesales-campaign-leads.jsx'
import DashTelesalesCampaignStatsGeneral from './dash-telesales-general-stats.jsx'
import WithLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import WidgetProcessInstancesByStep from './widget-process-instances-by-step.jsx'

const columns = []
class DashTelesalesCampaign extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ isLoading: val }, handler)
  }

  parseData(result) {
    // console.log('DashLeadsDetails parseData')
    // console.dir(result)
    this.setState({ data: result.data }, () => {
      this.toggleIsLoading(false)
    })
  }

  refreshStats = async () => {
    // console.log('dashTelesaesCampaign refreshStats', this.props)
    try {
      const filter = {}
      if (this.props.analyticsContext) {
        if (this.props.analyticsContext.campaign_uid) {
          filter.campaign_uid = this.props.analyticsContext.campaign_uid
        }
      }
      const variables = {
        filter,
        fields: ['current_step_name', 'current_step_type_uid'],
        aggregate: [{ id: 'process_uid', type: 'count' }]
      }
      if (this.props.leadInDateRange) {
        variables.filter.lead_in_date = {
          $gte: this.props.leadInDateRange.startDate,
          $lte: this.props.leadInDateRange.endDate
        }
      }

      const res = await this.props.client.query({
        query: qlQuery,
        variables,
        fetchPolicy: 'no-cache'
      })

      // console.log('Got result', res)
      this.setState({
        statsByCurrentStepResults: res.data
          .dialer_campaign_accounts_list_qm_paged
          ? res.data.dialer_campaign_accounts_list_qm_paged.data
          : undefined
      })
    } catch (err) {
      toastr.error('Problem getting stats')
      // console.error(err)
    }
  }
  handleCurrentStepClick = selectedStepType => {
    // console.log('handleCurrentStepClick', selectedStepType)
    this.setState({ selectedStepType })
  }

  handleRefresh = async (event, props) => {
    // console.log('handleRefresh', this.props)
    await this.refreshStats()
    // Build filters
  }

  _mergeWithLocalContext = () => {
    let res = { ...this.props.analyticsContext }
    if (this.state.selectedStepType) {
      res.filters.process_step_type_uid = this.state.selectedStepType.process_step_type_uid
    }
    // console.log('_mergeWithLocalContext', res)
    return res
  }
  handleAnalyticsRangeSelected = params => {
    // console.log('handleAnalyticsRangeSelected', params)
    this.props.onShowLeadsPopup(params)
  }

  render() {
    const allAccountsCount = this.state.statsByCurrentStepResults
      ? this.state.statsByCurrentStepResults.reduce((a, i) => a + i.count, 0)
      : 0
    const detailsFilter = {}
    const localContext = this._mergeWithLocalContext()
    // const { processType } = this.props
    // if (!processType) {
    //   return <div>Loading Process Type</div>
    // }
    return (
      <div>
        {/* <WidgetProcessInstancesByStep
          analyticsContext={this.props.analyticsContext}
        /> */}
        <div className="row">
          <div className="col-md-6">
            <DashTelesalesCampaignStatsGeneral
              analyticsContext={this.props.analyticsContext}
              onCurrentStepClick={this.handleCurrentStepClick}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <DashTelesalesCampaignStatsByStatus
              analyticsContext={this.props.analyticsContext}
              onCurrentStepClick={this.handleCurrentStepClick}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>
          <div className="col-md-6">
            <DashTelesalesCampaignStatsByOwner
              analyticsContext={this.props.analyticsContext}
              onCurrentStepClick={this.handleCurrentStepClick}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>
        </div>
      </div>
    )
  }
}

DashTelesalesCampaign.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

DashTelesalesCampaign.defaultProps = {
  dashboards: []
}

// Dialer type
// const processTypeUid = '273c0b3f-23dd-4d05-ba56-cd34c21d0fd2'

// const DashTelesalesCampaignQL = compose(
//   graphql(qlqProcessType, {
//     options: () => ({
//       variables: {
//         process_type_uid: processTypeUid
//       }
//     }),
//     props: ({ qlqProcessType, ownProps }) => ({
//       ...ownProps,
//       qlqProcessType,
//       processType: qlqProcessType ? qlqProcessType.process_type : undefined
//     }),
//     name: 'qlqProcessType'
//   }),
//   withApollo
// )(DashTelesalesCampaign)

// const DashTelesalesCampaignQL = withApollo(DashTelesalesCampaign)
export default WithLeadsPopupHoc(withApollo(DashTelesalesCampaign))
