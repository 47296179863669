import React from 'react'
import { shape, arrayOf, func, bool } from 'prop-types'
import moment from 'moment'
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl'
import processWidgetHoc from '../../../../processes/components/process-widget-ql-hoc'
import ProcessProgressStep from '../../../../processes/components/process-progress-step.jsx'
import ProcessWidget from '../../../../processes/components/process-widget.jsx'
import AccountPageHeader19 from '../account-page-header-19.jsx'
import styles from './sales-process-widget.scss'
import { calculateCurrentStepType } from 'crm-utils/process-utils'
import { QuotationBarWidget } from '@cartrack-crm/crm-opportunities'
import InfoNoAccountPermission from '../info_no_account_permission.jsx'

class OpportunityInfoEditable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleShowOpportunityModal = () => {
    // console.log('handleShowOpportunityModal')
    this.setState({ isOpportunityModalVisible: true })
  }
  handleCloseOpportunityModal = () => {
    // console.log('handleCloseOpportunityModal')
    this.setState({ isOpportunityModalVisible: false })
  }
  handleOpportunitySaved = () => {
    // toastr.success('Opportunity updated')
    this.setState({ isOpportunityModalVisible: false })
  }

  render() {
    const { activeOpportunity } = this.props
    const opportunityAdaptData = {
      ...activeOpportunity,
      is_existing_customer: activeOpportunity.is_existing_customer
        ? activeOpportunity.is_existing_customer.toString()
        : ''
    }
    console.log('Render Opportunity block')

    const isOverdue =
      activeOpportunity && activeOpportunity.due_date
        ? moment().isAfter(activeOpportunity.due_date)
        : false

    return (
      <div
        style={{
          paddingBottom: 5,
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        <div className="row">
          <div
            className="col-md-12 util-textCenter"
            style={{ paddingTop: 10, fontSize: '1.3em' }}
          >
            Opportunity
          </div>
        </div>
        {!this.state.isOpportunityModalVisible && (
          <React.Fragment>
            <div
              className="util-flexRow"
              onClick={this.handleShowOpportunityModal}
            >
              <div className=" util-paddingMd ">
                <div className="LabeledField-label">
                  <FormattedMessage
                    id="crm.ui.widget.opportunity_size"
                    defaultMessage="Size"
                  />
                </div>
                <div className="LabeledField-value">
                  {activeOpportunity
                    ? activeOpportunity.quantity
                    : 'No opportunity'}
                </div>
              </div>

              {activeOpportunity.process && (
                <div className=" util-paddingMd ">
                  <div className="LabeledField-label">
                    <FormattedMessage
                      id="crm.ui.widget.started"
                      defaultMessage="Started"
                    />
                  </div>
                  <div
                    className="LabeledField-value"
                    title={activeOpportunity.process.started_time}
                  >
                    <FormattedDate
                      value={activeOpportunity.process.started_time}
                    />
                  </div>
                </div>
              )}

              <div className="util-paddingMd ">
                <div className="LabeledField-label">
                  <FormattedMessage
                    id="crm.ui.widget.started"
                    defaultMessage="DUE DATE"
                  />
                </div>
                <div
                  className={`LabeledField-value ${
                    isOverdue ? 'util-textRed' : ''
                  }`}
                >
                  <FormattedDate value={activeOpportunity.due_date} />
                </div>
              </div>

              <div className="util-flexGrow util-paddingMd ">
                <div className="LabeledField-label">
                  <FormattedMessage
                    id="crm.ui.widget.owner"
                    defaultMessage="Owner"
                  />
                </div>
                <div className="LabeledField-value">
                  {activeOpportunity && activeOpportunity.owner_user
                    ? activeOpportunity.owner_user.full_name
                    : 'No owner'}
                </div>
              </div>
            </div>
            {/* <div className="util-paddingMd">
              <ProcessWidget
                process={activeOpportunity.process}
                showName={false}
                opportunity={activeOpportunity}
                editable={false}
              />
            </div> */}
          </React.Fragment>
        )}

        {/* {this.state.isOpportunityModalVisible && (
          <div className="util-paddingMd" style={{ minHeight: 350 }}>
            <OpportunityEditInlineForm
              account={this.props.account}
              onOpportunitySaved={this.handleOpportunitySaved}
              onCancel={this.handleCloseOpportunityModal}
              opportunity={opportunityAdaptData}
            />
          </div>
        )} */}
      </div>
    )
  }
}

class SalesProgressWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShowingResultOptions: false
    }
  }

  _getCurrentStep = () =>
    this.props.process ? this.props.process.current_step : undefined

  handleStepClick = stepType => {
    // console.log('handleStepClick', stepType)
    // Dif (stepType.kind === 'win') {
    //   if (this._haveNotCompletedOpportunities()) {
    //     toastr.info("To win a prospect - Go to Opportunity and click 'Win' ")
    //   } else {
    //     toastr.info('To win a prospect - Create opportunities first')
    //   }
    // } else if (stepType.kind === 'lost') {
    //   if (this._haveNotCompletedOpportunities()) {
    //     toastr.info(
    //       "This prospect have open opportunities - Go to Opportunity and make when 'Win' or 'Lost' "
    //     )
    //   }
    // }
  }

  // _renderStepProposal = () => {
  //   // Render Opportunities details here
  //   if (this.props.opportunities && this.props.opportunities.length > 0) {
  //     const sum = sumOpportunities(this.props.opportunities)
  //     return (
  //       <div>
  //         <FormattedMessage
  //           id="crm.ui.widget.opportunity:"
  //           defaultMessage="Opportunity:"
  //         />
  //         <strong
  //           title={
  //             <FormattedMessage
  //               id="crm.ui.widget.hardware"
  //               defaultMessage="Hardware"
  //             />
  //           }
  //         >
  //           {sum.totalHardware}
  //         </strong>
  //         {' / '}
  //         <strong
  //           title={
  //             <FormattedMessage
  //               id="crm.ui.widget.subscription"
  //               defaultMessage="Subscription"
  //             />
  //           }
  //         >
  //           {sum.totalSubscription}
  //         </strong>
  //       </div>
  //     )
  //   }
  //   return (
  //     <div>
  //       <FormattedMessage
  //         id="crm.ui.widget.create_opportunities"
  //         defaultMessage="Create Opportunities"
  //       />
  //     </div>
  //   )
  // }

  handleRestartProcess = () => {
    // console.log('handleRestartProcess')
  }
  handleToggleShowActions = () => {
    this.setState({
      isShowMoreActions: !this.state.isShowMoreActions
    })
  }

  // eslint-disable-next-line
  _renderStepDetails = () => {
    // Cconst currentStep = this._getCurrentStep()
    // console.log('_renderStepDetails', step)
    // if (step.kind === 'proposal') {
    //   return this._renderStepProposal()
    // }
    return <div />
  }
  handleSelectResult = () => {
    this.setState({
      isShowingResultOptions: !this.state.isShowingResultOptions
    })
  }

  _haveNotCompletedOpportunities = () => {
    // console.log('_haveNotCompletedOpportunities call ')
    const ret =
      this.props.opportunities &&
      this.props.opportunities.filter(
        op =>
          op.process &&
          op.process.current_step &&
          !op.process.current_step.process_step_type.is_final_step
      ).length > 0
    // console.log('_haveNotCompletedOpportunities', this.props.opportunities, ret)
    return ret
  }
  handleShowLostProspectModal = () => {
    // console.log('onShowLostProspectModal')
    this.setState({ showLostProspectModal: true })
  }
  handleLostProspectModalCancel = () => {
    this.setState({ showLostProspectModal: false })
  }
  handleShowManagerOptions = () => {
    this.setState({ isShowingManagerOptions: true })
  }
  handleCloseManagerOptions = () => {
    this.setState({ isShowingManagerOptions: false })
  }

  _renderStep = step => {
    const currentStep = this._getCurrentStep()
    // Cconsole.log('render step', step, currentStep)
    if (!currentStep) {
      return (
        <div className={`ProcessWidget-stepCircle `}>
          <FormattedMessage
            id="crm.ui.widget.no_current_step"
            defaultMessage="No currentStep!"
          />
        </div>
      )
    }
    const title =
      step.process_step_type_uid === currentStep.process_step_type_uid
        ? 'from : ' +
          this.props.intl.formatDate(currentStep.created_time) +
          ' ' +
          this.props.intl.formatTime(currentStep.created_time)
        : ''
    const handleClick = () => {
      this.handleStepClick(step)
    }
    return (
      <ProcessProgressStep
        key={step.process_step_type_uid}
        isCurrentStep={
          currentStep &&
          currentStep.process_step_type_uid === step.process_step_type_uid
        }
        isCompletedStep={
          currentStep &&
          currentStep.process_step_type &&
          currentStep.process_step_type.step > step.step
        }
        title={title}
        onClick={handleClick}
        step={step}
        renderStepDetails={this._renderStepDetails}
      />
    )
  }
  // _renderProcessSteps = ({ steps, process }) => (
  //   <div className="util-paddingMd">
  //     <div className="util-flexRow " style={{ position: 'relative' }}>
  //       <div
  //         className=" ProspectProgressWidget-wrapper"
  //         style={{ fontSize: 12 }}
  //       >
  //         <div className="ProcessWidget-steps">
  //           {steps
  //             .filter(
  //               s =>
  //                 !s.is_final_step ||
  //                 (process.current_step &&
  //                   s.process_step_type_uid ===
  //                     process.current_step.process_step_type_uid)
  //             )
  //             .map(this._renderStep)}
  //           {!this.props.process.completed_time && (
  //             <div className="ProcessWidget-step">
  //               <div className="ProcessWidget-step-content">
  //                 <div className="ProcessWidget-step-content-title">
  //                   <FormattedMessage
  //                     id="crm.ui.widget.completed"
  //                     defaultMessage="Completed"
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )

  handleConverDialerToProspect = () => {
    // console.log('handleConverDialerToProspect')
    this.props.onConvertToB2BProspect()
  }

  renderProcessActions = ({ processType, isProcessCompleted }) => {
    // console.log('renderProcessActions', processType)
    return (
      <div className="util-flexRow" style={{ paddingRight: 10 }}>
        {/* {processKind === 'dialer' &&
        !isProcessCompleted && (
          <CrmButton
            small
            type="secondary"
            label={
              <FormattedMessage
                id="crm.ui.button.convert_dialer_to_b2b_prospect"
                defaultMessage="Convert Dialer To B2B Prospect"
              />
            }
            onClick={this.handleConverDialerToProspect}
          />
        )} */}
        {/* {((processType && processType.kind === 'prospect') ||
          this.props.isManager) &&
        !isProcessCompleted &&
        !this._haveNotCompletedOpportunities() && (
          <div className="util-textCenter util-marginSm util-fullWidth">
            <CrmButton
              label={
                <FormattedMessage
                  id="crm.ui.button.lost_prospect"
                  defaultMessage="Lost Prospect"
                />
              }
              type="primary"
              small
              grouped
              onClick={this.handleShowLostProspectModal}
            />
          </div>
        )} */}
      </div>
    )
  }

  _renderAccountActions = () => {}

  handleResultOptionsCancel = () => {
    this.setState({ isShowingResultOptions: false })
  }

  findActiveOpportunity = () => {
    // console.log('findActiveOpportunity', this.props)
    let ret
    if (this.props.account.opportunities) {
      this.props.account.opportunities.forEach(op => {
        if (op.status_code !== 'won' && op.status_code !== 'lost') {
          ret = op
        }
      })
    }
    return ret
  }

  calculateWonOpportunitiesSize = () =>
    this.props.account && this.props.account.opportunities
      ? this.props.account.opportunities.reduce((a, i) => {
          // console.log('calc wonOpportunitiesSize', i)
          if (i.status_code === 'won') {
            return a + i.quantity
          }
          return a
        }, 0)
      : undefined

  render() {
    console.log('Render Sales Progress Progress widget', this.props)
    const { process, processType, account } = this.props

    let steps = processType ? processType.steps.slice() : []
    steps = steps.sort((a, b) => a.step - b.step)

    const isProcessCompleted = process.completed_time
    const currentStep = this._getCurrentStep()
    const processKind = processType ? processType.kind : undefined
    const activeOpportunity =
      this.props.historyMode || isProcessCompleted
        ? undefined
        : this.findActiveOpportunity()
    const wonOpportunitiesSize = this.calculateWonOpportunitiesSize()

    const { currentStepType } = calculateCurrentStepType(
      this.props.process,
      this.props.processType
    )
    console.log('activeOpportunity', activeOpportunity)
    return (
      <div
        className={`CrmMdCard--skip CrmMdCard--smallFont CrmMdCard--rounded
          util-marginBottom util-flexRow SaleProgressWidget`}
      >
        <div className="util-flexGrow util-fullWidth">
          <AccountPageHeader19
            {...this.props}
            isOpportunity={activeOpportunity}
            hasAccountDataPermission={this.props.hasAccountDataPermission}
          />
          {this.props.hasAccountDataPermission === false && (
            <InfoNoAccountPermission />
          )}
          {this.props.hasAccountDataPermission && (
            <div className="CrmMdCard util-hooverOp util-pointer">
              {/* {this.state.isShowingResultOptions && (
              <div>
                <SelectProcessResultWidget
                  process={this.props.process}
                  account={this.props.account}
                  inline
                  processType={this.props.processType}
                  onCancel={this.handleResultOptionsCancel}
                />
              </div>
            )} */}
              {/* {processType && (
              <LostProspectModal
                isOpen={this.state.showLostProspectModal}
                process={this.props.process}
                processType={this.props.processType}
                onCancel={this.handleLostProspectModalCancel}
                onClose={this.handleLostProspectModalCancel}
              />
            )} */}
              {/* {!this.state.isOpportunityModalVisible && (
              <div className="util-flexRow" style={{ paddingBottom: 5 }}>
                <div
                  className=" util-flexRow util-flexGrow"
                  style={{
                    color: '#75777a'
                  }}
                >
                  {currentStep &&
                  isProcessCompleted && (
                    <div className=" util-flexGrow util-paddingMd">
                      <ProcessResultInlineEditable
                        process={process}
                        editableAsManager={this.props.isManager}
                      />
                    </div>
                  )}
                </div>

                {/* <div className="util-paddingMd util-flexCenterContent">
                <ProcessWidgetMenu
                  isManager={this.props.isManager}
                  onManagerOptions={this.handleShowManagerOptions}
                  onManualySelectResult={this.handleSelectResult}
                  onStartEditAccount={this.props.onEditAccount}
                />
              </div> */}
              {/* </div> */}
              {/* )} */}
              {/* {this.state.isShowingManagerOptions &&
            !activeOpportunity && (
              <div className="util-paddingMd">
                <FormattedMessage
                  id="crm.ui.widget.manager_options"
                  defaultMessage="Manager Options"
                />
                <ProcessWidget process={this.props.process} showName="false" />
              </div>
            )} */}
              {process && (
                <React.Fragment>
                  <div
                    className={`${styles.ProcessHeader}
                ${
                  isProcessCompleted && currentStepType
                    ? styles['ProcessHeader--' + currentStepType.kind]
                    : ' '
                }
                `}
                  >
                    <div className={styles.ProcessHeader__Page1}>
                      <div
                        style={{
                          height: 25,
                          padding: '6px 10px',
                          boxSizing: 'border-box'
                        }}
                      >
                        <ProcessWidget
                          processType={this.props.processType}
                          process={process}
                        />
                      </div>

                      <div className="row">
                        {wonOpportunitiesSize > 0 && (
                          <div className="col-md-3 LabeledField">
                            <div className="LabeledField-label">
                              <FormattedMessage
                                id="crm.ui.widget.opportunity_size"
                                defaultMessage="Won Opportunities"
                              />
                            </div>
                            <div className="LabeledField-value">
                              {wonOpportunitiesSize}
                            </div>
                          </div>
                        )}
                        <div className="col-md-3 LabeledField">
                          <div className="LabeledField-label">
                            <FormattedMessage
                              id="crm.ui.widget.opportunity_size"
                              defaultMessage="Opportunity Size"
                            />
                          </div>
                          <div className="LabeledField-value">
                            {activeOpportunity
                              ? activeOpportunity.quantity
                              : 'No opportunity'}
                          </div>
                        </div>
                        <div className="col-md-3 LabeledField ">
                          <div className="LabeledField-label">
                            <FormattedMessage
                              id="crm.ui.widget.started"
                              defaultMessage="Started"
                            />
                          </div>
                          <div
                            className="LabeledField-value"
                            title={process.started_time}
                          >
                            <FormattedDate value={process.started_time} />
                          </div>
                        </div>
                        {process.owner_user && (
                          <div className="col-md-3 LabeledField ">
                            <div className="LabeledField-label">
                              <FormattedMessage
                                id="crm.ui.widget.owner"
                                defaultMessage="Owner"
                              />
                              <div className="LabeledField-value">
                                {process.owner_user.full_name}
                              </div>
                            </div>
                          </div>
                        )}

                        {activeOpportunity && (
                          <div className="col-md-3 LabeledField ">
                            <div className="LabeledField-label">
                              <FormattedMessage
                                id="crm.ui.widget.started"
                                defaultMessage="Due Date"
                              />
                            </div>
                            <div className="LabeledField-value">
                              <FormattedDate
                                value={activeOpportunity.due_date}
                              />
                            </div>
                          </div>
                        )}

                        {process.completed_time && (
                          <div className="col-md-3 LabeledField ">
                            <div className="LabeledField-label">
                              <FormattedMessage
                                id="crm.ui.widget.started"
                                defaultMessage="Completed"
                              />
                            </div>
                            <div
                              className="LabeledField-value"
                              title={process.completed_time}
                            >
                              <FormattedDate value={process.completed_time} />
                            </div>
                          </div>
                        )}
                      </div>
                      <div style={{ paddingBottom: 10 }} />
                    </div>

                    {this.props.historyMode && (
                      <div className="util-textRed">
                        {"You're working in history mode"}
                      </div>
                    )}
                    <div className={styles.ProcessHeader__Page2}>
                      <div className="row">
                        {process.process_type.name && (
                          <div className="col-md-4 LabeledField">
                            <div className="LabeledField-label">
                              <FormattedMessage
                                id="crm.ui.widget.process_type"
                                defaultMessage="Process Type"
                              />
                            </div>
                            <div className="LabeledField-value">
                              {process.process_type.name}
                            </div>
                          </div>
                        )}
                        {account && account.lead_campaign && (
                          <div className="col-md-4 LabeledField">
                            <div className="LabeledField-label">
                              <FormattedMessage
                                id="crm.ui.widget.sales_campaign"
                                defaultMessage="Sales Campaign"
                              />
                            </div>
                            <div className="LabeledField-value">
                              {process.sales_campaign ? (
                                process.sales_campaign.name
                              ) : (
                                <FormattedMessage
                                  id="crm.ui.widget.missing"
                                  defaultMessage="Missing"
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {/* <div
              className={
                this.state.isOpportunityModalVisible ? (
                  'util-animatedExpandable--expanded'
                ) : (
                  'util-animatedExpandable--collapsed'
                )
              }
              style={{
                maxHeight: this.state.isOpportunityModalVisible ? 350 : 0,
                // minHeight: this.state.isOpportunityModalVisible ? 350 : 0,
                backgroundColor: 'white'
              }}
            /> */}
            </div>
          )}
          {this.props.hasAccountDataPermission && process && activeOpportunity && (
            <React.Fragment>
              {activeOpportunity.documents && (
                <QuotationBarWidget
                  quotations={activeOpportunity.documents}
                  handleShowQuotationTab={this.props.handleShowQuotationTab}
                  handleQuotationClick={this.props.handleQuotationClick}
                  isShowAllQuotations={false}
                />
              )}
              {!activeOpportunity.documents && (
                <div>
                  <FormattedMessage
                    id="crm.ui.widget.no_quotation"
                    defaultMessage="No Quotation"
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>

        {/* <CrmModal
          title={
            <FormattedMessage
              id="crm.ui.account.opportunity.opportunity"
              defaultMessage="Opportunity"
            />
          }
          size="lg"
          isOpen={this.state.isOpportunityModalVisible}
          onClose={this.handleCloseOpportunityModal}
        >
        </CrmModal> */}
      </div>
    )
  }
}

SalesProgressWidget.propTypes = {
  process: shape().isRequired,
  account: shape().isRequired,
  processType: shape(),
  opportunities: arrayOf(shape()),
  isManager: bool.isRequired,
  onConvertToB2BProspect: func.isRequired,
  canEditAccount: bool.isRequired,
  onClickEditMarketPotential: func.isRequired,
  onEditAccount: func.isRequired,
  onShowHistory: func.isRequired,
  onStartNewProspect: func.isRequired,
  onMergeAccount: func.isRequired,
  onDeleteAccount: func.isRequired,
  onUnDeleteAccount: func.isRequired,
  lastProcessMode: bool,
  hasAccountDataPermission: bool
}
SalesProgressWidget.defaultProps = {
  onShowLostProspectModal: () => {},
  opportunities: undefined,
  processType: undefined,
  lastProcessMode: false,
  hasAccountDataPermission: false
}

export default processWidgetHoc(injectIntl(SalesProgressWidget))
