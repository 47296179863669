import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

const QUERY_FETCH_EMAIL_RECIPIENT = gql`
  query email_recipient_page($filter: JSON, $limit: Int, $offset: Int, $sort: [JSON]) {
    email_recipient_page(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      data {
        crm_system_recipient_uid
        email
        email_type
        created_by_user {
          name
        }
        updated_by_user {
          name
        }
        created_time
        updated_time
      }
      count
    }
  }
`

const QUERY_FETCH_ONE_EMAIL = gql`
  query email_recipient($crm_system_recipient_uid: String) {
    email_recipient(crm_system_recipient_uid: $crm_system_recipient_uid) {
      crm_system_recipient_uid
      email
      email_type
      created_by_user {
        name
      }
      updated_by_user {
        name
      }
      created_time
      updated_time
    }
  }
`

const MUTATION_CREATE = gql`
  mutation email_recipient_create($payload: JSON) {
    email_recipient_create(payload: $payload)
  }
`
const MUTATION_UPDATE = gql`
  mutation email_recipient_create($crm_system_recipient_uid: String, $payload: JSON) {
    email_recipient_update(crm_system_recipient_uid: $crm_system_recipient_uid, payload: $payload)
  }
`
const MUTATION_DELETE = gql`
  mutation email_recipient_delete($crm_system_recipient_uid: String) {
    email_recipient_delete(crm_system_recipient_uid: $crm_system_recipient_uid)
  }
`
export const useFetchOneEmailRecipient = (crm_system_recipient_uid = null) => {
  return useQuery(QUERY_FETCH_ONE_EMAIL, {
    variables: {
      crm_system_recipient_uid
    },
    skip: !crm_system_recipient_uid,
    fetchPolicy: 'no-cache'
  })
}

export const useFetchEmailRecipient = (filter = {}) => {
  return useQuery(QUERY_FETCH_EMAIL_RECIPIENT, {
    variables: {
      filter
    },
    fetchPolicy: 'no-cache'
  })
}

export const useInsertEmailRecipient = () => {
  return useMutation(MUTATION_CREATE)
}

export const useUpdateEmailRecipient = () => {
  return useMutation(MUTATION_UPDATE)
}

export const useDeleteEmailRecipient = () => {
  return useMutation(MUTATION_DELETE)
}
