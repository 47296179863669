import { useState } from 'react'
import uuidv4 from 'uuid'
import { useApolloClient } from '@apollo/react-hooks'
import ApolloClient from 'apollo-client'
import { qlmExecuteCommand } from '../../data/commands'

export const useGenericCommandQL = props => {
  const [isSaving, setIsSaving] = useState(false)
  const [commandUid, setCommandUid] = useState(uuidv4())
  const [hasErrors, setHasError] = useState(false)
  const client: ApolloClient<any> = useApolloClient()

  const _afterCommandSuccess = async () => {
    setCommandUid(uuidv4())
    setHasError(false)
    setIsSaving(false)
  }

  const _afterCommandFail = async () => {
    setCommandUid(uuidv4())
    setIsSaving(false)
    setHasError(true)
  }

  const onRunCommand = async (command, props, mutation = null) => {
    setIsSaving(true)
    const hasForceNewUid = props && props.forceNewCommandUid
    if (hasForceNewUid) {
      setCommandUid(uuidv4())
    }
    try {
      const variables = {
        command: {
          command_uid: hasForceNewUid ? uuidv4() : commandUid,
          ...command
        }
      }
      const result = await client.mutate({
        ...props,
        mutation: mutation || qlmExecuteCommand,
        variables
      })

      if (
        result &&
        result.data &&
        result.data.cqCommand &&
        result.data.cqCommand.errors &&
        result.data.cqCommand.errors.length > 0
      ) {
        setIsSaving(false)
        setHasError(true)
        throw result.data.cqCommand.errors[0].message
      }
      await _afterCommandSuccess()
      return result
    } catch (err) {
      await _afterCommandFail()
      throw err
    }
  }

  return { isSaving, hasErrors, onRunCommand }
}
