export const DIALER_CALL_STATUS_CHANGED = 'DIALER_CALL_STATUS_CHANGED'
export const DIALER_NEXT_TASK_RECEIVED = 'DIALER_NEXT_TASK_RECEIVED'
export const DIALER_TASK_STATUS_CHANGED = 'DIALER_TASK_STATUS_CHANGED'
export const SET_NEXT_TASK_COUNTDOWN = 'SET_NEXT_TASK_COUNTDOWN'

/*
Current Task status - represents
 pre.call
 call -
 post.call - time for reporting
*/

export const taskStatuses = {
  precall: 'Before Call',
  call: 'Calling',
  win: 'Closing',
  'post.call': 'After Call'
}
const initialState = {
  currentTask: undefined
}

function dialerReducer(state = initialState, action) {
  switch (action.type) {
    case DIALER_NEXT_TASK_RECEIVED:
      return {
        ...state,
        currentTask: action.payload.task,
        currenTaskStatus: action.payload.taskStatus,
        nextTaskCountdown: undefined
      }
    case DIALER_TASK_STATUS_CHANGED:
      return { ...state, currentTaskStatus: action.payload.taskStatus }
    case SET_NEXT_TASK_COUNTDOWN:
      return { ...state, nextTaskCountdown: action.payload.nextTaskCountdown }
    default:
      return state
  }
}

// Actions
export const dialerCallStatusChanged = () => ({
  type: DIALER_CALL_STATUS_CHANGED
})
// Selectors
export const getCurrentTask = state => state.crm.dialer.currentTask
export const getCurrentTaskStatus = state => state.crm.dialer.currentTaskStatus
export const getNextTaskcountdown = state => state.crm.dialer.nextTaskCountdown
export default dialerReducer
