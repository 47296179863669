import React from 'react'
import LeadsDetailsPopup from './leads-details-popup.jsx'
import DispositionDetailsPopup from './dispositions-details-popup.jsx'
import TasksDetailsPopup from './tasks-details-popup.jsx'
import FitmentsPopup from './fitments-popup'
import AccountsPopup from './accounts-popup.jsx'
import ActivitiesPopup from './activities-popup.jsx'
import OpportunitiesPopup from './opportunities-popup'
import FirstFitmentsPopup from './first-fitments-details-popup.jsx'
import OpportunitiesMeetingsStatsPopup from './opportunities-meetings-stats-popup.jsx'
import AccountsCurrentStatusPopup from './accounts-current-status-popup.jsx'
import AccountsResultsPopup from './accounts-results-popup.jsx'
import AccountLastProcessPopup from './account-last-process-popup.jsx'
import UserDashboardPopup from './user-dashboard-popup.jsx'
import SalesProcessPopup from './sales-process-popup.jsx'
import { AnalyticsContextProvider } from 'crm-core/analytics/context'
import { convertUnderlineFilterToObject } from '../dashboard-utils'

export const getDetailsComponent = type => {
  switch (type) {
    case 'lead':
      return LeadsDetailsPopup
    case 'disposition':
      return ActivitiesPopup
    case 'task':
      return TasksDetailsPopup
    case 'fitment':
      return FitmentsPopup
    case 'order':
      return FitmentsPopup
    case 'account':
      return AccountsPopup
    case 'activities':
      return ActivitiesPopup
    case 'opportunity':
      return OpportunitiesPopup
    case 'first_fitments':
      return FirstFitmentsPopup
    case 'pending_orders':
      return FitmentsPopup
    case 'opportunities_meetings_stats':
      return OpportunitiesMeetingsStatsPopup
    case 'account_current_status':
      return AccountsCurrentStatusPopup
    case 'accounts_results':
      return AccountsResultsPopup
    case 'account_last_process':
      return AccountLastProcessPopup
    case 'user_dashboard':
      return UserDashboardPopup
    case 'sales_process':
      return SalesProcessPopup
    default:
      return null
  }
}
function WithLeadsPopupHoc(WrappedComponent) {
  class InternalComponent extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {}
    }

    handleShowLeadsPopup = params => {
      console.log('handleShowLeadsPopUp', params)
      this.setState({
        isOpen: true,
        filters: params.filters,
        type: params.type,
        params
      })
    }

    handleClose = () => {
      this.setState({ isOpen: false })
    }

    getInitialColumnsForDispositions = () => {
      return [
        'account.name',
        'account.lead_in_time',
        'account.lead_campaign.name',
        'start_time',
        'activity_type.name',
        'disposition_type.name',
        'primary_performer.user.full_name',
        'call_time',
        'note_text'
      ]
    }

    getInitialColumnsForOrders = () => {
      return [
        'account.name',
        'vehicle_registration',
        'completed_date',
        'subscription',
        'hw_amount',
        'fitter_name',
        'product_package',
        'sale_date',
        'account.lead_campaign.name',
        'owner.full_name',
        'owner.name',
        'account.latest_owner.name'
      ]
    }
    getInitialColumnsForPendingOrders = () => {
      return [
        'account.name',
        'vehicle_registration',
        'completed_date',
        'subscription',
        'hw_amount',
        'fitter_name',
        'product_package',
        'sale_date',
        'account.lead_campaign.name',
        'owner.full_name',
        'owner.name',
        'account.latest_owner.name'
      ]
    }

    getInitialColumns = () => {
      if (this.state.params && this.state.params.initialColumns) {
        return this.state.params.initialColumns
      }

      let initialColumns =
        this.state.params && this.state.params.initialColumns
          ? this.state.params.initialColumns
          : undefined

      if (!initialColumns && this.state.type === 'disposition') {
        initialColumns = this.getInitialColumnsForDispositions()
      }

      if (
        !initialColumns &&
        (this.state.type === 'fitment' || this.state.type === 'order')
      ) {
        initialColumns = this.getInitialColumnsForOrders()
      }
      if (!initialColumns && this.state.type === 'pending_orders') {
        initialColumns = this.getInitialColumnsForPendingOrders()
      }

      return initialColumns
    }

    getMergedContextFilters = () => {
      let filters = this.state.filters ? { ...this.state.filters } : {}

      if (this.state.type === 'pending_orders') {
        filters.status = 'pending'
      }

      if (this.state.type !== 'opportunity') {
        filters = convertUnderlineFilterToObject(filters)
      }

      return filters
    }

    getTitle = () => {
      let title = this.props.title

      if (this.state.type === 'pending_orders') {
        title = 'Pending orders'
      }

      return title
    }

    render() {
      const Component = getDetailsComponent(this.state.type)
      let initialColumns = this.getInitialColumns()
      let filters = this.getMergedContextFilters()

      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            onShowLeadsPopup={this.handleShowLeadsPopup}
          />
          {this.state.type && Component && (
            <AnalyticsContextProvider initialFilters={filters}>
              <Component
                isOpen={this.state.isOpen}
                onClose={this.handleClose}
                filters={filters}
                analyticsContext={this.props.analyticsContext}
                initialColumns={initialColumns}
                title={this.getTitle()}
              />
            </AnalyticsContextProvider>
          )}
        </React.Fragment>
      )
    }
  }

  return InternalComponent
}

export default WithLeadsPopupHoc
