import React from 'react'
import widgetFirstMeetingStatusHoc, { WidgetFirstMeetingStatusHocProps } from './my-first-meeting-status-hoc'
import { DashBoardWidget } from '../dashboard-widget'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetFirstMeetingSummary: React.ComponentType<AnalyticDashboardWidget> = widgetFirstMeetingStatusHoc(
  (props: WidgetFirstMeetingStatusHocProps) => (
    <DashBoardWidget
      avgDataPassedMonth={props.avgFirstMeetingThreeMonth}
      avgDataPassedMonthPerPerson={props.avgFirstMeetingPersonPassedMonth}
      avgPerPersonNumber={props.avgFirstMeetingCurrentMonthPerPerson}
      footerName={props.footerName}
      footerRender={props.threeMonthAgoFirstMeeting}
      isLoading={props.isLoading}
      refetch={props.refetchData}
      secondFooterRender={props.avgFirstMeetingPassedMonthData}
      secondFooterName={props.footerNamePerPerson}
      subtitleSecondBlock={'Month date average'}
      title='First Meetings - Prospects'
      totalNumber={props.totalFirstMeeting}
      onClickDetail={props.onClickDetail}
      subtitle={props.mainCaption}
    />
  )
)
