import React, { Component } from 'react'
import { string, shape, func, oneOf, oneOfType, bool } from 'prop-types'
import { injectIntl } from 'react-intl'

class Checkbox extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleLabelClick = this.handleLabelClick.bind(this)
  }

  handleChange(e) {
    const onChange = this.props.input.onChange || this.props.onChange
    onChange(e.currentTarget.checked, this.props.name)
  }

  handleLabelClick(e) {
    e.preventDefault()
    const onChange = this.props.input.onChange || this.props.onChange
    const value = this.props.input.name // Provided by redux-form
      ? this.props.input.value
      : this.props.value
    onChange(!value, this.props.name)
  }

  render() {
    const {
      value,
      input,
      label,
      labelMessageId,
      extraClassNames,
      disabled,
      name,
      intl,
      small,
      indeterminate,
      style
    } = this.props

    const checked = input.value === undefined ? value : input.value

    return (
      <div
        style={style}
        className={`Checkbox 
          ${extraClassNames.containerClassNames} 
          ${small ? 'Checkbox--small' : ''}
          ${indeterminate ? 'is-indeterminate' : ''}`}
      >
        <input
          name={name}
          type="checkbox"
          value={value}
          id={name}
          checked={indeterminate || checked}
          className={`Checkbox-input ${extraClassNames.inputClassNames || ''}`}
          onChange={this.handleChange}
          disabled={disabled}
        />
        <label htmlFor={name} className={`Checkbox-box`} />
        {label ? (
          <span className="Checkbox-label" onClick={this.handleLabelClick}>
            {intl.formatMessage({
              id: labelMessageId || `checkbox.${label}`,
              defaultMessage: label
            })}
          </span>
        ) : null}
      </div>
    )
  }
}

Checkbox.propTypes = {
  value: oneOfType([string, bool]),
  name: string.isRequired,
  label: string,
  extraClassNames: shape({
    labelClassNames: string,
    inputClassNames: string,
    containerClassNames: string
  }),
  input: shape({
    value: oneOfType([string, bool]),
    onChange: func
  }),
  onChange: func,
  disabled: oneOf([true, undefined]),
  small: bool,
  indeterminate: bool,
  style: shape(),
  labelMessageId: string
}

Checkbox.defaultProps = {
  extraClassNames: {
    labelClassNames: '',
    inputClassNames: '',
    containerClassNames: ''
  },
  label: '',
  value: '',
  input: {},
  onChange: null,
  disabled: undefined,
  small: false,
  indeterminate: false,
  style: undefined,
  labelMessageId: undefined
}

export default injectIntl(Checkbox)
