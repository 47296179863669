/* eslint-disable camelcase */
import { callQLApi } from '../api-utils'

export const instanceAllFields = `instance_uid parent_instance_uid master_uid name country_code owner_account_uid description 
  parameters
  details
  timezone 
  is_active 
  parent { name instance_uid country_code } `

export default {
  fetchInstancesApi: params => {
    const query = `query master { 
      master (master_uid: "${params.payload.master_uid}") { 
        instances (master_uid: "${params.payload.master_uid}") { ${instanceAllFields} } 
      } 
    }`
    return callQLApi(query).then(res => res.master.instances)
  },
  createInstanceApi: instance_in => {
    const qlParams = {
      query: ` mutation  instance_create($instance_in:instance_in) { instance_create(instance_in: $instance_in) { ${instanceAllFields} }  } `,
      variables: {
        instance_in
      }
    }
    return callQLApi(qlParams).then(res => res.instance_create)
  },
  updateInstanceApi: instance_in => {
    const qlParams = {
      query: ` mutation  instance_update($instance_in:instance_in) { instance_update(instance_in: $instance_in) { ${instanceAllFields} }  } `,
      variables: {
        instance_in
      }
    }
    return callQLApi(qlParams).then(res => res.instance_update)
  }
}
