import React, { Component } from 'react'
import { func, shape, arrayOf } from 'prop-types'
import { CrmButton, CrmDropdown, CrmTextInput } from 'crm-components'

class ImportDefaultValueForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentFieldValue: undefined,
      defaultFields: props.defaultValues,
      fieldsName: this.fieldWithName(props)
    }
  }

  fieldWithName(props) {
    const fieldsName = {}
    props.fields.forEach(element => {
      fieldsName[element.key] = element.name
    })

    return fieldsName
  }

  getDefaultFields = () =>
    this.props.fields.filter(field => field.canSetDefault)

  handleFieldChange = value => {
    this.setState({ currentFieldValue: value })
  }

  handleAddField = () => {
    if (!this.state.currentFieldValue) return
    const defaultFields = this.state.defaultFields
    if (defaultFields[this.state.currentFieldValue]) return
    defaultFields[this.state.currentFieldValue] = ''
    this.setState({ defaultFields })
  }

  handleDeleteField = event => {
    const targetId = event.target.id
    const defaultFields = this.state.defaultFields
    // eslint-disable-next-line
    if (!defaultFields.hasOwnProperty(targetId)) return
    delete defaultFields[targetId]
    this.setState({ defaultFields })
  }

  handleDefaultValueChange = event => {
    const defaultFields = this.state.defaultFields
    const targetId = event.target.id
    // eslint-disable-next-line
    if (!defaultFields.hasOwnProperty(targetId)) return
    defaultFields[event.target.id] = event.target.value
    this.setState({ defaultFields })
  }

  handleSave = () => {
    this.props.onSave(this.state.defaultFields)
  }

  renderFieldForm = () => {
    const fields = Object.keys(this.state.defaultFields)
    if (fields.length === 0) return
    return fields.map(key => (
      <div key={key} className="row" style={{ margin: '20px' }}>
        <div className="col-md-2">{this.state.fieldsName[key]}</div>
        <div className="col-md-2">
          <CrmTextInput
            placeholder="Default value"
            id={key}
            input={{
              onChange: this.handleDefaultValueChange,
              value: this.state.defaultFields[key]
            }}
          />
        </div>
        <div className="col-md-1">
          <CrmButton
            className="remove-button"
            xsmall
            icon="trash"
            id={key}
            onClick={this.handleDeleteField}
          />
        </div>
      </div>
    ))
  }

  render() {
    console.log('this.props.fields', this.props.fields)
    const fields = this.getDefaultFields()

    return (
      <div className="importTool-DefaultFieldContainer">
        <div className="row" style={{ margin: '20px' }}>
          <div className="col-md-2">
            <CrmDropdown
              title="Select Filed"
              options={fields.map(field => ({
                name: field.name,
                value: field.key
              }))}
              input={{
                onChange: this.handleFieldChange,
                value: this.state.currentFieldValue
              }}
              style={{ width: 242 }}
            />
          </div>
          <div className="col-md-2">
            <CrmButton
              label="Add"
              type="primary"
              onClick={this.handleAddField}
              style={{ width: 100, marginLeft: '15px' }}
            />
          </div>
          <div className="col-md-6">
            <CrmButton
              label="Save"
              type="primary"
              onClick={this.handleSave}
              style={{ width: 150 }}
            />
            <CrmButton
              label="Cancel"
              onClick={this.props.onBack}
              style={{ width: 150, marginLeft: '15px' }}
            />
          </div>
        </div>
        <div className="importTool-DefaultFieldForm">
          {this.renderFieldForm()}
        </div>
      </div>
    )
  }
}

ImportDefaultValueForm.propTypes = {
  onBack: func.isRequired,
  onSave: func.isRequired,
  defaultValues: shape({}).isRequired,
  fields: arrayOf(shape({})).isRequired
}

export default ImportDefaultValueForm
