import React from 'react'
import ReactModal from 'react-modal'
import { func, shape, bool } from 'prop-types'
import { injectIntl } from 'react-intl'
import { Icon } from 'util-components'

const ModalStyle = {
  overlay: {
    zIndex: 1000
  },
  content: {
    padding: 0,
    borderRadius: 0,
    boxShadow: '0 5px 15px 0'
  }
}

const Modal = props => (
  <ReactModal
    {...props}
    style={{ ...ModalStyle, ...props.style }}
    onRequestClose={props.onClose}
  >
    <div className={`Modal ${props.extraClassNames.modalClassName || ''}`}>
      {props.hideHeader ? null : (
        <div className="Modal-header">
          <div className="Modal-title">
            {props.title &&
              props.intl.formatMessage({
                id: `modal.title.${props.title}`,
                defaultMessage: props.title
              })}
          </div>
          <div className="Modal-closeWrapper" onClick={props.onClose}>
            <Icon name="times" />
          </div>
        </div>
      )}
      <div
        className={`Modal-body ${props.extraClassNames.bodyClassName || ''} `}
      >
        {props.children}
      </div>
    </div>
  </ReactModal>
)

Modal.propTypes = {
  ...ReactModal.propTypes,
  hideHeader: bool,
  onClose: func.isRequired,
  extraClassNames: shape({})
}

Modal.defaultProps = {
  hideHeader: false,
  onClose: () => {},
  extraClassNames: {}
}

export default injectIntl(Modal)
