import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape, func } from 'prop-types'
import { injectIntl, FormattedDate, FormattedMessage } from 'react-intl'
import { CrmButton } from 'crm-components'
import { actions } from 'crm-duxs/document-reducer'

class DocumentListItem extends PureComponent {
  handleEditAction = () => {
    console.log('Document handleEditAction :', this.props.document)
    this.props.doStartDocumentEdit(this.props.document)
  }

  render() {
    const { document } = this.props
    const hasActions = true
    return (
      <div
        className={`OpportunityListItem  ${
          hasActions ? 'DocumentListItem--hasActions' : ''
        }`}
        style={this.props.style}
      >
        <div className="row util-util-paddingMd">
          <div className="ActivityListItem-headerItem col-md-3">
            <div>
              <FormattedMessage
                id="crm.ui.account.document.type"
                defaultMessage="TYPE"
              />
            </div>
            <div className="ActivityListItem-headerItem-value">
              {document.document_type ? document.document_type.name : ''}
            </div>
          </div>
          <div className="ActivityListItem-headerItem col-md-3">
            <div>
              <FormattedMessage
                id="crm.ui.account.document.name"
                defaultMessage="NAME"
              />
            </div>
            <div className="ActivityListItem-headerItem-value">
              {document.name}
            </div>
          </div>
          <div className="ActivityListItem-headerItem col-md-3">
            <div>
              <FormattedMessage
                id="crm.ui.account.document.created"
                defaultMessage="CREATED"
              />
            </div>
            <div className="ActivityListItem-headerItem-value">
              <FormattedDate value={document.created_time} />
            </div>
          </div>

          {hasActions && (
            <div className="ActivityListItem-actionsContainer">
              <CrmButton
                icon="pencil"
                iconButton
                onClick={this.handleEditAction}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

DocumentListItem.defaultProps = {
  style: {}
}

DocumentListItem.propTypes = {
  document: shape({}).isRequired,
  style: shape({}),
  doStartDocumentEdit: func.isRequired
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    doStartDocumentEdit: document =>
      dispatch(actions.startDocumentEdit.create({ document }))
  }
}

const DocumentListItemIntl = injectIntl(DocumentListItem)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentListItemIntl)
