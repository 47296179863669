import React from 'react'
import cloneDeep from 'clone-deep'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CrmLabeledField, CrmButton } from 'crm-components'
import styles from './section-address.scss'
import sectionAddressesHoc, {
  getIsLocationOnSite,
  locationTypeOptions,
  getLocationDisplayValue
} from 'crm-modules/accounts/hoc/section-addresses-hoc.js'
import AddressItem from 'crm-modules/accounts/components/layout19/address-item.jsx'
import MeetingAddressItem from './meeting-address-item.jsx'
import { func, shape, bool, arrayOf, string } from 'prop-types'
import { RadioInput } from 'util-components'

class SectionAddress extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      addresses: [...props.addresses],
      meetingAddress: props.meetingAddress
        ? cloneDeep(props.meetingAddress)
        : null,
      location_type: props.location_type
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.addresses !== this.props.addresses ||
      nextProps.meetingAddress !== this.props.meetingAddress ||
      nextProps.location_type !== this.props.location_type
    ) {
      const meetingAddress = nextProps.meetingAddress
        ? cloneDeep(nextProps.meetingAddress)
        : null
      const addresses = this.mapMeetingAddress(
        nextProps.addresses,
        meetingAddress
      )
      const location_type = nextProps.location_type
      this.setState({ addresses, meetingAddress, location_type })
    }
  }

  componentDidMount() {
    const addresses = this.mapMeetingAddress(
      this.state.addresses,
      this.state.meetingAddress
    )
    this.setState({ addresses })
  }

  mapMeetingAddress = (pAddresses, pMeetingAddress) => {
    const addresses = [...pAddresses].map(v => {
      const findAddress =
        pMeetingAddress && pMeetingAddress.address_uid === v.address_uid
      if (findAddress) {
        return { ...v, isMeetingAddress: true }
      } else {
        return v
      }
    })
    return addresses
  }

  handleClose = e => {
    const newItemIndex = this.props.addresses.findIndex(v => v.isNew === true)
    if (newItemIndex > -1) {
      this.props.removeAddressUI(newItemIndex)
    }
    e.stopPropagation()
    this.props.handleEditEnd()
  }

  getIsValidLocation = address => {
    let isValid = false
    if (
      address &&
      address.location &&
      address.location.lat &&
      address.location.lng
    ) {
      isValid = true
    } else {
      isValid = false
    }
    return isValid
  }

  handleMeetingAddressChange = (address_uid, isAdd) => {
    const addresses = this.state.addresses.map(v => {
      if (v.address_uid === address_uid) {
        return { ...v, isMeetingAddress: isAdd }
      } else {
        return { ...v, isMeetingAddress: false }
      }
    })

    const selectedAddress = addresses.filter(v => v.isMeetingAddress)[0] || null
    if (isAdd && !this.getIsValidLocation(selectedAddress)) {
      this.handleEditAddress(selectedAddress)
      return
    }

    this.setState(
      {
        addresses,
        meetingAddress: selectedAddress
      },
      () => {
        if (!this.props.activity.activity_uid) {
          this.props.handleEditEnd()
          this.props.handleMeetingAddressChange(this.state.meetingAddress)
        }
        this.handleInlineEditableValuesChange()
      }
    )
  }

  handleAddMeetingAddress = (e, address_uid) => {
    this.handleMeetingAddressChange(address_uid, true)
  }

  handleRemoveMeetingAddress = (e, address_uid) => {
    e.stopPropagation()
    this.handleMeetingAddressChange(address_uid, false)
  }

  handleStartAddAddress = () => {
    if (this.props.isAddingNewAddress()) {
      return
    }
    this.props.onStartAddAddress()
  }

  handleEditAddress = selectedAddress => {
    const addresses = [...this.state.addresses].map(v => {
      const findAddress =
        selectedAddress && selectedAddress.address_uid === v.address_uid
      if (findAddress) {
        return { ...v, isEditing: true }
      } else {
        return v
      }
    })
    this.setState({ addresses })
  }

  handleCancelEditAddressOnMeetingForm = () => {
    const addresses = [...this.state.addresses].map(v => {
      delete v.isEditing
      return { ...v }
    })
    this.setState({ addresses })
  }

  handleInlineEditableValuesChange = () => {
    if (this.props.input && this.props.input.onChange) {
      const new_values = {
        location_type: this.state.location_type,
        address_uid: this.state.meetingAddress
          ? this.state.meetingAddress.address_uid
          : ''
      }
      this.props.input.onChange(new_values)
    }
  }

  handleLocationTypeChange = location_type => {
    this.setState({ location_type }, () => {
      if (!this.props.activity.activity_uid) {
        if (location_type !== 'ON_SITE') {
          this.props.handleEditEnd()
        }
        this.props.handleLocationTypeChange(this.state.location_type)
      }
      this.handleInlineEditableValuesChange()
    })
  }

  render() {
    console.log('render section-address', this.state, this.props)
    const { addresses, meetingAddress, location_type } = this.state
    const { account_uid } = this.props
    const findNewAddress = addresses.find(v => v.isNew)
    const isLocationOnSite = getIsLocationOnSite(location_type)

    return (
      <div className={styles.SectionAddress}>
        <div>
          <CrmLabeledField lg noMargin>
            <div
              onClick={
                !this.props.activity.activity_uid
                  ? this.props.handleEditStart
                  : () => {}
              }
              className={
                !this.props.activity.activity_uid
                  ? 'util-hooverableContent util-hooverOp util-flexRow'
                  : ''
              }
            >
              {location_type ? (
                <div className="util-textUpdated">
                  {getLocationDisplayValue(location_type, meetingAddress)}
                </div>
              ) : (
                <div style={{ padding: 3 }}>
                  {this.props.intl.formatMessage({
                    id: `crm.activity.no_location_type_click_here_to_select`,
                    defaultMessage: 'No location type - Click here to select'
                  })}
                </div>
              )}
            </div>
          </CrmLabeledField>
        </div>

        {this.props.isExpanded && (
          <React.Fragment>
            <div className="LabeledField--lg">
              <div className="LabeledField-value">
                <RadioInput
                  options={locationTypeOptions}
                  input={{
                    value: location_type,
                    onChange: this.handleLocationTypeChange
                  }}
                  name={`location_type`}
                  extraClassNames={{
                    labelClassNames: 'col-md-4'
                  }}
                />
              </div>
            </div>

            {isLocationOnSite && (
              <div className="row">
                {addresses.map((address, index) => {
                  if (address.isNew || address.isEditing) {
                    return (
                      <div key={index} className="col-md-12">
                        <div className={styles.AddNewItemWrapper}>
                          <div className={styles.AddNewItemHeader}>
                            <FormattedMessage
                              id="crm.activity.add_new_address"
                              defaultMessage={
                                address.isNew
                                  ? 'Add new address'
                                  : 'Edit address'
                              }
                            />
                          </div>
                          <AddressItem
                            index={index}
                            account_uid={account_uid}
                            address={address}
                            onCancelAddAddress={this.props.onCancelAddAddress}
                            handleCancelEditAddressOnMeetingForm={
                              this.handleCancelEditAddressOnMeetingForm
                            }
                            removeAddressUI={this.props.removeAddressUI}
                            updateAddressesUI={this.props.updateAddressesUI}
                            mapAlign="left"
                            isRequiredValidLocation={true}
                          />
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div key={index} className="col-md-6 util-marginBottom">
                        <MeetingAddressItem
                          address={address}
                          handleAddMeetingAddress={this.handleAddMeetingAddress}
                          handleRemoveMeetingAddress={
                            this.handleRemoveMeetingAddress
                          }
                          caller="meetingForm"
                        />
                      </div>
                    )
                  }
                })}
                {!findNewAddress && (
                  <div className="col-md-6 util-marginBottom">
                    <div className={`${styles.AddressItem} util-fullHeight`}>
                      <div className={`${styles.AddNewItemIcon}`}>
                        <div>
                          <FontAwesomeIcon
                            title={this.props.intl.formatMessage({
                              id: 'crm.activity.click_to_add_adress',
                              defaultMessage: 'Click to add adress'
                            })}
                            className="util-hooverable util-pointer"
                            icon="plus"
                            onClick={this.handleStartAddAddress}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!this.props.activity.activity_uid && (
              <div className="util-flexRowRight">
                <CrmButton
                  grouped
                  label={
                    <FormattedMessage
                      id="crm.ui.button.close"
                      defaultMessage="Close"
                    />
                  }
                  id="section_address_close"
                  small
                  onClick={this.handleClose}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

SectionAddress.propTypes = {
  addresses: arrayOf(shape({})).isRequired,
  meetingAddress: shape({}),
  removeAddressUI: func.isRequired,
  handleMeetingAddressChange: func.isRequired,
  isAddingNewAddress: func.isRequired,
  onStartAddAddress: func.isRequired,
  account_uid: string.isRequired,
  activity: shape({}).isRequired,
  input: shape({}),
  handleEditEnd: func.isRequired,
  onCancelAddAddress: func.isRequired,
  updateAddressesUI: func.isRequired,
  handleEditStart: func.isRequired,
  isExpanded: bool.isRequired,
  intl: shape({}).isRequired,
  location_type: string.isRequired,
  handleLocationTypeChange: func.isRequired
}

SectionAddress.defaultProps = {
  meetingAddress: undefined,
  input: undefined
}

export default sectionAddressesHoc(injectIntl(SectionAddress))
