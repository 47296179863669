/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import { buildQlQueryByFields } from 'crm-utils/data-utils'

import {
  BarChart,
  Bar,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  Label
} from 'recharts'
import { dialerCampaignQl } from './telesales-dash-utils'
import { graphql, compose } from 'react-apollo'
import { arrayOf, shape, func } from 'prop-types'
import {
  CrmFullpageContainer,
  CrmDropdown,
  CrmButton,
  CrmLabeledField
} from 'crm-components'
import CrmWidget from '../../components/crm-widget.jsx'

import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'

class DashTelesalesCampaignStats extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ isLoading: val }, handler)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== undefined &&
      nextProps.analyticsContext !== this.props.analyticsContext
    ) {
      this.setState({ ...this.state }, () => {
        this.handleRefresh()
      })
    }
  }

  parseData(data) {
    const res = {
      count: 0
    }
    data.forEach(row => {
      if (!res[row.process_step_kind]) {
        res[row.process_step_kind] = 0
      }
      res[row.process_step_kind] += row.count
      res.count += row.count
    })
    Object.keys(res).forEach(key => {
      if (key !== 'count') {
        res[key + '_ratio'] =
          res.count !== 0 ? Number((100 * res[key]) / res.count).toFixed(1) : 0
      }
    })
    return res
  }
  handleRefresh = async () => {
    console.log('dash telesales general stats . handle refresh', this.props)
    try {
      const filter = mapContextToFilter(this.props)
      if (this.props.analyticsContext) {
        if (this.props.analyticsContext.campaign_uid) {
          filter.campaign_uid = this.props.analyticsContext.campaign_uid
        }
      }
      const variables = {
        filter,
        fields: ['current_step_name', 'process_step_kind'],
        aggregate: [{ id: 'process_uid', type: 'count' }]
      }
      if (this.props.leadInDateRange) {
        variables.filter.lead_in_date = {
          $gte: this.props.leadInDateRange.startDate,
          $lte: this.props.leadInDateRange.endDate
        }
      }

      const res = await this.props.client.query({
        query: buildQlQueryByFields(dialerCampaignQl, variables),
        variables,
        fetchPolicy: 'no-cache'
      })

      console.log('Got result', res)
      // parse data
      if (res.data.dialer_campaign_accounts_list_qm_paged) {
        const parsed = this.parseData(
          res.data.dialer_campaign_accounts_list_qm_paged.data
        )
        this.setState({ stats: parsed })
      }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }

  handleCurrentStepClick = event => {
    console.log('handleCurrentStepClick', event, this.props)
    this.setState({ selectedStepTypeUid: event.currentTarget.id })
    this.props.onCurrentStepClick({
      process_step_type_uid: event.currentTarget.id
    })
    const filters = {
      ...this.props.analyticsContext.filters,
      process_step_kind: event.currentTarget.id
    }
    console.log('filters')
    this.props.onAnalyticsRangeSelected({ type: 'lead', filters })
  }

  renderBlock = (field, label, className) => {
    console.log('renderBlock', field)
    const stats = this.state.stats ? this.state.stats : {}
    console.log('stats', stats)
    return stats[field] ? (
      <CrmLabeledField
        className="util-pointer"
        id={field}
        label={label}
        onClick={this.handleCurrentStepClick}
      >
        <div style={{ fontSize: 30, margin: 10 }} className={className}>
          {stats[field]}
          <span style={{ fontSize: 10, margin: 3, opacity: 0.7 }}>
            {stats[field + '_ratio']}%
          </span>
        </div>
      </CrmLabeledField>
    ) : (
      <span />
    )
  }

  render() {
    console.log('Render general stats', this.state)
    return (
      <CrmWidget
        title={'General Campaign statistics'}
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
      >
        <div className="Widget-content CrmWidget-content util-paddingSm">
          <div className="util-flexRow">
            {this.renderBlock('new', 'New')}
            {this.renderBlock('active', 'Pending')}
            {this.renderBlock('lost', 'Lost', 'util-textRed')}
            {this.renderBlock('bogus', 'Bogus', 'util-textRed')}
            {this.renderBlock('won', 'Win', 'util-textGreen')}
          </div>
        </div>
      </CrmWidget>
    )
  }
}

DashTelesalesCampaignStats.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  onCurrentStepClick: func.isRequired
}

DashTelesalesCampaignStats.defaultProps = {
  dashboards: []
}

const DashTelesalesCampaignStatsQL = withApollo(DashTelesalesCampaignStats)

export default DashTelesalesCampaignStatsQL
