import gql from 'graphql-tag'

export const QL_GET_USER_PERMISSION = gql`
query user($user_uid: String!, $master_uid: String!, $instance_uid: String!) {
  master(master_uid: $master_uid) {
      instance(instance_uid: $instance_uid) {
        adminUser(user_uid: $user_uid) {
          listPermissions {
            user_uid
            permission_code
            filters
            access_mode
            ownership_rule
            module
            profile_uid
          }
        }
      }
  }
}
`