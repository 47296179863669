import React, { FC, useState } from 'react'
import { TEMPLATE_OPTIONS } from '../component-template-selector/template-selecor-bar/template-selector-bar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { LayoutConfigTable } from './layout-config-table'
import { LayoutConfigHorizontalTable } from './layout-config-horizontal-table'
import { LayoutConfigPivot } from './layout-config-pivot'
import { LayoutCrmCardWrapper } from './layout-panel'
import styled from 'styled-components'

export const LayoutItemSectionHeader = styled.h6`
  cursor: pointer; 
  border-bottom: 1px solid var(--crm-color-gray40);
  padding: var(--crm-padding-08) 0;
`
interface ILayoutItem {
  item: {
    id?: string
    type: string
    props?: any
    rows?: any
    def_version?: number
    name?: string
  }
  index?: number
  onSaveItem?: Function
  dataSourceOptions?: any[]
}

export const LayoutItem: FC<ILayoutItem> = (props) => {
  const [expand, setExpand] = useState(false)
  const toggleExpand = () => setExpand(!expand)
  const item = props.item
  const renderFields = () => {
    if (props.item.type === 'table') {
      return <LayoutConfigTable item={item} />
    }
    if (props.item.type === 'tableHorizontal') {
      return <LayoutConfigHorizontalTable item={item} />
    }
    if (props.item.type === 'pivot') {
      return <LayoutConfigPivot item={item} />
    }
  }
  var layoutItemName = item.name || 'Item ' + (props.index + 1)
  return (
    <LayoutCrmCardWrapper title={layoutItemName} className="util-marginSm">
      <div className="CrmCard-content">
        <div className="row">
          <div className="col-md-12">
            <LayoutItemSectionHeader onClick={toggleExpand}>
              {TEMPLATE_OPTIONS.find((i) => i.value === props.item.type)?.name}
              <FontAwesomeIcon
                icon={expand ? faCaretUp : faCaretDown}
                style={{ float: 'right' }}
              />
            </LayoutItemSectionHeader>
          </div>
          {expand && <div className="col-md-12">{renderFields()}</div>}
        </div>
      </div>
    </LayoutCrmCardWrapper>
  )
}
