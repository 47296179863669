/* eslint-disable camelcase */
export function findPerformerByCode(activity, roleCode) {
  return activity && activity.participants
    ? activity.participants.find(p => p.role_code === roleCode)
    : undefined
}
export const mock = 1

export const isActivityPending = activity =>
  (activity &&
    activity.activity_status &&
    activity.activity_status.kind === 'initial') ||
  activity.activity_status.kind === 'pending'

export const buildNewActivity = (accountUid, activityType, currentUser) => {
  if (!activityType) {
    return
  }
  const activity = {
    activity_type_uid: activityType.activity_type_uid,
    activity_type: activityType,
    account_uid: accountUid,
    participants: []
  }

  if (
    activityType.medium_code === 'phone_call' &&
    activityType.form_code !== 'live_call'
  ) {
    activity.start_time = new Date().getTime()
  }
  if (
    activityType.medium_code === 'note' ||
    activityType.medium_code === 'email'
  ) {
    activity.start_time = new Date().getTime()
  }

  if (
    activityType.medium_code === 'meeting' ||
    !activity.participants ||
    activity.participants.length === 0
  ) {
    if (!activity.participants) {
      activity.participants = []
    }
    activity.participants.push({
      role_code: 'performer',
      user_uid: currentUser.user_uid,
      user: currentUser,
      is_primary: true
    })
  }

  activity.primary_performer_user_uid = currentUser.user_uid
  activity.arranged_by_user_uid = currentUser.user_uid
  return activity
}
