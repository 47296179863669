/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'

import UserStatusWidget from '../../common/userStatus/user-status-widget.jsx'
import QuicksearchWidget from '../../utils/navbar/quicksearch-widget.jsx'
import PhoneWidget from '../../phone/widget/phone-widget.jsx'
import DialerCurrentTaskStatus from './dialer-current-task-status.jsx'

class DialerViewHeaderMenu extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className=" CrmNavbar" style={{ background: '#5099ce', height: 60 }}>
        <div className="util-flexRow ">
          <div className="col-md-2 util-flexCenterContent util-textWhite">
            <div style={{ fontSize: 24 }}>CRM Dialer</div>
          </div>
          <div className="col-md-2 util-flexRow">
            Hide during task
            <QuicksearchWidget />
          </div>
          <div className="col-md-2 util-flexRow">
            <PhoneWidget />
          </div>
          <div>
            <DialerCurrentTaskStatus />
          </div>

          <div className="col-md-3">
            <UserStatusWidget />
          </div>
        </div>
      </div>
    )
  }
}

DialerViewHeaderMenu.propTypes = {}

DialerViewHeaderMenu.defaultProps = {}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps() {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialerViewHeaderMenu)
