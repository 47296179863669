import React from 'react'
import { string, bool } from 'prop-types'

const helpLink = 'https://cartrack.gitbooks.io/cartrack-crm-manual/content'

const handleLinkClicked = event => {
  console.log('handleLinkClicked')
  const helpPage = event.currentTarget.dataset.helpPage
  console.dir(helpPage)
  window.open(helpLink + helpPage, '_blank')
}

const CrmHelpButton = props => (
  <i
    data-help-page={props.helpPage}
    onClick={handleLinkClicked}
    className={`CrmHelpButton fa fa-question-circle-o ${
      props.lg ? 'CrmHelpButton--lg' : ''
    }`}
  />
)

CrmHelpButton.propTypes = {
  helpPage: string.isRequired,
  lg: bool
}
CrmHelpButton.defaultProps = {
  lg: false
}
export default CrmHelpButton
