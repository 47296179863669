/* eslint-disable camelcase, no-unused-vars */
import { callQLApi } from './api-utils'
import { qlDictionary } from './settings/dictionaries-api'
import { msToUTCTimestamp } from 'util-functions/time-utils'

export const qlMinutes =
  '  activity_uid meeting_minutes_uid details created_time updated_time completed_at'
export default {
  fetch(activity_uid, account_uid) {
    const dictsKey = [
      { alias: 'fleet_size_value', key: 'crm.minutes.fleet_size.value' },
      { alias: 'fleet_size_key', key: 'crm.minutes.fleet_size.key' },
      { alias: 'customer_priorities', key: 'crm.minutes.customer_priorities' },
      { alias: 'meeting_source', key: 'crm.minutes.meeting_source' },
      { alias: 'meeting_product', key: 'crm.minutes.products' }
    ]

    let dictQuery = dictsKey.map(
      obj =>
        ` ${obj.alias} : dictionary(code:"${obj.key}") {  ${qlDictionary} } `
    )

    dictQuery = dictQuery.join(' ')
    // OLD const query = ` query getMinutesAndConfigs {  meeting_minutes: meeting_minutes(activity_uid:"${activity_uid}") { ${qlMinutes}}  ${dictQuery} products : products { name, product_uid }  account : accounts(account_uid:"${account_uid}") { account_uid , name , kind  }  } `
    const query = ` query { meeting_minutes: meeting_minutes(activity_uid:"${activity_uid}") { ${qlMinutes}}  ${dictQuery} products : products { name, product_uid }  account : accounts(account_uid:"${account_uid}") { account_uid , name , kind  }  } `
    return callQLApi(query).then(res => res.meeting_minutes)
  },

  save(minutes) {
    const activity_uid = minutes.activity_uid
    console.log('Meeting minutes save')
    const details = { ...minutes.details }
    console.dir(details)
    // I if (
    //   details &&
    //   details.qualification &&
    //   details.qualification.value &&
    //   details.qualification.value.opportunity_time_to_close
    // ) {
    //   details.qualification.value.opportunity_time_to_close = msToUTCTimestamp(
    //     details.qualification.value.opportunity_time_to_close
    //   )
    // }
    const qlParams = {
      query: ` mutation  meeting_minutes_save($meeting_minutes:meeting_minutes_in) { meeting_minutes_save(meeting_minutes: $meeting_minutes)  { ${qlMinutes} }  } `,
      variables: {
        meeting_minutes: {
          activity_uid,
          details
        }
      }
    }
    return callQLApi(qlParams).then(res => res.meeting_minutes_save)
  },
  setAsComplete(activity_uid, value) {
    console.log('setAsComplete aPI ')
    const qlParams = {
      query: ` mutation meeting_minutes_complete($meeting_minutes:meeting_minutes_in, $completed: Boolean) { meeting_minutes_complete(meeting_minutes: $meeting_minutes, completed: $completed)  { ${qlMinutes} }  } `,
      variables: {
        meeting_minutes: {
          activity_uid
        },
        completed: value
      }
    }
    return callQLApi(qlParams).then(res => res.meeting_minutes_complete)
  },
  genReport(activity_uid, template_code, lang) {
    const qlParams = {
      query: ``,
      variables: {
        meeting_minutes: {
          activity_uid,
          template_code,
          lang
        }
      }
    }
    return callQLApi(qlParams).then(res => res.meeting_minutes_report)
  }
}
