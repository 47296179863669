import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { shape } from 'prop-types'
import {
  CrmTextInput,
  CrmFormSection,
  CrmButton,
  CrmValidatedField
} from 'crm-components'

class MeSipForm extends Component {
  render() {
    const { handleSubmit } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <CrmFormSection sectionName="Change sip user">
          <fieldset className="Form-fieldset CrmForm-form-fieldset">
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <CrmValidatedField
                  component={CrmTextInput}
                  componentProps={{
                    placeholder: 'Sip Server'
                  }}
                  name="sip_server"
                />

                <CrmValidatedField
                  component={CrmTextInput}
                  componentProps={{
                    placeholder: 'Sip Username'
                  }}
                  name="sip_username"
                />

                <CrmValidatedField
                  component={CrmTextInput}
                  componentProps={{
                    placeholder: 'Sip Password'
                  }}
                  name="sip_password"
                />

                <CrmButton
                  label="Change sip user"
                  onClick={this.handleChangePassword}
                  type="submit"
                />
              </div>
            </div>
          </fieldset>
        </CrmFormSection>
      </form>
    )
  }
}

MeSipForm.propTypes = {
  handleSubmit: shape({}).isRequired
}

const MeSipFormRedux = reduxForm({
  form: 'crmMeSettingForm',
  enableReinitialize: false
})(MeSipForm)

export default MeSipFormRedux
