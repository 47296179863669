import React, { useState } from 'react'
import { ForecastListInner } from './forecast-list-inner'
import { ForecastManagerModal } from './forecast-manager-modal'
import { ForecastManagerModalGraph } from './forecast-manager-graph-modal'
import { useFetchForecastThisMonth } from '../useFetchForecastManager'
import moment from 'moment'
import { CrmUserSelect } from 'crm-components'
import { DatePicker } from 'util-components'
import { CrmButton } from '@cartrack-crm/ui'
export const ForecastManager = props => {
  const [currentMonth, setCurrentMonth] = useState(
    moment(new Date()).format('YYYY-MM')
  )
  const [datePickerMonth, setDatePickerMonth] = useState(new Date())
  const [ownerUser, setOwnerUSer] = useState()
  const [filterql, setFilterQl] = useState<any>()
  const { forecastSize } = useFetchForecastThisMonth(null, currentMonth)

  const [isShowForecastModal, setIsShowForecastModal] = useState(false)
  const [isShowForecastModalGraph, setShowForecastModalGraph] = useState(false)
  const handleOwnerUserChange = value => {
    setOwnerUSer(value)
    const newFilter = {
      ...filterql,
      user_uid: value
    }
    setFilterQl(newFilter)
  }
  const handleDateChange = value => {
    const date = moment(new Date(value)).format('YYYY-MM')
    setDatePickerMonth(new Date(value))
    setCurrentMonth(date)
    const newFilter = {
      ...filterql,
      month_date: date
    }
    setFilterQl(newFilter)
  }
  const monthLabel = moment(currentMonth, 'YYYY-MM').format('MMMM YYYY')
  const currentYear = new Date().getFullYear()
  const toMonth = new Date(currentYear + 10, 11)
  return (
    <div className="CrmFullpageContainer CrmFullpageContainer--scrollInner">
      <div className="util-fullHeight util-flexGrow util-flexColumn">
        <div className="util-flexRow util-paddingMd">
          <div className="util-flexGrow">
            <div className="util-flexRow">
              <div className="util-marginRight" style={{ width: '250px' }}>
                <CrmUserSelect
                  name="ownerUser"
                  input={{
                    value: ownerUser,
                    onChange: handleOwnerUserChange
                  }}
                  placeholder="Select Owner User"
                />
              </div>
              <div className="util-marginRight" style={{ width: '250px' }}>
                <DatePicker
                  disabledDays={new Date(currentYear - 1, 1)}
                  placeholder="Choose month"
                  onChange={handleDateChange}
                  initialMonth={datePickerMonth}
                  toMonth={toMonth}
                />
              </div>
              <div className="util-marginRight" style={{ width: '250px' }}>
                <CrmButton
                  label="Create Forecasts"
                  onClick={() => setIsShowForecastModal(true)}
                />
              </div>
              <div className="util-marginRight util-flexRow">
                <label className="util-textVerticalAlignCenter util-marginRight">
                  Total forecasts size for {monthLabel} : {forecastSize}
                </label>
                <CrmButton
                  icon="chart-line"
                  onClick={() => setShowForecastModalGraph(true)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="AccountsListContainer-tableWrapper">
          <ForecastListInner filter={filterql} />
        </div>
      </div>
      <ForecastManagerModal
        isOpen={isShowForecastModal}
        onClose={() => setIsShowForecastModal(false)}
      />
      <ForecastManagerModalGraph
        isOpen={isShowForecastModalGraph}
        onClose={() => setShowForecastModalGraph(false)}
        month={currentMonth}
      />
    </div>
  )
}
