import React, { FC } from 'react'
interface IOrganizationUnitFilterValueString {
  filterValues: any
}
export const OrganizationUnitFilterValueString: FC<IOrganizationUnitFilterValueString> = ({ filterValues }) => {
  if (!filterValues) {
    return <strong>All</strong>
  }
  return (<React.Fragment>
    {!filterValues?.organization_unit && <strong>All</strong>}
    {filterValues?.organization_unit?.department_type_uid && (
      <span>
        {'Department Type '}
        <strong>
          {filterValues.organization_unit.department_type_uid.label}
        </strong>
      </span>
    )}
    {filterValues?.organization_unit?.organization_unit_uid && (
      <span>
        {' Unit: '}
        <strong>
          {filterValues.organization_unit.organization_unit_uid.label}
        </strong>
      </span>
    )}
  </React.Fragment>)
}
