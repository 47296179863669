import React, { useState } from 'react'
import { string, shape } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'util-components'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const qlq = gql`
  query profiles($master_uid: String!) {
    master(master_uid: $master_uid) {
      master_uid
      name
      profiles {
        user_profile_uid
        name
        is_deleted
      }
    }
  }
`

const CrmProfileSelect = props => {
  const [showInactive, setShowInactive] = useState(false)
  const handleShowInactive = () => {
    setShowInactive(!showInactive)
  }
  const toolboxRenderer = () => (
    <div>
      <Icon
        name="archive"
        title="Show deleted department"
        style={{
          position: 'absolute',
          top: '12px',
          right: '30px',
          opacity: showInactive ? '1.0' : '0.3',
          fontSize: '14px'
        }}
        onClick={handleShowInactive}
      />
    </div>
  )

  const variables = {
    master_uid: props.crmContext.master.master_uid
  }
  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options = data?.master?.profiles
          ? data.master.profiles
              .filter(v => (showInactive ? true : !v.is_deleted))
              .map(v => ({
                ...v,
                value: v.user_profile_uid
              }))
          : []

        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_profile"
                  defaultMessage="Select Profile"
                />
              )
            }
            toolboxRenderer={toolboxRenderer}
          />
        )
      }}
    </Query>
  )
}

CrmProfileSelect.propTypes = {
  placeholder: string,
  input: shape({}),
  crmContext: shape()
}

export default withCrmContext(CrmProfileSelect)
