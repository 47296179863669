/* eslint-disable */
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  CHANGE_AGENT_STATUS,
  AGENT_STATUS_CHANGED
} from 'crm-duxs/user-session'

function _getStatusName(code) {
  switch (code) {
    case 'dialer_auto':
      return 'Auto Dialer'
    default:
      return 'Other'
  }
}

function* changeAgentStatus(action) {
  console.log('changeAgentStatus SAGA')
  // Build new session

  const userSession = {
    startedAt: new Date(),
    sessionStatusCode: action.payload.newStatusCode,
    sessionStatusName: _getStatusName(action.payload.newStatusCode)
  }
  yield put({
    type: AGENT_STATUS_CHANGED,
    payload: {
      userSession
    }
  })
}

function* settingUserSessionSaga() {
  yield takeLatest(CHANGE_AGENT_STATUS, changeAgentStatus)
}

export default settingUserSessionSaga
