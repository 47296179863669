import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { func, shape, bool, string } from 'prop-types'
import MeSettingsForm from './components/settings-form.jsx'
import MeInstanceForm from './components/instance-form.jsx'
import MeSipForm from './components/sip-form.jsx'
import FleetLoginForm from './components/fleet-login-form.jsx'
import { getUser } from 'crm-duxs/crm-reducer'
import { AdminHeader, VerticalNav, UserInfo } from 'util-components'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import {
  changeMyPassword,
  loadSipUser,
  getSipUser,
  getFetch,
  changeSipUser,
  fleetLogin,
  getFleetToken,
  disconnectFleet
} from 'crm-duxs/settings/me-reducer'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import { CrmFullpageContainer, CrmJsonPreviewModal } from 'crm-components'
import { withApollo } from 'react-apollo'
import { QL_GET_USER_PERMISSION } from './ql'

const menuItems = [
  {
    label: 'General Info',
    id: 'general-settings'
  }
]

class CrmMeSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      permissions: [],
      jwt: {}
    }
    this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this)
    this.handleChangeSipUser = this.handleChangeSipUser.bind(this)
    this.handleFleetLogin = this.handleFleetLogin.bind(this)
  }

  async componentDidMount() {
    const { data } = await this.props.client.query({
      query: QL_GET_USER_PERMISSION,
      variables: {
        master_uid: this.props.crmContext.master.master_uid,
        instance_uid: this.props.crmContext.instance.instance_uid,
        user_uid: this.props.user.user_uid
      }
    })
    const permissions = data.master.instance.adminUser.listPermissions
    this.setState({ permissions })

    window.jwtShowInterval = setInterval(() => {
      this.setState({ jwt: getJWTJson() })
    }, 1000)
  }
  componentWillUnmount() {
    clearInterval(window.jwtShowInterval)
  }

  componentWillMount() {
    if (!this.props.sipUser) {
      this.props.loadSipUser(this.props.user.user_uid)
    }
  }

  handleChangeSipUser(values) {
    console.log('handleChangeSipUser', values)
    this.props.changeSipUser(values)
  }

  handleFleetLogin(values) {
    console.log('handleFleetLogin', values)
    this.props.fleetLogin(values.username, values.password)
  }

  handleChangePasswordClick(values) {
    if (
      values.new_password &&
      values.new_password !== '' &&
      values.new_password.length > 4 &&
      values.new_password === values.confirm_password
    ) {
      const formValue = Object.assign({}, values)
      formValue.user = {}
      formValue.user.data = this.props.user
      this.props.doChangePassword(formValue)
      this.props.resetPasswordForm()
    }
  }

  render() {
    const sipForm = this.props.fetch ? (
      'Loading..'
    ) : (
      <section>
        <MeSipForm
          onSubmit={this.handleChangeSipUser}
          initialValues={this.props.sipUser}
        />
      </section>
    )
    return (
      <CrmFullpageContainer style={{ padding: 50 }}>
        <AdminHeader>
          {this.props.user && <UserInfo username={this.props.user.username} />}
        </AdminHeader>
        <div className="UserSettings-main">
          <VerticalNav
            items={menuItems}
            active={'General Info'}
            scrollOffset={200}
          />
          <section className="util-contentRight AlertSettings-contentRight">
            <div style={{ height: '100%', width: '100%' }}>
              <section style={{ marginBottom: 20 }}>
                <MeInstanceForm />
              </section>
              <section style={{ marginBottom: 20 }}>
                <MeSettingsForm onSubmit={this.handleChangePasswordClick} />
              </section>
              {sipForm}
              <section style={{ marginBottom: 20 }}>
                <FleetLoginForm
                  onSubmit={this.handleFleetLogin}
                  fleetToken={this.props.fleetToken}
                  fleetDisconnect={this.props.fleetDisconnect}
                />
              </section>

              <section>
                <CrmJsonPreviewModal
                  buttonName="Permissions"
                  json={this.state.permissions}
                />
                <CrmJsonPreviewModal
                  buttonName="JWT Token"
                  json={this.state.jwt}
                />
              </section>
            </div>
          </section>
        </div>
      </CrmFullpageContainer>
    )
  }
}

const getJWTJson = () => {
  try {
    const accessToken = window.localStorage.getItem('crmAccessToken')
    const refreshToken = window.localStorage.getItem('crmRefreshToken')

    const refreshDates = getDatesFromToken(refreshToken)
    const tokenDates = getDatesFromToken(accessToken)
    const lastNetworkActivityTime = window.localStorage.getItem(
      'lastNetworkActivityTime'
    )
    const momentLastNetwork = moment(parseInt(lastNetworkActivityTime))
    return {
      access_token: accessToken,
      access_token_issued_at: tokenDates.iat + ' ' + tokenDates.issuedAt,
      access_token_will_expire_at: tokenDates.exp + ' ' + tokenDates.expiresIn,
      refresh_token: refreshToken,
      refresh_token_issued_at: refreshDates.iat + ' ' + refreshDates.issuedAt,
      refresh_token_will_expire_at:
        refreshDates.exp + ' ' + refreshDates.expiresIn,
      last_network_activity_time: `${momentLastNetwork.format(
        'YYYY/MM/DD HH:mm:ss'
      )} (${momentLastNetwork.fromNow()})`
    }
  } catch (error) {
    window.crmLogout()
  }
}

const getDatesFromToken = token => {
  const data = jwtDecode(token)
  const format = 'YYYY/MM/DD HH:mm:ss'
  return {
    exp: moment(data.exp * 1000).format(format),
    iat: moment(data.iat * 1000).format(format),
    expiresIn: `(${moment(data.exp * 1000).fromNow()}) (${data.exp -
      moment().unix()} seconds)`,
    issuedAt: `(${moment(data.iat * 1000).fromNow()}) (${moment().unix() -
      data.iat} seconds)`
  }
}

CrmMeSettings.propTypes = {
  fetch: bool.isRequired,
  user: shape({}).isRequired, // eslint-disable-line
  sipUser: shape({}).isRequired,
  doChangePassword: func.isRequired,
  resetPasswordForm: func.isRequired,
  loadSipUser: func.isRequired,
  changeSipUser: func.isRequired,
  fleetLogin: func.isRequired,
  fleetToken: string.isRequired,
  fleetDisconnect: func.isRequired
}

function mapStateToProps(state) {
  return {
    user: getUser(state),
    onFetch: getFetch(state),
    sipUser: getSipUser(state),
    fleetToken: getFleetToken(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangePassword: values => dispatch(changeMyPassword(values)),
    resetPasswordForm: () => dispatch(reset('crmMeSettingForm')),
    loadSipUser: id => dispatch(loadSipUser(id)),
    changeSipUser: values => dispatch(changeSipUser(values)),
    fleetLogin: (username, password) =>
      dispatch(fleetLogin(username, password)),
    fleetDisconnect: () => dispatch(disconnectFleet())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCrmContext(withApollo(CrmMeSettings)))
