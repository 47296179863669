import React, { useState, useEffect, SFC } from 'react'
import '@cartrack-crm/ui/style/crm-tree/crm-tree.scss'

interface Props {
  large?: boolean
  single?: boolean
  parents: any[any]
  handleSelect: (any) => void
  selected?: any[any]
}

export const CrmTree: SFC<Props> = props => {
  const [selected, setSelected] = useState([])
  const [openItems, setOpenItems] = useState([])

  useEffect(() => {
    let abort = false

    if (!abort) {
      setSelected(props.selected)
    }

    return () => {
      abort = true
    }
  }, [props.selected])

  return (
    <div className={`FilterTree ${props.large ? 'FilterTree__large' : ''}`}>
      <div className="util-fullWidth"></div>
      <div className="FilterTree__OptionsList" style={{ position: 'relative', width: '100%' }}>
        {props.parents?.map((parent, index) => (
          <Parent
            key={index}
            large={props.large}
            item={parent}
            selected={selected}
            onSelected={item => {
              props.handleSelect(item)

              setSelected([item.value])

              if (openItems?.includes(item.value)) {
                setOpenItems(openItems.filter(v => item.value !== v))
              } else {
                setOpenItems(openItems.concat(item.value))
              }
            }}
            openItems={openItems}
          />
        ))}
      </div>
    </div>
  )
}

const Parent = ({ item, selected, onSelected, openItems, large }) => {
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)

  useEffect(() => {
    let abort = false

    if (!abort && openItems?.includes(item.value)) {
      setActive(true)
    }

    return () => {
      abort = true
    }
  }, [openItems])

  const hasChildren = item.children.length > 0
  const onClick = () => {
    if (hasChildren) {
      toggle()
      onSelected(item)
    } else {
      onSelected(item)
    }
  }

  return (
    <div>
      <div
        className={`FilterTree__Options__Item${
          selected?.includes(item.value) ? ' FilterTree__Options__Item--selected' : ''
        }`}
        onClick={onClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: hasChildren ? 600 : 400
        }}
      >
        <i
          style={{ width: large ? 15 : 10 }}
          className={hasChildren ? `fa fa-caret-${active ? 'down' : 'right'}` : undefined}
        />
        {item.name}
      </div>
      {active && (
        <Children
          large={large}
          children={item.children}
          selected={selected}
          onSelected={onSelected}
          openItems={openItems}
        />
      )}
    </div>
  )
}

const Children = ({ children, selected, onSelected, openItems, large }) => (
  <div style={{ paddingLeft: large ? 15 : 5 }}>
    {children.map(child => {
      return (
        <Parent
          large={large}
          item={child}
          key={child.value}
          selected={selected}
          onSelected={onSelected}
          openItems={openItems}
        />
      )
    })}
  </div>
)
