import { fitBounds } from 'google-map-react/utils'

// Calculate x as a percentage of longitude, and y through unprojecting the latitude with the mercator projection formula
export function latLng2World({ lat, lng }) {
  const sin = Math.sin((lat * Math.PI) / 180)
  const x = lng / 360 + 0.5
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI

  y = y < -1 ? -1 : y > 1 ? 1 : y
  return { x, y }
}

// Convert x and y percentages to pixels based on map zoom
export function world2Screen({ x, y }, zoom) {
  const scale = Math.pow(2, zoom)
  return {
    x: x * scale * 256,
    y: y * scale * 256
  }
}

export function screen2World({ x, y }, zoom) {
  const scale = Math.pow(2, zoom)
  return {
    x: x / scale / 256,
    y: y / scale / 256
  }
}

// Convert percentages to lat and lng
export function world2LatLng({ x, y }) {
  const n = Math.PI - 2 * Math.PI * y

  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: x * 360 - 180
  }
}

export function findNwSeFromWorld(geometry) {
  return geometry.reduce(
    (a, c) => {
      const x = Math.min(c.x, a.x)
      const y = Math.min(c.y, a.y)
      const maxX = Math.max(c.x, a.maxX)
      const maxY = Math.max(c.y, a.maxY)
      return { x, y, maxX, maxY }
    },
    {
      x: geometry[0].x,
      y: geometry[0].y,
      maxX: geometry[0].x,
      maxY: geometry[0].y
    }
  )
}

function deg2rad(deg) {
  return deg * 0.01745329251 // PI / 180
}

export function distanceBetweenLatLng(lat1, lon1, lat2, lon2) {
  // Haversine formula, that does *not* care about the earth being squashed
  const R = 3959 // Radius of the earth in mi
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d
}

export function zoomToBounds(
  items,
  mapState,
  options = { shortLatLng: false }
) {
  const lat = options.shortLatLng ? 'lat' : 'latitude'
  const lng = options.shortLatLng ? 'lng' : 'longitude'

  const frame = items.reduce(
    (frame, v) => ({
      minLat: v[lat] < frame.minLat ? v[lat] : frame.minLat,
      maxLat: v[lat] > frame.maxLat ? v[lat] : frame.maxLat,
      minLng: v[lng] < frame.minLng ? v[lng] : frame.minLng,
      maxLng: v[lng] > frame.maxLng ? v[lng] : frame.maxLng
    }),
    { minLat: 90, maxLat: -90, minLng: 180, maxLng: -180 }
  )

  const bounds = {
    sw: { lat: frame.minLat - 0.0001, lng: frame.minLng - 0.0001 },
    ne: { lat: frame.maxLat + 0.0001, lng: frame.maxLng + 0.0001 }
  }

  const { width, height } = mapState
  let {
    center: { lat: latitude, lng: longitude },
    zoom
  } = fitBounds(bounds, {
    width,
    height
  })
  if (options.maxZoom) zoom = Math.min(zoom, options.maxZoom)
  if (options.minZoom) zoom = Math.max(zoom, options.minZoom)
  return [latitude, longitude, zoom]
}

function coordsAreEqual(a, b) {
  return (a.lat || a.y) === (b.lat || b.y) && (a.lng || a.x) === (b.lng || b.x)
}

export function geoEndsAreEqual(geoA, geoB) {
  if (!geoA || !geoB) return false
  if (geoA.length !== geoB.length) return false
  if (geoA.length === 0 && geoB.length === 0) return true

  return (
    coordsAreEqual(geoA[0], geoB[0]) &&
    coordsAreEqual(geoA[geoA.length - 1], geoB[geoB.length - 1])
  )
}
