/* eslint-disable global-require */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { shape, func, arrayOf } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { CrmButton, CrmTextInput, CrmHbox } from 'crm-components'
import { getUsers, actions } from 'crm-duxs/messages-reducer'
import { bindActions } from 'crm-utils/reducer-utils'
import { getAvatarUrl } from 'crm-utils/accounts-utils'

class MessagesTabSendNofitication extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSelectUser = this.handleSelectUser.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleClickSend = this.handleClickSend.bind(this)
    this._renderUserItem = this._renderUserItem.bind(this)
  }

  handleMessageChange(event) {
    this.setState({ ...this.state, message: event.currentTarget.value })
  }
  handleTitleChange(event) {
    this.setState({ ...this.state, title: event.currentTarget.value })
  }
  handleClickSend() {
    if (!this.state.title || !this.state.selectedUser) {
      return toastr.error('Please select user and write message title')
    }
    const message = {
      users: [this.state.selectedUser.user_uid],
      body: this.state.message,
      title: this.state.title
    }
    this.setState({ ...this.state, isSending: true }, () => {
      this.props.actions.sendNotificationToMobile({
        message,
        onSuccess: () => {
          console.log('Send Message on success')
          toastr.info('Message sent')
          this.setState({
            ...this.state,
            isSending: false,
            title: undefined,
            body: undefined
          })
        }
      })
    })
  }

  handleSelectUser(event) {
    const id = event.currentTarget.id
    const user = this.props.users
      ? this.props.users.find(u => u.user_uid === id)
      : undefined
    this.setState({ ...this.state, selectedUser: user })
  }

  componentDidMount() {
    this.props.actions.fetchUsers()
  }

  _renderUserItem(user) {
    const headshotUrl = user.headshot_url

    return (
      <div
        key={user.user_uid}
        id={user.user_uid}
        className="CrmMessagesPopup-userItem"
        onClick={this.handleSelectUser}
      >
        <div className="CrmMessagesPopup-userItem-headshot">
          <img
            className="CalendarEventItem-avatar-img"
            src={getAvatarUrl(headshotUrl)}
            width="30"
            height="30"
          />
        </div>
        <div className="CrmMessagesPopup-userItem-body">
          <div className="CrmMessagesPopup-userItem-name">{user.full_name}</div>
          <div className="CrmMessagesPopup-userItem-details">
            {user.mobile_phone}
          </div>
        </div>
      </div>
    )
  }
  render() {
    const users = this.props.users
      ? this.props.users.filter(u => u.is_active)
      : []
    return (
      <CrmHbox fill fullHeight>
        <div className="CrmMessagesPopup-usersList">
          {users.map(this._renderUserItem)}
        </div>
        <div className="CrmMessagesPopup-rightWrapper">
          <div className="util-marginTop">
            <h4>
              Send mobile phone notification to:{' '}
              {this.state.selectedUser
                ? this.state.selectedUser.full_name
                : 'Select User First'}
            </h4>
            {this.state.selectedUser && (
              <div>
                <div className="row util-marginTop">
                  <div className="col-md-8">
                    <CrmTextInput
                      placeholder="Title"
                      input={{
                        value: this.state.title,
                        onChange: this.handleTitleChange
                      }}
                    />
                  </div>
                </div>
                <div className="row util-marginTop">
                  <div className="col-md-8">
                    <CrmTextInput
                      placeholder="Message"
                      input={{
                        value: this.state.message,
                        onChange: this.handleMessageChange
                      }}
                    />
                  </div>
                </div>
                <div className="row util-marginTop">
                  <div className="col-md-8" style={{ textAlign: 'right' }}>
                    <CrmButton
                      label={
                        this.state.isSending ? 'Sending...' : 'Send Message'
                      }
                      disabled={this.state.isSending}
                      type="primary"
                      onClick={this.handleClickSend}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CrmHbox>
    )
  }
}

MessagesTabSendNofitication.propTypes = {
  users: arrayOf(shape()).isRequired,
  actions: shape({}).isRequired,
  fetchUsers: func.isRequired
}

function mapStateToProps(state) {
  return {
    users: getUsers(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActions(dispatch, actions)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesTabSendNofitication)
