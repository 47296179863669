import gql from 'graphql-tag'

export const qlGetResources = gql`
  query resources($tag: JSON, $containName: String, $includeDeleted: Boolean) {
    resources(
      tag: $tag
      containName: $containName
      includeDeleted: $includeDeleted
    ) {
      resource_uid
      name
      description
      size
      mime_type
      is_deleted
      account_uid
      activity_uid
      created_time
      file_link
      parsed
      meta
    }
  }
`

const qlCreateImport = gql`
  mutation import_create($importData: import_in) {
    import_create(import: $importData) {
      resource_uid
      status
      imported
      duplicate
    }
  }
`

export const qlImportHistoryLog = gql`
  query import_history_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    import_history_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        import_log_uid
        result
        config_mapping
        created_time
      }
    }
  }
`

export const qlImportConfig = gql`
  query import_configs {
    import_configs {
      template_uid
      name
      type
      data_mapping
    }
  }
`

export const qlCreateImportConfig = gql`
  mutation import_config_create($importConfig: import_config_in) {
    import_config_create(import_config: $importConfig) {
      status
    }
  }
`

export const qlUpdateImportConfig = gql`
  mutation import_config_update($importConfig: import_config_in) {
    import_config_update(import_config: $importConfig) {
      status
    }
  }
`

export default qlCreateImport
