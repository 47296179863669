export const countryFlags = {
  uae: require('../../assets/flags/uae.png'),
  hk: require('../../assets/flags/hk.png'),
  es: require('../../assets/flags/es.png'),
  id: require('../../assets/flags/id.png'),
  ke: require('../../assets/flags/ke.png'),
  my: require('../../assets/flags/my.png'),
  mz: require('../../assets/flags/mz.png'),
  ph: require('../../assets/flags/ph.png'),
  pl: require('../../assets/flags/pl.png'),
  pt: require('../../assets/flags/pt.png'),
  sg: require('../../assets/flags/sg.png'),
  th: require('../../assets/flags/th.png'),
  za: require('../../assets/flags/za.png')
}
