/* eslint-disable camelcase */
import { createSelector } from 'reselect'
import { getActivityTypes } from './activities-reducer'
import { buildCrmReducer } from 'crm-utils/reducer-utils'

// Reducer used by Create Activity forms
// Initial
const initialState = {
  activity: undefined,
  isEditing: false,
  isSaving: false,
  addressSaving: false,
  // Here we hold list of available statuses - either for new or completed activity
  availableStatuses: [],
  selectedStatus: undefined,
  isNextActionRequired: false,
  nextAction: 'shedule_contact',
  callPbxStatus: undefined,
  liveCallStatus: undefined, // Which step of call are we in: pre-call, dialing, call, post-call,
  generateReporting: false
}

// Actions
export const actions = {
  startActivityEdit: {},
  setEditedActivity: {
    updater: action => ({
      isEditing: true,
      isSaving: false,
      activity: action.payload.activity,
      availableStatuses: action.payload.availableStatuses
        ? action.payload.availableStatuses
        : [] // Reset and reload available statuses
    })
  },
  endActivityEdit: {
    updater: () => ({ isEditing: false, activity: undefined })
  },
  fetchAvailableStatusesSuccess: {
    updater: action => ({ availableStatuses: action.payload.availableStatuses })
  },
  // Starts editing new activity
  initNewActivity: {
    updater: () => ({ isSaving: false })
  },
  createActivity: {
    updater: () => ({ isSaving: true })
  },
  updateActivity: {
    updater: () => ({ isSaving: true })
  },
  saveUpdateActivityError: {
    updater: () => ({ isSaving: false })
  },
  updateActivitySuccess: {
    updater: action => {
      // console.log('updateActivitySuccess updater')
      const ret = {}
      ret.isSaving = false
      if (!action.payload.keepOpen) {
        ret.activity = undefined
      }
      return ret
    }
  },
  createActivitySuccess: {
    updater: action => {
      const ret = { isEditing: false, isSaving: false }
      if (!action.payload.keepOpen) {
        ret.activity = undefined
      }
      // console.log('createActivitySuccess updater')
      // console.dir(ret)
      return ret
    }
  },
  createActivityError: {
    updater: () => ({ isEditing: false, isSaving: false })
  },
  activityFormChange: {},
  setIsNextActionRequired: {
    updater: action => ({
      isNextActionRequired: action.payload.isNextActionRequired
    })
  },
  generateMinutesReport: {
    // eslint-disable-next-line
    updater: (templateCode, activityId) => ({ generateReporting: true })
  },
  receiveMinutesReport: {
    // eslint-disable-next-line
    updater: (templateCode, activityId) => ({ generateReporting: false })
  }
}

/**
 * This reducer is used to manage status of currently edited activity
 * Especially it is used to
 *  - create new activity
 *  - edit activity
 *  - perform other activity actions
 *
 * As we keep all the status inside this reducer (instead of some view) - there can be only ONE currently edited activity
 */

function functionActivityReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

// Selectors
export const getActivity = state => state.crm.activity.activity
export const getIsEditing = state => state.crm.activity.isEditing
export const getIsSaving = state => state.crm.activity.isSaving
export const getAddressIsSaving = state => state.crm.activity.addressSaving
export const getAvailableStatuses = state =>
  state.crm.activity.availableStatuses
export const getSelectedStatus = state => state.crm.activity.selectedStatus
export const getIsNextActionRequired = state =>
  state.crm.activity.isNextActionRequired

const _getActivityType = (activity, types) => {
  if (!activity || !types) {
    return undefined
  }
  return types.find(
    type => type.activity_type_uid === activity.activity_type_uid
  )
}

export const getActivityType = createSelector(
  getActivity,
  getActivityTypes,
  _getActivityType
)

const reducer = buildCrmReducer(functionActivityReducer, actions)
export const getCallPbxStatus = state => state.crm.activity.callPbxStatus
export default reducer
