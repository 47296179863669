import calendarsAPI from '../api/calendars-api'
import {
  SWITCH_CALENDAR_VIEW,
  CHANGE_DATE_IN_CALENDAR_WEEK,
  CHANGE_DATE_IN_CALENDAR_DAY,
  TOGGLE_FILTER_CANCEL_MEETING,
  RECEIVED_CALENDAR_ACTIVITIES,
  FETCH_CALENDAR_ACTIVITIES,
  VIEW_TODAY_CALENDAR,
  CALENDAR_SELECTED_DATE,
  RECEIVE_USERS,
  USER_FILTER_CHANGE,
  USER_GROUP_FILTER_CHANGE,
  ACTIVITY_TYPE_CHANGE,
  getStartAndEndDate,
  getIsMyCalendar,
  getUsersCalendar,
  getUserFilterSelected,
  getUserGroupFilterSelected,
  getMeetingCanceled,
  getActivityTypeFilterSelected
} from '../../crm/duxs/calendars-reducer'
import { call, put, takeLatest, select } from 'redux-saga/effects'

function getParamsExtraForType(name) {
  if (name === 'my') {
    return { account_uid: '79da1a8f-61e3-4b71-b13a-401ec54191c9' } // eslint-disable-line camelcase
  }

  return {}
}

function* fetchCalendar(action) {
  const isMyCalendar = yield select(getIsMyCalendar(action.name))

  if (!isMyCalendar) {
    const users = yield select(getUsersCalendar(action.name))

    if (users.length === 0) {
      const usersList = yield call(calendarsAPI.fetchUsers)
      yield put({
        type: RECEIVE_USERS,
        payload: { users: usersList },
        name: action.name
      })
    }
  }
  const date = yield select(getStartAndEndDate(action.name))
  const params = Object.assign(getParamsExtraForType(action.name), {
    startDate: date.start.format('YYYY-MM-DD'), // eslint-disable-line camelcase
    endDate: date.end.format('YYYY-MM-DD') // eslint-disable-line camelcase
  })
  const userSelected = yield select(getUserFilterSelected(action.name))

  if (!isMyCalendar && userSelected) {
    params.user_uid = userSelected // eslint-disable-line
  }

  const userGroupSelected = yield select(
    getUserGroupFilterSelected(action.name)
  )

  const activityType = yield select(getActivityTypeFilterSelected(action.name))

  if (!isMyCalendar && userGroupSelected) {
    params.resource_pool_uid = userGroupSelected // eslint-disable-line
  }

  if (!isMyCalendar && activityType && activityType !== '') {
    params.activity_type_uid = activityType // eslint-disable-line
  }

  if (yield select(getMeetingCanceled(action.name))) {
    // eslint-disable-next-line
    params.canceledFilter = 'canceledFilter'
  }

  const calendarData = yield call(
      calendarsAPI.fetchCalendar,
      isMyCalendar,
      params
  )

  // yield call(console.log, 'RECEIVE_CALENDAR', calendarData.data)
  yield put({
    type: RECEIVED_CALENDAR_ACTIVITIES,
    payload: {
      calendarData,
      calendarCode: action.payload ? action.payload.name : undefined
    },
    name: action.name
  })
}

function* fetchCalendarTogether(action) {
  yield put({ type: FETCH_CALENDAR_ACTIVITIES, name: action.name })
}

function* calendarsSaga() {
  yield takeLatest(SWITCH_CALENDAR_VIEW, fetchCalendarTogether)
  yield takeLatest(CHANGE_DATE_IN_CALENDAR_WEEK, fetchCalendarTogether)
  yield takeLatest(CHANGE_DATE_IN_CALENDAR_DAY, fetchCalendarTogether)
  yield takeLatest(VIEW_TODAY_CALENDAR, fetchCalendarTogether)
  yield takeLatest(CALENDAR_SELECTED_DATE, fetchCalendarTogether)
  yield takeLatest(USER_FILTER_CHANGE, fetchCalendarTogether)
  yield takeLatest(USER_GROUP_FILTER_CHANGE, fetchCalendarTogether)
  yield takeLatest(ACTIVITY_TYPE_CHANGE, fetchCalendarTogether)
  yield takeLatest(FETCH_CALENDAR_ACTIVITIES, fetchCalendar)
  yield takeLatest(TOGGLE_FILTER_CANCEL_MEETING, fetchCalendar)
}

export default calendarsSaga
