/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { shape, func, node, string, bool } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown'
import enhanceWithClickOutside from 'react-click-outside'
import PhoneNumberWidget from './phone-number-widget.jsx'
import { qlqfetchAccountContactDetails } from 'crm-data/accounts'
import { LoadingIcon } from 'util-components'

class DropdownItems extends PureComponent {
  handleClickOutside = () => {
    this.props.onClickedOutside()
  }
  render() {
    return <div className={this.props.className}>{this.props.children}</div>
  }
}
DropdownItems.propTypes = {
  onClickedOutside: func.isRequired,
  children: node.isRequired,
  className: string.isRequired
}

const DropdownItemsEx = enhanceWithClickOutside(DropdownItems)

class AccountContactDetailsBigDropdown extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: false
    }
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  handleDataChanged = () => {
    this.props.data.refetch()
  }

  componentDidMount() {
    console.log('AccountContactDetailsBigDropdown didmount', this.props)
    this.doAutoSelect()
  }
  doAutoSelect() {
    console.log('doAutoSelect')
    if (
      this.props.data.account &&
      this.props.data.account.contact_details &&
      this.props.autoSelect
    ) {
      const phoneNumbers = this.props.data.account.contact_details
        ? this.props.data.account.contact_details
            .slice()
            .filter(cd => cd.kind === 'phone' || cd.kind === 'mobile')
        : []
      console.log('do auto select')
      if (phoneNumbers.length === 1) {
        this.handleSelect({
          currentTarget: {
            id: phoneNumbers[0].contact_details_uid
          }
        })
      }
    }
  }
  handleSaveNewContactDetails = async () => {
    // Cconst newStatus = {
    //   account_uid: this.props.account.account_uid,
    //   owner_user_uid: this.state.newOwner,
    //   account_status_code: this.state.newStatus,
    //   valid_from: msToUTCTimestamp(this.state.newStatusValidFrom)
    // }
    // await this.setStateAsync({ ...this.state, isSaving: true })
    // await this.props.mutate({ variables: { account_status: newStatus } })
    // toastr.info('New market potential saved')
    // await this.setStateAsync({ ...this.state, isSaving: false })
    // this.props.data.refetch()
    // this.setState({ ...this.state, newSize: undefined })
  }
  componentWillReceiveProps = nextProps => {
    console.log('AccountContactDetailsBigDropdown componentWillReceiveProps')
    const current = this.props.account
      ? this.props.account.contact_details
      : undefined
    const next = nextProps.account
      ? nextProps.account.contact_details
      : undefined
    if (next && JSON.stringify(next) !== JSON.stringify(current)) {
      console.log('Received contact details')
    }
  }

  handleSelect = event => {
    console.log('handleSelect', event)
    const id = event.currentTarget.id
    if (id) {
      const contactDetails = this.getContactDetails()
      const cd = contactDetails.find(cd => cd.contact_details_uid === id)
      this.setState({ selectedItem: cd })
      if (this.props.onChange) {
        this.props.onChange(cd)
      }
      if (this.props.selectable) {
        this.handleClickedOutside()
      }
    }
  }
  handleNewOwnerSelect = value => {
    this.setState({ ...this.state, newOwner: value })
  }
  handleNewStatusChange = value => {
    console.log('handleNewStatusChange')
    this.setState({ ...this.state, newStatus: value })
  }
  handleNewStatusFromChange = value => {
    this.setState({ ...this.state, newStatusValidFrom: value })
  }
  handleClickedOutside = () => {
    this.setState({ ...this.state, isOpened: false })
  }
  handleClickOpen = () => {
    this.setState({ ...this.state, isOpened: !this.state.isOpened })
  }
  renderItem = cd => (
    <div
      key={cd.contact_details_uid}
      id={cd.contact_details_uid}
      onClick={this.handleSelect}
      className="u-flexRow CrmBigDropdown-item"
    >
      <PhoneNumberWidget contact={cd} />
    </div>
  )

  getContactDetails = () =>
    this.props.data &&
    this.props.data.account &&
    this.props.data.account.contact_details
      ? this.props.data.account.contact_details.slice()
      : []
  render() {
    console.log('account contact details modal', this.props)
    let contactDetails = this.getContactDetails()

    contactDetails = contactDetails.sort((a, b) =>
      a.kind && a.kind !== b.kind
        ? a.kind.localeCompare(b.kind)
        : a.value
        ? a.value.localeCompare(b.value)
        : 0
    )
    // Filter only phone numbers
    if (this.props.phoneOnly) {
      contactDetails = contactDetails.filter(
        cd => cd.kind === 'phone' || cd.kind === 'mobile'
      )
    }
    const isLoading = this.props.data.loading
    const { isOpened } = this.state
    return (
      <div className="CrmBigDropdown">
        <div className="CrmBigDropdown-content" onClick={this.handleClickOpen}>
          {isLoading && <LoadingIcon />}
          {!isLoading && (
            <span>
              {this.props.selectable ? (
                this.state.selectedItem ? (
                  <PhoneNumberWidget contact={this.state.selectedItem} />
                ) : (
                  <FormattedMessage
                    id="crm.ui.widget.select"
                    defaultMessage="Select"
                  />
                )
              ) : this.props.dropdownContent ? (
                this.props.dropdownContent()
              ) : (
                <FormattedMessage
                  id="crm.ui.widget.select"
                  defaultMessage="Select"
                />
              )}
            </span>
          )}
        </div>
        <div
          className="CrmBigDropdown-arrow util-flexAlignItemsCenter"
          onClick={this.handleClickOpen}
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </div>
        {isOpened && (
          <DropdownItemsEx
            className="CrmBigDropdown-dropdown"
            onClickedOutside={this.handleClickedOutside}
          >
            {isLoading ? (
              <LoadingIcon />
            ) : contactDetails ? (
              contactDetails.map(this.renderItem)
            ) : (
              <span />
            )}
          </DropdownItemsEx>
        )}
      </div>
    )
  }
}

const qlOptions = {
  options: ownProps => ({
    variables: { account_uid: ownProps.accountUid } // eslint-disable-line
  }),
  props: ({ data, ownProps }) => ({ data, ...ownProps })
}
const AccountContactDetailsBigDropdownQL = compose(
  graphql(qlqfetchAccountContactDetails, {
    ...qlOptions
  })
)(AccountContactDetailsBigDropdown)

AccountContactDetailsBigDropdown.defaultProps = {
  phoneOnly: false,
  onChange: undefined,
  selectable: false,
  autoSelect: false,
  account: undefined
}

AccountContactDetailsBigDropdown.propTypes = {
  account: shape({}),
  data: shape({}).isRequired,
  dropdownContent: func.isRequired,
  phoneOnly: bool,
  onChange: func,
  selectable: bool,
  autoSelect: bool
}

export default AccountContactDetailsBigDropdownQL
