import React from 'react'
import widgetCustomersWonHoc, {
  WidgetWinsHocProps
} from './widget-customers-won-hoc'
import { DashBoardWidget } from '../dashboard-widget'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetCustomersWon: React.ComponentType<AnalyticDashboardWidget> = widgetCustomersWonHoc(
  (props: WidgetWinsHocProps) => (
    <DashBoardWidget
      title='Customers Won'
      footerRender={props.threeMonthAgoWon}
      avgDataPassedMonth={props.avgWonThreeMonth}
      totalNumber={props.totalWon}
      isLoading={props.isLoading}
      refetch={props.refetchData}
      footerName={props.footerName}
      avgDataPassedMonthPerPerson={props.avgWonPersonPassedMonth}
      avgPerPersonNumber={props.avgWonCurrentMonthPerPerson}
      secondFooterName={props.footerNamePerPerson}
      secondFooterRender={props.avgWonPassedMonthData}
      subtitleSecondBlock='month date average'
      onClickDetail={props.onClickDetail}
      subtitle={props.mainCaption}
    />)
)
