import React from 'react'
import { Formik, Form, Field } from 'formik'
import { CrmTextInput, CrmButton } from '@cartrack-crm/ui'
import { SectionHeader } from './master-list'
import { isEmptyData } from '../utils/common-utils.js'

interface MasterFormStep3Props {
  masterUid: string
  initialValues: any
  master_customer: any
  isSaving: boolean
  handleCreateAdminUser: any
  created_users: any
}

export const MasterFormStep3: React.SFC<MasterFormStep3Props> = (props) => {
  console.log('MasterFormStep3', props)

  if (!isEmptyData(props.created_users)) {
    return (
      <React.Fragment>
        <SectionHeader name="Administrator User" />
        <div
          className="util-marginLg"
          style={{ maxHeight: '300px', overflowY: 'auto' }}
        >
          <div>Created Users</div>
          <div className="util-marginLeft">
            {props.created_users.map((v) => (
              <li className="">{`${v.full_name}`}</li>
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }

  const initialValues = {
    login: '',
    password: '',
    full_name: ''
  }

  return (
    <div>
      <SectionHeader name="Administrator User" />
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues }}
        onSubmit={props.handleCreateAdminUser}
        validateOnChange
        validate={(values) => {
          const errors = {} as any
          if (!values.login) errors.login = '* required'
          if (!values.password) errors.password = '* required'
          if (!values.full_name) errors.full_name = '* required'
          return errors
        }}
      >
        {({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form>
              <fieldset
                className="Form-fieldset"
                disabled={props.initialValues?.step >= 3}
              >
                <div className="row">
                  <div className="col-md-3">
                    <Field
                      name="login"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue('login', e.currentTarget.value),
                            value: values.login
                          }}
                          placeholder="Login"
                        />
                      )}
                    />
                    {errors.login && (
                      <div className="CrmForm-field-error">{errors.login}</div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="password"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue('password', e.currentTarget.value),
                            value: values.password
                          }}
                          placeholder="Password"
                          type="password"
                        />
                      )}
                    />
                    {errors.password && (
                      <div className="CrmForm-field-error">
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="full_name"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue('full_name', e.currentTarget.value),
                            value: values.full_name
                          }}
                          placeholder="Full Name"
                        />
                      )}
                    />
                    {errors.full_name && (
                      <div className="CrmForm-field-error">
                        {errors.full_name}
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <CrmButton
                      type="submit"
                      label="Create Admin User"
                      disabled={props.isSaving}
                      enable={!props.isSaving}
                      isSaving={props.isSaving}
                    />
                  </div>
                </div>
              </fieldset>
            </Form>
          )
        }}
      </Formik>
      <br /> <br /> <br />
    </div>
  )
}
