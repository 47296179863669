import React, { FC } from 'react'
import { qlqResourcePoolsList } from 'crm-data/resource-pools'
import { FiltersOptionList } from '../tools'
import { Query } from 'react-apollo'

interface IUserGroupSelect {
  filterValues: any
  onFilterValueChanged: Function
}

export const UserGroupSelect: FC<IUserGroupSelect> = props => (
  <Query query={qlqResourcePoolsList}>
    {({ loading, data }) => {
      const userGroups = data && data.users_groups ? [...data.users_groups] : []

      if (userGroups) {
        userGroups.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
      }

      const userGroupsOptions = userGroups
        ? userGroups.map(t => ({
            value: t.resource_pool_uid,
            name: t.name
          }))
        : []

      return (
        <FiltersOptionList
          enableSearch={true}
          isLoading={loading}
          options={userGroupsOptions}
          onFilterValueChanged={props.onFilterValueChanged}
          selectedValue={
            props.filterValues &&
            props.filterValues.user &&
            props.filterValues.user.resource_pool &&
            props.filterValues.user.resource_pool.resource_pool_uid
              ? props.filterValues.user.resource_pool.resource_pool_uid.value
              : undefined
          }
        />
      )
    }}
  </Query>
)
