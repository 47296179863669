import React from 'react'
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl'

export const columnsOrder = {
  type: 'Order',
  fields: [
    {
      description: 'Account',
      type: 'Account',
      name: 'account'
    },
    {
      description: 'Owner',
      type: 'User',
      name: 'owner'
    },
    {
      description: 'External ID',
      name: 'external_id',
      type: 'string'
    },
    {
      description: 'Order UID',
      name: 'order_uid',
      type: 'string'
    },
    {
      description: 'Status',
      name: 'status_code',
      type: 'string'
    },
    {
      description: 'Vehicle',
      name: 'vehicle_registration',
      type: 'string'
    },

    {
      description: 'Fitment Date',
      name: 'completed_date',
      type: 'date',
      id: 'completed_time',
      Cell: (row) =>
        row.original.completed_date ? (
          <span>
            <FormattedDate value={row.original.completed_date} />
          </span>
        ) : (
          <span />
        )
    },
    {
      description: 'Subscription',
      name: 'subscription',
      type: 'string'
    },
    {
      description: 'Hardware',
      name: 'hardware_price',
      queryFragment: {
        selections: [{ name: 'details' }]
      },
      Cell: (row) => (row.original.details ? row.original.details.hardware_price : ''),
      type: 'string'
    },
    {
      description: 'Fitter',
      name: 'fitter_name',
      type: 'string'
    },
    {
      description: 'Package',
      name: 'product_package',
      type: 'string'
    },
    {
      description: 'Sale Date',
      name: 'sale_date',
      type: 'date',
      Cell: (row) =>
        row.original.sale_date ? (
          <span>
            <FormattedDate value={row.original.sale_date} />
          </span>
        ) : (
          <span />
        )
    }
  ]
}
