import React, { useState, useEffect } from 'react'
import moment from 'moment'
import '@cartrack-crm/ui/style/crm-month-slider/crm-month-slider.scss'
const TEXT_MONTH_FORMAT = "MMMM YYYY"

interface CrmMonthSliderProps {
  lang?: string
  currentMonth?: string
  onChange?: (res: onchangeType) => void
  width?: number,
  disable?: boolean

}

export type onchangeType = {
  'currentMonth': string,
  'month': string,
  'year': string,
  'moment': moment.Moment,
}

export const CrmMonthSlider: React.FunctionComponent<CrmMonthSliderProps> = (props) => {
  const [currentFullMonth, setFullMonth] = useState<any>()
  useEffect(() => {
    const setCurrentMonth = () => {
      moment.locale(props.lang || 'en')
      if (props.currentMonth) {
        const currentMonth = moment(props.currentMonth, 'YYYY-MM').format(TEXT_MONTH_FORMAT)
        setFullMonth(currentMonth)
      } else {
        const currentMonth = moment(new Date()).format(TEXT_MONTH_FORMAT)
        setFullMonth(currentMonth)
      }

    }
    setCurrentMonth()
  }, [props.currentMonth])

  const clickPrevMonth = async () => {
    const prevMonth = moment(currentFullMonth, TEXT_MONTH_FORMAT).subtract(1, 'M').format(TEXT_MONTH_FORMAT)
    setFullMonth(prevMonth)
    changeValue(prevMonth)
  }

  const clickNexMonth = async () => {
    const nextMonth = moment(currentFullMonth, TEXT_MONTH_FORMAT).add(1, 'M').format(TEXT_MONTH_FORMAT)
    setFullMonth(nextMonth)
    changeValue(nextMonth)
  }

  const changeValue = (newMonth: string) => {
    if (props.onChange) {
      const momentObject = moment(newMonth, TEXT_MONTH_FORMAT);
      const response: onchangeType = {
        'moment': momentObject,
        'currentMonth': momentObject.format(TEXT_MONTH_FORMAT),
        'month': momentObject.format('MMMM'),
        'year': momentObject.format('YYYY')
      }
      props.onChange(response)
    }
  }


  return (
    <div className='CrmMonthSlider-wrapper' style={{ width: props.width || 'auto' }}>
      {!props.disable &&
        <a href='#' className='CrmMonthSlider-PrevBtn' onClick={clickPrevMonth}>
          <i className='fa fa-chevron-left' />
        </a>
      }

      <div className='CrmMonthSlider-MonthContent'>
        {currentFullMonth}
      </div>
      {!props.disable &&
        <a href='#' className='CrmMonthSlider-NextBtn' onClick={clickNexMonth}>
          <i className='fa fa-chevron-right' />
        </a>
      }
    </div>)
}