import React from 'react'
import { withRouter } from 'react-router-dom'
import { useCrmComponent } from 'crm-utils/crm-component-util'
import { CrmLoadingIcon } from '@cartrack-crm/ui'
import { WidgetJsonConfiguration } from '@cartrack-crm/crm-analytics'
import adminWidgetToolsHoc from './admin-widget-tools-hoc'

const WidgetJsonConfigurationWithHoc = adminWidgetToolsHoc((props) => (<WidgetJsonConfiguration {...props} />))

const AdminWidgetDetail = (props) => {
  const componentUid = props?.match?.params?.dashboardUid
  const { getOneByUid } = useCrmComponent(props)
  const { loading, component, refetch } = getOneByUid(componentUid)
  const initWidget = () => {
    return {
      type: 'widget',
      name: 'New Widget',
      def_version: 5,
      filters: null,
      access_level: 'manager',
      meta: null
    }
  }

  const widget = !loading && component ? component : initWidget()
  const isCreateMode = componentUid === 'new'
  const handleSaved = (component) => {
    if (isCreateMode)
      props.history.push(`/crm/admin/components/${component?.component_uid}`)
    refetch()
  }

  return (
    <div className='CrmFullpageContainer CrmFullpageContainer--scrollInner'>
      <div className='util-flexGrow util-fullHeight util-flexColumn'>
        {!loading && widget && <WidgetJsonConfigurationWithHoc {...props} widget={widget} onSaved={handleSaved} />}
        {loading && <CrmLoadingIcon />}
      </div>
    </div>
  )
}

export default withRouter(AdminWidgetDetail)