import { bool, string } from 'prop-types'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import React from 'react'

const editQuotationHoc = WrappedComponent => {
  class EditQuotationHocInner extends React.PureComponent {
    static propTypes = {
      isSaving: bool.isRequired,
      fieldName: string.isRequired,
      id: string.isRequired,
      label: string.isRequired,
      editable: bool.isRequired
    }
    render() {
      console.log('render edit-address-hoc')
      return (
        <WrappedComponent
          {...this.props}
          isSaving={this.props.isSaving}
          fieldName={this.props.fieldName}
          id={this.props.id}
          label={this.props.label}
          editable={this.props.editable}
        />
      )
    }
  }

  return crmGenericCommandHoc(EditQuotationHocInner)
}

export const saveHandler = async (value, props, isManagerMode = false) => {
  if (props.handleProductItemChange) {
    await props.handleProductItemChange(props.fieldName, props.index, value)
  } else {
    await props.handleFieldChange(props.fieldName, value)
  }
}

export default editQuotationHoc
