/* eslint-disable react/no-danger */
import React from 'react'
import { Link } from 'react-router-dom'
import { shape, bool, func } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CrmListItem, CrmListItemText, CrmListItemIcon } from 'crm-components'
import { accountLink } from 'crm-utils/accounts-utils'

class ProcessListItem extends React.PureComponent {
  _secondaryText = () => {
    const { process } = this.props
    return process.name
  }
  _renderPrimary = () => {
    if (this.props.process.match) {
      return (
        <div dangerouslySetInnerHTML={{ __html: this.props.process.match }} />
      )
    }
    return <span>{this.props.process.account_name}</span>
  }
  _renderInner = ({ process, hasPermission, primary }) => (
    <CrmListItem key={process.id} button>
      <CrmListItemIcon classes={{ root: 'util-textRed' }}>
        {this.props.checkPermission && !hasPermission ? (
          <span style={{ color: '#ce5239', marginRight: 5 }}>
            <FontAwesomeIcon icon="lock" />
          </span>
        ) : (
          <FontAwesomeIcon
            icon={
              process.meta && process.meta.process_kind === 'opportunity'
                ? 'handshake'
                : 'phone'
            }
          />
        )}
      </CrmListItemIcon>
      <CrmListItemText primary={primary} secondary={this._secondaryText()} />
    </CrmListItem>
  )
  render() {
    const { process } = this.props
    console.log('Render Process Item', process)
    const primary = this._renderPrimary()
    const hasPermission =
      process.object_permissions && process.object_permissions.length > 0
    const linkMode = hasPermission || !this.props.checkPermission
    if (linkMode) {
      return (
        <Link
          to={accountLink(process) + '/process/' + process.id}
          onClick={this.props.onClick}
        >
          {this._renderInner({ hasPermission, primary, process })}
        </Link>
      )
    }
    return this._renderInner({ hasPermission, primary, process })
  }
}

ProcessListItem.propTypes = {
  process: shape({}).isRequired,
  checkPermission: bool,
  onClick: func.isRequired
}

ProcessListItem.defaultProps = {
  checkPermission: false
}

export default ProcessListItem
