import React from 'react'
import useCrmCurrentUser from '../../../core/auth/hooks/use-crm-current-user'
import { useGetUserInformation } from 'crm-duxs/user-session'
import { UserInfoCard } from '../ct-dashboards/rm-dashboard/rm-summary-dashboard'
import { CrmFullpageContainer } from '../../../components/index'
import { DashBoardRM } from './new-dashboard-rm'
import { DashBoardAgent } from './new-dashboard-agent'
import { SaleManagerDashboard } from './dashboard-sale-manager'
import { TeleSaleManagerDashboard } from './dashboard-tele-sale-manager'
import { AnalyticsContextProvider } from 'crm-core/analytics/context'
import { CrmFilterContextProvider } from '../dashboards/dashboard-context/crm-filter-context'
const UserDashboard = props => {
  const crmCurrentUser = useCrmCurrentUser()
  const userUid: String = crmCurrentUser?.user_uid
  const { getDashboardCode, loading } = useGetUserInformation(userUid)
  const dashboardCode = getDashboardCode()
  const showDashboardRm = !loading && dashboardCode === 'rm'
  const showDashboardTeleSale = !loading && dashboardCode === 'tele_sales'
  const showDashboardSaleManager = !loading && dashboardCode === 'sales_manager'
  const showDashboardTeleSaleManager = !loading && dashboardCode === 'tele_sales_manager'

  const renderDashboard = () => {
    if (showDashboardRm) {
      return <DashBoardRM />
    } else if (showDashboardTeleSale) {
      return <DashBoardAgent />
    } else if (showDashboardSaleManager) {
      return <SaleManagerDashboard />
    } else if (showDashboardTeleSaleManager) {
      return <TeleSaleManagerDashboard />
    } else {
      return <DashboardDefault />
    }
  }
  if (!crmCurrentUser) {
    return <div>Not logged in</div>
  }
  return (
      <AnalyticsContextProvider>
        <CrmFilterContextProvider>{renderDashboard()}</CrmFilterContextProvider>
      </AnalyticsContextProvider>
  )
}

export const DashboardDefault = () => {
  const currentUser = useCrmCurrentUser()
  const user = { user_uid: currentUser?.user_uid }

  return (
    <CrmFullpageContainer noInnerNav>
      <div className="util-paddingXl">
        <div className="row">
          <div className="col-md-2">
            <UserInfoCard user={user} />
          </div>
        </div>
      </div>
    </CrmFullpageContainer>
  )
}

UserDashboard.defaultProps = {
  isManagerMode: false,
  currentScope: {}
}

export default UserDashboard
