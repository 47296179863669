import React from 'react'
import { string } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { FormattedMessage } from 'react-intl'

const CapFunctionSelect = props => {
  const variables = {}
  const qlq = gql`
    {
      crm_functions {
        function_uid
        name
        module_code
        fn_code
        name
        description
        access_level
        is_api
        is_graphql
        is_core
        is_user_function
      }
    }
  `
  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.crm_functions
            ? data.crm_functions.map(v => ({
                ...v,
                value: v.function_uid
              }))
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_function"
                  defaultMessage="Select Function"
                />
              )
            }
          />
        )
      }}
    </Query>
  )
}

CapFunctionSelect.propTypes = {
  accountUid: string.isRequired,
  placeholder: string
}

export default CapFunctionSelect
