import React from 'react'
import { withApollo } from 'react-apollo'
import moment from 'moment'
import { shape } from 'prop-types'
import { qlAnalyticsWidgetQuery } from 'crm-data/analytics'
import { mapContextFiltersIntoWidget, mapContextToFilter } from '../../dashboard-utils'

const widgetDefinition = {
  code: 'ct_daily_leads_2019',
  content: {
    data: {
      dataSources: [
        {
          code: 'AccountListQM',
          alias: 'NewLeads',
          type: 'QM',
          filter: {},
          qlQuery:
            '\n          query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {\n            master(master_uid: $master_uid)  { \n                master_uid\n                name\n                instance(instance_uid: $instance_uid) { \n                    instance_uid\n                    name\n                    accountsc(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {\n                      edges { \n                        edge \n                        { \n                          account_uid\n                          lead_in_date\n                        }\n                      }\n                  }\n                }\n            }\n          }\n          ',
          aggregate: [
            {
              id: 'account_uid',
              type: 'count'
            }
          ],
          qlRoot: 'accountsc',
          qlEdges: true,
          qlVariables: {
            groupBy: ['lead_in_date'],
            aggregate: [
              {
                id: 'account_uid',
                type: 'count'
              }
            ]
          },
          contextMapping: {
            lead_in_date: 'activity_date'
          }
        },
        {
          code: 'AccountsListQM',
          type: 'QM',
          alias: 'AuthenticatedLead',
          fields: ['validated_date'],
          filter: [],
          qlOnly: true,
          qlRoot: 'accountsc',
          qlEdges: true,
          qlQuery:
            '\n          query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {\n            master(master_uid: $master_uid)  {\n                master_uid\n                name\n                instance(instance_uid: $instance_uid) {\n                    instance_uid\n                    name\n                    accountsc(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {\n                      edges {\n                        edge\n                        {\n                            account_uid\n                            validated_date\n                        }\n                      }\n                  }\n                }\n            }\n        }\n          ',
          aggregate: [
            {
              id: 'account_uid',
              type: 'count'
            }
          ],
          qlVariables: {
            groupBy: ['validated_date'],
            aggregate: [
              {
                id: 'account_uid',
                type: 'count'
              }
            ]
          },
          contextMapping: {
            validated_date: 'activity_date',
            owner_user_uid: 'user_uid',
            owner__organization_unit_uid: 'organization_unit_uid'
          }
        },
        {
          code: 'SalesProcessQM',
          type: 'QM',
          fields: ['assigned_to_user_date'],
          filter: {
            account_uid: { $ne: null },
            is_first: true
          },
          qlOnly: true,
          qlRoot: 'sales_processes',
          qlEdges: true,
          qlQuery:
            ' query master(\n    $master_uid: String!\n    $instance_uid: String!\n    $filter: JSON\n    $groupBy: [String]\n    $aggregate: [JSON]\n  ) {\n    master(master_uid: $master_uid) {\n      master_uid\n      name\n      instance(instance_uid: $instance_uid) {\n        instance_uid\n        name\n        sales_processes(\n          filter: $filter\n          aggregate: $aggregate\n          groupBy: $groupBy\n        ) {\n          edges {\n            edge {\n              process_uid\n         assigned_to_user_date\n            }\n          }\n        }\n      }\n    }\n  }',
          qlVariables: {
            groupBy: ['assigned_to_user_date'],
            aggregate: [
              {
                id: 'process_uid',
                type: 'count'
              }
            ]
          },
          contextMapping: {
            assigned_to_user_date: 'activity_date',
            owner_user_uid: 'user_uid'
          }
        },
        {
          code: 'ActivitiesListQM',
          name: 'MeetingsScheduled',
          type: 'QM',
          fields: ['start_date'],
          filter: {
            activity: {
              activity_type: {
                medium_code: 'meeting'
              }
            },
            role_code: 'performer',
            account_is_deleted: false
          },
          qlOnly: true,
          qlRoot: 'activity_participants',
          qlEdges: true,
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
                      master(master_uid: $master_uid)  {
                        master_uid name 
                        instance(instance_uid: $instance_uid) {
                          instance_uid name
                          activity_participants(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                            edges{
                              edge {
                                activity_uid
                                start_date
                              }
                            }
                          }
                        }
                      }
                    }
                  `,
          aggregate: [
            {
              id: 'activity_uid',
              type: 'count'
            }
          ],
          qlVariables: {
            groupBy: ['start_date'],
            aggregate: [
              {
                id: 'activity_uid',
                type: 'count'
              }
            ]
          },
          contextMapping: {
            start_date: 'activity_date'
          }
        },
        {
          code: 'OpportunitiesListQM',
          type: 'QM',
          alias: 'WonOpportunities',
          fields: ['completed_date'],
          filter: {
            status_code: 'won',
            is_existing_customer: false
          },
          qlOnly: true,
          qlRoot: 'opportunities',
          qlEdges: true,
          qlQuery:
            'query opportunities( \n   $master_uid: String! \n   $instance_uid: String! \n   $filter: JSON \n   $aggregate: [JSON] \n   $groupBy: [String] \n ) { \n   master(master_uid: $master_uid) { \n     instance(instance_uid: $instance_uid) { \n       opportunities(filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { \n         edges { \n           edge { \n             opportunity_uid \n             completed_date \n             quantity \n           } \n         } \n         } \n     } \n   } \n } \n ',
          aggregate: [
            {
              id: 'quantity',
              type: 'sum'
            },
            {
              id: 'opportunity_uid',
              type: 'count'
            }
          ],
          qlVariables: {
            groupBy: ['completed_date'],
            aggregate: [
              {
                id: 'quantity',
                type: 'sum'
              },
              {
                id: 'opportunity_uid',
                type: 'count'
              }
            ]
          },
          contextMapping: {
            completed_date: 'activity_date'
          }
        },
        {
          code: 'OrdersListQM',
          type: 'QM',
          filter: {
            status_code: 'completed'
          },
          qlOnly: true,
          qlRoot: 'orders',
          qlEdges: true,
          qlQuery:
            '\n          query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON])\n          {\n             master(master_uid: $master_uid) {\n               master_uid\n               name\n               instance(instance_uid: $instance_uid) {\n                 instance_uid\n                 name\n                 orders(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {\n                   edges {\n                     edge {\n                       completed_date\n                       order_uid\n                     }\n                   }\n                 }\n               }\n             }\n           }\n          ',
          aggregate: [
            {
              id: 'order_uid',
              type: 'count'
            }
          ],
          qlVariables: {
            groupBy: ['completed_date'],
            aggregate: [
              {
                id: 'order_uid',
                type: 'count'
              }
            ]
          },
          contextMapping: {
            completed_date: 'activity_date'
          }
        },
        {
          code: 'FirstFitmentsList',
          type: 'QM',
          alias: 'FirstFitments',
          filter: {
            sequence: 1
          },
          qlOnly: true,
          qlRoot: 'first_fitmentsc',
          qlEdges: true,
          qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
              master(master_uid: $master_uid)  {
                  master_uid
                  name
                  instance(instance_uid: $instance_uid) {
                      instance_uid
                      name
                     first_fitmentsc(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
                         edges {
                          edge
                          {
                              order_uid
                              completed_date
                              cams_username
                              owner_user_uid
                              account_uid
                          }
                         }
                     }
                  }
              }
          }`,
          contextMapping: { completed_date: 'activity_date' },
          qlVariables: {
            groupBy: ['completed_date'],
            aggregate: [
              {
                id: 'account_uid',
                type: 'count'
              }
            ]
          }
        },
        {
          code: 'CampaignCostQM',
          type: 'QM',
          alias: 'CampaignCostQM',
          fields: ['date'],
          qlOnly: true,
          qlRoot: 'campaign_costc',
          qlEdges: true,
          qlQuery:
            'query master($master_uid: String!, $instance_uid: String!, $filter: JSON,  $aggregate: [JSON], $groupBy:[String]) {\n    master(master_uid: $master_uid)  { \n        master_uid\n        name\n        instance(instance_uid: $instance_uid) { \n            instance_uid\n            name\n            campaign_costc(filter: $filter, aggregate: $aggregate, groupBy: $groupBy){\n                edges { edge { value date } }\n            }\n        }\n    }\n }',
          aggregate: [
            {
              id: 'value',
              type: 'sum'
            }
          ],
          qlVariables: {
            groupBy: ['date'],
            aggregate: [
              {
                id: 'value',
                type: 'sum'
              }
            ]
          },
          contextMapping: {
            date: 'activity_date'
          }
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyFields: ['date'],
        mapping: [
          {
            fromDataSourceName: 'MeetingsScheduled',
            keyFields: { date: 'start_date' },
            mapping: [
              {
                from: 'activity_uid',
                to: 'meetings_scheduled'
              }
            ]
          },
          {
            fromDataSourceName: 'OrdersListQM',
            keyFields: { date: 'completed_date' },
            mapping: [
              {
                from: 'order_uid',
                to: 'fitments'
              }
            ]
          },
          {
            fromDataSourceName: 'FirstFitments',
            keyFields: { date: 'completed_date' },
            mapping: [
              {
                from: 'account_uid',
                to: 'first_fitments'
              }
            ]
          },
          {
            fromDataSourceName: 'NewLeads',
            keyFields: {
              date: 'lead_in_date'
            },
            mapping: [
              {
                from: 'account_uid',
                to: 'new_leads'
              }
            ]
          },
          {
            fromDataSourceName: 'AuthenticatedLead',
            keyFields: {
              date: 'validated_date'
            },
            mapping: [
              {
                from: 'account_uid',
                to: 'authenticated_leads'
              }
            ]
          },
          {
            fromDataSourceName: 'SalesProcessQM',
            keyFields: { date: 'assigned_to_user_date' },
            mapping: [
              {
                from: 'process_uid',
                to: 'leads_assigned'
              }
            ]
          },
          {
            fromDataSourceName: 'CampaignCostQM',
            keyFields: {
              date: 'date'
            },
            mapping: [
              {
                from: 'value',
                to: 'lead_campaign_cost'
              }
            ]
          },
          {
            fromDataSourceName: 'WonOpportunities',
            keyFields: { date: 'completed_date' },
            mapping: [
              {
                from: 'opportunity_uid',
                to: 'won_opportunities'
              }
            ]
          }
        ]
      }
    }
  }
}

const CtDailyLeadsMonitoringHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = { selectedSections: {} }
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (
        nextProps !== undefined &&
        JSON.stringify(nextProps.analyticsContext) !== JSON.stringify(this.props.analyticsContext)
      ) {
        this.setState({}, () => {
          this.handleRefresh()
        })
      }
    }
    handleCurrentStepClick = selectedStepType => {
      this.setState({ selectedStepType })
    }
    handleRefresh = async () => {
      const isActivityDateFilterMissing = this.getIsActivityDateFilterMissing()
      if (isActivityDateFilterMissing) {
        return
      }
      // if there is filter in dashboard - merge too
      let widgetDefinitionWithFilters = widgetDefinition
      if (this.props.dashboard && this.props.dashboard.filters) {
        widgetDefinitionWithFilters = mapContextFiltersIntoWidget(widgetDefinitionWithFilters, {
          analyticsContext: { filters: this.props.dashboard.filters }
        })
      }

      widgetDefinitionWithFilters = mapContextFiltersIntoWidget(widgetDefinitionWithFilters, this.props)

      const contextFilter = mapContextToFilter(this.props, true)
      const variables = {
        widgetDefinition: widgetDefinitionWithFilters,
        filter: contextFilter
      }
      await this.setStateAsync({ isLoading: true })
      try {
        const res = await this.props.client.query({
          query: qlAnalyticsWidgetQuery,
          variables,
          fetchPolicy: 'no-cache'
        })
        if (res.data && res.data.dataSourceQuery && res.data.dataSourceQuery.data) {
          let data = [...res.data.dataSourceQuery.data.dataSources.CombinedDataSource.rawData.rows]
          data.sort((a, b) =>
            (a.date ? moment(a.date) : moment('2000')) > (b.date ? moment(b.date) : moment('2000')) ? 1 : -1
          )
          const summary = this.calculateSummary(data)
          delete summary.user_uid
          summary.full_name = 'Total'
          summary.department__name = ''
          summary.is_summary = true
          const days = Object.values(
            data.reduce((a, i) => {
              a[i.date] = i.date
              return a
            }, {})
          )
          days.sort((a, b) => ((a ? moment(a) : moment('2000')) > (b ? moment(b) : moment('2000')) ? 1 : -1))

          const calculateKpis = i => ({
            sales_to_leads:
              i.won_opportunities > 0 && i.new_leads ? Number((100 * i.won_opportunities) / i.new_leads) : undefined,
            cost_to_lead:
              i.lead_campaign_cost > 0 && i.new_leads ? Number(i.lead_campaign_cost / i.new_leads) : undefined,
            cost_to_win:
              i.lead_campaign_cost > 0 && i.first_fitments
                ? Number(i.lead_campaign_cost / i.first_fitments)
                : undefined,
            cost_to_fitment:
              i.lead_campaign_cost > 0 && i.fitments ? Number(i.lead_campaign_cost / i.fitments) : undefined
          })
          data = data
            ? data.reduce((a, i) => {
                const ret = { ...a }
                ret[i.date] = {
                  ...i,
                  ...calculateKpis(i)
                }
                return ret
              }, {})
            : {}
          data.summary = { ...summary, ...calculateKpis(summary) }
          this.setState({
            data,
            days,
            summary,
            contextFilter,
            isLoading: false
          })
        }
        return
      } catch (err) {}
      await this.setStateAsync({ isLoading: false })
    }

    calculateSummary = data =>
      data.reduce((a, i) => {
        const r = { ...a }
        Object.keys(i).forEach(k => {
          r[k] = r[k] ? r[k] + Number(i[k]) : Number(i[k])
        })
        return r
      }, {})
    toggleIsLoading(val, handler) {
      return this.setState({ isLoading: val }, handler)
    }

    handleTogleSection = (event, code) => {
      if (code) {
        const selectedSections = { ...this.state.selectedSections }
        selectedSections[code] = !selectedSections[code]
        this.setState({ selectedSections })
      }
    }
    parseData(result) {
      this.setState({ data: result.data }, () => {
        this.toggleIsLoading(false)
      })
    }

    handleShowDetailsPopup = params => {}
    getIsActivityDateFilterMissing = () =>
      !this.props.analyticsContext ||
      !this.props.analyticsContext.filters ||
      !this.props.analyticsContext.filters.activity_date ||
      !this.props.analyticsContext.filters.activity_date.value

    render() {
      const isActivityDateFilterMissing = this.getIsActivityDateFilterMissing()

      return (
        <WrappedComponent
          {...this.props}
          isActivityDateFilterMissing={isActivityDateFilterMissing}
          onRefresh={this.handleRefresh}
          isLoading={this.state.isLoading}
          data={this.state.data}
          summary={this.state.summary}
          dataByLeadSource={this.state.dataByLeadSource}
          days={this.state.days}
          contextFilter={this.state.contextFilter}
        />
      )
    }
  }

  Inner.propTypes = {
    analyticsContext: shape({}).isRequired,
    client: shape({}).isRequired,
    dashboard: shape({}).isRequired
  }

  return withApollo(Inner)
}
export default CtDailyLeadsMonitoringHoc
