import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { shape, func } from 'prop-types'
import {
  getSelectedCampaignCost,
  clearSelectedCampaign
} from 'crm-duxs/settings/campaign-cost-reducer'
import {
  CrmTextInputNumber,
  CrmFormSection,
  CrmButton,
  CrmValidatedField,
  CrmCampaignSelect,
  CrmDayPickerInput, // eslint-disable-line
  CrmDropdown
} from 'crm-components'
import { withGeneralPermissionsContextHOC } from '@cartrack-crm/crm-core'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class CampaignForm extends React.PureComponent {
  handleClickHideForm = () => {
    this.props.doClearSelectedCampaign()
  }

  _getSectionName() {
    return this.props.campaign.formType === 'create'
      ? 'Create campaign cost'
      : `Update campaign cost`
  }

  handleSelectInstance(value) {
    console.log('handleSelectInstance : ', value)
  }

  render() {
    const has_permission = this.props.generalPermissionsContext.hasPermissionByType(
      PERMISSIONS_TYPES.CONFIG_CAMPAIGN_COST_MODIFY
    )

    if (!has_permission) {
      return (
        <div className="util-marginLg">
          <div className="util-marginLg">
            {"You don't have permission to edit campaign cost"}
          </div>
          <div className="util-marginLg">
            <CrmButton
              label="Hide"
              onClick={this.handleClickHideForm}
              type="button"
            />
          </div>
        </div>
      )
    }

    return (
      <form onSubmit={this.props.handleSubmit}>
        <CrmFormSection
          sectionName={this._getSectionName()}
          className="util-paddingMd"
        >
          <fieldset className="Form-fieldset CrmForm-form-fieldset">
            <div className="row util-marginBottom">
              <div className="col-xs-12 col-md-12 col-lg-12">
                <CrmValidatedField
                  name="campaign_uid"
                  component={CrmCampaignSelect}
                  componentProps={{
                    title: 'Campaign'
                  }}
                  isRequired
                />
              </div>
            </div>

            <div className="row util-marginBottom">
              <div className="col-xs-12 col-md-12 col-lg-12">
                <CrmValidatedField
                  name="date"
                  component={CrmDayPickerInput}
                  componentProps={{
                    placeholder: 'Date',
                    name: 'date'
                  }}
                  isRequired
                />
              </div>

              {/* <div className="col-xs-12 col-md-12 col-lg-12">
                <CrmValidatedField
                  name="date2"
                  component={CrmDayPickerInput}
                  componentProps={{
                    placeholder: 'Date',
                    name: 'date2'
                  }}
                  isRequired
                />
              </div> */}
            </div>

            <div className="row util-marginBottom">
              <div className="col-xs-12 col-md-12 col-lg-12">
                <CrmValidatedField
                  name="value"
                  component={CrmTextInputNumber}
                  componentProps={{
                    type: 'text',
                    placeholder: 'Cost',
                    name: 'value'
                  }}
                  isRequired
                />
              </div>
            </div>
            <div className="row util-marginBottom">
              <div className="col-xs-12 col-md-12 col-lg-12">
                <CrmValidatedField
                  name="currency_code"
                  component={CrmDropdown}
                  componentProps={{
                    name: 'currency_code',
                    labelField: 'currency',
                    placeholder: 'Currency',
                    options: [
                      'THB',
                      'ZAR',
                      'SGD',
                      'PLN',
                      'MYR',
                      'PHP',
                      'USD',
                      'IDR',
                      'EUR',
                      'KES',
                      'MZN'
                    ]
                  }}
                  isRequired
                />
              </div>
            </div>
            <CrmButton label="Save" type="submit" />
            <CrmButton
              label="Hide"
              onClick={this.handleClickHideForm}
              type="button"
            />
          </fieldset>
        </CrmFormSection>
      </form>
    )
  }
}

CampaignForm.propTypes = {
  doClearSelectedCampaign: func.isRequired,
  handleSubmit: func.isRequired,
  campaign: shape({}),
  generalPermissionsContext: shape({}).isRequired
}

CampaignForm.defaultProps = {
  campaign: {}
}

function mapStateToProps(state) {
  const campaignCostSelectState = getSelectedCampaignCost(state)
  return {
    campaign: campaignCostSelectState,
    initialValues: {
      ...campaignCostSelectState.originalData,
      currency_code: campaignCostSelectState.originalData
        ? campaignCostSelectState.originalData.currency_code
        : 'THB'
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doClearSelectedCampaign: () => dispatch(clearSelectedCampaign())
  }
}

function validate() {
  const errors = {}

  return errors
}

const CampaignReduxForm = reduxForm({
  form: 'crmCampaignCostForm',
  validate,
  enableReinitialize: true
})(CampaignForm)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGeneralPermissionsContextHOC(CampaignReduxForm))
