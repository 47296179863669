import React, { useState, useEffect } from 'react'
import { CrmModal, FlatTable, CrmCheckbox } from 'crm-components'
import { qlqListUsersQuery } from 'crm-data/users'
import { useQuery } from '@apollo/react-hooks'
import {
  CrmMonthSlider,
  onchangeType
} from '@cartrack-crm/ui/src/month-slider/crm-month-slider'
import {
  useForecastSendCommand,
  initForecastParams,
  initForecasts
} from '../useForecastSendCommand'
import { useCrmToastr, CrmToastrType } from '@cartrack-crm/ui/src/toastrs'
import {
  CrmLoadingIcon,
  CrmTabNavigator,
  CrmResourcePoolSelect
} from 'crm-components'
import { qlqResourcePoolItemsList } from 'crm-data/resource-pools'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { useCrmContext } from 'crm-core/contexts/crm-context'

const MODAL_TITLE = 'Forecasts Manager'
const PRIMARY_BTN_LABEL = 'Create Forecasts'
const SECONDARY_BTN_LABEL = 'Cancel'

interface ForecastManagerModalProps {
  isOpen: boolean
  onClose: () => any
}

export const ForecastManagerModal: React.FunctionComponent<ForecastManagerModalProps> = (
  props
) => {
  const [
    sendInitCommand,
    sendConfirmForecastCommand,
    sendUnlockForecastCommand,
    sendInitManyForecasts
  ] = useForecastSendCommand(props)
  const [showToastr, hideToastr] = useCrmToastr()
  let currentMonth: string = moment(new Date()).format('YYYY-MM')
  let currentListUser: [] = []
  const [month, setMonth] = useState(currentMonth)
  const [users, setUsers] = useState([])

  const onChangeListValue = (month: string, listUser: []) => {
    currentMonth = month
    currentListUser = listUser
    setMonth(month)
    setUsers(listUser)
  }

  const handleSaveForecasts = async () => {
    try {
      const params: any = {
        userUids: users,
        month: month
      }
      await sendInitManyForecasts(params)
      showToastr(CrmToastrType.success, 'Success Create Forecasts')
    } catch (e) {
      showToastr(CrmToastrType.error, e)
    }
    props.onClose()
  }
  return (
    <CrmModal
      isOpen={props.isOpen}
      title={MODAL_TITLE}
      onClose={props.onClose}
      showFooter
      primaryButtonLabel={PRIMARY_BTN_LABEL}
      secondaryButtonLabel={SECONDARY_BTN_LABEL}
      onPrimaryButton={() => handleSaveForecasts()}
      onSecondaryButton={props.onClose}
    >
      <ForecastManagerModalInner onChange={onChangeListValue} month={month} />
    </CrmModal>
  )
}

interface ForecastManagerModalInnerProps {
  onChange: (month: string, listUser: any) => any
  month: string
}

const ForecastManagerModalInner: React.FunctionComponent<ForecastManagerModalInnerProps> = (
  props
) => {
  const tabs = [
    {
      label: 'Create by user',
      childComponent: (
        <ForecastFlatUserTable onChange={props.onChange} month={props.month} />
      )
    },
    {
      label: 'Create by user group',
      childComponent: (
        <ForecastCreateByGroupUser
          onChange={props.onChange}
          month={props.month}
        />
      )
    }
  ]
  return <CrmTabNavigator tabs={tabs} contentWrapperNoPadding={true} />
}

const ForecastCreateByGroupUser = (props) => {
  const [userGroup, setUserGroup] = useState()
  const [isSelectedAll, setSelectAll] = useState(false)
  const [selectedUser, setSelectedUser] = useState(new Set())
  const [month, setMonth] = useState(props.month)
  const { data, loading, error } = useQuery(qlqResourcePoolItemsList, {
    variables: {
      filter: {
        resource_pool_uid: userGroup
      }
    },
    fetchPolicy: 'no-cache'
  })
  const tmpData =
    data &&
    data.resource_pool_items_list_qm_paged &&
    data.resource_pool_items_list_qm_paged.data
      ? data.resource_pool_items_list_qm_paged.data
      : []
  const tableData = tmpData.map((el) => {
    return { user_uid: el.user_uid, full_name: el.user.full_name }
  })
  const onMonthChange = (monthValue: onchangeType) => {
    const currentMonth = monthValue.moment.format('YYYY-MM')
    setMonth(currentMonth)
    props.onChange(currentMonth, [...selectedUser])
  }
  const onChangeGroup = (value) => {
    setSelectAll(false)
    setSelectedUser(new Set())
    setUserGroup(value)
  }
  const toggleSelectAll = () => {
    if (isSelectedAll === true) {
      setSelectAll(false)
      setSelectedUser(new Set())
      props.onChange(month, [])
    } else {
      setSelectAll(true)
      const userUids = new Set([...tableData].map((el) => el.user_uid))
      setSelectedUser(new Set(userUids))
      props.onChange(month, [...userUids])
    }
  }
  const checkIsSelectAllUserInGroup = () => {
    const userUids = [...tableData].map((el) => el.user_uid)
    if (selectedUser.size === userUids.length && userUids.length !== 0) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }
  const toggleCheckBox = (value) => {
    selectedUser.has(value)
      ? selectedUser.delete(value)
      : selectedUser.add(value)
    setSelectedUser(new Set(selectedUser))
    props.onChange(month, [...selectedUser])
    checkIsSelectAllUserInGroup()
  }

  const columns = [
    {
      Header: (
        <CrmCheckbox
          input={{
            value: isSelectedAll,
            onChange: () => {
              toggleSelectAll()
            }
          }}
        />
      ),
      key: 'action',
      Cell: (row) => (
        <div key={row.original.user_uid}>
          <CrmCheckbox
            input={{
              value: selectedUser.has(row.original.user_uid),
              onChange: () => {
                toggleCheckBox(row.original.user_uid)
              }
            }}
          />
        </div>
      )
    },
    {
      Header: 'Full Name',
      key: 'full_name',
      labelMessageId: 'crm.ui.account.history.size',
      accessor: 'full_name'
    }
  ]

  return (
    <div className="util-paddingMd">
      <div className="row">
        <div className="col-md-6">
          <CrmResourcePoolSelect
            input={{
              value: userGroup,
              onChange: onChangeGroup
            }}
            placeholder={
              <FormattedMessage
                id="crm.ui.account.convert.select_users_group"
                defaultMessage="Select Users Group"
              />
            }
          />
        </div>
        <div className="col-md-6">
          <CrmMonthSlider onChange={onMonthChange} />
        </div>
      </div>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CrmLoadingIcon />
        </div>
      )}
      {tableData && (
        <FlatTable
          data={tableData}
          columns={columns}
          translation
          tableName="list_user_from_group_users"
        />
      )}
    </div>
  )
}

const ForecastFlatUserTable = (props) => {
  let tableData
  const crmContext = useCrmContext()
  const [month, setMonth] = useState(props.month)
  const [selectedUser, setSelectedUser] = useState(new Set([]))
  const variables = {
    master_uid: crmContext.master.master_uid,
    instance_uid: crmContext.instance.instance_uid,
    filter: {
      is_active: true
    },
    fields: [
      'user_uid',
      'full_name',
      'user_profile_name',
      'email',
      'department_name'
    ]
  }

  const columns = [
    {
      Header: 'Action',
      key: 'Action',
      Cell: (row) => (
        <div key={row.original.user_uid}>
          <CrmCheckbox
            input={{
              value: selectedUser.has(row.original.user_uid),
              onChange: () => {
                toggleCheckBox(row.original.user_uid)
              }
            }}
          />
        </div>
      )
    },
    {
      Header: 'Full Name',
      key: 'Full Name',
      labelMessageId: 'crm.ui.account.history.size',
      accessor: 'full_name'
    },
    {
      Header: 'Profile',
      key: 'Profile',
      accessor: 'user_profile_name'
    },
    {
      Header: 'DepartMent',
      key: 'DepartMent',
      accessor: 'department_name'
    }
  ]
  const toggleCheckBox = (value: string) => {
    selectedUser.has(value)
      ? selectedUser.delete(value)
      : selectedUser.add(value)
    setSelectedUser(new Set(selectedUser))
    props.onChange(month, [...selectedUser])
  }
  const { data, loading, error } = useQuery(qlqListUsersQuery, {
    variables,
    fetchPolicy: 'no-cache'
  })
  if (data?.master?.instance?.user_list_qm_paged?.data) {
    tableData = data.master.instance.user_list_qm_paged.data
  }
  const onMonthChange = (monthValue: onchangeType) => {
    const currentMonth = monthValue.moment.format('YYYY-MM')
    setMonth(currentMonth)
    props.onChange(currentMonth, [...selectedUser])
  }
  return (
    <div className="util-paddingMd">
      <div className="CrmForecast-MonthSlider-Wrapper">
        <CrmMonthSlider onChange={onMonthChange} />
      </div>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CrmLoadingIcon />
        </div>
      )}
      {tableData && (
        <FlatTable
          data={tableData}
          columns={columns}
          translation
          tableName="list_user_table"
        />
      )}
    </div>
  )
}
