import { createSelector } from 'reselect'
import { reconciliateGroupItemIds } from 'util-functions/functional-utils'

// Actions
export const FETCH_GEOFENCES = 'FETCH_GEOFENCES'
export const RECEIVE_GEOFENCES = 'RECEIVE_GEOFENCES'
export const UPDATE_GEOFENCE_GROUP = 'UPDATE_GEOFENCE_GROUP'
export const DELETE_GEOFENCE_GROUP = 'DELETE_GEOFENCE_GROUP'
export const CREATE_GEOFENCE_GROUP = 'CREATE_GEOFENCE_GROUP'
export const SAVE_GEOFENCE = 'SAVE_GEOFENCE'
export const DELETE_GEOFENCE = 'DELETE_GEOFENCE'

// Reducer
const initialState = {
  geofences: [],
  groups: [],
  loading: true
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GEOFENCES:
      return { ...state, loading: true }
    case RECEIVE_GEOFENCES:
      return {
        ...state,
        geofences: action.payload.geofences,
        groups: action.payload.groups,
        loading: false
      }
    default:
      return state
  }
}

// Action Creators
export function fetchGeofences() {
  return {
    type: FETCH_GEOFENCES
  }
}

export function updateGeofenceGroup(group, selectedIds) {
  return {
    type: UPDATE_GEOFENCE_GROUP,
    payload: { group, selectedIds }
  }
}

export function deleteGeofenceGroup(groupId, groupName) {
  return {
    type: DELETE_GEOFENCE_GROUP,
    payload: { groupId, groupName }
  }
}

export function createGeofenceGroup(name) {
  return {
    type: CREATE_GEOFENCE_GROUP,
    payload: {
      name
    }
  }
}

export function saveGeofence(geofence) {
  return {
    type: SAVE_GEOFENCE,
    payload: {
      geofence
    }
  }
}

export function deleteGeofence(id, name) {
  return {
    type: DELETE_GEOFENCE,
    payload: {
      id,
      name
    }
  }
}

// Selectors
export const getGeofences = state => state.geofences.geofences
export const getGeofenceGroups = state => state.geofences.groups
export const getGeofenceOptions = createSelector(getGeofences, geofences =>
  geofences.map(geofence => ({ name: geofence.name, value: geofence.id }))
)
export const getLoading = state => state.geofences.loading

// T O D O: Use create selector
export const getGeofenceGroupItemIds = (state, groupId) =>
  state.geofences.groups.find(g => g.id === groupId).itemIds

// T O D O: Use createSelector
export const getGeofenceGroup = (state, groupId) =>
  reconciliateGroupItemIds(
    getGeofenceGroups(state).find(group => group.id === groupId),
    getGeofences(state)
  )

// T O D O: Use create selector
export const getGeofence = (state, geofenceId) =>
  state.geofences.geofences.find(geofence => geofence.id === geofenceId) || null
