import React from 'react'
import { Query } from 'react-apollo'
import { qlAccountOrders } from 'crm-data/orders'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

const withOrders = WrappedComponent => {
  class Inner extends React.PureComponent {
    static propTypes = {
      ...cqCommandPropTypes
    }
    constructor(props) {
      super(props)
      this.state = {}
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    handleConvertToCustomer = async () => {
      console.log('handleConvertToCustomer')
      try {
        const command = {
          type: 'salesAccount.detectFirstFitment',
          aggregate_uid: this.props.account.account_uid,
          aggregate_type: 'salesAccount',
          payload: {}
        }
        const refetchQueries = [
          // {
          //   query: qlAccountOrders,
          //   variables: { account_uid: this.props.account.account_uid }
          // }
        ]
        console.log('Run command')
        const res = await this.props.onRunCommand(command, { refetchQueries })
        console.log('res', res)
      } catch (err) {
        throw err
      }
    }
    handleLinkOrdersByCams = async () => {
      console.log('handleLinkOrdersByCams')
      await this.setStateAsync({ isSavingReassign: true })
      try {
        const command = {
          type: 'salesAccount.relinkOrdersByCams',
          aggregate_uid: this.props.account.account_uid,
          aggregate_type: 'salesAccount',
          payload: {}
        }
        const refetchQueries = [
          {
            query: qlAccountOrders,
            variables: { account_uid: this.props.account.account_uid }
          }
        ]
        console.log('Run command')
        const res = await this.props.onRunCommand(command, { refetchQueries })
        console.log('res', res)
      } catch (err) {
        throw err
      }

      await this.setStateAsync({ isSavingReassign: false })
    }
    handleReassignOrders = async () => {
      console.log('handleReassignOrders')
      await this.setStateAsync({ isSavingReassign: true })
      try {
        const command = {
          type: 'salesAccount.reassignOrders',
          aggregate_uid: this.props.account.account_uid,
          aggregate_type: 'salesAccount',
          payload: {}
        }
        const refetchQueries = [
          {
            query: qlAccountOrders,
            variables: { account_uid: this.props.account.account_uid }
          }
        ]
        console.log('Run command')
        const res = await this.props.onRunCommand(command, { refetchQueries })
        console.log('res', res)
      } catch (err) {
        throw err
      }

      await this.setStateAsync({ isSavingReassign: false })
    }
    render() {
      return (
        <Query
          query={qlAccountOrders}
          variables={{
            account_uid: this.props.account
              ? this.props.account.account_uid
              : undefined
          }}
          skip={!this.props.account}
        >
          {({ loading, error, data, refetch }) => (
            <WrappedComponent
              {...this.props}
              orders={data && data.account ? data.account.orders : undefined}
              loading={loading}
              error={error}
              refetch={refetch}
              onReassignOrders={this.handleReassignOrders}
              onLinkOrdersByCams={this.handleLinkOrdersByCams}
              onConvertToCustomer={this.handleConvertToCustomer}
            />
          )}
        </Query>
      )
    }
  }

  return crmGenericCommandQlHoc(Inner)
}

export default withOrders
