import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { faBug } from '@fortawesome/free-solid-svg-icons'
import { useClickOutside } from 'react-click-outside-hook'
import { CrmJsonPreview } from '@cartrack-crm/ui'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics/src/context/context'
const BtnDebug = styled.div`
  border-radius: 50%;
  border: 3px solid var(--crm-color-gray70);
  color: var(--crm-color-gray70);
  position: fixed;
  padding: 15px;
  cursor: pointer;
  background: var(--crm-color-white);
  right: 10%;
  top: 25%;
  z-index: 3;
  :hover {
    color: var(--crm-color-green);
    border-color: var(--crm-color-green);
  }
  i {
    position: relative;
  }
`
const PanelWrapper = styled.div`
  color: var(--crm-color-gray70);
  min-width: 500px;
  position: absolute;
  background: var(--crm-color-white);
  border-radius: var(--crm-border-radius-lg);
  box-shadow: var(--crm-box-shadow);
  overflow: scroll;
  cursor: default;
  max-height: 500px;
  right: 0;
`

export const DebugTools = () => {
  const [showContext, setShowContext] = useState(false)
  const [ref, hasClickOutSide] = useClickOutside()
  const toggle = () => setShowContext(!showContext)
  useEffect(() => setShowContext(false), [hasClickOutSide])
  const { filters } = useAnalyticsContext()

  return (
    <BtnDebug onClick={toggle}>
      <i>
        <FontAwesomeIcon icon={faBug} />
        {showContext && (
          <PanelWrapper ref={ref}>
            <CrmJsonPreview json={filters} />
          </PanelWrapper>)}
      </i>
    </BtnDebug>)
}