import React from 'react'
import widgetFitmentSummaryHoc, { WidgetFitmentSummaryHocProps } from './widget-fitment-summary-hoc'
import { DashBoardWidget } from '../dashboard-widget'
import { DashboardCardDescription, AnalyticDashboardWidget } from '../../../../../crm/types/index'

export const WidgetFitmentSummary: React.ComponentType<AnalyticDashboardWidget> = widgetFitmentSummaryHoc(
  (props: WidgetFitmentSummaryHocProps) => (
      <DashBoardWidget
        avgDataPassedMonth={props.avgFitThreeMonth}
        avgDataPassedMonthPerPerson={props.avgFitPerPersonPassedMonth}
        avgPerPersonNumber={props.avgFitCurrentMonthPerPerson}
        footerName={props.footerName}
        footerRender={props.threeMonthAgoFitment}
        isLoading={props.isLoading}
        onClickDetail={props.onClickDetail}
        refetch={props.refetchData}
        secondFooterName={props.footerNamePerPerson}
        secondFooterRender={props.threeMonthFitmentAvgPerPersonPassedMonth}
        subtitleSecondBlock="Month date average"
        title="Fitments"
        totalNumber={props.totalFitment}
        dashboardDescription={DashboardCardDescription.FITMENTS}
        subtitle={props.mainCaption}
      />
  )
)
