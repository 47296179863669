import React from 'react'
import { getInstanceNameAndCode } from 'crm-duxs/user-session'
import { countryFlags } from 'util-components'

export const CrmShortCodeCountry = props => {
  const { InstanceName, InstanceCode } = getInstanceNameAndCode()
  return (
    <div
      className="CrmNavbar-territory util-flexRow"
      title={InstanceName}
      onClick={props.handleChangeTerritory}
    >
      <div className="util-flexColumnCenter" title={InstanceName}>
        <div>
          <img
            className="CrmNavbar-flag"
            src={countryFlags[InstanceCode.toLowerCase()]}
          />
        </div>
        <div className="CrmNavbar-countryCode">{InstanceCode}</div>
      </div>
    </div>
  )
}

// <div className="CrmNavbar-changeTerritory util-flexColumnCenter">
//   <i className="fa fa-caret-down" aria-hidden="true" />
// </div>
// &-changeTerritory {
//   margin-left: 5px;
//   font-size: 14px;
//   color: #666;
// }
