import React from 'react'
import { shape } from 'prop-types'
// import CrmBarChart from 'crm-components/charts/crm-bar-chart.jsx'

const COLORS = ['#65b7f3', '#b2d28c', '#f47735', '#f67960', '#82c6da']
const CrmBarChart = (props) => <div>todo CrmBarChart</div>

export const parseChartBarData = (props) => {
  let parsed = props.data
    ? props.data.map((row, i) => {
        const ret = { ...row, fill: COLORS[i % 5] }
        if (props.chart && props.chart.labelField) {
          ret.name = row[props.chart.labelField]
        }
        if (props.chart && props.chart.valueField) {
          ret.count = Number(row[props.chart.valueField])
        }
        return ret
      })
    : []

  parsed.sort((a, b) => b.count - a.count)
  parsed = parsed.slice(
    0,
    Math.min(parsed.length, props.limit ? props.limit : 10)
  )
  return parsed
}

export const CmpChartBars = (props: any) => {
  console.log('render CmpChartBars')
  const parsedData = parseChartBarData(props)
  const handleClickBar = () => {}
  return (
    <div
      style={{
        padding: '0 24px',
        height: 300,
        width: 300,
        overflowY: 'auto'
      }}
    >
      <CrmBarChart
        data={parsedData}
        onClickBar={handleClickBar}
        width={600}
        height={600}
      />
    </div>
  )
}

export const mock = 1
