import { toastr } from 'react-redux-toastr'
import instancesApi from 'crm-api/settings/instances-api'
import {
  FETCH_INSTANCES_SUCCESS,
  FETCH_INSTANCES,
  CREATE_INSTANCE,
  UPDATE_INSTANCE
} from 'crm-duxs/settings/instance-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function _prepareInstance(instance) {
  const ret = {
    ...instance
  }
  delete ret.__typename
  delete ret.attribute_definitions
  console.log('_prepareInstance', ret)

  return ret
}

function* fetchInstances(master_uid) {
  const instances = yield call(instancesApi.fetchInstancesApi, master_uid)
  yield put({
    type: FETCH_INSTANCES_SUCCESS,
    payload: {
      instances
    }
  })
}

function* createInstance(data) {
  yield call(
    instancesApi.createInstanceApi,
    _prepareInstance(data.payload.values)
  )

  toastr.success('Create instance success')
  yield put({
    type: FETCH_INSTANCES
  })
}

function* updateInstance(data) {
  yield call(
    instancesApi.updateInstanceApi,
    _prepareInstance(data.payload.values)
  )

  toastr.success('Update instance success')
  yield put({
    type: FETCH_INSTANCES
  })
}

function* settingInstanceSaga() {
  yield takeLatest(FETCH_INSTANCES, fetchInstances)
  yield takeLatest(CREATE_INSTANCE, createInstance)
  yield takeLatest(UPDATE_INSTANCE, updateInstance)
}

export default settingInstanceSaga
