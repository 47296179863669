import React from 'react'
import { string } from 'prop-types'

const Spacer = ({ height }) => <div style={{ height }} />

Spacer.propTypes = {
  height: string.isRequired
}

export default Spacer
