import { crmSchemaProd } from '../crm-schema-prod'

// import { allColumns as columnOptions } from '../../../modules/dashboard/components/dynamic-tables/columns'
const columnOptions = crmSchemaProd.types

export const getColumnOptionsForType = (columnOptions, objectType) =>
  columnOptions ? columnOptions.find(t => t.type === objectType) : undefined

export const getColumnByName = (columnOptions, name) =>
  columnOptions ? columnOptions.find(t => t.name === name) : undefined

export const findColumnByPath = (path, rootQLType) => {
  if (typeof path == 'object') {
    path = path.field
  }

  const splited = path.split('.')
  const resultPath = []
  let currentLevel
  let currentType = rootQLType // props.rootQLType

  for (let i = 0; i < splited.length; i++) {
    currentLevel = getColumnOptionsForType(columnOptions, currentType)

    if (!currentLevel) {
      return undefined
    }

    if (i === splited.length - 1) {
      const columnDef = getColumnByName(currentLevel.fields, splited[i])

      return columnDef != undefined
        ? {
            column: columnDef,
            path: resultPath
          }
        : undefined
    } else {
      resultPath.push(splited[i])

      const columnDef: any = currentLevel ? getColumnByName(currentLevel.fields, splited[i]) : undefined

      if (!columnDef) {
        return undefined
      }

      currentType = columnDef.type
    }
  }
}

export const mapInitialColumns = (initialColumns, rootQLType) => {
  const initialColumnsMapped = []

  if (!initialColumns) {
    return []
  }

  initialColumns.forEach(col => {
    const foundColumn = findColumnByPath(col, rootQLType)

    if (foundColumn !== undefined && foundColumn !== null) {
      initialColumnsMapped.push(foundColumn)
    }
  })

  return initialColumnsMapped
}

export const mapColumnTypeFromColumnName = column_name => {
  const names_for_account_type = ['account']
  const names_for_user_type = ['owner']

  if (names_for_account_type.includes(column_name)) return 'Account'
  if (names_for_user_type.includes(column_name)) return 'User'

  return undefined
}

export const getColumnDefinitionByPath = (path, rootQLType, columnOptions) => {
  const has_previous_nested = path && path.length > 1 ? true : false
  const previous_name = has_previous_nested ? path[path.length - 2] : undefined
  const previous_type = mapColumnTypeFromColumnName(previous_name)
  const previous_columns = has_previous_nested
    ? getColumnOptionsForType(columnOptions, previous_type)
    : getColumnOptionsForType(columnOptions, rootQLType)

  const join_name = path ? path[path.length - 1] : undefined
  const join_defifnition = previous_columns?.fields
    ? previous_columns.fields.filter(v => v.name === join_name)[0]
    : undefined

  return join_defifnition
}

export const getColumnLabelPrefixByPath = (path, rootQLType, columnOptions) => {
  const join_column_definition = getColumnDefinitionByPath(path, rootQLType, columnOptions)

  const label_prefix = join_column_definition ? join_column_definition.column_label_prefix : ''

  return label_prefix
}

export const isSkipPreviousLabelPrefixByPath = (path_level, rootQLType, columnOptions) => {
  const previous_path = path_level.reduce((ret, v2, i2) => {
    if (i2 < path_level.length - 1) ret.push(v2)
    return ret
  }, [])
  const previous_label_prefix = getColumnLabelPrefixByPath(previous_path, rootQLType, columnOptions)
  const join_column_definition = getColumnDefinitionByPath(path_level, rootQLType, columnOptions)
  const is_skip_previous_label_prefix = join_column_definition
    ? join_column_definition.column_label_skip_previous_label_prefix
    : false

  return is_skip_previous_label_prefix && previous_label_prefix
}

export const collectLabelPrefixByPath = (path, rootQLType, columnOptions) => {
  const collect_label_prefix = []

  if (path && path.length > 0) {
    path.map((v, i) => {
      const path_level = path.reduce((ret, v2, i2) => {
        if (i2 <= i) ret.push(v2)
        return ret
      }, [])
      const label_prefix = getColumnLabelPrefixByPath(path_level, rootQLType, columnOptions)

      if (label_prefix) collect_label_prefix.push(label_prefix)

      if (isSkipPreviousLabelPrefixByPath(path_level, rootQLType, columnOptions)) {
        collect_label_prefix.shift()
      }
    })
  }
  return collect_label_prefix
}

export const isSkipColumnLabel = col => {
  const is_not_root = col?.path && col.path.length > 0 ? true : false
  const is_skip = col?.column?.column_label_skip_except_root ? true : false

  return is_not_root && is_skip
}

export const buildColumnHeader = (col, rootQLType, columnOptions) => {
  const column_label = col?.column?.column_label ? col.column.column_label : ''
  const is_skip_label = isSkipColumnLabel(col)
  const collect_label_prefix = collectLabelPrefixByPath(col.path, rootQLType, columnOptions)
  const collect_words = []

  if (col?.column && col.column.column_label_skip_all_nested) {
    collect_words.push(column_label)
  } else {
    collect_label_prefix.map(v => {
      collect_words.push(v)
    })

    if (column_label && !is_skip_label) collect_words.push(column_label)
  }

  const final_label = collect_words.join(' ')
  const header = final_label ? final_label : col.column.description ? col.column.description : col.column.header

  return header
}
