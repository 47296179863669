import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import React from 'react'
import moment from 'moment'
import { groupDataByMonth } from 'crm-utils/data-utils'
import { DashboardResponse, GQLQueryRes, HeadCount } from 'crm-types'
import { createDashboardFormats, getWidgetAnalyticContext } from '../../dashboards/tools'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export type WidgetWinsHocProps = {
  avgWonCurrentMonthPerPerson?: null | number
  avgWonPassedMonthData?: null | any[any]
  avgWonPersonPassedMonth?: null | number
  avgWonThreeMonth?: number
  footerName?: string
  footerNamePerPerson?: string
  isLoading?: boolean
  refetchData?: () => any
  statistics: any
  threeMonthAgoWon?: [any]
  totalWon?: number
  onClickDetail?: Function
  mainCaption?: string
}

export type UseGetNewCustomerWonByMonth = (
  numberOfMonth?: number,
  headcount?: HeadCount[],
  mode?: 'RM' | 'Agent'
) => DashboardResponse & GQLQueryRes

export const CUSTOMERS_WON_QL = gql`
  query accountsc($filter: JSON, $aggregate: [JSON], $groupBy: [String], $useCache: Boolean) {
    accountsc(filter: $filter, aggregate: $aggregate, groupBy: $groupBy, useCache: $useCache) {
      edges {
        edge {
          account_uid
          won_by_user_uid
          customer_since_date
        }
      }
    }
  }
`

export const useGetNewCustomerWonByMonth: UseGetNewCustomerWonByMonth = (numberOfMonth = 3, headcounts, mode) => {
  const analyticsContext = getWidgetAnalyticContext()
  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const variables = {
    filter: {
      organization_unit,
      won_by_user_uid: headcounts ? undefined : userUid,
      customer_since_date: {
        $gte: startDate,
        $lte: endDate
      },
      activity: {
        seq_same_type_success: 1
      }
    },
    groupBy: ['won_by_user_uid', 'customer_since_date'],
    aggregate: [{ id: 'account_uid', type: 'count' }],
    useCache: true
  }

  const { refetch, data, loading } = useQuery(CUSTOMERS_WON_QL, {
    variables,
    fetchPolicy: 'no-cache'
  })
  let listAccountSc = data?.accountsc?.edges?.map(e => e.edge) ?? []
  const statistics = groupDataByMonth(listAccountSc, 'customer_since_date', 'account_uid')

  return {
    refetch,
    loading,
    ...createDashboardFormats(
      statistics,
      numberOfMonth,
      moment(endDate, DATE_FORMAT).format('YYYY-MM'),
      headcounts,
      mode
    )
  }
}

const widgetCustomersWonHoc = (Component: any) => {
  const Wrapper = props => {
    const { headcounts, mode } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      avgPassedMonths,
      avgPerPersonPassedMonth,
      dataAvgPerPersonPassedMonth,
      dataPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      totalLatestMonth,
      mainCaption
    } = useGetNewCustomerWonByMonth(3, headcounts, mode)

    const onClickDetail = (monthSelected = null) => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)
      const filters = {
        won_by_user_uid: headcounts ? undefined : userUid,
        organization_unit,
        customer_since_date: convertMonthToDateRange(endDate),
        activity: {
          seq_same_type_success: 1
        }
      }

      if (monthSelected) {
        filters.customer_since_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'account', filters })
    }

    return (
      <Component
        avgWonCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgWonPassedMonthData={dataAvgPerPersonPassedMonth}
        avgWonPersonPassedMonth={avgPerPersonPassedMonth}
        avgWonThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        refetchData={() => refetch()}
        threeMonthAgoWon={dataPassedMonths}
        totalWon={totalLatestMonth}
        onClickDetail={onClickDetail}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetCustomersWonHoc
