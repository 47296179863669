import React from 'react'
import { AnalyticsContextType } from 'crm-core/analytics/context'
import { WidgetFitmentByOwner } from './widget-fitment-by-owner'
import { WidgetFitmentByLeadInDate } from './widget-fitment-by-lead-in-date'
import { WidgetFitmentByLeadSourceName } from './widget-fitment-by-lead-source'
interface IFitmentGeneralStaticDashboard {
  analyticsContext: AnalyticsContextType
}
const FitmentGeneralStaticDashboard: React.FC<IFitmentGeneralStaticDashboard> = ({ analyticsContext }) => {
  const haveActivityDate = analyticsContext?.filters?.activity_date
  return (
    <React.Fragment>
      {!haveActivityDate && 'Please select activity date'}
      {haveActivityDate &&
        <div className='row'>
          <div className='col-md-6'>
            <WidgetFitmentByOwner analyticsContext={analyticsContext} />
          </div>
          <div className='col-md-6'>
            <WidgetFitmentByLeadInDate analyticsContext={analyticsContext} />
          </div>
          <div className='col-md-6'>
            <WidgetFitmentByLeadSourceName analyticsContext={analyticsContext} />
          </div>
        </div>}
    </React.Fragment>)
}

export default FitmentGeneralStaticDashboard