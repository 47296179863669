import React, { useState } from 'react'
import { CrmButton, CrmToastrType, useCrmToastr } from '@cartrack-crm/ui'
import { useQuery } from '@apollo/react-hooks'
import { qlqProcessType } from 'crm-data/processes'
import { useGenericCommandQL } from 'crm-components/ql/useGenericCommandQL'

type ManualWonButtonType = {
  process: any
}

export const ManualWonButton = ({ process }: ManualWonButtonType) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showToastr] = useCrmToastr()
  const { isCanManualWon, onRunCommandManualWon } = useManualWonAction(process)
  const onActionManualWon = async () => {
    setIsLoading(true)
    const error = await onRunCommandManualWon(process?.account_uid)
    if (error) {
      setIsLoading(false)
      showToastr(CrmToastrType.error, 'Error: Please contact crm-support with crm link', {
        timeToShow: 4000,
        message: error.message
      })
    } else {
      setIsLoading(false)
      showToastr(CrmToastrType.success, 'Account already won')
      setTimeout(() => window.location.reload(), 3000)
    }
  }
  return (
    isCanManualWon && (
      <CrmButton
        title="Win"
        label={<b>Win</b>}
        type="success"
        style={{ backgroundColor: '#2DAB33', color: 'white' }}
        onClick={onActionManualWon}
        isSaving={isLoading}
      />
    )
  )
}

type onRunManualWonType = (account_uid: string) => any | undefined | void
type useManualWonActionType = (process: any) => { isCanManualWon: boolean; onRunCommandManualWon: onRunManualWonType }

const useCheckCanManualWon = process => {
  const { data } = useQuery(qlqProcessType, {
    variables: {
      process_type_uid: process?.process_type_uid
    },
    fetchPolicy: 'no-cache'
  })
  const steps = data?.process_type?.steps
    ?.sort((current, next) => current.step - next.step)
    ?.filter(el => el.is_active === true)
  const currentStepUid = process?.current_step?.process_step_type?.process_step_type_uid
  const manualWonIndex = steps?.findIndex(el => el.code === 'manual_won') ?? -1
  let beforeManualWonStep
  if (~manualWonIndex) {
    beforeManualWonStep = steps[manualWonIndex - 1]?.process_step_type_uid
  }
  const isCanManualWon =
    !!data?.process_type?.steps?.find(el => el.code === 'manual_won') &&
    !!currentStepUid &&
    !!beforeManualWonStep &&
    beforeManualWonStep === currentStepUid
  return isCanManualWon
}

export const useManualWonAction: useManualWonActionType = process => {
  const { onRunCommand } = useGenericCommandQL({})
  const isCanManualWon = useCheckCanManualWon(process)
  const onRunCommandManualWon = async (account_uid: string) => {
    const command = {
      type: 'salesAccount.manualWin',
      aggregate_uid: account_uid,
      aggregate_type: 'salesAccount',
      payload: {}
    }
    try {
      const res = await onRunCommand(command, {})
      if (res.data.cqCommand.status === 'OK') {
        await onRunCommand(
          {
            type: 'accountProjection.rebuild',
            aggregate_uid: account_uid,
            aggregate_type: 'accountProjection'
          },
          { forceNewCommandUid: true }
        )
      }
    } catch (error) {
      return error
    }
  }
  return { isCanManualWon, onRunCommandManualWon }
}
