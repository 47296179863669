import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import crmGenericSelectQLHocFactory from 'crm-components/ql/crm-generic-select-ql-hoc.jsx'

const qlqQuery = gql`
  {
    accounts_databases_list_qm_paged(limit: 1000) {
      data {
        database_uid
        name
      }
    }
  }
`

const mapToOptions = c => ({
  value: c.database_uid,
  name: c.name
})

const query = graphql(qlqQuery, {
  options: ownProps => ({
    variables: { type: ownProps.type }
  }),
  props: ({ qlqQuery, ownProps }) => ({
    ...ownProps,
    qlqQuery,
    optionsQl: qlqQuery,
    options:
      qlqQuery &&
      qlqQuery.accounts_databases_list_qm_paged &&
      qlqQuery.accounts_databases_list_qm_paged.data
        ? qlqQuery.accounts_databases_list_qm_paged.data.map(mapToOptions)
        : undefined,
    isLoading: qlqQuery ? qlqQuery.loading : false
  }),
  name: 'qlqQuery'
})

const CampaignSelect = crmGenericSelectQLHocFactory(query)

CampaignSelect.propTypes = {}

export default CampaignSelect
