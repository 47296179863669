/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { shape, arrayOf, node, bool, func, string, number } from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import filesize from 'filesize'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { getApiRootUrl } from 'crm-api/api-utils'
import { CrmFormSection, CrmButton, CrmTextInput } from 'crm-components'
import { createResourceItem } from 'crm-duxs/admin/resources-reducer'
import listAccountResourceHoc from 'crm-modules/documents/hoc/list-account-resources-hoc'
import { LoadingIcon } from 'util-components'

class SectionAttachments extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isAdding: false, isUploading: false, form: {} }
    this.prefixLink = getApiRootUrl() + 'crm_resources.php?file='
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.created !== this.props.created) {
      toastr.success('Uploaded !')
      this.refetch()
      this.setState({ isUploading: false })
    }
  }

  handleOnAddAttachment = () => {
    this.setState({ isAdding: true, form: {} })
  }
  handleCancelAdding = () => {
    this.setState({ isAdding: false })
  }

  handleChangeFileName = e => {
    this.updateAttachmentForm('fileName', e.currentTarget.value)
  }

  handleChangeFileDescription = e => {
    this.updateAttachmentForm('description', e.currentTarget.value)
  }

  handleDrop = files => {
    this.updateAttachmentForm('file', files[0])
    if (!this.state.form.fileName || this.state.form.fileName === '') {
      this.updateAttachmentForm(
        'fileName',
        files[0].name.replace(/\.[^/.]+$/, '')
      )
    }
  }

  updateAttachmentForm = (field, value) => {
    const formData = this.state.form
    formData[field] = value
    this.setState({ form: { ...formData } })
  }

  handleSave = async () => {
    const formValues = this.state.form
    if (!formValues.file) return
    formValues.account_uid = this.props.accountUid
    formValues.access_mode = 'public'
    this.setState({ isAdding: false, isUploading: true })
    this.props.doSaveAttachment(formValues)
  }

  refetch = () => {
    this.props.resourcesQL.refetch({})
  }

  _getActions = () => [
    {
      label:
        '+ ' +
        this.props.intl.formatMessage({
          id: 'crm.ui.account.attachment',
          defaultMessage: 'Attachment'
        }),
      onClick: this.handleOnAddAttachment
    }
  ]

  showFileDropped() {
    let text = (
      <FormattedMessage
        id="crm.ui.account.drop_files"
        defaultMessage="drop files"
      />
    )

    if (this.state.form.file) {
      text = 'size :' + filesize(this.state.form.file.size)
    }

    return <center>{text}</center>
  }

  render() {
    const { sectionName, resources } = this.props
    console.log('render section attachments')
    return (
      <CrmFormSection
        sectionName={sectionName}
        actions={this._getActions()}
        hasPadding
      >
        {!this.state.isAdding && !this.state.isUploading && (
          <React.Fragment>
            {resources &&
              resources.map(resource => (
                <div key={resource.resource_uid} className="row">
                  <div className="col-md-8 LabeledField-label">
                    {resource.name} ({filesize(resource.size)})
                  </div>
                  <div
                    className="col-md-4 LabeledField-label"
                    style={{ textAlign: 'right' }}
                  >
                    {resource && resource.file_link && (
                      <a
                        href={this.prefixLink + resource.file_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ActivityStatusWidget">
                          <FormattedMessage
                            id="crm.ui.account.view"
                            defaultMessage="View"
                          />
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              ))}
          </React.Fragment>
        )}
        {this.state.isAdding && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-4">
                <CrmTextInput
                  placeholder={
                    <FormattedMessage
                      id="crm.ui.account.filename"
                      defaultMessage="Filename"
                    />
                  }
                  input={{
                    value: this.state.form.fileName,
                    onChange: this.handleChangeFileName
                  }}
                />
              </div>
              <div className="col-md-4">
                <CrmTextInput
                  placeholder={
                    <FormattedMessage
                      id="crm.ui.account.description"
                      defaultMessage="description"
                    />
                  }
                  input={{
                    value: this.state.form.description,
                    onChange: this.handleChangeFileDescription
                  }}
                />
              </div>
              <div className="col-md-4">
                <Dropzone
                  onDrop={this.handleDrop}
                  multiple={false}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderWidth: '2px',
                    borderColor: 'rgb(102, 102, 102)',
                    borderStyle: 'dashed',
                    borderRadius: '5px'
                  }}
                >
                  <span>{this.showFileDropped()}</span>
                </Dropzone>
              </div>
            </div>
            <CrmButton
              xsmall
              onClick={this.handleSave}
              label={
                <FormattedMessage
                  id="crm.ui.button.save"
                  defaultMessage="Save"
                />
              }
              type="primary"
            />
            <CrmButton
              xsmall
              onClick={this.handleCancelAdding}
              label={
                <FormattedMessage
                  id="crm.ui.button.cancel"
                  defaultMessage="Cancel"
                />
              }
            />
          </React.Fragment>
        )}
        {this.state.isUploading && <LoadingIcon />}
        {this.props.isLoadingResources && <LoadingIcon />}
      </CrmFormSection>
    )
  }
}

SectionAttachments.defaultProps = {
  sectionName: (
    <FormattedMessage
      id="crm.ui.account.attachments"
      defaultMessage="Attachments"
    />
  ),
  document: undefined,
  resources: undefined
}

SectionAttachments.propTypes = {
  sectionName: node,
  resources: arrayOf(shape({})),
  isLoadingResources: bool.isRequired,
  doSaveAttachment: func.isRequired,
  accountUid: string.isRequired,
  resourcesQL: shape({}).isRequired,
  created: number.isRequired,
  intl: shape({}).isRequired
}

function mapStateToProps(state) {
  const resourceState = state.crm.adminResources
  return {
    created: resourceState.uploadedAttachmentForAccount
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doSaveAttachment: formValues => dispatch(createResourceItem(formValues))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(listAccountResourceHoc(SectionAttachments)))
