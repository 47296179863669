import React from 'react'
import { shape, bool, func, number } from 'prop-types'
import { jsonSyntaxHighlight } from 'crm-utils/common-utils'
import { CrmTextArea } from 'crm-components'

class CrmJsonEditArea extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ...this._mapToState(props),
      isValid: true,
      isDirty: false
    }
  }
  _mapToState = props => {
    return {
      jsonString:
        props.input && props.input.value
          ? JSON.stringify(props.input.value, null, 2)
          : null
    }
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.input !== this.props.input) {
      this.setState({
        jsonString:
          nextProps.input && nextProps.input.value
            ? JSON.stringify(nextProps.input.value, null, 2)
            : null
      })
    }
  }
  handleChange = event => {
    console.log('handleChange', event)
    try {
      this.setState({
        jsonString: event.currentTarget.value
      })
      const jsonObj = JSON.parse(event.currentTarget.value)
      if (jsonObj) {
        this.setState({
          jsonString: event.currentTarget.value,
          json: jsonObj,
          isValid: true
        })
        if (this.props.input && this.props.input.onChange) {
          this.props.input.onChange(jsonObj)
        }
      }
    } catch (err) {
      this.setState({ isValid: false })
    }
  }
  render() {
    const { editable, json, placeholder } = this.props
    return (
      <div style={{ position: 'relative' }}>
        <CrmTextArea
          input={{
            value: this.state.jsonString,
            onChange: this.handleChange
          }}
          rows={this.props.rows}
          placeholder={placeholder || 'Edit json string'}
        />
        <label style={{ position: 'absolute', right: 10, top: 10 }}>
          Valid: {this.state.isValid ? 'YES' : 'NO'}
        </label>
      </div>
    )
  }
}
CrmJsonEditArea.propTypes = {
  json: shape({}).isRequired,
  editable: bool,
  onChange: func,
  rows: number
}
CrmJsonEditArea.defaultProps = {
  onChange: () => {},
  editable: false,
  rows: 10
}
export default CrmJsonEditArea
