import { HeadCount, DashboardResponse } from 'crm-types'
import moment from 'moment'
import { useAnalyticsContext } from 'crm-core/analytics/context'
import { useCrmFilterContext } from '../dashboard-context/crm-filter-context'
type CreateDashboardFormats = (dataset: any, numberOfMonth?: number, latestMonth?: string, headcounts?: HeadCount[], personUnit?: 'RM' | 'Agent') => DashboardResponse
export const createDashboardFormats: CreateDashboardFormats = (dataset, numberOfMonth = 3, latestMonth = moment().format('YYYY-MM'), headcounts, personUnit = 'RM') => {
  let avgPassedMonths = 0
  let dataPassedMonths = []
  let mainCaption = `Month to date of ${moment(latestMonth, 'YYYY-MM').format('MMM')}`
  let footerName = headcounts ? 'Total' : undefined
  let footerNamePerPerson = headcounts ? `Avg / ${personUnit}` : undefined
  let totalLatestMonth = 0
  let totalPassedMonths = 0
  let totalAvgPassedMonth = headcounts ? 0 : undefined
  let avgPerPerson = headcounts ? 0 : undefined
  let avgPerPersonPassedMonth = headcounts ? 0 : undefined
  let dataAvgPerPersonPassedMonth = headcounts ? [] : undefined
  let avgCurrentMonthPerPerson = headcounts ? 0 : undefined
  let sumAvgPerPersonPassMonth = headcounts ? 0 : undefined
  let sumHeadCount = headcounts
    ? headcounts.reduce((acc, cur) => acc + cur.value, 0)
    : undefined
  for (let i = 0; i <= numberOfMonth; i++) {
    const dateObject = moment(latestMonth, 'YYYY-MM').subtract(i, 'months')
    const key = dateObject.format('YYYY-MM')
    if (key !== latestMonth) {
      dataPassedMonths[key] = 0
      if (headcounts) dataAvgPerPersonPassedMonth[key] = 0
    }
  }
  Object.keys(dataset).forEach(key => {
    const counter = dataset?.[key] ?? 0
    const isLatestMonth = key === latestMonth
    if (isLatestMonth) {
      totalLatestMonth = isLatestMonth ? counter : 0
      const pHeadcountCurrentMonth = headcounts?.find(el => el.month === key)
      const headcountCurrentMonth = pHeadcountCurrentMonth?.value ?? 0
      avgCurrentMonthPerPerson = headcountCurrentMonth === 0 ? Math.round(totalLatestMonth) : Math.round(totalLatestMonth / headcountCurrentMonth)
    } else {
      totalPassedMonths += !isLatestMonth ? counter : 0
      dataPassedMonths[key] = !isLatestMonth ? counter : 0
      if (headcounts) {
        const pHeadcounts = headcounts.find(el => el.month === key)
        const headcountsValue = pHeadcounts?.value ?? 0
        avgPerPerson = headcountsValue === 0 ? Math.round(counter) : Math.round(counter / headcountsValue)
        dataAvgPerPersonPassedMonth[key] = avgPerPerson
        sumAvgPerPersonPassMonth += avgPerPerson
      }
    }
  })
  avgPassedMonths = Math.round(totalPassedMonths / numberOfMonth)
  avgPerPersonPassedMonth = Math.round(sumAvgPerPersonPassMonth / numberOfMonth)
  totalAvgPassedMonth = Math.round(totalPassedMonths / numberOfMonth)
  return {
    avgPassedMonths,
    dataPassedMonths,
    latestMonth,
    mainCaption,
    totalLatestMonth,
    totalPassedMonths,
    totalAvgPassedMonth,
    avgPerPerson,
    avgPerPersonPassedMonth,
    dataAvgPerPersonPassedMonth,
    avgCurrentMonthPerPerson,
    sumHeadCount,
    footerName,
    footerNamePerPerson
  }
}

export const getWidgetAnalyticContext = () => {
  const contextFilter = useCrmFilterContext()?.filters ?? {}
  const analyticContextFilter = useAnalyticsContext()?.filters ?? {}
  return { filters: { ...contextFilter, ...analyticContextFilter } }
}