/* eslint-disable camelcase */
import React from 'react'
import { shape, func } from 'prop-types'
import { withApollo } from 'react-apollo'
import withLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'
import WidgetAgentsDispositions from './agents/widget-agents-dispositions-hm.jsx'
// import WidgetAgentsDispositionsSummary from './agents/widget-agents-dispositions-summary.jsx'

// Cconst columns = [
//   {
//     Header: 'Agent',
//     accessor: 'user_name'
//   },
//   {
//     Header: 'All Calls',
//     accessor: 'activity_count'
//   },
//   {
//     Header: 'Success',
//     accessor: 'success_calls'
//   },
//   {
//     Header: 'Call Time',
//     accessor: 'call_length',
//     Cell: row => {
//       const val = row.original.call_length
//         ? moment('2015-01-01')
//             .startOf('day')
//             .seconds(row.original.call_length)
//             .format('H:mm:ss')
//         : 0

//       return <span>{val}</span>
//     }
//   },
//   {
//     Header: 'Disposition',
//     accessor: 'call_disposition_name'
//   },
//   {
//     Header: 'Activity Status',
//     accessor: 'activity_status_name'
//   }
// ]

class DashTelesalesAgents extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== undefined &&
      nextProps.analyticsContext !== this.props.analyticsContext
    ) {
      // console.log(
      //   'DashTelesalesAgents propsChanged',
      //   this.props.analyticsContext,
      //   nextProps.analyticsContext
      // )
      // T this.setState({ ...this.state }, () => {
      //   this.handleRefresh()
      // })
    }
  }

  handleClickAgent = val => {
    // console.log('handleClickAgent', val)
  }

  handleAnalyticsRangeSelected = params => {
    if(!params.filters.activity_type) {
      // make popup call same filter with telesales_agents_dispositions report
      // src/crm/modules/dashboard/dashboards/telesales/agents/widget-agents-dispositions-hm.jsx:81
      params.filters.activity_type = {
        medium_code: 'phone_call'
      }
    }
    this.props.onShowLeadsPopup(params)
  }

  handleDateRangeFilterChange = value => {
    // console.log('handleDateRangeFilterChange')
    // console.dir(value)
    this.setState({
      activityDate:
        value && value.startDate ? [value.startDate, value.endDate] : undefined
    })
  }
  render() {
    console.log('render dash telesales agents')
    return (
      <div>
        <WidgetAgentsDispositions
          analyticsContext={this.props.analyticsContext}
          onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
        />
        {/* <WidgetAgentsDispositionsSummary
          analyticsContext={this.props.analyticsContext}
          onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
        /> */}
      </div>
    )
  }
}

DashTelesalesAgents.propTypes = {
  analyticsContext: shape({}).isRequired,
  onShowLeadsPopup: func.isRequired
}

DashTelesalesAgents.defaultProps = {
  dashboards: []
}

export default withApollo(withLeadsPopupHoc(DashTelesalesAgents))
