import React from 'react'
import { connect } from 'react-redux'
import { func, bool, string } from 'prop-types'
import { push } from 'connected-react-router'
import editAccountHoc from 'crm-modules/accounts/hoc/edit-account-hoc'
import { CrmCampaignSelect } from 'crm-components'

class ChangeLeadCampaignWidget extends React.PureComponent {
  handleLeadCampaignChange = async (value, item) => {
    if (this.props.onLeadCampaignChange) {
      this.props.onLeadCampaignChange(value, item)
    }
  }
  componentDidMount() {
    if (this.props.onBulkComponentInit) {
      this.props.onBulkComponentInit(this)
    }
  }
  render() {
    return (
      <div>
        <CrmCampaignSelect
          type="lead_source"
          placeholder="Lead Campaign"
          addEmptyOption
          emptyOptionLabel="All"
          style={{ width: 180 }}
          input={{ onChange: this.handleLeadCampaignChange }}
        />
      </div>
    )
  }
}

ChangeLeadCampaignWidget.propTypes = {
  onBulkComponentInit: func,
  bulkMode: bool,
  bulkType: string.isRequired,
  onLeadCampaignChange: func
}

ChangeLeadCampaignWidget.defaultProps = {
  onBulkComponentInit: undefined,
  bulkMode: false,
  onLeadCampaignChange: undefined
}

export default editAccountHoc(
  connect(null, dispatch => ({
    doPush: url => dispatch(push(url))
  }))(ChangeLeadCampaignWidget)
)
