/* eslint-disable camelcase */
import React from 'react'
import AccountsList from './accounts-list.jsx'

const AccountsRawView = () => (
  <AccountsList
    fixFilter={[
      {
        code: 'account_info',
        code_option: 'equal',
        params: [{ condition_key: 'kind', condition_value: 'lead' }]
      }
    ]}
    title="Raw, never contacted contacts. Tak one and close the deal!"
    accountsListCode="all_leads"
  />
)

export default AccountsRawView
