/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom'
import { shape, func, string } from 'prop-types'

import { FormattedDate, FormattedTime } from 'react-intl'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import CrmWidget from '../../../components/crm-widget.jsx'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'

const columns = [
  {
    Header: 'Account NAme',
    Cell: row => (
      <Link to={'/crm/account/' + row.original.account_uid} target="blank">
        {row.original.account_name}
      </Link>
    ),
    accessor: 'account_name'
  },
  {
    Header: 'Date Won',
    accessor: 'process_completed_date',
    Cell: row => (
      <span>
        <FormattedDate value={row.original.process_completed_date} />{' '}
        <FormattedTime value={row.original.process_completed_date} />
      </span>
    )
  },
  {
    Header: 'Result',
    accessor: 'current_step_name'
  },
  {
    Header: 'Owner',
    accessor: 'owner_user_name'
  },
  {
    Header: 'Lead Source',
    accessor: 'lead_source_name'
  },
  {
    Header: 'Lead In Date',
    accessor: 'lead_in_date',
    Cell: row => (
      <span>
        <FormattedDate value={row.original.lead_in_date} />{' '}
        <FormattedTime value={row.original.lead_in_date} />
      </span>
    )
  }
]

const qlOptions = {
  options: ownProps => {
    console.log('qlOtions, ', ownProps)
    const filter = { ...ownProps.filter, ...mapContextToFilter(ownProps) }
    filter.is_final_step = 'true'
    filter.process_step_kind = 'won'

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl
      ? tableDataQl.dialer_campaign_accounts_list_qm_paged
      : []
  }),
  fetchPolicy: 'no-cache'
}

const TelesalesWinsTable = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions
})

class WidgetTelesalesSales extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ ...this.state, isLoading: val }, handler)
  }

  renderSummary = () => (
    <div>
      {this.state.totalDispositions && (
        <span>Total Dispositions: {this.state.totalDispositions}</span>
      )}
    </div>
  )

  handleCurrentStepClick = event => {
    console.log('handleCurrentStepClick', event)
    this.setState({ selectedStepTypeUid: event.currentTarget.id })
    this.props.onCurrentStepClick({
      process_step_type_uid: event.currentTarget.id
    })
  }

  render() {
    console.log('Render widget new leads by current status', this.state)
    return (
      <CrmWidget
        title={this.props.title ? this.props.title : 'Telesales Wins'}
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        fixHeight
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-wins"
      >
        <TelesalesWinsTable
          columns={columns}
          style={{ width: '100%' }}
          analyticsContext={this.props.analyticsContext}
        />
      </CrmWidget>
    )
  }
}

WidgetTelesalesSales.propTypes = {
  analyticsContext: shape({}).isRequired,
  onCurrentStepClick: func.isRequired,
  title: string
}
WidgetTelesalesSales.defaultProps = {
  title: undefined
}

export default WidgetTelesalesSales
