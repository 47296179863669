import gql from 'graphql-tag'
import React, { PureComponent } from 'react'
import { Query } from 'react-apollo'
import { CrmTextInput } from 'crm-components'
import { getCrmInstanceUid } from 'crm-api/api-utils'
import { withCrmContext } from 'crm-core/contexts/crm-context'

export const listUsersQuery = gql`
  query users(
    $master_uid: String!
    $instance_uid: String!
    $filter: JSON
    $limit: Int
  ) {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        users(filter: $filter, limit: $limit) {
          user_uid
          full_name
          is_active
          headshot_url
          photo_url
          email
        }
      }
    }
  }
`

class CrmUserSelector extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      users: [...props.users],
      query: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.users !== this.props.users) {
      this.setState({
        users: [...nextProps.users]
      })
    }
  }

  handleSearchChange = event => {
    this.setState({
      query: event.currentTarget.value
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
    }
  }

  handleUserSelect = event => {
    const selectElement = event.target
    const user = this.props.users.filter(
      v => v.user_uid === selectElement.value
    )
    this.props.handleUserSelect(user)
    this.setState({ query: null })
  }

  render() {
    const { isLoading } = this.props
    let { users, query } = this.state
    users =
      users && query
        ? users.filter(v =>
            v.full_name.toLowerCase().includes(query.toLowerCase())
          )
        : users
    return (
      <div
        style={{ display: 'grid', gridGap: '5px', gridTemplateColumns: '100%' }}
      >
        {this.props.enableSearch && (
          <div>
            <CrmTextInput
              placeholder="Search users"
              iconName="search"
              input={{
                onChange: this.handleSearchChange,
                value: this.state.query
              }}
              onKeyPress={this.handleKeyPress}
              onIconClick={this.handleSearchProcess}
              withBackground
            />
          </div>
        )}
        <div>
          <select
            value={this.state.value}
            size="15"
            style={{
              width: '100%',
              borderColor: '#b5b5b5',
              padding: '5px'
            }}
            onChange={this.handleUserSelect}
          >
            {users.map(v => (
              <option key={v.user_uid} value={v.user_uid}>
                {v.full_name}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
}

const CrmUserSelectorQL = props => {
  const variables = {
    instance_uid: getCrmInstanceUid(),
    master_uid: props?.crmContext?.master?.master_uid,
    limit: 1000,
    offset: 0,
    filter: {
      is_active: { $ne: false },
      is_deleted: { $ne: true }
    }
  }

  return (
    <Query query={listUsersQuery} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const users = data?.master?.instance?.users ?? []
        const sortUsers = [...users].sort((a, b) =>
          a.full_name ? a.full_name.localeCompare(b.full_name) : 0
        )
        return (
          <CrmUserSelector
            {...props}
            loading={loading}
            error={error}
            refetch={refetch}
            users={sortUsers}
          />
        )
      }}
    </Query>
  )
}

export default withCrmContext(CrmUserSelectorQL)
