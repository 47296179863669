/* eslint-disable camelcase */
import React from 'react'
import { bool, func, shape } from 'prop-types'
import { Link } from 'react-router-dom'

import { FormattedDate } from 'react-intl'
import { mapContextToFilter } from '../dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { CrmModal } from 'crm-components'
import genericTableFactory from 'crm-components/ql/table/crm-generic-dynamic-table.tsx'

const defaultQueryFields = {
  selections: [
    { name: 'order_uid' },
    { name: 'completed_date' },
    { name: 'completed_date_month' },
    { name: 'cams_username' },
    { name: 'account_uid' },
    {
      name: 'owner',
      selections: [{ name: 'name' }, { name: 'uid' }]
    },
    {
      name: 'account',
      selections: [
        { name: 'name' },
        { name: 'account_uid' },
        {
          name: 'lead_campaign',
          selections: [
            { name: 'name' },
            { name: 'campaign_uid' }
          ]
        }
      ]
    }
  ]
}

const columns = [
  {
    Header: 'Account Name',
    Cell: row => (
      <Link
        to={'/crm/account/' + row.original?.account_uid + '/sales'}
        target="blank"
      >
        {row.original.account?.name}
      </Link>
    ),
    accessor: 'account.name'
  },
  {
    Header: 'First Fitment Date',
    accessor: 'completed_date',
    Cell: row =>
      row.original.completed_date ? (
        <span>
          <FormattedDate value={row.original.completed_date} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Lead Campaign',
    Cell: row => row.original?.account?.lead_campaign?.name ?? <span />
  },
  {
    Header: 'Fitment Owner',
    Cell: row => row.original?.owner?.name ?? <span />
  }
]
const qlOptions = {
  options: ownProps => {
    const filter = { ...ownProps.filters }

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions,
  rootQLType: 'FirstFitmentsList',
  useEdges: true,
  queryRoot: 'first_fitmentsc',
  defaultQueryFields
})

class FirstFitmentsPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Wins - First Fitments Details"
        onClose={this.props.onClose}
        size="xl"
      >
        <Table
          columns={columns}
          style={{ width: '100%' }}
          filters={this.props.filters}
        />
      </CrmModal>
    )
  }
}

FirstFitmentsPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: shape({})
}
FirstFitmentsPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

export default FirstFitmentsPopup
