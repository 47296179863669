import React from 'react'
import { string, node, arrayOf, shape } from 'prop-types'
import { Breadcrumbs } from 'util-components'

const AdminHeader = props => {
  const { breadcrumbs, className } = props
  const breadcrumbStyle = breadcrumbs.length ? 'is-breadcrumbed' : ''
  return (
    <div className={`AdminHeader  ${breadcrumbStyle}`}>
      <div className={`AdminHeader-container ${breadcrumbStyle}`}>
        {breadcrumbs.length ? (
          <Breadcrumbs
            data={breadcrumbs}
            className="col-xs-10 AdminHeader-breadcrumbs"
          />
        ) : null}
        {props.children && (
          <div
            className={`AdminHeader-row row middle-xs between-xs ${className}`}
          >
            {props.children}
          </div>
        )}
      </div>
    </div>
  )
}

AdminHeader.propTypes = {
  className: string,
  children: node.isRequired,
  breadcrumbs: arrayOf(shape({ label: string, path: string }))
}

AdminHeader.defaultProps = {
  className: '',
  breadcrumbs: []
}

export default AdminHeader
