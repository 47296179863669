/* eslint-disable */
import React, { Component } from 'react'
import { shape, arrayOf, string, func } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import importQLHoc from '../crm-import-hoc'
import ImportMappingConfigForm from './import-mapping-config-form'
import {
  CrmTable,
  CrmDropdown,
  CrmButton,
  CrmCampaignSelect,
  CrmTextInput,
  CrmCheckbox
} from 'crm-components'
import ImportResult from './import-result.jsx'
import ImportHistory from './import-history.jsx'
import { Query } from 'react-apollo'
import { qlGetResource } from 'crm-data/resources'
import ImportDefaultValueForm from './import-default-value-form.jsx'

class ImportMappingForm extends Component {
  constructor(props) {
    super(props)
    this.mapped = false
    this.valuesMapping = {}
    this.state = {
      dataRows: this.props.data ? this.props.data.slice() : [],
      errors: [],
      showImportResult: false,
      showImportDefaultValueForm: false,
      showImportHistory: false,
      importResult: {},
      importing: false,
      saving: false,
      configData: undefined,
      importName: '',
      selectedTemplate: undefined,
      type: 'account',
      mapState: ['All', 'Mapped', 'Unmapped'],
      selectedMapState: 'All',
      showConfigForm: false,
      configFormVariables: undefined,
      campaignUid: undefined,
      detectDuplicateFields: [],
      defaultValueFields: {},
      mappingFields: {
        account: [
          {
            key: 'company_account',
            name: 'Company name',
            mapping: undefined,
            require: true,
            canDetectDuplicate: true
          },
          {
            key: 'campaign_name',
            name: 'Campaign name',
            mapping: undefined
          },
          {
            key: 'note',
            name: 'Remark',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'website',
            name: 'Website',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'registration_number',
            name: 'Registration number',
            mapping: undefined,
            canDetectDuplicate: true
          },
          {
            key: 'mobile',
            name: 'Mobile',
            mapping: undefined,
            canDetectDuplicate: true
          },
          {
            key: 'email',
            name: 'Email',
            mapping: undefined,
            canDetectDuplicate: true
          },
          { key: 'meta', name: 'Meta', mapping: undefined, multiple: true },
          {
            key: 'tel',
            name: 'Phone number',
            mapping: undefined,
            canDetectDuplicate: true
          },
          {
            key: 'industry',
            name: 'Industry',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'owner_name',
            name: 'Owner Name',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'cams_username',
            name: 'Username',
            mapping: undefined,
            canSetDefault: false
          },
          {
            key: 'contract_start_date',
            name: 'Contract Start Date',
            mapping: undefined,
            canSetDefault: false
          },
          {
            key: 'owner_uid',
            name: 'Owner Uid',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'contact_person.name',
            name: 'Contact person name',
            mapping: undefined
          },
          {
            key: 'contact_person.position',
            name: 'Contact person position',
            mapping: undefined
          },
          {
            key: 'contact_person.department',
            name: 'Contact person department',
            mapping: undefined
          },
          {
            key: 'contact_person.email',
            name: 'Contact person email',
            mapping: undefined
          },
          {
            key: 'contact_person.mobile',
            name: 'Contact person mobile',
            mapping: undefined
          },
          {
            key: 'contact_person.phone',
            name: 'Contact person phone',
            mapping: undefined
          },
          {
            key: 'address',
            name: 'Address',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'street',
            name: 'Street',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'city',
            name: 'City',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'district',
            name: 'District',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'sub_district',
            name: 'Town',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'province',
            name: 'Province',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'postal_code',
            name: 'Postcode',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'country_code',
            name: 'Country',
            mapping: undefined,
            canSetDefault: true
          },
          { key: 'lead_in_date', name: 'Lead In Date', canSetDefault: true },
          { key: 'imported_id', name: 'Imported id' },
          {
            key: 'campaign_external_id',
            name: 'Campaign External Id',
            mapping: undefined
          },
          { key: 'campaign_uid', name: 'Campaign Uid', mapping: undefined },
          { key: 'industry', name: 'Industry', mapping: undefined },
          // Market Potential Fields
          {
            key: 'marketpotential.truck',
            name: 'Market Potential - Truck',
            mapping: undefined
          },
          {
            key: 'marketpotential.smalltruck',
            name: 'Market Potential - Small truck',
            mapping: undefined
          },
          {
            key: 'marketpotential.motorcycle',
            name: 'Market Potential - Motorcycle',
            mapping: undefined
          },
          {
            key: 'marketpotential.bus',
            name: 'Market Potential - Bus',
            mapping: undefined
          },
          {
            key: 'marketpotential.van',
            name: 'Market Potential - Van',
            mapping: undefined
          },
          {
            key: 'marketpotential.car',
            name: 'Market Potential - Car',
            mapping: undefined
          },
          {
            key: 'marketpotential.trailer',
            name: 'Market Potential - Trailer',
            mapping: undefined
          },
          {
            key: 'marketpotential.engine',
            name: 'Market Potential - Engine',
            mapping: undefined
          },
          {
            key: 'marketpotential.asset',
            name: 'Market Potential - Asset',
            mapping: undefined
          }
        ],
        fitment: [
          {
            key: 'account_uid',
            name: 'Account UID',
            mapping: undefined
          },
          {
            key: 'vehicles_count',
            name: 'Remark',
            mapping: undefined,
            require: true,
            canSetDefault: true
          },
          {
            key: 'description',
            name: 'Description',
            mapping: undefined,
            canSetDefault: true
          },
          {
            key: 'fitment_date',
            name: 'Date',
            mapping: undefined,
            require: true,
            canSetDefault: true
          },
          {
            key: 'cams_username',
            name: 'Username',
            mapping: undefined,
            require: true,
            canSetDefault: true
          }
        ]
      }
    }
  }

  handleClickTab = event => {
    this.setState({ selectedMapState: event.target.id })
  }

  componentDidMount() {
    this.props.qlImportConfig.refetch()
    this.props.qlImportHistoryLog.refetch()
    this.autoMappingField()
  }

  handleTemplateSelected = value => {
    const config = this.props.qlImportConfig.import_configs.filter(
      obj => obj.template_uid === value
    )
    // console.log('config', config)
    if (config.length > 0) {
      const detectDuplicateFields = []
      const dataMapping = JSON.parse(config[0].data_mapping)
      const mappingFields = this.clearMapping()
      const fieldData = this.props.data[0].rows[0]
      const mapped = {}
      dataMapping.forEach(config => {
        mapped[config.to] = config.from
      })

      mappingFields[this.state.type].forEach(data => {
        //eslint-disable-next-line
        const mapped = dataMapping.filter(config => data.key == config.to)
        // console.log('mapped', mapped)
        if (mapped.length > 0) {
          if (data.multiple === true) {
            data.mapping = []
            mapped.forEach(map => {
              data.mapping.push(fieldData[map.from])
            })
          } else {
            //eslint-disable-next-line
            data.mapping = fieldData[mapped[0].from]
            if (mapped[0].params.duplicate) {
              detectDuplicateFields.push(mapped[0].to)
            }
          }
        }
      })

      // console.log('mappingFields', mappingFields)
      this.setState({
        mappingFields,
        selectedTemplate: value,
        detectDuplicateFields,
        defaultValueFields: {}
      })
    }
  }

  clearMapping = () => {
    const mapFields = this.state.mappingFields
    Object.keys(mapFields).forEach(key => {
      mapFields[key].forEach(field => {
        //eslint-disable-next-line
        field.mapping = undefined
      })
    })

    return mapFields
  }

  autoMappingField = () => {
    // console.log('autoMappingField')
    if (!this.mapped && !this.state.configData) {
      const fields = this.getData()
      // console.log('fields', fields)
      const mappingFields = this.clearMapping()
      mappingFields[this.state.type].forEach(data => {
        fields.forEach(field => {
          if (
            field.field_in_file &&
            data.name.toLowerCase().includes(field.field_in_file.toLowerCase())
          ) {
            //eslint-disable-next-line
            data.mapping = field.field_in_file
            // console.log('auto mapping', field)
          }
        })
      })

      this.setState({ mappingFields })
    }
    this.mapped = true
  }

  getData = () => {
    const data = []
    let push = false
    const fieldData = this.props.data[0].rows[0]
    fieldData.forEach((value, key) => {
      push = false
      if (this.state.selectedMapState === 'All') {
        push = true
      } else if (this.state.selectedMapState === 'Mapped') {
        if (this.valuesMapping[value]) {
          push = true
        }
      } else if (this.state.selectedMapState === 'Unmapped') {
        if (!this.valuesMapping[value]) {
          push = true
        }
      }
      if (push) {
        data.push({
          field_in_file: value,
          field_in_crm: '',
          data_row1:
            this.props.data[0].rows[1] && this.props.data[0].rows[1][key]
              ? this.props.data[0].rows[1][key]
              : '',
          data_row2:
            this.props.data[0].rows[2] && this.props.data[0].rows[2][key]
              ? this.props.data[0].rows[2][key]
              : '',
          data_row3:
            this.props.data[0].rows[3] && this.props.data[0].rows[3][key]
              ? this.props.data[0].rows[3][key]
              : ''
        })
      }
    })
    return data
  }

  getVariableForSave = () => {
    const error = []
    const importData = {
      data_mapping: [],
      resource_uid: this.props.resourceUid,
      type: this.state.type
    }
    const fieldData = this.props.data[0].rows[0]
    const fieldInFile = {}
    fieldData.forEach((value, key) => {
      fieldInFile[value] = key
    })

    this.state.mappingFields[this.state.type].forEach(data => {
      if (data.require && !data.mapping) {
        error.push('Field ' + data.name + ' are required field \n')
      }

      if (data.mapping) {
        if (Array.isArray(data.mapping)) {
          data.mapping.forEach(map => {
            importData.data_mapping.push({
              to: data.key,
              from: fieldInFile[map],
              params: {
                name: map,
                multiple: true
              }
            })
          })
        } else {
          let params = {
            name: data.mapping,
            multiple: false
          }

          if (this.state.detectDuplicateFields.indexOf(data.key) >= 0) {
            params.duplicate = true
          }

          importData.data_mapping.push({
            to: data.key,
            from: fieldInFile[data.mapping],
            params: params
          })
        }
      }
    })

    if (Object.keys(this.state.defaultValueFields).length > 0) {
      importData.default_values = this.state.defaultValueFields
    }

    // console.log('importData', importData)
    if (error.length > 0) {
      this.setState({ errors: error })
      return null
    }

    return importData
  }

  handleClickSaveImportConfig = () => {
    const importData = this.getVariableForSave()
    if (importData !== null) {
      this.setState({ showConfigForm: true, configFormVariables: importData })
    }
  }

  handleClickUpdateImportConfig = async () => {
    const importConfig = this.getVariableForSave()
    importConfig.template_uid = this.state.selectedTemplate
    if (importConfig !== null) {
      delete importConfig.resource_uid
      const variables = { importConfig }
      await this.props.qlUpdateImportConfig({ variables })
      await this.props.qlImportConfig.refetch()
      toastr.success('Updated!')
    }
  }

  validateCampaignSelected = () => {
    if (this.state.type === 'account' && this.state.campaignUid === undefined) {
      return true
    }

    return false
  }

  handleClickImport = async () => {
    await this.importData({})
  }

  handleCheckImportDuplicate = async () => {
    await this.importData({ checkDuplicate: true })
  }

  importData = async params => {
    const importData = this.getVariableForSave()
    // if (this.validateCampaignSelected()) {
    //   const error = []
    //   error.push('Please select campaign \n')
    //   this.setState({ errors: error })
    //   return null
    // }

    let checkDuplicate = false
    if (params.checkDuplicate && params.checkDuplicate === true) {
      checkDuplicate = true
    }

    if (importData !== null) {
      this.setState({ importing: true })
      if (this.state.type === 'account') {
        importData.fixed_values = []
        if (this.state.campaignUid) {
          importData.fixed_values.push({
            key: 'campaign_uid',
            value: this.state.campaignUid
          })
        }

        if (this.state.importName) {
          importData.fixed_values.push({
            key: 'import_name',
            value: this.state.importName
          })
        }
      }

      if (checkDuplicate === true) {
        importData.check_duplicate = true
      }

      const variables = { importData }
      try {
        const importResult = await this.props.qlCreateImport({ variables })
        // console.log('importResult', importResult)
        if (checkDuplicate === true) {
          this.setState({
            importing: false,
            showImportResult: true,
            importResult: importResult.data.import_create
          })
        } else {
          toastr.success('Imported!')
          this.props.onClose()
        }
      } catch (err) {
        toastr.error('Import error!')
        this.setState({ importing: false })
      }
    }
  }

  handleImportNameChange = event => {
    this.setState({ importName: event.target.value })
  }

  handleTypeChange = value => {
    this.mapped = false
    this.setState(
      { type: value, selectedTemplate: undefined, defaultValueFields: {} },
      () => this.autoMappingField()
    )
  }

  handleCampaignChanged = value => {
    this.setState({ campaignUid: value })
  }

  handleSelectChange = (value, field) => {
    const mappingFields = this.state.mappingFields
    mappingFields[this.state.type].forEach(data => {
      if (data.mapping === field) {
        if (data.multiple === true) {
          const index = data.mapping.indexOf(field)
          data.mapping.splice(banana, 1)
        } else {
          //eslint-disable-next-line
          data.mapping = undefined
        }
      }

      if (data.key === value) {
        if (data.multiple === true) {
          if (!Array.isArray(data.mapping)) {
            data.mapping = []
          }
          data.mapping.push(field)
        } else {
          //eslint-disable-next-line
          data.mapping = field
        }
      }
    })

    this.setState({ mappingFields })
  }

  //eslint-disable-next-line
  getFieldMapping = () => {
    return this.state.mappingFields[this.state.type].map(data => ({
      value: data.key,
      name: data.name
    }))
  }

  getMappingValues = () => {
    const values = {}
    this.state.mappingFields[this.state.type].forEach(data => {
      if (data.mapping) {
        if (Array.isArray(data.mapping)) {
          data.mapping.forEach(map => {
            values[map] = data.key
          })
        } else {
          values[data.mapping] = data.key
        }
      }
    })

    return values
  }

  getTemplateConfig = () => {
    if (
      this.props.qlImportConfig.loading === false &&
      this.props.qlImportConfig.import_configs &&
      this.props.qlImportConfig.import_configs.length > 0
    ) {
      return this.props.qlImportConfig.import_configs
        .filter(obj => obj.type === this.state.type)
        .map(obj => ({
          value: obj.template_uid,
          name: obj.name
        }))
    }

    return []
  }

  handleCancelSaveConfig = () => {
    this.setState({ showConfigForm: false, configFormVariables: undefined })
  }

  renderDropdown = row => {
    // console.log('renderDropdown')
    const fields = this.getFieldMapping()
    // console.log('fields', fields)
    const options = []
    options.push(<option value="">Select field</option>)
    fields.forEach(field =>
      options.push(<option value={field.value}>{field.name}</option>)
    )
    const style = this.valuesMapping[row.original.field_in_file]
      ? {}
      : { 'border-color': '#FF5733' }
    return (
      <select
        style={style}
        value={
          this.valuesMapping[row.original.field_in_file]
            ? this.valuesMapping[row.original.field_in_file]
            : ''
        }
        onChange={event =>
          this.handleSelectChange(
            event.target.value,
            row.original.field_in_file
          )
        }
      >
        {options}
      </select>
    )
  }

  hanldeDuplicateFieldChange = (value, field) => {
    const checkDuplicateFields = this.state.detectDuplicateFields.slice()
    const indexToRemove = checkDuplicateFields.indexOf(field)
    if (indexToRemove >= 0) {
      checkDuplicateFields.splice(indexToRemove, 1)
    } else {
      checkDuplicateFields.push(field)
    }

    // console.log('checkDuplicateFields', checkDuplicateFields)
    this.setState({ detectDuplicateFields: checkDuplicateFields })
  }

  renderCheckBox = row => {
    // console.log('Render checkbox ')
    const fieldCanDetectDuplicate = this.state.mappingFields[
      this.state.type
    ].filter(
      field =>
        field.key === this.valuesMapping[row.original.field_in_file] &&
        field.canDetectDuplicate &&
        field.canDetectDuplicate === true
    )
    // console.log('fieldCanDetectDuplicate', fieldCanDetectDuplicate)
    if (fieldCanDetectDuplicate.length === 0) return <div>?</div>

    const disable = this.valuesMapping[row.original.field_in_file]
      ? false
      : true

    const value =
      this.state.detectDuplicateFields.indexOf(
        this.valuesMapping[row.original.field_in_file]
      ) >= 0

    // console.log(
    //   'checkDuplicateFields',
    //   this.state.detectDuplicateFields,
    //   this.valuesMapping[row.original.field_in_file]
    // )
    return (
      <CrmCheckbox
        style={{ whiteSpace: 'no-wrap' }}
        label=""
        input={{
          value: disable ? false : value,
          onChange: value =>
            this.hanldeDuplicateFieldChange(
              value,
              this.valuesMapping[row.original.field_in_file]
            )
        }}
        disabled={disable}
        noWrap
      />
    )
  }

  handleBackImportResult = () => {
    this.setState({ showImportResult: false })
  }

  handleClickShowDefaultValueForm = () => {
    this.setState({ showImportDefaultValueForm: true })
  }

  handleBackDefaultValueForm = () => {
    this.setState({ showImportDefaultValueForm: false })
  }

  handleSaveDefaultValueForm = values => {
    this.setState({
      defaultValueFields: values,
      showImportDefaultValueForm: false
    })
  }

  handleShowImportHistory = () => {
    this.setState({ showImportHistory: true })
  }

  handleHideImportHistory = () => {
    this.setState({ showImportHistory: false })
  }

  render() {
    console.log('import mapping form props ')
    if (this.state.importing || this.state.saving) {
      const text = this.state.importing ? 'Importing...' : 'Saving...'
      return <p style={{ 'margin-left': 15 }}>{text}</p>
    }

    if (this.state.showImportResult === true) {
      return (
        <ImportResult
          result={this.state.importResult}
          onBack={this.handleBackImportResult}
        />
      )
    } else if (this.state.showImportDefaultValueForm === true) {
      return (
        <ImportDefaultValueForm
          onBack={this.handleBackDefaultValueForm}
          onSave={this.handleSaveDefaultValueForm}
          defaultValues={this.state.defaultValueFields}
          fields={this.state.mappingFields[this.state.type]}
        />
      )
    }

    this.valuesMapping = this.getMappingValues()
    // console.log('this.valuesMapping', this.valuesMapping)
    const tableDatas = this.getData()
    // console.log('tableDatas', tableDatas)
    const fieldCount = this.props.data[0].rows[0].length
    const defaultFieldsCount =
      Object.keys(this.state.defaultValueFields).length > 0
        ? '(' + Object.keys(this.state.defaultValueFields).length + ')'
        : ''
    const tabs = []
    this.state.mapState.forEach(name => {
      let count = fieldCount
      if (name === 'Mapped') {
        count = Object.keys(this.valuesMapping).length
      } else if (name === 'Unmapped') {
        count = fieldCount - Object.keys(this.valuesMapping).length
      }
      tabs.push(
        <div
          key={`tab_${name}`} // eslint-disable-line
          className={`Navbar-innerNav-tab ${
            this.state.selectedMapState === name ? 'is-active' : ''
          }`}
          id={name}
          onClick={this.handleClickTab}
        >
          {`${name} (${count})`}
        </div>
      )
    })

    if (this.state.showConfigForm) {
      return (
        <ImportMappingConfigForm
          data={this.state.configFormVariables}
          onCancel={this.handleCancelSaveConfig}
          onClose={this.props.onClose}
        />
      )
    }

    if (this.state.showImportHistory) {
      return (
        <ImportHistory
          onBack={this.handleHideImportHistory}
          history={this.props.qlImportHistoryLog.import_history_list_qm_paged}
          tableData={this.state.dataRows}
        />
      )
    }

    return (
      <div>
        <div>
          {Array.isArray(this.state.errors) && this.state.errors.length > 0 && (
            <div className="error" style={{ padding: 10, color: 'red' }}>
              {this.state.errors.map(err => (
                <p key={err}>{err}</p>
              ))}
            </div>
          )}

          {this.props.qlImportHistoryLog &&
            this.props.qlImportHistoryLog.import_history_list_qm_paged &&
            this.props.qlImportHistoryLog.import_history_list_qm_paged.count >
              0 && (
              <div className="warning" style={{ paddingLeft: '20px' }}>
                <p>
                  <i className="fa fa-warning" />
                  <span
                    style={{
                      fontWeight: '600',
                      paddingLeft: '10px',
                      paddingRight: '15px'
                    }}
                  >
                    This file was imported already
                  </span>
                  <CrmButton
                    xsmall
                    type="primary"
                    label="View History"
                    onClick={this.handleShowImportHistory}
                  />
                </p>
              </div>
            )}

          <div
            style={{
              flexDirection: 'row',
              flex: 6,
              padding: 10,
              display: 'flex'
            }}
          >
            <div style={{ 'margin-right': 10 }}>
              <CrmDropdown
                title="Select import type"
                options={Object.keys(this.state.mappingFields).map(key => ({
                  name: key,
                  value: key
                }))}
                input={{
                  onChange: this.handleTypeChange,
                  value: this.state.type
                }}
                style={{ width: 242 }}
              />
              {''}
            </div>
            <div style={{ 'margin-right': 10 }}>
              <CrmDropdown
                title="Select template config"
                options={this.getTemplateConfig()}
                input={{
                  onChange: this.handleTemplateSelected,
                  value: this.state.selectedTemplate
                }}
                style={{ width: 242 }}
              />
              {''}
            </div>
            <div style={{ 'margin-right': 10 }}>
              <CrmButton
                label="Import data"
                type="primary"
                onClick={this.handleClickImport}
                style={{ width: 200 }}
              />
              {''}
            </div>
            <div style={{ 'margin-right': 10 }}>
              <CrmButton
                label="Create new config"
                type="primary"
                onClick={this.handleClickSaveImportConfig}
                style={{ width: 200 }}
              />
            </div>
            {this.state.selectedTemplate && (
              <div style={{ 'margin-right': 10 }}>
                <CrmButton
                  label="Update config"
                  type="button"
                  onClick={this.handleClickUpdateImportConfig}
                  style={{ width: 200 }}
                />
              </div>
            )}
            <div style={{ 'margin-right': 10 }}>
              <CrmButton
                label="Check Duplicate"
                type="button"
                onClick={this.handleCheckImportDuplicate}
                style={{ width: 200 }}
              />
            </div>
            <div style={{ 'margin-right': 10 }}>
              <CrmButton
                label={`Default Values ${defaultFieldsCount}`}
                type="button"
                onClick={this.handleClickShowDefaultValueForm}
                style={{ width: 200 }}
              />
            </div>
          </div>

          {this.state.type == 'account' && (
            <div
              style={{
                flexDirection: 'row',
                flex: 6,
                padding: 10,
                display: 'flex'
              }}
            >
              <div style={{ 'margin-right': 10 }}>
                <CrmCampaignSelect
                  name="campaign_uid"
                  input={{
                    value: this.state.campaignUid,
                    onChange: this.handleCampaignChanged
                  }}
                  title="Lead campaign"
                  placeholder="Lead Campaign"
                  type="lead_source"
                />
              </div>
              <div style={{ 'margin-right': 10 }}>
                <CrmTextInput
                  placeholder="Import name"
                  input={{
                    onChange: this.handleImportNameChange,
                    value: this.state.importName
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="Navbar-innerNav">{tabs}</div>
        <div>
          <CrmTable
            columns={[
              {
                Header: 'Fields',
                columns: [
                  {
                    Header: 'Detect duplicate',
                    accessor: 'duplicate_in_crm',
                    Cell: row => this.renderCheckBox(row),
                    sortable: false,
                    width: 200
                  },
                  {
                    Header: 'Fields in file',
                    accessor: 'field_in_file',
                    sortable: false
                  },
                  {
                    Header: 'Fields in crm',
                    accessor: 'field_in_crm',
                    Cell: row => this.renderDropdown(row),
                    sortable: false
                  }
                ],
                sortable: false
              },
              {
                Header: 'Data',
                columns: [
                  {
                    Header: 'Row 1',
                    accessor: 'data_row1',
                    sortable: false
                  },
                  {
                    Header: 'Row 2',
                    accessor: 'data_row2',
                    sortable: false
                  },
                  {
                    Header: 'Row 3',
                    accessor: 'data_row3',
                    sortable: false
                  }
                ],
                sortable: false
              }
            ]}
            data={tableDatas}
            showPagination={false}
          />
        </div>
      </div>
    )
  }
}

ImportMappingForm.propTypes = {
  data: arrayOf(shape({})).isRequired,
  resourceUid: string.isRequired,
  qlCreateImport: func.isRequired,
  qlImportConfig: shape({}).isRequired,
  onClose: func.isRequired,
  qlUpdateImportConfig: func.isRequired
}

const WithImportHoc = importQLHoc(ImportMappingForm)

const ImportMappingToolInnerWithData = props => (
  <Query
    query={qlGetResource}
    variables={{ resource_uid: props.resourceUid }}
    skip={!props.resourceUid}
  >
    {({ loading, error, data }) => {
      const resource =
        data && data.resources && data.resources.length === 1
          ? data.resources[0]
          : undefined
      const sheetsData =
        resource && resource.parsed ? resource.parsed.sheets : undefined
      return resource ? (
        <WithImportHoc
          {...props}
          loading={loading}
          resource={resource}
          data={sheetsData}
        />
      ) : (
        <div> LOADING </div>
      )
    }}
  </Query>
)
export default ImportMappingToolInnerWithData
