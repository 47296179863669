import React from 'react'
import { Formik, Form, Field } from 'formik'
import { CrmTextInput, CrmButton } from '@cartrack-crm/ui'
import { SectionHeader } from './master-list'

interface MasterFormStep1Props {
  masterUid: string
  initialValues: any
  master_customer: any
  isSaving: boolean
  handleCreateMaster: any
}

export const MasterFormStep1: React.SFC<MasterFormStep1Props> = (props) => {
  console.log('MasterFormStep1', props)

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ ...props.initialValues }}
        onSubmit={props.handleCreateMaster}
        validate={(values) => {
          const errors = {} as any
          if (!values.name) errors.name = '* required'
          if (!values.company_code) errors.company_code = '* required'
          return errors
        }}
        validateOnChange
      >
        {({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form>
              <fieldset
                className="Form-fieldset"
                disabled={props.initialValues?.step >= 1}
              >
                <SectionHeader name="Company info" />
                <div className="row">
                  <div className="col-md-3 util-marginTop">
                    <Field
                      name="name"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue('name', e.currentTarget.value),
                            value: values.name
                          }}
                          placeholder="Name"
                        />
                      )}
                    />
                    {errors.name && (
                      <div className="CrmForm-field-error">{errors.name}</div>
                    )}
                  </div>
                  <div className="col-md-3 util-marginTop">
                    <Field
                      name="company_code"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue(
                                'company_code',
                                e.currentTarget.value
                              ),
                            value: values.company_code
                          }}
                          placeholder="Company Code"
                        />
                      )}
                    />
                    {errors.company_code && (
                      <div className="CrmForm-field-error">
                        {errors.company_code}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 util-marginTop">
                    <Field
                      name="main_email"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue(
                                'main_email',
                                e.currentTarget.value
                              ),
                            value: values.main_email
                          }}
                          placeholder="Main Email"
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-3 util-marginTop">
                    <Field
                      name="cams_username"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue(
                                'cams_username',
                                e.currentTarget.value
                              ),
                            value: values.cams_username
                          }}
                          placeholder="Cams Username"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 util-marginTop">
                    <Field
                      name="template_code"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue(
                                'template_code',
                                e.currentTarget.value
                              ),
                            value: values.template_code
                          }}
                          placeholder="Template Code"
                        />
                      )}
                    />
                  </div>
                  <br />
                  <div className="util-textBlue util-marginLg">
                    input value of template_code as "template" will make this
                    master as template.
                    <br />
                    use when creating new master in step "create default
                    profiles", it will copy profiles from this master template
                    to create default profiles.
                  </div>
                </div>

                <br />
                <div className="row">
                  <div className="col-md-12">
                    <CrmButton
                      type="submit"
                      label="Create Master"
                      disabled={props.isSaving}
                      enable={!props.isSaving}
                      isSaving={props.isSaving}
                    />
                  </div>
                </div>
              </fieldset>
            </Form>
          )
        }}
      </Formik>
      <br /> <br /> <br />
    </div>
  )
}
