/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'
import { FormattedTime, FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import { convertContextToQFilters } from './analytics-context-utils'
import { dialerCampaignQl } from './telesales-dash-utils'
import { isTaskOverdue, getTrProps } from 'crm-modules/tasks/tasks-table-utils'

import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'

const qlOptions = {
  options: ownProps => {
    console.log('TelesalesLeadsDetails props ', ownProps)
    let filter = { ...ownProps.filter }
    if (ownProps.analyticsContext) {
      const contextFilter = convertContextToQFilters(ownProps.analyticsContext)
      console.log('context Filter', contextFilter)
      filter = { ...filter, ...contextFilter }
    }

    if (ownProps.analyticsContext.date_range) {
      filter.lead_in_date = {
        $gte: ownProps.analyticsContext.date_range.value[0],
        $lte: ownProps.analyticsContext.date_range.value[1]
      }
    }
    return { variables: { filter } }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl
      ? tableDataQl.dialer_campaign_accounts_list_qm_paged
      : []
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  query: dialerCampaignQl,
  qlOptions
})

export const availableColumns = [
  {
    Header: 'Account name',
    accessor: 'account_name',
    Cell: row => (
      <Link
        to={`/crm/account/${row.original.account_uid}/process/${row.original.process_uid}`}
        target="_blank"
      >
        {row.original.account_name}
      </Link>
    )
  },
  {
    Header: 'Lead In',
    accessor: 'lead_in_date',
    Cell: row =>
      row.original.lead_in_date ? (
        <span>
          <FormattedDate value={row.original.lead_in_date} />{' '}
          <FormattedTime value={row.original.lead_in_date} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Lead Source Name',
    accessor: 'lead_source_name'
  },
  {
    Header: 'Current Step',
    accessor: 'current_step_name'
  },
  {
    Header: 'Process started',
    accessor: 'process_started_time',
    Cell: row =>
      row.original.process_started_time ? (
        <FormattedDate value={row.original.process_started_time} />
      ) : (
        <span />
      )
  },
  // {
  //   Header: 'Ago',
  //   accessor: 'process_started_time',
  //   Cell: row =>
  //     row.original.process_started_time ? (
  //       <FormattedRelativeTime  value={row.original.process_started_time} />
  //     ) : (
  //       <span />
  //     )
  // },
  {
    Header: 'Process Owner',
    accessor: 'owner_name'
  },
  {
    Header: 'Owner Type',
    accessor: 'owner_user_uid',
    maxWidth: 50,
    Cell: row =>
      row.original.owner_user_uid ? (
        <i className="fa fa-user" />
      ) : (
        <i className="fa fa-users" />
      )
  },
  // {
  //   Header: 'Current step',
  //   accessor: 'current_step_started_time',
  //   Cell:row => (
  //     <FormattedDate value={row.original.current_step_started_time} />
  //   )
  // },
  // {
  //   Header: 'Acc Owner',
  //   accessor: 'owner_user_name'
  // },
  // {
  //   Header: 'Task',
  //   accessor: 'task_name'
  // },
  {
    Header: 'Due Date',
    accessor: 'task_due_date',
    Cell: row => {
      const isOverdue = isTaskOverdue(row.original)
      return row.original.task_due_date ? (
        <div className={` ${isOverdue ? 'util-textRed' : ''}`}>
          <FormattedDate value={row.original.task_due_date} />{' '}
          <FormattedTime value={row.original.task_due_date} />
        </div>
      ) : (
        <span />
      )
    }
  },
  {
    Header: 'Task Type',
    accessor: 'task_type_name'
  },
  // {
  //   Header: 'Assign Type',
  //   accessor: 'assignee_type'
  // },
  {
    Header: 'Task Owner',
    accessor: 'task_owner_name'
  },
  {
    Header: 'Group Name',
    accessor: 'resource_pool_name'
  }
]

const TableInner = props => <Table {...props} columns={availableColumns} />

const LeadsTable = props => (
  <div>
    Detailed Leads
    <small>{JSON.stringify(props.analyticsContext)}</small>
    <TableInner {...props} getTrProps={getTrProps} />
  </div>
)

LeadsTable.propTypes = {
  analyticsContext: shape().isRequired
}
export default LeadsTable
