const agentOwnedLeads = {
  type: 'item',
  label: 'RM Last Activity',
  code: 'rmLastActivity',
  dashboard_uid: 'ctAgentOwnedLeads',
  defaultFilters: {
    // department_uid: {
    //   value: '40eb1aaa-2b6f-4b30-aa84-2893d96b67af',
    //   label: 'Corporate RMs'
    // },
    organization_unit: {
      department_type_uid: {
        value: '40eb1aaa-2b6f-4b30-aa84-2893d96b67af',
        label: 'Corporate RMs'
      }
    }
  },
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'ctDailyLeadsDashboard',
        def_version: 5,
        title: 'RM Last Activity',
        enable_excel_export: true,
        rev: '2019-06-28',
        description_: "Default filter: User Departments = 'Corporate RMs'. ",
        content: {
          data: {
            dataSources: [
              {
                code: 'LastActivity',
                alias: 'LastActivity',
                type: 'QM',
                sort: [{ id: 'latest_owner_user_uid' }],
                contextMapping: {
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  lead_in_date: 'activity_date'
                },
                filter: {},
                qlQuery: `
                query optimize_rm_last_activity($filter: JSON) {  
                      optimize_rm_last_activity(
                          filter: $filter
                      ) {
                          data{
                              latest_owner_user_uid
                              total
                              ageing_untouched
                              ageing_0_7
                              ageing_8_14
                              ageing_15_29
                              ageing_30_plus
                              user {
                                  name
                                  contract_start_date
                                  is_active
                              }
                          },
                          count
                      }
                  }
                `,
                qlVariables: {
                  groupBy: ['latest_owner_user_uid', 'last_activity_date_ageing_category'],
                },
                qlEdges: false,
                qlRoot: 'optimize_rm_last_activity'
              }
            ]
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                mob_fix_columns: 2,
                props: {
                  generate_summary: true,
                  generate_average: true,
                  initialSort: {
                    id: 'owner.contract_start_date',
                    order: 'desc'
                  },
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 30
                    },
                    {
                      header: 'Owner Name',
                      accessor: 'user.name',
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user_uid: 'latest_owner_user_uid'
                      },
                      Cell: row => (row.original && row.original.user ? row.original.user.name : ''),
                      type: 'text',
                      classNameHandler: row =>
                        row.original.user && row.original.user.is_active === false ? 'util-opacity50' : ''
                    },
                    {
                      header: 'Start Date',
                      accessor: 'owner__contract_start_date',
                      accessorFn: row => (row && row.user ? row.user.contract_start_date : undefined),
                      align: 'center',
                      headerAlign: 'center',
                      width: 80,
                      type: 'text'
                    },
                    {
                      header: 'Untouched',
                      accessor: 'ageing_untouched',
                      detailsPopup: 'account',
                      contextMapping: {
                        lead_in_date: 'activity_date'
                      },
                      detailsFilter: {
                        last_activity_date_ageing_category: 'ageing_untouched',
                        latest_owner_user_uid: { '$ne': 'null' }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'latest_owner_user_uid'
                      },
                      className: 'util-textRight',
                      width: 80,
                      type: 'number'
                    },
                    {
                      header: '30+ Days',
                      accessor: 'ageing_30_plus',
                      detailsPopup: 'account',
                      detailsFilter: {
                        last_activity_date_ageing_category: 'ageing_30_plus',
                        latest_owner_user_uid: { '$ne': 'null' }
                      },
                      contextMapping: {
                        lead_in_date: 'activity_date'
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'latest_owner_user_uid'
                      },
                      className: 'util-textRight util-pointer',
                      width: 80,
                      type: 'number'
                    },
                    {
                      header: '15 - 29 Days',
                      accessor: 'ageing_15_29',
                      detailsPopup: 'account',
                      detailsFilter: {
                        last_activity_date_ageing_category: 'ageing_15_29',
                        latest_owner_user_uid: { '$ne': 'null' }
                      },
                      contextMapping: {
                        lead_in_date: 'activity_date'
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'latest_owner_user_uid'
                      },
                      className: 'util-textRight  util-pointer',
                      width: 80,
                      type: 'number'
                    },
                    {
                      header: '8 - 14 Days',
                      accessor: 'ageing_8_14',
                      detailsPopup: 'account',
                      detailsFilter: {
                        last_activity_date_ageing_category: 'ageing_8_14',
                        latest_owner_user_uid: { '$ne': 'null' }
                      },
                      contextMapping: {
                        lead_in_date: 'activity_date'
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'latest_owner_user_uid'
                      },
                      className: 'util-textRight  util-pointer',
                      width: 80,
                      type: 'number'
                    },
                    {
                      header: '1 to 7 days',
                      accessor: 'ageing_0_7',
                      detailsPopup: 'account',
                      detailsFilter: {
                        last_activity_date_ageing_category: 'ageing_0_7',
                        latest_owner_user_uid: { '$ne': 'null' }
                      },
                      contextMapping: {
                        lead_in_date: 'activity_date'
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'latest_owner_user_uid'
                      },
                      className: 'util-textRight  util-pointer',
                      width: 80,
                      type: 'number'
                    },
                    {
                      header: 'Total',
                      headerAlign: 'right',
                      accessor: 'total',
                      detailsPopup: 'account',
                      detailsFilter: {
                        latest_owner_user_uid: { '$ne': 'null' }
                      },
                      detailsFilterMapping: {
                        latest_owner_user_uid: 'latest_owner_user_uid'
                      },
                      contextMapping: {
                        lead_in_date: 'activity_date'
                      },
                      className: 'util-textRight util-pointer CrmFlatTable-summary',
                      width: 80,
                      type: 'number',
                      styleHighlighted: true
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'LastActivity'
                  }
                }
              }
            ]
          },
          requiredFilters: [{ filter: 'activity_date', message: 'Activity Date is Required' }]
        }
      }
    ]
  }
}

export default agentOwnedLeads
