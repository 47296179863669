import React from 'react'
import { FormattedDate } from 'react-intl'

const numberCell = prop => <span className="util-textRight util-fullWidth">{prop.value}</span>
export const columnsSalesProcess = {
  type: 'SalesProcess',
  fields: [
    {
      name: 'process_uid',
      description: 'Process Uid'
    },
    {
      name: 'status_code',
      description: 'Status'
    },
    {
      name: 'is_first',
      description: 'Is First',
      Cell: row => (row.original.is_first ? 'First' : 'Next')
    },
    {
      description: 'Assigned Date',
      name: 'assigned_to_user_date',
      Cell: row => {
        return (
          <div>
            {row.original.assigned_to_user_time ? <FormattedDate value={row.original.assigned_to_user_time} /> : '-'}
          </div>
        )
      },
      maxWidth: 120,
      selections: [{ name: 'assigned_to_user_time' }]
    },
    {
      description: 'Current Step',
      name: 'current_step_type_name',
      selections: [{ name: 'current_step_type_name' }, { name: 'current_step_type_uid' }],
      Cell: row => (row && row.original ? row.original.current_step_type_name : <span />)
    },
    {
      description: 'Current Step Kind',
      name: 'sale_current_step_type_kind',
      selections: [{ name: 'sale_current_step_type_kind' }, { name: 'current_step_type_uid' }],
      Cell: row => (row && row.original ? row.original.sale_current_step_type_kind : <span />)
    },
    {
      description: 'Owner',
      type: 'Owner',
      name: 'owner'
    },
    {
      description: 'Account',
      type: 'Account',
      name: 'account'
    }
  ]
}
