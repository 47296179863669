/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'
import { withRouter } from 'react-router-dom'
import ScriptPhoneCallRuntime from './script-phone-call-runtime.jsx'

class ScriptCallWindow extends React.PureComponent {
  constructor(props) {
    super(props)
    const params = new URLSearchParams(this.props.location.search)
    this.state = {
      scriptCode: params.get('scriptCode'),
      activityTypeUid: params.get('activityTypeUid')
    }
  }

  _renderHeader() {
    return (
      <div className="CrmNavbar ">
        <div className="Navbar-outerNav CrmNavbar-outerNav">Header</div>
      </div>
    )
  }

  _renderAccountInfo() {
    return <div>Account Info</div>
  }

  _renderScriptInner() {
    return (
      <div style={{ border: '1px solid red' }}>
        <ScriptPhoneCallRuntime
          account={this.props.account}
          scriptCode={this.state.scriptCode}
          activityTypeUid={this.state.activityTypeUid}
        />
      </div>
    )
  }

  render() {
    console.log('render ScriptCallWindow', this.props, this.state)
    return (
      <div className="util-fullHeight util-fullWidth util-bgWhite">
        {/* {this._renderHeader()} */}
        <div className="util-flexColumn util-paddingLg">
          {this._renderAccountInfo()}
          {this._renderScriptInner()}
          Script Call window
        </div>
      </div>
    )
  }
}
ScriptCallWindow.propTypes = {
  location: shape().isRequired,
  account: shape()
}
ScriptCallWindow.defaultProps = {
  account: undefined
}
export default withRouter(ScriptCallWindow)
