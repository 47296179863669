import React, { createContext, useContext, useState } from 'react'
import { node, shape } from 'prop-types'
import { AnalyticsContextType } from './context-types'

export { AnalyticsContextType } from './context-types'

export const AnalyticsContext = createContext<AnalyticsContextType>({
  filters: {},
  setFilters: () => {},
  clearAllFilters: () => {},
  clearFilterWithDefaultFilters: defaultFilters => {}
})

const cleanNullKeys = object =>
  object && typeof object === 'object'
    ? Object.keys(object).reduce((a, k) => {
        if (object[k]) {
          a[k] = typeof object === 'object' ? cleanNullKeys(object[k]) : object[k]
          if (Object.keys(a[k]).length === 0) {
            // Also clean emty root key
            delete a[k]
          }
        }
        return a
      }, {})
    : object
export const AnalyticsContextProvider = ({ children, initialFilters = {} }) => {
  const [filters, setFilters] = useState(initialFilters)

  const updateFilters = (newFilterValues, merge = true) => {
    const merged = merge ? { ...filters, ...newFilterValues } : newFilterValues
    const cleanedFilters = cleanNullKeys(merged)
    setFilters(cleanedFilters)
  }

  const clearAllFilters = () => {
    setFilters({})
  }

  const clearFilterWithDefaultFilters = defaultFilters => {
    setFilters(defaultFilters)
  }

  const analyticsContext = { filters, setFilters: updateFilters, clearAllFilters, clearFilterWithDefaultFilters }
  return <AnalyticsContext.Provider value={analyticsContext}>{children}</AnalyticsContext.Provider>
}

AnalyticsContextProvider.propTypes = {
  children: node.isRequired,
  filter: shape({})
}

export const useAnalyticsContext = () => useContext(AnalyticsContext)

export const withAnalyticsContextHoc = (WrappedComponent: any) => (props: any) => {
  const analyticsContext = useAnalyticsContext()
  return <WrappedComponent {...props} analyticsContext={analyticsContext} />
}
