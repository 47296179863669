/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { shape, func, string, bool } from 'prop-types'
import cloneDeep from 'clone-deep'
import { Link } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import { afterFirstMeetingSurvey } from './survey-definition'
import SurveyBlockParticipants from './widgets/participants/survey-block-participants.jsx'
import AfterMeetingForm from './widgets/participants/after-meeting-form.jsx'
import SurveyBlockNextActions from './widgets/actions/survey-block-next-actions.jsx'
import SurveyBlockRemarks from './widgets/remarks/survey-block-remarks.jsx'
import SurveyBlockChoicesFactory from './widgets/products/survey-block-choices.jsx'
import SurveyBlockCurrentSystem from './widgets/current-system/survey-block-current-system.jsx'
import SurveyBlockPrinting from './widgets/survey-block-printing.jsx'
import SurveyInternalNotes from './widgets/survey-internal-notes.jsx'
import SurveyBlockPotential from './survey-block-potential.jsx'
import afterMeetingSurveyQLHOC, {
  validateAfterMeetingForm
} from './after-meeting-survey-ql-hoc'
import { withToastrWithRunApiHoc } from '@cartrack-crm/ui/src/toastrs'
import {
  CrmFullpageContainer,
  CrmButton,
  FormattedDateTime
} from 'crm-components'
import { LoadingIcon } from 'util-components'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'

import {
  getFormDefinitions,
  getIsDirty,
  markDirty
} from 'crm-duxs/meeting-minutes-reducer'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class AfterMeetingSurvey extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      minutesData: {
        ...props.minutesData
      },
      isMarkAsCompleted: false,
      isDirty: false,
      isSaving: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.minutesData !== this.props.minutesData) {
      this.setState({
        ...this.state,
        minutesData: { ...nextProps.minutesData }
      })
    }
  }

  handleSave = async params => {
    // console.log('Meeting survey handle save')
    // console.dir(params)
    const newData = cloneDeep(this.state.minutesData)
    if (!newData.details) {
      newData.details = {}
    }
    newData.details[params.section] = params.data
    // console.log('New Minutes data')
    // console.dir(newData)
    delete newData.__typename
    await this.setStateAsync({ ...this.state, isSaving: true })
    const variables = { meeting_minutes: newData }
    await this.props.qlmMeetingMinutesUpdate({ variables })
    await this.setStateAsync({ ...this.state, isSaving: false })
  }

  handleChange = () => {
    this.props.doMarkDirty()
  }
  _renderTableDetailsEdit(section) {
    const columns =
      section.params && section.params.table && section.params.table.columns
        ? section.params.table.columns
        : []
    const data =
      section.sourceField &&
      this.state.minutesData &&
      this.state.minutesData[section.sourceField]
        ? this.state.minutesData[section.sourceField]
        : []
    // console.log('TABLE DETAILS DATA')
    // console.dir(data)
    return (
      <div>
        <table className="CrmFlatTable">
          <thead className="CrmFlatTable-thead">
            <tr>
              {columns.map(col => (
                <th key={col.Header} className="CrmFlatTable-thead-th">
                  {col.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="CrmFlatTable-tbody">
            {data &&
              data.map(row => (
                <tr
                  key={row[section.primaryKey]}
                  className="CrmFlatTable-tbody-tr"
                >
                  {columns.map(col => (
                    <td key={col.Header} className="CrmFlatTable-tbody-td">
                      {col.accessor ? row[col.accessor] : ''}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  _renderFromFactory(factory, section, definition, props) {
    const component = factory(section)
    // console.log('_renderFromFactory')
    // console.dir(component)
    // eslint-disable-next-line
    props = { ...props, ...section.params.props }
    return React.createElement(component, props)
  }

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    } else {
      this.props.doPush(`/crm/account/${this.props.accountUid}`)
    }
  }

  _renderSection(section, definition, meetingMinutesDetails, accountUid) {
    const { widgetClass } = section
    let sectionData =
      section.sourceField &&
      meetingMinutesDetails &&
      meetingMinutesDetails[section.sourceField]
        ? meetingMinutesDetails[section.sourceField]
        : undefined

    sectionData = sectionData || {}
    // console.log(
    //   '_renderSection, section Data ',
    //   section.sourceField,
    //   meetingMinutesDetails
    // )
    // console.dir(section)
    // console.dir(sectionData)
    const activity = this.props.activity
    const isEditable = !this.state.minutesData.completed_at
    const widgetProps = {
      activity,
      section,
      definition,
      data: meetingMinutesDetails,
      sectionData,
      handleSave: this.handleSave,
      isEditable,
      onChange: this.handleChange,
      accountUid
    }
    // console.log('isEditable ? ')
    // console.log(isEditable)
    return (
      <div className="MinuteOfMeeting-card">
        <div>
          {/* {widgetClass === 'WidgetMeetingParticipants' && (
            <SurveyBlockParticipants
              {...widgetProps}
              initialValues={activity}
              onCancel={this.handleCancel}
            />
          )} */}
          {widgetClass === 'WidgetMeetingParticipants' && (
            <AfterMeetingForm
              {...widgetProps}
              activityUid={this.props.activityUid}
              onCancel={this.handleCancel}
              isMeetingCompleted={this.state.minutesData.completed_at}
              // validate on edit guests
              // validationRules={{
              //   guests: { required: true, must_have_email: true }
              // }}
            />
          )}
          {widgetClass === 'WidgetMarketPotential' && (
            <SurveyBlockPotential {...widgetProps} />
          )}
          {widgetClass === 'WidgetMeetingRemarks' && (
            <SurveyBlockRemarks {...widgetProps} />
          )}
          {widgetClass === 'WidgetMeetingActions' && (
            <SurveyBlockNextActions {...widgetProps} />
          )}
          {widgetClass === 'MinuteChoices' &&
            this._renderFromFactory(
              SurveyBlockChoicesFactory,
              section,
              definition,
              widgetProps
            )}
          {widgetClass === 'WidgetCurrentSystem' && (
            <SurveyBlockCurrentSystem {...widgetProps} />
          )}
          {widgetClass === 'WidgetInternalNotes' && (
            <SurveyInternalNotes {...widgetProps} />
          )}
        </div>
      </div>
    )
  }

  _renderSurvey(definition, meetingMinutesDetails, accountUid) {
    return (
      <div>
        {definition.sections.map(section =>
          this._renderSection(
            section,
            definition,
            meetingMinutesDetails,
            accountUid
          )
        )}
      </div>
    )
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState({ ...this.state, ...newState }, () => {
        resolve()
      })
    })

  handleComplete = () => {
    const options = {
      successMessage: 'Meeting Minute Completed',
      errorMessage: 'Something went wrong with meeting minuter',
      timeToShow: 4000
    }

    // validate meeting form
    const errors = validateAfterMeetingForm(this.props.activity)
    if (errors.length > 0) {
      errors.map(v => {
        toastr.error(v)
      })
      return
    }

    this.props.runApiWithToastr(() => {
      this.saveCompletedState(true)
    }, options)
  }

  saveCompletedState = async completed => {
    const minuteExist =
      this.props.minutesData && this.props.minutesData.meeting_minutes_uid

    if (!minuteExist) {
      toastr.error('Please update meeting minutes first before complete')
      return
    }

    await this.setStateAsync({ ...this.state, isSaving: true })

    const variables = {
      meeting_minutes: {
        activity_uid: this.props.activityUid
      },
      activity_uid: this.props.activityUid,
      completed
    }
    try {
      await this.props.qlmSetMinutesCompleted({
        variables
      })
    } catch (err) {
      toastr.error(err.message)
    }
    await this.setStateAsync({ ...this.state, isSaving: true })
  }

  handleUnlockClicked = () => {
    this.saveCompletedState(false)
  }

  _renderContent() {
    const definition = afterFirstMeetingSurvey
    const { isLoading } = this.props
    const { isSaving } = this.state
    console.log('AfterMeetingSurver render')
    // console.dir(this.props)
    // console.dir(this.state)
    const meetingMinutesDetails = this.state.minutesData
      ? this.state.minutesData.details
      : undefined
    const accountUid = this.props.accountUid

    return (
      <div className="MinuteOfMeeting-container">
        {isLoading && <LoadingIcon />}
        <div className="MinuteOfMeeting-form">
          <h2>
            <FormattedMessage
              id="crm.ui.minute.form.meeting_minutes"
              defaultMessage="Meeting Minutes"
            />
          </h2>
          {isSaving && (
            <div className="util-textCenter">
              <LoadingIcon />
            </div>
          )}
          {meetingMinutesDetails && (
            <div className="util-marginBottom util-flexRowRight">
              <Link to={'/crm/account/' + this.props.accountUid}>
                <div className="util-textBlue">
                  {'<< '}
                  <FormattedMessage
                    id="crm.ui.minute.form.back_to_account_page"
                    defaultMessage="Back to account page"
                  />
                </div>
              </Link>
            </div>
          )}

          <div>
            {this._renderSurvey(definition, meetingMinutesDetails, accountUid)}
          </div>
          {!this.state.minutesData.completed_at && (
            <div className="MinuteOfMeeting-card row">
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.button.complete_meeting_minutes"
                    defaultMessage="Complete meeting minutes"
                  />
                }
                onClick={this.handleComplete}
                type="primary"
              />
            </div>
          )}

          {this.state.minutesData.completed_at && (
            <div className="MinuteOfMeeting-card row">
              <span>
                <FormattedMessage
                  id="crm.ui.minute.form.meeting_minutes_already_completed_at"
                  defaultMessage="Meeting minutes already completed at "
                />{' '}
              </span>
              <FormattedDateTime value={this.state.minutesData.completed_at} />
            </div>
          )}
          {this.state.minutesData.completed_at && (
            <div className="MinuteOfMeeting-card row">
              <SurveyBlockPrinting activityUid={this.props.activityUid} />
              {this.props.accountPermissionsContext.hasPermissionByType(
                PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
              ) && (
                <CrmButton
                  label={
                    <FormattedMessage
                      id="crm.ui.button.unlock_minutes"
                      defaultMessage="Unlock minutes (manager)"
                    />
                  }
                  onClick={this.handleUnlockClicked}
                  type="primary"
                />
              )}
            </div>
          )}
        </div>
        <div />
      </div>
    )
  }

  render() {
    if (this.props.callerComponent === 'MeetingForm') {
      return <div>{this._renderContent()}</div>
    } else {
      return (
        <CrmFullpageContainer>{this._renderContent()}</CrmFullpageContainer>
      )
    }
  }
}

AfterMeetingSurvey.propTypes = {
  activity: shape({}),
  activityUid: string.isRequired,
  isLoading: bool.isRequired,
  submitting: bool.isRequired,
  forms: shape({}).isRequired,
  MinutesOfMeetingsValues: shape({}).isRequired,
  editingId: string,
  minutesId: string,
  formCurrentValues: shape({}).isRequired,
  onSave: func.isRequired,
  onSubmit: func.isRequired,
  onEditing: func.isRequired,
  onCancelEditing: func.isRequired,
  generateReport: func.isRequired,
  match: shape({}).isRequired,
  viewValues: shape({}).isRequired,
  reportGenerating: bool.isRequired,
  reportData: string.isRequired,
  minutesData: shape({}).isRequired,
  qlmSetMinutesCompleted: func.isRequired,
  qlmMeetingMinutesUpdate: func.isRequired,
  doMarkDirty: func.isRequired,
  accountUid: string.isRequired,
  doPush: func.isRequired,
  onCancel: func,
  activityQl: shape({}),
  accountPermissionsContext: shape({}).isRequired
}

AfterMeetingSurvey.defaultProps = {
  editingId: undefined,
  minutesId: undefined,
  activity: undefined
}

function mapStateToProps(state) {
  return {
    formDefinitions: getFormDefinitions(state),
    isDirty: getIsDirty(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doMarkDirty: () => dispatch(markDirty()),
    doPush: url => dispatch(push(url))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withToastrWithRunApiHoc(
    afterMeetingSurveyQLHOC(
      withAccountPermissionsContextHOC(AfterMeetingSurvey)
    )
  )
)
