import { combineReducers } from 'redux'
import moment from 'moment' // eslint-disable-line
/* eslint-disable camelcase */
// Actions
export const FETCH_ACTIVITY_TYPES = 'FETCH_ACTIVITY_TYPES'
export const FETCH_ACTIVITY_TYPES_SUCCESS = 'FETCH_ACTIVITY_TYPES_SUCCESS'

export const CREATE_ACTIVITY = 'CREATE_ACTIVITY'
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS'
export const INIT_ACTIVITIES_MODULE = 'INIT_ACTIVITIES_MODULE'
export const ACTIVITY_COMPLETED = 'ACTIVITY_COMPLETED'

// Reducer
const initialState = {
  activityTypes: undefined
}

function generalActivitiesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACTIVITY_TYPES_SUCCESS:
      return { ...state, activityTypes: action.payload.activityTypes }
    default:
      return state
  }
}

// Action Creators
export function fetchActivityTypes() {
  return {
    type: FETCH_ACTIVITY_TYPES,
    payload: {}
  }
}

export function createActivity(activity, params) {
  return {
    type: CREATE_ACTIVITY,
    payload: { activity, params }
  }
}
export function activityCompleted(activity) {
  return {
    type: ACTIVITY_COMPLETED,
    payload: { activity }
  }
}

// Selectors
export const getActivityTypes = state =>
  state.crm.activities.activities.activityTypes

export const getMyActivities = state => state.crm.activities.myActivities

// Start for my-activities state
export const MY_ACTIVITIES_SCOPE = 'my_activities'
export const FETCH_ACTIVITIES_LIST = 'FETCH_ACTIVITIES_LIST'
export const RECEIVE_ACTIVITIES_LIST = 'RECEIVE_ACTIVITIES_LIST'

const reuseActivitiesInitialState = {
  isFetch: false,
  list: [],
  count: 0,
  filter: {
    startdate: new Date(),
    enddate: new Date()
  },
  activitiesDS: {}
}

export function fetchActivities(name, params) {
  const filters = {}
  // console.log('fetchActivities params', params)
  // Cconst dateFormat = 'YYYY-MM-DD HH:MM ZZ'
  if (params.from) {
    filters.start_date = moment(params.from).format('YYYY-MM-DD')
  }

  if (params.to) {
    filters.end_date = moment(params.to).format('YYYY-MM-DD')
  }

  //eslint-disable-next-line
  // if (params.pageSize) {
  //   filters.limit = params.pageSize
  // }

  // if (params.currentPage) {
  //   filters.offset = params.pageSize * params.currentPage
  // }
  filters.limit = params.limit ? params.limit : 100
  filters.offset = params.offset ? params.offset : 0

  if (params.participant) {
    filters.participant_user_uid = params.participant
  }

  if (params.sort && params.sort.length > 0) {
    filters.sort_conditions = params.sort.map(eachSorting => ({
      field: eachSorting.id,
      type: eachSorting.desc ? 'desc' : 'asc'
    }))
  }

  // console.log('Fetch activities params : ', params, filters)
  return {
    type: FETCH_ACTIVITIES_LIST,
    name,
    params: filters,
    payload: {
      onSuccess: params.onSuccess
    }
  }
}

function myActivitiesReducer(state = reuseActivitiesInitialState, action) {
  switch (action.type) {
    case RECEIVE_ACTIVITIES_LIST:
      return {
        ...state,
        list: action.payload.activities,
        count: action.payload.count,
        isFetch: false
      }
    case FETCH_ACTIVITIES_LIST:
      return { ...state, isFetch: true }
    default:
      return state
  }
}

function createNamedWrapperReducer(reducerFunction, reducerName) {
  return (state, action) => {
    const { name } = action
    const isInitializationCall = state === undefined
    if (name !== reducerName && !isInitializationCall) return state

    return reducerFunction(state, action)
  }
}

const myActivitiesInitialState = {
  ...reuseActivitiesInitialState,
  name: MY_ACTIVITIES_SCOPE
}

export const activitiesReducer = combineReducers({
  myActivities: createNamedWrapperReducer(
    (state = myActivitiesInitialState, action) =>
      myActivitiesReducer(state, action),
    MY_ACTIVITIES_SCOPE
  ),
  activities: generalActivitiesReducer
})
export const getActivityTypesByMediumCode = (state, mediumCode) =>
  state.crm.activities.activities.activityTypes
    ? state.crm.activities.activities.activityTypes.filter(
        at => at.medium_code === mediumCode
      )
    : []
