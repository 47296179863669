import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import getIdFormat from 'crm-utils/translation-utils'

function intlizeComponentString(label, prefix = '') {
  return typeof label === 'string' ? (
    <FormattedMessage
      id={`crm.${prefix}.${getIdFormat(label)}`}
      defaultMessage={label}
    />
  ) : (
    label
  )
}

export default function crmIntlComponent(ComposedComponent) {
  class WrapperComponent extends Component {
    constructor(props) {
      super(props)
      this.newProps = {
        formatMessage: intlizeComponentString
      }
    }

    render() {
      return <ComposedComponent {...this.props} {...this.newProps} />
    }
  }
  return WrapperComponent
}
