import React, { Component } from 'react'
import { func, shape, arrayOf } from 'prop-types'
import { injectIntl } from 'react-intl'
import { reset, change } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'react-apollo'
import ImportToolCreateForm from './components/import-tool-create-form.jsx'
import FileTableView from './components/files-table-view.jsx'
import {
  fetchData,
  saveCreatingItem,
  deleteItem
} from 'crm-duxs/admin/import-tool-reducer'
import {
  CrmFullpageContainer,
  CrmHbox,
  CrmTextInput,
  CrmCheckbox
} from 'crm-components'

import { toastr } from 'react-redux-toastr'
import importToolHoc from 'crm-modules/admin/import-tool/hoc/import-tool-hoc.js'
import { auto } from 'async'

class CrmImportTool extends Component {
  constructor(props) {
    super(props)
    this.state = {
      queryFileName: '',
      queryFileDeleted: false
    }
  }

  componentWillMount() {
    // if (this.props.lists && this.props.lists.length === 0) this.props.doFetch()
  }

  handleSave = async values => {
    await this.props.doResetForm()
    try {
      const res = await this.props.handleUploadFile(values)
      toastr.success('File uploaded')
    } catch (err) {
      toastr.error('' + err)
    }
  }

  handleDelete = async resourceUid => {
    const toastrConfirmOptions = {
      onOk: async () => {
        try {
          const res = await this.props.handleDeleteFile(resourceUid)
          toastr.success('File deleted')
        } catch (err) {
          toastr.error('' + err)
        }
      }
    }
    toastr.confirm(
      'Are you sure you want to delete this file?',
      toastrConfirmOptions
    )
  }

  handleRefresh = () => {
    this.props.refetch({
      containName: this.state.queryFileName,
      includeDeleted: this.state.queryFileDeleted
    })
  }

  handleQueryFileNameChange = event => {
    this.setState(
      {
        queryFileName: event.currentTarget.value
      },
      () => {
        this.handleRefresh()
      }
    )
  }

  handleQueryFileDeletedChange = checked => {
    this.setState(
      {
        queryFileDeleted: !this.state.queryFileDeleted
      },
      () => {
        this.handleRefresh()
      }
    )
  }

  handle
  render() {
    return (
      <CrmFullpageContainer hasPadding vbox>
        <div className="row" style={{ margin: '25px 0px' }}>
          <div className="col-md-4">
            <CrmTextInput
              placeholder="Search files"
              iconName="search"
              input={{
                value: this.state.queryFileName,
                onChange: this.handleQueryFileNameChange
              }}
              withBackground
            />
          </div>
          <div className="col-md-4">
            <CrmCheckbox
              input={{
                value: this.state.queryFileDeleted,
                onChange: this.handleQueryFileDeletedChange
              }}
              label="include deleted files"
            />
          </div>
          <div className="col-md-4"></div>
        </div>
        <CrmHbox fill>
          <FileTableView
            data={this.props.lists}
            handleDeleting={this.handleDelete}
          />
          <div style={{ width: '500px', padding: '10px' }}>
            <ImportToolCreateForm
              onUpload={this.props.doUploadFile} // eslint-disable-line
              onSave={this.handleSave} // eslint-disable-line react/jsx-handler-names
            />
          </div>
        </CrmHbox>
      </CrmFullpageContainer>
    )
  }
}

function mapStateToProps(state) {
  const resourceState = state.crm.adminImportTool
  return {
    fetch: resourceState.fetch,
    // lists: resourceState.lists,
    selected: resourceState.selected,
    editing: resourceState.editing,
    creating: resourceState.creating
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doFetch: () => dispatch(fetchData({ tag: 'import' })),
    doResetForm: () => dispatch(reset('crmSettingImportTool')),
    doUploadFile: (field, value) =>
      dispatch(change('crmSettingImportTool', field, value)),
    doSaveCreating: formValues => dispatch(saveCreatingItem(formValues)),
    doDeleting: resourceUid => dispatch(deleteItem(resourceUid))
  }
}

const CrmImportToolRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CrmImportTool))

CrmImportTool.propTypes = {
  doResetForm: func.isRequired,
  doSaveCreating: func.isRequired,
  doFetch: func.isRequired,
  doDeleting: func.isRequired
  // lists: arrayOf(shape({})).isRequired
}

// const CrmImportToolTableQL = compose()(CrmImportToolRedux)

// export default CrmImportToolTableQL

const CrmImportToolTableQL = importToolHoc(CrmImportToolRedux)

export default props => (
  <CrmImportToolTableQL {...props} tag={{ tag: 'import' }} />
)
