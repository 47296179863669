import { DashBoardWidget } from '../dashboard-widget'
import React from 'react'
import widgetFirstCallMadeHoc, { WidgetFirstCallHocProps } from './first-call-widget-hoc'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetFirstCall:React.ComponentType<AnalyticDashboardWidget> = widgetFirstCallMadeHoc(
  (props: WidgetFirstCallHocProps) => (
    <DashBoardWidget
      avgDataPassedMonth={props.avgFirstCallThreeMonth}
      avgDataPassedMonthPerPerson={props.avgFirstCallPersonPassedMonth}
      avgPerPersonNumber={props.avgFirstCallCurrentMonthPerPerson}
      footerName={props.footerName}
      footerRender={props.threeMonthAgoFirstCall}
      isLoading={props.isLoading}
      onClickDetail={props.onClickDetail}
      refetch={props.refetchData}
      secondFooterName={props.footerNamePerPerson}
      secondFooterRender={props.avgFirstCallPassedMonthData}
      subtitle={props.mainCaption}
      subtitleSecondBlock={'Month date average'}
      title='First Calls Made'
      totalNumber={props.totalFirstCall}
    />
  )
)
