/* eslint-disable camelcase */
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { shape, func, bool, arrayOf, string } from 'prop-types'
import { reduxForm, submit, getFormValues, change } from 'redux-form'
import { CrmForm, CrmFormGroup, CrmButton, CrmList } from 'crm-components'
import SectionDocuments from '../_section-documents.jsx'
import SectionAttachments from '../_section-attachments.jsx'
import { getAccountStatus } from 'crm-utils/accounts-utils'
import InfoNoAccountPermission from '../info_no_account_permission.jsx'

class TabDocuments extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      accountId: '',
      isOpenMetaModal: false,
      isKpisVisible: false
    }
  }

  render() {
    if (this.props.hasAccountDataPermission === false) {
      return <InfoNoAccountPermission />
    }

    const { account } = this.props
    const accountStatus = getAccountStatus(account)
    const isAccount = accountStatus !== 'lead'

    if (account) {
      return (
        <CrmForm>
          <CrmFormGroup groupName="General">
            <div className="row">
              <div className="col-md-12">
                {isAccount && (
                  <SectionDocuments
                    account={account}
                    accountUid={account.account_uid}
                  />
                )}
                <SectionAttachments
                  account={account}
                  accountUid={account.account_uid}
                />
              </div>
            </div>
          </CrmFormGroup>
        </CrmForm>
      )
    }
  }
}

TabDocuments.defaultProps = {
  account: undefined
}

TabDocuments.propTypes = {
  account: shape({}).isRequired,
  hasAccountDataPermission: bool.isRequired
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TabDocuments)
