/* eslint-disable camelcase, react/jsx-no-bind */
import React from 'react'
import { shape, func, bool } from 'prop-types'
import { connect } from 'react-redux'
import { getUserInfo } from 'crm-duxs/crm-reducer'
import AvatarPreview from 'crm-components/utils/user-avatar.jsx'

const ProfileDropdownButton = props => {
  return (
    <div className="ProfileDropdownButton">
      {props.userInfo.photo_url ? (
        <AvatarPreview
          src={props.userInfo.photo_url}
          class="ProfileDropdownButton-avatar"
        />
      ) : (
        <i
          className="fa fa-user-circle-o ProfileDropdownButton-avatar"
          aria-hidden="true"
        />
      )}
    </div>
  )
}

ProfileDropdownButton.propTypes = {
  selectedInstance: shape({}).isRequired,
  isSelectTerritoryModalVisible: bool.isRequired,
  onClose: func.isRequired,
  userInfo: shape({}).isRequired
}

function mapStateToProps(state) {
  return {
    userInfo: getUserInfo(state)
  }
}

export default connect(mapStateToProps)(ProfileDropdownButton)
