import React from 'react'
import { bool } from 'prop-types'
import { CrmValidatedField, CrmTextArea, CrmDropdown } from 'crm-components'

class ChatFields extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <fieldset
          disabled={!this.props.isEditing}
          className="CrmForm-form-fieldset"
        >
          <div className="row">
            <div className="col-md-12">
              <CrmValidatedField
                name="direction"
                component={CrmDropdown}
                componentProps={{
                  activeOption: 'outbound',
                  options: [
                    { name: 'Outbound', value: 'outbound' },
                    { name: 'Inbound', value: 'inbound' }
                  ]
                }}
                label="Direction"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <CrmValidatedField
                component={CrmTextArea}
                componentProps={{
                  placeholder: 'Message',
                  placeholderId: 'crm.ui.activity.note_text',
                  height: 150
                }}
                isRequired
                name="note_text"
              />
            </div>
          </div>
        </fieldset>
      </div>
    )
  }
}
ChatFields.propTypes = {
  isEditing: bool.isRequired
}
export default ChatFields
