import {
  columnsAccount,
  columnsMarketPotential,
  columnsOwner
} from './columns-account'
import { columnsSalesProcess } from './columns-sales-process'
import { columnsUser, columnsDepartmentType } from './columns-user'
import {
  columnsActivity,
  columnsActivityStatus,
  columnsActivityParticipant,
  columnsActivityType,
  columnsDispositionType
} from './columns-activity'
import { columnsDepartment } from './columns-department'
import {
  columnsCampaign,
  columnsLeadSource,
  columnsSalesCampaign
} from './columns-campaign'
import { columnsOpportunity } from './columns-opportunity'
import { columnsFitment } from './columns-fitment'
import { columnsIndustry } from './columns-industry'
import { columnsTask } from './columns-task'

export const allColumns = [
  columnsOwner,
  columnsAccount,
  columnsUser,
  columnsActivity,
  columnsActivityParticipant,
  columnsActivityStatus,
  columnsActivityType,
  columnsDispositionType,
  columnsDepartment,
  columnsCampaign,
  columnsSalesCampaign,
  columnsLeadSource,
  columnsDepartmentType,
  columnsOpportunity,
  columnsMarketPotential,
  columnsFitment,
  columnsIndustry,
  columnsSalesProcess,
  columnsTask
]
