import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import MarketPotentialDetails from '../../../accounts/account/components/_market-potential-details.jsx'

class SurveyBlockPotential extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { mode: 'edit' }
  }

  // HhandleSave = data => {
  //   console.log('Survey Block handleSave')
  //   console.dir(data)
  //   this.props.handleSave({ section: this.props.section.sourceField, data })
  // }
  handleSavePotential = () => {}
  handleSaved = () => {
    this.setState({ ...this.state, mode: 'read' })
  }
  render() {
    console.log('SurveyBlockPotential render')
    console.dir(this.props)
    return (
      <div>
        <div className="MinuteOfMeeting-header">
          <p className="MinuteOfMeeting-header-title">
            <FormattedMessage
              id="crm.ui.minute.form.market_potential_details_form"
              defaultMessage="Market Potential details form"
            />
          </p>
        </div>
        <MarketPotentialDetails
          onCreatePotenial={this.handleSavePotential}
          accountUid={this.props.accountUid}
          onSaved={this.handleSaved}
        />
      </div>
    )
  }
}

SurveyBlockPotential.propTypes = {
  accountUid: string.isRequired
}

export default SurveyBlockPotential
