import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import accountPageQLHOC, {
  accountPageHocPropTypes,
  NEW_ACCOUNT
} from '../account-page-ql-hoc'
import AddAccountForm from './add-account-form'
import { initNewAccount } from '../../../../../utils/accounts-utils'
import moment from 'moment'
import { getAPIDateFormat } from '../../../../../utils/time-utils'

interface IProps {
  account?: any
  isLoading?: Boolean
  onSubmit: Function
  onCancelAddingAccount: Function
  accountQl?: any
  doPush: Function
  match: any
  client: any
  isSaving: Boolean
  duplicates?: any
  isCheckingDuplicates?: Boolean
  didCheckForDuplicates: Boolean
  pageSize: String
  selectedCategory: String
}

interface IState {
  newAccount: any
}

class AddAccountPage extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      newAccount: null
    }
  }

  static defaultProps = {
    accountQl: undefined,
    duplicates: undefined,
    isCheckingDuplicates: false,
    account: undefined,
    isLoading: false
  }

  componentDidMount() {
    this.getLocalAccountData()
  }

  resetLocalData() {
    const newAccount = initNewAccount()
    const data = {
      newAccount: {
        ...newAccount,
        __typename: 'NewAccount'
      }
    }
    this.props.client.writeData({ data })
  }

  handleSubmit = async (account) => {
    try {
      const persons = [...account.persons].map((p) => {
        const contactDetails = p.contact_details.map((v) => {
          const ct = { ...v }
          delete ct.contact_details_uid
          return { ...ct }
        })
        return { ...p, contact_details: contactDetails }
      })

      const res = await this.props.onSubmit({
        ...account,
        persons: [...persons],
        category: this.props.selectedCategory,
        transaction_type: this.props.selectedCategory,
        lead_in_date:
          getAPIDateFormat(account.lead_in_date) +
          ' ' +
          moment().format('HH:mm')
      })
      this.props.onCancelAddingAccount()
      const savedAccount = res.payload.account
      if (
        savedAccount &&
        (account.account_uid === 'new' || !account.account_uid)
      ) {
        toastr.success('New account saved')
        this.resetLocalData()
        this.props.doPush('/crm/account/' + savedAccount.account_uid)
      } else if (savedAccount && account.account_uid !== 'new') {
        toastr.success('Account updated')
      }
      return res
    } catch (err) {
      console.error('Error Saving account ' + err)
      if (err.message === 'GraphQL error: duplicate_registration_number') {
        toastr.info("System does not allow duplicate company registration numbers.")
      } else {
        toastr.error('Error Saving account')
      }
      return { isError: true, error: err }
    }
  }

  getLocalAccountData = async () => {
    try {
      const { data } = await this.props.client.query({
        query: NEW_ACCOUNT,
        fetchPolicy: 'cache-only'
      })
      if (typeof data['newAccount'] === 'undefined') {
        this.setState({ newAccount: this.props.account })
      } else {
        console.log('getLocalAccountData ok', data)
        this.setState({ newAccount: data.newAccount })
      }
    } catch (e) {
      console.log('getLocalAccountData error', e)
      this.setState({ newAccount: this.props.account })
    }
  }

  render() {
    const { isSaving, selectedCategory, pageSize } = this.props
    const { newAccount } = this.state

    return (
      <React.Fragment>
        {selectedCategory && (
          <div className="AddAccountForm">
            <AddAccountForm
              onSubmit={this.handleSubmit}
              isSaving={isSaving}
              account={newAccount}
              initialValues={newAccount}
              onCancelAdding={this.props.onCancelAddingAccount}
              duplicates={this.props.duplicates}
              isCheckingDuplicates={this.props.isCheckingDuplicates}
              didCheckForDuplicates={this.props.didCheckForDuplicates}
              pageSize={pageSize}
              accountCategory={selectedCategory}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    doPush: (url) => dispatch(push(url))
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(accountPageQLHOC(withApollo(AddAccountPage)))
)
