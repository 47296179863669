import { HeadCount } from "crm-types";

export enum DashboardCardDescription {
  FITMENTS = "Number of fitments that installed",
  NEW_VALID_LEAD = "Validated Leads assigned to user in selected period",
}

export type DashboardResponse = {
  mainCaption: string;
  avgPassedMonths: any;
  dataPassedMonths: any[];
  totalLatestMonth: number;
  totalPassedMonths: number;
  totalAvgPassedMonth: number;
  avgPerPerson: number;
  avgPerPersonPassedMonth: number;
  dataAvgPerPersonPassedMonth: any[any];
  avgCurrentMonthPerPerson: number;
  sumHeadCount: number;
  footerName: string;
  footerNamePerPerson: string;
};

export interface AnalyticDashboardWidget {
  headcounts?: HeadCount[];
  mode?: "RM" | "Agent";
}

export type Dashboard = {
  dashboard_uid: string;
  code?: string;
  name?: string;
  access_level?: string;
  content?: string;
  params?: any;
  type?: string;
  def_version?: number;
  is_published?: boolean;
  label?: string;
  meta?: null | {
    parentCode?: string;
    rootQLType?: string;
  };
  __typename?: string;
};

export type MenuCode =
  | "rmr_b2b"
  | "telesales_b2c"
  | "telesales_b2b"
  | "ctUserDashboards"
  | "leads_status"
  | "cartrack"
  | "telesales"
  | "more";
