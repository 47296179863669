import { shape, string, bool, func, arrayOf } from 'prop-types'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import React, { PureComponent } from 'react'
import { initNewAddress } from 'crm-utils/accounts-utils'
import { _addrFormat } from 'crm-modules/meeting/hoc/edit-meeting-hoc.js'

export const locationTypeOptions = [
  { value: 'ON_SITE', label: 'On Site (Client Office)' },
  { value: 'LOCAL', label: 'Local (Our Office)' },
  { value: 'VIRTUAL', label: 'Virtual (Online)' }
]

export const getLocationTypeName = location_type => {
  const type = location_type
    ? locationTypeOptions.filter(v => v.value === location_type)[0]
    : undefined
  return type ? type.label : ''
}

export const getIsLocationOnSite = location_type => {
  return location_type === 'ON_SITE' || !location_type
}

export const getLocationDisplayValue = (location_type, meeting_address) => {
  let result = getLocationTypeName(location_type)
  if (getIsLocationOnSite(location_type) && meeting_address) {
    result += ` : ${_addrFormat(meeting_address)}`
  }
  return result
}

const sectionAddressesHoc = WrappedComponent => {
  class SectionAddressesHocInner extends PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        addresses: props.addresses ? [...props.addresses] : []
      }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (nextProps.addresses !== this.props.addresses) {
        this.setState({
          addresses: [...nextProps.addresses]
        })
      }
    }

    isAddingNewAddress = () => {
      const findNewAddress = this.state.addresses.find(v => v.isNew === true)
      if (findNewAddress) {
        return true
      } else {
        return false
      }
    }

    handleStartAddAddress = () => {
      const newAddress = initNewAddress(this.props.account_uid)
      const addresses = [newAddress, ...this.state.addresses]
      this.setState({ addresses })
    }

    handleCancelAddAddress = (index, address) => {
      if (!address.address_uid) {
        const addresses = this.state.addresses.filter((v, i) => i !== index)
        this.setState({ addresses })
      }
    }

    removeAddressUI = async (index, pAddress) => {
      let addresses = [...this.state.addresses]
      addresses.splice(index, 1)
      this.setState({ addresses })
    }

    updateAddressesUI = async (index, pAddress) => {
      const addresses = this.state.addresses.map((v, i) =>
        i === index ? pAddress : v
      )
      this.setState({ addresses })
    }

    render() {
      return (
        <WrappedComponent
          account_uid={this.props.account_uid}
          addresses={this.state.addresses}
          onStartAddAddress={this.handleStartAddAddress}
          onCancelAddAddress={this.handleCancelAddAddress}
          removeAddressUI={this.removeAddressUI}
          updateAddressesUI={this.updateAddressesUI}
          isAddingNewAddress={this.isAddingNewAddress}
          isExpanded={this.props.isExpanded}
          handleEditEnd={this.props.handleEditEnd}
          handleEditStart={this.props.handleEditStart}
          meetingAddress={this.props.meetingAddress}
          handleMeetingAddressChange={this.props.handleMeetingAddressChange}
          activity={this.props.activity}
          input={this.props.input}
          location_type={this.props.location_type}
          handleLocationTypeChange={this.props.handleLocationTypeChange}
          handleQuotationAddressChange={this.props.handleQuotationAddressChange}
          _addrFormat={this.props._addrFormat}
          hideLabel={this.props.hideLabel}
          quotation_address={this.props.quotation_address}
          hasPrimaryAddress={this.props.hasPrimaryAddress}
        />
      )
    }
  }
  SectionAddressesHocInner.propTypes = {
    addresses: arrayOf(shape({})).isRequired,
    account_uid: string,
    isExpanded: bool,
    handleEditEnd: func,
    handleEditStart: func,
    meetingAddress: shape(),
    handleMeetingAddressChange: func,
    activity: shape(),
    input: shape(),
    location_type: string,
    handleLocationTypeChange: func,
    handleQuotationAddressChange: func,
    _addrFormat: func,
    hideLabel: bool,
    quotation_address: shape({}),
    hasPrimaryAddress: shape({})
  }
  SectionAddressesHocInner.defaultProps = {
    meetingAddress: undefined,
    handleMeetingAddressChange: undefined,
    activity: undefined,
    input: undefined,
    location_type: undefined,
    handleLocationTypeChange: undefined,
    _addrFormat: undefined,
    hideLabel: false,
    quotation_address: undefined,
    hasPrimaryAddress: null
  }

  return crmGenericCommandHoc(SectionAddressesHocInner)
}

export default sectionAddressesHoc
