import React from 'react'
import { shape, arrayOf, func, bool } from 'prop-types'
import OpportunityListItem from './opportunity-list-item.jsx'

class OpportunitiesList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    console.log('render OpportunitiesList')
    return (
      <div className="ActivitiesList" style={this.props.style}>
        {this.props.opportunities &&
          this.props.opportunities.map(item => (
            <OpportunityListItem
              key={item.opportunity_uid}
              opportunity={item}
              onOpportunityClick={this.props.onOpportunityClick}
              handleSelectOpportunity={this.props.handleSelectOpportunity}
            />
          ))}
      </div>
    )
  }
}

OpportunitiesList.defaultProps = {
  style: {},
  opportunities: undefined,
  onOpportunityClick: () => {}
}

OpportunitiesList.propTypes = {
  opportunities: arrayOf(shape({})),
  style: shape({}),
  onOpportunityClick: func,
  handleSelectOpportunity: func.isRequired
}

export default OpportunitiesList
