import React from 'react'
import { CrmDropdown } from 'crm-components'

const leadSourceMediumTypeOptions = [
  { value: 'digital', name: 'Digital' },
  { value: 'inbound', name: 'Inbound' },
  { value: 'outbound', name: 'Outbound' },
  { value: 'agents', name: 'Agents' }
]

const LeadSourceTypeSelect = props => (
  <CrmDropdown
    {...props}
    style={{ marginLeft: 5, marginRight: 5, width: 120 }}
    placeholder="Lead Source Type"
    title="lead_channel"
    options={leadSourceMediumTypeOptions}
    addEmptyOption
    emptyOptionLabel="All"
  />
)
export default LeadSourceTypeSelect
