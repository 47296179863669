import React from 'react'
import { string } from 'prop-types'

export const Icon = ({ name, className, ...otherProps }) => (
  <i
    className={`fa fa-${name} ${className}`}
    aria-hidden="true"
    {...otherProps}
  />
)

Icon.propTypes = {
  name: string.isRequired,
  className: string
}

Icon.defaultProps = {
  className: ''
}
