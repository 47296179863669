import React from 'react'
import { number } from 'prop-types'

const AlertsBadge = ({ count }) =>
  count ? <div className="AlertsBadge">{count}</div> : null

AlertsBadge.propTypes = {
  count: number.isRequired
}

export default AlertsBadge
