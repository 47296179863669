import React, { SFC, useState } from 'react'
import { CrmButton, CrmModal, CrmJsonPreview } from 'crm-components'
import { withApollo } from 'react-apollo'
import { SectionHeader, Spacer } from 'util-components'
import gql from 'graphql-tag'
import JobsTable from '../../jobs/jobs-reports-view'

interface Props {
  schedule_uid: string
}
const ScheduleLog: React.SFC<Props> = props => {
  if (!props.schedule_uid) return null
  const [showHistory, setShowHistory] = useState(false)
  const toggle = () => setShowHistory(!showHistory)
  return (
    <div>
      <Spacer height="20px" />
      <SectionHeader
        label={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div>Schedule Logs</div>
            <CrmButton
              onClick={toggle}
              label="History"
              style={{ marginRight: 20 }}
            />
          </div>
        }
        sub
        id="schedule-logs"
      />
      <CrmModal
        isOpen={showHistory}
        title="History"
        showFooter={false}
        size="xl"
        onClose={toggle}
        extraClassNames={{ bodyClassNames: 'util-overflowInitial' }}
      >
        <JobsTable
          reference={props.schedule_uid}
          renderResult={row => {
            if (!row) return null
            return (
              <WrappedPreview
                email_queue_uid={JSON.parse(row).email_queue_uid}
              />
            )
          }}
        />
      </CrmModal>
    </div>
  )
}

const Preview: SFC<any> = ({ email_queue_uid, client }) => {
  const [isLoading, setLoading] = useState(true)
  const [email, setEmail] = useState({ message: '', status: '' })
  const [showRaw, setShowRaw] = useState(false)
  const onLoad = () => {
    setLoading(true)
    if (email_queue_uid) {
      client
        .query({
          query: emailQuery,
          variables: {
            email_queue_uid
          }
        })
        .then(({ data }) => {
          setLoading(false)
          setEmail(data.email_queue)
        })
        .catch(() => setLoading(false))
    }
  }
  return (
    <div>
      <button onClick={onLoad}>Show Email</button>
      <CrmModal
        title="Email Result"
        isOpen={!isLoading}
        onClose={() => setLoading(!isLoading)}
      >
        <div style={{ padding: 15 }}>
          <h4>Email Preview:</h4>
          <div
            dangerouslySetInnerHTML={{ __html: email.message }}
            style={{ marginBottom: 20 }}
          />
          <h4>
            Status: <small>{email.status}</small>
          </h4>
          <h4
            onClick={() => setShowRaw(!showRaw)}
            style={{ cursor: 'pointer' }}
          >
            Show Raw
          </h4>
          {showRaw && <CrmJsonPreview json={email} />}
        </div>
      </CrmModal>
    </div>
  )
}

const WrappedPreview = withApollo(Preview)

const emailQuery = gql`
  query email_queue($email_queue_uid: String) {
    email_queue(email_queue_uid: $email_queue_uid) {
      email_queue_uid
      params
      status
      title
      message
      remark
      created_time
      sent_time
    }
  }
`

export default ScheduleLog
