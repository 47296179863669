import React from 'react'
import { toastr } from 'react-redux-toastr'
import { shape } from 'prop-types'
import { withApollo } from 'react-apollo'
import classNames from 'classnames'
import { loadData } from '../rms/rms-dashboard-utils'
import UserItem from '../rms/rms-user-item.jsx'
import widgetCallHoc from '../../hoc/widgets19/widget-calls-hoc'
import { CrmButton } from 'crm-components' // eslint-disable-line
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingIcon } from 'util-components'
import {
  AreaChart,
  linearGradient,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

class CallsHistoryChart extends React.PureComponent {
  render() {
    const { data } = this.props
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width="100%"
          height="100%"
          data={data}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#f47735" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#f47735" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" label={() => <span />} />
          {/* <YAxis />  */}
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <Tooltip />
          <Area
            type="monotone"
            dataKey="calls"
            stroke="#f47735"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}

class WidgetCalls extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  renderPageSwitch = direction => {
    return (
      <div
        className="CrmWidget-pageSwitcher"
        onClick={() => this.props.onSwitchPage(direction)}
      >
        <FontAwesomeIcon
          icon={direction < 0 ? faChevronLeft : faChevronRight}
        />
      </div>
    )
  }
  renderLoading = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <LoadingIcon />
    </div>
  )
  renderPage1 = () => {
    return (
      <div
        className="util-flexColumn util-fullHeight util-paddingMd"
        style={{ justifyContent: 'stretch', margin: ' 0 10px' }}
      >
        {this.props.page1Data && (
          <React.Fragment>
            <div className="util-flexRow">
              <div style={{ flex: 1, color: '#325d34' }}>Today</div>
              <div style={{ flex: 1, textAlign: 'right', fontWeight: 900 }}>
                {/* <div>Fitments MTD</div> */}
                <div style={{ fontSize: 30 }}>
                  {this.props.page1Data.callToday
                    ? this.props.page1Data.callToday.activity_uid
                    : ''}
                </div>
              </div>
            </div>

            {/* <div style={{ width: 200, height: 200 }}> */}
            <div style={{ flex: 1 }}>
              <CallsHistoryChart
                data={this.props.page1Data && this.props.page1Data.CallsHistory}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
  renderPage2 = () => {
    return (
      <div
        className="util-flexColumn util-fullHeight util-paddingMd"
        style={{ justifyContent: 'stretch', margin: ' 0 10px' }}
      >
        {this.props.page2Data && (
          <React.Fragment>
            <div className="util-flexRow">
              <div style={{ flex: 1, color: '#325d34' }} title="Last 7 days">
                {this.props.page2Data.fitmentsTotal}
              </div>
              <div style={{ flex: 1, textAlign: 'right', fontWeight: 900 }}>
                <div style={{ fontSize: 30 }} title="Today">
                  {this.props.page2Data.fitmentsToday}
                </div>
              </div>
            </div>

            {/* <div style={{ width: 200, height: 200 }}> */}
            <div style={{ flex: 1 }}>
              <FitmentsHistoryChart
                data={this.props.page2Data.fitmentsHistory}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
  renderPage3 = () => {
    return <div className="util-flexRow">Fitments By Owner</div>
  }
  render() {
    console.log('Render widget calls', this.props)
    return (
      <div
        className={classNames(
          'CrmWidget CrmWidget--white',
          'CrmWidget--page' + this.props.currentPage
        )}
        style={{ height: this.props.height || 300 }}
      >
        <div className="CrmWidget-header">
          <div className="CrmWidget-title" onClick={this.props.onRefresh}>
            Calls
          </div>
        </div>
        <div className="CrmWidget-content">
          <div className="util-flexRow util-fullHeight">
            {this.renderPageSwitch(-1)}
            <div className="util-flexGrow">
              <div
                className={classNames(
                  'CrmHorizontalPaginator-container',
                  'CrmHorizontalPaginator-currentPage' + this.props.currentPage
                )}
              >
                <div className="CrmHorizontalPaginator-page CrmHorizontalPaginator-page1">
                  {this.props.page1Data && this.props.page1Data.isLoading
                    ? this.renderLoading()
                    : this.renderPage1()}
                </div>
                <div className="CrmHorizontalPaginator-page CrmHorizontalPaginator-page2">
                  {/* {this.props.page2Data && this.props.page2Data.isLoading ? (
                    this.renderLoading()
                  ) : (
                    this.renderPage2()
                  )} */}
                </div>
                <div className="CrmHorizontalPaginator-page CrmHorizontalPaginator-page3">
                  {/* {this.props.page3Data && this.props.page3Data.isLoading ? (
                    this.renderLoading()
                  ) : (
                    this.renderPage3()
                  )} */}
                </div>
              </div>
            </div>

            {this.renderPageSwitch(1)}
          </div>
        </div>
        {/* <CrmButton label="Refr" onClick={this.handleRefresh} /> */}
      </div>
    )
  }
}
WidgetCalls.propTypes = {
  currentUser: shape({}).isRequired
}

export default widgetCallHoc(WidgetCalls)
