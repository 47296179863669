import React from 'react'
import { shape, func } from 'prop-types'
import styles from './funnel-item.scss'

const COLORS = ['#65b7f3', '#b2d28c', '#f47735', '#f67960', '#82c6da']

const leadStatuses = {
  color: COLORS[0],
  name: 'Raw',
  sections: [
    { name: 'New', code: 'new', color: '#b2d28c30' },
    { name: 'New', code: 'touched', color: '#b2d28c60' },
    { name: 'Contacted', code: 'contacted', color: '#b2d28c90' }
  ]
}
const validStatuses = {
  color: COLORS[2],
  name: 'Valid Leads',
  sections: [
    { name: 'Validated', code: 'validated', color: '#65b7f330' },
    { name: 'Needs Analysis', code: 'needs_analysis', color: '#65b7f350' }
  ]
}
const prospectStatuses = {
  color: COLORS[3],
  name: 'Prospects',
  sections: [
    { name: 'Opportunity', code: 'opportunity', color: '#65b7f390' },
    { name: 'Quoted (and presented)', code: 'quoted', color: '#65b7aa' }
  ]
}

const winStatuses = {
  color: COLORS[3],
  name: 'Won',
  sections: [
    {
      code: 'closing',
      name: 'Closing (won, waiting for fitment)',
      color: '#f5874c'
    }
  ]
}

class FunnelItem extends React.PureComponent {
  renderBlock(block, w1, w2) {
    let total = 0
    block.sections.forEach(section => {
      total += this.getValue(section.code)
    })
    const x1 = Number(50 - w1 / 2).toFixed(0)
    const x2 = Number(50 + w1 / 2).toFixed(0)
    const x3 = Number(50 + w2 / 2).toFixed(0)
    const x4 = Number(50 - w2 / 2).toFixed(0)
    // console.log(x1, x2, x3, x4)
    const clipPath = `polygon(${x1}% 0%, ${x2}% 0%, ${x3}% 100%, ${x4}% 100%)`

    return (
      <React.Fragment>
        <div
          style={{
            flex: block.sections.length,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            clipPath: clipPath,
            WebkitClipPath: clipPath,
            position: 'relative'
            // `url(#path-${block.name})`
          }}
        >
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <div>{total}</div>
          </div>
          {block.sections.map(s => {
            const val = this.getValue(s.code)
            const title = s.name + ' ' + val + ' / ' + block.name + ' ' + total
            return (
              <div
                className={styles.FunnelItem__Section}
                title={title}
                key={s.code}
                style={{
                  minHeight: 10,
                  flex: val === 0 ? 1 : val,
                  backgroundColor: s.color
                }}
                onClick={() =>
                  this.props.onStatusClick({
                    statusCode: s.code,
                    userUid: this.props.data.userUid
                  })
                }
              >
                {/* {val} */}
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
  getValue(type) {
    return this.props.data.statuses && this.props.data.statuses[type]
      ? this.props.data.statuses[type]
      : 0
  }
  getTotal(block) {
    let total = 0

    block.sections.forEach(section => {
      total += this.getValue(section.code)
    })
    return total
  }
  render() {
    const { data } = this.props
    const totalLeads = this.getTotal(leadStatuses)
    const totalProspects = this.getTotal(prospectStatuses)
    const totalValid = this.getTotal(validStatuses)
    const totalWins = this.getTotal(winStatuses)
    console.log('Render Funnel Item', data)
    const maxCount = Math.max(totalLeads, totalProspects, totalWins, totalValid)
    // console.log(' maxCount ', maxCount)
    // console.log('totalLeads', totalLeads, totalProspects, totalWins)
    return (
      <div className="CrmMdCard" style={{ padding: 10 }}>
        {data.name} / {data.department__name}
        <div
          className="util-flexColumn"
          style={{ alignItems: 'center', height: 200, padding: 10 }}
        >
          {this.renderBlock(
            leadStatuses,
            Math.max(5, (totalLeads / maxCount) * 100),
            Math.max(5, (totalValid / maxCount) * 100)
          )}
          {this.renderBlock(
            validStatuses,
            Math.max(5, (totalValid / maxCount) * 100),
            Math.max(5, (totalProspects / maxCount) * 100)
          )}
          {this.renderBlock(
            prospectStatuses,
            Math.max(5, (totalProspects / maxCount) * 100),
            Math.max(5, (totalWins / maxCount) * 100)
          )}
          {this.renderBlock(
            winStatuses,
            Math.max(5, (totalWins / maxCount) * 100),
            0
          )}
        </div>
      </div>
    )
  }
}
FunnelItem.propTypes = {
  data: shape(),
  onStatusClick: func.isRequired
}
export default FunnelItem
