import React from 'react'
import { node, string } from 'prop-types'

const FormContent = props => (
  <div className={`util-contentRight util-textCenter ${props.className}`}>{props.children}</div>
)

FormContent.propTypes = {
  children: node.isRequired,
  className: string
}

FormContent.defaultProps = {
  className: ''
}

export default FormContent
