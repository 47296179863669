/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { shape, func, string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import {
  CrmButton,
  CrmValidatedField,
  CrmTextInput,
  CrmProductsSelect
} from 'crm-components'

class DocumentFormItems extends PureComponent {
  handleCopyFromOpportunity = () => {
    const opportunity = this.props.account.opportunities.find(
      o => o.opportunity_uid === this.props.opportunityUid
    )
    console.dir(opportunity)
    if (opportunity && opportunity.items) {
      opportunity.items.forEach(oitem => {
        this.props.fields.push({
          ...oitem,
          value_net: oitem.price,
          subscription_net: oitem.subscription_price
        })
      })
    }
  }

  sumQuantity = () => {
    let sum = 0
    for (let i = 0; i < this.props.fields.length; i++) {
      const item = this.props.fields.get(i)
      sum += item.quantity ? Number(item.quantity) : 0
    }
    return sum
  }

  sumtotal = (index, field) => {
    const item = this.props.fields.get(index)
    let price = 0
    if (field && item[field]) {
      price = parseFloat(item[field]) || 0
    }
    return price * (parseFloat(item.quantity) || 0)
  }

  sumAll = value => {
    console.log('sumAll')
    console.log(value)
    let total = 0
    for (let i = 0; i < this.props.fields.length; i++) {
      total += this.sumtotal(i, value)
    }

    return total
  }

  showTotal = () => {
    if (this.props.fields.length > 0) {
      return (
        <tr className="CrmFlatTable-tbody-tr">
          <td className="CrmFlatTable-tbody-td util-textRight">
            <FormattedMessage
              id="crm.ui.account.document.total"
              defaultMessage="Total:"
            />
          </td>
          <td className="CrmFlatTable-tbody-td util-textRight">
            {this.sumQuantity()}
          </td>
          <td className="CrmFlatTable-tbody-td util-textRight">
            {this.sumAll('value_net')}
          </td>
          <td className="CrmFlatTable-tbody-td util-textRight">
            {this.sumAll('subscription_net')}
          </td>
          <td />
        </tr>
      )
    }

    return ''
  }
  handleClickAdd = () => {
    this.props.fields.push({ value_currency_code: 'THB' })
  }
  handleDeleteItem = event => {
    const index = event.currentTarget.id
    this.props.onDeleteItem(this.props.fields.get(index))
    this.props.fields.remove(index)
  }

  render() {
    const { fields } = this.props
    return (
      <div>
        <p>{''}</p>
        <div className="row  util-marginBottom">
          <div className="col-md-12 util-textRight">
            <CrmButton
              type="button"
              label={
                <FormattedMessage
                  id="crm.ui.button.add_item"
                  defaultMessage="Add Item"
                />
              }
              icon="plus"
              onClick={this.handleClickAdd} // eslint-disable-line
            />
            <div className="col-md-4">
              {this.props.opportunityUid && (
                <CrmButton
                  label={
                    <FormattedMessage
                      id="crm.ui.button.copy_items_from_opportunity"
                      defaultMessage="Copy Items from Opportunity"
                    />
                  }
                  onClick={this.handleCopyFromOpportunity}
                />
              )}
            </div>
          </div>
        </div>
        <table className="CrmFlatTable">
          <thead className="CrmFlatTable-thead">
            <tr>
              <th className="CrmFlatTable-thead-th util-alignLeft" width="40%">
                <FormattedMessage
                  id="crm.ui.account.document.product"
                  defaultMessage="Product"
                />
              </th>
              <th className="CrmFlatTable-thead-th util-alignRight" width="20%">
                <FormattedMessage
                  id="crm.ui.account.document.quantity"
                  defaultMessage="Quantity"
                />
              </th>
              <th className="CrmFlatTable-thead-th util-alignRight" width="20%">
                <FormattedMessage
                  id="crm.ui.account.document.hardware_price"
                  defaultMessage="Hardware Price"
                />
              </th>
              <th className="CrmFlatTable-thead-th util-alignRight" width="20%">
                <FormattedMessage
                  id="crm.ui.account.document.subscription_price"
                  defaultMessage="Subscription Price"
                />
              </th>
              <th className="CrmFlatTable-thead-th util-alignRight" width="10%">
                <FormattedMessage
                  id="crm.ui.account.document.options"
                  defaultMessage="Options"
                />
              </th>
              {/* <th className="CrmFlatTable-thead-th util-alignRight" width="20%">
              Currency
            </th> */}
            </tr>
          </thead>
          <tbody>
            {fields.map((item, index) => (
              // eslint-disable-next-line
              <tr className="CrmFlatTable-tbody-tr" key={index}>
                <td className="CrmFlatTable-tbody-td">
                  <Field
                    name={`${item}.document_item_uid`}
                    component="hidden"
                    type="text"
                  />

                  <CrmValidatedField
                    name={`${item}.product_uid`}
                    component={CrmProductsSelect}
                    componentProps={{
                      labelField: 'name',
                      placeholder: (
                        <FormattedMessage
                          id="crm.ui.account.document.product_name"
                          defaultMessage="Product Name"
                        />
                      )
                    }}
                    isRequired
                  />
                </td>
                <td className="CrmFlatTable-tbody-td">
                  <CrmValidatedField
                    component={CrmTextInput}
                    componentProps={{
                      placeholder: 'Quantity',
                      placeholderId: 'crm.ui.account.document.quantity'
                    }}
                    name={`${item}.quantity`}
                  />
                </td>
                <td className="CrmFlatTable-tbody-td">
                  <CrmValidatedField
                    component={CrmTextInput}
                    componentProps={{
                      placeholder: 'Value Net',
                      placeholderId: 'crm.ui.account.document.value_net'
                    }}
                    name={`${item}.value_net`}
                  />
                </td>
                <td className="CrmFlatTable-tbody-td">
                  <CrmValidatedField
                    component={CrmTextInput}
                    componentProps={{
                      placeholder: 'Subscription net',
                      placeholderId: 'crm.ui.account.document.subscription_net'
                    }}
                    name={`${item}.subscription_net`}
                  />
                </td>

                {/* <div className="DocumentItem-valuetax">
              <CrmValidatedField
                component={CrmTextInput}
                componentProps={{ placeholder: 'Subscription tax' }}
                name={`${item}.subscription_tax`}
              />
            </div>
            <div className="DocumentItem-valuetax">
              <CrmValidatedField
                component={CrmTextInput}
                componentProps={{ placeholder: 'Subscription length' }}
                name={`${item}.subscription_length`}
              />
            </div> */}
                {/* <td className="CrmFlatTable-tbody-td">
              <CrmValidatedField
                name={`${item}.value_currency_code`}
                component={CrmDropdown}
                componentProps={{
                  placeholder: 'Currency',
                  options: ['THB']
                }}
                isRequired
              />
            </td> */}

                {/* <span>Total : {this.sumtotal(index)} </span> */}
                <td className="CrmFlatTable-tbody-td">
                  <CrmButton
                    id={index}
                    type="button"
                    iconButton
                    icon="trash"
                    onClick={this.handleDeleteItem}
                  />
                </td>
              </tr>
            ))}
            {this.showTotal()}
          </tbody>
        </table>
      </div>
    )
  }
}

DocumentFormItems.propTypes = {
  onDeleteItem: func.isRequired,
  fields: shape({}),
  account: shape({}).isRequired,
  opportunityUid: string
}

DocumentFormItems.defaultProps = {
  fields: {},
  opportunityUid: undefined
}

export default DocumentFormItems
