import React from 'react'
import { number, oneOf, string } from 'prop-types'
import { FormattedRelativeTime } from 'react-intl'

import { leftPad } from 'util-functions/time-utils'

function msToTimeSince(ms) {
  let n = Date.now() - ms

  n /= 1000
  if (n < 60) return `${Math.round(n)} sec`

  n /= 60
  if (n < 60) return `${Math.round(n)} min`

  n /= 60
  if (n < 24) return `${Math.round(n)} hrs`

  n /= 24
  return `${Math.round(n)} days`
}

function msToTimeSinceHoursMins(ms, colon = false) {
  const mins = Math.abs(ms / 1000 / 60)
  const sMins = Math.floor(mins % 60)
  const sHours = (mins / 60) | 0 // Truncate to 0
  const neg = ms < 0 ? '-' : ''

  return colon
    ? `${neg}${sHours}:${leftPad(sMins, 2)}`
    : `${neg}${sHours}hrs ${sMins}min`
}

function strip3Chars(str) {
  return <span>{str.length > 3 ? str.slice(3) : str}</span>
}

const FormattedDuration = props => {
  const type = props.type.split('Colon')
  switch (type[0]) {
    case 'hourMin':
      return (
        <span className={props.className}>
          {msToTimeSinceHoursMins(props.value, type[1] === '')}
        </span>
      )
    case 'minimal':
      return (
        <span className={props.className}>{msToTimeSince(props.value)}</span>
      )
    default:
      return (
        <FormattedRelativeTime
          value={props.value}
          initialNow={props.initialNow}
          updateInterval={0}
        >
          {strip3Chars}
        </FormattedRelativeTime>
      )
  }
}

FormattedDuration.propTypes = {
  value: number.isRequired,
  initialNow: number,
  type: oneOf(['hourMin', 'hourMinColon', 'minimal', '']),
  className: string
}

FormattedDuration.defaultProps = {
  initialNow: 0,
  type: '',
  className: ''
}

export default FormattedDuration
