import React, { useState, SFC } from 'react'
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form'
import { CrmJsonPreviewModal } from 'crm-components'
import { connect } from 'react-redux'
import validate from 'crm-utils/validates'
import {
  useCrmToastr,
  CrmToastrType,
  CrmToastrPosition
} from '@cartrack-crm/ui/src/toastrs'
import { useMutation } from '@apollo/react-hooks'
import Spacer from '../../../../util-components/spacer.jsx'
import SectionHeader from '../../../../util-components/section-header.jsx'

import { CrmTextInput, CrmButton } from 'crm-components'
import RecipientForm from './recipient-form'
import ScheduleForm from './time-scheduler-form'
import ReportForm from './report-form'
import gql from 'graphql-tag'
import { change } from 'redux-form'

interface ScheduleConfigFormInnerProps {
  handleSubmit: any
  formValues: any
  isSaving: boolean
  scheduleUid: string
  onRunCommand: Function
  history: any
  initialValues: any
  refetch?: Function
  changeFieldValue: Function
}

const ScheduleConfigFormInner: SFC<ScheduleConfigFormInnerProps> = (props) => {
  const [isSending, setSending] = useState(false)
  const [showToastr, hideToastr] = useCrmToastr()
  const [sendSchedule] = useMutation(sendNowQL, {
    variables: {
      schedule_uid: props.scheduleUid
    }
  })

  const getUpdateCommand = (payload) => {
    const scheduleUid = props.scheduleUid
    const commandType = 'ScheduleReport.update'
    const command = {
      type: commandType,
      aggregate_uid: scheduleUid,
      aggregate_type: 'ScheduleReport',
      payload: {
        schedule_uid: scheduleUid,
        ...payload
      }
    }
    return command
  }
  const onProcessDeleteCommand = async () => {
    const command = getUpdateCommand({ is_deleted: true })
    try {
      await props.onRunCommand(command)
      showToastr(CrmToastrType.success, 'Schedule has been deleted!')
      props.history.push('/crm/manager/schedule')
    } catch (err) {
      showToastr(CrmToastrType.error, 'There was problem saving data')
    }
  }

  const onProcessDisableCommand = async () => {
    const command = getUpdateCommand({ is_disabled: true })
    try {
      await props.onRunCommand(command)
      showToastr(CrmToastrType.success, 'Schedule has been disabled!')
      props.refetch()
    } catch (err) {
      showToastr(CrmToastrType.error, 'There was problem saving data')
    }
  }

  const onProcessEnableCommand = async () => {
    const command = getUpdateCommand({ is_disabled: false })
    try {
      await props.onRunCommand(command)
      showToastr(CrmToastrType.success, 'Schedule has been enabled!')
      props.refetch()
    } catch (err) {
      showToastr(CrmToastrType.error, 'There was problem saving data')
    }
  }

  const handleEnable = async () => {
    const option = {
      btnPrimaryTitle: 'Enable',
      btnSecondaryTitle: 'Cancel',
      onBtnPrimary: onProcessEnableCommand,
      timeToShow: null,
      position: CrmToastrPosition.middle
    }
    showToastr(
      CrmToastrType.info,
      'Do you want to enable this schedule?',
      option
    )
  }

  const handleDisable = async () => {
    const option = {
      btnPrimaryTitle: 'Disable',
      btnSecondaryTitle: 'Cancel',
      onBtnPrimary: onProcessDisableCommand,
      timeToShow: null,
      position: CrmToastrPosition.middle
    }
    showToastr(
      CrmToastrType.info,
      'Do you want to disable this schedule?',
      option
    )
  }

  const handleDelete = async () => {
    const options = {
      btnPrimaryTitle: 'Delete',
      btnSecondaryTitle: 'Cancel',
      onBtnPrimary: onProcessDeleteCommand,
      onSecondary: () => hideToastr(),
      timeToShow: null,
      position: CrmToastrPosition.middle
    }
    showToastr(
      CrmToastrType.error,
      'Do you want to delete this schedule?',
      options
    )
  }
  const handleSendReport = async () => {
    setSending(true)
    try {
      await sendSchedule()
      showToastr(
        CrmToastrType.success,
        'Email was sent. Please check your mailbox.'
      )
    } catch (error) {
      console.log(error)
      showToastr(CrmToastrType.error, 'Something when wrong!')
    }
    setSending(false)
  }
  const handleSubmit = props.handleSubmit

  const handleChangeFilterValues = (index, filterValues) => {
    props.changeFieldValue('parts[' + index + '].filter', { ...filterValues })
  }

  console.log('ScheduleConfigFormInnerScheduleConfigFormInner', props)

  return (
    <div>
      <form onSubmit={handleSubmit} className="userForm">
        <fieldset className="Form-fieldset">
          <SectionHeader
            label="Schedule"
            action={
              <CrmJsonPreviewModal json={props.formValues} buttonName="JSON" />
            }
            sub
            id="schedule-info-section"
          />
          <div className="row util-marginBottom">
            <div className="col-md-6">
              <Field
                name="name"
                component={CrmTextInput}
                type="text"
                placeholder="Name"
                validate={[validate.required]}
              />
            </div>
          </div>

          <Spacer height="20px" />
          <FieldArray name="recipients" component={RecipientForm} />
          <FieldArray name="schedules" component={ScheduleForm} />
          <FieldArray
            name="parts"
            component={ReportForm}
            props={{
              handleChangeFilterValues: handleChangeFilterValues
            }}
          />
          <Spacer height="20px" />
          <div className="row">
            <div className="col-md-12">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <CrmButton
                    grouped
                    enable={!props.isSaving}
                    isSaving={props.isSaving}
                    icon="save"
                    label="Save"
                    type="submit"
                  />
                  {props.scheduleUid !== 'new' && (
                    <CrmButton
                      grouped
                      icon="trash"
                      label="Delete"
                      onClick={handleDelete}
                    />
                  )}
                  {props.scheduleUid !== 'new' &&
                    (props.initialValues.is_disabled ? (
                      <CrmButton
                        grouped
                        icon="play"
                        label="Enable"
                        onClick={handleEnable}
                      />
                    ) : (
                      <CrmButton
                        grouped
                        icon="stop"
                        label="Disable"
                        onClick={handleDisable}
                      />
                    ))}
                </div>
                <div>
                  {props.scheduleUid !== 'new' && (
                    <CrmButton
                      grouped
                      icon="mail-bulk"
                      isSaving={isSending}
                      enable={!isSending}
                      label="Send Now!"
                      onClick={handleSendReport}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {props.scheduleUid !== 'new' &&
            props.initialValues.created_by_user?.full_name && (
              <div className="row">
                <div className="col-md-12">
                  <h5 style={{ textAlign: 'right', color: '#666' }}>
                    Created by {props.initialValues.created_by_user?.full_name}
                  </h5>
                </div>
              </div>
            )}
        </fieldset>
      </form>
    </div>
  )
}

const ScheduleConfigReduxForm = reduxForm({
  form: 'crmScheduleConfigForm',
  enableReinitialize: true
})(ScheduleConfigFormInner)

function mapStateToProps(state) {
  return {
    formValues: getFormValues('crmScheduleConfigForm')(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeFieldValue: (field, value) =>
      dispatch(change('crmScheduleConfigForm', field, value))
  }
}

const ScheduleConfigReduxFormwithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleConfigReduxForm)

export default ScheduleConfigReduxFormwithState

const sendNowQL = gql`
  mutation schedule_report_send($schedule_uid: String) {
    schedule_report_send(schedule_uid: $schedule_uid) {
      email_queue_uid
    }
  }
`
