import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CrmDropdown, CrmTextInput, CrmFormInputDropdown } from 'crm-components'
import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import ContactDetailItemInlineEditable from './contact-detail-item-inline-editable.jsx'
import { CrmEmailInput } from '@cartrack-crm/ui'
import { toastr } from 'react-redux-toastr'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'

class ContactDetailItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      contactDetail: { ...props.contactDetail },
      contactArchivedList: { ...props.contactArchivedList }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contactDetail !== this.props.contactDetail) {
      this.setState({
        contactDetail: { ...nextProps.contactDetail }
      })
    }
  }

  handleCountryCodeChange = async country_code => {
    const contactDetail = { ...this.state.contactDetail }
    contactDetail.country_code = country_code

    this.setState(
      {
        contactDetail
      },
      () => {
        this.props.onPersonContactDetailsFieldChange(
          this.props.index,
          contactDetail
        )
      }
    )
  }

  handleKindChange = async kind => {
    const contactDetail = { ...this.state.contactDetail }
    if (contactDetail.kind !== kind) {
      contactDetail.value = ''
      contactDetail.country_code = ''
    }
    contactDetail.kind = kind
    this.setState(
      {
        contactDetail
      },
      () => {
        this.props.onPersonContactDetailsFieldChange(
          this.props.index,
          contactDetail
        )
      }
    )
  }

  handleValueChange = event => {
    const contactDetail = { ...this.state.contactDetail }
    contactDetail.value = event.currentTarget.value
    this.setState(
      {
        contactDetail
      },
      () => {
        this.props.onPersonContactDetailsFieldChange(
          this.props.index,
          contactDetail
        )
      }
    )
  }

  handleRemove = () => {
    if (this.props.contactGroup === 'personContactDetails') {
      this.props.onRemovePersonContactDetail(this.props.index)
    }
    if (this.props.contactGroup === 'accountContactDetails') {
      this.props.onRemoveAccountContactDetail(
        this.props.index,
        contactDetail.name
      )
    }
  }

  handleRemoveArchivedContactDetailUI = () => {
    const { contactDetail } = this.state
    const toastrConfirmOptions = {
      onOk: async () => {
        try {
          const res = await this.props.onRemoveArchivedContactDetail(
            this.props.index,
            contactDetail.name
          )
          toastr.success('Contact person deleted')
        } catch (err) {
          toastr.error('Delete person error')
        }
      }
    }
    toastr.confirm(
      'Are you sure you want to delete this contact person ? ',
      toastrConfirmOptions
    )
  }

  handleCancelInlineEdit = () => {
    if (this.state.contactDetail.isNewInlineItem) {
      this.handleRemove()
    }
  }

  render() {
    const { contactDetail } = this.state
    const options = this.props.getKindOptions()
    const icons = this.props.getKindIcons()
    const countries = this.props.getCountriesOptions()
    const disabled = true

    console.log("contactDetail-item", this.props)
    return (
      <React.Fragment>
        {contactDetail.isNew || contactDetail.isEditing ? (
          <div className="row">
            <div className="row col-md-12">
              <div className="col-md-6">
                <CrmDropdown
                  title={this.props.intl.formatMessage({
                    id: 'crm.account.contact_type',
                    defaultMessage: 'Contact Type'
                  })}
                  placeholder={
                    <FormattedMessage
                      id="crm.account.contact_type"
                      defaultMessage="Contact Type"
                    />
                  }
                  options={options}
                  addEmptyOption
                  input={{
                    value: contactDetail.kind,
                    onChange: this.handleKindChange
                  }}
                />
              </div>
              <div className="col-md-6">
                {!contactDetail.kind || contactDetail.kind !== 'email' && contactDetail.kind !== 'line' &&
                  <CrmFormInputDropdown
                    title={this.props.intl.formatMessage({
                      id: 'crm.account.country_code',
                      defaultMessage: 'Country Code'
                    })}
                    placeholder={
                      <FormattedMessage
                        id="crm.account.country_code"
                        defaultMessage="Country Code"
                      />
                    }
                    disabled={
                      contactDetail.kind === 'email' ? disabled : !disabled
                    }
                    options={countries}
                    input={{
                      value:
                        contactDetail.kind === 'email'
                          ? ''
                          : contactDetail.country_code,
                      onChange: this.handleCountryCodeChange
                    }}
                    addEmptyOption
                    emptyOptionLabel="-"
                 />
                }
              </div>
            </div>
            <div className="row col-md-12">
              <div className="col-md-11">
                {contactDetail.kind !== 'email' && (
                  <CrmTextInput
                    placeholder={
                      <FormattedMessage
                        id="crm.account.contact"
                        defaultMessage="Contact"
                      />
                    }
                    input={{
                      value: contactDetail.value,
                      onChange: this.handleValueChange
                    }}
                  />
                )}
                {contactDetail.kind === 'email' && (
                  <CrmEmailInput
                    placeholder={
                      <FormattedMessage
                        id="crm.account.contact"
                        defaultMessage="Email"
                      />
                    }
                    input={{
                      value: contactDetail.value,
                      onChange: this.handleValueChange
                    }}
                  />
                )}
              </div>
              <div className="col-md-1 util-marginAuto">
                <FontAwesomeIcon
                  className="util-hooverable util-pointer util-textRed"
                  icon={faTimesCircle}
                  onClick={this.handleRemove}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12">
            <ContactDetailItemInlineEditable
              value={this.state.contactDetail}
              editable={true}
              options={options}
              icons={icons}
              startEdit={this.state.contactDetail.isNewInlineItem}
              contactArchivedList={this.state.contactArchivedList}
              onCancelInlineEdit={this.handleCancelInlineEdit}
              onRemove={this.handleRemove}
              onRemoveArchivedContactUI={
                this.handleRemoveArchivedContactDetailUI
              }
              index={this.props.index}
              countries={countries}
            />
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default injectIntl(ContactDetailItem)
