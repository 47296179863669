/* eslint-disable camelcase */
import React from 'react'
import { withApollo } from 'react-apollo'
import { shape, func } from 'prop-types'
import { loadData } from './rms-dashboard-utils'
import withLeadsPopupHoc from 'crm-modules/dashboard/components/_with-leads-popup-hoc.jsx'
import { buildMeetingsFilter } from './rms-dashboard-meetings'

const RmsDashboardHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = { selectedSections: {} }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (
        nextProps !== undefined &&
        JSON.stringify(nextProps.analyticsContext) !==
          JSON.stringify(this.props.analyticsContext)
      ) {
        this.setState({}, () => {
          this.handleRefresh()
        })
      }
    }
    handleCurrentStepClick = selectedStepType => {
      this.setState({ selectedStepType })
    }
    handleRefresh = async () => {
      const loadedData = await loadData(this.props, this.state.selectedSections)
      if (loadedData) {
        this.setState({ users: loadedData.data, ...loadedData })
      }
    }
    toggleIsLoading(val, handler) {
      return this.setState({ isLoading: val }, handler)
    }

    handleTogleSection = (event, code) => {
      if (code) {
        const selectedSections = { ...this.state.selectedSections }
        selectedSections[code] = !selectedSections[code]
        this.setState({ selectedSections })
      }
    }
    parseData(result) {
      this.setState({ data: result.data }, () => {
        this.toggleIsLoading(false)
      })
    }

    handleShowDetailsPopup = params => {
      let filters = { ...params.filters }
      if (params.type === 'activities') {
        const contextFilter = buildMeetingsFilter(this.props)
        filters = { ...filters, ...contextFilter }
      }
      this.props.onShowLeadsPopup({ type: params.type, filters })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          users={this.state.users}
          onRefresh={this.handleRefresh}
          selectedSections={this.state.selectedSections}
          onTogleSection={this.handleTogleSection}
          filterMeetings={this.state.filterMeetings}
          onShowLeadsPopup={this.handleShowDetailsPopup}
        />
      )
    }
  }

  Inner.propTypes = {
    analyticsContext: shape({}).isRequired,
    client: shape({}).isRequired,
    onShowLeadsPopup: func.isRequired
  }

  return withApollo(withLeadsPopupHoc(Inner))
}
export default RmsDashboardHoc
