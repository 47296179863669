/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { shape, func, arrayOf, string } from 'prop-types'
import { MODE_DASHBOARDS } from './analytics-dashboards-hoc'
import {
  getMenuDashboards,
  getMenuManagementReports
} from 'crm-duxs/analytics/analytics-reducer'
import { getDashboards } from 'crm-duxs/management/management-dashboard-reducer'
import { CrmButton } from 'crm-components'
import withCrmComponentUtils from 'crm-utils/crm-component-util'
class AnalyticsCenterMenu extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expandedItemCode: undefined,
      showHidden: false
    }
  }

  toggleShowHidden = () => {
    this.setState({ showHidden: !this.state.showHidden })
  }
  componentDidMount() {
    if (this.props.initialDashboard) {
      this.changeDashboard(this.props.initialDashboard)
      const item = this._findItembyCode(this.props.initialDashboard)
      if (item) {
        this.initialDashboardUid = item.dashboard_uid
        const activeSection = this._findSectionItembyCode(
          this.props.initialDashboard
        )

        if (activeSection && activeSection.code)
          this.initialDashboardSection = activeSection.code
      }
    }
  }

  handleClickMenuItem = event => {
    const key = event.currentTarget.id
    this.changeDashboard(key)
  }

  changeDashboard = key => {
    const item = this._findItembyCode(key)
    if (item && item.type === 'section') {
      if (item.code === this.state.expandedItemCode) {
        this.setState({ expandedItemCode: undefined })
      } else {
        this.setState({ expandedItemCode: item.code })
      }
    }
    if (item?.dashboard_uid || item?.component_uid) {
      this.props.onMenuItemClick(item)
    }
  }

  _getMenu() {
    if (this.props.mode === MODE_DASHBOARDS) {
      const currentMenu = [
        ...this.props.menuDashboards,
        ...this.props.menuManagementReports
      ]
      return this.props.mergeMenuWithPublishedComponent(currentMenu)
    }
    if (this.props.mode === 'MY_DASHBOARDS') {
      return this.props.dashboards
        ? this.props.dashboards
            .filter(d => d.access_level === 'user')
            .map(d => ({
              ...d,
              code: d.code ? d.code : d.dashboard_uid,
              label: d.name,
              type: 'item'
            }))
        : []
    }
    return []
  }
  _findItembyCode(code) {
    const menu = this._getMenu()
    const itemsWithSubitems = [].concat.apply(
      menu,
      menu.map(section => (section.items ? section.items : []))
    )
    let item = itemsWithSubitems.find(item => item.code === code)
    const load_component = item?.load_component
    if (load_component) {
      const srv_menu = menu.find(el => el.code === 'more_dashboard')
      if (srv_menu) {
        item = srv_menu.items.find(el => el.code === load_component.code) || []
      }
    }
    return item
  }

  _findSectionItembyCode(code) {
    const menu = this._getMenu()
    let activeSection
    menu.forEach(obj => {
      if (Array.isArray(obj.items)) {
        const item = obj.items.find(item => item.code === code)
        if (item) {
          activeSection = obj
        }
      }
    })

    return activeSection
  }

  activeDashboardUid = () => {
    if (this.props.selectedDashboardUid) {
      return this.props.selectedDashboardUid
    } else if (this.initialDashboardUid) {
      return this.initialDashboardUid
    }

    return undefined
  }

  activeDashboardCode = () => {
    if (this.state.expandedItemCode) {
      return this.state.expandedItemCode
    } else if (this.initialDashboardSection) {
      return this.initialDashboardSection
    }

    return undefined
  }

  _renderMenuItem = item => {
    const isExpanded = this.activeDashboardCode() === item.code
    const activeDashboardUid = this.activeDashboardUid()

    if (item.hidden && !this.state.showHidden) {
      return <span key={item.code} />
    }
    return (
      <div
        key={item.code}
        className={`AnalyticsCenterMenu-item
        ${
          activeDashboardUid && item.dashboard_uid === activeDashboardUid
            ? 'AnalyticsCenterMenu-item--selected'
            : ''
        }
        ${item.type === 'section' ? 'AnalyticsCenterMenu-section' : ''}
        ${item.type === 'item' ? 'AnalyticsCenterMenu-subItem' : ''}
        ${isExpanded ? 'AnalyticsCenterMenu-item--expanded' : ''}
        `}
      >
        <div
          className={`AnalyticsCenterMenu-item-title
          ${
            item.type === 'section' ? 'AnalyticsCenterMenu-section-title' : ''
          }`}
          id={item.code}
          onClick={this.handleClickMenuItem}
        >
          <div>{item.label}</div>
        </div>
        {isExpanded && item.items && (
          <div className="AnalyticsCenterMenu-item-subItems">
            {item.items.map(this._renderMenuItem)}
          </div>
        )}
      </div>
    )
  }

  handleClickAddDashboard = () => {}

  render() {
    const menu = this._getMenu()
    return (
      <div className="AnalyticsCenterMenu">
        {menu && menu.map(this._renderMenuItem)}
        {this.props.mode === 'MY_DASHBOARDS' && (
          <React.Fragment>
            <div className="util-paddingMd util-textCenter">
              <CrmButton
                label="Add Dashboard"
                onClick={this.props.onStartAddingDashboard}
              />
            </div>
          </React.Fragment>
        )}
        <div style={{ height: '100%' }} />
        <div
          style={{ height: 20, width: '100%' }}
          onDoubleClick={this.toggleShowHidden}
        />
      </div>
    )
  }
}

AnalyticsCenterMenu.propTypes = {
  menuDashboards: arrayOf(shape()).isRequired,
  menuManagementReports: arrayOf(shape()).isRequired,
  onMenuItemClick: func,
  mode: string.isRequired,
  onStartAddingDashboard: func.isRequired,
  dashboards: arrayOf(shape()),
  selectedDashboardUid: string,
  initialDashboard: string,
  mergeMenuWithPublishedComponent: func
}

AnalyticsCenterMenu.defaultProps = {
  onFilterValueChange: () => {},
  onMenuItemClick: () => {},
  selectedDashboardUid: undefined,
  initialDashboard: undefined
}

function mapStateToProps(state) {
  return {
    menuDashboards: getMenuDashboards(state),
    menuManagementReports: getMenuManagementReports(state)
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCrmComponentUtils(AnalyticsCenterMenu))
