import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape, bool, func, arrayOf } from 'prop-types'
import { propTypes } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import CrmAddressSelect from '../address/crm-address-select.jsx'
import MeetingStatusWidget from './meeting-status-widget.jsx'
import MeetingFormParticipantsFields from './meeting-form-participants-fields.jsx'
import { getActivityTypesByMediumCode } from 'crm-duxs/activities-reducer'
import {
  CrmTextInput,
  CrmValidatedField,
  CrmDayHourPickerInput,
  CrmUsersSelect,
  CrmDictionaryValueSelect,
  CrmDropdown
} from 'crm-components'

class MeetingFormFields extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
    this.meetingSourceSelector = props => (
      <CrmDictionaryValueSelect
        {...props}
        dictionaryCode="crm.meeting.source_type"
        placeholder={
          <FormattedMessage
            id="crm.ui.activity.form.meeting_source"
            defaultMessage="Meeting Source"
          />
        }
      />
    )
    this.arrangedByUserSelector = props => (
      <CrmUsersSelect
        {...props}
        permissionCode="all"
        placeholder={
          <FormattedMessage
            id="crm.ui.activity.form.meeting_arranged_by"
            defaultMessage="Meeting Arranged By"
          />
        }
      />
    )
    this.leadRmUserSelector = props => (
      <CrmUsersSelect
        {...props}
        placeholder={
          <FormattedMessage
            id="crm.ui.activity.form.lead_rm"
            defaultMessage="LEAD RM"
          />
        }
      />
    )
  }

  _addrFormat(addr) {
    let label = ''
    if (addr.line1) {
      label += addr.line1
    }
    if (addr.line1) {
      label += (label.length > 0 ? ', ' : '') + addr.line2
    }
    if (addr.city) {
      label += (label.length > 0 ? ', ' : '') + addr.city
    }
    if (addr.street) {
      label += (label.length > 0 ? ', ' : '') + addr.street
      if (addr.street_number) {
        label += ' ' + addr.street_number
      }
    }
    if (addr.suburb) {
      label += (label.length > 0 ? ', ' : '') + addr.suburb
    }
    if (addr.town) {
      label += (label.length > 0 ? ', ' : '') + addr.town
    }
    return label
  }

  addressOption = () => {
    console.log('addressOption', this.props.account)
    const addrOption = []
    if (this.props.account && Array.isArray(this.props.account.addresses)) {
      this.props.account.addresses.forEach(addr => {
        addrOption.push({
          value: addr.address_uid,
          name: this._addrFormat(addr)
        })
      })
    }
    return addrOption
  }

  render() {
    const { activity, isEditing } = this.props // eslint-disable-line
    // C console.log('render meeting form', activity, this.props.formValues)
    const isFinalStatus =
      activity.activity_status && activity.activity_status.is_result
    const meetingTypeOptions = this.props.meetingTypes
      ? this.props.meetingTypes.map(mt => ({
          value: mt.activity_type_uid,
          name: mt.name
        }))
      : []
    console.log('meetingTypeOptions', meetingTypeOptions)
    const addressOptions = this.addressOption()
    return (
      <div>
        <fieldset
          disabled={!this.props.isEditing}
          className="CrmForm-form-fieldset"
        >
          {/* <div className="row ">
            <div className="col-md-6">
              isEditing: {isEditing ? 'Y' : 'N'}
              <CrmValidatedField
                name="source_type_uid"
                component={this.meetingSourceSelector}
                isRequired
              />
            </div>
            <div className="col-md-6">
              <CrmValidatedField
                name="arranged_by_user_uid"
                component={this.arrangedByUserSelector}
                placeholder="Meeting Arranged By"
                isRequired
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-6">
              <CrmValidatedField
                name="activity_type_uid"
                component={CrmDropdown}
                componentProps={{
                  placeholder: 'Meeting Type',
                  options: meetingTypeOptions
                }}
                props={{ disabled: !this.props.isEditing }}
              />
            </div>
          </div>
          <div className="row ">
            {/* <div className="col-md-6">
              <CrmValidatedField
                name="primary_performer_user_uid"
                component={this.leadRmUserSelector}
                placeholder="Lead RM"
              />
            </div> */}
            <div className="col-md-4">
              <CrmValidatedField
                name="start_time"
                component={CrmDayHourPickerInput}
                props={{ disabled: !this.props.isEditing }}
              />
            </div>
            <div className="col-md-8">
              <CrmValidatedField
                name="subject"
                component={CrmTextInput}
                componentProps={{
                  placeholder: 'Subject',
                  placeholderId: 'crm.ui.activity.form.subject'
                }}
                props={{ disabled: !this.props.isEditing }}
              />
            </div>
          </div>
        </fieldset>
        <div className="util-marginBottom">
          <MeetingFormParticipantsFields
            formMode
            isEditing={this.props.isEditing}
            accountUid={this.props.activity.account_uid}
          />
        </div>
        <fieldset
          disabled={!this.props.isEditing}
          className="CrmForm-form-fieldset"
        >
          <div className="row ">
            <div className="col-md-12">
              <CrmValidatedField
                name="address_uid"
                component={CrmAddressSelect}
                placeholder={
                  <FormattedMessage
                    id="crm.ui.activity.form.please_select_address"
                    defaultMessage="Please select address"
                  />
                }
                options={addressOptions}
                componentProps={{
                  options: addressOptions,
                  accountUid: this.props.account
                    ? this.props.account.account_uid
                    : null,
                  onAddNewAddress: this.props.onAddNewAddress,
                  placeholder: (
                    <FormattedMessage
                      id="crm.ui.activity.form.please_select_address"
                      defaultMessage="Please select address"
                    />
                  ),
                  saving: this.props.addressSaving
                }}
                onAddNewAddress={this.props.onAddNewAddress}
                saving={this.props.addressSaving}
              />
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <CrmValidatedField
                component={CrmTextInput}
                componentProps={{
                  placeholder: 'Before meeting note',
                  placeholderId: 'crm.ui.activity.form.before_meeting_note'
                }}
                name="note_text"
              />
            </div>
          </div>
        </fieldset>
        <div className="row ">
          <div className="col-md-12">
            <MeetingStatusWidget
              activity={this.props.activity}
              isEditing={this.props.isEditing}
              onGotoMinutes={this.props.onGotoMinutes}
              isFinalStatus={isFinalStatus}
              onChanged={this.props.onChanged}
            />
          </div>
        </div>
      </div>
    )
  }
}

MeetingFormFields.propTypes = {
  ...propTypes,
  activity: shape({}).isRequired,
  addressSaving: bool.isRequired,
  isEditing: bool.isRequired,
  onGotoMinutes: func.isRequired,
  meetingTypes: arrayOf(shape())
}

MeetingFormFields.defaultProps = {
  meetingTypes: undefined
}
function mapStateToProps(state) {
  return { meetingTypes: getActivityTypesByMediumCode(state, 'meeting') }
}
export default connect(mapStateToProps)(MeetingFormFields)
