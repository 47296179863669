/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { number, string, func, shape, any } from 'prop-types'
import { rectanglePath } from 'util-functions/svg-utils'
import { robotoRegular } from 'util-functions/letter-widths'

const IconBar = props => {
  const { width: w, height: h, x, y, fill, label, value } = props
  const labelText = props[label]
  const labelWidth = labelText
    ? labelText
        .split('')
        .reduce((acc, letter) => (robotoRegular[letter] || 0) * 15 + acc, 0) +
      20
    : 0
  const showIcon = w > 50
  const barWidth = showIcon ? w - h / 2 : w
  const labelX = w > labelWidth + 35 ? x + 5 : w + 8
  const labelFill = w > labelWidth + 35 ? 'white' : fill
  const barPath = rectanglePath(x, y, barWidth, h, 2, 5, 5, 2)
  const handleClick = () => {
    props.onClick({ ...props.payload, data: props.data })
  }
  return (
    <g onClick={handleClick}>
      <path d={barPath} fill={fill} />
      {showIcon ? (
        <g>
          <circle cx={x + barWidth - 3} cy={y + h / 2} r={h / 2} fill={fill} />
          <circle
            cx={x + barWidth - 3}
            cy={y + h / 2}
            r={(h * 0.8) / 2}
            fill={'white'}
          />
          {/* <svg
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: vehicleIcons[vehicleType]
            }}
            className="WidgetBarChart-icon"
            x={x + barWidth - 18}
            y={y + 9}
            height={h * 0.6}
            width={h * 0.6}
            style={{ color: fill }}
          /> */}
          ) : (
          <text
            className="WidgetBarChart-icon"
            style={{ fontSize: 14 }}
            fontFamily="FontAwesome"
            x={x + barWidth - 3}
            y={y + h * 0.8}
            fill={fill}
          >
            {/* {icon} */}
            {value}
          </text>
        </g>
      ) : null}
      <text
        y={y + h * 0.8}
        x={labelX}
        className="WidgetBarChart-label-text"
        fill={labelFill}
      >
        {labelText}
      </text>
    </g>
  )
}

IconBar.propTypes = {
  width: number,
  height: number,
  x: number,
  y: number,
  fill: string,
  label: string,
  onClick: func,
  value: any,
  payload: shape({}).isRequired,
  data: any
}

IconBar.defaultProps = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
  fill: '',
  label: '',
  icon: '',
  onClick: () => {},
  value: undefined,
  data: undefined
}

export default IconBar
