import React from 'react'
import { shape } from 'prop-types'
import AccountsByStatusSummaryWidget from '../widgets18/accounts/accounts-by-status-stats-widgets.jsx'
import AccountsStatsAnalyticsWidgetGroup from '../widgets18/accounts/accounts-stats-analytics-widget-group.jsx'

class LeadsAnalyticsDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleDashboardEvent = event => {
    console.log('handleDashboardEvent', event)
    if (event === 'accountStatusSelected') {
      this.setState({ selectedAccountStatus })
    }
  }
  render() {
    const filters = {
      accountStatus: this.state.selectedAccountStatus
    }
    return (
      <div>
        <AccountsByStatusSummaryWidget
          title="All CRM Accounts by status"
          analyticsContext={this.props.analyticsContext}
          onDashboardEvent={this.handleDashboardEvent}
        />
        <AccountsStatsAnalyticsWidgetGroup
          analyticsContext={this.props.analyticsContext}
          onDashboardEvent={this.handleDashboardEvent}
          filters={filters}
        />
        <div className="util-marginTop">
          <code>{JSON.stringify(this.props.analyticsContext)}</code>
        </div>
      </div>
    )
  }
}

LeadsAnalyticsDashboard.propTypes = {
  analyticsContext: shape().isRequired
}

LeadsAnalyticsDashboard.defaultProps = {
  dashboards: []
}

export default LeadsAnalyticsDashboard
