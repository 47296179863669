import { graphql } from 'react-apollo'
import crmGenericSelectQLHocFactory from 'crm-components/ql/crm-generic-select-ql-hoc.jsx'
import { qlqResourcePoolsList } from 'crm-data/resource-pools'

const mapPoolToOptions = c => ({
  value: c.resource_pool_uid,
  key: c.resource_pool_uid,
  name: c.name
})

const query = graphql(qlqResourcePoolsList, {
  options: ownProps => ({
    variables: {
      type: ownProps.campaignType ? ownProps.campaignType : ownProps.type,
      limit: 1000
    }
  }),
  props: ({ qlqResourcePoolsList, ownProps }) => ({
    ...ownProps,
    qlqResourcePoolsList,
    optionsQl: qlqResourcePoolsList,
    options:
      qlqResourcePoolsList && qlqResourcePoolsList.users_groups
        ? qlqResourcePoolsList.users_groups.map(mapPoolToOptions)
        : undefined,
    isLoading: qlqResourcePoolsList ? qlqResourcePoolsList.loading : false
  }),
  name: 'qlqResourcePoolsList'
})

const ResourcePoolSelect = crmGenericSelectQLHocFactory(query)

ResourcePoolSelect.propTypes = {}

export default ResourcePoolSelect
