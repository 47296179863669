import { useLoadTask } from 'crm-modules/tasks/hook/use-fetch-task'

export const useFetchMyTaskWidget = (filter = {}, type) => {
  const limit = 50
  const masterFilter = { ...filter, assignee_type: type }
  const sort = [{ id: 'due_date', desc: false }]
  const getTypeFilter = () => {
    const user: any = window.localStorage.getItem('crmUser')
    const crmUser = JSON.parse(user)
    if (type === 'user') {
      return { assignee_user_uid: crmUser?.user_uid }
    } else {
      return { assignee_group_uid: { $currentUserGroups: true } }
    }
  }

  const typeFilter = getTypeFilter()
  const variables = {
    overDueTask: {
      filter: {
        ...masterFilter,
        ...typeFilter,
        is_overdue: true
      },
      limit,
      sort
    },
    todayTask: {
      filter: {
        ...masterFilter,
        ...typeFilter,
        is_today: true
      },
      limit,
      sort
    },
    futureTask: {
      filter: {
        ...masterFilter,
        ...typeFilter,
        is_future_day: true
      },
      limit,
      sort
    }
  }
  const overDueTask = useLoadTask(variables.overDueTask)
  const todayTask = useLoadTask(variables.todayTask)
  const futureTask = useLoadTask(variables.futureTask)
  return { overDueTask, todayTask, futureTask }
}
