/* eslint-disable camelcase */
import { callQLApi } from '../api-utils'

export const qlDepartment = ' department_uid  name  kind type '

export default {
  fetchDepartments: () => {
    const query = ` { departments {  ${qlDepartment} } } `
    return callQLApi(query).then(res => res.departments)
  },
  saveDepartment: params => {
    const qlParams = {
      query: ` mutation  department_create($department:department_in) { department_create(department: $department)  { ${qlDepartment} }  } `,
      variables: {
        department: {
          name: params.payload.values.name,
          type: params.payload.values.type
        }
      }
    }

    return callQLApi(qlParams).then(res => res.department_create)
  },
  updateDepartment: params => {
    const qlParams = {
      query: ` mutation  department_update($department:department_in) { department_update(department: $department)  { ${qlDepartment} }  } `,
      variables: {
        department: {
          name: params.payload.values.name,
          department_uid: params.payload.department.data.department_uid,
          type: params.payload.values.type
        }
      }
    }

    return callQLApi(qlParams).then(res => res.department_update)
  }
}
