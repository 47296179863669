import React from 'react'
import { get } from 'lodash'
import { FormattedRelativeTime, FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import { columnAcountHealth } from '../list/accounts-table-inner.jsx'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import { AccountListPageQL } from 'crm-data/accounts'
import { getAccountStatus } from 'crm-utils/accounts-utils'

const qlOptions = {
  options: ownProps => {
    return {
      variables: {
        filter: {
          ...ownProps.filter,
          kind: 'prospect'
        },
        load_data: ['opportunities', 'market_potential']
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.account_list_page : []
  })
}

const Table = genericTableFactory({
  query: AccountListPageQL,
  qlOptions
})

export const availableColumns = [
  columnAcountHealth,
  {
    Header: 'Name',
    accessor: 'account_name',
    Cell: row => (
      <Link to={'/crm/account/' + row.original.account_uid + '/sales'}>
        {row.original.account_name}
      </Link>
    )
  },
  {
    Header: 'City',
    accessor: 'city',
    maxWidth: 100
  },
  {
    Header: 'Owner',
    accessor: 'owner_full_name',
    Cell: row => row.original?.owner_group_name ?? row?.original?.owner_full_name,
    maxWidth: 140
  },
  {
    Header: 'Started At',
    accessor: 'first_account_touch_time',
    Cell: row => (
      <div>
        {row.original.first_account_touch_time ? (
          <FormattedDate value={row.original.first_account_touch_time} />
        ) : (
          ''
        )}
      </div>
    ),
    maxWidth: 100
  },
  {
    Header: 'Activity',
    accessor: 'last_activity_time',
    Cell: row => (
      <div>
        {row.original.last_activity_time ? (
          <FormattedRelativeTime value={row.original.last_activity_time} />
        ) : (
          ''
        )}
      </div>
    ),
    maxWidth: 100
  },
  {
    Header: 'Last Activity Note',
    accessor: 'last_activity_note',
    maxWidth: 180
  },
  {
    Header: 'Lead Campaign',
    accessor: 'lead_campaign_name'
  },
  {
    Header: 'Lead Source Type',
    accessor: 'lead_source_name'
  },
  {
    Header: 'Sales Campaign',
    accessor: 'sales_campaign_name'
  },
  {
    Header: 'Potential',
    accessor: 'market_potential',
    maxWidth: 100,
    className: 'util-flexRowRight',
    Cell: props => {
      const value = get(props, 'value.size', false)
      return value && value > 0 ? value : ''
    }
  },
  {
    Header: 'Opportunity',
    accessor: 'opportunities_size',
    maxWidth: 120,
    className: 'util-flexRowRight',
    Cell: props =>
      props?.original?.opportunities?.filter(
        el => el?.status_code === 'active'
      )?.[0]?.quantity ?? ''
  }
]

const TableInner = props => {
  const getTrProps = (state, rowInfo) => {
    if (rowInfo === undefined) {
      return {}
    }

    const accountStatus = getAccountStatus(rowInfo.original)

    /**
     *
     * For now, only apply red row color for deleted,
     *  unsure yet if other colors are applicable.
     *  Just remove condition below to apply all colors,
     *  and concat `accountStatus` directly
     *
     */
    const rowClassName = `AccountsTable-row-${
      accountStatus === 'deleted' ? accountStatus : ''
    }`

    return {
      className: rowClassName
    }
  }

  return (
    <Table
      {...props}
      getTrProps={getTrProps}
      columns={availableColumns}
      tableName="prospect"
      translation
      bulkOperationElementType="prospect"
    />
  )
}

export default TableInner
