import React from 'react'
import { string } from 'prop-types'

const MinuteFormName = props => (
  <div className="MinuteOfMeeting-header">
    <p className="MinuteOfMeeting-header-title">
      {props ? props.formname : ''}
    </p>
  </div>
)

MinuteFormName.propTypes = {
  formname: string.isRequired
}

export default MinuteFormName
