import React, { useState } from 'react'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import { FilterSection } from '../tools'
import { OrganizationUnitFilterValueString, OrganizationUnitDropDown } from '.'

export const FilterSectionOrganizationUnit = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleDropDown = () => setIsExpanded(!isExpanded)
  const closeDropDown = () => setIsExpanded(false)
  const { filters, setFilters } = useAnalyticsContext()
  return (
    <FilterSection
      name='Organization Unit'
      selectedValue={(<OrganizationUnitFilterValueString filterValues={filters} />)}
      onToggle={toggleDropDown}
      isExpanded={isExpanded}
      style={{ minWidth: 300 }}
      isDisabled={props.isDisabled}
    >
      {isExpanded && <OrganizationUnitDropDown
        isExpanded={isExpanded}
        onClickedOutside={closeDropDown}
        onSetFilters={setFilters}
        filterValues={filters}
      />}
    </FilterSection>
  )
}
