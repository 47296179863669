/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved, camelcase */
import uuidv4 from 'uuid/v4'
import _ from 'lodash'
import { actions } from 'crm-duxs/opportunity-reducer'
import { getUser } from 'crm-duxs/crm-reducer'
import { put, takeLatest, select } from 'redux-saga/effects'

function* startOpportunityEdit(action) {
  yield put({
    type: actions.setEditedOpportunity.type,
    payload: {
      opportunity: action.payload.opportunity,
      documentUid: _.has(action.payload, 'documentUid')
        ? action.payload.documentUid
        : undefined
    }
  })
  // Cconst availableStatuses = yield call(
  //   activitiesApi.listAvailableStatusesForOpportunityUid,
  //   action.payload.opportunity.opportunity_uid
  // )
  // console.log('got available actions')
  // console.dir(availableStatuses)
  // yield put({
  //   type: actions.fetchAvailableStatusesSuccess.type,
  //   payload: { availableStatuses }
  // })
}

function* initNewOpportunity(action) {
  const currentUser = yield select(getUser)
  console.log('initNewOpportunity', action)

  const opportunity = _buildNewOpportunity(
    action.payload.accountUid,
    currentUser,
    action.payload.marketing_campaign_uid,
    action.payload.parent_process_uid
  )

  yield put({
    type: actions.setEditedOpportunity.type,
    payload: { opportunity }
  })
  // Load statuses for this kind of opportunity
  // let availableStatuses = yield call(
  //   activitiesApi.listOpportunityStatusesByOpportunityTypeApi,
  //   opportunity.opportunity_type_uid
  // )
  // Filter only initials
  // availableStatuses = availableStatuses.filter(status => status.is_initial)
  // yield put({
  //   type: actions.fetchAvailableStatusesSuccess.type,
  //   payload: { availableStatuses }
  // })
}

function _buildNewOpportunity(
  accountUid,
  currentUser,
  marketing_campaign_uid,
  parent_process_uid
) {
  console.log('_buildNewOpportunity')
  console.dir(currentUser)
  const opportunity = {
    tmpId: uuidv4(),
    account_uid: accountUid,
    kind: 'opportunity',
    value_currency: 'THB',
    marketing_campaign_uid,
    parent_process_uid
  }
  opportunity.owner_user_uid = currentUser.user_uid
  console.dir(opportunity)
  return opportunity
}

export default function* activitiesSaga() {
  yield takeLatest(actions.startOpportunityEdit.type, startOpportunityEdit)
  yield takeLatest(actions.initNewOpportunity.type, initNewOpportunity)
}
