import React, { useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { useCrmContext } from 'crm-core/contexts/crm-context'
import { useCrmComponent } from 'crm-utils/crm-component-util'
import { GenericAnalyticsWidget } from '@cartrack-crm/crm-analytics'
import { buildQlQuery } from '../tools/data-source-test-utils'
import { FilterManager } from '../tools/filter-manager-widget'
import { mapContextFiltersIntoWidget } from '../../../dashboard/dashboard-utils'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import { WidgetConfigurationContextProvider } from '@cartrack-crm/crm-analytics/src/configuration-tools/component-configuration-context'
import { crmSchemaProd } from '@cartrack-crm/crm-analytics/src/crm-schema-prod'
import uuid from 'uuid'

const useRunQuery = (props) => {
  const context = useCrmContext()
  const client = useApolloClient()

  const runQuery = async (dataSource) => {
    if (props.analyticsContext) {
      dataSource = mapContextFiltersIntoWidget(dataSource, props)
    }
    const gqlQueryParams = buildQlQuery(dataSource, props.filter)
    const data = await client.query({
      query: gqlQueryParams.query,
      variables: {
        ...gqlQueryParams.variables,
        master_uid: context?.master?.master_uid,
        instance_uid: context?.instance?.instance_uid,
        fetchPolicy: 'no-cache'
      }
    })
    return { data, gqlQueryParams }
  }
  return [runQuery]
}

const useRenderFilterTools = (props) => {
  const [isShowFilterTool, setShowFilterTool] = useState(false)
  const onOpen = () => setShowFilterTool(true)
  const onClose = () => setShowFilterTool(false)
  const renderFilter = (data, onSave) => {
    return (
      <FilterManager
        isOpen={isShowFilterTool}
        onClose={onClose}
        filters={data}
        onSave={onSave}
      />
    )
  }
  return { onOpen, onClose, renderFilter }
}

const adminWidgetToolsHoc = (Component) => {
  const Wrapper = withLeadPopUpHoc((props) => {
    const { saveComponent } = useCrmComponent(props)
    const [runQuery] = useRunQuery(props)
    const { onOpen, onClose, renderFilter } = useRenderFilterTools(props)
    const renderPreview = (component) =>
      component && component.content && component.content.layout ? (
        <GenericAnalyticsWidget
          key={component?.component_uid}
          title={component?.name}
          style={{ margin: 0 }}
          widgetDefinition={component}
          filters={component?.test_filters}
          onShowLeadsPopup={props.onShowLeadsPopup}
        />
      ) : (
          <div> No layout in component </div>
        )
    return (
      <WidgetConfigurationContextProvider
        component={props.widget}
        schema={crmSchemaProd}
      >
        <Component
          {...props}
          saveDashboard={saveComponent}
          runQuery={runQuery}
          renderPreview={renderPreview}
          onOpenFilter={onOpen}
          onCloseFilter={onClose}
          renderFilter={renderFilter}
        />
      </WidgetConfigurationContextProvider>
    )
  })
  return Wrapper
}

export default adminWidgetToolsHoc
