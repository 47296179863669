/* eslint-disable camelcase */

import { createSelector } from 'reselect'
export const FETCH_INSTANCES = 'FETCH_INSTANCES'
export const FETCH_INSTANCES_SUCCESS = 'FETCH_INSTANCES_SUCCESS'
export const SELECT_INSTANCE = 'SELECT_INSTANCE'
export const CLEAR_SELECT_INSTANCE = 'CLEAR_SELECTINSTANCE'
export const SHOW_CREATE_FORM_INSTANCE = 'SHOW_CREATE_FORM_INSTANCE'
export const CREATE_INSTANCE = 'CREATE_INSTANCE'
export const CREATE_INSTANCE_SUCCESS = 'CREATE_INSTANCE_SUCCESS'
export const UPDATE_INSTANCE = 'UPDATE_INSTANCE'
export const UPDATE_INSTANCE_SUCCESS = 'UPDATE_INSTANCE_SUCCESS'

const initialState = {
  instances: [],
  instanceSelected: undefined
}

export const findIndexOfInstancesByUID = (instances, uid) =>
  instances.findIndex(instance => instance.instance_uid === uid)
const mergeStateInstanceData = (instances, uid, user) => {
  const indexOfInstances = findIndexOfInstancesByUID(instances, uid)
  return Object.assign([], instances, {
    [indexOfInstances]: user
  })
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INSTANCES:
    case CREATE_INSTANCE:
    case UPDATE_INSTANCE:
      return {
        ...state
      }

    case FETCH_INSTANCES_SUCCESS: {
      return {
        ...state,
        instances: action.payload.instances
      }
    }
    case SELECT_INSTANCE:
      return {
        ...state,
        instanceSelected: action.payload.instanceData
      }

    case CLEAR_SELECT_INSTANCE:
      return {
        ...state,
        instanceSelected: undefined
      }

    case SHOW_CREATE_FORM_INSTANCE:
      return {
        ...state,
        instanceSelected: undefined
      }

    case CREATE_INSTANCE_SUCCESS: {
      return {
        ...state,
        instances: [...state.instances, action.payload.instance]
      }
    }
    case UPDATE_INSTANCE_SUCCESS: {
      return {
        ...state,
        instances: mergeStateInstanceData(
          state.instances,
          action.payload.instance.instance_uid,
          action.payload.instance
        )
      }
    }

    default:
      return state
  }
}

export function fetchInstance(master_uid) {
  return {
    type: FETCH_INSTANCES,
    payload: { master_uid: master_uid }
  }
}

export function setSelectedInstance(instanceData) {
  return {
    type: SELECT_INSTANCE,
    payload: {
      instanceData
    }
  }
}

export function clearSelectedInstance() {
  return {
    type: CLEAR_SELECT_INSTANCE
  }
}

export function showCreateForm() {
  return {
    type: SHOW_CREATE_FORM_INSTANCE
  }
}

export function saveInstance(instance, values) {
  console.log('saveInstance', instance, values)
  if (!instance) {
    return
  }
  let type = CREATE_INSTANCE
  if (instance.instance_uid) {
    type = UPDATE_INSTANCE
  }

  return {
    type,
    payload: {
      instance,
      values
    }
  }
}

export const instanceSelector = state => state.crm.instance.instances
export const getSelectedInstance = state => state.crm.instance.instanceSelected

const parentSelector = (instances, selectedInstance) => {
  let selector = Object.assign([], instances)
  // console.log(
  //   'parentSelector',
  //   selectedInstance,
  //   typeof selectedInstance,
  //   instances
  // )
  if (typeof selectedInstance === 'undefined') {
    return selector
  }

  const index = findIndexOfInstancesByUID(
    instances,
    selectedInstance.instance_uid
  )

  if (index >= 0) {
    selector = selector.filter(
      item => item.instance_uid !== selectedInstance.instance_uid
    )
  }

  if (instances[index].parent_instance_uid) {
    selector = selector.filter(
      item => item.instance_uid !== instances[index].parent_instance_uid
    )
    selector.push(instances[index].parent)
  } else {
    selector.push(instances[index])
  }

  return selector
}
export const getParentInstanceSelector = createSelector(
  instanceSelector,
  getSelectedInstance,
  parentSelector
)
