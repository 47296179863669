/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import { shape, func, string } from 'prop-types'
import { dialerCampaignQl } from '../telesales-dash-utils'
import { mapContextToFilter } from '../../../dashboard-utils'

import CrmWidget from '../../../components/crm-widget.jsx'

import { arraySum } from 'crm-utils/data-utils'
import { CrmHeatmapChart } from 'crm-components'

class SalesByLeadSourceHM extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ ...this.state, isLoading: val }, handler)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== undefined &&
      nextProps.analyticsContext !== this.props.analyticsContext
    ) {
      // Tthis.setState({ ...this.state }, () => {
      //   this.handleRefresh()
      // })
    }
  }
  renderSummary = () => (
    <div>
      {this.state.totalDispositions && (
        <span>Total Dispositions: {this.state.totalDispositions}</span>
      )}
    </div>
  )

  handleRefresh = async () => {
    console.log('Sales Stats by Source', this.props)
    try {
      const filter = mapContextToFilter(this.props, false)
      filter.is_final_step = 'true'
      filter.process_step_kind = 'won'

      const variables = {
        filter,
        fields: [
          'owner_user_name',
          'current_step_name',
          'process_step_kind',
          'lead_source_uid',
          'lead_source_name'
        ],
        aggregate: [{ id: 'process_uid', type: 'count' }]
      }

      const res = await this.props.client.query({
        query: dialerCampaignQl,
        variables,
        fetchPolicy: 'no-cache'
      })
      const ldata = res.data.dialer_campaign_accounts_list_qm_paged.data.map(
        i => ({
          ...i
        })
      )
      this.setState({
        data: ldata,
        totalDispositions: arraySum(ldata, 'activity_count')
      })
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }

  handleCurrentStepClick = event => {
    console.log('handleCurrentStepClick', event)
    this.setState({ selectedStepTypeUid: event.currentTarget.id })
    this.props.onCurrentStepClick({
      process_step_type_uid: event.currentTarget.id
    })
  }

  render() {
    console.log('Render widget new leads by current status', this.state)
    return (
      <CrmWidget
        title={this.props.title ? this.props.title : 'Wins by Agent'}
        subtitle="Matrix of dispositions made by telesales agents in selected period "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
      >
        {!this.state.data && (
          <span>No data - select filters and press refresh to load</span>
        )}
        {this.state.data && (
          <CrmHeatmapChart
            yAxisField="owner_user_name"
            xAxisField="lead_source_name"
            valueField="process_uid"
            data={this.state.data}
          />
        )}
      </CrmWidget>
    )
  }
}

SalesByLeadSourceHM.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  onCurrentStepClick: func.isRequired,
  title: string
}
SalesByLeadSourceHM.defaultProps = {
  title: undefined
}

export default withApollo(SalesByLeadSourceHM)
