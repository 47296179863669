import { getDateFormat } from 'crm-utils/time-utils'

export const getLeadInDateString = (filterValues) => {
  let leadInDateLabel = 'Select Value'
  if (
    filterValues &&
    filterValues.lead_in_date &&
    filterValues.lead_in_date.value
  ) {
    const lead_in_date = filterValues.lead_in_date
    if (lead_in_date.label) {
      leadInDateLabel = lead_in_date.label
    } else if (lead_in_date.value.$gte && lead_in_date.value.$lte) {
      leadInDateLabel = `
      ${getDateFormat(lead_in_date.value.$gte)} - 
      ${getDateFormat(lead_in_date.value.$lte)}`
    } else {
      leadInDateLabel = lead_in_date.value
    }
  }
  return leadInDateLabel
}

export const getFirstValue = (val) => {
  return Array.isArray(val) && val.length > 0 ? val[0] : val
}
