import React from 'react'
import { withApollo } from 'react-apollo'
import moment from 'moment'
import cloneDeep from 'clone-deep'
import { shape, bool } from 'prop-types'
import { qlAnalyticsWidgetQuery } from 'crm-data/analytics'
import { mapContextFiltersIntoWidget, mapContextToFilter, generateDateSeries } from '../../dashboard-utils'

const widgetDefinition = {
  code: 'ct_monthly_leads_2019',
  content: {
    data: {
      dataSources: [
        {
          code: 'MonthlyLead',
          type: 'QM',
          filter: {},
          qlQuery: `
          query master($master_uid: String!, $instance_uid: String!, $filter: JSON) {
            master(master_uid: $master_uid)  { 
                master_uid
                name
                instance(instance_uid: $instance_uid) { 
                    instance_uid
                    name
                    monthly_lead_result_reports(filter: $filter) {
                        date_month
                        lead_cost
                        lead_auth
                        lead_receive
                        fitment
                        first_fitment
                        sales
                        sales_size
                        meeting_schedule
                        meeting_completed
                    }
                }
            }
        }
          `,
          qlRoot: 'monthly_lead_result_reports',
          qlOnly: true
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyFields: ['date'],
        mapping: [
          {
            fromDataSourceName: 'MonthlyLead',
            keyFields: { date: 'date_month' },
            mapping: [
              {
                from: 'meeting_completed',
                to: 'meetings_completed'
              },
              {
                from: 'meeting_schedule',
                to: 'meetings_scheduled'
              },
              {
                from: 'fitment',
                to: 'fitments'
              },
              {
                from: 'first_fitment',
                to: 'first_fitments'
              },
              {
                from: 'lead_receive',
                to: 'new_leads'
              },
              {
                from: 'lead_auth',
                to: 'authenticated_leads'
              },
              {
                from: 'lead_cost',
                to: 'lead_campaign_cost'
              },
              {
                from: 'sales',
                to: 'won_opportunities'
              }
            ]
          }
        ]
      }
    }
  }
}

const CtDailyLeadsMonitoringHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = { selectedSections: {} }
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (
        nextProps !== undefined &&
        JSON.stringify(nextProps.analyticsContext) !== JSON.stringify(this.props.analyticsContext)
      ) {
        this.setState({}, () => {
          this.handleRefresh()
        })
      }
    }
    handleCurrentStepClick = selectedStepType => {
      this.setState({ selectedStepType })
    }
    handleRefresh = async () => {
      const isActivityDateFilterMissing = this.getIsActivityDateFilterMissing()
      if (isActivityDateFilterMissing) {
        return
      }

      const mappedAnalyticsContext = cloneDeep(this.props.analyticsContext)
      const now = moment()
      now.set({ date: 1 })
      const yearAgo = moment()
      yearAgo.set({ date: 1 })
      yearAgo.subtract(1, 'year')
      if (!this.props.currentYear) {
        mappedAnalyticsContext.filters.activity_date = {
          value: {
            $gte: yearAgo.format('YYYY-MM-DD'),
            $lte: now.endOf('month').format('YYYY-MM-DD')
          },
          label: 'last 12 months',
          filterName: 'Activity Date'
        }
      } else {
        mappedAnalyticsContext.filters.activity_date = {
          value: [
            moment()
              .startOf('month')
              .format('YYYY-MM-DD'),
            moment()
              .add(1, 'year')
              .endOf('month')
              .format('YYYY-MM-DD')
          ],
          label: 'Current Year',
          filterName: 'Activity Date'
        }
      }
      let widgetDefinitionWithFilters = widgetDefinition
      if (this.props.dashboard && this.props.dashboard.filters) {
        widgetDefinitionWithFilters = mapContextFiltersIntoWidget(widgetDefinitionWithFilters, {
          analyticsContext: { filters: this.props.dashboard.filters }
        })
      }

      widgetDefinitionWithFilters = mapContextFiltersIntoWidget(widgetDefinitionWithFilters, {
        analyticsContext: mappedAnalyticsContext
      })

      const contextFilter = mapContextToFilter({ analyticsContext: mappedAnalyticsContext }, true)
      const variables = {
        widgetDefinition: widgetDefinitionWithFilters,
        filter: contextFilter
      }
      await this.setStateAsync({ isLoading: true })
      try {
        const res = await this.props.client.query({
          query: qlAnalyticsWidgetQuery,
          variables,
          fetchPolicy: 'no-cache'
        })

        if (res.data && res.data.dataSourceQuery && res.data.dataSourceQuery.data) {
          let data = [...res.data.dataSourceQuery.data.dataSources.CombinedDataSource.rawData.rows]
          data.sort((a, b) =>
            (a.date ? moment(a.date) : moment('2000')) > (b.date ? moment(b.date) : moment('2000')) ? 1 : -1
          )
          const summary = this.calculateSummary(data)
          delete summary.user_uid
          summary.full_name = 'Total'
          summary.department__name = ''
          summary.is_summary = true

          let months = []
          if (!this.props.currentYear) {
            months = [...generateDateSeries(now, yearAgo, date => moment(date).format('YYYY-MM-01'), 'months')]
          } else {
            months = [
              ...generateDateSeries(
                moment()
                  .startOf('month')
                  .format('YYYY-MM-DD'),
                moment()
                  .add(1, 'year')
                  .endOf('month')
                  .format('YYYY-MM-DD'),
                date => moment(date).format('YYYY-MM-DD'),
                'months'
              )
            ]
          }
          const calculateKpis = row => ({
            sales_to_leads:
              row.won_opportunities > 0 && row.new_leads
                ? Number((100 * row.won_opportunities) / row.new_leads)
                : undefined,
            cost_to_lead:
              row.lead_campaign_cost > 0 && row.new_leads ? Number(row.lead_campaign_cost / row.new_leads) : undefined,
            cost_to_win:
              row.lead_campaign_cost > 0 && row.first_fitments
                ? Number(row.lead_campaign_cost / row.first_fitments)
                : undefined,
            cost_to_fitment:
              row.lead_campaign_cost > 0 && row.fitments ? Number(row.lead_campaign_cost / row.fitments) : undefined
          })
          data = data
            ? data.reduce((a, i) => {
                const ret = { ...a }
                ret[i.date] = {
                  ...i,
                  ...calculateKpis(i)
                }
                return ret
              }, {})
            : {}

          data.summary = {
            ...summary,
            ...calculateKpis(summary)
          }
          this.setState({
            data,
            months,
            summary,
            contextFilter,
            isLoading: false
          })
        }
        return
      } catch (err) {
        console.log('Error', err)
      }
      await this.setStateAsync({ isLoading: false })
    }

    calculateSummary = data =>
      data.reduce((a, i) => {
        const r = { ...a }
        Object.keys(i).forEach(k => {
          r[k] = r[k] ? r[k] + Number(i[k]) : Number(i[k])
        })
        return r
      }, {})
    toggleIsLoading(val, handler) {
      return this.setState({ isLoading: val }, handler)
    }

    handleTogleSection = (event, code) => {
      if (code) {
        const selectedSections = { ...this.state.selectedSections }
        selectedSections[code] = !selectedSections[code]
        this.setState({ selectedSections })
      }
    }
    parseData(result) {
      this.setState({ data: result.data }, () => {
        this.toggleIsLoading(false)
      })
    }

    getIsActivityDateFilterMissing = () => false
    // getIsActivityDateFilterMissing = () =>
      // !this.props.analyticsContext ||
      // !this.props.analyticsContext.filters ||
      // !this.props.analyticsContext.filters.activity_date ||
      // !this.props.analyticsContext.filters.activity_date.value

    render() {
      const isActivityDateFilterMissing = this.getIsActivityDateFilterMissing()

      return (
        <WrappedComponent
          {...this.props}
          isActivityDateFilterMissing={isActivityDateFilterMissing}
          onRefresh={this.handleRefresh}
          isLoading={this.state.isLoading}
          data={this.state.data}
          summary={this.state.summary}
          dataByLeadSource={this.state.dataByLeadSource}
          months={this.state.months}
          contextFilter={this.state.contextFilter}
        />
      )
    }
  }

  Inner.propTypes = {
    analyticsContext: shape({}).isRequired,
    client: shape({}).isRequired,
    dashboard: shape(),
    currentYear: bool
  }

  return withApollo(Inner)
}
export default CtDailyLeadsMonitoringHoc
