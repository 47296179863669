const download = (url, name) => {
  const a = document.createElement('a')

  a.href = url
  a.download = name
  a.click()

  window.URL.revokeObjectURL(url)
}

function s2ab(s) {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  // eslint-disable-next-line
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

export const parseObjectToColumn = (parsed, row, keyname = null, separator = '.') => {
  Object.keys(row).forEach(key => {
    if (row[key] && typeof row[key] === 'object' && key !== '__typename') {
      Object.keys(row[key]).forEach(subkey => {
        if (row[key][subkey] && typeof row[key][subkey] === 'object' && key !== '__typename') {
          parseObjectToColumn(parsed, row[key][subkey], key + separator + subkey)
        } else {
          if (subkey !== '__typename') parsed[key + separator + subkey] = row[key][subkey]
        }
      })

      delete parsed[key]
    } else {
      if (keyname) {
        if (key !== '__typename') parsed[keyname + separator + key] = row[key]
      }
    }
  })
}

const doClearSpecialKey = row => {
  if (row['participants']) {
    const participants = row['participants']
    let listFullName = participants.map(el => {
      return el.user && el.user.full_name ? el.user.full_name : null
    })

    row['all_participants'] = [...new Set(listFullName)].join(', ')

    delete row['participants']
  }

  if (row['__typename']) {
    delete row['__typename']
  }
}

const _extractJsonFields = data =>
  data.map(row => {
    doClearSpecialKey(row)
    const parsed = { ...row }
    parseObjectToColumn(parsed, row)
    return parsed
  })

export const exportDataToExcel = async params => {
  import('xlsx').then(XLSX => {
    const parsed = _extractJsonFields(params.data)
    const worksheet = XLSX.utils.json_to_sheet(parsed)
    const workbook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Crm Export')
    
    const wbout = XLSX.write(workbook, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary'
    })

    const url = window.URL.createObjectURL(
      // eslint-disable-next-line
      new Blob([s2ab(wbout)], {
        type: 'application/octet-stream'
      })
    )

    download(url, params.fileName ? params.fileName + '.xlsx' : 'crm-export.xlsx')
  })
}

export const downloadXls = (workbook, fileName) => {
  import('xlsx').then(XLSX => {
    const wbout = XLSX.write(workbook, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary'
    })

    const url = window.URL.createObjectURL(
      // eslint-disable-next-line
      new Blob([s2ab(wbout)], {
        type: 'application/octet-stream'
      })
    )

    download(url, fileName ? fileName + '.xlsx' : 'crm-export.xlsx')
  })
}
