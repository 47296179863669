import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { func, string, bool, shape } from 'prop-types'
import { submit } from 'redux-form'
import LoginForm, {
  loginFormKey
} from './modules/utils/login/crm-login-form.jsx'
import {
  login,
  getLoginFailed,
  getUser,
  getLoginLoading,
  getError
} from 'crm-duxs/crm-reducer'
import { getLocale, changeLocale } from 'crm-duxs/locale'
import { Dropdown } from 'util-components'
import { CrmButton, CrmModal } from 'crm-components'
import CrmInitialForm from './crm-initial.jsx'
import CrmResetPasswordForm from 'crm-components/forms/crm-reset-password'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getMobileOperatingSystem } from 'crm-utils/open-app'

const platform = getMobileOperatingSystem()
const WIDTH = window.innerWidth
const HEIGHT = window.innerHeight

class Login extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      validateLogin: true,
      forgetPassword: false,
      enableChangeCompany: false,
      formData: { company_code: 'CARTRACK' },
      isMobile: WIDTH < 600,
      isOpenApp: WIDTH < 600 ? true : false
    }
  }

  get mobileLink() {
    const link = window.location.href
    let scheme = 'https://'
    if (platform === 'iOS') {
      scheme = 'saleslogicae'
    } else {
      scheme = 'saleslogicae'
    }
    const host = window.location.hostname
    let href = window.location.href.replace('https', scheme)
    if (window.location.search.includes('?redirectUri')) {
      const [_, path] = window.location.search.split('?redirectUri=')
      href = `${scheme}://${host}${path}`
    }
    return href
  }

  toggleForgetPassword = () => {
    this.setState({ forgetPassword: !this.state.forgetPassword })
  }

  handleLocaleChange = nextLocale => {
    window.localStorage.setItem('locale', nextLocale)
    this.props.doChangeLocale(nextLocale)
  }

  submitLogin = pFormData => {
    const formData = { ...pFormData }

    formData.company_code = formData.company_code
      ? formData.company_code
      : this.state.formData.company_code

    this.setState({ formData: { ...formData } })

    console.log('form formData', formData)

    if (!formData.username || !formData.password) {
      this.setState({
        validateLogin: false,
        errorMsg: 'Username or password is required'
      })

      return
    }

    this.setState({ validateLogin: true })
    this.props.doLogin(formData)
  }

  enableChangeCompany = () => {
    this.setState({ enableChangeCompany: !this.state.enableChangeCompany })
  }

  get logoutReason() {
    return window.localStorage.getItem('crmLogoutReason')
  }

  render() {
    console.log('CRM LOGIN', this.props)
    console.log('CRM LOGIN', window.localStorage.getItem('message'))
    const { isMobile, isOpenApp } = this.state
    if (this.props.user) {
      return <CrmInitialForm />
    }
    return (
      <React.Fragment>
        <div>
          {isMobile && isOpenApp && (
            <div className="MobileLogin">
              <div className="row" style={{ alignItems: 'center' }}>
                <div onClick={() => this.setState({ isOpenApp: false })}>
                  <FontAwesomeIcon icon="times" style={{ color: 'white' }} />
                </div>
                <img
                  src="../../assets/icons/mobile-icon.png"
                  style={{ width: '30px', height: '30px', marginLeft: '20px' }}
                />
                <div>
                  <div style={{ color: 'white', marginLeft: 10 }}>MiCRM</div>
                  <div style={{ color: 'white', marginLeft: 10, fontSize: 10 }}>
                    Open in the MiCRM app
                  </div>
                </div>
              </div>
              <div>
                <a style={{ color: '#4A90E2' }} href={this.mobileLink}>
                  OPEN
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="Login" style={{ minWidth: '300px' }}>
          <h2>Cartrack MiCRM</h2>
          <div>
            <LoginForm
              onSubmit={this.submitLogin}
              enableChangeCompany={this.state.enableChangeCompany}
              defaultValues={this.state.formData}
            />
            {this.props.loginFailed && (
              <React.Fragment>
                <div className="Login-msgfailed">
                  <FormattedMessage
                    id={this.props.error.code}
                    defaultMessage={this.props.error.message}
                  />
                </div>
              </React.Fragment>
            )}
            {!this.state.validateLogin && (
              <div className="Login-msgfailed">{this.state.errorMsg}</div>
            )}
            {this.logoutReason && (
              <div className="Login-msgfailed">{this.logoutReason}</div>
            )}
            <Dropdown
              title="language"
              options={['en', 'es', 'th', 'pt', 'pl']}
              activeOption={this.props.locale}
              onChange={this.handleLocaleChange}
              extraClassNames={{
                containerClassNames: 'Login-localeDropdown  util-marginTop'
              }}
            />
            <div>
              {window.localStorage.getItem('message') && (
                <div>{window.localStorage.getItem('message')}</div>
              )}
            </div>
            <div className="util-flexCenter">
              <CrmButton
                onClick={this.props.doSubmitLogin} // eslint-disable-line react/jsx-handler-names
                label={this.props.loginLoading ? 'Logging in...' : 'Login'}
                className="login-button  util-marginTop"
                type="primary"
                enable={!this.props.loginLoading}
              />
              {this.props.error.code === 'crm.app.login.failed.msg' && (
                <CrmButton
                  onClick={this.toggleForgetPassword}
                  className="util-marginTop"
                  label="Forgot Password"
                  style={{ marginLeft: 10 }}
                />
              )}
            </div>
            <div className="util-flexCenter">
              <CrmButton
                onClick={this.enableChangeCompany}
                label={'change company'}
                className="login-button util-marginTop"
                small
                type="secondary"
                noBorder
              />
            </div>
            <CrmModal
              isOpen={this.state.forgetPassword}
              onClose={this.toggleForgetPassword}
              title="Reset Password"
              size="sm"
            >
              <CrmResetPasswordForm onClose={this.toggleForgetPassword} />
            </CrmModal>

            <div className="Login-version">
              MiCRM v. {process.env.BITBUCKET_BUILD_NUMBER}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  user: shape({}),
  locale: string.isRequired,
  doLogin: func.isRequired,
  doSubmitLogin: func.isRequired,
  doChangeLocale: func.isRequired,
  loginFailed: bool.isRequired,
  loginLoading: bool
}

Login.defaultProps = {
  user: undefined,
  loginLoading: false
}

function mapStateToProps(state) {
  return {
    locale: getLocale(state),
    loginFailed: getLoginFailed(state),
    user: getUser(state),
    loginLoading: getLoginLoading(state),
    error: getError(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doLogin: formData => dispatch(login(formData)),
    doSubmitLogin: () => dispatch(submit(loginFormKey)),
    doChangeLocale: locale => dispatch(changeLocale(locale))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
