import React from 'react'
import { FiltersOptionList, getFirstValue } from '../tools'

export const LeadSourceTypeSelect = (props: any) => {
  const options = [
    { name: 'Digital', value: 'digital' },
    { name: 'Outbound', value: 'outbound' },
    { name: 'Inbound', value: 'inbound' }
  ]
  return (
    <FiltersOptionList
      options={options}
      onFilterValueChanged={props.onFilterValueChanged}
      selectedValue={getFirstValue(
        props.filterValues.account?.lead_campaign?.lead_source?.kind?.value
      )}
    />
  )
}