/* Data utils for users module */
import gql from 'graphql-tag'
// Builds list users query
export const listUsersQL = () => ` query users {
    users {
      user_uid
      full_name
    }
  }
`
export const qlqListUsers = gql`
  query users {
    users {
      user_uid
      full_name
      headshot_url
      photo_url
    }
  }
`

export const qlqGetUser = gql`
  query user($user_uid: String!) {
    user(user_uid: $user_uid) {
      user_uid
      full_name
      headshot_url
      photo_url
      department {
        name
      }
      contract {
        profile {
          name
        }
      }
      contract_start_date
    }
  }
`

export const qlqListUsersQuery = gql`
  query user_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
    $master_uid: String!
    $instance_uid: String!
  ) {
    master(master_uid: $master_uid) {
      instance(instance_uid: $instance_uid) {
        user_list_qm_paged(
          filter: $filter
          limit: $limit
          offset: $offset
          sort: $sort
          fields: $fields
          aggregate: $aggregate
        ) {
          count
          data {
            user_uid
            full_name
            is_active
            department_uid
          }
        }
      }
    }
  }
`

export const usersToOptions = users =>
  users
    ? users
        .map(u => ({ name: u.full_name, value: u.user_uid }))
        .sort((a, b) =>
          a.full_name ? a.full_name.localeCompare(b.full_name) : 0
        )
    : undefined
