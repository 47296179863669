import { CrmDropdown } from 'crm-components'
import gql from 'graphql-tag'
import React from 'react'
import { Query } from 'react-apollo'
import { string, shape } from 'prop-types'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import { LoadingIcon } from 'util-components'

const qlqListCamsSalesAgents = gql`
  query supervisor($master_uid: String!) {
    master(master_uid: $master_uid) {
          master_uid
          name
          supervisor {
                user_uid
                name
                is_active
          }
       }
  }                                    
`

const CrmSupervisorSelect = props => {
  const variables = {
    master_uid: props.crmContext.master.master_uid ?? null
  }
  return (
    <Query query={qlqListCamsSalesAgents} variables={variables}>
      {({ loading, error, data }) => {
        const options =
          data.master &&
          data.master.supervisor
            ? data.master.supervisor.map(v => ({
                name: v.name,
                value: v.user_uid
              }))
            : []
        options.sort((a, b) => {
          const va = a && a.name ? a.name : ''
          const vb = b && b.name ? b.name : ''
          return va.localeCompare(vb)
        })
        return (
          <div className="util-flexRow">
            <div className="util-flexGrow">
              <CrmDropdown
                {...props}
                options={options}
                placeholder={props.placeholder}
                addEmptyOption
                disabled={loading}
              />
            </div>
            <div className="util-marginLeftSm">
              {loading && (
                <span>
                  <LoadingIcon />
                </span>
              )}
            </div>
          </div>
        )
      }}
    </Query>
  )
}

CrmSupervisorSelect.propTypes = {
  placeholder: string.isRequired,
  crmContext: shape({}).isRequired
}

export default withCrmContext(CrmSupervisorSelect)
