import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, change } from 'redux-form'
import { CSVLink } from 'react-csv'
import json2csv from 'json2csv'
import { bool, func, arrayOf, string, shape } from 'prop-types'
import { Print, NoPrint } from 'react-easy-print'
import ReportForm from './components/report-form.jsx'
import ReportsResultRenderer from './components/report-result-renderer.jsx'
import { CrmFullpageContainer, CrmHbox, CrmButton } from 'crm-components'

import {
  fetchReports,
  getIsFetching,
  getReports,
  generateReport,
  getIsGenerating,
  getReportResults
} from 'crm-duxs/report-reducer'

class Reports extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.columns = [
      {
        Header: 'Result',
        accessor: ''
      }
    ]
    this.state = { layout: 'new' }
  }

  handleLayoutChanged = layout => {
    console.dir(layout)
    this.setState({ ...this.state, layout })
  }

  componentDidMount() {
    this.props.onFetch()
  }

  handleFormSubmit = values => {
    console.log('Run report : ', values)
    this.props.onGenerate(values)
  }

  handlePrint = () => {
    console.log('handlePrint')
    window.print()
  }
  render() {
    if (this.props.fetch) {
      return <h3>Loading...</h3>
    }

    let csvLink = ''
    if (this.props.results.length > 0) {
      const row = this.props.results[0]
      const fields = Object.keys(row)
      const csvData = json2csv({ data: this.props.results, fields })
      csvLink = (
        <p>
          <CSVLink
            data={csvData}
            filename={'report.csv'}
            className="btn btn-primary"
            target="_blank"
          >
            Download csv
          </CSVLink>
          <CrmButton label="Print" xsmall onClick={this.handlePrint} />
        </p>
      )
    }

    return (
      <div>
        <Print exclusive name="report-text">
          <div
            style={{
              width: '100%',
              height: 'auto'
            }}
          >
            <ReportsResultRenderer
              layout={this.state.layout}
              results={this.props.results}
              generating={this.props.generating}
            />
          </div>
        </Print>

        <CrmFullpageContainer>
          <CrmHbox fill style={{ height: '100%' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
              className="util-paddingMd"
            >
              <div>{csvLink}</div>
              <div style={{ flex: 1, overflow: 'auto' }}>
                <ReportsResultRenderer
                  layout={this.state.layout}
                  results={this.props.results}
                  generating={this.props.generating}
                />
              </div>
            </div>
            <NoPrint>
              <div
                style={{
                  width: 680,
                  height: '100%',
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
                className="util-paddingMd"
              >
                <ReportForm
                  onSubmit={this.handleFormSubmit}
                  reports={this.props.reports}
                  reportSelected={this.props.reportSelected}
                  onSetField={this.props.onSetField}
                  onLayoutChanged={this.handleLayoutChanged}
                  layout={this.state.layout}
                  isGenerating={this.props.generating}
                />
              </div>
            </NoPrint>
          </CrmHbox>
        </CrmFullpageContainer>
      </div>
    )
  }
}

Reports.defaultProps = {
  reportSelected: undefined
}

Reports.propTypes = {
  reportSelected: string,
  fetch: bool.isRequired,
  generating: bool.isRequired,
  onFetch: func.isRequired,
  onGenerate: func.isRequired,
  onSetField: func.isRequired,
  reports: arrayOf(shape({})).isRequired,
  results: arrayOf(shape({})).isRequired
}

function mapStateToProps(state) {
  const selector = formValueSelector('crmReport')
  return {
    fetch: getIsFetching(state),
    generating: getIsGenerating(state),
    reports: getReports(state),
    results: getReportResults(state),
    reportSelected: selector(state, 'report')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onFetch: () => dispatch(fetchReports()),
    onGenerate: values => dispatch(generateReport(values)),
    onSetField: (field, value) => dispatch(change('crmReport', field, value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
