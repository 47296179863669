import React from 'react'
import { groupDataByMonth } from 'crm-utils/data-utils'
import { createDashboardFormats, useGetSaleProCesses, getWidgetAnalyticContext } from '../../dashboards/tools'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import moment from 'moment'
import { GQLQueryRes, DashboardResponse, HeadCount } from 'crm-types'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

const INVALID_LEAD_FILTER = {
  current_step_type_code: {
    $in: ['bogus', 'lost_unreachable', 'duplicate', 'lost_contact_lost', 'lost_no_market', 'lost_wrong_number']
  }
}
export interface WidgetInvalidLeadsHocProps {
  avgInValidLeadsCurrentMonthPerPerson: number | null
  avgInValidLeadsPassedMonthData: number | any[any]
  avgInValidLeadsPersonPassedMonth: number | null
  avgInValidLeadsThreeMonth: number
  footerName: string | null
  footerNamePerPerson: string | null
  isLoading?: boolean
  refetchData?: () => any
  threeMonthAgoInValidLeads: any[any]
  totalInValidLeads: number
  onClickDetail?: Function
  mainCaption?: string
}

type useGetInvalidLeadsByMonth = (numberOfMonth?: number, headcounts?: HeadCount[]) => DashboardResponse & GQLQueryRes

export const useGetInvalidLeadsByMonth: useGetInvalidLeadsByMonth = (numberOfMonth = 3, headcounts) => {
  const analyticsContext = getWidgetAnalyticContext()

  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const filter: any = {
    ...INVALID_LEAD_FILTER,
    organization_unit,
    owner_user_uid: headcounts ? undefined : userUid,
    completed_time: {
      $gte: startDate,
      $lte: endDate
    }
  }

  const { listSaleProcesses, loading, refetch } = useGetSaleProCesses(
    filter,
    [' owner_user_uid', 'completed_date_month'],
    [{ id: 'process_uid', type: 'count' }]
  )

  const grouped = groupDataByMonth(listSaleProcesses, 'completed_date_month', 'process_uid')

  return {
    refetch,
    loading,
    ...createDashboardFormats(
      grouped,
      numberOfMonth,
      moment(endDate, DATE_FORMAT).format('YYYY-MM'),
      headcounts,
      'Agent'
    )
  }
}

export const widgetInvalidLeadsHoc = (Component: any) => {
  const Wrapper = props => {
    const analyticsContext = getWidgetAnalyticContext()
    const { headcounts } = props

    const {
      avgCurrentMonthPerPerson,
      dataAvgPerPersonPassedMonth,
      avgPerPersonPassedMonth,
      avgPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      dataPassedMonths,
      totalLatestMonth,
      mainCaption
    } = useGetInvalidLeadsByMonth(3, headcounts)
    const onClickDetail = monthSelected => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)

      const filters = {
        ...INVALID_LEAD_FILTER,
        organization_unit,
        owner_user_uid: headcounts ? undefined : userUid,
        completed_time: convertMonthToDateRange(endDate)
      }

      if (monthSelected) {
        filters.completed_time = monthSelected
      }

      props.onShowLeadsPopup({ type: 'sales_process', filters })
    }

    return (
      <Component
        avgInValidLeadsCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgInValidLeadsPassedMonthData={dataAvgPerPersonPassedMonth}
        avgInValidLeadsPersonPassedMonth={avgPerPersonPassedMonth}
        avgInValidLeadsThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        onClickDetail={onClickDetail}
        refetchData={() => refetch()}
        threeMonthAgoInValidLeads={dataPassedMonths}
        totalInValidLeads={totalLatestMonth}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetInvalidLeadsHoc
