import React from 'react'
import cloneDeep from 'clone-deep'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons/faMapMarker'
import { faStickyNote } from '@fortawesome/free-solid-svg-icons/faStickyNote'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faFont } from '@fortawesome/free-solid-svg-icons/faFont'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow'
import { toastr } from 'react-redux-toastr'
import {
  CrmButton,
  CrmUsersSelect,
  CrmDropdown,
  CrmDropdownButton,
  CrmTextInput,
  CrmMessageBlock
} from 'crm-components'
import { getDateTimeFormat } from '../../../utils/time-utils'
import styles from './meeting-form-inner.scss'
import SectionDateTime from './section-datetime/section-datetime.jsx'
import SectionGuests from './section-guests/section-guests.jsx'
import SectionAddress from './section-address/section-address.jsx'
import SectionParticipants from './section-participants/section-participants.jsx'
import { connect } from 'react-redux'
import { getUser } from 'crm-duxs/crm-reducer'
import { getActivityTypesByMediumCode } from 'crm-duxs/activities-reducer'
import { actions as activityActions } from 'crm-duxs/activity-reducer'
import { RadioInput } from 'util-components'
import MeetingInlineEditable from './meeting-inline-editable.jsx'
import editMeetingHoc, {
  getIsValidMeetingAddress
} from 'crm-modules/meeting/hoc/edit-meeting-hoc.js'
import { getLocationDisplayValue } from 'crm-modules/accounts/hoc/section-addresses-hoc.js'
import AfterMeetingSurveyView from '../../activities/component/survey/after-meeting-survey-view.jsx'
import moment from 'moment'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { func, shape, bool, array, arrayOf } from 'prop-types'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import { get } from 'lodash'

class MeetingFormInner extends React.PureComponent {
  constructor(props) {
    super(props)
    let activity = cloneDeep(props.activity)
    activity = this.props.setDefaultLocationType(activity)
    const {
      arrangedBy,
      host,
      guests,
      participants
    } = this.props.mapApiToMeetingPersons(activity)
    this.state = {
      activity,
      arrangedBy,
      host,
      guests,
      participants,
      isValidForm: true,
      isValidParticipants: this.props.isValidParticipants,
      participantsErrors: this.props.participantsErrors,
      isNew: !activity.activity_uid
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account !== this.props.account) {
      this.setState({
        account: cloneDeep(nextProps.account)
      })
    }
    if (nextProps.persons !== this.props.persons) {
      this.setState({
        persons: cloneDeep(nextProps.persons)
      })
    }
    if (nextProps.addresses !== this.props.addresses) {
      this.setState({
        addresses: cloneDeep(nextProps.addresses)
      })
    }
    if (nextProps.activity !== this.props.activity) {
      let activity = cloneDeep(nextProps.activity)
      activity = this.props.setDefaultLocationType(activity)
      const {
        arrangedBy,
        host,
        guests,
        participants
      } = this.props.mapApiToMeetingPersons(activity)
      this.setState({ activity, arrangedBy, host, guests, participants })
    }
    if (nextProps.participantsErrors !== this.props.participantsErrors) {
      this.setState({
        participantsErrors: cloneDeep(nextProps.participantsErrors)
      })
    }
    if (nextProps.isValidParticipants !== this.props.isValidParticipants) {
      this.setState({
        isValidParticipants: cloneDeep(nextProps.isValidParticipants)
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activity !== prevState.activity) {
      this.setState({
        isValidForm: this.props.validateForm(
          this.state.activity,
          this.state.host
        )
      })
    }
  }

  componentDidMount() {
    this.setState({
      isValidForm: this.props.validateForm(this.state.activity, this.state.host)
    })
  }

  handleSave = async event => {
    await this.props.onSubmit(this.state.activity)
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  handleEditStart = (e, mode) => {
    e.stopPropagation()
    this.setState({
      mode: mode
    })
  }

  handleEditEnd = () => {
    this.setState({
      mode: ''
    })
  }

  handleMeetingTypeChange = (value, title, item) => {
    const activity = { ...this.state.activity }
    activity.activity_type_uid = value
    activity.activity_type.name = item.name
    this.setState({ activity })
    if (this.state.mode === 'meetingType') {
      this.handleEditEnd()
    }
  }

  handleSubjectChange = e => {
    const activity = { ...this.state.activity }
    activity.subject = e.currentTarget.value
    this.setState({ activity })
  }

  handleTimeChange = time => {
    const activity = { ...this.state.activity }
    activity.start_time = time
    this.setState({ activity })
  }

  handleMeetingAddressChange = address => {
    const activity = { ...this.state.activity }
    activity.address_uid = address ? address.address_uid : null
    this.setState({ activity })
  }

  handleLocationTypeChange = location_type => {
    const activity = { ...this.state.activity }
    activity.location_type = location_type
    this.setState({ activity })
  }

  handleVehicleChange = value => {
    const activity = { ...this.state.activity }
    activity.vehicle_id = value
    this.setState({ activity })
  }

  handleNotesChange = e => {
    const activity = { ...this.state.activity }
    activity.note_text = e.currentTarget.value
    this.setState({ activity })
  }

  handleStatusChange = value => {
    const activity = { ...this.state.activity }
    activity.activity_status = this.props.availableStatuses.filter(
      v => v.activity_status_uid === value
    )[0]
    activity.activity_status_uid = value
    this.setState({ activity })
    this.handleEditEnd()
  }

  handleHostChange = (value, user) => {
    const fullName = get(user, 'full_name', '')

    this.setState(
      {
        host: {
          user: {
            user_uid: value,
            username: fullName,
            full_name: fullName
          }
        }
      },
      () => {
        this.mapMeetingPersonsToApi('host', [this.state.host.user])
      }
    )

    if (this.state.mode === 'host') {
      this.handleEditEnd()
    }
  }

  handleArrangedByChange = value => {
    this.setState(
      {
        arrangedBy: {
          user: {
            user_uid: value
          }
        }
      },
      () => {
        this.mapMeetingPersonsToApi('arrangedBy', [this.state.arrangedBy.user])
      }
    )
    if (this.state.mode === 'arrangedBy') {
      this.handleEditEnd()
    }
  }

  handleParticipantsChange = participants => {
    this.setState({ participants })
    this.mapMeetingPersonsToApi('participants', participants)
  }

  handleGuestsChange = guests => {
    this.setState({ guests })
    this.mapMeetingPersonsToApi('guests', guests)
  }

  handleCancelMeetingChange = async (value, item) => {
    var r = confirm(
      `Are you sure you want to cancel this meeting ? ${item.name}`
    )
    if (r === true) {
      try {
        const res = await this.props.handleSaveStatus(
          this.state.activity,
          value
        )
        console.log('Meeting canceled', res, this.props)
        toastr.success('Meeting canceled')
        this.props.onCancel()
      } catch (err) {
        this.props.handleErrors(err)
        console.log('Cancel meeting error', err)
        toastr.error('Cancel meeting error')
      }
    }
  }

  handleConfirmMeeting = async () => {
    const confirmMessage = this.isCurrentStatusConfirm()
      ? 'Un-Confirm this meeting ?'
      : 'Confirm this meeting ?'

    let value
    if (this.isCurrentStatusConfirm()) {
      value = this.getStatusForUnConfirm().activity_status_uid
    } else {
      if (!getIsValidMeetingAddress(this.state.activity)) {
        toastr.error('Address is required')
        return
      }
      value = this.getStatusForConfirm().activity_status_uid
    }

    var r = confirm(confirmMessage)
    if (r === true) {
      try {
        const res = await this.props.handleSaveStatus(
          this.state.activity,
          value
        )
        console.log('Meeting status updated', res, this.props)
        toastr.success('Meeting status updated')
        this.props.onCancel()
      } catch (err) {
        this.props.handleErrors(err)
        console.log('Update meeting status error', err)
        toastr.error('Update meeting status error')
      }
    }
  }

  handleDeleteMeeting = async () => {
    var r = confirm('Are you sure you want to delete this meeting ?')
    if (r === true) {
      try {
        const is_deleted = true
        const res = await this.props.handleDeleteMeeting(
          this.state.activity,
          is_deleted
        )
        console.log('Meeting deleted', res, this.props)
        toastr.success('Meeting deleted')
        this.props.onCancel()
      } catch (err) {
        console.log('Delete meeting error', err)
        toastr.error('Delete meeting error')
      }
    }
  }

  handleCreateMeetingMinutes = async minutes => {
    const isafter = moment(this.state.activity.start_time).isAfter(moment())
    if (isafter) {
      toastr.error("You can't create meeting minutes for future meeting.")
      return
    }

    // try {
    //   const res = await this.props.handleCompleteMinutes(minutes)
    //   console.log('Meeting minutes saved', res, this.props)
    //   toastr.success('Meeting minutes saved')
    //   this.props.onCancel()
    // } catch (err) {
    //   console.log('Save meeting minutes error', err)
    //   toastr.error('Save meeting minutes error')
    // }

    this.props.doEndActivityEdit()
    setTimeout(() => {
      this.props.onGotoMinutes()
    })
  }

  handleCompleteMeeting = async () => {
    try {
      await this.props.handleSaveMeetingCompleteState(this.state.activity, true)
      toastr.success('Meeting completed')
    } catch (err) {
      console.error('Complete meeting error', err)
      toastr.error('Complete meeting error')
    }
  }

  handleUnlockCompletedMeeting = async () => {
    try {
      await this.props.handleSaveMeetingCompleteState(
        this.state.activity,
        false
      )
      toastr.success('Meeting unlocked')
    } catch (err) {
      console.error('Unlock meeting error', err)
      toastr.error('Unlock meeting error')
    }
  }

  getParseUpdateParticipantsToServerByKind = (kind, activity, newValues) => {
    let oldPersons = []
    let oldPersonsIds = []
    let newPersonsIds = []

    // Compare new values and old values
    // If not exist in old values => api add participants
    // If not exist in new values => api remvoe participants
    if (kind === 'host') {
      newPersonsIds = [newValues]
      oldPersons = activity.participants.filter(
        v => v.role_code === 'performer' && v.activity_participant_uid
      )[0]
      oldPersonsIds = oldPersons ? [oldPersons.user_uid] : []
    }
    if (kind === 'arrangedBy') {
      newPersonsIds = [newValues]
      oldPersons = activity.participants.filter(
        v => v.role_code === 'arranged_by' && v.activity_participant_uid
      )[0]
      oldPersonsIds = oldPersons ? [oldPersons.user_uid] : []
    }
    if (kind === 'guests') {
      newPersonsIds = newValues.map(v => v.account_person_uid)
      oldPersons = activity.participants.filter(
        v => v.role_code === 'participant' && v.person !== null
      )
      oldPersonsIds = oldPersons.map(v => v.participant_account_person_uid)
    }
    if (kind === 'participants') {
      newPersonsIds = newValues.map(v => v.user_uid)
      oldPersons = activity.participants.filter(
        v => v.role_code === 'participant' && v.user !== null
      )
      oldPersonsIds = oldPersons.map(v => v.user_uid)
    }

    const createPersonUid = newPersonsIds.filter(
      v => !oldPersonsIds.find(v2 => v === v2)
    )
    const removePersonUid = oldPersonsIds.filter(
      v => !newPersonsIds.find(v2 => v === v2)
    )

    // API format
    const createParticipants = [...createPersonUid].map(v => {
      if (kind === 'host') {
        return {
          user_uid: v,
          role_code: 'performer',
          is_primary: true
        }
      }
      if (kind === 'arrangedBy') {
        return {
          user_uid: v,
          role_code: 'arranged_by',
          is_primary: false
        }
      }
      if (kind === 'guests') {
        return {
          participant_account_person_uid: v,
          role_code: 'participant',
          is_primary: false
        }
      }
      if (kind === 'participants') {
        return {
          role_code: 'participant',
          user_uid: v,
          is_primary: false
        }
      }
    })
    const removeParticipants = [...removePersonUid].map(v => {
      if (kind === 'host') {
        return {
          activity_participant_uid: oldPersons.activity_participant_uid
        }
      }
      if (kind === 'arrangedBy') {
        return {
          activity_participant_uid: oldPersons.activity_participant_uid
        }
      }
      if (kind === 'guests') {
        return {
          activity_participant_uid: oldPersons.filter(
            v2 => v2.participant_account_person_uid === v
          )[0].activity_participant_uid
        }
      }
      if (kind === 'participants') {
        return {
          activity_participant_uid: oldPersons.filter(
            v2 => v2.user_uid === v
          )[0].activity_participant_uid
        }
      }
    })

    return { createParticipants, removeParticipants }
  }

  mapMeetingPersonsToApi = (kind, users) => {
    const activity = { ...this.state.activity }
    let participants = []

    if (kind === 'host') {
      participants = activity.participants.filter(
        v => v.role_code !== 'performer'
      )
      participants.push({
        role_code: 'performer',
        is_primary: true,
        user_uid: users[0].user_uid
      })
      activity.primary_performer_user_uid = users[0].user_uid
    }

    if (kind === 'arrangedBy') {
      participants = activity.participants.filter(
        v => v.role_code !== 'arranged_by'
      )
      participants.push({
        role_code: 'arranged_by',
        is_primary: false,
        kind: 'user',
        user_uid: users[0].user_uid
      })
      activity.arranged_by_user_uid = users[0].user_uid
    }

    if (kind === 'guests') {
      participants = activity.participants.filter(
        v => !(v.role_code === 'participant' && v.kind === 'person')
      )
      users.map(v => {
        participants.push({
          role_code: 'participant',
          kind: 'person',
          participant_account_person_uid: v.account_person_uid,
          is_primary: false
        })
      })
    }

    if (kind === 'participants') {
      participants = activity.participants.filter(
        v => !(v.role_code === 'participant' && v.kind === 'user')
      )
      users.map(v => {
        participants.push({
          role_code: 'participant',
          kind: 'user',
          user_uid: v.user_uid,
          is_primary: false,
          // map from user selector, use same data format as loading from api
          user: {
            email: v.email,
            full_name: v.full_name
          }
        })
      })
    }

    activity.participants = participants
    this.setState({ activity })
  }

  getMeetingTypeOptions = () => {
    const meetingTypeOptions = this.props.meetingTypes
      ? this.props.meetingTypes.map(mt => ({
          value: mt.activity_type_uid,
          name: mt.name
        }))
      : []
    return meetingTypeOptions
  }

  isCurrentStatusConfirm = () => {
    return this.getCurrentStatusCode() === 'sheduled_confirmed'
  }

  getStatusForNewOptions = () => {
    const statusForNewOptions = this.props.availableStatuses.reduce(
      (ret, v) => {
        if (v.is_initial) {
          ret.push({ value: v.activity_status_uid, label: v.description })
        }
        return ret
      },
      []
    )
    return statusForNewOptions
  }

  getStatusIcon = () => {
    if (this.isCurrentStatusConfirm()) {
      return faCheck
    } else {
      return faEllipsisH
    }
  }

  getStatusForCancelOptions = () => {
    const statusForCancelOptions = this.props.availableStatuses.reduce(
      (ret, v) => {
        if (v.kind === 'canceled') {
          ret.push({
            value: v.activity_status_uid,
            name: v.description,
            label: v.description,
            onClick: (value, item) =>
              this.handleCancelMeetingChange(v.activity_status_uid, v)
          })
        }
        return ret
      },
      []
    )

    return statusForCancelOptions
  }

  getCurrentStatusCode = () => {
    return this.state.activity && this.state.activity.activity_status
      ? this.state.activity.activity_status.code
      : undefined
  }

  getStatusForConfirm = () => {
    return this.props.availableStatuses.filter(
      v => v.code === 'sheduled_confirmed'
    )[0]
  }

  getStatusForUnConfirm = () => {
    return this.props.availableStatuses.filter(
      v => v.code === 'sheduled_not_confirmed'
    )[0]
  }

  _getGuestsValue = guests => {
    return guests
  }

  _getGuestsDisplayValue = guests => {
    return guests.map(v => v.name).join(', ')
  }

  _getParticipantsValue = participants => {
    return participants
  }

  _getParticipantsDisplayValue = participants => {
    return participants.map(v => v.full_name).join(', ')
  }

  _renderErrors = () => {
    return (
      this.props.errorMessages.length > 0 && (
        <div className={styles.Error}>
          <div>
            <i className="fa fa-times-circle" />
          </div>
          <div>
            <div className={styles.ErrorMessagesWrapper}>
              {this.props.errorMessages.map((v, index) => (
                <div key={index}>{v}</div>
              ))}
            </div>
          </div>
        </div>
      )
    )
  }

  render() {
    console.log('render meeting-form-inner', this.props, this.state)
    const { activity, mode, isNew } = this.state
    const {
      account,
      persons,
      addresses,
      validationRules,
      hiddenFields,
      disabledFields,
      isMeetingCompleted
    } = this.props
    const meetingAddress =
      addresses.filter(
        v => v.address_uid && v.address_uid === activity.address_uid
      )[0] || null
    const location_type = activity.location_type
    const isFinalStatus =
      activity.activity_status && activity.activity_status.is_result
    const isEditable = !isFinalStatus && !isMeetingCompleted
    let meetingItemClass = styles.MeetingItem
    let wrapperClasses = styles.MeetingForm
    if (isEditable) {
      if (mode === 'datetime') {
        wrapperClasses += ' ' + styles['MeetingForm--modeDateTime']
      }
      if (mode === 'address') {
        wrapperClasses += ' ' + styles['MeetingForm--modeAddress']
      }
      if (mode === 'guests') {
        wrapperClasses += ' ' + styles['MeetingForm--modeGuests']
      }
      if (mode === 'participants') {
        wrapperClasses += ' ' + styles['MeetingForm--modeParticipants']
      }
    }
    console.log('render meeting-form-inner', activity)
    const meeting_minutes_code = activity?.activity_type?.meeting_minutes_code
      ? activity.activity_type.meeting_minutes_code
      : undefined
    const meeting_completed_at = activity.completed_at

    return (
      <div
        className="util-marginTop util-marginBottom"
        style={{ height: '100%' }}
      >
        {/* {this._renderErrors()} */}

        <div className={wrapperClasses}>
          {/* Arranged by */}
          <div className={`${meetingItemClass}`}>
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faUser} />
            </div>
            {isNew && (
              <div id="arrangedBy">
                <CrmUsersSelect
                  style={{ marginLeft: 5, marginRight: 5, width: 200 }}
                  placeholder={
                    <FormattedMessage
                      id="crm.activity.arranged_by"
                      defaultMessage="Arranged by"
                    />
                  }
                  id="meeting-arranged-by"
                  input={{
                    value: this.state.arrangedBy
                      ? this.state.arrangedBy.user.user_uid
                      : '',
                    onChange: this.handleArrangedByChange
                  }}
                />
              </div>
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="arrangedBy"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.arranged_by',
                  defaultMessage: 'Arranged by'
                })}
                value={this.state.arrangedBy.user.user_uid}
                displayValue={this.state.arrangedBy.user.full_name}
                editable={isEditable && !disabledFields.arranged_by}
                readModeStyle="CrmLabeledField"
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                editComponent={CrmUsersSelect}
                editComponentProps={{
                  style: { marginLeft: 5, marginRight: 5, width: 200 },
                  placeholder: (
                    <FormattedMessage
                      id="crm.activity.arranged_by"
                      defaultMessage="Arranged by"
                    />
                  ),
                  id: 'meeting-arranged-by'
                }}
                handleStartEdit={e => this.handleEditStart(e, 'arrangedBy')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
                getParseUpdateParticipantsToServerByKind={
                  this.getParseUpdateParticipantsToServerByKind
                }
              />
            )}
          </div>

          {/* Host */}
          <div className={`${meetingItemClass}`}>
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faUser} />
            </div>
            {isNew && (
              <div id="host">
                <CrmUsersSelect
                  style={{ marginLeft: 5, marginRight: 5, width: 200 }}
                  placeholder={
                    <FormattedMessage
                      id="crm.activity.host"
                      defaultMessage="Host"
                    />
                  }
                  id="meeting-host"
                  input={{
                    value: this.state.host ? this.state.host.user.user_uid : '',
                    onChange: this.handleHostChange
                  }}
                />
              </div>
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="host"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.host',
                  defaultMessage: 'Host'
                })}
                value={
                  this.state.host && this.state.host.user.user_uid
                    ? this.state.host.user.user_uid
                    : ''
                }
                displayValue={
                  this.state.host && this.state.host.user.user_uid
                    ? this.state.host.user.username ||
                      this.state.host.user.full_name
                    : ''
                }
                emptyValue="No host - Click here to select"
                editable={isEditable && !disabledFields.host}
                readModeStyle="CrmLabeledField"
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                editComponent={CrmUsersSelect}
                editComponentProps={{
                  style: { marginLeft: 5, marginRight: 5, width: 200 },
                  placeholder: (
                    <FormattedMessage
                      id="crm.activity.host"
                      defaultMessage="Host"
                    />
                  ),
                  id: 'meeting-host'
                }}
                handleStartEdit={e => this.handleEditStart(e, 'host')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
                getParseUpdateParticipantsToServerByKind={
                  this.getParseUpdateParticipantsToServerByKind
                }
              />
            )}
          </div>

          {/* Guests */}
          <div
            id="guests"
            className={`${meetingItemClass} ${styles.MeetingForm__GuestsWrapper}`}
          >
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faUserFriends} />
            </div>
            {isNew && (
              <SectionGuests
                account={account}
                persons={persons}
                isExpanded={this.state.mode === 'guests'}
                handleEditEnd={this.handleEditEnd}
                handleEditStart={e => this.handleEditStart(e, 'guests')}
                guests={this.state.guests}
                activity={activity}
                handleGuestsChange={this.handleGuestsChange}
                _getGuestsValue={this._getGuestsValue}
                _getGuestsDisplayValue={this._getGuestsDisplayValue}
                guestsValidationRules={
                  validationRules && validationRules.guests
                    ? validationRules.guests
                    : {}
                }
              />
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="guests"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.guests',
                  defaultMessage: 'Guests'
                })}
                value={this._getGuestsValue(this.state.guests)}
                displayValue={this._getGuestsDisplayValue(this.state.guests)}
                editable={isEditable}
                readModeStyle=""
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                emptyValue="No guests - Click here to select"
                editComponent={SectionGuests}
                editComponentWrapperStyles={{ padding: '20px' }}
                editComponentProps={{
                  account: account,
                  persons: persons,
                  isExpanded: mode === 'guests',
                  guests: this.state.guests,
                  activity: activity,
                  handleGuestsChange: this.handleGuestsChange,
                  _getGuestsValue: this._getGuestsValue,
                  _getGuestsDisplayValue: this._getGuestsDisplayValue,
                  guestsValidationRules:
                    validationRules && validationRules.guests
                      ? validationRules.guests
                      : {}
                }}
                handleStartEdit={e => this.handleEditStart(e, 'guests')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
                getParseUpdateParticipantsToServerByKind={
                  this.getParseUpdateParticipantsToServerByKind
                }
              />
            )}
          </div>

          {/* Meeting type */}
          <div className={`${meetingItemClass}`}>
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faTag} />
            </div>
            {isNew && (
              <div id="meetingType">
                <CrmDropdown
                  placeholder={
                    <FormattedMessage
                      id="crm.activity.meeting_type"
                      defaultMessage="Meeting type"
                    />
                  }
                  options={this.getMeetingTypeOptions()}
                  input={{
                    value: activity.activity_type_uid
                      ? activity.activity_type_uid
                      : '',
                    onChange: (value, title, item) => {
                      this.handleMeetingTypeChange(value, title, item)
                    }
                  }}
                />
              </div>
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="activity_type_uid"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.meeting_type',
                  defaultMessage: 'Meeting type'
                })}
                value={
                  activity.activity_type_uid ? activity.activity_type_uid : ''
                }
                displayValue={
                  activity.activity_type_uid ? activity.activity_type.name : ''
                }
                editable={isEditable}
                readModeStyle=""
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                emptyValue="No meeting type - Click here to select"
                editComponent={CrmDropdown}
                editComponentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.activity.meeting_type"
                      defaultMessage="Meeting type"
                    />
                  ),
                  options: this.getMeetingTypeOptions()
                }}
                handleStartEdit={e => this.handleEditStart(e, 'meetingType')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
              />
            )}
          </div>

          {/* Subject */}
          <div className={`${meetingItemClass}`}>
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faFont} />
            </div>
            {isNew && (
              <div id="subject">
                <CrmTextInput
                  placeholder={
                    <FormattedMessage
                      id="crm.activity.subject"
                      defaultMessage="Subject"
                    />
                  }
                  onChange={this.handleSubjectChange}
                  value={activity.subject ? activity.subject : ''}
                  className="CrmTextInput-noRightPadding"
                />
              </div>
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="subject"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.subject',
                  defaultMessage: 'Subject'
                })}
                value={activity.subject ? activity.subject : ''}
                editable={isEditable}
                readModeStyle=""
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                emptyValue="No subject - Click here to edit"
                editComponent={CrmTextInput}
                editComponentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.activity.subject"
                      defaultMessage="Subject"
                    />
                  ),
                  className: 'CrmTextInput-noRightPadding'
                }}
                handleStartEdit={e => this.handleEditStart(e, 'subject')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
              />
            )}

            {activity && !activity.subject && (
              <div className="CrmForm-field-error">
                *{' '}
                {this.props.intl.formatMessage({
                  id: `crm.activity.meeting_subject_is_required`,
                  defaultMessage: 'Subject is required'
                })}
              </div>
            )}
          </div>

          {/* Participants */}
          <div
            id="participants"
            className={`${meetingItemClass} ${styles.MeetingForm__ParticipantsWrapper}`}
          >
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faUsers} />
            </div>
            {isNew && (
              <SectionParticipants
                isExpanded={this.state.mode === 'participants'}
                handleEditEnd={this.handleEditEnd}
                handleHostChange={this.handleHostChange}
                handleEditStart={e => this.handleEditStart(e, 'participants')}
                participants={this.state.participants}
                activity={activity}
                handleParticipantsChange={this.handleParticipantsChange}
              />
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="participants"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.participants',
                  defaultMessage: 'Participants'
                })}
                value={this._getParticipantsValue(this.state.participants)}
                displayValue={this._getParticipantsDisplayValue(
                  this.state.participants
                )}
                editable={isEditable}
                readModeStyle="Participants"
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                emptyValue="No participants - Click here to select"
                editComponent={SectionParticipants}
                editComponentWrapperStyles={{ padding: '20px' }}
                editComponentProps={{
                  isExpanded: mode === 'participants',
                  participants: this.state.participants,
                  activity: activity,
                  handleParticipantsChange: this.handleParticipantsChange,
                  _getParticipantsValue: this._getParticipantsValue,
                  _getParticipantsDisplayValue: this
                    ._getParticipantsDisplayValue
                }}
                handleStartEdit={e => this.handleEditStart(e, 'participants')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
                getParseUpdateParticipantsToServerByKind={
                  this.getParseUpdateParticipantsToServerByKind
                }
                validateParticipants={this.props.validateParticipants}
              />
            )}

            {this.state.isValidParticipants === false && (
              <div className="CrmForm-field-error">
                *{' '}
                {this.props.intl.formatMessage({
                  id: `crm.activity.meeting_participant_with_email_is_required`,
                  defaultMessage: 'Participants must have emails'
                })}
              </div>
            )}
          </div>

          {/* Date Time */}
          <div
            id="datetime"
            className={`${meetingItemClass}
            ${styles['MeetingItem--withIcon']}
            ${styles.MeetingForm__DateTimeWrapper}`}
          >
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faClock} />
            </div>
            {isNew && (
              <SectionDateTime
                isExpanded={this.state.mode === 'datetime'}
                handleEditEnd={this.handleEditEnd}
                handleEditStart={e => this.handleEditStart(e, 'datetime')}
                time={activity.start_time}
                handleTimeChange={this.handleTimeChange}
                activity={activity}
                hostUid={this.state.host.user.user_uid}
              />
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="start_time"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.date_time',
                  defaultMessage: 'Date Time'
                })}
                icon="time"
                value={activity.start_time}
                displayValue={getDateTimeFormat(activity.start_time)}
                editable={isEditable}
                readModeStyle=""
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                emptyValue="No date and time - Click here to select"
                editComponent={SectionDateTime}
                editComponentWrapperStyles={{ padding: '20px' }}
                editComponentProps={{
                  isExpanded: mode === 'datetime',
                  time: activity.start_time,
                  handleTimeChange: this.handleTimeChange,
                  activity: activity,
                  hostUid: this.state.host ? this.state.host.user.user_uid : ''
                }}
                handleStartEdit={e => this.handleEditStart(e, 'datetime')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
              />
            )}

            {activity && !activity.start_time && (
              <div className="CrmForm-field-error">
                *{' '}
                {this.props.intl.formatMessage({
                  id: `crm.activity.meeting_time_is_required`,
                  defaultMessage: 'Time is required'
                })}
              </div>
            )}
          </div>

          {/* Location Type */}
          <div
            id="location_type"
            className={`${meetingItemClass} ${styles.MeetingForm__AddressWrapper}`}
          >
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faLocationArrow} />
            </div>
            {isNew && (
              <SectionAddress
                account_uid={account.account_uid}
                addresses={
                  addresses ? addresses.filter(v => v.address_uid) : []
                }
                isExpanded={mode === 'location_type'}
                handleEditEnd={this.handleEditEnd}
                handleEditStart={e => this.handleEditStart(e, 'location_type')}
                location_type={location_type}
                handleLocationTypeChange={this.handleLocationTypeChange}
                meetingAddress={meetingAddress}
                handleMeetingAddressChange={this.handleMeetingAddressChange}
                activity={activity}
              />
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="location_type"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.meeting_location_type',
                  defaultMessage: 'Meeting Location Type'
                })}
                value={location_type}
                displayValue={getLocationDisplayValue(
                  location_type,
                  meetingAddress
                )}
                editable={isEditable}
                readModeStyle=""
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                emptyValue={this.props.intl.formatMessage({
                  id: `crm.activity.no_location_type_click_here_to_select`,
                  defaultMessage: 'No location type - Click here to select'
                })}
                editComponent={SectionAddress}
                editComponentWrapperStyles={{ padding: '20px' }}
                editComponentProps={{
                  account_uid: account.account_uid,
                  addresses: addresses,
                  isExpanded: mode === 'address',
                  meetingAddress: meetingAddress,
                  location_type: location_type,
                  handleLocationTypeChange: this.handleLocationTypeChange,
                  activity: activity,
                  handleMeetingAddressChange: this.handleMeetingAddressChange
                }}
                handleStartEdit={e => this.handleEditStart(e, 'address')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
              />
            )}

            {activity && !activity.location_type && (
              <div className="CrmForm-field-error">
                *{' '}
                {this.props.intl.formatMessage({
                  id: `crm.activity.meeting_location_type_is_required`,
                  defaultMessage: 'Location type is required'
                })}
              </div>
            )}

            {!getIsValidMeetingAddress(activity) && (
              <div className="CrmForm-field-error">
                *{' '}
                {this.props.intl.formatMessage({
                  id: `crm.activity.meeting_address_is_required`,
                  defaultMessage: 'Address is required'
                })}
              </div>
            )}
          </div>

          {/* Meeting note */}
          <div className={`${meetingItemClass}`}>
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={faStickyNote} />
            </div>
            {isNew && (
              <div id="notes">
                <CrmTextInput
                  placeholder={
                    <FormattedMessage
                      id="crm.activity.meeting_notes"
                      defaultMessage="Meeting notes"
                    />
                  }
                  onChange={this.handleNotesChange}
                  value={activity.note_text ? activity.note_text : ''}
                  className="CrmTextInput-noRightPadding"
                />
              </div>
            )}

            {!isNew && (
              <MeetingInlineEditable
                activity={activity}
                fieldName="note_text"
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.meeting_notes',
                  defaultMessage: 'Meeting notes'
                })}
                value={activity.note_text}
                editable={isEditable}
                readModeStyle=""
                className="util-textUpdated"
                handleErrors={this.props.handleErrors}
                emptyValue="No notes - Click here to edit"
                editComponent={CrmTextInput}
                editComponentProps={{
                  placeholder: (
                    <FormattedMessage
                      id="crm.activity.meeting_notes"
                      defaultMessage="Meeting notes"
                    />
                  ),
                  className: 'CrmTextInput-noRightPadding'
                }}
                handleStartEdit={e => this.handleEditStart(e, 'notes')}
                onCancelInlineEdit={this.handleEditEnd}
                handleSave={this.handleEditEnd}
              />
            )}
          </div>

          {/* Status */}
          <div className={`${meetingItemClass}`}>
            <div className={styles.MeetingItem__Icon}>
              <FontAwesomeIcon icon={this.getStatusIcon()} />
            </div>

            <div id="status" className="LabeledField--lg">
              <div className="LabeledField-value">
                {isNew && (
                  <RadioInput
                    options={this.getStatusForNewOptions()}
                    input={{
                      value: activity.activity_status_uid,
                      onChange: this.handleStatusChange
                    }}
                    name="statusForNew"
                    extraClassNames={{
                      labelClassNames: 'col-md-4'
                    }}
                  />
                )}
                {!isNew && (
                  <div className="util-textUpdated">
                    {activity.activity_status.description}
                  </div>
                )}
              </div>
            </div>

            {activity && !activity.activity_status_uid && (
              <div className="CrmForm-field-error util-marginTopSm">
                *{' '}
                {this.props.intl.formatMessage({
                  id: `crm.activity.meeting_status_is_required`,
                  defaultMessage: 'Status is required'
                })}
              </div>
            )}
          </div>

          <div className={`${meetingItemClass}`} />

          {/* participantsErrors */}
          {this.state.participantsErrors &&
            this.state.participantsErrors.length > 0 && (
              <div className={`${meetingItemClass}`}>
                <CrmMessageBlock
                  messages={this.state.participantsErrors}
                  messageType="error"
                  disableAutoClose={true}
                />
              </div>
            )}
        </div>

        {/* Save new meeting */}
        {!hiddenFields.save_new_meeting_button && isNew && (
          <div className={`${styles.MeetingFormButtons} util-flexRowRight`}>
            <div>
              <CrmButton
                style={{ marginRight: 5 }}
                onClick={this.handleCancel}
                label={this.props.intl.formatMessage({
                  id: 'crm.ui.button.cancel',
                  defaultMessage: 'Cancel'
                })}
              />
            </div>
            <div>
              <CrmButton
                type="primary"
                onClick={this.handleSave}
                label={
                  this.props.isSavingNewMeeting
                    ? this.props.intl.formatMessage({
                        id: 'crm.ui.button.saving',
                        defaultMessage: 'Saving...'
                      })
                    : this.props.intl.formatMessage({
                        id: 'crm.ui.button.save',
                        defaultMessage: 'Save'
                      })
                }
                enable={
                  this.state.isValidForm && !this.props.isSavingNewMeeting
                }
              />
            </div>
          </div>
        )}

        <div
          className={`${styles.MeetingFormButtons} util-flexRowSpaceBetween`}
          style={
            hiddenFields.meeting_form_buttons_section ? { display: 'none' } : {}
          }
        >
          {/* Confirm Meeting */}
          {!hiddenFields.confirm_meeting_button && !isNew && isEditable && (
            <div id="meetingConfirm">
              <CrmButton
                type="primary"
                onClick={this.handleConfirmMeeting}
                label={
                  this.props.isUpdatingStatus
                    ? this.props.intl.formatMessage({
                        id: 'crm.ui.button.saving',
                        defaultMessage: 'Saving...'
                      })
                    : this.props.intl.formatMessage({
                        id: 'crm.ui.button.confirm',
                        defaultMessage: this.isCurrentStatusConfirm()
                          ? 'Un-Confirm'
                          : 'Confirm'
                      })
                }
                enable={!this.props.isUpdatingStatus}
              />
            </div>
          )}

          {/* Cancel Meeting */}
          {!hiddenFields.cancel_meeting_button && !isNew && isEditable && (
            <div id="meetingCancel">
              <CrmDropdownButton
                label={this.props.intl.formatMessage({
                  id: 'crm.activity.cancel_meeting',
                  defaultMessage: 'Cancel Meeting'
                })}
                type={'primary'}
                options={this.getStatusForCancelOptions()}
                style={{
                  whiteSpace: 'nowrap',
                  marginLeft: 5,
                  marginRight: 5,
                  width: 170
                }}
              />
            </div>
          )}

          {/* Create meeting munites */}
          {!hiddenFields.create_meeting_minutes_button && !isNew && (
            <React.Fragment>
              {meeting_minutes_code && (
                <div>
                  <CrmButton
                    type="primary"
                    grouped
                    label={
                      isFinalStatus
                        ? this.props.intl.formatMessage({
                            id: 'crm.ui.button.show_meeting_minutes',
                            defaultMessage: 'Show Meeting Minutes'
                          })
                        : this.props.intl.formatMessage({
                            id: 'crm.ui.button.create_meeting_minutes',
                            defaultMessage: 'Create Meeting Minutes'
                          })
                    }
                    onClick={this.handleCreateMeetingMinutes}
                  />
                </div>
              )}
              {!meeting_minutes_code && (
                <div>
                  <CrmButton
                    type="primary"
                    grouped
                    label={
                      meeting_completed_at
                        ? this.props.intl.formatMessage({
                            id: 'crm.ui.button.unlock_completed_meeting',
                            defaultMessage: 'Unlock Completed Meeting'
                          })
                        : this.props.intl.formatMessage({
                            id: 'crm.ui.button.complete_meeting',
                            defaultMessage: 'Complete Meeting'
                          })
                    }
                    onClick={
                      meeting_completed_at
                        ? this.handleUnlockCompletedMeeting
                        : this.handleCompleteMeeting
                    }
                    isSaving={this.props.isSavingCompleteState}
                    enable={!this.props.isSavingCompleteState}
                  />{' '}
                  {/* <div>{meeting_completed_at}</div> */}
                </div>
              )}
              {/* {!meeting_minutes_code && !meeting_completed_at && (
                <div>
                  <CrmButton
                    type="primary"
                    grouped
                    label={this.props.intl.formatMessage({
                      id: 'crm.ui.button.complete_meeting',
                      defaultMessage: 'Complete Meeting'
                    })}
                    onClick={this.handleCompleteMeeting}
                    isSaving={this.props.isSavingCompleteState}
                    enable={!this.props.isSavingCompleteState}
                  />
                </div>
              )}*/}
            </React.Fragment>
          )}

          {/* Delete meeting */}
          {!hiddenFields.delete_meeting_button && !isNew && (
            <div className="style19">
              <CrmButton
                onClick={this.handleDeleteMeeting}
                label={
                  this.props.isDeletetingMeeting
                    ? this.props.intl.formatMessage({
                        id: 'crm.ui.button.deleting',
                        defaultMessage: 'Deleting..'
                      })
                    : this.props.intl.formatMessage({
                        id: 'crm.ui.button.delete_activity',
                        defaultMessage: 'Delete Activity'
                      })
                }
                enable={
                  this.props.accountPermissionsContext.hasPermissionByType(
                    PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
                  ) && !this.props.isDeletetingMeeting
                }
              />
            </div>
          )}
        </div>

        {this._renderErrors()}

        {/* Meeting Munites View */}
        {/* todo : this is infinite loop error */}
        {/* {isFinalStatus && (
          <div className={styles.AfterMeetingSurveyViewWrapper}>
            <AfterMeetingSurveyView
              callerComponent="MeetingForm"
              activityUid={activity.activity_uid}
              accountUid={account.account_uid}
              onCancel={this.props.onCancel}
            />
          </div>
        )} */}

        {!isNew && (
          <div className={styles.Close}>
            <CrmButton
              style={{ marginRight: 5 }}
              onClick={this.handleCancel}
              label={this.props.intl.formatMessage({
                id: 'crm.ui.button.close',
                defaultMessage: 'Close'
              })}
            />
          </div>
        )}
      </div>
    )
  }
}

MeetingFormInner.propTypes = {
  errorMessages: array,
  activity: shape({}).isRequired,
  account: shape({}).isRequired,
  currentUser: shape({}).isRequired,
  persons: arrayOf(shape({})).isRequired,
  addresses: arrayOf(shape({})).isRequired,
  availableStatuses: arrayOf(shape({})).isRequired,
  meetingTypes: arrayOf(shape({})).isRequired,
  isSavingNewMeeting: bool,
  isDeletetingMeeting: bool,
  isUpdatingStatus: bool,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  handleErrors: func.isRequired,
  onGotoMinutes: func.isRequired,
  handleSaveStatus: func.isRequired,
  handleDeleteMeeting: func.isRequired,
  doEndActivityEdit: func.isRequired,
  accountPermissionsContext: shape({}).isRequired,
  intl: shape({}).isRequired,
  mapApiToMeetingPersons: func.isRequired,
  validateParticipants: func.isRequired,
  participantsErrors: arrayOf(shape({})).isRequired,
  isValidParticipants: bool,
  validationRules: shape({}),
  hiddenFields: shape({}),
  disabledFields: shape({}),
  isMeetingCompleted: bool,
  setDefaultLocationType: func.isRequired,
  validateForm: func.isRequired,
  handleSaveMeetingCompleteState: func.isRequired,
  isSavingCompleteState: bool
}

MeetingFormInner.defaultProps = {
  isSavingNewMeeting: false,
  isDeletetingMeeting: false,
  isUpdatingStatus: false,
  errorMessages: [],
  validationRules: {},
  hiddenFields: {},
  disabledFields: {},
  isMeetingCompleted: false,
  isValidParticipants: undefined
}

function mapStateToProps(state) {
  return {
    currentUser: getUser(state),
    meetingTypes: getActivityTypesByMediumCode(state, 'meeting')
  }
}
function mapDispatchToProps(dispatch) {
  return {
    doEndActivityEdit: () => dispatch(activityActions.endActivityEdit.create())
  }
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(editMeetingHoc(withAccountPermissionsContextHOC(MeetingFormInner)))
)
