import gql from 'graphql-tag'
export const qlProcessTypeFields = `process_type_uid name kind code is_default module is_main data`
export const qlProcessStepFields = `process_step_uid process_step_type_uid created_time completed_time completed_by_user_uid result_type_uid result_value due_date is_admin_mode permission_code  `

export const qlProcessOwnFields = ` process_uid instance_uid account_uid process_type_uid current_step_uid started_time completed_time completed_by_user_uid 
result_type_uid attributes `
export const qlProcessStepTypeDetails = `process_step_type_uid name kind is_final_step step data is_active code require_manage_permission for_validated_account for_not_validated_account `

export const qlProcessFields = ` 
process_uid process_type_uid 
started_time 
completed_time
process_type {${qlProcessTypeFields}} 
current_step {${qlProcessStepFields} 
  process_step_type { ${qlProcessStepTypeDetails} } } `

export const qlProcessWithCurrentStep = ` ${qlProcessOwnFields} 
  current_step { ${qlProcessStepFields} process_step_type { ${qlProcessStepTypeDetails}} } `

export const fragmentProcessWithFields = `
  fragment ProcessesFields on Process {
      ${qlProcessWithCurrentStep}
      process_type { ${qlProcessTypeFields} }
      owner_user {
        user_uid
        full_name
        photo_url
      }
      completed_by_user {
        user_uid
        full_name
      }
      owner_pool { 
        name
      }
      sales_campaign_uid
      sales_campaign { 
        campaign_uid
        name
      }
  }
`

export const accountProcessesFragment = `
fragment AccountProcesses on Account {
  processes {
    ...ProcessesFields
  }
}
${fragmentProcessWithFields}
`

export const qlqProcessWithFields = gql`
  query process($process_uid: String!) {
    process(process_uid: $process_uid) {
      ...ProcessesFields
    }
  }
  ${fragmentProcessWithFields}
`
