import React from 'react'
import { bool, func, shape } from 'prop-types'
import { Link } from 'react-router-dom'

import { FormattedDate, FormattedTime } from 'react-intl'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { CrmModal } from 'crm-components'
import genericTableFactory from 'crm-components/ql/table/crm-generic-dynamic-table.tsx'
import { allColumns } from './dynamic-tables/columns'

const initialColumns = [
  'account.name',
  'assigned_to_user_date',
  'owner.name',
  'owner.department_type.name',
  'account.lead_campaign.name',
  'account.customer_since_date',
  'won_by_user.name',
  'account.lead_in_date',
  'account.last_activity_date',
  'attempt_count',
  'sales_result_code',
  'detailed_status_code',
  'current_step_type_name',
  'sale_current_step_type_kind',
  'current_step_type.name'
]

const qlOptions = {
  options: ownProps => {
    const props = { analyticsContext: { filters: ownProps.filters } }
    const filter = { ...ownProps.filters, ...mapContextToFilter(props) }

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  qlOptions,
  rootQLType: 'SalesProcess',
  useEdges: true,
  columnOptions: allColumns,
  queryRoot: 'sales_processes'
})

const SalesProcessDetailsPopup = props => {
  return (
    <CrmModal
      isOpen={props.isOpen}
      title="Sales Processes Details"
      onClose={props.onClose}
      size="xl"
    >
      <Table
        style={{ width: '100%' }}
        filters={props.filters}
        initialColumns={props.initialColumns || initialColumns}
      />
    </CrmModal>
  )
}

SalesProcessDetailsPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: shape({})
}
SalesProcessDetailsPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

export default SalesProcessDetailsPopup
