import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate } from 'react-intl'
import { columnAcountHealth } from '../../accounts/list/accounts-table-inner.jsx'

const numberCell = prop => (
  <span className="util-textRight util-fullWidth">{prop.value}</span>
)

const columns = [
  {
    Header: 'Account',
    accessor: 'account__name',
    Cell: row => (
      <div>
        <Link to={`/crm/account/${row.original.account_uid}/sales`}>
          {row.original.account__name}
        </Link>
      </div>
    )
  },
  {
    Header: 'Current Owner',
    accessor: 'account__owner__full_name',
    width: 140
  },
  {
    Header: 'Contract Winner',
    accessor: 'owner__full_name',
    width: 140
  },
  {
    Header: 'Date Signed',
    accessor: 'contract_signed_date',
    Cell: row => (
      <div>
        {row.original.contract_signed_date ? (
          <FormattedDate value={row.original.contract_signed_date} />
        ) : (
          '-'
        )}
      </div>
    ),
    maxWidth: 180
  },
  {
    Header: 'Vehicles (Opp)',
    accessor: 'vehicle_count',
    maxWidth: 120,
    Cell: numberCell
  },
  {
    Header: 'Vehicles (Quot)',
    Cell: () => 'n/a',
    maxWidth: 120
  },
  {
    Header: 'Existing',
    accessor: 'is_existing_customer',
    Cell: row =>
      row.original.is_existing_customer ? 'Existing' : 'New Customer',
    maxWidth: 120
  },
  {
    Header: 'SA Pending',
    accessor: 'cams_pending',
    maxWidth: 120
  },
  {
    Header: 'SA Completed',
    accessor: 'cams_completed',
    maxWidth: 120
  },
  {
    Header: 'SA Canceled',
    accessor: 'cams_canceled',
    maxWidth: 120
  },
  {
    Header: 'Remaining',
    accessor: 'remaining',
    maxWidth: 120
  }
]

export default columns
