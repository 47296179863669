import React from 'react'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl'
import { func, bool, shape, string, number } from 'prop-types'
import {
  CrmLabeledField,
  CrmActivityDateTimeSelect,
  CrmButton
} from 'crm-components'
import styles from './section-datetime.scss'
import { getAPIDateFormat, getHourFormat } from 'crm-utils/time-utils'

class SectionDateTime extends React.PureComponent {
  constructor(props) {
    super(props)
    const time = props.time ? props.time : undefined
    const { hourValue, dateString, selectedDate } = this.getTimeValues(time)
    this.state = {
      time,
      dateString,
      hourValue,
      selectedDate
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.time !== this.props.time) {
      const time = nextProps.time ? nextProps.time : undefined
      const { hourValue, dateString, selectedDate } = this.getTimeValues(time)
      this.setState({
        time,
        dateString,
        hourValue,
        selectedDate
      })
    }
  }

  getTimeValues = time => {
    return {
      hourValue: time ? getHourFormat(time) : '',
      dateString: time ? getAPIDateFormat(time) : '',
      selectedDate: time ? getAPIDateFormat(time) : getAPIDateFormat(new Date())
    }
  }

  handleTimeChange = time => {
    this.setState({
      dateString: getAPIDateFormat(time),
      hourValue: getHourFormat(time)
    })
    if (!this.props.activity.activity_uid) {
      if (!this.props.time) {
        this.props.handleEditEnd()
      }
      this.props.handleTimeChange(time)
    }
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(time)
    }
  }

  handleDayChange = value => {
    this.setState({ selectedDate: getAPIDateFormat(value) })
  }

  handleClose = e => {
    e.stopPropagation()
    this.props.handleEditEnd()
  }

  render() {
    console.log('render section-datetime', this.state, this.props)
    const { selectedDate } = this.state
    const { hostUid } = this.props
    return (
      <div className={styles.SectionDateTime}>
        <div>
          <CrmLabeledField lg noMargin>
            <div
              onClick={
                !this.props.activity.activity_uid
                  ? this.props.handleEditStart
                  : () => {}
              }
              className={
                !this.props.activity.activity_uid
                  ? 'util-hooverableContent util-hooverOp util-flexRow'
                  : ''
              }
            >
              {this.state.dateString && this.state.hourValue ? (
                <div className="util-textUpdated">
                  <FormattedDate value={this.state.time} />
                  <span> {this.state.hourValue}</span>
                </div>
              ) : (
                <div style={{ padding: 3 }}>
                  <FormattedMessage
                    id="crm.activity.no_date_and_time_Click_here_to_select"
                    defaultMessage="No date and time - Click here to select"
                  />
                </div>
              )}
            </div>
          </CrmLabeledField>
        </div>

        {this.props.isExpanded && (
          <React.Fragment>
            <CrmActivityDateTimeSelect
              time={this.props.activity.start_time}
              handleTimeChange={this.handleTimeChange}
              handleDayChange={this.handleDayChange}
              queryUserUid={hostUid}
              queryStartDate={selectedDate}
              queryEndDate={selectedDate}
            />
            {!this.props.activity.activity_uid && (
              <div className="util-flexRowRight">
                <CrmButton
                  grouped
                  label={
                    <FormattedMessage
                      id="crm.ui.button.close"
                      defaultMessage="Close"
                    />
                  }
                  id="section_datetime_close"
                  small
                  onClick={this.handleClose}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

SectionDateTime.propTypes = {
  time: number,
  activity: shape({}).isRequired,
  handleEditStart: func.isRequired,
  handleEditEnd: func.isRequired,
  handleTimeChange: func.isRequired,
  hostUid: string.isRequired,
  isExpanded: bool.isRequired,
  input: shape({})
}

SectionDateTime.defaultProps = {
  time: undefined,
  input: undefined
}

export default SectionDateTime
