import React, { createContext, useContext, useState } from 'react'
import { node, shape, bool } from 'prop-types'

export const EditableContext: any = createContext({ isEditable: false })

export const EditableContextProvider = ({ children }) => {
  const [isEditable, setIsEditable] = useState(false)

  const updateIsEditable = isEditable => {
    setIsEditable(isEditable)
  }

  const editableContext = {
    isEditable,
    setIsEditable: updateIsEditable
  }

  return (
    <EditableContext.Provider value={editableContext}>
      {children}
    </EditableContext.Provider>
  )
}

EditableContextProvider.propTypes = {
  children: node.isRequired,
  isEditable: bool
}

export const useEditableContext = () => useContext(EditableContext)

export const withEditableContextHOC = (Component: any) => {
  return (props: any) => {
    return <Component editableContext={useEditableContext()} {...props} />
  }
}
