import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Field as FieldFormik, FieldArray } from 'formik'
import { debounce } from 'lodash'
import accountCheckDuplicateHoc from '../account-name-check-duplicate-hoc'
import DatePickerJSX from '../../../../../../util-components/date-picker.jsx'
import AccountListItem from '../../../../../modules/accounts/components/account-list-item.jsx'
import {
  CrmTextInput,
  CrmDictionaryValueSelect,
  CrmCampaignSelect,
  CrmCheckbox,
  CrmList,
  CrmTextArea
} from '../../../../../components/index'
import Spacer from '../../../../../../util-components/spacer.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage'
import ContactDetailItem from './company-contact-detail-item'

const DatePicker: any = DatePickerJSX

const IndustrySelect = (props) => (
  <CrmDictionaryValueSelect
    {...props}
    dictionaryCode="industries"
    placeholder={
      <FormattedMessage
        id="crm.ui.account.form.industry"
        defaultMessage="Industry"
      />
    }
  />
)

interface IRenderMembersProps {
  values: any
  onHandleCheckByUid: Function
  duplicate: any
}

const RenderMembers: React.SFC<IRenderMembersProps> = ({
  values,
  onHandleCheckByUid,
  duplicate
}) => {
  const defaultProps = {
    onChange: undefined,
    duplicateData: undefined
  }
  const contactDetails = values.contact_details
  return (
    <div className="row">
      {contactDetails &&
        contactDetails.map &&
        contactDetails.map((item, index) => {
          const f = contactDetails[index]
          const kind = f.kind
            ? f.kind.charAt(0).toUpperCase() + f.kind.slice(1)
            : 'Value'

          return (
            <div key={index} className="col-md-6">
              <ContactDetailItem
                item={item}
                onHandleCheckByUid={onHandleCheckByUid}
                index={index}
                kind={kind}
                duplicate={duplicate}
              />
            </div>
          )
        })}
    </div>
  )
}

interface IProps {
  account: any
  sectionName: String
  onHandleCheck: Function
  onHandleCheckByUid: Function
  duplicate: any
  accountCategory: String
  values: any
  errors: any
  intl: any
  onDuplicateResultChanged: Function
}

interface IState {
  showPopover: any
  showAccountDuplicate: any
  showAccountLocalName: any
  showRegistrationDuplicate: any
}

class SectionOverview extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      showPopover: false,
      showAccountDuplicate: false,
      showAccountLocalName: false,
      showRegistrationDuplicate: false
    }
  }

  static defaultProps = {
    sectionName: (
      <FormattedMessage
        id="crm.ui.account.info.basic_information"
        defaultMessage="Basic Information"
      />
    ),
    onHandleCheck: undefined,
    onHandleCheckByUid: undefined,
    duplicate: {}
  }

  checkDuplicateName = (value) => {
    if (this.props.onHandleCheck) {
      this.props.onHandleCheck('name', value)
    }
  }

  checkDuplicateRegistration = (value) => {
    if (this.props.onHandleCheck) {
      this.props.onHandleCheck('registration_number', value)
    }
  }

  findDuplicateValue = debounce(this.checkDuplicateName, 1000)
  findDuplicateRegisteration = debounce(this.checkDuplicateRegistration, 1000)

  componentWillReceiveProps = (nextProps) => {
    if (this.props.duplicate !== nextProps.duplicate) {
      this.props.onDuplicateResultChanged('basicInfo', nextProps.duplicate)
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
    if (this.props.values) {
      const name = this.props.values.name
      const contactDetails = this.props.values.contact_details
      if (name) {
        this.checkDuplicateName(name)
      }
      const registerationNumber = this.props.values.registration_number
      if (registerationNumber) {
        this.checkDuplicateRegistration(registerationNumber)
      }
    }
  }
  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  refAccountDuplicate: any = React.createRef()
  handleClickOutside = (e) => {
    if (
      this.refAccountDuplicate &&
      this.refAccountDuplicate.current &&
      !this.refAccountDuplicate.current.contains(e.target)
    ) {
      // this.setState({ showAccountDuplicate: false })
    }
  }

  handleShowPopover = () => {
    this.setState({ showPopover: true })
  }

  handleHidePopover = () => {
    this.setState({ showPopover: false })
  }

  handleAccountNameChange = (e, form) => {
    form.setFieldValue('name', e.currentTarget.value)
    this.findDuplicateValue(e.currentTarget.value)
  }

  handleRegistrationChange = (e, form) => {
    form.setFieldValue('registration_number', e.currentTarget.value)
    this.findDuplicateRegisteration(e.currentTarget.value)
  }

  handleShowDuplicateAccount = () => {
    const showDuplicate = this.state.showAccountDuplicate
    this.setState({ showAccountDuplicate: !showDuplicate })
  }

  handleShowDuplicateRegistration = () => {
    const showDuplicate = this.state.showRegistrationDuplicate
    this.setState({ showRegistrationDuplicate: !showDuplicate })
  }

  handleShowDuplicate = (field) => {
    if (field === 'name') {
      this.handleShowDuplicateAccount()
    }
    if (field === 'registration_number') {
      this.handleShowDuplicateRegistration()
    }
  }

  hasDuplicateData = (duplicate, field) => {
    const duplicateData = duplicate[field]
    if (
      duplicateData &&
      duplicateData.duplicate &&
      Array.isArray(duplicateData.duplicateData) &&
      duplicateData.duplicateData.length > 0
    ) {
      return true
    } else {
      return false
    }
  }

  handleRenderDuplicateAccount = (field) => {
    const duplicateData = this.props.duplicate[field]
    if (this.hasDuplicateData(this.props.duplicate, field)) {
      const size = field === 'name' ? 'normal' : 'small'
      return (
        <div className="row">
          <div className="col-md-10">
            <CrmList>
              {duplicateData.duplicateData.map((d) => (
                <AccountListItem
                  key={d.account_uid}
                  account={d}
                  target="_blank"
                  size={size}
                />
              ))}
            </CrmList>
          </div>
        </div>
      )
    }
  }

  getTextShowMoreForField = (field, show) => {
    if (field === 'name') {
      return show ? '<< show less' : 'show more >>'
    }

    return show ? 'Hide' : 'Show'
  }

  handleRenderDuplicateStatus = (field) => {
    if (!this.props.duplicate || !this.props.duplicate.hasOwnProperty(field)) {
      return
    }

    const duplicateData = this.props.duplicate[field]
    if (duplicateData.loading) {
      return <span>Checking duplicates ...</span>
    }

    if (duplicateData.duplicate === false && duplicateData.search) {
      return <span className="util-textGreen">No duplicates found</span>
    }

    if (!duplicateData.duplicate) {
      return ''
    }

    const showMoreDuplicate =
      field === 'registration_number'
        ? {
          registration_number: this.state.showRegistrationDuplicate
        }
        : {
          name: this.state.showAccountDuplicate
        }

    const showText = this.getTextShowMoreForField(
      field,
      showMoreDuplicate[field]
    )

    return (
      <span>
        <strong className="util-textRed">
          {duplicateData.duplicateData.length} duplicates
        </strong>
        &nbsp; | &nbsp;{' '}
        <strong
          onClick={() => this.handleShowDuplicate(field)}
          style={{ cursor: 'pointer' }}
        >
          {showText}
        </strong>
      </span>
    )
  }

  handleAddLocalName = () => {
    this.setState({ showAccountLocalName: !this.state.showAccountLocalName })
  }

  _renderIndustry = (values) => {
    return (
      <FieldFormik
        render={({ field, form }) => (
          <IndustrySelect
            input={{
              value: values.industry_uid,
              onChange: (value) => {
                form.setFieldValue('industry_uid', value)
              }
            }}
          />
        )}
      />
    )
  }

  _renderRegistration = (values) => {
    return (
      <React.Fragment>
        <FieldFormik
          render={({ field, form }) => (
            <CrmTextInput
              value={values.registration_number}
              placeholder={this.props.intl.formatMessage({
                id: 'crm.ui.account.form.company_registration_number',
                defaultMessage: 'Company Registration Number'
              })}
              onChange={(e) => this.handleRegistrationChange(e, form)}
            />
          )}
        />
        {/* Show Duplicate Registration */}
        <div className="AddAccountForm-DuplicateAccountWrapper">
          <div className="duplicate-checking-status">
            {this.handleRenderDuplicateStatus('registration_number')}
          </div>
          <ReactCSSTransitionGroup
            transitionName="duplicate-account"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {this.state.showRegistrationDuplicate && (
              <div
                ref={this.refAccountDuplicate}
                className="AddAccountForm-DuplicateAccountList"
              >
                {this.handleRenderDuplicateAccount('registration_number')}
              </div>
            )}
          </ReactCSSTransitionGroup>
        </div>
        <Spacer height="10px" />
      </React.Fragment>
    )
  }

  _renderCAMS = (values) => {
    return (
      <FieldFormik
        render={({ field, form }) => (
          <CrmTextInput
            value={values.cams_username}
            placeholder={this.props.intl.formatMessage({
              id: 'crm.ui.account.form.cams_username',
              defaultMessage: 'CAMS Username'
            })}
            onChange={(e) => {
              form.setFieldValue('cams_username', e.currentTarget.value)
            }}
          />
        )}
      />
    )
  }

  render (): JSX.Element {
    const { sectionName, accountCategory, errors, values } = this.props
    const { showAccountLocalName } = this.state
    const fromDate: any = new Date()
    const isB2C = accountCategory === 'B2C'
    const fromMonth: any = new Date(fromDate.setYear(2000))

    return (
      <div>
        <div className="AddAccountForm-Header">{sectionName}</div>
        <fieldset className="Form-fieldset CrmForm-form-fieldset">
          {/* Account Name */}
          <div className="row">
            <div className="col-md-11">
              <FieldFormik
                render={({ field, form }) => (
                  <CrmTextInput
                    value={values.name}
                    placeholder={this.props.intl.formatMessage({
                      id: 'crm.ui.account.form.account_name',
                      defaultMessage: 'Account Name'
                    })}
                    onChange={(e) => this.handleAccountNameChange(e, form)}
                  />
                )}
              />
              {errors.name && (
                <div className="CrmForm-field-error">{errors.name}</div>
              )}

              {/* Show Duplicate Account */}
              <div className="AddAccountForm-DuplicateAccountWrapper">
                <div className="duplicate-checking-status">
                  {this.handleRenderDuplicateStatus('name')}
                </div>
                <ReactCSSTransitionGroup
                  transitionName="duplicate-account"
                  transitionEnterTimeout={300}
                  transitionLeaveTimeout={300}
                >
                  {this.state.showAccountDuplicate && (
                    <div
                      ref={this.refAccountDuplicate}
                      className="AddAccountForm-DuplicateAccountList"
                    >
                      {this.handleRenderDuplicateAccount('name')}
                    </div>
                  )}
                </ReactCSSTransitionGroup>
              </div>
            </div>

            <div className="col-md-1">
              <span
                className="AddAccountForm-LocalNameIcon util-pointer util-textApply"
                onClick={this.handleAddLocalName}
              >
                <FontAwesomeIcon icon={faLanguage} />
              </span>
            </div>
          </div>

          <Spacer height="10px" />

          {showAccountLocalName && (
            <div className="row">
              <div className="col-md-12">
                <FieldFormik
                  render={({ field, form }) => (
                    <CrmTextInput
                      value={values.name_local}
                      placeholder={this.props.intl.formatMessage({
                        id: 'crm.ui.account.form.original_name',
                        defaultMessage: 'Account Name in local language'
                      })}
                      onChange={(e) => {
                        form.setFieldValue('name_local', e.currentTarget.value)
                      }}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {/* Industry */}
          {!isB2C && (
            <div className="row">
              <div className="col-md-7">{this._renderIndustry(values)}</div>
            </div>
          )}

          {/* Lead */}
          <div className="row">
            <div className="col-md-7">
              <FieldFormik
                render={({ field, form }) => (
                  <CrmCampaignSelect
                    placeholder={
                      <FormattedMessage
                        id="crm.ui.account.form.lead_campaign"
                        defaultMessage="Lead Campaign"
                      />
                    }
                    input={{
                      value: values.lead_campaign_uid,
                      onChange: (value) => {
                        form.setFieldValue('lead_campaign_uid', value)
                      }
                    }}
                  />
                )}
              />
              {errors.lead_campaign_uid && (
                <div className="CrmForm-field-error">
                  {errors.lead_campaign_uid}
                </div>
              )}
            </div>
            <div className="col-md-5">
              <FieldFormik
                render={({ field, form }) => (
                  <DatePicker
                    placeholder={this.props.intl.formatMessage({
                      id: 'crm.ui.account.form.lead_in_date',
                      defaultMessage: 'Lead In Date'
                    })}
                    initialMonth={new Date()}
                    fromMonth={fromMonth}
                    input={{
                      value: values.lead_in_date,
                      onChange: (value) => {
                        form.setFieldValue('lead_in_date', value)
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          {isB2C && (
            <div className="row">
              {/* CAMS */}
              <div className="col-md-7">{this._renderCAMS(values)}</div>
            </div>
          )}

          {!isB2C && (
            <div className="row">
              {/* Registration */}
              <div className="col-md-7">{this._renderRegistration(values)}</div>
              {/* CAMS */}
              <div className="col-md-5">{this._renderCAMS(values)}</div>
            </div>
          )}

          {/* Remarks */}
          <div className="row">
            <div className="col-md-12">
              <FieldFormik
                render={({ field, form }) => (
                  <CrmTextArea
                    rows={2}
                    placeholder={this.props.intl.formatMessage({
                      id: 'crm.ui.account.form.remark',
                      defaultMessage: 'Remark'
                    })}
                    input={{
                      value: values.remark,
                      onChange: (e) => {
                        form.setFieldValue('remark', e.currentTarget.value)
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          <Spacer height="10px" />

          {/* Contact Details */}
          {!isB2C && (
            <div className="row">
              <div className="col-md-12">
                <FieldFormik
                  render={({ field, form }) => (
                    <CrmTextInput
                      value={values.website}
                      placeholder={this.props.intl.formatMessage({
                        id: 'crm.ui.account.form.website',
                        defaultMessage: 'Website'
                      })}
                      onChange={(e) => {
                        form.setFieldValue('website', e.currentTarget.value)
                      }}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className={isB2C ? 'col-md-12' : 'col-md-12'}>
              <FieldArray
                name="contact_details"
                render={(arrayHelpers) => {
                  return (
                    <RenderMembers
                      values={values}
                      duplicate={this.props.duplicate}
                      onHandleCheckByUid={this.props.onHandleCheckByUid}
                    />
                  )
                }}
              />
            </div>
          </div>

          {/* Assign to me */}
          <div className="row">
            <div className="col-md-12">
              <FieldFormik
                render={({ field, form }) => (
                  <CrmCheckbox
                    label={
                      <FormattedMessage
                        id="crm.ui.account.form.assign_this_account_to_me"
                        defaultMessage="Assign this account to me"
                      />
                    }
                    value={values.is_assign_to_me}
                    onChange={(value) => {
                      form.setFieldValue(
                        'is_assign_to_me',
                        !values.is_assign_to_me
                      )
                    }}
                  />
                )}
              />
            </div>
          </div>
        </fieldset>
      </div>
    )
  }
}

export default accountCheckDuplicateHoc(injectIntl(SectionOverview))
