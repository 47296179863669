import React from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { func, string, bool, shape } from 'prop-types'
import { isValid } from 'redux-form'
import ConvertToProspectForm from './_convert-to-prospect-fields.jsx'

class StartNewProcessWidget extends React.PureComponent {
  render() {
    return (
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <ConvertToProspectForm
          {...this.props}
          bulkMode
          onSetClickHandler={this.handleSetClickHandler}
          onBulkComponentInit={this.props.onBulkComponentInit}
        />
      </div>
    )
  }
}

StartNewProcessWidget.propTypes = {
  onSaveOwnerChange: func.isRequired,
  onCancel: func.isRequired,
  onUsersGroupSelect: func.isRequired,
  onOwnerTypeChanged: func.isRequired,
  userUid: string,
  isSaving: bool.isRequired,
  onUserSelect: func.isRequired,
  newOwnerType: string.isRequired,
  usersGroup: string,
  bulkMode: bool,
  onBulkComponentInit: func,
  process: shape().isRequired,
  doPush: func.isRequired
}

StartNewProcessWidget.defaultProps = {
  userUid: undefined,
  usersGroup: undefined,
  bulkMode: false,
  onBulkComponentInit: undefined
}

export default connect(state => ({
  isConvertToProspectValid: isValid('convertToProspect')(state)
}))(StartNewProcessWidget)
