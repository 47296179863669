import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import DayPicker from 'react-day-picker'
import enhanceClickOutside from 'react-click-outside'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { getDateFormat } from 'crm-utils/time-utils'
import { CrmTextInput } from '@cartrack-crm/ui'
import { toastr } from 'react-redux-toastr'

interface IProps {
  id: string
  input: any
  name: string
  onChange: Function
  placeholder?: {
    from: string
    to: string
  }
  value?: {
    from: string
    to: string
  }
  showDateTextInput: boolean
  onDateChangeManually: Function
  disabled: boolean
  required: boolean
  meta: any
  extraClassNames: any
  style: any
  openUpwards: boolean
  openLeft: boolean
  maxRange: number
  onOpen?: Function
  onClose?: Function
  fromMonth?: Date
  toMonth?: Date
  initialMonth?: Date
  onBlur?: Function
}

interface IState {
  isFocused: boolean
  selected?: any
  inputState?: any
  showPicker: boolean
  startMonth: any
  endMonth: any
  dateValueManually?: any
  hovered?: any
  selecting?: boolean
}

class DateRangePicker extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)

    const value = props.input ? props.input.value : props.value
    const { startMonth, endMonth } = this.calculateInitialMonth(props.value)

    this.state = {
      isFocused: false,
      selected: value ? this.resolveUtilDates(value) : null,
      inputState: value ? this.resolveInputState(value) : null,
      showPicker: false,
      startMonth,
      endMonth,
      dateValueManually: {}
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.value !== nextProps.value) {
      const { startMonth, endMonth } = this.calculateInitialMonth(nextProps.value)

      this.setState({
        startMonth,
        endMonth,
        dateValueManually: {},
        hovered: {}
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { state, props } = this

    if (!state.showPicker && !isEqual(props.value, prevProps.value)) {
      let selected = props.input ? props.input.value : props.value

      if (state.selected !== selected) {
        if (selected) selected = this.resolveUtilDates(selected)
      }

      this.setStateValue(selected)
    }
  }

  setStateValue = selected =>
    this.setState({
      selected,
      inputState: this.resolveInputState(selected)
    })

  handleClickOutside = () => {
    this.setState({ hovered: {} })

    if (!this.state.showPicker) return null
    if (this.state.selecting) {
      const onChange = this.props.input ? this.props.input.onChange : this.props.onChange
      onChange(this.state.selected, this.props.id)
    }

    this.setState({ showPicker: false, selecting: false })

    if (this.props.input && this.props.input.onBlur) this.props.input.onBlur()
    if (this.props.onClose) this.props.onClose()
  }

  handleStartDayClick = clickedDate => {
    this.handleDayClick(clickedDate)
  }

  handleEndDayClick = clickedDate => {
    this.handleDayClick(clickedDate)
  }

  handleDayClick = clickedDate => {
    const onChange = this.props.input ? this.props.input.onChange : this.props.onChange
    let selected
    let hovered
    let dispatch = true
    let { selecting } = this.state
    let showPicker

    if (selecting) {
      if (moment(clickedDate).isBefore(this.state.selected.from, 'day')) {
        selected = {
          from: clickedDate,
          to: this.state.selected.from
        }
      } else {
        selected = {
          from: this.state.selected.from,
          to: clickedDate
        }
      }

      selecting = false
    } else {
      selected = {
        from: clickedDate,
        to: clickedDate
      }
      hovered = {
        from: clickedDate,
        to: clickedDate
      }
      selecting = true
      showPicker = true
      dispatch = false
    }

    if (selected) {
      if (selected.from && selected.to) {
        selected = {
          from: new Date(selected.from.setHours(0, 0, 0, 0)),
          to: new Date(selected.to.setHours(23, 59, 59, 999))
        }
      }

      if (dispatch) onChange(selected, this.props.id)
    }

    const inputState = this.resolveInputState(selected)

    this.setState({
      selected,
      hovered,
      selecting,
      showPicker,
      inputState
    })

    if (!showPicker && this.props.onClose) this.props.onClose()
  }

  handleDayMouseEnter = date => {
    if (!this.state.selecting) return null

    this.setState({
      hovered: {
        from: this.state.hovered.from,
        to: date
      }
    })
  }

  resolveInputState = value => {
    if (!value || !value.from || !value.to) return null

    return {
      from: getDateFormat(value.from),
      to: getDateFormat(value.to)
    }
  }

  resolveUtilDates = value => {
    if (!value || !value.from || !value.to) return null

    const from = this.doGetServerTime(value.from)
    const to = this.doGetServerTime(value.to)

    return {
      from: new Date(from),
      to: new Date(to)
    }
  }

  handleOnIconClick = () => {
    const { disabled } = this.props

    if (!disabled) {
      this.setState(prevState => ({ showPicker: !prevState.showPicker }))
    }
  }

  doGetServerTime = dateTime => {
    return moment(dateTime).format('YYYY-MM-DD')
  }

  calculateInitialMonth = value => {
    if (!value || !value.from || !value.to) {
      const startMonth = new Date()
      const endMonth = new Date(new Date().setMonth(new Date().getMonth() + 1))

      return { startMonth, endMonth }
    } else {
      const startDate = new Date(value.from)
      const endDate = new Date(value.to)
      const startMonth = startDate
      const endMonth =
        startDate.getMonth() === endDate.getMonth() ? new Date(endDate.setMonth(endDate.getMonth() + 1)) : endDate

      return { startMonth, endMonth }
    }
  }

  handleStartMonthChanged = value => {
    this.setState(
      {
        startMonth: value
      },
      () => {
        this.calculateEndMonth()
      }
    )
  }

  handleEndMonthChanged = value => {
    this.setState(
      {
        endMonth: value
      },
      () => {
        this.calculateEndMonth()
      }
    )
  }

  calculateEndMonth = () => {
    const startDate = new Date(this.state.startMonth)
    const endDate = new Date(this.state.endMonth)

    if (startDate.getMonth() >= endDate.getMonth() && startDate.getFullYear() >= endDate.getFullYear()) {
      this.setState({
        endMonth: new Date(startDate.setMonth(startDate.getMonth() + 1))
      })
    }
  }

  handleStartDateChangeManually = event => {
    const dateValueManually = { ...this.state.dateValueManually }
    dateValueManually.from = event.currentTarget.value

    this.setState({ dateValueManually })
  }

  handleEndDateChangeManually = event => {
    const dateValueManually = { ...this.state.dateValueManually }
    dateValueManually.to = event.currentTarget.value

    this.setState({ dateValueManually })
  }

  handleStartDateBlur = event => {
    const startDate: any = this.validateDateString(event.currentTarget.value)

    if (startDate !== false) {
      const endDate = moment(this.props.value.to)
      const dateRangeValue = {
        from: new Date(startDate),
        to: startDate.isAfter(endDate, 'day') ? new Date(startDate) : this.props.value.to
      }

      if (this.props.onDateChangeManually) {
        this.props.onDateChangeManually(dateRangeValue)
      }
    }
  }

  handleEndDateBlur = event => {
    const endDate: any = this.validateDateString(event.currentTarget.value)

    if (endDate !== false) {
      const startDate = moment(this.props.value.from)
      const dateRangeValue = {
        from: startDate.isAfter(endDate, 'day') ? new Date(endDate) : this.props.value.from,
        to: new Date(endDate)
      }

      if (this.props.onDateChangeManually) {
        this.props.onDateChangeManually(dateRangeValue)
      }
    }
  }

  validateDateString = value => {
    let date = moment(value, 'DD/MM/YYYY')

    if (!date.isValid()) {
      toastr.warning('Invalid date format')
      return false
    } else {
      return date
    }
  }

  handleCaptionClick = value => {
    const endOfMonth: any = moment(value).endOf('month')

    this.setState(
      {
        selected: {
          from: value,
          to: new Date(endOfMonth)
        }
      },
      () => {
        this.props.onChange(this.state.selected, this.props.id)
      }
    )
  }

  render() {
    const { openUpwards, openLeft, name, placeholder, style } = this.props
    const { selected, hovered, showPicker, inputState, startMonth, endMonth, dateValueManually } = this.state

    const modifiers = hovered
      ? {
          hoverRange: hovered
        }
      : undefined

    let formatted = { from: '', to: '' }

    if (selected && selected.from) {
      if (selected.to) {
        formatted = inputState
      } else {
        formatted = {
          from: inputState.from,
          to: inputState.to
        }
      }
    }

    return (
      <div className={`DateRangePicker DateRangePicker--double`}>
        <div style={{ position: 'relative' }}>
          <div
            className={`DateRangePicker-inner ${showPicker ? 'is-open' : ''} ${
              openLeft ? 'DateRangePicker-inner--openLeft' : ''
            } ${openUpwards ? 'DateRangePicker-inner--openUpwards' : ''}`}
            style={style}
          >
            {this.props.showDateTextInput && (
              <div className="row between-xs">
                <CrmTextInput
                  id={'date-text-from'}
                  iconName="calendar"
                  input={{
                    onChange: this.handleStartDateChangeManually,
                    value: dateValueManually && dateValueManually.from ? dateValueManually.from : formatted?.from
                  }}
                  placeholder={(placeholder && placeholder.from) || 'From'}
                  extraClassNames={{
                    containerClassNames: 'col-xs-6'
                  }}
                  onBlur={this.handleStartDateBlur}
                />
                <CrmTextInput
                  id={'date-text-to'}
                  iconName="calendar"
                  input={{
                    onChange: this.handleEndDateChangeManually,
                    value: dateValueManually && dateValueManually.to ? dateValueManually.to : formatted?.to
                  }}
                  placeholder={(placeholder && placeholder.to) || 'To'}
                  extraClassNames={{
                    containerClassNames: 'col-xs-6'
                  }}
                  onBlur={this.handleEndDateBlur}
                />
              </div>
            )}
            <div className="util-flexRow DateRangePicker-holder">
              <div className="StartMonth">
                <DayPicker
                  modifiers={modifiers}
                  numberOfMonths={1}
                  onDayClick={this.handleStartDayClick}
                  onDayMouseEnter={this.handleDayMouseEnter}
                  selectedDays={selected}
                  month={startMonth}
                  onMonthChange={this.handleStartMonthChanged}
                  onCaptionClick={this.handleCaptionClick}
                />
              </div>
              <div className="EndMonth">
                <DayPicker
                  modifiers={modifiers}
                  numberOfMonths={1}
                  onDayClick={this.handleEndDayClick}
                  onDayMouseEnter={this.handleDayMouseEnter}
                  selectedDays={selected}
                  month={endMonth}
                  onMonthChange={this.handleEndMonthChanged}
                  onCaptionClick={this.handleCaptionClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(injectIntl(enhanceClickOutside(DateRangePicker)))
