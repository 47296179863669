import React, { useState, useRef } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { forecastType, OpportunityType } from './useFetchForecastManager'
import { TextFormWithVale } from './manager/forecast-manager-view'
import useOpportunityCommand from '../../modules/accounts/account/opportunities/hooks/with-opportunity-command'
import moment, { Moment } from 'moment'
import OpportunityWinLoseWidget from '../accounts/account/opportunities/layout19/opportunity-win-loose-widget.jsx'
import { getWonSize } from './useFetchForecastManager'
import {
  CrmButton,
  CrmDropdown,
  CrmDayPickerInput,
  CrmTextInputNumber
} from 'crm-components'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { CrmLoadingIcon } from 'crm-components'
import { useCrmToastr, CrmToastrType } from '@cartrack-crm/ui/src/toastrs'

const QL_LIST_OPPORTUNITIES = gql`
  query opportunities($filter: JSON) {
    opportunities(filter: $filter, limit: 500) {
      edges {
        edge {
          account_uid
          opportunity_uid
          confidence_level
          vehicle_count
          due_date
          status_code
          completed_date
          account {
            account_uid
            name
            lead_campaign {
              name
            }
            lead_in_time
          }
          documents {
            process {
              current_step {
                process_step_type {
                  name
                }
              }
            }
            items {
              product {
                is_addon
              }
              quantity
            }
          }
        }
      }
    }
  }
`
interface ForecastOpportunitiesListViewProps {
  month: string
  onLoadedOpportunities?: (
    summaryVehicle: any,
    opportunities: {},
    warningMessage: any
  ) => any
  forecastData: forecastType
  userUid: string
  refetchForecast?: () => any
}
export const isOpportunityDueDateBeforeCurrentMonth = (
  dueDate: Moment,
  currentMonth: string
) => {
  const minFloorThisMonth = moment(currentMonth, 'YYYY-MM').startOf('month')
  return dueDate.isBefore(minFloorThisMonth)
}

export const isOpportunityDueDateAfterCurrentMonth = (
  dueDate: Moment,
  currentMonth: string
) => {
  const maxFloorThisMonth = moment(currentMonth, 'YYYY-MM').endOf('month')
  return dueDate.isAfter(maxFloorThisMonth)
}

const confidenceLevelOptions = [
  { name: '20%', value: 20 },
  { name: '40%', value: 40 },
  { name: '60%', value: 60 },
  { name: '80%', value: 80 }
]

export const ForecastOpportunitiesListView: React.FunctionComponent<ForecastOpportunitiesListViewProps> = props => {
  let opportunities = []
  let hasWarning = false
  let hasOpportunities = false
  const { isSaving, runOpportunityCommand } = useOpportunityCommand()
  const forecastData = props.forecastData
  const forecastConfirmed =
    forecastData &&
    forecastData.status &&
    forecastData.status === 'forecast.confirmed'
  const dueDateMoment = moment(props.month, 'YYYY-MM')
  const dueDate = dueDateMoment.endOf('month').format('YYYY-MM-DD')
  let variables = {
    filter: {
      due_date: {
        $lte: dueDate
      },
      is_deleted: false,
      owner_user_uid: props.userUid,
      completed_time: null,
      status_code: 'active'
    }
  }
  if (forecastConfirmed) {
    variables['filter']['due_date']['$gte'] = dueDateMoment
      .startOf('month')
      .format('YYYY-MM-DD')
    delete variables['filter']['is_deleted']
    delete variables['filter']['completed_time']
    delete variables['filter']['status_code']
  }

  const { loading, data, error, refetch } = useQuery(QL_LIST_OPPORTUNITIES, {
    variables: variables,
    fetchPolicy: 'no-cache'
  })

  let summary = {
    active: 0,
    pending: 0,
    won: 0,
    lost: 0,
    summary: 0,
    push: 0
  }

  const mergeOpportunitiesAndForecastItems = (opportunities, forecastItems) => {
    const listOpportunitiesUid = [...opportunities].map(
      el => el.opportunity_uid
    )
    const uniqueForecasts = [...forecastItems].filter(
      el => !listOpportunitiesUid.includes(el.opportunity_uid)
    )
    return opportunities.concat(uniqueForecasts)
  }

  if (data && data.opportunities && data.opportunities.edges) {
    opportunities = data.opportunities.edges.map(e => e.edge)
    const forecastItems = forecastData.items.map(e => {
      return { ...e.opportunity, additional_sales: e.additional_sales }
    })
    opportunities = mergeOpportunitiesAndForecastItems(
      opportunities,
      forecastItems
    )
    const listOpportunitiesForCommand = opportunities.map(el => {
      const dueDate = moment(el.due_date, 'YYYY-MM-DD')
      if (
        el.vehicle_count === null ||
        el.due_date === null ||
        el.confidence_level === null ||
        isOpportunityDueDateBeforeCurrentMonth(dueDate, props.month)
      ) {
        hasWarning = true
      }
      const isPushOpportunity = isOpportunityDueDateAfterCurrentMonth(
        dueDate,
        props.month
      )
      summary['active'] +=
        el.status_code === 'active' && !isPushOpportunity ? el.vehicle_count : 0
      summary['pending'] +=
        el.status_code === null && !isPushOpportunity ? el.vehicle_count : 0
      summary['won'] +=
        el.status_code === 'won' && !isPushOpportunity ? getWonSize(el) : 0
      summary['lost'] +=
        el.status_code === 'lost' && !isPushOpportunity ? el.vehicle_count : 0
      summary['push'] += isPushOpportunity ? el.vehicle_count : 0
      summary['summary'] += el.vehicle_count

      return {
        opportunity_uid: el.opportunity_uid,
        account_uid: el.account_uid,
        confidence_level: el.confidence_level,
        due_date: el.due_date,
        quantity: el.vehicle_count
      }
    })
    hasOpportunities = opportunities.length > 0 ? true : false
    props.onLoadedOpportunities(
      summary,
      listOpportunitiesForCommand,
      hasWarning
    )
  } else {
    hasOpportunities = false
    props.onLoadedOpportunities(summary, [], null)
  }
  const handleSaveOpportunity = async (opportunity: OpportunityType) => {
    const command = {
      type: 'opportunity.update',
      payload: {
        opportunity: opportunity
      },
      aggregate_type: 'opportunity',
      aggregate_uid: opportunity.opportunity_uid
    }
    await runOpportunityCommand(command)
    await refetchData()
  }

  const refetchData = async () => {
    await props.refetchForecast()
    await refetch()
  }

  return (
    <React.Fragment>
      {loading && (
        <div style={{ width: '100%' }}>
          <CrmLoadingIcon />
        </div>
      )}
      {!loading && !hasOpportunities && (
        <h2>No Opportunities match in this month</h2>
      )}
      {opportunities.map((el, index) => {
        return (
          <OpportunityCard
            key={el.opportunity_uid}
            opportunity={el}
            handleSaveOpportunity={handleSaveOpportunity}
            forecast={forecastData}
            currentMonth={props.month}
            refetchData={refetchData}
          />
        )
      })}
    </React.Fragment>
  )
}

interface OpportunityCardProps {
  opportunity: OpportunityType
  handleSaveOpportunity: (params: OpportunityType) => void
  forecast?: forecastType
  currentMonth?: string
  refetchData?: () => void
}

const OpportunityCard: React.FunctionComponent<OpportunityCardProps> = props => {
  const [isShowEditConfident, setShowEditConfident] = useState(false)
  const [isShowEditDueDate, setShowEditDueDate] = useState(false)
  const [isShowEditQuantity, setShowEditQuantity] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isShowModal, setShowModal] = useState(false)
  const [quantity, setQuantity] = useState(props.opportunity.vehicle_count)
  const [confidence, setConfident] = useState(
    props.opportunity.confidence_level || 0
  )
  const [showToarst, hideToastr] = useCrmToastr()
  const [dueDate, setDueDate] = useState(props.opportunity.due_date || '')
  const opportunitiesDeleted =
    props.opportunity.account === null && props.opportunity.account_uid === null
  const opportunity = props.opportunity
  const isPushStatus = isOpportunityDueDateAfterCurrentMonth(
    moment(opportunity.due_date, 'YYYY-MM-DD'),
    props.currentMonth
  )
  const convertDate = (
    date: string,
    currentFormat: string = 'YYYY-MM-DD',
    format: string = 'YYYY-MM-DD',
    isForecastDate: boolean = false
  ) => {
    const convertDate = moment(date, currentFormat)
    let res: any = <span style={{ color: '#ce5239' }}>Due date is not set</span>
    if (convertDate.isValid()) {
      const dateText = convertDate.format('DD/MM/YYYY')
      if (
        isOpportunityDueDateBeforeCurrentMonth(
          convertDate,
          props.currentMonth
        ) &&
        !isForecastDate
      ) {
        res = <span style={{ color: '#ce5239' }}>{dateText}</span>
      } else {
        res = dateText
      }
    }
    return res
  }
  const setDefaultState = () => {
    setShowEditConfident(false)
    setShowEditDueDate(false)
    setShowEditQuantity(false)
    setIsSaving(false)
  }
  const updateOpportunity = async () => {
    const newOop: OpportunityType = { ...opportunity }
    newOop.due_date = dueDate
    newOop.confidence_level = confidence
    newOop.quantity = quantity
    setIsSaving(true)
    if (!opportunitiesDeleted) {
      await props.handleSaveOpportunity(newOop)
    } else {
      showToarst(CrmToastrType.error, 'This Opportunity was deleted', {
        message: `Can't action on deleted item`
      })
    }

    setDefaultState()
  }
  const getStatusText = () => {
    let status = 'Active'
    if (opportunity.status_code === 'active' && !isPushStatus) {
      status = 'Active'
    } else if (isPushStatus) {
      status = 'Pushed'
    } else if (opportunity.status_code === 'won') {
      status = 'Won'
    } else if (opportunity.status_code === 'lost') {
      status = 'Lost'
    }

    if (opportunitiesDeleted) {
      status = 'Deleted'
    }

    return status
  }
  const handleChange = (fieldName: string, newValue: any) => {
    if (fieldName === 'quantity') {
      setQuantity(newValue)
    }
    if (fieldName === 'confidence_level') {
      setConfident(newValue)
    }
    if (fieldName === 'due_date') {
      setDueDate(newValue)
    }
  }

  const handleClickLost = async () => {
    setShowModal(false)
    props.refetchData()
  }

  const dueDateText = convertDate(
    opportunity.due_date,
    'YYYY-MM-DD',
    'DD/MM/YYYY'
  )
  const forecastOpportunities = props.forecast.items
  const currentForecastOpportunity = forecastOpportunities.find(
    element => element.opportunity_uid === opportunity.opportunity_uid
  )
  const showForecastBlock =
    props.forecast &&
    props.forecast.status === 'forecast.confirmed' &&
    currentForecastOpportunity
  let forecastOpportunityDueDateText = 'Not have forecast for this Due Date'
  if (currentForecastOpportunity && currentForecastOpportunity.due_date) {
    forecastOpportunityDueDateText = convertDate(
      currentForecastOpportunity.due_date,
      'YYYY-MM-DD',
      'DD/MM/YYYY',
      true
    )
  }
  const isAdditionalSales = opportunity.additional_sales
  return (
    <div
      key={opportunity.opportunity_uid}
      style={{ margin: '10px 21%' }}
      className="CrmMdCard util-flexRow util-paddingLg util-marginMd util-textLeft util-hoverAbleBackgroundWhite row"
    >
      <div className="col-md-11 row">
        {!isAdditionalSales && (
          <div className="util-flexColumn col-md-4">
            <a
              href={`/crm/account/${opportunity.account_uid}`}
              target="__blank"
              className="CrmForecast-OpportunityCard-TextHeader util-flexRow util-flexGrow util-marginBottom util-textLarge"
            >
              {opportunity.account ? opportunity.account.name : ''}
            </a>
            {opportunity.account && (
              <div className="util-fontWeightMedium util-textSmall util-textGray60">
                <FormattedDate value={opportunity.account.lead_in_time} />
                {' from'}{' '}
                {opportunity.account.lead_campaign
                  ? opportunity.account.lead_campaign.name
                  : '<lead campaign missing>'}
              </div>
            )}
          </div>
        )}

        <div
          className={`util-flexColumn ${
            isAdditionalSales ? 'col-md-12' : 'col-md-4'
          } util-alignItemCenter ${
            showForecastBlock ? 'util-BackgroundHighlightGray' : ''
          }`}
        >
          {showForecastBlock && (
            <React.Fragment>
              <div className="CrmForecast-OpportunityCard-TextHeader">
                Forecast
              </div>
              <div className="body">
                <TextFormWithVale
                  title="Confidence Level"
                  value={`${currentForecastOpportunity.confidence_level ||
                    0} %`}
                />
                <TextFormWithVale
                  title="Forecast Due date"
                  value={forecastOpportunityDueDateText}
                />
                <TextFormWithVale
                  title="Forecast Size"
                  icon="car-side"
                  value={currentForecastOpportunity.quantity || 0}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        {!isAdditionalSales && (
          <div
            className="util-flexColumn col-md-4 util-alignItemCenter"
            style={showForecastBlock ? { width: '35%' } : {}}
          >
            <div className="CrmForecast-OpportunityCard-TextHeader">
              Current
            </div>
            <div className="body row">
              <TextFormWithVale
                title="Status"
                value={getStatusText()}
                valueClassName={`status ${
                  isPushStatus ? 'push' : opportunity.status_code
                }`}
              />
              {isShowEditConfident ? (
                <div className="CrmForecast-OpportunityCard-inLineEdit">
                  <CrmDropdown
                    placeholder={
                      <FormattedMessage
                        id="crm.ui.account.confidence"
                        defaultMessage="Confidence"
                      />
                    }
                    options={confidenceLevelOptions}
                    input={{
                      value: confidence,
                      onChange: value => {
                        handleChange('confidence_level', value)
                      }
                    }}
                  />
                  <SaveAndCancelIcon
                    onCancel={() => setDefaultState()}
                    onSave={updateOpportunity}
                    isSaving={isSaving}
                  />
                </div>
              ) : (
                <TextFormWithVale
                  onClick={() => setShowEditConfident(true)}
                  title="Confidence Level"
                  value={`${opportunity.confidence_level || 0} %`}
                />
              )}

              {isShowEditDueDate ? (
                <div className="CrmForecast-OpportunityCard-inLineEdit">
                  <CrmDayPickerInput
                    placeholder={
                      <FormattedMessage
                        id="crm.ui.account.due_date"
                        defaultMessage="Due Date"
                      />
                    }
                    input={{
                      value: dueDate,
                      onChange: value => {
                        handleChange('due_date', value)
                      }
                    }}
                  />
                  <SaveAndCancelIcon
                    onCancel={() => setDefaultState()}
                    onSave={updateOpportunity}
                    isSaving={isSaving}
                  />
                </div>
              ) : (
                <TextFormWithVale
                  onClick={() => setShowEditDueDate(true)}
                  title="Due date"
                  value={dueDateText}
                />
              )}

              {isShowEditQuantity ? (
                <div className="CrmForecast-OpportunityCard-inLineEdit">
                  <CrmTextInputNumber
                    placeholder={
                      <FormattedMessage
                        id="crm.ui.account.quantity"
                        defaultMessage="Quantity"
                      />
                    }
                    input={{
                      value: quantity,
                      onChange: e => {
                        handleChange('quantity', e.currentTarget.value)
                      }
                    }}
                  />
                  <SaveAndCancelIcon
                    onCancel={() => setDefaultState()}
                    onSave={updateOpportunity}
                    isSaving={isSaving}
                  />
                </div>
              ) : (
                <TextFormWithVale
                  onClick={() => setShowEditQuantity(true)}
                  title="Quantity"
                  value={opportunity.vehicle_count || 'N/A'}
                  icon="car-side"
                />
              )}
            </div>
          </div>
        )}
      </div>
      {!isAdditionalSales && (
        <div className="col-md-1">
          <div className="util-flex util-relative util-cursorPointer">
            <i
              className="fa fa-ellipsis-v"
              style={{ padding: '24px' }}
              onClick={() => setShowModal(true)}
            />
            {isShowModal && (
              <MoreMenu
                closeModal={() => setShowModal(false)}
                refetch={props.refetchData}
                handleClickLost={handleClickLost}
                opportunity={opportunity}
                forecastUid={props.forecast.forecast_uid}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
interface MoreMenuProps {
  closeModal: () => any
  opportunity: OpportunityType
  handleClickLost: () => void
  forecastUid: string
  refetch: () => any
}

export const MoreMenu: React.FunctionComponent<MoreMenuProps> = props => {
  const [isShowLostForm, setShowLostForm] = useState(false)
  return (
    <div
      className="CrmAddTag-wrapper"
      style={isShowLostForm ? { width: '400px' } : {}}
    >
      <div style={{ width: '100%' }}>
        <div onClick={() => props.closeModal()}>
          <i className="fa fa-close" />
        </div>
        <CrmButton
          label={
            <FormattedMessage
              id="crm.ui.button.opportunity_lost"
              defaultMessage="Lost"
            />
          }
          grouped
          onClick={() => setShowLostForm(true)}
        />
        {isShowLostForm && (
          <div style={{ width: '100%' }}>
            <OpportunityWinLoseWidget
              processTypeUid="e30eb792-2832-4c10-9f74-2c6b1df1318e"
              opportunity={props.opportunity}
              onCancel={() => setShowLostForm(false)}
              onSaved={() => props.handleClickLost()}
            />
          </div>
        )}
      </div>
    </div>
  )
}

interface SaveAndCanCancelIconProps {
  onCancel: () => void
  onSave: () => void
  isSaving: boolean
}
export const SaveAndCancelIcon: React.FunctionComponent<SaveAndCanCancelIconProps> = props => {
  const handleCancel = () => {
    props.onCancel()
  }
  const handleSave = () => {
    props.onSave()
  }
  return props.isSaving ? (
    <CrmLoadingIcon />
  ) : (
    <div style={{ width: '80px', display: 'flex', fontSize: '14px' }}>
      <div
        className="CrmInlineEditable-editButton"
        title="Cancel"
        onClick={handleCancel}
      >
        <FontAwesomeIcon icon="times" />
      </div>
      <div
        className="CrmInlineEditable-editButton"
        title="Save"
        onClick={handleSave}
      >
        <FontAwesomeIcon icon="check" />
      </div>
    </div>
  )
}
