import React, { useState } from 'react'
import { CrmDropdown, CrmButton } from '@cartrack-crm/ui'
import { useComponentConfigurationContext } from '../configuration-tools/component-configuration-context'

export const NewDataSourceWidget = ({ onCancel, onSave }) => {
  const [dataType, setDataType] = useState()
  const configurationContext = useComponentConfigurationContext()

  const types = configurationContext.schema.types
  const dataSourceOptions = types
    ? [
        { name: 'Select Type', value: null },
        ...types.map((type) => ({
          value: type.name,
          name: type.description || type.name
        }))
      ]
    : []

  const handleSave = () => {
    console.log('on Save', dataType)
    if (dataType) {
      onSave({
        data_type: dataType,
        type: 'QL'
      })
    }
  }

  const _hasOtherDataSources =
    configurationContext.component?.content?.data?.dataSources?.length > 0
  return (
    // ToDO Ton - change these components to SmallCard  from this package
    <div className="CrmMdCard util-paddingSm">
      <h6>New Data Source</h6>
      <CrmDropdown
        options={dataSourceOptions}
        input={{
          value: dataType,
          onChange: setDataType
        }}
      />

      {/* TODO TON - if we need to ask more data (how to join with existing- we should do it here ) */}
      {_hasOtherDataSources && (
        <div>alreadd _hasOtherDataSources - ask how to link</div>
      )}
      <div>
        <CrmButton small onClick={onCancel} label="Cancel" />
        <CrmButton small onClick={handleSave} label="Save" />
      </div>
    </div>
  )
}
