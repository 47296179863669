import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import moment from 'moment'
import { shape, func, bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  injectIntl,
  FormattedDate,
  FormattedTime,
  FormattedMessage
} from 'react-intl'
import ActivityStatusWidget, {
  EmailStatusWidget
} from './activity-status-widget.jsx'
import { CrmButton } from 'crm-components'
import { hhmmss } from 'crm-utils/common-utils'
import { getInstanceParameter } from 'crm-duxs/crm-reducer'
import { Icon } from 'util-components'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import AddressItem from 'crm-modules/accounts/components/layout19/address-item.jsx'
import { withCrmContext } from 'crm-core/contexts/crm-context'

class ActivityListItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShowCallLog: false,
      isEdited: false,
      isShowMeetingLocation: false,
      hasPrimary: false
    }
  }

  _getIconClass(activityType) {
    if (!activityType) {
      return 'fa-users'
    }
    switch (activityType.medium_code) {
      case 'meeting':
        return 'fa-users'
      case 'phone_call':
        return 'fa-phone'
      case 'note':
        return 'fa-sticky-note'
      case 'email':
        return 'fa-envelope'
      case 'communicator':
        return 'fa-weixin'
      default:
        return 'fa-question'
    }
  }

  handleEditAction = () => {
    if (this.props.activity && this.props.activity.activity_type) {
      const isLiveCallEditAction =
        this.props.activity.activity_type.medium_code === 'phone_call'
      this.props.onEditActivity(this.props.activity, isLiveCallEditAction)
    }
  }
  _getParticipant(roleCode) {
    return this.props.activity && this.props.activity.participants
      ? this.props.activity.participants.find(p => p.role_code === roleCode)
      : undefined
  }

  renderAudioFileButton() {
    const icon = 'file-audio-o'
    const styles = { width: 60, minWidth: 60 }

    if (this.state.isShowCallLog) styles.backgroundColor = '#7efbee'

    return (
      <CrmButton
        iconButton
        icon={icon}
        title={
          <FormattedMessage
            id="crm.ui.button.show_audio_file"
            defaultMessage="Show audio file"
          />
        }
        onClick={this.handleShowAudioControl}
        style={styles}
        grouped
      />
    )
  }

  handleShowAudioControl = () => {
    this.setState({ isShowCallLog: !this.state.isShowCallLog })
  }

  renderMinutesOfMeetingButton = () => {
    const minutes = this.props.activity.meeting_minutes
    const styles = {}
    let icon = 'plus'
    if (minutes.meeting_minutes_uid && minutes.completed_at) {
      icon = 'check-circle'
      styles.color = 'green'
    } else if (minutes.meeting_minutes_uid) {
      icon = 'edit'
    }

    return (
      <CrmButton
        iconButton
        icon={icon}
        title={
          <FormattedMessage
            id="crm.ui.button.meeting_minutes"
            defaultMessage="Meeting Minutes"
          />
        }
        onClick={this.handleMinutesOfMeeting}
        className="ActivityListItem-actionButton"
        grouped
      />
    )
  }

  handleShowMeetingLocation = () => {
    this.setState({ isShowMeetingLocation: !this.state.isShowMeetingLocation })
  }

  renderMeetingLocationButton = () => {
    if (this.props.activity.address_uid) {
      return (
        <CrmButton
          iconButton
          icon={'map-marker'}
          title={
            <FormattedMessage
              id="crm.ui.button.meeting_location"
              defaultMessage="Meeting Location"
            />
          }
          onClick={this.handleShowMeetingLocation}
          className="ActivityListItem-actionButton"
          type={this.state.isShowMeetingLocation ? 'primary' : ''}
          grouped
        />
      )
    } else {
      return <span />
    }
  }

  getMeetingAddress = () => {
    const addresses = this.props.account.addresses
    const address =
      addresses && addresses.map
        ? addresses.filter(
            v => v.address_uid === this.props.activity.address_uid
          )[0]
        : undefined
    return address
  }

  renderMeetingLocationAddress = () => {
    const address = this.getMeetingAddress()
    if (address) {
      return (
        <div className="util-flexRowCenter">
          <div style={{ width: '600px' }}>
            <AddressItem
              index={0}
              account_uid={this.props.activity.account_uid}
              address={address}
              mapWidth={0.6}
              mapHeight={'200px'}
              showOnlyRemoveAddress={true}
              hiddenFields={{ all_editable_fields: true }}
              keepFormOpen={true}
            />
          </div>
        </div>
      )
    } else {
      return <span />
    }
  }

  handleOpenNewTab = e => window.open(e.currentTarget.id, '_blank')

  renderMinutesOfMeetingLocation = () => {
    const minutes = this.props.activity.meeting_minutes

    if (
      !minutes.meeting_minutes_uid ||
      !minutes.completed_at ||
      !this.props.activity.meta ||
      !this.props.activity.meta.meeting_minutes_completed_location
    ) {
      return
    }

    const meta = this.props.activity.meta.meeting_minutes_completed_location
    const lat = meta.latitude
    const lng = meta.longitude
    const link = `http://www.google.com/maps/place/${lat},${lng}`
    return (
      <div
        className="MapContextMenu-item"
        title="Location where meeting minutes were completed using phone"
      >
        <Icon name="map-marker" className="MapContextMenu-item-icon" />
        <a onClick={this.handleOpenNewTab} id={link}>
          Meeting location
        </a>
      </div>
    )
  }

  renderTranslateButton = () => {
    if (this.props.showTranlsationIcon !== true) {
      return ''
    }

    const icon = 'file-text-o'
    return (
      <CrmButton
        iconButton
        icon={icon}
        title={
          <FormattedMessage
            id="crm.ui.button.translate"
            defaultMessage="Translate"
          />
        }
        onClick={this.handleClickTranslate}
        grouped
      />
    )
  }

  renderMinutesOfMeetingNote = () => {
    try {
      if (
        this.props.activity.meeting_minutes.details.adnotations.value
          .adnotations
      ) {
        return (
          <div className="ActivityListItem-noteText">
            <FormattedMessage
              id="crm.ui.activity.meeting_minutes_internal_note"
              defaultMessage="Meeting Minutes Internal Note:"
            />{' '}
            <small>
              {
                this.props.activity.meeting_minutes.details.adnotations.value
                  .adnotations
              }
            </small>
          </div>
        )
      }
    } catch (err) {}
    return ''
  }

  renderValidLocationStatus = () => {
    const distance =
      this.props.activity &&
      this.props.activity.meeting_minutes_location_distance
        ? (this.props.activity.meeting_minutes_location_distance * 1).toFixed(2)
        : undefined
    const message = `Meeting minutes completed ${distance} kilometer${
      distance > 1 ? 's' : ''
    } from meeting address`

    if (
      this.props.activity &&
      this.props.activity.meeting_minutes_location_validation
    ) {
      const meeting_minutes_location_validation = JSON.parse(
        this.props.activity.meeting_minutes_location_validation
      )
      return meeting_minutes_location_validation ? (
        <label className="util-textGreen" title={message}>
          Location Valid
        </label>
      ) : (
        <label className="util-textRed" title={message}>
          Location Invalid
        </label>
      )
    } else if (
      this.props.activity &&
      this.props.activity.activity_status &&
      this.props.activity.activity_status.is_success &&
      this.props.activity.activity_status.is_success === true
    ) {
      return <label className="util-textOrange">No Location Information</label>
    }
  }

  _renderActivityKpis() {
    const ret = []
    if (this.props.activity && this.props.activity.seq_same_type_success) {
      ret.push(
        <div
          className={`ActivityStatusWidget util-marginRight`}
          title="Activity sequence (same type)"
        >
          {this.props.activity.seq_same_type_success_user}
        </div>
      )
    }
    return ret
  }

  handleMinutesOfMeeting = () => {
    const { activity } = this.props
    this.props.doChangePage(
      '/crm/account/' +
        activity.account_uid +
        '/activity/' +
        activity.activity_uid +
        '/meeting-minutes'
    )
  }

  handleClickTranslate = () => {
    const noteText = this.props.activity.note_text
      ? this.props.activity.note_text
      : ''
    let mettingNote
    try {
      mettingNote = this.props.activity.meeting_minutes.details.adnotations
        .value.adnotations
    } catch (err) {
      mettingNote = ''
    }

    parent.window.open(
      `https://translate.google.com/?hl=th#th/en/${encodeURIComponent(
        noteText
      )} ${encodeURIComponent(mettingNote)}`,
      'Translate',
      'menubar=1,resizable=1,width=850,height=550'
    )
  }
  handleActivityCompleted = () => this.setState({ isEdited: false })
  renderNoteBody() {}

  getDiffTime() {
    if (
      this.props.activity.call_start_time &&
      this.props.activity.call_end_time
    ) {
      const callStartTimeMoment = moment(this.props.activity.call_start_time)
      const callEndTimeMoment = moment(this.props.activity.call_end_time)
      const diffTime = callEndTimeMoment.diff(callStartTimeMoment, 'seconds')
      return diffTime
    }
    return null
  }

  renderRecordingWidget = () => {
    const p_module = this.props.activity.activity_type
      ? this.props.activity.activity_type.module
      : null
    const has_permission = p_module
      ? this.props.accountPermissionsContext.hasPermissionByType(
          PERMISSIONS_TYPES.ACTIVITIES_RECORDING_READ,
          p_module
        )
      : true
    const dialer_server_url = this.props.crmContext.getAttributeValueByCode(
      'dialer_server_url'
    )
    console.log('dialer_server_url', dialer_server_url)
    if (!dialer_server_url) {
      return (
        // <small className="util-textError util-paddingSm">
        //   dialer server url not found
        // </small>
        <span />
      )
    } else {
      return (
        <div style={{ padding: '0 10px' }}>
          {has_permission && (
            <div>
              <audio className="player" controls>
                <source
                  src={`${dialer_server_url}/play/${this.props.activity?.call_log?.call_uid}.mp3`}
                  type=" audio/mp3"
                />
                <FormattedMessage
                  id="crm.ui.activity.browser_does_not_support"
                  defaultMessage="Your browser does not support the audio element."
                />
              </audio>
              <a
                href={`${dialer_server_url}/play/${this.props.activity?.call_log?.call_uid}.wav`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="crm.ui.activity.download_recording_wav"
                  defaultMessage="Download recording wav"
                />
              </a>
              <a
                href={`${dialer_server_url}/play/${this.props.activity?.call_log?.call_uid}.mp3`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="crm.ui.activity.download_recording_mp3"
                  defaultMessage="Download recording mp3"
                />
              </a>
            </div>
          )}
          {!has_permission && (
            <p style={{ color: 'red', fontSize: '12px', fontStyle: 'italic' }}>
              <FormattedMessage
                id="crm.ui.activity.dont_have_permissions"
                defaultMessage={`Don't have permissions to access recording file.`}
              />
            </p>
          )}
        </div>
      )
    }
  }

  capitalize = s => s[0].toUpperCase() + s.substr(1)

  render() {
    const { activity } = this.props
    const hasActions = true
    const callDurationTime = this.getDiffTime()
    const mediumCode =
      activity && activity.activity_type
        ? activity.activity_type.medium_code
        : undefined

    const infoNote = activity
      ? 'Created ' +
        this.props.intl.formatDate(activity.created_time) +
        ' ' +
        this.props.intl.formatTime(activity.created_time) +
        (activity.created_by_user
          ? ' by ' + activity.created_by_user.full_name
          : '')
      : undefined

    const meeting_minutes_code = activity?.activity_type?.meeting_minutes_code
      ? activity.activity_type.meeting_minutes_code
      : undefined

    console.log('ActivityListItem.render', JSON.stringify(this.props.activity))
    const isPending =
      activity &&
      activity.activity_type &&
      mediumCode === 'phone_call' &&
      !activity.completed_time

    return (
      <div
        className={`ActivityListItem
        ${
          activity.activity_status &&
          activity.activity_status.is_result &&
          !activity.activity_status.is_success
            ? 'ActivityListItem--failed'
            : ''
        }
          TimelineItem ${hasActions ? 'ActivityListItem--hasActions' : ''}
          ${isPending ? 'ActivityListItem--pending' : ''}`}
        style={this.props.style}
      >
        <div className="TimelineItem-timeline">
          <div className="TimelineItem-timeline-line TimelineItem-timeline-lineTop">
            &nbsp;
          </div>
          <div className={`TimelineItem-timeline-icon-wrapper`}>
            <i
              className={`TimelineItem-timeline-icon fa ${this._getIconClass(
                activity.activity_type
              )} `}
            />
          </div>
          <div className="TimelineItem-timeline-line">&nbsp;</div>
        </div>
        <div className={`ActivityListItem-body`}>
          <div className="ActivityListItem-header ActivityListItem-headerBg">
            <div
              className="row"
              style={{
                marginBottom: 20,
                justifyContent: 'space-between'
              }}
            >
              <div className="ActivityListItem-headerItem-value col-md-13">
                {activity.direction
                  ? `${this.capitalize(activity.direction)} - `
                  : ''}
                <span
                  title={`Activity Type: ${activity.activity_type.name_en}`}
                >
                  {activity.activity_type ? activity.activity_type.name : ''}
                </span>{' '}
                <span>
                  <span style={{ color: '#808080', fontWeight: 'normal' }}>
                    {activity.activity_type.medium_code === 'note' &&
                      'created '}
                    {activity.activity_type.medium_code === 'phone_call' &&
                      'started '}
                    {activity.activity_type.medium_code === 'meeting' &&
                      (activity.activity_status &&
                      activity.activity_status.code === 'completed'
                        ? ' held '
                        : '') +
                        (activity.activity_status &&
                        activity.activity_status.code !== 'completed'
                          ? ' scheduled for '
                          : '')}
                  </span>
                  <FormattedDate value={activity.start_time} />
                  <span style={{ color: '#808080', fontWeight: 'normal' }}>
                    {' at '}
                  </span>
                  <FormattedTime value={activity.start_time} />
                  {activity.primary_performer && (
                    <React.Fragment>
                      <span style={{ color: '#808080', fontWeight: 'normal' }}>
                        {' by '}
                      </span>
                      <span>
                        {activity.primary_performer.user &&
                          activity.primary_performer.user.full_name}
                      </span>
                    </React.Fragment>
                  )}
                </span>
                {callDurationTime && (
                  <React.Fragment>
                    <span style={{ color: '#808080', fontWeight: 'normal' }}>
                      {' duration '}
                    </span>
                    {hhmmss(callDurationTime)}
                  </React.Fragment>
                )}
              </div>
              <div
                style={{ justifyContent: 'flex-end' }}
                className="ActivityListItem-headerItem-value col-md-3 util-textRight util-flexRow"
              >
                {this._renderActivityKpis()}
                {mediumCode !== 'email' && activity.activity_status && (
                  <ActivityStatusWidget
                    activityStatus={activity.activity_status}
                  />
                )}
                {activity.email_status && (
                  <EmailStatusWidget status={activity.email_status} />
                )}
              </div>
            </div>
          </div>

          <div className="ActivityListItem-contentWrapper">
            <div className="ActivityListItem-content">
              {isPending && (
                <div className="util-textWhite util-textCenter util-marginBottom">
                  <FormattedMessage
                    id="crm.ui.activity.activity_is_currently"
                    defaultMessage="This activity is currently being performed"
                  />
                </div>
              )}

              {activity.subject && (
                <div className=" row">
                  <div className="ActivityListItem-headerItem col-md-12">
                    <div className="ActivityListItem-headerItem-value">
                      {activity.subject}
                    </div>
                  </div>
                </div>
              )}
              {mediumCode === 'note' && this.renderNoteBody()}

              {activity.note_text && (
                <div
                  className="ActivityListItem-noteText"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: activity.note_text }}
                  />
                  {this.state.noteTextTranslated && (
                    <div>{this.state.noteTextTranslated}</div>
                  )}
                </div>
              )}

              {activity.attachments && activity.attachments.length > 0 && (
                <div className="row">
                  <FontAwesomeIcon
                    style={{ fontSize: '15px', marginTop: '5px' }}
                    className="util-marginLeft"
                    icon="paperclip"
                  />
                  <div
                    style={{
                      display: 'grid',
                      gridGap: '5px',
                      gridTemplateColumns:
                        'repeat(auto-fit, minmax(200px, 1fr))',
                      marginLeft: '10px'
                    }}
                  >
                    {activity.attachments.map(at => {
                      return (
                        <div key={at.resource_uid}>
                          <small className="TasksListItem-doBy">
                            {at.resource && at.resource.name}
                          </small>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              {this.renderMinutesOfMeetingNote()}

              <div />
            </div>
            {hasActions && (
              <div className="ActivityListItem-actionsContainer">
                {mediumCode === 'meeting' &&
                  this.getMeetingAddress() &&
                  this.renderMeetingLocationButton()}

                {mediumCode === 'meeting' &&
                  meeting_minutes_code &&
                  this.renderMinutesOfMeetingButton()}
                {activity?.call_log?.voice_record_uid &&
                  activity?.call_log?.current_status == 'Connected' &&
                  this.renderAudioFileButton()}

                {(activity.note_text || this.props.activity.meeting_minutes) &&
                  this.renderTranslateButton()}
                {mediumCode === 'email' && (
                  <CrmButton
                    icon="envelope"
                    onClick={() =>
                      this.props.onShowEmailLog(this.props.activity)
                    }
                  />
                )}
                <CrmButton
                  icon="pencil"
                  iconButton
                  onClick={this.handleEditAction}
                  title={
                    <FormattedMessage
                      id="crm.ui.button.edit_activity"
                      defaultMessage="Edit activity"
                    />
                  }
                  grouped
                />
              </div>
            )}
          </div>
          <div className="ActivityListItem-footer ">
            <div className="row" style={{ marginBottom: 0 }}>
              {activity.disposition_type && (
                <div className="col-md-5">
                  <strong
                    title={`Call Disposition: ${activity.disposition_type.name_en}`}
                  >
                    {activity.disposition_type.name}
                  </strong>
                </div>
              )}
              {mediumCode === 'meeting' && (
                <React.Fragment>
                  <div className="col-md-2">
                    {this.renderMinutesOfMeetingLocation()}
                  </div>
                  <div className="col-md-4">
                    {this.renderValidLocationStatus()}
                  </div>
                </React.Fragment>
              )}
              {activity.contact_details && (
                <div className="col-md-3">
                  <strong title="Dialed Number">
                    {activity.contact_details.value}
                  </strong>
                </div>
              )}
              <div
                className="col-md-4 ActivityListItem-hideable"
                style={{ fontSize: '0.8em', textAlign: 'right' }}
              >
                {infoNote}
              </div>
            </div>
          </div>

          {this.state.isShowMeetingLocation &&
            this.renderMeetingLocationAddress()}

          {this.state.isShowCallLog && this.renderRecordingWidget()}
        </div>
      </div>
    )
  }
}

ActivityListItem.defaultProps = {
  style: {},
  showTranlsationIcon: false
}

ActivityListItem.propTypes = {
  activity: shape({}).isRequired,
  style: shape({}),
  doChangePage: func.isRequired,
  // hasRecordingsPermission: bool.isRequired,
  showTranlsationIcon: bool,
  account: shape().isRequired,
  onEditActivity: func.isRequired,
  accountPermissionsContext: shape({}).isRequired,
  crmContext: shape({})
}

function mapStateToProps(state) {
  return {
    showTranlsationIcon: getInstanceParameter(
      state,
      'activities.show_translation_icon'
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangePage: path => dispatch(push(path))
  }
}

const ActivityListItemIntl = injectIntl(ActivityListItem)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCrmContext(withAccountPermissionsContextHOC(ActivityListItemIntl)))
