/* eslint-disable camelcase */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { shape } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import { FormContent } from '@cartrack-crm/ui'
import { CrmFullpageContainer } from 'crm-components'
import { AdminHeader, Spacer, VerticalNav } from 'util-components'
import AttributeFormInner from './attribute-form-inner.jsx'
import { saveItem, fetchItem } from 'crm-duxs/admin/attributes-reducer'

class AttributeFormView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      attributeUid:
        props.match.params.attributeUid === 'new'
          ? undefined
          : props.match.params.attributeUid
    }
  }

  componentDidMount() {
    if (this.state.attributeUid) {
      this.props.fetchItem(this.state.attributeUid)
    }
  }

  handleFormSubmit = async values => {
    // console.log('attribute form value : ', values)
    this.props.saveItem(values)
    toastr.success('Saved')
    this.props.doPush('/crm/admin/attribute/')
  }

  formatFormValue = () => {
    if (this.state.attributeUid) {
      const formValues = Object.assign({}, this.props.attribute)
      return formValues
    }

    return {}
  }

  render() {
    console.log('Attribute form view ')
    this.breadcrumbPaths = [
      { label: 'Back to list', path: '/crm/admin/attribute' },
      {
        label:
          this.props.attributeUid !== 'new'
            ? 'Edit Attribute'
            : 'Create Attribute'
      }
    ]

    return (
      <CrmFullpageContainer vbox>
        <div className="row FormDetailView">
          <div className="col-xs-10 col-xs-offset-1">
            <AdminHeader breadcrumbs={this.breadcrumbPaths} />
            <VerticalNav
              items={[
                {
                  label: 'Details',
                  id: 'details',
                  subItems: [
                    { label: 'Attribute info', id: 'attribute-info-section' }
                  ]
                }
              ]}
              scrollOffset={250}
            />
            <FormContent>
              <AttributeFormInner
                onSubmit={this.handleFormSubmit}
                initialValues={this.formatFormValue()}
              />
            </FormContent>
            <Spacer height="300px" />
          </div>
        </div>
      </CrmFullpageContainer>
    )
  }
}

AttributeFormView.propTypes = {
  attribute: shape({})
}

AttributeFormView.defaultProps = {
  attribute: {}
}

function mapStateToProps(state) {
  const attributeState = state.crm.attribute
  return {
    attribute: attributeState.selected
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveItem: values => dispatch(saveItem(values)),
    fetchItem: uid => dispatch(fetchItem(uid)),
    doPush: url => dispatch(push(url))
  }
}

const AttributeFormViewWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributeFormView)

export default AttributeFormViewWithState
