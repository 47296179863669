import React, { PureComponent } from 'react'
import { shape, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ActionButtonForm from '../../components/action-button.jsx'
// eslint-disable-next-line
import { CrmTextInput, CrmButton, CrmDayPickerInput } from 'crm-components'
import { msToUTCTimestamp } from 'util-functions/time-utils'

class ParticipantForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { data: props.data ? props.data : {} }
    this.handleFieldChanged = this.handleFieldChanged.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleInsertPresentQuotation = this.handleInsertPresentQuotation.bind(
      this
    )
    this.handleDateFieldChanged = this.handleDateFieldChanged.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ ...this.state, data: nextProps.data })
    }
  }

  handleInsertPresentQuotation() {}
  handleSave() {
    const data = { ...this.state.data }
    data.due_date = data.due_date ? msToUTCTimestamp(data.due_date) : undefined // eslint-disable-line
    this.props.onSave(data)
  }

  handleFieldChanged(event) {
    const newData = { ...this.state.data }
    newData[event.currentTarget.id] = event.currentTarget.value
    this.setState({ ...this.state, data: newData })
  }
  handleDateFieldChanged(value, name) {
    const newData = { ...this.state.data }
    if (name) {
      newData[name] = value
      this.setState({ ...this.state, data: newData })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div>
        <div className="row util-marginBottom">
          <div className="col-md-4">
            <h5>
              <FormattedMessage
                id="crm.ui.form.edit_next_action_data"
                defaultMessage="Edit Next Action Data"
              />
            </h5>
          </div>
          {/* <div className="col-md-4">
            <CrmButton
              label="Insert present quotation task"
              onClick={this.handleInsertPresentQuotation}
            />
          </div> */}
        </div>
        <div className="row util-marginBottom">
          <div className="col-md-4">
            <CrmTextInput
              placeholder="action"
              placeholderId="crm.ui.textinput.action"
              id="action"
              input={{
                value: data.action,
                onChange: this.handleFieldChanged
              }}
            />
          </div>
          <div className="col-md-4">
            <CrmTextInput
              placeholder="owner"
              placeholderId="crm.ui.textinput.owner"
              id="owner"
              input={{
                value: data.owner,
                onChange: this.handleFieldChanged
              }}
            />
          </div>
          <div className="col-md-4">
            <CrmDayPickerInput
              id="due_date"
              placeholder={
                <FormattedMessage
                  id="crm.ui.picker.due_date"
                  defaultMessage="due date"
                />
              }
              name="due_date"
              input={{
                value: data.due_date,
                onChange: this.handleDateFieldChanged
              }}
            />
          </div>
        </div>
        <div>
          <ActionButtonForm
            onSave={this.handleSave}
            onCancel={this.props.onCancel}
          />
        </div>
      </div>
    )
  }
}

ParticipantForm.propTypes = {
  onCancel: func.isRequired,
  onSave: func.isRequired,
  data: shape({}).isRequired
}

export default ParticipantForm
