/* eslint-disable import/no-duplicates */
/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { func, shape, string, bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MoreFilterContainer from '../more-filter-container.jsx'
import { CrmTextInput, CrmUsersSelect, CrmButton } from 'crm-components'
import { updateFilter } from 'crm-duxs/accounts-reducer'
import { getFilterGroup, setFilterValue } from 'crm-duxs/filters-reducer'
import { withGeneralPermissionsContextHOC } from '@cartrack-crm/crm-core'
import { LoadingIcon } from 'util-components'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import { get } from 'lodash'
import styled from 'styled-components'

const SearchBtn = styled(CrmButton)`
  border-radius: 0 8px 8px 0;
  display: flow-root;
`

class AccountInfoFilter extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      accountNameValue: '',
      kindValue: 'all',
      kindIsDisabled: false,
      accountNameIsDisabled: false,
      isShowingMoreFilters: false,
      accountString: ''
    }
  }
  componentDidMount = () => {
    const query = get(this.props, 'filters.query.enabled', false)
    ? get(this.props, 'filters.query.value.rawValue', '')
    : this.state.accountString
    this.setState({ accountString: query })
  }
  handleAccountNameChange = event => {
    const value = event.target.value
    this.setState({ accountString: value })
  }

  _handleKeyDown = event => event.key === 'Enter' && this.doSentRequest()

  doSentRequest = () => {
    if (this.props.filters?.query?.value?.rawValue !== this.state.accountString) {
      this.updateFilter({ accountNameValue: this.state.accountString })
      this.props.doSetFilterValue(
        this.props.accountsListCode,
        this.props.queryField ? this.props.queryField : 'query',
        {
          value: { rawValue: this.state.accountString },
          enabled: true
        }
      )
    }
  }

  handleKindChange(value) {
    this.setState({ ...this.state, kindValue: value === 'all' ? '' : value })
  }

  handleToggleMoreFilters = () => {
    this.setState({
      ...this.state,
      isShowingMoreFilters: !this.state.isShowingMoreFilters
    })
  }

  updateFilter(state) {
    const nameCondition = {
      condition_key: this.props.defaultColumnFilter,
      condition_value: state.accountNameValue
    }

    this.props.doUpdateFilter(
      'account_info',
      [nameCondition],
      this.props.accountsListCode
    )
  }

  handleUserSelect = value => {
    this.props.doUpdateFilter(
      'owner_new',
      [
        {
          condition_key: 'owner_user_uid',
          condition_value: value
        }
      ],
      this.props.accountsListCode
    )

    this.props.doSetFilterValue(this.props.accountsListCode, 'owner_user_uid', {
      value: { rawValue: value },
      enabled: true
    })
  }

  getPlaceholder() {
    switch (this.props.defaultColumnFilter) {
      case 'cams_username':
        return 'Cams username'
      default:
        return 'Account name'
    }
  }

  renderMoreFiltersButton = () => {
    const haveEnabledFilters = this.props.filters
      ? Object.keys(this.props.filters).reduce(
          (a, v) => a || this.props.filters[v].enabled,
          false
        )
      : false

    return (
      <div className="Button CrmButton" onClick={this.handleToggleMoreFilters}>
        <FontAwesomeIcon
          icon="filter"
          className={`util-marginRight ${
            haveEnabledFilters ? 'util-textOrange' : ''
          }`}
        />
        More Filters
      </div>
    )
  }

  render() {
    const isManager = this.props.generalPermissionsContext.hasPermissionByType(
      PERMISSIONS_TYPES.GENERAL_DATA_MANAGE
    )

    const selectedOwnerUid = get(this.props, 'filters.owner_user_uid.enabled')
      ? get(this.props, 'filters.owner_user_uid.value.rawValue', '')
      : ''
    
    return (
      <div>
        <div className="AccountsListFilters-filterItem util-flexRow ">
          <div className="util-flexGrow util-marginRight" style={{ display: 'flex' }}>
            <CrmTextInput
              name={this.props.defaultColumnFilter}
              placeholder={this.getPlaceholder()}
              onChange={this.handleAccountNameChange}
              value={this.state.accountString}
              disabled={this.state.accountNameIsDisabled}
              style={{ borderRadius: '8px 0 0 8px !important' }}
              onKeyPress={this._handleKeyDown}
            />
            <SearchBtn label='Search' type='primary' icon='search' onClick={this.doSentRequest} />
          </div>
          <div className="util-marginRight">
            {this.renderMoreFiltersButton()}
          </div>
          {isManager && (
            <div style={{ minWidth: 300 }}>
              <CrmUsersSelect
                name="name"
                input={{
                  value: selectedOwnerUid,
                  onChange: this.handleUserSelect
                }}
                permissionCode="all"
                placeholder="Account owner"
                disabled={!isManager}
                addEmptyOption
                emptyOptionLabel="All"
              />
            </div>
          )}

          {this.props.isShowLoadingIcon && (
            <div style={{ width: '25px', textAlign: 'right' }}>
              <LoadingIcon />
            </div>
          )}
        </div>

        {this.state.isShowingMoreFilters && (
          <MoreFilterContainer groupCode={this.props.accountsListCode} />
        )}
      </div>
    )
  }
}

AccountInfoFilter.propTypes = {
  accountsListCode: string.isRequired,
  doUpdateFilter: func.isRequired,
  isShowLoadingIcon: bool,
  defaultColumnFilter: string,
  generalPermissionsContext: shape({}).isRequired,
  filters: shape({}).isRequired,
  doSetFilterValue: func.isRequired,
  queryField: string
}

AccountInfoFilter.defaultProps = {
  string: undefined,
  defaultColumnFilter: undefined,
  queryField: undefined,
  isShowLoadingIcon: false
}

function mapStateToProps(state, ownProps) {
  return {
    filters: getFilterGroup(state, ownProps.accountsListCode || 'global')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doUpdateFilter: (filterName, conditions, accountsListCode) =>
      dispatch(updateFilter(filterName, conditions, accountsListCode)),
    doSetFilterValue: (groupCode, filterCode, params) =>
      dispatch(setFilterValue(groupCode, filterCode, params))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGeneralPermissionsContextHOC(AccountInfoFilter))
