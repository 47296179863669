import React from 'react'
import { connect } from 'react-redux'
import { Query, withApollo } from 'react-apollo'
import { getFormValues } from 'redux-form'
import { func, arrayOf, shape } from 'prop-types'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import {
  qlqListCallDispositionTypes,
  qlqGetLiveActivity,
  qlmActivitiesCommand,
  qlqAccountActivities
} from 'crm-data/activities'
import { qlqAccountTasks } from 'crm-data/tasks'
import { qlqProcessDetails } from 'crm-data/processes'
import { activityCompleted } from 'crm-duxs/activities-reducer'
import { parseActivityStatus } from 'crm-api/api-parsers'
import { getDefaultDispositionForStatus } from '../utils/call-disposition-utils'
import { updateActivitiesStoreAfterCreated } from 'crm-data/activities-store-utils'

const debounce = (fn, time) => {
  let timeout
  return async function() {
    const functionCall = async () => fn.apply(this, arguments)
    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

const LiveCallHoc = WrappedComponent => {
  const LiveCallHOCInner = class extends React.PureComponent {
    static propTypes = {
      activity: shape(),
      formValues: shape().isRequired,
      dispositionTypes: arrayOf(shape()),
      onRunCommand: func.isRequired,
      doActivityCompleted: func.isRequired,
      onActivityCompleted: func,
      client: shape({}).isRequired,
      options: shape()
    }
    static defaultProps = {
      activity: null,
      dispositionTypes: [],
      onActivityCompleted: () => {},
      options: undefined
    }

    constructor(props) {
      super(props)
      const initialValues = {}
      if (props.activity) {
        initialValues.dispositionDetailsForm =
          props.activity.disposition_details
      }
      this.state = {
        activity: props.activity,
        noteText: props.activity ? props.activity.note_text : '',
        callDispositionUid: props.activity
          ? props.activity.disposition_type_uid
          : undefined,
        initialValues,
        isDeletetingActivity: false
      }
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    componentWillReceiveProps(nextProps) {
      if (nextProps.activity !== this.props.activity && !this.state.activity) {
        // Update only if  no current activity
        const newState = {
          activity: nextProps.activity,
          noteText: nextProps.activity.note_text,
          callDispositionUid: nextProps.activity
            ? nextProps.activity.disposition_type_uid
            : undefined
        }
        this.setState(newState)
      }
      if (
        nextProps.formValues &&
        ((this.props.formValues &&
          nextProps.formValues.dispositionDetailsForm !==
            this.props.formValues.dispositionDetailsForm) ||
          !this.props.formValues)
      ) {
        const formValues = nextProps.formValues
        this.setState({}, () => {
          if (
            formValues.dispositionDetailsForm !==
            this.state.initialValues.dispositionDetailsForm
          ) {
            this.handleDispositionChanged({
              disposition_type_uid: this.state.callDispositionUid,
              dispositionDetails: formValues.dispositionDetailsForm
            })
          }
        })
      }
    }

    handleNoteChanged = async event => {
      const noteText = event.currentTarget.value
      await this.setStateAsync({ noteText })
    }

    handleActivityResultDataChange = async resultData => {
      const activityChange = {
        result_data: this.state.activity.result_data
          ? { ...this.state.activity.result_data, ...resultData }
          : { ...resultData },
        activity_uid: this.props.activity.activity_uid,
        account_uid: this.props.activity.account_uid,
        activity_status_code: this.state.activity.activity_status?.code
      }
      return this._saveLiveChange(activityChange)
    }

    saveActivityNote = async () => {
      const activityChange = {
        note_text: this.state.noteText,
        activity_uid: this.props.activity.activity_uid,
        account_uid: this.props.activity.account_uid,
        activity_status_code: this.state.activity.activity_status?.code
      }
      await this._saveLiveChange(activityChange, true)
    }

    handleStatusChanged = async activityStatus => {
      const activityChange = {
        activity_status_code: activityStatus ? activityStatus.code : undefined,
        activity_uid: this.props.activity.activity_uid,
        account_uid: this.props.activity.account_uid
      }
      // check if should change default disposition
      const defaultDisposition = getDefaultDispositionForStatus(
        this.props.dispositionTypes,
        activityStatus
      )

      if (defaultDisposition) {
        activityChange.disposition_type_uid =
          defaultDisposition.disposition_type_uid
        await this.setStateAsync({
          callDispositionUid: defaultDisposition.disposition_type_uid
        })
      }
      if (activityStatus === undefined) {
        activityChange.disposition_type_uid = undefined
      }

      await this._saveLiveChange(activityChange)
    }
    handleCallCompleted = async () => {
      const command = {
        type: 'activity.completeLive',
        aggregate_uid: this.props.activity.activity_uid,
        aggregate_type: 'Activity',
        payload: {
          activity_uid: this.props.activity.activity_uid
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqAccountTasks,
            variables: { account_uid: this.props.activity.account_uid }
          }
        ]
        if (this.props.activity.process_uid) {
          refetchQueries.push({
            query: qlqProcessDetails,
            variables: { process_uid: this.props.activity.process_uid }
          })
        }
        await this.setStateAsync({ isSaving: true })
        const update = updateActivitiesStoreAfterCreated({
          variables: { account_uid: this.props.activity.account_uid }
        })
        await this.saveActivityNote()
        const res = await this.props.onRunCommand(
          command,
          {
            refetchQueries,
            update: update
          },
          qlmActivitiesCommand
        )
        const activity = res.payload
        // Notify reducer to propagate to dialer
        await this.setStateAsync({ isSaving: false })
        this.props.doActivityCompleted(this.props.activity)
        if (this.props.onActivityCompleted) {
          this.props.onActivityCompleted(activity)
        }
        return activity
      } catch (err) {
        await this.setStateAsync({ isSaving: false })
        throw new Error(err)
      }
    }

    handleDispositionChanged = debounce(async disposition => {
      if (disposition) {
        const newState = {}
        if (disposition.disposition_type_uid) {
          newState.disposition_type_uid = disposition.disposition_type_uid
          newState.callDispositionUid = disposition.disposition_type_uid
        }
        newState.callDispositionDetails = disposition.dispositionDetails
        await this.setStateAsync(newState)
        const activityChange = {
          disposition_type_uid: disposition.disposition_type_uid,
          disposition_details: disposition.dispositionDetails,
          activity_uid: this.props.activity.activity_uid,
          account_uid: this.props.activity.account_uid,
          activity_status_code: this.state.activity.activity_status?.code
        }
        await this._saveLiveChange(activityChange)
      }
    }, 500)

    _saveLiveChange = async (activityChange, skipLoading = false) => {
      // TEST !
      await this.setStateAsync({
        isSaving: true,
        activity: {
          ...this.state.activity,
          ...activityChange
        }
      })
      if (!activityChange.activity_uid) {
        throw new Error('Problem saving live activity - no activity_uid')
      }
      const command = {
        type: 'activity.liveUpdate',
        aggregate_uid: activityChange.activity_uid,
        aggregate_type: 'Activity',
        payload: {
          ...activityChange
        }
      }
      try {
        const res = await this.props.onRunCommand(command, {
          forceNewCommandUid: true
        })
        let savedActivity = res.data.cqCommand.payload.activity
        // Extract nested response - for compat
        if (savedActivity.hasOwnProperty('activity')) {
          savedActivity = savedActivity.activity
        }
        const refreshActivityRes = await this.props.client.query({
          query: qlqGetLiveActivity,
          variables: { activity_uid: activityChange.activity_uid },
          fetchPolicy: 'no-cache'
        })
        let refreshActivity = refreshActivityRes.data.activity
        refreshActivity = parseActivityStatus(refreshActivity)
        const objectReload = {
          activity: refreshActivity,
          isSaving: true
        }
        if (!skipLoading) {
          objectReload.isSaving = false
        }
        await this.setStateAsync(objectReload)
        return refreshActivity
      } catch (err) {
        console.log('Catch error ', err)
        await this.setStateAsync({ isSaving: false })
      }
      if (!skipLoading) {
          await this.setStateAsync({ isSaving: false })
      }
    }
    handleUpdateResultData = data => {
      this.setState()
    }

    handleDeleteActivity = async (activity, is_deleted) => {
      await this.setStateAsync({ isDeletetingActivity: true })
      const command = {
        type: 'activity.admin_delete',
        aggregate_uid: activity.activity_uid,
        aggregate_type: 'activity',
        payload: {
          activity: {
            activity_uid: activity.activity_uid,
            is_deleted: is_deleted
          }
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqAccountActivities,
            variables: {
              account_uid: activity.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })
        await this.setStateAsync({ isDeletetingActivity: false })
        return res.data.cqCommand
      } catch (err) {
        await this.setStateAsync({ isDeletetingActivity: false })
        throw err
      }
    }

    render() {
      const isNoteChanged =
        this.state.activity &&
        this.state.activity.note_text !== this.state.noteText
      const isDispositionChanged =
        this.state.activity &&
        this.state.activity.disposition_type_uid !==
          this.state.callDispositionUid
      return (
        <WrappedComponent
          {...this.props}
          activity={this.state.activity}
          options={this.props.options}
          noteText={this.state.noteText}
          isSaving={this.state.isSaving}
          isNoteChanged={isNoteChanged}
          isDispositionChanged={isDispositionChanged}
          onChange={this.handleOnChange}
          onNoteChanged={this.handleNoteChanged}
          onCallCompleted={this.handleCallCompleted}
          onCallStatusChanged={this.handleStatusChanged}
          onDispositionChange={this.handleDispositionChanged}
          onResultDataChange={this.handleActivityResultDataChange}
          addEmptyOption
          initialValues={this.state.initialValues}
          onDeleteActivity={this.handleDeleteActivity}
          isDeletetingActivity={this.state.isDeletetingActivity}
        />
      )
    }
  }

  const WithCommand = withApollo(
    crmGenericCommandHoc(
      connect((state, ownProps) => {
        const formValues = getFormValues(
          ownProps.form ? ownProps.form : 'liveCall'
        )(state)

        return {
          formValues
        }
      }, mapDispatchToProps)(LiveCallHOCInner)
    )
  )
  // return withCommand
  const withDispositions = props => (
    <Query query={qlqListCallDispositionTypes}>
      {({ loading, error, data, refetch }) => (
        <WithCommand
          {...props}
          dispositionTypes={
            data && data.call_disposition_type_list_qm_paged
              ? data.call_disposition_type_list_qm_paged.data
              : undefined
          }
        />
      )}
    </Query>
  )
  return withDispositions
}

function mapDispatchToProps(dispatch) {
  return {
    doActivityCompleted: activity => dispatch(activityCompleted(activity))
  }
}
LiveCallHoc.propTypes = {
  onChange: func.isRequired,
  options: arrayOf({}).isRequired,
  activity: shape({}).isRequired
}

export default LiveCallHoc
