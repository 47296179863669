/* eslint-disable camelcase */
import moment from 'moment'
import { qlqListOpportunities } from 'crm-data/opportunities'
import { mapContextToFilter } from './rms-dashboard-utils'

const parseOpportunitiesDueMonth = data => {
  const users = {}
  data.forEach(row => {
    if (!users[row.owner_user_uid]) {
      users[row.owner_user_uid] = {
        user_uid: row.owner_user_uid,
        user_name: row.owner_user_name,
        opportunitiesDueMonth: {},
        mainKpis: []
      }
    }
    const user = users[row.owner_user_uid]
    if (row.opportunity_due_date_month) {
      const date = moment(row.opportunity_due_date_month)
      const dateFormat = date.format('YYYY-MM-DD')
      if (!user.opportunitiesDueMonth[dateFormat]) {
        user.opportunitiesDueMonth[dateFormat] = {
          count: 0,
          size: 0,
          date: dateFormat
        }
      }

      user.opportunitiesDueMonth[dateFormat].size += Number(
        row.opportunities_size
      )

      user.opportunitiesDueMonth[dateFormat].count += Number(
        row.opportunity_uid
      )
    }
  })

  return users
}
export const mock = 1
export const loadOpportunitiesDueMonth = async params => {
  console.log('loadOpportunitiesDueMonth')

  const filter = mapContextToFilter(params, true)
  filter.completed_date = 'null'
  const variables = {
    filter,
    fields: ['owner_user_name', 'owner_user_uid', 'opportunity_due_date_month'],
    aggregate: [
      { id: 'opportunity_uid', type: 'count' },
      { id: 'opportunities_size', type: 'sum' }
    ]
  }

  const res = await params.client.query({
    query: qlqListOpportunities,
    variables,
    fetchPolicy: 'no-cache'
  })
  console.log('Got OpportunitiesDueMonth result', res)
  if (res && res.data && res.data.opportunities_list_qm_paged) {
    return parseOpportunitiesDueMonth(res.data.opportunities_list_qm_paged.data)
  }
  return undefined
}
