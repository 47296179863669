/* eslint-disable */

import React, { Component } from 'react'
import { shape, arrayOf, string } from 'prop-types'
import moment from 'moment'
import { FormattedTime, FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import messagesQLHOC from './messages-tab-messages-hoc'
import {
  CrmButton,
  CrmCheckbox,
  CrmHbox,
  CrmLabeledField
} from 'crm-components'

class MessagesTabMessages extends Component {
  constructor(props) {
    super(props)
    this.state = { unreadOnly: true, selectedMessage: undefined }
  }

  handleRefresh = () => {
    this.props.messagesQl.refetch({
      mine: true,
      unread: this.state.unreadOnly
    })
  }

  handleClickMessage = async event => {
    const messageUid = event.currentTarget.id
    if (messageUid) {
      this.setState({ selectedMessage: messageUid })
      const variables = { message_uid: messageUid }
      await this.props.qlFetchSelectedMessage.refetch({
        message_uid: messageUid,
        skip: false
      })

      this.props.markAsReadQl({ variables })
    }
  }

  handleToggleUnreadOnly = () => {
    this.setState({ ...this.state, unreadOnly: !this.state.unreadOnly }, () => {
      this.handleRefresh()
    })
  }

  handleMarkAsRead = async () => {
    const variables = { message_uid: this.state.selectedMessage }
    await this.props.markAsReadQl({ variables })
    await this.handleRefresh()
  }

  _renderMessageItem = msg => (
    <div
      key={msg.message_uid}
      className={`CrmMessageItem ${
        msg.read_time === undefined || msg.read_time === null
          ? 'CrmMessageItem--unread'
          : ''
      }`}
      id={msg.message_uid}
      onClick={this.handleClickMessage}
    >
      {msg.title}
      {msg.created_time && (
        <div className="CrmMessageItem-time">
          <FormattedDate value={msg.created_time} />{' '}
          <FormattedTime value={msg.created_time} />
        </div>
      )}
    </div>
  )

  renderAttachments = paramsData => {
    const params = JSON.parse(paramsData)
    if (
      params != null &&
      typeof params == 'object' &&
      params.hasOwnProperty('attachments') &&
      Array.isArray(params.attachments)
    ) {
      return params.attachments.map(attach => this.renderAttachment(attach))
    }

    return ''
  }

  renderAttachment = attach => {
    if (attach.item_type == 'account') {
      const linkAccount = '/crm/account/' + attach.account_uid
      return (
        <div className="Widget CrmWidget CrmWidget--noHeight util-marginBottom util-marginTop">
          <div className="util-flexRow">
            <Link to={linkAccount}>
              <div className="util-flexGrow">
                <CrmLabeledField label="Account Name">
                  <span
                    className="util-textRed util-textBold"
                    style={{ fontSize: 20 }}
                  >
                    <span style={{ fontSize: 10, margin: 5 }}>
                      {attach.name}
                    </span>
                  </span>
                </CrmLabeledField>
              </div>
            </Link>
          </div>
        </div>
      )
    }

    return ''
  }

  render() {
    if (this.props.messagesQl.loading) {
      return 'Loading...'
    }

    const messages = Array.isArray(this.props.messagesQl.messages)
      ? this.props.messagesQl.messages
          .slice(0)
          .sort((a, b) => moment(b.created_time) - moment(a.created_time))
      : []

    const displayBody = message => {
      var text = message.replace(/<br\s*[\/]?>/gi, '\n')
      return <div style={{ whiteSpace: 'pre-line' }}>{text}</div>
    }

    return (
      <div
        className="CrmMessagesPopup-body util-fullHeight"
        style={{ flexDirection: 'row' }}
      >
        <div className="CrmMessagesPopup-messagesLeft util-fullHeight">
          <CrmHbox style={{ padding: 5 }}>
            <CrmButton label="Refresh" onClick={this.handleRefresh} />
            <CrmCheckbox
              label="New Only"
              input={{
                value: this.state.unreadOnly,
                onChange: this.handleToggleUnreadOnly
              }}
              extraClassNames={{ containerClassNames: 'util-marginLeft' }}
            />
          </CrmHbox>
          <div className="CrmMessagesPopup-messagesList util-fullHeight">
            {messages && <div>{messages.map(this._renderMessageItem)}</div>}
          </div>
        </div>
        <div className="CrmMessagesPopup-messagesRight">
          {this.props.qlFetchSelectedMessage.loading && <p>Loading...</p>}
          {this.state.selectedMessage &&
            !this.props.qlFetchSelectedMessage.loading &&
            this.props.qlFetchSelectedMessage.message && (
              <div className="CrmMessagesPopup-message">
                {this.renderAttachments(
                  this.props.qlFetchSelectedMessage.message.params
                )}
                <div className="CrmMessagesPopup-message-title">
                  {this.props.qlFetchSelectedMessage.message.title}
                </div>
                <div className="CrmMessagesPopup-message-body">
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {this.props.qlFetchSelectedMessage?.message?.body?.replace(
                      /<br\s*[\/]?>/gi,
                      '\n'
                    )}
                  </div>
                </div>
                {/* <div className="util-padding">
                <CrmButton
                  label="Mark As Read"
                  onClick={this.handleMarkAsRead}
                />
              </div> */}
              </div>
            )}
        </div>
      </div>
    )
  }
}

MessagesTabMessages.propTypes = {
  messages: arrayOf(shape()),
  actions: shape({}).isRequired,
  selectedMessage: string
}
MessagesTabMessages.defaultProps = {
  selectedMessage: undefined,
  messages: []
}

export default messagesQLHOC(MessagesTabMessages)
