import territoriesApi from 'crm-api/settings/territories-api'
import {
  FETCH_TERRITORIES,
  FETCH_TERRITORIES_SUCCESS,
  SAVE_TERRITORY,
  SAVE_TERRITORY_SUCCESS
} from 'crm-duxs/settings/territory-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchTerritories() {
  const territories = yield call(territoriesApi.listTerritoriesApi, {})
  yield put({ type: FETCH_TERRITORIES_SUCCESS, payload: { territories } })
}

function* saveTerritory(action) {
  const territoryUid = action.payload.territory.territory_uid
  const territory = territoryUid
    ? yield call(
        territoriesApi.updateTerritoryApi,
        territoryUid,
        action.payload.territory
      )
    : yield call(territoriesApi.createTerritoryApi, action.payload.territory)
  yield put({ type: SAVE_TERRITORY_SUCCESS, payload: { territory } })
  yield put({ type: FETCH_TERRITORIES })
}

export default function* territorySaga() {
  yield takeLatest(FETCH_TERRITORIES, fetchTerritories)
  yield takeLatest(SAVE_TERRITORY, saveTerritory)
}
