import React from 'react'
import { arrayOf, shape, func } from 'prop-types'
import { availableColumns } from './digital-widgets-commons.jsx'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'

class ShortAnalyticsAccountsList extends React.PureComponent {
  doRefetch = () => {
    console.log('ShortAnalyticsAccountsList do Refresh')
    console.log(this.listRef)
    this.listRef.doRefetch()
  }

  handleRef = ref => {
    this.listRef = ref
  }
  handleOnDataLoaded = data => {
    console.log('handleOnDataLoaded', data, this.props)
    if (this.props.setHeaderComponent && data) {
      // Render header
      let count = data && data.count ? data.count : undefined
      if (!count && data && Array.isArray(data.data)) {
        count = data.data.length
      }
      console.log('count ', count)
      this.props.setHeaderComponent(
        <div className="util-textGreen">{count ? count + ' Accounts' : ''}</div>
      )
    }
  }
  render() {
    const { query, qlOptions } = this.props
    const Table = genericTableFactory({
      query,
      qlOptions
    })

    const buildColumns = this.props.columns
      ? this.props.columns.map(c => {
          if (typeof c === 'string') {
            return availableColumns[c] ? availableColumns[c] : undefined
          }
          return c
        })
      : availableColumns
    return (
      <Table
        ref={this.handleRef}
        {...this.props}
        columns={buildColumns}
        style={{ height: '100%' }}
        onDataLoaded={this.handleOnDataLoaded}
      />
    )
  }
}

ShortAnalyticsAccountsList.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  query: shape().isRequired,
  qlOptions: shape().isRequired,
  setHeaderComponent: func.isRequired
}
export default ShortAnalyticsAccountsList
