import React from 'react'
import { Query } from 'react-apollo'
import { campaignQmQl } from 'crm-data/campaigns'
import { FiltersOptionList } from '../tools'
import Icon from '../../../../../util-components/icon'
interface CampaignSelectProp {
  filterValues: any
  onFilterValueChanged: Function
  type: string
}
interface CampaignSelectState {
  showInactive: boolean
}
class CampaignSelect extends React.Component<CampaignSelectProp, CampaignSelectState> {
  constructor(props) {
    super(props)
    this.state = {
      showInactive: false
    }
  }

  handleShowInactive = () => {
    this.setState({ showInactive: !this.state.showInactive })
  }

  toolboxRenderer = () => (
    <div>
      <div>
        <Icon
          name="archive"
          title="Show archived campaigns"
          style={{
            position: 'absolute',
            top: '3px',
            right: '22px',
            opacity: this.state.showInactive ? '1.0' : '0.3',
            fontSize: '14px'
          }}
          onClick={this.handleShowInactive}
        />
      </div>
    </div>
  )
  getFirstValue = val => {
    return Array.isArray(val) && val.length > 0 ? val[0] : val
  }

  render() {
    const selectedLeadSourceUid = this.getFirstValue(
      this.props.filterValues?.account?.lead_campaign?.lead_source_uid?.value
    )
    return (
      <Query query={campaignQmQl} variables={{ filter: { type: this.props.type }, limit: 2000 }}>
        {({ loading, data, refetch }) => {
          const campaings = data && data.campaign_list_qm_paged ? [...data.campaign_list_qm_paged.data] : []
          if (campaings) {
            campaings.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
          }
          const campaignOptions = campaings
            ? campaings
                .filter(v => {
                  return selectedLeadSourceUid ? v.lead_source_uid === selectedLeadSourceUid : v
                })
                .map(c => ({
                  value: c.campaign_uid,
                  name: c.name,
                  is_active: c.is_active
                }))
            : []
          return (
            <FiltersOptionList
              enableSearch={true}
              isLoading={loading}
              options={campaignOptions}
              onFilterValueChanged={this.props.onFilterValueChanged}
              selectedValue={
                this.props.type === 'sale'
                  ? this.props.filterValues?.sales_campaign?.campaign_uid?.value
                  : this.props.filterValues?.account?.lead_campaign?.campaign_uid?.value
              }
              toolboxRenderer={this.toolboxRenderer}
              showInactive={this.state.showInactive}
            />
          )
        }}
      </Query>
    )
  }
}

export default CampaignSelect
