import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import { AnalyticsContextFilters } from '@cartrack-crm/crm-analytics'
import { GenericDropDownFilter, GenericDropDownContainer, GenericClearFilter } from '../tools'
import { CrmOrganizationUnitTreeInner } from 'crm-components'
import { DepartmentTypeSelect } from '.'

interface OrganizationUnitDropdownTypeProps {
  filterValues: AnalyticsContextFilters
  isExpanded: boolean
  onClickedOutside: Function
  onSetFilters: Function
}

class OrganizationUnitDropdown extends React.PureComponent<OrganizationUnitDropdownTypeProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      filterValues: props.filterValues ? { ...props.filterValues } : {}
    }
  }

  componentWillReceiveProps = nextProps => {
    if (JSON.stringify(nextProps.filterValues) !== JSON.stringify(this.props.filterValues)) {
      this.setState({ filterValues: { ...nextProps.filterValues } })
    }
  }

  handleClickOutside = () => {
    if (this.props.isExpanded) {
      this.saveValue()
      setTimeout(this.props.onClickedOutside, 5)
    }
  }

  saveValue = () => {
    this.props.onSetFilters(this.state.filterValues)
  }

  handleDepartmentTypeChanged = newValue => {
    const newFilterValues = { ...this.state.filterValues }
    const newOrgUnitFilters: any = {}

    newOrgUnitFilters.department_type_uid = newValue
      ? {
          value: newValue.value,
          label: newValue.name ? newValue.name : newValue.label,
          item: newValue
        }
      : undefined

    newFilterValues.organization_unit = { ...newOrgUnitFilters }

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleFilterOrganizationUnitChanged = newValue => {
    const newFilterValues = { ...this.state.filterValues }
    const newOrgUnitFilters: any = {
      department_type_uid: {
        ...newFilterValues?.organization_unit?.department_type_uid
      }
    }

    newOrgUnitFilters.organization_unit_uid = newValue
      ? {
          value: newValue.value,
          label: newValue.name ? newValue.name : newValue.label,
          item: newValue
        }
      : undefined

    newFilterValues.organization_unit = { ...newOrgUnitFilters }

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilter = filterName => {
    const newFilterValues = { ...this.state.filterValues }

    if (newFilterValues?.organization_unit) {
      newFilterValues.organization_unit[filterName] = undefined
    }

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilters = () => {
    const newFilterValues = { ...this.state.filterValues }
    newFilterValues.organization_unit = undefined

    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClickApply = () => {
    this.handleClickOutside()
  }

  handleClickCancel = () => {
    this.setState({ filterValues: { ...this.props.filterValues } })
    setTimeout(this.props.onClickedOutside, 5)
  }

  render() {
    const selected_department_uid = this.state.filterValues?.organization_unit?.department_type_uid?.value

    return (
      <GenericDropDownContainer>
        <GenericDropDownFilter
          label="Department type"
          value={
            this.state.filterValues?.organization_unit?.department_type_uid
              ? this.state.filterValues.organization_unit.department_type_uid.label
              : 'All'
          }
          onClearFilter={() => this.handleClearFilter('department_type_uid')}
        >
          <DepartmentTypeSelect
            onFilterValueChanged={this.handleDepartmentTypeChanged}
            filterValues={this.state.filterValues}
            filterGroupTypeName="organization_unit"
          />
        </GenericDropDownFilter>
        <GenericDropDownFilter
          label="Department"
          onClearFilter={() => this.handleClearFilter('organization_unit_uid')}
          value={
            this.state.filterValues?.organization_unit?.organization_unit_uid
              ? this.state.filterValues.organization_unit.organization_unit_uid.label
              : 'All'
          }
        >
          <CrmOrganizationUnitTreeInner
            handleSelected={this.handleFilterOrganizationUnitChanged}
            filter={{ department_uid: selected_department_uid }}
            selected={this.state.filterValues?.organization_unit?.organization_unit_uid}
          />
        </GenericDropDownFilter>
        <GenericClearFilter
          onApply={this.handleClickApply}
          onCancel={this.handleClickCancel}
          onClearFilters={this.handleClearFilters}
        />
      </GenericDropDownContainer>
    )
  }
}

export default enhanceWithClickOutside(OrganizationUnitDropdown)
