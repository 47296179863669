import React from 'react'
import moment from 'moment'
import uuidv4 from 'uuid'
import { FormattedTime, FormattedDate } from 'react-intl'
import deepmerge from 'deepmerge'
import { Link } from 'react-router-dom'
import { resolvePathStringFromObject } from '../../generic/_generic-analytics-widget'
import { get } from 'lodash'

export const generatedColumns = sourceColumns => {
  const ret = []
  sourceColumns?.forEach(c => {
    if (c.generator && c.generator.type === 'months') {
      let date = moment()
      date.set({ date: 1 })
      date.add(c.generator.startMonth, 'month')
      const count =
        c.generator.order === 'desc'
          ? c.generator.startMonth - c.generator.endMonth
          : c.generator.endMonth - c.generator.startMonth
      for (let i = 0; i < count; i++) {
        const key = date.format('YYYY-MM-DD')
        const endOfMonth = moment(key).endOf('month')
        const gcol = {
          ...c.generator.columnProps,
          accessor: key,
          nestedField: 'fitments_history',
          Header: date.format('YYYY-MM'),
          accessorFn: row => (row && row[c.generator.accessor] ? row[c.generator.accessor][key] : undefined),
          Cell: row => {
            return (
              <div onClick={row.onClick} className={c.generator.columnProps.className}>
                {row.original[c.generator.accessor] ? row.original[c.generator.accessor][key] : ''}
              </div>
            )
          },
          detailsFilter: {
            ...c.generator.columnProps.detailsFilter,
            activity_date: null,
            completed_date: {
              value: {
                $lte: endOfMonth.format('YYYY-MM-DD'),
                $gte: key
              },
              label: endOfMonth.format('YYYY-MM')
            }
          },
          contextMapping: {
            completed_date: 'activity_date'
          }
        }
        date.add(c.generator.order === 'desc' ? -1 : 1, 'month')
        ret.push(gcol)
      }
    }
  })
  return ret
}

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const _generateHeader = c => {
  var Header = c.header ? c.header : c.Header
  if (!Header && c.name) {
    Header = c.name
  }
  if (!Header && c.data?.field) {
    const str_path = c.data.field
    const paths = str_path.split('.')
    const filed_name = [...paths].pop()
    const words = filed_name.split('_')
    const final_filed_name = words.reduce((ret, item) => {
      ret += `${capitalizeFirstLetter(item)} `
      return ret
    }, '')
    Header = final_filed_name
  }

  return Header
}

const _findDataSourceNameFromAccessorInCombineDataSource = (combineDataSources, accessor) => {
  return combineDataSources?.mapping?.find(mappedField =>
    mappedField?.mapping?.find(dataSourceMapping => dataSourceMapping.to === accessor)
  )?.fromDataSourceName
}

const _getDetailPopUpDetailsNameFromDataSourceName = (dataSource: any) => {
  const popupName: string = dataSource?.qlRoot ?? dataSource?.id ?? dataSource?.code
  const ordersChecker = /([O|o]rder|[F|f]itment)/g
  const opportunitiesChecker = /([O|o]pportunit[y|ies])/g
  const accountChecker = /([A|a]tivit[y|ies])/g
  if (popupName?.match(ordersChecker)) {
    return 'order'
  } else if (popupName?.match(opportunitiesChecker)) {
    return 'opportunity'
  } else if (popupName?.match(accountChecker)) {
    return 'activities'
  }
  return 'default'
}

const _getPopUpAndFilterFromDataSources = (data: any = {}, accessor: string, row) => {
  const dataSources = data?.dataSources
  const combineDataSources = data?.combineDataSources
  let object = {
    filter: {},
    popup: 'unknown type'
  }
  let dataSource = dataSources[0]
  if (combineDataSources && dataSources.length > 1) {
    const dataSourceName = _findDataSourceNameFromAccessorInCombineDataSource(combineDataSources, accessor)
    dataSource = dataSources.find(
      ds => ds.name === dataSourceName || ds.code === dataSourceName || ds.alias === dataSourceName
    )
  }
  const rootPopUpName = _getDetailPopUpDetailsNameFromDataSourceName(dataSource)
  object.filter = dataSource?.filter
  object.popup = rootPopUpName
  return object
}

const _generateClickHandler = (c, props: any = {}) => (row, col) => {
  const objectPopUpAndFilter = _getPopUpAndFilterFromDataSources(props.dataSources, col?.accessor, row)
  const params: any = {
    type: c.detailsPopup ?? objectPopUpAndFilter?.popup,
    filters: {
      ...c.detailsFilter,
      ...objectPopUpAndFilter?.filter,
      ...props.analyticsContext
    }
  }

  if (Object(row).hasOwnProperty('due_date_month')) {
    params.filters = {
      ...params.filters,
      due_date: {
        value: {
          $gte: moment(row['due_date_month'])
            .startOf('month')
            .format('YYYY-MM-DD'),
          $lte: moment(row['due_date_month'])
            .endOf('month')
            .format('YYYY-MM-DD')
        }
      }
    }
  }

  if (c.detailsFilterMapping) {
    const mapFiltersRecurent = mapping => {
      const result = {}
      if (mapping) {
        Object.keys(mapping).forEach(key => {
          if (typeof mapping[key] === 'object' && mapping[key] !== null) {
            if (mapping[key].value && mapping[key].label) {
              result[key] = {
                value: get(row, mapping[key].value, null),
                label: get(row, mapping[key].label, '')
              }
            } else {
              result[key] = mapFiltersRecurent(mapping[key])
            }
          } else if (row[mapping[key]]) {
            if (['$gte', '$lte'].includes(key)) {
              result[key] = row[mapping[key]]
            } else if (mapping[key] === 'account_lead_in_date') {
              result[key] = row[mapping[key]]
            } else {
              result[key] = { value: row[mapping[key]] }

              if (mapping[key] === 'user_uid' && row['full_name']) {
                result[key].label = row['full_name']
              }
            }
          }
        })
      }
      return result
    }

    const mappedFilters = mapFiltersRecurent(c.detailsFilterMapping)
    params.filters = deepmerge(params.filters || {}, mappedFilters)
  }
  const ignoreField = col.fieldIgnoreContextMapping ?? []
  if (c.contextMapping && props.analyticsContext) {
    Object.keys(c.contextMapping).forEach(k => {
      if (props.analyticsContext) {
        if (!ignoreField.includes(c.contextMapping?.[k])) {
          params.filters[k] = {
            ...params.filters?.[k],
            ...props.analyticsContext?.[c.contextMapping?.[k]]
          }
        }
      }
    })
  }
  const disablePopUpRules = ['contract_start_date']
  if (!disablePopUpRules.includes(col?.accessor) && props.onShowLeadsPopup) {
    props.onShowLeadsPopup(params)
  }
}

const _generateCellRenderer = col => {
  if (col.Cell) {
    return col.Cell
  }
  return row => {
    const value = getData(row, col)
    return renderValue(row, value, col)
  }
}

const _generateColumnId = col => {
  if (col.id) {
    return col.id
  }
  if (col.accessor) {
    return col.accessor
  }
  if (col.data && col.data.field) {
    return col.data.field
  }
  return uuidv4()
}

export const generateTableColumns = (columns, props = {}) => {
  var resultColumns = []
  let sourceColumns = [...(columns?.filter(c => !c.generator) ?? []), ...generatedColumns(columns)]
  sourceColumns?.forEach(c => {
    if (!c.accessor && c.data && c.data.field) {
      c.accessor = c.data.field
    }
    const Header = _generateHeader(c)
    const onClick = _generateClickHandler(c, props)
    const Cell = _generateCellRenderer(c)
    const id = _generateColumnId(c)

    var ret = {
      id,
      ...c,
      Header,
      onClick,
      Cell,
      className: c.className ? c.className : ' '
    }

    if (c.width) {
      ret.width = c.width
    }
    if (c.type === 'number') {
      ret.className += ' util-textRight  '
    }

    resultColumns.push(ret)
  })
  return resultColumns
}

export const getData = (row, col) => {
  if (col.accessorFn) {
    return col.accessorFn(row.original)
  }
  if (col.data && col.data.field && col.data.field.indexOf('.') > 0) {
    var value = resolvePathStringFromObject(row?.original, col.data.field, undefined)
    return value
  }
  if (col.type === 'objectMapping') {
    return resolvePathStringFromObject(row?.original, col.key, undefined)
  }
  if (row.original) {
    if (col.data && col.data.field) {
      return row.original[col.data.field]
    }
    if (col.accessor) {
      return row.original[col.accessor]
    }
  }
}

const isAccountColumn = col => {
  if (col.accessor == 'account.name') {
    return true
  }
  if (col.data && col.data.field == 'account.name') {
    return true
  }
  return false
}
export const renderValue = (row, value, col) => {
  if (!value) {
    return <span />
  }

  if (isAccountColumn(col) || col.type === 'account') {
    return <Link to={`/crm/account/` + row.original.account_uid}>{value != null ? value : <span>Account</span>}</Link>
  }

  if (col.type === 'date') {
    return (
      <div style={{ textAlign: 'right', flex: 1 }}>
        <FormattedDate value={value} />
      </div>
    )
  }
  if (col.type === 'datetime') {
    return (
      <div style={{ textAlign: 'right', flex: 1 }}>
        <FormattedDate value={value} /> <FormattedTime value={value} />
      </div>
    )
  }
  if (col.type === 'time') {
    return (
      <div style={{ textAlign: 'right', flex: 1 }}>
        <FormattedTime value={value} />
      </div>
    )
  } else if (col.type === 'number') {
    return (
      <div style={{ textAlign: 'right', flex: 1 }}>
        {Number(value).toLocaleString(undefined, {
          maximumFractionDigits: col.maximumFractionDigits || 0,
          useGrouping: true
        })}
      </div>
    )
  }

  if (col?.align) {
    return <div style={{ textAlign: col?.align }}>{value}</div>
  }

  return value != null ? value : <span />
}
