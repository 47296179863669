/* eslint-disable camelcase */
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { shape, string, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { withApollo } from 'react-apollo'
import TasksList from './inner-task-list.jsx'
import NewTaskForm from '../../../tasks/components/new-task-form.jsx'
import { CrmButton } from 'crm-components'
import { getUser } from 'crm-duxs/crm-reducer'
import { qlqAccountTasks } from 'crm-data/tasks'
import tasksListQLHOC from './tasks-list-ql-hoc'

class SectionTasksHoc extends PureComponent {
  constructor(props) {
    super(props)
    const newTask = {
      task: {
        account_uid: props.accountUid,
        assign_to_user_uid: props.user.user_uid
      }
    }

    this.state = {
      isAddingTask: false,
      task: newTask
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.task && !nextProps.task) {
      this.handleCancelAddingTask()
    }

    if (this.props.processStepCode !== nextProps.processStepCode) {
      nextProps.taskql?.refetch()
    }
  }

  handleTaskCreated = () => {
    // console.log('handleTaskCreated')
    this.props.onCancelAddingTask()
  }

  handleRefresh = () => {
    // console.log('refresh Tasks')
    this.props.taskql.refetch({})
  }

  handleTaskClicked = task => {
    // console.log('handleTaskClicked', task)
  }

  render() {
    const { accountUid, processStepCode, accountKind } = this.props
    const { isAddingTask, task } = this.state
    return (
      <TasksList
        isAddingTask={this.props.isAddingTask}
        task={task}
        tasks={this.props.tasks}
        accountUid={this.props.accountUid}
        onAddTask={this.handleOnAddTask}
        onTaskCreated={this.handleTaskCreated}
        onCancelCreated={this.props.onCancelAddingTask}
        onTaskItemClicked={this.handleTaskClicked}
        processStepCode={processStepCode}
        accountKind={accountKind}
      />
    )
  }
}

SectionTasksHoc.defaultProps = {
  sectionName: 'Tasks',
  task: undefined
}

SectionTasksHoc.propTypes = {
  accountUid: string.isRequired,
  task: shape({}),
  user: shape({}).isRequired,
  client: shape({}).isRequired,
  isAddingTask: bool.isRequired
}

function mapStateToProps(state) {
  return {
    user: getUser(state)
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(tasksListQLHOC(withApollo(SectionTasksHoc)))
