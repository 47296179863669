/* eslint-disable */
import React from 'react'
import { shape, func, string } from 'prop-types'
import { COLORS } from './widget-utils'

const SingleNumberWidget = (props) => {
  console.log('SingleNumberWidget render')
  console.dir(props)

  const field =
    props.widgetDefinition &&
    props.widgetDefinition.layoutParams &&
    props.widgetDefinition.layoutParams.dataField
      ? props.widgetDefinition.layoutParams.dataField
      : 'count'
  let value =
    props.dataSourceData &&
    props.dataSourceData.data &&
    props.dataSourceData.data.rawData &&
    props.dataSourceData.data.rawData.rows &&
    props.dataSourceData.data.rawData.rows.length > 0
      ? props.dataSourceData.data.rawData.rows[0][field]
      : 0
  if (props.value) {
    value = props.value
  }
  console.log('value')
  console.dir(value)
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.value)
    }
  }
  return (
    // <div
    //   className={`Widget CrmWidget util-marginBottom ${props.className
    //     ? props.className
    //     : ''}`}
    //   style={props.style}
    //   onClick={handleClick}
    // >
    //   <div className="Widget-header row util-noMargin between-xs top-xs">
    //     <div className="Widget-title CrmWidget-title">
    //       <div>{props.widgetDefinition.title}</div>
    //       {props.widgetDefinition.description && (
    //         <div className="Widget-description">
    //           {props.widgetDefinition.description}
    //         </div>
    //       )}
    //     </div>

    //     <div className="CrmWidget-tools">
    //       {props.onRefresh && (
    //         <CrmButton
    //           style={{ float: 'right' }}
    //           icon="refresh"
    //           iconButton
    //           onClick={props.onRefresh}
    //         />
    //       )}
    //       {props.onShowWidgetConfig && (
    //         <CrmButton
    //           style={{ float: 'right' }}
    //           icon="refresh"
    //           iconButton
    //           onClick={props.onShowWidgetConfig}
    //         />
    //       )}
    //     </div>
    //   </div>
    //   {/* <div className="Widget-description">
    //       {props.widgetDefinition.description}
    //     </div> */}
    //   <div className="Widget-content CrmWidget-content CrmWidget-content--padding">
    <div
      style={{
        color: COLORS[3],
        fontSize: 32,
        textAlign: 'center'
      }}
    >
      {value}
    </div>
    //   </div>
    // </div>
  )
}

SingleNumberWidget.propTypes = {
  tableData: shape({}).isRequired,
  widgetDefinition: shape({}).isRequired,
  style: shape({}).isRequired,
  onRefresh: func,
  onClick: func,
  className: string.isRequired,
  value: string,
  description: string,
  onShowWidgetConfig: func
}
SingleNumberWidget.defaultProps = {
  value: undefined,
  onRefresh: undefined,
  onClick: undefined,
  description: undefined,
  onShowWidgetConfig: undefined
}

export default SingleNumberWidget
