import React, { useState, useEffect } from 'react'
import {
  CrmJsonPreview,
  CrmModal,
  CrmLoadingIcon,
  CrmTabNavigator,
  CrmButton,
  CrmTextArea
} from '@cartrack-crm/ui'
import { DataSourceQLEditor } from './datasource-ql-editor'
import { TestResultTable } from './modal-test-result-inner'
type ModalTestDefinitionProps = {
  isOpen?: boolean
  onClose?: Function
  filter?: any
  dataSource: any
  runQuery?: () => any
  onSave?: Function
}
export const ModalTestDefinition: React.SFC<ModalTestDefinitionProps> = (
  props
) => {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [variables, setVariables] = useState<any>()
  // We can edit qlString inside this component
  // const [qlString, setQlString] = useState<string>(
  //   props.dataSource ? props.dataSource.qlQuery : undefined
  // )

  const [dataSource, setDataSource] = useState(props.dataSource)
  const [queryTestParams, setQueryTestParams]: [any, Function] = useState()

  useEffect(() => {
    console.log('Data source changed outside')
    setDataSource(props.dataSource) // good ??
    // setQlString(dataSource ? dataSource.qlQuery : undefined)
  }, ['dataSource'])

  if (!dataSource) {
    return <div> No data source </div>
  }

  const handleChanged = (dataSource) => {
    console.log('handleChanged', dataSource)
    setDataSource(dataSource)
  }
  const renderJsonPreview = data ?? {}
  const TABS = [
    {
      label: 'Definition',
      childComponent: (
        <div style={{ height: '100%' }}>
          <DataSourceQLEditor
            dataSource={dataSource}
            onChange={handleChanged}
          />
        </div>
      )
    },
    {
      label: 'QL Query (from test)',
      childComponent: (
        <React.Fragment>
          {queryTestParams ? (
            <div>
              <CrmTextArea
                input={{
                  value: queryTestParams ? queryTestParams.qlQueryString : ''
                  // onChange: (event) => setQlString(event.currentTarget.value)
                }}
              />
              <h5> Generated variables </h5>
              <CrmJsonPreview json={queryTestParams.variables} />
            </div>
          ) : (
            <div> Run Test first </div>
          )}
        </React.Fragment>
      )
    },
    {
      label: 'Result',
      childComponent: (
        <ModalResultTab
          loading={loading}
          jsonPreview={renderJsonPreview}
          qlRoot={dataSource?.qlRoot}
        />
      )
    }
  ]

  const handleSave = () => {
    console.log('handleSave', dataSource)
    if (props.onSave) {
      props.onSave(dataSource)
    }
  }
  const handleRunQuery = async () => {
    setLoading(true)
    const { data, gqlQueryParams, variables } = await props.runQuery()
    console.log('data', data)
    console.log('Test QL Query params ', gqlQueryParams)
    setLoading(false)
    setQueryTestParams(gqlQueryParams)
    // setQlString(gqlQueryParams.qlQueryString)
    setData(data)
  }
  return (
    <CrmModal
      isOpen={props.isOpen}
      isShowCloseBtn
      title="Data Source Definition"
      onClose={props.onClose}
      size={'lg'}
    >
      <div
        className="util-flexColumn util-fullHeight"
        style={{ height: '100%' }}
      >
        <div
          className="util-flexGrow util-flexColumn "
          style={{ minHeight: 0 }}
        >
          <CrmTabNavigator tabs={TABS} selectedIndex={0} />
        </div>
        <div>
          <div
            className="row"
            style={{ padding: 10, backgroundColor: '#f0f0f0', width: '100%' }}
          >
            <div className="col-md-4">
              <CrmButton
                label="Run Query"
                type="primary"
                onClick={handleRunQuery}
                isSaving={loading}
              />
            </div>

            <div className="col-md-4">
              <CrmButton
                label="Save"
                type="primary"
                onClick={handleSave}
                isSaving={loading}
              />
            </div>
            <div className="col-md-4">
              <CrmButton
                label="Cancel"
                type="primary"
                onClick={handleSave}
                isSaving={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </CrmModal>
  )
}

interface IModalResultTab extends IModalVariableTab {
  loading?: boolean
  qlRoot: string
}
const ModalResultTab: React.SFC<IModalResultTab> = ({
  loading,
  jsonPreview,
  qlRoot
}) => {
  const [viewMode, setViewMode] = useState('TABLE')

  return (
    <React.Fragment>
      {loading && <CrmLoadingIcon />}
      {
        <div>
          <CrmButton label="Table" onClick={() => setViewMode('TABLE')} />
          <CrmButton label="JSON" onClick={() => setViewMode('JSON')} />
        </div>
      }
      {!loading && viewMode == 'TABLE' && (
        <TestResultTable results={jsonPreview} qlRoot={qlRoot} />
      )}
      {!loading && viewMode == 'JSON' && <CrmJsonPreview json={jsonPreview} />}
    </React.Fragment>
  )
}
interface IModalVariableTab {
  jsonPreview: any
}
// const ModalVariableTab: React.SFC<IModalVariableTab> = ({ jsonPreview }) => (
//   <CrmJsonPreview json={jsonPreview} />
// )
