import React from 'react'
import { func, string, shape, bool, arrayOf } from 'prop-types'
import gql from 'graphql-tag'
import { CrmJsonEditArea, FlatTable } from 'crm-components'
import { Query } from 'react-apollo'
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl'

const qlqListDomainEvents = gql`
  query listDomainEvents($aggregates: [JSON]) {
    listDomainEvents(aggregates: $aggregates) {
      aggregate_type
      type
      created_time
      payload
      meta
      event_uid
    }
  }
`
const availableColumns = [
  {
    Header: (
      <FormattedMessage
        id="crm.history.aggregate_type"
        defaultMessage="Aggregate type"
      />
    ),
    accessor: 'aggregate_type',
    id: 'aggregate_type'
  },
  {
    Header: <FormattedMessage id="crm.history.type" defaultMessage="Type" />,
    accessor: 'type',
    id: 'type'
  },
  {
    Header: (
      <FormattedMessage
        id="crm.history.created_time"
        defaultMessage="Created time"
      />
    ),
    accessor: 'created_time',
    id: 'created_time',
    Cell: row =>
      row.original.created_time ? (
        <div>
          <FormattedDate value={row.original.created_time} />{' '}
          <FormattedTime value={row.original.created_time} />
        </div>
      ) : (
        <span />
      )
  },
  {
    Header: (
      <FormattedMessage id="crm.history.create_by" defaultMessage="Create By" />
    ),
    accessor: 'meta',
    id: 'create_by',
    Cell: row => (
      <div style={{ textAlign: 'left' }}>
        {row.original.meta &&
          row.original.meta.user &&
          row.original.meta.user.user_name}
      </div>
    )
  }
]

class EventsHistoryList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { selectedRow: null }
  }

  handleRowClick = row => {
    this.setState({
      selectedRow: row
    })
  }

  render() {
    const { selectedRow } = this.state
    const variables = {
      aggregates: this.props.aggregates
    }
    return (
      <Query
        query={qlqListDomainEvents}
        variables={variables}
        fetchPolicy="network-only"
        this={this}
      >
        {({ loading, data, refetch }) => {
          if (loading) return null
          const events = data.listDomainEvents
            ? data.listDomainEvents.map(v => {
                return { id: v.event_uid, ...v }
              })
            : []
          return (
            <div className="util-flexColumn util-fullHeight">
              <div
                className="util-marginMd util-flexGrow"
                style={{ overflow: 'auto' }}
              >
                <FlatTable
                  data={events}
                  columns={availableColumns}
                  onRowClick={this.handleRowClick}
                />
              </div>
              {selectedRow && (
                <div className="util-marginMd util-flexGrow">
                  <CrmJsonEditArea
                    input={{
                      value: selectedRow.payload,
                      onChange: undefined
                    }}
                  />
                </div>
              )}
            </div>
          )
        }}
      </Query>
    )
  }
}

EventsHistoryList.propTypes = {
  aggregates: arrayOf(shape({})).isRequired
}
EventsHistoryList.defaultProps = {}

export default EventsHistoryList
