import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { func, string, bool, shape, arrayOf } from 'prop-types'
import AccountsViewsBar from '../../accounts/list/components/accounts-views-bar.jsx'
import DefaultFilterContainer from '../../accounts/list/components/filter/default-filter-container.jsx'
import ContractsListInner from './contracts-list-inner.jsx'
import { getOwnerUserUid } from 'crm-duxs/opportunities-reducer'
import {
  fetchAccountListCounts,
  getViews
} from 'crm-duxs/accounts-list-reducer'

import { CrmFullpageContainer } from 'crm-components'
import {
  getQFilterGroup,
  setFiltersGroupView,
  getSelectedView,
  initFiltersGroup
} from 'crm-duxs/filters-reducer'
import { withGeneralPermissionsContextHOC } from '@cartrack-crm/crm-core'
import { getIsShowMoreFilter } from 'crm-duxs/accounts-reducer'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class ContractsListView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleOpportunityNameChange = event => {
    const value = event.target.value
    console.log('handleOpportunityNameChange', value)
    this.setState({ accountName: value })
  }

  componentWillMount = () => {
    this.props.doFetchAccountsListCount()
    console.log('opportunities list view will mount ', this.props)
    if (!this.props.selectedView && this.props.views) {
      this.props.doSetFilterGroupView(this.props.views[1])
    }
    this.props.doInitFiltersGroup('opportunities')
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.generalPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.GENERAL_DATA_MANAGE
      ) !==
      nextProps.generalPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.GENERAL_DATA_MANAGE
      )
    ) {
      // Reinit filters
      this.props.doInitFiltersGroup(this.props.accountsListCode)
    }
  }

  getTrProps(state, rowInfo) {
    return {
      onClick: () => {
        const id = rowInfo.original.datatype_uid // eslint-disable-line
      },
      style: {
        cursor: 'pointer'
      }
    }
  }
  handleListRef = ref => {
    this.listRef = ref
  }

  handleFetchData = state => {
    console.log('Opportunities list view, handleFetchData', state)
    this.setState({ listState: state })
  }
  handleRefresh = () => {
    console.log('handleRefresh', this.listRef)
    if (this.listRef) {
      this.listRef.getWrappedInstance().refetch()
    }
  }

  render() {
    console.log('ContractsListView', this.props, this.state)
    return (
      <CrmFullpageContainer>
        <div className="util-fullHeight util-flexGrow util-flexColumn">
          <DefaultFilterContainer
            accountsListCode={this.props.accountsListCode}
            isShowFilter
            isManager={this.props.generalPermissionsContext.hasPermissionByType(
              PERMISSIONS_TYPES.GENERAL_DATA_MANAGE
            )}
          />
          <div className="AccountsListContainer-tableWrapper">
            <ContractsListInner
              ref={this.handleListRef}
              onFetchData={this.handleFetchData}
              listState={this.state.listState}
              filter={this.props.qfilter}
            />
          </div>
        </div>
      </CrmFullpageContainer>
    )
  }
}

ContractsListView.defaultProps = {
  accountsListCode: 'contracts',
  ownerUserUid: undefined
}

ContractsListView.propTypes = {
  generalPermissionsContext: shape({}).isRequired,
  accountsListCode: string.isRequired,
  doFetchAccountsListCount: func.isRequired,
  qfilter: shape().isRequired,
  selectedView: shape({}).isRequired,
  doSetFilterGroupView: func.isRequired,
  views: arrayOf(shape()).isRequired,
  doInitFiltersGroup: func.isRequired
}

function mapStateToProps(state) {
  return {
    isShowMoreFilter: getIsShowMoreFilter(state, 'contracts'),
    ownerUserUid: getOwnerUserUid(state),
    qfilter: getQFilterGroup(state, 'contracts'),
    selectedView: getSelectedView(state, 'contracts'),
    views: getViews(state, 'contracts')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doFetchAccountsListCount: () =>
      dispatch(fetchAccountListCounts('contracts')),
    doSetFilterGroupView: view =>
      dispatch(setFiltersGroupView('contracts', view)),
    doInitFiltersGroup: groupCode => dispatch(initFiltersGroup(groupCode))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGeneralPermissionsContextHOC(ContractsListView))
