import React, { Component } from 'react'
import { func, shape } from 'prop-types'
import { CrmButton } from 'crm-components'

class ImportResult extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="row" style={{ margin: '20px' }}>
        <div className="col-md-3">
          <CrmButton
            label="Back to import form"
            type="primary"
            onClick={this.props.onBack}
            style={{ width: 200 }}
          />
        </div>
        <div className="col-md-3">
          <h4>
            Total :{' '}
            {parseInt(this.props.result.duplicate, 0) +
              parseInt(this.props.result.imported, 0)}{' '}
            rows
          </h4>
        </div>
        <div className="col-md-3">
          <h4>Duplicate : {this.props.result.duplicate} rows</h4>
        </div>
        <div className="col-md-3">
          <h4>Imported : {this.props.result.imported} rows</h4>
        </div>
      </div>
    )
  }
}

ImportResult.propTypes = {
  onBack: func.isRequired,
  result: shape({}).isRequired
}

export default ImportResult
