import React, { Component } from 'react'
import { func, bool, string, shape, any } from 'prop-types'
import { every, mapValues } from 'lodash'
import Checkbox from 'util-components/checkbox.jsx'
import Icon from 'util-components/icon.jsx'

const ItemCheckbox = ({ checked, name, child, onChange }) => (
  <div
    className={`ToggleDropdown-item ${
      child ? 'ToggleDropdown-item--child' : ''
    }`}
  >
    <Checkbox
      label={name}
      name={name}
      value={checked}
      small={child}
      onChange={onChange}
    />
  </div>
)

ItemCheckbox.propTypes = {
  checked: bool.isRequired,
  name: string.isRequired,
  child: bool.isRequired,
  onChange: func.isRequired
}

class ToggleDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      allValues: 'indeterminate'
    }

    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.handleToggleOpen = this.handleToggleOpen.bind(this)
    this.handleAllClick = this.handleAllClick.bind(this)
  }

  componentWillReceiveProps({ values }) {
    if (values) {
      this.setState({
        allValues: every(values, l => l === values[Object.keys(values)[0]])
          ? values[Object.keys(values)[0]]
          : 'indeterminate'
      })
    }
  }

  setAllValues(value) {
    return mapValues(this.props.options, () => value)
  }

  handleCheckboxClick(value, label) {
    const update = {
      [label]: value
    }
    this.props.onChange(update)
  }

  handleAllClick(e, l, value) {
    console.log(e, l, value)
    this.props.onChange(this.setAllValues(value))
  }

  handleToggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const {
      options,
      values,
      className,
      innerClassName,
      showToggle,
      toggleAll,
      label
    } = this.props
    return (
      <div
        className={`ToggleDropdown ${className} ${
          this.state.isOpen ? 'is-open' : ''
        }`}
      >
        {' '}
        <div
          className={`ToggleDropdown-inner ${innerClassName} ${
            this.state.isOpen ? 'is-open' : ''
          }`}
        >
          {this.props.component && !this.state.isOpen ? (
            <this.props.component onClick={this.handleToggleOpen} />
          ) : (
            <div
              className="ToggleDropdown-header"
              onClick={this.handleToggleOpen}
            >
              {label}
              {showToggle ? (
                <Icon
                  name="caret-down"
                  className={`
              ToggleDropdown-header-toggle 
              ${this.state.isOpen ? 'is-open' : ''}`}
                />
              ) : null}
            </div>
          )}
          {this.state.isOpen ? (
            <div>
              {toggleAll ? (
                <div className="ToggleDropdown-item">
                  <Checkbox
                    label="all"
                    name="all"
                    value={this.state.allValues}
                    indeterminate={this.state.allValues === 'indeterminate'}
                    onChange={this.handleAllClick}
                  />
                </div>
              ) : null}
              {Object.keys(this.props.options).map(label => {
                const item = options[label]
                if (label === 'Selected') return null
                return (
                  <div key={label}>
                    <ItemCheckbox
                      key={label}
                      name={label}
                      checked={values[label]}
                      onChange={this.handleCheckboxClick}
                      child={false}
                    />
                    {values[label] && item.children
                      ? item.children.map(child => (
                          <ItemCheckbox
                            key={child}
                            name={child}
                            checked={values[child]}
                            onChange={this.handleCheckboxClick}
                            child
                          />
                        ))
                      : null}
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

ToggleDropdown.propTypes = {
  options: shape({}).isRequired,
  values: shape({}).isRequired,
  className: string,
  innerClassName: string,
  onChange: func.isRequired,
  toggleAll: bool,
  showToggle: bool,
  label: any.isRequired, // eslint-disable-line react/forbid-prop-types
  component: func
}

ToggleDropdown.defaultProps = {
  className: '',
  innerClassName: '',
  toggleAll: false,
  showToggle: true,
  component: null
}

export default ToggleDropdown
