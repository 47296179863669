/* eslint-disable no-restricted-globals */
import React from 'react'
import { connect } from 'react-redux'
import {
  arrayOf,
  bool,
  shape,
  string,
  number,
  func,
  any,
  oneOfType
} from 'prop-types'
import { graphql, compose, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { CrmTable, CrmButton } from 'crm-components'
import { LoadingIcon } from 'util-components'
import { exportDataToExcel } from '@cartrack-crm/crm-analytics'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withCrmContext } from '@cartrack-crm/crm-core/src/contexts/crm-context'
import {
  withGeneralPermissionsContextHOC,
  withBulkOperationsContext,
  PERMISSIONS_TYPES
} from '@cartrack-crm/crm-core'
import { withCrmToastrHoc } from '@cartrack-crm/ui/src/toastrs/withCrmToastHoc'

const EXCEL_EXPORT = gql`
  query crm_export_excel_table($instance_uid: String!, $master_uid: String!, $query_root: String, $definition: String, $filter: JSON) {
    master(master_uid: $master_uid) {
      master_uid
      name    
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        crm_export_excel_table(query_root: $query_root,definition: $definition, filter: $filter) 
        {
          status
          output
          details
        }
      }
    }
  }
`

const genericTableFactory = ({ query, qlOptions }) => {
  class TableInner extends React.PureComponent {
    static propTypes = {
      tableData: any,
      tableDataQl: shape({}),
      count: number,
      onDataLoaded: func,
      getTrProps: func,
      columns: arrayOf(shape()),
      listState: shape({}),
      onRowClick: func,
      tableName: string,
      translation: oneOfType([string, bool]),
      getTdProps: func,
      onFetchData: func,
      defaultSorted: oneOfType([arrayOf(any), shape()]),
      onSortedChange: func,
      bulkOperationsContext: shape()
    }
    static defaultProps = {
      tableData: undefined,
      onDataLoaded: undefined,
      getTrProps: undefined,
      columns: undefined,
      onRowClick: undefined,
      tableName: undefined,
      translation: undefined,
      getTdProps: undefined,
      onFetchData: undefined,
      defaultSorted: undefined,
      listState: undefined,
      count: undefined,
      tableDataQl: undefined,
      onSortedChange: undefined
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps !== this.props) {
        if (this.props.onDataLoaded) {
          this.props.onDataLoaded({
            data: nextProps.tableData,
            count: nextProps.count
          })
        }
      }
    }

    render() {
      let data = []

      const { tableData } = this.props
      const isLoading = this.props.tableDataQl
        ? this.props.tableDataQl.loading
        : true

      let totalPages =
        this.props.count && this.props.listState
          ? Math.ceil(this.props.count / this.props.listState.pageSize)
          : 1
      // Passed tableData may be either an object or an array
      if (tableData) {
        if (Array.isArray(tableData)) {
          data = tableData
        } else {
          // Pased as object { data: [] , count: 123 }
          data = tableData.data
          totalPages =
            tableData.count && this.props.listState
              ? Math.ceil(tableData.count / this.props.listState.pageSize)
              : 1
        }
      }
      const getTrProps = (state, rowInfo) => {
        let ret = {}
        if (this.props.getTrProps) {
          const outer = this.props.getTrProps(state, rowInfo)
          ret = { ...ret, ...outer }
        }

        if (this.props.onRowClick) {
          ret.onClick = () => this.props.onRowClick(rowInfo)
        }
        return ret
      }

      const getTdProps = (state, rowInfo, column, instance) => {
        let ret = {}
        if (this.props.getTdProps) {
          const outer = this.props.getTdProps(state, rowInfo, column, instance)
          ret = { ...ret, ...outer }
        }
        return ret
      }
      return (
        <div style={{ height: '100%', position: 'relative' }}>
          {isLoading && (
            <div className="CrmWidget--loading">
              <LoadingIcon />
              <div>Loading...</div>
            </div>
          )}
          {data && (
            <CrmTable
              style={{ height: '100%' }}
              columns={this.props.columns}
              tableName={this.props.tableName}
              translation={this.props.translation}
              className="react-table -striped -highlight"
              data={data}
              getTrProps={getTrProps}
              getTdProps={getTdProps}
              manual
              pages={totalPages}
              onFetchData={this.props.onFetchData}
              defaultSorted={this.props.defaultSorted}
              onSortedChange={this.props.onSortedChange}
            />
          )}
        </div>
      )
    }
  }

  const TableInnerQL = compose(
    graphql(query, {
      ...qlOptions,
      name: 'tableDataQl',
      withRef: true,
      forceFetch: true,
      skip: ownProps => !ownProps.listState,
      options: ownProps => {
        // Build variables
        let variables = {}
        // Get variables from external
        if (qlOptions && qlOptions.options) {
          const externalOptions = qlOptions.options(ownProps)
          variables = { ...externalOptions.variables, limit: 20 }
        }
        // Generate variables based on list state
        if (ownProps && ownProps.listState) {
          if (
            ownProps.listState.sorted &&
            ownProps.listState.sorted.length > 0
          ) {
            variables.sort = ownProps.listState.sorted
          }
          variables.offset = ownProps.listState.page
            ? ownProps.listState.page * ownProps.listState.pageSize
            : 0
          if (ownProps.listState.pageSize) {
            variables.limit = ownProps.listState.pageSize
          }
        } else {
        }
        return {
          variables,
          fetchPolicy: 'network-only' // That's the key to prevent using cache
        }
      },
      fetchPolicy: 'network-only'
    })
  )(TableInner)

  class TableOuter extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {}
      this.exportDataPermission = 'crm.export_data'
    }
    handleRefresh = () => {
      this.innerRef.getWrappedInstance().props.tableDataQl.refetch()
    }
    doRefetch = () => {
      this.handleRefresh()
    }

    handleBulkOperation = () => {
      const accounts = this.state.data ? this.state.data.data : []
      const params = {
        elementType: this.props.bulkOperationElementType
      }
      // eslint-disable-next-line react/prop-types
      this.props.bulkOperationsContext.onShowBulkOperations(accounts, params)
    }
    handleSetRef = ref => {
      this.innerRef = ref
    }
    handleFetchData = state => {
      this.setState({ listState: state })
    }

    componentWillMount() {
      if (this.props.getRefreshFunc) this.props.getRefreshFunc(this.doRefetch)
    }

    handleDataLoaded = ({ data }) => {
      this.setState({ data })
      if (typeof this.props.onDataLoaded === 'function') {
        this.props.onDataLoaded(data)
      }
    }

    getExportFileName = () => {
      let exportFileName = 'Crm-export'
      if (typeof this.props.exportFileName === 'string') {
        exportFileName = this.props.exportFileName
      } else if (typeof this.props.exportFileName === 'function') {
        exportFileName = this.props.exportFileName()
      }

      return exportFileName
    }

    downloadPopup = (sUrl, fileName) => {
      console.log('downloadPopup', sUrl, fileName)
      const link = document.createElement('a')
      link.href = sUrl
      link.setAttribute('target', '_blank')
      if (link.download !== undefined) {
        link.download = fileName
      }
      if (document.createEvent) {
        const e = document.createEvent('MouseEvents')
        e.initEvent('click', true, true)
        link.dispatchEvent(e)
        return true
      }
    }
  
    handleExportExcel = async () => {
      const options = qlOptions.options
        ? qlOptions.options(this.props)
        : { variables: {} }
      const reportNeedles = ['accounts_list_qm_paged', 'account_list_page']
      if (!reportNeedles.includes(query?.definitions?.[0]?.name?.value)) {
        const data = await this.props.client.query({
          query,
          variables: {
            ...options.variables,
            limit: 5000
          },
          context: {
            headers: {
              'x-crm-log-query': true
            }
          },
          fetchPolicy: 'no-cache'
        })
        const parsed = qlOptions.props({ tableDataQl: data.data })
        if (parsed && parsed.tableData) {
          // eslint-disable-next-line
          const data = parsed.tableData.hasOwnProperty('data')
            ? parsed.tableData.data
            : parsed.tableData
          const fileName = this.getExportFileName()
          exportDataToExcel({
            data,
            fileName
          })
        }
      } else {
        const { loading, data } = await this.props.client.query({
          query: EXCEL_EXPORT,
          variables: {
            ...options.variables,
            definition: query?.loc?.source?.body,
            query_root: query?.definitions?.[0]?.name?.value,
            instance_uid: this.props.crmContext?.instance?.instance_uid,
            master_uid: this.props.crmContext?.master?.master_uid
          }
        })
        if (!loading) {
          const status = data?.master?.instance?.crm_export_excel_table?.status
          const content = data?.master?.instance?.crm_export_excel_table?.output
          const extension = data?.master?.instance?.crm_export_excel_table?.details
          const fileName = this.getExportFileName()
          if (status === 'Ok') {
            this.downloadPopup(content, `${fileName}.${extension}`)
          } else {
            this.props.showToastr('error', 'Export Error Please contact support.')
          }
        }
      }
    }

    render() {
      return (
        <div
          className="util-flexRow util-minHeight"
          style={{ height: '100%', ...this.props.style }}
        >
          <div className="util-flexGrow util-flexColumn util-fullHeight">
            <TableInnerQL
              {...this.props}
              ref={this.handleSetRef}
              onFetchData={this.handleFetchData}
              listState={this.state.listState}
              columns={this.props.columns}
              onDataLoaded={this.handleDataLoaded}
            />
          </div>
          <div className="util-flexColumn">
            <CrmButton
              noBorder
              onClick={this.handleRefresh}
              icon="refresh"
              iconButton
            />
            {this.props.generalPermissionsContext.hasPermissionByType(
              PERMISSIONS_TYPES.EXPORT_DATA
            ) && (
              <CrmButton
                noBorder
                onClick={this.handleExportExcel}
                icon="file-excel-o"
                iconButton
              />
            )}
            {this.props.generalPermissionsContext.hasPermissionByType(
              PERMISSIONS_TYPES.BULK_OPERATIONS
            ) && (
              <CrmButton
                noBorder
                onClick={this.handleBulkOperation}
                icon="list"
                title="Bulk Operations"
                iconButton
              />
            )}
            <div
              title={
                this.state.data
                  ? `Total records = ${this.state.data.count}`
                  : ''
              }
              className="util-pointer"
              style={{
                textAlign: 'center'
              }}
            >
              <FontAwesomeIcon icon="info" />
            </div>
          </div>
        </div>
      )
    }
  }

  function mapStateToProps(state) {
    return {}
  }

  TableOuter.propTypes = {
    columns: arrayOf(shape()).isRequired,
    style: shape(),
    client: shape().isRequired,
    exportFileName: oneOfType([string, func]),
    bulkOperationElementType: string,
    onShowBulkOperations: func,
    onDataLoaded: func,
    getRefreshFunc: func,
    defaultSorted: oneOfType([arrayOf(any), shape()]),
    generalPermissionsContext: shape({}).isRequired,
    crmContext: shape()
  }
  TableOuter.defaultProps = {
    style: undefined,
    exportFileName: undefined,
    bulkOperationElementType: undefined,
    onDataLoaded: undefined,
    getRefreshFunc: undefined,
    defaultSorted: undefined
  }

  const TableOuterWithRedux = connect(mapStateToProps)(
    withBulkOperationsContext(withGeneralPermissionsContextHOC(TableOuter))
  )
  return withCrmContext(withCrmToastrHoc(withApollo(TableOuterWithRedux, { withRef: true })))
}

export default genericTableFactory
