/* eslint-disable camelcase */
import { languages } from 'crm-utils/crm-utils'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { string, shape, bool } from 'prop-types'
import moment from 'moment'
import gql from 'graphql-tag'
import { compose } from 'react-apollo'
import { FormattedMessage } from 'react-intl'

import PrintMinutesButtonQL from './print-minutes-button.jsx'
import { CrmDropdown } from 'crm-components'

import { getFormViewValues } from 'crm-duxs/meeting-minutes-reducer'
import { crmQLActionButtonFactory } from 'crm-components/crm-ql-action-button.jsx'

// QL Mutations
const qlMutationSendMeetingMinutesEmail = gql`
  mutation send_meeting_minutes_email($activity_uid: String!) {
    send_meeting_minutes_email(activity_uid: $activity_uid) {
      result
      error
    }
  }
`

const ActionButtonSendMeetingMinutes = crmQLActionButtonFactory({
  mutation: qlMutationSendMeetingMinutesEmail
})

class SurveyBlockPrinting extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      reportType: undefined,
      lang: 'en',
      langOption: languages
    }
  }

  handleLangOnChange = value => {
    this.setState({ lang: value })
  }

  getPdfFileName = () => {
    const accountName = 'Meeting' // T O D O this.props.viewValues.account[0].name
    const accountFileName =
      accountName.length > 80 ? accountName.substr(0, 80) : accountName
    return (
      this.state.reportType +
      ' ' +
      accountFileName +
      ' ' +
      moment().format('YYYY-MM-DD') +
      '.pdf'
    )
  }

  // DEPR showDownloadLink = () => {
  //   if (this.props.reportData) {
  //     return (
  //       <div className="row">
  //         <a
  //           href={'data:application/pdf;base64,' + this.props.reportData}
  //           download={this.getPdfFileName()}
  //           // eslint-disable-next-line
  //           ref={input => {
  //             this.downloadlink = input
  //             this.autoDownloadFile()
  //           }}
  //         >
  //           <p>
  //             <u>Download</u>
  //           </p>
  //         </a>
  //       </div>
  //     )
  //   }
  // }

  autoDownloadFile = () => {
    if (this.props.isCompleted && this.downloadlink && this.state.reportType) {
      this.downloadlink.click()
    }
  }

  render() {
    // Cconsole.log('render Block Choices')
    // console.dir(this.props)
    // console.dir(this.state)
    const qlVariables = {
      activity_uid: this.props.activityUid
    }

    const qlPrintPublicMinutesVariables = {
      meeting_minutes: {
        activity_uid: this.props.activityUid,
        template_code: 'meeting_minutes_public',
        lang: this.state.lang
      },
      fileName: this.getPdfFileName()
    }
    const qlPrintInternalMinutesVariables = {
      meeting_minutes: {
        activity_uid: this.props.activityUid,
        template_code: 'meeting_minutes_internal',
        lang: this.state.lang
      },
      fileName: 'Internal Notes'
    }
    return (
      <div>
        <div className="row util-marginBottom">
          <div className="col-md-12">
            <div className="MinuteOfMeeting-header-title">
              <FormattedMessage
                id="crm.ui.minute.form.print_meeting_minutes"
                defaultMessage="Print Meeting Minutes"
              />
            </div>
          </div>
          <div className="col-md-3">
            <CrmDropdown
              activeOption={this.state.lang}
              options={this.state.langOption}
              onChange={this.handleLangOnChange}
              placeholder={
                <FormattedMessage
                  id="crm.ui.dropdown.report_language"
                  defaultMessage="Report language"
                />
              }
            />
          </div>
          <div className="col-md-3">
            <PrintMinutesButtonQL
              style={{ fontSize: 12 }}
              label={
                <FormattedMessage
                  id="crm.ui.button.meeting_minutes_pdf"
                  defaultMessage="Meeting Minutes PDF"
                />
              }
              qlVariables={qlPrintPublicMinutesVariables}
              successMessage={
                <FormattedMessage
                  id="crm.ui.button.meeting_minutes_printed"
                  defaultMessage="Meeting minutes printed"
                />
              }
              progressMessage={
                <FormattedMessage
                  id="crm.ui.button.generating_pdf"
                  defaultMessage="Generating PDF..."
                />
              }
            />
          </div>
          <div className="col-md-3">
            <PrintMinutesButtonQL
              label={
                <FormattedMessage
                  id="crm.ui.button.internal_notes_pdf"
                  defaultMessage="Internal Notes PDF"
                />
              }
              qlVariables={qlPrintInternalMinutesVariables}
              successMessage={
                <FormattedMessage
                  id="crm.ui.button.meeting_minutes_printed"
                  defaultMessage="Meeting minutes printed"
                />
              }
              progressMessage={
                <FormattedMessage
                  id="crm.ui.button.generating_pdf"
                  defaultMessage="Generating PDF..."
                />
              }
            />
          </div>
          <div className="col-md-3">
            <ActionButtonSendMeetingMinutes
              label={
                <FormattedMessage
                  id="crm.ui.button.send_email_with_meeting_minutes"
                  defaultMessage="Send email with Meeting Minutes"
                />
              }
              qlMutation={qlMutationSendMeetingMinutesEmail}
              qlVariables={qlVariables}
              successMessage={
                <FormattedMessage
                  id="crm.ui.button.meeting_minutes_email_sent"
                  defaultMessage="Meeting Minutes Email Sent!."
                />
              }
              progressMessage={
                <FormattedMessage
                  id="crm.ui.button.sending_minutes"
                  defaultMessage="Sending Minutes..."
                />
              }
            />
          </div>
        </div>
      </div>
    )
  }
}

SurveyBlockPrinting.propTypes = {
  isCompleted: bool.isRequired,
  activityUid: string.isRequired,
  viewValues: shape({}).isRequired // eslint-disable-line
}

SurveyBlockPrinting.defaultProps = {
  sectionName: undefined,
  multiple: true,
  wrapperClassName: undefined,
  reportGenerating: false
}

function mapStateToProps(state) {
  return {
    viewValues: getFormViewValues(state)
  }
}

function mapDispatchToProps() {
  return {}
}

const SurveyBlockPrintingQL = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SurveyBlockPrinting)

export default SurveyBlockPrintingQL
