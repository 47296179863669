import cloneDeep from 'clone-deep'
import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { qlqfetchAccountContactDetails } from 'crm-data/accounts'
import React from 'react'
import uuidv4 from 'uuid'
import { qlqActivityWithFields } from 'crm-data/activities'
import validate from 'crm-utils/validates'

const editContactPersonHoc = WrappedComponent => {
  class EditContactPersonHocInner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        person: props.person ? { ...props.person } : undefined
      }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (nextProps.person !== this.props.person) {
        this.setState({
          person: nextProps.person ? { ...nextProps.person } : undefined
        })
      }
    }

    static propTypes = {
      ...cqCommandPropTypes
    }

    static defaultProps = {
      isEditing: false,
      gotoPage: undefined,
      newAccountUid: undefined
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })

    validateEmails = person => {
      let error
      const contact_details = person.contact_details
        ? [...person.contact_details]
        : []
      // eslint-disable-next-line array-callback-return
      contact_details.map(v => {
        if (v.kind === 'email') {
          const error_validate = validate.email(v.value)
          if (error_validate) error = error_validate
        }
      })
      return error
    }

    handleEditPerson = async (pPerson, activityUid) => {
      delete pPerson.isEditing
      pPerson.contact_details = pPerson.contact_details.map(v => ({
        ...v,
        isEditing: false,
        isNew: false
      }))

      const person = cloneDeep(pPerson)
      person.contact_details = person.contact_details.map(v => ({
        account_uid: v.account_uid,
        contact_details_uid: v.contact_details_uid,
        contact_details_uuid: v.contact_details_uid,
        kind: v.kind,
        value: v.value,
        country_code: v.country_code,
        is_valid: v.isDeleted ? false : v.is_valid,
        remarks: v.remarks
      }))

      if (this.props.isSaving) {
        throw new Error('Person is saving')
      }
      if (!person) {
        throw new Error('No person passed')
      }
      if (!person.account_uid) {
        throw new Error('Person is missing account_uid')
      }
      if (!person.account_person_uid) {
        throw new Error('Person is missing account_person_uid')
      }

      const command = {
        type: 'contact_person.update',
        aggregate_uid: person.account_person_uid,
        aggregate_type: 'contact_person',
        payload: { ...person }
      }
      // console.log('command', command)
      try {
        const refetchQueries = [
          {
            query: qlqfetchAccountContactDetails, // qlqfetchAccount
            variables: {
              account_uid: person.account_uid
            }
          }
        ]
        // case edit person in meeting form
        if (activityUid) {
          refetchQueries.push({
            query: qlqActivityWithFields,
            variables: { activity_uid: activityUid }
          })
        }
        const res = await this.props.onRunCommand(command, { refetchQueries })
        // console.log('Person saved', res)
        return res.data.cqCommand
      } catch (err) {
        console.log('Save Person error', err)
        throw new Error('Save Person error')
      }
    }

    handleSaveNewPerson = async pPerson => {
      delete pPerson.isNew
      pPerson.contact_details = pPerson.contact_details.map(v => ({
        ...v,
        isNew: false
      }))

      const person = cloneDeep(pPerson)
      // console.log('handleSaveNewPerson', person)

      if (this.props.isSaving) {
        throw new Error('Person is saving')
      }
      if (!person) {
        throw new Error('No person passed')
      }
      if (!person.account_uid) {
        throw new Error('Person is missing account_uid')
      }

      const command = {
        type: 'contact_person.create',
        aggregate_uid: uuidv4(),
        aggregate_type: 'contact_person',
        payload: { ...person }
      }
      // console.log('command', command)
      try {
        const refetchQueries = [
          {
            query: qlqfetchAccountContactDetails, // qlqfetchAccount
            variables: {
              account_uid: person.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, { refetchQueries })
        // console.log('Person added', res, this.props)
        return res.data.cqCommand
      } catch (err) {
        console.log('Add Person error', err)
        throw new Error('Add Person error')
      }
    }

    handleRemovePerson = async pPerson => {
      const person = { ...pPerson, is_deleted: true, is_valid: false }
      person.contact_details = person.contact_details.map(v => ({
        account_uid: v.account_uid,
        contact_details_uid: v.contact_details_uid,
        contact_details_uuid: v.contact_details_uid,
        kind: v.kind,
        value: v.value,
        is_valid: false,
        remarks: v.remarks
      }))
      // console.log('handleRemovePerson', person)

      if (this.props.isSaving) {
        throw new Error('Person is saving')
      }
      if (!person) {
        throw new Error('No person passed')
      }
      if (!person.account_uid) {
        throw new Error('Person is missing account_uid')
      }
      if (!person.account_person_uid) {
        throw new Error('Person is missing account_person_uid')
      }

      const command = {
        type: 'contact_person.delete',
        aggregate_uid: person.account_person_uid,
        aggregate_type: 'contact_person',
        payload: { ...person }
      }
      // console.log('command', command)
      try {
        const refetchQueries = [
          {
            query: qlqfetchAccountContactDetails, // qlqfetchAccount
            variables: {
              account_uid: person.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, { refetchQueries })
        // console.log('Person removed', res, this.props)
        return res.data.cqCommand
      } catch (err) {
        console.log('Remove Person error', err)
        throw new Error('Remove Person error')
      }
    }

    handlePersonContactFieldChanged = event => {
      const newData = { ...this.state.person }
      newData[event.currentTarget.id] = event.currentTarget.value
      this.setState({ person: newData })
    }

    handlePersonContactDetailChanged = personContactDetails => {
      const person = {
        ...this.state.person,
        contact_details: [...personContactDetails]
      }
      this.setState({ person })
    }

    switchPersonToReadMode = async () => {
      let person = { ...this.state.person, isNew: false, isEditing: false }
      person.contact_details = person.contact_details.map(v => ({
        ...v,
        isNew: false,
        isEditing: false
      }))
      this.setState({ person })
    }

    toggleEditMode = isEditing => {
      const person = { ...this.props.person, isEditing }
      person.contact_details = person.contact_details
        ? person.contact_details
          .map(v => ({
            ...v,
            isEditing: isEditing,
            isDeleted: false
          }))
          .filter(v => !v.isNew)
        : []
      this.setState({ person })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          person={this.state.person}
          onEditPerson={this.handleEditPerson}
          onRemovePerson={this.handleRemovePerson}
          onSaveNewPerson={this.handleSaveNewPerson}
          isSaving={this.props.isSaving}
          switchPersonToReadMode={this.switchPersonToReadMode}
          toggleEditMode={this.toggleEditMode}
          onPersonContactFieldChanged={this.handlePersonContactFieldChanged}
          onPersonContactDetailChanged={this.handlePersonContactDetailChanged}
          validateEmails={this.validateEmails}
        />
      )
    }
  }
  return crmGenericCommandHoc(EditContactPersonHocInner)
}

export default editContactPersonHoc
