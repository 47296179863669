export const qlMarketPotentialFields = ` market_potential_uid
size
other_size
type
valid_from
valid_till
created_by_user_uid
details,
created_by_user {
  user_uid
  full_name
}

`
export const qlCreateMarketPotentialMutation = `
mutation market_potential_create($marketPotential: MarketPotentialIn) {
  market_potential_create(marketPotential: $marketPotential) {
   ${qlMarketPotentialFields}
  }
}`

export const qlListMartketPotential = `
  query market_potential_history($account_uid: String!) {
    market_potential_history(account_uid: $account_uid) { ${qlMarketPotentialFields} }
  }
`

// OLD export const qlGetMartketPotential = `
// query market_potential($account_uid: String!) {
//   market_potential(account_uid: $account_uid) { ${qlMarketPotentialFields} }
// }
// `

export const qlGetMartketPotential = `
query account($account_uid: String!) {
  account(account_uid: $account_uid) {
    account_uid
    market_potential {${qlMarketPotentialFields} }
  }
}
`
