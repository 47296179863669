import React from 'react'
import { func, string, bool, shape } from 'prop-types'
import uuidv4 from 'uuid'
import { graphql, withApollo } from 'react-apollo'
import { qlmExecuteCommand } from 'crm-data/commands'

// eslint-disable-next-line
export const crmGenericCommandHoc = WrappedComponent => {
  class WithCommand extends React.PureComponent {
    static propTypes = {
      cqCommand: func,
      onCancel: func,
      client: shape().isRequired
    }
    static defaultProps = {
      onCancel: undefined,
      cqCommand: undefined
    }
    constructor(props) {
      super(props)
      this.state = {
        isSaving: false,
        commandUid: uuidv4(),
        newObjectUid: uuidv4()
      }
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState({ ...this.state, ...newState }, () => {
          resolve()
        })
      })

    _afterCommandSuccess = async () => {
      await this.setStateAsync({
        commandUid: uuidv4(),
        newObjectUid: uuidv4(),
        isSaving: false,
        hasErrors: false
      })
    }

    _afterCommandFailure = async () => {
      await this.setStateAsync({
        commandUid: uuidv4(),
        isSaving: false,
        hasErrors: true
      })
    }

    handleRunCommand = async (command, props, mutation = null) => {
      this.setState({ isSaving: true })
      console.log('command', command, props, mutation)
      if (props && props.forceNewCommandUid) {
        await this.setStateAsync({ commandUid: uuidv4() })
      }
      try {
        const variables = {
          command: {
            command_uid:
              props && props.forceNewCommandUid
                ? uuidv4()
                : this.state.commandUid,
            ...command
          }
        }
        console.log('command variables', variables)
        const result = await this.props.client.mutate({
          ...props,
          mutation: mutation || qlmExecuteCommand,
          variables
        })
        console.log('command result', result)
        if (
          result &&
          result.data &&
          result.data.cqCommand &&
          result.data.cqCommand.errors &&
          result.data.cqCommand.errors.length > 0
        ) {
          await this.setStateAsync({ isSaving: false, hasErrors: true })
          throw result.data.cqCommand.errors[0].message
        }
        await this._afterCommandSuccess()
        return result
      } catch (err) {
        await this._afterCommandFailure()
        throw err
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onRunCommand={this.handleRunCommand}
          isSaving={this.state.isSaving}
          isRunningCommand={this.state.isSaving}
          newObjectUid={this.state.newObjectUid}
        />
      )
    }
  }
  return withApollo(WithCommand)
}

export const cqCommandQl = graphql(qlmExecuteCommand, {
  name: 'cqCommand',
  withRef: true,
  options: () => ({
    refetchQueries: []
  })
})

export const cqCommandPropTypes = {
  onRunCommand: func.isRequired,
  newObjectUid: string.isRequired,
  isRunningCommand: bool.isRequired
}

export default crmGenericCommandHoc //#endregioncrmGenericCommandQlHoc
