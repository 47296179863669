import React, { PureComponent } from 'react'
import { shape, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import MinuteFormName from '../../../minutes-form/minute-form-name.jsx'
import { CrmTextInput, CrmButton } from 'crm-components'

class SurveyBlockCurrentSystem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: this.buildData(props),
      isEdited: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data !== this.props.data ||
      nextProps.sectionData !== this.props.sectionData
    ) {
      this.setState({
        ...this.state,
        data: this.buildData(nextProps),
        isEdited: false
      })
    }
  }

  handleFieldChanged = event => {
    const newData = { ...this.state.data }
    newData[event.currentTarget.id] = event.currentTarget.value
    this.setState({ ...this.state, data: newData })
  }

  buildData(props) {
    console.log('SurveBlock CurrentSystem buildData')
    console.dir(props)
    let sectionData = props.sectionData
    if (sectionData && typeof sectionData === 'object' && sectionData.value) {
      sectionData = sectionData.value
    }

    return { ...sectionData }
  }

  handleSave = data => {
    console.log('SurveyBlockCurrentSystem handleSave')
    console.dir(data)
    this.setState({ ...this.state, isSaving: true }, () => {
      // Do save
      const sectionData = { ...this.state.data }
      this.props.handleSave({
        section: this.props.section.code,
        data: sectionData
      })
    })
  }
  handleCurrentSystemEdit = () => {
    this.setState({ ...this.state, isEdited: true })
  }

  handleCancel = () => {
    this.setState({ ...this.state, isEdited: false })
    let newState = { ...this.state }
    newState.isEdited = false
    newState.saveData = newState.data
    this.setState({ newState })
  }

  render() {
    const { isEdited } = this.state
    return (
      <div>
        <MinuteFormName
          formname={
            <FormattedMessage
              id="crm.ui.minute.form.current_system"
              defaultMessage="Current System"
            />
          }
        />
        {!isEdited && this.props.isEditable && (
          <CrmButton
            label={
              <FormattedMessage
                id="crm.ui.button.edit_information_about_current_system"
                defaultMessage="Edit information about  current system"
              />
            }
            onClick={this.handleCurrentSystemEdit}
          />
        )}

        {!isEdited && this.state.data && (
          <div>
            <div className="row ActivityListItem-header util-marginBottom">
              <div className="col-xs-12 col-md-12 col-lg-4 ActivityListItem-headerItem">
                <span>
                  <FormattedMessage
                    id="crm.ui.minute.form.current_competitor"
                    defaultMessage="Current Competitor"
                  />
                </span>
                <div className="ActivityListItem-headerItem-value">
                  {this.state.data.current_competitor_name}
                </div>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-4 ActivityListItem-headerItem">
                <span>
                  <FormattedMessage
                    id="crm.ui.minute.form.current_price"
                    defaultMessage="Current price"
                  />
                </span>
                <div className="ActivityListItem-headerItem-value">
                  {this.state.data.current_price}
                </div>
              </div>
            </div>

            <div className="row ActivityListItem-header util-marginBottom">
              <div className="col-xs-12 col-md-12 col-lg-4 ActivityListItem-headerItem">
                <span>
                  <FormattedMessage
                    id="crm.ui.minute.form.reason_to_move"
                    defaultMessage="Reason to move"
                  />
                </span>
                <div className="ActivityListItem-headerItem-value">
                  {this.state.data.reason_to_move}
                </div>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-4 ActivityListItem-headerItem">
                <span>
                  <FormattedMessage
                    id="crm.ui.minute.form.motivation_factor_to_move"
                    defaultMessage="Motivation factor for client to move"
                  />
                </span>
                <div className="ActivityListItem-headerItem-value">
                  {this.state.data.motivation_factor_to_move}
                </div>
              </div>
            </div>

            <div className="row ActivityListItem-header util-marginBottom">
              <div className="col-xs-12 col-md-12 col-lg-12 ActivityListItem-headerItem">
                <span>
                  <FormattedMessage
                    id="crm.ui.minute.form.comments"
                    defaultMessage="Comments"
                  />
                </span>
                <div className="ActivityListItem-headerItem-value">
                  {this.state.data.comments}
                </div>
              </div>
            </div>
          </div>
        )}
        {isEdited && (
          <div className="row">
            <div className="col-md-4">
              <CrmTextInput
                placeholder="Current competitor"
                placeholderId="crm.ui.textinput.current_competitor"
                name="current_competitor_name"
                id="current_competitor_name"
                input={{
                  value: this.state.data.current_competitor_name,
                  onChange: this.handleFieldChanged
                }}
              />
            </div>
            <div className="col-md-4">
              <CrmTextInput
                placeholder="Current price"
                placeholderId="crm.ui.textinput.current_price"
                name="current_price"
                id="current_price"
                input={{
                  value: this.state.data.current_price,
                  onChange: this.handleFieldChanged
                }}
              />
            </div>
            <div className="col-md-4">
              <CrmTextInput
                placeholder="Reason to move"
                placeholderId="crm.ui.textinput.reason_to_move"
                name="reason_to_move"
                id="reason_to_move"
                input={{
                  value: this.state.data.reason_to_move,
                  onChange: this.handleFieldChanged
                }}
              />
            </div>
            <div className="col-md-4">
              <CrmTextInput
                placeholder="Motivation factor for client to move"
                placeholderId="crm.ui.textinput.motivation_factor_to_move"
                name="motivation_factor_to_move"
                id="motivation_factor_to_move"
                input={{
                  value: this.state.data.motivation_factor_to_move,
                  onChange: this.handleFieldChanged
                }}
              />
            </div>
            <div className="col-md-4">
              <CrmTextInput
                placeholder="Comments"
                placeholderId="crm.ui.textinput.comments"
                name="comments"
                id="comments"
                input={{
                  value: this.state.data.comments,
                  onChange: this.handleFieldChanged
                }}
              />
            </div>
            <div className="col-md-12">
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.button.save"
                    defaultMessage="Save"
                  />
                }
                onClick={this.handleSave}
              />
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.button.cancel"
                    defaultMessage="Cancel"
                  />
                }
                onClick={this.handleCancel}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

SurveyBlockCurrentSystem.propTypes = {
  data: shape({}).isRequired,
  sectionData: shape({}).isRequired,
  handleSave: func.isRequired,
  section: shape({}).isRequired,
  isEditable: bool.isRequired
}

export default SurveyBlockCurrentSystem
