/* eslint-disable camelcase */
import React from 'react'
import { array } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import { qlqAccountOpportunities } from 'crm-data/opportunities'

const qlOptions = {
  options: ownProps => ({
    variables: { account_uid: ownProps.accountUid }
  }),
  skip: ownProps => !ownProps.accountUid,
  props: ({ opportunitiesQl, ownProps }) => ({
    ...ownProps,
    opportunitiesQl,
    opportunities:
      opportunitiesQl && opportunitiesQl.account
        ? opportunitiesQl.account.opportunities
        : undefined,
    isLoading: opportunitiesQl ? opportunitiesQl.loading : false
  })
}

const opportunitiesListHoc = WrappedComponent => {
  class OpportunitiesListHocInner extends React.PureComponent {
    render() {
      const pendingOpportunities = []
      const lostOpportunities = []
      const wonOpportunities = []
      if (this.props.opportunities) {
        this.props.opportunities.forEach(op => {
          if (op.status_code === 'lost') {
            lostOpportunities.push(op)
          } else if (op.status_code === 'won') {
            wonOpportunities.push(op)
          } else {
            pendingOpportunities.push(op)
          }
        })
      }

      return (
        <WrappedComponent
          {...this.props}
          pendingOpportunities={pendingOpportunities}
          lostOpportunities={lostOpportunities}
          wonOpportunities={wonOpportunities}
        />
      )
    }
  }
  OpportunitiesListHocInner.propTypes = {
    opportunities: array
  }
  OpportunitiesListHocInner.defaultProps = {
    opportunities: []
  }

  return compose(
    graphql(qlqAccountOpportunities, {
      ...qlOptions,
      name: 'opportunitiesQl'
    })
  )(OpportunitiesListHocInner)
}

export default opportunitiesListHoc
