import React from 'react'
import gql from 'graphql-tag'
import { string } from 'prop-types'
import { Query } from 'react-apollo'

export const qlqListMeeting = gql(`
  query group_calendar($startDate: String!, $endDate: String!, $userUid: String!) {
    group_calendar(
      start_date: $startDate,
      end_date: $endDate,
      user_uid: $userUid
    ) {
      activity_uid
      account_uid
      account { 
        account_uid
        name
      }
      start_time
      completed_time
      activity_type { 
        name
        medium_code       
        code
      }
      activity_status {
        name
        kind
        is_success
      }
      meeting_minutes { meeting_minutes_uid completed_at } 
      address { address_uid city location street province suburb postcode line1 line2}
    }
  }
`)

const userMeetingListHoc = WrappedComponent => {
  const userMeetingListWithQuery = props => {
    console.log('userMeetingListWithQuery', props)
    return (
      <Query
        query={qlqListMeeting}
        variables={{
          userUid: props.queryUserUid,
          startDate: props.queryStartDate,
          endDate: props.queryEndDate
        }}
        fetchPolicy="network-only"
        // skip={!props.queryUserUid || !props.queryStartDate || !props.queryEndDate}
      >
        {prop => {
          const { loading, data } = prop
          return (
            <WrappedComponent
              {...props}
              loading={loading}
              listMeeting={data.group_calendar}
              loadingListMeeting={loading}
            />
          )
        }}
      </Query>
    )
  }
  userMeetingListWithQuery.propTypes = {
    queryEndDate: string.isRequired,
    queryStartDate: string.isRequired,
    queryUserUid: string.isRequired
  }
  return userMeetingListWithQuery
}

export default userMeetingListHoc
