import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from 'crm-components/generic-dropdown.jsx'

interface TemplateDropdownProps {
  documentTypeCode: string
  input: any
}

const TemplateDropdown: React.SFC<TemplateDropdownProps> = (props) => {
  console.log('TemplateDropdown', props)
  const variables = {}
  const qlq = gql`
    {
      templates(document_type_code:"${props.documentTypeCode}") {
        template_uid
        code
        document_type_code
        is_default
        description
        html
        json_data
        style
      }
    }
  `
  return (
    <Query query={qlq} variables={variables}>
      {({ data }) => {
        const options =
          data && data.templates
            ? data.templates.map((v) => ({
                ...v,
                value: v.template_uid,
                name: v.code
              }))
            : []
        console.log('TemplateDropdown', data)
        return (
          <GenericDropdown
            {...props}
            placeholder={'Quotation Type'}
            options={options}
          />
        )
      }}
    </Query>
  )
}

export default TemplateDropdown
