import React, { useState } from 'react'
import { DataSourceItem } from './data-source-item'
import { NewDataSourceWidget } from './new-data-source'
import { useComponentConfigurationContext } from '../configuration-tools/component-configuration-context'
import {
  CrmButton,
  CrmModal,
  CrmJsonPreview,
  CrmCard,
  CrmCheckbox,
  CrmTextInput
} from '@cartrack-crm/ui'
import { faCode, faPen } from '@fortawesome/free-solid-svg-icons'

export const ComponentSectionDataSources = ({
  component,
  runQuery,
  onSaveDataSource
}) => {
  const [isAdding, setIsAdding] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const componentDefinitionContext = useComponentConfigurationContext()
  const [isShowwingJsonPopup, setIsShowingJsonPopup] = useState(false)

  const handleSaveNewDataSource = (dataSourceDefinition) => {
    console.log('handleSaveNewDataSource', dataSourceDefinition)
    componentDefinitionContext.addDataSource(dataSourceDefinition)
    setIsAdding(false)
  }
  const componentDataConfig =
    componentDefinitionContext.component?.content?.data || {}
  const isCombined = componentDataConfig?.dataSources
    ? componentDataConfig.dataSources.length > 1
    : false

  console.log('isCombined', isCombined, componentDataConfig)
  return (
    <CrmCard className="util-paddingMd">
      <div className="CrmCard-content">
        <div className="row">
          <div className="col-md-6">
            <strong>Data</strong>
          </div>
          <div className="col-md-3">
            <CrmButton
              small
              style={{ minWidth: '0 !important', margin: 0 }}
              className="util-marginLeft"
              icon={faCode}
              iconButton
              onClick={() => setIsShowingJsonPopup(true)}
            />
          </div>
          <div className="col-md-3">
            {/* <CrmButton
              small
              style={{ minWidth: '0 !important', margin: 0 }}
              className="util-marginLeft"
              icon={faPen}
              iconButton
              onClick={() => setShowGeneralForm(true)}
            /> */}
          </div>
        </div>

        {component?.content?.data?.dataSources ? (
          <div>
            {component.content.data.dataSources.map((ds: any, idx) => (
              <DataSourceItem
                dataSource={ds}
                component={component}
                runQuery={runQuery}
                onSave={(dataSource) => {
                  console.log('save', dataSource, onSaveDataSource)
                  onSaveDataSource(dataSource, idx)
                }}
                isCombined={isCombined}
              />
            ))}
          </div>
        ) : (
          <div> No Data Sources </div>
        )}

        {isAdding ? (
          <NewDataSourceWidget
            onCancel={() => {
              setIsAdding(false)
            }}
            onSave={handleSaveNewDataSource}
          />
        ) : (
          <div>
            <CrmButton
              type="primary"
              small
              onClick={() => {
                setIsAdding(true)
              }}
              label="+ Add more data"
            />
          </div>
        )}

        {isCombined && isExpanded && (
          <div>
            <div>Merge many data sources using: </div>
            <CrmTextInput
              input={{
                value: componentDataConfig.combineDataSources.targetKeyField,
                onChange: () => {}
              }}
            />
            <div>Merge field Type</div>
            <CrmTextInput
              input={{
                value: componentDataConfig.combineDataSources.merge_field_type,
                onChange: () => {}
              }}
            />
          </div>
        )}
        <div onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'Collapse' : 'Expand'}
        </div>
        <CrmModal
          isOpen={isShowwingJsonPopup}
          onClose={() => setIsShowingJsonPopup(false)}
        >
          <div style={{ height: '100%' }} className="util-flexColumn">
            <h5>Data:</h5>
            <CrmJsonPreview json={component?.content?.data?.dataSources} />
          </div>
        </CrmModal>
      </div>
    </CrmCard>
  )
}
