/* eslint-disable global-require */
import React, { PureComponent } from 'react'
import { bool, any, func, shape } from 'prop-types'

class CrmForm extends PureComponent {
  // Aconstructor(props) {
  //   super(props)
  //   this.handleNavbarItemClick = this.handleNavbarItemClick.bind(this)
  // }

  handleNavbarItemClick = () => {
    console.log('handleNavbarItemClick')
  }

  buildNav() {
    return
    // eslint-disable-next-line
    const sections = this.props.children
      .filter(section => section instanceof Object)
      .filter(section => section.props.sectionName || section.props.groupName)
      .map(section => {
        let ret
        if (section.props.sectionName) {
          ret = { sectionName: section.props.sectionName, isGroup: false }
        } else if (section.props.groupName) {
          ret = { sectionName: section.props.groupName, isGroup: true }
          if (section.props.children) {
            let children
            if (
              typeof section.props.children === 'object' &&
              section.props.children.type === 'form'
            ) {
              children = section.props.children.props.children
            } else {
              children = section.props.children
            }
            ret.items = children
              .filter(s => s.props && s.props.sectionName)
              .map(s => ({ sectionName: s.props.sectionName }))
          }
          // Parse children
        }
        return ret
      })
    return sections // eslint-disable-line
  }

  render() {
    const { hasNavbar } = this.props
    const { handleNavbarItemClick } = this
    const sections = this.buildNav()
    return (
      <div
        className={`CrmForm ${hasNavbar && 'CrmForm--hasNavbar'}`}
        style={this.props.style}
      >
        {hasNavbar && (
          <div className="CrmForm-navbar">
            {sections.map(section => (
              <div
                key={section.sectionName}
                className={`CrmForm-navbar-item ${
                  section.isGroup ? 'CrmForm-navbar-group' : ''
                }`}
                onClick={handleNavbarItemClick}
              >
                <span>
                  {this.props.formatMessage(
                    section.sectionName,
                    'section.name'
                  )}
                </span>
                {section.items &&
                  section.items.map(sub => (
                    <div key={sub.sectionName} className="CrmForm-navbar-item">
                      {this.props.formatMessage(
                        sub.sectionName,
                        'section.name'
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        )}
        <div
          className={`CrmForm-body ${this.props.fullHeight &&
            'util-fullHeight'}`}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

CrmForm.defaultProps = {
  hasNavbar: false,
  style: undefined
}

CrmForm.propTypes = {
  children: any.isRequired, // eslint-disable-line react/forbid-prop-types
  hasNavbar: bool,
  formatMessage: func.isRequired,
  style: shape()
}

export default CrmForm
