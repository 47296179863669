import React, { createElement } from 'react'
import { shape, string, bool, func, arrayOf } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field, FieldArray } from 'redux-form'
import { CrmTextInput, CrmButton } from 'crm-components'
import { Dropdown } from 'util-components'

// eslint-disable-next-line
const kindOptions = [
  { name: 'Individual', value: 'individual' },
  { name: 'Department', value: 'department' }
]

// eslint-disable-next-line
const RenderMembers = ({
  isEditing,
  fields,
  // eslint-disable-next-line
  meta: { error, submitFailed }
}) => {
  console.log('Render members props')
  console.log('Render Contact Form')
  console.dir(fields)

  const handleAdd = event => {
    console.log('handleAdd', event)
    fields.push({ kind: event.currentTarget.id })
  }
  return (
    <div className="row  CrmForm-row">
      {/* <button type="button" onClick={() => fields.push({})}>
        Add Contact
      </button> */}
      {fields &&
        fields.map &&
        fields.map((member, index) => {
          console.log('Render field')
          console.log(member)
          const f = fields.get(index)
          const kind = f.kind
            ? f.kind.charAt(0).toUpperCase() + f.kind.slice(1)
            : 'Value'
          return (
            // eslint-disable-next-line
            <div key={index} className="col-md-4">
              {/* <button
        type="button"
        title="Remove Member"
        onClick={() => fields.remove(index)}
      /> */}
              <Field
                name={`${member}.value`}
                type="text"
                component={CrmTextInput}
                placeholder={kind}
              />
            </div>
          )
        })}
      {isEditing && (
        <div className="row">
          <div className="col-md-12">
            <CrmButton
              id="mobile"
              grouped
              iconButton
              icon="mobile"
              label={
                <FormattedMessage
                  id="crm.ui.contact.form.add_mobile"
                  defaultMessage="Add Mobile"
                />
              }
              onClick={handleAdd}
            />
            <CrmButton
              id="email"
              grouped
              iconButton
              icon="envelope"
              label={
                <FormattedMessage
                  id="crm.ui.contact.form.add_email"
                  defaultMessage="Add Email"
                />
              }
              onClick={handleAdd}
            />
            <CrmButton
              id="phone"
              grouped
              iconButton
              icon="phone"
              label={
                <FormattedMessage
                  id="crm.ui.contact.form.add_phone"
                  defaultMessage="Add Phone"
                />
              }
              onClick={handleAdd}
            />
          </div>
        </div>
      )}
    </div>
  )
}
RenderMembers.propTypes = {
  isEditing: bool.isRequired,
  fields: arrayOf(shape()).isRequired,
  meta: shape().isRequired
}
const PersonItem = props => {
  console.log('PersonItem', props)
  const { member, isEditing } = props
  // eslint-disable-next-line
  const dropdownRenderer = props =>
    createElement(Dropdown, { ...props, disabled: !isEditing })
  const prefix = member ? member + '.' : ''
  return (
    <div className="ContactDetailItem" style={props.style}>
      <div className="row CrmForm-row">
        {/* <div className="col-md-3">
          <Field
            options={kindOptions}
            name={`${member}.kind`}
            component={dropdownRenderer}
            disabled={!isEditing}
            title="Type"
          />
        </div> */}
        <div className="col-md-4">
          <Field
            name={`${prefix}name`}
            component={CrmTextInput}
            type="text"
            placeholder="Name"
            placeholderId="crm.ui.contact.form.name"
          />
        </div>
        <div className="col-md-4">
          <Field
            name={`${prefix}description`}
            component={CrmTextInput}
            type="text"
            placeholder="Title"
            placeholderId="crm.ui.contact.form.title"
          />
        </div>
        <div className="col-md-4">
          <Field
            name={`${prefix}department`}
            component={CrmTextInput}
            type="text"
            placeholder="Department"
            placeholderId="crm.ui.contact.form.department"
          />
        </div>
      </div>
      <FieldArray
        name={`${prefix}contact_details`}
        component={RenderMembers}
        parentName={member}
        isEditing={isEditing}
      />
      {/* <div className="col-md-4">
          <Field
            name={`${member}.email`}
            component={CrmTextInput}
            type="text"
            placeholder="Email"
          />
        </div> */}
      {/* <div className="col-md-1">
          {isEditing && (
            <CrmButton
              className="remove-button"
              xsmall
              icon="trash"
              onClick={props.onRemoveItem}
            />
          )}
        </div> */}
      {/* <div className="col-md-4">T O D O: Support Line, whatsapp contacts</div> */}
    </div>
  )
}

PersonItem.defaultProps = {
  style: {},
  isEditing: false
}

PersonItem.propTypes = {
  style: shape({}),
  member: string.isRequired,
  isEditing: bool,
  onRemoveItem: func.isRequired // eslint-disable-line
}

export default PersonItem
