import React, { PureComponent } from 'react'
import {
  shape,
  string,
  arrayOf,
  func,
  bool,
  number,
  oneOfType
} from 'prop-types'
import {
  CrmCheckbox,
  CrmTextInput,
  CrmButton,
  CrmGoogleMap
} from 'crm-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import editAddressHoc from 'crm-modules/accounts/hoc/edit-address-hoc.js'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MeetingAddressItem from '../../../meeting/components/section-address/meeting-address-item.jsx'
import { Spinner } from 'util-components'
import { _addrFormat } from 'crm-modules/meeting/hoc/edit-meeting-hoc.js'

class AddressItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      googleMapOptions: {
        isShow: true,
        width: '100%',
        height: '200px',
        zoom: 17
      },
      isFindingGeocode: false,
      foundGeocode: false,
      isEditingAddress: false,
      isValidLocation: this.getIsValidLocation(props.address),
      hasPrimary: props.address.is_primary
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.address !== nextProps.address) {
      this.setState({
        isValidLocation: this.getIsValidLocation(nextProps.address)
      })
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, resolve)
    })

  updateGoogleMapMarker = markerLocation => {
    const googleMapOptions = {
      ...this.state.googleMapOptions,
      markerLocation
    }
    this.setState({
      googleMapOptions
    })
  }

  findGeocode = async () => {
    this.setStateAsync({ isFindingGeocode: true, foundGeocode: false })
    try {
      const geocode = await this.props.findGeocode(this.props.address)
      this.updateGoogleMapMarker(geocode)
      this.setStateAsync({ foundGeocode: true })
      this.props.onAddressFieldChanged(geocode, 'location', this.props.index)
    } catch (err) {
      if (this.props.isRequiredValidLocation) {
        // todo: toastr not working with popup
        alert(err.message)
      } else {
        toastr.error(err.message)
      }
      this.updateGoogleMapMarker(null)
      this.setStateAsync({ foundGeocode: false })
    }
    this.setStateAsync({ isFindingGeocode: false })
  }

  getIsValidLocation = address => {
    let isValid = false
    if (
      address &&
      address.location &&
      address.location.lat &&
      address.location.lng
    ) {
      isValid = true
    }
    return isValid
  }

  handleAddressFieldChanged = (event, fieldName) => {
    this.setState({ isEditingAddress: true, foundGeocode: false })
    this.props.onAddressFieldChanged(event, fieldName, this.props.index)
  }

  handleSave = async event => {
    if (this.props.isSaving) {
      return
    }

    // if no current primary and address form has is not primary
    if (
      this.props.hasPrimaryAddress === null &&
      !this.props.address.is_primary
    ) {
      toastr.error('Please select a primary address.')
      return
      // if no current primary and new/existing address is not primary
    } else if (!this.props.address.is_primary) {
      toastr.error('Please select a primary address.')
      return
    }

    // validate location
    if (this.props.isRequiredValidLocation && !this.state.isValidLocation) {
      return
    }

    if (this.props.address.isNew) {
      // call api add address
      try {
        const res = await this.props.onSaveAddress(this.props.address)
        await this.props.switchAddressToReadMode()
        await this.props.updateAddressesUI(this.props.index, this.props.address)
        toastr.success('Address added')
      } catch (err) {
        toastr.error('Error adding address')
      }
    } else {
      // call api update address
      try {
        const res = await this.props.onSaveAddress(this.props.address)
        await this.props.switchAddressToReadMode()
        toastr.success('Address changed')
      } catch (err) {
        toastr.error('Error saving address')
      }
    }
  }

  handleCancel = () => {
    this.setState({ isEditingAddress: false })
    if (this.props.isSaving) {
      return
    }
    if (this.props.address.address_uid) {
      // if edit existing, switch to read mode
      if (this.props.handleCancelEditAddressOnMeetingForm) {
        this.props.handleCancelEditAddressOnMeetingForm()
      } else {
        this.props.toggleEditMode(false)
      }
    } else {
      // if new address, remove from array
      this.props.onCancelAddAddress(this.props.index, this.props.address)
    }
  }

  handleRemove = () => {
    if (this.props.isSaving) {
      return
    }
    const toastrConfirmOptions = {
      onOk: async () => {
        // call api remove address
        try {
          const res = await this.props.onRemoveAddress(this.props.address)
          this.props.removeAddressUI(this.props.index, this.props.address)
          toastr.success('Address deleted')
        } catch (err) {
          toastr.error('Delete address error')
        }
      }
    }
    toastr.confirm(
      'Are you sure you want to delete this address ? ',
      toastrConfirmOptions
    )
  }

  handleStartEdit = () => {
    this.props.toggleEditMode(true)
  }

  getLocationFromAddress = address => {
    const location =
      address &&
      address.location &&
      address.location.lat &&
      address.location.lng
        ? {
            lat: parseFloat(address.location.lat),
            lng: parseFloat(address.location.lng)
          }
        : null
    return location
  }

  render() {
    const {
      address,
      mapAlign,
      mapWidth,
      mapHeight,
      hiddenFields,
      keepFormOpen,
      isRequiredValidLocation
    } = this.props
    const {
      googleMapOptions,
      isFindingGeocode,
      foundGeocode,
      isEditingAddress,
      isValidLocation
    } = this.state
    const isEditMode = address.isNew || address.isEditing || keepFormOpen
    const editModeClass = isEditMode ? 'CrmMdCard util-marginBottom' : ''
    const pAddress = { ...address, isMeetingAddress: true }
    const markerLocation = this.getLocationFromAddress(address)
    const center = markerLocation || {
      lat: 13.667327,
      lng: 100.641038
    }
    const markerClass =
      markerLocation && (!isEditingAddress || foundGeocode)
        ? 'util-textGreen'
        : ''
    return (
      <div
        className="CrmInlineEditable util-hooverableContainer"
        style={{ position: 'relative', paddingRight: '0px' }}
      >
        <div
          style={{ padding: isEditMode ? '15px' : '10px 0px 10px 0px' }}
          className={editModeClass}
        >
          <div
            className={mapAlign === 'top' ? `util-flexColumn` : `util-flexRow`}
          >
            {isEditMode && googleMapOptions.isShow && (
              <div
                className={`
                ${
                  mapAlign === 'top'
                    ? ' util-marginBottom'
                    : ' util-marginRight'
                }`}
                style={{ flex: mapWidth }}
              >
                <CrmGoogleMap
                  height={
                    mapAlign === 'top' ? googleMapOptions.height : mapHeight
                  }
                  width={googleMapOptions.width}
                  zoom={googleMapOptions.zoom}
                  markerLocation={markerLocation}
                  center={center}
                  markerClass={markerClass}
                  markerStyles={{ fontSize: '20px' }}
                />
              </div>
            )}

            {hiddenFields.all_editable_fields && (
              <div>{_addrFormat(address)}</div>
            )}

            {!hiddenFields.all_editable_fields && (
              <div className="util-flexGrow">
                {isEditMode ? (
                  <React.Fragment>
                    <div>
                      <div className="row">
                        <div className="col-md-11 CrmForm-row">
                          <CrmTextInput
                            placeholder={
                              <FormattedMessage
                                id="crm.account.address_line_1"
                                defaultMessage="Address Line 1"
                              />
                            }
                            placeholderId=""
                            name="line1"
                            id="line1" // id="crm.ui.address.form.address_line_1"
                            input={{
                              value: address.line1,
                              onChange: this.handleAddressFieldChanged
                            }}
                          />
                        </div>
                        <div className="col-md-1 util-marginAuto">
                          {!isFindingGeocode && (
                            <div
                              className={`util-pointer ${markerClass}`}
                              onClick={this.findGeocode}
                              title={this.props.intl.formatMessage({
                                id: 'crm.account.click_to_find_address_geocode',
                                defaultMessage: 'Click to find address geocode'
                              })}
                            >
                              <FontAwesomeIcon icon="map-marker-alt" />
                            </div>
                          )}
                          {isFindingGeocode && (
                            <div
                              style={{
                                position: 'relative',
                                left: '-5px'
                              }}
                            >
                              <Spinner className="Spinner_NoMargin" />
                            </div>
                          )}
                        </div>
                      </div>
                      {isRequiredValidLocation && isValidLocation === false && (
                        <div className="CrmForm-field-error">
                          *{' '}
                          {this.props.intl.formatMessage({
                            id: `crm.activity.address_selected_for_a_meeting_must_be_confirmed`,
                            defaultMessage:
                              'Address selected for a meeting must be confirmed on a map. Please click Locate button next to the address to validate location on a map'
                          })}
                        </div>
                      )}
                      {!hiddenFields.line2 && (
                        <div className="row">
                          <div className="col-md-12 CrmForm-row">
                            <CrmTextInput
                              placeholder={
                                <FormattedMessage
                                  id="crm.account.address_line_2"
                                  defaultMessage="Address Line 2"
                                />
                              }
                              placeholderId=""
                              name="line2"
                              id="line2" // id="crm.ui.address.form.address_line_2"
                              input={{
                                value: address.line2,
                                onChange: this.handleAddressFieldChanged
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-6 CrmForm-row">
                          <CrmTextInput
                            placeholder={
                              <FormattedMessage
                                id="crm.account.city"
                                defaultMessage="City"
                              />
                            }
                            placeholderId=""
                            name="city"
                            id="city" // id="crm.ui.address.form.city"
                            input={{
                              value: address.city,
                              onChange: this.handleAddressFieldChanged
                            }}
                          />
                        </div>
                        <div className="col-md-6 CrmForm-row">
                          <CrmTextInput
                            placeholder={
                              <FormattedMessage
                                id="crm.account.postcode"
                                defaultMessage="Postcode"
                              />
                            }
                            placeholderId=""
                            name="postcode"
                            id="postcode" // id="crm.ui.address.form.postcode"
                            input={{
                              value: address.postcode,
                              onChange: this.handleAddressFieldChanged
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 CrmForm-row">
                          <CrmTextInput
                            placeholder={
                              <FormattedMessage
                                id="crm.account.suburb"
                                defaultMessage="Suburb"
                              />
                            }
                            placeholderId=""
                            name="suburb"
                            id="suburb" // id="crm.ui.address.form.suburb"
                            input={{
                              value: address.suburb,
                              onChange: this.handleAddressFieldChanged
                            }}
                          />
                        </div>
                        <div className="col-md-4 CrmForm-row">
                          <CrmTextInput
                            placeholder={
                              <FormattedMessage
                                id="crm.account.town"
                                defaultMessage="Town"
                              />
                            }
                            placeholderId=""
                            name="town"
                            id="town" // id="crm.ui.address.form.town"
                            input={{
                              value: address.town,
                              onChange: this.handleAddressFieldChanged
                            }}
                          />
                        </div>
                        <div className="col-md-4 CrmForm-row">
                          <CrmTextInput
                            placeholder={
                              <FormattedMessage
                                id="crm.account.province"
                                defaultMessage="Province"
                              />
                            }
                            placeholderId=""
                            name="province"
                            id="province" // id="crm.ui.address.form.province"
                            input={{
                              value: address.province,
                              onChange: this.handleAddressFieldChanged
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 CrmForm-row">
                        <CrmCheckbox
                          name="is_primary"
                          id="is_primary"
                          label={this.props.intl.formatMessage({
                            id: 'crm.account.is_primary',
                            defaultMessage: 'Set as primary address'
                          })}
                          value={address.is_primary}
                          onChange={this.handleAddressFieldChanged}
                        />
                      </div>
                    </div>
                    {!hiddenFields.headquarter && (
                      <div className="row">
                        <div className="col-md-12 CrmForm-row">
                          <CrmCheckbox
                            name="address_kind"
                            id="address_kind"
                            label={this.props.intl.formatMessage({
                              id: 'crm.ui.address.form.headquater',
                              defaultMessage: 'Headquater'
                            })}
                            value={address.address_kind === 'headquarter'}
                            onChange={this.props.onAddressFieldChanged}
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <MeetingAddressItem
                      address={pAddress}
                      caller="accountPage"
                      handleStartEdit={this.handleStartEdit}
                    />
                  </React.Fragment>
                )}

                {this.props.showOnlyRemoveAddress && (
                  <div className="util-flexRowRight util-marginTop">
                    <CrmButton
                      type="secondary"
                      className="util-textApply"
                      onClick={() =>
                        this.props.removeAddressUI(this.props.index)
                      }
                      icon={this.props.isSaving ? '' : 'trash'}
                      iconButton
                      isSaving={this.props.isSaving}
                      enabled={!this.props.isSaving}
                    />
                  </div>
                )}

                {!this.props.showOnlyRemoveAddress &&
                  (this.props.isSaving || isEditMode) && (
                    <React.Fragment>
                      <div className="util-flexRowSpaceBetween util-marginTop">
                        <div>
                          {address.isEditing && (
                            <CrmButton
                              onClick={this.handleRemove}
                              icon={this.props.isSaving ? '' : 'trash'}
                              iconButton
                              isSaving={this.props.isSaving}
                              enabled={!this.props.isSaving}
                            />
                          )}
                        </div>
                        <div className="util-flexRowRight">
                          <CrmButton
                            style={{ marginRight: 5 }}
                            onClick={this.handleCancel}
                            icon="close"
                            iconButton
                            enabled={!this.props.isSaving}
                          />
                          <CrmButton
                            style={{ marginLeft: 5, marginRight: 5 }}
                            onClick={this.handleSave}
                            type="primary"
                            icon={this.props.isSaving ? '' : 'check'}
                            iconButton
                            isSaving={this.props.isSaving}
                            enabled={!this.props.isSaving}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

AddressItem.propTypes = {
  isSaving: bool.isRequired,
  onAddressFieldChanged: func.isRequired,
  removeAddressUI: func.isRequired,
  onRemoveAddress: func.isRequired,
  toggleEditMode: func.isRequired,
  onCancelAddAddress: func.isRequired,
  onSaveAddress: func.isRequired,
  switchAddressToReadMode: func.isRequired,
  updateAddressesUI: func.isRequired,
  index: oneOfType([string, number]).isRequired,
  address: oneOfType([arrayOf(shape()), shape()]).isRequired,
  account_uid: string.isRequired,
  findGeocode: func,
  mapAlign: string,
  mapWidth: number,
  mapHeight: string,
  showOnlyRemoveAddress: bool,
  hiddenFields: oneOfType([arrayOf(shape({})), shape()]),
  keepFormOpen: bool,
  isRequiredValidLocation: bool,
  handleCancelEditAddressOnMeetingForm: func,
  intl: shape({}).isRequired,
  hasPrimaryAddress: shape()
}

AddressItem.defaultProps = {
  findGeocode: undefined,
  mapAlign: 'top',
  mapWidth: 1,
  mapHeight: '300px',
  showOnlyRemoveAddress: false,
  hiddenFields: {},
  keepFormOpen: false,
  isRequiredValidLocation: false,
  handleCancelEditAddressOnMeetingForm: undefined,
  hasPrimaryAddress: null
}

export default injectIntl(editAddressHoc(AddressItem))
