import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { qlqProcessType, qlqProcessDetails } from '../../../../../data/processes'
import { CrmDropdown } from '../../../../../components/index'
import { useAccountPermissionsContext } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

export const buildLostProspectCommand = (accountUid, processUid, reasonLostCode) => ({
  type: 'salesAccount.loseSalesPipeline',
  aggregate_uid: accountUid,
  aggregate_type: 'salesAccount',
  version: 2,
  payload: {
    processUid,
    resultStepTypeCode: reasonLostCode,
    causeType: 'manual'
  }
})
export const LostProspectForm = (props) => {
  const accountPermissionsContext: any = useAccountPermissionsContext()
  const { data, error, loading } = useQuery(qlqProcessType, {
    variables: {
      process_type_uid: props.processTypeUid
    },
    skip: !props.processTypeUid,
    fetchPolicy: 'no-cache'
  })

  console.log('render Lost prospect modall', props, data)
  const optionsReasonLost =
    data && data.process_type && data.process_type.steps
      ? data.process_type.steps
          .filter((qt) => {
            const is_lost = qt.kind === 'lost'
            const require_manage_permission = qt.require_manage_permission
              ? accountPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE)
              : true

            const account_validation_match = props.account?.validated_date
              ? qt.for_validated_account
              : qt.for_not_validated_account

            console.log(
              'Filter prospect lost reason result',
              qt.code,
              account_validation_match,
              qt.for_validated_account,
              qt.for_not_validated_account,
              props.account?.validated_date
            )
            return is_lost && require_manage_permission && account_validation_match
          })
          .map((qt) => ({
            name: qt.name,
            value: qt.process_step_type_uid,
            code: qt.code
          }))
      : []

  return (
    <div className="util-paddingMd">
      <div>
        <h4>
          <FormattedMessage
            id="crm.ui.account.prospect.select_reason_prospect_is_lost"
            defaultMessage="Select Reason the Prospect is Lost"
          />
        </h4>
        <CrmDropdown
          label={<FormattedMessage id="crm.ui.dropdown.reason_lost" defaultMessage="Reason Lost" />}
          options={optionsReasonLost}
          input={{
            value: props.reasonLost,
            onChange: (id, val) => {
              console.log('On change reason', id, val)
              const reason = optionsReasonLost.find((v) => v.value === id)
              console.log(reason)
              props.onSelectedReasonLost(reason)
            }
          }}
        />
      </div>
    </div>
  )
}

// LostProspectInner.defaultProps = {
//   onCancel: () => {},
//   onClose: () => {},
//   onChange: () => {},
//   widgetMode: false,
//   reasonLostUid: undefined
// }

// LostProspectInner.propTypes = {
//   processType: shape({}),
//   reasonLostUid: string,
//   onSelectedReasonLost: func.isRequired
// }

// LostProspectInner.defaultProps = {
//   reasonLostUid: undefined,
//   processType: undefined
// }
