/* eslint-disable */
import React from 'react'
import { from } from 'zen-observable'

export function isEmptyData(data) {
  if (typeof data === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true
    } else if (!data) {
      return true
    }
    return false
  } else if (typeof data === 'string') {
    if (!data.trim()) {
      return true
    }
    return false
  } else if (typeof data === 'undefined') {
    return true
  } else {
    return false
  }
}

function convertJsonToTableString(data) {
  let jsonArray = []
  if (Array.isArray(data)) {
    jsonArray = data
  } else {
    if (data && typeof data === 'object') jsonArray.push(data)
  }
  let tables = ''
  jsonArray.map(item => {
    let keys = Object.keys(item)
    let bodyRows = ''
    keys.map(function(key) {
      bodyRows += `<tr>`
      bodyRows += `<th>${key ? key : ''}</th>`
      bodyRows += `<td class='dont-break-out'>${
        item[key] ? JSON.stringify(item[key]) : ''
      }</td>`
      bodyRows += `</tr>`
    })
    tables += `<table cellpadding="2"><tbody>${bodyRows}</tbody></table>`
    if (jsonArray.length > 1) {
      tables += '<hr/>'
    }
  })
  return tables
}

export const JsonToTable = props => {
  const tables = convertJsonToTableString(props.json)
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: tables
      }}
    />
  )
}

export function jsonSyntaxHighlight(jsonParam) {
  let json = jsonParam
  if (!jsonParam) {
    return undefined
  }
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, 2)
  }
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
  return json.replace(
    // eslint-disable-next-line
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    match => {
      let cls = 'number'
      // eslint-disable-next-line
      if (/^"/.test(match)) {
        // eslint-disable-next-line
        if (/:$/.test(match)) {
          cls = 'key'
        } else {
          cls = 'string'
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean'
      } else if (/null/.test(match)) {
        cls = 'null'
      }
      return '<span class="' + cls + '">' + match + '</span>'
    }
  )
}

export function pad(num) {
  return ('0' + num).slice(-2)
}
export function hhmmss(sec) {
  if (!sec || isNaN(Number(sec))) {
    return undefined
  }
  let secs = sec
  let minutes = Math.floor(secs / 60)
  secs %= 60
  const hours = Math.floor(minutes / 60)
  minutes %= 60
  return `${hours > 0 ? `${hours}:` : ``}${pad(minutes)}:${pad(secs)}`
}

export function compareDateFn(field) {
  return (a, b) => {
    var da = a[field] ? moment(a[field]) : null
    var db = a[field] ? moment(a[field]) : null
    if (da != null && db != null) {
      return db.diff(da)
    }
    return 0
  }
}
export default []
