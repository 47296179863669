/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved, camelcase */
import { delay } from 'redux-saga'
import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_SUCCESS,
  SAVE_NEW_ACCOUNT,
  SAVE_NEW_ACCOUNT_SUCCESS,
  SAVE_UPDATE_ACCOUNT,
  SAVE_UPDATE_ACCOUNT_SUCCESS,
  ACCOUNT_FIELDS_UPDATED,
  ADD_CONTACT_EMAIL,
  MARK_AS_CUSTOMER,
  SAVE_ACCOUNT_ERROR,
  accountFieldsUpdated,
  toggleAccountIsLoading,
  MARK_CONTACT_VERIFIED
} from 'crm-duxs/account-reducer'

import {
  SET_CURRENT_FILTER_VALUE,
  UPDATE_FILTER,
  TOGGLE_FILTER,
  TOGGLE_FILTER_SUCCESS,
  getIsEnableFilterStatus,
  INIT_ACCOUNTS_MODULE,
  SEARCH_PLACE,
  SET_NEAR_BY_RESULT,
  GOTO_NEW_ACCOUNT
} from 'crm-duxs/accounts-reducer'

import { FETCH_ACCOUNTS_LIST_COUNT } from 'crm-duxs/accounts-list-reducer'

import { CRM_CURRENT_USER_CHANGED } from 'crm-duxs/crm-reducer'
import accountsApi from 'crm-api/accounts-api'
import crmApi from 'crm-api/crm-api'

import { call, put, takeLatest, select } from 'redux-saga/effects'

function* fetchAccount(action) {
  yield put(toggleAccountIsLoading(true))
  yield put({
    type: FETCH_ACCOUNT_SUCCESS,
    payload: {
      account: undefined
    }
  })
  // Get Light version first
  let account = yield call(
    accountsApi.getAccountLight,
    action.payload.accountId
  )
  yield put({
    type: FETCH_ACCOUNT_SUCCESS,
    payload: {
      account
    }
  })
  // Get details
  account = yield call(accountsApi.getAccountApi, action.payload.accountId)
  yield put({
    type: FETCH_ACCOUNT_SUCCESS,
    payload: {
      account
    }
  })
  yield put(toggleAccountIsLoading(false))
}

function* createAccount(action) {
  console.log('createAccount Saga', action)
  const account = action.payload.account
  // Normalize account
  const normalizedAccount = {
    name: account.name,
    name_local: account.name_local,
    territory_uid: account.territory_uid,
    industry_uid: account.industry_uid,
    lead_campaign_uid: account.lead_campaign_uid,
    lead_in_date: account.lead_in_date,
    registration_number: account.registration_number,
    cams_username: account.cams_username,
    is_assign_to_me: account.is_assign_to_me,
    addresses: account.addresses,
    website: account.website,
    contact_details: [],
    persons: account.persons,
    phone: account.phone_value ? { value: account.phone_value } : undefined,
    mobile_phone: account.mobile_phone_value
      ? { value: account.mobile_phone_value }
      : undefined,
    email: account.email_value ? { value: account.email_value } : undefined
  }
  try {
    const accountSaved = yield call(accountsApi.createAccountApi, {
      account: normalizedAccount
    })
    // Instead of showing account - redirect to this account's page
    yield put(push('/crm/account/' + accountSaved.account_uid))
    yield put({
      type: SAVE_NEW_ACCOUNT_SUCCESS,
      payload: {
        account: accountSaved
      }
    })
  } catch (err) {
    console.error('Save new accounts error')
    yield put({
      type: SAVE_ACCOUNT_ERROR,
      payload: {
        saveError: 'Error message'
      }
    })
  }
}

function* updateAccount(action) {
  console.log('updateAccount Saga')
  const accountUpdated = yield call(
    accountsApi.updateAccount,
    action.payload.account
  )
  console.log('update result')
  toastr.success('Account has been updated')
  // Instead of showing account - redirect to this account's page
  yield put(push('/crm/account/' + accountUpdated.account_uid))
  yield put({
    type: SAVE_UPDATE_ACCOUNT_SUCCESS,
    payload: {
      account: accountUpdated
    }
  })
}

function* accountFieldsUpdatedSaga(action) {
  console.log('accountFieldsUpdated SAGA')
  // Needs to reload accout and update only some fields
  const account = yield call(
    accountsApi.getAccountApi,
    action.payload.accountUid
  )
  console.log('accountFieldsUpdated SAGA result')
  yield put({
    type: FETCH_ACCOUNT_SUCCESS,
    payload: {
      account
    }
  })
  if (action.payload.onSuccess) {
    action.payload.onSuccess(account)
  }
}

function* updateAccountFilter(action) {
  const conditions = action.payload.conditions.filter(
    condition => condition.condition_value !== ''
  )
  yield put({
    type: SET_CURRENT_FILTER_VALUE,
    payload: {
      filterName: action.payload.filterName,
      conditions,
      accountsListCode: action.payload.accountsListCode
    }
  })

  // Reload stats
  yield put({
    type: FETCH_ACCOUNTS_LIST_COUNT,
    payload: {
      accountsListCode: action.payload.accountsListCode
    }
  })
}

function* toggleFilter(action) {
  // eslint-disable-next-line
  const disableFilterStatus = yield select(
    getIsEnableFilterStatus,
    action.payload.filterStatusName,
    action.payload.disableFilters,
    action.payload.accountsListCode
  )

  yield put({
    type: TOGGLE_FILTER_SUCCESS,
    payload: action.payload
  })
}

function* initAccounts() {
  // const user = yield select(getUser)
  // if (!user) return
}

function* handleCurrentUserChanged() {
  // Init module
  console.log('Accounts Saga handleCurrentUserChanged')
  yield call(initAccounts)
}

function* addContactEmail(action) {
  yield call(accountsApi.addContactDetailsAccount, action.payload.accountUid, [
    { kind: 'email', value: action.payload.email }
  ])
  yield put(
    accountFieldsUpdated(action.payload.accountUid, ['contact_details'])
  )
  toastr.success('Email has been saved')
}

function* searchPlace(action) {
  yield delay(500)
  const result = yield accountsApi.searchPlaceApi(action.payload.query)
  yield put({
    type: SET_NEAR_BY_RESULT,
    payload: {
      result,
      query: action.payload.query,
      accountsListCode: action.payload.accountsListCode
    }
  })
}

function* markAsCustomer(action) {
  // eslint-disable-next-line
  const isConfirm = yield confirm(
    'Are you sure you want to mark this account as Customer ?'
  )
  if (isConfirm) {
    const account = yield call(
      crmApi.executeFunction,
      'convert_account_to_customer',
      {
        account_uid: action.payload.accountUid
      }
    )
    yield put(accountFieldsUpdated(account.payload.accountUid))
    toastr.success('This account was converted to Customer')
  }
}

function* markContactVerified(action) {
  console.log('markContactVerified SAGA')
  yield call(
    accountsApi.verifyContact,
    action.payload.contact,
    action.payload.verified
  )
  yield put(accountFieldsUpdated(action.payload.contact.account_uid))
  if (action.payload.onSuccess) {
    action.payload.onSuccess()
  }
}

function* gotoNewAccount() {
  console.log('gotoNewAccount SAGA')
  yield put(push('/crm/account/new?r=' + Math.random()))
}

export default function* accountsSaga() {
  yield takeLatest(UPDATE_FILTER, updateAccountFilter)
  yield takeLatest(TOGGLE_FILTER, toggleFilter)
  yield takeLatest(FETCH_ACCOUNT, fetchAccount)
  yield takeLatest(SAVE_NEW_ACCOUNT, createAccount)
  yield takeLatest(SAVE_UPDATE_ACCOUNT, updateAccount)
  yield takeLatest(ACCOUNT_FIELDS_UPDATED, accountFieldsUpdatedSaga)
  yield takeLatest(CRM_CURRENT_USER_CHANGED, handleCurrentUserChanged)
  yield takeLatest(INIT_ACCOUNTS_MODULE, initAccounts)
  yield takeLatest(ADD_CONTACT_EMAIL, addContactEmail)
  yield takeLatest(SEARCH_PLACE, searchPlace)
  yield takeLatest(MARK_AS_CUSTOMER, markAsCustomer)
  yield takeLatest(MARK_CONTACT_VERIFIED, markContactVerified)
  yield takeLatest(CRM_CURRENT_USER_CHANGED, handleCurrentUserChanged)
  yield takeLatest(GOTO_NEW_ACCOUNT, gotoNewAccount)

  yield call(initAccounts)
}
