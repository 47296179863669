import React, { Component } from 'react'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import { object, string, func, bool, arrayOf } from 'prop-types'
import { Provider, connect } from 'react-redux'
import moment from 'moment'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch, Redirect } from 'react-router'
// import { addLocaleData } from 'react-intl'
import ReduxToastr, { toastr } from 'react-redux-toastr'
import ReactTouchEvents from 'react-touch-events'
import PrintProvider from 'react-easy-print'

import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'

import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'

import { onError } from 'apollo-link-error'
// import { th } from '@formatjs/intl-relativetimeformat/dist/locale-data/th'
// import { en } from '@formatjs/intl-relativetimeformat/dist/locale-data/en'
// import { pl } from '@formatjs/intl-relativetimeformat/dist/locale-data/pl'
import CrmApolloHttpLink from './api/crm-apollo-http-link'

import App from 'crm-modules/app/app'

import ErrorReportingModal, {
  ToastApiErrorViewButton
} from 'crm-modules/app/report-error-modal'

import errorReport from './api/crm-api-warning'
import crmRoutes from './crm-routes.jsx'
import CrmLogin from './crm-login.jsx'

import { IntlProvider } from 'react-intl'
import {
  getLocale,
  updateMessages,
  getMessages,
  getTranslationMessages
} from 'crm-duxs/locale'
import { pushError } from 'crm-duxs/error-reporting'
import {
  getHasSideWindow,
  touchOutsideNavigationBar,
  getUser,
  getCrmInitializationStatus,
  initialCrm,
  getMyPermissions2019,
  getSelectedInstance
} from 'crm-duxs/crm-reducer'
import CrmInitialForm from './crm-initial.jsx'
import './styles/index.scss'

import { AnalyticsContextProvider } from './core/analytics/context.tsx'
import { GeneralPermissionsContextProvider } from '@cartrack-crm/crm-core'
import { CrmAuthContext } from './core/auth/auth-context'
import { CrmContextProvider } from './core/contexts/crm-context'
import {
  CrmToastrContextProvider,
  CrmToastr
} from '@cartrack-crm/ui/src/toastrs'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons/faMapMarker'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons/faMoneyCheckAlt'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage'
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand'
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList'
import { faHeadset } from '@fortawesome/free-solid-svg-icons/faHeadset'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile'
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare'
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay'
import { faStickyNote } from '@fortawesome/free-solid-svg-icons/faStickyNote'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap'
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake'

import 'moment/locale/th'
import 'moment/locale/en-sg'
import 'moment/locale/en-gb'
import 'moment/locale/pt'
import 'moment/locale/pl'
import './utils/open-app'
import { BulkOperationsProvider } from 'crm-modules/accounts/bulk-operations/bulk-operations-provider'

library.add(faHistory)
library.add(faUser)
library.add(faUsers)
library.add(faPencilAlt)
library.add(faPlus)
library.add(faUserPlus)
library.add(faCheck)
library.add(faTimes)
library.add(faAngleDown)
library.add(faPhone)
library.add(faMapMarker)
library.add(faMapMarkerAlt)
library.add(faAddressCard)
library.add(faMinus)
library.add(faTrash)
library.add(faInfo)
library.add(faLightbulb)
library.add(faFilter)
library.add(faMoneyCheckAlt)
library.add(faLock)
library.add(faLanguage)
library.add(faExpand)
library.add(faCompress)
library.add(faStopwatch)
library.add({
  faClipboardList,
  faHeadset,
  faClock,
  faMapMarkedAlt,
  faFile,
  faShare,
  faPlay,
  faStickyNote,
  faEnvelope,
  faPaperclip,
  faDollarSign,
  faGraduationCap,
  faHandshake
})
class AppRouter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadedLocaleKey: '',
      loadedLocale: ''
    }

    this.errors = []
    this.redirectUri = new URL(window.location.href)
  }

  handleApiError = error => {
    const apiError = JSON.parse(JSON.stringify(error))
    const toastrOptions = {
      timeOut: 3000
    }

    toastr.info('Found some errors', toastrOptions)
    this.props.onApiError(apiError)
  }

  handleTap = () => {
    this.props.onTouchOutsideNav()
  }

  componentWillMount () {
    this.props.initialCrm()
  }

  componentDidMount () {
    this.loadLocaleData(this.props.locale)
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.locale !== nextProps.locale)
      this.loadLocaleData(nextProps.locale)
    if (this.state.loadedLocale !== nextState.loadedLocale) return true
    if (this.props.hasSideWindow !== nextProps.hasSideWindow) return true
    if (this.props.messages !== nextProps.messages) return true
    if (this.props.messagesFromServer !== nextProps.messagesFromServer)
      return true
    if (
      this.props.appInitializationStatus !== nextProps.appInitializationStatus
    )
      return true
    if (this.props.instance !== nextProps.instance) return true

    return false
  }

  loadLocaleData = locale => {
    if (locale === undefined) return

    let localeKey = locale

    if (typeof locale === 'string' && locale.indexOf('-') >= 0)
      localeKey = locale.split('-')[0]

    const data = [
      import(`@formatjs/intl-relativetimeformat/dist/locale-data/${localeKey}`),
      import(`../../locales/${localeKey}.json`)
    ]

    if (window.IntlPolyfill)
      data.push(import(`intl/locale-data/jsonp/${localeKey}`))

    Promise.all(data).then(([localeData, messagesCrm, messagesFleet]) => {
      const messsageCombine = Object.assign({}, messagesCrm, messagesFleet)

      this.props.updateMessages(messsageCombine)
      this.setState({
        loadedLocaleKey: localeKey,
        loadedLocale: locale
      })
    })
  }

  gotoRedirectUri = () => {
    if (this.props.hotReload) return

    const historyUri = this.props.history.location.pathname
    const homeUri = '/crm/dashboard/me'
    let uri

    if (this.redirectUri) {
      uri = this.redirectUri.pathname + this.redirectUri.search
      this.redirectUri = undefined
    }

    if (
      [undefined, '/', '/login', '/login/', '/crm/initial'].includes(historyUri)
    ) {
      const search = this.props.history.location.search

      if (search.includes('?redirectUri')) {
        uri = search.split('?redirectUri=')[1]
        if (uri === '/') uri = homeUri
      } else {
        uri = homeUri
      }
    }

    if (uri) this.props.history.push(uri)
  }

  _renderCrmRoute = () => {
    if (this.props.appInitializationStatus) return crmRoutes

    const nextPath = this.redirectUri.pathname
    const search = this.redirectUri.search
    const splitSearch = search.split('?redirectUri=')
    const newSearch = splitSearch[splitSearch.length - 1]
    const newNextPath =
      nextPath === '/login/' || nextPath === '/login' || nextPath === '/'
        ? ''
        : nextPath
    const uri = encodeURI(newNextPath + newSearch)

    if (this.props.user) return null

    if (uri && !['/', '/login'].includes(uri)) {
      return <Redirect exact from="*" to={`/login/?redirectUri=${uri}`} />
    }

    return <Redirect exact from="*" to={`/login`} />
  }

  intlErrorFunction = data => { }

  render () {
    if (!this.state.loadedLocale) return null

    const timeZone =
      this.props.instance &&
        this.props.instance.parameters &&
        this.props.instance.parameters.timeZone
        ? this.props.instance.parameters.timeZone
        : undefined

    const messagesTranslation = {
      ...this.props.messages,
      ...this.props.messagesFromServer
    }

    const currentLocale =
      this.props.locale === 'en' || this.props.locale === 'en-US'
        ? 'en-GB'
        : this.props.locale
    const crmAuthContextValue = { crmCurrentUser: this.props.user }

    const crmRoute = this._renderCrmRoute()
    moment.locale(currentLocale)

    return (
      <IntlProvider
        key={this.state.loadedLocale}
        locale={currentLocale}
        messages={messagesTranslation}
        timeZone={timeZone}
        onError={this.intlErrorFunction}
      >
        <div>
          <PrintProvider loose>
            <AnalyticsContextProvider>
              <GeneralPermissionsContextProvider
                my_permission={this.props.permissions}
              >
                <CrmAuthContext.Provider value={crmAuthContextValue}>
                  <CrmContextProvider>
                    <CrmToastrContextProvider>
                      <ConnectedRouter history={this.props.history}>
                        <div
                          id="router-root"
                          className={`router-root CrmApp ${this.props.hasSideWindow
                              ? 'CrmApp--hasSideWindow'
                              : ''
                            }`}
                        >
                          {this.props.appInitializationStatus && (
                            <Route path="*" component={App} />
                          )}
                          <ReactTouchEvents onTap={this.handleTap}>
                            <div className="App-Contents">
                              {crmRoute === null && <CrmInitialForm />}
                              {crmRoute !== null && (
                                <Switch>
                                  <BulkOperationsProvider>
                                    <Route
                                      exact
                                      path="/login"
                                      component={CrmLogin}
                                    />
                                    {crmRoute}
                                    {this.props.appInitializationStatus &&
                                      this.gotoRedirectUri()}
                                  </BulkOperationsProvider>
                                </Switch>
                              )}
                            </div>
                          </ReactTouchEvents>
                          <ErrorReportingModal />
                          <CrmToastr />
                        </div>
                      </ConnectedRouter>
                    </CrmToastrContextProvider>
                  </CrmContextProvider>
                </CrmAuthContext.Provider>
              </GeneralPermissionsContextProvider>
            </AnalyticsContextProvider>
          </PrintProvider>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
          />
        </div>
      </IntlProvider>
    )
  }
}

AppRouter.propTypes = {
  history: object.isRequired,
  locale: string.isRequired,
  messages: object.isRequired,
  updateMessages: func.isRequired,
  hasSideWindow: bool.isRequired,
  hotReload: bool.isRequired,
  onTouchOutsideNav: func.isRequired,
  onApiError: func.isRequired,
  instance: object,
  user: object,
  messagesFromServer: object,
  appInitializationStatus: bool.isRequired,
  permissions: arrayOf(object).isRequired
}
AppRouter.defaultProps = {
  instance: undefined,
  messagesFromServer: undefined
}

function mapStateToProps (state) {
  return {
    user: getUser(state),
    locale: getLocale(state),
    messages: getMessages(state),
    messagesFromServer: getTranslationMessages(state),
    hasSideWindow: getHasSideWindow(state),
    appInitializationStatus: getCrmInitializationStatus(state),
    permissions: getMyPermissions2019(state),
    instance: getSelectedInstance(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onApiError: error => dispatch(pushError(error)),
    onTouchOutsideNav: () => dispatch(touchOutsideNavigationBar()),
    updateMessages: messages => dispatch(updateMessages(messages)),
    initialCrm: () => dispatch(initialCrm())
  }
}

const AppRouterWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRouter)

const dataIdFromObject = object => {
  switch (object.__typename) {
    case 'Opportunity':
      return object.opportunity_uid
    case 'Account':
      if (!object.account_uid) {
        return defaultDataIdFromObject(object)
      }

      return object.account_uid
    case 'Process':
      if (!object.process_uid) {
        return defaultDataIdFromObject(object)
      }

      return object.process_uid
    case 'Activity':
      if (!object.activity_uid) {
        return defaultDataIdFromObject(object)
      }

      return object.activity_uid
    default:
      return defaultDataIdFromObject(object)
  }
}

let crmApolloClient

const AppWithApollo = props => {
  if (!crmApolloClient) {
    const ErrorLink = onError(data => {
      const { graphQLErrors, networkError, response, operation } = data

      if (graphQLErrors) {
        errorReport({
          error: graphQLErrors,
          request: operation,
          response: response,
          tags: { type: 'graphql-hoc' }
        })

        if (graphQLErrors.map) {
          graphQLErrors.map(({ message, locations, path }) => {
            if (message === 'refresh_token_revoke') {
              window.crmLogout('Refresh Token Expired: AppWithApollo')
            }
          })
        }
      }

      if (networkError) {
        const statusCode = networkError.statusCode

        if (statusCode === 401) {
          props.doLogout()
        }
      }
    })

    const link = ApolloLink.from([ErrorLink, new CrmApolloHttpLink({})])

    crmApolloClient = new ApolloClient({
      link,
      cache: new InMemoryCache({ dataIdFromObject }) // dataIdFromObject
    })
  }

  return (
    <ApolloProvider client={crmApolloClient}>
      <ApolloHooksProvider client={crmApolloClient}>
        <AppRouterWithRedux
          history={props.history}
          hotReload={props.hotReload}
        />
      </ApolloHooksProvider>
    </ApolloProvider>
  )
}

const AppWithApolloConnected = connect(undefined, dispatch => ({
  doLogout: () => dispatch({ type: 'LOG_OUT' })
}))(AppWithApollo)

const AppRoot = props => (
  <Provider store={props.store}>
    <AppWithApolloConnected {...props} />
  </Provider>
)

AppRoot.propTypes = {
  store: object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  hotReload: bool.isRequired
}

export default AppRoot
