/* eslint-disable camelcase */
import React from 'react'
import columns from './quotation-to-approve-columns.jsx'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

const qlOptions = {
  options: ownProps => ({
    variables: {
      filter: {
        process__current_step_type_code: 'awaitingApproval',
        process__completed_time: 'null',
        is_deleted: false
      },
      fields: [
        'account_uid',
        'document_uid',
        'account__name',
        'account__owner__full_name',
        'process__name',
        'name',
        'contract_length',
        'process__current_step_type_code'
      ],
      rootType: 'DocumentsListQM'
    }
  }),
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  })
}

const Table = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions
})

class QuotationToApproveListInner extends React.Component {
  tableDataRefresh = refreshFunction => {
    this.refreshData = refreshFunction
  }

  getColumns = () => {
    const columnsWithAction = columns.slice()

    // columnsWithAction.push({
    //   Header: 'Quotations',
    //   accessor: 'total_quotation'
    // })

    // columnsWithAction.push({
    //   Header: 'Watinng approve',
    //   accessor: 'quotation_waiting',
    //   Cell: row =>
    //     row.original.quotation_waiting ? (
    //       <i className="fa fa-check-circle" style={{ color: 'red' }} />
    //     ) : (
    //       ''
    //     )
    // })

    // columnsWithAction.push({
    //   Header: 'Action',
    //   accessor: 'opportunity_is_deleted',
    //   Cell: row => (
    //     <div>
    //       {row.original.opportunity_is_deleted ? (
    //         <CrmButton
    //           className="remove-button"
    //           xsmall
    //           label="Undelete"
    //           // eslint-disable-next-line
    //           onClick={() =>
    //             this.onHandleUndelete(row.original.opportunity_uid)}
    //         />
    //       ) : (
    //         <CrmButton
    //           className="remove-button"
    //           xsmall
    //           icon="trash"
    //           // eslint-disable-next-line
    //           onClick={() => this.onHandleDelete(row.original.opportunity_uid)}
    //         />
    //       )}
    //     </div>
    //   ),
    //   maxWidth: 80

    return columnsWithAction
  }

  render() {
    const getTdProps = (state, rowInfo, column) => ({
      onClick: (e, handleOriginal) => {
        if (column.Header !== 'Action' && this.onRowClick) {
          this.onRowClick(e, rowInfo)
        }
        handleOriginal()
      }
    })

    console.log('render quotation List Inner')

    return (
      <Table
        {...this.props}
        style={{ width: '100%' }}
        getTdProps={getTdProps}
        columns={this.getColumns()}
        className="react-table -striped -highlight"
        tableName="opportunities"
        getRefreshFunc={this.tableDataRefresh}
        translation
      />
    )
  }
}

QuotationToApproveListInner.propTypes = {
  ...cqCommandPropTypes
}
export default crmGenericCommandQlHoc(QuotationToApproveListInner)
