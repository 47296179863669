import React from 'react'
import { number } from 'prop-types'
import { injectIntl } from 'react-intl'

export function formatTemperature(intl, value) {
  return (
    (intl.messages['units.temperature'] === 'fahrenheit'
      ? value * (9 / 5) + 32
      : value
    ).toFixed(0) + '°'
  )
}

const FormattedTemperature = props => (
  <span>{formatTemperature(props.intl, props.value)}</span>
)

FormattedTemperature.propTypes = {
  value: number.isRequired
}

export default injectIntl(FormattedTemperature)
