import React from 'react'
import { CrmModal } from 'crm-components'
import { useCrmComponent } from 'crm-utils/crm-component-util'
import { GenericAnalyticsWidget } from '@cartrack-crm/crm-analytics'
import { LoadingIcon } from 'util-components'

const SchedulePreview = (props) => {
  const { getOneByUid } = useCrmComponent(props)
  const { component, loading } = getOneByUid(props.dashboardUid)
  const widgetDefinition = component

  return (
    <CrmModal
      size="xl"
      title="Preview Schedule"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <div style={{ padding: 20 }}>
        {loading && <LoadingIcon />}
        {!loading && (
          <GenericAnalyticsWidget
            widgetDefinition={widgetDefinition}
            filters={props.filter}
          />
        )}
      </div>
    </CrmModal>
  )
}

export default SchedulePreview
