import React from 'react'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'
import { Dropdown } from 'util-components'
import { getLocale } from 'crm-duxs/locale'

const LanguageSelect = props => {
  return (
    <Dropdown
      title="language"
      options={['en', 'es', 'th', 'pt', 'pl']}
      activeOption={props.activeOption ? props.activeOption : props.locale}
      onChange={props.onChange}
      extraClassNames={{
        containerClassNames: 'Login-localeDropdown'
      }}
    />
  )
}

LanguageSelect.propTypes = {
  locale: string.isRequired,
  onChange: func.isRequired,
  activeOption: string.isRequired
}
LanguageSelect.defaultProps = {}

function mapStateToProps(state) {
  return {
    locale: getLocale(state)
  }
}

export default connect(mapStateToProps, null)(LanguageSelect)
