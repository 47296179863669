import React, { useState } from 'react'
import { string, shape, bool } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { Icon } from 'util-components'
import { useQuery } from '@apollo/react-hooks'

export const QL_LIST_ORGANIZATION_UNITS = gql`
  query organizationUnits($organizationStructureUid: String!) {
    organizationUnits(organizationStructureUid: $organizationStructureUid) {
      organization_unit_uid
      name
      parent_department_uid
      manager_user_uid
      department_uid
      is_deleted
    }
  }
`

const CrmOrganizationUnitSelect = props => {
  console.log(
    'Render CrmOrganizationUnitSelect',
    props.organizationStructureUid,
    props
  )
  const variables = { organizationStructureUid: props.organizationStructureUid }

  const [showInactive, setShowInactive] = useState(false)
  const handleShowInactive = () => {
    setShowInactive(!showInactive)
  }
  const toolboxRenderer = () => (
    <div>
      <Icon
        name="archive"
        title="Show deleted department"
        style={{
          position: 'absolute',
          top: '12px',
          right: '30px',
          opacity: showInactive ? '1.0' : '0.3',
          fontSize: '14px'
        }}
        onClick={handleShowInactive}
      />
    </div>
  )
  return (
    <Query
      query={QL_LIST_ORGANIZATION_UNITS}
      variables={variables}
      skip={!props.organizationStructureUid}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data, refetch }) => {
        console.log('CrmOrganizationUnitSelect', data, props)
        const options =
          data && data.organizationUnits
            ? data.organizationUnits
                .filter(v => (showInactive ? true : !v.is_deleted))
                .map(v => ({
                  ...v,
                  value: v.organization_unit_uid
                }))
            : []
        console.log('CrmOrganizationUnitSelectoptions', options)
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={props.placeholder ? props.placeholder : 'Organization'}
            toolboxRenderer={toolboxRenderer}
          />
        )
      }}
    </Query>
  )
}

CrmOrganizationUnitSelect.propTypes = {
  placeholder: string,
  labeled: bool,
  organizationStructureUid: string.isRequired,
  input: shape({})
}

CrmOrganizationUnitSelect.defaultProps = {
  labeled: false
}

export default CrmOrganizationUnitSelect
