/* eslint-disable new-cap */
import { string } from 'prop-types'
import { qlqAccountPersonList } from 'crm-data/accounts'
import { CrmGenericSelectorFactory } from 'crm-components'

const AccountPersonSelect = CrmGenericSelectorFactory({
  qlQuery: qlqAccountPersonList,
  variablesMapHandler: ownProps => ({
    account_uid: ownProps.accountUid // eslint-disable-line
  }),
  itemValueField: 'account_person_uid',
  itemLabelField: 'name'
})
AccountPersonSelect.propTypes = {
  accountUid: string.isRequired
}
export default AccountPersonSelect
