export const getDefaultDispositionForStatus = (
  dispositionTypes,
  activityStatus
) => {
  const defaultDisposition =
    activityStatus && dispositionTypes
      ? dispositionTypes.find(
          dt =>
            dt.pbx_status_code === activityStatus.code && dt.is_default === true
        )
      : undefined
  console.log('found defaultDisposition', defaultDisposition)
  return defaultDisposition
}

export const mock = 1
