import styled from 'styled-components'

export const AddressStyled = styled.div`
  .SectionAddress {
    color: #333;
    font-size: 12px;
  }

  .AddressFormHeader {
    margin: 10px 0px;
    text-align: center;
    font-weight: bold;
  }

  .AddNewAddressIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 55px;
    font-size: 2em;
  }

  .AddressItem {
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    opacity: 0.5;
    text-align: center;

    &__ContentWrapper {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    &__ContentAction {
      margin: 5px 8px 0px 0px;
      font-size: 18px;
      width: 30px;
    }

    &__Content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      margin: 10px 10px;

      .Headquater {
        font-weight: bold;
      }

      .Addr1 {
        padding: 10px;
        border-radius: 5px;
      }

      .City {
        font-size: 18px;
        text-align: right;
      }
    }

    &:hover {
      opacity: 1;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }

    &__SelectedIcon {
      color: #5bae5f;
    }
  }

  .SelectedItem {
    opacity: 1;
  }
`
