import React, { useState } from 'react'
import styles from '../../modules/dashboard/dashboards/filters/dashboard-filters.scss'
import { func, shape, bool } from 'prop-types'
import classNames from 'classnames'
import enhanceWithClickOutside from 'react-click-outside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { useAnalyticsContext } from 'crm-core/analytics/context'
import { isEmptyData } from 'crm-utils/common-utils'
import { FiltersOptionList } from '@cartrack-crm/crm-filter-section/src/tools'

const OrderStatusSelectOptions = (props) => {
  const options = [
    {
      name: 'Pending',
      value: 'pending',
      qfilter: {
        status_code: 'pending'
      }
    },
    {
      name: 'Completed',
      value: 'completed',
      qfilter: {
        status_code: 'completed'
      }
    },
    {
      name: 'Canceled',
      value: 'canceled',
      qfilter: {
        status_code: 'canceled'
      }
    },
    {
      name: 'Repaired',
      value: 'repaired',
      qfilter: {
        status_code: 'repaired'
      }
    }
  ]

  return (
    < FiltersOptionList
      options={options}
      onFilterValueChanged={props.onFilterValueChanged}
      selectedValue={
        props.filterValues && props.filterValues.status_code
          ? props.filterValues.status_code
          : undefined
      }
    />
  )
}

class GeneralFilterDropdown extends React.PureComponent<any, any> {
  static propTypes = {
    filterValues: shape({}),
    onSetFilters: func.isRequired,
    isExpanded: bool.isRequired,
    onClickedOutside: func.isRequired,
    onFilterValueChanged: func,
    instanceSelected: shape({})
  }

  myRef: any

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      filterValues: props.filterValues ? { ...props.filterValues } : {}
    }
  }
  componentDidUpdate () {
    this.recalculatePopupMaxWidth()
  }
  componentDidMount () {
    window.addEventListener('resize', this.recalculatePopupMaxWidth)
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.recalculatePopupMaxWidth)
  }
  componentWillReceiveProps = (nextProps) => {
    if (JSON.stringify(nextProps.filterValues) !== JSON.stringify(this.props.filterValues)) {
      this.setState({ filterValues: { ...nextProps.filterValues } })
    }
  }
  handleClickOutside = () => {
    if (this.props.isExpanded) {
      this.saveValue()

      setTimeout(this.props.onClickedOutside, 5)
    }
  }
  handleClickApply = () => {
    this.handleClickOutside()
  }
  handleClickCancel = () => {
    setTimeout(this.props.onClickedOutside, 5)
  }
  saveValue = () => {
    this.props.onSetFilters(this.state.filterValues)
  }
  handleFilterValueChanged = (field) => (newValue) => {

    let newFilterValues = { ...this.state.filterValues }

    if (!newFilterValues) {
      newFilterValues = {}
    }
    if (newFilterValues[field] == newValue?.label) {
      newFilterValues[field] = undefined
    }
    else {
      newFilterValues[field] = newValue ? newValue.value.toLowerCase() : undefined
    }

    this.setState({
      filterValues: newFilterValues
    })

  }

  handleClearFilters = () => {
    const newFilterValues = { ...this.state.filterValues, status_code: null }
    this.setState({
      filterValues: newFilterValues
    })
  }

  handleClearFilter = (filterName) => {
    const newFilterValues = { ...this.state.filterValues }

    newFilterValues['status_code'] = undefined

    this.setState({
      filterValues: newFilterValues
    })
  }

  recalculatePopupMaxWidth = () => {
    if (this.myRef && this.myRef.current) {
      const left = this.myRef.current.getBoundingClientRect().left
      this.myRef.current.style.maxWidth = `calc(100vw - ${left + 20}px)`
    }
  }

  hasValueByFilterName = (name) => {
    return (
      this.state.filterValues &&
      this.state.filterValues[name] &&
      this.state.filterValues[name].value &&
      this.state.filterValues[name].value.length > 0
    )
  }

  getValueByFilterName = (name) => {
    return this.state.filterValues &&
      this.state.filterValues[name] &&
      this.state.filterValues[name].value &&
      this.state.filterValues[name].value[0]
      ? this.state.filterValues[name].label
      : ''
  }

  handleOthersSelected = (value, item) => {
    const filterValue = {
      value: [item.value],
      label: item.name,
      item: item
    }
    this.handleFilterValueChanged(item.value)(filterValue)
  }

  _renderOrderStatus = () => {
    return (
      <div className={styles.DashboardFilters__Dropdown__Group}>
        <div className={styles.DashboardFilters__Dropdown__GroupHeader} style={{ width: 150 }}>
          <div
            className={`${styles.DashboardFilters__Dropdown__ClearFilter}`}
            onClick={() => this.handleClearFilter('status_code')}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className={styles.DashboardFilters__Dropdown__GroupLabel}>Status</div>
          <div className={styles.DashboardFilters__Dropdown__Value}>
            {this.state.filterValues && this.state.filterValues.status_code
              ? this.state.filterValues.status_code.charAt(0).toUpperCase() + this.state.filterValues.status_code.slice(1)
              : 'All'}
          </div>
        </div>
        <div className={styles.DashboardFilters__Dropdown__OptionsWrapper}>
          <OrderStatusSelectOptions
            onFilterValueChanged={this.handleFilterValueChanged('status_code')}
            filterValues={this.state.filterValues}
          />
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className={`${styles.DashboardFilters__Dropdown} `} ref={this.myRef}>
        <div className={styles.DashboardFilters__Dropdown__Container}>
          <div className={styles.DashboardFilters__Dropdown__Body}>
            {this._renderOrderStatus()}

            {/* Clear Filter */}
            <div className={`${styles.DashboardFilters__Dropdown__Group}`}>
              <div
                className={`util-textCenter ${styles.DashboardFilters__Dropdown__GroupHeader}`}
                style={{ width: 130 }}
                onClick={this.handleClearFilters}
              >
                <div className="util-flexRowRight">
                  <div>
                    <div className={styles.DashboardFilters__Dropdown__Value}>
                      <FontAwesomeIcon icon={faFilter} />
                    </div>
                    <div>Clear Filter</div>
                  </div>
                </div>
              </div>

              <div className="util-flexGrow" />
              <div className="util-flexRowRight">
                <div
                  className={`${styles.DashboardFilters__Dropdown__Value}  util-marginBottom util-marginRight util-textBold util-textApply`}
                  onClick={this.handleClickApply}
                >
                  Apply
                </div>
                <div
                  className={`${styles.DashboardFilters__Dropdown__Value}  util-marginBottom util-marginLeft util-marginRight util-textBold util-textApply`}
                  onClick={this.handleClickCancel}
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const GeneralFilterDropdownO = enhanceWithClickOutside(GeneralFilterDropdown)

const OrdersFilterSectionValueString = ({ filterValues }) => {

  if (isEmptyData(filterValues)) {
    return <strong>Select Value</strong>
  }
  return (
    <React.Fragment>
      {!filterValues?.status_code && (!filterValues.completed_date || filterValues.completed_date == 'null') && <strong>Select Value</strong>}

      {filterValues && filterValues.status_code && (
        <div>
          <strong>{filterValues && filterValues.status_code.charAt(0).toUpperCase() + filterValues.status_code.slice(1)}</strong>
        </div>
      )}
      {filterValues && filterValues.completed_date?.value && (
        <div>
          Completed: <strong>{filterValues && filterValues.completed_date?.label}</strong>
        </div>
      )}
    </React.Fragment>
  )
}
OrdersFilterSectionValueString.propTypes = {
  filterValues: shape({})
}

export const OrdersFilterSection = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const analyticsContext = useAnalyticsContext()

  const handleShowDropdown = () => setIsExpanded(!isExpanded)
  const handleCloseDropdown = () => setIsExpanded(false)

  const onSetFilters = (value) => {
    analyticsContext.setFilters(value)
  }
  const filterValues = analyticsContext.filters
  return (
    <div
      className={classNames(styles.DashboardFilters__Section, {
        [styles['DashboardFilters__Section--expanded']]: isExpanded
      })}
      style={{ minWidth: 300, ...props.style }}
    >
      <div onClick={handleShowDropdown} className={styles.DashboardFilters__Section__Values}>
        <div className="util-flexRow">
          <div className={`${styles.DashboardFilters__FilterDropdownIconWrapper} util-flexCenterContent`}>
            <i className="fa fa-caret-down" />
          </div>
          <div>
            <div className={styles.DashboardFilters__Section__Label}>Order</div>
            <div className={styles.DashboardFilters__Section__Value}>
              <OrdersFilterSectionValueString filterValues={filterValues} />
            </div>
          </div>
        </div>
      </div>

      <GeneralFilterDropdown
        isExpanded={isExpanded}
        onClickedOutside={handleCloseDropdown}
        onSetFilters={onSetFilters}
        filterValues={filterValues}
      />
    </div>
  )
}
