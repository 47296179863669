import React from 'react'
import cloneDeep from 'clone-deep'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { injectIntl } from 'react-intl'
import { CrmLabeledField, CrmButton } from '@cartrack-crm/ui'
import { AddressStyled } from './section-address.styled'
import AddressItem from './address-item'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'

interface IProps {
  addresses: any
  removeAddressUI: Function
  handleQuotationAddressChange: Function
  isAddingNewAddress: Function
  onStartAddAddress: Function
  account_uid: string
  handleEditEnd: Function
  onCancelAddAddress: Function
  updateAddressesUI: Function
  handleEditStart: Function
  isExpanded: boolean
  intl: any
  _addrFormat: Function
  hideLabel: boolean
  quotation_address: any
}

interface IState {
  addresses: any
  selected_address: any
}

class SectionAddress extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      addresses: [...props.addresses],
      selected_address: undefined
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.addresses !== this.props.addresses) {
      const addresses = cloneDeep(nextProps.addresses)
      this.setState({ addresses })
    }
    if (nextProps.quotation_address !== this.props.quotation_address) {
      this.handleSelectAddress(null, nextProps.quotation_address, false)
    }
  }

  handleClose = (e) => {
    const newItemIndex = this.props.addresses.findIndex((v) => v.isNew === true)
    if (newItemIndex > -1) {
      this.props.removeAddressUI(newItemIndex)
    }
    e.stopPropagation()
    this.props.handleEditEnd()
  }

  handleSelectAddress = (e, address, do_api_update) => {
    this.setState(
      {
        selected_address: address
      },
      () => {
        this.props.handleQuotationAddressChange(address, do_api_update)
      }
    )
  }

  handleStartAddAddress = () => {
    if (this.props.isAddingNewAddress()) {
      return
    }
    this.props.onStartAddAddress()
  }

  handleCancelEditAddressOnMeetingForm = () => {
    const addresses = [...this.state.addresses].map((v) => {
      delete v.isEditing
      return { ...v }
    })
    this.setState({ addresses })
  }

  _renderSelectedAddressLabel = (selected_address) => {
    return <div>{this.props._addrFormat(selected_address)}</div>
  }

  _renderAddressForm = (index, address) => {
    const children = React.Children.map(this.props.children, (child: any) => {
      return React.cloneElement(child, {
        index: index,
        account_uid: this.props.account_uid,
        address: address,
        onCancelAddAddress: this.props.onCancelAddAddress,
        handleCancelEditAddressOnMeetingForm: this
          .handleCancelEditAddressOnMeetingForm,
        removeAddressUI: this.props.removeAddressUI,
        updateAddressesUI: this.props.updateAddressesUI,
        mapAlign: 'left',
        isRequiredValidLocation: true
      })
    })
    return children
  }

  render() {
    console.log('render section-address-inner', this.state, this.props)
    const { addresses, selected_address } = this.state
    const isAddingNewAddress = addresses.find((v) => v.isNew)

    return (
      <AddressStyled>
        <div className={'SectionAddress'}>
          {!this.props.hideLabel && (
            <div>
              <CrmLabeledField lg noMargin>
                <div
                  onClick={(e) => {
                    this.props.handleEditStart(e)
                  }}
                  className={'util-hooverableContent util-flexRow'}
                >
                  {selected_address && (
                    <div>
                      {this._renderSelectedAddressLabel(selected_address)}
                    </div>
                  )}
                  {!selected_address && (
                    <div>
                      {this.props.intl.formatMessage({
                        id: 'crm.activity.select_address',
                        defaultMessage: 'select address'
                      })}
                    </div>
                  )}
                </div>
              </CrmLabeledField>
            </div>
          )}

          {this.props.isExpanded && (
            <React.Fragment>
              <div className="row util-marginTop">
                {addresses.map((address, index) => {
                  if (address.isNew || address.isEditing) {
                    return (
                      <div key={index} className="col-md-12">
                        <div className={'AddressFormHeader'}>
                          {address.isNew &&
                            this.props.intl.formatMessage({
                              id: 'crm.activity.add_new_address',
                              defaultMessage: 'Add new address'
                            })}
                          {!address.isNew &&
                            this.props.intl.formatMessage({
                              id: 'crm.activity.edit_address',
                              defaultMessage: 'Edit address'
                            })}
                        </div>
                        {this._renderAddressForm(index, address)}
                        {/* <AddressItemForm
                          index={index}
                          account_uid={account_uid}
                          address={address}
                          onCancelAddAddress={this.props.onCancelAddAddress}
                          handleCancelEditAddressOnMeetingForm={
                            this.handleCancelEditAddressOnMeetingForm
                          }
                          removeAddressUI={this.props.removeAddressUI}
                          updateAddressesUI={this.props.updateAddressesUI}
                          mapAlign="left"
                          isRequiredValidLocation={true}
                        /> */}
                      </div>
                    )
                  } else {
                    return (
                      <div key={index} className="col-md-6 util-marginBottom">
                        <AddressItem
                          address={address}
                          handleSelectAddress={(e) =>
                            this.handleSelectAddress(e, address, true)
                          }
                          is_selected={
                            address.address_uid ===
                            selected_address?.address_uid
                          }
                        />
                      </div>
                    )
                  }
                })}
                {!isAddingNewAddress && (
                  <div className="col-md-6 util-marginBottom">
                    <div className={`${'AddressItem'} util-fullHeight`}>
                      <div
                        className={`${'AddNewAddressIcon'}`}
                        onClick={() => {
                          this.handleStartAddAddress()
                        }}
                      >
                        <FontAwesomeIcon
                          title={this.props.intl.formatMessage({
                            id: 'crm.activity.click_to_add_address',
                            defaultMessage: 'Click to add address'
                          })}
                          className="util-hooverable util-pointer"
                          icon={faPlus}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="util-flexRowRight">
                <CrmButton
                  grouped
                  label={this.props.intl.formatMessage({
                    id: 'crm.ui.button.close',
                    defaultMessage: 'Close'
                  })}
                  id="section_address_close"
                  small
                  onClick={this.handleClose}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </AddressStyled>
    )
  }
}

export default injectIntl(SectionAddress)
