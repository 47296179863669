import React, { PureComponent } from 'react'
import { shape, bool, func } from 'prop-types'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import TableWithEdit from '../../components/_table-width-edit.jsx'
import MinuteFormName from '../../../minutes-form/minute-form-name.jsx'
import NextActionForm from './next-action-form.jsx'

import { FormattedDateTime } from 'crm-components'

const nextActionsTableDefinition = {
  columns: [
    {
      accessor: 'action',
      Header: (
        <FormattedMessage
          id="crm.ui.minute.form.actions"
          defaultMessage="Actions"
        />
      )
    },
    {
      accessor: 'due_date',
      Header: (
        <FormattedMessage
          id="crm.ui.minute.form.due_date"
          defaultMessage="Due Date"
        />
      ),
      Cell: row =>
        row.original.due_date ? (
          <FormattedDateTime value={moment(row.original.due_date)} />
        ) : (
          <span />
        )
    },
    {
      accessor: 'owner',
      Header: (
        <FormattedMessage
          id="crm.ui.minute.form.owner"
          defaultMessage="Owner"
        />
      )
    }
  ]
}

const nextActionFormDefinition = {
  component: props => <NextActionForm {...props} />
}

const definition = {
  table: nextActionsTableDefinition,
  form: nextActionFormDefinition
}
class SurveyBlockNextActions extends PureComponent {
  handleSave = data => {
    console.log('Survey Block handleSave')
    console.dir(data)
    this.props.handleSave({ section: this.props.section.code, data })
  }

  render() {
    return (
      <div>
        <MinuteFormName
          formname={
            <FormattedMessage
              id="crm.ui.minute.form.next_actions"
              defaultMessage="Next Actions"
            />
          }
        />
        <TableWithEdit
          activity={this.props.activity}
          section={this.props.section}
          definition={definition}
          onSave={this.handleSave}
          data={this.props.sectionData}
          isEditable={this.props.isEditable}
        />
      </div>
    )
  }
}

SurveyBlockNextActions.propTypes = {
  activity: shape().isRequired,
  handleSave: func.isRequired,
  section: shape({}).isRequired,
  isEditable: bool.isRequired,
  sectionData: shape({}).isRequired
}

export default SurveyBlockNextActions
