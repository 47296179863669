import React from 'react'
import { func, shape } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ActivityForm from './component/activity-form.jsx'
import MeetingForm from '../meeting/components/meeting-form.jsx'
import LiveCallNew from './live-call/live-call-window.jsx'
import AccountPageRightSidePanel from '../accounts/account/account-page-right-side-panel.jsx'

const ActivityEditPopup = props => {
  const { activity } = props
  return (
    <AccountPageRightSidePanel
      preventClosingOnClickOutside={true}
      isOpen={activity !== undefined}
      title={
        <FormattedMessage
          id="crm.ui.activity.edit_activity"
          defaultMessage="Edit Activity"
        />
      }
      onClose={props.onClose}
      onClickOutside={props.onClose}
    >
      {activity ? (
        <React.Fragment>
          {activity.activity_type_uid === 'activity_type_uid' ||
          (activity.activity_type &&
            activity.activity_type.form_code === 'live_call') ? (
            <LiveCallNew {...props} onCancel={props.onClose} />
          ) : (
            <React.Fragment>
              {activity.activity_type.medium_code !== 'meeting' && (
                <ActivityForm {...props} onCancel={props.onClose} />
              )}
              {activity.activity_type.medium_code === 'meeting' && (
                <MeetingForm {...props} onCancel={props.onClose} />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <div>
          <FormattedMessage
            id="crm.ui.activity.no_activity"
            defaultMessage="No Activity"
          />
        </div>
      )}
    </AccountPageRightSidePanel>
  )
}

ActivityEditPopup.propTypes = {
  activity: shape({}),
  onClose: func.isRequired
}

ActivityEditPopup.defaultProps = {
  activity: undefined
}

export default ActivityEditPopup
