import React from 'react'
import { CrmLoadingIcon } from '@cartrack-crm/ui'
import '../../style/crm-listbox/crm-listbox.scss'
import classNames from 'classnames'

type CrmListOptions = {
  name?: string
  value?: string
  description?: string
  disabled?: boolean
}
interface ICrmListbox {
  options?: CrmListOptions[]
  input: {
    value: string
    onChange: Function
  }
  isLoading?: boolean
  style?: React.CSSProperties
  bordered?: boolean
}
export const CrmListbox: React.FC<ICrmListbox> = (props) => {
  const handleOptionClick = event => {
    let value = event.currentTarget.id
    const item =
      props.options && value
        ? props.options.find(i => i.value === value)
        : undefined
    if (props.input.onChange) {
      props.input.onChange(value, item)
    }
  }
  const { isLoading, options, input, style, bordered } = props
  return (
    <React.Fragment>
      {isLoading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CrmLoadingIcon />
        </div>
      )}
      {!isLoading && (
        <div
          className={classNames('Listbox', { 'Listbox--bordered': bordered })}
          style={style}
        >
          {options.map(opt => {
            const selectedClass = input.value === opt.value
            const disabledClass = opt?.disabled
            return (
              <div
                key={opt.value}
                id={opt.value}
                className={classNames('ListboxItem', { 'ListboxItem__Selected': selectedClass, 'ListboxItem__Disabled': disabledClass })}
                onClick={opt.disabled ? () => { } : (e) => handleOptionClick(e)}
              >
                {opt.name}
                {opt.description && (
                  <span className='ListboxItem__Description'>
                    {' '}
                  - {opt.description}
                  </span>
                )}
              </div>
            )
          })}
        </div>
      )}
    </React.Fragment>
  )
}