import React, { PureComponent } from 'react'
import { shape, func, string, bool } from 'prop-types'
import AccountProcessItem from '../processes/account-process-item.jsx'
import { FormattedMessage } from 'react-intl'

class AccountProcesses extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    console.log('Render Account Processes', this.props)
    const { account } = this.props
    const filteredProcesses =
      account && account.processes
        ? account.processes.filter(
            pr => pr.process_type && pr.process_type.kind !== 'opportunity'
          )
        : []
    return (
      <div style={{ margin: 0, marginBottom: 20, padding: 20 }}>
        <h5>
          <FormattedMessage
            id="crm.ui.account.process.account_processes"
            defaultMessage="Account Processes"
          />
        </h5>
        <div className="util-marginTop">
          {filteredProcesses.map(item => (
            <AccountProcessItem
              key={item.process_uid}
              account={account}
              item={item}
              isManager={this.props.isManager}
            />
          ))}
        </div>
      </div>
    )
  }
}

AccountProcesses.defaultProps = {
  isSaving: false,
  currentProcessUid: undefined
}

AccountProcesses.propTypes = {
  account: shape({}).isRequired,
  onConverToAccount: func.isRequired, // eslint-disable-line
  onAssignTm: func.isRequired, // eslint-disable-line
  currentProcessUid: string,
  isManager: bool.isRequired
}

export default AccountProcesses
