import React, { useState } from 'react'
import {
  CrmButton,
  CrmLabeledField,
  CrmJsonPreview,
  CrmTextInput
} from '@cartrack-crm/ui'
import { ModalTestDefinition } from '../modal-test-definition'
import { DataSource } from '../types/component-type'
import { DataSourceFields } from './data-source-fields'
import { faCode, faPen } from '@fortawesome/free-solid-svg-icons'

export const DataSourceEditInline = (props) => {}

export const DataSourceItem = ({
  dataSource,
  component,
  runQuery,
  onSave,
  isCombined = false
}) => {
  const [isEdited, setIsEdited] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showAvailableFields, setShowAvailableFields] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleSave = (dataSource) => {
    console.log('handleSave')
    onSave(dataSource)
    setIsEdited(false)
  }

  const isAggregate = dataSource.group_by && dataSource.group_by.length > 0
  return (
    <div className="CrmMdCard CrmMdCard-padding util-marginBottom">
      <div className="row">
        <div className="col-md-6">
          <label>{dataSource.name}</label>
        </div>
        <div className="col-md-3">
          <CrmButton
            small
            style={{ minWidth: '0 !important', margin: 0 }}
            className="util-marginLeft"
            icon={faPen}
            iconButton
            onClick={() => setIsEdited(true)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <CrmLabeledField label="Name" value={dataSource.name ?? '-'} />
        </div>
        <div className="col-md-3">
          <CrmLabeledField
            label="Data Type"
            value={dataSource.data_type ?? '-'}
          />
        </div>
        <div className="col-md-3">
          <CrmLabeledField label="Code" value={dataSource.code ?? '-'} />
        </div>
        {/* <div className="col-md-3">
          <CrmLabeledField label="Type" value={dataSource.type ?? '-'} />
        </div>
        <div className="col-md-3">
          <CrmLabeledField label="root QL" value={dataSource.qlRoot ?? '-'} />
        </div> */}
      </div>

      {isAggregate && <div>is aggregated</div>}

      {isCombined && (
        <div>
          <div>Merge using field:</div>
          <CrmTextInput
            input={{
              value: dataSource.combine_field_name,
              onChange: () => {}
            }}
          />
        </div>
      )}
      <DataSourceFields dataSource={dataSource} />
      <div className="row">
        <div>Filters:</div>
        {dataSource.filters ? (
          <div>Filters Enabled </div>
        ) : (
          <div>Add Filter</div>
        )}
      </div>
      {!isExpanded && (
        <div>
          <div
            onClick={() => {
              setIsExpanded(true)
            }}
          >
            expand
          </div>
        </div>
      )}
      <div>
        <CrmButton
          small
          label="Advanced Edit"
          onClick={() => setIsEdited(true)}
        />
      </div>
      {dataSource && (
        <ModalTestDefinition
          dataSource={dataSource}
          filter={component.filter}
          isOpen={isEdited}
          onClose={() => setIsEdited(false)}
          runQuery={() => runQuery(dataSource, component.filters)}
          onSave={handleSave}
        />
      )}
      {showDetails ? (
        <small>
          <CrmJsonPreview json={dataSource} />
        </small>
      ) : (
        <div
          onClick={() => {
            setShowDetails(true)
          }}
        >
          show details
        </div>
      )}
    </div>
  )
}
