import React from 'react'
import { shape, string } from 'prop-types'
import { GenericWidgetWeb } from '@cartrack-crm/crm-analytics'

const widgetDefinition = {
  type: 'analitics_widget',
  code: 'leadsAssignment',
  def_version: 5,
  title: 'Leads Assignment',
  content: {
    data: {
      dataSources: [
        {
          code: 'SalesProcessQM',
          name: 'SalesProcessQM',
          type: 'QM',
          fields: ['owner_user_uid', 'assigned_to_user_date'],
          aggregate: [{ id: 'process_uid', type: 'count' }],
          filter: {
            is_first: true
          },
          contextMapping: {
            start_date: 'activity_date',
            performer__user_uid: 'user_uid',
            performer__department_uid: 'department_uid',
            performer__organization_unit_uid: 'organization_unit_uid'
          }
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {},
          fields: [
            'department_uid',
            'full_name',
            'user_uid',
            'contract_start_date',
            'contract_end_date',
            'department__name',
            'is_active'
          ]
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyField: 'user_uid',
        mapping: [
          {
            fromDataSourceName: 'SalesProcessQM',
            keyField: 'owner__user_uid',
            mapping: [
              {
                from: 'account_uid',
                to: 'assigned_leads'
              }
            ]
          },
          {
            fromDataSourceName: 'CrmUser',
            keyField: 'user_uid',
            type: 'left',
            mapping: [
              {
                from: 'full_name'
              },
              {
                from: 'contract_start_date'
              },
              { from: 'contract_end_date' },
              { from: 'department__name' },
              { from: 'is_active' }
            ]
          }
        ]
      }
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: false,
            generate_average: false,
            columns: [
              {
                Header: 'Assigned Leads',
                accessor: 'full_name',
                width: 50,
                type: 'number',
                detailsPopup: 'disposition',
                detailsFilter: {
                  status__is_success: true,
                  type__medium_code: 'phone_call'
                },
                detailsFilterMapping: {
                  performer__user_uid: 'user_uid'
                },
                className: 'util-textRight util-pointer'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}

const LeadsAssignments = props => (
  <GenericWidgetWeb
    title={props.title ? props.title : 'Wins by Agent'}
    subtitle="Matrix of dispositions made by telesales agents in selected period "
    analyticsContext={props.analyticsContext}
    widgetDefinition={widgetDefinition}
    padding
  />
)

LeadsAssignments.propTypes = {
  analyticsContext: shape().isRequired,
  title: string.isRequired
}

export default LeadsAssignments
