import React from 'react'
import {
  arrayOf,
  oneOfType,
  element,
  string,
  shape,
  func,
  any,
  bool
} from 'prop-types'
import { FormattedMessage } from 'react-intl'

const handleClick = onChange => event => {
  onChange(event.target.value)
}

const RadioInputGroup = ({
  input,
  onChange,
  value,
  options,
  name,
  placeholder,
  required,
  mainStyle,
  labelStyle,
  wrappedStyle,
  extraClassNames: {
    containerClassNames = '',
    inputClassNames = '',
    labelClassNames = '',
    placeholderClassNames = ''
  }
}) => (
  <div className={`RadioInputGroup ${containerClassNames}`} style={wrappedStyle}>
    <div className={`RadioInputGroup-placeholder ${placeholderClassNames}`}>
      {required && <span className="RadioInputGroup-required">*</span>}
      {placeholder && typeof placeholder === 'string' ? (
        <FormattedMessage
          id={`form.placeholder.${placeholder}`}
          defaultMessage={placeholder}
        />
      ) : (
        placeholder
      )}
    </div>

    <div className="RadioInputGroup-inputs" style={mainStyle}>
      {options.map(option => (
        <label
          key={option.value || option}
          className={`RadioInputGroup-label ${labelClassNames}`}
          style={labelStyle}
        >
          <input
            type="radio"
            name={name}
            id={name + '_' + option.value || option}
            value={option.value || option}
            onClick={handleClick(input.onChange || onChange)}
            defaultChecked={
              (option.value || option) ===
              (input.value === undefined ? value : input.value)
            }
            className={inputClassNames}
          />
          {option.label || option}
        </label>
      ))}
    </div>
  </div>
)

RadioInputGroup.propTypes = {
  input: shape({
    value: string,
    onChange: func
  }),
  onChange: func,
  value: string,
  mainStyle: any,
  labelStyle: any,
  wrappedStyle: any,
  name: string.isRequired,
  options: arrayOf(
    oneOfType([
      string,
      shape({
        label: any,
        value: string
      })
    ])
  ).isRequired,
  placeholder: oneOfType([element.isRequired, string.isRequired]),
  required: bool,
  extraClassNames: shape({
    containerClassNames: string,
    inputClassNames: string,
    placeholderClassNames: string
  })
}

RadioInputGroup.defaultProps = {
  input: {},
  placeholder: null,
  onChange: null,
  value: '',
  required: false,
  mainStyle: {},
  wrappedStyle: {},
  labelStyle: {},
  extraClassNames: {
    containerClassNames: '',
    inputClassNames: '',
    labelClassNames: '',
    placeholderClassNames: ''
  }
}

export default RadioInputGroup
