import moment from 'moment'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import { qlqGenericDataQuery } from 'crm-data/generic-data'

const buildFilters = ownProps => {
  const filters = {}

  if (ownProps.dateRange?.completedDate?.value?.[0] && ownProps.dateRange?.completedDate?.value?.[1]) {
    filters.completed_date = {
      $gte: moment(ownProps.dateRange?.completedDate?.value?.[0], 'DD/MM/YYYY').format('MM/DD/YYYY'),
      $lte: moment(ownProps.dateRange?.completedDate?.value?.[1], 'DD/MM/YYYY').format('MM/DD/YYYY')
    }
  }

  if (ownProps.dateRange?.salesDate?.value?.[0] && ownProps.dateRange?.salesDate?.value?.[1]) {
    filters.sale_date = {
      $gte: moment(ownProps.dateRange?.salesDate?.value?.[0], 'DD/MM/YYYY').format('MM/DD/YYYY'),
      $lte: moment(ownProps.dateRange?.salesDate?.value?.[1], 'DD/MM/YYYY').format('MM/DD/YYYY')
    }
  }
  if (ownProps.camUsername) {
    filters.cams_username = {
      like: ownProps.camUsername
    }
  }
  if (ownProps.showOnlyMissingCams) {
    filters.account_uid = null
  }

  if (ownProps.contractId) {
    filters.external_id = {
      like: ownProps.contractId
    }
  }
  if (ownProps.vehicle) {
    filters.vehicle_registration = {
      like: ownProps.vehicle
    }
  }
  if (ownProps.status) {
    filters.status_code = ownProps.status
  }
  if (ownProps.ownerUser) {
    filters.owner_user_uid = ownProps.ownerUser
  }
  return filters
}

const qlOptions = {
  options: ownProps => ({
    variables: {
      filter: buildFilters(ownProps),
      fields: [
        'order_uid',
        'fitment_uid',
        'vehicles_count',
        'units_fitted',
        'completed_date',
        'cams_username',
        'description',
        'details',
        'instance_uid',
        'account_uid',
        'account_name',
        'is_deleted',
        'owner_user_name',
        'lead_source_name',
        'lead_source_uid',
        'lead_source_kind',
        'lead_source_type',
        'lead_source_type_uid',
        'vehicle_registration',
        'external_id',
        'subscription',
        'hw_amount',
        'fitter_name',
        'product_package',
        'sale_date',
        'status_code',
        'owner__full_name'
      ],
      rootType: 'OrdersListQM'
    }
  }),
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  })
}

const fitmentTable = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions
})

export default fitmentTable
