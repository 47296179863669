export const currencies = [
  { name: 'THB', value: 'THB' },
  { name: 'EUR', value: 'EUR' },
  { name: 'SGD', value: 'SGD' },
  { name: 'MYR', value: 'MYR' },
  { name: 'IDR', value: 'IDR' },
  { name: 'PHP', value: 'PHP' },
  { name: 'ZAR', value: 'ZAR' },
  { name: 'PLN', value: 'PLN' },
  { name: 'KES', value: 'KES' },
  { name: 'MZN', value: 'MZN' },
  { name: 'USD', value: 'USD' },
  { name: 'NGN', value: 'NGN' },
  { name: 'AED', value: 'AED' },
  { name: 'HKD', value: 'HKD' },
  { name: 'NAD', value: 'NAD' },
  { name: 'SZL', value: 'SZL' },
]
export const languages = [
  { name: 'Thai', value: 'TH' },
  { name: 'English', value: 'EN' },
  { name: 'Polish', value: 'PL' },
  { name: 'Portuguese', value: 'PT' },
  { name: 'Spanish', value: 'ES' }
]
