/* eslint-disable */
import React from 'react'
// Iimport { AgGridReact } from 'ag-grid-react'
import PropTypes from 'prop-types'

// This component is used just for some tests ..
const CrmAgTable = props => <div>Used to evaluate AgGrid</div>
// <AgGridReact {...props} />

CrmAgTable.defaultProps = {
  className: ''
}

CrmAgTable.propTypes = {
  className: PropTypes.string
  // ...AgGridReact.propTypes
}

export default CrmAgTable
