import React, { PureComponent } from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import { DateRangePicker } from 'util-components'
import {
  getDateRangeByLabel,
  getDateFormat,
  getDateRangeOptions,
  getAPIDateFormat,
  getDateByFormat
} from 'crm-utils/time-utils'
import { CrmButton, CrmTextInput } from '@cartrack-crm/ui'
import moment from 'moment'

interface IProps {
  onChange: Function
  input: any
  placeholder: string
  style?: any
  dateFormat?: string
}

interface IState {
  dateRangeValue: any
  dateRangeOptions: any
  isShowDropdown: boolean
  isShowCustomRange: boolean
}

class CrmDateRangeDropdown extends PureComponent<IProps, IState> {
  currentMonth
  constructor(props) {
    super(props)

    this.currentMonth = new Date()

    const dateRangeOptions = [...getDateRangeOptions(), { name: 'Custom Range', value: 'customRange' }]

    this.state = {
      dateRangeOptions,
      dateRangeValue: { ...props.input.value },
      isShowDropdown: false,
      isShowCustomRange: false
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.input.value !== nextProps.input.value) {
      this.setState({ dateRangeValue: { ...nextProps.input.value } })
    }
  }

  handleClickOutside = () => {
    this.setState({ isShowDropdown: false })
  }

  handleApply = () => {
    this.setState({ isShowDropdown: false })

    const result = {
      ...this.state.dateRangeValue,
      startDate: this.props.dateFormat
        ? getDateByFormat(this.state.dateRangeValue.from, this.props.dateFormat)
        : getAPIDateFormat(this.state.dateRangeValue.from),
      endDate: this.props.dateFormat
        ? getDateByFormat(this.state.dateRangeValue.to, this.props.dateFormat)
        : getAPIDateFormat(this.state.dateRangeValue.to)
    }

    if (this.props.onChange) {
      this.props.onChange(result)
    } else if (this.props.input.onChange) {
      this.props.input.onChange(result)
    }
  }

  handleCancel = () => {
    this.setState({
      dateRangeValue: this.props.input.value,
      isShowDropdown: false
    })
  }

  getDateInputValue = () => {
    if (this.props.input.value && this.props.input.value.startDate && this.props.input.value.endDate) {
      let startTime = this.props.input.value.startDate
      let endTime = this.props.input.value.endDate

      if (typeof this.props.input.value.startDate === 'string') {
        if (this.props.dateFormat) {
          startTime = moment(this.props.input.value.startDate, this.props.dateFormat)
          endTime = moment(this.props.input.value.endDate, this.props.dateFormat)
        } else {
          startTime = moment(this.props.input.value.startDate)
          endTime = moment(this.props.input.value.endDate)
        }
      }

      const startDateString = getDateFormat(startTime)
      const endDateString = getDateFormat(endTime)

      return `${startDateString} - ${endDateString}`
    }

    return ''
  }

  handleDateRangeChanged = (dateRange, selectedDateRangeOption) => {
    let dateRangeValue = {}

    if (dateRange && dateRange.from && dateRange.to) {
      dateRangeValue = {
        from: dateRange.from,
        to: dateRange.to,
        selectedDateRangeOption
      }
    }

    this.setState(
      {
        dateRangeValue
      },
      () => {
        if (selectedDateRangeOption) {
          this.handleApply()
        }
      }
    )
  }

  handleDateRangeLabelSelect = selectedDateRangeOption => {
    if (selectedDateRangeOption.value === 'customRange') {
      this.setState({
        isShowCustomRange: true,
        dateRangeValue: {
          from: null,
          to: null,
          selectedDateRangeOption
        }
      })
    } else {
      const dateRange = getDateRangeByLabel(selectedDateRangeOption.value)
      this.handleDateRangeChanged(dateRange, selectedDateRangeOption)
    }
  }

  handleInputFocus = e => {
    this.setState({ isShowDropdown: true })
  }

  handleDateChangeManually = dateRangeValue => {
    this.setState({ dateRangeValue })
  }

  render() {
    const placeholder = this.props.placeholder ? this.props.placeholder : 'Select date range'

    return (
      <React.Fragment>
        <div className="CrmDateRangeDropdown util-flexColumn">
          <div className={`DateRangeInputWrapper`}>
            <CrmTextInput
              placeholder={placeholder}
              onFocus={this.handleInputFocus}
              value={this.getDateInputValue()}
              style={this.props.style}
            />
          </div>

          {this.state.isShowDropdown && (
            <div className={`DatePickerWrapper`}>
              <div className={`util-flexRow`}>
                <div>
                  <div className={`CrmDateRangeDropdown__OptionsList`}>
                    {this.state.dateRangeOptions.map(v => {
                      const selectedDateRangeOption = this.state.dateRangeValue
                        ? this.state.dateRangeValue.selectedDateRangeOption
                        : null
                      return (
                        <div
                          key={v.value}
                          id={v.value}
                          className={`CrmDateRangeDropdown__Options__Item 
                        ${
                          selectedDateRangeOption && v.value === selectedDateRangeOption.value
                            ? 'CrmDateRangeDropdown__Options__Item--selected'
                            : ''
                        }`}
                          onClick={() => this.handleDateRangeLabelSelect(v)}
                        >
                          {v.name}
                        </div>
                      )
                    })}
                    <div className="util-flexGrow" />
                    <div className={`util-flexRowSpaceBetween`}>
                      <div>
                        <CrmButton label="Apply" onClick={this.handleApply} xsmall />
                      </div>
                      <div>
                        <CrmButton label="Cancel" onClick={this.handleCancel} xsmall />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.isShowCustomRange && (
                  <div className={`DateRangePicker-holder`}>
                    <DateRangePicker
                      value={this.state.dateRangeValue}
                      onChange={this.handleDateRangeChanged}
                      initialMonth={this.currentMonth}
                      showDateTextInput={true}
                      onDateChangeManually={this.handleDateChangeManually}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default enhanceWithClickOutside(CrmDateRangeDropdown)
