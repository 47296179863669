/* eslint-disable   */
import React from 'react'
import { connect } from 'react-redux'
import { bool, func, arrayOf, shape } from 'prop-types'
import uuidv4 from 'uuid'
import { toastr } from 'react-redux-toastr'
import { getMessage, getEnable, openApiErrorModal, closeApiErrorModal, clearError } from 'crm-duxs/error-reporting'
import crmGenericCommandQlHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { CrmButton, CrmModal } from 'crm-components'
import { jsonSyntaxHighlight } from 'crm-utils/common-utils'

class ToastApiError extends React.PureComponent {
  handleShowDetailsClick = () => {
    this.props.openErrorModal()
  }

  render() {
    return (
      <CrmButton
        red
        type="secondary"
        onClick={this.handleShowDetailsClick}
        label="Show details"
        enableTranslate={false}
      />
    )
  }
}

ToastApiError.propTypes = {
  openErrorModal: func.isRequired
}

function mapToastDispatchToProps(dispatch) {
  return {
    openErrorModal: () => dispatch(openApiErrorModal())
  }
}

export const ToastApiErrorViewButton = connect(null, mapToastDispatchToProps)(ToastApiError)

// eslint-disable-next-line
class ReportErrorModal extends React.PureComponent {
  sendError = async () => {
    const command = {
      type: 'error.reportWithMail',
      aggregate_type: 'errorReport',
      aggregate_uid: uuidv4(),
      payload: this.props.errors[this.props.errors.length - 1]
    }

    await this.props.onRunCommand(command, {})
    this.props.doClearError()
    this.props.doCloseConfirmation()
    toastr.success('Report error success!')
  }

  getErrorMessage = msgError => {
    try {
      if (msgError) {
        let msg = undefined

        if (Array.isArray(msgError.error)) {
          msg = msgError.error[0].message
        } else if (Array.isArray(msgError.error.errors)) {
          msg = msgError.error.errors[0].message
        }

        if (msg.length > 200) {
          return msg.substr(0, 200) + '...'
        }

        return msg
      }
    } catch (err) {
      console.error(err)
    }

    return undefined
  }

  getErrorResult = msgError => {
    let result = undefined

    if (msgError) {
      if (msgError.response && Object.keys(msgError.response).length > 0) {
        result = msgError.response
      } else {
        if (msgError.error) {
          result = msgError.error
        }
      }
    }

    if (typeof result == 'object') {
      return result
    }

    try {
      return result ? JSON.parse(result) : undefined
    } catch (err) {
      console.error(err)
    }

    return result
  }

  getErrorRequestBody = msgError => {
    let body = undefined

    if (msgError) {
      body = msgError.request

      try {
        return JSON.parse(body)
      } catch (err) {
        console.error('ErrorRequestBody cannot parse')
      }
    }

    return body
  }

  getErrorRequestType = msgError => {
    if (msgError) return msgError.tags.type
    return undefined
  }

  handleClickClear = () => {
    this.props.doClearError(true)
  }

  render() {
    const lastError = this.props.errors.length > 0 ? this.props.errors[this.props.errors.length - 1] : undefined

    const errorMsg = this.getErrorResult(lastError)
    const errorRequest = this.getErrorRequestBody(lastError)
    const errorFrom = this.getErrorRequestType(lastError)

    return (
      <CrmModal title="Report Problem to IT" isOpen={this.props.enable} onClose={this.props.doCloseConfirmation}>
        <div className="util-flexColumn util-flexGrow util-fullHeight">
          <div className="util-paddingLg util-flexGrow util-flexColumn">
            {errorMsg && (
              <div className="util-paddingSm">
                <div className="row">
                  <div className="col-lg-4 util-textBold">Error message:</div>
                </div>
                <div className="row">
                  <div
                    className="json-output"
                    style={{
                      overflow: 'scroll',
                      maxHeight: 150
                    }}
                  >
                    <div>
                      <pre
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                          __html: jsonSyntaxHighlight(errorMsg)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {errorRequest && (
              <div className="util-paddingSm">
                <div className="row">
                  <div
                    className="col-lg-4 util-textBold"
                    style={{
                      fontWeight: 'bold',
                      marginTop: 10
                    }}
                  >
                    Request body:
                  </div>
                </div>
                <div className="row">
                  <div
                    className="json-output"
                    style={{
                      overflow: 'scroll',
                      maxHeight: 150
                    }}
                  >
                    <div>
                      <pre
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                          __html: jsonSyntaxHighlight(errorRequest)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {errorFrom && (
              <div>
                <div className="row">
                  <div
                    className="col-lg-4"
                    style={{
                      fontWeight: 'bold',
                      marginTop: 10
                    }}
                  >
                    Request from :
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-12"
                    style={{
                      fontSize: 12
                    }}
                  >
                    {errorFrom}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="CrmModal-footer">
            <CrmButton
              id=""
              className="ConfirmationModal-button--left"
              label="Clear"
              type="secondary"
              onClick={this.handleClickClear}
            />
            <CrmButton
              id=""
              className="ConfirmationModal-button--left"
              label="Close"
              type="secondary"
              onClick={this.props.doCloseConfirmation}
            />{' '}
            {lastError && (
              <CrmButton
                id="true"
                className="ConfirmationModal-button--right"
                label="Report error"
                type="primary"
                onClick={this.sendError}
              />
            )}
          </div>
        </div>
      </CrmModal>
    )
  }
}

ReportErrorModal.propTypes = {
  errors: arrayOf(shape({})).isRequired,
  enable: bool.isRequired,
  doCloseConfirmation: func.isRequired,
  onRunCommand: func.isRequired,
  doClearError: func.isRequired
}

function mapStateToProps(state) {
  return { errors: getMessage(state), enable: getEnable(state) }
}

function mapDispatchToProps(dispatch) {
  return {
    doCloseConfirmation: () => dispatch(closeApiErrorModal()),
    doClearError: () => dispatch(clearError())
  }
}

export default crmGenericCommandQlHoc(connect(mapStateToProps, mapDispatchToProps)(ReportErrorModal))
