/* eslint-disable global-require */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { shape, func } from 'prop-types'
import CampaignForm from './campaign-cost-form.jsx'

class CampaignPage extends Component {
  render() {
    if (!this.props.campaignCost.formType) {
      return <div className="hidden" />
    }

    return (
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <CampaignForm
          campaignCost={this.campaignCost}
          onSubmit={this.props.handleFormSubmit}
        />
      </div>
    )
  }
}

CampaignPage.defaultProps = {
  campaignCost: {}
}

CampaignPage.propTypes = {
  campaignCost: shape(),
  handleFormSubmit: func.isRequired
}

export default connect(null, null)(CampaignPage)
