/* eslint-disable camelcase */
import React from 'react'
import gql from 'graphql-tag'
import { string } from 'prop-types'
import { Query } from 'react-apollo'

export const getPackageItemQL = gql`
  query package_item_runtime_qm(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    package_item_runtime_qm(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        package_item_uid
        name
        code
        type
        version
        active
        is_new
        package_uid
        details
      }
    }
  }
`

const getPackageItmesAdminQlHoc = WrappedComponent => {
  // eslint-disable-next-line
  class GetPackageItmesAdminQlHoc extends React.PureComponent {
    static propTypes = {
      code: string,
      version: string,
      package_item_uid: string,
      package_uid: string,
      type: string,
      instnace_uid: string,
      name: string,
      active: string
    }

    static defaultProps = {
      code: undefined,
      package_item_uid: undefined,
      package_uid: undefined,
      type: undefined,
      instnace_uid: undefined,
      active: undefined,
      name: undefined,
      version: undefined
    }

    render() {
      const variables = {
        filter: {
          package_item_uid: this.props.package_item_uid
            ? this.props.package_item_uid
            : undefined,
          name: this.props.name ? this.props.name : undefined,
          package_uid: this.props.package_uid
            ? this.props.package_uid
            : undefined,
          code: this.props.code ? this.props.code : undefined,
          version: this.props.version ? this.props.version : undefined,
          type: this.props.type ? this.props.type : undefined,
          instnace_uid: this.props.instnace_uid
            ? this.props.instnace_uid
            : undefined,
          active: this.props.active ? this.props.active : undefined
        }
      }

      return (
        <Query query={getPackageItemQL} variables={variables}>
          {({ loading, error, data, refetch }) => {
            if (loading) return false
            return (
              <WrappedComponent
                {...this.props}
                packageItems={data.package_item_runtime_qm.data}
                error={error}
                loading={loading}
                packageItemRefresh={refetch}
              />
            )
          }}
        </Query>
      )
    }
  }

  return GetPackageItmesAdminQlHoc
}
export default getPackageItmesAdminQlHoc
