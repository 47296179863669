import React from 'react'
import { shape, func } from 'prop-types'
import { CrmButton } from 'crm-components'
import { Spacer } from 'util-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  getContractLengthOptionsName,
  formatTotal
} from 'crm-data/prices-table'

const QuickQuotationSummary = (props) => {
  const getTotals = () => {
    const totals = {
      value_net: 0,
      subscription_net: 0,
      subscription_net_next_years: 0
    }
    props.quotation.items.map((v) => {
      const itemSubNet = props.getPriceByCycle(v.cycle, v.subscription_net)
      const itemSubNetNextYear = props.getPriceByCycle(
        v.cycle,
        v.subscription_net_next_years
      )
      totals.value_net += parseFloat(Number(v.value_net).toString())
      totals.subscription_net += parseFloat(Number(itemSubNet).toString())
      totals.subscription_net_next_years += parseFloat(
        Number(itemSubNetNextYear).toString()
      )
    })

    totals.value_net = formatTotal(totals.value_net)
    totals.subscription_net = formatTotal(totals.subscription_net)
    totals.subscription_net_next_years = formatTotal(
      totals.subscription_net_next_years
    )

    return totals
  }

  return (
    <div className="util-paddingMd">
      <div className="util-flexRowSpaceBetween LabeledField-value">
        <div>
          <FormattedMessage
            id="crm.quotation.quotation_items"
            defaultMessage="Quotation Items"
          />
        </div>
        <div>
          <div className="LabeledField-label">
            <FormattedMessage id="crm.quotation.cycle" defaultMessage="Cycle" />
          </div>
          <div>{props.getFirstItemCycle()}</div>
        </div>
        <div>
          <div className="LabeledField-label">
            <FormattedMessage
              id="crm.ui.oppournity.form.contract_length"
              defaultMessage="Contract Length"
            />
          </div>
          <div>
            {getContractLengthOptionsName(props.quotation.contract_length)}
          </div>
        </div>
      </div>
      <Spacer height="20px" />
      <table className="util-fullWidth LabeledField-value">
        <tbody>
          {props.quotation.items.map((v) => {
            return (
              <React.Fragment key={v.product.product_uid}>
                <tr>
                  <td style={{ width: '15px' }}>{v.quantity}</td>
                  <td style={{ width: '10px' }}>x</td>
                  <td>{v.product.name}</td>
                  <td style={{ width: '40px' }}>
                    <div className="util-flexRowRight">
                      <CrmButton
                        type="button"
                        xsmall
                        icon="trash"
                        onClick={() =>
                          props.handleDeleteProduct(v.product.product_uid)
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <div className="util-flexRowRight util-textBold util-textRight util-marginBottom">
                      <div style={{ width: '60px' }}>{v.value_net}</div>
                      <div style={{ width: '60px' }}>
                        {props.getPriceByCycle(v.cycle, v.subscription_net)}
                      </div>
                      <div style={{ width: '60px' }}>
                        {props.getPriceByCycle(
                          v.cycle,
                          v.subscription_net_next_years
                        )}
                      </div>
                    </div>
                  </td>
                  <td />
                </tr>
              </React.Fragment>
            )
          })}
          <tr>
            <td colSpan={4}>
              <hr className="util-horizontalLine" />
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <div className="util-flexRowSpaceBetween util-marginTop">
                <div>Total</div>
                <div className="util-flexRowRight util-textBold util-textRight util-marginBottom">
                  <div style={{ width: '60px' }}>{getTotals().value_net}</div>
                  <div style={{ width: '60px' }}>
                    {getTotals().subscription_net}
                  </div>
                  <div style={{ width: '60px' }}>
                    {getTotals().subscription_net_next_years}
                  </div>
                </div>
              </div>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  )
}
QuickQuotationSummary.propTypes = {
  quotation: shape({}).isRequired,
  handleDeleteProduct: func.isRequired,
  getPriceByCycle: func.isRequired,
  getFirstItemCycle: func.isRequired
}
QuickQuotationSummary.defaultProps = {}

export default injectIntl(QuickQuotationSummary)
