/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { graphql, compose } from 'react-apollo'
import { string, func, shape } from 'prop-types'
import { getFormValues, change } from 'redux-form'
import { qlmExecuteFunction } from 'crm-data/crm'
import { qlqProcessTypes } from 'crm-data/processes'
import { qlqfetchAccount } from 'crm-data/accounts'
import { qlqAccountTasks } from 'crm-data/tasks'
import uuidv4 from 'uuid'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { getAccountOwnerUserUid } from 'crm-utils/accounts-utils'

const newOwnerOptions = [
  { value: 'user', label: 'User' },
  { value: 'group', label: 'Group' }
]

export const generateCommandFromForm = (
  formValues,
  props,
  accountUid,
  souceProcessUid,
  isBulkMode = false
) => {
  console.log('handleSave Convert to prospect')
  // console.log('formValues', formValues)
  if (!formValues) {
    throw new Error('Error - no formValues!')
  }
  if (!formValues.campaign_uid) {
    throw new Error('Select Sale Campaign')
  }
  if (!formValues.process_type_uid) {
    throw new Error('Select Process Type')
  }
  if (!formValues.owner_type) {
    throw new Error('Select new owner type')
  }
  if (formValues.owner_type === 'user' && !formValues.owner_user_uid) {
    throw new Error('Select new owner')
  }
  if (formValues.owner_type === 'group' && !formValues.owner_pool_uid) {
    throw new Error('Select users group')
  }
  const process = {
    account_uid: accountUid,
    owner_type: formValues.owner_type,
    campaign_uid: formValues.campaign_uid,
    opportunity_type_uid: 'bd76af36-fc69-4bf6-9a97-6ed64f56af73',
    source_process_uid: souceProcessUid,
    process_type_uid: formValues.process_type_uid
  }
  if (isBulkMode) {
    process.is_bulk_mode = true
  }
  if (formValues.owner_type === 'user') {
    process.owner_user_uid = formValues.owner_user_uid
  } else if (formValues.owner_type === 'group') {
    process.owner_pool_uid = formValues.owner_pool_uid
  } else {
    throw new Error('Unknown process owner type')
  }
  const command = {
    type: 'process.start',
    aggregate_uid: props.newObjectUid,
    aggregate_type: 'process',
    payload: {
      process
    }
  }
  console.log('Returning command')

  return command
}

export function calculateInitialValues(props) {
  const accountOwnerUid = props.account
    ? getAccountOwnerUserUid(props.account)
    : undefined
  const initialValues = {
    owner_user_uid: accountOwnerUid,
    campaign_uid:
      props.account && props.account.lead_campaign
        ? props.account.lead_campaign.marketing_campaign_uid
          ? props.account.lead_campaign.marketing_campaign_uid
          : props.account.lead_campaign.campaign_uid
        : undefined,
    owner_type: 'user' // props.newProcessKind === 'prospect' ? 'user' : 'group'
  }
  initialValues.process_type_uid = 'e30eb792-2832-4c10-9f74-2c6b1df1318e'
  // if (props && props.newProcessKind === 'dialer') {
  //   initialValues.process_type_uid = 'e30eb792-2832-4c10-9f74-2c6b1df1318e'
  //   // initialValues.process_type_uid = '273c0b3f-23dd-4d05-ba56-cd34c21d0fd2'

  // }
  // if (props && props.newProcessKind === 'prospect') {
  //   initialValues.process_type_uid = 'e30eb792-2832-4c10-9f74-2c6b1df1318e'
  //   if (props && props.account && props.account.category === 'B2B') {
  //     // initialValues.process_type_uid = '0ac9339f-f04f-41d6-8adf-792f4d300c25'
  //   } else if (props && props.account && props.account.category === 'B2C') {
  //     initialValues.process_type_uid = 'bc2df74d-f773-4b99-98c4-6036d2429516'
  //   } else {
  //     initialValues.process_type_uid = '0ac9339f-f04f-41d6-8adf-792f4d300c25'
  //   }
  // }
  return initialValues
}
const convertToProspectHOC = WrappedComponent => {
  class Inner extends React.PureComponent {
    static propTypes = {
      ...cqCommandPropTypes,
      accountUid: string.isRequired,
      qlmExecuteFunction: func.isRequired,
      account: shape().isRequired
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })

    constructor(props) {
      super(props)

      console.log('Conver to prospect ql hoc constructor')
      const initialValues = calculateInitialValues(props)
      const state = {
        initialValues,
        noDefaultSaleCampaignError:
          props.account &&
          props.account.campaign &&
          !props.account.campaign.marketing_campaign_uid,
        isShowingMoreOptions: false,
        requireSelectTransactionType:
          props && props.account && !props.account.category,
        thisUid: uuidv4()
      }

      // console.log('State: ', state)
      this.state = state
    }

    componentDidMount() {
      // console.log('ConvertToProspect did mount', this.props)
      if (this.props.isNested) {
        // Set initial values
        const initialValues = calculateInitialValues(this.props)
        // console.log('initialValues', initialValues)
        Object.keys(initialValues).forEach(key => {
          this.props.doChange(
            this.props.form,
            'dispositionDetailsForm.' + key,
            initialValues[key]
          )
        })
      }
    }

    handleSave = async (pAccount = undefined, isBulkMode = false) => {
      // console.log('handleSave', pAccount, this.props)
      const accountUid = pAccount ? pAccount.account_uid : this.props.accountUid

      let souceProcessUid
      if (pAccount) {
        souceProcessUid = pAccount.process_uid
      } else {
        souceProcessUid = this.props.sourceProcess
          ? this.props.sourceProcess.process_uid
          : undefined
      }

      const command = generateCommandFromForm(
        this.props.formValues,
        this.props,
        accountUid,
        souceProcessUid,
        isBulkMode
      )
      console.log('Have command', command)
      // await this.setStateAsync({ isSaving: true })
      try {
        const refetchQueries = pAccount
          ? []
          : [
              {
                query: qlqfetchAccount,
                variables: { account_uid: accountUid }
              },
              {
                query: qlqAccountTasks,
                variables: { account_uid: accountUid }
              }
            ]
        console.log('Run onRunCommand')
        const res = await this.props.onRunCommand(command, { refetchQueries })
        console.log('Res', res)
        // await this.setStateAsync({ isSaving: false })
        return {
          status: 'OK',
          process: res.data.cqCommand.payload
            ? res.data.cqCommand.payload.process
            : undefined
        }
      } catch (err) {
        // await this.setStateAsync({ isSaving: false })
        throw new Error(err)
      }
    }

    handleUserSelect = value => {
      // console.log('handleUserSelect', value)
      this.setState({ user: value })
    }

    handleCampaignChanged = campaign_uid => {
      // console.log('handleCampaignChanged', campaign_uid)
      this.setState({ campaign_uid })
    }
    handleowner_typeChange = owner_type => {
      // console.log('handleowner_typeChange', owner_type)
      this.setState({ owner_type })
    }
    handleUsersGroupSelect = usersGroup => {
      // console.log('usersGroupChange', usersGroup)
      this.setState({ usersGroup })
    }
    handleProcessTypeChange = process_type_uid => {
      // console.log('process_type_uid', process_type_uid)
      this.setState({ process_type_uid })
    }
    handleToggleShowMoreOptions = () => {
      this.setState({ isShowingMoreOptions: !this.state.isShowingMoreOptions })
    }

    handleBulkOperation = async pAccount => {
      // console.log('Convert to prospect HOC - onBulkOperation', pAccount)
      const res = await this.handleSave(pAccount, true)
      return res
    }

    render() {
      const processTypeOptions =
        this.props.qlqProcessTypes && this.props.qlqProcessTypes.process_types
          ? this.props.qlqProcessTypes.process_types
              .filter(p => p.kind === 'prospect' || p.kind === 'dialer')
              .filter(p =>
                this.props.newProcessKind
                  ? p.kind === this.props.newProcessKind
                  : true
              )
              .map(p => ({
                value: p.process_type_uid,
                name: p.name
              }))
          : []

      return (
        <WrappedComponent
          {...this.props}
          campaign_uid={this.state.campaign_uid}
          user={this.state.user}
          owner_type={this.state.owner_type}
          onSave={this.handleSave}
          process_type_uid={this.state.process_type_uid}
          onUserSelect={this.handleUserSelect}
          onCampaignChanged={this.handleCampaignChanged}
          onowner_typeChanged={this.handleowner_typeChange}
          onUserGroupSelect={this.handleUsersGroupSelect}
          onProcessTypeChange={this.handleProcessTypeChange}
          newOwnerOptions={newOwnerOptions}
          newProcessKind={this.props.newProcessKind}
          processTypeOptions={processTypeOptions}
          initialValues={this.state.initialValues}
          formValues={this.props.formValues}
          noDefaultSaleCampaignError={this.state.noDefaultSaleCampaignError}
          isShowingMoreOptions={this.state.isShowingMoreOptions}
          onToggleShowMoreOptions={this.handleToggleShowMoreOptions}
          requireSelectTransactionType={this.state.requireSelectTransactionType}
          onBulkOperation={this.handleBulkOperation}
        />
      )
    }
  }
  return connect(
    (state, ownProps) => {
      // console.log('Conver to prospect map', ownProps)
      const formName = ownProps.form ? ownProps.form : 'convertToProspect'
      const formValues = getFormValues(formName)(state)
      const resultFormValues =
        ownProps.isNested && formValues
          ? formValues.dispositionDetailsForm
          : formValues
      // console.log('resultFormValues', resultFormValues)
      return {
        formValues: resultFormValues
      }
    },
    dispatch => ({
      doChange: (formName, field, value) =>
        dispatch(change(formName, field, value))
    })
  )(crmGenericCommandQlHoc(Inner))
}

const convertToProspectQLHOC = compose(
  graphql(qlmExecuteFunction, {
    name: 'qlmExecuteFunction',
    options: ownProps => ({
      refetchQueries: [
        {
          query: qlqfetchAccount,
          variables: { account_uid: ownProps.accountUid }
        }
      ]
    })
  }),
  graphql(qlqProcessTypes, {
    name: 'qlqProcessTypes'
  }),
  convertToProspectHOC
)

export default convertToProspectQLHOC
