/* eslint-disable camelcase */
import { callQLApi } from '../api-utils'

export const qlReport = ' report_uid name query definitions  '

export default {
  create: params => {
    const qlParams = {
      query: ` mutation  report_create($report:report_in) { report_create(report: $report)  { ${qlReport} }  } `,
      variables: {
        report: {
          name: params.name,
          query: params.query,
          definitions: params.definitions
        }
      }
    }
    console.log('reports ql create: ', qlParams)
    return callQLApi(qlParams).then(res => res.report_create)
  },
  update: params => {
    const qlParams = {
      query: ` mutation  report_update($report:report_in) { report_update(report: $report)  { ${qlReport} }  } `,
      variables: {
        report: {
          report_uid: params.report_uid,
          name: params.name,
          query: params.query,
          definitions: params.definitions
        }
      }
    }
    console.log('reports ql update: ', qlParams)
    return callQLApi(qlParams).then(res => res.report_update)
  },
  fetch: () => {
    const query = ` { reports {  ${qlReport} } } `
    console.log('reports ql : ', query)
    return callQLApi(query).then(res => res.reports)
  }
}
