import React, { useState, useEffect } from 'react'
import { shape, func, string, bool } from 'prop-types'
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl'
import { Formik, Form, Field as FieldFormik } from 'formik'
import {
  CrmButton,
  CrmDayPickerInput,
  CrmLabeledField,
  CrmUserSelect,
  CrmProcessTypeSelect,
  CrmCampaignSelect
} from 'crm-components'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'
import { getAPIDateFormat } from 'crm-utils/time-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import crmGenericCommandQlHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { qlqfetchAccount } from 'crm-data/accounts'
import { DatePicker } from 'util-components'
import moment from 'moment'

const AccountProcessItem = props => {
  const { item, account } = props
  const [isEditing, setIsEditing] = useState(false)
  const [processStepName, setProcessStepName] = useState('-')

  useEffect(() => {
    if (item.current_step && item.current_step.process_step_type) {
      setProcessStepName(item.current_step.process_step_type.name)
    }
    return () => {}
  }, [item])

  const handleStartCorrectProcessResult = e => {
    e.preventDefault()
    if (!props.isManager) {
      toastr.info("You don't have permission to edit")
    } else {
      setIsEditing(true)
    }
  }

  const handleCancel = () => {
    setIsEditing(false)
  }

  const submitForm = async pData => {
    const data = { ...pData }
    const command = {
      type: 'salesAccount.adminCorrectResult',
      aggregate_uid: account.account_uid,
      aggregate_type: 'salesAccount',
      payload: {
        process_uid: data.process_uid,
        started_time: data.started_time,
        completed_time: data.completed_time,
        owner_user_uid: data.owner_user.user_uid,
        sales_campaign_uid: data.sales_campaign.campaign_uid,
        process_type_uid: data.process_type.process_type_uid,
        completed_by_user_uid: data.completed_by_user_uid
      }
    }
    const refetchQueries = []
    refetchQueries.push({
      query: qlqfetchAccount,
      variables: { account_uid: account.account_uid }
    })
    try {
      const res = await props.onRunCommand(command, { refetchQueries })
      toastr.success('Saved')
      setIsEditing(false)
      return res
    } catch (err) {
      console.error('Error saving process result', err)
      toastr.error('Error saving process result')
      toastr.error(err.message)
      return { isError: true, error: err }
    }
  }
  const renderNeedsAnalysis = () => {
    const analysis =
      item.attributes && item.attributes.needsAnalysis
        ? item.attributes.needsAnalysis.value
        : ''
    return (
      <CrmLabeledField
        label="Analysis"
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
      >
        {analysis}
      </CrmLabeledField>
    )
  }
  const renderValueProposition = () => {
    const proposition =
      item.attributes && item.attributes.valueProposition
        ? item.attributes.valueProposition.value
        : ''
    return (
      <CrmLabeledField
        label="Proposition"
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
      >
        {proposition}
      </CrmLabeledField>
    )
  }

  const renderStarted = () => {
    return (
      <CrmLabeledField
        label={
          <FormattedMessage
            id="crm.account.item.started"
            defaultMessage="Started"
          />
        }
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
      >
        <FormattedDate value={item.started_time} />
      </CrmLabeledField>
    )
  }

  const renderCompletedTime = () => {
    return (
      <CrmLabeledField
        label={
          <FormattedMessage
            id="crm.account.item.completed"
            defaultMessage="Completed"
          />
        }
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
      >
        {item.completed_time ? (
          <FormattedDate value={item.completed_time} />
        ) : (
          '-'
        )}
      </CrmLabeledField>
    )
  }

  const renderOwner = () => {
    return (
      <CrmLabeledField
        label={
          <FormattedMessage id="crm.ui.account.owner" defaultMessage="Owner" />
        }
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
      >
        {item.owner_user ? item.owner_user.full_name : ''}
        {item.owner_group ? item.owner_group.name : ''}
      </CrmLabeledField>
    )
  }

  const renderSalesCampaign = () => {
    return (
      <CrmLabeledField
        label={
          <FormattedMessage
            id="crm.ui.widget.sales_campaign"
            defaultMessage="Sales Campaign"
          />
        }
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
      >
        {item.sales_campaign ? item.sales_campaign.name : ''}
      </CrmLabeledField>
    )
  }

  const renderType = () => {
    return (
      <CrmLabeledField
        label={
          <FormattedMessage
            id="crm.global.account.type"
            defaultMessage="Type"
          />
        }
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
      >
        {item.process_type ? item.process_type.name : ''}
      </CrmLabeledField>
    )
  }

  const renderCompletedBy = () => {
    return (
      <CrmLabeledField
        label={
          <FormattedMessage
            id="crm.account.item.completed_by"
            defaultMessage="Completed by"
          />
        }
        noMargin
        style={{ marginTop: 5, marginRight: 5 }}
      >
        {item.completed_by_user ? item.completed_by_user.full_name : ''}
      </CrmLabeledField>
    )
  }

  return (
    <div
      className="util-flexRowSpaceBetween util-flexGrow CrmMdCard util-paddingMd util-marginBottom"
      key={item.process_uid}
    >
      {!isEditing && (
        <React.Fragment>
          <div className="util-fullWidth util-pointer">
            <Link
              key={item.process_uid}
              to={`/crm/account/${account.account_uid}/history/${item.process_uid}`}
            >
              <div className="util-textInfo">{processStepName}</div>
              <div className="row">
                <div className="col-md-4">{renderStarted()}</div>
                <div className="col-md-4">{renderCompletedTime()}</div>
                <div className="col-md-4">{renderOwner()}</div>
              </div>
              <div className="row">
                <div className="col-md-4">{renderSalesCampaign()}</div>
                <div className="col-md-4">{renderType()}</div>
                <div className="col-md-4">{renderCompletedBy()}</div>
              </div>
              <div className="row">
                <div className="col-md-6">{renderNeedsAnalysis()}</div>
                <div className="col-md-6">{renderValueProposition()}</div>
              </div>
            </Link>
          </div>
          <div>
            <FontAwesomeIcon
              className="util-marginLeft util-pointer"
              icon="pencil-alt"
              title="Click to edit process"
              onClick={handleStartCorrectProcessResult}
            />
          </div>
        </React.Fragment>
      )}

      {isEditing && (
        <React.Fragment>
          <Formik
            enableReinitialize
            initialValues={{ ...item }}
            validate={values => {
              let errors = {}
              if (!values.completed_time) errors.completed_time = '* required'
              return errors
            }}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setStatus }
            ) => {
              const res = await submitForm(values)
              setSubmitting(false)
              if (res.isError) {
                setErrors({ apiError: res.error })
              } else {
                setStatus({ success: true })
              }
            }}
          >
            {({ values, errors, status, isSubmitting }) => {
              return (
                <Form className="CrmForm">
                  <div className="util-flexColumn util-fullWidth">
                    <div className="util-flexGrow">
                      <div className="util-marginBottom">{processStepName}</div>
                      <div className="row">
                        <div className="col-md-4">
                          <FieldFormik
                            render={({ field, form }) => (
                              <FieldFormik
                                render={({ field, form }) => (
                                  <DatePicker
                                    placeholder="Started Date"
                                    input={{
                                      value: values.started_time
                                        ? new Date(values.started_time)
                                        : null,
                                      onChange: value => {
                                        form.setFieldValue(
                                          'started_time',
                                          moment(value).format('YYYY-MM-DD')
                                        )
                                      }
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <FieldFormik
                            render={({ field, form }) => (
                              <FieldFormik
                                render={({ field, form }) => (
                                  <DatePicker
                                    placeholder="Completed Date"
                                    input={{
                                      value: values.completed_time
                                        ? new Date(values.completed_time)
                                        : null,
                                      onChange: value => {
                                        form.setFieldValue(
                                          'completed_time',
                                          moment(value).format('YYYY-MM-DD')
                                        )
                                      }
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                          {errors.completed_time && (
                            <div className="CrmForm-field-error">
                              {errors.completed_time}
                            </div>
                          )}
                        </div>
                        <div className="col-md-4">
                          <FieldFormik
                            render={({ field, form }) => (
                              <CrmUserSelect
                                placeholder="Owner"
                                input={{
                                  value: values.owner_user
                                    ? values.owner_user.user_uid
                                    : '',
                                  onChange: value => {
                                    form.setFieldValue(
                                      'owner_user.user_uid',
                                      value
                                    )
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <FieldFormik
                            render={({ field, form }) => (
                              <FieldFormik
                                render={({ field, form }) => (
                                  <CrmCampaignSelect
                                    type="sale"
                                    placeholder={
                                      <FormattedMessage
                                        id="crm.ui.widget.sales_campaign"
                                        defaultMessage="Sales Campaign"
                                      />
                                    }
                                    input={{
                                      value: values.sales_campaign.campaign_uid,
                                      onChange: value => {
                                        form.setFieldValue(
                                          'sales_campaign.campaign_uid',
                                          value
                                        )
                                      }
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <FieldFormik
                            render={({ field, form }) => (
                              <CrmProcessTypeSelect
                                input={{
                                  value: values.process_type
                                    ? values.process_type.process_type_uid
                                    : '',
                                  onChange: value => {
                                    form.setFieldValue(
                                      'process_type.process_type_uid',
                                      value
                                    )
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <FieldFormik
                            render={({ field, form }) => (
                              <CrmUserSelect
                                placeholder="Completed by"
                                input={{
                                  value: values.completed_by_user_uid
                                    ? values.completed_by_user_uid
                                    : '',
                                  onChange: value => {
                                    form.setFieldValue(
                                      'completed_by_user_uid',
                                      value
                                    )
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="util-flexRowSpaceBetween util-marginTop">
                      <div className="util-flexGrow" />
                      <div className="util-flexRowRight">
                        <CrmButton
                          style={{ marginRight: 5 }}
                          onClick={handleCancel}
                          icon="close"
                          iconButton
                          enabled={!isSubmitting}
                        />
                        <CrmButton
                          style={{ marginLeft: 5, marginRight: 5 }}
                          type="submit"
                          icon={isSubmitting ? '' : 'check'}
                          iconButton
                          isSaving={isSubmitting}
                          enabled={!isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </React.Fragment>
      )}
    </div>
  )
}

AccountProcessItem.propTypes = {
  item: string.isRequired,
  isEditing: bool.isRequired,
  account: shape({}).isRequired,
  onRunCommand: func.isRequired,
  isManager: bool.isRequired
}

AccountProcessItem.defaultProps = {}

export default crmGenericCommandQlHoc(injectIntl(AccountProcessItem))
