/* eslint-disable  */
import salesSpain from './data/sales-spain'
import salesPortugal from './data/sales-portugal'

const parseCountryData = data => {
  return data.map(item => {
    const ret = { name: item.name, type: 'Sales Person', results: [] }
    ret.results.push({
      month: item.month,
      sales: item.sales,
      arpu: item.arpu,
      payback: item.payback,
      arpuAvg: item.arpu
    })
    return ret
  })
}

// Sumup function
const sumup = region => {
  region.results = []
  for (let i = 1; i < 7; i++) {
    const su = {
      month: i,
      sales: 0,
      arpu: 0,
      arpuCount: 0,
      payback: 0,
      arpuAvg: 0
    }
    if (region.items) {
      region.items.forEach(item => {
        if (item.results) {
          item.results.forEach(r => {
            if (r.month === i) {
              ;['sales', 'arpu', 'payback'].forEach(field => {
                su[field] += r[field]
              })
              su.arpuCount += 1
              su.arpuAvg = Math.round(su.arpu / su.arpuCount)
            }
          })
        }
      })
    }
    region.results.push(su)
  }
  return region
}

let sg = [
  { name: 'Yasa Phua', sales: 703, arpu: 20.61, payback: 9.13, month: 2 },
  { name: 'Felicia Ng', sales: 217, arpu: 28.44, payback: 6.91, month: 2 },
  { name: 'Sor Sin Yee', sales: 53, arpu: 27, payback: 0.41, month: 2 },
  { name: 'Liow Wee Xiang', sales: 42, arpu: 26.19, payback: 10.11, month: 2 },
  { name: 'Joey Lim', sales: 22, arpu: 29, payback: 2.63, month: 2 },
  { name: 'Elson Wang', sales: 13, arpu: 22.62, payback: 19.82, month: 2 },
  { name: 'Daimen ', sales: 10, arpu: 25, payback: 14.16, month: 2 },
  { name: 'Kaden Sim', sales: 2, arpu: 25, payback: 68.4, month: 2 },
  { name: 'Law Chun Liang', sales: 2, arpu: 26, payback: 75.75, month: 2 }
]
sg = parseCountryData(sg)

const singapore = { name: 'Singapore', items: sg, type: 'Country' }
sumup(singapore)

const countriesAsia = [
  // { name: 'Thailand', items: regionsTH, type: 'Country' },
  singapore
  // { name: 'Philipines', items: [], type: 'Country' }
]

const polandNorthSales = parseCountryData([
  { name: 'Pawel rakozcy', sales: 53, arpu: 38.45, payback: 18.35, month: 2 },
  { name: 'Iwo kaminski', sales: 36, arpu: 40.75, payback: 13.67, month: 2 },
  { name: 'Robert rymerczyk', sales: 29, arpu: 41.1, payback: 11.46, month: 2 },
  { name: 'Lukacz stachira', sales: 24, arpu: 42, payback: 14.45, month: 2 },
  { name: 'Pawel bielawski', sales: 24, arpu: 36.75, payback: 14.87, month: 2 },
  {
    name: 'Tomasz wojciechowski',
    sales: 15,
    arpu: 40.07,
    payback: 19.61,
    month: 2
  },
  {
    name: 'Michal drobniewski',
    sales: 13,
    arpu: 48.46,
    payback: 18.47,
    month: 2
  }
])
const polandSouthSales = parseCountryData([
  {
    name: 'Lukacz jakubowski',
    sales: 12,
    arpu: 38.17,
    payback: 25.02,
    month: 2
  },
  { name: 'Jakub nowak', sales: 12, arpu: 42.42, payback: 27.07, month: 2 },
  { name: 'Jakub toczek', sales: 9, arpu: 34.67, payback: 25.85, month: 2 },
  {
    name: 'Katarzina nowocielska',
    sales: 7,
    arpu: 35,
    payback: 54.47,
    month: 2
  },
  { name: 'Pawel bonczyk', sales: 5, arpu: 53.8, payback: 16.82, month: 2 }
])

const poland = sumup({
  name: 'Poland',
  type: 'Country',
  items: [
    sumup({
      name: 'North Poland',
      type: 'Country Region',
      items: polandNorthSales
    }),
    sumup({
      name: 'South Poland',
      type: 'Country Region',
      items: polandSouthSales
    })
  ]
})

const countriesEurope = [
  poland,
  sumup({
    name: 'Portugal',
    items: parseCountryData(salesPortugal),
    type: 'Country'
  }),
  sumup({ name: 'Spain', items: parseCountryData(salesSpain), type: 'Country' })
]

const groupRegions = [
  sumup({ name: 'Asia', items: countriesAsia, type: 'Countries Group' }),
  sumup({ name: 'Europe', items: countriesEurope, type: 'Countries Group' })
]
const global = { name: 'Global', items: groupRegions, type: 'Global' }
sumup(global)

export default global
