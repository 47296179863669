const rmOpportunities = {
  type: 'analitics_widget',
  code: 'rmOpportunities',
  def_version: 5,
  title: 'RM Opportunities',
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: {},
          fields: ['owner_user_uid'],
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {
            owner_user_uid: 'user_uid'
          },
          qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                edges { edge { opportunity_uid owner_user_uid vehicle_count } } } }`,
          qlVariables: {
            aggregate: [
              { id: 'vehicle_count', type: 'sum' },
              { id: 'opportunity_uid', type: 'count' }
            ],
            groupBy: ['owner_user_uid'],
            limit: 10
          },
          qlEdges: true,
          qlRoot: 'opportunities'
        },
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          alias: 'WonOpportunities',
          type: 'QM',
          filter: {
            status_code: 'won',
            process_step_type_kind: 'won'
          },
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {
            completed_time: 'activity_date',
            owner_user_uid: 'user_uid'
          },
          groupBy: ['owner_user_uid'],
          qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                edges { edge { opportunity_uid owner_user_uid vehicle_count } } } }`,
          qlEdges: true,
          qlRoot: 'opportunities'
        },
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          alias: 'QuotedOpportunities',
          type: 'QM',
          filter: {
            is_quoted: 'true',
            status_code: 'active'
          },
          fields: ['owner_user_uid'],
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          groupBy: ['owner_user_uid'],
          contextMapping: {
            completed_time: 'activity_date',
            owner_user_uid: 'user_uid'
          },
          qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                edges { edge { opportunity_uid owner_user_uid vehicle_count } } } }`,
          qlEdges: true,
          qlRoot: 'opportunities'
        },
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          alias: 'ActiveOpportunities',
          type: 'QM',
          filter: {
            status_code: 'active'
          },
          fields: ['owner_user_uid'],
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {
            completed_time: 'activity_date',
            owner_user_uid: 'user_uid'
          },
          groupBy: ['owner_user_uid'],
          qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                edges { edge { opportunity_uid owner_user_uid vehicle_count } } } }`,
          qlEdges: true,
          qlRoot: 'opportunities'
        },
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          alias: 'OverdueOpportunities',
          type: 'QM',
          filter: {
            is_overdue: 'true',
            status_code: 'active'
          },
          fields: ['owner_user_uid'],
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {
            completed_time: 'activity_date',
            owner_user_uid: 'user_uid'
          },
          groupBy: ['owner_user_uid'],
          qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                edges { edge { opportunity_uid owner_user_uid vehicle_count } } } }`,
          qlEdges: true,
          qlRoot: 'opportunities'
        },

        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          alias: 'LostOpportunities',
          type: 'QM',
          filter: {
            status_code: 'lost'
          },
          fields: ['owner_user_uid'],
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {
            completed_time: 'activity_date',
            owner_user_uid: 'user_uid'
          },
          groupBy: ['owner_user_uid'],
          qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
            opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                edges { edge { opportunity_uid owner_user_uid vehicle_count } } } }`,
          qlEdges: true,
          qlRoot: 'opportunities'
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {},
          fields: [
            'department_uid',
            'full_name',
            'user_uid',
            'contract_start_date',
            'contract_end_date',
            'department_name',
            'is_active'
          ]
        }
      ],
      combineDataSources: {
        name: 'CombinedDataSource',
        targetKeyField: 'user_uid',
        mapping: [
          {
            fromDataSourceName: 'OpportunitiesListQM',
            keyField: 'owner_user_uid',
            mapping: [
              {
                from: 'vehicle_count',
                to: 'total_opportunities_size'
              },
              {
                from: 'opportunity_uid',
                to: 'total_opportunities_count'
              }
            ]
          },
          {
            fromDataSourceName: 'WonOpportunities',
            keyField: 'owner_user_uid',
            mapping: [
              {
                from: 'vehicle_count',
                to: 'won_opportunities_size'
              },
              {
                from: 'opportunity_uid',
                to: 'won_opportunities_count'
              }
            ]
          },
          {
            fromDataSourceName: 'LostOpportunities',
            keyField: 'owner_user_uid',
            mapping: [
              {
                from: 'opportunity_uid',
                to: 'lost_opportunities_count'
              },
              {
                from: 'vehicle_count',
                to: 'lost_opportunities_size'
              }
            ]
          },
          {
            fromDataSourceName: 'QuotedOpportunities',
            keyField: 'owner_user_uid',
            mapping: [
              {
                from: 'opportunity_uid',
                to: 'quoted_opportunities_count'
              },
              {
                from: 'vehicle_count',
                to: 'quoted_opportunities_size'
              }
            ]
          },
          {
            fromDataSourceName: 'ActiveOpportunities',
            keyField: 'owner_user_uid',
            mapping: [
              {
                from: 'opportunity_uid',
                to: 'active_opportunities_count'
              },
              {
                from: 'vehicle_count',
                to: 'active_opportunities_size'
              }
            ]
          },
          {
            fromDataSourceName: 'OverdueOpportunities',
            keyField: 'owner_user_uid',
            mapping: [
              {
                from: 'opportunity_uid',
                to: 'overdue_opportunities_count'
              },
              {
                from: 'vehicle_count',
                to: 'overdue_opportunities_size'
              }
            ]
          },

          {
            fromDataSourceName: 'CrmUser',
            keyField: 'user_uid',
            type: 'left',
            mapping: [
              {
                from: 'full_name'
              },
              {
                from: 'contract_start_date'
              },
              { from: 'contract_end_date' },
              { from: 'department_name' },
              { from: 'is_active' }
            ]
          }
        ]
      }
    },
    layout: {
      items: [
        {
          type: 'table',
          def_version: 5,
          props: {
            generate_summary: true,
            generate_average: true,
            columnGroups: [
              {
                Header: '',
                colSpan: 3
              },
              {
                Header: 'Total Opportunities',
                colSpan: 2
              },
              {
                Header: 'Quoted',
                colSpan: 2
              },
              {
                Header: 'Active',
                colSpan: 2
              },
              {
                Header: 'Overdue',
                colSpan: 2
              },
              {
                Header: 'Won',
                colSpan: 2
              },
              {
                Header: 'Lost',
                colSpan: 2
              }
            ],
            columns: [
              {
                Header: '#',
                accessor: 'index',
                width: 30
              },
              {
                Header: 'RM',
                accessor: 'full_name',
                classNameHandler: row => (row.original.is_active ? '' : 'util-opacity50'),
                detailsPopup: 'user_dashboard',
                detailsFilterMapping: {
                  user_uid: 'user_uid'
                }
              },
              {
                Header: 'Start Date',
                accessor: 'contract_start_date',
                width: 80,
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Size',
                accessor: 'total_opportunities_size',
                width: 50,
                type: 'number',
                maximumFractionDigits: 2,
                detailsPopup: 'opportunity',
                detailsFilter: {
                  process_step: {
                    type: {
                      is_final_step: false
                    }
                  }
                },
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                },
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Count',
                accessor: 'total_opportunities_count',
                width: 50,
                type: 'number',
                maximumFractionDigits: 2,
                detailsPopup: 'opportunity',
                detailsFilter: {
                  process_step: {
                    type: {
                      is_final_step: false
                    }
                  }
                },
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                },
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center'
              },
              {
                Header: 'Size',
                accessor: 'quoted_opportunities_size',
                width: 50,
                type: 'number',
                maximumFractionDigits: 1,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {},
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Count',
                accessor: 'quoted_opportunities_count',
                width: 50,
                type: 'number',
                maximumFractionDigits: 1,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {},
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Size',
                accessor: 'active_opportunities_size',
                width: 50,
                type: 'number',
                maximumFractionDigits: 1,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {},
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Count',
                accessor: 'active_opportunities_count',
                width: 50,
                type: 'number',
                maximumFractionDigits: 1,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {},
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Size',
                accessor: 'overdue_opportunities_size',
                width: 50,
                type: 'number',
                maximumFractionDigits: 1,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {},
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Count',
                accessor: 'overdue_opportunities_count',
                width: 50,
                type: 'number',
                maximumFractionDigits: 1,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {},
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Size',
                accessor: 'won_opportunities_size',
                width: 50,
                type: 'number',
                maximumFractionDigits: 0,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {
                  process_step: { type: { kind: 'won' } }
                },
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Count',
                accessor: 'won_opportunities_count',
                width: 50,
                type: 'number',
                maximumFractionDigits: 0,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {
                  process_step_type_kind: 'won'
                },
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Size',
                accessor: 'lost_opportunities_size',
                width: 50,
                type: 'number',
                maximumFractionDigits: 0,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {
                  process_step_type_kind: 'lost'
                },
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              },
              {
                Header: 'Count',
                accessor: 'lost_opportunities_count',
                width: 50,
                type: 'number',
                maximumFractionDigits: 0,
                className: 'util-pointer',
                align: 'right',
                headerAlign: 'center',
                detailsPopup: 'opportunity',
                detailsFilter: {
                  process_step_type_kind: 'lost'
                },
                detailsFilterMapping: {
                  opp_owner_user_uid: 'user_uid'
                }
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'CombinedDataSource'
            }
          }
        }
      ]
    }
  }
}

export default rmOpportunities
