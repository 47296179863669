// Layouts
import CrmFullpageContainer from './layout/crm-fullpage-container.jsx'
import { CrmHbox, CrmVbox } from './layout/crm-box.jsx'
import CrmTabs from './layout/crm-tabs.jsx'
import CrmTabNavigator from './layout/crm-tab-navigator.jsx'

// Forms
import {
  CrmButton,
  CrmLoadingIcon,
  CrmTextArea,
  CrmDropdown,
  CrmListbox,
  CrmTextInput,
  CrmTextInputNumber,
  CrmDropdownButton,
  CrmSubmitButton,
  ConnectedCrmDropdown,
  CrmCheckbox,
  CrmFormInputDropdown,
  CrmInputDropdown,
  CrmTable,
  CrmTree,
  CrmModal,
  CrmLabeledField
} from '@cartrack-crm/ui'

import CrmOptionButtons from './forms/crm-option-buttons.jsx'
import CrmFormSection_ from './crm-form/crm-form-section.jsx'
import CrmFormHeader from './crm-form/crm-form-header.jsx'
import CrmForm_ from './crm-form/crm-form.jsx'
import CrmFormGroup from './crm-form/crm-form-group.jsx'
import CrmLabeledFieldEditable_ from './forms/crm-labeled-field-editable.jsx'
import CrmDayPickerInput from './forms/crm-day-picker-input.jsx'
import CrmDayHourPickerInput from './forms/crm-day-hour-picker-input.jsx'
import CrmDateRangeDropdown from './forms/crm-date-range-dropdown'

import CrmActivityTypeSelect from './modules/activities/crm-activity-types-select.jsx'
import CrmUsersSelect from './modules/user/crm-users-select.jsx'
import CrmUserGroupSelect from './modules/user/crm-user-group-select'
import CrmResourcesSelect from './modules/resources/resources-select.jsx'
import CrmQuotationSelectorQL from './modules/resources/crm-quotation-selector.jsx'
import CrmPersonSelect from './modules/accounts/person-select.jsx'
import FlatTable from './flat-table.jsx'
import CrmDynamicTable from './crm-dynamic-table.jsx'
import CrmAgTable from './crm-ag-table.jsx'
import CrmQLActionButton from './crm-ql-action-button.jsx'

// Config
import CrmProfileSelect from './modules/config/profile-select.jsx'
import CrmInstanceSelect from './modules/config/instance-select.jsx'
import CrmPermissionSelect from './modules/config/permission-select.jsx'
import CrmSaleChannelSelect from './modules/config/sale-channel-select.jsx'
import CrmProcessTypeSelect from './modules/config/process-type-select.jsx'
// Modules
import CrmCampaignSelect from './modules/accounts/campaign-select.jsx'
import LeadSourceSelect from './modules/accounts/lead-source-select.jsx'
import LeadSourceTypeSelect from './modules/accounts/lead-source-type-select.jsx'
import BusinessSourceSelect from './modules/accounts/business-source-select.jsx'
import CrmTerritorySelect from './modules/settings/territory-select.jsx'
import CrmOpportunityTypeSelect from './modules/opportunities/opportunity-type-select'
import CrmDictionaryValueSelect from './modules/common/dictionary-value-select.jsx'
import CrmResourcePoolSelect from './modules/common/resource-pool-select.jsx'

import CrmGoogleTranslate from './crm-gg-translate.jsx'
import CrmMessageWithCounterHoc from './crm-message-with-counter-hoc.jsx'

import CrmPackageItemSelect from './modules/package/crm-package-item-select.jsx'

// Platform
import CapDatatypeSelect from './modules/platform/cap-datatype-select.jsx'
import CapDatatypeFieldSelect from './modules/platform/cap-datatype-field-select.jsx'
import CapFunctionSelect from './modules/platform/cap-function-select.jsx'

import CrmDocumentTypeSelect from './modules/documents/document-type-select.jsx'
import CrmProductsSelect from './modules/products/products-select.jsx'
import CrmProductsTerritorySelect from './modules/products/products-territory-select.jsx'
import CrmCategoryProductsSelect from './modules/products/category-product-select.jsx'
import crmIntlComponent from './crm-intl-component.jsx'
import CrmHelpButton from './crm-help-button.jsx'
import CrmDepartmentSelect from './modules/user/crm-department-select.jsx'
import CrmCamsBusinessSourceSelect from './modules/user/crm-cams-business-source-select.jsx'
import CrmCamsSalesAgentsSelect from './modules/user/crm-cams-sales-agents-select.jsx'
import CrmSupervisorSelect from './modules/user/crm-supervisor-select.jsx'
import CrmVehicleSelect from './modules/user/crm-vehicle-select.jsx'
import CrmUserSelectorQL from './modules/user/crm-user-selector.jsx'
import CrmActivityDateTimeSelect from './modules/activities/crm-activity-datetime-select.jsx'
import CrmTaskQueueSelect from './modules/user/crm-task-queue-select.jsx'

// Form
import CrmValidatedField from './forms/crm-validated-field.jsx'

// Utils
import FormattedDateTime from './utils/formatted-date-time.jsx'
import CrmErrorBoundary from './error-boundary.jsx'
import CrmGenericSelectorFactory from './crm-generic-selector-ql.jsx'
import CrmGenericFormQL from './ql/crm-generic-form-ql.jsx'
import CrmAccountsDatasetSelect from './modules/accounts/accounts-dataset-select.jsx'
import CrmJsonPreview, {
  CrmJsonPreviewModal,
  CrmJsonPreviewInline
} from './utils/crm-json-preview.jsx'
import CrmJsonEditArea from './utils/crm-json-edit-area.jsx'
import CrmMessageBlock from './utils/crm-message-block.jsx'
// Charts
import { CrmHeatmapChart } from '@cartrack-crm/crm-analytics'
import CrmInfoBlock from './crm-info-block.jsx'

// Organization
import CrmOrganizationUnitSelect from './modules/organization/organization-select.jsx'
import CrmOrganizationUnitTree, {
  CrmOrganizationUnitTreeInner
} from './modules/organization/organization-tree.tsx'

import CrmList from '@material-ui/core/List'
import CrmListItem from '@material-ui/core/ListItem'
import CrmListItemText from '@material-ui/core/ListItemText'
import CrmListItemIcon from '@material-ui/core/ListItemIcon'

import CrmAnalyticsTable from './table/analytics-table.jsx'
import CrmGoogleMap from './crm-google-map.jsx'
import CrmTranslations from './crm-translations.jsx'
import CrmEmailPreview from './crm-email-preview'

const CrmFormSection = crmIntlComponent(CrmFormSection_)
const CrmForm = crmIntlComponent(CrmForm_)
const CrmLabeledFieldEditable = crmIntlComponent(CrmLabeledFieldEditable_)

export {
  CrmFullpageContainer,
  CrmHbox,
  CrmVbox,
  CrmTabs,
  CrmTabNavigator,
  CrmButton,
  CrmSubmitButton,
  CrmTextInput,
  CrmTextInputNumber,
  CrmTextArea,
  CrmDropdownButton,
  CrmDropdown,
  CrmListbox,
  ConnectedCrmDropdown,
  CrmInfoBlock,
  CrmInputDropdown,
  CrmFormInputDropdown,
  CrmCheckbox,
  CrmForm,
  CrmFormGroup,
  CrmFormSection,
  CrmFormHeader,
  CrmLabeledField,
  CrmLabeledFieldEditable,
  CrmDayPickerInput,
  CrmDayHourPickerInput,
  CrmOptionButtons,
  CrmTable,
  CrmAgTable,
  CrmModal,
  CrmValidatedField,
  CrmUsersSelect as CrmUserSelect,
  CrmUsersSelect,
  CrmUserGroupSelect,
  CrmActivityTypeSelect,
  CrmLoadingIcon,
  CrmPersonSelect,
  CrmMessageWithCounterHoc,
  CrmSaleChannelSelect,
  CrmProcessTypeSelect,
  CrmTerritorySelect,
  CapDatatypeSelect,
  CapDatatypeFieldSelect,
  CapFunctionSelect,
  CrmOpportunityTypeSelect,
  CrmProfileSelect,
  CrmInstanceSelect,
  CrmPermissionSelect,
  CrmDocumentTypeSelect,
  CrmDictionaryValueSelect,
  CrmProductsSelect,
  CrmProductsTerritorySelect,
  CrmCampaignSelect,
  CrmAccountsDatasetSelect,
  FlatTable,
  CrmGoogleTranslate,
  CrmDynamicTable,
  FormattedDateTime,
  CrmHelpButton,
  CrmErrorBoundary,
  CrmQLActionButton,
  CrmResourcesSelect,
  CrmQuotationSelectorQL,
  CrmGenericSelectorFactory,
  CrmGenericFormQL,
  CrmResourcePoolSelect,
  CrmHeatmapChart,
  CrmList,
  CrmListItem,
  CrmListItemText,
  CrmListItemIcon,
  CrmJsonPreview,
  CrmJsonPreviewModal,
  CrmJsonPreviewInline,
  CrmJsonEditArea,
  CrmMessageBlock,
  CrmPackageItemSelect,
  CrmCategoryProductsSelect,
  LeadSourceSelect,
  LeadSourceTypeSelect,
  BusinessSourceSelect,
  CrmDepartmentSelect,
  CrmCamsBusinessSourceSelect,
  CrmCamsSalesAgentsSelect,
  CrmSupervisorSelect,
  CrmTaskQueueSelect,
  CrmVehicleSelect,
  CrmUserSelectorQL,
  CrmActivityDateTimeSelect,
  CrmAnalyticsTable,
  CrmGoogleMap,
  CrmDateRangeDropdown,
  CrmTranslations,
  CrmOrganizationUnitSelect,
  CrmEmailPreview,
  CrmTree,
  CrmOrganizationUnitTree,
  CrmOrganizationUnitTreeInner
}
