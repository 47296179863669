/* eslint-disable camelcase */
import React from 'react'
import { bool, func, shape } from 'prop-types'

import cloneDeep from 'clone-deep'
import { CrmModal } from 'crm-components'
import styles from './analytics-details-popup.scss'

import userDashboardPopupHoc from 'crm-modules/dashboard/hoc/ct-dashboards/user-dashboard-popup-hoc'
import RmSummaryDashboard from '../ct-dashboards/rm-dashboard/rm-summary-dashboard.jsx'
import AgentSummaryDashboard from '../ct-dashboards/rm-dashboard/agent-summary-dashboard.jsx'
import { LoadingIcon } from 'util-components'
import { getDetailsComponent } from './_with-leads-popup-hoc.jsx'

class UserDashboardPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleShowLeadsPopup = params => {
    this.setState({
      isOpenInnerDetails: true,
      filters: params.filters,
      type: params.type,
      params
    })
  }
  handleCloseInner = () => {
    this.setState({ isOpenInnerDetails: false })
  }

  render() {
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="User Dashboard"
        onClose={this.props.onClose}
        size="xl"
        className={styles.AnalyticsDetailsPopup}
      >
        {this.props.loading && (
          <div className="util-flexRowCenter">
            <LoadingIcon />
          </div>
        )}
        <div className="util-paddingMd">
          {this.props.user &&
            !this.props.loading &&
            this.props.dashboardType === 'rm' && (
              <RmSummaryDashboard
                {...this.props}
                analyticsContext={{ filters: this.props.filters }}
                onShowLeadsPopup={this.handleShowLeadsPopup}
              />
            )}
          {this.props.user && this.props.dashboardType === 'telesales' && (
            <AgentSummaryDashboard
              {...this.props}
              analyticsContext={{ filters: this.props.filters }}
              onShowLeadsPopup={this.handleShowLeadsPopup}
            />
          )}
        </div>
      </CrmModal>
    )
  }
}

UserDashboardPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: shape({})
}
UserDashboardPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

export default userDashboardPopupHoc(UserDashboardPopup)
