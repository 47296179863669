/* eslint-disable camelcase */
import { callQLApi } from '../api-utils'

export const leadSourceAllFields =
  ' lead_source_type_uid instance_uid name code kind description '
export default {
  fetchLeadSourcesApi: params => {
    const query = `query master { 
      master (master_uid: "${params.payload.master_uid}") { 
        lead_source_types  { ${leadSourceAllFields} }  
      } 
    }`
    return callQLApi(query).then(res => res.master.lead_source_types)
  },
  createLeadSourceApi: lead_source_in => {
    const qlParams = {
      query: ` mutation  lead_source_create($lead_source_in:lead_source_in) { lead_source_create(lead_source_in: $lead_source_in) {  ${leadSourceAllFields}  }  }  `,
      variables: {
        lead_source_in
      }
    }
    return callQLApi(qlParams).then(res => res.lead_source_create)
  },
  updateLeadSourceApi: lead_source_in => {
    const qlParams = {
      query: ` mutation  lead_source_update($lead_source_in:lead_source_in) { lead_source_update(lead_source_in: $lead_source_in) {  ${leadSourceAllFields}  }  }  `,
      variables: {
        lead_source_in
      }
    }
    return callQLApi(qlParams).then(res => res.lead_source_update)
  }
}
