import { callQLWithMetaApi } from './api-utils'

export const qlAccountBasicFields = ' account_uid name '

export const parseOpportunity = type => ({
  ...type,
  is_active: type.is_active === 't' || type.is_active === true,
  confidence_level: type.confidence_level
    ? type.confidence_level.toString()
    : undefined
})

export function countOpportunitiesApi(lparams) {
  console.log('countOpportunitiesApi', lparams)
  const params = { ...lparams }
  if (!params.pagination) {
    params.pagination = {}
  }
  params.pagination.pageSize = 0
  const query = `query opportunities_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
  ) {
    opportunities_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
    ) { count } } `
  return callQLWithMetaApi(query, {
    filter: lparams.filter,
    limit: 0
  }).then(res => res.data.opportunities_list_qm_paged.count)
}
