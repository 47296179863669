/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import gql from 'graphql-tag'
import { graphql, compose } from 'react-apollo'
import { toastr } from 'react-redux-toastr'

import { CrmButton, CrmTextInput } from 'crm-components'
import { accountFieldsUpdated } from 'crm-duxs/account-reducer'
import {
  qlCreateMarketPotentialMutation,
  qlGetMartketPotential
} from 'crm-data/account-market-potential'

class ValidationMarketPotential extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      size: this._getFleetSizeFromPotential(props.marketPotential),
      // eslint-disable-next-line
      other_size: props.marketPotential
        ? Number(props.marketPotential.other_size)
        : undefined,
      isEdited: false,
      isSaving: false
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.marketPotential !== this.props.marketPotential) {
      this.setState({
        ...this.state,
        size: this._getFleetSizeFromPotential(nextProps.marketPotential),
        // eslint-disable-next-line
        other_size: nextProps.marketPotential
          ? Number(nextProps.marketPotential.other_size)
          : undefined
      })
    }
  }
  setStateAsync = state =>
    new Promise(resolve => {
      this.setState(state, resolve)
    })

  handleStartEdit = () => {
    this.setState({ ...this.state, isEdited: true })
  }
  handleBlur = () => {
    console.log('handleBlur')
    this.handleClickSave()
  }

  handleClickSave = async () => {
    if (!this.state.other_size) {
      return toastr.error('Please provide new market size')
    }
    console.log('handleSave', this.props)

    if (
      !this.props.marketPotential ||
      Number(this.state.other_size) !==
        Number(this.props.marketPotential.other_size)
    ) {
      const marketPotential = {
        details:
          this.props.marketPotential && this.props.marketPotential.details
            ? { ...this.props.marketPotential.details }
            : undefined,
        size: this.props.marketPotential
          ? this.props.marketPotential.size
          : undefined,
        other_size: Number(this.state.other_size), // eslint-disable-line
        account_uid: this.props.account.account_uid // eslint-disable-line
      }
      console.log('new marketPotential', marketPotential)
      await this.setStateAsync({ ...this.state, isSaving: true })
      await this.props.mutate({
        variables: { marketPotential }
      })
      toastr.info('New market potential saved')
      await this.setStateAsync({
        ...this.state,
        isSaving: false,
        isEdited: false
      })
    }
    this.setState({ ...this.state, newSize: undefined })
    console.log('call doAccountFieldsUpdate')
    this.props.doAccountFieldsUpdated(this.props.account.account_uid)
  }

  _getFleetSizeFromPotential(marketPotential) {
    return marketPotential
      ? Number(marketPotential.size) + Number(marketPotential.other_size)
      : undefined
  }

  handleChangeSize = event => {
    // eslint-disable-next-line
    this.setState({ ...this.state, other_size: event.currentTarget.value })
  }

  render() {
    console.log('Render Market Potential Widget')
    console.log(this.props, this.state)
    // eslint-disable-next-line
    const marketPotential = this.props.marketPotential
    const totalSize = this._getFleetSizeFromPotential(
      this.props.marketPotential
    )
    const isOk = totalSize > 0

    return (
      <div
        className={`LeadActionsWidget-validationItem ${
          isOk
            ? 'LeadActionsWidget-validationItem--ok'
            : 'LeadActionsWidget-validationItem--bad'
        }`}
      >
        <div className="row">
          <div className="col-md-6">
            <div>
              <FormattedMessage
                id="crm.ui.account.fleet_size"
                defaultMessage="Fleet Size"
              />
            </div>
            {this.state.isEdited ? (
              <div className="util-flexRow">
                <CrmTextInput
                  placeholder="Unqualified Fleet Size"
                  placeholderId="crm.ui.account.lead.unqualified_fleet_size"
                  input={{
                    value: this.state.other_size,
                    onChange: this.handleChangeSize
                  }}
                />
                <CrmButton
                  onClick={this.handleClickSave}
                  iconButtona
                  icon="save"
                  small
                  title="Save"
                />
              </div>
            ) : (
              <div
                className="LeadActionsWidget-validationItem-value"
                onClick={this.handleStartEdit}
              >
                {totalSize || (
                  <FormattedMessage
                    id="crm.ui.account.lead.not_found"
                    defaultMessage="Not Found!"
                  />
                )}
              </div>
            )}
          </div>
          <div className="col-md-3 util-flexCenterContent">
            <CrmButton
              title="Edit"
              grouped
              iconButton
              icon="pencil"
              onClick={this.props.onStartEdit}
            />
            {/* <CrmButton title="Check" grouped /> */}
          </div>
          <div
            className="col-md-3 util-flexCenterContent
          LeadActionsWidget-validationItem-status "
          >
            {isOk ? (
              <div>
                <i className="fa fa-check" />{' '}
              </div>
            ) : (
              <div>
                <i className="fa fa-warning" />{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

ValidationMarketPotential.defaultProps = {
  marketPotential: undefined
}

ValidationMarketPotential.propTypes = {
  account: shape({}).isRequired,
  onStartEdit: func.isRequired,
  marketPotential: shape({}),
  mutate: func.isRequired,
  doAccountFieldsUpdated: func.isRequired
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    doAccountFieldsUpdated: accountUid =>
      dispatch(accountFieldsUpdated(accountUid, ['market_potential']))
  }
}

const createMarketPotentialMutation = gql(qlCreateMarketPotentialMutation)

const ValidationMarketPotentialQL = compose(
  graphql(createMarketPotentialMutation, {
    options: ownProps => ({
      refetchQueries: [
        {
          query: gql(qlGetMartketPotential),
          variables: { account_uid: ownProps.account.account_uid }
        }
      ]
    })
  }),
  graphql(gql(qlGetMartketPotential), {
    options: ownProps => {
      console.log('Resolve props')
      console.log(ownProps)
      return {
        // eslint-disable-next-line
        variables: { account_uid: ownProps.account.account_uid }
      }
    },
    props: ({ data, qlGetMartketPotential }) => ({
      data,
      qlGetMartketPotential,
      marketPotential:
        qlGetMartketPotential && qlGetMartketPotential.account
          ? qlGetMartketPotential.account.market_potential
          : undefined
    }),
    name: 'qlGetMartketPotential'
  })
)(ValidationMarketPotential)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidationMarketPotentialQL)
