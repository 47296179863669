/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { qlmGetNextTask } from 'crm-data/tasks'
import { getUserSettings } from 'crm-duxs/crm-reducer'

const GetNextTaskHoc = WrappedComponent => {
  const GetNextTaskHocInner = class extends React.PureComponent {
    static propTypes = {
      client: shape().isRequired,
      userSettings: shape({})
    }
    static defaultProps = {
      userSettings: undefined
    }

    handleGetNextTask = async params => {
      // console.log('GetNextTaskHocInner handleGetNextTask', params, this.props)

      const variables = { ...params }
      if (!variables.filter) {
        variables.filter = {}
      }
      if (this.props.userSettings && this.props.userSettings.nextTask) {
        if (this.props.userSettings.nextTask.campaignUid) {
          variables.filter.campaign_uid = this.props.userSettings.nextTask.campaignUid
        }
        if (
          this.props.userSettings.nextTask.showOverdueFromOtherCampaigns !==
          undefined
        ) {
          variables.filter.show_overdue_from_other_campaigns = this.props.userSettings.nextTask.showOverdueFromOtherCampaigns
        }
      }
      const res = await this.props.client.mutate({
        mutation: qlmGetNextTask,
        variables
      })
      console.log('Got Next Task', res)
      if (res && res.data && res.data.task_get_next) {
        return res.data.task_get_next
      }
      return undefined
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onGetNextTask={this.handleGetNextTask}
        />
      )
    }
  }
  function mapStateToProps(state) {
    return {
      userSettings: getUserSettings(state)
    }
  }

  return connect(mapStateToProps)(withApollo(GetNextTaskHocInner))
}

export default GetNextTaskHoc
