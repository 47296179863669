import React from 'react'

import { _buildStyle } from './generic-widget-renderers'
import { withSlFormRuntime } from '../../../slforms/sl-forms-provider'

import AnalyticsTable from '@cartrack-crm/ui/src/analytics-table/analytics-table'
import { generateTableColumns } from '../../../shared/table-utils/table-utils'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'

class SlfTableEdit extends React.PureComponent {
  render() {
    return <div>SlfTableEdit</div>
  }
}

const SlfTableRuntime = props => {
  const { filters } = useAnalyticsContext()
  const columns = generateTableColumns(props.item.props.columns, {
    onShowLeadsPopup: props.onShowLeadsPopup,
    dataSources: props.definition?.data ?? props.definition?.content?.data,
    analyticsContext: filters
  })

  const parseData = columns => {
    let data =
      props.data && props.data.map
        ? props.data.map(r => {
            const ret = { ...r }
            if (props.item.keyField) {
              ret.id = r[props.item.keyField]
            }
            return r
          })
        : []
    return data
  }

  const data = parseData(columns)

  return (
    <div
      style={{
        padding: '0 0 12px 12px',
        height: '100%',
        overflowY: 'auto',
        boxSizing: 'border-box'
      }}
    >
      <AnalyticsTable
        expandRowsInline={false}
        columns={columns}
        columnGroups={props.columnGroups}
        data={data}
        generateSummary={props.item.props.generate_summary ?? true}
        generateAverage={props.item.props.generate_average}
        initialSort={props.item.props.initialSort}
        scrollBody
      />
    </div>
  )
}

const widget = {
  code: 'crm.table',
  name: 'Table',
  runtimeComponent: withSlFormRuntime(SlfTableRuntime),
  editComponent: withSlFormRuntime(SlfTableEdit)
}
export default widget
