import React, { useEffect } from 'react'
import { CrmLoadingIcon } from 'crm-components'
import moment from 'moment'
import { convertMonthToDateRange } from '../../dashboard-utils'
import CtDailyLeadsMonitoringHoc from '../cartrack/ct-monthly-leads-dashboard-hoc.js'
import withLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'

export const CtMonthlyLeadDashboardTable = withLeadsPopupHoc(
  CtDailyLeadsMonitoringHoc(props => {
    useEffect(() => {
      props.onRefresh()
    }, [JSON.stringify(props.analyticsContext?.filters ?? {})])

    const months = props?.months ?? []
    const data = props?.data ?? []

    const renderRow = (
      label: string,
      data: any,
      field: string,
      summaryOnly: boolean = false,
      isCurrency: boolean = false,
      options: any = {}
    ) => {
      const months = props && props.months ? props.months : []
      const clickHandler = event => {
        const dataset = event.currentTarget.dataset
        const date = dataset.date
        const filter: any = {}
        if (data.account__lead_campaign__lead_source__name) {
          filter.account__lead_campaign__lead_source__name = data.account__lead_campaign__lead_source__name
        }
        if (data.account__lead_campaign__lead_source_uid) {
          filter.account__lead_campaign__lead_source_uid = data.account__lead_campaign__lead_source_uid
        }
        if (data.account__lead_campaign__lead_source__kind) {
          filter.account__lead_campaign__lead_source__kind = data.account__lead_campaign__lead_source__kind
        }

        handleClickDetails(
          {
            field,
            date
          },
          filter
        )
      }
      return (
        <tr>
          <td style={{ width: 150, minWidth: 150 }} className="CrmFlatTable-tbody-td">
            {label}
          </td>
          <td className="util-textRight CrmFlatTable-tbody-td" style={{ width: '7%' }} onClick={clickHandler}>
            <strong>
              {data?.summary?.[field] &&
                (isCurrency
                  ? Number(data.summary[field]).toLocaleString(undefined, { maximumFractionDigits: 0 })
                  : data.summary[field])}
              {!data?.summary?.[field] && '-'}
              {data?.summary?.[field] && options?.isPercent && '%'}
            </strong>
          </td>
          {!summaryOnly &&
            months.map(i => {
              return (
                <td
                  key={i}
                  className="CrmFlatTable-tbody-td util-textRight util-pointer"
                  onClick={clickHandler}
                  data-date={moment(i).format('YYYY-MM-DD')}
                >
                  {data?.[i]?.[field] &&
                    (isCurrency
                      ? Number(data[i][field]).toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        })
                      : data[i][field])}
                  {!data?.[i]?.[field] && '-'}
                  {data?.[i]?.[field] && options?.isPercent && '%'}
                </td>
              )
            })}
        </tr>
      )
    }
    const getYearlyFilter = months => {
      const FORMAT = 'YYYY-MM-DD'
      return {
        $gte: [...months].pop(),
        $lte: moment([...months].shift(), FORMAT)
          .endOf('month')
          .format(FORMAT)
      }
    }

    const handleClickDetails = (cell, filters) => {
      const lparams: any = {
        filters: {
          ...props.contextFilter,
          ...filters
        }
      }
      delete lparams.filters.activity_date
      if (cell.field === 'fitments') {
        lparams.type = 'fitment'
        lparams.filters.account_uid = { $ne: null }
        lparams.filters.status_code = 'completed'
        lparams.filters.completed_time = cell?.date
          ? convertMonthToDateRange(cell.date)
          : getYearlyFilter(props?.months)
      }
      if (cell.field === 'authenticated_leads') {
        lparams.type = 'account'
        lparams.filters.validated_date = cell?.date
          ? convertMonthToDateRange(cell.date)
          : getYearlyFilter(props?.months)
        lparams.filters.used_latest_owner_user_uid = true
      }
      if (cell.field === 'first_fitments') {
        lparams.type = 'first_fitments'
        lparams.filters.completed_date = cell?.date
          ? convertMonthToDateRange(cell.date)
          : getYearlyFilter(props?.months)
      }
      if (cell.field === 'meetings_completed') {
        lparams.type = 'activities'
        lparams.filters.start_date = cell?.date ? convertMonthToDateRange(cell.date) : getYearlyFilter(props?.months)
        lparams.filters.type = { medium_code: 'meeting' }
        lparams.filters.status = { kind: 'completed' }
        lparams.filters.is_primary = true;
        lparams.filters.used_user_uid = true;
      }
      if (cell.field === 'meetings_scheduled') {
        lparams.type = 'activities'
        lparams.filters.start_time = cell?.date ? convertMonthToDateRange(cell.date) : getYearlyFilter(props?.months)
        lparams.filters.type = { medium_code: 'meeting' }
        lparams.filters.is_primary = true;
        lparams.filters.used_user_uid = true;
      }
      if (cell.field === 'new_leads') {
        lparams.type = 'account'

        lparams.filters.lead_in_date = cell?.date ? convertMonthToDateRange(cell.date) : getYearlyFilter(props?.months)
        lparams.filters.used_latest_owner_user_uid = true
      }
      if (cell.field === 'won_opportunities') {
        lparams.type = 'opportunity'
        lparams.filters.status_code = 'won'
        lparams.filters.completed_date = cell?.date
          ? convertMonthToDateRange(cell.date)
          : getYearlyFilter(props?.months)
      }
      if (lparams.type) {
        props.onShowLeadsPopup(lparams)
      }
    }

    return (
      <React.Fragment>
        {props.isLoading && <CrmLoadingIcon />}
        {props.data && !props.isLoading && (
          <table className="CrmFlatTable">
            <thead
              className="CrmFlatTable-thead--no-border CrmFlatTable-thead--small util-backgroundGray35"
              style={{ border: 'unset' }}
            >
              <tr>
                <th>{props?.tableName}</th>
                <th>
                  <strong>Total</strong>
                </th>
                {months.map(i => {
                  return (
                    <th key={i}>
                      <div className="util-textRight">
                        {moment(i).format('YY-MMM')} <br />
                        <label className="util-textSmall">{moment(i).format('YYYY')}</label>
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className="CrmFlatTable-tbody ct-monthly-leads-dashboard">
              {renderRow('Cost', data, 'lead_campaign_cost', false, true)}
              {renderRow('Leads Received', data, 'new_leads')}
              {renderRow('Authenticated Leads', data, 'authenticated_leads')}
              {!props.hideMeeting && (
                <React.Fragment>
                  {renderRow('Meetings Scheduled', data, 'meetings_scheduled')}
                  {renderRow('Meetings Attended', data, 'meetings_completed')}
                </React.Fragment>
              )}
              {renderRow('SAs Signed (Won Opportunities) (N/A)', data, 'won_opportunities')}
              {renderRow('Customers Acquired (First Fitted)', data, 'first_fitments')}
              {renderRow('Fitments', data, 'fitments')}
              {renderRow('Conversions % (Sales to leads)', data, 'sales_to_leads', false, true, {
                isPercent: true
              })}
              {renderRow('Cost/Lead', data, 'cost_to_lead', false, true)}
              {renderRow('Cost/Win', data, 'cost_to_win', false, true)}
              {renderRow('Cost/Fit', data, 'cost_to_fitment', false, true)}
            </tbody>
          </table>
        )}
      </React.Fragment>
    )
  })
)
