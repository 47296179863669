/* eslint-disable camelcase */
import { qlqAccountsListQM } from 'crm-data/accounts'
import { mapContextToFilter } from './rms-dashboard-utils'

const parseFunnel = data => {
  const users = {}
  data.forEach(row => {
    const key_uid = row.owner_user_uid ? row.owner_user_uid : row.owner_pool_uid
    const key_name = row.owner_user_uid
      ? row.owner_user_name
      : row.owner_pool_name
    if (!users[key_uid]) {
      users[key_uid] = {
        user_uid: key_uid,
        user_name: key_name,
        funnel: {},
        mainKpis: []
      }
    }
    if (!users[key_uid].funnel[row.kpi_sales_status_code]) {
      users[key_uid].funnel[row.kpi_sales_status_code] = 0
    }
    users[key_uid].funnel[row.kpi_sales_status_code] += Number(row.account_uid)
  })
  // Object.values(users).forEach(user => {
  //   user.mainKpis.push({
  //     name: 'Leads',
  //     value: user.opportunities.overdueSize
  //   })
  //   user.mainKpis.push({
  //     name: 'Opportunities Current',
  //     value: user.opportunities.currentSize
  //   })
  // })
  console.log('parseFunnel', data, users)
  return users
}
export const mock = 1
export const loadFunnel = async params => {
  console.log('loadFunnel', params, mapContextToFilter)

  const filter = mapContextToFilter(params, true)
  console.log('Filter', filter)
  filter.completed_date = 'null'
  filter.is_deleted = false

  const variables = {
    filter,
    fields: [
      'owner_user_name',
      'owner_user_uid',
      'sales_status',
      'owner_pool_uid',
      'owner_pool_name'
    ],
    aggregate: [{ id: 'account_uid', type: 'count' }]
  }

  const res = await params.client.query({
    query: qlqAccountsListQM,
    variables,
    fetchPolicy: 'no-cache'
  })
  console.log('Got Accounts result', res)
  if (res && res.data && res.data.accounts_list_qm_paged) {
    return parseFunnel(res.data.accounts_list_qm_paged.data)
  }
  return undefined
}
