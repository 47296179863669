import React, { FC } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MetaItem as BaseMetaItem, MetaRow, ActivitySubject, ActivityTextContent } from './shared.styled'
import { ActivityContentProps } from './activity-content'
import { CrmTranslateText } from '@cartrack-crm/ui'
import CrmActivityPerformer from './activity-performer'
import CrmActivityMeetingLocationMap from './activity-meeting-location-map'

const MeetingActivityContainer = styled.div``

const MeetingTextContent = styled(ActivityTextContent)`
  margin-top: 6px;
  -webkit-line-clamp: 2;
  color: #333333;
  font-style: normal;
`
const MetaItem = styled(BaseMetaItem)`
  width: auto;
  font-size: 10px;
`

const MeetingWarningText = styled.div`
  margin-top: 8px;
  color: #666666;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
`

const LocationItem = styled.div`
  color: #5099ce;
  font-size: 10px;
  font-weight: 500;
`

const MeetingActivity: FC<ActivityContentProps> = props => {
  const time = moment(props.start_time).format('DD/MM/YYYY HH:mm')
  const getDeclaredAddressLocation = () => {
    const addresses = props.account.addresses
    const address =
      addresses && addresses.map ? addresses.filter(v => v.address_uid === props.address_uid)[0] : undefined
    const location = address?.location?.lat && address?.location?.lng ? { ...address } : undefined
    return location
  }

  const getActualLocation = () => {
    const location =
      props.meta && props.meta.meeting_minutes_completed_location
        ? {
          location: {
            lat: props.meta.meeting_minutes_completed_location.latitude,
            lng: props.meta.meeting_minutes_completed_location.longitude
          }
        }
        : undefined
    return location
  }

  const getActualParticipants = () => {
    const unique = [...new Set(props.participants?.map(item => item.user_uid))]
    return unique?.length ?? 0
  }

  const declare_location = getDeclaredAddressLocation()
  const actual_location = getActualLocation()
  const has_location = declare_location || actual_location
  const actual_participants = getActualParticipants()

  return (
    <MeetingActivityContainer>
      <ActivitySubject oneline>{props.subject}</ActivitySubject>
      <MeetingTextContent>
        <CrmTranslateText>{props.note_text}</CrmTranslateText>
      </MeetingTextContent>
      <MetaRow>
        <MetaItem>
          <CrmActivityPerformer isOwner={props.isOwner} performer={props.primary_performer} />
        </MetaItem>
        <MetaItem>
          <FontAwesomeIcon icon="clock" /> {time}
        </MetaItem>
        <MetaItem>
          <FontAwesomeIcon icon="users" /> {actual_participants}
        </MetaItem>
        <MetaItem>
          {has_location ? (
            <CrmActivityMeetingLocationMap
              declare_location={declare_location}
              actual_location={actual_location}
              location_type={props.location_type}
              use_popup={true}
              _addrFormat={props._addrFormat}
            />
          ) : (
              <LocationItem>
                <FontAwesomeIcon icon="map-marked-alt" /> {props.location_type}
              </LocationItem>
            )}
        </MetaItem>
      </MetaRow>
      {!(props.activity_status?.kind == 'canceled' || props.activity_status?.kind == 'completed') && (<MeetingWarningText>
        Remember to print out necessary materials for training
      </MeetingWarningText>)}
    </MeetingActivityContainer>
  )
}

export default MeetingActivity
