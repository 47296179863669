/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { Motion, spring, presets } from 'react-motion'
import { func, arrayOf, shape, any, string, bool } from 'prop-types'
import enhanceWithClickOutside from 'react-click-outside'
import PhoneWidget from '../../phone/widget/phone-widget.jsx'
import NextTaskButton from '../../tasks/widgets/next-task-button.jsx'
import ProfileDropdownButton from 'crm-modules/app/profile-dropdown-button'
import SelectBranchModal from 'crm-modules/app/select-branch-modal'
import CrmMessagesPopup from '../../common/messages/crm-messages-popup.jsx'
import crmNavbarAllItems from './crm-navbar-settings'
import QuicksearchWidget from './quicksearch-widget.jsx'
import {
  getIsMyNextTasksModalVisible,
  toggleMyNextTasksModal
} from 'crm-duxs/tasks-reducer'
import { getMessages } from 'crm-duxs/messages-reducer'
import { getIsBulkOperationsOpen } from 'crm-duxs/bulk-operations-reducer'

import { Dropdown } from 'util-components'
import {
  CrmButton,
  CrmHelpButton,
  CrmLabeledField,
  CrmMessageWithCounterHoc
} from 'crm-components'

import {
  getAvailableInstances,
  changeSelectedInstance,
  getSelectedInstance,
  toggleMabileNavigation,
  getMobileNavigationVisible,
  getIsSelectTerritoryModalVisible,
  toggleSelectTerritoryModal
} from 'crm-duxs/crm-reducer'
import getIdFormat from 'crm-utils/translation-utils'
import { getMessage, openApiErrorModal } from 'crm-duxs/error-reporting'
import { gotoNewAccount } from 'crm-duxs/accounts-reducer'
import SelectLanguageModal from 'crm-components/modules/settings/select-language-modal.jsx'
import AddAccountPanel from 'crm-modules/accounts/account/components/add-account/add-account-panel.tsx'
import { findMainProcess } from 'crm-utils/accounts-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand'
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress'
import { AddAccountButton } from './add-account-buttons'
import { withGeneralPermissionsContextHOC } from '@cartrack-crm/crm-core'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import { CrmShortCodeCountry } from './crm-navbar-short-code'
import { withToastrWithRunApiHoc } from '@cartrack-crm/ui/src/toastrs'
import washHandIcon from '../../../../../assets/events/covid/hand-washing.png'
import moment from 'moment'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const messageIconWithCounter = props => {
  return <i className="fa fa-envelope" onClick={props.handleClickMessages} />
}

const MessageIconWithCounter = CrmMessageWithCounterHoc(messageIconWithCounter)

class CrmNavbar extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      mobileNavbarVisible: false,
      isShowMessages: false,
      debugMode: Boolean(window.localStorage.getItem('crm_is_debug_mode')),
      menuItems: [],
      isShowLanguageSelect: false,
      showAddAccountPopup: false,
      addAccountPopupSize: 'large'
    }
    this.allMenuItems = crmNavbarAllItems(this.props.crmContext)
  }

  refAddAccountPopup = React.createRef()

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.rebuildVisibleMenuOptions()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = e => {
    if (
      this.refAddAccountPopup &&
      this.refAddAccountPopup.current &&
      !this.refAddAccountPopup.current.contains(e.target)
    ) {
      this.setState({ showAddAccountPopup: false })
    }
  }

  handleCancelAddingAccount = () => {
    this.setState({ showAddAccountPopup: false })
  }

  handleDebugToggle = () => {
    window.localStorage.setItem('crm_is_debug_mode', !this.state.debugMode)
    this.setState({ debugMode: !this.state.debugMode })
  }

  handleSelectInstance = event => {
    if (event.currentTarget.id) {
      const instance = this.props.availableInstances.find(
        inst => inst.instance_uid === event.currentTarget.id
      )
      if (instance) {
        this.props.doChangeSelectedInstance(instance)
      }
    }
  }

  onClickMessages = () => {
    this.setState({ ...this.state, isShowMessages: !this.state.isShowMessages })
  }

  handleClickErrorReporting = () => {
    this.props.doOpenApiErrorModal()
  }

  handleCloseMessagesPopup = () => {
    this.setState({ ...this.state, isShowMessages: false })
  }

  handleMyNextTasksClick = () => {
    this.props.doToggleMyNextTasksModal(!this.props.isMyNextTasksModalVisible)
  }

  handleClickMobileNavbar = () => {
    this.props.doToggleMobileNav(!this.props.mobileNavVisible)
  }
  handleClickNav = () => {
    this.props.doToggleMobileNav(false)
  }

  handleChangeTerritory = () => {
    this.props.doToggleSelectTerritoryModal(true)
  }

  handleCloseSelectTerritory = () => {
    this.props.doToggleSelectTerritoryModal(false)
  }

  handleChangeLanguage = () => {
    this.setState({ isShowLanguageSelect: true })
  }

  handleCloseSelectLanguage = () => {
    this.setState({ isShowLanguageSelect: false })
  }

  hasMenuItemPermission = crmPermissions => {
    let hasPermissionSettings = true
    let hasPermissions = false

    if (crmPermissions && crmPermissions.length > 0) {
      hasPermissions = true
      crmPermissions.map(v => {
        if (v.permissionCode) {
          const has = this.props.generalPermissionsContext.hasPermissionTo(
            v.permissionCode,
            v.accessMode,
            v.module
          )
          if (!has) hasPermissions = false
        } else {
          hasPermissions = false
        }
      })
    } else {
      hasPermissionSettings = false
    }

    // has no crmPermissions settings || has all permissions in crmPermissions settings
    const has =
      !hasPermissionSettings ||
      (hasPermissionSettings && hasPermissions) ||
      this.props.generalPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.SUPER_ADMIN
      )
    return has
  }

  rebuildVisibleMenuOptions() {
    const hasViewMenuPermission = this.props.generalPermissionsContext.hasPermissionByType(
      PERMISSIONS_TYPES.MENU
    )
    const menuItems = this.allMenuItems.filter(mi => {
      return this.hasMenuItemPermission(mi.crmPermissions)
    })
    this.setState({ menuItems })
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user)) {
      setTimeout(() => this.rebuildVisibleMenuOptions())
    }
  }

  calculateSelectedTab() {
    let selectedTab

    if (this.state.menuItems) {
      for (const tab of this.state.menuItems) {
        if (this.props.currentPath.indexOf('/crm/account/') === 0) {
          selectedTab = {
            text: 'Account',
            selectedSubTab: {},
            hideInnerNav: true
          }
          break
        }
        if (
          tab.path &&
          tab.path === this.props.currentPath.slice(0, tab.path.length)
        ) {
          selectedTab = {
            text: tab.text,
            icon: tab.icon,
            meta: tab.meta,
            path: tab.path
          }
          break
        }

        if (!tab.subTabs) {
          continue
        }

        const selectedSubTab = tab.subTabs.find(
          subTab =>
            subTab.path === this.props.currentPath.slice(0, subTab.path.length)
        )
        if (selectedSubTab) {
          selectedTab = {
            subTabs: tab.subTabs.filter(v =>
              this.hasMenuItemPermission(v.crmPermissions)
            ),
            selectedSubTab,
            text: tab.text,
            icon: tab.icon,
            meta: tab.meta
          }
          break
        }
      }
    }

    return selectedTab
  }

  _buildUserMenuOptions = () => {
    const user = this.props.user
    const instance = this.props.selectedInstance
    const options = [
      {
        name: 'name',
        value: 'profile',
        element: (
          <div className="util-fullWidthChild">
            {user && (
              <div className="ProfileDropdownButton-username">
                {user.username}
              </div>
            )}
            {instance && (
              <div className="ProfileDropdownButton-instance">
                {instance.name}
              </div>
            )}
          </div>
        )
      },
      {
        name: 'change_territory',
        value: 'Change Territory',
        element: (
          <div
            className="util-fullWidthChild"
            onClick={this.handleChangeTerritory}
          >
            <FormattedMessage
              id="crm.app.menu.change_territory"
              defaultMessage="Change Territory"
            />
          </div>
        )
      },
      {
        name: 'change_language',
        value: 'Change Language',
        element: (
          <div
            className="util-fullWidthChild"
            onClick={this.handleChangeLanguage}
          >
            <FormattedMessage
              id="crm.app.menu.change_language"
              defaultMessage="Change Language"
            />
          </div>
        )
      },
      {
        name: 'logout',
        value: 'logout',
        element: (
          <div className="util-fullWidthChild" onClick={this.props.onLogout}>
            <FormattedMessage id="crm.app.logout" defaultMessage="Log out" />
          </div>
        )
      },
      {
        name: 'settings',
        element: (
          <div className="util-fullWidthChild">
            <NavLink to="/crm/profile-settings">
              <FormattedMessage
                id="crm.app.profile_settings"
                defaultMessage="Profile Settings"
              />
            </NavLink>
          </div>
        )
      },
      {
        name: 'release-notes',
        element: (
          <div className="util-fullWidthChild">
            <NavLink to="/crm/release-notes">Release notes</NavLink>
          </div>
        )
      },
      {
        name: 'dialer-agent',
        element: (
          <div className="util-fullWidthChild">
            <NavLink to="/crm/callcenter/dialer">Dialer Agent</NavLink>
          </div>
        )
      }
    ]

    const isAdmin = this.props.generalPermissionsContext.hasPermissionByType(
      PERMISSIONS_TYPES.ADMIN_MANAGE
    )
    const isSuperAdmin = this.props.generalPermissionsContext.hasPermissionByType(
      PERMISSIONS_TYPES.SUPER_ADMIN
    )
    options.push({
      name: 'Settings',
      element: (
        <div className="util-fullWidthChild">
          <NavLink to="/crm/settings/users">CRM Settings</NavLink>
        </div>
      )
    })

    if (isSuperAdmin) {
      options.push({
        name: 'Admin Advanced',
        element: (
          <div className="util-fullWidthChild">
            <NavLink to="/crm/admin/advanced">Admin</NavLink>
          </div>
        )
      })
    }

    if (isAdmin) {
      options.push({
        name: 'Hook Logs',
        element: (
          <div className="util-fullWidthChild">
            <NavLink to="/crm/admin/hook-request-log">
              Hook request logs
            </NavLink>
          </div>
        )
      })
      options.push({
        name: 'Agent status',
        element: (
          <div className="util-fullWidthChild">
            <NavLink to="/crm/callcenter/agent-status">Agent status</NavLink>
          </div>
        )
      })
    }

    options.push({
      name: 'build',
      element: (
        <div className="util-fullWidthChild" onClick={this.handleDebugToggle}>
          <FormattedMessage id="crm.app.build_no" defaultMessage="Ver. " />
          {process.env.BITBUCKET_BUILD_NUMBER}
          {this.state.debugMode ? ' debug' : ' no-debug'}
        </div>
      )
    })

    return options
  }

  handleAddAccount = () => {
    this.setState({ showAddAccountPopup: true })
  }

  handleAddAccountPopupLargeSizeSelected = () => {
    this.setState({ addAccountPopupSize: 'large' })
  }

  handleAddAccountPopupSmallSizeSelected = () => {
    this.setState({ addAccountPopupSize: 'small' })
  }

  render() {
    const { availableInstances, selectedInstance, intl, user } = this.props
    const selectedTab = this.calculateSelectedTab()
    const { handleSelectInstance } = this
    const menuItems = this.state.menuItems
    const options = availableInstances
      ? availableInstances.map(inst => ({
          name: inst.name,
          element: (
            <div key={inst.instance_uid}>
              <div id={inst.instance_uid} onClick={handleSelectInstance}>
                {inst.name}
              </div>
            </div>
          )
        }))
      : []
    const userMenuOptions = this._buildUserMenuOptions()
    const enable_next_task_button = this.props.crmContext.getAttributeValueByCode(
      'enable_next_task_button'
    )
    const selectIndex = selectedTab?.subTabs?.indexOf(selectedTab?.selectedSubTab);
    const maxIndex = selectedTab?.subTabs?.length - 1
    const subtab =  selectedTab?.subTabs?.[selectIndex]
    return (
      <div>
        <div className={`CrmNavbar-mobile `}>
          <div
            className="CrmNavbar-mobile-navButton"
            onClick={this.handleClickMobileNavbar}
          >
            <i className="fa fa-bars" />
          </div>
          <div className="CrmNavbar-mobile-logoWrapper">
            <img
              className="CrmNavbar-mobile-logo"
              src={require('../../../../../assets/icons/logo-white.png')}
            />
          </div>
        </div>
        <div
          className={`CrmNavbar ${
            this.props.mobileNavVisible ? 'CrmNavbar-visible' : ''
          }`}
        >
          <div className="Navbar-outerNav CrmNavbar-outerNav">
            <div className="className=Navbar-logo" style={{ paddingLeft: 10 }}>
              &nbsp;
            </div>
            <div className="CrmNavbar-outerNav-tabs ">
              {menuItems &&
                menuItems
                  .filter(mi => !mi.hide)
                  .map(
                    ({
                      subTabs,
                      text,
                      icon,
                      meta,
                      mobile,
                      mobileOnly,
                      path,
                      style
                    }) => {
                      if (meta) return null
                      let lpath = subTabs ? subTabs[0].path : undefined
                      if (path) {
                        lpath = path
                      }
                      return (
                        <NavLink
                          key={lpath}
                          activeClassName="is-active"
                          to={lpath}
                          onClick={this.handleClickNav}
                          style={style}
                          className={`CrmNavbar-outerNav-tab ${
                            mobile ? 'CrmNavbar-outerNav-tabMobile' : ''
                          }
                        ${
                          mobileOnly ? 'CrmNavbar-outerNav-tabMobileOnly' : ''
                        }`}
                          isActive={() =>
                            (selectedTab && selectedTab.path === lpath) ||
                            (subTabs &&
                              subTabs.find(
                                st =>
                                  selectedTab &&
                                  selectedTab.selectedSubTab &&
                                  st.path === selectedTab.selectedSubTab.path
                              ) !== undefined)
                          }
                        >
                          <i className={`fa fa-${icon}`} aria-hidden="true" />
                          <br />
                          <div>
                            {intl.formatMessage({
                              id: `crm.navbar.tab.${getIdFormat(text)}`,
                              defaultMessage: text
                            })}
                          </div>
                        </NavLink>
                      )
                    }
                  )}
            </div>
            <div className="util-flexGrow">
              <QuicksearchWidget />
            </div>
            <div
              className="util-flexCenterContent"
              style={{ padding: '10px 5px' }}
            >
              <CrmHelpButton helpPage="/" lg />
            </div>
            <div style={{ padding: '10px 5px' }}>
              <div className="CrmNavbar-phone">
                <PhoneWidget />
              </div>
            </div>
            <div
              style={{ padding: '10px 5px' }}
              className="AddAccountPopup"
              ref={this.refAddAccountPopup}
            >
              <AddAccountButton handleAddAccount={this.handleAddAccount} />

              {this.state.showAddAccountPopup && (
                <div
                  className={`util-flexColumn AddAccountPopup-Wrapper AddAccountPopup--${this.state.addAccountPopupSize} `}
                >
                  <div className="util-flexGrow AddAccountPopup-Inner">
                    <div className="util-flexRowRight AddAccountPopup-Size">
                      <div className="util-flexRow">
                        {this.state.addAccountPopupSize === 'small' && (
                          <div
                            className=""
                            onClick={
                              this.handleAddAccountPopupLargeSizeSelected
                            }
                          >
                            <FontAwesomeIcon icon={faExpand} />
                          </div>
                        )}
                        {this.state.addAccountPopupSize === 'large' && (
                          <div
                            className=""
                            onClick={
                              this.handleAddAccountPopupSmallSizeSelected
                            }
                          >
                            <FontAwesomeIcon icon={faCompress} />
                          </div>
                        )}
                      </div>
                    </div>
                    <AddAccountPanel
                      onCancelAddingAccount={this.handleCancelAddingAccount}
                      pageSize={this.state.addAccountPopupSize}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                marginLeft: 5,
                marginRight: 5
              }}
              className="CrmNavbar-tasks util-flexCenterContent"
            >
              {enable_next_task_button && <NextTaskButton />}
            </div>

            <div className="CrmNavbar-messages">
              <MessageIconWithCounter
                handleClickMessages={this.onClickMessages}
                onClick={this.onClickMessages}
                count={this.props.messages.countUnreadMessage}
              />
            </div>

            {this.props.errors.length > 0 && (
              <div className="CrmNavbar-errors">
                <i
                  className="fa fa-exclamation-circle"
                  onClick={this.handleClickErrorReporting}
                />
              </div>
            )}
            <CrmMessagesPopup
              onClose={this.handleCloseMessagesPopup}
              isOpen={this.state.isShowMessages}
            />
            <Dropdown
              title={
                user && (
                  <ProfileDropdownButton
                    user={user}
                    selectedInstance={selectedInstance}
                    style={{ float: 'initial' }}
                    isSelectTerritoryModalVisible={
                      this.props.isSelectTerritoryModalVisible
                    }
                    onClose={this.handleCloseSelectTerritory}
                  />
                )
              }
              options={userMenuOptions}
              extraClassNames={{
                containerClassNames: `ProfileDropdownButton--container ${
                  selectedTab && selectedTab.meta ? 'is-active' : ''
                }`
              }}
              style={{
                borderBottom: 0
              }}
            />

            <SelectBranchModal
              isOpen={this.props.isSelectTerritoryModalVisible}
              selectedInstance={selectedInstance}
              onClose={this.handleCloseSelectTerritory}
            />

            <CrmShortCodeCountry
              handleChangeTerritory={this.handleChangeTerritory}
            />
          </div>
          {selectedTab && selectedTab.subTabs && (
            <div className="Navbar-innerNav">
              {selectedTab &&
                selectedTab.subTabs &&
                selectedTab.subTabs.map(subTab => {
                  if (this.hasMenuItemPermission(subTab.crmPermissions)) {
                    return (
                      <NavLink
                        key={subTab.path}
                        activeClassName="is-active"
                        exact
                        to={subTab.path}
                        style={subTab.style ?? {}}
                        className={`Navbar-innerNav-tab`}
                        isActive={() => subTab === selectedTab.selectedSubTab}
                      >
                        {intl.formatMessage({
                          id: `crm.navbar.tab.${getIdFormat(subTab.text)}`,
                          defaultMessage: subTab.text
                        })}
                      </NavLink>
                    )
                  }
                })}
            </div>
          )}
          <SelectLanguageModal
            {...this.props}
            isOpen={this.state.isShowLanguageSelect}
            onClose={this.handleCloseSelectLanguage}
          />
        </div>
      </div>
    )
  }
}

CrmNavbar.defaultProps = {
  availableInstances: []
}

CrmNavbar.propTypes = {
  doChangeSelectedInstance: func.isRequired,
  selectedInstance: shape({}).isRequired,
  availableInstances: arrayOf(shape({})).isRequired,
  isMyNextTasksModalVisible: bool.isRequired,
  doToggleMyNextTasksModal: func.isRequired,
  onLogout: func.isRequired,
  currentPath: string.isRequired,
  doToggleMobileNav: func.isRequired,
  mobileNavVisible: bool.isRequired,
  user: any.isRequired, // eslint-disable-line
  doToggleSelectTerritoryModal: func.isRequired,
  isSelectTerritoryModalVisible: bool.isRequired,
  doGotoNewAccount: func.isRequired,
  doOpenApiErrorModal: func.isRequired,
  errors: arrayOf(shape({})).isRequired,
  generalPermissionsContext: shape({}).isRequired
}

function mapStateToProps(state) {
  const availableInstances = getAvailableInstances(state)
  return {
    availableInstances: availableInstances?.available_instances
      ? availableInstances.available_instances
      : [],
    selectedInstance: getSelectedInstance(state),
    isMyNextTasksModalVisible: getIsMyNextTasksModalVisible(state),
    mobileNavVisible: getMobileNavigationVisible(state),
    isSelectTerritoryModalVisible: getIsSelectTerritoryModalVisible(state),
    errors: getMessage(state),
    messages: getMessages(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangeSelectedInstance: instance => {
      dispatch(changeSelectedInstance(instance))
    },
    doToggleMyNextTasksModal: value => dispatch(toggleMyNextTasksModal(value)),
    doToggleMobileNav: enable => dispatch(toggleMabileNavigation(enable)),
    doToggleSelectTerritoryModal: value =>
      dispatch(toggleSelectTerritoryModal(value)),
    doGotoNewAccount: () => dispatch(gotoNewAccount()),
    doOpenApiErrorModal: () => dispatch(openApiErrorModal())
  }
}
const CrmNavbarClickOutide = enhanceWithClickOutside(CrmNavbar)
const intlCrmNavbar = injectIntl(CrmNavbarClickOutide)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withCrmContext(
    withGeneralPermissionsContextHOC(withToastrWithRunApiHoc(intlCrmNavbar))
  )
)
