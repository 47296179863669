/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'
import moment from 'moment'
import { shape, func, string } from 'prop-types'
import { loadNewLeadsInCampaign } from '../telesales-dash-utils'
import CrmWidget from '../../../components/crm-widget.jsx'
import { aggregateBy, arraySum } from 'crm-utils/data-utils'
import { CrmLabeledField } from 'crm-components'
import CrmBarChart from 'crm-components/charts/crm-bar-chart.jsx'

const COLORS = ['#65b7f3', '#b2d28c', '#f47735', '#f67960', '#82c6da']

class WidgetMovementsNewLeads extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ isLoading: val }, handler)
  }

  parseData(data) {
    const res = {}
    res.data = data.map(i => ({
      ...i,
      hour: moment.parseZone(i.process_started_hour).format('HH:mm')
    }))
    console.log('data with hour ', res.data)
    res.byHour = aggregateBy(res.data, 'hour', 'process_uid')
    res.byLeadSource = aggregateBy(
      res.data,
      'account__lead_campaign__name',
      'process_uid'
    )
    res.total = arraySum(data, 'process_uid')

    return res
  }
  handleRefresh = async () => {
    console.log('dash telesales general stats . handle refresh', this.props)
    try {
      const data = await loadNewLeadsInCampaign(this.props)
      console.log('data: ', data)
      if (data) {
        const parsed = this.parseData(data)
        this.setState(parsed)
      }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }

  handleCurrentStepClick = event => {
    console.log('handleCurrentStepClick', event)
    this.setState({ selectedStepTypeUid: event.currentTarget.id })
    this.props.onCurrentStepClick({
      process_step_type_uid: event.currentTarget.id
    })
  }
  handleClickBarByHour = event => {
    console.log('handleClickBar', event, this.props)
    const filters = {
      ...this.props.analyticsContext.filters
    }
    if (this.props.analyticsContext.filters.activity_date) {
      filters.process_started_hour =
        this.props.analyticsContext.filters.activity_date.value[0] +
        ' ' +
        event.name
    }
    this.props.onAnalyticsRangeSelected({ type: 'lead', filters })
  }

  handleClickBarByLeadSource = event => {
    console.log('handleClickBarByLeadSource', event, this.props)
    const filters = {
      account__lead_campaign__name: event.name,
      ...this.props.analyticsContext.filters
    }
    if (this.props.analyticsContext.filters.activity_date) {
      filters.process_started_date = this.props.analyticsContext.filters.activity_date.$gte
    }
    this.props.onAnalyticsRangeSelected({ type: 'lead', filters })
  }

  renderBlock = (field, label, className) =>
    this.state[field] ? (
      <CrmLabeledField label={label}>
        <div style={{ fontSize: 30, margin: 10 }} className={className}>
          {this.state[field]}
          <span style={{ fontSize: 10, margin: 3, opacity: 0.7 }}>
            {this.state[field + '_ratio']}%
          </span>
        </div>
      </CrmLabeledField>
    ) : (
      <span />
    )

  renderByHour = () => {
    const resultsParsed = Object.values(this.state.byHour)
      .map((a, i) => ({ ...a, count: a.value, fill: COLORS[i % 5] }))
      .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
    return (
      <div style={{ height: 400 }}>
        <label>By Hour </label>
        <CrmBarChart
          data={resultsParsed}
          onClickBar={this.handleClickBarByHour}
        />
      </div>
    )
  }
  renderByLeadSource = () => {
    const resultsParsed = Object.values(this.state.byLeadSource)
      .map(a => ({ ...a, count: a.value }))
      .sort((a, b) => b.value - a.value)
    return (
      <div style={{ height: 400 }}>
        <label>By Lead Source</label>
        <CrmBarChart
          data={resultsParsed}
          onClickBar={this.handleClickBarByLeadSource}
        />
      </div>
    )
  }
  handleGetRawData = () => this.state.data

  renderSummary = () => (
    <div>
      {this.state ? <span>Total Leads {this.state.total}</span> : <span />}
    </div>
  )

  render() {
    console.log('Render widget movements new leads', this.state)
    return (
      <CrmWidget
        title={this.props.title ? this.props.title : 'New Leads in Campaign'}
        subtitle="Select filter Activity Date to show processes started in this range "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-campaign-new-leads"
      >
        <div className="row">
          <div className="col-md-6">
            {this.state.byHour && this.renderByHour()}
          </div>
          <div className="col-md-6">
            {this.state.byLeadSource && this.renderByLeadSource()}
          </div>
        </div>
      </CrmWidget>
    )
  }
}

WidgetMovementsNewLeads.propTypes = {
  analyticsContext: shape({}).isRequired,
  onCurrentStepClick: func.isRequired,
  onAnalyticsRangeSelected: func.isRequired,
  title: string
}

WidgetMovementsNewLeads.defaultProps = {
  title: undefined
}
export default withApollo(WidgetMovementsNewLeads)
