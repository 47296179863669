/* eslint-disable react/no-danger */
import React from 'react'
import { Link } from 'react-router-dom'
import { shape, bool, string, func } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CrmListItem, CrmListItemText, CrmListItemIcon } from 'crm-components'
import { accountLink } from 'crm-utils/accounts-utils'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding'

class AccountListItem extends React.PureComponent {
  _secondaryText = () => {
    const { account } = this.props
    let ret = ''
    if (account.contact_details) {
      ret += account.contact_details
        .filter(cd => cd.value)
        .map(
          cd =>
            (cd.kind === 'phone' ? 't:' : '') +
            (cd.kind === 'mobile' ? 'm:' : '') +
            (cd.kind === 'email' ? 'e:' : '') +
            cd.value
        )
        .join(', ')
    }
    return ret
  }
  _renderPrimary = () => {
    if (this.props.account.match) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: `${this.props.account.match}  ${
              this.props.account.owner_name
                ? `(${this.props.account.owner_name})`
                : ''
            }`
          }}
        />
      )
    }
    return (
      <span>
        {this.props.account.name}{' '}
        {this.props.account.owner_name
          ? `(` + this.props.account.owner_name + `)`
          : ''}
      </span>
    )
  }
  _renderInner = ({ account, hasPermission, primary }) => {
    if (this.props.size === 'normal') {
      return (
        <CrmListItem key={account.account_uid} button>
          <CrmListItemIcon classes={{ root: 'util-textRed' }}>
            {this.props.checkPermission && !hasPermission ? (
              <span style={{ color: '#ce5239' }}>
                <FontAwesomeIcon icon={faLock} />
              </span>
            ) : (
              <FontAwesomeIcon icon={faBuilding} />
            )}
          </CrmListItemIcon>

          <CrmListItemText
            primary={primary}
            secondary={this._secondaryText()}
          />
        </CrmListItem>
      )
    } else if (this.props.size === 'small') {
      return (
        <CrmListItem
          key={account.account_uid}
          button
          style={{ padding: '5px' }}
        >
          <span className="account-list-item-small">
            - {this.props.account.name}
          </span>
        </CrmListItem>
      )
    }
  }

  render() {
    const { account } = this.props
    console.log('Render List Item', account)
    const primary = this._renderPrimary()
    const hasPermission = true // account.has_account_data_permission
    const linkMode = hasPermission || !this.props.checkPermission
    if (linkMode) {
      return (
        <Link
          to={accountLink(account)}
          target={this.props.target ? this.props.target : undefined}
          onClick={this.props.onClick}
        >
          {this._renderInner({ hasPermission, primary, account })}
        </Link>
      )
    }
    return this._renderInner({ hasPermission, primary, account })
  }
}

AccountListItem.propTypes = {
  account: shape({}).isRequired,
  checkPermission: bool,
  target: string,
  onClick: func,
  size: string
}

AccountListItem.defaultProps = {
  checkPermission: false,
  target: undefined,
  onClick: undefined,
  size: 'normal'
}

export default AccountListItem
