import { toastr } from 'react-redux-toastr'
import { delay } from 'redux-saga'
import minutesAPI from '../api/minutes-of-meeting-api'
import activitiesApi from '../api/activities-api'
import {
  LOAD_INITIAL_FORM_VALUE,
  RECEIVE_INITIAL_FORM_VALUE,
  SUBMIT_MINUTES_FORM,
  UPDATE_MINUTES_VALUE,
  SET_MINUTES_COMPLETED,
  UPDATE_MINUTES_ERROR,
  SAVE_MINUTES_FORM,
  TOGGLE_IS_SAVING
} from 'crm-duxs/meeting-minutes-reducer'
import { actions } from 'crm-duxs/activity-reducer'

import { call, put, takeLatest } from 'redux-saga/effects'

function* fetch(action) {
  const minutesData = yield call(
    minutesAPI.fetch,
    action.activity_uid,
    action.account_uid
  )
  console.log('received minutes')
  console.dir(minutesData)
  // Fetch activity
  const activity = yield call(activitiesApi.getActivity, action.activity_uid)
  yield put({
    type: RECEIVE_INITIAL_FORM_VALUE,
    payload: { minutesData, activity }
  })
  yield put(actions.startActivityEdit.create({ activity }))
  // Fetch activity
}
// eslint-disable-next-line
function* showSaveError(errorMessage) {
  yield put({
    type: UPDATE_MINUTES_ERROR,
    payload: { errorMessage }
  })
  toastr.error(errorMessage)
  yield call(delay, 5000)
  yield put({
    type: UPDATE_MINUTES_ERROR,
    payload: { errorMessage: undefined }
  })
}

function* save(action) {
  console.log('Save Meeting minutes SAGA')
  const minutes = action.payload.minutes
  console.dir(minutes)
  // Do check
  // if (
  //   !details.qualification ||
  //   !details.qualification.value ||
  //   !details.qualification.value.next_status
  // ) {
  //   return yield call(showSaveError, 'Select value in qualification section')
  // }
  // if (details.qualification.value.next_status === 'opportunity') {
  //   if (
  //     !details.target_prices ||
  //     !details.target_prices ||
  //     details.target_prices.length <= 0
  //   ) {
  //     return yield call(
  //       showSaveError,
  //       'You must specify opportunity size in Opportnity Size section'
  //     )
  //   }
  // }
  // if (details.qualification.value.next_status === 'lost') {
  //   if (!details.qualification.value.qualification_type_uid) {
  //     return yield call(
  //       showSaveError,
  //       'Select "Reason  Lost" in "Qualification" section'
  //     )
  //   }
  // }
  console.log('Do update')
  console.dir(minutes)
  yield put({ type: TOGGLE_IS_SAVING, payload: { value: true } })
  const minutesSaved = yield call(minutesAPI.save, minutes)
  toastr.success('Minutes of meeting has been updated')
  console.dir(minutesSaved)
  yield put({ type: TOGGLE_IS_SAVING, payload: { value: false } })
  yield put({
    type: UPDATE_MINUTES_VALUE,
    payload: { minutes: minutesSaved }
  })
}

function* setCompleted(action) {
  const minutes = yield call(
    minutesAPI.setAsComplete,
    action.payload.activityId,
    action.payload.value
  )
  toastr.success('Set status Minutes of meeting as completed')
  yield put({
    type: UPDATE_MINUTES_VALUE,
    payload: { minutes }
  })
}

function* minutesOfMeetingSaga() {
  yield takeLatest(LOAD_INITIAL_FORM_VALUE, fetch)
  yield takeLatest(SUBMIT_MINUTES_FORM, save)
  yield takeLatest(SAVE_MINUTES_FORM, save)
  yield takeLatest(SET_MINUTES_COMPLETED, setCompleted)
}

export default minutesOfMeetingSaga
