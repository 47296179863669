import React, { useState, useEffect } from 'react'
import {
  CrmModal,
  CrmTextInput,
  CrmLabeledField,
  CrmTextArea,
  CrmTextInputNumber,
  CrmCheckbox,
} from '@cartrack-crm/ui'

import { useCrmToastr, CrmToastrType, CrmToastrPosition } from '@cartrack-crm/ui/src/toastrs'

interface IModalGeneralSection {
  data: any
  isOpen: boolean
  onSave: (data: any) => any
  onClose: () => any
}
export const ModalGeneralSection: React.FC<IModalGeneralSection> = (props) => {
  const [showToastr] = useCrmToastr()
  const [data, setData] = useState(props.data)
  const [metaString, setMetaString] = useState(JSON.stringify(props?.data?.meta))
  useEffect(() => {
    setData(props.data)
  }, [props])
  const onChangeTextValue = (value, field) => {
    let pData = { ...data }
    pData[field] = value
    setData(pData)
  }
  const onSave = () => {
    try {
      const meta = JSON.parse(metaString)
      let pData = { ...data }
      pData.meta = meta
      if (props.onSave) props.onSave({ ...pData })
      props?.onClose()
    } catch (err) {
      showToastr(CrmToastrType.error, 'Validate Error', {
        message: err.message,
        timeToShow: 4000,
        position: CrmToastrPosition.topRight
      })
    }
  }
  const onSetMetaString = (value) => {
    let pData = { ...data }
    pData.meta = value
    setMetaString(value)
  }

  return (
    <CrmModal
      isOpen={props.isOpen}
      onClose={props?.onClose}
      isShowCloseBtn
      title='General Section'
      size='lg'
      showFooter
      primaryButtonLabel='Save'
      secondaryButtonLabel='Cancel'
      onSecondaryButton={props?.onClose}
      onPrimaryButton={onSave}
    >
      <div className='util-flexColumn util-fullHeight'>
        <div className='util-paddingMd row'>
          <div className='col-md-4'>
            <CrmLabeledField label='Component Uid' value={data?.component_uid ?? '-'} />
          </div>
          <div className='col-md-3'>
            <CrmTextInput
              input={{
                value: data?.name,
                onChange: (e) => onChangeTextValue(e?.target?.value, 'name')
              }}
              placeholder="Component Name"
            />
          </div>
          <div className='col-md-3'>
            <CrmTextInput
              input={{
                value: data?.code,
                onChange: (e) => onChangeTextValue(e?.target?.value, 'code')
              }}
              placeholder="Component Code"
            />
          </div>
          <div className='col-md-3'>
            <CrmTextInput
              input={{
                value: data?.type,
                onChange: (e) => onChangeTextValue(e?.target?.value, 'type')
              }}
              placeholder="Component Type"
            />
          </div>
          <div className='col-md-2'>
            <CrmTextInput
              input={{
                value: data?.access_level,
                onChange: (e) => onChangeTextValue(e?.target?.value, 'access_level')
              }}
              placeholder="Access Level"
            />
          </div>
          <div className='col-md-2'>
            <CrmTextInputNumber
              input={{
                value: data?.def_version,
                onChange: (e) => onChangeTextValue(e?.target?.value, 'def_version')
              }}
              placeholder="Def Version"
            />
          </div>
          <div className='col-md-6 util-paddingSm'>
            <CrmCheckbox label='Enable Excel Export' value={data?.enable_excel_export} onChange={() => onChangeTextValue(!data?.enable_excel_export, 'enable_excel_export')} />
          </div>
          <div className='col-md-6 util-paddingSm'>
            <CrmCheckbox label='is Published' value={data?.is_published} onChange={() => onChangeTextValue(!data?.is_published, 'is_published')} />
          </div>
          <div className='col-md-12'>
            <CrmTextArea
              rows={15}
              placeholder='meta'
              input={{
                value: metaString,
                onChange: (e) => {
                  onSetMetaString(e?.target?.value)
                }
              }}
            />
          </div>
        </div>
      </div>
    </CrmModal>)
}