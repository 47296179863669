/* eslint-disable camelcase */
import React from 'react'
import { bool, func, shape } from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedTime } from 'react-intl'
import { mapContextToFilter } from '../dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { hhmmss } from 'crm-utils/common-utils'
import { CrmModal } from 'crm-components'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'

const columns = [
  {
    Header: 'Account Name',
    Cell: row => (
      <Link
        to={'/crm/account/' + row.original.account_uid + '/sales'}
        target="blank"
      >
        {row.original.account__name}
      </Link>
    ),
    accessor: 'account__name',
    width: 250
  },
  {
    Header: 'Lead In',
    accessor: 'account__lead_in_date',
    width: 100,
    Cell: row =>
      row.original.account__lead_in_date ? (
        <FormattedDate value={row.original.account__lead_in_date} />
      ) : (
        <span />
      )
  },
  {
    Header: 'Lead Campaign',
    accessor: 'account__lead_campaign__name',
    width: 100
  },

  {
    Header: 'Activity date',
    accessor: 'start_time',
    Cell: row =>
      row.original.start_time ? (
        <span>
          <FormattedDate value={row.original.start_time} />{' '}
          <FormattedTime value={row.original.start_time} />
        </span>
      ) : (
        <span />
      ),
    width: 150
  },
  {
    Header: 'Type',
    accessor: 'type__name',
    width: 120
  },
  {
    Header: 'Disposition',
    accessor: 'disposition__name',
    width: 150
  },
  {
    Header: 'Performed by',
    accessor: 'performer__full_name',
    width: 150
  },
  {
    Header: 'Time',
    accessor: 'call_length',
    Cell: row =>
      Object.prototype.hasOwnProperty.call(row.original, 'call_length') &&
      row.original.call_length
        ? hhmmss(row.original.call_length)
        : '',
    width: 70
  },
  // {
  //   Header: 'Module',
  //   accessor: 'module',
  //   width: 80
  // },
  {
    Header: 'Note',
    accessor: 'note_text'
  }
]
const qlOptions = {
  options: ownProps => {
    console.log('qlOtions, ', ownProps)
    const props = { analyticsContext: { filters: ownProps.filters } }
    const filter = {
      ...ownProps.filters,
      ...mapContextToFilter(props, true, 'start_time')
    }
    filter.type__medium_code = { $in: ['phone_call'] }
    return {
      variables: {
        filter,
        fields: [
          'account_uid',
          'account__name',
          'start_time',
          'created_by__full_name',
          'performer__full_name',
          'performer__user_uid',
          'created_by_user_uid',
          'disposition__name',
          'disposition_uid',
          'disposition__code',
          'disposition__is_success',
          'status__name',
          'call_length',
          'type__name',
          'module',
          'note_text',
          'account__lead_campaign__name',
          'account__lead_in_date',
          'status__is_success'
        ],
        rootType: 'ActivitiesListQM'
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions
})

class LeadsDetailsPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  getTrProps = (state, rowInfo) => {
    const ret = { style: {} }
    if (rowInfo && rowInfo.original) {
      if (!rowInfo.original.status__is_success) {
        ret.style.opacity = 0.5
      }
      if (rowInfo.original.disposition__code === 'SALE') {
        ret.style.opacity = 1
        ret.style.color = '#325d34'
      }
      const lostCodes = ['NI', 'OT', 'A', 'NOVEH', 'BOGUS']
      if (lostCodes.includes(rowInfo.original.disposition__code)) {
        ret.style.color = '#ce5239'
      }
    }
    return ret
  }
  render() {
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Call Dispositions Details"
        onClose={this.props.onClose}
        size="xl"
      >
        <Table
          columns={columns}
          style={{ width: '100%' }}
          filters={this.props.filters}
          getTrProps={this.getTrProps}
        />
      </CrmModal>
    )
  }
}

LeadsDetailsPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: shape({})
}
LeadsDetailsPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

export default LeadsDetailsPopup
