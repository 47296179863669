import React, { SFC, useEffect } from 'react'
import { useClickOutside } from 'react-click-outside-hook'
import FitmentEventHistoryInner from './fitment-event-history-inner'

interface Props {
  isOpen: boolean
  onClose: () => void
  order: any
}
const FitmentEventHistoryModal: SFC<Props> = props => {
  const classNames = ['AccountPageRightSidePanel-wrapper']
  if (props.isOpen) {
    classNames.push('AccountPageRightSidePanel--open')
  }

  return (
    <div className={classNames.join(' ')}>
      {props.isOpen && (
        <WrappedInner onClose={props.onClose} order={props.order} />
      )}
    </div>
  )
}

const WrappedInner = props => {
  const [ref, hasClickOutSide] = useClickOutside()

  useEffect(() => {
    if (hasClickOutSide) {
      props.onClose()
    }
  }, [hasClickOutSide])

  return (
    <div className="AccountPageRightSidePanel-inner" ref={ref}>
      <FitmentEventHistoryInner order={props.order} />
    </div>
  )
}

export default FitmentEventHistoryModal
