import React, { createRef } from 'react'
import ReactTable from 'react-table'
import { withApollo } from 'react-apollo'
import {
  CrmFullpageContainer,
  CrmDropdown,
  CrmModal,
  CrmDateRangeDropdown,
  CrmCheckbox
} from 'crm-components'
import moment from 'moment'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table'
import requestJobsQL, { requestJobTypesQL } from './jobs-reports-gql'
import JobsRelationView, { PreviewJson } from './jobs-relation-view'

const qlOptions = {
  options: (ownProps) => {
    const filter = {
      type: ownProps.type,
      status: ownProps.status,
      only_parent: ownProps.onlyParent,
      reference: ''
    }
    if (ownProps) {
      filter.reference = ownProps.reference
    }

    console.log('qlOptionsqlOptions', filter)
    const { pageSize, page } = ownProps.listState
    return {
      variables: {
        filter,
        first: pageSize,
        skip: page * pageSize,
        range: {
          startAt: moment(ownProps.range.from)
            .startOf('day')
            .format(),
          endAt: moment(ownProps.range.to)
            .endOf('day')
            .format()
        }
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => {
    let tableData = undefined
    if (tableDataQl.list_jobs) {
      tableData = {
        data: tableDataQl.list_jobs.nodes,
        count: tableDataQl.list_jobs.totalCount
      }
    }
    return {
      ...ownProps,
      onDataLoaded: undefined,
      tableDataQl: tableDataQl,
      tableData: tableDataQl ? tableData : []
    }
  },
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({ query: requestJobsQL, qlOptions })
interface IJobReports {
  type?: string
  client?: any
  reference?: string
  renderResult?: (row) => void
}
class JobsReports extends React.Component<IJobReports> {
  state = {
    showModal: false,
    selected: null,
    correlation_uid: '',
    filter: {
      type: this.props.type || 'all',
      status: 'all'
    },
    range: {
      from: moment()
        .startOf('month')
        .toDate(),
      to: moment()
        .endOf('day')
        .toDate()
    },
    onlyParent: true,
    types: ['all']
  }
  componentDidMount() {
    this.fetchTypes()
  }

  fetchTypes = () => {
    const variables = {
      filter: {
        only_parent: this.state.onlyParent
      }
    }
    this.props.client
      .query({
        query: requestJobTypesQL,
        variables,
        fetchPolicy: 'no-cache'
      })
      .then((response) => {
        const types = response.data.list_job_types.types
        this.setState({ types: ['all', ...types] })
      })
  }
  getModalTitle(selected) {
    return selected ? `${selected.type}: ${selected.job_name}` : ''
  }
  relationView = createRef<JobsRelationView>()

  onFilterChange = (key, value) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [key]: value
      }
    })
  }
  toggleOnlyParent = () => {
    this.setState({ onlyParent: !this.state.onlyParent }, this.fetchTypes)
  }
  handleRangeChange = (range) => {
    this.setState({ range })
  }
  handleCloseModal = () => this.setState({ showModal: false })
  get columns() {
    const columns = [
      {
        Header: 'Name',
        accessor: 'job_name',
        Cell: (row) => <div>{row.original.job_name}</div>
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (row) => <div>{row.original.type ? row.original.type : ''}</div>
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        Cell: (row) => <div>{row.original.status}</div>
      },
      {
        Header: 'Input',
        accessor: 'input',
        width: 100,
        Cell: (row) => <PreviewJson json={row.original.input} />
      },
      {
        Header: 'Result',
        accessor: 'result',
        width: 100,
        Cell: (row) => {
          if (this.props.renderResult) {
            return this.props.renderResult(row.original.result)
          }
          return <PreviewJson json={row.original.result} />
        }
      },
      {
        Header: 'Last Log',
        Cell: (row) => {
          if (row.original.logs) {
            return (
              <div>
                {row.original.logs.reduce(
                  (p, c) => (c.message ? c.message : p),
                  ''
                )}
              </div>
            )
          }
          return <div></div>
        }
      },
      {
        Header: 'Created At',
        accessor: 'created_time',
        width: 160,
        Cell: (row) => (
          <div>
            {moment(row.original.created_time).format('YYYY/MM/DD HH:mm:ss')}
          </div>
        )
      },
      {
        Header: 'Actions',
        width: 150,
        Cell: (row) => (
          <div>
            {row.original.logs && (
              <button
                onClick={() =>
                  this.setState({ showModal: true, selected: row.original })
                }
              >
                Details
              </button>
            )}
            {row.original.has_children && (
              <button
                onClick={() => {
                  this.relationView.current!.reload(
                    row.original.job_uid,
                    this.getModalTitle(row.original)
                  )
                }}
              >
                Children
              </button>
            )}
          </div>
        )
      }
    ]
    return columns
  }
  get typeOptions() {
    return this.state.types.map((type) => {
      return {
        name: type === 'all' ? 'All' : type,
        value: type
      }
    })
  }
  render() {
    const getTdProps = (state, rowInfo, column, instance) => ({
      onClick: (e, handleOriginal) => {
        handleOriginal && handleOriginal()
      }
    })
    return (
      <React.Fragment>
        <div className="util-fullHeight util-flexColumn">
          <div className="util-marginBottom row">
            <div className="col-md-3">
              <CrmDropdown
                placeholder="Type Filter"
                options={this.typeOptions}
                input={{
                  value: this.state.filter.type,
                  onChange: (e) => this.onFilterChange('type', e)
                }}
              />
            </div>
            <div className="col-md-3">
              <CrmDropdown
                placeholder="Status Filter"
                options={[
                  { name: 'All', value: 'all' },
                  { name: 'Success', value: 'success' },
                  { name: 'Error', value: 'error' },
                  { name: 'Pending', value: 'pending' }
                ]}
                input={{
                  value: this.state.filter.status,
                  onChange: (e) => this.onFilterChange('status', e)
                }}
              />
            </div>
            <div className="col-md-3">
              <CrmDateRangeDropdown
                placeholder="Please Select Range"
                input={{ value: this.state.range }}
                onChange={this.handleRangeChange}
              />
            </div>
            <div className="col-md-3">
              <CrmCheckbox
                label="Display only parent jobs"
                input={{
                  value: this.state.onlyParent,
                  onChange: this.toggleOnlyParent
                }}
              />
            </div>
          </div>
          <Table
            range={this.state.range}
            status={this.state.filter.status}
            onlyParent={this.state.onlyParent}
            type={this.state.filter.type}
            columns={this.columns}
            reference={this.props.reference}
            getTdProps={getTdProps}
          />
        </div>
        <CrmModal
          isOpen={this.state.showModal}
          title={this.getModalTitle(this.state.selected)}
          onClose={this.handleCloseModal}
          size="lg"
        >
          <ReactTable
            data={this.state.selected ? this.state.selected.logs || [] : []}
            columns={[
              {
                Header: 'At',
                accessor: 'created_time',
                width: 200,
                Cell: (row) => moment(row.value).format('YYYY/MM/DD HH:mm:ss')
              },
              {
                Header: 'Message',
                accessor: 'message'
              },
              {
                Header: 'Json',
                accessor: 'json',
                width: 100,
                Cell: (row) => (
                  <div style={{ whiteSpace: 'normal' }}>
                    <PreviewJson json={row.value} />
                  </div>
                )
              }
            ]}
          />
        </CrmModal>
        <JobsRelationView ref={this.relationView} client={this.props.client} />
      </React.Fragment>
    )
  }
}

const WrappedComponent = withApollo<any>(JobsReports as any)
export default WrappedComponent

export const JobsAdminReportView = () => (
  <CrmFullpageContainer style={{ marginTop: 10 }} hasPadding vbox>
    <WrappedComponent />
  </CrmFullpageContainer>
)
