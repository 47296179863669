/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { reduxForm, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { func, shape, arrayOf, string } from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import PrintQuotationButtonQL from '../quotation-print-button'
import TemplateDropdown from '../template-dropdown'
import QuotationItem from './quotation-item'
import {
  CrmLabeledField,
  CrmTextArea,
  CrmButton,
  CrmDropdown,
  CrmTextInput,
  CrmDayPickerInput,
  CrmLoadingIcon
} from 'crm-components'
import ProcessWidget from 'crm-modules/processes/components/process-widget.jsx'
import QuotationWinLoseWidget from './quotation-win-lose-widget'
import cloneDeep from 'clone-deep'
import quotationWinQl from 'crm-modules/quotations/hoc/quotation-window-hoc'
import QuotationInlineEditable from './quotation-inline-editable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchProducts } from 'crm-duxs/settings/products-reducer'
import { getApiRootUrl } from 'crm-api/api-utils'
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync'
import {
  PriceTableCycleOptions,
  getPriceTableCycleName,
  contractLengthOptions,
  SalesTypeOptions
} from 'crm-data/prices-table'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

import SectionContactPerson from './section-contact-person/section-contact-person'
import SectionAddress from './section-address/section-address'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow'
import { _addrFormat } from 'crm-modules/meeting/hoc/edit-meeting-hoc.js'

interface IProps {
  onUnlink: Function
  onSaletypeChange: Function
  initialValues: any
  formValues: any
  productList: any
  quotation: any
  doFetchProducts: Function
  handleErrors: Function
  onSaveQuotation: Function
  documentUid: string
  processType: any
  onClose: Function
  onShowConfetti: Function
  onDeleteQuotation: Function
  handleSendEmailFromQuotation: Function
  accountPermissionsContext: any
  editableContext: any
  intl: any
  account: any
  persons: any
  addresses: any
}

interface IState {
  quotation: any
  productList: any
  selectedOption: any
  total: any
  showCurrency: boolean
  showDropDownMenu: boolean
  templateUid: string
  quotation_person: any
  quotation_address: any
  expanding_section: string
  selected_person: any
  selected_address: any
}

class QuotationForm extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    const { quotation, productList } = this.getStateDataMap(props.quotation, props.productList)
    this.state = {
      quotation,
      productList,
      selectedOption: '',
      total: this.getInitialSummaryValues(),
      showCurrency: false,
      showDropDownMenu: false,
      templateUid: undefined,
      quotation_person: undefined,
      quotation_address: undefined,
      expanding_section: '',
      selected_address: undefined,
      selected_person: undefined
    }
  }

  getInitialSummaryValues = () => {
    return {
      quantity: 0,
      value_net: 0, // total of hardware price
      subscription_net: 0, // total of first year price
      subscription_net_next_years: 0, // total of next years price
      sub_total: 0, // total of all prices (hardware + first + next)
      tax: 0, // total tax of all prices
      grand_total: 0, // total include tax of all prices
      hardwarePrice: {
        tax: 0, // total tax of harware
        grand_total: 0 // total include tax of harware
      },
      subPriceFirstYear: {
        tax: 0, // total tax of first year price
        grand_total: 0 // total include tax of first year price
      },
      subPriceNextYears: {
        tax: 0, // total tax of next years price
        grand_total: 0 // total include tax of next years price
      }
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, resolve)
    })

  componentWillReceiveProps(nextProps) {
    if (nextProps.quotation !== this.props.quotation || nextProps.productList !== this.props.productList) {
      const { quotation, productList } = this.getStateDataMap(nextProps.quotation, nextProps.productList)
      this.setState(
        {
          quotation,
          productList
        },
        () => {
          this.calculateTotal()
        }
      )
    }

    this.setState({
      quotation_person: this.findQuotationPerson(this.props.quotation, nextProps.persons)
    })

    this.setState({
      quotation_address: this.findQuotationAddress(this.props.quotation, nextProps.addresses)
    })
  }

  findQuotationPerson = (quotation, persons) => {
    let person = undefined
    if (quotation && persons.length > 0) {
      person = persons.find(v => v.account_person_uid === quotation.account_person_uid)
    }
    return person
  }

  findQuotationAddress = (quotation, addresses) => {
    let address = undefined
    if (quotation && addresses.length > 0) {
      address = addresses.find(v => v.address_uid === quotation.address_uid)
    }
    return address
  }

  getStateDataMap = (pQuotation, pProductList) => {
    const quotation = cloneDeep(pQuotation)
    const productList = cloneDeep(pProductList)
    quotation.items = quotation.items.map(item => {
      return {
        ...item,
        product: productList ? productList.filter(v => v.product_uid === item.product_uid)[0] : {}
      }
    })
    return { quotation, productList }
  }

  componentDidMount() {
    if (this.props.productList.length === 0) {
      this.props.doFetchProducts()
    }
    if (this.state.quotation.items.length === 0) {
      this.handleAddNewItem(null)
    }
    this.calculateTotal()

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleAddNewItem = e => {
    if (e) e.stopPropagation()
    const newItem = {
      document_item_uid: '',
      document_uid: this.props.documentUid,
      product_uid: null,
      quantity: 1,
      subscription_net: 0,
      subscription_net_next_years: 0,
      value_currency_code: null,
      value_gross: null,
      value_net: 0,
      value_tax: null
    }
    const quotation = { ...this.state.quotation }
    quotation.items = [...quotation.items, newItem]
    this.setState({ quotation })
  }

  handleRemoveItem = async (e, index) => {
    e.stopPropagation()
    const quotation = { ...this.state.quotation }
    if (quotation.items[index].document_item_uid) {
      // remove exist item
      quotation.items[index] = { ...quotation.items[index], is_deleted: true }
      await this.setStateAsync({ quotation })
      await this.handleSaveQuotation()
    } else {
      // remove new item
      const items = [...quotation.items]
      items.splice(index, 1)
      quotation.items = items
      await this.setStateAsync({ quotation })
    }
    this.calculateTotal()
  }
  getStatusCode = () => {
    const { quotation } = this.state
    const statusCode =
      quotation.process && quotation.process.current_step ? quotation.process.current_step.process_step_type.code : ''
    return statusCode
  }
  getProcessStepKind = () => {
    const { quotation } = this.state
    const kind =
      quotation.process && quotation.process.current_step ? quotation.process.current_step.process_step_type.kind : ''
    return kind
  }

  getProcessStepCode = () => {
    const { quotation } = this.state
    const kind =
      quotation.process && quotation.process.current_step ? quotation.process.current_step.process_step_type.code : ''
    return kind
  }

  handleClickWin = () => {
    const isWinButtonActive = this.getStatusCode() === 'presented'
    if (!isWinButtonActive) {
      toastr.error('Quotation cannot be won until it is presented')
    } else {
      this.setState({ selectedOption: 'win' })
    }
  }
  handleClickLost = () => {
    this.setState({ selectedOption: 'lost' })
  }
  handleCancelWinLost = () => {
    this.setState({ selectedOption: '' })
  }
  handleClickDeleteQuotation = async () => {
    var r = confirm(
      this.props.intl.formatMessage({
        id: 'crm.account.are_you_sure_you_want_to_delete_this_quotation',
        defaultMessage: 'Are you sure you want to delete this quotation ?'
      })
    )
    if (r === true) {
      try {
        const quotation = { ...this.state.quotation }
        quotation.is_deleted = true
        await this.setStateAsync({ quotation })
        await this.props.onDeleteQuotation(quotation)
        toastr.success('Quotation deleted')
      } catch (err) {
        console.log('Delete quotation error', err)
        toastr.error('Delete quotation error')
      }
    }
  }

  handleProspectLost = () => {
    console.log('handleProspectLost')
  }
  handleFieldChange = async (fieldName, newValue) => {
    const quotation = { ...this.state.quotation }

    quotation[fieldName] = newValue
    await this.setStateAsync({ quotation })
    await this.handleSaveQuotation()
  }
  handleProductItemChange = async (fieldName, index, newValue) => {
    const quotation = { ...this.state.quotation }

    if (fieldName === 'productItem') {
      quotation.items[index].product = newValue.product || {}
      quotation.items[index].discount = newValue.discount || 0
      quotation.items[index].product_uid = newValue.product ? newValue.product.product_uid : null
      quotation.items[index].value_net = newValue.value_net
      quotation.items[index].subscription_net = newValue.subscription_net
      quotation.items[index].subscription_net_next_years = newValue.subscription_net_next_years
      quotation.items[index].quantity = newValue.quantity
    }
    const error = await this.validateProductItem()
    if (error) {
      toastr.error(error)
    } else {
      this.calculateTotal()
      await this.handleSaveQuotation()
    }
  }
  validateProductItem = async () => {
    const quotation = cloneDeep(this.state.quotation)
    quotation.items = quotation.items.map(v => {
      delete v.__typename
      return {
        ...v,
        quantity: parseInt(Number(v.quantity).toString()),
        discount: v.discount ? parseInt(Number(v.discount).toString()) : 0,
        value_net: parseFloat(Number(v.value_net).toString()),
        subscription_net: parseFloat(Number(v.subscription_net).toString()),
        subscription_net_next_years: parseFloat(Number(v.subscription_net_next_years).toString())
      }
    })
    const invalidProduct = quotation.items.filter(v => !v.product_uid)
    if (invalidProduct.length > 0) {
      return 'Product cannot be empty'
    }
    const invalidQuantity = quotation.items.filter(v => v.quantity === 0)
    if (invalidQuantity.length > 0) {
      return 'Quantity cannot be empty'
    }
    await this.setStateAsync({ quotation })
    return ''
  }
  isValidQuotationPerson = person => {
    const ct = person?.contact_details ? person.contact_details : []
    let has_email = false
    let has_phone = false
    ct.map(v => {
      if (['phone', 'mobile'].includes(v.kind) && v.value) {
        has_phone = true
      }
      if (v.kind === 'email' && v.value) {
        has_email = true
      }
    })
    return has_email && has_phone
  }
  validateQuotation = async () => {
    const quotation = cloneDeep(this.state.quotation)
    if (!quotation.account_person_uid) {
      return 'Quotation person cannot be empty'
    }
    if (!quotation.name) {
      return 'Quotation name cannot be empty'
    }
    if (!quotation.cycle_code) {
      return 'Cycle cannot be empty'
    }
    // if (!quotation.contract_length) {
    //   return 'Contract length cannot be empty'
    // }
    if (!quotation.sale_type) {
      return 'Sales type cannot be empty'
    }
    if (!quotation.currency_code) {
      return 'Currency cannot be empty'
    }
    if (!quotation.document_date) {
      return 'Document date cannot be empty'
    }
    if (!quotation.items || quotation.items.length === 0) {
      return 'Product item cannot be empty'
    } else {
      const invalidProduct = quotation.items.filter(v => !v.product_uid)
      if (invalidProduct.length > 0) {
        return 'Product cannot be empty'
      }
      const invalidQuantity = quotation.items.filter(v => v.quantity === 0)
      if (invalidQuantity.length > 0) {
        return 'Quantity cannot be empty'
      }
      const invalidPrice =
        parseFloat(Number(this.state.total.value_net).toString()) <= 0 &&
        parseFloat(Number(this.state.total.subscription_net).toString()) <= 0
      //let user be able to add 0 price and add check only TH can be 0 price on api
      // if (invalidPrice) {
      //   // return 'Price cannot be empty'
      // }
    }
    return ''
  }
  handleChangeQuotationTemplate = temUid => {
    this.setState({ templateUid: temUid })
  }
  formatTotal = value => {
    return value.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  calculateTotal = () => {
    const total = this.getInitialSummaryValues()
    this.state.quotation.items.map(v => {
      if (v.is_deleted) {
        return false
      }
      if (v.product) {
        total.quantity += !v.product.is_addon ? Number(v.quantity) : 0
      } else {
        total.quantity += 0
      }
      const discountPercentValue = v.discount && v.discount !== 0 ? parseInt(v.discount) / 100 : 0
      const itemValueNet = parseFloat(Number(v.value_net).toString()) * parseInt(Number(v.quantity).toString())
      const itemSubNet = parseFloat(Number(v.subscription_net).toString()) * parseInt(Number(v.quantity).toString())
      const itemSubNetNextYears =
        parseFloat(Number(v.subscription_net_next_years).toString()) * parseInt(Number(v.quantity).toString())
      const itemValueNetWithDiscount =
        discountPercentValue === 0 ? itemValueNet : itemValueNet - itemValueNet * discountPercentValue
      const itemSubNetWithDiscount =
        discountPercentValue === 0 ? itemSubNet : itemSubNet - itemSubNet * discountPercentValue
      const itemSubNetNextYearsWithDiscount =
        discountPercentValue === 0
          ? itemSubNetNextYears
          : itemSubNetNextYears - itemSubNetNextYears * discountPercentValue
      total.value_net += itemValueNetWithDiscount
      total.subscription_net += itemSubNetWithDiscount
      total.subscription_net_next_years += itemSubNetNextYearsWithDiscount

      const itemSubTotal = itemValueNetWithDiscount + itemSubNetWithDiscount
      total.sub_total += itemSubTotal

      if (v.product && v.product.tax_rate) {
        // all prices
        const itemTax = itemSubTotal * (v.product.tax_rate / 100)
        total.tax += itemTax
        total.grand_total += itemSubTotal + itemTax

        // hardware
        const hardwareTax = itemValueNetWithDiscount * (v.product.tax_rate / 100)
        total.hardwarePrice.tax += hardwareTax
        total.hardwarePrice.grand_total += itemValueNetWithDiscount + hardwareTax

        // first year price
        const firstYearTax = itemSubNetWithDiscount * (v.product.tax_rate / 100)
        total.subPriceFirstYear.tax += firstYearTax
        total.subPriceFirstYear.grand_total += itemSubNetWithDiscount + firstYearTax

        // next year prices
        const nextYearsTax = itemSubNetNextYearsWithDiscount * (v.product.tax_rate / 100)
        total.subPriceNextYears.tax += nextYearsTax
        total.subPriceNextYears.grand_total += itemSubNetNextYearsWithDiscount + nextYearsTax

        console.log('Summary - hardware', itemValueNetWithDiscount, hardwareTax, total.hardwarePrice)
        console.log('Summary - subPriceFirstYear', itemSubNetWithDiscount, firstYearTax, total.subPriceFirstYear)
        console.log(
          'Summary - subPriceNextYears',
          itemSubNetNextYearsWithDiscount,
          nextYearsTax,
          total.subPriceNextYears
        )
      }
    })
    console.log('Summary - Total', total)

    // format total
    total.value_net = this.formatTotal(total.value_net)
    total.subscription_net = this.formatTotal(total.subscription_net)
    total.subscription_net_next_years = this.formatTotal(total.subscription_net_next_years)
    total.sub_total = this.formatTotal(total.sub_total)
    total.tax = this.formatTotal(total.tax)
    total.grand_total = this.formatTotal(total.grand_total)

    total.hardwarePrice.tax = this.formatTotal(total.hardwarePrice.tax)
    total.hardwarePrice.grand_total = this.formatTotal(total.hardwarePrice.grand_total)

    total.subPriceFirstYear.tax = this.formatTotal(total.subPriceFirstYear.tax)
    total.subPriceFirstYear.grand_total = this.formatTotal(total.subPriceFirstYear.grand_total)

    total.subPriceNextYears.tax = this.formatTotal(total.subPriceNextYears.tax)
    total.subPriceNextYears.grand_total = this.formatTotal(total.subPriceNextYears.grand_total)

    this.setState({ total })
  }

  getCleanProductItems = items => {
    return items.filter(v => v.product_uid && v.quantity)
  }

  cleanProductItems = async () => {
    const quotation = { ...this.state.quotation }
    quotation.items = this.getCleanProductItems(quotation.items)
    this.setState({ quotation }, () => {
      this.calculateTotal()
      if (this.state.quotation.items.filter(v => !v.is_deleted).length === 0) {
        this.handleAddNewItem(null)
      }
    })
  }

  handleSaveQuotation = async () => {
    try {
      const quotation = { ...this.state.quotation }
      quotation.items = quotation.items.map(v => {
        // delete v.product
        if (!v.quantity) v.quantity = 0
        if (!v.value_net) v.value_net = 0
        if (!v.subscription_net) v.subscription_net = 0
        if (!v.subscription_net_next_years) v.subscription_net_next_years = 0
        return { ...v }
      })
      await this.props.onSaveQuotation(quotation)
      toastr.success('Quotation saved')
    } catch (err) {
      toastr.error('Problem updating quotation')
    }
  }

  renderColAction = (index, isLastRow) => {
    return (
      <div className="leftBorder">
        <div className="rotate">
          <span title="Click to remove product" onClick={e => this.handleRemoveItem(e, index)}>
            <FontAwesomeIcon className="util-hooverable util-pointer" icon="times" />
          </span>
        </div>
      </div>
    )
  }

  // Ellipse downdown menu
  refMenu: any = React.createRef()
  handleClickOutside = e => {
    if (this.refMenu && this.refMenu.current && !this.refMenu.current.contains(e.target)) {
      this.setState({ showDropDownMenu: false })
    }
  }
  showDropDownMenu = () => {
    this.setState({ showDropDownMenu: !this.state.showDropDownMenu })
  }
  handleShowCurrency = () => {
    this.setState({ showCurrency: !this.state.showCurrency })
  }
  isAllItemsFromPriceTable = () => {
    let result = false
    this.state.quotation.items.map(v => {
      if (v.price_table_item_uid) {
        result = true
      }
    })
    return result
  }

  getIsEditable = () => {
    const result =
      this.getProcessStepKind() === 'new' &&
      this.props.accountPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.QUOTATION_MODIFY)
    // if (this.props.editableContext.isEditable !== result) {
    //   this.props.editableContext.setIsEditable(result)
    // }
    return result
  }

  // Address & Contact Person
  handleEditStart = (e, expanding_section) => {
    e.stopPropagation()
    if (!this.getIsEditable()) return
    if (this.state.expanding_section === expanding_section) {
      this.handleEditEnd()
    } else {
      this.setState({
        expanding_section: expanding_section
      })
    }
  }
  handleEditEnd = () => {
    this.setState({
      expanding_section: ''
    })
  }
  handleQuotationPersonChange = (selected_person, do_api_update) => {
    this.setState({ selected_person: selected_person })
    this.handleEditEnd()
    if (do_api_update) {
      this.handleFieldChange('account_person_uid', selected_person.account_person_uid)
    }
  }
  handleQuotationAddressChange = (selected_address, do_api_update) => {
    this.setState({ selected_address: selected_address })
    this.handleEditEnd()
    if (do_api_update) {
      this.handleFieldChange('address_uid', selected_address.address_uid)
    }
  }

  render() {
    console.log('Render QuotationForm', this.props, this.state)
    const { formValues, account, persons, addresses } = this.props
    const { quotation, total, selected_person, selected_address, quotation_person, quotation_address } = this.state

    const quotation_var: any = {
      document_uid: this.props.initialValues.document_uid,
      template_code: 'crm.opportunity_cartrack',
      account_person_uid: selected_person?.account_person_uid,
      address_uid: selected_address?.address_uid
    }
    if (this.state.templateUid) {
      quotation_var.template_uid = this.state.templateUid
    }
    let qlVariables = {
      quotation: { ...quotation_var },
      fileName: 'Quotation'
    }

    const isCompleted = formValues && formValues.process && formValues.process.completed_time

    const status =
      quotation.process && quotation.process.current_step ? quotation.process.current_step.process_step_type.name : ''
    const isEditable = this.getIsEditable()
    const isAllItemsFromPriceTable = this.isAllItemsFromPriceTable()
    console.log('Render QuotationForm', this.props, this.state)
    const prefixLink = getApiRootUrl() + 'crm_resources.php?file='

    console.log('QuotationProcess', this.props.quotation)
    const showPrintPdfButton =
      this.getProcessStepCode() === 'approved' ||
      this.getProcessStepCode() === 'presented' ||
      this.getProcessStepCode() === 'won'

    console.log('showPrintPdfButton', this.getProcessStepCode())
    const showRequoteButton =
      this.getProcessStepKind() === 'approve' ||
      this.getProcessStepKind() === 'active' ||
      this.getProcessStepKind() === 'wait'
    const showWinButton = this.getProcessStepCode() === 'presented'

    return (
      <React.Fragment>
        <div className="util-flexRow">
          <div className="documentType">
            <FormattedMessage id="crm.quotation.quotation" defaultMessage="Quotation" />
          </div>
          <div className="util-flexGrow" />
          <div className="util-flexCenterContent">
            <div ref={this.refMenu} className="Ellipse-Dropdown" onClick={this.showDropDownMenu}>
              <div className="icon-background">
                <i className="fa fa-ellipsis-v" />
              </div>
              {this.state.showDropDownMenu && (
                <ul>
                  <li onClick={this.handleShowCurrency}>
                    <span>
                      <FormattedMessage id="crm.quotation.show_currency" defaultMessage="Show currency" />
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="contentWrapper">
          <div className="util-fullHeight util-flexColumn ">
            {/* Header Fields */}
            <div className="contentHeaderWrapper">
              <div className="row">
                <div className="col-md-3">
                  <QuotationInlineEditable
                    fieldName="name"
                    id="quotation-name"
                    label={<FormattedMessage id="crm.quotation.quotation_name" defaultMessage="Quotation Name" />}
                    value={quotation.name}
                    displayValue={quotation.name}
                    editable={isEditable}
                    readModeStyle="CrmLabeledField"
                    handleErrors={this.props.handleErrors}
                    emptyValue="-"
                    editComponent={CrmTextInput}
                    editComponentProps={{
                      placeholder: (
                        <FormattedMessage id="crm.quotation.quotation_name" defaultMessage="Quotation Name" />
                      ),
                      className: 'CrmTextInput-noRightPadding'
                    }}
                    handleFieldChange={this.handleFieldChange}
                  />
                </div>
                <div className="col-md-3">
                  <QuotationInlineEditable
                    fieldName="cycle_code"
                    id="quotation-cycle"
                    label={<FormattedMessage id="crm.quotation.cycle" defaultMessage="Cycle" />}
                    value={quotation.cycle_code}
                    displayValue={getPriceTableCycleName(quotation.cycle_code)}
                    editable={isEditable && !isAllItemsFromPriceTable}
                    readModeStyle="CrmLabeledField"
                    handleErrors={this.props.handleErrors}
                    emptyValue="-"
                    editComponent={CrmDropdown}
                    editComponentProps={{
                      placeholder: <FormattedMessage id="crm.quotation.cycle" defaultMessage="Cycle" />,
                      options: PriceTableCycleOptions
                    }}
                    handleFieldChange={this.handleFieldChange}
                  />
                </div>
                <div className="col-md-3" />
                <div className="col-md-3 documentDate">
                  <QuotationInlineEditable
                    fieldName="document_date"
                    id="quotation-document_date"
                    label={<FormattedMessage id="crm.quotation.document_date" defaultMessage="Document Date" />}
                    value={quotation.document_date}
                    displayValue={quotation.document_date}
                    editable={isEditable}
                    readModeStyle="CrmLabeledField"
                    handleErrors={this.props.handleErrors}
                    editComponent={CrmDayPickerInput}
                    handleFieldChange={this.handleFieldChange}
                  />
                </div>
              </div>
              <div className="row" style={{ margin: '2px 0px' }} />
              <div className="row">
                <div className="col-md-3">
                  <QuotationInlineEditable
                    fieldName="contract_length"
                    id="quotation-contract_length"
                    label={<FormattedMessage id="crm.quotation.contract_length" defaultMessage="Contract Length" />}
                    value={quotation.contract_length}
                    displayValue={quotation.contract_length}
                    editable={isEditable && !isAllItemsFromPriceTable}
                    readModeStyle="CrmLabeledField"
                    handleErrors={this.props.handleErrors}
                    emptyValue="-"
                    editComponent={CrmDropdown}
                    editComponentProps={{
                      placeholder: (
                        <FormattedMessage id="crm.quotation.contract_length" defaultMessage="Contract Length" />
                      ),
                      options: contractLengthOptions
                    }}
                    handleFieldChange={this.handleFieldChange}
                  />
                </div>
                <div className="col-md-3">
                  <QuotationInlineEditable
                    fieldName="sale_type"
                    id="quotation-sale_type"
                    label={<FormattedMessage id="crm.quotation.sales_type" defaultMessage="Sales type" />}
                    value={quotation.sale_type}
                    displayValue={quotation.sale_type}
                    editable={isEditable && !isAllItemsFromPriceTable}
                    readModeStyle="CrmLabeledField"
                    handleErrors={this.props.handleErrors}
                    emptyValue="-"
                    editComponent={CrmDropdown}
                    editComponentProps={{
                      placeholder: <FormattedMessage id="crm.quotation.sales_type" defaultMessage="Sales type" />,
                      options: SalesTypeOptions.filter(v => v.value !== 'all')
                    }}
                    handleFieldChange={this.handleFieldChange}
                  />
                </div>
                <div className="col-md-3">
                  {this.state.showCurrency && (
                    <QuotationInlineEditable
                      fieldName="currency_code"
                      id="quotation-currency_code"
                      label={<FormattedMessage id="crm.quotation.currency" defaultMessage="Currency" />}
                      value={quotation.currency_code}
                      displayValue={quotation.currency_code}
                      editable={isEditable && !isAllItemsFromPriceTable}
                      readModeStyle="CrmLabeledField"
                      handleErrors={this.props.handleErrors}
                      emptyValue="-"
                      editComponent={CrmDropdown}
                      editComponentProps={{
                        placeholder: <FormattedMessage id="crm.quotation.currency" defaultMessage="Currency" />,
                        options: [
                          { name: 'THB', value: 'THB' },
                          { name: 'USD', value: 'USD' },
                          { name: 'SGD', value: 'SGD' },
                          { name: 'MYR', value: 'MYR' },
                          { name: 'IDR', value: 'IDR' },
                          { name: 'PHP', value: 'PHP' },
                          { name: 'ZAR', value: 'ZAR' },
                          { name: 'PLN', value: 'PLN' },
                          { name: 'EUR', value: 'EUR' },
                          { name: 'KES', value: 'KES' },
                          { name: 'MZN', value: 'MZN' }
                        ]
                      }}
                      handleFieldChange={this.handleFieldChange}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  <CrmLabeledField
                    label={<FormattedMessage id="crm.quotation.status" defaultMessage="Status" />}
                    valueClassName="LabeledField-value"
                    noMargin
                  >
                    <div>{status}</div>
                  </CrmLabeledField>
                </div>
              </div>
            </div>
            <hr className="util-horizontalLine" />

            {/* Contact Person, Address */}
            <div className="row util-marginLeft">
              <div className="col-md-6">
                <div
                  className={`util-flexRow ${isEditable ? 'util-pointer' : ''}`}
                  onClick={e => this.handleEditStart(e, 'contact_person')}
                >
                  <div style={{ width: '30px', margin: 'auto 0px auto 0px' }}>
                    <FontAwesomeIcon icon={faUserFriends} />
                  </div>
                  <div className="LabeledField-value">
                    {selected_person ? selected_person.name : 'Select contact person'}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={`util-flexRow ${isEditable ? 'util-pointer' : ''}`}
                  onClick={e => this.handleEditStart(e, 'address')}
                >
                  <div style={{ width: '30px', margin: 'auto 0px auto 0px' }}>
                    <FontAwesomeIcon icon={faLocationArrow} />
                  </div>
                  <div className="LabeledField-value">
                    {' '}
                    {selected_address ? _addrFormat(selected_address) : 'Select address'}
                  </div>
                </div>
              </div>
            </div>
            <SectionContactPerson
              account={account}
              persons={persons}
              isExpanded={this.state.expanding_section === 'contact_person'}
              handleEditEnd={this.handleEditEnd}
              handleEditStart={e => this.handleEditStart(e, 'contact_person')}
              handleQuotationPersonChange={this.handleQuotationPersonChange}
              hideLabel={true}
              quotation_person={quotation_person}
            />
            <SectionAddress
              account_uid={account.account_uid}
              addresses={addresses}
              isExpanded={this.state.expanding_section === 'address'}
              handleEditEnd={this.handleEditEnd}
              handleEditStart={e => this.handleEditStart(e, 'address')}
              handleQuotationAddressChange={this.handleQuotationAddressChange}
              _addrFormat={_addrFormat}
              hideLabel={true}
              quotation_address={quotation_address}
            />
            <hr className="util-horizontalLine" />

            {/* Table Products */}
            <div className="tableWrapper">
              <table>
                <thead>
                  <tr>
                    <td>
                      <div style={{ marginRight: '13px' }}>
                        <table>
                          <tr>
                            <th className="colFirst colProduct util-valignTop">
                              <FormattedMessage id="crm.quotation.product" defaultMessage="Product" />
                            </th>
                            <th className="colProductType util-valignTop">
                              <FormattedMessage id="crm.quotation.type" defaultMessage="Type" />
                            </th>
                            <th className="colDisCount util-valignTop">
                              <FormattedMessage id="crm.quotation.discount" defaultMessage="Discount %" />
                            </th>
                            <th className="colQuantity util-valignTop">
                              <FormattedMessage id="crm.quotation.quantity" defaultMessage="Quantity" />
                            </th>
                            <th className="colHardwarePrice util-valignTop util-textRight">
                              <FormattedMessage id="crm.quotation.hardware_price" defaultMessage="Hardware Price" />
                            </th>
                            <th className="colSubscriptionPrice util-valignTop util-textRight">
                              <FormattedMessage
                                id="crm.quotation.subscription_price_first_year"
                                defaultMessage="Sub Price (first year)"
                              />
                            </th>
                            <th className="colSubscriptionPriceNextYears util-valignTop util-textRight">
                              <FormattedMessage
                                id="crm.quotation.subscription_price_next_year"
                                defaultMessage="Sub Price (next year)"
                              />
                            </th>
                            <th className="colTaxRate util-valignTop">
                              <FormattedMessage id="crm.quotation.tax" defaultMessage="Tax" />
                              {' %'}
                            </th>
                            <th className="colActionHeader">&nbsp;</th>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {quotation.items.map((item, index) => {
                    if (item.is_deleted) {
                      return
                    }
                    const isLastRow = index === quotation.items.length - 1
                    const productType = item.product
                      ? item.product.is_addon === true
                        ? 'Add-on'
                        : item.product.is_addon === false
                        ? 'Product'
                        : '-'
                      : '-'
                    return (
                      <tr className="stripe" key={index}>
                        <td>
                          <QuotationInlineEditable
                            fieldName="productItem"
                            id=""
                            label=""
                            value={item}
                            displayValue={''}
                            editable={isEditable && !isAllItemsFromPriceTable}
                            lockEditingMessage={
                              isAllItemsFromPriceTable
                                ? this.props.intl.formatMessage({
                                    id: 'crm.quotation.this_price_comes_from_price_table_and_cant_be_changed',
                                    defaultMessage: "This price comes from Price Table and can't be changed. "
                                  })
                                : ''
                            }
                            readModeStyle="productItem"
                            handleErrors={this.props.handleErrors}
                            editComponent={QuotationItem}
                            editComponentProps={{
                              // props for render edit mode
                              index: index,
                              item: item,
                              documentUid: this.props.documentUid,
                              isLastRow: isLastRow,
                              productList: this.props.productList,
                              renderColAction: this.renderColAction,
                              productType: productType
                            }}
                            // props for saveHandler
                            handleProductItemChange={this.handleProductItemChange}
                            // props for render read mode
                            item={item}
                            index={index}
                            isLastRow={isLastRow}
                            renderColAction={this.renderColAction}
                            productType={productType}
                          />
                        </td>
                      </tr>
                    )
                  })}
                  {isEditable && !isAllItemsFromPriceTable && (
                    <tr className="stripe">
                      <td>
                        <div style={{ marginRight: '13px' }}>
                          <table>
                            <tr>
                              <td colSpan={5} />
                              <td className="colAction">
                                <div className="leftBorder">
                                  <span
                                    className="AccountLeftSide-addButton"
                                    title="Click to add new product"
                                    onClick={e => this.handleAddNewItem(e)}
                                  >
                                    <FontAwesomeIcon className="util-hooverable util-pointer" icon="plus" />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
                <tr style={{ height: '70px' }} />
                <tfoot>
                  <tr className="border LabeledField-value">
                    <td className="border" colSpan={7}>
                      <div>
                        <table>
                          <tr className="stripe">
                            <td className="colFirst colProduct">
                              <FormattedMessage id="crm.quotation.total_net" defaultMessage="Total Net" />
                            </td>
                            <td className="colProductType" />
                            <td className="colDiscount"> {total.discount}</td>
                            <td className="colQuantity">{total.quantity}</td>
                            <td className="colHardwarePrice util-textRight">{total.value_net}</td>
                            <td className="colSubscriptionPrice util-textRight">{total.subscription_net}</td>
                            <td className="colSubscriptionPriceNextYears util-textRight">
                              {total.subscription_net_next_years}
                            </td>
                            <td className="colTaxRate" />
                            <td className="colAction">
                              <div className="util-hooverable util-pointer" onClick={this.cleanProductItems}>
                                <FontAwesomeIcon icon={faSync} />
                              </div>
                            </td>
                            <td className="colInlineEditIconWidth" />
                          </tr>
                          <tr className="stripe">
                            <td className="colFirst colProduct">
                              <FormattedMessage id="crm.quotation.tax" defaultMessage="Tax" />
                            </td>
                            <td colSpan={3} />
                            <td className="colHardwarePrice util-textRight">{total.hardwarePrice.tax}</td>
                            <td className="colSubscriptionPrice util-textRight">{total.subPriceFirstYear.tax}</td>
                            <td className="colSubscriptionPriceNextYears util-textRight">
                              {total.subPriceNextYears.tax}
                            </td>
                            <td colSpan={2} />
                            <td className="colInlineEditIconWidth" />
                          </tr>
                          <tr className="stripe">
                            <td className="colFirst colProduct">
                              <FormattedMessage id="crm.quotation.total_gross" defaultMessage="Total Gross" />
                            </td>
                            <td colSpan={3} />
                            <td className="colHardwarePrice util-textRight">{total.hardwarePrice.grand_total}</td>
                            <td className="colSubscriptionPrice util-textRight">
                              {total.subPriceFirstYear.grand_total}
                            </td>
                            <td className="colSubscriptionPriceNextYears util-textRight">
                              {total.subPriceNextYears.grand_total}
                            </td>
                            <td />
                            <td>{quotation.currency_code}</td>
                            <td className="colInlineEditIconWidth" />
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            {/* Note */}
            <div className="noteWrapper">
              <div className="row">
                <div className="col-md-6">
                  <QuotationInlineEditable
                    fieldName="note"
                    id="quotation-note"
                    label={
                      <FormattedMessage
                        id="crm.quotation.note_visible_to_customer"
                        defaultMessage="Note (visible to customer)"
                      />
                    }
                    value={quotation.note}
                    displayValue={quotation.note}
                    editable={isEditable}
                    readModeStyle="CrmLabeledField"
                    handleErrors={this.props.handleErrors}
                    emptyValue=""
                    editComponent={CrmTextArea}
                    editComponentProps={{
                      placeholder: <FormattedMessage id="crm.quotation.note" defaultMessage="Note" />,
                      rows: 3
                    }}
                    handleFieldChange={this.handleFieldChange}
                  />
                </div>
                <div className="col-md-6">
                  <QuotationInlineEditable
                    fieldName="internal_note"
                    id="quotation-internal_note"
                    label={<FormattedMessage id="crm.quotation.internal_note" defaultMessage="Internal Note" />}
                    value={quotation.internal_note}
                    displayValue={quotation.internal_note}
                    editable={isEditable}
                    readModeStyle="CrmLabeledField"
                    handleErrors={this.props.handleErrors}
                    emptyValue=""
                    editComponent={CrmTextArea}
                    editComponentProps={{
                      placeholder: <FormattedMessage id="crm.quotation.internal_note" defaultMessage="Internal Note" />,
                      rows: 3
                    }}
                    handleFieldChange={this.handleFieldChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.props.formValues && this.props.formValues.process ? (
                  <ProcessWidget
                    renderActions={true}
                    editable={true}
                    quotation={this.props.initialValues}
                    processUid={this.props.formValues.process.process_uid}
                    processTypeUid={this.props.formValues.process.process_type_uid}
                    showName={false}
                    showHint={true}
                    validateQuotation={this.validateQuotation}
                  />
                ) : (
                  <div>
                    <FormattedMessage id="crm.quotation.no_process" defaultMessage="No process" />
                  </div>
                )}
              </div>
            </div>
            {isAllItemsFromPriceTable && (
              <div className="LabeledField-value util-textGreen util-marginTop">
                <small>
                  <FormattedMessage
                    id="crm.quotation.all_items_in_this_quotation_are_from_price_table_and_will_be_approved_automatically"
                    defaultMessage="All items in this quotation are from Price Table and will be approved automatically."
                  />
                </small>
              </div>
            )}
            <div className="util-flexGrow" />

            {quotation.resource && (
              <div className="util-marginBottom util-alignRight">
                <div className="LabeledField-label">
                  <FormattedMessage id="global.generated_pdf" defaultMessage="Generated PDF" />
                </div>
                <div className="LabeledField-value">
                  <a href={prefixLink + quotation.resource.file_link} target="_blank" rel="noopener noreferrer">
                    {quotation.resource.name}
                  </a>
                </div>
              </div>
            )}

            {/* Buttons */}
            <div className="util-flexRow util-marginBottom">
              <div className="util-marginRight">
                <CrmButton
                  label={<FormattedMessage id="crm.ui.button.close" defaultMessage="Close" />}
                  grouped
                  onClick={() => {
                    this.props.onClose()
                  }}
                />
              </div>
              {this.props.accountPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.QUOTATION_MANAGE) && (
                <div className="util-marginRight">
                  <CrmButton grouped xsmall icon="trash" onClick={this.handleClickDeleteQuotation} />
                </div>
              )}

              {this.props.accountPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.QUOTATION_MODIFY) &&
                !isCompleted &&
                showRequoteButton &&
                this.props.processType && (
                  <div className="util-marginRight">
                    <CrmButton
                      grouped
                      label={<FormattedMessage id="crm.ui.button.requote" defaultMessage="Requote" />}
                      onClick={this.handleClickLost}
                    />
                  </div>
                )}

              <div className="util-flexGrow">
                {!this.props.processType && (
                  <div className="util-textCenter">
                    <FormattedMessage
                      id={`global.loading_more_data` + ' ...'}
                      defaultMessage={`Loading more data ...`}
                    />
                  </div>
                )}
              </div>

              {this.props.accountPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.QUOTATION_MODIFY) &&
                !isCompleted &&
                showWinButton &&
                this.props.processType && (
                  <div className="util-marginRight">
                    <CrmButton
                      grouped
                      label={<FormattedMessage id="crm.ui.button.win" defaultMessage="Win" />}
                      onClick={this.handleClickWin}
                    />
                  </div>
                )}

              {showPrintPdfButton &&
                this.props.accountPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.QUOTATION_MODIFY) && (
                  <div className="util-marginRight">
                    <CrmButton
                      grouped
                      label={<FormattedMessage id="crm.ui.button.send_quotation" defaultMessage="Send Quotation" />}
                      onClick={() => this.props.handleSendEmailFromQuotation(quotation.resource, quotation)}
                    />
                  </div>
                )}

              {showPrintPdfButton && (
                <div className="">
                  <PrintQuotationButtonQL
                    label={<FormattedMessage id="crm.ui.button.print_pdf" defaultMessage="Print PDF" />}
                    qlVariables={qlVariables}
                    successMessage="Success"
                    progressMessage={
                      <FormattedMessage id="crm.ui.button.generating_pdf" defaultMessage="Generating PDF..." />
                    }
                  />
                </div>
              )}
            </div>
            <div className="row util-marginBottom util-flexRowRight">
              {showPrintPdfButton && (
                <div className="col-md-3" style={{ marginLeft: '10px' }}>
                  <TemplateDropdown
                    documentTypeCode="quotation"
                    input={{
                      onChange: this.handleChangeQuotationTemplate,
                      value: this.state.templateUid ? this.state.templateUid : ''
                    }}
                  />
                </div>
              )}
              {/* <div className="col-md-3" style={{ marginLeft: '10px' }}>
                <QuotationInlineEditable
                  fieldName="is_show_term_and_condition"
                  id="quotation-is_show_term_and_condition"
                  label={
                    <FormattedMessage
                      id="crm.quotation.is_show_term_and_condition"
                      defaultMessage="Show Term and Condition"
                    />
                  }
                  value={quotation.is_show_term_and_condition ? 'yes' : 'no'}
                  displayValue={
                    quotation.is_show_term_and_condition ? 'Yes' : 'No'
                  }
                  editable
                  readModeStyle="CrmLabeledField"
                  handleErrors={this.props.handleErrors}
                  editComponent={CrmDropdown}
                  editComponentProps={{
                    placeholder: (
                      <FormattedMessage
                        id="crm.quotation.is_show_term_and_condition"
                        defaultMessage="Show Term and Condition"
                      />
                    ),
                    options: [
                      { value: 'yes', name: 'Yes' },
                      { value: 'no', name: 'No' }
                    ]
                  }}
                  handleFieldChange={this.handleFieldChange}
                />
              </div> */}
            </div>

            <div className="util-marginBottom" />
            {/* End: Buttons */}

            {/* {this.state.selectedOption && ( */}
            <div className="sliderContainer">
              <QuotationWinLoseWidget
                accountUid={this.props.initialValues.account_uid}
                opportunityUid={this.props.initialValues.opportunity_uid}
                quotation={this.props.initialValues}
                processType={this.props.processType}
                onShowConfetti={this.props.onShowConfetti}
                selectedOption={this.state.selectedOption}
                handleCancelWinLost={this.handleCancelWinLost}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    formValues: getFormValues('quotationOpportunityForm')(state),
    fetch: state.crm.products.fetch,
    productList: state.crm.products.lists
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doFetchProducts: () => dispatch(fetchProducts())
  }
}

const QuotationFormInner = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'quotationOpportunityForm',
    enableReinitialize: true
  })(QuotationForm)
)

interface QuotationFormOuterProps {
  initialValues: any
  quotation: any
}

interface QuotationFormOuterStates {
  isEditing: boolean
}

class QuotationFormOuter extends React.PureComponent<QuotationFormOuterProps, QuotationFormOuterStates> {
  static propTypes = {
    initialValues: shape({}).isRequired,
    quotation: shape({})
  }
  static defaultProps = {
    quotation: undefined
  }
  constructor(props) {
    super(props)
    this.state = { isEditing: false }
  }

  handleStartEdit = () => {
    this.setState({
      isEditing: true
    })
  }
  handleCancelEdit = () => {
    this.setState({ isEditing: false })
  }

  render() {
    console.log('QuotationForm19.render', this.props)
    return this.props.quotation ? (
      <QuotationFormInner
        {...this.props}
        initialValues={this.props.quotation}
        onStartEdit={this.handleStartEdit}
        onCancelEdit={this.handleCancelEdit}
        isEditing={this.state.isEditing}
      />
    ) : (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <CrmLoadingIcon />
      </div>
    )
  }
}

export default withAccountPermissionsContextHOC(quotationWinQl(injectIntl(QuotationFormOuter)))
