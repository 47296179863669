/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved, camelcase, no-unused-vars */
import { toastr } from 'react-redux-toastr'
import cloneDeep from 'clone-deep'
import { prepareTaskForServer } from './tasks-saga'
import {
  actions,
  getAvailableStatuses,
  getActivity
} from 'crm-duxs/activity-reducer'
import { getActivityTypes } from 'crm-duxs/activities-reducer'
import activitiesApi from 'crm-api/activities-api'
import accountApi from 'crm-api/accounts-api'
import { getUser } from 'crm-duxs/crm-reducer'
import { accountFieldsUpdated } from 'crm-duxs/account-reducer'
import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { findPerformerByCode } from 'crm-utils/activities-utils'
import {
  buildNewActivity,
  validateCreateActivity,
  validateActivity
} from 'crm-data/activities-utils'

function* startActivityEdit(action) {
  console.log('startActivityEdit')
  console.dir(action)

  yield put({
    type: actions.setEditedActivity.type,
    payload: { activity: action.payload.activity }
  })
  const availableStatuses = yield call(
    activitiesApi.listAvailableStatusesForActivityUid,
    action.payload.activity.activity_uid
  )
  console.log('got available actions')
  console.dir(availableStatuses)
  yield put({
    type: actions.fetchAvailableStatusesSuccess.type,
    payload: { availableStatuses }
  })
}

function* initNewActivity(action) {
  const currentUser = yield select(getUser)
  console.log('initNewActivity')
  console.dir(currentUser)
  const activity = buildNewActivity(
    action.payload.accountUid,
    action.payload.activityType,
    currentUser
  )

  // Load statuses for this kind of activity
  let availableStatuses = []
  // Filter only initials
  availableStatuses = availableStatuses.filter(status => status.is_initial)
  yield put({
    type: actions.fetchAvailableStatusesSuccess.type,
    payload: { availableStatuses }
  })
  if (availableStatuses && availableStatuses.length === 1 && activity.tmpId) {
    const formId = 'activity-' + activity.tmpId
    activity.activity_status_uid = availableStatuses[0].activity_status_uid
  }
  yield put({
    type: actions.setEditedActivity.type,
    payload: { activity, availableStatuses }
  })

  // If
}

function* createActivity(action) {
  // Convert activity to server structre
  console.log('createActivity')
  console.dir(action)
  // Let activity = yield select(getActivity) //  { ...action.payload.activity }
  const activity = cloneDeep(action.payload.activity)
  if (activity.type && !activity.activity_type_uid) {
    activity.activity_type_uid = activity.type.activity_type_uid
    delete activity.type
  }
  // Map primary_performer_user_uid to participant
  if (!activity.participants) {
    activity.participants = []
  }
  if (
    activity.participants.find(p => p.role_code === 'performer') ===
      undefined &&
    activity.primary_performer_user_uid
  ) {
    // Iif (activity.primary_performer_user_uid) {
    // const found = activity.participants.find(
    //   p => p.user_uid === activity.primary_performer_user_uid
    // )
    // if (!found) {
    activity.participants.push({
      user_uid: activity.primary_performer_user_uid,
      role_code: 'performer',
      is_primary: true
    })
    // }
  }
  // Eelse {
  //   throw new Error('Activity is mising primary performer')
  // }
  // Check activity type

  const activityTypes = yield select(getActivityTypes)
  const activityType = activityTypes.find(
    at => at.activity_type_uid === activity.activity_type_uid
  )
  if (!activityType) {
    toastr.error('Error creating activity - cant find activity type')
    return
  }
  if (!activity.activity_status_uid) {
    yield put({
      type: actions.createActivityError.type,
      payload: { errorMessage: 'Activity is mising status field' }
    })
    toastr.error('Please select activity status')
    return
  }
  console.log('type')
  console.dir(activityType)
  // Map arranged_by_user_uid to participant
  // if (activity.arranged_by_user_uid) {
  //   activity.participants.push({
  //     user_uid: activity.arranged_by_user_uid,
  //     role_code: 'arranged_by',
  //     is_primary: false
  //   })
  // } else {
  //   yield put({
  //     type: actions.createActivityError.type,
  //     payload: { errorMessage: 'Activity is mising information Arranged By' }
  //   })
  //   throw new Error('Activity is mising information Arranged By')
  // }
  // Attach Task
  if (activity.task && activity.has_task) {
    activity.task = prepareTaskForServer(activity.task)
  } else {
    delete activity.task
  }
  delete activity.has_task

  try {
    validateActivity(activity)
    validateCreateActivity(activity)
    const createdActivity = yield call(
      activitiesApi.createActivityApi,
      activity
    )
    yield put({
      type: actions.createActivitySuccess.type,
      payload: { activity: createdActivity }
    })
    console.log('createActivity Saga - pass to handlers')
    console.dir(action.payload.params)
    if (action.payload.params && action.payload.params.onSuccess) {
      action.payload.params.onSuccess({ activity: createdActivity })
    }
    // Notify accounts that field has been updates
    yield put(accountFieldsUpdated(activity.account_uid, ['activities']))
    toastr.success('Activity has been saved')
  } catch (err) {
    let msg = 'Problem creating activity'
    console.log('Problem creating activity', err)
    if (err instanceof Object && err.message) {
      msg += ': ' + err.message
    }
    if (err instanceof String) {
      msg += ': ' + err
    }
    toastr.error(msg)
    yield put({
      type: actions.createActivityError.type,
      payload: { errorMessage: 'There was problem creating activity' }
    })
    yield put(actions.saveUpdateActivityError.create())
  }
}

// Ffunction _prepareActivity(activity) {
//   const ret = { ...activity }
//   if (ret.type && !ret.activity_type_uid) {
//     ret.activity_type_uid = ret.type.activity_type_uid
//     delete ret.type
//   }
//   // Map primary_performer_user_uid to participant
//   ret.participants = []
//   if (ret.primary_performer_user_uid) {
//     ret.participants.push({
//       user_uid: ret.primary_performer_user_uid,
//       role_code: 'performer',
//       is_primary: true
//     })
//   } else {
//     throw new Error('Activity is mising primary performer')
//   }
//   return ret
// }

// function* _getActivityType() {
//   const activityTypes = yield select(getActivityTypes)
//   const activityType = activityTypes.find(
//     at => at.activity_type_uid === activity.activity_type_uid
//   )
//   if (!activityType) {
//     toastr.error('Error creating activity - cant find activity type')
//     return
//   }
//   return activityType
// }

// function* createLiveActivity(action) {
//   // Convert activity to server structre
//   console.log('createLiveActivity SAGA')
//   console.dir(action)

//   let activity = yield select(getActivity)

//   // Llet activity = { ...action.payload.activity }
//   activity = _prepareActivity(activity)
//   // Check activity type
//   const activityType = _getActivityType()
//   console.dir(activityType)
//   // Validate
//   if (
//     activityType.medium_code === 'phone_call' &&
//     !activity.has_task &&
//     !activity.has_qualification
//   ) {
//     toastr.error('New task or account qualification is required')
//     return
//   }
//   if (activity.has_qualification) {
//     if (
//       !activity.qualification ||
//       !activity.qualification.qualification_type_uid
//     ) {
//       toastr.error('Please select qualification')
//       return
//     }
//   } else {
//     delete activity.qualification
//   }
//   delete activity.has_qualification
//   activity.participants = activity.participants
//     ? activity.participants.slice()
//     : []
//   // Map arranged_by_user_uid to participant
//   if (activity.arranged_by_user_uid) {
//     activity.participants.push({
//       user_uid: activity.arranged_by_user_uid,
//       role_code: 'arranged_by',
//       is_primary: false
//     })
//   } else {
//     throw new Error('Activity is mising information Arranged By')
//   }
//   // Attach Task
//   if (activity.task && activity.has_task) {
//     activity.task = prepareTaskForServer(activity.task)
//   } else {
//     delete activity.task
//   }
//   delete activity.has_task

//   try {
//     const createdActivity = yield call(
//       activitiesApi.createLiveActivity,
//       activity
//     )
//     console.log('created activity')
//     console.dir(createdActivity)
//     yield put({
//       type: actions.createLiveActivitySuccess.type,
//       payload: { activity: createdActivity }
//     })
//     console.log('createActivity Saga - pass to handlers')
//     console.dir(action.payload.params)
//     if (action.payload.params && action.payload.params.onSuccess) {
//       action.payload.params.onSuccess({ activity: createdActivity })
//     }
//     // Notify accounts that field has been updates
//     // yield put(accountFieldsUpdated(activity.account_uid, ['activities']))
//     // yield put(toastr.success('Activity has been saved'))
//   } catch (err) {
//     toastr.error('Problem creating activity')
//   }
// }

function* updateActivity(action) {
  console.log('updateActivity SAGA')
  const activity = cloneDeep(action.payload.activity)
  console.dir(activity)
  // Const performerParticipant = findPerformerByCode(activity, 'performer')
  // console.log('performerParticipant')
  // console.dir(performerParticipant)
  // if (performerParticipant) {
  //   console.log('update performer')
  //   performerParticipant.user_uid = activity.primary_performer_user_uid
  // }

  try {
    validateActivity(activity)
    const updatedActivity = yield call(activitiesApi.updateActivity, activity)
    console.log('updatedActivity', updatedActivity)
    yield put({
      type: actions.updateActivitySuccess.type,
      payload: { activity: updatedActivity, keepOpen: action.payload.keepOpen }
    })

    console.log('updateActivitySuccess Saga - pass to handlers')
    console.dir(action.payload.params)
    if (action.payload.params && action.payload.params.onSuccess) {
      action.payload.params.onSuccess({ activity: updatedActivity })
    }
    // Notify accounts that field has been updates
    yield put(accountFieldsUpdated(activity.account_uid, ['activities']))
    toastr.success('Activity has been updates')
  } catch (err) {
    console.log('Update activity catch')
    console.dir(actions)
    yield put({ type: actions.saveUpdateActivityError.type })
    toastr.error('Problem updating activity')
  }
  console.log('Update Activity after catch')
  yield put({ type: actions.saveUpdateActivityError.type })
}

function* activityFormChange(action) {
  const availableStatuses = yield select(getAvailableStatuses)
  let selectedStatus
  if (action.payload.activity_status_uid) {
    selectedStatus = availableStatuses.find(
      st => st.activity_status_uid === action.payload.activity_status_uid
    )
  }
  // Get current status details
  // DEPR const isNextActionRequired = true
  // yield put({
  //   type: actions.setIsNextActionRequired.type,
  //   payload: { isNextActionRequired }
  // })
  // Recalculate is next action required
}

export default function* activitiesSaga() {
  yield takeLatest(actions.startActivityEdit.type, startActivityEdit)
  yield takeLatest(actions.initNewActivity.type, initNewActivity)
  yield takeLatest(actions.createActivity.type, createActivity)
  yield takeLatest(actions.updateActivity.type, updateActivity)
  yield takeEvery(actions.activityFormChange.type, activityFormChange)
}
