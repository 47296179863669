import React, { PureComponent } from 'react'
import { object, string, number } from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CustomMarker = ({ text, markerClass, markerStyles }) => (
  <div className={markerClass} style={markerStyles} title={text}>
    <FontAwesomeIcon icon="map-marker-alt" />
  </div>
)

class CrmGoogleMap extends PureComponent {
  render() {
    // console.log('render crm-google-map', this.props)
    return (
      <div style={{ height: this.props.height, width: this.props.width }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
          defaultCenter={this.props.center}
          center={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
        >
          {this.props.markerLocation && (
            <CustomMarker
              lat={this.props.markerLocation.lat}
              lng={this.props.markerLocation.lng}
              text={'....'}
              markerClass={this.props.markerClass}
              markerStyles={this.props.markerStyles}
            />
          )}
        </GoogleMapReact>
      </div>
    )
  }
}

CrmGoogleMap.propTypes = {
  center: object,
  zoom: number,
  height: string.isRequired,
  width: string.isRequired,
  markerLocation: object,
  markerClass: string,
  markerStyles: object
}

CrmGoogleMap.defaultProp = {
  zoom: 17,
  center: {},
  markerLocation: {},
  markerClass: '',
  markerStyles: {}
}

export default CrmGoogleMap

// onGoogleApiLoaded={({ map, maps }) =>
//   this.renderMarkers(map, maps, this.state.markerLocation)}

// renderMarkers(map, maps, markerLocation) {
//   console.log('renderMarkers', this.state)
//   if (markerLocation) {
//     let marker = new maps.Marker({
//       position: markerLocation,
//       map,
//       title: '...'
//     })
//   }
// }
