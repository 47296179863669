/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'

import { withApollo } from 'react-apollo'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { qlAgentsAnalytics } from './telesales-dash-utils'
import DashTelesalesAgentItem from './dash-telesales-agent-item.jsx'

import FlatTable from 'crm-components/flat-table.jsx'
import { CrmButton } from 'crm-components'

const columns = [
  {
    Header: 'Agent',
    accessor: 'user_name'
  },
  {
    Header: 'All Calls',
    accessor: 'activity_count'
  },
  {
    Header: 'Success',
    accessor: 'success_calls'
  },
  {
    Header: 'Call Time',
    accessor: 'call_length',
    Cell: row => {
      const val = row.original.call_length
        ? moment('2015-01-01')
            .startOf('day')
            .seconds(row.original.call_length)
            .format('H:mm:ss')
        : 0

      return <span>{val}</span>
    }
  },
  {
    Header: 'Disposition',
    accessor: 'call_disposition_name'
  },
  {
    Header: 'Activity Status',
    accessor: 'activity_status_name'
  }
]

class DashTelesalesAgents extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps !== undefined &&
      nextProps.analyticsContext !== this.props.analyticsContext
    ) {
      console.log(
        'DashTelesalesAgents propsChanged',
        this.props.analyticsContext,
        nextProps.analyticsContext
      )
      // Tthis.setState({ ...this.state }, () => {
      //   this.handleRefresh()
      // })
    }
  }

  _parseAgents = rawData => {
    const agents = {}
    const fields = ['call_length', 'activity_count', 'success_calls']
    rawData.forEach(row => {
      if (!agents[row.user_name]) {
        agents[row.user_name] = {
          rows: [],
          user_name: row.user_name,
          call_length: 0,
          activity_count: 0,
          success_calls: 0,
          dispositions: {}
        }
      }
      const agentRow = agents[row.user_name]
      agentRow.rows.push(row)
      fields.forEach(field => {
        if (row[field]) {
          agentRow[field] += Number(row[field])
        }
      })
      // Add dispositions
      if (row.call_disposition_name) {
        if (!agentRow.dispositions[row.call_disposition_name]) {
          agentRow.dispositions[row.call_disposition_name] = {
            disposition_name: row.call_disposition_name,
            count: 0,
            call_disposition_is_success: row.call_disposition_is_success
          }
        }
        agentRow.dispositions[row.call_disposition_name].count += Number(
          row.activity_count
        )
      }
    })

    return Object.values(agents).sort((a, b) =>
      a.user_name ? a.user_name.localeCompare(b.user_name) : 0
    )
  }

  handleRefresh = async () => {
    console.log('handleRefresh', this.props, this.state)
    // Build filters
    if (!this.props.analyticsContext.filters.resource_pool_uid) {
      toastr.error('Select Users Group in filters')
      return
    }

    const filter = mapContextToFilter(this.props)
    if (this.props.analyticsContext.filters.activity_date) {
      filter.start_time = this.props.analyticsContext.filters.activity_date
    }

    console.log('filter', filter)
    const variables = {
      fields: [
        'user_name',
        'call_disposition_name',
        'activity_status_name',
        'headshot_url',
        'call_disposition_is_success'
      ],
      aggregate: [
        { id: 'activity_count', type: 'count' },
        { id: 'call_length', type: 'sum' },
        { id: 'success_calls', type: 'sum' }
      ],
      filter,
      limit: 100
    }
    console.log('variables', variables)
    const res = await this.props.client.query({
      query: qlAgentsAnalytics,
      variables,
      fetchPolicy: 'no-cache'
    })
    if (res && res.data && res.data.agents_analytics_qm_paged) {
      const rawData = res.data.agents_analytics_qm_paged.data.map(r => ({
        ...r,
        call_disposition_name: r.call_disposition_name
          ? r.call_disposition_name
          : 'empty'
      }))
      const agents = this._parseAgents(rawData)
      this.setState({ rawData, agents })
    }
  }
  handleUsersPoolChange = val => {
    console.log('handleUsersPoolChange', val)
    this.setState({ selectedUsersPoolUid: val })
  }

  handleClickAgent = val => {
    console.log('handleClickAgent', val)
  }

  handleDateRangeFilterChange = value => {
    console.log('handleDateRangeFilterChange')
    console.dir(value)
    this.setState({
      activityDate:
        value && value.startDate ? [value.startDate, value.endDate] : undefined
    })
  }
  render() {
    console.log('render dash telesales agents', this.props)
    return (
      <div>
        <div className="row">
          <div className="col-md-2">
            <CrmButton
              label="Refresh Agents PAnel"
              onClick={this.handleRefresh}
            />
          </div>
        </div>
        {/* <div className="col-md-2">
            <CrmResourcePoolSelect
              input={{
                value: this.state.usersPoolUid,
                onChange: this.handleUsersPoolChange
              }}
            />
          </div>
        </div> */}

        <div className="row">
          {this.state.agents &&
            this.state.agents.map(agent => (
              <div key={agent.user_name} className="col-md-3 util-paddingMd">
                <DashTelesalesAgentItem
                  agent={agent}
                  onClick={this.handleClickAgent}
                />
              </div>
            ))}
        </div>

        {this.state.rawData && (
          <FlatTable columns={columns} data={this.state.rawData} />
        )}
      </div>
    )
  }
}

DashTelesalesAgents.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

DashTelesalesAgents.defaultProps = {
  dashboards: []
}

export default withApollo(DashTelesalesAgents)
