import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import {
  CrmFormGroup,
  CrmFormSection,
  CrmTextInput,
  CrmButton
} from 'crm-components'
import validate from 'crm-utils/validates'

class ReportForm extends PureComponent {
  handleEdit = () => {
    this.props.onEdit()
  }

  handleCancelEdit = () => {
    this.props.onReset()
    this.props.onCancel()
  }

  getButton = () => {
    if (this.props.editing) {
      return (
        <div>
          <CrmButton label="Save" type="submit" />
          <CrmButton
            label="Cancel"
            type="secondary"
            onClick={this.handleCancelEdit}
          />
        </div>
      )
    }

    if (this.props.creating) {
      return (
        <div>
          <CrmButton label="Save" type="submit" />
          <CrmButton
            label="Cancel"
            type="secondary"
            onClick={this.handleCancelEdit}
          />
        </div>
      )
    }
    return <CrmButton onClick={this.handleEdit} label="Edit" />
  }

  render() {
    const { handleSubmit } = this.props
    const isEditing = this.props.editing || this.props.creating
    return (
      <div>
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <div className="row">{this.getButton()}</div>
          <CrmFormGroup groupName="General">
            <CrmFormSection sectionName="Report information">
              <fieldset
                disabled={!isEditing}
                className="Form-fieldset CrmForm-form-fieldset"
              >
                <div className="row">
                  <div className="col-xs-12 col-md-12 col-lg-8">
                    <Field
                      name="name"
                      component={CrmTextInput}
                      type="text"
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-12 col-lg-8">
                    <p>
                      <label>Query:</label>
                    </p>
                    <Field
                      name="query"
                      component="textarea"
                      style={{ width: 450, height: 300 }}
                      validate={[validate.required]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-12 col-lg-8">
                    <p>
                      <label>Definitions:</label>
                    </p>
                    <Field
                      name="definitions"
                      component="textarea"
                      style={{ width: 450, height: 300 }}
                      validate={[validate.required, validate.jsonValue]}
                    />
                  </div>
                </div>
              </fieldset>
            </CrmFormSection>
          </CrmFormGroup>
        </form>
      </div>
    )
  }
}

ReportForm.propTypes = {
  creating: bool.isRequired,
  editing: bool.isRequired,
  onCancel: func.isRequired,
  onEdit: func.isRequired,
  handleSubmit: func.isRequired,
  onReset: func.isRequired
}

const ReportReduxForm = reduxForm({ form: 'crmSettingReport' })(ReportForm)
export default ReportReduxForm
