import React from 'react'
import { connect } from 'react-redux'
import BulkOperationsPopup from './bulk-operations-popup.jsx'
import { getIsBulkOperationsOpen, openBulkOperations } from 'crm-duxs/bulk-operations-reducer'
import { BulkOperationsContextProvider } from '@cartrack-crm/crm-core'

function mapStateToProps(state) {
  return {
    isBulkOperationsOpen: getIsBulkOperationsOpen(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doOpenBulkOperations: (accounts, params) => dispatch(openBulkOperations(accounts, params))
  }
}

export const BulkOperationsProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(props => {
  const handleShowBulkOperations = (accounts, params) => {
    props.doOpenBulkOperations(accounts, params)
  }

  return (
    <BulkOperationsContextProvider onShowBulkOperations={handleShowBulkOperations}>
      <React.Fragment>
        {props.children}
        <BulkOperationsPopup isOpen={props.isBulkOperationsOpen} />
      </React.Fragment>
    </BulkOperationsContextProvider>
  )
})
