/* eslint-disable camelcase */
import React from 'react'
import { graphql, compose } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import gql from 'graphql-tag'
import { shape, func, string, bool } from 'prop-types'
import { qlGetMartketPotential } from 'crm-data/account-market-potential'

const PotentialWidget = props => {
  const { isLoading, marketPotential } = props
  console.log('Render potential widget', props)
  const size = marketPotential
    ? Number(marketPotential.size) + Number(marketPotential.other_size)
    : 0
  return (
    <div onClick={props.onClick}>
      {props.account && (
        <div className="">
          <span className="LabeledField-label">
            <FormattedMessage
              id="crm.ui.account.process.market_potential"
              defaultMessage="Market Potential"
            />
          </span>
          <div className="LabeledField-value">
            {isLoading && (
              <span>
                <FormattedMessage
                  id="crm.ui.account.process.loading"
                  defaultMessage="loading.."
                />
              </span>
            )}
            {marketPotential && size > 0 ? (
              <div>
                {size}
                <span>
                  {' '}
                  <FormattedMessage
                    id="crm.ui.account.process.vehicles"
                    defaultMessage="Vehicles"
                  />
                </span>
              </div>
            ) : (
              <FormattedMessage
                id="crm.ui.account.process.click_to_add_market_potential"
                defaultMessage="Click to add"
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
PotentialWidget.propTypes = {
  account: shape({}).isRequired,
  accountUid: string.isRequired, // eslint-disable-line
  onClick: func.isRequired,
  isLoading: bool.isRequired,
  marketPotential: shape({})
}

PotentialWidget.defaultProps = {
  marketPotential: undefined
}

const PotentialWidgetQL = compose(
  graphql(gql(qlGetMartketPotential), {
    name: 'currentMarketPotential',
    options: ownProps => ({
      // eslint-disable-next-line
      variables: { account_uid: ownProps.accountUid }
    }),
    props: ({ currentMarketPotential, ownProps }) => ({
      ...ownProps,
      currentMarketPotential,
      marketPotential:
        currentMarketPotential && currentMarketPotential.account
          ? currentMarketPotential.account.market_potential
          : undefined,
      isLoading: currentMarketPotential ? currentMarketPotential.loading : false
    })
  })
)(PotentialWidget)

export default PotentialWidgetQL
