import React, { useState, useRef, useEffect } from 'react'
import { useClickOutside } from 'react-click-outside-hook'
import cloneDeep from 'clone-deep'
// import { FilterManager } from '../tools'
import {
  CrmButton,
  CrmJsonPreview,
} from '@cartrack-crm/ui'

export interface JsonEditorRenderCardProps {
  title?: string
  onSave: Function
  data: any | any[]
  textRow?: number
  onTest?: Function
  type?: 'all' | 'data_source' | 'layout' | 'filter'
  onOpenFilter?: () => any
  onCloseFilter?: () => any
  renderFilter?: (data, onSave) => any
}

interface IJsonPreviewCard extends JsonEditorRenderCardProps {
  row?: number
  index?: number
}

export const JsonEditorRenderCard: React.SFC<JsonEditorRenderCardProps> = (props) => {
  const { title, data, onSave, type, onTest, onOpenFilter, onCloseFilter, renderFilter } = props
  return (
    <div className='util-paddingMd' key={data?.dashboard_uid ?? data?.code}>
      <strong>{title}</strong>
      {
        Array.isArray(data) ?
          data.map((item, index) => <JsonPreviewCard data={item} onSave={onSave} type={type} index={index} onTest={onTest} />)
          :
          <JsonPreviewCard data={data} type={type} onSave={onSave} onOpenFilter={onOpenFilter} onCloseFilter={onCloseFilter} renderFilter={renderFilter} />
      }
    </div>
  )
}

const JsonPreviewCard: React.SFC<IJsonPreviewCard> = ({ data, onSave, row, onTest, index, type, onCloseFilter, onOpenFilter, renderFilter }) => {
  const [isEdit, setEdit] = useState(false)
  const [definition, setDefinition] = useState(data)
  const [ref, hasClickedOutside] = useClickOutside()
  useEffect(() => {
    setEdit(false)
  }, [hasClickedOutside])
  useEffect(() => setDefinition(data), [data])
  const onSaveDefinition = async () => {
    const pDefinition = cloneDeep(definition)
    await onSave(pDefinition, type, index ?? null)
    setEdit(false)
  }
  const onChangeDefinition = (newDefinition) => {
    setDefinition(newDefinition)
  }
  const setDefaultEdit = () => {
    if (type !== 'filter') {
      setEdit(true)
    }
  }
  const onTestResult = () => {
    if (onTest) {
      onTest(definition)
    }
  }
  if (type === 'filter') {
    console.log('filterData', data)
  }
  return (
    <div key={definition?.code ?? definition?.dashboard_uid} ref={type !== 'filter' ? ref : null} className='CrmMdCard util-marginMd util-paddingMd' style={type === 'filter' ? { height: '450px' } : { height: '235px' }} onDoubleClick={setDefaultEdit}>
      <CrmJsonPreview json={definition} editable={isEdit} rows={row ?? 10} onChange={onChangeDefinition} />
      {
        isEdit && onSave &&
        <CrmButton label='Save' small className='util-marginMd' type='primary' onClick={onSaveDefinition} />
      }
      {
        isEdit && onTest &&
        <CrmButton label='Test' small className='util-marginMd' onClick={onTestResult} />
      }
      {
        type === 'filter' &&
        <React.Fragment>
          <CrmButton label='add filter' small className='util-marginMd' onClick={onOpenFilter} />
          {renderFilter && renderFilter(data, onSave)}
        </React.Fragment>
      }
    </div>

  )
}
