/* eslint-disable camelcase */
import React, { Component } from 'react'
import { shape, func, arrayOf } from 'prop-types'
import { Link } from 'react-router-dom'
import {
  CrmButton,
  CrmTable,
  CrmDropdown,
  FormattedDateTime
} from 'crm-components'

class ImportHistory extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedImport: 0 }
  }

  handleBack = () => {
    this.props.onBack()
  }

  showImportedResult = () => {
    const currentLog = this.props.history.data[this.state.selectedImport]
    const accountNameMappingField = currentLog.config_mapping.filter(
      obj => obj.to === 'company_account'
    )

    const accountName = []
    if (accountNameMappingField.length > 0) {
      const accountNameIndex = accountNameMappingField[0].from
      for (let i = 1; i < this.props.tableData[0].rows.length; i++) {
        accountName.push(this.props.tableData[0].rows[i][accountNameIndex])
      }

      return currentLog.result.map((row, index) => ({
        no: row.row,
        name: accountName[index],
        status: row.status,
        account_uid: row.account_uid
      }))
    }

    return []
  }

  getImportResult = () => {
    const currentLog = this.props.history.data[this.state.selectedImport]
    const imported = Array.isArray(currentLog.result)
      ? currentLog.result.filter(row => row.status === 'IMPORTED').length
      : 0
    const duplicate = Array.isArray(currentLog.result)
      ? currentLog.result.filter(row => row.status === 'DUPLICATE').length
      : 0
    const createdTime = <FormattedDateTime value={currentLog.created_time} />
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <strong>Total rows : </strong> {imported + duplicate} {' ,'}
        <strong>Imported rows : </strong> {imported} {' ,'}
        <strong>Duplicate rows : </strong> {duplicate}
        {' ,'}
        <strong>Import time : </strong> {createdTime}{' '}
      </div>
    )
  }

  getImportOptions = () =>
    this.props.history.data.map((log, index) => ({
      name: 'Import #' + (index + 1),
      value: index
    }))

  handleOnChange = value => {
    this.setState({ selectedImport: value })
  }

  render() {
    const columns = [
      {
        Header: 'Row #',
        accessor: 'no'
      },
      {
        Header: 'Account',
        accessor: 'name'
      },

      {
        Header: 'Status',
        accessor: 'status'
      },

      {
        Header: 'Account uid',
        accessor: 'account_uid',
        Cell: row =>
          row.original.account_uid ? (
            <Link
              to={'/crm/account/' + row.original.account_uid}
              target="blank"
            >
              {row.original.account_uid}
            </Link>
          ) : (
            ''
          )
      }
    ]

    const resultData = this.showImportedResult()
    return (
      <div style={{ padding: 20 }}>
        <CrmButton label="Back" type="primary" onClick={this.handleBack} />{' '}
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-md-3">
            <CrmDropdown
              style={{ width: '300px', marginRight: '15px' }}
              placeholder="Select import number"
              options={this.getImportOptions()}
              input={{
                value:
                  'Import #' + (parseInt(this.state.selectedImport, 0) + 1),
                onChange: this.handleOnChange
              }}
            />
          </div>
          <div className="col-md-8">{this.getImportResult()}</div>
        </div>
        <div style={{ marginTop: 10 }}>
          <CrmTable columns={columns} data={resultData} className="table" />
        </div>
      </div>
    )
  }
}

ImportHistory.propTypes = {
  history: shape({}).isRequired,
  onBack: func.isRequired,
  tableData: arrayOf(shape({})).isRequired
}

export default ImportHistory
