import React, { SFC } from 'react'
import { isEmptyData } from 'crm-utils/common-utils'
interface IOpportunityValueString {
  filterValues: any
}

export const OpportunityValueString: SFC<IOpportunityValueString> = ({ filterValues }) => {
  if (isEmptyData(filterValues)) {
    return <strong>Select Values</strong>
  }
  return (
    <React.Fragment>
      {!filterValues.opportunity?.status && <strong>Select Values</strong>}

      {filterValues && filterValues.opportunity?.status && (
        <div>
          <strong>{filterValues && filterValues.opportunity?.status?.label}</strong>
        </div>
      )}

      {filterValues && filterValues.opportunity?.processing_status && (
        <div>
          <strong>{filterValues && filterValues.opportunity?.processing_status.label}</strong>
        </div>
      )}
    </React.Fragment>
  )
}
