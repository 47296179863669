// Actions for dictionaries
export const FETCH_IMPORT_FILES = 'FETCH_IMPORT_FILES'
export const RECEIVE_IMPORT_FILES = 'RECEIVE_IMPORT_FILES'
export const SELECTED_IMPORT_FILE = 'SELECTED_IMPORT_FILE'
export const UNSELECTED_IMPORT_FILE = 'UNSELECTED_IMPORT_FILE'
export const SAVE_EDITING_IMPORT_FILE = 'SAVE_EDITING_IMPORT_FILE'
export const SAVED_EDITING_IMPORT_FILE = 'SAVED_EDITING_IMPORT_FILE'
export const EDITING_IMPORT_FILE = 'EDITING_IMPORT_FILE'
export const CANCEL_EDITING_IMPORT_FILE = 'CANCEL_EDITING_IMPORT_FILE'
export const CREATING_IMPORT_FILE = 'CREATING_IMPORT_FILE'
export const CANCEL_CREATING_IMPORT_FILE = 'CANCEL_CREATING_IMPORT_FILE'
export const SAVING_IMPORT_FILE = 'SAVING_IMPORT_FILE'
export const SAVE_CREATING_IMPORT_FILE = 'SAVE_CREATING_IMPORT_FILE'
export const SAVING_IMPORT_FILE_ERROR = 'SAVING_IMPORT_FILE_ERROR'
export const DELETE_IMPORT_FILE_VALUE = 'DELETE_IMPORT_FILE_VALUE'

// Reducer
const initialState = {
  lists: [],
  fetch: false,
  editing: false,
  creating: false,
  selected: undefined,
  deleteValues: []
}

export default function importToolReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_IMPORT_FILES:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_IMPORT_FILES:
      return {
        ...state,
        fetch: false,
        lists: action.payload.importFiles
      }
    case SELECTED_IMPORT_FILE:
      return {
        ...state,
        selected: action.item,
        creating: false,
        editing: true
      }
    case UNSELECTED_IMPORT_FILE:
      return {
        ...state,
        selected: undefined,
        editing: false
      }
    case EDITING_IMPORT_FILE:
      return {
        ...state,
        editing: true,
        creating: false,
        deleteValues: []
      }
    case CANCEL_EDITING_IMPORT_FILE:
      return {
        ...state,
        editing: false,
        selected: undefined
      }
    case CREATING_IMPORT_FILE:
      return {
        ...state,
        creating: true,
        editing: false,
        selected: undefined,
        deleteValues: []
      }
    case CANCEL_CREATING_IMPORT_FILE:
      return {
        ...state,
        creating: false
      }
    case SAVING_IMPORT_FILE:
      return {
        ...state,
        creating: true
      }

    default:
      return state
  }
}

export function fetchData(tag) {
  return {
    type: FETCH_IMPORT_FILES,
    payload: tag
  }
}

export function selectedItem(item) {
  return {
    type: SELECTED_IMPORT_FILE,
    item
  }
}

export function deleteItem(resourceUid) {
  return {
    type: DELETE_IMPORT_FILE_VALUE,
    resourceUid
  }
}

export function unselectedItem() {
  return {
    type: UNSELECTED_IMPORT_FILE
  }
}

export function startEditingItem() {
  return {
    type: EDITING_IMPORT_FILE
  }
}

export function cancelEditingItem() {
  return {
    type: CANCEL_EDITING_IMPORT_FILE
  }
}

export function startCreatingItem() {
  return {
    type: CREATING_IMPORT_FILE
  }
}

export function cancelCreatingItem() {
  return {
    type: CANCEL_CREATING_IMPORT_FILE
  }
}

export function saveCreatingItem(formValues) {
  // console.log('saveCreatingItem')
  // console.log(formValues)
  return {
    type: SAVE_CREATING_IMPORT_FILE,
    formValues
  }
}

export function saveEditingItem(formValues) {
  return {
    type: SAVE_EDITING_IMPORT_FILE,
    formValues
  }
}
