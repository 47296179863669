import moment from 'moment'

export function toMSformat(date) {
  return parseInt(moment(date).format('x'), 10)
}

export function msToDateformat(ms, dateFormat = 'YYYY-MM-DD HH:MM ZZ') {
  if (ms === 0 || ms === '' || ms === undefined || ms === null) return undefined
  return moment(ms).format(dateFormat)
}

export function getLocaleBrowser() {
  let language

  if (window.navigator.languages) {
    language = window.navigator.languages[0]
  } else {
    language = window.navigator.userLanguage || window.navigator.language
  }

  return language
}

export function getLocaleDateString(locale = undefined) {
  let currentLocale = locale

  if (!currentLocale) {
    currentLocale = getLocaleBrowser()
  }

  const formats = {
    th: 'DD/MM/YYYY',
    en: 'DD/MM/YYYY'
  }

  return formats[currentLocale] ? formats[currentLocale] : 'DD/MM/YYYY'
}

export function getDateFormat(time) {
  return moment(new Date(time)).format('DD/MM/YYYY')
}

export function getAPIDateFormat(time) {
  return moment(new Date(time)).format('YYYY-MM-DD')
}

export function getDateByFormat(time, format) {
  return moment(new Date(time)).format(format)
}

export function getHourFormat(time) {
  if (!time) {
    return ''
  }

  const dateObj = new Date(time)
  let hh = dateObj.getHours()
  hh = ('0' + hh).slice(-2)
  let mm = dateObj.getMinutes()
  mm = ('0' + mm).slice(-2)
  let hourValue = hh + ':' + mm

  return hourValue
}

export function getDateTimeFormat(time) {
  return getDateFormat(time) + ' ' + getHourFormat(time)
}

export function getDateRangeOptions() {
  return [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'Last 7 Days', value: 'last7Days' },
    { name: 'This Month', value: 'thisMonth' },
    { name: 'Previous Month', value: 'previousMonth' },
    { name: 'Next Month', value: 'nextMonth' },
    { name: 'Last 3 months', value: 'last3months' },
    { name: 'Last 6 months', value: 'last6months' },
    { name: 'Last year', value: 'lastyear' }
  ]
}

export function getDateRangeOptionsWithCustom(customOptions) {
  const options = getDateRangeOptions()

  if (customOptions.includes('this_year')) {
    options.push({ name: 'This year', value: 'this_year' })
  }

  return options
}

export function getDateRangeByLabel(label, return_date_object) {
  const dateRange = {
    from: null,
    to: null
  }
  const today = moment()
  const yesterday = moment().subtract(1, 'days')
  const last7Days = moment().subtract(6, 'days')

  if (label === 'today') {
    dateRange.from = today
    dateRange.to = today
  } else if (label === 'yesterday') {
    dateRange.from = yesterday
    dateRange.to = yesterday
  } else if (label === 'last7Days') {
    dateRange.from = last7Days
    dateRange.to = today
  } else if (label === 'thisMonth') {
    dateRange.from = moment().startOf('month')
    dateRange.to = moment().endOf('month')
  } else if (label === 'previousMonth') {
    dateRange.from = moment()
      .subtract(1, 'months')
      .startOf('month')
    dateRange.to = moment()
      .subtract(1, 'months')
      .endOf('month')
  } else if (label === 'nextMonth') {
    dateRange.from = moment()
      .add(1, 'months')
      .startOf('month')
    dateRange.to = moment()
      .add(1, 'months')
      .endOf('month')
  } else if (label === 'this_year') {
    dateRange.from = moment().startOf('year')
    dateRange.to = moment().endOf('year')
  } else if (label === 'last3months') {
    dateRange.from = moment()
      .subtract(3, 'months')
      .startOf('month')
    dateRange.to = moment()
      .subtract(1, 'months')
      .endOf('month')
  } else if (label === 'last6months') {
    dateRange.from = moment()
      .subtract(6, 'months')
      .startOf('month')
    dateRange.to = moment()
      .subtract(1, 'months')
      .endOf('month')
  } else if (label === 'lastyear') {
    dateRange.from = moment()
      .subtract(11, 'months')
      .startOf('month')
    dateRange.to = moment().endOf('month')
  }

  if (return_date_object) {
    return {
      from: dateRange?.from ? dateRange.from.toDate() : null,
      to: dateRange?.to ? dateRange.to.toDate() : null
    }
  } else {
    return dateRange
  }
}
