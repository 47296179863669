/* eslint-disable camelcase */
import { qlqListOpportunities } from 'crm-data/opportunities'
import { mapContextToFilter } from './rms-dashboard-utils'

const parseOpportunities = data => {
  const users = {}
  data.forEach(row => {
    if (!users[row.owner_user_uid]) {
      users[row.owner_user_uid] = {
        user_uid: row.owner_user_uid,
        user_name: row.owner_user_name,
        opportunities: {
          overdueSize: 0,
          overdueCount: 0,
          currentSize: 0,
          currentCount: 0
        },
        mainKpis: []
      }
    }

    if (row.is_overdue) {
      users[row.owner_user_uid].opportunities.overdueSize += Number(
        row.opportunities_size
      )
      users[row.owner_user_uid].opportunities.overdueCount += Number(
        row.opportunity_uid
      )
    } else {
      users[row.owner_user_uid].opportunities.currentSize += Number(
        row.opportunities_size
      )
      users[row.owner_user_uid].opportunities.currentCount += Number(
        row.opportunity_uid
      )
    }
  })

  Object.values(users).forEach(user => {
    user.mainKpis.push({
      name: 'Opportunities Overdue',
      value: user.opportunities.overdueSize
    })
    user.mainKpis.push({
      name: 'Opportunities Current',
      value: user.opportunities.currentSize
    })
  })
  return users
}
export const mock = 1
export const loadDataCurrentOpportunities = async params => {
  console.log('loadDataOpportunities', params, mapContextToFilter)

  const filter = mapContextToFilter(params, true)
  console.log('Filter', filter)
  // if (this.props.analyticsContext) {
  //   if (this.props.analyticsContext.campaign_uid) {
  //     filter.campaign_uid = this.props.analyticsContext.campaign_uid
  //   }
  // }
  filter.completed_date = 'null'
  filter.is_deleted = false
  const variables = {
    filter,
    fields: ['owner_user_name', 'owner_user_uid', 'is_overdue'],
    aggregate: [
      { id: 'opportunity_uid', type: 'count' },
      { id: 'opportunities_size', type: 'sum' }
    ]
  }

  const res = await params.client.query({
    query: qlqListOpportunities,
    variables,
    fetchPolicy: 'no-cache'
  })
  console.log('Got Opportunities result', res)
  if (res && res.data && res.data.opportunities_list_qm_paged) {
    return parseOpportunities(res.data.opportunities_list_qm_paged.data)
  }
  return undefined
}
