export default [
  require('../../assets/vehicles/large-truck.svg'),
  require('../../assets/vehicles/motorbike.svg'),
  require('../../assets/vehicles/small-car.svg'),
  require('../../assets/vehicles/sedan-car.svg'),
  require('../../assets/vehicles/4x4.svg'),
  require('../../assets/vehicles/van.svg'),
  require('../../assets/vehicles/small-truck.svg'),
  require('../../assets/vehicles/large-truck.svg'),
  require('../../assets/vehicles/small-machine.svg'),
  require('../../assets/vehicles/large-machine.svg'),
  require('../../assets/vehicles/bus.svg')
]

export const vehicleTypes = [
  'Large Truck',
  'Motorbike',
  'Small Car',
  'Sedan',
  '4x4',
  'Van',
  'Small Truck',
  'Large Truck',
  'Small Machine',
  'Large Machine',
  'Bus'
]
