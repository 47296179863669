/* eslint-disable camelcase */

export const SELECTED_SCRIPT = 'SELECTED_SCRIPT'
export const SELECTED_PACKAGE = 'SELECTED_PACKAGE'

// Reducer
const initialState = {
  packageUid: undefined,
  packageItems: undefined,
  scriptSelected: undefined
}

export default function salesScriptReducer(state = initialState, action) {
  switch (action.type) {
    case SELECTED_SCRIPT: {
      return {
        ...state,
        scriptSelected: action.payload.scriptSelected
      }
    }
    case SELECTED_PACKAGE: {
      return {
        ...state,
        packageUid: action.payload.packageUid,
        packageItems: action.payload.packageItems
      }
    }
    default:
      return state
  }
}

export function setSelectedScript(scriptSelected) {
  return {
    type: SELECTED_SCRIPT,
    payload: {
      scriptSelected
    }
  }
}

export function setSelectedPackage(packageUid, packageItems) {
  return {
    type: SELECTED_PACKAGE,
    payload: {
      packageUid,
      packageItems
    }
  }
}

export const getSelectedPackageItems = state =>
  state.crm.saleScript.packageItems
export const getSelectedPackageUid = state => state.crm.saleScript.packageUid
export const getSelectedScript = state => state.crm.saleScript.scriptSelected
