const rmMeetingActivity = {
  type: 'item',
  label: 'RM Meeting Activity',
  code: 'rmMeetingActivity',
  dashboard_uid: 'rmMeetingActivity',
  defaultFilters: {},
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'rmMeetingActivity',
        def_version: 5,
        title: 'RM Meeting Activity',
        enable_excel_export: true,
        description_: "Default filter: User Departments = 'Corporate RMs'",
        content: {
          data: {
            dataSources: [
              {
                code: 'ActivitiesListQM',
                name: 'MeetingsCreatedFirst',
                type: 'QM',
                filter: {
                  activity: {
                    type: {
                      medium_code: 'meeting'
                    },
                    first_type: 'first'
                  },
                  role_code: 'performer',
                  get_from_user_created: true,
                  account_is_deleted: false
                },
                contextMapping: {
                  activity: {
                    start_date: 'activity_date'
                  },
                  user: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  }
                },
                qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                  activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                      edges { edge { activity_uid created_by_user_uid } }
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [{ id: 'activity_uid', type: 'count' }],
                  groupBy: ['created_by_user_uid']
                },
                qlEdges: true,
                qlOnly: true,
                qlRoot: 'activity_participants'
              },
              {
                code: 'ActivitiesListQM',
                name: 'MeetingsCreatedFollowup',
                type: 'QM',
                filter: {
                  activity: {
                    first_type: 'follow_up',
                    type: {
                      medium_code: 'meeting'
                    }
                  },
                  role_code: 'performer',
                  get_from_user_created: true,
                  account_is_deleted: false
                },
                contextMapping: {
                  activity: {
                    start_date: 'activity_date'
                  },
                  performer_user: 'user',
                  user: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  }
                },
                qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                  edges { edge { activity_uid created_by_user_uid } }
                    pageInfo { count }
                  }
                }`,
                qlVariables: {
                  aggregate: [{ id: 'activity_uid', type: 'count' }],
                  groupBy: ['created_by_user_uid']
                },
                qlEdges: true,
                qlOnly: true,
                qlRoot: 'activity_participants'
              },
              {
                code: 'ActivitiesListQM',
                name: 'MeetingsAll',
                type: 'QM',
                filter: {
                  activity: {
                    activity_type: {
                      medium_code: 'meeting'
                    }
                  },
                  role_code: 'performer',
                  get_from_user_created: true,
                  account_is_deleted: false
                },
                contextMapping: {
                  user: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  activity: {
                    start_date: 'activity_date'
                  }
                },
                qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                  activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                      edges { edge { activity_uid created_by_user_uid } }
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [{ id: 'activity_uid', type: 'count' }],
                  groupBy: ['created_by_user_uid']
                },
                qlEdges: true,
                qlOnly: true,
                qlRoot: 'activity_participants'
              },
              {
                code: 'ActivitiesListQM',
                alias: 'MeetingsCompleted',
                type: 'QM',
                filter: {
                  activity: {
                    activity_type: {
                      medium_code: 'meeting'
                    },
                    status: {
                      is_success: true
                    }
                  },
                  role_code: 'performer',
                  get_from_user_created: true,
                  account_is_deleted: false
                },
                contextMapping: {
                  user: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  activity: {
                    start_date: 'activity_date'
                  }
                },
                qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                    edges { edge { activity_uid created_by_user_uid } }
                    pageInfo { count }
                  }
                }`,
                qlVariables: {
                  aggregate: [{ id: 'activity_uid', type: 'count' }],
                  groupBy: ['created_by_user_uid']
                },
                qlEdges: true,
                qlOnly: true,
                qlRoot: 'activity_participants'
              },
              {
                code: 'ActivitiesListQM',
                alias: 'MeetingsCanceled',
                type: 'QM',
                filter: {
                  activity: {
                    type: { medium_code: 'meeting' },
                    status: { kind: 'canceled' }
                  },
                  role_code: 'performer',
                  get_from_user_created: true,
                  account_is_deleted: false
                },
                fields: ['performer_user_uid'],
                aggregate: [{ id: 'activity_uid', type: 'count' }],
                contextMapping: {
                  user: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  activity: {
                    start_date: 'activity_date'
                  }
                },
                qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                  activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                      edges { edge { activity_uid created_by_user_uid } }
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  groupBy: ['created_by_user_uid']
                },
                qlEdges: true,
                qlOnly: true,
                qlRoot: 'activity_participants'
              },
              {
                code: 'ActivitiesListQM',
                alias: 'MeetingsOverdue',
                type: 'QM',
                filter: {
                  activity: {
                    type: { medium_code: 'meeting' },
                    status: { kind: 'sheduled' },
                    start_date: { $lte: 'now()' }
                  },
                  role_code: 'performer',
                  get_from_user_created: true,
                  account_is_deleted: false
                },
                contextMapping: {
                  user: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  }
                },
                qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                  activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                      edges { edge { activity_uid created_by_user_uid } }
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [{ id: 'activity_uid', type: 'count' }],
                  groupBy: ['created_by_user_uid']
                },
                qlEdges: true,
                qlOnly: true,
                qlRoot: 'activity_participants'
              },
              {
                code: 'ActivitiesListQM',
                alias: 'MeetingsPending',
                type: 'QM',
                filter: {
                  activity: {
                    activity_type: {
                      medium_code: 'meeting'
                    },
                    status: {
                      kind: 'sheduled'
                    },
                    start_date: { $gte: 'now()' }
                  },
                  role_code: 'performer',
                  get_from_user_created: true,
                  account_is_deleted: false
                },
                fields: ['performer_user_uid'],
                contextMapping: {
                  user: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  }
                },
                qlQuery: `query activity_participants($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) {
                  activity_participants(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) {
                      edges { edge { activity_uid created_by_user_uid } }
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [{ id: 'activity_uid', type: 'count' }],
                  groupBy: ['created_by_user_uid']
                },
                qlEdges: true,
                qlOnly: true,
                qlRoot: 'activity_participants'
              },
              {
                code: 'CrmUser',
                name: 'CrmUser',
                type: 'QM',
                filter: {},
                qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON) {
                  master(master_uid: $master_uid)  { 
                      master_uid
                      name
                      instance(instance_uid: $instance_uid) { 
                          instance_uid
                          name
                          users (filter: $filter) { 
                            user_uid
                            is_active
                            full_name
                            contract_start_date
                            contract_end_date
                            department { 
                              name 
                            }
                          }
                      }
                  }
                }`,
                qlRoot: 'users'
              }
            ],
            combineDataSources: {
              name: 'CombinedDataSource',
              targetKeyField: 'user_uid',
              mapping: [
                {
                  fromDataSourceName: 'MeetingsCreatedFirst',
                  keyField: 'created_by_user_uid',
                  mapping: [
                    {
                      from: 'activity_uid',
                      to: 'meetings_created_first'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'MeetingsCreatedFollowup',
                  keyField: 'created_by_user_uid',
                  mapping: [
                    {
                      from: 'activity_uid',
                      to: 'meetings_created_followup'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'MeetingsAll',
                  keyField: 'created_by_user_uid',
                  mapping: [
                    {
                      from: 'activity_uid',
                      to: 'meetings_all'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'MeetingsCompleted',
                  keyField: 'created_by_user_uid',
                  mapping: [
                    {
                      from: 'activity_uid',
                      to: 'meetings_completed'
                    }
                  ]
                },

                {
                  fromDataSourceName: 'MeetingsCanceled',
                  keyField: 'created_by_user_uid',
                  mapping: [
                    {
                      from: 'activity_uid',
                      to: 'meetings_canceled'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'MeetingsOverdue',
                  keyField: 'created_by_user_uid',
                  mapping: [
                    {
                      from: 'activity_uid',
                      to: 'meetings_overdue'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'MeetingsPending',
                  keyField: 'created_by_user_uid',
                  mapping: [
                    {
                      from: 'activity_uid',
                      to: 'meetings_pending'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'CrmUser',
                  keyField: 'user_uid',
                  type: 'left',
                  mapping: [
                    {
                      from: 'full_name'
                    },
                    {
                      from: 'contract_start_date'
                    },
                    { from: 'contract_end_date' },
                    { from: 'department__name' },
                    { from: 'is_active' }
                  ]
                }
              ]
            }
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                initialSort: {
                  id: 'contract_start_date',
                  order: 'asc'
                },
                mob_fix_columns: 2,
                props: {
                  generate_summary: true,
                  generate_average: true,
                  columnGroups: [
                    {
                      Header: '',
                      colSpan: 3
                    },
                    {
                      Header: 'Statistics for period',
                      colSpan: 5
                    },
                    {
                      Header: 'Current meeting status',
                      colSpan: 2
                    }
                  ],
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 30,
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'RM',
                      width: 150,
                      accessor: 'full_name',
                      classNameHandler: row => {
                        return row.original.is_active ? '' : 'util-opacity50'
                      },
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user_uid: 'user_uid'
                      }
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'contract_start_date',
                      width: 80,
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'First Meetings Created ',
                      accessor: 'meetings_created_first',
                      width: 100,
                      type: 'number',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: {
                          medium_code: 'meeting'
                        },
                        first_type: 'first'
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      contextMapping: {
                        performer: 'user'
                      },
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'Followup Meetings Created ',
                      accessor: 'meetings_created_followup',
                      width: 100,
                      type: 'number',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: { medium_code: 'meeting' },
                        first_type: 'follow_up'
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      contextMapping: {
                        performer_user: 'user'
                      },
                      className: ' util-pointer',
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'Total Meetings Created',
                      accessor: 'meetings_all',
                      width: 100,
                      type: 'number',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: { medium_code: 'meeting' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      contextMapping: {
                        performer: 'user'
                      },
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: ' Meetings Completed',
                      accessor: 'meetings_completed',
                      width: 100,
                      type: 'number',
                      className: 'util-pointer',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: { medium_code: 'meeting' },
                        status: { is_success: 'true' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid', label: 'user_name' }
                      },
                      contextMapping: {
                        performer: 'user'
                      },
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'Meetings Canceled',
                      accessor: 'meetings_canceled',
                      width: 100,
                      type: 'number',
                      className: 'util-pointer',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: { medium_code: 'meeting' },
                        status: { kind: 'canceled' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      contextMapping: {
                        performer: 'user'
                      },
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'Overdue Meetings',
                      accessor: 'meetings_overdue',
                      width: 100,
                      type: 'number',
                      className: 'util-pointer',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        status: { kind: 'sheduled' },
                        start_date: { $lte: 'now()' },
                        account_is_deleted: false
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      contextMapping: {
                        performer: 'user'
                      },
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'Pending Meetings',
                      accessor: 'meetings_pending',
                      width: 100,
                      type: 'number',
                      className: 'util-pointer',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        status: { kind: 'sheduled' },
                        start_date: { $gte: 'now()' }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      contextMapping: {
                        performer: 'user'
                      },
                      align: 'right',
                      headerAlign: 'center'
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'CombinedDataSource'
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}

export default rmMeetingActivity
