/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved, camelcase */
import { toastr } from 'react-redux-toastr'
import { delay } from 'redux-saga'
import activitiesApi from 'crm-api/activities-api'
import { getUser, toggleSideWindow } from 'crm-duxs/crm-reducer'
import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  setStatus,
  actions,
  getActivity,
  STATUS_INIT,
  STATUS_PENDING
} from 'crm-duxs/activities/live-call-reducer'
import { accountFieldsUpdated } from 'crm-duxs/account-reducer'
import { FETCH_ACTIVITY_TYPES_SUCCESS } from 'crm-duxs/activities-reducer'
import { buildNewActivity } from 'crm-utils/activities-utils'

function* initNewLiveCall(action) {
  console.log('initNewLiveCall SAGA ', action)
  const activityType = undefined
  if (!activityType) {
    return toastr.error(
      "Can't register call - can't find defalt type for Live Call"
    )
  }

  const currentUser = yield select(getUser)
  yield put(setStatus(STATUS_INIT))
  yield put(toggleSideWindow(true))

  // Activate window
  const activity = buildNewActivity(
    action.payload.activity.account_uid,
    activityType,
    currentUser
  )
  // Aactivity.contact_details_uid  = action.payload.activity
  yield put({ type: actions.createLiveActivity.type, payload: { activity } })
}

function _prepareActivity(activity) {
  const ret = { ...activity }

  if (ret.type && !ret.activity_type_uid) {
    ret.activity_type_uid = ret.type.activity_type_uid
    delete ret.type
  }
  // Map primary_performer_user_uid to participant
  ret.participants = []
  if (ret.primary_performer_user_uid) {
    ret.participants.push({
      user_uid: ret.primary_performer_user_uid,
      role_code: 'performer',
      is_primary: true
    })
  } else {
    throw new Error('Activity is mising primary performer')
  }
  return ret
}

function* createLiveActivity(action) {
  // Convert activity to server structre
  console.log('LiveCall.createLiveActivity SAGA')
  let activity = action.payload.activity
  activity = _prepareActivity(activity)

  try {
    const createdActivity = yield call(
      activitiesApi.createLiveActivity,
      activity
    )
    console.log('created activity')
    yield put({
      type: actions.createLiveActivitySuccess.type,
      payload: { activity: createdActivity }
    })
    yield put(setStatus(STATUS_PENDING))
    console.log('createActivity Saga - pass to handlers')
    console.dir(action.payload.params)
    if (action.payload.params && action.payload.params.onSuccess) {
      action.payload.params.onSuccess({ activity: createdActivity })
    }
    // Notify accounts that field has been updates
    // yield put(accountFieldsUpdated(activity.account_uid, ['activities']))
    // yield put(toastr.success('Activity has been saved'))
  } catch (err) {
    let msg = 'Problem creating live activity'
    if (err && typeof err === 'string') {
      msg += ': ' + err
    }
    if (err && typeof err === 'object' && err.message) {
      msg += ': ' + err.message
    }
    console.log('typeof', typeof err)
    toastr.error(msg)
    console.log('Problem creating live activity', err)
  }
}
function* receivedActivityTypesList(action) {
  console.log('receivedActivityTypesList')
  const liveActivityType = action.payload.activityTypes.find(
    act => act.medium_code === 'phone_call' && act.form_code === 'live_call'
  )
  if (liveActivityType) {
    yield put({
      type: actions.setLiveCallActivityType.type,
      payload: {
        liveCallActivityType: liveActivityType
      }
    })
  }
  // Request statuses for this type
}

function* activityDetailsChanged(action) {
  console.log('activityDetailsChanged SAGA')

  // SAVE to server
  yield put({ type: actions.setIsChanged.type, payload: { isChanged: true } })
  const newActivityData = action.payload.activityChange
  const savedActivity = yield call(saveActivityLive, newActivityData)

  // Compare again
  yield put({ type: actions.setIsChanged.type, payload: { isChanged: false } })
  // Set current activity
  const activity = yield select(getActivity)
  yield put({
    type: actions.createLiveActivitySuccess.type,
    payload: { activity: { ...activity, ...savedActivity } }
  })
}
function* saveActivityLive(activity) {
  yield call(delay, 500)
  yield put({ type: actions.setIsSaving.type, payload: { isSaving: true } })
  const savedActivity = yield call(
    activitiesApi.updateLiveActivity,
    activity.activity_uid,
    activity
  )
  yield put({ type: actions.setIsSaving.type, payload: { isSaving: false } })
  return savedActivity
}

function* completeLiveCall(action) {
  console.log('completeLiveCall SAGA')
  const activity = action.payload.activityChange
  const savedActivity = yield call(
    activitiesApi.completeLiveActivity,
    activity.activity_uid,
    activity
  )
  console.dir(savedActivity)
  yield put({ type: actions.completeLiveCallSuccess.type, payload: {} })
  yield put(toggleSideWindow(false))
  yield put(accountFieldsUpdated(activity.account_uid, ['activities']))
  if (action.payload.onSuccess) {
    action.payload.onSuccess(savedActivity)
  }
  // Refresh activities
}

export default function* liveCallSaga() {
  yield takeLatest(actions.initNewLiveCall.type, initNewLiveCall)
  yield takeLatest(actions.createLiveActivity.type, createLiveActivity)
  // Observe activity types list change to find Live call type
  yield takeLatest(FETCH_ACTIVITY_TYPES_SUCCESS, receivedActivityTypesList)
  // Live change
  yield takeLatest(actions.activityDetailsChanged.type, activityDetailsChanged)
  // Saved
  yield takeLatest(actions.completeLiveCall.type, completeLiveCall)
}
