import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const asset = {
  arrow_green: require('./asset/arrow-right-green.svg'),
  arrow_orange: require('./asset/arrow-right-orange.svg'),
  celebrate: require('./asset/icn-celerate.svg'),
  task: require('./asset/icn-tasks.svg'),
  welcome: require('./asset/welcome.png')
}

export const colors = {
  green: '#4CE2A7',
  orange: '#F47735',
  light_green: '#EEF5F2',
  light_orange: '#FDF1EA'
}

const getColor = (color: string) => {
  return colors[color]
}

const ArrowIcon = styled.img`
  color: ${({ color }) => getColor(color)};
  font-size: 24px;
  font-weight: 100;
  height: 25px;
  letter-spacing: 0;
  line-height: 25px;
  margin: auto;
  margin-right: 24px;
  width: 21px;
`
const FlexItem = styled.div`
  ${props => props.textCenter && ` text-align: center;`}
`
const Icon = styled.img`
  background-color: ${({ color }) => getColor(color)};
  border-radius: 4px;
  padding: 7px;
  margin-right: 18px;
`
const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 57px 20px;
`
const Label = styled.label`
  color: #2b2b2b;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  margin: auto 0;
  cursor: pointer;
`
const Logo = styled.img`
  margin-top: 16px;
  margin-bottom: 70px;
`
const Panel = styled(Link)`
  background-color: ${({ color }) => getColor(color)};
  border-radius: 8px;
  border: 1px solid ${({ borderColor }) => getColor(borderColor)};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 60px;
  margin: 8px;
  padding: 10px;
  &:hover {
    opacity: 0.5;
  }
`
const Row = styled.div`
  width: auto;
`
const Title = styled.span`
  color: #2b2b2b;
  font-size: 32px;
  ${props => props.name && `font-weight: 200;`}
`

type MainDashboardProps = {
  currentUser?: string,
  linkDashboard?: string,
  linkTask?: string
}

export const MainDashboard = ({ currentUser, linkDashboard, linkTask }: MainDashboardProps) => (
  <InnerContainer>
    <Row>
      <FlexItem textCenter>
        <Title>Welcome back, </Title>
        <Title name>{currentUser ?? 'Empty User'}</Title>
      </FlexItem>
      <FlexItem>
        <Logo src={asset.welcome} alt="welcome back" />
      </FlexItem>
      <FlexItem>
        <Panel color="light_green" borderColor="green" to={linkDashboard}>
          <Icon src={asset.celebrate} alt="task" width="25" height="25" color="green" />
          <Label>You are doing great so far. Let’s review your achievement!</Label>
          <ArrowIcon src={asset.arrow_green} color="green" />
        </Panel>
      </FlexItem>
      <FlexItem>
        <Panel color="light_orange" borderColor="orange" to={linkTask}>
          <Icon src={asset.task} alt="celebrate" width="25" height="25" color="orange" />
          <Label>Check what we’ve got for you today!</Label>
          <ArrowIcon src={asset.arrow_orange} color="orange" />
        </Panel>
      </FlexItem>
    </Row>
  </InnerContainer>
)
