import React from 'react'
import { CrmTabNavigator } from '../../../../../components/index'
import AddAccountPage from './add-account-page'
import { initNewAccount } from '../../../../../utils/accounts-utils'

const TabB2B = props => {
  return (
    <AddAccountPage
      account={initNewAccount()}
      onCancelAddingAccount={props.onCancelAddingAccount}
      pageSize={props.pageSize}
      selectedCategory="B2B"
    />
  )
}

const TabB2C = props => {
  return (
    <AddAccountPage
      account={initNewAccount()}
      onCancelAddingAccount={props.onCancelAddingAccount}
      pageSize={props.pageSize}
      selectedCategory="B2C"
    />
  )
}

const AddAccountPanel = props => {
  const tabs = [
    {
      label: 'Company B2B',
      childComponent: <TabB2B {...props} />
    },
    {
      label: 'Individual B2C',
      childComponent: <TabB2C {...props} />
    }
  ]
  return (
    <div className="util-fullHeight util-flexColumn">
      <CrmTabNavigator
        tabs={tabs}
        selectedIndex={0}
        pageSize={props.pageSize}
        centered={true}
        contentWrapperNoPadding={true}
      />
    </div>
  )
}

export default AddAccountPanel
