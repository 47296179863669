/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'
import { qlqfetchAccount } from 'crm-data/accounts'
import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

export function buildCommand(account, params) {
  const command = {
    type: 'account.updateTransactionType',
    aggregate_uid: account.account_uid,
    aggregate_type: 'account',
    payload: {
      transaction_type_code: params.transaction_type_code
    }
  }
  return command
}
// eslint-disable-next-line
const changeAccountTransactionTypeHoc = WrappedComponent => {
  class ChangeAccountTransactionType extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        isEdited: props.account && !props.account.category
      }
    }
    static propTypes = {
      account: shape({}).isRequired,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      isEditing: false,
      gotoPage: undefined,
      newAccountUid: undefined
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })

    handleSaveNewTransactionType = async (transactionTypeCode, pAccount) => {
      // console.log('handleSaveNewTransactionType', pAccount)
      const account = pAccount || this.props.account
      const command = buildCommand(account, {
        transaction_type_code: transactionTypeCode
      })

      // console.log('command', command)
      try {
        const res = await this.props.onRunCommand(command, {
          refetchQueries: pAccount
            ? []
            : [
                {
                  query: qlqfetchAccount,
                  variables: {
                    account_uid: account.account_uid
                  }
                }
              ]
        })
        // console.log('Change Account Transaction Type Result', res)
        return res.data.cqCommand
      } catch (err) {
        console.log('Change Account Transaction Type Result', err)
        throw new Error('Change Account Transaction Type Result')
      }
    }
    handleCancel = () => {
      this.setState({ isEdited: false })
    }
    handleStartEdit = () => {
      this.setState({ isEdited: true })
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          onSaveNewTransactionType={this.handleSaveNewTransactionType}
          isSavingNewTransactionType={this.props.isSaving}
          onBulkOperation={this.handleSaveOwnerChange}
          isEdited={this.state.isEdited}
          onCancel={this.handleCancel}
          onStartEdit={this.handleStartEdit}
        />
      )
    }
  }
  return crmGenericCommandHoc(ChangeAccountTransactionType)
}

export default changeAccountTransactionTypeHoc
