import React from 'react'
import { withApollo } from 'react-apollo'
import { bool, shape } from 'prop-types'
import moment from 'moment'
import { qlAnalyticsWidgetQuery } from 'crm-data/generic-data'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { qlqGetUser } from 'crm-data/users'

export const buildTable1Columns = quarterDates => {
  return [
    {
      Header: 'Totals',
      accessor: 'label'
    },
    ...quarterDates.map((qd, index) => ({
      Header: 'Q' + (index + 1) + ' ' + qd,
      accessor: qd
    }))
  ]
}
export const propTypes = {
  isUserRequiredError: bool.isRequired
}

const getWidgetDefinition = (userUid, startDate, endDate, filter) => ({
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: {
            opp_owner_user_uid: userUid,
            created_time: {
              $gte: startDate,
              $lte: endDate
            }
          },
          fields: ['created_date_quarter', 'opp_owner_user_uid'],
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {}
        },
        {
          code: 'ActivitiesListQM',
          name: 'ActivitiesListQM',
          type: 'QM',
          filter: {
            performer_user_uid: userUid,
            start_date_quarter: {
              $gte: startDate,
              $lte: endDate
            }
          },
          fields: ['start_date_quarter'],
          aggregate: [{ id: 'activity_uid', type: 'count' }],
          contextMapping: {}
        },
        {
          code: 'OrdersListQM',
          name: 'OrdersListQM',
          type: 'QM',
          filter: {
            owner_user_uid: userUid,
            completed_date_quarter: {
              $gte: startDate,
              $lte: endDate
            }
          },
          fields: ['completed_date_quarter'],
          aggregate: [{ id: 'vehicles_count', type: 'sum' }],
          contextMapping: {}
        },
        {
          code: 'OrdersListQM',
          name: 'OrdersListQM',
          alias: 'FitmentsInPeriod',
          type: 'QM',
          filter: {
            owner_user_uid: userUid,
            completed_date: filter.activity_date,
            status_code: 'completed'
          },
          fields: ['account__owner_user_uid'],
          aggregate: [{ id: 'vehicles_count', type: 'sum' }],
          contextMapping: {}
        },
        {
          code: 'OrdersListQM',
          name: 'OrdersListQM',
          alias: 'PendingInPeriod',
          type: 'QM',
          filter: {
            status_code: 'pending',
            owner_user_uid: userUid
          },
          fields: ['account__owner_user_uid'],
          aggregate: [{ id: 'vehicles_count', type: 'sum' }],
          contextMapping: {}
        },
        {
          code: 'OrdersListQM',
          name: 'OrdersListQM',
          alias: 'FitmentsHistory',
          type: 'QM',
          filter: {
            account__owner_user_uid: userUid
          },
          fields: ['owner_user_uid', 'fitment_month'],
          aggregate: [{ id: 'vehicles_count', type: 'sum' }],
          transposeColumns: ['completed_month'],
          transposeKey: 'owner__full_name',
          transposeValueField: 'vehicles_count',
          transposeNestedField: 'fitments_history',
          transposeFn: 'date'
        },
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          alias: 'OpportunitiesResults',
          type: 'QM',
          filter: {
            opp_owner_user_uid: userUid,
            process__completed_date_quarter: {
              $gte: startDate,
              $lte: endDate
            }
          },
          fields: ['process__completed_date_quarter', 'process__step__type__name'],
          aggregate: [
            { id: 'vehicle_count', type: 'sum' },
            { id: 'opportunity_uid', type: 'count' }
          ],
          contextMapping: {}
        },
        {
          code: 'AccountsResults',
          name: 'AccountsResults',
          type: 'QM',
          filter: {
            owner_user_uid: userUid
          },
          fields: ['owner_user_uid', 'sales_step_code'],
          aggregate: [{ id: 'account_uid', type: 'count' }],
          transposeColumns: ['sales_step_code'],
          transposeKey: 'owner_user_uid',
          transposeValueField: 'account_uid'
        },
        {
          code: 'CrmUser',
          name: 'CrmUser',
          type: 'QM',
          filter: {
            user_uid: userUid
          },
          fields: [
            'department_uid',
            'full_name',
            'user_uid',
            'contract_start_date',
            'contract_end_date',
            'department__name',
            'is_active',
            'photo_url'
          ]
        }
      ]
    }
  }
})

const agentDetailsB2BDashboardHoc = WrappedComponent => {
  class AgentDetailsB2BDashboardHoc extends React.PureComponent {
    static propTypes = {
      analyticsContext: shape().isRequired,
      client: shape().isRequired
    }
    constructor(props) {
      super(props)
      this.state = {}
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (
        JSON.stringify(nextProps.analyticsContext?.filters) !== JSON.stringify(this.props.analyticsContext?.filters)
      ) {
        this.setState({}, () => this.handleRefresh())
      }
    }

    buildMonths = () => {
      const date = moment()
      date.add(-1, 'months')
      const months = []
      for (let i = 0; i < 12; i++) {
        months.push(date.format('YYYY-MM'))
        date.add(-1, 'months')
      }
      return months
    }
    handleRefresh = async () => {
      const newState = { isLoading: false }
      if (!this.props.analyticsContext.filters.user_uid || !this.props.analyticsContext.filters.user_uid.value) {
        return
      }

      this.setStateAsync({ isLoading: true, user: undefined })
      const filter = mapContextToFilter(this.props, true)

      const startDate = '2018-01-01'
      const endDate = '2019-05-31'
      let start = moment(startDate)
      const quarterDates = []
      while (start.isBefore(moment(endDate))) {
        quarterDates.push(start.format('MM-Y'))
        start.add(3, 'months')
      }

      const widgetDefinition = getWidgetDefinition(
        this.props.analyticsContext.filters.user_uid.value[0],
        startDate,
        endDate,
        filter
      )
      const variables = {
        widgetDefinition,
        filter
      }
      const userRes = await this.props.client.query({
        query: qlqGetUser,
        variables: {
          user_uid: this.props.analyticsContext.filters.user_uid.value[0]
        }
      })
      if (userRes) {
        newState.user = userRes.data.user
      }

      const res = await this.props.client.query({
        query: qlAnalyticsWidgetQuery,
        variables,
        fetchPolicy: 'no-cache'
      })
      if (res && res.data && res.data.dataSourceQuery && res.data.dataSourceQuery.data.dataSources) {
        const reduceQuarters = (quarterField, valueField) => (a, i) => {
          const r = { ...a }
          if (i[quarterField]) {
            const d = moment(i[quarterField])
            const qkey = d.format('MM-Y')
            r[qkey] = i[valueField]
          }
          return r
        }
        const dataSourcesData = res.data.dataSourceQuery.data.dataSources
        const meetingsData = dataSourcesData.ActivitiesListQM.rawData.data
          ? dataSourcesData.ActivitiesListQM.rawData.data.reduce(
              reduceQuarters('start_date_quarter', 'activity_uid'),
              {}
            )
          : {}
        const fitmentsData = dataSourcesData.OrdersListQM.rawData.data
          ? dataSourcesData.OrdersListQM.rawData.data.reduce(
              reduceQuarters('completed_date_quarter', 'vehicles_count'),
              {}
            )
          : {}

        const opportunitiesCountData =
          dataSourcesData.OpportunitiesListQM.rawData && dataSourcesData.OpportunitiesListQM.rawData.data
            ? dataSourcesData.OpportunitiesListQM.rawData.data.reduce(
                reduceQuarters('created_date_quarter', 'opportunity_uid'),
                {}
              )
            : {}

        const opportunitiesSizeData = dataSourcesData.OpportunitiesListQM.rawData.data
          ? dataSourcesData.OpportunitiesListQM.rawData.data.reduce(
              reduceQuarters('created_date_quarter', 'vehicle_count'),
              {}
            )
          : {}

        const table1Data = [
          {
            label: 'Quotes Created - number',
            ...opportunitiesCountData
          },
          {
            label: 'Quotes Created - size',
            ...opportunitiesSizeData
          },
          {
            label: 'Meetings Completed',
            ...meetingsData
          },
          {
            label: 'Fitments',
            ...fitmentsData
          }
        ]

        newState.table1Data = table1Data
        newState.months = this.buildMonths()

        if (
          dataSourcesData.AccountsResults &&
          dataSourcesData.AccountsResults.rawData &&
          dataSourcesData.AccountsResults.rawData.data
        ) {
          newState.leadsResults = dataSourcesData.AccountsResults.rawData.data[0]
        }
        if (
          dataSourcesData.FitmentsInPeriod &&
          dataSourcesData.FitmentsInPeriod.rawData &&
          dataSourcesData.FitmentsInPeriod.rawData.data.length === 1
        ) {
          newState.fitmentsInPeriod = dataSourcesData.FitmentsInPeriod.rawData.data[0].vehicles_count
        }

        if (
          dataSourcesData.PendingInPeriod &&
          dataSourcesData.PendingInPeriod.rawData &&
          dataSourcesData.PendingInPeriod.rawData.data.length === 1
        ) {
          newState.pendingInPeriod = dataSourcesData.PendingInPeriod.rawData.data[0].vehicles_count
        }

        if (
          dataSourcesData.FitmentsHistory &&
          dataSourcesData.FitmentsHistory.rawData &&
          dataSourcesData.FitmentsHistory.rawData.data.length === 1
        ) {
          newState.fitmentsHistoryRows = [
            newState.months.reduce(
              (a, i) => {
                return {
                  ...a,
                  [i]: dataSourcesData.FitmentsHistory.rawData.data[0].fitments_history[i + '-01']
                }
              },
              {
                owner_user_uid: dataSourcesData.FitmentsHistory.rawData.data[0].fitments_history.owner_user_uid
              }
            )
          ]

          newState.fitmentsHistoryColumns = newState.months.reduce((a, i) => {
            return [
              ...a,
              {
                accessor: i,
                Header: i,
                headerClassName: 'CrmFlatTable-summary util-textCenter',
                align: 'center',
                type: 'number',
                detailsPopup: 'fitment',
                detailsFilter: {
                  owner_user_uid: dataSourcesData.FitmentsHistory.rawData.data[0].fitments_history.owner_user_uid,
                  completed_date: {
                    $lte: moment(i)
                      .endOf('month')
                      .format('YYYY-MM-DD'),
                    $gte: i + '-01'
                  }
                }
              }
            ]
          }, [])
        }
      }

      if (res.data.dataSourceQuery.data.dataSources.OpportunitiesResults) {
        const reduced = res.data.dataSourceQuery.data.dataSources.OpportunitiesResults.rawData.data.reduce((a, i) => {
          const ret = { ...a }
          const qkey = moment(i.process__completed_date_quarter).format('MM-Y')
          if (!ret[i.process__step__type__name]) {
            ret[i.process__step__type__name] = {
              process__step__type__name: i.process__step__type__name,
              label: i.process__step__type__name
            }
          }
          ret[i.process__step__type__name][qkey] = {
            vehicle_count: i.vehicle_count,
            opportunity_uid: i.opportunity_uid
          }

          return ret
        }, {})
        newState.table3Data = Object.values(reduced)
      }

      this.setState({
        quarterDates,
        isLoading: false,
        ...newState,
        table1Columns: buildTable1Columns(quarterDates)
      })
    }

    render() {
      const isUserRequiredError =
        !this.props.analyticsContext?.filters?.user?.user_uid?.value &&
        !this.props.analyticsContext?.filters?.user_uid?.value

      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onRefresh={this.handleRefresh}
          isUserRequiredError={isUserRequiredError}
        />
      )
    }
  }
  return withApollo(AgentDetailsB2BDashboardHoc)
}

export default agentDetailsB2BDashboardHoc
