import React, { SFC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { CrmBadge } from '@cartrack-crm/ui'

type ActivityStatusProps = {
  code: string
  kind: string
  name: string
  is_success: boolean
  description: string
}

type ActivityActionProps = {
  showActionsOnHover?: boolean
  activityStatus: ActivityStatusProps
  activityType?: string
  isPendingLiveCall?: boolean
  calling?: {
    started_time: string
  }
  callDuration?: number
  children: React.ReactNode
}

const ActivityStatus = styled.div``
export const ActivityActionButtons = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  ${p =>
    p.showActionsOnHover &&
    `
  visibility: hidden;
  `}
`
const ActivityActionContainer = styled.div``

enum BadgeType {
  primary = 'primary',
  success = 'success',
  failure = 'danger',
  default = 'default',
  pending = 'pending'
}
const mapBadgeType = (kind): BadgeType => {
  switch (kind) {
    case 'failure':
      return BadgeType.failure
    case 'success':
      return BadgeType.success
    case 'pending':
      return BadgeType.pending
    case 'initial':
    default:
      return BadgeType.default
  }
}
const ActivityAction = (props: ActivityActionProps) => {
  const [durationString, setDurationString] = useState('')
  useEffect(() => {
    if (props.isPendingLiveCall) {
      setDurationString('Pending...')
    }
  }, [])

  const badge_type = props.isPendingLiveCall
    ? 'pending'
    : props && props.activityStatus
    ? props.activityStatus.kind
    : undefined

  const activity_name =
    props.activityStatus?.kind == 'queued' && props.activityStatus?.is_success
      ? 'Email Sent'
      : props.activityStatus?.description

  return (
    <ActivityActionContainer>
      <ActivityStatus>
         <CrmBadge type={mapBadgeType(badge_type)}>
            {durationString || activity_name || props.activityStatus?.name}
          </CrmBadge>
      </ActivityStatus>
      <ActivityActionButtons showActionsOnHover={props.showActionsOnHover}>{props.children}</ActivityActionButtons>
    </ActivityActionContainer>
  )
}

const hhmmss = sec => {
  const pad = num => {
    return ('0' + num).slice(-2)
  }
  if (!sec || isNaN(Number(sec))) {
    return undefined
  }
  let secs = sec
  let minutes = Math.floor(secs / 60)
  secs %= 60
  const hours = Math.floor(minutes / 60)
  minutes %= 60
  return `${hours > 0 ? `${hours}:` : ``}${pad(minutes)}:${pad(secs)}`
}

export default ActivityAction
