import React, { PureComponent } from 'react'
import { shape } from 'prop-types'

import AfterMeetingSurveyView from './after-meeting-survey-view.jsx'
import { AccountPermissionsContextProvider } from '../../../../core/permissions/account-permissions-context'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'

class AfterMeetingSurveyPage extends PureComponent {
  componentWillMount() {
    console.log(
      'AfterMeetingSurveyPage params match : ',
      this.props.match.params
    )
  }

  render() {
    console.log('AfterMeetingSurveyPage ', this.props)
    return (
      <AfterMeetingSurveyView
        activityUid={this.props.match.params.activityUid}
        accountUid={this.props.match.params.accountId}
      />
    )
  }
}

AfterMeetingSurveyPage.propTypes = {
  match: shape({}).isRequired
}

const PermissionsContextPage = withAccountPermissionsContextHOC(
  AfterMeetingSurveyPage
)
const WithPermissionsContextPage = props => {
  return (
    <AccountPermissionsContextProvider
      account_uid={props.match.params.accountId}
    >
      <PermissionsContextPage {...props} />
    </AccountPermissionsContextProvider>
  )
}
WithPermissionsContextPage.propTypes = {
  account: shape({}),
  match: shape({}).isRequired
}
WithPermissionsContextPage.defaultProps = {
  account: undefined
}

export default WithPermissionsContextPage
