import { useContext } from 'react'
import { CrmToastrPosition, CrmToastrType, ToastrContext } from '@cartrack-crm/ui/src/toastrs'
interface optionParams {
  btnPrimaryTitle?: string
  btnSecondaryTitle?: string
  onBtnPrimary?: any
  onBtnSecondary?: any
  textDetailContent?: any
  textDetailTitle?: string
  position?: CrmToastrPosition
  message?: string
  timeToShow?: number
}
export const useCrmToastr = () => {
  const { state, setState, resetState } = useContext(ToastrContext)
  const showToastr = (type = CrmToastrType.success, title: string = null, options: optionParams = null) => {
    let timeToShow = null
    if (!options || options?.timeToShow === undefined) {
      timeToShow = 4000
    } else if (options && options.timeToShow !== null) {
      timeToShow = options.timeToShow
    }
    setState({ state, isShowToast: true, title, type, options: { ...options, timeToShow: timeToShow } })
    if (timeToShow) {
      setTimeout(() => {
        hideToastr()
      }, timeToShow)
    }
  }

  const hideToastr = () => {
    resetState()
  }

  return [showToastr, hideToastr]
}
