/* eslint-disable camelcase */
import React from 'react'
import moment from 'moment'
import { withApollo } from 'react-apollo'
import { shape, func } from 'prop-types'
import withLeadsPopupHoc from '../../../components/_with-leads-popup-hoc.jsx'
import { CrmButton } from 'crm-components'

import WidgetRmFitments from './widget-rms-fitments.jsx'

class CtDashForecast19 extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'months'
    }
  }

  render() {
    console.log('CT Dash Forecast 2019', this.state)
    const selectedMonthData =
      this.state.selectedMonth && this.state.opportunitiesData
        ? this.state.opportunitiesData.filter(
            d => d.opportunity_due_date_month === this.state.selectedMonth
          )
        : []
    console.log('selectedMonthData, ', selectedMonthData)
    return (
      <div>
        <WidgetRmFitments />
      </div>
    )
  }
}

CtDashForecast19.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

export default withLeadsPopupHoc(withApollo(CtDashForecast19))
