import { callQLApi } from './api-utils'
import {
  qlActivityOwnFields,
  qlActivityAccountFields,
  qlActivityTypeOwnFields,
  qlActivityParticipantOwnFields,
  qlActivityStatusOwnFields
} from 'crm-data/activities'

/* Ql example
{"query": "{ my_calendar(start_date:\"2017-07-11\", end_date:\"2017-07-11\") { activity_uid start_time } } "}
{"query": "{ group_calendar(start_date:\"2017-07-11\", end_date:\"2017-07-11\") { activity_uid start_time } } "}
*/

// Note - don't reuse qlActivityWithFields here because it gets too much details - not needed for calendar
const qlActivitiesFields = ` ${qlActivityOwnFields} 
  account { ${qlActivityAccountFields} }
  activity_type { ${qlActivityTypeOwnFields} }
  participants { ${qlActivityParticipantOwnFields} } 
  primary_performer { ${qlActivityParticipantOwnFields} } 
  activity_status {${qlActivityStatusOwnFields}}
  meeting_minutes { meeting_minutes_uid completed_at } 
  address { address_uid city location } `

const qlCalendarFields = `
  data {
    account_uid
    account_name
    activity_uid
    medium_code
    city
    start_time
    activity_type_uid
    activity_type_code
    activity_status_kind
    activity_type_name
    location_type
    primary_performer_full_name
    primary_performer_photo_url
    participants_length
    participants_full_name_all
  }
`

export default {
  fetchActivities(isMyCalendar, params) {
    const rootQuery = isMyCalendar ? 'my_calendar' : 'group_calendar'
    const filters = []
    if (params.canceledFilter) {
      filters.push('with_canceled:"true"')
    }

    if (!isMyCalendar && params.user_uid) {
      filters.push(`user_uid:"${params.user_uid}"`)
    }

    if (!isMyCalendar && params.activity_type_uid) {
      filters.push(`activity_type_uid:"${params.activity_type_uid}"`)
    }

    if (!isMyCalendar && params.resource_pool_uid) {
      filters.push(`resource_pool_uid:"${params.resource_pool_uid}"`)
    }

    const filterStr = filters.length > 0 ? ',' + filters.join(',') : ''

    const query = ` { ${rootQuery}(start_date:"${params.startDate}", end_date:"${params.endDate}" ${filterStr}) { ${qlActivitiesFields} } }`
    return callQLApi(query, null, null, true).then(res => res[rootQuery])
  },
  fetchUsers() {
    const query = ` { users { user_uid, full_name headshot_url } } `
    return callQLApi(query, null, null, true).then(res => res.users)
  },
  fetchCalendar(isMyCalendar, params) {
    const rootQuery = isMyCalendar ? 'my_calendar_ql' : 'group_calendar_ql'
    const filters = {}
    if (params.canceledFilter) {
      filters.with_canceled = true
    }

    if (!isMyCalendar) {
      if (params.user_uid) {
        filters.user_uid = params.user_uid
      }
      if (params.activity_type_uid) {
        filters.activity_type_uid = params.activity_type_uid
      }
      if (params.resource_pool_uid) {
        filters.resource_pool_uid = params.resource_pool_uid
      }
    }

    let filterStr = ''
    let key
    for (key in filters) {
      filterStr += `,${key}:"${filters[key]}"`
    }

    const query = ` { ${rootQuery}(start_date:"${params.startDate}", end_date:"${params.endDate}" ${filterStr}) { ${qlCalendarFields} } }`
    return callQLApi(query, null, null, true).then(res => res[rootQuery])
  }
}
