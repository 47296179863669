import React, { useState } from 'react'
import { CrmTextArea, CrmModal, CrmButton } from '@cartrack-crm/ui'

interface CrmJsonPreviewProps {
  onChange?: Function
  json: any
  rows?: number
  editable?: boolean
}

interface CrmJsonPreviewState {
  isValid: boolean
  jsonString: string
  json?: any
}

export function jsonSyntaxHighlight(jsonParam) {
  let json = jsonParam
  if (!jsonParam) {
    return undefined
  }
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, 2)
  }
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
  return json.replace(
    // eslint-disable-next-line
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    match => {
      let cls = 'number'
      // eslint-disable-next-line
      if (/^"/.test(match)) {
        // eslint-disable-next-line
        if (/:$/.test(match)) {
          cls = 'key'
        } else {
          cls = 'string'
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean'
      } else if (/null/.test(match)) {
        cls = 'null'
      }
      return '<span class="' + cls + '">' + match + '</span>'
    }
  )
}

class CrmJsonPreview extends React.PureComponent<CrmJsonPreviewProps, CrmJsonPreviewState> {
  constructor(props) {
    super(props)
    this.state = {
      jsonString: JSON.stringify(props.json, null, 2),
      isValid: true
    }
  }
  handleChange = (event) => {
    console.log('handleChange', event)
    try {
      this.setState({
        jsonString: event.currentTarget.value
      })
      const jsonObj = JSON.parse(event.currentTarget.value)
      this.setState({
        jsonString: event.currentTarget.value,
        json: jsonObj,
        isValid: true
      })
      this.props.onChange(jsonObj)
    } catch (err) {
      this.setState({ isValid: false })
    }
  }
  renderEditable() {
    return (
      <div>
        <label>Valid: {this.state.isValid ? 'YES' : 'NO'}</label>
        <CrmTextArea
          input={{
            value: this.state.jsonString,
            onChange: this.handleChange
          }}
          rows={this.props.rows}
          placeholder="Edit json string"
        />
      </div>
    )
  }
  render() {
    const { editable, json } = this.props
    if (editable) {
      return this.renderEditable()
    }
    return (
      <div className="json-output util-flex util-fullHeight">
        <pre
          className="util-overflowYAuto util-fullWidth"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: jsonSyntaxHighlight(json)
          }}
        />
      </div>
    )
  }
}

interface CrmJsonPreviewModalProps extends CrmJsonPreviewProps {
  onLoad: Function
  buttonName: string
}

export class CrmJsonPreviewModal extends React.PureComponent<CrmJsonPreviewModalProps> {
  state = {
    preview: this.props.json || {},
    expanded: false,
    isLoading: false
  }
  onClick = async () => {
    const { preview, expanded } = this.state
    if (!this.props.onLoad) {
      this.setState({ expanded: !expanded })
    } else if (Object.keys(preview).length >= 1) {
      this.setState({ expanded: !expanded })
    } else {
      this.setState({ isLoading: true })
      const data = await this.props.onLoad()
      this.setState({
        preview: data,
        isLoading: false,
        expanded: true
      })
    }
  }
  get preview() {
    if (this.props.json) return this.props.json
    return this.state.preview
  }
  get text() {
    const { expanded, isLoading } = this.state
    let text = expanded
      ? `Hide ${this.props.buttonName}`
      : `Show ${this.props.buttonName}`
    if (isLoading) {
      text = 'Loading...'
    }
    return text
  }
  render() {
    return (
      <React.Fragment>
        <CrmButton
          onClick={this.onClick}
          isSaving={this.state.isLoading}
          label={this.text}
          small
        />
        <CrmModal
          isOpen={this.state.expanded}
          title="Preview JSON"
          onClose={() => this.setState({ expanded: !this.state.expanded })}
          size="lg"
        >
          <CrmJsonPreview json={this.preview} />
        </CrmModal>
      </React.Fragment>
    )
  }
}

export const CrmJsonPreviewInline = ({ json }) => {
  if (!json) return <div></div>
  const [expanded, setExpand] = useState(false)
  return (
    <div style={{ display: 'block' }}>
      <div>
        <button onClick={() => setExpand(!expanded)}>
          {expanded ? 'Hide' : 'Show'}
        </button>
      </div>
      {expanded && <CrmJsonPreview json={json} />}
    </div>
  )
}

export default CrmJsonPreview
