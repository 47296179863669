import { callQLApi } from './api-utils'

const qlReportFields = `  { report_uid, input_parameters, name, result  } `

export default {
  fetchReports() {
    const query = `{ reports ${qlReportFields} } `
    return callQLApi(query).then(res => res.reports)
  },
  generateReport(params) {
    const report = {
      report_uid: params.report, // eslint-disable-line
      reportDefinition: params.reportDefinition,
      parameters: { ...params }
    }
    delete report.parameters.report
    delete report.parameters.reportDefinition

    const query = {
      query: `mutation report_generator($report: report_generate) { report_generator(report: $report)  ${qlReportFields}  }`,
      variables: { report }
    }
    console.log('generateReport ql : ', query)
    return callQLApi(query).then(res => res.report_generator)
  }
}
