import cloneDeep from 'clone-deep'
import moment, { MomentInput } from 'moment'
import camelCase from 'camelcase'
import { isDate } from 'util'
import { getDateFormat } from 'crm-utils/time-utils'
import { get } from 'lodash'

const DATE_FORMAT = 'YYYY-MM-DD'

export const formatDateFilterValue = filter => {
  if (!filter) return 'empty'

  var value = filter.value ? filter.value : filter

  if (value.$lte && value.$gte) return getDateFormat(value.$gte) + ' - ' + getDateFormat(value.$lte)

  return value
}

export const mapContextToFilter = (props, mapDates = true, mapActivityDateAs = undefined, params = undefined) => {
  let filter: any = {}

  if (props && props.analyticsContext && props.analyticsContext.filters) {
    const supportedFields = Object.keys(props.analyticsContext.filters)

    supportedFields.forEach(field => {
      if (field === 'activity_date' || field === 'lead_in_date') return
      if (!props.analyticsContext.filters[field] || filter.hasOwnProperty(field)) return

      if (props.analyticsContext.filters[field].value) {
        if (
          Array.isArray(props.analyticsContext.filters[field].value) &&
          props.analyticsContext.filters[field].value.length > 0
        ) {
          filter[field] = props.analyticsContext.filters[field].value[0]
        } else {
          if (props.analyticsContext.filters[field].label) {
            filter[field] = { ...props.analyticsContext.filters[field] }
          } else {
            filter[field] = props.analyticsContext.filters[field].value
          }
        }
      } else {
        filter[field] = props.analyticsContext.filters[field]
      }
    })

    if (props.analyticsContext.filters.lead_in_date) {
      filter.account = mergeDeep({}, filter.account, {
        lead_in_date: props.analyticsContext.filters.lead_in_date
      })
    }

    if (props.analyticsContext.filters.user_uid && !props.analyticsContext.filters.user) {
      filter.user = {
        user_uid: props.analyticsContext.filters.user_uid
      }
    }

    if (mapDates) {
      const targetKey = mapActivityDateAs || 'activity_date'

      if (props.analyticsContext.filters.activity_date && !filter.hasOwnProperty(targetKey)) {
        if (props.analyticsContext.filters.activity_date.value) {
          filter[targetKey] = props.analyticsContext.filters.activity_date
        } else {
          filter[targetKey] = props.analyticsContext.filters.activity_date
        }
      }
    }

    if (get(props, 'analyticsContext.filters.resource_pool_uid.value', false)) {
      filter.user_uid = {
        // eslint-disable-next-line
        $inResourcePool: props.analyticsContext.filters.resource_pool_uid.value[0]
      }

      delete filter.resource_pool_uid
    }
  }

  if (get(params, 'contextMapping', false)) {
    filter = runContextMapping(params.contextMapping, filter, filter)
  }

  return filter
}

const runContextMapping = (contextMapping, filter, contextFilter) => {
  const ret = { ...filter }

  Object.keys(contextMapping).forEach(key => {
    const isObject = contextMapping[key] instanceof Object

    if (isObject) {
      const mappedObjectFilter = runContextMapping(contextMapping[key], {}, contextFilter)
      ret[key] = mappedObjectFilter
    } else {
      ret[key] = contextFilter[contextMapping[key]]

      if (contextMapping[key] !== key) {
        delete ret[contextMapping[key]]
      }
    }
  })

  return ret
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target

  const source = sources.shift()

  if (isObject(target) && isObject(source) && !target.$skip) {
    for (const key in source) {
      if (isDate(source[key])) {
        target[key] = source[key]
      } else if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const mapContextFiltersIntoWidget = (widgetDefinition, props) => {
  if (!get(widgetDefinition, 'content.data.dataSources', false)) return widgetDefinition

  let contextFilter = mapContextToFilter(props, true)

  contextFilter = {
    ...convertUnderlineFilterToObject(contextFilter),
    ...contextFilter
  }

  const ret = cloneDeep(widgetDefinition)

  ret.content.data.dataSources.forEach(ds => {
    ds.filter = mergeDeep({}, ds.$noContextFilter ? {} : contextFilter, ds.filter)

    if (ds.contextMapping) {
      const contextMappedFilter = runContextMapping(ds.contextMapping, ds.filter, contextFilter)

      ds.filter = mergeDeep({}, ds.$noContextFilter ? {} : contextFilter, ds.filter, contextMappedFilter)
    }
  })

  return ret
}

export const mapContextToFilter2 = (props, params) =>
  mapContextToFilter(props, params.mapDates, params.mapActivityDateAs, params)

export type Precision = 'days' | 'months' | 'years'

export type GenerateDateSeries = (
  pStartDate: MomentInput,
  pEndDate: MomentInput,
  handler: Function,
  precision: Precision
) => Array<string>

export const generateDateSeries: GenerateDateSeries = (pStartDate, pEndDate, handler, precision = 'days') => {
  const startDate = moment(pStartDate)
  const endDate = moment(pEndDate)
  const order = startDate.isAfter(endDate) ? 'desc' : 'asc'
  const res = {}
  const date = startDate

  while (order === 'asc' ? date.isSameOrBefore(endDate) : date.isSameOrAfter(endDate)) {
    res[date.format(DATE_FORMAT)] = handler(date.format(DATE_FORMAT))
    date.add(order === 'asc' ? 1 : -1, precision)
  }

  return Object.values(res)
}

export const filterToCamelCase = filters =>
  Object.keys(filters).reduce((a, key) => {
    a[camelCase(key)] = filters[key]
    return a
  }, {})

export const convertUnderlineFilterToObject = filter => {
  let result = {}
  const roots = {}
  const ownFields = {}

  Object.keys(filter).forEach(key => {
    const parts = key.split('__')
    if (parts.length > 1) {
      const root = parts[0]
      parts.splice(0, 1)
      roots[root] = roots[root] || {}
      roots[root][parts.join('__')] = filter[key]
    } else {
      ownFields[parts[0]] = parts[0]
      result[parts[0]] = filter[parts[0]]
    }
  })
  Object.keys(roots).forEach(root => {
    result[root] = convertUnderlineFilterToObject(roots[root])
  })
  return result
}

export const convertMonthToDateRange = month => ({
  $gte: moment(month)
    .startOf('month')
    .format(DATE_FORMAT),
  $lte: moment(month)
    .endOf('month')
    .format(DATE_FORMAT)
})

export const resolveDashboardWidgetFiltersFromContextFilters = context => {
  const activityDateLte = get(context, 'filters.activity_date.value.$lte', false)
  return {
    DATE_FORMAT,
    endDate: activityDateLte
      ? moment(activityDateLte)
          .endOf('month')
          .format(DATE_FORMAT)
      : moment()
          .endOf('month')
          .format(DATE_FORMAT),
    userUid: get(context, 'filters.user.user_uid.value', undefined),
    organization_unit: get(context, 'filters.organization_unit', undefined)
  }
}
