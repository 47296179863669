/* eslint-disable camelcase */
import React from 'react'
import { shape, func } from 'prop-types'
import moment from 'moment'
import withLeadsPopupHoc from '../../../components/_with-leads-popup-hoc.jsx'

class WhiteBoardWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentWillMount() {}

  handleSelectCallDetails = (filters = {}, UserUid) => {
    // console.log('handleSelectCallDetails XXX : ', filters, UserUid)

    const propsFilter = this.props.analyticsContext.filters

    const filterParams = {
      user_uid: UserUid,
      ...filters,
      start_time: {
        $gte: Object.prototype.hasOwnProperty.call(propsFilter, 'activity_date')
          ? propsFilter.activity_date.value.$gte
          : moment().format('YYYY-MM-DD'),
        $lte: Object.prototype.hasOwnProperty.call(propsFilter, 'activity_date')
          ? propsFilter.activity_date.value.$lte
          : moment().format('YYYY-MM-DD')
      },

      ...this.props.analyticsContext.filters
    }
    const params = { type: 'disposition', filters: filterParams }
    this.props.onShowLeadsPopup(params)
  }

  render() {
    console.log('Wallboard Render')
    return <div></div>
  }
}

WhiteBoardWidget.propTypes = {
  analyticsContext: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

export default withLeadsPopupHoc(WhiteBoardWidget)
