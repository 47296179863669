/* eslint-disable */
import React, { PureComponent } from 'react'
import gql from 'graphql-tag'
import { shape, arrayOf, string } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import { CrmTable, CrmHbox, CrmModal, CrmButton } from 'crm-components'
import { jsonSyntaxHighlight } from 'crm-utils/common-utils'
import HookLogTable from './hook-log-table-inner.jsx'

class CrmHookRequestLogTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      startDateLog: new Date(),
      endDateLog: new Date(),
      showModal: false,
      selectedRow: undefined,
      isDisabledRetry: false
    }
  }

  onClickRetryHook = async (rowInfo, event) => {
    if (!this.state.isDisabledRetry) {
      this.setState({ ...this.state, isDisabledRetry: true })
      try {
        await this.props.retry_hook({ variables: { id: `${rowInfo.id}` } })
      } catch (e) {}
      this.setState({ ...this.state, isDisabledRetry: false })
      this.tableRefreshQL()
    }
  }

  onClickOpenInfo = rowInfo => {
    if (rowInfo)
      this.setState({ ...this.state, showModal: true, selectedRow: rowInfo })
  }

  handleCloseModal = () => {
    this.setState({ ...this.state, showModal: false, selectedRow: undefined })
  }

  getRefreshFunc = func => {
    this.tableRefreshQL = func
  }

  render() {
    const { data } = this.props

    let selectedID = null
    let selectedStatus = null
    if (this.state.selectedRow) {
      selectedID = this.state.selectedRow.id
      selectedStatus = this.state.selectedRow.status
    }

    return (
      <div fill>
        <div>
          <HookLogTable
            isDisabledRetry={this.state.isDisabledRetry}
            handleClickRetryHook={this.onClickRetryHook}
            handleClickOpenInfo={this.onClickOpenInfo}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            columns={this.columns}
            getRefreshFunc={this.getRefreshFunc}
            filterText={this.props.filterText}
          />
        </div>
        <CrmModal
          isOpen={this.state.showModal}
          title={`Request ID : ${selectedID} (${selectedStatus})`}
          onClose={this.handleCloseModal}
          size="xl"
        >
          {this.state.selectedRow && (
            <div className="json-output">
              <div>
                <h3>INPUT:</h3>
                <pre
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: jsonSyntaxHighlight(this.state.selectedRow.data)
                  }}
                />
              </div>
              <div>
                <h3>OUTPUT:</h3>
                <pre
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: jsonSyntaxHighlight(this.state.selectedRow.response)
                  }}
                />
              </div>
              <div>
                <h3>SERVER DATA:</h3>
                <pre
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: jsonSyntaxHighlight(
                      this.state.selectedRow.server_data
                    )
                  }}
                />
              </div>
            </div>
          )}
        </CrmModal>
      </div>
    )
  }
}

CrmHookRequestLogTable.defaultProps = {
  department: {}
}

CrmHookRequestLogTable.propTypes = {
  data: arrayOf(shape({})).isRequired,
  data: string.isRequired
}

const retryHook = gql(
  `mutation retry_hook($id: String!) { retry_hook(id: $id){ id, status } }`
)

const CrmHookRequestLogTableQL = compose(
  graphql(retryHook, { name: 'retry_hook' })
)(CrmHookRequestLogTable)

export default CrmHookRequestLogTableQL
