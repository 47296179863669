/* eslint-disable camelcase */
import React from 'react'
import { bool, func, object } from 'prop-types'
import { Link } from 'react-router-dom'

import { FormattedDate, FormattedTime } from 'react-intl'
import {
  mapContextToFilter,
  convertUnderlineFilterToObject
} from 'crm-modules/dashboard/dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { CrmModal } from 'crm-components'
import genericTableFactory from 'crm-components/ql/table/crm-generic-dynamic-table.tsx'
import { allColumns } from './dynamic-tables/columns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const defaultQueryFields = {
  selections: [
    { name: 'account_uid' },
    { name: 'name' },
    { name: 'customer_since_date' },
    { name: 'current_owner_age' },
    { name: 'won_by_user_uid' },
    {
      name: 'won_by_user',
      selections: [{ name: 'full_name' }]
    },
    { name: 'latest_step_type_kind' },
    { name: 'latest_step_type_code' },
    { name: 'latest_step_type_name' },
    { name: 'sales_result_code' },
    { name: 'detailed_status_code' },
    { name: 'current_owner_age' },
    { name: 'last_activity_note' },
    { name: 'current_step_type_name' },
    {
      name: 'lead_campaign',
      selections: [{ name: 'name' }, { name: 'campaign_uid' }]
    },
    { name: 'lead_in_time' },
    { name: 'meta' },
    {
      name: 'last_activity',
      selections: [
        { name: 'activity_uid' },
        { name: 'start_time' }
      ]
    },
    {
      name: 'latest_owner',
      selections: [
        { name: 'name' },
        { name: 'uid' },
        { name: 'type' },
        { name: '__typename' },
        { name: 'department_type', selections: [{ name: 'name' }] }
      ]
    }
  ]
}

const columns = [
  {
    Header: 'Account Name',
    Cell: row => (
      <Link to={'/crm/account/' + row.original.account_uid} target="blank">
        {row.original.name}
      </Link>
    ),
    accessor: 'name'
  },
  {
    Header: 'Owner',
    accessor: 'latest_owner.name',
    Cell: row => (
      <div>
        {row.original && row.original.latest_owner && (
          <FontAwesomeIcon
            style={{ marginRight: 3, fontSize: 10, opacity: 0.8 }}
            icon={row.original.latest_owner.type === 'user' ? 'user' : 'users'}
          />
        )}
        {row.original.latest_owner ? row.original.latest_owner.name : undefined}
      </div>
    )
  },
  {
    Header: 'Owner Department',
    accessor: 'latest_owner.department_type.name',
    Cell: row =>
      row.original.latest_owner && row.original.latest_owner.department_type
        ? row.original.latest_owner.department_type.name
        : ''
  },
  {
    Header: 'Lead Campaign',
    accessor: 'lead_campaign.name',
    Cell: row =>
      row.original.lead_campaign ? row.original.lead_campaign.name : ''
  },
  {
    Header: 'Customer Since',
    accessor: 'customer_since_date',
    Cell: row =>
      row.original.customer_since_date ? (
        <span>
          <FormattedDate value={row.original.customer_since_date} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Won By',
    accessor: 'won_by_user.full_name',
    Cell: row =>
      row.original.won_by_user ? row.original.won_by_user.full_name : ''
  },
  {
    Header: 'Lead In Time',
    accessor: 'lead_in_time',
    Cell: row =>
      row.original.lead_in_time ? (
        <span>
          <FormattedDate value={row.original.lead_in_time} />{' '}
          <FormattedTime value={row.original.lead_in_time} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Last Activity Date',
    accessor: 'last_activity_time',
    Cell: row =>
      row.original.last_activity ? (
        <span>
          <FormattedDate value={row.original.last_activity.start_time} />{' '}
          <FormattedTime value={row.original.last_activity.start_time} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Owned Since',
    accessor: 'current_owner_age'
  },
  {
    Header: 'Status',
    accessor: 'sales_result_code'
  },
  {
    Header: 'Details',
    accessor: 'detailed_status_code'
  },
  {
    Header: 'Step Code',
    accessor: 'latest_step_type_code'
  },
  {
    Header: 'Step Name',
    accessor: 'latest_step_type_name'
  },
  {
    Header: 'Details Step Name',
    accessor: 'current_step_type_name'
  }
]
const qlOptions = {
  options: ownProps => {
    const props = { analyticsContext: { filters: ownProps.filters } }

    let filter = { ...ownProps.filters, ...mapContextToFilter(props) }
    filter = convertUnderlineFilterToObject(filter)
    filter.is_deleted = 'false'

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions,
  rootQLType: 'Account',
  useEdges: true,
  queryRoot: 'accountsc',
  columnOptions: allColumns,
  defaultQueryFields
})

const AccountsDetailsPopup = props => (
  <CrmModal
    isOpen={props.isOpen}
    title="Accounts Details"
    onClose={props.onClose}
    size="xl"
  >
    <Table
      columns={columns}
      style={{ width: '100%' }}
      filters={props.filters}
    />
  </CrmModal>
)

AccountsDetailsPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: object
}

AccountsDetailsPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

export default AccountsDetailsPopup
