import React from 'react'
import cloneDeep from 'clone-deep'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CrmLabeledField, CrmButton } from '@cartrack-crm/ui'
import { ContactPersonStyled } from './section-contact-person.styled'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { injectIntl } from 'react-intl'
// import ContactPersonItem from 'crm-modules/accounts/components/layout19/contact-person-item.jsx'

interface IProps {
  account: any
  persons: any
  handleEditEnd: Function
  handleQuotationPersonChange: Function
  isAddingNewPerson: Function
  onStartAddPerson: Function
  removePersonUI: Function
  handleEditStart: Function
  onCancelAddPerson: Function
  updatePersonsUI: Function
  isExpanded: boolean
  intl: any
  hideLabel: boolean
  quotation_person: any
}

interface IState {
  persons: any
  selected_person: any
}

class SectionContactPerson extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      persons: cloneDeep(props.persons),
      selected_person: undefined
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.persons !== this.props.persons) {
      const persons = [...nextProps.persons]
      this.setState({ persons })
    }
    if (nextProps.quotation_person !== this.props.quotation_person) {
      this.handleSelectPerson(null, nextProps.quotation_person, false)
    }
  }

  handleClose = (e) => {
    const newItemIndex = this.props.persons.findIndex((v) => v.isNew === true)
    if (newItemIndex > -1) {
      this.props.removePersonUI(newItemIndex)
    }
    e.stopPropagation()
    this.props.handleEditEnd()
  }

  handleSelectPerson = (e, person, do_api_update) => {
    this.setState(
      {
        selected_person: person
      },
      () => {
        this.props.handleQuotationPersonChange(person, do_api_update)
      }
    )
  }

  handleStartAddPerson = () => {
    if (this.props.isAddingNewPerson()) {
      return
    }
    this.props.onStartAddPerson()
  }

  getPersonEmails = (person) => {
    const result = []
    person.contact_details &&
      person.contact_details.map((v) => {
        if (v.kind === 'email') {
          result.push(v)
        }
      })

    return result
  }

  handleEditPerson = (selectedPerson) => {
    const persons = [...this.state.persons].map((v) => {
      const findPerson =
        selectedPerson &&
        selectedPerson.account_person_uid === v.account_person_uid
      if (findPerson) {
        const contact_details =
          v.contact_details && v.contact_details.map
            ? [...v.contact_details].map((v2) => {
                return { ...v2, isEditing: true }
              })
            : []
        return { ...v, contact_details, isEditing: true }
      } else {
        return v
      }
    })
    this.setState({ persons })
  }

  handleCancelEditPersonOnMeetingForm = () => {
    const persons = [...this.state.persons].map((v) => {
      delete v.isEditing
      return { ...v }
    })
    this.setState({ persons })
  }

  _renderSelectedPersonLabel = (person) => {
    return <div>{person.name}</div>
  }

  _renderConactPersonForm = (index, person) => {
    const children = React.Children.map(this.props.children, (child: any) => {
      return React.cloneElement(child, {
        index: index,
        person: person,
        onCancelAddPerson: this.props.onCancelAddPerson,
        removePersonUI: this.props.removePersonUI,
        updatePersonsUI: this.props.updatePersonsUI,
        handleCancelEditPersonOnMeetingForm: this
          .handleCancelEditPersonOnMeetingForm,
        disableRemovePerson: true
      })
    })
    return children
  }

  render() {
    console.log('render section-contact-person', this.state, this.props)
    const { persons, selected_person } = this.state
    const findNewPerson = persons.find((v) => v.isNew) || []

    return (
      <ContactPersonStyled>
        <div className={'SectionContactPerson'}>
          {!this.props.hideLabel && (
            <div>
              <CrmLabeledField lg noMargin>
                <div
                  onClick={(e) => {
                    this.props.handleEditStart(e)
                  }}
                  className={'util-hooverableContent util-flexRow'}
                >
                  {selected_person && (
                    <div>
                      {this._renderSelectedPersonLabel(selected_person)}
                    </div>
                  )}
                  {!selected_person && (
                    <div>
                      {this.props.intl.formatMessage({
                        id: 'crm.activity.select_contact_person',
                        defaultMessage: 'select contact person'
                      })}
                    </div>
                  )}
                </div>
              </CrmLabeledField>
            </div>
          )}

          {this.props.isExpanded && (
            <React.Fragment>
              <div className={'PersonItemsWrapper util-marginTop'}>
                {persons.map((person, index) => {
                  const key = person.account_person_uid || person.newObjectUid
                  const isSelectedItem =
                    person.account_person_uid ===
                    selected_person?.account_person_uid
                  const selectedItemClass = isSelectedItem ? 'SelectedItem' : ''
                  const selectedIconClass = isSelectedItem
                    ? 'PersonItem__SelectedIcon'
                    : 'PersonItem__NoSelectedIcon'
                  const personEmails = this.getPersonEmails(person)

                  if (person.isNew || person.isEditing) {
                    return (
                      <div key={key}>
                        <div className={'PersonFormHeader'}>
                          {person.isNew &&
                            this.props.intl.formatMessage({
                              id: 'crm.activity.add_new_person',
                              defaultMessage: 'Add new person'
                            })}
                          {!person.isNew &&
                            this.props.intl.formatMessage({
                              id: 'crm.activity.edit_person',
                              defaultMessage: 'Edit person'
                            })}
                        </div>
                        {this._renderConactPersonForm(index, person)}
                        {/* <ContactPersonItem
                          index={index}
                          person={person}
                          onCancelAddPerson={this.props.onCancelAddPerson}
                          removePersonUI={this.props.removePersonUI}
                          updatePersonsUI={this.props.updatePersonsUI}
                          handleCancelEditPersonOnMeetingForm={
                            this.handleCancelEditPersonOnMeetingForm
                          }
                          disableRemovePerson={true}
                        /> */}
                      </div>
                    )
                  } else {
                    if (findNewPerson.length === 0) {
                      return (
                        <div
                          key={key}
                          className={`ActivityListItem-headerItem-value ${'PersonItem'} ${selectedItemClass}`}
                        >
                          <div className={`${'PersonItem__ContentWrapper'}`}>
                            <div className={`${'PersonItem__Content'}`}>
                              <div className={`${'Department'}`}>
                                {person.department}
                              </div>
                              <div className={`${'PersonName'}`}>
                                {person.name}
                              </div>
                              {personEmails.length > 0 && (
                                <div>
                                  {personEmails.map((v) => {
                                    return (
                                      <div key={v.contact_details_uid}>
                                        <i className="fa fa-envelope-o" />
                                        <span className="util-marginLeftSm">
                                          {v.value}
                                        </span>
                                      </div>
                                    )
                                  })}
                                </div>
                              )}
                            </div>
                            <div className={`${'PersonItem__ContentAction'}`}>
                              <React.Fragment>
                                <div
                                  className={'PersonItem__EditIcon'}
                                  title={this.props.intl.formatMessage({
                                    id: 'crm.activity.click_to_edit_person',
                                    defaultMessage: 'Click to edit person'
                                  })}
                                  onClick={(e) => this.handleEditPerson(person)}
                                >
                                  <FontAwesomeIcon
                                    className="util-marginLeft"
                                    icon={faPencilAlt}
                                  />
                                </div>

                                <div
                                  className={selectedIconClass}
                                  onClick={(e) =>
                                    this.handleSelectPerson(e, person, true)
                                  }
                                  title={this.props.intl.formatMessage({
                                    id: 'crm.activity.click_to_select_person',
                                    defaultMessage: 'Click to select person'
                                  })}
                                >
                                  <FontAwesomeIcon
                                    className="util-marginLeft"
                                    icon={faCheckCircle}
                                  />
                                </div>
                              </React.Fragment>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  }
                })}

                {findNewPerson.length === 0 && (
                  <div className={`${'PersonItem'}`}>
                    <div className={`${'AddNewPersonIcon'}`}>
                      <div
                        onClick={() => {
                          this.handleStartAddPerson()
                        }}
                      >
                        <FontAwesomeIcon
                          title={this.props.intl.formatMessage({
                            id: 'crm.activity.click_to_add_person',
                            defaultMessage: 'Click to add person'
                          })}
                          className="util-hooverable util-pointer"
                          icon={faUserPlus}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="util-flexRowRight util-marginTop">
                <CrmButton
                  grouped
                  label={this.props.intl.formatMessage({
                    id: 'crm.ui.button.close',
                    defaultMessage: 'Close'
                  })}
                  small
                  onClick={this.handleClose}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </ContactPersonStyled>
    )
  }
}

export default injectIntl(SectionContactPerson)
