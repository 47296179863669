import { buildCrmReducer } from 'crm-utils/reducer-utils'

// Device status codes
// idle
// unavailable

// Initial
const initialState = {
  currentCall: undefined,
  sipStatusCode: 'unavailable',
  agentStatusCode: 'offline',
  agentStatusName: 'Offline',
  sip: undefined,
  isLoadScript: false,
  callStartTime: undefined,
  callEndTime: undefined
}

// Actions
export const actions = {
  agentStatusChange: {},
  originateCall: {},
  originateCallSuccess: {},
  callStatusChanged: {},
  callHangUp: {},
  callStatusUpdated: {},
  loadScriptSuccess: {
    updater: () => ({
      isLoadScript: true
    })
  },
  sipStatusUpdated: {
    updater: action => ({
      sipStatusCode: action.payload.sipStatusCode
    })
  },
  agentStatusUpdated: {
    updater: action => ({
      agentStatusCode: action.payload.agentStatusCode,
      agentStatusName: action.payload.agentStatusName
    })
  },
  callDetails: {
    updater: action => {
      const callData = {}
      if (
        Object.prototype.hasOwnProperty.call(action.payload, 'call_start_time')
      )
        callData.callStartTime = action.payload.call_start_time
      if (Object.prototype.hasOwnProperty.call(action.payload, 'call_end_time'))
        callData.callEndTime = action.payload.call_end_time

      return callData
    }
  },
  dialerClientEventReceived: {}
}

function phoneReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

// Selectors
export const getCurrentCall = state => state.crm.phone.currentCall
export const getCurrentAgentStatusName = state =>
  state.crm.phone.agentStatusCode
export const getCurrentAgentStatusCode = state =>
  state.crm.phone.agentStatusName
export const getSipStatusCode = state => state.crm.phone.sipStatusCode
export const getCallStartTime = state => state.crm.phone.callStartTime
export const getCallEndTime = state => state.crm.phone.callEndTime
export const getIsLoadScript = state => state.crm.phone.isLoadScript

const reducer = buildCrmReducer(phoneReducer, actions)
export default reducer
