import React from 'react'
import { CrmFullpageContainer, MainDashboard } from '@cartrack-crm/ui'
import { AnalyticsContextProvider } from 'crm-core/analytics/context'
import { CrmFilterContextProvider } from '../dashboards/dashboard-context/crm-filter-context'
import useCrmCurrentUser from '../../../core/auth/hooks/use-crm-current-user'

export const MainPage = () => {
  const user = useCrmCurrentUser()
  return (
    <AnalyticsContextProvider>
      <CrmFilterContextProvider>
        <CrmFullpageContainer>
          <MainDashboard
            currentUser={user.name}
            linkDashboard="/crm/dashboard/details"
            linkTask="/crm/dashboard/work"
          />
        </CrmFullpageContainer>
      </CrmFilterContextProvider>
    </AnalyticsContextProvider>
  )
}
