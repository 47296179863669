import React from 'react'
import { string } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import GenericDropdown from '../../generic-dropdown.jsx'

const PersonSelect = props => {
  const variables = {}
  const qlq = gql`
    { accounts(account_uid:"${props.accountUid}") { name account_uid persons {account_person_uid name description kind is_valid} } }
  `
  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.accounts
            ? data.accounts.map(v => ({
                ...v,
                value: v.account_uid
              }))
            : []
        return (
          <GenericDropdown
            {...props}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_person"
                  defaultMessage="Select Person"
                />
              )
            }
            options={options}
          />
        )
      }}
    </Query>
  )
}

PersonSelect.propTypes = {
  accountUid: string.isRequired,
  placeholder: string
}

export default PersonSelect
