import React from 'react'
import { string, func, bool, shape } from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import GenericDropdown from '../../generic-dropdown.jsx'

const CrmProductsSelect = props => {
  const variables = {}
  const qlq = gql`
    {
      products {
        name
        product_uid
        is_addon
        kind
        is_active
      }
    }
  `
  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data && data.products
            ? data.products.reduce((ret, v) => {
                let match = false
                if (props.showAddOn === true && v.is_addon === true) {
                  match = true
                }
                if (props.showProduct === true && v.is_addon !== true) {
                  match = true
                }
                if (match) {
                  ret.push({
                    ...v,
                    value: v.product_uid
                  })
                }
                return ret
              }, [])
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? (
                props.placeholder
              ) : (
                <FormattedMessage
                  id="crm.ui.dropdown.select_product"
                  defaultMessage="Select Product"
                />
              )
            }
          />
        )
      }}
    </Query>
  )
}

CrmProductsSelect.propTypes = {
  placeholder: string,
  onChange: func.isRequired,
  showAddOn: bool,
  showProduct: bool,
  input: shape({})
}

CrmProductsSelect.defaultProps = {
  showAddOn: true,
  showProduct: true
}

export default CrmProductsSelect
