import reportAPI from 'crm-api/settings/reports-api'
import {
  FETCH_REPORTS_SETTING,
  RECEIVE_REPORTS_SETTING,
  SAVE_EDITING_REPORT,
  CANCEL_EDITING_REPORT,
  CANCEL_CREATING_REPORT,
  SAVE_CREATING_REPORT
} from 'crm-duxs/settings/reports-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchReports() {
  const reports = yield call(reportAPI.fetch)
  yield put({ type: RECEIVE_REPORTS_SETTING, payload: { reports } })
}

function* createReport(action) {
  try {
    yield call(reportAPI.create, action.formValues)
    yield put({ type: CANCEL_CREATING_REPORT })
    yield put({ type: FETCH_REPORTS_SETTING })
  } catch (err) {}
}

function* updateReport(action) {
  try {
    yield call(reportAPI.update, action.formValues)
    yield put({ type: CANCEL_EDITING_REPORT })
    yield put({ type: FETCH_REPORTS_SETTING })
  } catch (err) {}
}

function* settingReportSaga() {
  yield takeLatest(FETCH_REPORTS_SETTING, fetchReports)
  yield takeLatest(SAVE_CREATING_REPORT, createReport)
  yield takeLatest(SAVE_EDITING_REPORT, updateReport)
}

export default settingReportSaga
