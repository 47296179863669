import moment from 'moment'

export const isTaskOverdue = task =>
  task && moment().isAfter(task.task_due_date)

export const getTrProps = (state, rowInfo) => {
  if (rowInfo === undefined) {
    return {}
  }
  const isOverdue = rowInfo.original && isTaskOverdue(rowInfo.original)
  const rowClassName = isOverdue ? `util-textRed` : ''
  return {
    className: rowClassName
  }
}

export const mock = 1
