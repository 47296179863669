import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { PureComponent } from 'react'
import { shape, string, arrayOf, func, bool } from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import AddressItem from './address-item.jsx'
import sectionAddressesHoc from 'crm-modules/accounts/hoc/section-addresses-hoc.js'

class SectionAddresses extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleStartAddAddress = () => {
    if (this.props.isAddingNewAddress()) {
      const elmnt = document.getElementById('div-address-item-0')
      elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return
    }
    this.props.onStartAddAddress()
  }

  render() {
    const { account_uid, addresses } = this.props
    // const addresses = this.props.addresses.filter(v => v.address_uid)

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-1">
            <span className="AccountLeftSide-addButton">
              {/* title={this.props.intl.formatMessage({
                id: 'crm.account.click_to_add_new_address',
                defaultMessage: 'Click to add new address'
              })} */}
              <FontAwesomeIcon
                className="util-hooverable util-pointer"
                icon="plus"
                onClick={this.handleStartAddAddress}
              />
            </span>
          </div>
          <div className="col-md-11">
            {addresses.length === 0 && (
              <div className="util-textLight util-textCenter">
                <small>
                  <FormattedMessage
                    id="crm.account.no_address_for_this_account_click_icon_on_the_left_side_to_add_new_address"
                    defaultMessage="No address for this account. Click icon on the left side to
                  add new address."
                  />
                </small>
              </div>
            )}
            {addresses.map((address, index) => {
              const key = address.address_uid || address.newObjectUid
              return (
                <div id={'div-address-item-' + index} key={key}>
                  <AddressItem
                    key={key}
                    index={index}
                    account_uid={account_uid}
                    address={address}
                    onCancelAddAddress={this.props.onCancelAddAddress}
                    hasPrimaryAddress={this.props.hasPrimaryAddress}
                    removeAddressUI={this.props.removeAddressUI}
                    updateAddressesUI={this.props.updateAddressesUI}
                    mapAlign="top"
                  />
                  {/* <hr className="util-horizontalLine" /> */}
                </div>
              )
            })}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

SectionAddresses.propTypes = {
  onCancelAddAddress: func.isRequired,
  removeAddressUI: func.isRequired,
  updateAddressesUI: func.isRequired,
  isAddingNewAddress: func.isRequired,
  onStartAddAddress: func.isRequired,
  addresses: arrayOf(shape()).isRequired,
  hasPrimaryAddress: shape({}),
  account_uid: string.isRequired
}

SectionAddresses.defaultProp = {}

export default injectIntl(sectionAddressesHoc(SectionAddresses))
