/* eslint-disable camelcase */
import { buildCrmReducer } from 'crm-utils/reducer-utils'

// Initial
const initialState = {
  activity: undefined,
  isEditing: false,
  isSaving: false,
  // Here we hold list of available statuses - either for new or completed activity
  availableStatuses: [],
  opportunityTypes: [],
  opportunities: [],
  searchTxt: '',
  ownerUserUid: undefined
}

// Actions
export const actions = {
  searchTextChange: {
    handler: (state, action) => ({ ...state, searchTxt: action.payload })
  },
  setOwnerUserUid: {
    updater: action => ({
      ownerUserUid: action.payload.ownerUserUid
    })
  }
}

function opportunitiesReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

// Selectors
export const getOpportunities = state => state.crm.opportunities.opportunities
export const getOpportunityTypes = state =>
  state.crm.opportunities.opportunityTypes
export const getOpportunitySearchText = state =>
  state.crm.opportunities.searchTxt
export const getOwnerUserUid = state => state.crm.opportunities.ownerUserUid
const reducer = buildCrmReducer(opportunitiesReducer, actions)
export default reducer
