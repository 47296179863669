/* eslint-disable react/jsx-no-bind */

import React from 'react'
import { arrayOf, shape, string, func, bool, any } from 'prop-types'
import { NavLink, Link } from 'react-router-dom'
import { Motion, spring, presets } from 'react-motion'
import { injectIntl, FormattedMessage } from 'react-intl'
import ProfileDropdownButton from './profile-dropdown-button.jsx'
import Dropdown from 'util-components/dropdown.jsx'
import Icon from 'util-components/icon.jsx'

const Navbar = props => {
  let selectedTab
  for (const tab of props.tabs) {
    if (tab.path && tab.path === props.currentPath.slice(0, tab.path.length)) {
      selectedTab = {
        text: tab.text,
        icon: tab.icon,
        meta: tab.meta,
        path: tab.path,
        withSubSubNav: tab.withSubSubNav
      }
      break
    }

    if (!tab.subTabs) {
      continue
    }

    const subTabs = tab.subTabs.filter(s => s)

    const selectedSubTab = subTabs.find(
      subTab =>
        subTab.path === props.currentPath.slice(0, subTab.path.length) ||
        (subTab.aliases &&
          subTab.aliases.find(a => a === props.currentPath.slice(0, a.length)))
    )
    if (selectedSubTab) {
      selectedTab = {
        subTabs,
        selectedSubTab,
        text: tab.text,
        icon: tab.icon,
        meta: tab.meta,
        withSubSubNav: tab.withSubSubNav
      }
      break
    }
  }

  if (!selectedTab) {
    console.error(
      `The path "${props.currentPath}" does not match any paths on your Navbar configuration`
    )
    return null
  }

  return (
    <div
      className={`Navbar ${selectedTab.withSubSubNav
        ? 'Navbar--withSubSubNav'
        : ''}`}
    >
      <div className="Navbar-outerNav">
        <Link to="/map/vehicles">
          <img
            className="Navbar-logo"
            src={require('../../../../assets/icons/logo-white.png')}
          />
        </Link>
        <div className="Navbar-outerNav-tabs">
          {props.tabs.map(({ path, subTabs, text, icon, meta }) => {
            if (meta) return null
            const defaultPath = path || subTabs[0].path
            return (
              <NavLink
                key={defaultPath}
                activeClassName="is-active"
                to={defaultPath}
                className="Navbar-outerNav-tab"
                isActive={() =>
                  Array.isArray(subTabs)
                    ? subTabs.indexOf(selectedTab.selectedSubTab) !== -1
                    : path === selectedTab.path}
              >
                <Icon name={icon} />
                <br />
                {props.intl.formatMessage({
                  id: `navbar.tab.${text}`,
                  defaultMessage: text
                })}
              </NavLink>
            )
          })}
        </div>
        <Dropdown
          title={<ProfileDropdownButton user={props.user} />}
          options={[
            {
              name: 'settings',
              element: (
                <div className="util-fullWidthChild">
                  <NavLink to="/profile-settings">
                    <FormattedMessage
                      id="app.settings"
                      defaultMessage="Settings"
                    />
                  </NavLink>
                </div>
              )
            },
            {
              name: 'logout',
              element: (
                <div className="util-fullWidthChild" onClick={props.onLogout}>
                  <FormattedMessage id="app.logout" defaultMessage="Log out" />
                </div>
              )
            }
          ]}
          extraClassNames={{
            containerClassNames: `ProfileDropdownButton--container ${selectedTab.meta
              ? 'is-active'
              : ''}`
          }}
        />
        <NavLink to="/alerts/current">
          <i className="fa fa-bell Navbar-faIconLink" aria-hidden="true" />
        </NavLink>
        {/* <i className="fa fa-envelope Navbar-faIconLink" aria-hidden="true" /> */}
      </div>
      {selectedTab.subTabs && (
        <div className="Navbar-innerNav">
          {selectedTab.subTabs.map(subTab => (
            <NavLink
              key={subTab.path}
              activeClassName="is-active"
              exact
              to={subTab.path}
              className="Navbar-innerNav-tab"
              isActive={() => subTab === selectedTab.selectedSubTab}
            >
              {props.intl.formatMessage({
                id: `navbar.tab.${subTab.text}`,
                defaultMessage: subTab.text
              })}
            </NavLink>
          ))}
          <Motion
            defaultStyle={{ left: 0 }}
            style={{
              left: spring(
                14 +
                  selectedTab.subTabs.indexOf(selectedTab.selectedSubTab) * 125,
                presets.gentle
              )
            }}
          >
            {lerpStyle => (
              <div className="Navbar-activeUnderline" style={lerpStyle} />
            )}
          </Motion>
        </div>
      )}
    </div>
  )
}

Navbar.propTypes = {
  user: any.isRequired, // eslint-disable-line
  tabs: arrayOf(
    shape({
      meta: bool,
      text: string.isRequired,
      icon: string.isRequired,
      path: string,
      subTabs: arrayOf(
        shape({
          path: string.isRequired,
          text: string.isRequired
        })
      )
    })
  ).isRequired,
  currentPath: string.isRequired,
  onLogout: func.isRequired
}

export default injectIntl(Navbar)
