import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { shape, string, func } from 'prop-types'
import {
  CrmTextInput,
  CrmFormSection,
  CrmButton,
  CrmValidatedField
} from 'crm-components'

// eslint-disable-next-line
class FleetLoginForm extends Component {
  render() {
    const { handleSubmit } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <CrmFormSection sectionName="Fleet Login">
          <fieldset className="Form-fieldset CrmForm-form-fieldset">
            <div className="row">
              {this.props.fleetToken && (
                <div>
                  <p>
                    <i
                      className="fa fa-check-circle-o"
                      style={{ color: 'green' }}
                      aria-hidden="true"
                    />{' '}
                    Fleet Connected
                  </p>
                  <CrmButton
                    label="disconnect"
                    // eslint-disable-next-line
                    onClick={this.props.fleetDisconnect}
                  />
                </div>
              )}
              {!this.props.fleetToken && (
                <div className="col-xs-12 col-md-12">
                  <CrmValidatedField
                    component={CrmTextInput}
                    componentProps={{
                      placeholder: 'Fleet Username'
                    }}
                    name="username"
                  />

                  <CrmValidatedField
                    component={CrmTextInput}
                    componentProps={{
                      placeholder: 'Fleet Password'
                    }}
                    type="password"
                    name="password"
                  />

                  <CrmButton label="Connect Fleet App" type="submit" />
                </div>
              )}
            </div>
          </fieldset>
        </CrmFormSection>
      </form>
    )
  }
}

FleetLoginForm.propTypes = {
  handleSubmit: shape({}).isRequired,
  fleetToken: string.isRequired,
  fleetDisconnect: func.isRequired
}

const FleetLoginFormRedux = reduxForm({
  form: 'crmFleetLogin',
  enableReinitialize: false
})(FleetLoginForm)

export default FleetLoginFormRedux
