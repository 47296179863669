export const allOpportunities = {
  type: 'analitics_widget',
  code: 'allOpportunities',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: { is_deleted: false },
          fields: ['opportunity_uid'],
          aggregate: [{ id: 'opportunity_uid', type: 'count' }]
        }
      ],
      aggregates: {
        countAll: {
          type: 'count',
          dataSource: 'OpportunitiesListQM',
          valueField: 'opportunity_uid'
        }
      }
    },
    layout: {
      items: [
        {
          type: 'labeledField',
          props: {
            value: {
              type: 'aggregates',
              dataField: 'countAll'
            }
          }
        }
      ]
    }
  }
}

export const overdueOpportunities = {
  type: 'analitics_widget',
  code: 'overdueOpportunities',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: {
            opportunity_is_deleted: false,
            is_overdue: true,
            status_code: 'active',
            is_deleted: false
          },
          fields: ['opportunity_uid'],
          aggregate: [{ id: 'opportunity_uid', type: 'count' }]
        }
      ],
      aggregates: {
        countOverDue: {
          type: 'count',
          dataSource: 'OpportunitiesListQM',
          valueField: 'opportunity_uid'
        }
      }
    },
    layout: {
      items: [
        {
          type: 'labeledField',
          props: {
            value: {
              type: 'aggregates',
              dataField: 'countOverDue'
            }
          }
        }
      ]
    }
  }
}

export const activeOpportunities = {
  type: 'analitics_widget',
  code: 'activeOpportunities',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: {
            opportunity_is_deleted: false,
            status_code: 'active',
            is_deleted: false
          },
          fields: ['opportunity_uid'],
          aggregate: [{ id: 'opportunity_uid', type: 'count' }]
        }
      ],
      aggregates: {
        countActive: {
          type: 'count',
          dataSource: 'OpportunitiesListQM',
          valueField: 'opportunity_uid'
        }
      }
    },
    layout: {
      items: [
        {
          type: 'labeledField',
          props: {
            value: {
              type: 'aggregates',
              dataField: 'countActive'
            }
          }
        }
      ]
    }
  }
}

export const completeOpportunities = {
  type: 'analitics_widget',
  code: 'completeOpportunities',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: {
            opportunity_is_deleted: false,
            status_code: { $ne: 'active' },
            is_deleted: false
          },
          fields: ['opportunity_uid'],
          aggregate: [{ id: 'opportunity_uid', type: 'count' }]
        }
      ],
      aggregates: {
        countComplete: {
          type: 'count',
          dataSource: 'OpportunitiesListQM',
          valueField: 'opportunity_uid'
        }
      }
    },
    layout: {
      items: [
        {
          type: 'labeledField',
          props: {
            value: {
              type: 'aggregates',
              dataField: 'countComplete'
            }
          }
        }
      ]
    }
  }
}

export const wonOpportunities = {
  type: 'analitics_widget',
  code: 'wonOpportunities',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: {
            opportunity_is_deleted: false,
            status_code: 'won',
            is_deleted: false
          },
          fields: ['opportunity_uid'],
          aggregate: [{ id: 'opportunity_uid', type: 'count' }]
        }
      ],
      aggregates: {
        countWon: {
          type: 'count',
          dataSource: 'OpportunitiesListQM',
          valueField: 'opportunity_uid'
        }
      }
    },
    layout: {
      items: [
        {
          type: 'labeledField',
          props: {
            value: {
              type: 'aggregates',
              dataField: 'countWon'
            }
          }
        }
      ]
    }
  }
}

export const lostOpportunities = {
  type: 'analitics_widget',
  code: 'lostOpportunities',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          name: 'OpportunitiesListQM',
          type: 'QM',
          filter: {
            opportunity_is_deleted: false,
            status_code: 'lost',
            is_deleted: false
          },
          fields: ['opportunity_uid'],
          aggregate: [{ id: 'opportunity_uid', type: 'count' }]
        }
      ],
      aggregates: {
        countLost: {
          type: 'count',
          dataSource: 'OpportunitiesListQM',
          valueField: 'opportunity_uid'
        }
      }
    },
    layout: {
      items: [
        {
          type: 'labeledField',
          props: {
            value: {
              type: 'aggregates',
              dataField: 'countLost'
            }
          }
        }
      ]
    }
  }
}
