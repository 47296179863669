import { isProspectCompleted } from './prospect-utils'
import uuidv4 from 'uuid'
import { getAPIDateFormat } from 'crm-utils/time-utils'
import { get } from 'lodash'

/* eslint-disable camelcase */
export function getAccountHqAddress(account) {
  if (account === undefined || account.addresses === undefined) {
    return undefined
  }

  const primary = account.addresses.find(
    addr => addr.address_kind === 'headquarter'
  )

  if (primary) {
    return primary
  }

  return account.addresses.length > 0 ? account.addresses[0] : undefined
}

export function getAccountOwnerUser(account) {
  if (get(account, 'owners.sales', false)) {
    return account.owners.sales
  }

  return undefined
}

export function getAccountOwnerUserUid(account) {
  return get(account, 'status.owner_user.user_uid', undefined)
}

export function getAvatarUrl(file) {
  let fileName = file
  if (!fileName) fileName = 'no-avatar.png'

  const resourceURL = process.env.resourceURL
    ? `${process.env.resourceURL}/${fileName}`
    : `https://crm.cartrack.sg/resources/users/${fileName}`

  return resourceURL
}

export function getAccountStatus(account) {
  if (!account) {
    return undefined
  }

  if (account.status) {
    return account.status.account_status_code
  }

  if (get(account, 'is_deleted', false)) {
    return 'deleted'
  }

  if (account.kind === 'customer') {
    return 'customer'
  }

  if (get(account, 'qualification.qualification_type', false)) {
    return 'lost'
  }

  if (get(account, 'opportunities', []).length > 0) {
    return 'opportunity'
  }

  return account.kind
}

export function getAccountQualificationType(account) {
  return get(account, 'qualification.qualification_type', undefined)
}

export function getContactDetailByType(target, kind) {
  if (get(target, 'contact_details', false)) {
    return target.contact_details.find(cd => cd.kind === kind)
  }

  return undefined
}

export function initNewPersonContactDetail(kind) {
  return {
    kind: kind,
    value: '',
    contact_details_uid: uuidv4(),
    __typename: 'NewPersonContactDetail'
  }
}

export function initNewPerson() {
  return {
    kind: 'individual',
    name: '',
    description: '',
    department: '',
    contact_details: [
      initNewPersonContactDetail('email'),
      initNewPersonContactDetail('mobile'),
      initNewPersonContactDetail('phone')
    ],
    __typename: 'NewContactPerson'
  }
}

export function initNewCompanyContactDetail(kind) {
  return {
    kind: kind,
    value: '',
    contact_details_uid: uuidv4(),
    __typename: 'NewCompanyContactDetail'
  }
}

export function initAddressLocation() {
  return {
    lat: '',
    lng: '',
    __typename: 'GeocodeResult'
  }
}

export function initNewAddress(account_uid) {
  return {
    newObjectUid: uuidv4(),
    isNew: true,
    account_uid: account_uid,
    city: '',
    street: '',
    province: '',
    suburb: '',
    is_primary: false,
    is_valid: true,
    street_number: '',
    house_number: '',
    postcode: '',
    country_code: '',
    address_kind: 'branch',
    town: '',
    remark: '',
    location: initAddressLocation(),
    line1: '',
    line2: '',
    __typename: 'NewAddress'
  }
}

export function initNewAccount() {
  return {
    account_uid: 'new',
    name: '',
    is_assign_to_me: true,
    lead_in_date: getAPIDateFormat(new Date()),
    isNew: true,
    cams_username: '',
    campaign_uid: '',
    website: '',
    industry_uid: '',
    remark: '',
    registration_number: '',
    addresses: [initNewAddress('new')],
    contact_details: [
      initNewCompanyContactDetail('phone'),
      initNewCompanyContactDetail('email')
    ],
    persons: [initNewPerson()]
  }
}

export function canStartNewProspect(account) {
  if (!account) {
    return false
  }

  if (account && !account.processes) {
    return false
  }

  if (get(account, 'sales_status.code', '') === 'customer') {
    return false
  }

  return get(account, 'processes', [])
    .filter(p =>
      ['prospect', 'dialer'].includes(get(p, 'process_type.type', ''))
    )
    .reduce((a, p) => a && isProspectCompleted(p), true)
}

export function haveDialerProcess(account) {
  if (!account || !account.processes) {
    return false
  }

  const pr = account.processes.find(
    pr => pr.process_type && pr.process_type.kind === 'dialer'
  )

  return pr && pr.length > 0
}

export function getDialerProcess(account) {
  if (!account || !account.processes) {
    return false
  }

  return account.processes.find(
    pr => get(pr, 'process_type.kind', '') === 'dialer'
  )
}

export function accountLink(account) {
  return `/crm/account/${account.account_uid}`
}

export function findMainProcess(account) {
  if (!account) {
    return
  }

  if (
    get(account, 'currentSalesProcess.process_uid', false) &&
    get(account, 'processes', false)
  ) {
    return account.processes.find(
      p => p.process_uid === account.currentSalesProcess.process_uid
    )
  }

  return undefined
}

export function findLastSalesProcess(account) {
  if (
    get(account, 'lastSalesProcess.process_uid', false) &&
    get(account, 'processes', false)
  ) {
    return account.processes.find(
      p => p.process_uid === account.lastSalesProcess.process_uid
    )
  }

  return undefined
}

export const buildDeleteAccountCommand = (accountUid, isDelete) => {
  const command = {
    type: isDelete ? 'account.delete' : 'account.undelete',
    aggregate_uid: accountUid,
    aggregate_type: 'account',
    payload: {}
  }

  return command
}

export const buildPrioritizeAccountCommand = (accountUid, newPriority = 11) => {
  const command = {
    type: 'salesAccount.prioritize',
    aggregate_uid: accountUid,
    aggregate_type: 'salesAccount',
    payload: {
      newPriority
    }
  }

  return command
}

export const mergeAccountCommand = (
  sourceAccountUid,
  destinationAccountUid
) => {
  const command = {
    type: 'account.mergeTo',
    aggregate_uid: sourceAccountUid,
    aggregate_type: 'account',
    payload: {
      destinationAccountUid: destinationAccountUid
    }
  }

  return command
}

export const transactionTypeOptions = [
  { value: 'B2B', name: 'B2B' },
  { value: 'B2C', name: 'B2C' }
]
