import React from 'react'
import { func, object, string } from 'prop-types'
import { FiltersOptionList } from '../tools'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { get } from 'lodash'

const qlqListDictionaryValues = gql`
  query dictionary_values {
    dictionary_values(code: "crm.user_departments") {
      dictionary_value_uid
      name
    }
  }
`

const DepartmentTypeSelect = props => {
  const getSelectedValue = () =>
    props.filterGroupTypeName === 'organization_unit'
      ? get(props, 'filterValues.organization_unit.department_type_uid.value', undefined)
      : get(props, 'filterValues.department_uid.value', undefined)

  return (
    <Query query={qlqListDictionaryValues}>
      {({ loading, data, refetch }) => {
        const departments = get(data, 'dictionary_values', false) ? [...data.dictionary_values] : []

        if (departments) {
          departments.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
        }

        const usersOptions = departments
          ? departments.map(t => ({
              value: t.dictionary_value_uid,
              name: t.name,
              label: t.name
            }))
          : []

        return (
          <FiltersOptionList
            enableSearch={true}
            isLoading={loading}
            options={usersOptions}
            onFilterValueChanged={props.onFilterValueChanged}
            selectedValue={getSelectedValue()}
          />
        )
      }}
    </Query>
  )
}

DepartmentTypeSelect.propTypes = {
  onFilterValueChanged: func.isRequired,
  filterValues: object,
  filterGroupTypeName: string
}

DepartmentTypeSelect.defaultProps = {
  filterValues: undefined,
  filterGroupTypeName: undefined
}

export default DepartmentTypeSelect
