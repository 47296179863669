import React, { PureComponent } from 'react'
import { node } from 'prop-types'

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(err) {
    console.log('getDerivedStateFromError', err)
    return <div> There was some problem creating this view </div>
  }
  componentDidCatch(error, info) {
    console.log('componentDidCatch')
    console.dir(error)
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    console.dir(error, info)
    // LlogErrorToMyService(error, info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <span>Something went wrong.</span>
    }
    return this.props.children
  }
}
ErrorBoundary.propTypes = {
  children: node
}

ErrorBoundary.defaultProps = {
  children: undefined
}
export default ErrorBoundary
