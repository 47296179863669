// Actions for attribute
export const FETCH_ATTRIBUTES = 'FETCH_ATTRIBUTES'
export const RECEIVE_ATTRIBUTES = 'RECEIVE_ATTRIBUTES'
export const FETCH_ATTRIBUTE = 'FETCH_ATTRIBUTE'
export const RECEIVE_ATTRIBUTE = 'RECEIVE_ATTRIBUTE'
export const SELECTED_ATTRIBUTE = 'SELECTED_ATTRIBUTE'
export const SAVE_EDITING_ATTRIBUTE = 'SAVE_EDITING_ATTRIBUTE'
export const SAVED_EDITING_ATTRIBUTE = 'SAVED_EDITING_ATTRIBUTE'
export const SAVE_ATTRIBUTE = 'SAVE_ATTRIBUTE'
export const SAVE_ATTRIBUTES = 'SAVE_ATTRIBUTES'

// Reducer
const initialState = {
  lists: [],
  fetch: false,
  saving: false,
  selected: undefined,
  deleteValues: []
}

export default function resourceReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ATTRIBUTES:
      return {
        ...state,
        fetch: true
      }
    case RECEIVE_ATTRIBUTES:
      return {
        ...state,
        fetch: false,
        lists: action.payload.attributes
      }
    case FETCH_ATTRIBUTE:
      return {
        ...state,
        fetch: true,
        selected: undefined
      }
    case RECEIVE_ATTRIBUTE:
      return {
        ...state,
        fetch: false,
        selected: action.payload.attribute
      }
    case SELECTED_ATTRIBUTE:
      return {
        ...state,
        selected: action.item
      }
    case SAVE_ATTRIBUTE:
      return {
        ...state,
        saving: true
      }
    // case EDITING_RESOURCE:
    //   return {
    //     ...state,
    //     editing: true,
    //     creating: false,
    //     deleteValues: []
    //   }
    // case CANCEL_EDITING_RESOURCE:
    //   return {
    //     ...state,
    //     editing: false,
    //     selected: undefined
    //   }
    // case CREATING_RESOURCE:
    //   return {
    //     ...state,
    //     creating: true,
    //     editing: false,
    //     selected: undefined,
    //     deleteValues: []
    //   }
    // case CANCEL_CREATING_RESOURCE:
    //   return {
    //     ...state,
    //     creating: false
    //   }
    // case SAVING_RESOURCE:
    //   return {
    //     ...state,
    //     creating: true
    //   }
    // case CREATED_RESOURCE_FOR_ACCOUNT: {
    //   let uploaded = state.uploadedAttachmentForAccount
    //   return {
    //     ...state,
    //     uploadedAttachmentForAccount: ++uploaded
    //   }
    // }
    default:
      return state
  }
}

export function fetchData(params) {
  return {
    type: FETCH_ATTRIBUTES,
    params
  }
}

export function fetchItem(attributeUid) {
  return {
    type: FETCH_ATTRIBUTE,
    attribute_uid: attributeUid
  }
}

export function selectedItem(item) {
  return {
    type: SELECTED_ATTRIBUTE,
    item
  }
}

// export function deleteItem(resourceUid) {
//   return {
//     type: DELETE_RESOURCE_VALUE,
//     resourceUid
//   }
// }

export function saveItem(formValues) {
  return {
    type: SAVE_ATTRIBUTE,
    formValues
  }
}

export function saveItems(formValues) {
  return {
    type: SAVE_ATTRIBUTES,
    formValues
  }
}
