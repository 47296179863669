import React, { SFC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { submit } from 'redux-form'
import { CrmButton } from 'crm-components'
import ResetPasswordForm, {
  resetPasswordFormKey
} from '../../modules/utils/login/crm-reset-password-form'
import { connect } from 'react-redux'
import api from '../../api/crm-login-api'

const CrmResetPasswordForm = props => {
  const [error, setError] = useState({ code: '', message: '' })
  const [isSuccess, setSuccess] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const handleResetPassword = async formData => {
    setSaving(true)
    setError({ code: '', message: '' })
    const { error, result } = await api.doResetPassword(
      formData.login,
      formData.email
    )
    setSaving(false)
    if (result === 'error') {
      setError(error)
    } else {
      setSuccess(true)
      setError({ code: '', message: '' })
    }
  }
  console.log(props)
  return (
    <div className="util-paddingLg">
      <h4 style={{ textAlign: 'center' }}>
        Type in your login and email to reset password
      </h4>
      <div className="util-paddingLg">
        <ResetPasswordForm onSubmit={handleResetPassword} />

        <div className="util-flexCenter util-marginTop">
          {error.code && (
            <React.Fragment>
              <div className="Login-msgfailed">
                <FormattedMessage
                  id={error.code}
                  defaultMessage={error.message}
                />
              </div>
            </React.Fragment>
          )}
        </div>

        {isSuccess && (
          <div className="util-marginTop">
            <p style={{ textAlign: 'center' }}>
              New password has been sent to your email.{' '}
            </p>
            <p style={{ textAlign: 'center' }}>
              Please check your email in 5 minutes.
            </p>
          </div>
        )}
        <div className="util-flexCenter util-marginTop">
          {!isSuccess && (
            <CrmButton
              isSaving={isSaving}
              label="Request new password"
              type="primary"
              onClick={props.doSubmit}
            />
          )}
          {isSuccess && (
            <CrmButton onClick={props.onClose} label="Close" type="primary" />
          )}
        </div>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    doSubmit: () => {
      console.log('resetPasswordFormKey', resetPasswordFormKey)
      return dispatch(submit(resetPasswordFormKey))
    }
  }
}
export default connect(null, mapDispatchToProps)(CrmResetPasswordForm)
