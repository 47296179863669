import { DashBoardWidget } from '../dashboard-widget'
import React from 'react'
import widgetAmountOfCallMadeHoc, {
  widgetAmountOfCallHocProps
} from './amount-of-call-widget-hoc'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetAmountOfCall: React.ComponentType<AnalyticDashboardWidget> = widgetAmountOfCallMadeHoc(
  (props: widgetAmountOfCallHocProps) => (
    <DashBoardWidget
      avgDataPassedMonth={props.avgAmountOfCallThreeMonth}
      avgDataPassedMonthPerPerson={props.avgAmountOfCallPersonPassedMonth}
      avgPerPersonNumber={props.avgAmountOfCallCurrentMonthPerPerson}
      footerName={props.footerName}
      footerRender={props.threeMonthAgoAmountOfCall}
      isLoading={props.isLoading}
      onClickDetail={props.onClickDetail}
      refetch={props.refetchData}
      secondFooterName={props.footerNamePerPerson}
      secondFooterRender={props.avgAmountOfCallPassedMonthData}
      subtitle={props.mainCaption}
      subtitleSecondBlock={'Month date average'}
      title="Amount Of Answered Calls"
      totalNumber={props.totalAmountOfCall}
    />
  )
)
