import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CrmButton } from 'crm-components'

/* eslint-disable */
const ActionButtonForm = props => {
  return (
    <div className="MinuteOfMeeting-group-saveButton">
      <CrmButton
        type="button"
        label={
          <FormattedMessage id="crm.ui.button.save" defaultMessage="Save" />
        }
        small
        onClick={() => props.onSave()}
      />
      <CrmButton
        type="button"
        label={
          <FormattedMessage id="crm.ui.button.cancel" defaultMessage="Cancel" />
        }
        small
        className="MinuteOfMeeting-cancelButton"
        onClick={() => {
          props.onCancel(props.itemValue, props.index)
        }}
      />
    </div>
  )
}

export default ActionButtonForm
