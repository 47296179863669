import React, { PureComponent } from 'react'
import { shape, func, string, bool, arrayOf, node } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import qualifyButtonQlHoc from './qualify-button-ql-hoc'
import { getAccountQualificationType } from 'crm-utils/accounts-utils'
import { CrmButton, CrmDropdown } from 'crm-components'

class QualifyButton extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  calculateCallAttempsCount = () =>
    this.props && this.props.account && this.props.account.activities
      ? this.props.account.activities.filter(
          a => a.activity_type && a.activity_type.medium_code === 'phone_call'
        ).length
      : 0

  handleSelectedReasonLost = selectedQualificationUid => {
    const selectedQualificationType = this.props.qualificationTypes.find(
      q => q.qualification_type_uid === selectedQualificationUid
    )
    this.setState({
      selectedQualificationUid,
      selectedQualificationType
    })
  }

  handleSave = async () => {
    const qualificationTypeCode = this.props.selectReason
      ? this.state.selectedQualificationType
        ? this.state.selectedQualificationType.code
        : undefined
      : this.props.qualificationTypeCode
    if (!qualificationTypeCode) {
      toastr.error('Please select account qualification')
      return
    }
    const qualification = {
      account_uid: this.props.account.account_uid, // eslint-disable-line
      qualification_type_code: qualificationTypeCode // eslint-disable-line
    }
    try {
      await this.props.onSave(qualification)
      if (this.props.onCancel) {
        this.props.onCancel()
      }
      toastr.success('Lead Qualification saved!')
    } catch (err) {
      // console.error('Error saving qualification')
      toastr.error('There was problem saving qualification')
    }
  }
  render() {
    const { account, isSaving, isActive } = this.props
    const qualificationType = getAccountQualificationType(account)
    const isAlreadyQualified =
      qualificationType &&
      qualificationType.code === this.props.qualificationTypeCode
    const callAttempts = this.calculateCallAttempsCount()
    const optionsReasonLost = this.props.qualificationTypes
      .filter(qt => qt.is_for_lead)
      .map(qt => ({
        name: qt.name,
        value: qt.qualification_type_uid
      }))
    return (
      <div
        className={`LeadActionsWidget-but 
                LeadActionsWidget-but-md 
                util-hooverable ${
                  this.props.className ? this.props.className : ''
                }`}
        onClick={this.props.onClickDo}
      >
        <div>
          <strong>{this.props.title}</strong>
        </div>
        {isAlreadyQualified && (
          <div>This Account is already qualified as {this.props.title}</div>
        )}
        {!isAlreadyQualified && (
          <div>
            {!isActive &&
              callAttempts !== undefined &&
              this.props.qualificationTypeCode === 'uncontactable' && (
                <div className="LeadActionsWidget-validationItem-value">
                  {callAttempts} / 5
                </div>
              )}
            {!isActive && <small>{this.props.description}</small>}

            {isActive && (
              <div>
                <div>Confirm:</div>
                {this.props.selectReason && (
                  <div className="util-paddingSm">
                    <CrmDropdown
                      label="Reason Lost"
                      options={optionsReasonLost}
                      input={{
                        value: this.state.selectedQualificationUid,
                        onChange: this.handleSelectedReasonLost
                      }}
                    />
                  </div>
                )}
                <div className="util-flexRow">
                  <CrmButton
                    label="Cancel"
                    onClick={this.props.onCancel}
                    grouped
                    small
                  />
                  <CrmButton
                    label={isSaving ? 'Saving' : 'Save'}
                    onClick={this.handleSave}
                    type="primary"
                    grouped
                    small
                    enable={!isSaving}
                    isSaving={this.props.isSaving}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

QualifyButton.propTypes = {
  account: shape({}).isRequired,
  onCancel: func.isRequired,
  onSave: func.isRequired,
  onClickDo: func.isRequired,
  title: node.isRequired,
  description: node.isRequired,
  qualificationTypeCode: string.isRequired,
  selectReason: bool,
  qualificationTypes: arrayOf(shape()),
  className: string,
  isActive: bool.isRequired,
  isSaving: bool.isRequired
}

QualifyButton.defaultProps = {
  callAttempts: undefined,
  selectReason: false,
  qualificationTypes: [],
  className: undefined
}

export default qualifyButtonQlHoc(QualifyButton)
