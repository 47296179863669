/*eslint-disable */
/* eslint-disable camelcase */
import uuidv4 from 'uuid/v4'
import { createSelector } from 'reselect'
import { buildCrmReducer } from 'crm-utils/reducer-utils'

// Initial
const initialState = {
  document: undefined,
  isEditing: false,
  isSaving: false
}

// Actions
export const actions = {
  startDocumentEdit: {},
  setEditedDocument: {
    updater: action => {
      return {
        isEditing: true,
        document: action.payload.document
      }
    }
  },
  endDocumentEdit: {
    updater: action => {
      return { isEditing: false, document: undefined }
    }
  },
  initNewDocument: {
    updater: action => {
      return {
        document: {
          items: [],
          ...(action.payload ? action.payload.document : {})
        }
      }
    }
  },
  createDocument: {},
  updateDocument: {},
  updateDocumentSuccess: {
    handler: (state, action) => {
      // console.log('updateDocumentSuccess reducer')
    }
  },
  createDocumentSuccess: {
    updater: action => {
      // console.log('createDocumentSuccess updater')
      return { document: undefined, isEditing: false, isSaving: false }
    }
  }
}

/**
 * This reducer is used to manage status of currently edited activity
 * Especially it is used to
 *  - create new activity
 *  - edit activity
 *  - perform other activity actions
 *
 * As we keep all the status inside this reducer (instead of some view) - there can be only ONE currently edited activity
 */

function functionDocumentReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

// Selectors
export const getDocument = state => state.crm.document.document
export const getIsEditing = state => state.crm.document.isEditing
export const getIsSaving = state => state.crm.document.isSaving

const reducer = buildCrmReducer(functionDocumentReducer, actions)
export default reducer
