/*
Sample form schema:
{
  username: { type: text, iconName: '', placeholder: '', validation: validationFunc, extraClassNames: { containerClassNames = '', inputClassNames = '', placeholderClassNames = '' } },
  password: { type: password, iconName: '', placeholder: '', validation: validationFunc, extraClassNames: { containerClassNames = '', inputClassNames = '', placeholderClassNames = '' } },
}

We should extend this schema as needed to take advantage of all the redux-form features.
Docs URL: http://redux-form.com/6.8.0/docs/api/Field.md/
*/
import React from 'react'
import { func, string, bool } from 'prop-types'
import { map } from 'lodash'
import { Field, Fields, FormSection, reduxForm, formValues } from 'redux-form'
import { TextInput } from '@cartrack-crm/ui'
import RadioInputGroup from './radio-input.jsx'
import Checkbox from './checkbox.jsx'
import DatePicker from './date-picker.jsx'
import { ConnectedDropdown } from './dropdown.jsx'
import DateRangeInput from './date-range-input'
import { visibleBySelector } from 'util-components'

function visibleIf(Component, valueKey) {
  const VisibleIf = props => (props[valueKey] ? <Component {...props} /> : null)

  VisibleIf.defaultProps = {
    [valueKey]: false
  }

  return formValues(valueKey)(VisibleIf)
}

export function createFields(formName, schema) {
  return map(schema, (field, fieldKey) => {
    let useFields = false
    let Component
    let props
    let validate
    let normalize
    switch (field.type) {
      case 'text':
        Component = TextInput
        props = {
          type: 'text',
          disabled: field.disabled,
          extraClassNames: field.extraClassNames,
          placeholder: field.placeholder,
          iconName: field.iconName,
          focus: field.focus,
          extraProps: field.extraProps,
          required: field.required,
          value: field.default_value ? field.default_value : ''
        }
        validate = field.validate
        normalize = field.normalize
        break
      case 'password':
        Component = TextInput
        props = {
          type: 'password',
          disabled: field.disabled,
          extraClassNames: field.extraClassNames,
          placeholder: field.placeholder,
          iconName: field.iconName,
          extraProps: field.extraProps,
          required: field.required,
          value: field.default_value ? field.default_value : ''
        }
        validate = field.validate
        normalize = field.normalize
        break
      case 'tel':
        Component = TextInput
        props = {
          type: 'tel',
          disabled: field.disabled,
          extraClassNames: field.extraClassNames,
          placeholder: field.placeholder,
          iconName: field.iconName,
          extraProps: field.extraProps,
          required: field.required
        }
        validate = field.validate
        normalize = field.normalize
        break
      case 'radio':
        Component = RadioInputGroup
        props = {
          extraClassNames: field.extraClassNames,
          placeholder: field.placeholder,
          value: field.value,
          label: field.label,
          name: fieldKey,
          options: field.options,
          required: field.required
        }
        validate = field.validate
        break
      case 'dropdown':
        Component = ConnectedDropdown
        props = {
          title: field.placeholder,
          extraClassNames: field.extraClassNames,
          options: field.options,
          disableOptionsIntl: field.disableOptionsIntl,
          required: field.required
        }
        validate = field.validate
        break
      case 'daterange':
        useFields = true
        Component = DateRangeInput
        props = {
          extraClassNames: field.extraClassNames,
          options: field.options,
          title: field.placeholder,
          startFieldName: field.startFieldName,
          endFieldName: field.endFieldName,
          valueFieldName: field.valueFieldName
        }
        break
      case 'checkbox':
        Component = Checkbox
        props = {
          extraClassNames: field.extraClassNames,
          label: field.label,
          name: field.name
        }
        validate = field.validate
        break
      case 'component':
        Component = field.component
        props = field.props
        break
      case 'date':
        Component = DatePicker
        props = {
          type: 'text',
          placeholder: field.placeholder,
          extraClassNames: field.extraClassNames,
          required: field.required,
          ...field.extraProps
        }
        validate = field.validate
        break
      case 'customInput':
        Component = field.component
        props = field.props
        break
      default:
        break
    }

    if (useFields) {
      return (
        <Fields
          key={`${formName}-${fieldKey}`}
          names={field.names}
          component={Component}
          normalize={field.normalize}
          format={field.format}
          props={props}
        />
      )
    }

    Component = field.visibleBySelector
      ? visibleBySelector(Component, field.visibleBySelector)
      : Component
    Component = field.visibleIf
      ? visibleIf(Component, field.visibleIf)
      : Component

    return field.type === 'component' ? (
      <Component key={`${formName}-${fieldKey}`} {...props} />
    ) : (
      <Field
        key={`${formName}-${fieldKey}`}
        name={fieldKey}
        component={Component}
        validate={validate}
        props={props}
        normalize={normalize}
      />
    )
  })
}

function handleKeyDown(e, submit) {
  if (e.key === 'Enter' && e.shiftKey === false) {
    e.preventDefault()
    submit()
  }
}

export default (formName, schema, options = {}) => {
  const fields = createFields(formName, schema)

  const Form = props => {
    const formOptions = {}
    //eslint-disable-next-line
    if (options.hasOwnProperty('submitOnEnter')) {
      formOptions.onKeyDown = e => {
        handleKeyDown(e, props.handleSubmit)
      }
    }

    return (
      <form
        onSubmit={props.handleSubmit}
        className={props.className}
        {...formOptions}
      >
        <fieldset className="Form-fieldset" disabled={props.disabled}>
          <div className={props.containerClassName}>{fields}</div>
        </fieldset>
      </form>
    )
  }

  Form.propTypes = {
    handleSubmit: func.isRequired,
    className: string,
    containerClassName: string,
    disabled: bool
  }

  Form.defaultProps = {
    className: '',
    containerClassName: '',
    disabled: false
  }

  return reduxForm({ form: formName })(Form)
}

// T O D O: Change this and pass custom components in form schema
export const formPartial = (name, schema) => props => {
  const fields = createFields(name, schema)

  const FormPartial = (
    <FormSection name={name}>
      <fieldset className="Form-fieldset" disabled={props.disabled}>
        <div className={props.containerClassName}>{fields}</div>
      </fieldset>
    </FormSection>
  )

  return FormPartial
}
