import React, { SFC } from 'react'
import { FiltersOptionList } from '../tools/filter-option-list'
interface IProcessingStatusSelect {
  onFilterValueChanged: Function
  filterValues: any
}

export const ProcessingStatusSelect: SFC<IProcessingStatusSelect> = (props) => {
  const options = [
    {
      name: 'Never Processed',
      value: 'not_processed',
      qfilter: {
        processing_status: 'not_processed'
      }
    },
    {
      name: 'Lost',
      value: 'lost',
      qfilter: {
        processing_status: 'lost'
      }
    },
    {
      name: 'Customer',
      value: 'customer',
      qfilter: {
        processing_status: 'customer'
      }
    },
    {
      name: 'In process',
      value: 'in_process',
      qfilter: {
        processing_status: 'in_process'
      }
    }
  ]

  return (
    <FiltersOptionList
      options={options}
      onFilterValueChanged={props.onFilterValueChanged}
      selectedValue={
        props.filterValues && props.filterValues.processing_status
          ? props.filterValues.processing_status.value
          : undefined
      }
    />
  )
}