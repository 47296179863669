/* eslint-disable  camelcase */
import React from 'react'
import { graphql, compose } from 'react-apollo'
import { bool } from 'prop-types'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { Icon } from 'util-components'
import { getUser, getSelectedInstance } from 'crm-duxs/crm-reducer'
import crmGenericSelectQLHocFactory from 'crm-components/ql/crm-generic-select-ql-hoc.jsx'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const mapStateToProps = state => ({ instance: getSelectedInstance(state) })

export const listUsersQuery = gql`
  query users(
    $master_uid: String!
    $instance_uid: String!
    $filter: JSON
    $limit: Int
  ) {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        users(filter: $filter, limit: $limit) {
          user_uid
          full_name
          is_active
        }
      }
    }
  }
`

const query = graphql(listUsersQuery, {
  options: ownProps => ({
    variables: {
      master_uid: ownProps.crmContext.master.master_uid,
      instance_uid: ownProps.crmContext.instance.instance_uid,
      limit: ownProps.limit ? ownProps.limit : 1000,
      offset: ownProps.offset ? ownProps.offset : 0,
      filter: ownProps.filter
    }
  }),
  props: ({ listUsersQuery, ownProps }) => ({
    ...ownProps,
    listUsersQuery,
    prefix: 'user_list_qm_paged',
    optionsQl: listUsersQuery,
    options: listUsersQuery?.master?.instance?.users
      ? listUsersQuery.master.instance.users
          .map(item => ({
            ...item,
            name: item.full_name,
            key: item.user_uid,
            value: item.user_uid
          }))
          .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
      : undefined,
    count: listUsersQuery?.master?.instance?.users
      ? listUsersQuery.master.instance.users.length
      : undefined,
    isLoading: listUsersQuery ? listUsersQuery.loading : false
  }),
  name: 'listUsersQuery'
})

const CrmUsersSelectInner = crmGenericSelectQLHocFactory(
  compose(connect(mapStateToProps), query)
)

class CrmUsersSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedMe: false,
      showInactive: false
    }

    this.currentValue = undefined
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.clearSelected !== this.props.clearSelected) {
      if (nextProps.clearSelected === true) {
        this.handleSelectchange('')
      }
    }
  }

  handleShowInactiveUsers = () => {
    this.setState({ showInactive: !this.state.showInactive })
  }

  handleSelectedMe = () => {
    if (this.currentValue !== this.props.currentUser.user_uid)
      this.setState({ selectedMe: !this.state.selectedMe })
  }

  handleSelectchange = value => {
    let selected = false

    if (value === this.props.currentUser.user_uid) {
      selected = true
    }

    this.currentValue = value
    this.setState({ selectedMe: selected })
  }

  toolboxRenderer = () =>
    this.props.disabled ? (
      <span />
    ) : (
      <div>
        <div>
          <Icon
            name="user-circle-o"
            title="Select me"
            style={{
              position: 'absolute',
              top: '10px',
              right: '55px',
              opacity:
                this.state.currentValue === this.props.currentUser ||
                this.state.selectedMe
                  ? '1.0'
                  : '0.4',
              width: '12px'
            }}
            onClick={this.handleSelectedMe}
          />
        </div>
        <div>
          <Icon
            name="trash"
            title="Show inactive users"
            style={{
              position: 'absolute',
              top: '10px',
              right: '32px',
              opacity: this.state.showInactive ? '1.0' : '0.4',
              width: '12px'
            }}
            onClick={this.handleShowInactiveUsers}
          />
        </div>
      </div>
    )

  callOnChange = value => {
    if (this.currentValue !== value) {
      this.currentValue = value
      this.props.input.onChange(value)
    }
  }

  render() {
    const activeUserFilter = this.state.showInactive ? {} : { is_active: true }
    const inputOption = { input: this.props.input }

    if (this.state.selectedMe) {
      inputOption.input.value = this.props.currentUser.user_uid
      this.callOnChange(this.props.currentUser.user_uid)
    }

    const options = { ...inputOption, ...this.props }

    return (
      <CrmUsersSelectInner
        {...options}
        filter={{ ...this.props.filter, ...activeUserFilter }}
        toolboxRenderer={this.toolboxRenderer}
        onChange={this.handleSelectchange}
      />
    )
  }
}

CrmUsersSelect.propTypes = {
  enableFetchMore: bool,
  clearSelected: bool
}

CrmUsersSelect.defaultProps = {
  enableFetchMore: false,
  clearSelected: false
}

function mapStateToPropsUserSelect(state) {
  return {
    currentUser: getUser(state)
  }
}

export default connect(mapStateToPropsUserSelect)(
  withCrmContext(CrmUsersSelect)
)
