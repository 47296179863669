import React, { SFC, useState, useEffect } from 'react'
import { useClickOutside } from 'react-click-outside-hook'
// import { useGeneralPermissionsContext } from '@cartrack-crm/crm-core'
// import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import DownloadButton from './download-button'
import { CrmButton } from '@cartrack-crm/ui'

interface Props {
  showEditWidgetButton?: boolean
  onEditWidget: Function
  onEnableEditMode: Function
  mergedContextToWidgetDefinition: JSON
  dataSourcesVariables: JSON
}

const OptionButton: SFC<Props> = props => {
  const [ref, hasClickedOutside] = useClickOutside()
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false)
  // const permissionsContext: any = useGeneralPermissionsContext()

  // const hasExcelPermission = permissionsContext.hasPermissionByType(
  //   PERMISSIONS_TYPES.EXCEL_EXPORT
  // )

  let hasExcelPermission = false
  const toggleOpen = () => setOpen(!isOpen)

  useEffect(() => {
    if (hasClickedOutside) {
      setOpen(false)
    }
  }, [hasClickedOutside])

  const onDownloadButtonClick = DownloadButton({
    widgetDefinition: props.mergedContextToWidgetDefinition,
    dataSourcesVariables: props.dataSourcesVariables,
    onlyOnClick: true
  })
  const options = []
  if (props.showEditWidgetButton) {
    options.push({
      id: 'edit',
      label: 'Edit Widget',
      icon: 'fa fa-cogs',
      onClick: props.onEditWidget
    })
  }
  // options.push({
  //   id: 'edit-mode',
  //   label: 'Edit DataSources',
  //   icon: 'fa fa-pencil',
  //   onClick: props.onEnableEditMode
  // })
  hasExcelPermission = true
  if (onDownloadButtonClick && hasExcelPermission) {
    options.push({
      id: 'download-excel',
      label: 'Download Excel',
      icon: 'fa fa-download',
      onClick: onDownloadButtonClick
    })
  }

  return (
    <React.Fragment>
      <div ref={ref}>
        <CrmButton
          grouped
          icon={isLoading ? 'circle-notch fa-spin' : 'caret-down'}
          onClick={isLoading ? () => {} : toggleOpen}
          style={{ marginLeft: 10 }}
          disabled={options.length === 0}
        />
        {isOpen && (
          <div className="optionsContainer">
            <ul className="CrmDropdownButton-options" style={{ zIndex: 2, left: -105, top: 5 }}>
              {options.map(option => {
                return (
                  <li
                    className="Dropdown-option CrmDropdownButton-optionItem"
                    key={option.id}
                    onClick={async () => {
                      setOpen(false)
                      setLoading(true)
                      try {
                        await option.onClick()
                      } catch (error) {
                        console.log('Optionbutton onClick Error: ', error)
                      }
                      setLoading(false)
                    }}
                  >
                    <i className={option.icon} />
                    <span style={{ marginLeft: 15 }}>{option.label}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default OptionButton
