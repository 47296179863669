import React, { useState, useEffect } from 'react'
import { useClickOutside } from 'react-click-outside-hook'
import { QL_LIST_ORGANIZATION_UNITS } from './organization-select'
import { useCrmContext } from 'crm-core/contexts/crm-context'
import { CrmTree, CrmTextInput } from 'crm-components'
import { useQuery } from '@apollo/react-hooks'
import { OrganizationUnit } from 'crm-types'

type OrganizationTreeProps = {
  handleSelect: Function
  large?: boolean
  single?: boolean
  organizationStructureUid?: string
  topOrganizationUnitUid?: string
}
const OrganizationTree: React.SFC<OrganizationTreeProps> = (props) => {
  const [active, setActive] = useState(false)
  const [display, setDisplay] = useState('')
  const toggle = () => setActive(!active)
  const [ref, hasClickedOutside] = useClickOutside()
  useEffect(() => {
    if (hasClickedOutside) {
      setActive(false)
    }
  }, [hasClickedOutside])
  const handleSelect = (selected) => {
    if (selected) {
      setDisplay(selected.name)
      if (selected.children.length === 0) {
        setActive(false)
      }
      props.handleSelect(selected)
    }
  }

  return (
    <React.Fragment>
      <div className="util-relative" ref={ref}>
        <CrmTextInput
          input={{
            value: display
          }}
          placeholder="Organization"
          onClick={toggle}
        />
        <div
          className="Dropdown-select"
          style={{ display: active ? 'block' : 'none' }}
        >
          <CrmOrganizationUnitTreeInner
            topOrganizationUnitUid={props.topOrganizationUnitUid}
            {...props}
            handleSelected={handleSelect}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

type FilterProps = {
  department_uid?: string
}

type CrmOrganizationUnitTreeInnerProps = {
  topOrganizationUnitUid?: string
  handleSelected?: (selected: OrganizationUnit) => any
  organizationStructureUid?: string
  selected?: OrganizationUnit
  filter?: FilterProps
}

export const CrmOrganizationUnitTreeInner: React.SFC<CrmOrganizationUnitTreeInnerProps> = (
  props
) => {
  const [selected, setSelect] = useState<OrganizationUnit>()
  const crmContext = useCrmContext()
  let organizationStructureUid = crmContext?.instance?.instance_uid
  if (props.organizationStructureUid) {
    organizationStructureUid = props.organizationStructureUid
  }
  const filter_department_uid = props.filter?.department_uid

  useEffect(() => {
    if (
      selected &&
      filter_department_uid &&
      filter_department_uid !== selected.department_uid
    ) {
      setSelect(undefined)
    }
  }, [props.filter])

  const { data } = useQuery(QL_LIST_ORGANIZATION_UNITS, {
    variables: {
      organizationStructureUid: organizationStructureUid
    },
    fetchPolicy: 'no-cache',
    skip: !organizationStructureUid
  })

  const organizationUnits: OrganizationUnit[] = data?.organizationUnits
    ? [...data.organizationUnits]
    : []

  useEffect(() => {
    const format_selected = props.selected
      ? organizationUnits.filter(
          (v) => v.organization_unit_uid === props.selected.value
        )[0]
      : undefined
    if (format_selected) {
      format_selected.value = format_selected?.organization_unit_uid
    }
    setSelect(format_selected)
  }, [props.selected, organizationUnits])

  const filtered_data: OrganizationUnit[] = organizationUnits.filter((v) => {
    return (
      !filter_department_uid || // no filter, return all
      (filter_department_uid && filter_department_uid === v.department_uid) || // has filter
      organizationUnits.find(
        (v2) => v2.parent_department_uid === v.organization_unit_uid // return all parents to build parent node
      )
    )
  })
  const final_data: OrganizationUnit[] = filtered_data.filter(
    (v) =>
      !filter_department_uid ||
      !v.parent_department_uid ||
      v.department_uid === filter_department_uid ||
      filtered_data.find(
        (v2) => v2.parent_department_uid === v.organization_unit_uid // remove parent with no children
      )
  )

  final_data.sort((a, b) =>
    a.name && b.name ? a.name.localeCompare(b.name) : 0
  )

  const parents = listToTree(
    final_data.map((item) => ({
      ...item,
      value: item.organization_unit_uid
    })),
    'parent_department_uid',
    'organization_unit_uid',
    props.topOrganizationUnitUid
  )
  const onSelected = (selected: OrganizationUnit) => {
    setSelect(selected)
    if (props.handleSelected) {
      props.handleSelected(selected)
    }
  }
  return (
    <CrmTree
      {...props}
      single
      parents={parents}
      selected={selected ? [selected.value] : []}
      handleSelect={onSelected}
    />
  )
}

const listToTree = (dataset, parentID, childKey, selectedParent) => {
  let hashTable = Object.create(null)
  dataset.forEach(
    (aData) => (hashTable[aData[childKey]] = { ...aData, children: [] })
  )
  let dataTree = []
  dataset.forEach((aData) => {
    if (aData[parentID])
      hashTable[aData[parentID]].children.push(hashTable[aData[childKey]])
    else dataTree.push(hashTable[aData[childKey]])
  })
  if (selectedParent && hashTable[selectedParent]) {
    return [hashTable[selectedParent]]
  }
  return dataTree
}

export default OrganizationTree
