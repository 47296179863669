import { getAccountHqAddress } from '../utils/accounts-utils'
import { parseOpportunity } from './opportunities-api'

/* eslint-disable camelcase, capitalized-comments */
export function parseAccount(a) {
  const addresses = a.addresses ? a.addresses.map(parseAddress) : undefined
  const activities = a.activities ? a.activities.map(parseActivity) : undefined
  const opportunities = a.opportunities
    ? a.opportunities.map(parseOpportunity)
    : undefined
  const ret = {
    ...a,
    // accountUid: a.account_uid,
    // instanceUid: a.instance_uid,
    // name: a.name,
    account_details: parseAccountDetails(a.account_details),
    addresses,
    activities,
    opportunities,
    lead_in_date: new Date(a.lead_in_date)
  }
  // Map process from processes to opportunities
  if (ret.processes && ret.opportunities) {
    ret.opportunities.forEach(opp => {
      if (opp.process && opp.process.process_uid) {
        const pr = ret.processes.find(
          p => p.process_uid === opp.process.process_uid
        )
        if (pr) {
          opp.process = pr // eslint-disable-line
        }
      }
    })
  }
  ret.hqAddress = getAccountHqAddress(ret)
  return ret
}

export function parseQualification(q) {
  const qualification = q
  qualification.is_active = qualification.is_active === 'true'
  return qualification
}

export function parseAccountDetails(a) {
  return a
  // ? {
  //     accountDetailsUid: a.account_details_uid,
  //     instanceUid: a.instance_uid,
  //     businessName: a.business_name,
  //     firstName: a.first_name,
  //     middleName: a.middle_name,
  //     familyName: a.family_name,
  //     isValid: a.is_valid,
  //     remarks: a.remarks,
  //     validFrom: a.validFrom,
  //     validTill: a.validTill
  //   }
  // : undefined
}

export function parseAddress(a) {
  return a
  // return {
  //   addressUid: a.address_uid,
  //   instanceUid: a.instance_uid,
  //   name: a.name,
  //   city: a.city,
  //   street: a.street,
  //   postcode: a.postcode,
  //   province: a.province,
  //   town: a.town,
  //   suburb: a.suburb,
  //   countryCode: a.country_code,
  //   houseNumber: a.house_number,
  //   streetNumber: a.street_number,
  //   isValid: a.is_valid
  // }
}

export function parseActivity(a) {
  // const start_time = a.start_time ? new Date(a.start_time).getTime() : undefined
  const ret = {
    ...a,
    // start_time,
    activity_status: a.activity_status
      ? parseActivityStatus(a.activity_status)
      : undefined
  }
  // extract participants

  if (a.participants) {
    const lead_rm = a.participants.find(pa => pa.role_code === 'performer')
    const arranged_by = a.participants.find(
      pa => pa.role_code === 'arranged_by'
    )
    ret.lead_rm_user_uid = lead_rm ? lead_rm.user_uid : undefined
    ret.lead_rm = lead_rm
    if (arranged_by) {
      ret.arranged_by_user_uid = arranged_by.user_uid
    }
  }
  if (ret.primary_performer) {
    ret.primary_performer_user_uid = ret.primary_performer.user_uid
  }
  return ret
}

export function parseUser(a) {
  let result
  if (a.is_active === true || a.is_active === 't') {
    result.is_active = true
  } else {
    result.is_active = false
  }
  return result
}

export function parseActivityStatus(a) {
  return {
    ...a,
    is_active: a.is_active === 't' || a.is_active === true,
    is_initial: a.is_initial === 't' || a.is_initial === true,
    is_result: a.is_result === 't' || a.is_result === true,
    is_success: a.is_success === 't' || a.is_success === true,
    step: Number(a.step)
  }
}

export function parseUserListQL(a) {
  return a
}
