import { useState } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { useCrmContext } from '@cartrack-crm/crm-core'
import { QUERY_COMPONENTS } from 'crm-utils/crm-component-util'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import { DateRangeFilterValue } from '@cartrack-crm/crm-analytics/src/context/context-types'
import { Dashboard } from '../type'
import {
  agentHistoricalActualsB2B,
  agentHistoricalActualsB2C,
  agentLeadsResultsB2B,
  agentLeadsResultsB2C,
  agentLiveMonitoringB2B,
  agentLiveMonitoringB2C,
  ctAgentOwnedLeads,
  rmLastActivity,
  rmLeadsResults,
  rmMeetingActivity,
  rmMeetingPerformance,
  rmOpportunities,
  rmsHistoricalPerformance
} from 'crm-duxs/analytics/ct-dashboards'

export const useGetReportAction = (dashboard: Dashboard) => {
  const { filters, clearAllFilters, setFilters } = useAnalyticsContext()
  const [isReload, setReload] = useState(false)
  const [isCanRender, setCanRender] = useState(false)
  const forceLeadInDateDashboard = [
    'ctAgentOwnedLeads',
    'ctCampaignStatistics',
    'ctLeadsCurrentStatus',
    'ctLeadsStatusByLeadDate',
    'ctMonthlyLeadsResultsDashboardB2B',
    'ctMonthlyLeadsResultsDashboardB2C'
  ]
  const onApply = () => {
    if (dashboard) {
      setCanRender(true)
    }
    setReload(true)
  }
  const refreshReload = async () => setReload(false)
  const clearFilter = () => {
    clearAllFilters()
    setCanRender(false)
  }
  const autoDefaultFilter = () => {
    const code = dashboard?.value
    const pfilter: any = {}
    const stringToday = moment()
      .format('YYYY-MM-DD')
      .toString()
    const lastYearFilter = {
      value: {
        $gte: moment()
          .subtract(11, 'months')
          .startOf('month').format('YYYY-MM-DD').toString(),
        $lte: moment().endOf('month').format('YYYY-MM-DD').toString()
      },
      calendarValue: {
        from: moment()
          .subtract(11, 'months')
          .startOf('month').format('YYYY-MM-DD').toString(),
        to: moment().endOf('month').format('YYYY-MM-DD').toString()
      },
      label: 'Last year',
      valueCode: 'lastyear'
    }
    const todayFilter: DateRangeFilterValue = {
      value: {
        $gte: stringToday,
        $lte: stringToday
      },
      calendarValue: {
        from: stringToday,
        to: stringToday
      },
      label: 'Today',
      valueCode: 'today'
    }

    if (code) {
      if (forceLeadInDateDashboard?.includes(code)) {
        if (!['ctMonthlyLeadsResultsDashboardB2B', 'ctMonthlyLeadsResultsDashboardB2C'].includes(code)) {
          pfilter.lead_in_date = todayFilter
        }
      } else if (['rmsHistoricalPerformance']?.includes(code)) {
        pfilter.activity_date = lastYearFilter
      } else {
        pfilter.activity_date = todayFilter
      }
    }
    if (Object.keys(filters).length === 0) {
      setFilters(pfilter)
    }
  }
  return {
    autoDefaultFilter,
    clearFilter,
    filters,
    isCanRender,
    isReload,
    onApply,
    refreshReload,
    setCanRender
  }
}

const REPORT_TYPE_CHOICE = [
  { name: 'Agent Historical Actuals B2B', value: 'agentHistoricalActualsB2B', json: agentHistoricalActualsB2B },
  { name: 'Agent Historical Actuals B2C', value: 'agentHistoricalActualsB2C', json: agentHistoricalActualsB2C },
  { name: 'Agent Leads Results B2B', value: 'agentLeadsResultsB2B', json: agentLeadsResultsB2B },
  { name: 'Agent Leads Results B2C', value: 'agentLeadsResultsB2C', json: agentLeadsResultsB2C },
  { name: 'Agent Live Monitoring B2B', value: 'agentLiveMonitoringB2B', json: agentLiveMonitoringB2B },
  { name: 'Agent Live Monitoring B2C', value: 'agentLiveMonitoringB2C', json: agentLiveMonitoringB2C },
  { name: 'Agent Owned Leads Ageing', value: 'ctAgentOwnedLeads', json: ctAgentOwnedLeads },
  { name: 'Agent Summary', value: 'ctNewAgentDashboard' },
  { name: 'Agents Dispositions', value: 'dashTelesalesAgentsDispositions' },
  { name: 'Campaign Statistics', value: 'ctCampaignStatistics' },
  { name: 'Daily Lead Results (no filters)', value: 'ctDailyLeadsDashboard' },
  { name: 'Daily Lead Results B2B', value: 'ctDailyLeadsResultsB2B' },
  { name: 'Daily Lead Results B2C', value: 'ctDailyLeadsResultsB2C' },
  { name: 'Horizontal by Lead In Date', value: 'ctLeadsStatusByLeadDate' },
  { name: 'Lead Current Status', value: 'ctLeadsCurrentStatus' },
  { name: 'Monthly Lead Results (no filters)', value: 'ctMonthlyLeadsDashboard' },
  { name: 'Monthly Lead Results B2B', value: 'ctMonthlyLeadsResultsDashboardB2B' },
  { name: 'Monthly Lead Results B2C', value: 'ctMonthlyLeadsResultsDashboardB2C' },
  { name: 'RM Historical Actuals', value: 'rmsHistoricalPerformance', json: rmsHistoricalPerformance },
  { name: 'RM Last Activity', value: 'rmLastActivity', json: rmLastActivity },
  { name: 'RM Leads Results', value: 'rmLeadsResults', json: rmLeadsResults },
  { name: 'RM Meeting Activity', value: 'rmMeetingActivity', json: rmMeetingActivity },
  { name: 'RM Meeting Performance', value: 'rmMeetingPerformance', json: rmMeetingPerformance },
  { name: 'RM Opportunities', value: 'rmOpportunities', json: rmOpportunities },
  { name: 'RM Summary', value: 'ctNewRmDashboard' },
  { name: 'Sale Manager Dashboard', value: 'ctNewSaleManagerDashboard' },
  { name: 'Sources of Fitment', value: 'joeDashboard' },
  { name: 'Tele Sale Manager Dashboard', value: 'ctNewTeleSaleManagerDashboard' }
]

export const useGetDashboardOptions = () => {
  const { instance, master } = useCrmContext()
  const { data, loading } = useQuery(QUERY_COMPONENTS, {
    variables: {
      master_uid: master?.master_uid,
      instance_uid: instance?.instance_uid,
      filter: { is_published: true }
    }
  })
  const moreOptions = data?.master?.instance?.components
    ?.filter?.(item => (!item?.meta?.parentCode || item?.meta?.parenCode === 'more') && !item?.meta?.popupOnly)
    .map?.(item => ({
      name: `[More] ${item.name}`,
      label: item.name,
      value: item.component_uid,
      json: item
    }))

  const options = !loading ? [...REPORT_TYPE_CHOICE, ...moreOptions] : []
  return { loading, options }
}
