import React, { PureComponent } from 'react'
import { string, bool, array } from 'prop-types'
import { CrmTable } from 'crm-components'
import FlatTable from 'crm-components/flat-table.jsx'

class ReportsResultRenderer extends PureComponent {
  getColumns = () => {
    if (this.props.results.length > 0) {
      const columns = []
      const row = this.props.results[0]
      Object.keys(row).forEach(key => {
        if (key === 'account_name') {
          columns.push({
            Header: key.toUpperCase(),
            accessor: key,
            Cell: row => (
              <a
                id={row.original.account_uid}
                href={'/crm/account/' + row.original.account_uid}
                target="_blank"
              >
                {row.original[key]}
              </a>
            )
          })
        } else {
          columns.push({ Header: key.toUpperCase(), accessor: key })
        }
      })
      return columns
    }

    return [
      {
        Header: 'Result',
        accessor: ''
      }
    ]
  }

  render() {
    const { results, layout } = this.props
    const columns = this.getColumns()

    return layout === 'new' ? (
      <FlatTable columns={columns} data={results} />
    ) : (
      <CrmTable
        columns={columns}
        data={results}
        className="table"
        loading={this.props.generating}
      />
    )
  }
}
ReportsResultRenderer.propTypes = {
  results: array,
  layout: string.isRequired,
  generating: bool.isRequired
}
export default ReportsResultRenderer
