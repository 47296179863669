import gql from 'graphql-tag'

export const cqCommandResult = ' status errors payload command_uid '

export const qlmExecuteCommand = gql`
mutation cqCommand($command: CqCommand!) { 
  cqCommand(command: $command) { 
  ${cqCommandResult} 
}  
}`
