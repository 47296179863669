import React from 'react'
import { string, any, func, bool, shape, node } from 'prop-types'
import { injectIntl } from 'react-intl'
import { formatMessage } from '../utils/inital-component'

const CrmLabeledField = (props) => {
  return (
    <div
      style={props.style}
      className={`LabeledField 
    ${props.className ? props.className : ''}
    ${props.lg ? 'LabeledField--lg' : ''}
    ${props.noMargin ? 'LabeledField--noMargin' : ''}`}
    >
      {props.label && (
        <div className="LabeledField-label">
          {formatMessage(props.label, 'form.label')}
        </div>
      )}
      <div
        className={`LabeledField-value ${
          props.valueClassName ? props.valueClassName : ''
        }`}
        style={{ cursor: `${props.onClickLabel ? 'pointer' : 'default'}` }}
        onClick={props.onClickLabel}
      >
        {props.value}
        {props.children}
      </div>
    </div>
  )
}

CrmLabeledField.defaultProps = {
  children: undefined,
  value: undefined,
  noMargin: false,
  valueClassName: undefined,
  className: undefined,
  style: undefined,
  alignRight: false,
  lg: false,
  label: undefined
}

CrmLabeledField.propTypes = {
  children: any,
  label: node,
  value: string,
  onClickLabel: func,
  valueClassName: string,
  noMargin: bool,
  className: string,
  lg: bool,
  alignRight: bool,
  style: shape({})
}
export default injectIntl(CrmLabeledField)
