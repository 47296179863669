/* eslint-disable */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withApollo } from 'react-apollo'

import { graphql, compose } from 'react-apollo'
import { dialerCampaignQl } from './telesales-dash-utils'
import { qlqProcessType } from 'crm-data/processes'

import { arrayOf, shape, func } from 'prop-types'
import { CrmFullpageContainer, CrmDropdown, CrmButton } from 'crm-components'
import WidgetMovementsNewLeads from './movements/widget-movements-new-leads.jsx'
import WiedgetMovementsProcessedLeads from './movements/widget-movements-processed-leads.jsx'
import WidgetMovementNewLeadsByStatus from './movements/widget-movements-new-leads-by-status.jsx'
import WithLeadsPopupHoc from '../../components/_with-leads-popup-hoc.jsx'

const columns = []

class DashCampaignMovements extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleCurrentStepClick = selectedStepType => {
    // console.log('handleCurrentStepClick', selectedStepType)
    this.setState({ selectedStepType })
  }

  _mergeWithLocalContext = () => {
    let res = { ...this.props.analyticsContext }
    if (this.state.selectedStepType) {
      res.filters.process_step_type_uid = this.state.selectedStepType.process_step_type_uid
    }
    // console.log('_mergeWithLocalContext', res)
    return res
  }

  handleAnalyticsRangeSelected = params => {
    // console.log('handleAnalyticsRangeSelected', params)
    this.props.onShowLeadsPopup(params)
  }

  render() {
    const allAccountsCount = this.state.statsByCurrentStepResults
      ? this.state.statsByCurrentStepResults.reduce((a, i) => a + i.count, 0)
      : 0
    const detailsFilter = {}
    const localContext = this._mergeWithLocalContext()
    const { processType } = this.props
    if (!processType) {
      return <div>Loading Process Type</div>
    }
    return (
      <div>
        {/* <WidgetProcessInstancesByStep
          analyticsContext={this.props.analyticsContext}
        /> */}
        <div className="row">
          <div className="col-md-10">
            <WidgetMovementsNewLeads
              analyticsContext={this.props.analyticsContext}
              onCurrentStepClick={this.handleCurrentStepClick}
              onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
            />
          </div>

          <div className="col-md-6" />
        </div>

        <WiedgetMovementsProcessedLeads
          analyticsContext={this.props.analyticsContext}
          onCurrentStepClick={this.handleCurrentStepClick}
          onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
        />

        <WidgetMovementNewLeadsByStatus
          analyticsContext={this.props.analyticsContext}
          onCurrentStepClick={this.handleCurrentStepClick}
          onAnalyticsRangeSelected={this.handleAnalyticsRangeSelected}
          mode="days"
        />

        {/* <DashTelesalesCampaignLeads
          filter={detailsFilter}
          analyticsContext={localContext}
        /> */}
        <div>selectedStepTypeUid : {this.state.selectedStepTypeUid}</div>
        <small>{JSON.stringify(this.props.analyticsContext)}</small>
      </div>
    )
  }
}

DashCampaignMovements.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired
}

DashCampaignMovements.defaultProps = {
  dashboards: []
}

// Dialer type
const processTypeUid = '273c0b3f-23dd-4d05-ba56-cd34c21d0fd2'

const DashCampaignMovementsQL = compose(
  graphql(qlqProcessType, {
    options: () => ({
      variables: {
        process_type_uid: processTypeUid
      }
    }),
    props: ({ qlqProcessType, ownProps }) => ({
      ...ownProps,
      qlqProcessType,
      processType: qlqProcessType ? qlqProcessType.process_type : undefined
    }),
    name: 'qlqProcessType'
  }),
  withApollo
)(DashCampaignMovements)

export default withApollo(WithLeadsPopupHoc(DashCampaignMovementsQL))
