/* eslint-disable camelcase */
import { createSelector } from 'reselect'
// Actions
export const FETCH_SETTING_CAMPAIGNS = 'FETCH_SETTING_CAMPAIGNS'
export const FETCH_SETTING_CAMPAIGNS_SUCCESS = 'FETCH_SETTING_CAMPAIGNS_SUCCESS'
export const SELECT_CAMPAIGN = 'SELECT_CAMPAIGN'
export const CLEAR_SELECT_CAMPAIGN = 'CLEAR_SELECTCAMPAIGN'
export const SHOW_CREATE_FORM_CAMPAIGN = 'SHOW_CREATE_FORM_CAMPAIGN'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS'
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS'

// Reducer
const initialState = {
  campaigns: [],
  leadSources: [],
  campaignSelected: {
    uid: undefined,
    form_type: undefined
  }
}

export const findIndexOfCampaignsByUID = (campaigns, uid) =>
  campaigns.findIndex(campaign => campaign.campaign_uid === uid)
const mergeStateCampaignData = (campaigns, uid, user) => {
  const indexOfCampaigns = findIndexOfCampaignsByUID(campaigns, uid)
  return Object.assign([], campaigns, { [indexOfCampaigns]: user })
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTING_CAMPAIGNS:
    case CREATE_CAMPAIGN:
    case UPDATE_CAMPAIGN:
      return { ...state }

    case FETCH_SETTING_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        campaigns: action.payload.campaigns,
        leadSources: action.payload.leadSources
      }
    }
    case SELECT_CAMPAIGN:
      return {
        ...state,
        campaignSelected: { uid: action.payload.uid, formType: 'update' }
      }

    case CLEAR_SELECT_CAMPAIGN:
      return {
        ...state,
        campaignSelected: { uid: undefined, formType: undefined }
      }

    case SHOW_CREATE_FORM_CAMPAIGN:
      return {
        ...state,
        campaignSelected: { uid: undefined, formType: 'create' }
      }

    case CREATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        campaigns: [...state.campaigns, action.payload.campaign]
      }
    }
    case UPDATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        campaigns: mergeStateCampaignData(
          state.campaigns,
          action.payload.campaign.campaign_uid,
          action.payload.campaign
        )
      }
    }

    default:
      return state
  }
}

export function fetchCampaign(params) {
  return {
    type: FETCH_SETTING_CAMPAIGNS,
    payload: {
      params
    }
  }
}

export function setSelectedCampaign(campaignUid) {
  return {
    type: SELECT_CAMPAIGN,
    payload: {
      uid: campaignUid
    }
  }
}

export function clearSelectedCampaign() {
  return {
    type: CLEAR_SELECT_CAMPAIGN
  }
}

export function showCreateForm() {
  return {
    type: SHOW_CREATE_FORM_CAMPAIGN
  }
}

export function saveCampaign(campaign, values) {
  let type = CREATE_CAMPAIGN
  if (campaign.formType === 'update') {
    type = UPDATE_CAMPAIGN
  }
  return {
    type,
    payload: {
      campaign,
      values
    }
  }
}

// Selector
export const campaignSelector = state => state.crm.campaign.campaigns
export const getLeadSources = state => state.crm.campaign.leadSources
const selectedCampaign = state => state.crm.campaign.campaignSelected
const getSelected = (campaigns, selectedCampaignData) => ({
  data:
    campaigns[findIndexOfCampaignsByUID(campaigns, selectedCampaignData.uid)],
  formType: selectedCampaignData.formType
})
export const getSelectedCampaign = createSelector(
  campaignSelector,
  selectedCampaign,
  getSelected
)
