import React, { PureComponent } from 'react'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import { shape, func } from 'prop-types'
import { injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import { CrmButton } from 'crm-components'
import taskCompleteHoc, {
  taskCompleteHocPropTypes
} from 'crm-modules/processes/hoc/task-complete-hoc'

class TaskListItem extends PureComponent {
  _getIconClass(task) {
    const taskType = task && task.task_type ? task.task_type.kind : ''
    switch (taskType) {
      case 'meeting':
        return 'fa-users'
      case 'phone_call':
        return 'fa-phone'
      case 'note':
        return 'fa-sticky-note'
      default:
        return 'fa-futbol-o'
    }
  }

  componentWillReceiveProps(nextProps) {
    const processStepCode =
      nextProps && nextProps.processStepCode
        ? nextProps.processStepCode
        : undefined
    if (
      this.props &&
      this.props.processStepCode !== processStepCode &&
      processStepCode === 'closing'
    ) {
      this.handleMarkAsCompleted()
    }
  }

  handleMarkAsCompleted = async () => {
    try {
      const task = {
        ...this.props.task,
        account_uid: this.props.accountUid
      }
      await this.props.onCompleteTask(task)
      toastr.success('Task has been completed')
    } catch (err) {
      const message = err
        ? err.message
        : 'There was a problem completing this task'
      toastr.error(message)
    }
  }

  _buildInfoText = task => {
    let ret = task.created_time
      ? 'created: ' +
        this.props.intl.formatDate(task.created_time) +
        ' ' +
        this.props.intl.formatTime(task.created_time)
      : ''
  }

  render() {
    const { task } = this.props
    const infoText = task ? this._buildInfoText(task) : ''
    const isOverdue = task.due_date ? moment().isAfter(task.due_date) : false
    const priorityText = task.priority ? `Priority ${task.priority}` : ''
    return (
      <div
        className={`TasksListItem ${
          isOverdue ? 'TasksListItem--isOverdue' : ''
        }`}
        title={infoText}
      >
        <div className="TasksListItem-icon-priority" title={priorityText}>
          <i className={`fa ${this._getIconClass(task)}`} />
          {task.priority >= 5 && (
            <i className={`fa fa-exclamation util-marginSm`} />
          )}
          {task.priority >= 10 && (
            <i className={`fa fa-exclamation util-marginSm`} />
          )}
          {task.priority && <span>({task.priority})</span>}
        </div>
        <div className="TasksListItem-content" onClick={this.props.onClick}>
          <div>{task.name}</div>
          <small className="TasksListItem-doBy">
            {task.assignee_type === 'user' && task.assignee_user && (
              <div>by: {task.assignee_user.full_name}</div>
            )}
            {task.assignee_type === 'group' && task.assignee_group && (
              <div>by: {task.assignee_group.name}</div>
            )}
          </small>
        </div>
        <div className="TasksListItem-action">
          <div className="TasksListItem-due">
            {task.due_date && (
              <div>
                <span>due </span>
                <FormattedDate value={task.due_date} />{' '}
                <FormattedTime value={task.due_date} />
              </div>
            )}
          </div>
        <CrmButton
            small
            id={task.task_uid}
            className="TasksListItem-actionButton"
            label="Completed"
            onClick={this.handleMarkAsCompleted}
            grouped
          />
        </div>
      </div>
    )
  }
}

TaskListItem.defaultProps = {
  style: {},
  onClick: undefined
}

TaskListItem.propTypes = {
  task: shape({}).isRequired,
  onClick: func,
  ...taskCompleteHocPropTypes
}

export default injectIntl(taskCompleteHoc(TaskListItem))
