import React from 'react'
import memoizeOne from 'memoize-one'
import { get } from 'lodash'

export interface AnalyticsTableHocProps extends AnalyticsTableHocInnerProps {
  expandRowsInline: boolean
  initialSort?: Array<any>
  columns: Array<any>
  generateSummary?: boolean
  generateAverage?: boolean
  scrollBody?: boolean
}

export type AnalyticsTableHocInnerProps = {
  onExpandCell?: Function
  summaryRows?: Array<any>
  onHeaderClick?: Function
  columnGroups?: any
  sortColumnId?: string
  sortOrder?: string
  detailsRenderers?: any
  expandedCell?: any
  data?: Array<any>
  expandedRow?: any
}

const crmAnalyticsTableHoc = WrappedComponent => {
  class AnalyticsTableWrapper extends React.Component<AnalyticsTableHocProps, any> {
    constructor(props) {
      super(props)

      const state: any = {
        ...this.mapStateToProps(props)
      }

      if (props.initialSort) {
        state.sortColumnId = props.initialSort.id
        state.sortOrder = props.initialSort.order
      }

      this.state = state
    }

    componentWillReceiveProps(nextProps) {
      this.setState({ ...this.mapStateToProps(nextProps) })
    }

    handleHeaderClick = columnId => {
      if (!columnId) return

      const tempState = { sortOrder: 'asc', sortColumnId: columnId }

      if (this.state.sortColumnId === columnId) {
        tempState.sortOrder = this.state.sortOrder === 'asc' ? 'desc' : undefined
        tempState.sortColumnId = tempState.sortOrder ? columnId : undefined
      }

      this.setState(tempState)
    }

    mapStateToProps = props => {
      const data = props.data
        ? props.data.map(r => {
            const ret = { ...r }
            if (!r.id && props.rowIdField) {
              ret.id = ret[props.rowIdField]
            }
            if (!r.id) {
              ret.id = ret.user_uid
            }
            return ret
          })
        : undefined

      let columns

      if (props.generateColumns) {
        columns = props.generateColumns(props)
      } else {
        columns = props.columns
          ? props.columns.map(r => {
              const ret = { ...r }
              if (!ret.id && ret.accessor) {
                ret.id = ret.accessor
              }
              if (!ret.id && ret.Header) {
                ret.id = ret.Header
              }
              if (!ret.detailsType) {
                ret.detailsType = ret.detailsPopup
              }
              return ret
            })
          : undefined
      }

      const summaryRows = props ? this.calculateSummaryRows(props.data, props.columns) : undefined

      return { data, columns, summaryRows }
    }

    findColumnSummable = (data: any[], columns: any[]) => {
      let checker = new Set([])

      data?.map(item =>
        columns?.map(c => {
          if (!isNaN(item?.[c?.['accessor']])) {
            checker.add(c?.['accessor'])
          }
        })
      )

      return columns.filter(
        c =>
          [...checker].includes(c.accessor) ||
          c.type === 'number' ||
          c.type === 'float' ||
          c.type === 'integer' ||
          c.type === 'interval'
      )
    }

    calculateSummaryRows = memoizeOne((data, columns) => {
      const summableColumns = this.findColumnSummable(data, columns)

      const total =
        data && data.reduce
          ? data.reduce(
              (a, i) => {
                const t = { ...a }

                summableColumns.forEach(col => {
                  if (col.nestedField && !t[col.nestedField]) {
                    t[col.nestedField] = {}
                  }

                  const num = col
                    ? Number(
                        col.nestedField
                          ? i[col.nestedField]
                            ? i[col.nestedField][col.accessor]
                            : undefined
                          : i[col.accessor]
                      )
                    : undefined

                  if (num && !isNaN(num)) {
                    if (col.nestedField) {
                      const v = t[col.nestedField][col.accessor]
                      t[col.nestedField][col.accessor] = v ? v + num : num
                    } else {
                      if (col.accessorFn) {
                        t[col.accessor] = t[col.accessor] ? t[col.accessor] + col.accessorFn(i) : col.accessorFn(i)
                      } else {
                        t[col.accessor] = t[col.accessor] ? t[col.accessor] + num : num
                      }
                    }
                  }
                })

                return t
              },
              {
                isSummary: true,
                className: 'util-textBold CrmFlatTable-summary',
                id: 'summary',
                full_name: 'Total',
                is_active: true
              }
            )
          : 0

      return [total]
    })

    getColumnHeaderValue = ({ Header, labelMessageId }) => Header

    _generateColumns(props) {
      const ret = props.data
        ? Object.values(
            props.data.reduce((a, i) => {
              // eslint-disable-next-line
              Object.keys(i).forEach(k => (a[k] = k))
              return a
            }, {})
          )
        : []

      const columns = ret.map(name => ({
        accessor: name,
        Header: name
      }))

      return columns.slice(0, 5)
    }

    handleExpandCell = params => {
      if (this.props.expandRowsInline) {
        if (
          this.state.expandedCell &&
          this.state.expandedCell.rowId === params.rowId &&
          this.state.expandedCell.columnId === params.columnId
        ) {
          this.setState({ expandedCell: undefined, expandedRow: undefined })
        } else {
          this.setState({
            expandedCell: {
              ...params,
              row: this.state.data.find(r => r.id === params.rowId),
              column: this.state.columns.find(c => c.id === params.columnId)
            },
            expandedRow: undefined
          })
          requestAnimationFrame(() => {
            this.setState({ expandedRow: true })
          })
        }
      }
    }

    prepareData = memoizeOne((data, columns, { sortColumnId, sortOrder }) => {
      const compareFn = (a, b) => {
        const aIsActive = get(a, 'is_active', false),
          bIsActive = get(b, 'is_active', false)

        if (aIsActive && bIsActive && aIsActive !== bIsActive) return aIsActive ? -1 : 1
        if (!columns || !sortColumnId) return

        const sortCol = columns.find(c => c.id === sortColumnId)

        if (!sortCol) return

        const getCellValue = row =>
          sortCol.accessorFn ? sortCol.accessorFn(row) : get(row, sortCol.accessor, get(row, sortCol.key, ''))

        let va = getCellValue(a) || 0
        let vb = getCellValue(b) || 0

        if (sortCol.type === 'number' || sortCol.type === 'interval' || (!isNaN(va) && !isNaN(vb)))
          return (Number(va) - Number(vb)) * (sortOrder === 'desc' ? -1 : 1)

        va = getCellValue(a) || ''
        vb = getCellValue(b) || ''

        if (va.localeCompare && vb.localeCompare) return va.localeCompare(vb) * (sortOrder === 'desc' ? -1 : 1)

        return 0
      }

      let sortedData = data ? data.sort(compareFn) : []

      return sortedData.map((i, index) => ({ index: index + 1, ...i, id: i.id || index + 1 }))
    })

    render() {
      const params = {
        sortColumnId: this.state.sortColumnId,
        sortOrder: this.state.sortOrder
      }

      const data = this.prepareData(this.state.data, this.state.columns, params)

      return (
        <WrappedComponent
          {...this.props}
          data={data}
          columns={this.state.columns}
          sortColumnId={this.state.sortColumnId}
          sortOrder={this.state.sortOrder}
          summaryRows={this.state.summaryRows}
          expandedRow={this.state.expandedRow}
          expandedCell={this.state.expandedCell}
          onExpandCell={this.handleExpandCell}
          onHeaderClick={this.handleHeaderClick}
        />
      )
    }
  }

  return AnalyticsTableWrapper
}

export default crmAnalyticsTableHoc
