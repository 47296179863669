/* eslint-disable camelcase */
import { callQLApi } from './api-utils'

const qlMessageFields = ` message_uid title body created_time from_user { full_name } to_user { full_name } type read_time `

export default {
  sendNotificationToMobile: message => {
    const qlParams = {
      query: ` mutation  push_notification_create($push_notification_in: push_notification_in) { push_notification_create(push_notification: $push_notification_in) { result message_id } } `,
      variables: {
        push_notification_in: message
      }
    }

    return callQLApi(qlParams).then(res => res.push_notification_create)
  },
  markMessageAsRead: message => {
    const qlParams = {
      query: ` mutation message_mark_as_read($message_uid: String) { message_mark_as_read(message_uid: $message_uid) { message_uid } } `,
      variables: {
        message_uid: message.message_uid
      }
    }
    return callQLApi(qlParams).then(res => res.message_mark_as_read)
  },
  listMessages: params => {
    console.log('listMessages api')
    console.dir(params)
    const filters = []
    if (params) {
      if (params.mine) {
        filters.push('mine:true')
      }
      if (params.unread) {
        filters.push('unread:true')
      }
    }
    const qlParams = {
      query: ` { messages${
        filters.length > 0 ? '(' + filters.join(',') + ')' : ''
      } { ${qlMessageFields} } } `
    }
    return callQLApi(qlParams).then(res => ({
      data: res.messages,
      count: res.count
    }))
  },
  getMessage(id) {
    console.log('getMessage api')
    console.dir(id)
    const qlParams = {
      query: ` { message(message_uid:"${id}") { ${qlMessageFields} params } } `
    }
    return callQLApi(qlParams).then(res => res.message)
  }
}
