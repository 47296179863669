import React from 'react'
import { connect } from 'react-redux'
import { node } from 'prop-types'

export const Context = React.createContext({})

export class SLFormsProvider extends React.Component<any, any> {
  constructor(props) {
    super(props)

    const rootContext = {
      app: {
        user: props.currentUser
      },
      ...props.slFormsRuntimeParams.contexts
    }

    this.state = {
      rootContext,
      defaultContextName: props.slFormsRuntimeParams.defaultContextName
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState({ ...this.state, ...newState }, () => {
        resolve()
      })
    })

  componentWillReceiveProps(nextProps) {
    if (nextProps.slFormsRuntimeParams !== this.props.slFormsRuntimeParams) {
      this.setState({
        rootContext: {
          ...this.state.rootContext,
          ...nextProps.slFormsRuntimeParams.contexts
        }
      })
    }
  }

  handleSave = async params => {
    await this.setStateAsync({ isSaving: true })

    if (this.props.onSave) {
      const saveRes = await this.props.onSave(params)

      if (saveRes && saveRes.result === 'OK') {
        await this.setStateAsync({ isSaving: false })
        return saveRes
      }
    }
  }

  _getSlFormsRuntime = () => {
    const getContextVariable = (contextName, variableName) => {
      const targetContext = contextName === 'default' ? this.state.defaultContextName : contextName

      if (this.state.rootContext[targetContext]) {
        return this.state.rootContext[targetContext][variableName]
      }

      return undefined
    }

    const storeContextVariable = (contextName, variableName, value) => {
      const targetContext = contextName === 'default' ? this.state.defaultContextName : contextName

      const updatedContext = { ...this.state.rootContext[targetContext] }
      updatedContext[variableName] = value

      this.setState({
        rootContext: {
          ...this.state.rootContext,
          [targetContext]: updatedContext
        }
      })
    }

    const onChange = (widget, event) => {
      Object.keys(event).forEach(key => {
        storeContextVariable('default', key, event[key])
      })
    }

    return {
      contextName: this.state.defaultContextName,
      rootContext: this.state.rootContext,
      onSave: this.handleSave,
      getContextVariable,
      storeContextVariable,
      onChange
    }
  }

  render() {
    const slFormRuntime = this._getSlFormsRuntime()

    return <Context.Provider value={slFormRuntime}>{React.Children.only(this.props.children)}</Context.Provider>
  }
}

export function withSlFormRuntime(InnerComponent) {
  class WithSl extends React.Component<any, any> {
    static propTypes = {
      forwardedRef: node
    }
    static defaultProps = {
      forwardedRef: undefined
    }

    render() {
      const { forwardedRef, ...rest } = this.props

      return (
        <Context.Consumer>
          {slFormRuntime => {
            return <InnerComponent ref={forwardedRef} {...rest} slFormRuntime={slFormRuntime} />
          }}
        </Context.Consumer>
      )
    }
  }

  return React.forwardRef((props, ref) => <WithSl {...props} forwardedRef={ref} />)
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(SLFormsProvider)
