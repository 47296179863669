import { get } from 'lodash'

/* eslint-disable camelcase */
export function getDimensionsForKpis(kpis, availableKpis, dataTypes) {
  if (!kpis) return []

  const ret = []

  kpis.forEach(kpi => {
    const kpiDef = availableKpis.find(d => d.code === kpi.code)

    if (!get(kpiDef, 'dims', false)) return

    kpiDef.dims.forEach(dim => {
      ret.push({
        kpi_code: kpi.code,
        dim_code: dim.code,
        name: `${kpiDef.name}: ${dim.name}`,
        id: `${kpi.code}.${dim.code}`,
        data_type_code: dim.data_type_code
      })

      if (!dim.data_type_code || !dataTypes) return

      const dt = dataTypes.find(dt => dt.code === dim.data_type_code)

      if (!get(dt, 'fields', false)) return

      dt.fields.forEach(dtf => {
        ret.push({
          kpi_code: kpi.code,
          dim_code: dim.code,
          field_code: dtf.code,
          name: `${kpiDef.name}: ${dim.name} -> ${dtf.name}`,
          id: `${kpi.code}.${dim.code}.${dtf.code}`,
          data_type_code: dtf.data_type_code
        })
      })
    })
  })

  return ret
}

export const mock = 1
