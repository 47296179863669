import React from 'react'
import { CrmTextInput, CrmDropdown, CrmButton } from 'crm-components'
import { DatePicker } from 'util-components'
import { Formik, Field } from 'formik'
import moment from 'moment'

class CreateOrderForm extends React.PureComponent {
  static defaultProps = {
    enableCanceled: false,
    isAllowToDelete: false,
    value: {
      status: 'pending',
      sale_date: moment().toDate(),
      completed_time: null,
      remark: '',
      cancelation_reason: ''
    },
    onDelete: () => {}
  }
  state = {
    isLoading: false
  }
  get statusOptions() {
    const statuses = [
      { name: 'Pending', value: 'pending' },
      {
        name: 'Completed',
        value: 'completed'
      }
    ]

    if (this.props.enableCanceled) {
      statuses.push({
        name: 'Canceled',
        value: 'canceled'
      })
    }
    return statuses
  }

  handleSubmit = async values => {
    this.setState({ isLoading: true })
    const value = Object.assign({}, values)
    switch (values.status) {
      case 'pending': {
        value.sale_date = moment(values.sale_date).format('YYYY-MM-DD')
        value.completed_time = null
        value.cancelation_reason = null
        break
      }
      case 'completed': {
        value.sale_date = moment(values.sale_date).format('YYYY-MM-DD')
        value.completed_time = moment(values.completed_time).format(
          'YYYY-MM-DD'
        )
        value.cancelation_reason = null
        break
      }
      case 'canceled': {
        break
      }
    }
    await this.props.onSubmit(value)
    this.setState({ isLoading: false })
  }

  validateForm = values => {
    const errors = {}
    switch (values.status) {
      case 'pending': {
        if (!values.sale_date) {
          errors.sale_date = 'Required Registered Date'
        }
        break
      }
      case 'completed': {
        if (!values.completed_time) {
          errors.completed_time = 'Required Completed Date'
        }
        if (!values.sale_date) {
          errors.sale_date = 'Required Registered Date'
        }
        if (values.sale_date && values.completed_time) {
          const completed_time = moment(values.completed_time)
          const sale_date = moment(values.sale_date)
          if (sale_date.isAfter(completed_time)) {
            errors.sale_date =
              "'Registered Date' must be before 'Completed Date'"
          }
        }
        break
      }
      case 'canceled': {
        if (!values.cancelation_reason) {
          errors.cancelation_reason = 'Required cancelation reason'
        }
      }
    }
    return errors
  }
  renderErrors(errors) {
    return Object.keys(errors).map(key => {
      const value = errors[key]
      return (
        <div className="CrmForm-field-error" key={key}>
          {value}
        </div>
      )
    })
  }
  render() {
    return (
      <Formik
        initialValues={this.props.value}
        validateOnChange
        validate={this.validateForm}
        onSubmit={this.handleSubmit}
      >
        {props => (
          <form className="CrmForm" onSubmit={props.handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Field
                  render={({ field, form }) => (
                    <CrmDropdown
                      title="Status"
                      options={this.statusOptions}
                      input={{
                        value: props.values.status,
                        onChange: x => form.setFieldValue('status', x)
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {['pending', 'completed'].includes(props.values.status) && (
              <div className="row">
                <div className="col-md-12">
                  <Field
                    render={({ field, form }) => (
                      <DatePicker
                        title="Registered Date"
                        placeholder="Registered Date"
                        input={{
                          value: props.values.sale_date,
                          onChange: x => form.setFieldValue('sale_date', x)
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {props.values.status === 'completed' && (
              <div className="row">
                <div className="col-md-12">
                  <Field
                    render={({ field, form }) => (
                      <DatePicker
                        title="Completed Date"
                        placeholder="Completed Date"
                        input={{
                          value: props.values.completed_time,
                          onChange: x => form.setFieldValue('completed_time', x)
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {props.values.status === 'canceled' && (
              <div className="row">
                <div className="col-md-12">
                  <Field
                    render={({ field, form }) => (
                      <CrmTextInput
                        title="Cancelation Reason"
                        placeholder="Cancelation Reason"
                        input={{
                          value: props.values.cancelation_reason,
                          onChange: x =>
                            form.setFieldValue(
                              'cancelation_reason',
                              x.target.value
                            )
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <Field
                  render={({ field, form }) => (
                    <CrmTextInput
                      title="Remark"
                      placeholder="Remark"
                      input={{
                        value: props.values.remark,
                        onChange: x =>
                          form.setFieldValue('remark', x.target.value)
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div>
                <CrmButton
                  small
                  label={this.state.isLoading ? 'Loading..' : 'Submit'}
                  type="submit"
                  enable={
                    !this.state.isLoading &&
                    Object.keys(props.errors).length === 0
                  }
                />
                {this.props.isAllowToDelete && (
                  <CrmButton
                    small
                    style={{ marginLeft: 10 }}
                    label="Delete"
                    onClick={this.props.onDelete}
                  />
                )}
                {/* <button
                  type="submit"
                  disabled={
                    this.state.isLoading || Object.keys(props.errors).length > 0
                  }
                >
                  {this.state.isLoading ? 'Loading..' : 'Submit'}
                </button>
                {this.props.isAllowToDelete && (
                  <button type="button" onClick={this.props.onDelete}>
                    Delete
                  </button>
                )} */}
              </div>
              <div> {this.renderErrors(props.errors)}</div>
            </div>
          </form>
        )}
      </Formik>
    )
  }
}

export default CreateOrderForm
