import React, { useState } from 'react'
import { useAnalyticsContext } from '@cartrack-crm/crm-analytics'
import { FilterSection } from '../tools'
import { CampaignSelectDropDown } from '.'
export const FilterSectionCampaign = props => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleDropDown = () => setIsExpanded(!isExpanded)
  const closeDropDown = () => setIsExpanded(false)
  const { filters, setFilters } = useAnalyticsContext()
  return (
    <FilterSection
      name="Sales Campaign"
      isExpanded={isExpanded}
      onToggle={toggleDropDown}
      selectedValue={
        <strong>
          {filters?.sales_campaign?.campaign_uid?.value
            ? filters?.sales_campaign?.campaign_uid?.label
            : 'All Campaigns'}
        </strong>
      }
      {...props}
    >
      {isExpanded && (
        <CampaignSelectDropDown
          isExpanded={isExpanded}
          onClickedOutside={closeDropDown}
          onSetFilters={setFilters}
          filterValues={filters}
        />
      )}
    </FilterSection>
  )
}
