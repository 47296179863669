import React from 'react'
import { WidgetConfigurationContextProvider } from '@cartrack-crm/crm-analytics/src/configuration-tools/component-configuration-context'
import { crmSchemaProd } from '@cartrack-crm/crm-analytics/src/crm-schema-prod'
import {
  WidgetJsonConfiguration,
  GenericAnalyticsWidget
} from '@cartrack-crm/crm-analytics'

export const DynamicComponentDashboard = (props) => {
  const renderPreview = (component) =>
    component && component.content && component.content.layout ? (
      <GenericAnalyticsWidget
        key={component?.dashboard_uid}
        title={component?.name}
        style={{ margin: 0 }}
        widgetDefinition={component}
        filters={component?.filters}
        onShowLeadsPopup={props.onShowLeadsPopup}
      />
    ) : (
      <div> No layout in component </div>
    )
  return (
    <WidgetConfigurationContextProvider schema={crmSchemaProd} component={{}}>
      <div>Dynamic component das </div>
      <WidgetJsonConfiguration {...props} renderPreview={renderPreview} />
    </WidgetConfigurationContextProvider>
  )
}
