import React from 'react'
import { Formik, Form, Field } from 'formik'
import { CrmTextInput, CrmButton } from '@cartrack-crm/ui'
import { SectionHeader } from './master-list'
import { isEmptyData } from '../utils/common-utils.js'

interface MasterFormStep2Props {
  masterUid: string
  initialValues: any
  master_customer: any
  isSaving: boolean
  handleCreateFirstInstance: any
  created_instances: any
}

export const MasterFormStep2: React.SFC<MasterFormStep2Props> = (props) => {
  console.log('MasterFormStep2', props)

  if (!isEmptyData(props.created_instances)) {
    return (
      <React.Fragment>
        <SectionHeader name="Root Instance" />
        <div
          className="util-marginLg"
          style={{ maxHeight: '300px', overflowY: 'auto' }}
        >
          <div>Created Instances</div>
          <div className="util-marginLeft">
            {props.created_instances.map((v) => (
              <li className="">{`${v.name}`}</li>
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }

  const initialValues = {
    name: '',
    country_code: '',
    timezone: '',
    currency_code: ''
  }
  return (
    <div>
      <SectionHeader name="Root Instance" />
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues }}
        onSubmit={props.handleCreateFirstInstance}
        validateOnChange
        validate={(values) => {
          const errors = {} as any
          if (!values.name) errors.name = '* required'
          return errors
        }}
      >
        {({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => {
          return (
            <Form>
              <fieldset
                className="Form-fieldset"
                disabled={props.initialValues?.step >= 2}
              >
                <div className="row">
                  <div className="col-md-3">
                    <Field
                      name="name"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue('name', e.currentTarget.value),
                            value: values.name
                          }}
                          placeholder="Instance Name"
                        />
                      )}
                    />
                    {errors.name && (
                      <div className="CrmForm-field-error">{errors.name}</div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="country_code"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue(
                                'country_code',
                                e.currentTarget.value
                              ),
                            value: values.country_code
                          }}
                          placeholder="Country Code"
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="timezone"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue('timezone', e.currentTarget.value),
                            value: values.timezone
                          }}
                          placeholder="Timezone"
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="currency_code"
                      render={({ field, form }) => (
                        <CrmTextInput
                          input={{
                            onChange: (e) =>
                              setFieldValue(
                                'currency_code',
                                e.currentTarget.value
                              ),
                            value: values.currency_code
                          }}
                          placeholder="Currency Code"
                        />
                      )}
                    />
                  </div>
                </div>

                <br />
                <div className="row">
                  <div className="col-md-12">
                    <CrmButton
                      type="submit"
                      label="Create First Instance"
                      disabled={props.isSaving}
                      enable={!props.isSaving}
                      isSaving={props.isSaving}
                    />
                  </div>
                </div>
              </fieldset>
            </Form>
          )
        }}
      </Formik>
      <br /> <br /> <br />
    </div>
  )
}
