import React from 'react'
import { func, shape, bool, arrayOf } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import {
  CrmButton,
  CrmOpportunityTypeSelect,
  CrmDropdown,
  CrmDayPickerInput,
  CrmLabeledField,
  CrmUsersSelect,
  CrmTextInputNumber,
  CrmTextArea
} from 'crm-components'
import cloneDeep from 'clone-deep'
import { toastr } from 'react-redux-toastr'
import OpportunityInlineEditable from './opportunity-inline-editable.jsx'
import { getDateFormat } from 'crm-utils/time-utils'
import generateQuotationForOpportunityHoc from 'crm-modules/quotations/hoc/generate-quotation-for-opportunity-hoc'
import { withOpportunityCommandHoc } from '../hooks/with-opportunity-command'
import OpportunityWinLoseWidget from './opportunity-win-loose-widget.jsx'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { withEditableContextHOC } from 'crm-core/permissions/editable-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import { BusinessSourceSelect } from 'crm-components'

const confidenceLevelOptions = [
  { name: '20%', value: 20 },
  { name: '40%', value: 40 },
  { name: '60%', value: 60 },
  { name: '80%', value: 80 }
]

const specialProjectOptions = [
  { name: 'Yes', value: "true" },
  { name: 'No', value: "false" },
]

const forecastOptions = [
  { name: 'Yes', value: "true" },
  { name: 'No', value: "false" },
]

class OpportunityForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      opportunity: cloneDeep(props.opportunity),
      isSaving: false,
      isShowingOpportunityLostWidget: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.opportunity !== this.props.opportunity) {
      this.setState(
        {
          opportunity: cloneDeep(nextProps.opportunity)
        },
        () => {}
      )
    }
  }

  handleOpportunityLost = async () => {
    this.setState({ isShowingOpportunityLostWidget: true })
  }

  handleSaveOpportunity = async () => {
    this.setState({ isSaving: true })
    await this.props.handleSaveOpportunity()
    this.setState({ isSaving: false })
  }

  renderColAction = (index, isLastRow, isNewOpp) => {
    const style = isNewOpp ? { height: '38px' } : {}
    if (isLastRow && isNewOpp) {
      return (
        <div className="leftBorder" style={style}>
          <span
            className="AccountLeftSide-addButton"
            title="Click to add new product"
          >
            <FontAwesomeIcon
              className="util-hooverable util-pointer"
              icon="plus"
              onClick={e => this.props.handleAddNewItem(e)}
            />
          </span>
        </div>
      )
    } else {
      return (
        <div className="leftBorder" style={style}>
          <div className="rotate">
            <span title="Click to remove product">
              <FontAwesomeIcon
                className="util-hooverable util-pointer"
                icon="times"
                onClick={e => this.props.handleRemoveItem(e, index)}
              />
            </span>
          </div>
        </div>
      )
    }
  }

  handleOpportunityLoseSaved = () => {
    console.log('handleOpportunityLoseSaved')
    toastr.success('Opportunity lost saved')
    this.setState({ isShowingOpportunityLostWidget: false })
  }

  handleQuickQuotationOpen = () => {
    const { opportunity } = this.state
    if (
      !this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.QUOTATION_MODIFY
      )
    ) {
      toastr.success("You don't have permission to edit quotation")
      return
    }
    this.props.handleQuickQuotationOpen(opportunity)
  }

  getIsEditable = () => {
    const { opportunity } = this.state
    const isEditable =
      !(
        opportunity.status_code === 'won' || opportunity.status_code === 'lost'
      ) &&
      this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.OPPORTUNITIES_MODIFY
      )
    if (this.props.editableContext.isEditable !== isEditable) {
      this.props.editableContext.setIsEditable(isEditable)
    }
    return isEditable
  }

  getIsEditableCom = () => {
    return !!this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.QUOTATION_MANAGE_COMMISSION_AND_SPEC
    );
  }

  render() {
    console.log('render opportunity-form-19', this.props, this.state)
    const { opportunity } = this.state

    const isNewOpp = !opportunity.opportunity_uid
    const isEditable = this.getIsEditable()
    const isEditableCom = this.getIsEditableCom()
    const isWon = opportunity.status_code === 'won'
    const isLost = opportunity.status_code === 'lost'
    const dueDate = opportunity.due_date
      ? getDateFormat(opportunity.due_date)
      : null
    const completedDate = opportunity.completed_time
      ? getDateFormat(opportunity.completed_time)
      : null
    const ownerUserName =
      opportunity.owner_user && opportunity.owner_user.full_name
        ? opportunity.owner_user.full_name
        : ''
    const isAdmitEdit =
      this.props.isAdmin && this.props.adminCorrectDataSelected

    const accountProcessType =
      this.props.account.processes && this.props.account.currentSalesProcess
        ? this.props.account.processes.find(
            p =>
              p.process_uid ===
              this.props.account.currentSalesProcess.processUid
          )
        : undefined
    const lastSalesProcessType =
      this.props.account.processes && this.props.account.lastSalesProcess
        ? this.props.account.processes.find(
            p =>
              p.process_uid === this.props.account.lastSalesProcess.process_uid
          )
        : undefined
    console.log('accountProcessType', accountProcessType)
    console.log('lastSalesProcessType', lastSalesProcessType)
    return (
      <React.Fragment>
        <div className="util-fullHeight util-flexColumn ">
          {/* Header Fields */}
          <div className="contentHeaderWrapper newOpportunity">
            <div className="row">
              {isNewOpp && (
                <React.Fragment>
                  <div className="col-md-5">
                    <CrmOpportunityTypeSelect
                      placeholder={
                        <FormattedMessage
                          id="crm.ui.account.opportunity_type"
                          defaultMessage="Opportunity Type"
                        />
                      }
                      input={{
                        value: opportunity.opportunity_type
                          ? opportunity.opportunity_type.name
                          : '',
                        onChange: (value, title, item) => {
                          this.props.handleOpportunityTypeChange(
                            'confidence_level',
                            item
                          )
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-3">
                    <CrmDropdown
                      placeholder={
                        <FormattedMessage
                          id="crm.ui.account.confidence"
                          defaultMessage="Confidence"
                        />
                      }
                      options={confidenceLevelOptions}
                      input={{
                        value: opportunity.confidence_level,
                        onChange: value => {
                          this.props.handleFieldChange(
                            'confidence_level',
                            value
                          )
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3 dueDate">
                    <CrmDayPickerInput
                      placeholder={
                        <FormattedMessage
                          id="crm.ui.account.due_date"
                          defaultMessage="Due Date"
                        />
                      }
                      input={{
                        value: dueDate,
                        onChange: value => {
                          this.props.handleFieldChange('due_date', value)
                        }
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
              {!isNewOpp && (
                <React.Fragment>
                  <div className="col-md-4">
                    <OpportunityInlineEditable
                      fieldName="opportunity_type_uid"
                      label={
                        <FormattedMessage
                          id="crm.ui.account.opportunity_type"
                          defaultMessage="Opportunity Type"
                        />
                      }
                      value={
                        opportunity.opportunity_type
                          ? opportunity.opportunity_type.name
                          : ''
                      }
                      displayValue={
                        opportunity.opportunity_type
                          ? opportunity.opportunity_type.name
                          : ''
                      }
                      editable={isEditable}
                      readModeStyle="CrmLabeledField"
                      emptyValue="-"
                      editComponent={CrmOpportunityTypeSelect}
                      editComponentProps={{
                        placeholder: (
                          <FormattedMessage
                            id="crm.ui.account.opportunity_type"
                            defaultMessage="Opportunity Type"
                          />
                        )
                      }}
                      handleOpportunityTypeChange={
                        this.props.handleOpportunityTypeChange
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <OpportunityInlineEditable
                      fieldName="confidence_level"
                      label={
                        <FormattedMessage
                          id="crm.ui.account.confidence"
                          defaultMessage="Confidence"
                        />
                      }
                      value={opportunity.confidence_level}
                      displayValue={opportunity.confidence_level + '%'}
                      editable={isEditable}
                      readModeStyle="CrmLabeledField"
                      emptyValue="-"
                      editComponent={CrmDropdown}
                      editComponentProps={{
                        placeholder: (
                          <FormattedMessage
                            id="crm.ui.account.confidence"
                            defaultMessage="Confidence"
                          />
                        ),
                        options: confidenceLevelOptions
                      }}
                      handleFieldChange={this.props.handleFieldChange}
                    />
                  </div>
                  {isEditable && (
                    <div className="col-md-3 dueDate">
                      <OpportunityInlineEditable
                        fieldName="due_date"
                        label={
                          <FormattedMessage
                            id="crm.ui.account.due_date"
                            defaultMessage="Due Date"
                          />
                        }
                        value={dueDate}
                        displayValue={dueDate}
                        emptyValue="-"
                        editable={isEditable}
                        readModeStyle="CrmLabeledField"
                        editComponent={CrmDayPickerInput}
                        editComponentProps={{
                          placeholder: (
                            <FormattedMessage
                              id="crm.ui.account.due_date"
                              defaultMessage="Due Date"
                            />
                          ),
                          input: {
                            value: dueDate
                          }
                        }}
                        handleFieldChange={this.props.handleFieldChange}
                      />
                    </div>
                  )}
                  {!isEditable && (
                    <div className="col-md-3">
                      <CrmLabeledField
                        noMargin
                        label={
                          <FormattedMessage
                            id="crm.ui.account.due_date"
                            defaultMessage="Due Date"
                          />
                        }
                      >
                        {dueDate || '-'}
                      </CrmLabeledField>
                    </div>
                  )}

                  <div className="col-md-3 completed">
                    {isAdmitEdit && (
                      <OpportunityInlineEditable
                        fieldName="completed_time"
                        label={
                          <FormattedMessage
                            id="crm.ui.account.completed"
                            defaultMessage="Completed"
                          />
                        }
                        value={completedDate}
                        displayValue={completedDate}
                        emptyValue="-"
                        editable={isAdmitEdit}
                        readModeStyle="CrmLabeledField"
                        editComponent={CrmDayPickerInput}
                        editComponentProps={{
                          placeholder: (
                            <FormattedMessage
                              id="crm.ui.account.completed"
                              defaultMessage="Completed"
                            />
                          ),
                          input: {
                            value: completedDate
                          }
                        }}
                        handleFieldChange={this.props.handleFieldChange}
                        isAdmitEdit={isAdmitEdit}
                      />
                    )}
                    {!isAdmitEdit && (
                      <CrmLabeledField
                        noMargin
                        label={
                          <FormattedMessage
                            id="crm.ui.account.completed"
                            defaultMessage="Completed"
                          />
                        }
                      >
                        {opportunity.completed_time
                          ? getDateFormat(opportunity.completed_time)
                          : '-'}
                      </CrmLabeledField>
                    )}
                  </div>
                  <div className="col-md-4">
                    {isAdmitEdit && (
                      <OpportunityInlineEditable
                        fieldName="owner_user_uid"
                        label={
                          <FormattedMessage
                            id="crm.ui.account.owner"
                            defaultMessage="Owner"
                          />
                        }
                        value={opportunity.owner_user_uid}
                        displayValue={ownerUserName}
                        emptyValue="-"
                        editable={isAdmitEdit}
                        readModeStyle="CrmLabeledField"
                        editComponent={CrmUsersSelect}
                        editComponentProps={{
                          placeholder: (
                            <FormattedMessage
                              id="crm.ui.account.owner"
                              defaultMessage="Owner"
                            />
                          ),
                          input: {
                            value: opportunity.owner_user_uid
                          }
                        }}
                        handleFieldChange={this.props.handleFieldChange}
                        isAdmitEdit={isAdmitEdit}
                      />
                    )}
                    {!isAdmitEdit && (
                      <CrmLabeledField
                        noMargin
                        label={
                          <FormattedMessage
                            id="crm.ui.account.owner"
                            defaultMessage="Owner"
                          />
                        }
                      >
                        {ownerUserName}
                      </CrmLabeledField>
                    )}
                  </div>
                  <div className="col-md-5">
                    <CrmLabeledField
                      noMargin
                      label={
                        <FormattedMessage
                          id="crm.ui.oppournity.form.organization"
                          defaultMessage="Organization"
                        />
                      }
                    >
                      {opportunity.organization_unit &&
                      opportunity.organization_unit.name
                        ? opportunity.organization_unit.name
                        : '-'}
                    </CrmLabeledField>
                  </div>
                  <div className="col-md-3">
                    <CrmLabeledField
                      noMargin
                      label={
                        <FormattedMessage
                          id="crm.ui.account.started"
                          defaultMessage="Started"
                        />
                      }
                    >
                      {opportunity.created_time
                        ? getDateFormat(opportunity.created_time)
                        : '-'}
                    </CrmLabeledField>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {/* End: Header Fields */}

          <hr className="util-horizontalLine" />

          <div className="tableWrapper">
            <div className="util-flexRowSpaceBetween">
              <div
                style={{
                  paddingLeft: '10px'
                }}
                className="LabeledField-value"
              >
                <FormattedMessage
                  id="crm.ui.account.fleet_size"
                  defaultMessage="Fleet size."
                />
              </div>
              <div className="util-textCenter" style={{ width: '100px' }}>
                {!isNewOpp && (
                  <OpportunityInlineEditable
                    fieldName="quantity"
                    value={opportunity.quantity ? (opportunity.quantity >= 1 ? opportunity.quantity : 0) : 0}
                    displayValue={opportunity.quantity ? (opportunity.quantity >= 1 ? opportunity.quantity : 0) : 0}
                    editable={isEditable}
                    readModeStyle=""
                    emptyValue="0"
                    editComponent={CrmTextInputNumber}
                    editComponentProps={{
                      placeholder: (
                        <FormattedMessage
                          id="crm.quotation.quantity"
                          defaultMessage="Quantity"
                        />
                      )
                    }}
                    handleFieldChange={this.props.handleFieldChange}
                  />
                )}
                {isNewOpp && (
                  <CrmTextInputNumber
                    input={{
                      value: opportunity.quantity,
                      onChange: e => {
                        this.props.handleFieldChange(
                          'quantity',
                          e.currentTarget.value
                        )
                      }
                    }}
                    focus={!isNewOpp}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="tableWrapper">
            <div className="util-flexRowSpaceBetween">
              <div
                  style={{
                    paddingLeft: '10px'
                  }}
                  className="LabeledField-value"
              >
                <FormattedMessage
                    id="crm.ui.account.fleet_size"
                    defaultMessage="Commission % Reduction"
                />
              </div>
              <div className="util-textCenter" style={{ width: '100px' }}>
                {!isNewOpp ? (
                    isEditableCom ? (
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <OpportunityInlineEditable
                        fieldName="commission_reduction"
                        value={opportunity.commission_reduction ? (opportunity.commission_reduction >= 1 ? opportunity.commission_reduction : 0) : 0}
                        displayValue={opportunity.commission_reduction ? (opportunity.commission_reduction >= 1 ? opportunity.commission_reduction : 0) : 0}
                        readModeStyle=""
                        emptyValue="0"
                        editComponent={CrmTextInputNumber}
                        editComponentProps={{
                          placeholder: (
                              <FormattedMessage
                                  id="crm.quotation.commission_reduction"
                                  defaultMessage="commission_reduction"
                              />
                          )
                        }}
                        handleFieldChange={this.props.handleFieldChange}
                    />
                    <span style={{ marginLeft: '4px' }}>%</span>
                    </div>
                    ) : (
                        <span>{opportunity.commission_reduction || 0} %</span>
                    )
                ) : (
                    isEditableCom ? (
                    <CrmTextInputNumber
                        input={{
                          value: opportunity.commission_reduction,
                          onChange: e => {
                            this.props.handleFieldChange(
                                'commission_reduction',
                                e.currentTarget.value
                            )
                          }
                        }}
                        focus={!isNewOpp}
                        min={0}
                        max={100}
                    />
                    ) : (
                        <span>0%</span>
                    )
                )}
              </div>
            </div>
          </div>

          <div className="tableWrapper">
            <div className="util-flexRowSpaceBetween">
              <div
                  style={{
                    paddingLeft: '10px'
                  }}
                  className="LabeledField-value"
              >
                <FormattedMessage
                    id="crm.ui.account.fleet_size"
                    defaultMessage="Special Project"
                />
              </div>
              <div className="util-textCenter" style={{ width: '100px' }}>
                {!isNewOpp ? (
                    isEditableCom ? (
                      <CrmDropdown
                          placeholder={
                            <FormattedMessage
                                id="crm.ui.account.special_project"
                                defaultMessage=" "
                            />
                          }
                          options={specialProjectOptions}
                          input={{
                            value: opportunity.special_project ? "true" : "false",
                            onChange: value => {
                              this.props.handleFieldChange(
                                  'special_project',
                                  value
                              )
                            }
                          }}
                      />
                    ) : (
                        <span>No</span>
                    )
                ) : (
                    isEditableCom ? (
                      <CrmDropdown
                          options={specialProjectOptions}
                          input={{
                            value: opportunity.special_project ? "true" : "false",
                            onChange: value => {
                              this.props.handleFieldChange(
                                  'special_project',
                                   value
                              )
                            }
                          }}
                          focus={!isNewOpp}
                    />
                    ) : (
                        <span>{opportunity.special_project ? "Yes" : "No"}</span>
                    )
                )}
              </div>
            </div>
          </div>


          <div className="tableWrapper">
            <div className="util-flexRowSpaceBetween">
              <div
                style={{ paddingLeft: '10px' }}
                className="LabeledField-value"
              >
                <FormattedMessage
                  id="crm.ui.account.business_source_label"
                  defaultMessage="Business Source"
                />
              </div>

              <div className="util-textCenter" style={{ width: '100px' }}>
                {
                  isNewOpp ?
                    (
                      <BusinessSourceSelect
                        input={{
                          value: opportunity.business_source,
                          onChange: value => {
                            this.props.handleFieldChange(
                              'business_source',
                              value
                            )
                          }
                        }}
                        placeholder="Business Source"
                      />
                    ) :
                    (
                      !isWon && !isLost ? (
                        <OpportunityInlineEditable
                          fieldName="business_source"
                          value={opportunity.business_source}
                          displayValue={opportunity.business_source}
                          editable={!isWon && !isLost}
                          readModeStyle="CrmLabeledField"
                          editComponent={BusinessSourceSelect}
                          editComponentProps={{
                            placeholder: (
                              <FormattedMessage
                                id="crm.quotation.business_source"
                                defaultMessage="Business Source"
                              />
                            )
                          }}
                          handleFieldChange={this.props.handleFieldChange}
                        />
                      ) : (
                        <span>{opportunity.business_source}</span>
                      )
                    )
                }
              </div>
            </div>
          </div>

          <div className="tableWrapper">
            <div className="util-flexRowSpaceBetween">
              <div
                style={{ paddingLeft: '10px' }}
                className="LabeledField-value"
              >
                <FormattedMessage
                  id="crm.ui.account.forecast_label"
                  defaultMessage="Forecast"
                />
              </div>
              <div className="util-textCenter" style={{ width: '100px' }}>
                {isEditable ? (
                  <CrmDropdown
                    placeholder={
                      !isNewOpp && <FormattedMessage
                        id="crm.ui.account.forecast"
                        defaultMessage=" "
                      />
                    }
                    options={forecastOptions}
                    input={{
                      value: opportunity.forecast ? "true" : "false",
                      onChange: value => this.props.handleFieldChange('forecast', value)
                    }}
                  />
                ) : (
                  <span>{opportunity.forecast ? "Yes" : "No"}</span>
                )}
              </div>
            </div>
          </div>

          <div className="tableWrapper">
            <div className="util-flexRowSpaceBetween">
              <div
                style={{ paddingLeft: '10px' }}
                className="LabeledField-value"
              >
                <FormattedMessage
                  id="crm.ui.account.current_progress_update_label"
                  defaultMessage="Current Progress Update"
                />
              </div>

              <div className="util-textCenter" style={{ width: '200px', textAlign: 'justify' }}>
                {
                  isNewOpp ?
                    (
                      <CrmTextArea
                        input={{
                          value: opportunity.current_progress_update,
                          onChange: e => {
                            this.props.handleFieldChange(
                              'current_progress_update',
                              e.currentTarget.value
                            )
                          }
                        }}
                        placeholder="Current Progress Update"
                      />
                    ) :
                    (
                      !isWon && !isLost ? (
                        <OpportunityInlineEditable
                          fieldName="current_progress_update"
                          value={opportunity.current_progress_update}
                          displayValue={opportunity.current_progress_update}
                          editable={!isWon && !isLost}
                          readModeStyle="CrmLabeledField"
                          editComponent={CrmTextArea}
                          editComponentProps={{
                            placeholder: (
                              <FormattedMessage
                                id="crm.quotation.current_progress_update"
                                defaultMessage="Current Progress Update"
                              />
                            )
                          }}
                          handleFieldChange={this.props.handleFieldChange}
                        />
                      ) : (
                        <div style={{ textAlign: 'justify', textAlignLast: 'right', fontSize: '0.8em' }}>{opportunity.current_progress_update}</div>
                      )
                    )
                }
              </div>
            </div>
          </div>

          <div className="tableWrapper">
            <div className="util-flexRowSpaceBetween">
              <div
                style={{ paddingLeft: '10px' }}
                className="LabeledField-value"
              >
                <FormattedMessage
                  id="crm.ui.account.action_plan_for_next_steps_label"
                  defaultMessage="Action Plan for Next Steps"
                />
              </div>

              <div className="util-textCenter" style={{ width: '200px', textAlign: 'justify' }}>
                {
                  isNewOpp ?
                    (
                      <CrmTextArea
                        input={{
                          value: opportunity.action_plan_for_next_steps,
                          onChange: e => {
                            this.props.handleFieldChange(
                              'action_plan_for_next_steps',
                              e.currentTarget.value
                            )
                          }
                        }}
                        placeholder="Action Plan for Next Steps"
                      />
                    ) :
                    (
                      !isWon && !isLost ? (
                        <OpportunityInlineEditable
                          fieldName="action_plan_for_next_steps"
                          value={opportunity.action_plan_for_next_steps}
                          displayValue={opportunity.action_plan_for_next_steps}
                          editable={!isWon && !isLost}
                          readModeStyle="CrmLabeledField"
                          editComponent={CrmTextArea}
                          editComponentProps={{
                            placeholder: (
                              <FormattedMessage
                                id="crm.quotation.action_plan_for_next_steps"
                                defaultMessage="Action Plan for Next Steps"
                              />
                            )
                          }}
                          handleFieldChange={this.props.handleFieldChange}
                        />
                      ) : (
                        <div style={{ textAlign: 'justify', textAlignLast: 'right', fontSize: '0.8em' }}>{opportunity.action_plan_for_next_steps}</div>
                      )
                    )
                }
              </div>
            </div>
          </div>

          <div className="util-flexGrow" />

          {/* Buttons */}
          <div className="buttonsWrapper">
            <div className="util-flexRow">
              {!isNewOpp && (
                <React.Fragment>
                  {this.props.accountPermissionsContext.hasPermissionByType(
                    PERMISSIONS_TYPES.OPPORTUNITIES_MANAGE
                  ) && (
                    <div>
                      <CrmButton
                        grouped
                        xsmall
                        icon="trash"
                        enable={true}
                        onClick={this.props.handleDeleteOpportunity}
                      />
                    </div>
                  )}
                  <div className="util-flexGrow" />
                  {!isWon &&
                    !isLost &&
                    this.props.accountPermissionsContext.hasPermissionByType(
                      PERMISSIONS_TYPES.OPPORTUNITIES_MODIFY
                    ) && (
                      <div>
                        <CrmButton
                          label={
                            <FormattedMessage
                              id="crm.ui.button.opportunity_lost"
                              defaultMessage="Lost"
                            />
                          }
                          grouped
                          onClick={this.handleOpportunityLost}
                        />
                        <CrmButton
                          label={
                            <FormattedMessage
                              id="crm.ui.button.create_quotation"
                              defaultMessage="Create Quotation"
                            />
                          }
                          grouped
                          type="primary"
                          onClick={() => this.handleQuickQuotationOpen()}
                        />
                      </div>
                    )}
                </React.Fragment>
              )}

              {isNewOpp && (
                <React.Fragment>
                  <div>
                    <CrmButton
                      label={
                        <FormattedMessage
                          id={'crm.ui.button.cancel'}
                          defaultMessage={'Cancel'}
                        />
                      }
                      grouped
                      onClick={this.props.handleCloseOpportunity}
                    />
                  </div>
                  <div className="util-flexGrow" />
                  <div>
                    <CrmButton
                      label={
                        <FormattedMessage
                          id="crm.ui.button.save_opportunity"
                          defaultMessage="Save"
                        />
                      }
                      onClick={this.handleSaveOpportunity}
                      isSaving={this.state.isSaving}
                      type="primary"
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
            {this.state.isShowingOpportunityLostWidget && (
              <OpportunityWinLoseWidget
                processTypeUid={
                  accountProcessType
                    ? accountProcessType.process_type_uid
                    : undefined
                }
                lastProcessTypeUid={
                  lastSalesProcessType
                    ? lastSalesProcessType.process_type_uid
                    : undefined
                }
                opportunity={this.props.opportunity}
                onCancel={() =>
                  this.setState({ isShowingOpportunityLostWidget: false })
                }
                onSaved={this.handleOpportunityLoseSaved}
              />
            )}
          </div>
          {/* End: Buttons */}
        </div>
      </React.Fragment>
    )
  }
}

OpportunityForm.propTypes = {
  handleCloseOpportunity: func.isRequired,
  opportunity: shape({}),
  account: shape().isRequired,
  onGenerateQuotationForOpportunity: func.isRequired,
  handleSaveOpportunity: func.isRequired,
  handleOpportunityTypeChange: func.isRequired,
  handleFieldChange: func.isRequired,
  productList: arrayOf(shape()),
  handleDeleteOpportunity: func.isRequired,
  isGeneratingQuotation: bool.isRequired,
  handleQuotationClick: func.isRequired,
  isAdmin: bool.isRequired,
  adminCorrectDataSelected: bool,
  onRunOpportunityCommand: func.isRequired,
  handleQuickQuotationOpen: func.isRequired,
  accountPermissionsContext: shape({}).isRequired,
  editableContext: shape({}).isRequired
}

OpportunityForm.defaultProps = {
  opportunity: undefined,
  productList: undefined,
  adminCorrectDataSelected: false
}

export default generateQuotationForOpportunityHoc(
  withOpportunityCommandHoc(
    withAccountPermissionsContextHOC(withEditableContextHOC(OpportunityForm))
  )
)
