import { accountsListReducerFactory } from '../accounts-list-reducer'

const views = [
  {
    name: 'All My Customers',
    code: 'all',
    filter: {
      kind: 'customer'
    }
  }
]
const accountsCustomersReducer = accountsListReducerFactory('customers', {
  views
})

export default accountsCustomersReducer
