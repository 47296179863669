import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

interface ICrmCheckboxProps {
  value?: any
  name?: string
  label?: string
  extraClassNames?: {
    labelClassNames: string
    inputClassNames: string
    containerClassNames: string
  }
  input: {
    name?: string
    value?: any
    onChange: Function
  }
  onChange?: Function
  disabled?: boolean
  small?: boolean
  xsmall?: boolean
  indeterminate?: boolean
  style?: React.CSSProperties
  noWrap?: boolean
  labelMessageId?: string
  intl?: any
}

class CrmCheckbox extends PureComponent<ICrmCheckboxProps> {
  constructor(props) {
    super(props)
  }

  handleChange = e => {
    if (!this.props.disabled) {
      const onChange = this.props.input.onChange || this.props.onChange
      onChange(e.currentTarget.checked, this.props.name)
    }
  }

  handleLabelClick = e => {
    e.preventDefault()

    if (!this.props.disabled) {
      const onChange = this.props.input?.onChange || this.props.onChange
      const value = this.props.input?.name ? this.props.input?.value : this.props.value

      onChange(!value, this.props.name)
    }
  }

  render() {
    const {
      value,
      input,
      label,
      extraClassNames,
      disabled,
      name,
      intl,
      small,
      xsmall,
      indeterminate,
      style,
      noWrap,
      labelMessageId
    } = this.props

    const checked = input?.value === undefined ? value : input.value
    return (
      <div
        style={style}
        className={`CrmCheckbox 
          ${extraClassNames?.containerClassNames} 
          ${small ? 'CrmCheckbox--small' : ''}
          ${xsmall ? 'CrmCheckbox--xsmall' : ''}
          ${noWrap ? 'CrmCheckbox--noWrap' : ''}
          ${indeterminate ? 'is-indeterminate' : ''}`}
      >
        <input
          name={name}
          type="checkbox"
          value={value}
          id={name}
          checked={indeterminate || checked}
          className={`CrmCheckbox-input ${extraClassNames?.inputClassNames || ''}`}
          onChange={this.handleChange}
          disabled={disabled}
        />
        <label htmlFor={name} className={`CrmCheckbox-box`} onClick={this.handleLabelClick} />
        {label ? (
          <span className="CrmCheckbox-label" onClick={this.handleLabelClick}>
            {typeof label === 'string'
              ? intl.formatMessage({
                  id: labelMessageId || `checkbox.${label}`,
                  defaultMessage: label
                })
              : label}
          </span>
        ) : null}
      </div>
    )
  }
}

const CrmCheckboxInject = injectIntl(CrmCheckbox)

export default CrmCheckboxInject
