import gql from 'graphql-tag'

export const qlTasksFields = ` 
  task_uid 
  account_uid
  process_uid 
  name 
  status_code
  created_time
  remarks
  priority
  assignee_type
  assignee_user_uid
  assignee_user { full_name }
  assignee_group_uid 
  assignee_group { name }
  due_date
  process_type_name
  assignments { 
    due_date 
    user { user_uid full_name } 
    assignee_type
    due_date_type
    due_date_precision
    resource_pool { name } 
  }  
  task_type { 
    task_type_uid
    name
    kind
  }
  `
export const qlTasksFieldsNew = ` 
  task_uid 
  account_uid
  process_uid 
  name 
  status_code
  created_time
  remarks
  priority
  assignee_type
  assignee_user_uid
  assignee_user_name
  assignee_group_uid 
  assignee_group_name
  due_date
  process_type_name
  assignments { 
    due_date 
    user { user_uid full_name } 
    assignee_type
    due_date_type
    due_date_precision
    resource_pool { name } 
  }  
   name
  `
export const fragmentTaskFields = gql`
fragment TasksFields on Task {
 ${qlTasksFields}
}
`
export const qlqAccountTasks = gql`
query tasks($account_uid: String!) { 
account(account_uid: $account_uid) { account_uid tasks {${qlTasksFields} } }
}
`
export const qlqTasksList = gql`
query task_todo_pages(
  $filter: JSON
  $limit: Int
  $offset: Int
  $sort: [JSON]
  $aggregate: [JSON]
) {
  task_todo_pages(
    filter: $filter
    limit: $limit
    offset: $offset
    sort: $sort
    aggregate: $aggregate
  ) {
    data {
      account_uid account { name latest_step_type_name  } ${qlTasksFields}
    }
    count
  }
}
`
export const qltaskListV2 = gql`
query tasks_todo_paged(
  $filter: JSON
  $limit: Int
  $offset: Int
  $sort: [JSON]
  $aggregate: [JSON]
) {
  tasks_todo_paged(
    filter: $filter
    limit: $limit
    offset: $offset
    sort: $sort
    aggregate: $aggregate
  ) {
    data {
      account_uid account { name latest_step_type_name  } ${qlTasksFieldsNew}
    }
    count
  }
}
`
export const qlqTasksListPaged = gql`
  query tasks_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    tasks_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      data {
        task_uid
        task_name
        task_type_name
        task_type_kind
        task_owner_name
        process_uid
        account_uid
        account_name
        lead_source_name
        lead_source_uid
        campaign_name
        campaign_uid
        process_name
        process_type_name
        process_type_kind
        task_due_date
        locked_by_user_name
        locked_till
        assignee_type
      }
      count
    }
  }
`

export const qlqTasksTodoListPaged = gql`
  query tasks_todo_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    tasks_todo_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      data {
        task_uid
        task_name
        task_type_name
        task_type_kind
        task_owner_name
        process_uid
        account_uid
        account_name
        lead_source_name
        lead_source_uid
        lead_in_date
        campaign_name
        campaign_uid
        process_name
        process_type_name
        process_type_kind
        task_due_date
        task_due_day
        assignee_type
        resource_pool_name
        current_step_name
        locked_by_user_name
        locked_till
        overdue_range
        is_overdue
        is_today
        is_future_day
      }
      count
    }
  }
`

export const qGetNextTask = `
mutation task_get_next($lock: Boolean, $filter: JSON) {
  task_get_next(lock: $lock, filter: $filter)
}
`
export const qlmGetNextTask = gql(qGetNextTask)
