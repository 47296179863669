/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { shape, number } from 'prop-types'
import { FormattedRelativeTime } from 'react-intl'

import {
  getCurrentTaskStatus,
  getNextTaskcountdown
} from 'crm-duxs/dialer-reducer'

class DialerCurrentTaskStatus extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { currentTaskStatus, nextTaskCountdown } = this.props
    console.log('currentTaskStatus  ', this.props)
    if (!currentTaskStatus) {
      return <span />
    }
    return (
      <div>
        Task Status:
        {currentTaskStatus.name} since
        <FormattedRelativeTime value={currentTaskStatus.statusStartedAt} />
        {nextTaskCountdown && <div>Next Task in {nextTaskCountdown}</div>}
      </div>
    )
  }
}

DialerCurrentTaskStatus.propTypes = {
  currentTaskStatus: shape(),
  nextTaskCountdown: number
}

DialerCurrentTaskStatus.defaultProps = {
  currentTaskStatus: undefined,
  nextTaskCountdown: undefined
}

function mapStateToProps(state) {
  return {
    currentTaskStatus: getCurrentTaskStatus(state),
    nextTaskCountdown: getNextTaskcountdown(state)
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialerCurrentTaskStatus)
