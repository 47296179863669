import React, { useState, useEffect } from 'react'
import { useCrmFilterContext } from '../dashboards/dashboard-context/crm-filter-context'
import { UserInfoCard } from '../ct-dashboards/rm-dashboard/rm-summary-dashboard'
import { OrganizationUnitWidget } from '../widgets/organization-unit-widget'
import useCrmCurrentUser from '../../../core/auth/hooks/use-crm-current-user'
import { CrmFullpageContainer } from '../../../components/index'
import { WidgetFitmentSummary } from '../widgets/fitment-summary/widget-fitment-summary'
import { WidgetHeadCount } from '../widgets/headcount/headcount-wiget'
import { WidgetCustomersWon } from '../widgets/wins/widget-customers-won'
import { WidgetFirstMeetingSummary } from '../widgets/meetings/my-first-meeting-status-widget'
import { WidgetCustomersWinRatio } from '../widgets/wins/widget-customer-win-ratio-widget'
import { WidgetFollowUpMeetingProspectSummary } from '../widgets/meetings/my-follow-up-meetings-prospects-widget'
import { WidgetFollowUpMeetingClientSummary } from '../widgets/meetings/my-follow-up-meetings-client-widget'
import { useGetUserInformation } from 'crm-duxs/user-session'
import { useGetAnalyticContextHeadCount, DashboardFilterBar } from './tools'
import { CrmLoadingIcon } from 'crm-components'
import { getWidgetAnalyticContext } from '../dashboards/tools/util-widget-dashboard'
import { ReportProvider } from 'crm-utils/reports-utils'

export const SaleManagerDashboard: React.SFC = props => {
  const analyticsContext = useCrmFilterContext()
  const currentUser = useCrmCurrentUser()
  const userUid = currentUser?.user_uid
  const { getOrganizationUnit, loading } = useGetUserInformation(userUid)
  const organizationUnit = getOrganizationUnit()
  const topOrganizationUnit = organizationUnit?.organization_unit_uid
  const [selectedOrganizationUnit, setOrganizationUnit] = useState<string>(organizationUnit?.organization_unit_uid)
  const [organizationName, setOrganizationName] = useState<string>(organizationUnit.name)

  useEffect(() => {
    if (!loading) {
      onChangeOrganization(organizationUnit)
    }
  }, [organizationUnit?.organization_unit_uid])

  const onChangeOrganization = (organization): void => {
    const nameValue = organization?.name ?? organizationUnit?.name
    const uidValue = organization?.organization_unit_uid ?? organizationUnit?.organization_unit_uid
    setOrganizationName(nameValue)
    setOrganizationUnit(uidValue)
    const newFilter = {
      organization_unit: { organization_unit_uid: { value: uidValue, label: nameValue } },
      user_uid: undefined
    }
    analyticsContext.setFilters(newFilter)
  }

  return (
    <CrmFullpageContainer>
      <DashboardFilterBar
        topOrganizationUnit={topOrganizationUnit}
        selectedOrganizationUnit={selectedOrganizationUnit}
        onChangeOrganization={onChangeOrganization}
        organizationName={organizationName}
      />
      {!loading && <SaleManagerDashboardInner />}
    </CrmFullpageContainer>
  )
}

export const SaleManagerDashboardInner: React.SFC = () => {
  const analyticsContext = getWidgetAnalyticContext()
  const userInfo = useGetUserInformation(useCrmCurrentUser()?.user_uid)

  if (!analyticsContext?.filters?.organization_unit && !userInfo.loading) {
    const organizationUnit = userInfo.getOrganizationUnit()
    analyticsContext.filters.organization_unit = {
      organization_unit_uid: {
        value: organizationUnit.organization_unit_uid,
        label: organizationUnit.name
      }
    }
  }

  const [headcounts, loading] = useGetAnalyticContextHeadCount(analyticsContext)
  const user = { user_uid: useCrmCurrentUser()?.user_uid }
  const organizationUnitName = analyticsContext?.filters?.organization_unit?.organization_unit_uid?.label

  return (
    <React.Fragment>
      {userInfo.loading && loading && (
        <div className="util-relative util-fullExpand">
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)'
            }}
          >
            <CrmLoadingIcon />
          </div>
        </div>
      )}
      {!loading && !userInfo.loading && (
        <div className="util-paddingXl">
          <div className="row">
            <div className="col-md-2">
              {organizationUnitName && <OrganizationUnitWidget organizationUnitName={organizationUnitName} />}
              {!organizationUnitName && <UserInfoCard user={user} />}
            </div>
            <div className="col-md-3">
              <WidgetHeadCount headcounts={headcounts} title="Total Number RMs" />
            </div>
            <ReportProvider>
              <div className="col-md-3">
                <WidgetFitmentSummary headcounts={headcounts} />
              </div>
              <div className="col-md-3" />
              <div className="col-md-2" />
              <div className="col-md-3">
                <WidgetCustomersWon headcounts={headcounts} />
              </div>
              <div className="col-md-3">
                <WidgetCustomersWinRatio headcounts={headcounts} />
              </div>
              <div className="col-md-3" />
              <div className="col-md-2" />
              <div className="col-md-3">
                <WidgetFirstMeetingSummary headcounts={headcounts} />
              </div>
              <div className="col-md-3">
                <WidgetFollowUpMeetingProspectSummary headcounts={headcounts} />
              </div>
              <div className="col-md-3">
                <WidgetFollowUpMeetingClientSummary headcounts={headcounts} />
              </div>
            </ReportProvider>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
