import React from 'react'
import { shape, func, string } from 'prop-types'
import { Spacer } from 'util-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import { getPriceTableCycleName, getSalesTypeName } from 'crm-data/prices-table'

const PriceOptionItem = (props) => {
  const selected =
    props.selectedPrice === props.priceTableItem.price_table_item_uid
  const selectedClass = selected ? 'CardItem__Selected' : ''

  return (
    <div>
      <div
        className={`ActivityListItem-headerItem-value CardItem ${selectedClass}`}
        onClick={() => {
          props.handlePriceSelected(props.priceTableItem)
        }}
      >
        <div className={`CardItem__ContentWrapper`}>
          <div className={`CardItem__Content`}>
            <div className="util-flexColumn">
              <div className="util-flexRowSpaceBetween util-marginLeft util-marginRight">
                <div>
                  {props.priceTableItem.pay_option === 'all'
                    ? ''
                    : getSalesTypeName(props.priceTableItem.pay_option)}
                </div>
                <div>{getPriceTableCycleName(props.priceTableItem.cycle)}</div>
              </div>
              <div className="util-flexGrow">
                <Spacer height="20px" />
              </div>
              <div className="util-flexRowSpaceBetween">
                <div className="LabeledField">
                  <div className="LabeledField-label">
                    <FormattedMessage
                      id="crm.ui.oppournity.form.hardware"
                      defaultMessage="Hardware"
                    />
                  </div>
                  <div className="LabeledField-value util-textCenter">
                    <span className="" style={{ fontSize: '16px' }}>
                      {props.priceTableItem.value_net}
                    </span>
                  </div>
                </div>
                <div className="LabeledField">
                  <div className="LabeledField-label">
                    <FormattedMessage
                      id="crm.quotation.1_year"
                      defaultMessage="1 Year"
                    />
                  </div>
                  <div className="LabeledField-value util-textCenter">
                    <span className="" style={{ fontSize: '16px' }}>
                      {props.priceTableItem.subscription_net}
                    </span>
                  </div>
                </div>
                <div className="LabeledField">
                  <div className="LabeledField-label">
                    <FormattedMessage
                      id="crm.quotation.2_3_years"
                      defaultMessage="2-3 Years"
                    />
                  </div>
                  <div className="LabeledField-value util-textCenter">
                    <span className="" style={{ fontSize: '16px' }}>
                      {props.priceTableItem.subscription_net_next_years}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
PriceOptionItem.propTypes = {
  handlePriceSelected: func.isRequired,
  priceTableItem: shape({}).isRequired,
  selectedPrice: string
}
PriceOptionItem.defaultProps = {
  selectedPrice: null
}

export default injectIntl(PriceOptionItem)
