/* eslint-disable */
import React from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { FormattedDate, FormattedTime } from 'react-intl'
import { CrmButton } from 'crm-components'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import { toMSformat, msToDateformat } from 'crm-utils/time-utils'
import _ from 'lodash'

const requestLogQL = gql`
  query hook_logs_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    hook_logs_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        id
        data
        server_data
        created_at
        status
        external_token_uid
        external_source_uid
        external_system_id
        response
      }
    }
  }
`

const qlOptions = {
  options: ownProps => {
    const startDate = moment(ownProps.startDate, 'DD-MM-YYYY').format()
    const endDate = moment(ownProps.endDate, 'DD-MM-YYYY').format()
    return {
      variables: {
        filter: {
          created_at: { $gte: startDate, $lte: endDate },
          query_hook_log: ownProps.filterText
        }
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.hook_logs_qm_paged : []
  })
}

const Table = genericTableFactory({
  query: requestLogQL,
  qlOptions
})

const TableInner = props => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'data',
      Cell: row => <div>{row.original.data ? row.original.data.name : ''}</div>
    },
    {
      Header: 'Server context',
      accessor: 'server_data',
      Cell: row => (
        <div>
          {row.original.server_data ? row.original.server_data.SERVER_NAME : ''}
        </div>
      )
    },
    {
      Header: 'Account UID',
      Cell: row => {
        let accountUID = row.original.response
          ? row.original.response.account_uid
          : ''
        if (!accountUID && _.has(row, 'original.response.data')) {
          accountUID = row.original.response.data.account_uid
        }

        return <div>{accountUID}</div>
      }
    },
    {
      Header: 'Source ID',
      accessor: 'external_source_uid'
    },
    {
      Header: 'System ID',
      accessor: 'external_system_id'
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: row =>
        row.original.created_at ? (
          <div>
            <FormattedDate value={row.original.created_at} />{' '}
            <FormattedTime value={row.original.created_at} />
          </div>
        ) : (
          <span />
        )
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Control',
      Cell: row => {
        const handleRetryHook = async event => {
          await props.handleClickRetryHook(row.original, event)
        }

        const handleOpenInfo = () => {
          props.handleClickOpenInfo(row.original)
        }

        return (
          <div>
            <CrmButton
              onClick={handleOpenInfo}
              label="Open"
              className="primary"
              small
              margin
            />
            {row.original.status !== 'new_account' &&
              row.original.status !== 'success' &&
              row.original.status !== 'duplicate' && (
                <CrmButton
                  onClick={handleRetryHook}
                  label="Retry"
                  className="info"
                  small
                  blue
                  disabled={props.isDisabledRetry}
                />
              )}
          </div>
        )
      }
    }
  ]

  return <Table {...props} columns={columns} />
}

export default TableInner
