/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom'
import { shape, func, string } from 'prop-types'
import gql from 'graphql-tag'

import { FormattedDate, FormattedTime } from 'react-intl'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import CrmWidget from '../../../components/crm-widget.jsx'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'

export const dialerAnalyticsQl = gql(`
  query dialer_campaign_result_analytics_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    dialer_campaign_result_analytics_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      data {
        database_uid
        instance_uid
        account_uid
        account_name
        lead_source_uid
        lead_source_name
        lead_in_date
        process_type_name
        current_step_name
        current_step_type_uid
        process_started_time
        current_step_started_time
        process_started_date
        process_completed_time
        process_completed_date
        process_step_step
        owner_user_name
        owner_user_uid
        owner_pool_name
        owner_pool_uid
        owner_name
        process_uid
        task_uid
        task_name
        task_owner_name
        assignee_type
        task_due_date
        task_type_name
        resource_pool_name
        count
        process_step_kind
        process_started_hour
        campaign_uid
        campaign_name
        account_meta
        prospect_owner_name
        prospect_owner_user_uid
        prospect_started_time
        prospect_process_step_name
        last_meeting_start_time
        last_meeting_status_name
        last_meeting_status_kind
        last_meeting_performer_user_name
        market_potential_size
      }
      count
    }
  }
`)

const columns = [
  {
    Header: 'Account NAme',
    Cell: row => (
      <Link to={'/crm/account/' + row.original.account_uid} target="blank">
        {row.original.account_name}
      </Link>
    ),
    accessor: 'account_name'
  },
  {
    Header: 'Date Won',
    accessor: 'process_completed_date',
    Cell: row => (
      <span>
        <FormattedDate value={row.original.process_completed_date} />{' '}
        <FormattedTime value={row.original.process_completed_date} />
      </span>
    )
  },
  {
    Header: 'Result',
    accessor: 'current_step_name'
  },
  {
    Header: 'Owner',
    accessor: 'owner_user_name'
  },
  {
    Header: 'Lead Source',
    accessor: 'lead_source_name'
  },
  {
    Header: 'Lead In Date',
    accessor: 'lead_in_date',
    Cell: row => (
      <span>
        <FormattedDate value={row.original.lead_in_date} />{' '}
        <FormattedTime value={row.original.lead_in_date} />
      </span>
    )
  },
  {
    Header: 'Prospect Started',
    accessor: 'prospect_started_time',
    Cell: row =>
      row.original.prospect_started_time ? (
        <span>
          <FormattedDate value={row.original.prospect_started_time} />{' '}
          <FormattedTime value={row.original.prospect_started_time} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Prospect Owner',
    accessor: 'prospect_owner_name'
  },
  {
    Header: 'Last Meeting Start',
    accessor: 'last_meeting_start_time',
    Cell: row =>
      row.original.last_meeting_start_time ? (
        <span>
          <FormattedDate value={row.original.last_meeting_start_time} />{' '}
          <FormattedTime value={row.original.last_meeting_start_time} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Last Meeting Status',
    accessor: 'last_meeting_status_name'
  },
  {
    Header: 'Last Meeting User',
    accessor: 'last_meeting_performer_user_name'
  },
  {
    Header: 'Potential',
    accessor: 'market_potential_size'
  }
]

const qlOptions = {
  options: ownProps => {
    console.log('qlOtions, ', ownProps)
    const filter = { ...ownProps.filter, ...mapContextToFilter(ownProps) }
    filter.is_final_step = 'true'
    filter.process_step_kind = 'won'

    return {
      variables: {
        filter
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl
      ? tableDataQl.dialer_campaign_result_analytics_list_qm_paged
      : []
  }),
  fetchPolicy: 'no-cache'
}

const TelesalesWinsTable = genericTableFactory({
  query: dialerAnalyticsQl,
  qlOptions
})

class WidgetTelesalesSalesToProspect extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggleIsLoading(val, handler) {
    return this.setState({ ...this.state, isLoading: val }, handler)
  }

  renderSummary = () => (
    <div>
      {this.state.totalDispositions && (
        <span>Total Dispositions: {this.state.totalDispositions}</span>
      )}
    </div>
  )

  handleCurrentStepClick = event => {
    console.log('handleCurrentStepClick', event)
    this.setState({ selectedStepTypeUid: event.currentTarget.id })
    this.props.onCurrentStepClick({
      process_step_type_uid: event.currentTarget.id
    })
  }

  render() {
    console.log('Render widget new leads by current status', this.state)
    return (
      <CrmWidget
        title={
          this.props.title
            ? this.props.title
            : 'Telesales Meetings - Converted to Prospect'
        }
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        fixHeight
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-wins"
      >
        <TelesalesWinsTable
          columns={columns}
          style={{ width: '100%' }}
          analyticsContext={this.props.analyticsContext}
        />
      </CrmWidget>
    )
  }
}

WidgetTelesalesSalesToProspect.propTypes = {
  analyticsContext: shape({}).isRequired,
  onCurrentStepClick: func.isRequired,
  title: string
}
WidgetTelesalesSalesToProspect.defaultProps = {
  title: undefined
}

export default WidgetTelesalesSalesToProspect
