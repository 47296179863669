/* eslint-disable camelcase */
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { func, shape, bool } from 'prop-types'
import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import { injectIntl } from 'react-intl'
import MeetingFormInner from './meeting-form-inner.jsx'
import activityPageQlHoc from '../../activities/component/activity-form-ql-hoc'
import { LoadingIcon } from 'util-components'

import {
  getActivity,
  getIsSaving,
  getActivityType
} from 'crm-duxs/activity-reducer'

class MeetingForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity ? this.props.activity : undefined,
      errorMessages: []
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState({ ...this.state, ...newState }, () => {
        resolve()
      })
    })

  handleFormSubmit = async activity => {
    console.log('MeetingForm handleFormSubmit', activity)
    this.setState({ errorMessages: [] })
    try {
      const res = await this.props.onSubmit(activity)
      console.log('Saved', res)
      toastr.success('Activity saved')
      this.props.onCancel()
    } catch (err) {
      console.error('Problem saving activity: ' + err)
      toastr.error('Problem saving activity: ' + err.message)
      this.setState({
        errorMessages: ['Problem saving activity: ' + err.message]
      })
    }
  }

  handleErrors = errors => {
    this.setState({ errorMessages: errors })
  }

  handleGotoMinutes = () => {
    console.log('handleGotoMinutes')
    this.props.onCancel()
    this.props.doChangePage(
      '/crm/account/' +
        this.props.activity.account_uid +
        '/activity/' +
        this.props.activity.activity_uid +
        '/meeting-minutes'
    )
  }

  _getFormKey() {
    let id = ''
    if (this.props.activity && this.props.activity.activity_uid) {
      id = this.props.activity.activity_uid
    } else if (this.props.activity && this.props.activity.tmpId) {
      id = this.props.activity.tmpId
    }
    return 'activity-' + id
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.activityQl !== this.props.activityQl &&
      nextProps.activityQl.activity
    ) {
      this.setState({ ...this.state, activity: nextProps.activityQl.activity })
    }
  }

  render() {
    console.log('render meeting-form', this.props, this.state)
    const { isSaving } = this.props
    const isLoading = this.props.activityQl
      ? this.props.activityQl.loading
      : false
    const activity = this.state.activity
    const activityType = activity ? activity.activity_type : undefined
    const availableStatuses = this.props.getAvailableStatuses(activity)

    return (
      <React.Fragment>
        {isLoading && <LoadingIcon />}
        {!isLoading && activity && activityType && (
          <MeetingFormInner
            account={this.props.account}
            persons={this.props.account.persons}
            addresses={this.props.account.addresses}
            activity={activity}
            availableStatuses={availableStatuses}
            onSubmit={this.handleFormSubmit}
            onCancel={this.props.onCancel}
            isSavingNewMeeting={isSaving}
            errorMessages={this.state.errorMessages}
            handleErrors={this.handleErrors}
            onGotoMinutes={this.handleGotoMinutes}
            // validationRules={{
            //   guests: { required: true, must_have_email: true }
            // }}
            // errorMessages={['Test error validate 1', 'Test error validate 2']}
          />
        )}
      </React.Fragment>
    )
  }
}

MeetingForm.propTypes = {
  account: shape({}).isRequired,
  activity: shape({}).isRequired,
  isSaving: bool.isRequired,
  onCancel: func,
  doChangePage: func.isRequired,
  dispatch: func.isRequired,
  activityQl: shape({}),
  onSubmit: func.isRequired,
  getAvailableStatuses: func.isRequired
}

function mapStateToProps(state, ownProps) {
  const activity = ownProps.activity ? ownProps.activity : getActivity(state)
  return {
    activity,
    activityType: getActivityType(state),
    isSaving: getIsSaving(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangePage: path => dispatch(push(path)),
    dispatch
  }
}

/* Inject QL */

const MeetingFormIntl = injectIntl(activityPageQlHoc(MeetingForm))
export default connect(mapStateToProps, mapDispatchToProps)(MeetingFormIntl)
