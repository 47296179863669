import gql from 'graphql-tag'

const requestJobsQL = gql`
  query list_jobs(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $offset: Int
    $filter: JobConnectionFilter
    $sort: [JobConnectionSort]
    $range: JobConnectionRange
  ) {
    list_jobs(
      first: $first
      last: $last
      after: $after
      before: $before
      offset: $offset
      filter: $filter
      sort: $sort
      range: $range
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        created_time
        job_uid
        correlation_uid
        job_name
        type
        status
        result
        input
        meta
        created_time
        has_children
        logs {
          message
          json
          created_time
        }
      }
    }
  }
`

export const requestJobTypesQL = gql`
  query list_job_types($filter: JobConnectionFilter) {
    list_job_types(filter: $filter) {
      types
    }
  }
`

export default requestJobsQL
