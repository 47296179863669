/* eslint-disable camelcase */
import { buildCrmReducer } from 'crm-utils/reducer-utils'

// Initial
const initialState = {
  opportunity: undefined,
  selectedDocumentUid: undefined,
  isEditing: false,
  isSaving: false
}

// Actions
export const actions = {
  startOpportunityEdit: {},
  selectQuotation: {
    updater: action => ({ selectedDocumentUid: action.payload.documentUid })
  },
  setEditedOpportunity: {
    updater: action => ({
      isEditing: true,
      opportunity: action.payload.opportunity,
      selectedDocumentUid: action.payload.documentUid,
      availableStatuses: [] // Reset and reload available statuses
    })
  },
  endOpportunityEdit: {
    updater: () => ({ isEditing: false, opportunity: undefined })
  },
  fetchAvailableStatusesSuccess: {
    updater: action => {
      // console.log('fetchAvailableActionsSuccess updater')
      // console.dir(action)
      return { availableStatuses: action.payload.availableStatuses }
    }
  },
  // Starts editing new opportunityF
  initNewOpportunity: {}
}

function functionOpportunityReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

// Selectors
export const getIsEditing = state => state.crm.opportunity.isEditing
export const getIsSaving = state => state.crm.opportunity.isSaving
export const getAvailableStatuses = state =>
  state.crm.opportunity.availableStatuses
export const getSelectedDocumentUid = state =>
  state.crm.opportunity.selectedDocumentUid

const reducer = buildCrmReducer(functionOpportunityReducer, actions)
export default reducer
