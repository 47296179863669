import React, { createContext, useContext, useState, useEffect } from 'react'
import { node, shape } from 'prop-types'
import { mapInitialColumns } from './dynamic-columns-utils'
import { WidgetConfigurationContextProvider } from '../configuration-tools/component-configuration-context'

const initalContextValue = {
  viewMode: 'TABLE'
}

export type DynamicTableContextType = {
  viewMode: string
  setViewMode: Function
  chartType: string
  setChartType: Function
  customColumns: Array<any>
  setCustomColumns: Function
  pivotDefinition: any
  setPivotDefinition: any
  rootQLType: string
  currentDashboard: any
  setCurrentDashboard: Function
  refreshHandler?: { refresh: Function }
  setRefreshHandler: Function
  excelExportHandler?: { exportExcel: Function }
  setExcelExportHandler: Function
  actionHandlers?: { bulkOperations: Function; refresh: Function; exportExcel: Function }
  setActionHandlers: Function
  startBulkOperationsHandler: Function
  resultCount?: number
  setResultCount: Function
  selectedDashboard: string
  setSelectDashboard?: Function
  currentComponent?: any
}

export const CrmDynamicTableContext: any = createContext(initalContextValue)

export const CrmDynamicTableContextProvider = ({
  children,
  rootQLType,
  initialColumns,
  initialPivotComponent = undefined,
  schema,
  onShowBulkOperations = undefined
}) => {
  const buildComponentDataSection = () => {
    return {
      dataSources: [
        {
          data_type: rootQLType,
          type: 'QL',
          id: 'ds',
          name: rootQLType
        }
      ]
    }
  }

  const buildTableComponent = () => {
    const tableTmpComponent = {
      content: {
        data: buildComponentDataSection(),
        layout: {
          items: [
            {
              type: 'table',
              name: 'Table',
              props: {
                columns: initialColumns.map(col => {
                  let field
                  let name
                  let path
                  let type

                  if (typeof col == 'string') {
                    field = col
                    name = col
                    path = col

                    if (name.indexOf('.') > 0) {
                      var parts = name.split('.')
                      name = parts.map(p => p).join(' ')
                    }
                  } else if (typeof col == 'object') {
                    field = col.field
                    path = col.field
                    name = col.name || col.field
                    type = col.type
                  }

                  return {
                    data: {
                      data_source: 'ds',
                      field
                    },
                    path,
                    name,
                    id: field,
                    type
                  }
                })
              }
            }
          ]
        }
      }
    }

    return tableTmpComponent
  }

  const buildPivotComponent = () => {
    const pivotComponent = {
      content: {
        data: buildComponentDataSection(),
        layout: {
          items: [
            {
              type: 'pivot',
              name: 'Pivot',
              props: {
                data: {
                  type: 'dataSource',
                  dataSourceCode: 'ds'
                },
                rows: [],
                columns: [],
                values: [],
                ...(initialPivotComponent ? initialPivotComponent.props : {})
              }
            }
          ]
        }
      }
    }

    return pivotComponent
  }

  const [viewMode, setViewMode] = useState(initalContextValue.viewMode)
  const [chartType, setChartType] = useState('PIE')
  const initialColumnsMapped = mapInitialColumns(initialColumns, rootQLType)
  const [customColumns, setCustomColumns] = useState(initialColumnsMapped)
  const [currentDashboard, setCurrentDashboard] = useState({})
  const [refreshHandler, setRefreshHandler]: [any, any] = useState()
  const [excelExportHandler, setExceplExportHandler]: [any, any] = useState()
  const [actionHandlers, setActionHandlers]: [any, any] = useState()
  const [resultCount, setResultCount] = useState()
  const [selectedDashboard, setSelectDashboard] = useState()
  const [tableComponent, setTableComponent] = useState(buildTableComponent())
  const [pivotDefinition, setPivotDefinition] = useState<any>(buildPivotComponent())

  let currentComponent

  if (viewMode === 'TABLE') {
    currentComponent = tableComponent
  } else if (viewMode === 'PIVOT') {
    currentComponent = pivotDefinition
  } else if (viewMode === 'DASHBOARD') {
    currentComponent = {}
  }

  const handleSetViewMode = newViewMode => {
    setViewMode(newViewMode)
  }

  const dynamicTableContextValue: DynamicTableContextType = {
    viewMode,
    setViewMode: handleSetViewMode,
    chartType,
    setChartType,
    customColumns,
    setCustomColumns: columns => {
      setCustomColumns(columns)
    },
    pivotDefinition,
    setPivotDefinition,
    rootQLType,
    currentDashboard,
    setCurrentDashboard,
    refreshHandler,
    setRefreshHandler: handler => {
      setRefreshHandler({ refresh: handler })
    },
    setExcelExportHandler: handler => {
      setExceplExportHandler({ exportExcel: handler })
    },
    actionHandlers,
    setActionHandlers,
    startBulkOperationsHandler: onShowBulkOperations,
    excelExportHandler: excelExportHandler,
    resultCount,
    setResultCount: count => {
      setResultCount(count)
    },
    selectedDashboard,
    setSelectDashboard,
    currentComponent
  }

  return (
    <CrmDynamicTableContext.Provider value={dynamicTableContextValue}>
      <WidgetConfigurationContextProvider schema={schema} component={currentComponent}>
        {children}
      </WidgetConfigurationContextProvider>
    </CrmDynamicTableContext.Provider>
  )
}

CrmDynamicTableContextProvider.propTypes = {
  children: node.isRequired,
  filter: shape({})
}

export const useCrmDynamicTableContext = (): DynamicTableContextType => useContext(CrmDynamicTableContext)
