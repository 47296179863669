import React, { useState } from 'react'
import useCrmCurrentUser from '../../../core/auth/hooks/use-crm-current-user'

import moment from 'moment'
import { CrmFullpageContainer } from '../../../components/index'
import { MyTaskWidget } from 'crm-modules/tasks/widgets/my-task-widget'
import WidgetResults from './widgets18/widget-results.jsx'
import WidgetCalls from './widgets18/widget-calls.jsx'
import WidgetMarketing from './widgets18/widget-marketing.jsx'
import WidgetDigitalLeadsStatus from './widgets18/widget-digital-leads-status.jsx'
import { ForecastProgressChart } from '../../forecasts/forecast-progresschart'
import { UserInfoCard } from '../ct-dashboards/rm-dashboard/rm-summary-dashboard'

const UserWorkDashboard = props => {
  const crmCurrentUser = useCrmCurrentUser()
  const [showTestMode, setShowTestMode] = useState(false)
  const handleEnableTestMode = () => setShowTestMode(true)
  const renderNewDashboards = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <WidgetResults style={{ height: 300 }} analyticsContext={props.analyticsContext} />
          </div>
          <div className="col-md-3">
            <WidgetCalls style={{ height: 300 }} analyticsContext={props.analyticsContext} />
          </div>
          <div className="col-md-3">
            <WidgetMarketing style={{ height: 300 }} analyticsContext={props.analyticsContext} />
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="col-md-3">
            <WidgetDigitalLeadsStatus style={{ height: 300 }} analyticsContext={props.analyticsContext} />
          </div>
        </div>
      </div>
    )
  }

  if (!crmCurrentUser) {
    return <div>Not logged in</div>
  }

  return (
    <CrmFullpageContainer>
      <div className="util-paddingXl">
        <div className="util-marginTop util-marginBottom" style={{ fontSize: 22 }} onClick={handleEnableTestMode}>
          User Dashboard
        </div>
        <div className="row">
          <div className="col-md-2">
            <UserInfoCard user={crmCurrentUser} />
          </div>
          <div className="col-md-3">
            <MyTaskWidget title="My Overdue Tasks" type="user" style={{ height: '40vh' }} />
          </div>
          <div className="col-md-4">
            <ForecastProgressChart userUid={crmCurrentUser.user_uid} />
          </div>
        </div>
        {showTestMode && renderNewDashboards()}
      </div>
    </CrmFullpageContainer>
  )
}

export default UserWorkDashboard
