/* eslint-disable camelcase */
import moment from 'moment'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'

const getUserRow = (users, row) => {
  if (!users[row.performer_user_uid]) {
    // eslint-disable-next-line
    users[row.performer_user_uid] = {
      user_uid: row.performer_user_uid,
      user_name: row.performer__full_name,
      mainKpis: []
    }
  }
  return users[row.performer_user_uid]
}

const parseMeetings = data => {
  const users = {}
  console.log('parseMeetings', data)
  data.forEach(row => {
    const user = getUserRow(users, row)
    if (!user.meetingsInPeriod) {
      user.meetingsInPeriod = {
        completedCount: 0,
        uncompletedCount: 0,
        canceledCount: 0,
        byDate: []
      }
    }

    if (row.status__kind === 'failure' || row.status__kind === 'canceled') {
      users[row.performer_user_uid].meetingsInPeriod.canceledCount += Number(
        row.activity_uid
      )
    } else if (row.status__kind === 'completed') {
      users[row.performer_user_uid].meetingsInPeriod.completedCount += Number(
        row.activity_uid
      )
    } else {
      users[row.performer_user_uid].meetingsInPeriod.uncompletedCount += Number(
        row.activity_uid
      )
    }
  })

  Object.values(users).forEach(user => {
    user.mainKpis.push({
      name: 'Meetings completed in period',
      value: user.meetingsInPeriod.completedCount
    })
  })
  console.log('Result', users)
  return users
}

const parseMeetings30Days = data => {
  const users = {}
  console.log('parseMeetings30Days')
  data.forEach(row => {
    const user = getUserRow(users, row)
    if (!user.meetingsLast30Days) {
      user.meetingsLast30Days = {
        completedCount: 0,
        uncompletedCount: 0,
        canceledCount: 0,
        byDate: []
      }
    }

    if (row.status__kind === 'failure') {
      users[row.performer_user_uid].meetingsLast30Days.canceledCount += Number(
        row.activity_uid
      )
    }
    if (row.status__kind === 'completed') {
      users[row.performer_user_uid].meetingsLast30Days.completedCount += Number(
        row.activity_uid
      )
    }
  })

  Object.values(users).forEach(user => {
    user.mainKpis.push({
      name: 'Meetings Last 30 Days',
      value: user.meetingsLast30Days.completedCount
    })
  })
  return users
}

export const buildMeetingsFilter = params => {
  const filter = mapContextToFilter(params, true)
  console.log('filter', filter)
  if (params.analyticsContext) {
    if (params.analyticsContext.filters.activity_date) {
      filter.start_date = params.analyticsContext.filters.activity_date
    }
  }
  if (filter.user_uid) {
    filter.performer_user_uid = filter.user_uid
    delete filter.user_uid
  }

  filter.type__medium_code = 'meeting'
  return filter
}

export const loadDataMeetingsInPeriod = async params => {
  console.log('loadDataMeetingsInPeriod', params)

  const filter = buildMeetingsFilter(params)

  const variables = {
    filter,
    fields: ['performer__full_name', 'performer_user_uid', 'status__kind'],
    aggregate: [{ id: 'activity_uid', type: 'count' }],
    rootType: 'ActivitiesListQM'
  }

  const res = await params.client.query({
    query: qlqGenericDataQuery,
    variables,
    fetchPolicy: 'no-cache'
  })
  console.log('Got Meetings result', res)
  if (res && res.data && res.data.genericDataQuery) {
    return {
      data: parseMeetings(res.data.genericDataQuery.data),
      filter
    }
  }
  return undefined
}

export const loadMeetingsLast30Days = async params => {
  console.log('loadMeetingsLast30Days', params)
  const filter = mapContextToFilter(params, true)

  let date = moment()
  date = date.subtract(1, 'months')
  filter.start_date = {
    $gte: date.format('YYYY-MM-DD'),
    $lte: moment().format('YYYY-MM-DD')
  }
  filter.type__medium_code = 'meeting'

  const variables = {
    filter,
    fields: ['performer__full_name', 'performer_user_uid', 'status__kind'],
    aggregate: [{ id: 'activity_uid', type: 'count' }],
    rootType: 'ActivitiesListQM'
  }

  const res = await params.client.query({
    query: qlqGenericDataQuery,
    variables,
    fetchPolicy: 'no-cache'
  })
  console.log('Got Meetings result', res)
  if (res && res.data && res.data.genericDataQuery) {
    const result = parseMeetings30Days(res.data.genericDataQuery.data)
    return result
  }
  return undefined
}
