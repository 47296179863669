/* eslint-disable camelcase */
import moment from 'moment'

export const DATE_FORMAT = 'YYYYMMDD'

export function getStartDateOfWeek(date = undefined) {
  return moment(date).startOf('isoWeek')
}

export function getStartDateOfDay() {
  return moment()
}

export function dateEqual(date, compareDate) {
  if (date.format(DATE_FORMAT) === compareDate.format(DATE_FORMAT)) return true
  return false
}

export function getEndDate(startDate) {
  return startDate.add(6, 'd')
}

export function toMSformat(date) {
  return parseInt(date.format('x'), 10)
}

export function parseToCalendarFormat(res) {
  const calendarsFormat = []
  if (res) {
    res.forEach(item =>
      calendarsFormat.push({
        title: item.account_name ? item.account_name : 'Event',
        account_uid: item.account_uid,
        activity_uid: item.activity_uid,
        medium_code: item.medium_code,
        city: item.city,
        activity_type_uid: item.activity_type_uid,
        activity_type_code: item.activity_type_code,
        activity_type_name: item.activity_type_name,
        activity_status_kind: item.activity_status_kind,
        start: moment(item.start_time),
        primary_performer_full_name: item.primary_performer_full_name,
        primary_performer_photo_url: item.primary_performer_photo_url,
        participants_full_name_all: item.participants_full_name_all,
        participants_length: item.participants_length,
        date: moment(item.start_time).format(DATE_FORMAT),
        time: moment(item.start_time).format('H'),
        location_type: item.location_type
      })
    )
  }

  return calendarsFormat
}
