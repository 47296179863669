export const columnsUser = {
  type: 'User',
  fields: [
    {
      queryFragment: {
        selections: [{ name: 'full_name' }, { name: 'user_uid' }]
      },
      description: 'Name',
      name: 'full_name',
      type: 'string',
      column_label: 'Name',
      column_label_skip_except_root: false
    },
    {
      queryFragment: {
        selections: [{ name: 'email' }]
      },
      description: 'Email',
      name: 'email',
      type: 'string'
    },

    {
      name: 'login',
      description: 'Login',
      type: 'string'
    },
    {
      name: 'sip_username',
      description: 'SIP Number',
      type: 'string'
    },
    {
      name: 'is_active',
      description: 'Is User Active',
      type: 'boolean'
    },
    {
      name: 'mobile_phone',
      description: 'Mobile Phone',
      type: 'string'
    },
    {
      name: 'contract_start_date',
      description: 'Contract Start Date',
      type: 'date'
    },
    {
      name: 'contract_end_date',
      description: 'Contract End Date',
      type: 'date'
    },
    {
      name: `department`,
      description: 'Department',
      type: 'DepartmentType'
    }
  ]
}
export const columnsDepartmentType = {
  type: 'DepartmentType',
  fields: [
    {
      name: 'name',
      description: 'Name',
      type: 'string'
    }
  ]
}
