/* eslint-disable camelcase */
import React from 'react'
import AccountsList from './accounts-list.jsx'

const visibleColumns = [
  'name',
  'address',
  'owner',
  'market_potential',
  'assigned_time',
  'last_activity_date',
  'last_activity_comment',
  'qualification',
  'campaign_name'
]

const ProspectsAccountsView = () => (
  <AccountsList
    fixQFilter={{
      kind: 'customer'
    }}
    accountsListCode="customers"
    disableMoreFilters={['owner']}
    visibleColumns={visibleColumns}
    loadData={['market_potential']}
    noInnerNav
  />
)

export default ProspectsAccountsView
