/* eslint-disable camelcase, no-unused-vars */
import React from 'react'
import { func, shape } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import { qlqProcessDetails, qlqProcessType } from 'crm-data/processes'
import {
  qlqAccountStatusAndOwners,
  qlqFetchAccountProcesses,
  qlqfetchAccount
} from 'crm-data/accounts'
import { qlmExecuteCommand } from 'crm-data/commands'
import {
  crmGenericCommandHoc,
  cqCommandQl,
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

// eslint-disable-next-line
const processWidgetHoc = WrappedComponent => {
  return class ProcessWidgetHOC extends React.PureComponent {
    static propTypes = {
      cqCommand: func,
      opportunity: shape({}),
      onCancel: func,
      onRunCommand: func.isRequired,
      process: shape({}),
      qlqProcessDetails: shape(),
      accountPermissionsContext: shape({}).isRequired
    }
    static defaultProps = {
      opportunity: undefined,
      qlqProcessDetails: undefined,
      process: undefined,
      onCancel: () => {},
      cqCommand: undefined
    }
    constructor(props) {
      super(props)
      this.state = {
        isSaving: false,
        isActive: false
      }
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(
          {
            ...this.state,
            ...newState
          },
          () => {
            resolve()
          }
        )
      })

    handleSaveCompletedDate = pDate => {
      // console.log('handleSaveCompletedDate', this.props, pDate)
      const date = new Date(pDate)
      if (!date) {
        throw new Error('No date selected')
      }
      const command = {
        type: 'process.updateCompletedDate',
        aggregate_uid: this.props.process.process_uid,
        aggregate_type: 'process',
        payload: {
          process_uid: this.props.process.process_uid,
          completed_date: date
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqProcessDetails,
            variables: {
              process_uid: this.props.process.process_uid
            }
          }
        ]

        const result = this.props.onRunCommand(command, {
          refetchQueries
        })
      } catch (err) {
        // console.error('Error running command')
        throw err
      }
    }

    handleSaveNewResult = pResult => {
      // console.log('handleSaveNewResult', this.props, pResult)

      const command = {
        type: 'process.adminUpdateResult',
        aggregate_uid: this.props.process.process_uid,
        aggregate_type: 'process',
        payload: {
          process_uid: this.props.process.process_uid,
          process_step_type_uid: pResult
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqProcessDetails,
            variables: {
              process_uid: this.props.process.process_uid
            }
          }
        ]

        const result = this.props.onRunCommand(command, {
          refetchQueries
        })
      } catch (err) {
        console.error('Error running command')
        throw err
      }
    }

    handleDoProcessAction = async actionCode => {
      console.log('handleDoProcessAction', actionCode)
      if (!actionCode) {
        return
      }
      const command = {
        type: 'process.doProcessAction',
        aggregate_uid: this.props.process.process_uid,
        aggregate_type: 'process',
        payload: {
          actionCode: actionCode
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqProcessDetails,
            variables: {
              process_uid: this.props.process.process_uid
            }
          },
          {
            query: qlqfetchAccount,
            variables: {
              account_uid: this.props.process.account_uid
            }
          }
        ]

        const result = await this.props.onRunCommand(command, {
          refetchQueries
        })
      } catch (err) {
        console.error('Error running command')
        throw err
      }
    }
    handleMoveProcessStep = (processStepUid, quotation) => {
      const command = {
        type: 'process.moveProcessStep',
        aggregate_uid: this.props.process.process_uid,
        aggregate_type: 'process',
        payload: {
          process_uid: this.props.process.process_uid,
          process_step_type_uid: processStepUid
        }
      }
      try {
        const refetchQueries = [
          {
            query: qlqProcessDetails,
            variables: {
              process_uid: this.props.process.process_uid
            }
          }
        ]

        const result = this.props.onRunCommand(command, {
          refetchQueries
        })
      } catch (err) {
        console.error('Error running command')
        throw err
      }
    }

    handleRefetch = () => {
      // console.log('handleRefetch', this.props)
      this.props.qlqProcessDetails.refetch()
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          onSaveWin={this.handleSaveWin}
          onSaveLost={this.handleSaveLost}
          onClickDo={this.handleClickDo}
          onCancel={this.props.onCancel}
          isSaving={this.state.isSaving}
          isActive={this.state.isActive}
          onSaveCompletedDate={this.handleSaveCompletedDate}
          onSaveNewResult={this.handleSaveNewResult}
          onMoveProcessStep={this.handleMoveProcessStep}
          onDoProcessAction={this.handleDoProcessAction}
          onRefetch={this.handleRefetch}
          isManager={this.props.accountPermissionsContext.hasPermissionByType(
            PERMISSIONS_TYPES.ACCOUNT_DATA_MANAGE
          )}
        />
      )
    }
  }
}

const processWidgetQl = compose(
  graphql(qlmExecuteCommand, {
    name: 'cqCommand',
    options: ownProps => ({
      refetchQueries: []
    })
  }),
  graphql(qlqProcessDetails, {
    options: ownProps => ({
      variables: {
        process_uid: ownProps.processUid
      }
    }),
    skip: ownProps => !ownProps.processUid,
    props: ({ qlqProcessDetails, ownProps }) => ({
      ...ownProps,
      qlqProcessDetails,
      process: qlqProcessDetails ? qlqProcessDetails.process : undefined,
      isLoading: qlqProcessDetails ? qlqProcessDetails.loading : false
    }),
    name: 'qlqProcessDetails'
  }),
  graphql(qlqProcessType, {
    options: ownProps => ({
      variables: {
        process_type_uid: ownProps.process
          ? ownProps.process.process_type_uid
          : ownProps.processTypeUid
      }
    }),
    skip: ownProps => !ownProps.processTypeUid && !ownProps.process,
    props: ({ qlqProcessType, ownProps }) => ({
      ...ownProps,
      qlqProcessType,
      processType: qlqProcessType ? qlqProcessType.process_type : undefined
    }),
    name: 'qlqProcessType'
  }),
  cqCommandQl,
  crmGenericCommandHoc,
  withAccountPermissionsContextHOC,
  processWidgetHoc
)

export default processWidgetQl
