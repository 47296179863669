import {
  getVehiclesWithDrivers,
  getFocusedVehicle,
  getFocusedVehicleTimelinesData,
  getFocusedVehicleCachedEvents,
  UPDATE_CACHED_VEHICLE_EVENTS
} from 'crm-duxs/vehicles'
import { getActiveDriversWithVehicles, getFocusedDriver, getFocusedDriverTimelinesData } from 'crm-duxs/drivers'
import { SAVE_LANDMARK } from 'crm-duxs/landmarks'
import { SAVE_GEOFENCE } from 'crm-duxs/geofences'

// Actions
const CHANGE_TYPE = 'CHANGE_TYPE'
const TOGGLE_FOLLOW_FOCUSED_ITEM = 'TOGGLE_FOLLOW_FOCUSED_ITEM'
export const FOCUS_VEHICLE = 'FOCUS_VEHICLE'
const FOCUS_DRIVER = 'FOCUS_DRIVER'
export const UPDATE_FOCUSED_VEHICLE = 'UPDATE_FOCUSED_VEHICLE'
const UPDATE_FOCUSED_DRIVER = 'UPDATE_FOCUSED_DRIVER'
const SELECT_DRIVER_TRIP_SUMMARY = 'SELECT_DRIVER_TRIP_SUMMARY'
export const SELECT_VEHICLE_TRIP_SUMMARY = 'SELECT_VEHICLE_TRIP_SUMMARY'
export const CLOSE_DETAILS = 'CLOSE_DETAILS'
export const CHANGE_MAP_CENTER_ZOOM = 'CHANGE_MAP_CENTER_ZOOM'
export const JUMP_TO_TIME = 'JUMP_TO_TIME'
const CHANGE_TIMELINE_VIEW = 'CHANGE_TIMELINE_VIEW'
const CHANGE_EXPAND_TIMELINE = 'CHANGE_EXPAND_TIMELINE'
export const SET_NEARBY_VISIBLE = 'SET_NEARBY_VISIBLE'
export const SET_NEARBY_HIDDEN = 'SET_NEARBY_HIDDEN'
const SET_FOCUSED_POINT = 'SET_FOCUSED_POINT'
const CLEAR_FOCUSED_POINT = 'CLEAR_FOCUSED_POINT'
const SET_LAYER_VISIBILITY = 'SET_LAYER_VISIBILITY'
const SET_HOVERED_VEHICLE = 'SET_HOVERED_VEHICLE'
const CHANGE_ACTIVE_TIMELINE_TABLE = 'CHANGE_ACTIVE_TIMELINE_TABLE'

// Reducer
const initialState = {
  type: 'vehicles',
  focusedItemId: '',
  followFocusedItem: false,
  hoveredVehicleId: '',
  selectedTripSummaryIndex: 0,
  selectedTripStartDate: new Date(),
  center: { lat: 0, lng: 0 },
  zoom: 1,
  savedMapProps: { lat: 0, lng: 0, zoom: 12 },
  activeView: 'timeline', // 'timeline' || 'graph' || 'table'
  timelineProgress: 0,
  activeEventIndex: 0,
  timelineIsExpanded: false,
  isNearbyVisible: false,
  layerVisibility: {
    geofences: true,
    geofenceLabels: false,
    harshEvents: true,
    /* T O D O: implement when we have these events
    harshAcceleration: true,
    harshBraking: true,
    harshTurning: true,
    */
    harshSpeeding: true,
    landmarks: true,
    landmarkLabels: false,
    livePositions: true,
    livePositionLabels: true,
    livePositionClusters: true,
    traffic: false
  },
  timelineTablesActiveTab: 'all-trips'
}

// eslint-disable-next-line complexity
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_TYPE:
      return { ...state, type: action.payload.type }
    case TOGGLE_FOLLOW_FOCUSED_ITEM:
      return { ...state, followFocusedItem: !state.followFocusedItem }
    case FOCUS_VEHICLE: {
      const vehicle = action.payload.vehicle
      const hasValidPos = vehicle.latitude !== 360 && vehicle.longitude !== 360
      const center = hasValidPos ? { lat: vehicle.latitude, lng: vehicle.longitude } : state.center
      const zoom = hasValidPos ? 12 : state.zoom
      const savedMapProps = { ...state.center, zoom: state.zoom }
      return {
        ...state,
        type: 'vehicles',
        focusedItemId: vehicle.id,
        selectedTripSummaryIndex: 0,
        timelineProgress: 1,
        center,
        zoom,
        savedMapProps
      }
    }
    case FOCUS_DRIVER:
      return {
        ...state,
        type: 'drivers',
        focusedItemId: action.payload.driver.id,
        selectedTripSummaryIndex: 0,
        savedMapProps: { ...state.center, zoom: state.zoom }
      }
    case SELECT_DRIVER_TRIP_SUMMARY:
      return {
        ...state,
        selectedTripSummaryIndex: action.payload.index,
        selectedTripStartDate: action.payload.startDate
      }
    case SELECT_VEHICLE_TRIP_SUMMARY:
      return {
        ...state,
        selectedTripSummaryIndex: action.payload.index,
        selectedTripStartDate: action.payload.startDate
      }
    case CLOSE_DETAILS:
      return {
        ...state,
        focusedItemId: '',
        selectedTripSummaryIndex: 0,
        center: { lat: state.savedMapProps.lat, lng: state.savedMapProps.lng },
        zoom: state.savedMapProps.zoom
      }
    case CHANGE_MAP_CENTER_ZOOM:
      return {
        ...state,
        center: action.payload.center,
        zoom: action.payload.zoom || state.zoom
      }
    case JUMP_TO_TIME: {
      return {
        ...state,
        center: {
          lat: action.payload.nextActiveEvent.latitude,
          lng: action.payload.nextActiveEvent.longitude
        },
        zoom: action.payload.zoom || state.zoom,
        timelineProgress: action.payload.progress,
        activeEventIndex: action.payload.nextActiveEventIndex
      }
    }
    case CHANGE_TIMELINE_VIEW:
      return { ...state, activeView: action.payload.view }
    case CHANGE_EXPAND_TIMELINE:
      return { ...state, timelineIsExpanded: action.payload.expanded }
    case SET_NEARBY_VISIBLE: {
      const savedMapProps = {
        center: state.center,
        zoom: state.zoom
      }
      return {
        ...state,
        focusedItemId: '',
        selectedTripSummaryIndex: 0,
        isNearbyVisible: true,
        focusedPoint: state.focusedPoint,
        savedMapProps
      }
    }
    case SET_NEARBY_HIDDEN:
      return {
        ...state,
        isNearbyVisible: false,
        focusedPoint: null,
        center: state.savedMapProps.center,
        zoom: state.savedMapProps.zoom
      }
    case SET_FOCUSED_POINT: {
      return {
        ...state,
        focusedPoint: action.payload.point,
        focusedPointZoom: state.zoom
      }
    }
    case CLEAR_FOCUSED_POINT: // Clear highlighted point for next three cases
    case SAVE_LANDMARK:
    case SAVE_GEOFENCE:
      return {
        ...state,
        focusedPoint: null,
        focusedPointZoom: null,
        isNearbyVisible: false
      }
    case SET_LAYER_VISIBILITY:
      return {
        ...state,
        layerVisibility: {
          ...state.layerVisibility,
          ...action.payload.layers
        }
      }
    case SET_HOVERED_VEHICLE:
      return {
        ...state,
        hoveredVehicleId: action.payload.id
      }
    case CHANGE_ACTIVE_TIMELINE_TABLE:
      return {
        ...state,
        timelineTablesActiveTab: action.payload.tab
      }
    case UPDATE_CACHED_VEHICLE_EVENTS:
      return {
        ...state,
        activeEventIndex: action.payload.cachedEvents.length - 1
      }
    default:
      return state
  }
}

// Action Creators
export function changeType(type) {
  return {
    type: CHANGE_TYPE,
    payload: { type }
  }
}

export function toggleFollowFocusedItem() {
  return {
    type: TOGGLE_FOLLOW_FOCUSED_ITEM
  }
}

export function closeDetails() {
  return {
    type: CLOSE_DETAILS
  }
}

export function selectTripSummary(index, type, item, startDate) {
  return {
    type: type === 'vehicles' ? SELECT_VEHICLE_TRIP_SUMMARY : SELECT_DRIVER_TRIP_SUMMARY,
    payload: { index, [type.slice(0, type.length - 1)]: item, startDate }
  }
}

export function focusItem(type, item) {
  return {
    type: type === 'vehicle' ? FOCUS_VEHICLE : FOCUS_DRIVER,
    payload: { [type]: item }
  }
}

export function updateFocusedItem(type, item, date) {
  return {
    type: type === 'vehicle' ? UPDATE_FOCUSED_VEHICLE : UPDATE_FOCUSED_DRIVER,
    payload: { [type]: item, date }
  }
}

export function changeMapCenterZoom(lat, lng, zoom) {
  return {
    type: CHANGE_MAP_CENTER_ZOOM,
    payload: { center: { lat, lng }, zoom }
  }
}

export function changeTimelineView(view) {
  return {
    type: CHANGE_TIMELINE_VIEW,
    payload: { view }
  }
}

export function jumpToTime(progress, nextActiveEventIndex, nextActiveEvent) {
  return {
    type: JUMP_TO_TIME,
    payload: { progress, nextActiveEventIndex, nextActiveEvent }
  }
}

export function changeTimelineIsExpanded(expanded) {
  return {
    type: CHANGE_EXPAND_TIMELINE,
    payload: { expanded }
  }
}

export function setNearbyVisible() {
  return {
    type: SET_NEARBY_VISIBLE
  }
}

export function setNearbyHidden() {
  return {
    type: SET_NEARBY_HIDDEN
  }
}

export function setFocusedPoint(point) {
  return {
    type: SET_FOCUSED_POINT,
    payload: { point }
  }
}

export function clearFocusedPoint() {
  return {
    type: CLEAR_FOCUSED_POINT
  }
}

export function setLayerVisibility(layers) {
  return {
    type: SET_LAYER_VISIBILITY,
    payload: { layers }
  }
}

export function setHoveredVehicle(id) {
  return {
    type: SET_HOVERED_VEHICLE,
    payload: { id }
  }
}

export function changeTimelineTablesActiveTab(tab) {
  return {
    type: CHANGE_ACTIVE_TIMELINE_TABLE,
    payload: { tab }
  }
}

// Selectors
// Map
export const getCenter = state => state.map.center
export const getZoom = state => state.map.zoom
export const getIsNearbyVisible = state => state.map.isNearbyVisible
export const getFocusedPoint = state => state.map.focusedPoint
export const getLayerVisibility = state => state.map.layerVisibility
export const getHoveredVehicle = state => state.map.hoveredVehicleId
export const getFollowFocusedItem = state => state.map.followFocusedItem
export const getFocusedPointZoom = state => state.map.focusedPointZoom

// Data
export const getType = state => {
  if (state.router.location) {
    const pathname = state.router.location.pathname
    return pathname.slice(pathname.lastIndexOf('/') + 1)
  }

  return ''
}
export const getSelectedTripSummaryIndex = state => state.map.selectedTripSummaryIndex

export const getSelectedTripStartDate = state => new Date(state.map.selectedTripStartDate)

export const getItems = state =>
  getType(state) === 'vehicles' ? getVehiclesWithDrivers(state) : getActiveDriversWithVehicles(state)
export const getFocusedItem = state =>
  getType(state) === 'vehicles' ? getFocusedVehicle(state) : getFocusedDriver(state)

// Timeline
export const getTimelinesData = state =>
  getType(state) === 'vehicles' ? getFocusedVehicleTimelinesData(state) : getFocusedDriverTimelinesData(state)
export const getTimelineProgress = state => state.map.timelineProgress
const getActiveEventIndex = state => state.map.activeEventIndex
export const getActiveEvent = state => {
  const activeEventIndex = getActiveEventIndex(state)
  const cachedEvents = getFocusedVehicleCachedEvents(state)
  if (cachedEvents && activeEventIndex < cachedEvents.length) {
    return cachedEvents[activeEventIndex]
  }
  return null
}
export const getActiveView = state => state.map.activeView
export const getTimelineIsExpanded = state => state.map.timelineIsExpanded
export const getTimelineTablesActiveTab = state => state.map.timelineTablesActiveTab
