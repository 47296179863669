/* eslint-disable camelcase */
import { createSelector } from 'reselect'
// Actions
export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENT'
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS'
export const SELECT_DEPARTMENT = 'SELECT_DEPARTMENT'
export const CLEAR_SELECT_DEPARTMENT = 'CLEAR_SELECT_DEPARTMENT'
export const SHOW_CREATE_FORM_DEPARTMENT = 'SHOW_CREATE_FORM_DEPARTMENT'
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT'
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS'
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT'
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS'

// Reducer
const initialState = {
  departments: [],
  departmentSelected: {
    uid: undefined,
    form_type: undefined
  }
}

const findIndexOfDepartmentsByUID = (departments, uid) =>
  departments.findIndex(departments => departments.department_uid === uid)
const mergeStateDepartmentData = (departments, uid, user) => {
  const indexOfDepartments = findIndexOfDepartmentsByUID(departments, uid)
  return Object.assign([], departments, { [indexOfDepartments]: user })
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEPARTMENTS:
    case CREATE_DEPARTMENT:
    case UPDATE_DEPARTMENT:
      return { ...state }

    case FETCH_DEPARTMENT_SUCCESS: {
      return { ...state, departments: action.payload.departments }
    }
    case SELECT_DEPARTMENT:
      return {
        ...state,
        departmentSelected: { uid: action.payload.uid, formType: 'update' }
      }

    case CLEAR_SELECT_DEPARTMENT:
      return {
        ...state,
        departmentSelected: { uid: undefined, formType: undefined }
      }

    case SHOW_CREATE_FORM_DEPARTMENT:
      return {
        ...state,
        departmentSelected: { uid: undefined, formType: 'create' }
      }

    case CREATE_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        departments: [...state.departments, action.payload.department]
      }
    }
    case UPDATE_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        departments: mergeStateDepartmentData(
          state.departments,
          action.payload.department.department_uid,
          action.payload.department
        )
      }
    }

    default:
      return state
  }
}

export function fetchDepartment() {
  return {
    type: FETCH_DEPARTMENTS,
    payload: {}
  }
}

export function setSelectedDepartment(departmentUid) {
  return {
    type: SELECT_DEPARTMENT,
    payload: {
      uid: departmentUid
    }
  }
}

export function clearSelectedDepartment() {
  return {
    type: CLEAR_SELECT_DEPARTMENT
  }
}

export function showCreateForm() {
  return {
    type: SHOW_CREATE_FORM_DEPARTMENT
  }
}

export function saveDepartment(department, values) {
  let type = CREATE_DEPARTMENT
  if (department.formType === 'update') {
    type = UPDATE_DEPARTMENT
  }

  return {
    type,
    payload: {
      department,
      values
    }
  }
}

/*eslint-disable */
// Selector
export const departmentSelector = state => state.crm.department.departments
export const selectedDepartment = state =>
  state.crm.department.departmentSelected
const getSelected = (departments, selectedDepartmentData) => {
  // console.log(selectedDepartmentData)
  return {
    data:
      departments[
        findIndexOfDepartmentsByUID(departments, selectedDepartmentData.uid)
      ],
    formType: selectedDepartmentData.formType
  }
}
/*eslint-enable */
export const getSelectedDepartment = createSelector(
  departmentSelector,
  selectedDepartment,
  getSelected
)
