/* eslint-disable camelcase */
import { buildCrmReducer } from 'crm-utils/reducer-utils'

// Initial
const initialState = {
  users: undefined,
  selectedMessage: undefined,
  messages: {
    data: [],
    count: 0,
    countUnreadMessage: 0
  }
}

// Actions
export const actions = {
  sendNotificationToMobile: {},
  fetchUsers: {},
  fetchUsersSuccess: {
    updater: action => ({
      users: action.payload.users
    })
  },
  fetchMessages: {},
  fetchMessagesSuccess: {
    updater: action => ({
      messages: action.payload.messages
    })
  },
  selectMessage: {},
  setSelectedMessage: {
    updater: action => ({
      selectedMessage: action.payload.selectedMessage
    })
  }
}

function messagesReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

// Selectors
export const getUsers = state => state.crm.messages.users
export const getMessages = state => state.crm.messages.messages
export const getSelectedMessage = state => state.crm.messages.selectedMessage
const reducer = buildCrmReducer(messagesReducer, actions)
export default reducer
