/* eslint-disable  camelcase */
import React from 'react'
import { func, arrayOf, shape } from 'prop-types'
import packageItemQlHoc from '../../../modules/admin/packages/package-item-runtime-ql-hoc.jsx'
import { CrmDropdown } from 'crm-components'

class CrmPackageItemSelect extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedValue: undefined
    }
  }

  getOptions = () =>
    this.props.packageItems.map(item => ({
      value: item.package_item_uid,
      name: item.name
    }))

  handleOnChange = value => {
    this.setState({ selectedValue: value })
    this.props.onChange(value)
  }

  render() {
    return (
      <div>
        <CrmDropdown
          options={this.getOptions()}
          input={{
            value: this.state.selectedValue,
            onChange: this.handleOnChange
          }}
        />
      </div>
    )
  }
}

CrmPackageItemSelect.defaultProps = {
  onChange: () => {},
  packageItems: []
}
CrmPackageItemSelect.propTypes = {
  onChange: func.isRequired,
  packageItems: arrayOf(shape({}))
}

export default packageItemQlHoc(CrmPackageItemSelect)
