export enum CrmToastrType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning'
}

export enum CrmToastrPosition {
  bottomLeft = 'bottom-left',
  bottomMiddle = 'bottom-middle',
  bottomRight = 'bottom-right',
  middle = 'middle',
  middleLeft = 'middle-left',
  middleRight = 'middle-right',
  topLeft = 'top-left',
  topMiddle = 'top-middle',
  topRight = 'top-right'
}

export interface optionParams {
  btnPrimaryTitle?: string
  btnSecondaryTitle?: string
  onBtnPrimary?: any
  onBtnSecondary?: any
  textDetailContent?: any
  textDetailTitle?: string
  position?: CrmToastrPosition
  message?: string
  timeToShow?: number
}

export interface withCrmToastrHocProps {
  showToastr: (type: CrmToastrType, title: string, options: optionParams) => void
  hideToastr: () => void
}

export interface withToastrWithRunApiOptions {
  successMessage?: string
  errorMessage?: string
  timeToShow?: number
}

export interface withToastrWithRunApiHocProps {
  runApiWithToastr: (onRunCommand: any, options: withToastrWithRunApiOptions) => any
}

export interface withHandleErrorExceptionHocProps {
  toastrHandleErrorException: (err: any) => void
}
