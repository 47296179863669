import React, { useState, useEffect } from 'react'
import { CrmJsonPreview, CrmButton, CrmTextInput } from '@cartrack-crm/ui'
import CrmTextAreaIntl, {
  CrmTextArea
} from '@cartrack-crm/ui/src/text-area/crm-text-area'
import {
  useCrmToastr,
  CrmToastrType,
  CrmToastrPosition
} from '@cartrack-crm/ui/src/toastrs'

const sectionStyle = {
  marginTop: 10,
  backgroundColor: 'white'
}

const QlQueryFreeEditor = ({ dataSource, onSave }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [qlString, setQlString] = useState(dataSource.qlQuery)

  const handleSave = () => {
    console.log('Save QL Query free edit', qlString)
    // TODO: Validate
    const noNewLines = qlString.replace(/\n/g, '\n')
    onSave(noNewLines)
    setIsEditing(false)
  }
  return (
    <div style={sectionStyle}>
      <label style={{ fontWeight: 800 }}>QL Query</label>
      {!isEditing && (
        <div>
          {dataSource.qlQuery ? dataSource.qlQuery : 'NO QL QUERY FOUND'}
          <CrmButton label="Edit" onClick={() => setIsEditing(true)} small />
        </div>
      )}

      {isEditing && (
        <div>
          <CrmTextAreaIntl
            input={{
              value: qlString,
              onChange: (event) => setQlString(event.currentTarget.value)
            }}
            rows={10}
          />
          <CrmButton label="Save" onClick={handleSave} small type="primary" />
        </div>
      )}
    </div>
  )
}

const SectionFilter = ({ dataSource, onSave }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [filterString, setFilterString] = useState(
    JSON.stringify(dataSource.filter)
  )
  const [showToastr, hideToastr] = useCrmToastr()

  const handleSave = () => {
    console.log('Save QL Query free edit', filterString)
    // TODO: Validate
    try {
      const filter = JSON.parse(filterString)
      onSave(filter)
      setIsEditing(false)
    } catch (err) {
      showToastr(CrmToastrType.error, 'Error', {
        btnPrimaryTitle: 'ignore',
        message: err.message,
        onBtnPrimary: () => hideToastr(),
        position: CrmToastrPosition.middle,
        timeToShow: null
      })
    }
  }
  return (
    <div style={sectionStyle}>
      <label style={{ fontWeight: 800 }}>Filter</label>
      {!isEditing && (
        <div>
          {dataSource.filter ? (
            <CrmJsonPreview json={dataSource.filter} />
          ) : (
            'NO filter'
          )}
          <CrmButton label="Edit" onClick={() => setIsEditing(true)} small />
        </div>
      )}

      {isEditing && (
        <div>
          <CrmTextAreaIntl
            input={{
              value: filterString,
              onChange: (event) => setFilterString(event.currentTarget.value)
            }}
            rows={10}
          />
          <CrmButton label="Save" onClick={handleSave} small type="primary" />
        </div>
      )}
    </div>
  )
}

const SectionQlVariables = ({ dataSource, onSave }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [qlVariablesString, setQlVariablesString] = useState(
    JSON.stringify(dataSource.qlVariables)
  )
  const [showToastr, hideToastr] = useCrmToastr()
  const handleSave = () => {
    console.log('Save QL Variables free edit', qlVariablesString)
    // TODO: Validate
    try {
      const qlVariables = JSON.parse(qlVariablesString)
      onSave(qlVariables)
      setIsEditing(false)
    } catch (err) {
      showToastr(CrmToastrType.error, 'Error', {
        btnPrimaryTitle: 'ignore',
        message: err.message,
        onBtnPrimary: () => hideToastr(),
        position: CrmToastrPosition.middle,
        timeToShow: null
      })
    }
  }
  return (
    <div style={sectionStyle}>
      <label style={{ fontWeight: 800 }}>QL Variables</label>
      {!isEditing && (
        <div>
          {dataSource.qlVariables ? (
            <CrmJsonPreview json={dataSource.qlVariables} />
          ) : (
            'NO qlVariables'
          )}
          <CrmButton label="Edit" onClick={() => setIsEditing(true)} small />
        </div>
      )}

      {isEditing && (
        <div>
          <CrmTextAreaIntl
            input={{
              value: qlVariablesString,
              onChange: (event) =>
                setQlVariablesString(event.currentTarget.value)
            }}
            rows={5}
          />
          <CrmButton label="Save" onClick={handleSave} small type="primary" />
        </div>
      )}
    </div>
  )
}

const SectionAggregate = ({ dataSource, onSave }) => {
  // TOOD: Render nice editor without UI ( field + dropdown with aggregate type)
  const [isEditing, setIsEditing] = useState(false)
  const [aggregatesString, setAggregatesString] = useState(
    JSON.stringify(dataSource.aggregate)
  )
  const [groupByString, setGroupByString] = useState(
    JSON.stringify(dataSource.groupBy)
  )
  const [showToastr, hideToastr] = useCrmToastr()

  const handleSave = () => {
    console.log('Save aggregate free edit', aggregatesString)

    try {
      const aggregate = JSON.parse(aggregatesString)
      const groupBy = JSON.parse(groupByString)
      onSave({ aggregate, groupBy })

      setIsEditing(false)
    } catch (err) {
      console.error(err)
      showToastr(CrmToastrType.error, 'Error', {
        btnPrimaryTitle: 'ignore',
        message: err.message,
        onBtnPrimary: () => hideToastr(),
        position: CrmToastrPosition.middle,
        timeToShow: null
      })
    }
  }
  return (
    <div style={sectionStyle}>
      <label style={{ fontWeight: 800 }}>Aggregate</label>
      {!isEditing && (
        <div>
          {dataSource.aggregate && dataSource?.aggregate.map ? (
            <React.Fragment>
              {dataSource?.aggregate?.map((aggregate) => {
                return (
                  <div>
                    {JSON.stringify(aggregate)}
                    {/* // TODO TON */}
                  </div>
                )
              })}
            </React.Fragment>
          ) : (
            'NO aggregate'
          )}
          {dataSource?.groupBy && dataSource?.groupBy.map ? (
            <React.Fragment>
              Group By:
              {dataSource?.groupBy?.map((groupBy) => {
                return (
                  <div>
                    {JSON.stringify(groupBy)}
                    {/* // TODO TON */}
                  </div>
                )
              })}
            </React.Fragment>
          ) : (
            'NO groupBy'
          )}
          <CrmButton label="Edit" onClick={() => setIsEditing(true)} small />
        </div>
      )}

      {isEditing && (
        <div>
          <CrmTextAreaIntl
            input={{
              value: aggregatesString,
              onChange: (event) =>
                setAggregatesString(event.currentTarget.value)
            }}
            rows={3}
          />
          <CrmTextAreaIntl
            input={{
              value: groupByString,
              onChange: (event) => setGroupByString(event.currentTarget.value)
            }}
            rows={3}
          />

          <CrmButton label="Save" onClick={handleSave} small type="primary" />
        </div>
      )}
    </div>
  )
}

export const DataSourceQLEditor = ({ dataSource, onChange }) => {
  // const [ldataSource, setLDataSource] = useState(dataSource)

  // const setDataSource(dataSource) {
  //   onChange
  // }
  const handleSaveQlQuery = (qlQuery) => {
    onChange({ ...dataSource, qlQuery })
  }

  const updateDataSource = (field) => (event) => {
    onChange({ ...dataSource, [field]: event.currentTarget.value })
  }
  return (
    <div
      style={{
        height: '100%',
        backgroundColor: '#Fafafa',
        paddingLeft: 10,
        paddingRight: 10
      }}
    >
      <div className="row">
        <div className="col-md-4">
          <CrmTextInput
            placeholder="Name"
            input={{
              value: dataSource.name,
              onChange: updateDataSource('name')
            }}
          />
        </div>
        <div className="col-md-4">
          <CrmTextInput
            placeholder="Code"
            input={{
              value: dataSource.code,
              onChange: updateDataSource('code')
            }}
          />
        </div>
        <div className="col-md-4">
          <CrmTextInput
            placeholder="Type"
            input={{
              value: dataSource.type,
              onChange: updateDataSource('type')
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <CrmTextInput
            placeholder="QL Root"
            input={{
              value: dataSource.qlRoot,
              onChange: updateDataSource('qlRoot')
            }}
          />
        </div>
      </div>
      <QlQueryFreeEditor dataSource={dataSource} onSave={handleSaveQlQuery} />
      <SectionFilter
        dataSource={dataSource}
        onSave={(filter) => onChange({ ...dataSource, filter })}
      />
      <SectionQlVariables
        dataSource={dataSource}
        onSave={(qlVariables) => onChange({ ...dataSource, qlVariables })}
      />
      <SectionAggregate
        dataSource={dataSource}
        onSave={(aggregates) => onChange({ ...dataSource, ...aggregates })}
      />
      {/* <div className="row">
        <div className="col-md-6">
          <CrmTextInput
            placeholder="aggregate"
            input={{
              value: ldataSource.aggregate,
              onChange: updateDataSource('aggregate')
            }}
          />
        </div>
        <div className="col-md-6">
          <CrmTextInput
            placeholder="Group By"
            input={{
              value: ldataSource.groupBy,
              onChange: updateDataSource('groupBy')
            }}
          />
        </div>
      </div> */}

      <small>
        Preview (not editable)
        <CrmJsonPreview json={dataSource} />
      </small>
    </div>
  )
}
