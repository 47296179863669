import React, { PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import { string, func, shape, bool, node } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import { CrmButton } from './index'

class CrmQLActionButton extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, resolve)
    })

  handleClick = async () => {
    console.log('handle click')
    console.dir(this.props)

    await this.setStateAsync({ isSaving: true })
    try {
      const res = await this.props.mutate({ variables: this.props.qlVariables })
      console.log('Completed')
      console.dir(res)
      const message =
        this.props.successMessage &&
        typeof this.props.successMessage === 'string'
          ? this.props.successMessage
          : 'Action completed!'
      toastr.success(message)
      if (this.props.onSuccess) {
        this.props.onSuccess(res, { qlVariables: this.props.qlVariables })
      }
    } catch (err) {
      toastr.error('Error while performing operation')
      console.error(err)
    }
    await this.setStateAsync({ isSaving: false })
  }
  _getSavingMessage = () =>
    this.props.progressMessage ? this.props.progressMessage : 'Saving'
  render() {
    const label = this.state.isSaving
      ? this._getSavingMessage()
      : this.props.label
    return (
      <CrmButton
        small={this.props.small}
        label={label}
        onClick={this.handleClick}
        enable={!this.state.isSaving}
        style={this.props.style ? this.props.style : {}}
      />
    )
  }
}

CrmQLActionButton.propTypes = {
  label: node.isRequired,
  qlVariables: shape({}),
  mutate: func.isRequired,
  successMessage: node,
  progressMessage: node,
  small: bool,
  onSuccess: func,
  style: shape({})
}

CrmQLActionButton.defaultProps = {
  qlMutation: undefined,
  qlVariables: undefined,
  successMessage: undefined,
  progressMessage: undefined,
  small: false,
  onSuccess: () => {},
  style: undefined
}

export default CrmQLActionButton

export const CrmQLActionButtonFactory = props => {
  const CrmQLActionButtonQL = compose(graphql(props.mutation))(
    CrmQLActionButton
  )
  return p => <CrmQLActionButtonQL {...props} {...p} />
}
export const crmQLActionButtonFactory = CrmQLActionButtonFactory
