import React, { PureComponent } from 'react'
import { shape, func } from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import AccountPage from './account-page.jsx'
import { initNewAccount, findMainProcess } from 'crm-utils/accounts-utils'

class ViewAccountPage extends PureComponent {
  constructor(props) {
    console.log('ViewAccount page create', props)
    super(props)
    this.state = {
      accountUid: props.match.params.accountId
    }
    if (props.match.params.accountId === 'new') {
      this.state.account = initNewAccount()
    }
  }

  componentWillMount() {
    if (
      this.props &&
      this.props.match &&
      this.props.match.params.accountId === 'new'
    ) {
      console.log("View account page will mount, is 'new' ")
      this.setState({
        account: initNewAccount(),
        accountUid: this.props.match.params.accountId
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('view account page will receive props', nextProps)
    if (
      nextProps.match.params.accountId !== this.props.match.params.accountId ||
      nextProps.match.params.moduleCode !==
        this.props.match.params.moduleCode ||
      nextProps.location.search !== this.props.location.search
    ) {
      if (nextProps.match.params.accountId === 'new') {
        console.log("View account Page - received props 'new'")
        this.setState({
          account: initNewAccount(),
          accountUid: nextProps.match.params.accountId
        })
      } else {
        this.setState({
          accountUid: nextProps.match.params.accountId,
          account: undefined
        })
      }
    }
  }

  render() {
    const process = findMainProcess(this.state.account)
    return (
      <AccountPage
        gotoPage={this.props.doPush}
        accountUid={this.state.accountUid}
        currentModuleCode={this.props.match.params.currentModuleCode || 'sales'}
        account={this.state.account}
        process={process}
      />
    )
  }
}
ViewAccountPage.propTypes = {
  match: shape({}).isRequired,
  location: shape({}).isRequired,
  doPush: func.isRequired
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    doPush: url => dispatch(push(url))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAccountPage)
