import React, { ComponentType, FC, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Component } from 'crm-types'
import { useCrmContext } from '@cartrack-crm/crm-core'
import { useGenericCommandQL } from 'crm-components/ql/useGenericCommandQL'
import gql from 'graphql-tag'
import cloneDeep from 'clone-deep'
import uuidv4 from 'uuid'

type DashboardMenu = {
  type?: string
  icon?: string
  label?: string
  code?: string
  items?: any
}

export interface WithCrmComponentUtilProps {
  getOneByUid: (uuid: string) => { component: Component; loading: boolean; refetch: Function }
  getComponentByFilter: (filter?: any) => { components: Component[]; loading: boolean; refetch: Function }
  saveComponent: (
    component: Component,
    type: 'create' | 'update'
  ) => Promise<{ data?: any; loading: boolean; error?: any }>
  onArchive: (uuid: string) => Promise<{ isSaving: boolean; error?: any }>
  mergeMenuWithPublishedComponent: (menus: DashboardMenu[]) => DashboardMenu[]
  published_components: Component[]
}

type UseCrmComponentUtil = (props: any) => WithCrmComponentUtilProps

const CRM_COMPONENT_FIELDS = `
  component_uid
  instance_uid
  code
  name
  owner_user_uid
  access_level
  enable_excel_export
  content
  params
  test_filters
  type
  def_version
  is_published
  meta
  is_archived
  scope
`

const QUERY_FIND_COMPONENT_BY_UID = gql`
  query master($master_uid: String!, $instance_uid: String!, $component_uid: String) 
  {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        component(component_uid: $component_uid) {
          ${CRM_COMPONENT_FIELDS}
        }
      }
    }
  }
`

export const QUERY_COMPONENTS = gql`
  query master($master_uid: String!, $instance_uid: String!, $filter: JSON)
    {
      master(master_uid: $master_uid) {
        master_uid
        name
        instance(instance_uid: $instance_uid) {
          instance_uid
          name
          components (filter: $filter){
              ${CRM_COMPONENT_FIELDS}
          } 
        }
      }
    }
`

export const useCrmComponent: UseCrmComponentUtil = props => {
  const { instance, master } = useCrmContext()
  const { isSaving, onRunCommand } = useGenericCommandQL(props)
  const [isEditingDashboard, setEditing] = useState(false)
  const { data } = useQuery(QUERY_COMPONENTS, {
    variables: {
      master_uid: master?.master_uid,
      instance_uid: instance?.instance_uid,
      filter: { is_published: true }
    },
    fetchPolicy: 'no-cache'
  })
  const published_components =
    data?.master?.instance?.components?.map(el => ({ ...el, label: el.name, type: 'item' })) ?? []
  const getOneByUid = (uuid: string) => {
    const { data, loading, refetch } = useQuery(QUERY_FIND_COMPONENT_BY_UID, {
      variables: {
        master_uid: master?.master_uid,
        instance_uid: instance?.instance_uid,
        component_uid: uuid
      },
      fetchPolicy: 'no-cache'
    })
    const component = data?.master?.instance?.component ?? null

    if (component) {
      delete component.__typename
    }

    return { component, loading, refetch }
  }

  const getComponentByFilter = (filter = {}) => {
    const { data, loading, refetch } = useQuery(QUERY_COMPONENTS, {
      variables: {
        master_uid: master?.master_uid,
        instance_uid: instance?.instance_uid,
        filter
      },
      fetchPolicy: 'no-cache'
    })

    const components = data?.master?.instance.components ?? []

    return { components, loading, refetch }
  }

  const saveComponent = async (component: Component, type: 'create' | 'update') => {
    const command = {
      aggregate_type: 'component',
      type: 'component.update',
      aggregate_uid: component?.component_uid,
      command_uid: uuidv4(),
      payload: { ...component }
    }

    if (type === 'create') command.type = 'component.create'

    const loading = isSaving && isEditingDashboard

    try {
      setEditing(true)
      const res = await onRunCommand(command, props)
      const data = res?.data?.cqCommand?.payload
      return { loading, data }
    } catch (error) {
      setEditing(false)
      return { loading, error }
    }
  }

  const onArchive = async uuid => {
    const command = {
      aggregate_type: 'component',
      type: 'component.archive',
      aggregate_uid: uuid,
      command_uid: uuidv4(),
      payload: {}
    }

    try {
      setEditing(true)
      await onRunCommand(command, props)
      setEditing(false)
      return { isSaving }
    } catch (error) {
      return { isSaving, error }
    }
  }

  const mergeMenuWithPublishedComponent = (menus: DashboardMenu[]) => {
    const moreMenus = setPublishedMenuToMoreMenu(published_components)
    let tmpMenu = [...menus, ...moreMenus]
    const resMenu = mergedLoadedComponentDefinition(tmpMenu, published_components)

    return resMenu
  }

  return {
    getOneByUid,
    getComponentByFilter,
    saveComponent,
    onArchive,
    published_components,
    mergeMenuWithPublishedComponent
  }
}

const withCrmComponentUtilHoc = (Component: ComponentType) => {
  const Wrapper: FC = props => {
    const hocProps = useCrmComponent(props)
    return <Component {...props} {...hocProps} />
  }

  return Wrapper
}

function filterToGetMoreMenu(item) {
  return (!item?.meta?.parentCode || item?.meta?.parentCode === 'more') && !item?.meta?.popupOnly
}

function setPublishedMenuToMoreMenu(published_components: Component[]): DashboardMenu[] {
  const menus =
    published_components.filter(filterToGetMoreMenu).map(el => ({ ...el, label: el.name, type: 'item' })) ?? []

  return [
    {
      type: 'section',
      icon: 'car',
      label: 'More',
      code: 'more_dashboard',
      items: [...menus]
    }
  ]
}

function mergedLoadedComponentDefinition(menus: DashboardMenu[], published_components: Component[]): DashboardMenu[] {
  let copyMenus = cloneDeep(menus)

  copyMenus?.map(menu => {
    if (menu?.code) {
      const listItem = menu?.items ?? []
      const dashboardWithCode = published_components.filter(el => el?.meta?.parentCode === menu?.code)
      const pItems = listItem.concat(dashboardWithCode)
      menu.items = [...pItems]
      menu.items = setLoadedComponentItems(menu)
    } else {
      return menu
    }
  })

  return copyMenus
}

function setLoadedComponentItems(menu: DashboardMenu) {
  const copyMenu = cloneDeep(menu)

  return copyMenu.items.map(item => {
    if (item?.load_component) {
      const pItem = { ...item }
      const key = `loadComponent__${pItem.code}`

      pItem.code = key
      pItem.component_uid = key

      return pItem
    }

    return item
  })
}

export default withCrmComponentUtilHoc
