import React from 'react'
import { string } from 'prop-types'

const LoadingIcon = props => (
  <div className={`${props.className} LoadingIcon`} />
)

LoadingIcon.propTypes = {
  className: string
}

LoadingIcon.defaultProps = {
  className: ''
}

export default LoadingIcon
