import React from 'react'
import { useCrmToastr } from '@cartrack-crm/ui/src/toastrs/useCrmToastr'

export const withCrmToastrHoc = (Component: any) => {
  return (props: any) => {
    const [showToastr, hideToastr] = useCrmToastr()
    return (
      <Component showToastr={showToastr} hideToastr={hideToastr} {...props} />
    )
  }
}
