import gql from 'graphql-tag'

export const qlqGenericDataQuery = gql`
  query genericDataQuery(
    $sort: [JSON]
    $offset: Int
    $limit: Int
    $filter: JSON
    $fields: [String]
    $aggregate: [JSON]
    $rootType: String
    $useCache: Boolean
  ) {
    genericDataQuery(
      sort: $sort
      offset: $offset
      limit: $limit
      filter: $filter
      aggregate: $aggregate
      fields: $fields
      rootType: $rootType
      useCache: $useCache
    ) {
      data
      count
    }
  }
`

export const qlAnalyticsWidgetQuery = gql(`
  query dataSourceQuery($widgetDefinition: JSON, $filter: JSON ){
    dataSourceQuery(
      widgetDefinition: $widgetDefinition, 
      filter: $filter
    )
    {
      data
      meta
    }
  }
`)

export const mock = 1
