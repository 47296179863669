/* eslint-disable */
import apiCaller, { callQLApi } from './api-utils'
// import { parseActivity, parseActivityStatus } from './api-parsers'
import { msToUTCTimestamp } from 'util-functions/time-utils'
import { mapDocumentToServer } from '../data/documents-utils'
/* QL Partials */
export const qlDocumentOwnFields = ` 
  document_uid account_uid document_type_uid name created_time date_signed date_presented 
  created_date opportunity_uid sale_type document_date contract_length sale_type currency_code note 
  internal_note is_deleted cycle_code is_show_term_and_condition
  address_uid account_person_uid
`

export const qlDocumentTypeOwnFields = ' document_type_uid name description '
export const qlDocumentItemOwnFields =
  ' document_uid document_item_uid  discount product_uid  quantity  value_net  value_gross  value_tax value_currency_code  subscription_net subscription_net_next_years price_table_uid price_table_item_uid '

export const qlDocument = `  
   ${qlDocumentOwnFields} 
  document_type { ${qlDocumentTypeOwnFields} } 
  items { product { is_addon } ${qlDocumentItemOwnFields} } 
  opportunity { opportunity_uid }
  resource {
    resource_uid
    name
    description
    file_link
  } `

function filterItemsForQL(items) {
  if (items == undefined || items.length == 0) return {}

  return items.map(item => ({
    document_item_uid: item.document_item_uid ? item.document_item_uid : '',
    product_uid: item.product_uid, // eslint-disable-line
    quantity: item.quantity,
    value_net: item.value_net,
    value_gross: item.value_gross ? item.value_gross : 0,
    value_tax: item.value_tax,
    subscription_net: item.subscription_net,
    subscription_tax: item.subscription_tax,
    subscription_length: item.subscription_length,
    value_currency_code: item.value_currency_code
  }))
}

export default {
  listDocuments: params => {
    const args = []
    if (params && params.account_uid) {
      args.push(`account_uid:"${params.account_uid}"`)
    }
    if (params && params.category_code) {
      args.push(`category_code:"${params.category_code}"`)
    }
    const query = ` { documents(${args.join(
      ','
    )}) {  ${qlDocument} account { account_uid name  }  } } `
    return callQLApi(query).then(res => res.documents)
  },
  createDocument: params => {
    const createdObj = {
      name: params.name,
      account_uid: params.account_uid,
      document_type_uid: params.document_type_uid,
      created_date: params.created_date,
      items: filterItemsForQL(params.items),
      opportunity_uid: params.opportunity_uid
    }

    if (params.date_presented) {
      createdObj.date_presented = params.date_presented
    }

    if (params.date_signed) {
      createdObj.date_signed = params.date_signed
    }

    const qlParams = {
      query: ` mutation  document_create($document:document_in) { document_create(document: $document)  { ${qlDocumentOwnFields} }  } `,
      variables: {
        document: mapDocumentToServer(createdObj)
      }
    }

    return callQLApi(qlParams).then(res => {
      return res.document_create
    })
  },
  updateDocument: params => {
    const updateObj = {
      name: params.name,
      account_uid: params.account_uid,
      document_type_uid: params.document_type_uid,
      document_uid: params.document_uid,
      created_date: params.created_date,
      items: filterItemsForQL(params.items),
      items_delete: params.items_delete,
      opportunity_uid: params.opportunity_uid
    }
    if (params.date_presented) {
      updateObj.date_presented = params.date_presented
    }

    if (params.date_signed) {
      updateObj.date_signed = params.date_signed
    }

    const qlParams = {
      query: ` mutation  document_update($document:document_in) { document_update(document: $document)  { ${qlDocumentOwnFields} }  } `,
      variables: {
        document: updateObj
      }
    }

    return callQLApi(qlParams).then(res => {
      return res.document_update
    })
  }
}
