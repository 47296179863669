import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { func, shape } from 'prop-types'
import uuidv4 from 'uuid'
import FitmentTable from './fitment-table.jsx'
import crmGenericCommandQlHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import {
  CrmFullpageContainer,
  CrmHbox,
  CrmButton,
  CrmDateRangeDropdown,
  CrmTextInput,
  CrmCheckbox,
  CrmDropdown,
  CrmUserSelect,
} from 'crm-components'
import { Icon } from 'util-components'
import { push } from 'connected-react-router'

class CrmFitmentSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      camUsername: '',
      showOnlyMissingCams: false,
      contractId: '',
      vehicle: '',
      status: 'completed',
      ownerUser: '',
      completedDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
      salesDate: undefined
    }

    this.columns = [
      {
        Header: 'Account Name',
        accessor: 'account_name',
        getProps: (state, rowInfo, column) => {
          return {
            className: 'util-hooverableContainer util-hooverOp'
          }
        }
      },
      {
        Header: 'CAMS Username',
        accessor: 'cams_username',
        width: 120
      },
      {
        Header: 'Owner User',
        accessor: 'owner__full_name',
        width: 170
      },
      {
        Header: 'Vehicles',
        accessor: 'vehicles_count',
        width: 120
      },
      {
        Header: 'Sale Date',
        accessor: 'sale_date',
        width: 120
      },
      {
        Header: 'Completed Date',
        accessor: 'completed_date',
        width: 120
      },
      {
        Header: 'Status',
        accessor: 'status_code',
        width: 120
      },
      {
        Header: 'Registration',
        accessor: 'vehicle_registration'
      },
      {
        Header: 'Contract Id',
        accessor: 'external_id'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Action',
        width: 60,
        sortable: false,
        Cell: row => (
          <div>
            <CrmButton
              className="remove-button"
              xsmall
              icon="trash"
              id={row.original.order_uid}
              onClick={this.handleDelete}
            />
          </div>
        )
      }
    ]

    this.handleClickAddBtn = this.handleClickAddBtn.bind(this)
    this.handleClickRowTable = this.handleClickRowTable.bind(this)
  }

  handleDelete = async event => {
    const fitmentUid = event.currentTarget.id
    const toastrConfirmOptions = {
      onOk: () => this.handleDeleteProcess(fitmentUid)
    }

    toastr.confirm('Are you sure you want to delete ? ', toastrConfirmOptions)
  }

  handleDeleteProcess = async fitmentUid => {
    const command = {
      type: 'FitmentQM.delete',
      aggregate_uid: uuidv4(),
      aggregate_type: 'FitmentQM',
      payload: {
        fitment_uid: fitmentUid
      }
    }

    try {
      await this.props.onRunCommand(command, {})
      this.refreshData()
    } catch (err) {}
  }

  handleClickRowTable(rowInfo) {
    if (rowInfo) {
      const account_uid = rowInfo.original.account_uid
      if (account_uid) {
        this.props.doPush('/crm/account/' + account_uid)
      }
    }
  }

  handleCamUsernameChange = event => {
    this.setState({ camUsername: event.currentTarget.value })
  }

  handleContractIdChange = event => {
    this.setState({ contractId: event.currentTarget.value })
  }

  handleVehicleChange = event => {
    this.setState({ vehicle: event.currentTarget.value })
  }

  handleStatusChange = value => {
    this.setState({ status: value })
  }

  handleOwnerUserChange = value => {
    this.setState({ ownerUser: value })
  }

  handleShowOnlyMissingCamsChange = () => {
    this.setState({ showOnlyMissingCams: !this.state.showOnlyMissingCams })
  }

  handleDateChange = (date, type) => {
    const nextState = { ...this.state }
    if (type === 'completed_date') {
       nextState.completedDate = {
        startDate: date.startDate,
        endDate: date.endDate
      }
    } else {
      nextState.salesDate = {
        startDate: date.startDate,
        endDate: date.endDate
      }
    }
    
    this.setState(nextState)
  }

  handleClickAddBtn() {
    toastr.info('To register new fitment you must go to account page')
  }

  tableDataRefresh = refreshFunction => {
    this.refreshData = refreshFunction
  }

  getStatusOptions = () => {
    return [
      {
        name: 'All',
        value: ''
      },
      {
        name: 'Pending',
        value: 'pending'
      },
      {
        name: 'Completed',
        value: 'completed'
      },
      {
        name: 'Canceled',
        value: 'canceled'
      }
    ]
  }
  render() {
    const getTdProps = (state, rowInfo, column) => ({
      onClick: () => {
        if (column.id === 'account_name') {
          this.handleClickRowTable(rowInfo)
        }
      }
    })

    const value = {
      completedDate: { value: [this.state.completedDate?.startDate, this.state.completedDate?.endDate] },
      salesDate: { value: [this.state.salesDate?.startDate, this.state.salesDate?.endDate] }
    }

    return (
      <CrmFullpageContainer hasPadding vbox>
        <div fill="true">
          <div className="util-flexRow util-paddingMd">
            <div className="util-flexGrow">
              <div className="util-flexRow">
               
                <div className="util-flexGrow util-marginRight">
                  <CrmDropdown
                    placeholder="Status"
                    options={this.getStatusOptions()}
                    input={{
                      value: this.state.status,
                      onChange: this.handleStatusChange
                    }}
                  />
                </div>
                <div className="util-flexGrow util-marginRight" style={{ position: 'relative' }}>
                  <CrmDateRangeDropdown
                    name="date_range"
                    placeholder="Completed Date"
                    onChange={(v) => this.handleDateChange(v, 'completed_date')}
                    dateFormat="DD-MM-YYYY"
                    input={{
                      value: {
                        startDate: this.state.completedDate?.startDate,
                        endDate: this.state.completedDate?.endDate
                      }
                    }}
                  />
                   <Icon
                    name="close"
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      width: '12px',
                      cursor: 'pointer'
                    }}
                    onClick={() => { this.setState({ completedDate: undefined }) }}
                   />
                </div>
                <div className="util-flexGrow util-marginRight" style={{ position: 'relative' }}>
                  <CrmDateRangeDropdown
                    name="date_range"
                    placeholder="Sales Date"
                    onChange={(v) => this.handleDateChange(v, 'sales_date')}
                    dateFormat="DD-MM-YYYY"
                    input={{
                      value: {
                        startDate: this.state.salesDate?.startDate,
                        endDate: this.state.salesDate?.endDate
                      }
                    }}
                  />
                   <Icon
                    name="close"
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      width: '12px',
                      cursor: 'pointer'
                    }}
                    onClick={() => { this.setState({ salesDate: undefined }) }}
                   />
                </div>
                <div className="util-flexGrow util-marginRight">
                  <CrmTextInput
                    placeholder="Cams username"
                    input={{
                      value: this.state.camUsername,
                      onChange: this.handleCamUsernameChange
                    }}
                  />
                </div>

                <div className="util-flexGrow util-marginRight">
                  <CrmTextInput
                    placeholder="Contract ID"
                    input={{
                      value: this.state.contractId,
                      onChange: this.handleContractIdChange
                    }}
                  />
                </div>
                <div className="util-flexGrow util-marginRight">
                  <CrmTextInput
                    placeholder="Vehicle"
                    input={{
                      value: this.state.vehicle,
                      onChange: this.handleVehicleChange
                    }}
                  />
                </div>
                <div className="util-marginRight" style={{ width: '250px' }}>
                  <CrmUserSelect
                    name="ownerUser"
                    input={{
                      value: this.state.ownerUser,
                      onChange: this.handleOwnerUserChange
                    }}
                    placeholder="Select Owner User"
                  />
                </div>

                <div style={{ width: '100px' }}>
                  <CrmCheckbox
                    input={{
                      value: this.state.showOnlyMissingCams,
                      onChange: this.handleShowOnlyMissingCamsChange
                    }}
                    label="No CAMS"
                  />
                </div>
              </div>
            </div>
            <div className="util-marginLeft">
              <CrmButton label="Create" onClick={this.handleClickAddBtn} />
            </div>
          </div>
        </div>

        <CrmHbox fill style={{ flex: 4 }}>
          <div style={{ flex: 3 }}>
            <FitmentTable
              camUsername={this.state.camUsername}
              contractId={this.state.contractId}
              vehicle={this.state.vehicle}
              status={this.state.status}
              ownerUser={this.state.ownerUser}
              dateRange={value}
              showOnlyMissingCams={this.state.showOnlyMissingCams}
              {...this.props}
              columns={this.columns}
              getTdProps={getTdProps}
              getRefreshFunc={this.tableDataRefresh}
              defaultSorted = {[
                { 'id': 'completed_date', 'desc': true },
                { 'id': 'sale_date', 'desc': true }
              ]}
            />
          </div>
        </CrmHbox>
      </CrmFullpageContainer>
    )
  }
}

CrmFitmentSettings.propTypes = {
  fitment: shape({}),
  ...cqCommandPropTypes,
  doPush: func.isRequired
}

CrmFitmentSettings.defaultProps = {
  fitment: {}
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    doPush: url => dispatch(push(url))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(crmGenericCommandQlHoc(CrmFitmentSettings))
