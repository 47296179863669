import React from 'react'
import { CrmTable, CrmModal, CrmHbox, CrmButton } from 'crm-components'
import { Query } from 'react-apollo'
import { qlGetResource } from 'crm-data/resources'

class ImportToolInner extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sheetSelected: 0
    }
  }

  render() {
    console.log('Render Import Tool Inner', this.props)
    const html = []
    const columns = []
    const sheets = []
    let rowData
    const resource = this.props.resource
    console.log('resource')
    if (resource && resource.parsed && resource.parsed.sheets) {
      console.log('Render sheets')
      resource.parsed.sheets.forEach(item => {
        if (item.rows) {
          const data = []
          item.rows.forEach((value, key) => {
            if (key === 0) {
              value.forEach(val => {
                if (val) {
                  columns.push({
                    Header: val,
                    accessor: val ? val.toLowerCase().replace(' ', '_') : ''
                  })
                }
              })
            } else {
              rowData = {}
              value.forEach((rowVal, rowkey) => {
                if (columns[rowkey] && columns[rowkey].accessor)
                  rowData[columns[rowkey].accessor] = rowVal
              })
              data.push(rowData)
            }
          })

          html.push(<CrmTable columns={columns} data={data} />)
          sheets.push(item.sheet_name)
        }
      })
    }

    const tabs = sheets.map((sheet, index) => (
      <div
        key={`tab_${index}`} // eslint-disable-line
        className={`Navbar-innerNav-tab 
        ${this.state.sheetSelected === index ? 'is-active' : ''}`}
        id={index}
        onClick={this.handleClickTab}
      >
        {sheet}
      </div>
    ))

    const tabsContent = html.map((html, index) => (
      <div
        key={`tabdata_${index}`} // eslint-disable-line
        style={{
          display: `${this.state.sheetSelected === index ? 'block' : 'none'}`
        }}
      >
        {html}
      </div>
    ))

    return (
      <div>
        <div className="Navbar-innerNav">{tabs}</div>
        <div>{tabsContent}</div>
      </div>
    )
  }
}

const ImportToolInnerWithData = props => (
  <Query
    query={qlGetResource}
    variables={{ resource_uid: props.resourceUid }}
    skip={!props.resourceUid}
  >
    {({ loading, error, data }) => {
      const resource =
        data && data.resources && data.resources.length === 1
          ? data.resources[0]
          : undefined

      return (
        <ImportToolInner {...props} loading={loading} resource={resource} />
      )
    }}
  </Query>
)
export default ImportToolInnerWithData
