/* eslint-disable camelcase */
import { shape, func, oneOfType, array } from 'prop-types'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import countries from '../../../../../countries.jsx'
import React from 'react'
import uuidv4 from 'uuid'

const sectionContactDetailsHoc = WrappedComponent => {
  class SectionContactDetailsHocInner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        account: props.account ? { ...props.account } : undefined,
        person: props.person ? { ...props.person } : undefined,
        personContactDetails: props.personContactDetails
          ? [...props.personContactDetails]
          : undefined,
        accountContactDetails: props.accountContactDetails
          ? [...props.accountContactDetails]
          : undefined
      }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      const newState = {}
      if (nextProps.personContactDetails !== this.props.personContactDetails) {
        newState.personContactDetails = nextProps.personContactDetails
          ? [...nextProps.personContactDetails]
          : undefined
      }
      if (
        nextProps.accountContactDetails !== this.props.accountContactDetails
      ) {
        newState.accountContactDetails = nextProps.accountContactDetails
          ? [...nextProps.accountContactDetails]
          : undefined
      }
      if (nextProps.person !== this.props.person) {
        newState.person = nextProps.person ? { ...nextProps.person } : undefined
      }
      if (nextProps.account !== this.props.account) {
        newState.account = nextProps.account
          ? { ...nextProps.account }
          : undefined
      }
      this.setState(newState)
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })

    handleStartAddPersonContactDetail = () => {
      const newContactDetails = {
        kind: '',
        value: '',
        newObjectUid: uuidv4()
      }
      if (this.state.person.isNew || this.state.person.isEditing) {
        newContactDetails.isNew = true
      } else {
        newContactDetails.isNewInlineItem = true
        newContactDetails.account_uid = this.state.person.account_uid
        newContactDetails.account_person_uid = this.state.person.account_person_uid
      }
      const personContactDetails = [
        ...this.state.personContactDetails,
        newContactDetails
      ]
      this.setState(
        {
          personContactDetails
        },
        () => {
          this.props.onPersonContactDetailChanged(
            this.state.personContactDetails
          )
        }
      )
    }

    handleStartAddAccountContactDetail = () => {
      const newContactDetails = {
        kind: '',
        value: '',
        newObjectUid: uuidv4(),
        isNewInlineItem: true,
        account_uid: this.state.account.account_uid
      }
      const accountContactDetails = [...this.state.accountContactDetails]

      const newContactDetail = accountContactDetails.find(c => c.name === null)

      if (newContactDetail) {
        newContactDetail.contactDetails.push(newContactDetails)
      } else {
        accountContactDetails.push({
          name: null,
          contactDetails: [{ newContactDetails }]
        })
      }

      this.setState(
        {
          accountContactDetails
        },
        () => {}
      )
    }

    handleRemovePersonContactDetail = index => {
      const personContactDetails = [...this.state.personContactDetails]
      if (this.state.personContactDetails[index].contact_details_uid) {
        // if existing contact
        personContactDetails[index].isDeleted = true
      } else {
        // if new contact remove from array
        personContactDetails.splice(index, 1)
      }
      this.setState(
        {
          personContactDetails
        },
        () => {
          this.props.onPersonContactDetailChanged(
            this.state.personContactDetails
          )
        }
      )
    }

    handleRemoveAccountContactDetail = (index, name) => {
      const accountContactDetails = [...this.state.accountContactDetails]
      if (this.state.accountContactDetails[index].contact_details_uid) {
        // if existing contact
        accountContactDetails[index].isDeleted = true
      } else {
        // if new contact remove from array
        accountContactDetails.splice(index, 1)
      }
      this.setState(
        {
          accountContactDetails
        },
        () => {}
      )
    }

    handleRemoveArchivedContactDetail = (index, name) => {
      if (!this.state.personContactDetails) {
        const accountContactDetails = [...this.state.accountContactDetails]
        const person = accountContactDetails.find(p => p.name === name)

        if (person && person.contactDetails[index].contact_details_uid) {
          if (person.contactDetails.length === 1) {
            const mIndex = accountContactDetails.indexOf(person)
            accountContactDetails.splice(mIndex, 1)
          } else {
            person.contactDetails.splice(index, 1)
          }
        }
        this.setState(
          {
            accountContactDetails
          },
          () => {}
        )
      } else {
        const personContactDetails = [...this.state.personContactDetails]
        if (this.state.personContactDetails[index].contact_details_uid) {
          personContactDetails.splice(index, 1)
        }
        this.setState(
          {
            personContactDetails
          },
          () => {}
        )
      }
    }

    handlePersonContactDetailsFieldChange = (index, contactDetail) => {
      const personContactDetails = this.state.personContactDetails.map((v, i) =>
        i === index ? contactDetail : v
      )
      this.setState(
        {
          personContactDetails
        },
        () => {
          this.props.onPersonContactDetailChanged(
            this.state.personContactDetails
          )
        }
      )
    }

    getKindOptions = () => {
      const options = [
        {
          name: 'Email',
          value: 'email'
        },
        {
          name: 'Phone',
          value: 'phone'
        },
        {
          name: 'Mobile',
          value: 'mobile'
        },
        {
          name: 'Line',
          value: 'line'
        }
      ]
      return options
    }

    getKindIcons = () => {
      const icon = {
        unknown: {
          classA: 'fa fa-question'
        },
        email: {
          classA: 'fa fa-envelope-o'
        },
        phone: {
          classA: 'fa fa-phone'
        },
        mobile: {
          classA: 'fa fa-mobile'
        },
        line: {
          classA: 'fa fa-commenting-o'
        }
      }
      return icon
    }

    getCountriesOptions = () => {
      const options = Object.keys(countries).map(key => {
        const country = countries[key]
        return { name: key + ' (+' + country.code + ')', value: country.code }
      })
      return options
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          account={this.state.account}
          accountContactDetails={this.state.accountContactDetails}
          onStartAddAccountContactDetail={
            this.handleStartAddAccountContactDetail
          }
          onRemoveAccountContactDetail={this.handleRemoveAccountContactDetail}
          person={this.state.person}
          personContactDetails={this.state.personContactDetails}
          onStartAddPersonContactDetail={this.handleStartAddPersonContactDetail}
          onRemovePersonContactDetail={this.handleRemovePersonContactDetail}
          onPersonContactDetailsFieldChange={
            this.handlePersonContactDetailsFieldChange
          }
          onRemoveArchivedContactDetail={this.handleRemoveArchivedContactDetail}
          getKindOptions={this.getKindOptions}
          getKindIcons={this.getKindIcons}
          getCountriesOptions={this.getCountriesOptions}
        />
      )
    }
  }

  SectionContactDetailsHocInner.propTypes = {
    person: shape(),
    account: shape(),
    personContactDetails: oneOfType([array, shape()]),
    accountContactDetails: oneOfType([array, shape()]),
    onPersonContactDetailChanged: func
  }
  SectionContactDetailsHocInner.defaultProps = {
    onPersonContactDetailChanged: () => {},
    personContactDetails: undefined,
    accountContactDetails: undefined
  }
  return crmGenericCommandHoc(SectionContactDetailsHocInner)
}

export default sectionContactDetailsHoc
