/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape } from 'prop-types'
import AccountInfoFilter from '../accounts/list/components/filter/filters/account-info-filter.jsx'
import TasksTable from './tasks-table.jsx'
import { CrmFullpageContainer } from 'crm-components'
import {
  getFilterGroup,
  getQFilterGroup,
  initFiltersGroup
} from 'crm-duxs/filters-reducer'
import { getUser } from 'crm-duxs/crm-reducer'

class MyDialerTasksView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const mergedFilter = {
      ...this.props.qfilter,
      process_type_kind: 'dialer',
      assignee_user_uid: this.props.currentUser.user_uid,
      is_completed: false
    }
    return (
      <CrmFullpageContainer vbox>
        <div className="util-paddingMd">
          <AccountInfoFilter accountsListCode="my_dialer_tasks" />
        </div>
        <TasksTable filter={mergedFilter} exportFileName="My_dialer_tasks" />
      </CrmFullpageContainer>
    )
  }
}

MyDialerTasksView.propTypes = {
  qfilter: shape({}).isRequired,
  currentUser: shape({}).isRequired
}

function mapStateToProps(state) {
  return {
    filters: getFilterGroup(state, 'my_dialer_tasks'),
    qfilter: getQFilterGroup(state, 'my_dialer_tasks'),
    currentUser: getUser(state)
  }
}
function mapDispatchToProps(dispatch) {
  return {
    doInitFiltersGroup: groupCode => dispatch(initFiltersGroup(groupCode))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDialerTasksView)
