import React from 'react'
import { ActivityItemContiner } from './shared.styled'
import {
  CrmActivityItem,
  CrmActivityNotation,
  CrmActivityCategory,
  CrmActivityAction
} from '.'
import { CrmButtonGroup, CrmButton } from '@cartrack-crm/ui'
import CrmActivityContent from './activity-content-email'

const ActivityEmail = (props) => {
  const { activity } = props
  return (
    <ActivityItemContiner>
      <CrmActivityNotation
        activity_type={activity.activity_type}
        direction={activity.direction}
      />
      <CrmActivityItem isSuccess={activity.activity_status?.is_success}>
        <CrmActivityCategory
          label={activity.activity_type.name}
          sequence={activity.seq_same_type_success_user}
          createdTime={activity.created_time}
          isCustomer={props.isCustomer}
        />
        <CrmActivityContent {...activity} />
        <CrmActivityAction
          showActionsOnHover
          activityStatus={activity.activity_status}
        >
          <CrmButtonGroup small>
            <CrmButton icon="eye" onClick={props.onEditActivity} />
          </CrmButtonGroup>
        </CrmActivityAction>
      </CrmActivityItem>
    </ActivityItemContiner>
  )
}
export default ActivityEmail
