import React, { Component } from 'react'
import { reduxForm, Field, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import validate from 'crm-utils/validates'
import { func, shape } from 'prop-types'
import { SectionHeader, Spacer } from 'util-components'
import {
  CrmTextInput,
  CrmButton,
  CrmDropdown,
  CrmValidatedField
} from 'crm-components'

class AttributeFormInner extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { handleSubmit } = this.props
    //console.log('Attribute form : ', this.props)
    const formValues = !this.props.formValues ? {} : this.props.formValues
    return (
      <div>
        <form onSubmit={handleSubmit} className="userForm">
          <fieldset className="Form-fieldset">
            <SectionHeader
              label="Attribute Info"
              sub="true"
              id="master-info-section"
            />
            <div className="row util-marginBottom">
              <div className="col-md-3">
                <Field
                  name="code"
                  component={CrmTextInput}
                  type="text"
                  placeholder="Code"
                  validate={[validate.required]}
                />
              </div>
              <div className="col-md-3">
                <CrmValidatedField
                  name="data_type"
                  component={CrmDropdown}
                  componentProps={{
                    placeholder: 'Data Type',
                    options: [
                      { name: 'Number', value: 'number' },
                      { name: 'String', value: 'string' },
                      { name: 'Boolean', value: 'boolean' }
                    ]
                  }}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="name"
                  component={CrmTextInput}
                  type="text"
                  placeholder="Name"
                  validate={[validate.required]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="description"
                  component={CrmTextInput}
                  type="text"
                  placeholder="Description"
                />
              </div>
            </div>
            <SectionHeader
              label="Attribute Type"
              sub="true"
              id="master-type-section"
            />
            <small>
              which page you want to show this ex. account page, master config
              page, instance config page
            </small>
            <div className="row util-marginBottom">
              <div className="col-md-3">
                <CrmValidatedField
                  name="type"
                  component={CrmDropdown}
                  componentProps={{
                    placeholder: 'Type',
                    options: [
                      { name: 'Account', value: 'account' },
                      { name: 'Process', value: 'process' },
                      { name: 'Master', value: 'master' },
                      { name: 'Instance', value: 'instance' }
                    ]
                  }}
                />
              </div>
            </div>

            <SectionHeader
              label="Attribute Level"
              sub="true"
              id="master-level-section"
            />
            <small>
              who should see this ex. master cartrack only, instance th only,
              ...{' '}
            </small>
            <div className="row util-marginBottom">
              <div className="col-md-3">
                <CrmValidatedField
                  name="level"
                  component={CrmDropdown}
                  componentProps={{
                    placeholder: 'Level',
                    options: [
                      { name: 'System', value: 'system' },
                      { name: 'Master', value: 'master' },
                      { name: 'Instance', value: 'instance' }
                    ]
                  }}
                />
              </div>
              {formValues.hasOwnProperty('level') &&
                (formValues.level === 'instance' ||
                  formValues.level === 'master') && (
                  <div className="col-md-3">
                    <Field
                      name="master_uid"
                      component={CrmTextInput}
                      type="text"
                      placeholder="Master Uid"
                      validate={[validate.required]}
                    />
                  </div>
                )}
              {formValues.hasOwnProperty('level') &&
                formValues.level === 'instance' && (
                  <div className="col-md-3">
                    <Field
                      name="territory_uid"
                      component={CrmTextInput}
                      type="text"
                      placeholder="Instance Uid"
                      validate={[validate.required]}
                    />
                  </div>
                )}
            </div>
            <Spacer height="20px" />

            <div className="row">
              <CrmButton label="Save" type="submit" />
            </div>
          </fieldset>
        </form>
      </div>
    )
  }
}

AttributeFormInner.propTypes = {
  handleSubmit: func.isRequired,
  formValues: shape({}).isRequired
}

AttributeFormInner.defaultProps = {}

const AttributeFormInnerReduxForm = reduxForm({
  form: 'crmAttributeForm',
  enableReinitialize: true
})(AttributeFormInner)

function mapStateToProps(state) {
  return {
    formValues: getFormValues('crmAttributeForm')(state)
  }
}

const AttributeFormInnerReduxFormwithState = connect(mapStateToProps)(
  AttributeFormInnerReduxForm
)

export default AttributeFormInnerReduxFormwithState
