import React from 'react'
import { withApollo } from 'react-apollo'
import { shape } from 'prop-types'
import { mapContextToFilter } from '../../../dashboard-utils'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import gql from 'graphql-tag'

const widgetFitmentsArpuByRmHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {}
    }

    handleRefresh = async () => {
      console.log('widgetFitmentsArpuByRmHoc refresh', this.props)
      const filter = mapContextToFilter(this.props, false)
      filter.status_code = 'completed'
      if (this.props.analyticsContext.filters.activity_date) {
        filter.completed_date = this.props.analyticsContext.filters.activity_date
      }

      const QL_FITMENTS = gql(`
        query master(
          $master_uid: String!
          $instance_uid: String!
          $filter: JSON
          $groupBy: [String]
          $aggregate: [JSON]
        ) {
          master(master_uid: $master_uid) {
            master_uid
            name
            instance(instance_uid: $instance_uid) {
              instance_uid
              name
              orders(
                filter: $filter
                groupBy: $groupBy
                aggregate: $aggregate
              ) {
                edges {
                  edge {
                    order_uid
                    quantity
                    owner_user_uid
                    owner {
                      full_name
                    }
                    lead_source {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `)

      const variables = {
        filter,
        aggregate: [{ id: 'quantity', type: 'count' }],
        rootType: 'OrdersListQM',
        master_uid: this.props.crmContext.master.master_uid,
        instance_uid: this.props.crmContext.instance.instance_uid,
        groupBy: ['lead_source_uid', 'owner_user_uid']
      }
      console.log('Variables', variables)

      const res = await this.props.client.query({
        query: QL_FITMENTS,
        variables,
        fetchPolicy: 'no-cache'
      })

      // console.log('RES', res)

      if (res.data) {
        var mapped = res?.data?.master?.instance?.orders
          ? res.data.master.instance.orders.edges.map(({ edge }) => ({
              ...edge,
              owner__full_name: edge.owner ? edge.owner.full_name : 'empty',
              account__lead_campaign__lead_source__name: edge.lead_source
                ? edge.lead_source.name
                : 'empty'
            }))
          : []
        this.setState({
          data: mapped
        })
      }
    }

    render() {
      console.log('render widget-fitments-by-user-and-source')
      return (
        <WrappedComponent
          {...this.props}
          data={this.state.data}
          onRefresh={this.handleRefresh}
        />
      )
    }
  }

  Inner.propTypes = {
    analyticsContext: shape({}).isRequired,
    client: shape({}).isRequired,
    crmContext: shape({})
  }
  return withCrmContext(withApollo(Inner))
}

export default widgetFitmentsArpuByRmHoc
