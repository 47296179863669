import React, { SFC, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import fetch from 'fetch-everywhere'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActivityContentProps } from './activity-content'
import { CrmTranslateText } from '@cartrack-crm/ui'
import CrmActivityPerformer from './activity-performer'

const LiveCallActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Note = styled.div`
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
`

const CallLog = styled.div`
  font-size: 9px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 5px;
  color: #f15b5b;
`

const RowItem = styled.div`
  svg {
    margin-right: 6px;
    color: #999999;
  }
  margin-right: 16px;
`
const Disposition = styled(RowItem)``
const PhoneNumber = styled(RowItem)``
const CallDuration = styled(RowItem)``
const Performer = styled(RowItem)``

const TopRow = styled.div`
  display: flex;
  color: #3a3a3a;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 16px;
  align-items: center;
  svg {
    font-size: 11px;
  }
`
interface LiveCallActivityContentProps extends ActivityContentProps {
  call_external_uid?: string
  contact_details?: any
  isShowRecord?: boolean
  isOwner?: boolean
  dialerUrl?: string
  setShowRecord?: any
}

const LiveCallActivity: SFC<LiveCallActivityContentProps> = (props) => {
  let callDuration = ''
  const [showAudioPlayer, setAudioPlayer] = useState(null)

  if (props.call_start_time && props.call_end_time) {
    const callStartTimeMoment = moment(props.call_start_time)
    const callEndTimeMoment = moment(props.call_end_time)
    const diffTime = callEndTimeMoment.diff(callStartTimeMoment, 'seconds')
    callDuration = hhmmss(diffTime)
  }

  function _getFileMeta(endpoint) {
    return fetch(`${endpoint}`,
          {headers: {'Connection': 'close'},Connection: close}
        )
        .then(response => response.text())
        .then((response) => {
          let contentLength = response.length;
          if (contentLength > 1000) {
            setAudioPlayer(true)
          } else {
            setAudioPlayer(false)
            props.setShowRecord(false)
          }
        })
       }

  let getFileMeta = useCallback(_getFileMeta, [])

  useEffect(() => {
    if (props.isShowRecord) {
      getFileMeta(`${props.dialerUrl}/play/${props?.call_log?.call_uid}`);
    }
  }, [props.isShowRecord, getFileMeta]);

  return (
    <LiveCallActivityContainer>
      <TopRow>
        <Performer>
          <CrmActivityPerformer
            isOwner={props.isOwner}
            performer={props.primary_performer}
          />
        </Performer>
        {props?.contact_details?.value && (
          <PhoneNumber>
            <FontAwesomeIcon icon="phone" />
            {props?.contact_details?.value}
          </PhoneNumber>
        )}
        {props.disposition_type?.name && (
          <Disposition>
            <FontAwesomeIcon icon="clipboard-list" />
            {props.disposition_type.name}
          </Disposition>
        )}
        {callDuration && (
          <CallDuration>
            <FontAwesomeIcon icon="stopwatch" />
            {callDuration}
          </CallDuration>
        )}
      </TopRow>
      {props.note_text && (
        <Note>
          <CrmTranslateText>{props.note_text}</CrmTranslateText>
        </Note>
      )}

      {showAudioPlayer == true && (
        <RecordingPlayer dialerUrl={props.dialerUrl} activity={props} />
      )}

      {props.activity_type?.code == 'live_call' &&
      props.call_log?.call_uid && !props?.call_log?.path && (
        <CallLog>Call Status: {props.call_log?.current_status} - (No voice recording file found).</CallLog>
      )}

      {props.activity_type?.code == 'live_call' && !props.call_log && (
        <CallLog>No dialer call log found.</CallLog>
      )}

      {props.activity_type?.code == 'live_call' && props.call_log &&
      showAudioPlayer == false && props?.call_log?.path && (
        <CallLog>Voice recording call duration too short.</CallLog>
      )}


    </LiveCallActivityContainer>
  )
}

const RecordingPlayer = ({ dialerUrl, activity }) => {
  const [showDropDownMenu, setShowDropDownMenu] = useState(false)
  const source = `${dialerUrl}/play/${activity?.call_log?.call_uid}`
  const { has_recording_permission } = activity
  const toggleDropdown = () => setShowDropDownMenu(!showDropDownMenu)
  const Download = styled.div`
    .icon-download {
      border: solid 1px gray;
      border-radius: 7px;
      margin-left: 10px;
      width: 25px;
      height: 26px;
      font-size: 13px;
      &:hover {
        color: ${(p) => (p.expanded ? 'white' : '#ff6a00')};
        background-color: ${(p) => (p.expanded ? '#ff6a00' : 'white')};
        border: ${(p) =>
          p.expanded ? 'solid 1px #ff6a00' : 'solid 1px #ff6a00'};
      }
      color: ${(p) => (p.expanded ? 'white' : 'inherit')};
      background-color: ${(p) => (p.expanded ? '#ff6a00' : 'inherit')};
      border: ${(p) => (p.expanded ? 'solid 1px #ff6a00' : 'inherit')};
    }

    .Ellipse-Dropdown ul {
      top: 18px;
    }

    .Ellipse-Dropdown ul li {
      padding: 7px 7px 0px 10px;
    }
  `

  const Audio = styled.div`
    .audio-wrapper {
      width: 340px;
      background-color: grey;
      border-radius: 10px;
      height: 30px;
    }

    audio {
      width: 340px;
      height: 30px;
    }
  `
  if (!has_recording_permission) {
    return (
      <div>
        {"You don't have permission to read activity "}
        {' - '}
        {activity.activity_type.module}
      </div>
    )
  }

  return (
    <div className="util-flexRow">
      <Audio>
        <div className="audio-wrapper">
          <audio controls autoPlay>
            <source src={`${source}.mp3`} type=" audio/mp3" />
          </audio>
        </div>
      </Audio>
      <div className="util-flexCenterContent">
        <Download expanded={showDropDownMenu}>
          <div className="Ellipse-Dropdown" onClick={toggleDropdown}>
            <div
              className={`icon-background ${
                showDropDownMenu ? 'icon-download' : 'icon-download'
              }`}
            >
              <i className="fas fa-download"></i>
            </div>
            {showDropDownMenu && (
              <ul>
                <li>
                  <a
                    href={`${source}.wav`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>Download WAV</div>
                  </a>
                </li>
                <li>
                  <a
                    href={`${source}.mp3`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>Download MP3</div>
                  </a>
                </li>
              </ul>
            )}
          </div>
        </Download>
      </div>
    </div>
  )
}

const hhmmss = (sec) => {
  const pad = (num) => {
    return ('0' + num).slice(-2)
  }
  if (!sec || isNaN(Number(sec))) {
    return undefined
  }
  let secs = sec
  let minutes = Math.floor(secs / 60)
  secs %= 60
  const hours = Math.floor(minutes / 60)
  minutes %= 60
  return `${hours > 0 ? `${hours}:` : ``}${pad(minutes)}:${pad(secs)}`
}

export default LiveCallActivity
