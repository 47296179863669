/* eslint-disable camelcase */
import React from 'react'
import { func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ReassignWidget from './_reassign-widget.jsx'
import { CrmModal } from 'crm-components'

const ReassignModal = props => (
  <CrmModal
    isOpen={props.isOpen}
    title={
      <FormattedMessage
        id="crm.ui.account.reassign.reassign_account"
        defaultMessage="Reassign Account"
      />
    }
    onClose={props.onClose}
    showFooter
    secondaryButtonLabel="Close"
    onSecondaryButton={props.onClose}
  >
    <ReassignWidget onClose={props.onClose} {...props} />
  </CrmModal>
)

ReassignModal.defaultProps = {
  onSaved: () => {}
}

ReassignModal.propTypes = {
  onClose: func.isRequired,
  isOpen: bool
}
ReassignModal.defaultProps = {
  isOpen: false
}

export default ReassignModal
