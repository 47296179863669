import React from 'react'
import { FormattedRelativeTime } from 'react-intl'
import { Link } from 'react-router-dom'

export const availableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: row => (
      <Link to={'/crm/account/' + row.original.account_uid} target="_blank">
        {row.original.name}
      </Link>
    )
  },
  {
    Header: 'Lead Campaign',
    accessor: 'campaign_name'
  },
  {
    Header: 'Owner',
    accessor: 'owner_user_name'
  },
  {
    Header: 'Lead Received At',
    accessor: 'lead_in_time',
    Cell: row => (
      <div>
        {row.original.lead_in_time ? (
          <FormattedRelativeTime value={row.original.lead_in_time} />
        ) : (
          ''
        )}
      </div>
    )
  },
  {
    Header: 'First Activity At',
    accessor: 'first_activity_date',
    Cell: row => (
      <div>
        {row.original.first_activity_date ? (
          <FormattedRelativeTime value={row.original.first_activity_date} />
        ) : (
          ''
        )}
      </div>
    )
  }
]
export const mock = 1
