import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { qlqFetchAccountAddresses } from 'crm-data/accounts'
import React from 'react'
import { shape, string, bool, func, oneOfType } from 'prop-types'
import gql from 'graphql-tag'
import { initAddressLocation } from 'crm-utils/accounts-utils'

const editAddressHoc = WrappedComponent => {
  class EditAddressHocInner extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        address: props.address ? { ...props.address } : undefined
      }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (nextProps.address !== this.props.address) {
        this.setState({
          address: nextProps.address ? { ...nextProps.address } : undefined
        })
      }
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })

    handleSaveAddress = async pAddress => {
      if (!pAddress) {
        throw new Error('No address passed')
      }
      if (!this.props.account_uid) {
        throw new Error('Address is missing account_uid')
      }
      if (this.props.hasPrimaryAddress === null && !pAddress.is_primary) {
        throw new Error('Please select at least 1 primary address.')
      } else if (!pAddress.is_primary) {
        throw new Error('Please select at least 1 primary address.')
      }

      delete pAddress.__typename
      delete pAddress.isEditing
      delete pAddress.isNew
      const command = {
        type: pAddress.address_uid ? 'address.update' : 'address.create',
        aggregate_uid: pAddress.address_uid
          ? pAddress.address_uid
          : this.props.newObjectUid,
        aggregate_type: 'address',
        payload: {
          ...pAddress
        }
      }
      try {
        // request #2 switching old primary address is_primary to false
        if (this.props.hasPrimaryAddress) {
          const oldAdress = {
            ...this.props.hasPrimaryAddress,
            is_primary: false
          }
          delete oldAdress.__typename
          const command2 = {
            type: 'address.update',
            aggregate_uid: oldAdress.address_uid,
            aggregate_type: 'address',
            payload: {
              ...oldAdress
            }
          }

          await this.props.onRunCommand(command2)
        }

        const refetchQueries = [
          {
            query: qlqFetchAccountAddresses,
            variables: {
              account_uid: this.props.account_uid
            }
          }
        ]
        let res = await this.props.onRunCommand(command, {
          refetchQueries
        })

        return res.data.cqCommand
      } catch (err) {
        throw err
      }
    }

    handleRemoveAddress = async pAddress => {
      const address = { ...pAddress, is_valid: false }
      delete address.__typename
      delete address.isEditing

      if (this.props.isSaving) {
        throw new Error('Address is saving')
      }
      if (!address) {
        throw new Error('No address passed')
      }
      if (!this.props.account_uid) {
        throw new Error('Address is missing account_uid')
      }

      const command = {
        type: 'address.update',
        aggregate_uid: address.address_uid,
        aggregate_type: 'address',
        payload: { ...address }
      }
      try {
        const refetchQueries = [
          {
            query: qlqFetchAccountAddresses,
            variables: {
              account_uid: this.props.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, { refetchQueries })
        return res.data.cqCommand
      } catch (err) {
        throw new Error('Remove Address error')
      }
    }

    handleAddressFieldChanged = (value, fieldName, index) => {
      const newData = { ...this.state.address }
      if (fieldName === 'location') {
        // geocode
        newData.location = value
      } else if (fieldName === 'address_kind') {
        // checkbox
        newData[fieldName] = value ? 'headquarter' : 'branch'
      } else if (fieldName === 'is_primary') {
        newData[fieldName] = value
      } else {
        // textbox
        newData[value.currentTarget.id] = value.currentTarget.value
        newData.location = initAddressLocation()
      }
      this.setState({ address: newData })
      if (this.props.updateOnAddressFieldChanged) {
        this.props.updateOnAddressFieldChanged(index, newData)
      }
    }
    createGeoSearchString = address => {
      let searchString = ''
      const searchKey = [
        'line1',
        'line2',
        'city',
        'postcode',
        'suburb',
        'town',
        'province'
      ]
      Object.keys(address).forEach(key => {
        if (searchKey.includes(key) && address?.[key] && address?.[key] !== '')
          searchString += `${address[key]} `
      })
      return searchString
    }

    findGeocode = async pAddress => {
      if (!pAddress) {
        throw new Error('No address passed')
      }
      try {
        const address = this.createGeoSearchString(pAddress)
        if (address === '') {
          throw new Error('No address passed')
        }
        const res = await this.props.client.query({
          query: gql(
            `query geocodeAddressString($address: String!) { geocodeAddressString(address: $address) { lat lng  } }`
          ),
          variables: {
            address
          },
          fetchPolicy: 'no-cache'
        })
        return res.data.geocodeAddressString
      } catch (err) {
        throw err
      }
    }

    switchAddressToReadMode = async () => {
      let address = { ...this.state.address, isNew: false, isEditing: false }
      this.setState({ address })
    }

    toggleEditMode = isEditing => {
      const address = { ...this.props.address, isEditing }
      this.setState({ address })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          address={this.state.address}
          onSaveAddress={this.handleSaveAddress}
          onRemoveAddress={this.handleRemoveAddress}
          switchAddressToReadMode={this.switchAddressToReadMode}
          toggleEditMode={this.toggleEditMode}
          onAddressFieldChanged={this.handleAddressFieldChanged}
          updateOnAddressFieldChanged={this.props.updateOnAddressFieldChanged}
          // crmGenericCommandHoc...
          isSaving={this.props.isSaving}
          // <AddressItem...
          account_uid={this.props.account_uid}
          onCancelAddAddress={this.props.onCancelAddAddress}
          removeAddressUI={this.props.removeAddressUI}
          updateAddressesUI={this.props.updateAddressesUI}
          findGeocode={this.findGeocode}
          // <AddressItemInlineEditable...
          valueAddress={this.props.valueAddress}
          fieldName={this.props.fieldName}
          id={this.props.id}
          label={this.props.label}
          editable={this.props.editable}
        />
      )
    }
  }
  EditAddressHocInner.propTypes = {
    valueAddress: shape({}),
    updateAddressesUI: oneOfType([shape({}), func]).isRequired,
    removeAddressUI: oneOfType([shape({}), func]).isRequired,
    address: shape({}).isRequired,
    account: shape({}),
    editable: bool,
    label: string,
    hasPrimaryAddress: bool,
    account_uid: string.isRequired,
    onRunCommand: func.isRequired,
    newObjectUid: string.isRequired,
    fieldName: string,
    onCancelAddAddress: func.isRequired,
    isSaving: bool.isRequired,
    id: string,
    client: shape({}).isRequired,
    updateOnAddressFieldChanged: func
  }

  EditAddressHocInner.defaultProps = {
    updateOnAddressFieldChanged: undefined
  }

  return crmGenericCommandHoc(EditAddressHocInner)
}

export const saveHandler = async (value, props, isManagerMode = false) => {
  if (!value || !props || !props.valueAddress) {
    return
  }
  const command = {
    type: 'address.update',
    aggregate_uid: props.valueAddress.address_uid,
    aggregate_type: 'address',
    payload: {
      [props.fieldName]: value.value,
      address_uid: props.valueAddress.address_uid
    }
  }
  try {
    const refetchQueries = [
      {
        query: qlqFetchAccountAddresses,
        variables: {
          account_uid: props.account_uid
        }
      }
    ]
    await props.onRunCommand(command, {
      refetchQueries
    })
  } catch (err) {
    throw err
  }
}

export default editAddressHoc
