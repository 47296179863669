import React from 'react'
import { connect } from 'react-redux'
import { shape, func, bool, object } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { FormattedDate, FormattedMessage } from 'react-intl'
import processWidgetQl from './process-widget-ql-hoc'
import opportnitiesApi from 'crm-api/opportunities-api'
import {
  CrmButton,
  CrmDayPickerInput,
  CrmInfoBlock,
  CrmDropdown
} from 'crm-components'
import { calculateCurrentStepType } from 'crm-utils/process-utils'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class ProcessWidget extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      processDetails: props.process
    }
  }

  async _loadProcessDetails() {
    this.props.onRefetch()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.process && nextProps.process !== this.props.process) {
      this.setState({ processDetails: nextProps.process })
    }
  }

  _findStepById(id) {
    return this.props.processType && this.props.processType.steps
      ? this.props.processType.steps.find(s => s.process_step_type_uid === id)
      : undefined
  }

  handleClickUpdateCompletedDate = () => {
    this.setState({ isUpdatingCompletedDate: true })
  }

  handleClickUpdateResult = () => {
    this.setState({ isUpdatingResult: true })
  }

  handleCancelChangeResult = () => {
    this.setState({ isUpdatingResult: false })
  }

  hasPermission = permissionCode => {
    return false
  }

  handleProcessStepClick = event => {
    const selectedStep = this._findStepById(event.currentTarget.id)

    if (
      selectedStep &&
      (selectedStep.permission_code === null ||
        this.hasPermission(selectedStep.permission_code))
    ) {
      if (
        this.props.editable ||
        this.props.process.current_step.process_step_type.kind === 'new'
      ) {
        if (this.props.onStepClick) {
          this.props.onStepClick(event)
          return
        }
        // eslint-disable-next-line
        if (selectedStep) {
          this.setState({ selectedStep })
        }
      }
    }
  }

  handleCompleteCancel = () => {
    this.setState({
      showCompleteProcess: false,
      showWinProcess: false,
      showLostProcess: false,
      finalStatus: undefined
    })
  }

  handleClickedWin = () => {
    this.setState({ showWinProcess: true })
  }

  handleClickedLost = () => {
    this.setState({ showLostProcess: true })
  }

  handleClickedComplete = () => {
    this.setState({ showCompleteProcess: true })
  }

  handleChangeStepCancel = () => {
    this.setState({ selectedStep: undefined })
  }

  handleChangeNewCompletedDate = value => {
    this.setState({ newCompletedDate: value })
  }

  handleChangeNewResult = newResult => {
    this.setState({ newResult })
  }

  handleCancelNewDate = () => {
    this.setState({ isUpdatingCompletedDate: false })
  }

  handleSaveNewCompletedDate = async () => {
    try {
      await this.props.onSaveCompletedDate(this.state.newCompletedDate)
      this.handleCancelNewDate()
    } catch (err) {
      toastr.error('Problem changing date')
    }
  }

  handleSaveNewResult = async () => {
    try {
      await this.props.onSaveNewResult(this.state.newResult)
      this.handleCancelChangeResult()
    } catch (err) {
      toastr.error('Problem changing date')
    }
  }

  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, resolve)
    })

  handleFinalStatusChange = value => {
    this.setState({ finalStatus: value })
  }

  handleProcessAction = async event => {
    const actionCode = event.currentTarget.id

    if (actionCode === 'sendToApproval') {
      if (this.props.validateQuotation) {
        const errorQuotation = await this.props.validateQuotation()
        if (errorQuotation) {
          toastr.error(errorQuotation)
          return
        }
      }
    }

    event.preventDefault()

    if (actionCode) {
      try {
        await this.setStateAsync({
          isSavingAction: true,
          savingActionCode: actionCode
        })
        await this.props.onDoProcessAction(actionCode)
      } catch (err) {
        toastr.error(err.message)
      }

      await this.setStateAsync({ isSavingAction: false })
    }
  }

  renderHint = ({ currentStepType }) => {
    if (
      currentStepType &&
      currentStepType.data &&
      currentStepType.data.userHint
    ) {
      return (
        <CrmInfoBlock hint>
          <div
            dangerouslySetInnerHTML={{ __html: currentStepType.data.userHint }}
          />
        </CrmInfoBlock>
      )
    }

    return <span />
  }

  hasPermissionsToAction = action => {
    if (this.props.accountPermissionsContext) {
      if (action.code === 'approve') {
        return this.props.accountPermissionsContext.hasPermissionByType(
          PERMISSIONS_TYPES.QUOTATION_MANAGE
        )
      } else {
        return this.props.accountPermissionsContext.hasPermissionByType(
          PERMISSIONS_TYPES.QUOTATION_MODIFY
        )
      }
    } else {
      return true
    }
  }

  renderProcessActions = ({ currentStepType }) => {
    const actions =
      currentStepType && currentStepType.data && currentStepType.data.actions
        ? currentStepType.data.actions
        : []

    return (
      <div className="util-paddingMd util-flexRow">
        {actions && (
          <React.Fragment>
            {actions.map(action => {
              return (
                <CrmButton
                  key={action.code}
                  label={action.name}
                  id={action.code}
                  disabled={
                    !this.hasPermissionsToAction(action) ||
                    this.state.isSavingAction
                  }
                  onClick={this.handleProcessAction}
                  type={action.default ? 'primary' : 'button'}
                  grouped
                  isSaving={this.state.isSavingAction}
                />
              )
            })}
          </React.Fragment>
        )}
      </div>
    )
  }

  renderPending({
    selectedStep,
    processType,
    processTypeStages,
    currentStage,
    currentStepTypeUid
  }) {
    const renderSteps = stage => {
      const steps = processType.steps
        ? processType.steps.filter(
            s => !s.data || !stage || s.data.stageCode === stage.code
          )
        : processType.steps || []

      const currentStepType =
        currentStepTypeUid && steps
          ? steps.find(s => s.process_step_type_uid === currentStepTypeUid)
          : undefined

      return (
        <div className="ProcessWidget-steps">
          {steps
            .filter(s => !s.is_final_step && s.is_active)
            .sort((a, b) => a.step - b.step)
            .map(step => (
              <div
                key={step.process_step_type_uid}
                id={step.process_step_type_uid}
                onClick={this.handleProcessStepClick}
                className={`ProcessWidget-step ProcessWidget-step-kind-${
                  step.kind
                } 
              ${
                currentStepTypeUid === step.process_step_type_uid
                  ? 'ProcessWidget-step--active'
                  : ''
              } 
                ${
                  currentStepType && currentStepType.step >= step.step
                    ? 'ProcessWidget-step--completed'
                    : ''
                } 
                 }`}
              >
                {step.name}
              </div>
            ))}
        </div>
      )
    }

    return renderSteps(undefined)
  }

  render() {
    let processType =
      this.state.processDetails && this.state.processDetails.process_type
        ? this.state.processDetails.process_type
        : undefined

    if (this.props.processType) {
      processType = this.props.processType
    }

    const { processDetails, selectedStep } = this.state
    const processTypeStages =
      processType && processType.data ? processType.data.stages : undefined
    const { currentStepType, currentStage } = calculateCurrentStepType(
      this.props.process,
      this.props.processType
    )
    const currentStepTypeUid = currentStepType
      ? currentStepType.process_step_type_uid
      : undefined

    // eslint-disable-next-line
    const finalStepOptions =
      processType && processType.steps
        ? processType.steps
            .filter(s => s.is_final_step)
            .sort((a, b) => a.step > b.step)
            .map(s => ({ name: s.name, value: s.process_step_type_uid }))
        : []

    const isProcessCompleted = currentStepType && currentStepType.is_final_step
    const resultsOptions = this.props.processType
      ? this.props.processType.steps.map(s => ({
          name: s.name,
          value: s.process_step_type_uid
        }))
      : []

    if (!processDetails) {
      return <div> Loading process</div>
    }

    return (
      <div
        className={`ProcessWidget ${
          this.props.showName ? '' : 'ProcessWidget--noName'
        }`}
      >
        {isProcessCompleted && (
          <div className="util-flexRow row">
            <div className="ActivityListItem-headerItem col-md-6">
              <div
                className={`ProcessWidget-step 
                ${
                  currentStepType && currentStepType.kind === 'lost'
                    ? 'ProcessWidget-step--kind-lost'
                    : ''
                }
                ${
                  currentStepType && currentStepType.kind === 'win'
                    ? 'ProcessWidget-step--kind-win'
                    : ''
                }`}
              >
                {currentStepType && <span>{currentStepType.name}</span>}
                {' at '}
                <FormattedDate
                  value={this.state.processDetails.completed_time}
                />
              </div>
            </div>

            <div className="ActivityListItem-headerItem col-md-4">
              <div>
                <FormattedMessage
                  id="crm.ui.oppournity.completed_date"
                  defaultMessage="Completed Date"
                />
              </div>
              <div className="ActivityListItem-headerItem-value">
                <div
                  title={
                    <FormattedMessage
                      id="crm.ui.oppournity.click_to_update_completed_date"
                      defaultMessage="Click to update Completed Date"
                    />
                  }
                >
                  {this.state.processDetails.completed_time ? (
                    <FormattedDate
                      value={this.state.processDetails.completed_time}
                    />
                  ) : (
                    'no completed_date!'
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!isProcessCompleted &&
          this.renderPending({
            currentStage,
            selectedStep,
            processType,
            processTypeStages,
            currentStepTypeUid
          })}
        {this.props.additionalWidget && (
          <div className="util-flexGrow">{this.props.additionalWidget()}</div>
        )}
        {this.props.renderActions &&
          this.renderProcessActions({ currentStepType })}
        {this.props.showHint && this.renderHint({ currentStepType })}
        {(this.state.showWinProcess || this.state.showLostProcess) && (
          <div className="row">
            <div className="col-md-12">
              <FormattedMessage
                id="crm.ui.oppournity.got_to_win_on_lose_it"
                defaultMessage="Got to opportunity form to Win on Lose it"
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

ProcessWidget.propTypes = {
  process: object,
  opportunity: object,
  quotation: object,
  onStepClick: func,
  showName: bool,
  editable: bool,
  additionalWidget: func,
  isSaving: bool.isRequired,
  isLoading: bool,
  processType: object,
  onSaveCompletedDate: func.isRequired,
  onMoveProcessStep: func.isRequired,
  onSaveNewResult: func.isRequired,
  onRefetch: func.isRequired,
  onDoProcessAction: func.isRequired,
  renderActions: bool,
  validateQuotation: func,
  accountPermissionsContext: object
}

ProcessWidget.defaultProps = {
  onStepClick: undefined,
  quotation: {},
  opportunity: undefined,
  showName: true,
  editable: true,
  additionalWidget: undefined,
  isLoading: false,
  processType: undefined,
  process: undefined,
  renderActions: false,
  validateQuotation: undefined,
  accountPermissionsContext: undefined
}

const mapStateToProps = state => ({})

export default processWidgetQl(
  withAccountPermissionsContextHOC(connect(mapStateToProps)(ProcessWidget))
)
