/* eslint-disable camelcase */
import React, { Component } from 'react'
import { shape, func } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { gqlCreateImportConfig } from '../crm-import-hoc'
import { CrmTextInput, CrmButton } from 'crm-components'

class ImportMappingConfigForm extends Component {
  constructor(props) {
    super(props)
    this.state = { name: '', errors: [] }
  }

  handleSave = async () => {
    if (this.state.name === '') {
      const errors = ['Name of config is required']
      this.setState({ errors })
    } else {
      const importConfig = this.props.data
      delete importConfig.resource_uid
      importConfig.name = this.state.name
      // console.log('handleSave ImportMappingConfigForm', importConfig)
      const variables = { importConfig }
      await this.props.qlCreateImportConfig({ variables })
      this.props.onClose()
      toastr.success('Save template success !')
    }
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  handleChange = event => {
    this.setState({ name: event.target.value })
  }

  render() {
    return (
      <div style={{ padding: 20 }}>
        <div className="error">
          {this.state.errors.map(err => (
            <p key={err}>{err}</p>
          ))}
        </div>
        <div>
          <CrmTextInput
            placeholder="Config name"
            input={{
              onChange: this.handleChange,
              value: this.state.name
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <CrmButton label="Save" type="primary" onClick={this.handleSave} />
          <CrmButton label="Cancel" type="button" onClick={this.handleCancel} />
        </div>
      </div>
    )
  }
}

ImportMappingConfigForm.propTypes = {
  data: shape({}).isRequired,
  onCancel: func.isRequired,
  onClose: func.isRequired,
  qlCreateImportConfig: func.isRequired
}

export default gqlCreateImportConfig(ImportMappingConfigForm)
