/* eslint-disable camelcase */
import { graphql, compose } from 'react-apollo'
import { qlqProcessDetails } from 'crm-data/processes'

const processPageHoc = compose(
  graphql(qlqProcessDetails, {
    options: ownProps => ({
      variables: { process_uid: ownProps.processUid }
    }),
    props: ({ processQl, ownProps }) => ({
      ...ownProps,
      processQl,
      process: processQl && processQl.process ? processQl.process : undefined,
      prospect:
        processQl && processQl.process ? processQl.process.prospect : undefined,
      isLoading: processQl ? processQl.loading : false
    }),
    name: 'processQl',
    skip: ownProps => !ownProps.processUid,
    fetchPolicy: 'no-cache'
  })
)
export default processPageHoc
