import React, { useState } from 'react'
import styles from './dashboard-filters.scss'
import { func, shape, bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { useAnalyticsContext } from 'crm-core/analytics/context'
import {
  useGeneralPermissionsContext,
  PERMISSIONS_TYPES
} from '@cartrack-crm/crm-core'
import { useSelector } from 'react-redux'
import { getUser } from 'crm-duxs/crm-reducer'

const FilterSectionClearAll = props => {
  const { hasPermissionByType } = useGeneralPermissionsContext()
  const { user_uid } = useSelector(getUser)
  const {
    clearAllFilters,
    clearFilterWithDefaultFilters
  } = useAnalyticsContext()

  const onClickClearFilter = () => {
    if (!hasPermissionByType(PERMISSIONS_TYPES.OPPORTUNITIES_MANAGE)) {
      clearFilterWithDefaultFilters({
        opportunity: {
          owner_user_uid: user_uid
        }
      })
    } else {
      clearAllFilters()
    }
  }
  return (
    <div
      className={styles.DashboardFilters__Section}
      style={{ ...props.style, minWidth: '100px' }}
      onClick={onClickClearFilter}
    >
      <div className="util-textCenter">
        <div className={styles.DashboardFilters__Dropdown__Value}>
          <FontAwesomeIcon icon={faFilter} />
        </div>
        <div>Clear Filters</div>
      </div>
    </div>
  )
}
FilterSectionClearAll.propTypes = {
  filterValues: shape(),
  onSetFilters: func,
  style: shape(),
  locked_filters: shape()
}
FilterSectionClearAll.defaultProps = {
  filterValues: undefined,
  style: {},
  locked_filters: {}
}

export default FilterSectionClearAll
