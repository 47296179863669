/* eslint-disable camelcase */
// Actions
export const SELECT_CAMPAIGN_COST = 'SELECT_CAMPAIGN_COST'
export const CLEAR_SELECT_CAMPAIGN_COST = 'CLEAR_SELECTCAMPAIGN_COST'
export const SHOW_CREATE_FORM_CAMPAIGN_COST = 'SHOW_CREATE_FORM_CAMPAIGN_COST'
export const CREATE_CAMPAIGN_COST = 'CREATE_CAMPAIGN_COST'
export const UPDATE_CAMPAIGN_COST = 'UPDATE_COST_CAMPAIGN'

// Reducer
const initialState = {
  campaignCostSelected: {
    uid: undefined,
    originalData: undefined,
    form_type: undefined
  }
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_CAMPAIGN_COST:
    case UPDATE_CAMPAIGN_COST:
      return { ...state }

    case SELECT_CAMPAIGN_COST:
      return {
        ...state,
        campaignCostSelected: {
          uid: action.payload.originalData.cost_uid,
          originalData: action.payload.originalData,
          formType: 'update'
        }
      }

    case CLEAR_SELECT_CAMPAIGN_COST:
      return {
        ...state,
        campaignCostSelected: { uid: undefined, formType: undefined }
      }

    case SHOW_CREATE_FORM_CAMPAIGN_COST:
      return {
        ...state,
        campaignCostSelected: { uid: undefined, formType: 'create' }
      }

    default:
      return state
  }
}

export function setSelectedCampaignCost(originalData) {
  return {
    type: SELECT_CAMPAIGN_COST,
    payload: {
      originalData
    }
  }
}

export function clearSelectedCampaign() {
  return {
    type: CLEAR_SELECT_CAMPAIGN_COST
  }
}

export function showCreateForm() {
  return {
    type: SHOW_CREATE_FORM_CAMPAIGN_COST
  }
}

export const getSelectedCampaignCost = state =>
  state.crm.campaignCost.campaignCostSelected
