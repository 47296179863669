/* eslint-disable camelcase */
import { graphql, compose } from 'react-apollo'
import React, { PureComponent } from 'react'
import { func, shape } from 'prop-types'
import { injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  qlmVerifyContact,
  qlqfetchAccountContactDetails,
  mapContactDetailsToServer
} from 'crm-data/accounts'

class PhoneNumberWidget extends PureComponent {
  handleClickCall = () => {
    console.log('handleClickCall')
  }

  handleMarkValidated = () => {
    this._doVerifyContact(true)
  }
  handleMarkInValidated = () => {
    this._doVerifyContact(false)
  }
  _getContactIcon(cd) {
    if (!cd) {
      return 'question'
    }
    if (cd.kind === 'phone' || cd.kind === 'mobile') {
      return 'phone'
    }
    if (cd.kind === 'email') {
      return 'envelope'
    }
    return 'phone'
  }

  _doVerifyContact = async verified => {
    const variables = {
      contact: mapContactDetailsToServer(this.props.contact),
      verified
    }
    console.dir(this.props)
    await this.props.qlmVerifyContact({ variables })
  }
  render() {
    const { contact } = this.props

    if (!contact) {
      return <span />
    }
    console.log('render contact', contact)
    let title = 'Not Validated'
    let validType = 'unknown'

    const validatedAt = contact.validated_time
      ? this.props.intl.formatDate(contact.validated_time) +
        ' ' +
        this.props.intl.formatTime(contact.validated_time)
      : ''
    if (
      contact.validated_time &&
      (contact.validated_result === 'valid' ||
        contact.validated_result === undefined)
    ) {
      title =
        'Validated at ' +
        validatedAt +
        (contact.validated_by_user
          ? ' by ' + contact.validated_by_user.full_name
          : '')
      validType = 'valid'
    }

    if (contact && contact.validated_result === 'wrong_number') {
      validType = 'invalid'
      title =
        'Marked as Wrong Number at ' +
        validatedAt +
        (contact.validated_by_user
          ? ' by ' + contact.validated_by_user.full_name
          : '')
    }
    console.log('validType', validType)
    const icon = this._getContactIcon(contact)
    return (
      <div
        title={title}
        className={`util-flexRow util-hooverable util-pointer util-textGray ${
          validType === 'valid' ? 'util-textOk' : ''
        }
          ${validType === 'invalid' ? 'util-textWarning' : ''}`}
      >
        {icon && (
          <FontAwesomeIcon
            className="util-marginLeft"
            icon={icon}
            onClick={this.handleClickCall}
          />
        )}
        <span className="util-marginRight util-flexGrow util-marginLeft">
          {contact.country_code}
          {contact.value}
        </span>
        {validType !== 'invalid' && (
          <FontAwesomeIcon
            title="Mark as NOT valid"
            className="util-marginLeft"
            icon="check-circle"
            onClick={this.handleMarkInValidated}
          />
        )}
        {validType !== 'valid' && (
          <FontAwesomeIcon
            title="Mark as Validated"
            className="util-marginLeft"
            icon="check-circle"
            onClick={this.handleMarkValidated}
          />
        )}
      </div>
    )
  }
}

PhoneNumberWidget.propTypes = {
  contact: shape({}).isRequired,
  qlmVerifyContact: func.isRequired,
  intl: shape({}).isRequired
}
PhoneNumberWidget.defaultProps = {}

const PhoneNumberWidgetQL = compose(
  graphql(qlmVerifyContact, {
    options: ownProps => ({
      refetchQueries: [
        {
          query: qlqfetchAccountContactDetails,
          variables: {
            account_uid: ownProps.contact
              ? ownProps.contact.account_uid
              : undefined
          }
        }
      ]
    }),
    name: 'qlmVerifyContact'
  })
)(PhoneNumberWidget)

export default injectIntl(PhoneNumberWidgetQL)
