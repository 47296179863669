import { CrmLabeledField } from 'crm-components'
import { string, bool, shape } from 'prop-types'
import generateInlineEditableField from 'crm-components/hoc/inline-editable-component-web-hoc'
import editOpportunityHoc, { saveHandler } from './edit-opportunity-hoc.js'
import React from 'react'

class OpportunityInlineEditable extends React.PureComponent {
  static propTypes = {
    label: string,
    value: string,
    displayValue: string,
    emptyValue: string,
    item: string,
    index: string,
    isLastRow: string,
    isNewOpp: string,
    readModeStyle: string.isRequired
  }
  renderInner_CrmLabeledField() {
    const { label, value, displayValue, emptyValue } = this.props
    return (
      <CrmLabeledField label={label} noMargin>
        {(!Array.isArray(value) && value) ||
        (Array.isArray(value) && value.length > 0) ? (
          <div className="">{displayValue || value}</div>
        ) : (
          <div className="LabeledField-label">{emptyValue}</div>
        )}
      </CrmLabeledField>
    )
  }

  renderInner_NoStyle() {
    const { value, displayValue, emptyValue } = this.props
    if (displayValue) {
      return <div className="LabeledField-value">{displayValue || value}</div>
    } else {
      return <div className="LabeledField-label">{emptyValue}</div>
    }
  }

  render() {
    const { readModeStyle } = this.props
    if (readModeStyle === 'CrmLabeledField') {
      return <div>{this.renderInner_CrmLabeledField()}</div>
    } else {
      return <div>{this.renderInner_NoStyle()}</div>
    }
  }
}

class OpportunityEditComponent extends React.PureComponent {
  static propTypes = {
    input: shape().isRequired,
    fieldName: string.isRequired,
    isSavingInlineEdit: bool.isRequired,
    editComponentWrapperStyles: shape().isRequired,
    editComponentProps: shape
  }
  static defaultProps = {
    editComponentProps: undefined
  }
  handleValueChange = (object, data1, data2) => {
    if (this.props.fieldName === 'opportunity_type_uid') {
      this.props.input.onChange(data2)
      return
    }
    const textFields = ['quantity', 'current_progress_update', 'action_plan_for_next_steps']
    const dropDownFields = ['confidence_level']
    const dateFields = ['due_date']

    if (this.props.fieldName === 'commission_reduction') {
      const value = parseFloat(object.currentTarget.value);
      if (isNaN(value) || value < 0) {
        this.props.input.onChange("0");
      } else if (value > 100) {
        this.props.input.onChange("100");
      } else {
        this.props.input.onChange(value.toString());
      }
    } else {
      if (textFields.includes(this.props.fieldName)) {
        this.props.input.onChange(object.currentTarget.value)
      } else if (dropDownFields.includes(this.props.fieldName)) {
        this.props.input.onChange(object)
      } else if (dateFields.includes(this.props.fieldName)) {
        this.props.input.onChange(object)
      } else {
        this.props.input.onChange(object)
      }
    }
  }
  render() {
    return (
      <div
        style={
          this.props.isSavingInlineEdit
            ? {}
            : this.props.editComponentWrapperStyles
        }
      >
        <this.props.editComponent
          {...this.props.editComponentProps}
          input={{
            value: this.props.input.value,
            onChange: this.handleValueChange
          }}
        />
      </div>
    )
  }
}

const inlineEditable = generateInlineEditableField(OpportunityInlineEditable, {
  editComponent: editOpportunityHoc(OpportunityEditComponent),
  saveHandler
})
export default inlineEditable
