import React from 'react'
import gql from 'graphql-tag'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  CrmLabeledField,
  CrmButton,
  CrmDayHourPickerInput,
  CrmDayPickerInput,
  CrmDropdown,
  CrmListbox,
  DateTimePicker
} from 'crm-components'
import DayPicker, { DateUtils } from 'react-day-picker'
import { DatePicker } from 'util-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getHourFormat, getDateFormat } from '../../../utils/time-utils'
import userMeetingListHoc from 'crm-modules/activities/hoc/user-meeting-list-hoc'

class CrmActivityDateTimeSelect extends React.PureComponent {
  constructor(props) {
    super(props)
    const listMeeting = props.listMeeting ? [...props.listMeeting] : []
    const availableHours = this._buildAvailableHours(listMeeting)
    const time = props.time ? props.time : undefined
    const { hourValue, dateValue, dateString } = this.getTimeValues(time)
    this.state = {
      time,
      hourValue,
      dateValue,
      dateString,
      userUid: props.userUid ? props.userUid : undefined,
      availableHours,
      listMeeting
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.time !== this.props.time) {
      const time = nextProps.time ? nextProps.time : undefined
      const { hourValue, dateValue, dateString } = this.getTimeValues(time)
      this.setState({
        time,
        hourValue,
        dateValue,
        dateString
      })
    }
    if (nextProps.userUid !== this.props.userUid) {
      this.setState({
        time: nextProps.userUid ? nextProps.userUid : undefined
      })
    }
    if (nextProps.listMeeting !== this.props.listMeeting) {
      const listMeeting = nextProps.listMeeting
        ? [...nextProps.listMeeting]
        : []
      const availableHours = this._buildAvailableHours(listMeeting)
      this.setState({
        listMeeting,
        availableHours
      })
    }
  }

  getTimeValues = time => {
    return {
      hourValue: time ? getHourFormat(time) : '',
      dateValue: time ? new Date(time) : new Date(),
      dateString: time ? getDateFormat(time) : ''
    }
  }

  _buildAvailableHours(listMeeting) {
    const meetings = listMeeting.map(v => ({
      ...v,
      start_time: getHourFormat(new Date(v.start_time))
    }))
    const ret = []
    const addLeadig0 = val => (val <= 9 ? '0' + val : val)
    const _pushHour = (h, m) => {
      const label = addLeadig0(h) + ':' + addLeadig0(m)
      const meeting = meetings.filter(v => v.start_time === label)[0]
      const performer =
        meeting && meeting.participants
          ? meeting.participants.filter(v => v.role_code === 'performer')[0]
          : null
      const hostName = performer
        ? performer.person
          ? performer.person.name
          : performer.user.full_name
        : ''
      const accountName = meeting && meeting.account ? meeting.account.name : ''
      const description = meeting ? `${hostName} ${accountName}` : ''

      ret.push({
        value: label,
        name: label,
        label,
        hour: h,
        minutes: m,
        element: <div>{label}</div>,
        disabled: meeting,
        description: description
      })
    }
    for (let i = 7; i < 22; i += 1) {
      if (i !== 21) {
        for (let minute = 0; minute < 60; minute += 15) {
          _pushHour(i, minute)
        }
      } else {
        _pushHour(i, 0)
      }
    }
    return ret
  }

  _recalculateValue(dateValue, hourValue) {
    if (dateValue && hourValue) {
      const hours = hourValue.split(':')
      const newStart = new Date(dateValue)
      newStart.setHours(hours[0], hours[1])
      const newStartTime = newStart.getTime()
      return newStartTime
    }
    return undefined
  }

  handleHourChange = (value, item) => {
    const time = this._recalculateValue(this.state.dateValue, value)
    const hourValue = value
    this.setState({ hourValue, time }, () => {
      if (time) {
        this.props.handleTimeChange(time)
      }
    })
  }

  handleDayChange = value => {
    const time = this._recalculateValue(value, this.state.hourValue)
    const dateValue = value
    this.setState({ dateValue, time }, () => {
      if (time) {
        this.props.handleTimeChange(time)
      }
      this.props.handleDayChange(value)
    })
  }

  render() {
    console.log('render crm-activity-datetime-select', this.props, this.state)
    return (
      <React.Fragment>
        <div className="row" style={{ height: 280 }}>
          <div className="col-md-5">
            <DayPicker
              numberOfMonths={1}
              onDayClick={this.handleDayChange}
              selectedDays={this.state.dateValue}
              // disabledDays={
              //   this.props.disabledDays || { after: this.state.today }
              // }
              // fromMonth={this.props.fromMonth || this.state.sixMonthsAgo}
              // toMonth={this.props.toMonth || this.state.today}
              // initialMonth={this.props.initialMonth || this.state.lastMonth}
              initialMonth={
                this.state.time ? new Date(this.state.time) : new Date()
              }
            />
          </div>
          <div className="col-md-7" style={{ height: '100%' }}>
            <CrmListbox
              name="start_time_hour"
              options={this.state.availableHours}
              style={{ height: '100%' }}
              input={{
                value: this.state.hourValue,
                onChange: this.handleHourChange
              }}
              isLoading={this.props.loadingListMeeting}
              bordered
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default userMeetingListHoc(CrmActivityDateTimeSelect)
