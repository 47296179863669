/* eslint-disable  */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { withApollo } from 'react-apollo'
import { convertToMatrix } from 'crm-utils/data-utils'
import moment from 'moment'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
import { aggregateBy } from '../telesales-dash-utils'
import { shape, func } from 'prop-types'
import { CrmButton, CrmLabeledField, CrmHeatmapChart } from 'crm-components'
import CrmWidget from '../../../components/crm-widget.jsx'
const COLORS = ['#65b7f3', '#b2d28c', '#f47735', '#f67960', '#82c6da']

class WidgetMovementsNewLeads extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleRefresh = async () => {
    console.log('dash telesales general stats . handle refresh', this.props)
    try {
      const filter = mapContextToFilter(this.props, false)
      if (this.props.analyticsContext.filters.activity_date) {
        filter.process_started_time = this.props.analyticsContext.filters.activity_date
      }
      const variables = {
        filter,
        fields: [
          'step__type__name',
          'step__type__step_type_uid',
          'step__type__kind',
          'step__type__step'
        ],
        rootType: 'DialerCampaignAccountsQM',
        aggregate: [{ id: 'process_uid', type: 'count' }]
      }

      variables.fields.push('process_started_date')
      if (this.props.mode === 'days') {
        // variables.fields.push('process_started_date')
      } else {
        variables.fields.push('process_started_hour')
      }
      const res = await this.props.client.query({
        query: qlqGenericDataQuery,
        variables,
        fetchPolicy: 'no-cache'
      })

      console.log('Got result', res)
      // parse data
      if (res.data.genericDataQuery) {
        const ldata = res.data.genericDataQuery.data.map(i => ({
          ...i,
          step__type__name:
            i.step__type__kind === 'lost' ? 'Lost' : i.step__type__name,
          hour: i.process_started_hour
            ? moment(i.process_started_hour).format('HH:mm')
            : undefined,
          process_started_date: i.process_started_date
            ? moment(i.process_started_date).format('YYYY-MM-DD')
            : undefined
        }))
        this.setState({
          data: ldata
        })
      }
    } catch (err) {
      toastr.error('Problem getting stats')
      console.error(err)
    }
  }

  handleCellClicked = cell => {
    console.log('handleCellClicked', cell)
    const filters = {
      ...this.props.analyticsContext.filters,
      step__type__name: cell.step__type__name
    }
    if (this.props.mode === 'days') {
      filters.process_started_date = cell.process_started_date
    } else {
      if (cell.hour) {
        filters.process_started_hour = cell.process_started_hour
      } else {
        filters.process_started_date = cell.process_started_date
      }
    }
    if (cell.step__type__kind === 'lost') {
      filters.step__type__kind = cell.step__type__kind
      delete filters.step__type__name
    }
    this.props.onAnalyticsRangeSelected({ type: 'lead', filters })
  }
  handleGetRawData = () => this.state.data
  handleGetLayoutData = () => {
    const aggregated = convertToMatrix(
      this.state.data,
      'current_step_name',
      this.props.mode === 'days' ? 'process_started_date' : 'hour',
      'process_uid',
      {
        xSortField: 'process_step_step',
        ySortField: 'process_started_date'
      }
    )
    console.log('aggregated convertToMatrix', aggregated)
    return aggregated
  }

  render() {
    console.log('Render widget new leads by current status', this.state)
    return (
      <CrmWidget
        title={
          this.props.title
            ? this.props.title
            : ' New Leads by current status - grouped by ' +
              (this.props.mode === 'days' ? 'days received' : 'hour received')
        }
        subtitle=" "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        onGetLayoutData={this.handleGetLayoutData}
        exportFileName="crm-new-leads-by-status"
      >
        {this.state.data &&
          (this.props.mode === 'days' ? (
            <CrmHeatmapChart
              xAxisField="step__type__name"
              yAxisField="process_started_date"
              valueField="process_uid"
              xSortType="number"
              xSortField="step__type__step"
              data={this.state.data}
              onCellClicked={this.handleCellClicked}
            />
          ) : (
            <CrmHeatmapChart
              xAxisField="step__type__name"
              yAxisField="hour"
              xSortField="step__type__step"
              xSortType="number"
              valueField="process_uid"
              data={this.state.data}
              onCellClicked={this.handleCellClicked}
            />
          ))}
      </CrmWidget>
    )
  }
}

WidgetMovementsNewLeads.propTypes = {
  analyticsContext: shape({}).isRequired,
  client: shape({}).isRequired,
  onCurrentStepClick: func.isRequired
}

export default withApollo(WidgetMovementsNewLeads)
