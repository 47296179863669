import React from 'react'
import { CrmButton } from '@cartrack-crm/ui'
import styled from 'styled-components'
import { useCrmDynamicTableContext } from '../dynamic-table-context'
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync'
import { PERMISSIONS_TYPES, useGeneralPermissionsContext, GeneralPermissionsContextType } from '@cartrack-crm/crm-core'

const Badges = styled.div`
  top: -7px;
  left: 20px;
  border-radius: var(--crm-border-radius-lg);
  padding: var(--crm-padding-02);
  background: var(--crm-color-blue40);
  color: var(--crm-color-white);
  overflow: hidden;
  color: var(--crm-color-white);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  position: absolute;
}
`
const StyledDataToolsBarWrapper = styled.div`
    padding: 5px 15px 5px 5px;
    justify-content: center;
    background: rgba(240, 240, 242, 0.85);
    border-left: solid 1px lightgrey;
}
`

export const DataToolsBar = () => {
  const dynamicTableContext = useCrmDynamicTableContext()
  const generalPermissionsContext: GeneralPermissionsContextType = useGeneralPermissionsContext()

  const handleRefresh = () => {
    if (dynamicTableContext && dynamicTableContext.refreshHandler && dynamicTableContext.refreshHandler.refresh) {
      dynamicTableContext.refreshHandler.refresh()
    }
  }

  const handleExportExcel = async () => {
    if (
      dynamicTableContext &&
      dynamicTableContext.excelExportHandler &&
      dynamicTableContext.excelExportHandler.exportExcel
    ) {
      await dynamicTableContext.excelExportHandler.exportExcel()
    }
  }

  const handleBulkOperation = async () => {
    if (
      dynamicTableContext &&
      dynamicTableContext.actionHandlers &&
      dynamicTableContext.actionHandlers.bulkOperations
    ) {
      await dynamicTableContext.actionHandlers.bulkOperations()
    }
  }

  return (
    <StyledDataToolsBarWrapper className={`util-flexRow DataToolsBar `}>
      <div className="util-relative">
        <CrmButton noBorder onClick={handleRefresh} icon={faSync} iconButton />
        {dynamicTableContext?.resultCount && <Badges>{dynamicTableContext?.resultCount}</Badges>}
      </div>
      {generalPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.EXPORT_DATA) && (
        <div className="util-marginLeft">
          <CrmButton noBorder onClick={handleExportExcel} icon="file-excel-o" iconButton />
        </div>
      )}
      {generalPermissionsContext.hasPermissionByType(PERMISSIONS_TYPES.BULK_OPERATIONS) && (
        <div>
          <CrmButton noBorder onClick={handleBulkOperation} icon="list" title="Bulk Operations" iconButton />
        </div>
      )}
    </StyledDataToolsBarWrapper>
  )
}
