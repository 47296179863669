export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'

// Reducer
const initialState = {
  type: '',
  message: ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TOAST:
      return { type: action.payload.type, message: action.payload.message }
    case REMOVE_TOAST:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function addToast(type, message, duration) {
  return {
    type: ADD_TOAST,
    payload: { type, message, duration }
  }
}

export function removeToast() {
  return {
    type: REMOVE_TOAST
  }
}

// Selectors
export const getType = state => state.toast.type
export const getMessage = state => state.toast.message
