/* eslint-disable camelcase */
import gql from 'graphql-tag'

export const leadsGenerationsQl = gql`
  query leads_generation_analysis_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    leads_generation_analysis_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      data {
        instance_uid
        account_uid
        account_name
        lead_source_uid
        lead_source_name
        lead_in_hour
        lead_in_date
        count
        cost_per_lead
      }
      count
    }
  }
`

export const dailyStatsQl = gql`
  query daily_stats_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    daily_stats_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      data {
        instance_uid
        lead_source_uid
        lead_source_name
        campaign_uid
        campaign_name
        value
        kpi_code
        kpi_name
        details
        stats_date
        stats_month
        lead_source_group_name
      }
      count
    }
  }
`

export const mock = 1
