/* eslint-disable */

import gql from 'graphql-tag'
import {
  qlDocument,
  qlDocumentOwnFields,
  qlDocumentItemOwnFields,
  qlDocumentTypeOwnFields
} from 'crm-api/documents-api'
import { qlProcessWithCurrentStep } from './processes-fragments'

export const qlqAccountDocuments = gql`
query documents($account_uid: String!) { 
  account(account_uid: $account_uid) { account_uid documents {${qlDocument} } }
}
`

export const qlGetDocument = gql`
query document($document_uid: String!) { 
  document(document_uid: $document_uid) { 
      ${qlDocumentOwnFields} 
      document_type { ${qlDocumentTypeOwnFields} } 
      items { ${qlDocumentItemOwnFields} product { name product_uid name description code kind is_addon } } 
      opportunity_uid
      process_uid
      process {
       ${qlProcessWithCurrentStep}
      }
  }
}
`

export const qlGetQuotationFull = gql`
query document($document_uid: String!) { 
  document(document_uid: $document_uid) { 
      ${qlDocumentOwnFields} 
      document_type { ${qlDocumentTypeOwnFields} } 
      items { ${qlDocumentItemOwnFields} product { name product_uid name description code kind is_addon } } 
      opportunity {
        opportunity_uid
      }
  }
}
`

export const fragmentDocumentFields = `
fragment DocumentFields on Document {
  ${qlDocumentOwnFields} 
  document_type { ${qlDocumentTypeOwnFields} } 
  items { ${qlDocumentItemOwnFields} product { name product_uid name description code kind is_addon } } 
}`

export const qlqListDocuments = gql`
query documents_list_qm_paged($filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
  ) {
    documents_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count
      data {
        ${qlDocumentOwnFields} 
      }
  }
}`
