import React, { FC } from 'react'
import { IconSelector } from '../icon-selector'

export const TEMPLATE_OPTIONS = [
  { name: 'Table', value: 'table' },
  { name: 'Horizontal Table', value: 'tableHorizontal', disabled: true },
  { name: 'Widget', value: 'widget', disabled: true },
  { name: 'Pie Chart', value: 'pie_chart', disabled: true }
]
interface ITemplateSelectorBar {
  onSelectTemplate?: Function
}
export const TemplateSelectorBar: FC<ITemplateSelectorBar> = (props) => {
  const onClickSelect = (value) => {
    if (props.onSelectTemplate) {
      props.onSelectTemplate(value)
    }
  }
  return (
    <div className='util-fullExpand' style={{ display: 'flex', overflowX: 'scroll' }}>
      {TEMPLATE_OPTIONS.map((item) => (
        <div style={{ width: '25%' }}>
          <IconSelector type={item.value} disabled={item.disabled} label={item.name} onClick={onClickSelect} />
        </div>
      ))}
    </div>)
}