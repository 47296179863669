/* eslint-disable camelcase */
import { accountsListReducerFactory } from '../accounts-list-reducer'

const views = [
  {
    name: 'All Opportunities',
    code: 'all'
  },
  {
    name: 'Overdue Opportunities',
    code: 'overdue',
    filter: {
      is_overdue: true,
      status_code: 'active'
    }
  },
  {
    name: 'Active Opportunities',
    code: 'pending',
    filter: {
      status_code: 'active'
    }
  },
  {
    name: 'Completed Opportunities',
    code: 'completed',
    filter: {
      status_code: { $ne: 'active' }
    }
  },
  {
    name: 'Won Opportunities',
    code: 'won',
    filter: {
      status_code: 'won'
    }
  },
  {
    name: 'Lost Opportunities',
    code: 'lost',
    filter: {
      status_code: 'lost'
    }
  }
]

const accountsLeadsReducer = accountsListReducerFactory('opportunities', {
  views
})

export default accountsLeadsReducer
