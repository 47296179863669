/* eslint-disable */
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { shape, bool, func, string } from 'prop-types'
import { reduxForm } from 'redux-form'
import ActivityForm from '../../../activity-form.jsx'
// import ParticipantForm from './participant-form.jsx'
import { ContactPersonInlineForm } from 'crm-modules/accounts/components/'

const participantsTableDefinition = {
  columns: [
    { accessor: 'name', Header: 'Name' },
    { accessor: 'company', Header: 'Company' },
    { accessor: 'phone', Header: 'Phone' },
    { accessor: 'email', Header: 'Email' },
    { accessor: 'position', Header: 'Position' }
  ]
}

// Cconst participantFormDefinition = {
//   component: props => <ParticipantForm {...props} />
// }

class SurveyBlockParticipants extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSave = data => {
    console.log('Survey Block handleSave')
    console.dir(data)
    this.props.handleSave({ section: 'participants', data })
  }

  onCreateNew = () => {
    return {
      account_person_uid: 'new',
      account_uid: this.props.accountUid,
      kind: 'individual',
      contact_details: [
        { kind: 'email' },
        { kind: 'mobile' },
        { kind: 'phone' }
      ]
    }
  }

  render() {
    console.log('survey block participant render')
    console.dir(this.props)
    const { activity } = this.props
    const PersonEdit = props => (
      <ContactPersonInlineForm {...props} accountUid={this.props.accountUid} />
    )
    const definition = {
      table: participantsTableDefinition,
      form: { component: PersonEdit }
    }
    return (
      <div>
        <h5>
          <FormattedMessage
            id="crm.ui.minute.form.confirm_meeting_details_and_participants"
            defaultMessage="Confirm Meeting Details and Participants"
          />
        </h5>

        {activity && (
          <ActivityForm
            accountUid={this.props.accountUid}
            activity={activity}
            activityUid={activity.activity_uid}
            keepOpen
            onCancel={this.props.onCancel}
          />
          // <MeetingFormParticipantsFields
          //   accountUid={this.props.activity.account_uid}
          //   activity={this.props.activity}
          //   isEditing
          //   form="surveyParticipants"
          // />
        )}
        {/* <TableWithEdit
          section={this.props.section}
          definition={definition}
          onSave={this.handleSave}
          data={this.props.sectionData}
          isEditable={this.props.isEditable}
          newElementFactory={this.onCreateNew}
          editComponent={PersonEdit}
        />*/}
      </div>
    )
  }
}

SurveyBlockParticipants.propTypes = {
  accountUid: string.isRequired,
  handleSave: func.isRequired,
  section: shape({}).isRequired,
  isEditable: bool.isRequired,
  sectionData: shape({}).isRequired,
  activity: shape().isRequired
}

export default reduxForm({ form: 'surveyParticipants' })(
  SurveyBlockParticipants
)
