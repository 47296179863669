import { QueryRootLevel, CrmQLQueryBuilder } from '../../data-utils/crm-ql-query-builder'

export const buildGQLQuery = (columnsSelection, defaultQueryFields, queryRoot) => {
  var builder = new CrmQLQueryBuilder()
  var builderParams = {
    root: queryRoot,
    root_type: queryRoot,
    root_level: QueryRootLevel.INSTANCE,
    use_edges: true,
    fields: columnsSelection.map(col => {
      if (col.data) {
        return col.data.field
      }

      return (col.path && col.path.length > 0 ? col.path.join('.') + '.' : '') + col.column.name
    })
  }

  var builderResult = builder.buildQlQuery(builderParams, {})

  return builderResult
}

const buildQueryTree = (columnsSelection, defaultQueryFields) => {
  const mergeLevels = (level1, level2) => {
    const resultSelections = [
      ...(level1 && level1.selections ? level1.selections : []),
      ...(level2 && level2.selections ? level2.selections : [])
    ]

    return {
      selections: resultSelections
    }
  }

  const mappedOptionalColumns = columnsSelection
    ? columnsSelection.reduce((a, col) => {
        const mapPathsToSelection = paths => {
          let res = []

          if (paths.length >= 1) {
            const selection: any = { name: paths[0] }
            const lpaths = [...paths]
            lpaths.splice(0, 1)
            selection.selections = mapPathsToSelection(lpaths)
            res = [selection]
          }

          if (paths.length === 0 && col.column) {
            if (col.column.queryFragment) {
              res = col.column.queryFragment.selections
            } else if (col.column.selections) {
              res = col.column.selections
            } else {
              res = [{ name: col.column.name }]
            }
          }

          return res
        }

        const mappedPath: Array<any> = col.path ? mapPathsToSelection([...col.path]) : []

        return [...a, ...mappedPath]
      }, [])
    : []

  const mergedTree = mergeLevels(defaultQueryFields, {
    selections: mappedOptionalColumns
  })

  return mergedTree
}

const generatePagedQLQueryString = (columnsSelection, defaultQueryFields, queryRoot) => {
  const queryTree = buildQueryTree(columnsSelection, defaultQueryFields)

  const buildQueryForLevel = level => {
    if (level && level.selections) {
      let levelString = ` { `
      const fields = level.selections.reduce((a, field) => {
        let res = a + ' ' + field.name + ' '
        if (field.selections) {
          res += buildQueryForLevel(field)
        }
        return res
      }, '')
      console.log('Level fieldS: ', fields)
      levelString += fields + ' } '
      return levelString
    }
    return ''
  }

  const fieldsString = buildQueryForLevel(queryTree)
  let queryString = `query ${queryRoot}($sort: [JSON], $offset: Int, $limit: Int, $filter: JSON,$aggregate: [JSON], $groupBy: [String]) {
        ${queryRoot} (limit: $limit, filter: $filter, sort: $sort, offset: $offset, aggregate: $aggregate, groupBy: $groupBy) {
           edges {
              edge ${fieldsString} 
            } 
            pageInfo {
              count
            }
            } 
        } `
  return queryString
}
