import { useCrmContext } from 'crm-core/contexts/crm-context'
import { HeadCount } from 'crm-types'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import gql from 'graphql-tag'

const HEAD_COUNTS = gql`
  query headcount_stats(
    $master_uid: String!
    $instance_uid: String!
    $date_from: String
    $date_to: String
    $organization_unit_uid: String
  ) {
    master(master_uid: $master_uid) {
      instance(instance_uid: $instance_uid) {
        headcount_stats(
          date_from: $date_from
          date_to: $date_to
          organization_unit_uid: $organization_unit_uid
        ) {
          month
          value
        }
      }
    }
  }
`

type useGetAnalyticContextHeadCount = (
  analyticsContext: any
) => [HeadCount[], boolean]
export const useGetAnalyticContextHeadCount: useGetAnalyticContextHeadCount = analyticsContext => {
  const context = useCrmContext()

  const organizationUnitUid = analyticsContext?.filters?.organization_unit?.organization_unit_uid?.value

  const dateTo = analyticsContext?.filters?.activity_date?.value?.$lte
  const dateFrom = moment(dateTo)
    .subtract(3, 'months')
    .format('YYYY-MM-DD')
  const { data, loading } = useQuery(HEAD_COUNTS, {
    variables: {
      date_from: dateFrom,
      date_to: dateTo,
      organization_unit_uid: organizationUnitUid,
      master_uid: context?.master?.master_uid,
      instance_uid: context.instance?.instance_uid
    },
    fetchPolicy: 'no-cache'
  })
  const headCounts = data?.master?.instance?.headcount_stats?.map(
    (item: HeadCount) => ({ month: item.month, value: item.value })
  )
  return [headCounts, loading]
}
