import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import crmGenericSelectQLHocFactory from 'crm-components/ql/crm-generic-select-ql-hoc.jsx'

const listActivityTypesQuery = gql`
  query activity_type_items_list_qm_paged(
    $filter: JSON
    $limit: Int
    $offset: Int
    $sort: [JSON]
    $fields: [String]
    $aggregate: [JSON]
  ) {
    activity_type_items_list_qm_paged(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
      fields: $fields
      aggregate: $aggregate
    ) {
      count
      data {
        activity_type_uid
        name
        medium_code
        description
      }
    }
  }
`

const query = graphql(listActivityTypesQuery, {
  options: ownProps => {
    const filterQuery = { limit: 200, offset: 0 }
    if (ownProps.limit) {
      filterQuery.limit = ownProps.limit
    }

    if (ownProps.offset) {
      filterQuery.offset = ownProps.offset
    }

    if (ownProps.medium_code) {
      // eslint-disable-next-line
      filterQuery.filter = { medium_code: ownProps.medium_code }
    }

    return {
      variables: filterQuery
    }
  },
  props: ({ listActivityTypesQuery, ownProps }) => ({
    ...ownProps,
    listActivityTypesQuery,
    prefix: 'activity_type_items_list_qm_paged',
    optionsQl: listActivityTypesQuery,
    options:
      listActivityTypesQuery &&
      listActivityTypesQuery.activity_type_items_list_qm_paged
        ? listActivityTypesQuery.activity_type_items_list_qm_paged.data
            .map(item => ({
              ...item,
              name: item.name,
              key: item.activity_type_uid,
              value: item.activity_type_uid
            }))
            .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
        : undefined,
    count:
      listActivityTypesQuery &&
      listActivityTypesQuery.activity_type_items_list_qm_paged
        ? listActivityTypesQuery.activity_type_items_list_qm_paged.count
        : undefined,
    isLoading: listActivityTypesQuery ? listActivityTypesQuery.loading : false
  }),
  name: 'listActivityTypesQuery'
})

const CrmActivityTypeSelect = crmGenericSelectQLHocFactory(compose(query))

CrmActivityTypeSelect.propTypes = {}

export default CrmActivityTypeSelect
