import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import renderers from './renderers/generic-widget-renderers'
import { withAnalyticsContextHoc } from '@cartrack-crm/crm-analytics/src/context/context'
import genericWidgetHoc from '../generic-widget-hoc'
import { DragDropContext } from 'react-beautiful-dnd'
import OptionButton from './buttons/options-button'
import { CrmButton } from '@cartrack-crm/ui'

const WidgetEditPanel = props => {
  return <div>WidgetEditPanel</div>
}

class SLFormItemWrapper extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleItemToolboxClick = type => () => {
    this.props.onItemToolboxClick(type, this.props.item)
  }

  handleClickEdit = () => {
    this.setState({ isEdited: !this.state.isEdited })
  }

  handleCancel = () => {
    this.setState({ isEdited: false })
  }

  handleSave = async () => {
    await this.props.onSaveWidgetDefinition()
    this.setState({ isEdited: false })
  }

  handleClickAddElement = elementType => {
    this.props.onAddElement({
      item: this.props.item,
      path: this.props.path,
      elementType
    })
  }

  handleClickLeft = this.handleItemToolboxClick('left')
  handleClickRight = this.handleItemToolboxClick('right')

  render() {
    let Renderer = renderers[this.props.item.type]

    if (Renderer) {
      if (typeof Renderer === 'object') {
        Renderer = this.state.isEdited ? Renderer.editComponent : Renderer.runtimeComponent
      }
    }

    return (
      <div
        key={this.props?.item?.code}
        className={`util-fullWidth util-fullHeight ${this.props.isEditingWidget ? 'CrmWidgetItem--editing' : ''}`}
      >
        {this.props.isEditingWidget && (
          <div className="CrmWidgetItem-editToolbox">
            <FontAwesomeIcon className="CrmWidgetItem-editToolbox-icon" icon="pencil-alt" />
            <FontAwesomeIcon className="CrmWidgetItem-editToolbox-icon" icon="chevron-left" />
            <FontAwesomeIcon className="CrmWidgetItem-editToolbox-icon" icon="chevron-right" />
            <FontAwesomeIcon className="CrmWidgetItem-editToolbox-icon" icon="table" title="Add Table" />
            <FontAwesomeIcon className="CrmWidgetItem-editToolbox-icon" icon="chart-line" title="Add Chart" />
          </div>
        )}
        {Renderer ? <Renderer {...this.props} /> : <span>Cant find renderer for type: {this.props.item.type}</span>}

        {this.state.isEdited && (
          <div>
            <CrmButton small grouped label="Save" onClick={this.handleSave} />
            <CrmButton small grouped label="Cancel" onClick={this.handleCancel} />
          </div>
        )}
      </div>
    )
  }
}

class GenericWidgetWebInner extends React.PureComponent<any, any> {
  renderLayoutItem = (item, path) => {
    const renderChildren = () =>
      item.items
        ? item.items.map((child, index) => {
            const lpath = [...path, index]
            return this.renderLayoutItem(child, lpath)
          })
        : []

    return (
      <SLFormItemWrapper
        key={JSON.stringify(item)}
        isEditingWidget={this.props.isEditingWidget}
        item={item}
        renderChildren={renderChildren}
        {...item.itemProps}
        rawData={this.props.data}
        onAnalyticsItemClicked={this.props.onAnalyticsItemClicked}
        onAddElement={this.props.onAddLayoutElement}
        path={path}
        allDataFields={this.props.allDataFields}
        onSaveWidgetDefinition={this.props.onSaveWidgetDefinition}
        onShowLeadsPopup={this.props.onShowLeadsPopup}
        definition={this.props.widgetDefinition}
      />
    )
  }

  componentDidMount() {
    this.props.onFetchData()
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.analyticsContext) !== JSON.stringify(this.props.analyticsContext)) {
      this.props.onFetchData()
    }
  }

  handleDragEnd = event => {
    this.props.onDndDrop(event)
  }

  render() {
    let layout = this.props.layout
    const { widgetDefinition } = this.props

    if (!layout && widgetDefinition && widgetDefinition.content) {
      layout = widgetDefinition.content.layout
    }

    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <div
          className={`CrmWidget--white CrmWidget  util-marginBottom 
      ${this.props.noHeight ? 'CrmWidget--noHeight' : ''}
      ${this.props.fullHeight ? 'CrmWidget--fullHeight' : ''}
        `}
          style={this.props.style ? this.props.style : {}}
        >
          <div className="row" style={{ height: '100%' }}>
            {this.props.isEditingWidget && (
              <div className="col-md-3">
                <WidgetEditPanel {...this.props} />
              </div>
            )}
            <div className={`util-fullHeight util-flexColumn ${this.props.isEditingWidget ? 'col-md-9' : 'col-md-12'}`}>
              <div className="CrmWidget-header row util-noMargin between-xs top-xs">
                <div className="util-flexColumn util-flexGrow">
                  <div className="Widget-title CrmWidget-title util-flexRow">
                    <div className="util-flexGrow util-flexRow">
                      {this.props.title ?? widgetDefinition?.title}
                      {this.props.isLoading && (
                        <div style={{ marginLeft: 5, marginRight: 5 }}>
                          <div>Loading..</div>
                        </div>
                      )}
                    </div>
                    {this.props.isEditingWidget && <span>Editing</span>}
                  </div>

                  {(widgetDefinition && widgetDefinition.description) ||
                    (this.props.description && (
                      <div className="util-flexRow">
                        <div className="CrmWidget-subtitle ">
                          {widgetDefinition && widgetDefinition.description
                            ? widgetDefinition.description
                            : this.props.description}
                        </div>
                      </div>
                    ))}
                </div>

                <div
                  style={{
                    minWidth: '100px',
                    width: '15%'
                  }}
                  className="CrmWidget-toolboxWrapper"
                >
                  <div className="CrmWidget-toolboxWrapper-tools  util-flexRow" style={{ justifyContent: 'flex-end' }}>
                    <CrmButton icon="refresh" onClick={this.props.onFetchData} grouped />
                    <OptionButton
                      showEditWidgetButton={this.props.showEditWidgetButton}
                      onEditWidget={this.props.onEditWidget}
                      onEnableEditMode={this.props.onEnableEditMode}
                      mergedContextToWidgetDefinition={this.props.mergedContextToWidgetDefinition}
                      dataSourcesVariables={this.props.dataSourcesVariables}
                    />
                  </div>
                </div>
              </div>

              <div className="CrmWidget-content" style={{ overflowY: 'scroll' }}>
                {layout &&
                  layout.items &&
                  layout.items.map((item, index) => {
                    const path = [index]
                    return this.renderLayoutItem(item, path)
                  })}
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
    )
  }
}

export const GenericWidgetWeb = withAnalyticsContextHoc(genericWidgetHoc(GenericWidgetWebInner))
export default GenericWidgetWeb
