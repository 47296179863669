import React, { createContext, useContext, useState } from 'react'
import moment from 'moment'
import useCrmCurrentUser from 'crm-core/auth/hooks/use-crm-current-user'

type CrmFilterContextType = {
  filters: any
  setFilters: any
}
export const CrmFilterContext = createContext<CrmFilterContextType>({
  filters: {},
  setFilters: () => {}
})

export const CrmFilterContextProvider = ({ children }) => {
  const now = moment()
  const currentUser = useCrmCurrentUser()
  const initState = {
    activity_date: {
      value: {
        $lte: now
          .endOf('month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        $gte: now
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD')
      }
    },
    user: {
      user_uid: {
        value: currentUser?.user_uid,
        locked: true
      }
    },
    organization_unit: undefined
  }

  const [filters, setFilters] = useState(initState)
  const updateFilters = (newFilterValues, merge = true) => {
    const merged = merge ? { ...filters, ...newFilterValues } : newFilterValues
    setFilters(merged)
  }

  const analyticContext = {
    filters,
    setFilters: updateFilters
  }

  return <CrmFilterContext.Provider value={analyticContext}>{children}</CrmFilterContext.Provider>
}

export const useCrmFilterContext = () => useContext(CrmFilterContext)
export const withCrmFilterContext = (Component: React.ComponentType) => {
  return props => {
    const crmFilterContext = useCrmFilterContext()
    return <Component {...props} crmFilterContext={crmFilterContext} />
  }
}
