import { connect } from 'react-redux'
import React from 'react'
import { withApollo } from 'react-apollo'
import moment from 'moment'
import { shape } from 'prop-types'
import { getUser } from 'crm-duxs/crm-reducer'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { compareDateFn } from 'crm-utils/common-utils'
import { AnalyticsContext } from '../../../../core/analytics/context.tsx'

const TOTAL_PAGES = 3

const calculateForecast = val => {
  const today = moment()
  const r = Number(moment().daysInMonth() / today.date())
  return Number((val * r).toFixed(0))
}

const widgetResultsHoc = WrappedComponent => {
  class Widget extends React.PureComponent {
    static propTypes = {
      currentUser: shape({}).isRequired,
      client: shape({}).isRequired,
      analyticsContext: shape({}).isRequired
    }
    constructor(props) {
      super(props)
      this.state = {
        currentPage: 1,
        transactionType: 'All'
      }
    }

    componentDidMount() {
      this.handleRefresh()
    }
    handleSwitchPage = order => {
      const nextPage = Math.max(
        1,
        Math.min(TOTAL_PAGES, this.state.currentPage + order)
      )
      this.setState({ currentPage: nextPage }, this.handleRefresh)
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    parseData = async data => {
      let firstCount = 0
      let followUpsCount = 0
      if (data.data) {
        data.data.forEach(row => {
          firstCount += !isNaN(Number(row.first)) ? Number(row.first) : 0
          followUpsCount += !isNaN(Number(row.follow_up))
            ? Number(row.follow_up)
            : 0
        })

        this.setState({ firstCount, followUpsCount })
      }
    }

    handleRefresh = async () => {
      if (
        this.state.currentPage === 1 ||
        this.state.currentPage === undefined
      ) {
        await this.handleRefresh1()
      } else if (this.state.currentPage === 2) {
        await this.handleRefresh2ByUser()
      } else if (this.state.currentPage === 3) {
        await this.handleRefresh3()
      }
    }

    handleToggleTransactionType = async () => {
      const trans = this.state.transactionType
      if (trans === 'All') {
        await this.setState({ transactionType: 'B2B' })
      }
      if (trans === 'B2B') {
        await this.setState({ transactionType: 'B2C' })
      }
      if (trans === 'B2C') {
        await this.setState({ transactionType: 'All' })
      }
      this.handleRefresh()
    }

    handleRefresh1 = async () => {
      const filter = {
        completed_month: {
          $gte: moment()
            .startOf('month')
            .add(-5, 'month')
            .startOf('month')
            .format('YYYY-MM-DD'),
          $lte: moment()
            .startOf('month')
            .format('YYYY-MM-DD')
        },
        status_code: 'completed'
      }
      if (this.state.transactionType !== 'All') {
        filter.account__transaction_type_code = this.state.transactionType
      }

      // Load fitments
      const variables = {
        fields: ['completed_month'],
        aggregate: [{ id: 'order_uid', type: 'count' }],
        rootType: 'OrdersListQM',
        filter
      }
      await this.setStateAsync({
        page1Data: {
          isLoading: true
        }
      })
      const res = await this.props.client.query({
        query: qlqGenericDataQuery,
        variables,
        fetchPolicy: 'no-cache'
      })
      const fitments = [...res.data.genericDataQuery.data]
      fitments.sort(f => compareDateFn('completed_month'))

      const fitmentsThisMonth = fitments.reduce((a, i) => {
        if (
          moment(i.completed_month).format('YYYY-MM-DD') ===
          moment()
            .startOf('month')
            .format('YYYY-MM-DD')
        ) {
          a += Number(i.order_uid)
        }
        return a
      }, 0)

      const forecastThisMonth = calculateForecast(fitmentsThisMonth)
      const fitmentsHistory = fitments.reduce(
        (a, i) => [
          ...a,
          {
            fitments:
              moment(i.completed_month).format('YYYY-MM-DD') ===
              moment()
                .startOf('month')
                .format('YYYY-MM-DD')
                ? calculateForecast(Number(i.order_uid))
                : Number(i.order_uid),
            date: moment(i.completed_month).format('YYYY-MM')
          }
        ],
        []
      )

      this.setState({
        page1Data: {
          fitmentsThisMonth: fitmentsThisMonth,
          forecastThisMonth,
          fitmentsHistory,
          isLoading: false
        }
      })
    }

    handleRefresh2ByUser = async () => {
      const filter = {
        completed_date: {
          $gte: moment()
            .startOf('day')
            .add(-7, 'day')
            .startOf('day')
            .format('YYYY-MM-DD'),
          $lte: moment()
            .startOf('day')
            .format('YYYY-MM-DD')
        },
        status_code: 'completed'
      }
      if (this.state.transactionType !== 'All') {
        filter.account__transaction_type_code = this.state.transactionType
      }

      // Load fitments
      const variables = {
        fields: ['completed_date'],
        aggregate: [{ id: 'order_uid', type: 'count' }],
        rootType: 'OrdersListQM',
        filter
      }

      await this.setStateAsync({
        page2Data: {
          isLoading: true
        }
      })
      const res = await this.props.client.query({
        query: qlqGenericDataQuery,
        variables,
        fetchPolicy: 'no-cache'
      })
      const fitments = [...res.data.genericDataQuery.data]
      fitments.sort(f => compareDateFn('completed_date'))
      const fitmentsToday = fitments.reduce((a, i) => {
        if (
          moment(i.completed_date).format('YYYY-MM-DD') ===
          moment()
            .startOf('day')
            .format('YYYY-MM-DD')
        ) {
          a += Number(i.order_uid)
        }
        return a
      }, 0)
      const fitmentsTotal = fitments.reduce(
        (a, i) => (a += Number(i.order_uid)),
        0
      )
      const fitmentsHistory = fitments.reduce(
        (a, i) => [
          ...a,
          {
            fitments:
              moment(i.completed_date).format('YYYY-MM-DD') ===
              moment()
                .startOf('month')
                .format('YYYY-MM-DD')
                ? calculateForecast(Number(i.order_uid))
                : Number(i.order_uid),
            date: moment(i.completed_date).format('MM-DD')
          }
        ],
        []
      )

      this.setState({
        page2Data: {
          fitmentsToday,
          fitmentsHistory,
          fitmentsTotal,
          isLoading: false
        }
      })
    }
    handleRefresh3 = async () => {
      const filter = {
        completed_month: {
          $gte: moment()
            .startOf('month')
            .add(-4, 'month')
            .startOf('month')
            .format('YYYY-MM-DD'),
          $lte: moment()
            .startOf('month')
            .format('YYYY-MM-DD')
        },
        status_code: 'completed'
      }
      if (this.state.transactionType !== 'All') {
        filter.account__transaction_type_code = this.state.transactionType
      }

      // Load fitments
      const variables = {
        fields: ['completed_month', 'owner__department__name'],
        aggregate: [{ id: 'order_uid', type: 'count' }],
        rootType: 'OrdersListQM',
        filter
      }
      await this.setStateAsync({
        page3Data: {
          isLoading: true
        }
      })
      const res = await this.props.client.query({
        query: qlqGenericDataQuery,
        variables,
        fetchPolicy: 'no-cache'
      })

      const fitments = [...res.data.genericDataQuery.data]
      fitments.sort(f => compareDateFn('completed_month'))
      // eslint-disable-next-line array-callback-return
      const fitmentsHistory = fitments.reduce((a, i) => {
        if (
          a &&
          a.length > 0 &&
          a.find(b => b.month === moment(i.completed_month).format('YYYY-MM'))
        ) {
          let find =
            a &&
            a.length > 0 &&
            a.find(b => b.month === moment(i.completed_month).format('YYYY-MM'))

          const keys = Object.keys(find)

          if (keys.find(k => k !== i.owner__department__name)) {
            find = { ...find, [i.owner__department__name]: Number(i.order_uid) }
            find.total = Number(find.total) + Number(i.order_uid)

            const newData = a.filter(
              f => f.month !== moment(i.completed_month).format('YYYY-MM')
            )
              ? a.filter(
                  f => f.month !== moment(i.completed_month).format('YYYY-MM')
                )
              : []
            newData.push(find)
            return newData
          }
        } else {
          return [
            ...a,
            {
              month: moment(i.completed_month).format('YYYY-MM'),
              total: Number(i.order_uid),
              [i.owner__department__name]: Number(i.order_uid)
            }
          ]
        }
      }, [])
      this.setState({
        page3Data: {
          fitmentsHistory,
          isLoading: false
        }
      })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          currentPage={this.state.currentPage}
          onRefresh={this.handleRefresh}
          onToggleTransactionType={this.handleToggleTransactionType}
          onSwitchPage={this.handleSwitchPage}
          transactionType={this.state.transactionType}
          fitmentsThisMonth={this.state.fitmentsThisMonth}
          fitmentsHistory={this.state.fitmentsHistory}
          isLoading={this.state.isLoading}
          page1Data={this.state.page1Data}
          page2Data={this.state.page2Data}
          page3Data={this.state.page3Data}
          analyticsContext={this.props.analyticsContext}
        />
      )
    }
  }

  function mapStateToProps(state) {
    return {
      currentUser: getUser(state)
    }
  }

  const WithContext = props => (
    <AnalyticsContext.Consumer>
      {analyticsContext => {
        return <Widget {...props} analyticsContext={analyticsContext} />
      }}
    </AnalyticsContext.Consumer>
  )
  return withApollo(connect(mapStateToProps)(WithContext))
}

export default widgetResultsHoc
