import React from 'react'
import { string, element, oneOfType } from 'prop-types'
import { connect } from 'react-redux'
import { Motion, spring, presets } from 'react-motion'

import { getType, getMessage } from 'crm-duxs/toast'

const Toast = props => {
  if (!props.type) return null
  return (
    <Motion
      defaultStyle={{ top: -60 }}
      style={{ top: spring(10, presets.gentle) }}
    >
      {interpolatedStyles => (
        <div
          className={`Toast Toast--${props.type}`}
          style={interpolatedStyles}
        >
          {props.message}
        </div>
      )}
    </Motion>
  )
}

Toast.propTypes = {
  type: string.isRequired,
  message: oneOfType([string, element]).isRequired
}

function mapStateToProps(state) {
  return {
    type: getType(state),
    message: getMessage(state)
  }
}

export default connect(mapStateToProps)(Toast)
