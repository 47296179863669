import React from 'react'
import { FormattedDate } from 'react-intl'

const numberCell = prop => (
  <span className="util-textRight util-fullWidth">{prop.value}</span>
)
export const columnsOpportunity = {
  type: 'Opportunity',
  fields: [
    {
      name: 'status_code',
      description: 'Status'
    },
    {
      name: 'due_date',
      description: 'Due Date',
      Cell: row => (
        <div>
          {row.original.due_date ? (
            <FormattedDate value={row.original.due_date} />
          ) : (
            '-'
          )}
        </div>
      )
    },
    {
      name: 'quantity',
      description: 'Size',
      column_label: 'Quantity'
    },
    {
      description: 'Forecast Close',
      name: 'due_date',
      Cell: row => (
        <div>
          {row.original.due_date ? (
            <FormattedDate value={row.original.due_date} />
          ) : (
            '-'
          )}
        </div>
      ),
      maxWidth: 180
    },
    {
      name: 'created_time',
      description: 'Created At',
      Cell: row => (
        <div>
          {row.original.created_time ? (
            <FormattedDate value={row.original.created_time} />
          ) : (
            '-'
          )}
        </div>
      )
    },
    {
      description: 'Confidence',
      name: 'confidence_level',
      maxWidth: 120,
      Cell: numberCell
    },
    {
      description: 'Owner',
      type: 'User',
      name: 'owner',
      column_label_prefix: 'Owner'
    },
    {
      description: 'Account',
      type: 'Account',
      name: 'account',
      column_label_prefix: 'Account'
    }
  ]
}
