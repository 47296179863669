/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { shape, func, string, arrayOf } from 'prop-types'
import CrmWidget from '../../../components/crm-widget.jsx'
import widgetFitmentsByLeadSourceTypeHoc from './widget-fitments-by-lead-source-type-hoc'
import { FlatTable } from 'crm-components'

class WidgetFitmentsByLeadSourceType extends React.PureComponent {
  constructor(props) {
    super(props)
    this.columns = [
      {
        accessor: 'lead_source_name',
        Header: 'Lead Source Type'
      },
      {
        accessor: 'quantity',
        Header: 'Units Fitted',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <span onClick={handleClick} className="util-textRight">
              {row.original.quantity}
            </span>
          )
        }
      },
      {
        accessor: 'hardware_price',
        Header: 'HW Sum',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.hardware_price
                ? row.original.hardware_price.toLocaleString(undefined, {
                    maximumFractionDigits: 0
                  })
                : ''}
            </div>
          )
        }
      },
      {
        accessor: 'subscription',
        Header: 'MRR Sum',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.subscription
                ? row.original.subscription.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                    useGrouping: true
                  })
                : ''}
            </div>
          )
        }
      },
      {
        accessor: 'avg_subscription',
        Header: 'MRR Avg',
        align: 'right',
        Cell: row => {
          const handleClick = () => this.handleCellClicked(row.original)
          return (
            <div onClick={handleClick} className="util-textRight">
              {row.original.avg_subscription
                ? row.original.avg_subscription.toLocaleString(undefined, {
                    maximumFractionDigits: 0
                  })
                : ''}
            </div>
          )
        }
      }
    ]
  }

  handleCellClicked = cell => {
    const filters = {
      ...this.props.analyticsContext.filters
    }

    if (cell.lead_source === null) {
      filters.lead_source_empty = 'empty';
    } else if (cell && cell.lead_source && cell.lead_source.name) {
      filters.lead_source_type_uid = cell.lead_source.lead_source_type_uid
    }

    this.props.onAnalyticsRangeSelected({ type: 'fitment', filters })
  }
  handleGetRawData = () => this.props.data

  handleRefresh = async () => {
    try {
      await this.props.onRefresh()
    } catch (err) {
      toastr.error('Problem getting stats')
      // console.error(err)
    }
  }
  render() {
    console.log('Render widget new leads by current status')
    return (
      <CrmWidget
        title={this.props.title ? this.props.title : 'Revenue by Lead Source'}
        subtitle=" "
        onRefresh={this.handleRefresh}
        analyticsContext={this.props.analyticsContext}
        renderSummary={this.renderSummary}
        padding
        autoRefresh
        onGetRawData={this.handleGetRawData}
        exportFileName="crm-new-leads-by-status"
      >
        {!this.props.analyticsContext.filters.activity_date && (
          <span>You have to select activity date range filter </span>
        )}

        {this.props.data && (
          <FlatTable
            columns={this.columns}
            data={this.props.data}
            onCellClicked={this.handleCellClicked}
          />
        )}
      </CrmWidget>
    )
  }
}

WidgetFitmentsByLeadSourceType.propTypes = {
  analyticsContext: shape({}).isRequired,
  data: arrayOf({}),
  onAnalyticsRangeSelected: func.isRequired,
  title: string,
  onRefresh: func.isRequired
}
WidgetFitmentsByLeadSourceType.defaultProps = {
  title: undefined,
  data: undefined
}

export default widgetFitmentsByLeadSourceTypeHoc(WidgetFitmentsByLeadSourceType)
