/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object, func, any, number } from 'prop-types'
import Dimensions from 'react-dimensions'
import { DragDropContext } from 'react-dnd' // eslint-disable-line
import HTML5Backend from 'react-dnd-html5-backend' // eslint-disable-line
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CrmNavbar from 'crm-modules/utils/navbar/crm-navbar'
import Navbar from 'crm-modules/app/navbar'
import Toast from 'crm-modules/app/toast'
import ConfirmationModal from 'crm-modules/app/confirmation-modal'
import LoadingBar from 'crm-modules/app/loading-bar'
import { withEld } from 'util-components/with-eld.jsx'
import { getUser, logout } from 'crm-duxs/user'
import { windowSizeChange } from 'crm-duxs/crm-reducer'
import { getLocale } from 'crm-duxs/locale'
import { getMobileOperatingSystem } from 'crm-utils/open-app'

const WIDTH = window.innerWidth

class App extends Component {
  constructor(props) {
    super(props)
    this.size = { height: 0, width: 0 }
    this.state = {
      isMobile: WIDTH < 600,
      isOpenApp: WIDTH < 600 ? true : false
    }
  }

  componentWillMount() {
    this._initWindowSize()
  }

  handleLogout = () => {
    this.props.doLogout('Manual Logout')
    this.props.history.push('/login')
    window.location.reload()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      window.scrollTo(0, 0)
    }
  }

  _initWindowSize = () => {
    if (this.size.width === 0 && this.size.height === 0) {
      this.size.width = this.props.containerWidth
      this.size.height = this.props.containerHeight
      this.props.windowSizeChange(this.size)
    }
  }

  get mobileLink() {
    const link = window.location.href
    let scheme = 'https://'
    const platform = getMobileOperatingSystem()
    if (platform === 'iOS') {
      scheme = 'saleslogicae'
    } else {
      scheme = 'saleslogicae'
    }
    const host = window.location.hostname
    let href = window.location.href.replace('https', scheme)
    if (window.location.search.includes('?redirectUri')) {
      const [_, path] = window.location.search.split('?redirectUri=')
      href = `${scheme}://${host}${path}`
    }
    return href
  }

  render() {
    const { isMobile, isOpenApp } = this.state

    return (
      <div className="App CrmApp">
        {isMobile && isOpenApp && (
          <div
            style={{
              backgroundColor: 'black',
              width: WIDTH,
              paddingLeft: '30px',
              paddingRight: '20px',
              paddingTop: '10px',
              paddingBottom: '10px',
              boxSizing: 'border-box'
            }}
          >
            <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="row" style={{ alignItems: 'center' }}>
                <div onClick={() => this.setState({ isOpenApp: false })}>
                  <FontAwesomeIcon icon="times" style={{ color: 'white' }} />
                </div>
                <img
                  src="../../../assets/icons/mobile-icon.png"
                  style={{ width: '30px', height: '30px', marginLeft: '20px' }}
                />
                <div>
                  <div style={{ color: 'white', marginLeft: 10 }}>MiCRM</div>
                  <div style={{ color: 'white', marginLeft: 10, fontSize: 10 }}>Open in the MiCRM app</div>
                </div>
              </div>
              <div>
                <a style={{ color: '#4A90E2' }} href={this.mobileLink}>
                  OPEN
                </a>
              </div>
            </div>
          </div>
        )}
        {this.props.user && (
          <CrmNavbar currentPath={this.props.location.pathname} onLogout={this.handleLogout} user={this.props.user} />
        )}
        <Toast />
        <LoadingBar />
      </div>
    )
    // eslint-disable-next-line
    return (
      <div className={`App `}>
        {this.props.user ? (
          <Navbar
            user={this.props.user}
            tabs={[
              {
                text: 'Map',
                icon: 'map',
                subTabs: [
                  {
                    path: '/map/vehicles',
                    text: 'Vehicles'
                  },
                  {
                    path: '/map/drivers',
                    text: 'Drivers'
                  },
                  withEld
                    ? {
                        path: '/documents',
                        text: 'Documents'
                      }
                    : null,

                  {
                    path: '/landmarks',
                    text: 'Landmarks'
                  },
                  {
                    path: '/geofences',
                    text: 'Geofences'
                  }
                ]
              },
              {
                text: 'List',
                icon: 'list-alt',
                subTabs: [
                  {
                    path: '/vehicles',
                    text: 'Vehicles'
                  },
                  {
                    path: '/landmarks',
                    text: 'Landmarks'
                  },
                  {
                    path: '/geofences',
                    text: 'Geofences'
                  },
                  {
                    path: '/drivers',
                    text: 'Drivers'
                  }
                ]
              },
              {
                text: '',
                icon: '',
                meta: true,
                subTabs: [
                  {
                    path: '/profile-settings',
                    text: 'Profile Settings'
                  },
                  {
                    path: '/manage-users',
                    text: 'Manage Users'
                  },
                  {
                    path: '/alert-settings',
                    text: 'Alert Settings'
                  }
                ]
              },
              {
                text: 'Dashboard',
                icon: 'tachometer',
                path: '/dashboard'
              },
              {
                text: 'Reports',
                icon: 'line-chart',
                path: '/reports'
              },
              {
                text: 'Setting',
                icon: 'map',
                subTabs: [
                  {
                    path: '/crm/settings/sales_channels',
                    text: 'Sales Channels'
                  },
                  {
                    path: '/crm/settings/users',
                    text: 'Users'
                  },
                  {
                    path: '/crm/settings/contracts',
                    text: 'Contracts'
                  },
                  {
                    path: '/crm/settings/contracts',
                    text: 'Contracts'
                  },
                  {
                    path: '/crm/settings/products',
                    text: 'Products'
                  }
                ]
              },
              {
                text: 'Crm',
                icon: 'map',
                subTabs: [
                  {
                    path: '/crm/dashboard',
                    text: 'Crm Dashboard'
                  },
                  {
                    path: '/crm/accounts',
                    text: 'Accounts'
                  }
                ]
              },
              {
                text: 'Calendar',
                icon: 'map',
                subTabs: [
                  {
                    path: '/crm/calendar/meetings',
                    text: 'Meeting'
                  },
                  {
                    path: '/crm/calendar/dealers',
                    text: 'Available Dealers'
                  }
                ]
              },
              {
                text: 'Calendar',
                icon: 'map',
                subTabs: [
                  {
                    path: '/crm/calendar/meetings',
                    text: 'Meeting'
                  },
                  {
                    path: '/crm/calendar/dealers',
                    text: 'Available Dealers'
                  }
                ]
              },
              {
                text: 'Calendar',
                icon: 'map',
                subTabs: [
                  {
                    path: '/crm/calendar/meetings',
                    text: 'Meeting'
                  },
                  {
                    path: '/crm/calendar/dealers',
                    text: 'Available Dealers'
                  }
                ]
              },
              {
                text: 'Calendar',
                icon: 'map',
                subTabs: [
                  {
                    path: '/crm/calendar/meetings',
                    text: 'Meeting'
                  },
                  {
                    path: '/crm/calendar/dealers',
                    text: 'Available Dealers'
                  }
                ]
              }
            ]}
            currentPath={this.props.location.pathname}
            onLogout={this.handleLogout}
          />
        ) : (
          ''
        )}
        <Toast />
        <ConfirmationModal />
        <LoadingBar />
      </div>
    )
  }
}

App.propTypes = {
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  location: object.isRequired, // eslint-disable-line react/forbid-prop-types
  user: any, // eslint-disable-line
  doLogout: func.isRequired,
  containerWidth: number,
  containerHeight: number,
  windowSizeChange: func.isRequired
}

App.defaultProps = {
  user: null,
  containerWidth: 0,
  containerHeight: 0
}

function mapStateToProps(state) {
  return {
    user: getUser(state),
    locale: getLocale(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout: reason => dispatch(logout(reason)),
    windowSizeChange: size => dispatch(windowSizeChange(size)),
    receiveTranslationData: data => dispatch(receiveTranslationMessages(data))
  }
}

// eslint-disable-next-line
const AppWithDimension = Dimensions()(App)

export default connect(mapStateToProps, mapDispatchToProps)(AppWithDimension)
