import React from 'react'
import { GenericAnalyticsWidget } from '@cartrack-crm/crm-analytics'
import { useCrmComponent } from 'crm-utils/crm-component-util'
import { AnalyticsContextType } from '@cartrack-crm/crm-analytics/src/context/context-types'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'
interface IWidgetFitmentByOwner {
  analyticsContext?: AnalyticsContextType
}
export const WidgetFitmentByLeadSourceName: React.FC<IWidgetFitmentByOwner> = (
  props
) => {
  const { getComponentByFilter } = useCrmComponent(props)
  const { components, loading } = getComponentByFilter({
    code: 'fitmentsGroupByLeadSourceName'
  })
  const widgetDefinition = components.pop()
  const filters = mapContextToFilter(props, true)

  return (
    !loading && (
      <GenericAnalyticsWidget
        key={widgetDefinition?.component_uid}
        title={widgetDefinition?.name}
        widgetDefinition={widgetDefinition}
        filters={filters}
      />
    )
  )
}
