import gql from 'graphql-tag'
import React, { PureComponent } from 'react'
import { Query } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import { CrmButton } from 'crm-components'
import { arrayOf, bool, func, string, shape } from 'prop-types'
import moment from 'moment'
import cloneDeep from 'clone-deep'

const qlqDocuments = gql`
  query documents($account_uid: String) {
    accounts(account_uid: $account_uid) {
      documents {
        document_uid
        name
        created_time
        resource {
          resource_uid
          name
          is_deleted
        }
      }
    }
  }
`
class CrmDocumentsSelector extends PureComponent {
  constructor(props) {
    super(props)
    const newState = {
      documents: cloneDeep(props.documents),
      sendEmailFromQuotation: cloneDeep(props.sendEmailFromQuotation)
    }
    this.state = {
      ...newState
    }
  }

  componentDidMount() {
    this.setDefaultAttachmentFile()
  }

  setDefaultAttachmentFile = () => {
    const { documents, sendEmailFromQuotation } = this.state

    if (sendEmailFromQuotation) {
      if (sendEmailFromQuotation.resource) {
        const defaultAttachFile = sendEmailFromQuotation.resource
        if (documents.length > 0) {
          const latestDocs = cloneDeep(documents)
          latestDocs.sort((a, b) =>
            moment(b.created_time).diff(moment(a.created_time))
          )
          const findSelectedQuotation =
            defaultAttachFile &&
            latestDocs.filter(
              v =>
                v.resource &&
                v.resource.resource_uid === defaultAttachFile.resource_uid
            )
          if (findSelectedQuotation && findSelectedQuotation.length > 0) {
            this.props.handleSetDefaultAttachFile(findSelectedQuotation[0])
          }
        }
      }
    }
  }

  handleShowToast = () => {
    toastr.error('You have to print PDF first')
  }

  handleCloseSection = () => {
    this.props.onCloseSection()
  }

  handleOnSelectAttachment = async doc => {
    this.props.onChange(doc)
  }

  render() {
    console.log('render CrmDocumentsSelector', this.props)
    const { documents } = this.props
    return (
      <div>
        {this.props.isExpanded && (
          <React.Fragment>
            <div
              style={{
                display: 'grid',
                gridGap: '5px',
                gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))'
              }}
            >
              {documents &&
                documents.length > 0 &&
                documents.map((doc, index) => {
                  if (doc.resource) {
                    return (
                      <div
                        style={{
                          background: 'white',
                          border: '1px solid #ccc',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          justifyContent: 'center',
                          lineHeight: '20px',
                          opacity: 0.5,
                          textAlign: 'center',
                          padding: '10px'
                        }}
                        onClick={() => this.handleOnSelectAttachment(doc)}
                      >
                        <div
                          className="row"
                          style={{ justifyContent: 'space-around' }}
                        >
                          <div>
                            <div>{doc.name}</div>
                            <small>{doc.resource.name}</small>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div
                      key={doc.documents_uid}
                      onClick={this.handleShowToast}
                      style={{
                        background: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        lineHeight: '20px',
                        opacity: 0.5,
                        textAlign: 'center',
                        padding: '10px'
                      }}
                    >
                      <div>{doc.name}</div>
                      <small>You need to Generate PDF first</small>
                    </div>
                  )
                })}
              <div className={' util-marginBottom'}>
                <div className="util-flexRowRight">
                  <CrmButton
                    grouped
                    label={
                      <FormattedMessage
                        id="meeting.section.close"
                        defaultMessage="Close"
                      />
                    }
                    id="section_guests_close"
                    small
                    onClick={this.handleCloseSection}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

CrmDocumentsSelector.propTypes = {
  accountUid: string.isRequired,
  documents: arrayOf({}),
  onCloseSection: func.isRequired,
  onChange: func.isRequired,
  isExpanded: bool.isRequired,
  sendEmailFromQuotation: shape({}),
  handleSetDefaultAttachFile: func
}

CrmDocumentsSelector.defaultProps = {
  documents: [],
  sendEmailFromQuotation: undefined,
  handleSetDefaultAttachFile: undefined
}

const CrmQuotationSelectorQL = props => {
  const variables = {
    account_uid: props.accountUid
  }
  return (
    <Query query={qlqDocuments} variables={variables}>
      {({ loading, error, data, refetch }) => {
        console.log('render CrmQuotationSelectorQL', loading, data, error)
        const documents =
          data && data.accounts && data.accounts.length > 0
            ? data.accounts[0].documents
            : []
        return (
          <CrmDocumentsSelector
            {...props}
            loading={loading}
            error={error}
            refetch={refetch}
            documents={documents}
          />
        )
      }}
    </Query>
  )
}

CrmQuotationSelectorQL.propTypes = {
  accountUid: string.isRequired
}

export default CrmQuotationSelectorQL
