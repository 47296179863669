// eslint-disable-next-line
export function findValueFromFilter(
  fixFilters,
  currentFilterValues,
  filterName,
  keyName
) {
  let returnValue = ''
  let isDisabled = false

  if (Object.keys(fixFilters).length > 0) {
    const filter = fixFilters.find(filter => filter.code === filterName)

    if (
      typeof filter !== 'undefined' &&
      Object.keys(filter.params).length > 0
    ) {
      const filterValue = filter.params.find(
        filter => filter.condition_key === keyName
      )

      if (filterValue) {
        returnValue = filterValue.condition_value
        isDisabled = true
      }
    }
  }

  if (!isDisabled) {
    if (Object.keys(currentFilterValues).length > 0) {
      const filter = currentFilterValues.find(
        filter => filter.code === filterName
      )

      if (
        typeof filter !== 'undefined' &&
        Object.keys(filter.params).length > 0
      ) {
        const filterValue = filter.params.find(
          filter => filter.condition_key === keyName
        )

        if (filterValue) {
          returnValue = filterValue.condition_value
        }
      }
    }
  }

  return { value: returnValue, isDisabled }
}

export function debounce(func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
