import styled from 'styled-components'
import { CrmTranslateText } from '@cartrack-crm/ui'
import { NotationContainer } from './activity-notation'

export const MetaRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`
export const MetaItem = styled.div`
  color: #5099ce;
  font-size: 13px;
  font-weight: 500;
  svg {
    color: #999999;
    font-size: 12px;
    line-height: 13px;
    margin-right: 5px;
  }

  margin-right: 16px;
`

export const ActivitySubject = styled(CrmTranslateText)`
  color: #606060;
  font-size: 13px !important;
  font-weight: 500;
  * {
    font-size: 13px !important;
  }
`

export const ActivityTextContent = styled.div`
  color: #333;
  font-size: 13px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  flex: 15;
  white-space: pre-wrap;

  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ActivityItemContiner = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 10px;
  }
  padding-bottom: 8px;
  position: relative;
  // overflow: hidden;

  :hover ${NotationContainer} {
    border: 1px solid rgba(75, 145, 195, 0.5);
  }
`
