import React from 'react'
import { string } from 'prop-types'
import { Query } from 'react-apollo'
import GenericDropdown from '../../generic-dropdown.jsx'
import { taskQueuesQL } from 'crm-data/task-queue'

const CrmTaskQueueSelect = props => {
  const variables = {}
  const qlq = taskQueuesQL

  return (
    <Query query={qlq} variables={variables}>
      {({ loading, error, data, refetch }) => {
        const options =
          data &&
          data.taskQueues &&
          data.taskQueues.edges &&
          data.taskQueues.edges.length > 0
            ? data.taskQueues.edges
                .filter(v => v.edge && v.edge.is_deleted !== true)
                .map(v => ({
                  ...v.edge,
                  value: v.edge.task_queue_uid
                }))
            : []
        return (
          <GenericDropdown
            {...props}
            options={options}
            placeholder={
              props.placeholder ? props.placeholder : 'Select Task Queue'
            }
          />
        )
      }}
    </Query>
  )
}

CrmTaskQueueSelect.propTypes = {
  placeholder: string
}

export default CrmTaskQueueSelect
