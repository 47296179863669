import React, { createContext, useContext, useState } from 'react'
import { node, shape } from 'prop-types'
import { hasPermission, hasPermissionByPermissionType } from './permission-utils'
import { GeneralPermissionsContextType } from './permissions-context-types'

export const GeneralPermissionsContext = createContext<GeneralPermissionsContextType>({
  hasPermissionTo: () => {},
  hasPermissionByType: () => {}
})

export const GeneralPermissionsContextProvider = ({ children, my_permission }) => {
  const hasPermissionTo = (permissionCode, accessMode, module) =>
    hasPermission(my_permission, permissionCode, accessMode, module)

  const hasPermissionByType = (permission_type, match_modules) =>
    hasPermissionByPermissionType(my_permission, permission_type, match_modules)

  const generalPermissionsContext = {
    my_permission,
    hasPermissionTo,
    hasPermissionByType
  }

  return (
    <GeneralPermissionsContext.Provider value={generalPermissionsContext}>
      {children}
    </GeneralPermissionsContext.Provider>
  )
}

GeneralPermissionsContextProvider.propTypes = {
  children: node.isRequired
}

export const useGeneralPermissionsContext = () => useContext(GeneralPermissionsContext)

export const withGeneralPermissionsContextHOC = (Component: any) => {
  return (props: any) => {
    return <Component generalPermissionsContext={useGeneralPermissionsContext()} {...props} />
  }
}
