import React from 'react'
import widgetCustomersWinRatioHoc, { widgetWinRatioHocProps } from './widget-customer-win-ratio-hoc'
import { DashBoardWidget } from '../dashboard-widget'
import { AnalyticDashboardWidget } from 'crm-types'

export const WidgetCustomersWinRatioAgent: React.ComponentType<AnalyticDashboardWidget> = widgetCustomersWinRatioHoc(
  (props: widgetWinRatioHocProps) => (
    <DashBoardWidget
      bigContent={props.bigContent}
      footerRender={props.footerRenderAgent}
      hideAverage
      isLoading={props.isLoading}
      onClickDetail={props.onClickDetail}
      refetch={props.refetchData}
      subtitle="Last 6 Months"
      title="Customers Win Ratio (Agent)"
      totalNumber={props.totalPercentWinRatioAgent}
    />
  )
)
