import React from 'react'
import { object, func, arrayOf, bool, number, array } from 'prop-types'
import rmSummaryDashboardHoc from '../../hoc/ct-dashboards/rm-details/rm-summary-dashboard-hoc'
import {
  CrmButton,
  CrmInfoBlock,
  FlatTable,
  CrmLabeledField
} from 'crm-components'
import { useQuery } from '@apollo/react-hooks'
import { qlqGetUser } from 'crm-data/users'
import RmLeadResults from './rm-lead-results.jsx'
import RmMeetingActivity from './rm-meeting-activity.jsx'
import rmMeetingsPerformanceWidget from '../../hoc/ct-dashboards/rm-details/rm-meetings-performance-widget'
import userOpportunitiesWidget from 'crm-modules/dashboard/hoc/ct-dashboards/rm-details/user-opportunities-widget'
import { GenericWidgetWeb } from '@cartrack-crm/crm-analytics'
import AvatarPreview from 'crm-components/utils/user-avatar.jsx'
import { LoadingIcon } from 'util-components'

export const UserInfoCard = props => {
  const variables = props.user ? { user_uid: props.user.user_uid } : {}
  const { data, loading } = useQuery(qlqGetUser, {
    variables,
    skip: !props.user,
    fetchPolicy: 'no-cache'
  })
  const checkCompleteUserData = () =>
    props.user && 'full_name' in props.user && 'department' in props.user
  let user = props.user

  if (!checkCompleteUserData() && data?.user) {
    user = { ...data.user }
  }

  return (
    <div className="CrmMdCard CrmMdCard--paddingMd util-flexGrow">
      <div className="util-paddingMd">
        <div className="util-textCenter">
          {(props.isLoading || loading) && <LoadingIcon />}
          {!(props.isLoading || loading) && user && user.photo_url && (
            <AvatarPreview
              src={user.photo_url}
              style={{ width: 84, height: 84, borderRadius: 84 }}
            />
          )}
          {!(props.isLoading || loading) && user && !user.photo_url && (
            <i className="fa fa-user util-textXL"></i>
          )}
        </div>
        {user && (
          <div className="util-textCenter">
            {user.full_name && <h4>{user.full_name}</h4>}
            {user.contract &&
              user.contract.profile &&
              user.contract.profile && (
                <small style={{ display: 'block' }}>
                  {user.contract.profile.name}
                </small>
              )}
            {user.department && <small>{user.department.name}</small>}
            {user.contract_start_date && (
              <small>
                {user.department && user.department.contract_start_date}
              </small>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

UserInfoCard.propTypes = {
  user: object,
  isLoading: bool
}

UserInfoCard.defaultProps = {
  isLoading: false
}

const RmDashboard = props => {
  function renderStatisticsForPeriod() {
    return (
      <div className="CrmMdCard util-flexGrow" style={{ padding: 16 }}>
        <div className="CrmMdCard-title">Statistics for period</div>
        <div className="CrmMdCard-content">
          <div className="row">
            <div className="col-md-3">
              <CrmLabeledField label="Captured Sales">
                {(props.pendingInPeriod || 0) + (props.fitmentsInPeriod || 0)}
              </CrmLabeledField>
            </div>
            <div className="col-md-3">
              <CrmLabeledField label="Pending Installations">
                {props.pendingInPeriod}
              </CrmLabeledField>
            </div>
            <div className="col-md-3">
              <CrmLabeledField label="Installed">
                {props.fitmentsInPeriod}
              </CrmLabeledField>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function render12MonthHistorical() {
    return (
      <div className="CrmWidget CrmWidget--white util-flexGrow util-marginTop">
        <div className="CrmWidget-header">
          <div className="CrmWidget-title util-marginBottom">
            12-month historical actuals
          </div>
        </div>
        <div className="CrmWidget-content">
          {props.fitmentsHistoryRows && props.fitmentsHistoryColumns && (
            <FlatTable
              columns={props.fitmentsHistoryColumns}
              data={props.fitmentsHistoryRows}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      {props.isUserRequiredError && (
        <CrmInfoBlock warning>
          Select user from filters on top to run this dashboard
        </CrmInfoBlock>
      )}
      {!props.isUserRequiredError && (
        <div style={{ marginBottom: 10 }}>
          <CrmButton
            label="Refresh"
            onClick={props.onRefresh}
            type="primary"
            isLoading={props.isLoading}
          />
        </div>
      )}
      {!props.isUserRequiredError && (
        <React.Fragment>
          <div className="util-flexRow  util-marginBottom">
            <div
              style={{ width: '20%', paddingRight: 10 }}
              className="util-flexColumn"
            >
              <UserInfoCard {...props} />
            </div>
            <div className="util-flexGrow util-flexColumn" style={{}}>
              {renderStatisticsForPeriod()}
              {render12MonthHistorical()}
            </div>
          </div>
          <RmLeadResults
            analyticsContext={props.analyticsContext}
            onShowLeadsPopup={props.onShowLeadsPopup}
          />
          <RmMeetingActivity
            analyticsContext={props.analyticsContext}
            onShowLeadsPopup={props.onShowLeadsPopup}
          />
          <GenericWidgetWeb
            widgetDefinition={rmMeetingsPerformanceWidget}
            key={rmMeetingsPerformanceWidget.code}
            analyticsContext={props.analyticsContext}
            noHeight
            noFilterBar
            onShowLeadsPopup={props.onShowLeadsPopup}
          />
          <GenericWidgetWeb
            widgetDefinition={userOpportunitiesWidget}
            key={userOpportunitiesWidget.code}
            analyticsContext={props.analyticsContext}
            noHeight
            noFilterBar
            onShowLeadsPopup={props.onShowLeadsPopup}
          />
        </React.Fragment>
      )}
    </div>
  )
}

RmDashboard.propTypes = {
  analyticsContext: object.isRequired,
  onShowLeadsPopup: func.isRequired,
  table1Columns: arrayOf(object),
  isLoading: bool,
  user: object,
  pendingInPeriod: number,
  fitmentsInPeriod: number,
  table1Data: arrayOf(object),
  isUserRequiredError: bool.isRequired,
  fitmentsHistoryRows: arrayOf(object),
  quarterDates: array,
  table3Data: arrayOf(object),
  fitmentsHistoryColumns: arrayOf(object),
  onRefresh: func.isRequired
}

export default rmSummaryDashboardHoc(RmDashboard)
