import React from 'react'
import { CrmTable } from '@cartrack-crm/ui'
import { parseObjectToColumn } from '../utils/excel-utils'

interface ITestResultTable {
  qlRoot: string
  results: any
}
export const TestResultTable: React.SFC<ITestResultTable> = props => {
  const { qlRoot, results } = props

  const getResults = () => {
    const data = results?.data?.master?.instance?.[qlRoot]?.edges.map(e => e.edge) ?? []
    return data.map(row => {
      let ret = { ...row }
      parseObjectToColumn(ret, row, null, '__')
      return ret
    })
  }
  const getColumn = (data = []) => {
    const pColumn = [...data].pop() ?? {}
    let columns = []
    Object.keys(pColumn).forEach(key => {
      columns.push({
        Header: key,
        accessor: key
      })
    })
    return columns
  }
  const data = getResults() ?? []
  const columns = getColumn(data)
  return <CrmTable columns={columns} data={data} />
}
