import React from 'react'
import cloneDeep from 'clone-deep'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CrmLabeledField, CrmButton } from 'crm-components'
import styles from './section-guests.scss'
import sectionContactPersonsHoc from 'crm-modules/accounts/hoc/section-contact-persons-hoc.js'
import ContactPersonItem from 'crm-modules/accounts/components/layout19/contact-person-item.jsx'
import { shape, arrayOf, func, bool, string } from 'prop-types'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'

class SectionGuests extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      persons: cloneDeep(props.persons),
      guests: props.guests ? cloneDeep(props.guests) : []
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.persons !== this.props.persons ||
      nextProps.guests !== this.props.guests
    ) {
      const guests = nextProps.guests ? cloneDeep(nextProps.guests) : []
      const persons = this.mapGuestsToPersons(nextProps.persons, guests)
      this.setState({ persons, guests })
    }
  }

  componentDidMount() {
    const persons = this.mapGuestsToPersons(
      this.state.persons,
      this.state.guests
    )
    this.setState({ persons })
  }

  mapGuestsToPersons = (pPersons, pGuests) => {
    const persons = cloneDeep(pPersons).map(v => {
      const findGuest = pGuests.filter(
        g =>
          g.account_person_uid === v.account_person_uid ||
          g.participant_account_person_uid === v.account_person_uid
      )[0]
      if (findGuest) {
        return { ...v, isGuest: true }
      } else {
        return v
      }
    })
    return persons
  }

  handleClose = e => {
    const newItemIndex = this.props.persons.findIndex(v => v.isNew === true)
    if (newItemIndex > -1) {
      this.props.removePersonUI(newItemIndex)
    }
    e.stopPropagation()
    this.props.handleEditEnd()
  }

  getIsValidEmail = guest => {
    let hasEmail = false
    let hasEmailValue = false
    const emails = this.getPersonEmails(guest)
    emails.map(v => {
      hasEmail = true
      hasEmailValue = false
      if (v.value) {
        hasEmailValue = true
      }
    })
    const isValid = hasEmail && hasEmailValue
    return isValid
  }

  handleGuestsChange = (account_person_uid, isAdd) => {
    const persons = this.state.persons.map(v => {
      if (v.account_person_uid === account_person_uid) {
        return { ...v, isGuest: isAdd }
      } else {
        return v
      }
    })

    const selectedGuests = [...persons.filter(v => v.isGuest)]

    const { guestsValidationRules } = this.props
    if (
      isAdd &&
      guestsValidationRules.required &&
      guestsValidationRules.must_have_email
    ) {
      const hasGuest = selectedGuests.length > 0
      let isAllGuestsHasEmail = true
      selectedGuests.map(guest => {
        if (!this.getIsValidEmail(guest)) {
          this.handleEditPerson(guest)
          isAllGuestsHasEmail = false
        }
      })
      if (!hasGuest || !isAllGuestsHasEmail) {
        return
      }
    }

    this.setState(
      {
        persons,
        guests: selectedGuests
      },
      () => {
        if (!this.props.activity.activity_uid) {
          this.props.handleGuestsChange(this.state.guests)
        }
        if (this.props.input && this.props.input.onChange) {
          const newGuestsValue = this.props._getGuestsValue(this.state.guests)
          this.props.input.onChange(newGuestsValue)
        }
      }
    )
  }

  handleAddGuest = (e, account_person_uid) => {
    this.handleGuestsChange(account_person_uid, true)
  }

  handleRemoveGuest = (e, account_person_uid) => {
    e.stopPropagation()
    this.handleGuestsChange(account_person_uid, false)
  }

  handleStartAddPerson = () => {
    if (this.props.isAddingNewPerson()) {
      return
    }
    this.props.onStartAddPerson()
  }

  getPersonEmails = person => {
    const result = []
    person.contact_details &&
      person.contact_details.map(v => {
        if (v.kind === 'email') {
          result.push(v)
        }
      })

    return result
  }

  handleEditPerson = selectedPerson => {
    const persons = [...this.state.persons].map(v => {
      const findPerson =
        selectedPerson &&
        selectedPerson.account_person_uid === v.account_person_uid
      if (findPerson) {
        const contact_details =
          v.contact_details && v.contact_details.map
            ? [...v.contact_details].map(v2 => {
                return { ...v2, isEditing: true }
              })
            : []
        return { ...v, contact_details, isEditing: true }
      } else {
        return v
      }
    })
    this.setState({ persons })
  }

  handleCancelEditPersonOnMeetingForm = () => {
    const persons = [...this.state.persons].map(v => {
      delete v.isEditing
      return { ...v }
    })
    this.setState({ persons })
  }

  render() {
    console.log('render section-guests', this.state, this.props)
    const { persons, guests } = this.state
    const findNewPerson = persons.find(v => v.isNew) || []

    return (
      <div className={styles.SectionGuests}>
        <div>
          <CrmLabeledField lg noMargin>
            <div
              onClick={
                !this.props.activity.activity_uid
                  ? this.props.handleEditStart
                  : () => {}
              }
              className={
                !this.props.activity.activity_uid
                  ? 'util-hooverableContent util-hooverOp util-flexRow'
                  : ''
              }
            >
              {guests.length > 0 ? (
                <div className="util-textUpdated">
                  {this.props._getGuestsDisplayValue(guests)}
                </div>
              ) : (
                <div>
                  <FormattedMessage
                    id="crm.activity.no_guests_click_here_to_select"
                    defaultMessage="No guests - Click here to select"
                  />
                </div>
              )}
            </div>
          </CrmLabeledField>
        </div>

        {this.props.isExpanded && (
          <React.Fragment>
            <div className={styles.PersonItemsWrapper}>
              {persons.map((person, index) => {
                const key = person.account_person_uid || person.newObjectUid
                const selectedItemClass = person.isGuest
                  ? styles.SelectedItem
                  : ''
                const personEmails = this.getPersonEmails(person)
                if (person.isNew || person.isEditing) {
                  return (
                    <div key={key} className={styles.AddNewItemWrapper}>
                      <div className={styles.AddNewItemHeader}>
                        <FormattedMessage
                          id="crm.activity.add_new_person"
                          defaultMessage={
                            person.isNew ? 'Add new person' : 'Edit person'
                          }
                        />
                      </div>
                      <ContactPersonItem
                        index={index}
                        person={person}
                        onCancelAddPerson={this.props.onCancelAddPerson}
                        removePersonUI={this.props.removePersonUI}
                        updatePersonsUI={this.props.updatePersonsUI}
                        guestsValidationRules={this.props.guestsValidationRules}
                        handleCancelEditPersonOnMeetingForm={
                          this.handleCancelEditPersonOnMeetingForm
                        }
                        // user for refresh query in case edit person in meeting form
                        activityUid={
                          this.props.activity
                            ? this.props.activity.activity_uid
                            : undefined
                        }
                      />
                    </div>
                  )
                } else {
                  if (findNewPerson.length === 0) {
                    return (
                      <div
                        key={key}
                        className={`ActivityListItem-headerItem-value ${styles.PersonItem} ${styles.GuestItem} ${selectedItemClass}`}
                      >
                        <div className={`${styles.GuestItem__ContentWrapper}`}>
                          <div className={`${styles.GuestItem__Content}`}>
                            <div className={`${styles.Department}`}>
                              {person.department}
                            </div>
                            <div className={`${styles.GuestName}`}>
                              {person.name}
                            </div>
                            {personEmails.length > 0 && (
                              <div>
                                {personEmails.map(v => {
                                  return (
                                    <div key={v.contact_details_uid}>
                                      <i className="fa fa-envelope-o" />
                                      <span className="util-marginLeftSm">
                                        {v.value}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                          <div className={`${styles.GuestItem__ContentAction}`}>
                            <React.Fragment>
                              <div
                                className={styles.GuestItem__EditIcon}
                                title={this.props.intl.formatMessage({
                                  id: 'crm.activity.click_to_edit_person',
                                  defaultMessage: 'Click to edit contact'
                                })}
                              >
                                <FontAwesomeIcon
                                  className="util-marginLeft"
                                  onClick={e => this.handleEditPerson(person)}
                                  icon={faPencilAlt}
                                />
                              </div>
                              {!person.isGuest && (
                                <div
                                  className={styles.GuestItem__NoSelectedIcon}
                                  onClick={e =>
                                    this.handleAddGuest(
                                      e,
                                      person.account_person_uid
                                    )
                                  }
                                  title={this.props.intl.formatMessage({
                                    id: 'crm.activity.click_to_add_guest',
                                    defaultMessage: 'Click to add guest'
                                  })}
                                >
                                  <FontAwesomeIcon
                                    className="util-marginLeft"
                                    icon={faCheckCircle}
                                  />
                                </div>
                              )}
                              {person.isGuest && (
                                <div
                                  className={styles.GuestItem__SelectedIcon}
                                  onClick={e =>
                                    this.handleRemoveGuest(
                                      e,
                                      person.account_person_uid
                                    )
                                  }
                                  title={this.props.intl.formatMessage({
                                    id: 'crm.activity.click_to_remove_guest',
                                    defaultMessage: 'Click to remove guest'
                                  })}
                                >
                                  <FontAwesomeIcon
                                    className="util-marginLeft"
                                    icon={faCheckCircle}
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          </div>
                        </div>
                      </div>
                    )
                  }
                }
              })}

              {findNewPerson.length === 0 && (
                <div className={`${styles.PersonItem}`}>
                  <div className={`${styles.AddNewItemIcon}`}>
                    <div>
                      <FontAwesomeIcon
                        title={this.props.intl.formatMessage({
                          id: 'crm.activity.click_to_add_guests',
                          defaultMessage: 'Click to add guests'
                        })}
                        className="util-hooverable util-pointer"
                        icon="user-plus"
                        onClick={this.handleStartAddPerson}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!this.props.activity.activity_uid && (
              <div className="util-flexRowRight">
                <CrmButton
                  grouped
                  label={
                    <FormattedMessage
                      id="crm.ui.button.close"
                      defaultMessage="Close"
                    />
                  }
                  id="section_guests_close"
                  small
                  onClick={this.handleClose}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

SectionGuests.propTypes = {
  persons: arrayOf(shape({})).isRequired,
  guests: arrayOf(shape({})).isRequired,
  activity: shape({}).isRequired,
  input: shape({}).isRequired,
  handleEditEnd: func.isRequired,
  handleGuestsChange: func.isRequired,
  _getGuestsValue: func.isRequired,
  isAddingNewPerson: func.isRequired,
  onStartAddPerson: func.isRequired,
  removePersonUI: func.isRequired,
  handleEditStart: func.isRequired,
  _getGuestsDisplayValue: func.isRequired,
  onCancelAddPerson: func.isRequired,
  updatePersonsUI: func.isRequired,
  isExpanded: bool,
  intl: shape({}).isRequired,
  guestsValidationRules: shape({})
}

SectionGuests.defaultProps = {
  guestsValidationRules: {}
}

export default injectIntl(sectionContactPersonsHoc(SectionGuests))
