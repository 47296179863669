import React, { SFC } from 'react'
import { ActivityContentProps } from '../activity-item/activity-content'
import {
  CrmActivityLiveCall,
  CrmActivityNote,
  CrmActivityEmail,
  CrmActivityMeeting
} from '../activity-item'
import {
  ActivityDateGroup,
  ActivityListWrapper
} from './styled'
import { groupItemsByRelativeTime } from './util'

interface ActivityListContainerProps {
  activities: [ActivityContentProps]
  onEditActivity: Function
  onMinutesOfMeeting: Function
  onShowEmailLog: Function
}

const ActivityListContainer: SFC<ActivityListContainerProps> = (props) => {
  const grouped = groupItemsByRelativeTime('created_time', props.activities)

  return (
    <ActivityListWrapper>
      {Object.keys(grouped).map((key) => {
        const activities = grouped[key]
        return (
          <React.Fragment key={key}>
            <ActivityDateGroup>{key}</ActivityDateGroup>
            {activities.map(activityAdapter(props))}
          </React.Fragment>
        )
      })}
    </ActivityListWrapper>
  )
}

export const activityAdapter = (props) => (activity: ActivityContentProps) => {
  const medium = activity.activity_type.medium_code
  if (!activity.has_permission) {
    return (
      <div>
        {"You don't have permission to read activity "}
        {' - '}
        {activity.activity_type.module}
      </div>
    )
  }

  const onEditActivity = () => {
    const isLiveCallEditAction =
      activity.activity_type.medium_code === 'phone_call'
    props.onEditActivity(activity, isLiveCallEditAction)
  }
  const onMinutesOfMeeting = () => props.onMinutesOfMeeting(activity)
  const onShowEmailLog = () => props.onShowEmailLog(activity)
  const isCustomer = props.account?.sales_status?.code === 'customer'

  switch (medium) {
    case 'note': {
      return (
        <CrmActivityNote
          key={activity['activity_uid']}
          activity={activity}
          onEditActivity={onEditActivity}
          isCustomer={isCustomer}
        />
      )
    }
    case 'email': {
      return (
        <CrmActivityEmail
          key={activity['activity_uid']}
          activity={activity}
          onEditActivity={onShowEmailLog}
          isCustomer={isCustomer}
        />
      )
    }
    case 'phone_call': {
      return (
        <CrmActivityLiveCall
          key={activity['activity_uid']}
          activity={activity}
          onEditActivity={onEditActivity}
          dialerUrl={props.dialerUrl}
          isCustomer={isCustomer}
        />
      )
    }
    case 'meeting': {
      return (
        <CrmActivityMeeting
          key={activity['activity_uid']}
          activity={activity}
          account={props.account}
          onEditActivity={onEditActivity}
          onMinutesOfMeeting={onMinutesOfMeeting}
          _addrFormat={props._addrFormat}
          isCustomer={isCustomer}
        />
      )
    }
  }
}
export default ActivityListContainer
