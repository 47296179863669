/* eslint-disable */
// Order book
const signedContractsDetails = {
  title: 'Order book - Opportunities won in selected period - rev 24-01-2019',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          alias: 'OpportunitiesListQM',
          filter: {
            status_code: 'won'
          },
          fields: [
            'account_uid',
            'account__name',
            'account__lead_campaign__name',
            'account__owner__full_name',
            'vehicle_count',
            'completed_time'
          ],
          order: [{ id: 'completed_time', desc: true }],
          contextMapping: {
            completed_time: 'activity_date'
          }
        }
      ],
      layout: 'pivot'
    },
    layout: {
      items: [
        {
          type: 'table',
          keyField: 'account_uid',
          props: {
            columns: [
              {
                header: 'Lead Source Name',
                accessor: 'account__lead_campaign__name'
              },
              {
                header: 'Account Name',
                accessor: 'account__name'
              },
              {
                header: 'Owner',
                accessor: 'account__owner__full_name'
              },
              {
                header: 'Opportunity won at',
                accessor: 'completed_time',
                type: 'date'
              },
              {
                header: 'Vehicles',
                accessor: 'vehicle_count'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'OpportunitiesListQM'
            }
          }
        }
      ]
    }
  }
}

const signedContractsBySource = {
  title: 'New contracts signed - by source rev 24-01-2019',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          alias: 'OpportunitiesListQM',
          filter: {
            status_code: 'won'
          },
          fields: ['account__lead_campaign__name'],
          aggregate: [{ id: 'vehicle_count', type: 'sum' }],
          order: [{ id: 'account__lead_campaign__name', desc: true }],
          contextMapping: {
            completed_time: 'activity_date'
          }
        }
      ],
      layout: 'pivot'
    },
    layout: {
      items: [
        {
          type: 'table',
          keyField: 'account_uid',
          props: {
            columns: [
              {
                header: 'Lead Source Name',
                accessor: 'account__lead_campaign__name'
              },
              {
                header: 'Vehicles',
                accessor: 'vehicle_count'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'OpportunitiesListQM'
            }
          }
        }
      ]
    }
  }
}

const signedContractsByRm = {
  title: 'New contracts signed - by Owner rev 24-01-2019',
  def_version: 5,
  content: {
    data: {
      dataSources: [
        {
          code: 'OpportunitiesListQM',
          alias: 'OpportunitiesListQM',
          filter: {
            status_code: 'won'
          },
          fields: ['account__owner__full_name'],
          aggregate: [{ id: 'vehicle_count', type: 'sum' }],
          order: [{ id: 'account__owner__full_name', desc: true }],
          contextMapping: {
            completed_time: 'activity_date'
          }
        }
      ],
      layout: 'pivot'
    },
    layout: {
      items: [
        {
          type: 'table',
          keyField: 'account_uid',
          props: {
            columns: [
              {
                header: 'Owner Name',
                accessor: 'account__owner__full_name'
              },
              {
                header: 'Vehicles',
                accessor: 'vehicle_count'
              }
            ],
            data: {
              type: 'dataSource',
              dataSourceCode: 'OpportunitiesListQM'
            }
          }
        }
      ]
    }
  }
}

const dashSalesOverview = {
  dashboard_uid: 'dashSalesOverview',
  name: 'Order Book',
  fullscreen: true,

  content: {
    items: [
      {
        type: 'analitics_widget',
        widgetDefinition: signedContractsDetails
      },
      {
        type: 'container',
        class: 'row util-marginBottom',
        items: [
          {
            type: 'container',
            class: 'col-md-5',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: signedContractsBySource
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-5',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: signedContractsByRm
              }
            ]
          }
        ]
      }
    ]
  }
}

export default dashSalesOverview
