export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  // Windows Phone must come first because its UA also contains "Android"
  if (/android/i.test(userAgent)) {
    return 'Android'
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }
  return 'unknown'
}

const redirectToApp = () => {
  let [schema, uri] = window.location.href.split('://')
  schema = 'saleslogicae'

  const platform = getMobileOperatingSystem()
  if (platform === 'Android') {
  }
  if (platform === 'iOS') {
    let link = `${schema}://${uri}`
    window.location.replace(link)
  }
}

redirectToApp()
