/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset, change } from 'redux-form'
import filesize from 'filesize'
import ReactTable from 'react-table'
import { injectIntl, FormattedDate } from 'react-intl'
import { shape, func, bool, arrayOf } from 'prop-types'
import ResourceQuickView from './components/resource-quick-view.jsx'
import ResourceCreateForm from './components/resource-create-form.jsx'
import { toMSformat } from 'crm-utils/time-utils'
import {
  fetchData,
  selectedItem,
  deleteItem,
  unselectedItem,
  startEditingItem,
  cancelEditingItem,
  startCreatingItem,
  cancelCreatingItem,
  saveCreatingItem,
  saveEditingItem
} from 'crm-duxs/admin/resources-reducer'

import { CrmFullpageContainer, CrmHbox, CrmButton } from 'crm-components'

class CrmResourcesSettings extends Component {
  constructor(props) {
    super(props)
    this.columns = this.getColumn()
    this.isSelectedRow = this.isSelectedRow.bind(this)
    this.handleCreateItem = this.handleCreateItem.bind(this)
  }

  handleCreateItem() {
    this.props.doCreateItem()
  }

  onHandleDelete = resourceUid => {
    console.log('start to delete resource')
    this.props.doDeleting(resourceUid)
  }

  getColumn() {
    return [
      {
        accessor: 'name',
        Header: 'Filename',
        width: 300
      },
      {
        accessor: 'description',
        Header: 'Description'
      },
      {
        accessor: 'size',
        Header: 'Size',
        Cell: row => filesize(row.original.size)
      },
      {
        accessor: 'mime_type',
        Header: 'Mimetype'
      },
      {
        accessor: 'created_time',
        Header: 'Createtime'
      },
      {
        Header: 'Action',
        width: 100,
        sortable: false,
        Cell: row => (
          <CrmButton
            className="remove-button"
            xsmall
            icon="trash"
            // eslint-disable-next-line
            onClick={() => this.onHandleDelete(row.original.resource_uid)}
          />
        )
      }
    ]
  }

  getDateValue(datetime) {
    return (
      <FormattedDate
        year="numeric"
        month="long"
        day="2-digit"
        value={toMSformat(datetime)}
      />
    )
  }

  componentWillMount() {
    // console.log("componentWillMount")
    // console.log(this.props.lists.length)
    if (this.props.lists.length === 0) this.props.doFetch()
  }

  onRowClick(e, row) {
    if (
      this.props.selected &&
      row.original.resource_uid === this.props.selected.resource_uid
    ) {
      this.props.doUnselectedItem()
    } else {
      this.props.doSelectedItem(row.original)
    }
  }

  isSelectedRow(state, rowInfo) {
    const bg =
      this.props.selected &&
      rowInfo &&
      rowInfo.original.resource_uid === this.props.selected.resource_uid
        ? '#666'
        : ''
    return {
      style: {
        background: bg
      }
    }
  }

  _renderRightSide() {
    if (this.props.creating) {
      return (
        <div style={{ width: 700, height: '100%', overflowY: 'auto' }}>
          <ResourceCreateForm
            onUpload={this.props.doUploadFile}
            onReset={this.props.doResetForm} // eslint-disable-line react/jsx-handler-names
            onCancel={this.props.doCancelCreating} // eslint-disable-line react/jsx-handler-names
            onSave={this.props.doSaveCreating} // eslint-disable-line react/jsx-handler-names
          />
        </div>
      )
    }

    if (this.props.selected) {
      return (
        <div style={{ width: 700, height: '100%', overflowY: 'auto' }}>
          <ResourceQuickView
            editing={this.props.editing}
            creating={this.props.creating}
            dictionary={this.props.selected}
            onClose={this.props.doUnselectedItem} // eslint-disable-line react/jsx-handler-names
            onEdit={this.props.doEditing} // eslint-disable-line react/jsx-handler-names
            onCancelEdit={this.props.doCancelEditing} // eslint-disable-line react/jsx-handler-names
            onReset={this.props.doResetForm} // eslint-disable-line react/jsx-handler-names
            onSaveEditing={this.props.doSaveEditing} // eslint-disable-line react/jsx-handler-names
          />
        </div>
      )
    }
  }

  render() {
    const columns = this.columns
    const getTdProps = (state, rowInfo, column, instance) => ({
      onClick: (e, handleOriginal) => {
        if (column.Header != 'Action') {
          console.log('It was in this column:', column)
          this.onRowClick(e, rowInfo)
        }

        handleOriginal()
      }
    })

    return (
      <CrmFullpageContainer hasPadding vbox>
        <div className="row">
          <CrmButton onClick={this.handleCreateItem} label="Create" />
        </div>
        <CrmHbox fill>
          <ReactTable
            loading={this.props.fetch}
            data={this.props.lists}
            columns={[
              {
                Header: ' ',
                columns: columns
              }
            ]}
            className="react-table -striped -highlight"
            style={{ height: '100%' }}
            getTdProps={getTdProps}
            getTrProps={this.isSelectedRow}
          />
          {this._renderRightSide()}
        </CrmHbox>
      </CrmFullpageContainer>
    )
  }
}

CrmResourcesSettings.defaultProps = {
  selected: undefined,
  lists: []
}

CrmResourcesSettings.propTypes = {
  selected: shape({}),
  lists: arrayOf(shape({})),
  fetch: bool.isRequired,
  editing: bool.isRequired,
  creating: bool.isRequired,
  doUnselectedItem: func.isRequired,
  doDeleting: func.isRequired,
  doEditing: func.isRequired,
  doSaveEditing: func.isRequired,
  doCancelEditing: func.isRequired,
  doSelectedItem: func.isRequired,
  doFetch: func.isRequired,
  doResetForm: func.isRequired,
  doCreateItem: func.isRequired,
  doCancelCreating: func.isRequired,
  doSaveCreating: func.isRequired
}

function mapStateToProps(state) {
  const resourceState = state.crm.adminResources
  return {
    fetch: resourceState.fetch,
    lists: resourceState.lists,
    selected: resourceState.selected,
    editing: resourceState.editing,
    creating: resourceState.creating
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doFetch: () => dispatch(fetchData()),
    doSelectedItem: item => dispatch(selectedItem(item)),
    doUnselectedItem: () => dispatch(unselectedItem()),
    doDeleting: resourceUid => dispatch(deleteItem(resourceUid)),
    doEditing: () => dispatch(startEditingItem()),
    doCancelEditing: () => dispatch(cancelEditingItem()),
    doResetForm: () => dispatch(reset('crmSettingResource')),
    doUploadFile: (field, value) =>
      dispatch(change('crmSettingResource', field, value)),
    doCreateItem: () => dispatch(startCreatingItem()),
    doCancelCreating: () => dispatch(cancelCreatingItem()),
    doSaveCreating: formValues => dispatch(saveCreatingItem(formValues)),
    doSaveEditing: formValues => dispatch(saveEditingItem(formValues))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CrmResourcesSettings))
