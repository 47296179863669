/* eslint-disable camelcase */
import React from 'react'
import { shape } from 'prop-types'
import { qlqProcessDetails } from 'crm-data/processes'
import { qlqfetchAccountStatusAndProcesses } from 'crm-data/accounts'
import { qlqAccountTasks } from 'crm-data/tasks'
import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

export function buildCommand(account, process, params) {
  const command = {
    type: 'salesAccount.reassign',
    aggregate_uid: account.account_uid,
    aggregate_type: 'salesAccount',
    payload: {}
  }
  if (process) {
    command.payload.process_uid = process.process_uid
  }
  if (params.newOwnerType === 'group') {
    command.payload.ownerGroupUid = params.usersGroup
    command.payload.ownerType = 'group'
  }
  if (params.newOwnerType === 'user') {
    command.payload.ownerUserUid = params.userUid
    command.payload.ownerType = 'user'
  }
  return command
}
// eslint-disable-next-line
const changeProcessOwnerHoc = WrappedComponent => {
  class ChangeProcessHoc extends React.PureComponent {
    static propTypes = {
      process: shape({}),
      ...cqCommandPropTypes
    }
    static defaultProps = {
      isEditing: false,
      gotoPage: undefined,
      newAccountUid: undefined,
      process: undefined,
      onCancel: undefined
    }
    constructor(props) {
      super(props)
      this.state = { newOwnerType: 'user' }
    }

    handleUsersGroupSelect = usersGroup => {
      // console.log('usersGroupChange', usersGroup)
      this.setState({ usersGroup })
    }
    handleNewOwnerTypeChanged = newOwnerType => {
      this.setState({ newOwnerType })
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    handleSaveOwnerChange = async pProcess => {
      console.log('handleSaveOwnerChange', pProcess, this.props, this.state)
      const process = pProcess || this.props.process
      console.log('process', process, this.state)
      if (!this.state.newOwnerType) {
        throw new Error('Select new owner type')
      }
      if (this.state.newOwnerType === 'user' && !this.state.userUid) {
        throw new Error('Select user')
      }
      if (this.state.newOwnerType === 'group' && !this.state.usersGroup) {
        throw new Error('Select group')
      }

      const command = buildCommand(this.props.account, process, this.state)
      console.log('command', command)
      try {
        const refetchQueries = [
          {
            query: qlqfetchAccountStatusAndProcesses,
            variables: {
              account_uid: this.props.accountUid
            },
            partialRefetch: true
          },
          {
            query: qlqAccountTasks,
            variables: {
              account_uid: this.props.accountUid
            },
            partialRefetch: true
          }
        ]
        if (process) {
          refetchQueries.push({
            query: qlqProcessDetails,
            variables: {
              process_uid: process.process_uid
            },
            partialRefetch: true
          })
        }
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })
        return res.data.cqCommand
      } catch (err) {
        // console.log('Change process owner', err)
        throw new Error('Change Process owner error', err)
      }
    }

    handleUserSelect = value => {
      console.log('handleUserSelect', value)
      this.setState({ userUid: value })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onSaveOwnerChange={this.handleSaveOwnerChange}
          onUserSelect={this.handleUserSelect}
          onUsersGroupSelect={this.handleUsersGroupSelect}
          onOwnerTypeChanged={this.handleNewOwnerTypeChanged}
          userUid={this.state.userUid}
          usersGroup={this.state.usersGroup}
          newOwnerType={this.state.newOwnerType}
          onBulkOperation={this.handleSaveOwnerChange}
        />
      )
    }
  }
  return crmGenericCommandHoc(ChangeProcessHoc)
}

export default changeProcessOwnerHoc
