import React from 'react'
import { bool } from 'prop-types'
import { connect } from 'react-redux'
import { formValues } from 'redux-form'
import store from '../index'

export function visibleBySelector(Wrapped, selector) {
  const VisibleBySelector = props =>
    props.visible ? <Wrapped {...props} /> : null

  VisibleBySelector.propTypes = {
    visible: bool
  }

  VisibleBySelector.defaultProps = {
    visible: false
  }

  function mapStateToProps(state) {
    return {
      visible: selector(state)
    }
  }

  return connect(mapStateToProps)(VisibleBySelector)
}

export function validateBySelector(validate, selector) {
  if (!validate) return null
  return (...args) =>
    selector(store.getState()) ? validate(...args) : undefined
}

export function visibleIf(Component, valueKey) {
  const isNegated = valueKey[0] === '!'
  const key = isNegated ? valueKey.slice(1) : valueKey

  const VisibleIf = props =>
    (isNegated ? !props[key] : props[key]) ? <Component {...props} /> : null

  VisibleIf.defaultProps = {
    [key]: false
  }

  return formValues(key)(VisibleIf)
}

export function validateIf(validate, valueKey) {
  return (val, allVals) => {
    const isNegated = valueKey[0] === '!'
    const key = isNegated ? valueKey.slice(1) : valueKey
    return (isNegated
    ? !allVals[key]
    : allVals[key])
      ? validate(val, allVals)
      : undefined
  }
}
