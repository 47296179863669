import React from 'react'

export const withEld = [
  'localhost',
  'fleetusa.cartrack.com',
  'fleetusa1.cartrack.com',
  'staging.cartrack.us.s3-website-us-west-1.amazonaws.com'
].includes(window.location.hostname)

const WithEld = ({ children }) => {
  if (withEld) return React.Children.only(children)
  return null
}

export default WithEld

export const withEldHOC = Component => (withEld ? Component : () => null)
