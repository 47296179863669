import { shape, number, bool, string } from 'prop-types'
import { createSelector } from 'reselect'
import { groupBy } from 'lodash'
// import { processVehicleTrips, processVehicleEvents, countVehiclesByActivity } from '../process-data/vehicles'
import { CLOSE_DETAILS, FOCUS_VEHICLE, SET_NEARBY_VISIBLE, UPDATE_FOCUSED_VEHICLE } from 'crm-duxs/map'
import { reconciliateGroupItemIds } from 'util-functions/functional-utils'
import { distanceBetweenLatLng } from 'util-functions/map-utils'

// Actions
// General
export const FETCH_VEHICLES_AND_FOCUSED_EVENTS = 'FETCH_VEHICLES_AND_FOCUSED_EVENTS'
export const RECEIVE_VEHICLE_AND_FOCUSED_EVENTS = 'RECEIVE_VEHICLE_AND_FOCUSED_EVENTS'
export const RECEIVE_VEHICLE_TRIPS = 'RECEIVE_VEHICLE_TRIPS'
export const UPDATE_CACHED_VEHICLE_EVENTS = 'UPDATE_CACHED_VEHICLE_EVENTS'
export const FETCH_ACTIVE_VEHICLE_TRIPS = 'FETCH_ACTIVE_VEHICLE_TRIPS'
export const RECEIVE_ACTIVE_VEHICLE_TRIP = 'RECEIVE_ACTIVE_VEHICLE_TRIP'
// Detail Pages
export const FETCH_VEHICLE_DETAIL = 'FETCH_VEHICLE_DETAIL'
export const RECEIVE_VEHICLE_DETAIL = 'RECEIVE_VEHICLE_DETAIL'
export const CLEAR_CURRENT_VEHICLE = 'CLEAR_CURRENT_VEHICLE'
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const UPDATE_VEHICLE_TRIPS = 'UPDATE_VEHICLE_TRIPS'
export const TOGGLE_VEHICLE_STATUS = 'TOGGLE_VEHICLE_STATUS'
// Group Pages
export const UPDATE_VEHICLE_GROUP = 'UPDATE_VEHICLE_GROUP'
export const DELETE_VEHICLE_GROUP = 'DELETE_VEHICLE_GROUP'
export const CREATE_VEHICLE_GROUP = 'CREATE_VEHICLE_GROUP'
// Dashboard
export const FETCH_TOP_VEHICLE_TRIPS = 'FETCH_TOP_VEHICLE_TRIPS'
export const RECEIVE_TOP_VEHICLE_TRIP = 'RECEIVE_TOP_VEHICLE_TRIP'
export const CLEAR_TOP_VEHICLE_TRIPS = 'CLEAR_TOP_VEHICLE_TRIPS'
export const FETCH_WATCHED_VEHICLE_EVENTS = 'FETCH_WATCHED_VEHICLE_EVENTS'
export const RECEIVE_WATCHED_VEHICLE_EVENTS = 'RECEIVE_WATCHED_VEHICLE_EVENTS'

// Reducer
const initialState = {
  hasLoaded: false,
  hasLoadedDetails: false,
  detailedVehicle: null,
  vehicles: [],
  groups: [],
  trips: [],
  tripsStartInMs: 0,
  tripsNDays: 0,
  events: [],
  cachedEvents: [], // We need this so we don't rerender the scrubbable timeline on every server poll
  topTrips: [],
  activeVehicleTrips: {},
  watchedVehiclesEvents: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_VEHICLE_DETAIL:
      return {
        ...state,
        detailedVehicle: action.payload.vehicle,
        trips: action.payload.trips,
        hasLoadedDetails: true
      }
    case RECEIVE_VEHICLE_AND_FOCUSED_EVENTS:
      return {
        ...state,
        vehicles: action.payload.vehicles,
        cachedVehicles: state.cachedVehicles || action.payload.vehicles,
        groups: action.payload.groups,
        events: action.payload.events,
        hasLoaded: true
      }
    case UPDATE_CACHED_VEHICLE_EVENTS:
      return {
        ...state,
        cachedEvents: action.payload.cachedEvents
      }
    case RECEIVE_VEHICLE_TRIPS:
      return {
        ...state,
        trips: action.payload.trips,
        tripsStartInMs: action.payload.tripsStartInMs,
        tripsNDays: action.payload.tripsNDays
      }
    case RECEIVE_TOP_VEHICLE_TRIP:
      return {
        ...state,
        topTrips: [...state.topTrips, { ...action.payload.trip, id: action.payload.id }]
      }
    case CLEAR_TOP_VEHICLE_TRIPS:
      return {
        ...state,
        topTrips: []
      }
    case RECEIVE_WATCHED_VEHICLE_EVENTS:
      return {
        ...state,
        watchedVehiclesEvents: {
          ...state.watchedVehiclesEvents,
          [action.payload.id]: {
            timelineData: action.payload.events,
            alerts: action.payload.alerts,
            id: action.payload.id,
            stats: action.payload.stats
          }
        }
      }
    case RECEIVE_ACTIVE_VEHICLE_TRIP:
      return {
        ...state,
        activeVehicleTrips: {
          ...state.activeVehicleTrips,
          [action.payload.id]: {
            events: action.payload.events,
            currentEvent: action.payload.currentEvent
          }
        }
      }
    // Clear cache used to draw scrubbable timeline
    case CLOSE_DETAILS:
      return { ...state, cachedEvents: [] }
    case FOCUS_VEHICLE:
      return { ...state, cachedEvents: [] }
    case UPDATE_FOCUSED_VEHICLE:
      return {
        ...state,
        cachedEvents: [],
        trips: [],
        tripsStartInMs: 0,
        tripsNDays: 0
      }
    case SET_NEARBY_VISIBLE:
      return { ...state, cachedEvents: [] }
    case CLEAR_CURRENT_VEHICLE:
      return { ...state, detailedVehicle: null, hasLoadedDetails: false }
    default:
      return state
  }
}

// Action Creators
// Events/Trips
export function fetchActiveVehicleTrips() {
  return {
    type: FETCH_ACTIVE_VEHICLE_TRIPS
  }
}

// Detail
export function fetchVehicleDetails(vehicleId) {
  return {
    type: FETCH_VEHICLE_DETAIL,
    payload: {
      vehicleId
    }
  }
}

export function updateVehicleTrips(id, trips) {
  return {
    type: UPDATE_VEHICLE_TRIPS,
    payload: { id, trips }
  }
}

export function toggleVehicleStatus(id, currentStatus) {
  return {
    type: TOGGLE_VEHICLE_STATUS,
    payload: { id, currentStatus }
  }
}

export function updateVehicle(update) {
  return {
    type: UPDATE_VEHICLE,
    payload: { update }
  }
}

export function clearCurrentVehicle() {
  return {
    type: CLEAR_CURRENT_VEHICLE
  }
}

// Dashboard
export function fetchTopVehicleTrips() {
  return {
    type: FETCH_TOP_VEHICLE_TRIPS
  }
}

export function clearTopVehicleTrips() {
  return {
    type: CLEAR_TOP_VEHICLE_TRIPS
  }
}

export function fetchWatchedVehicleEvents(vehicleIds) {
  return {
    type: FETCH_WATCHED_VEHICLE_EVENTS,
    payload: { vehicleIds }
  }
}

// Groups
export function updateVehicleGroup(group, selectedIds) {
  return {
    type: UPDATE_VEHICLE_GROUP,
    payload: { group, selectedIds }
  }
}

export function deleteVehicleGroup(groupId, groupName) {
  return {
    type: DELETE_VEHICLE_GROUP,
    payload: { groupId, groupName }
  }
}

export function createVehicleGroup(name) {
  return {
    type: CREATE_VEHICLE_GROUP,
    payload: {
      name
    }
  }
}

// Selectors
export const getHasLoaded = state => state.vehicles.hasLoaded
export const getHasLoadedDetails = state => state.vehicles.hasLoadedDetails
export const getDetailedVehicle = state => state.vehicles.detailedVehicle
export const getVehicles = state => state.vehicles.vehicles
export const getCachedVehicles = state => state.vehicles.cachedVehicles
export const getVehicleOptions = createSelector(getVehicles, vehicles =>
  vehicles.map(v => ({
    name: v.name,
    value: v.id
  }))
)

export const getVehiclesWithDrivers = createSelector(
  getVehicles,
  state => state.drivers.drivers, // Circular dependency guard
  (vehicles, drivers) =>
    vehicles.map(v => ({
      ...v,
      driver: drivers.filter(d => d.vehicleId === v.id).reduce((acc, d) => (acc ? (d.seenToday && d) || acc : d), null)
    }))
)

export const getActiveVehicles = createSelector(getVehicles, vehicles =>
  vehicles.filter(v => Date.now() - 86400000 < v.lastTimestamp)
)
export const getActiveVehicleTrips = state => state.vehicles.activeVehicleTrips

// T O D O: Use createSelector
export const getVehicle = (state, id) => state.vehicles.vehicles.find(v => v.id === id)

export const getVehicleTrips = state => state.vehicles.trips

export const getFocusedVehicleEvents = state => state.vehicles.events
export const getFocusedVehicleCachedEvents = state => state.vehicles.cachedEvents
export const getFocusedVehicleId = state => state.map.focusedItemId
export const getVehicleGroups = state => state.vehicles.groups

// Using getFocusedItemId Would be a circular dependency.
export const getFocusedVehicle = createSelector(
  getVehicles,
  getFocusedVehicleId,
  (vehicles, id) => (id && vehicles.find(v => v.id === id)) || null
)
export const getFocusedVehicleTimelinesData = createSelector(
  state => state.vehicles.trips,
  state => state.vehicles.tripsStartInMs,
  state => state.vehicles.tripsNDays
  // processVehicleTrips
)

export const getFocusedVehicleTimelineData = createSelector(getFocusedVehicleCachedEvents)

// T O D O: Use createSelector
export const getVehicleGroup = (state, groupId) =>
  reconciliateGroupItemIds(
    getVehicleGroups(state).find(group => group.id === groupId),
    getVehicles(state)
  )

// T O D O: Use create selector
export const getVehicleGroupItemIds = (state, groupId) => state.vehicles.groups.find(g => g.id === groupId).items

// export const getVehicleCountByActivity = createSelector(getVehicles, countVehiclesByActivity)

export const getMostRecentActiveVehicles = createSelector(getVehicles, vehicles =>
  [...vehicles].sort((a, b) => b.lastTimestamp - a.lastTimestamp).slice(0, 6)
)

export const getTopTrips = state => state.vehicles.topTrips

export const getWatchedVehiclesEvents = createSelector(
  state => state.vehicles.watchedVehiclesEvents,
  events => Object.values(events)
)

export const getVehicleDistancesFromPoint = createSelector(
  state => state.map.focusedPoint,
  getVehicles,
  (point, vehicles) =>
    point
      ? vehicles
          .map(vehicle => ({
            ...vehicle,
            distance:
              Math.round(distanceBetweenLatLng(point.lat, point.lng, vehicle.latitude, vehicle.longitude) * 4) / 4
          }))
          .sort((a, b) => a.distance - b.distance)
      : []
)

export const getFocusedVehicleEventsByTrip = createSelector(
  getVehicleTrips,
  getFocusedVehicleCachedEvents,
  (trips, events) =>
    trips
      .map(trip => {
        const tripEvents = events.filter(event => event.time >= trip.startTime && event.time <= trip.endTime)

        return {
          ...trip,
          events: tripEvents,
          stats
        }
      })
      .filter(trip => trip.events.length && trip.stats.totalEngineTime)
)

export const getFocusedVehicleEventsByType = createSelector(getFocusedVehicleCachedEvents, events =>
  groupBy(events, event => event.type || event.statusClassName)
)

// PropTypes
export const vehicleShape = shape({
  id: string.isRequired,
  type: string.isRequired,
  name: string.isRequired,
  statusClassName: string.isRequired,
  rating: number.isRequired,
  lastTimestamp: number.isRequired,
  ignition: bool.isRequired,
  speed: number.isRequired,
  positionDescription: string.isRequired,
  latitude: number.isRequired,
  longitude: number.isRequired,
  bearing: number.isRequired,
  description: string.isRequired,
  maxSpeed: number.isRequired
})

export const vehicleEventShape = shape({
  id: string.isRequired,
  time: number.isRequired,
  latitude: number.isRequired,
  longitude: number.isRequired,
  speed: number.isRequired,
  bearing: number.isRequired,
  description: string.isRequired,
  roadSpeed: number.isRequired,
  position: string.isRequired,
  rpm: number.isRequired,
  waterTemp: number.isRequired,
  oilTemp: number.isRequired,
  speeding: bool.isRequired,
  odometer: number.isRequired,
  statusClassName: string.isRequired
})
