import React from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import { CrmButton } from '@cartrack-crm/ui'
import { formatMessage } from '../utils/inital-component'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string
type Action = {
  label?: string
  type?: string
  onClick?: (...args: any[]) => any
  grouped?: boolean
}
interface ICrmModalFooter {
  primaryButtonLabel?: any
  secondaryButtonLabel?: any
  onSecondaryButton?: (...args: any[]) => any
  onPrimaryButton?: (...args: any[]) => any
  actions?: Action[]
  isSaving?: boolean
  specialContentLabel?: string
  onSpecialContent?: (...args: any[]) => any
}
interface ICrmModal extends ICrmModalFooter {
  isOpen: boolean
  children?: React.ReactNode
  showFooter?: boolean
  isShowCloseBtn?: boolean
  size?: ModalSize
  title?: any
  onClose?: Function
  formatMessage?: any
  extraClassNames?: {
    bodyClassNames?: string
  }
  isSaving?: boolean
  zIndex?: number
}
type _getDimensionsFromSize = (size: ModalSize) => React.CSSProperties
const _getDimensionsFromSize: _getDimensionsFromSize = size => {
  switch (size) {
    case 'xs':
      return { width: 300, height: 200 }
    case 'sm':
      return { width: 400, height: 400 }
    case 'md':
      return { width: 800, height: 500 }
    case 'lg':
      return { width: 1200, height: 600 }
    case 'xl':
      return { width: '90%', height: '90%' }
    default:
      return { width: 600, height: 600 }
  }
}
const renderContent = props => {
  if (props.bodyRenderer) {
    return props.bodyRenderer()
  }
  return props.children ?? 'This Modal is empty yet'
}

export const CrmModal: React.FC<ICrmModal> = props => {
  const { isOpen, size, title, onClose, showFooter, extraClassNames, isShowCloseBtn = true, zIndex } = props
  const dimensions = _getDimensionsFromSize(size)
  const modalStyle = {
    content: {
      width: dimensions.width,
      height: dimensions.height,
      padding: 0,
      borderRadius: 0,
      position: 'relative',
      top: undefined,
      bottom: undefined,
      left: undefined,
      right: undefined,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto'
    },
    overlay: {
      display: 'flex',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      top: 0,
      zIndex: zIndex ?? 5000
    }
  }
  const handleCloseClick = () => {
    if (onClose) {
      onClose()
    }
  }
  return (
    <Modal
      ariaHideApp={false}
      className={{
        base: 'ReactModal__Content CrmModal-content',
        afterOpen: 'ReactModal__Content--after-open',
        beforeClose: 'ReactModal__Content--before-close'
      }}
      isOpen={isOpen}
      style={modalStyle}
      {...props}
      contentLabel={title?.toString()}
    >
      <div className="CrmModal">
        <div className="CrmModal-header">
          <div className="CrmModal-title">{formatMessage(title, 'modal.name')}</div>
          {isShowCloseBtn && (
            <div className="CrmModal-close-button-wrapper">
              <div onClick={handleCloseClick} className="CrmModal-close-button-icon">
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          )}
        </div>
        <div className="CrmModal-bodyWrapper">
          <div
            className={classNames('CrmModal-body', {
              [`${extraClassNames?.bodyClassNames}`]: extraClassNames?.bodyClassNames
            })}
          >
            {renderContent(props)}
          </div>
        </div>
        {showFooter && <CrmModalFooter {...props} />}
      </div>
    </Modal>
  )
}

const CrmModalFooter: React.FC<ICrmModalFooter> = ({
  primaryButtonLabel,
  secondaryButtonLabel,
  onSecondaryButton,
  onPrimaryButton,
  actions,
  isSaving,
  specialContentLabel,
  onSpecialContent
}) => (
  <div className="CrmModal-footer">
    {specialContentLabel && (
      <div className="util-flexGrow">
        <CrmButton
          className="btn-fleet btn-secondary"
          onClick={onSpecialContent}
          label={specialContentLabel}
          grouped
          type="secondary"
        />
      </div>
    )}
    <div className="CrmModal-actions">
      {secondaryButtonLabel && (
        <CrmButton
          className="btn-fleet btn-secondary"
          onClick={onSecondaryButton}
          label={secondaryButtonLabel}
          grouped
          type="secondary"
        />
      )}
      {onPrimaryButton && (
        <CrmButton
          className="btn-fleet btn-primary"
          onClick={onPrimaryButton}
          label={primaryButtonLabel}
          type="primary"
          grouped
          isSaving={isSaving}
          enable={!isSaving}
        />
      )}
      {actions?.map(action => (
        <CrmButton
          key={action.label}
          className="CrmModal-actions-button"
          label={action.label}
          type={action.type}
          onClick={action.onClick}
          grouped
          disabled={isSaving}
        />
      ))}
    </div>
  </div>
)
