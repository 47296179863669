import React from 'react'
import { connect } from 'react-redux'
import { func, bool, string } from 'prop-types'
import { push } from 'connected-react-router'
import editAccountHoc from 'crm-modules/accounts/hoc/edit-account-hoc'
import DayPicker from 'react-day-picker'

class ChangeLeadInDateWidget extends React.PureComponent {
  handleLeadInDateChange = async value => {
    if (this.props.onLeadInDateChange) {
      this.props.onLeadInDateChange(value)
    }
  }
  componentDidMount() {
    if (this.props.onBulkComponentInit) {
      this.props.onBulkComponentInit(this)
    }
  }
  render() {
    return (
      <div>
        <DayPicker
          numberOfMonths={1}
          onDayClick={this.handleLeadInDateChange}
          selectedDays={this.props.leadInDate}
          initialMonth={new Date()}
        />
      </div>
    )
  }
}

ChangeLeadInDateWidget.propTypes = {
  onBulkComponentInit: func,
  bulkMode: bool,
  bulkType: string.isRequired,
  onLeadInDateChange: func,
  leadInDate: string
}

ChangeLeadInDateWidget.defaultProps = {
  onBulkComponentInit: undefined,
  bulkMode: false,
  onLeadInDateChange: undefined,
  leadInDate: undefined
}

export default editAccountHoc(
  connect(null, dispatch => ({
    doPush: url => dispatch(push(url))
  }))(ChangeLeadInDateWidget)
)
