import React, { useState, useCallback, useEffect } from 'react'
import { shape, func, arrayOf, bool } from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import withLeadsPopupHoc from '../components/_with-leads-popup-hoc.jsx'
import {
  mapContextToFilter,
  generateDateSeries
} from 'crm-modules/dashboard/dashboard-utils'
import CrmWidget from 'crm-modules/dashboard/components/crm-widget.jsx'
import { useApolloClient } from '@apollo/react-hooks'
import { CrmInfoBlock, CrmCheckbox } from 'crm-components/index.js'
import gql from 'graphql-tag'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const BuildExcel = data => {
  const renderRow = (label, data, renderer) => {
    const total = data.reduce((a, i) => {
      const val = renderer(i)
      return a + (isNaN(val) ? 0 : val)
    }, 0)
    let resultRow = {}
    resultRow[''] = label
    resultRow.total = total
    data.map(i => {
      const value = renderer(i)
      const dateHeader = moment(i.date).format('ddd MM-DD')
      resultRow[dateHeader] = value
    })

    return resultRow
  }

  const ret = []

  let row = renderRow('Total', data, cell => {
    return cell ? cell.total : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Leads', data, cell => {
    return cell.lead ? cell.lead.total : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Raw (untouched)', data, cell => {
    return cell.lead && cell.lead.detailed.new
        ? cell.lead.detailed.new.total
        : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Touched', data, cell => {
    return cell.lead && cell.lead.detailed.touched
        ? cell.lead.detailed.touched.total
        : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Contacted', data, cell => {
    return cell.lead && cell.lead.detailed.contacted
        ? cell.lead.detailed.contacted.total
        : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Invalid', data, cell =>
      cell.invalid ? cell.invalid.total : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Duplicate', data, cell =>
    cell.invalid && cell.invalid.detailed.duplicate
      ? cell.invalid.detailed.duplicate.total
      : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Bogus', data, cell =>
    cell.invalid && cell.invalid.detailed.bogus
      ? cell.invalid.detailed.bogus.total
      : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Wrong Number', data, cell =>
    cell.invalid && cell.invalid.detailed.lost_wrong_number
      ? cell.invalid.detailed.lost_wrong_number.total
      : undefined
  )

  if (row) ret.push(row)

  row = renderRow('No market', data, cell =>
    cell.invalid && cell.invalid.detailed.lost_no_market
      ? cell.invalid.detailed.lost_no_market.total
      : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Unreachable', data, cell =>
      cell.invalid && cell.invalid.detailed.lost_unreachable
          ? cell.invalid.detailed.lost_unreachable.total
          : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Valid', data, cell => {
    const res =
      (cell.valid ? cell.valid.total : 0) +
      (cell.customer ? cell.customer.total : 0) +
      (cell.lost ? cell.lost.total : 0)
    return res > 0 ? res : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Validated', data, cell => {
    return cell.valid && cell.valid.detailed.validated
      ? cell.valid.detailed.validated.total
      : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Needs Analysis', data, cell => {
    return cell.valid && cell.valid.detailed.needs_analysis
      ? cell.valid.detailed.needs_analysis.total
      : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Opportunity', data, cell => {
    return cell.valid && cell.valid.detailed.opportunity
      ? cell.valid.detailed.opportunity.total
      : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Quotation', data, cell => {
    return cell.valid && cell.valid.detailed.quoted
      ? cell.valid.detailed.quoted.total
      : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Closing', data, cell => {
    return cell.valid && cell.valid.detailed.closing
      ? cell.valid.detailed.closing.total
      : undefined
  })

  if (row) ret.push(row)

  row = renderRow('Lost', data, cell =>
    cell.lost ? cell.lost.total : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Competitor', data, cell =>
    cell.lost && cell.lost.detailed.lost_competitor
      ? cell.lost.detailed.lost_competitor.total
      : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Not Interested', data, cell =>
    cell.lost && cell.lost.detailed.lost_not_interested
      ? cell.lost.detailed.lost_not_interested.total
      : undefined
  )

  if (row) ret.push(row)

  row = renderRow('No Value for Customer', data, cell =>
    cell.lost && cell.lost.detailed.lost_no_value
      ? cell.lost.detailed.lost_no_value.total
      : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Affordability', data, cell =>
    cell.lost && cell.lost.detailed.lost_affordability
      ? cell.lost.detailed.lost_affordability.total
      : undefined
  )

  if (row) ret.push(row)

  row = renderRow('Customer', data, cell =>
    cell.customer ? cell.customer.total : undefined
  )

  if (row) ret.push(row)

  return ret
}

const smartRound = number => {
  const n = Number(number)

  return n.toLocaleString(undefined, {
    maximumFractionDigits: n > 10 ? 0 : 1
  })
}

const LeadStatusStream = ({ data, onCellClicked, showPercentage }) => {
  const renderRow = (
    label,
    data,
    renderer,
    classHandler,
    detailsFilter,
    options = {
      noPercent: false,
      showPercentOfValid: false
    }
  ) => {
    const clickHandler = event => {
      const dataset = event.currentTarget.dataset
      const date = dataset.date
      const filter = {
        ...detailsFilter
      }

      if (date) {
        filter.lead_in_date = date
      }

      onCellClicked(filter)
    }

    const total = data.reduce((a, i) => {
      const val = renderer(i)
      return a + (isNaN(val) ? 0 : val)
    }, 0)

    const totalAll = data.reduce((a, i) => {
      return a + (isNaN(i.total) ? 0 : i.total)
    }, 0)

    const totalValid = data.reduce((a, i) => {
      return a + (isNaN(i.validTotal) ? 0 : i.validTotal)
    }, 0)

    const rowClassNames = classHandler()
    const percent =
      total && totalAll ? smartRound((100 * total) / totalAll) : undefined

    const percentOfValid =
      totalValid && total
        ? smartRound(Number((100 * total) / totalValid))
        : undefined
    let title = percent ? percent + '% of ' + totalAll : undefined

    if (options.showPercentOfValid && percentOfValid) {
      title += '\n' + percentOfValid + '% of ' + totalValid + ' Valid Leads '
    }

    return (
      <tr className={classNames('CrmAnalyticsTable__Row', rowClassNames)}>
        <td style={{ width: 150, minWidth: 150 }} className="">
          {label}
        </td>
        <td
          className={classNames('util-textRight', 'CrmAnalyticsTable__Cell')}
          onClick={clickHandler}
          title={title}
        >
          <strong>
            {showPercentage && !options.noPercent
              ? percent
                ? percent + '%'
                : ''
              : total}
          </strong>
        </td>
        {!options.summaryOnly &&
          data.map(i => {
            const value = renderer(i)
            const extraClassNames = classHandler(i)
            const percent =
              i.total && value ? smartRound((100 * value) / i.total) : undefined

            const percentOfValid =
              i.validTotal && value
                ? smartRound((100 * value) / i.validTotal)
                : undefined

            let title = percent
              ? percent + '% of ' + i.total + ' All Leads'
              : undefined
            if (options.showPercentOfValid && percentOfValid) {
              title +=
                '\n' + percentOfValid + '% of ' + i.validTotal + ' Valid Leads '
            }
            return (
              <td
                key={i.date}
                className={classNames(
                  'CrmAnalyticsTable__Cell',
                  'CrmAnalyticsTable__Cell--alignRight',
                  'util-pointer',
                  extraClassNames
                )}
                onClick={clickHandler}
                data-date={i.date}
                title={title}
              >
                {showPercentage && !options.noPercent
                  ? percent
                    ? percent + '%'
                    : ''
                  : value}
              </td>
            )
          })}
      </tr>
    )
  }

  const getStyle = date => {
    const dow = Number(moment(date).format('e'))
    const style = { width: 36, minWidth: 36 }

    if (dow === 0 || dow === 6) {
      style.backgroundColor = '#f1f1ef'
    }

    return style
  }

  return (
    <div
      className={classNames(
        'CrmAnalyticsTable__Wrapper',
        'CrmAnalyticsTable--themeCartrack'
      )}
      style={{ overflowX: 'scroll' }}
    >
      <table style={{ width: 'initial' }} className="CrmAnalyticsTable__Table">
        <thead>
          <tr>
            <th colSpan={2}> </th>
            {data.map(day => {
              return (
                <th key={day.date} style={getStyle(day.date)}>
                  <div className="util-textRight">
                    {moment(day.date).format('ddd MM-DD')}
                  </div>
                </th>
              )
            })}
          </tr>
          <tr>
            <th />
            <th>
              <strong>Total</strong>
            </th>
          </tr>
        </thead>
        <tbody className="CrmAnalyticsTable__Body">
          {renderRow(
            'Total',
            data,
            cell => {
              return cell ? cell.total : undefined
            },
            () => '--strong',
            { "skip_invalid_status": "true" },
            {
              noPercent: true
            }
          )}
          {renderRow(
            'Leads',
            data,
            cell => {
              return cell.lead ? cell.lead.total : undefined
            },
            () => '--bgGreen',
            { sales_result_code: 'lead' }
          )}

          {renderRow(
            'Raw (untouched)',
            data,
            cell => {
              return cell.lead && cell.lead.detailed.new
                ? cell.lead.detailed.new.total
                : undefined
            },
            () => '--small',
            { detailed_status_code: 'new', sales_result_code: 'lead' }
          )}
          {renderRow(
            'Touched',
            data,
            cell => {
              return cell.lead && cell.lead.detailed.touched
                ? cell.lead.detailed.touched.total
                : undefined
            },
            () => '--small',
            { detailed_status_code: 'touched', sales_result_code: 'lead' }
          )}
          {renderRow(
            'Contacted',
            data,
            cell => {
              return cell.lead && cell.lead.detailed.contacted
                ? cell.lead.detailed.contacted.total
                : undefined
            },
            () => '--small',
            { detailed_status_code: 'contacted', sales_result_code: 'lead' }
          )}
          {renderRow(
            'Invalid',
            data,
            cell => (cell.invalid ? cell.invalid.total : undefined),
            () => '--bgRed --strong',
            { sales_result_code: 'invalid' }
          )}
          {renderRow(
            'Bogus',
            data,
            cell =>
              cell.invalid && cell.invalid.detailed.bogus
                ? cell.invalid.detailed.bogus.total
                : undefined,
            () => '--small',
            { detailed_status_code: 'bogus', sales_result_code: 'invalid' }
          )}
          {renderRow(
            'Wrong Number',
            data,
            cell =>
              cell.invalid && cell.invalid.detailed.lost_wrong_number
                ? cell.invalid.detailed.lost_wrong_number.total
                : undefined,
            () => '--small',
            {
              detailed_status_code: 'lost_wrong_number',
              sales_result_code: 'invalid'
            }
          )}
          {renderRow(
            'No market',
            data,
            cell =>
              cell.invalid && cell.invalid.detailed.lost_no_market
                ? cell.invalid.detailed.lost_no_market.total
                : undefined,
            () => '--small',
            {
              detailed_status_code: 'lost_no_market',
              sales_result_code: 'invalid'
            }
          )}
          {renderRow(
              'Unreachable',
              data,
              cell =>
                  cell.invalid && cell.invalid.detailed.lost_unreachable
                      ? cell.invalid.detailed.lost_unreachable.total
                      : undefined,
              () => '--small',
              {
                detailed_status_code: 'lost_unreachable',
                sales_result_code: 'invalid'
              }
          )}
          {renderRow(
            'Valid',
            data,
            cell => {
              const res =
                (cell.valid ? cell.valid.total : 0)
              return res > 0 ? res : undefined
            },
            () => '--bgGreen --strong',
            { sales_result_code: 'valid' }
          )}
          {renderRow(
            'Validated',
            data,
            cell => {
              return cell.valid && cell.valid.detailed.validated
                ? cell.valid.detailed.validated.total
                : undefined
            },
            () => '--small',
            { detailed_status_code: 'validated', sales_result_code: 'valid' }
          )}
          {renderRow(
            'Needs Analysis',
            data,
            cell => {
              return cell.valid && cell.valid.detailed.needs_analysis
                ? cell.valid.detailed.needs_analysis.total
                : undefined
            },
            () => '--small',
            {
              detailed_status_code: 'needs_analysis',
              sales_result_code: 'valid'
            }
          )}
          {renderRow(
            'Opportunity',
            data,
            cell => {
              return cell.valid && cell.valid.detailed.opportunity
                ? cell.valid.detailed.opportunity.total
                : undefined
            },
            () => '--small',
            { detailed_status_code: 'opportunity', sales_result_code: 'valid' }
          )}
          {renderRow(
            'Quotation',
            data,
            cell => {
              return cell.valid && cell.valid.detailed.quoted
                ? cell.valid.detailed.quoted.total
                : undefined
            },
            () => '--small',
            { detailed_status_code: 'quoted', sales_result_code: 'valid' }
          )}
          {renderRow(
            'Closing',
            data,
            cell => {
              return cell.valid && cell.valid.detailed.closing
                ? cell.valid.detailed.closing.total
                : undefined
            },
            () => '--small',
            { detailed_status_code: 'closing', sales_result_code: 'valid' }
          )}
          {renderRow(
            'Lost',
            data,
            cell => (cell.lost ? cell.lost.total : undefined),
            () => '--bgRed --strong --small',
            { sales_result_code: 'lost' },
            { showPercentOfValid: true }
          )}
          {renderRow(
            'Competitor',
            data,
            cell =>
              cell.lost && cell.lost.detailed.lost_competitor
                ? cell.lost.detailed.lost_competitor.total
                : undefined,
            () => '--small',
            {
              detailed_status_code: 'lost_competitor',
              sales_result_code: 'lost'
            },
            { showPercentOfValid: true }
          )}
          {renderRow(
            'Not Interested',
            data,
            cell =>
              cell.lost && cell.lost.detailed.lost_not_interested
                ? cell.lost.detailed.lost_not_interested.total
                : undefined,
            () => '--small',
            {
              detailed_status_code: 'lost_not_interested',
              sales_result_code: 'lost'
            },
            { showPercentOfValid: true }
          )}
          {renderRow(
            'No Value for Cust',
            data,
            cell =>
              cell.lost && cell.lost.detailed.lost_no_value
                ? cell.lost.detailed.lost_no_value.total
                : undefined,
            () => '--small',
            {
              detailed_status_code: 'lost_no_value',
              sales_result_code: 'lost'
            },
            { showPercentOfValid: true }
          )}
          {renderRow(
            'Affordability',
            data,
            cell =>
              cell.lost && cell.lost.detailed.lost_affordability
                ? cell.lost.detailed.lost_affordability.total
                : undefined,
            () => '--small',
            {
              detailed_status_code: 'lost_affordability',
              sales_result_code: 'lost'
            },
            { showPercentOfValid: true }
          )}

          {renderRow(
            'Customer',
            data,
            cell => (cell.customer ? cell.customer.total : undefined),
            () => '--bgGreen --strong',
            { sales_result_code: 'customer' },
            { showPercentOfValid: true }
          )}
        </tbody>
      </table>
    </div>
  )
}

LeadStatusStream.propTypes = {
  data: arrayOf(shape({})),
  onCellClicked: func.isRequired,
  showPercentage: bool.isRequired
}

const WidgetLeadsStatusByDate = withCrmContext(props => {
  const client = useApolloClient()
  const [dataByDay, setDataByDay] = useState()
  const [excelData, setExcelData] = useState()
  const [showPercentage, setShowPercentage] = useState(false)
  const [lastFilters, setLastFilters] = useState()
  const [isLoading, setIsLoading] = useState()

  useEffect(() => {
    handleRefresh()
  }, [props.analyticsContext.filters, handleRefresh])

  const handleRefresh = useCallback(async () => {
    try {
      const filter = mapContextToFilter(props, false, undefined, {
        contextMapping: {
          latest_owner_user_uid: 'user',
          latest_owner: {
            organization_unit: 'organization_unit'
          }
        }
      })

      const variables = {
        instance_uid: props.instance_uid
          ? props.instance_uid
          : props.crmContext.instance.instance_uid,
        master_uid: props.crmContext.master.master_uid,
        filter,
        aggregate: [{ id: 'account_uid', type: 'count' }],
        groupBy: ['lead_in_date', 'sales_result_code', 'detailed_status_code']
      }

      const qlQuery = `
      query horizontal_by_lead_in_date($filter: JSON) { 
        horizontal_by_lead_in_date(filter:$filter) { 
          count
          lead_in_date
          sales_result_code
          detailed_status_code
        }
      }
      `

      setIsLoading(true)

      const res = await client.query({
        query: gql(qlQuery),
        variables,
        fetchPolicy: 'no-cache'
      })

      const sourceData = res.data?.horizontal_by_lead_in_date ?? []
      const dataByDates = sourceData.reduce((a, i) => {
        const r = { ...a }

        if (!r[i.lead_in_date]) {
          r[i.lead_in_date] = {
            date: i.lead_in_date,
            total: 0
          }
        }

        if (!r[i.lead_in_date][i.sales_result_code]) {
          r[i.lead_in_date][i.sales_result_code] = {
            total: 0,
            detailed: {}
          }
        }

        if (i.detailed_status_code !== "closed_by_admin") {
          r[i.lead_in_date].total += Number(i.count)
        }
        r[i.lead_in_date][i.sales_result_code].total += Number(i.count)

        if (
          // eslint-disable-next-line
          !r[i.lead_in_date][i.sales_result_code].detailed[
          i.detailed_status_code
          ]
        ) {
          // eslint-disable-next-line
          r[i.lead_in_date][i.sales_result_code].detailed[
            i.detailed_status_code
          ] = {
            total: 0
          }
        }

        // eslint-disable-next-line
        r[i.lead_in_date][i.sales_result_code].detailed[
          i.detailed_status_code
        ].total += Number(i.count)

        r[i.lead_in_date].validTotal =
          (r[i.lead_in_date].valid ? r[i.lead_in_date].valid.total : 0) +
          (r[i.lead_in_date].customer ? r[i.lead_in_date].customer.total : 0) +
          (r[i.lead_in_date].lost ? r[i.lead_in_date].lost.total : 0)
        return r
      }, {})

      // Generate days based on filter
      const resultsByDays = generateDateSeries(
        props.analyticsContext.filters.lead_in_date.value.$gte,
        props.analyticsContext.filters.lead_in_date.value.$lte,
        date => ({
          date,
          ...dataByDates[date]
        })
      )

      setDataByDay(resultsByDays)
      setExcelData(BuildExcel(resultsByDays))
      setLastFilters(props.analyticsContext.filters)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }, [client, props, setIsLoading])

  const handleCellClicked = filters => {
    props.onShowLeadsPopup({ type: 'account', filters })
  }

  const handleGetRawData = () => excelData
  const handleGetLayoutData = () => excelData

  const hasUserFilter =
    props.analyticsContext &&
    props.analyticsContext.filters &&
    (props.analyticsContext.filters.user_uid ||
      props.analyticsContext.filters.department_uid)

  return (
    <CrmWidget
      title="Leads Status By Lead In Date"
      onRefresh={handleRefresh}
      isLoading={isLoading}
      subtitle={
        hasUserFilter
          ? 'User or Department filter will be applied to the LATEST owner of the account'
          : undefined
      }
      padding
      autoRefresh
      onGetRawData={handleGetRawData}
      onGetLayoutData={handleGetLayoutData}
      exportFileName="crm-lead-status-by-date"
    >
      <CrmCheckbox
        input={{
          value: showPercentage,
          onChange: () => setShowPercentage(!showPercentage)
        }}
        label="Show as percentage"
      />
      {dataByDay && (
        <LeadStatusStream
          showPercentage={showPercentage}
          data={dataByDay}
          onCellClicked={handleCellClicked}
        />
      )}
    </CrmWidget>
  )
})

WidgetLeadsStatusByDate.propTypes = {
  analyticsContext: shape().isRequired,
  client: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}
const CtLeadsCurrentStatusDashboard = props => {
  const handleShowLeadsPopup = params => {
    const lparams = {
      type: params.type,
      filters: {
        ...mapContextToFilter(props),
        ...params.filters
      }
    }
    if (lparams?.filters?.user) {
      lparams.filters.latest_owner_user_uid = lparams?.filters?.user
      delete lparams.filters.user
    }
    props.onShowLeadsPopup(lparams)
  }

  const isLeadInDateSelected =
    props.analyticsContext &&
    props.analyticsContext.filters &&
    props.analyticsContext.filters.lead_in_date

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {!isLeadInDateSelected && (
            <CrmInfoBlock>Please select Lead In Date in filters</CrmInfoBlock>
          )}
          {isLeadInDateSelected && (
            <WidgetLeadsStatusByDate
              analyticsContext={props.analyticsContext}
              onShowLeadsPopup={handleShowLeadsPopup}
            />
          )}
        </div>
      </div>
    </div>
  )
}

CtLeadsCurrentStatusDashboard.propTypes = {
  analyticsContext: shape().isRequired,
  client: shape().isRequired,
  onShowLeadsPopup: func.isRequired
}

export default withCrmContext(withLeadsPopupHoc(CtLeadsCurrentStatusDashboard))
