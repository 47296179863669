import React, { Component, useState } from 'react'
import { shape, arrayOf, func, bool } from 'prop-types'
import moment from 'moment'
import { FormattedMessage, FormattedDate } from 'react-intl'
import uuidv4 from 'uuid'
import accountOrdersHoc from 'crm-modules/orders/hoc/account-orders-hoc'
import {
  CrmFormSection,
  CrmLabeledField,
  CrmInfoBlock,
  CrmButton,
  CrmJsonEditArea
} from 'crm-components'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import CreateOrderForm from './_section-orders-create-form'
import { withGeneralPermissionsContextHOC } from '@cartrack-crm/crm-core'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'
import { withCrmContext } from 'crm-core/contexts/crm-context'

const OrderEvents = ({ events, isLoading }) => {
  const [expanded, toggleExpand] = useState([])
  if (isLoading)
    return (
      <div className="CrmMdCard util-paddingSm util-textCenter">
        <FontAwesomeIcon spin icon={faSpinner} />
      </div>
    )
  return (
    <React.Fragment>
      {events.map((event, index) => (
        <div
          className="CrmMdCard"
          style={{ marginBottom: 3 }}
          key={event.event_uid}
        >
          <div className="util-paddingSm">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.event.created_date"
                  defaultMessage="Created Date"
                />
              }
            >
              {moment(event.created_time).format('YYYY/MM/DD HH:mm')}
            </CrmLabeledField>
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.event.type"
                  defaultMessage="Type"
                />
              }
            >
              {event.type}
            </CrmLabeledField>
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.event.by"
                  defaultMessage="By"
                />
              }
            >
              {event.meta.user.user_name || ''}
            </CrmLabeledField>
            <div>
              <button
                onClick={() =>
                  toggleExpand(
                    expanded.includes(index)
                      ? expanded.filter(x => x !== index)
                      : [...expanded, index]
                  )
                }
              >
                {expanded.includes(index) ? 'Hide' : 'Show'} Payload
              </button>
            </div>
          </div>
          {expanded.includes(index) && (
            <CrmJsonEditArea input={{ value: event.payload }} />
          )}
        </div>
      ))}
    </React.Fragment>
  )
}
OrderEvents.propTypes = {
  events: arrayOf(shape()),
  isLoading: bool
}

class OrderItem extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      editable: false,
      isLoading: false,
      showDropDownMenu: false
    }
  }
  handleExpand = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  toggleEdit = () => this.setState({ editable: !this.state.editable })
  get isCustomOrder() {
    const { order } = this.props
    if (!order.details) {
      return false
    }
    return order.details.custom_order
  }

  handleDelete = async () => {
    const command = {
      type: 'order.delete',
      aggregate_type: 'order',
      aggregate_uid: this.props.order.order_uid,
      payload: {}
    }
    if (confirm('Are you want to delete this order?')) {
      this.setState({ isLoading: true })
      await this.props.onRunCommand(command)
      this.props.refetch()
    }
  }

  handleEditOrder = async values => {
    let type = ''
    const payload = {
      details: {
        remark: values.remark
      }
    }
    switch (values.status) {
      case 'pending': {
        type = 'order.pending'
        payload.remark = values.remark
        payload.sale_date = values.sale_date
        payload.details.sale_date = values.sale_date
        break
      }
      case 'completed': {
        type = 'order.complete'
        payload.completed_time = values.completed_time
        payload.details.completed_time = values.completed_time
        payload.details.sale_date = values.sale_date
        break
      }
      case 'canceled': {
        type = 'order.cancel'
        payload.cancelation_reason = values.cancelation_reason
        payload.details.cancelation_reason = values.cancelation_reason
        break
      }
    }
    const command = {
      type: type,
      aggregate_type: 'order',
      aggregate_uid: this.props.order.order_uid,
      payload: payload
    }
    await this.props.onRunCommand(command)
    this.toggleEdit()
    this.props.refetch()
  }

  unassignAccount = async () => {
    if (confirm('Do you want to unassign account?')) {
      this.setState({ showDropDownMenu: false, isLoading: true })
      const command = {
        type: 'order.unassign_account',
        aggregate_type: 'order',
        aggregate_uid: this.props.order.order_uid
      }
      try {
        await this.props.onRunCommand(command)
      } catch (error) {
        this.setState({ isLoading: false })
      }
      this.props.refetch()
    }
  }

  renderMenu() {
    const toggleDropdown = () =>
      this.setState({
        showDropDownMenu: !this.state.showDropDownMenu
      })
    const menus = []
    if (this.props.isAllowToEdit && !this.isCustomOrder) {
      menus.push({
        label: 'Unassign Account',
        onClick: this.unassignAccount
      })
    }
    if (this.props.isAllowToEdit) {
      menus.push({
        label: 'Delete',
        onClick: this.handleDelete
      })
    }
    if (this.props.isAllowToEdit) {
      menus.push({
        label: 'Event History',
        onClick: this.props.loadEventHistory
      })
    }
    if (menus.length === 0) {
      return <></>
    }

    return (
      <div className="util-flexCenterContent">
        <div className="Ellipse-Dropdown" onClick={toggleDropdown}>
          <div className="icon-background">
            <i className="fa fa-ellipsis-v" />
          </div>
          {this.state.showDropDownMenu && (
            <ul>
              {menus.map(menu => (
                <li key={menu.label} onClick={menu.onClick}>
                  <span>{menu.label}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    )
  }

  renderDetail() {
    const { order } = this.props
    const details = order.details
    if (this.state.isLoading) {
      return <OrderLoading />
    }
    return (
      <div style={{ marginBottom: 0, width: '100%', position: 'relative' }}>
        <div style={{ position: 'absolute', right: '0px' }}>
          {this.renderMenu()}
        </div>

        <div className="row util-marginTop" style={{ marginRight: '25px' }}>
          <div className="col-md-2">
            <CrmLabeledField label="Status">
              {order.status_code}
            </CrmLabeledField>
          </div>

          <div className="col-md-3">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.date"
                  defaultMessage="Fitment Date"
                />
              }
            >
              {order.completed_time && (
                <FormattedDate value={order.completed_time} />
              )}
            </CrmLabeledField>
          </div>

          <div className="col-md-3">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.owner"
                  defaultMessage="Owner"
                />
              }
            >
              {order && order.owner ? order.owner.full_name : ''}
            </CrmLabeledField>
          </div>

          <div className="col-md-2">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.organization_unit"
                  defaultMessage="Unit"
                />
              }
            >
              {order && order.organization_unit
                ? order.organization_unit.name
                : ''}
            </CrmLabeledField>
          </div>

          <div className="col-md-2">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.vehicle"
                  defaultMessage="Vehicle"
                />
              }
            >
              {details ? details.vehicle_registration : ''}
            </CrmLabeledField>
          </div>
        </div>
      </div>
    )
  }

  renderExpanded() {
    const { order } = this.props
    const details = order.details
    return (
      this.state.expanded && (
        <div style={{ marginBottom: 0 }} className="row">
          <div className="col-md-3">
            <CrmLabeledField label="Contract Status">
              {order.contract_status_code}
            </CrmLabeledField>
          </div>

          {order.repairs_count && (
            <div className="col-md-3">
              <CrmLabeledField label="Repairs Count">
                {order.repairs_count}
              </CrmLabeledField>
            </div>
          )}

          {order.terminated_time && (
            <div className="col-md-3">
              <CrmLabeledField label="Terminated Date">
                {moment(order.terminated_time).format('YYYY/MM/DD')}
              </CrmLabeledField>
            </div>
          )}

          {order.canceled_time && (
            <div className="col-md-3">
              <CrmLabeledField label="Canceled Date">
                {moment(order.canceled_time).format('YYYY/MM/DD')}
              </CrmLabeledField>
            </div>
          )}

          {details && details.remark && (
            <div className="col-md-3">
              <CrmLabeledField
                label={
                  <FormattedMessage
                    id="crm.ui.account.fitments.remark"
                    defaultMessage="Remark"
                  />
                }
              >
                {details.remark}
              </CrmLabeledField>
            </div>
          )}

          {details && details.cancelation_reason && (
            <div className="col-md-3">
              <CrmLabeledField
                label={
                  <FormattedMessage
                    id="crm.ui.account.fitments.cancelation_reason"
                    defaultMessage="Cancelation Reason"
                  />
                }
              >
                {details.cancelation_reason}
              </CrmLabeledField>
            </div>
          )}

          <div className="col-md-3">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.date"
                  defaultMessage="Sale Date"
                />
              }
            >
              {details && <FormattedDate value={details.sale_date} />}
            </CrmLabeledField>
          </div>

          <div className="col-md-3">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.fitments.fitter"
                  defaultMessage="Fitter"
                />
              }
            >
              {details ? details.fitter_name : ''}
            </CrmLabeledField>
          </div>

          <div className="col-md-3">
            <CrmLabeledField label="Price code/Vas">
              {details && details.contract_package
                ? details.contract_package
                : '-'}
              {','}
              {details && details.value_added_services
                ? details.value_added_services
                : '-'}
            </CrmLabeledField>
          </div>

          <div className="col-md-3">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.hardware"
                  defaultMessage="Hardware"
                />
              }
            >
              {details?.hardware_price &&
                parseFloat(Number(details.hardware_price)).toFixed(2)}
            </CrmLabeledField>
          </div>

          <div className="col-md-3">
            <CrmLabeledField
              label={
                <FormattedMessage
                  id="crm.ui.account.subscription"
                  defaultMessage="Subscription"
                />
              }
            >
              {details?.subscription &&
                parseFloat(Number(details.subscription)).toFixed(2)}
            </CrmLabeledField>
          </div>
        </div>
      )
    )
  }
  render() {
    const { order } = this.props
    const details = order.details
    if (this.state.isLoading) {
      return <OrderLoading />
    }
    return (
      <React.Fragment>
        <div className="CrmMdCard">
          {!order.owner && (
            <div className="util-textWhite util-textCenter util-bgRed util-paddingSm">
              No Owner for this fitment!
            </div>
          )}
          {!this.state.editable && this.renderDetail()}
          {this.state.editable && (
            <CreateOrderForm
              enableCanceled
              isAllowToEdit={this.props.isAllowToEdit}
              value={{
                status: order.status_code,
                sale_date: details.sale_date,
                completed_time: order.completed_time,
                remark: details.remark,
                cancelation_reason: details.cancelation_reason
              }}
              onDelete={this.handleDelete}
              onSubmit={this.handleEditOrder}
            />
          )}
          {!this.state.editable && this.renderExpanded()}

          <div className="row">
            <div className="col-md-3">
              {this.isCustomOrder && (
                <CrmButton
                  small
                  style={{ marginLeft: 4 }}
                  onClick={this.toggleEdit}
                  label={this.state.editable ? 'Close' : 'Edit'}
                />
              )}
            </div>
            <div className="col-md-6">
              <div
                onClick={this.handleExpand}
                className="util-pointer util-hooverable util-paddingSm util-textCenter"
              >
                <FontAwesomeIcon
                  icon={this.state.expanded ? faChevronUp : faChevronDown}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row util-marginBottom">
          <div className="col-md-2"></div>
        </div>
      </React.Fragment>
    )
  }
}
OrderItem.propTypes = {
  order: shape().isRequired,
  loadEventHistory: func
}
class SectionOrders extends Component {
  static propTypes = {
    orders: arrayOf(shape())
  }
  static defaultProps = {
    orders: undefined
  }
  constructor(props) {
    super(props)
    this.state = {
      selectedStatus: 'pending',
      openCreateForm: false,
      isLinkingOrders: false
    }
  }
  componentDidMount() {
    this.props.fetchAttributes()
  }
  get isEnableManualOrder() {
    return this.props.attributes.some(
      item => item.code === 'enableManualOrders'
    )
  }

  get isAllowToEdit() {
    const has_permission = this.props.generalPermissionsContext.hasPermissionByType(
      PERMISSIONS_TYPES.ORDERS_MANAGE
    )
    return has_permission
  }

  handleSelectStatus = event => {
    this.setState({ selectedStatus: event.currentTarget.id })
  }

  handleCreateOrder = async values => {
    const { remark, status, sale_date, completed_time } = values
    const payload = {
      status_code: status,
      account_uid: this.props.account.account_uid,
      skip_search_by_cams: true,
      details: {
        remark: remark,
        custom_order: true,
        sale_date,
        completed_time
      }
    }
    switch (status) {
      case 'pending': {
        payload.sale_date = sale_date
        break
      }
      case 'completed': {
        payload.completed_time = completed_time
        break
      }
      case 'canceled': {
      }
    }
    const command = {
      type: 'order.create_custom_order',
      aggregate_type: 'order',
      aggregate_uid: uuidv4(),
      payload: payload
    }

    await this.props.onRunCommand(command)
    this.setState({ openCreateForm: false })
    this.props.refetch()
  }

  renderWarnings() {
    const hasOrderWithoutOwner = this.props.orders
      ? this.props.orders.reduce((a, i) => a || !i.owner_user_uid, false)
      : false
    return (
      <React.Fragment>
        {hasOrderWithoutOwner && (
          <CrmInfoBlock error>
            Warning - there are fitments without owner
          </CrmInfoBlock>
        )}
      </React.Fragment>
    )
  }
  renderTabs() {
    const summaries = this.props.orders
      ? this.props.orders.reduce((a, i) => {
          if (!a[i.status_code]) {
            a[i.status_code] = 0
          }
          a[i.status_code] += 1
          return a
        }, {})
      : {}

    return (
      <React.Fragment>
        {this.renderWarnings()}
        <div className="util-flexRow CrmTabs">
          <div
            onClick={this.handleSelectStatus}
            id="pending"
            className={`CrmTabs-tab ${
              this.state.selectedStatus === 'pending'
                ? 'CrmTabs-tab--selected'
                : ''
            }`}
          >
            <FormattedMessage
              id="crm.ui.account.pending"
              defaultMessage="Pending"
            />{' '}
            {summaries.pending}
          </div>
          <div
            onClick={this.handleSelectStatus}
            id="completed"
            className={`CrmTabs-tab ${
              this.state.selectedStatus === 'completed'
                ? 'CrmTabs-tab--selected'
                : ''
            }`}
          >
            <FormattedMessage
              id="crm.ui.account.completed"
              defaultMessage="Completed"
            />{' '}
            {summaries.completed}
          </div>
          <div
            onClick={this.handleSelectStatus}
            id="canceled"
            className={`CrmTabs-tab ${
              this.state.selectedStatus === 'canceled'
                ? 'CrmTabs-tab--selected'
                : ''
            }`}
          >
            <FormattedMessage
              id="crm.ui.account.canceled"
              defaultMessage="Canceled"
            />{' '}
            {summaries.canceled}
          </div>
        </div>
      </React.Fragment>
    )
  }

  loadEventHistory = async order => {
    this.props.handleShowFitmentHistory(order)
  }
  renderOrders() {
    if (!this.props.orders) {
      return ''
    }
    const sorted = this.props.orders.filter(
      f => f.status_code === this.state.selectedStatus
    )
    sorted.sort(
      (a, b) => -moment(a.completed_time).diff(moment(b.completed_time))
    )
    return sorted.map(order => {
      return (
        <OrderItem
          isAllowToEdit={this.isAllowToEdit}
          key={order.order_uid}
          order={order}
          loadEventHistory={() => this.loadEventHistory(order)}
          onRunCommand={this.props.onRunCommand}
          refetch={this.props.refetch}
        />
      )
    })
  }

  handleReassign = async () => {
    await this.props.onReassignOrders()
    // toastr.confirm(`Are you sure you want to merge account? `, {
    //   onOk: async () = > {  }
    // })
  }

  handleConvertToCustomer = async () => {
    this.setState({ isConvertingToCustomer: true })
    try {
      await this.props.onConvertToCustomer()
    } catch (error) {
      console.log(error)
    }
    this.setState({ isConvertingToCustomer: false })
  }
  handleLinkOrdersByCams = async () => {
    this.setState({ isLinkingOrders: true })
    try {
      await this.props.onLinkOrdersByCams()
    } catch (error) {
      console.log(error)
    }
    this.setState({ isLinkingOrders: false })
  }
  renderActions() {
    return (
      <div>
        <CrmButton
          label="Reassign Orders"
          small
          grouped
          onClick={this.handleReassign}
        />
        <CrmButton
          isSaving={this.state.isLinkingOrders}
          label="Find Orders by CAMS"
          small
          grouped
          onClick={this.handleLinkOrdersByCams}
        />

        <CrmButton
          isSaving={this.state.isConvertingToCustomer}
          label="Try to convert to customer"
          small
          grouped
          onClick={this.handleConvertToCustomer}
        />
      </div>
    )
  }

  toggleCreateForm = () => {
    this.setState({ openCreateForm: !this.state.openCreateForm })
  }

  get createFormActions() {
    return [
      {
        label: 'Close',
        onClick: this.toggleCreateForm
      }
    ]
  }

  get sectionActions() {
    if (this.isEnableManualOrder) {
      return [
        {
          label: 'Create Order',
          onClick: this.toggleCreateForm
        }
      ]
    }
    return []
  }

  render() {
    const use_cams_integration = this.props.crmContext.getAttributeValueByCode(
      'use_cams_integration'
    )
    return (
      <React.Fragment>
        {this.isEnableManualOrder && this.state.openCreateForm && (
          <CrmFormSection
            actions={this.createFormActions}
            sectionName="Create new order"
          >
            <CreateOrderForm onSubmit={this.handleCreateOrder} />
          </CrmFormSection>
        )}
        <CrmFormSection
          actions={this.sectionActions}
          sectionName={
            <FormattedMessage
              id="crm.ui.account.fitments"
              defaultMessage="Fitments"
            />
          }
        >
          {/* <fieldset className="Form-fieldset CrmForm-form-fieldset"> */}
          <div className="util-flexCol">
            {this.renderTabs()}
            <div
              style={{
                padding: 20,
                height: 'auto',
                flex: '1',
                overflowY: 'scroll'
              }}
            >
              {this.renderOrders()}
            </div>
            {use_cams_integration && this.renderActions()}
          </div>
          {/* </fieldset> */}
        </CrmFormSection>
      </React.Fragment>
    )
  }
}

const qlqListDomainEvents = gql`
  query listDomainEvents($aggregates: [JSON]) {
    listDomainEvents(aggregates: $aggregates) {
      aggregate_type
      type
      created_time
      payload
      meta
      event_uid
    }
  }
`
const OrderLoading = () => (
  <div
    style={{
      height: 70,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    className="CrmMdCard util-paddingSm util-textCenter"
  >
    <FontAwesomeIcon spin icon={faSpinner} />
  </div>
)

SectionOrders.propTypes = {
  account: shape().isRequired,
  generalPermissionsContext: shape({}).isRequired,
  crmContext: shape()
}

SectionOrders.defaultProps = {}

const mapStateToProps = state => {
  return {
    attributes: state.crm.attribute.lists
  }
}
const mapDispatchToProps = dispatch => ({
  fetchAttributes: () => dispatch({ type: 'FETCH_ATTRIBUTES' })
})
export default accountOrdersHoc(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withGeneralPermissionsContextHOC(withCrmContext(SectionOrders)))
)
