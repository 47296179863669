import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { func, shape, arrayOf } from 'prop-types'
import {
  getViews,
  getSummaries,
  selectAccountsView
} from 'crm-duxs/accounts-list-reducer'
import { getSelectedView } from 'crm-duxs/filters-reducer'

class AccountsViewsBar extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { hasQualifyWidget: false }
  }

  handleSelectView = event => {
    const viewCode = event.currentTarget.id
    const view = this.props.views.find(v => v.code === viewCode)

    if (view) {
      this.props.doSelectAccountsView(view)
    }
  }

  _renderBlock = view => {
    const selected =
      this.props.selectedView && this.props.selectedView.code === view.code
    const summary = this.props.summaries
      ? this.props.summaries.find(s => s.code === view.code)
      : undefined

    return (
      <div
        key={view.name}
        id={view.code}
        className={`AccountsViewButton util-marginLeft util-marginTop util-marginRight util-flexRow
        ${selected ? 'AccountsViewButton--selected' : ''}
        ${view.className ? view.className : ''}`}
        onClick={this.handleSelectView}
      >
        <div className="AccountsViewButton-title  ">{view.name}</div>
        <div className="util-flexRow">
          <div
            className="util-flexGrow util-textCenter util-marginLeft util-marginRight"
            style={{ fontSize: 28 }}
          >
            {summary ? summary.count : '-'}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="util-flexRow util-paddingSm">
        {this.props.views.map(this._renderBlock)}
      </div>
    )
  }
}

AccountsViewsBar.propTypes = {
  doSelectAccountsView: func.isRequired,
  selectedView: shape({}),
  views: arrayOf(shape()),
  summaries: arrayOf(shape())
}

AccountsViewsBar.defaultProps = {
  selectedView: undefined,
  views: [],
  summaries: []
}

function mapStateToProps(state, ownProps) {
  return {
    selectedView: getSelectedView(state, ownProps.accountsListCode),
    views: getViews(state, ownProps.accountsListCode),
    summaries: getSummaries(state, ownProps.accountsListCode)
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    doSelectAccountsView: view =>
      dispatch(selectAccountsView(ownProps.accountsListCode, view))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsViewsBar)
