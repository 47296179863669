/* eslint-disable*/
import React from 'react'
import { arrayOf } from 'prop-types'
import FlatTable from './flat-table.jsx'

const CrmDynamicTable = ({ data, columns }) => {
  let lcolumns = []
  // build columns
  console.log('CrmDynamicTable', data)
  if (data) {
    const fields = data.reduce((a, v) => {
      const ret = [...a, ...Object.keys(v).filter(nk => a.indexOf(nk) === -1)]
      return ret
    }, [])
    console.log('reduced fields', fields)
    lcolumns = fields.map(field => ({ accessor: field, Header: field }))
    console.log('columns', lcolumns)
  }

  return <FlatTable columns={lcolumns} data={data} />
}
CrmDynamicTable.propTypes = {
  data: arrayOf({}).isRequired,
  columns: arrayOf({})
}
CrmDynamicTable.defaultProps = {
  columns: undefined
}
export default CrmDynamicTable
