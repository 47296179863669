import React from 'react'
import { shape, string } from 'prop-types'

import AccountPage from '../account/components/account-page.jsx'
import processPageHoc from './process-page-ql-hoc'

const ProcessPageInner = props => (
  <AccountPage
    accountUid={props.accountUid}
    processUid={props.processUid}
    process={props.process}
    historyMode={props.historyMode}
  />
)

ProcessPageInner.propTypes = {
  accountUid: string.isRequired,
  processUid: string.isRequired,
  process: shape()
}
ProcessPageInner.defaultProps = {
  process: undefined
}
const ProspectPageInnerQl = processPageHoc(ProcessPageInner)
const ProcessPage = props => (
  <ProspectPageInnerQl
    accountUid={props.match.params.accountId}
    processUid={props.match.params.processUid}
    historyMode={props.historyMode}
  />
)

ProcessPage.propTypes = {
  match: shape().isRequired
}

export default ProcessPage
