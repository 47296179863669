/* eslint-disable camelcase */
import React from 'react'
import { func } from 'prop-types'
import { withApollo } from 'react-apollo'
import { qlqfetchAccount } from 'crm-data/accounts'
import crmGenericCommandQlHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

// eslint-disable-next-line
const qualifyButtonHoc = WrappedComponent => {
  class Inner extends React.PureComponent {
    static propTypes = {
      onRunCommand: func.isRequired
    }
    static defaultProps = {
      isEditing: false
    }
    constructor(props) {
      super(props)
      this.state = {
        isSaving: false,
        isActive: false
      }
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })

    handleClickDo = () => {
      // console.log('handleClickDo')
      if (!this.state.isActive) {
        this.setState({ isActive: true })
      }
    }
    handleCancel = () => {
      this.setState({ isActive: false })
    }

    handleSave = async qualification => {
      // console.log('handleSave', qualification)
      await this.setState({ isSaving: true })
      try {
        const command = {
          type: 'account.qualify_lead',
          aggregate_uid: qualification.account_uid,
          aggregate_type: 'account',
          payload: {
            qualification
          }
        }
        const refetchQueries = [
          {
            query: qlqfetchAccount,
            variables: { account_uid: qualification.account_uid }
          }
        ]
        const res = await this.props.onRunCommand(command, { refetchQueries })
        await this.setState({ isSaving: false })
        return res
      } catch (err) {
        console.error(err)
        throw err
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onSave={this.handleSave}
          onClickDo={this.handleClickDo}
          onCancel={this.handleCancel}
          isSaving={this.state.isSaving}
          isActive={this.state.isActive}
        />
      )
    }
  }
  const withCommand = crmGenericCommandQlHoc(Inner)
  return withApollo(withCommand)
}

export default qualifyButtonHoc
