/* eslint-disable */
import fetch from 'fetch-everywhere'
import AsyncStorage from '@callstack/async-storage' // eslint-disable-line
import errorReport from './crm-api-warning'
import { silentRefreshToken } from './auth'

export const errorTypes = {
  INVALID: 'INVALID',
  TIMEOUT: 'TIMEOUT'
}

const crmAppVersion =
  process && process.env ? process.env.APP_VERSION : undefined
const crmAppName = process.env.APP_NAME ? process.env.APP_NAME : ''

function getCurrentUser() {
  const userJson = window.localStorage.getItem('user')
  if (userJson === undefined || userJson === null || userJson.length === 0) {
    return undefined
  }
  try {
    const user = JSON.parse(userJson)
    return user
  } catch (err) {
    console.error('Wrong structure of user object in session storage')
    return undefined
  }
}

export function getApiRootUrl() {
  let apiUrl = process.env.CRM_API_URL
    ? process.env.CRM_API_URL
    : 'http://localhost/jsonrpc/'
  if (window.localStorage.getItem('crm_api_url')) {
    apiUrl = window.localStorage.getItem('crm_api_url')
  }
  return apiUrl
}

export function getCrmInstanceUid() {
  if (window.localStorage.getItem('crm_instance')) {
    const crmInstance = JSON.parse(window.localStorage.getItem('crm_instance'))
    return crmInstance.instance_uid
  }
  return undefined
}

export function getCrmLocale() {
  if (window.localStorage.getItem('locale')) {
    return window.localStorage.getItem('locale')
  }

  return 'en'
}

export function getCrmCountryCode() {
  if (window.localStorage.getItem('crm_instance')) {
    const crmInstance = JSON.parse(window.localStorage.getItem('crm_instance'))
    return crmInstance.country_code
  }
  return undefined
}

function _fetchCrm(endpoint, body, headers, authToken,report = false) {
  const lbody = {
    ...body,
    crm_instance_uid: getCrmInstanceUid(),
    crm_locale: getCrmLocale(),
    crm_client_version: crmAppVersion,
    crm_name: crmAppName
  }
  const apiUrl = getApiRootUrl() + endpoint
  let lAuthToken = authToken
  if (!lAuthToken) {
    const user = JSON.parse(window.localStorage.getItem('user'))
    lAuthToken = (user && user.authToken) || ''
  }
  let callEndpoint = `${apiUrl}?PHPSESSID=${lAuthToken}`
  if(report){
    callEndpoint = `${apiUrl}?reports=true`
  }
  return fetch(callEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('crmAccessToken')}`,
      'Content-Language': `${window.localStorage.getItem('locale')}`,
      ...headers
    },
    timeout: 5000,
    body: JSON.stringify(lbody)
  }).then(response => {
    return response
  })
}

export default function apiCaller(method, params = {}, authToken) {
  const body = {
    version: '2.0',
    method,
    params: {
      ...params
    }
  }
  return _fetchCrm('crm.php', body, undefined, authToken).then(
    res => {
      return res.json().then(json => {
        if (res.ok && !json.error) return json.result
        console.error('API Error', json)
        if (json.error === 'refresh_token_revoke') {
          window.crmLogout()
        }
        if (params.retrunErrorFormat === 'json') {
          return {
            error: json.error,
            type: errorTypes.INVALID
          }
        } else {
          throw new Error({
            err: json.error,
            type: errorTypes.INVALID
          })
        }
      })
    },
    err => {
      throw new Error({
        err,
        type: errorTypes.TIMEOUT
      })
    }
  )
}

export function callGooglePlaceSerach(query) {
  const url = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${query}&key=AIzaSyCpmHlnBsCwMdAWxe9VyFYOdEG43YCiods`
  return new Promise(resolve => {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        res.json().then(json => {
          resolve(json)
        })
      })
      .catch(error => {
        console.error('Google place search error', error)
        throw new Error('Google place search error')
      })
  })
}

export async function callQLApi(query = '', variables, params, report = false) {
  await silentRefreshToken()
  const user = getCurrentUser()
  // Can we run this query without instance ?
  const noInstance = params && params.noInstance ? params.noInstance : false
  if (user === undefined) {
    // Promise.reject()
    throw new Error("Can't call API query - not authorized")
  }
  let body
  if (typeof query === 'string') {
    body = {
      query,
      variables
    }
  } else {
    body = query
  }
  body.crm_instance_uid = getCrmInstanceUid()
  if (body.crm_instance_uid === undefined && !noInstance) {
    return new Promise(resolve => {
      resolve({})
    })
  }
  body.crm_is_debug_mode = Boolean(
    window.localStorage.getItem('crm_is_debug_mode')
  )
  return new Promise((resolve, reject) => {
    _fetchCrm('crm_ql.php', body, null, null, report).then(res => {
      if (res.status === 200) {
        window.localStorage.removeItem('message')
      }
      if (res.status === 401) {
        console.log('Doing logout')
        if (window && window.localStorage) {
          window.localStorage.removeItem('crmUser')
          window.localStorage.removeItem('crmAccessToken')
          window.localStorage.removeItem('crmRefreshToken')
          window.localStorage.removeItem('user')
          window.location.pathname = '/login'
          window.crmLogout('401 Unauthorize')
        }
        throw new Error('Unauthorized')
      }
      return res.json().then(json => {
        if (json.errors && json.errors.length > 0) {
          console.log('result contains errors - throwing Exception')
          errorReport({
            error: json,
            request: body,
            tags: {
              type: 'graphql'
            },
            response: res
          })
          reject({
            message: json.errors[0].message,
            errors: json.errors
          })
        }
        if (json.data) {
          if (json.data.error) {
            errorReport({
              error: json.data.error,
              request: body,
              tags: {
                type: 'graphql'
              },
              response: res
            })
            console.log('QL contains error')
            console.dir(json.data.error)
          }
          resolve(json.data)
        } else {
          if(json.errors && json.errors.message) {
            if(json.errors.message == 'access_token_expired' ) {
              window.localStorage.removeItem('crmUser')
              window.localStorage.removeItem('crmAccessToken')
              window.localStorage.removeItem('crmRefreshToken')
              window.localStorage.removeItem('user')
              window.location.pathname = '/login'
            }
          }
          errorReport({
            error: json,
            request: body,
            tags: {
              type: 'graphql'
            },
            response: res
          })
          const e = {
            err: json.error,
            type: errorTypes.INVALID
          }
          reject(e)
          throw new Error(e)
        }
      })
    })
  })
}

export async function callQLApiFormData(formData) {
  await silentRefreshToken()
  const user = getCurrentUser()
  if (user === undefined) {
    throw new Error("Can't call API query - not authorized")
  }
  formData.append('crm_instance_uid', getCrmInstanceUid())

  return new Promise(resolve => {
    _fetchCrm('crm_ql.php', formData)
      .then(
        res => {
          res.json().then(json => {
            if (json.data) {
              resolve(json.data)
            } else {
              console.error('QL API ERROR', json)
              throw new Error({
                err: json.error,
                type: errorTypes.INVALID
              })
            }
          })
        },
        err => {
          throw new Error({
            err,
            type: errorTypes.TIMEOUT
          })
        }
      )
      .catch(err => {
        throw new Error({
          err,
          type: errorTypes.TIMEOUT
        })
      })
  })
}

export async function callQLApiFormDataNew(formData) {
  await silentRefreshToken()
  const user = getCurrentUser()
  if (user === undefined) {
    throw new Error("Can't call API query - not authorized")
  }
  const apiUrl = getApiRootUrl() + 'crm_ql.php'
  formData.append('crm_instance_uid', getCrmInstanceUid())

  return new Promise(resolve => {
    fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem(
          'crmAccessToken'
        )}`,
        'Content-Language': `${window.localStorage.getItem('locale')}`
      },
      timeout: 5000,
      body: formData
    }).then(
      res => {
        res.json().then(json => {
          if (!json.errors) {
            resolve(json.data)
          } else {
            console.error('QL API ERROR', json)
            resolve({
              err: json.errors[0].message,
              type: errorTypes.INVALID
            })
          }
        })
      },
      err => {
        throw new Error({
          err,
          type: errorTypes.TIMEOUT
        })
      }
    )
  })
}

export async function callQLWithMetaApi(query = '', variables) {
  await silentRefreshToken()
  let body
  if (typeof query === 'string') {
    body = {
      query,
      variables
    }
  } else {
    body = query
  }
  body.crm_instance_uid = getCrmInstanceUid()
  body.crm_is_debug_mode = Boolean(
    window.localStorage.getItem('crm_is_debug_mode')
  )
  return new Promise(resolve => {
    _fetchCrm('crm_ql.php', body).then(
      res => {
        res.json().then(json => {
          if (json.data) {
            resolve({
              data: json.data,
              meta: json.meta
            })
          } else {
            console.error('QL API ERROR', json)
            throw new Error({
              err: json.error,
              type: errorTypes.INVALID
            })
          }
        })
      },
      err => {
        throw new Error({
          err,
          type: errorTypes.TIMEOUT
        })
      }
    )
  })
}
