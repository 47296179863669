import React from 'react'
import { ActivityItemContiner } from './shared.styled'
import {
  CrmActivityItem,
  CrmActivityNotation,
  CrmActivityCategory,
  CrmActivityAction
} from '.'
import { CrmButtonGroup, CrmButton } from '@cartrack-crm/ui'
import CrmActivityContent from './activity-content-meeting'

const ActivityMeeting = (props) => {
  const { activity } = props
  let meetingMinuteIcon = 'file'
  const minutes = activity.meeting_minutes
  if (minutes.meeting_minutes_uid && minutes.completed_at) {
    meetingMinuteIcon = 'check-circle'
  }

  const meeting_minutes_code = activity?.activity_type?.meeting_minutes_code
    ? activity.activity_type.meeting_minutes_code
    : undefined

  console.log('meeting_minutes_code', meeting_minutes_code)

  const renderBtn = () => meeting_minutes_code ? (<React.Fragment>
    <CrmButton
      icon={meetingMinuteIcon}
      onClick={props.onMinutesOfMeeting}
    />
    <CrmButton icon="pencil-alt" onClick={props.onEditActivity} />
  </React.Fragment>) : (<CrmButton icon="pencil-alt" onClick={props.onEditActivity} />)

  return (
    <ActivityItemContiner isSuccess={activity.activity_status?.is_success}>
      <CrmActivityNotation
        activity_type={activity.activity_type}
        direction={activity.direction}
      />
      <CrmActivityItem isSuccess={activity.activity_status?.is_success}>
        <CrmActivityCategory
          label={activity.activity_type.name}
          sequence={activity.seq_same_type_success_user}
          createdTime={activity.created_time}
          isCustomer={props.isCustomer}
        />
        <CrmActivityContent
          {...activity}
          account={props.account}
          _addrFormat={props._addrFormat}
        />
        <CrmActivityAction
          showActionsOnHover
          activityStatus={activity.activity_status}
        >
          <CrmButtonGroup small>
            {renderBtn()}
          </CrmButtonGroup>
        </CrmActivityAction>
      </CrmActivityItem>
    </ActivityItemContiner>
  )
}

export default ActivityMeeting
