import React from 'react'
import { func, object, string } from 'prop-types'

const Icon = props => {
  const { name, className, ...otherProps } = props
  return (
    <i
      className={`fa fa-${name} ${className}`}
      aria-hidden="true"
      {...otherProps}
    />
  )
}

Icon.propTypes = {
  className: string,
  name: string.isRequired,
  onClick: func,
  title: string,
  style: object
}

Icon.defaultProps = {
  className: '',
  onClick: () => {},
  style: null,
  title: ''
}

export default Icon
