/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { arrayOf, func, shape } from 'prop-types'
import { FormattedTime, FormattedDate } from 'react-intl'
import { CrmFullpageContainer, CrmHbox, CrmButton } from 'crm-components'

import ManagerTasksTable from './manager-tasks-table.jsx'

class ManagerProcessesList extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentWillMount() {}

  handleClickRefresh = () => {
    this.props.listProcesses()
  }

  render() {
    console.log('ManagerProcessesList render', this.props)

    return (
      <CrmFullpageContainer vbox hasPadding>
        <div className="row">
          <div className="col-md-4">
            <h5>Processes and Tasks Management</h5>
          </div>
        </div>
        <div className="util-flexGrow util-flexRow">
          <div className="col-md-8">
            <ManagerTasksTable exportFileName="Manager_processes" />
          </div>
          <div className="col-md-4">Toos</div>
        </div>
      </CrmFullpageContainer>
    )
  }
}

ManagerProcessesList.propTypes = {
  processes: arrayOf(shape({})).isRequired,
  listProcesses: func.isRequired
}

ManagerProcessesList.defaultProps = {
  list: []
}

export default ManagerProcessesList
