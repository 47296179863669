import React, { useState, useEffect } from 'react'
import { shape, func, string, number, arrayOf, bool } from 'prop-types'
import { CrmButton, CrmTextInput, CrmDropdown } from 'crm-components'
import { Spacer } from 'util-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LanguageSelect from 'crm-components/modules/settings/language-select.jsx'
import { injectIntl } from 'react-intl'
import { JsonToTable } from 'crm-utils/common-utils'

const ReadMode = ({ value, label, intl }) => {
  let formatValue = value
  if (label === 'language') {
    formatValue = intl.formatMessage({
      id: `dropdown.option.${value}`,
      defaultMessage: value
    })
  }
  return <div className="LabeledField-value">{formatValue}</div>
}
ReadMode.propTypes = {
  value: string.isRequired,
  label: string.isRequired,
  intl: shape({}).isRequired
}

const TranslationItem = props => {
  const { isEditing, attributes, index, intl } = props
  const [item, setItem] = useState(null)

  useEffect(() => {
    setItem({ ...props.data })
    return () => {}
  }, [props])

  const handleLanguageChange = async value => {
    const updated = { ...item, language: value }
    setItem(updated)
    props.onItemChange(updated, index)
  }

  const handleNameChange = async (value, title, attributeDefinition) => {
    const updated = { ...item, attributeCode: attributeDefinition.value }
    setItem(updated)
    props.onItemChange(updated, index)
  }

  const handleValueChange = async event => {
    const updated = { ...item, value: event.currentTarget.value }
    setItem(updated)
    props.onItemChange(updated, index)
  }

  const handleDeleteItem = async event => {
    props.onDeleteItem(index)
  }

  const nameOptions = attributes.reduce((ret, item) => {
    ret.push({
      name: item.label,
      value: item.code
    })
    return ret
  }, [])

  if (!item) return <tr />
  return (
    <tr className="stripe">
      <td>
        {!isEditing && (
          <ReadMode intl={intl} value={item.attributeCode} label="name" />
        )}
        {isEditing && (
          <CrmDropdown
            title={'Name'}
            options={nameOptions}
            input={{
              value: item.attributeCode,
              onChange: handleNameChange
            }}
          />
        )}
      </td>
      <td>
        {!isEditing && (
          <ReadMode intl={intl} value={item.language} label="language" />
        )}
        {isEditing && (
          <LanguageSelect
            onChange={handleLanguageChange}
            activeOption={item.language}
          />
        )}
      </td>
      <td>
        {!isEditing && (
          <ReadMode intl={intl} value={item.value} label="value" />
        )}
        {isEditing && (
          <CrmTextInput
            placeholder={`Value`}
            onChange={handleValueChange}
            value={item.value}
            className="CrmTextInput-noRightPadding"
          />
        )}
      </td>
      <td>
        <div className="util-flexRowRight">
          {isEditing && (
            <div className="util-marginRight">
              <CrmButton
                id={index}
                type="button"
                xsmall
                icon="trash"
                onClick={handleDeleteItem}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}
TranslationItem.propTypes = {
  data: shape({}).isRequired,
  isEditing: bool.isRequired,
  attributes: arrayOf(shape({})).isRequired,
  onItemChange: func.isRequired,
  onDeleteItem: func.isRequired,
  index: number.isRequired,
  intl: shape({}).isRequired
}
TranslationItem.defaultProps = {}

const CrmTranslations = props => {
  const { attributes, localeData } = props
  const [isEditing, setIsEditing] = useState(false)
  const [items, setItems] = useState([])
  const [showJson, setShowJson] = useState(false)

  const handleSave = async () => {
    await props.onChange(items)
    setIsEditing(false)
  }

  const handleAddNewItem = async () => {
    const newItem = {
      attributeCode: '',
      value: '',
      language: 'en'
    }
    setItems([...items, newItem])
  }

  const handleStartEdit = async () => {
    if (items.length === 0) {
      await handleAddNewItem()
    }
    setIsEditing(true)
  }

  const handleCancel = async () => {
    const oldItems = [...localeData]
    setItems(oldItems)
    setIsEditing(false)
  }

  const handleItemChange = async (newItem, index) => {
    const newItems = [...items]
    newItems[index] = newItem
    setItems(newItems)
  }

  const handleDeleteItem = async index => {
    const newItems = [...items].reduce((ret, item, i) => {
      if (i !== index) ret.push(item)
      return ret
    }, [])
    setItems(newItems)
  }

  const handleShowJson = () => {
    setShowJson(!showJson)
  }

  useEffect(() => {
    setItems(localeData && Array.isArray(localeData) ? [...localeData] : [])
    return () => {}
  }, [localeData])

  return (
    <div className="CrmInlineEditable util-hooverableContainer util-paddingSm util-marginTop util-marginBottom">
      <div className={`${isEditing ? 'util-paddingSm' : ''}`}>
        <div className="util-flexRow">
          <div className="util-flexGrow util-textCenter">
            {items.length === 0 && (
              <span>No Translations - Click the right icon to edit</span>
            )}
            {items.length > 0 && (
              <span className="util-textBold">Translations</span>
            )}
          </div>
          <div>
            <div
              className="util-hooverOp util-textRight"
              title="Click to edit translations"
              onClick={handleStartEdit}
            >
              <FontAwesomeIcon className="util-marginLeft" icon="pencil-alt" />
            </div>
          </div>
        </div>
        {items.length > 0 && (
          <div className="translationTable util-marginTop">
            <table className="util-fullWidth">
              <thead className="util-alignLeft LabeledField-label">
                <tr>
                  <th className="util-valignTop" width="180px">
                    Attribute
                  </th>
                  <th className="util-valignTop" width="120px">
                    Language
                  </th>
                  <th className="util-valignTop">Value</th>
                  <th className="util-valignTop" width="30px" />
                </tr>
              </thead>
              <tbody>
                {items.map((v, i) => {
                  return (
                    <TranslationItem
                      key={i}
                      index={i}
                      data={v}
                      isEditing={isEditing}
                      attributes={attributes}
                      onItemChange={handleItemChange}
                      onDeleteItem={handleDeleteItem}
                      intl={props.intl}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        <Spacer height={`${isEditing ? '30px' : '10px'}`} />
        {isEditing && (
          <div className="util-flexRow">
            <div>
              <CrmButton
                small
                label="+ Add new translation"
                onClick={handleAddNewItem}
              />
            </div>
            <div className="util-marginLeft">
              <CrmButton
                xsmall
                label={`${showJson ? 'Hide json' : 'Show json'}`}
                onClick={handleShowJson}
              />
            </div>
            <div className="util-flexGrow" />
            <div className="util-marginLeft">
              <CrmButton small label={'Cancel'} onClick={handleCancel} />
            </div>
            <div className="util-marginLeft">
              <CrmButton
                small
                type="primary"
                label={'OK'}
                onClick={handleSave}
              />
            </div>
          </div>
        )}
        {isEditing && showJson && (
          <div>
            <Spacer height="20px" />
            <div className="json-to-table">
              <div className="wrapper">
                <JsonToTable json={items} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
CrmTranslations.propTypes = {
  onChange: func.isRequired,
  localeData: arrayOf(shape({})),
  attributes: arrayOf(shape({})).isRequired,
  intl: shape({}).isRequired
}
CrmTranslations.defaultProps = {
  localeData: []
}

const injectedCrmTranslations = injectIntl(CrmTranslations)
export default injectedCrmTranslations
