import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'

import { qlqResourcePoolsList } from 'crm-data/resource-pools'
import { CrmFormInputDropdown } from 'crm-components'
import { useCrmContext } from '@cartrack-crm/crm-core/src/contexts/crm-context'
import { Icon } from 'util-components'

type UserGroupSelectProps = {
  disabled?: boolean
  filter?: object
}

const UserGroupSelect = (props: UserGroupSelectProps) => {
  const [showInactive, setShowInactive] = useState(false)
  const { instance, master } = useCrmContext()
  const { data, error, loading } = useQuery(qlqResourcePoolsList, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: { ...(props?.filter ?? {}), include_deleted: showInactive },
      instance: instance.instance_uid,
      master_uid: master.master_uid
    }
  })

  const handleShowInactive = () => {
    setShowInactive(!showInactive)
  }

  const toolboxRenderer = () =>
    props?.disabled ? (
      <span />
    ) : (
      <Icon
        name="trash"
        onClick={handleShowInactive}
        style={{
          opacity: showInactive ? '1.0' : '0.4',
          position: 'absolute',
          right: '32px',
          top: '10px',
          width: '12px'
        }}
        title="Show inactive user groups"
      />
    )

  const fieldProps = {
    ...props,
    options:
      loading || error || !data?.users_groups
        ? []
        : data.users_groups.map(d => ({
            is_deleted: d.is_deleted,
            name: d.name,
            value: d.resource_pool_uid
          })),
    toolboxRenderer
  }

  return <CrmFormInputDropdown {...fieldProps} />
}

UserGroupSelect.defaultProps = {
  disabled: false
}

export default UserGroupSelect
