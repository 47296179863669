import React, { createContext, useContext, useState } from 'react'
import { node, shape } from 'prop-types'
import { hasPermission, hasPermissionByPermissionType } from '@cartrack-crm/crm-core'
import { qlqfetchAccountPermissions } from 'crm-data/accounts'
import { useQuery } from '@apollo/react-hooks'
import { AccountPermissionsContextType } from '@cartrack-crm/crm-core'

export const AccountPermissionsContext = createContext<AccountPermissionsContextType>({
  hasPermissionTo: () => {},
  hasPermissionByType: () => {}
})

export const AccountPermissionsContextProvider = ({ children, account_permissions, account_uid }) => {
  const { data, error, loading } = useQuery(qlqfetchAccountPermissions, {
    variables: {
      account_uid: account_uid
    },
    skip: account_permissions || (!account_permissions && !account_uid),
    fetchPolicy: 'no-cache'
  })
  account_permissions = account_permissions ? account_permissions : data ? data.account.permissions : []

  const hasPermissionTo = (permissionCode, accessMode, module) => {
    return hasPermission(account_permissions, permissionCode, accessMode, module)
  }

  const hasPermissionByType = (permission_type, match_modules) =>
    hasPermissionByPermissionType(account_permissions, permission_type, match_modules)

  const accountPermissionsContext = {
    account_permissions,
    account_uid,
    hasPermissionTo,
    hasPermissionByType
  }

  return (
    <AccountPermissionsContext.Provider value={accountPermissionsContext}>
      {children}
    </AccountPermissionsContext.Provider>
  )
}

AccountPermissionsContextProvider.propTypes = {
  children: node.isRequired
}

export const useAccountPermissionsContext = () => useContext(AccountPermissionsContext)

export const withAccountPermissionsContextHOC = (Component: any) => {
  return (props: any) => {
    return <Component accountPermissionsContext={useAccountPermissionsContext()} {...props} />
  }
}
