import React from 'react'
import { groupDataByMonth } from 'crm-utils/data-utils'
import moment from 'moment'
import { HeadCount, DashboardResponse, GQLQueryRes } from 'crm-types'
import { createDashboardFormats, useGetSaleProCesses, getWidgetAnalyticContext } from '../../dashboards/tools'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export interface widgetNewValidLeadsHocProps {
  avgNewValidLeadsCurrentMonthPerPerson: number | null
  avgNewValidLeadsPassedMonthData: number | any[any]
  avgNewValidLeadsPersonPassedMonth: number | null
  avgNewValidLeadsThreeMonth: number
  footerName: string | null
  footerNamePerPerson: string | null
  isLoading?: boolean
  refetchData?: () => any
  threeMonthAgoNewValidLeads: any[any]
  totalNewValidLeads: number
  onClickDetail?: Function
  mainCaption?: string
}

export type useGetNewValidLeads = (numberOfMonth?: number, headcount?: HeadCount[]) => DashboardResponse & GQLQueryRes

export const useGetNewValidLeadsByMonth: useGetNewValidLeads = (numberOfMonth = 3, headcounts) => {
  const analyticsContext = getWidgetAnalyticContext()
  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const filter = {
    assigned_to_user_date: {
      $gte: startDate,
      $lte: endDate
    },
    owner_user_uid: headcounts ? undefined : userUid,
    organization_unit
  }

  const aggregate = [{ id: 'process_uid', type: 'count' }]
  const groupBy = ['assigned_to_user_date', 'owner_user_uid']
  const { listSaleProcesses, refetch, loading } = useGetSaleProCesses(filter, groupBy, aggregate)

  const grouped = groupDataByMonth(listSaleProcesses, 'assigned_to_user_date', 'process_uid')

  return {
    refetch,
    loading,
    ...createDashboardFormats(grouped, numberOfMonth, moment(endDate).format('YYYY-MM'), headcounts, 'Agent')
  }
}

const widgetNewValidLeadsHoc = (Component: any) => {
  const Wrapper = props => {
    const { headcounts } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      avgPassedMonths,
      avgPerPersonPassedMonth,
      dataAvgPerPersonPassedMonth,
      dataPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      mainCaption,
      refetch,
      totalLatestMonth
    } = useGetNewValidLeadsByMonth(3, headcounts)
    const onClickDetail = monthSelected => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)

      const filters = {
        organization_unit,
        owner_user_uid: headcounts ? undefined : userUid,
        assigned_to_user_date: convertMonthToDateRange(endDate)
      }

      if (monthSelected) {
        filters.assigned_to_user_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'sales_process', filters })
    }

    return (
      <Component
        avgNewValidLeadsCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgNewValidLeadsPassedMonthData={dataAvgPerPersonPassedMonth}
        avgNewValidLeadsPersonPassedMonth={avgPerPersonPassedMonth}
        avgNewValidLeadsThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        onClickDetail={onClickDetail}
        refetchData={() => refetch()}
        threeMonthAgoNewValidLeads={dataPassedMonths}
        totalNewValidLeads={totalLatestMonth}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetNewValidLeadsHoc
