/* eslint-disable camelcase */

import { get } from 'lodash'
import {
  nameOfFilters,
  TOGGLE_SHOW_MORE_FILTER,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_SUCCESS,
  SELECT_ACCOUNT,
  SET_OPTIONS_ACCOUNT_TABLE,
  SET_CURRENT_PAGE,
  TOGGLE_FILTER_SUCCESS,
  SET_PAGE_SIZE,
  SET_SORT_CONDITIONS,
  SET_CURRENT_FILTER_VALUE,
  SET_FIX_FILTER,
  SET_NEAR_BY_RESULT
} from 'crm-duxs/accounts-reducer'

export const FETCH_ACCOUNTS_LIST_COUNT = 'FETCH_ACCOUNTS_LIST_COUNT'
export const FETCH_ACCOUNTS_LIST_COUNT_SUCCESS =
  'FETCH_ACCOUNTS_LIST_COUNT_SUCCESS'
export const SELECT_ACCOUNTS_VIEW = 'SELECT_ACCOUNTS_VIEW'
export const SET_ACCOUNTS_VIEW = 'SET_ACCOUNTS_VIEW'
export const SET_ACCOUNTS_SUMMARY = 'SET_ACCOUNTS_SUMMARY'

export const accountsListReducerFactory = (accountsListCode, initial) => {
  const initialState = {
    accounts: [],
    currentPage: 0,
    pageSize: 20,
    sorts: [],
    countRows: -1,
    queries: [],
    selectedAccount: undefined,
    isLoading: false,
    qualificationTypes: [],
    isShowMoreFilter: false,
    isEnableAddressFilter: false,
    isEnableContactDetailFilter: false,
    isEnableSaleFilter: false,
    isEnableTMFilter: false,
    isEnableQualificationFilter: false,
    isEnableAccountAttibuteFilter: false,
    isEnableLeadDatabaseFilter: false,
    isEnableDuringProcessFilter: false,
    isEnableLocalName: false,
    isEnableCampaignFilter: false,
    isEnableLeadSourceFilter: false,
    isEnableLeadInDateFilter: false,
    isEnableCreatedTimeFilter: false,
    isEnableCreatedByUserFilter: false,
    isEnableNearByFilter: false,
    isPristine: true,
    onlyOwnAccounts: true,
    fixFilters: [],
    currentFilters: [],
    nearbyKeyword: undefined,
    nearByItems: [],

    selectedView: { code: 'all', name: 'All' },
    ...initial
  }

  // prettier-ignore
  return function (state = initialState, action) {
    if (action.payload && action.payload.accountsListCode && action.payload.accountsListCode === accountsListCode) {
      switch (action.type) {
        case FETCH_ACCOUNTS: {
          return { ...state, isLoading: true }
        }

        case SET_NEAR_BY_RESULT: {
          return {
            ...state,
            nearByItems: action.payload.result.results,
            nearbyKeyword: action.payload.query
          }
        }

        case TOGGLE_SHOW_MORE_FILTER: {
          return { ...state, isShowMoreFilter: !state.isShowMoreFilter }
        }

        case SET_CURRENT_FILTER_VALUE: {
          const currentFilters = Object.assign([], state.currentFilters)
          const index = state.currentFilters.findIndex(filter => filter.code === action.payload.filterName)

          if (index === -1) {
            currentFilters.push({
              code: action.payload.filterName,
              code_option: 'ilike',
              params: action.payload.conditions
            })
          } else {
            currentFilters[index] = {
              code: action.payload.filterName,
              code_option: 'ilike',
              params: action.payload.conditions
            }
          }
          return { ...state, currentFilters }
        }

        case SET_FIX_FILTER: {
          return { ...state, fixFilters: action.payload.fixFilters }
        }

        case TOGGLE_FILTER_SUCCESS: {
          let newQuery = Object.assign([], state.currentFilters)
          if (!state.isShowMoreFilter === false) {
            newQuery = newQuery.filter(item => item.code !== nameOfFilters[action.payload.filterStatusName])
          }

          return {
            ...state,
            // eslint-disable-next-line
            [action.payload.filterStatusName]: !state[action.payload.filterStatusName],
            currentFilters: newQuery
          }
        }

        case FETCH_ACCOUNTS_SUCCESS: {
          return {
            ...state,
            isLoading: false,
            isPristine: false,
            accounts: action.payload.accounts,
            countRows: Number(action.payload.countRows)
          }
        }

        case SET_CURRENT_PAGE: {
          return {
            ...state,
            currentPage: action.payload.currentPage
          }
        }

        case SET_SORT_CONDITIONS: {
          return {
            ...state,
            sorts: action.payload.sortConditions
          }
        }

        case SET_PAGE_SIZE: {
          return {
            ...state,
            pageSize: action.payload.pageSize
          }
        }

        case SET_ACCOUNTS_VIEW: {
          const newState = {
            ...state,
            selectedView: action.payload.accountsView
          }
          return newState
        }
        case FETCH_ACCOUNTS_LIST_COUNT_SUCCESS: {
          return { ...state, summaries: action.payload.summaries }
        }
        default:
          return state
      }
    } else return state
  }
}

export function fetchAccounts(query, params) {
  return {
    type: FETCH_ACCOUNTS,
    payload: {
      query,
      pagination_options: {
        page: params.page,
        pageSize: params.pageSize,
        sortConditions: params.sort
      },
      accountsListCode: params.accountsListCode
    }
  }
}

export function selectAccount(account) {
  return {
    type: SELECT_ACCOUNT,
    payload: {
      account
    }
  }
}

export function setOptionsAccountTable(
  currentPage,
  pageSize,
  sorts,
  accountsListCode
) {
  return {
    type: SET_OPTIONS_ACCOUNT_TABLE,
    payload: {
      currentPage,
      pageSize,
      sorts,
      accountsListCode
    }
  }
}

export function setCurrentPage(currentPage, accountsListCode) {
  return {
    type: SET_CURRENT_PAGE,
    payload: {
      currentPage,
      accountsListCode
    }
  }
}

export const fetchAccountListCounts = accountsListCode => ({
  type: FETCH_ACCOUNTS_LIST_COUNT,
  payload: { accountsListCode }
})

export const selectAccountsView = (accountsListCode, accountsView) => ({
  type: SELECT_ACCOUNTS_VIEW,
  payload: {
    accountsListCode,
    accountsView
  }
})

export const getAccounts = state => get(state, 'crm.accounts.accounts', [])
export const getSelectedAccount = state =>
  get(state, 'crm.accounts.selectedAccount', null)
export const getCountRows = (state, listCode) =>
  get(state, 'crm.accounts.accountsLists.listCode.countRows', 0)

export const getQueries = (state, listCode) =>
  get(state, 'crm.accounts.accountsLists.listCode.queries', 0)
export const getCurrentPage = (state, listCode) =>
  get(state, 'crm.accounts.accountsLists.listCode.currentPage', 0)
export const getPageSize = (state, listCode) =>
  get(state, 'crm.accounts.accountsLists.listCode.pageSize', 0)
export const getPageSorts = (state, listCode) =>
  get(state, 'crm.accounts.accountsLists.listCode.sorts', 0)

export const getIsLoading = (state, listCode) =>
  get(state, 'crm.accounts.accountsLists.listCode.isLoading', 0)

export const getIsPristine = (state, listCode) =>
  get(state, `crm.accountsList_${listCode}.isPristine`, false)

export const getQualificationTypes = state =>
  get(state, 'crm.accounts.qualificationTypes', null)
export const getAccountsListByCode = (state, listCode) => {
  if (listCode) {
    return get(state, 'crm.accounts.accountsLists.listCode', [])
  }

  return get(state, 'crm.accounts.accounts', [])
}
export const getViews = (state, listCode) =>
  listCode
    ? get(state, `crm.accountsList_${listCode}.views`, undefined)
    : undefined
export const getSummaries = (state, listCode) =>
  listCode
    ? get(state, `crm.accountsList_${listCode}.summaries`, undefined)
    : undefined
export const getFixFilters = (state, listCode) =>
  get(state, `crm.accountsList_${listCode}.fixFilters`, undefined)
export const getCurrentFilters = (state, listCode) =>
  get(state, `crm.accountsList_${listCode}.currentFilters`, undefined)
export const getOnlyOwnAccounts = (state, listCode) =>
  get(state, `crm.accountsList_${listCode}.onlyOwnAccounts`, undefined)
