import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Link } from 'react-router-dom'
import { getTrProps } from './tasks-table-utils'
import genericTableFactory from 'crm-components/ql/table/crm-generic-ql-table.jsx'
import { qltaskListV2 } from 'crm-data/tasks'

const qlOptions = {
  options: ownProps => ({
    variables: {
      filter: ownProps.filter
    }
  }),
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.tasks_todo_paged : []
  })
}

const Table = genericTableFactory({
  query: qltaskListV2,
  qlOptions
})

export const availableColumns = [
  {
    Header: 'Account Name',
    accessor: 'account_name',
    Cell: row => {
      return(
      <Link
        to={`/crm/account/${row.original.account_uid}${
          row.original.process_uid ? `/process/${row.original.process_uid}` : ''
        }?do_task_uid=${row.original.task_uid}`}
      >
       {row.original.account && row.original.account.name}
      </Link>
    )}
  },
  {
    Header: 'Process Type',
    accessor: 'process_type_name',
    maxWidth: 120,
     Cell: row => {
      return(
      <span className={new Date(row.original.due_date) < Date.now() ? 'util-textRed' : ''}>
          {row.original.account && row.original.process_type_name}
      </span>
    )}
  },
  {
    Header: 'Current Step',
    accessor: 'current_step_name',
     Cell: row => {
      return(
      <span className={new Date(row.original.due_date) < Date.now() ? 'util-textRed' : ''}>
          {row.original.account && row.original.account.latest_step_type_name}
      </span>
    )},
    maxWidth: 120
  },
  {
    Header: 'Task Name',
    accessor: 'task_name',
      Cell: row => {
      return(
      <span className={new Date(row.original.due_date) < Date.now() ? 'util-textRed' : ''}>
          {row.original.name && row.original.name}
      </span>
    )},
    maxWidth: 120
  },
  {
    Header: 'Assigned To',
    accessor: 'task_owner_name',
    Cell: row => (
      <span className={new Date(row.original.due_date) < Date.now() ? 'util-textRed' : ''}>
        {row.original.assignee_type === 'user'
          ? row.original.assignee_user_name
          : row.original.assignee_group_name}
      </span>
    )
  },
  {
    Header: 'Due Date',
    accessor: 'due_date',
    Cell: row =>
      row.original.due_date ? (
        <span className={new Date(row.original.due_date) < Date.now() ? 'util-textRed' : ''}>
          <FormattedDate value={row.original.due_date} />{' '}
          <FormattedTime value={row.original.due_date} />
        </span>
      ) : (
        <span />
      )
  }
]

const TableInner = props => (
  <Table {...props} columns={availableColumns} getTrProps={getTrProps} />
)

export default TableInner
