import { hhmmss } from 'crm-utils/common-utils'

const agentsLiveMonitoringB2B = {
  type: 'item',
  label: 'Agent Live Monitoring B2B',
  code: 'agentLiveMonitoringB2B',
  dashboard_uid: 'agentLiveMonitoringB2B',
  defaultFilters: {
    account__lead_campaign__transaction_type_code: {
      value: 'B2B',
      label: 'B2B'
    },
    organization_unit: {
      department_type_uid: {
        value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
        label: 'Telesales'
      }
    }
  },
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'agentLiveMonitoringB2B',
        def_version: 5,
        rev: '2019-07-05',
        title: 'Agent Live Monitoring Activity B2B',
        description_: 'Default Filter - Department Telesales, Transaction Type B2B',
        content: {
          data: {
            dataSources: [
              {
                code: 'ActivitiesListQM',
                name: 'ActivitiesListQM',
                type: 'QM',
                qlQuery: `
                query agent_live_monitoring($filter: JSON) {
                  agent_live_monitoring(filter: $filter) {
                      user_uid
                      calls_unique_leads
                      total_work_time
                      current_idle_time
                      last_call_start_time
                      first_call_start_time
                      total_time_on_phone
                      total_calls_time
                      total_calls
                      not_answered_calls
                      answered_calls
                      total_idle_time
                      user { 
                          is_active 
                          name
                          contract_start_date
                          department {
                              name
                          }
                      }
                  }
              }
                `,
                filter: {
                  start_date: '$today'
                },
                qlEdges: false,
                qlRoot: 'agent_live_monitoring'
              }
            ]
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 5,
                mob_fix_columns: 2,
                props: {
                  generate_summary: true,
                  generate_average: true,
                  initialSort: {
                    id: 'user.contract_start_date',
                    order: 'asc'
                  },
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 50
                    },
                    {
                      Header: 'RM',
                      accessor: 'user.name',
                      classNameHandler: row => (row.original?.user?.is_active ? '' : 'util-opacity50'),
                      /* eslint-disable no-restricted-globals*/
                      Cell: row => row?.original?.user?.name,
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user_uid: 'user_uid'
                      }
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'user.contract_start_date',
                      Cell: row => row?.original?.user?.contract_start_date,
                      width: 80
                    },
                    {
                      Header: 'First Call Time',
                      accessor: 'first_call_start_time',
                      width: 60,
                      type: 'time',
                      format: 'HH:mm',
                      align: 'right'
                    },
                    {
                      Header: 'Last Call Time',
                      accessor: 'last_call_start_time',
                      width: 60,
                      type: 'time',
                      format: 'HH:mm',
                      align: 'right'
                    },
                    {
                      Header: 'Current Idle Time',
                      accessor: 'current_idle_time',
                      width: 60,
                      type: 'interval',
                      align: 'right'
                    },
                    {
                      Header: 'Answered Calls',
                      accessor: 'answered_calls',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        status: { is_success: true },
                        type: { medium_code: 'phone_call' },
                        start_date: '$today'
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Not Answered Calls',
                      accessor: 'not_answered_calls',
                      width: 60,
                      type: 'number',
                      align: 'right',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        status: { is_success: false },
                        type: { medium_code: 'phone_call' },
                        start_date: '$today'
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Total Calls',
                      accessor: 'total_calls',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: { medium_code: 'phone_call' },
                        start_date: '$today'
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Total Time on Phone Today',
                      accessor: 'total_time_on_phone',
                      Cell: row => {
                        if (row?.original?.total_time_on_phone) {
                          hhmmss(row.original.total_time_on_phone)
                        }
                      },
                      width: 60,
                      type: 'interval',
                      detailsPopup: 'disposition',
                      detailsFilter: {
                        status: { is_success: true },
                        type: { medium_code: 'phone_call' },
                        start_date: '$today'
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Total Talk Time Today',
                      accessor: 'total_calls_time',
                      width: 60,
                      type: 'interval',
                      align: 'right',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: { medium_code: 'phone_call' },
                        start_date: '$today'
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    },
                    {
                      Header: 'Total Work Time Today',
                      accessor: 'total_work_time',
                      type: 'interval',
                      align: 'right',
                      width: 60
                    },
                    {
                      Header: 'Total Idle Time Today',
                      align: 'right',
                      accessor: 'total_idle_time',
                      type: 'interval',
                      width: 60
                    },
                    {
                      Header: 'Leads Dialed',
                      accessor: 'calls_unique_leads',
                      width: 60,
                      type: 'number',
                      detailsPopup: 'activities',
                      detailsFilter: {
                        type: { medium_code: 'phone_call' },
                        start_date: '$today',
                        array_agg: {
                          group_by: ['account_uid', 'primary_performer_user_uid'],
                          key_condition: 'activity_uid'
                        }
                      },
                      detailsFilterMapping: {
                        performer: { user_uid: 'user_uid' }
                      },
                      className: 'util-textRight util-pointer'
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'ActivitiesListQM'
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}
export default agentsLiveMonitoringB2B
