/* eslint-disable camelcase */
import React from 'react'
import { func, shape, bool } from 'prop-types'
import { qlqAccountTasks } from 'crm-data/tasks'
import crmGenericCommandHoc, {
  cqCommandPropTypes
} from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'

const taskCompleteHoc = WrappedComponent => {
  class TaskCompleteHoc extends React.PureComponent {
    static propTypes = {
      process: shape({}),
      onCancel: func,
      ...cqCommandPropTypes
    }
    static defaultProps = {
      isEditing: false,
      gotoPage: undefined,
      process: undefined,
      newAccountUid: undefined,
      onCancel: () => {}
    }
    constructor(props) {
      super(props)
      this.state = { isSaving: false }
    }

    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, () => {
          resolve()
        })
      })

    handleCompleteTask = async pTask => {
      // console.log('handleCompleteTask', pTask)

      const command = {
        type: 'task.manualComplete',
        aggregate_type: 'task',
        aggregate_uid: pTask.task_uid,
        payload: {}
      }

      // console.log('command', command)
      try {
        const refetchQueries = [
          {
            query: qlqAccountTasks,
            variables: {
              account_uid: pTask.account_uid
            }
          }
        ]
        const res = await this.props.onRunCommand(command, {
          refetchQueries
        })

        // console.log('Task Complete HOC reslt', res)
        return res.data.cqCommand
      } catch (err) {
        console.log('Complete task error', err)
        throw err
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onCompleteTask={this.handleCompleteTask}
          isSavingCompleteTask={this.props.isRunningCommand}
        />
      )
    }
  }
  return crmGenericCommandHoc(TaskCompleteHoc)
}

export const taskCompleteHocPropTypes = {
  onCompleteTask: func.isRequired,
  isSavingCompleteTask: bool.isRequired
}
export default taskCompleteHoc
