import { CrmButton, CrmTextInput, CrmLabeledField } from 'crm-components'
import { shape, string, func, bool, oneOfType, number } from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import editContactPersonHoc from 'crm-modules/accounts/hoc/edit-contact-person-hoc.js'
import React, { PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import SectionContactDetails from './section-contact-details.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ContactPersonItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isValidEmail: this.getIsValidEmail(props.person)
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.person !== nextProps.person) {
      this.setState({
        isValidEmail: this.getIsValidEmail(nextProps.person)
      })
    }
  }

  getIsValidEmail = person => {
    let hasEmail = false
    let hasEmailValue = false
    const emails = this.getPersonEmails(person)
    emails.map(v => {
      hasEmail = true
      hasEmailValue = false
      if (v.value) {
        hasEmailValue = true
      }
    })
    const isValid = hasEmail && hasEmailValue
    return isValid
  }

  getPersonEmails = person => {
    const result = []
    person.contact_details &&
      person.contact_details.map(v => {
        if (v.kind === 'email') {
          result.push(v)
        }
      })

    return result
  }

  getEmailErrorMessage = () => {
    return this.props.intl.formatMessage({
      id: `crm.activity.person_selected_for_a_meeting_must_have_email`,
      defaultMessage: 'Person selected for a meeting must have email'
    })
  }

  handleSave = async event => {
    if (this.props.isSaving) {
      return
    }

    // validate email
    const { guestsValidationRules } = this.props
    if (guestsValidationRules.must_have_email && !this.state.isValidEmail) {
      alert(this.getEmailErrorMessage())
      return
    }

    const error_email = this.props.validateEmails(this.props.person)
    if (error_email) {
      toastr.error('Person has invalid email')
      return
    }

    if (this.props.person.isNew) {
      // call api add person
      try {
        const res = await this.props.onSaveNewPerson(this.props.person)
        await this.props.switchPersonToReadMode()
        await this.props.updatePersonsUI(this.props.index, this.props.person)
        toastr.success('Contact person added')
      } catch (err) {
        toastr.error('Error adding contact person')
      }
    } else {
      // call api update person
      try {
        const res = await this.props.onEditPerson(this.props.person, this.props.activityUid)
        await this.props.switchPersonToReadMode()
        toastr.success('Contact person changed')
      } catch (err) {
        toastr.error('Error saving contact person')
      }
    }
  }

  handleCancel = () => {
    if (this.props.isSaving) {
      return
    }
    if (this.props.person.account_person_uid) {
      // if edit existing, switch to read mode
      if (this.props.handleCancelEditPersonOnMeetingForm) {
        this.props.handleCancelEditPersonOnMeetingForm()
      } else {
        this.props.toggleEditMode(false)
      }
    } else {
      // if new contact, remove from array
      this.props.onCancelAddPerson(this.props.index, this.props.person)
    }
  }

  handleRemove = () => {
    if (this.props.isSaving) {
      return
    }
    const toastrConfirmOptions = {
      onOk: async () => {
        // call api remove person
        try {
          const res = await this.props.onRemovePerson(this.props.person)
          this.props.removePersonUI(this.props.index, this.props.person)
          toastr.success('Contact person deleted')
        } catch (err) {
          toastr.error('Delete person error')
        }
      }
    }
    toastr.confirm('Are you sure you want to delete this contact person ? ', toastrConfirmOptions)
  }

  handleStartEdit = () => {
    this.props.toggleEditMode(true)
  }

  render () {
    const { person, guestsValidationRules } = this.props
    const isEditMode = person.isNew || person.isEditing
    const editModeClass = isEditMode ? 'CrmMdCard' : ''
    const { isValidEmail } = this.state
    return (
      <React.Fragment>
        {guestsValidationRules.must_have_email && isValidEmail === false && (
          <div className="CrmForm-field-error util-flexRowCenter">* {this.getEmailErrorMessage()}</div>
        )}

        <div
          className="CrmInlineEditable util-hooverableContainer"
          style={{ position: 'relative', paddingRight: '0px' }}
        >
          <div style={{ padding: '15px' }} className={editModeClass}>
            {isEditMode ? (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6" style={{ margin: '5px 0px' }}>
                        <CrmTextInput
                          placeholder={<FormattedMessage id="global.name" defaultMessage="Name" />}
                          placeholderId=""
                          name="name"
                          id="name"
                          input={{
                            value: person.name,
                            onChange: this.props.onPersonContactFieldChanged
                          }}
                        />
                      </div>
                      <div className="col-md-6" style={{ margin: '5px 0px' }}>
                        <CrmTextInput
                          placeholder={
                            <FormattedMessage id="crm.ui.contact.form.department" defaultMessage="Department" />
                          }
                          placeholderId=""
                          name="department"
                          id="department"
                          input={{
                            value: person.department,
                            onChange: this.props.onPersonContactFieldChanged
                          }}
                        />
                      </div>
                      <div className="col-md-6" style={{ margin: '5px 0px' }}>
                        <CrmTextInput
                          placeholder={<FormattedMessage id="crm.account.designation" defaultMessage="Designation" />}
                          placeholderId=""
                          name="description"
                          id="description"
                          input={{
                            value: person.description,
                            onChange: this.props.onPersonContactFieldChanged
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-5">
                      <CrmLabeledField
                        label={<FormattedMessage id="global.name" defaultMessage={'Name'} />}
                        valueClassName="ActivityListItem-headerItem-value"
                        noMargin
                      >
                        {person.name ? person.name : '-'}
                      </CrmLabeledField>
                    </div>
                    <div className="col-md-5">
                      <CrmLabeledField
                        label={
                          person.department ? (
                            person.department
                          ) : (
                              <FormattedMessage id="crm.ui.account.contact.department" defaultMessage={'Department'} />
                            )
                        }
                        valueClassName="ActivityListItem-headerItem-value"
                        noMargin
                      >
                        {person.description ? person.description : '-'}
                      </CrmLabeledField>
                    </div>
                  </div>
                </React.Fragment>
              )}
            {!isEditMode && <div style={{ marginBottom: '15px' }} />}
            {person.contact_details && (
              <SectionContactDetails
                key="person-contact-details"
                person={person}
                personContactDetails={person.contact_details}
                onPersonContactDetailChanged={this.props.onPersonContactDetailChanged}
                isSavingPerson={this.props.isSaving}
              />
            )}
            {(this.props.isSaving || isEditMode) && (
              <React.Fragment>
                <div className="util-flexRowSpaceBetween">
                  <div>
                    {person.isEditing && !this.props.disableRemovePerson && (
                      <CrmButton
                        onClick={this.handleRemove}
                        icon={this.props.isSaving ? '' : 'trash'}
                        iconButton
                        isSaving={this.props.isSaving}
                        enabled={!this.props.isSaving}
                      />
                    )}
                  </div>
                  <div className="util-flexRowRight">
                    <CrmButton
                      style={{ marginRight: 5 }}
                      onClick={this.handleCancel}
                      icon="close"
                      iconButton
                      enabled={!this.props.isSaving}
                    />
                    <CrmButton
                      style={{ marginLeft: 5, marginRight: 5 }}
                      onClick={this.handleSave}
                      type="primary"
                      icon={this.props.isSaving ? '' : 'check'}
                      iconButton
                      isSaving={this.props.isSaving}
                      enabled={!this.props.isSaving}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          {!isEditMode && (
            <div
              className=" util-hooverableContent util-hooverOp"
              style={{
                position: 'absolute',
                right: 10,
                top: 5,
                color: undefined
              }}
              title={'Edit'}
              onClick={this.handleStartEdit}
            >
              <FontAwesomeIcon icon="pencil-alt" />
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

ContactPersonItem.propTypes = {
  isSaving: bool.isRequired,
  person: shape({}).isRequired,
  onPersonContactDetailChanged: func.isRequired,
  onPersonContactFieldChanged: func.isRequired,
  onCancelAddPerson: func.isRequired,
  toggleEditMode: func.isRequired,
  removePersonUI: func.isRequired,
  onRemovePerson: func.isRequired,
  onSaveNewPerson: func.isRequired,
  switchPersonToReadMode: func.isRequired,
  updatePersonsUI: func.isRequired,
  onEditPerson: func.isRequired,
  index: oneOfType([string, number]).isRequired,
  intl: shape({}).isRequired,
  guestsValidationRules: shape({}),
  handleCancelEditPersonOnMeetingForm: func,
  activityUid: string,
  validateEmails: func.isRequired,
  disableRemovePerson: bool
}

ContactPersonItem.defaultProps = {
  guestsValidationRules: {},
  handleCancelEditPersonOnMeetingForm: undefined,
  activityUid: undefined,
  disableRemovePerson: false
}

export default injectIntl(editContactPersonHoc(ContactPersonItem))
