import cloneDeep from 'clone-deep'
import moment, { MomentInput } from 'moment'
import camelCase from 'camelcase'
import { isDate } from 'util'

export function getDateFormat(time) {
  return moment(new Date(time)).format('DD/MM/YYYY')
}

export const formatDateFilterValue = filter => {
  if (!filter) {
    return 'empty'
  }

  var value = filter.value ? filter.value : filter

  if (value.$lte && value.$gte) {
    return getDateFormat(value.$gte) + ' - ' + getDateFormat(value.$lte)
  }

  return value
}

export const mapContextToFilter = (props, mapDates = true, mapActivityDateAs = undefined, params = undefined) => {
  let filter: any = {}

  if (props && props.analyticsContext && props.analyticsContext.filters) {
    const supportedFields = Object.keys(props.analyticsContext.filters)

    supportedFields.forEach(field => {
      if (field !== 'activity_date' && field !== 'lead_in_date') {
        if (props.analyticsContext.filters[field] && !filter.hasOwnProperty(field)) {
          if (props.analyticsContext.filters[field].value) {
            if (
              Array.isArray(props.analyticsContext.filters[field].value) &&
              props.analyticsContext.filters[field].value.length > 0
            ) {
              filter[field] = props.analyticsContext.filters[field].value[0]
            } else {
              filter[field] = props.analyticsContext.filters[field].value
            }
          } else {
            filter[field] = props.analyticsContext.filters[field]
          }
        }
      }
    })

    if (props.analyticsContext.filters.lead_in_date) {
      filter.account = mergeDeep({}, filter.account, {
        lead_in_date: props.analyticsContext.filters.lead_in_date
      })
    }

    if (props.analyticsContext.filters.user_uid && !props.analyticsContext.filters.user) {
      filter.user = {
        user_uid: props.analyticsContext.filters.user_uid
      }
    }

    if (mapDates) {
      const targetKey = mapActivityDateAs || 'activity_date'

      if (props.analyticsContext.filters.activity_date && !filter.hasOwnProperty(targetKey)) {
        if (props.analyticsContext.filters.activity_date.value) {
          filter[targetKey] = props.analyticsContext.filters.activity_date
        } else {
          filter[targetKey] = props.analyticsContext.filters.activity_date
        }
      }
    }

    if (props.analyticsContext.filters.resource_pool_uid) {
      if (props.analyticsContext.filters.resource_pool_uid.value) {
        filter.user_uid = {
          // eslint-disable-next-line
          $inResourcePool: props.analyticsContext.filters.resource_pool_uid.value[0]
        }

        delete filter.resource_pool_uid
      }
    }
  }

  if (params && params.contextMapping) {
    filter = runContextMapping(params.contextMapping, filter, filter)
  }

  return filter
}

const runContextMapping = (contextMapping, filter, contextFilter) => {
  const ret = { ...filter }

  Object.keys(contextMapping).forEach(key => {
    const isObject = contextMapping[key] instanceof Object

    if (isObject) {
      const mappedObjectFilter = runContextMapping(contextMapping[key], {}, contextFilter)

      ret[key] = mappedObjectFilter
    } else {
      ret[key] = contextFilter[contextMapping[key]]

      if (contextMapping[key] !== key) {
        delete ret[contextMapping[key]]
      }
    }
  })

  return ret
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target

  const source = sources.shift()

  if (isObject(target) && isObject(source) && !target.$skip) {
    for (const key in source) {
      if (isDate(source[key])) {
        target[key] = source[key]
      } else if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })

        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const mapContextFiltersIntoWidget = (widgetDefinition, props) => {
  if (
    !widgetDefinition ||
    !widgetDefinition.content ||
    !widgetDefinition.content.data ||
    !widgetDefinition.content.data.dataSources
  ) {
    return widgetDefinition
  }

  let contextFilter = mapContextToFilter(props, true)

  contextFilter = {
    ...convertUnderlineFilterToObject(contextFilter),
    ...contextFilter
  }

  const ret = cloneDeep(widgetDefinition)

  ret.content.data.dataSources.forEach(ds => {
    ds.filter = mergeDeep({}, ds.$noContextFilter ? {} : contextFilter, ds.filter)

    if (ds.contextMapping) {
      const contextMappedFilter = runContextMapping(ds.contextMapping, ds.filter, contextFilter)

      ds.filter = mergeDeep({}, ds.$noContextFilter ? {} : contextFilter, ds.filter, contextMappedFilter)
    }
  })

  return ret
}

export const mapContextToFilter2 = (props, params) => {
  return mapContextToFilter(props, params.mapDates, params.mapActivityDateAs, params)
}

export type Precision = 'days' | 'months' | 'years'

export type GenerateDateSeries = (
  pStartDate: MomentInput,
  pEndDate: MomentInput,
  handler: Function,
  precision: Precision
) => Array<string>

export const generateDateSeries: GenerateDateSeries = (pStartDate, pEndDate, handler, precision = 'days') => {
  const startDate = moment(pStartDate)
  const endDate = moment(pEndDate)
  const order = startDate.isAfter(endDate) ? 'desc' : 'asc'
  const res = {}
  const date = startDate

  while (order === 'asc' ? date.isSameOrBefore(endDate) : date.isSameOrAfter(endDate)) {
    res[date.format('YYYY-MM-DD')] = handler(date.format('YYYY-MM-DD'))
    date.add(order === 'asc' ? 1 : -1, precision)
  }

  return Object.values(res)
}

export const filterToCamelCase = filters =>
  Object.keys(filters).reduce((a, key) => {
    a[camelCase(key)] = filters[key]
    return a
  }, {})

export const convertUnderlineFilterToObject = filter => {
  const result = {}
  const roots = {}
  const ownFields = {}

  Object.keys(filter).forEach(key => {
    const parts = key.split('__')

    if (parts.length > 1) {
      const root = parts[0]
      parts.splice(0, 1)
      roots[root] = roots[root] || {}
      roots[root][parts.join('__')] = filter[key]
    } else {
      ownFields[parts[0]] = parts[0]
      result[parts[0]] = filter[parts[0]]
    }
  })

  Object.keys(roots).forEach(root => {
    result[root] = convertUnderlineFilterToObject(roots[root])
  })

  return result
}

export const convertMonthToDateRange = month => ({
  $gte: moment(month)
    .startOf('month')
    .format('YYYY-MM-DD'),
  $lte: moment(month)
    .endOf('month')
    .format('YYYY-MM-DD')
})
