/* eslint-disable camelcase */
import agentOwnedLeads from './ct-dashboards/agent-owned-leads'
import rmLastActivity from './ct-dashboards/rm-last-activity'
import rmsHistoricalPerformance from './ct-dashboards/rms-historical-performance'
import rmMeetingActivity from './ct-dashboards/rm-meeting-activity'
import agentHistoricalActualsB2C from './ct-dashboards/agent-historical-actuals-b2c'
import agentHistoricalActualsB2B from './ct-dashboards/agent-historical-actuals-b2b'
import rmLeadsResults from './ct-dashboards/rm-leads-results'
import rmMeetingPerformance from './ct-dashboards/rm-meetings-performance'
import rmOpportunities from './ct-dashboards/rm-opportunities'
import agentsLiveMonitoringB2C from './ct-dashboards/agents-live-monitoring-b2c'
import agentsLiveMonitoringB2B from './ct-dashboards/agents-live-monitoring-b2b'
import agentLeadsResultsB2B from './ct-dashboards/agent-leads-results-b2b'
import agentLeadsResultsB2C from './ct-dashboards/agent-leads-results-b2c'

// Analytics menu structure
const menuDashboards = [
  {
    type: 'section',
    icon: 'car',
    label: 'RM B2B',
    code: 'rmr_b2b',
    items: [
      rmsHistoricalPerformance,
      rmLeadsResults,
      rmLastActivity,
      rmMeetingActivity,
      rmMeetingPerformance,
      rmOpportunities
      // leadsCurrentStatus
    ]
  },

  {
    type: 'section',
    icon: 'phone',
    label: 'Telesales B2C',
    code: 'telesales_b2c',
    items: [
      agentHistoricalActualsB2C,
      {
        type: 'item',
        label: 'Daily Lead Results B2C',
        code: 'ctDailyLeadsResultsB2C',
        dashboard_uid: 'ctDailyLeadsResultsB2C',
        defaultFilters: {
          department_uid: {
            value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
            label: 'Telesales'
          }
        },
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctDailyLeadsDashboard',
              filters: {
                account_uid: { $ne: null }
              }
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Monthly Lead Results B2C',
        code: 'ctMonthlyLeadsResultsDashboardB2C',
        dashboard_uid: 'ctMonthlyLeadsResultsDashboardB2C',
        defaultFilters: {
          department_uid: {
            value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
            label: 'Telesales'
          }
        },
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctMonthlyLeadsDashboard',
              filters: {}
            }
          ]
        }
      },
      agentLeadsResultsB2C,
      agentOwnedLeads,
      agentsLiveMonitoringB2C,
      {
        type: 'item',
        label: 'Campaign Statistics',
        code: 'ctCampaignStatistics',
        dashboard_uid: 'ctCampaignStatistics',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctCampaignStatistics'
            }
          ]
        }
      }
    ]
  },
  {
    type: 'section',
    icon: 'phone',
    label: 'Telesales B2B',
    code: 'telesales_b2b',
    items: [
      agentHistoricalActualsB2B,
      {
        type: 'item',
        label: 'Daily Lead Results B2B',
        code: 'ctDailyLeadsResultsB2B',
        dashboard_uid: 'ctDailyLeadsResultsB2B',
        defaultFilters: {
          department_uid: {
            value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
            label: 'Telesales'
          }
        },
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctDailyLeadsDashboard',
              filters: {}
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Monthly Lead Results B2B',
        code: 'ctMonthlyLeadsResultsDashboardB2B',
        dashboard_uid: 'ctMonthlyLeadsResultsDashboardB2B',
        defaultFilters: {
          department_uid: {
            value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
            label: 'Telesales'
          }
        },
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctMonthlyLeadsDashboard',
              filters: {}
            }
          ]
        }
      },
      agentLeadsResultsB2B,
      agentsLiveMonitoringB2B
    ]
  },
  {
    type: 'section',
    icon: 'car',
    label: 'User Dashboards',
    code: 'ctUserDashboards',
    items: [
      {
        type: 'item',
        label: 'RM Summary',
        code: 'ctRmDashboard',
        dashboard_uid: 'ctRmDashboard',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctRmDashboard'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Agent Summary',
        code: 'ctAgentDashboard',
        dashboard_uid: 'ctAgentDashboard',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctAgentDashboard'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'New RM Summary',
        code: 'ctNewRmDashboard',
        dashboard_uid: 'ctNewRmDashboard',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctNewRmDashboard'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'New Agent Summary',
        code: 'ctNewAgentDashboard',
        dashboard_uid: 'ctNewAgentDashboard',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctNewAgentDashboard'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'New Sale Manager Dashboard',
        code: 'ctNewSaleManagerDashboard',
        dashboard_uid: 'ctNewSaleManagerDashboard',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctNewSaleManagerDashboard'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'New Tele Sale Manager Dashboard',
        code: 'ctNewTeleSaleManagerDashboard',
        dashboard_uid: 'ctNewTeleSaleManagerDashboard',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctNewTeleSaleManagerDashboard'
            }
          ]
        }
      }
    ]
  },
  {
    type: 'section',
    icon: 'address-book',
    label: 'Leads Management',
    code: 'leads_status',
    items: [
      {
        type: 'item',
        label: 'Lead Current Status',
        code: 'ctLeadsCurrentStatus',
        dashboard_uid: 'ctLeadsCurrentStatus',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctLeadsCurrentStatus'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Horizontal by Lead In Date',
        code: 'ctLeadsStatusByLeadDate',
        dashboard_uid: 'ctLeadsStatusByLeadDate',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctLeadsStatusByLeadDate'
            }
          ]
        }
      }
    ]
  },
  {
    type: 'section',
    icon: 'car',
    label: 'Cartrack',
    code: 'cartrack',
    items: [
      // {
      //   type: 'item',
      //   label: 'Performance Dashboard',
      //   code: 'ctWhiteboardDashboard',
      //   dashboard_uid: 'ctWhiteboardDashboard',
      //   content: {
      //     items: [
      //       {
      //         type: 'special_widget',
      //         code: 'ctWhiteboardDashboard'
      //       }
      //     ]
      //   }
      // },
      {
        type: 'item',
        label: 'Sources of Fitment',
        code: 'joeDashboard',
        dashboard_uid: 'joeDashboard'
      },
      {
        type: 'item',
        label: 'Daily Lead Results (no filters)',
        code: 'ctDailyLeadsResultsNoF',
        dashboard_uid: 'ctDailyLeadsResultsNoF',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctDailyLeadsDashboard',
              filters: {}
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Monthly Lead Results (no filters)',
        code: 'ctMonthlyLeadsResultsNoFilter',
        dashboard_uid: 'ctMonthlyLeadsResultsNoFilter',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'ctMonthlyLeadsDashboard',
              filters: {}
            }
          ]
        }
      }
      // {
      //   type: 'item',
      //   label: 'All Leads analysis',
      //   code: 'ctOpsAllLeads',
      //   dashboard_uid: 'ctOpsAllLeads',
      //   content: {
      //     items: [
      //       {
      //         type: 'special_widget',
      //         code: 'ctOpsAllLeadsDashboard',
      //         filters: {}
      //       }
      //     ]
      //   }
      // },
      // {
      //   type: 'item',
      //   label: 'All Leads analysis',
      //   code: 'ctOpsCamsFitments',
      //   dashboard_uid: 'ctOpsCamsFitments',
      //   content: {
      //     items: [
      //       {
      //         type: 'special_widget',
      //         code: 'ctOpsCamsFitmentsDashboard',
      //         filters: {}
      //       }
      //     ]
      //   }
      // }
    ]
  },
  // {
  //   type: 'section',
  //   icon: 'eye',
  //   label: 'Overview',
  //   code: 'overview',
  //   items: [

  //     // {
  //     //   type: 'item',
  //     //   label: 'Order Book',
  //     //   code: 'sales_overview',
  //     //   dashboard_uid: 'dashSalesOverview'
  //     // // },
  //     // {
  //     //   type: 'item',
  //     //   label: 'Weekly Sales Activities',
  //     //   code: 'dashWeeklySalesActivities18',
  //     //   dashboard_uid: 'dashWeeklySalesActivities18'
  //     // },
  //     // {
  //     //   type: 'item',
  //     //   label: 'Stale Opportunities',
  //     //   code: 'dashNoneMovement18',
  //     //   dashboard_uid: 'dashNoneMovement18'
  //     // }
  //   ]
  // },
  // {
  //   type: 'section',
  //   icon: 'eye',
  //   label: 'Live View',
  //   code: 'live',
  //   dashboard_uid: 'dashLiveView'
  // },
  // {
  //   type: 'section',
  //   icon: 'cubes',
  //   label: 'Marketing',
  //   code: 'marketing',
  //   items: [
  // {
  //   type: 'item',
  //   label: 'Overview',
  //   code: 'leads_overview',
  //   dashboard_uid: 'dashLeadsOverview'
  // },
  // {
  //   type: 'item',
  //   label: 'Leads Today',
  //   code: 'leads_today',
  //   dashboard_uid: 'dashLeadsToday'
  // },
  // {
  //   type: 'item',
  //   label: 'Details',
  //   code: 'leads_details',
  //   dashboard_uid: 'dashLeadsDetails'
  // },
  // {
  //   type: 'item',
  //   label: 'Campaign Results',
  //   code: 'campaign_leads_results',
  //   dashboard_uid: 'dashCampaignLeadsResults'
  // }
  // {
  //   type: 'item',
  //   icon: 'sign-in',
  //   label: '*Leads Analytics',
  //   code: 'leads_analytics',
  //   dashboard_uid: 'dashLeadsAnalytics18'
  // },
  // {
  //   type: 'item',
  //   icon: 'google',
  //   label: 'Digital - Live Monitoring',
  //   code: 'dashDigitalMonitoring18',
  //   dashboard_uid: 'dashDigitalMonitoring18'
  // }
  // {
  //   type: 'item',
  //   icon: 'google',
  //   label: 'Leads - Current Status',
  //   code: 'dashCurrentLeadStatus',
  //   dashboard_uid: 'dashCurrentLeadStatus',
  //   favorite: true
  // },
  // ]
  // },
  // {
  //   type: 'section',
  //   icon: 'plug',
  //   label: 'Fitments',
  //   code: 'fitments',
  //   items: [
  //     {
  //       type: 'item',
  //       label: 'Fitments Overview',
  //       code: 'fitments_overview',
  //       dashboard_uid: 'dashFitmentsOverview'
  //     }
  //     // {
  //     //   type: 'item',
  //     //   label: 'Details',
  //     //   code: 'fitments_details',
  //     //   dashboard_uid: 'dashFitmentsDetails'
  //     // },
  //     // {
  //     //   type: 'item',
  //     //   label: 'Pending Fitments',
  //     //   code: 'fitments_pending',
  //     //   dashboard_uid: 'dashPendingFitments'
  //     // }
  //   ]
  // },
  // {
  //   type: 'section',
  //   icon: 'phone',
  //   label: 'Telesales Management',
  //   code: 'telesales_mgmt',
  //   items: [
  //     // {
  //     //   type: 'item',
  //     //   label: 'Overview',
  //     //   code: 'telesales_overview',
  //     //   dashboard_uid: 'dashTelesalesOverview'
  //     // },
  //   ]
  // },
  // {
  //   type: 'section',
  //   icon: 'users',
  //   label: 'RMs',
  //   code: 'rms',
  //   items: [
  //     {
  //       type: 'item',
  //       label: 'RMs Overview',
  //       code: 'rms_overview',
  //       dashboard_uid: 'dashRmsOverview'
  //     },
  //     {
  //       type: 'item',
  //       label: 'Opportunities Funnel',
  //       code: 'opportunitiesFunnel',
  //       dashboard_uid: 'opportunitiesFunnel'
  //     },
  //     {
  //       type: 'item',
  //       label: 'Opportunities Analysis',
  //       code: 'opportunitiesStats',
  //       dashboard_uid: 'opportunitiesStats'
  //     },
  //     // {
  //     //   type: 'item',
  //     //   label: 'Opportunities Dashboard',
  //     //   code: 'opportunitiesDashboard',
  //     //   dashboard_uid: 'opportunitiesDashboard'
  //     // },
  //     {
  //       type: 'item',
  //       label: 'Overdue Opportunities',
  //       code: 'rms_overdue_opportunities',
  //       dashboard_uid: 'dashRmsOverdueOpportunities'
  //     },
  //     {
  //       type: 'item',
  //       label: 'Leads Stats',
  //       code: 'rmLeadsStats',
  //       dashboard_uid: 'rmLeadsStats'
  //     },
  //     {
  //       type: 'item',
  //       label: 'Activity CRM',
  //       code: 'rms_activity',
  //       dashboard_uid: 'dashRmsActivityCrm'
  //     },
  //     {
  //       type: 'item',
  //       label: 'RMs Tasks Statistics',
  //       code: 'dashRmsTasksStats',
  //       dashboard_uid: 'dashRmsTasksStats'
  //     }
  //   ]
  // },
  {
    type: 'section',
    icon: 'phone',
    label: 'Telesales',
    code: 'telesales',
    items: [
      // {
      //   type: 'item',
      //   label: 'Agents live monitoring',
      //   code: 'agentWb',
      //   dashboard_uid: 'agentWb'
      // },
      // {
      //   type: 'item',
      //   label: 'Telesales Today',
      //   code: 'telesales_today',
      //   dashboard_uid: 'dashTelesalesToday'
      // },
      // {
      //   type: 'item',
      //   label: 'Campaign Status',
      //   code: 'telesales_campaign',
      //   dashboard_uid: 'dashTelesalesCampaign'
      // },
      // {
      //   type: 'item',
      //   label: 'Campaign Movement',
      //   code: 'telesales_campaign_movement',
      //   dashboard_uid: 'dashTelesalesCampaignMovements'
      // },
      {
        type: 'item',
        label: 'Agents Dispositions',
        code: 'telesales_agents_dispositions',
        dashboard_uid: 'dashTelesalesAgentsDispositions'
      }
      // {
      //   type: 'item',
      //   label: 'Agents Performance',
      //   code: 'telesales_agents_performance',
      //   dashboard_uid: 'dashTelesalesAgentsPerformance'
      // },
      // {
      //   type: 'item',
      //   label: 'Agents Callbacks',
      //   code: 'telesales_agents_callbacks',
      //   dashboard_uid: 'dashTelesalesAgentsCallbacks'
      // },
      // {
      //   type: 'item',
      //   label: 'Meetings by Telesales',
      //   code: 'telesales_overview',
      //   dashboard_uid: 'dashTelesalesOverview'
      // },
      // {
      //   type: 'item',
      //   label: 'Sales',
      //   code: 'telesales_sales',
      //   dashboard_uid: 'dashTelesalesSales'
      // }
      // {
      //   type: 'item',
      //   label: 'Monthly Leads Monitoring',
      //   code: 'telesales_monthly_leads_monitoring',
      //   dashboard_uid: 'dashMonthlyLeadsMonitoring'
      // }
    ]
  },
  {
    type: 'section',
    icon: 'car',
    label: 'Test Dashboards',
    code: 'test_dashboards',
    hidden: true,
    items: [
      {
        type: 'item',
        label: 'Funnel 2019',
        code: 'testFunnelDashboard19',
        dashboard_uid: 'testFunnelDashboard19',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'testFunnelDashboard19'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Leads Mgmt 2019',
        code: 'testLeadsMgmt2019',
        dashboard_uid: 'testLeadsMgmt2019',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'testLeadsMgmt2019'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Forecast 2019',
        code: 'testForecast2019',
        dashboard_uid: 'testForecast2019',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'testForecast2019'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Test Charts',
        code: 'testCharts2019',
        dashboard_uid: 'testCharts2019',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'testCharts2019'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Ton Dashboard',
        code: 'testOpportunities2019',
        dashboard_uid: 'testOpportunities2019',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'testOpportunities2019'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Fitments - general statistics',
        code: 'fitmentGeneralStatic',
        dashboard_uid: 'fitmentGeneralStatic',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'fitmentGeneralStatic'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Show Context Filter',
        code: 'showContextFilter',
        dashboard_uid: 'showContextFilter',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'showContextFilter'
            }
          ]
        }
      },
      {
        type: 'item',
        label: 'Custom Dasdhboard',
        code: 'dynamicComponentDashboard',
        dashboard_uid: 'dynamicComponentDashboard',
        content: {
          items: [
            {
              type: 'special_widget',
              code: 'dynamicComponentDashboard'
            }
          ]
        }
      }
    ]
  }
]

const menuManagementReports = [
  // {
  //   type: 'section',
  //   icon: 'line-chart',
  //   label: 'RM Performance',
  //   code: 'rm_performance_section',
  //   dashboard_uid: 'dashRmPerformance'
  // },
  // {
  //   type: 'section',
  //   icon: 'table',
  //   label: 'Leads Daily Stats',
  //   code: 'rm_dash_leads_daily_stats',
  //   dashboard_uid: 'dashLeadsDailyStats'
  // },
  // {
  //   type: 'section',
  //   icon: 'users',
  //   label: 'Meetings',
  //   code: 'Meetings',
  //   items: [
  //     {
  //       type: 'item',
  //       label: 'Meetings Dashboard',
  //       code: 'dashMeetingsDashboard18',
  //       dashboard_uid: 'dashMeetingsDashboard18'
  //     }
  //   ]
  // },
  // {
  //   type: 'section',
  //   icon: 'trophy',
  //   label: 'Wins',
  //   code: 'Wins',
  //   items: [
  //     {
  //       type: 'item',
  //       label: 'Wins Dashboard',
  //       code: 'dashWinsDashboard18',
  //       dashboard_uid: 'dashWinsDashboard18'
  //     }
  //   ]
  // },
  // {
  //   type: 'section',
  //   icon: 'home',
  //   label: 'Home (old)',
  //   code: 'home',
  //   dashboard_uid: 'dashHome'
  // },
  // {
  //   type: 'section',
  //   icon: 'phone',
  //   label: 'Audit',
  //   code: 'audit',
  //   items: [
  //     {
  //       type: 'item',
  //       label: 'Rm Activities History',
  //       code: 'rmActivitiesHistory',
  //       dashboard_uid: 'rmActivitiesHistory'
  //     }
  //   ]
  // }
]

// Reducer
const initialState = {
  menuDashboards,
  menuManagementReports
}

function analyticsReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

export const getMenuDashboards = state => state.crm.analytics.menuDashboards
export const getMenuManagementReports = state =>
  state.crm.analytics.menuManagementReports
export default analyticsReducer
