/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { func, string, bool, shape } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import { push } from 'connected-react-router'
import changeProcessOwnerHoc from './_change-process-owner-hoc.jsx'
import { CrmButton, CrmUserSelect, CrmResourcePoolSelect } from 'crm-components'
import { RadioInput } from 'util-components'

const newOwnerOptions = [
  { value: 'user', label: 'User' },
  { value: 'group', label: 'Group' }
]

class ChangeProcessOwnerWidget extends React.PureComponent {
  handleSaveOwnerChange = async () => {
    console.log('handleSaveOwnerChange', this.props)
    if (this.props.bulkMode) {
      // console.log('handleSaveOwnerChange bulkNode')
      return
    }
    try {
      const res = await this.props.onSaveOwnerChange()
      // console.log('Reassigned - res', res, this.props)
      toastr.success('Process owner changed')

      if (res && res.status === 'OK' && res.payload && res.payload.data) {
        // console.log('Try push')
        const url = '/crm/account/' + this.props.account.account_uid + '/sales'
        // console.log('Push url', url)
        this.props.doPush(url)
      }
      this.props.onCancel()
    } catch (err) {
      toastr.error(err.message)
    }
  }
  componentDidMount() {
    if (this.props.onBulkComponentInit) {
      this.props.onBulkComponentInit(this)
    }
  }

  render() {
    console.log('render ChangeProcessOwnerWidget')
    const { newOwnerType } = this.props

    const currentOwner = this.props.account.owners
      ? this.props.account.owners.sales
      : undefined
    console.log('currentOwner', currentOwner)
    return (
      <div className="CrmMdCard util-paddingSm">
        <RadioInput
          options={newOwnerOptions}
          input={{
            value: this.props.newOwnerType,
            onChange: this.props.onOwnerTypeChanged
          }}
          name="newOwnerType"
        />
        {newOwnerType === 'group' && (
          <CrmResourcePoolSelect
            input={{
              value: this.props.usersGroup,
              onChange: this.props.onUsersGroupSelect
            }}
            placeholder={
              <FormattedMessage
                id="crm.ui.account.convert.select_users_group_as_prospect_owner"
                defaultMessage="Select Users Group as new Owner"
              />
            }
          />
        )}
        {newOwnerType === 'user' && (
          <CrmUserSelect
            placeholder={
              <FormattedMessage
                id="crm.ui.widget.select_new_owner"
                defaultMessage="Select new owner"
              />
            }
            input={{
              value: this.props.userUid,
              onChange: this.props.onUserSelect
            }}
          />
        )}
        {!this.props.bulkMode && (
          <div className="util-marginTop util-flexRowRight">
            <CrmButton
              xsmall
              type="secondary"
              grouped
              label={
                <FormattedMessage
                  id="crm.ui.button.cancel"
                  defaultMessage="cancel"
                />
              }
              onClick={this.props.onCancel}
            />
            <CrmButton
              xsmall
              grouped
              type="primary"
              label={
                <FormattedMessage
                  id="crm.ui.button.save"
                  defaultMessage="Save"
                />
              }
              isSaving={this.props.isSaving}
              onClick={this.handleSaveOwnerChange}
            />
          </div>
        )}
      </div>
    )
  }
}

ChangeProcessOwnerWidget.propTypes = {
  onSaveOwnerChange: func.isRequired,
  onCancel: func.isRequired,
  onUsersGroupSelect: func.isRequired,
  onOwnerTypeChanged: func.isRequired,
  userUid: string,
  isSaving: bool.isRequired,
  onUserSelect: func.isRequired,
  newOwnerType: string.isRequired,
  usersGroup: string,
  bulkMode: bool,
  onBulkComponentInit: func,
  process: shape().isRequired,
  doPush: func.isRequired,
  account: shape().isRequired
}

ChangeProcessOwnerWidget.defaultProps = {
  userUid: undefined,
  usersGroup: undefined,
  bulkMode: false,
  onBulkComponentInit: undefined
}

export default changeProcessOwnerHoc(
  connect(null, dispatch => ({
    doPush: url => dispatch(push(url))
  }))(ChangeProcessOwnerWidget)
)
