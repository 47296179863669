import { CrmTextInput, CrmLabeledField } from 'crm-components'
import { shape, func, object, string, oneOfType, node } from 'prop-types'
import generateInlineEditableField from 'crm-components/hoc/inline-editable-component-web-hoc'
import editAccountHoc, {
  saveHandler
} from 'crm-modules/accounts/hoc/edit-account-hoc.js'
import React from 'react'
import { toastr } from 'react-redux-toastr'

class AccountInlineEditable extends React.PureComponent {
  renderInner_CrmLabeledField() {
    const { label, value, displayValue } = this.props
    return (
      <CrmLabeledField
        label={label}
        valueClassName="ActivityListItem-headerItem-value"
        noMargin
      >
        {displayValue || value}
      </CrmLabeledField>
    )
  }

  renderInner_NoStyle() {
    return <div style={this.props.style}>{this.props.value}</div>
  }

  render() {
    const { readModeStyle } = this.props
    if (readModeStyle === 'CrmLabeledField') {
      return <div>{this.renderInner_CrmLabeledField()}</div>
    } else {
      return <div>{this.renderInner_NoStyle()}</div>
    }
  }
}

AccountInlineEditable.propTypes = {
  value: string,
  label: node,
  readModeStyle: string,
  style: shape({}),
  displayValue: string
}
AccountInlineEditable.defaultProps = {
  value: '',
  label: '',
  readModeStyle: '',
  style: {},
  displayValue: ''
}

class AccountEditComponent extends React.PureComponent {
  handleValueChange = object => {
    if (
      this.props.fieldName === 'industry_uid' ||
      this.props.fieldName === 'lead_campaign_uid'
    ) {
      this.props.input.onChange(object)
    } else {
      this.props.input.onChange(object.currentTarget.value)
    }
  }

  render() {
    console.log('Render AccountEditComponent')
    if (this.props.editComponent) {
      return (
        <this.props.editComponent
          {...this.props.editComponentProps}
          input={{
            value: this.props.input.value,
            onChange: this.handleValueChange
          }}
        />
      )
    } else {
      return (
        <div style={{ marginBottom: '20px' }}>
          <CrmTextInput
            placeholder={this.props.label}
            input={{
              value: this.props.input.value,
              onChange: this.handleValueChange
            }}
            focus={true}
          />
        </div>
      )
    }
  }
}

AccountEditComponent.propTypes = {
  input: shape({}).isRequired,
  label: node,
  editComponent: oneOfType([object, func]),
  editComponentProps: shape({}),
  fieldName: string.isRequired
}
AccountEditComponent.defaultProps = {
  label: '',
  editComponent: undefined,
  editComponentProps: undefined
}

const errorHandler = props => {
  toastr.error(props.errorMessage)
}

const inlineEditable = generateInlineEditableField(AccountInlineEditable, {
  editComponent: editAccountHoc(AccountEditComponent),
  saveHandler,
  errorHandler
})

export default inlineEditable
