import React, { PureComponent } from 'react'
import { shape, func, bool, arrayOf, string } from 'prop-types'
import { CrmForm, CrmFormGroup, CrmButton, FlatTable } from 'crm-components'
import { FormattedMessage } from 'react-intl'
import SectionDuplicateLeads from '../_section-duplicate-leads'
import SectionFooter from '../_section-footers.jsx'
import { JsonToTable } from 'crm-utils/common-utils'
import AccountInlineEditable from '../../../components/layout19/account-inline-editable.jsx'
import { qlqfetchAccount } from 'crm-data/accounts'
import crmGenericCommandQlHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import InfoNoAccountPermission from '../info_no_account_permission.jsx'

class TabAdditionalInfo extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isKpisVisible: false
    }
  }

  handleShowKpis = () => {
    this.setState({ isKpisVisible: !this.state.isKpisVisible })
  }

  handleRebuildProjection = async () => {
    console.log('handleRebuildProjection', this.props)
    const cmd = {
      aggregate_type: 'accountProjection',
      type: 'accountProjection.rebuild',
      aggregate_uid: this.props.account.account_uid
    }
    const refetchQueries = [
      {
        query: qlqfetchAccount,
        variables: {
          account_uid: this.props.account.account_uid
        }
      }
    ]
    await this.props.onRunCommand(cmd, { refetchQueries })
  }

  getPermissionsColumns = () => {
    return [
      {
        Header: (
          <FormattedMessage
            id="crm.ui.account.permission"
            defaultMessage="Permission"
          />
        ),
        key: 'permission_code',
        accessor: 'permission_code'
      },
      {
        Header: (
          <FormattedMessage
            id="crm.ui.account.module"
            defaultMessage="Module"
          />
        ),
        key: 'module',
        accessor: 'module'
      },
      {
        Header: (
          <FormattedMessage id="crm.ui.account.read" defaultMessage="Read" />
        ),
        key: 'access_mode_read',
        Cell: row => (
          <div>
            {row.original.access_mode && row.original.access_mode.read ? (
              <i className="fa fa-check" />
            ) : (
              '-'
            )}
          </div>
        )
      },
      {
        Header: (
          <FormattedMessage id="crm.ui.account.write" defaultMessage="Write" />
        ),
        key: 'access_mode_modify',
        Cell: row => (
          <div>
            {row.original.access_mode && row.original.access_mode.modify ? (
              <i className="fa fa-check" />
            ) : (
              '-'
            )}
          </div>
        )
      },
      {
        Header: (
          <FormattedMessage
            id="crm.ui.account.manage"
            defaultMessage="Manage"
          />
        ),
        key: 'access_mode_manage',
        Cell: row => (
          <div>
            {row.original.access_mode && row.original.access_mode.manage ? (
              <i className="fa fa-check" />
            ) : (
              '-'
            )}
          </div>
        )
      },
      {
        Header: (
          <FormattedMessage id="crm.ui.account.admin" defaultMessage="Admin" />
        ),
        key: 'access_mode_admin',
        Cell: row => (
          <div>
            {row.original.access_mode && row.original.access_mode.admin ? (
              <i className="fa fa-check" />
            ) : (
              '-'
            )}
          </div>
        )
      }
    ]
  }

  getPermissionsData = () => {
    const data = this.props.account.permissions.map(v => v)
    return data
  }

  render() {
    if (this.props.hasAccountDataPermission === false) {
      return <InfoNoAccountPermission />
    }

    const { account } = this.props
    const isFeedListVisible = false
    const labelRemark =
      account && account.remark ? (
        <FormattedMessage
          id="crm.ui.account.remarks"
          defaultMessage="Remarks"
        />
      ) : (
        <FormattedMessage
          id="crm.account.there_is_no_remarks_click_here_to_add_one"
          defaultMessage="There is no remarks. Click here to add one."
        />
      )

    if (account) {
      return (
        <CrmForm>
          <CrmFormGroup groupName="General">
            <div className="row">
              <div className="col-md-12">
                <div className="CrmFormSection CrmFormSection--hasActions">
                  <div className="CrmFormSection-header">
                    <span>
                      <FormattedMessage
                        id="crm.ui.account.remarks"
                        defaultMessage="Remarks"
                      />
                    </span>
                  </div>
                  <div className="CrmFormSection-body CrmFormSection-body--hasPadding">
                    <AccountInlineEditable
                      account={account}
                      fieldName="remark"
                      label={labelRemark}
                      value={account.remark}
                      editable={true}
                      readModeStyle="CrmLabeledField"
                    />
                  </div>
                </div>

                <SectionFooter account={account} />

                {account.meta && (
                  <div className="CrmFormSection">
                    <div className="CrmFormSection-header">
                      <span>
                        <FormattedMessage
                          id="crm.ui.account.additional.additional_information"
                          defaultMessage="Additional Information"
                        />
                      </span>
                    </div>
                    <div className="json-to-table">
                      <div className="wrapper">
                        <JsonToTable json={account.meta} />
                      </div>
                    </div>
                  </div>
                )}

                {account.kpis && (
                  <div className="CrmFormSection">
                    <div
                      className="CrmFormSection-header"
                      onClick={this.handleShowKpis}
                    >
                      <span>
                        <FormattedMessage
                          id="crm.ui.account.kpi.show_statistics_kpis"
                          defaultMessage="Show statistics KPIs"
                        />
                      </span>
                    </div>
                    {this.state.isKpisVisible && (
                      <div className="json-to-table">
                        <div className="wrapper">
                          <JsonToTable json={account.kpis} />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="CrmFormSection">
                  <div className="CrmFormSection-header">
                    <span>
                      <FormattedMessage
                        id="crm.ui.account.form.duplicate_leads"
                        defaultMessage="Duplicate Leads"
                      />
                    </span>
                  </div>
                  <SectionDuplicateLeads account={this.props.account} />
                </div>

                <div>
                  <small>
                    <FormattedMessage
                      id="crm.ui.account.current_module"
                      defaultMessage="Current Module"
                    />
                    {': '} {this.props.currentModuleCode}
                  </small>
                </div>
              </div>

              <div className="col-md-12 util-marginTop">
                <div className="CrmFormSection">
                  <div className="CrmFormSection-header">
                    <FormattedMessage
                      id="crm.ui.account.permission"
                      defaultMessage="Permissions"
                    />
                  </div>
                  <div>
                    <FlatTable
                      data={this.getPermissionsData()}
                      columns={this.getPermissionsColumns()}
                      translation
                      tableName="market_potential_history"
                    />
                  </div>
                </div>
              </div>

              {
                <div className="col-md-12 util-marginTop">
                  <div className="CrmFormSection">
                    <div
                      className="CrmFormSection-header"
                      onClick={this.handleShowKpis}
                    >
                      <span>
                        <FormattedMessage
                          id="crm.ui.account.kpi.show_statistics_kpis"
                          defaultMessage="Show statistics KPIs"
                        />
                      </span>
                    </div>
                    {this.state.isKpisVisible && (
                      <React.Fragment>
                        <CrmButton
                          label={
                            <FormattedMessage
                              id="crm.account.rebuild_account_information_advanced"
                              defaultMessage="Rebuild account information (advanced)"
                            />
                          }
                          onClick={this.handleRebuildProjection}
                        />
                        <div className="json-to-table">
                          <div className="wrapper">
                            <JsonToTable json={account.kpis} />
                          </div>
                        </div>
                        <small>
                          <FormattedMessage
                            id="crm.ui.account.permission"
                            defaultMessage="Permissions"
                          />
                          {':'}
                        </small>
                        <div className="json-to-table">
                          <div className="wrapper">
                            <JsonToTable json={account.permissions} />
                          </div>
                        </div>
                        <small>
                          <FormattedMessage
                            id="crm.account.new_account_owners_tests"
                            defaultMessage="New account owners (tests)"
                          />
                          {':'}
                        </small>
                        <div className="json-to-table">
                          <div className="wrapper">
                            <JsonToTable json={account.owners} />
                          </div>
                        </div>
                        <small>
                          <FormattedMessage
                            id="crm.account.sales_status"
                            defaultMessage="Sales Status"
                          />
                        </small>
                        <div className="json-to-table">
                          <div className="wrapper">
                            <JsonToTable json={account.sales_status} />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                // <AccountMetaModal
                //   isOpen={this.state.isOpenMetaModal}
                //   title="Meta"
                //   meta={account.meta}
                //   handleCloseModal={this.onClickMetaModalClose}
                // />
              }

              {isFeedListVisible && (
                <div className="col-md-12">
                  <FeedList
                    accountUid={account.account_uid}
                    filter={{ account_uid: account.account_uid }}
                  />
                </div>
              )}
            </div>
          </CrmFormGroup>
        </CrmForm>
      )
    }
  }
}

TabAdditionalInfo.defaultProps = {
  account: undefined,
  currentModuleCode: undefined
}

TabAdditionalInfo.propTypes = {
  account: shape({}).isRequired,
  currentModuleCode: string,
  hasAccountDataPermission: bool.isRequired
}

export default crmGenericCommandQlHoc(TabAdditionalInfo)
