import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedRelativeTime } from 'react-intl'
import { getAccountStatus } from 'crm-utils/accounts-utils'

function _createShowInfoLink(row, value) {
  return <Link to={`/crm/account/${row.original.account_uid}`}>{value}</Link>
}

export const availableColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Account Name',
    show: false,
    CellName: row => (
      <div style={{ textAlign: 'left' }}>
        {_createShowInfoLink(
          row,
          row.value ? row.value : row.original.name_local
        )}
      </div>
    ),
    CellLocalName: row => (
      <div style={{ textAlign: 'left' }}>
        {_createShowInfoLink(
          row,
          row.original.name_local ? row.original.name_local : row.value
        )}
      </div>
    )
  },
  {
    id: 'kind',
    accessor: 'kind',
    Header: 'Kind',
    maxWidth: 100,
    show: false,
    Cell: row => {
      const accountStatus = getAccountStatus(row.original)
      const rowClassName = `AccountsTable-row-${accountStatus}`
      return <div className={rowClassName}>{row.original.kind}</div>
    }
    // Cell: row => _createShowInfoLink(row, row.value)
  },
  {
    id: 'address',
    accessor: 'city',
    Header: 'City',
    show: false,
    maxWidth: 150,
    Cell: row => {
      let content = ''

      if (row.value.length > 0) {
        content = row.value[0].city
      }
      return <div>{content}</div>
    }
  },
  {
    id: 'owner',
    Header: 'Owner',
    maxWidth: 180,
    show: false,
    Cell: row => {
      const user =
        row.original.status && row.original.status.owner_user
          ? row.original.status.owner_user
          : undefined
      return user && user.full_name ? <div>{user.full_name}</div> : null
    }
  },
  // {
  //   id: 'last_result',
  //   Header: 'Last Result',
  //   maxWidth: 180,
  //   show: false,
  //   Cell: row => {
  //     if (row.original.lastSalesProcess) {
  //       return 'HAS'
  //     }
  //     return <span />
  //   }
  // },
  // {
  //   id: 'assigned_time',
  //   Header: 'Assigned at',
  //   Cell: row => {
  //     if (row.original.status && row.original.status.valid_from) {
  //       return <FormattedRelativeTime value={row.original.status.valid_from} />
  //     }
  //   },
  //   maxWidth: 180,
  //   show: false
  // },
  {
    id: 'last_activity_date',
    accessor: 'last_activity_date',
    Header: 'Last activity at',
    Cell: row => {
      if (row.original.last_activity) {
        console.log(
          'Render formatted relative',
          row.original.last_activity.start_time
        )
        return (
          <FormattedRelativeTime
            value={row.original.last_activity.start_time}
          />
        )
      }
    },
    maxWidth: 180,
    show: false
  },
  {
    id: 'last_activity_comment',
    accessor: 'last_activity_comment',
    Header: 'Activity Note',
    Cell: row => {
      if (row.original.last_activity) {
        return <small>{row.original.last_activity.note_text}</small>
      }
    },
    maxWidth: 250,
    show: false
  },
  {
    id: 'campaign_name',
    accessor: 'campaign_name',
    Header: 'Source Campaign',
    Cell: row => {
      if (row.original.campaign) {
        return <small>{row.original.campaign.name}</small>
      }
    },
    maxWidth: 250,
    show: false
  },
  {
    id: 'market_potential',
    Header: 'Potential',
    maxWidth: 100,
    show: false,
    Cell: row => {
      const marketPotential = row.original.market_potential
      const rowClassName = `AccountsTable-potential` // T O D O- different color for bigger
      return (
        <div className={rowClassName}>
          {marketPotential ? marketPotential.size : ''}
        </div>
      )
    }
    // Cell: row => _createShowInfoLink(row, row.value)
  },

  {
    id: 'qualification',
    accessor: 'qualification',
    Header: 'Last Qualification',
    Cell: row => {
      if (
        row.original.status &&
        row.original.status.qualification_type &&
        row.original.status.qualification_type.name
      ) {
        return row.original.status.qualification_type.name
      }
    },
    maxWidth: 180,
    show: false
  }
]

export const mock = 1
