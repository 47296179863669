import React from 'react'
import {
  useCrmDynamicTableContext,
  DynamicTableContextType
} from '../dynamic-table-context'
import { LayoutConfigPivot } from '../../component-section-layout/layout-config-pivot'

const ViewOptionsPivot = (props) => {
  const dynamicTableContext: DynamicTableContextType = useCrmDynamicTableContext()

  console.log('Render ViewOptionsPivot', dynamicTableContext, props)
  const columnOptions = dynamicTableContext.customColumns
    ? dynamicTableContext.customColumns.map((col) => {
        return {
          ...col,
          name: col.column.description || col.column.name,
          value: col.column.name
        }
      })
    : []
  console.log('columnOptions', columnOptions)
  const pivotDefinition = dynamicTableContext.pivotDefinition

  return (
    <div className="util-flexRow util-flexCenterItems">
      <LayoutConfigPivot {...props} />
      {/* <CrmDropdown
        placeholder="Columns Field"
        options={columnOptions}
        style={{ minWidth: 150 }}
        input={{
          value: pivotDefinition.pivotColumn,
          onChange: (val, label, item) => {
            console.log('Column field selected', item)
            dynamicTableContext.setPivotDefinition({
              ...pivotDefinition,
              pivotColumn: { ...item }
            })
          }
        }}
        extraClassNames={{
          containerClassNames: 'util-marginLeftSm util-marginRightSm'
        }}
      />
      <CrmDropdown
        placeholder="Rows Field"
        options={columnOptions}
        style={{ minWidth: 150 }}
        input={{
          value: pivotDefinition.pivotRow,
          onChange: (val, label, item) => {
            console.log('Row field selected', item)
            dynamicTableContext.setPivotDefinition({
              ...pivotDefinition,
              pivotRow: { ...item }
            })
          }
        }}
        extraClassNames={{
          containerClassNames: 'util-marginLeftSm util-marginRightSm'
        }}
      />
      <CrmDropdown
        placeholder="Values"
        options={columnOptions}
        style={{ minWidth: 150 }}
        input={{
          value: pivotDefinition.pivotCell,
          onChange: (val, label, item) => {
            console.log('cell field selected', item)
            dynamicTableContext.setPivotDefinition({
              ...pivotDefinition,
              pivotCell: { ...item }
            })
          }
        }}
        extraClassNames={{
          containerClassNames: 'util-marginLeftSm util-marginRightSm'
        }}
      /> */}
    </div>
  )
}

export default ViewOptionsPivot
