import React, { useState } from 'react'
import { CustomAttributesEditFormInner } from './custom-attributes-edit-form-inner'

interface Props {
  attributeDefinitions: any
  attributes: any
  onChange: Function
}

export const CustomAttributesEditForm: React.SFC<Props> = (props) => {
  if (!props.attributeDefinitions || props.attributeDefinitions.length == 0) {
    return <div>Not found any attributes</div>
  }

  const handleAttributeChange = (field: string, value: any) => {
    props.onChange(field, value)
  }

  return (
    <CustomAttributesEditFormInner
      {...props}
      onAttributeChange={handleAttributeChange}
    />
  )
}
