import React from 'react'
import processWidgetHoc from '../../../../processes/components/process-widget-ql-hoc'
import moment from 'moment'
import {
  intlShape,
  injectIntl,
  FormattedMessage,
  FormattedDate
} from 'react-intl'
import { calculateCurrentStepType } from 'crm-utils/process-utils'
import styles from '../dialer/sales-process-widget.scss'
import { CrmButton } from 'crm-components'
import { bool } from 'prop-types'
import InfoNoAccountPermission from '../info_no_account_permission.jsx'

class LastSalesResult extends React.PureComponent {
  render() {
    console.log('LastSalesResult.render ', this.props)

    if (this.props.hasAccountDataPermission === false) {
      return <InfoNoAccountPermission />
    }

    const { process } = this.props
    const { currentStepType, currentStage } = calculateCurrentStepType(
      this.props.process,
      this.props.processType
    )
    console.log('currentStepType', currentStepType)
    return (
      <div className="CrmMdCard util-hooverOp util-pointer">
        <div
          className={`${styles.ProcessHeaderX} 
                ${
                  currentStepType
                    ? styles['ProcessHeader--' + currentStepType.kind]
                    : ' '
                }
                `}
        >
          {currentStepType && (
            <span>
              <strong>{currentStepType.name}</strong>
              <span style={{ opacity: 0.8 }}>
                {' '}
                <FormattedMessage
                  id="crm.account.by"
                  defaultMessage="by"
                />{' '}
              </span>
            </span>
          )}
          {process.owner_user && (
            <span>
              <strong>{process.owner_user.full_name} </strong>
            </span>
          )}
          {process.completed_time && (
            <span>
              {' '}
              <span style={{ opacity: 0.8 }}>
                {' '}
                <FormattedMessage
                  id="crm.account.at"
                  defaultMessage="at"
                />{' '}
              </span>
              <strong>
                <FormattedDate value={process.completed_time} />
              </strong>
            </span>
          )}
          <div style={{ textAlign: 'center', padding: 10 }}>
            <div>
              <FormattedMessage
                id="crm.account.if_you_need_to_register_more_sales_go_to_opportunities_tabs_and_create_new_opportunity_for_customer"
                defaultMessage="If you need to register more sales - go to Opportunities tabs and
            create new opportunity for customer."
              />
            </div>
            {/* <CrmButton type="primary" label="Start new Sales" /> */}
          </div>
        </div>
      </div>
    )
  }
}

LastSalesResult.propTypes = {
  hasAccountDataPermission: bool
}
LastSalesResult.defaultProps = {
  hasAccountDataPermission: false
}

export default processWidgetHoc(injectIntl(LastSalesResult))
