/* eslint-disable camelcase */
import React from 'react'
import { shape, func, string, bool } from 'prop-types'
import gql from 'graphql-tag'
import cloneDeep from 'clone-deep'
import { FormattedMessage } from 'react-intl'
import { graphql, compose } from 'react-apollo'
import MarketPotientialSizeInput from './_market-potential-text-input.jsx'
import { qlDictionary } from 'crm-api/settings/dictionaries-api'
import { CrmButton, FlatTable } from 'crm-components'
import {
  qlCreateMarketPotentialMutation,
  qlGetMartketPotential,
  qlListMartketPotential
} from 'crm-data/account-market-potential'
import { qlRefetchAccountStatus } from 'crm-data/accounts.js'
import { withCrmContext } from 'crm-core/contexts/crm-context'
import { CrmTextInput } from '@cartrack-crm/ui'

class MarketPotentialDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedTypeUid: undefined,
      marketSizeValues: {},
      currentMarketPotential: props.currentMarketPotential?.account
        ?.market_potential
        ? cloneDeep(props.currentMarketPotential.account.market_potential)
        : { details: {} },
      mode: this.props.mode
    }
  }
  componentDidMount = () => {
    const { onSetHandleFromChild } = this.props
    if (onSetHandleFromChild) {
      onSetHandleFromChild(this.handleClickSave)
    }
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  handleOnTypeChange = value => {
    this.setState({ selectedTypeUid: value })
  }

  onSizeChange = (dictionaryValue, size) => {
    const market_potential_type_uid = dictionaryValue.dictionary_value_uid
    const currentMarketPotential = {
      details: { items: [] },
      ...cloneDeep(this.state.currentMarketPotential)
    }
    if (!currentMarketPotential.details) {
      currentMarketPotential.details = {}
    }
    if (!currentMarketPotential.details.items) {
      currentMarketPotential.details.items = []
    }
    currentMarketPotential.details.items = currentMarketPotential.details.items.filter(
      i =>
        i.market_potential_type_uid !== market_potential_type_uid &&
        i.size !== ''
    )
    currentMarketPotential.details.items.push({
      size,
      type: size > 0 ? dictionaryValue.type : null,
      market_potential_type_uid: dictionaryValue.dictionary_value_uid
    })
    this.setState({ currentMarketPotential })
  }

  handleSlFormSave = async potentialItems => {
    this.props.slFormOnSave({ data: { potentialItems }, commands: [] })
  }
  handleClickSave = async potentialItems => {
    // SL Form mode
    if (this.props.slFormOnSave) {
      return this.handleSlFormSave(potentialItems)
    }
    await this.setStateAsync({ isSaving: true })
    const marketPotential = {
      items: this.state.currentMarketPotential.details.items,
      account_uid: this.props.accountUid,
      other_size: this.state.currentMarketPotential.other_size
    }
    await this.props.createMarketPotentialMutation({
      variables: { marketPotential },
      refetchQueries: [
        {
          query: gql(qlGetMartketPotential),
          variables: { account_uid: this.props.accountUid },
          partialRefetch: true
        },
        {
          query: gql(qlListMartketPotential),
          variables: { account_uid: this.props.accountUid },
          partialRefetch: true
        },
        {
          query: gql(qlRefetchAccountStatus),
          variables: { account_uid: this.props.accountUid },
          partialRefetch: true
        }
      ]
    })
    this.setState({ mode: 'readonly' })
    await this.setStateAsync({ isSaving: false })
    this.props.data.refetch()
    if (this.props.onSaved) {
      this.props.onSaved()
    }
  }

  handleClickEdit = () => {
    this.setState({ mode: 'edit' })
  }

  componentWillReceiveProps = nextProps => {
    const nextState = { ...this.state }
    if (
      nextProps.currentMarketPotential !== this.props.currentMarketPotential &&
      !nextProps.currentMarketPotential.loading &&
      nextProps.currentMarketPotential.market_potential
    ) {
      try {
        const currentMarketPotential =
          nextProps.currentMarketPotential &&
          nextProps.currentMarketPotential.account
            ? cloneDeep(
                nextProps.currentMarketPotential.account.market_potential
              )
            : undefined

        nextState.currentMarketPotential = currentMarketPotential
      } catch (err) {
        console.error("Can't parse market potential details")
      }
    }
    // Handle mode update from the outside
    if (nextProps.mode !== this.props.mode) {
      nextState.mode = nextProps.mode
    }
    this.setState(nextState)
  }

  handleClickCancel = () => {
    this.setState({ mode: 'readonly' })
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }
  getColumns = () => [
    {
      Header: 'Type',
      labelMessageId: 'crm.ui.account.history.type',
      accessor: 'type',
      key: 'type'
    },
    {
      Header: 'Size',
      labelMessageId: 'crm.ui.account.history.size',
      accessor: 'size',
      key: 'size',
      Cell: row => (
        <MarketPotientialSizeInput
          marketPotential={this.state.currentMarketPotential}
          row={row.original}
          handleSizeChange={this.onSizeChange}
          mode={this.state.mode}
        />
      )
    }
  ]

  handleOtherSizeChange = event => {
    const currentMarketPotential = { ...this.state.currentMarketPotential }
    currentMarketPotential.other_size = event.currentTarget.value
    this.setState({ currentMarketPotential })
  }

  _renderSimplified = () => {
    return (
      <div className="util-flexRow">
        <div>
          <CrmTextInput
            placeholder="Size"
            placeholderId="crm.ui.account.market.size"
            input={{
              value: this.state.currentMarketPotential?.other_size
                ? this.state.currentMarketPotential.other_size
                : null,
              onChange: this.handleOtherSizeChange
            }}
          />
        </div>
        <div className="util-marginLeft">
          <CrmButton
            onClick={this.handleClickSave}
            type="primary"
            label={
              this.state.isSaving ? (
                <FormattedMessage
                  id="crm.ui.button.saving"
                  defaultMessage="Saving.."
                />
              ) : (
                <FormattedMessage
                  id="crm.ui.button.save"
                  defaultMessage="Save"
                />
              )
            }
          />
        </div>
      </div>
    )
  }

  render() {
    const use_advanced = this.props.crmContext.getAttributeValueByCode(
      'use_advanced_market_potential'
    )
    const { mode } = this.state
    let tableData = []
    const columns = this.getColumns()
    const getSize = type => {
      if (
        this.state.currentMarketPotential &&
        this.state.currentMarketPotential.details &&
        this.state.currentMarketPotential.details.items
      ) {
        const marketSize = this.state.currentMarketPotential.details.items.find(
          item => item.market_potential_type_uid === type
        )
        return marketSize ? marketSize.size : undefined
      }
      return undefined
    }
    if (this.props.data.fleetValues) {
      tableData = this.props.data.fleetValues.values.map(value => ({
        type: value.name,
        size: getSize(value.dictionary_value_uid),
        dictionary_value_uid: value.dictionary_value_uid,
        id: value.dictionary_value_uid
      }))
      tableData.sort((a, b) => a.type.localeCompare(b.type))
    }
    return (
      <div>
        <div>
          {use_advanced && tableData && (
            <FlatTable data={tableData} columns={columns} tableName="account" />
          )}
          {!use_advanced && this._renderSimplified()}
        </div>
        {this.props.showEditButtons && (
          <div style={{ marginTop: '15px', textAlign: 'right' }}>
            {mode === 'edit' && (
              <div>
                <CrmButton
                  label={
                    <FormattedMessage
                      id="crm.ui.button.cancel"
                      defaultMessage="Cancel"
                    />
                  }
                  onClick={this.handleClickCancel}
                  type="secondary"
                  grouped
                />
                <CrmButton
                  type="primary"
                  label={
                    <FormattedMessage
                      id="crm.ui.button.save"
                      defaultMessage="Save"
                    />
                  }
                  isSaving={this.state.isSaving}
                  enable={!this.state.isSaving}
                  onClick={this.handleClickSave}
                />
              </div>
            )}

            {mode !== 'edit' && (
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.button.edit"
                    defaultMessage="Edit"
                  />
                }
                onClick={this.handleClickEdit}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

const createMarketPotentialMutation = gql(qlCreateMarketPotentialMutation)
const fleetValuesDictionaryQL = gql(`query dictionary($code: String!) {
  fleetValues: dictionary(code: $code) {${qlDictionary} }
}`)

const MarketPotentialModalQL = compose(
  graphql(fleetValuesDictionaryQL, {
    options: () => ({ variables: { code: 'crm.minutes.fleet_size.value' } })
  }),
  graphql(createMarketPotentialMutation, {
    name: 'createMarketPotentialMutation'
  }),
  graphql(gql(qlGetMartketPotential), {
    name: 'currentMarketPotential',
    options: ownProps => ({
      variables: {
        account_uid: ownProps.accountUid
      }
    }),
    skip: ownProps => !ownProps.accountUid
  })
)(MarketPotentialDetails)

MarketPotentialDetails.propTypes = {
  onCancel: func,
  mode: string.isRequired,
  currentMarketPotential: shape({}).isRequired,
  accountUid: string.isRequired,
  onSaved: func.isRequired,
  createMarketPotentialMutation: func.isRequired,
  data: shape({}).isRequired,
  slFormOnSave: func,
  onSetHandleFromChild: func,
  showEditButtons: bool,
  crmContext: shape()
}

MarketPotentialDetails.defaultProps = {
  onCancel: undefined,
  ctRuntime: undefined,
  slFormOnSave: undefined,
  showEditButtons: true
}

export default withCrmContext(MarketPotentialModalQL)
