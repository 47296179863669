import { groupBy } from 'lodash'

const s = 1000
const m = s * 60
const h = m * 60
const d = h * 24

export const dayNames = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}

export const utilRanges = {
  lastWeek() {
    return {
      from: new Date(Date.now() - 8.64e7 * 7),
      to: new Date()
    }
  },
  today() {
    return expandDateRange({ from: new Date(), to: new Date() })
  },
  todayWorking() {
    const from = new Date()
    const to = new Date()
    from.setHours(5, 0, 0, 0)
    to.setHours(20, 0, 0, 0)
    return {
      from,
      to
    }
  }
}

export function utcTimestampToMs(ts) {
  if (!ts) return 0
  const t = ts.split(/[- :+]/)
  const ms = Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
  return t[6] ? ms - Number(t[6]) * 1000 * 60 * 60 : ms
}

export function leftPad(num, width, padding = '0') {
  const n = String(num)
  return n.length >= width
    ? n
    : new Array(width - n.length + 1).join(padding) + n
}

export function msToUTCTimestamp(ms, time = true) {
  const d = new Date(ms)
  const year = d.getUTCFullYear()
  const month = leftPad(d.getUTCMonth() + 1, 2)
  const date = leftPad(d.getUTCDate(), 2)

  if (!time) return `${year}-${month}-${date}`

  const hours = leftPad(d.getUTCHours(), 2)
  const minutes = leftPad(d.getUTCMinutes(), 2)
  const seconds = leftPad(d.getUTCSeconds(), 2)
  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}+00`
}

export function dateTimeToDateString(dateTime, { utc = true } = {}) {
  const t = new Date(dateTime)
  const UTC = utc ? 'UTC' : ''
  const year = t['get' + UTC + 'FullYear']()
  const month = leftPad(t['get' + UTC + 'Month']() + 1, 2)
  const date = leftPad(t['get' + UTC + 'Date'](), 2)
  return `${year}-${month}-${date}`
}

export function dateStringToDateTime(
  dateString,
  { time = true, utc = true } = {}
) {
  const t = new Date(dateString)
  if (utc) t.setTime(t.getTime() + t.getTimezoneOffset() * 60000)
  return time ? t.getTime() : t
}

export function dateTimeToLocalTimestamp(
  dateTime,
  { time = true, timezone = false } = {}
) {
  const t = new Date(dateTime)
  const year = t.getFullYear()
  const month = leftPad(t.getMonth() + 1, 2)
  const date = leftPad(t.getDate(), 2)

  if (!time) return `${year}-${month}-${date}`

  const hours = leftPad(t.getHours(), 2)
  const minutes = leftPad(t.getMinutes(), 2)
  const seconds = leftPad(t.getSeconds(), 2)
  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}${
    timezone ? getISOTimezoneOffset() : ''
  }`
}

export function utcTimestampDateRange(
  date,
  modifier,
  { modifyBoth = false, time = true } = {}
) {
  const sD = new Date(date.getTime())
  const eD = new Date(date.getTime())

  if (modifier) {
    sD.setDate(sD.getDate() + modifier)
    if (modifyBoth) eD.setDate(eD.getDate() + modifier)
  }

  sD.setHours(0, 0, 0, 0)
  eD.setHours(23, 59, 59, 999)

  return {
    start: msToUTCTimestamp(sD.getTime(), time),
    end: msToUTCTimestamp(eD.getTime(), time),
    startMs: sD.getTime(),
    endMs: eD.getTime()
  }
}

export function dateTimesToUTCTimestampRange(
  startDateTime,
  endDateTime = startDateTime,
  { expand = true, time = true } = {}
) {
  const startDate = new Date(startDateTime)
  const endDate = new Date(endDateTime)
  if (expand) {
    startDate.setHours(0, 0, 0, 0)
    endDate.setHours(23, 59, 59, 999)
  }

  const startDateTZOffset = startDate.getTimezoneOffset()
  const endDateTZOffset = endDate.getTimezoneOffset()
  if (startDateTZOffset !== endDateTZOffset) {
    startDate.setTime(
      startDate.getTime() + (endDateTZOffset - startDateTZOffset) * 60000
    )
  }

  return {
    startTS: msToUTCTimestamp(startDate, time),
    endTS: msToUTCTimestamp(endDate, time),
    startDate,
    endDate
  }
}

export function modifyDate(date, modifier) {
  const newDate = new Date(date)
  const parts = modifier.split(':')
  return parts.length > 3
    ? new Date(
        newDate.getTime() +
          parts[0] * d +
          parts[1] * h +
          parts[2] * m +
          parts[3] * s
      )
    : new Date(newDate.getTime() + parts[0] * h + parts[1] * m + parts[2] * s)
}

export function isDate(date) {
  const d = new Date(date)
  return !isNaN(d)
}

export function timeToMs(time) {
  if (!time) return 0
  const parts = time.split(':').map(Number)
  return parts.length === 3
    ? parts[0] * h + parts[1] * m + parts[2] * s
    : parts[0] * h + parts[1] * m
}

export function msToDuration(ms, days = false) {
  const segments = [3600000, 60000, 1000]
  if (days) segments.unshift(86400000)
  let remainder = ms
  let time = ''
  segments.forEach(t => {
    const segment = Math.floor(remainder / t)
    remainder %= t
    time += leftPad(segment, 2) + ':'
  })
  return time.slice(0, -1)
}

export function daysApart(dateTime, otherDateTime) {
  const d1 = new Date(dateTime)
  const d2 = new Date(otherDateTime)
  const tZO1 = d1.getTimezoneOffset() * 60000
  const tZO2 = d2.getTimezoneOffset() * 60000
  const t1 = d1.getTime() - tZO1
  const t2 = d2.getTime() - tZO2

  return Math.abs(Math.floor(t1 / 8.64e7) - Math.floor(t2 / 8.64e7))
}

export function daysAgo(dateOrMs) {
  const d = new Date(dateOrMs)
  const t = new Date()
  d.setHours(0, 0, 0, 0)
  t.setHours(0, 0, 0, 0)

  return Math.floor((t.getTime() - d.getTime()) / 86400000)
}

export function isCurrentDay(dateOrMs) {
  const today = new Date()
  const date = typeof dateOrMs === 'number' ? new Date(dateOrMs) : dateOrMs

  return today.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)
}

export function timeLeftToTimeOfDay(ms) {
  const date = new Date()

  const now = date.getTime()
  const todayInMs = date.setHours(0, 0, 0, 0)

  const nowFromToday = now - todayInMs

  return nowFromToday + ms
}

export function groupByDay(arr, dateAccessor, { inclEmpty, from, to }) {
  if (arr.length === 0 && !from && !to) return {}

  const groupedByDay = groupBy(arr, x => {
    const d = new Date(x[dateAccessor])
    d.setHours(0, 0, 0, 0)
    return d.getTime()
  })

  if (inclEmpty || (from && to)) {
    const first = from || new Date(arr[0][dateAccessor])
    first.setHours(0, 0, 0, 0)
    const last = to || new Date(arr[arr.length - 1][dateAccessor])
    last.setHours(0, 0, 0, 0)
    const totalDays = daysApart(first, last) + 1

    const days = {}

    const tempDate = new Date(first)
    for (let i = 0; i < totalDays; i++) {
      tempDate.setDate(tempDate.getDate() + i)
      const ms = tempDate.getTime()
      days[ms] = groupedByDay[ms] || []
    }

    return days
  }
  return groupedByDay
}

export function getISOTimezoneOffset() {
  // Get timezone offset in minutes
  let mins = new Date().getTimezoneOffset()
  // Choose sign
  const sign = mins > 0 ? '-' : '+'
  // Convert negatives since we already decided on sign
  mins = Math.abs(mins)

  // Hours (truncate towards 0)
  const hours = (mins / 60) | 0
  // Remainder minutes
  mins %= 60

  // Return string
  return sign + leftPad(hours, 2) + ':' + leftPad(mins, 2)
}

export function durationToString(
  ms,
  short = false,
  granularity,
  maxSegments = 4
) {
  const segments = ['days', 'hours', 'minutes', 'seconds']

  // Not going down to ms, but most dates are stored in ms currently
  const totalSeconds = Math.round(ms / 1000)

  // Split into sections
  const s = totalSeconds % 60
  const m = Math.floor((totalSeconds / 60) % 60)
  const h = Math.floor((totalSeconds / 3600) % 24)
  const d = Math.floor(totalSeconds / (3600 * 24))

  // Accept string to limit granularity
  const granularityIndex = segments.indexOf(granularity) + 1 || 4

  // Filter out 0's, join to string
  return [d, h, m, s]
    .map((x, i) => {
      if (x === 0) return false

      // Singular
      if (x === 1 && !short) {
        return x + ' ' + segments[i].slice(0, -1)
      }

      // Plural or short
      return x + (short ? '' : ' ') + segments[i].slice(0, short ? 1 : Infinity)
    })
    .slice(0, granularityIndex)
    .filter(a => a)
    .slice(0, maxSegments)
    .join(', ')
}

export function expandDateRange(dateRange) {
  const from = new Date(dateRange.from)
  const to = new Date(dateRange.to)
  from.setHours(0, 0, 0, 0)
  to.setHours(23, 59, 59, 999)
  return {
    from,
    to
  }
}

export function addDays(dateTime, days) {
  const d = new Date(dateTime)
  d.setDate(d.getDate() + days)
  return d
}

export function timeStringToMs(str) {
  const res = /([012]?\d):(\d\d) ?([aApP][mM])?/.exec(str)
  if (!res) return null

  const amPmOffset =
    res[3] && res[3].toLowerCase() === 'pm' && res[1] !== '12' ? 4.32e7 : 0
  const isMidnight = res[1] === '12' && res[3] && res[3].toLowerCase() === 'am'

  return isMidnight
    ? Number(res[2]) * 6e4
    : Number(res[1]) * 3.6e6 + Number(res[2]) * 6e4 + amPmOffset
}

const demoTimeDiffDate = new Date()
export const demoTimeDiff =
  demoTimeDiffDate.setHours(23, 59, 59, 9999) -
  (1123208999 + demoTimeDiffDate.getTimezoneOffset() * 60000)
