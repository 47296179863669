import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDateTime } from 'crm-components'

type SectionDuplicateLeadsProps = {
  account: any
}
const SectionDuplicateLeads: React.SFC<SectionDuplicateLeadsProps> = ({
  account
}) => (
  <div>
    {account &&
      account.duplicate_leads &&
      account.duplicate_leads.map(d => (
        <div key={d.account_uid} className="row">
          <div className="col-md-4">
            <Link to={`/crm/account/${d.account_uid}`}>{d.name}</Link>
          </div>

          <div className="col-md-4" title="Lead in time">
            <FormattedDateTime value={d.lead_in_time} />
          </div>
          <div className="col-md-4" title="Lead Campaign">
            {d.lead_campaign ? d.lead_campaign.name : ''}
          </div>
        </div>
      ))}
  </div>
)

export default SectionDuplicateLeads
