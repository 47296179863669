import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { shape } from 'prop-types'
import MeInstanceForm from './modules/settings/me/components/instance-form.jsx'
import { getSelectedInstance } from 'crm-duxs/crm-reducer'
import { LoadingIcon } from 'util-components'

class CrmInitialForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div
        className="Login"
        style={{ minWidth: '300px', maxHeight: '500px', top: '40%' }}
      >
        <h2>Cartrack MiCRM</h2>
        {!this.props.instance && <MeInstanceForm showWithTitle={false} />}
        {this.props.instance && <LoadingIcon />}
      </div>
    )
  }
}

CrmInitialForm.propTypes = {
  instance: shape({})
}
CrmInitialForm.defaultProps = {
  instance: undefined
}
function mapStateToProps(state) {
  return {
    instance: getSelectedInstance(state)
  }
}

export default connect(mapStateToProps)(CrmInitialForm)
