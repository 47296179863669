import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Downshift from 'downshift'
import { debounce } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Icon } from '../icon/icon'
import fuzzySearch from '../../../../../util-functions/search-utils'
import { CrmTextInput } from '@cartrack-crm/ui'
interface IDropdownFetchMore {
  item: any
}
class DropdownFetchMore extends PureComponent<IDropdownFetchMore> {
  handleOnClick = event => {
    event.preventDefault()
    this.props.item.onClick()
  }

  render() {
    return (
      <div
        onClick={this.handleOnClick}
        style={{
          color: 'blue',
          textAlign: 'center',
          textDecoration: 'underline'
        }}
      >
        Show more
      </div>
    )
  }
}

interface InputDropdownProps {
  disableFilter?: boolean
  filterWithApi?: boolean
  filterPredicate?: any
  showFetchMore?: boolean
  itemSearchTransform?: any
  disableOptionIntl?: boolean
  maxItems?: number
  disableInput?: boolean
  disabled?: boolean
  returnItem?: boolean
  input: any
  activeOption?: any
  defaultInputValue?: string
  observerSelectedChange?: any
  onStateChange?: any
  itemToString?: any
  toolboxRenderer?: any
  onInputChange?: any
  placeholder?: any
  icon?: any
  iconName?: string
  options: any
  onChange: any
  extraClassNames?: {
    containerClassNames?: string
  }
  addEmptyOption?: boolean
  emptyOptionLabel?: string
  required?: boolean
  isLoading?: boolean
  onFetchMore?: any
  value?: string
  className?: string
  style?: any
  intl?: any
}
interface InputDropdownState {
  inputValue?: string
  inputFocus?: boolean
  forceAllOptions?: any
  options?: any
  selectedItem?: string
}
class InputDropdown extends React.Component<InputDropdownProps, InputDropdownState> {
  inputDropdownOptions
  filterInputValue
  openMenu
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      inputFocus: false
    }

    this.inputDropdownOptions = React.createRef()
    this.filterInputValue = debounce(this.filterValue, 500)
  }

  static itemToString = item => (item && item.name) || ''

  componentWillMount() {
    const { activeOption, options } = this.props
    this.setState({
      options: this.resolveOptions(this.props),
      selectedItem: this.resolveSelected(activeOption, options)
    })
  }

  componentWillReceiveProps(nextProps) {
    let newState
    if (nextProps.options !== this.props.options) {
      newState = {}
      newState.options = this.resolveOptions(nextProps)
    }
    if (
      nextProps.activeOption !== this.props.activeOption ||
      nextProps.input !== this.props.input ||
      nextProps.options !== this.props.options
    ) {
      newState = newState || {}
      let value = nextProps.input ? nextProps.input?.value : nextProps.activeOption
      if (typeof value === 'object' && value?.value) {
        value = value?.value?.[0]
      }
      newState.selectedItem = this.resolveSelected(value, newState.options ? newState.options : this.props.options)
    }

    if (newState) this.setState(newState)
  }

  resolveSelected = (item: any, options: any) =>
    item === Object(item) ? item : options ? options.find(o => o?.value === item) : undefined

  resolveOptions(
    { options, disableInput, disableFilter, itemSearchTransform, filterPredicate }: any,
    inputValue: any = undefined
  ) {
    // Necessary to allow use within handleInputChange
    const filterStr = inputValue === undefined ? this.state.inputValue : inputValue

    if (disableFilter || !filterStr || disableInput || this.state.forceAllOptions) return options

    let filtered = options.filter((o, i, arr) => {
      // Allow custom transform to search single/compound values
      const item = itemSearchTransform ? itemSearchTransform(o) : o
      // Default to fuzzy string search
      return filterPredicate ? filterPredicate(item, filterStr, i, arr) : fuzzySearch(item, filterStr)
    })

    if (this.props.maxItems) filtered = filtered.slice(0, this.props.maxItems)
    return filtered
  }

  handleInputFocus = () => {
    this.handleFilterWithInputValue('')
    this.setState({
      forceAllOptions: true,
      inputFocus: true,
      inputValue: ''
    })

    if (this.openMenu) this.openMenu()
  }

  handleInputChange = e => {
    const value = e.currentTarget.value
    this.handleFilterWithInputValue(value)
  }

  handleInputBlur = () => {
    if (this.props.filterWithApi) this.handleFilterWithInputValue('')

    this.setState({
      inputFocus: false,
      inputValue: ''
    })
  }

  filterValue = value => {
    this.props.onInputChange(value)
  }

  handleFilterWithInputValue = value => {
    if (this.props.onInputChange && this.props.filterWithApi) {
      this.filterInputValue(value)
      this.setState({
        inputValue: value
      })
    } else if (!this.props.filterWithApi) {
      this.setState({
        inputValue: value,
        options: this.resolveOptions(this.props, value),
        forceAllOptions: false
      })
    }
  }

  handleDownshiftOnChange = item => {
    const onChange = this.props.input && this.props.input.onChange ? this.props.input.onChange : this.props.onChange

    if (typeof onChange !== 'function') {
      return
    }

    if (this.props.returnItem) {
      onChange(item)
    } else {
      onChange(item?.value, item)
    }

    if (typeof this.props.observerSelectedChange === 'function') {
      this.props.observerSelectedChange(item?.value)
    }

    this.setState({
      forceAllOptions: false,
      inputFocus: false,
      inputValue: ''
    })
  }

  refWithOptions = input => {
    this.inputDropdownOptions = input
    if (this.inputDropdownOptions !== null) this.handleOptionsScroll()
  }

  renderDropdown = ({
    getInputProps,
    getButtonProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    selectedItem,
    openMenu,
    closeMenu,
    inputValue
  }) => {
    const {
      extraClassNames,
      placeholder,
      icon,
      iconName,
      disableInput,
      disabled,
      disableOptionIntl,
      required,
      addEmptyOption,
      emptyOptionLabel
    } = this.props

    const options = this.state.options ? [...this.state.options] : []
    const isWithValue = selectedItem ? 'is-withValue' : ''
    this.openMenu = openMenu

    if (addEmptyOption) {
      options.unshift({
        name: this.props.isLoading ? 'Loading...' : emptyOptionLabel,
        value: ''
      })
    }

    if (this.props.showFetchMore) {
      options.push({
        Component: DropdownFetchMore,
        onClick: this.props.onFetchMore
      })
    }

    const inputProps = getInputProps({
      placeholder,
      icon,
      iconName,
      disabled,
      disableAutofill: true,
      onFocus: this.handleInputFocus,
      onClick: this.handleInputFocus,
      onChange: this.handleInputChange
    })

    let dropDownValue = inputValue !== '' && this.props?.value === '' && this.state.inputValue === '' ? '' : inputValue

    if (this.state.inputFocus) {
      dropDownValue = this.state.inputValue
    }

    inputProps.value = dropDownValue

    return (
      <div
        className={`InputDropdown ${extraClassNames?.containerClassNames} ${
          placeholder === ' ' ? 'InputDropdown--noPlaceholder' : ''
        }`}
      >
        <div className="InputDropdown-container">
          {disableInput ? (
            <button className={`InputDropdown-button ${isWithValue}`} {...getButtonProps()}>
              <div className={`InputDropdown-button-placeholder ${isWithValue}`}>{placeholder}</div>
              {dropDownValue}
            </button>
          ) : (
            <CrmTextInput {...inputProps} autocomplete="off" onBlur={this.handleInputBlur} />
          )}

          {this.props.toolboxRenderer !== undefined && this.props.toolboxRenderer()}

          {!icon && !iconName && !disabled && (
            <Icon
              name="caret-down"
              className={`InputDropdown-arrow ${isOpen ? 'is-open' : ''}`}
              onClick={isOpen ? closeMenu : openMenu}
            />
          )}
          {required && <span className="InputDropdown-required">*</span>}
          {isOpen && options.length > 0 && (
            <div ref={this.refWithOptions} className="InputDropdown-options">
              {options.map((item, index) => (
                <div
                  key={index}
                  {...getItemProps({
                    item,
                    index,
                    className: `InputDropdown-options-option ${highlightedIndex === index ? 'is-focused' : ''} ${
                      item === selectedItem ? 'is-selected' : ''
                    }
                      ${
                        item.is_deleted === true || item.is_active === false || item.is_active === null
                          ? ' is-deleted'
                          : ''
                      }
                      `
                  })}
                >
                  {item.Component ? (
                    <item.Component item={item} />
                  ) : disableOptionIntl ? (
                    item.name
                  ) : (
                    <FormattedMessage id={`dropdown.option.${item.name}`} defaultMessage={item.name} />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  handleOptionsScroll = () => {
    this.inputDropdownOptions.addEventListener('scroll', this.trackScrolling)
  }

  trackScrolling = () => {
    if (
      this.props.showFetchMore &&
      this.inputDropdownOptions.scrollHeight -
        (this.inputDropdownOptions.scrollTop + this.inputDropdownOptions.clientHeight) <
        100
    ) {
      this.props.onFetchMore()
    }
  }

  render() {
    return (
      <Downshift
        selectedItem={this.state.selectedItem}
        itemToString={this.props.itemToString || InputDropdown.itemToString}
        onChange={this.handleDownshiftOnChange}
        onSelect={this.handleDownshiftOnChange}
        onStateChange={this.props.onStateChange}
        defaultInputValue={this.props.defaultInputValue}
        defaultHighlightedIndex={0}
      >
        {this.renderDropdown}
      </Downshift>
    )
  }
}

export default InputDropdown

function mapStateToProps(state, { options, input: { value: activeOption } }) {
  const isFunc = typeof options === 'function'
  return {
    options: isFunc ? options(state) : options,
    activeOption
  }
}

export const CrmFormInputDropdown = props => (
  <InputDropdown {...props} {...props.input} activeOption={props.input ? props.input.value : props.activeOption} />
)

export const ConnectedDropdown = connect(mapStateToProps)(CrmFormInputDropdown)
