import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { func, shape } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getRecipientEmails } from 'crm-duxs/account-reducer'

class EmailLabel extends PureComponent {
  renderEmails = () => {
    const emails = this.props.emails
    const renderHtml = []

    Object.entries(emails).forEach(entry => {
      const [key, data] = entry

      const removeEmail = () => this.props.handleRemoveEmail(data)

      renderHtml.push(
        <div key={key} className="CrmChip CrmChip-green util-marginBottom">
          {data.value}
          <span style={{ color: 'red' }}>
            <FontAwesomeIcon
              title="Remove"
              className="util-marginLeft"
              icon="times"
              onClick={removeEmail}
            />
          </span>
        </div>
      )
    })

    return renderHtml
  }

  render() {
    return <div>{this.renderEmails()}</div>
  }
}

EmailLabel.propTypes = {
  handleRemoveEmail: func.isRequired,
  emails: shape({}).isRequired
}

EmailLabel.defaultProps = {}

function mapStateToProps(state) {
  return {
    emails: getRecipientEmails(state)
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailLabel)
