/* eslint-disable camelcase, no-restricted-globals*/
import React from 'react'
import { bool, func, shape } from 'prop-types'
import { Link } from 'react-router-dom'

import { FormattedDate } from 'react-intl'
import { mapContextToFilter } from '../dashboard-utils'
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { CrmModal } from 'crm-components'
import genericTableFactory from 'crm-components/ql/table/crm-generic-dynamic-table.tsx'

const columns = [
  {
    Header: 'Account Name',
    Cell: row => (
      <Link
        to={'/crm/account/' + row.original.account_uid + '/sales'}
        target="blank"
      >
        {row.original.account?.name}
      </Link>
    ),
  },
  {
    Header: 'Lead Campaign',
    Cell: row => row?.original?.account?.lead_campaign?.name
  },
  {
    Header: 'First Meeting Date',
    accessor: 'first_meeting_date',
    Cell: row =>
      row.original.first_meeting_date ? (
        <span>
          <FormattedDate value={row.original.first_meeting_date} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Meetings Till Win',
    accessor: 'meetings_till_win_count'
  },
  {
    Header: 'Days Till Win',
    accessor: 'days_till_win'
  },
  {
    Header: 'Contract Signed Date',
    accessor: 'contract_signed_date',
    Cell: row =>
      row.original.contract_signed_date ? (
        <span>
          <FormattedDate value={row.original.contract_signed_date} />
        </span>
      ) : (
        <span />
      )
  },
  {
    Header: 'Size',
    accessor: 'vehicle_count'
  },
  {
    Header: 'Owner',
    Cell: row => row.original?.owner?.full_name
  }
]

const defaultQueryFields = {
  selections: [
    { name: 'account_uid' },
    { name: 'opportunity_uid' },
    { name: 'account_uid' },
    { name: 'vehicle_count' },
    { name: 'owner_user_uid' },
    { name: 'contract_signed_date' },
    { name: 'meetings_till_win_count' },
    { name: 'first_meeting_date' },
    { name: 'days_till_win' },
    {
      name: 'account',
      selections: [
        { name: 'name' },
        { name: 'lead_campaign', selections: [{ name: 'name' }] }
      ]
    },
    {
      name: 'owner',
      selections: [{ name: 'full_name' }]
    }
  ]
}
const qlOptions = {
  options: ownProps => {
    const props = { analyticsContext: { filters: ownProps.filters } }
    const filter = { ...ownProps.filters, ...mapContextToFilter(props) }
    if (ownProps.filters.activity_date && !filter.contract_signed_date) {
      if (ownProps.filters.activity_date.value) {
        filter.contract_signed_date = ownProps.filters.activity_date
      } else {
        filter.contract_signed_date = ownProps.filters.activity_date
      }
    }

    return {
      variables: {
        filter,
        groupBy: ['owner_user_uid', "account_uid", "opportunity_uid"],
        aggregate: [
          { "id": "meetings_till_win_count", "type": "avg" },
          { "id": "days_till_win", "type": "avg" }
        ]
      }
    }
  },
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.genericDataQuery : []
  }),
  fetchPolicy: 'no-cache'
}

const Table = genericTableFactory({
  query: qlqGenericDataQuery,
  qlOptions,
  rootQLType: 'OpportunityMeetingStatus',
  useEdges: true,
  queryRoot: 'opportunity_meeting_status',
  columnOptions: [],
  defaultQueryFields
})

class OpportunitiesPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <CrmModal
        isOpen={this.props.isOpen}
        title="Opportunities Details with Meetings Stats"
        onClose={this.props.onClose}
        size="xl"
      >
        <Table
          columns={columns}
          style={{ width: '100%' }}
          filters={this.props.filters}
        />
      </CrmModal>
    )
  }
}

OpportunitiesPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  filters: shape({})
}
OpportunitiesPopup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  filters: undefined
}

export default OpportunitiesPopup
