import React from 'react'
import { columnAcountHealth } from '../../accounts/list/accounts-table-inner.jsx'
import moment from 'moment'
import { Link } from 'react-router-dom'
const numberCell = value => (
  <span className="util-textRight util-fullWidth">{value}</span>
)

const columns = [
  columnAcountHealth,
  {
    Header: 'User',
    accessor: 'user_uid',
    Cell: row => {
      return (
        <div>
          <Link to={`/crm/manager/forecasts/${row.original.forecast_uid}`}>
            {row.original.user.full_name}
          </Link>
        </div>
      )
    }
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: row => (
      <div>
        {row.original.status === 'forecast.hadInit' ? 'init' : 'confirmed'}
      </div>
    )
  },
  {
    Header: 'Forecast Date',
    accessor: 'month_date',
    Cell: row => (
      <div>
        {moment(row.original.month_date, 'YYYY-MM').format('MMMM YYYY')}
      </div>
    )
  },
  {
    Header: 'Forecast size',
    accessor: 'forecast',
    Cell: row => {
      if (row.original.status === 'forecast.hadInit') {
        return numberCell(0)
      } else {
        let summary = 0
        row.original.items.map(el => summary += el.quantity*1)
        return numberCell(summary)
      }
    }
  },
]

export default columns
