export enum DynamicTableViewMode {
  TABLE = 'TABLE',
  PIVOT = 'PIVOT',
  CHART = 'CHART',
  DASHBOARD = 'DASHBOARD'
}

export enum ChartTypes {
  PIE = 'PIE',
  LINEAR = 'LINEAR'
}
