import React, { useState, useEffect } from 'react'
import { useClickOutside } from 'react-click-outside-hook'
import { useGetDashboardOptions } from '../hooks/report-action-hook'
import {
  FilterSection,
  FiltersOptionList,
  GenericDropDownFilter,
  GenericDropDownContainer,
  GenericClearFilter
} from '@cartrack-crm/crm-filter-section/src/tools'
import { DashboardProps, ReportTypeDropDownProps } from '../type'

export const FilterSectionDashboard = (props: DashboardProps) => {
  const { dashboard, setDashboard } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleDropDown = () => setIsExpanded(!isExpanded)
  const closeDropDown = () => setIsExpanded(false)
  const [ref, hasClickOutside] = useClickOutside()
  useEffect(() => {
    closeDropDown()
  }, [hasClickOutside])

  return (
    <FilterSection
      name="Report Type"
      selectedValue={<strong>{dashboard?.label ?? 'Select value'}</strong>}
      onToggle={toggleDropDown}
      isExpanded={isExpanded}
      {...props}
    >
      {isExpanded && (
        <div className="util-relative" ref={ref}>
          <ReportTypeDropDown dashboard={dashboard} setDashboard={setDashboard} closeDropDown={closeDropDown} />
        </div>
      )}
    </FilterSection>
  )
}

export const ReportTypeDropDown = ({ dashboard, setDashboard, closeDropDown }: ReportTypeDropDownProps) => {
  const [currentData, setStateData] = useState(dashboard)
  const { loading, options } = useGetDashboardOptions()
  const applyFilter = () => {
    setDashboard(currentData)
    closeDropDown()
  }
  return (
    <GenericDropDownContainer>
      <GenericDropDownFilter value={currentData?.label} label="Report" onClearFilter={() => setDashboard(undefined)}>
        <FiltersOptionList
          options={options}
          onFilterValueChanged={setStateData}
          selectedValue={currentData?.value}
          isLoading={loading}
        />
      </GenericDropDownFilter>
      <GenericClearFilter
        onApply={applyFilter}
        onCancel={closeDropDown}
        onClearFilters={() => setStateData(undefined)}
      />
    </GenericDropDownContainer>
  )
}
