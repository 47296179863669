import React from 'react'
import gql from 'graphql-tag'
import DigitalLeadsShortListWidget from './short-accounts-list-generic-widget.jsx'

const query = gql`
  query leadsAnalytics(
    $filters: [JSON]
    $limit: Int
    $page: Int
    $sorted: [SortField]
  ) {
    leadsAnalytics(
      filters: $filters
      limit: $limit
      page: $page
      sorted: $sorted
    ) {
      account_uid
      name
      lead_in_date
      campaign_name
      last_activity_date
      last_activity_note
      first_activity_date
      owner_user_name
    }
  }
`
const qlOptions = {
  options: () => ({
    variables: {
      filters: [
        { code: 'lead_in_date', expression: { fn: 'date_range_today' } },
        { code: 'lead_source_kind', value: 'digital' }
      ]
    }
  }),
  props: ({ tableDataQl, ownProps }) => ({
    ...ownProps,
    tableDataQl,
    tableData: tableDataQl ? tableDataQl.leadsAnalytics : []
  })
}

const DigitalLeadsWaitingForReactionTable = props => (
  <DigitalLeadsShortListWidget {...props} query={query} qlOptions={qlOptions} />
)

export default DigitalLeadsWaitingForReactionTable
