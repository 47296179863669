/* eslint-disable camelcase, no-unused-vars */
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { func, string, shape, bool } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import debounce from 'debounce'
import { CrmTextArea, CrmButton, CrmGoogleTranslate } from 'crm-components'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { isProspectCompleted } from 'crm-utils/prospect-utils'
import { qlqProcessDetails } from 'crm-data/processes'
import { calculateCurrentStepType } from 'crm-utils/process-utils'
import InfoNoAccountPermission from '../info_no_account_permission.jsx'
class SectionInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      isSaving: props.isSaving
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = e => {
    if (document.getElementById(this.props.id).contains(e.target)) {
    } else {
      if (this.state.value !== this.props.value) {
        this.handleSave()
      } else {
        this.handleCancelEdit()
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value, isEditing: false })
    }
    if (nextProps.isSaving !== this.props.isSaving) {
      this.setState({ isSaving: nextProps.isSaving })
    }
  }

  onTextAreaRef = ref => (this.textAreaRef = ref)

  handleStartEdit = () => {
    console.log('handleStartEdit')

    if (!this.state.isEditing) {
      this.setState({ isEditing: true }, () => {
        if (this.textAreaRef) {
          ReactDOM.findDOMNode(this.textAreaRef).focus() // eslint-disable-line
        }
      })
    }
  }
  handleCancelEdit = () => {
    this.setState({
      ...this.state,
      value: this.props.value,
      isEditing: false
    })
  }

  handleSave = () => {
    // console.log('handleSave')
    this.props.onChange(this.state.value)
  }
  handleChange = event => {
    // console.log('handleChange')
    this.setState({ value: event.currentTarget.value })
    // , () => this.handleSave())
    debounce(() => this.handleSave, 3000)
  }
  render() {
    const { isEditing, value, isSaving } = this.state
    const isChanged = this.props.value !== this.state.value
    const isInvalidValueLength = value.length < 30 && value.length !== 0
    return (
      <div
        id={this.props.id}
        className="CrmInlineEditable util-hooverableContainer util-hooverOp"
        style={{
          position: 'relative',
          paddingRight: this.state.isEditing ? 0 : 15
        }}
      >
        <div
          className=" util-hooverableContent"
          style={{
            position: 'absolute',
            right: 20,
            top: 5,
            color: undefined,
            zIndex: 1
          }}
          title={'Edit'}
          onClick={this.handleStartEdit}
        >
          {!this.state.isEditing && <FontAwesomeIcon icon="pencil-alt" />}
        </div>
        <CrmTextArea
          inputRef={this.onTextAreaRef}
          placeholder={this.props.placeholder}
          placeholderId={this.props.placeholderId}
          rows={5}
          input={{
            value,
            onChange: this.handleChange
          }}
          isSaving={isSaving}
          isChanged={isChanged}
          disabled={!isEditing}
          onClick={this.handleStartEdit}
        />
        {!isEditing && !isSaving && isInvalidValueLength && (
          <div className="row util-marginLeft">
            <div className="LabeledField-value">
              <FormattedMessage
                id="crm.ui.account.enter_at_least_30_characters_to_continue"
                defaultMessage="enter at least 30 characters to continue"
              />
            </div>
          </div>
        )}
        <div className="util-textRight row">
          {(isEditing || isSaving) && (
            <CrmButton
              label={
                <FormattedMessage
                  id="crm.ui.button.cancel"
                  defaultMessage="Cancel"
                />
              }
              onClick={this.handleCancelEdit}
              grouped
              type="secondary"
              small
            />
          )}
          {(isEditing || isSaving) && (
            <CrmButton
              disabled={!isChanged}
              label={
                <FormattedMessage
                  id="crm.ui.button.save"
                  defaultMessage={isSaving ? 'Saving...' : 'Save'}
                />
              }
              type="primary"
              onClick={this.handleSave}
              grouped
              small
              enable={!isSaving}
              icon={isSaving ? 'cloud' : ''}
            />
          )}
        </div>
      </div>
    )
  }
}

class SectionNeedsAnalysis extends PureComponent {
  constructor(props) {
    super(props)
    const { needsAnalysis, valueProposition } = this.getDefaultState(props)
    this.state = {
      needsAnalysis,
      valueProposition,
      isEditing: false,
      isSaving: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.process !== this.props.process) {
      const { needsAnalysis, valueProposition } = this.getDefaultState(
        nextProps
      )
      this.setState({
        needsAnalysis,
        valueProposition
      })
    }
  }

  getDefaultState = props => {
    const needsAnalysis =
      props.process &&
      props.process.attributes &&
      props.process.attributes.needsAnalysis &&
      props.process.attributes.needsAnalysis.value
        ? props.process.attributes.needsAnalysis.value
        : ''
    const valueProposition =
      props.process &&
      props.process.attributes &&
      props.process.attributes.valueProposition &&
      props.process.attributes.valueProposition.value
        ? props.process.attributes.valueProposition.value
        : ''
    return { needsAnalysis, valueProposition }
  }

  handleChangeNeedsAnalysis = value => {
    console.log('handleChangeNeedsAnalysis', value)
    this.setState({ needsAnalysis: value, isSavingNeedsAnalysis: true }, () =>
      this.handleSaveApi()
    )
    // debounce(() => this.handleSave, 3000)
  }

  handleChangeValueProposition = value => {
    this.setState(
      { valueProposition: value, isSavingValueProposition: true },
      () => this.handleSaveApi()
    )
  }

  handleSaveApi = async () => {
    console.log('handleSaveApi API')
    const attributes = []
    if (this.state.needsAnalysis) {
      attributes.push({
        attributeCode: 'needsAnalysis',
        value: this.state.needsAnalysis
      })
    }
    if (this.state.valueProposition) {
      attributes.push({
        attributeCode: 'valueProposition',
        value: this.state.valueProposition
      })
    }

    const command = {
      type: 'process.updateAttributesValue',
      aggregate_uid: this.props.process.process_uid,
      aggregate_type: 'process',
      version: 2,
      payload: {
        attributes,
        account_uid: this.props.account_uid
      }
    }
    try {
      const refetchQueries = [
        {
          query: qlqProcessDetails,
          variables: {
            process_uid: this.props.process.process_uid
          }
        }
      ]
      await this.props.onRunCommand(command, { refetchQueries })
      this.setState({ isEditing: false })
      toastr.success('Saved')
    } catch (err) {
      console.error('Error running command')
      if (err && err.message) {
        toastr.error(err.message)
      }
    }
    this.setState({
      isSavingNeedsAnalysis: false,
      isSavingValueProposition: false
    })
  }

  renderField = (value, onChange) => {}

  render() {
    console.log(
      'Render Needs Analysis and Value Proposition',
      this.props,
      this.state
    )

    if (this.props.hasAccountDataPermission === false) {
      return <InfoNoAccountPermission />
    }

    const { isSaving } = this.props
    const { isEditing, needsAnalysis, valueProposition } = this.state
    const { currentStepType, currentStage } = calculateCurrentStepType(
      this.props.process,
      this.props.processType
    )
    console.log('currentStepType', currentStepType)
    // if (!currentStepType) {
    //   return <div title="No current step in process passed to needs analysis" />
    // }

    return (
      <div>
        <div className="row">
          {this.props.process && (
            <React.Fragment>
              <div className={`util-util-paddingMd col-md-6`}>
                <SectionInput
                  id="div_needs_analysis"
                  placeholder="Needs Analysis"
                  placeholderId="crm.ui.textarea.type_needs_analysis"
                  value={needsAnalysis}
                  onChange={this.handleChangeNeedsAnalysis}
                  isSaving={
                    this.props.isSaving && this.state.isSavingNeedsAnalysis
                  }
                />
              </div>
              <div className={`util-util-paddingMd col-md-6`}>
                <SectionInput
                  id="div_value_proposition"
                  placeholder="Value Proposition"
                  placeholderId="crm.ui.textarea.type_value_proposition"
                  value={valueProposition}
                  onChange={this.handleChangeValueProposition}
                  isSaving={
                    this.props.isSaving && this.state.isSavingValueProposition
                  }
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

SectionNeedsAnalysis.defaultProps = {
  hasAccountDataPermission: false
}

SectionNeedsAnalysis.propTypes = {
  accountUid: string.isRequired,
  onRunCommand: func.isRequired,
  isSaving: bool.isRequired,
  hasAccountDataPermission: bool
}

export default crmGenericCommandHoc(SectionNeedsAnalysis)
