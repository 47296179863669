import gql from 'graphql-tag'

export const taskQueueQL = gql`
  query taskQueue($task_queue_uid: String) {
    taskQueue(task_queue_uid: $task_queue_uid) {
      task_queue_uid
      name
      steps
      is_deleted
    }
  }
`

export const taskQueuesQL = gql`
  query taskQueues {
    taskQueues {
      edges {
        edge {
          name
          task_queue_uid
          is_deleted
        }
      }
      pageInfo {
        count
      }
    }
  }
`
export function taskQueueInitialValues() {
  return {
    name: '',
    step: []
  }
}

export const taskQueueListUrl = '/crm/manager/tasks-manager/queues'

export const StepTypeOptions = [
  { name: 'Task', value: 'task' },
  { name: 'Account', value: 'account' }
]

export const DirectionOptions = [
  { name: 'Descending', value: 'desc' },
  { name: 'Ascending', value: 'asc' }
]
