/* eslint-disable no-unneeded-ternary, camelcase, react/no-unused-prop-types */
import React, { PureComponent } from 'react'
import { func, shape } from 'prop-types'
import LeadsStatsByOwner, {
  LeadsStatsByReasonLost
} from './leads-stats-by-owner.jsx'

class AccountsStatsAnalyticsWidgetGroup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleClickStatus = event => {
    console.log('handleClickStatus', event)
    this.props.onDashboardEvent({
      type: 'accountStatusSelected',
      data: {
        status: event
      }
    })
  }

  render() {
    console.log('AccountsByStatusSummaryWidget')
    return (
      <div className="Widget-content CrmWidget-content CrmWidget-content--padding">
        AccountsStatsAnalyticsWidgetGroup
        <div className="row">
          <div className="col-md-4">
            <LeadsStatsByOwner
              title="By Owner"
              analyticsContext={this.props.analyticsContext}
            />
          </div>
          <div className="col-md-4">
            <LeadsStatsByReasonLost
              analyticsContext={this.props.analyticsContext}
            />
          </div>
        </div>
      </div>
    )
  }
}

AccountsStatsAnalyticsWidgetGroup.propTypes = {
  onDashboardEvent: func,
  analyticsContext: shape().isRequired
}
AccountsStatsAnalyticsWidgetGroup.defaultProps = {
  onDashboardEvent: () => {}
}

export default AccountsStatsAnalyticsWidgetGroup
