import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { func, shape } from 'prop-types'
import { push } from 'connected-react-router'

import { getCurrentUserSession, changeUserStatus } from 'crm-duxs/user-session'
import { CrmButton, CrmDropdown } from 'crm-components'

const options = [
  {
    value: 'break',
    name: 'Break'
  },
  {
    value: 'dialer_auto',
    name: 'Dialer Auto Dialing'
  }
]
class UserStatusWidget extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleClickChangeStatus = () => {
    this.setState({ isStatusSelectVisible: true })
  }

  handleNewStatusSelected = event => {
    console.log('handleNewStatusSelected')
    this.props.doChangeUserStatus(event.currentTarget.id)
  }
  handleStatusSelected = value => {
    console.log('handleStatusSelected', value)
    if (value) {
      this.props.doChangeUserStatus(value)
    }
  }
  handleGotoCrm = () => {
    this.props.push('/crm/dashboard/me')
  }

  render() {
    const { currentSession } = this.props
    console.log('Render User Stats Widget', this.props)
    return (
      <div style={{ backgroundColor: 'white' }}>
        {this.state.isStatusSelectVisible && (
          <div>
            <CrmDropdown
              options={options}
              input={{
                onChange: this.handleStatusSelected,
                value: this.state.selectedStatus
              }}
            />
          </div>
        )}
        {(!currentSession ||
          currentSession.sessionStatusCode !== 'auto_dialer') && (
          <span>
            <CrmButton
              label="Start Auto Dialing"
              id="auto_dialer"
              onClick={this.handleNewStatusSelected}
            />
            <CrmButton label="Go to CRM" onClick={this.handleGotoCrm} />
          </span>
        )}
        {currentSession && (
          <span>
            Current Status <bold>{currentSession.sessionStatusName}</bold>
          </span>
        )}

        {currentSession && currentSession.sessionStatusCode !== 'break' && (
          <CrmButton
            label="Go To Break"
            id="break"
            onClick={this.handleNewStatusSelected}
          />
        )}
        {!this.state.isStatusSelectVisible && (
          <div>
            Current Status:{' '}
            {currentSession ? currentSession.statusCode : 'No Session'}
            {/* <CrmButton
              label="Change Status"
              onClick={this.handleClickChangeStatus}
            /> */}
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentSession: getCurrentUserSession(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doChangeUserStatus: newStatusCode =>
      dispatch(changeUserStatus(newStatusCode)),
    push: url => dispatch(push(url))
  }
}
UserStatusWidget.propTypes = {
  doChangeUserStatus: func.isRequired,
  currentSession: shape({}),
  push: func.isRequired
}
UserStatusWidget.defaultProps = {
  currentSession: undefined
}

export default connect(mapStateToProps, mapDispatchToProps)(UserStatusWidget)
