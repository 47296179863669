import React from 'react'
import { shape, func, arrayOf } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import UserItem from './rms-user-item.jsx'
import { availableSections } from './rms-dashboard-utils'
import rmsDashboardHoc from './rms-dashboard-hoc'
import { CrmButton, CrmCheckbox } from 'crm-components'
class RmsDashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }
  setStateAsync = newState =>
    new Promise(resolve => {
      this.setState(newState, resolve)
    })
  handleRefresh = async () => {
    // console.log('handleRefresh RMs dashboard')
    try {
      await this.setStateAsync({ isLoading: true })
      const ret = await this.props.onRefresh()
      await this.setStateAsync({ isLoading: false })
      return ret
    } catch (err) {
      toastr.error('Problem getting stats')
      // console.error(err)
    }
    await this.setStateAsync({ isLoading: false })
  }

  renderAvailableSections = () => {
    // console.log('renderAvailableSections', availableSections)
    return (
      <div className="util-flexRow">
        {availableSections.map(s => (
          <div key={s.code} className="util-marginMd">
            <CrmCheckbox
              label={s.name}
              name={s.code}
              input={{
                value: this.props.selectedSections[s.code],
                onChange: this.props.onTogleSection
              }}
            />
          </div>
        ))}
      </div>
    )
  }

  renderUser = user => {
    // console.log('renderUser', user)
    return (
      <div key={user.user_uid} className="col-md-4 util-marginBottom">
        <UserItem
          user={user}
          onShowLeadsPopup={this.props.onShowLeadsPopup}
          analyticsContext={this.props.analyticsContext}
        />
      </div>
    )
  }

  render() {
    console.log('render Dashboard')
    return (
      <div>
        <div className="row util-marginBottom">
          <div className="col-md-10">{this.renderAvailableSections()}</div>
          <div className="col-md-2">
            <CrmButton
              label="Refresh"
              onClick={this.handleRefresh}
              type="primary"
              isSaving={this.state.isLoading}
            />
          </div>
        </div>
        {this.props.users && (
          <div className="row">{this.props.users.map(this.renderUser)}</div>
        )}
      </div>
    )
  }
}

RmsDashboard.propTypes = {
  users: arrayOf(shape({})).isRequired,
  onRefresh: func.isRequired,
  onTogleSection: func.isRequired,
  selectedSections: shape({}).isRequired
}

RmsDashboard.defaultProps = {
  dashboards: []
}

export default rmsDashboardHoc(RmsDashboard)
