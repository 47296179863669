import React from 'react'
import { toastr } from 'react-redux-toastr'
import { shape } from 'prop-types'
import { withApollo } from 'react-apollo'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { loadData } from '../rms/rms-dashboard-utils'
import UserItem from '../rms/rms-user-item.jsx'
import widgetDigitalLeadsStatusHoc from '../../hoc/widgets19/widget-digital-leads-status-hoc'
import { CrmButton } from 'crm-components' // eslint-disable-line
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingIcon } from 'util-components'
import {
  AreaChart,
  linearGradient,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { ResponsiveBar } from '@nivo/bar'

class DigitalLeadsBarChart extends React.PureComponent {
  handleOpenHistorical = v => {
    this.props.onOpenManagerDashboard(v)
  }

  render() {
    const { data, type } = this.props

    const keys =
      data && Object.keys(data[0]).filter(k => k !== 'month' && k !== 'total')
    return (
      <div style={{ width: 400, height: 200 }}>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy="month"
          margin={{ top: 30, right: 100, bottom: 30, left: 0 }}
          padding={0.3}
          colors={['#b2d28c', '#f67960', '#65b7f3']}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'Month',
            legendPosition: 'middle',
            legendOffset: 32
          }}
          axisLeft={null}
          enableGridY={false}
          labelSkipWidth={12}
          labelSkipHeight={0}
          labelTextColor="white"
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 12,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          isInteractive={true}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          onClick={this.handleOpenHistorical}
        />
      </div>
    )
  }
}

class DigitalLeadsHistoryChart extends React.PureComponent {
  render() {
    const { data } = this.props
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width="100%"
          height="100%"
          data={data}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#f47735" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#f47735" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" label={() => <span />} />
          {/* <YAxis />  */}
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <Tooltip />
          <Area
            type="monotone"
            dataKey="digitalLeads"
            stroke="#f47735"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
        </AreaChart>
      </ResponsiveContainer>
    )
  }
}

class widgetDigitalLeadsStatus extends React.PureComponent {
  constructor(props) {
    super(props)
    this.prefixUri = '/crm/manager/dashboards'
    this.state = {
      showModal: false,
      transactionType: props.transactionType
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.transactionType !== this.props.transactionType) {
      this.setState({ transactionType: nextProps.transactionType })
    }
  }

  handleOpenManagerDashboard = v => {
    if (this.props.analyticsContext && this.props.analyticsContext.setFilters) {
      const newFilters = {
        department_uid: {
          value: '6800931e-71de-47ae-82a3-82b23e8bd20d',
          label: 'Telesales'
        },
        account__lead_campaign__transaction_type_code: {
          value: 'B2B',
          label: 'B2B'
        }
      }
      this.props.analyticsContext.setFilters(newFilters)
    }
    this.props.doPush('/crm/manager/dashboards?code=rmsHistoricalPerformance')
  }

  renderPageSwitch = direction => {
    return (
      <div
        className="CrmWidget-pageSwitcher"
        onClick={() => this.props.onSwitchPage(direction)}
      >
        <FontAwesomeIcon
          icon={direction < 0 ? faChevronLeft : faChevronRight}
        />
      </div>
    )
  }
  renderLoading = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <LoadingIcon />
    </div>
  )
  renderPage1 = () => {
    return (
      <div
        className="util-flexColumn util-fullHeight util-paddingMd"
        style={{ justifyContent: 'stretch', margin: ' 0 10px' }}
      >
        {this.props.page1Data && (
          <React.Fragment>
            <div className="util-flexRow">
              <div style={{ flex: 1, color: '#325d34' }}>Untouched Leads</div>
              <div style={{ flex: 1, textAlign: 'right', fontWeight: 900 }}>
                {/* <div>Fitments MTD</div> */}
                <div style={{ fontSize: 30 }}>
                  {this.props.page1Data.digitalLeadsStatus}
                </div>
              </div>
            </div>

            {/* <div style={{ width: 200, height: 200 }}> */}
            <div style={{ flex: 1 }}>
              <DigitalLeadsHistoryChart
                data={this.props.page1Data.digitalLeadsHistory}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
  renderPage2 = () => {
    return (
      <div
        className="util-flexColumn util-fullHeight util-paddingMd"
        style={{ justifyContent: 'stretch', margin: ' 0 10px' }}
      >
        {this.props.page2Data && (
          <React.Fragment>
            <div className="util-flexRow">
              <div style={{ flex: 1, color: '#325d34' }} title="Last 7 days">
                {this.props.page2Data.fitmentsTotal}
              </div>
              <div style={{ flex: 1, textAlign: 'right', fontWeight: 900 }}>
                <div style={{ fontSize: 30 }} title="Today">
                  {this.props.page2Data.fitmentsToday}
                </div>
              </div>
            </div>

            {/* <div style={{ width: 200, height: 200 }}> */}
            <div style={{ flex: 1 }}>
              <DigitalLeadsHistoryChart
                data={this.props.page2Data.fitmentsHistory}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
  renderPage3 = () => {
    return (
      <div>
        <div className="util-flexRow">Fitments By Department</div>
        {this.props.page3Data && (
          <React.Fragment>
            {/* <div style={{ width: 200, height: 200 }}> */}
            <div style={{ flex: 1 }}>
              <FitmentsHistoryBarChart
                type="bar"
                onOpenManagerDashboard={this.handleOpenManagerDashboard}
                data={this.props.page3Data.fitmentsHistory}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
  render() {
    console.log('widgetDigitalLeadsStatus', this.props)
    return (
      <div
        className={classNames(
          'CrmWidget CrmWidget--white',
          'CrmWidget--page' + this.props.currentPage
        )}
        style={{ height: this.props.height || 300 }}
      >
        <div className="CrmWidget-header">
          <div className="CrmWidget-title" onClick={this.props.onRefresh}>
            Digital Leads Status
          </div>
          {/* <div style={{ alignItems: 'flex-end', cursor: 'pointer' }}>
            <div
              className="CrmWidget-title"
              onClick={this.props.onToggleTransactionType}
            >
              {this.state.transactionType}
            </div>
          </div> */}
        </div>
        <div className="CrmWidget-content">
          <div className="util-flexRow util-fullHeight">
            {this.renderPageSwitch(-1)}
            <div className="util-flexGrow">
              <div
                className={classNames(
                  'CrmHorizontalPaginator-container',
                  'CrmHorizontalPaginator-currentPage' + this.props.currentPage
                )}
              >
                <div className="CrmHorizontalPaginator-page CrmHorizontalPaginator-page1">
                  {this.props.page1Data && this.props.page1Data.isLoading
                    ? this.renderLoading()
                    : this.renderPage1()}
                </div>
                <div className="CrmHorizontalPaginator-page CrmHorizontalPaginator-page2">
                  {/* {this.props.page2Data && this.props.page2Data.isLoading ? (
                    this.renderLoading()
                  ) : (
                    this.renderPage2()
                  )} */}
                </div>
                <div className="CrmHorizontalPaginator-page CrmHorizontalPaginator-page3">
                  {/* {this.props.page3Data && this.props.page3Data.isLoading ? (
                    this.renderLoading()
                  ) : (
                    this.renderPage3()
                  )} */}
                </div>
              </div>
            </div>

            {this.renderPageSwitch(1)}
          </div>
        </div>
        {/* <CrmButton label="Refr" onClick={this.handleRefresh} /> */}
      </div>
    )
  }
}
widgetDigitalLeadsStatus.propTypes = {
  currentUser: shape({}).isRequired,
  analyticsContext: shape({}).isRequired
}

function mapDispatchToProps(dispatch) {
  return {
    doPush: url => dispatch(push(url))
  }
}

export default connect(
  undefined,
  mapDispatchToProps
)(widgetDigitalLeadsStatusHoc(widgetDigitalLeadsStatus))
