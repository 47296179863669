import React from 'react'
import TextInput from './text-input'

interface ICrmInput {
  input?: {
    value: number | string
    onChange?: Function
    onBlur?: Function
  }
  focus?: boolean
  value?: string
  type?: string
  placeholder?: React.ElementType | string
  iconName?: string
  extraClassNames?: {
    containerClassNames?: string
    inputClassNames?: string
    placeholderClassNames?: string
  }
  onChange?: Function
  hasErrors?: boolean
  id?: string
  withBackground?: boolean
  onKeyPress?: Function
  autocomplete?: string
  onClick?: any
  style?: React.CSSProperties
  onBlur?: Function
  onFocus?: Function
  hidden?: boolean
  min?: number
  max?: number
}

export const CrmTextInput: React.SFC<ICrmInput> = props => {
  const extraClassNames = { ...props.extraClassNames }
  extraClassNames.containerClassNames += ` CrmTextInput ${
    props.hasErrors ? 'CrmTextInput--hasErrors' : ''
  }`
  if (props.withBackground) {
    extraClassNames.containerClassNames += 'CrmTextInput--withBackground'
  }
  extraClassNames.inputClassNames += ' CrmTextInput-input'
  extraClassNames.placeholderClassNames += ' CrmTextInput-placeholder'
  return <TextInput {...props} extraClassNames={extraClassNames} />
}

export const CrmTextInputNumber = props => {
  const handleKeyPressed = event => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    const re = /^[0-9.,]+$/ // /^([0-9\b]+$)|(\.)/
    console.log('keyValue', keyValue, re.test(keyValue))

    if (!re.test(keyValue)) {
      event.preventDefault()
    }

    const inputElement = event.target
    const currentValue = inputElement.value
    const newValue = currentValue + keyValue

    const newValueFloat = parseFloat(newValue.replace(',', '.'))
    const { min, max } = props

    if ((min !== undefined && newValueFloat < min) || (max !== undefined && newValueFloat > max)) {
      event.preventDefault()
    }
  }

  return <CrmTextInput {...props} onKeyPress={handleKeyPressed} />
}
