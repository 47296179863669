import React, { useState } from 'react'
import cloneDeep from 'clone-deep'
import { connect } from 'react-redux'
import { _getLocalDashboards } from 'crm-duxs/management/management-dashboard-reducer'
import AnalyticsCenterMenu from './_analytics-center-menu'
import { DashboardFilters } from '@cartrack-crm/crm-filter-section'
import DashboardContent from './dashboard-content.jsx'
import { CrmFullpageContainer } from 'crm-components'
import { withAnalyticsContextHoc, AnalyticsContextType } from 'crm-core/analytics/context'
import { ReportProvider } from 'crm-utils/reports-utils'

type GenericDashboardsProps = {
  location: any
  dashboards: Array<any>
  analyticsContext: AnalyticsContextType
  mode: string
  code?: string
  history: any
}
const GenericDashboards: React.SFC<GenericDashboardsProps> = props => {
  const [currentDashboard, setCurrentDashboard]: [any, Function] = useState()
  const [isEditingDashboard, setIsEditingDashboard] = useState(false)
  const dashboards = props.dashboards
  let initialDashboard = null

  if (props.location && props.location.search !== '') {
    let params = parseQuery(props.location.search)

    if (params && params.code) {
      initialDashboard = params.code
    }
  }

  function parseQuery(queryString): any {
    let query = {}
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')

    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=')
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }

    return query
  }

  const handleMenuItemClick = item => {
    if (item?.dashboard_uid || item?.component_uid) {
      handleSelectedDashboardChange(item)
    }
  }

  const handleStartAddingDashboard = () => {
    console.error('NOT SUPPORTED')
  }

  function prepareDashboard(dashboard) {
    const dashboardInitiated = cloneDeep(dashboard)
    return dashboardInitiated
  }

  const renderQueryString = code => `${props.location.pathname}?code=${code}`

  const handleSelectedDashboardChange = item => {
    if (props.history) {
      props.history.replace(renderQueryString(item.code))
    }

    const val = item.dashboard_uid
    const dashboard = dashboards ? dashboards.find(d => d.dashboard_uid === val) : undefined

    if (dashboard) {
      setCurrentDashboard(prepareDashboard(dashboard))
      setIsEditingDashboard(false)
    } else if (item && item.content) {
      const dashboard = { ...item }
      setCurrentDashboard(prepareDashboard(dashboard))
      setIsEditingDashboard(false)
    } else {
      setCurrentDashboard(undefined)
    }
  }

  function renderDashboard() {
    return currentDashboard ? (
      <div className="util-fullHeight util-fullWidth">
        <DashboardContent dashboard={currentDashboard} isEditingDashboard={isEditingDashboard} />
      </div>
    ) : (
      <div>No dashboard</div>
    )
  }

  return (
    <CrmFullpageContainer>
      <div className="util-fullWidth util-fullHeight">
        <div className="AnalyticsCenter">
          <div className="AnalyticsCenter-mainLayoutContainer">
            <div className="AnalyticsCenter-leftMenu">
              <AnalyticsCenterMenu
                mode="DASHBOARDS"
                initialDashboard={initialDashboard}
                onMenuItemClick={handleMenuItemClick}
                onStartAddingDashboard={handleStartAddingDashboard}
                selectedDashboardUid={currentDashboard?.dashboard_uid ?? currentDashboard?.code}
              />
            </div>
            <div className="AnalyticsCenter-right">
              <div className="AnalyticsCenter-rightFiltersWrapper">
                <DashboardFilters currentDashboard={currentDashboard} />
              </div>
              <ReportProvider>
                <div className="AnalyticsCenter-mainView">{renderDashboard()}</div>
              </ReportProvider>
            </div>
          </div>
        </div>
      </div>
    </CrmFullpageContainer>
  )
}

export default withAnalyticsContextHoc(
  connect((state: any) => ({ dashboards: _getLocalDashboards(state) }))(GenericDashboards)
)
