import React from 'react'
import styled from 'styled-components'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Box = styled.div`
  background-color: #fff6e5;
  display: flex;
  text-align: center;
`
const Label = styled.div`
  color: #805100;
  font-size: 12px;
  font-weight: 500;
  margin: auto;
  padding: 10px;
`
export const WarningBox = () => (
  <Box>
    <Label>
      <FontAwesomeIcon icon={faInfoCircle} />
      &nbsp;If you want to change to another report or edit filters, please select the &quot;Report Type&quot; and click
      &quot;Apply Filters&quot; button to change the view.
    </Label>
  </Box>
)
