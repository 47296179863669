import React from 'react'
import moment from 'moment'
import { useCrmContext } from 'crm-core/contexts/crm-context'
import gql from 'graphql-tag'
import { groupDataByMonth, numberFormat } from 'crm-utils/data-utils'
import { useQuery } from '@apollo/react-hooks'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import { createDashboardFormats, getWidgetAnalyticContext } from '../../dashboards/tools'
import { HeadCount, DashboardResponse, GQLQueryRes } from 'crm-types'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export const FITMENTS_QL = gql`
  query master($master_uid: String!, $instance_uid: String!, $filter: JSON, $groupBy: [String], $aggregate: [JSON]) {
    master(master_uid: $master_uid) {
      master_uid
      name
      instance(instance_uid: $instance_uid) {
        instance_uid
        name
        orders(filter: $filter, groupBy: $groupBy, aggregate: $aggregate) {
          edges {
            edge {
              order_uid
              completed_month
            }
          }
        }
      }
    }
  }
`

export type WidgetFitmentSummaryHocProps = {
  avgFitCurrentMonthPerPerson?: null | number
  avgFitPerPersonPassedMonth?: null | number
  avgFitThreeMonth: number
  footerName?: string
  footerNamePerPerson?: string
  forecastSize: number
  isLoading?: boolean
  onClickDetail?: Function
  refetchData?: () => any
  threeMonthAgoFitment: any[any]
  threeMonthFitmentAvgPerPersonPassedMonth?: null | any[any]
  totalFitment: number
  mainCaption: string
}

export type useGetOrderResultsByMonth = (
  numberOfMonth?: number,
  headcount?: HeadCount[],
  mode?: 'RM' | 'Agent'
) => DashboardResponse & GQLQueryRes

export const useGetOrderResultsByMonth: useGetOrderResultsByMonth = (numberOfMonth = 3, headcounts, mode) => {
  const crmContext = useCrmContext()
  const analyticsContext = getWidgetAnalyticContext()
  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  let variables: any = {
    master_uid: crmContext.master.master_uid,
    instance_uid: crmContext.instance.instance_uid,
    filter: {
      organization_unit,
      owner_user_uid: headcounts ? undefined : userUid,
      completed_date: {
        $ne: null,
        $gte: startDate,
        $lte: endDate
      }
    },
    aggregate: [
      {
        id: 'order_uid',
        type: 'count'
      }
    ],
    groupBy: ['completed_month']
  }

  const { data, loading, refetch } = useQuery(FITMENTS_QL, {
    variables,
    fetchPolicy: 'no-cache'
  })

  const listFitment = data?.master?.instance?.orders?.edges?.map(e => e.edge) ?? []
  const grouped = groupDataByMonth(listFitment, 'completed_month', 'order_uid')

  return {
    refetch,
    loading,
    ...createDashboardFormats(grouped, numberOfMonth, moment(endDate, DATE_FORMAT).format('YYYY-MM'), headcounts, mode)
  }
}

const widgetFitmentSummaryHoc = Component => {
  const Wrapper = props => {
    const { headcounts, mode } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      avgPassedMonths,
      avgPerPersonPassedMonth,
      dataAvgPerPersonPassedMonth,
      dataPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      totalLatestMonth,
      mainCaption
    } = useGetOrderResultsByMonth(3, headcounts, mode)

    const onClickDetail = (monthSelected = null) => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)

      const filters = {
        owner_user_uid: headcounts ? undefined : userUid,
        organization_unit,
        activity_date: convertMonthToDateRange(endDate)
      }

      if (monthSelected) {
        filters.activity_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'fitment', filters })
    }

    return (
      <Component
        avgFitCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgFitPerPersonPassedMonth={avgPerPersonPassedMonth}
        avgFitThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        onClickDetail={onClickDetail}
        refetchData={() => refetch()}
        threeMonthAgoFitment={dataPassedMonths}
        threeMonthFitmentAvgPerPersonPassedMonth={dataAvgPerPersonPassedMonth}
        totalFitment={numberFormat(totalLatestMonth)}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetFitmentSummaryHoc
