import React from 'react'
import { useGetGraphDataMonthly } from '../forecasts/useFetchForecastManager'
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts'

interface ForecastProgressChartProps {
  month?: string
  userUid?: string
  isManager?: boolean
  widgetClassName?: string
  filter?: {any}
}
export const ForecastProgressChart: React.FunctionComponent<ForecastProgressChartProps> = (props) => {
  let userUid = props.userUid
  let title = 'Forecast Progress Chart'
  let caption = 'Your progress will show in chart'
  if (props.isManager) {
    caption = 'Forecast Progress Chart Manager view'
    userUid = null
  }
  const [data] = useGetGraphDataMonthly(userUid, props.month, props.filter)
  return (
    <div className={`CrmWidget--white CrmWidget CrmWidget--noHeight ${props.widgetClassName}`}>
      <div className='Widget-header row util-noMargin between-xs top-xs'>
        <div className="util-flexColumn util-flexGrow">
          <div className="Widget-title CrmWidget-title util-flexRow">
            <span title="Click to refresh">{title}</span>
          </div>
          <div className="util-flexRow">
            <div className="CrmWidget-subtitle ">
              {caption}
            </div>
          </div>
        </div>
      </div>
      <div className='Widget-content CrmWidget-content'>
        <div className='util-flexRow util-paddingLg util-flexGrow'>
          <ForecastLineChat data={data} />
        </div>
      </div>
    </div>
  )
}

export const ForecastLineChat = (props) => {
  const customXAxis = (data) => {
    const { x, y, stroke, payload } = data;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={0} textAnchor="end" fill="#666" transform="rotate(-90)" fontSize='12px'>{payload.value}</text>
      </g>
    )
  }
  const ref = props.data.filter(el => el.sales !== undefined).pop()
  let forecast = ref && ref.forecast ? ref.forecast : 0
  let sales = ref && ref.sales ? ref.sales : 0
  let name = ref && ref.name ? ref.name : null
  const delta = forecast - sales
  const showDeltaLine = !(delta <= 0) && (forecast !== 0 && sales !== 0) && (sales < forecast)
  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart data={props.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" tick={customXAxis} height={60} padding={{ left: 10 }} />
        <YAxis domain={['auto', 'dataMax + 10']} />
        <Tooltip />
        <Line name="Actual Fitments" type="monotone" dataKey="fitments" stroke="#4472C4" dot={false} strokeWidth={2} />
        <Line name="Actual Sales" type="monotone" dataKey="sales" stroke="#229954" dot={false} strokeWidth={2} />
        <Line name="Forecasted Sales" type="monotone" dataKey="forecast" stroke="#FAC002" dot={false} strokeWidth={2} />
        {showDeltaLine && <ReferenceLine x={name} stroke="#4472C4" label={{ value: 'Delta: ' + delta, fill: '#4472C4' }} />}
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  )
}