import React, { PureComponent } from 'react'
import { bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  CrmValidatedField,
  CrmTextInput,
  CrmUsersSelect,
  CrmDayPickerInput
} from 'crm-components'

const TodoByUserSelector = props => (
  <CrmUsersSelect {...props} permissionCode="all" placeholder="Assign to" />
)

class TaskFormFields extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isExtended: false }
  }

  render() {
    const { isSimpleReminder } = this.props
    const { isExtended } = this.state
    return (
      <div>
        <div className="row">
          {!isSimpleReminder && (
            <div className="col-md-6">
              <CrmValidatedField
                name="assign_to_user_uid"
                component={TodoByUserSelector}
                isRequired
              />
            </div>
          )}
          <div className="col-md-6">
            <CrmValidatedField
              name="due_date"
              component={CrmDayPickerInput}
              componentProps={{
                placeholder: (
                  <FormattedMessage
                    id="crm.ui.picker.due_date"
                    defaultMessage="Due date"
                  />
                )
              }}
              isRequired
            />
          </div>
          <div className="col-md-6">
            <CrmValidatedField
              name="name"
              component={CrmTextInput}
              componentProps={{
                placeholder: 'Task Name',
                placeholderId: 'crm.ui.textinput.task_name'
              }}
              isRequired
            />
          </div>
        </div>
        {isExtended && (
          <div className="row">
            <div className="col-md-12">
              <CrmValidatedField
                name="remarks"
                component={CrmTextInput}
                componentProps={{
                  placeholder: 'Remarks',
                  placeholderId: 'crm.ui.textinput.remarks'
                }}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

TaskFormFields.propTypes = {
  isSimpleReminder: bool
}

TaskFormFields.defaultProps = {
  taskType: undefined,
  onCancel: () => {},
  isWidget: false,
  isSimpleReminder: false,
  isSaving: false
}

export default TaskFormFields
