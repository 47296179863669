/* eslint-disable camelcase */
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom'
import { func, shape } from 'prop-types'
import getNextTaskHoc from '../hoc/get-next-task-hoc.jsx'
import { FormattedMessage } from 'react-intl'
import { CrmButton } from 'crm-components'

class NextTaskButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }
  static propTypes = {
    onGetNextTask: func.isRequired,
    history: shape().isRequired
  }
  handleDoNextTask = async () => {
    this.setState({ isLoading: true })
    if (this.state.isLoading) {
      return
    }
    try {
      const nextTaskRes = await this.props.onGetNextTask({ lock: true })
      console.log('nextTaskRes', nextTaskRes)
      const { task, rule_name } = nextTaskRes

      this.setState({ isLoading: false })
      if (task === undefined || task === null) {
        toastr.info('No more tasks to do now!')
        return
      }
      if (task && task.account_uid) {
        let newUrl = `/crm/account/${task.account_uid}${
          task.module ? '/' + task.module : ''
        }`
        if (task.task_uid) {
          newUrl += `?task_uid=${task.task_uid}`
        }
        if (rule_name) {
          toastr.success(rule_name)
        }
        this.props.history.push(newUrl)
      }
    } catch (error) {
      console.log('handleDoNextTask Error: ', error)
      this.setState({ isLoading: false })
    }
  }
  handleShowNextTaskOptions = () => {
    this.setState({ isNextTaskOptionsVisible: true })
  }

  handleCloseNextTaskOptions = () => {
    this.setState({ isNextTaskOptionsVisible: false })
  }

  render() {
    return (
      <CrmButton
        isSaving={this.state.isLoading}
        enable={!this.state.isLoading}
        type="primary"
        label={
          <FormattedMessage id="crm.ui.next_task" defaultMessage="Next task" />
        }
        onClick={this.handleDoNextTask}
        small
        className="CrmNavbar-nextTaskButton"
        style={{ minWidth: 'initial !important' }}
      />
    )
  }
}

export default getNextTaskHoc(withRouter(NextTaskButton))
