import React from 'react'

interface Props {
  activities: [any]
}

const activityOwnerHOC = (Component) => (user) => {
  const userUid = user.user_uid
  class WrapperComponent extends React.PureComponent<Props> {
    get activities() {
      return this.props.activities.map((activity) => {
        return {
          ...activity,
          isOwner: activity.primary_performer_user_uid === userUid
        }
      })
    }
    render() {
      return <Component {...this.props} activities={this.activities} />
    }
  }

  return WrapperComponent
}

export default activityOwnerHOC
