const rmOpportunities = {
  type: 'item',
  label: 'RM Opportunities',
  code: 'rmOpportunities',
  dashboard_uid: 'rmOpportunities',
  defaultFilters: {
    organization_unit: {
      department_type_uid: {
        value: '40eb1aaa-2b6f-4b30-aa84-2893d96b67af',
        label: 'Corporate RMs'
      }
    }
  },
  content: {
    items: [
      {
        type: 'analitics_widget',
        code: 'rmOpportunities',
        def_version: 5,
        rev: '2020-03-30',
        title: 'RM Opportunities',
        description_: 'Default filter - department Corporate RMs',
        content: {
          data: {
            dataSources: [
              {
                code: 'OpportunitiesListQM',
                name: 'OpportunitiesListQM',
                type: 'QM',
                aggregate: [
                  { id: 'quantity', type: 'sum' },
                  { id: 'opportunity_uid', type: 'count' }
                ],
                contextMapping: {
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  owner_user_uid: 'user_uid',
                  completed_time: 'activity_date'
                },
                qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
                    opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                        edges { edge { opportunity_uid owner_user_uid owner_user_uid quantity } } } }`,
                qlVariables: {
                  aggregate: [
                    { id: 'quantity', type: 'sum' },
                    { id: 'opportunity_uid', type: 'count' }
                  ],
                  groupBy: ['owner_user_uid'],
                  limit: 10
                },
                qlEdges: true,
                qlRoot: 'opportunities'
              },
              {
                code: 'OpportunitiesListQM',
                name: 'OpportunitiesListQM',
                alias: 'WonOpportunities',
                type: 'QM',
                filter: {
                  status_code: 'won'
                },
                aggregate: [
                  { id: 'quantity', type: 'sum' },
                  { id: 'opportunity_uid', type: 'count' }
                ],
                contextMapping: {
                  completed_time: 'activity_date',
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  owner_user_uid: 'user_uid'
                },
                qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
                  opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                      edges { edge { opportunity_uid owner_user_uid owner_user_uid quantity } }  
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [
                    { id: 'quantity', type: 'sum' },
                    { id: 'opportunity_uid', type: 'count' }
                  ],
                  groupBy: ['owner_user_uid'],
                  limit: 10,
                  filter: {}
                },
                qlEdges: true,
                qlRoot: 'opportunities'
              },
              {
                code: 'OpportunitiesListQM',
                name: 'OpportunitiesListQM',
                alias: 'QuotedOpportunities',
                type: 'QM',
                filter: {
                  is_quoted: 'true',
                  status_code: 'active'
                },
                aggregate: [
                  { id: 'quantity', type: 'sum' },
                  { id: 'opportunity_uid', type: 'count' }
                ],
                contextMapping: {
                  created_time: 'activity_date',
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  owner_user_uid: 'user_uid'
                },
                qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
                  opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                      edges { edge { opportunity_uid owner_user_uid owner_user_uid quantity } }  
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [
                    { id: 'quantity', type: 'sum' },
                    { id: 'opportunity_uid', type: 'count' }
                  ],
                  groupBy: ['owner_user_uid'],
                  limit: 10,
                  filter: {}
                },
                qlEdges: true,
                qlRoot: 'opportunities'
              },
              {
                code: 'OpportunitiesListQM',
                name: 'OpportunitiesListQM',
                alias: 'ActiveOpportunities',
                type: 'QM',
                filter: {
                  status_code: 'active'
                },
                aggregate: [
                  { id: 'quantity', type: 'sum' },
                  { id: 'opportunity_uid', type: 'count' }
                ],
                contextMapping: {
                  created_time: 'activity_date',
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  owner_user_uid: 'user_uid'
                },
                qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
                  opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                      edges { edge { opportunity_uid owner_user_uid owner_user_uid quantity } }  
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [
                    { id: 'quantity', type: 'sum' },
                    { id: 'opportunity_uid', type: 'count' }
                  ],
                  groupBy: ['owner_user_uid'],
                  limit: 10,
                  filter: {}
                },
                qlEdges: true,
                qlRoot: 'opportunities'
              },
              {
                code: 'OpportunitiesListQM',
                name: 'OpportunitiesListQM',
                alias: 'OverdueOpportunities',
                type: 'QM',
                filter: {
                  is_overdue: 'true',
                  status_code: 'active'
                },
                aggregate: [
                  { id: 'quantity', type: 'sum' },
                  { id: 'opportunity_uid', type: 'count' }
                ],
                contextMapping: {
                  created_time: 'activity_date',
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  owner_user_uid: 'user_uid'
                },
                qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
                  opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                      edges { edge { opportunity_uid owner_user_uid owner_user_uid quantity } }  
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [
                    { id: 'quantity', type: 'sum' },
                    { id: 'opportunity_uid', type: 'count' }
                  ],
                  groupBy: ['owner_user_uid'],
                  limit: 10,
                  filter: {}
                },
                qlEdges: true,
                qlRoot: 'opportunities'
              },

              {
                code: 'OpportunitiesListQM',
                name: 'OpportunitiesListQM',
                alias: 'LostOpportunities',
                type: 'QM',
                filter: {
                  status_code: 'lost'
                },
                aggregate: [
                  { id: 'quantity', type: 'sum' },
                  { id: 'opportunity_uid', type: 'count' }
                ],
                contextMapping: {
                  completed_time: 'activity_date',
                  owner: {
                    department_uid: 'department_uid',
                    organization_unit_uid: 'organization_unit_uid'
                  },
                  owner_user_uid: 'user_uid'
                },
                qlQuery: `query opportunities($limit: Int, $filter: JSON, $aggregate: [JSON], $groupBy: [String]) { 
                  opportunities(limit: $limit, filter: $filter, aggregate: $aggregate, groupBy: $groupBy) { 
                      edges { edge { opportunity_uid owner_user_uid owner_user_uid quantity } }  
                      pageInfo { count }
                    }
                  }`,
                qlVariables: {
                  aggregate: [
                    { id: 'quantity', type: 'sum' },
                    { id: 'opportunity_uid', type: 'count' }
                  ],
                  groupBy: ['owner_user_uid'],
                  limit: 10,
                  filter: {}
                },
                qlEdges: true,
                qlRoot: 'opportunities'
              },
              {
                code: 'CrmUser',
                name: 'CrmUser',
                type: 'QM',
                filter: {},
                qlQuery: `query master($master_uid: String!, $instance_uid: String!, $filter: JSON) {
                  master(master_uid: $master_uid)  { 
                      master_uid
                      name
                      instance(instance_uid: $instance_uid) { 
                          instance_uid
                          name
                          users (filter: $filter) { 
                            user_uid
                            is_active
                            full_name
                            contract_start_date
                            contract_end_date
                            department { 
                              name 
                            }
                          }
                      }
                  }
                }`,
                qlRoot: 'users'
              }
            ],
            combineDataSources: {
              name: 'CombinedDataSource',
              targetKeyField: 'user_uid',
              mapping: [
                {
                  fromDataSourceName: 'OpportunitiesListQM',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'quantity',
                      to: 'total_opportunities_size'
                    },
                    {
                      from: 'opportunity_uid',
                      to: 'total_opportunities_count'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'WonOpportunities',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'quantity',
                      to: 'won_opportunities_size'
                    },
                    {
                      from: 'opportunity_uid',
                      to: 'won_opportunities_count'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'LostOpportunities',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'quantity',
                      to: 'lost_opportunities_size'
                    },
                    {
                      from: 'opportunity_uid',
                      to: 'lost_opportunities_count'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'QuotedOpportunities',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'opportunity_uid',
                      to: 'quoted_opportunities_count'
                    },
                    {
                      from: 'quantity',
                      to: 'quoted_opportunities_size'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'ActiveOpportunities',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'opportunity_uid',
                      to: 'active_opportunities_count'
                    },
                    {
                      from: 'quantity',
                      to: 'active_opportunities_size'
                    }
                  ]
                },
                {
                  fromDataSourceName: 'OverdueOpportunities',
                  keyField: 'owner_user_uid',
                  mapping: [
                    {
                      from: 'opportunity_uid',
                      to: 'overdue_opportunities_count'
                    },
                    {
                      from: 'quantity',
                      to: 'overdue_opportunities_size'
                    }
                  ]
                },

                {
                  fromDataSourceName: 'CrmUser',
                  keyField: 'user_uid',
                  type: 'left',
                  mapping: [
                    {
                      from: 'full_name'
                    },
                    {
                      from: 'contract_start_date'
                    },
                    { from: 'contract_end_date' },
                    { from: 'department__name' },
                    { from: 'is_active' }
                  ]
                }
              ]
            }
          },
          layout: {
            items: [
              {
                type: 'table',
                def_version: 7,
                props: {
                  generate_summary: true,
                  generate_average: true,
                  initialSort: {
                    id: 'contract_start_date',
                    order: 'asc'
                  },
                  columnGroups: [
                    {
                      Header: '',
                      colSpan: 3
                    },
                    {
                      Header: 'Total Opportunities',
                      colSpan: 2,
                      styleHighlighted: true
                    },
                    {
                      Header: 'Quoted',
                      colSpan: 2
                    },
                    {
                      Header: 'Active',
                      colSpan: 2
                    },
                    {
                      Header: 'Overdue',
                      colSpan: 2,
                      styleHighlighted: true
                    },
                    {
                      Header: 'Won',
                      colSpan: 2
                    },
                    {
                      Header: 'Lost',
                      colSpan: 2
                    }
                  ],
                  columns: [
                    {
                      Header: '#',
                      accessor: 'index',
                      width: 30
                    },
                    {
                      Header: 'RM',
                      accessor: 'full_name',
                      classNameHandler: row => {
                        return row.original.is_active ? '' : 'util-opacity50'
                      },
                      detailsPopup: 'user_dashboard',
                      detailsFilterMapping: {
                        user_uid: 'user_uid'
                      }
                    },
                    {
                      Header: 'Start Date',
                      accessor: 'contract_start_date',
                      width: 80,
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'Size',
                      accessor: 'total_opportunities_size',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 2,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'none'
                    },
                    {
                      Header: 'Count',
                      accessor: 'total_opportunities_count',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 2,
                      detailsPopup: 'opportunity',
                      detailsFilter: {},
                      detailsFilterMapping: {
                        user: {
                          user_uid: {
                            value: 'user_uid',
                            label: 'full_name'
                          }
                        }
                      },
                      contextMapping: {
                        completed_time: 'activity_date'
                      },
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center'
                    },
                    {
                      Header: 'Size',
                      accessor: 'quoted_opportunities_size',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 1,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'none'
                    },
                    {
                      Header: 'Count',
                      accessor: 'quoted_opportunities_count',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 1,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        opportunity: {
                          status: { value: 'quoted', label: 'Quoted' },
                          is_quoted: 'true'
                        }
                      },
                      detailsFilterMapping: {
                        user: {
                          user_uid: {
                            value: 'user_uid',
                            label: 'full_name'
                          }
                        }
                      },
                      contextMapping: {
                        created_time: 'activity_date'
                      }
                    },
                    {
                      Header: 'Size',
                      accessor: 'active_opportunities_size',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 1,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'none'
                    },
                    {
                      Header: 'Count',
                      accessor: 'active_opportunities_count',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 1,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        opportunity: {
                          status: { value: 'pending', label: 'Pending' }
                        }
                      },
                      detailsFilterMapping: {
                        user: {
                          user_uid: {
                            value: 'user_uid',
                            label: 'full_name'
                          }
                        }
                      },
                      contextMapping: {
                        created_time: 'activity_date'
                      }
                    },
                    {
                      Header: 'Size',
                      accessor: 'overdue_opportunities_size',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 1,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'none'
                    },
                    {
                      Header: 'Count',
                      accessor: 'overdue_opportunities_count',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 1,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        opportunity: {
                          status: { value: 'overdue', label: 'Overdue' }
                        }
                      },
                      detailsFilterMapping: {
                        user: {
                          user_uid: {
                            value: 'user_uid',
                            label: 'full_name'
                          }
                        }
                      },
                      contextMapping: {
                        created_time: 'activity_date'
                      }
                    },
                    {
                      Header: 'Size',
                      accessor: 'won_opportunities_size',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 0,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'none'
                    },
                    {
                      Header: 'Count',
                      accessor: 'won_opportunities_count',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 0,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        opportunity: {
                          status: { value: 'won', label: 'Won' }
                        }
                      },
                      detailsFilterMapping: {
                        user: {
                          user_uid: {
                            value: 'user_uid',
                            label: 'full_name'
                          }
                        }
                      },
                      contextMapping: {
                        completed_time: 'activity_date'
                      }
                    },
                    {
                      Header: 'Size',
                      accessor: 'lost_opportunities_size',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 0,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'none'
                    },
                    {
                      Header: 'Count',
                      accessor: 'lost_opportunities_count',
                      width: 50,
                      type: 'number',
                      maximumFractionDigits: 0,
                      className: 'util-pointer',
                      align: 'right',
                      headerAlign: 'center',
                      detailsPopup: 'opportunity',
                      detailsFilter: {
                        opportunity: {
                          status: { value: 'lost', label: 'Lost' }
                        }
                      },
                      detailsFilterMapping: {
                        user: {
                          user_uid: {
                            value: 'user_uid',
                            label: 'full_name'
                          }
                        }
                      },
                      contextMapping: {
                        completed_time: 'activity_date'
                      }
                    }
                  ],
                  data: {
                    type: 'dataSource',
                    dataSourceCode: 'CombinedDataSource'
                  }
                }
              }
            ]
          }
        }
      }
    ]
  }
}

export default rmOpportunities
