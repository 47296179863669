import React from 'react'
import { bool, string, func, shape } from 'prop-types'

const ProcessProgressStep = ({
  isCurrentStep,
  isCompletedStep,
  title,
  onClick,
  step,
  renderStepDetails
}) => (
  <div
    className={`ProcessWidget-step
  ${isCurrentStep ? 'ProcessWidget-step--active' : ''}
  ${isCompletedStep ? 'ProcessWidget-step--completed' : ''}
      `}
    title={title}
    onClick={onClick}
  >
    <div className="ProcessWidget-step-content">
      <div className="ProcessWidget-step-content-title">{step.name}</div>
      <div className="ProcessWidget-step-content-details">
        {renderStepDetails && renderStepDetails(step)}
        {/* {currentStep.process_step_type_uid === step.process_step_type_uid &&
        this._renderCurrentStepDetails()} */}
      </div>
    </div>
  </div>
)

ProcessProgressStep.propTypes = {
  isCurrentStep: bool.isRequired,
  isCompletedStep: bool,
  title: string.isRequired,
  onClick: func.isRequired,
  step: shape({}).isRequired,
  renderStepDetails: func
}

ProcessProgressStep.defaultProps = {
  renderStepDetails: undefined,
  isCompletedStep: false
}

export default ProcessProgressStep
