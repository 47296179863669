import React, { FC, CSSProperties } from 'react'
import {
  DashboardFiltersSection,
  DashboardFiltersSectionValue,
  DashboardFiltersSectionValues,
  DashboardFiltersSectionLabel
} from '../styled/dashboard-filters-section'
import { DashboardFiltersDropDownIconWrapper } from '../styled/dropdown-group-style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

interface IFilterSection {
  name: string
  selectedValue?: any
  isExpanded?: boolean
  onToggle?: Function
  style?: CSSProperties
  isDisabled?: boolean
}

export const FilterSection: FC<IFilterSection> = (props) => {
  return (
    <DashboardFiltersSection isExpanded={props.isExpanded} isDisabled={props.isDisabled}  style={props.style}>
      <DashboardFiltersSectionValues onClick={!props.isDisabled && props.onToggle}>
        <div className='util-flexRow'>
          <DashboardFiltersDropDownIconWrapper>
            <FontAwesomeIcon icon={faCaretDown} />
          </DashboardFiltersDropDownIconWrapper>
          <div>
            <DashboardFiltersSectionLabel>{props.name}</DashboardFiltersSectionLabel>
            <DashboardFiltersSectionValue>
              {props.selectedValue ?? 'All'}
            </DashboardFiltersSectionValue>
          </div>
        </div>
      </DashboardFiltersSectionValues>
      {props.children}
    </DashboardFiltersSection>
  )
}