/* eslint-disable camelcase */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { shape, bool, func, string } from 'prop-types'
import { FieldArray, Field, propTypes, change } from 'redux-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CrmUsersSelect, CrmButton, CrmDropdown } from 'crm-components'
import {
  AccountPersonSelect,
  ContactPersonInlineForm
} from 'crm-modules/accounts/components/'
import { getContactDetailByType } from 'crm-utils/accounts-utils'

const rolesOptions = [
  { name: 'Lead RM', value: 'performer', is_primary: true },
  { name: 'Participant', value: 'participant', is_primary: false },
  { name: 'Arranged By', value: 'arranged_by', is_primary: false }
]

const RoleCodeDropdown = props => (
  <CrmDropdown
    placeholder={
      <FormattedMessage id="crm.ui.activity.form.role" defaultMessage="Role" />
    }
    {...props}
    options={rolesOptions}
  />
)

const ParticipantItem = props => {
  console.log('ParticipantItem')
  console.dir(props)

  const { participant, isEditing, member, accountUid } = props
  let name = "Can't find name"
  let company = 'Cartrack'
  let email
  let phone
  let position
  let department // eslint-disable-line
  if (participant.user) {
    name = participant.user.full_name
  }
  if (participant.person) {
    name = participant.person.name
    company = 'Client'
    position = participant.person.description
    department = participant.person.department
    phone = getContactDetailByType(participant.person, 'mobile')
    email = getContactDetailByType(participant.person, 'email')
  }
  console.dir(phone, email)
  // Check what kind is that 'person' (for Company Person) or 'user'
  let kind = participant.kind
  if (!kind) {
    if (participant.user_uid) {
      kind = 'user'
    } else if (participant.participant_account_person_uid) {
      kind = 'person'
    } else {
      kind = 'user'
    }
  }
  const handleRemove = () => {
    console.log('handleRemove')
    props.fields.remove(props.index)
  }

  if (participant && participant.role_code === 'arranged_by') {
    return <div />
  }
  return (
    <div className="row util-hooverable util-marginBottom">
      {!isEditing && (
        <div className="col-xs-4">
          <div>
            <strong>{name}</strong>
          </div>
          <div>
            <small>{position}</small>
          </div>
          <div>
            <small>
              <FormattedMessage
                id="crm.ui.activity.form.m"
                defaultMessage="M:"
              />
              {phone ? phone.value : ''} ,{' '}
              <FormattedMessage
                id="crm.ui.activity.form.e"
                defaultMessage="E:"
              />
              {email ? email.value : ''}
            </small>
          </div>
        </div>
      )}
      {!isEditing && <div className="col-xs-4">{company}</div>}
      {isEditing && (
        <div className="col-xs-8">
          {kind === 'user' ? (
            <Field
              placeholder={
                <FormattedMessage
                  id="crm.ui.activity.form.cartrack_employee"
                  defaultMessage="Cartrack Employee"
                />
              }
              name={`${member}.user_uid`}
              component={CrmUsersSelect}
            />
          ) : (
            <Field
              placeholder={
                <FormattedMessage
                  id="crm.ui.activity.form.account_person"
                  defaultMessage="Account Person"
                />
              }
              name={`${member}.participant_account_person_uid`}
              componentProps={{ accountUid }}
              accountUid={accountUid}
              component={AccountPersonSelect}
            />
          )}
        </div>
      )}
      <div className="col-md-3">
        {isEditing ? (
          <Field name={`${member}.role_code`} component={RoleCodeDropdown} />
        ) : rolesOptions.find(o => o.value === participant.role_code) ? (
          rolesOptions.find(o => o.value === participant.role_code).name
        ) : (
          participant.role_code
        )}
      </div>
      {isEditing && (
        <div className="col-md-1 util-flexCenterContent">
          <div>
            <FontAwesomeIcon
              className="util-pointer util-textError"
              icon="times"
              onClick={handleRemove}
            />
          </div>
        </div>
      )}
    </div>
  )
}
ParticipantItem.propTypes = {
  participant: shape().isRequired,
  isEditing: bool.isRequired,
  member: string.isRequired,
  accountUid: string.isRequired,
  fields: shape().isRequired,
  index: string.isRequired
}

class RenderItems extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isAddingNew: false }
  }

  handleAddNewCompanyPerson = () => {
    this.setState({ ...this.state, isAddingNew: true })
  }

  handleNewPersonSaved = person => {
    console.log('handleNewPersonSaved', person)
    console.dir(this.props)
    this.props.fields.push({
      kind: 'person',
      participant_account_person_uid: person.account_person_uid
    })
    this.setState({ ...this.state, isAddingNew: false })
  }
  handleNewPersonCancel = () => {
    console.log('handleNewPersonCancel')
    this.setState({ ...this.state, isAddingNew: false })
  }
  handleAddCompanyPerson = () => {
    this.props.fields.push({ kind: 'person', role_code: 'participant' })
  }
  handleAddUser = () => {
    this.props.fields.push({
      kind: 'user',
      role_code: 'performer',
      is_primary: true
    })
  }

  render() {
    console.log('ParticipanyFields render item', this.props)
    const { fields } = this.props
    const { isAddingNew } = this.state

    return (
      <div>
        <div className="row util-marginBottom ">
          <div className="col-md-4">
            <FormattedMessage
              id="crm.ui.activity.form.participants"
              defaultMessage="Participants"
            />
          </div>
          {this.props.isEditing && (
            <div className="col-md-8">
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.activity.form.add_ct_employee"
                    defaultMessage="+ CT Employee"
                  />
                }
                xsmall
                grouped
                onClick={this.handleAddUser}
              />

              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.activity.form.add_select_contact_person"
                    defaultMessage="+ Select Contact Person"
                  />
                }
                xsmall
                grouped
                onClick={this.handleAddCompanyPerson}
              />
              <CrmButton
                label={
                  <FormattedMessage
                    id="crm.ui.activity.form.add_new_contact_person"
                    defaultMessage="+ Add New Contact Person"
                  />
                }
                xsmall
                grouped
                onClick={this.handleAddNewCompanyPerson}
              />
            </div>
          )}
        </div>
        {fields
          // .filter(f => f.role_code !== 'arranged_by')
          .map((member, index) => (
            <div key={member}>
              <ParticipantItem
                member={member}
                isEditing={this.props.isEditing}
                index={index}
                participant={fields.get(index)}
                fields={fields}
                accountUid={this.props.accountUid}
                doChange={this.props.doChange}
                form={this.props.form}
              />
            </div>
          ))}
        {isAddingNew && (
          <ContactPersonInlineForm
            accountUid={this.props.accountUid}
            onSaved={this.handleNewPersonSaved}
            onCancel={this.handleNewPersonCancel}
            initNew
          />
        )}
      </div>
    )
  }
}

RenderItems.propTypes = {
  fields: shape().isRequired,
  isEditing: bool.isRequired,
  accountUid: shape().isRequired,
  doChange: func.isRequired,
  form: string.isRequired
}
class MeetingFormParticipantsFields extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleAddToMeeting = event => {
    console.log('handleAddToMeeting')
    event.preventDefault()
    console.dir(this.props)
    console.dir(this.state)
    if (this.state.selectedPersonToAdd) {
      // Add participant
      const newData = { ...this.props.sectionData }
      newData.value.push(this.state.selectedPersonToAdd)
      this.props.handleSave({ section: 'participants', newData })
    }
  }

  handleSelectedPerson = (value, data) => {
    console.log('handleSelectedPerson', value, data)
    console.dir(value)
    this.setState({ ...this.state, selectedPersonToAdd: data })
  }

  handleAddCompanyPerson = () => {}

  handleAddEmployee = () => {}

  render() {
    const { activity, isEditing } = this.props // eslint-disable-line
    console.log('Render MeetingFormParticipantFields')
    console.dir(this.props)
    return (
      <div>
        <fieldset
          disabled={!this.props.isEditing}
          className="CrmForm-form-fieldset"
        >
          {/* {activity && (
            <div className="row">
              <div className="col-md-4">
                <AccountPersonSelect
                  accountUid={activity.account_uid}
                  onChange={this.handleSelectedPerson}
                />
              </div>
              <div className="col-md-2">
                <CrmButton
                  type="button"
                  label="Add To Meeting"
                  onClick={this.handleAddToMeeting}
                />
              </div>
            </div>
          )} */}

          <FieldArray
            name="participants"
            doChange={this.props.doChange}
            component={RenderItems}
            isEditing={this.props.isEditing}
            accountUid={this.props.accountUid}
          />
          {/* {activity &&
            activity.participants &&
            activity.participants.map(p => (
              <ParticipantItem
                key={p.activity_participant_ui}
                participant={p}
              />
            ))} */}
        </fieldset>
      </div>
    )
  }
}

MeetingFormParticipantsFields.propTypes = {
  ...propTypes,
  activity: shape({}).isRequired,
  addressSaving: bool.isRequired,
  isEditing: bool.isRequired,
  onGotoMinutes: func.isRequired,
  accountUid: string.isRequired
}
function mapStateToProps() {
  return {}
}
MeetingFormParticipantsFields.defaultProps = {}
function mapDispatchToProps(dispatch) {
  return {
    doChange: (form, field, value) => dispatch(change(form, field, value))
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingFormParticipantsFields)
