import reportAPI from '../api/reports-api'
import {
  FETCH_REPORTS,
  RECEIVE_REPORTS,
  GENERATE_REPORT,
  RECEIVE_GENERATE_REPORT
} from 'crm-duxs/report-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchReport() {
  const reports = yield call(reportAPI.fetchReports)
  yield put({
    type: RECEIVE_REPORTS,
    payload: { reports }
  })
}

function* generateReport(action) {
  console.log('report data params : ', action)
  const reportData = yield call(reportAPI.generateReport, action.params)
  console.log('report data result : ', reportData)
  yield put({
    type: RECEIVE_GENERATE_REPORT,
    payload: { reportData }
  })
}

function* reportSaga() {
  yield takeLatest(FETCH_REPORTS, fetchReport)
  yield takeLatest(GENERATE_REPORT, generateReport)
}

export default reportSaga
