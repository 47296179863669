export const OPEN_ERROR_REPORT = 'OPEN_ERROR_REPORT'
export const CLOSE_ERROR_REPORT = 'CLOSE_ERROR_REPORT'
export const PUSH_ERROR_REPORT = 'PUSH_ERROR_REPORT'
export const CLEAR_ERROR_REPORT = 'CLEAR_ERROR_REPORT'

// Reducer
const initialState = {
  errors: [],
  enable: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_ERROR_REPORT:
      return {
        ...state,
        enable: true
      }
    case CLOSE_ERROR_REPORT:
      return {
        ...state,
        enable: false
      }
    case PUSH_ERROR_REPORT: {
      return {
        ...state,
        errors: [...state.errors.slice(), action.payload.error]
      }
    }
    case CLEAR_ERROR_REPORT:
      return {
        ...state,
        errors: []
      }
    default:
      return state
  }
}

// Action Creators
export function openApiErrorModal() {
  return {
    type: OPEN_ERROR_REPORT
  }
}

export function closeApiErrorModal() {
  return {
    type: CLOSE_ERROR_REPORT
  }
}

export function pushError(error) {
  return {
    type: PUSH_ERROR_REPORT,
    payload: { error }
  }
}

export function clearError() {
  return {
    type: CLEAR_ERROR_REPORT
  }
}

// Selectors
export const getMessage = state => state.crm.errorReporting.errors
export const getEnable = state => state.crm.errorReporting.enable
