import React from 'react'
import { groupDataByMonth } from 'crm-utils/data-utils'
import withLeadPopUpHoc from '../../components/_with-leads-popup-hoc'
import moment from 'moment'
import { createDashboardFormats, useQueryActivity, getWidgetAnalyticContext } from '../../dashboards/tools'
import { GQLQueryRes, HeadCount, DashboardResponse } from 'crm-types'
import {
  convertMonthToDateRange,
  resolveDashboardWidgetFiltersFromContextFilters
} from 'crm-modules/dashboard/dashboard-utils'

export interface WidgetFirstCallHocProps {
  avgFirstCallCurrentMonthPerPerson: number | null
  avgFirstCallPassedMonthData: number | any[any]
  avgFirstCallPersonPassedMonth: number | null
  avgFirstCallThreeMonth: number
  footerName: string | null
  footerNamePerPerson: string | null
  isLoading?: boolean
  refetchData?: () => any
  threeMonthAgoFirstCall: any[any]
  totalFirstCall: number
  onClickDetail?: Function
  mainCaption?: string
}

export type useGetFirstCallByMonth = (
  numberOfMonth?: number,
  headcounts?: HeadCount[]
) => DashboardResponse & GQLQueryRes

export const useGetFirstCallByMonth: useGetFirstCallByMonth = (numberOfMonth = 3, headcounts) => {
  const analyticsContext = getWidgetAnalyticContext()
  const { DATE_FORMAT, endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(
    analyticsContext
  )
  const startDate = moment(endDate, DATE_FORMAT)
    .subtract(numberOfMonth, 'month')
    .startOf('month')
    .format(DATE_FORMAT)

  const filter = {
    organization_unit,
    user_uid: headcounts ? undefined : userUid,
    account_is_deleted: false,
    activity: {
      activity_type: {
        medium_code: 'phone_call'
      },
      seq_same_type_success_user: 1,
      start_time: {
        $gte: startDate,
        $lte: endDate
      }
    }
  }

  const { listActivity, loading, refetch } = useQueryActivity(
    filter,
    ['start_date_month'],
    [{ id: 'activity_uid', type: 'count' }]
  )
  const grouped = groupDataByMonth(listActivity, 'start_date_month', 'activity_uid')

  return {
    refetch,
    loading,
    ...createDashboardFormats(grouped, numberOfMonth, moment(endDate).format('YYYY-MM'), headcounts, 'Agent')
  }
}

const widgetFirstCallHoc = (Component: any) => {
  const Wrapper = props => {
    const { headcounts } = props
    const analyticsContext = getWidgetAnalyticContext()
    const {
      avgCurrentMonthPerPerson,
      dataAvgPerPersonPassedMonth,
      avgPerPersonPassedMonth,
      avgPassedMonths,
      footerName,
      footerNamePerPerson,
      loading,
      refetch,
      dataPassedMonths,
      totalLatestMonth,
      mainCaption
    } = useGetFirstCallByMonth(3, headcounts)

    const onClickDetail = (monthSelected = null) => {
      const { endDate, userUid, organization_unit } = resolveDashboardWidgetFiltersFromContextFilters(analyticsContext)
      const filters = {
        organization_unit,
        performer_user_uid: headcounts ? undefined : userUid,
        activity_date: convertMonthToDateRange(endDate),
        type: { medium_code: 'phone_call' },
        first_type: 'first'
      }

      if (monthSelected) {
        filters.activity_date = monthSelected
      }

      props.onShowLeadsPopup({ type: 'disposition', filters })
    }

    return (
      <Component
        avgFirstCallCurrentMonthPerPerson={avgCurrentMonthPerPerson}
        avgFirstCallPassedMonthData={dataAvgPerPersonPassedMonth}
        avgFirstCallPersonPassedMonth={avgPerPersonPassedMonth}
        avgFirstCallThreeMonth={avgPassedMonths}
        footerName={footerName}
        footerNamePerPerson={footerNamePerPerson}
        isLoading={loading}
        onClickDetail={onClickDetail}
        refetchData={() => refetch()}
        threeMonthAgoFirstCall={dataPassedMonths}
        totalFirstCall={totalLatestMonth}
        mainCaption={mainCaption}
        {...props}
      />
    )
  }

  return withLeadPopUpHoc(Wrapper)
}

export default widgetFirstCallHoc
