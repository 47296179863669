/* eslint-disable */
import gql from 'graphql-tag'

import {
  qlProcessTypeFields,
  qlProcessStepFields,
  qlProcessStepTypeDetails,
  qlProcessOwnFields,
  qlProcessFields
} from './processes-fragments'

export const qlProcessWithCurrentStep = ` ${qlProcessOwnFields} 
  current_step { ${qlProcessStepFields} process_step_type { ${qlProcessStepTypeDetails}} } `

export const qlqProcessType = gql`
query process_type($process_type_uid: String, $process_uid: String) {
  process_type(process_type_uid: $process_type_uid, process_uid: $process_uid) {
    ${qlProcessTypeFields} steps { ${qlProcessStepTypeDetails} } 
  }
}
`

export const qlqProcessTypes = gql`
query process_types {
  process_types {
    ${qlProcessTypeFields} steps { ${qlProcessStepTypeDetails} } 
  }
}
`

export const qlqProcessDetails = gql`
query process($process_uid: String!) {
  process(process_uid: $process_uid) { 
    ${qlProcessOwnFields} 
    process_type { 
      ${qlProcessTypeFields} steps { process_step_type_uid name kind is_final_step step permission_code is_active} 
    }  
    current_step { 
      process_step_uid process_step_type_uid created_time due_date 
      process_step_type { process_step_type_uid name kind is_final_step step is_active  }
    } 
    owner_user {
      user_uid
      full_name
      photo_url
    }
    owner_pool {
      name
    }
  }
}
`
