import React from 'react'
import { shape, func, bool } from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  editAdditionalOwnersHoc,
  ownerRoles
} from 'crm-modules/accounts/hoc/account-reassign-hoc'
import ReassignModal from './_reassign-modal.jsx'
import { withAccountPermissionsContextHOC } from 'crm-core/permissions/account-permissions-context'
import { PERMISSIONS_TYPES } from '@cartrack-crm/crm-core'

class AccountAdditionalOwners extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedRoleCode: null,
      selectedAdditionalOwner: null,
      isNewAdditionalOwner: false
    }
  }

  handleCloseReassignModal = () => {
    this.setState({ isReassignModal: false })
  }

  handleSaveAdditionalOwner = () => {}

  _renderAdditionalOwners = () => {
    const { account } = this.props
    const additionalOwners =
      account && account.additional_owners ? account.additional_owners : {}
    const items = []
    Object.keys(additionalOwners).forEach(key => {
      items.push({
        roleCode: key,
        ...additionalOwners[key]
      })
    })
    const hasItems = items.length > 0

    return (
      <div className="util-marginTopSm">
        <div className="util-flexRow util-fullHeight">
          <div className="util-flexGrow util-marginRight">
            {!hasItems && (
              <div className="LabeledField-label">
                <FormattedMessage
                  id="crm.ui.account.additional_owners"
                  defaultMessage="Additional Owners"
                />
              </div>
            )}
            {hasItems &&
              items.map(v => {
                const findRole = ownerRoles.find(v2 => v2.value === v.roleCode)
                const roleName = findRole ? findRole.name : ''
                return (
                  <div
                    key={v.roleCode}
                    className="util-flexRow util-hooverableContainer util-hooverOp"
                  >
                    <div
                      className="util-flexColumn util-flexGrow"
                      onClick={
                        this.props.hasAccountDataPermission
                          ? () =>
                              this.handleStartEditAdditionalOwner(v.roleCode, v)
                          : null
                      }
                    >
                      <div className="LabeledField-label">{roleName}</div>
                      <div className="LabeledField-value">{v.name}</div>
                    </div>
                    <div className="util-hooverableContent util-flexEndContent">
                      <div
                        className="util-paddingSm"
                        title="Click to remove additional owner"
                        onClick={
                          this.props.hasAccountDataPermission
                            ? () => this.handleRemoveAdditionalOwner(v.roleCode)
                            : null
                        }
                      >
                        <FontAwesomeIcon icon="trash" />
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
          <div
            className={
              hasItems ? 'util-flexEndContent' : 'util-flexCenterContent'
            }
          >
            <span
              className=""
              title={this.props.intl.formatMessage({
                id: 'crm.ui.account.click_to_add_additional_owners',
                defaultMessage: 'Click to add additional owners'
              })}
              onClick={
                this.props.hasAccountDataPermission
                  ? this.handleStartAddAdditionalOwner
                  : null
              }
            >
              <FontAwesomeIcon
                className="util-hooverable util-pointer"
                icon="users"
              />
            </span>
          </div>
        </div>
      </div>
    )
  }

  handleStartAddAdditionalOwner = () => {
    this.setState({
      isReassignModal: true,
      selectedRoleCode: null,
      selectedAdditionalOwner: null,
      isNewAdditionalOwner: true
    })
  }

  handleStartEditAdditionalOwner = (roleCode, item) => {
    const canChangeOwner =
      this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.ADDITIONAL_OWNER_MODIFY,
        roleCode
      ) ||
      this.props.accountPermissionsContext.hasPermissionByType(
        PERMISSIONS_TYPES.ADDITIONAL_OWNER_MANAGE,
        roleCode
      )
    if (!canChangeOwner) {
      toastr.error("You don't have permission to change owner")
      return
    }
    this.setState({
      isReassignModal: true,
      selectedRoleCode: roleCode,
      selectedAdditionalOwner: item,
      isNewAdditionalOwner: false
    })
  }

  handleRemoveAdditionalOwner = async roleCode => {
    const toastrConfirmOptions = {
      onOk: async () => {
        try {
          const { account } = this.props
          const params = {
            accountUid: account.account_uid,
            roleCode: roleCode
          }
          await this.props.handleRemoveAdditionalOwner(params)
          toastr.success(
            this.props.intl.formatMessage({
              id: 'crm.ui.account.additional_owner_has_been_removed',
              defaultMessage: 'Additional owner has been removed'
            })
          )
        } catch (err) {
          toastr.error(
            this.props.intl.formatMessage({
              id:
                'crm.ui.account.there_was_a_problem_removing_additional_owner',
              defaultMessage: 'There was a problem removing additional owner'
            })
          )
          console.error(err)
        }
      }
    }
    toastr.confirm(
      this.props.intl.formatMessage({
        id:
          'crm.ui.account.are_you_sure_you_want_to_delete_this_additional_owner',
        defaultMessage: 'Are you sure you want to delete this additional owner?'
      }),
      toastrConfirmOptions
    )
  }

  render() {
    return (
      <React.Fragment>
        {this._renderAdditionalOwners()}
        <ReassignModal
          isOpen={this.state.isReassignModal}
          onClose={this.handleCloseReassignModal}
          onSave={this.handleSaveAdditionalOwner}
          account={this.props.account}
          showOnlyOwnerType={'user'}
          selectedRoleCode={this.state.selectedRoleCode}
          selectedOwner={this.state.selectedAdditionalOwner}
          isNew={this.state.isNewAdditionalOwner}
        />
      </React.Fragment>
    )
  }
}

AccountAdditionalOwners.propTypes = {
  account: shape({}).isRequired,
  hasAccountDataPermission: bool,
  handleRemoveAdditionalOwner: func.isRequired,
  intl: shape({}).isRequired,
  accountPermissionsContext: shape({}).isRequired
}
AccountAdditionalOwners.defaultProps = {
  hasAccountDataPermission: false
}
export default injectIntl(
  editAdditionalOwnersHoc(
    withAccountPermissionsContextHOC(AccountAdditionalOwners)
  )
)
