import React from 'react'
import { func, shape, bool } from 'prop-types'
import { reduxForm, propTypes, FormSection } from 'redux-form'
import TaskFormFields from './task-form-fields.jsx'
import { CrmButton } from 'crm-components'

const TaskFormInner = props => {
  const { handleSubmit, isSaving, task } = props

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormSection name="task">
          <TaskFormFields task={task} isSaving={isSaving} />
        </FormSection>
        <fieldset className="CrmForm-form-fieldset">
          {!props.isWidget && (
            <div className="row">
              <div className="col-md-12">
                <CrmButton
                  label="Save"
                  type="submit"
                  grouped
                  isSaving={props.isSaving}
                />
                <CrmButton
                  label="Cancel"
                  type="button"
                  onClick={props.onCancel}
                  grouped
                />
              </div>
            </div>
          )}
        </fieldset>
      </form>
    </div>
  )
}

TaskFormInner.propTypes = {
  ...propTypes,
  task: shape({}).isRequired,
  taskType: shape({}),
  handleSubmit: func.isRequired,
  isSaving: bool,
  isWidget: bool,
  onCancel: func
}

TaskFormInner.defaultProps = {
  taskType: undefined,
  onCancel: () => {},
  isWidget: false,
  isSaving: false
}

export default reduxForm({
  enableReinitialize: true
})(TaskFormInner)
