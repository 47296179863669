/* eslint-disable */
import React, { Component } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { arrayOf, shape } from 'prop-types'
import { qlqListOpportunities } from 'crm-data/opportunities'
import { getOpportunities } from 'crm-duxs/opportunities-reducer'
import { CrmFullpageContainer } from 'crm-components'

class OpportunitiesDashboardView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getTrProps(state, rowInfo) {
    return {
      onClick: () => {
        const id = rowInfo.original.datatype_uid
        console.dir(rowInfo.original)
        console.log('clicked id' + id)
        this.setState({ ...this.state, selectedDatatypeUid: id })
      },
      style: {
        cursor: 'pointer'
      }
    }
  }

  render() {
    return (
      <CrmFullpageContainer
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div>Opportunities Dashboard</div>
      </CrmFullpageContainer>
    )
  }
}

OpportunitiesDashboardView.propTypes = {
  opportunities: arrayOf(shape({})).isRequired
}

function mapStateToProps(state) {
  return {
    opportunities: getOpportunities(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(OpportunitiesDashboardView))
