/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { func, shape, string } from 'prop-types'
import { graphql, compose } from 'react-apollo'
import enhanceWithClickOutside from 'react-click-outside'
import { LoadingIcon } from 'util-components'
import { qlqQuickserachSearch } from 'crm-data/accounts'
import { CrmList } from 'crm-components'
import AccountListItem from 'crm-modules/accounts/components/account-list-item.jsx'
import ProcessListItem from 'crm-modules/processes/components/process-list-item.jsx'

class SearchResults extends PureComponent {
  componentWillReceiveProps(nextProps) {
    console.log('Search will received')
    console.dir(nextProps)
    if (nextProps.query !== this.props.query) {
      console.log('Force refetch')
      setTimeout(() => {
        this.props.data.refetch()
      })
    }
  }

  handleClickOutside = event => {
    event.preventDefault()
    this.props.onClickOutside()
  }
  handleCloseResults = () => {
    console.log('handleCloseResults')
    this.props.onClickOutside()
  }
  renderResult = r => {
    if (r.type === 'account' || r.type === 'contact') {
      return (
        <AccountListItem
          key={r.account_uid}
          account={r}
          checkPermission
          onClick={this.handleCloseResults}
        />
      )
    }
    if (r.type === 'process') {
      return (
        <ProcessListItem
          key={r.id}
          process={r}
          checkPermission
          onClick={this.handleCloseResults}
        />
      )
    }
    return (
      <div
        key={r.id}
        className="QuicksearchResults-item util-hooverable"
        onClick={this.handleClickOutside}
      >
        {r.account_uid ? (
          <Link to={`/crm/account/${r.account_uid}`}>
            <div dangerouslySetInnerHTML={{ __html: r.match }} />
          </Link>
        ) : (
          r.name
        )}
      </div>
    )
  }

  render() {
    const isLoading = this.props.data.loading
    const searchResults =
      this.props.data && this.props.data.search
        ? this.props.data.search.results
        : undefined
    return (
      <div className="QuicksearchResults">
        {isLoading && (
          <div className="util-flexRow util-alignCenter util-fullWidth util-paddingXl">
            <LoadingIcon />
          </div>
        )}
        {!isLoading && searchResults && (
          <CrmList dense>{searchResults.map(this.renderResult)}</CrmList>
        )}
      </div>
    )
  }
}
SearchResults.propTypes = {
  data: shape({}).isRequired,
  query: string,
  onClickOutside: func
}

SearchResults.defaultProps = {
  className: '',
  onClickOutside: () => {},
  query: undefined
}

const SearchResultsCO = enhanceWithClickOutside(SearchResults)
const SearchResultsQL = compose(
  graphql(qlqQuickserachSearch, {
    options: ownProps => ({
      variables: {
        query:
          typeof ownProps.query === 'string'
            ? ownProps.query.trim()
            : ownProps.query
      }
    }),
    props: ({ data, ownProps }) => ({ data, ...ownProps })
  })
)(SearchResultsCO)

export default SearchResultsQL
