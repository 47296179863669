import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import React, { PureComponent } from 'react'
import { arrayOf, shape, bool, func } from 'prop-types'
import uuidv4 from 'uuid'

const sectionContactPersonsHoc = WrappedComponent => {
  class SectionContactPersonsHocInner extends PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        persons: props.persons ? [...props.persons] : []
      }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      if (nextProps.persons !== this.props.persons) {
        this.setState({
          persons: nextProps.persons ? [...nextProps.persons] : []
        })
      }
    }

    isAddingNewPerson = () => {
      const findNewPerson = this.state.persons
        ? this.state.persons.find(v => v.isNew === true)
        : undefined
      if (findNewPerson) {
        return true
      } else {
        return false
      }
    }

    handleStartAddPerson = () => {
      const newPerson = {
        isNew: true,
        account_uid: this.props.account.account_uid,
        newObjectUid: uuidv4(),
        kind: 'individual',
        salutation: '',
        name: '',
        department: '',
        description: '',
        contact_details: [
          {
            kind: 'mobile',
            value: '',
            isNew: true
          },
          {
            kind: 'email',
            value: '',
            isNew: true
          },
          {
            kind: 'phone',
            value: '',
            isNew: true
          }
        ]
      }
      const persons = [newPerson, ...this.state.persons]
      this.setState({ persons })
    }

    handleCancelAddPerson = (index, person) => {
      if (!person.account_person_uid) {
        const persons = this.state.persons.filter(v => v.isNew !== true)
        this.setState({ persons })
      }
    }

    removePersonUI = async (index, pPerson) => {
      let persons = [...this.state.persons]
      persons.splice(index, 1)
      this.setState({ persons })
    }

    updatePersonsUI = async (index, pPerson) => {
      const persons = this.state.persons.map((v, i) =>
        i === index ? pPerson : v
      )
      this.setState({ persons })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          persons={this.state.persons}
          onStartAddPerson={this.handleStartAddPerson}
          onCancelAddPerson={this.handleCancelAddPerson}
          removePersonUI={this.removePersonUI}
          updatePersonsUI={this.updatePersonsUI}
          isAddingNewPerson={this.isAddingNewPerson}
          isExpanded={this.props.isExpanded}
          handleEditEnd={this.props.handleEditEnd}
          handleEditStart={this.props.handleEditStart}
          handleGuestsChange={this.props.handleGuestsChange}
          guests={this.props.guests}
          activity={this.props.activity}
          input={this.props.input}
          _getGuestsValue={this.props._getGuestsValue}
          _getGuestsDisplayValue={this.props._getGuestsDisplayValue}
        />
      )
    }
  }

  SectionContactPersonsHocInner.propTypes = {
    persons: arrayOf(shape()),
    isExpanded: bool,
    account: shape().isRequired,
    _getGuestsValue: func,
    activity: shape(),
    handleEditStart: func,
    handleEditEnd: func,
    handleGuestsChange: func,
    guests: arrayOf(shape()),
    input: arrayOf(shape()),
    _getGuestsDisplayValue: func
  }
  SectionContactPersonsHocInner.defaultProps = {
    isExpanded: false,
    _getGuestsValue: () => {},
    activity: undefined,
    handleEditStart: () => {},
    handleEditEnd: () => {},
    handleGuestsChange: () => {},
    guests: undefined,
    input: undefined,
    _getGuestsDisplayValue: () => {}
  }
  return crmGenericCommandHoc(SectionContactPersonsHocInner)
}

export default sectionContactPersonsHoc
