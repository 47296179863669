/* eslint-disable camelcase */
import { qlqGenericDataQuery } from 'crm-data/generic-data'
import { mapContextToFilter } from 'crm-modules/dashboard/dashboard-utils'

const getUserRow = (users, row) => {
  if (!users[row.created_by_user_uid]) {
    // eslint-disable-next-line
    users[row.created_by_user_uid] = {
      user_uid: row.created_by_user_uid,
      user_name: row.created_by__full_name,
      mainKpis: []
    }
  }
  return users[row.created_by_user_uid]
}

const parseCrmActivityInPeriod = data => {
  const users = {}
  console.log('parse Activity in Period')
  data.forEach(row => {
    const user = getUserRow(users, row)
    if (!user.crmActivityInPeriod) {
      user.crmActivityInPeriod = {
        meetingsCreated: 0,
        callsCreated: 0,
        newAccountsCreated: -1
      }
    }

    if (row.type__medium_code === 'meeting') {
      // eslint-disable-next-line
      users[
        row.created_by_user_uid
      ].crmActivityInPeriod.meetingsCreated += Number(row.activity_uid)
    }
    if (row.type__medium_code === 'phone_call') {
      users[row.created_by_user_uid].crmActivityInPeriod.callsCreated += Number(
        row.activity_uid
      )
    }
  })

  return users
}
export const mock = 1
export const loadCrmActivityInPeriod = async params => {
  console.log('loadCrmActivityInPeriod', params)

  const filter = mapContextToFilter(params)
  if (filter.user_uid) {
    filter.created_by_user_uid = filter.user_uid
    delete filter.user_uid
    delete filter.owner_user_uid
  }
  if (params.analyticsContext) {
    if (params.analyticsContext.filters.activity_date) {
      filter.created_date = params.analyticsContext.filters.activity_date
    }
  }
  const variables = {
    filter,
    fields: [
      'created_by_user_uid',
      'created_by__full_name',
      'type__medium_code'
    ],
    aggregate: [{ id: 'activity_uid', type: 'count' }],
    rootType: 'ActivitiesListQM'
  }

  const res = await params.client.query({
    query: qlqGenericDataQuery,
    variables,
    fetchPolicy: 'no-cache'
  })
  console.log('Got loadCrmActivityInPeriod result', res)
  if (res && res.data && res.data.genericDataQuery) {
    return parseCrmActivityInPeriod(res.data.genericDataQuery.data)
  }
  return undefined
}
