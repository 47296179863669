import React from 'react'
import { shape, bool, arrayOf, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import TaskListItem from './task-list-item.jsx'
import NewTaskForm from '../../../tasks/components/new-task-form.jsx'
import { CrmButton } from 'crm-components'
import { LoadingIcon } from 'util-components'

class InnerTasksList extends React.PureComponent {
  renderTaskList() {
    return (
      <div className="ActivitiesList" style={this.props.style}>
        {this.props.isLoading && (
          <div className="util-fullWidth util-textCenter">
            <LoadingIcon />
          </div>
        )}
        {this.props.tasks && this.props.tasks.length > 0 ? (
          this.props.tasks.map(task => {
            const handleTaskClicked = () => {
              if (this.props.onTaskItemClicked) {
                this.props.onTaskItemClicked(task)
              }
            }
            return (
              <TaskListItem
                accountUid={this.props.accountUid}
                key={task.task_uid}
                task={task}
                onClick={handleTaskClicked}
                processStepCode={this.props.processStepCode}
                accountKind={this.props.accountKind}
              />
            )
          })
        ) : (
          <div>
            <div className="util-textLight util-textCenter">
              <FormattedMessage
                id="crm.ui.account.prospect.no_tasks_for_this_account"
                defaultMessage="There are no tasks for this account."
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className="CrmFormSection" style={{ marginTop: '20px' }}>
        {this.props.isAddingTask && (
          <NewTaskForm
            task={this.props.task}
            accountUid={this.props.accountUid}
            onTaskCreated={this.props.onTaskCreated}
            onCancel={this.props.onCancelCreated}
          />
        )}
        {this.renderTaskList()}
      </div>
    )
  }
}

InnerTasksList.defaultProps = {
  style: {},
  onTaskItemClicked: undefined,
  onTaskCreated: func.isRequired,
  onCancelCreated: func.isRequired,
  onAddTask: func.isRequired,
  tasks: undefined,
  isLoading: false
}

InnerTasksList.propTypes = {
  tasks: arrayOf(shape({})),
  isLoading: bool,
  style: shape({}),
  onTaskItemClicked: func
}

export default InnerTasksList
