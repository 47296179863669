/* eslint-disable */

// export const meetingsCreatedToday = {
//   columns_dims: [
//     {
//       dim_code: 'month_fitted',
//       kpi_code: 'fitment_details',
//       name: 'Month Fitted'
//     }
//   ],
//   rows_dims: [
//     {
//       dim_code: 'month_fitted',
//       kpi_code: 'fitment_details',
//       name: 'Month Fitted'
//     }
//   ],
//   kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
//   title: 'TM New Appointments',
//   description: 'This month',
//   filters: [
//     {
//       kpi_code: 'fitment_details',
//       dim_code: 'date_fitted',
//       operator: 'eq',
//       expression: { fn: 'date_range_today' }
//     }
//   ],
//   layout: 'single_number'
// }

// export const meetingsCreatedToday = {
//   columns_dims: [
//     {
//       dim_code: 'month_fitted',
//       kpi_code: 'fitment_details',
//       name: 'Month Fitted'
//     }
//   ],
//   rows_dims: [
//     {
//       dim_code: 'month_fitted',
//       kpi_code: 'fitment_details',
//       name: 'Month Fitted'
//     }
//   ],
//   kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
//   title: 'TM New Appointments',
//   description: 'This month',
//   filters: [
//     {
//       kpi_code: 'fitment_details',
//       dim_code: 'date_fitted',
//       operator: 'eq',
//       expression: { fn: 'date_range_today' }
//     }
//   ],
//   layout: 'single_number'
// }

export const fitmentsToday = {
  title: 'Fitments Today',
  def_version: 5,
  data: {
    dataSources: [
      {
        code: 'OrdersListQM',
        alias: 'OrdersListQM',
        fields: [],
        aggregate: [{ id: 'units_fitted', type: 'sum' }],
        filter: {
          fitment_date: 'now()'
        }
      }
    ],
    aggregates: {
      fitmentsToday: {
        type: 'sum',
        dataSource: 'OrdersListQM',
        valueField: 'units_fitted',
        aggregateBy: 'fitment_date'
      }
    }
  },
  layout: {
    items: [
      {
        type: 'labeledField',
        props: {
          value: {
            type: 'aggregates',
            dataField: 'fitmentsToday'
          }
        }
      }
    ]
  }
}

export const fitmentsMtd = {
  title: 'Fitments MTD',
  def_version: 5,
  data: {
    dataSources: [
      {
        code: 'OrdersListQM',
        alias: 'OrdersListQM',
        fields: [],
        aggregate: [{ id: 'units_fitted', type: 'sum' }],
        filter: {
          completed_date: { value: 'now()', dateTrunc: 'month' }
        }
      }
    ],
    aggregates: {
      fitmentsMtd: {
        type: 'sum',
        dataSource: 'OrdersListQM',
        valueField: 'units_fitted'
      }
    }
  },
  layout: {
    items: [
      {
        type: 'labeledField',
        props: {
          value: {
            type: 'aggregates',
            dataField: 'fitmentsMtd'
          }
        }
      }
    ]
  }
}

// export const meetingsToday = {
//   columns_dims: [
//     {
//       dim_code: 'month_fitted',
//       kpi_code: 'fitment_details',
//       name: 'Month Fitted'
//     }
//   ],
//   rows_dims: [
//     {
//       dim_code: 'month_fitted',
//       kpi_code: 'fitment_details',
//       name: 'Month Fitted'
//     }
//   ],
//   kpis: [{ code: 'fitment_details', as: 'fitment_details' }],
//   title: 'Meetings today',
//   description: 'This month',
//   filters: [
//     {
//       kpi_code: 'fitment_details',
//       dim_code: 'date_fitted',
//       operator: 'eq',
//       expression: { fn: 'date_range_today' }
//     }
//   ],
//   layout: 'single_number'
// }

export const meetingsToday = {
  title: 'Meetings Completed Today',
  def_version: 5,
  data: {
    dataSources: [
      {
        code: 'ActivitiesListQM',
        alias: 'ActivitiesListQM',
        fields: ['start_date'],
        aggregate: [{ id: 'start_date', type: 'count' }],
        filter: {
          start_date: 'now()',
          medium_code: 'meeting',
          activity_status_type: 'completed'
        }
      }
    ],
    aggregates: {
      meetingsToday: {
        type: 'sum',
        dataSource: 'ActivitiesListQM',
        valueField: 'count',
        aggregateBy: 'start_date'
      }
    }
  },
  layout: {
    items: [
      {
        type: 'labeledField',
        props: {
          value: {
            type: 'aggregates',
            dataField: 'meetingsToday'
          }
        }
      }
    ]
  }
}

export const meetingsCreatedToday = {
  title: 'Meetings Created Today',
  def_version: 5,
  data: {
    dataSources: [
      {
        code: 'ActivitiesListQM',
        alias: 'ActivitiesListQM',
        fields: ['created_date'],
        aggregate: [{ id: 'value', type: 'count' }],
        filter: {
          created_date: 'now()',
          medium_code: 'meeting'
        }
      }
    ],
    aggregates: {
      meetingsCreatedToday: {
        type: 'sum',
        dataSource: 'ActivitiesListQM',
        valueField: 'value',
        aggregateBy: 'owner_user_uid'
      }
    }
  },
  layout: {
    items: [
      {
        type: 'labeledField',
        props: {
          value: {
            type: 'aggregates',
            dataField: 'meetingsCreatedToday'
          },
          label: 'New Meetings Today'
        }
      }
    ]
  }
}

const dashLiveView = {
  dashboard_uid: 'dashLiveView',
  name:
    'Live View - show current calls, current meetings (today), fitments - today. Who is where and whats happening',
  fullscreen: true,
  content: {
    items: [
      {
        type: 'container',
        class: 'row util-marginBottom',
        items: [
          {
            type: 'container',
            class: 'col-md-2',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: meetingsCreatedToday
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-2',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: fitmentsToday
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-2',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: fitmentsMtd
              }
            ]
          },
          {
            type: 'container',
            class: 'col-md-2',
            items: [
              {
                type: 'analitics_widget',
                widgetDefinition: meetingsToday
              }
            ]
          }
        ]
      }
    ]
  }
}

export default dashLiveView
