import React, { useState } from 'react'
import styled from 'styled-components'
import {
  FilterSectionLead,
  FilterSectionUser,
  FilterSectionCampaign,
  FilterSectionActivityDate,
  FilterSectionOrganizationUnit
} from '@cartrack-crm/crm-filter-section'
import { FilterSectionDashboard } from './filter-section-report-type'
import { DashboardFiltersWrapper } from '@cartrack-crm/crm-filter-section/src/styled/dashboard-filters-section'
import { CrmButton } from '@cartrack-crm/ui'
import { ReportFiltersProps } from '../type'
import { MessageBox } from './message-box'

const ButtonWrapper = styled.div`
  border-bottom: 1px solid #97979780;
  border-right: 1px solid #97979780;
  display: flex;
  flex-grow: 1;
  flex: 7;
  padding: 10px 20px;
`
const FilterButton = styled(CrmButton)`
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
`

export const ReportFilters = ({ dashboard, setDashboard, onApply }: ReportFiltersProps) => {
  const [isShowWarning, setWarning] = useState(false)
  const isDisabled = !dashboard
  const handleApply = () => {
    setWarning(isDisabled)
    onApply?.()
  }
  const isDisabledLead =
    isDisabled ||
    [
      'ctMonthlyLeadsDashboard',
      'ctNewAgentDashboard',
      'ctNewRmDashboard',
      'ctNewSaleManagerDashboard',
      'ctNewTeleSaleManagerDashboard'
    ].includes(dashboard?.value)
  const isDisableOrganization =
    isDisabled ||
    [
      'ctMonthlyLeadsDashboard',
      'ctMonthlyLeadsResultsDashboardB2B',
      'ctMonthlyLeadsResultsDashboardB2C',
      'ctNewAgentDashboard',
      'ctNewRmDashboard'
    ].includes(dashboard?.value)
  const isDisableUser =
    isDisabled ||
    ['ctNewSaleManagerDashboard', 'ctNewTeleSaleManagerDashboard', 'ctMonthlyLeadsDashboard'].includes(dashboard?.value)
  const isDisableActivityDate =
    isDisabled ||
    [
      'agentLiveMonitoringB2B',
      'agentLiveMonitoringB2C',
      'ctAgentOwnedLeads',
      'ctCampaignStatistics',
      'ctLeadsCurrentStatus',
      'ctLeadsStatusByLeadDate',
      'ctMonthlyLeadsDashboard',
      'ctMonthlyLeadsResultsDashboardB2B',
      'ctMonthlyLeadsResultsDashboardB2C',
      'ctNewAgentDashboard',
      'ctNewRmDashboard',
      'ctNewSaleManagerDashboard',
      'ctNewTeleSaleManagerDashboard'
    ].includes(dashboard?.value)
  const isDisableCampaign =
    isDisabled ||
    [
      'ctMonthlyLeadsDashboard',
      'ctNewAgentDashboard',
      'ctNewRmDashboard',
      'ctNewSaleManagerDashboard',
      'ctNewTeleSaleManagerDashboard'
    ].includes(dashboard?.value)
  return (
    <DashboardFiltersWrapper>
      <div className="util-flexRow">
        <div style={{ width: '400px' }}>
          <FilterSectionDashboard dashboard={dashboard} setDashboard={setDashboard} />
        </div>
        <div className="util-flexGrow">
          <FilterSectionLead isDisabled={isDisabledLead} />
        </div>
        <div>
          <FilterSectionOrganizationUnit isDisabled={isDisableOrganization} />
        </div>
        <div className="util-flexGrow">
          <FilterSectionUser isDisabled={isDisableUser} />
        </div>
      </div>
      <div className="util-flexRow">
        <div style={{ flex: 5 }}>
          <FilterSectionActivityDate isDisabled={isDisableActivityDate} />
        </div>
        <div style={{ flex: 5 }}>
          <FilterSectionCampaign isDisabled={isDisableCampaign} />
        </div>
        <ButtonWrapper>
          <FilterButton label="Apply Filters" type="primary" small onClick={handleApply} />
          {isShowWarning && !dashboard && <MessageBox message="Please select one Report Type" type="danger" />}
        </ButtonWrapper>
      </div>
    </DashboardFiltersWrapper>
  )
}
