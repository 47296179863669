import React from 'react'
import crmGenericCommandHoc from 'crm-components/ql/crm-generic-command-ql-hoc.jsx'
import { qlqfetchAccountTags } from 'crm-data/accounts'
import { LIST_OF_ACCOUNT_TAGS } from 'crm-data/account-tags'
import { withToastrWithRunApiHoc } from '@cartrack-crm/ui/src/toastrs'
const ADD_TAG_TO_ACCOUNT = 'account.addTag'
const REMOVE_TAG_FROM_ACCOUNT = 'account.removeTag'
interface ListAccountTag {
  code: string,
  name: string,
  icon: string
}
interface ChangeAccountTagHocProps {
  account: { account_uid: string },
  onRunCommand: any,
  runApiWithToastr: any
}
interface ChangeAccountTagHocState {
  tagOnSaving: string,
  listAccountTags: ListAccountTag[]
}
export function buildCommand(account: { account_uid: string }, payload: { code: string, name?: string }, type: string) {
  const command = {
    type: type,
    aggregate_uid: account.account_uid,
    aggregate_type: 'account',
    payload: payload
  }
  return command
}
const changeAccountTagHoc = WrappedComponent => {
  class ChangeAccountTagHoc extends React.PureComponent<ChangeAccountTagHocProps, ChangeAccountTagHocState>{
    constructor(props) {
      super(props)
      this.state = {
        tagOnSaving: '',
        listAccountTags: []
      }
    }
    setStateAsync = newState =>
      new Promise(resolve => {
        this.setState(newState, resolve)
      })
    componentDidMount = () => {
      this.loadAccountTags()
    }
    loadAccountTags = () => {
      this.setState({
        listAccountTags: LIST_OF_ACCOUNT_TAGS
      })
    }
    buildQueryForReFetchTag = () => {
      const accountUid = this.props.account.account_uid
      const query = {
        query: qlqfetchAccountTags,
        variables: {
          account_uid: accountUid
        },
        partialRefetch: true
      }
      return query
    }
    handleAddAccountTag = async (code: string, name: string) => {
      console.log('on Add Tag', this.props)
      await this.setStateAsync({ tagOnSaving: code })
      const payload = { code: code, name: name }
      const command = buildCommand(this.props.account, payload, ADD_TAG_TO_ACCOUNT)
      const refetchQueries = this.buildQueryForReFetchTag()
      const { onRunCommand } = this.props
      try {
        const res = await this.props.runApiWithToastr(
          () => onRunCommand(command, { refetchQueries: [refetchQueries] }),
          { successMessage: 'Tag Saved' }
        )
        await this.setStateAsync({ tagOnSaving: '' })
        return res.data.cqCommand
      } catch (err) {
        throw new Error('Change Process add tag error')
      }
    }
    handleRemoveTagFromAccount = async (code: string) => {
      await this.setStateAsync({ tagOnSaving: code })
      const payload = { code: code }
      const command = buildCommand(this.props.account, payload, REMOVE_TAG_FROM_ACCOUNT)
      const refetchQueries = this.buildQueryForReFetchTag()
      const { onRunCommand } = this.props
      try {

        const res = await this.props.runApiWithToastr(
          () => onRunCommand(command, { refetchQueries: [refetchQueries] }),
          { successMessage: 'Tag Saved' }
        )
        await this.setStateAsync({ tagOnSaving: '' })
        return res.data.cqCommand
      } catch (err) {
        throw new Error('Change Process add tag error')
      }
    }
    render = () => (
      <WrappedComponent
        {...this.props}
        tagOnSaving={this.state.tagOnSaving}
        listAccountTags={this.state.listAccountTags}
        onAddAccountTag={this.handleAddAccountTag}
        onRemoveTagFromAccount={this.handleRemoveTagFromAccount}
      />
    )
  }
  return withToastrWithRunApiHoc(crmGenericCommandHoc(ChangeAccountTagHoc))
}

export default changeAccountTagHoc
