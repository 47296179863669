import settingsAPI from 'crm-api/settings/departments-api'
import {
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENTS,
  CREATE_DEPARTMENT,
  CREATE_DEPARTMENT_SUCCESS,
  CLEAR_SELECT_DEPARTMENT,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS
} from 'crm-duxs/settings/department-reducer'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchDepartments() {
  const departments = yield call(settingsAPI.fetchDepartments)
  yield put({ type: FETCH_DEPARTMENT_SUCCESS, payload: { departments } })
}

function* createDepartment(data) {
  const department = yield call(settingsAPI.saveDepartment, data)
  yield put({ type: CREATE_DEPARTMENT_SUCCESS, payload: { department } })
  yield put({ type: CLEAR_SELECT_DEPARTMENT })
}

function* updateDepartment(data) {
  const department = yield call(settingsAPI.updateDepartment, data)
  yield put({ type: UPDATE_DEPARTMENT_SUCCESS, payload: { department } })
  yield put({ type: CLEAR_SELECT_DEPARTMENT })
}

function* settingDepaertmentSaga() {
  yield takeLatest(FETCH_DEPARTMENTS, fetchDepartments)
  yield takeLatest(CREATE_DEPARTMENT, createDepartment)
  yield takeLatest(UPDATE_DEPARTMENT, updateDepartment)
}

export default settingDepaertmentSaga
