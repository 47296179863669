/* eslint-disable global-require */
/* eslint-disable camelcase */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { shape, func, arrayOf } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { gqlCreateMessage } from './messages-tab-messages-hoc'
import {
  CrmButton,
  CrmTextInput,
  CrmTextArea,
  CrmHbox,
  CrmCheckbox
} from 'crm-components'
import { getUsers, actions } from 'crm-duxs/messages-reducer'
import { bindActions } from 'crm-utils/reducer-utils'
import { getAvatarUrl } from 'crm-utils/accounts-utils'

class CreateMessageform extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSelectUser = this.handleSelectUser.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleNotiyfyChange = this.handleNotiyfyChange.bind(this)
    this.handleClickSend = this.handleClickSend.bind(this)
    this._renderUserItem = this._renderUserItem.bind(this)
  }

  handleMessageChange(event) {
    this.setState({ ...this.state, message: event.currentTarget.value })
  }
  handleTitleChange(event) {
    this.setState({ ...this.state, title: event.currentTarget.value })
  }
  handleNotiyfyChange() {
    const notify = this.state.notify ? this.state.notify : false
    this.setState({ ...this.state, notify: !notify })
  }
  handleClickSend = async () => {
    if (!this.state.title || !this.state.selectedUser) {
      return toastr.error('Please select user and write message title')
    }

    this.setState({ ...this.state, isSending: true })
    const message = {
      to_user_uid: this.state.selectedUser.user_uid,
      from_user_uid: this.props.users.user_uid,
      title: this.state.title,
      body: this.state.message,
      type: 'message',
      params: {},
      email_notification_type: 'immediate',
      mobile_notification_type: this.state.notify ? 'immediate' : ''
    }
    const variables = { message }
    await this.props.createMessageQl({ variables })
    toastr.info('Message sent')
    this.setState({
      isSending: false,
      title: '',
      message: '',
      selectedUser: undefined,
      notify: false
    })
  }

  handleSelectUser(event) {
    const id = event.currentTarget.id
    const user = this.props.users
      ? this.props.users.find(u => u.user_uid === id)
      : undefined
    this.setState({ ...this.state, selectedUser: user })
  }

  componentDidMount() {
    this.props.actions.fetchUsers()
  }

  _renderUserItem(user) {
    const headshotUrl = user.headshot_url

    return (
      <div
        key={user.user_uid}
        id={user.user_uid}
        className="CrmMessagesPopup-userItem"
        onClick={this.handleSelectUser}
      >
        <div className="CrmMessagesPopup-userItem-headshot">
          <img
            className="CalendarEventItem-avatar-img"
            src={getAvatarUrl(headshotUrl)}
            width="30"
            height="30"
          />
        </div>
        <div className="CrmMessagesPopup-userItem-body">
          <div className="CrmMessagesPopup-userItem-name">{user.full_name}</div>
          <div className="CrmMessagesPopup-userItem-details">{user.mobile}</div>
        </div>
      </div>
    )
  }
  render() {
    const users = this.props.users
      ? this.props.users.filter(u => u.is_active)
      : []
    return (
      <CrmHbox fill fullHeight>
        <div className="CrmMessagesPopup-usersList">
          {users.map(this._renderUserItem)}
        </div>
        <div className="CrmMessagesPopup-rightWrapper">
          <div className="util-marginTop">
            <h4>
              Send message to:{' '}
              {this.state.selectedUser
                ? this.state.selectedUser.full_name
                : 'Select User First'}
            </h4>
            {this.state.selectedUser && (
              <div>
                <div className="row util-marginTop">
                  <div className="col-md-8">
                    <CrmTextInput
                      placeholder="Title"
                      input={{
                        value: this.state.title,
                        onChange: this.handleTitleChange
                      }}
                    />
                  </div>
                </div>
                <div className="row util-marginTop">
                  <div className="col-md-8">
                    <CrmTextArea
                      placeholder="Type message here"
                      lines="5"
                      input={{
                        value: this.state.message,
                        onChange: this.handleMessageChange
                      }}
                    />
                  </div>
                </div>
                <div className="row util-marginTop">
                  <div className="col-md-8">
                    <CrmCheckbox
                      name="notify"
                      label="Notify on mobile"
                      placeholder=""
                      value={this.state.notify}
                      onChange={this.handleNotiyfyChange}
                    />
                  </div>
                </div>
                <div className="row util-marginTop">
                  <div className="col-md-8" style={{ textAlign: 'right' }}>
                    <CrmButton
                      label={
                        this.state.isSending ? 'Sending...' : 'Send Message'
                      }
                      disabled={this.state.isSending}
                      type="primary"
                      onClick={this.handleClickSend}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CrmHbox>
    )
  }
}

CreateMessageform.propTypes = {
  users: arrayOf(shape()).isRequired,
  actions: shape({}).isRequired,
  fetchUsers: func.isRequired,
  createMessageQl: func.isRequired
}

function mapStateToProps(state) {
  return {
    users: getUsers(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActions(dispatch, actions)
  }
}

export default gqlCreateMessage(
  connect(mapStateToProps, mapDispatchToProps)(CreateMessageform)
)
