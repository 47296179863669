import React from 'react'
import { string, bool, func, shape } from 'prop-types'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { reduxForm } from 'redux-form'
import { FormattedTime, FormattedMessage } from 'react-intl'
import CallerWidget from './caller-widget.jsx'
import CallDisposition from './call-disposition.jsx'
import LiveDurationCallTime from './live-duration-call-time.jsx'
import liveCallHoc from 'crm-modules/activities/hoc/live-call-hoc.jsx'
import { CrmTextArea, CrmButton } from 'crm-components'
import { actions } from 'crm-duxs/activities/live-call-reducer'
import {
  actions as phoneActions,
  getCallStartTime,
  getCallEndTime
} from 'crm-duxs/phone/phone-reducer'
import { bindActions } from 'crm-utils/reducer-utils'
import styles from './live-call.scss'
import SalesCallInner from './sales-call-inner.jsx'

class LiveCallNew extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    if (this.props.clearIsLiveCallEditAction) {
      this.props.clearIsLiveCallEditAction()
    }
  }

  handlePhoneNumberChanged = event => {
    // console.log('handlePhoneNumberChanged', event)
  }

  handleCallCompleted = async () => {
    // Check for conditions
    if (!this.props.activity.disposition_type_uid) {
      return toastr.error(
        'You have to select Call Disposition before completing the call'
      )
    }
    if (!this.props.activity.activity_status) {
      return toastr.error(
        'You have to select status before completing the call'
      )
    }
    try {
      if (!this.props.isSaving) {
        const activity = await this.props.onCallCompleted()
        toastr.success('Call completed!')
        if (this.props.onActivityCompleted) {
          this.props.onActivityCompleted(activity)
        }
      }
    } catch (err) {
      // console.log('error', err)
      toastr.error(
        'Problem completing activity' + (err && err.message ? err.message : '')
      )
    }
  }

  handleCancel = async event => {
    event.preventDefault()
    this.props.onActivityCompleted()
  }

  handleDeleteActivity = async () => {
    var r = confirm('Are you sure you want to delete this activity ?')
    if (r === true) {
      try {
        const is_deleted = true
        const res = await this.props.onDeleteActivity(
          this.props.activity,
          is_deleted
        )
        console.log('Activity deleted', res, this.props)
        toastr.success('Activity deleted')
        this.props.onActivityCompleted()
      } catch (err) {
        console.log('Delete Activity error', err)
        toastr.error('Delete Activity error')
      }
    }
  }

  render() {
    const {
      activity,
      isNoteChanged,
      isSaving,
      isDispositionChanged
    } = this.props

    const useNewSalesCallForm = false
    console.log('LiveCallWindow.render')
    const isNewCall = !(activity && activity.call_asterisk_id)
    const isPending = !!(activity && !activity.completed_time)

    return (
      <form className={styles.LiveCall} style={{ height: '100%' }}>
        {/* <div className="LiveCall-overlay" /> */}
        <div className={styles['LiveCall-window']}>
          <div className={` row ${styles['LiveCall-header']}`}>
            <div className="col-md-2">
              <div className="util-textOrange util-paddingSm">Live Call</div>
            </div>
            <div className="col-md-2 util-textSmall util-flexCenterContent util-paddingSm">
              <FormattedMessage
                id="crm.ui.activity.form.started"
                defaultMessage="Started "
              />
              <strong style={{ marginTop: 2 }}>
                <FormattedTime value={activity.start_time} />
              </strong>
            </div>
            <div className="col-md-2 util-textSmall util-flexCenterContent util-paddingSm">
              {activity.end_time && (
                <FormattedMessage
                  id="crm.ui.activity.form.ended"
                  defaultMessage="Ended "
                />
              )}
              {activity.end_time && (
                <strong style={{ marginTop: 2 }}>
                  <FormattedTime value={activity.end_time} />
                </strong>
              )}
            </div>
            <div className="col-md-2 util-textSmall util-flexCenterContent util-paddingSm">
              {activity.call_start_time && (
                <FormattedMessage
                  id="crm.ui.activity.form.call_started"
                  defaultMessage="Call Started  "
                />
              )}
              <strong style={{ marginTop: 2 }}>
                {activity.call_start_time && (
                  <FormattedTime value={activity.call_start_time} />
                )}
              </strong>
            </div>
            <div className="col-md-2 util-textSmall util-flexCenterContent util-paddingSm">
              {activity.end_time && (
                <FormattedMessage
                  id="crm.ui.activity.form.call_ended"
                  defaultMessage="Call Ended  "
                />
              )}
              {activity.end_time && (
                <strong style={{ marginTop: 2 }}>
                  <FormattedTime value={activity.call_end_time} />
                </strong>
              )}
            </div>
            <div className="col-md-2 util-textSmall util-flexCenterContent util-paddingSm">
              <LiveDurationCallTime
                startTime={this.props.callStartTime}
                endTime={this.props.callEndTime}
              />
            </div>
            <div className="col-md-2 util-textSmall util-flexCenterContent">
              &nbsp;
            </div>
            <div className="col-md-1 ">
              <span
                style={{
                  float: 'right',
                  position: 'absolute',
                  right: '5px',
                  top: '5px'
                }}
              >
                {isSaving && <i className="fa fa-save" />}
              </span>
            </div>
          </div>
          <div className="util-paddingLg  LiveCall-content">
            <div className="row">
              <div className="col-md-12">
                <CallerWidget
                  contact_details_uid={activity.contact_details_uid}
                  accountUid={this.props.accountUid}
                  activityUid={this.props.activity.activity_uid}
                  onPhoneNumberChanged={this.handlePhoneNumberChanged}
                  onCallStatusChanged={this.props.onCallStatusChanged}
                  activityStatus={this.props.activity.activity_status}
                  autoDial
                  isNewCall={isNewCall}
                  isPending={isPending}
                  isLiveCallEditAction={this.props.isLiveCallEditAction}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                {activity.activity_status ? 'Status' : ''}
              </div>
              <div className="col-md-8">
                {activity.activity_status && (
                  <span>{activity.activity_status.name}</span>
                )}
              </div>
            </div>
            {useNewSalesCallForm && (
              <SalesCallInner
                activity={activity}
                onResultDataChange={this.props.onResultDataChange}
              />
            )}

            {activity.activity_status && !useNewSalesCallForm && (
              <div className="row">
                <div className="col-md-12">
                  <div className="util-flexRow util-textSmall util-marginBottom">
                    <div>
                      <FormattedMessage
                        id="crm.ui.activity.form.note_from_the_call:"
                        defaultMessage="Note from the call:"
                      />
                    </div>
                    <div className="util-flexGrow util-textRight">
                      {isNoteChanged && <i className="fa fa-pencil" />}
                    </div>
                  </div>
                  <CrmTextArea
                    rows="8"
                    placeholder="Call Note"
                    placeholderId="crm.ui.textarea.call_note"
                    style={{ maxWidth: '100%' }}
                    input={{
                      onChange: this.props.onNoteChanged,
                      value: this.props.noteText
                    }}
                  />
                </div>
              </div>
            )}

            {!activity.activity_status && (
              <div
                className="util-flexGrow util-textCenter util-textWhite util-bgGreen
              util-paddingMd"
              >
                <p>
                  <FormattedMessage
                    id="crm.ui.activity.form.wait_for_dialing_and_select_call_status"
                    defaultMessage="Wait for dialing and select call status."
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="crm.ui.activity.form.remember_select_status_immediately_after_answer"
                    defaultMessage="Remember to select status immediately after answer "
                  />
                </p>
              </div>
            )}
            {activity.activity_status && (
              <div className="util-flexGrow util-textRight">
                {isDispositionChanged && <i className="fa fa-pencil" />}
              </div>
            )}
            {activity.activity_status && isPending && (
              <CallDisposition
                accountUid={this.props.accountUid}
                account={this.props.account}
                activity={activity}
                value={activity.disposition_type_uid}
                onChange={this.props.onDispositionChange}
                activityStatus={activity.activity_status}
                form={this.props.form}
              />
            )}
            <div className="row" />

            {!isPending && (
              <div className="row">
                <div className="col-md-12 util-textRight">
                  {activity.activity_status && (
                    <CrmButton
                      icon="close"
                      label={
                        <FormattedMessage
                          id="crm.ui.button.close"
                          defaultMessage="Close"
                        />
                      }
                      grouped
                      onClick={this.handleCancel}
                    />
                  )}
                </div>
              </div>
            )}

            {isPending && (
              <div className="row">
                <div className="col-md-6 util-textLeft">
                  <CrmButton
                    type="secondary"
                    small
                    label={
                      <FormattedMessage
                        id="crm.ui.button.cancel_call"
                        defaultMessage="Cancel Call"
                      />
                    }
                    onClick={this.handleCancel}
                  />
                </div>
                <div className="col-md-6 util-textRight">
                  {activity.activity_status && (
                    <CrmButton
                      type="primary"
                      label={
                        <FormattedMessage
                          id="crm.ui.button.call_completed"
                          defaultMessage="Call completed"
                        />
                      }
                      isSaving={this.props.isSaving}
                      onClick={this.handleCallCompleted}
                      disabled={this.props.isSaving}
                    />
                  )}
                </div>
              </div>
            )}

            {this.props.isManager && (
              <div className="row">
                <div className="col-md-12 util-textRight">
                  <CrmButton
                    onClick={this.handleDeleteActivity}
                    label={
                      this.props.isDeletetingActivity ? (
                        <FormattedMessage
                          id="crm.ui.button.deleting"
                          defaultMessage="Deleting.."
                        />
                      ) : (
                        <FormattedMessage
                          id="crm.ui.button.delete"
                          defaultMessage="Delete Activity"
                        />
                      )
                    }
                    enable={
                      this.props.isManager && !this.props.isDeletetingActivity
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    )
  }
}

LiveCallNew.propTypes = {
  accountUid: string.isRequired,
  callStartTime: string.isRequired,
  callEndTime: string.isRequired,
  isNoteChanged: bool.isRequired,
  onNoteChanged: func.isRequired,
  onDispositionChange: func.isRequired,
  onCallStatusChanged: func.isRequired,
  onCallCompleted: func.isRequired,
  noteText: string.isRequired,
  isSaving: bool.isRequired,
  activity: shape({}).isRequired,
  isDispositionChanged: bool.isRequired,
  onActivityCompleted: func.isRequired,
  form: string.isRequired,
  account: shape().isRequired,
  onResultDataChange: func,
  onDeleteActivity: func,
  isManager: bool,
  isDeletetingActivity: bool,
  isLiveCallEditAction: bool,
  clearIsLiveCallEditAction: func
}
LiveCallNew.defaultProps = {
  onResultDataChange: undefined,
  isManager: false,
  onDeleteActivity: undefined,
  isDeletetingActivity: false,
  isLiveCallEditAction: false,
  clearIsLiveCallEditAction: undefined
}

function mapStateToProps(state) {
  return {
    callStartTime: getCallStartTime(state),
    callEndTime: getCallEndTime(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    phoneActions: bindActions(dispatch, phoneActions),
    liveCallActions: bindActions(dispatch, actions)
  }
}
const LiveCallQLForm = reduxForm({
  enableReinitialize: true,
  form: 'liveCall'
})(LiveCallNew)
const LiveCallQL = liveCallHoc(LiveCallQLForm)

export default connect(mapStateToProps, mapDispatchToProps)(LiveCallQL)
