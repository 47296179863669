import React, { PureComponent } from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { getButtonClassProperties } from '@cartrack-crm/ui'
import '../../style/crm-dropdown-button/_crm-dropdown-button.scss'
/* eslint-disable */
const optionButtonRender = props => {
  console.log('optionButtonRender : ')

  const label = Object.prototype.hasOwnProperty.call(props, 'label')
    ? props.label
    : ''
  const onClick = Object.prototype.hasOwnProperty.call(props, 'onClick')
    ? event => {
      props.hideOption()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      if (props.onClick) {
        props.onClick(event)
      }
    }
    : event => {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      props.hideOption()
    }
  return (
    <li
      key={props.key ? props.key : label}
      onClick={onClick}
      id={props.id}
      className="Dropdown-option CrmDropdownButton-optionItem"
    >
      {label}
    </li>
  )
}
/* eslint-enable */
interface ICrmDropdownButton {
  options: { name: string, value: string, hideOption: any }[]
  onClick: Function
  id?: string
  label?: string
  style?: React.CSSProperties
  small?: boolean
  title?: string
  iconButton?: boolean
  icon?: any
}
interface ICrmDropdownButtonState {
  isShowOption: boolean
}
class CrmDropdownButton extends PureComponent<ICrmDropdownButton, ICrmDropdownButtonState> {
  constructor(props) {
    super(props)

    this.state = {
      isShowOption: false
    }
  }

  handleToggleExpandOption = event => {
    console.log('handleToggleExpandOption')
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    this.setState({ isShowOption: !this.state.isShowOption })
    event.preventDefault()
    return false
  }

  renderOptionButton = () => {
    const hideOption = () => {
      this.setState({ isShowOption: false })
    }

    return this.props.options ? (
      this.props.options.map(props => {
        // eslint-disable-next-line
        props.hideOption = hideOption
        return optionButtonRender(props)
      })
    ) : (
        <div />
      )
  }

  handleClickOutside = () => {
    this.setState({ isShowOption: false })
  }

  handlePrimaryButton = event => {
    this.setState({ isShowOption: false })
    if (this.props.onClick) {
      this.props.onClick(event)
    } else {
      this.handleToggleExpandOption(event)
    }
  }

  render () {
    const { label } = this.props

    return (
      <div className="CrmDropdownButtonAndOptionsContainer">
        <div className="CrmDropdownButtonContainer">
          <div
            id={this.props.id}
            className={`CrmDropdownButtonNew CrmDropdownButton ${getButtonClassProperties(
              this.props
            )} ${this.props.small ? 'CrmDropdownButton--small' : ''}`}
            onClick={this.handlePrimaryButton}
            title={this.props.title}
            style={this.props.style}
          >
            <div className="dropdownContainer">
              {this.props.icon && (
                <div className="CrmButton-iconWrapper">
                  {this.props.iconButton && (
                    <FontAwesomeIcon icon={this.props.icon} />
                  )}
                  {typeof this.props.icon === 'string' && (
                    <span
                      className={`${
                        this.props.icon ? 'fa fa-' + this.props.icon : ''
                        }`}
                    />
                  )}
                </div>
              )}
              {!this.props.iconButton && (
                <label className="util-flexGrow">{label}</label>
              )}
              {this.props.options && this.props.options.length > 0 && (
                <span
                  className="dropdownMoreOptionIcon"
                  onClick={this.handleToggleExpandOption}
                >
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              )}
            </div>
          </div>
        </div>
        {this.state.isShowOption && (
          <div className="optionsContainer">
            <ul className="CrmDropdownButton-options">
              {this.renderOptionButton()}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default enhanceWithClickOutside(CrmDropdownButton)
