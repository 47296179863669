import React from 'react'
import { func, shape, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { CrmDropdownButton } from 'crm-components'
import { canStartNewProspect } from 'crm-utils/accounts-utils'
import { PERMISSIONS_TYPES, useGeneralPermissionsContext } from '@cartrack-crm/crm-core'

const AccountActionsButton = props => {
  const {
    account,
    onStartNewProspect,
    process,
    onMergeAccount,
    onDeleteAccount,
    onUnDeleteAccount,
    onCompleteProcess,
    onMigrationAccount,
    onPrioritizeAccount,
    isManager
  } = props
  const convertOptions = []

  const { hasPermissionByType } = useGeneralPermissionsContext()
  const isAdmin = hasPermissionByType(PERMISSIONS_TYPES.ADMIN_MANAGE) || hasPermissionByType(PERMISSIONS_TYPES.ADMIN_READ)

  let secondaryActionLabel
  let secondaryActionHandler
  let secondaryOptions = []

  const canStartProcess = canStartNewProspect(account)
  let primaryActionLabel = (
    <FormattedMessage
      id="crm.ui.button.actions"
      defaultMessage="Other Actions"
    />
  )
  let primaryActionHandler
  let instanceUid = account.lead_campaign?.instance_uid ?? "";
  let showBtn = ["793c83c2-f829-464c-ad82-a157cf85bf34", "5acd013f-0a59-4042-ba8c-55139b4f8996"].includes(instanceUid) && isAdmin

  const isCustomer =
    props.account &&
    props.account.sales_status &&
    props.account.sales_status.code === 'customer'
  const kind =
    props.account.sales_status && props.account.sales_status.code
      ? props.account.sales_status.code
      : props.account.kind

  if (kind === 'lead' && !isCustomer && !process) {
    primaryActionLabel = (
      <FormattedMessage id="crm.ui.button.convert" defaultMessage="Convert" />
    )
    primaryActionHandler = () =>
      onStartNewProspect({
        kind: 'prospect',
        mode: 'convert'
      })
  }
  if (kind === 'lead' && !process) {
    primaryActionLabel = 'Convert'
    primaryActionHandler = () =>
      onStartNewProspect({
        kind: 'prospect',
        mode: 'convert'
      })
  }

  if (process && !process.completed_time) {
    secondaryOptions.push({
      key: 'completeProcess',
      icon: 'ellipsis-v',
      label: (
        <FormattedMessage
          id="crm.ui.button.send_to_dialer"
          defaultMessage="Complete process"
        />
      ),
      iconButton: true,
      onClick: () => onCompleteProcess({})
    })
    secondaryActionLabel = (
      <FormattedMessage
        id="crm.ui.account.prospect.lost_prospect"
        defaultMessage="Prospect Lost"
      />
    )
    secondaryActionHandler = onCompleteProcess
  }
  if (
    ((process && process.completed_time) ||
      process === undefined ||
      process.completed_time) &&
    canStartProcess
  ) {
    primaryActionLabel = (
      <FormattedMessage
        id="crm.account.start_sales_pipeline"
        defaultMessage="Start Sales Pipeline"
      />
    )
    primaryActionHandler = () =>
      onStartNewProspect({
        kind: 'prospect',
        mode: 'start'
      })
  }
  if (account.is_deleted) {
    convertOptions.push({
      key: 'Restore',
      icon: 'trash',
      label: (
        <FormattedMessage
          id="crm.ui.button.undelete_account"
          defaultMessage="Restore Deleted Account"
        />
      ),
      iconButton: true,
      onClick: onUnDeleteAccount
    })
  } else {
    convertOptions.push({
      key: 'delete',
      icon: 'trash',
      label: (
        <FormattedMessage
          id="crm.ui.button.delete_account"
          defaultMessage="Delete Account"
        />
      ),
      iconButton: true,
      onClick: onDeleteAccount
    })
  }

  if (convertOptions.length === 0 && !primaryActionHandler) {
    return <div />
  }
  convertOptions.push({
    key: 'merge accounts',
    icon: 'ellipsis-v',
    label: (
      <FormattedMessage
        id="crm.ui.button.merge_accounts"
        defaultMessage="Merge Accounts"
      />
    ),
    iconButton: true,
    onClick: onMergeAccount
  })

  if (isManager) {
    convertOptions.push({
      key: 'prioritize',
      icon: 'ellipsis-v',
      label: (
        <FormattedMessage
          id="crm.ui.button.prioritize_account_tasks"
          defaultMessage="Prioritize Account Tasks"
        />
      ),
      iconButton: true,
      onClick: onPrioritizeAccount
    })
  }
  if (showBtn) {
    convertOptions.push({
      key: 'migration',
      icon: 'ellipsis-v',
      label: (
        <FormattedMessage
          id="crm.ui.button.prioritize_account_tasks"
          defaultMessage="Migrate Account to CRM2"
        />
      ),
      iconButton: true,
      onClick: onMigrationAccount
    })
  }
  return (
    <React.Fragment>
      {secondaryActionLabel && (
        <CrmDropdownButton
          title="Convert to Prospect or send to Dialer"
          type="secondary"
          label={secondaryActionLabel}
          onClick={secondaryActionHandler}
          options={secondaryOptions}
          style={{ marginLeft: 5, marginRight: 5 }}
        />
      )}
      <CrmDropdownButton
        title="Convert to Prospect or send to Dialer"
        type="primary"
        label={primaryActionLabel}
        onClick={primaryActionHandler}
        options={convertOptions}
        style={{ marginLeft: 5, marginRight: 5 }}
      />
    </React.Fragment>
  )
}
AccountActionsButton.propTypes = {
  onDeleteAccount: func.isRequired,
  onUnDeleteAccount: func.isRequired,
  onStartNewProspect: func.isRequired,
  onMergeAccount: func.isRequired,
  account: shape().isRequired,
  process: shape(),
  onPrioritizeAccount: func.isRequired,
  onMigrationAccount: func.isRequired,
  isManager: bool.isRequired
}
AccountActionsButton.defaultProps = {
  process: undefined
}

export default AccountActionsButton
