import React from 'react'

import styles from './sales-call-inner.scss'
import { CrmOptionButtons } from 'crm-components'
import Step3NextAction from './step3-next-action.jsx'

class Step2Questionaire extends React.PureComponent {
  handleTypeChanged = val => {
    console.log('handleTypeChanged', val)
    const newResultData = {
      firstValidationResult: {
        value: val
      }
    }
    this.props.onResultDataChange(newResultData)
  }
  render() {
    const { activity, resultData } = this.props

    return (
      <div className={styles.SalesCallInnerStep}>
        Step2Questionaire
        <div>Vehicles</div>
      </div>
    )
  }
}

class Step1BogusSeparation extends React.PureComponent {
  handleTypeChanged = val => {
    console.log('handleTypeChanged', val)
    const newResultData = {
      firstValidationResult: {
        value: val
      }
    }
    this.props.onResultDataChange(newResultData)
  }
  render() {
    const { activity, resultData } = this.props
    const options = [
      { value: 'valid', label: 'Valid' },
      { value: 'callback', label: 'Callback' },
      { value: 'bogus', label: 'Bogus' }
    ]
    return (
      <div className={styles.SalesCallInnerStep}>
        Step1
        <CrmOptionButtons
          options={options}
          input={{
            onChange: this.handleTypeChanged,
            value: resultData.firstValidationResult
              ? resultData.firstValidationResult.value
              : undefined
          }}
        />
      </div>
    )
  }
}

class SalesCallInner extends React.PureComponent {
  handleUpdate() {
    this.props.onResultDataChange()
  }

  render() {
    const { activity } = this.props
    const resultData = this.props.activity.result_data

    return (
      <div>
        Sales call inner
        <Step1BogusSeparation
          activity={activity}
          resultData={resultData}
          onResultDataChange={this.props.onResultDataChange}
        />
        {resultData.firstValidationResult && (
          <React.Fragment>
            step 2
            <Step2Questionaire {...this.props} resultData={resultData} />
          </React.Fragment>
        )}
        <Step3NextAction {...this.props} resultData={resultData} />
        <small>{JSON.stringify(resultData)}</small>
      </div>
    )
  }
}

export default SalesCallInner
