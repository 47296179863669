import React, { Component } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { CrmButton } from '@cartrack-crm/ui'

const GET_MASTERS = gql`
  query masters {
    masters {
      master_uid
      name
      cams_username
      details
      company_code
      step
      main_email
      config_completed
      template_code
    }
  }
`

const ListMasterCustomer = (props) => {
  const history = props.history
  const { loading, error, data } = useQuery(GET_MASTERS, {
    variables: {},
    skip: false,
    fetchPolicy: 'no-cache'
  })

  if (loading)
    return (
      <div className="row util-marginTop">
        <div className={`LoadingIcon`} />
      </div>
    )

  if (error) return <p>Error</p>

  const list = data?.masters ? data.masters : []

  const renderItem = (obj) => (
    <div className="col-md-2" style={{ padding: '5px' }} key={obj.master_uid}>
      <div className="CrmMdCard util-paddingSm util-hooverable util-pointer">
        <div
          className="CrmFormSection-header util-flexRow"
          style={{ justifyContent: ' space-between' }}
        >
          <div>{obj.name}</div>
          <div
            className="util-textRight"
            style={{ display: 'flex', flexDirection: 'row', maxWidth: '70px' }}
          >
            <CrmButton
              xsmall
              icon="pencil"
              id={obj.master_uid}
              onClick={() => {
                history.push(`/master/${obj.master_uid}`)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div className="row util-marginTop">{MasterListNav()}</div>
      <br />
      <div className="row util-marginTop">
        <CrmButton
          label="Create"
          type="primary"
          onClick={() => {
            history.push(`/master/new`)
          }}
        />
      </div>
      <div className="row util-marginTop">
        {list.map((v: any) => renderItem(v))}
      </div>
    </div>
  )
}

export default ListMasterCustomer

export const MasterListNav = () => {
  return (
    <Link to="/master">
      <span className="Breadcrumbs-crumbLink">
        <i className={`fa fa-angle-left`} aria-hidden="true" /> List Master
      </span>
    </Link>
  )
}

export const SectionHeader = ({ name }) => {
  return <p className={`SectionHeader SectionHeader--sub`}>{name}</p>
}
