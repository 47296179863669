import React from 'react'
import { FormattedDate } from 'react-intl'

const numberCell = (prop) => <span className="util-textRight util-fullWidth">{prop.value}</span>
export const columnsOpportunity = {
  type: 'Opportunity',
  name: 'Opportunity',
  description: 'Opportunity',
  fields: [
    {
      name: 'status_code',
      description: 'Status',
      type: 'string'
    },
    {
      name: 'due_date',
      description: 'Due Date',
      type: 'date',
      Cell: (row) => <div>{row.original.due_date ? <FormattedDate value={row.original.due_date} /> : '-'}</div>
    },
    {
      name: 'due_date_month',
      description: 'Due Date Month',
      type: 'date'
    },
    {
      name: 'quantity',
      description: 'Size',
      type: 'number',
      column_label: 'Quantity'
    },
    {
      description: 'Forecast Close',
      name: 'due_date',
      type: 'date',
      Cell: (row) => <div>{row.original.due_date ? <FormattedDate value={row.original.due_date} /> : '-'}</div>,
      maxWidth: 180
    },
    {
      name: 'created_time',
      description: 'Created At',
      type: 'date',
      Cell: (row) => <div>{row.original.created_time ? <FormattedDate value={row.original.created_time} /> : '-'}</div>
    },
    {
      name: 'completed_date',
      description: 'Completed Date',
      type: 'date',
      Cell: (row) => (
        <div>{row.original.completed_date ? <FormattedDate value={row.original.completed_date} /> : '-'}</div>
      )
    },
    {
      description: 'Confidence',
      name: 'confidence_level',
      type: 'number',
      maxWidth: 120,
      Cell: numberCell
    },
    {
      description: 'Owner',
      type: 'User',
      name: 'owner',
      column_label_prefix: 'Owner'
    },
    {
      description: 'Account',
      type: 'Account',
      name: 'account',
      column_label_prefix: 'Account'
    }
  ]
}
