/* eslint-disable no-unused-vars, react/jsx-no-bind */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  string,
  arrayOf,
  shape,
  func,
  element,
  oneOfType,
  bool
} from 'prop-types'
import enhanceWithClickOutside from 'react-click-outside'
import { injectIntl, intlShape } from 'react-intl'

class CrmOptionButtons extends PureComponent {
  // Aconstructor(props) {
  //   super(props)

  //   this.handleOptionClick = this.handleOptionClick.bind(this)
  // }

  handleOptionClick = event => {
    const value = event.currentTarget.id
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(value)
    }
  }

  render() {
    const {
      input,
      options,
      small,
      extraClassNames: {
        containerClassNames = '',
        titleClassNames = '',
        selectClassNames = ''
      }
    } = this.props
    const value = input ? input.value : undefined

    return (
      <div className="CrmOptionButtons">
        {options &&
          options.map(option => (
            <div
              key={option.value}
              id={option.value}
              onClick={this.handleOptionClick}
              className={`CrmOptionButtons-button ${
                option.value === value
                  ? 'CrmOptionButtons-button--selected'
                  : ''
              } ${small ? 'CrmOptionButtons-small' : ''}`}
            >
              {option.label}
            </div>
          ))}
      </div>
    )
  }
}

CrmOptionButtons.propTypes = {
  options: arrayOf(shape()).isRequired,
  input: shape({
    onChange: func,
    value: string
  }),
  extraClassNames: shape({
    containerClassNames: string,
    titleClassNames: string,
    selectClassNames: string
  }),
  onChange: func,
  small: bool
}

CrmOptionButtons.defaultProps = {
  onChange() {},
  activeOption: null,
  extraClassNames: {
    containerClassNames: '',
    titleClassNames: '',
    selectClassNames: ''
  },
  input: {},
  small: false
}

const injectedDropdown = injectIntl(CrmOptionButtons)
export default injectedDropdown
