import React from 'react'
import cloneDeep from 'clone-deep'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CrmButton } from 'crm-components'
import styles from 'crm-modules/meeting/components/section-guests/section-guests.scss'
import sectionContactPersonsHoc from 'crm-modules/accounts/hoc/section-contact-persons-hoc.js'
import ContactPersonItem from 'crm-modules/accounts/components/layout19/contact-person-item.jsx'
import { shape, bool, func, string, arrayOf } from 'prop-types'

class SectionSelectRecipients extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      persons: cloneDeep(props.persons),
      selectedTab: '',
      sendEmailFromQuotation: cloneDeep(props.sendEmailFromQuotation),
      target: 'to'
    }
  }

  componentDidMount() {
    this.setDefaultRecipient()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.persons !== this.props.persons) {
      this.setState({
        persons: cloneDeep(nextProps.persons)
      })
    }
    if (
      this.props.sendEmailFromQuotation !== nextProps.sendEmailFromQuotation
    ) {
      this.setState({
        sendEmailFromQuotation: cloneDeep(nextProps.sendEmailFromQuotation)
      })
    }
  }

  handleAddPerson = async email => {
    this.setState({ isExpanded: false })
    const recipients = await this.props.fields.getAll()
    const found = recipients.find(element => {
      return (
        element.contact_details_uid ===
        email.contact_details.contact_details_uid
      )
    })
    email.role_code = this.state.target
    if (found) {
      this.props.handleRemoveEmail(email)
    } else {
      this.props.handleGuestsChange(email)
    }
  }

  handleStartAddPerson = () => {
    if (this.props.isAddingNewPerson()) {
      return
    }
    this.props.onStartAddPerson()
  }

  getPersons = persons => {
    if (persons && persons.length > 0) {
      const pPersons = [...persons]
      const emails = pPersons.reduce((ret, v) => {
        v.contact_details.map(ct => {
          if (ct.kind === 'email') {
            let vv = { ...v }
            delete vv.contact_details
            vv = { ...vv, contact_details: ct }
            ret.push(vv)
          }
        })
        return ret
      }, [])
      return emails
    } else {
      return []
    }
  }

  getPersonWithEmail = persons => {
    const { account } = this.props
    let personWithEmail = []
    if (account.contact_details && account.contact_details.length > 0) {
      const emails = account.contact_details.reduce((ret, v) => {
        if (v.kind === 'email') {
          let realContact = {
            contact_details: v
          }

          ret.push(realContact)
        }

        return ret
      }, [])
      personWithEmail = emails
    }

    persons.map(v => {
      if (v.isNew) {
        personWithEmail.push({ ...v })
      }
    })

    const added = [...this.emailTO, ...this.emailCC].map(
      x => x.contact_details_uid
    )
    return personWithEmail.filter(p => {
      if (p.contact_details.contact_details_uid) {
        return !added.includes(p.contact_details.contact_details_uid)
      }
      return true
    })
  }

  setDefaultRecipient = () => {
    if (this.state.sendEmailFromQuotation) {
      const personWithEmail = this.getPersonWithEmail(this.state.persons)
      const person = personWithEmail[0]
      if (person) {
        this.handleAddPerson(person)
      }
    }
  }

  get emailTO() {
    return this.props.fields.getAll().filter(r => r.role_code === 'to')
  }
  get emailCC() {
    return this.props.fields.getAll().filter(r => r.role_code === 'cc')
  }

  getDisplayPerson = r => {
    const persons = this.props.persons
    const person = persons.find(element => {
      if (element.contact_details && r.contact_details)
        return element.contact_details
          .map(x => x.contact_details_uid)
          .includes(r.contact_details.contact_details_uid)
    })
    if (person) return person.name
    return ''
  }

  renderRecipents(recipients, target) {
    return recipients
      .map(r => {
        const person = this.getDisplayPerson(r)
        return (
          <div
            className="CrmChip CrmChip-default CrmChip-small"
            key={r.contact_details_uid}
          >
            <span
              onClick={() => this.props.toggleRole(r)}
              style={{ marginRight: 4 }}
            >
              <i
                className={`fa ${
                  r.role_code === 'to' ? 'fa-caret-down' : 'fa-caret-up'
                }`}
              />
            </span>
            <span>
              <b>{person}</b>
              {'<'}
              {r.contact_details && r.contact_details.value} {'>'}
            </span>
            <span
              style={{ marginLeft: 10 }}
              onClick={() => this.props.handleRemoveEmail(r)}
            >
              <i className="fa fa-close" />
            </span>
          </div>
        )
      })
      .concat(
        <div className="CrmChip CrmChip-default CrmChip-small">
          <span
            onClick={() => this.setState({ isExpanded: true, target: target })}
          >
            <i className="fa fa-plus" /> {target}
          </span>
        </div>
      )
  }

  render() {
    const { account } = this.props
    const personWithEmail = this.getPersonWithEmail(this.state.persons)
    const persons = this.getPersons(this.state.persons)
    const findNewPerson =
      (personWithEmail && personWithEmail.find(v => v.isNew)) || []

    return (
      <div className={styles.SectionGuests}>
        {!this.props.isEditing ? (
          <div>
            {this.props.activity.participants &&
              this.props.activity.participants.map(par => {
                if (par.role_code === 'to') {
                  const person = persons.find(element => {
                    return (
                      element.contact_details.contact_details_uid ===
                      par.contact_details.contact_details_uid
                    )
                  })
                  return (
                    <div className="row">
                      <div>
                        {person && person.name ? person.name : account.name}
                      </div>
                      <div>
                        <small className="util-marginLeft">
                          {'<'}
                          {par.contact_details.value}
                          {'>'}
                        </small>
                      </div>
                    </div>
                  )
                }
              })}
          </div>
        ) : (
          <React.Fragment>
            {this.emailTO.length === 0 && (
              <div
                onClick={() =>
                  this.setState({ isExpanded: true, target: 'to' })
                }
                className="util-hooverableContent util-hooverOp"
              >
                <div>No recipients - Click here to select</div>
                <div className="CrmValidatedField-errors">
                  This field is required
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12 util-hooverableContent util-hooverOp">
                <div>
                  <strong>To: </strong>
                  {this.renderRecipents(this.emailTO, 'to')}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 util-hooverableContent util-hooverOp">
                <div>
                  <strong>Cc: </strong>
                  {this.renderRecipents(this.emailCC, 'cc')}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        {this.state.isExpanded && (
          <React.Fragment>
            <div className={styles.PersonItemsWrapper}>
              {personWithEmail.map((person, index) => {
                const key =
                  person.contact_details.contact_details_uid ||
                  person.newObjectUid
                const guestItemClass = person.isGuest ? styles.GuestItem : ''
                if (person.isNew) {
                  return (
                    <div key={key} className={styles.AddNewItemWrapper}>
                      <div className={styles.AddNewItemHeader}>
                        Add new person
                      </div>
                      <ContactPersonItem
                        index={index}
                        person={person}
                        onCancelAddPerson={this.props.onCancelAddPerson}
                        removePersonUI={this.props.removePersonUI}
                        updatePersonsUI={this.props.updatePersonsUI}
                      />
                    </div>
                  )
                } else {
                  if (findNewPerson.length === 0) {
                    let selected
                    const ps = persons.find(element => {
                      return (
                        element.contact_details.contact_details_uid ===
                        person.contact_details.contact_details_uid
                      )
                    })
                    return (
                      <div
                        key={key}
                        className={`ActivityListItem-headerItem-value ${styles.PersonItem} ${guestItemClass}`}
                        onClick={() => this.handleAddPerson(person)}
                      >
                        <div className={`${styles.GuestItem__ContentWrapper}`}>
                          <div className={`${styles.GuestItem__Content}`}>
                            <div className={`${styles.Department}`}>
                              {ps && ps.department ? ps.department : ''}
                            </div>
                            <div className={`${styles.GuestName}`}>
                              {ps && ps.name ? ps.name : account.name}
                            </div>
                            <div className={`${styles.Department}`}>
                              {person.contact_details.value}
                            </div>
                          </div>
                          <div className={`${styles.GuestItem__ContentAction}`}>
                            {selected && (
                              <React.Fragment>
                                <div className={styles.GuestItem__Top}>
                                  <div
                                    className={styles.GuestItem__RemoveIcon}
                                    onClick={e =>
                                      this.handleRemoveGuest(
                                        e,
                                        person.account_person_uid
                                      )
                                    }
                                    title="Click to remove guest"
                                  >
                                    <FontAwesomeIcon
                                      className="util-marginLeft"
                                      icon="times"
                                    />
                                  </div>
                                </div>
                                <div className={styles.GuestItem__SelectedIcon}>
                                  <FontAwesomeIcon
                                    className="util-marginLeft"
                                    icon="user"
                                  />
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                }
              })}

              {findNewPerson.length === 0 && (
                <div className={`${styles.PersonItem}`}>
                  <div className={`${styles.AddNewItemIcon}`}>
                    <div>
                      <FontAwesomeIcon
                        title="Click to add guests"
                        className="util-hooverable util-pointer"
                        icon="user-plus"
                        onClick={this.handleStartAddPerson}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!this.props.activity.activity_uid && (
              <div className="util-flexRowRight">
                <CrmButton
                  grouped
                  label={
                    <FormattedMessage
                      id="meeting.section.close"
                      defaultMessage="Close"
                    />
                  }
                  id="section_guests_close"
                  small
                  onClick={() => this.setState({ isExpanded: false })}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

SectionSelectRecipients.propTypes = {
  account: shape({}).isRequired,
  activity: shape({}).isRequired,
  fields: shape({}).isRequired,
  isEditing: bool.isRequired,
  persons: arrayOf(shape({})).isRequired,
  handleGuestsChange: func.isRequired,
  handleRemoveEmail: func.isRequired,
  isAddingNewPerson: func.isRequired,
  onStartAddPerson: func.isRequired,
  onCancelAddPerson: func.isRequired,
  sendEmailFromQuotation: shape({})
}

SectionSelectRecipients.defaultProps = {
  sendEmailFromQuotation: undefined
}

export default sectionContactPersonsHoc(SectionSelectRecipients)
