import React, { useState } from 'react'
import { useCrmDynamicTableContext } from '../../dynamic-table-context'
import { CrmButton } from '@cartrack-crm/ui'
import { DynamicTableColumnsSelector } from '../../dynamic-table-columns'
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns'
import { useComponentConfigurationContext } from '../../../configuration-tools/component-configuration-context'

const ViewOptionsTable = (props) => {
  const dynamicTableContext: any = useCrmDynamicTableContext()
  const [isShowingColumnSelect, setShowColumnsSelect] = useState(false)
  const componentContext = useComponentConfigurationContext()
  const handleSelectColumnClick = () => {
    console.log('handleSelectColumnClick')
    setShowColumnsSelect(true)
    props.setShowColumnsSelect(true)
  }
  const handleSaveOptionalColumns = (newColumns) => {
    console.log('handleSaveOptionalColumns', newColumns)
    dynamicTableContext.setCustomColumns(newColumns)
    setShowColumnsSelect(false)
    props.setShowColumnsSelect(false)
  }
  const handleClose = () => {
    setShowColumnsSelect(false)
    props.setShowColumnsSelect(false)
  }
  console.log('ViewOptionsTable render', dynamicTableContext, componentContext)
  const allColumns = componentContext.schema.types
  return (
    <div>
      <CrmButton
        label="Select Columns"
        onClick={handleSelectColumnClick}
        icon={faColumns}
        grouped
      />
      <DynamicTableColumnsSelector
        isOpen={isShowingColumnSelect}
        onClose={handleClose}
        columnOptions={allColumns}
        optionalColumns={dynamicTableContext.customColumns}
        onSave={handleSaveOptionalColumns}
        rootQLType={dynamicTableContext.rootQLType}
      />
    </div>
  )
}

export default ViewOptionsTable
