/* eslint-disable react/jsx-handler-names */
import React from 'react'
import { connect } from 'react-redux'
import { string, func, shape } from 'prop-types'
import { Modal } from 'util-components'
import {
  getMessage,
  getVerbs,
  closeConfirmation
} from 'crm-duxs/confirmation-modal'
import Button from 'util-components/button.jsx'

const ConfirmationModal = props =>
  props.message ? (
    <Modal
      extraClassNames={{ modalClassName: 'ConfirmationModal' }}
      contentLabel="Confirmation Modal"
      isOpen={Boolean(props.message)}
      hideHeader
      style={{
        content: {
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          height: '115px',
          width: '50%'
        }
      }}
    >
      <div className="ConfirmationModal-message">{props.message}</div>
      <Button
        id=""
        className="ConfirmationModal-button--left"
        label={`No${props.verbs.no ? `, ${props.verbs.no}` : ''}`}
        onClick={props.doCloseConfirmation}
      />
      <Button
        id="true"
        className="ConfirmationModal-button--right"
        blue
        label={`Yes${props.verbs.yes ? `, ${props.verbs.yes}` : ''}`}
        onClick={props.doCloseConfirmation}
      />
    </Modal>
  ) : null

ConfirmationModal.propTypes = {
  message: string.isRequired,
  verbs: shape({}).isRequired,
  doCloseConfirmation: func.isRequired
}

function mapStateToProps(state) {
  return { message: getMessage(state), verbs: getVerbs(state) }
}

function mapDispatchToProps(dispatch) {
  return {
    doCloseConfirmation: event =>
      dispatch(closeConfirmation(Boolean(event.currentTarget.id)))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)
