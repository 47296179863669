import React, { Component } from 'react'
import { arrayOf, shape, string, element, func, number } from 'prop-types'
import zenscroll from 'zenscroll'

/*
  Example Schema:
  const items = [
    {
      label: 'General Info',
      id: 'general-settings' // anchor tag to scroll to
      subItems: [
        {
          label: 'Company',
          id: 'company-settings'
        },
      ],
    },
  ]
*/

class VerticalNav extends Component {
  constructor(props) {
    super(props)
    this.state = { active: props.items[0].id }
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    const root = document.getElementById('router-root')
    this.zenscroll = zenscroll.createScroller(
      root,
      200,
      this.props.scrollOffset
    )
  }

  handleClick(e) {
    const { target } = e.currentTarget.dataset
    this.setState(
      {
        active: target
      },
      () => {
        const targetDiv = document.getElementById(target)
        if (targetDiv) this.zenscroll.to(targetDiv)
      }
    )
  }

  render() {
    const { items } = this.props
    const { active } = this.state

    return (
      <ul className="VerticalNav">
        {items.map(item => (
          <li key={item.label}>
            <span
              className={`VerticalNav-item${
                active === item.id ? ' is-active' : ''
              }`}
              data-target={item.id}
              onClick={this.handleClick}
            >
              <a href={'#' + item.id} className="VerticalNav-link">
                {item.label}
              </a>
            </span>
            {item.subItems ? (
              <ul key={item.label} className="VerticalNav-subList">
                {item.subItems.map(subItem => (
                  <li
                    key={subItem.label}
                    data-target={subItem.id}
                    className={`VerticalNav-subList-item${
                      active === subItem.id ? ' is-active' : ''
                    }`}
                    onClick={this.handleClick}
                  >
                    <a href={'#' + subItem.id} className="VerticalNav-link">
                      {subItem.label}
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    )
  }
}

VerticalNav.propTypes = {
  items: arrayOf(
    shape({
      label: string.isRequired,
      id: string.isRequired,
      handleClick: func,
      subItems: arrayOf(
        shape({
          label: string.isRequired,
          id: string.isRequired,
          component: element,
          handleClick: func
        })
      )
    })
  ).isRequired,
  scrollOffset: number.isRequired
}

export default VerticalNav
