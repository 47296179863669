import { graphql, compose } from 'react-apollo'
import { qlqAccountResources } from 'crm-data/resources'

const qlOptions = {
  options: ownProps => ({
    variables: { account_uid: ownProps.accountUid }
  }),
  props: ({ resourcesQL, ownProps }) => {
    // console.log('resourcesQL', resourcesQL, ownProps)
    return {
      ...ownProps,
      resourcesQL,
      resources:
        resourcesQL && resourcesQL.account
          ? resourcesQL.account.resources
          : undefined,
      isLoadingResources: resourcesQL ? resourcesQL.loading : false
    }
  }
}
const ResourcesListQLHOC = compose(
  graphql(qlqAccountResources, {
    ...qlOptions,
    name: 'resourcesQL'
  })
)

export default ResourcesListQLHOC
