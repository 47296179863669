import React from 'react'
import styled from 'styled-components'

const LoadingStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: #484848;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
    padding: 20px;
  }

  .lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 46.25%;
    left: 82.5%;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 27.5%;
    left: 77.5%;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 13.75%;
    left: 65%;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 8.75%;
    left: 46.25%;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 13.75%;
    left: 27.5%;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 27.5%;
    left: 13.75%;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 46.25%;
    left: 8.75%;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 65%;
    left: 13.75%;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 77.5%;
    left: 27.5%;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 82.5%;
    left: 46.25%;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 77.5%;
    left: 65%;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 65%;
    left: 77.5%;
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
`

const BgLoading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #fff;
`
export const ReportLoading = () => {
  const circles = [...Array(12)].map((_, index) => (
    <div key={index} style={{ background: `#F4793A`, width: 100 * 0.075, height: 100 * 0.075 }} />
  ))
  return (
    <React.Fragment>
      <BgLoading />
      <LoadingStyle>
        <div className="lds-default">{circles}</div>
        <label>Please wait while loading...</label>
      </LoadingStyle>
    </React.Fragment>
  )
}
